export const LoginStatusEnum = Object.freeze({
  INTERACTING: -3,
  PROCESSING: -2,
  LOADING: -1,
  SUCCESS: 0,
});

export const NormalLoginStatusEnum = Object.freeze({
  SENDING_RESET_PWD_EMAIL: -4,
  CHECKING_USERNAME: -3,
  CHECKING_PASSWORD: -2,
  INIT: -1,
  SUCCESS: 0,
});

export const RegisterStatusEnum = Object.freeze({
  REGISTERING: -2,
  INIT: -1,
  SUCCESS: 0,
});

export const AuthStepEnum = Object.freeze({
  WAIT_FOR_CHECK_USERNAME: 0,
  WAIT_FOR_CHECK_PASSWORD: 1,
  WAIT_FOR_WECHAT_SCAN_QR_CODE: 2,
  WAIT_FOR_REGISTER: 3,
  RESET_PWD_EMAIL_CONFIRM: 4,
  RESET_PWD_EMAIL_SENT: 5,
});

export const WeChatLoginStatusEnum = Object.freeze({
  PROCESSING: -5,
  WAIT_FOR_SCANNING: -4,
  LOADING: -3,
  WAIT_FOR_SCANNING_FWH_QR_CODE: -2,
  LOADING_FWH_QR_CODE: -1,
  SUCCESS: 0,
});

export const ResetPwdStatusEnum = Object.freeze({
  INTERACTING: -2,
  PROCESSING: -1,
  SUCCESS: 0,
});

export const ChangePwdStatus = Object.freeze({
  INTERACTING: -2,
  PROCESSING: -1,
  SUCCESS: 0,
});

export const ChangeUserBasicInfoStatus = Object.freeze({
  PROCESSING: -1,
  SUCCESS: 0,
});