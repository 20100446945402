import _ from 'lodash';

import {
  NODE_TYPE_TEXT,
  NODE_TYPE_COMPANY,
  NODE_TYPE_TALENT,
  NODE_TYPE_PATENT,
  NODE_TYPE_PAPER,
  NODE_TYPE_POLICY,
  NODE_TYPE_ORG,
  NODE_TYPE_INSTITUTE,
  NODE_TYPE_NEWS_ACTIVITIES,
  NODE_TYPE_TAG,
  NODE_TYPE_DOCS,
  NODE_TYPE_DATASET,
  NODE_TYPE_GOV,
  NODE_TYPE_NATURE,
  NODE_TYPE_INDEX,
  NODE_TYPE_GRAPH,
  NODE_TYPE_COLLEGE_AND_UNIVERSITY,
  NODE_TYPE_PARK,
  NODE_TYPE_TECHNOLOGY,
  NODE_TYPE_PROJECT,
} from '@/constants/nodeConfig';
import {IconTypes} from '@/constants/common';
import {defaultDefine} from '@/constants/vis.defaultDefine.1';

export const ICON_CATEGORY_CUSTOM = 'custom'; // 自定义

export const ICON_CATEGORY_TEXT = `nt0`; // 关注点
export const ICON_CATEGORY_COMPANY = `nt1`; // 企业
export const ICON_CATEGORY_TALENT = `nt2`; // 人物
export const ICON_CATEGORY_PATENT = `nt3`; // #废弃# 专利
export const ICON_CATEGORY_PAPER = `nt4`; // #废弃# 论文
export const ICON_CATEGORY_POLICY = `nt5`; // 政策
export const ICON_CATEGORY_ORG = `nt6`; // 协会 原：社团协会
export const ICON_CATEGORY_INSTITUTE = `nt7`; // 院所 原：科研院所
export const ICON_CATEGORY_NEWS_ACTIVITIES = `nt8`; // #废弃# 新闻与活动
export const ICON_CATEGORY_TAG = `nt9`; // 线索 即：后台计算出的标签
export const ICON_CATEGORY_DOCS = `nt10`; // #废弃# 用户文档 = 用户文档
export const ICON_CATEGORY_DATASET = `nt11`; // 数据 原：数据集
export const ICON_CATEGORY_GOV = `nt12`; // 政府
export const ICON_CATEGORY_NATURE = `nt13`; // #自然
export const ICON_CATEGORY_INDEX = `nt14`; // #废弃# 指标
export const ICON_CATEGORY_GRAPH = `nt15`; // #废弃# 图表
export const ICON_CATEGORY_COLLEGE_AND_UNIVERSITY = `nt16`; // 高校
export const ICON_CATEGORY_PARK = `nt17`; // 园区
export const ICON_CATEGORY_TECHNOLOGY = `nt18`; // 技术
export const ICON_CATEGORY_PROJECT = `nt19`; // 项目
export const ICON_CATEGORY_PREPARE = `nt1_prepare`; // 准备
export const ICON_CATEGORY_DOING = `nt1_doing`; // 进行
export const ICON_CATEGORY_FINISH = `nt1_finish`; // 完成
export const ICON_CATEGORY_FLAG_A = `nt1_flag_a`; // 旗帜A
export const ICON_CATEGORY_FLAG_B = `nt1_flag_b`; // 旗帜B
export const ICON_CATEGORY_FLAG_C = `nt1_flag_c`; // 旗帜C
export const ICON_CATEGORY_FLAG_E = `nt1_flag_e`; // 旗帜D
export const ICON_CATEGORY_FLAG_F = `nt1_flag_f`; // 旗帜E
export const ICON_CATEGORY_FLAG_G = `nt1_flag_g`; // 旗帜F
export const ICON_CATEGORY_TEXT_A = `nt0_text_a`; // 关注点A
export const ICON_CATEGORY_TEXT_B = `nt0_text_b`; // 关注点B
export const ICON_CATEGORY_TEXT_C = `nt0_text_c`; // 关注点C
export const ICON_CATEGORY_TIP_A = `nt0_tip_a`; // 便签A
export const ICON_CATEGORY_TIP_B = `nt0_tip_b`; // 便签B
export const ICON_CATEGORY_TIP_C = `nt0_tip_c`; // 便签C
export const ICON_CATEGORY_TIP_D = `nt0_tip_d`; // 便签D
export const ICON_CATEGORY_TIP_E = `nt0_tip_e`; // 便签E
export const ICON_CATEGORY_TIP_F = `nt0_tip_f`; // 便签F
export const ICON_CATEGORY_TIP_G = `nt0_tip_g`; // 便签G
export const ICON_CATEGORY_TIP_H = `nt0_tip_h`; // 便签H
export const ICON_CATEGORY_TAG_A = `nt0_tag_a`; // 标签A
export const ICON_CATEGORY_TAG_B = `nt0_tag_b`; // 标签B
export const ICON_CATEGORY_TAG_C = `nt0_tag_c`; // 标签C
export const ICON_CATEGORY_TAG_D = `nt0_tag_d`; // 标签D
export const ICON_CATEGORY_TAG_E = `nt0_tag_e`; // 标签E
export const ICON_CATEGORY_TAG_F = `nt0_tag_f`; // 标签F
export const ICON_CATEGORY_TAG_G = `nt0_tag_g`; // 标签G
export const ICON_CATEGORY_TAG_H = `nt0_tag_h`; // 标签H
export const ICON_CATEGORY_NO_ICON = `nt0_no_icon`; // 无标记
export const ICON_CATEGORY_GOOD = `nt0_good`; // 点赞
export const ICON_CATEGORY_BAD = `nt0_bad`; // 点踩
export const ICON_CATEGORY_WATCH = `nt0_watch`; // 围观

const getIconConfig = ({title, nodeProperty, iconProperty, extra}) => {
  title = _.isObject(title) ? title : {_default: title};
  nodeProperty = _.isObject(nodeProperty) ? {
    lev: 'gradeD',
    meta: {
      userPreferredType: nodeProperty.userPreferredType,
      iconData: undefined,
      iconMeta: undefined,
    },
    ...nodeProperty,
  } : {
    userPreferredType: nodeProperty,
    lev: 'gradeD',
    meta: {
      userPreferredType: nodeProperty,
      iconData: undefined,
      iconMeta: undefined,
    },
  };
  iconProperty = _.isObject(iconProperty) ? {name: 'icon-circle', type: IconTypes.ICON_FONT, ...iconProperty}
    : {name: iconProperty, type: IconTypes.ICON_FONT};
  return {
    title,
    nodeProperty,
    iconProperty,
    extra: {
      nodeType: nodeProperty.userPreferredType,
      ...(extra || {}),
    },
  };
};

export const iconConfig = (() => {
  // noinspection SpellCheckingInspection
  let cfg = {
    [ICON_CATEGORY_CUSTOM]: getIconConfig({
      title: {
        _default: '其他',
      },
      nodeProperty: 'custom',
      iconProperty: {
        name: 'picture',
        type: IconTypes.ANT_DESIGN,
      },
    }),
    [ICON_CATEGORY_TEXT]: getIconConfig({
      title: {
        _default: '关注点',
        x2: '关注',
      },
      nodeProperty: NODE_TYPE_TEXT,
      iconProperty: 'icon-circle',
    }),
    [ICON_CATEGORY_COMPANY]: getIconConfig({
      title: {
        _default: '企业',
        x4: '企业组织',
      },
      nodeProperty: NODE_TYPE_COMPANY,
      iconProperty: 'icon-company',
    }),
    [ICON_CATEGORY_TALENT]: getIconConfig({
      title: {
        _default: '人物',
        x4: '社会人物',
      },
      nodeProperty: NODE_TYPE_TALENT,
      iconProperty: 'icon-talent',
    }),
    [ICON_CATEGORY_PATENT]: getIconConfig({
      title: {
        _default: '专利',
        x4: '科技专利',
      },
      nodeProperty: NODE_TYPE_PATENT,
      iconProperty: 'icon-zhuanli',
    }),
    [ICON_CATEGORY_PAPER]: getIconConfig({
      title: {
        _default: '论文',
        x4: '论文专著',
      },
      nodeProperty: NODE_TYPE_PAPER,
      iconProperty: 'icon-book',
    }),
    [ICON_CATEGORY_POLICY]: getIconConfig({
      title: {
        _default: '政策',
        x4: '政情政策',
      },
      nodeProperty: NODE_TYPE_POLICY,
      iconProperty: 'icon-policy1',
    }),
    [ICON_CATEGORY_ORG]: getIconConfig({
      title: {
        _default: '协会',
        x4: '协会社团',
      },
      nodeProperty: NODE_TYPE_ORG,
      iconProperty: 'icon-org',
    }),
    [ICON_CATEGORY_INSTITUTE]: getIconConfig({
      title: {
        _default: '院所',
        x4: '科研院所',
      },
      nodeProperty: NODE_TYPE_INSTITUTE,
      iconProperty: 'icon-institute',
    }),
    [ICON_CATEGORY_NEWS_ACTIVITIES]: getIconConfig({
      title: {
        _default: '新闻',
        x4: '社会新闻',
      },
      nodeProperty: NODE_TYPE_NEWS_ACTIVITIES,
      iconProperty: 'icon--rss',
    }),
    [ICON_CATEGORY_TAG]: getIconConfig({
      title: {
        _default: '线索',
        x4: '线索信息',
      },
      nodeProperty: NODE_TYPE_TAG,
      iconProperty: 'icon-bubble',
    }),
    [ICON_CATEGORY_DOCS]: getIconConfig({
      title: {
        _default: '文档',
        x4: '文件档案',
      },
      nodeProperty: NODE_TYPE_DOCS,
      iconProperty: 'icon-files-o',
    }),
    [ICON_CATEGORY_DATASET]: getIconConfig({
      title: {
        _default: '数据',
        x4: '数据信息',
      },
      nodeProperty: NODE_TYPE_DATASET,
      iconProperty: 'icon-database',
    }),
    [ICON_CATEGORY_GOV]: getIconConfig({
      title: {
        _default: '政府',
        x4: '政府信息',
      },
      nodeProperty: NODE_TYPE_GOV,
      iconProperty: 'icon-gov',
    }),
    [ICON_CATEGORY_NATURE]: getIconConfig({
      title: {
        _default: '自然',
        x4: '自然资源',
      },
      nodeProperty: NODE_TYPE_NATURE,
      iconProperty: 'icon-natural-resources',
    }),
    [ICON_CATEGORY_INDEX]: getIconConfig({
      title: {
        _default: '指标',
        x4: '指标数量',
      },
      nodeProperty: NODE_TYPE_INDEX,
      iconProperty: 'icon-index',
    }),
    [ICON_CATEGORY_GRAPH]: getIconConfig({
      title: {
        _default: '图表',
        x4: '数据图表',
      },
      nodeProperty: NODE_TYPE_GRAPH,
      iconProperty: 'icon-graph',
    }),
    [ICON_CATEGORY_COLLEGE_AND_UNIVERSITY]: getIconConfig({
      title: {
        _default: '高校',
        x4: '高等院校',
      },
      nodeProperty: NODE_TYPE_COLLEGE_AND_UNIVERSITY,
      iconProperty: 'icon-university',
    }),
    [ICON_CATEGORY_PARK]: getIconConfig({
      title: {
        _default: '园区',
        x4: '开发园区',
      },
      nodeProperty: NODE_TYPE_PARK,
      iconProperty: 'icon-park',
    }),
    [ICON_CATEGORY_TECHNOLOGY]: getIconConfig({
      title: {
        _default: '技术',
        x4: '科学技术',
      },
      nodeProperty: NODE_TYPE_TECHNOLOGY,
      iconProperty: 'icon-book',
    }),
    [ICON_CATEGORY_PROJECT]: getIconConfig({
      title: {
        _default: '项目',
        x4: '工程项目',
      },
      nodeProperty: NODE_TYPE_PROJECT,
      iconProperty: 'icon-project',
    }),
    [ICON_CATEGORY_PREPARE]: getIconConfig({
      title: {
        _default: '准备开始',
        x2: '准备',
      },
      nodeProperty: {
        userPreferredType: NODE_TYPE_TEXT,
        lev: 'prepare',
      },
      iconProperty: 'icon-caret-right',
    }),
    [ICON_CATEGORY_DOING]: getIconConfig({
      title: {
        _default: '正在进行',
        x2: '进行',
      },
      nodeProperty: {
        userPreferredType: NODE_TYPE_TEXT,
        lev: 'doing',
      },
      iconProperty: 'icon-doing',
    }),
    [ICON_CATEGORY_FINISH]: getIconConfig({
      title: {
        _default: '已经完成',
        x2: '完成',
      },
      nodeProperty: {
        userPreferredType: NODE_TYPE_TEXT,
        lev: 'finish',
      },
      iconProperty: 'icon-ready',
    }),
    [ICON_CATEGORY_FLAG_A]: getIconConfig({
      title: {
        _default: '旗帜A',
        x2: '旗帜',
      },
      nodeProperty: {
        userPreferredType: NODE_TYPE_TEXT,
        lev: 'gradeA',
      },
      iconProperty: {
        name: 'icon-flag1',
        color: defaultDefine.colors.level3,
      },
    }),
    [ICON_CATEGORY_FLAG_B]: getIconConfig({
      title: {
        _default: '旗帜B',
        x2: '旗帜',
      },
      nodeProperty: {
        userPreferredType: NODE_TYPE_TEXT,
        lev: 'gradeB',
      },
      iconProperty: {
        name: 'icon-flag1',
        color: defaultDefine.colors.level2,
      },
    }),
    [ICON_CATEGORY_FLAG_C]: getIconConfig({
      title: {
        _default: '旗帜C',
        x2: '旗帜',
      },
      nodeProperty: {
        userPreferredType: NODE_TYPE_TEXT,
        lev: 'gradeC',
      },
      iconProperty: {
        name: 'icon-flag1',
        color: defaultDefine.colors.level0,
      },
    }),
    [ICON_CATEGORY_FLAG_E]: getIconConfig({
      title: {
        _default: '旗帜D',
        x2: '旗帜',
      },
      nodeProperty: {
        userPreferredType: NODE_TYPE_TEXT,
        lev: 'gradeE',
      },
      iconProperty: {
        name: 'icon-flag2',
        color: defaultDefine.colors.level3,
      },
    }),
    [ICON_CATEGORY_FLAG_F]: getIconConfig({
      title: {
        _default: '旗帜E',
        x2: '旗帜',
      },
      nodeProperty: {
        userPreferredType: NODE_TYPE_TEXT,
        lev: 'gradeF',
      },
      iconProperty: {
        name: 'icon-flag2',
        color: defaultDefine.colors.level2,
      },
    }),
    [ICON_CATEGORY_FLAG_G]: getIconConfig({
      title: {
        _default: '旗帜F',
        x2: '旗帜',
      },
      nodeProperty: {
        userPreferredType: NODE_TYPE_TEXT,
        lev: 'gradeG',
      },
      iconProperty: {
        name: 'icon-flag2',
        color: defaultDefine.colors.level0,
      },
    }),
    [ICON_CATEGORY_TEXT_A]: getIconConfig({
      title: {
        _default: '关注点A',
        x2: '关注',
      },
      nodeProperty: {
        userPreferredType: NODE_TYPE_TEXT,
        lev: 'gradeDA',
      },
      iconProperty: {
        name: 'icon-circle',
        color: defaultDefine.colors.level3,
      },
    }),
    [ICON_CATEGORY_TEXT_B]: getIconConfig({
      title: {
        _default: '关注点B',
        x2: '关注',
      },
      nodeProperty: {
        userPreferredType: NODE_TYPE_TEXT,
        lev: 'gradeDB',
      },
      iconProperty: {
        name: 'icon-circle',
        color: defaultDefine.colors.level2,
      },
    }),
    [ICON_CATEGORY_TEXT_C]: getIconConfig({
      title: {
        _default: '关注点C',
        x2: '关注',
      },
      nodeProperty: {
        userPreferredType: NODE_TYPE_TEXT,
        lev: 'gradeDC',
      },
      iconProperty: {
        name: 'icon-circle',
        color: defaultDefine.colors.level0,
      },
    }),
    [ICON_CATEGORY_NO_ICON]: getIconConfig({
      title: {
        _default: '无标记',
        x2: '无标',
      },
      nodeProperty: {
        userPreferredType: NODE_TYPE_TEXT,
        lev: 'noIcon',
      },
      iconProperty: {
        name: 'stop',
        type: IconTypes.ANT_DESIGN,
      },
    }),
    [ICON_CATEGORY_GOOD]: getIconConfig({
      title: '点赞',
      nodeProperty: {
        userPreferredType: NODE_TYPE_TEXT,
        lev: 'good',
      },
      iconProperty: 'icon-good',
    }),
    [ICON_CATEGORY_BAD]: getIconConfig({
      title: '点踩',
      nodeProperty: {
        userPreferredType: NODE_TYPE_TEXT,
        lev: 'bad',
      },
      iconProperty: 'icon-bad',
    }),
    [ICON_CATEGORY_WATCH]: getIconConfig({
      title: '围观',
      nodeProperty: {
        userPreferredType: NODE_TYPE_TEXT,
        lev: 'watch',
      },
      iconProperty: 'icon-watch',
    }),
  };
  // noinspection SpellCheckingInspection
  'ABCDEFGH'.split('').forEach((l, idx) => {
    cfg[`nt0_tip_${l.toLowerCase()}`] = getIconConfig({
      title: {
        _default: `便签${l}`,
        x2: '便签',
      },
      nodeProperty: {
        userPreferredType: NODE_TYPE_TEXT,
        lev: `tip${l}`,
      },
      iconProperty: {
        name: 'icon-tip-solid',
        color: defaultDefine.colors.multipleBackground[idx],
      },
    });
  });
  // noinspection SpellCheckingInspection
  'ABCDEFGH'.split('').forEach((l, idx) => {
    cfg[`nt0_tag_${l.toLowerCase()}`] = getIconConfig({
      title: {
        _default: `标签${l}`,
        x2: '标签',
      },
      nodeProperty: {
        userPreferredType: NODE_TYPE_TEXT,
        lev: `tag${l}`,
      },
      iconProperty: {
        name: 'icon-tag-solid',
        color: defaultDefine.colors.multipleBackground[idx],
      },
    });
  });
  return cfg;
})();

const levToIconTypeMap = (() => {
  let result = {};
  Object.keys(iconConfig).forEach(key => {
    if (iconConfig[key].nodeProperty.lev) {
      result[iconConfig[key].nodeProperty.lev] = key;
    }
  });
  delete result['gradeD'];
  return result;
})();

export const getNodeIconType = ({type, lev, userPreferredType, aiPreferredType, meta}) => {
  // type字段优先级最高
  if (type !== NODE_TYPE_TEXT) {
    return `nt${type}`;
  }
  // meta.iconData和meta.iconMeta判定是否为自定义类型
  if (meta.iconData && meta.iconMeta) {
    return ICON_CATEGORY_CUSTOM;
  }
  // lev字段与userPreferredType、aiPreferredType综合判定
  if (lev === 'gradeD' || lev === null || lev === undefined || lev.startsWith('gradeType')) {
    if (userPreferredType !== undefined && !isNaN(parseInt(userPreferredType))) {
      return `nt${parseInt(userPreferredType)}`;
    } else if (aiPreferredType !== undefined && !isNaN(parseInt(aiPreferredType))) {
      return `nt${parseInt(aiPreferredType)}`;
    } else {
      return ICON_CATEGORY_TEXT;
    }
  } else {
    return levToIconTypeMap[lev] || ICON_CATEGORY_CUSTOM;
  }
};