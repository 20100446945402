import {withPromiseMessageDecorator} from "@/libs/core-decorators";
import messageProxy from '@/components/common/common.messageProxy';
import NodeDataProvider from "@/libs/view/NodeDataProvider";
import {nodeOperationMessages} from "@/constants/messages";

const overrideMessages = {};

/**
 * @type {typeof NodeDataProvider}
 */
const NodeDataProviderWrapper = withPromiseMessageDecorator(
  messageProxy(nodeOperationMessages, overrideMessages)
)(NodeDataProvider);

// noinspection JSUnusedGlobalSymbols
export const overrideNextMessage = (method, msg) => {
  overrideMessages[method] = msg;
};

export default NodeDataProviderWrapper;