import React from 'react';
import PropTypes from 'prop-types';
import {Tabs, Button, Row, Col} from 'antd';

import PB, {SimplePB} from '@/libs/simplePB';

import ViewDataProvider from '@/components/common/dataProvider/common.dataProvider.view';

import {MicroServiceUIConfigOperationItemTypes}
  from '@/components/common/view/microService/shape/common.view.microService.shape.uiConfig';
import MicroServicePanelResultSaveNodes
  from '@/components/common/view/microService/panel/result/common.view.microService.panel.result.saveNodes';
import MicroServicePanelResultSaveEdges
  from '@/components/common/view/microService/panel/result/common.view.microService.panel.result.saveEdges';
import MicroServicePanelResultSaveSubGraphs
  from '@/components/common/view/microService/panel/result/common.view.microService.panel.result.saveSubGraphs';
import MicroServicePanelResultSaveContent
  from '@/components/common/view/microService/panel/result/common.view.microService.panel.result.saveContent';
import MicroServicePanelResultShowWordCloud
  from '@/components/common/view/microService/panel/result/common.view.microService.panel.result.showWordCloud';
import MicroServicePanelResultSaveFiles
  from '@/components/common/view/microService/panel/result/common.view.microService.panel.result.saveFiles';
import MicroServicePanelResultShowCharts
  from '@/components/common/view/microService/panel/result/common.view.microService.panel.result.showCharts';
import MicroServicePanelResultShowImplement
  from '@/components/common/view/microService/panel/result/common.view.microService.panel.result.showImplement';

import style from '@/style/common/microService/common.microService.v2.less';

const getDisplayNum = n => n > 9999 ? '9999+' : n;

const BTN_JSX = {
  saveNodes: (<React.Fragment>生成节点</React.Fragment>),
  saveEdges: (<React.Fragment>建立连接</React.Fragment>),
  saveSubGraphs: (<React.Fragment>生成子图</React.Fragment>),
  saveContent: (<React.Fragment>更新节点</React.Fragment>),
  showWordCloud: (<React.Fragment>展示词云</React.Fragment>),
  saveFiles: (<React.Fragment>下载文件</React.Fragment>),
  showCharts: (<React.Fragment>展示图表</React.Fragment>),
  showOutLink: (<React.Fragment>展示结果</React.Fragment>),
  //showImplement: (<React.Fragment>刷新查看</React.Fragment>),
};

const getResultPanel = (me, type) => {
  switch (type) {
    case 'saveNodes':
      return (
        <MicroServicePanelResultSaveNodes
          locked={me.props.locked}
          autoLoadMore={me.props.autoLoadMore}
          hasMoreResult={me.props.hasMoreResult}
          lastLoadingFailed={me.props.lastLoadingFailed}
          loadingResult={me.props.loadingResult}
          onCheckStatusChanged={(hasCheckedKey) => me.onCheckStatusChanged(type, hasCheckedKey)}
          viewDataProvider={me.props.viewDataProvider}
          bus={me.props.bus}
          microServiceId={me.props.microServiceId}
          viewId={me.props.viewId}
          visible={me.props.visible && me.state.activatedType === type}
        />
      );
    case 'saveEdges':
      return (
        <MicroServicePanelResultSaveEdges
          locked={me.props.locked}
          autoLoadMore={me.props.autoLoadMore}
          hasMoreResult={me.props.hasMoreResult}
          lastLoadingFailed={me.props.lastLoadingFailed}
          loadingResult={me.props.loadingResult}
          onCheckStatusChanged={(hasCheckedKey) => me.onCheckStatusChanged(type, hasCheckedKey)}
          viewDataProvider={me.props.viewDataProvider}
          bus={me.props.bus}
          microServiceId={me.props.microServiceId}
          viewId={me.props.viewId}
          visible={me.props.visible && me.state.activatedType === type}
        />
      );
    case 'saveSubGraphs':
      return (
        <MicroServicePanelResultSaveSubGraphs
          locked={me.props.locked}
          autoLoadMore={me.props.autoLoadMore}
          hasMoreResult={me.props.hasMoreResult}
          lastLoadingFailed={me.props.lastLoadingFailed}
          loadingResult={me.props.loadingResult}
          onCheckStatusChanged={(hasCheckedKey) => me.onCheckStatusChanged(type, hasCheckedKey)}
          viewDataProvider={me.props.viewDataProvider}
          bus={me.props.bus}
          microServiceId={me.props.microServiceId}
          viewId={me.props.viewId}
          visible={me.props.visible && me.state.activatedType === type}
        />
      );
    case 'saveContent':
      return (
        <MicroServicePanelResultSaveContent
          locked={me.props.locked}
          autoLoadMore={me.props.autoLoadMore}
          hasMoreResult={me.props.hasMoreResult}
          lastLoadingFailed={me.props.lastLoadingFailed}
          loadingResult={me.props.loadingResult}
          onCheckStatusChanged={(hasCheckedKey) => me.onCheckStatusChanged(type, hasCheckedKey)}
          viewDataProvider={me.props.viewDataProvider}
          bus={me.props.bus}
          microServiceId={me.props.microServiceId}
          viewId={me.props.viewId}
          visible={me.props.visible && me.state.activatedType === type}
        />
      );
    case 'showWordCloud':
      return (
        <MicroServicePanelResultShowWordCloud
          locked={me.props.locked}
          autoLoadMore={me.props.autoLoadMore}
          hasMoreResult={me.props.hasMoreResult}
          lastLoadingFailed={me.props.lastLoadingFailed}
          loadingResult={me.props.loadingResult}
          onCheckStatusChanged={(hasCheckedKey) => me.onCheckStatusChanged(type, hasCheckedKey)}
          viewDataProvider={me.props.viewDataProvider}
          bus={me.props.bus}
          microServiceId={me.props.microServiceId}
          viewId={me.props.viewId}
          visible={me.props.visible && me.state.activatedType === type}
        />
      );
    case 'saveFiles':
      return (
        <MicroServicePanelResultSaveFiles
          locked={me.props.locked}
          autoLoadMore={me.props.autoLoadMore}
          hasMoreResult={me.props.hasMoreResult}
          lastLoadingFailed={me.props.lastLoadingFailed}
          loadingResult={me.props.loadingResult}
          onCheckStatusChanged={(hasCheckedKey) => me.onCheckStatusChanged(type, hasCheckedKey)}
          viewDataProvider={me.props.viewDataProvider}
          bus={me.props.bus}
          microServiceId={me.props.microServiceId}
          viewId={me.props.viewId}
          visible={me.props.visible && me.state.activatedType === type}
        />
      );
    case 'showCharts':
      return (
        <MicroServicePanelResultShowCharts
          locked={me.props.locked}
          autoLoadMore={me.props.autoLoadMore}
          hasMoreResult={me.props.hasMoreResult}
          lastLoadingFailed={me.props.lastLoadingFailed}
          loadingResult={me.props.loadingResult}
          onCheckStatusChanged={(hasCheckedKey) => me.onCheckStatusChanged(type, hasCheckedKey)}
          viewDataProvider={me.props.viewDataProvider}
          bus={me.props.bus}
          microServiceId={me.props.microServiceId}
          viewId={me.props.viewId}
          visible={me.props.visible && me.state.activatedType === type}
        />
      );
    case 'showImplement':
      return (
        <MicroServicePanelResultShowImplement
          locked={me.props.locked}
          autoLoadMore={me.props.autoLoadMore}
          hasMoreResult={me.props.hasMoreResult}
          lastLoadingFailed={me.props.lastLoadingFailed}
          loadingResult={me.props.loadingResult}
          onCheckStatusChanged={(hasCheckedKey) => me.onCheckStatusChanged(type, hasCheckedKey)}
          viewDataProvider={me.props.viewDataProvider}
          bus={me.props.bus}
          microServiceId={me.props.microServiceId}
          viewId={me.props.viewId}
          visible={me.props.visible && me.state.activatedType === type}
        />
      );
    default:
      return null;
  }
};

const TAB_TITLES = {
  saveNodes: '节点',
  saveEdges: '连接',
  saveSubGraphs: '子图',
  saveContent: '内容',
  showWordCloud: '词云',
  saveFiles: '文件',
  showCharts: '图表',
  showOutLink: '结果',
  showImplement: '执行结果',
};

class MicroServicePanelResult extends React.PureComponent {
  state = {
    activatedType: undefined,
  };

  hasCheckedKeyMap = {};

  onCheckStatusChanged = (type, hasCheckedKey) => {
    let me = this;
    me.hasCheckedKeyMap[type] = hasCheckedKey;

    if (type === me.state.activatedType) {
      return me.props.onCheckStatusChanged(hasCheckedKey);
    }
  };

  componentDidMount() {
    let me = this;
    requestAnimationFrame(() => me.setState({activatedType: me.props.operations[0].type}));
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let me = this;

    if (prevProps.operations !== me.props.operations) {
      requestAnimationFrame(() => me.setState({activatedType: me.props.operations[0].type}));
    }
  }

  render() {
    let me = this;
    let mainButton,mainContent,setlineButton,statisticsByType = me.props.statisticsByType;

    if (BTN_JSX[me.props.operationType]) {
      mainButton = (
        <Button
          onClick={me.props.onMainActionClicked}
          disabled={me.props.mainActionDisabled && me.props.operationType!=='showCharts' && me.props.operationType!=='showOutLink' }
          type={'primary'}
          block={true}
        >
          {BTN_JSX[me.props.operationType]}
        </Button>
      );
    }
    /*setlineButton = ['saveNodes','saveEdges','saveSubGraphs'].includes(me.props.operationType) ? (
      <Tooltip
            placement={"top"}
            overlayClassName={'light-theme'}
            title={(
              <>
                <Form.Item labelCol={{span: 8}} wrapperCol={{span: 16}} label={'连线可见'} style={{marginBottom: 0}}>
                  <Radio.Group
                    value={(me.state.userOperationConfig.lineType || 'auto')}
                    size={'small'}
                    onChange={e => {
                      e.stopPropagation();
                      me.setState({
                        userOperationConfig: {
                          ...me.state.userOperationConfig,
                          lineType: e.target.value,
                        },
                      }, () => {
                        me.props.bus.emit('view', 'micro_service.service.operation_save_edges.set_operation_config', {
                          viewId: me.props.viewId,
                          serviceId: me.props.microServiceId,
                          path: 'lineType',
                          config: e.target.value,
                        });
                      })
                    }}
                  >
                    <Radio.Button value={'auto'}>自动</Radio.Button>
                    <Radio.Button value={'dash'}>隐藏</Radio.Button>
                    <Radio.Button value={'solid'}>显示</Radio.Button>
                  </Radio.Group>
                </Form.Item>
                <Form.Item labelCol={{span: 8}} wrapperCol={{span: 16}} label={'连线样式'} style={{marginBottom: 0}}>
                  <Radio.Group
                    value={(me.state.userOperationConfig.lineStyle || 'auto')}
                    size={'small'}
                    onChange={e => {
                      e.stopPropagation();
                      me.setState({
                        userOperationConfig:{
                          ...me.state.userOperationConfig,
                          lineStyle: e.target.value,
                        },
                      }, () => {
                        me.props.bus.emit('view', 'micro_service.service.operation_save_edges.set_operation_config', {
                          viewId: me.props.viewId,
                          serviceId: me.props.microServiceId,
                          path: 'lineStyle',
                          config: e.target.value,
                        });
                      })
                    }}
                  >
                    <Radio.Button value={'auto'}>自动</Radio.Button>
                    <Radio.Button value={'straight'}>直线</Radio.Button>
                    <Radio.Button value={'curve'}>曲线</Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </>
            )}
          >
            <a className={`a-ctrl`} onClick={e => e.stopPropagation()}>
              <Icon name={'setting'}/>
            </a>
          </Tooltip>
    ):null
    */
    if (!me.props.operations) {
      mainContent = null;
    } else if (me.props.operations.length === 1) {
      mainContent = (
        <div className={style['result-frame']}>
          {getResultPanel(me, me.props.operations[0].type)}
        </div>
      );
    } else {
      mainContent = (
        <Tabs
          className={style['result-frame']}
          activeKey={me.state.activatedType}
          type={'card'}
          tabBarStyle={{marginBottom: '-1px', borderBottomColor: 'transparent'}}
          onChange={activatedType => {
            me.setState({activatedType});
            me.props.onCheckStatusChanged(me.hasCheckedKeyMap[activatedType] === true);
            me.props.onOperationChanged(activatedType);
          }}
        >
          {me.props.operations.map(({type}) => (
            <Tabs.TabPane key={type} tab={TAB_TITLES[type]} forceRender={true} >
              {getResultPanel(me, type)}
            </Tabs.TabPane>
          ))}
        </Tabs>
      )
    }
    return (
      <React.Fragment>
        <div className={style['market-drawer-header']} style={{borderBottom:0,padding:0}}>
          <div className={style['market-drawer-title']}>输出结果</div>
          <div className={style['market-drawer-right']} style={{flex:'none',paddingRight:0}}>
            {mainButton}
          </div>
        </div>
        {statisticsByType && me.props.locked && ['saveNodes','saveEdges','saveSubGraphs'].includes(me.props.operationType) || me.props.microServiceInfo.dataType===1 &&
        <div style={{marginBottom: '0.6rem'}}>
          <Row justify="start">
            <Col span={4}>增加：{statisticsByType['add'] ? getDisplayNum(statisticsByType['add'].saved + statisticsByType['add'].selected) : 0}/{statisticsByType['add'] ? getDisplayNum(statisticsByType['add'].total) : 0}</Col>
            <Col span={4}>修改：{statisticsByType['modify'] ? getDisplayNum(statisticsByType['modify'].saved + statisticsByType['modify'].selected) : 0}/{statisticsByType['modify'] ? getDisplayNum(statisticsByType['modify'].total) : 0}</Col>
            <Col span={4}>删除：{statisticsByType['remove'] ? getDisplayNum(statisticsByType['remove'].saved + statisticsByType['remove'].selected) : 0}/{statisticsByType['remove'] ? getDisplayNum(statisticsByType['remove'].total) : 0}</Col>
            <Col span={4}>连接：{statisticsByType['connect'] ? getDisplayNum(statisticsByType['connect'].saved + statisticsByType['connect'].selected) : 0}/{statisticsByType['connect'] ? getDisplayNum(statisticsByType['connect'].total) : 0}</Col>
          </Row>
        </div>
        }
        {me.props.microServiceInfo.calcServerId && me.props.microServiceInfo.calcServerPathId &&
        <div className={style['wizard-calc']}>
          <div className={style['wizard-calc-result']}>
            {mainContent}              
          </div>
        </div>
        }
        {me.props.microServiceInfo.dataType===1 &&
        <div className={style['wizard-calc']}>
          <div className={style['wizard-calc-result']}>
            {mainContent}              
          </div>
        </div>
        }
      </React.Fragment>
    )
  }
}

MicroServicePanelResult.defaultTypes = {
  bus: PB,
};

MicroServicePanelResult.propTypes = {
  operations: PropTypes.arrayOf(PropTypes.oneOfType(MicroServiceUIConfigOperationItemTypes)).isRequired,
  locked: PropTypes.bool.isRequired,
  autoLoadMore: PropTypes.bool.isRequired,
  hasMoreResult: PropTypes.bool.isRequired,
  lastLoadingFailed: PropTypes.bool.isRequired,
  loadingResult: PropTypes.bool.isRequired,
  onCheckStatusChanged: PropTypes.func.isRequired,
  onOperationChanged: PropTypes.func.isRequired,
  viewDataProvider: PropTypes.instanceOf(ViewDataProvider).isRequired,
  bus: PropTypes.instanceOf(SimplePB),
  microServiceId: PropTypes.string,
  microServiceInfo: PropTypes.object.isRequired,
  viewId: PropTypes.string,
  visible: PropTypes.bool,
  onMainActionClicked: PropTypes.func.isRequired,
  statisticsByType: PropTypes.objectOf(PropTypes.shape({
    total: PropTypes.number.isRequired,
    idle: PropTypes.number,
    selected: PropTypes.number,
    saved: PropTypes.number,
    invalid: PropTypes.number,
  })),
};

export default MicroServicePanelResult;