import React from 'react';
import PropTypes from 'prop-types';
import {Input, Modal, Steps} from 'antd';

import PB, {SimplePB} from '@/libs/simplePB';

import {IconTypes} from '@/constants/common';

import ViewDataProvider from '@/components/common/dataProvider/common.dataProvider.view';
import Icon from '@/components/common/common.icon';
import UserDataProvider from '@/components/common/dataProvider/common.dataProvider.user';
import {showErrorMessage} from '@/components/common/common.message';

import style from "@/style/common/view/common.view.rank.less";

class ExploreCustomRequestModal extends React.Component {
  state = {
    customRequest: '',
  };

  customMessageKey = undefined;

  onRequestCustomExplore = () => {
    let me = this, feedback = `定制发现，图谱：${me.props.visible ? me.props.viewDataProvider.viewInfo.name : ''}(${me.props.visible ? me.props.viewDataProvider.viewId : ''})，定制需求：${me.state.customRequest}`;

    me.props.bus.emit('aiConsole', 'message.update', {
      key: me.customMessageKey,
      content: (
        <span><Icon name={'loading'} style={{marginRight: '0.5em'}} />定制发现需求正在提交，请稍后...</span>
      ),
    });
    UserDataProvider.sendFeedback(feedback).then(() => {
      me.props.bus.emit('aiConsole', 'message.update',
        {key: me.customMessageKey, content: '定制发现需求已提交，稍后会有客服人员与你联系。'});
      me.customMessageKey = undefined;
      me.props.onClose();
    }).catch(({code, msg}) => {
      showErrorMessage({ code, msg, extra: { viewId: me.state.viewId, isModification: false } });
      me.props.bus.emit('aiConsole', 'message.update', {
        type: 'ai',
        content: (<span><Icon name={'loading'} style={{marginRight: '0.5em'}} />等待用户提供更多信息</span>),
        key: me.customMessageKey,
      });
    });
  };

  emitAiConsoleMessage = () => {
    let me = this;

    me.props.bus.emit('aiConsole', 'message.push', {
      type: 'ai',
      content: (<span><Icon name={'loading'} style={{marginRight: '0.5em'}} />等待用户提供更多信息</span>),
      callback: ({key}) => me.customMessageKey = key,
      delay: 200,
    });
  };

  componentDidMount() {
    let me = this;

    if (me.props.visible) {
      // 触发AI对话框消息
      me.emitAiConsoleMessage();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let me = this;

    if ((!prevProps.visible && me.props.visible) ||
      (me.props.visible && me.props.type !== prevProps.type && me.props.type)) {
      // 触发AI对话框消息
      me.emitAiConsoleMessage();
    }
  }

  render() {
    let me = this;

    return (
      <Modal
        width={'38rem'}
        title={(
          <span>
            定制发现 - {me.props.visible ? me.props.viewDataProvider.viewInfo.name : '没数据'}
          </span>
        )}
        visible={me.props.visible}
        onOk={me.onRequestCustomExplore}
        onCancel={() => {
          me.props.bus.emit('aiConsole', 'message.update',
            {key: me.customMessageKey, content: `用户取消操作`});
          me.props.onClose();
        }}
        okText={'提交'}
        cancelText={'取消'}
        afterClose={() => me.setState({customRequest: ''})}
      >
        <div>
          <Steps direction="vertical" current={0} className={style['rank-list-custom-steps']}>
            <Steps.Step
              title="请告诉我们您的需求"
              description={(
                <Input.TextArea
                  rows={4}
                  value={me.state.customRequest}
                  onChange={({target: {value}}) => me.setState({customRequest: value})}
                />
              )}
            />
            <Steps.Step title="我们将为您提供专属定制方案" status={'process'} />
            <Steps.Step title="我们会持续优化定制发现" status={'process'} />
          </Steps>
          <div style={{opacity: 0.6}}>
            <Icon type={IconTypes.ICON_FONT} name={'icon-email-open'} style={{marginRight: '0.5em'}} />
            提交后我们会尽快与您邮件联系。
          </div>
        </div>
      </Modal>
    );
  }
}

ExploreCustomRequestModal.defaultProps = {
  bus: PB,
};

ExploreCustomRequestModal.propTypes = {
  bus: PropTypes.instanceOf(SimplePB),
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  viewDataProvider: PropTypes.instanceOf(ViewDataProvider).isRequired,
};

export default ExploreCustomRequestModal;