/*
 * @Author: Carr.s
 * @Date: 2022-04-18 18:09:32
 * @LastEditTime: 2022-06-06 17:28:54
 * @LastEditors: Carr.s
 * @Description: 洞察模板 vis network 图
 * @Copyright by joinmap
 */
import React from "react";
import axios from "axios";
import { Checkbox, Empty, message, Spin, Tooltip, Button } from "antd";
import relationStyle from "@/style/components/main.relation.less";
// import styles from "@/style/components/insightView/insight.less";
import styles from "@/style/components/insightView/insight.vis.less";
import Icon from "@/components/common/common.icon";
import { IconTypes } from "@/constants/common";
import { getHttpUtil } from "@/utils/HttpUtil";
import MyNetwork, { myVisNetworkStyle } from "@/libs/myVis/MyNetwork3";
// --------------------------------------------------------------------
// 接口返回值样例
// --------------------------------------------------------------------
// 有特殊字段的另商
const api_response_data_example = {
  code: "必须",
  msg: "必须",
  data: {
    data: [
      {
        name: "必须，没有值时传空值=None",
        value: "必须，没有值时传空值=None",
        valueUnit: "必须，没有值时传空值=None",
        xxx: [
          // 同父级
        ],
        xxxTotal: "必须，没有值时传空值，与xxx字段对应",
        yyy: [
          // 同父级
        ],
        yyyTotal: "必须，没有值时传空值，与yyy字段对应",
      },
    ],
    dataTotal: "必须，没有值时传空值=None",
    dataUnit: "必须，没有值时传空值=None",
  },
};

// --------------------------------------------------------------------
// 组件
// --------------------------------------------------------------------
export default class ViewInsightVisNetworkTpl extends React.Component {
  state = {
    // ------------------------------------------------------------
  };

  // 洞察名称
  _viewerTitle = "洞察模板";
  /**
   * 标题渲染
   * 兼容已经做好的洞察页面
   * @returns
   */
  _viewerTitleRender = () => {
    return this._viewerTitle + "洞察";
  };

  /**
   * vis 关系图 MyNetwork3 实例
   */
  _myNetwork = undefined;

  /**
   * 【继承组件要重写】
   * 刷新 vis network
   */
  _refreshNetwork = () => {};

  // --------------------------------------------------------------------
  // 微服务接口
  // --------------------------------------------------------------------
  _API_MicroServer = (
    url,
    query = {},
    data = {},
    method = "get",
    isDebug = false,
    isDev = false
  ) => {
    if (isDebug) console.log("_API_MicroServer url= ", url);
    if (isDebug) console.log("_API_MicroServer query= ", query);
    const service = axios.create({
      timeout: 1500000, // 超时时间 150秒
      // timeout: 40000, // 超时时间 40秒
      headers: {
        "x-requested-with": "XMLHttpRequest",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Max-Age": 0,
      },
    });
    return service({ method: method, url: url, data: data, params: query });
  };

  // --------------------------------------------------------------------
  // vis
  // --------------------------------------------------------------------

  /**
   * 自定义事件
   * @returns object
   */
  myEvents = () => {
    let me = this;
    return {
      click: (params) => {
        me._myNetwork.network.stopSimulation();
      },
      stabilized: (params) => {
        console.log(
          "🚀 ~ file: insight.vis.tpl.js ~ line 110 ~ ViewInsightVisNetworkTpl ~ stabilized params",
          params
        );
      },
      nearlyStabilized: (params) => {
        console.log(
          "🚀 ~ file: insight.vis.tpl.js ~ line 113 ~ ViewInsightVisNetworkTpl ~ stabilized params",
          params
        );
        me._myNetwork.network.stopSimulation();
      },
    };
  };

  componentDidMount() {
    // 画图
    let me = this,
      networkOptions = myVisNetworkStyle.options();
    networkOptions.physics.enabled = false; // 强制设置，防止出错
    me.container = document.getElementById("network");
    networkOptions.physics.enabled = true; // 强制设置，防止出错
    networkOptions.physics.solver = "forceAtlas2Based";
    networkOptions.physics.forceAtlas2Based.springLength = 500;

    me._myNetwork = new MyNetwork(
      me.container,
      { nodes: [], edges: [] },
      networkOptions
    );

    this._myNetwork.bindEvent(me.myEvents());

    // 加载数据
    me._refreshNetwork();
  }

  // ============================================================================================

  render() {
    return (
      <>
        <div className={relationStyle["view-title"]} style={{ zIndex: 99999 }}>
          {this._viewerTitleRender()}
        </div>
        <div id="network" className={styles["result-network"]} />
        <div className={styles["network-legend"]}>
          <div className={styles["legend-item"]}>
            <span
              style={{ backgroundColor: "yellow" }}
              className={styles["icon-dot"]}
            ></span>
            <span className={styles["legend-text"]}>高新科技企业</span>
          </div>
          <div className={styles["legend-item"]}>
            <span
              style={{ backgroundColor: "green" }}
              className={styles["icon-dot"]}
            ></span>
            <span className={styles["legend-text"]}>专精特新企业</span>
          </div>
          <div className={styles["legend-item"]}>
            <span
              style={{ backgroundColor: "red" }}
              className={styles["icon-dot"]}
            ></span>
            <span className={styles["legend-text"]}>上市企业</span>
          </div>
        </div>
      </>
    );
  }
}
