import _ from "lodash";

const getPathStructure = (cost = Infinity, edgeId = false) => ({
  weight: cost,
  pathList: edgeId ? [[edgeId]] : [],
});

const DefaultCalcWeightFn = (edge) => {
  return edge.weight || 1;
};

export const splitNodeGroups = (nodes, edges) => {
  let nextTmpEdges = edges.map(edge => edge);
  const nodeMap = {};
  nodes.forEach(node => nodeMap[node.id] = node);

  const nodeGroups = [];
  let nodeIds = Object.keys(nodeMap);
  while (nodeIds.length > 0) {
    // 取第一个节点作为起始点
    let currentRootNodeId = nodeIds.pop();
    // delete nodeMap[currentRootNodeId];
    let nodeGroupIdx = nodeGroups.length;
    nodeGroups[nodeGroupIdx] = {nodes: [], edges: []};

    let currentNodeIdSet = {};
    currentNodeIdSet[currentRootNodeId] = false;
    let currentNodeIds = [];
    let nextNodeIds = [currentRootNodeId];

    // 当有待处理的节点时
    while (nextNodeIds.length > 0) {
      // 将待处理节点放到当前节点列表中，将待处理节点置空
      currentNodeIds = nextNodeIds;
      nextNodeIds = [];

      // 处理当前节点列表
      while (currentNodeIds.length > 0) {
        let currentNodeId = currentNodeIds.pop();
        currentNodeIdSet[currentNodeId] = true;
        if (!nodeMap[currentNodeId]) { // 容错：节点不存在
          console.warn(`没有找到ID ${currentNodeId} 对应的节点`);
          continue;
        }
        nodeGroups[nodeGroupIdx].nodes.push(nodeMap[currentNodeId]);
        delete nodeMap[currentNodeId];

        // 将待处理边放置到当前处理边数组中，将待处理数组置空
        let currentTmpEdges = nextTmpEdges;
        nextTmpEdges = [];

        // 循环当前处理的每一条边
        while (currentTmpEdges.length > 0) {
          let currentEdge = currentTmpEdges.pop();

          if (currentEdge.from === currentNodeId) {
            // 如果边的起点为当前节点，将边加入结果集中
            nodeGroups[nodeGroupIdx].edges.push(currentEdge);

            // 如果边的终点不在节点集合内，则加入节点集合
            if (currentNodeIdSet[currentEdge.to] === undefined) {
              currentNodeIdSet[currentEdge.to] = false;
              nextNodeIds.push(currentEdge.to);
            }
          } else if (currentEdge.to === currentNodeId) {
            // 如果边的终点为当前节点，将边加入结果集中
            nodeGroups[nodeGroupIdx].edges.push(currentEdge);

            // 如果边的终点不在节点集合内，则加入节点集合
            if (currentNodeIdSet[currentEdge.from] === undefined) {
              currentNodeIdSet[currentEdge.from] = false;
              nextNodeIds.push(currentEdge.from);
            }
          } else {
            // 其他情况，将边置入待处理数组中
            nextTmpEdges.push(currentEdge);
          }
        }
        // 当前处理的边循环结束
      }
      // 当前处理的节点循环结束
    }
    // 以当前节点作为起始点的子图处理完毕，重新获取未处理节点ID列表
    nodeIds = Object.keys(nodeMap);
  }
  // 所有节点均处理完毕，返回

  return nodeGroups;
};

export const getNodeConnectionMap = (nodes, edges, visibleEdgesOnly = false, checkLocateOnly = true,
                                     nodeConnectionsMapCache, allNodeMapCache) => {
  let nodeConnectionsMap = nodeConnectionsMapCache || {}, allNodeMap = allNodeMapCache || {}, nodeConnectionsMapEx = {};

  if (!nodeConnectionsMapCache) {
    edges.forEach(edge => {
      if (edge.from && edge.to && (edge.visible !== false || !visibleEdgesOnly)) {
        if (!nodeConnectionsMap[edge.from]) nodeConnectionsMap[edge.from] = [];
        if (!nodeConnectionsMap[edge.to]) nodeConnectionsMap[edge.to] = [];
        nodeConnectionsMap[edge.from].push(edge.to);
        nodeConnectionsMap[edge.to].push(edge.from);
      }
    });
  }

  if (!checkLocateOnly) {
    return nodeConnectionsMap;
  }

  if (!allNodeMapCache) {
    nodes.forEach(node => allNodeMap[node.id] = node);
  }

  let extendedEdges = {};
  nodes.forEach(node => {
    if (node._locateOnly && !extendedEdges[node.id]) {
      let groupNodeIds = [], proceedingNodeIds = [node.id], proceededNodeMap = {};
      while (proceedingNodeIds.length > 0) {
        let nodeId = proceedingNodeIds.shift();
        if (!proceededNodeMap[nodeId]) {
          proceededNodeMap[nodeId] = true;
          groupNodeIds.push(nodeId);
          if (nodeConnectionsMap[nodeId] && allNodeMap[nodeId] && allNodeMap[nodeId]._locateOnly) {
            nodeConnectionsMap[nodeId].forEach(id => proceedingNodeIds.push(id));
          }
        }
      }
      groupNodeIds.forEach(nodeId => {
        if (allNodeMap[nodeId] && allNodeMap[nodeId]._locateOnly) {
          extendedEdges[nodeId] = groupNodeIds;
        }
      });
    }
  });
  nodes.forEach(node => {
    if (nodeConnectionsMap[node.id]) {
      let exConnectedNodeIds = [...nodeConnectionsMap[node.id]];
      nodeConnectionsMap[node.id].forEach(nodeId => {
        if (extendedEdges[nodeId]) {
          exConnectedNodeIds.push.apply(exConnectedNodeIds, extendedEdges[nodeId]);
        }
      });
      nodeConnectionsMapEx[node.id] = _.uniq(exConnectedNodeIds);
    }
  });

  return nodeConnectionsMapEx;
}

/**
 *
 * @param nodes
 * @param edges
 * @param [onProgress]
 * @param [onSuccess]
 * @param [delay]
 * @param [nodeConnectionsMapCache]
 * @param [individualNodeMapCache]
 * @param [allNodeMapCache]
 */
export const getNodeLevelInGraph = (nodes, edges, {onProgress = () => {}, onSuccess = () => {}}, delay = 0,
                                    nodeConnectionsMapCache, individualNodeMapCache, allNodeMapCache) => {
  let locateOnlyNodeIds = [];

  if (!nodeConnectionsMapCache) {
    nodeConnectionsMapCache = getNodeConnectionMap(nodes, edges);
  }

  if (!individualNodeMapCache) {
    individualNodeMapCache = {};
    allNodeMapCache = {};
    nodes.forEach(n => {
      allNodeMapCache[n.id] = n;
      if (!nodeConnectionsMapCache[n.id]) {
        individualNodeMapCache[n.id] = n;
      }
    });
  }

  if (!allNodeMapCache) {
    allNodeMapCache = {};
    nodes.forEach(node => allNodeMapCache[node.id] = node);
  }

  let connectionInfoByAmountMap = {}, nodeGroupToProcess, nodeLevelMap = {}, currentRoundNodeLevel = {}, loopFn,
    globalMaxLevel = 1;

  Object.keys(individualNodeMapCache).forEach(nodeId => {
    nodeLevelMap[nodeId] = 1;
    currentRoundNodeLevel[nodeId] = 1;
  });

  Object.keys(nodeConnectionsMapCache).forEach(nodeId => {
    if (!allNodeMapCache[nodeId]) {
      return;
    }
    let connectionAmount = nodeConnectionsMapCache[nodeId].length;
    if (connectionAmount === 1) {
      nodeLevelMap[nodeId] = 1;
      currentRoundNodeLevel[nodeId] = 1;
    } else if (allNodeMapCache[nodeId]._locateOnly) {
      locateOnlyNodeIds.push(nodeId);
    } else {
      connectionInfoByAmountMap[`c-${connectionAmount}`] = connectionInfoByAmountMap[`c-${connectionAmount}`] || {
        amount: connectionAmount,
        nodeIds: [],
      };
      connectionInfoByAmountMap[`c-${connectionAmount}`].nodeIds.push(nodeId);
    }
  });

  nodeGroupToProcess = Object.values(connectionInfoByAmountMap).sort((a, b) => a.amount - b.amount);

  loopFn = () => {
    currentRoundNodeLevel = {};
    let currentNodeGroup = nodeGroupToProcess.shift(), currentNodeIdsToProcess = currentNodeGroup.nodeIds,
      currentConnectionAmount = currentNodeGroup.amount, nodesBeforeProcess = currentNodeIdsToProcess.length,
      nodeIdsDelayed = [];

    while (currentNodeIdsToProcess.length > 0) {
      let currentNodeId = currentNodeIdsToProcess.shift(), connectedNodeIds = nodeConnectionsMapCache[currentNodeId],
        maxLevels = [0, 0];

      connectedNodeIds.find(nodeId => {
        if (allNodeMapCache[nodeId]._locateOnly) {
          return false;
        }
        let nodeLevel = nodeLevelMap[nodeId] ? nodeLevelMap[nodeId] : Infinity;
        if (maxLevels[0] <= nodeLevel) {
          maxLevels[1] = maxLevels[0];
          maxLevels[0] = nodeLevel;
        } else if (maxLevels[1] <= nodeLevel) {
          maxLevels[1] = nodeLevel;
        }

        return maxLevels[1] === Infinity;
      });

      if (maxLevels[1] === Infinity) {
        // 本轮无法确定节点层级
        nodeIdsDelayed.push(currentNodeId);
      } else {
        // 本轮已确定节点层级
        nodeLevelMap[currentNodeId] = 1 + maxLevels[1];
        currentRoundNodeLevel[currentNodeId] = nodeLevelMap[currentNodeId];
        globalMaxLevel = Math.max(globalMaxLevel, nodeLevelMap[currentNodeId]);
      }
    }

    if (nodeGroupToProcess.length > 0 || nodesBeforeProcess > nodeIdsDelayed.length) {
      // 有新节点被处理
      nodeGroupToProcess[0] = nodeGroupToProcess[0] || {
        amount: currentConnectionAmount,
        nodeIds: [],
      };
      nodeGroupToProcess[0].nodeIds = (nodeGroupToProcess[0].nodeIds || []).concat(nodeIdsDelayed);
      onProgress({nodeLevel: currentRoundNodeLevel});
      setTimeout(loopFn, delay);
    } else {
      // 没有新节点被处理
      nodeIdsDelayed.forEach(nodeId => {
        nodeLevelMap[nodeId] = 1 + globalMaxLevel;
        currentRoundNodeLevel[nodeId] = nodeLevelMap[nodeId];
      });
      // 处理定位专用点
      locateOnlyNodeIds.forEach(nodeId => {
        nodeLevelMap[nodeId] = nodeConnectionsMapCache[nodeId].reduce((maxLevel, id) => {
          if (!allNodeMapCache[id]._locateOnly) {
            return Math.min(nodeLevelMap[id], maxLevel);
          } else {
            return maxLevel;
          }
        }, Infinity);
        currentRoundNodeLevel[nodeId] = nodeLevelMap[nodeId];
      });
      onProgress({nodeLevel: currentRoundNodeLevel});
      onSuccess({nodeLevel: nodeLevelMap});
    }
  };

  setTimeout(() => {
    onProgress({nodeLevel: currentRoundNodeLevel});

    requestAnimationFrame(loopFn);
  }, delay);
};

export const getShortestPath = (nodes, edges, hasDirection = true, calcWeightFn = DefaultCalcWeightFn) => {
  const result = {};
  const nodeIds = nodes.map(node => node.id);
  const allNodes = {};

  nodes.forEach(node => {
    result[node.id] = {};
    allNodes[node.id] = node;
    nodes.forEach(insideNode => {
      result[node.id][insideNode.id] = getPathStructure();
    });
  });

  edges = edges.filter(edge => result[edge.from] && result[edge.to]);

  nodes.forEach(node => {
    const asFromNodeEdges = edges.filter(edge => edge.from === node.id);
    const asToNodeEdges = hasDirection ? [] : edges.filter(edge => edge.to === node.id);
    asFromNodeEdges.forEach(edge => {
      result[node.id][edge.to].weight = calcWeightFn(edge, [node, allNodes[edge.to]]);
      result[node.id][edge.to].pathList = [[edge.id]];
    });
    asToNodeEdges.forEach(edge => {
      result[node.id][edge.from].weight = calcWeightFn(edge, [node, allNodes[edge.from]]);
      result[node.id][edge.from].pathList = [[edge.id]];
    });
  });

  for (let k = 0; k < nodeIds.length; k++) {
    for (let i = 0; i < nodeIds.length; i++) {
      for (let j = 0; j < nodeIds.length; j++) {
        if (result[nodeIds[i]][nodeIds[j]].weight >=
          (result[nodeIds[i]][nodeIds[k]].weight + result[nodeIds[k]][nodeIds[j]].weight)) {

          const pathIKList = result[nodeIds[i]][nodeIds[k]].pathList;
          const pathKJList = result[nodeIds[k]][nodeIds[j]].pathList;
          const pathIJList =
            (
              result[nodeIds[i]][nodeIds[j]].weight ===
              (result[nodeIds[i]][nodeIds[k]].weight + result[nodeIds[k]][nodeIds[j]].weight)
            ) ? result[nodeIds[i]][nodeIds[j]].pathList : [];
          for (let pI = 0; pI < pathIKList.length; pI++) {
            for (let pJ = 0; pJ < pathKJList.length; pJ++) {
              pathIJList.push(pathIKList[pI].concat(pathKJList[pJ]));
            }
          }
          result[nodeIds[i]][nodeIds[j]] = {
            weight: result[nodeIds[i]][nodeIds[k]].weight + result[nodeIds[k]][nodeIds[j]].weight,
            pathList: pathIJList,
          }
        }
      }
    }
  }

  return result;
};

const calcEdgeParticipation = (nodes, edges, nodeIds, hasDirection = true, shortestPathResult) => {
  const resultOut = {};
  const resultIn = {};
  const allEdges = {};
  edges.forEach(edge => allEdges[edge.id] = edge);

  nodes.forEach(node => {
    resultOut[node.id] = {};
    nodes.forEach(insideNode => {
      resultOut[node.id][insideNode.id] = {
        targets: 0, // 节点node的出度
        i: 0.0, // 边(node.id, insideNode.id)的出参与度
      };
      if (hasDirection) {
        resultIn[node.id][insideNode.id] = {
          targets: 0, // 节点insideNode的入度
          i: 0.0, // 边(node.id, insideNode.id)的入参与度
        };
      }
    });
  });

  for (let i = 0; i < nodeIds.length; i++) {
    let edgesOut = {}; // i(start) => j
    let edgesIn = {}; // j => i(end)
    for (let j = 0; j < nodeIds.length; j++) {

      let pathInfoOut = shortestPathResult[nodeIds[i]][nodeIds[j]];
      for (let k = 0; k < pathInfoOut.pathList.length; k++) {
        let pathList = pathInfoOut.pathList[k];
        let edgeOutId = pathList[0];
        let anotherNodeId =
          (allEdges[edgeOutId].from === nodeIds[i] ? allEdges[edgeOutId].to : allEdges[edgeOutId].from);
        edgesOut[edgeOutId] = edgesOut[edgeOutId] || {
          targets: {},
          anotherNodeId,
          i: 0.0,
        };
        edgesOut[edgeOutId].targets[anotherNodeId] = true;
        edgesOut[edgeOutId].i += (1 / pathInfoOut.pathList.length);
      }

      if (hasDirection) {
        let pathInfoIn = shortestPathResult[nodeIds[j]][nodeIds[i]];
        for (let k = 0; k < pathInfoIn.pathList.length; k++) {
          let pathList = pathInfoIn.pathList[k];
          let edgeInId = pathList[pathList.length - 1];
          let anotherNodeId =
            (allEdges[edgeInId].from === nodeIds[i] ? allEdges[edgeInId].to : allEdges[edgeInId].from);
          edgesIn[edgeInId] = edgesIn[edgeInId] || {
            targets: {},
            anotherNodeId,
            i: 0.0,
          };
          edgesIn[edgeInId].targets[anotherNodeId] = true;
          edgesIn[edgeInId].i += (1 / pathInfoOut.pathList.length);
        }
      }
    }

    Object.keys(edgesOut).forEach(edgesOutId => {
      resultOut[nodeIds[i]][edgesOut[edgesOutId].anotherNodeId] = {
        targets: Object.keys(edgesOut[edgesOutId].targets).length,
        i: edgesOut[edgesOutId].i / Object.keys(edgesOut[edgesOutId].targets).length,
      };
    });

    if (hasDirection) {
      Object.keys(edgesIn).forEach(edgesInId => {
        resultIn[edgesIn[edgesInId].anotherNodeId][nodeIds[i]] = {
          targets: Object.keys(edgesIn[edgesInId].targets).length,
          i: edgesIn[edgesInId].i / Object.keys(edgesIn[edgesInId].targets).length,
        };
      });
    }
  }

  return [resultOut, resultIn];
};