import React from 'react';
import PropTypes from 'prop-types';
import {Button, Form, Icon, Input, Progress} from 'antd';

import AuthForm from '@/components/common/common.authForm';

import style from '@/style/components/common.authForm.less';
import checkPwdStrength from "@/utils/PwdStrengthChecker";

const StrengthColors = ['#f5222d', '#faad14', '#52c41a'];

class NormalResetPwdForm extends React.Component {
  state = {
    confirmDirty: false,
    strengthColor: StrengthColors[0],
  };

  handleConfirmBlur = (e) => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('password')) {
      callback('两次输入的密码不一致');
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  };

  handleResetPwd = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onResetPwd(values.password)
      }
    });
  };

  render () {
    let me = this;
    const {getFieldDecorator} = this.props.form;
    // noinspection RequiredAttributes
    return (
      <AuthForm>
        <Form onSubmit={this.handleResetPwd}>
          <h1 className={style['form-title']}>重置密码</h1>
          <div className={style['form-item']} style={{marginBottom: '1em'}}>
            尊敬的用户，请输入您的新密码。<br />
            新密码保存后原密码将立即失效，请用新密码登录。
          </div>
          <Form.Item className={style['form-item']}>
            {getFieldDecorator('password', {
              rules: [{
                required: true,
                message: '请输入密码',
              }, {
                validator: this.validateToNextPassword,
              }],
            })(
              <div>
                <Input prefix={<Icon type="lock"/>}
                       placeholder="请输入新密码"
                       type="password" />
                <Progress percent={checkPwdStrength(this.props.form.getFieldValue('password'))}
                          strokeColor={me.state.strengthColor}
                          format={percent => {
                            if (percent < 35) {
                              if (me.state.strengthColor !== StrengthColors[0]) {
                                requestAnimationFrame(() => me.setState({strengthColor: StrengthColors[0]}));
                              }
                              return <span className={'error-text'}>较弱</span>
                            } else if (percent < 70) {
                              if (me.state.strengthColor !== StrengthColors[1]) {
                                requestAnimationFrame(() => me.setState({strengthColor: StrengthColors[1]}));
                              }
                              return <span className={'warning-text'}>中等</span>
                            } else {
                              if (me.state.strengthColor !== StrengthColors[2]) {
                                requestAnimationFrame(() => me.setState({strengthColor: StrengthColors[2]}));
                              }
                              return <span className={'success-text'}>较强</span>
                            }
                          }}/>
              </div>
            )}
          </Form.Item>
          <Form.Item className={style['form-item']}>
            {getFieldDecorator('confirm', {
              rules: [{
                required: true,
                message: '请再次输入新密码',
              }, {
                validator: this.compareToFirstPassword,
              }],
            })(
              <Input prefix={<Icon type="lock"/>}
                     placeholder="请再次输入密码"
                     type="password"
                     onBlur={this.handleConfirmBlur} />
            )}
          </Form.Item>
          <Form.Item className={style['form-item']}>
            <Button type="primary"
                    htmlType="submit"
                    loading={this.props.processing}
                    className={style['form-button']}>
              确定
            </Button>
          </Form.Item>
        </Form>
      </AuthForm>
    )
  }
}

NormalResetPwdForm.defaultProps = {
  processing: false,
  onResetPwd: () => {
    console.log('onResetPwd property is not set.')
  },
};

NormalResetPwdForm.propTypes = {
  processing: PropTypes.bool,
  onResetPwd: PropTypes.func,
};

const WrappedNormalResetPwdForm = Form.create()(NormalResetPwdForm);

export default WrappedNormalResetPwdForm;
