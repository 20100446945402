/*
 * @Author: Carr.s
 * @Date: 2022-04-18 18:09:32
 * @LastEditTime: 2022-06-06 17:28:54
 * @LastEditors: Carr.s
 * @Description: 洞察模板 echarts、highcharts图表
 * @Copyright by joinmap
 */
import React from "react";
import axios from "axios";
import { Checkbox, Empty, message, Spin, Tooltip, Button } from "antd";
import relationStyle from "@/style/components/main.relation.less";
import styles from "@/style/components/nodeExtendedView/nodeExtended.less";
import Icon from "@/components/common/common.icon";
import { IconTypes } from "@/constants/common";
import { getHttpUtil } from "@/utils/HttpUtil";
// js 复制文本
import copy from "copy-to-clipboard";
// echarts 及地图插件
import ReactECharts from "echarts-for-react";
import echarts from "echarts/lib/echarts";
// Highcharts
import Highcharts from "highcharts";
import highchartsMore from "highcharts/highcharts-more";
import HighchartsReact from "highcharts-react-official";
highchartsMore(Highcharts);

// --------------------------------------------------------------------
// 接口返回值样例
// --------------------------------------------------------------------
// 有特殊字段的另商
const api_response_data_example = {
  code: "必须",
  msg: "必须",
  data: {
    data: [
      {
        name: "必须，没有值时传空值=None",
        value: "必须，没有值时传空值=None",
        valueUnit: "必须，没有值时传空值=None",
        xxx: [
          // 同父级
        ],
        xxxTotal: "必须，没有值时传空值，与xxx字段对应",
        yyy: [
          // 同父级
        ],
        yyyTotal: "必须，没有值时传空值，与yyy字段对应",
      },
    ],
    dataTotal: "必须，没有值时传空值=None",
    dataUnit: "必须，没有值时传空值=None",
  },
};

// --------------------------------------------------------------------
// 组件
// --------------------------------------------------------------------
export default class ViewNodeExtendedChartTpl extends React.Component {
  state = {
    // ------------------------------------------------------------
    // 第一列
    _column1Total: 0, // 计算总数
    _column1Len: 0, // 显示数量(=返回的数量=数组长度 or tree 全部节点的总数)
    // _column1ClickedIdx: -1, // 被点击元素的数组下标
    _column1ClickedPath: -1, // 被点击元素的: “层级-数组下标”
    _column1ClickedName: "", // 被点击的数组元素的name
    _column1ClickedIndeterminate: false, // checkbox 状态
    _column1CheckAll: false, // 是否全部选中level1数据
    _column1CheckedList: [], // 被选中的level1数据:[数组下标]
    _column1CheckedPathList: [], // 被选中的level1数据:[“层级-数组下标”,“层级-数组下标”]
    // 第二列
    _column2Total: 0, // 计算总数
    _column2Len: 0, // 显示数量(=返回的数量=数组长度)
    _column2ClickedIdx: -1, // 被点击的数组下标
    _column2ClickedName: "", // 被点击的数组元素的name
    _column2ClickedIndeterminate: false, // 第二列 item checkbox 状态
    _column2CheckAll: false, // 是否全部选中第二列数据
    _column2CheckedList: [], // 被选中的第二列 idx 数据
    _column2Data: [], // column2 的显示数据
    // 第三列 【预留】
    _column3Total: 0, // 计算总数
    _column3Len: 0, // 显示数量(=返回的数量=数组长度)
    _column3ClickedIdx: -1, // 被点击的数组下标
    _column3ClickedName: "", // 被点击的数组元素的name
    _column3ClickedIndeterminate: false, // checkbox 状态
    _column3CheckAll: false, // 是否全部选中第三列数据
    _column3CheckedList: [], // 被选中的第三列 idx 数据
    _column3Data: [], // column3 的显示数据
    // ------------------------------------------------------------
    // 全局
    _refresh: false, // 刷新
    _loading: true, // 页面加载状态
    _apiLoading: true, // 接口加载状态
    _calcMsg: null, // 计算进度信息
    _apiLoadingMsg: "分析中", // 接口加载时的显示文字
    _apiResponseMsg: "未计算出数据", // 接口返回后的报错文字
  };
  // api 加载计时器，每2秒运行一次
  _apiLoadingTime = 0; // 接口加载消耗时间(单位秒)
  _apiLoadingInterval = undefined;

  //===============================================================
  // begin of overwrite
  // 使用默认的方式就不用重写

  // ------------------------------------------------------------
  // 全局配置
  // ------------------------------------------------------------
  // 提示信息中提示添加哪种类型的节点
  nodeTitleInTip = "事件";
  // 显示底部按钮
  showToolbar = false;
  // 洞察名称
  viewerTitle = "图表模板";
  // 每次点击 column1 时是否刷新 chart
  refreshForClickColumn1 = true;

  // ------------------------------------------------------------
  // 层级分析对象的配置
  // ------------------------------------------------------------
  // 第一列
  column1_showType = "list"; // 第一列的展现形式，list=显示列，tree=显示树, none=不显示
  column1_DataField = "data"; // 取值字段
  column1_TotalField = "dataTotal"; // 计算总数(与返回数量不一定一致)字段
  column1_showTreeLevelNumb = 2; // 用树状目录显示层数,1=二层，2=三层，3=四层
  column1_treeFields = []; // 用树状目录显示数据时各层级的 children 字段名数据
  column1_Title = "标签"; // 显示名称
  column1_Unit = "篇"; // 显示的文字单位
  column1_subTitle = () => {
    // 列表顶部副标题文字
    const { _column1Len, _column1Total } = this.state;
    return (
      <>
        已显示前{_column1Len}
        {this.column1_Unit},共计算出{_column1Total}
        {this.column1_Unit}
      </>
    );
  };
  column1_ValueRender = (column1_data, idx, nodePath = undefined) => {
    // 右侧数值渲染，不输出则空白
    // 必须加上 className={styles["line-value"]}
    /*
    let value = column1_data.hasOwnProperty("value") ? column1_data.value : "";
    let valueUnit = column1_data.hasOwnProperty("valueUnit")
      ? column1_data.valueUnit
      : "";
    return (
      <div className={styles["line-value"]}>
        {value} {valueUnit}
      </div>
    );
    */

    const { column2_DataField, column2_Unit } = this;
    let value = column1_data.hasOwnProperty(column2_DataField)
      ? column1_data[column2_DataField].length
      : "";
    return (
      <div className={styles["line-value"]}>
        {value}
        {column2_Unit}
      </div>
    );
  };
  column1_IconRender = (column1_data, idx, nodePath = undefined) => {
    // 左侧图表渲染，不输出则空白
    // 必须加上 className={styles["line-icon"]}
  };
  column1_HoverTitle = (column1_data, idx, nodePath = undefined) => {
    // 列表鼠标悬浮提示文字
    let name = column1_data.hasOwnProperty("name") ? column1_data.name : "";
    if (this.column2_DataField) {
      let column2Length = this.column2_DataField
        ? column1_data[this.column2_DataField].length
        : "";
      return `${this.column1_Title}：${name}\n${this.column2_Title}：${column2Length}${this.column2_Unit}\n点击打开${this.column2_Title}列表`;
    } else {
      return `${this.column1_Title}：${name}`;
    }
  };

  /**
   * column1 的子项 checkbox 改变后的响应
   */
  column1CheckboxChangeCallback = (selectedNodePathArr) => {};

  /**
   * column1 的全部选中 checkbox 改变后的响应
   */
  column1CheckAllBoxChangeCallback = (checked) => {};

  // 第二列
  column2_showType = "list"; // 第二列的展现形式，list=显示列，tree=显示树, none=不显示
  column2_DataField = ""; // 取值字段
  column2_TotalField = ""; // 计算总数(与返回数量不一定一致)字段
  column2_Title = "事件"; // 显示名称
  column2_Unit = "个"; // 显示的文字单位
  column2_ValueRender = (column2_data, idx) => {
    // 右侧数值渲染，不输出则空白
    // 必须加上 className={styles["line-value"]}
    return (
      <div className={styles["line-value"]}>
        {column2_data["value"]}
        {column2_data["valueUnit"]
          ? column2_data["valueUnit"]
          : this.column2_Unit}
      </div>
    );
  };
  column2_IconRender = (column2_data, idx) => {
    // 左侧图表渲染，不输出则空白
    // 必须加上 className={styles["line-icon"]}
  };
  column2_HoverTitle = (column2_data, idx) => {
    // 列表鼠标悬浮提示文字
    return `${this.column2_Title}：${column2_data["name"]}\n点击打开${this.column2_Title}链接`;
  };
  column2_subTitle = () => {
    // 列表顶部副标题文字
    const { _column2Data } = this.state;
    return (
      <>
        共{_column2Data.length}
        {this.column2_Unit}
      </>
    );
  };
  /**
   * 【预留】
   * 第三列
   */
  /*
  column3_DataField = "dataTotal"; // 取值字段
  column3_TotalField = "children"; // 计算总数(与返回数量不一定一致)字段
  column3_Title = "内容"; // 显示名称
  column3_Unit = "篇"; // 显示的文字单位
  column3_ValueRender = (level3_data, idx) => {
    // 右侧数值渲染，不输出则空白
    // 必须加上 className={styles["line-value"]}
    // const { _column2ClickedIdx } = this.state;
    return (
      <div className={styles["line-value"]}>
        {level3_data["value"] + +level3_data["valueUnit"] || ""}
      </div>
    );
  };
  column3_IconRender = (level3_data, idx) => {
    // 左侧图表渲染，不输出则空白
    // 必须加上 className={styles["line-icon"]}
  };
  column3_HoverTitle = (level3_data, idx) => {
    // 列表鼠标悬浮提示文字
    // return `${this.column3_Title}：${level3_data["name"]}\n点击打开${this.column3_Title}链接`;
  };
  */

  // ------------------------------------------------------------
  // 接口的配置
  // ------------------------------------------------------------
  // 接口 url path
  api_path = "";
  // 接口前缀
  api_base = () => {
    const {
      query: { isDev },
    } = this.props;
    if (isDev === true) {
      return "http://localhost:5000";
    }
    return "https://snap.joinmap.ai";
  };

  // 返回数据默认值
  defaultLimit = 150;

  /**
   * 【继承组件要重写】
   * api 请求完成后，setState结束后的 callback
   */
  apiLoadedCallback = () => {
    // message.info("数据加载成功");
  };

  // ------------------------------------------------------------
  // 图表的配置
  // ------------------------------------------------------------
  // 图表显示库
  chartType = "echarts";

  /**
   * 生成公用的、默认的 option
   * @returns
   */
  initChartOption = () => {
    return {};
  };

  /**
   * 【继承组件要重写】
   * 生成 chart 的 options
   * @param {int} idx 选中的数据下标
   */
  refreshChartOptions = (idx) => {};

  /**
   * 【继承组件要重写】
   * echart 有时需要绑定点击事件什么
   * @param {object} e ReactECharts 返回的 echarts 实例
   */
  eChartRefCallback = (e) => {
    // let me = this;
    // me._chartIns = e.getEchartsInstance();
  };

  // 图表默认左边距（是否留出第一列的空间）
  chartDivLeft = 0;

  // 图表父div元素的 style
  chartStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  };

  // 图表组件的 style
  chartCompomentStyle = {
    width: "100px",
  };

  // 【预留】
  // echarts 操作
  chartZoomEnd = 1;

  /**
   * 【预留】
   * 手动缩放 echarts treemap,看 echarts 的配置文件没有对应的操作
   * dataZoom 动作对 treemap 无效
   * @param end
   */
  chartZoom = (end) => {
    const {
      query: { isDebug },
    } = this.props;
    if (isDebug) console.log("ViewNodeExtendedChartTpl chartZoom end: ", end);
    return false;
  };

  // end of overwrite
  //===============================================================

  // ------------------------------------------------------------
  // 通用的内部变量，也可覆盖
  // ------------------------------------------------------------

  // 图表实例
  _chartIns = undefined;
  // 特征标签数据
  _chartData = [];
  // 图表的配置
  _chartOption = {};
  // 颜色
  _chartColors = [
    "#EE6666",
    "#5470C6",
    "#3BA272",
    "#FAC858",
    "#FC8452",
    "#73C0DE",
    "#9A60B4",
    "#91CC75",
    "#F4B183",
    "#EA7CCC",
  ];

  // 接口--------------------------------------------------------------------
  _API_MicroServer = (url_path, { viewId, limit, sortBy }, isDev = false) => {
    let api_prefix = this.api_base();
    url_path = url_path + "";
    let first_seq = "?";
    if (url_path.indexOf("?") > -1) {
      first_seq = "&";
    }
    if (limit >= 0) {
      url_path += `${first_seq}limit=${limit}`;
    } else {
      url_path += `${first_seq}limit=${this.defaultLimit}`;
    }
    if (sortBy) {
      url_path += `&sort_by=${sortBy}`;
    }
    const service = axios.create({
      timeout: 1500000, // 超时时间 150秒
      // timeout: 40000, // 超时时间 40秒
      headers: {
        "x-requested-with": "XMLHttpRequest",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Max-Age": 0,
      },
    });
    return service({
      method: "get",
      url: api_prefix + url_path,
      data: {},
      params: { view_id: viewId },
    });
    // return getHttpUtil(false, api_prefix).get(url_path, { view_id: viewId });
  };

  /**
   * 标题渲染
   * 兼容已经做好的洞察页面
   * @returns
   */
  _viewerTitleRender = () => {
    return this.viewerTitle + "图表";
  };

  // 第一列 ----------------------------
  // 计算第一列总数，包含各层子元素
  __column1CheckBoxTotal = 0;
  _loopData = (
    data,
    parentPath = undefined,
    callback = () => {},
    treeFieldArr = []
  ) => {
    const {
      query: { isDebug },
    } = this.props;
    if (isDebug) console.log("_loopData parentPath=", parentPath);
    let pathArr = []; // 正在渲染 node 的路径
    if (
      parentPath === undefined ||
      parentPath === null ||
      parentPath === false
    ) {
      pathArr = [];
    } else {
      pathArr = (parentPath + "").split("-");
    }
    let treeLevel = pathArr.length; // 正在渲染 node 的层数
    let fieldName = "children"; // 当前层级的子元素字段名
    if (treeLevel < treeFieldArr.length) {
      fieldName = treeFieldArr[treeLevel];
    }
    if (isDebug) console.log("_loopData data.length=", data.length);
    // this.__column1CheckBoxTotal = this.__column1CheckBoxTotal + data.length;
    data.forEach((node, idx) => {
      // 当前节点的 nodePath
      let nodePath = "";
      if (pathArr.length > 0) {
        nodePath = pathArr.join("-") + "-" + idx;
      } else {
        nodePath = "" + idx;
      }
      // if (isDebug) console.log("_loopData nodePath=", nodePath);
      // 递归内要做的动作
      callback && callback(node, nodePath);
      // 继续判断递归
      if (node.hasOwnProperty(fieldName) && node[fieldName].length > 0) {
        this._loopData(node[fieldName], nodePath, callback, treeFieldArr);
      }
    });
  };

  /**
   * 深度循环tree
   * 可对每个数值进行属性增减更新操作
   * @param {array} children
   * @param {array|undefined} dataArrPath 正在处理的 nodePath 不存在时认为是顶层
   * @param {function} callback
   * @param {array} treeFieldArr 树中各层对应的字段名
   * @param {function} brforeReturn 返回前对 child 的处理
   * @param {object} parent
   * @returns
   */
  _setDataDeepValue = (
    children,
    parentPath,
    callback = undefined,
    treeFieldArr = [],
    brforeReturn = undefined,
    parent = undefined
  ) => {
    const {
      query: { isDebug },
    } = this.props;
    if (!children) {
      // 空数组
      return children;
    }
    let parentPathArr = []; // 正在渲染 node 的路径
    if (
      parentPath === undefined ||
      parentPath === null ||
      parentPath === false
    ) {
      // 顶层
      parentPathArr = [];
    } else {
      parentPathArr = (parentPath + "").split("-");
    }
    let parentLevel = parentPathArr.length; // 正在渲染 node 的层数
    let childField = "children"; // 当前层级的子元素字段名
    if (parentLevel < treeFieldArr.length) {
      childField = treeFieldArr[parentLevel];
    }
    return children.map((child, idx) => {
      // 当前节点的 childPath
      let childPath = [].concat(parentPathArr);
      childPath.push(idx);
      childPath = childPath.join("-");
      // if (isDebug) console.log("_setDataDeepValue childPath=", childPath);
      // 递归内要做的动作
      if (callback) {
        child = callback(child, childPath, parent);
      }
      // 继续判断递归
      if (child.hasOwnProperty(childField) && child[childField]) {
        child[childField] = this._setDataDeepValue(
          child[childField],
          childPath + "",
          callback,
          treeFieldArr,
          brforeReturn,
          child
        );
      }
      if (brforeReturn) {
        child = brforeReturn(child, childPath, childField, parent);
      }
      return child;
    });
  };

  /**
   * tag 的排序
   * @param fieldName 排序字段
   * @param desc 是否降序
   */
  _level1Sort = (fieldName = "value", desc = true) => {
    if (desc === true) {
      this._chartData = this._chartData.sort((v1, v2) => {
        return v2[fieldName] - v1[fieldName];
      });
    } else {
      this._chartData = this._chartData.sort((v1, v2) => {
        return v1[fieldName] - v2[fieldName];
      });
    }
  };

  /**
   * 点击 level1 item
   * @param nodePath 当前node的路径
   * @param name level2显示的文字标题,用于自定义
   * @param switchVisiable true=如果 nodePath 与 _column1ClickedPath 相同，column2会切换状态
   */
  _column1_onClick = (nodePath, name, switchVisiable = true) => {
    const {
      query: { isDebug },
    } = this.props;
    const { column1_treeFields, column2_DataField } = this;
    let me = this;
    // --------------------------------------------------------------------------
    // 判断是否是取消选择
    if (
      nodePath === null ||
      nodePath === undefined ||
      nodePath === false ||
      nodePath === ""
    ) {
      if (isDebug) console.log("_column1_onClick 取消已经选中：", nodePath);
      // 刷新 chart option
      if (this.refreshForClickColumn1) {
        this.refreshChartOptions(null);
      }

      this.setState({
        _column1ClickedPath: null,
        _column1ClickedName: null,
        _column2CheckedList: [],
        _column2ClickedIndeterminate: false,
        _column2CheckAll: false,
        _column2Data: [],
      });
      return;
    }

    // --------------------------------------------------------------------------
    // 判断是否是 点击已经选中的行取消选择状态
    if (switchVisiable) {
      if (this.state._column1ClickedPath === nodePath) {
        if (isDebug) console.log("_column1_onClick 点击已经选中：", nodePath);
        // 刷新 chart option
        if (this.refreshForClickColumn1) {
          this.refreshChartOptions(null);
        }
        this.setState(
          {
            _column1ClickedPath: null,
            _column1ClickedName: null,
            _column2Data: [],
          },
          () => {
            me.scrollToAnchor("column1_" + nodePath);
          }
        );
        return false;
      }
    }

    // --------------------------------------------------------------------------
    // 点击新行
    if (isDebug) console.log("_column1_onClick 点击新行：", nodePath);
    let nodePathArr = ("" + nodePath).split("-");
    let item = undefined; // 确定选中的 item
    if (column1_treeFields.length > 0) {
      item = { [column1_treeFields[0]]: this._chartData };
    } else {
      // 默认值 children
      item = { children: this._chartData };
    }
    nodePathArr.forEach((idx, inx) => {
      let fieldName = "children";
      if (inx < column1_treeFields.length) {
        fieldName = column1_treeFields[inx];
      }
      item = item[fieldName][parseInt(idx)];
    });
    // 排序，默认用 value 字段排序
    let column2Data = []; // 没有第二个字段的话，则显示空数组
    if (item.hasOwnProperty(column2_DataField)) {
      column2Data = item[column2_DataField].sort((v1, v2) => {
        if (v1.hasOwnProperty("value") && v2.hasOwnProperty("value")) {
          return v2.value - v1.value;
        } else {
          return 0;
        }
        // return compare(v1.name, v2.name);
      });
    }

    // 刷新 chart
    if (this.refreshForClickColumn1) {
      this.refreshChartOptions(nodePath);
    }
    // console.log("_column1_onClick this._chartOption", this._chartOption);
    // console.log("_column1_onClick this._chartData：", this._chartData);
    // console.log("_column1_onClick nodePath：", nodePath);
    // console.log("_column1_onClick name：", name);
    // console.log("_column1_onClick column2Data", column2Data);

    // return;
    // --------------------------------------------------------------------------
    // 渲染
    this.setState(
      {
        _column1ClickedPath: "" + nodePath + "",
        _column1ClickedName: name,
        _column2CheckedList: [],
        _column2ClickedIndeterminate: false,
        _column2CheckAll: false,
        _column2Data: column2Data,
      },
      () => {
        me.scrollToAnchor("column1_" + nodePath);
      }
    );
    return;
  };

  /**
   * 第一列 checkbox 状态改变
   * Todo: 向上回溯选择状态
   * @param {*} e 被点击的checkbox元素
   */
  _column1_onCheckboxChange = (e) => {
    const {
      query: { isDebug },
    } = this.props;
    if (isDebug)
      console.log("ViewNodeExtendedChartTpl _column1_onCheckboxChange e: ", e);
    let me = this;
    let selectedPathList = [];
    if (this.state._column1CheckedPathList.indexOf(e.target.path) > -1) {
      // 删除选中
      this.state._column1CheckedPathList.forEach((i) => {
        if (i != e.target.path) {
          selectedPathList.push(i);
        }
      });

      // Todo：删除子节点
      // 判断父节点状态
    } else {
      // 增加选中的
      selectedPathList = [e.target.path].concat(
        this.state._column1CheckedPathList
      );
      // 增加子节点
      // 判断父节点状态
    }

    // 选中子节点

    this.setState(
      {
        _column1CheckedPathList: selectedPathList,
        _column1ClickedIndeterminate:
          !!selectedPathList.length &&
          selectedPathList.length < this.__column1CheckBoxTotal,
        _column1CheckAll:
          selectedPathList.length === this.__column1CheckBoxTotal,
      },
      () => {
        me.column1CheckboxChangeCallback &&
          me.column1CheckboxChangeCallback(e.target.path);
      }
    );
  };

  /**
   * 选中第一列全部 checkbox
   * @param {*} e 被点击的checkbox元素
   */
  _column1_onCheckAllBoxChange = (e) => {
    let me = this;
    let selectedPathList = [];
    if (e.target.checked) {
      me._loopData(
        me._chartData,
        false,
        (node, nodePath) => {
          selectedPathList.push(nodePath);
        },
        this.column1_treeFields
      );
    }

    this.setState(
      {
        _column1ClickedIndeterminate: !e.target.checked,
        _column1CheckedPathList: selectedPathList,
        _column1CheckAll: e.target.checked,
      },
      () => {
        me.column1CheckAllBoxChangeCallback &&
          me.column1CheckAllBoxChangeCallback(e.target.checked);
      }
    );
  };

  /**
   * 点击复制选中的第一列文本到剪切板
   */
  _column1_onCopyToClipboard = () => {
    let me = this;
    let selected = [];
    if (this.state._column1CheckAll) {
      me._loopData(
        me._chartData,
        false,
        (node, nodePath) => {
          selected.push(node.name);
        },
        this.column1_treeFields
      );
    } else {
      if (this.state._column1CheckedPathList.length === 0) {
        message.warning("请先选中再点击复制。");
        return false;
      }
      me._loopData(
        me._chartData,
        false,
        (node, nodePath) => {
          if (me.state._column1CheckedPathList.indexOf(nodePath) > -1) {
            selected.push(node.name);
          }
        },
        this.column1_treeFields
      );
    }

    let result = copy(selected.join("\r\n"));
    if (result) message.success("选中的文本已复制到剪切板。");
  };

  // 第二列 ----------------------------
  /**
   * 点击 level2 item，有url 字段则打开url字段，没有则打开原图谱
   * @param idx
   */
  _handlerColumn2Click = (idx) => {
    const { _column2Data } = this.state;
    // const { column2_DataField } = this;
    let item = _column2Data[idx];
    if (item.hasOwnProperty("url") && item["url"]) {
      window.open(item["url"], "_blank");
    } else {
      if (item.hasOwnProperty("viewId")) {
        let viewId = item["viewId"];
        window.open("/mainview/relation/" + viewId, "_blank");
      } else {
        let me = this;
        let parent = undefined;
        this._loopData(
          this._chartData,
          undefined,
          (node, nodePath) => {
            if (nodePath == me._column1ClickedPath) {
              parent = node;
            }
          },
          this.column1_treeFields
        );
        if (parent && parent.hasOwnProperty("url") && parent["url"]) {
          window.open(parent["url"], "_blank");
        } else {
          const { query } = this.props;
          window.open("/mainview/relation/" + query.viewId, "_blank");
        }
      }
    }
  };

  /**
   * 选中全部 level2 item 的 checkbox
   * @param {*} e
   * @param {*} callbcak 回调
   */
  _handleOnLevel2CheckAllChange = (e, callbcak = undefined) => {
    const { _column2Data: data } = this.state;
    let selectedList = [];
    data.forEach((i, idx) => {
      selectedList.push(idx);
    });
    this.setState(
      {
        _column2CheckedList: e.target.checked ? selectedList : [],
        _column2ClickedIndeterminate: false,
        _column2CheckAll: e.target.checked,
      },
      () => {
        callbcak && callbcak();
      }
    );
  };

  /**
   * level2 的 item 的 checkbox
   * @param {*} e 被点击的checkbox元素
   */
  _handleOnlevel2CheckChange = (e) => {
    const {
      query: { isDebug },
    } = this.props;
    if (isDebug)
      console.log("ViewNodeExtendedChartTpl _handleOnlevel2CheckChange e: ", e);
    const { _column2CheckedList, _column2Data } = this.state;
    let allLength = _column2Data.length;
    let selectedList = [];
    if (_column2CheckedList.indexOf(e.target.idx) > -1) {
      // 删除选中
      _column2CheckedList.forEach((i) => {
        if (i != e.target.idx) {
          selectedList.push(i);
        }
      });
    } else {
      // 增加选中的
      selectedList = [e.target.idx].concat(_column2CheckedList);
    }
    this.setState({
      _column2CheckedList: selectedList,
      _column2ClickedIndeterminate:
        selectedList.length > 0 && selectedList.length < allLength,
      _column2CheckAll: selectedList.length === allLength,
    });
  };

  /**
   * 点击复制选中 level2 item 至剪切板
   */
  _onCopySelectedLevel2sToClipboard = () => {
    const { _column2CheckedList, _column2Data: data } = this.state;
    if (_column2CheckedList.length === 0) {
      message.warning("请先选中后再点击复制。");
      return false;
    }
    let selected = [];
    data.forEach((i, idx) => {
      if (_column2CheckedList.indexOf(idx) > -1) {
        selected.push(i.name);
      }
    });
    let result = copy(selected.join("\r\n"));
    if (result) message.success("选中的文本已复制到剪切板。");
  };

  /**
   * api 请求成功后的初始化过程
   * @param {*} resData api 返回的data
   * @returns
   */
  _init = (resData) => {
    // 生成 chart options
    this.refreshChartOptions();
  };

  componentDidMount() {
    const {
      query: { viewId, limit, sortBy, isDev, isDebug },
    } = this.props;
    if (isDebug) console.log("ViewNodeExtendedChartTpl=> ", this.props);
    document.title = this.viewerTitle + "图标 - 炬图";
    if (!this.api_path) {
      if (isDebug) console.log("ViewNodeExtendedChartTpl 没有设置 api ");
      this.refreshChartOptions();
      me.setState({
        _apiLoading: false,
        _loading: false,
        _column1Len: me._chartData.length,
        _column1Total: me._chartData.length,
        _refresh: !this.state._refresh, // 确保刷新
      });
      return false;
    }
    let me = this;

    // 初始化图表div的左边距
    if (this.chartDivLeft == "auto") {
      let column1El = document.getElementById("column1_list");
      this.chartDivLeft = column1El ? column1El.clientWidth + 10 : 300;
    }

    this.setState(
      {
        _refresh: !this.state._refresh,
        _apiLoading: true,
        _loading: true,
        _column1Len: 0,
      },
      () => {
        // 启动api时间计时器
        clearInterval(me._apiLoadingInterval);
        me._apiLoadingInterval = setInterval(() => {
          if (isDebug)
            console.log("_apiLoadingInterval = ", me._apiLoadingTime);

          if (me._apiLoadingTime > 30) {
            // throw new Error("计算洞察数据超时"); //传入message
            // 加载成功取消定时器
            // clearInterval(me._apiLoadingInterval);
            // me.setState({
            //   _apiLoading: false,
            //   _loading: false,
            //   _column1Len: 0,
            // });
          } else if (me._apiLoadingTime > 18) {
            me.setState({
              _apiLoadingMsg: "计算时间较长，请再耐心等待",
            });
          } else if (me._apiLoadingTime > 8) {
            me.setState({
              _apiLoadingMsg: "计算时间可能较长，请耐心等待一会儿",
            });
          }
          me._apiLoadingTime = me._apiLoadingTime + 2;
        }, 2000);

        // return;
        // 启动接口
        me._API_MicroServer(
          me.api_path,
          {
            viewId: viewId,
            limit: limit,
            sortBy: sortBy,
          },
          isDev
        )
          .then((response) => {
            if (isDebug) console.log("api response = ", response);
            // 加载成功取消定时器
            clearInterval(me._apiLoadingInterval);
            // return;
            // 处理返回数据
            let res = response.data;
            if (res.hasOwnProperty("code") && res.code === 0) {
              let resData = res.data;

              // 判断计算进度是否完成
              if (
                resData.hasOwnProperty("calcStatus") &&
                resData.calcStatus != 0
              ) {
                me.setState({
                  _apiLoading: false,
                  _loading: false,
                  _calcMsg: resData.calcMsg,
                  _column1Len: 0,
                });
                return false;
              }

              // 赋值给 this._chartDate ,并将第一列按 value 值排序
              if (resData.hasOwnProperty(me.column1_DataField)) {
                me._chartData = resData[me.column1_DataField].sort((v1, v2) => {
                  if (
                    v1.hasOwnProperty("value") &&
                    v2.hasOwnProperty("value")
                  ) {
                    return v2.value - v1.value;
                  } else {
                    return 0;
                  }
                });
              } else {
                me._chartData = [];
              }

              // 给各个层级的元素加上 _path
              me._setDataDeepValue(
                me._chartData,
                false,
                (node, nodePath) => {
                  me.__column1CheckBoxTotal = me.__column1CheckBoxTotal + 1;
                  node["_path"] = nodePath;
                  return node;
                },
                me.column1_treeFields
              );

              // 计算可点击的 checkbox 数量
              if (me.column1_showType == "tree") {
                me.__column1CheckBoxTotal = 0;
                me._loopData(
                  me._chartData,
                  false,
                  (node, nodePath) => {
                    me.__column1CheckBoxTotal = me.__column1CheckBoxTotal + 1;
                  },
                  me.column1_treeFields
                );
              } else {
                me.__column1CheckBoxTotal = me._chartData.length;
              }

              // 判断用什么库显示
              if (resData.hasOwnProperty("showType")) {
                me.chartType = resData["showType"];
              }

              // column1 的显示单位
              me.column1_Unit = resData.hasOwnProperty("dataUnit")
                ? resData.dataUnit
                : me.column1_Unit;

              me._init(resData);

              me.setState(
                {
                  _refresh: !this.state._refresh,
                  _apiLoading: false,
                  _loading: false,
                  _column1Len: me._chartData.length,
                  _column1Total: resData[me.column1_TotalField],
                  showToolbar: resData.hasOwnProperty("showToolbar")
                    ? !!resData.showToolbar
                    : me.state.showToolbar,
                },
                () => {
                  me.apiLoadedCallback && me.apiLoadedCallback();
                }
              );
            } else {
              // 返回出错
              me.setState({
                _apiLoading: false,
                _apiResponseMsg: "未计算出结果",
                _loading: false,
                _column1Len: 0,
              });
            }
          })
          .catch((e) => {
            // 加载成功取消定时器
            clearInterval(me._apiLoadingInterval);
            if (isDebug)
              console.log("ViewNodeExtendedChartTpl _API_MicroServer error: ", e);

            me.setState({
              _apiLoading: false,
              _loading: false,
              _column1Len: 0,
              _apiResponseMsg: "未计算出结果",
            });
          });
      }
    );
  }
  // ============================================================================================
  // 数组的包含关系
  arrayIsEqual(arr1, arr2) {
    let arrFlag = false;

    if (arr1.length != arr2.length) {
      return arrFlag;
    }

    arr1.forEach((element, index) => {
      if (arr1.indexOf(arr2[index]) != -1 && arr2.indexOf(element) != -1) {
        arrFlag = true;
      }
    });

    return arrFlag;
  }

  _column1TreeRender2 = (nodes, parentPath) => {
    const { column1_showType, column1_treeFields, column1_showTreeLevelNumb } =
      this;
    const { _column1ClickedPath, _column1CheckAll, _column1CheckedPathList } =
      this.state;
    let pathArr = undefined; // 正在渲染 node 的路径
    if (
      parentPath === undefined ||
      parentPath === null ||
      parentPath === false
    ) {
      pathArr = [];
    } else {
      pathArr = (parentPath + "").split("-");
    }
    let treeLevel = pathArr.length; // 正在渲染 node 的层数
    let fieldName = "children"; // 当前层级的子元素字段名
    if (treeLevel < column1_treeFields.length) {
      fieldName = column1_treeFields[treeLevel];
    }
    // 设置显示层数
    if (treeLevel > column1_showTreeLevelNumb) {
      return "";
    }
    return nodes.map((i, idx) => {
      let nodePath = "";
      if (
        parentPath === undefined ||
        parentPath === null ||
        parentPath === false
      ) {
        nodePath = "" + idx;
      } else {
        nodePath = parentPath + "-" + idx;
      }
      return (
        <>
          <div
            key={nodePath}
            id={"column1_" + nodePath}
            className={
              _column1ClickedPath === nodePath
                ? styles["list-line"] + " " + styles["selected"]
                : column1_showType == "tree" && treeLevel == 0
                ? styles["list-line"] + " " + styles["section"]
                : styles["list-line"]
            }
            style={{
              paddingLeft: treeLevel * 1.6 + 0.5 + "rem",
            }}
          >
            <div className={styles["line-checkbox"]}>
              <Checkbox
                checked={
                  _column1CheckAll
                    ? true
                    : _column1CheckedPathList.indexOf(nodePath) > -1
                }
                idx={idx}
                path={nodePath}
                name={i["name"]}
                onChange={this._column1_onCheckboxChange}
              />
            </div>
            {this.column1_IconRender(i, idx, nodePath)}
            <div
              className={styles["line-body"]}
              onClick={() => {
                this._column1_onClick(nodePath, i["name"]);
              }}
              title={this.column1_HoverTitle(i, idx, nodePath)}
            >
              <div className={styles["line-center"]}>{i["name"]}</div>
              {this.column1_ValueRender(i, idx, nodePath)}
            </div>
          </div>
          {column1_showType == "tree" &&
          i.hasOwnProperty(fieldName) &&
          Array.isArray(i[fieldName]) &&
          i[fieldName].length > 0
            ? this._column1TreeRender2(i[fieldName], nodePath)
            : ""}
        </>
      );
    });
  };

  scrollToAnchor = (anchorName) => {
    // return;
    if (anchorName) {
      let anchorElement = document.getElementById(anchorName);
      if (anchorElement) {
        anchorElement.scrollIntoView({
          behavior: "smooth", //'auto' | 'instant' | 'smooth',
          block: "nearest", // 'start' | 'center' | 'end' | 'nearest'
        });
      }
    }
  };

  // ============================================================================================

  render() {
    const {
      _loading,
      _apiLoading,
      _calcMsg,
      _apiLoadingMsg,
      _apiResponseMsg,
      _column1Total,
      _column1Len,
      _column1ClickedName,
      _column1ClickedIndeterminate,
      _column1CheckAll,
      _column1CheckedList,

      _column2ClickedIdx,
      _column2ClickedName,
      _column2ClickedIndeterminate,
      _column2CheckAll,
      _column2CheckedList,
      _column2Data,
    } = this.state;

    const {
      column1_showType,
      column1_DataField,
      column1_TotalField,
      column1_Title,
      column1_Unit,

      column2_showType,
      column2_DataField,
      column2_TotalField,
      column2_Title,

      column3_DataField,
      column3_TotalField,
      column3_Title,

      nodeTitleInTip,
      showToolbar,
    } = this;

    return (
      <>
        <div className={relationStyle["view-title"]} style={{ zIndex: 99999 }}>
          {this._viewerTitleRender()}
        </div>
        {_apiLoading ? (
          <div className={styles["loading_div"]}>
            <div>
              <Spin
                size="large"
                tip={
                  <div style={{ color: "#f8f8f8" }}>
                    <div style={{ fontSize: "1.5rem" }}>{_apiLoadingMsg}</div>
                    <div style={{ marginTop: 10 }}>正在获取数据</div>
                  </div>
                }
              />
            </div>
          </div>
        ) : _column1Len == 0 ? (
          _calcMsg ? (
            <div className={styles["loading_div"]}>
              <div>
                <Empty
                  image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                  imageStyle={{
                    height: 120,
                  }}
                  description={
                    <span>
                      <span style={{ fontSize: "1.5rem" }}>{_calcMsg}</span>
                      <br />
                      <span style={{ marginTop: 15 }}>数据正在计算中</span>
                    </span>
                  }
                />
              </div>
            </div>
          ) : (
            <div className={styles["loading_div"]}>
              <div>
                <Empty
                  image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                  imageStyle={{
                    height: 120,
                  }}
                  description={
                    <span>
                      <span style={{ fontSize: "1.5rem" }}>
                        {_apiResponseMsg}
                      </span>
                      <br />
                      <span style={{ marginTop: 15 }}>
                        请检查图谱内是否添加了{nodeTitleInTip}节点
                      </span>
                    </span>
                  }
                />
              </div>
            </div>
          )
        ) : (
          ""
        )}

        {/*图表区域*/}
        {this._chartData && this._chartData.length > 0 ? (
          this.chartType === "highcharts" ? (
            <div
              id="chart_div"
              className={styles["chart_div"] + " scrollbar"}
              style={{
                ...{
                  left:
                    this.column1_showType == "none"
                      ? 0
                      : this.chartDivLeft == "auto"
                      ? 300
                      : this.chartDivLeft,
                },
                ...this.chartStyle,
              }}
            >
              <HighchartsReact
                highcharts={Highcharts}
                options={this._chartOption}
                style={this.chartCompomentStyle}
              />
            </div>
          ) : (
            <div
              id="chart_div"
              className={styles["chart_div"] + " scrollbar"}
              style={{
                ...{
                  left:
                    this.column1_showType == "none"
                      ? 0
                      : this.chartDivLeft == "auto"
                      ? 300
                      : this.chartDivLeft,
                },
                ...this.chartStyle,
              }}
            >
              <ReactECharts
                echarts={echarts}
                option={this._chartOption}
                style={this.chartCompomentStyle}
                ref={this.eChartRefCallback}
              />
            </div>
          )
        ) : (
          <div
            id="chart_div"
            className={styles["chart_div"] + " scrollbar"}
            style={{
              ...{
                left:
                  this.column1_showType == "none"
                    ? 0
                    : this.chartDivLeft == "auto"
                    ? 300
                    : this.chartDivLeft,
              },
              ...this.chartStyle,
            }}
          ></div>
        )}

        {/*第一列区域*/}
        {column1_showType != "none" && _column1Len > 0 ? (
          <div
            id="column1_list"
            className={styles["level1-list"] + " " + styles["list"]}
          >
            <div
              className={styles["list-title"]}
              style={{ flexDirection: "column" }}
            >
              <div>{column1_Title}</div>
              <div className={styles["sub-title"]}>
                {this.column1_subTitle()}
              </div>
            </div>
            <div
              className={styles["list-title"]}
              style={{
                borderBottom: "1px solid #67727e",
                borderTop: "1px solid #67727e",
                padding: "0.2rem 0.5rem",
                fontSize: ".9rem",
                lineHeight: ".9rem",
                alignItems: "center",
              }}
            >
              <Checkbox
                indeterminate={_column1ClickedIndeterminate}
                onChange={this._column1_onCheckAllBoxChange}
                checked={_column1CheckAll}
              >
                全选
              </Checkbox>
              <Tooltip
                placement={"top"}
                title={"复制选中的" + column1_Title + "到剪切板"}
              >
                <a className={""} onClick={this._column1_onCopyToClipboard}>
                  <Icon name={"snippets"} />
                </a>
              </Tooltip>
              <span style={{ flex: 1 }}>&nbsp;</span>
            </div>
            <div className={styles["list-body"] + " scrollbar"}>
              {this._column1TreeRender2(this._chartData, false)}
              {/* {this._level1ListRender(this._chartData)} */}
            </div>
          </div>
        ) : (
          <div
            id="column1_list"
            className={styles["level1-list"] + " " + styles["list"]}
            style={{
              backgroundColor: "transparent",
            }}
          ></div>
        )}

        {/*第二列区域*/}
        {column2_showType != "none" &&
        _column2Data &&
        _column2Data.length > 0 ? (
          <div
            id="column2_list"
            className={styles["level2-list"] + " " + styles["list"]}
          >
            <div
              className={styles["list-title"]}
              style={{ flexDirection: "column" }}
            >
              <div style={{ display: "flex" }}>
                <div style={{ flex: "0 1 auto" }}>
                  <span style={{ color: "#d3935b" }}>
                    {_column1ClickedName}
                  </span>{" "}
                  的{column2_Title}
                </div>
                <span style={{ flex: 1 }}>&nbsp;</span>
                <div style={{ flex: "0 1 auto" }}>
                  <Tooltip placement={"top"} title={"关闭"}>
                    <a
                      className={""}
                      onClick={() => {
                        this._column1_onClick(null, null, true);
                      }}
                    >
                      <Icon className={styles["close-btn"]} name={"close"} />
                    </a>
                  </Tooltip>
                </div>
              </div>
              <div className={styles["sub-title"]}>
                {this.column2_subTitle()}
              </div>
            </div>
            <div
              className={styles["list-title"]}
              style={{
                borderBottom: "1px solid #67727e",
                borderTop: "1px solid #67727e",
                padding: "0.2rem 0.5rem",
                fontSize: ".9rem",
                lineHeight: ".9rem",
                alignItems: "center",
              }}
            >
              <Checkbox
                indeterminate={_column2ClickedIndeterminate}
                onChange={this._handleOnLevel2CheckAllChange}
                checked={_column2CheckAll}
              >
                全选
              </Checkbox>
              <Tooltip
                placement={"top"}
                title={"复制选中的" + column2_Title + "到剪切板"}
              >
                <a
                  className={""}
                  onClick={this._onCopySelectedLevel2sToClipboard}
                >
                  <Icon name={"snippets"} />
                </a>
              </Tooltip>
              <span style={{ flex: 1 }}>&nbsp;</span>
            </div>
            <div className={styles["list-body"] + " scrollbar"}>
              {_column2Data.map((i, idx) => {
                return (
                  <div
                    className={styles["list-line"]}
                    style={{ cursor: "pointer" }}
                    key={"c-" + idx}
                  >
                    <div className={styles["line-checkbox"]}>
                      <Checkbox
                        checked={
                          _column2CheckedList
                            ? _column2CheckedList.indexOf(idx) > -1
                            : false
                        }
                        idx={idx}
                        name={i["name"]}
                        onChange={this._handleOnlevel2CheckChange}
                      />
                    </div>
                    {this.column2_IconRender(i, idx)}
                    <div
                      className={styles["line-body"]}
                      onClick={() => {
                        this._handlerColumn2Click(idx);
                      }}
                      title={this.column2_HoverTitle(i, idx)}
                    >
                      <div className={styles["line-center"]}>{i["name"]}</div>
                      {this.column2_ValueRender(i, idx)}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          ""
        )}

        {/*底部工具条*/}
        {showToolbar ? (
          <div className={styles["tool-bar"]} style={{ display: "flex" }}>
            <Button
              style={{ display: "none" }}
              onClick={() => {
                this.chartZoom(-1);
              }}
            >
              缩小
            </Button>
            <Button
              onClick={() => {
                this.chartZoom(0);
              }}
            >
              <Icon type={IconTypes.ANT_DESIGN} name="fullscreen-exit" />{" "}
              显示全貌
            </Button>
            <Button
              style={{ display: "none" }}
              onClick={() => {
                this.chartZoom(1);
              }}
            >
              放大
            </Button>
          </div>
        ) : (
          ""
        )}
      </>
    );
  }
}
