import React from 'react';
import PropTypes from 'prop-types';
import {Form, Input, InputNumber, Modal, Checkbox, DatePicker, message, Radio} from 'antd';
import copy from 'copy-to-clipboard';

import PB, {SimplePB} from '@/libs/simplePB';

import {NODE_TYPE_TEXT} from '@/constants/vis.defaultDefine.1';

import ViewDataProvider from '@/components/common/dataProvider/common.dataProvider.view';
import ViewManager from '@/components/common/common.viewManager';

import style from '@/style/common/view/common.view.teamwork.ticket.less';

const varList = ['code', 'title', 'description'];

class TeamworkTicketBatchAddModal extends React.Component {
  state = {
    savingStatus: 'idle',

    amount: 0,
    autoGenerateId: true,
    codeOptionNum: true,
    codeOptionUC: false,
    codeOptionLC: false,
    codeOptionLength: 6,
    autoCreateNode: 1,
    ticketInformationFormat: '',
    expireAtMoment: undefined,
    batchInfo: '',
  };

  generateCode = () => {
    let me = this, text = '', result = [];

    if (me.state.codeOptionNum) {
      text += '0123456789';
    }
    if (me.state.codeOptionUC) {
      text += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    }
    if (me.state.codeOptionLC) {
      text += 'abcdefghijklmnopqrstuvwxyz';
    }
    for (let i = 0; i < me.state.codeOptionLength; i++) {
      result.push(text.charAt(Math.floor(Math.random() * text.length)));
    }
    return result.join('');
  };

  onClose = () => {
    let me = this;

    me.props.onClose();
  };

  onSave = () => {
    let me = this,
      {dataList, batchInfoList} = me.parseBatchInfo(),
      currentTicketPos = 0,
      ticketToCreate = me.state.amount === 0 ? dataList.length : me.state.amount,
      ticketCreated = [],
      needConfirm = true,
      hideMsg,
      msgKey = `${Math.random()}`;

    let onCopyToClipboard = (withDetail = false) => {
      let text = ticketCreated.map(({info, ticket, node}) => {
        if (withDetail) {
          return (node ? [
            ticket.code,
            (ticket.ticketInformation || '(验证信息为空)'),
            (ticket.expireAtTimestamp || '(过期时间为空)'),
            node.id,
            node.fname,
            (node.description || '(节点描述为空)'),
          ] : [
            ticket.code,
            (ticket.ticketInformation || '(验证信息为空)'),
            (ticket.expireAtTimestamp || '(过期时间为空)'),
          ]).join("\r\n");
        } else {
          return `${ticket.code} ${info.title}`;
        }
      }).join(withDetail ? "\r\n\r\n---------------------------------------\r\n\r\n" : "\r\n");

      let result = copy(text, {
        message: '请按下 #{key} 复制文本。',
      });

      if (result) message.success('文本已复制到剪切板。');
    }, onSavingProgressStopped = (succeeded = true) => {
      hideMsg && hideMsg();

      if (ticketCreated.length === 0) return;

      Modal[succeeded ? 'success' : 'error']({
        title: `${succeeded ? '操作完成，' : '操作出错，'}已为您添加 ${ticketCreated.length} 条数据。`,
        content: (
          <span>
            点击复制已添加的
            <a onClick={() => onCopyToClipboard()}>唯一码+节点标题</a>
            或
            <a onClick={() => onCopyToClipboard(true)}>完整数据信息</a>
            至剪切板
          </span>
        ),
        onOk: () => {
          if (succeeded) {
            me.props.onClose();
          }
        }
      })
    }, onError = () => {
      me.setState({savingStatus: 'failed'});
      onSavingProgressStopped(false);
    }, doAddNext = () => {
      let info = {
        code: undefined,
        ticketInformation: '',
        extraInformation: {},
        expireAtTimestamp: me.state.expireAtMoment ? me.state.expireAtMoment.valueOf() : undefined,
        ...(dataList[currentTicketPos] || {}),
      };
      if (me.state.autoGenerateId) {
        info.code = me.generateCode();
      }
      info.title = info.title || '请修改节点信息，对应：' + info.code;

      let ticket = {
        code: info.code,
        ticketInformationType: 0,
        ticketInformation: varList.reduce((text, k) => text.replaceAll(`#{${k}}`, info[k]), `${me.state.ticketInformationFormat}`),
        extraInformation: {},
        expireAtTimestamp: info.expireAtTimestamp,
      }, node = undefined;

      if (me.state.autoCreateNode === 1) {
        node = {
          fname: info.title,
          label: info.title,
          description: info.description,
          version: 'E07',
          type: NODE_TYPE_TEXT,
          owner: 0,
          forceAdd: true,
        };
      } else if (me.state.autoCreateNode === 2) {
        node = me.props.viewDataProvider.getNode({filter: n => n.fname === info.title});
        if (node && node.length === 1) {
          node = node[0];
        } else {
          message.error(`找不到以 ${info.title} 为名称的唯一确定节点，请再次确认。`);
          onSavingProgressStopped(false);
          return;
        }
      }

      if (needConfirm) {
        hideMsg && hideMsg();
        Modal.confirm({
          title: '请确认下列信息：',
          content: (
            <React.Fragment>
              <div style={{maxHeight: '10rem', overflow: 'hidden auto'}}>
                <table className={style['ticket-confirm-table']}>
                  <tbody>
                  <tr><td>唯一码：</td><td>{ticket.code}</td></tr>
                  <tr><td>验证信息：</td><td>{ticket.ticketInformation}</td></tr>
                  <tr><td>过期时间：</td><td>{me.state.expireAtMoment ? me.state.expireAtMoment.format('YYYY-MM-DD HH:mm:ss') : '不限'}</td></tr>
                  <tr><td>相连节点：</td><td>{(['否', '新节点', '已有节点'])[me.state.autoCreateNode]}</td></tr>
                  {node ? (<tr><td>节点名称：</td><td>{node.fname}</td></tr>) : null}
                  {node ? (<tr><td>节点描述：</td><td>{node.description}</td></tr>) : null}
                  </tbody>
                </table>
              </div>
              <br />
              <Checkbox defaultChecked={false} onChange={e => needConfirm = !e.target.checked}>本次操作不再提示</Checkbox>
            </React.Fragment>
          ),
          autoFocusButton: 'ok',
          okText: '确认添加',
          onOk: () => doSave({info, ticket, node}),
          cancelText: '停止操作',
        });
      } else {
        doSave({info, ticket, node});
      }
    };

    let doAddTicket = ({info, ticket, node}) => {
      ViewManager.addViewTeamworkTicket(me.props.viewDataProvider.viewId, ticket).then(() => {
        batchInfoList.shift();
        ticketCreated.push({info, ticket, node});
        me.setState({savingStatus: 'success', batchInfo: batchInfoList.join("\r\n")}, () => {
          if (ticketCreated.length === ticketToCreate) {
            me.props.bus.emit('teamwork', 'ticket.list.changed', {viewId: me.props.viewDataProvider.viewId});
            onSavingProgressStopped();
          } else {
            currentTicketPos++;
            doAddNext();
          }
        });
      }).catch(() => {
        if (me.state.autoCreateNode === 1) {
          // 删除节点
          // noinspection JSIgnoredPromiseFromCall
          me.props.viewDataProvider.removeNode(node.id);
        }
        onError();
      })
    }, doSave = ({info, ticket, node}) => {
      hideMsg = message.loading({content: `正在保存第 ${ticketCreated.length + 1} 条，共 ${ticketToCreate} 条...`, key: msgKey, duration: 0});
      me.setState({savingStatus: 'processing'}, () => {
        if (me.state.autoCreateNode === 1) {
          me.props.viewDataProvider.addTextNodes([node]).then(({nodes}) => {
            if (nodes && nodes.length > 0) {
              ticket.extraInformation.afterUse = [{
                action: 'transfer_owner',
                nodeId: nodes[0].id,
              }];
              node = nodes[0];
              doAddTicket({info, ticket, node});
            } else {
              onError();
            }
          }).catch(() => onError());
        } else {
          if (me.state.autoCreateNode === 2) {
            ticket.extraInformation.afterUse = [{
              action: 'transfer_owner',
              nodeId: node.id,
            }];
          }
          doAddTicket({info, ticket, node});
        }
      });
    };

    doAddNext();
  };

  parseBatchInfo = () => {
    let me = this, dataList = [], batchInfoList = [];

    if (!me.state.autoGenerateId || me.state.autoCreateNode) {
      (me.state.batchInfo || '').replaceAll("\r", "\n").split("\n").forEach(l => {
        let pos, info = {};

        l = (l || '').trim();
        if (!l || l === '') return;

        batchInfoList.push(`${l}`);

        if (!me.state.autoGenerateId) {
          pos = l.indexOf(' ');
          if (pos > 0) {
            info.code = l.substring(0, pos);
            l = l.length > (pos + 1) ? l.substring(pos + 1) : '';
          }
        }

        pos = l.indexOf('$$$');
        if (pos >= 0) {
          info.title = l.substring(0, pos);
          info.description = l.substring(pos + 3);
        } else {
          info.title = l;
        }

        dataList.push(info);
      });
    }

    return {dataList, batchInfoList};
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    let me = this;

    if (!prevProps.visible && me.props.visible) {
      me.setState({
        savingStatus: 'idle',

        amount: 0,
        autoGenerateId: true,
        codeOptionNum: true,
        codeOptionUC: false,
        codeOptionLC: false,
        codeOptionLength: 6,
        autoCreateNode: 1,
        ticketInformationFormat: '',
        expireAtMoment: undefined,
        batchInfo: '',
      });
    }
  }

  render() {
    let me = this, batchInfoFormat, batchInfoPlaceholder;

    if (me.state.autoGenerateId) {
      batchInfoFormat = '节点标题$$$节点描述(可选)';
      batchInfoPlaceholder = '名称$$$描述';
    } else if (!me.state.autoGenerateId) {
      batchInfoFormat = '唯一码(必填) <空格隔开> 节点标题$$$节点描述(可选)';
      batchInfoPlaceholder = '666666 名称$$$描述';
    }

    return (
      <Modal
        title={'批量添加'}
        visible={me.props.visible}
        clasable={me.state.savingStatus !== 'processing'}
        centered={true}
        onCancel={me.onClose}
        cancelText={'取消'}
        onOk={me.onSave}
        okText={'开始添加'}
        okButtonProps={{disabled: (me.state.amount === 0 && !me.state.batchInfo) || (!me.state.autoGenerateId && !me.state.batchInfo)}}
        width={'70rem'}
        destroyOnClose={true}
      >
        <Form>
          <Form.Item label={'设置门票数量（0表示依据最下方的 "邀请码批量信息" 自动判定）'}>
            <InputNumber min={0} precision={0} value={me.state.amount} onChange={amount => me.setState({amount})} />
          </Form.Item>
          <Form.Item label={'自动生成唯一码'}>
            <Checkbox onChange={e => me.setState({autoGenerateId: e.target.checked})} checked={me.state.autoGenerateId}>启用</Checkbox>
            <Checkbox disabled={!me.state.autoGenerateId} onChange={e => me.setState({codeOptionNum: e.target.checked})} checked={me.state.codeOptionNum}>数字</Checkbox>
            <Checkbox disabled={!me.state.autoGenerateId} onChange={e => me.setState({codeOptionUC: e.target.checked})} checked={me.state.codeOptionUC}>大写字母</Checkbox>
            <Checkbox disabled={!me.state.autoGenerateId} onChange={e => me.setState({codeOptionLC: e.target.checked})} checked={me.state.codeOptionLC}>小写字母</Checkbox>
            长度：
            <InputNumber disabled={!me.state.autoGenerateId} max={15} min={1} precision={0} value={me.state.codeOptionLength} onChange={v => me.setState({codeOptionLength: v})} />
          </Form.Item>
          <Form.Item label={'验证展示信息（100字内，支持变量：#{title}、#{code}、#{description}）'}>
            <Input.TextArea
              maxLength={100}
              rows={3}
              value={me.state.ticketInformationFormat}
              onChange={e => me.setState({ticketInformationFormat: e.target.value})}
              placeholder={"如：\n欢迎来自 #{title} 的嘉宾\n欢迎 #{title} 参加本次峰会"}
            />
          </Form.Item>
          <Form.Item label={'过期时间'}>
            <DatePicker
              readOnly={me.state.readOnly}
              value={me.state.expireAtMoment}
              onChange={(m) =>
                me.setState({expireAtMoment: m, expireAtTimestamp: m ? m.valueOf() : undefined})}
              showTime={true}
              format={'YYYY-MM-DD HH:mm:ss'}
            />
          </Form.Item>
          <Form.Item label={'自动绑定节点'}>
            <Radio.Group value={me.state.autoCreateNode} onChange={e => {me.setState({autoCreateNode: e.target.value})}}>
              <Radio value={0}>不绑定</Radio>
              <Radio value={1}>自动创建</Radio>
              <Radio value={2}>关联已有</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item label={`邀请码批量信息（一行一个，格式：${batchInfoFormat}）`}>
            <Input.TextArea
              rows={10}
              value={me.state.batchInfo}
              onChange={e => me.setState({batchInfo: e.target.value})}
              placeholder={`如：\n${batchInfoPlaceholder}`}
            />
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

TeamworkTicketBatchAddModal.defaultProps = {
  bus: PB,
};

TeamworkTicketBatchAddModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  viewDataProvider: PropTypes.instanceOf(ViewDataProvider).isRequired,
  bus: PropTypes.instanceOf(SimplePB),
};

export default TeamworkTicketBatchAddModal;