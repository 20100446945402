import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'antd';

import PB, { SimplePB } from '@/libs/simplePB';
import Icon from '@/components/common/common.icon';

import style from '@/style/components/main.toolbar.stortcutMenu.less';
import { IconTypes } from '@/constants/common';

class shareModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {
		let me = this;
		/*if (me.props.viewInfo.hasCover === 1) {
			requestAnimationFrame( () => {
				ViewManager.loadViewCover(me.props.viewId).then(coverRes => {
					if (coverRes) {
						me.setState({
							uploadedCoverImgBase64Url: coverRes,
						})
					}
				});
			});
		}*/

		me.props.bus.sub(me, 'graph', 'export.done', () => {
			me.props.onClose();
		});
	}

	componentWillUnmount() {
		this.props.bus.remove(this);
	}

	render() {
		let me = this;
		// const {viewInfo, callback} = me.props;
		// const path = window.location.host + `/mainview/relation/${viewInfo.viewId}`;

		return (
			<Modal
				title={<div>
					<Icon type={IconTypes.ICON_FONT}
					      name="icon-fenxiang"
					      style={{marginRight: 10}}/>
					导出图片
				</div>}
				visible={me.props.visible}
				centered={true}
				destroyOnClose={true}
				maskClosable={true}
				width={'43rem'}
				footer={null}
				onCancel={me.props.onClose}
				bodyStyle={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
			>
				<div className={style['shortcut-menu-content']}>
					<div className={style['share-demo-img']}>
						<img src="/assets/share-demo-img.jpg" alt="导出图片示意图"/>
					</div>
					<div className={style['share-item']}>
						<dl className={style['description-list']}>
							<dd>
								<Icon type={IconTypes.ICON_FONT}
								      name="icon-yuandianzhong"
								      style={{color: '#a5a5a5', marginRight: 6}}/>
								分享给领导&nbsp;工作汇报&nbsp;一目了然
							</dd>
							<dd>
								<Icon type={IconTypes.ICON_FONT}
								      name="icon-yuandianzhong"
								      style={{color: '#a5a5a5', marginRight: 6}}/>
								分享给同事&nbsp;方案讲解&nbsp;言简意赅
							</dd>
							<dd>
								<Icon type={IconTypes.ICON_FONT}
								      name="icon-yuandianzhong"
								      style={{color: '#a5a5a5', marginRight: 6}}/>
								分享给朋友&nbsp;知识传播&nbsp;快速学习
							</dd>
						</dl>

						{/*<div className={style['share-btns']}>
							<Button
								className={style['share-btn']}
								type='primary'
								onClick={e => {
									e.stopPropagation();
									ViewManager.copyUrlToBoard(viewInfo, callback);
									me.props.onClose();
								}}
							><Icon type={IconTypes.ICON_FONT} name="icon-fenxiang"/>分享链接</Button>
							<div className={style['tips']}>可直接打开</div>
						</div>*/}
						<div className={style['tips']} style={{marginRight: '-0.9rem'}}>
							Tips：
							<div style={{flex: 1}}>
								导出的高清图片尺寸较大，可用于印刷。<br/>
								如需去除水印或其他需求，可联系客服。
							</div>
						</div>
						<div className={style['email']}>
							<div className={style['email-icon']}>
								<Icon type={IconTypes.ICON_FONT}
								      name="icon-email"
									style={{fontSize: '0.8rem'}}
								/>
							</div>
							<a href="mailto:hi@joinmap.ai">hi@joinmap.ai</a>
						</div>
						<div className={style['share-btns']}>
							<div className={style['share-btn-tips']}>可印刷贴墙</div>
							<Button className={style['share-btn']}
							        type='primary'
							        size={'large'}
							        onClick={e => {
								        e.stopPropagation();
								        me.props.bus.emit('graph', 'export.do', {
									        containerId: 'network',
									        type: 'image/jpeg',
									        // scale: '4',
									        title: me.props.viewInfo.name,
								        });
							        }}
							><Icon type={IconTypes.ICON_FONT} name="icon-fenxiang"/>导出图片</Button>
						</div>
					</div>
				</div>
			</Modal>
		);
	}
}

shareModal.defaultProps = {
	bus: PB,
	visible: false,
	viewInfo: {name: '', desc: '', hasCover: false},
};

shareModal.propTypes = {
	bus: PropTypes.instanceOf(SimplePB),
	visible: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	viewInfo: PropTypes.object.isRequired,
};

export default shareModal;
