import React from 'react';
// noinspection ES6CheckImport
// import {renderToString} from 'react-dom/server';
import PropTypes from 'prop-types';
import ReactECharts from 'echarts-for-react';
import eCharts from 'echarts/lib/echarts';
import moment from "moment";

import 'echarts/lib/chart/scatter';
// import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/title';

class StatisticsUserNodeChart extends React.Component {
  eChartsRef = undefined;

  getOption = () => ({
    tooltip: false,
    title: {
      text: `近一周节点统计`,
      left: 'center',
    },
    xAxis: {
      type: 'time',
      min: moment().hour(0).minute(0).second(0).millisecond(0)
        .subtract(7, 'd').valueOf(),
      max: moment().hour(0).minute(0).second(0).millisecond(0)
        .add(1, 'd').valueOf(),
      interval: 86400000,
      axisLabel: {
        formatter: value => {
          if (value === moment().hour(0).minute(0).second(0).millisecond(0)
            .subtract(7, 'd').valueOf()) {
            return '';
          } else {
            let contentMoment = moment(value).subtract(1, 'd');
            return contentMoment.format('MM-DD') + '\n' + contentMoment.format('ddd');
          }
        },
        padding: [0, 0, 0, this.eChartsRef ? -this.eChartsRef.echartsElement.clientWidth * 0.1 : 0],
      },
    },
    yAxis: {
      type: 'value',
      min: 0,
      max: 60 * 24,
      interval: 4 * 60,
      axisLabel: {
        formatter: value => `${
          value === 60 * 24 ? '24:00' : moment().hour(Math.floor(value / 60)).minute(value % 60)
            .format('H:mm')
          }`,
      },
    },
    series: [{
      name: this.props.userInfo.nick,
      smooth: true,
      showSymbol: false,
      type: 'scatter',
      cursor: 'default',
      data: this.props.userStatistics.points.data
        .map((value, idx) => ([value[0], value[1], 1, idx, value[4], value[2]])),
      itemStyle: {
        color: '#28a745',
      },
      areaStyle: {},
    }],
  });

  render() {
    let me = this;

    return (
      <ReactECharts
        echarts={eCharts}
        option={me.getOption()}
        style={{height: '24rem'}}
        ref={e => {
          me.eChartsRef = e;
          if (e) {
            requestAnimationFrame(() => {
              e.getEchartsInstance().setOption(me.getOption());
            });
          }
        }}
      />
    );
  }
}

StatisticsUserNodeChart.defaultProps = {
  userStatistics: {points: {data: []}},
};

StatisticsUserNodeChart.propTypes = {
  userStatistics: PropTypes.object,
  userInfo: PropTypes.object.isRequired,
};

export default StatisticsUserNodeChart;