import ViewDataProvider from '@/libs/view/network/ViewDataProvider';
import {withPromiseMessageDecorator} from '@/libs/core-decorators';
import {viewOperationMessages} from "@/constants/messages";
import {showPageLoading} from "@/components/common/common.message";

const overrideMessages = {};

/**
 * @type {typeof ViewDataProvider}
 */
const ViewDataProviderWrapper = withPromiseMessageDecorator({
  processing: (key, args) => {
    let msg = viewOperationMessages[key];
    if (key === 'loadData' && args[1]) {
      msg = viewOperationMessages['loadDynamicData'];
    }
    if (overrideMessages[key] !== undefined) {
      msg = overrideMessages[key];
      delete overrideMessages[key];
    }
    if (msg !== false) {
      return showPageLoading(msg);
    } else {
      return () => {};
    }
  },
  success: (key, onProcessingResult) => {
    onProcessingResult();
  },
  failure: (key, onProcessingResult) => {
    onProcessingResult();
  },
})(ViewDataProvider);

// noinspection JSUnusedGlobalSymbols
export const overrideNextMessage = (method, msg) => {
  overrideMessages[method] = msg;
};

export default ViewDataProviderWrapper;