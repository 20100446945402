import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  message,
  Modal,
  notification,
  Button,
  Tooltip,
  Divider, Checkbox,Menu
} from 'antd';
import UserAvatar from 'react-user-avatar';
import {IDBPObjectStore} from 'idb';
import EventListener from 'react-event-listener';

import style from '@/style/components/main.relation.less'

import {DataSet} from '@/libs/vis';
import RelationDataProvider from "@/components/common/dataProvider/common.dataProvider.relation";
import NodeDataProvider from '@/components/common/dataProvider/common.dataProvider.node.js';
import NodeInfoCommonTooltip, {getNodeTooltipContent} from "@/components/mainView/nodeInfo/main.nodeInfo.common.tooltip";

import ViewUploadBannerComponent from '@/components/common/view/common.view.uploadBannerComponent';

import {
  getNodeDisplayTitle,
  getNodeIcon,
  myVis,
  NODE_TYPE_COMPANY,
  NODE_TYPE_DATASET,
  NODE_TYPE_DOCS,
  NODE_TYPE_GOV,
  NODE_TYPE_GRAPH,
  NODE_TYPE_INDEX,
  NODE_TYPE_INSTITUTE,
  NODE_TYPE_NATURE,
  NODE_TYPE_NEWS_ACTIVITIES,
  NODE_TYPE_ORG,
  NODE_TYPE_PAPER,
  NODE_TYPE_PATENT,
  NODE_TYPE_POLICY,
  NODE_TYPE_TAG,
  NODE_TYPE_TALENT,
  NODE_TYPE_TEXT,
  NODE_TYPE_PROJECT,
  STATUS_GREW,
  TYPE_FIELD_NAME,
} from '@/constants/vis.defaultDefine.1';
import PB from '@/libs/simplePB'

import Edge from '../../libs/view/Edge';
import Node from '../../libs/view/Node';

import {getCacheStore} from '@/storage/idbStorage';

import Context from '@/components/mainView/main.toolbar.context'
import {
  ADD_TO_GRAPH,
  ADD_TO_VIEW,
  EdgeEvents,
  NetworkEvents,
  NodeEvents,
  REMOVE_FROM_GRAPH,
  REMOVE_FROM_VIEW,
} from "@/libs/view/network/events";
import ViewDataProvider, {overrideNextMessage} from '@/components/common/dataProvider/common.dataProvider.view';
import {NetworkDataLoadingStatus, NodeExpandingStatus} from "@/libs/view/network/status";

import {createExpandNodeGuide} from "@/components/guide"
import NodeInfoAttachmentListModal from "@/components/mainView/nodeInfo/main.nodeInfo.attachmentListModal";
import NodeNormalContextMenu from "@/components/mainView/node.normal.contextMenu";
import CommonEditModal from "@/components/mainView/node/common.edit";
import TextEditModal from "@/components/mainView/node/text.edit";
import CommonEdgeEditModal from "@/components/mainView/edge/common.edit";
import FileUploadModal from "@/components/mainView/main.fileUploadModal";
import Teamwork from "@/components/common/view/teamwork/common.view.teamwork.member";
import SetViewInfoModal from '@/components/common/view/common.view.info.setInfoModal';
import GraphExport from '@/components/common/view/common.view.graph.export';
import ExploreCustomRequestModal from "@/components/common/view/explore/common.view.explore.customRequestModal";
import NodeDeleteModal from '@/components/mainView/node/node.delete';
import ViewWordCloudModal from '@/components/common/view/common.view.wordCloudModal';
import CopyNodeToViewModal from "@/components/mainView/node/copy.node.to.view";

const configStyle = {
  _default: {
    bottom: 'calc(50% - 18px)',
    left: 'calc(50% + 34px)',
  },
  gradeT: {
    bottom: 'calc(50% - 45px)',
    left: 'calc(50% + 57px)',
    minHeight: '90px',
  },
  gradeD: {
    bottom: 'calc(50% - 12px)',
    left: 'calc(50% + 24px)',
  },
};

// 节点选择历史记录数上限
const selectedNodeHistorySize = 2;

const rankNameMap = {
  word_freq: '词汇排名',
  company_con: '企业排名',
  custom: '定制排名',
};

// 迁移到MyNetwork3
import MyNetwork, {myVisNetworkStyle} from '@/libs/myVis/MyNetwork3';
import RelationPresentation from "@/components/common/relation/common.relation.presentation";
import NodePresentation from "@/components/common/node/common.node.presentation";
import GuideModal from "@/components/mainView/main.guideModal";
import VisualToolMini from "@/components/common/relation/common.relation.visualToolMini";
import BigArrow from "@/components/common/relation/common.relation.bigArrow";
import ImageLightBox from "@/components/common/common.imageLightBox";
import {showErrorMessage, getDelayHideFn, showPageLoading} from "@/components/common/common.message";
import CountUp from "react-countup";
import Icon from "@/components/common/common.icon";
import RankListModal from "@/components/common/view/common.view.rank.listModal";
import MemberSetBadgeModal from '@/components/common/view/common.view.member.setBadge';
import MainAiConsoleMessageCommonNodeInfo
  from "@/components/mainView/aiConsoleMessage/main.aiConsoleMessage.commonNodeInfo";
import {IconTypes} from "@/constants/common";
import MainAiConsoleMessageEdgeInfo from "@/components/mainView/aiConsoleMessage/main.aiConsoleMessage.edgeInfo";
import SysConfig from "@/constants/sys.config";
import PersonMultiRelationModal from '@/components/common/view/common.view.person.multiRelationshipModal';
import SmartSearchUserModal from '@/components/common/view/common.view.find.smartSearchUserModal';
import {saveJsonFile} from "@/components/common/common.functions";
import {viewBackground} from "@/constants/view";
// import ExploreNormal from "@/components/common/node/explore/common.node.explore.normal";
import GraphNormalContextMenu from "@/components/mainView/graph.normal.contextMenu";
import ExploreOverridePanel from '@/components/common/node/explore/common.node.explore.override.panel';
import ExploreRelatedNodePanel from '@/components/common/node/explore/common.node.explore.relatedNode.panel';
import PresentationDrawer from "@/components/common/view/presentation/common.view.presentation.drawer";
import RelationComponentConnector from "@/components/common/relation/common.relation.componentConnector";
import Desktop from "@/components/mainView/main.desktop";
// import PresentationStory from "@/components/common/view/presentation/common.view.presentation.story";
import ExtendedActionSettingsEditModal from "@/components/mainView/node/extendedActionSettings.edit";
import ExtendedActionsViewer from "@/components/common/view/common.view.extendedActions.viewer";
import {getNodeLevelInGraph} from "@/libs/graph-utils";
import MicroServiceSpecialNodeExpand
  from '@/components/common/view/microService/special/common.view.microService.special.nodeExpand';
import NodeAssessModal from "@/components/common/node/assess/common.node.assess.modal";
import NodeExpandAnalysis from "@/components/common/node/explore/common.node.explore.analysis";
import AnalysisIncidentList from "@/components/common/node/explore/common.node.explore.analysis.incident";
import InputSearchFromDB from "@/components/common/node/explore/common.node.explore.search.input";
import AnalysisNodeForEdge from "@/components/common/node/explore/common.node.explore.analysis.nodeforedge";
import intl from 'react-intl-universal';

const directionEdge = new Edge({
  id: `direction-${Math.random()}`,
  from: undefined,
  to: undefined,
  arrows: 'to',
  status: STATUS_GREW,
  userConfirmed: false,
  meta: {smooth: 0},
  _brightness: 0.8,
});

const NUM_ITERATIONS = 4;

class Relation extends React.Component {
  // 迁移到MyNetwork3
  /**
   * @type {undefined|MyNetwork}
   */
  myNetwork = undefined;
  // network 容器
  container = undefined;

  // ----以下原代码------------------------
  // 当前图谱的数据
  data = {'nodes': new DataSet(), 'edges': new DataSet()};
  // vis network 对象
  network = false;
  // 被聚焦的节点
  focusedNode = undefined;
  // 是否时在拖动的状态,记录被拖动节点的id
  draggingNodeId = false;
  // 拖动时鼠标位置与节点位置的误差
  draggingDelta = undefined;
  // 被拖动节点原始位置
  draggingNodeOriginalPosition = {};
  // 相对点中心数据对象
  relatedPoint = undefined;
  // 被拖动点原中心数据对象
  draggingNodePoint = undefined;
  //当前选中节点ID，空时为undefined
  currentNodeId = undefined;
  // 待连接的节点
  idOfNodeToConnect = undefined;
  // 选中节点历史记录
  selectedNodeHistory = [];
  // 用户是否正在双击
  doubleClicked = false;
  // 正在等待确认是否双击
  clicking = false;
  // 隐藏所有边
  allEdgesHidden = false;
  // 当前选中的内容（正常模式下）
  currentSelection = {nodes: [], edges: []};
  // 我的收藏节点ID
  favorite = {
    status: 'idle',
    nodes: [],
  };
  // 重要节点收藏节点ID
  viewFavorite = {
    status: 'idle',
    nodes: [],
  };

  detailMessageKeys = {};
  relatedNodeMessageKeys = {};
  overrideMessageKeys = {};

  delayMarkAsInterestedTimeout = undefined; // 延时标记选中节点为感兴趣的节点

  lastLightNodeConfig = {status: 'none', fn: undefined}; // 最后一次点亮节点配置

  previousLightNodeConfigBeforePresentation = {status: 'none', fn: undefined}; // 漫游之前点亮节点配置

  previousSelectionBeforePresentation = undefined; // 漫游之前画面选中状态

  delayedFocusFnTimeout = undefined; // 延迟锁定节点函数

  delayedFocusNodeIdMap = {}; // 延时锁定节点ID

  backgroundCache = {
    initialized: false,
    coordinates: [],
  }; // 底图缓存

  /**
   * 方向键是否被按下
   *
   * @type {{
   *   left: boolean,
   *   up: boolean,
   *   right: boolean,
   *   down: boolean,
   *   zoomOut: boolean,
   *   zoomIn: boolean,
   *   holding: number,
   *   movingInterval: undefined|number
   * }}
   */
  ctrlKeyDown = {
    up: false,
    down: false,
    left: false,
    right: false,
    zoomOut: false, // 缩小
    zoomIn: false, // 放大
    holding: 0, // 长按计数
    movingInterval: undefined, // 移动函数
    story: false,
    done: false, //执行标记
    duration: [false,false,false,false,false,false,false]  //up,down,left,right,zoomOut,zoomIn, story
  };

  /**
   * @type {IDBPObjectStore<*, ['local-cache-data'], 'local-cache-data', 'readwrite'>}
   */
  localDataCacheStore = undefined;

  /**
   * @type {IDBPObjectStore<*, ['view-cache-data'], 'view-cache-data', 'readwrite'>}
   */
  viewDataCacheStore = undefined;

  constructor(props) {
    super(props);
    // console.log(props)
    this.state = {
      viewId: undefined, // 当前图谱id
      viewInfo: undefined, // 当前图谱信息
      dataLoadingStatus: NetworkDataLoadingStatus.IDLE, // 数据加载状态
      dataLoadingErrorCode: 0, // 数据加载错误码
      dataLoadingErrorMsg: '', // 数据加载错误信息
      /*allDatasets, // 包含图谱配置+图谱数据+联想数据+删除数据+更新数据 的数据包*/
      showMask: 'none', // 切换vis network 的遮罩
      showDelConfirm: false, // 显示delete确认框
      confirmOK: null, // confirm的ok响应
      confirmCancel: null, // confirm的cancel响应,

      multiLines: [], // 多节点连线的数据
      showViewDesc: false, // 显示图谱的描述内容
      showContextMenu: false,
      contextMenuX: 0,
      contextMenuY: 0,

      contextNodeId: undefined,
      contextEdgeId: undefined,
      contextSelection: undefined,

      extraInfoListModalVisible: false,
      extraInfoListModalNodeId: undefined,

      addModalType: false,
      addModalExtra: undefined,
      addModalProcessing: false,

      editModalType: false,
      editModalTarget: undefined,
      editModalExtra: undefined,
      editModalProcessing: false,

      deleteModalType: false,
      deleteModalTarget: undefined,

      showUploadModal: false,
      uploadModalNodeId: undefined,

      showGuideModal: false, // 是否展示图谱操作指南图片

      showNodeMatchNoticeModal: false,
      matchedNodes: [],
      totalNodesToAdd: 0,
      useNewNodeCallback: undefined,
      useOriginalCallback: undefined,
      noDecisionCallback: undefined,

      showNodeTooltip: false,
      nodeTooltipInfo: undefined,

      showSetViewInfoModal: false, // 设置图谱基本信息弹框显示隐藏

      showRankModal: false,
      rankType: undefined,

      showExploreCustomRequestModal: false, // 是否显示定制发现对话框

      showMemberBadgeFormModal: false, // 是否显示给用户奖励对话框
      badgeNode: undefined,

      showMultiRelationShipModal: false, // 多人社会关系弹框

      /*recommendationByConnection: [],*/ // 通过关联关系推荐的节点
      aiConsoleStatus: 'active', // AI对话框状态

      activatedNodeId: undefined, // 当前用户关注的节点ID

      showSmartSearchUserModal: false, // 发现用户
      smartSearchUserByNode: undefined, // 发现用户根据哪个节点

      showWordCloudModal: false, // 图谱词云弹框

      nodeId: undefined, // 当前节点id

      // 扩展功能相关
      showExtendedActionSettingsModal: false,
      extendedActionRelatedNodeId: '',
      extendedActionIdx: 0,
      extendedActionName: '',
      extendedActionDescription: '',
      extendedActionIconName: 'icon-extended-feature',
      extendedActionIconType: IconTypes.ICON_FONT,
      extendedActionUrl: '',
      extendedActionUserToken: false,
      extendedActionSettingsModalProcessing: false,
      recommendation: [false],

      // 刷新
      refresh: false,

      rememberOperation: 0,
      rememberOperationState: false,

      copyNodeToViewType: 0,
      copyInfo: undefined,
      copyNodeToViewModalProcessing: false,
      activatedNodeList: []
    };

    getCacheStore('view-cache-data').then(store => this.viewDataCacheStore = store);
  }

  inMultiLineMode = false;

  processingNodeTooltip = undefined;

  backgroundImageConfig = {
    image: undefined,
    imageLoaded: false,
    dataUrl: undefined,
  };

  // 标题的替换图片数据
  bannerImageConfig = {
    dataUrl: undefined,
    imageLoaded: false,
  };

  currentSearchEngine = SysConfig.sysSearchEngine;

  calculateRelatedPoint = node => {
    let me = this;

    return {
      nodeId: node.id,
      center: {x: node.x, y: node.y},
      lines: [ // Ax + By + C = 0, sqrt(A^2 + B^2), A^2 + B^2
        [1, 0, -node.x, 1, 1],
        [1, 1, -(node.x + node.y), Math.sqrt(2), 2],
        [0, 1, -node.y, 1, 1],
        [1, -1, node.y - node.x, Math.sqrt(2), 2],
      ],
    };
  };

  setMagneticPositionToNode = (pointerPosition, node, nodes, event) => {
    let me = this, relatedPoint = undefined, minDelta = 0, maxDelta = 0, showDirectionEdge = false;
    nodes = nodes || [node];

    // 计算被拖动节点的中心位置
    let fixedPosition = {x: me.draggingDelta.x + pointerPosition.x, y: me.draggingDelta.y + pointerPosition.y};
    if (isNaN(fixedPosition.x) || isNaN(fixedPosition.y)) {
      console.warn('fixedPosition is NaN, positions: ',
        me.draggingDelta.x, me.draggingDelta.y, pointerPosition.x, pointerPosition.y);
      fixedPosition = {x: pointerPosition.x, y: pointerPosition.y};
    }

    if (event['changedPointers'][0].shiftKey && me.draggingNodePoint) {
      relatedPoint = me.draggingNodePoint;
      minDelta = -2;
      maxDelta = -1;
      showDirectionEdge = false;
    } else if (me.relatedPoint) {
      relatedPoint = me.relatedPoint;
      minDelta = 25;
      maxDelta = 50;
      showDirectionEdge = true;
    }

    if (relatedPoint) {
      // 计算被拖动节点的中心位置距离四条直线的距离
      let dArray = relatedPoint.lines.map(
        params => Math.abs(params[0] * fixedPosition.x + params[1] * fixedPosition.y + params[2]) / params[3]
      ), targetLineIdx = -1, targetDistance = minDelta + 1;
      dArray.forEach((d, idx) => {
        if ((minDelta < 0 || d <= minDelta) && (targetDistance < 0 || d <= targetDistance)) {
          targetDistance = d;
          targetLineIdx = idx;
        }
      });

      if (targetLineIdx < 0) {
        // 再案按角度计算
        let dX = fixedPosition.x - relatedPoint.center.x;
        let dY = fixedPosition.y - relatedPoint.center.y;
        let slope = dX / dY;
        if (slope > -0.1 && slope < 0.1) targetLineIdx = 0;
        else if (slope > -1.1 && slope < -0.9) targetLineIdx = 1;
        else if (slope > 10 || slope < -10) targetLineIdx = 2;
        else if (slope > 0.9 && slope < 1.1) targetLineIdx = 3;
        if (maxDelta > 0 && dArray[targetLineIdx] > maxDelta) {
          targetLineIdx = -1;
        }
      }

      if (targetLineIdx >= 0) {
        // x1 = (B^2 * x0 - AC - ABy0) / (A^2 + B^2)
        // y1 = -(Ax1 + C) / B
        let A = relatedPoint.lines[targetLineIdx][0], B = relatedPoint.lines[targetLineIdx][1],
          C = relatedPoint.lines[targetLineIdx][2];
        let x = B === 0 ? -C : (B * B * fixedPosition.x - A * B * fixedPosition.y - A * C) / relatedPoint.lines[targetLineIdx][4];
        let y = B === 0 ? fixedPosition.y : 0 - (A * x + C) / B;
        let diffX = x - me.draggingNodeOriginalPosition[node.id].x;
        let diffY = y - me.draggingNodeOriginalPosition[node.id].y;
        nodes.forEach(node => {
          if (node.fixed) {
            node.x = node.fx = me.draggingNodeOriginalPosition[node.id].x + diffX;
            node.y = node.fy = me.draggingNodeOriginalPosition[node.id].y + diffY;
          }
        });
        me.data.nodes.update(nodes, 'magicId$doNotAutoStartSimulation');
        let de = me.data.edges.get(directionEdge.id);
        if (showDirectionEdge && !de) {
          directionEdge.from = relatedPoint.nodeId;
          directionEdge.to = node.id;
          me.data.edges.update(directionEdge, 'magicId$doNotAutoStartSimulation');
        } else if (!showDirectionEdge && de) {
          me.data.edges.remove(directionEdge.id);
        }
      } else {
        if (me.data.edges.get(directionEdge.id)) {
          me.data.edges.remove(directionEdge.id);
        }
      }
    }
  };

  /**
   * vis不同状态下的事件响应
   * @type {*}
   */
  EventsForDifferentMode = (me => ({
    default: {
      'dragStart': {
        before: (params) => {
          console.log('relation.1 before dragStart');
          if (params.nodes.length >= 1) {
            let nodeId = me.myNetwork.network.getNodeAt(params.pointer.DOM);
            let node = nodeId ? me.data.nodes.get(nodeId) : undefined;
            if (!node) {
              message.error('找不到指定的节点');
              return false;
            }
            let nodes = me.data.nodes.get(params.nodes);

            if (nodes.findIndex(node => node.fixed && !node._locked) < 0) return true; // 没有固定节点，继续拖动

            const position = params.pointer.canvas;
            me.draggingDelta = {x: node.x - position.x, y: node.y - position.y};
            nodes.forEach(n => me.draggingNodeOriginalPosition[n.id] = {x: n.x, y: n.y});
            if (isNaN(me.draggingDelta.x) || isNaN(me.draggingDelta.y)) {
              console.warn('draggingDelta is NaN, params: ', me.draggingDelta.x, me.draggingDelta.y, position.x, position.y);
              me.draggingDelta = {x: 0, y: 0};
            }

            // 判断是否存在相对点
            let relatedNode = me.selectedNodeHistory[0];
            if (nodeId === me.currentNodeId) {
              relatedNode = me.selectedNodeHistory[1] ? me.selectedNodeHistory[1] : undefined;
            }
            if (relatedNode && params.nodes.find(nodeId => nodeId === relatedNode.id)) {
              relatedNode = undefined;
            }
            // 获取最新的节点信息
            relatedNode = relatedNode ? me.props.networkRef.getNode(relatedNode.id) : undefined;
            if (relatedNode && relatedNode.id !== node.id && relatedNode.fixed) {
              me.relatedPoint = me.calculateRelatedPoint(relatedNode);
            } else {
              me.relatedPoint = undefined;
            }
            me.draggingNodePoint = me.calculateRelatedPoint(node);

            const currentUserId = parseInt(localStorage.getItem("userId"));
            const viewCreateUserId = parseInt(me.state.viewInfo.userId);
            const enableCrossEdit = me.props.networkRef.viewInfo && me.props.networkRef.viewInfo['teamworkMetaJSON']
              && me.props.networkRef.viewInfo['teamworkMetaJSON']['enable_cross_edit'] === 1;
            const hasUnauthorizedFixedNode = (viewCreateUserId !== currentUserId) && nodes.findIndex(node =>
              node.fixed && !node._locked && parseInt(node.userId) !== currentUserId
              && (!enableCrossEdit)) >= 0
            if (hasUnauthorizedFixedNode) {
              // TODO 文案提示
              message.warn('节点创建者才可修改位置');
              return false;
            }

            me.draggingNodeId = node.id;
          } else {
            me.network.stopSimulation();
          }
        },
      },
      'dragging': (params) => {
        if (params.nodes.length >= 1) {
          console.log('Tracking dragging, has relatedCenterNode.');
          let nodeId = params.nodes.find(nodeId => nodeId === me.draggingNodeId);
          let node = nodeId ? me.data.nodes.get(nodeId) : undefined;
          if (node && me.draggingNodeId && me.draggingNodeId === node.id) {
            me.setMagneticPositionToNode(params.pointer.canvas, node, me.data.nodes.get(params.nodes), params.event);
          }
        }
      },
      'dragEnd': (params) => {
        if (params.nodes.length >= 1) {
          let nodeId = params.nodes.find(nodeId => nodeId === me.draggingNodeId);
          let node = nodeId ? me.data.nodes.get(nodeId) : undefined;
          if (node && me.draggingNodeId && me.draggingNodeId === node.id) {
            me.setMagneticPositionToNode(params.pointer.canvas, node, me.data.nodes.get(params.nodes), params.event);
            if (me.data.edges.get(directionEdge.id)) me.data.edges.remove(directionEdge.id);
            // const delayHide = getDelayHideFn('正在修改节点位置……');
            overrideNextMessage('updateNodeInfoListPartially', false);
            me.props.networkRef.updateNodeInfoListPartially(
              me.data.nodes.get(params.nodes).filter(n => n.fixed && !n._locked)
                .map(n => ({id: n.id, fixed: true, fx: n.x, fy: n.y, _locked: false})), false
            ).then(() => {
              // delayHide(() => message.success('节点位置修改成功'));
              PB.emit('console', 'info', '节点位置修改成功');
              me.draggingNodeOriginalPosition = {};
            }).catch(({code/*, msg*/}) => {
              /*delayHide(() =>
                showErrorMessage({code, msg, extra: {viewId: me.state.viewId, isModification: true}}));*/
              showErrorMessage({
                code, msg: '保存位置出现异常，建议刷新图谱再继续操作。',
                extra: {viewId: me.state.viewId, isModification: true}
              });
              PB.emit('console', 'info', '节点位置修改失败');
            });
            me.draggingNodeId = undefined;
          }
        }
      },
      'oncontext': (params) => {
        params.event.preventDefault();//取消了原生的右击事件
        PB.emit('relation', 'presentation.do_pause');
        PB.emit('network', 'node_tooltip.hide');
        console.log('network->onContext', params);
        let nodeId = me.myNetwork.network.getNodeAt(params.pointer.DOM);
        let edgeId = me.myNetwork.network.getEdgeAt(params.pointer.DOM);
        if (nodeId || edgeId) {
          let {nodes, edges} = me.myNetwork.network.getSelection(),
            selection = {nodes: me.myNetwork.getNodes(nodes), edges: me.myNetwork.getEdges(edges)};
          if (nodeId) {
            if (selection.nodes.length > 1 && selection.nodes.find(n => n.id === nodeId)) {
              // 整体右键
              me.setState({
                showContextMenu: 'graph',
                contextMenuX: params.pointer.DOM.x,
                contextMenuY: params.pointer.DOM.y,
                contextSelection: selection,
                contextNodeId: nodeId,
              });
            } else {
              // 选中单个点
              me.setState({
                showContextMenu: 'node',
                contextMenuX: params.pointer.DOM.x,
                contextMenuY: params.pointer.DOM.y,
                contextNodeId: nodeId,
              });
            }
          } else {
            if (selection.edges.length > 1 && selection.edges.find(e => e.id === edgeId)) {
              // 整体右键
              me.setState({
                showContextMenu: 'graph',
                contextMenuX: params.pointer.DOM.x,
                contextMenuY: params.pointer.DOM.y,
                contextSelection: selection,
                contextNodeId: undefined,
              });
            } else {
              // 选中单个边
              /*me.setState({
                showContextMenu: 'edge',
                contextMenuX: params.pointer.DOM.x,
                contextMenuY: params.pointer.DOM.y,
                contextEdgeId: edgeId,
              });*/
            }
          }
        }
      },
      'click': (params) => {
        if (params.event['changedPointers'][0].ctrlKey || params.event['changedPointers'][0].metaKey) return;
        PB.emit('relation', 'presentation.do_pause');
        PB.emit('network', 'node_tooltip.hide');
        PB.emit('workspace', 'input.do_focus');
        me.network.stopSimulation();
        console.log('network->click', params);
        // 后期再考虑按住键盘点击的问题
        me.currentSelection = {nodes: [], edges: []};
        // 清除聚焦节点的状态，取消前一个节点的聚焦状态
        if (me.focusedNode && me.data.nodes.get(me.focusedNode.id)) {
          me.focusedNode = me.data.nodes.get(me.focusedNode.id);
          me.focusedNode.cancelFocus();
          me.data.nodes.update(me.focusedNode);
          // 清除聚焦节点数据
          me.focusedNode = undefined;
        }
        // 清除聚焦节点数据
        me.focusedNode = undefined;
        // 处理当前选中节点，切换
        let currentSelected = me.currentNodeId ?
          me.data.nodes.get(me.currentNodeId) : undefined;
        if (currentSelected && me.data.nodes.get(currentSelected.id)) {
          currentSelected.becomeCommonNode();
          me.data.nodes.update(currentSelected);
        }


        if (params.nodes.length === 0 && params.edges.length === 1) { // 只选中了一条边
          console.log('network->click-> only edge', params, me.data.edges.get(params.edges[0]));
          me.currentSelection.edges.push(me.data.edges.get(params.edges[0]));
          PB.emit('network', 'changeNodeToConnect');
          // 清空选中节点数据
          me.currentNodeId = undefined;
          PB.emit('relation', 'node.single_selection_change', me.currentNodeId);

          // 清空上次选中节点 @190723
          // 历史记录中上一次选中的节点
          const lastNodeToConnect = me.idOfNodeToConnect ? me.data.nodes.get(me.idOfNodeToConnect) : undefined;
          if (lastNodeToConnect && me.data.nodes.get(lastNodeToConnect.id)) {
            lastNodeToConnect.becomeCommonNode();
            me.data.nodes.update(lastNodeToConnect);
          }

          // 点中了一条边，高亮样式
          myVis.highlightEdge(me.network, me.data, params.edges[0]);
          // 连接的节点id数组
          let connectedNodes = undefined;
          let selectedEdge = me.props.networkRef.getEdge(params.edges[0]);
          if (params.edges[0] && selectedEdge) {
            connectedNodes = me.data.nodes.get([selectedEdge.from, selectedEdge.to]);
            PB.emit("network", "selectedEdge", {edge: selectedEdge, connectedNodes: connectedNodes})
          } else {
            PB.emit("network", "selectedEdge", {edge: selectedEdge, connectedNodes: undefined})
          }

        } else if (params.nodes.length === 1) { // 选中单个点
          console.log('network->click-> only node', params);
          me.currentSelection.nodes.push(me.data.nodes.get(params.nodes[0]));
          // 激活上下文浮动工具条
          let poss = me.network.getPositions([params.nodes[0]]);
          let pos = me.network.canvasToDOM(poss[params.nodes[0]]);
          PB.emit('network', 'xActionContext', {
            status: true,
            pos,
            node: me.data.nodes.get(params.nodes[0]),
          });
          // 触发节点选中
          PB.emit('network', 'selectOneNode', me.data.nodes.get(params.nodes[0]));
        } else { // 其他状况
          console.log('network->click-> clear selection', params);
          PB.emit('network', 'changeNodeToConnect');

          // 清空选中节点数据
          me.currentNodeId = undefined;
          PB.emit('relation', 'node.single_selection_change', me.currentNodeId);

          // 清空上次选中节点 @190723
          // 历史记录中上一次选中的节点
          const lastNodeToConnect = me.idOfNodeToConnect ? me.data.nodes.get(me.idOfNodeToConnect) : undefined;
          if (lastNodeToConnect && me.data.nodes.get(lastNodeToConnect.id)) {
            lastNodeToConnect.becomeCommonNode();
            me.data.nodes.update(lastNodeToConnect);
          }


          PB.emit("network", "selectedNode", {node: null, connected: null});

          // 触发上下文按钮
          PB.emit('network', 'xActionContext', {status: true, pos: params.pointer.DOM});

        }

        // 画面停止运动 每个判断单独设置停止仿真，需要停止的位置不同
        console.log('network selection: ', me.network.getSelection());
        me.allEdgesHidden && me.myNetwork.showAllEdges();
        me.allEdgesHidden = false;
        me.setState({
          showContextMenu: false,
          contextNodeId: undefined,
        });
      },
      'doubleClick': (params) => {
        //me.doubleClicked = true;
        if (params.event['changedPointers'][0].ctrlKey || params.event['changedPointers'][0].metaKey) return;
        PB.emit('relation', 'presentation.do_pause');
        PB.emit('network', 'node_tooltip.hide');
        if (params.nodes.length === 1) { // 双击单个节点
          const currentUserId = parseInt(localStorage.getItem("userId"));
          const nodeUserId = parseInt(me.data.nodes.get(params.nodes[0]).userId);
          const viewCreateUserId = parseInt(me.state.viewInfo.userId);
          const enableCrossEdit = me.props.networkRef.viewInfo && me.props.networkRef.viewInfo['teamworkMetaJSON']
            && me.props.networkRef.viewInfo['teamworkMetaJSON']['enable_cross_edit'] === 1;
          if (nodeUserId === currentUserId || viewCreateUserId === currentUserId || enableCrossEdit) {
            let currentSelected = me.data.nodes.get(params.nodes[0]);
            PB.emit('network', 'node.on_edit', {node: currentSelected, id: currentSelected.id});
          } else {
            // TODO 文案提示
            message.warn('节点创建者才可编辑');
          }
        } else if (params.nodes.length === 0 && params.edges.length === 1) { // 只选中了一条边
          // 连接的节点id数组
          let connectedNodes = undefined;
          let selectedEdge = me.props.networkRef.getEdge(params.edges[0]);
          if (params.edges[0] && selectedEdge) {
            connectedNodes = me.data.nodes.get([selectedEdge.from, selectedEdge.to]);
            PB.emit('network', 'edge.on_edit', {id: selectedEdge.id, edge: selectedEdge, connectedNodes});
          }
        } else if (params.nodes.length === 0 && params.edges.length === 0) { // 双击节点除外的其他区域
          PB.emit('workspace', 'input.do_focus');
          me.network.startSimulation();
        }
      },
      'stabilized': () => {
        PB.emit('network', 'stoped');
        me.myNetwork._mediacy.network._stabilized = true;
      },
      'startStabilizing': () => {
        PB.emit('network', 'started');
        me.myNetwork._mediacy.network._stabilized = false;
      },
      'release': () => {
        // alert('release')
        // 改变节点的状态==悬浮状态=hoverFocusNode
        // node.groupNames = node.groupNames.replace(/ grew/g,"")
        // node.groupNames+=' hoverFocusNode'
        // myVis.dealNodeGroupNames(node)
      },
      'selectNode': (params) => {
        let {nodes, edges} = me.myNetwork.network.getSelection();
        edges.forEach(item => {
          let _edge = me.props.networkRef.getEdge(item);
          nodes.push(_edge.from);
          nodes.push(_edge.to);
        });
        me.setState({activatedNodeList:[...new Set(nodes)]})
      },
    },
    multiLine: {
      'dragStart': () => {
        console.log('dragStart')
      },
      'dragEnd': () => {
        console.log('dragEnd')
      },
      'oncontext': () => {
        console.log("oncontext")
      },
      'click': (params) => {
        console.log("release")
      },
      'release': () => {
        console.log("release")
      },
      'selectNode': (/*params*/) => {
        console.log("selectNode")
      },
      'hoverNode': (/*params*/) => {
        console.log("hoverNode")
      },
      'blurNode': (/*params*/) => {
        console.log("blurNode")
      },
      'doubleClick': () => {
        console.log("doubleClick")
      },
      'stabilized': () => {
        console.log('network->stabilized');
        PB.emit('network', 'stoped');
      },
      'startStabilizing': () => {
        console.log('network->startStabilizing');
        PB.emit('network', 'started')
      },
    },
  }))(this);

  /**
   * tip: 干啥的？多点连线时切换vis的事件响应？
   * @param isInMultiLineMode bool 是否进入多点连线模式
   * @param saveEdges bool 是否保存多点连线结果
   */
  changeEventsByMode = (isInMultiLineMode, saveEdges) => {
    const {multiLines} = this.state;
    const {edges} = this.data;
    let me = this;
    this.inMultiLineMode = isInMultiLineMode === true; // 默认为false
    if (!isInMultiLineMode) {
      // 隐藏提醒框
      notification.close('changeEventsByModeInMultiLineMode');
      const nodesToUpdate = [];
      if (saveEdges) {
        // 保存动作
        if (multiLines.length > 0) {
          console.log('edgesToSave', multiLines);
          // noinspection JSIgnoredPromiseFromCall
          this.props.networkRef.saveRelationGraph([], multiLines.map(e => (
            {from: e.from, to: e.to, userConfirmed: true})));
        } else {
          console.log('没有选择多点连线节点edgesToSave');
          message.info('没有选择连线的节点，马上退出多点连接状态。');
        }
      } else {
        // 不保存，退出多点连线，去除已经存在的虚线
        multiLines.forEach(item => {
          edges.remove(item.id);
          let node = this.data.nodes.get(item.to);

          node.groupNames = node.groupNames.replace(' multiSelected', '');
          myVis.dealNodeGroupNames(node);
          nodesToUpdate.push(node)
        });
      }

      this.data.nodes.update(nodesToUpdate);
      if (this.currentNodeId) {
        myVis.selectedNodeByIdArr(me.network, [this.currentNodeId], []);
      }

      this.setState({multiLines: []});
      if (isInMultiLineMode === false) {
        message.info("您已退出多连线模式，可正常查看节点");
      }

      me.myNetwork.network.setOptions({physics: true});
      me.myNetwork.network.disableEditMode();
      me.myNetwork.network.stopSimulation();

      me.myNetwork.bindEvent(me.EventsForDifferentMode['default']);
    } else {
      // message.info("您已进入多连线状态，点击其他节点将与当前选中点添加连线",0);
      // noinspection RequiredAttributes
      notification.info({
        key: 'changeEventsByModeInMultiLineMode', // 唯一key用于关闭
        message: (
          <span>
            多节点连线
            <Tooltip
              placement={'right'}
              title={(
                <span>
                  <b>Ctrl</b>+<b>Shift</b>+<b>L</b> - 多点连线<br/>
                  <b>Ctrl</b>+<b>Shift</b>+<b>S</b> - 保存连线<br/>
                  <b>Ctrl</b>+<b>Shift</b>+<b>Q</b> - 取消保存
                </span>
              )}
            >
              <Icon name={'icon-keyboard-shortcut'} type={IconTypes.ICON_FONT}
                    style={{marginLeft: '0.5em', opacity: 0.6}}/>
            </Tooltip>
          </span>
        ),
        description: (
          <div>
            <span>请将某个节点拖拽到要连接的节点以创建连接</span>
            <br/>
            <br/>
            <Button
              size="small"
              type="primary"
              icon="check"
              style={{float: 'right', marginLeft: '1rem'}}
              onClick={() => {
                PB.emit('infoBoard', 'changeEventByMode', false, true);
              }}
            >
              保存连线
            </Button>
            <Button
              size="small"
              icon="close"
              style={{float: 'right'}}
              onClick={() => {
                PB.emit('infoBoard', 'changeEventByMode', false, false);
              }}
            >
              取消保存
            </Button>
          </div>
        ),
        duration: null,
        btn: false,
        onClose: () => PB.emit('infoBoard', 'changeEventByMode', false, false),
      });

      PB.emit('relation', 'presentation.do_pause');
      PB.emit('network', 'node_tooltip.hide');

      me.myNetwork.network.setOptions({physics: false});
      me.myNetwork.network.addEdgeMode();

      // 切换画面的事件响应
      me.myNetwork.bindEvent(me.EventsForDifferentMode.multiLine, false);
    }
  };

  getNodeEditModal = () => {
    let me = this;

    if (me.props.graphOnly) {
      return null;
    }

    if (me.state.editModalType === 'addNode') {
      return (
        <TextEditModal
          key={'text-edit-modal'}
          editType={'addNode'}
          withNode={me.state.addModalExtra}
          visible={me.state.editModalType === 'addNode'}
          processing={me.state.addModalProcessing}
          doClose={() => me.setState({editModalType: false})}
        />
      )
    } else {
      const modalVisible = {};
      let useCommon = true;
      [
        NODE_TYPE_TEXT,
        NODE_TYPE_COMPANY,
        NODE_TYPE_TALENT,
        NODE_TYPE_PATENT,
        NODE_TYPE_PAPER,
        NODE_TYPE_POLICY,
        NODE_TYPE_ORG,
        NODE_TYPE_INSTITUTE,
        NODE_TYPE_NEWS_ACTIVITIES,
        //NODE_TYPE_TAG, // tag类型使用通用编辑框
        NODE_TYPE_DOCS,
        NODE_TYPE_DATASET,
        NODE_TYPE_GOV,
        NODE_TYPE_NATURE,
        NODE_TYPE_INDEX,
        NODE_TYPE_GRAPH,
        NODE_TYPE_PROJECT,
      ].forEach(
        type => {
          modalVisible[type] = me.state.editModalType === 'editNode' && me.state.editModalTarget[TYPE_FIELD_NAME] === type;
          if (useCommon) {
            useCommon = !modalVisible[type];
          }
        });

      return [(
        <CommonEditModal
          key={'common-edit-modal'}
          node={me.state.editModalType === 'editNode' ? me.state.editModalTarget : undefined}
          visible={me.state.editModalType === 'editNode' && useCommon}
          processing={me.state.editModalProcessing}
          doClose={() => me.setState({editModalType: false})}
        />
      ), (
        <TextEditModal
          key={'text-edit-modal'}
          editType={'editNode'}
          node={me.state.editModalType === 'editNode' ? me.state.editModalTarget : undefined}
          visible={me.state.editModalType === 'editNode' && modalVisible[NODE_TYPE_TEXT]}
          processing={me.state.editModalProcessing}
          doClose={() => me.setState({editModalType: false})}
        />
      )];
    }

  };

  getNodeDeleteModal = () => {
    let me = this;
    return (
      <NodeDeleteModal
        networkRef={me.props.networkRef}
        viewInfo={me.state.viewInfo}
        visible={me.state.deleteModalType === 'node'}
        withNode={me.state.deleteModalType === 'node' ? me.state.deleteModalTarget : undefined}
        doClose={() => {
          me.setState({deleteModalType: false})
        }}
      />
    );
  };

  getEdgeEditModal = () => {
    let me = this;

    if (me.props.graphOnly) {
      return null;
    }

    return (
      <CommonEdgeEditModal
        key={'common-edge-edit-modal'}
        edge={me.state.editModalType === 'editEdge' ? me.state.editModalTarget : undefined}
        fromNode={me.state.editModalType === 'editEdge' ? me.state.editModalExtra[0] : undefined}
        toNode={me.state.editModalType === 'editEdge' ? me.state.editModalExtra[1] : undefined}
        visible={me.state.editModalType === 'editEdge'}
        processing={me.state.editModalProcessing}
        doClose={() => me.setState({editModalType: false})}
      />
    );
  };

  userActionBindingForNode = () => {
    let me = this;
    const userId = parseInt(localStorage.getItem('userId'));

    let storeNewCustomIconInfo = newCustomIconInfo => {
      if (me.localDataCacheStore && newCustomIconInfo) {
        let nextCustomIconToReplace = 0;
        me.localDataCacheStore.get(`cache:local:node:new_custom_icon_pos`).then(dataStr => {
          nextCustomIconToReplace = parseInt(dataStr || '0');
        }).finally(() => {
          me.localDataCacheStore.put(JSON.stringify(newCustomIconInfo), `cache:local:node:new_custom_icon_${nextCustomIconToReplace}`).then(() => {
            nextCustomIconToReplace += 1;
            if (nextCustomIconToReplace >= 6) {
              nextCustomIconToReplace = 0;
            }
            // noinspection JSIgnoredPromiseFromCall
            me.localDataCacheStore.put(`${nextCustomIconToReplace}`, `cache:local:node:new_custom_icon_pos`);
          });
        });
      }
    }

    // 保留节点
    PB.sub(me, 'network', 'node.on_save', ({node}) => {
      PB.emit('network', 'node.do_save', {node});
    });
    PB.sub(me, 'network', 'node.do_save', ({node, success}) => {
      PB.emit('console', 'info', '正在保留节点信息，请稍后...');
      let messageKey;
      PB.emit('aiConsole', 'message.push', {
        type: 'user',
        content: `保留节点 "${getNodeDisplayTitle(node, 12)}"`,
      });
      PB.emit('aiConsole', 'message.push', {
        type: 'ai',
        content: (<span><Icon name={'loading'} style={{marginRight: '0.5em'}}/>请稍后...</span>),
        callback: ({key}) => messageKey = key,
        delay: 200,
      });
      let nodePositions = me.myNetwork.network.getPositions(node.id);
      let existedNodes = me.myNetwork.getNodes(node.id);
      if (existedNodes && existedNodes[0]) {
        existedNodes[0].fx = nodePositions[node.id].x;
        existedNodes[0].fy = nodePositions[node.id].y;
        me.myNetwork.updateNodes(existedNodes);
      }
      me.props.networkRef.saveNodeWithRelations(node.id).then(() => {
        PB.emit('console', 'info', '节点信息保留成功');
        PB.emit('aiConsole', 'message.update', {key: messageKey, content: `操作成功`});
        success && success();
      }).catch(({code, msg}) => {
        showErrorMessage({code, msg, extra: {viewId: me.state.viewId, isModification: true}});
        PB.emit('console', 'info', '节点信息保留失败');
        PB.emit('aiConsole', 'message.update', {key: messageKey, content: `操作失败`});
      });
    });

    // 添加节点
    PB.sub(me, 'network', 'node.on_add', ({withNode}) => {
      if (me.props.networkRef.viewInfo && me.props.networkRef.viewInfo.lock === 1) {
        showErrorMessage({code: 25005});
        return;
      }

      /* me.setState({
         addModalType: 'node',
         addModalExtra: withNode ? withNode : undefined,
         addModalProcessing: false,
       });*/
      me.setState({
        editModalType: 'addNode',
        editModalTarget: undefined,
        addModalExtra: withNode ? withNode : undefined,
        addModalProcessing: false,
      });
    });
    PB.sub(me, 'network', 'node.do_add', ({node, newCustomIconInfo}) => {
      PB.emit('console', 'info', '正在添加节点信息，请稍后...');
      let messageKey;
      PB.emit('aiConsole', 'message.push', {
        type: 'user',
        content: `添加节点 "${getNodeDisplayTitle(node, 12)}"${me.state.addModalExtra ? ` 并关联至 "${getNodeDisplayTitle(me.state.addModalExtra, 12)}"` : ''}`,
      });
      PB.emit('aiConsole', 'message.push', {
        type: 'ai',
        content: (<span><Icon name={'loading'} style={{marginRight: '0.5em'}}/>请稍后...</span>),
        callback: ({key}) => messageKey = key,
        delay: 200,
      });
      me.setState({
        addModalProcessing: true,
      }, () => {
        me.props.networkRef.addNodeLinkedTo(
          [{...node, userId, forceAdd: true}],
          me.state.addModalExtra ? me.state.addModalExtra.id : false,
          me.state.addModalExtra ? me.state.addModalExtra.id : false,
          false,
          'user_add_node'
        ).then(relation => {
          PB.emit('console', 'info', '节点信息添加成功');
          PB.emit('aiConsole', 'message.update', {
            key: messageKey,
            content: (
              <span>
                操作成功
                <a
                  style={{marginLeft: '0.5em'}}
                  className={'plain-action'}
                  onClick={me.undoAddRelationFnGenerator(messageKey, '操作成功', relation)}
                >撤销</a>
              </span>
            ),
          });
          me.setState({editModalType: false, addModalProcessing: false},
            () => storeNewCustomIconInfo(newCustomIconInfo));
          // PB.emit('network', 'focus', node);
        }).catch(({code, msg}) => {
          showErrorMessage({code, msg, extra: {viewId: me.state.viewId, isModification: true}});
          PB.emit('console', 'info', '节点信息添加失败');
          PB.emit('aiConsole', 'message.update', {key: messageKey, content: `操作失败`});
          me.setState({addModalProcessing: false});
        });
      });
    });

    let onNodesUpdated = (newNodesInfo, msgKeyObj, successMsg) => {
      PB.emit('console', 'info', successMsg);
      PB.emit('aiConsole', 'message.update', {key: msgKeyObj.messageKey, content: `操作成功`});
      // 添加节点时有message提示，编辑节点时也增加提示信息
      if (newNodesInfo.length > 1) {
        message.success(
          (
            <span>
              <span className={style["node-in-msg"]}>{getNodeDisplayTitle(newNodesInfo[0])}</span>
              <span>等 {newNodesInfo.length} 个节点更新成功</span>
            </span>
          ),
          1.5
        );
      } else {
        message.success(
          (
            <span>
              <span>节点</span>
              <span className={style["node-in-msg"]}>{getNodeDisplayTitle(newNodesInfo[0])}</span>
              <span>更新成功</span>
            </span>
          ),
          1.5
        );
      }
    }, onNodesUpdateFailed = ({code, msg}, newNodesInfo, msgKeyObj, failedMsg) => {
      showErrorMessage({code, msg, extra: {viewId: me.state.viewId, isModification: true}});
      PB.emit('console', 'info', failedMsg);
      PB.emit('aiConsole', 'message.update', {key: msgKeyObj.messageKey, content: `操作失败`});
      // 添加节点时有message提示，编辑节点时也增加提示信息
      if (newNodesInfo.length > 1) {
        message.success(
          (
            <span>
              <span className={style["node-in-msg"]}>{getNodeDisplayTitle(newNodesInfo[0])}</span>
              <span>等 {newNodesInfo.length} 个节点更新失败</span>
            </span>
          ),
          1.5
        );
      } else {
        message.error(
          (
            <span>
                <span>节点</span>
                <span className={style["node-in-msg"]}>{getNodeDisplayTitle(newNodesInfo[0])}</span>
                <span>更新失败</span>
              </span>
          ),
          1.5
        );
      }
    }, updatePartiallyFn = (msgKeyObj, newNodesInfo, successMsg, failedMsg) => {
      return new Promise((resolve, reject) => {
        me.props.networkRef.updateNodeInfoListPartially(
          newNodesInfo
        ).then(() => {
          onNodesUpdated(newNodesInfo, msgKeyObj, successMsg);
          resolve();
        }).catch(({code, msg}) => {
          onNodesUpdateFailed({code, msg}, newNodesInfo, msgKeyObj, failedMsg);
          reject({code, msg});
        });
      });
    }, updateFn = (msgKeyObj, newNodeInfo, successMsg, failedMsg) => {
      return new Promise((resolve, reject) => {
        me.props.networkRef.updateNodeInfo(
          newNodeInfo
        ).then(() => {
          onNodesUpdated([newNodeInfo], msgKeyObj, successMsg);
          resolve();
        }).catch(({code, msg}) => {
          onNodesUpdateFailed({code, msg}, [newNodeInfo], msgKeyObj, failedMsg);
          reject({code, msg});
        });
      });
    };

    // 节点编辑
    PB.sub(me, 'network', 'node.on_edit', ({node}) => {
      // 用户触发编辑操作

      if (me.props.networkRef.viewInfo && me.props.networkRef.viewInfo.lock === 1) {
        showErrorMessage({code: 25005});
        return;
      }
      // 检查节点类型
      const editableNodeType = [NODE_TYPE_TEXT, NODE_TYPE_TAG];
      if (node) {
        if (editableNodeType.includes(node[TYPE_FIELD_NAME])) {
          me.setState({
            editModalType: 'editNode',
            editModalTarget: node,
            editModalProcessing: false,
          });
        } else {
          Modal.info({
            title: '提示',
            centered: true,
            content: `您选择的节点不支持编辑。`,
            okText: '确定',
          });
        }
      }
    });
    PB.sub(me, 'network', 'node.do_edit', ({node, newCustomIconInfo}) => {
      PB.emit('console', 'info', '正在更新节点信息，请稍后...');
      let msgKeyObj = {messageKey: undefined};
      PB.emit('aiConsole', 'message.push', {
        type: 'user',
        content: `编辑节点 "${getNodeDisplayTitle(node, 12)}"`,
      });
      PB.emit('aiConsole', 'message.push', {
        type: 'ai',
        content: (<span><Icon name={'loading'} style={{marginRight: '0.5em'}}/>请稍后...</span>),
        callback: ({key}) => msgKeyObj.messageKey = key,
        delay: 200,
      });
      me.setState({
        editModalProcessing: true,
      }, () => {
        updateFn(msgKeyObj, {...node, userId}, '节点信息更新成功', '节点信息更新失败').then(() => {
          me.setState({editModalType: false, editModalProcessing: false},
            () => storeNewCustomIconInfo(newCustomIconInfo));
        }).catch(() => {
          me.setState({editModalProcessing: false});
        });
      });
    });

    // 设置标记
    PB.sub(me, 'network', 'node.on_set_flag', ({nodes, flag, extra, title}) => {
      PB.emit('network', 'node.do_set_flag', {nodes, flag, extra, title});
    });
    PB.sub(me, 'network', 'node.do_set_flag', ({nodes, flag, extra, title}) => {
      if (nodes.length < 1) return;
      PB.emit('console', 'info', '正在设置节点标记，请稍后...');
      let msgKeyObj = {messageKey: undefined};
      PB.emit('aiConsole', 'message.push', {
        type: 'user',
        content: nodes.length === 1
          ? `将节点 "${getNodeDisplayTitle(nodes[0], 12)}" 标记为 "${title}"`
          : `将 "${getNodeDisplayTitle(nodes[0], 12)}" 等 ${nodes.length} 个节点标记为 "${title}"`,
      });
      PB.emit('aiConsole', 'message.push', {
        type: 'ai',
        content: (<span><Icon name={'loading'} style={{marginRight: '0.5em'}}/>请稍后...</span>),
        callback: ({key}) => msgKeyObj.messageKey = key,
        delay: 200,
      });
      // noinspection JSIgnoredPromiseFromCall
      updatePartiallyFn(
        msgKeyObj,
        nodes.map(node => {
          let meta = node.meta, tmpExtra = {...extra};
          if (tmpExtra && tmpExtra.meta) {
            meta = {...(meta || {}), ...tmpExtra.meta};
            delete tmpExtra.meta;
          }
          return {userId, lev: flag, id: node.id, ...tmpExtra, meta};
        }),
        '节点标记设置成功',
        '节点标记设置失败'
      );
    });

    // 设置缩放
    PB.sub(me, 'network', 'node.on_set_scale', ({nodes, scale}) => {
      PB.emit('network', 'node.do_set_scale', {nodes, scale});
    });
    PB.sub(me, 'network', 'node.do_set_scale', ({nodes, scale}) => {
      PB.emit('console', 'info', '正在设置节点缩放比例，请稍后...');
      let msgKeyObj = {messageKey: undefined};
      PB.emit('aiConsole', 'message.push', {
        type: 'user',
        content: nodes.length === 1
          ? `设置节点 "${getNodeDisplayTitle(nodes[0], 12)}" 的缩放比例为${scale * 100}%`
          : `将 "${getNodeDisplayTitle(nodes[0], 12)}" 等 ${nodes.length} 个节点的缩放比例设为${scale * 100}%`,
      });
      PB.emit('aiConsole', 'message.push', {
        type: 'ai',
        content: (<span><Icon name={'loading'} style={{marginRight: '0.5em'}}/>请稍后...</span>),
        callback: ({key}) => msgKeyObj.messageKey = key,
        delay: 200,
      });
      // noinspection JSIgnoredPromiseFromCall
      updatePartiallyFn(
        msgKeyObj,
        nodes.map(node => ({...node, userId, meta: (node.meta ? {...node.meta, scale} : {scale})})),
        '节点缩放比例设置成功',
        '节点缩放比例设置失败'
      );
    });

    // 固定节点
    PB.sub(me, 'network', 'node.on_fix', ({nodes, positions}) => {
      if (!positions) {
        positions = me.network.getPositions(nodes.map(n => n.id));
      }
      PB.emit('network', 'node.do_fix',
        {nodes, positions: nodes.map(n => ({id: n.id, x: positions[n.id].x, y: positions[n.id].y}))});
    });
    PB.sub(me, 'network', 'node.do_fix', ({nodes, positions}) => {
      /*const delayHide = getDelayHideFn('正在固定节点位置……');*/
      PB.emit('console', 'info', '正在固定节点，请稍后...');
      let messageKey;
      PB.emit('aiConsole', 'message.push', {
        type: 'user',
        content: nodes.length === 1
          ? `固定节点 "${getNodeDisplayTitle(nodes[0], 12)}" 的位置`
          : `将 "${getNodeDisplayTitle(nodes[0], 12)}" 等 ${nodes.length} 个节点固定节点位置`,
      });
      PB.emit('aiConsole', 'message.push', {
        type: 'ai',
        content: (<span><Icon name={'loading'} style={{marginRight: '0.5em'}}/>请稍后...</span>),
        callback: ({key}) => messageKey = key,
        delay: 200,
      });
      overrideNextMessage('updateNodeInfoListPartially', false);
      me.props.networkRef.updateNodeInfoListPartially(
        positions.map(p => ({id: p.id, fixed: true, fx: `${p.x}`, fy: `${p.y}`})), false
      ).then(() => {
        PB.emit('console', 'info', '节点固定成功');
        PB.emit('aiConsole', 'message.update', {key: messageKey, content: `操作成功`});
      }).catch(({code, msg}) => {
        showErrorMessage({code, msg, extra: {viewId: me.state.viewId, isModification: true}});
        PB.emit('console', 'info', '节点固定失败');
        PB.emit('aiConsole', 'message.update', {key: messageKey, content: `操作失败`});
      });
    });

    // 取消固定节点
    PB.sub(me, 'network', 'node.on_unfix', ({nodes}) => {
      PB.emit('network', 'node.do_unfix', {nodes});
    });
    PB.sub(me, 'network', 'node.do_unfix', ({nodes}) => {
      /*const delayHide = getDelayHideFn('正在取消固定位置……');*/
      PB.emit('console', 'info', '正在取消固定节点，请稍后...');
      let messageKey;
      PB.emit('aiConsole', 'message.push', {
        type: 'user',
        content: nodes.length === 1
          ? `节点 "${getNodeDisplayTitle(nodes[0], 12)}" 取消固定位置`
          : `将 "${getNodeDisplayTitle(nodes[0], 12)}" 等 ${nodes.length} 个节点取消固定位置`,
      });
      PB.emit('aiConsole', 'message.push', {
        type: 'ai',
        content: (<span><Icon name={'loading'} style={{marginRight: '0.5em'}}/>请稍后...</span>),
        callback: ({key}) => messageKey = key,
        delay: 200,
      });
      overrideNextMessage('updateNodeInfoListPartially', false);
      me.props.networkRef.updateNodeInfoListPartially(nodes.map(n => ({
          id: n.id,
fixed: false,
})), false).then(() => {
        /*delayHide(() => message.success('取消固定位置成功'));*/
        PB.emit('console', 'info', '节点取消固定成功');
        PB.emit('aiConsole', 'message.update', {key: messageKey, content: `操作成功`});
      }).catch(({code, msg}) => {
        /*delayHide(() =>*/
        showErrorMessage({code, msg, extra: {viewId: me.state.viewId, isModification: true}})/*)*/;
        PB.emit('console', 'info', '节点取消固定失败');
        PB.emit('aiConsole', 'message.update', {key: messageKey, content: `操作失败`});
      });
    });

    // 联想线索
    PB.sub(me, 'network', 'node.on_get_related_clue', ({node}) => {
      PB.emit('network', 'node.do_get_related_clue', {node});
    });
    PB.sub(me, 'network', 'node.do_get_related_clue', ({node}) => {
      let _stabilized = me.myNetwork._mediacy.network._stabilized;
      if (node.status === 0) {
        // 保留并进行语义发现
        let messageKey;
        PB.emit('console', 'info', '正保留节点并进行语义发现，请稍后...');
        PB.emit('aiConsole', 'message.push', {
          type: 'user',
          content: `保留节点 "${getNodeDisplayTitle(node, 12)}" 并以该节点为中心启动语义发现`,
        });
        PB.emit('aiConsole', 'message.push', {
          type: 'ai',
          content: (<span><Icon name={'loading'} style={{marginRight: '0.5em'}}/>请稍后...</span>),
          callback: ({key}) => messageKey = key,
          delay: 200,
        });
        let nodePositions = me.myNetwork.network.getPositions(node.id);
        let existedNodes = me.myNetwork.getNodes(node.id);
        if (existedNodes && existedNodes[0]) {
          existedNodes[0].fx = nodePositions[node.id].x;
          existedNodes[0].fy = nodePositions[node.id].y;
          me.myNetwork.updateNodes(existedNodes);
        }
        me.props.networkRef.saveNodeWithRelations(node.id).then((node) => {
          PB.emit('console', 'info', '节点信息保留成功');
          PB.emit('aiConsole', 'message.update', {key: messageKey, content: `保留节点操作成功`});
          PB.emit('aiConsole', 'message.push', {
            type: 'ai',
            content: (<span><Icon name={'loading'} style={{marginRight: '0.5em'}}/>请稍后...</span>),
            callback: ({key}) => messageKey = key,
            delay: 200,
          });
          if (_stabilized) {
            me.myNetwork.network.stopSimulation();
          }
          me.props.networkRef.loadRelatedClue(node.id).then(() => {
            PB.emit('console', 'info', '语义发现成功');
            PB.emit('aiConsole', 'message.update', {
              key: messageKey,
              content: `语义发现操作成功，发现：${
                me.props.networkRef.getCurrentShownRelatedClueResult(node.id).nodes.length
              }个新线索`,
            });
            if (_stabilized) {
              setTimeout(() => {
                me.myNetwork.network.stopSimulation();
              }, 1200);
            }
          }).catch(({code, msg}) => {
            showErrorMessage({code, msg, extra: {viewId: me.state.viewId}});
            PB.emit('console', 'info', '语义发现失败');
            PB.emit('aiConsole', 'message.update', {key: messageKey, content: `语义发现操作失败`});
          })
        }).catch(({code, msg}) => {
          showErrorMessage({code, msg, extra: {viewId: me.state.viewId, isModification: true}});
          PB.emit('console', 'info', '节点信息保留失败');
          PB.emit('aiConsole', 'message.update', {key: messageKey, content: `保留节点操作失败`});
        });
      } else {
        // 记录关系图运行状态
        const result = me.props.networkRef.getCurrentShownRelatedClueResult(node.id);
        if (result.status === NodeExpandingStatus.IDLE || result.status === NodeExpandingStatus.FAILED) {
          // 发请求获取结果
          let messageKey;
          PB.emit('console', 'info', '正在进行语义发现，请稍后...');
          PB.emit('aiConsole', 'message.push', {
            type: 'user',
            content: `以 "${getNodeDisplayTitle(node, 12)}" 为中心启动语义发现`,
          });
          PB.emit('aiConsole', 'message.push', {
            type: 'ai',
            content: (<span><Icon name={'loading'} style={{marginRight: '0.5em'}}/>请稍后...</span>),
            callback: ({key}) => messageKey = key,
            delay: 200,
          });
          me.props.networkRef.loadRelatedClue(node.id).then(() => {
            PB.emit('console', 'info', '语义发现成功');
            PB.emit('aiConsole', 'message.update', {
              key: messageKey,
              content: `操作成功，发现：${
                me.props.networkRef.getCurrentShownRelatedClueResult(node.id).nodes.length
              }个新线索`,
            });
            if (_stabilized) {
              setTimeout(() => {
                me.myNetwork.network.stopSimulation();
              }, 1200);
            }
          }).catch(({code, msg}) => {
            showErrorMessage({code, msg, extra: {viewId: me.state.viewId}});
            PB.emit('console', 'info', '语义发现失败');
            PB.emit('aiConsole', 'message.update', {key: messageKey, content: `操作失败`});
          })
        } else if (result.status === NodeExpandingStatus.PROCESSING) {
          // 请求发送中
          PB.emit('console', 'info', '正在等待语义发现结果，请稍后...');
        } else {
          // 换一批数据
          PB.emit('console', 'info', '正在进行语义发现，请稍后...');
          PB.emit('aiConsole', 'message.push', {
            type: 'user',
            content: `以 "${getNodeDisplayTitle(node, 12)}" 为中心启动语义发现`,
          });
          PB.emit('aiConsole', 'message.push', {
            type: 'ai',
            content: `操作成功，发现：${
              me.props.networkRef.replaceWithNextRelatedClueResult(node.id).nodes.length
            }个新线索`,
          });
          if (_stabilized) {
            setTimeout(() => {
              me.myNetwork.network.stopSimulation();
            }, 1200);
          }
        }
      }
    });

    // 节点设置扩展功能
    PB.sub(me, 'network', 'node.on_edit_extended_action_settings', ({node, idx}) => {
      // 用户触发编辑操作
      if (me.props.networkRef.viewInfo && me.props.networkRef.viewInfo.lock === 1) {
        showErrorMessage({code: 25005});
        return;
      }
      // 检查节点类型
      const editableNodeType = [NODE_TYPE_TEXT, NODE_TYPE_TAG];
      if (node) {
        if (editableNodeType.includes(node[TYPE_FIELD_NAME])) {
          const action = (node.meta && node.meta['extendedActions'] && node.meta['extendedActions'][idx]) ? {
            name: '',
            description: '',
            iconName: 'icon-extended-feature',
            iconType: IconTypes.ICON_FONT,
            url: '',
            userToken: false,
            ...(node.meta['extendedActions'][idx]),
          } : {
            name: '',
            description: '',
            iconName: 'icon-extended-feature',
            iconType: IconTypes.ICON_FONT,
            url: '',
            userToken: false,
          };
          me.setState({
            showExtendedActionSettingsModal: true,
            extendedActionRelatedNodeId: node.id,
            extendedActionIdx: idx,
            extendedActionName: action.name,
            extendedActionDescription: action.description,
            extendedActionIconName: action.iconName,
            extendedActionIconType: action.iconType,
            extendedActionUrl: action.url,
            extendedActionUserToken: action.userToken,
          });
        } else {
          Modal.info({
            title: '提示',
            centered: true,
            content: `您选择的节点不支持设置扩展功能哦。`,
            okText: '确定',
          });
        }
      }
    });
    PB.sub(me, 'network', 'node.do_edit_extended_action_settings', ({nodeId, idx, settings}) => {
      let node = me.props.networkRef.getNode(nodeId);
      if (!node) return;
      /*const delayHide = getDelayHideFn('正在取消固定位置……');*/
      PB.emit('console', 'info', '正在设置扩展功能，请稍后...');
      let messageKey;
      PB.emit('aiConsole', 'message.push', {
        type: 'user',
        content: `节点 "${getNodeDisplayTitle(node, 12)}" 设置扩展功能`,
      });
      PB.emit('aiConsole', 'message.push', {
        type: 'ai',
        content: (<span><Icon name={'loading'} style={{marginRight: '0.5em'}}/>请稍后...</span>),
        callback: ({key}) => messageKey = key,
        delay: 200,
      });
      let meta = {...(node.meta || {})};
      meta.extendedActions = [...(meta.extendedActions || [undefined, undefined, undefined])]
      meta.extendedActions[idx] = settings ? {...settings} : undefined;
      me.setState({extendedActionSettingsModalProcessing: true}, () => {
        overrideNextMessage('updateNodeInfoListPartially', false);
        me.props.networkRef.updateNodeInfoListPartially([{id: nodeId, meta}]).then(() => {
          /*delayHide(() => message.success('取消固定位置成功'));*/
          PB.emit('console', 'info', '扩展功能设置成功');
          PB.emit('aiConsole', 'message.update', {key: messageKey, content: `操作成功`});
          me.setState({extendedActionSettingsModalProcessing: false, showExtendedActionSettingsModal: false});
        }).catch(({code, msg}) => {
          /*delayHide(() =>*/
          showErrorMessage({code, msg, extra: {viewId: me.state.viewId, isModification: true}})/*)*/;
          PB.emit('console', 'info', '扩展功能设置失败');
          PB.emit('aiConsole', 'message.update', {key: messageKey, content: `操作失败`});
          me.setState({extendedActionSettingsModalProcessing: false});
        });
      });
    });

    // 附加信息
    PB.sub(me, 'network', 'node.on_add_extra_info', ({node}) => {
      if (me.props.networkRef.viewInfo && me.props.networkRef.viewInfo.lock === 1) {
        showErrorMessage({code: 25005});
        return;
      }
      me.setState({
        showUploadModal: true,
        uploadModalNodeId: node.id,
      });
    });
    PB.sub(me, 'network', 'node.on_show_extra_info', ({node}) => {
      PB.emit('network', 'node.do_show_extra_info', {node});
    });
    PB.sub(me, 'network', 'node.do_show_extra_info', ({node}) => {
      me.setState({
        extraInfoListModalVisible: true,
        extraInfoListModalNodeId: node.id,
      });
    });

    // 删除节点
    PB.sub(me, 'network', 'node.on_remove', ({node, nodeIds}) => {
      me.setState({
        deleteModalType: 'node',
        deleteModalTarget: {
          node,
          nodeIds,
        },
      });
    });

    PB.sub(me, 'network', 'node.do_remove', ({node, nodeIds}) => {
      let messageKey, nodesToRemove = [], edgesToRemove = [], undoRemoveFn = () => {
        // 停用按钮
        PB.emit('aiConsole', 'message.update', {
          key: messageKey,
          content: (
            <span>
              操作成功
              <span style={{marginLeft: '0.5em', opacity: 0.6}}>撤销</span>
            </span>
          ),
        });
        PB.emit('aiConsole', 'message.push', {
          type: 'user',
          content: `撤销删除节点`,
        });
        let undoMessageKey;
        PB.emit('aiConsole', 'message.push', {
          type: 'ai',
          content: '正常处理...',
          callback: ({key}) => undoMessageKey = key,
          delay: 200,
        });
        me.props.networkRef.recoverRelationGraph(nodesToRemove, edgesToRemove).then(() => {
          PB.emit('aiConsole', 'message.update', {
            key: undoMessageKey,
            content: '撤销成功',
          });
        }).catch(() => {
          PB.emit('aiConsole', 'message.update', {
            key: undoMessageKey,
            content: '撤销失败',
          });
          PB.emit('aiConsole', 'message.update', {
            key: messageKey,
            content: (
              <span>
                操作成功
                <a
                  style={{marginLeft: '0.5em'}}
                  className={'plain-action'}
                  onClick={undoRemoveFn}
                >撤销</a>
              </span>
            ),
          });
        });
      };
      if (node) {
        nodesToRemove.push(node);
        edgesToRemove.push.apply(edgesToRemove,
          me.props.networkRef.getEdge(me.props.networkRef.getConnectedEdgeIds(node.id)));
        PB.emit('console', 'info', '正在删除节点，请稍后...');
        PB.emit('aiConsole', 'message.push', {type: 'user', content: `删除节点 "${getNodeDisplayTitle(node, 12)}"`});
        PB.emit('aiConsole', 'message.push', {
          type: 'ai',
          content: (<span><Icon name={'loading'} style={{marginRight: '0.5em'}}/>请稍后...</span>),
          callback: ({key}) => messageKey = key,
          delay: 200,
        });
        me.props.networkRef.removeNode(node.id).then(() => {
          PB.emit('console', 'info', '节点删除成功');
          PB.emit('aiConsole', 'message.update', {
            key: messageKey,
            content: (
              <span>
                操作成功
                <a
                  style={{marginLeft: '0.5em'}}
                  className={'plain-action'}
                  onClick={undoRemoveFn}
                >撤销</a>
              </span>
            ),
          });
          if (node.id === me.state.contextNodeId) {
            me.setState({contextNodeId: undefined});
          }
        }).catch(({code, msg}) => {
          showErrorMessage({code, msg, extra: {viewId: me.state.viewId, isModification: true}});
          PB.emit('console', 'info', '节点删除失败');
          PB.emit('aiConsole', 'message.update', {key: messageKey, content: `操作失败`});
        });
      } else if (nodeIds && nodeIds.length > 0) {
        nodesToRemove.push
          .apply(nodesToRemove, me.props.networkRef.getNode(nodeIds).filter(n => !!n));
        if (nodesToRemove.length <= 0) return;
        let edgeIds = [];
        nodesToRemove.forEach(node => edgeIds.push.apply(edgeIds,
          me.props.networkRef.getConnectedEdgeIds(node.id)));
        edgeIds = _.uniq(edgeIds);
        edgesToRemove.push.apply(edgesToRemove, me.props.networkRef.getEdge(edgeIds));
        PB.emit('console', 'info', '正在删除节点，请稍后...');
        node = nodesToRemove[0];
        if (nodeIds.length > 1) {
          PB.emit('aiConsole', 'message.push', {
            type: 'user',
            content: `删除 "${getNodeDisplayTitle(node, 12)}" 等${nodeIds.length}个节点`,
          });
        } else {
          PB.emit('aiConsole', 'message.push', {type: 'user', content: `删除节点 "${getNodeDisplayTitle(node, 12)}"`});
        }
        PB.emit('aiConsole', 'message.push', {
          type: 'ai',
          content: (<span><Icon name={'loading'} style={{marginRight: '0.5em'}}/>请稍后...</span>),
          callback: ({key}) => messageKey = key,
          delay: 200,
        });
        me.props.networkRef.removeNodes(nodeIds).then(() => {
          PB.emit('console', 'info', '节点删除成功');
          PB.emit('aiConsole', 'message.update', {
            key: messageKey,
            content: (
              <span>
                操作成功
                <a
                  style={{marginLeft: '0.5em'}}
                  className={'plain-action'}
                  onClick={undoRemoveFn}
                >撤销</a>
              </span>
            ),
          });
          if (nodeIds.indexOf(me.state.contextNodeId) >= 0) {
            me.setState({contextNodeId: undefined});
          }
        }).catch(({code, msg}) => {
          showErrorMessage({code, msg, extra: {viewId: me.state.viewId, isModification: true}});
          PB.emit('console', 'info', '节点删除失败');
          PB.emit('aiConsole', 'message.update', {key: messageKey, content: `操作失败`});
        });
      }
    });
  };

  userActionBindingForEdge = () => {
    let me = this;

    // 多点连线
    PB.sub(me, 'network', 'edge.on_batch_add_from_node', () => {
      me.changeEventsByMode(true);
    });

    // 关联至上一节点（增加单一关系）
    PB.sub(me, 'network', 'edge.on_add', ({from, to, isNew = true}) => {
      PB.emit('network', 'edge.do_add', {from, to, isNew});
    });
    PB.sub(me, 'network', 'edge.do_add', ({from, to, isNew = true}) => {
      let messageKey;
      let content = isNew
        ? `相连节点 "${getNodeDisplayTitle(from, 12)}" 与 "${getNodeDisplayTitle(to, 12)}"`
        : `保留节点 "${getNodeDisplayTitle(from, 12)}" 与 "${getNodeDisplayTitle(to, 12)}" 的关联关系`
      PB.emit('console', 'info', isNew ? '正在添加关联关系，请稍后...' : '正在保留关联关系，请稍后...');
      PB.emit('aiConsole', 'message.push', {type: 'user', content});
      PB.emit('aiConsole', 'message.push', {
        type: 'ai',
        content: (<span><Icon name={'loading'} style={{marginRight: '0.5em'}}/>请稍后...</span>),
        callback: ({key}) => messageKey = key,
        delay: 200,
      });
      me.props.networkRef.addRelation(from.id, to.id).then(() => {
        PB.emit('console', 'info', isNew ? '关联关系添加成功' : '关联关系保留成功');
        PB.emit('aiConsole', 'message.update', {key: messageKey, content: `操作成功`});
      }).catch(({code, msg}) => {
        showErrorMessage({code, msg, extra: {viewId: me.state.viewId, isModification: true}});
        PB.emit('console', 'info', isNew ? '关联关系添加失败' : '关联关系保留失败');
        PB.emit('aiConsole', 'message.update', {key: messageKey, content: `操作失败`});
      })
    });

    // 修改边
    PB.sub(me, 'network', 'edge.on_edit', ({edge, connectedNodes}) => {
      // 用户触发编辑操作
      if (me.props.networkRef.viewInfo && me.props.networkRef.viewInfo.lock === 1) {
        showErrorMessage({code: 25005});
        return;
      }
      if (edge) {
        me.setState({
          editModalType: 'editEdge',
          editModalTarget: edge,
          editModalExtra: connectedNodes,
          editModalProcessing: false,
        });
      }
    });
    PB.sub(me, 'network', 'edge.do_edit', ({id, meta, isNew = false}) => {
      const delayHide = getDelayHideFn(isNew ? '正在保留关联关系，请稍后...' : '正在更新关联关系信息……');
      PB.emit('console', 'info', isNew ? '正在保留关联关系，请稍后...' : '正在更新关联关系信息，请稍后...');
      let updateFn = () => {
        me.props.networkRef.updateEdgeInfo({id, meta}).then(() => {
          delayHide(() => message.success(isNew ? '关联关系保留成功' : '关联关系信息更新成功'));
          PB.emit('console', 'info', isNew ? '关联关系保留成功' : '关联关系信息更新成功');
          PB.emit('network', 'decorate_edge.on', {});
          if (me.state.editModalType === 'editEdge') {
            me.setState({
              editModalType: false,
              editModalTarget: undefined,
              editModalExtra: undefined,
              editModalProcessing: false,
            });
          }
        }).catch(({code, msg}) => {
          delayHide(() =>
            showErrorMessage({code, msg, extra: {viewId: me.state.viewId, isModification: true}}));
          PB.emit('console', 'info', isNew ? '关联关系保留失败' : '关联关系信息更新失败');
          if (me.state.editModalType === 'editEdge') {
            me.setState({
              editModalProcessing: false,
            });
          }
        });
      }
      if (me.state.editModalType === 'editEdge') {
        me.setState({editModalProcessing: true}, updateFn);
      } else {
        updateFn();
      }
    });

    // 删除边
    PB.sub(me, 'network', 'edge.on_remove', ({edge}) => {
      let connectedNodes = me.props.networkRef.getConnectedNodes(edge.id);
      let messageKey;
      Modal.confirm({
        title: '删除关系',
        width: '500px',
        content: (
          <div style={{position: 'relative', padding: '16px 0 0'}}>
            <div style={{width: '100%', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}}>
              <Icon
                name={'icon-circle'}
                type={IconTypes.ICON_FONT}
                style={{
                  marginRight: '10px',
                  fontSize: '18px',
                  color: '#d9d9d9',
                }}
              />
              <span style={{fontSize: '18px'}}>
                {getNodeDisplayTitle(connectedNodes[0])}
              </span>
            </div>
            <div style={{position: 'relative', padding: '15px 0'}}>
              <div style={{
                position: 'absolute',
                top: '-5px',
                left: '9px',
                bottom: '-6px',
                width: '0',
                borderLeft: '1px solid #1890ff',
              }}/>
              <Icon
                name={'icon-scissors-tool'}
                type={IconTypes.ICON_FONT}
                style={{
                  position: 'absolute',
                  top: '0',
                  left: '-52px',
                  fontSize: '32px',
                  color: '#999',
                  transform: 'rotate(90deg) translateY(-50%)',
                }}
              />
            </div>
            <div style={{width: '100%', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}}>
              <Icon
                name={'icon-circle'}
                type={IconTypes.ICON_FONT}
                style={{
                  marginRight: '10px',
                  fontSize: '18px',
                  color: '#d9d9d9',
                }}
              />
              <span style={{fontSize: '18px'}}>
                {getNodeDisplayTitle(connectedNodes[1])}
              </span>
            </div>
          </div>
        ),
        okText: '确认删除',
        okButtonProps: {
          type: 'danger',
        },
        cancelText: '取消',
        onOk: () => {
          PB.emit('aiConsole', 'message.push', {
            type: 'user',
            content: `删除节点 "${getNodeDisplayTitle(connectedNodes[0], 12)}" 与 "${getNodeDisplayTitle(connectedNodes[1], 12)}" 的关联关系`,
          });
          PB.emit('aiConsole', 'message.push', {
            type: 'ai',
            content: (<span><Icon name={'loading'} style={{marginRight: '0.5em'}}/>请稍后...</span>),
            callback: ({key}) => messageKey = key,
            delay: 200,
          });
          me.props.networkRef.removeRelation(edge.from, edge.to).then(() => {
            PB.emit('console', 'info', '关联关系添加成功');
            PB.emit('aiConsole', 'message.update', {key: messageKey, content: `操作成功`});
          }).catch(({code, msg}) => {
            showErrorMessage({code, msg, extra: {viewId: me.state.viewId, isModification: true}});
            PB.emit('console', 'info', '关联关系添加失败');
            PB.emit('aiConsole', 'message.update', {key: messageKey, content: `操作失败`});
          });
        },
      });
    });
  };

  showGuideModal = () => {
    this.setState({showGuideModal: true});
  };

  drawBackground = (ctx) => {
    let me = this, factor, iterations, cacheCtx, i;

    if (!me.backgroundImageConfig.dataUrl) return;

    if (!me.backgroundImageConfig.imageLoaded) return;

    let image = me.backgroundImageConfig.image;

    if (!me.backgroundCache.initialized && image) {
      me.backgroundCache.initialized = true;

      let w = image.width;
      let h = image.height;

      for (i = 0; i <= NUM_ITERATIONS; i++) {
        me.backgroundCache[`canvas${i}`] = document.createElement('canvas');
        me.backgroundCache[`canvas${i}`].width = w;
        me.backgroundCache[`canvas${i}`].height = h;

        cacheCtx = me.backgroundCache[`canvas${i}`].getContext('2d');

        if (h > 0 && w > 0) {
          cacheCtx.drawImage(image, 0, 0, w, h);
        } else {
          delete me.backgroundCache[`canvas${i}`];
        }

        w = Math.floor(w / 2);
        h = Math.floor(h / 2);
      }
    }

    if (me.backgroundCache.initialized) {
      factor = 1 / me.network.getScale();
      iterations = -1;
      if (factor > 2) {
        // Determine which zoomed image to use
        factor *= 0.5;
        iterations = 0;
        while (factor > 2 && iterations < NUM_ITERATIONS) {
          factor *= 0.5;
          iterations += 1;
        }

        if (iterations >= NUM_ITERATIONS) {
          iterations = NUM_ITERATIONS - 1;
        }
      }
      ctx.drawImage(me.backgroundCache[`canvas${iterations + 1}`] ? me.backgroundCache[`canvas${iterations + 1}`] : image,
        -image.width / 2, -image.height / 2, image.width, image.height
      );
    }
  };

  handleExtraProperties = (extraData, nodeIds, originalNodes) => {
    let me = this;

    let extraProperties = extraData;

    // PB.emit('aiConsole', 'status.do_change', {status: 'active'});
    Object.keys(extraProperties).map(key => {
      let contentDom = [], messageKey, index = 1;
      // contentDom.push(<div key={`extraProperties-item-${key}`} style={{padding: '.6rem 0 .3rem 0'}}>{key}：</div>);
      if (extraProperties[key].length > 0) {
        extraProperties[key].map((item, idx) => {
          if (item.meta && (item.meta.description || item.meta.url)) {
            const {name, type, color} = getNodeIcon(item);
            contentDom.push(
              <Tooltip
                key={`extraProperties-item-${index}`}
                title={<div className={style['extra-properties-tooltip']}>
                  <div className={style['text-name']}>
                    <span><span>{getNodeDisplayTitle(item)}</span></span>
                  </div>
                  <div className={style['text-icon']}>图标：{
                    item.meta && item.meta.iconData ? (
                      <UserAvatar
                        name={item.fname}
                        size={40}
                        src={item.meta.iconData}
                        style={{marginBottom: '.3rem'}}
                      />
                    ) : (
                      <Icon name={name} type={type} color={color} style={{marginRight: '0.5em'}}/>
                    )
                  }</div>
                  <div
                    className={`multi-ellipsis ${style['text-desc']} `}>描述：{item.meta && item.meta.description ? item.meta.description : '--'}</div>
                  <div
                    className={style['text-url']}>外链：{item.meta && item.meta.url ? item.meta.url : '--'}</div>
                </div>}
                placement={'left'}
              >
                <div
                  className={style['extra-properties-item']}
                  style={idx === 0 ? {color: '#3498db'} : null}
                  onClick={e => {
                    e.stopPropagation();
                    if (idx === 0) return;
                    me.selectExtraPropertiesItem(key, item, nodeIds, messageKey, originalNodes);
                  }}
                >
                  <label style={{cursor: 'pointer'}}>
                    <input
                      type="radio"
                      value={`${key}-${index}`}
                      name={key}
                      defaultChecked={index === 1}
                      style={{marginRight: '.3rem'}}
                    />
                    <span>{index}.{item.meta && item.meta.description || ''} {item.meta && item.meta.url || ''}</span>
                  </label>
                </div>
              </Tooltip>);
            index++;
          }
        });
      }

      if (contentDom.length > 0) {
        contentDom.push(
          <div className={style['extra-properties-item']}
               onClick={e => {
                 e.stopPropagation();
                 me.selectExtraPropertiesItem(key, null, nodeIds, messageKey, originalNodes);
               }}
          >
            <label style={{cursor: 'pointer'}}>
              <input type="radio" value={`${key}-${index}`} name={key} style={{marginRight: '.3rem'}}/>
              <span>{index}.取消自动填充</span>
            </label>
          </div>
        );

        PB.emit('aiConsole', 'message.push', {
          type: 'ai',
          content: (
            <div>
              <div>{`节点“${key}”可自动填充选项有：`}</div>
              <div style={{paddingTop: '.5rem'}}>{
                contentDom.map((item, index) => <div key={`extraProperties-o-${index}`}>{item}</div>)
              }</div>
            </div>
          ),
          callback: ({key}) => messageKey = key,
        });
      }
    });
  };

  selectExtraPropertiesItem = (key, extraDataItem, nodeIds, messageKey, originalNodes) => {
    let me = this;
    const userId = parseInt(localStorage.getItem('userId'));

    if (nodeIds.length > 0) {
      nodeIds.forEach(nodeId => {
        if (me.data.nodes.get(nodeId) && me.data.nodes.get(nodeId).fname === key) {
          let newNode = me.data.nodes.get(nodeId);
          let dataSource = extraDataItem;
          let userFill = originalNodes.filter(item => item.fname === key)[0];
          let target = new Node(userFill);
          target.id = newNode.id;

          if (extraDataItem) {
            // preferredType
            if (dataSource.aiPreferredType) {
              target['aiPreferredType'] = dataSource.aiPreferredType;
            } else if (dataSource.type) {
              target['aiPreferredType'] = dataSource.type;
            } else if (dataSource.meta && dataSource.meta.preferredType) {
              target['aiPreferredType'] = dataSource.meta.preferredType;
            } else {
              target['aiPreferredType'] = null;
            }

            if (target.meta && target.meta.preferredType) {
              delete target.meta.preferredType;
            }

            // lev
            if (!target.lev || target.lev === 'gradeD') {
              if (dataSource.lev) {
                target.lev = dataSource.lev;
              }
            }

            // description
            if (!target.description && target.meta && target.meta.description) {
              target.description = target.meta.description;
            }
            if (!target.description) {
              if (dataSource.description) {
                target.description = dataSource.description;
              } else if (dataSource.meta && dataSource.meta.description) {
                target.description = dataSource.meta.description;
              }
            }

            // url
            if (!target.url && target.meta && target.meta.url) {
              target.url = target.meta.url;
            }
            if (!target.url) {
              if (dataSource.url) {
                target.url = dataSource.url;
              } else if (dataSource.meta && dataSource.meta.url) {
                target.url = dataSource.meta.url;
              }
            }

            // meta
            if (dataSource.meta) {
              Object.keys(dataSource.meta).forEach(key => {
                if (!Object.hasOwnProperty.call(target.meta, key)) {
                  target.meta[key] = dataSource.meta[key];
                }
              });
            }

            if (target.meta && target.meta.userPreferredType) {
              delete target.meta.userPreferredType;
            }
          }

          me.props.networkRef.updateNodeInfo(
            {...target, userId}
          ).then(() => {
            me.myNetwork.focus([nodeId], {locked: false});
            PB.emit('console', 'info', extraDataItem ? '节点自动填充更新成功' : '节点自动填充取消成功');
            PB.emit('aiConsole', 'message.update', {
              key: messageKey,
              content: extraDataItem ? `节点“${key}”自动填充更新成功` : `节点“${key}”自动填充取消成功`,
            });
            me.setState({editModalType: false, editModalProcessing: false});
          }).catch(({code, msg}) => {
            showErrorMessage({code, msg, extra: {viewId: me.state.viewId, isModification: true}});
            PB.emit('console', 'info', extraDataItem ? '节点自动填充更新失败' : '节点自动填充取消失败');
            PB.emit('aiConsole', 'message.update', {
              key: messageKey,
              content: extraDataItem ? `节点“${key}”自动填充更新失败` : `节点“${key}”自动填充取消失败`,
            });
            me.setState({editModalProcessing: false});
          });
        } else {
          PB.emit('aiConsole', 'message.update', {key: messageKey, content: `节点“${key}”已修改或已删除`});
        }
      })
    }
  };

  getActionsForAiConsoleMessageForEdgeInfo = (edge, connectedNodes) => [(
    <Tooltip title={`定位到关联关系`} key={'locate'}>
      <Button
        shape={'circle'}
        className={'first ant-btn-icon ant-btn-icon-only'}
        onClick={e => {
          e.preventDefault();
          PB.emit('network', 'focus', connectedNodes)
        }}
      >
        <Icon name={'icon-location'} type={IconTypes.ICON_FONT}/>
      </Button>
    </Tooltip>
  ), (edge && edge.status !== 1) ? (
    <Tooltip title={'保留关联关系'} key={'save'}>
      <Button
        shape={'circle'}
        className={'last ant-btn-icon ant-btn-icon-only'}
        onClick={() => {
          PB.emit('network', 'edge.on_add',
            {from: connectedNodes[0], to: connectedNodes[1], isNew: false});
        }}
      >
        <Icon name={'check'}/>
      </Button>
    </Tooltip>
  ) : null, (edge && !edge._localOnly) ? (
    <Tooltip title={'删除关联关系'} key={'remove'}>
      <Button
        shape={'circle'}
        className={`${edge.status === 1 ? 'last' : ''} ant-btn-icon ant-btn-icon-only`}
        onClick={() => {
          PB.emit('network', 'edge.on_remove', {edge});
        }}
      >
        <Icon name={'icon-scissors-tool'} type={IconTypes.ICON_FONT}/>
      </Button>
    </Tooltip>
  ) : null, (edge && edge.status === 1 && !edge._userMarkedInvisible) ? (
    <Tooltip title={'设为默认隐藏'} key={'hide'}>
      <Button
        shape={'circle'}
        className={'last ant-btn-icon ant-btn-icon-only'}
        onClick={() => {
          PB.emit('network', 'edge.do_edit',
            {...edge, _userMarkedInvisible: true, meta: {...(edge.meta || {}), userMarkedInvisible: 1}});
        }}
      >
        <Icon name={'eye-invisible'}/>
      </Button>
    </Tooltip>
  ) : null, (edge && edge.status === 1 && edge._userMarkedInvisible) ? (
    <Tooltip title={'设为默认展示'} key={'show'}>
      <Button
        shape={'circle'}
        className={'last ant-btn-icon ant-btn-icon-only'}
        onClick={() => {
          PB.emit('network', 'edge.do_edit',
            {...edge, _userMarkedInvisible: false, meta: {...(edge.meta || {}), userMarkedInvisible: 0}});
        }}
      >
        <Icon name={'eye'}/>
      </Button>
    </Tooltip>
  ) : null, (edge && edge.status === 1 && !edge._userMarkedInvisible) ? (
    (edge.meta && edge.meta.smooth !== undefined && edge.meta.smooth === 0) ? (
      <Tooltip title={'改为曲线'} key={'smooth'}>
        <Button
          shape={'circle'}
          className={'ant-btn-icon ant-btn-icon-only'}
          onClick={() => {
            PB.emit('network', 'edge.do_edit',
              {...edge, meta: {...edge.meta, smooth: 1}});
          }}
        >
          <Icon name={'icon-smooth-line'} type={IconTypes.ICON_FONT}/>
        </Button>
      </Tooltip>
    ) : (
      <Tooltip title={'改为直线'} key={'straight'}>
        <Button
          shape={'circle'}
          className={'ant-btn-icon ant-btn-icon-only'}
          onClick={() => {
            PB.emit('network', 'edge.do_edit',
              {...edge, meta: {...(edge.meta || {}), smooth: 0}});
          }}
        >
          <Icon name={'icon-straight-line'} type={IconTypes.ICON_FONT}/>
        </Button>
      </Tooltip>
    )
  ) : (
    (edge.meta && edge.meta.smooth !== undefined && edge.meta.smooth === 0) ? (
      <Tooltip title={'隐藏状态时，不可编辑'} key={'disabled-smooth'}>
        <Button
          shape={'circle'}
          className={'ant-btn-icon ant-btn-icon-only'}
          disabled={true}
        >
          <Icon name={'icon-smooth-line'} type={IconTypes.ICON_FONT}/>
        </Button>
      </Tooltip>
    ) : (
      <Tooltip title={'隐藏状态时，不可编辑'} key={'disabled-straight'}>
        <Button
          shape={'circle'}
          className={'ant-btn-icon ant-btn-icon-only'}
          disabled={true}
        >
          <Icon name={'icon-straight-line'} type={IconTypes.ICON_FONT}/>
        </Button>
      </Tooltip>
    )
  ), <Tooltip
    overlayClassName={style['search-engine-list']}
    title={
      <div>
        <div
          onClick={e => {
            e.preventDefault();
            this.currentSearchEngine = SysConfig.sysSearchEngine;
            window.open(`${SysConfig.sysSearchEngine}${getNodeDisplayTitle(connectedNodes[0]) + ' ' + getNodeDisplayTitle(connectedNodes[1])}`, '_blank');
          }}
          className={style['search-engine-item']}
        >百度搜索
        </div>
        <div
          onClick={e => {
            e.preventDefault();
            this.currentSearchEngine = SysConfig.sysSearchEngineBySogou;
            window.open(`${SysConfig.sysSearchEngineBySogou}${getNodeDisplayTitle(connectedNodes[0]) + ' ' + getNodeDisplayTitle(connectedNodes[1])}`, '_blank');
          }}
          className={style['search-engine-item']}
        >搜狗搜索
        </div>
        <div
          onClick={e => {
            e.preventDefault();
            this.currentSearchEngine = SysConfig.sysSearchEngineByBing;
            window.open(`${SysConfig.sysSearchEngineByBing}${getNodeDisplayTitle(connectedNodes[0]) + ' ' + getNodeDisplayTitle(connectedNodes[1])}`, '_blank');
          }}
          className={style['search-engine-item']}
        >必应搜索
        </div>
        <div
          onClick={e => {
            e.preventDefault();
            this.currentSearchEngine = SysConfig.sysSearchEngineBySo;
            window.open(`${SysConfig.sysSearchEngineBySo}${getNodeDisplayTitle(connectedNodes[0]) + ' ' + getNodeDisplayTitle(connectedNodes[1])}`, '_blank');
          }}
          className={style['search-engine-item']}
        >360搜索
        </div>
      </div>
    }
    key={'search_online'}>
    <Button
      shape={'circle'}
      style={{float: 'right'}}
      className={'last ant-btn-icon ant-btn-icon-only'}
      onClick={e => {
        e.preventDefault();
        window.open(`${this.currentSearchEngine}${getNodeDisplayTitle(connectedNodes[0]) + ' ' + getNodeDisplayTitle(connectedNodes[1])}`, '_blank');
      }}
    >
      <Icon name={'icon-earth'} type={IconTypes.ICON_FONT}/>
    </Button>
  </Tooltip>];

  delayMarkAsInterested = nodeId => {
    let me = this;

    if (me.delayMarkAsInterestedTimeout) {
      clearTimeout(me.delayMarkAsInterestedTimeout);
      me.delayMarkAsInterestedTimeout = undefined;
    }

    if (!nodeId) return;

    me.delayMarkAsInterestedTimeout = setTimeout(() => {
      me.delayMarkAsInterestedTimeout = undefined;
      if (nodeId === me.currentNodeId) {
        console.log(`Node (id=${nodeId}) is marked as interested.`);
        if (me.props.networkRef) {
          // noinspection JSIgnoredPromiseFromCall
          me.props.networkRef.accessNode(nodeId);
        }
      }
    }, 1000);
  };

  undoAddRelationFnGenerator = (messageKey, content, relation) => {
    let me = this, removeFn = () => {
      // 停用按钮
      PB.emit('aiConsole', 'message.update', {
        key: messageKey,
        content: (
          <span>
            {content}
            <span style={{marginLeft: '0.5em', opacity: 0.6}}>撤销</span>
          </span>
        ),
      });
      PB.emit('aiConsole', 'message.push', {
        type: 'user',
        content: `撤销新增节点`,
      });
      let undoMessageKey;
      PB.emit('aiConsole', 'message.push', {
        type: 'ai',
        content: '正常处理...',
        callback: ({key}) => undoMessageKey = key,
        delay: 200,
      });
      me.props.networkRef.removeRelationGraph(
        relation['nodes'].map(node => node.id),
        relation['edges'].map(edge => ({from: edge.from, to: edge.to}))
      ).then(() => {
        PB.emit('aiConsole', 'message.update', {
          key: undoMessageKey,
          content: '撤销成功',
        });
      }).catch(() => {
        PB.emit('aiConsole', 'message.update', {
          key: undoMessageKey,
          content: '撤销失败',
        });
        PB.emit('aiConsole', 'message.update', {
          key: messageKey,
          content: (
            <span>
              {content}
              <a
                style={{marginLeft: '0.5em'}}
                className={'plain-action'}
                onClick={removeFn}
              >撤销</a>
            </span>
          ),
        });
      });
    };
    return removeFn;
  };

  recommendNodesByConnection = (nodeId, messageKey, content, relation) => {
    let me = this, userId = parseInt(localStorage.getItem('userId')), newNodeStatus = {}, nodeMap = {};
    let updateContentFn = (nodes) => {
      PB.emit('aiConsole', 'message.update', {
        key: messageKey,
        content: (
          <div>
            <div>
              {content}
              <a
                style={{marginLeft: '0.5em'}}
                className={'plain-action'}
                onClick={
                  me.undoAddRelationFnGenerator(messageKey, content, relation)
                }
              >撤销</a>
            </div>
            <div
              className={
                `${style['extra-recommendation']} animate__animated animate__fadeIn`
              }
            >
              {
                nodes.length > 0 ? (
                  <Tooltip title={'勾选即可保留节点'}>
                    <Divider>
                      您可能还关注
                    </Divider>
                  </Tooltip>
                ) : (
                  <Divider>
                    您可能还关注
                  </Divider>
                )
              }
              {
                nodes.length > 0 ? (
                  <ul>
                    {
                      nodes.map((n, idx) => (n && n.description) ? (
                        <Tooltip
                          key={`n-${idx}`}
                          placement={'left'}
                          title={
                            n.description.split('\n').map((line, idx) => (
                              <span key={`ln-${idx}`}>{line}<br/></span>
                            ))
                          }
                        >
                          {itemFn(nodes, n, idx)}
                        </Tooltip>
                      ) : itemFn(nodes, n, idx))
                    }
                  </ul>
                ) : (
                  <div
                    key={`not-found`}
                    className={style['not-found']}
                  >
                    尚未找到可能相关的节点
                  </div>
                )
              }
            </div>
          </div>
        ),
      });
    };
    let itemFn = (nodes, n, idx) => (
      <li
        key={`n-${idx}`}
        onClick={() => newNodeStatus[n.id] === true
          && me.props.networkRef.getNode(nodeMap[n.id].id)
          && PB.emit('network', 'focus', nodeMap[n.id])}
        className={newNodeStatus[n.id] === true ? 'added' : ''}
      >
        {
          (newNodeStatus[n.id] === false) ? (
            <Checkbox
              style={{marginRight: '8px'}}
              onClick={e => e.stopPropagation()}
              onChange={() => {
                let newNodeMessageKey;
                PB.emit('aiConsole', 'message.push', {
                  type: 'user',
                  content: `保留节点 "${getNodeDisplayTitle(n, 12)}"`,
                });
                PB.emit('aiConsole', 'message.push', {
                  type: 'ai',
                  content: (<span><Icon name={'loading'} style={{marginRight: '0.5em'}}/>请稍后...</span>),
                  callback: ({key}) => newNodeMessageKey = key,
                  delay: 200,
                });
                newNodeStatus[n.id] = 'waiting';
                let tmpNodeId = n.id;
                delete n.id;
                n.meta && (n.meta.status = 1);
                n.userConfirmed = true;
                n.forceAdd = true;
                n.status = 1;
                me.props.networkRef.saveRelationGraph(
                  [n],
                  [{from: nodeId, toIndex: 0, userConfirmed: true}],
                  nodeId
                ).then(relation => {
                  PB.emit('aiConsole', 'message.update',
                    {key: newNodeMessageKey, content: `操作成功`});
                  newNodeStatus[tmpNodeId] = true;
                  nodeMap[tmpNodeId] = relation.nodes[0];
                  updateContentFn(nodes);
                }).catch(() => {
                  PB.emit('aiConsole', 'message.update',
                    {key: newNodeMessageKey, content: `操作失败`});
                  newNodeStatus[tmpNodeId] = false;
                  updateContentFn(nodes);
                });
                n.id = tmpNodeId;
                updateContentFn(nodes);
              }}
              checked={false}
            />
          ) : (
            <Icon
              name={newNodeStatus[n.id] !== true ? 'loading' : 'check-circle'}
              style={{marginRight: '8px', verticalAlign: '-0.2rem'}}
            />
          )
        }
        {getNodeDisplayTitle(n)}
      </li>
    );
    me.props.networkRef.exploreSyncByConnection(nodeId, {userId}).then(({nodes}) => {
      if (me.props.networkRef.getNode(nodeId)) {
        // 节点仍然存在
        nodes.forEach(n => newNodeStatus[n.id] = false);
        // me.setState({recommendationByConnection: nodes}, () => updateContentFn(nodes));
        updateContentFn(nodes);
      }
    }).catch(() => {
      updateContentFn([]);
    });
    /*me.setState({recommendationByConnection: []}, () => {
    });*/
  };

  onKeyDown = e => {
    // left = 37
    // up = 38
    // right = 39
    // down = 40
    let me = this, matched = false;
    if (e.ctrlKey && !e.metaKey) {
      if (e.shiftKey) {
        switch (e.code) {
          case 'Comma': // ,或<
            if (!me.ctrlKeyDown.zoomOut) {
              me.ctrlKeyDown.holding = 0;
            }
            me.ctrlKeyDown.zoomOut = true;
            me.ctrlKeyDown.duration[4] = true;
            break;
          case 'Period': // .或>
            if (!me.ctrlKeyDown.zoomIn) {
              me.ctrlKeyDown.holding = 0;
            }
            me.ctrlKeyDown.zoomIn = true;
            me.ctrlKeyDown.duration[5] = true;
            break;
          default:
            return;
        }
        matched = true;
      } else {
        switch (e.code) {
          case 'KeyH': // h
          case 'ArrowLeft': // left
            if (!me.ctrlKeyDown.left) {
              me.ctrlKeyDown.holding = 0;
            }
            me.ctrlKeyDown.left = true;
            me.ctrlKeyDown.duration[2] = true;
            break;
          case 'KeyK': // k
          case 'ArrowUp': // up
            if (!me.ctrlKeyDown.up) {
              me.ctrlKeyDown.holding = 0;
            }
            me.ctrlKeyDown.up = true;
            me.ctrlKeyDown.duration[0] = true;
            break;
          case 'KeyL': // l
          case 'ArrowRight': // right
            if (!me.ctrlKeyDown.right) {
              me.ctrlKeyDown.holding = 0;
            }
            me.ctrlKeyDown.right = true;
            me.ctrlKeyDown.duration[3] = true;
            break;
          case 'KeyJ': // j
          case 'ArrowDown': // down
            if (!me.ctrlKeyDown.down) {
              me.ctrlKeyDown.holding = 0;
            }
            me.ctrlKeyDown.down = true;
            me.ctrlKeyDown.duration[1] = true;
            break;
          case 'Comma': // ,或<
            if (!me.ctrlKeyDown.zoomOut) {
              me.ctrlKeyDown.holding = 0;
            }
            me.ctrlKeyDown.zoomOut = true;
            me.ctrlKeyDown.duration[4] = true;
            break;
          case 'Period': // .或>
            if (!me.ctrlKeyDown.zoomIn) {
              me.ctrlKeyDown.holding = 0;
            }
            me.ctrlKeyDown.zoomIn = true;
            me.ctrlKeyDown.duration[5] = true;
            break;
          case 'Backquote': // ~
            if (!me.ctrlKeyDown.story) {
              me.ctrlKeyDown.holding = 0;
            }
            me.ctrlKeyDown.story = true;
            break;
          default:
            return;
        }
        matched = true;
      }
    }
    if (matched) {
      e.preventDefault();
      e.stopPropagation();
      if(me.ctrlKeyDown.story){
        PB.emit('presentation', 'show.presentation.node.list');
        me.ctrlKeyDown.story = false;
      }else{
        if (!me.ctrlKeyDown.movingInterval) {
          me.ctrlKeyDown.movingInterval = setInterval(() => {
            let moveLeft = 0, moveDown = 0, moveScale = 1;
            if (me.ctrlKeyDown.duration[3]) moveLeft += 1;
            if (me.ctrlKeyDown.duration[2]) moveLeft -= 1;
            if (me.ctrlKeyDown.duration[1]) moveDown += 1;
            if (me.ctrlKeyDown.duration[0]) moveDown -= 1;
            if (me.ctrlKeyDown.duration[5]) moveScale += 0.1;
            if (me.ctrlKeyDown.duration[4]) moveScale -= 0.1;
            me.ctrlKeyDown.holding++;
            me.ctrlKeyDown.done = true;
            // 获取当前位置及缩放比例
            let {x, y} = me.myNetwork.network.getViewPosition(), scale = me.myNetwork.network.getScale();
            x += (me.ctrlKeyDown.holding > 5 ? 90 : 30) * moveLeft / scale;
            y += (me.ctrlKeyDown.holding > 5 ? 90 : 30) * moveDown / scale;
            scale *= (me.ctrlKeyDown.holding > 5 ? moveScale * moveScale : moveScale)
            me.myNetwork.network.moveTo({position: {x, y}, scale, animation: {duration: 100, easingFunction: 'linear'}});
          }, 100);
        }
      }
    }
  };

  onKeyUp = e => {
    // left = 37
    // up = 38
    // right = 39
    // down = 40
    let me = this, matched = false;
    if (me.ctrlKeyDown.movingInterval) {
      switch (e.code) {
        case 'KeyH': // h
        case 'ArrowLeft': // left
          me.ctrlKeyDown.left = false;
          break;
        case 'KeyK': // k
        case 'ArrowUp': // up
          me.ctrlKeyDown.up = false;
          break;
        case 'KeyL': // l
        case 'ArrowRight': // right
          me.ctrlKeyDown.right = false;
          break;
        case 'KeyJ': // j
        case 'ArrowDown': // down
          me.ctrlKeyDown.down = false;
          break;
        case 'Comma': // ,或<
          me.ctrlKeyDown.zoomOut = false;
          break;
        case 'Period': // .或>
          me.ctrlKeyDown.zoomIn = false;
          break;
        case 'Backquote': // ~
          me.ctrlKeyDown.story = false;
          break;
        default:
          return;
      }
      matched = true;
    } else if (e.ctrlKey && e.shiftKey && !e.metaKey) {
      switch (e.code) {
        case 'KeyL': // L
          if (!me.inMultiLineMode) {
            me.changeEventsByMode(true);
            e.preventDefault();
            e.stopPropagation();
          }
          return;
        case 'KeyS': // S
          if (me.inMultiLineMode) {
            me.changeEventsByMode(false, true);
            e.preventDefault();
            e.stopPropagation();
          }
          return;
        case 'KeyQ': // Q
          if (me.inMultiLineMode) {
            me.changeEventsByMode(false, false);
            e.preventDefault();
            e.stopPropagation();
          }
          return;
      }
    }
    if (matched) {
      e.preventDefault();
      e.stopPropagation();
      if (!me.ctrlKeyDown.left && !me.ctrlKeyDown.up && !me.ctrlKeyDown.right && !me.ctrlKeyDown.down &&
        !me.ctrlKeyDown.zoomOut && !me.ctrlKeyDown.zoomIn) {
          if(me.ctrlKeyDown.done){
            me.changerCtrlKeyDownDuration(e.code);
          }else{
            setTimeout(() => {
              me.changerCtrlKeyDownDuration(e.code);
            },100);
          }
      }
      me.ctrlKeyDown.done = false;
    }
  };

  changerCtrlKeyDownDuration = (code) => {
    let me = this;
    clearInterval(me.ctrlKeyDown.movingInterval);
    me.ctrlKeyDown.movingInterval = undefined;
    switch (code) {
      case 'KeyH': // h
      case 'ArrowLeft': // left
        me.ctrlKeyDown.duration[2] = false;
        break;
      case 'KeyK': // k
      case 'ArrowUp': // up
        me.ctrlKeyDown.duration[0] = false;
        break;
      case 'KeyL': // l
      case 'ArrowRight': // right
        me.ctrlKeyDown.duration[3] = false;
        break;
      case 'KeyJ': // j
      case 'ArrowDown': // down
        me.ctrlKeyDown.duration[1] = false;
        break;
      case 'Comma': // ,或<
        me.ctrlKeyDown.duration[4] = false;
        break;
      case 'Period': // .或>
        me.ctrlKeyDown.duration[5] = false;
        break;
      default:
        return;
    }
  }

  onDragAddEdge = (edgeData, callback) => {
    let me = this;
    const multiLines = me.state.multiLines;
    if (edgeData.from === edgeData.to) {
      message.warning('请将某个节点拖拽到要连接的节点以创建连接');
      me.myNetwork.network.addEdgeMode();
      callback();
      return;
    }
    let fromNode = me.data.nodes.get(edgeData.from), toNode = me.data.nodes.get(edgeData.to);
    if (fromNode.status === 0 || toNode.status === 0) {
      message.warning('未保留节点不能被连接');
      me.myNetwork.network.addEdgeMode();
      callback();
      return
    }
    //已存在的边
    const existEdges = me.data.edges.get(me.myNetwork.network.getConnectedEdges(edgeData.from));
    //查询是不是已存在的边
    const tmpEdge = existEdges.findIndex(edge =>
      edge.visible && ((edge.from === edgeData.from && edge.to === edgeData.to) ||
      (edge.to === edgeData.from && edge.from === edgeData.to)));
    //查询是不是新添加的边
    const tmpNewEdge = multiLines.findIndex(edge =>
      (edge.from === edgeData.from && edge.to === edgeData.to) ||
      (edge.to === edgeData.from && edge.from === edgeData.to));

    const newEdge = new Edge({
      from: edgeData.from,
      to: edgeData.to,
      userConfirmed: false,
      status: 0,
    });

    // 图上不存在
    if (tmpEdge === -1 && tmpNewEdge === -1) { // 没有添加过的边，加进数组
      callback(newEdge, 'magicId$doNotAutoStartSimulation');
      multiLines.push(newEdge);
      return;
    } else if (tmpNewEdge > -1) {//已经添加过的边,再次点击取消
      me.data.edges.remove(multiLines[tmpNewEdge].id);
      me.myNetwork.network.addEdgeMode();
      multiLines.splice(tmpNewEdge, 1);
    }
    callback();
  };

  /*
  * 处理冗余数据
  * 去重
  * */
  handleRedundancyNodes = (nodes) => {
    let result = [], sameIndex = new Set();
    const fieldPaths = [
      'lev',
      'description',
      'meta.description',
      'url',
      'meta.url',
      'userPreferredType',
      'meta.userPreferredType',
      'aiPreferredType',
      'tag',
      'tags',
      'meta.iconData',
      'meta.scale',
      'meta.iconMeta.rectWidth',
      'meta.iconMeta.rectHeight',
      'meta.iconMeta.shape',
    ];

    // 记录重复元素的索引index
    for (let i = 0; i < nodes.length; i++) {
      for (let j = i + 1; j < nodes.length; j++) {
        let flag = false;
        fieldPaths.forEach(path => {
          if (_.get(nodes[i], path) !== _.get(nodes[j], path)) {
            flag = true;
          }
        });
        if (!flag) {
          sameIndex.add(j);
        }
      }
    }

    // 保留不重复元素
    nodes.forEach((node, index) => {
      if (!sameIndex.has(index)) {
        result.push(node);
      }
    });
    return result;
  };

  eventMap = (params) => {
    let me = this;
    const key = 'messageEventMap';
    message.loading({
      content: '正在计算中，本次计算预计用时3分钟，请耐心等待。计算成功后，生成的事件图谱24小时后会自动删除，如想保留可改动图谱名称。',
      key,
      duration: 0,
    });
    RelationDataProvider.makeMap(params).then((data) => {
      if(data && data.length>0){;
        let back_data = data[0];
        if(back_data.msgStatus===1){
          message.warning({
            content: back_data.msg,
            key,
            duration: 3
          });
        }else if(back_data.msgStatus===0){
          message.success({
            content: '计算成功,将为您打开生成的事件图谱。生成的事件图谱24小时后会自动删除，如想保留可改动图谱名称。',
            key,
            duration: 1,
          }).then(()=>{window.open(`/mainview/relation/${back_data.view_id}?type=event`, '_blank');});
          
        }
      }
    }).catch(({code, msg}) => {
      message.destroy(key);
      showErrorMessage({code, msg});
    });
  }

  countDownModal = (url) => {
    let secondsToGo = 5,outTimer;
    const modal = Modal.warning({
      title: '计算成功，生成的事件图谱24小时后自动删除，如想保留可改动图谱名称',
      okText: '知道了',
      onOk: () => {window.open(url, '_blank');clearTimeout(outTimer);}
    });
    outTimer = setTimeout(() => {
      modal.destroy();
      window.open(url, '_blank');
    }, secondsToGo * 1000);
  };

  recommendedVocabulary = () => {
    let me = this;
    RelationDataProvider.callMicroService("a63f93ef-fb6c-40ce-a007-80eb8cf8113a","f60cf0b6-d2cf-494d-9fa5-6da4d97c4984",
    {
      limit: 20,
      start: 0,
      target: {
        textOrUrls: [me.state.viewInfo.name.replace('的事件关联图谱','')],
      },
      parameters: {},
    }).then((data) => {
      if(data && data.length>0 && data[0].nodes && data[0].nodes.length>0){
        PB.emit('aiConsole', 'message.push', {
          type: 'ai',
          content: (
            <div>
              推荐词汇：
              <ul>{data[0].nodes.map((node) => (<li style={{listStyleType:'none',display:'inline-block'}}><a
                  style={{marginRight: '0.8em'}}
                  className={'plain-action'}
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    me.eventMap({wd:node.fname})
                  }}
                >{node.fname}</a></li>
              ))}</ul>
            </div>
          ),
          delay: 200,
        });
      }else{
        message.warn('暂无推荐词汇');
      }
    }).catch(({code, msg}) => {
      console.error('Get common word error',code, msg);
    });          
  }

  showLatestNodes = () => {
    let me = this,nodes = me.data && me.data.nodes && me.data.nodes.get();
    if(nodes && nodes.length>0){
      PB.emit('aiConsole', 'message.push', {
        type: 'ai',
        content: (
          <div>
            {intl.get('Custom.view.newNodes')}：
            <ul>{nodes.sort((a, b) => (
                  -`${a['updateTime'] || a['linkTime'] || a['accessTime'] }`
                    .localeCompare(`${b['updateTime'] || b['linkTime'] || b['accessTime']}`)
                )
              ).slice(0,10).map((node) => (<li className={style['latest-node-li']}
                onClick={e => {
                  e.preventDefault();
                  PB.emit('network', 'focus', node);
                }}
              >
                <span className={style['node-dot']}>
                  <Icon {...getNodeIcon({type: NODE_TYPE_TEXT})} style={{width:'0.8em',marginRight: '0.5em'}}/>
                </span>
                <Tooltip title={node.fname}>{node.fname}</Tooltip></li>
            ))}</ul>
          </div>
        ),
        delay: 200,
      });
    }        
  }

  copyNode = (copyNodeToViewType,node,connectedNodes) => {
    let nodes=[],edges=[];
    if(copyNodeToViewType===1){
      //node.id = undefined;
      nodes.push(node);
    }else{
      /*this.data.edges.get().map((edge)=>{
        if(edge.from==node.id || edge.to==node.id){
          edge.id = undefined;
          edges.push(edge);
        }
      })*/
      nodes = [node,...connectedNodes];
      nodes.map((n,i) => {
        n.id = undefined;
        n.fx = undefined;
        n.fy = undefined;
        n.forceAdd = true;
        i>0 && edges.push({fromIndex:0,toIndex:i,status: 1,userConfirmed: true,"meta": {text2text: true}});
        return n;
      })
    }
    let copyInfo = {nodes,edges};
    this.setState({copyNodeToViewType,copyInfo});
  }

  componentDidMount() {
    let me = this, networkOptions = myVisNetworkStyle.options();

    // 迁移到MyNetwork3
    me.container = document.getElementById('network');
    networkOptions.physics.enabled = false;
    me.myNetwork = new MyNetwork(me.container, {nodes: [], edges: []}, networkOptions);
    me.myNetwork.network.on('beforeDrawing', (ctx) => me.drawBackground(ctx));

    // 打开新手导航页面
    createExpandNodeGuide();

    // 关系图事件监听
    me.props.networkRef.with(me).subscribe(NetworkEvents.LOADING_DATA, viewId => {
      // 关系图加载中
      me.setState({
        viewId,
        dataLoadingStatus: NetworkDataLoadingStatus.PROCESSING,
        dataLoadingErrorCode: 0,
        dataLoadingErrorMsg: '',
      });
    }).subscribe(NetworkEvents.LOADING_STRUCTURE_SUCCESS, viewId => {
      me.setState({
        viewInfo: me.props.networkRef.getData().viewInfo,
      }, () => {
        // 加载底图
        let hidePageLoadingFn = showPageLoading(intl.get('Custom.message.loadingUnderlay'));
        let backgroundLoadedFn = ({viewId}) => {
          if (viewId !== me.props.viewId) {
            PB.once(me, 'view', 'background.loaded', backgroundLoadedFn);
            return;
          }
          hidePageLoadingFn();
        };
        let backgroundLoadFailedFn = ({viewId}) => {
          if (viewId !== me.props.viewId) {
            PB.once(me, 'view', 'background.load_failed', backgroundLoadFailedFn);
            return;
          }
          hidePageLoadingFn();
        };
        PB.once(me, 'view', 'background.loaded', backgroundLoadedFn);
        PB.once(me, 'view', 'background.load_failed', backgroundLoadFailedFn);
        PB.emit('view', 'background.do_load', ({viewId: me.props.viewId}));
        PB.emit('view', 'banner.do_load', ({viewId: me.props.viewId}));
        PB.emit('view', 'info.loaded', ({viewInfo: me.state.viewInfo}));
      });
      // 关系图结构加载完毕
      if (!me.props.graphOnly) {
        me.myNetwork.hideAllEdges();
        me.allEdgesHidden = true;
        let second = data.data.edges.length > 50 ? (data.data.edges.length > 500 ? 4000 : 1000) : 500;
        setTimeout(() => {
          me.allEdgesHidden && me.myNetwork.showAllEdges();
          me.allEdgesHidden = false;
        }, second);
        // 新图谱画面适应屏幕大小
        me.network.fit({animation: true});
      }
    }).subscribe(NetworkEvents.LOADING_DATA_SUCCESS, () => {
      me.props.networkRef.accessView();
      setInterval(() => me.props.networkRef.accessView(), 3600000);
      // 关系图加载完毕
      const data = me.props.networkRef.getData();
      // 修改页面标题
      document.title = data.viewInfo.name + ' - ' + intl.get('Custom.general.title');
      me.myNetwork.normalBrightness(() => true);
      let options = me.myNetwork.options;
      options.physics.enabled = true;
      me.myNetwork.options = options;

      // 关闭提示框，判断是否展示操作指南
      me.setState({
        viewInfo: data.viewInfo,
        dataLoadingStatus: data.status,
        dataLoadingErrorCode: 0,
        dataLoadingErrorMsg: '',
        showGuideModal: data.data.nodes.length <= 1 && localStorage.getItem('show_relation_guide') !== '0', // 是否展示图谱操作指南图片
      }, () => {
        // 加载我的收藏
        let hidePageLoadingFn = showPageLoading(intl.get('Custom.message.loadingFavorite'));
        PB.once(me, 'relation', 'user.favorite.on_data_ready', () => {
          hidePageLoadingFn();
          // 我的收藏节点设置标识_userFavorite，_userFavorite的值为-1(不是我的收藏节点),0,1,2, ...有序数字，用于左侧筛选排序使用
          me.myNetwork.updateNodes(me.props.networkRef.getNode(me.favorite.nodes)
            .filter(n => !!n).map((n, i) => {
              n._userFavorite = i;
              return {...n};
            }));
          let networkData = me.props.networkRef.getData();
          let relationData = {
            nodes: networkData.data.nodes.get(), edges: networkData.data.edges.get(),
            viewId: networkData.viewId
          };
          PB.emit('relation', 'data.after_change', relationData);

          // 加载图谱附件
          requestAnimationFrame(() => me.props.networkRef.loadFileList().then(() => {
            PB.emit('aiConsole', 'message.push', {type: 'ai', content: intl.get('Custom.aiConsole.mapLoaded')});
            PB.emit('relation', 'presentation.on_relation_data_ready', {viewId: me.state.viewId});
            setTimeout(() => {
              PB.emit('aiConsole', 'message.push', {type: 'ai', content: intl.get('Custom.aiConsole.popFilterBar')});
            }, 1000);
            setTimeout(() => {
              PB.emit('aiConsole', 'message.push', {type: 'ai', content: intl.get('Custom.aiConsole.clickReport')});
            }, 1500);
            setTimeout(() => {
              PB.emit('aiConsole', 'message.push', {type: 'ai', content: intl.get('Custom.aiConsole.clickLatestNews')});
            }, 2000);
            setTimeout(() => {
              PB.emit('aiConsole', 'message.push', {type: 'ai', content: me.state.viewInfo.desc});
            }, 2500);
            setTimeout(() => {me.showLatestNodes()}, 3000);
            /*setTimeout(() => {
              PB.emit('aiConsole', 'message.push', {
                type: 'ai',
                content: (
                  <div>
                    点击
                    <a className={'plain-action'} onClick={e => {e.preventDefault();e.stopPropagation();me.recommendedVocabulary()}}>推荐相关词汇</a>
                  </div>
                ),
                delay: 200,
              });
            }, 2500);*/
          }));
        });
        PB.once(me, 'relation', 'view.favorite.on_data_ready', () => {
          hidePageLoadingFn();
          // 重要节点收藏节点设置标识_viewFavorite，_viewFavorite的值为-1(不是我的收藏节点),0,1,2, ...有序数字，用于左侧筛选排序使用
          me.myNetwork.updateNodes(me.props.networkRef.getNode(me.viewFavorite.nodes)
            .filter(n => !!n).map((n, i) => {
              n._viewFavorite = i;
              return {...n};
            }));
          let networkData = me.props.networkRef.getData();
          let relationData = {
            nodes: networkData.data.nodes.get(), edges: networkData.data.edges.get(),
            viewId: networkData.viewId
          };
          PB.emit('relation', 'data.after_change', relationData);
        });
        // 加载我的收藏失败，重新获取
        PB.once(me, 'relation', 'user.favorite.load_failed', hidePageLoadingFn);
        PB.emit('relation', 'user.favorite.get');
        // 加载重要节点收藏失败，重新获取
        PB.once(me, 'relation', 'view.favorite.load_failed', hidePageLoadingFn);
        PB.emit('relation', 'view.favorite.get');
        getNodeLevelInGraph(data.data.nodes.get(), data.data.edges.get(), {
          onProgress: ({nodeLevel}) => {
            me.myNetwork.updateGraph({
              nodes: me.props.networkRef.getNode(Object.keys(nodeLevel))
                .map(node => ({...node, _nodeLevel: nodeLevel[node.id], _brightnessFromLevel: 0.3}))
            });
            if (me.myNetwork.defaultNormalBrightness === 'level') {
              PB.emit('network', 'light_node.do', me.lastLightNodeConfig);
            }
          },
          onSuccess: ({nodeLevel}) => {
            let maxLevel = Math.max(...Object.values(nodeLevel)) / 5, nodeMap = {};
            me.props.networkRef.getNode().forEach(node => {
              nodeMap[node.id] = {
                ...node,
                _brightnessFromLevel: (1000 - 175 * (5 - Math.ceil(node._nodeLevel / maxLevel))) / 1000,
              };
            });
            me.myNetwork.updateGraph({
              nodes: Object.values(nodeMap),
              edges: me.props.networkRef.getEdge().map(edge => {
                edge.updateBrightnessFromLevel(nodeMap[edge.from], nodeMap[edge.to]);
                return edge;
              }),
            });
            if (me.myNetwork.defaultNormalBrightness === 'level') {
              PB.emit('network', 'light_node.do', me.lastLightNodeConfig);
            }
          },
        }, 100);
      });

      // 图谱为开放协作类型时，给出加入图谱提示
      me.props.networkRef.loadViewInfo().then(view => {
        if (view.viewId === me.props.viewId && view.memberInfoInitialized === 0 && view.teamworkType === 1) {
          message.info({
            key: 'teamwork.member.cooperation',
            content: intl.get('Custom.message.cooperation'),
            onClose: () => {
              PB.emit('teamwork', 'member.join.do', {viewId: view.viewId});
              PB.emit('aiConsole', 'message.push', {
                type: 'ai',
                content: (<span>{intl.get('Custom.message.cooperation')[0]}<br/>{intl.get('Custom.message.cooperation')[1]}</span>),
                delay: 200,
              });
            }
          })
        }
      });

      // 新图谱画面适应屏幕大小
      if (me.props.graphOnly) {
        me.myNetwork.hideAllEdges();
        me.allEdgesHidden = true;
        console.time('[time] stabilize');
        console.time('[time] simulation');
        me.network.stabilize();
        let stabilizeDoneFn = () => {
          console.timeEnd('[time] stabilize');
          me.network.off('stabilizationIterationsDone', stabilizeDoneFn);
          let fittingObj = {};
          let stopFittingFn = () => {
            clearTimeout(fittingTimeout);
            me.myNetwork.unSubscribe(fittingObj);
            me.network.fit({animation: false});
            me.myNetwork.network.stopSimulation();
            console.timeEnd('[time] simulation');
            me.allEdgesHidden && me.myNetwork.showAllEdges();
            me.allEdgesHidden = false;
            window.graphReady = true;
          };
          let fittingTimeout = setTimeout(stopFittingFn, 20000);
          me.myNetwork.subscribe(fittingObj, 'NETWORK.nearlyStabilized', stopFittingFn);
        };
        me.network.on('stabilizationIterationsDone', stabilizeDoneFn);
      } else {
        // me.myNetwork.hideAllEdges();
        // me.allEdgesHidden = true;
        // let second = data.data.edges.length > 50 ? (data.data.edges.length > 500 ? 4000 : 1000) : 500;
        // setTimeout(() => {
        //   me.allEdgesHidden && me.myNetwork.showAllEdges();
        //   me.allEdgesHidden = false;
        // }, second);

        let fitted = false;
        let fittingTimeout = setTimeout(() => {
          fitted = true;
          me.network.fit({animation: true});
        }, 10000);
        let fittingObj = {};
        let stopFittingFn = () => {
          me.myNetwork.network.stopSimulation();
          clearTimeout(fittingTimeout);
          me.myNetwork.unSubscribe(fittingObj);
          PB.remove(fittingObj);

          // 频道新手提示
          PB.emit("tutorial", "status.show",{viewInfo: data.viewInfo});

        };
        me.myNetwork.subscribe(fittingObj, 'NETWORK.click', stopFittingFn);
        me.myNetwork.subscribe(fittingObj, 'NETWORK.dragStart', stopFittingFn);
        me.myNetwork.subscribe(fittingObj, 'NETWORK.zoom', stopFittingFn);
        me.myNetwork.subscribe(fittingObj, 'NETWORK.nearlyStabilized', () => {
          me.myNetwork.network.stopSimulation();
          if (!fitted) {
            let hideMsg = showPageLoading(intl.get('Custom.message.calculatingOptimization'));
            me.network.once('animationFinished', () => {
              hideMsg();
            });
            me.network.fit({animation: true});
          }
          stopFittingFn();
        });
        PB.once(fittingObj, 'network', 'focus', stopFittingFn);
        PB.once(fittingObj, 'network', 'node_tooltip.show', stopFittingFn);
        PB.once(fittingObj, 'relation', 'presentation.started', stopFittingFn);
        PB.once(fittingObj, 'node', 'presentation.started', stopFittingFn);

      }

      let relationData = {nodes: data.data.nodes.get(), edges: data.data.edges.get()};
      PB.emit('network', 'graph.after_loaded', relationData);
      PB.emit('relation', 'data.loaded', relationData);
      PB.emit('network', 'decorate_edge.on', {});
    }).subscribe(NetworkEvents.LOADING_DATA_FAILED, (viewId, code, msg) => {
      // 关系图加载失败
      showErrorMessage({
        code,
        msg,
        extra: {viewId: me.state.viewId, accessToken: me.props.userInfo ? me.props.userInfo.accessToken : undefined}
      });
      me.setState({
        dataLoadingStatus: NetworkDataLoadingStatus.FAILED,
        dataLoadingErrorCode: code,
        dataLoadingErrorMsg: msg,
      });
    }).subscribe(NodeEvents.REMOVED, (type, nodeInfoList) => {
      // 删除节点
      // noinspection JSBitwiseOperatorUsage
      if (type & REMOVE_FROM_GRAPH) {
        nodeInfoList.forEach(node => {
          /*message.warning(<span><span
                        className={style["node-in-msg"]}>{node.fname}</span><span>已经从图谱中被移除。</span></span>);*/
          if (node.id === me.currentNodeId) {
            me.currentNodeId = undefined;
            PB.emit('relation', 'node.single_selection_change', me.currentNodeId);
          }
          if (node.id === me.state.contextNodeId) {
            me.setState({contextNodeId: undefined});
          }
          if (me.detailMessageKeys[node.id]) {
            // 清理上一次显示的信息
            PB.emit('aiConsole', 'message.update', {
              key: me.detailMessageKeys[node.id],
              content: '节点已删除',
            });
            delete me.detailMessageKeys[node.id];
          }
        });
      }
      // noinspection JSBitwiseOperatorUsage
      if (type & REMOVE_FROM_VIEW) {
        PB.emit('relation', 'node.removed',
          {viewId: me.props.viewId, nodeIds: nodeInfoList.map(n => n.id), nodes: nodeInfoList});
      }
    }).subscribe(EdgeEvents.REMOVED, (type, edgeInfoList) => {
      // 删除关系
      // noinspection JSBitwiseOperatorUsage
      if (type & REMOVE_FROM_GRAPH) {
        edgeInfoList.forEach(edge => {
          if (me.detailMessageKeys[edge.id]) {
            // 清理上一次显示的信息
            PB.emit('aiConsole', 'message.update', {
              key: me.detailMessageKeys[edge.id],
              content: '关系已变更或删除',
            });
            delete me.detailMessageKeys[edge.id];
          }
        });
      }
    }).subscribe(NodeEvents.ID_REPLACED, (replacement) => {
      Object.keys(replacement).forEach(fromId => {
        if (fromId === me.currentNodeId) {
          me.currentNodeId = replacement[fromId];
          const {data, viewId} = me.props.networkRef.getData();
          let relationData = {nodes: data.nodes.get(), edges: data.edges.get(), viewId};
          PB.emit('relation', 'data.after_change', relationData);
          PB.emit('relation', 'node.single_selection_change', me.currentNodeId);
        }
        if (fromId === me.state.contextNodeId) {
          me.setState({contextNodeId: replacement[fromId]});
        }
        if (me.detailMessageKeys[fromId]) {
          me.detailMessageKeys[replacement[fromId]] = me.detailMessageKeys[fromId];
          delete me.detailMessageKeys[fromId];
        }
      });
      PB.emit('relation', 'node.id_replaced', {viewId: me.props.viewId, replacement});
    }).subscribe(EdgeEvents.ADDED, (type, addedEdgeIds, addedEdges) => {
      // noinspection JSBitwiseOperatorUsage
      if ((type & ADD_TO_GRAPH) && (type & ADD_TO_VIEW)) {
        // Nothing
      } else {
        // noinspection JSBitwiseOperatorUsage
        if (type & ADD_TO_VIEW) {
          // 固化关联关系
          addedEdges.forEach(addedEdge => {
            const fromNode = me.props.networkRef.getNode(addedEdge.from);
            const toNode = me.props.networkRef.getNode(addedEdge.to);
            message.info(
              <span>
                <span className={style["node-in-msg"]}>{getNodeDisplayTitle(fromNode)}</span>
                <span>与</span>
                <span className={style["node-in-msg"]}>{getNodeDisplayTitle(toNode)}</span>
                <span>的关系保存成功</span>
              </span>
            );
            if (me.detailMessageKeys[addedEdge.id]) {
              // 更新上一次显示的信息
              PB.emit('aiConsole', 'message.patch', {
                key: me.detailMessageKeys[addedEdge.id],
                content: (
                  <MainAiConsoleMessageEdgeInfo
                    bus={PB}
                    fromNode={fromNode}
                    toNode={toNode}
                  />
                ),
                actions: me.getActionsForAiConsoleMessageForEdgeInfo(addedEdge, [fromNode, toNode]),
              });
            }
          });
        }
      }
    }).subscribe(NodeEvents.ADDED, (type, addedNodeIds, addedNodes, toNodeId, senderId) => {
      // noinspection JSBitwiseOperatorUsage
      if ((type & ADD_TO_GRAPH) && (type & ADD_TO_VIEW)) {
        // 新增节点
        addedNodes.forEach(addedNode => {
          if (senderId === 'user_add_node') {
            let extraTextList = [];
            if (addedNode['meta']) {
              if (!!addedNode['meta']['iconData']) extraTextList.push('图标');
              if (!!addedNode['description']) extraTextList.push('描述');
              if (!!addedNode['url']) extraTextList.push('外链');
            }
            message.success(
              <span>
                <span>新节点</span>
                <span className={style["node-in-msg"]}>{getNodeDisplayTitle(addedNode)}</span>
                <span>添加成功</span>
                {extraTextList.length > 0 ? (
                  <span>，并自动补充了{extraTextList.join('、')}信息</span>
                ) : null}
              </span>
            );
          } else {
            // Nothing
          }
        });
        PB.emit('relation', 'node.added',
          {viewId: me.props.viewId, nodeIds: addedNodeIds, nodes: addedNodes, toNodeId});
      } else {
        // noinspection JSBitwiseOperatorUsage
        if (type & ADD_TO_VIEW) {
          PB.emit('relation', 'node.added',
            {viewId: me.props.viewId, nodeIds: addedNodeIds, nodes: addedNodes, toNodeId});
        }
      }
    }).subscribe([NetworkEvents.VISIBLE_RELATION_CHANGED, NodeEvents.UPDATED], () => {
      // 关系图发生改变
      const {data, viewId} = me.props.networkRef.getData();
      let relationData = {nodes: data.nodes.get(), edges: data.edges.get(), viewId};
      PB.emit('relation', 'data.after_change', relationData);
    }).subscribe(NodeEvents.UPDATED, (updatedNodeIds, updatedNodes) => {
      // 关系图发生改变
      PB.emit('relation', 'node.updated',
        {viewId: me.props.viewId, nodeIds: updatedNodeIds, nodes: updatedNodes});
      updatedNodes.forEach(node => {
        if (me.detailMessageKeys[node.id]) {
          // 更新上一次显示的信息
          PB.emit('aiConsole', 'message.patch', {
            key: me.detailMessageKeys[node.id],
            content: (
              <MainAiConsoleMessageCommonNodeInfo
                bus={PB}
                node={node}
                originalViewId={me.props.viewId}
                viewDataProvider={me.props.networkRef}
                recommendation={[false]}
              />
            ),
          });

          /*me.props.networkRef.smartSearchUserInAllView(node.fname, node.id).then(users => {
            PB.emit('aiConsole', 'message.patch', {
              key: me.detailMessageKeys[node.id],
              content: (
                <MainAiConsoleMessageCommonNodeInfo
                  bus={PB}
                  node={node}
                  originalViewId={me.props.viewId}
                  recommendation={(users || []).slice(0, 3)}
                  viewDataProvider={me.props.networkRef}
                />
              ),
            });
          }).catch(() => {
            PB.emit('aiConsole', 'message.patch', {
              key: me.detailMessageKeys[node.id],
              content: (
                <MainAiConsoleMessageCommonNodeInfo
                  bus={PB}
                  node={node}
                  originalViewId={me.props.viewId}
                  recommendation={[]}
                  viewDataProvider={me.props.networkRef}
                />
              ),
            });
          });*/
        }
      })
    }).subscribe(EdgeEvents.UPDATED, (updatedEdgeIds, updatedEdges) => {
      // 关系图发生改变
      updatedEdges.forEach(edge => {
        if (me.detailMessageKeys[edge.id]) {
          const fromNode = me.props.networkRef.getNode(edge.from);
          const toNode = me.props.networkRef.getNode(edge.to);
          // 更新上一次显示的信息
          PB.emit('aiConsole', 'message.patch', {
            key: me.detailMessageKeys[edge.id],
            content: (
              <MainAiConsoleMessageEdgeInfo
                bus={PB}
                fromNode={fromNode}
                toNode={toNode}
              />
            ),
            actions: me.getActionsForAiConsoleMessageForEdgeInfo(edge, [fromNode, toNode]),
          });
        }
      })
    });

    //初始化单独加载图谱 与siderBar分离
    if (this.props.viewId) { // 已经传入了view id
      console.log('relation 1 传入的viewId：', this.props.viewId);
      this.setState({viewId: this.props.viewId/*, allDatasets: null*/});
      requestAnimationFrame(() => {
        me.props.networkRef.loadDataGradually(this.props.viewId);
      });
    }

    // 数据处理完成以后再画图
    const data = me.props.networkRef.getData();
    me.data = data.data;
    // this.network = new Network(this.container, this.data, myVisNetworkStyle.options());
    me.network = this.myNetwork.network;
    me.myNetwork.setGraph(this.data);

    // 判断network是否时在多点连线状态还是默认状态，设置vis对应的事件响应
    this.changeEventsByMode();

    // 切换vis的多节点连线模,保存或者不保存节点
    PB.sub(this, 'infoBoard', 'changeEventByMode', (type, ifSave) => {
      this.changeEventsByMode(type, ifSave)
    });
    // 显示network画面上的遮罩div
    PB.sub(this, 'network', 'showMask', (display) => {
      this.setState({showMask: display})
    });
    PB.sub(this, 'relation', 'sub_graph.do_parse', ({value, selectedNodeId, onSuccess, onFailed}) => {
      let me = this, messageKey, content;

      PB.emit('aiConsole', 'message.push', {
        type: 'ai',
        content: (
          <span>
            <Icon name={'loading'} style={{marginRight: '0.5em'}}/>
            正在解析 "{getNodeDisplayTitle({fname: value}, 5)}" ...
          </span>
        ),
        callback: ({key}) => messageKey = key,
        delay: 200,
      });
      RelationDataProvider.parseRelation(value, selectedNodeId).then(parsedRelation => {
        content = `"${getNodeDisplayTitle({fname: value}, 5)}" 解析为`;
        let nodeAmount = parsedRelation.nodes.length;
        let edgeAmount = parsedRelation.edges.length;
        if (nodeAmount > 0 && edgeAmount > 0) {
          content = `${content} ${nodeAmount} 个节点，${edgeAmount} 条边`
        } else if (nodeAmount > 0) {
          content = `${content} ${nodeAmount} 个节点`
        } else {
          content = `${content} ${edgeAmount} 条边`
        }
        PB.emit('aiConsole', 'message.update', {
          key: messageKey,
          content,
        });
        onSuccess && onSuccess({value, selectedNodeId, parsedRelation});
      }).catch(({code, msg}) => {
        console.warn(`解析失败，value: ${value}，code: ${code}, msg: ${msg}`);
        PB.emit('aiConsole', 'message.update', {
          key: messageKey,
          content: `"${getNodeDisplayTitle({fname: value}, 12)}" 解析失败`,
        });
        showErrorMessage({code, msg, extra: {viewId: me.state.viewId, isModification: true}});
        onFailed && onFailed({code, msg});
      });
    });
    PB.sub(this, 'relation', 'sub_graph.do_test_and_save', ({
                                                              nodes,
                                                              edges,
                                                              selectedNodeId,
                                                              onSuccess,
                                                              onFailed,
                                                              onInterrupted
                                                            }) => {
      let me = this, messageKey, content;

      PB.emit('aiConsole', 'message.push', {
        type: 'ai',
        content: (
          <span>
            <Icon name={'loading'} style={{marginRight: '0.5em'}}/>
            正在添加...
          </span>
        ),
        callback: ({key}) => messageKey = key,
        delay: 200,
      });
      me.props.networkRef.saveRelationGraph(
        nodes, edges, selectedNodeId ? selectedNodeId : false, true, 'user_add_node'
      ).then(testedRelation => {
        let noticeMap = [];
        if (testedRelation['node_match']) {
          noticeMap = testedRelation['node_match'].filter(pair => pair[0] !== pair[1]);
        }
        if (noticeMap.length > 0) {
          PB.emit('aiConsole', 'message.update', {
            key: messageKey,
            content: '输入内容匹配到现有节点，等待用户指令',
          });

          me.setState({
            showNodeMatchNoticeModal: true,
            matchedNodes: noticeMap,
            totalNodesToAdd: nodes.length,
            useNewNodeCallback: () => {
              nodes.forEach(node => node.exactText = true);
              PB.emit('aiConsole', 'message.remove', {key: messageKey});
              PB.emit('relation', 'sub_graph.do_save',
                {nodes, edges, selectedNodeId, onSuccess, onFailed});
              if(me.state.rememberOperationState){
                me.setState({rememberOperation:2})
              }else{
                me.setState({rememberOperation:0})
              }
            },
            useOriginalCallback: () => {
              PB.emit('aiConsole', 'message.remove', {key: messageKey});
              PB.emit('relation', 'sub_graph.do_save',
                {nodes, edges, selectedNodeId, onSuccess, onFailed});
              if(me.state.rememberOperationState){
                  me.setState({rememberOperation:3})
              }else{
                  me.setState({rememberOperation:0})
              }
            },
            noDecisionCallback: onInterrupted,
          },me.rememberOperationCallback);

        } else {
          PB.emit('aiConsole', 'message.remove', {key: messageKey});
          PB.emit('relation', 'sub_graph.do_save',
            {nodes, edges, selectedNodeId, onSuccess, onFailed});
        }
      }).catch(({code, msg}) => {
        console.warn(`测试添加失败，value: ${value}，code: ${code}, msg: ${msg}`);
        PB.emit('aiConsole', 'message.update', {key: messageKey, content: `添加操作失败`});
        showErrorMessage({code, msg, extra: {viewId: me.state.viewId, isModification: true}});
        onFailed && onFailed({code, msg});
      });
    });
    PB.sub(this, 'relation', 'sub_graph.do_save', ({nodes, edges, selectedNodeId, onSuccess, onFailed}) => {
      let me = this, messageKey, content;

      PB.emit('aiConsole', 'message.push', {
        type: 'ai',
        content: (
          <span>
            <Icon name={'loading'} style={{marginRight: '0.5em'}}/>
            正在添加...
          </span>
        ),
        callback: ({key}) => messageKey = key,
        delay: 200,
      });
      me.props.networkRef.saveRelationGraph(
        nodes, edges, selectedNodeId ? selectedNodeId : false, false, 'user_add_node'
      ).then(relation => {
        if (relation.nodes.length === 0 && relation.edges.length === 0) {
          PB.emit('aiConsole', 'message.update', {key: messageKey, content: `您输入的所有关注点都已在图谱中`});
        } else {
          content = '成功新增';
          if (relation.nodes.length > 0 && relation.edges.length > 0) {
            content = `${content} ${relation['nodes'].length} 个节点，${relation['edges'].length} 条边`
          } else if (relation.nodes.length > 0) {
            content = `${content} ${relation['nodes'].length} 个节点`
          } else {
            content = `${content} ${relation['edges'].length} 条边`
          }
          PB.emit('aiConsole', 'message.update', {
            key: messageKey,
            content: (
              <div>
                <div>
                  {content}
                  <a
                    style={{marginLeft: '0.5em'}}
                    className={'plain-action'}
                    onClick={
                      me.undoAddRelationFnGenerator(messageKey, content, relation)
                    }
                  >撤销</a>
                </div>
              </div>
            ),
          });
        }
        onSuccess && onSuccess({nodes, edges, selectedNodeId, relation});
      }).catch(({code, msg}) => {
        console.warn(`添加失败，code: ${code}, msg: ${msg}`);
        PB.emit('aiConsole', 'message.update', {key: messageKey, content: `添加操作失败`});
        showErrorMessage({code, msg, extra: {viewId: me.state.viewId, isModification: true}});
        onFailed && onFailed({code, msg});
      });
    });
    // 添加新节点
    PB.sub(this, 'network', 'addNode', ({value, autoParse = 'complex', successCB, failedCB, interruptedCB, bulk, addNodeList}) => {
      let me = this;
      let currentNodeId = this.currentNodeId;
      let userId = parseInt(localStorage.getItem('userId'));
      me.setState({activatedNodeId: undefined});

      let focusNodes = nodeIds => {
        let singleNodeAdded = false;
        nodeIds.forEach(nodeId => me.delayedFocusNodeIdMap[nodeId] = true);
        let addedNodeIds = Object.keys(me.delayedFocusNodeIdMap);
        if (addedNodeIds.length <= 0) return;
        if (addedNodeIds.length === 1) singleNodeAdded = addedNodeIds[0];
        if (currentNodeId) me.delayedFocusNodeIdMap[currentNodeId] = true;
        if (Object.keys(me.delayedFocusNodeIdMap).length > 0) {
          me.delayedFocusFnTimeout = setTimeout(() => {
            me.delayedFocusFnTimeout = undefined;
            me.myNetwork.network.stopSimulation();
            me.myNetwork.focus(Object.keys(me.delayedFocusNodeIdMap), {locked: !!singleNodeAdded}, () => {
              // PB.emit('network', 'selectOneNode', relation['nodes'][0]);
            });
            me.delayedFocusNodeIdMap = {};
          }, (currentNodeId || nodeIds.length > 1) ? 1500 : 100);
        }
      };

      if (me.delayedFocusFnTimeout) {
        clearTimeout(me.delayedFocusFnTimeout);
      }

      let addNodeFn, currentNode = undefined;
      if (currentNodeId) {
        currentNode = me.props.networkRef.getNode(currentNodeId);
      }

      let commonAddNodeFn = (nodes, edges) => {
        PB.emit('relation', 'sub_graph.do_test_and_save', {
          nodes,
          edges,
          selectedNodeId: currentNodeId,
          onSuccess: ({relation}) => {
            let nodeIds = relation['existed_node_ids'].concat(relation['nodes'].map(node => node.id));
            if (!bulk && relation.nodes.length === 1) {
              me.setState({activatedNodeId: relation.nodes[0].id});
            }
            focusNodes(nodeIds);
            successCB && successCB(relation);
          },
          onFailed: ({code, msg}) => {
            focusNodes([]);
            failedCB && failedCB({code, msg});
          },
          onInterrupted: interruptedCB,
        });
      };

      switch (autoParse) {
        case 'link':
          addNodeFn = () => {
            let textList = value.split(' ');
            commonAddNodeFn(
              textList.map(text => ({
                fname: text,
                label: text,
                [TYPE_FIELD_NAME]: NODE_TYPE_TEXT,
                owner: 0,
                userId,
                userConfirmed: true,
              })),
              textList.map((text, idx) => (idx === 0 ? (
                currentNode ? {
                  from: currentNodeId,
                  toIndex: idx,
                  userConfirmed: true,
                  meta: {
                    text2text: currentNode[TYPE_FIELD_NAME] === NODE_TYPE_TEXT,
                  },
                } : undefined
              ) : {
                fromIndex: idx - 1,
                toIndex: idx,
                userConfirmed: true,
                meta: {
                  text2text: true,
                },
              })).filter(e => !!e)
            );
          };
          break;
        case 'star':
          addNodeFn = () => {
            let textList = value.split(' ');
            commonAddNodeFn(
              textList.map(text => ({
                fname: text,
                label: text,
                [TYPE_FIELD_NAME]: NODE_TYPE_TEXT,
                owner: 0,
                userId,
                userConfirmed: true,
              })),
              currentNode ? textList.map((text, idx) => ({
                from: currentNodeId,
                toIndex: idx,
                userConfirmed: true,
                meta: {
                  text2text: currentNode[TYPE_FIELD_NAME] === NODE_TYPE_TEXT,
                },
              })) : []
            );
          };
          break;
        case 'single':
          addNodeFn = () => {
            PB.emit('relation', 'sub_graph.do_save', {
              nodes: [{
                fname: value,
                label: value,
                [TYPE_FIELD_NAME]: NODE_TYPE_TEXT,
                owner: 0,
                userId,
                userConfirmed: true,
                forceAdd: true,
              }],
              edges: currentNode ? [{
                from: currentNodeId,
                toIndex: 0,
                userConfirmed: true,
                meta: {
                  text2text: currentNode[TYPE_FIELD_NAME] === NODE_TYPE_TEXT,
                },
              }] : [],
              selectedNodeId: currentNodeId,
              onSuccess: ({relation}) => {
                let nodeIds = relation['existed_node_ids'].concat(relation['nodes'].map(node => node.id));
                if (!bulk && relation.nodes.length === 1) {
                  me.setState({activatedNodeId: relation.nodes[0].id});
                }
                focusNodes(nodeIds);
                successCB && successCB(relation);
              },
              onFailed: ({code, msg}) => {
                focusNodes([]);
                failedCB && failedCB({code, msg});
              },
            });
          };
          break;
        case 'expand-star':
          addNodeFn = () => {
            commonAddNodeFn(addNodeList,
              currentNode ? addNodeList.map((text, idx) => ({
                from: currentNodeId,
                toIndex: idx,
                userConfirmed: true,
                meta: {
                  text2text: currentNode[TYPE_FIELD_NAME] === NODE_TYPE_TEXT,
                },
              })) : []
            );
          };
          break;
        default: // complex
          addNodeFn = () => {
            PB.emit('relation', 'sub_graph.do_parse', {
              value,
              selectedNodeId: currentNodeId,
              onSuccess: ({parsedRelation}) => {
                commonAddNodeFn(parsedRelation.nodes, parsedRelation.edges);
              },
              onFailed: ({code, msg}) => {
                focusNodes([]);
                failedCB && failedCB({code, msg});
              },
            });
          };
      }

      // 是否有选中的节点
      if (currentNode) {
        if (currentNode.status === 0) {
          Modal.confirm({
            title: (
              <span>
                <span>无法将新节点关联至未保留的节点</span>
                <span className={style["node-in-msg"]}>{getNodeDisplayTitle(currentNode)}</span>
                <span>，是否立即保留并添加？</span>
              </span>
            ),
            okText: '保留并添加相连节点',
            cancelText: '取消',
            onOk: () => {
              me.props.networkRef.saveNodeWithRelations(currentNodeId).then(() => {
                addNodeFn();
              });
            },
            onCancel: () => failedCB && failedCB({code: 400, msg: '用户取消操作'}),
          });
        } else {
          addNodeFn();
        }
      } else {
        addNodeFn();
      }
    });
    // 聚焦到节点
    PB.sub(this, 'network', 'focus', (nodes) => {
      /**/
      console.log("PB[network][focus] beginning：nodes", nodes);
      if (_.isArray(nodes)) {
        setTimeout(() => {
          // this.myNetwork.network.stopSimulation();
          this.myNetwork.focus(nodes.map(node => node.id));
        }, 100);
      } else {
        setTimeout(() => {
          // this.myNetwork.network.stopSimulation();
          this.myNetwork.focus(nodes.id);
        }, 100);
      }
    });
    PB.sub(this, 'network', 'node_tooltip.hide', () => {
      if (this.state.showNodeTooltip) {
        this.setState({showNodeTooltip: false});
        this.processingNodeTooltip = undefined;
      }
    });
    PB.sub(this, 'network', 'node_tooltip.show', node => {
      PB.emit('network', 'node_tooltip.hide');
      this.processingNodeTooltip = node.id;
      this.myNetwork.focus(node.id, null, () => {
        let nodeTooltipInfo = getNodeTooltipContent(this.props.networkRef, node.id);
        if (nodeTooltipInfo.success && this.processingNodeTooltip === node.id) {
          // 显示节点Tooltip
          let style = configStyle[node['lev']] || configStyle['_default'];
          nodeTooltipInfo = {...nodeTooltipInfo, style};
          this.setState({showNodeTooltip: true, nodeTooltipInfo});
        }
      });
    });

    PB.sub(me, 'aiConsole', 'status.changed', ({status}) => {
      // AI对话框状态改变
      me.setState({aiConsoleStatus: status});
    });

    const hideNodeTooltipFn = () => {
      PB.emit('network', 'node_tooltip.hide');
    };
    this.myNetwork.subscribe(this, 'NETWORK.click', hideNodeTooltipFn);
    this.myNetwork.subscribe(this, 'NETWORK.dragStart', hideNodeTooltipFn);
    this.myNetwork.subscribe(this, 'NETWORK.zoom', hideNodeTooltipFn);

    // 基于适当策略修改待连接节点
    PB.sub(this, 'network', 'changeNodeToConnect', node => {
      console.log('changeNodeToConnect, current selected node: ', node);
      // 历史记录中上一次选中的节点
      const lastNodeToConnect = this.idOfNodeToConnect ? this.data.nodes.get(this.idOfNodeToConnect) : undefined;

      // 设置当前潜在的待连接节点：
      // 1.当上连续两次点击不同节点时取上一次操作选中的节点
      // 2.当上一次操作中点击的是节点且原待连接节点不存在时取上一次操作中点击的节点
      // 3.其他情况下取原待连接节点
      const currentNodeToConnect = (!!this.currentNodeId && !!node && this.currentNodeId !== node.id) ?
        this.data.nodes.get(this.currentNodeId) :
        ((!!this.currentNodeId && !lastNodeToConnect) ? this.data.nodes.get(this.currentNodeId) : lastNodeToConnect);

      // 是否替换待连接节点
      const changeNodeToConnect = !!currentNodeToConnect &&
        (!lastNodeToConnect || lastNodeToConnect.id !== currentNodeToConnect.id) &&
        this.data.nodes.get(currentNodeToConnect.id);

      if (changeNodeToConnect) {
        // 设置待连接节点的颜色
        changeNodeToConnect.becomeLastNode();
        this.data.nodes.update(changeNodeToConnect);
        this.idOfNodeToConnect = currentNodeToConnect.id;

        if (lastNodeToConnect && this.data.nodes.get(lastNodeToConnect.id)) {
          lastNodeToConnect.becomeCommonNode();
          this.data.nodes.update(lastNodeToConnect);
        }
      }
    });
    // 模拟选择、点击节点
    PB.sub(this, 'network', 'selectOneNode', (node) => {
      if (!this.data.nodes.get(node.id)) return;
      PB.emit('network', 'changeNodeToConnect', node);
      const [current] = this.selectedNodeHistory;
      this.currentNodeId = node.id;
      PB.emit('relation', 'node.single_selection_change', this.currentNodeId);
      let currentNode = this.data.nodes.get(node.id);

      // 修改当前节点样式
      currentNode.becomeCurrentNode();
      this.data.nodes.update(currentNode);

      // 改变画面样式，高亮选中节点和连接的节点
      let result = myVis.highlightNode(this.network, this.data, node.id);

      if (!current || !current.id || node.id !== current.id) {
        // 这次点击和上次点击的不是同一个节点
        if (current && current.id && this.data.nodes.get(current.id)) {
          this.selectedNodeHistory.unshift(currentNode);
          if (this.selectedNodeHistory.length > selectedNodeHistorySize) {
            this.selectedNodeHistory.length = selectedNodeHistorySize;
          }
        } else {
          this.selectedNodeHistory[0] = currentNode;
        }
      }

      // 连接的节点数组(非id数组)
      let connectedNodes = this.data.nodes.get(result.connectedNodesIdArr);

      console.log('被点击的当前节点', result.node);
      // 观察者：触发 信息板 节点点击
      PB.emit("network", "selectedNode", {node: result.node, connectedNodes: connectedNodes})
    });
    // network点击 上下文按钮
    PB.sub(this, 'network', 'ActionContext', ({status/*,pos,node,edge*/}) => {
      this.setState({showMask: status ? 'block' : 'none'})
    });

    /*  可视化操作工具   */
    PB.sub(this, 'network', 'zoomUp', () => {
      myVis.zoom(this.network, 'up')
    });
    PB.sub(this, 'network', 'zoomDown', () => {
      myVis.zoom(this.network, 'down')
    });
    PB.sub(this, 'network', 'stop', () => {//alert('停止运动')
      this.network.stopSimulation()
    });
    PB.sub(this, 'network', 'start', () => {
      this.network.startSimulation()
    });
    PB.sub(this, 'network', 'springLength', (value) => {
      // tip: 注意不同的布局模式
      this.network.setOptions({
        physics: {
          forceAtlas2Based: {
            springLength: value,
          },
          barnesHut: {
            springLength: value,
          },
        },
      })
    });
    PB.sub(this, "network", 'changePhysics', (value) => {
      this.network.setOptions({
        'layout': {
          'hierarchical': {
            'enabled': false,
          },
        },
        'physics': {
          'solver': value,
        },
      })
    });
    PB.sub(this, "network", 'changeLayout', (direction) => {
      this.network.setOptions({
        'layout': {
          improvedLayout: true,
          'hierarchical': {
            'enabled': true,
            'direction': direction,
          },
        },
      })
    });
    PB.sub(this, "network", 'fit', () => {
      this.network.fit({animation: true})
    });
    PB.sub(this, 'network', 'graph.get', () => {
      if (this.state.dataLoadingStatus === NetworkDataLoadingStatus.SUCCESS) {
        const data = me.props.networkRef.getData();
        PB.emit('network', 'graph.after_loaded',
          {nodes: data.data.nodes.get(), edges: data.data.edges.get()});
      }
    });
    PB.sub(this, 'relation', 'data.get', () => {
      if (this.state.dataLoadingStatus === NetworkDataLoadingStatus.SUCCESS) {
        const {data} = me.props.networkRef.getData();
        PB.emit('network', 'graph.after_loaded',
          {nodes: data.data.nodes.get(), edges: data.data.edges.get()});
      }
    });
    PB.sub(this, 'relation', 'selection.on_remove', () => {
      if (this.currentSelection.nodes.length > 0 && this.currentSelection.nodes[0]) {
        PB.emit('network', 'node.on_remove', {node: this.currentSelection.nodes[0]});
      } else if (this.currentSelection.edges.length > 0 && this.currentSelection.edges[0]) {
        PB.emit('network', 'edge.on_remove', {edge: this.currentSelection.edges[0]});
      }
    });
    PB.sub(this, 'relation', 'node.on_remove', nodeIds => {
      PB.emit('network', 'node.on_remove', {nodeIds: nodeIds});
    });
    PB.sub(this, 'relation', 'gravity_node_list.do_load', ({sourceNodeId}) => {
      me.props.networkRef.loadGravityNodeList(sourceNodeId).then(nodeList => {
        PB.emit('relation', 'gravity_node_list.loaded', {sourceNodeId, nodeList});
      }).catch(() => {
      });
    });
    PB.sub(this, "network", "selectedNode", ({node,connectedNodes}) => {
      if (node) {
        // 用户选中节点
        // 添加用户操作提示
        PB.emit('aiConsole', 'message.push',
          {type: 'user', content: `查看节点 "${getNodeDisplayTitle(node, 5)}" 的详细信息`});
        let getNodeAction = node => ([(
          <Tooltip title={`定位到节点 "${getNodeDisplayTitle(node, 12)}"`} key={'locate'}>
            <Button
              shape={'circle'}
              className={'first ant-btn-icon ant-btn-icon-only'}
              onClick={e => {
                e.preventDefault();
                PB.emit('network', 'focus', node);
              }}
            >
              <Icon name={'icon-location'} type={IconTypes.ICON_FONT}/>
            </Button>
          </Tooltip>
        ),
        (
          <Tooltip title={`子弹评估`} key={'assess'}>
            <Button
              shape={'circle'}
              className={'first ant-btn-icon ant-btn-icon-only'}
              onClick={e => {
                e.preventDefault();
                PB.emit("node_assess", "modal.show", {
                  action: "create",
                  viewId: me.state.viewId,
                  node,
                  loadList: true,
                });
              }}
            >
              {/* <Icon name={'icon-file1'} type={IconTypes.ICON_FONT}/> */}
              <img src="/assets/icons/assess.png" style={{width: '1em',height: '1em'}} />
            </Button>
          </Tooltip>
        ),
        (
          node.userConfirmed ? (
            <Tooltip title={`给TA点赞`} key={'reward'}>
              <Button
                shape={'circle'}
                className={'ant-btn-icon ant-btn-icon-only'}
                onClick={e => {
                  e.preventDefault();
                  PB.emit('relation', 'member.badge.on_set', {node});
                }}
              >
                <Icon name={'icon-good'} type={IconTypes.ICON_FONT}/>
              </Button>
            </Tooltip>
          ) : (
            <Tooltip title={`请先添加节点到图谱，再给TA点赞吧`} key={'disabled-reward'}>
              <Button
                shape={'circle'}
                className={'ant-btn-icon ant-btn-icon-only'}
                disabled={true}
              >
                <Icon name={'icon-good'} type={IconTypes.ICON_FONT}/>
              </Button>
            </Tooltip>
          )
        ), (
          node.userConfirmed && me.props.userInfo.userId === node.userId ? (
            <Tooltip title={`将节点设为待办任务`} key={'todo'}>
              <Button
                shape={'circle'}
                className={'ant-btn-icon ant-btn-icon-only'}
                onClick={e => {
                  e.preventDefault();
                  PB.emit('node_todo', 'detailModal.do_show', {
                    action: 'create',
                    task: {
                      viewId: me.props.viewId,
                      nodeId: node.id,
                      vrDisplayText: getNodeDisplayTitle(node),
                    },
                    userInfo: me.props.userInfo,
                  });
                }}
              >
                <Icon name={'icon-todo-list'} type={IconTypes.ICON_FONT}/>
              </Button>
            </Tooltip>
          ) : (
            <Tooltip title={`他人创建的节点，不可设为待办任务`} key={'disabled-todo'}>
              <Button
                shape={'circle'}
                className={'ant-btn-icon ant-btn-icon-only'}
                disabled={true}
              >
                <Icon name={'icon-todo-list'} type={IconTypes.ICON_FONT}/>
              </Button>
            </Tooltip>
          )
        ), (
          node.userConfirmed ? (
            <Tooltip title={`推荐填充节点 "${getNodeDisplayTitle(node, 12)}"`} key={'override'}>
              <Button
                shape={'circle'}
                className={'ant-btn-icon ant-btn-icon-only'}
                onClick={e => {
                  e.preventDefault();
                  PB.emit('node', 'explore.aiConsole.override.do_load', {node});
                }}
              >
                <Icon name={'icon-override-node'} type={IconTypes.ICON_FONT}/>
              </Button>
            </Tooltip>
          ) : (
            <Tooltip title={`请先添加节点到图谱，再使用推荐填充吧`} key={'disabled-override'}>
              <Button
                shape={'circle'}
                className={'ant-btn-icon ant-btn-icon-only'}
                disabled={true}
              >
                <Icon name={'icon-override-node'} type={IconTypes.ICON_FONT}/>
              </Button>
            </Tooltip>
          )
        ), (
          <Tooltip
            overlayClassName={style['search-engine-list']}
            title={
              <div>
                <div
                  onClick={e => {
                    e.preventDefault();
                    me.copyNode(1,node,connectedNodes);
                  }}
                  className={style['search-engine-item']}
                >复制节点
                </div>
                <div
                  onClick={e => {
                    e.preventDefault();
                    me.copyNode(2,node,connectedNodes);
                  }}
                  className={style['search-engine-item']}
                >复制子图
                </div>
              </div>
            }
            key={'search_online'}>
            <Button
              shape={'circle'}
              className={'first ant-btn-icon ant-btn-icon-only'}
            >
              <Icon name={'copy'}/>
            </Button>
          </Tooltip>
        ), (
          <Tooltip
            overlayClassName={style['search-engine-list']}
            title={
              <div>
                <div
                  onClick={e => {
                    e.preventDefault();
                    me.currentSearchEngine = SysConfig.sysSearchEngine;
                    window.open(`${SysConfig.sysSearchEngine}${getNodeDisplayTitle(node)}`, '_blank');
                  }}
                  className={style['search-engine-item']}
                >百度搜索
                </div>
                <div
                  onClick={e => {
                    e.preventDefault();
                    me.currentSearchEngine = SysConfig.sysSearchEngineBySogou;
                    window.open(`${SysConfig.sysSearchEngineBySogou}${getNodeDisplayTitle(node)}`, '_blank');
                  }}
                  className={style['search-engine-item']}
                >搜狗搜索
                </div>
                <div
                  onClick={e => {
                    e.preventDefault();
                    me.currentSearchEngine = SysConfig.sysSearchEngineByBing;
                    window.open(`${SysConfig.sysSearchEngineByBing}${getNodeDisplayTitle(node)}`, '_blank');
                  }}
                  className={style['search-engine-item']}
                >必应搜索
                </div>
                <div
                  onClick={e => {
                    e.preventDefault();
                    me.currentSearchEngine = SysConfig.sysSearchEngineBySo;
                    window.open(`${SysConfig.sysSearchEngineBySo}${getNodeDisplayTitle(node)}`, '_blank');
                  }}
                  className={style['search-engine-item']}
                >360搜索
                </div>
              </div>
            }
            key={'search_online'}>
            <Button
              shape={'circle'}
              style={{float: 'right'}}
              className={'last ant-btn-icon ant-btn-icon-only'}
              onClick={e => {
                e.preventDefault();
                window.open(`${me.currentSearchEngine}${getNodeDisplayTitle(node)}`, '_blank');
              }}
            >
              <Icon name={'icon-earth'} type={IconTypes.ICON_FONT}/>
            </Button>
          </Tooltip>
        ), (
          /*<Tooltip
            overlayClassName={style['search-engine-list']}
            title={
              <div>事件关联图谱</div>
            }
            key={'search_online'}>
            <Button
              shape={'circle'}
              style={{float: 'right'}}
              className={'last ant-btn-icon ant-btn-icon-only'}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                me.eventMap({node_id:node.id,view_id:me.props.viewId})
              }}
            >
              <Icon name={'thunderbolt'}/>
            </Button>
          </Tooltip>*/
          intl.get('locale')==='zh-cn' ?( <Tooltip
          overlayClassName={style['search-engine-list']}
          title={
            <div>{intl.get('Custom.general.dynamicInformationBase')}</div>
          }
          key={'search_online'}>
          <Button
            shape={'circle'}
            style={{float: 'right'}}
            className={'last ant-btn-icon ant-btn-icon-only'}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              PB.emit('searchinput', 'ety.list.show', {keyword: node.fname});
            }}
          >
            <Icon name="icon-search" theme="outlined" type={IconTypes.ICON_FONT}/>
          </Button>
        </Tooltip>):null
        )]);
        // 添加加载节点详情提示
        PB.emit('aiConsole', 'message.push', {
          type: 'ai',
          content: (
            <MainAiConsoleMessageCommonNodeInfo
              bus={PB}
              node={node}
              originalViewId={me.props.viewId}
              recommendation={[false]}
              viewDataProvider={me.props.networkRef}
            />
          ),
          actions: getNodeAction(node),
          callback: ({key}) => {
            if (me.detailMessageKeys[node.id]) {
              // 清理上一次显示的信息
              PB.emit('aiConsole', 'message.update', {
                key: me.detailMessageKeys[node.id],
                content: (
                  <span>
                    <a
                      onClick={() => PB.emit('aiConsole', 'message.notice',
                        {key: me.detailMessageKeys[node.id] || key})}
                    >
                      点击查看节点最新信息
                    </a>
                  </span>
                ),
              });
            }
            me.detailMessageKeys[node.id] = key;
          },
        });
        let loadingStatus = {
          detailLoaded: false,
          detailNode: undefined,
          recommendation: [false],
        };
        // TODO 用于节点ID替换后，保证还出现在原位置，需加判断
        /*let {x, y} = me.myNetwork.network.getPositions(node.id)[node.id];
          node.fx = x;
          node.fy = y;
          node._initialized = false;
          me.myNetwork.graph.nodes.update(node);*/
        // 加载节点详情
        me.props.networkRef.loadNodeDetailInfo(node.id).then((nodeId) => {
          loadingStatus.detailLoaded = true;
          loadingStatus.detailNode = me.props.networkRef.getNode(nodeId);
          me.delayMarkAsInterested(nodeId);
          PB.emit('aiConsole', 'message.update', {
            key: me.detailMessageKeys[nodeId],
            content: (
              <MainAiConsoleMessageCommonNodeInfo
                bus={PB}
                node={loadingStatus.detailNode}
                originalViewId={me.props.viewId}
                recommendation={loadingStatus.recommendation}
                viewDataProvider={me.props.networkRef}
              />
            ),
            actions: getNodeAction(node),
          });
        }).catch(({code, msg}) => {
          showErrorMessage({code, msg, extra: {viewId: me.state.viewId, isModification: true}});
          PB.emit('console', 'info', '获取节点详细信息失败');
          PB.emit('aiConsole', 'message.update',
            {key: me.detailMessageKeys[node.id], content: `操作失败`});
        });
        me.props.networkRef.smartSearchUserInAllView(node.fname, node.id).then(users => {
          let recommendation = (users || []);
          me.setState({recommendation});
        }).catch(() => {
          me.setState({recommendation: []});
        });
        // 加载自动推荐（目前暂时选用线索联想功能提供数据）
        // overrideNextMessageForNodeDataProvider('static::loadRelatedClue', false);
        /*me.props.networkRef.smartSearchUserInAllView(node.fname, node.id).then(users => {
          loadingStatus.recommendation = (users || []).slice(0, 3);
          console.log(loadingStatus.recommendation);
          if (loadingStatus.detailLoaded) {
            PB.emit('aiConsole', 'message.patch', {
              key: me.detailMessageKeys[node.id],
              content: (
                <MainAiConsoleMessageCommonNodeInfo
                  bus={PB}
                  node={loadingStatus.detailNode}
                  originalViewId={me.props.viewId}
                  recommendation={loadingStatus.recommendation}
                  viewDataProvider={me.props.networkRef}
                />
              ),
            });
          }
        }).catch(() => {
          loadingStatus.recommendation = [];
          console.log(loadingStatus.recommendation);
          if (loadingStatus.detailLoaded) {
            PB.emit('aiConsole', 'message.patch', {
              key: me.detailMessageKeys[node.id],
              content: (
                <MainAiConsoleMessageCommonNodeInfo
                  bus={PB}
                  node={loadingStatus.detailNode}
                  originalViewId={me.props.viewId}
                  recommendation={loadingStatus.recommendation}
                  viewDataProvider={me.props.networkRef}
                />
              ),
            });
          }
        });*/
      }
    });
    PB.sub(this, "network", "selectedEdge", ({edge, connectedNodes}) => {
      if (connectedNodes && connectedNodes.length === 2) {
        // 用户选中关系
        // 添加用户操作提示
        PB.emit('aiConsole', 'message.push', {type: 'user', content: `查看关联关系`});
        // 添加关联关系信息展示
        PB.emit('aiConsole', 'message.push', {
          type: 'ai',
          content: (
            <MainAiConsoleMessageEdgeInfo
              bus={PB}
              fromNode={connectedNodes[0]}
              toNode={connectedNodes[1]}
            />
          ),
          actions: me.getActionsForAiConsoleMessageForEdgeInfo(edge, connectedNodes),
          callback: ({key}) => {
            if (me.detailMessageKeys[edge.id]) {
              // 清理上一次显示的信息
              PB.emit('aiConsole', 'message.update', {
                key: me.detailMessageKeys[edge.id],
                content: (
                  <span>
                    <a
                      onClick={() => PB.emit('aiConsole', 'message.notice',
                        {key: me.detailMessageKeys[edge.id] || key})}
                    >
                      点击查看关系最新信息
                    </a>
                  </span>
                ),
              });
            }
            me.detailMessageKeys[edge.id] = key;
          },
        });
      }
    });

    me.userActionBindingForNode();
    me.userActionBindingForEdge();

    PB.sub(me, 'network', 'light_node.do', ({status, fn}) => {
      me.lastLightNodeConfig = {status, fn};
      if (status !== 'none') {
        me.myNetwork.dark(() => true);
      }
      fn ? me.myNetwork.light(fn) : me.myNetwork.normalBrightness(() => true);
    });

    PB.sub(me, 'network', 'decorate_edge.do', ({status, fn}) => {
      if (status !== 'none') {
        me.myNetwork.decorateEdges(() => ({arrows: undefined}));
      }
      me.myNetwork.decorateEdges(fn);
      PB.emit('network', 'decorate_edge.done', {status});
    });

    PB.sub(me, 'view', 'view.info.update', ({viewId, viewInfo}) => {
      if (viewId === me.props.viewId) {
        me.setState({viewInfo: {...me.state.viewInfo, ...viewInfo}});
      }
    });

    PB.sub(me, 'view', 'background.do_load', ({viewId}) => {
      if (viewId === me.props.viewId) {
        let failedToLoadBackgroundFn = ({code, msg}) => {
          PB.emit('view', 'background.load_failed', {viewId, code, msg});
          showErrorMessage({code, msg, extra: {viewId: me.state.viewId, isModification: false}});
          console.log(`加载图谱底图失败，code: ${code}, msg: ${msg}`);
        };
        let doLoadBackgroundData = () => {
          me.props.networkRef.loadConfig(`$.viewData.background`).then(config => {
            let dataUrl = undefined;
            if (config && config.hasData) {
              if (config.customKey) {
                dataUrl = viewBackground[config.customKey];
              } else if (config.dataUrl) {
                dataUrl = config.dataUrl;
              }
            }
            // 更新缓存
            if (me.viewDataCacheStore && config) {
              me.viewDataCacheStore.put(JSON.stringify(config), `cache:view:${viewId}:background`);
            }
            PB.emit('view', 'background.loaded', {viewId, dataUrl});
          }).catch(failedToLoadBackgroundFn);
        };
        // 缓存数据存在，判断是否需要从服务器获取数据
        if (me.viewDataCacheStore) {
          // 获取背景随机数标识，判断背景图是否更改
          me.props.networkRef.loadConfig(`$.viewData.background.random`).then(random => {
            me.viewDataCacheStore.get(`cache:view:${viewId}:background`).then(dataStr => {
              if (dataStr) {
                let backgroundData = JSON.parse(dataStr);
                if (backgroundData) {
                  if ((random && backgroundData.random === `${random}`) || (!random && !backgroundData.random)) {
                    // 使用缓存
                    let dataUrl = undefined;
                    if (backgroundData.hasData) {
                      if (backgroundData.customKey) {
                        dataUrl = viewBackground[backgroundData.customKey];
                      } else if (backgroundData.dataUrl) {
                        dataUrl = backgroundData.dataUrl;
                      }
                    }
                    PB.emit('view', 'background.loaded', {viewId, dataUrl});
                    return;
                  }
                }
              }
              // 正常加载
              doLoadBackgroundData();
            }).catch(doLoadBackgroundData);
          }).catch(failedToLoadBackgroundFn);
        }
      }
    });

    PB.sub(me, 'view', 'background.do_upload', ({viewId, dataUrl, customKey = ''}) => {
      if (viewId === me.props.viewId) {
        let messageKey;
        PB.emit('aiConsole', 'message.push', {type: 'user', content: `设置底图`});
        PB.emit('aiConsole', 'message.push', {
          type: 'ai',
          content: (<span><Icon name={'loading'} style={{marginRight: '0.5em'}}/>请稍后...</span>),
          callback: ({key}) => messageKey = key,
          delay: 200,
        });
        let config = {
          hasData: true,
          dataUrl: dataUrl,
          random: `${Math.random()}-${(dataUrl || customKey).slice(-10)}`,
          customKey,
        };
        me.props.networkRef.updateConfig(`$.viewData.background`, config).then(() => {
          PB.emit('aiConsole', 'message.update', {key: messageKey, content: `操作成功`});
          let dataUrlValue = dataUrl;
          if (customKey) {
            dataUrlValue = viewBackground[customKey];
          }
          PB.emit('view', 'background.uploaded', {viewId, dataUrl: dataUrlValue});
          if (me.viewDataCacheStore) {
            me.viewDataCacheStore.put(JSON.stringify(config), `cache:view:${viewId}:background`);
          }
        }).catch(({code, msg}) => {
          PB.emit('aiConsole', 'message.update', {key: messageKey, content: `操作失败`});
          PB.emit('view', 'background.upload_failed', {viewId, code, msg});
          showErrorMessage({code, msg, extra: {viewId: me.state.viewId, isModification: true}});
          console.log(`设置图谱底图失败，code: ${code}, msg: ${msg}`);
        });
      }
    });

    // 设置预置的图片
    PB.sub(me, 'view', 'background.on_custom', ({viewId, key}) => {
      if (viewId === me.props.viewId) {
        PB.emit('view', 'background.do_upload',
          ({viewId: me.props.viewId, dataUrl: null, customKey: key}));
      }
    });

    PB.sub(me, 'view', 'background.on_clear', ({viewId}) => {
      if (viewId === me.props.viewId) {
        if (me.backgroundImageConfig.dataUrl) {
          Modal.confirm({
            title: "操作确认",
            content: '确认清除底图图片吗？',
            okText: '确认清除',
            cancelText: '取消',
            okButtonProps: {
              type: 'danger',
            },
            onOk: () => {
              PB.emit('view', 'background.do_clear', ({viewId}));
            },
          });
        } else {
          Modal.info({
            content: '当前图谱没有设置底图',
            okText: '知道了',
          });
        }
      }
    });

    PB.sub(me, 'view', 'background.do_clear', ({viewId}) => {
      if (viewId === me.props.viewId) {
        let messageKey;
        PB.emit('aiConsole', 'message.push', {type: 'user', content: `清除底图`});
        PB.emit('aiConsole', 'message.push', {
          type: 'ai',
          content: (<span><Icon name={'loading'} style={{marginRight: '0.5em'}}/>请稍后...</span>),
          callback: ({key}) => messageKey = key,
          delay: 200,
        });
        let config = {
          hasData: false,
          random: `${Math.random()}-$empty`,
        };
        me.props.networkRef.updateConfig(`$.viewData.background`, config).then(() => {
          PB.emit('aiConsole', 'message.update', {key: messageKey, content: `操作成功`});
          PB.emit('view', 'background.cleared', {viewId});
          if (me.viewDataCacheStore) {
            me.viewDataCacheStore.put(JSON.stringify(config), `cache:view:${viewId}:background`);
          }
        }).catch(({code, msg}) => {
          PB.emit('aiConsole', 'message.update', {key: messageKey, content: `操作失败`});
          PB.emit('view', 'background.clear_failed', {viewId, code, msg});
          showErrorMessage({code, msg, extra: {viewId: me.state.viewId, isModification: true}});
          console.log(`设置图谱底图失败，code: ${code}, msg: ${msg}`);
        });
      }
    });

    PB.sub(me, 'view', 'background.cache.do_clear', ({viewId}) => {
      if (viewId === me.props.viewId) {
        let config = {
          hasData: false,
          random: `${Math.random()}-$empty`,
        };
        if (me.viewDataCacheStore) {
          me.viewDataCacheStore.put(JSON.stringify(config), `cache:view:${viewId}:background`);
        }
      }
    });

    PB.sub(me, 'view', 'background.loaded', ({viewId, dataUrl}) => {
      let i;
      if (viewId === me.props.viewId) {
        if (me.backgroundImageConfig.dataUrl !== dataUrl) {
          me.backgroundImageConfig.dataUrl = dataUrl;
          if (dataUrl) {
            me.backgroundImageConfig.imageLoaded = false;
            me.backgroundImageConfig.image = new Image();
            me.backgroundImageConfig.image.onload = () => {
              me.backgroundImageConfig.imageLoaded = true;
              if (me.backgroundCache.initialized) {
                for (i = 0; i <= NUM_ITERATIONS; i++) {
                  delete me.backgroundCache[`canvas${i}`];
                }
                me.backgroundCache.initialized = false;
              }
              me.myNetwork.network.redraw();
            };
            me.backgroundImageConfig.image.src = dataUrl;
          } else {
            me.backgroundImageConfig.imageLoaded = true;
            me.backgroundImageConfig.image = undefined;
            if (me.backgroundCache.initialized) {
              for (i = 0; i <= NUM_ITERATIONS; i++) {
                delete me.backgroundCache[`canvas${i}`];
              }
              me.backgroundCache.initialized = false;
            }
            me.myNetwork.network.redraw();
          }
        }
      }
    });

    PB.sub(me, 'view', ['background.uploaded', 'background.cleared'], ({viewId, dataUrl}) => {
      if (viewId === me.props.viewId) {
        PB.emit('view', 'background.loaded', ({viewId, dataUrl}));
        let internal = setInterval(() => {
          if (me.backgroundImageConfig.imageLoaded) {
            clearInterval(internal);
            if (me.backgroundImageConfig.image) {
              let heightScale = me.container.clientHeight * 0.8 / me.backgroundImageConfig.image.height;
              let widthScale = me.container.clientWidth * 0.9 / me.backgroundImageConfig.image.width;
              let scale = Math.min(heightScale, widthScale);
              let currentScale = me.myNetwork.network.getScale();
              let targetScale = Math.max(Math.min(currentScale, scale), scale / 2);
              me.myNetwork.network.moveTo({
                scale: targetScale,
                position: {x: 0, y: 0},
                animation: true,
              });
            }
          }
        }, 100);
      }
    });

    PB.sub(me, 'view', 'banner.do_load', ({viewId}) => {
      if (viewId === me.props.viewId) {
        let failedToLoadBannerFn = ({code, msg}) => {
          PB.emit('view', 'banner.load_failed', {viewId, code, msg});
          showErrorMessage({code, msg, extra: {viewId: me.state.viewId, isModification: false}});
          console.log(`加载图谱头图失败，code: ${code}, msg: ${msg}`);
        };
        let doLoadBannerData = () => {
          me.props.networkRef.loadConfig(`$.viewData.banner`).then(config => {
            let dataUrl = undefined;
            if (config && config.hasData) {
              if (config.dataUrl) {
                dataUrl = config.dataUrl;
              }
            }
            // 更新缓存
            if (me.viewDataCacheStore && config) {
              me.viewDataCacheStore.put(JSON.stringify(config), `cache:view:${viewId}:banner`);
            }
            PB.emit('view', 'banner.loaded', {viewId, dataUrl});
          }).catch(failedToLoadBannerFn);
        };
        // --
        // 缓存数据存在，判断是否需要从服务器获取数据
        if (me.viewDataCacheStore) {
          // 获取标题图片随机数标识，判断标题图片是否更改
          me.props.networkRef.loadConfig(`$.viewData.banner.random`).then(random => {
            me.viewDataCacheStore.get(`cache:view:${viewId}:banner`).then(dataStr => {
              if (dataStr) {
                let bannerData = JSON.parse(dataStr);
                if (bannerData) {
                  if ((random && bannerData.random === `${random}`) || (!random && !bannerData.random)) {
                    // 使用缓存
                    let dataUrl = undefined;
                    if (bannerData.hasData) {
                      if (bannerData.dataUrl) {
                        dataUrl = bannerData.dataUrl;
                      }
                    }
                    PB.emit('view', 'banner.loaded', {viewId, dataUrl});
                    return;
                  }
                }
              }
              // 正常加载
              doLoadBannerData();
            }).catch(doLoadBannerData);
          }).catch(failedToLoadBannerFn);
        }
      }
    });

    PB.sub(me, 'view', 'banner.loaded', ({viewId, dataUrl}) => {
      if (viewId === me.props.viewId) {
        if (me.bannerImageConfig.dataUrl !== dataUrl) {
          me.bannerImageConfig.dataUrl = dataUrl;
          me.bannerImageConfig.imageLoaded = true;
        }
        // me.bannerImageConfig.show = localStorage.getItem('show_view_title_banner') === '1';
        me.bannerImageConfig.show = !!dataUrl;
        me.setState({refresh: !me.state.refresh});
      }
    });

    // PB.sub(me, 'view', ['banner.uploaded', 'banner.cleared'], ({viewId, dataUrl}) => {
    //     if (viewId === me.props.viewId) {
    //         PB.emit('view', 'banner.loaded', ({viewId, dataUrl}));
    //     }
    // });
    // --

    PB.sub(me, 'view', 'rank.show_modal', ({viewId, type}) => {
      if (viewId === me.props.viewId) {
        PB.emit('aiConsole', 'message.push', {
          type: 'user',
          content: `获取图谱${rankNameMap[type]}`,
        });
        me.setState({showRankModal: true, rankType: type});
      }
    });

    PB.sub(me, 'view', 'explore.show_custom_request_modal', ({viewId, type}) => {
      if (viewId === me.props.viewId) {
        PB.emit('aiConsole', 'message.push', {
          type: 'user',
          content: `获取图谱定制发现`,
        });
        me.setState({showExploreCustomRequestModal: true});
      }
    });

    PB.sub(me, 'view', 'person.multiRelationship.show_modal', ({viewId}) => {
      if (viewId === me.props.viewId) {
        me.setState({showMultiRelationShipModal: true})
      }
    });

    PB.sub(me, 'view', 'find.smartSearchUser.show_modal', ({viewId, node}) => {
      if (viewId === me.props.viewId) {
        PB.emit('aiConsole', 'message.push', {
          type: 'user',
          content: `获取类似节点的系统用户`,
        });
        me.setState({
          showSmartSearchUserModal: true,
          smartSearchUserByNode: node,
        });
      }
    });

    PB.sub(me, 'relation', 'node.initial_position.do_save', ({viewId}) => {
      if (viewId === me.props.viewId) {
        Modal.confirm({
          title: '优化加载',
          content: '下次加载时将跳过动画直接加载节点的当前位置，是否继续？',
          okText: '是',
          cancelText: '否',
          onOk: () => {
            let allNodePositions = me.myNetwork.network.getPositions();

            let nodeListActions = [];
            let nodeList = [];
            Object.keys(allNodePositions).forEach(k => {
              if (nodeList.length >= 300) {
                nodeListActions.push(nodeList);
                nodeList = [];
              }
              nodeList.push({id: k, fx: allNodePositions[k].x, fy: allNodePositions[k].y, silentlyUpdate: true});
            });
            if (nodeList.length > 0) nodeListActions.push(nodeList);

            let totalRequests = nodeListActions.length, finishedRequests = 0, lastPercent = 0, lastHidePageLoading;

            let rFn = () => {
              if (nodeListActions.length <= 0) return;
              let percent = 100 * ((finishedRequests * 2 + 1) / (totalRequests * 2)),
                hidePageLoading = showPageLoading(
                  <span>
                    正在优化加载速度，当前进度：
                    <CountUp
                      start={lastPercent}
                      end={percent}
                      delay={0}
                      duration={0.4}
                    />
                    %
                  </span>
                );
              if (lastHidePageLoading) lastHidePageLoading();
              lastHidePageLoading = hidePageLoading;
              lastPercent = percent;
              me.props.networkRef.updateNodeInfoListPartially(nodeListActions[0], false).then(() => {
                nodeListActions.shift();
                finishedRequests++;
                if (nodeListActions.length <= 0) {
                  setTimeout(() => {
                    hidePageLoading = showPageLoading(
                      <span>
                        正在优化加载速度，当前进度：
                        <CountUp
                          start={lastPercent}
                          end={100}
                          delay={0}
                          duration={0.3}
                        />
                        %
                      </span>
                    );
                    if (lastHidePageLoading) lastHidePageLoading();
                    lastHidePageLoading = hidePageLoading;
                    setTimeout(() => {
                      if (lastHidePageLoading) lastHidePageLoading();
                      message.success('刷新时的加载速度已优化完成');
                    }, 800);
                  }, 300);
                } else {
                  setTimeout(rFn, 100);
                }
              }).catch(({code, msg}) => {
                hidePageLoading();
                showErrorMessage({code, msg, extra: {viewId: me.state.viewId, isModification: true}});
                console.log(`快速加载保存失败，code: ${code}, msg: ${msg}`);
              });
            };
            rFn();
          },
        });
      }
    });

    PB.sub(me, 'relation', 'node.initial_position.do_remove', ({viewId}) => {
      if (viewId === me.props.viewId) {
        Modal.confirm({
          title: '取消加载优化',
          content: '下次加载将从动画过程开始，是否继续？',
          okText: '是',
          cancelText: '否',
          onOk: () => {
            me.props.networkRef.removeNoneFixedNodePosition().then(() => {
              message.success('加载优化已取消');
            }).catch(({code, msg}) => {
              showErrorMessage({code, msg, extra: {viewId: me.state.viewId, isModification: true}});
              console.log(`加载优化取消失败，code: ${code}, msg: ${msg}`);
            });
          },
        });
      }
    });

    PB.sub(me, 'relation', 'user.favorite.get', () => {
      if (me.favorite.status === 'success') {
        PB.emit('relation', 'user.favorite.on_data_ready', me.favorite);
      } else if (me.favorite.status !== 'processing') {
        me.favorite.status = 'processing';
        me.props.networkRef.loadUserConfig(
          `$.favorite`
        ).then(favorite => {
          me.favorite = favorite ? {...me.favorite, ...favorite, status: 'success'} :
            {...me.favorite, status: 'success'};
          PB.emit('relation', 'user.favorite.on_data_ready', me.favorite);
        }).catch(({code, msg}) => {
          me.favorite.status = 'failed';
          PB.emit('relation', 'user.favorite.load_failed', {code, msg});
          showErrorMessage({code, msg, extra: {viewId: me.state.viewId, isModification: false}});
          console.log(`获取用户私有收藏失败，code: ${code}, msg: ${msg}`);
        });
      }
    });

    PB.sub(me, 'relation', 'user.favorite.node.add', ({nodeId}) => {
      if (me.favorite.status === 'success') {
        let nodeIds = _.uniq(me.props.networkRef.getNode([].concat(me.favorite.nodes).concat(nodeId))
          .filter(n => !!n).map(n => n.id));
        let node = me.props.networkRef.getNode(nodeId), messageKey;
        if (!node) return;
        PB.emit('aiConsole', 'message.push', {
          type: 'user',
          content: `将 "${getNodeDisplayTitle(node, 12)}" 设为我的收藏`,
        });
        PB.emit('aiConsole', 'message.push', {
          type: 'ai',
          content: (<span><Icon name={'loading'} style={{marginRight: '0.5em'}}/>请稍后...</span>),
          callback: ({key}) => messageKey = key,
          delay: 200,
        });
        me.favorite.status = 'processing';
        me.props.networkRef.updateUserConfig(`$.favorite`, {
          nodes: nodeIds,
        }).then(() => {
          me.favorite.nodes = nodeIds;
          // 将设置我的收藏的节点_userFavorite附加到最后一个
          me.myNetwork.updateNodes(me.props.networkRef.getNode([nodeId])
            .filter(n => !!n).map((n, i) => {
              n._userFavorite = nodeIds.length - 1;
              return {...n};
            }));

          PB.emit('relation', 'user.favorite.updated', me.favorite);
          const {data, viewId} = me.props.networkRef.getData();
          let relationData = {nodes: data.nodes.get(), edges: data.edges.get(), viewId};
          PB.emit('relation', 'data.after_change', relationData);
          PB.emit('aiConsole', 'message.update', {key: messageKey, content: `操作成功`});
        }).catch(({code, msg}) => {
          me.favorite.status = 'failed';
          PB.emit('relation', 'user.favorite.update_failed', {code, msg});
          showErrorMessage({code, msg, extra: {viewId: me.state.viewId, isModification: true}});
          console.log(`设置私有收藏失败，code: ${code}, msg: ${msg}`);
          PB.emit('aiConsole', 'message.update', {key: messageKey, content: `操作失败`});
        }).finally(() => me.favorite.status = 'success');
      } else if (me.favorite.status === 'processing') {
        showErrorMessage({code: 101, msg: '请求正在处理中'});
      } else {
        showErrorMessage({code: 400, msg: '数据加载失败，请刷新页面再试'});
      }
    });

    PB.sub(me, 'relation', 'user.favorite.node.remove', ({nodeId}) => {
      if (me.favorite.status === 'success') {
        if (me.favorite.nodes.indexOf(nodeId) >= 0) {
          let nodeIds = _.uniq(me.props.networkRef.getNode(me.favorite.nodes.filter(id => id !== nodeId))
            .filter(n => !!n).map(n => n.id));
          let node = me.props.networkRef.getNode(nodeId), messageKey;
          if (!node) return;
          PB.emit('aiConsole', 'message.push', {
            type: 'user',
            content: `将 "${getNodeDisplayTitle(node, 12)}" 取消我的收藏`,
          });
          PB.emit('aiConsole', 'message.push', {
            type: 'ai',
            content: (<span><Icon name={'loading'} style={{marginRight: '0.5em'}}/>请稍后...</span>),
            callback: ({key}) => messageKey = key,
            delay: 200,
          });
          me.favorite.status = 'processing';
          me.props.networkRef.updateUserConfig(`$.favorite`, {
            nodes: nodeIds,
          }).then(() => {
            me.favorite.nodes = nodeIds;
            // 将取消我的收藏的节点_userFavorite清除
            me.myNetwork.updateNodes(me.props.networkRef.getNode([nodeId])
              .filter(n => !!n).map((n) => {
                n._userFavorite = -1;
                return {...n};
              }));
            // 将其他我的收藏的节点_userFavorite重排
            me.myNetwork.updateNodes(me.props.networkRef.getNode(me.favorite.nodes)
              .filter(n => !!n).map((n, i) => {
                n._userFavorite = i;
                return {...n};
              }));
            PB.emit('relation', 'user.favorite.updated', me.favorite);
            const {data, viewId} = me.props.networkRef.getData();
            let relationData = {nodes: data.nodes.get(), edges: data.edges.get(), viewId};
            PB.emit('relation', 'data.after_change', relationData);
            PB.emit('aiConsole', 'message.update', {key: messageKey, content: `操作成功`});
          }).catch(({code, msg}) => {
            me.favorite.status = 'failed';
            PB.emit('relation', 'user.favorite.update_failed', {code, msg});
            showErrorMessage({code, msg, extra: {viewId: me.state.viewId, isModification: true}});
            console.log(`设置私有收藏失败，code: ${code}, msg: ${msg}`);
            PB.emit('aiConsole', 'message.update', {key: messageKey, content: `操作失败`});
          }).finally(() => me.favorite.status = 'success');
        }
      } else if (me.favorite.status === 'processing') {
        showErrorMessage({code: 101, msg: '请求正在处理中'});
      } else {
        showErrorMessage({code: 400, msg: '数据加载失败，请刷新页面再试'});
      }
    });
    // 我的收藏节点自定义排序更新
    PB.sub(me, 'relation', 'user.favorite.updating', ({nodeIds, currentNodeId, targetNodeId}) => {
      me.props.networkRef.updateUserConfig(`$.favorite`, {
        nodes: nodeIds,
      }).then(() => {
        me.favorite.nodes = nodeIds;
        me.myNetwork.updateNodes(me.props.networkRef.getNode(nodeIds)
          .filter(n => !!n).map((n, i) => {
            n._userFavorite = i;
            return {...n};
          }));
        PB.emit('relation', 'user.favorite.updated', me.favorite);
        const {data, viewId} = me.props.networkRef.getData();
        let relationData = {nodes: data.nodes.get(), edges: data.edges.get(), viewId};

        PB.emit('relation', 'data.after_change', relationData);
      }).catch(({code, msg}) => {
        me.favorite.status = 'failed';
        PB.emit('relation', 'user.favorite.update_failed', {code, msg});
        PB.emit('aiConsole', 'message.push', {
          type: 'ai',
          content: `节点“${getNodeDisplayTitle(me.props.networkRef.getNode(currentNodeId), 12)}”排序失败，请稍后再试！`,
        });
      }).finally(() => me.favorite.status = 'success');
    });

    // 获取重要节点
    PB.sub(me, 'relation', 'view.favorite.get', () => {
      if (me.viewFavorite.status === 'success') {
        PB.emit('relation', 'view.favorite.on_data_ready', me.viewFavorite);
      } else if (me.viewFavorite.status !== 'processing') {
        me.viewFavorite.status = 'processing';
        me.props.networkRef.loadConfig(
          `$.viewData.favorite`
        ).then(favorite => {
          me.viewFavorite = favorite ? {...me.viewFavorite, ...favorite, status: 'success'} :
            {...me.viewFavorite, status: 'success'};
          const {data} = me.props.networkRef.getData();
          let priorityNodeIds = data.nodes.get().filter(node => node.priority === 10).map(node => node.id);
          let uniqueNodeIds = [];
          me.viewFavorite.nodes.concat(priorityNodeIds).forEach(item => {
            if (uniqueNodeIds.indexOf(item) === -1) {
              uniqueNodeIds.push(item);
            }
          });
          me.viewFavorite.nodes = uniqueNodeIds;
          PB.emit('relation', 'view.favorite.on_data_ready', me.viewFavorite);
        }).catch(({code, msg}) => {
          me.viewFavorite.status = 'failed';
          PB.emit('relation', 'view.favorite.load_failed', {code, msg});
          showErrorMessage({code, msg, extra: {viewId: me.state.viewId, isModification: false}});
          console.log(`获取重要节点收藏失败，code: ${code}, msg: ${msg}`);
        });
      }
    });

    PB.sub(me, 'relation', 'view.favorite.node.add', ({nodeId}) => {
      if (me.viewFavorite.status === 'success') {
        let nodeIds = _.uniq(me.props.networkRef.getNode([].concat(me.viewFavorite.nodes).concat(nodeId))
          .filter(n => !!n).map(n => n.id));
        let node = me.props.networkRef.getNode(nodeId), messageKey;
        if (!node) return;
        PB.emit('aiConsole', 'message.push', {
          type: 'user',
          content: `将 "${getNodeDisplayTitle(node, 12)}" 设为重要节点`,
        });
        PB.emit('aiConsole', 'message.push', {
          type: 'ai',
          content: (<span><Icon name={'loading'} style={{marginRight: '0.5em'}}/>请稍后...</span>),
          callback: ({key}) => messageKey = key,
          delay: 200,
        });
        me.viewFavorite.status = 'processing';
        me.props.networkRef.updateConfig(`$.viewData.favorite`, {
          nodes: nodeIds,
        }).then(() => {
          me.viewFavorite.nodes = nodeIds;
          // 将设置重要节点的节点_viewFavorite附加到最后一个
          me.myNetwork.updateNodes(me.props.networkRef.getNode([nodeId])
            .filter(n => !!n).map((n, i) => {
              n._viewFavorite = nodeIds.length - 1;
              n.priority = 10;
              return {...n};
            }));

          PB.emit('relation', 'view.favorite.updated', me.viewFavorite);
          const {data, viewId} = me.props.networkRef.getData();
          let relationData = {nodes: data.nodes.get(), edges: data.edges.get(), viewId};
          PB.emit('relation', 'data.after_change', relationData);
          PB.emit('aiConsole', 'message.update', {key: messageKey, content: `操作成功`});
          // 修改节点优先级 此处为了兼容之前重要节点实现方式功能
          me.props.networkRef.updateViewNodeInfo({priority: 10, id: nodeId, silentlyUpdate: true}).then(res => {
            console.log('修改节点优先级 updateViewNodeInfo ->  res:', res);
          });
        }).catch(({code, msg}) => {
          me.viewFavorite.status = 'failed';
          PB.emit('relation', 'view.favorite.update_failed', {code, msg});
          showErrorMessage({code, msg, extra: {viewId: me.state.viewId, isModification: true}});
          console.log(`设置重要节点收藏失败，code: ${code}, msg: ${msg}`);
          PB.emit('aiConsole', 'message.update', {key: messageKey, content: `操作失败`});
        }).finally(() => me.viewFavorite.status = 'success');
      } else if (me.viewFavorite.status === 'processing') {
        showErrorMessage({code: 101, msg: '请求正在处理中'});
      } else {
        showErrorMessage({code: 400, msg: '数据加载失败，请刷新页面再试'});
      }
    });

    PB.sub(me, 'relation', 'view.favorite.node.remove', ({nodeId}) => {
      if (me.viewFavorite.status === 'success') {
        if (me.viewFavorite.nodes.indexOf(nodeId) >= 0) {
          let nodeIds = _.uniq(me.props.networkRef.getNode(me.viewFavorite.nodes.filter(id => id !== nodeId))
            .filter(n => !!n).map(n => n.id));
          let node = me.props.networkRef.getNode(nodeId), messageKey;
          if (!node) return;
          PB.emit('aiConsole', 'message.push', {
            type: 'user',
            content: `将 "${getNodeDisplayTitle(node, 12)}" 取消重要节点`,
          });
          PB.emit('aiConsole', 'message.push', {
            type: 'ai',
            content: (<span><Icon name={'loading'} style={{marginRight: '0.5em'}}/>请稍后...</span>),
            callback: ({key}) => messageKey = key,
            delay: 200,
          });
          me.viewFavorite.status = 'processing';
          me.props.networkRef.updateConfig(`$.viewData.favorite`, {
            nodes: nodeIds,
          }).then(() => {
            me.viewFavorite.nodes = nodeIds;
            // 将取消重要节点的节点_viewFavorite清除
            me.myNetwork.updateNodes(me.props.networkRef.getNode([nodeId])
              .filter(n => !!n).map((n) => {
                n._viewFavorite = -1;
                n.priority = 0;
                return {...n};
              }));
            // 将其他重要节点的节点_viewFavorite重排
            me.myNetwork.updateNodes(me.props.networkRef.getNode(me.viewFavorite.nodes)
              .filter(n => !!n).map((n, i) => {
                n._viewFavorite = i;
                return {...n};
              }));
            PB.emit('relation', 'view.favorite.updated', me.viewFavorite);
            const {data, viewId} = me.props.networkRef.getData();
            let relationData = {nodes: data.nodes.get(), edges: data.edges.get(), viewId};
            PB.emit('relation', 'data.after_change', relationData);
            PB.emit('aiConsole', 'message.update', {key: messageKey, content: `操作成功`});
            // 修改节点优先级 此处为了兼容之前重要节点实现方式功能
            me.props.networkRef.updateViewNodeInfo({priority: 0, id: nodeId, silentlyUpdate: true}).then(res => {
              console.log('修改节点优先级 updateViewNodeInfo ->  res:', res);
            });
          }).catch(({code, msg}) => {
            me.viewFavorite.status = 'failed';
            PB.emit('relation', 'view.favorite.update_failed', {code, msg});
            showErrorMessage({code, msg, extra: {viewId: me.state.viewId, isModification: true}});
            console.log(`设置重要节点失败，code: ${code}, msg: ${msg}`);
            PB.emit('aiConsole', 'message.update', {key: messageKey, content: `操作失败`});
          }).finally(() => me.viewFavorite.status = 'success');
        }
      } else if (me.viewFavorite.status === 'processing') {
        showErrorMessage({code: 101, msg: '请求正在处理中'});
      } else {
        showErrorMessage({code: 400, msg: '数据加载失败，请刷新页面再试'});
      }
    });
    // 重要节点自定义排序更新
    PB.sub(me, 'relation', 'view.favorite.updating', ({nodeIds, currentNodeId, targetNodeId}) => {
      me.props.networkRef.updateConfig(`$.viewData.favorite`, {
        nodes: nodeIds,
      }).then(() => {
        me.viewFavorite.nodes = nodeIds;
        me.myNetwork.updateNodes(me.props.networkRef.getNode(nodeIds)
          .filter(n => !!n).map((n, i) => {
            n._viewFavorite = i;
            return {...n};
          }));
        PB.emit('relation', 'view.favorite.updated', me.viewFavorite);
        const {data, viewId} = me.props.networkRef.getData();
        let relationData = {nodes: data.nodes.get(), edges: data.edges.get(), viewId};
        PB.emit('relation', 'data.after_change', relationData);
      }).catch(({code, msg}) => {
        me.viewFavorite.status = 'failed';
        PB.emit('relation', 'view.favorite.update_failed', {code, msg});
        PB.emit('aiConsole', 'message.push', {
          type: 'ai',
          content: `节点“${getNodeDisplayTitle(me.props.networkRef.getNode(currentNodeId), 12)}”排序失败，请稍后再试！`,
        });
      }).finally(() => me.viewFavorite.status = 'success');
    });

    PB.sub(me, 'relation', 'member.badge.on_set', ({node}) => {
      this.setState({showMemberBadgeFormModal: true, badgeNode: node});
    });

    // 全部固定
    PB.sub(me, 'relation', 'node.do_fix_all', ({viewId}) => {
      if (viewId === me.props.viewId) {
        let nodesToUpdate = me.props.networkRef.getNode({filter: n => n.userConfirmed && (!n.fixed || n['_locked'])});
        let nodeAmountToUpdate = nodesToUpdate.length;
        let allNodeAmount = me.props.networkRef.getNode({filter: n => n.userConfirmed}).length;
        let nodePercent = (100 * nodeAmountToUpdate / allNodeAmount).toFixed(2);
        if (nodeAmountToUpdate <= 0) {
          message.info('当前所有节点均为固定节点');
          return;
        }
        Modal.confirm({
          title: '固定所有节点',
          content: (
            <React.Fragment>
              将所有节点设为固定节点，共将影响 {nodeAmountToUpdate} 个节点（占图谱总节点的 {nodePercent}%）。<br/>
              本操作无法撤销，是否继续？
            </React.Fragment>
          ),
          okText: '是',
          cancelText: '否',
          onOk: () => {
            let allNodePositions = me.myNetwork.network.getPositions();

            let nodeListActions = [];
            let nodeList = [];
            nodesToUpdate.forEach(node => {
              if (nodeList.length >= 300) {
                nodeListActions.push(nodeList);
                nodeList = [];
              }
              nodeList.push({
                id: node.id,
                fixed: true,
                fx: allNodePositions[node.id].x,
                fy: allNodePositions[node.id].y,
                silentlyUpdate: true,
              });
            });
            if (nodeList.length > 0) nodeListActions.push(nodeList);

            let totalRequests = nodeListActions.length, finishedRequests = 0, lastPercent = 0, lastHidePageLoading;

            let rFn = () => {
              if (nodeListActions.length <= 0) return;
              let percent = 100 * ((finishedRequests * 2 + 1) / (totalRequests * 2)),
                hidePageLoading = showPageLoading(
                  <span>
                    正在将所有节点设为固定节点，当前进度：
                    <CountUp
                      start={lastPercent}
                      end={percent}
                      delay={0}
                      duration={0.4}
                    />
                    %
                  </span>
                );
              if (lastHidePageLoading) lastHidePageLoading();
              lastHidePageLoading = hidePageLoading;
              lastPercent = percent;
              me.props.networkRef.updateNodeInfoListPartially(nodeListActions[0], false).then(() => {
                nodeListActions.shift();
                finishedRequests++;
                if (nodeListActions.length <= 0) {
                  setTimeout(() => {
                    hidePageLoading = showPageLoading(
                      <span>
                        正在将所有节点设为固定节点，当前进度：
                        <CountUp
                          start={lastPercent}
                          end={100}
                          delay={0}
                          duration={0.3}
                        />
                        %
                      </span>
                    );
                    if (lastHidePageLoading) lastHidePageLoading();
                    lastHidePageLoading = hidePageLoading;
                    setTimeout(() => {
                      if (lastHidePageLoading) lastHidePageLoading();
                      message.success('所有节点已固定');
                    }, 200);
                  }, 300);
                } else {
                  setTimeout(rFn, 100);
                }
              }).catch(({code, msg}) => {
                hidePageLoading();
                showErrorMessage({code, msg, extra: {viewId: me.state.viewId, isModification: true}});
                console.log(`节点固定失败，code: ${code}, msg: ${msg}`);
              });
            };
            rFn();
          },
        });
      }
    });

    // 全部可动
    PB.sub(me, 'relation', 'node.do_unfix_all', ({viewId}) => {
      if (viewId === me.props.viewId) {
        let nodesToUpdate = me.props.networkRef.getNode({filter: n => n.userConfirmed && (n.fixed && !n['_locked'])});
        let nodeAmountToUpdate = nodesToUpdate.length;
        let allNodeAmount = me.props.networkRef.getNode({filter: n => n.userConfirmed}).length;
        let nodePercent = (100 * nodeAmountToUpdate / allNodeAmount).toFixed(2);
        if (nodeAmountToUpdate <= 0) {
          message.info('当前所有节点均为自由位置节点');
          return;
        }
        Modal.confirm({
          title: '取消固定所有节点',
          content: (
            <React.Fragment>
              将所有节点设为自由位置节点，共将影响 {nodeAmountToUpdate} 个节点（占图谱总节点的 {nodePercent}%）。<br/>
              本操作无法撤销，是否继续？
            </React.Fragment>
          ),
          okText: '是',
          cancelText: '否',
          onOk: () => {
            let nodeListActions = [];
            let nodeList = [];
            nodesToUpdate.forEach(node => {
              if (nodeList.length >= 300) {
                nodeListActions.push(nodeList);
                nodeList = [];
              }
              nodeList.push({
                id: node.id,
                fixed: false,
                silentlyUpdate: true,
              });
            });
            if (nodeList.length > 0) nodeListActions.push(nodeList);

            let totalRequests = nodeListActions.length, finishedRequests = 0, lastPercent = 0, lastHidePageLoading;

            let rFn = () => {
              if (nodeListActions.length <= 0) return;
              let percent = 100 * ((finishedRequests * 2 + 1) / (totalRequests * 2)),
                hidePageLoading = showPageLoading(
                  <span>
                    正在将所有节点设为自由位置节点，当前进度：
                    <CountUp
                      start={lastPercent}
                      end={percent}
                      delay={0}
                      duration={0.4}
                    />
                    %
                  </span>
                );
              if (lastHidePageLoading) lastHidePageLoading();
              lastHidePageLoading = hidePageLoading;
              lastPercent = percent;
              me.props.networkRef.updateNodeInfoListPartially(nodeListActions[0], false).then(() => {
                nodeListActions.shift();
                finishedRequests++;
                if (nodeListActions.length <= 0) {
                  setTimeout(() => {
                    hidePageLoading = showPageLoading(
                      <span>
                        正在将所有节点设为自由位置节点，当前进度：
                        <CountUp
                          start={lastPercent}
                          end={100}
                          delay={0}
                          duration={0.3}
                        />
                        %
                      </span>
                    );
                    if (lastHidePageLoading) lastHidePageLoading();
                    lastHidePageLoading = hidePageLoading;
                    setTimeout(() => {
                      if (lastHidePageLoading) lastHidePageLoading();
                      message.success('所有节点已设为自由位置');
                    }, 200);
                  }, 300);
                } else {
                  setTimeout(rFn, 100);
                }
              }).catch(({code, msg}) => {
                hidePageLoading();
                showErrorMessage({code, msg, extra: {viewId: me.state.viewId, isModification: true}});
                console.log(`节点释放失败，code: ${code}, msg: ${msg}`);
              });
            };
            rFn();
          },
        });
      }
    });

    // 全部直线
    PB.sub(me, 'relation', 'edge.do_straight_all', ({viewId}) => {
      if (viewId === me.props.viewId) {
        let edgesToUpdate = me.props.networkRef.getEdge(
          {filter: e => (e.userConfirmed && (!e.meta || e.meta.smooth === undefined || e.meta.smooth === 1))});
        let edgeAmountToUpdate = edgesToUpdate.length;
        let allEdgeAmount = me.props.networkRef.getEdge({filter: n => n.userConfirmed}).length;
        let edgePercent = (100 * edgeAmountToUpdate / allEdgeAmount).toFixed(2);
        if (edgeAmountToUpdate <= 0) {
          message.info('当前所有连线均为直线');
          return;
        }
        Modal.confirm({
          title: '全部改为直线',
          content: (
            <React.Fragment>
              将所有连线改为直线，共将影响 {edgeAmountToUpdate} 条连线（占图谱总连线的 {edgePercent}%）。<br/>
              本操作无法撤销，是否继续？
            </React.Fragment>
          ),
          okText: '是',
          cancelText: '否',
          onOk: () => {
            let edgeListActions = [];
            let edgeList = [];
            edgesToUpdate.forEach(edge => {
              if (edgeList.length >= 300) {
                edgeListActions.push(edgeList);
                edgeList = [];
              }
              edgeList.push({
                ...edge,
                meta: (edge.meta ? {...edge.meta, smooth: 0} : {smooth: 0}),
              });
            });
            if (edgeList.length > 0) edgeListActions.push(edgeList);

            let totalRequests = edgeListActions.length, finishedRequests = 0, lastPercent = 0, lastHidePageLoading;

            let rFn = () => {
              if (edgeListActions.length <= 0) return;
              let percent = 100 * ((finishedRequests * 2 + 1) / (totalRequests * 2)),
                hidePageLoading = showPageLoading(
                  <span>
                    正在将所有连线改为直线，当前进度：
                    <CountUp
                      start={lastPercent}
                      end={percent}
                      delay={0}
                      duration={0.4}
                    />
                    %
                  </span>
                );
              if (lastHidePageLoading) lastHidePageLoading();
              lastHidePageLoading = hidePageLoading;
              lastPercent = percent;
              me.props.networkRef.updateEdgeInfoList(edgeListActions[0]).then(() => {
                edgeListActions.shift();
                finishedRequests++;
                if (edgeListActions.length <= 0) {
                  setTimeout(() => {
                    hidePageLoading = showPageLoading(
                      <span>
                        正在将所有连线改为直线，当前进度：
                        <CountUp
                          start={lastPercent}
                          end={100}
                          delay={0}
                          duration={0.3}
                        />
                        %
                      </span>
                    );
                    if (lastHidePageLoading) lastHidePageLoading();
                    lastHidePageLoading = hidePageLoading;
                    setTimeout(() => {
                      if (lastHidePageLoading) lastHidePageLoading();
                      message.success('所有连线已设为直线');
                    }, 200);
                  }, 300);
                } else {
                  setTimeout(rFn, 100);
                }
              }).catch(({code, msg}) => {
                hidePageLoading();
                showErrorMessage({code, msg, extra: {viewId: me.state.viewId, isModification: true}});
                console.log(`连线设置失败，code: ${code}, msg: ${msg}`);
              });
            };
            rFn();
          },
        });
      }
    });

    // 全部曲线
    PB.sub(me, 'relation', 'edge.do_smooth_all', ({viewId}) => {
      if (viewId === me.props.viewId) {
        let edgesToUpdate = me.props.networkRef.getEdge(
          {filter: e => (e.userConfirmed && (e.meta && e.meta.smooth === 0))});
        let edgeAmountToUpdate = edgesToUpdate.length;
        let allEdgeAmount = me.props.networkRef.getEdge({filter: n => n.userConfirmed}).length;
        let edgePercent = (100 * edgeAmountToUpdate / allEdgeAmount).toFixed(2);
        if (edgeAmountToUpdate <= 0) {
          message.info('当前所有连线均为曲线');
          return;
        }
        Modal.confirm({
          title: '全部改为曲线',
          content: (
            <React.Fragment>
              将所有连线改为曲线，共将影响 {edgeAmountToUpdate} 条连线（占图谱总连线的 {edgePercent}%）。<br/>
              本操作无法撤销，是否继续？
            </React.Fragment>
          ),
          okText: '是',
          cancelText: '否',
          onOk: () => {
            let edgeListActions = [];
            let edgeList = [];
            edgesToUpdate.forEach(edge => {
              if (edgeList.length >= 300) {
                edgeListActions.push(edgeList);
                edgeList = [];
              }
              edgeList.push({
                ...edge,
                meta: (edge.meta ? {...edge.meta, smooth: 1} : {smooth: 1}),
              });
            });
            if (edgeList.length > 0) edgeListActions.push(edgeList);

            let totalRequests = edgeListActions.length, finishedRequests = 0, lastPercent = 0, lastHidePageLoading;

            let rFn = () => {
              if (edgeListActions.length <= 0) return;
              let percent = 100 * ((finishedRequests * 2 + 1) / (totalRequests * 2)),
                hidePageLoading = showPageLoading(
                  <span>
                    正在将所有连线改为曲线，当前进度：
                    <CountUp
                      start={lastPercent}
                      end={percent}
                      delay={0}
                      duration={0.4}
                    />
                    %
                  </span>
                );
              if (lastHidePageLoading) lastHidePageLoading();
              lastHidePageLoading = hidePageLoading;
              lastPercent = percent;
              me.props.networkRef.updateEdgeInfoList(edgeListActions[0]).then(() => {
                edgeListActions.shift();
                finishedRequests++;
                if (edgeListActions.length <= 0) {
                  setTimeout(() => {
                    hidePageLoading = showPageLoading(
                      <span>
                        正在将所有连线改为曲线，当前进度：
                        <CountUp
                          start={lastPercent}
                          end={100}
                          delay={0}
                          duration={0.3}
                        />
                        %
                      </span>
                    );
                    if (lastHidePageLoading) lastHidePageLoading();
                    lastHidePageLoading = hidePageLoading;
                    setTimeout(() => {
                      if (lastHidePageLoading) lastHidePageLoading();
                      message.success('所有连线已设为曲线');
                    }, 200);
                  }, 300);
                } else {
                  setTimeout(rFn, 100);
                }
              }).catch(({code, msg}) => {
                hidePageLoading();
                showErrorMessage({code, msg, extra: {viewId: me.state.viewId, isModification: true}});
                console.log(`连线设置失败，code: ${code}, msg: ${msg}`);
              });
            };
            rFn();
          },
        });
      }
    });

    PB.sub(me, 'relation', 'graph.export', ({viewId}) => {
      if (viewId === me.props.viewId) {
        let {viewInfo, data: {nodes, edges}} = me.props.networkRef.getData();
        let data = {
          viewInfo: {
            id: viewInfo.viewId,
            name: viewInfo.name,
            description: viewInfo.desc,
            coverDataUrl: viewInfo['hasCover'] ? viewInfo['coverDataUrl'] : undefined,
            backgroundDataUrl: me.backgroundImageConfig.dataUrl,
            nodeCount: viewInfo.nodeCount,
            edgeCount: viewInfo.edgeCount,
            tags: `${viewInfo.tags}`.trim().split('\n'),
            updateTime: viewInfo.updateTime,
            ownerNick: viewInfo.nick,
            ownerId: viewInfo.userId,
          },
          edges: edges.get().filter(e => e.visible && e.userConfirmed).map(e => ({
            from: e.from,
            to: e.to,
            userConfirmed: e.userConfirmed,
            status: e.status,
            meta: e.meta || {},
          })),
          nodes: nodes.get().filter(n => n.userConfirmed).map(n => ({
            id: n.id,
            fname: n.fname,
            org: n.org,
            tags: n.tags,
            fixed: n.fixed,
            fx: n.fx,
            fy: n.fy,
            lev: n.lev,
            status: n.status,
            meta: n.meta,
            linkTime: n.linkTime,
            updateTime: n.updateTime,
            position: n.position,
            userId: n.userId,
            listed: n.listed,
            priority: n.priority,
            description: n.description,
            url: n.url,
            aiGraphRank: n.aiGraphRank,
            aiPreferredType: n.aiPreferredType,
            aiUpdateTime: n.aiUpdateTime,
            userPreferredType: n.userPreferredType,
            userConfirmed: n.userConfirmed,
          })),
        };
        saveJsonFile(data, viewInfo.name);
      }
    });

    PB.sub(me, 'relation', 'node.single_selection_change', nodeId => {
      if (me.state.activatedNodeId !== nodeId) {
        me.setState({activatedNodeId: nodeId});
      }
    });

    PB.sub(me, 'relation', 'presentation.started', ({viewId}) => {
      if (viewId === me.props.viewId) {
        me.previousLightNodeConfigBeforePresentation = {...me.lastLightNodeConfig};
        me.previousSelectionBeforePresentation = me.myNetwork.network.getSelection();
        me.myNetwork.network.unselectAll();
      }
    });

    PB.sub(me, 'relation', 'presentation.playing.node', ({viewId, nodeId}) => {
      if (viewId === me.props.viewId) {
        let nodeIdList = me.props.networkRef.getConnectedNodeIds(nodeId);
        PB.emit('network', 'light_node.do', {
          status: 'playing',
          fn: node => nodeIdList.indexOf(node.id) >= 0,
        });
        me.myNetwork.network.selectNodes([nodeId]);
      }
    });

    PB.sub(me, 'relation', 'presentation.stopped', ({viewId}) => {
      if (viewId === me.props.viewId) {
        PB.emit('network', 'light_node.do', me.previousLightNodeConfigBeforePresentation);
        me.myNetwork.network.setSelection(me.previousSelectionBeforePresentation);
      }
    });

    PB.sub(me, 'view', 'wordCloud.show', () => {
      me.setState({
        showWordCloudModal: true,
      })
    });

    PB.sub(me, 'node', 'explore.normal.invisible', () => {
      me.setState({
        activatedNodeId: undefined,
      })
    });

    /* 节点的推荐连接 */
    PB.sub(me, 'node', 'explore.aiConsole.related_node.do_load', ({node}) => {
      if (node) {
        // 添加用户操作提示
        PB.emit('aiConsole', 'message.push',
          {type: 'user', content: `查看节点 "${getNodeDisplayTitle(node, 5)}" 的推荐连接`});
        // 添加加载节点推荐连接提示
        PB.emit('aiConsole', 'message.push', {
          type: 'ai',
          content: (<span><Icon name={'loading'} style={{marginRight: '0.5em'}}/>请稍后...</span>),
          callback: ({key}) => {
            if (me.relatedNodeMessageKeys[node.id]) {
              // 清理上一次显示的信息
              PB.emit('aiConsole', 'message.update', {
                key: me.relatedNodeMessageKeys[node.id],
                content: (
                  <span>
                    <a
                      onClick={() => PB.emit('aiConsole', 'message.notice',
                        {key: me.relatedNodeMessageKeys[node.id] || key})}
                    >
                      点击查看节点的推荐连接节点列表
                    </a>
                  </span>
                ),
              });
            }
            me.relatedNodeMessageKeys[node.id] = key;
          },
          delay: 200,
        });

        let loadingStatus = {
          relatedNodeLoaded: false,
          targetNode: node,
          relatedNodeList: [],
        };

        NodeDataProvider.exploreRelatedNode(node).then((nodes) => {
          let relatedNodes = nodes || [];
          relatedNodes.map(node => {
            node['type'] = node.type || NODE_TYPE_TEXT;
            return node;
          });

          loadingStatus.relatedNodeLoaded = true;
          // 推荐连接信息加载完成
          loadingStatus.relatedNodeList = me.handleRedundancyNodes(relatedNodes);
          PB.emit('aiConsole', 'message.update', {
            key: me.relatedNodeMessageKeys[node.id],
            content: (
              <ExploreRelatedNodePanel
                nodeId={node.id}
                nodeInfo={node}
                relatedNodeList={loadingStatus.relatedNodeList}
                viewDataProvider={me.props.networkRef}
                bus={PB}
              />
            ),
          });
        }).catch(({code, msg}) => {
          showErrorMessage({code, msg, extra: {viewId: me.state.viewId, isModification: true}});
          PB.emit('console', 'info', '获取节点推荐连接信息失败');
          PB.emit('aiConsole', 'message.update',
            {key: me.relatedNodeMessageKeys[node.id], content: `操作失败`});
        });
      }
    });

    /* 节点的推荐填充 */
    PB.sub(me, 'node', 'explore.aiConsole.override.do_load', ({node}) => {
      if (node) {
        // 添加用户操作提示
        PB.emit('aiConsole', 'message.push',
          {type: 'user', content: `查看节点 "${getNodeDisplayTitle(node, 5)}" 的推荐填充内容`});
        // 添加加载节点推荐连接提示
        PB.emit('aiConsole', 'message.push', {
          type: 'ai',
          content: (<span><Icon name={'loading'} style={{marginRight: '0.5em'}}/>请稍后...</span>),
          callback: ({key}) => {
            if (me.overrideMessageKeys[node.id]) {
              // 清理上一次显示的信息
              PB.emit('aiConsole', 'message.update', {
                key: me.overrideMessageKeys[node.id],
                content: (
                  <span>
                    <a
                      onClick={() => PB.emit('aiConsole', 'message.notice',
                        {key: me.overrideMessageKeys[node.id] || key})}
                    >
                      点击查看节点的推荐填充节点列表
                    </a>
                  </span>
                ),
              });
            }
            me.overrideMessageKeys[node.id] = key;
          },
          delay: 200,
        });

        let loadingStatus = {
          overrideLoaded: false,
          targetNode: node,
          overrideList: [],
        };

        NodeDataProvider.exploreOverride(node).then((nodes) => {
          let overrideNodes = nodes || [];
          overrideNodes.map(node => {
            node['type'] = node.type || NODE_TYPE_TEXT;
            return node;
          });

          loadingStatus.overrideLoaded = true;
          // 推荐连接信息加载完成
          loadingStatus.overrideList = me.handleRedundancyNodes(overrideNodes);
          PB.emit('aiConsole', 'message.update', {
            key: me.overrideMessageKeys[node.id],
            content: (
              <ExploreOverridePanel
                visible={true}
                nodeInfo={node}
                nodeId={node.id}
                overrideList={loadingStatus.overrideList}
                // viewDataProvider={me.props.networkRef}
                bus={PB}
              />
            ),
          });
        }).catch(({code, msg}) => {
          showErrorMessage({code, msg, extra: {viewId: me.state.viewId, isModification: true}});
          PB.emit('console', 'info', '获取节点推荐填充信息失败');
          PB.emit('aiConsole', 'message.update',
            {key: me.overrideMessageKeys[node.id], content: `操作失败`});
        });
      }
    });

    me.myNetwork.subscribe(me, 'CONFIG.normalBrightnessChanged', () => {
      PB.emit('network', 'light_node.do', me.lastLightNodeConfig);
    });

    PB.sub(me, 'relation', 'node.presentation.focus', ({node, config, type}) => {
      if (me.state.nodeId !== node.id || type === 'autoRefresh') {  //autoRefresh时为自动刷新，对同一节点修改需要再次漫游该节点以更新数据
        PB.emit('relation', 'node.presentation.stop');
        me.setState({nodeId: node.id}, () => {
          PB.emit('node', 'presentation.do', {node, config});
         });
      }
    });

    PB.sub(me, 'relation', 'node.presentation.stop', () => {
      me.setState({nodeId: undefined}, () => {
        PB.emit('node', 'presentation.stop');
       })
    });

    PB.sub(me, 'network', 'node.do_copy_to_view', ({viewId}) => {
      if (viewId && me.state.copyNodeToViewType>0 && me.state.copyInfo && me.state.copyInfo.nodes && me.state.copyInfo.nodes.length>0 ) {
        me.setState({editModalProcessing: true}, ()=>{
          me.props.networkRef.addRelationPromise(me.state.copyInfo.nodes, me.state.copyInfo.edges, false, undefined,viewId).then(() => {
            message.success(`复制成功`);
            me.setState({copyNodeToViewType: 0,copyInfo: undefined,editModalProcessing:false});
          }).catch(({code, msg}) => {
            me.setState({editModalProcessing:false});
            showErrorMessage({code, msg, extra: {viewId: viewId, isModification: true}});
          });
        });
        
      }
    });

    me.myNetwork.options = {
      ...me.myNetwork.options,
      manipulation: {
        ...me.myNetwork.options.manipulation,
        addEdge: me.onDragAddEdge,
      },
    };

    if ((!me.props.visionConfig) || (!me.props.visionConfig.multiLevelBrightness)) {
      if (me.myNetwork.defaultNormalBrightness !== 1.0) {
        me.myNetwork.defaultNormalBrightness = 1.0;
      }
    } else {
      if (me.myNetwork.defaultNormalBrightness !== me.props.visionConfig.multiLevelBrightness) {
        me.myNetwork.defaultNormalBrightness = me.props.visionConfig.multiLevelBrightness;
      }
    }

    if (!me.props.graphOnly) {
      getCacheStore('local-cache-data').then(store => me.localDataCacheStore = store);
    }
  }

  componentWillUnmount() {
    //去掉所有的订阅
    PB.remove(this);
    this.props.networkRef.unSubscribe(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let me = this;

    if ((me.props.visionConfig !== prevProps.visionConfig)
      || (me.props.visionConfig && (
        prevProps.visionConfig.multiLevelBrightness !== me.props.visionConfig.multiLevelBrightness
      ))) {

      if ((!me.props.visionConfig) || (!me.props.visionConfig.multiLevelBrightness)) {
        if (me.myNetwork.defaultNormalBrightness !== 1.0) {
          me.myNetwork.defaultNormalBrightness = 1.0;
        }
      } else {
        if (me.myNetwork.defaultNormalBrightness !== me.props.visionConfig.multiLevelBrightness) {
          me.myNetwork.defaultNormalBrightness = me.props.visionConfig.multiLevelBrightness;
        }
      }
    }
  }

  onRememberOperationChanged = (e) => {
    this.setState({
      rememberOperationState:  e.target.checked
    });
  };
  rememberOperationCallback = () => {
    let me = this;
    if(me.state.rememberOperation===2){
      me.setState({showNodeMatchNoticeModal: false},
        me.state.useNewNodeCallback);
    }else if(me.state.rememberOperation===3){
      me.setState({showNodeMatchNoticeModal: false},
        me.state.useOriginalCallback);
      }
  };

  render() {
    let me = this;

    const {viewInfo} = this.state;
    let view = viewInfo;
    const currentUserId = parseInt(localStorage.getItem("userId"));
    let currentNode = me.currentNodeId ? me.props.networkRef.getNode(me.currentNodeId) : undefined;
    const enableCrossEdit = me.props.networkRef.viewInfo && me.props.networkRef.viewInfo['teamworkMetaJSON']
      && me.props.networkRef.viewInfo['teamworkMetaJSON']['enable_cross_edit'] === 1;

    return (
      <div className={this.props.className} style={{
        flex: 1,
        position: 'relative',
        zIndex: me.props.graphOnly ? 10000 : undefined,
        display: me.props.graphOnly ? 'contents' : undefined
      }}>
        <Desktop viewDataProvider={me.props.networkRef}/>
        <GraphExport myNetwork={this.myNetwork}/>
        <div style={{height: '100%', width: '100%', zIndex: me.props.graphOnly ? 10000 : undefined}}>
          <EventListener
            target={window}
            onKeyDown={me.onKeyDown}
            onKeyUp={me.onKeyUp}
          />
          <div id="network" className={style["relation-container"]}>&nbsp;</div>
          {
            viewInfo && (
              <div
                style={{
                  height: '100%',
                  width: '100%',
                  display: me.state.showContextMenu ? 'block' : 'none', position: 'absolute',
                  top: 0,
                  left: 0,
                  backgroundColor: 'rgba(0, 0, 0, 0.2)',
                  zIndex: 102,
                }}
                onClick={() => me.setState({showContextMenu: false, contextNodeId: undefined})}
              >
                {
                  me.state.contextNodeId ? (
                    <NodeNormalContextMenu
                      visible={me.state.showContextMenu === 'node'}
                      x={me.state.contextMenuX}
                      y={me.state.contextMenuY}
                      node={me.props.networkRef.getNode(me.state.contextNodeId)}
                      lastSelectedNode={
                        me.selectedNodeHistory[1] ? me.props.networkRef.getNode(me.selectedNodeHistory[1].id) : undefined
                      }
                      readOnly={
                        parseInt(me.props.networkRef.getNode(me.state.contextNodeId).userId) !== currentUserId
                        && parseInt(me.state.viewInfo.userId) !== currentUserId
                        && (!enableCrossEdit)
                      }
                    />
                  ) : null
                }
                {
                  me.state.contextSelection ? (
                    <GraphNormalContextMenu
                      visible={me.state.showContextMenu === 'graph'}
                      x={me.state.contextMenuX}
                      y={me.state.contextMenuY}
                      node={me.state.contextNodeId ? me.props.networkRef.getNode(me.state.contextNodeId) : undefined}
                      nodes={me.state.contextSelection.nodes}
                      edges={me.state.contextSelection.edges}
                      network={me.myNetwork.network}
                      readOnly={me.props.readonly}
                      viewOwnerId={this.state.viewInfo.userId}
                      currentUserId={currentUserId}
                      enableCrossEdit={enableCrossEdit}
                    />
                  ) : null
                }
                <FileUploadModal
                  networkRef={me.props.networkRef}
                  visible={me.state.showUploadModal}
                  nodeId={me.state.uploadModalNodeId}
                  onClose={() => me.setState({showUploadModal: false})}
                />
                {
                  me.state.extraInfoListModalNodeId ? (
                    <NodeInfoAttachmentListModal
                      nodeId={me.state.extraInfoListModalNodeId}
                      editable={
                        !me.props.readonly
                        && (parseInt(me.props.networkRef.getNode(me.state.extraInfoListModalNodeId).userId) === currentUserId
                          || parseInt(view.userId) === currentUserId)
                      }
                      networkRef={me.props.networkRef}
                      visible={me.state.extraInfoListModalVisible}
                      onClose={() => me.setState({
                        extraInfoListModalVisible: false,
                        extraInfoListModalNodeId: undefined,
                      })}
                    />
                  ) : null
                }
              </div>
            )
          }
          {/* 推荐填充 */}
          {/*<div className={style['tiny-ai-console']}>
            <ExploreNormal
              visible={!!me.state.activatedNodeId && me.state.aiConsoleStatus !== 'hidden'}
              globalUse={!!me.state.activatedNodeId && me.state.aiConsoleStatus !== 'hidden'}
              nodeId={me.state.activatedNodeId}
              viewDataProvider={me.props.networkRef}
            />
          </div>*/}
          {/* 推荐填充 */}
          {/*
            (viewInfo && me.props.networkRef) ? (
              <div className={style['tiny-ai-console']}>
                <MicroServiceSpecialNodeExpand
                  visible={!!me.state.activatedNodeId && me.state.aiConsoleStatus !== 'hidden'}
                  viewId={viewInfo.viewId}
                  nodeInfo={me.state.activatedNodeId ? me.props.networkRef.getNode(me.state.activatedNodeId) : undefined}
                  viewDataProvider={this.props.networkRef}
                  bus={PB}
                />
              </div>
            ) : null*/
          }
          {
            (viewInfo && me.props.networkRef) ? (
              <div className={style['tiny-ai-console']}>
                <NodeExpandAnalysis
                  visible={!!me.state.activatedNodeId}  // && me.state.aiConsoleStatus !== 'hidden'
                  viewId={viewInfo.viewId}
                  nodeInfo={me.state.activatedNodeId ? me.props.networkRef.getNode(me.state.activatedNodeId) : undefined}
                  viewDataProvider={this.props.networkRef}
                  bus={PB}
                />
              </div>
            ) : null
          }
          {
            (viewInfo && me.props.networkRef) ? (
              <div>
                <AnalysisIncidentList
                  visible={!!me.state.activatedNodeId}
                  viewId={viewInfo.viewId}
                  nodeInfo={me.state.activatedNodeId ? me.props.networkRef.getNode(me.state.activatedNodeId) : undefined}
                  viewDataProvider={this.props.networkRef}
                  bus={PB}
                />
              </div>
            ) : null
          }
          {
            (viewInfo && me.props.networkRef) ? (
              <div>
                <InputSearchFromDB
                  visible={true}
                  viewId={viewInfo.viewId}
                  viewInfo={viewInfo}
                  nodeInfo={me.state.activatedNodeId ? me.props.networkRef.getNode(me.state.activatedNodeId) : undefined}
                  viewDataProvider={this.props.networkRef}
                  bus={PB}
                />
              </div>
            ) : null
          }
           {
            (viewInfo && me.props.networkRef) ? (
              <div>
                <AnalysisNodeForEdge
                  visible={true}
                  viewId={viewInfo.viewId}
                  //nodeInfo={me.state.activatedNodeId ? me.props.networkRef.getNode(me.state.activatedNodeId) : undefined}
                  viewDataProvider={this.props.networkRef}
                  bus={PB}
                />
              </div>
            ) : null
          }
        </div>
        <div
          style={{
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            pointerEvents: 'none',
            position: 'absolute',
          }}
        >
          {
            this.state.showNodeTooltip && this.state.nodeTooltipInfo &&
            this.state.nodeTooltipInfo.success && (
              <NodeInfoCommonTooltip {...this.state.nodeTooltipInfo} />
            )
          }
        </div>
        <div className={style['network-mask']} style={{display: this.state.showMask}}>&nbsp;</div>
        {
          (viewInfo && me.props.networkRef) ? (
            <ViewUploadBannerComponent
              disabled={false}
              networkRef={me.props.networkRef}
              viewId={viewInfo.viewId}
              viewInfo={viewInfo}
            />
          ) : null
        }

        {
          me.bannerImageConfig.dataUrl ? (
            <div className={style['view-title-banner-pointer']}>
              <img
                src={me.bannerImageConfig.dataUrl}
                alt={me.state.viewInfo.name}
                onClick={() => PB.emit('view', 'view.info.show_modal')}
              />
            </div>
          ) : view ? (
            <div className={style['view-title']}>
              {
                me.state.viewInfo ? (
                  <span
                    style={{
                      borderBottom: "3px double",
                      cursor: (
                        me.props.readonly || !me.state.viewInfo ||
                        me.state.viewInfo.userId !== currentUserId
                      ) ? "normal" : "pointer",
                    }}
                  >
                    <span onClick={() => PB.emit('view', 'view.info.show_modal')}>
                      {view.name}
                    </span>
                    {
                      view.lock === 1 ? (
                        <Icon
                          type={IconTypes.ICON_FONT}
                          name={'icon-lock-position'}
                          style={{marginLeft: '0.5rem'}}
                        />
                      ) : null
                    }
                  </span>
                ) : null
              }
            </div>
          ) : ''
        }
        {
          this.state.dataLoadingStatus === NetworkDataLoadingStatus.SUCCESS ? (
            <SetViewInfoModal
              bus={PB}
              visible={me.state.showSetViewInfoModal}
              viewId={me.state.viewId}
              viewInfo={me.state.viewInfo}
              onClose={() => me.setState({showSetViewInfoModal: false})}
            />
          ) : null
        }

        {
          this.state.viewId && this.state.viewInfo && !this.props.graphOnly ? (
            <React.Fragment>
              <Teamwork
                bus={PB}
                viewId={this.state.viewId}
                viewOwnerId={this.state.viewInfo.userId}
                viewInfo={this.state.viewInfo}
                currentUserId={currentUserId}
                readonly={this.props.readonly || this.state.viewInfo.userId !== currentUserId}
                recommendation={this.state.recommendation || []}
                userInfo={this.props.userInfo}
              />
              <PresentationDrawer
                bus={PB}
                readOnly={this.props.readonly}
                viewDataProvider={this.props.networkRef}
                currentUserId={currentUserId}
                viewOwnerId={this.state.viewInfo.userId}
                activatedNodeList={this.state.activatedNodeList}
              />
              {/*
              <PresentationStory
                bus={PB}
                readOnly={this.props.readonly}
                viewDataProvider={this.props.networkRef}
                currentUserId={currentUserId}
                viewId={this.state.viewId}
                viewOwnerId={this.state.viewInfo.userId}
              />*/}
              <ExtendedActionsViewer bus={PB} />
            </React.Fragment>
          ) : null
        }
        {
          this.myNetwork && this.props.networkRef && this.state.viewId ? (
            <React.Fragment>
              <VisualToolMini
                readonly={this.props.readonly}
                enablePresentation={true}
                networkRef={this.myNetwork}
                viewId={this.state.viewId}
                viewDataProvider={this.props.networkRef}
                hideAiDialogBoxFlag={this.props.hideAiDialogBoxFlag}
                hideInputBoxFlag={this.props.hideInputBoxFlag}
                hideMicroservicesBoxFlag={this.props.hideMicroservicesBoxFlag}
                hideViewBoxFlag={this.props.hideViewBoxFlag}
              />
              <RelationPresentation
                bus={PB}
                viewDataProvider={this.props.networkRef}
                network={this.myNetwork}
                viewId={this.state.viewId}
              />
              <RelationComponentConnector
                bus={PB}
                viewId={this.state.viewId}
              />
            </React.Fragment>
          ) : null
        }
        {
          this.myNetwork && this.props.networkRef && this.state.nodeId ? (
            <NodePresentation
              bus={PB}
              viewDataProvider={this.props.networkRef}
              network={this.myNetwork}
              viewId={this.state.viewId}
              nodeId={this.state.nodeId}
            />
          ) : null
        }
        {/*{me.getNodeAddModal()}*/}
        {me.getNodeEditModal()}
        {me.getEdgeEditModal()}
        {this.state.deleteModalTarget ? me.getNodeDeleteModal() : null}
        <GuideModal
          visible={me.state.showGuideModal}
          onClose={() => me.setState({showGuideModal: false})}
        />
        <RankListModal
          onClose={() => me.setState({showRankModal: false})}
          viewDataProvider={me.props.networkRef}
          visible={me.state.showRankModal}
          type={me.state.rankType}
        />
        <MemberSetBadgeModal
          node={me.state.badgeNode}
          onClose={() => me.setState({showMemberBadgeFormModal: false})}
          visible={me.state.showMemberBadgeFormModal}
          viewDataProvider={me.props.networkRef}
          currentUserId={currentUserId}
        />
        <ExploreCustomRequestModal
          onClose={() => me.setState({showExploreCustomRequestModal: false})}
          viewDataProvider={me.props.networkRef}
          visible={me.state.showExploreCustomRequestModal}
        />
        <PersonMultiRelationModal
          onClose={() => me.setState({showMultiRelationShipModal: false})}
          viewDataProvider={me.props.networkRef}
          visible={me.state.showMultiRelationShipModal}
        />
        {
          me.state.smartSearchUserByNode ? (
            <SmartSearchUserModal
              onClose={() => me.setState({showSmartSearchUserModal: false})}
              node={me.state.smartSearchUserByNode}
              viewDataProvider={me.props.networkRef}
              visible={me.state.showSmartSearchUserModal}
            />
          ) : null
        }

        {
          me.state.showNodeMatchNoticeModal ? (
            <Modal
              visible={me.state.showNodeMatchNoticeModal}
              onCancel={() => me.setState({showNodeMatchNoticeModal: false},
                me.state.noDecisionCallback)}
              title={'全新创建或沿用已有节点'}
              footer={[
                (
                  <Checkbox style={{marginRight: '8px',float:'left'}}
                    onClick={e => e.stopPropagation()}
                    onChange={e => me.onRememberOperationChanged(e)}
                    checked={me.state.rememberOperationState === true}
                  > 记住当前操作</Checkbox>
                ),
                (
                  <Button
                    key={'close'}
                    onClick={() => me.setState({showNodeMatchNoticeModal: false},
                      me.state.noDecisionCallback)}
                  >
                    取消
                  </Button>
                ),
                (
                  <Button
                    key={'create-new'}
                    onClick={() => {
                      me.setState({showNodeMatchNoticeModal: false},
                        me.state.useNewNodeCallback);
                    }}
                  >
                    全新创建
                  </Button>
                ),
                (
                  <Button
                    type={'primary'}
                    key={'use-original'}
                    onClick={() => {
                      me.setState({showNodeMatchNoticeModal: false},
                        me.state.useOriginalCallback);
                    }}
                    autoFocus
                  >
                    沿用已有
                  </Button>
                ),
              ]}
            >
              {
                me.state.matchedNodes.length === me.state.totalNodesToAdd ? (
                  <span>发现将要创建的 {me.state.totalNodesToAdd} 个节点{
                    me.state.matchedNodes.length > 1 ? '全部' : ''
                  }与图中已有节点相似{
                    me.state.matchedNodes.length === 1 ? '：' : '，例如：'
                  }</span>
                ) : (
                  <span>将要创建 {me.state.totalNodesToAdd} 个节点，发现其中 {
                    me.state.matchedNodes.length
                  } 个与图中已有节点相似{
                    me.state.matchedNodes.length === 1 ? '：' : '，例如：'
                  }</span>
                )
              }<br/>
              <br/>
              <span style={{padding: '0 1rem'}}>
                拟建：<span style={{fontWeight: 'bold'}}>{me.state.matchedNodes[0][0]}</span>
              </span><br/>
              <span style={{padding: '0 1rem'}}>
                已有：<span style={{fontWeight: 'bold'}}>{me.state.matchedNodes[0][1]}</span>
              </span><br/>
              <br/>
              希望全新创建还是沿用已有节点？<br/>
              {
                me.state.matchedNodes.length > 1 ? (
                  <span>注：上述 {me.state.matchedNodes.length} 个节点都将这样处理。</span>
                ) : null
              }
            </Modal>
          ) : null
        }
        {
          this.state.dataLoadingStatus === NetworkDataLoadingStatus.SUCCESS ? (
            <ViewWordCloudModal
              viewId={me.state.viewId}
              visible={me.state.showWordCloudModal}
              onClose={() => me.setState({showWordCloudModal: false})}
              viewDataProvider={me.props.networkRef}
            />
          ) : null
        }
        <ExtendedActionSettingsEditModal
          idx={me.state.extendedActionIdx}
          name={me.state.extendedActionName}
          description={me.state.extendedActionDescription}
          iconName={me.state.extendedActionIconName}
          iconType={me.state.extendedActionIconType}
          url={me.state.extendedActionUrl}
          userToken={me.state.extendedActionUserToken}
          bus={PB}
          visible={me.state.showExtendedActionSettingsModal}
          processing={me.state.extendedActionSettingsModalProcessing}
          viewId={me.state.viewId}
          nodeId={me.state.extendedActionRelatedNodeId}
          doClose={() => me.setState({showExtendedActionSettingsModal: false})}
        />

        <Context/>


        <BigArrow
          networkRef={this.myNetwork}
          areaTop={44}
        />

        <ImageLightBox/>

        {currentNode ? (
          <div
            id="nodeInfoDetailHidden"
            data-id={currentNode.id}
            data-fname={currentNode.fname}
            style={{visibility: 'hidden'}}
          />
        ) : null}
        {
          me.state.copyNodeToViewType>0 ? (
            <CopyNodeToViewModal
              onClose={() => me.setState({copyNodeToViewType: 0})}
              copyNodeToViewType={me.state.copyNodeToViewType}
              copyInfo={me.state.copyInfo}
              viewDataProvider={me.props.networkRef}
              visible={me.state.copyNodeToViewType>0}
              processing={me.state.copyNodeToViewModalProcessing}
            />
          ) : null
        }

        {/* 子弹评估 */}
        <NodeAssessModal userInfo={ me.props.userInfo} />
      </div>
    )
  }
}

Relation.defaultProps = {
  readonly: true,
  graphOnly: false,
};

Relation.propTypes = {
  networkRef: PropTypes.instanceOf(ViewDataProvider),
  readonly: PropTypes.bool,
  graphOnly: PropTypes.bool,
  userInfo: PropTypes.object,
  visionConfig: PropTypes.object,
};

export default Relation;
