import React from 'react';
import PropTypes from 'prop-types';
import {Modal, Button, message, Form, Select} from 'antd';

import PB, {SimplePB} from "@/libs/simplePB";

class NodeTodoAssignToModal extends React.Component {
  state = {
    assignTo: undefined,
    processing: false,
  };

  onAssignTo = () => {
    let me = this;

    if (!me.state.assignTo) {
      message.error('请先选择待办成员');
      return;
    }

    me.setState({processing: true}, () => {
      me.props.bus.emit('node_todo', 'todo.do_patch',
        {task: {nodeTaskId: me.props.taskId, assignTo: me.state.assignTo},
          cb: success => {me.setState({processing: false}, () => {success && me.props.onClose()})}});
    });
  };

  onClose = () => {
    let me = this;

    me.props.onClose();
  };

  render() {
    let me = this;
    const formItemLayout = {
      labelCol: { span: 7 },
      wrapperCol: { span: 17 },
    };

    return (
      <Modal
        title={'指定待办成员'}
        visible={me.props.visible}
        centered={true}
        onCancel={me.onClose}
        width={'22rem'}
        footer={[
          (<Button key={'cancel'} style={{marginRight: '8px'}} onClick={me.onClose}>取消</Button>),
          (<Button type={'primary'} key={'create'} style={{marginRight: '8px'}} onClick={me.onAssignTo}>确定</Button>),
        ]}
      >
        <Form.Item label={'待办成员'} {...formItemLayout} style={{marginBottom: 0}}>
          <Select
            value={me.state.assignTo}
            style={{width: '13rem'}}
            onChange={assignTo => {
              if (me.props.action !== 'view') {
                me.setState({assignTo});
              }
            }}
          >
            {me.props.memberList.map(member => (
              <Select.Option value={member.userId} key={`m-${member.userId}`}>{member.nick}</Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Modal>
    );
  }
}

NodeTodoAssignToModal.defaultProps = {
  bus: PB,
};

NodeTodoAssignToModal.propTypes = {
  bus: PropTypes.instanceOf(SimplePB),
  taskId: PropTypes.string,
  memberList: PropTypes.array.isRequired,
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default NodeTodoAssignToModal