import React from "react";
import PropTypes from 'prop-types';

import PB, {SimplePB} from "@/libs/simplePB";
import badgeInfoStyle from '@/style/components/mainView/main.badgeInfo.less';
import { Tooltip } from 'antd';
import moment from 'moment';
import UserDataProvider from '@/components/common/dataProvider/common.dataProvider.user';
import Icon from '@/components/common/common.icon';
import { getViewBadgeList } from '@/components/common/common.functions';
import { IconTypes } from '@/constants/common';
import TimeDisplayField from '@/components/common/common.timeDisplayField';
import AccountBadgeListModal from '@/components/common/common.account.badgeList';
import DigitConvertToIcons from '@/components/common/common.digitConvertToIcons';

class AccountActionsBadgeLatest extends React.PureComponent {
  state = {
    viewBadgeInfo: undefined, // 最新（最高）奖章信息
    viewBadgeTotal: 0, // 奖章总数量
    showMemberBadgeListModal: false, // 是否显示用户奖章奖励记录
  };

  // 获取图谱相关的用户奖章
  getViewBadgeInfo = () => {
    let me = this;

    UserDataProvider.getViewBadge({
      userId: me.props.userInfo.userId,
      orderBy: 'timestamp',
      limit: 0,
    }).then(res => {
      // console.log('MainBadgeInfo -> getViewBadge ->  res:', res);
      me.setState({
        viewBadgeInfo: res[0],
        viewBadgeTotal: res.length,
      });
    }).catch(() => {
      console.log(' 获取奖章信息失败 ');
    });
  };

  componentDidMount() {
    let me = this;
    // 获取图谱操作记录统计
    me.getViewBadgeInfo();

    me.props.bus.sub(me, 'badge', 'badgeList.do_show', (show) => {
      me.setState({showMemberBadgeListModal: !!show}, () => {
        PB.emit('account', 'badgeList.get');
      });
    })

  }

  componentWillUnmount() {
    this.props.bus.remove(this);
  }

  render() {
    let me = this;

    return (
      <React.Fragment>
        <Tooltip
          title={ me.state.viewBadgeInfo ? (
            <div style={{padding: '.3rem .5rem', lineHeight: '1.8'}}
                 onClick={e => {
                   e.stopPropagation();
                   me.setState({showMemberBadgeListModal: true}, () => {
                     PB.emit('account', 'badgeList.get');
                   });
                 }}
            >
              <div>奖名：{me.state.viewBadgeInfo.text}</div>
              <div>
                礼物：
                <Icon
                  name={getViewBadgeList()[me.state.viewBadgeInfo.type]['icon']}
                  type={IconTypes.ICON_FONT}
                />
              </div>
              <div>
                赠送人：
                {me.state.viewBadgeInfo['byUserNick'] || '--'}
              </div>
              <div>节点：{me.state.viewBadgeInfo.nodeFname}</div>
              <div>
                时间：
                <TimeDisplayField
                  timestamp={moment(me.state.viewBadgeInfo.timestamp, 'YYYY-MM-DD HH:mm:ss').valueOf()}/>
              </div>
            </div>
          ) : '暂无点赞记录'}
          placement={'topRight'}
        >
          <div className={`${badgeInfoStyle['badge-more-view']} ${badgeInfoStyle['account-action']} ${me.props.className ? me.props.className : ''}`}
               style={me.props.style}
               onClick={e => {
                 e.stopPropagation();
                 me.setState({showMemberBadgeListModal: true}, () => {
                   PB.emit('account', 'badgeList.get');
                 });
               }}
          >
            <Icon
              name={'icon-good'}
              type={IconTypes.ICON_FONT}
              className={badgeInfoStyle['icon']}
            />
            <DigitConvertToIcons digit={me.state.viewBadgeTotal}  />
          </div>
        </Tooltip>
        <AccountBadgeListModal
          onClose={() => me.setState({showMemberBadgeListModal: false})}
          visible={me.state.showMemberBadgeListModal}
          currentUserInfo={me.props.userInfo}
        />
      </React.Fragment>
    );
  }
}

AccountActionsBadgeLatest.defaultProps = {
  style: {},
  bus: PB,
};

AccountActionsBadgeLatest.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  bus: PropTypes.instanceOf(SimplePB),
  userInfo: PropTypes.object,
};

export default AccountActionsBadgeLatest;