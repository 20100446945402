import {bindUtil, withPromiseMessageDecorator} from "@/libs/core-decorators";
import messageProxy from '@/components/common/common.messageProxy';
import {getErrorInfo as commonGetErrorInfo} from "@/components/common/common.functions";
import {systemOperationMessages} from "@/constants/messages";
import {API_UploadTempFiles, API_UploadPublicFiles} from "@/api/system";

@bindUtil.asSourceClass
class SystemDataProvider {
  /**
   * 上传临时文件
   *
   * @param {File} file 要上传的文件对象
   * @param {Function} [progressCallback] 进度回调函数
   * @param {CancelToken} [cancelToken] 取消token
   *
   * @return {Promise}
   */
  static uploadTempFile = (file, progressCallback = undefined, cancelToken = undefined) => {
    const formData = new FormData();
    formData.append('files', file);
    return new Promise((resolve, reject) => {
      API_UploadTempFiles(formData, progressCallback, cancelToken).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve(response.data.data);
        } else {
          reject({...SystemDataProvider.getErrorInfo(response)});
        }
      }).catch(error => {
        reject({...SystemDataProvider.getErrorInfo(error)});
      });
    });
  };

  /**
   * 上传公开文件
   *
   * @param {File} file 要上传的文件对象
   * @param {Function} [progressCallback] 进度回调函数
   * @param {CancelToken} [cancelToken] 取消token
   *
   * @return {Promise}
   */
  static uploadPublicFile = (file, progressCallback = undefined, cancelToken = undefined) => {
    const formData = new FormData();
    formData.append('files', file);
    return new Promise((resolve, reject) => {
      API_UploadPublicFiles(formData, progressCallback, cancelToken).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve(response.data.data);
        } else {
          reject({...SystemDataProvider.getErrorInfo(response)});
        }
      }).catch(error => {
        reject({...SystemDataProvider.getErrorInfo(error)});
      });
    });
  };

  /**
   * @private
   * 通過Axios请求返回的错误信息获取错误详情
   *
   * @param {*} error
   *
   * @return {{msg: string, code: number}}
   */
  static getErrorInfo = commonGetErrorInfo;
}

const overrideMessages = {};

/**
 * @type {typeof SystemDataProvider}
 */
const SystemDataProviderWrapper = withPromiseMessageDecorator(
    messageProxy(systemOperationMessages, overrideMessages)
)(SystemDataProvider);

// noinspection JSUnusedGlobalSymbols
export const overrideNextMessage = (method, msg) => {
  overrideMessages[method] = msg;
};

export default SystemDataProviderWrapper;