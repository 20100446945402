import React from 'react';
import PropTypes from 'prop-types';
import { Button, Input, Tooltip, Badge } from 'antd';
import EventListener from 'react-event-listener';
import { Element as ScrollElement } from 'react-scroll';

import { IconTypes } from '@/constants/common';

import PB, { SimplePB } from '@/libs/simplePB';

import Icon from '@/components/common/common.icon';

import style from '@/style/common/common.stickyNodes.less';
import moment from 'moment';
import ViewManager from '@/libs/view/ViewManager';

class StickyNodes extends React.PureComponent {
	state = {
		translationX: 0,
		translationY: 0,
		translationW: 20,
		translationH: 12.8,
		focused: false, // 便签小窗口是否在聚焦状态
		inputFocused: false,
		textValue: '',
		textInfo: undefined, // 保存的数据对象
		status: 'active',
		show: false,
		loading: 'none',
		size: 1,
		fontSize: 1, // 默认字体大小 单位rem
		hasSave: false, // 内容有变化 ，需要保存
		hasUpdate: false, // 内容有变化，需要选择是否更新
	};

	element = undefined;

	moveBarElement = undefined;

	containerBoundingRect = undefined;

	dragging = false;

	lastX = undefined;

	lastY = undefined;

	rem = 14; // 默认

	initW = 20.5; // 初始宽度
	initH = 13; // 初始高度

	messageKey = undefined;  // aiConsole的key

	constructor(props) {
		super(props);

	}

	initRem = () => {
		const me = this;
		let documentWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

		if (documentWidth > 3999) {
			me.rem = 24;
		} else if (documentWidth > 1920) {
			me.rem = 18;
		} else {
			me.rem = 14;
		}
	};

	initElementXY = () => {
		const me = this;

		if (me.containerBoundingRect) return;
		if (!me.moveBarElement) return;
		if (!me.state.show) return;
		let moveBarElementBoundingClientRect = me.moveBarElement.getBoundingClientRect();
		let documentWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
		let documentHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

		me.containerBoundingRect = {
			// 浮层位置范围
			minTop: moveBarElementBoundingClientRect.height / 2 * 4.7,
			maxTop: documentHeight,
			minLeft: 0,
			maxLeft: documentWidth - moveBarElementBoundingClientRect.width,
			// 鼠标范围
			minX: 0,
			maxX: documentWidth,
			minY: moveBarElementBoundingClientRect.height / 2 * 2.6 + 1,
			maxY: documentHeight,

			// 移动条宽高
			moveBarWidth: moveBarElementBoundingClientRect.width,
			moveBarHeight: moveBarElementBoundingClientRect.height,
			// 文档宽高
			documentWidth,
			documentHeight,

			// 初始位置
			initLeft: documentWidth - me.initW * me.state.size * me.rem - me.initW * me.rem,
			initTop: 6 * me.rem,
		};

		me.setState({
			translationX: me.containerBoundingRect.initLeft,
			translationY: me.containerBoundingRect.initTop,
		});
	};

	onDragStart = e => {
		let me = this;
		e.stopPropagation();
		me.dragging = true;
	};

	onDragging = e => {
		let me = this;
		if (!me.dragging) return;

		let currentX = Math.max(me.containerBoundingRect.minX, Math.min(me.containerBoundingRect.maxX, e.clientX));
		let currentY = Math.max(me.containerBoundingRect.minY, Math.min(me.containerBoundingRect.maxY, e.clientY));
		if (me.lastX && me.lastY) {
			let dx = currentX - me.lastX,
				dy = currentY - me.lastY,
				currentLeft = me.state.translationX,
				currentTop = me.state.translationY,
				targetLeft = currentLeft + dx,
				targetTop = currentTop + dy,
				finalLeft = Math.max(me.containerBoundingRect.minLeft,
					Math.min(me.containerBoundingRect.maxLeft, targetLeft)),
				finalTop = Math.max(me.containerBoundingRect.minTop, Math.min(me.containerBoundingRect.maxTop, targetTop));
			me.setState({translationX: finalLeft, translationY: finalTop});
		}
		me.lastX = currentX;
		me.lastY = currentY;
	};

	onDragEnd = () => {
		let me = this;
		if (!me.dragging) return;
		me.dragging = false;
		me.lastX = undefined;
		me.lastY = undefined;
		me.forceUpdate();
	};

	/* 从服务器获取内容 */
	loadCrossViewConfig = () => {
		let me = this;
		ViewManager.getCrossViewConfig(`$.stickyNode`).then(res => {
			// console.log(' user.stickyNodes.get ->  getCrossViewConfig ->  res:', res );
			// me.onContentChange(me.state.textInfo, res.data);
			let cacheData = me.getStorageStickyNodes();
			let latestData = moment(cacheData.updateTime).valueOf() > moment(me.state.textInfo.updateTime) ? cacheData : me.state.textInfo;
			if (res.data) {
				latestData = moment(res.data.updateTime).valueOf() > moment(latestData.updateTime).valueOf() ? res.data : latestData;
			}
			me.setState({
				hasUpdate: false,
			});
			me.props.bus.emit('user', 'stickyNodes.hasChange', {hasUpdate: false, hasSave: me.state.hasSave});

			me.setStickyNodes(latestData);
			if (me.messageKey) {
				me.props.bus.emit('aiConsole', 'message.update', {
					key: me.messageKey,
					content: (
						<span>操作成功</span>
					),
				});
				me.messageKey = undefined;
			}
			// me.props.bus.emit('aiConsole', 'message.push', {type: 'ai', content: `获取便签数据成功，请注意保存内容！`});
		}).catch(({code, msg}) => {
			me.setState({
				loading: 'none',
			});
			me.props.bus.emit('aiConsole', 'message.push', {type: 'ai', content: `便签内容获取失败！`});
			console.log(`获取用户便签内容失败，code: ${code}, msg: ${msg}`);
		});
	};

	/* 更新服务器内容 */
	updateCrossViewConfig = (data) => {
		let me = this;
		me.setState({
			loading: 'save',
		}, () => {
			ViewManager.updateCrossViewConfig(`$.stickyNode`, data).then(res => {
				// console.log(' stickyNodes.show  -> updateCrossViewConfig -> res :', res);
				me.setState({
					hasSave: false,
					hasUpdate: false,
					loading: 'none',
					textInfo: data,
				});
				me.props.bus.emit('aiConsole', 'message.update', {
					key: me.messageKey,
					content: (
						<span>便签内容已更新</span>
					),
				});
				me.props.bus.emit('user', 'stickyNodes.hasChange', {hasUpdate: false, hasSave: false});
				me.props.bus.emit('aiConsole', 'message.push', {type: 'ai', content: `便签内容保存成功！`});

			}).catch(({code, msg}) => {
				me.setState({
					loading: 'none',
				});
				me.props.bus.emit('aiConsole', 'message.update', {
					key: me.messageKey,
					content: (
						<span>便签内容已更新</span>
					),
				});
				me.props.bus.emit('aiConsole', 'message.push', {type: 'ai', content: `便签内容保存失败，请稍后再试！`});
				console.log(`更新用户便签内容失败，code: ${code}, msg: ${msg}`);
			});
		});

	};

	/* 获取内容 */
	getStickyNodes = (needAsync = false) => {
		let me = this;
		let getData = me.getStorageStickyNodes();

		if (needAsync) {
			me.loadCrossViewConfig();
		} else {
			me.setState({
				textInfo: getData,
				fontSize: getData.fontSize,
				textValue: getData.text,
				loading: 'none',
			});
		}

	};

	/* 保存内容 */
	setStickyNodes = (data, needAsync = false) => {
		const me = this;
		let prevHasSave = me.state.hasSave;

		me.setState({
			textValue: data.text,
			fontSize: data.fontSize,
			hasSave: data.asyncTime !== data.updateTime,
			loading: 'none',
		}, () => {
			if (prevHasSave && !me.state.hasSave || !prevHasSave && me.state.hasSave) {
				me.props.bus.emit('user', 'stickyNodes.hasChange', {hasSave: me.state.hasSave, hasUpdate: me.state.hasUpdate});
			}
		});

		if (needAsync) {
			data.asyncTime = data.updateTime;
			me.setStorageStickyNodes(data);
			me.updateCrossViewConfig(data);
		} else {
			me.setState({textInfo: data});
			me.setStorageStickyNodes(data);
		}
	};

	getStorageStickyNodes = () => {
		const me = this;
		let getData = localStorage.getItem(`userStickyNodes-${me.props.currentUserInfo.userId}`);
		if (getData && getData[0] === '{' && getData[getData.length - 1] === '}') {
			getData = JSON.parse(getData);
		} else if (getData) {
			getData = {
				userId: me.props.currentUserInfo.userId,
				name: '便签记事本',
				text: `${getData}`,
				updateTime: moment().format('MM-DD-YYYY HH:mm:ss'),
				asyncTime: moment().format('MM-DD-YYYY HH:mm:ss'),
				fontSize: me.state.fontSize,
			};
		} else {
			getData = {
				userId: me.props.currentUserInfo.userId,
				name: '便签记事本',
				text: '',
				updateTime: moment().format('MM-DD-YYYY HH:mm:ss'),
				asyncTime: moment().format('MM-DD-YYYY HH:mm:ss'),
				fontSize: 1,
			};
		}
		return getData;
	};

	setStorageStickyNodes = (data) => {
		const me = this;
		localStorage.setItem(`userStickyNodes-${me.props.currentUserInfo.userId}`, JSON.stringify(data));
	};

	/* 刷新 */
	onRefresh = (needAsync = false) => {
		const me = this;
		if (needAsync) {
			me.setState({
				loading: 'refresh',
			});
			me.getStickyNodes(true);
		} else {
			me.getStickyNodes();
		}
	};

	/* 改变窗口大小 */
	onResize = (size) => {
		const me = this;
		// let size = me.state.size > 3 ? 1 : me.state.size + 1;
		let width = me.initW * size * me.rem;
		let height = me.initH * size * me.rem;

		me.setState({
			size: size,
			translationX: me.state.translationX + width > me.containerBoundingRect.documentWidth ? me.containerBoundingRect.documentWidth - width - 5 : (size === 1 ? me.containerBoundingRect.initLeft : me.state.translationX),
			translationY: me.state.translationY + height > me.containerBoundingRect.documentHeight ? me.containerBoundingRect.documentHeight - height + 3 : (size === 1 ? me.containerBoundingRect.initTop : me.state.translationY),
			translationW: me.initW * size,
			translationH: me.initH * size,
		});
	};

	/* 改变显示大字体大小 */
	onFontSize = (size) => {
		const me = this;
		let data = {
			...me.state.textInfo,
			fontSize: size,
			updateTime: moment().format('YYYY-MM-DD HH:mm:ss'),
		};
		me.setStickyNodes(data);
	};

	/* 文本框内容改变 */
	onTextChange = e => {
		const me = this;
		const {value} = e.target;
		e.stopPropagation();
		let data = {
			...me.state.textInfo,
			text: value,
			updateTime: moment().format('YYYY-MM-DD HH:mm:ss'),
		};
		me.setStickyNodes(data);
	};

	/* 文本框聚焦，检查是否有内容变化  */
	onTextFocus = e => {
		const me = this;
		e.stopPropagation();
		me.setState({
			inputFocused: true,
		}, () => {
			let getData = me.getStorageStickyNodes();
			me.onContentChange(me.state.textInfo, getData);
		});
	};

	/* 文本框失去焦点 */
	onTextBlur = e => {
		const me = this;
		e.stopPropagation();
		me.setState({
			inputFocused: false,
		});
	};

	/* 判断内容是否有变化，询问是否更新 */
	onContentChange = (dataOne, dataTwo) => {
		const me = this;
		if (dataOne.updateTime !== dataTwo.updateTime) {
			let prevData, nextData;
			if (moment(dataTwo.updateTime).valueOf() > moment(dataOne.updateTime).valueOf()) {
				prevData = dataOne;
				nextData = dataTwo;
			} else {
				prevData = dataTwo;
				nextData = dataOne;
			}

			me.setState({
				hasUpdate: true,
			});
			me.props.bus.emit('user', 'stickyNodes.hasChange', {hasUpdate: true, hasSave: me.state.hasSave});

			if (me.messageKey) {
				me.props.bus.emit('aiConsole', `message.update`, {
					key: me.messageKey,
					content: me.aiConsoleMessage(prevData, nextData),
				});
			} else {
				me.props.bus.emit('aiConsole', `message.push`,{
					type: 'ai',
					content: me.aiConsoleMessage(prevData, nextData),
					callback: ({key}) => me.messageKey = key,
				});
			}
		} else {
			me.setState({
				loading: 'none',
			})
		}
	};

	aiConsoleMessage = (prevData, nextData) => {
		const me = this;
		return (
			<div>
				便签内容有变化，是否更新？
				<div style={{textAlign: 'right', marginTop: '1rem'}}>
					<a
						style={{marginRight: '1rem'}}
						className={'plain-action'}
						onClick={() => {
							me.setState({
								hasUpdate: false,
							});
							me.props.bus.emit('user', 'stickyNodes.hasChange', {hasUpdate: false, hasSave: me.state.hasSave});

							me.props.bus.emit('aiConsole', 'message.update', {
								key: me.messageKey,
								content: (
									<span>操作成功</span>
								),
							});
							me.setStickyNodes(prevData);
							// me.props.bus.emit('aiConsole', 'message.push', {type: 'user', content: `保留便签当前内容`});
							me.messageKey = undefined;
						}}
					> 取消 </a>
					<a
						className={'plain-action'}
						onClick={() => {
							me.setState({
								hasUpdate: false,
							});
							me.props.bus.emit('user', 'stickyNodes.hasChange', {hasUpdate: false, hasSave: me.state.hasSave});

							let cacheData = me.getStorageStickyNodes();
							me.setStickyNodes(cacheData);
							// me.props.bus.emit('aiConsole', 'message.push', {type: 'user', content: `获取便签最新内容`});
							me.props.bus.emit('aiConsole', 'message.update', {
								key: me.messageKey,
								content: (
									<span>操作成功</span>
								),
							});
							me.messageKey = undefined;
						}}
					> 更新 </a>
				</div>
			</div>
		)
	};

	/* 关闭小窗口 */
	onClose = () => {
		const me = this;
		me.props.bus.emit('user', 'stickyNodes.switch', {show: false});
	};

	componentDidMount() {
		let me = this;

		// 初始化rem的值
		me.initRem();

		me.props.bus.sub(this, 'user', 'stickyNodes.switch', ({show, forceUpdate = true}) => {
			const me = this;
			if (!me.state.show && show) {
				me.setState({show}, () => {
					me.onRefresh();
					if (me.containerBoundingRect) {
						me.setState({
							translationX: me.containerBoundingRect.initLeft,
							translationY: me.containerBoundingRect.initTop,
						});
					} else {
						requestAnimationFrame(() => me.initElementXY());
					}

					me.props.bus.emit('user', 'stickyNodes.show', {show});
				});
			} else {
				if (forceUpdate) {
					// asyncTime 和 updateTime都是同样格式时间，可以直接比较是否相同
					if (!show && me.state.textInfo && (me.state.textInfo.asyncTime !== me.state.textInfo.updateTime)) {
						me.props.bus.emit('aiConsole', 'message.push', {
							type: 'ai',
							content: (<div>
								便签内容有变化，是否需要保存数据？
								<div style={{textAlign: 'right', marginTop: '1rem'}}>
									<a
										style={{marginRight: '1rem'}}
										className={'plain-action'}
										onClick={() => {
											me.props.bus.emit('aiConsole', 'message.update', {
												key: me.messageKey,
												content: (
													<span>操作成功</span>
												),
											});
											me.setState({
												show,
												focused: show ? me.state.focused : false,
											});
											me.props.bus.emit('user', 'stickyNodes.show', {show});
											// me.props.bus.emit('aiConsole', 'message.push', {type: 'user', content: `保留便签当前内容`});
											me.messageKey = undefined;
										}}
									> 取消 </a>
									<a
										className={'plain-action'}
										onClick={() => {
											me.props.bus.emit('aiConsole', 'message.update', {
												key: me.messageKey,
												content: (
													<span>操作成功</span>
												),
											});
											me.setStickyNodes(me.state.textInfo, true);
											// me.props.bus.emit('aiConsole', 'message.push', {type: 'user', content: `保存便签内容成功`});
											me.messageKey = undefined;
										}}
									> 保存 </a>
								</div>
							</div>),
							callback: ({key}) => me.messageKey = key,
							delay: 200,
						});
					} else {
						me.setState({
							show,
							focused: show ? me.state.focused : false,
						}, () => {
							me.props.bus.emit('user', 'stickyNodes.show', {show});
						});
					}

				} else {
					setTimeout(() => {
						if (me.state.show && !show && !me.state.focused) {
							me.setState({
								show,
								focused: show ? me.state.focused : false,
							});
							me.props.bus.emit('user', 'stickyNodes.show', {show});
						}
					}, 500);
				}
			}
		});
	}

	componentWillUnmount() {
		this.props.bus.remove(this);
	}

	render() {
		let me = this;

		return (
			<div
				id={'stickyNodesFrame'}
				className={`dark-theme ${style['sticky-nodes-frame']} ${me.state.status}`}
				style={{
					left: `${me.state.translationX}px`,
					top: `${me.state.translationY}px`,
					width: `${me.state.translationW * me.rem}px`,
					height: `${me.state.translationH * me.rem}px`,
				}}
				hidden={!me.state.show}
				ref={ele => {
					if (!ele || ele === me.element) return;
					me.element = ele;
				}}
				onMouseEnter={e => {
					e.stopPropagation();
					me.setState({focused: true})
				}}
			>
				<EventListener
					target={window}
					onMouseMove={e => me.onDragging(e)}
					onMouseUp={() => me.onDragEnd()}
				/>
				<div
					className={`${style['draggable-btn']} ${me.dragging ? 'dragging' : ''}`}
					style={{
						width: `${me.state.translationW * me.rem}px`,
					}}
					onMouseDown={e => me.onDragStart(e)}
					onMouseUp={() => me.onDragEnd()}
					ref={ele => {
						if (!ele || ele === me.moveBarElement) return;
						me.moveBarElement = ele;
						me.initElementXY();
					}}
				>
					<Icon name={'icon-move'} theme="outlined" type={IconTypes.ICON_FONT} style={{marginRight: '0.5em'}}/>
					按住拖动
					<span style={{right: 0, position: 'absolute'}}>
            <Tooltip
	            title={<div className={style['control-list']}>
		            <div className={style['control-item']} style={{opacity: me.state.fontSize === 1 ? '0.5' : '1'}} onClick={() => me.onFontSize(1)}>默认字体</div>
		            <div className={style['control-item']} style={{opacity: me.state.fontSize === 2 ? '0.5' : '1'}} onClick={() => me.onFontSize(2)}>中号字体</div>
		            <div className={style['control-item']} style={{opacity: me.state.fontSize === 3 ? '0.5' : '1'}} onClick={() => me.onFontSize(3)}>大号字体</div>
		            <div className={style['control-item']} style={{opacity: me.state.fontSize === 4 ? '0.5' : '1'}} onClick={() => me.onFontSize(4)}>超大字体</div>
	            </div>}
	            placement={'bottom'}
	            getPopupContainer={() => document.getElementById('stickyNodesFrame')}
            >
            <Button
	            className={'ant-btn-icon-only'}
	            onClick={e => {
		            e.stopPropagation();
	            }}
	            onMouseDown={e => e.stopPropagation()}
            >
	            <Icon type={IconTypes.ICON_FONT} name={'icon-font-size-large'} style={{fontSize: '1.3rem'}}/>
            </Button>
	          </Tooltip>
	          <Tooltip
		          title={<div className={style['control-list']}>
			          <div className={style['control-item']} style={{opacity: me.state.size === 1 ? '0.5' : '1'}} onClick={() => me.onResize(1)}>默认窗口</div>
			          <div className={style['control-item']} style={{opacity: me.state.size === 2 ? '0.5' : '1'}} onClick={() => me.onResize(2)}>二倍大小</div>
			          <div className={style['control-item']} style={{opacity: me.state.size === 3 ? '0.5' : '1'}} onClick={() => me.onResize(3)}>三倍大小</div>
			          <div className={style['control-item']} style={{opacity: me.state.size === 4 ? '0.5' : '1'}} onClick={() => me.onResize(4)}>四倍大小</div>
		          </div>}
		          placement={'bottom'}
		          getPopupContainer={() => document.getElementById('stickyNodesFrame')}
	          >
	            <Button
		            className={'ant-btn-icon-only'}
		            onClick={e => {
			            e.stopPropagation();
		            }}
		            onMouseDown={e => e.stopPropagation()}
	            >
	              <Icon type={IconTypes.ICON_FONT} name={'icon-enlarge'}/>
	            </Button>
	          </Tooltip>
	          <Tooltip title={'刷新'}
	          >
	            <Button
		            className={'ant-btn-icon-only'}
		            onClick={e => {
			            e.stopPropagation();
			            me.onRefresh(true);
		            }}
		            onMouseDown={e => e.stopPropagation()}
	            >

	              {
		              me.state.loading === 'refresh' ? (
			              <Icon name="loading"/>
		              ) : (
			              <Icon name="sync"/>
		              )
	              }
	            </Button>
	          </Tooltip>
						<Tooltip title={
							me.state.hasUpdate ? (
								'内容有变化，请在AI对话框中进行选择操作'
							) : me.state.hasSave ? (
								'内容有变化，请注意保存'
							) : '保存'
						}>
	            <Button
		            className={'ant-btn-icon-only'}
		            onClick={e => {
			            e.stopPropagation();
			            me.setStickyNodes(me.state.textInfo, true);
		            }}
		            onMouseDown={e => e.stopPropagation()}
	            >
		            <Badge dot={me.state.hasSave || me.state.hasUpdate}>
			            {
				            me.state.loading === 'save' ? (
					            <Icon name="loading"/>
				            ) : (
					            <Icon name="save" style={{fontSize: '1rem'}}/>
				            )
			            }
		            </Badge>
	            </Button>
	          </Tooltip>
            <Tooltip title={'关闭便签'}>
              <Button
	              className={'ant-btn-icon-only'}
	              onClick={() => me.onClose()}
	              onMouseDown={e => e.stopPropagation()}
              >
                <Icon name={'close'}/>
              </Button>
            </Tooltip>
          </span>
				</div>
				<ScrollElement
					className={`scrollbar-none ${style['console-frame']} ${me.state.show ? 'focused' : ''}`}
					style={{
						width: `${me.state.translationW * me.rem}px`,
						height: `${(me.state.translationH - 2) * me.rem}px`,
						overflow: 'hidden auto',
					}}
					onScroll={e => {
						// let element = e.target;
					}}
				>
					<Input.TextArea
						className={`scrollbar-none ${style['textarea-input']}`}
						style={{fontSize: `${me.state.fontSize * 0.4 + 0.5}rem`}}
						placeholder={'便签记事本：随时记录待办事项、重要会议，日常行程等，数据永久保存，手机电脑同步。帮助您备份数据、整合碎片信息；Tips：清理缓存后，请刷新页面重新获取内容。'}
						value={me.state.textValue}
						onChange={e => me.onTextChange(e)}
						onFocus={e => me.onTextFocus(e)}
						onBlur={e => me.onTextBlur(e)}
					/>
				</ScrollElement>
			</div>
		);
	}
}

StickyNodes.defaultProps = {
	bus: PB,
	currentUserInfo: {userId: parseInt(localStorage.getItem('userId'))},
};

StickyNodes.propTypes = {
	bus: PropTypes.instanceOf(SimplePB),
	currentUserInfo: PropTypes.object,
};

export default StickyNodes;