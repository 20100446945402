import React from 'react';
import PropTypes from "prop-types";
import EventListener from "react-event-listener";

import PB from '@/libs/simplePB';
import {EdgeEvents, NodeEvents, REMOVE_FROM_GRAPH} from "@/libs/view/network/events";

import {IconTypes} from "@/constants/common";

import NodeInfoPanel from '@/components/mainView/nodeInfo/main.nodeInfo';
import EdgeInfoPanel from "@/components/mainView/main.edgeInfo";
import ViewDataProvider from '@/components/common/dataProvider/common.dataProvider.view';
import BriefingInfoPanel from "@/components/common/relation/common.relation.briefingInfo";
import ExploreRelationPlugin from "@/components/exploreRelationView/exploreRelation.plugin";
import Icon from "@/components/common/common.icon";

import style from '@/style/components/main.infoboard.less';

class InfoBoard extends React.Component {
  state = {
    comment: '',
    show: false, // 面板显示状态
    selectedNode: undefined, // 选中的点
    connectedNodes: [], // 连接节点列表
    connectedNodesCount: 0, // 连接节点数量
    viewId: undefined,
    fileList: [],

    right: 0,
    top: 0,
  };

  moveBarElement = undefined;

  containerBoundingRect = undefined;

  dragging = false;

  lastX = undefined;

  lastY = undefined;

  componentDidMount() {
    let me = this;
    this.setState({viewId: sessionStorage.getItem("currentViewId")});
    if (me.props.networkRef) {
      me.props.networkRef.subscribe(me, EdgeEvents.REMOVED, (type, edgeInfoList) => {
        // noinspection JSBitwiseOperatorUsage
        if (me.state.selectedEdge && (type & REMOVE_FROM_GRAPH)) {
          edgeInfoList.forEach(({from, to}) => {
            if ((me.state.selectedEdge.from === from && me.state.selectedEdge.to === to) ||
              (me.state.selectedEdge.from === to && me.state.selectedEdge.to === from)) {

              me.setState({
                selectedNode: undefined,
                selectedEdge: undefined,
                connectedNodes: [],
                show: false,
              });
            }
          });
        }
      }).subscribe(me, NodeEvents.REMOVED, (type, nodeInfoList) => {
        // noinspection JSBitwiseOperatorUsage
        if (me.state.selectedNode && (type & REMOVE_FROM_GRAPH)) {
          nodeInfoList.forEach(node => {
            if (me.state.selectedNode.id === node.id) {
              this.setState({
                selectedNode: undefined,
                selectedEdge: undefined,
                connectedNodes: [],
                show: false,
              });
            }
          });
        }
      });
    }
    PB.sub(this, "network", "selectedNode", (result) => {
      console.log("main.infoBoard.js-> 接受到点击的result：", result);
      // const select = this.state.selectedNode
      if (result.node) {
        let connectedNodesTemp = []; // 连接节点
        if (result.connectedNodes) {
          connectedNodesTemp = result.connectedNodes
        }
        // 赋值并刷新页面
        this.setState({
          selectedNode: result.node,
          selectedEdge: undefined,
          connectedNodes: connectedNodesTemp,
          connectedNodesCount: connectedNodesTemp.length,
          show: true,
        });
      } else {
        this.setState({
          selectedNode: undefined,
          selectedEdge: undefined,
          connectedNodes: [],
          show: false,
        });
      }
    });
    // 监听选中边
    PB.sub(this, "network", "selectedEdge", (result) => {
      console.log('infoBoard 接受到选中边', result);
      // 连接节点
      let connectedNodesTemp = result.connectedNodes || [];
      // 赋值并刷新页面
      this.setState({
        selectedEdge: result.edge,
        selectedNode: undefined,
        connectedNodes: connectedNodesTemp,
        connectedNodesCount: connectedNodesTemp.length,
        show: true,
      });
    });
  }

  componentWillUnmount() {
    PB.remove(this);
    this.props.networkRef && this.props.networkRef.unSubscribe(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let me = this;

    if (me.state.show || me.props.showBriefingInfo) {
      requestAnimationFrame(() => me.initElementXY());
    }
  }

  initElementXY = () => {
    let me = this;

    if (me.containerBoundingRect) return;
    if (!me.moveBarElement) return;
    if (!me.state.show && !me.props.showBriefingInfo) return;

    let moveBarElementBoundingClientRect = me.moveBarElement.getBoundingClientRect();
    let documentWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    let documentHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    me.containerBoundingRect = {
      // 浮层位置范围
      minTop: 0,
      maxTop: documentHeight - moveBarElementBoundingClientRect.height - moveBarElementBoundingClientRect.top,
      minRight: 0,
      maxRight: documentWidth - moveBarElementBoundingClientRect.width,
      // 鼠标范围
      minX: 0,
      maxX: documentWidth,
      minY: moveBarElementBoundingClientRect.top,
      maxY: documentHeight,
    };
  };

  onDragStart = e => {
    e.stopPropagation();
    this.dragging = true;
  };

  onDragging = e => {
    let me = this;
    if (!me.dragging) return;
    let currentX = Math.max(me.containerBoundingRect.minX, Math.min(me.containerBoundingRect.maxX, e.clientX));
    let currentY = Math.max(me.containerBoundingRect.minY, Math.min(me.containerBoundingRect.maxY, e.clientY));
    if (me.lastX && me.lastY) {
      let dx = currentX - me.lastX,
        dy = currentY - me.lastY,
        currentRight = me.state.right,
        currentTop = me.state.top,
        targetRight = currentRight - dx,
        targetTop = currentTop + dy,
        finalRight = Math.max(me.containerBoundingRect.minRight,
          Math.min(me.containerBoundingRect.maxRight, targetRight)),
        finalTop = Math.max(me.containerBoundingRect.minTop, Math.min(me.containerBoundingRect.maxTop, targetTop));
      me.setState({right: finalRight, top: finalTop});
    }
    me.lastX = currentX;
    me.lastY = currentY;
  };

  onDragEnd = () => {
    let me = this;

    if (!me.dragging) return;
    me.dragging = false;
    me.lastX = undefined;
    me.lastY = undefined;
    me.forceUpdate();
  };

  render() {
    let me = this;

    const rightC = {right: "1px"};
    return (
      <div
        className={this.props.className + ' ' + style['info-board-inside']}
        style={rightC}
        hidden={!this.state.show && !this.props.showBriefingInfo}
      >
        <EventListener
          target={window}
          onMouseMove={e => me.onDragging(e)}
          onMouseUp={() => me.onDragEnd()}
        />
        {/* 信息板主体 */}
        <div className={style["board-body"]}>
          <div
            className={style['draggable-frame']}
            style={{
              position: 'absolute',
              right: `${this.state.right}px`,
              top: `${this.state.top}px`,
            }}
          >
            <div
              className={`${style['draggable-btn']} ${me.dragging ? 'dragging' : ''}`}
              onMouseDown={e => me.onDragStart(e)}
              onMouseUp={() => me.onDragEnd()}
              ref={ele => {
                if (!ele || ele === me.moveBarElement) return;
                me.moveBarElement = ele;
                me.initElementXY();
              }}
            >
              <Icon name={'icon-move'} theme="outlined" type={IconTypes.ICON_FONT} style={{marginRight: '0.5em'}}/>
              按住拖动
            </div>
            {
              this.state.selectedNode && this.state.selectedNode.id ? (
                <NodeInfoPanel
                  userInfo={this.props.userInfo}
                  nodeId={this.state.selectedNode.id}
                  getNodeInfo={this.props.getNodeInfo}
                  getNodeConnectedInfo={this.props.getNodeConnectedInfo}
                  networkRef={this.props.networkRef}
                  readonly={this.props.readonly}
                  showAssistant={this.props.showAssistant}
                  ignoreLoadError={this.props.ignoreLoadError}
                  originalViewId={this.props.originalViewId}
                />
              ) : null
            }
            {
              this.state.selectedEdge && this.state.selectedEdge.id ? (
                <EdgeInfoPanel
                  edgeId={this.state.selectedEdge.id}
                  getEdgeInfo={this.props.getEdgeInfo}
                  getEdgeConnectedNodes={this.props.getEdgeConnectedNodes}
                  networkRef={this.props.networkRef}
                  readonly={this.props.readonly}
                  ignoreLoadError={this.props.ignoreLoadError}
                />
              ) : null
            }
            {
              this.props.showBriefingInfo ? (
                <BriefingInfoPanel
                  nodes={this.props.briefingInfoRelatedNodes}
                />
              ) : null
            }
          </div>
          <ExploreRelationPlugin bus={PB} srcNodeId={this.props.srcNodeId}/>
        </div>
      </div>
    )
  }
}

InfoBoard.defaultProps = {
  readonly: false,
  getNodeInfo: () => undefined,
  getNodeConnectedInfo: () => undefined,
  getEdgeInfo: () => undefined,
  getEdgeConnectedNodes: () => [],
  ignoreLoadError: false,
  showBriefingInfo: false,
  showAssistant: false,
  initialTop: 0,
  initialRight: 0,
};

InfoBoard.propTypes = {
  userInfo: PropTypes.object,
  readonly: PropTypes.bool,
  className: PropTypes.string,
  networkRef: PropTypes.instanceOf(ViewDataProvider),
  getNodeInfo: PropTypes.func,
  getNodeConnectedInfo: PropTypes.func,
  getEdgeInfo: PropTypes.func,
  getEdgeConnectedNodes: PropTypes.func,
  ignoreLoadError: PropTypes.bool,
  showBriefingInfo: PropTypes.bool,
  briefingInfoRelatedNodes: PropTypes.array,
  originalViewId: PropTypes.string,
  srcNodeId: PropTypes.string,
  showAssistant: PropTypes.bool,
  initialTop: PropTypes.number, // 初始top值
  initialRight: PropTypes.number, // 初始right值
};

export default InfoBoard;
