import React, {Suspense} from 'react';
import {Table, Tooltip, message} from "antd";
import _ from "lodash";

import RelationSearch from "@/components/common/relation/common.relation.search";
import VisualToolMini from "@/components/common/relation/common.relation.visualToolMini";
import {
  getNodeDisplayTitle,
  EDGE_TYPE_OUTLINE,
  TYPE_FIELD_NAME,
  NODE_TYPE_TEXT,
  NODE_TYPE_TALENT,
} from "@/constants/vis.defaultDefine.1";
import {
  iconConfig,
  ICON_CATEGORY_COMPANY,
  ICON_CATEGORY_TALENT,
  ICON_CATEGORY_PATENT,
  ICON_CATEGORY_PAPER,
  ICON_CATEGORY_ORG,
  ICON_CATEGORY_INSTITUTE,
  ICON_CATEGORY_DATASET,
  ICON_CATEGORY_GOV,
  ICON_CATEGORY_COLLEGE_AND_UNIVERSITY,
  ICON_CATEGORY_PARK,
} from "@/constants/iconConfig";
import MyNetwork from '@/libs/myVis/MyNetwork3';
import Node from '@/libs/view/Node';
import Edge from '@/libs/view/Edge';

import style from '@/style/containers/detailRelationView.less';
import PB from "@/libs/simplePB";
import Icon from "@/components/common/common.icon";
import {IconTypes} from "@/constants/common";
import styleMainView from "@/style/containers/mainView.1.0.1.less";
import InfoBoard from '@/components/mainView/main.infoBoard';
import LightBox from "react-image-lightbox";
import TimelinePanel from "@/components/detailRelationView/detailRelation.timeline";
import MapPanel from "@/components/detailRelationView/detailRelation.map";
import PropTypes from "prop-types";
import NodeDataProvider from "@/components/common/dataProvider/common.dataProvider.node";

/*const getPoint = (r, nodes, {x, y} = {x: 0, y: 0}) => {
  let count = nodes.length;
  let radians = (Math.PI / 180) * Math.round(360 / count); //弧度
  let i = 0;
  for (; i < count; i++) {
    nodes[i].x = x + r * Math.sin(radians * i + Math.PI);
    nodes[i].y = y + r * Math.cos(radians * i + Math.PI);
    nodes[i].fixed = true;
  }

  return nodes;
};*/

const typeIdPrefix = 'node_category_';

/*const testImages = [{
  title: '中国卒中学会重症脑血管病分会第一届学术年会',
  src: 'http://www.365heart.com//meeting/xinjian/zgzzzz2016/%E4%B8%93%E5%AE%B6%E4%B8%AA%E4%BA%BA/%E5%88%98%E4%B8%BD%E8%90%8D/zgzzzz2016-0000022.JPG',
  link: 'http://www.365heart.com/show/showpic.asp?uid=147185',
}, {
  title: '中国卒中学会重症脑血管病分会第一届学术年会',
  src: 'http://www.365heart.com//meeting/xinjian/zgzzzz2016/%E4%B8%93%E5%AE%B6%E4%B8%AA%E4%BA%BA/%E5%88%98%E4%B8%BD%E8%90%8D/zgzzzz2016-0000021.JPG',
  link: 'http://www.365heart.com/show/showpic.asp?uid=147185',
}, {
  title: '中国卒中学会重症脑血管病分会第一届学术年会',
  src: 'http://www.365heart.com//meeting/xinjian/zgzzzz2016/%E4%B8%93%E5%AE%B6%E5%90%88%E5%BD%B1/zgzzzz2016-0000034.JPG',
  link: 'http://www.365heart.com/show/showpic.asp?uid=147185',
}, {
  title: '2016中国脑卒中大会',
  src: 'http://www.365heart.com//meeting/xinjian/zgnzzdh2016/%E4%B8%93%E5%AE%B6%E5%90%88%E5%BD%B1/zgnzzdh2016-0000816.JPG',
  link: 'http://www.365heart.com/show/showpic.asp?uid=147185',
}, {
  title: '2016中国脑卒中大会',
  src: 'http://www.365heart.com//meeting/xinjian/zgnzzdh2016/%E4%B8%93%E5%AE%B6%E5%90%88%E5%BD%B1/zgnzzdh2016-0000811.JPG',
  link: 'http://www.365heart.com/show/showpic.asp?uid=147185',
}];*/

// TODO 属性名称对应数组，暂时放在这里
const propertyNames = {
  birth: '出生日期',
  degree: '文化程度',
  gender: '性别',
  name: '姓名',
  org_set: '相关单位',
  origin: '籍贯',
  post: '职位',
  tags: '专业领域',
  title: '头衔',
  school: '毕业院校',
  honor: '奖项荣誉',
};

// TODO 资源属性排序方案，暂时放在这里
const propertyOrderMap = {
  [NODE_TYPE_TALENT]: ['name', 'gender', 'origin', 'birth', 'degree', 'school', 'tags', 'post', 'title', 'honor', 'org_set'],
};

const propertyColumns = [{
  title: '属性名',
  dataIndex: 'key',
  key: 'key',
  className: style['property-key'],
  render: text => <span>{propertyNames[text] || text}</span>,
}, {
  title: '属性值',
  dataIndex: 'value',
  key: 'value',
  className: style['property-value'],
  render: (text, record) => {
    const value = record.value;
    if (value === undefined) {
      return <span><Icon name={'loading'} style={{marginRight: '0.5rem'}}/>后台计算中…</span>;
    } else if (!value || (_.isArray(value) && value.length <= 0)) {
      return (
        <Tooltip
          placement={'top'}
          title={'后台计算可能长达1小时'}
          overlayClassName={'dark-theme'}
        >
          <span><Icon name={'info-circle'} style={{opacity: 0.6, cursor: 'pointer'}} /></span>
        </Tooltip>
      );
    } else if (_.isArray(value)) {
      return (
        <ul>
          {
            record.value.map((v, idx) => (
              <li key={`li-${idx}`}><span>{`${v}`}</span></li>
            ))
          }
        </ul>
      );
    } else {
      return <span>{value}</span>;
    }
  },
}];

class MainView extends React.Component {
  containerId = 'detail-relation-network-' + Math.random();
  container = undefined;
  relationData = undefined;
  imageData = undefined;
  propertyData = undefined;
  resourceData = undefined;

  state = {
    showMoreChart: false,
    showLightBox: -1,
    showTimeline: false,
    showChinaADC: false,
    // 是否展示关联关系简报列表
    showBriefingInfoList: false,
    // 与关联关系简报相关的用户选择节点
    briefingInfoRelatedNode: undefined,
  };

  /**
   * @type {MyNetwork|undefined}
   */
  myNetwork = undefined;

  /**
   * @type {Node|undefined}
   */
  nodeInfo = undefined;

  componentDidMount() {
    let me = this;
    me.container = document.getElementById(me.containerId);
    me.propertyData = [];
    // noinspection SpellCheckingInspection
    me.myNetwork = new MyNetwork(me.container, {nodes: [], edges: []}, {});
    me.relationData = me.myNetwork.graph;
    me.resourceData = [];

    const networkCanvas = me.container.getElementsByTagName("canvas")[0];

    // 自定义事件
    const myEvents = {
      'click': (params) => {
        me.myNetwork.network.stopSimulation();
        me.setState({
          showBriefingInfoList: false,
          briefingInfoRelatedNode: undefined,
        }, () => {
          if (params.nodes.length === 1) { // 点击一个节点
            // 观察者：触发节点点击
            PB.emit("network", "selectedNode", {node: me.relationData.nodes.get(params.nodes[0]), connectedNodes: {}});
            // 用于训练菜单
            PB.emit('relation', 'node.single_selection_change', params.nodes[0]);
            // 返回，不走外面的逻辑
            return;
          } else if (params.edges.length === 1) { // 点击一条边
            try {
              // 获取边信息
              let edge = me.relationData.edges.get(params.edges[0]);
              // 如果是有详情的边，展示关联关系列表
              if (edge.type !== EDGE_TYPE_OUTLINE) {
                // 获取节点两端节点，并找到与关联关系简报相关的用户选择节点（资源节点）
                let node = me.relationData.nodes.get([edge.from, edge.to])
                  .filter(node => node[TYPE_FIELD_NAME] !== NODE_TYPE_TEXT)[0];
                me.setState({
                  showBriefingInfoList: true,
                  briefingInfoRelatedNode: node,
                });
              }
            } catch (e) {
              // 记录错误并忽略
              console.log('Network click event error on edge: ', e);
            }
            // 继续走完外面的逻辑
          }
          // 观察者：触发节点点击
          PB.emit("network", "selectedNode", {node: undefined, connectedNodes: {}});
        });
      },
      'hoverEdge': ({edge: edgeId}) => {
        let edge = me.relationData.edges.get(edgeId);
        if (edge.type === EDGE_TYPE_OUTLINE) return;
        edge.label = '…';
        me.myNetwork.updateEdges([edge]);
        networkCanvas.style.cursor = 'pointer';
      },
      'blurEdge': ({edge: edgeId}) => {
        let edge = me.relationData.edges.get(edgeId);
        if (edge.type === EDGE_TYPE_OUTLINE) return;
        edge.label = '';
        me.myNetwork.updateEdges([edge]);
        networkCanvas.style.cursor = 'default';
      },
    };
    // 我的视图
    this.myNetwork.bindEvent(myEvents);

    PB.sub(this, 'network', 'focus', nodes => {
      if (!_.isArray(nodes)) nodes = [nodes];
      me.setState({
        clickedNode: me.myNetwork.graph.nodes.get(nodes[0].id),
      }, () => {
        me.myNetwork.focus(nodes[0].id);
      })
    });

    NodeDataProvider.loadInsightDetailInfo(me.props.nodeId, me.props.nodeType).then(data => {
      me.nodeInfo = new Node({...data, x: 0, y: 0, fixed: true});
      me.nodeInfo['size'] = 40;
      const graphData = {
        nodes: (() => {
          let result = [me.nodeInfo];

          let categoryNodes = [
            ICON_CATEGORY_GOV,
            ICON_CATEGORY_ORG,
            ICON_CATEGORY_PARK,
            ICON_CATEGORY_COMPANY,
            ICON_CATEGORY_COLLEGE_AND_UNIVERSITY,
            ICON_CATEGORY_INSTITUTE,
            ICON_CATEGORY_TALENT,
            ICON_CATEGORY_PATENT,
            ICON_CATEGORY_PAPER,
            ICON_CATEGORY_DATASET,
          ].map(type => ({
            id: `${typeIdPrefix}${iconConfig[type].extra.nodeType}`,
            fname: iconConfig[type].title._default,
            label: iconConfig[type].title._default,
            type: NODE_TYPE_TEXT,
            lev: 'gradeF',
          }));
          categoryNodes.map(node => result.push(new Node(node)));

          return result;
        })(),
        edges: (() => {
          let result = [];

          [
            ICON_CATEGORY_GOV,
            ICON_CATEGORY_ORG,
            ICON_CATEGORY_PARK,
            ICON_CATEGORY_COMPANY,
            ICON_CATEGORY_COLLEGE_AND_UNIVERSITY,
            ICON_CATEGORY_INSTITUTE,
            ICON_CATEGORY_TALENT,
            ICON_CATEGORY_PATENT,
            ICON_CATEGORY_PAPER,
            ICON_CATEGORY_DATASET,
          ].forEach(type => {
            result.push(new Edge({
              from: me.nodeInfo.id,
              to: `${typeIdPrefix}${iconConfig[type].extra.nodeType}`,
              type: EDGE_TYPE_OUTLINE,
              meta: {
                text2text: true,
              },
            }));
          });

          return result;
        })(),
      };
      me.myNetwork.addGraph(graphData);
      me.myNetwork.graph.nodes.update(me.nodeInfo);

      // 获取关系
      NodeDataProvider.loadInsightDetailRelations(me.props.nodeId, me.props.nodeType).then(({nodes}) => {
        data = nodes.filter(node => node.id !== me.nodeInfo.id); // TODO 后台不再返回主节点后删除

        const graphData = {
          'nodes': ((function (nodes) {
            let result = [];
            nodes.map(node => result.push(new Node(node)));
            return result;
          })(data)),
          'edges': (nodes => {
            let result = [];

            nodes.map(node => {
              result.push(new Edge({
                from: `${typeIdPrefix}${node[TYPE_FIELD_NAME]}`,
                to: node.id,
              }))
            });

            return result;
          })(data),
        };
        me.resourceData = graphData.nodes;
        me.myNetwork.addGraph(graphData);

        // 画面自动居中
        setTimeout(() => {
          me.myNetwork.network.fit({'animation': true});
          me.myNetwork.network.once('animationFinished', () => {
            requestAnimationFrame(() => me.myNetwork.network.moveTo({
              scale: me.myNetwork.network.getScale() * 0.9,
              'animation': true,
            }));
          });
        }, 1500);
      }).catch(error => {
        // TODO: 获取关系失败了，需要提示
        console.log('TODO: 获取关系失败了，需要提示', error);
      });

      // 获取图片
      me.imageData = undefined;
      NodeDataProvider.loadInsightDetailImages(me.props.nodeId, me.props.nodeType).then(data => {
        me.imageData = data;
        me.setState({showMoreChart: me.imageData && me.imageData.length > 0});
      }).catch(error => {
        // TODO: 获取图片失败了，需要提示
        console.log('TODO: 获取图片失败了，需要提示', error);
        // TODO: 这里暂时放上测试图片
        // me.imageData = testImages;
        me.setState({showMoreChart: me.imageData && me.imageData.length > 0});
      });

      // 获取属性
      me.propertyData = [];
      if (propertyOrderMap[me.props.nodeType]) {
        propertyOrderMap[me.props.nodeType].forEach(key => {
          if (key === 'name' && me.nodeInfo) {
            me.propertyData.push({key: 'name', value: me.nodeInfo.fname});
          } else {
            me.propertyData.push({key, value: undefined});
          }
        });
      }
      NodeDataProvider.loadInsightDetailProperties(me.props.nodeId, me.props.nodeType).then(data => {
        me.propertyData = [];
        if (propertyOrderMap[me.props.nodeType]) {
          propertyOrderMap[me.props.nodeType].forEach(key => {
            if (data[key]) {
              me.propertyData.push({key, value: data[key]});
            } else {
              me.propertyData.push({key, value: false});
            }
          });
        }
        me.forceUpdate();
      }).catch(error => {
        // TODO: 获取属性失败了，需要提示
        console.log('TODO: 获取属性失败了，需要提示', error);
        me.propertyData = [];
        if (propertyOrderMap[me.props.nodeType]) {
          propertyOrderMap[me.props.nodeType].forEach(key => {
            if (key === 'name' && me.nodeInfo) {
              me.propertyData.push({key: 'name', value: me.nodeInfo.fname});
            } else {
              me.propertyData.push({key, value: false});
            }
          });
        }
        me.forceUpdate();
      });

      me.forceUpdate();
    }).catch(error => {
      // TODO: 获取详情失败了，需要提示
      console.log('TODO: 获取详情失败了，需要提示', error);
    });
  }

  componentWillUnmount() {
    PB.remove(this);
  }

  getNodeInfo = id => this.relationData.nodes.get(id);

  getNodeConnectedInfo = id => {
    let me = this;

    const edges = [];
    if (me.relationData.nodes.get(id)) {
      me.relationData.edges.forEach(edge => {
        if (edge.from === id || edge.to === id) {
          edges.push(edge);
        }
      })
    }
    const nodes = edges.map(edge =>
      edge.from === id ? me.relationData.nodes.get(edge.to) : me.relationData.nodes.get(edge.from)
    );
    return {nodes, edges};
  };

  getEdgeInfo = id => this.relationData.edges.get(id);

  getEdgeConnectedNodes = id => {
    const edge = this.relationData.edges.get(id);
    if (edge) {
      return this.relationData.nodes.get([edge.from, edge.to]);
    } else {
      return [];
    }
  };

  render() {
    let me = this;

    let lightBox = null;
    if (me.state.showLightBox >= 0 && me.state.showLightBox < me.imageData.length) {
      const imagePathList = me.imageData.map(imgData => imgData.src);
      const mainSrc = imagePathList[me.state.showLightBox] || null;
      if (mainSrc) {
        let nextSrcIdx = (me.state.showLightBox + 1) % imagePathList.length;
        let nextSrc = imagePathList[nextSrcIdx];
        while (!nextSrc) {
          nextSrcIdx = (nextSrcIdx + 1) % imagePathList.length;
          nextSrc = imagePathList[nextSrcIdx];
          if (nextSrc === mainSrc) {
            nextSrc = undefined;
            break;
          }
        }
        let prevSrcIdx = (imagePathList.length + me.state.showLightBox - 1) % imagePathList.length;
        let prevSrc = imagePathList[prevSrcIdx];
        while (!prevSrc) {
          prevSrcIdx = (imagePathList.length + prevSrcIdx - 1) % imagePathList.length;
          prevSrc = imagePathList[prevSrcIdx];
          if (prevSrc === mainSrc) {
            prevSrc = undefined;
            break;
          }
        }

        lightBox = (
          <LightBox
            mainSrc={mainSrc}
            prevSrc={prevSrc === mainSrc ? undefined : prevSrc}
            nextSrc={nextSrc === mainSrc ? undefined : nextSrc}
            onCloseRequest={() => me.setState({showLightBox: -1})}
            onMovePrevRequest={() => me.setState({showLightBox: prevSrcIdx})}
            onMoveNextRequest={() => me.setState({showLightBox: nextSrcIdx})}
            imageTitle={
              <span>
                {me.imageData[me.state.showLightBox].title} - (<a href={me.imageData[me.state.showLightBox].link} target={'_blank'}>查看原文</a>)
              </span>
            }
          />
        );
      }
    }

    return (
      <div className={style['container-body']}>
        <div id={me.containerId} className={style['relation']}>&nbsp;</div>
        {
          me.myNetwork ? (
            <RelationSearch
              dataLists={me.myNetwork.graph}
              typeFieldName={TYPE_FIELD_NAME}
            />
          ) : null
        }
        {
          me.nodeInfo ? (
            <div className={style['title']}>
              <span>{getNodeDisplayTitle(me.nodeInfo)} 详情</span>
            </div>
          ) : null
        }
        <div className={style['menu-bar']}>&nbsp;</div>
        <div className={`${style['property-table-frame']} scrollbar`}>
          <Table
            showHeader={false}
            pagination={false}
            scroll={{y: 'calc(61.8vh - 8rem)'}}
            columns={propertyColumns}
            dataSource={me.propertyData}
            locale={{
              emptyText: '暂无数据',
            }}
          />
          <div
            className={style['property-more_info_btn']}
            onClick={() => me.setState({showTimeline: true})}
          >
            <span>时间相关</span>
          </div>
          <div
            className={style['property-more_info_btn']}
            onClick={() => me.setState({showChinaADC: true})}
          >
            <span>地理相关</span>
          </div>
        </div>
        {
          me.imageData
        }
        <div className={`${style['more-chart-frame']} scrollbar`}>
          <div className={style['more-chart-content']} style={{display: me.state.showMoreChart ? 'block' : 'none'}}>
            {
              me.imageData && me.imageData.map(({title, src, link}, idx) => (
                <div className={style['more-chart-item-frame']} key={`more-chart-${idx}`}>
                  <div
                    className={style['more-chart-item-img']}
                    onClick={() => {
                      me.setState({showLightBox: idx});
                    }}
                  >
                    <span className={'helper'} />
                    <img src={src} alt={title} />
                  </div>
                  <a href={link} target={'_blank'}>{title}</a>
                </div>
              ))
            }
          </div>
          <Tooltip
            placement={'topLeft'}
            title={me.state.showMoreChart ? '收起' : '展开'}
            overlayClassName={'dark-theme'}
          >
            <div className={`${style['more-chart-switch']} ${me.state.showMoreChart ? 'active' : ''}`}>
              <a
                onClick={() => {
                  if (me.imageData && me.imageData.length > 0) {
                    me.setState({showMoreChart: !me.state.showMoreChart});
                  } else {
                    message.info('没有找到相关图片');
                  }
                }}
              >
                <Icon name={me.state.showMoreChart ? 'icon-caret-left' : 'icon-caret-right'} type={IconTypes.ICON_FONT}/>
              </a>
            </div>
          </Tooltip>
        </div>
        <VisualToolMini networkRef={me.myNetwork}/>
        <div className={style['east-container']}>
          <Suspense fallback={null}>
            <InfoBoard
              className={styleMainView["info-board"]}
              userInfo={me.props.userInfo}
              networkRef={me.networkRef}
              readonly={true}
              getNodeInfo={me.getNodeInfo}
              getNodeConnectedInfo={me.getNodeConnectedInfo}
              getEdgeInfo={me.getEdgeInfo}
              getEdgeConnectedNodes={me.getEdgeConnectedNodes}
              showBriefingInfo={me.state.showBriefingInfoList}
              briefingInfoRelatedNodes={[me.nodeInfo, me.state.briefingInfoRelatedNode]}
            />
          </Suspense>
        </div>
        {me.state.showTimeline && (
          <TimelinePanel
            nodeId={me.props.nodeId}
            nodeType={me.props.nodeType}
            onClose={() => me.setState({showTimeline: false})}
          />
        )}
        {me.state.showChinaADC && (
          <MapPanel
            allNodes={me.resourceData}
            onClose={() => me.setState({showChinaADC: false})}
          />
        )}
        {lightBox}
      </div>
    );
  }
}

MainView.defaultProps = {
};

MainView.propTypes = {
  nodeId: PropTypes.string.isRequired,
  nodeType: PropTypes.number.isRequired,
};

export default MainView;