import React from 'react';
import PropTypes from 'prop-types';
import {Tooltip, Table, Button, Row, Col, Modal, message} from 'antd';
import Highlighter from 'react-highlight-words';

import PB, {SimplePB} from '@/libs/simplePB';

import {IconTypes} from '@/constants/common';

import Icon from '@/components/common/common.icon';
import TimeDisplayField from "@/components/common/common.timeDisplayField";
import {defaultIconData} from '@/components/common/view/presentation/common.view.presentation.logic';
import ViewDataProvider from '@/components/common/dataProvider/common.dataProvider.view';
import {getNodeDisplayTitle, getNodeIcon} from '@/constants/vis.defaultDefine.1';

import style from '@/style/common/view/common.view.presentation.story.less';

class PresentationStoryList extends React.Component {
  state = {
    nodeListLimit: 100,
    storyNodeMaps: [],
    presentationPlayingNodeId: undefined
  };

  lastHoverKey = {
    list: undefined,
    statistics: undefined,
  };

  doStatistics = (allNodeMap, idMap, storyNodeIds) => {
    let me = this, storyNodeMaps = [];
    (storyNodeIds.length>0?storyNodeIds:Object.keys(idMap['story'])).forEach((n,index) => {
      if(Object.keys(idMap['story']).includes(n)){
        storyNodeMaps.push(allNodeMap[n]);
      }else{
        storyNodeMaps.push({id:'id_'+index,fname:n,type:'notNode'});
      }
    })
    me.setState({storyNodeMaps});
  };

  doFocusNode = node => {
    this.props.bus.emit('relation', 'node.presentation.focus', node);
  };

  onShowList = (viewId) => {
    let me = this;
    me.props.bus.emit('relation', 'node.presentation.stop');
    me.props.bus.emit('presentation', 'story.list.show_drawer', ({viewId:me.props.viewId}));
  }
  
  componentDidMount() {
    let me = this;

    // 当前自动漫游的节点
    me.props.bus.sub(me, 'relation', 'presentation.playing.node', ({nodeId}) => {
      if (nodeId) {
        me.setState({
          presentationPlayingNodeId: nodeId,
        })
      }
    });

  }

  componentWillUnmount() {
    let me = this;
    me.props.bus.remove(me);
  }

  render() {
    let me = this;
    let textList = [];
    let nodeList = me.props.viewDataProvider.getNode(me.props.config['content'].nodeIds).filter(n => !!n);
    let contentList = me.props.config['content'].contentList;
    if(contentList && contentList.length>0){
      contentList.forEach((n,index) => {
        if(me.props.config['content'].nodeIds.includes(n)){
          nodeList.map((node, idx) => {
            if(node.id==n){
              textList.push(node);
            }
          })
        }else{
          textList.push({id:'id_'+index,description:n,type:'notNode'});
        }
      })
    }else{
      textList = nodeList;
    }
    return (
      <div className={style['config-detail-panel']}>
        <div className={style['config-detail-header']}>
          <span style={{cursor: 'pointer'}} onClick={e => {e.stopPropagation();me.onShowList()}}><Icon name="left"/>{' 返回专题报告列表'} </span>
        </div>
        <div className={`${style['config-detail-content']} scrollbar scrollbar-none`}>
          {
            me.props.config.title || me.props.config.description ? (
              <div className={style['config-detail-section']}>
                <div className={`ant-avatar ant-avatar-icon common-avatar ${style['config-avatar']}`} style={{float: 'left',margin:'0.6rem 0.5rem 0 0'}}>
                  <img
                    src={me.props.config.meta && me.props.config.meta['iconData'] ? me.props.config.meta['iconData'] : defaultIconData}
                    alt={'icon'}
                  />
                </div>
                <h4>{me.props.config.title}</h4>
                <p>
                  {
                    me.props.config.description && me.props.config.description.split('\n').map((line, idx) => (
                      <React.Fragment key={`ln-${idx}`}>{line}<br /></React.Fragment>
                    ))
                  }
                </p>
              </div>
            ) : null
          }
          <div className={style['config-detail-section']}>
            <table className={style['config-detail-node-list']}>
              <tbody>
              {
                me.state.nodeListLimit > 0 && textList.length>0 &&
                textList.map((node, idx) => {
                  return node.type !=='notNode'?
                  (
                    <tr
                      key={`node-${node.id}-${idx}`}
                      className={
                        me.state.presentationPlayingNodeId === node.id ? style['active'] : ''
                      }
                      onClick={e => {me.doFocusNode({node: node, config:me.props.config})}}
                    >
                      <td style={{paddingLeft:'1.9rem'}}>
                        <Icon name={getNodeIcon(node).name} type={getNodeIcon(node).type} color={'#a5a5a5'} style={{marginRight: '0.8em'}} />
                        {getNodeDisplayTitle(node)}
                      </td>
                    </tr>
                  ):(
                    <tr
                    key={`node-${node.id}-${idx}`}
                    className={
                      me.state.presentationPlayingNodeId === node.description ? style['active'] : ''
                    }
                    onClick={e => {me.doFocusNode({node: node, config:me.props.config})}}
                  >
                    <td style={{fontStyle:'italic',textIndent:'2em',whiteSpace:'normal'}}>{node.description}</td>
                  </tr>
                  )
                })
              }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

PresentationStoryList.defaultProps = {
  bus: PB,
};

PresentationStoryList.propTypes = {
  bus: PropTypes.instanceOf(SimplePB),
  viewId: PropTypes.string.isRequired,
  currentUserId: PropTypes.number.isRequired,
  viewDataProvider: PropTypes.instanceOf(ViewDataProvider).isRequired,
  config: PropTypes.object.isRequired,
};

export default PresentationStoryList;