import React from "react";
import PropTypes from 'prop-types';
import {Spin, Radio} from 'antd';
import 'echarts/lib/chart/scatter';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/legend';
import axios from 'axios'
import {getToken, REQUEST_BASE} from '@/utils/HttpUtil'
import {AvatarColors} from '@/constants/common';
import PB, {SimplePB} from "@/libs/simplePB";
import UserAvatar from 'react-user-avatar';
import Icon from "@/components/common/common.icon";
import memberStyles from '@/style/common/view/common.view.teamwork.member.less';
import styles from '@/style/common/view/common.view.teamwork.memberStatPanel_nodes.less';
import moment from "moment";
import TimeDisplayField from "@/components/common/common.timeDisplayField";
import {getNodeDisplayTitle, getNodeIcon} from "@/constants/vis.defaultDefine.1";
import Node from '@/libs/view/Node';
// import {IconTypes} from "@/constants/common";

// api 请求
const service = axios.create({
  'timeout': 1500000, // 超时时间
  'headers': {
    'x-requested-with': 'XMLHttpRequest',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Max-Age': 0,
    'Authorization': getToken(),
  },
});
// 统计接口
const API_BASE = 'https://snap.joinmap.ai'
// const API_BASE = 'http://localhost:5000'
// 获取个人用户的统计信息
// 733统计
const API_GetViewMemberStat733 = ({usersId, viewId, inRange}) => {
  return service({
    method: 'POST',
    url: API_BASE + '/stat_api/view/member/stat_7_3_3',
    data: {users_id: usersId, view_id: viewId, in_range: inRange},
  })
};
const Blank_Node_List = [
  [null, null, null],
  [null, null, null],
  [null, null, null],
  [null, null, null],
  [null, null, null],
  [null, null, null],
  [null, null, null],
  // [null, null, null],
  // [null, null, null],
]

class TeamworkMemberStatPanelOfNodes extends React.Component {
  state = {
    loading: true,
    schRange: 'this_view',
    days: [],
    statData: [],
  }
  
  // cache 再次点击不再发请求
  cacheStatDate = {
    'this_view': false,
    'member_views': false,
    'open_views': false,
  }
  
  componentDidMount() {
    console.log('TeamworkMemberStatPanelOfNodes this.props:', this.props)
    this.loading_data();
  }
  
  // componentWillReceiveProps(nextProps, nextContext) {
  // console.log('TeamworkMemberStatPanelOfNodes componentWillReceiveProps nextProps:', nextProps);
  // console.log('TeamworkMemberStatPanelOfNodes componentWillReceiveProps nextContext:', nextContext);
  // }
  
  loading_data = () => {
    const {memberList, viewInfo} = this.props;
    const {schRange, statData} = this.state;
    let me = this;
    
    if (me.cacheStatDate.hasOwnProperty(schRange) && me.cacheStatDate[schRange]) {
      // 有数据不变动
      me.setState({
        statData: me.cacheStatDate[schRange],
        loading: false
      });
      return true;
    }
    // 加载中
    this.setState({loading: true});
    // 需要请求数据的成员 userId
    let viewId = viewInfo.viewId;
    let usersId = [];
    memberList.forEach(m => {
      usersId.push(m.userId);
    });
    let post_data = {usersId, inRange: schRange};
    if (schRange === 'this_view') {
      post_data['viewId'] = viewId;
    }
    
    API_GetViewMemberStat733(post_data).then(response => {
      console.log('TeamworkMemberStatPanelOfNodes response:', response)
      let resp_data = response.data.data.nodes;
      let temp_data = [];
      memberList.forEach(m => {
        if (resp_data.hasOwnProperty('user_' + m.userId)) {
          temp_data.push({
            user: m,
            data: resp_data['user_' + m.userId]
          });
        } else {
          temp_data.push({
            user: m,
            data: [].concat(Blank_Node_List)
          });
        }
      })
      // 表头显示时间
      // 今天
      // let dayT0 = new Date();
      // dayT0.setTime(dayT0.getTime());
      // let today_str = dayT0.getFullYear() + '-' + (dayT0.getMonth() + 1) + '-' + dayT0.getDate();
      // // 明天
      // let dayT1 = new Date();
      // dayT1.setTime(dayT1.getTime() + 24 * 60 * 60 * 1000);
      // let tomorrow_str = dayT1.getFullYear() + '-' + (dayT1.getMonth() + 1) + '-' + dayT1.getDate();
      // 缓存数据
      me.cacheStatDate[schRange] = [].concat(temp_data);
      // 刷新页面
      me.setState({
        // days: response.data.data.days.concat([today_str, tomorrow_str]),  // 含今天、明天
        days: response.data.data.days,// 不含今天、明天
        statData: temp_data,
        loading: false
      })
    }).catch(error => {
      console.log('TeamworkMemberStatPanelOfNodes error:', error)
      // 表头
      let header_days = [];
      // 前 7 天 + 今明
      // let days_total = [7, 6, 5, 4, 3, 2, 1, 0, -1]; // 含今天、明天
      let days_total = [7, 6, 5, 4, 3, 2, 1]; // 不含今天、明天
      for (let i in days_total) {
        let x = days_total[i];
        let dayT_x = new Date();
        dayT_x.setTime(dayT_x.getTime() - x * 24 * 60 * 60 * 1000);
        header_days.push(dayT_x.getFullYear() + '-' + (dayT_x.getMonth() + 1) + '-' + dayT_x.getDate());
      }
      
      // 数据
      let temp_data = [];
      memberList.forEach(m => {
        temp_data.push({
          user: m,
          data: [].concat(Blank_Node_List)
        });
      })
      me.setState({
        days: header_days,
        statData: temp_data,
        loading: false
      });
    });
  }
  
  render() {
    const {loading, days, statData} = this.state;
    let me = this;
    // 表头
    let header_table_jsx = [<th key={'h1'}>
      <div className={styles['td-name']} style={{textAlign: 'center'}}>
        成员
      </div>
    </th>];
    days.forEach(d => {
      header_table_jsx.push(
        <th key={d}>
          <div className={styles['date_header']}>
            <TimeDisplayField
              timestamp={moment(d, 'YYYY-MM-DD').valueOf()}
              content={displayObject => (<span>{displayObject.shortText.split(' ')[0]}</span>)}
            />
          </div>
        </th>
      )
    })
    
    // 数据
    let u_table_jsx = [];
    statData.map((ud, idx1) => {
      let userInfo = ud.user;
      let uid = ud.user.userId;
      
      // 每行表格内容
      let u_row_jsx = [];
      
      // # 间隔样式
      let tr_class = (idx1 % 2 === 0) ? "" : styles['bg'];
      
      // 头像
      let avatar = (
        <div className={memberStyles['user-stat_avatar']} style={{marginLeft: 10, width: 'calc(100% - 20px)'}}>
          <div
            className={`${memberStyles['avatar']} ${userInfo.picId ? '' : memberStyles['avatar-default-icon']}`}>
            <UserAvatar
              size={'var(--avatar-size)'}
              name={userInfo.nick ? userInfo.nick : '-'}
              src={userInfo.picId ?
                `${REQUEST_BASE}/user/user/file/${userInfo.picId}?Authorization=${getToken()}`
                : undefined
              }
              colors={AvatarColors}
            />
          </div>
          <div style={{marginLeft: 8, width: 'calc(100% - var(--avatar-size) - 25px)'}}>{userInfo.nick}</div>
        </div>
      );
      
      // 每个用户的默认数据
      let x_u_table_data = [
        [null, null, null, null, null, null, null, null, null,],
        [null, null, null, null, null, null, null, null, null,],
        [null, null, null, null, null, null, null, null, null,],
      ];// 含 今天、明天
      let u_table_data = [
        [null, null, null, null, null, null, null],
        [null, null, null, null, null, null, null],
        [null, null, null, null, null, null, null],
      ];// 不含 今天、明天
      u_table_data.forEach((x, idx_x) => {
        x.forEach((y, idx_y) => {
          try {
            u_table_data[idx_x][idx_y] = new Node(ud.data[idx_y][idx_x]);
          } catch (e) {
            u_table_data[idx_x][idx_y] = null;
          }
        })
      })
      
      u_table_data.forEach((row, idx2) => {
        let tr_jsx = [];
        row.forEach((row_td, idx3) => {
          if (row_td) {
            tr_jsx.push(
              <td className={styles['td-data']}>
                <div key={'row_' + idx2 + '_' + idx3} className={styles['node']}
                     title={'图谱：' + row_td.name + '\n节点：' + row_td.fname || '-'}>
                  <Icon name={getNodeIcon(row_td).name} type={getNodeIcon(row_td).type}
                        color={'#a5a5a5'}
                        style={{marginRight: '0.5em'}}/> {getNodeDisplayTitle(row_td)}
                </div>
              </td>
            );
          } else {
            tr_jsx.push(
              <td className={styles['td-data']}>
                <div className={styles['node'] + ' ' + styles['blank']}> -</div>
              </td>
            );
          }
        });
        if (idx2 === 0) {
          tr_jsx.unshift(<td rowSpan="3">
            <div className={styles['td-name']}>
              {avatar}
            </div>
          </td>)
        }
        u_row_jsx.push(<tr key={'us' + uid + idx2}>
          {
            tr_jsx.map(i => i)
          }
        </tr>)
        
        /*
                        if (idx2 === 0) {

                            u_row_jsx.push(<tr key={'us' + uid + idx2}>
                                <td rowSpan="3">
                                    <div className={styles['td-name']}>
                                        {avatar}
                                    </div>
                                </td>
                                <td className={styles['td-data']}>
                                    {
                                        row[0] ?
                                            <div className={styles['node']} title={row[0].name + '\n' + row[0].fname || '-'}>
                                                <Icon name={getNodeIcon(row[0]).name} type={getNodeIcon(row[0]).type}
                                                      color={'#a5a5a5'}
                                                      style={{marginRight: '0.5em'}}/> {getNodeDisplayTitle(row[0])}
                                            </div> : <div className={styles['node'] + ' ' + styles['blank']}> - </div>
                                    }
                                </td>
                                <td className={styles['td-data']}>
                                    {
                                        row[1] ? <div className={styles['node']} title={row[1].fname || '-'}>
                                            <Icon name={getNodeIcon(row[1]).name} type={getNodeIcon(row[1]).type}
                                                  color={'#a5a5a5'}
                                                  style={{marginRight: '0.5em'}}/> {getNodeDisplayTitle(row[1])}
                                        </div> : <div className={styles['node'] + ' ' + styles['blank']}> - </div>
                                    }
                                </td>
                                <td className={styles['td-data']}>
                                    {
                                        row[2] ? <div className={styles['node']} title={row[2].fname || '-'}>
                                            <Icon name={getNodeIcon(row[2]).name} type={getNodeIcon(row[2]).type}
                                                  color={'#a5a5a5'}
                                                  style={{marginRight: '0.5em'}}/> {getNodeDisplayTitle(row[2])}
                                        </div> : <div className={styles['node'] + ' ' + styles['blank']}> - </div>
                                    }
                                </td>
                                <td className={styles['td-data']}>
                                    {
                                        row[3] ? <div className={styles['node']} title={row[3].fname || '-'}>
                                            <Icon name={getNodeIcon(row[3]).name} type={getNodeIcon(row[3]).type}
                                                  color={'#a5a5a5'}
                                                  style={{marginRight: '0.5em'}}/> {getNodeDisplayTitle(row[3])}
                                        </div> : <div className={styles['node'] + ' ' + styles['blank']}> - </div>
                                    }
                                </td>
                                <td className={styles['td-data']}>
                                    {
                                        row[4] ? <div className={styles['node']} title={row[4].fname || '-'}>
                                            <Icon name={getNodeIcon(row[4]).name} type={getNodeIcon(row[4]).type}
                                                  color={'#a5a5a5'}
                                                  style={{marginRight: '0.5em'}}/> {getNodeDisplayTitle(row[4])}
                                        </div> : <div className={styles['node'] + ' ' + styles['blank']}> - </div>
                                    }
                                </td>
                                <td className={styles['td-data']}>
                                    {
                                        row[5] ? <div className={styles['node']} title={row[5].fname || '-'}>
                                            <Icon name={getNodeIcon(row[5]).name} type={getNodeIcon(row[5]).type}
                                                  color={'#a5a5a5'}
                                                  style={{marginRight: '0.5em'}}/> {getNodeDisplayTitle(row[5])}
                                        </div> : <div className={styles['node'] + ' ' + styles['blank']}> - </div>
                                    }
                                </td>
                                <td className={styles['td-data']}>
                                    {
                                        row[6] ? <div className={styles['node']} title={row[6].fname || '-'}>
                                            <Icon name={getNodeIcon(row[6]).name} type={getNodeIcon(row[6]).type}
                                                  color={'#a5a5a5'}
                                                  style={{marginRight: '0.5em'}}/> {getNodeDisplayTitle(row[6])}
                                        </div> : <div className={styles['node'] + ' ' + styles['blank']}> - </div>
                                    }
                                </td>
                                <td className={styles['td-data']}>
                                    {
                                        row[7] ? <div className={styles['node']} title={row[7].fname || '-'}>
                                            <Icon name={getNodeIcon(row[7]).name} type={getNodeIcon(row[7]).type}
                                                  color={'#a5a5a5'}
                                                  style={{marginRight: '0.5em'}}/> {getNodeDisplayTitle(row[7])}
                                        </div> : <div className={styles['node'] + ' ' + styles['blank']}> - </div>
                                    }
                                </td>
                                <td className={styles['td-data']}>
                                    {
                                        row[8] ? <div className={styles['node']} title={row[8].fname || '-'}>
                                            <Icon name={getNodeIcon(row[8]).name} type={getNodeIcon(row[8]).type}
                                                  color={'#a5a5a5'}
                                                  style={{marginRight: '0.5em'}}/> {getNodeDisplayTitle(row[8])}
                                        </div> : <div className={styles['node'] + ' ' + styles['blank']}> - </div>
                                    }
                                </td>
                            </tr>)
                        } else {
                            u_row_jsx.push(<tr key={'us' + uid + idx2}>
                                <td className={styles['td-data']}>
                                    {
                                        row[0] ? <div className={styles['node']} title={row[0].fname || '-'}>
                                            <Icon name={getNodeIcon(row[0]).name} type={getNodeIcon(row[0]).type}
                                                  color={'#a5a5a5'}
                                                  style={{marginRight: '0.5em'}}/> {getNodeDisplayTitle(row[0])}
                                        </div> : <div className={styles['node'] + ' ' + styles['blank']}> - </div>
                                    }
                                </td>
                                <td className={styles['td-data']}>
                                    {
                                        row[1] ? <div className={styles['node']} title={row[1].fname || '-'}>
                                            <Icon name={getNodeIcon(row[1]).name} type={getNodeIcon(row[1]).type}
                                                  color={'#a5a5a5'}
                                                  style={{marginRight: '0.5em'}}/> {getNodeDisplayTitle(row[1])}
                                        </div> : <div className={styles['node'] + ' ' + styles['blank']}> - </div>
                                    }
                                </td>
                                <td className={styles['td-data']}>
                                    {
                                        row[2] ? <div className={styles['node']} title={row[2].fname || '-'}>
                                            <Icon name={getNodeIcon(row[2]).name} type={getNodeIcon(row[2]).type}
                                                  color={'#a5a5a5'}
                                                  style={{marginRight: '0.5em'}}/> {getNodeDisplayTitle(row[2])}
                                        </div> : <div className={styles['node'] + ' ' + styles['blank']}> - </div>
                                    }
                                </td>
                                <td className={styles['td-data']}>
                                    {
                                        row[3] ? <div className={styles['node']} title={row[3].fname || '-'}>
                                            <Icon name={getNodeIcon(row[3]).name} type={getNodeIcon(row[3]).type}
                                                  color={'#a5a5a5'}
                                                  style={{marginRight: '0.5em'}}/> {getNodeDisplayTitle(row[3])}
                                        </div> : <div className={styles['node'] + ' ' + styles['blank']}> - </div>
                                    }
                                </td>
                                <td className={styles['td-data']}>
                                    {
                                        row[4] ? <div className={styles['node']} title={row[4].fname || '-'}>
                                            <Icon name={getNodeIcon(row[4]).name} type={getNodeIcon(row[4]).type}
                                                  color={'#a5a5a5'}
                                                  style={{marginRight: '0.5em'}}/> {getNodeDisplayTitle(row[4])}
                                        </div> : <div className={styles['node'] + ' ' + styles['blank']}> - </div>
                                    }
                                </td>
                                <td className={styles['td-data']}>
                                    {
                                        row[5] ? <div className={styles['node']} title={row[5].fname || '-'}>
                                            <Icon name={getNodeIcon(row[5]).name} type={getNodeIcon(row[5]).type}
                                                  color={'#a5a5a5'}
                                                  style={{marginRight: '0.5em'}}/> {getNodeDisplayTitle(row[5])}
                                        </div> : <div className={styles['node'] + ' ' + styles['blank']}> - </div>
                                    }
                                </td>
                                <td className={styles['td-data']}>
                                    {
                                        row[6] ? <div className={styles['node']} title={row[6].fname || '-'}>
                                            <Icon name={getNodeIcon(row[6]).name} type={getNodeIcon(row[6]).type}
                                                  color={'#a5a5a5'}
                                                  style={{marginRight: '0.5em'}}/> {getNodeDisplayTitle(row[6])}
                                        </div> : <div className={styles['node'] + ' ' + styles['blank']}> - </div>
                                    }
                                </td>
                                <td className={styles['td-data']}>
                                    {
                                        row[7] ? <div className={styles['node']} title={row[7].fname || '-'}>
                                            <Icon name={getNodeIcon(row[7]).name} type={getNodeIcon(row[7]).type}
                                                  color={'#a5a5a5'}
                                                  style={{marginRight: '0.5em'}}/> {getNodeDisplayTitle(row[7])}
                                        </div> : <div className={styles['node'] + ' ' + styles['blank']}> - </div>
                                    }
                                </td>
                                <td className={styles['td-data']}>
                                    {
                                        row[8] ? <div className={styles['node']} title={row[8].fname || '-'}>
                                            <Icon name={getNodeIcon(row[8]).name} type={getNodeIcon(row[8]).type}
                                                  color={'#a5a5a5'}
                                                  style={{marginRight: '0.5em'}}/> {getNodeDisplayTitle(row[8])}
                                        </div> : <div className={styles['node'] + ' ' + styles['blank']}> - </div>
                                    }
                                </td>
                            </tr>)
                        }
        */
        
      });
      
      // 表格
      u_table_jsx.push(
        <table
          key={uid + '_' + idx1}
          className={styles['table-ly'] + ' ' + styles['stat-table'] + ' ' + tr_class}
        >
          <tbody>
          {u_row_jsx.map(i => i)}
          </tbody>
        </table>
      )
    });
    
    return (
      <div style={{width: 'auto', minHeight: 150}}>
        <Spin delay={50} spinning={loading} size="large" tip={'加载中...'} style={{height: '100%'}}>
          <div style={{float: 'right'}}>
            <Radio.Group
              buttonStyle="solid"
              value={me.state.schRange}
              onChange={e => {
                e.stopPropagation();
                console.log('TeamworkMemberStatPanelOfNodes e:', e)
                me.setState({
                  schRange: e.target.value
                }, () => {
                  me.loading_data();
                })
              }}
            >
              <Radio.Button value="this_view">本图谱</Radio.Button>
              <Radio.Button value="member_views">协作图谱</Radio.Button>
              <Radio.Button value="open_views">公开图谱</Radio.Button>
            </Radio.Group>
          </div>
          <div style={{clear: 'both'}}/>
          <br/>
          <table className={styles['table-ly'] + ' ' + styles['header-table']}>
            <thead>
            <tr>{days.length > 0 ? header_table_jsx.map(i => i) : ''}</tr>
            </thead>
          </table>
          {u_table_jsx.map(i => i)}
        </Spin>
      </div>
    );
  }
}

TeamworkMemberStatPanelOfNodes.defaultProps = {
  bus: PB,
};

TeamworkMemberStatPanelOfNodes.propTypes = {
  bus: PropTypes.instanceOf(SimplePB),
  viewInfo: PropTypes.object.isRequired,
  memberList: PropTypes.array.isRequired,
};

export default TeamworkMemberStatPanelOfNodes;
