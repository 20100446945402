import React from 'react';
import PropTypes from 'prop-types';
// import {Tooltip} from "antd";
import moment from "moment";

import NodeDataProvider, {overrideNextMessage} from '@/components/common/dataProvider/common.dataProvider.node';
import StatisticsUserNodeChart from "@/components/common/view/statistics/common.view.statistics.userNodeChart";

import style from '@/style/common/view/statistics/common.view.statistics.userNodePanel.less';
import Icon from "@/components/common/common.icon";
import {getDisplayNumber} from '@/components/common/common.functions';

class StatisticsUserNodePanel extends React.Component {
  state = {
    nodeListLoadingStatus: 'idle',
    nodeAmountStatisticsLoadingStatus: 'idle',

    userStatistics: {points: {data: []}}, // 近一周贡献点统计

    totalNodeAmount: 0, // 总贡献点数
    maxNodeAmountByDay: 0, // 最大日贡献点数
    maxNodeAmountByDayDate: undefined, // 最大日贡献点对应日期
    recentNodeAmount: 0, // 近一周贡献点数
    nodeAmountThisMonth: 0, // 本月贡献点数
    nodeAmountThisQuarter: 0, // 本季度贡献点数
    nodeAmountThisYear: 0, // 本年贡献点数
  };

  onTryAgain = () => {
    let me = this;

    me.setState({
      nodeListLoadingStatus: 'idle',
      nodeAmountStatisticsLoadingStatus: 'idle',

      userStatistics: {points: {data: []}}, // 近一周贡献点统计

      totalNodeAmount: 0, // 总贡献点数
      maxNodeAmountByDay: 0, // 最大日贡献点数
      maxNodeAmountByDayDate: undefined, // 最大日贡献点对应日期
      recentNodeAmount: 0, // 近一周贡献点数
      nodeAmountThisMonth: 0, // 本月贡献点数
      nodeAmountThisQuarter: 0, // 本季度贡献点数
      nodeAmountThisYear: 0, // 本年贡献点数
    }, () => {
      me.doLoadStatisticsData();
    });
  };

  doStatistics = nodes => {
    let result = {points: {data: []}};

    if (nodes && nodes.length > 0) {
      nodes.forEach(node => {
        let updateTimeObj = moment(node.updateTime, 'YYYY-MM-DD HH:mm:ss');
        if (updateTimeObj.isValid()) {
          let realUpdateTimestamp = updateTimeObj.valueOf();
          let pointY = updateTimeObj.hour() * 60 + updateTimeObj.minute();
          let pointX = (updateTimeObj.second() * 1000 + updateTimeObj.millisecond()) * 24 * 60;
          pointX += updateTimeObj.hour(0).valueOf();
          result.points.data.push([pointX, pointY, node, result.points.data.length, realUpdateTimestamp]);
        }
      });
    }

    return result;
  };

  doLoadStatisticsData = () => {
    let me = this;

    let startDay = moment().hour(0).minute(0).second(0).millisecond(0)
      .subtract(7, 'd');
    let currentDay = moment().hour(0).minute(0).second(0).millisecond(0);

    me.setState(
      {nodeListLoadingStatus: 'processing', nodeAmountStatisticsLoadingStatus: 'processing'},
      () => {
        overrideNextMessage('static::loadNodeListByUser', false);
        NodeDataProvider
          .loadNodeListByUser(me.props.userInfo.userId, startDay.format('YYYY-MM-DD'))
          .then(recentNodes => {
            let userStatistics = me.doStatistics(recentNodes);
            me.setState({
              nodeListLoadingStatus: 'success',
              userStatistics,
              recentNodeAmount: recentNodes.length,
            });
          })
          .catch(error => {
            console.log('获取最新节点列表失败', error);
            me.setState({nodeListLoadingStatus: 'failed'});
          });
        overrideNextMessage('static::batchLoadNodeAmountStatistics', false);
        NodeDataProvider
          .batchLoadNodeAmountStatistics([
            //{conditions: {userId: me.props.userInfo.userId, userConfirmed: 1}}, //2022-08-23 全图积分
            {
              conditions: {userId: me.props.userInfo.userId, userConfirmed: 1},
              statisticsBy: ['vr_date_of_update_time'],
              pagination: {limit: 1},
            },
            {
              conditions: {
                userId: me.props.userInfo.userId,
                userConfirmed: 1,
                // Note: Months are zero indexed, so January is month 0.
                vrYearMonthOfUpdateTime: currentDay.year() * 12 + currentDay.month(),
              },
            },
            {
              conditions: {
                userId: me.props.userInfo.userId,
                userConfirmed: 1,
                // Note: Months are zero indexed, so January is month 0.
                vrYearQuarterOfUpdateTime: currentDay.year() * 4 + Math.floor(currentDay.month() / 3),
              },
            },
            {
              conditions: {
                userId: me.props.userInfo.userId,
                userConfirmed: 1,
                vrYearOfUpdateTime: currentDay.year(),
              },
            },
          ])
          .then(statisticsResultList => {
            if (statisticsResultList.length === 5) {
              me.setState({
                nodeAmountStatisticsLoadingStatus: 'success',
                totalNodeAmount: statisticsResultList[0]['amount'] || 0,
                maxNodeAmountByDay: statisticsResultList[1]['amountList'].length > 0 ?
                  statisticsResultList[1]['amountList'][0]['amount'] : 0,
                maxNodeAmountByDayDate: statisticsResultList[1]['amountList'].length > 0 ?
                  statisticsResultList[1]['amountList'][0].group['vr_date_of_update_time'] : undefined,
                nodeAmountThisMonth: statisticsResultList[2]['amount'] || 0,
                nodeAmountThisQuarter: statisticsResultList[3]['amount'] || 0,
                nodeAmountThisYear: statisticsResultList[4]['amount'] || 0,
              });
            }
          })
          .catch(error => {
            console.log('获取节点数量统计信息失败', error);
            me.setState({nodeAmountStatisticsLoadingStatus: 'failed'});
          });
      });
  };

  componentDidMount() {
    let me = this;

    requestAnimationFrame(() => {
      me.doLoadStatisticsData();
    });
  }

  render() {
    let me = this;

    if (
      me.state.nodeListLoadingStatus === 'success' &&
      me.state.nodeAmountStatisticsLoadingStatus === 'success'
    ) {
      return (
        <div key={'success'} className={style['statistics-result-frame']}>
          <div className={style['statistics-result-chart-frame']}>
            <div className={style['statistics-result-chart-graph']}>
              <StatisticsUserNodeChart userInfo={me.props.userInfo} userStatistics={me.state.userStatistics}/>
            </div>
            <div className={style['statistics-result-chart-statistics']}>
              <table>
                <tbody>
                <tr>
                  <td>全图积分：</td>
                  <td style={{textAlign: 'right'}}>{getDisplayNumber(me.state.totalNodeAmount)}</td>
                </tr>
                <tr>
                  <td>最大单日积分：</td>
                  <td style={{textAlign: 'right'}}>{getDisplayNumber(me.state.maxNodeAmountByDay)}</td>
                </tr>
                <tr>
                  <td>最近一周积分：</td>
                  <td style={{textAlign: 'right'}}>{getDisplayNumber(me.state.recentNodeAmount)}</td>
                </tr>
                <tr>
                  <td>本月度积分：</td>
                  <td style={{textAlign: 'right'}}>{getDisplayNumber(me.state.nodeAmountThisMonth)}</td>
                </tr>
                <tr>
                  <td>本季度积分：</td>
                  <td style={{textAlign: 'right'}}>{getDisplayNumber(me.state.nodeAmountThisQuarter)}</td>
                </tr>
                <tr>
                  <td>本年度积分：</td>
                  <td style={{textAlign: 'right'}}>{getDisplayNumber(me.state.nodeAmountThisYear)}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      );
    } else if (me.state.nodeListLoadingStatus === 'failed' || me.state.nodeAmountStatisticsLoadingStatus === 'failed') {
      return (
        <div key={'failed'} className={style['statistics-result-failed']}>
          <Icon name={'exclamation-circle'} theme={'outlined'} /><br />
          <span>数据加载失败，<a onClick={() => me.onTryAgain()}>点击重试</a></span>
        </div>
      );
    } else {
      // loading
      return (
        <div key={'loading'} className={style['statistics-result-loading']}>
          <Icon name={'loading'} theme={'outlined'} /><br />
          数据加载中，请稍后...
        </div>
      );
    }
  }
}

StatisticsUserNodePanel.defaultProps = {
};

StatisticsUserNodePanel.propTypes = {
  userInfo: PropTypes.object.isRequired,
};

export default StatisticsUserNodePanel;