import util from '@/libs/vis/lib/util';
import InteractionHandler from '@/libs/vis/lib/network/modules/InteractionHandler';

export default class MyInteractionHandler extends InteractionHandler {
  constructor(body, canvas, selectionHandler) {
    super(body, canvas, selectionHandler);
    this.rangeSelect = {
      nodeIds: [],
      edgeIds: [],
      working: false,
      previousSelection: undefined,
      currentSelectedNodeIds: [],
    };
  }

  onDragStart(event) {
    //in case the touch event was triggered on an external div, do the initial touch now.
    if (this.drag.pointer === undefined) {
      this.onTouch(event);
    }
    if (event['changedPointers'][0].ctrlKey || event['changedPointers'][0].metaKey) {
      this.drag.dragging = true;
      this.drag.translation = util.extend({},this.body.view.translation); // copy the object
      this.drag.nodeId = undefined;

      this._clearAndCreateRangeSelectionControl(this.drag.pointer);
    } else {
      super.onDragStart(event);
    }
  }

  onDrag(event) {
    if (this.drag.pinched === true) {
      return;
    }
    this.body.emitter.emit('unlockNode');
    let pointer = this.getPointer(event['center']);

    if (this.rangeSelect.working) {
      this._updateRangeSelectionControl(pointer);

      let leftTopNode = this.body.nodes[this.rangeSelect.nodeIds[0]],
        rightBottomNode = this.body.nodes[this.rangeSelect.nodeIds[3]];
      let overlapObj = {
        left:   Math.min(leftTopNode.x, rightBottomNode.x) - 1,
        top:    Math.min(leftTopNode.y, rightBottomNode.y) + 1,
        right:  Math.max(leftTopNode.x, rightBottomNode.x) + 1,
        bottom: Math.max(leftTopNode.y, rightBottomNode.y) - 1,
      }
      // get the overlapping node but NOT the temporary node;
      let overlappingNodes = this.selectionHandler._getAllNodesOverlappingWith(overlapObj)
        .filter(nodeId => this.rangeSelect.nodeIds.indexOf(nodeId) < 0)
        .map(nodeId => this.body.nodes[nodeId]).filter(n => !!n) || [],
        overlappingNodeIds = [],
        previousSelectedNodeIds = this.rangeSelect.previousSelection.nodes.map(n => n.id);
      overlappingNodes.forEach(node => {
        if (!node.isSelected()) {
          this.selectionHandler.selectObject(node);
        }
        overlappingNodeIds.push(node.id);
      });
      this.rangeSelect.currentSelectedNodeIds.map(nodeId => this.body.nodes[nodeId])
        .forEach(n => {
          if (n && previousSelectedNodeIds.indexOf(n.id) < 0 && overlappingNodeIds.indexOf(n.id) < 0 && n.isSelected()) {
            this.selectionHandler.deselectObject(n);
          }
        });
      this.rangeSelect.currentSelectedNodeIds = overlappingNodeIds;

      this.body.emitter.emit('_redraw');
    } else {
      let selection = this.drag.selection;
      if (selection && selection.length && this.options.dragNodes === true) {
        this.selectionHandler._generateClickEvent('dragging', event, pointer);

        // calculate delta's and new location
        let deltaX = pointer.x - this.drag.pointer.x;
        let deltaY = pointer.y - this.drag.pointer.y;

        // update position of all selected nodes
        selection.forEach((selection) => {
          let node = selection.node;
          // only move the node if it was not fixed initially
          if (selection.xFixed === false) {
            node.x = this.canvas._XconvertDOMtoCanvas(this.canvas._XconvertCanvasToDOM(selection.x) + deltaX);
          }
          // only move the node if it was not fixed initially
          if (selection.yFixed === false) {
            node.y = this.canvas._YconvertDOMtoCanvas(this.canvas._YconvertCanvasToDOM(selection.y) + deltaY);
          }
        });

        // start the simulation of the physics
        if (selection.length <= 1) {
          this.body.emitter.emit('startSimulation');
        } else {
          this.body.emitter.emit('_requestRedraw');
        }
      }
      else {
        // move the network
        if (this.options.dragView === true) {
          this.selectionHandler._generateClickEvent('dragging', event, pointer, undefined, true);

          // if the drag was not started properly because the click started outside the network div, start it now.
          if (this.drag.pointer === undefined) {
            this.onDragStart(event);
            return;
          }
          let diffX = pointer.x - this.drag.pointer.x;
          let diffY = pointer.y - this.drag.pointer.y;

          this.body.view.translation = {x:this.drag.translation.x + diffX, y:this.drag.translation.y + diffY};
          this.body.emitter.emit('_requestRedraw');
        }
      }
    }
  }

  onDragEnd(event) {
    this.drag.dragging = false;
    if (this.rangeSelect.working) {
      let currentSelectedNodeIds = this.selectionHandler.getSelection();
      // See NOTE in method comment for the reason to do it like this
      let deselectedItems = this._determineDifference(this.rangeSelect.previousSelection, currentSelectedNodeIds);
      let selectedItems   = this._determineDifference(currentSelectedNodeIds , this.rangeSelect.previousSelection);
      let selected = false;
      let pointer = this.getPointer(event['center']);
      if (deselectedItems.edges.length > 0) {
        this.selectionHandler._generateClickEvent('deselectEdge', event, pointer, this.rangeSelect.previousSelection);
        selected = true;
      }

      if (deselectedItems.nodes.length > 0) {
        this.selectionHandler._generateClickEvent('deselectNode', event, pointer, this.rangeSelect.previousSelection);
        selected = true;
      }

      if (selectedItems.nodes.length > 0) {
        this.selectionHandler._generateClickEvent('selectNode', event, pointer);
        selected = true;
      }

      if (selectedItems.edges.length > 0) {
        this.selectionHandler._generateClickEvent('selectEdge', event, pointer);
        selected = true;
      }

      // fire the select event if anything has been selected or deselected
      if (selected === true) { // select or unselect
        this.selectionHandler._generateClickEvent('select', event, pointer);
      }
      this._clearRangeSelectionControl();
      this.body.emitter.emit('_redraw');
    } else {
      let selection = this.drag.selection;
      if (selection && selection.length) {
        selection.forEach(function (s) {
          // restore original xFixed and yFixed
          s.node.options.fixed.x = s.xFixed;
          s.node.options.fixed.y = s.yFixed;
        });
        this.selectionHandler._generateClickEvent('dragEnd', event, this.getPointer(event.center));
        if (selection.length <= 1) {
          this.body.emitter.emit('startSimulation');
        } else {
          this.body.emitter.emit('_requestRedraw');
        }
      }
      else {
        this.selectionHandler._generateClickEvent('dragEnd', event, this.getPointer(event.center), undefined, true);
        this.body.emitter.emit('_requestRedraw');
      }
    }
  }

  _clearRangeSelectionControl() {
    this.rangeSelect.edgeIds.forEach(edgeId => {
      this.body.edges[edgeId].disconnect();
      delete this.body.edges[edgeId];
      let indexTempEdge = this.body.edgeIndices.indexOf(edgeId);
      if (indexTempEdge !== -1) {this.body.edgeIndices.splice(indexTempEdge,1);}
    });
    this.rangeSelect.nodeIds.forEach(nodeId => {
      delete this.body.nodes[nodeId];
      let indexTempNode = this.body.nodeIndices.indexOf(nodeId);
      if (indexTempNode !== -1) {this.body.nodeIndices.splice(indexTempNode,1);}
    });
    this.rangeSelect.edgeIds = [];
    this.rangeSelect.nodeIds = [];
    this.rangeSelect.working = false;
    this.rangeSelect.previousSelection = undefined;
    this.rangeSelect.currentSelectedNodeIds = [];
  }

  _clearAndCreateRangeSelectionControl({x, y}) {
    let me = this;

    me._clearRangeSelectionControl();

    x = me.canvas._XconvertDOMtoCanvas(x);
    y = me.canvas._YconvertDOMtoCanvas(y)

    let nodes = [undefined, undefined, undefined, undefined].map(() => {
      // we have to define the bounding box in order for the nodes to be drawn immediately
      let node = me.body.functions.createNode({
        id: `rang-selection-ctrl-node-${util.randomUUID()}`,
        hidden: false,
        physics: false,
        x,
        y,
        shape: 'image',
        image: '/assets/flag/noIcon.svg',
        size: 10,
        color: {background: '#1F252E', border: '#1F252E', highlight: {background: '#1F252E', border: '#1F252E'}},
        borderWidth: 0,
        borderWidthSelected: 0,
      });
      node.shape.boundingBox = {left: x, right:x, top:y, bottom:y};

      me.body.nodes[node.id] = node;
      me.body.nodeIndices.push(node.id);

      return node;
    }), edges = [
      {from: nodes[0].id, to: nodes[1].id}, {from: nodes[0].id, to: nodes[2].id}, {from: nodes[1].id, to: nodes[3].id},
      {from: nodes[2].id, to: nodes[3].id}
    ].map(({from, to}) => {
      let edge = this.body.functions.createEdge({
        id: `rang-selection-ctrl-edge-${util.randomUUID()}`,
        from,
        to,
        width: 0.5,
        physics: false,
        dashes: true,
        smooth: false,
      });

      me.body.edges[edge.id] = edge;
      me.body.edgeIndices.push(edge.id);

      return edge;
    });

    this.rangeSelect.edgeIds = edges.map(e => e.id);
    this.rangeSelect.nodeIds = nodes.map(n => n.id);
    this.rangeSelect.working = true;
    this.rangeSelect.previousSelection = this.selectionHandler.getSelection();
    this.rangeSelect.currentSelectedNodeIds = [];
  }

  _updateRangeSelectionControl({x, y}) {
    if (this.rangeSelect.working) {
      let nodeX = this.body.nodes[this.rangeSelect.nodeIds[1]],
        nodeY = this.body.nodes[this.rangeSelect.nodeIds[2]],
        nodeXY = this.body.nodes[this.rangeSelect.nodeIds[3]];
      nodeX.x = nodeXY.x = this.canvas._XconvertDOMtoCanvas(x);
      nodeY.y = nodeXY.y = this.canvas._YconvertDOMtoCanvas(y);
    }
  }
}