import React from 'react';
import PropTypes from 'prop-types';
import {Route, Redirect, Switch, withRouter} from 'react-router-dom';
import {Layout, Button, Icon, message} from 'antd';

import AccountBasicInfoPanel from '@/components/accountCenter/account.basicInfoPanel';
import AccountSecurityInfoPanel from '@/components/accountCenter/account.securityInfoPanel';
import AccountBindingInfoPanel from '@/components/accountCenter/account.bindingInfoPanel';

import toolbarStyle from '@/style/components/main.toolbar.less';

import style from '@/style/containers/mainView.accountCenter.less';
// import Sidebar from "@/components/mainView/main.sidebar.1.0.1";
import {ChangePwdStatus} from "@/constants/account";

class AccountCenter extends React.Component {
  render () {
    let me = this;
    const {userId: id, nick, name, email, picId: avatarPicId, org, province, city} = this.props.userInfo;
    const basicInfo = {
      id,
      nick: nick || name || email,
      name, avatarPicId, org, province, city,
      changeUserBasicInfoStatus: me.props.changeUserBasicInfoStatus,
      onSaveUserBasicInfo: me.props.onSaveUserBasicInfo,
    };
    return (
      <Layout className={style['container']}>
        <Layout.Header className={style['header']}>
          <div className={`${toolbarStyle['frame']} dark-theme`} style={{paddingLeft: '1.2rem'}}>
            <Button onClick={e => {
                      e.preventDefault();
                      me.props.history.push(`${me.props.baseUri}/base`);
                    }}>
              基本信息<Icon type="idcard" />
            </Button>
            <Button onClick={e => {
                      e.preventDefault();
                      // message.warn('正在建设中……');
                      me.props.history.push(`${me.props.baseUri}/security`);
                    }}>
              安全设置<Icon type="safety" />
            </Button>
            <Button onClick={e => {
                      e.preventDefault();
                      // message.warn('正在建设中……');
                      me.props.history.push(`${me.props.baseUri}/binding`);
                    }}>
              账号绑定<Icon type="link" />
            </Button>
          </div>
        </Layout.Header>
        <Layout.Content className={style["content"]}>
          <Switch>
            <Route path={`${this.props.baseUri}/base`}
                   render={() => <AccountBasicInfoPanel {...basicInfo}/>}/>
            <Route path={`${this.props.baseUri}/security`}
                   render={
                     () => (
                       <AccountSecurityInfoPanel
                         passwordStrength={this.props.userInfo.strength}
                         changePwdStatus={this.props.changePwdStatus}
                         hasPassword={!this.props.userInfo['noPassword']}
                         doChangePwd={this.props.doChangePwd}
                         resetChangePwdStatus={this.props.resetChangePwdStatus}
                       />
                     )
                   }/>
            <Route path={`${this.props.baseUri}/binding`} render={() => <AccountBindingInfoPanel />}/>
            <Route render={() => (<Redirect to={`${this.props.baseUri}/base`} />)} />
          </Switch>
        </Layout.Content>
      </Layout>
    );
  }
}

AccountCenter.defaultProps = {
  baseUri: '',
  changePwdStatus: ChangePwdStatus.INTERACTING,
  onSaveUserBasicInfo: (userInfo) => {
    console.log('property onSaveUserBasicInfo is not set, userInfo: ', userInfo);
  },
  doChangePwd: (oldPwd, newPwd) => {
    console.log('doChangePwd property is not set, old password: %s, new password: %s.', oldPwd, newPwd);
  },
  resetChangePwdStatus: () => {
    console.log('resetChangePwdStatus property is not set.');
  },
};

AccountCenter.propTypes = {
  baseUri: PropTypes.string.isRequired,
  userInfo: PropTypes.object.isRequired,
  changeUserBasicInfoStatus: PropTypes.number,
  changePwdStatus: PropTypes.number,
  onSaveUserBasicInfo: PropTypes.func,
  doChangePwd: PropTypes.func,
  resetChangePwdStatus: PropTypes.func,
};

export default withRouter(AccountCenter);