import React from 'react';
import PropTypes from "prop-types";
import MyNetwork from '@/libs/myVis/MyNetwork3';

import PB, {SimplePB} from "@/libs/simplePB";
import {showPageLoading} from "@/components/common/common.message";
import canvasToImage from "canvas-to-image";
import {Form, Input, InputNumber, Modal, Select, Button, Row, Col, message, Tooltip} from "antd";

// 厘米=>英寸
const ratioToInch = 0.39370078740157480314960629921260;

class GraphExport extends React.PureComponent {
  state = {
    // export
    line: 1, // 第几行
    step: -2, // 正在处理第几片瓦片
    title: '', //
    // defaultImgType: 'image/jpg',
    defaultImgType: 'png', // 导出图片类型
    defaultImgBg: 'rgba(255,255,255,0)', // 导出图片背景色

    dpi: 300,
    unitType: 1, // 0 - 英寸，1 - 厘米

    // export canvas
    height: 120, // 输出画布高度，modal中设置
    width: 300, // 输出画布宽度，modal中设置
    heightPx: 2700,// 输出画布高度像素值，计算得到
    widthPx: 4800,// 输出画布宽度像素值，计算得到
    heightScreen: 675, // 预览画布的高度，根据显示器高度计算
    widthScreen: 1200, // 预览画布的宽度，根据显示器宽度计算

    // vis
    containerId: undefined, // vis容器
    visScaleValue: 1, // 此时的vis缩放值
    scale: 4, // 切片倍数
    setScale: 1, // 废弃
    targetFontSizeHeight: 0.4, // 单位厘米
    newNodeColor: '', // 节点颜色
    newEdgeColor: '', // 边颜色

    // modal
    modalMarginTop: 80, // 弹框距离顶部距离
    modalMarginRight: 100, // 弹框距离右边距离
  };
  // 临时存储canvas图的临时canvas id
  tempCanvasId = `graph-export-${Math.random()}`;
  // 高级导出时的配置数据
  _advanceExportInitOptions = {x: 0, y: 0, scale: 1, height: 0, width: 0};

  componentDidMount() {
    const {bus, myNetwork} = this.props;
    let me = this;

    // 正常导出，瓦片导出，导出当前画布的图片
    bus.sub(me, 'graph', 'export.do', (options) => {
      // let {containerId, title, type, filename, withMark} = options;
      let hideMsgFn = showPageLoading('正在将图谱画面转换成图片，请耐心等待...');
      this.saveVisCanvasToImg(options, () => {
        // 移除遮罩
        hideMsgFn();
      });
    });

    // 高级导出图片，弹出设置窗口
    bus.sub(me, 'graph', 'advance_export.do', ({containerId, title}) => {
      me.setState({step: -1, title, containerId}, me.reCalculateSize);
    });

    // vis zoom 时实时显示缩放数值
    bus.sub(me, 'network', 'zooming', (scale) => {
      // console.log('zooming ', scale);
      me.setState({visScaleValue: scale});
    });

    // =============================
    // 测试
    bus.sub(me, 'network', 'advance_export.test', (a = 0) => {
      console.log('advance_export.test a=', a);
    });
    // =============================


  }

  /**
   * 用新尺寸设置画布尺寸(显示尺寸)
   */
  reCalculateSize = () => {
    let me = this,
      heightInch = me.state.unitType === 0 ? me.state.height : me.state.height * ratioToInch, // 输出高度英寸
      widthInch = me.state.unitType === 0 ? me.state.width : me.state.width * ratioToInch, // 输出宽度英寸
      heightPx = heightInch * me.state.dpi,// 输出高度像素
      widthPx = widthInch * me.state.dpi,// 输出宽度像素
      heightScreenMax = window.innerHeight - 40, // 预览画布最大高度 像素
      widthScreenMax = window.innerWidth, // 预览画布最大宽度 像素
      heightRatio = Math.ceil(heightPx / heightScreenMax),// 预览画布高度缩放比例
      widthRatio = Math.ceil(widthPx / widthScreenMax),// 预览画布宽度缩放比例
      scale = Math.ceil(Math.max(heightRatio, widthRatio) / 2) * 2, // 预览画布的缩放比例
      heightScreen = Math.floor(heightPx / scale), // 预览画布高度 像素
      widthScreen = Math.floor(widthPx / scale); // 预览画布宽度 像素

    // 使用 Math.ceil 使得尺寸会有些许错位，这里用于修正，使得scale尺寸准确
    heightPx = heightScreen * scale; // 输出画布高度像素
    widthPx = widthScreen * scale; // 输出画布宽度像素

    me.setState({
      heightPx,
      widthPx,
      heightScreen,
      widthScreen,
      scale: scale / 2,
    }, () => {
      let container = document.getElementById(me.state.containerId);
      if (container) {
        container.style.marginTop = '2.8rem'; // 菜单栏的高度
        container.style.height = heightScreen + 'px';
        container.style.width = widthScreen + 'px';
        container.style.border = 'white 1px solid';
        container.style.boxSizing = 'content-box';
        container.style.position = 'absolute';
      }
    });
  };

  /**
   * 重置vis图尺寸
   */
  resetSize = () => {
    let me = this, container = document.getElementById(me.state.containerId);
    if (container) {
      container.style.marginTop = '';
      container.style.height = '';
      container.style.width = '';
      container.style.border = '';
      container.style.boxSizing = '';
      container.style.position = '';
    }
  };

  /**
   * 开始高级导出
   */
  startAdvancedExport = () => {
    // alert('startAdvancedExport');
    let me = this;
    // 初始化高级导出参数
    let {x, y} = me.props.myNetwork.network.getViewPosition();
    let lt = me.props.myNetwork.network.DOMtoCanvas({x: 0, y: 0});
    me._advanceExportInitOptions = {
      x,
      y,
      scale: me.props.myNetwork.network.getScale() * 2,
      height: 2 * (y - lt.y),
      width: 2 * (x - lt.x),
      l: lt.x,
      t: lt.y,
      pre_x:0,
      pre_y:0
    };

    // 切片的小窗口
    let container = document.getElementById(me.state.containerId);
    if (container) {
      container.style.height = me.state.heightScreen * 2 + 'px';
      container.style.width = me.state.widthScreen * 2 + 'px';
    }
    me.setState({step: 0});
  };

  /**
   * 开始逐步导出
   */
  startDoStep = () => {
    let hideMsgFn = showPageLoading('正在将图谱画面转换成图片，请耐心等待...');
    this.doNextStep(() => {
      hideMsgFn();
    });
  };

  /**
   * 处理瓦片
   */
  doNextStep = (callback) => {
    const {defaultImgType} = this.state;
    let me = this;
    if (me.state.step >= ((me.state.scale * me.state.scale))) {
      message.success('步骤完毕完毕');
      me.setState({step: -2}, () => {
        me.resetSize();
        callback && callback();
      });
    } else {
      me.setState({step: me.state.step + 1},
        () => {
          const {step, scale} = me.state;
          message.success('进行步骤：' + me.state.step, 2);

          let line = Math.floor(me.state.step / me.state.scale) + 1;
          let line_ = me.state.step % me.state.scale;
          if (line_ === 0) {
            line = line - 1;
          }

          let realScale = scale * me._advanceExportInitOptions.scale,
            stepHeight = me._advanceExportInitOptions.height / scale,
            stepWidth = me._advanceExportInitOptions.width / scale,
            centerY = me._advanceExportInitOptions.t + (Math.floor((step - 1) / scale) + 0.5) * stepHeight,
            centerX = me._advanceExportInitOptions.l + ((step - 1) - Math.floor((step - 1) / scale) * scale + 0.5) * stepWidth;
          // console.log('advance_export realScale= ', realScale);
          // console.log('advance_export ', Math.floor((step - 1) / scale) + 0.5);
          // console.log('advance_export ', (step - 1) - Math.floor((step - 1) / scale) * scale + 0.5);
          if(line==1 && step==1){
            me._advanceExportInitOptions.pre_y = me._advanceExportInitOptions.t + (Math.floor((step - 1) / scale) + 0.5) * stepHeight;
            me._advanceExportInitOptions.pre_x = me._advanceExportInitOptions.l + ((step - 1) - Math.floor((step - 1) / scale) * scale + 0.5) * stepWidth;
          }
          let cY = me._advanceExportInitOptions.pre_y + stepHeight * (line - 1),
            cX = me._advanceExportInitOptions.pre_x + stepWidth * ((step-scale*(line - 1)) % (scale+1) - 1);
          me.props.myNetwork.network.moveTo({
            //position: {x: centerX, y: centerY},
            position: {x: cX, y: cY},
            scale: realScale,
            animation: false,
          });
          // me.props.bus.emit('network', 'advance_export.step', {step: me.state.step, scale: me.state.scale});
          setTimeout(() => {
            me.saveVisCanvasToImg({
              containerId: me.state.containerId,
              title: false,
              scale: 1,
              withMark: false,
              type: defaultImgType,
              filename: `${me.state.title}-${line}-${me.state.step}`,
            }, () => {
              me.doNextStep(callback);
            });
          }, 3000); // 3000 3秒过后画面移动到下一个瓦片
        }
      );
    }
  };

  /**
   * 设置画面缩放值，保证字体的大小
   */
  loopSetNetworkScale = () => {
    const {dpi, unitType, height, heightPx, widthPx, targetFontSizeHeight, scale, containerId} = this.state;
    let hideMsgFn = showPageLoading('正在调整图谱字体大小，请耐心等待...');
    // 字体高度转换成像素值
    let targetFontHeightPx = targetFontSizeHeight * ratioToInch * dpi;
    // let targetFontHeightPx = targetFontSizeHeight * ratioToInch * this.js_getDPI()[0];
    // let targetFontHeightPx = targetFontSizeHeight * ratioToInch * dpi;
    // 图谱当前座标
    let xy = this.props.myNetwork.network.getViewPosition();
    // console.log('➷ GraphExport ', 'setNetworkScale ,this.js_getDPI(): ', this.js_getDPI());
    // console.log('➷ GraphExport ', 'setNetworkScale ,targetFontHeightPx: ', targetFontHeightPx);
    let me = this;
    let loop = false; // 不循环
    this.setNetworkScale(this.props.myNetwork.network, scale, targetFontHeightPx, xy, () => {
      // 多做几次更接近数值
      hideMsgFn();
      message.success('调整完毕');
    }, loop);
  };

  /**
   * 设置画面缩放值，保证字体的大小
   */
  setNetworkScale = (network, scale, targetFontHeightPx, xy, callback, loop = true) => {
    let me = this;
    let targetScale = (targetFontHeightPx / 20) / scale; // 上一版 较准确值
    if (!!loop) {
      // vis画面当前缩放值
      targetScale = network.getScale();
      let wordWidthPx = 11 * targetScale; // 单个字的宽度
      let fh = wordWidthPx - targetFontHeightPx > 0 ? -1 : 1;
      if (Math.abs(wordWidthPx - targetFontHeightPx) < 0.005) {
        // 认为相等
        callback && callback();
        return true;
        // } else if (Math.abs(wordWidthPx - targetFontHeightPx) > 2) {
        //     targetScale = targetScale * (1 + 0.5 * fh);
      } else if (Math.abs(wordWidthPx - targetFontHeightPx) > 1) {
        targetScale = targetScale * (1 + 0.1 * fh);
      } else {
        targetScale = targetScale * (1 + 0.001 * fh);
      }
      // console.log('➷ GraphExport ', 'setNetworkScale ,targetFontHeightPx: ', targetFontHeightPx);
      // console.log('➷ GraphExport ', 'setNetworkScale ,wordWidthPx: ', wordWidthPx);
      // console.log('➷ GraphExport ', 'setNetworkScale ,targetScale: ', targetScale);
      // targetScale = 1; // 测试
      network.moveTo({
        'position': xy,
        'scale': targetScale,
        'animation': {
          'duration': 100,
        },
      });
      setTimeout(() => {
        me.setNetworkScale(network, targetFontHeightPx, xy, callback);
      }, 500)
    } else {
      // targetScale = 1; // 测试
      network.moveTo({
        'position': xy,
        'scale': targetScale,
        'animation': {
          'duration': 100,
        },
      });
      setTimeout(() => {
        callback && callback();
      }, 150)
    }
  };
  /**
   * vis 图定位到中心点(0,0)
   */
  setNetworkCenter = () => {
    let scale = this.props.myNetwork.network.getScale();
    this.props.myNetwork.network.moveTo({
      'position': {x: 0, y: 0},
      'scale': scale,
      'animation': {
        'duration': 100,
      },
    });
  };

  /**
   * 获取vis画面的中心点座标
   */
  getNetworkCenter = () => {
    // this.setState({
    //     visScaleValue: this.props.myNetwork.network.getViewPosition(),
    // });
    console.log('advance_export 中心点:', this.props.myNetwork.network.getViewPosition());
  };

  /**
   * 获取vis画面的中心点座标
   */
  getNetworkScale = () => {
    this.setState({
      visScaleValue: this.props.myNetwork.network.getScale(),
    });
  };

  /**
   * 保存canvas到图片
   * @param options 导出配置
   * @param callback 结束回调
   */
  saveVisCanvasToImg = (options, callback) => {
    // console.log('➷ GraphExport ', 'saveVisCanvasToImg: ', options);
    const {defaultImgType} = this.state;
    const me = this;
    let {
      containerId, // network 的容器
      title,
      type,
      scale,
      filename,
      withMark,
    } = options;
    filename = filename || `炬图 - ${title}`; // 文件名称
    withMark = withMark !== false; // 是否带水印
    const container = document.getElementById(containerId); // vis network 的容器
    if (['jpg', 'jpeg', 'png'].indexOf(type) === -1) {
      type = defaultImgType;
    }
    let fileExt = type === 'jpeg' ? 'jpg' : type;
    console.log('advance_export -> saveVisCanvasToImg -> fileExt:', fileExt);

    // 画布容器的宽高
    let originalWH = {
      w: parseInt(me.getStyle(container, 'width'), 10),
      h: parseInt(me.getStyle(container, 'height'), 10),
    };
    let targetWH = {
      w: 0,
      h: 0,
    };
    let hf = false;// 导出后是否需要恢复
    if (!scale) {
      // 没有设置画面缩放比例的情况下，输出画面最大尺寸 1920 * 3 ，1080 * 3
      if ((1920 * 3 / originalWH.w) > (1080 * 3 / originalWH.h)) {
        targetWH.h = 1080 * 3;
        targetWH.w = originalWH.w * (1080 * 3 / originalWH.h);
      } else {
        targetWH.w = 1920 * 3;
        targetWH.h = originalWH.h * (1920 * 3 / originalWH.w);
      }
    } else {
      targetWH = {
        w: originalWH.w * scale,
        h: originalWH.h * scale,
      };
    }

    //  重新设置容器的宽高
    container.style.width = targetWH.w + 'px';
    container.style.height = targetWH.h + 'px';
    let canvas = container.getElementsByTagName('canvas')[0];

    setTimeout(() => {
      me.setCanvasMarkText(canvas, withMark, '炬图', 20, title, document.getElementById(me.tempCanvasId));
      // 导出下载
      setTimeout(() => {
        canvasToImage(me.tempCanvasId, {
          name: filename,
          type: fileExt,
        });
        setTimeout(() => {
          if (targetWH.w != originalWH.w && targetWH.h != originalWH.h) { // 移除画布的下载样式，恢复到原来尺寸
            container.style.width = '100%';
            container.style.height = '100%';
          }

          callback && callback();
        }, 5000); // 5000 5秒是等canvasToImage能够完成
      }, 3000); //3000
    }, 2000); //2000
  };

  /**
   * canvas 加水印
   * @param canvas 原图canvas
   * @param withMark 是否有水印
   * @param text 水印文字
   * @param fsz 水印文字大小
   * @param title 标题
   * @param tempCanvas 中专画面的临时canvas
   */
  setCanvasMarkText = (canvas, withMark, text, fsz, title, tempCanvas) => {
    // console.log('➷ GraphExport -> setCanvasMarkText: ');

    //图片加载完成，才可处理
    if (!text) {
      text = '炬图'
    }
    text = '';
    if (!fsz) {
      fsz = 80
    }
    // 1、放大关系图canvas
    // 2、新建一个和放大的canvas一样大小的临时canvas
    // 2、底部画上水印
    // 3、把关系图的图片画到新的canvas上
    // 4、下载新的canvas图片

    let tpl_canvas; // 水印文字的临时canvas
    // noinspection JSValidateTypes
    tpl_canvas = document.createElement('canvas');
    tpl_canvas.width = 200;
    tpl_canvas.height = 200;


    let tpl_canvas_repeat = tempCanvas;
    tpl_canvas_repeat.width = canvas.width;
    tpl_canvas_repeat.height = canvas.height;
    const tpl_canvas_repeat_ctx = tpl_canvas_repeat.getContext('2d');   // 返回一个用于在画布上绘图的环境
    tpl_canvas_repeat_ctx.clearRect(0, 0, tpl_canvas_repeat.width, tpl_canvas_repeat.height);  // 清除整个画布

    // 背景色
    // tpl_canvas_repeat_ctx.fillStyle = "rgba(255,255,255,1)"; // 白底
    // tpl_canvas_repeat_ctx.fillStyle = "rgba(0,0,0,1)"; // 黑底
    tpl_canvas_repeat_ctx.fillStyle = "rgba(255,255,255,0)"; // 透明

    tpl_canvas_repeat_ctx.fillRect(0, 0, tpl_canvas_repeat.width, tpl_canvas_repeat.height);

    // 加水印
    if (withMark) {
      const tpl_canvas_ctx = tpl_canvas.getContext('2d');   //返回一个用于在画布上绘图的环境
      // tpl_canvas_ctx.clearRect(0, 0, 100, 100);  // 绘制之前画布清除
      tpl_canvas_ctx.font = fsz + "px 黑体";
      tpl_canvas_ctx.rotate(-45 * Math.PI / 180);
      tpl_canvas_ctx.fillStyle = "rgba(155,155,155,0.2)";
      tpl_canvas_ctx.fillText(text, -20, 80);
      // tpl_canvas_ctx.rotate(20 * Math.PI / 180);  //全部旋转了角度，这里还原

      // 在指定的方向上重复指定的元素
      tpl_canvas_repeat_ctx.fillStyle = tpl_canvas_repeat_ctx.createPattern(tpl_canvas, 'repeat');
      tpl_canvas_repeat_ctx.fillRect(0, 0, tpl_canvas_repeat.width, tpl_canvas_repeat.height);
    }

    // 复制原图到临时canvas,在指定的方向上重复指定的元素
    tpl_canvas_repeat_ctx.fillStyle = tpl_canvas_repeat_ctx.createPattern(canvas, 'no-repeat');
    tpl_canvas_repeat_ctx.fillRect(0, 0, tpl_canvas_repeat.width, tpl_canvas_repeat.height);

    // 加标题
    if (title) {
      tpl_canvas_repeat_ctx.font = "40px 黑体";
      tpl_canvas_repeat_ctx.fillStyle = "rgba(255,255,255,0.85)";
      tpl_canvas_repeat_ctx.textAlign = "center";
      tpl_canvas_repeat_ctx.textBaseline = "middle";
      tpl_canvas_repeat_ctx.fillText(title, tpl_canvas_repeat.width / 2, 30);
    }
  };

  /**
   * 获取元素的样式属性，兼容多个情况，不保证全部兼容
   * @param el
   * @param attr
   * @returns {string|*}
   */
  getStyle = (el, attr) => {
    if (el['currentStyle']) { // ie浏览器
      return el['currentStyle'][attr];
    } else {
      return getComputedStyle(el)[attr]; // 火狐浏览器
    }
  };

  // 自定义 节点 边 得颜色
  changeNetworkNodeColor = () => {
    console.log('changeNetworkColor node', this.state.newNodeColor);
    let me = this;
    let {newNodeColor} = this.state;
    newNodeColor = newNodeColor.toUpperCase();
    let i_todo = [];
    if (newNodeColor && newNodeColor.length === 7) {
      console.log('changeNetworkColor -> node color=', this.state.newNodeColor);
      // 判断是否是16进制颜色
      this.props.myNetwork.graph.nodes.forEach(n => {
        // if (n.font.color.toUpperCase() != newNodeColor || n.color.background.toUpperCase() != newNodeColor) {
        n.font.color = newNodeColor;
        // n.color.background = newNodeColor;
        i_todo.push(n);
        // }
      });
      console.log('changeNetworkColor -> node length=', i_todo.length);
      me.props.myNetwork.graph.nodes.update(i_todo);
    }


  }
  // 自定义 节点 边 得颜色
  changeNetworkEdgeColor = () => {
    console.log('changeNetworkColor edge', this.state.newEdgeColor);
    let me = this;
    let {newEdgeColor} = this.state;
    newEdgeColor = newEdgeColor.toUpperCase();
    let i2_todo = [];
    if (this.state.newEdgeColor && this.state.newEdgeColor.length === 7) {
      console.log('changeNetworkColor -> edge color=', this.state.newEdgeColor);
      // 判断是否是16进制颜色
      this.props.myNetwork.graph.edges.forEach(n => {
        // if (n.font.color.toUpperCase() != newEdgeColor || n.color.color.toUpperCase() != newEdgeColor) {
        n.font.color = newEdgeColor;
        n.color.color = newEdgeColor;
        i2_todo.push(n);
        // }
      });
      console.log('changeNetworkColor -> edge length=', i2_todo.length);
      me.props.myNetwork.graph.edges.update(i2_todo);
    }


  }

  componentWillUnmount() {
    this.props.bus.remove(this);
  }

  render() {
    let me = this;
    // 表单的label与input对齐样式
    const formItemLayout_12 = {
      // labelCol: {
      //   xs: {span: 8},
      //   sm: {span: 6},
      // },
      // wrapperCol: {
      //   xs: {span: 16},
      //   sm: {span: 18},
      // },
    };


    const formItemStyle = {
      style: {
        width: '99%'
      }
    };


    const formItemLayout_24 = {
      // labelCol: {
      //   xs: {span: 8},
      //   sm: {span: 3},
      // },
      // wrapperCol: {
      //   xs: {span: 16},
      //   sm: {span: 21},
      // },
    };

    let scale_step = 0.01;

    return (
      <div style={{height: 0, width: 0, overflow: 'hidden'}}>
        <canvas id={me.tempCanvasId}/>
        <Modal
          title={'高级导出设置'}
          visible={me.state.step === -1}
          okText={'开始'}
          onOk={me.startAdvancedExport}
          cancelText={'关闭'}
          onCancel={() => me.setState({step: -2}, me.resetSize)}
          mask={false}
          maskClosable={false}
          wrapClassName={'pointer-event__none'}
          className={'all-pointer-event'}
          style={{
            "marginTop": this.state.modalMarginTop + "px",
            "marginRight": this.state.modalMarginRight + "px",
          }}
          width={600}
        >
          <Row>
            <Col span={16}>
              <Form layout={'vertical'}>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      {...formItemLayout_24}
                      label={'名称：'}
                    >
                      <Input value={me.state.title} onChange={v => me.setState({title: v})}/>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      {...formItemLayout_12}
                      label={'DPI：'}
                      help={'数值在 75-300 之间'}
                    >
                      <InputNumber
                        formItemStyle
                        {...formItemStyle}
                        precision={0} min={32} value={me.state.dpi}
                        onChange={v => me.setState({dpi: v})}/>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      {...formItemLayout_12}
                      label={'单位：'}
                    >
                      <Select
                        {...formItemStyle}
                        value={me.state.unitType}
                        onChange={v => me.setState({unitType: v}, me.reCalculateSize)}
                      >
                        <Select.Option value={0}>英寸</Select.Option>
                        <Select.Option value={1}>厘米</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      {...formItemLayout_12}
                      label={me.state.unitType === 1 ? '高度：(厘米)' : '高度：(英寸)'}
                    >
                      <InputNumber
                        {...formItemStyle}
                        min={0} value={me.state.height}
                        onChange={v => me.setState({height: v}, me.reCalculateSize)}/>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      {...formItemLayout_12}
                      label={me.state.unitType === 1 ? '宽度：(厘米)' : '宽度：(英寸)'}
                    >
                      <InputNumber
                        {...formItemStyle}
                        min={0} value={me.state.width}
                        onChange={v => me.setState({width: v}, me.reCalculateSize)}/>
                    </Form.Item>
                  </Col>
                </Row>

                <Row>

                  <Col span={12}>
                    <Form.Item
                      {...formItemLayout_12}
                      label={'字高：'}
                      help={'估算值,不作为实际字高'}
                    >
                      <InputNumber
                        {...formItemStyle}
                        min={0}
                        value={me.state.targetFontSizeHeight}
                        onChange={v => me.setState({targetFontSizeHeight: v}, me.reCalculateSize)}/>
                    </Form.Item>
                  </Col>
                  <Col span={12}>

                  </Col>
                </Row>
                <Row>

                  <Col span={12}>
                    <Form.Item
                      {...formItemLayout_12}
                      label={'节点颜色：'}
                      help={'16进制颜色值,默认不填'}
                    >
                      <Input
                        {...formItemStyle}
                        defaultValue={me.state.newNodeColor}
                        onChange={e => {
                          console.log('changeNetworkColor e=', e);
                          const {value} = e.target;
                          me.setState({newNodeColor: value}, () => {
                            me.changeNetworkNodeColor();
                          })
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      {...formItemLayout_12}
                      label={'边颜色：'}
                      help={'16进制颜色值,默认不填'}
                    >
                      <Input
                        {...formItemStyle}
                        defaultValue={me.state.newEdgeColor}
                        onChange={e => {
                          console.log('changeNetworkColor e=', e);
                          const {value} = e.target;
                          me.setState({newEdgeColor: value}, () => {
                            me.changeNetworkEdgeColor();
                          })
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      {...formItemLayout_12}
                      label={'对话框位置：'}
                    >
                      顶部：<InputNumber min={50} step={50} value={me.state.modalMarginTop}
                                      onChange={v => me.setState({modalMarginTop: v})}/>
                      右边：<InputNumber min={50} step={50} value={me.state.modalMarginRight}
                                      onChange={v => me.setState({modalMarginRight: v})}/>

                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Col>
            <Col span={8}>
              <div style={{padding: "8px 8px 8px 10px"}}>
                <strong>整图尺寸</strong>:<br/>高：{me.state.heightPx}像素，<br/>宽：{me.state.widthPx}像素
                <br/>
                <strong>小窗尺寸</strong>：<br/>高：{me.state.heightScreen}像素，<br/>宽：{me.state.widthScreen}像素
                <br/>
                <strong>切片数量</strong>：<br/>{me.state.scale} * {me.state.scale} = {me.state.scale * me.state.scale} 张
                <br/>
                <strong>当前缩放值</strong>: <br/>{this.state.visScaleValue}
                <br/>
                <br/>


                <strong>画面微调</strong>: <br/>
                <table style={{width: '100px', height: '100px', margin: '0 auto'}}>
                  <tbody>
                  <tr>
                    <td align={'center'}></td>
                    <td align={'center'}>
                      <Tooltip title="微调上移">
                        <Button size={'small'} onClick={() => {
                          let this_xy = this.props.myNetwork.network.getViewPosition();
                          this_xy.y = this_xy.y + me.state.heightScreen * 0.005;
                          this.props.myNetwork.network.moveTo({
                            'position': this_xy,
                            'animation': false,
                          });
                        }}>↑</Button>
                      </Tooltip>
                    </td>
                    <td align={'center'}></td>
                  </tr>
                  <tr>
                    <td align={'center'}>
                      <Tooltip title="微调左移">
                        <Button size={'small'} onClick={() => {
                          let this_xy = this.props.myNetwork.network.getViewPosition();
                          this_xy.x = this_xy.x + me.state.widthScreen * 0.005;
                          this.props.myNetwork.network.moveTo({
                            'position': this_xy,
                            'animation': false,
                          });
                        }}>←</Button>
                      </Tooltip>
                    </td>
                    <td align={'center'}>
                      <Tooltip title="移至画布中心，不缩放">
                        <Button size={'small'} onClick={() => {
                          this.setNetworkCenter();
                        }}>⊙</Button>
                      </Tooltip>
                    </td>
                    <td align={'center'}>
                      <Tooltip title="微调右移">
                        <Button size={'small'} onClick={() => {
                          let this_xy = this.props.myNetwork.network.getViewPosition();
                          this_xy.x = this_xy.x - me.state.widthScreen * 0.005;
                          this.props.myNetwork.network.moveTo({
                            'position': this_xy,
                            'animation': false,
                          });
                        }}>→</Button>
                      </Tooltip>
                    </td>
                  </tr>
                  <tr>
                    <td align={'center'}>
                      <Tooltip title="微调放大">
                        <Button size={'small'} onClick={() => {
                          let this_xy = this.props.myNetwork.network.getViewPosition();
                          let this_scale = this.props.myNetwork.network.getScale();
                          this.props.myNetwork.network.moveTo({
                            'position': this_xy,
                            'scale': this_scale * 1.005,
                            'animation': false,
                          })
                          setTimeout(() => {
                            me.getNetworkScale();
                          }, 1000)
                        }}>＋</Button>
                      </Tooltip>
                    </td>
                    <td align={'center'}>
                      <Tooltip title="微调下移">
                        <Button size={'small'} onClick={() => {
                          let this_xy = this.props.myNetwork.network.getViewPosition();
                          this_xy.y = this_xy.y - me.state.heightScreen * 0.005;
                          this.props.myNetwork.network.moveTo({
                            'position': this_xy,
                            'animation': false,
                          });
                        }}>↓</Button>
                      </Tooltip>
                    </td>
                    <td align={'center'}>
                      <Tooltip title="微调缩小">
                        <Button size={'small'} onClick={() => {
                          let this_xy = this.props.myNetwork.network.getViewPosition();
                          let this_scale = this.props.myNetwork.network.getScale();
                          this.props.myNetwork.network.moveTo({
                            'position': this_xy,
                            'scale': this_scale * 0.995,
                            'animation': false,
                          })
                          setTimeout(() => {
                            me.getNetworkScale();
                          }, 1000)
                        }}>－</Button>
                      </Tooltip>
                    </td>
                  </tr>
                  <tr>
                    <td align={'center'}></td>
                    <td align={'center'}></td>
                    <td align={'center'}></td>
                  </tr>
                  </tbody>
                </table>
                <br/>
                <Button block onClick={() => {
                  this.loopSetNetworkScale();
                }}>估算缩放</Button>

              </div>

            </Col>
          </Row>
        </Modal>
        <Modal
          title={'高级导出进行中'}
          visible={me.state.step >= 0}
          onCancel={() => me.setState({step: -2}, me.resetSize)}
          onOk={me.startDoStep}
          okText={me.state.step === me.state.scale * me.state.scale ? '完成' : (me.state.step === 0 ? '开始' : '下一步')}
          cancelText={'关闭'}
        >
          {me.state.step > 0 ? `正在进行第 ${me.state.step} 步，` : ''}共 {me.state.scale * me.state.scale} 步。
        </Modal>
      </div>
    );
  }
}

GraphExport.defaultProps = {
  bus: PB,
  myNetwork: undefined,
};

GraphExport.propTypes = {
  bus: PropTypes.instanceOf(SimplePB),
  myNetwork: PropTypes.instanceOf(MyNetwork),
};

export default GraphExport;
