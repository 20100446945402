import React from 'react';
import PropTypes from 'prop-types';
import {Button, Modal, Tag, message, List} from "antd";
import ReactWordCloud from 'react-wordcloud';

import Node from '@/libs/view/Node';

import loadingTags from '@/constants/loadingTags';

import style from '@/style/containers/explorationView.less';
import {
  getNodeDisplayTitle,
  getNodeIcon,
  /*getNodeIconByType,*/
  NODE_TYPE_TAG,
  NODE_TYPE_TEXT,
  TYPE_FIELD_NAME,
} from "@/constants/vis.defaultDefine.1";
import Icon from "@/components/common/common.icon";
import {IconTypes} from "@/constants/common";

const MaxParallelRequest = 3;

const STEP_DETECTION = 0;
const STEP_EXPLORATION = 1;
const STEP_SELECT_TYPE = 2;
const STEP_SELECT_TAG = 3;

const randomTags = (() => {
  const existedIdxList = [];
  return amount => {
    const currentIdxList = [];
    let itemsToRemove = existedIdxList.length - Math.min(3 * amount, loadingTags.length - amount);
    if (itemsToRemove > 0) {
      existedIdxList.splice(0, itemsToRemove);
    }
    if (amount >= loadingTags.length) {
      return loadingTags.map(text => ({text, value: Math.floor(Math.random() * 3) + 1}));
    }
    while (currentIdxList.length < amount) {
      let idx = Math.floor(Math.random() * loadingTags.length);
      if (!existedIdxList.includes(idx)) {
        currentIdxList.push(idx);
        existedIdxList.push(idx);
      }
    }
    return currentIdxList.map(
      idx => ({text: loadingTags[idx], value: Math.floor(Math.random() * 3) + 1}));
  }
})();

/*class ResourceDetectionList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resourceDetectionListStatus: 'idle', // 资源识别状态
      resourceDetectionUserSelection: undefined, // 用户选择的资源
      resourceDetectionListData: [], // 资源识别结果

      // 加载过程中背景图标类型
      resourceDetectionListLoadingBackgroundType: props.resourceTypes[
        Math.floor(Math.random() * props.resourceTypes.length)].value,
    };
  }

  backgroundSwitchInterval = 0;

  componentDidMount() {
    let me = this;

    me.backgroundSwitchInterval = setInterval(() => {
      let rndResourceType = me.props.resourceTypes[Math.floor(Math.random() * me.props.resourceTypes.length)].value;
      while (rndResourceType === me.state.resourceDetectionListLoadingBackgroundType) {
        rndResourceType = me.props.resourceTypes[Math.floor(Math.random() * me.props.resourceTypes.length)].value;
      }
      me.setState({resourceDetectionListLoadingBackgroundType: rndResourceType});
    }, 2000);

    requestAnimationFrame(() => me.doGetResourceList());
  }

  componentWillUnmount() {
    if (this.backgroundSwitchInterval) {
      clearInterval(this.backgroundSwitchInterval);
    }
  }

  /!**
   * 尝试根据关键词获取已存在资源实体
   *!/
  doGetResourceList = () => {
    let me  = this;

    me.setState({resourceDetectionListStatus: 'processing'}, () => {
      me.props.doLoadMatchedResources().then(resources => {
        if (me.backgroundSwitchInterval) {
          clearInterval(me.backgroundSwitchInterval);
        }
        me.setState({resourceDetectionListStatus: 'success', resourceDetectionListData: resources});
      }).catch(error => {
        if (me.backgroundSwitchInterval) {
          clearInterval(me.backgroundSwitchInterval);
        }
        console.log('doGetResourceList - failed: ', error);
        me.setState({resourceDetectionListStatus: 'failed'});
      });
    });
  };

  render() {
    let me = this;
    let content = null;

    if (me.state.resourceDetectionListStatus === 'success' && me.state.resourceDetectionListData.length > 0) {
      content = (
        <List
          dataSource={me.state.resourceDetectionListData}
          renderItem={node => {
            const iconConfig = getNodeIcon(node);
            delete iconConfig.color;
            return (
              <List.Item
                key={`r-${node.id}`}
                onClick={
                  () => me.setState({
                    resourceDetectionUserSelection:
                      (
                        me.state.resourceDetectionUserSelection &&
                        node.id === me.state.resourceDetectionUserSelection.id
                      ) ? undefined : node,
                  }, () =>
                    me.props.onSelectionChanged(me.state.resourceDetectionUserSelection))
                }
                className={(
                  me.state.resourceDetectionUserSelection === undefined ? 'none-selected' : (
                    me.state.resourceDetectionUserSelection.id === node.id ? 'selected' : null
                  )
                )}
              >
                <List.Item.Meta
                  avatar={(
                    <div className={'resource-avatar'}>
                      <Icon {...iconConfig}/>
                    </div>
                  )}
                  title={getNodeDisplayTitle(node)}
                  description={`[ ${node.tag.split(' ', 3).join(' ')} ]`}
                />
              </List.Item>
            );
          }}
        />
      );
    } else if (me.state.resourceDetectionListStatus === 'idle' || me.state.resourceDetectionListStatus === 'processing') {
      content = (
        <div className={style['resource-detection-list-loading']}>
          <div className={style['resource-detection-list-loading-background']}>
            <Icon {...getNodeIconByType(me.state.resourceDetectionListLoadingBackgroundType)} />
          </div>
          <div className={style['resource-detection-list-loading-message']}>这里是说明文本</div>
        </div>
      );
    } else {
      content = (
        <div className={style['resource-detection-list-failed']}>
          <Icon name={'icon-robot'} type={IconTypes.ICON_FONT} />
          <span>这里是说明文本</span>
        </div>
      );
    }

    return (
      <div className={style['resource-detection-list-frame']}>
        {content}
      </div>
    )
  }
}

ResourceDetectionList.defaultProps = {
  resourceTypes: [],
};

ResourceDetectionList.propTypes = {
  onSelectionChanged: PropTypes.func.isRequired,
  doLoadMatchedResources: PropTypes.func.isRequired,
  resourceTypes: PropTypes.array,
};*/

class ResourceDetectionTags extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resourceDetectionTagsStatus: 'idle', // 标签识别状态

      resourceDetectionTagsUserSelection: [], // 用户选择的相关标签

      resourceDetectionTags: randomTags(15), // 标签识别结果
    };
  }

  backgroundSwitchInterval = 0;

  componentDidMount() {
    let me = this;

    me.backgroundSwitchInterval = setInterval(() => {
      me.setState({resourceDetectionTags: randomTags(15)});
    }, 2000);

    requestAnimationFrame(() => me.doGetResourceTags());
  }

  componentWillUnmount() {
    if (this.backgroundSwitchInterval) {
      clearInterval(this.backgroundSwitchInterval);
    }
  }

  /**
   * 尝试根据关键词查找相关标签
   */
  doGetResourceTags = () => {
    let me  = this;

    me.setState({resourceDetectionTagsStatus: 'processing'}, () => {
      me.props.doLoadMatchedTags().then(({tags}) => {
        if (me.backgroundSwitchInterval) {
          clearInterval(me.backgroundSwitchInterval);
        }
        me.setState({resourceDetectionTagsStatus: 'success', resourceDetectionTags: tags});
      }).catch(error => {
        if (me.backgroundSwitchInterval) {
          clearInterval(me.backgroundSwitchInterval);
        }
        // TODO: 错误处理
        console.log('doGetResourceTags - failed: ', error);
        me.setState({resourceDetectionTagsStatus: 'failed', resourceDetectionTags: []});
      });
    });
  };

  render() {
    let me = this;
    let words = me.state.resourceDetectionTags
      .filter(tag => !me.state.resourceDetectionTagsUserSelection.includes(tag.text));

    // noinspection JSUnusedGlobalSymbols
    return (
      <div className={style['resource-detection-word-cloud-frame']}>
        <div className={style['word-cloud-frame']}>
          {
            words.length > 0 ? (
              <ReactWordCloud
                minSize={[200, 100]}
                maxWords={50}
                options={{
                  rotations: 1,
                  rotationAngles: [0, 0],
                  fontSizes: [15, 45],
                }}
                words={words}
                callbacks={{
                  onWordClick: word => {
                    const resourceDetectionTagsUserSelection = me.state.resourceDetectionTagsUserSelection;
                    // 当总标签数大于等于三个时不再添加
                    if (resourceDetectionTagsUserSelection.length >= 3) {
                      message.warning('您最多可以选取三条线索');
                    } else {
                      resourceDetectionTagsUserSelection.push(word.text);
                      me.setState({resourceDetectionTagsUserSelection},
                        () => me.props.onSelectionChanged(resourceDetectionTagsUserSelection));
                    }
                  },
                }}
              />
            ) : (
              (
                (me.state.resourceDetectionTagsStatus === 'success' && me.state.resourceDetectionTags.length === 0) ||
                  me.state.resourceDetectionTagsStatus === 'failed'
              ) ? (
                <div className={style['no-more-tags-panel']}>
                  <Icon name={'icon-robot'} type={IconTypes.ICON_FONT}/>
                  <span>没有找到更多的线索了</span>
                </div>
              ) : (
                <div className={style['no-more-tags-panel']}>
                  <Icon name={'icon-robot'} type={IconTypes.ICON_FONT}/>
                  <span>没有更多可选的线索了</span>
                </div>
              )
            )
          }
        </div>
        <div className={style['word-cloud-tags']}>
          {
            me.state.resourceDetectionTagsUserSelection.map((tag, idx) => (
              <Tag key={`tag-${idx}`} color="blue" title={tag}>
                <Icon name={'tag'} style={{marginRight: '0.5rem'}}/>
                <span><span>{tag}</span></span>
                <a
                  onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();
                    const resourceDetectionTagsUserSelection = me.state.resourceDetectionTagsUserSelection;
                    resourceDetectionTagsUserSelection.splice(idx, 1);
                    me.setState({resourceDetectionTagsUserSelection},
                      () => me.props.onSelectionChanged(resourceDetectionTagsUserSelection));
                  }}
                >
                  <Icon name={'close'}/>
                </a>
              </Tag>
            ))
          }
        </div>
        {
          (me.state.resourceDetectionTagsStatus === 'idle' || me.state.resourceDetectionTagsStatus === 'processing') ? (
            <div className={style['resource-detection-word-cloud-message']}>正在查找相关线索…</div>
          ) : null
        }
      </div>
    );
  }
}

ResourceDetectionTags.defaultProps = {
};

ResourceDetectionTags.propTypes = {
  onSelectionChanged: PropTypes.func.isRequired,
  doLoadMatchedTags: PropTypes.func.isRequired,
};

class WizardModal extends React.Component {

  constructor(props) {
    super(props);
    let me = this;

    const resourceExplorationStatus = {};
    const resourceExplorationResult = {};
    me.props.resourceTypes.forEach(r => {
      resourceExplorationStatus[`r-${r.value}`] = 'idle';
      resourceExplorationResult[`r-${r.value}`] = {nodes: [], edges: [], sources: [], tags: [], score: 0};
    });

    me.state = {
      currentStep: STEP_DETECTION, // 当前步骤

      // 资源探索步骤
      resourceExplorationStatus, // 资源探索状态
      resourceExplorationResult, // 资源探索结果

      // 资源类型选择步骤
      selectedResourceType: undefined, // 用户选择（或推荐）的资源类型
    };
  }

  userSelectedResource = undefined;

  userSelectedTags = [];

  senderId = `wizard-sender-${Math.round(100 * Math.random())}`;

  getStepTitle = () => {
    let me = this;

    switch (me.state.currentStep) {
      case STEP_DETECTION:
        if (!me.props.currentNodeInfo) return null;
        return <h2>系统正在尝试识别 "{getNodeDisplayTitle(me.props.currentNodeInfo)}" …</h2>;
      case STEP_EXPLORATION:
        return <h2>系统正在计算相关资源…</h2>;
      case STEP_SELECT_TYPE:
        return <h2>请选择您关注的资源种类：</h2>;
      case STEP_SELECT_TAG:
        return <h2>系统将会根据您选择的标签优化排序：</h2>;
      default:
        return null;
    }
  };

  getStepContent = () => {
    let me = this;

    switch (me.state.currentStep) {
      case STEP_DETECTION:
        if (!me.props.currentNodeInfo) return null;
        return (
          <div className={style['modal-content']}>
            <div className={style['resource-detection-frame']}>
              <div>
                <ResourceDetectionTags
                  onSelectionChanged={tags => me.userSelectedTags = tags}
                  doLoadMatchedTags={() => me.props.doLoadMatchedTags(me.props.currentNodeInfo.id)}
                />
              </div>
              {/*<div>
                <ResourceDetectionList
                  onSelectionChanged={node => me.userSelectedResource = node}
                  doLoadMatchedResources={() => me.props.doLoadMatchedResources(me.props.currentNodeInfo.id)}
                  resourceTypes={me.props.resourceTypes}
                />
              </div>*/}
            </div>
            <div style={{fontSize: '1rem', textAlign: 'center', paddingTop: '1rem'}}>(点击您感兴趣的线索会使系统搜索更加精确)</div>
          </div>
        );
      case STEP_EXPLORATION:
        return (
          <div className={style['modal-content']}>
            <div className={style['resource-loading-list-container']}>
              <ul className={style['resource-loading-list']}>
                {
                  me.props.resourceTypes.map(info => {
                    let iconConfig = getNodeIcon({[TYPE_FIELD_NAME]: info.value});
                    delete iconConfig.color;
                    return (
                      <li
                        key={`progress-${info.value}`}
                        className={
                          (me.state.resourceExplorationStatus[`r-${info.value}`] === 'success' ? (
                            me.state.resourceExplorationResult[`r-${info.value}`].nodes.length > 0 ? 'success' : 'empty'
                          ) : me.state.resourceExplorationStatus[`r-${info.value}`])
                        }
                      >
                        <span><Icon {...iconConfig}/></span>
                        <span>{info.label}</span>
                        <span className={style['resource-loading-icon']}>
                          <br />
                          {
                            me.state.resourceExplorationStatus[`r-${info.value}`] === 'success' ? (
                              <Icon name={'check-circle-o'} />
                            ) : (
                              me.state.resourceExplorationStatus[`r-${info.value}`] === 'failed' ? (
                                <Icon name={'exclamation-circle-o'} />
                              ) : (
                                <Icon name={'loading'}/>
                              )
                            )
                          }
                        </span>
                      </li>
                    );
                  })
                }
              </ul>
            </div>
          </div>
        );
      case STEP_SELECT_TYPE:
        return (
          <div className={style['modal-content']}>
            <div className={style['resource-type-list-container']}>
              <ul className={style['resource-type-list']}>
                {
                  me.props.resourceTypes.map(info => {
                    let iconConfig = getNodeIcon({[TYPE_FIELD_NAME]: info.value});
                    delete iconConfig.color;
                    return (
                      <li
                        key={`r-${info.value}`}
                        className={
                          (
                            me.state.resourceExplorationStatus[`r-${info.value}`] === 'success' &&
                            me.state.resourceExplorationResult[`r-${info.value}`].nodes.length > 0
                          ) ? (
                            me.state.selectedResourceType === info.value ? 'selected' : (
                              me.state.selectedResourceType === undefined ? 'none-selected': null
                            )
                          ) : 'disabled'
                        }
                        onClick={() => {
                          if (
                            me.state.resourceExplorationStatus[`r-${info.value}`] === 'success' &&
                            me.state.resourceExplorationResult[`r-${info.value}`].nodes.length > 0
                          ) {
                            me.setState({selectedResourceType: info.value});
                          }
                        }}
                      >
                        <span><Icon {...iconConfig}/></span>
                        <span>{info.label}</span>
                        <span className={style['resource-loading-icon']}>
                          <br />
                          {
                            me.state.resourceExplorationStatus[`r-${info.value}`] === 'success' ? (
                              <Icon name={'check-circle-o'} />
                            ) : (
                              me.state.resourceExplorationStatus[`r-${info.value}`] === 'failed' ? (
                                <Icon name={'exclamation-circle-o'} />
                              ) : (
                                <Icon name={'loading'}/>
                              )
                            )
                          }
                        </span>
                      </li>
                    )
                  })
                }
              </ul>
            </div>
            <div style={{textAlign: 'center'}}>(在结果输出页面中可自由选择)</div>
          </div>
        );
      default:
        return null;
    }
  };

  getFooter = () => {
    let me = this;
    
    switch (me.state.currentStep) {
      case STEP_DETECTION:
        return [(
          <Button
            key={'continue'}
            onClick={() => {
              me.setState({currentStep: STEP_EXPLORATION}, () => {
                me.doFindingResources();
              });
            }}
            type={'primary'}
          >
            继续
          </Button>
        )];
      case STEP_EXPLORATION:
        let processing = false;
        let continuable = false;
        for (let i = 0; i < me.props.resourceTypes.length; i++) {
          let resourceType = me.props.resourceTypes[i].value;
          if (
            me.state.resourceExplorationStatus[`r-${resourceType}`] === 'idle' ||
            me.state.resourceExplorationStatus[`r-${resourceType}`] === 'processing'
          ) {
            processing = true;
            continuable = true;
            break;
          } else if (
            me.state.resourceExplorationStatus[`r-${resourceType}`] === 'success' &&
            me.state.resourceExplorationResult[`r-${resourceType}`].nodes.length > 0
          ) {
            continuable = true;
          }
        }
        if (!processing && continuable) {
          requestAnimationFrame(() => me.setState({currentStep: STEP_SELECT_TYPE},
            () => me.doSelectResultType()));
        }
        return continuable ? [(
          <Button
            key={'continue'}
            onClick={() => {
              me.setState({currentStep: STEP_SELECT_TYPE}, () => {
                me.doSelectResultType();
              });
            }}
            disabled={processing}
            type={'primary'}
          >
            继续
          </Button>
        )] : [(
          <Button key={'close'} type={'primary'} onClick={() => me.doFindingResources()}>
            重试
          </Button>
        )];
      case STEP_SELECT_TYPE:
        return [(
          <Button
            key={'continue'}
            onClick={() => {
              me.setState({currentStep: STEP_SELECT_TAG}, () => {
                me.doShowResult();
              });
            }}
            disabled={me.state.selectedResourceType === undefined}
            type={'primary'}
          >
            查看结果
          </Button>
        )];
      default:
        return [
          (
            <Button
              key={'unknown'}
              disabled={true}
            >
              请稍后
            </Button>
          ),
        ];
    }
  };

  doResourceDetection = () => {
    let me = this;

    if (me.props.currentNodeInfo[TYPE_FIELD_NAME] !== NODE_TYPE_TAG &&
      me.props.currentNodeInfo[TYPE_FIELD_NAME] !== NODE_TYPE_TEXT) {

      requestAnimationFrame(() => {
        me.userSelectedResource = me.props.currentNodeInfo;
        me.setState({
          currentStep: STEP_EXPLORATION,
        }, () => me.doFindingResources());
      });
    }
  };

  doFindingResources = () => {
    let me = this;

    const resourceExplorationStatus = {};
    const resourceExplorationResult = {};
    me.props.resourceTypes.forEach(r => {
      resourceExplorationStatus[`r-${r.value}`] = 'idle';
      resourceExplorationResult[`r-${r.value}`] = {nodes: [], edges: [], sources: [], tags: [], score: 0};
    });

    console.log("User selected resource: ", me.userSelectedResource);
    console.log("User selected tags: ", me.userSelectedTags);

    me.setState({
      resourceExplorationStatus, resourceExplorationResult,
    }, () => {
      me.startResourceExploration();
    });
  };

  doSelectResultType = () => {
    let me = this;

    if (me.state.selectedResourceType === undefined) {
      if (me.userSelectedResource !== undefined) {
        // 首先确定资源识别是否有明确结果
        me.setState({selectedResourceType: me.userSelectedResource[TYPE_FIELD_NAME]});
      } else {
        // 尝试通过探索结果确定推荐资源类型
        let maxScore = 0;
        let recommendResourceType = undefined;
        me.props.resourceTypes.forEach(info => {
          let resourceType = info.value;
          if (me.state.resourceExplorationStatus[`r-${resourceType}`] !== 'success') return;
          if (maxScore < me.state.resourceExplorationResult[`r-${resourceType}`].score) {
            maxScore = me.state.resourceExplorationResult[`r-${resourceType}`].score;
            recommendResourceType = resourceType;
          }
        });
        if (recommendResourceType !== undefined) {
          me.setState({selectedResourceType: recommendResourceType});
        }
      }
    }
  };

  doShowResult = () => {
    let me = this;

    me.props.onClose({
      explorationResultByType: me.state.resourceExplorationResult,
      selectedType: me.state.selectedResourceType,
    });
  };

  startResourceExploration = () => {
    let me = this;

    // 判断当前请求数量是否超过最大值
    let processingRequest = 0;
    me.props.resourceTypes.forEach(r => {
      if (me.state.resourceExplorationStatus[`r-${r.value}`] === 'processing') {
        processingRequest++;
      }
    });
    if (processingRequest >= MaxParallelRequest) return;

    // 随机选取起始位置，发送请求
    const rndPos = Math.floor(Math.random() * me.props.resourceTypes.length);
    let currentPos = (rndPos + 1) % me.props.resourceTypes.length;
    while (currentPos !== rndPos && me.state.resourceExplorationStatus[`r-${me.props.resourceTypes[currentPos].value}`] !== 'idle') {
      currentPos = (currentPos + 1) % me.props.resourceTypes.length;
    }
    if (currentPos === rndPos) return; // 没有需要发送的请求了

    const resourceExplorationStatus = me.state.resourceExplorationStatus;
    const resourceType = me.props.resourceTypes[currentPos].value;
    resourceExplorationStatus[`r-${resourceType}`] = 'processing';
    me.setState({resourceExplorationStatus}, () => {
      me.props.doExplore(
        resourceType, me.userSelectedResource, me.userSelectedTags, me.senderId
      ).then(({nodes, edges, sources, tags, score}) => {
        const currentStatus = me.state.resourceExplorationStatus;
        const currentResult = me.state.resourceExplorationResult;
        currentStatus[`r-${resourceType}`] = 'success';
        currentResult[`r-${resourceType}`] = {nodes, edges, sources, tags, score};
        me.setState({resourceExplorationStatus: currentStatus, resourceExplorationResult: currentResult},
          () => me.startResourceExploration());
      }).catch(error => {
        console.log('资源探索失败：', error);
        const currentStatus = me.state.resourceExplorationStatus;
        currentStatus[`r-${resourceType}`] = 'failed';
        me.setState({resourceExplorationStatus: currentStatus},
          () => me.startResourceExploration());
      });
      setTimeout(() => me.startResourceExploration(), (500 + Math.floor(Math.random() * 1000)));
    });
  };

  componentDidMount() {
    let me = this;

    if (me.props.visible && me.props.currentNodeInfo) {
      me.doResourceDetection();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let me = this;

    if (
      (me.props.visible && me.props.currentNodeInfo !== prevProps.currentNodeInfo) ||
      (me.props.currentNodeInfo && me.props.visible && !prevProps.visible)
    ) {
      // 当前向导可见且当前节点发生改变 或 当前节点存在且向导可见性发生改变 时，开始向导流程

      me.doResourceDetection();
    }
  }

  render() {
    let me = this;

    return (
      <Modal
        visible={me.props.visible && !!me.props.currentNodeInfo}
        closable={false}
        maskClosable={false}
        centered={true}
        title={me.getStepTitle()}
        footer={me.getFooter()}
        className={style['wizard-modal']}
        width={'50rem'}
      >
        {me.getStepContent()}
      </Modal>
    );
  }
}

WizardModal.defaultProps = {
  resourceTypes: [],
};

WizardModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  doExplore: PropTypes.func.isRequired,
  doLoadMatchedTags: PropTypes.func.isRequired,
  doLoadMatchedResources: PropTypes.func.isRequired,
  currentNodeInfo: PropTypes.instanceOf(Node),
  resourceTypes: PropTypes.array,
};

export default WizardModal;