import _ from 'lodash'

export function SimplePB () {
    this.allHandlers = {};
    this.allHandlersSortByClassName = {};
    this.onceHandlers = {};
    this.onceHandlersSortByClassName = {};
    this.wrappers = {};
}

SimplePB.prototype.bind = function (self, name, eventType, handler, handlerType) {
    if (!['allHandlers', 'onceHandlers'].includes(handlerType)) handlerType = 'allHandlers';

    name = name.toLowerCase();
    if(!self._uuid)self._uuid = _.uniqueId('component_');

    const className = self._uuid;
    const handlerTypeSortMap = handlerType + 'SortByClassName';
    if (!(name in this[handlerType])) {
        this[handlerType][name] = {};
        this[handlerType][name].handlers = {}
    }
    if (!(className in this[handlerTypeSortMap])) {
        this[handlerTypeSortMap][className] = []
    }
    handler.$self = self;

    if (_.isString(eventType)) {
        eventType = [eventType];
    }

    eventType.forEach(eventType => {
        eventType = eventType.toLowerCase();
        if (!(eventType in this[handlerType][name].handlers)) {
            this[handlerType][name].handlers[eventType] = {}
        }
        if (!this[handlerType][name].handlers[eventType][className]) {
            this[handlerType][name].handlers[eventType][className] = [];
            this[handlerTypeSortMap][className].push([name, "handlers", eventType, className]);
        }
        this[handlerType][name].handlers[eventType][className].push(handler);
    });

    return this;
};

SimplePB.prototype.subscribe = function (self, name, eventType, handler) {
    return this.bind(self, name, eventType, handler, 'allHandlers');
};

SimplePB.prototype.sub = function (self, name, eventType, handler) {
    return this.bind(self, name, eventType, handler, 'allHandlers');
};

SimplePB.prototype.once = function (self, name, eventType, handler) {
    return this.bind(self, name, eventType, handler, 'onceHandlers');
};

SimplePB.prototype.emit = function (name, eventType) {
    name = name.toLowerCase();
    eventType = eventType.toLowerCase();
    console.log("emit", name, eventType);
    const args = Array.prototype.slice.call(arguments, 2);

    if (name in this.allHandlers && eventType in this.allHandlers[name].handlers) {
        // Object.keys、Object.values曾经在处理handlersMap时发生过不可思议的错误，这里改为for...in
        const handlersMap = this.allHandlers[name].handlers[eventType];
        for (let uuid in handlersMap) {
            if (Object.hasOwnProperty.call(handlersMap, uuid)) {
                handlersMap[uuid].forEach(handler => {
                    try {
                        handler.apply(handler.$self, args);
                    } catch (e) {
                        console.error('Error(s) occurred when EMIT: ', e);
                    }
                })
            }
        }
    }
    if (name in this.onceHandlers && eventType in this.onceHandlers[name].handlers) {
        let eventObjects = this.onceHandlers[name].handlers[eventType];
        delete this.onceHandlers[name].handlers[eventType];
        Object.keys(eventObjects).forEach(
            className => {
                this.onceHandlersSortByClassName[className] = this.onceHandlersSortByClassName[className]
                  .filter(arr => !(arr[0] === name && arr[2] === eventType));
                eventObjects[className].forEach(handler => {
                    try {
                        handler.apply(handler.$self, args);
                    } catch (e) {
                        console.error('Error(s) occurred when EMIT: ', e);
                    }
                });
            });
    }
    return this;
};

SimplePB.prototype.remove = function (self) {
    const allHandlers = this.allHandlers;
    const allHandlersSortByClassName = this.allHandlersSortByClassName;
    const onceHandlersSortByClassName = this.onceHandlersSortByClassName;
    // if (self) {
    try {
        const className = self._uuid;
        [allHandlersSortByClassName[className], onceHandlersSortByClassName[className]]
          .forEach(handler => {
              try {
                  if (handler) {
                      handler.forEach((arr) => {
                          delete allHandlers[arr[0]][arr[1]][arr[2]][arr[3]];
                      })
                  }
                  allHandlersSortByClassName[className] = []
              } catch (error) {
                  console.error(`${className}并未注册任何事件，无需remove`)
              }
          });
    } catch (error) {
        console.error("remove的 首个参数必须为this")
    }

    // }
    return this
};

/**
 * @param self
 * @returns {SimplePBWrapper}
 */
SimplePB.prototype.with = function (self) {
    if(!self._uuid)self._uuid = _.uniqueId('component_');

    if (!this.wrappers[self._uuid]) {
        this.wrappers[self._uuid] = new SimplePBWrapper(self, this);
    }
    return this.wrappers[self._uuid];
};

function SimplePBWrapper(target, simplePB) {
    this.target = target;
    this.bus = simplePB;
}

SimplePBWrapper.prototype.bind = function (name, eventType, handler, handlerType) {
    this.bus.bind(this.target, name, eventType, handler, handlerType);
    return this;
};

SimplePBWrapper.prototype.subscribe = function (name, eventType, handler) {
    this.bus.subscribe(this.target, name, eventType, handler);
    return this;
};

SimplePBWrapper.prototype.sub = function (name, eventType, handler) {
    this.bus.sub(this.target, name, eventType, handler);
    return this;
};

SimplePBWrapper.prototype.once = function (name, eventType, handler) {
    this.bus.once(this.target, name, eventType, handler);
    return this;
};

SimplePBWrapper.prototype.emit = function (name, eventType) {
    const args = Array.prototype.slice.call(arguments, 2);
    this.bus.emit.apply(this.bus, [name, eventType].concat(args));
    return this;
};

SimplePBWrapper.prototype.remove = function () {
    this.bus.remove(this.target);
    return this;
};

export const SimplePBInstance = new SimplePB();

export default SimplePBInstance;