import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import copy from 'copy-to-clipboard';
import {Menu, Tooltip, message, Checkbox, Divider, Popover} from 'antd';
import nzh from 'nzh';
// noinspection SpellCheckingInspection
import anime from 'animejs';
import EventListener from 'react-event-listener';

import PB, {SimplePB} from '@/libs/simplePB';

import StoryNodeFilter from "@/components/common/relation/common.relation.story.nodeFilter";
import StoryListFilter from "@/components/common/view/presentation/common.view.presentation.storyFilter";

import {
  ICON_CATEGORY_CUSTOM,
  ICON_CATEGORY_TEXT,
  ICON_CATEGORY_COMPANY,
  ICON_CATEGORY_TALENT,
  ICON_CATEGORY_PATENT,
  ICON_CATEGORY_PAPER,
  ICON_CATEGORY_POLICY,
  ICON_CATEGORY_ORG,
  ICON_CATEGORY_INSTITUTE,
  ICON_CATEGORY_NEWS_ACTIVITIES,
  ICON_CATEGORY_TAG,
  ICON_CATEGORY_DOCS,
  ICON_CATEGORY_DATASET,
  ICON_CATEGORY_GOV,
  ICON_CATEGORY_NATURE,
  ICON_CATEGORY_INDEX,
  ICON_CATEGORY_GRAPH,
  ICON_CATEGORY_COLLEGE_AND_UNIVERSITY,
  ICON_CATEGORY_PARK,
  ICON_CATEGORY_TECHNOLOGY,
  ICON_CATEGORY_PROJECT,
  ICON_CATEGORY_PREPARE,
  ICON_CATEGORY_DOING,
  ICON_CATEGORY_FINISH,
  ICON_CATEGORY_FLAG_A,
  ICON_CATEGORY_FLAG_B,
  ICON_CATEGORY_FLAG_C,
  ICON_CATEGORY_FLAG_E,
  ICON_CATEGORY_FLAG_F,
  ICON_CATEGORY_FLAG_G,
  ICON_CATEGORY_TEXT_A,
  ICON_CATEGORY_TEXT_B,
  ICON_CATEGORY_TEXT_C,
  ICON_CATEGORY_TIP_A,
  ICON_CATEGORY_TIP_B,
  ICON_CATEGORY_TIP_C,
  ICON_CATEGORY_TIP_D,
  ICON_CATEGORY_TIP_E,
  ICON_CATEGORY_TIP_F,
  ICON_CATEGORY_TIP_G,
  ICON_CATEGORY_TIP_H,
  ICON_CATEGORY_NO_ICON,
  ICON_CATEGORY_GOOD,
  ICON_CATEGORY_BAD,
  ICON_CATEGORY_WATCH,
  iconConfig, getNodeIconType,
} from "@/constants/iconConfig";
import {AvatarColors, IconTypes} from '@/constants/common';

import Icon from '@/components/common/common.icon';
import {defaultDefine, getNodeDisplayTitle, getNodeIcon, NODE_TYPE_TEXT} from '@/constants/vis.defaultDefine.1';

import ViewStatisticsWordPanel from '@/components/common/view/statistics/word/common.view.statistics.word.panel';
import ViewStatisticsNodeDatetimePanel from '@/components/common/view/statistics/node/common.view.statistics.node.datetimePanel';
import ViewStatisticsNodeGeneralPanel from '@/components/common/view/statistics/node/common.view.statistics.node.generalPanel';

import style from '@/style/common/relation/common.relation.nodeFilter.less';
import ViewStatisticsEdgePanel from "@/components/common/view/statistics/edge/common.view.statistics.edge.panel";
import {getNodeConnectionMap} from "@/libs/graph-utils";

import {defaultIconData} from '@/components/common/view/presentation/common.view.presentation.logic';
import TimeDisplayField from "@/components/common/common.timeDisplayField";
import {getToken, REQUEST_BASE} from '@/utils/HttpUtil';
import UserAvatar from "react-user-avatar";
import intl from 'react-intl-universal';

const iconTypes = [
  ICON_CATEGORY_GOV,
  ICON_CATEGORY_POLICY,
  ICON_CATEGORY_ORG,
  ICON_CATEGORY_PARK,
  ICON_CATEGORY_COMPANY,
  ICON_CATEGORY_COLLEGE_AND_UNIVERSITY,
  ICON_CATEGORY_INSTITUTE,
  ICON_CATEGORY_TALENT,
  ICON_CATEGORY_PATENT,
  ICON_CATEGORY_PAPER,
  ICON_CATEGORY_DOCS,
  ICON_CATEGORY_DATASET,
  ICON_CATEGORY_INDEX,
  ICON_CATEGORY_GRAPH,
  ICON_CATEGORY_TECHNOLOGY,
  ICON_CATEGORY_NEWS_ACTIVITIES,
  ICON_CATEGORY_NATURE,
  ICON_CATEGORY_TAG,
  ICON_CATEGORY_PROJECT,
  ICON_CATEGORY_PREPARE,
  ICON_CATEGORY_DOING,
  ICON_CATEGORY_FINISH,
  ICON_CATEGORY_FLAG_A,
  ICON_CATEGORY_FLAG_B,
  ICON_CATEGORY_FLAG_C,
  ICON_CATEGORY_FLAG_E,
  ICON_CATEGORY_FLAG_F,
  ICON_CATEGORY_FLAG_G,
  ICON_CATEGORY_TEXT_A,
  ICON_CATEGORY_TEXT_B,
  ICON_CATEGORY_TEXT_C,
  ICON_CATEGORY_TEXT,
  ICON_CATEGORY_TIP_A,
  ICON_CATEGORY_TIP_B,
  ICON_CATEGORY_TIP_C,
  ICON_CATEGORY_TIP_D,
  ICON_CATEGORY_TIP_E,
  ICON_CATEGORY_TIP_F,
  ICON_CATEGORY_TIP_G,
  ICON_CATEGORY_TIP_H,
  ICON_CATEGORY_GOOD,
  ICON_CATEGORY_BAD,
  ICON_CATEGORY_WATCH,
  ICON_CATEGORY_NO_ICON,
  ICON_CATEGORY_CUSTOM,
];

// const dayIcon = '㏠㏡㏢㏣㏤㏥㏦㏧㏨㏩㏪㏫㏬㏭㏮㏯㏰㏱㏲㏳㏴㏵㏶㏷㏸㏹㏺㏻㏼㏽㏾'.split('');
const dayIcon = '01 02 03 04 05 06 07 08 09 10 11 12 13 14 15 16 17 18 19 20 21 22 23 24 25 26 27 28 29 30 31'.split(' ');

const timelineType = ['today', 'yesterday', 'dby'];

const connectionType = ['individual', 'connectedToMine', 'selected'];

const numChars = '0123456789零〇一二三四五六七八九十'.split('');
const numAChars = '0123456789'.split('');
const numZChars = '零〇一二三四五六七八九十'.split('');

const theDayBeforeYesterdayMoment  = moment().subtract(2, 'd');
const yesterdayMoment = moment().subtract(1, 'd');
const todayMoment = moment();
const theDayBeforeYesterday = theDayBeforeYesterdayMoment.format('YYYY-MM-DD');
const yesterday = yesterdayMoment.format('YYYY-MM-DD');
const today = todayMoment.format('YYYY-MM-DD');

const categoryFilterConfigFn = me => {
  let result = {};
  iconTypes.forEach(type => {
    result[`category-${type}`] = {
      icon: <Icon {...iconConfig[type].iconProperty} />,
      text: iconConfig[type].title.x2 || iconConfig[type].title._default,
      tip: `${intl.get('Custom.filter.highlightAll')}【${iconConfig[type].title._default}】${intl.get('Custom.filter.typeNodes')}`,
      fn: node => me.idMap[`category-${type}`][node.id],
    };
  });
  result[`category-${ICON_CATEGORY_TIP_H}`].icon = (<Icon {...{...iconConfig[ICON_CATEGORY_TIP_H].iconProperty, color: defaultDefine.colors.level0}}/>);
  result[`category-${ICON_CATEGORY_TIP_A}`].icon = (<Icon {...{...iconConfig[ICON_CATEGORY_TIP_A].iconProperty, color: '#777777'}}/>);
  result[`category-${ICON_CATEGORY_TIP_B}`].icon = (<Icon {...{...iconConfig[ICON_CATEGORY_TIP_B].iconProperty, color: undefined}}/>);
  return result;
};

const timelineFilterConfigFn = me => ({
  today: {
    icon: <span style={{letterSpacing: '-1px'}}>{dayIcon[todayMoment.date() - 1]}</span>,
    text: intl.get('Custom.filter.today'),
    tip: intl.get('Custom.filter.todayTip'),
    fn: node => me.idMap['today'][node.id],
  },
  yesterday: {
    icon: <span style={{letterSpacing: '-1px'}}>{dayIcon[yesterdayMoment.date() - 1]}</span>,
    text: intl.get('Custom.filter.yesterday'),
    tip: intl.get('Custom.filter.yesterdayTip'),
    fn: node => me.idMap['yesterday'][node.id],
  },
  dby: {
    icon: <span style={{letterSpacing: '-1px'}}>{dayIcon[theDayBeforeYesterdayMoment.date() - 1]}</span>,
    text: intl.get('Custom.filter.before'),
    tip: intl.get('Custom.filter.beforeTip'),
    fn: node => me.idMap['dby'][node.id],
  },
});

const connectionFilterConfigFn = me => ({
  individual: {
    icon: undefined,
    text: intl.get('Custom.filter.isolated'),
    tip: intl.get('Custom.filter.isolatedTip'),
    fn: node => me.idMap['individual'][node.id],
  },
  connectedToMine: {
    icon: undefined,
    text: intl.get('Custom.filter.connectMe'),
    tip: intl.get('Custom.filter.connectMeTip'),
    fn: node => me.idMap['connectedToMine'][node.id],
  },
});

const alphaCompare = (aNode, bNode, factor = 1) => {
  const aTitle = getNodeDisplayTitle(aNode) || '';
  const bTitle = getNodeDisplayTitle(bNode) || '';

  let pos = 0;
  while (aTitle.length > pos && bTitle.length > pos && aTitle[pos] === bTitle[pos] &&
    numChars.indexOf(aTitle[pos]) < 0) {

    pos++;
  }
  if (pos < aTitle.length && pos < bTitle.length) {
    let aHasANum = numAChars.indexOf(aTitle[pos]) >= 0,
      aHasZNum = numZChars.indexOf(aTitle[pos]) >= 0,
      bHasANum = numAChars.indexOf(bTitle[pos]) >= 0,
      bHasZNum = numZChars.indexOf(bTitle[pos]) >= 0;

    if ((aHasANum || aHasZNum) && !bHasANum && !bHasZNum) {
      return factor * -1;
    } else if ((bHasANum || bHasZNum) && !aHasANum && !aHasZNum) {
      return factor;
    } else if (aHasANum && !bHasANum) {
      return factor * -1;
    } else if (bHasANum && !aHasANum) {
      return factor;
    } else if (aHasANum && bHasANum) {
      let aInt = parseInt(aTitle.substring(pos)), bInt = parseInt(bTitle.substring(pos));
      return factor * (aInt < bInt ? -1 : (aInt > bInt ? 1 : 0));
    } else if (aHasZNum && bHasZNum) {
      let aZIntString = aTitle.substring(pos).replace(/〇/g, '零')
          .replace(/^([零一二三四五六七八九十百千万亿]+).*/, "$1"),
        bZIntString = bTitle.substring(pos).replace(/〇/g, '零')
          .replace(/^([零一二三四五六七八九十百千万亿]+).*/, "$1");

      let aZInt = nzh.cn.decodeS(aZIntString), bZZInt = nzh.cn.decodeS(bZIntString);

      return factor * (aZInt < bZZInt ? -1 : (aZInt > bZZInt ? 1 : 0));
    }
  }

  return factor * aTitle.localeCompare(bTitle, 'zh-CN');
};

const alphaAscCompare = (aNode, bNode) => {
  return alphaCompare(aNode, bNode);
}

const alphaDescCompare = (aNode, bNode) => {
  return alphaCompare(aNode, bNode, -1);
}

const latestCompare = (aNode, bNode) => -`${aNode['updateTime'] || aNode['linkTime']}`
  .localeCompare(`${bNode['updateTime'] || bNode['linkTime']}`);

const earliestCompare = (aNode, bNode) => `${aNode['updateTime'] || aNode['linkTime']}`
  .localeCompare(`${bNode['updateTime'] || bNode['linkTime']}`);

const connectionCompareFn = nodeConnectionMap => (aNode, bNode) =>
  (nodeConnectionMap[bNode.id] ? nodeConnectionMap[bNode.id].length : 0) -
  (nodeConnectionMap[aNode.id] ? nodeConnectionMap[aNode.id].length : 0);

class NodeFilter extends React.PureComponent {
  state = {
    totalAmount: 0,
    currentFilter: 'none', //story-list
    currentUserSelectFilter: 'none',
    loading: true,
    forceUpdate: undefined,
    sortBy: 'connection', // 排序方案，默认 connection 还支持 latest, alpha, custom
    nodeListAllSelectedIndeterminate: false,
    nodeListAllSelected: false,
    nodeListSelectionMap: {},
    nodeListSelectedAmount: 0,
    nodeListLimit: 100,

    showStatisticsGrid: true, // 是否展示统计表

    presentationPlayingNodeId: undefined, // 当前自动漫游节点Id
    presentationStarted: false, // 当前播放状态

    showStatisticsPanel: undefined, // 是否展示统计面板
    nodeStatisticsConditionKey: undefined, // 节点计数条件

    presentationStayFocus: false,
    currentDuring: 8000,
    firstLoading: true,
    focusNodeId: undefined,
    smallScreen: 1,

    storyNodeIds : undefined,
    outShow: true
  };

  memberMap = undefined;

  statisticsElement = undefined; // 节点统计组件DOM对象

  storyListElement = undefined;

  nodeListElement = undefined;

  idMap = {};

  connectionLimitMap = {};

  allNodeMap = {};

  allEdges = [];

  nodeConnectionsMap = {};

  nodeConnectionsMapEx = {};

  categoryFilterConfig = {};

  timelineFilterConfig = {};

  connectionFilterConfig = {};

  filterConfig = {};

  currentSelectedNodeId = undefined;

  additionalNodeIds = [];

  dataCache = {};

  lastHoverKey = {
    list: undefined,
    statistics: undefined,
  };

  filterFn = undefined;

  gravityNodeList = [];

  ignoreMouseMoveEvent = false; // 是否忽略鼠标移动事件，按下鼠标的时候需要忽略

  storyNodeMaps = [];

  presentationList = [];
  currentPresentationIndex = -1;
  currentStoryInfo = undefined;

  // 统计表自动展示触发区域
  showStatisticsPanelTriggerArea = {
    minX: 0,
    maxX: 0,
    minY: 0,
    maxY: 0,
  };

  // 统计表自动隐藏排除区域
  hideStatisticsPanelTriggerArea = {
    minX: 0,
    maxX: 0,
    minY: 0,
    maxY: 0,
  };
  //矩形区域内故事线区域外
  hideStatisticsStoryListArea = {
    minX: 0,
    maxX: 0,
    minY: 0,
    maxY: 0,
  };
  //矩形区域内节点区域外
  hideStatisticsNodeListArea = {
    minX: 0,
    maxX: 0,
    minY: 0,
    maxY: 0,
  };

  statisticsGridAnim = undefined; // 统计表格动画对象

  storyNodeIdMap = undefined;
  storyNodeIds = undefined;


  presentationFilterNodeIds = []; // 播放筛选节点列表
  playPresentationAction = 'do_start';
  filterName = ''; // 漫游播放节点对象名称
  // 漫游（播放）功能节点专题报告间隔时间初始值
  presentationPlayList = {
    latest: {id: 0, key: 'latest', title: intl.get('Custom.view.newNodes'), text: intl.get('Custom.view.newNodes')},
    filter: {id: 1, key: 'filter', title: intl.get('Custom.view.leftFilter'), text: this.filename ? `${this.filename} ${intl.get('Custom.view.screen')}` : ''},
    custom: {id: 4, key: 'custom', title: intl.get('Custom.view.customList'), text: intl.get('Custom.view.customList')},
  };

  // 漫游（播放）功能节点专题报告间隔时间初始值
  presentationDuringArr = [
    {during: 12000, text: intl.get('Custom.menu.slowlyPlay')},
    {during: 8000, text: intl.get('Custom.menu.constantPlay')},
    {during: 5000, text: intl.get('Custom.menu.quickPlay')},
  ];

  // 播放控制按钮
  presentationPlayControl = () => {
    const me = this;
    me.props.bus.emit('node', 'presentation.stop');
    if (me.presentationFilterNodeIds.length > 0) {
      me.props.bus.emit(
        'relation',
        me.state.presentationStarted ? (
          me.state.presentationStayFocus ? 'presentation.playing.do_continue' : 'presentation.do_hold'
        ) : `presentation.${me.playPresentationAction}`,
        me.playPresentationAction === 'do_start' ?
          {config: {nodeIds: me.state.currentFilter==='story'?me.state.storyNodeIds:me.presentationFilterNodeIds}, noWait: true} : undefined);
    } else {
      message.info('请在左侧节点筛选项中选择播放节点列表');
    }
  };

  presentationPlay = (during=8000) => {
    let me = this;
    me.setState({currentDuring: during}, () => {
      me.playPresentationAction = 'do_start';
      me.props.bus.emit('relation', 'presentation.default_config.update',
        {interval: me.state.currentDuring});
      me.presentationPlayControl();
    });
  };

  /*lostHoverTimeout = {
    list: undefined,
    statistics: undefined,
  };*/

  initIdMap = () => {
    let result = {
      all: {},
      favorite: {},
      important: {},
      find: {},
      story: {},
      today: {},
      yesterday: {},
      dby: {},
      individual: {},
      connectedToMine: {},
      'story-list': {}
    };
    iconTypes.forEach(type => result[`category-${type}`] = {});
    return result;
  };

  getFilterFn = () => {
    let me = this;
    if (!me.filterFn) {
      me.filterFn = {
        latest: latestCompare,
        earliest: earliestCompare,
        alphaAsc: alphaAscCompare,
        alphaDesc: alphaDescCompare,
        connection: connectionCompareFn(me.nodeConnectionsMapEx),
        custom: (aNode, bNode) => {
          if (me.state.currentFilter === 'favorite') {
            return aNode._userFavorite - bNode._userFavorite;
          } else if (me.state.currentFilter === 'important') {
            return aNode._viewFavorite - bNode._viewFavorite;
          } else {
            return true;
          }
        },
        story: (aNode, bNode) => me.state.storyNodeIds.indexOf(aNode.id) - me.state.storyNodeIds.indexOf(bNode.id),
      }
    }
    return me.filterFn[me.state.sortBy];
  };

  doStatistics = ({nodes, edges}, currentUserId, clearNodeListSelection = false) => {
    let me = this, myNodeIds = [], connectedIds = [];

    currentUserId = currentUserId || parseInt(localStorage.getItem('userId'))
    if (nodes && edges) {
      // 关系图数据改变
      currentUserId = parseInt(currentUserId);

      me.idMap = me.initIdMap();
      me.connectionLimitMap = me.initIdMap();
      me.allNodeMap = {};
      me.nodeConnectionsMap = getNodeConnectionMap(nodes, edges, false, false);
      me.allEdges = edges;

      nodes.sort(me.getFilterFn()).forEach(node => {
        me.allNodeMap[node.id] = node;

        let iconType = getNodeIconType(node),
          nodeTime = moment(node.updateTime || node['createTime'], 'YYYY-MM-DD HH:mm:ss')
            .format('YYYY-MM-DD');

        me.idMap.all[node.id] = true;
        me.idMap['story-list'][node.id] = true;

        if (me.idMap[`category-${iconType}`]) {
          me.idMap[`category-${iconType}`][node.id] = true;
        }

        if (me.storyNodeIdMap && me.storyNodeIdMap[node.id]) {
          me.idMap['story'][node.id] = true;
        }

        if (node._viewFavorite > -1) {
          me.idMap['important'][node.id] = true;
        }

        if (node._userFavorite > -1) {
          me.idMap.favorite[node.id] = true;
        }

        if (node.aiRelatedTo) {
          me.idMap.find[node.id] = true;
        }

        if (nodeTime === today) {
          me.idMap.today[node.id] = true;
        } else if (nodeTime === yesterday) {
          me.idMap.yesterday[node.id] = true;
        } else if (nodeTime === theDayBeforeYesterday) {
          me.idMap.dby[node.id] = true;
        }

        if (!me.nodeConnectionsMap[node.id]) {
          me.idMap.individual[node.id] = true;
        }
        if (parseInt(node.userId) === currentUserId) {
          myNodeIds.push(node.id);
        }
      });

      me.nodeConnectionsMapEx = getNodeConnectionMap(nodes, edges, false, true,
        me.nodeConnectionsMap, me.allNodeMap);

      myNodeIds.forEach(nodeId => {
        connectedIds.push.apply(connectedIds, me.nodeConnectionsMapEx[nodeId]);
      });

      connectedIds = _.uniq(connectedIds);
      connectedIds = _.difference(connectedIds, myNodeIds);
      // noinspection DuplicatedCode
      connectedIds.filter(
        nodeId => !!me.allNodeMap[nodeId]
      ).sort((aNodeId, bNodeId) => {
        return me.getFilterFn()(me.allNodeMap[aNodeId], me.allNodeMap[bNodeId]);
      }).forEach(nodeId => {
        if (me.allNodeMap[nodeId]) me.idMap.connectedToMine[nodeId] = true;
      });
    }

    me.idMap['selected'] = {};
    me.connectionLimitMap['selected'] = {};

    let selectedLinkedNodeIds = [...me.additionalNodeIds];
    if (me.currentSelectedNodeId && me.nodeConnectionsMapEx[me.currentSelectedNodeId]) {
      selectedLinkedNodeIds = _.concat(selectedLinkedNodeIds, me.nodeConnectionsMapEx[me.currentSelectedNodeId]);
    }

    // noinspection DuplicatedCode
    selectedLinkedNodeIds
      .filter(nodeId => !!me.allNodeMap[nodeId])
      .sort((aNodeId, bNodeId) => {
        return me.getFilterFn()(me.allNodeMap[aNodeId], me.allNodeMap[bNodeId]);
      })
      .forEach(nodeId => me.idMap['selected'][nodeId] = true);

    // 计算连接数相关数值
    for (let k in me.connectionLimitMap) {
      if (Object.hasOwnProperty.apply(me.connectionLimitMap, [k])) {
        let ids = Object.keys(me.idMap[k]).filter(nodeId => me.idMap[k][nodeId]);
        if (ids.length > 0) {
          let connections = ids.map(id => me.nodeConnectionsMapEx[id] ? me.nodeConnectionsMapEx[id].length : 0);
          let avg = connections.reduce((r, c) => r + c, 0) / connections.length;
          let std = Math.sqrt(connections.reduce((r, c) => r + Math.pow(c - avg, 2), 0) / connections.length);
          me.connectionLimitMap[k] = {
            top: avg + 2 * std,
            bottom: avg - 2 * std,
          };
        } else {
          me.connectionLimitMap[k] = {
            top: 0,
            bottom: 0,
          };
        }
      }
    }

    if (nodes && edges) {
      me.setState({totalAmount: nodes.length, loading: false}, () => setTimeout(
        () => me.resetFilter(undefined,
          me.state.currentFilter === me.state.currentUserSelectFilter, clearNodeListSelection), 100));
    }
  };

  storyNodeSort = ({nodes}) => {
    let me = this;
    if(me.state.currentFilter==='story'){
      let temp_storyNodeIds = me.state.storyNodeIds,temp_Ids = [];
      nodes.forEach(node => {
        if(me.storyNodeIds.includes(node.id)){
          temp_Ids.push(node.id);
          temp_storyNodeIds = temp_storyNodeIds.filter(item=>item !== node.id);
        }
      })
      //me.storyNodeIds = [...temp_Ids,...temp_storyNodeIds];
      me.setState({storyNodeIds:[...temp_Ids,...temp_storyNodeIds]})
    }
  }

  /**
   * 重置过滤器
   *
   * @param {string} [filterKey] 当前选定的过滤方式
   * @param {boolean} [userSelected] 是否由用户点击主动触发
   * @param {boolean} [clearNodeListSelection] 是否清空节点列表选中状态
   */
  resetFilter = (filterKey = undefined, userSelected = true, clearNodeListSelection = true) => {
    let me = this, config = me.filterConfig[filterKey], idMap = {...me.idMap[filterKey]},
      nodeListLimit = me.state.nodeListLimit;

    if (userSelected && filterKey !== 'none' && me.state.currentFilter === filterKey) {
      filterKey = 'none';
      idMap = {};
    } else if (filterKey === undefined) {
      filterKey = me.state.currentFilter;
      config = me.filterConfig[filterKey];
      idMap = {...me.idMap[filterKey]};
    }
    if (filterKey === 'selected' && !me.currentSelectedNodeId) {
      message.info('请先选择任意一个节点');
      return;
    }
    if (filterKey === 'story' && !me.storyNodeIdMap) {
      if (!me.props.viewId) {
        message.info('抱歉，当前关系图暂不支持按专题报告筛选');
      } else {
        //me.props.bus.emit('presentation', 'config.list.show_drawer', {viewId: me.props.viewId});
        message.info('已为您打开专题报告列表，请先点击图标播放任意一条专题报告');
      }
      return;
    }

    let ids = Object.keys(idMap), selectedAmount = 0;
    ids.forEach(id => idMap[id] = false);

    if (config || filterKey === 'none') {
      if (clearNodeListSelection) {
        // 限制节点列表首次加载数量
        nodeListLimit = 100;
      } else {
        Object.keys(me.state.nodeListSelectionMap).forEach(id => {
          if (me.state.nodeListSelectionMap[id] && idMap[id] !== undefined) {
            idMap[id] = true;
            selectedAmount++;
          }
        });
      }
      let filteredNodeIds = [];
      if (filterKey !== 'none') {
        filteredNodeIds = Object.keys(me.idMap[filterKey]);
      }
      let sortBy = me.state.sortBy, resort;
      if (me.state.currentFilter !== filterKey && filterKey === 'story') {
        sortBy = 'story';
      } else if (sortBy === 'custom' && filterKey !== 'favorite' && filterKey !== 'important') {
        sortBy = 'connection';
      }
      resort = sortBy !== me.state.sortBy;
      me.setState({
        currentFilter: filterKey,
        currentUserSelectFilter: userSelected ? filterKey : me.state.currentUserSelectFilter,
        forceUpdate: Math.random(),
        nodeListAllSelectedIndeterminate: selectedAmount > 0 && selectedAmount < ids.length,
        nodeListAllSelected: selectedAmount === ids.length,
        nodeListSelectionMap: idMap,
        nodeListSelectedAmount: selectedAmount,
        nodeListLimit,
        sortBy,
        showStatisticsPanel: undefined,
        nodeStatisticsConditionKey: undefined,
      }, () => {
        if (resort) {
          me.doStatistics(me.dataCache, undefined, clearNodeListSelection);
        } else {
          me.props.bus.emit('network', 'light_node.do', {
            status: me.state.currentFilter,
            fn: filterKey === 'none' ? undefined : config.fn,
          });
          me.props.bus.emit('relation', 'node_filter.updated', {
            viewId: me.props.viewId,
            filterKey,
            filterName: filteredNodeIds.length > 0 ? me.filterConfig[filterKey]['text'] : '',
            filteredNodeIds,
          });
          me.recalculateTriggerArea();
        }
      });
    }
  };

  resetStoryFilter = (filterKey = undefined, userSelected = true, clearNodeListSelection = true) => {
    let me = this, config = me.filterConfig[filterKey];
    if (userSelected && filterKey !== 'none' && me.state.currentFilter === filterKey) {
      filterKey = 'none';
    } else if (filterKey === undefined) {
      filterKey = me.state.currentFilter;
    }
    me.setState({
      currentFilter: filterKey,
      forceUpdate: Math.random(),
      showStatisticsPanel: undefined,
      nodeStatisticsConditionKey: undefined,
    }, () => {
      let filteredNodeIds = [];
      me.props.bus.emit('network', 'light_node.do', {
        status: me.state.currentFilter,
        fn: filterKey === 'none' ? undefined : config.fn,
      });
      me.props.bus.emit('relation', 'node_filter.updated', {
        viewId: me.props.viewId,
        filterKey,
        filterName: '',
        filteredNodeIds,
      });
      me.recalculateTriggerArea();
    });
  }


  rollbackFilter = () => {
    let me = this;

    if (me.state.currentFilter !== 'selected') {
      me.forceUpdate(() => me.recalculateTriggerArea());
      return;
    }

    me.props.bus.emit('relation', 'presentation.do_pause');
    if (me.state.currentUserSelectFilter === me.state.currentFilter) {
      me.resetFilter('none');
    } else {
      me.resetFilter(me.state.currentUserSelectFilter);
    }
  };

  getTBodyByType = (typeList, config) => {
    let me = this;

    return (
      <tbody>
      {
        typeList.map(type => (
          <Tooltip
            title={config[type].tip}
            key={type}
            placement={'right'}
            mouseLeaveDelay={0.05}
            onVisibleChange={visible =>
              visible ? me.onComponentHover('statistics', type) :
                me.delayComponentLostHover('statistics', type)}
          >
            <tr
              className={me.state.currentFilter === type ? 'highlighted' : null}
              onClick={() => {
                me.props.bus.emit('relation', 'presentation.do_pause');
                me.resetFilter(type);
              }}
            >
              <td>{config[type].icon}</td>
              <td>{config[type].text}</td>
              <td>{Object.keys(me.idMap[type]).length}</td>
            </tr>
          </Tooltip>
        ))
      }
      </tbody>
    );
  };

  onComponentHover = (type, key) => {
    let me = this;

    /*if (me.lostHoverTimeout[type]) {
      clearTimeout(me.lostHoverTimeout[type]);
      me.lostHoverTimeout[type] = undefined;
    }*/

    if (!me.lastHoverKey[type]) {
      me.lastHoverKey[type] = key;
      me.forceUpdate();
    } else {
      me.lastHoverKey[type] = key;
    }
  };

  delayComponentLostHover = (type, key) => {
    let me = this;

    /*if (me.lostHoverTimeout[type]) {
      clearTimeout(me.lostHoverTimeout[type]);
    }*/

    /*me.lostHoverTimeout[type] = */setTimeout(() => {
      if (me.lastHoverKey[type] === key) {
        me.lastHoverKey[type] = undefined;
        me.forceUpdate();
      }
    }, 10);
  };

  onNodeListSelectAllChanged = e => {
    let me = this, nodeListAllSelected = e.target.checked, nodeListSelectionMap = {}, nodeListSelectedAmount = 0;
    if (nodeListAllSelected) {
      if (me.state.nodeListLimit > 0) {
        Object.keys(me.idMap[me.state.currentFilter]).slice(0, me.state.nodeListLimit)
          .forEach(k => nodeListSelectionMap[k] = me.idMap[me.state.currentFilter][k]);
        nodeListSelectedAmount = me.state.nodeListLimit;
      } else {
        nodeListSelectionMap = {...me.idMap[me.state.currentFilter]};
        nodeListSelectedAmount = Object.keys(me.idMap[me.state.currentFilter]).length;
      }
    }
    me.setState({
      nodeListAllSelected,
      nodeListAllSelectedIndeterminate: false,
      nodeListSelectionMap,
      nodeListSelectedAmount,
    });
  };

  onNodeListSelectionChanged = (nodeId, e) => {
    let me = this, selected = e.target.checked,
      nodeListLimit = me.state.nodeListLimit,
      nodeListSelectedAmount = me.state.nodeListSelectedAmount,
      nodeListAmount = nodeListLimit > 0 ? nodeListLimit : Object.keys(me.idMap[me.state.currentFilter]).length,
      nodeListSelectionMap = {...me.state.nodeListSelectionMap};

    if (selected) {
      nodeListSelectedAmount++;
      nodeListSelectionMap[nodeId] = true;
    } else {
      nodeListSelectedAmount--;
      nodeListSelectionMap[nodeId] = false;
    }

    me.setState({
      nodeListAllSelected: nodeListAmount === nodeListSelectedAmount,
      nodeListAllSelectedIndeterminate: nodeListSelectedAmount > 0 && nodeListAmount > nodeListSelectedAmount,
      nodeListSelectionMap: nodeListSelectionMap,
      nodeListSelectedAmount: nodeListSelectedAmount,
    });
  };

  copySelectedNodesToClipboard = () => {
    let me = this, textList = [];
    
    me.state.currentFilter!=='story' && Object.keys(me.idMap[me.state.currentFilter]).forEach(nodeId => {
      if (me.state.nodeListSelectionMap[nodeId] && me.allNodeMap[nodeId]) {
        textList.push(getNodeDisplayTitle(me.allNodeMap[nodeId]));
      }
    });
    me.state.currentFilter==='story' && me.storyNodeMaps.forEach((node) => {
      if (node && me.state.nodeListSelectionMap[node.id]) {
        textList.push(node.fname);
      }
    });
    if (textList.length <= 0) {
      message.warn('请先选择要复制文本内容的节点。');
      return;
    }

    let result = copy(textList.join("\r\n"), {
      message: '请按下 #{key} 复制选中节点文本。',
    });

    if (result) message.success('选中节点文本已复制到剪切板。');
  };

  copySelectedNodesIDToClipboard = () => {
    let me = this, textList = [];
    
    me.state.currentFilter!=='story' && Object.keys(me.idMap[me.state.currentFilter]).forEach(nodeId => {
      if (me.state.nodeListSelectionMap[nodeId] && me.allNodeMap[nodeId]) {
        textList.push(nodeId);
      }
    });
    me.state.currentFilter==='story' && me.storyNodeMaps.forEach((node) => {
      if (node && me.state.nodeListSelectionMap[node.id]) {
        textList.push(node.id);
      }
    });
    if (textList.length <= 0) {
      message.warn('请先选择要复制ID的节点。');
      return;
    }

    let result = copy(textList.join("\r\n"), {
      message: '请按下 #{key} 复制选中节点ID。',
    });

    if (result) message.success('选中节点ID已复制到剪切板。');
  };

  removeSelectedNodes = () => {
    let me = this, selectedNodeIdList = [];
    if(!(me.state.nodeListSelectedAmount === 0 || me.state.currentFilter === 'story')){
      Object.keys(me.idMap[me.state.currentFilter]).forEach(nodeId => {
        if (me.state.nodeListSelectionMap[nodeId] && me.allNodeMap[nodeId]) {
          selectedNodeIdList.push(nodeId);
        }
      });
      me.props.bus.emit('relation', 'node.on_remove', selectedNodeIdList);
    }
  };

  onMouseMove = () => {
    // fake empty function, initialized after mount 3 seconds
  };

  recalculateTriggerArea = () => {
    let me = this;
    if(!me.state.outShow){
      return;
    }

    me.showStatisticsPanelTriggerArea = {
      minX: 0,
      maxX: 0,
      minY: 0,
      maxY: 0,
    };
    me.hideStatisticsPanelTriggerArea = {
      minX: 0,
      maxX: 0,
      minY: 0,
      maxY: 0,
    };
    if (me.statisticsElement) {
      {
        let {left: minX, right: maxX, top: minY, bottom: maxY} = me.statisticsElement.children[0].getBoundingClientRect();
        maxX = Math.max(maxX, 10);
        me.showStatisticsPanelTriggerArea = {minX, maxX, minY, maxY};
      }
      {
        let {left: minX, right: maxX, top: minY, bottom: maxY} = me.statisticsElement.getBoundingClientRect();
        maxX = Math.max(maxX, 10);
        me.hideStatisticsPanelTriggerArea = {minX, maxX, minY, maxY};
      }
    }
    if(me.storyListElement){
        let {left: minX, right: maxX, top: minY, bottom: maxY} = me.storyListElement.getBoundingClientRect();
        me.hideStatisticsStoryListArea = {minX, maxX, minY, maxY};
    }
    if(me.nodeListElement){
      let {left: minX, right: maxX, top: minY} = me.nodeListElement.children[0].getBoundingClientRect();
      let {bottom: maxY} = me.nodeListElement.children[1].getBoundingClientRect();
      me.hideStatisticsNodeListArea = {minX, maxX, minY, maxY};
    }
  };

  animateStatisticsGrid = () => {
    let me = this;
    const {statisticsGridAnim} = me;

    if (statisticsGridAnim) stopAnimation(statisticsGridAnim);

    requestAnimationFrame(() => {
      me.statisticsGridAnim = anime({
        targets: me.statisticsElement,
        translateX: me.state.showStatisticsGrid ? 0 : ((me.state.currentFilter === 'story'||(me.state.currentFilter === 'story-list' && window.innerWidth<2000))?-me.storyListElement.children[0].getBoundingClientRect().width-me.statisticsElement.children[0].getBoundingClientRect().width:-me.statisticsElement.children[0].getBoundingClientRect().width),
        duration: 400,
        easing: 'easeInOutCirc',
        update: function() {
          me.recalculateTriggerArea();
        },
      });
    });
  };

  /*
  recalculateTriggerArea = () => {
    let me = this;

    me.showStatisticsPanelTriggerArea = {
      minX: 0,
      maxX: 0,
      minY: 0,
      maxY: 0,
    };
    me.hideStatisticsPanelTriggerArea = {
      minX: 0,
      maxX: 0,
      minY: 0,
      maxY: 0,
    };
    if (me.statisticsElement) {
      {
        let {left: minX, right: maxX, top: minY, bottom: maxY} = me.statisticsElement.children[0].getBoundingClientRect();
        maxX = Math.max(maxX, 10);
        me.showStatisticsPanelTriggerArea = {minX, maxX, minY, maxY};
      }
      {
        let {left: minX, right: maxX, top: minY, bottom: maxY} = me.statisticsElement.getBoundingClientRect();
        maxX = Math.max(maxX, 10);
        me.hideStatisticsPanelTriggerArea = {minX, maxX, minY, maxY};
      }
    }
    if(me.storyListElement){
        let {left: minX, right: maxX, top: minY, bottom: maxY} = me.storyListElement.children[0].getBoundingClientRect();
        me.hideStatisticsStoryListArea = {minX, maxX, minY, maxY};
    }
    if(me.nodeListElement){
      let {left: minX, right: maxX, top: minY} = me.nodeListElement.children[0].getBoundingClientRect();
      let {bottom: maxY} = me.nodeListElement.children[1].getBoundingClientRect();
      me.hideStatisticsNodeListArea = {minX, maxX, minY, maxY};
    }
  };
  */

  // 自定义排序 排序
  changeCustomSort = (nodeId, index, targetIndex) => {
    let me = this;
    if (targetIndex < 0 || targetIndex >= Object.keys(me.idMap[me.state.currentFilter]).length) return;

    let targetNodeId = Object.keys(me.idMap[me.state.currentFilter])[targetIndex];
    let currentNodeId = Object.keys(me.idMap[me.state.currentFilter])[index];

    let nodeIds = Object.keys(me.idMap[me.state.currentFilter]);
    nodeIds[index] = targetNodeId;
    nodeIds[targetIndex] = currentNodeId;
    if (me.state.currentFilter === 'favorite') {
      PB.emit('relation', 'user.favorite.updating', {nodeIds, currentNodeId, targetNodeId});
    } else if (me.state.currentFilter === 'important') {
      PB.emit('relation', 'view.favorite.updating', {nodeIds, currentNodeId, targetNodeId});
    }
  };

  getStoryNodeBody = (loading,presentationPlayingNodeId, idMap, allNodeMap, storyNodeIds) => {
    let me = this, storyNodeMaps = [], notNodeNum = 0;
    (storyNodeIds.length>0?storyNodeIds:Object.keys(idMap['story'])).forEach((n,index) => {
    //storyNodeIds && storyNodeIds.forEach((n,index) => {
      if(me.currentStoryInfo['content']['nodeIds'].includes(n)){
        if(Object.keys(idMap['story']).includes(n)){
          storyNodeMaps.push(allNodeMap[n]);
        }else{
          //storyNodeMaps.push({id:n,fname:'- - - - - - - -'});
        }
      }else{
        storyNodeMaps.push({id:'id_'+index,fname:n,type:'notNode'});
        notNodeNum++
      }
    })
    me.storyNodeMaps = storyNodeMaps;
    let notNodeIdx = 1;
    return loading ? null : (
      <ul className={style['list-content-li-box']}>
        {
          me.state.nodeListLimit > 0 && storyNodeMaps.length>0 &&
            storyNodeMaps.map((node, index) => (
              node.type!=='notNode'?(
                <li
                  className={
                    presentationPlayingNodeId === node.id || me.state.focusNodeId=== node.id ? style['active'] : ''
                  }
                  style={{whiteSpace:'normal'}}
                  onClick={() => me.doFocusNode(node)}
                >
                  <Checkbox style={{marginRight: '8px',float:'left',top:0}}
                              onClick={e => e.stopPropagation()}
                              onChange={e => me.onNodeListSelectionChanged(node.id, e)}
                              checked={me.state.nodeListSelectionMap[node.id] === true}/>
                  <span className={notNodeNum>0?style['node-dot-exp'] : style['node-dot']}>
                    ○
                  </span>
                  <span className={style['node-fname']}>{node.fname}</span>
                </li>
            ) : (
            <li
                key={`n-${index}`}
                className={
                  presentationPlayingNodeId === node.fname || me.state.focusNodeId=== node.fname ? style['active'] : ''
                }
                style={{whiteSpace:'normal'}}
                onClick={() => me.doFocusNode({id:'id_'+index,description:node.fname,type:'notNode'})}
              >
                <Checkbox style={{marginRight: '8px',float:'left',top:0}}
                              onClick={e => e.stopPropagation()}
                              onChange={e => me.onNodeListSelectionChanged(node.id, e)}
                              checked={me.state.nodeListSelectionMap[node.id] === true}/>
                <span className={style['node-line']}>{notNodeIdx<100?notNodeIdx++:'--'}</span>
                <span className={style['node-text']}>{node.fname}</span>
              </li>
            ))
          )
        }
      </ul>
    );
  };

  getStoryInfoBody = (currentStoryInfo) => {
    let me = this;
    return (
      <div className={style['story-info-box']}>
        <div className={style['config-title-box']}>
          <div className={`ant-avatar common-avatar ${style['config-avatar']}`}>
            <img src={currentStoryInfo.meta && currentStoryInfo.meta['iconData'] ? currentStoryInfo.meta['iconData'] : defaultIconData} alt={'icon'}/>
          </div>
          <div className={style['config-title']}>
            <Popover content={currentStoryInfo.title} placement="bottom">{currentStoryInfo.title}</Popover>
          </div>
        </div>
        <div className={style['config-misc']}>
          <div className={style['config-owner']}>
            { me.memberMap && me.memberMap[`user-${currentStoryInfo.userId}`] && 
                        <UserAvatar
                          size={15}
                          name={me.memberMap[`user-${currentStoryInfo.userId}`].nick}
                          src={me.memberMap[`user-${currentStoryInfo.userId}`].picId ? `${REQUEST_BASE}/user/user/file/${me.memberMap[`user-${currentStoryInfo.userId}`].picId}?Authorization=${getToken()}` : defaultIconData}
                          colors={AvatarColors}
                          style={{marginRight: '0.5em',paddingTop:'0.3rem'}}
                        />
            }
            <Popover content={me.memberMap && me.memberMap[`user-${currentStoryInfo.userId}`]
                          ? (me.memberMap[`user-${currentStoryInfo.userId}`].nick) : (
                            currentStoryInfo.userId === -1 ? intl.get('Custom.view.system') : '--'
                          )} placement="top">
              <span>
                      {
                        me.memberMap && me.memberMap[`user-${currentStoryInfo.userId}`]
                          ? (me.memberMap[`user-${currentStoryInfo.userId}`].nick) : (
                            currentStoryInfo.userId === -1 ? intl.get('Custom.view.system') : '--'
                          )
                      }
              </span>
            </Popover>
          </div>
          <div className={style['config-update-timestamp']}>
              <Icon type={IconTypes.ICON_FONT} name={'icon-latest'} style={{marginRight: '0.3em'}}/>
              <TimeDisplayField timestamp={(new Date(currentStoryInfo.updateTimestamp)).getTime()} />
          </div>
        </div>
        <div className={style['story-info-description']}>{currentStoryInfo.description}</div>
      </div>
    );
  };

  getOperateBody = () => {
    let me = this;
    return (<div className={`${style['list-header-checkbox']} ${style['list-header-story-checkbox']}`}>
    <Checkbox
      indeterminate={me.state.nodeListAllSelectedIndeterminate}
      checked={me.state.nodeListAllSelected}
      onChange={e => me.onNodeListSelectAllChanged(e)}
    >
      全选
    </Checkbox>
    <Tooltip
      placement={"top"}
      title={"复制选中节点文本"}
      overlayClassName={'dark-theme'}
    >
      <a
        className={me.state.nodeListSelectedAmount === 0 ? 'disabled' : null}
        onClick={me.copySelectedNodesToClipboard}
      >
        <Icon name={'snippets'}/>
      </a>
    </Tooltip>
    <Tooltip
      placement={"top"}
      title={"复制选中节点ID"}
      overlayClassName={'dark-theme'}
    >
      <a
        className={me.state.nodeListSelectedAmount === 0 ? 'disabled' : null}
        onClick={me.copySelectedNodesIDToClipboard}
      >
        <Icon name={'copy'}/>
      </a>
    </Tooltip>
    <Tooltip
      placement={"top"}
      title={"删除选中节点"}
      overlayClassName={'dark-theme'}
    >
      <a
        className={(me.state.nodeListSelectedAmount === 0 || me.state.currentFilter === 'story') ? 'disabled' : null}
        onClick={me.removeSelectedNodes}
      >
        <Icon name={'delete'}/>
      </a>
    </Tooltip>
    <Tooltip
      placement={"top"}
      title={"名称排序"}
      overlayClassName={'dark-theme'}
    >
      <a
        className={['alphaAsc', 'alphaDesc'].includes(me.state.sortBy) ? 'current' : null}
        onClick={() => {
          if (!['alphaAsc', 'alphaDesc'].includes(me.state.sortBy)) {
            me.state.sortBy = 'alphaAsc'; // 这里不用setState，调用doStatistics后回自动调用
          } else if (me.state.sortBy === 'alphaAsc') {
            me.state.sortBy = 'alphaDesc'; // 这里不用setState，调用doStatistics后回自动调用
          } else {
            me.state.sortBy = 'alphaAsc'; // 这里不用setState，调用doStatistics后回自动调用
          }
          me.doStatistics(me.dataCache);
          me.storyNodeSort(me.dataCache);
        }}
        style={{float: 'right'}}
      >
        <Icon type={IconTypes.ICON_FONT} name={'icon-a_to_z'}/>
      </a>
    </Tooltip>
    {
      me.state.currentFilter === 'favorite' || me.state.currentFilter === 'important' ? (
        <Tooltip
          placement={"top"}
          title={'自定义排序'}
          overlayClassName={'dark-theme'}
        >
          <a
            className={me.state.sortBy === 'custom' ? 'current' : null}
            onClick={() => {
              if (me.state.sortBy !== 'custom') {
                me.state.sortBy = 'custom'; // 这里不用setState，调用doStatistics后回自动调用
                me.doStatistics(me.dataCache);
                me.storyNodeSort(me.dataCache);
              }
            }}
            style={{float: 'right'}}
          >
            <Icon type={IconTypes.ICON_FONT} name={'icon-one-to-three'}/>
          </a>
        </Tooltip>
      ) : null
    }
    <Tooltip
      placement={"top"}
      title={"时间排序"}
      overlayClassName={'dark-theme'}
    >
      <a
        className={['latest', 'earliest'].includes(me.state.sortBy) ? 'current' : null}
        onClick={() => {
          if (!['latest', 'earliest'].includes(me.state.sortBy)) {
            me.state.sortBy = 'latest'; // 这里不用setState，调用doStatistics后回自动调用
          } else if (me.state.sortBy === 'latest') {
            me.state.sortBy = 'earliest'; // 这里不用setState，调用doStatistics后回自动调用
          } else {
            me.state.sortBy = 'latest'; // 这里不用setState，调用doStatistics后回自动调用
          }
          me.doStatistics(me.dataCache);
          me.storyNodeSort(me.dataCache);
        }}
        style={{float: 'right'}}
      >
        <Icon type={IconTypes.ICON_FONT} name={'icon-latest'}/>
      </a>
    </Tooltip>
    <Tooltip
      placement={"top"}
      title={'连接模式'}
      overlayClassName={'dark-theme'}
    >
      <a
        className={me.state.sortBy === 'connection' ? 'current' : null}
        onClick={() => {
          if (me.state.sortBy !== 'connection') {
            me.state.sortBy = 'connection'; // 这里不用setState，调用doStatistics后回自动调用
            me.doStatistics(me.dataCache);
            me.storyNodeSort(me.dataCache);
          }
        }}
        style={{float: 'right'}}
      >
        <Icon type={IconTypes.ICON_FONT} name={'icon-relation'}/>
      </a>
    </Tooltip>
    {
      me.state.currentFilter === 'story' ? (
        <Tooltip
          placement={"top"}
          title={'专题报告'}
          overlayClassName={'dark-theme'}
        >
          <a
            className={me.state.sortBy === 'story' ? 'current' : null}
            onClick={() => {
              if (me.state.sortBy !== 'story') {
                me.state.sortBy = 'story'; // 这里不用setState，调用doStatistics后回自动调用
                me.doStatistics(me.dataCache);
                me.storyNodeSort(me.dataCache);
              }
            }}
            style={{float: 'right'}}
          >
            <Icon type={IconTypes.ICON_FONT} name={'icon-story'}/>
          </a>
        </Tooltip>
      ) : null
    }
    <Tooltip
      overlayClassName={style['presentation-content']}
      placement={"top"}
      title={me.state.presentationStarted ? (
        me.state.presentationStayFocus ? (
          <span style={{display: 'inline-block', padding: '6px 8px'}}>
                                  ↑ / ←：上一个节点<br/>
                                  ↓ / →：下一个节点<br/>
                                  空格/点击按钮：恢复自动播放
                              </span>
        ) : (
          <span style={{display: 'inline-block', padding: '6px 8px'}}>
                                  {`正在漫游${
                                    me.filterName
                                      ? `左侧“${me.filterName}”筛选`
                                      : ('')
                                  }节点列表`}<br/>点击按钮停止，按空格键暂停
                              </span>
        )
      ) : (
        <div className={`dark-theme`}>
          <Menu style={{borderRight: 'none'}} selectable={false}>
            <Menu.ItemGroup title={(
              <span><span style={{fontSize: '1.2em',color:'#fff'}}>选择播放速度</span></span>
            )}
            >
              {
                me.presentationDuringArr.map(item => (
                  <Menu.Item
                    key={`presentation-during-${item.during}`}
                    onClick={e => {
                      this.presentationPlay(item.during);
                    }}
                  >
                    <Icon name={'check'}
                          className={style['check-icon']}
                          style={{
                            visibility: item.during === me.state.currentDuring && me.state.presentationStarted ? 'visible' : 'hidden',
                          }}
                    />
                    {item.text}
                  </Menu.Item>
                ))
              }
            </Menu.ItemGroup>
          </Menu>
        </div>
      )}
      overlayClassName={'dark-theme'}
    >
      <a
        className={me.state.sortBy === 'play' ? 'current' : null}
        onClick={(e) => {
          me.state.sortBy = 'play';
          if(me.state.presentationStarted){
            e.stopPropagation();
            me.presentationPlayControl();
          }
        }}
        style={{float: 'right'}}
      >
        {
          me.state.presentationStarted ? (
            me.state.presentationStayFocus ? (
              <Icon name={'icon-left-right'} type={IconTypes.ICON_FONT}/>
            ) : (
              <Icon name={'icon-stop_play'} type={IconTypes.ICON_FONT}/>
            )
          ) : (
            <Icon name={'caret-right'} type={IconTypes.ANT_DESIGN}/>
          )
        }
      </a>
    </Tooltip>
  </div>);
  };

  onStartPresentation = (presentationId, config, username, userPicId, iconData) => {
    let me = this;
    config.username = username;
    config.userPicId = userPicId;
    config.iconData = iconData;
    me.props.bus.emit('relation', 'node.presentation.stop');
    me.props.bus.emit('presentation', 'config.play.do', {viewId: me.props.viewId, presentationId, config});
  };

  onKeyDown = e => {
    let me = this;
    if (!me.presentationStarted) {
      let filterNodeIds = me.state.currentFilter === 'story'? me.state.storyNodeIds.length>0?me.state.storyNodeIds:Object.keys(me.idMap['story']) : me.presentationFilterNodeIds;
      if(filterNodeIds && filterNodeIds.length>0){
        me.props.bus.emit('image_light_box', 'hide.do');
        if( e.keyCode === 33 || e.keyCode === 34){
          let nextNodeId = filterNodeIds[0],nextIndex = 0;
          let currentIndex = -1;
          filterNodeIds.forEach((nodeId,index) =>{
            if(nodeId == me.state.focusNodeId){
              currentIndex = index;
            }
          })
          if ( e.keyCode === 33 /* pgup */) {
            if(currentIndex==0){
              nextNodeId = filterNodeIds[filterNodeIds.length-1];
              nextIndex = filterNodeIds.length-1;
            }else{
              nextNodeId = filterNodeIds[currentIndex-1];
              nextIndex = currentIndex-1;
            }
          } else if (e.keyCode === 34 /* pgdown */ ) {
            if(currentIndex==filterNodeIds.length-1){
              nextNodeId = filterNodeIds[0];
              nextIndex = 0;
            }else{
              nextNodeId = filterNodeIds[currentIndex+1];
              nextIndex = currentIndex+1;
            }
          }
          if(me.state.currentFilter === 'story'){
            if(nextNodeId==me.storyNodeMaps[nextIndex].id){
              me.doFocusNode(me.storyNodeMaps[nextIndex]);
            }else{
              me.doFocusNode({id:me.storyNodeMaps[nextIndex].id,description:me.storyNodeMaps[nextIndex].fname,type:'notNode'})
            }
          }else{
            me.doFocusNode(me.allNodeMap[nextNodeId]);
          }
        }
      }
    }
  };

  onKeyEvent = e => {
    let me = this;
    if (!me.presentationStarted) {
      if (e.keyCode === 33 || e.keyCode === 34)  {
        e.stopPropagation();
        e.preventDefault();
      }
    }
  };

  onPresentationNode = (config, index) => {
    let me = this,viewId = this.props.viewId;
    me.props.bus.emit('relation', 'presentation.custom_nodes.set',
        {viewId, title: config['title'], nodeIds: config['content']['nodeIds']});
    me.props.bus.emit('relation', 'node_filter.story_nodes.set',
        {viewId, title: config['title'], description: config['description'], storyInfo: config, nodeIds: config['content']['contentList']?config['content']['contentList']:config['content']['nodeIds']});
    me.props.bus.emit('relation', 'node_filter.filter.set',
        {viewId: config['viewId'], filterKey: 'story'});
    me.props.bus.emit('presentation', 'show.presentation.node.list.index', {index});
  };

  constructor(props) {
    super(props);

    let me = this;

    me.idMap = me.initIdMap();
    me.categoryFilterConfig = categoryFilterConfigFn(me);
    me.timelineFilterConfig = timelineFilterConfigFn(me);
    me.connectionFilterConfig = connectionFilterConfigFn(me);
    me.filterConfig = _.assign(
      {
        all: {
          text: intl.get('Custom.filter.all'),
          tip: intl.get('Custom.filter.highlightAllNodes'),
          fn: () => true,
        },
        favorite: {
          text: intl.get('Custom.filter.favorite'),
          tip: intl.get('Custom.filter.favoriteTip'),
          fn: node => me.idMap['favorite'][node.id],
        },
        important: {
          text: intl.get('Custom.filter.important'),
          tip: intl.get('Custom.filter.importantTip'),
          fn: node => me.idMap['important'][node.id],
        },
        find: {
          text: intl.get('Custom.filter.find'),
          tip: intl.get('Custom.filter.findTip'),
          fn: node => me.idMap['find'][node.id],
        },
        story: {
          text: intl.get('Custom.filter.report'),
          tip: intl.get('Custom.filter.reportTip'),
          fn: node => me.idMap['story'][node.id],
        },
        selected: {
          text: intl.get('Custom.filter.selected'),
          tip: intl.get('Custom.filter.selectedTip'),
          fn: node => me.idMap['selected'][node.id],
        },
        'story-list': {
          text: intl.get('Custom.filter.report'),
          tip: intl.get('Custom.filter.highlightReport'),
          fn: () => true,
        },
      },
      me.categoryFilterConfig,
      me.timelineFilterConfig,
      me.connectionFilterConfig
    );
  }

  componentDidMount() {
    let me = this;

    me.props.bus.sub(me, 'relation', 'presentation.playing.focus', () => {
      me.setState({presentationStarted: true, presentationStayFocus: true});
    });

    me.props.bus.sub(me, 'relation', 'presentation.playing.continue', () => {
      me.setState({presentationStarted: true, presentationStayFocus: false});
    });

    me.props.bus.sub(me, 'relation', 'presentation.started', () => {
      me.setState({presentationStarted: true, presentationStayFocus: false});
    });

    me.props.bus.sub(me, 'relation', 'presentation.stopped', () => {
      me.playPresentationAction = 'do_resume';
      me.setState({presentationStarted: false, presentationStayFocus: false});
    });

    me.props.bus.sub(me, 'relation', 'presentation.filtered_nodes.set', ({viewId, filterName, nodeIds}) => {
      if (me.props.viewId && me.props.viewId !== viewId) return;
      me.presentationFilterNodeIds = nodeIds;
      me.playPresentationAction = 'do_start';
      me.filterName = filterName;
    });

    me.props.bus.subscribe(me, 'relation', 'data.loaded', ({nodes, edges}) => {
      me.dataCache = {nodes, edges};

      me.props.bus.once(me, 'relation', 'user.favorite.on_data_ready', () => {
        me.doStatistics(me.dataCache);
      });

      me.props.bus.emit('relation', 'user.favorite.get');
    });

    me.props.bus.subscribe(me, 'relation', 'data.after_change', ({nodes, edges}) => {
      me.dataCache = {nodes, edges};
      me.doStatistics(me.dataCache);
    });
    
    me.props.bus.subscribe(me, 'presentation', 'config.list.success', ({viewId, configList}) => {
      if (me.props.viewId !== viewId) return;
      me.presentationList = [...configList];
    });
    me.props.bus.subscribe(me, 'presentation', 'show.presentation.node.list', () => {
      if(me.presentationList.length>0){
        let index = me.currentPresentationIndex === -1 ? 0 : (me.currentPresentationIndex !== (me.presentationList.length-1) ? (me.currentPresentationIndex+1) : 0 ) ;
        me.onPresentationNode(me.presentationList[index],index);
      }else{
        message.info('加载中，请稍后...');
      }
    }).subscribe(me,'presentation', 'show.presentation.node.list.index', ({index}) => {
      me.currentPresentationIndex = index;
    }).subscribe(me,'teamwork', 'member.list.success', ({viewId, userList}) => {
      if (me.props.viewId !== viewId) return;
      me.memberMap = {};
      userList.forEach(userInfo => {
        me.memberMap[`user-${userInfo.userId}`] = userInfo;
      });
    });

    me.props.bus.sub(me, 'relation', 'node.single_selection_change', nodeId => {
      if (me.currentSelectedNodeId !== nodeId) {
        me.filterConfig['selected'].tip = '高亮与选中节点关联的其他节点';
        if (nodeId && me.allNodeMap[nodeId]) {
          // 用户点击了一个节点，需要更新filterConfig和idMap
          me.currentSelectedNodeId = nodeId;
          me.filterConfig['selected'].tip = `高亮与【${getNodeDisplayTitle(me.allNodeMap[nodeId])}】关联的节点`;
          me.doStatistics({nodes: false, edges: false});
          me.props.bus.emit('relation', 'presentation.do_pause');
          me.gravityNodeList = [];
          me.props.bus.emit('relation', 'gravity_node_list.do_load', {sourceNodeId: nodeId});
          me.resetFilter('selected', false);
        } else if (!nodeId) {
          // 取消选中节点
          me.currentSelectedNodeId = undefined;
          me.additionalNodeIds = [];
          me.doStatistics({nodes: false, edges: false});
          me.rollbackFilter();
        } else {
          // 找不到对应节点
          me.currentSelectedNodeId = undefined;
          me.additionalNodeIds = [];
          me.doStatistics({nodes: false, edges: false});
          me.rollbackFilter();
        }
      }
    });

    me.props.bus.sub(me, 'relation', 'node.added', ({nodeIds, toNodeId}) => {
      if (me.currentSelectedNodeId && me.currentSelectedNodeId === toNodeId) {
        me.additionalNodeIds = me.additionalNodeIds.concat(nodeIds);
        me.doStatistics({nodes: false, edges: false});
        setTimeout(() => me.resetFilter('selected', false, false), 100);
      }
    });
    /* 暂时不显示可能关注 2023-01-31
    me.props.bus.sub(me, 'relation', 'gravity_node_list.loaded', ({sourceNodeId, nodeList}) => {
      if (me.currentSelectedNodeId && me.currentSelectedNodeId === sourceNodeId) {
        me.gravityNodeList = nodeList;
        me.forceUpdate();
      }
    });
    */
    me.props.bus.sub(me, 'nodeFilter', 'update.localstorage.nodeFilter', async ({viewId, type}) => {
      let nodesMap = {};
      let filterKey = me.state.currentFilter === 'none' ? 'all' : me.state.currentFilter;
      Object.keys(me.idMap.all).forEach(nodeId => {
        let node = me.allNodeMap[nodeId];
        nodesMap[node.id] = {
          id: node.id,
          fname: node.fname,
          description: node.description,
          tags: node.tags,
          userId: node.userId,
          type: node.type,
          lev: node.lev,
          updateTime: node.updateTime,
          linkTime: node.linkTime,
        }
      });

      localStorage.setItem(`MT_nodes_${viewId}`, JSON.stringify(nodesMap));

      // 筛选的文字，用于给用户提示
      let filterText;
      if (filterKey.includes('category-')) {
        filterText = me.categoryFilterConfig[filterKey].text
      } else if (['today', 'yesterday', 'dby'].includes(filterKey) ) {
        filterText = me.timelineFilterConfig[filterKey].text
      } else if (['individual', 'connectedToMine'].includes(filterKey) ) {
        filterText = me.connectionFilterConfig[filterKey].text
      } else {
        filterText = me.filterConfig[filterKey].text
      }

      if (filterKey !== 'all') {
        localStorage.setItem(`MT_nodeIds_filter_${viewId}`, JSON.stringify({
          key: filterKey,
          text: filterText,
          nodeIds: Object.keys(me.idMap[filterKey]),
        }));

        message.info(<span style={{textAlign: 'center'}}>
          <span>{`您当前选择了“${filterText}”类型的节点`},</span>
          <span>{`将以此为解析数据进入${type === 'timeline' ? '时间' : '地理'}视图页面！`}</span>
        </span>);

        setTimeout(() => {
            window.open(`/relation/${viewId}/${type}`, '_blank');
        }, 2000);
      } else {
        localStorage.removeItem(`MT_nodeIds_filter_${viewId}`);
        window.open(`/relation/${viewId}/${type}`, '_blank');
      }

    });

    // 当前自动漫游的节点
    me.props.bus.sub(me, 'relation', 'presentation.playing.node', ({nodeId}) => {
      if (me.state.presentationStarted && nodeId) {
        me.setState({
          presentationPlayingNodeId: nodeId,
          focusNodeId: nodeId
        })
      }
    });

    me.props.bus.sub(me, 'relation', 'presentation.started', () => {
      me.setState({ presentationStarted: true });
    });

    me.props.bus.sub(me, 'relation', 'presentation.stopped', () => {
      me.setState({ presentationStarted: false, presentationPlayingNodeId: undefined });
    });

    me.props.bus.sub(me, 'relation', 'node_filter.story_nodes.set', ({viewId, title, nodeIds,storyInfo}) => {
      if (me.props.viewId && me.props.viewId !== viewId) return;
      if (nodeIds && nodeIds.length > 0) {
        me.storyNodeIdMap = {};
        //me.storyNodeIds = [...nodeIds];
        me.setState({storyNodeIds:[...nodeIds]});
        nodeIds.forEach(nodeId => me.storyNodeIdMap[nodeId] = true);
        me.filterConfig.story.tip = `高亮专题报告【${title}】中的节点`;
        me.currentStoryInfo = storyInfo;
      } else {
        me.storyNodeIdMap = undefined;
        //me.storyNodeIds = undefined;
        me.setState({storyNodeIds:undefined});
        me.filterConfig.story.tip = `高亮最近播放的专题报告中的节点`;
      }
      me.setState({showStatisticsGrid: true});
      me.doStatistics(me.dataCache);
    });

    me.props.bus.sub(me, 'relation', 'node_filter.filter.set', ({viewId, filterKey, userSelected = false}) => {
      if (me.props.viewId && me.props.viewId !== viewId) return;
      me.resetFilter(filterKey, userSelected);
    });

    me.props.bus.sub(me, 'relation', 'micro_service.node.get', ({viewId}) => {
      if (me.props.viewId && me.props.viewId !== viewId) return;
      let nedes={
        individual: me.idMap.individual,
        connectedToMine: me.idMap.connectedToMine,
        selected: me.idMap.selected
      }
      me.props.bus.emit('micro_service', 'relation.node.get', {viewId,nedes});
    });

    me.props.bus.sub(me, 'relation', 'node_filter.out.show', ({outShow}) => {
      me.setState({outShow});
    });

    requestAnimationFrame(() => me.props.bus.emit('relation', 'data.get'));

    setTimeout(() => {
      me.onMouseMove = e => {
        if (me.statisticsElement && !me.ignoreMouseMoveEvent &&
          Object.values(me.lastHoverKey).filter(v => !!v).length === 0) {

            if ((e.clientX > me.hideStatisticsStoryListArea.minX &&
              e.clientX < me.hideStatisticsStoryListArea.maxX && (
              e.clientY < me.hideStatisticsStoryListArea.minY ||
              e.clientY > me.hideStatisticsStoryListArea.maxY
              ))|| (e.clientX > me.hideStatisticsNodeListArea.minX &&
              e.clientX < me.hideStatisticsNodeListArea.maxX && (
              e.clientY < me.hideStatisticsNodeListArea.minY ||
              e.clientY > me.hideStatisticsNodeListArea.maxY
              ))){
                if (me.state.showStatisticsGrid) {
                  requestAnimationFrame(() => me.setState({showStatisticsGrid: false},
                    () => me.animateStatisticsGrid()));
                }
            } else if (e.clientX >= me.showStatisticsPanelTriggerArea.minX &&
            e.clientX <= me.showStatisticsPanelTriggerArea.maxX &&
            e.clientY >= me.showStatisticsPanelTriggerArea.minY &&
            e.clientY <= me.showStatisticsPanelTriggerArea.maxY) {

            me.state.firstLoading && this.setState({firstLoading: false});
            // 需要显示
            if (!me.state.showStatisticsGrid) {
              requestAnimationFrame(() => me.setState({showStatisticsGrid: true},
                () => me.animateStatisticsGrid()));
            }
          } else if (e.clientX < me.hideStatisticsPanelTriggerArea.minX ||
            e.clientX > me.hideStatisticsPanelTriggerArea.maxX ||
            e.clientY < me.hideStatisticsPanelTriggerArea.minY ||
            e.clientY > me.hideStatisticsPanelTriggerArea.maxY) {
            // 需要隐藏
            if (me.state.showStatisticsGrid) {
              requestAnimationFrame(() => me.setState({showStatisticsGrid: false},
                () => me.animateStatisticsGrid()));
            }
          }
        }
        if(e.clientX > me.hideStatisticsStoryListArea.minX &&
          e.clientX < me.hideStatisticsStoryListArea.maxX &&
          e.clientY > me.hideStatisticsStoryListArea.minY &&
          e.clientY < me.hideStatisticsStoryListArea.maxY){
            me.state.firstLoading && this.setState({firstLoading: false});
        }
      };
    }, 3000);

    let ScreenObject_1 = window.matchMedia('(max-height:600px)').matches?1:0;
    let ScreenObject_2 = window.matchMedia('(max-height:800px)').matches?1:0;
    let ScreenObject_3 = window.matchMedia('(max-height:1000px)').matches?1:0;
    let ScreenObject_4 = window.matchMedia('(max-height:1200px)').matches?1:0;
    let ScreenObject_5 = window.matchMedia('(max-height:1500px)').matches?1:0;
    let ScreenObject_6 = window.matchMedia('(max-height:1800px)').matches?1:0;
    let ScreenObject_7 = window.matchMedia('(max-height:2200px)').matches?1:0;
    let smallScreen = 7 - ScreenObject_1 - ScreenObject_2 - ScreenObject_3 - ScreenObject_4 - ScreenObject_5 - ScreenObject_6 - ScreenObject_7;
    me.setState({smallScreen});
  }

  componentDidUpdate() {
    let ScreenObject_1 = window.matchMedia('(max-height:600px)').matches?1:0;
    let ScreenObject_2 = window.matchMedia('(max-height:800px)').matches?1:0;
    let ScreenObject_3 = window.matchMedia('(max-height:1000px)').matches?1:0;
    let ScreenObject_4 = window.matchMedia('(max-height:1200px)').matches?1:0;
    let ScreenObject_5 = window.matchMedia('(max-height:1500px)').matches?1:0;
    let ScreenObject_6 = window.matchMedia('(max-height:1800px)').matches?1:0;
    let ScreenObject_7 = window.matchMedia('(max-height:2200px)').matches?1:0;
    let smallScreen = 7 - ScreenObject_1 - ScreenObject_2 - ScreenObject_3 - ScreenObject_4 - ScreenObject_5 - ScreenObject_6 - ScreenObject_7;
    this.setState({smallScreen});
  }

  componentWillUnmount() {
    let me = this;
    me.props.bus.remove(me);
  }

  doFocusNode = node => {
    if (node && node.id) {
      this.setState({
        focusNodeId: node.type=='notNode'?node.description:node.id
      })
    }
    this.props.bus.emit('relation', 'node.presentation.focus', {node: node});
  };

  render() {
    let me = this;

    return me.state.loading ? null : (
      <div
        className={`${style['frame']} ${me.state.currentFilter === 'story' ? '':'dark-theme'}`}
        ref={ele => {
          if (!ele || ele === me.statisticsElement) return;
          me.statisticsElement = ele;
          me.recalculateTriggerArea();
        }}
      >
        <EventListener
          target={window}
          onResize={() => me.recalculateTriggerArea()}
          onMouseDown={() => me.ignoreMouseMoveEvent = true}
          onMouseUp={() => me.ignoreMouseMoveEvent = false}
          onMouseMove={e => me.onMouseMove(e)}
          onKeyDown={me.onKeyDown}
          onKeyPress={me.onKeyEvent}
          onKeyUp={me.onKeyEvent}
        />
        {me.state.outShow && <>
        <div
          className={`${style['statistics-frame']} ${me.lastHoverKey['statistics'] ? 'hover' : ''}`}
        >
          <div className={`${style['statistics-content-frame']} ${me.lastHoverKey['statistics'] ? 'hover' : ''} `}>
            <table>
              <tbody>
              <tr
                  className={`${me.state.currentFilter === 'story-list' || me.state.currentFilter === 'story' ? 'highlighted' : null}`}
                  onClick={() => {
                    me.props.bus.emit('relation', 'presentation.do_pause');
                    me.resetStoryFilter('story-list');
                  }}>
                <th colSpan={2}>{intl.get('Custom.filter.report')}</th>
                <th>
                  <a
                    onClick={(e) =>{
                      e.stopPropagation();
                      me.setState({showStatisticsPanel: 'node_general', nodeStatisticsConditionKey: 'ICON'})
                      }
                    }
                  >
                    <Tooltip placement={'right'} title={'综合统计'}>
                      <Icon name={'icon-ranking'} type={IconTypes.ICON_FONT}/>
                    </Tooltip>
                  </a>
                </th>
              </tr>
              </tbody>
            </table>
            <hr />
            <table>
              <thead>
              <tr>
                <th colSpan={2}>{intl.get('Custom.view.node')}：</th>
                <th>
                  <a onClick={() => {me.setState({showStatisticsPanel: 'word'})}}>
                    <Tooltip placement={'right'} title={'词频统计'}>
                      <Icon name={'icon-ranking'} type={IconTypes.ICON_FONT}/>
                    </Tooltip>
                  </a>
                </th>
              </tr>
              </thead>
              <tbody>
              <Tooltip
                title={me.filterConfig.all.tip}
                key={'all'}
                placement={'right'}
                mouseLeaveDelay={0.05}
                onVisibleChange={visible =>
                  visible ? me.onComponentHover('statistics', 'all') :
                    me.delayComponentLostHover('statistics', 'all')}
              >
                <tr
                  className={`${me.state.currentFilter === 'all' ? 'highlighted' : null} ${style['total-amount']}`}
                  onClick={() => {
                    me.props.bus.emit('relation', 'presentation.do_pause');
                    me.resetFilter('all');
                  }}
                >
                  <td><Icon name={'icon-circle-border'} type={IconTypes.ICON_FONT} /></td>
                  <td>{me.filterConfig.all.text}</td>
                  <td>{me.state.totalAmount}</td>
                </tr>
              </Tooltip>
              <Tooltip
                title={me.filterConfig.favorite.tip}
                key={'favorite'}
                placement={'right'}
                mouseLeaveDelay={0.05}
                onVisibleChange={visible =>
                  visible ? me.onComponentHover('statistics', 'favorite') :
                    me.delayComponentLostHover('statistics', 'favorite')}
              >
                <tr
                  className={`${me.state.currentFilter === 'favorite' ? 'highlighted' : null} ${style['favorite-amount']}`}
                  onClick={() => {
                    me.props.bus.emit('relation', 'presentation.do_pause');
                    me.resetFilter('favorite');
                  }}
                >
                  <td><Icon name={'heart'} theme={'filled'} /></td>
                  <td>{me.filterConfig.favorite.text}</td>
                  <td>{Object.keys(me.idMap['favorite']).length}</td>
                </tr>
              </Tooltip>
              <Tooltip
                title={me.filterConfig.important.tip}
                key={'important'}
                placement={'right'}
                mouseLeaveDelay={0.05}
                onVisibleChange={visible =>
                  visible ? me.onComponentHover('statistics', 'important') :
                    me.delayComponentLostHover('statistics', 'important')}
              >
                <tr
                  className={`${me.state.currentFilter === 'important' ? 'highlighted' : null} ${style['important-amount']}`}
                  onClick={() => {
                    me.props.bus.emit('relation', 'presentation.do_pause');
                    me.resetFilter('important');
                  }}
                >
                  <td><Icon name={'star'} theme={'filled'} /></td>
                  <td>{me.filterConfig.important.text}</td>
                  <td>{Object.keys(me.idMap['important']).length}</td>
                </tr>
              </Tooltip>
              <Tooltip
                title={me.filterConfig.find.tip}
                key={'find'}
                placement={'right'}
                mouseLeaveDelay={0.05}
                onVisibleChange={visible =>
                  visible ? me.onComponentHover('statistics', 'find') :
                    me.delayComponentLostHover('statistics', 'find')}
              >
                <tr
                  className={`${me.state.currentFilter === 'important' ? 'highlighted' : null} ${style['important-amount']}`}
                  onClick={() => {
                    me.props.bus.emit('relation', 'presentation.do_pause');
                    me.resetFilter('find');
                  }}
                >
                  <td><Icon name={'icon-robot-smile'} type={IconTypes.ICON_FONT} /></td>
                  <td>{me.filterConfig.find.text}</td>
                  <td>{Object.keys(me.idMap['find']).length}</td>
                </tr>
              </Tooltip>
             {/*
              <Tooltip
                title={me.filterConfig.story.tip}
                key={'story'}
                placement={'right'}
                mouseLeaveDelay={0.05}
                onVisibleChange={visible =>
                  visible ? me.onComponentHover('statistics', 'story') :
                    me.delayComponentLostHover('statistics', 'story')}
              >
                <tr
                  className={me.state.currentFilter === 'story' ? 'highlighted' : undefined}
                  onClick={() => {
                    me.props.bus.emit('relation', 'presentation.do_pause');
                    me.resetFilter('story');
                  }}
                >
                  <td style={{paddingBottom: '0.05rem'}}><Icon name={'icon-story'} type={IconTypes.ICON_FONT} /></td>
                  <th>{me.filterConfig.story.text}</th>
                  <th>{Object.keys(me.idMap['story']).length}</th>
                </tr>
              </Tooltip>
              */}
              </tbody>
            </table>
            <hr />
            <table>
              <thead>
              <tr>
                <th colSpan={2}>{intl.get('Custom.filter.type')}：</th>
                <th>
                  <a
                    onClick={() =>
                      me.setState({showStatisticsPanel: 'node_general', nodeStatisticsConditionKey: 'ICON'})}
                  >
                    <Tooltip placement={'right'} title={'综合统计'}>
                      <Icon name={'icon-ranking'} type={IconTypes.ICON_FONT}/>
                    </Tooltip>
                  </a>
                </th>
              </tr>
              </thead>
            </table>
            <div style={{maxHeight: me.state.smallScreen*9+'vh', overflow: 'hidden auto'}} className={'scrollbar-none'}>
              <table>
                <tbody>
                {
                  iconTypes.map(type => (
                    me.categoryFilterConfig[`category-${type}`] &&
                    me.idMap[`category-${type}`] &&
                    Object.values(me.idMap[`category-${type}`]).length > 0
                  ) ? (
                    <Tooltip
                      title={me.categoryFilterConfig[`category-${type}`].tip}
                      key={`category-${type}`}
                      placement={'right'}
                      mouseLeaveDelay={0.05}
                      onVisibleChange={visible =>
                        visible ? me.onComponentHover('statistics', `category-${type}`) :
                          me.delayComponentLostHover('statistics', `category-${type}`)}
                    >
                      <tr
                        className={me.state.currentFilter === `category-${type}` ? 'highlighted' : undefined}
                        onClick={() => {
                          me.props.bus.emit('relation', 'presentation.do_pause');
                          me.resetFilter(`category-${type}`);
                        }}
                      >
                        <td>{me.categoryFilterConfig[`category-${type}`].icon}</td>
                        <td>{me.categoryFilterConfig[`category-${type}`].text}</td>
                        <td>{Object.keys(me.idMap[`category-${type}`]).length}</td>
                      </tr>
                    </Tooltip>
                  ) : null)
                }
                </tbody>
              </table>
            </div>

            <hr />
            <table>
              <thead>
              <tr>
                <th colSpan={2}>{intl.get('Custom.general.news')}：</th>
                <th>
                  <a
                    onClick={() =>
                      me.setState({showStatisticsPanel: 'node_datetime', nodeStatisticsConditionKey: 'CREATE_DATE'})}
                  >
                    <Tooltip placement={'right'} title={'时间统计'}>
                      <Icon name={'icon-ranking'} type={IconTypes.ICON_FONT}/>
                    </Tooltip>
                  </a>
                </th>
              </tr>
              </thead>
              {me.getTBodyByType(timelineType, me.timelineFilterConfig)}
            </table>
            <hr />
            <table>
              <thead>
              <tr>
                <th colSpan={2}>{intl.get('Custom.filter.connect')}：</th>
                <th>
                  <a
                    onClick={() => me.setState({showStatisticsPanel: 'edge', nodeStatisticsConditionKey: 'TYPE'})}
                  >
                    <Tooltip placement={'right'} title={'连接统计'}>
                      <Icon name={'icon-ranking'} type={IconTypes.ICON_FONT}/>
                    </Tooltip>
                  </a>
                </th>
              </tr>
              </thead>
              {me.getTBodyByType(connectionType, me.filterConfig)}
            </table>
          </div>
        </div>
        {(me.state.currentFilter === 'story-list' || me.state.currentFilter === 'story') &&
        <div className={`${style['list-frame']} dark-theme ${me.lastHoverKey['list']||me.state.firstLoading ? 'hover' : ''}`}
        ref={ele => {
          if (!ele || ele === me.storyListElement) return;
          me.storyListElement = ele;
          me.recalculateTriggerArea();
        }}>
          <div className={`${style['list-content-frame']} scrollbar scrollbar-none ${me.lastHoverKey['list'] ? 'hover' : ''}`}>
            <StoryListFilter 
              loading={me.state.loading}
              bus={me.props.bus}
              viewId={me.props.viewId}
              resetFilter={me.resetFilter} />
          </div>
        </div>
        }
        {
          me.idMap[me.state.currentFilter] && Object.keys(me.idMap[me.state.currentFilter]).length > 0 && me.state.currentFilter !== 'story-list' ? (
            me.state.currentFilter === 'story'?(
              <div className={`${style['list-frame']} ${me.lastHoverKey['list'] ? 'hover' : ''}`} style={{color:'rgba(0, 0, 0, 0.65)'}}
              ref={ele => {
                if (!ele || ele === me.nodeListElement) return;
                me.nodeListElement = ele;
                me.recalculateTriggerArea();
              }}>
                <div className={style['list-header-frame']} style={{opacity:1,backgroundColor:'#fff'}}>
                  {me.currentStoryInfo && me.getStoryInfoBody(me.currentStoryInfo)}
                  {me.getOperateBody()}
                </div>
                <div style={{opacity:1,backgroundColor:'#fff'}} className={`${style['list-content-frame']} scrollbar ${me.lastHoverKey['list'] ? 'hover' : ''}`}>
                  {me.getStoryNodeBody(me.state.loading, me.state.presentationPlayingNodeId, me.idMap, me.allNodeMap, me.state.storyNodeIds)}
                </div>
              </div>
            ):(
            <div className={`${style['list-frame']} ${me.lastHoverKey['list'] ? 'hover' : ''}`}
            ref={ele => {
              if (!ele || ele === me.nodeListElement) return;
              me.nodeListElement = ele;
              me.recalculateTriggerArea();
            }}>
              <div className={style['list-header-frame']}>
                {(
                <div className={style['list-header-checkbox']}>
                  <Checkbox
                    indeterminate={me.state.nodeListAllSelectedIndeterminate}
                    checked={me.state.nodeListAllSelected}
                    onChange={e => me.onNodeListSelectAllChanged(e)}
                  >
                    全选
                  </Checkbox>
                  <Tooltip
                    placement={"top"}
                    title={"复制选中节点文本"}
                    overlayClassName={'dark-theme'}
                  >
                    <a
                      className={me.state.nodeListSelectedAmount === 0 ? 'disabled' : null}
                      onClick={me.copySelectedNodesToClipboard}
                    >
                      <Icon name={'snippets'}/>
                    </a>
                  </Tooltip>
                  <Tooltip
                    placement={"top"}
                    title={"复制选中节点ID"}
                    overlayClassName={'dark-theme'}
                  >
                    <a
                      className={me.state.nodeListSelectedAmount === 0 ? 'disabled' : null}
                      onClick={me.copySelectedNodesIDToClipboard}
                    >
                      <Icon name={'copy'}/>
                    </a>
                  </Tooltip>
                  <Tooltip
                    placement={"top"}
                    title={"删除选中节点"}
                    overlayClassName={'dark-theme'}
                  >
                    <a
                      className={(me.state.nodeListSelectedAmount === 0 || me.state.currentFilter === 'story') ? 'disabled' : null}
                      onClick={me.removeSelectedNodes}
                    >
                      <Icon name={'delete'}/>
                    </a>
                  </Tooltip>
                  <Tooltip
                    placement={"top"}
                    title={"名称排序"}
                    overlayClassName={'dark-theme'}
                  >
                    <a
                      className={['alphaAsc', 'alphaDesc'].includes(me.state.sortBy) ? 'current' : null}
                      onClick={() => {
                        if (!['alphaAsc', 'alphaDesc'].includes(me.state.sortBy)) {
                          me.state.sortBy = 'alphaAsc'; // 这里不用setState，调用doStatistics后回自动调用
                        } else if (me.state.sortBy === 'alphaAsc') {
                          me.state.sortBy = 'alphaDesc'; // 这里不用setState，调用doStatistics后回自动调用
                        } else {
                          me.state.sortBy = 'alphaAsc'; // 这里不用setState，调用doStatistics后回自动调用
                        }
                        me.doStatistics(me.dataCache);
                        me.storyNodeSort(me.dataCache);
                      }}
                      style={{float: 'right'}}
                    >
                      <Icon type={IconTypes.ICON_FONT} name={'icon-a_to_z'}/>
                    </a>
                  </Tooltip>
                  {
                    me.state.currentFilter === 'favorite' || me.state.currentFilter === 'important' ? (
                      <Tooltip
                        placement={"top"}
                        title={'自定义排序'}
                        overlayClassName={'dark-theme'}
                      >
                        <a
                          className={me.state.sortBy === 'custom' ? 'current' : null}
                          onClick={() => {
                            if (me.state.sortBy !== 'custom') {
                              me.state.sortBy = 'custom'; // 这里不用setState，调用doStatistics后回自动调用
                              me.doStatistics(me.dataCache);
                              me.storyNodeSort(me.dataCache);
                            }
                          }}
                          style={{float: 'right'}}
                        >
                          <Icon type={IconTypes.ICON_FONT} name={'icon-one-to-three'}/>
                        </a>
                      </Tooltip>
                    ) : null
                  }
                  <Tooltip
                    placement={"top"}
                    title={"时间排序"}
                    overlayClassName={'dark-theme'}
                  >
                    <a
                      className={['latest', 'earliest'].includes(me.state.sortBy) ? 'current' : null}
                      onClick={() => {
                        if (!['latest', 'earliest'].includes(me.state.sortBy)) {
                          me.state.sortBy = 'latest'; // 这里不用setState，调用doStatistics后回自动调用
                        } else if (me.state.sortBy === 'latest') {
                          me.state.sortBy = 'earliest'; // 这里不用setState，调用doStatistics后回自动调用
                        } else {
                          me.state.sortBy = 'latest'; // 这里不用setState，调用doStatistics后回自动调用
                        }
                        me.doStatistics(me.dataCache);
                        me.storyNodeSort(me.dataCache);
                      }}
                      style={{float: 'right'}}
                    >
                      <Icon type={IconTypes.ICON_FONT} name={'icon-latest'}/>
                    </a>
                  </Tooltip>
                  <Tooltip
                    placement={"top"}
                    title={'连接模式'}
                    overlayClassName={'dark-theme'}
                  >
                    <a
                      className={me.state.sortBy === 'connection' ? 'current' : null}
                      onClick={() => {
                        if (me.state.sortBy !== 'connection') {
                          me.state.sortBy = 'connection'; // 这里不用setState，调用doStatistics后回自动调用
                          me.doStatistics(me.dataCache);
                          me.storyNodeSort(me.dataCache);
                        }
                      }}
                      style={{float: 'right'}}
                    >
                      <Icon type={IconTypes.ICON_FONT} name={'icon-relation'}/>
                    </a>
                  </Tooltip>
                  {
                    me.state.currentFilter === 'story' ? (
                      <Tooltip
                        placement={"top"}
                        title={'专题报告'}
                        overlayClassName={'dark-theme'}
                      >
                        <a
                          className={me.state.sortBy === 'story' ? 'current' : null}
                          onClick={() => {
                            if (me.state.sortBy !== 'story') {
                              me.state.sortBy = 'story'; // 这里不用setState，调用doStatistics后回自动调用
                              me.doStatistics(me.dataCache);
                              me.storyNodeSort(me.dataCache);
                            }
                          }}
                          style={{float: 'right'}}
                        >
                          <Icon type={IconTypes.ICON_FONT} name={'icon-story'}/>
                        </a>
                      </Tooltip>
                    ) : null
                  }
                  <Tooltip
                    overlayClassName={style['presentation-content']}
                    placement={"top"}
                    title={me.state.presentationStarted ? (
                      me.state.presentationStayFocus ? (
                        <span style={{display: 'inline-block', padding: '6px 8px'}}>
                                                ↑ / ←：上一个节点<br/>
                                                ↓ / →：下一个节点<br/>
                                                空格/点击按钮：恢复自动播放
                                            </span>
                      ) : (
                        <span style={{display: 'inline-block', padding: '6px 8px'}}>
                                                {`正在漫游${
                                                  me.filterName
                                                    ? `左侧“${me.filterName}”筛选`
                                                    : ('')
                                                }节点列表`}<br/>点击按钮停止，按空格键暂停
                                            </span>
                      )
                    ) : (
                      <div className={`dark-theme`}>
                        <Menu style={{borderRight: 'none'}} selectable={false}>
                          <Menu.ItemGroup title={(
                            <span><span style={{fontSize: '1.2em',color:'#fff'}}>选择播放速度</span></span>
                          )}
                          >
                            {
                              me.presentationDuringArr.map(item => (
                                <Menu.Item
                                  key={`presentation-during-${item.during}`}
                                  onClick={e => {
                                    this.presentationPlay(item.during);
                                  }}
                                >
                                  <Icon name={'check'}
                                        className={style['check-icon']}
                                        style={{
                                          visibility: item.during === me.state.currentDuring && me.state.presentationStarted ? 'visible' : 'hidden',
                                        }}
                                  />
                                  {item.text}
                                </Menu.Item>
                              ))
                            }
                          </Menu.ItemGroup>
                        </Menu>
                      </div>
                    )}
                    overlayClassName={'dark-theme'}
                  >
                    <a
                      className={me.state.sortBy === 'play' ? 'current' : null}
                      onClick={(e) => {
                        me.state.sortBy = 'play';
                        if(me.state.presentationStarted){
                          e.stopPropagation();
                          me.presentationPlayControl();
                        }
                      }}
                      style={{float: 'right'}}
                    >
                      {
                        me.state.presentationStarted ? (
                          me.state.presentationStayFocus ? (
                            <Icon name={'icon-left-right'} type={IconTypes.ICON_FONT}/>
                          ) : (
                            <Icon name={'icon-stop_play'} type={IconTypes.ICON_FONT}/>
                          )
                        ) : (
                          <Icon name={'caret-right'} type={IconTypes.ANT_DESIGN}/>
                        )
                      }
                    </a>
                  </Tooltip>
                </div>)}
              </div>
              <div className={`${style['list-content-frame']} scrollbar ${me.lastHoverKey['list'] ? 'hover' : ''}`}>
              {(
                <ul>
                  {
                    (
                      me.state.nodeListLimit > 0
                        ? Object.keys(me.idMap[me.state.currentFilter]).filter(nodeId => !!me.allNodeMap[nodeId])
                          .slice(0, me.state.nodeListLimit)
                        : Object.keys(me.idMap[me.state.currentFilter]).filter(nodeId => !!me.allNodeMap[nodeId])
                    ).map((nodeId, index) => me.allNodeMap[nodeId] ? (
                      me.allNodeMap[nodeId].description ? (
                        <Tooltip
                          key={`n-${nodeId}`}
                          placement={'right'}
                          onVisibleChange={visible =>
                            visible ? me.onComponentHover('list', `n-${nodeId}`) :
                              me.delayComponentLostHover('list', `n-${nodeId}`)}
                        >
                          <li
                            className={
                              me.state.presentationPlayingNodeId === nodeId || me.state.focusNodeId=== nodeId ? style['active'] :
                              ((me.state.sortBy === 'connection' && me.nodeConnectionsMap[nodeId]) ? (
                                me.nodeConnectionsMap[nodeId].length >
                                me.connectionLimitMap[me.state.currentFilter].top ? 'top' : (
                                  me.nodeConnectionsMap[nodeId].length <
                                  me.connectionLimitMap[me.state.currentFilter].bottom ? 'bottom' : ''
                                )
                              ) : '')
                            }
                            onClick={() => me.doFocusNode(me.allNodeMap[nodeId])}
                          >
                            <Checkbox
                              style={{marginRight: '8px'}}
                              onClick={e => e.stopPropagation()}
                              onChange={e => me.onNodeListSelectionChanged(nodeId, e)}
                              checked={me.state.nodeListSelectionMap[nodeId] === true}
                            />
                            {getNodeDisplayTitle(me.allNodeMap[nodeId])}
                            {
                              (me.state.currentFilter === 'favorite' || me.state.currentFilter === 'important') && me.state.sortBy === 'custom' ? (
                                <div className={style['node-item-sort']}>
                                  <span className={style['sort-icon']}
                                        style={{ cursor: index === 0 ? 'not-allowed' : 'pointer'}}
                                        onClick={ e => {
                                          e.stopPropagation();
                                          me.changeCustomSort(nodeId, index, index - 1);
                                        }}
                                  >
                                    <Icon type={IconTypes.ICON_FONT} name={'icon-move-up'}/>
                                  </span>
                                  <span className={style['sort-icon']}
                                        style={{ cursor: index === Object.keys(me.idMap[me.state.currentFilter]).length - 1 ? 'not-allowed' : 'pointer'}}
                                        onClick={ e => {
                                          e.stopPropagation();
                                          me.changeCustomSort(nodeId, index, index + 1);
                                        }}
                                  >
                                    <Icon type={IconTypes.ICON_FONT} name={'icon-move-down'}/>
                                  </span>
                                </div>
                              ) : null
                            }
                          </li>
                        </Tooltip>
                      ) : (
                        <li
                          key={`n-${nodeId}`}
                          className={
                            me.state.presentationPlayingNodeId === nodeId || me.state.focusNodeId=== nodeId ? style['active'] :
                              ((me.state.sortBy === 'connection' && me.nodeConnectionsMap[nodeId]) ? (
                                me.nodeConnectionsMap[nodeId].length >
                                me.connectionLimitMap[me.state.currentFilter].top ? 'top' : (
                                  me.nodeConnectionsMap[nodeId].length <
                                  me.connectionLimitMap[me.state.currentFilter].bottom ? 'bottom' : ''
                                )
                              ) : '')
                          }
                          onClick={() => me.doFocusNode(me.allNodeMap[nodeId])}
                        >
                          <Checkbox
                            style={{marginRight: '8px'}}
                            onClick={e => e.stopPropagation()}
                            onChange={e => me.onNodeListSelectionChanged(nodeId, e)}
                            checked={me.state.nodeListSelectionMap[nodeId] === true}
                          />
                          {getNodeDisplayTitle(me.allNodeMap[nodeId])}
                          {
                            (me.state.currentFilter === 'favorite' || me.state.currentFilter === 'important') && me.state.sortBy === 'custom' ? (
                              <div className={style['node-item-sort']}>
                                <span className={style['sort-icon']}
                                      style={{ cursor: index === 0 ? 'not-allowed' : 'pointer'}}
                                      onClick={ e => {
                                        e.stopPropagation();
                                        me.changeCustomSort(nodeId, index, index - 1);
                                      }}
                                >
                                  <Icon type={IconTypes.ICON_FONT} name={'icon-move-up'}/>
                                </span>
                                <span className={style['sort-icon']}
                                      style={{ cursor: index === Object.keys(me.idMap[me.state.currentFilter]).length - 1 ? 'not-allowed' : 'pointer'}}
                                      onClick={ e => {
                                        e.stopPropagation();
                                        me.changeCustomSort(nodeId, index, index + 1);
                                      }}
                                >
                                    <Icon type={IconTypes.ICON_FONT} name={'icon-move-down'}/>
                                  </span>
                              </div>
                            ) : null
                          }
                        </li>
                      )
                    ) : null)
                  }
                </ul>)}
                {
                  (me.state.nodeListLimit > 0 && Object.keys(me.idMap[me.state.currentFilter]).length > me.state.nodeListLimit) ? (
                    <React.Fragment>
                      <Divider className={'dashed'}>
                        <a
                          onClick={() => me.setState({
                            nodeListAllSelected: false,
                            nodeListAllSelectedIndeterminate: me.state.nodeListSelectedAmount > 0,
                            nodeListLimit: -1,
                          })}
                        >展示全部节点</a>
                      </Divider>
                    </React.Fragment>
                  ) : undefined
                }
                {
                  (false && me.state.currentFilter === 'selected' && me.currentSelectedNodeId && me.gravityNodeList.length > 0) ? (
                    <React.Fragment>
                      <Divider>您可能还关注</Divider>
                      <ul>
                        {
                          me.gravityNodeList.map(node => (
                            <li
                              key={`n-${node.id}`}
                              onClick={() => me.doFocusNode(me.allNodeMap[node.id])}
                            >
                              <Icon
                                {...getNodeIcon(me.allNodeMap[node.id])}
                                style={{
                                  marginRight: '8px',
                                  fontSize: '16px',
                                  verticalAlign: '-0.2em',
                                }}
                              />
                              {getNodeDisplayTitle(me.allNodeMap[node.id])}
                            </li>
                          ))
                        }
                      </ul>
                    </React.Fragment>
                  ) : null
                }
              </div>
            </div>)
          ) : null
        }
        {
          me.state.showStatisticsPanel === 'word' && Object.keys(me.idMap['all']).length > 0 ? (
            <ViewStatisticsWordPanel
              onClose={() => me.setState({showStatisticsPanel: undefined})}
              bus={me.props.bus}
              nodes={Object.keys(me.idMap['all']).map(id => me.allNodeMap[id])}
              viewDataProvider={me.props.viewDataProvider}
            />
          ) : undefined
        }
        {
          me.state.showStatisticsPanel === 'node_datetime' && Object.keys(me.idMap['all']).length > 0 ? (
            <ViewStatisticsNodeDatetimePanel
              onClose={() => me.setState({showStatisticsPanel: undefined})}
              bus={me.props.bus}
              nodes={Object.keys(me.idMap['all']).map(id => me.allNodeMap[id])}
              initialConditionKey={me.state.nodeStatisticsConditionKey}
            />
          ) : undefined
        }
        {
          me.state.showStatisticsPanel === 'node_general' && Object.keys(me.idMap['all']).length > 0 ? (
            <ViewStatisticsNodeGeneralPanel
              onClose={() => me.setState({showStatisticsPanel: undefined})}
              bus={me.props.bus}
              nodes={Object.keys(me.idMap['all']).map(id => me.allNodeMap[id])}
              initialConditionKey={me.state.nodeStatisticsConditionKey}
              viewId={me.props.viewId}
            />
          ) : undefined
        }
        {
          me.state.showStatisticsPanel === 'edge' && Object.keys(me.idMap['all']).length > 0 ? (
            <ViewStatisticsEdgePanel
              onClose={() => me.setState({showStatisticsPanel: undefined})}
              bus={me.props.bus}
              nodes={Object.keys(me.idMap['all']).map(id => me.allNodeMap[id])}
              edges={me.allEdges}
              initialConditionKey={me.state.nodeStatisticsConditionKey}
            />
          ) : undefined
        }
      </>}
      </div>
    );
  }
}

NodeFilter.defaultProps = {
  bus: PB,
};

NodeFilter.propTypes = {
  bus: PropTypes.instanceOf(SimplePB),
  viewId: PropTypes.string,
};

export default NodeFilter;


const stopAnimation = animations => {
  /*
   This used to just pause any remaining animation
   but anime gets stuck sometimes when an animation
   is trying to tween values approaching 0.

   Basically to avoid that we're just forcing near-finished
   animations to jump to the end.

   This is definitely a hack but it gets the job done—
   if the root cause can be determined it would be good
   to revisit.
   */
  const stop = anim => {
    const { duration, remaining } = anim;
    if (remaining === 1) anim.seek(duration);
    else anim.pause();
  };
  if (Array.isArray(animations)) animations.forEach(anim => stop(anim));
  else stop(animations);
};
