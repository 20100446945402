import React from 'react';
import PropTypes from 'prop-types';
import {Tooltip, Button, Menu, Slider, message, Popover, Modal} from 'antd';

import Network from '@/libs/myVis/MyNetwork3';
import Icon from '@/components/common/common.icon';

import style from '@/style/common/relation/common.relation.visualToolMini.less';
import PB, {SimplePB} from "@/libs/simplePB";
import {IconTypes} from "@/constants/common";
import {getDelayHideFn} from "@/components/common/common.message";
import RelationAutoRefresh from "@/components/common/view/common.view.relation.autoRefresh";
import ViewDataProvider from "@/components/common/dataProvider/common.dataProvider.view";
import ViewRemoteAction from "@/components/common/view/common.view.remote.action";
import intl from 'react-intl-universal';

const edgeDecorator = {
  auto: {
    tip: intl.get('Custom.menu.showNodeEditing'),
    fn: ({edge}) => {
      if (edge && edge.meta && edge.meta.direction) {
        switch (edge.meta.direction) {
          case 'from':
          case 'to':
            return {arrows: edge.meta.direction};
          case 'both':
            return {arrows: 'from, to'};
          default:
            return {arrows: ''};
        }
      } else {
        return {arrows: ''};
      }
    },
    next: 'time',
  },
  time: {
    tip: intl.get('Custom.menu.hideNodeEditing'),
    fn: ({fromNode, toNode}) => {
      let fromTime = fromNode.linkTime || fromNode.updateTime;
      let toTime = toNode.linkTime || toNode.updateTime;
      return {
        arrows: {
          middle: {
            enabled: true,
            type: "image",
            scaleFactor: fromTime > toTime ? -1 : 1,
            imageHeight: 20,
            imageWidth: 20,
            src: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMDI0IDEwMjQiPgogIDxwYXRoIGQ9Ik01MTIgMEw0OCA0NjQgMjkwIDQ2NCAyOTAgMTAyNCA3MzcgMTAyNCA3MzcgNDY0IDk3NiA0NjQgNTEyIDBaTTUxMiAxMjRMNzYyIDM3NCA2NDcgMzc0IDY0NyA5MzQgMzc3IDkzNCAzNzcgMzc0IDI2MiAzNzQgNTEyIDEyNFoiIGZpbGw9IiNFOEU4RTgiLz4KICA8cGF0aCBkPSJNNTEyIDEyNEwyNjIgMzc0IDM3NyAzNzQgMzc3IDkzNCA2NDcgOTM0IDY0NyAzNzQgNzYyIDM3NCA1MTIgMTI0WiIgZmlsbD0iI0VEN0QzMSIgLz4KPC9zdmc+Cg==",
          },
        },
      };
    },
    next: 'auto',
  },
  /*created: {
    tip: '隐藏节点连线箭头',
    fn: ({fromNode, toNode}) => {
      let fromTime = fromNode.linkTime || fromNode.updateTime;
      let toTime = toNode.linkTime || toNode.updateTime;
      return {arrows: {from: fromTime > toTime, to: fromTime <= toTime}};
    },
    next: 'none',
  },
  updated: {
    tip: '当前箭头指向最近更新节点',
    fn: ({fromNode, toNode}) => {
      let fromTime = fromNode.updateTime || fromNode.linkTime;
      let toTime = toNode.updateTime || toNode.linkTime;
      return {arrows: {from: fromTime > toTime, to: fromTime <= toTime}};
    },
    next: 'none',
  },*/
};

class VisualToolMini extends React.Component {
  state = {
    presentationStarted: false,
    presentationStayFocus: false,
    edgeDecorator: 'auto',
    currentDuring: 8000, // 漫游（播放）功能节点专题间隔时间
    multiLevelBrightness: false, // 多级明暗效果方案
    currentType: 'latest',
    moveControlPanel: false, // 显示/隐藏 平移缩放 用来保持选中状态的
  };

  // 漫游（播放）功能节点专题间隔时间初始值
  presentationPlayList = {
    latest: {id: 0, key: 'latest', title: intl.get('Custom.view.newNodes'), text: intl.get('Custom.view.newNodes')},
    filter: {id: 1, key: 'filter', title: intl.get('Custom.view.leftFilter'), text: this.filename ? `${this.filename} ${intl.get('Custom.view.screen')}` : ''},
    custom: {id: 4, key: 'custom', title: intl.get('Custom.view.customList'), text: intl.get('Custom.view.customList')},
  };

  // 漫游（播放）功能节点专题间隔时间初始值
  presentationDuringArr = [
    {during: 12000, text: intl.get('Custom.menu.slowlyPlay')},
    {during: 8000, text: intl.get('Custom.menu.constantPlay')},
    {during: 5000, text: intl.get('Custom.menu.quickPlay')},
  ];

  presentationFilterNodeIds = []; // 播放筛选节点列表
  presentationCustomNodeIds = []; // 播放自定认节点列表
  playPresentationAction = 'do_start';
  filterName = ''; // 漫游播放节点对象名称
  customTitle = ''; // 自定义配置名称

  // 设置播放速度
  setPresentationDuring = during => {
    let me = this;
    me.setState({currentDuring: during}, () => {
      me.props.bus.emit('relation', 'presentation.default_config.update',
        {interval: me.state.currentDuring});
    });
  };

  // 切换播放内容
  setPresentationContent = content => {
    let me = this;
    me.setState({currentType: content.key}, () => {
      me.playPresentationAction = 'do_start';
      switch (me.state.currentType) {
        case 'filter':
          if (me.presentationFilterNodeIds.length <= 0) {
            message.info('请在左侧节点筛选项中选择播放节点列表');
          }
          break;
        case 'custom':
          if (!me.props.viewId) {
            message.info('抱歉，当前关系图暂不支持自定义播放');
          } else if (me.presentationCustomNodeIds.length <= 0) {
            me.props.bus.emit('presentation', 'config.list.show_drawer',
              {viewId: me.props.viewId});
            message.info('已为您打开专题报告列表，请点击专题报告图标开始播放');
          }
          break;
      }
    });
  };

  // 播放控制按钮
  presentationPlayControl = () => {
    const me = this;
    me.props.bus.emit('node', 'presentation.stop');
    switch (me.state.currentType) {
      case 'filter':
        if (me.presentationFilterNodeIds.length > 0) {
          me.props.bus.emit(
            'relation',
            me.state.presentationStarted ? (
              me.state.presentationStayFocus ? 'presentation.playing.do_continue' : 'presentation.do_hold'
            ) : `presentation.${me.playPresentationAction}`,
            me.playPresentationAction === 'do_start' ?
              {config: {nodeIds: me.presentationFilterNodeIds}, noWait: true} : undefined);
        } else {
          message.info('请在左侧节点筛选项中选择播放节点列表');
        }
        break;
      case 'custom':
        if (me.presentationCustomNodeIds.length > 0) {
          me.props.bus.emit(
            'relation',
            me.state.presentationStarted ? (
              me.state.presentationStayFocus ? 'presentation.playing.do_continue' : 'presentation.do_hold'
            ) : `presentation.${me.playPresentationAction}`,
            me.playPresentationAction === 'do_start' ?
              {config: {nodeIds: me.presentationCustomNodeIds}, noWait: true} : undefined);
        } else if (!me.props.viewId) {
          message.info('抱歉，当前关系图暂不支持自定义播放');
        } else {
          me.props.bus.emit('presentation', 'config.list.show_drawer',
            {viewId: me.props.viewId});
          message.info('已为您打开专题报告列表，请点击专题报告图标开始播放');
        }
        break;
      default:
        me.props.bus.emit(
          'relation',
          me.state.presentationStarted ? (
            me.state.presentationStayFocus ? 'presentation.playing.do_continue' : 'presentation.do_hold'
          ) : `presentation.${me.playPresentationAction}`, {noWait: true});
    }
  };

  presentationPlay = during => {
    let me = this;
    me.setState({currentDuring: during,currentType: 'latest'}, () => {
      me.playPresentationAction = 'do_start';
      me.props.bus.emit('relation', 'presentation.default_config.update',
        {interval: me.state.currentDuring});
      me.presentationPlayControl();
    });
  };

  componentDidMount() {
    let me = this;
    me.props.bus.sub(me, 'relation', 'presentation.playing.focus', () => {
      me.setState({presentationStarted: true, presentationStayFocus: true});
    });

    me.props.bus.sub(me, 'relation', 'presentation.playing.continue', () => {
      me.setState({presentationStarted: true, presentationStayFocus: false});
    });

    me.props.bus.sub(me, 'relation', 'presentation.started', () => {
      me.setState({presentationStarted: true, presentationStayFocus: false});
    });

    me.props.bus.sub(me, 'relation', 'presentation.stopped', () => {
      me.playPresentationAction = 'do_resume';
      me.setState({presentationStarted: false, presentationStayFocus: false});
    });

    me.props.bus.sub(me, 'relation', 'presentation.filtered_nodes.set', ({viewId, filterName, nodeIds}) => {
      if (me.props.viewId && me.props.viewId !== viewId) return;
      me.presentationFilterNodeIds = nodeIds;
      if (me.state.currentType === 'filter') {
        me.playPresentationAction = 'do_start';
      }
      me.filterName = filterName;
    });

    me.props.bus.sub(me, 'relation', 'presentation.custom_nodes.set', ({viewId, title, nodeIds}) => {
      if (me.props.viewId && me.props.viewId !== viewId) return;
      me.presentationCustomNodeIds = nodeIds || [];
      me.customTitle = title || '';
      if (me.presentationCustomNodeIds.length > 0) {
        me.setState({currentType: 'custom'});
        me.playPresentationAction = 'do_start';
      }
    });

    me.props.bus.sub(me, 'network', 'decorate_edge.on', ({status}) => {
      if (!status) {
        status = me.state.edgeDecorator;
      }
      me.props.bus.emit('network', 'decorate_edge.do', {
        status,
        fn: edgeDecorator[status].fn,
      });
    });

    me.props.bus.sub(me, 'network', 'decorate_edge.done', ({status}) => {
      if (me.state.edgeDecorator !== status) {
        me.setState({edgeDecorator: status});
      }
    });

    me.props.networkRef.subscribe(me, 'CONFIG.normalBrightnessChanged', () => {
      if (me.props.networkRef.defaultNormalBrightness === 1) {
        me.setState({multiLevelBrightness: false});
      } else {
        me.setState({multiLevelBrightness: me.props.networkRef.defaultNormalBrightness});
      }
    });
  }

  componentWillUnmount() {
    this.props.bus.remove(this);
  }

  render() {
    let me = this;
    const {readonly} = me.props;
    return (<>
      <div className={`${style['frame']} dark-theme`}>
        <div className={style['inner']}>
          {
            me.props.enablePresentation ? (
              <Tooltip
                overlayClassName={style['presentation-content']}
                title={me.state.presentationStarted ? (
                  me.state.presentationStayFocus ? (
                    <span
                      style={{display: 'inline-block', padding: '6px 8px'}}
                    >
                      ↑ / ←：{intl.get('Custom.view.previousNode')}<br/>
                      ↓ / →：{intl.get('Custom.view.nextNode')}<br/>
                      {intl.get('Custom.menu.resumeAutomatic')}
                    </span>
                  ) : (
                    <span
                      style={{display: 'inline-block', padding: '6px 8px'}}
                    >
                      {`${intl.get('Custom.view.rambling')} ${
                        me.state.currentType === 'filter' && me.filterName
                          ? `${intl.get('Custom.view.left')} “${me.filterName}” ${intl.get('Custom.view.screen')} `
                          : (
                            me.state.currentType === 'custom' && me.customTitle
                              ? `${intl.get('Custom.menu.keynoteReport')} “${me.customTitle}”${intl.get('Custom.general.of')} `
                              : `“${me.presentationPlayList[me.state.currentType].text}”`
                          )
                      }节点列表`}<br/>{intl.get('Custom.menu.clickStopPause')}
                    </span>
                  )
                ) : (
                  <div className={`dark-theme`}>
                    <Menu style={{borderRight: 'none'}} selectable={false}>
                      <Menu.ItemGroup title={(
                        <span><span style={{fontSize: '1.2em'}}>{intl.get('Custom.menu.selectPlaySpeed')}</span></span>
                      )}
                      >
                        {
                          me.presentationDuringArr.map(item => (
                            <Menu.Item
                              key={`presentation-during-${item.during}`}
                              onClick={() => {
                                this.presentationPlay(item.during);
                              }}
                            >
                              <Icon name={'check'}
                                    className={style['check-icon']}
                                    style={{
                                      visibility: item.during === me.state.currentDuring && me.state.presentationStarted ? 'visible' : 'hidden',
                                    }}
                              />
                              {item.text}
                            </Menu.Item>
                          ))
                        }
                      </Menu.ItemGroup>
                    </Menu>
                  </div>
                )}
                trigger='hover'
                placement="top"
              >
                <Button
                  className={'ant-btn-icon-only'}
                  onClick={e => {
                    if(me.state.presentationStarted){
                      e.stopPropagation();
                      me.presentationPlayControl();
                    }
                  }}
                >
                  {
                    me.state.presentationStarted ? (
                      me.state.presentationStayFocus ? (
                        <Icon name={'icon-left-right'} type={IconTypes.ICON_FONT}/>
                      ) : (
                        <Icon name={'icon-stop_play'} type={IconTypes.ICON_FONT}/>
                      )
                    ) : (
                      <Icon name={'caret-right'} type={IconTypes.ANT_DESIGN}/>
                    )
                  }
                </Button>
              </Tooltip>
            ) : null
          }
          <Tooltip title={intl.get('Custom.menu.ShowHideAI')} trigger='hover' placement="top">
            <Button
              className={'ant-btn-icon-only'}
              onClick={() => PB.emit('view', 'view.aibox.do',{viewId: me.props.viewId,hide_ai_dialog_box_flag: me.props.hideAiDialogBoxFlag===1?0:1})}
            >
              <Icon name={'icon-robot-smile'} type={IconTypes.ICON_FONT} />
            </Button>
          </Tooltip>
          <Tooltip title={intl.get('Custom.menu.ShowHideMicroservices')} trigger='hover' placement="top">
            <Button
              className={'ant-btn-icon-only'}
              onClick={() => PB.emit('view', 'view.microservicesbox.do',{viewId: me.props.viewId,hide_microservices_box_flag: me.props.hideMicroservicesBoxFlag===1?0:1})}
            >
              <Icon name={'icon-extended-feature'} type={IconTypes.ICON_FONT}/>
            </Button>
          </Tooltip>
          <Tooltip title={readonly ? intl.get('Custom.menu.ShowHideSearch') : intl.get('Custom.menu.ShowHideInput')} trigger='hover' placement="top">
            <Button
              className={'ant-btn-icon-only'}
              onClick={() => {
                PB.emit('view', 'view.inputbox.do',{viewId: me.props.viewId,hide_input_box_flag: me.props.hideInputBoxFlag===1?0:1});
                //me.props.bus.emit('workspace', 'input.change_visible')
              }}
            >
              <Icon name={readonly ? 'icon-search' : 'icon-input'} type={IconTypes.ICON_FONT}/>
            </Button>
          </Tooltip>
          <Tooltip title={intl.get('Custom.menu.ShowHideView')} trigger='hover' placement="top">
            <Button
              className={'ant-btn-icon-only'}
              onClick={() => PB.emit('view', 'view.viewbox.do',{viewId: me.props.viewId,hide_view_box_flag: me.props.hideViewBoxFlag===1?0:1})}
            >
              <Icon name={'picture'} type={IconTypes.ANT_DESIGN}/>
            </Button>
          </Tooltip>
          <Tooltip
            overlayClassName={style['layout-overlay']}
            title={(
              <div className={'dark-theme'}>
                <Menu style={{borderRight: 'none',color:'hsl(0, 0%, 80%)',margin:0,padding:0}} selectable={false}>
                  <Menu.ItemGroup title={(
                    <span><span style={{fontSize: '1.2em'}}>{intl.get('Custom.menu.switchDimMode')}</span><br/><span
                      style={{fontSize: '0.8em'}}>{intl.get('Custom.menu.affectsVisual')}</span></span>
                  )}>
                    <Menu.Item
                      key={'m.brightness.alwaysBright'}
                      onClick={() => {
                        if (me.state.multiLevelBrightness) {
                          me.props.networkRef.defaultNormalBrightness = 1.0;
                        }
                      }}
                    >
                      {intl.get('Custom.menu.keepAllBright')}
                    </Menu.Item>
                    <Menu.Item
                      key={'m.brightness.decreaseByTime'}
                      onClick={() => {
                        if (me.state.multiLevelBrightness !== 'time') {
                          me.props.networkRef.defaultNormalBrightness = 'time';
                        }
                      }}
                    >
                      {intl.get('Custom.menu.autoDimTime')}
                    </Menu.Item>
                    <Menu.Item
                      key={'m.brightness.decreaseByLevel'}
                      onClick={() => {
                        if (me.state.multiLevelBrightness !== 'level') {
                          me.props.networkRef.defaultNormalBrightness = 'level';
                        }
                      }}
                    >
                      {intl.get('Custom.menu.autoDimLevel')}
                    </Menu.Item>
                  </Menu.ItemGroup>
                </Menu>
              </div>
            )}
            trigger='hover'
            placement="top"
          >
            <Button className={'ant-btn-icon-only'}>
              <Icon
                name={me.state.multiLevelBrightness ? 'icon-normal-light' : 'icon-high-light'}
                type={IconTypes.ICON_FONT}
              />
            </Button>
          </Tooltip>
          <Tooltip
            title={(
              <div>
                <div style={{textAlign: 'center', width: '8rem', marginTop:'0.3rem'}}>调节固定节点间距</div>
                <Slider
                  defaultValue={20}
                  min={1}
                  max={40}
                  tooltip={{ formatter: null }}
                  onChange={(v) => {
                    me.props.networkRef ? me.props.networkRef.setSpringLengthFix(v) :
                      me.props.bus.emit('network', 'setSpringLengthFix', v)
                  }}
                />
                <div style={{textAlign: 'center', width: '8rem', marginTop:'0.5rem'}}>调节非固定节点间距</div>
                <Slider
                  defaultValue={100}
                  min={20}
                  max={250}
                  tooltip={{ formatter: null }}
                  onChange={(v) => {
                    me.props.networkRef ? me.props.networkRef.setSpringLength(v) :
                      me.props.bus.emit('network', 'springLength', v)
                  }}
                />
              </div>
            )}
            trigger='hover'
            placement="top"
          >
            <Button className={'ant-btn-icon-only'}><Icon name={'column-width'}/></Button>
          </Tooltip>
          <Tooltip
            overlayClassName={style['layout-overlay']}
            title={(
              <div className={'dark-theme'}>
                <Menu style={{borderRight: 'none'}} selectable={false}>
                  <Menu.ItemGroup title={(
                    <span><span style={{fontSize: '1.2em'}}>{intl.get('Custom.menu.switchNodeLayout')}</span><br/><span
                      style={{fontSize: '0.8em'}}>{intl.get('Custom.menu.affectsVisual')}</span></span>
                  )}>
                    <Menu.Item
                      key={'m.physics.forceAtlas2Based'}
                      onClick={() => {
                        const delayHide = getDelayHideFn('正在重新布局……');
                        setTimeout(() => {
                          me.props.networkRef ? me.props.networkRef.setPhysics('forceAtlas2Based') :
                            me.props.bus.emit('network', 'changePhysics', 'forceAtlas2Based');
                          delayHide(() => message.success('节点布局已完成'));
                        }, 200);
                      }}
                    >
                      <Icon name={'layout'}/>{intl.get('Custom.menu.gravitationalContractionLayout')}
                    </Menu.Item>
                    <Menu.Item
                      key={'m.physics.barnesHut'}
                      onClick={() => {
                        const delayHide = getDelayHideFn('正在重新布局……');
                        setTimeout(() => {
                          me.props.networkRef ? me.props.networkRef.setPhysics('barnesHut') :
                            me.props.bus.emit('network', 'changePhysics', 'barnesHut');
                          delayHide(() => message.success('节点布局已完成'));
                        }, 200);
                      }}
                    >
                      <Icon name={'layout'}/>{intl.get('Custom.menu.expansionExtensionLayout')}
                    </Menu.Item>
                    <Menu.Item
                      key={'m.layout.l2r'}
                      onClick={() => {
                        let fn = () => {
                          const delayHide = getDelayHideFn('正在重新布局……');
                          setTimeout(() => {
                            me.props.networkRef ? me.props.networkRef.setLayout('LR') :
                              me.props.bus.emit('network', 'changeLayout', 'LR');
                            delayHide(() => message.success('节点布局已完成'));
                          }, 200);
                        };
                        if (me.props.networkRef?.graph.nodes.length >= 500) {
                          Modal.confirm({
                            title: '图谱中节点较多，重新布局可能需要较长时间，是否继续？',
                            onOk: fn,
                            okText: '继续布局',
                            cancelText: '取消',
                          });
                        } else {
                          fn();
                        }
                      }}
                    >
                      <Icon name={'layout'} />{intl.get('Custom.menu.layoutRightLeft')}
                    </Menu.Item>
                    <Menu.Item
                      key={'m.layout.r2l'}
                      onClick={() => {
                        let fn = () => {
                          const delayHide = getDelayHideFn('正在重新布局……');
                          setTimeout(() => {
                            me.props.networkRef ? me.props.networkRef.setLayout('RL') :
                              me.props.bus.emit('network', 'changeLayout', 'RL');
                            delayHide(() => message.success('节点布局已完成'));
                          }, 200);
                        }
                        if (me.props.networkRef?.graph.nodes.length >= 500) {
                          Modal.confirm({
                            title: '图谱中节点较多，重新布局可能需要较长时间，是否继续？',
                            onOk: fn,
                            okText: '继续布局',
                            cancelText: '取消',
                          });
                        } else {
                          fn();
                        }
                      }}
                    >
                      <Icon name={'layout'} />{intl.get('Custom.menu.layoutLeftRight')}
                    </Menu.Item>
                    <Menu.Item
                      key={'m.layout.u2d'}
                      onClick={() => {
                        let fn = () => {
                          const delayHide = getDelayHideFn('正在重新布局……');
                          setTimeout(() => {
                            me.props.networkRef ? me.props.networkRef.setLayout('RL') :
                              me.props.bus.emit('network', 'changeLayout', 'UD');
                            delayHide(() => message.success('节点布局已完成'));
                          }, 200);
                        }
                        if (me.props.networkRef?.graph.nodes.length >= 500) {
                          Modal.confirm({
                            title: '图谱中节点较多，重新布局可能需要较长时间，是否继续？',
                            onOk: fn,
                            okText: '继续布局',
                            cancelText: '取消',
                          });
                        } else {
                          fn();
                        }
                      }}
                    >
                      <Icon name={'layout'} />{intl.get('Custom.menu.layoutTopDown')}
                    </Menu.Item>
                    <Menu.Item
                      key={'m.layout.d2u'}
                      onClick={() => {
                        let fn = () => {
                          const delayHide = getDelayHideFn('正在重新布局……');
                          setTimeout(() => {
                            me.props.networkRef ? me.props.networkRef.setLayout('RL') :
                              me.props.bus.emit('network', 'changeLayout', 'DU');
                            delayHide(() => message.success('节点布局已完成'));
                          }, 200);
                        }
                        if (me.props.networkRef?.graph.nodes.length >= 500) {
                          Modal.confirm({
                            title: '图谱中节点较多，重新布局可能需要较长时间，是否继续？',
                            onOk: fn,
                            okText: '继续布局',
                            cancelText: '取消',
                          });
                        } else {
                          fn();
                        }
                      }}
                    >
                      <Icon name={'layout'} />{intl.get('Custom.menu.layoutBottomUp')}
                    </Menu.Item>
                  </Menu.ItemGroup>
                </Menu>
              </div>
            )}
            trigger='hover'
            placement="top"
          >
            <Button className={'ant-btn-icon-only'}><Icon name={'icon-layout'} type={IconTypes.ICON_FONT}/></Button>
          </Tooltip>
          <Tooltip title={intl.get('Custom.menu.showAllNodes')} trigger='hover' placement="top">
            <Button
              className={'ant-btn-icon-only'}
              onClick={() => {
                me.props.networkRef ? me.props.networkRef.network.fit({animation: true}) :
                  me.props.bus.emit('network', 'fit')
              }}
            >
              <Icon name={'fullscreen-exit'}/>
            </Button>
          </Tooltip>
          {/*
          <Tooltip title={'缩小'} trigger='hover' placement="top">
            <Button
              className={'ant-btn-icon-only'}
              onClick={() => {
                me.props.networkRef ? me.props.networkRef.zoom('down') :
                  me.props.bus.emit('network', 'zoomDown')
              }}
            >
              <Icon name={'zoom-out'}/>
            </Button>
          </Tooltip>
          <Tooltip title={'放大'} trigger='hover' placement="top">
            <Button
              className={'ant-btn-icon-only'}
              onClick={() => {
                me.props.networkRef ? me.props.networkRef.zoom('up') :
                  me.props.bus.emit('network', 'zoomUp')
              }}
            >
              <Icon name={'zoom-in'}/>
            </Button>
          </Tooltip>
*/}
          <Tooltip
            // overlayClassName={style['popover-inner'] + ' dark-theme'}
            // title="缩放平移"
            trigger="hover"
            // visible={me.state.moveControlPanel}
            placement="top"
            onVisibleChange={(visible) => {
              console.log('ToolMini onVisibleChange = ', visible);
              me.setState({moveControlPanel: !me.state.moveControlPanel});
            }}
            title={
              <ViewRemoteAction viewProvider={this.props.viewDataProvider} myNetwork={this.props.networkRef} visible={me.state.moveControlPanel}></ViewRemoteAction>
            }
          >
            {/*<Tooltip title={'缩放平移'} trigger='hover' placement="top">*/}
            <Button
              // className={me.state.moveControlPanel ? 'ant-btn-icon-only ' + style['temp-icon-btn-active'] : 'ant-btn-icon-only'}
              className={'ant-btn-icon-only'}
              onClick={() => {
                // me.setState({moveControlPanel: !me.state.moveControlPanel});
              }}
            >
              <Icon type={IconTypes.ICON_FONT} name={'icon-kuozhan'}/>
            </Button>
            {/*</Tooltip>*/}
          </Tooltip>

          <Tooltip title={edgeDecorator[me.state.edgeDecorator].tip} trigger={'hover'} placement={'top'}>
            <Button
              className={'ant-btn-icon-only'}
              onClick={() => {
                me.props.bus.emit('network', 'decorate_edge.do', {
                  status: edgeDecorator[me.state.edgeDecorator].next,
                  fn: edgeDecorator[edgeDecorator[me.state.edgeDecorator].next].fn,
                });
              }}
            >
              <Icon name={'icon-relation-arrow'} type={IconTypes.ICON_FONT}/>
            </Button>
          </Tooltip>
          <Tooltip
            overlayClassName={style['layout-overlay']}
            title={(
              <div className={style['big-arrow-menu']}>
                <Menu style={{borderRight: 'none'}} selectable={false}>
                  <Menu.ItemGroup title={(
                    <span><span style={{fontSize: '1.2em'}}>{intl.get('Custom.menu.arrowMark')}</span></span>
                  )}
                  >
                    <Menu.Item
                      key={'m.bigarrow.barnesHut'}
                      onClick={() => {
                        PB.emit('bigArrow', 'show', 'bigArrow4');
                      }}
                    >
                      {/* <Icon name={'icon-fatarrow'} type={IconTypes.ICON_FONT} style={{ color: '#ED7D31', fontSize: '2.5rem' }} /> */}
                      <img src={`/assets/arrows/bigArrow4.svg`}/>
                    </Menu.Item>
                    {/* <Menu.SubMenu title="层级布局"> */}
                    <Menu.Item
                      key={'m.bigarrow.l2r'}
                      onClick={() => {
                        PB.emit('bigArrow', 'show', 'bigArrow3');
                      }}
                    >
                      <img src={`/assets/arrows/bigArrow3.svg`}/>
                    </Menu.Item>
                    <Menu.Item
                      key={'m.bigarrow.r2l'}
                      onClick={() => {
                        PB.emit('bigArrow', 'show', 'bigArrow2');
                      }}
                    >
                      <img src={`/assets/arrows/bigArrow2.svg`}/>
                    </Menu.Item>
                    <Menu.Item
                      key={'m.bigarrow.u2d'}
                      onClick={() => {
                        PB.emit('bigArrow', 'show', 'bigArrow1');
                      }}
                    >
                      <img src={`/assets/arrows/bigArrow1.svg`}/>
                    </Menu.Item>
                    <Menu.Item
                      key={'m.bigarrow.d2u'}
                      onClick={() => {
                        PB.emit('bigArrow', 'show', 'bigArrow0');
                      }}
                    >
                      <img src={`/assets/arrows/bigArrow0.svg`}/>
                    </Menu.Item>
                  </Menu.ItemGroup>
                </Menu>
              </div>
            )}
            trigger={'hover'}
            placement={'top'}
          >
            <Button className={'ant-btn-icon-only'}>
              <Icon name={'icon-fatarrow'} type={IconTypes.ICON_FONT}/>
            </Button>
          </Tooltip>
          <Tooltip title={intl.get('Custom.menu.displayWordCloud')} trigger={'hover'} placement={'top'}>
            <Button
              className={'ant-btn-icon-only'}
              onClick={e => {
                e.stopPropagation();
                me.props.bus.emit('view', 'wordCloud.show');
              }}
            >
              <img src={`/assets/icons/icons-abc-30.png`} width='20px'/>
            </Button>
          </Tooltip>
          {
            me.props.viewDataProvider ? (
              <RelationAutoRefresh bus={me.props.bus} viewDataProvider={me.props.viewDataProvider}/>
            ) : null
          }
        </div>
      </div>
    </>);
  }
}

VisualToolMini.defaultProps = {
  bus: PB,
  networkRef: undefined,
  readonly: true,
  enablePresentation: false,
  viewId: undefined,
};

VisualToolMini.propTypes = {
  bus: PropTypes.instanceOf(SimplePB),
  networkRef: PropTypes.instanceOf(Network),
  viewDataProvider: PropTypes.instanceOf(ViewDataProvider),
  readonly: PropTypes.bool,
  enablePresentation: PropTypes.bool,
  viewId: PropTypes.string,
};

export default VisualToolMini;
