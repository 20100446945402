import React from "react";
import PropTypes from 'prop-types';
import { IconTypes } from '@/constants/common';
import Icon from '@/components/common/common.icon';

class DigitConvertToIcons extends React.PureComponent {
  refreshInterval = undefined;

  getDisplayObject = () => {
    let me = this,
      targetFigure = me.props.digit.toString(),
      result = [];
    let targetFigureArr = targetFigure.length > 1 ? targetFigure.split('') :  `0${targetFigure}`.split('');

    targetFigureArr.forEach((number, index) => {
      if(me.props.type === 'LED') {
        result.push(<Icon
          key={`digit-${index}`}
          name={`icon-digit-line-${number}`}
          type={IconTypes.ICON_FONT}
          style={{
            width: '0.9rem',
            fontSize: '1.3rem',
            color: targetFigure.length > 1 ? '#75CBFB' : (index === targetFigureArr.length - 1 ? '#75CBFB' : ''),
          }}
        />);
      } else {
        result.push(number);
      }
    });

    return result;
  };

  componentDidMount() {
    this.refreshInterval = setInterval(() => this.forceUpdate(), 60000);
  }

  componentWillUnmount() {
    clearInterval(this.refreshInterval);
  }

  render() {
    let me = this, displayObject = me.getDisplayObject();

    return (
      <span style={{lineHeight: 1}}>
        {displayObject}
      </span>
    );
  }
}

DigitConvertToIcons.defaultProps = {
  digit: 0,
  type: 'LED',
};

DigitConvertToIcons.propTypes = {
  digit: PropTypes.number.isRequired,
  type: PropTypes.string,
};

export default DigitConvertToIcons;