import React from 'react';
import PropTypes from 'prop-types';
import {Form, Input, InputNumber, Modal, Row, Col, Checkbox, Button, DatePicker, message} from 'antd';
import moment from 'moment';

import PB, {SimplePB} from '@/libs/simplePB';

import {getNodeDisplayTitle, NODE_TYPE_TEXT} from '@/constants/vis.defaultDefine.1';

import ViewDataProvider from '@/components/common/dataProvider/common.dataProvider.view';
import ViewManager from '@/components/common/common.viewManager';

class TeamworkTicketDetailModal extends React.Component {
  state = {
    code: '',
    ticketInformation: '',
    extraInformation: {},
    // extraInformationText: '{}',
    expireAtTimestamp: undefined,

    codeOptionNum: true,
    codeOptionUC: false,
    codeOptionLC: false,
    codeOptionLength: 6,
    autoCreateRelatedNode: true,

    readOnly: false,

    savingStatus: 'idle',
  };

  onClose = () => {
    let me = this;

    me.props.onClose();
  };

  onGenerateCode = () => {
    let me = this, text = '', result = [];

    if (me.state.codeOptionNum) {
      text += '0123456789';
    }
    if (me.state.codeOptionUC) {
      text += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    }
    if (me.state.codeOptionLC) {
      text += 'abcdefghijklmnopqrstuvwxyz';
    }
    for (let i = 0; i < me.state.codeOptionLength; i++) {
      result.push(text.charAt(Math.floor(Math.random() * text.length)));
    }
    me.setState({code: result.join('')});
  };

  onSave = () => {
    let me = this, config = {
      code: me.state.code,
      ticketInformationType: 0,
      ticketInformation: me.state.ticketInformation,
      extraInformation: {},
    }, onError = () => {
      me.setState({savingStatus: 'failed'});
      message.error('操作失败');
    }, doAddTicket = () => {
      ViewManager.addViewTeamworkTicket(me.props.viewDataProvider.viewId, config).then(() => {
        me.setState({savingStatus: 'success'}, () => {
          message.success('操作成功');
          me.props.bus.emit('teamwork', 'ticket.list.changed', {viewId: me.props.viewDataProvider.viewId});
          me.props.onClose();
        });
      }).catch(() => {
        if (me.state.autoCreateRelatedNode) {
          // 删除节点
          // noinspection JSIgnoredPromiseFromCall
          me.props.viewDataProvider.removeNode(config.extraInformation.afterUse[0].nodeId);
        }
        onError();
      })
    };

    if (me.state.expireAtTimestamp) {
      config.expireAtTimestamp = me.state.expireAtTimestamp;
    }

    me.setState({savingStatus: 'processing'}, () => {
      if (me.state.autoCreateRelatedNode) {
        me.props.viewDataProvider.addTextNodes([{
          fname: me.state.code,
          label: me.state.code,
          type: NODE_TYPE_TEXT,
          owner: 0,
          forceAdd: true,
        }]).then(({nodes}) => {
          if (nodes && nodes.length > 0) {
            config.extraInformation.afterUse = [{
              action: 'transfer_owner',
              nodeId: nodes[0].id,
            }];
            doAddTicket();
          } else {
            onError();
          }
        }).catch(() => onError());
      } else {
        doAddTicket();
      }
    });
  };

  initState = () => {
    let me = this;

    if (me.props.config) {
      me.setState({
        code: me.props.config.code,
        ticketInformation: me.props.config.ticketInformation,
        extraInformation: me.props.config.extraInformation,
        // extraInformationText: JSON.stringify(me.props.config.extraInformation),
        expireAtTimestamp: me.props.config.expireAtTimestamp ? me.props.expireAtTimestamp : undefined,

        codeOptionNum: true,
        codeOptionUC: false,
        codeOptionLC: false,
        codeOptionLength: 6,
        expireAtMoment: me.props.config.expireAtTimestamp ? moment(me.props.expireAtTimestamp) : undefined,
        autoCreateRelatedNode: false,

        readOnly: true,

        savingStatus: 'idle',
      });
    } else {
      me.setState({
        code: '',
        ticketInformation: '',
        extraInformation: {},
        // extraInformationText: '{}',
        expireAtTimestamp: undefined,

        codeOptionNum: true,
        codeOptionUC: false,
        codeOptionLC: false,
        codeOptionLength: 6,
        expireAtMoment: undefined,
        autoCreateRelatedNode: true,

        readOnly: false,

        savingStatus: 'idle',
      });
    }
  };

  componentDidMount() {
    let me = this;

    if (me.props.visible) {
      me.initState();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let me = this;

    if (!prevProps.visible && me.props.visible) {
      me.initState();
    }
  }

  render() {
    let me = this;

    return (
      <Modal
        title={'门票编辑'}
        visible={me.props.visible}
        clasable={me.state.savingStatus !== 'processing'}
        centered={true}
        onCancel={me.onClose}
        cancelText={'取消'}
        onOk={me.onSave}
        okText={'保存'}
        okButtonProps={{disabled: !me.state.code}}
        width={'70rem'}
      >
        <Form>
          <Form.Item label={'唯一码（15字符内）'}>
            <Row>
              <Col span={6}>
                <Input readOnly={me.state.readOnly} maxLength={15} onChange={e => me.setState({code: e.target.value})} value={me.state.code} autoFocus={true} />
              </Col>
              {
                me.state.readOnly ? null : (
                  <Col span={17} offset={1}>
                    <Checkbox onChange={e => me.setState({codeOptionNum: e.target.checked})} checked={me.state.codeOptionNum}>数字</Checkbox>
                    <Checkbox onChange={e => me.setState({codeOptionUC: e.target.checked})} checked={me.state.codeOptionUC}>大写字母</Checkbox>
                    <Checkbox onChange={e => me.setState({codeOptionLC: e.target.checked})} checked={me.state.codeOptionLC}>小写字母</Checkbox>
                    长度：
                    <InputNumber max={15} min={1} precision={0} value={me.state.codeOptionLength} onChange={v => me.setState({codeOptionLength: v})} />
                    <Button onClick={me.onGenerateCode}>生成</Button>
                  </Col>
                )
              }
            </Row>
          </Form.Item>
          <Form.Item label={'验证展示信息（100字内）'}>
            <Input.TextArea
              readOnly={me.state.readOnly}
              maxLength={100}
              rows={3}
              value={me.state.ticketInformation}
              onChange={e => me.setState({ticketInformation: e.target.value})}
            />
          </Form.Item>
          <Form.Item label={'过期时间'}>
            <DatePicker
              readOnly={me.state.readOnly}
              value={me.state.expireAtMoment}
              onChange={(m) =>
                me.setState({expireAtMoment: m, expireAtTimestamp: m ? m.valueOf() : undefined})}
              showTime={true}
              format={'YYYY-MM-DD HH:mm:ss'}
            />
          </Form.Item>
          <Form.Item label={'相连节点'}>
            {
              me.props.config ? (
                me.state.extraInformation && me.state.extraInformation['afterUse'] && me.state.extraInformation['afterUse'][0] ? (
                  me.props.viewDataProvider.getNode(me.state.extraInformation['afterUse'][0].nodeId) ? (
                    <a
                      onClick={() => me.props.bus.emit('network', 'node.on_edit', {
                        node: me.props.viewDataProvider.getNode(me.state.extraInformation['afterUse'][0].nodeId),
                        id: me.state.extraInformation['afterUse'][0].nodeId,
                      })}
                    >
                      {getNodeDisplayTitle(me.props.viewDataProvider.getNode(me.state.extraInformation['afterUse'][0].nodeId))}
                    </a>
                  ) : (
                    <React.Fragment>
                      节点不存在
                    </React.Fragment>
                  )
                ) : (
                  <Checkbox onChange={e => me.setState({autoCreateRelatedNode: e.target.checked})} checked={me.state.autoCreateRelatedNode}>自动创建节点</Checkbox>
                )
              ) : (
                <Checkbox onChange={e => me.setState({autoCreateRelatedNode: e.target.checked})} checked={me.state.autoCreateRelatedNode}>自动创建节点</Checkbox>
              )
            }
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

TeamworkTicketDetailModal.defaultProps = {
  bus: PB,
};

TeamworkTicketDetailModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  viewDataProvider: PropTypes.instanceOf(ViewDataProvider).isRequired,
  config: PropTypes.object,
  bus: PropTypes.instanceOf(SimplePB),
};

export default TeamworkTicketDetailModal;