// import PinYin from 'pinyin/lib/index';

const objectString = Object.prototype.toString.call({});

export function getPinYin(o, field = 'label') {
/*
  if (o && Object.prototype.toString.call(o) === objectString) {
    if (!o.$pinYin) {
      o.$pinYin = PinYin(String(o[field]));
      console.log(o[field] + ': ', o.$pinYin);
    }
    return o.$pinYin;
  } else {
    return PinYin(String(o));
  }
*/
}

export function compare(o1, o2, field = 'label') {
/*
  let v1 = getPinYin(o1, field), v2 = getPinYin(o2, field);
  return String(v1).localeCompare(v2);
*/
}
