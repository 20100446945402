import React from 'react';
import PropTypes from 'prop-types';
import {Button, Tooltip, Badge} from 'antd';

import PB, {SimplePB} from '@/libs/simplePB';

import {getNodeIconByType, NODE_TYPE_COMPANY} from '@/constants/vis.defaultDefine.1';

import Icon from '@/components/common/common.icon';
import ViewDataProvider from '@/components/common/dataProvider/common.dataProvider.view';
import ExploreMenuCompany from '@/components/common/view/explore/common.view.explore.menu.company';

import style from '@/style/common/view/common.view.explore.less';

class ExploreButtonCompany extends React.PureComponent {
  state = {
    overallStatus: 'idle',
    recommendDetailStatus: 'idle',
  };

  componentDidMount() {
    let me = this;

    me.props.bus.with(me).subscribe('view', 'explore.explore_company.overall.do_load_more', ({viewId}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId) {
        me.setState({overallStatus: 'loading'});
      }
    }).subscribe('view', 'explore.explore_company.overall.data_loaded', ({start, limit, total, viewId}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId) {
        me.setState({
          overallStatus: (start + limit < total) ? 'stopped' : 'finished',
        });
      }
    }).subscribe('view', 'explore.explore_company.overall.failed_to_load', ({viewId}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId) {
        me.setState({
          overallStatus: 'failed',
        });
      }
    }).subscribe('view', 'explore.explore_company.overall.modal_visible_changed', ({viewId, visible}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId) {
        if (visible && (me.state.overallStatus === 'stopped' || me.state.overallStatus === 'finished' || me.state.overallStatus === 'failed')) {
          me.setState({
            overallStatus: 'idle',
          });
        }
      }
    }).subscribe('view', 'explore.explore_company.recommend_detail.do_load_more', ({viewId}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId) {
        me.setState({recommendDetailStatus: 'loading'});
      }
    }).subscribe('view', 'explore.explore_company.recommend_detail.data_loaded', ({hasMoreResult, viewId}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId) {
        me.setState({
          recommendDetailStatus: hasMoreResult ? 'stopped' : 'finished',
        });
      }
    }).subscribe('view', 'explore.explore_company.recommend_detail.failed_to_load', ({viewId}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId) {
        me.setState({
          recommendDetailStatus: 'failed',
        });
      }
    }).subscribe('view', 'explore.explore_company.recommend_detail.modal_visible_changed', ({viewId, visible}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId) {
        if (visible && (me.state.recommendDetailStatus === 'stopped' || me.state.recommendDetailStatus === 'finished' || me.state.recommendDetailStatus === 'failed')) {
          me.setState({
            recommendDetailStatus: 'idle',
          });
        }
      }
    });
  }

  componentWillUnmount() {
    this.props.bus.remove(this);
  }

  render() {
    let me = this, statusIdx = 0;

    // statusIdx: 0-idle, 1-success, 2-processing, 3-warning
    ['overallStatus', 'recommendDetailStatus'].forEach(k => {
      switch(me.state[k]) {
        case 'failed':
          statusIdx = 3;
          return;
        case 'loading':
          statusIdx = Math.max(statusIdx, 2);
          return;
        case 'stopped':
        case 'finished':
          statusIdx = Math.max(statusIdx, 1);
          return;
      }
    });

    return (
      <Tooltip
        overlayClassName={style['layout-overlay']}
        title={(<ExploreMenuCompany viewDataProvider={me.props.viewDataProvider} bus={me.props.bus} />)}
        trigger='hover'
        placement="left"
      >
        <Button
          shape={'circle'}
        >
          <Badge status={[undefined, 'success', 'processing', 'warning'][statusIdx]}>
            <Icon {...getNodeIconByType(NODE_TYPE_COMPANY)} />
          </Badge>
        </Button>
      </Tooltip>
    )
  }
}

ExploreButtonCompany.defaultProps = {
  bus: PB,
};

ExploreButtonCompany.propTypes = {
  bus: PropTypes.instanceOf(SimplePB),
  viewDataProvider: PropTypes.instanceOf(ViewDataProvider).isRequired,
};

export default ExploreButtonCompany;