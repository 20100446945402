import util from '@/libs/vis/lib/util';
import ManipulationSystem from '@/libs/vis/lib/network/modules/ManipulationSystem';

export default class MyManipulationSystem extends ManipulationSystem {
  /**
   * If something changes in the data during editing, switch back to the initial datamanipulation state and close all edit modes.
   * @private
   */
  _restore() {
    if (this.inMode !== false) {
      if (this.options.initiallyActive === true) {
        this.enableEditMode();
      }
      else {
        let lastMode = this.inMode;
        this.disableEditMode();
        switch (lastMode) {
          case 'addEdge':
            this.addEdgeMode();
            break;
        }
      }
    }
  }

  _setup() {
    if (this.options.enabled === true) {
      // Enable the GUI
      this.guiEnabled = false;

      // noinspection JSUnresolvedFunction
      this._createWrappers();
      if (this.editMode === false) {
        this._createEditButton();
      }
      else {
        // noinspection JSUnresolvedFunction
        this.showManipulatorToolbar();
      }
    }
    else {
      // noinspection JSUnresolvedFunction
      this._removeManipulationDOM();

      // disable the gui
      this.guiEnabled = false;
    }
  }

  _handleConnect(event) {
    // check to avoid double fireing of this function.
    if (new Date().valueOf() - this.touchTime > 100) {
      this.lastTouch = this.body.functions.getPointer(event['center']);
      this.lastTouch.translation = util.extend({},this.body.view.translation); // copy the object

      let pointer = this.lastTouch;
      let node = this.selectionHandler.getNodeAt(pointer);

      if (node !== undefined) {
        if (node.isCluster === true) {
          alert(this.options.locales[this.options.locale]['createEdgeError'] || this.options.locales['en']['createEdgeError'])
        }
        else {
          // create a node the temporary line can look at
          let targetNode = this._getNewTargetNode(node.x,node.y);
          this.body.nodes[targetNode.id] = targetNode;
          this.body.nodeIndices.push(targetNode.id);

          // create a temporary edge
          let connectionEdge = this.body.functions.createEdge({
            id: 'connectionEdge' + util.randomUUID(),
            from: node.id,
            to: targetNode.id,
            physics: false,
            dashes: true,
            smooth: {
              enabled: true,
              forceDirection: 'horizontal',
              type: 'curvedCW',
              roundness: 0.15,
            },
          });
          this.body.edges[connectionEdge.id] = connectionEdge;
          this.body.edgeIndices.push(connectionEdge.id);

          this.temporaryIds.nodes.push(targetNode.id);
          this.temporaryIds.edges.push(connectionEdge.id);
        }
      }
      this.touchTime = new Date().valueOf();
    }
  }

  _performAddEdge(sourceNodeId, targetNodeId) {
    let defaultData = {from: sourceNodeId, to: targetNodeId};
    if (typeof this.options.addEdge === 'function') {
      if (this.options.addEdge.length === 2) {
        this.options.addEdge(defaultData, (finalizedData, senderId) => {
          if (this.inMode === 'addEdge') { // if for whatever reason the mode has changes (due to dataset change) disregard the callback
            if (finalizedData !== null && finalizedData !== undefined) {
              this.body.data.edges.getDataSet().add(finalizedData, senderId);
            }
            this.selectionHandler.unselectAll();
            this.addEdgeMode();
          }
        });
      }
      else {
        throw new Error('The function for connect does not support two arguments (data,callback)');
      }
    }
    else {
      this.body.data.edges.getDataSet().add(defaultData);
      this.selectionHandler.unselectAll();
      this.addEdgeMode();
    }
  }
}