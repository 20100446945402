/*
 * @Author: Carr.s
 * @Date: 2022-04-08 11:28:40
 * @LastEditTime: 2022-05-17 08:32:28
 * @LastEditors: Carr.s
 * @Description: 洞察 政企关系
 * @Copyright by joinmap
 */
import React from "react";
import { notification } from "antd";
import ViewInsightChartTpl from "@/components/insightView/template/insight.chart.tpl";
import { connect } from "react-redux";
import styles from "@/style/components/insightView/insight.less";
// 各省的地图数据
import {
  ChinaADCDisplayTitleMap,
  ChinaADCStatisticsType,
  getChinaADCByTitle,
  getChinaADCCoordinate,
  simplifiedChinaProvinceADCMap,
  unknownChinaADC,
} from "@/components/common/common.functions";

class ViewGovEntInsight extends ViewInsightChartTpl {
  // ---begin custom-----------------------------------------
  colorMaxIdx = 10;
  // ---end of custom-----------------------------------------

  //===============================================================
  // begin of overwrite
  // 使用默认的方式就不用重写

  // ------------------------------------------------------------
  // 全局配置
  // ------------------------------------------------------------
  nodeTitleInTip = "事件";
  // 显示底部按钮
  showToolbar = false;
  // 洞察名称
  viewerTitle = "政企交流";

  // ------------------------------------------------------------
  // 层级分析对象的配置
  // ------------------------------------------------------------
  // 第一层 level1 tag
  // column1_DataField = "data"; // 取值字段
  // column1_TotalField = "dataTotal"; // 计算总数(与返回数量不一定一致)字段
  column1_Title = "企业"; // 显示名称
  column1_Unit = "家"; // 显示的文字单位
  /*   column1_ValueRender = (level1_data,idx,nodePath=undefined) => {
    // 右侧数值渲染，不输出则空白
    // 必须加上 className={styles["line-value"]}
    return (
      <div className={styles["line-value"]}>
        {level1_data["value"] + level1_data["valueUnit"]}
      </div>
    );
  };
 */ column1_IconRender = (level1_data, idx, nodePath = undefined) => {
    // 左侧图表渲染，不输出则空白
    // 必须加上 className={styles["line-icon"]}
    let color = "#4b565c";
    if (idx < this.colorMaxIdx - 1 && idx < this._chartColors.length) {
      color = this._chartColors[idx];
    }
    return (
      <div className={styles["line-icon"]}>
        <div
          className={styles["dot-icon"]}
          style={{ backgroundColor: color }}
        ></div>
      </div>
    );
  };
  /*   column1_HoverTitle = (level1_data,idx,nodePath=undefined) => {
    // 列表鼠标悬浮提示文字
    return `${this.column1_Title}：${level1_data["name"]}\n${
      this.column2_Title
    }：${level1_data[this.column2_DataField]}${
      level1_data["valueUnit"]
    }\n点击打开${this.column2_Title}列表`;
  };
 */
  // 第二层 item
  column2_DataField = "news"; // 取值字段
  column2_TotalField = "newsTotal"; // 计算总数(与返回数量不一定一致)字段
  column2_ValueRender = (level2_data, idx) => {
    // 右侧数值渲染，不输出则空白
    // 必须加上 className={styles["line-value"]}
  };
  /*   column2_IconRender = (level2_data,idx) => {
    // 左侧图表渲染，不输出则空白
    // 必须加上 className={styles["line-icon"]}
  };
 */
  /*   column2_HoverTitle = (level2_data,idx) => {
    // 列表鼠标悬浮提示文字
    return `${this.column2_Title}：${level2_data["name"]}\n点击打开${this.column2_Title}链接`;
  };
 */

  // ------------------------------------------------------------
  // 接口的配置
  // ------------------------------------------------------------
  // 接口 url path
  api_path = "/wth_server_api/shangji_mode_by_view_id";

  // 返回数据默认值
  defaultLimit = 100;

  /**
   * 【继承组件要重写】
   * api 请求完成后，setState结束后的 callback
   */
  apiLoadedCallback = () => {
    if (this.state._column1Len > 0 && this.chartType === "echarts") {
      notification.open({
        duration: 8,
        message: "操作提示",
        description: (
          <div>
            <div>洞察数据加载成功</div>
            <div>
              图表支持鼠标<b>缩放</b>、<b>平移</b>操作。
            </div>
          </div>
        ),
      });
    }
  };

  // ------------------------------------------------------------
  // 图表的配置
  // ------------------------------------------------------------
  // 图表显示库
  chartType = "echarts";
  chartStyle = {
    top: 0,
    bottom: 0,
  };

  chartCompomentStyle = {
    width: "100%",
    height: "100%",
  };

  /**
   * 生成公用的、默认的 option
   * @returns
   */
  initChartOption = () => {
    let centerCoordinate = getChinaADCCoordinate("000000");
    let _chartOption = {
      colors: this._chartColors,
      // backgroundColor: 'rgb(41,50,61)',
      // backgroundColor: '#030E24',
      animation: true,
      animationDuration: 1000,
      animationEasing: "cubicInOut",
      animationDurationUpdate: 800,
      animationEasingUpdate: "cubicInOut",
      title: [
        {
          show: false,
          text: "",
          left: "45%",
          top: "12%",
          textStyle: {
            color: "#fff",
            fontSize: 30,
          },
        },
      ],
      // legend: [],
      grid: [
        {
          right: "4.5%",
          bottom: "5%",
          // height: '27.6%', // 动态计算
          width: 180,
        },
        {
          right: "4.5%",
          bottom: "5%",
          // height: 300, // 动态计算
          width: 180,
        },
      ],
      xAxis: [],
      yAxis: [],
      visualMap: {
        show: false,
        min: 0,
        max: 18,
        right: "1%",
        bottom: "4%",
        text: ["高", "低"], // 文本，默认为数值文本
        textStyle: {
          color: "#ccc",
        },
        itemWidth: 7,
        itemHeight: 100,
        calculable: false,
        seriesIndex: [
          // this.state.chartsSeries.indexOf("pin_scatter"),
          // this.state.chartsSeries.indexOf("effect_scatter"),
        ], // 指定对第二个序列视觉映射
        inRange: {
          // color: ["#37A2DA", colors[colorIndex][n]] // 蓝绿
          // 中间有绿色
          // color: ['lightgrey', 'lightgreen', 'green', 'yellow', 'gold', 'orangered']
          // 中间无绿色
          // color: ['lightgrey', 'grey', 'yellow', 'gold', 'orangered'],
          // color: ['lightgrey', 'yellow', 'gold', 'orangered'],
          // --------
          // color: ['#d94e5d', '#eac736', '#50a3ba'].reverse(),
          // color: ['#1260A8', '#4394C2', '#66A9D7', '#96C6DD', '#18639E'].reverse(),
          // color:[ '#d48265', '#91c7ae', '#c4ccd3'].reverse(),
          // color: ['#c23531', '#61a0a8', '#2f4554'].reverse(),
          // color: ['#c23531', '#67d08b', '#eec41b'].reverse(),
          // color: ['#2f83e4', '#23cbff', '#00e5c1'].reverse(),
          // color: ['#3c72a6', '#2ca681', '#f6e628'],
          // color: ['#3c72a6', '#2ca681', '#edb73e'],
          // MIT
          // color: ['#ff3534', '#f18989', '#eea9a9', '#e6e6e6', '#a9a9ee', '#8989f1', '#3435ff'],
          // color: ['#eea9a9', '#e6e6e6', '#a9a9ee', '#8989f1', '#3435ff'],
          // xiaya
          // color: ['#fe8284', '#4ccbce', '#999900'],
          // color: ['#33cc66', '#4ccbce', '#999900'],
          // color: ['#e59517', '#0664ed', '#058eb5'], // x
          color: ["#d2c057", "#f28b54", "#f28b54"], // x
          // color: ['#04a3ac', '#846ac8', '#2b62d7'], //
          // color: ['#01fd77', '#1ceef9', '#2c96ff'], //
        },
      },
      tooltip: {
        enterable: true,
        trigger: "item",
        padding: 0,
        hideDelay: 200,
        transitionDuration: 0.4,
        textStyle: {
          color: "#fff",
          decoration: "none",
          height: 20,
          lineHeight: 16,
        },
        // position: function (point, params, dom, rect, size) {
        //   return [point[0], point[1]];
        // },
        formatter: function (params) {
          // console.log('params=', params);
          if (params.seriesName == "pin_scatter") {
            // params['data']['china_adc']
            // me.getPointPos(params['seriesIndex'], params['value']);
            // me.state.focusedDetailListADC = params['data']['china_adc'];
            // me.state.focusedDetailListADCName = params['name'];
            return null;
          } else if (
            ["barAll", "barL1", "barL2", "barL3"].indexOf(
              params["seriesName"]
            ) > -1
          ) {
            return params["name"] + ":" + params["value"];
          }
          return null;
        },
      },
      geo: [
        {
          map: "china",
          roam: true,
          center: [centerCoordinate.x, centerCoordinate.y],
          selected: ["江苏"],
          zoom: 1.2,
          label: {
            position: "bottom",
            distance: 5,
            normal: {
              show: false,
            },
            emphasis: {
              show: false,
            },
          },
          // 地图区域的多边形 图形样式。
          itemStyle: {
            normal: {
              areaColor: "rgba(0,0,0,0)",
              // borderColor: "rgba(161,161,161,1)",
              borderColor: "rgba(255,255,255,.6)",
            },
            emphasis: {
              // areaColor: '#1890ff',
              areaColor: "rgba(0,0,0,0)",
              // borderColor: "rgb(178,178,178)",
              borderColor: "rgba(255,255,255,1)",
            },
          },
        },
      ],
      series: [],
    };

    // 最后加入地图用于点击聚焦省份事件
    // _chartOption.series.push({
    //   geoIndex: 0,
    //   name: "全国",
    //   type: "map",
    //   map: "china",
    //   // roam: true,
    //   data: [],
    // });

    // console.log('_chartOption=', _chartOption);
    return _chartOption;
  };

  /**
   * 【继承组件要重写】
   * 生成 chart 的 options
   * @param {int} idx 选中的数据下标
   */
  refreshChartOptions = (idx) => {
    // 旗子的前缀
    let flagPrefix = "https://vip.joinmap.ai";
    let me = this;
    // const { column2_DataField } = this.state;
    this._chartOption = this.initChartOption();
    // 地图打点
    me._chartData.forEach((i, idx0) => {
      let cityTempData = [];
      i.city.forEach((i2) => {
        let cityName = i2.split(" ")[0];
        let cityPos = getChinaADCCoordinate(getChinaADCByTitle(cityName));
        if (cityPos) {
          cityTempData.push({
            name: cityName,
            value: [cityPos.x, cityPos.y, 10],
          });
        } else {
          console.log("ViewGovEntInsight 没有找到城市坐标 cityName:", cityName);
        }
      });

      //气泡数字
      me._chartOption.series.push({
        name: i.name,
        type: idx == idx0 ? "scatter" : "scatter",
        symbol:
          idx == idx0
            ? `image://${flagPrefix}/assets/icons/flag_1.png`
            : "circle",
        coordinateSystem: "geo",
        geoIndex: 0,
        data: cityTempData,
        symbolSize: function (val) {
          // return val;
          // 最小值=50 最大值=200
          return idx == idx0 ? 50 : idx0 > me.colorMaxIdx - 1 ? 12 : 20;
        },
        showEffectOn: "render",
        hoverAnimation: true,
        label: {
          normal: {
            show: idx >= 0 ? idx == idx0 : false,
            formatter: function (info) {
              return "企业：" + info.seriesName + "\n城市：" + info.name;
            },
            // position: ["-60%", "100%"],
            position: "bottom",
            align: "center",
            // color: '#031525',
            color: "#d1d1d1",
            fontSize: 16,
            // fontWeight: "bold",
          },
        },
        itemStyle: {
          normal: {
            // borderColor: 'rgba(255,255,255,0.5)',
            // borderColor: 'rgba(255,255,255,1)',
            // borderColor: '#fff',
            // borderWidth: 0.5,
            // color: '#F62157',
            // color: '#808080',
            // color: '#2bc8d5',
            // shadowBlur: 2,
            // shadowColor: '#fff',
            // shadowColor: '#1890ff',
            opacity: idx == idx0 ? 1 : 1,
          },
          emphasis: {
            // color: '#F62157',
            // color: "#d2c057",
            // shadowBlur: 5,
            // shadowColor: '#f28b54',
            // shadowColor: '#1890ff',
            opacity: 1,
          },
        },
        zlevel: idx == idx0 ? 99 : idx0 > me.colorMaxIdx - 1 ? 0 : idx0,
      });
    });

    // 鼠标悬浮提示
    me._chartOption["tooltip"] = {
      show: true,
      formatter: function (info) {
        return [
          "<div style='width:120px'>企业：" + info.seriesName + "</div>",
          "<div>城市：" + info.name + "</div>",
        ].join("");
      },
    };
  };

  /**
   * 【继承组件要重写】
   * echart 有时需要绑定点击事件什么
   * @param {object} e ReactECharts 返回的 echarts 实例
   */
  eChartRefCallback = (e) => {
    // me._chartIns = e.getEchartsInstance();
    // let me = this;
  };

  // end of overwrite
  //===============================================================
}

export default connect(
  (state) => ({
    userInfo: state.account.userInfo,
  }),
  (dispatch) => ({})
)(ViewGovEntInsight);
