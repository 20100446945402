import React from 'react';
import PropTypes from 'prop-types';
import PB, { SimplePB } from '@/libs/simplePB';
import { Modal, Form, Radio, message } from 'antd';

import style from '@/style/common/view/common.view.config.joinTypeModal.less';
import ViewManager, { overrideNextMessage } from '@/components/common/common.viewManager';
import Icon from '@/components/common/common.icon';
import { IconTypes } from '@/constants/common';

class JoinTypeModal extends React.Component {
	state = {
		joinType: -1,
		sharingType: -1,
		displayType: -1,
		initDisplayType: -1,
		processing: true,
		saving: false,
	};

	componentDidUpdate(prevProps, prevState, snapshot) {
		let me = this;

		if (!prevProps.visible && me.props.visible) {
			me.loadViewTeamworkSettings();
		}
	}

	loadViewTeamworkSettings = () => {
		let me = this;
		overrideNextMessage('static::loadViewTeamworkSettings', false);
		ViewManager.loadViewTeamworkSettings(me.props.viewId).then(({type, meta}) => {
			// console.log(' 访问设置 loadViewTeamworkSettings ->  type:', type, ' -> meta :', meta);
			let joinType = type,
				sharingType = meta && meta['public'] > 0 ? 1 : (joinType === 0 ? 0 : 3),
				displayType = sharingType === 1 ? 2 : joinType;
			let canCopy = !!(me.props.viewInfo && me.props.viewInfo.up === 1 && !(me.props.viewInfo.price > 0));
			// console.log(' 访问设置 -> displayType :', displayType);
			// console.log(' 访问设置  -> canCopy :', canCopy);
			if (canCopy && displayType === 2) {
				me.setState({joinType, sharingType, displayType: 4, processing: false, initDisplayType: 4});
			} else {
				me.setState({joinType, sharingType, displayType, processing: false, initDisplayType: displayType});
			}

		}).catch(error => {
			console.log('获取图谱协作配置失败', error);
			Modal.error({
				title: '加载失败，请稍后重试。',
				okText: '确定',
			});
		});
	};

	saveSettings = () => {
		let me = this;

		// 如果初始选项和最终选项相同时，直接关闭弹框
		if (me.state.displayType === me.state.initDisplayType) {
			me.props.onClose();
			return;
		}

		if (me.state.displayType === 4) {
			// 开放复制  先设置成开放阅读，然后上架销售
			ViewManager.setViewTeamworkSettingsPartially(me.props.viewId, 0, 2).then(() => {
				ViewManager.setCopyView(me.props.viewInfo, result => {
					if (result === null || (result && result.success)) {
						if (result && result.success) {
							PB.emit('view', 'view.info.update', {viewId: result.viewInfo.viewId, viewInfo: result.viewInfo});
						}
						me.props.callback && me.props.callback({action: 'sale', viewId: result.viewInfo.viewId, success: true, viewInfo: result.viewInfo});
						me.setState({saving: false}, () => me.props.onClose());
					} else {
						console.log('游客权限设置保存失败');
						message.success('保存失败，请稍后再试。');
						me.setState({saving: false}, () => me.props.onClose());
					}
				});
			}).catch(error => {
				me.setState({saving: false}, () => me.props.onClose());
			});
		} else {
			let type = me.state.displayType === 2 ? 0 : me.state.displayType,
				autoSetSharingType = me.state.displayType === 2 ? 2 : 1;
			ViewManager.setNotCopyView(me.props.viewInfo, result => {
				if (result === null || (result && result.success)) {
					me.setState({saving: true}, () => {
						overrideNextMessage('static::setViewTeamworkSettingsPartially', false);
						ViewManager.setViewTeamworkSettingsPartially(me.props.viewId, type, autoSetSharingType).then(() => {
						message.success('保存成功');
							if (result && result.success) {
								PB.emit('view', 'view.info.update', {viewId: result.viewInfo.viewId, viewInfo: result.viewInfo});
								me.props.callback && me.props.callback({action: 'cancel_sale', success: true, viewId: result.viewInfo.viewId, viewInfo: result.viewInfo});
							} else if (me.state.displayType === 0 ) {
								me.props.callback && me.props.callback({action: '', success: true });
							}

						me.setState({saving: false}, () => me.props.onClose());
						}).catch(error => {
						console.log('游客权限设置保存失败');
						message.success('保存失败，请稍后再试。');
							me.setState({saving: false}, () => me.props.onClose());
						});
					});
				} else {
				console.log('游客权限设置保存失败');
				message.success('保存失败，请稍后再试。');
					me.setState({saving: false}, () => me.props.onClose());
				}
			});

		}
	};

	componentWillUnmount() {
		PB.remove(this);
	}

	render() {
		let me = this;

		return (
			<Modal
				title={<div><Icon name="edit" style={{color: '#a5a5a5', marginRight: 10}}/>访问设置</div>}
				centered
				visible={me.props.visible}
				width={'480px'}
				onOk={me.saveSettings}
				onCancel={me.props.onClose}
				destroyOnClose={true}
				className={style['join-type-modal']}
				okText={'保存'}
				cancelText={me.props.readonly ? '关闭' : '取消'}
				okButtonProps={{disabled: me.state.processing, style: {display: me.props.readonly ? 'none' : 'unset'}}}
			>
				{
					me.props.readonly ? null : (
						<Form.Item style={{marginBottom: 0, padding: '0 28px'}}>
							<div className={style['radio-header']}>
								用户访问图谱的权限：
								<div className={style['tips-text']}>
									<Icon name={'icon-triangle-mark'} type={IconTypes.ICON_FONT} /> 图谱组长可以删改成员创建的节点
									<br/>
									<Icon name={'icon-user'} type={IconTypes.ICON_FONT} /> 图谱成员只能删改自己创建的节点
								</div>
							</div>
						</Form.Item>
					)
				}
				<Form.Item className={style['radio-form']}  style={{padding: '0 0 0 38px'}}>
					<Radio.Group
						value={me.state.displayType}
						onChange={e => {
							me.props.readonly || me.setState({displayType: e.target.value});
						}}
						disabled={me.state.processing}
					>
						<Radio value={0}>
							<span className={style['radio-label']} style={{ paddingLeft: '8px'}}>组长审核（默认）</span>
							<br/>
							<span className={style['tips-text']} style={{ display: 'inline-block', paddingLeft: '32px', whiteSpace: 'normal'}}>
								必须由组长输入用户协作ID或登录邮箱才能使之成为成员
							</span>
						</Radio>
						<Radio value={3}>
							<span className={style['radio-label']} style={{ paddingLeft: '8px'}}>邀请码模式</span>
							<br/>
							<span className={style['tips-text']} style={{ display: 'inline-block', paddingLeft: '32px', whiteSpace: 'normal'}}>
								打开图谱时输入4位邀请码即可成为成员
								<br/>
								<span>（节点菜单中可预先批量生成邀请码节点）</span>
							</span>
							<br/>

						</Radio>
						<Radio value={1}>
							<span className={style['radio-label']} style={{ paddingLeft: '8px'}}>开放协作</span>
							<br/>
							<span className={style['tips-text']} style={{ display: 'inline-block', paddingLeft: '32px', whiteSpace: 'normal'}}>
								任何用户无需审核，打开图谱即可成为成员
							</span>
						</Radio>
						<Radio value={2}>
							<span className={style['radio-label']} style={{ paddingLeft: '8px'}}>开放阅读</span>
							<br/>
							<span className={style['tips-text']} style={{ display: 'inline-block', paddingLeft: '32px', whiteSpace: 'normal'}}>
								任何用户均可阅读图谱内容，无须成为成员
							</span>
						</Radio>
						<Radio value={4}>
							<span className={style['radio-label']} style={{ paddingLeft: '8px'}}>开放复制</span>
							<br/>
							<span className={style['tips-text']} style={{ display: 'inline-block', paddingLeft: '32px', whiteSpace: 'normal'}}>
								任何用户均可阅读图谱内容，复制图谱，无须成为成员
							</span>
						</Radio>
					</Radio.Group>
				</Form.Item>
			</Modal>
		);
	}
}

JoinTypeModal.defaultProps = {
	visible: false,
	callback: () => {},
};

JoinTypeModal.propTypes = {
	visible: PropTypes.bool.isRequired,
	bus: PropTypes.instanceOf(SimplePB),
	readonly: PropTypes.bool.isRequired,
	viewId: PropTypes.string.isRequired,
	onClose: PropTypes.func.isRequired,
	viewInfo: PropTypes.object.isRequired,
	callback: PropTypes.func,
};

export default JoinTypeModal;