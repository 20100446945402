import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Button,
  Form,
  Input,
  message,
  Modal,
  Slider,
  Tooltip,
  Upload,
  Tag,
  Radio,
  Badge,
  Popover,
  Row,
  Col,
} from 'antd';
import AvatarEditor from "react-avatar-editor";
import UserAvatar from "react-user-avatar";
import {SketchPicker} from 'react-color';
import _ from 'lodash';

import PB, {SimplePB} from "@/libs/simplePB";

import {getBase64Url} from "@/utils/Common";

import defaultTextIconImg from '@/constants/icon/default_text_node_icon.png';
import {IconTypes} from "@/constants/common";
import { getNodeDisplayTitle, defaultDefine, NODE_TYPE_TEXT} from '@/constants/vis.defaultDefine.1';
import {
  ICON_CATEGORY_TEXT,
  ICON_CATEGORY_COMPANY,
  ICON_CATEGORY_TALENT,
  ICON_CATEGORY_PATENT,
  ICON_CATEGORY_PAPER,
  ICON_CATEGORY_POLICY,
  ICON_CATEGORY_ORG,
  ICON_CATEGORY_INSTITUTE,
  ICON_CATEGORY_NEWS_ACTIVITIES,
  ICON_CATEGORY_DATASET,
  ICON_CATEGORY_GOV,
  ICON_CATEGORY_NATURE,
  ICON_CATEGORY_COLLEGE_AND_UNIVERSITY,
  ICON_CATEGORY_PARK,
  ICON_CATEGORY_PROJECT,
  ICON_CATEGORY_PREPARE,
  ICON_CATEGORY_DOING,
  ICON_CATEGORY_FINISH,
  ICON_CATEGORY_FLAG_A,
  ICON_CATEGORY_FLAG_B,
  ICON_CATEGORY_FLAG_C,
  ICON_CATEGORY_FLAG_E,
  ICON_CATEGORY_FLAG_F,
  ICON_CATEGORY_FLAG_G,
  ICON_CATEGORY_TEXT_A,
  ICON_CATEGORY_TEXT_B,
  ICON_CATEGORY_TEXT_C,
  ICON_CATEGORY_TIP_A,
  ICON_CATEGORY_TIP_B,
  ICON_CATEGORY_TIP_C,
  ICON_CATEGORY_TIP_D,
  ICON_CATEGORY_TIP_E,
  ICON_CATEGORY_TIP_F,
  ICON_CATEGORY_TIP_G,
  ICON_CATEGORY_TIP_H,
  ICON_CATEGORY_TAG_A,
  ICON_CATEGORY_TAG_B,
  ICON_CATEGORY_TAG_C,
  ICON_CATEGORY_TAG_D,
  ICON_CATEGORY_TAG_E,
  ICON_CATEGORY_TAG_F,
  ICON_CATEGORY_TAG_G,
  ICON_CATEGORY_TAG_H,
  ICON_CATEGORY_NO_ICON,
  ICON_CATEGORY_GOOD,
  ICON_CATEGORY_BAD,
  ICON_CATEGORY_WATCH,
  getNodeIconType,
} from "@/constants/iconConfig";

import Icon from '@/components/common/common.icon';
import {
  checkUploadImage,
  getChinaADCByTitle,
  unknownChinaADC,
  stringToMoment,
} from '@/components/common/common.functions';
import { iconCfgToIconMartCfg } from '@/components/common/node/common.node.iconMart';
import {reloadCustomIconFromCache} from '@/components/common/node/common.node.customIcon';

import style from '@/style/components/mainView/node.less';

const iconSize = 180;

const scaleText = ['微(0.5x)', '小(1x)', '中(2x)', '大(4x)'];

const presetColors = ['rgba(0, 0, 0, 0)', defaultDefine.colors.level1, '#D0021B', '#F5A623', '#F8E71C', '#8B572A',
  '#7ED321', '#417505', '#BD10E0', '#9013FE', '#4A90E2', '#50E3C2', '#B8E986', '#000000', '#FFFFFF']
  .map(color => ({color, title: ''}));

// const maxImageLength = 360;

const nodeIcons = [
  ICON_CATEGORY_TEXT,
  ICON_CATEGORY_GOV,
  ICON_CATEGORY_ORG,
  ICON_CATEGORY_PARK,
  ICON_CATEGORY_COMPANY,
  ICON_CATEGORY_COLLEGE_AND_UNIVERSITY,
  ICON_CATEGORY_INSTITUTE,
  ICON_CATEGORY_TALENT,
  ICON_CATEGORY_PATENT,
  ICON_CATEGORY_PAPER,
  ICON_CATEGORY_DATASET,
  ICON_CATEGORY_NEWS_ACTIVITIES,
  ICON_CATEGORY_NATURE,
  ICON_CATEGORY_POLICY,
  ICON_CATEGORY_PROJECT,
  ICON_CATEGORY_PREPARE,
  ICON_CATEGORY_DOING,
  ICON_CATEGORY_FINISH,
  ICON_CATEGORY_FLAG_A,
  ICON_CATEGORY_FLAG_B,
  ICON_CATEGORY_FLAG_C,
  ICON_CATEGORY_FLAG_E,
  ICON_CATEGORY_FLAG_F,
  ICON_CATEGORY_FLAG_G,
  ICON_CATEGORY_TEXT_A,
  ICON_CATEGORY_TEXT_B,
  ICON_CATEGORY_TEXT_C,
  ICON_CATEGORY_TIP_C,
  ICON_CATEGORY_TIP_D,
  ICON_CATEGORY_TIP_E,
  ICON_CATEGORY_TIP_F,
  ICON_CATEGORY_TIP_G,
  {
    key: ICON_CATEGORY_TIP_H,
    color: defaultDefine.colors.level0, // 颜色调整
  },
  ICON_CATEGORY_TIP_A,
  {
    key: ICON_CATEGORY_TIP_B,
    color: '#bbb', // 颜色调整
  },
  ICON_CATEGORY_NO_ICON,
  ICON_CATEGORY_TAG_C,
  ICON_CATEGORY_TAG_D,
  ICON_CATEGORY_TAG_E,
  ICON_CATEGORY_TAG_F,
  ICON_CATEGORY_TAG_G,
  {
    key: ICON_CATEGORY_TAG_H,
    color: defaultDefine.colors.level0, // 颜色调整
  },
  ICON_CATEGORY_TAG_A,
  {
    key: ICON_CATEGORY_TAG_B,
    color: '#bbb', // 颜色调整
  },
  undefined,
  ICON_CATEGORY_GOOD,
  ICON_CATEGORY_BAD,
  ICON_CATEGORY_WATCH
].map(cfg => cfg ? iconCfgToIconMartCfg(cfg) : cfg).concat('custom');

class TextEditModal extends React.Component {
  state = {
    name: '',
    description: '',
    url: '',
    tags: [],
    currentTag: '',
    lev: undefined,
    userPreferredType: undefined,
    scale: 1,
    iconData: undefined,
	  iconKey: 'gradeD',
    iconChanged: false,
    newCustomIcon: false,

    aiPreferredType: undefined,
    showIconEditor: false,
    uploading: false,
    uploadedIconImgBase64Url: undefined,
    iconScale: 1,
    iconRotate: 0,
    iconShape: 'circle',
    iconOriginalHeight: 0,
    iconOriginalWidth: 0,
    iconRectHeight: 0,
    iconRectWidth: 0,
	  iconBackground: {r: 0, g: 0, b: 0, a: 0},
    iconSize,
    iconPosition: {x: 0.5, y: 0.5},

    showTagInputTooltip: false,

    shortcutTimeTags: [
      {
        text: '今天',
        value: moment().format('YYYY-MM-DD'),
      },
      {
        text: '昨天',
        value: moment().subtract(1, "days").format("YYYY-MM-DD"),
      },
      {
        text: '前天',
        value: moment().subtract(2, "days").format("YYYY-MM-DD"),
      },
    ], // 快捷时间标签
    shortcutLocationTags:['北京', '上海', '深圳', '广州', '南京'], // 快捷地理位置标签
    historyTags: [], // 历史记录标签
	  openFull: localStorage.getItem('show_full_create_node_modal') === '1',
  };

  addTagInputRef = undefined;

  iconEditorRef = undefined;

  descriptionRef = React.createRef();

  cachedIconInfo = [];

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (this.props.visible === false && nextProps.visible === true) {
      const me = this;

      this.setState((state, props) => {
        requestAnimationFrame(() => {
          const {form} = me.props;
          form.resetFields();
        });

        let iconMeta = {
          shape: 'circle',
          rectHeight: 0,
          rectWidth: 0,
        };
        if (props.node.meta && props.node.meta['iconMeta']) {
          iconMeta = {...iconMeta, ...props.node.meta['iconMeta']};
        }
        let preferredType = props.node.userPreferredType;
        if (preferredType === null || preferredType === undefined) {
	        preferredType = props.node.aiPreferredType;
        }
				let iconData = props.node.meta ? (props.node.meta.iconData || undefined) : undefined;

	      return {
		      name: props.node.fname,
		      url: props.node.url || '',
		      description: props.node.description || '',
		      tags: props.node.tags || [],
		      lev: props.node.lev || 'gradeD',
		      userPreferredType: isNaN(props.node.userPreferredType) ? undefined : props.node.userPreferredType,
		      scale: props.node.meta ? (props.node.meta.scale || 1) : 1,
		      iconData: iconData,
		      iconKey: getNodeIconType(props.node),

		      aiPreferredType: props.node.aiPreferredType || undefined,
		      showIconEditor: !!iconData,
		      uploading: false,
		      uploadedIconImgBase64Url: iconData || undefined,
		      iconScale: 1,
		      iconRotate: 0,
		      iconShape: iconMeta.shape,
		      iconOriginalHeight: iconMeta.rectHeight || iconSize,
		      iconOriginalWidth: iconMeta.rectWidth || iconSize,
		      iconRectHeight: iconMeta.rectHeight || iconSize,
		      iconRectWidth: iconMeta.rectWidth || iconSize,
		      iconBackground: iconMeta.bg || {r: 0, g: 0, b: 0, a: 0},
		      iconSize,
		      iconPosition: {x: 0.5, y: 0.5},

		      currentTag: '',
		      openFull: props.editType === 'editNode' || localStorage.getItem('show_full_create_node_modal') === '1',
	      };

      });
    }
    return true;
  }

  onInsertText = (text, isBlock = true) => {
    let me = this;

    let input = ReactDOM.findDOMNode(me.descriptionRef);
    let description = me.props.form.getFieldValue('description');
    let startPos = 0, endPos = 0;
    if (input && input.selectionStart >= 0) {
      startPos = input.selectionStart;
      endPos = input.selectionEnd;
      const startStr = input.value.substring(0, startPos);
      if (isBlock) {
        if (startStr && startStr[startStr.length - 1] !== '\n') {
          text = '\n' + text;
        }
        if (endPos < input.value.length && input.value[endPos] !== '\n') {
          text = text + '\n';
        }
      }
      description = startStr + text + input.value.substring(endPos, input.value.length);
      input.selectionStart = startPos + text.length;
      input.selectionEnd = startPos + text.length;
    } else {
      if (description && description[description.length - 1] !== '\n') {
        description += '\n';
      }
      description += text;
    }
    me.props.form.setFieldsValue({description}, () => {
      input = ReactDOM.findDOMNode(me.descriptionRef);
      if (input && input.selectionStart >= 0) {
        input.selectionStart = startPos + text.length;
        input.selectionEnd = startPos + text.length;
      }
    });
  }

  onSave = () => {
    let me = this;
    const {form} = me.props;
    form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
        // 用户标签信息中的名称、描述和链接可编辑
        const {name, description, url, tags} = values;
	      /*const customIconNotAllowed =
		      ((me.state.userPreferredType || me.state.aiPreferredType || NODE_TYPE_TEXT) === NODE_TYPE_TEXT)
		      && me.state.lev && !['gradeD', 'gradeT', 'gradeU', 'gradeType0'].includes(me.state.lev);*/
	      const customIconNotAllowed = me.state.iconKey !== 'custom';
        const newCustomIconInfo = (!customIconNotAllowed && me.state.iconChanged && me.state.newCustomIcon) ? {
          lev: 'gradeD',
          iconKey: 'custom',
          uploadedIconImgBase64Url: me.state.uploadedIconImgBase64Url,
          iconScale: me.state.iconScale,
          iconRotate: me.state.iconRotate,
          iconShape: me.state.iconShape,
          iconOriginalHeight: me.state.iconOriginalHeight,
          iconOriginalWidth: me.state.iconOriginalWidth,
          iconRectHeight: me.state.iconRectHeight,
          iconRectWidth: me.state.iconRectWidth,
          iconBackground: me.state.iconBackground,
          iconSize: me.state.iconSize,
          iconPosition: {...me.state.iconPosition},
        } : undefined;
	      const canvas = ((!customIconNotAllowed) && me.iconEditorRef && me.state.uploadedIconImgBase64Url) ?
		      me.iconEditorRef.getImageScaledToCanvas() : undefined;
	      /**
	       * @type {undefined|HTMLCanvasElement}
	       */
	      let dstCanvas = undefined;
	      if (canvas) {
		      // noinspection JSValidateTypes
		      dstCanvas = document.createElement('canvas');
		      dstCanvas.width = canvas.width;
		      dstCanvas.height = canvas.height;
		      const ctx = dstCanvas.getContext('2d');
		      ctx.fillStyle = `rgba(${Object.values(me.state.iconBackground).join(', ')})`;
		      ctx.fillRect(0, 0, dstCanvas.width, dstCanvas.height);
		      ctx.drawImage(canvas, 0, 0);
	      }
	      let iconMeta = {
		      shape: me.state.iconShape,
		      rectHeight: me.state.iconRectHeight,
		      rectWidth: me.state.iconRectWidth,
          bg: me.state.iconBackground,
	      };

        if (me.props.editType === 'addNode') {
	        const node = {
		        fname: name,
		        label: name,
		        type: NODE_TYPE_TEXT,
		        lev: me.state.lev || 'gradeD',
		        tags: tags.length > 0 ? tags : undefined,
		        tag: tags.length > 0 ? tags.join(' ') : undefined,
		        description: description ? description : undefined,
		        url: url ? url : undefined,
		        owner: 0,
		        scale: me.state.scale,
		        userConfirmed: true,
		        userPreferredType: me.state.userPreferredType || undefined,
		        meta: {
			        description: description ? description : undefined,
			        url: url ? url : undefined,
			        scale: me.state.scale,
			        iconData: customIconNotAllowed ? undefined : (dstCanvas ? dstCanvas.toDataURL(
				        me.state.iconBackground.a === 1 ? 'image/jpeg' : 'image/png') : me.state.iconData),
			        iconMeta: customIconNotAllowed ? undefined : iconMeta,
			        userPreferredType: me.state.userPreferredType || undefined,
		        },
	        };
	        if (newCustomIconInfo) newCustomIconInfo.iconData = node.meta.iconData;
	        me.props.bus.emit('network', 'node.do_add', {node, newCustomIconInfo});

        } else if (me.props.editType === 'editNode'){

	        const node = {
		        ...me.props.node,
            aiGraphRank: undefined,
		        fname: name,
		        label: name,
		        tags: tags.length > 0 ? tags : undefined,
		        tag: tags.length > 0 ? tags.join(' ') : undefined,
		        description: (description || description === undefined) ? description : '',
		        url: (url || url === undefined) ? url : '',
		        lev: me.state.lev || undefined,
		        userPreferredType: me.state.userPreferredType,
		        meta: {
			        ...(me.props.node.meta || {}),
			        description: (description || description === undefined) ? description : '',
			        url: (url || url === undefined) ? url : '',
			        scale: me.state.scale,
			        iconData: customIconNotAllowed ? undefined : (dstCanvas ? dstCanvas.toDataURL(
				        me.state.iconBackground.a === 1 ? 'image/jpeg' : 'image/png') : me.state.iconData),
			        iconMeta: customIconNotAllowed ? undefined : iconMeta,
			        userPreferredType: me.state.userPreferredType,
		        },
	        };
	        if (!customIconNotAllowed) {
		        if (!me.state.iconData && me.props.node.meta && me.props.node.meta.iconData) {
			        if (node.lev && (!node.lev.startsWith('gradeType'))) {
				        // 清空图片后重置节点层级样式
				        node.lev = 'gradeD';
			        }
		        } else if (dstCanvas) {
			        if (!node.lev || !node.lev.startsWith('gradeType')) {
				        // 节点图片发生变化
				        node.lev = 'gradeD';
			        }
		        }
	        }
          if (newCustomIconInfo) newCustomIconInfo.iconData = node.meta.iconData;
	        me.props.bus.emit('network', 'node.do_edit', {node, newCustomIconInfo});
        }
      }
    });
  };

  onTagRemove = (i) => {
    const {form} = this.props;
    // can use data-binding to get
    let tags = Array.from(form.getFieldValue('tags'));
    tags.splice(i, 1);

    // can use data-binding to set
    form.setFieldsValue({
      tags: tags,
    });
    this.setState({tags: tags});
  };

  onTagAdd = (tag) => {
    const {form} = this.props;
    let tags = Array.from(form.getFieldValue('tags'));
    if (!tag) {
      message.error('请输入标签内容');
      return;
    }
    let newTags = tag.includes(' ') ? tag.split(' ') : [tag];
    let historyTags = localStorage.getItem('historyTags') || '';
    if (newTags.filter(tag => !!tag).find(tag => {
      if (tags.includes(tag)) {
        message.error('标签 ' + tag + ' 已存在');
        return true;
      }
      tags = tags.concat(tag);

      // 将标签缓存到本地
      if (historyTags) {
        historyTags = historyTags.includes(tag) ? historyTags : `${tag}|${historyTags}`;
      } else{
        historyTags = `${tag}`;
      }

      localStorage.setItem('historyTags', historyTags);
      return false;
    })) {
      return;
    }
    // can use data-binding to set
    form.setFieldsValue({
      tags: tags,
    });
    this.setState({
      tags: tags,
      currentTag: '',
      historyTags: historyTags.split('|'),
    });
  };

  tagIconType = (tag) => {
    let result = <Icon name='tag'/>;
    if (stringToMoment(tag)) {
      result = <Icon name={'calendar'}/>;
    } else if (getChinaADCByTitle(tag) !== unknownChinaADC && getChinaADCByTitle(tag) !== '000000') {
      result = <Icon name={'environment'}/>
    }

    return result;
  };

  /* 选择内置图标 */
  onIconClicked = (key, value, extra) => {
	  this.setState({
		  iconKey: key,
      iconChanged: true,
      newCustomIcon: false,
		  lev: value,
		  userPreferredType: extra ? extra.userPreferredType : undefined,
	  });
  };

  beforeCustomIconUpload = file => {
    let me = this;

    if (checkUploadImage(file, message)) {
      getBase64Url(file, imgBase64Url => {
        const URL = window.URL || window.webkitURL;
        // noinspection JSCheckFunctionSignatures
        let img = new Image();
        img.src = URL.createObjectURL(file);
        img.onload = () => {
          const ratio = img.naturalWidth / img.naturalHeight;
          let iconRectHeight = img.naturalHeight;
          let iconRectWidth = img.naturalWidth;
          if (ratio > 1) {
            // 以宽度为准
            if (iconRectWidth > iconSize) {
              iconRectHeight = iconSize / ratio;
              iconRectWidth = iconSize;
            }
          } else {
            // 以高度为准
            if (iconRectHeight > iconSize) {
              iconRectWidth = iconSize * ratio;
              iconRectHeight = iconSize;
            }
          }
          let iconPosition = {x: 0.5, y: 0.5};
          if (ratio >= 0.5 && ratio <= 0.85) {
            iconPosition.y *= (0.85 - (0.85 - ratio) * 4 / 7);
          }
          me.setState({
            uploadedIconImgBase64Url: imgBase64Url,
            uploading: false,
            showIconEditor: true,
            iconScale: 1,
            iconRotate: 0,
            iconShape: 'circle',
            iconPosition,
            iconOriginalHeight: img.naturalHeight,
            iconOriginalWidth: img.naturalWidth,
            iconRectHeight,
            iconRectWidth,
            iconSize,
            iconKey: 'custom',
            iconChanged: true,
            newCustomIcon: true,
            lev: 'gradeD',
            userPreferredType: NODE_TYPE_TEXT,
          });
        };
      });
    } else {
      me.setState({uploading: false});
    }
    return false;
  }

  onPastingImage = e => {
    let me = this;
    if (!me.props.visible) return;
    if (e.clipboardData && e.clipboardData.items) {
      // 从剪贴板中获取items
      let items = e.clipboardData.items;
      // 循环items获取剪贴板中的图片
      if (items.length === 1 && items[0].type.indexOf('image') !== -1) {
        // 获取图片的文件信息
        let blob = items[0].getAsFile();
        // 配合输入框文字，"可粘贴截屏"好像不太顺，改为截图
        let fileName = '截图' + moment().format("YYYY-MM-DD HH:mm:ss");
        let file = new window.File([blob], fileName + '.png', {type: blob.type});
        me.beforeCustomIconUpload(file);
        e.preventDefault();
      }
    }
  };

  reloadCustomIconCache = () => {
    let me = this;

    reloadCustomIconFromCache().then(list => {
      me.cachedIconInfo = list;
      me.forceUpdate();
    });
  };

  componentDidMount() {
    let me = this;
    // 聚焦添加描述文本框时，使用粘贴功能，提取剪贴板中图片作为附件
    window.addEventListener('paste', me.onPastingImage);

    me.reloadCustomIconCache();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let me = this;

    if (me.props.visible && !prevProps.visible) {
      window.addEventListener('paste', me.onPastingImage);
      me.reloadCustomIconCache();
    } else if (!me.props.visible && prevProps.visible) {
      window.removeEventListener('paste', me.onPastingImage);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('paste', this.onPastingImage);
  }

  render() {
    let me = this, ratio;
    const {visible, doClose, form} = me.props;
    const {getFieldDecorator} = form;

    getFieldDecorator('tags', {initialValue: this.props.node.tags || []});
    // let textNodeIconCfg = getTextNodeIconCfgByNode(me.state);

    if (me.state.showIconEditor) ratio = Math.min(2, me.state.iconOriginalHeight / me.state.iconRectHeight);
    // noinspection RequiredAttributes
    return (
      <Modal
	      className={style['node-edit-modal']}
        width={me.state.openFull ? 1066 : 553}
        closable={!me.props.processing}
        visible={visible}
        title={<div>
          <Icon name="edit" style={{color: '#a5a5a5', marginRight: 10}}/>
	        {
	        	me.props.editType === 'editNode' ? (
	        		<span>编辑节点详情（{getNodeDisplayTitle(me.props.node, 18)}）</span>
		        ) : (
			        me.props.editType === 'addNode' ? (
				        me.props.withNode ? `添加相连节点（连接至：${getNodeDisplayTitle(me.props.withNode, 14)}）` : '添加新关注点'
			        ) : null
		        )
	        }

        </div>}
        okText={'确定'}
        cancelText={'取消'}
        onCancel={doClose}
        centered={true}
	      maskClosable={false}
        onOk={me.onSave}
        okButtonProps={{loading: me.props.processing}}
        cancelButtonProps={{disabled: me.props.processing}}
	      bodyStyle={{padding: '20px 40px 6px'}}
      >
        <Form id={"nodeEditForm"} layout={'vertical'}>
          <Row>
            <Col span={me.state.openFull ? 12 : 24}  style={{paddingRight: me.state.openFull ? '20px' : '0'}}>
              <Form.Item
                label={<span>名称<span className={style['label-required']}>*</span></span>}
	              style={{marginBottom: '12px'}}
              >
                {getFieldDecorator('name', {
                  initialValue: me.props.node.fname,
                  rules: [{
                    required: true, message: '请输入节点名称，不能为空',
                  }],
                })(
                  <Input placeholder="请输入节点名称" autoFocus={true}/>
                )}
              </Form.Item>
              <Form.Item
                label={(
                  <div>
                    描述
                    <span style={{float: 'right'}}>
                      排版视觉增强：
                      <a
                        onClick={() => {
                          me.onInsertText('♦\n♦\n♦');
                        }}
                        style={{color: '#f5222d'}}
                      >
                        <Icon name={'icon-diamond-border'} type={IconTypes.ICON_FONT} style={{verticalAlign: '-0.15em'}} />
                      </a>
                      &nbsp;
                      <a
                        onClick={() => {
                          me.onInsertText('❤️⚠️✅❇️✳️❎✔️❌⭕️♦☀', false);
                        }}
                        style={{color: '#52c41a'}}
                      >
                        <Icon name={'icon-check-border'} type={IconTypes.ICON_FONT} style={{verticalAlign: '-0.15em'}} />
                      </a>
                      &nbsp;
                      <a
                        onClick={() => {
                          me.onInsertText('0️⃣\n1️⃣\n2️⃣\n3️⃣\n4️⃣\n5️⃣\n6️⃣\n7️⃣\n8️⃣\n9️⃣');
                        }}
                        style={{color: '#1890ff'}}
                      >
                        <Icon name={'icon-nine-border'} type={IconTypes.ICON_FONT} style={{verticalAlign: '-0.15em'}} />
                      </a>
                    </span>
                  </div>
                )}
                style={{marginBottom: '12px'}}
              >
                {getFieldDecorator('description', {
                  initialValue: me.props.node.description || '',
                  rules: [{
                    message: '请输入描述信息',
                  }],
                })(
                  <Input.TextArea
                    className={`scrollbar ${style['description-textarea']}`}
                    placeholder="输入与当前关注点有关的任何描述信息"
                    ref={descriptionRef => {me.descriptionRef = descriptionRef;}}
                    rows={12}
                  />
                )}
              </Form.Item>
              <Form.Item
                label="外链"
                style={{marginBottom: '12px'}}
              >
                {getFieldDecorator('url', {
                  initialValue: me.props.node.url || '',
                  rules: [{
                    pattern: /^((https?:)?\/)?\/.+/,
                    message: '请输入有效的链接地址，以http或https开头',
                  }],
                })(
                  <Input
                    placeholder="请输入以 http:// 或 https:// 开头的网络链接"
                  />
                )}
              </Form.Item>
              <Form.Item
                label="标签"
                style={{marginBottom: '0'}}
              >
                {
                  this.state.tags.map(
                    (tag, i) => (
                      <Tag key={`tag-${i}`}>
                        { me.tagIconType(tag)}
                        &nbsp;{tag}&nbsp;
                        <a
                          className={style['tag-remove']}
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            this.onTagRemove(i);
                          }}
                        >
                          <Icon name={'delete'}/>
                        </a>
                      </Tag>
                    )
                  )
                }
                <Tooltip
                  title={
                    <div style={{paddingTop: '10px'}}>
                      <Input.Search
                        placeholder="请输入标签"
                        enterButton="添加"
                        value={this.state.currentTag}
                        ref={inputRef => {me.addTagInputRef = inputRef;}}
                        onChange={e => {
                          let value = e.target.value;
                          let tags = localStorage.getItem('historyTags') || '';
                          let historyTags = tags.split('|') || [];
                          let filterTags = historyTags.filter(item => item.includes(value));
                          if (value) {
                            this.setState({
                              currentTag: e.target.value,
                              historyTags: filterTags,
                            });
                          } else {
                            this.setState({
                              currentTag: e.target.value,
                              historyTags: historyTags,
                            });
                          }
                        }}
                        onSearch={this.onTagAdd}
                        onKeyDown={event => {
                          if (event.key === 'Escape') {
                            event.stopPropagation();
                            me.setState({showTagInputTooltip: false, currentTag: ''});
                          }
                        }}
                      />
                      {
                        me.state.currentTag ? (
                          <div className={style['tag-shortcut-wrap']}>
                            {
                              me.state.historyTags.length > 0 ? (
                                <React.Fragment>
                                  <div className={style['tag-group-title']}>输入匹配：</div>
                                  <div className={style['tag-group']}>
                                    {
                                      me.state.historyTags.map((item, index) => {
                                        if (index < 3) {
                                          return (
                                            <div key={item} style={{display: 'inline-block', marginBottom: '10px'}}>
                                              <Tag
                                                style={{cursor: 'pointer'}}
                                                onClick={() => {
                                                  this.onTagAdd(item);
                                                }}
                                              >
                                                {item}
                                              </Tag>
                                            </div>
                                          )
                                        }
                                      })
                                    }
                                  </div>
                                </React.Fragment>
                              ) : null
                            }
                          </div>
                        ) : (
                          <div className={style['tag-shortcut-wrap']}>
                            <div className={style['tag-group-title']}>快捷标签：</div>
                            <div className={style['tag-group']}>
                              {
                                me.state.shortcutTimeTags.map(item => (
                                  <div key={item.text} style={{ display: 'inline-block', marginBottom: '12px',
                                    marginRight: '24px'}}>
                                    <Badge count={item.text} style={{ backgroundColor: '#eee', color: '#999', top: '11px', right: '-20px'}}>
                                      <Tag style={{cursor: 'pointer'}}
                                           onClick={() => {
                                             this.onTagAdd(item.value);
                                           }}
                                      >
                                        <Icon name={'calendar'} style={{marginRight: '6px'}}/>
                                        {item.value}
                                      </Tag>
                                    </Badge>
                                  </div>
                                ))
                              }
                            </div>
                            <div className={style['tag-group']}>
                              {
                                me.state.shortcutLocationTags.map(item => (
                                  <div key={item} style={{display: 'inline-block', marginBottom: '10px'}}>
                                    <Tag style={{cursor: 'pointer'}}
                                         onClick={() => {
                                           this.onTagAdd(item);
                                         }}
                                    >
                                      <Icon name={'environment'} style={{marginRight: '6px'}}/>
                                      {item}
                                    </Tag>
                                  </div>
                                ))
                              }
                            </div>
                            {
                              me.state.historyTags.length > 0 ? (
                                <React.Fragment>
                                  <div className={style['tag-group-title']}>历史记录：</div>
                                  <div className={style['tag-group']}>
                                    {
                                      me.state.historyTags.map((item, index) => {
                                        if (index < 8) {
                                          return (
                                            <div key={item} style={{display: 'inline-block', marginBottom: '10px', cursor: 'pointer'}}>
                                              <Tag onClick={() => {
                                                this.onTagAdd(item);
                                              }}
                                              >
                                                {item}
                                              </Tag>
                                            </div>
                                          )
                                        }
                                      })
                                    }
                                  </div>
                                </React.Fragment>
                              ) : null
                            }
                          </div>
                        )
                      }
                    </div>
                  }
                  placement="top"
                  overlayClassName={style['add-tag-tooltip']}
                  trigger={'click'}
                  visible={me.state.showTagInputTooltip}
                  getPopupContainer={() => document.getElementById('nodeEditForm')}
                  onVisibleChange={showTagInputTooltip => {
                    me.setState({showTagInputTooltip});
                    if (showTagInputTooltip) {
                      requestAnimationFrame(() => {
                        if (me.addTagInputRef) {
                          me.addTagInputRef.focus();
                        }
                        let tags = localStorage.getItem('historyTags') || '';
                        if (tags) {
                          me.setState({
                            historyTags: tags.split('|'),
                          })
                        }
                      });
                    }
                  }}
                >
                  <Button shape="circle" icon="plus" size={'small'} />
                </Tooltip>
              </Form.Item>
            </Col>
	          {
	          	me.state.openFull ? (
			          <Col span={12} style={{paddingLeft: '20px'}}>
				          <Form.Item
					          label="图标大小"
					          style={{marginBottom: '12px'}}
				          >
					          <Radio.Group
						          onChange={e => me.setState({scale: e.target.value})}
						          value={me.state.scale}
						          style={{width: '100%'}}
					          >
						          {
							          [0.5, 1, 2, 4].map((i, idx) => (
								          <Tooltip key={`scale-${idx}`} title={`${i * 100}%`}>
									          <Radio.Button value={i} style={{width: '25%', textAlign: 'center'}}>
										          <Icon
											          name={'icon-yuandianda'}
											          type={IconTypes.ICON_FONT}
											          style={{
												          marginRight: '0.5em',
												          fontSize: `${0.2 * (idx + 2)}rem`,
												          // lineHeight: '1rem',
												          verticalAlign: `${0.12 - 0.12 * idx}rem`,
											          }}
										          />
										          {scaleText[idx]}
									          </Radio.Button>
								          </Tooltip>
							          ))
						          }
					          </Radio.Group>
				          </Form.Item>
				          <Form.Item
					          label="选择图标"
					          style={{marginBottom: 0}}
				          >
					          <div className={style['icon-btn-frame']}>
						          <div className={`${style['icon-btn-box']}`}>
							          {
								          nodeIcons.map((cfg, idx) => {
									          if (cfg && _.isObject(cfg)) {
										          return (
											          <Tooltip title={cfg.title} key={`r-${cfg.key}`}>
												          <Button
													          shape={'circle'}
													          size={'large'}
													          className={`${style['icon-btn']} ${me.state.iconKey === cfg.key ? 'selected' : ''}`}
													          onClick={() => me.onIconClicked(cfg.key, cfg.value, cfg.extra)}
												          >
													          <Icon {...cfg} />
												          </Button>
											          </Tooltip>
										          )
									          } else if (cfg === 'custom') {
										          if (me.state.uploadedIconImgBase64Url) {
											          return (
												          <Tooltip
                                    title={(me.cachedIconInfo.length > 0 ? (
                                      <React.Fragment>
                                        <div className={style['custom-icon-bar-title']}>自定义图标</div>
                                        <div className={style['custom-icon-bar-content']}>
                                          {me.cachedIconInfo.filter(info => !!info).map((info, idx) => (
                                            <div key={`icon-${idx}`}>
                                              <a
                                                onClick={() => {
                                                  let i = {...info};
                                                  delete i.iconData;
                                                  me.setState({...i});
                                                }}
                                                style={{display: 'inline-block'}}
                                                className={info.iconShape}
                                              >
                                                <img src={info.iconData} alt={'自定义图标'} className={info.iconShape} />
                                              </a>
                                            </div>
                                          ))}
                                        </div>
                                      </React.Fragment>
                                    ) : '自定义图标')}
                                    key={`r-${cfg}`}
                                  >
													          <Button
														          shape={'circle'}
														          size={'large'}
														          className={`${style['icon-btn']} ${me.state.iconKey === cfg ? 'selected' : ''}`}
														          onClick={() => me.onIconClicked('custom', 'gradeD', undefined)}
													          >
														          <Icon name="edit" style={{}}/>
													          </Button>
												          </Tooltip>
											          )
										          } else {
											          return (
												          <Upload
													          key={'custom-icon-upload'}
													          name={'file'}
													          multiple={false}
													          showUploadList={false}
													          disabled={this.state.uploading}
													          beforeUpload={file => me.beforeCustomIconUpload(file)}
													          onChange={info => {
														          if (info.file.status === 'uploading') {
															          this.setState({uploading: true});
														          }
													          }}
												          >
													          <Tooltip
                                      title={(me.cachedIconInfo.length > 0 ? (
                                        <React.Fragment>
                                          <div className={style['custom-icon-bar-title']}>自定义图标</div>
                                          <div className={style['custom-icon-bar-content']}>
                                            {me.cachedIconInfo.filter(info => !!info).map((info, idx) => (
                                              <div key={`icon-${idx}`}>
                                                <a
                                                  onClick={e => {
                                                    e.stopPropagation();
                                                    let i = {...info};
                                                    delete i.iconData;
                                                    me.setState({
                                                      showIconEditor: true,
                                                      ...i,
                                                      newCustomIcon: false,
                                                    });
                                                  }}
                                                  style={{display: 'inline-block'}}
                                                  className={info.iconShape}
                                                >
                                                  <img src={info.iconData} alt={'原图标'} className={info.iconShape} />
                                                </a>
                                              </div>
                                            ))}
                                          </div>
                                        </React.Fragment>
                                      ) : '自定义图标')}
                                      key={`r-${cfg}`}
                                    >
														          <Button
															          shape={'circle'}
															          size={'large'}
															          className={`${style['icon-btn']} ${me.state.iconKey === cfg ? 'selected' : ''}`}
															          onClick={() => me.onIconClicked('custom', 'gradeD', undefined)}
														          >
															          <Icon name="edit" style={{}}/>
														          </Button>
													          </Tooltip>
												          </Upload>
											          )
										          }
									          } else {
										          return (<br key={`r-idx-${idx}`} />);
									          }
								          })
							          }
						          </div>

						          <div className={style['avatar-editor-wrap']}>
                        {
                          me.state.showIconEditor ? (
                            <Row type={'flex'} justify="space-around" style={{paddingTop: '8px'}}>
                              <Col span={12}>
                                <div>
                                  <Upload
                                    name={'file'}
                                    multiple={false}
                                    showUploadList={false}
                                    disabled={this.state.uploading}
                                    beforeUpload={file => me.beforeCustomIconUpload(file)}
                                    onChange={info => {
                                      if (info.file.status === 'uploading') {
                                        this.setState({uploading: true});
                                      }
                                    }}
                                  >
                                    <Button icon={'upload'} style={{marginRight: '10px', padding: '0 10px'}}>图标文件</Button>
                                  </Upload>
                                  <Button
                                    icon={'smile'}
                                    disabled={!me.state.showIconEditor && !me.state.iconData}
                                    onClick={() =>
                                      this.setState({
                                        showIconEditor: false,
                                        uploadedIconImgBase64Url: undefined,
                                        iconData: undefined,
                                        iconShape: 'circle',
                                        iconOriginalHeight: 0,
                                        iconOriginalWidth: 0,
                                        iconRectHeight: 0,
                                        iconRectWidth: 0,

                                        iconKey: 'gradeD',
                                        iconChanged: true,
                                        newCustomIcon: false,
                                        lev: 'gradeD',
                                        userPreferredType: NODE_TYPE_TEXT,
                                      })
                                    }
                                    style={{padding: '0 10px'}}
                                  >
                                    使用默认
                                  </Button>
                                </div>
											          <div>
												          {/* 形状 和 背景 */}
												          <div style={{display: 'flex', alignItems: 'center', marginTop: '16px'}}>
													          <Radio.Group
														          defaultValue={me.state.iconShape}
														          buttonStyle={'solid'}
														          style={{display: 'block', marginRight: '10px'}}
														          value={me.state.iconShape}
														          onChange={e => {
															          me.setState({
                                          iconShape: e.target.value,
                                          iconKey: 'custom',
                                          iconChanged: true,
                                          newCustomIcon: true,
                                          lev: 'gradeD',
                                          userPreferredType: NODE_TYPE_TEXT,
															          })
														          }}
													          >
														          <Radio.Button value={'circle'} style={{padding: '0 9px'}}>圆形</Radio.Button>
														          <Radio.Button value={'rect'} style={{padding: '0 10px'}}>方形</Radio.Button>
													          </Radio.Group>
													          <Popover
														          content={(
															          <SketchPicker
																          color={me.state.iconBackground}
																          onChange={color => me.setState({
                                            iconBackground: color.rgb,
                                            iconKey: 'custom',
                                            iconChanged: true,
                                            newCustomIcon: true,
                                            lev: 'gradeD',
                                            userPreferredType: NODE_TYPE_TEXT,
																          })}
																          presetColors={presetColors}
															          />
														          )}
														          overlayClassName={style['sketch-picker-overlay']}
													          >
														          <Button style={{width: '100px', lineHeight: '32px', padding: '0 10px'}}>
															          背景
															          <span className={style['icon-bg-sample']}>
                                          <span
                                            style={{
                                              backgroundColor: `rgba(${Object.values(me.state.iconBackground).join(', ')})`,
                                            }}
                                          >
                                            &nbsp;
                                          </span>
                                        </span>
														          </Button>
													          </Popover>
												          </div>
												          {/* 缩放 和 旋转 */}
												          <div style={{marginTop: '20px'}}>
													          <Tooltip placement={'top'} title={'缩小'}>
														          <Button
															          icon={'zoom-out'}
															          className={style['control-btn']}
															          disabled={me.state.iconScale <= 0.5}
															          onClick={() => me.setState({
                                          iconScale: Math.max(me.state.iconScale - 0.1, 0.5),
                                          iconKey: 'custom',
                                          iconChanged: true,
                                          newCustomIcon: true,
                                          lev: 'gradeD',
                                          userPreferredType: NODE_TYPE_TEXT,
															          })}
															          style={{marginRight: '10px'}}
														          />
													          </Tooltip>
													          <Tooltip placement={'top'} title={'放大'}>
														          <Button
															          icon={'zoom-in'}
															          className={style['control-btn']}
															          disabled={me.state.iconScale >= 2}
															          onClick={() => me.setState({
                                          iconScale: Math.min(me.state.iconScale + 0.1, 2),
                                          iconKey: 'custom',
                                          iconChanged: true,
                                          newCustomIcon: true,
                                          lev: 'gradeD',
                                          userPreferredType: NODE_TYPE_TEXT,
															          })}
															          style={{marginRight: '10px'}}
														          />
													          </Tooltip>
													          <Tooltip placement={'top'} title={'左转90°'}>
														          <Button
															          icon={'undo'}
															          className={style['control-btn']}
															          onClick={() => me.setState({
                                          iconRotate: (me.state.iconRotate - 90) % 360,
                                          iconKey: 'custom',
                                          iconChanged: true,
                                          newCustomIcon: true,
                                          lev: 'gradeD',
                                          userPreferredType: NODE_TYPE_TEXT,
                                        })}
															          style={{marginRight: '10px'}}
														          />
													          </Tooltip>
													          <Tooltip placement={'top'} title={'右转90°'}>
														          <Button
															          icon={'redo'}
															          className={style['control-btn']}
															          onClick={() => me.setState({
                                          iconRotate: (me.state.iconRotate + 90) % 360,
                                          iconKey: 'custom',
                                          iconChanged: true,
                                          newCustomIcon: true,
                                          lev: 'gradeD',
                                          userPreferredType: NODE_TYPE_TEXT,
                                        })}
														          />
													          </Tooltip>
												          </div>
												          {/* 滑动缩放 */}
												          <div style={{marginTop: '26px'}}>
													          <Slider
														          onChange={v => me.setState({
                                        iconScale: v,
                                        iconKey: 'custom',
                                        iconChanged: true,
                                        newCustomIcon: true,
                                        lev: 'gradeD',
                                        userPreferredType: NODE_TYPE_TEXT,
														          })}
														          min={0.5}
														          max={2}
														          step={0.01}
														          value={this.state.iconScale}
														          style={{width: iconSize + 30, margin: '0 auto calc(0.5rem - 4px)'}}
													          />
												          </div>
											          </div>
                              </Col>
                              <Col span={11}>
                                {
                                  me.state.showIconEditor ? (
                                    <div style={{textAlign: 'center'}}>
                                      <div className={style['avatar-editor']}>
                                        <AvatarEditor
                                          image={this.state.uploadedIconImgBase64Url}
                                          width={me.state.iconShape === 'circle' ? me.state.iconSize : me.state.iconRectWidth}
                                          height={me.state.iconShape === 'circle' ? me.state.iconSize : me.state.iconRectHeight}
                                          position={me.state.iconPosition}
                                          border={10}
                                          borderRadius={me.state.iconShape === 'circle' ? me.state.iconSize / 2 : 0}
                                          color={[0, 0, 0, 0.4]}
                                          scale={this.state.iconScale}
                                          rotate={this.state.iconRotate}
                                          style={{
                                            backgroundColor: `rgba(${Object.values(me.state.iconBackground).join(', ')})`,
                                          }}
                                          onPositionChange={iconPosition => {
                                            me.setState({iconPosition});
                                          }}
                                        />
                                        <div style={{
                                          position: 'absolute',
                                          userSelect: 'none',
                                          pointerEvents: 'none',
                                          top: 0,
                                          opacity: 0,
                                          height: 0,
                                          width: 0,
                                          overflow: 'hidden',
                                        }}>
                                          <AvatarEditor
                                            ref={ref => this.iconEditorRef = ref}
                                            image={this.state.uploadedIconImgBase64Url}
                                            width={me.state.iconShape === 'circle' ? me.state.iconSize * ratio : me.state.iconRectWidth * ratio}
                                            height={me.state.iconShape === 'circle' ? me.state.iconSize * ratio : me.state.iconRectHeight * ratio}
                                            position={me.state.iconPosition}
                                            border={10 * ratio}
                                            borderRadius={me.state.iconShape === 'circle' ? me.state.iconSize / 2 * ratio : 0}
                                            color={[0, 0, 0, 0.4]}
                                            scale={this.state.iconScale}
                                            rotate={this.state.iconRotate}
                                            style={{
                                              backgroundColor: `rgba(${Object.values(me.state.iconBackground).join(', ')})`,
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    this.state.iconShape === 'circle' ? (
                                      <UserAvatar
                                        size={iconSize}
                                        name={'无'}
                                        src={this.state.iconData ? this.state.iconData : defaultTextIconImg}
                                        style={{
                                          width: 'fit-content',
                                          fontSize: iconSize / 2.5,
                                          margin: 10,
                                        }}
                                      />
                                    ) : (
                                      <div style={{textAlign: 'center'}}>
                                        <img alt={me.state.name} src={me.state.iconData} style={{maxWidth: '100%', maxHeight: '100%'}}/>
                                      </div>
                                    )
                                  )
                                }
                              </Col>
                            </Row>
                          ) : (
                            <Upload.Dragger
                              name={'file'}
                              multiple={false}
                              showUploadList={false}
                              disabled={this.state.uploading}
                              beforeUpload={file => me.beforeCustomIconUpload(file)}
                              onChange={info => {
                                if (info.file.status === 'uploading') {
                                  this.setState({uploading: true});
                                }
                              }}
                            >
                              <p className="ant-upload-drag-icon">
                                <Icon name="picture"/>
                              </p>
                              <p className="ant-upload-text">
                                &lt;自定义图标&gt;<br />
                                点击选取上传图标文件 或 拖拽图标文件到这里<br />
                                也可直接 Ctrl+V 粘贴
                              </p>
                            </Upload.Dragger>
                          )
                        }
						          </div>
					          </div>
				          </Form.Item>
			          </Col>
		          ) : null
	          }
          </Row>
          {
            me.props.editType !== 'editNode' ? (
              me.state.openFull ? (
                <Tag
                  onClick={() => me.setState({openFull: false},
                    () => {localStorage.setItem('show_full_create_node_modal', '0')})}
                  className={style['show-more-box']}
                >
                  <Icon name="double-left" className={style['show-more-arrow']} style={{marginBottom: '10px'}}/>
                  <span>收</span>
                  <span>起</span>
                  <Icon name="double-left" className={style['show-more-arrow']} style={{marginTop: '10px'}}/>
                </Tag>
              ) : (
                <Tag
                  onClick={() => me.setState({openFull: true},
                    () => {localStorage.setItem('show_full_create_node_modal', '1')})}
                  className={style['show-more-box']}
                >
                  <Icon name="double-right" className={style['show-more-arrow']} style={{marginBottom: '10px'}}/>
                  <span>更</span>
                  <span>多</span>
                  <Icon name="double-right" className={style['show-more-arrow']} style={{marginTop: '10px'}}/>
                </Tag>
              )
            ) : null
          }
        </Form>
      </Modal>
    );
  }
}

const WrappedTextEditModal = Form.create()(TextEditModal);

// noinspection JSValidateTypes
WrappedTextEditModal.defaultProps = {
	editType: '',
  node: {fname: '', description: '', url: '', tags: [], iconData: undefined},
	withNode: undefined,
  bus: PB,
  visible: false,
  processing: false,
};

// noinspection JSValidateTypes
WrappedTextEditModal.propTypes = {
	editType: PropTypes.string,
  node: PropTypes.object,
	withNode: PropTypes.object,
  bus: PropTypes.instanceOf(SimplePB),
  visible: PropTypes.bool,
  processing: PropTypes.bool,
  doClose: PropTypes.func.isRequired,
};

export default WrappedTextEditModal;
