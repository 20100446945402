import React from 'react';
import {Row, Col, Tooltip} from 'antd';
import PropTypes from 'prop-types';
import ClampLines from '@/libs/my-react-clamp-lines';

/*import {
    getNodeIcon,
    /!*NODE_TYPE_TEXT,
    TYPE_FIELD_NAME,
    NODE_TYPE_TAG,
    getExternalLinkUrl,*!/
} from "@/constants/vis.defaultDefine.1";*/
// import IconAvatar from '@/components/common/common.iconAvatar';
import PB, {SimplePB} from "@/libs/simplePB";
import LightBox from "react-image-lightbox";
import {getToken, REQUEST_BASE} from "@/utils/HttpUtil";

import style from "@/style/components/main.nodeInfo.less";
import TimeDisplayField from "@/components/common/common.timeDisplayField";
import moment from "moment";
import HoverContent from "@/components/common/common.hoverContent";

const supportedSuffixList = ['png', 'jpg', 'gif', 'jpeg'];

class NodeInfoCommonDetailPanel extends React.Component {
    state = {
        currentImageIdx: -1,
        createUserInfo: null,
        fullScreenContent: false,
    };

    componentDidMount() {
	    let me = this;
	    // 获取协作成员信息列表
	    me.props.bus.sub(me, 'teamwork', 'member.list.success', ({viewId, userList}) => {
		    if (viewId === me.props.originalViewId) {
			    let nodeUserInfo = userList.length > 0 && userList.filter(item => parseInt(item.userId) === parseInt(me.props.node.userId));
			    me.setState({createUserInfo: nodeUserInfo[0]});
		    }
	    });

	    // 触发获取协作成员信息
	    me.props.bus.emit('teamwork', 'member.list.do', {viewId: me.props.originalViewId, forceReload: false});

    }

    componentWillUnmount() {
        this.props.bus.remove(this);
    }

    render() {
        let me = this;
        const {node} = this.props;

        // const {name, type, color} = getNodeIcon(node);
        const actions = [];
        const images = [];
        let lightBox = null, fullScreenContent = null;
        let tags = [];

        let contentList = [];
        if (node.description) {
            contentList.push(node.description);
        }
        let description = '-';
        if (contentList.length > 0) {
            description = contentList.join("\n");
        }

        if (node.tag && node.tag.length > 0) {
            tags = node.tag.split(' ');
        }

        // 其他操作
        if (this.props.actions && this.props.actions.length > 0) {
            Array.prototype.push.apply(actions, this.props.actions);
        }

        // 尝试获取附件中的图片信息
        if (node && node['prop']) {
            for (let i = node['prop'].length - 1; i >= 0; i--) {
                let property = node['prop'][i];
                let fileList = property['fileList'];
                if (fileList) {
                    for (let j = 0; j < fileList.length; j++) {
                        let fileInfo = fileList[j];
                        const segments = fileInfo['fileName'].split(/\./g);
                        const type = segments.pop().toLowerCase();
                        if (supportedSuffixList.includes(type)) {
                            // 找到图片
                            images.push(fileInfo);
                        }
                    }
                }
            }
        }

        if (images.length > 0 && me.state.currentImageIdx >= 0 && me.state.currentImageIdx < images.length) {
            const mainSrc =
              `${REQUEST_BASE}/view/project/file/${images[me.state.currentImageIdx]['fileId']}?Authorization=${getToken()}`;
            if (mainSrc) {
                const nextSrcIdx = (this.state.currentImageIdx + 1) % images.length;
                const nextSrc = `${REQUEST_BASE}/view/project/file/${images[nextSrcIdx]['fileId']}?Authorization=${getToken()}`;
                const prevSrcIdx = (images.length + this.state.currentImageIdx - 1) % images.length;
                const prevSrc = `${REQUEST_BASE}/view/project/file/${images[prevSrcIdx]['fileId']}?Authorization=${getToken()}`;
                lightBox = (
                  <LightBox
                    mainSrc={mainSrc}
                    prevSrc={images.length === 1 ? null : prevSrc}
                    nextSrc={images.length === 1 ? null : nextSrc}
                    onCloseRequest={() => this.setState({currentImageIdx: -1})}
                    onMovePrevRequest={() => this.setState({currentImageIdx: prevSrcIdx})}
                    onMoveNextRequest={() => this.setState({currentImageIdx: nextSrcIdx})}
                  />
                );
            }
        }

        if (me.state.fullScreenContent && contentList.length > 0) {
            fullScreenContent = (
              <HoverContent onClose={() => me.setState({fullScreenContent: false})}>
                  <div
                    className={`${style['node-fullscreen-content-frame']} scrollbar scrollbar-none`}
                    onClick={e => e.stopPropagation()}
                  >
                        <pre style={{minHeight: '3rem','marginBottom': '1.5rem',fontSize: '3.9rem',color: '#fff',backgroundColor:'#385C80',padding:'0 1rem'}}>{node.fname}</pre>
                        {tags && tags.length>0 && 
                            <div className="ant-form-vertical" style={{'marginBottom': '1rem'}}>    
                            <div className="ant-form-item-control" style={{lineHeight: '0.5'}}>
                                <span className="ant-form-item-children">
                                {tags.map((tag, idx) => (
                                    <span className="ant-tag" key={`tag-${idx}`} style={{'backgroundColor': 'rgba(88, 118, 163)',border:'0',textShadow:'none',fontSize: '1.6rem','lineHeight': '1.5',marginBottom:'8px',display:'inline-block'}}>{tag}</span>
                                ))}
                                </span>
                            </div>
                        </div>
                        }
                        <div style={{borderTop: '1px solid rgba(146, 207, 243, 0.7)',height: '2rem'}}></div>
                        {description.split('\n').map((line, idx) => (
                            <p key={`l-${idx}`}>{line}</p>
                        ))}
                        <div style={{borderBottom: '1px solid rgba(146, 207, 243, 0.7)',height: '2rem'}}></div>
                  </div>
              </HoverContent>
            );
        }

        return (
            <div className="ant-list-item-meta">
                {
                    /*this.props.showIcon ? (
                        <div className="ant-list-item-meta-avatar" style={{marginRight: '10px'}}>
                            <Tooltip title={'定位节点'}>
                                <a
                                  onClick={(e) => {
                                      e.preventDefault();
                                      PB.emit('network', 'focus', node)
                                  }}
                                >
                                    <IconAvatar name={name} type={type} color={color}/>
                                </a>
                            </Tooltip>
                        </div>
                    ) : null*/
                }
                <div className="ant-list-item-meta-description">
                    <Row gutter={0}>
                        <Col span={3}>描述：</Col>
                        <Col span={21}>
                            <ClampLines
                                className={`ant-list-item-meta-description scrollbar ${style['node-detail-description']} ${images.length > 0 ? 'has-img' : ''}`}
                                lines={5}
                                moreText={'展开 ∨'}
                                lessText={'收起 ∧'}
                                text={description}
                                useLinkButtons={true}
                                extraLinkButtons={contentList.length > 0 ? (
                                    <React.Fragment>
                                        <br />
                                        <a
                                            className="clamp-lines__link_button"
                                            onClick={() => {contentList.length > 0 && me.setState({fullScreenContent: true})}}
                                        >
                                            放大 ＜
                                        </a>
                                    </React.Fragment>
                                ) : null}
                                renderFn={(text, extraElement) => {
                                    const lines = text.split('\n');
                                    return (
                                        <span>
                                            {
                                                images.length > 0 ? (
                                                  <div className={style['node-detail-attachment-image-container']}>
                                                      <a onClick={() => me.setState({currentImageIdx: 0})}>
                                                          {/* TODO: 最大宽度写死200，后期需根据屏幕适配 */}
                                                          <img
                                                            src={`${REQUEST_BASE}/view/project/file/thumb/200/0/${images[0]['fileId']}?Authorization=${getToken()}`}
                                                            alt={images[0]['fileName']}
                                                            hspace="5"
                                                          />
                                                      </a>
                                                  </div>
                                                ) : null
                                            }
                                            {
                                                tags.length > 0 ? (
                                                    <div className="ant-form-vertical">    
                                                        <div className="ant-form-item-control">
                                                            <span className="ant-form-item-children">
                                                            {tags.map((tag, idx) => (
                                                                <span className="ant-tag" key={`tag-${idx}`}>{tag}</span>
                                                            ))}
                                                            </span>
                                                        </div>
                                                    </div>
                                                ) : null
                                            }
                                            {
                                                lines.map((line, idx) => (
                                                  <span key={`ln-${idx}`}>{line}{idx === lines.length - 1 ? null : (<br />)}</span>
                                                ))
                                            }
                                            {
                                                extraElement ? extraElement : null
                                            }
                                        </span>
                                    );
                                }}
                            />
                        </Col>
                    </Row>
                    <Row gutter={0}>
                        <Col span={3}>外链：</Col>
                        <Col span={21}>
                            {(node.url) ? (
                              <ClampLines
                                className={'ant-list-item-meta-description'}
                                lines={2}
                                moreText={'展开 ∨'}
                                lessText={'收起 ∧'}
                                text={node.url}
                                renderFn={(text) => (
                                  <a href={node.url} target={'_blank'}>
                                      <span>{text}</span>
                                  </a>
                                )}
                              />
                            ) : '-'}
                        </Col>
                    </Row>
                    <Row gutter={0}>
                        <Tooltip title={'最后修改时间'} placement={'left'}>
                            <Col span={3}>编辑：</Col>
                        </Tooltip>
                        <Col span={21}>
                            {node.updateTime ? (
                              <TimeDisplayField timestamp={moment(node.updateTime, 'YYYY-MM-DD HH:mm:ss').valueOf()} tooltipPlacement={'right'} />
                            ) : (node.linkTime ? (
                              <TimeDisplayField timestamp={moment(node.linkTime, 'YYYY-MM-DD HH:mm:ss').valueOf()} tooltipPlacement={'right'} />
                            ) : '-')}
                        </Col>
                    </Row>
                    <Row gutter={0}>
                        <Col span={3}>附件：</Col>
                        {
                            node['prop'] && node['prop'].length > 0 ? (
                              <Col span={21}>
                                  <a
                                    className={'as-link'}
                                    onClick={() => {
                                      PB.emit('network', 'node.on_show_extra_info', {node});
                                    }}
                                  >
                                      附件数量({node['prop'].length > 999 ? '999+' : node['prop'].length})
                                  </a>
                              </Col>
                            ) : (
                              <Col span={21}>附件数量(0)</Col>
                            )
                        }
                    </Row>
                    <Row gutter={0}>
                        <Tooltip title={'创建人信息'} placement={'left'}>
                            <Col span={3}>作者：</Col>
                        </Tooltip>
                        <Col span={21}>{node['userId'] && me.state.createUserInfo ? me.state.createUserInfo.nick : '-'}</Col>
                    </Row>
                </div>
                <div id="nodeInfoDetailHidden" data-id={node.id} data-fname={node.fname} style={{visibility: 'hidden'}} />
                {lightBox}
                {fullScreenContent}
            </div>
        );
    }
}

NodeInfoCommonDetailPanel.defaultProps = {
	  bus: PB,
    // showIcon: false,
    showAssistant: false,
};

NodeInfoCommonDetailPanel.propTypes = {
	  bus: PropTypes.instanceOf(SimplePB),
    node: PropTypes.object.isRequired,
    // showIcon: PropTypes.bool,
    showAssistant: PropTypes.bool,
    originalViewId: PropTypes.string,
};

export default NodeInfoCommonDetailPanel;
