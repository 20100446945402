import React from 'react';
import PropTypes from 'prop-types';
import {Form, Icon, Input, Tag, Modal, Button, Tooltip, message} from 'antd';

import style from '@/style/components/main.nodeInfo.less';

class NodeInfoCompanyGeneralEditModal extends React.Component {
  state = {
    name: '',
    tags: [],
  };

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (this.props.visible === false && nextProps.visible === true) {
      const me = this;
      this.setState((state, props) => {
        requestAnimationFrame(() => {
          const {form} = me.props;
          form.resetFields();
        });
        return {name: props.companyName, tags: props.companyTags};
      });
    }
    return true;
  }

  onTagRemove = (i) => {
    const {form} = this.props;
    // can use data-binding to get
    let tags = Array.from(form.getFieldValue('tags'));
    tags.splice(i, 1);

    // can use data-binding to set
    form.setFieldsValue({
      tags: tags,
    });
    this.setState({tags: tags});
  };

  onTagAdd = (tag, event) => {
    const {form} = this.props;
    // can use data-binding to get
    let tags = Array.from(form.getFieldValue('tags')),
      inputElement =
        (event.currentTarget.nodeName.toUpperCase() === 'INPUT' ?
          event.currentTarget : event.currentTarget.parentElement.previousSibling);
    if (tag.includes(' ')) {
      message.error('标签内容不可含有空格');
    } else if (tags.includes(tag)) {
      message.error('该标签已存在');
      inputElement.value = '';
    } else {
      tags = tags.concat(tag);
      // can use data-binding to set
      form.setFieldsValue({
        tags: tags,
      });
      this.setState({tags: tags});
      inputElement.value = '';
    }
  };

  render() {
    const {visible, onCancel, onSave, form} = this.props;
    const {getFieldDecorator} = form;
    const formItemLayout = {
      labelCol: {
        xs: {span: 8},
        sm: {span: 4},
      },
      wrapperCol: {
        xs: {span: 40},
        sm: {span: 20},
      },
    };
    getFieldDecorator('tags', {initialValue: this.props.companyTags});

    // noinspection RequiredAttributes
    return (
      <Modal
        closable={!this.props.processing}
        visible={visible}
        title={'修改公司信息'}
        okText={'确定'}
        cancelText={'取消'}
        onCancel={onCancel}
        onOk={onSave}
        okButtonProps={{loading: this.props.processing}}
        cancelButtonProps={{disabled: this.props.processing}}
      >
        <Form>
          <Form.Item
            {...formItemLayout}
            label="公司名称"
          >
            {getFieldDecorator('name', {
              initialValue: this.props.companyName,
              rules: [{
                required: true, message: '请输入公司名称',
              }],
            })(
              <Input/>
            )}
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            label="相关标签"
          >
            {
              this.state.tags.map(
                (tag, i) => (
                  <Tag key={`tag-${i}`}>
                    <Icon type='tag'/>
                    &nbsp;{tag}&nbsp;
                    <a className={style['tag-remove']}
                       onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      this.onTagRemove(i);
                    }}>
                      <Icon type={'delete'}/>
                    </a>
                  </Tag>
                )
              )
            }
            <Tooltip
              title={
                <Input.Search placeholder="请输入标签"
                              enterButton="确定"
                              onSearch={this.onTagAdd} />
              }
              placement="bottom">

              <Button shape="circle" icon="plus" size={'small'} />
            </Tooltip>
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

const WrappedNodeInfoCompanyGeneralEditModal = Form.create()(NodeInfoCompanyGeneralEditModal);

// noinspection JSValidateTypes
WrappedNodeInfoCompanyGeneralEditModal.defaultProps = {
  companyTags: [],
  visible: false,
  processing: false,
};

// noinspection JSValidateTypes
WrappedNodeInfoCompanyGeneralEditModal.propTypes = {
  companyName: PropTypes.string.isRequired,
  companyTags: PropTypes.array,
  visible: PropTypes.bool,
  processing: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default WrappedNodeInfoCompanyGeneralEditModal;