/*
 * @Author: Carr.s
 * @Date: 2022-04-29 11:41:35
 * @LastEditTime: 2022-05-22 23:07:38
 * @LastEditors: Carr.s
 * @Description: 预料分析洞察(领导讲话)
 * @Copyright by joinmap
 */
import React from "react";
import ViewInsightChartTpl from "@/components/insightView/template/insight.chart.tpl";
import { connect } from "react-redux";
import styles from "@/style/components/insightView/insight.less";
import Highcharts from "highcharts";
import xrange from "highcharts/modules/xrange";
xrange(Highcharts);

class ViewTaskProcessAInsight extends ViewInsightChartTpl {
  //===============================================================
  // begin of overwrite
  // 使用默认的方式就不用重写

  // ------------------------------------------------------------
  // 全局配置
  // ------------------------------------------------------------
  // 提示信息中提示添加哪种类型的节点
  nodeTitleInTip = "相关";
  // 显示底部按钮
  showToolbar = false;
  // 洞察名称
  viewerTitle = "任务推进";

  // ------------------------------------------------------------
  // 层级分析对象的配置
  // ------------------------------------------------------------
  // 第一层 level1 tag
  column1_showType = "list"; // 第一列的展现形式，list=显示列，tree=显示树
  // column1_DataField = "data"; // 取值字段
  // column1_TotalField = "dataTotal"; // 计算总数(与返回数量不一定一致)字段
  column1_treeFields = []; // 用树状目录显示数据时各层级的 children 字段名数据
  column1_Title = "任务项"; // 显示名称
  column1_Unit = "项"; // 显示的文字单位

  /*
  column1_IconRender = (level1_data, idx,nodePath=undefined) => {
    // 左侧图表渲染，不输出则空白
    // 必须加上 className={styles["line-icon"]}
  };
  */
  column1_HoverTitle = (column1_data, idx, nodePath = undefined) => {
    // 列表鼠标悬浮提示文字
    let name = column1_data.hasOwnProperty("name") ? column1_data.name : "";
    if (this.column2_DataField) {
      let column2Length = this.column2_DataField
        ? column1_data[this.column2_DataField].length
        : "";
      return `${this.column1_Title}：${name}\n${this.column2_Title}：${column2Length}${this.column2_Unit}\n点击打开${this.column2_Title}列表`;
    } else {
      return `${this.column1_Title}：${name}`;
    }
  };

  // 第二层 item
  column2_DataField = "dateList"; // 取值字段
  column2_TotalField = "value"; // 计算总数(与返回数量不一定一致)字段
  column2_Title = "任务"; // 显示名称
  column2_Unit = "条"; // 显示的文字单位
  column2_ValueRender = (level2_data, idx) => {
    // 右侧数值渲染，不输出则空白
    // 必须加上 className={styles["line-value"]}
    // 不显示
  };

  // ------------------------------------------------------------
  // 接口的配置
  // ------------------------------------------------------------
  // 接口 url path
  // api_path = "";
  api_path = "/wth_server_api/ren_wu_by_view_id";

  // 返回数据默认值
  defaultLimit = 60;

  /**
   * 【继承组件要重写】
   * api 请求完成后，setState结束后的 callback
   */
  apiLoadedCallback = () => {
    // message.info("数据加载成功");
  };

  // ------------------------------------------------------------
  // 图表的配置
  // ------------------------------------------------------------

  // 图表默认左边距（是否留出第一列的空间）
  chartDivLeft = "auto";

  // 图表显示库
  chartType = "highcharts";
  // chart div style
  chartStyle = {};

  /**
   * 生成公用的、默认的 option
   * @returns
   */
  initChartOption = () => {
    let me = this;
    // 浏览器窗口宽度
    // let browserWidth = document.documentElement.clientWidth;
    // let browserHeight = document.documentElement.clientHeight;
    // 图表左边距，根据是否显示第一列决定
    // let marginLeft = me.column1_showType == "none" ? 0 : 300;
    let _chartOption = {
      colors: this._chartColors,
      credits: {
        enabled: false, // 禁用版权信息
      },
      chart: {
        backgroundColor: null,
        // 指定内边距，下面的四个配置可以用 spacing: [10, 10, 15, 10] 来代替
        spacingBottom: 0,
        spacingTop: 0,
        spacingLeft: 0,
        spacingRight: 20,

        type: "xrange",
      },
      title: {
        text: "",
      },
      legend: {
        enabled: false,
        itemStyle: {
          color: "#f8f8f8",
        },
      },
      tooltip: {
        backgroundColor: "#fff",
        dateTimeLabelFormats: {
          second: "%Y-%m-%d<br/>%H:%M:%S",
          minute: "%Y-%m-%d<br/>%H:%M",
          hour: "%Y-%m-%d<br/>%H:%M",
          day: "%Y年%m月%e日", // %Y年%m月%d%e
          week: "%Y<br/>%m-%d",
          month: "%Y-%m",
          year: "%Y",
        },
        // pointFormat: "<b>{series.name}</b>: <b>{point.name}%</b>",
        style: {
          fontSize: 14,
        },
      },
      plotOptions: {
        xrange: {
          // shared options for all xrange series
          point: {
            events: {
              click: (e) => {
                console.log("point event e:", e);
                let sIndex = undefined;
                let sName = undefined;

                sIndex = e.point.y;
                sName = e.point.yCategory;
                me._column1_onClick(sIndex, sName, false);
              },
            },
          },
        },
      },
      xAxis: {
        title: {
          text: "起止日期",
          align: "high",
          style: {
            color: "#eeeeee",
          },
        },
        type: "datetime",
        dateTimeLabelFormats: {
          second: "%Y-%m-%d<br/>%H:%M:%S",
          minute: "%Y-%m-%d<br/>%H:%M",
          hour: "%Y-%m-%d<br/>%H:%M",
          day: "%Y年%m月%d日", // %Y年%m月%d%e，%e 占2个位置
          week: "%Y<br/>%m-%d",
          month: "%Y-%m",
          year: "%Y",
        },

        labels: {
          style: {
            color: "#eeeeee",
          },
        },
      },
      yAxis: {
        title: {
          text: "任务项",
          align: "high",
          style: {
            color: "#eeeeee",
          },
        },
        categories: ["x", "xx", "xxx"],
        reversed: true,

        labels: {
          style: {
            color: "#eeeeee",
          },
        },
        gridLineColor: "#4f4f4f",
      },
      series: [
        {
          name: "任务",
          // pointPadding: 0,
          // groupPadding: 0,
          borderColor: "gray",
          pointWidth: 20,
          pointPadding: 10,
          data: [
            {
              x: Date.UTC(2014, 10, 21),
              x2: Date.UTC(2014, 11, 2),
              y: 0,
              partialFill: 0.25,
            },
          ],
          dataLabels: {
            enabled: true,
          },
        },
      ],
    };
    return _chartOption;
  };

  /**
   * 【继承组件要重写】
   * 生成 chart 的 options
   * @param {int} idx 选中的数据下标
   */
  refreshChartOptions = (nodePath = null) => {
    console.log("refreshChartOptions 0 nodePath:", nodePath);
    const {
      query: { isDebug },
    } = this.props;
    if (isDebug) console.log("refreshChartOptions 0 nodePath:", nodePath);
    let me = this;
    let data = [];
    let yAxisCategories = [];
    this._chartData.forEach((e, idx) => {
      yAxisCategories.push(e.name);
      data.push({
        x: new Date(e.start_date).getTime(),
        x2: new Date(e.end_date).getTime(),
        y: idx, // yAxis 的序号
      });
    });

    // 生成options
    me._chartOption = this.initChartOption();
    // ---------------------------------------------
    // 图表数据的处理
    me._chartOption.yAxis.categories = yAxisCategories;
    me._chartOption.series[0].data = data;
    // ---------------------------------------------
    // 图表大小的处理
    let chartMaxWidth = 1500;
    let chartMaxHeight = yAxisCategories.length * 36;
    // 计算图表的宽度
    let chartWidth = document.getElementById("chart_div").clientWidth;
    // 判断显示宽度
    // chartWidth = chartWidth > chartMaxWidth ? chartMaxWidth : chartWidth;
    me._chartOption.chart.width = chartWidth;
    // 计算图表的高度
    let chartHeight = document.getElementById("chart_div").clientHeight;
    // chartHeight = chartHeight > chartMaxHeight ? chartMaxHeight : chartHeight;
    chartHeight = chartMaxHeight;
    me._chartOption.chart.height = chartHeight;
    // 图表的左边距
    setTimeout(() => {
      let column1El = document.getElementById("column1_list");
      let column1Width = column1El ? column1El.clientWidth : 300;
      me.chartDivLeft = column1Width + 10;
    }, 800);

    if (isDebug) console.log("me._chartOption:", me._chartOption);
    return;
  };

  /**
   * 【继承组件要重写】
   * echart 有时需要绑定点击事件什么
   * @param {object} e ReactECharts 返回的 echarts 实例
   */
  eChartRefCallback = (e) => {};

  // end of overwrite
  //===============================================================

  getColorOfLevel = (level, maxLevel = 1000000) => {
    if (level > 0 && level < this._chartColors.length) {
      if (level > maxLevel) {
        return "#dddddd";
      }
      return this._chartColors[level];
    } else {
      return "#dddddd";
    }
  };
}

export default connect(
  (state) => ({
    userInfo: state.account.userInfo,
  }),
  (dispatch) => ({})
)(ViewTaskProcessAInsight);
