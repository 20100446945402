import React from 'react';
import {Menu} from 'antd';
import ViewStatisticsNodePanel from "@/components/common/view/statistics/node/common.view.statistics.node.panel";

const CONDITION_TITLE = {
  CREATE_DATE: '创建时间',
  CREATE_MONTH: '创建时间',
  CREATE_YEAR: '创建时间',
  UPDATE_DATE: '更新时间',
  UPDATE_MONTH: '更新时间',
  UPDATE_YEAR: '更新时间',
  TAG_DATE: '标签时间',
  TAG_MONTH: '标签时间',
  TAG_YEAR: '标签时间',
};

const CONDITION_FULL_TITLE = {
  CREATE_DATE: '创建时间(日期)',
  CREATE_MONTH: '创建时间(月份)',
  CREATE_YEAR: '创建时间(年份)',
  UPDATE_DATE: '更新时间(日期)',
  UPDATE_MONTH: '更新时间(月份)',
  UPDATE_YEAR: '更新时间(年份)',
  TAG_DATE: '标签时间(日期)',
  TAG_MONTH: '标签时间(月份)',
  TAG_YEAR: '标签时间(年份)',
};

class ViewStatisticsNodeDatetimePanel extends ViewStatisticsNodePanel {
  getMenuContent = () => {
    let me = this;

    return (
      <Menu className={'dark-theme'}>
        <Menu.SubMenu key={'m-create_time'} popupClassName={'dark-theme'} title="创建时间">
          <Menu.Item
            key={'m-create_time-day'}
            onClick={() => {
              me.delayComponentLostHover('menu', `n-statistics_menu`);
              me.setState({
                rankLoadingStatus: 'processing',
                conditionKey: 'CREATE_DATE',
                limit: 10,
                sort: 'desc',
              }, () => {
                me.props.bus.emit('node.statistics', 'node.do_load',
                  {nodes: me.props.nodes.filter(node => !!node.status), conditionKey: me.state.conditionKey,
                    limit: me.state.limit, sort: me.state.sort, cacheKey: me.state.cacheKey,
                    distinct: me.state.distinct});
              });
            }}
          >按日期</Menu.Item>
          <Menu.Item
            key={'m-create_time-month'}
            onClick={() => {
              me.delayComponentLostHover('menu', `n-statistics_menu`);
              me.setState({
                rankLoadingStatus: 'processing',
                conditionKey: 'CREATE_MONTH',
                limit: 10,
                sort: 'desc',
              }, () => {
                me.props.bus.emit('node.statistics', 'node.do_load',
                  {nodes: me.props.nodes.filter(node => !!node.status), conditionKey: me.state.conditionKey,
                    limit: me.state.limit, sort: me.state.sort, cacheKey: me.state.cacheKey,
                    distinct: me.state.distinct});
              });
            }}
          >按月份</Menu.Item>
          <Menu.Item
            key={'m-create_time-year'}
            onClick={() => {
              me.delayComponentLostHover('menu', `n-statistics_menu`);
              me.setState({
                rankLoadingStatus: 'processing',
                conditionKey: 'CREATE_YEAR',
                limit: 10,
                sort: 'desc',
              }, () => {
                me.props.bus.emit('node.statistics', 'node.do_load',
                  {nodes: me.props.nodes.filter(node => !!node.status), conditionKey: me.state.conditionKey,
                    limit: me.state.limit, sort: me.state.sort, cacheKey: me.state.cacheKey,
                    distinct: me.state.distinct});
              });
            }}
          >按年份</Menu.Item>
        </Menu.SubMenu>
        <Menu.SubMenu key={'m-update_time'} popupClassName={'dark-theme'} title="更新时间">
          <Menu.Item
            key={'m-update_time-day'}
            onClick={() => {
              me.delayComponentLostHover('menu', `n-statistics_menu`);
              me.setState({
                rankLoadingStatus: 'processing',
                conditionKey: 'UPDATE_DATE',
                limit: 10,
                sort: 'desc',
              }, () => {
                me.props.bus.emit('node.statistics', 'node.do_load',
                  {nodes: me.props.nodes.filter(node => !!node.status), conditionKey: me.state.conditionKey,
                    limit: me.state.limit, sort: me.state.sort, cacheKey: me.state.cacheKey,
                    distinct: me.state.distinct});
              });
            }}
          >按日期</Menu.Item>
          <Menu.Item
            key={'m-update_time-month'}
            onClick={() => {
              me.delayComponentLostHover('menu', `n-statistics_menu`);
              me.setState({
                rankLoadingStatus: 'processing',
                conditionKey: 'UPDATE_MONTH',
                limit: 10,
                sort: 'desc',
              }, () => {
                me.props.bus.emit('node.statistics', 'node.do_load',
                  {nodes: me.props.nodes.filter(node => !!node.status), conditionKey: me.state.conditionKey,
                    limit: me.state.limit, sort: me.state.sort, cacheKey: me.state.cacheKey,
                    distinct: me.state.distinct});
              });
            }}
          >按月份</Menu.Item>
          <Menu.Item
            key={'m-update_time-year'}
            onClick={() => {
              me.delayComponentLostHover('menu', `n-statistics_menu`);
              me.setState({
                rankLoadingStatus: 'processing',
                conditionKey: 'UPDATE_YEAR',
                limit: 10,
                sort: 'desc',
              }, () => {
                me.props.bus.emit('node.statistics', 'node.do_load',
                  {nodes: me.props.nodes.filter(node => !!node.status), conditionKey: me.state.conditionKey,
                    limit: me.state.limit, sort: me.state.sort, cacheKey: me.state.cacheKey,
                    distinct: me.state.distinct});
              });
            }}
          >按年份</Menu.Item>
        </Menu.SubMenu>
        <Menu.SubMenu key={'m-tag_time'} popupClassName={'dark-theme'} title="标签时间">
          <Menu.Item
            key={'m-tag_time-day'}
            onClick={() => {
              me.delayComponentLostHover('menu', `n-statistics_menu`);
              me.setState({
                rankLoadingStatus: 'processing',
                conditionKey: 'TAG_DATE',
                limit: 10,
                sort: 'desc',
              }, () => {
                me.props.bus.emit('node.statistics', 'node.do_load',
                  {nodes: me.props.nodes.filter(node => !!node.status), conditionKey: me.state.conditionKey,
                    limit: me.state.limit, sort: me.state.sort, cacheKey: me.state.cacheKey,
                    distinct: me.state.distinct});
              });
            }}
          >按日期</Menu.Item>
          <Menu.Item
            key={'m-tag_time-month'}
            onClick={() => {
              me.delayComponentLostHover('menu', `n-statistics_menu`);
              me.setState({
                rankLoadingStatus: 'processing',
                conditionKey: 'TAG_MONTH',
                limit: 10,
                sort: 'desc',
              }, () => {
                me.props.bus.emit('node.statistics', 'node.do_load',
                  {nodes: me.props.nodes.filter(node => !!node.status), conditionKey: me.state.conditionKey,
                    limit: me.state.limit, sort: me.state.sort, cacheKey: me.state.cacheKey,
                    distinct: me.state.distinct});
              });
            }}
          >按月份</Menu.Item>
          <Menu.Item
            key={'m-tag_time-year'}
            onClick={() => {
              me.delayComponentLostHover('menu', `n-statistics_menu`);
              me.setState({
                rankLoadingStatus: 'processing',
                conditionKey: 'TAG_YEAR',
                limit: 10,
                sort: 'desc',
              }, () => {
                me.props.bus.emit('node.statistics', 'node.do_load',
                  {nodes: me.props.nodes.filter(node => !!node.status), conditionKey: me.state.conditionKey,
                    limit: me.state.limit, sort: me.state.sort, cacheKey: me.state.cacheKey,
                    distinct: me.state.distinct});
              });
            }}
          >按年份</Menu.Item>
        </Menu.SubMenu>
      </Menu>
    );
  };

  getConditionCellTitle = key => CONDITION_TITLE[key];

  getConditionMenuTitle = (key, sort, limit) => `${CONDITION_FULL_TITLE[key]} ${(sort && limit > 0) ? 'Top' : ''}${(sort && limit > 0) ? limit : ''}`;
}

ViewStatisticsNodeDatetimePanel.defaultProps = {
  ...ViewStatisticsNodePanel.defaultProps,
};

ViewStatisticsNodeDatetimePanel.propTypes = {
  ...ViewStatisticsNodePanel.propTypes,
};

export default ViewStatisticsNodeDatetimePanel;