import React from 'react';
import PropTypes from 'prop-types';
import {Modal, Checkbox, Row, Col} from 'antd';

import {getNodeDisplayTitle, NODE_TYPE_TEXT} from '@/constants/vis.defaultDefine.1';

import ViewDataProvider from '@/components/common/dataProvider/common.dataProvider.view';
import {showErrorMessage} from "@/components/common/common.message";

class ExploreExpandWordByNodeModal extends React.PureComponent {
  state = {
    savingStatus: 'idle',
    wordsToSave: [],
  };

  afterModalClose = () => {
    this.setState({
      savingStatus: 'idle',
      wordsToSave: [],
    });
  };

  saveWordsToView = () => {
    let me = this;

    me.setState({savingStatus: 'processing'}, () => {
      me.props.viewDataProvider.addTextNodes(
        me.state.wordsToSave.map(wordIdx => ({
          fname: me.props.wordGroups[Math.floor(wordIdx / 1000)][wordIdx % 1000],
          label: me.props.wordGroups[Math.floor(wordIdx / 1000)][wordIdx % 1000],
          type: NODE_TYPE_TEXT,
          owner: 0,
          forceAdd: true,
        })),
        me.props.targetNode.id
      ).then(() => {
        me.setState({savingStatus: 'succeeded'}, me.props.onClose);
      }).catch(({code, msg}) => {
        me.setState({savingStatus: 'failed'});
        showErrorMessage({code, msg});
      });
    });
  };

  render() {
    let me = this;

    return (
      <Modal
        title={`左右组词联想${me.props.targetNode ? `（${getNodeDisplayTitle(me.props.targetNode, 15)}）` : ''}`}
        visible={me.props.visible}
        onCancel={me.props.onClose}
        afterClose={me.afterModalClose}
        closable={me.state.savingStatus !== 'processing'}
        centered={true}
        cancelText={'取消'}
        okText={'加入图谱'}
        width={'calc(30rem + 48px)'}
        okButtonProps={{
          disabled: me.state.wordsToSave.length <= 0,
          loading: me.state.savingStatus === 'processing',
        }}
        onOk={me.saveWordsToView}
        bodyStyle={{lineHeight: 1}}
      >
        <span style={{fontWeight: 'bold', paddingBottom: '20px', display: 'inline-block'}}>
          为您找到下列词语，可勾选保存至图谱：
        </span>
        <Checkbox.Group
          style={{width: '100%', marginBottom: '-5px'}}
          value={me.state.wordsToSave}
          onChange={checkedValue => me.setState({wordsToSave: checkedValue})}
        >
          <Row>
            {me.props.wordGroups.reduce((resultList, wordGroup, grpIdx) => {
              let keyword = wordGroup[0];

              for (let i = 1; i < wordGroup.length; i++) {
                let otherWords = wordGroup[i].split(keyword);
                resultList.push(
                  <Col span={12} key={`c-${grpIdx}-${i}`} style={{paddingBottom: '5px'}}>
                    <Checkbox value={grpIdx * 1000 + i}>
                      {otherWords.reduce((spanList, otherWord) => {
                        if (spanList.length > 0) {
                          spanList.push(<span className={'primary-text'} key={`w-${spanList.length}`}>{keyword}</span>);
                        }
                        spanList.push(<span key={`w-${spanList.length}`}>{otherWord}</span>);
                        return spanList;
                      }, [])}
                    </Checkbox>
                  </Col>
                );
              }

              return resultList;
            }, [])}
          </Row>
        </Checkbox.Group>
      </Modal>
    );
  }
}

ExploreExpandWordByNodeModal.propTypes = {
  targetNode: PropTypes.object,
  wordGroups: PropTypes.array.isRequired,
  viewDataProvider: PropTypes.instanceOf(ViewDataProvider).isRequired,
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ExploreExpandWordByNodeModal;