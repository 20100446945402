import _ from 'lodash';

export default function (d) {
  let h = 0;
  let i = 0;
  let j = 0;
  let k = 0;
  let l = 0;
  let m = 0;
  let n = 0;
  let o = 0;
  let r = 0;
  let p = 0;
  let s;
  let t = 0;
  let u = 0;
  let v = 0;
  let y = 0;
  let z = 0;
  let S = -1;
  let T = -1;
  let U = -1;
  let bk = "abcdefghijklmnopqrstuvwxyz";
  let bl = "01234567890";
  let bo = 8;
  let bs;
  let bt;
  let bA;
  if (d) {
    h = parseInt(d.length * 4, 10);
    i = d.length;
    let bq = d.replace(/\s+/g, "").split(/\s*/);
    let br = bq.length;
    for (let a = 0; a < br; a++) {
      if (bq[a].match(new RegExp(/[A-Z]/g))) {
        if (S !== -1) {
          if ((S + 1) === a) {
            t++;
          }
        }
        S = a;
        j++
      } else if (bq[a].match(new RegExp(/[a-z]/g))) {
        if (T !== -1) {
          if ((T + 1) === a) {
            u++;
          }
        }
        T = a;
        k++
      } else if (bq[a].match(new RegExp(/[0-9]/g))) {
        if (a > 0 && a < (br - 1)) {
          n++
        }
        if (U !== -1) {
          if ((U + 1) === a) {
            v++;
          }
        }
        U = a;
        l++
      } else if (bq[a].match(new RegExp(/[^a-zA-Z0-9_]/g))) {
        if (a > 0 && a < (br - 1)) {
          n++
        }
        m++
      }
      r = 0;
      for (let b = 0; b < br; b++) {
        if (bq[a].toLowerCase() === bq[b].toLowerCase() && a !== b) {
          r++
        }
      }
      p = Math.max(p, r);
    }
    for (s = 0; s < 23; s++) {
      bs = bk.substring(s, parseInt(s + 3, 10));
      bt = _.chain(bs).split('').reverse().join('').value();
      if (d.toLowerCase().indexOf(bs) !== -1 || d.toLowerCase().indexOf(bt) !== -1) {
        y++;
      }
    }
    for (s = 0; s < 8; s++) {
      bs = bl.substring(s, parseInt(s + 3, 10));
      bt = _.chain(bs).split('').reverse().join('').value();
      if (d.toLowerCase().indexOf(bs) !== -1 || d.toLowerCase().indexOf(bt) !== -1) {
        z++;
      }
    }
    if (j > 0 && j < i) {
      h = parseInt(h + ((i - j) * 2), 10);
    }
    if (k > 0 && k < i) {
      h = parseInt(h + ((i - k) * 2), 10);
    }
    if (l > 0 && l < i) {
      h = parseInt(h + (l * 4), 10);
    }
    if (m > 0) {
      h = parseInt(h + (m * 6), 10);
    }
    if (n > 0) {
      h = parseInt(h + (n * 2), 10);
    }
    if ((k > 0 || j > 0) && m === 0 && l === 0) {
      h = parseInt(h - i, 10);
    }
    if (k === 0 && j === 0 && m === 0 && l > 0) {
      h = parseInt(h - i, 10);
    }
    if (p > 0) {
      h = parseInt(h - (p * p), 10);
    }
    if (t > 0) {
      h = parseInt(h - (t * 2), 10);
    }
    if (u > 0) {
      h = parseInt(h - (u * 2), 10);
    }
    if (v > 0) {
      h = parseInt(h - (v * 2), 10);
    }
    if (y > 0) {
      h = parseInt(h - (y * 3), 10);
    }
    if (z > 0) {
      h = parseInt(h - (z * 3), 10);
    }
    o = 0;
    if (d.length >= bo) {
      bA = 3
    } else {
      bA = 4
    }
    if (o > bA) {
      h = parseInt(h + (o * 2), 10);
    }
    if (h > 100) {
      h = 100
    } else if (h < 0) {
      h = 0
    }
  }
  return h;
};