import React from 'react';
import PropTypes from 'prop-types';
import {Tooltip, Tree} from 'antd';
import addEventListener from 'add-dom-event-listener';

import PB, {SimplePB} from '@/libs/simplePB';

import {getNodeDisplayTitle} from '@/constants/vis.defaultDefine.1';

import Icon from '@/components/common/common.icon';
import ViewDataProvider from '@/components/common/dataProvider/common.dataProvider.view';

import style from '@/style/common/view/common.view.explore.less';

class CandidateNodeTree extends React.PureComponent {
  allNodeKeys = {};

  treeData = [];

  constructor(props) {
    super(props);

    this.calculateTreeData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.calculateTreeData();
  }

  /**
   * 生成树形数据结构，仅生成两层结构
   */
  calculateTreeData = () => {
    let me = this;

    // 获取中心节点相关联的边信息
    let rootEdges = me.props.viewDataProvider.getEdge(
      me.props.viewDataProvider.getConnectedEdgeIds(me.props.relatedTo.id));

    me.treeData = [];
    // 生成第一层
    rootEdges.forEach(edge => {
      if (edge.meta && ((edge.meta.relatedTo || edge.meta.source) === me.props.relatedTo.id)) {
        let targetNodeId = edge.from === me.props.relatedTo.id ? edge.to : edge.from;
        let targetNode = me.props.allNodeMap[targetNodeId];
        if (targetNode) {
          // 保存节点对应树叶ID
          me.allNodeKeys[targetNodeId] = [`root/${targetNodeId}`];
          me.treeData.push({
            node: me.props.allNodeMap[targetNodeId],
            children: [],
          });
        }
      }
    });

    // 生成第二层
    me.treeData.forEach(p => {
      let connectedNodeIds = me.props.viewDataProvider.getConnectedNodeIds(p.node.id);
      connectedNodeIds.forEach(nodeId => {
        let targetNode = me.props.allNodeMap[nodeId];
        if (targetNode) {
          if (targetNode.fname && targetNode.fname !== ' ') {
            // 保存节点对应树叶ID
            me.allNodeKeys[nodeId] = [...(me.allNodeKeys[nodeId] || []), `root/${p.node.id}/${nodeId}`];
            p.children.push({node: me.props.allNodeMap[nodeId]});
          } else {
            // 获取下一层数据
            let connectedSubNodeIds = me.props.viewDataProvider.getConnectedNodeIds(targetNode.id);
            connectedSubNodeIds.forEach(nodeId => {
              if (nodeId === p.node.id) return;
              let targetSubNode = me.props.allNodeMap[nodeId];
              if (targetSubNode && targetSubNode.fname && targetSubNode.fname !== ' ') {
                // 保存节点对应树叶ID
                me.allNodeKeys[nodeId] = [...(me.allNodeKeys[nodeId] || []), `root/${p.node.id}/${nodeId}`];
                p.children.push({node: me.props.allNodeMap[nodeId]});
              }
            });
          }
        }
      });
    });
  };

  renderTreeNode = (parentId, children) => children.map(item => {
    let title = item.node.description ? (
      <Tooltip
        key={'tip-' + parentId + '/' + item.node.id}
        placement={'left'}
        align={{offset: [-48, 0]}}
        title={(
          <span
            ref={ele => {
              if (ele) {
                addEventListener(ele.parentElement.parentElement.parentElement, 'click', e => e.stopPropagation());
                addEventListener(ele.parentElement.parentElement.parentElement, 'mouseUp', e => e.stopPropagation());
              }
            }}
          >
            {
              item.node.description.split('\n').map((line, idx) => (
                <span key={`ln-${idx}`}>{line}<br /></span>
              ))
            }
          </span>
        )}
        mouseLeaveDelay={0.05}
        overlayClassName={'light-theme'}
      >
        <span style={{width: '100%'}}>{getNodeDisplayTitle(item.node)}</span>
      </Tooltip>
    ) : (
      <span>{getNodeDisplayTitle(item.node)}</span>
    );
    return item.children && item.children.length > 0 ? (
      <Tree.TreeNode
        title={title}
        key={parentId + '/' + item.node.id}
        dataRef={item}
        checkable={this.props.savedNodeMap[item.node.id] === undefined}
        className={this.props.savedNodeMap[item.node.id] === undefined ? '' : style['uncheckable-tree-node']}
        icon={
          this.props.removedNodeMap[item.node.id] ? (
            <Icon name={'stop'}/>
          ) : (
            this.props.savedNodeMap[item.node.id] === undefined ? null : (
              <Icon name={this.props.savedNodeMap[item.node.id] !== false ? 'check-circle' : 'loading'}/>
            )
          )
        }
      >
        {this.renderTreeNode(parentId + '/' + item.node.id, item.children)}
      </Tree.TreeNode>
    ) : (
      <Tree.TreeNode
        title={title}
        key={parentId + '/' + item.node.id}
        dataRef={item}
        checkable={this.props.savedNodeMap[item.node.id] === undefined}
        className={this.props.savedNodeMap[item.node.id] === undefined ? '' : style['uncheckable-tree-node']}
        icon={
          this.props.removedNodeMap[item.node.id] ? (
            <Icon name={'stop'}/>
          ) : (
            this.props.savedNodeMap[item.node.id] === undefined ? null : (
              <Icon name={this.props.savedNodeMap[item.node.id] !== false ? 'check-circle' : 'loading'}/>
            )
          )
        }
      />
    );
  });

  onCheck = ({checked}) => {
    let me = this;

    let node = me.props.viewDataProvider.getNode(checked[0].split('/').pop());

    me.props.onSaveNode(node, {target: {checked: true}});
  };

  render() {
    return (
      <Tree
        checkable={true}
        selectable={false}
        checkStrictly={true}
        onCheck={this.onCheck}
        checkedKeys={[]}
        showIcon={true}
        className={style['candidate-node-tree']}
      >
        {this.renderTreeNode('root', this.treeData)}
      </Tree>
    );
  }
}

CandidateNodeTree.defaultProps = {
  bus: PB,
};

CandidateNodeTree.propTypes = {
  bus: PropTypes.instanceOf(SimplePB),
  nodeIds: PropTypes.array.isRequired,
  relatedTo: PropTypes.object.isRequired,
  allNodeMap: PropTypes.object.isRequired,
  savedNodeMap: PropTypes.object.isRequired,
  removedNodeMap: PropTypes.object.isRequired,
  viewDataProvider: PropTypes.instanceOf(ViewDataProvider).isRequired,
  updateTicket: PropTypes.number.isRequired,
  onSaveNode: PropTypes.func.isRequired,
};

export default CandidateNodeTree;