import React from 'react';
import PropTypes from 'prop-types';
import {Button, Modal, Progress} from "antd";

import ViewManager from '@/components/common/common.viewManager';
import UserDataProvider from "@/components/common/dataProvider/common.dataProvider.user";

import style from '@/style/components/accountCenter/account.mergeAccountModal.less';

const progressText = [
  {
    before: '等待合并图谱数据',
    doing: '正在合并图谱数据',
    done: '图谱数据合并成功',
    failed: '图谱数据合并失败',
  },
  {
    before: '等待合并交易数据',
    doing: '正在合并交易数据',
    done: '交易数据合并成功',
    failed: '交易数据合并失败',
  },
  {
    before: '等待合并其他数据',
    doing: '正在合并其他数据',
    done: '其他数据合并成功',
    failed: '其他数据合并失败',
  },
];

class MergeAccountModal extends React.Component {
  state = {
    status: 'idle',
    step: 0,
    progressInfoList: [
      {content: progressText[0].before, percent: 0},
      {content: progressText[1].before, percent: 0},
      {content: progressText[2].before, percent: 0},
    ],
  };

  accessToken = undefined;

  fakeProgressTimeInterval = -1;

  progressFn = [
    ViewManager.moveViewInfoByAccessToken,
    ViewManager.moveTransInfoByAccessToken,
    UserDataProvider.moveUserInfoByAccessToken,
  ];

  onRetry = () => {
    let me = this;

    me.doProgress(me.state.step);
    me.fakeProgressTimeInterval =
      setInterval(() => me.doFakeProgress(), 1000);
  };

  onSuccess = () => {location.reload()};

  doFakeProgress = () => {
    let me = this;
    if (me.state.status !== 'processing') {
      clearTimeout(me.fakeProgressTimeInterval);
      me.fakeProgressTimeInterval = -1;
      return;
    }
    const progressInfoList = me.state.progressInfoList;
    progressInfoList.forEach(step => {
      if (step.percent > 0 && step.percent < 100) {
        step.percent += Math.min(40, Math.floor((100 - step.percent) / 3));
      }
    });
    me.setState({progressInfoList});
  };

  doProgress = step => {
    let me = this;

    const progressInfoList = me.state.progressInfoList;
    progressInfoList[step].content = progressText[step].doing;
    progressInfoList[step].percent = 1;

    me.setState({
      status: 'processing',
      step,
      progressInfoList,
    }, () => {
      me.progressFn[step](me.accessToken).then(data => {
        progressInfoList[step].content = progressText[step].done;
        progressInfoList[step].percent = 100;
        me.setState({
          progressInfoList,
        }, () => {
          step++;
          if (step === me.progressFn.length) {
            // 操作完成
            me.accessToken = undefined;
            me.setState({
              status: 'success',
              step,
            });
          } else {
            // 执行下一步
            requestAnimationFrame(() => {
              me.accessToken = data.accessToken;
              me.doProgress(step);
            });
          }
        });
      }).catch(({code, msg, data}) => {
        if (data) me.accessToken = data.accessToken;
        progressInfoList[step].content = progressText[step].failed;
        progressInfoList[step].percent = 0;
        me.setState({
          status: 'failed',
          progressInfoList,
        });
      });
    });
  };

  componentDidMount() {
    if (this.props.visible && !this.accessToken && this.props.accessToken) {
      this.accessToken = this.props.accessToken;
      this.onRetry();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.visible && !prevProps.visible) {
      if (!this.accessToken && this.props.accessToken) {
        this.accessToken = this.props.accessToken;
        this.onRetry();
      }
    }
  }

  render() {
    let me = this;

    return (
      <Modal
        title={'账号合并'}
        closable={false}
        maskClosable={false}
        visible={me.props.visible}
        centered={true}
        footer={
          (me.state.status === 'idle' || me.state.status === 'processing') ? (
            [<Button disabled={true}>合并中，请稍后……</Button>]
          ) : (
            me.state.status === 'success' ? (
              [<Button onClick={me.onSuccess} type={'success'}>完成并刷新页面</Button>]
            ) : (
              [
                <Button onClick={me.props.onClose}>取消</Button>,
                <Button onClick={me.onRetry} type={'primary'}>立即重试</Button>,
              ]
            )
          )
        }
      >
        <ul className={style['progress-list']}>
          {me.state.progressInfoList.map(info => (
            <li key={`step-${info.step}`}>
              <Progress
                type={'circle'}
                percent={info.percent}
                style={{marginRight: '0.5rem'}}
                width={'3rem'}
              />
              {info.content}
            </li>
          ))}
        </ul>
      </Modal>
    );
  }
}

MergeAccountModal.defaultProps = {
  accessToken: undefined,
};

MergeAccountModal.propTypes = {
  accessToken: PropTypes.string,
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default MergeAccountModal;