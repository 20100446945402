/*
 * @Author: Carr.s
 * @Date: 2022-07-15 09:05:22
 * @LastEditTime: 2022-07-22 16:33:00
 * @LastEditors: Carr.s
 * @Description: 个人待办中心 timeline 视图
 * @Copyright by joinmap
 */
import React from "react";
import ReactDOM from "react-dom";
import PropTypes, { node } from "prop-types";
import { renderToString } from "react-dom/server";
import { Timeline, DataSet } from "vis";
import { message, Tooltip, Button, Divider, Modal } from "antd";
// import axios from "axios";
import moment from "moment";
import momentLocale from "moment/locale/zh-cn";

import styles from "@/style/components/todoView/todo.timeline.less";

import { IconFont } from "@/components/common/common.fonticon";
// import { IconTypes } from "@/constants/common";

import PB, { SimplePB } from "@/libs/simplePB";
import NodeDataProvider from "@/components/common/dataProvider/common.dataProvider.node";
import ViewDataProvider from "@/components/common/dataProvider/common.dataProvider.view";
import MainAiConsoleMessageCommonNodeInfo from "@/components/mainView/aiConsoleMessage/main.aiConsoleMessage.commonNodeInfo";
import UserNickAvatar from "@/components/common/objects/common.userAvatar";

// import NodeInfoCard from "@/components/relationView/relation.nodeInfoCard";
import TimelineTodoItemCard from "@/components/todoView/timeline.nodeInfo.card";
import TodoInfoCard from "@/components/todoView/todo.info.aiConsoleCard";

import NodeTodoLogic from "@/components/common/node/todo/common.node.todo.logic";
import NodeTodoListModal from "@/components/common/node/todo/common.node.todo.listModal";
import NodeTodoDetailModal from "@/components/common/node/todo/common.node.todo.detailModal";
import NodeTodoAssignToModal from "@/components/common/node/todo/common.node.todo.assignToModal";
import NodeTodoStatusModal from "@/components/common/node/todo/common.node.todo.statusModal";

// moment 使用中文
moment.updateLocale("zh-cn", momentLocale);

// 任务常量值
const xpriorityText = ["一般", "紧急", "特急"];
const xstatusText = ["--", "待办", "已办", "办结", "撤办"];
const xstatusTextTips = [
  "--",
  <span>任务将重置，确定要重新发起此任务吗？</span>,
  <span>任务确定已经完成了吗？</span>,
  <span>
    任务确实已经完成了吗？ <br />
    （如果需要的话，办结后还可以再次设置任务）
  </span>,
  <span>任务确实不需要了吗？撤办后任务记录将消失！</span>,
];

const xstatusFilter = [
  ["not-status-4", "全部"],
  ["status-1", "待办"],
  ["status-2", "已办"],
  ["status-3", "办结"],
];

const xrelationFilter = {
  all: "全部",
  related_to: "与我相关",
  user_id: "由我创建",
  assign_to: "指派给我",
};

const xdetailModalActionList = {
  create: "创建待办事项",
  update: "修改待办事项",
  view: "查看待办事项",
};

class TodoTimelineView extends React.PureComponent {
  state = {
    // =====================================================
    // task 的多个弹框的显隐状态
    showListModal: false, // 待办列表弹窗显隐
    showViewTodo: false, // 图谱内的 node task
    showDetailModal: false, // 显示 node task 的详情 modal
    detailModalAction: "update", // 对 node task 的操作 create、update、view
    showAssignToModal: false, // 显示 修改 todo assignTo 的 modal
    showStatusModal: false, // 显示 修改 todo status 的 modal

    // =====================================================
    // 加载task列表
    processing: false, // 正在加载
    statusFilter: "not-status-4", // task 状态条件值
    relationFilter: "related_to", // 发送方 字段名称，
    pagination: {
      current: 1,
      pageSize: 50,
    }, // 记录分页数据
    orderBy: {
      field: "update_timestamp",
      order: "desc",
    }, // 记录排序条件,sorter
    // selectedFilterBarIdx: undefined, // 选中的筛选项序号
    selectedFilterBarIdx: 1, // 选中的筛选项序号，默认打开未办的待办事项

    // 待定
    userInfo: {}, // 当前登录用户信息
    viewInfo: undefined, // 图谱的info
  };
  // 正在被操作的timeline节点
  operateItem = undefined;
  // 点击的timeline节点
  clickedItem = undefined;
  // clickedItem 对应的节点
  clickedNode = undefined;
  // timeline
  myTimeline = undefined;
  nodes = new DataSet();
  groups = new DataSet();
  memberList = {}; // 任务列表内涉及的用户信息// 协作成员，新建、编辑时选择成员
  memberListArr = []; // 上面对象value的 array
  // ai消息的key与node id的关联
  aiMessageKeys = {};

  // 筛选条件
  filterConfigs = [
    // {
    //   tooltipTxt: "系统内所有待办",
    //   icon: <IconFont type="icon-todo-list" />,
    //   btnTxt: "所有待办",
    //   status: "not-status-4",
    //   relation: "all",
    //   orderBy: {
    //     field: "update_timestamp",
    //     order: "desc",
    //   },
    // },
    // "-",
    // 与我相关
    {
      tooltipTxt: "显示与我相关的全部待办",
      icon: <IconFont type="icon-todo-list" />,
      btnTxt: "【与我相关】【全部】待办",
      status: "not-status-4",
      relation: "related_to",
      orderBy: {
        field: "update_timestamp",
        order: "desc",
      },
    },
    {
      tooltipTxt: "显示与我相关的未完成的待办",
      icon: <IconFont type="icon-todo-list" />,
      btnTxt: "【与我相关】【未办】待办",
      status: "status-1",
      relation: "related_to",
      orderBy: {
        field: "deadline",
        order: undefined,
      },
    },
    {
      tooltipTxt: "显示与我相关的已办完的待办",
      icon: <IconFont type="icon-todo-list" />,
      btnTxt: "【与我相关】【已办】待办",
      status: "status-2",
      relation: "related_to",
      orderBy: {
        field: "update_timestamp",
        order: "desc",
      },
    },
    {
      tooltipTxt: "显示与我相关的已办结的待办",
      icon: <IconFont type="icon-todo-list" />,
      btnTxt: "【与我相关】【办结】待办",
      status: "status-3",
      relation: "related_to",
      orderBy: {
        field: "update_timestamp",
        order: "desc",
      },
    },
    "-",
    // 我发起的
    {
      tooltipTxt: "显示我发起的的全部待办",
      icon: <IconFont type="icon-todo-list" />,
      btnTxt: "【我发起的】【全部】待办",
      status: "not-status-4",
      relation: "user_id",
      orderBy: {
        field: "update_timestamp",
        order: "desc",
      },
    },
    {
      tooltipTxt: "显示我发起的未完成的待办",
      icon: <IconFont type="icon-todo-list" />,
      btnTxt: "【我发起的】【未办】待办",
      status: "status-1",
      relation: "user_id",
      orderBy: {
        field: "deadline",
        order: undefined,
      },
    },
    {
      tooltipTxt: "显示我发起的已办完的待办",
      icon: <IconFont type="icon-todo-list" />,
      btnTxt: "【我发起的】【已办】待办",
      status: "status-2",
      relation: "user_id",
      orderBy: {
        field: "update_timestamp",
        order: "desc",
      },
    },
    {
      tooltipTxt: "显示我发起的已办结的待办",
      icon: <IconFont type="icon-todo-list" />,
      btnTxt: "【我发起的】【办结】待办",
      status: "status-3",
      relation: "user_id",
      orderBy: {
        field: "update_timestamp",
        order: "desc",
      },
    },
    "-",
    // 指派给我的
    {
      tooltipTxt: "显示指派给我的全部待办",
      icon: <IconFont type="icon-todo-list" />,
      btnTxt: "【指派给我】【全部】待办",
      status: "not-status-4",
      relation: "assign_to",
      orderBy: {
        field: "update_timestamp",
        order: "desc",
      },
    },
    {
      tooltipTxt: "显示指派给我的未完成的待办",
      icon: <IconFont type="icon-todo-list" />,
      btnTxt: "【指派给我】【未办】待办",
      status: "status-1",
      relation: "assign_to",
      orderBy: {
        field: "deadline",
        order: undefined,
      },
    },
    {
      tooltipTxt: "显示指派给我的已办完的待办",
      icon: <IconFont type="icon-todo-list" />,
      btnTxt: "【指派给我】【已办】待办",
      status: "status-2",
      relation: "assign_to",
      orderBy: {
        field: "update_timestamp",
        order: "desc",
      },
    },
    {
      tooltipTxt: "显示指派给我的已办结的待办",
      icon: <IconFont type="icon-todo-list" />,
      btnTxt: "【指派给我】【办结】待办",
      status: "status-3",
      relation: "assign_to",
      orderBy: {
        field: "update_timestamp",
        order: "desc",
      },
    },
  ];

  /**
   * 切换视图查询条件
   * @param {int} filterConfigIdx filter config 的序号
   * @param {int} forceReload 是否重载，timeline会重新画图，自动缩放
   */
  changeFilter = (
    filterConfigIdx,
    forceReload = true,
    callback = undefined
  ) => {
    let me = this;
    if (filterConfigIdx === undefined) {
      return;
    }
    let filter = me.filterConfigs[filterConfigIdx];

    me.setState(
      {
        selectedFilterBarIdx: filterConfigIdx,
        statusFilter: filter.status,
        relationFilter: filter.relation,
        orderBy: filter.orderBy,
        pagination: {
          current: 1,
          pageSize: 50,
        },
      },
      () => {
        // 读取todo列表
        me.fetchTodoList((result) => {
          callback && callback(result);
        }, forceReload);
      }
    );
  };

  fetchTodoList = (
    callback = undefined,
    forceReload = true,
    conditions = undefined
  ) => {
    // 查询条件默认值
    let _conditions = {};
    // _conditions["related_to"] = this.props.currentUserId;
    // _conditions["status"] = 1;
    // _conditions["limit"] = 100;
    // _conditions["start"] = 0;
    // _conditions["orderBy"] = "update_timestamp";
    // _conditions["orderType"] = "desc";
    // _conditions["status_not"] = 4;

    // 判断查询条件
    let me = this;
    const { currentUserId, query } = this.props;
    const { showViewTodo, relationFilter, statusFilter, pagination, orderBy } =
      this.state;

    if (pagination) {
      _conditions["start"] = (pagination.current - 1) * pagination.pageSize;
      _conditions["limit"] = pagination.pageSize;
    }
    if (orderBy && orderBy.field) {
      _conditions["orderBy"] = orderBy.field;
      _conditions["orderType"] = orderBy.order;
    }

    if (relationFilter !== "all") {
      _conditions[relationFilter] = currentUserId;
    }

    // 判断 timeline group 的字段名
    let group_field = "userId";
    if (relationFilter == "user_id") {
      group_field = "assignTo";
    }

    if (statusFilter !== "not-status-4") {
      _conditions["status"] = parseInt(statusFilter.substr(7));
    } else {
      _conditions["status_not"] = 4;
    }

    if (showViewTodo) {
      if (query.viewId) {
        _conditions["view_id"] = query.viewId;
      } else {
        message.warning("没有设置图谱,请从图谱页面再进入待办中心");
        return false;
      }
    }

    if (conditions) {
      _conditions = conditions;
    }

    console.log(
      "🚀 ~ file: todo.timeline.js ~ line 349 ~ TodoTimelineView ~ _conditions",
      _conditions
    );
    me.setState({ processing: true }, () => {
      message.destroy();
      // 加载 task 数据
      NodeDataProvider.loadNodeTaskList(_conditions)
        .then(({ data, total, userInfo }) => {
          console.log(
            "🚀 ~ file: todo.timeline.js ~ line 355 ~ TodoTimelineView ~ .then ~ total",
            total
          );
          if (data.length == 0) {
            message.warning(
              "您还没有待办事项，您可以前往图谱中选择节点创建待办！",
              0
            );
          }
          // 保存任务列表内涉及的用户信息
          me.memberList = userInfo;
          me.memberListArr = Object.values(me.memberList);
          // 数据
          let temp_nodes = [];
          let temp_groups = [];
          let temp_groups_ids = [];

          // 当月时间
          let temp_start_time = new Date();
          let temp_end_time = new Date();
          data.forEach((t, idx) => {
            if (t.createTimestamp && t.deadline) {
              // timeline node
              let temp = {
                id: t.nodeTaskId,
                type: "range",
                group: t[group_field],
                content: "" + t.vrDisplayText,
                start: t.createTimestamp,
                end: t.deadline,
                data: t,
              };

              let start = new Date(t.createTimestamp);
              if (temp_start_time > start) {
                temp_start_time = start;
              }

              let end = new Date(t.deadline);
              if (temp_end_time < end) {
                temp_end_time = end;
              }

              temp_nodes.push(temp);
            }

            // timeline group
            if (temp_groups_ids.indexOf(t.userId) === -1) {
              temp_groups.push({
                id: t[group_field],
                content:
                  me.memberList[t[group_field]].name ||
                  me.memberList[t[group_field]].nick,
              });
              temp_groups_ids.push(t[group_field]);
            }
          });
          // 重设 timeline 两侧时间
          let _day = temp_start_time.getDate();
          temp_start_time.setDate(_day - 7);
          _day = temp_end_time.getDate();
          temp_end_time.setDate(_day + 7);
          // 画图
          me.nodes.clear();
          me.nodes.update(temp_nodes);
          me.groups.clear();
          me.groups.update(temp_groups);

          if (forceReload) {
            me.myTimeline.fit();
            me.myTimeline.setOptions({
              start: temp_start_time,
              end: temp_end_time,
            });
          }
          me.myTimeline.redraw();
          callback && callback(true);
        })
        .catch((e) => {
          console.error(
            "🚀 ~ file: todo.timeline.js ~ line 473 ~ TodoTimelineView ~ me.setState ~ e",
            e
          );
          callback && callback(false);
        });
    });
  };

  fetchNodeInfo = (item, callback = undefined) => {
    let me = this;
    me.props.bus.emit("aiConsole", "message.push", {
      type: "ai", // user ai
      content: (
        <span>
          <IconFont name={"loading"} style={{ marginRight: "0.5em" }} />
          读取待办节点信息...
        </span>
      ),
      callback: ({ key }) => {
        me.aiMessageKeys[item.data.nodeId] = key;
        NodeDataProvider.loadNodeDetail(item.data.viewId, 0, item.data.nodeId)
          .then((node) => {
            console.log(
              " Relation.timeline -> click -> loadNodeDetail node:",
              node
            );
            me.clickedNode = node;

            // 节点ai信息卡片
            me.props.bus.emit("aiConsole", "message.patch", {
              key: key,
              type: "ai",
              content: (
                <MainAiConsoleMessageCommonNodeInfo
                  bus={me.props.bus}
                  node={node}
                  originalViewId={item.data.viewId}
                  recommendation={[]}
                  viewDataProvider={ViewDataProvider}
                  // viewInfo={me.props.viewInfo}
                />
              ),
              actions: [
                <span style={{ float: "right" }}>
                  <Button
                    onClick={() => {
                      window.open(
                        `/mainview/relation/${item.data.viewId}`,
                        "blank_"
                      );
                    }}
                  >
                    打开图谱
                    <IconFont
                      type="icon-move-right"
                      style={{ marginLeft: 5, marginRight: 3 }}
                    />
                  </Button>
                </span>,
              ],
            });

            callback && callback();
          })
          .catch((e) => {
            console.log(
              "🚀 ~ file: todo.timeline.js ~ line 463 ~ TodoTimelineView ~ e",
              e
            );
            let eMsg = "";
            if (e.code === 10003) {
              eMsg = ": " + e.msg;
            }
            me.clickedNode = undefined;
            me.props.bus.emit("aiConsole", "message.patch", {
              key: key,
              content: "读取待办信息出错" + eMsg,
            });
          });
      },
      delay: 200,
    });
  };

  /**
   * 加载待办的详情，(用于修改过待办状态后重新刷新节点)
   * @param {*} nodeTaskId 待办id，不是node id
   * @param {*} callback
   */
  fetchTodoInfo = (nodeTaskId, callback = undefined) => {
    NodeDataProvider.loadNodeTask(nodeTaskId)
      .then((res) => {
        let data = res.data;
        let item = {
          id: data.nodeTaskId,
          type: "range",
          group: data.userId,
          content: "" + data.vrDisplayText,
          start: data.assignTimestamp,
          end: data.deadline,
          data: data,
        };

        callback && callback(item);
      })
      .catch((e) => {
        console.log(
          "🚀 ~ file: todo.timeline.js ~ line 567 ~ TodoTimelineView ~ e",
          e
        );
        callback && callback(false);
      });
  };

  /**
   * 编辑待办
   * @param {*} item timeline item
   * @param {*} action 操作方式
   */
  todoAction = (item, action) => {
    if (action == "change-status") {
      this.operateItem = item;
      this.setState({
        showStatusModal: true,
      });

      return true;
    }
    if (action == "change-assignto") {
      this.operateItem = item;
      this.setState({
        showAssignToModal: true,
      });

      return true;
    }
    this.operateItem = item;
    this.setState({
      showDetailModal: true,
      detailModalAction: action,
    });
  };

  refresh = (callback = undefined) => {
    let me = this;
    if (this.state.selectedFilterBarIdx == undefined) {
      this.fetchTodoList(() => {}, true, {
        related_to: me.props.currentUserId,
        status: 1,
        limit: me.props.query.limit ? me.props.query.limit : 0,
        start: 0,
      });
      return;
    }

    this.changeFilter(this.state.selectedFilterBarIdx, false, callback);
  };

  /**
   * (仅仅是)发出 aiconsole 消息
   * @param {*} item 待办详情
   * @param {*} act push or update aiConsole
   * @param {*} callback
   */
  itemAiMessage = (item, act = "push", callback = undefined) => {
    console.log(
      "🚀 ~ file: todo.timeline.js ~ line 617 ~ TodoTimelineView ~ item",
      item
    );
    let me = this;
    let key = undefined;
    if (act == "update") {
      key = this.aiMessageKeys[item.id];
    }
    me.props.bus.emit("aiConsole", "message." + act, {
      key: key,
      type: "ai",
      content: (
        <TodoInfoCard
          item={item}
          curUserId={me.props.currentUserId}
          action={me.todoAction}
          memberInfoList={me.memberList}
        />
      ),
      actions: [
        <span key={"act1-" + item.id} style={{ float: "right" }}>
          <Button
            onClick={() => {
              // 读取待办节点
              me.fetchNodeInfo(item);
            }}
          >
            查看任务节点
            <IconFont
              type="icon-move-right"
              style={{ marginLeft: 5, marginRight: 3 }}
            />
          </Button>
        </span>,
        <Tooltip
          key={"act2-" + item.id}
          placement={"bottom"}
          title={"编辑待办"}
        >
          <Button
            onClick={() => {
              me.todoAction(item, "update");
            }}
            disabled={
              me.props.currentUserId == item.data.userId ? "" : "disabled"
            }
          >
            <IconFont type="icon-edit1" />
          </Button>
        </Tooltip>,
        <Tooltip
          key={"act3-" + item.id}
          placement={"bottom"}
          title={"更改待办状态"}
        >
          <Button
            onClick={() => {
              me.todoAction(item, "change-status");
            }}
            disabled={
              [item.data.userId, item.data.assignTo].indexOf(
                me.props.currentUserId
              ) > -1
                ? false
                : true
            }
          >
            <IconFont type={"icon-icon_Readytopick"} />
          </Button>
        </Tooltip>,
      ],
      callback: callback,
    });

    // 指派按钮，暂时保留不放
    let xxx = (
      <Tooltip key={"act4-" + item.id} placement={"bottom"} title={"重新指派"}>
        <Button
          onClick={() => {
            me.todoAction(item, "change-assignto");
          }}
          disabled={me.props.currentUserId == item.data.userId ? false : true}
        >
          <IconFont type={"icon-yaoqing"} />
        </Button>
      </Tooltip>
    );
  };

  componentDidMount() {
    console.log(
      "🚀 ~ file: task.timeline.js ~ line 120 ~ TodoTimelineView ~ componentDidMount ~ this.props= ",
      this.props
    );

    let me = this;
    // =====================================================================
    // 绑定各个modal的显隐

    // todo detail Modal
    me.props.bus.sub(
      me,
      "node_todo",
      "detailModal.do_show",
      ({ action, task, userInfo }) => {
        let cb = () => {
          if (action !== "view") {
            // 加载图谱成员
            me.props.bus.emit("node_todo", "memberList.do_load", {
              viewId: task["viewId"],
              cb: (memberList) => {
                if (memberList) me.setState({ memberList });
              },
            });
          }
        };
        me.setState(
          {
            showDetailModal: true,
            taskInfo: task,
            memberList: userInfo ? [userInfo] : [],
            detailModalAction: action,
          },
          cb
        );
      }
    );

    // 指派 Modal
    me.props.bus.sub(
      me,
      "node_todo",
      "assignToModal.do_show",
      ({ task, userInfo }) => {
        let cb = () => {
          // 加载图谱成员
          me.props.bus.emit("node_todo", "memberList.do_load", {
            viewId: task["viewId"],
            cb: (memberList) => {
              if (memberList) me.setState({ memberList });
            },
          });
        };
        me.setState(
          { showAssignToModal: true, taskInfo: task, memberList: [userInfo] },
          cb
        );
      }
    );

    // 无用
    me.props.bus.sub(me, "view", "info.loaded", ({ viewInfo }) => {
      me.setState({ viewInfo });
    });

    // =====================================================================
    // 加载待办事项

    // todo 更新后的响应 （timeline 不自适应）
    me.props.bus.sub(
      me,
      "node_todo",
      ["todo.updated", "todo.patched", "todo.completed"],
      ({ nodeTaskId }) => {
        // 刷新aiconsole
        me.fetchTodoInfo(nodeTaskId, (item) => {
          if (item) {
            me.operateItem = item;
            message.success("待办更新成功！");
            // ai 待办信息
            me.itemAiMessage(item, "update", (key) => {
              console.log(
                "🚀 ~ file: todo.timeline.js ~ line 781 ~ TodoTimelineView ~ me.itemAiMessage ~ key",
                key
              );
            });
          } else {
            me.props.bus.emit("aiConsole", "message.push", {
              type: "ai", // user ai
              content: (
                <span>
                  <IconFont
                    name={"icon-error-r"}
                    style={{ marginRight: "0.5em" }}
                  />
                  读取待办信息出错,请重试
                </span>
              ),
            });
          }

          // 刷新 timeline nodes
          me.refresh();
        });
      }
    );

    // =====================================================================
    // 画图 vis Timeline
    // =====================================================================
    const vw = document.body.clientWidth;
    const vh = document.body.clientHeight;

    // 当月时间
    let now = new Date();
    let now_day = now.getDate();

    let tomorrow = new Date();
    tomorrow.setDate(now_day + 30);

    let before = new Date();
    before.setDate(now_day - 30);

    // create Timeline
    var container = document.getElementById("vis_timeline");
    var options = {
      width: vw - 220 + "px",
      height: "98%",
      type: "box",
      selectable: true,
      // zoomMin: 30 * 60 * 1000,
      min: new Date(2015, 1, 1),
      max: new Date(2030, 12, 31),
      start: before,
      end: tomorrow,
      showCurrentTime: true,
      groupTemplate: (item, element) => {
        return ReactDOM.render(
          <UserNickAvatar
            className={styles["user"]}
            // userId={item.group}
            user={me.memberList[item.id]}
            avatarSize="1rem"
          />,
          element
        );
      },

      template: (item, element) => {
        // return TimelineTodoItemCard({ item });
        return ReactDOM.render(
          <TimelineTodoItemCard
            item={item}
            curUserId={me.props.currentUserId}
            action={me.todoAction}
            memberInfoList={me.memberList}
          />,
          element
        );
      },
      tooltip: {
        followMouse: true,
      },
      format: {
        minorLabels: {
          millisecond: "SSS",
          second: "MM:ss",
          minute: "HH:mm",
          hour: "HH:mm",
          weekday: "D日",
          day: "D日",
          week: "第w周",
          month: "M月",
          year: "YYYY年",
        },
        majorLabels: {
          millisecond: "HH:mm:ss",
          second: "D日 HH时",
          minute: "M月DD日",
          hour: "M月DD日",
          weekday: "YYYY年MM月",
          day: "YYYY年MM月",
          week: "YYYY年MM月",
          month: "YYYY年",
          year: "",
        },
      },
    };

    this.myTimeline = new Timeline(container, this.nodes, this.groups, options);

    // 单击显示对应节点信息
    this.myTimeline.on("click", function (properties) {
      if (properties.item) {
        // timeline 选中节点
        me.myTimeline.setSelection(properties.item, {
          focus: false,
          animation: true,
        });
        let item = me.nodes.get(properties.item);
        me.clickedItem = me.nodes.get(properties.item);
        me.operateItem = me.clickedItem;

        // ai 待办信息

        me.itemAiMessage(me.operateItem, "push", ({ key }) => {
          me.aiMessageKeys[properties.item] = key;
        });
      } else {
        me.clickedItem = undefined;
        me.clickedNode = undefined;
      }
    });

    // =====================================================================
    // 初次加载 task list
    this.changeFilter(this.state.selectedFilterBarIdx, true, () => {
      setTimeout(() => {
        me.myTimeline.redraw();
      }, 500);
    });
  }

  componentWillUnmount() {
    this.props.bus.remove(this);
  }

  render() {
    let me = this;

    return (
      <>
        <div className={styles.filter_bar}>
          <div style={{ marginLeft: "20px" }}>
            {me.state.showViewTodo ? "图谱待办事项" : "我的待办事项"}
          </div>

          <div className={styles.div_hr} />

          {me.filterConfigs.map((i, idx) => {
            if (i == "-") {
              return <div key={"filter_" + idx} className={styles.div_hr} />;
            }
            return (
              <Tooltip
                key={"filter_" + idx}
                placement={"right"}
                title={i.tooltipTxt}
                overlayClassName={"dark-theme"}
              >
                <Button
                  onClick={() => {
                    me.changeFilter(idx);
                  }}
                  type={me.state.selectedFilterBarIdx === idx ? "primary" : ""}
                  className={styles.btn}
                >
                  {i.btnTxt}
                </Button>
              </Tooltip>
            );
          })}

          <div className={styles.div_hr} />

          {me.props.query.viewId ? (
            <>
              {/* <div className={styles.div_hr} /> */}
              <Tooltip
                placement={"right"}
                title={
                  me.state.showViewTodo
                    ? "打开我的待办事项"
                    : "打开当前图谱的待办"
                }
                overlayClassName={"dark-theme"}
              >
                <Button
                  onClick={() => {
                    me.setState(
                      {
                        showViewTodo: !me.state.showViewTodo,
                        selectedFilterBarIdx: 0,
                      },
                      me.refresh
                    );
                  }}
                >
                  {me.state.showViewTodo
                    ? "切换至【我的待办事项】"
                    : "切换至【当前图谱待办】"}

                  {/* <IconFont
                    type="icon-back-to-list"
                    style={{ fontSize: "14px" }}
                  /> */}
                </Button>
              </Tooltip>
            </>
          ) : (
            ""
          )}

          <Tooltip
            placement={"right"}
            title={"打开列表管理视图"}
            overlayClassName={"dark-theme"}
          >
            <Button
              onClick={() => {
                me.setState({
                  showListModal: !me.state.showListModal,
                });
              }}
            >
              打开【列表视图管理】
            </Button>
          </Tooltip>
        </div>
        <div id="vis_timeline" className={styles.timeline}></div>
        <NodeTodoLogic
          bus={me.props.bus}
          currentUserId={me.props.currentUserId}
        />
        <NodeTodoListModal
          viewInfo={me.state.viewInfo}
          userId={me.props.currentUserId}
          visible={me.state.showListModal}
          showViewTask={me.state.showViewTodo}
          onClose={() => me.setState({ showListModal: false })}
        />
        <NodeTodoDetailModal
          task={me.operateItem ? me.operateItem.data : undefined}
          action={me.state.detailModalAction}
          memberList={me.memberListArr}
          visible={me.state.showDetailModal}
          onClose={() => me.setState({ showDetailModal: false })}
        />
        <NodeTodoAssignToModal
          taskId={me.operateItem ? me.operateItem.data.nodeTaskId : undefined}
          memberList={me.memberListArr}
          visible={me.state.showAssignToModal}
          onClose={() => me.setState({ showAssignToModal: false })}
        />
        <NodeTodoStatusModal
          task={me.operateItem}
          curUserId={me.props.currentUserId}
          visible={me.state.showStatusModal}
          onClose={() => me.setState({ showStatusModal: false })}
        />
      </>
    );
  }
}

TodoTimelineView.defaultProps = {
  bus: PB,
  currentUserId: undefined,
  query: {
    viewId: undefined,
    limit: undefined,
    sortBy: undefined,
    isDev: false,
    isDebug: false,
  },
};
TodoTimelineView.propTypes = {
  bus: PropTypes.instanceOf(SimplePB),
  currentUserId: PropTypes.number,
  query: PropTypes.object,
};
export default TodoTimelineView;
