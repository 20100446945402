/**
 * 用户头像组件
 */
import React from 'react';
import PropTypes from 'prop-types';
import {getNodeIcon} from "../../../constants/vis.defaultDefine.1";
import TimeDisplayField from '@/components/common/common.timeDisplayField';
import Icon from "../common.icon";
import {API_GetNodeInfo} from "../../../libs/view/network/api";
import moment from "moment";
import Highlighter from "react-highlight-words";

export default class NodeAvatar extends React.Component {
  state = {
    // 节点信息
    nodeInfo: null
  }
  
  componentDidMount() {
    let me = this;
    // 判断是否要读取用户信息
    const {node, nodeId, viewId, nodeType = 0} = this.props;
    if (!node) {
      if (nodeId) {
        API_GetNodeInfo(viewId, nodeType, nodeId).then(response => {
          let res = response.data;
          if (res.hasOwnProperty('code') && res.code === 0) {
            if (!res.data['fname']) {
              console.log('fname 为空 res.data= ', res.data)
            }
            me.setState({
              nodeInfo: res.data
            }, () => {
              // ignore
            })
          } else {
            // 获取看板信息出错
            me.setState({
              nodeInfo: false
            });
          }
        }).catch(e => {
          console.log('NodeAvatar API_GetNodeInfo e: ', e);
          // ignore
        });
      } else {
        // 没有看板信息
        me.setState({
          nodeInfo: false
        });
      }
    } else {
      me.setState({
        nodeInfo: node
      });
    }
    
  }
  
  render() {
    const {
      className,
      style,
      showFname,
      showUpdateTime,
      children,
      renderFun,
      highlightWords,
      highlightClass
    } = this.props;
    const {nodeInfo} = this.state;
    if (!nodeInfo) {
      return (
        <div
          className={className}
          style={style}
        />
      )
    }
    if (renderFun) {
      return renderFun(nodeInfo);
    }
    const {name, type, color} = getNodeIcon(nodeInfo);
    return (
      <div className={className} style={{
        ...{
          display: 'flex',
          justifyContent: 'space-between'
        }, ...style
      }}>
        <div style={{flex: 1, width: 0, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
          <Icon name={name ? name : 'icon-yuandianda'}
                type={type ? type : 1}
                color={color ? color : '#a5a5a5'}
                style={{marginRight: '0.2em'}}
          />
          {nodeInfo && nodeInfo.hasOwnProperty('fname') && showFname ? highlightWords ? <Highlighter
            highlightClassName={highlightClass ? highlightClass : ''}
            searchWords={highlightWords}
            autoEscape={true}
            textToHighlight={nodeInfo['fname'] || ''}
          /> : nodeInfo['fname'] : ''}
        </div>
        {
          nodeInfo && nodeInfo.hasOwnProperty('updateTime') && nodeInfo['updateTime'] && showUpdateTime ?
            <div style={{flex: '0 1 96px', width: 0, marginRight: '0.2em'}}><TimeDisplayField
              timestamp={moment(nodeInfo['updateTime'], 'YYYY-MM-DD HH:mm:ss').valueOf()}/></div> : ''
        }
        {children}
      </div>
    );
  }
}

NodeAvatar.defaultProps = {
  node: undefined,
  showFname: true,
  showUpdateTime: false,
  renderFun: undefined
};

NodeAvatar.propTypes = {
  node: PropTypes.object,
  nodeId: PropTypes.string, // 优先使用 user 内的数据，当 user 不存在时，再用 userId 读取 user 信息
  viewId: PropTypes.string, // node 所在 view 的 viewId，当传入 nodeId 时需要
  showFname: PropTypes.bool, // 是否显示fname
  showUpdateTime: PropTypes.bool, // 是否显示更新时间
  renderFun: PropTypes.func, // 自定义渲染方法
  highlightWords: PropTypes.array, //高亮单词
  highlightClass: PropTypes.string, //高亮单词的 class 类
};
