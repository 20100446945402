/**
 * 用户头像组件
 */
import React from "react";
import PropTypes from "prop-types";
import UserAvatar from "react-user-avatar";
import { AvatarColors, IconTypes } from "@/constants/common";
import userStyles from "@/style/default/user.less";
import { API_GetUserInfoById } from "../../../api/user";
import { getToken, REQUEST_BASE } from "../../../utils/HttpUtil";

class UserNickAvatar extends React.Component {
  state = {
    // 用户信息
    userInfo: null,
  };
  componentDidMount() {
    let me = this;
    // 判断是否要读取用户信息
    const { user, userId } = this.props;
    if (!user) {
      if (userId) {
        API_GetUserInfoById(userId)
          .then((response) => {
            let res = response.data;
            if (res.hasOwnProperty("code") && res.code === 0) {
              // 添加到缓存
              // me.userInfoCache["u_" + res.data.id] = res.data;
              me.setState(
                {
                  userInfo: res.data,
                },
                () => {
                  // ignore
                }
              );
            } else {
              // 获取看板信息出错
              me.setState({
                userInfo: false,
              });
            }
          })
          .catch((e) => {
            console.log("UserAvatar API_GetUserInfoById e: ", e);
            // ignore
          });
      } else {
        // 没有看板信息
        me.setState({
          userInfo: false,
        });
      }
    } else {
      me.setState({
        userInfo: user,
      });
    }
  }

  render() {
    const { className, style, avatarSize, showName, nickStyle } = this.props;
    const { userInfo } = this.state;
    if (!userInfo) {
      return <div className={className} style={style} />;
    }

    let _style = {};
    if (style) {
      _style = { ..._style, style };
    }

    if (avatarSize) {
      _style["--avatar-size"] = avatarSize;
    }

    let fontStyle = {};
    if (nickStyle) {
      fontStyle = nickStyle;
    }
    return (
      <div
        className={
          className + " " + userStyles["avatar-outer"] + " " + userStyles["row"]
        }
        style={_style}
      >
        {userInfo && userInfo.hasOwnProperty("picId") && userInfo.picId ? (
          <div className={userStyles["avatar"]} title={userInfo.nick}>
            <UserAvatar
              size={"var(--avatar-size)"}
              name={userInfo.nick || ""}
              src={`${REQUEST_BASE}/user/user/file/${
                userInfo.picId
              }?Authorization=${getToken()}`}
              colors={AvatarColors}
            />
          </div>
        ) : (
          <div
            className={`${userStyles["avatar"]} ${userStyles["avatar-default-icon"]}`}
            title={userInfo.nick}
          >
            <UserAvatar
              size={"var(--avatar-size)"}
              name={userInfo.nick || ""}
              colors={AvatarColors}
            />
          </div>
        )}
        {/*
        <div
          className={`${userStyles['avatar']} ${userInfo && userInfo.picId ? '' : userStyles['avatar-default-icon']}`}
        >
          {
            userInfo ? <UserAvatar
              size={'var(--avatar-size)'}
              name={userInfo.nick}
              src={
                userInfo.picId ?
                  `${REQUEST_BASE}/user/user/file/${userInfo.picId}?Authorization=${getToken()}` :
                  undefined
              }
              colors={AvatarColors}
            /> : ''
          }
        </div>
*/}
        {showName && userInfo && userInfo.hasOwnProperty("nick") ? (
          <div
            className={userStyles["nick"]}
            style={fontStyle}
            title={userInfo.nick}
          >
            {userInfo.nick}
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

UserNickAvatar.defaultProps = {
  avatarSize: "2rem", // 头像的默认尺寸
  showName: true, // 默认不显示
};

UserNickAvatar.propTypes = {
  user: PropTypes.object,
  userId: PropTypes.string, // 优先使用 user 内的数据，当 user 不存在时，再用 userId 读取 user 信息
  showName: PropTypes.bool, // 是否显示用户昵称
  avatarSize: PropTypes.string, // 头像的大小
  nickStyle: PropTypes.object, // 名字的样式，不传则文字大小是头像大小的 9/10
};
export default UserNickAvatar;
