import React from 'react';

import DurationNoticePanel from '@/components/common/common.durationNoticePanel';

import style from "@/style/components/common.authForm.less";
import PropTypes from "prop-types";

class SuccessNoticePanel extends React.Component {
  render() {
    let me = this;
    return (
      <DurationNoticePanel redirectAt={me.props.redirectAt}
                           btnText={'立即登录'}
                           onBack={me.props.onBack}>
        <h1 className={style['form-title']}>密码已重置</h1>
        <div className={style['form-item']} style={{marginBottom: '3em'}}>
          重置成功，请牢记您的密码。<br />
          系统将在5秒后自动跳转至登录页面。
        </div>
      </DurationNoticePanel>
    );
  }
}

SuccessNoticePanel.defaultProps = {
  redirectAt: 0,
  onBack: () => {console.log('onBack property is not set.')},
};

SuccessNoticePanel.propTypes = {
  redirectAt: PropTypes.number,
  onBack: PropTypes.func,
};

export default SuccessNoticePanel;