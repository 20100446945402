import React from 'react';
import PropTypes from 'prop-types';

import PB, {SimplePB} from '@/libs/simplePB';

import {getNodeDisplayTitle, getNodeIcon} from '@/constants/vis.defaultDefine.1';

import ViewDataProvider from '@/components/common/dataProvider/common.dataProvider.view';
import Icon from '@/components/common/common.icon';

import {defaultIconData} from '@/components/common/view/presentation/common.view.presentation.logic';

import style from '@/style/common/view/common.view.presentation.story.less';

class PresentationConfigDetailPanel extends React.PureComponent {
  render() {
    let me = this,textList = [], notNodeIdx = 1, notNodeNum = 0;
    let nodeList = me.props.config && me.props.config.content && me.props.config.content.nodeIds && me.props.viewDataProvider.getNode(me.props.config['content'].nodeIds).filter(n => !!n);
    let contentList = me.props.config && me.props.config.content && me.props.config['content'].contentList || undefined;
    if(contentList && contentList.length>0){
      contentList.forEach((n,index) => {
        if(me.props.config['content'].nodeIds.includes(n)){
          nodeList.map((node, idx) => {
            if(node.id==n){
              textList.push(node);
            }
          })
        }else{
          textList.push({id:'id_'+index,description:n,type:'notNode'});
          notNodeNum++
        }
      })
    }else{
      textList = nodeList;
    }

    return me.props.config ?(
      <div className={style['config-detail-panel']}>
        <div className={style['config-detail-header']}>
          <div className={`ant-avatar ant-avatar-icon common-avatar ${style['config-avatar']}`}>
            <img
              src={me.props.config && me.props.config.meta && me.props.config.meta['iconData']
                ? me.props.config.meta['iconData'] : defaultIconData}
              alt={'icon'}
            />
          </div>
          <div className={style['config-title']}>
            {me.props.config.title}
          </div>
          <div
            className={style['config-back-to-list']}
            onClick={() => me.props.bus.emit('presentation', 'config.list.show_drawer',
              {viewId: me.props.viewDataProvider.viewId})}
          >
            <Icon name={'left'}/> 专题报告列表
          </div>
        </div>
        <div className={`${style['config-detail-content']} scrollbar scrollbar-none`}>
          <div className={`${style['config-table']} scrollbar-none`}>
          {
            me.props.config.description ? (
              <div className={style['config-detail-section']}>
                <h4>专题报告描述</h4>
                <p>
                  {
                    me.props.config.description.split('\n').map((line, idx) => (
                      <React.Fragment key={`ln-${idx}`}>{line}<br /></React.Fragment>
                    ))
                  }
                </p>
              </div>
            ) : null
          }
          <div className={style['config-detail-section']}>
            <h4>节点列表</h4>
            <table className={style['config-detail-node-list']}>
              <tbody>
              {
                textList.map((node, idx) => {
                  return node.type !=='notNode'?
                  (
                    <tr
                      key={`node-${node.id}-${idx}`}
                      onClick={e => {me.props.bus.emit('relation', 'node.presentation.focus', {node: node, config:me.props.config})}}
                    >
                      <td>
                      <span className={notNodeNum>0?style['node-dot-exp'] : style['node-dot']}> ○ </span>
                        {getNodeDisplayTitle(node)}
                      </td>
                    </tr>
                  ):(
                    <tr
                    key={`node-${node.id}-${idx}`}
                    onClick={e => {me.props.bus.emit('relation', 'node.presentation.focus', {node: node, config:me.props.config})}}
                  >
                    <td><span className={style['node-line']}>{notNodeIdx<100?notNodeIdx++:'--'}</span>
                    <span className={style['node-text']}>{node.description}</span></td>
                  </tr>
                  )
                })
              }
              </tbody>
            </table>
          </div>
          </div>
        </div>
      </div>
    ):null
  }
}

PresentationConfigDetailPanel.defaultProps = {
  bus: PB,
};

PresentationConfigDetailPanel.propTypes = {
  bus: PropTypes.instanceOf(SimplePB),
  viewDataProvider: PropTypes.instanceOf(ViewDataProvider).isRequired,
  config: PropTypes.object,
};

export default PresentationConfigDetailPanel;