import React from 'react';
import PropTypes from 'prop-types';
import {Button, message, Modal, Drawer, Row, Col} from 'antd';

import {SimplePB} from '@/libs/simplePB';

import Icon from '@/components/common/common.icon';

import style from '@/style/common/view/common.view.teamwork.member.less';
import TeamworkMemberBar from '@/components/common/view/teamwork/common.view.teamwork.memberBar';
import TeamworkLogic from '@/components/common/view/teamwork/common.view.teamwork.logic';
import TeamworkMemberFindForm from '@/components/common/view/teamwork/common.view.teamwork.memberFindForm';
import TeamworkMemberStatisticsTable
    from '@/components/common/view/teamwork/common.view.teamwork.memberStatisticsTable';
import StatisticsNodeTable from '@/components/common/view/statistics/common.view.statistics.nodeTable';
import SettingsModal from '@/components/common/view/teamwork/common.view.teamwork.configModal';
import TeamworkMemberNodePanel from '@/components/common/view/teamwork/common.view.teamwork.memberNodePanel';
import ViewInfoComponent from '@/components/common/view/common.view.infoComponent';

import UserAvatar from 'react-user-avatar';
import {getToken, REQUEST_BASE} from '@/utils/HttpUtil';
import {AvatarColors} from '@/constants/common';
import View from '@/components/common/common.viewManager';
import moment from 'moment';
import TeamworkMemberStatPanel from '@/components/common/view/teamwork/common.view.teamwork.memberStatPanel';
import TeamworkMemberStatPanelOfNodes
    from '@/components/common/view/teamwork/common.view.teamwork.memberStatPanel_Nodes';

const MAX_RECENT_ITEM_AMOUNT = 100;

class Teamwork extends React.Component {
    state = {
        memberList: [],
        memberListSorted: false,
        status: 'idle',
        overallStatistics: {},
        memberStatistics: {},
        memberInfo: undefined,
        nodeTableData: [],
        showFindMemberModal: false,
        showMemberListModal: null,
        showSettingsModal: false,
        showStatisticsModal: false,
        showMemberStatisticsChartModal: false,

        showMemberNodePanelWithID: -1,
        memberNodeAmountStatisticsLoadingStatus: 'idle',
        memberNodeAmountStatistics: undefined,

        showNodeTable: false,
        settings: {
            viewId: undefined,
            type: 0,
            meta: {},
        },

        latestNodes: undefined,

        showViewTransferModal: false, // 是否显示图谱转让弹层
        userStatModalVisible: false, // 是否显示用户画像弹框
        userStat2ModalVisible: false, // 是否显示用户画像二弹框
        nodeCount: 0
    };

    nodes = undefined;

    autoStartStatistics = false;

    statisticsResult = undefined;

    bus = undefined;

    notificationDisplayed = false;

    transferToUserId = undefined;

    componentDidMount() {
        let me = this;

        me.bus = me.props.bus || new SimplePB();

        me.bus.sub(me, 'teamwork', 'member.list.show_modal', () => {
            me.setState({showMemberListModal: 'list'});
        });

        me.bus.sub(me, 'view', 'view.info.show_modal', () => {
            me.setState({showMemberListModal: 'view'}, () => {
                if (me.nodes && me.nodes.length > 0) {
                    let latestNodes = me.nodes;
                    latestNodes.sort((a, b) => moment(b.updateTime).valueOf() - moment(a.updateTime).valueOf());
                    latestNodes = latestNodes.slice(0, 100);

                    me.setState({
                        latestNodes,
                        nodeCount:me.nodes.length
                    })
                }
            });
        });

        me.bus.sub(me, 'teamwork', 'member.list.hide_modal', () => {
            me.setState({showMemberListModal: null});
        });

        me.bus.sub(me, 'teamwork', 'member.add.show_modal', () => {
            me.setState({showFindMemberModal: true});
        });

        me.bus.sub(me, 'teamwork', 'member.statistics.show_modal', () => {
            me.setState({showStatisticsModal: true});
        });

        me.bus.sub(me, 'teamwork', 'member.statistics.show_node_table', ({nodes: nodeTableData, memberInfo}) => {
            me.setState({showNodeTable: true, nodeTableData, memberInfo});
        });

        me.bus.sub(me, 'teamwork', 'member.list.processing', ({viewId}) => {
            if (viewId === me.props.viewId) {
                me.setState({memberList: [], memberListSorted: false, status: 'processing'});
            }
        });

        me.bus.sub(me, 'teamwork', 'member.list.success', ({viewId, userList, reloaded}) => {
            if (viewId === me.props.viewId) {
                if (!!reloaded) {
                    me.statisticsResult = undefined;
                }
                me.setState({
                    memberList: userList,
                    memberListSorted: !reloaded && me.state.memberListSorted,
                    status: 'success',
                }, () => {
                    me.bus.emit('teamwork', 'member.list.sort', {viewId, forceReload: !!reloaded});
                });
            }
        });

        me.bus.sub(me, 'teamwork', 'member.list.sorted', ({viewId, userList}) => {
            if (viewId === me.props.viewId) {
                me.setState({memberList: userList, memberListSorted: true}, () => {
                    me.bus.emit('teamwork', 'statistics.members.ready', {members: userList});
                    if (me.autoStartStatistics) {
                        me.props.bus.emit('teamwork', 'statistics.result.get');
                    }
                });
            }
        });

        me.bus.sub(me, 'teamwork', 'member.list.failed', ({viewId}) => {
            if (viewId === me.props.viewId) {
                me.setState({memberList: [], memberListSorted: false, status: 'failed'});
            }
        });

        me.bus.sub(me, 'teamwork', 'member.list.sort_failed', ({viewId}) => {
            if (viewId === me.props.viewId) {
                me.setState({memberListSorted: false});
            }
        });

        // 协作成员添加成功后更新协作成员列表
        me.bus.sub(me, 'teamwork', 'member.add.success', ({viewId, userInfo}) => {
            if (me.props.viewId !== viewId) return;
            message.success(`协作成员 ${userInfo.nick} 添加成功`);
        });

        // 协作成员添加失败后更新协作成员列表
        me.bus.sub(me, 'teamwork', 'member.add.failed', ({viewId, userInfo}) => {
            if (me.props.viewId !== viewId) return;
            message.error(`协作成员 ${userInfo.nick} 添加失败`);
        });

        me.bus.sub(me, 'teamwork', 'member.join.success', () => {
            message.success('您已成功加入协作团队');
        });

        // 协作成员移除成功后更新协作成员列表
        me.bus.sub(me, 'teamwork', 'member.remove.success', ({viewId, userInfo}) => {
            if (userInfo) {
                message.success(`协作成员 ${userInfo.nick} 移除成功`);
            } else {
                Modal.success({
                    title: '操作成功',
                    content: '您已不再是本图谱协作成员，点击确定关闭本页面。',
                    okText: '确定',
                    onOk: () => {
                        window.close();
                    },
                });
            }
        });

        // 协作成员移除失败后更新协作成员列表
        me.bus.sub(me, 'teamwork', 'member.remove.failed', ({viewId, userInfo}) => {
            message.error(`协作成员 ${userInfo.nick} 移除失败`);
        });

        me.bus.sub(me, 'teamwork', 'member.remove.show_confirm', ({viewId, userInfo}) => {
            if (userInfo) {
                Modal.confirm({
                    title: '移除协作成员',
                    content: `确认移除协作成员 ${userInfo.nick} ？`,
                    icon: <Icon name={'delete'}/>,
                    okText: '确认移除',
                    cancelText: '取消',
                    okButtonProps: {type: 'danger'},
                    onOk: () => {
                        me.bus.emit('teamwork', 'member.remove.do', {viewId, userInfo});
                    },
                });
            } else {
                Modal.confirm({
                    title: '操作确认',
                    content: `确认要离开吗？`,
                    icon: <Icon name={'exit'}/>,
                    okText: '确认离开',
                    cancelText: '取消',
                    okButtonProps: {type: 'danger'},
                    onOk: () => {
                        me.bus.emit('teamwork', 'member.remove.do', {viewId, userInfo});
                    },
                });
            }
        });

        me.props.bus.sub(me, 'teamwork', 'settings.load.success', ({viewId, type, meta}) => {
            if (me.props.viewId !== viewId) return;
            me.setState({settings: {viewId, type, meta}});
        });

        me.bus.sub(me, 'network', 'graph.after_loaded', ({nodes}) => {
            me.nodes = nodes;
            me.bus.emit('teamwork', 'statistics.nodes.ready', {nodes});
            if (me.autoStartStatistics) {
                me.props.bus.emit('teamwork', 'statistics.result.get');
            }
        });

        me.bus.sub(me, 'relation', 'data.after_change', ({nodes, edges}) => {
            me.nodes = nodes;
        });

        me.bus.sub(me, 'teamwork', 'statistics.nodes.get', () => {
            me.bus.emit('network', 'graph.get');
        });

        me.bus.sub(me, 'teamwork', 'statistics.members.get', () => {
            if (me.state.status === 'success') {
                me.bus.emit('teamwork', 'statistics.members.ready', {members: me.state.memberList});
            } else {
                me.bus.emit('teamwork', 'member.list.do', {viewId: me.props.viewId});
            }
        });

        me.bus.sub(me, 'teamwork', 'statistics.member_statistics_chart.show',
            ({overallStatistics, memberStatistics, memberInfo}) => {
                me.setState({showMemberStatisticsChartModal: true, memberStatistics, overallStatistics, memberInfo});
            });

        me.bus.sub(me, 'teamwork', 'member.node_panel.show', async ({memberId}) => {
            if (me.statisticsResult) {
                me.bus.emit('teamwork', 'statistics.member_node_amount.load', {memberId});
            } else {
                await me.bus.emit('teamwork', 'statistics.nodes.get');
                await me.props.bus.emit('teamwork', 'statistics.result.get');
                setTimeout(() => {
                    me.bus.emit('teamwork', 'statistics.members.get');
                    me.props.bus.emit('teamwork', 'statistics.member_node_amount.load', {memberId});
                }, 300);
            }

            me.setState({
                showMemberListModal: 'member',
                showMemberNodePanelWithID: memberId,
            });
        });

        me.props.bus.sub(me, 'teamwork', 'statistics.result.get', () => {
            me.autoStartStatistics = true;
            if (me.statisticsResult) {
                me.props.bus.emit('teamwork', 'statistics.result.ready', me.statisticsResult);
            } else if (me.nodes && me.state.memberList && me.state.memberList.length > 0) {
                me.props.bus.emit('teamwork', 'statistics.generate_result',
                    {nodes: me.nodes, members: me.state.memberList, maxRecentItemAmount: MAX_RECENT_ITEM_AMOUNT});
            }
        });

        me.props.bus.sub(me, 'teamwork', 'statistics.result.ready',
            ({memberStatisticsList, overallStatistics, memberIdxMap, viewStatistics}) => {
                me.statisticsResult = {memberStatisticsList, overallStatistics, memberIdxMap, viewStatistics};
            });

        me.props.bus.sub(me, 'teamwork', 'statistics.member_node_amount.load',
            ({memberId}) => {
                if (me.state.showMemberNodePanelWithID === memberId) {
                    me.setState({
                        memberNodeAmountStatisticsLoadingStatus: 'processing',
                        memberNodeAmountStatistics: undefined,
                    });
                }
            });

        me.props.bus.sub(me, 'teamwork', 'statistics.member_node_amount.success',
            ({memberId, statistics}) => {
                if (me.state.showMemberNodePanelWithID === memberId) {
                    me.setState({
                        memberNodeAmountStatisticsLoadingStatus: 'success',
                        memberNodeAmountStatistics: {
                            ...statistics,
                            nodeAmountRecentDays: me.statisticsResult.memberStatisticsList[me.statisticsResult
                                .memberIdxMap[`user-${me.state.showMemberNodePanelWithID}`]].overallStatistics.points.data.length,
                        },
                    });

                }
            });

        me.props.bus.sub(me, 'teamwork', 'statistics.member_node_amount.failed',
            ({memberId}) => {
                if (me.state.showMemberNodePanelWithID === memberId) {
                    me.setState({
                        memberNodeAmountStatisticsLoadingStatus: 'failed',
                        memberNodeAmountStatistics: undefined,
                    });
                }
            });

        me.bus.sub(me, 'teamwork', 'settings.show_modal', () => {
            me.setState({showSettingsModal: true});
        });

        me.bus.sub(me, 'view', 'view.transfer.toUser', async ({viewId}) => {
            this.setState({
                showViewTransferModal: true,
            })
        });

        me.bus.sub(me, 'teamwork', 'update.localstorage.members', async () => {
            let me = this;
            let members = this.state.memberList.map(user => {
                return {
                    userId: user.userId,
                    nick: user.nick,
                    name: user.name,
                    picId: user.picId,
                }
            });
            console.log(' update.localstorage.members ->  :', members);
            localStorage.setItem(`MT_members_${me.props.viewId}`, JSON.stringify(members));
        });

        requestAnimationFrame(() => {
            me.bus.emit('teamwork', 'statistics.members.get');
            me.bus.emit('teamwork', 'settings.load.do', {viewId: me.props.viewId});
        });
    }

    componentWillUnmount() {
        this.props.bus.remove(this);
    }

    viewTransferUserId = (userId) => {
        this.transferToUserId = userId;
    };

    viewTransfer = () => {
        let viewId = this.props.viewId, userId = this.transferToUserId;
        Modal.confirm({
            title: '图谱转让后，您将失去管理员权限，此页面将会刷新。确认转让吗？',
            okText: '确认',
            cancelText: '取消',
            onOk() {
                View.viewTransferToUser(viewId, userId).then(res => {
                    location.reload();
                }).catch(error => {
                    console.log('图谱转让失败', error);
                    message.warn('图谱转让失败，请稍后再试！')
                });
            },
            onCancel() {
                // ignore
            },
        });

    };

    render() {
        let me = this;

        if (!me.notificationDisplayed && me.state.settings.type === 1 && me.state.memberList.length > 0) {
            me.notificationDisplayed = true;
        }
        return (
            <div className={style['container']} onContextMenu = {() => {window.event.returnValue=false;return false;}}>
                <TeamworkLogic
                    viewOwnerId={me.props.viewOwnerId}
                    currentUserId={me.props.currentUserId}
                    bus={me.bus}
                    viewId={me.props.viewId}
                />
                <TeamworkMemberBar
                    viewId={me.props.viewId}
                    viewOwnerId={me.props.viewOwnerId}
                    currentUserId={me.props.currentUserId}
                    bus={me.bus}
                    userList={me.state.memberList}
                    userListSorted={me.state.memberListSorted}
                    readonly={me.props.readonly}
                    recommendation={me.props.recommendation[0] == false ? [] : me.props.recommendation}
                    userInfo={me.props.userInfo}
                />
                <Drawer
                    onClose={() => me.setState({showMemberListModal: null})}
                    visible={!!me.state.showMemberListModal}
                    width={'calc(28rem + 10px)'}
                    placement='left'
                    destroyOnClose={true}
                    mask={false}
                    className={`${style['member-list-wrap']} dark-theme`}
                    bodyStyle={{height: '100%'}}
                >
                    {/*<TeamworkMemberList
            bus={me.bus}
            currentUserId={me.props.currentUserId}
            userList={me.state.memberList}
            viewId={me.props.viewId}
            readonly={me.props.readonly}
          />*/}
                    {
                        me.state.showMemberListModal === 'view' && (
                            <div style={{height: '100%'}}>
                                <div className={style['member-list-header']}>
                                    <div className={style['title']}>最新概况</div>
                                </div>
                                <div className={`scrollbar scrollbar-18`}
                                     style={{height: `calc(100% - 4rem)`, overflowY: 'auto'}}>
                                    <ViewInfoComponent
                                        bus={me.bus}
                                        viewInfo={me.props.viewInfo}
                                        userList={me.state.memberList}
                                        latestNodes={me.state.latestNodes}
                                        nodeCount={me.state.nodeCount}
                                    />
                                </div>
                            </div>
                        )
                    }
                    {
                        me.state.showMemberListModal === 'list' && (
                            <div style={{height: '100%'}}>
                                <div className={style['member-list-header']}>
                                    <div className={style['title']}>协作成员列表</div>
                                </div>
                                <div className={`scrollbar scrollbar-18`}
                                     style={{
                                         height: `calc(100% - 4rem)`,
                                         overflowX: 'hidden',
                                         overflowY: 'auto',
                                         padding: '12px 5px'
                                     }}>
                                    {
                                        me.props.readonly ? (
                                          <div className={style['add-member-wrap']}>
                                          <div className={style['add-member-controls']}>
                                              <Button
                                                type={'primary'}
                                                onClick={() => me.setState({userStat2ModalVisible: true})}
                                                style={{marginRight: 10}}
                                              >
                                                  查看成员动态
                                              </Button>
                                              <Button
                                                type={'primary'}
                                                disabled={true}
                                              >
                                                  添加协作成员
                                              </Button>
                                              <div style={{clear: 'both'}}></div>
                                          </div>
                                          </div>
                                        ) : (
                                            <div className={style['add-member-wrap']}>
                                                {
                                                    me.state.showFindMemberModal ?
                                                        (
                                                            <TeamworkMemberFindForm
                                                                bus={me.bus}
                                                                userList={me.state.memberList}
                                                                viewId={me.props.viewId}
                                                                onClose={() => me.setState({showFindMemberModal: false})}
                                                            />
                                                        ) : (
                                                            <div className={style['add-member-controls']}>
                                                                <Button
                                                                    type={'primary'}
                                                                    onClick={() => me.setState({userStat2ModalVisible: true})}
                                                                    style={{marginRight: 10}}
                                                                >
                                                                    查看成员动态
                                                                </Button>
                                                                <Button
                                                                    type={'primary'}
                                                                    onClick={() => me.bus.emit('teamwork', 'member.add.show_modal')}
                                                                >
                                                                    添加协作成员
                                                                </Button>
                                                                <div style={{clear: 'both'}}></div>
                                                            </div>
                                                        )
                                                }
                                            </div>
                                        )
                                    }
                                    <TeamworkMemberStatisticsTable
                                        bus={me.bus}
                                        readonly={me.props.readonly}
                                        currentUserId={me.props.currentUserId}
                                        viewId={me.props.viewId}
                                        type={'show'}
                                    />
                                </div>
                            </div>
                        )
                    }
                    {
                        me.state.showMemberListModal === 'member' && me.state.showMemberNodePanelWithID >= 0 && (
                            <div style={{height: '100%'}}>
                                <div className={style['member-list-header']}>
                                    <div className={style['title']}>
                                        {
                                            me.state.showMemberNodePanelWithID >= 0 && me.statisticsResult ? (
                                                <div className={style['user-info']}>
                                                    <div className={`${style['avatar']} ${me.state.memberList.find(
                                                        member => parseInt(member.userId) === me.state.showMemberNodePanelWithID).picId ? '' : style['avatar-default-icon']}`}>
                                                        <UserAvatar
                                                            size={'var(--avatar-size)'}
                                                            name={me.state.memberList.find(
                                                                member => parseInt(member.userId) === me.state.showMemberNodePanelWithID).nick}
                                                            src={me.state.memberList.find(
                                                                member => parseInt(member.userId) === me.state.showMemberNodePanelWithID).picId ?
                                                                `${REQUEST_BASE}/user/user/file/${me.state.memberList.find(
                                                                    member => parseInt(member.userId) === me.state.showMemberNodePanelWithID).picId}?Authorization=${getToken()}`
                                                                : undefined
                                                            }
                                                            colors={AvatarColors}
                                                        />
                                                    </div>
                                                    <div>{me.state.memberList.find(
                                                        member => parseInt(member.userId) === me.state.showMemberNodePanelWithID).nick}</div>
                                                </div>
                                            ) : null
                                        }
                                    </div>
                                    <div className={style['link-right']}
                                         onClick={() => me.setState({showMemberListModal: 'list'})}
                                    >
                                        <Icon name={'left'}/>
                                        成员列表
                                    </div>
                                </div>
                                <div className={`scrollbar scrollbar-18`}
                                     style={{height: `calc(100% - 4rem)`, overflowY: 'auto', padding: 12}}>
                                    <br/>
                                    <Row gutter={[16, 24]}>
                                        <Col span={12}>
                                        <Button
                                            type={'primary'}
                                            block
                                            onClick={() => {
                                                me.props.bus.emit('account', 'user_center.show_modal');
                                            }}>
                                            【{
                                            me.state.memberList.find(
                                                member => parseInt(member.userId) === me.state.showMemberNodePanelWithID).nick
                                        }】 的个人中心
                                        </Button>
                                        </Col>
                                        <Col span={12}>
                                        <Button
                                            type={'primary'}
                                            block
                                            onClick={() => {
                                                me.setState({
                                                    userStatModalVisible: !me.state.userStatModalVisible
                                                })
                                            }}>
                                            【{
                                            me.state.memberList.find(
                                                member => parseInt(member.userId) === me.state.showMemberNodePanelWithID).nick
                                        }】 的协作画像
                                        </Button>
                                        </Col>
                                    </Row>
                                    <br/>

                                    <TeamworkMemberNodePanel
                                        bus={me.bus}
                                        loadingStatus={me.state.memberNodeAmountStatisticsLoadingStatus}
                                        memberRecentNodeList={
                                            me.statisticsResult && me.statisticsResult.memberStatisticsList[me.statisticsResult
                                                .memberIdxMap[`user-${me.state.showMemberNodePanelWithID}`]].lastInputNodes
                                        }
                                        viewStatistics={
                                            me.statisticsResult && me.statisticsResult.viewStatistics
                                        }
                                        overallStatistics={me.statisticsResult && me.statisticsResult.overallStatistics}
                                        memberStatistics={
                                            me.statisticsResult && me.statisticsResult.memberStatisticsList[me.statisticsResult
                                                .memberIdxMap[`user-${me.state.showMemberNodePanelWithID}`]].overallStatistics
                                        }
                                        memberNodeAmountStatistics={me.state.memberNodeAmountStatistics}
                                        memberInfo={
                                            me.state.memberList.find(
                                                member => parseInt(member.userId) === me.state.showMemberNodePanelWithID)
                                        }
                                    />
                                </div>

                            </div>
                        )
                    }
                </Drawer>
                <SettingsModal
                    visible={me.state.showSettingsModal}
                    currentUserId={me.props.currentUserId}
                    viewId={me.props.viewId}
                    bus={me.bus}
                    readonly={me.props.readonly}
                    onClose={() => me.setState({showSettingsModal: false})}
                />
                {/*<Modal
          title={'协作成员'}
          onCancel={() => me.setState({showStatisticsModal: false})}
          visible={me.state.showStatisticsModal}
          width={'80rem'}
          footer={null}
        >
          <TeamworkMemberStatisticsTable
            bus={me.bus}
            scroll={{y: '50vh'}}
            readonly={me.props.readonly}
            currentUserId={me.props.currentUserId}
          />
        </Modal>*/}

                {
                    me.state.memberInfo && (
                        <Modal
                            title={`节点列表（创建人：${me.state.memberInfo.nick}）`}
                            onCancel={() => me.setState({showNodeTable: false})}
                            visible={me.state.showNodeTable}
                            width={'70rem'}
                            footer={[
                                (<Button key={'close'} onClick={() => me.setState({showNodeTable: false})}>关闭</Button>),
                            ]}
                            destroyOnClose={true}
                        >
                            <StatisticsNodeTable
                                scroll={{y: '50vh'}}
                                nodes={me.state.nodeTableData}
                            />
                        </Modal>
                    )
                }
                {
                    <Modal
                        title={'图谱转让'}
                        onCancel={() => me.setState({showViewTransferModal: false})}
                        visible={me.state.showViewTransferModal}
                        width={'44.3rem'}
                        footer={[
                            (<Button key={'close'}
                                     onClick={() => me.setState({showViewTransferModal: false})}>关闭</Button>),
                            (<Button key={'transfer'} onClick={() => me.viewTransfer()} type={'primary'}>转让</Button>),
                        ]}
                        destroyOnClose={true}
                        centered={true}
                        wrapClassName={style['view-transfer-modal']}
                    >
                        <TeamworkMemberStatisticsTable
                            bus={me.bus}
                            readonly={me.props.readonly}
                            currentUserId={me.props.currentUserId}
                            viewId={me.props.viewId}
                            members={me.state.memberList}
                            type={'transfer'}
                            viewTransferUserId={me.viewTransferUserId}
                        />
                    </Modal>
                }

                {/*用户画像弹框*/}
                {me.state.userStatModalVisible && <Modal
                    title={
                        me.state.showMemberNodePanelWithID >= 0 && me.statisticsResult ? (
                            <div className={style['user-stat_avatar']}>
                                <div className={`${style['avatar']} ${me.state.memberList.find(
                                    member => parseInt(member.userId) === me.state.showMemberNodePanelWithID).picId ? '' : style['avatar-default-icon']}`}>
                                    <UserAvatar
                                        size={'var(--avatar-size)'}
                                        name={me.state.memberList.find(
                                            member => parseInt(member.userId) === me.state.showMemberNodePanelWithID).nick}
                                        src={me.state.memberList.find(
                                            member => parseInt(member.userId) === me.state.showMemberNodePanelWithID).picId ?
                                            `${REQUEST_BASE}/user/user/file/${me.state.memberList.find(
                                                member => parseInt(member.userId) === me.state.showMemberNodePanelWithID).picId}?Authorization=${getToken()}`
                                            : undefined
                                        }
                                        colors={AvatarColors}
                                    />
                                </div>
                                <div>【{me.state.memberList.find(
                                    member => parseInt(member.userId) === me.state.showMemberNodePanelWithID).nick}】
                                    的协作画像
                                </div>
                            </div>
                        ) : <div>协作画像</div>

                    }
                    visible={me.state.userStatModalVisible}
                    closable={true}
                    destroyOnClose={true}
                    centered={true}
                    onCancel={() => me.setState({userStatModalVisible: false})}
                    footer={false}
                    width={'85rem'}
                    bodyStyle={{
                        padding: 0, paddingLeft: 0, backgroundColor: '#F2F2F2'
                    }}
                >
                    <TeamworkMemberStatPanel
                        viewInfo={me.props.viewInfo}
                        userId={me.state.showMemberNodePanelWithID}
                        memberInfo={me.state.memberList.find(member => parseInt(member.userId) === me.state.showMemberNodePanelWithID)}
                    />

                </Modal>
                }

                {/*用户画像二 弹框*/}
                <Modal
                    title={
                        <div>协作成员创建节点统计</div>
                    }
                    visible={me.state.userStat2ModalVisible}
                    closable={true}
                    destroyOnClose={true}
                    centered={true}
                    onCancel={() => me.setState({userStat2ModalVisible: false})}
                    footer={false}
                    width={'83rem'}
                    xbodyStyle={{
                        padding: 0, paddingLeft: 0, backgroundColor: '#F2F2F2',

                    }}
                >
                    <TeamworkMemberStatPanelOfNodes
                        viewInfo={me.props.viewInfo}
                        memberList={me.state.memberList}
                    />
                </Modal>
            </div>
        );
    }
}

Teamwork.defaultProps = {
    bus: undefined,
    readonly: true,
};

Teamwork.propTypes = {
    bus: PropTypes.instanceOf(SimplePB),
    readonly: PropTypes.bool,
    viewId: PropTypes.string.isRequired,
    viewOwnerId: PropTypes.number.isRequired,
    viewInfo: PropTypes.object.isRequired,
    currentUserId: PropTypes.number.isRequired,
    recommendation: PropTypes.array,
    userInfo: PropTypes.object.isRequired,
};

export default Teamwork;
