// 库
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment'
import momentLocale from 'moment/locale/zh-cn';
import {DataSet} from '@/libs/vis'
import MyNetwork from '@/libs/myVis/MyNetwork3'
// css
import style from '@/style/components/toolbar/network.less'
import lineStyle from '@/style/components/toolbar/line.list.less'

// 组件
import {Popover, Tooltip, message} from 'antd';
// import Icon from '@/components/common/common.icon';
import NodeInfoCard from "@/components/explorationView/exploration.nodeInfoCard";
import {getObjectValue, simplifyProv} from '@/components/mainView/toolbar/functions'
// import {getNodeIconByType} from '@/constants/vis.defaultDefine.1'
import VisualToolMini from "@/components/common/relation/common.relation.visualToolMini";
import {bindUtil} from "@/libs/core-decorators";
import {autoSetState, withReactStateHelper} from "@/libs/react-state-helper";
import {FilterAndStatisticsBus} from "@/components/common/common.timestampAndLocationStatistics";
import {showAsTip} from "@/components/common/common.functions";

// 地图数据，演示数据
// import {worldCountryNodes} from '@/constants/worldcountry/node'
// import {worldCountryEdges} from '@/constants/worldcountry/edge'

// 时序图内节点样式
/*function NodeInfoInNetwork(props, /!* context *!/) {
    const {mode, item} = props;
    const {name, type, color} = getNodeIconByType(item.node.type);
    // 按节点类型显示不同的文字
    return <div style={{backgroundColor: 'hsla(0, 0%, 13%, 1)'}}>
        <Icon name={name} type={type} color={color}/> {mode === 'nav' ? item.start : item.content}
    </div>
}*/

// momnet 使用中文
moment.updateLocale('zh-cn', momentLocale)

/**
 * 用vis js 的timeline组件画图标出节点的时间信息
 * 方式一：nav 配置
 * 方式二：default 配置
 * 状态：
 * 1、没有外部数据传入
 * 2、外部数据没有有效的网络图数据
 * 3、有效的组件内数据里没有筛选出分类数据
 */
@bindUtil.asTargetClass
@withReactStateHelper
class NetworkNav extends React.Component {
    // 缓存 node 地区分类
    nodeGroupCache = {};
    // 容器id
    containerId = 'visNetworkContainerId';
    container = undefined;
    // 浮动提示id
    elNodeInfoCardId = 'NodeInfoCard';
    // vis timeline实例
    myNetwork = undefined;
    // network 定制配置（将覆盖默认配置）
    extraOptions = {};
    // 有开始时间的node数据
    graphOfArr = {nodes: [], edges: []}; // vis timeline 格式的node 数组
    graphDataSet = {nodes: new DataSet(), edges: new DataSet()}; // 用于 vis timeline 画图绑定的dataset

    @bindUtil.bindToProperty('props.bus', 'statisticsUpdated')
    @autoSetState
    statisticsUpdated = false;

    processedUpdate = false; // 已处理过的原始数据更新标记

    state = {
        // 是否初次加载
        isFirstLoad: true,
        // 强制刷新
        refresh: false,
        // 组件自己的提示
        tip: null,
        // 鼠标悬浮的节点
        nodeOnMouseHover: undefined,
        // 鼠标点中的节点
        nodeOnMouseClick: undefined,
        // 列表展示的nodes
        nodesArrForList: [],
        // 列表展示的省名
        fieldValueForList: '',
    };

    // componentWillUnmount() {}

    componentDidMount() {
        let me = this;
        this.container = document.getElementById(this.containerId)

        // 自定义事件
        const myEvents = {
            'click': (params) => {
                console.log('NetworkNav->myNetwork->click->params=', params)
                if (params.nodes.length === 0 && params.edges.length === 0) {
                    console.log('NetworkNav->myNetwork->click->空白 params=', params)
                    // 空白
                    // 清空列表内的数据
                    this.state.nodesArrForList = [];
                    // 设置区域名称
                    this.state.fieldValueForList = null;

                    // 区域内的节点 画图
                    // this.myNetwork.highlightAll()
                } else if (params.nodes.length === 1) {
                    // 刷新页面
                    me.setState({
                        nodeOnMouseClick: me.graphDataSet.nodes.get(params.nodes)[0],
                    }, () => {
                        me.myNetwork.network.stopSimulation()
                    })
                } else {
                    // 刷新页面
                    me.setState({
                        nodeOnMouseClick: undefined,
                    }, () => {
                        me.myNetwork.network.stopSimulation()
                    })
                }
            },
            'hoverNode': (params) => {
                // 刷新页面
                me.setState({
                    nodeOnMouseHover: me.graphDataSet.nodes.get(params.node),
                }, () => {
                    me.myNetwork.network.stopSimulation();
                    let c = me.container.getClientRects();
                    let nxy = me.myNetwork.network.canvasToDOM(me.myNetwork.network.getPositions([me.state.nodeOnMouseHover.id]))
                    console.log('NetworkNav->myNetwork->hoverNode->nxy=', nxy)
                    // setTip(me.elNodeInfoCardId,{x: 0, y: -c[0].top}, {x: params.screenX, y: params.screenY}) // 设置跟随鼠标移动
                    showAsTip(me.elNodeInfoCardId, {x: 0, y: -c[0].top}, nxy[me.state.nodeOnMouseHover.id]) // 设置到节点位置
                })

            },
            'stabilized': (params) => {
                console.log('NetworkNav->myNetwork->stabilized->params=', params)
                // this.myNetwork.network.fit({animation: true})  // 没法聚焦操作
            },
            'stabilizationProgress': (params) => {
                 // 测试进度条
                console.log('NetworkNav->myNetwork->stabilizationProgress->params=', params)
                alert('beginning');
                let maxWidth = 496;
                let minWidth = 20;
                let widthFactor = params.iterations / params.total;
                var width = Math.max(minWidth, maxWidth * widthFactor);

                document.getElementById('bar').style.width = width + 'px';
                document.getElementById('text').innerHTML = Math.round(widthFactor * 100) + '%';

            },
            'stabilizationIterationsDone': () => {
                // 测试进度条
                console.log('NetworkNav->myNetwork->stabilizationIterationsDone->end')
                document.getElementById('text').innerHTML = '100%';
                document.getElementById('bar').style.width = '496px';
                document.getElementById('loadingBar').style.opacity = 0;
                // really clean the dom element
                setTimeout(function () {
                    document.getElementById('loadingBar').style.display = 'none';
                }, 500);

            },
        };
        this.myNetwork = new MyNetwork(this.container, {'nodes': [], 'edges': []}, this.extraOptions);
        this.graphDataSet=this.myNetwork.graph;
        this.myNetwork.bindEvent(myEvents);
        this.myNetwork.setPhysics('forceAtlas2Based');


        //清理数据，只留下有指定字段的数据
        this.graphOfArr = this.getGraphOfArr({nodes: this.props.bus.nodes, edges: this.props.edges});
        // 画图
        this.drawAll(this.graphOfArr);

        // 设置一个时间自当fit
        setTimeout(()=>{
            me.myNetwork.network.fit({animation: true})
        },2000)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.processedUpdate !== this.props.bus.statisticsUpdated) {
            this.processedUpdate = this.props.bus.statisticsUpdated;
            this.graphOfArr = this.getGraphOfArr({nodes: this.props.bus.nodes, edges: this.props.edges});
            // 画图
            this.drawAll(this.graphOfArr)
        }
    }

    // componentWillUnmount() {}

    /**
     * 去掉数据中没有地理经纬度的数据
     * @param {object} dataset 数据集,格式{nodes:[],edges:[]}
     * @returns {{nodes: Array, edges: Array}}
     */
    getGraphOfArr = (dataset) => {
        let result = {nodes: [], edges: []}
        if (dataset && Array.isArray(dataset.nodes) && dataset.nodes.length > 0) {
            result = dataset
        } else {
            console.log('NetworkNav->getGraph->没有数据dataset')
        }
        // 清除缓存数据
        this.nodeGroupCache = {}

        if (result.nodes.length === 0) {
            this.props.noticeAsEmpty(true);
        }

        return result
    }

    /**
     * 按照字段值获取节点
     * @param {function} predicate 过滤条件函数
     * @param {Array} nodes 被过滤的数组
     * @returns {Array}
     */
    getItemsByFieldFromArray = (predicate, nodes) => {
        console.log('NetworkNav->getNodesByFieldFromArray->nodes=', nodes)
        let result = []
        nodes.map((node, index) => {
            // console.log('NetworkNav->getNodesByFieldFromArray->map->node=', node)

            if (predicate(node, index)) {

                result.push(node)
            }
            return null
        })
        return result
    }


    /**
     * 画全部节点
     * @param {object} [graphOfArr] 含有有效分类字段值的节点数组
     */
    drawAll = (graphOfArr) => {
        console.log('NetworkNav->drawAll->graphOfArr=', graphOfArr);
        this.graphDataSet.nodes.clear();
        this.graphDataSet.edges.clear();
        this.graphDataSet.nodes.add(graphOfArr.nodes);
        this.graphDataSet.edges.add(graphOfArr.edges);
        // 强制刷新
        this.setState({
            refresh: !this.state.refresh,
        })
    };

    /**
     * 切换
     * @param classifyValue
     * @param classifyField
     * @param predicate
     */
    switchTo = ({classifyValue, classifyField = this.props.options.classifyField, predicate = undefined}) => {

        console.log('NetworkNav->switchTo->this.graphDataSet=', this.graphDataSet)
        // 例外
        if (classifyValue === '合计') {
            // 清空列表内的数据
            this.state.nodesArrForList = []
            // 设置区域名称
            this.state.fieldValueForList = null

            // 区域内的节点 画图
            this.drawAll(this.graphOfArr)
            return
        }

        // fieldName 点分隔层级
        let classifyFieldArr = classifyField.split('.')

        // 设置默认断言函数,针对省份,tip:根据业务写方法，todo：props里传？
        if (!predicate) {
            predicate = (node, index) => {
                let value = getObjectValue(node, classifyFieldArr)
                let prov = simplifyProv(value, true)
                prov = prov ? prov : value
                // classifyValue = simplifyProv(classifyValue, true)
                // console.log('NetworkNav->switchTo->predicate->value=', value)
                // console.log('NetworkNav->switchTo->predicate->prov=', prov)
                // console.log('NetworkNav->switchTo->predicate->classifyValue=', classifyValue)
                if (prov === classifyValue) {
                    return true
                }
            }
        }
        // 设置区域名称
        this.state.fieldValueForList = classifyValue
        // 过滤出地区的节点,优先查询缓存数据
        if (this.nodeGroupCache[classifyField + '=' + classifyValue]) {
            this.state.nodesArrForList = this.nodeGroupCache[classifyField + '=' + classifyValue]
        } else {
            this.state.nodesArrForList = this.getItemsByFieldFromArray(predicate, this.graphOfArr.nodes) // todo：dataset内部过滤
            // 写入缓存数据
            this.nodeGroupCache[classifyField + '=' + classifyValue] = this.state.nodesArrForList
        }

        if (this.state.fieldValueForList === 0) {
            message.warning('暂时没有找到 ' + classifyValue + ' 内资源，请尝试其他地区。')
            return null
        }

        console.log('NetworkNav->switchTo->this.state.nodesForList=', this.state.nodesArrForList)

        // 区域内的节点 画图
        // this.drawAll({nodes: this.state.nodesArrForList, edges: this.graphOfArr.edges})

        this.myNetwork.network.fit({animation: true})
        // this.myNetwork.highlightNodes(this.state.nodesArrForList)
        // 强制刷新
        this.setState({
            refresh: !this.state.refresh,
        })
    };

    render() {
        console.log('NetworkNav->render->this.props=', this.props)
        const {mode} = this.props
        const {tip, nodeOnMouseHover, nodesArrForList, fieldValueForList} = this.state
        if (mode !== 'nav') {
            this.state.isFirstLoad = false
        }

        let listHtml = [];
        nodesArrForList.forEach((node, i) => {
            listHtml.push(
                <Popover
                    key={"statistic" + i}
                    placement="leftTop"
                    overlayClassName={style['node-info'] + ' dark-theme'}
                    content={
                        <div>
                            <NodeInfoCard
                                className={style['info-card-popover']}
                                node={node}
                                onAddToGraph={this.props.onAddToGraph}
                                onRemoveFromGraph={this.props.onRemoveFromGraph}
                                onExplore={this.props.onExplore}
                                onVote={this.props.onVote}
                            />
                        </div>
                    }>
                    <div
                        className={lineStyle['line-list'] + ' ' + lineStyle['mouse-over']}
                        onMouseOver={() => {
                            // this.myNetwork.highlightNodes(node.id) // 地图上高亮节点
                        }}
                        onMouseLeave={() => {
                            // this.myNetwork.highlightNodes(node.id) // 取消地图上高亮节点
                        }}
                    >
                        {/*<span className={style['line-hd']}></span>*/}
                        <span className={lineStyle['line-bd']}>{node.fname}</span>
                        {/*<span className={style['line-ft']}></span>*/}
                    </div>
                </Popover>
            )
        })

        return (
            <div
                className={mode === 'nav' ? (this.props.className + ' ' + style['nav'] + ' ' + style['inside']) : (this.props.className + ' ' + style['inside'])}
            >
                <span
                    style={{
                        position: 'absolute',
                        top: '-25px',
                        left: '200px',
                        textAlign: 'center',
                    }}
                >
                    {tip}
                </span>
                <div
                    id={this.containerId}
                    style={{width: '100%', height: '100%'}}
                >
                </div>
                <div className={style['visual-list']}>
                    <div className={lineStyle['vertical-container']}>
                        {
                            fieldValueForList && listHtml.length > 0 ? (
                              <div className={lineStyle['list-title']}>
                                  {fieldValueForList}找到资源 {listHtml.length} 条：
                              </div>
                            ) : null
                        }
                        {
                            listHtml.length > 0 ? (
                              <div className={lineStyle['list-content'] + '  scrollbar scrollbar-none'}>
                                  {listHtml.map(i => i)}
                              </div>
                            ) : null
                        }

                    </div>
                </div>
                <VisualToolMini networkRef={this.myNetwork} />
                {
                    !nodeOnMouseHover ? null : (
                      <div
                        id={this.elNodeInfoCardId}
                        className={style['info-card-float']}
                        onMouseLeave={() => {
                            // 取消选择的节点
                            this.setState({
                                nodeOnMouseHover: undefined,
                            })
                        }}
                      >
                          <NodeInfoCard
                            style={{padding: 0}}
                            readonly={this.props.readonly}
                            node={nodeOnMouseHover}
                            onAddToGraph={this.props.onAddToGraph}
                            onRemoveFromGraph={this.props.onRemoveFromGraph}
                            onExplore={this.props.onExplore}
                            onVote={this.props.onVote}
                          />
                      </div>
                    )
                }

                <div id="loadingBar" className={style['loadingBar']} style={{opacity: '0', display: 'none'}}>
                    <div className={style['outerBorder']}>
                        <div id="text"  className={style['text']}>100%</div>
                        <div id="border"  className={style['border']}>
                            <div id="bar"  className={style['bar']} style={{width: '496px'}} />
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

NetworkNav.propTypes = {
    readonly: PropTypes.bool,
    mode: PropTypes.oneOf(['default', 'nav', 'viewer']), // 组件的模式：nav=导航状态，normal=正常状态
    edges: PropTypes.array,
    className: PropTypes.string,// 组件的外部容器的 classname
    // 传入的配置项，要传一起传
    options: PropTypes.shape({
        filterField: PropTypes.string, // 过滤数据字段
        classifyField: PropTypes.string, // 分类字段
    }),
    noticeAsEmpty: PropTypes.func, // 最终绘图时，如结果为空，则通知上级组件
    bus: PropTypes.instanceOf(FilterAndStatisticsBus).isRequired,
    onExplore: PropTypes.func.isRequired,
    onVote: PropTypes.func.isRequired,
};

NetworkNav.defaultProps = {
    mode: 'default',
    edges: [],
    className: null,
    options: {
        filterField: 'meta.prov',
        classifyField: 'meta.prov',
    },
    noticeAsEmpty: () => {
        console.log('MapNav->defaultProps->noticeAsEmpty emited')
    },
};

export default NetworkNav;
