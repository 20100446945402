/*
 * @Author: Carr.s
 * @Date: 2022-10-14 09:36:32
 * @LastEditTime: 2022-10-14 09:36:32
 * @LastEditors: Carr.s
 * @Description: 频道专题页面模板
 * @Copyright by joinmap
 */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Route, Redirect, Switch as SwitchR } from "react-router-dom";
import PB from "@/libs/simplePB";
import ViewsList from "@/components/dashboard/dashboard.views";
import Dashboard from "@/components/dashboard/dashboard.default";
import ResultView from "@/components/dashboard/dashboard.resultView";
import layoutStyles from "@/style/default/defaultViewLayout.less";
import style from "@/style/components/channelView/channel.less";
import intl from 'react-intl-universal';

// --------------------------------------------------------------------
// 频道瀑布
// --------------------------------------------------------------------
class TplOfChannel extends React.Component {
  state = {
    // 频道栏宽度
    channelCatesWidth: 160,
    // 计算图谱列表容器左右间距
    contentWrapSpacing: 100,
  };
  // 本组件路由前缀
  routePath = undefined;
  // 频道标题
  title = undefined;
  // 频道副标题
  subTitle = undefined;

  // 中上部筛选标签(示例, 继承的组件必须重载)
  topMenus = [
    { name: "我是组长", path: `${this.routePath}/userview`, module: "my" },
    {
      name: "我是成员",
      path: `${this.routePath}/teamwork`,
      module: "teamwork",
    },
    { name: "开放阅读", path: `${this.routePath}/public`, module: "public" },
    {
      name: "开放协作",
      path: `${this.routePath}/cooperation`,
      module: "cooperation",
    },
    { name: "开放复制", path: `${this.routePath}/free`, module: "free" },
    //   {
    //     name: "事件关联",
    //     path: `${this.routePath}/eventcorrelation`,
    //     module: "eventcorrelation",
    //   },
    //   { name: "最新动态", path: `${this.routePath}/latest`, module: "latest" },
    // {name: '搜索图谱', path: `${this.routePath}/result`},
  ];

  // 子标签(左侧栏目)
  channelCates = ["课件", "科普", "笔记", "思政", "比赛"];

  // 搜索 所有图谱
  searchViewByTag = (searchTag) => {
    this.props.history.push({
      pathname: `${this.routePath}/search`,
      state: { searchTag },
    });
  };

  /**
   * 浏览器窗口大小改变
   * New Feature: 计算 ViewList 相对父容器宽度的两侧空白
   */
  handleResize = () => {
    const { channelCatesWidth } = this.state;
    // 图谱卡片占的宽度，单位rem，需要跟据html的响应变化基准
    const viewInfoCardWidth = 26;
    // 相对容器的宽度
    let outerWidth = window.innerWidth - channelCatesWidth;
    if (this.channelCates === false) {
      outerWidth = window.innerWidth;
    }
    // body 的字体大小 // html的响应变化基准
    const bodyFontSize = parseInt(
      window.getComputedStyle(document.body).fontSize
    );
    // 算出卡片宽度
    const cardWidth = viewInfoCardWidth * bodyFontSize;
    // 算出两边空白数值
    const paddingVal =
      (outerWidth - Math.floor(outerWidth / cardWidth) * cardWidth) / 2;
    this.setState({ contentWrapSpacing: paddingVal });
  };

  componentWillMount() {
    const { loginStatus } = this.props;
    if (loginStatus !== 0) {
      this.props.history.push("/login");
    }
  }

  componentWillUnmount() {
    PB.remove(this);
    window.removeEventListener("resize", this.handleResize); // 取消监听窗口大小变化
  }

  componentDidMount() {
    let me = this;
    //监听窗口大小改变
    this.handleResize();
    window.addEventListener("resize", this.handleResize);

    // 来自顶部搜索条的搜索事件
    PB.sub(this, "search", "search.view.searchWord", ({ searchWord }) => {
      me.props.history.push({
        pathname: `${this.routePath}/search`,
        state: { searchWord, channel: 0 },
      });
    });
  }

  render() {
    const { title, subTitle, topMenus, channelCates } = this;
    // 分类按钮
    const { contentWrapSpacing = 100, channelCatesWidth } = this.state;
    // 频道参数
    const { channel } = this.props;
    // 全局搜索的关键字参数 => 顶部搜索条传入
    let q = undefined;
    // 频道标签参数
    let channelTag = undefined;
    const { location } = this.props.history;
    if (location) {
      const { state } = location;
      if (state) {
        const { searchTag = undefined, searchWord = undefined } = state;
        channelTag = searchTag;
        q = searchWord;
      }
    }

    // const viewListMarginLeft = contentWrapSpacing - 0.5;
    const viewListMarginLeft = 0;

    // 渲染 频道标签
    let innerChannelCatesWidth = 0;
    const channelTagJsx = [];
    if (channelCates !== false) {
      innerChannelCatesWidth = channelCatesWidth;
      channelCates.forEach((cate, idx) => {
        channelTagJsx.push(
          <div
            key={idx + cate}
            className={
              channelTag === cate
                ? `${style["channel-cate-item"]} ${style["active"]}`
                : `${style["channel-cate-item"]}`
            }
            onClick={() => this.searchViewByTag(cate)}
          >
            <span>{cate}</span>
          </div>
        );
      });
    }

    return (
      <div
        className={`${layoutStyles["content-outer"]} dark-theme scrollbar-none`}
        style={{
          alignContent: "flex-start",
          position: "relative",
          padding: `40px ${contentWrapSpacing}px`,
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
            marginBottom: "10px",
          }}
        >
          <div
            style={{
              flex: "0 1 auto",
              fontSize: "1.8rem",
              fontWeight: 600,
            }}
          >
            {title}
          </div>
          <div style={{ flex: "1", paddingLeft: "20px" }}>{subTitle}</div>
          <div className={style["create-btn-outer"]}>
            <img
              src="/assets/icons/create2.png"
              className={style["create-btn-img"]}
              style={{
                width: "42px",
              }}
              alt={intl.get('Custom.view.add')}
            />
            <div
              type="primary"
              size="large"
              className={style["create-btn"]}
              onClick={() => {
                PB.emit("dashboard", "dashboard.createViewModal.show", {
                  status: true,
                });
              }}
            >
              {intl.get('Custom.view.add')}
            </div>
          </div>
        </div>

        <div style={{ display: "flex" }}>
          {channelCates === false ? null : (
            <div
              className={style["cannel-cates"]}
              style={{
                width: `${innerChannelCatesWidth}px`,
                paddingTop: "calc(2.5rem + 32px + 1rem + 1rem)",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {channelTagJsx.map((i) => i)}
            </div>
          )}
        
          <div style={{ flex: 1 }}>
            <SwitchR>
              {/* 九宫格，已废弃 */}
              <Route
                path={`${this.routePath}/default`}
                render={({ history }) => (
                  <Dashboard title={"炬图"} history={history} />
                )}
              />

              <Route
                path={`${this.routePath}/free`}
                render={({ history }) => (
                  // 公开复制
                  <ViewsList
                    history={history}
                    module={"free"}
                    channel={channel}
                    navOptionList={topMenus}
                    contentWrapSpacing={viewListMarginLeft}
                  />
                )}
              />
              <Route
                path={`${this.routePath}/all`}
                render={({ history }) => (
                  // 全部
                  <ViewsList
                    history={history}
                    module={"all"}
                    channel={channel}
                    navOptionList={topMenus}
                    contentWrapSpacing={viewListMarginLeft}
                  />
                )}
              />
              <Route
                path={`${this.routePath}/public`}
                render={({ history }) => (
                  // 公开阅读
                  <ViewsList
                    history={history}
                    module={"public"}
                    channel={channel}
                    navOptionList={topMenus}
                    contentWrapSpacing={viewListMarginLeft}
                  />
                )}
              />
              <Route
                path={`${this.routePath}/userview`}
                render={({ history }) => (
                  // 我创建的
                  <ViewsList
                    history={history}
                    module={"my"}
                    channel={channel}
                    navOptionList={topMenus}
                    contentWrapSpacing={viewListMarginLeft}
                  />
                )}
              />
              <Route
                path={`${this.routePath}/teamwork`}
                render={({ history }) => (
                  // 我参与的
                  <ViewsList
                    history={history}
                    module={"teamwork"}
                    channel={channel}
                    navOptionList={topMenus}
                    contentWrapSpacing={viewListMarginLeft}
                  />
                )}
              />
              <Route
                path={`${this.routePath}/cooperation`}
                render={({ history }) => (
                  // 开放协作
                  <ViewsList
                    history={history}
                    module={"cooperation"}
                    channel={channel}
                    navOptionList={topMenus}
                    contentWrapSpacing={viewListMarginLeft}
                  />
                )}
              />
              <Route
                path={`${this.routePath}/eventcorrelation`}
                render={({ history }) => (
                  // 事件关联
                  <ViewsList
                    history={history}
                    module={"eventcorrelation"}
                    channel={channel}
                    navOptionList={topMenus}
                    contentWrapSpacing={viewListMarginLeft}
                  />
                )}
              />
              <Route
                path={`${this.routePath}/latest`}
                render={({ history }) => (
                  // 事件关联
                  <ViewsList
                    history={history}
                    module={"latest"}
                    channel={channel}
                    navOptionList={topMenus}
                    contentWrapSpacing={viewListMarginLeft}
                  />
                )}
              />
              <Route
                path={`${this.routePath}/search`}
                render={(props) => (
                  // 搜索看板
                  <ResultView
                    {...props}
                    history={props.history}
                    currentPath={`${this.routePath}/search`}
                    searchWord={this.state.searchWord}
                    searchParams={{
                      word: q,
                      channel: channel,
                      tag: channelTag,
                    }}
                    // search={this.searchView} // 要做什么的?
                    search={() => {}}
                    navOptionList={topMenus}
                    contentWrapSpacing={viewListMarginLeft}
                    channel={channel}
                  />
                )}
              />
              <Route render={() => <Redirect to={topMenus[2].path} />} />
            </SwitchR>
          </div>
          <div style={{ clear: "both" }}></div>
        </div>
      </div>
    );
  }
}
TplOfChannel.defaultProps = {};

TplOfChannel.propTypes = {
  currentUserId: PropTypes.number,
  channel: PropTypes.number,
  query: PropTypes.object,
  // y: PropTypes.number,
  // bus: PropTypes.instanceOf(SimplePB),
  // node: PropTypes.instanceOf(Node),
  // nodes: PropTypes.arrayOf(PropTypes.instanceOf(Node)).isRequired,
  // edges: PropTypes.arrayOf(PropTypes.instanceOf(Edge)).isRequired,
  // network: PropTypes.instanceOf(Network).isRequired,
  // readOnly: PropTypes.bool,
  // currentUserId: PropTypes.number.isRequired,
  // enableCrossEdit: PropTypes.bool,
  // viewOwnerId: PropTypes.number.isRequired,
  // visible: PropTypes.bool,
};

export default TplOfChannel;
