import React from 'react';
import PropTypes from 'prop-types';
import style from "@/style/common/common.hoverContent.less";

class HoverContent extends React.PureComponent {
  element = undefined; // dom node

  handleKeyDown = (e) => {
    let me = this;
    console.log("HoverContent -> 按下了键盘键");
    if (e.keyCode === 27) {
      console.log("HoverContent -> 按下了ESC键");
      me.props.onClose();
    }
  };

  componentDidMount() {
    document.addEventListener('keydown',this.handleKeyDown);
  }
  componentWillUnmount() {
    document.removeEventListener('keydown',this.handleKeyDown);
  }

  render() {
    let me = this;

    return (
      <div
        className={style['hover-content-frame-container']}
        onClick={me.props.onClose}
        style={{zIndex: me.props.frameZIndex}}
      >
        <div className={style['hover-content-frame']}>
          <div className={style['hover-content-frame-lt']} />
          <div className={style['hover-content-frame-ct']} />
          <div className={style['hover-content-frame-rt']} />
          <div className={style['hover-content-frame-lc']} />
          <div className={style['hover-content-frame-rc']} />
          <div className={style['hover-content-frame-lb']} />
          <div className={style['hover-content-frame-cb']} />
          <div className={style['hover-content-frame-rb']} />
          <div
            className={style['hover-content-frame-inner']}
          >
            {me.props.children}
            {
              me.props.showInnerCover &&
              <div className={style['hover-content-frame-inner-cover']} style={{zIndex: me.props.innerCoverZIndex}} />
            }
          </div>
        </div>
      </div>
    )
  }
}

HoverContent.defaultProps = {
  frameZIndex: 1,
  innerCoverZIndex: 2,
  showInnerCover: true,
};

HoverContent.propTypes = {
  frameZIndex: PropTypes.number,
  innerCoverZIndex: PropTypes.number,
  showInnerCover: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

export default HoverContent;