import React from "react";
import PropTypes from 'prop-types';
import moment from 'moment';
import momentLocale from 'moment/locale/zh-cn';
import SolarLunar from 'solarlunar';
import {Tooltip} from "antd";
import _ from 'lodash';
import intl from 'react-intl-universal';

// moment 使用中文
moment.updateLocale(intl.get('locale'), momentLocale);

const today = moment().startOf('day');
const yesterday = moment().startOf('day').subtract(1, 'days');
const beforeYesterday = moment().startOf('day').subtract(2, 'days');
const thisMonday = moment().startOf('day').subtract(moment().weekday(), 'days');
const beforeMonday = moment(thisMonday).subtract(7, 'days');
const thisMonth = moment().startOf('month');
const beforeMonth = moment(thisMonth).subtract(1, 'month');
const fistDayOfYear = moment().startOf('year');

const weekName = ['周一', '周二', '周三', '周四', '周五', '周六', '周日'];

const tomorrow = moment().startOf('day').add(1, 'days');
const afterTomorrow = moment().startOf('day').add(2, 'days');
const threeDaysAfter = moment().startOf('day').add(3, 'days');
const nextMonday = moment(thisMonday).add(7, 'days');
const afterNextMonday = moment(nextMonday).add(7, 'days');
const nextMonth = moment(thisMonth).add(1, 'month');
const afterNextMonth = moment(thisMonth).add(2, 'month');

class TimeDisplayField extends React.PureComponent {
  refreshInterval = undefined;

  getDisplayObject = () => {
    let me = this, result = {
      shortText: false,
      fullText: false,
      weekday: false,
      fromNow: false,
      lunarYearText: false,
      lunarMonthText: false,
      lunarDayText: false,
      fromNowDays: false,
    }, targetMoment = moment(me.props.timestamp), lunarData;

    if (targetMoment.isValid()) {
      if (targetMoment.year() >= 1900 && targetMoment.year() <= 2100) {
        lunarData = SolarLunar.solar2lunar(targetMoment.year(), targetMoment.month() + 1, targetMoment.date());
        result.lunarMonthText = lunarData.monthCn;
        result.lunarDayText = lunarData.dayCn;
        result.lunarYearText = lunarData.gzYear;
      }

      if (targetMoment.valueOf() <= today.valueOf()) {
        if (today <= targetMoment) {
          result.shortText = targetMoment.format('今天 HH:mm');
        } else if (yesterday <= targetMoment) {
          result.shortText = targetMoment.format('昨天 HH:mm');
        } else if (beforeYesterday <= targetMoment) {
          result.shortText = targetMoment.format('前天 HH:mm');
        } else if (thisMonday <= targetMoment) {
          result.shortText = '本' + weekName[targetMoment.weekday()] + targetMoment.format(' HH:mm');
        } else if (beforeMonday <= targetMoment) {
          result.shortText = '上' + weekName[targetMoment.weekday()] + targetMoment.format(' HH:mm');
        } else if (beforeMonth <= targetMoment) {
          result.shortText = -(targetMoment.diff(moment(), 'days') - 1) + '天前' + targetMoment.format(' HH:mm');
        } else if (fistDayOfYear <= targetMoment) {
          result.shortText = targetMoment.format('MM-DD HH:mm');
        } else {
          result.shortText = targetMoment.format('YYYY-MM-DD');
        }
      } else {
        if (targetMoment < tomorrow) {
          result.shortText = targetMoment.format('今天 HH:mm');
        } else if (targetMoment < afterTomorrow) {
          result.shortText = targetMoment.format('明天 HH:mm');
        } else if (targetMoment < threeDaysAfter) {
          result.shortText = targetMoment.format('后天 HH:mm');
        } else if (targetMoment < nextMonday) {
          result.shortText = '本' + weekName[targetMoment.weekday()] + targetMoment.format(' HH:mm');
        } else if (targetMoment < afterNextMonday) {
          result.shortText = '下' + weekName[targetMoment.weekday()] + targetMoment.format(' HH:mm');
        } else if (targetMoment < nextMonth) {
          result.shortText = targetMoment.diff(moment(), 'days') + 1 + '天后' + targetMoment.format('HH:mm');
        } else if (targetMoment < afterNextMonth) {
          result.shortText = targetMoment.format('MM-DD HH:mm');
        } else {
          result.shortText = targetMoment.format('YYYY-MM-DD');
        }
      }

      result.fullText = targetMoment.format('YYYY-MM-DD HH:mm:ss');
      result.weekday = targetMoment.format('dddd');
      result.fromNow = moment().valueOf() - targetMoment.valueOf() > 0 ? `${targetMoment.fromNow(true)}前` : `${targetMoment.fromNow(true)}后`;
      result.fromNowDays = -targetMoment.diff(moment(), 'days');
    }

    return result;
  };

  getDisplayObject_en = () => {
    let me = this, result = {
      shortText: false,
      fullText: false,
      weekday: false,
      fromNow: false,
      lunarYearText: false,
      lunarMonthText: false,
      lunarDayText: false,
      fromNowDays: false,
    }, targetMoment = moment(me.props.timestamp), lunarData;

    if (targetMoment.isValid()) {
      if (targetMoment.year() >= 1900 && targetMoment.year() <= 2100) {
        lunarData = SolarLunar.solar2lunar(targetMoment.year(), targetMoment.month() + 1, targetMoment.date());
        result.lunarMonthText = lunarData.monthCn;
        result.lunarDayText = lunarData.dayCn;
        result.lunarYearText = lunarData.gzYear;
      }

      if (targetMoment.valueOf() <= today.valueOf()) {
        if (fistDayOfYear <= targetMoment) {
          result.shortText = targetMoment.format('MM-DD HH:mm');
        } else {
          result.shortText = targetMoment.format('YYYY-MM-DD');
        }
      } else {
        if (targetMoment < afterNextMonth) {
          result.shortText = targetMoment.format('MM-DD HH:mm');
        } else {
          result.shortText = targetMoment.format('YYYY-MM-DD');
        }
      }

      result.fullText = targetMoment.format('YYYY-MM-DD HH:mm:ss');
      result.weekday = targetMoment.format('dddd');
      result.fromNow = moment().valueOf() - targetMoment.valueOf() > 0 ? `${targetMoment.fromNow(true)} before` : `${targetMoment.fromNow(true)} after`;
      result.fromNowDays = -targetMoment.diff(moment(), 'days');
    }

    return result;
  };

  componentDidMount() {
    this.refreshInterval = setInterval(() => this.forceUpdate(), 60000);
  }

  componentWillUnmount() {
    clearInterval(this.refreshInterval);
  }

  render() {
    let me = this, displayObject = intl.get('locale')==='zh-cn'?me.getDisplayObject():me.getDisplayObject_en();

    switch (me.props.detailType) {
      case 'custom':
        let spanProps = {};
        if (_.isFunction(me.props.customCallback)) {
          let fn = () => me.props.customCallback(displayObject);
          switch (me.props.detailTrigger) {
            case 'click':
              spanProps.onClick = fn;
              break;
            case 'hover':
              spanProps.onMouseOver = fn;
              break;
            case 'focus':
              spanProps.onFocus = fn;
              break;
            case 'contextMenu':
              spanProps.onContextMenu = fn;
              break;
          }
        }

        return (
          <span {...spanProps}>{displayObject.shortText}</span>
        );
      case 'tooltip':
        return (
          <Tooltip
            placement={me.props.tooltipPlacement}
            trigger={me.props.detailTrigger}
            title={intl.get('locale')==='zh-cn'?(
              <div className={'dark-theme'}>
                {me.props.description ? (
                  <span>{me.props.description}<br/></span>
                ) : null }
                {displayObject.fullText}<br />
                {displayObject.weekday} 农历{displayObject.lunarMonthText}{displayObject.lunarDayText}<br />
                {displayObject.fromNow}{displayObject.fromNowDays >= 26 ? `，距今${displayObject.fromNowDays}天` : null}
              </div>
            ):(
              <div className={'dark-theme'}>
                {me.props.description ? (
                  <span>{me.props.description}<br/></span>
                ) : null }
                {displayObject.fullText}
              </div>
            )}
          >
            {me.props.children || me.props.content(displayObject)}
          </Tooltip>
        );
    }
  }
}

TimeDisplayField.defaultProps = {
  detailType: 'tooltip',
  detailTrigger: 'hover',
  tooltipPlacement: 'top',
  description: '',
  content: (displayObject) => (<span>{displayObject.shortText}</span>),
};

TimeDisplayField.propTypes = {
  timestamp: PropTypes.number.isRequired,
  description: PropTypes.string,
  detailType: PropTypes.oneOf(['tooltip', 'custom']),
  detailTrigger: PropTypes.oneOf(['hover', 'focus', 'click', 'contextMenu']),
  customCallback: PropTypes.func,
  content: PropTypes.func,
  tooltipPlacement: PropTypes.oneOf([
    'top', 'left', 'right', 'bottom', 'topLeft', 'topRight', 'bottomLeft', 'bottomRight', 'leftTop', 'leftBottom',
    'rightTop', 'rightBottom',
  ]),
};

export default TimeDisplayField;