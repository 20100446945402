import React from "react";
import {connect} from 'react-redux'
import {Tooltip,Popover} from 'antd';
import {
  changePwdInitAction,
  changeUserBasicInfoAction,
  doChangePwdAction,
  doLogoutAction,
} from "@/redux-saga/account/actions";
import PB from "@/libs/simplePB";
import {Copyright} from "@/components/framework/frame.components";
import Dashboard from "@/components/dashboard/dashboard.default";
import style from "@/style/containers/indexView.less";

import StickyNodes from '@/components/common/common.stickyNodes';
import NodeTodo from '@/components/common/node/todo/common.node.todo';
import Feedback from '@/components/feedback/feedback.1.0';
import { submitFeedbackAction } from '@/redux-saga/system/actions';
import { SysUIConfig } from '@/constants/sys.config';
import Icon from '@/components/common/common.icon';
import intl from 'react-intl-universal';
class IndexView extends React.PureComponent {

  state = {
    contentType: 'simple-intro',
    index: -1,
    active: false,
    smallScreen: 1,
    widthImgBase: 20,
    state_tooltip: [false,false]
  };

  timer = undefined;

  swiperMainImg = '/assets/home/index-bg.jpg';

  swiperData = [
    {
      img:'/assets/home/index-bg0.jpg',
      icon:'/assets/home/index-icon0.png',
      title:intl.get('Custom.leader.swiperTitle_0'),
      content:intl.get('Custom.leader.swiperContent_0'),
    },{
      img:'/assets/home/index-bg1.jpg',
      icon:'/assets/home/index-icon1.png',
      title:intl.get('Custom.leader.swiperTitle_1'),
      content:intl.get('Custom.leader.swiperContent_1'),
    },{
      img:'/assets/home/index-bg2.jpg',
      icon:'/assets/home/index-icon2.png',
      title:intl.get('Custom.leader.swiperTitle_2'),
      content:intl.get('Custom.leader.swiperContent_2'),
    },{
      img:'/assets/home/index-bg3.jpg',
      icon:'/assets/home/index-icon3.png',
      title:intl.get('Custom.leader.swiperTitle_3'),
      content:intl.get('Custom.leader.swiperContent_3'),
    },{
      img:'/assets/home/index-bg4.jpg',
      icon:'/assets/home/index-icon4.png',
      title:intl.get('Custom.leader.swiperTitle_4'),
      content:intl.get('Custom.leader.swiperContent_4'),
    },{
      img:'/assets/home/index-bg5.jpg',
      icon:'/assets/home/index-icon5.png',
      title:intl.get('Custom.leader.swiperTitle_5'),
      content:intl.get('Custom.leader.swiperContent_5'),
    },{
      img:'/assets/home/index-bg6.jpg',
      icon:'/assets/home/index-icon6.png',
      title:intl.get('Custom.leader.swiperTitle_6'),
      content:intl.get('Custom.leader.swiperContent_6'),
    }
  ];


  turn = step => {
    let index = this.state.index + step;
    if (index >= this.swiperData.length) {
      index = 0;
    }
    if (index < 0) {
      index = this.swiperData.length - 1;
    }
    this.setState({ index: index })
  };

  swiperGo = () => {
    this.timer = setInterval(
      () => this.turn(1),
      6000,
    )
  };

  swiperClear = (index) => {
      clearInterval(this.timer);
      this.setState({ active: true,index:index });
  };

  go = (index) => {
    if(index === this.state.index && this.state.active){
      this.setState({ active: false });
      this.swiperGo();
    }
  };

  overSwiper = (index) => {
    this.setState({ active: true,index:index });
  };

  outSwiper = (index) => {
    this.setState({ active: false,index:-1 });
  };

  setStateTooltip = (val) => {
    let me = this;
    let state_tooltip = me.state.state_tooltip;
    state_tooltip[val] = true;
    me.setState({ state_tooltip });
  };

  componentDidMount() {
    let me = this;
    if (this.props.location.state && this.props.location.state.type) {
      me.setState({contentType: this.props.location.state.type});
    } else {
      setTimeout(() => me.setState({contentType: 'intro'}), 2000);
    }

    // 便签小窗口显示隐藏
    PB.sub(this,  'user', 'stickyNodes.show', ({show}) => {
      me.setState({showStickyNodesWindow: show});
    });
    // 便签小窗口内容有变化
    PB.sub(this,  'user', 'stickyNodes.hasChange', ({hasSave = false, hasUpdate = false}) => {
      me.setState({hasChangeInStickyNodes: hasSave || hasUpdate});
    });
    //this.swiperGo();
    let ScreenObject_1 = window.matchMedia('(max-height:600px)').matches?1:0;
    let ScreenObject_2 = window.matchMedia('(max-height:800px)').matches?1:0;
    let ScreenObject_3 = window.matchMedia('(max-height:1000px)').matches?1:0;
    let ScreenObject_4 = window.matchMedia('(max-height:1200px)').matches?1:0;
    let ScreenObject_5 = window.matchMedia('(max-height:1500px)').matches?1:0;
    let ScreenObject_6 = window.matchMedia('(max-height:1800px)').matches?1:0;
    let ScreenObject_7 = window.matchMedia('(max-height:2200px)').matches?1:0;
    let smallScreen = 7 - ScreenObject_1 - ScreenObject_2 - ScreenObject_3 - ScreenObject_4 - ScreenObject_5 - ScreenObject_6 - ScreenObject_7;
    me.setState({smallScreen,widthImgBase:smallScreen*3+5});
  }

  componentDidUpdate() {
    let ScreenObject_1 = window.matchMedia('(max-height:600px)').matches?1:0;
    let ScreenObject_2 = window.matchMedia('(max-height:800px)').matches?1:0;
    let ScreenObject_3 = window.matchMedia('(max-height:1000px)').matches?1:0;
    let ScreenObject_4 = window.matchMedia('(max-height:1200px)').matches?1:0;
    let ScreenObject_5 = window.matchMedia('(max-height:1500px)').matches?1:0;
    let ScreenObject_6 = window.matchMedia('(max-height:1800px)').matches?1:0;
    let ScreenObject_7 = window.matchMedia('(max-height:2200px)').matches?1:0;
    let smallScreen = 7 - ScreenObject_1 - ScreenObject_2 - ScreenObject_3 - ScreenObject_4 - ScreenObject_5 - ScreenObject_6 - ScreenObject_7;
    this.setState({smallScreen,widthImgBase:smallScreen*3+5});
  }

  componentWillUnmount() {
    PB.remove(this);
  }

  render() {
    let me = this;   //185---1.5->2

    return (
      <div className={`dark-theme ${style['frame']}`}>
        {
          me.state.contentType === 'intro' ? (
            <div className={style['intro-frame']}>
              <div className={style.root}>
                  <div style={{opacity:this.state.active?'1':'0.3'}}
                    className={style.center} >
                    <img src={ this.state.index===-1?this.swiperMainImg:this.swiperData[this.state.index].img } />
                  </div>
              </div>
              <div className={style['intro-box']} style={{display:this.state.active?'none':'',top:this.state.smallScreen*this.state.smallScreen/6+'rem'}}>
                <div className={`${style['intro-step']} ${style['intro-step-1']}`} style={{marginBottom: 0}}>
                  <img src={'/assets/joinmap.png'} alt={intl.get('Custom.general.titleMeta')} style={{width:this.state.widthImgBase*0.6+'rem'}}/>
                </div>
                <div className={`${style['intro-step']} ${style['intro-step-1']}`} style={{marginTop: 0}}>
                  <img src={intl.get('locale')==='zh-cn'?'/assets/home/index-1-1.png':'/assets/home/index-1-1_en.png'} alt={intl.get('Custom.general.titleMeta')} style={{width:this.state.widthImgBase*2+'rem'}}/>
                </div>
              </div>
              <div className={style['intro-box']} style={{display:this.state.active?'none':'',top:'auto'}}>
                <div className={`${style['intro-step']} ${style['intro-step-2']}`}>
                  <img
                    style={{width:this.state.widthImgBase+'rem'}}
                    src={intl.get('locale')==='zh-cn'?'/assets/home/index-3-1.png':'/assets/home/index-3-1_en.png'}
                    alt={intl.get('Custom.general.join')}
                    onClick={() => me.props.history.push('/mainview/dashboard/public')}
                  />
                </div>
              </div>
              <div className={style['intro-box']} style={{marginTop:this.state.smallScreen*3+'rem',display:this.state.active?'':'none'}}>
                {this.state.index >-1 && this.swiperData[this.state.index].content.map((item, index) => (
                  <div key={index} className={`${style['intro-step']} ${style['intro-step-1']} ${style['swiper-text']}`}>
                    {item}
                  </div>
                ))}
              </div>
              <div className={`${style['intro-step']} ${style['swiper-box']}`} style={{bottom:this.state.smallScreen*1+'rem',display:'inline'}}>
                <div className={style['swiper-row']}>
                { this.swiperData.map((item, index) => (
                  <div key={index} onMouseOver={() =>this.overSwiper(index)} onMouseOut={() =>this.outSwiper(index)} className={style.active}>
                    <p style={{marginBottom:this.state.smallScreen/4+'rem'}}><img src={ item.icon }></img></p>
                    <p style={{fontSize:this.state.smallScreen/4+'rem'}}>{ item.title }</p>
                  </div>
                ))
                }
                </div>
              </div>
            </div>
          ) : (
            me.state.contentType === 'simple-intro' ? (
              <div className={style['intro-frame']}>
                <div key={Math.random()} className={`${style['intro-step']} ${style['intro-step-1']}`} style={{marginTop:'-15rem'}} >
                  <img src={intl.get('locale')==='zh-cn'?'/assets/home/index-1-1.png':'/assets/home/index-1-1_en.png'} alt={intl.get('Custom.general.titleMeta')}/>
                </div>
              </div>
            ) : (
              <div className={style['view-list-frame']}>
                <Dashboard
                  title={intl.get('Custom.general.title')}
                  history={me.props.history}
                />
              </div>
            )
          )
        }
        <div className={style['logo']}>
          <img
            onClick={() => {
              /*if (me.state.contentType === 'intro') {
								me.setState({contentType: 'simple-intro'});
								setTimeout(() => me.setState({contentType: 'view-list'}), 2000);
							} else if (me.state.contentType === 'view-list') {
								me.setState({contentType: 'intro'});
							}*/
              me.setState({contentType: 'intro'});
            }}
            src={intl.get('locale')==='zh-cn'?'/assets/logo-hans.png':'/assets/logo-hans_en.png'}
            alt={intl.get('Custom.general.title')}
          />
        </div>
        <Copyright className={style['copyright']} locale={intl.get('Custom.locale')}/>
        <Feedback
          feedbackLoading={this.props.feedbackLoading}
          feedbackResult={this.props.feedbackResult}
          submitFeedbackAction={this.props.submitFeedbackAction}
        />
        <StickyNodes />
        {/*<NodeTodo currentUserId={me.props.userInfo.userId} />*/}
        <div className={style.rightlogo} style={{display:'none'}}>
          <Tooltip trigger='click' placement='top'>
            <div className={style.rightlogointem} onClick={() => {window.open('https://space.bilibili.com/1839363891', '_blank');}}>
            <i className={style.wx_channels}><img src={'/assets/home/bilibili.png'}/></i>
              <span style={{fontSize:this.state.smallScreen/10+'rem'}}>{intl.get('Custom.outLink.bilibili')}</span>
            </div>
          </Tooltip>
          <Tooltip trigger='click' placement='top' title={<div className={style['control-list']}><img src={'/assets/home/wx_channels_code.jpg'}/></div>}>
            <div className={style.rightlogointem}>
              <i className={style.wx_channels}><img src={'/assets/home/wx_channels.png'}/></i>
              <span style={{fontSize:this.state.smallScreen/10+'rem'}}>{intl.get('Custom.outLink.wx_video')}</span>
            </div>
          </Tooltip>
          <Tooltip trigger='click' placement='top' title={<div className={style['control-list']}><img src={'/assets/home/wx_mini_p.jpg'}/></div>}>
            <div className={style.rightlogointem}>
              <i className={style.wx_channels}><img src={'/assets/home/wx_mini.png'}/></i>
              <span style={{fontSize:this.state.smallScreen/10+'rem'}}>{intl.get('Custom.outLink.wx_mini')}</span>
            </div>
          </Tooltip>
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    userInfo: state.account.userInfo,
    changePwdStatus: state.account.changePwdStatus,
    feedbackLoading: state.system.feedbackLoading,
    feedbackResult: state.system.feedbackResult,
    changeUserBasicInfoStatus: state.account.changeUserBasicInfoStatus,
  }),
  dispatch => ({
    doLogout: () => dispatch(doLogoutAction()),
    submitFeedbackAction: (params) => dispatch(submitFeedbackAction(params)),
    doChangePwd: (oldPwd, newPwd, strength) => dispatch(doChangePwdAction(oldPwd, newPwd, strength)),
    resetChangePwdStatus: () => dispatch(changePwdInitAction()),
    onChangeUserBasicInfo: (userInfo) => dispatch(changeUserBasicInfoAction(userInfo)),
  })
)(IndexView);