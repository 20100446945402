/*
 * @Author: Carr.s
 * @Date: 2022-04-26 11:30:34
 * @LastEditTime: 2022-04-27 18:10:42
 * @LastEditors: Carr.s
 * @Description: 产业链洞察
 * @Copyright by joinmap
 */
import React from "react";
import { Checkbox, Empty, message, Spin, Tooltip, Button, Tabs } from "antd";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import Qs from "qs";

import { Copyright } from "@/components/common/common.copyright";
import Loading from "@/components/common/common.loading";

import layoutStyles from "@/style/default/defaultViewLayout.less";
import styles from "@/style/components/insightView/insight.less";

import Icon from "@/components/common/common.icon";
import { IconTypes } from "@/constants/common";
// 组件
import ViewIndustrialChainAInsight from "@/components/insightView/insight.industrial.chain.a";
import ViewIndustrialChainBInsight from "@/components/insightView/insight.industrial.chain.b";
// import ViewIndustrialChainBInsight from "@/components/insightView/insight.industrial.chain.b.3d";
import ViewIndustrialChainCInsight from "@/components/insightView/insight.industrial.chain.c";

// const { Content } = Layout;
const { TabPane } = Tabs;

class InsightIndustrialChainContainer extends React.Component {
  componentDidMount() {
    document.title = "产业链洞察 - 炬图";
  }

  render() {
    const { query, userInfo, history } = this.props;
    return (
      <div
        style={{
          paddingTop: 60,
          height: "100%",
        }}
      >
        <Tabs
          centered={true}
          tabBarStyle={{
            textAlign: "center",
          }}
          style={{
            height: "100%",
          }}
        >
          <TabPane
            tab={
              <span style={{ fontSize: 16 }}>
                <Icon name="pie-chart" /> 产业链企业分类统计图
              </span>
            }
            key="1"
          >
            <ViewIndustrialChainAInsight
              history={history}
              userInfo={userInfo}
              query={query}
            />
          </TabPane>
          <TabPane
            tab={
              <span style={{ fontSize: 16 }}>
                <Icon name="bar-chart" />
                产业链链点分布图
              </span>
            }
            key="2"
          >
            <ViewIndustrialChainBInsight
              history={history}
              userInfo={userInfo}
              query={query}
            />
          </TabPane>
          <TabPane
            tab={
              <span style={{ fontSize: 16 }}>
                <Icon name="radar-chart" />
                产业链关键环节雷达图
              </span>
            }
            key="3"
          >
            <ViewIndustrialChainCInsight
              history={history}
              userInfo={userInfo}
              query={query}
            />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

export default InsightIndustrialChainContainer;
