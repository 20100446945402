import {showPageLoading} from "@/components/common/common.message";

/**
 * @param messageMap
 * @param overrideMessages
 * @return {{success: messageProxyOnSuccess, failure: messageProxyOnFailure, processing: messageProxyOnProcessing}}
 */
const messageProxy = (messageMap, overrideMessages) => ({
  /**
   * @var {messageProxyOnProcessing}
   */
  processing: (key) => {
    let msg = messageMap[key];
    if (overrideMessages[key] !== undefined) {
      msg = overrideMessages[key];
      delete overrideMessages[key];
    }
    if (msg !== false) {
      return showPageLoading(msg);
    } else {
      return () => {
      };
    }
  },
  /**
   * @var {messageProxyOnSuccess}
   */
  success: (key, onProcessingResult) => {
    onProcessingResult();
  },
  /**
   * @var {messageProxyOnFailure}
   */
  failure: (key, onProcessingResult) => {
    onProcessingResult();
  },
});

export default messageProxy;