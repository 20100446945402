/*
 * @Author: Carr.s
 * @Date: 2022-09-05 18:09:32
 * @LastEditTime: 2022-09-06 10:28:54
 * @LastEditors: Carr.s
 * @Description: 合肥一中专题页面
 * @Copyright by joinmap
 */
import React from "react";
import { connect } from "react-redux";
import TplOfChannel from "@/components/channelView/channel.tpl";
// --------------------------------------------------------------------
// 合肥一中专题页面瀑布
// --------------------------------------------------------------------
class ChannelDashBoard extends TplOfChannel {
  constructor(props) {
    super(props);
  }

  // 本组件路由前缀
  routePath = "/channel/hfyz";
  // 频道标题
  title = "合肥一中";
  // 频道副标题
  subTitle = "超限认知教育图谱专题频道";
  // 子标签(左侧栏目)
  channelCates = false;
  // 中上部筛选标签
  topMenus = [
    { name: "我是组长", path: `${this.routePath}/userview`, module: "my" },
    {
      name: "我是成员",
      path: `${this.routePath}/teamwork`,
      module: "teamwork",
    },
    { name: "开放阅读", path: `${this.routePath}/public`, module: "public" },
    {
      name: "开放协作",
      path: `${this.routePath}/cooperation`,
      module: "cooperation",
    },
    { name: "开放复制", path: `${this.routePath}/free`, module: "free" },
  ];
}

export default connect(
  (state) => ({
    loginStatus: state.account.loginStatus,
    userInfo: state.account.userInfo,
  }),
  (dispatch) => ({})
)(ChannelDashBoard);
