import {bindUtil, withPromiseMessageDecorator} from "@/libs/core-decorators";
import messageProxy from '@/components/common/common.messageProxy';
import {userOperationMessages} from "@/constants/messages";
import {
  API_MatchUser,
  API_MoveUserInfoByAccessToken,
  API_VerifyEmailByToken,
  API_ChangeEmail,
  API_SendFeedback,
  API_GetViewBadge,
  API_GetViewActionLogSummary,
  API_GetUserInfoById,
  API_GetEditableViewByUserId,
  API_GetViewPublicNode,
} from "@/api/user";
import {getErrorInfo as commonGetErrorInfo} from "@/components/common/common.functions";

@bindUtil.asSourceClass
class UserDataProvider {
  /**
   * 通过指定字段匹配用户信息
   *
   * @param {string} nick 昵称
   * @param {string} email 邮箱
   * @param {string} mobile 手机号
   * @param {string} accessToken 协作ID
   * @return {Promise}
   */
  static matchUser = ({nick, email, mobile, accessToken}) => {
    return new Promise((resolve, reject) => {
      API_MatchUser({nick, email, mobile, accessToken}).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve(response.data.data);
        } else {
          reject(UserDataProvider.getErrorInfo(response));
        }
      }).catch(error => {
        reject(UserDataProvider.getErrorInfo(error));
      });
    });
  };

  /**
   * 通过指定字段查询用户信息
   *
   * @param {string} nick 昵称
   * @param {string} email 邮箱
   * @param {string} mobile 手机号
   * @return {Promise}
   */
  /*static queryUser = ({nick, email, mobile}) => {
    return new Promise((resolve, reject) => {
      API_QueryUser({nick, email, mobile}).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve(response.data.data);
        } else {
          reject(UserDataProvider.getErrorInfo(response));
        }
      }).catch(error => {
        reject(UserDataProvider.getErrorInfo(error));
      });
    });
  };*/

  static moveUserInfoByAccessToken = accessToken => {
    return new Promise((resolve, reject) => {
      API_MoveUserInfoByAccessToken(accessToken).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve();
        } else {
          reject({...UserDataProvider.getErrorInfo(response), data});
        }
      }).catch(error => {
        reject({...UserDataProvider.getErrorInfo(error), data: undefined});
      });
    });
  };

  static changeEmail = (email, password = null) => {
    return new Promise((resolve, reject) => {
      API_ChangeEmail(email, password).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve();
        } else {
          reject(UserDataProvider.getErrorInfo(response));
        }
      }).catch(error => {
        reject(UserDataProvider.getErrorInfo(error));
      });
    });
  };

  static verifyEmailByToken = token => {
    return new Promise((resolve, reject) => {
      API_VerifyEmailByToken(token).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve(response.data.data);
        } else {
          reject(UserDataProvider.getErrorInfo(response));
        }
      }).catch(error => {
        reject(UserDataProvider.getErrorInfo(error));
      });
    });
  };

  static sendFeedback = (feedback = '', files = []) => {
    return new Promise((resolve, reject) => {
      API_SendFeedback(feedback, files).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve();
        } else {
          reject(UserDataProvider.getErrorInfo(response));
        }
      }).catch(error => {
        reject(UserDataProvider.getErrorInfo(error));
      });
    });
  };

  /**
   * 获取图谱相关的用户奖章
   * @param userId
   * @param start
   * @param limit
   * @param orderType
   * @param orderBy
   * @returns {*}
   */
  static getViewBadge = ({userId, start = 0,  limit = 20, orderType = 'desc', orderBy = 'timestamp'}) => {
    return new Promise((resolve, reject) => {
      API_GetViewBadge({userId, start,  limit, orderType, orderBy}).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve(response.data.data);
        } else {
          reject(UserDataProvider.getErrorInfo({response}));
        }
      }).catch(error => {
        reject(UserDataProvider.getErrorInfo(error));
      });
    });
  };

  /**
   * 获取图谱操作记录统计
   *
   * @param {string} userId
   */
  static getViewActionLogSummary = ({userId}) => {
    return new Promise((resolve, reject) => {
      API_GetViewActionLogSummary({userId}).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve(response.data.data);
        } else {
          reject(UserDataProvider.getErrorInfo({response}));
        }
      }).catch(error => {
        reject(UserDataProvider.getErrorInfo(error));
      });
    });
  };

  /**
   * 通过Id找人物信息
   *
   * @param {string} userId
   */
  static getUserInfoById = (userId) => {
    return new Promise((resolve, reject) => {
      API_GetUserInfoById(userId).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve(response.data.data);
        } else {
          reject(UserDataProvider.getErrorInfo({response}));
        }
      }).catch(error => {
        reject(UserDataProvider.getErrorInfo(error));
      });
    });
  };

  /**
   * 获取参与的图谱列表
   *
   * @param {string} userId
   */
  static getEditableViewByUserId = (userId) => {
    return new Promise((resolve, reject) => {
      API_GetEditableViewByUserId(userId).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve(response.data.data);
        } else {
          reject(UserDataProvider.getErrorInfo({response}));
        }
      }).catch(error => {
        reject(UserDataProvider.getErrorInfo(error));
      });
    });
  };

  /**
   * 获取节点公开信息列表
   * @param userId
   * @param start
   * @param order_by
   * @param order_type
   * @param with_detail
   * @returns {*}
   */
  static getViewPublicNode = ({ userId, start = 0, limit = 100,  order_by ='update_time', order_type = 'desc', with_detail = 0}) => {
    return new Promise((resolve, reject) => {
      API_GetViewPublicNode({user_id : userId, start, limit, order_by, order_type, with_detail}).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve(response.data.data);
        } else {
          reject(UserDataProvider.getErrorInfo({response}));
        }
      }).catch(error => {
        reject(UserDataProvider.getErrorInfo(error));
      });
    });
  };

  /**
   * @private
   * 通過Axios请求返回的错误信息获取错误详情
   *
   * @param {*} error
   *
   * @return {{msg: string, code: number}}
   */
  static getErrorInfo = commonGetErrorInfo;
}

const overrideMessages = {};

/**
 * @type {typeof UserDataProvider}
 */
const UserDataProviderWrapper = withPromiseMessageDecorator(
  messageProxy(userOperationMessages, overrideMessages)
)(UserDataProvider);

// noinspection JSUnusedGlobalSymbols
export const overrideNextMessage = (method, msg) => {
  overrideMessages[method] = msg;
};

export default UserDataProviderWrapper;