import React from 'react';
import PropTypes from "prop-types";
import {Tooltip} from "antd";

// import relationStep0 from '@/assets/relation-step/relation-step-0.svg';
import relationStep1 from '@/assets/relation-step/relation-step-1.svg';
import relationStep2 from '@/assets/relation-step/relation-step-2.svg';
import relationStep3 from '@/assets/relation-step/relation-step-3.svg';

import style from '@/style/common/relation/common.relation.stepInfo.less';

const svgList = [
  relationStep1,
  relationStep1,
  relationStep2,
  relationStep3,
];

const svgText = [
  '现在是只读状态',
  '现在是编辑状态',
  '现在是训练状态',
  '现在是洞察状态',
];

export default class StepInfo extends React.Component {
  render() {
    return (
      <div className={this.props.className} style={this.props.style}>
        <Tooltip
          title={svgText[this.props.step]}
          overlayClassName={'dark-theme'}
          placement={'left'}
        >
          <img src={svgList[this.props.step]} alt={svgText[this.props.step]} />
        </Tooltip>
      </div>
    );
  }
}

StepInfo.defaultProps = {
  step: 0,
  style: {},
  className: style['container'],
};

StepInfo.propTypes = {
  step: PropTypes.number.isRequired,
  style: PropTypes.object,
  className: PropTypes.string,
};