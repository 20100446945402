import React from 'react';
import PropTypes from 'prop-types';
import {Card} from "antd";

import style from '@/style/common/relation/common.relation.briefingInfoCard.less';
import Icon from "../common.icon";
import SysConfig from "@/constants/sys.config";

export default class BriefingInfoCard extends React.Component {
  myRef = React.createRef();

  onWheel = e => {
    let me = this;
    if (e.deltaMode !== undefined && e.deltaMode === 0) {
      if (me.myRef.current.scrollTop === 0 && e.deltaY < 0) {
        // ignore
      } else if (me.myRef.current.scrollTop + me.myRef.current.clientHeight + 1 >= me.myRef.current.scrollHeight &&
        e.deltaY > 0) {

        // ignore
      } else {
        e.stopPropagation();
        e.preventDefault();
        me.myRef.current.scroll(e.deltaX, e.deltaY);
      }
    }
  };

  componentDidMount() {
    const {node} = this.props;
    if (node) {
      node.$getInfoCardRef = () => this;
    }

    if (this.myRef.current) {
      this.myRef.current.addEventListener("wheel", this.onWheel);
    }
  }

  componentWillUnmount() {
    if (this.myRef.current) {
      this.myRef.current.removeEventListener("wheel", this.onWheel);
    }
  }

  render() {
    let me = this;
    const {info} = this.props;
    let domain = info.link.split('/')[2];

    return (
      <div
        className={me.props.className ? `${this.props.className} ${style['result-item']}` : style['result-item']}
        style={me.props.style}
      >
        <Card>
          <div className={`${style['result-item-meta']}`}>
            <h4>
              {/*清明节样式*/}
              <a
                href={info.link ? info.link : `${SysConfig.sysSearchEngine}${info.title}`}
                target={'_blank'}
                title={{/*info.title*/}}
              >
                <Icon name={'paper-clip'} style={{marginRight: '0.5em'}}/>
                {info.title}
              </a>
            </h4>
            <div className={style['result-item-meta-extra']}>{(info.time[0] || '').substring(0, 10)} {info.location[0].text}</div>
            <div className={style['result-item-meta-description']}>
              <div
                ref={me.myRef}
                className={`${style['result-item-description-content']} ${info.image ? 'has-img' : ''}`}
              >
                {
                  info.content.map(({id, type, word}, idx) => (
                    <span key={`ln-${idx}`} ft-data-type={type}>{
                      word.split('\n').map((w, i, array) =>
                        <span key={`ln-w-${i}`}>{w}{i === (array.length - 1) ? null : (<br />)}</span>)
                    }</span>
                  ))
                }
              </div>
              {
                info.image ? (
                  <div className={style['result-item-attachment-image-container']}>
                    <a onClick={() => me.setState({currentImageIdx: 0})}>
                      图像位置
                    </a>
                  </div>
                ) : null
              }
            </div>
          </div>
          <div className={style['result-item-source']}>
            <span><a href={info.link} target={'_blank'}>{domain}</a></span>
          </div>
        </Card>
      </div>
    );
  }
}

BriefingInfoCard.defaultProps = {
  className: undefined,
  style: {},
  info: [],
};

BriefingInfoCard.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  info: PropTypes.object.isRequired,
};