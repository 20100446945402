export const helperKey = 'react-state-helper';

export const withReactStateHelper = constructor => {
  return class extends constructor {
    constructor (...args) {
      super(...args);
      let me = this;
      me[`_$${helperKey}-trigger`] = 0;
      me.state = me.state || {};
      me.state[helperKey] = Math.random();

      return new Proxy(me, {
        get: function (target, key, receiver) {
          return Reflect.get(target, key, receiver);
        },
        set: function (target, key, value, receiver) {
          const keys = constructor.prototype[`${helperKey}-params`];
          if (keys && keys.includes && keys.includes(key)) {
            if (target[`_$${helperKey}-trigger`] === 0) {
              target[`_$${helperKey}-trigger`] = requestAnimationFrame(() => {
                target[`_$${helperKey}-trigger`] = 0;
                target.setState({[helperKey]: Math.random()});
                console.log('async set state...');
              });
            }
          }
          return Reflect.set(target, key, value, receiver);
        },
      });
    }
  }
};

export const autoSetState = (target, name) => {
  target[`${helperKey}-params`] = target[`${helperKey}-params`] || [];
  target[`${helperKey}-params`].push(name);
};