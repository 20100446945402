import React from 'react';
import PropTypes from 'prop-types';

import style from '@/style/containers/explorationView.less';
import {NodeExactMatchingStatus} from "@/libs/view/network/status";
import NodeInfoCard from "@/components/explorationView/exploration.nodeInfoCard";
import {FilterAndStatisticsBus} from "@/components/common/common.timestampAndLocationStatistics";
import {bindUtil} from "@/libs/core-decorators";
import {autoSetState, withReactStateHelper} from "@/libs/react-state-helper";

@bindUtil.asTargetClass
@withReactStateHelper
class ResultList extends React.Component {
  state = {
    currentId: '',
    searchingStatus: NodeExactMatchingStatus.IDLE,
  };

  data = {};

  @bindUtil.bindToProperty('props.bus', 'statisticsUpdated')
  @autoSetState
  statisticsUpdated = false;

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  render() {
    const nodes = this.props.bus.nodes;

    return (
      <div className={`${style['result-list-frame']} scrollbar scrollbar-18`}>
        {nodes.length > 0 ? (
          <div className={`${style['result-list-container']}`}>
            {nodes.map(node => (
              <NodeInfoCard
                node={node} key={`node-${node.id}`}
                readonly={this.props.readonly}
                onAddToGraph={this.props.onAddToGraph}
                onRemoveFromGraph={this.props.onRemoveFromGraph}
                onExplore={this.props.onExplore}
                onVote={this.props.onVote}
              />
            ))}
          </div>
        ) : (
          null
        )}
      </div>
    );
  }
}

ResultList.defaultProps = {
  readonly: true,
};

ResultList.propTypes = {
  readonly: PropTypes.bool,
  onAddToGraph: PropTypes.func.isRequired,
  onRemoveFromGraph: PropTypes.func.isRequired,
  onExplore: PropTypes.func.isRequired,
  onVote: PropTypes.func.isRequired,
  bus: PropTypes.instanceOf(FilterAndStatisticsBus).isRequired,
};

export default ResultList;
