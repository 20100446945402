import React from 'react';
import PropTypes from 'prop-types';
import {Form, Input, Button, Modal, Table, Col, Row, Select} from 'antd';

import PB, {SimplePB} from '@/libs/simplePB';

import {IconTypes} from '@/constants/common';

import Icon from '@/components/common/common.icon';
import TimeDisplayField from '@/components/common/common.timeDisplayField';

import style from '@/style/common/microService/common.microService.less';

const { Option } = Select;

class MicroServiceModalConfigList extends React.Component {
  state = {
    projectId: undefined,
    tag: undefined,
    query:'',
    loading: false,
    infoList: [],
    pagination: {
      current: 1,
      pageSize: 20,
      total:0,
      showLessItems:true
    },
    sorter: {
      field: undefined,
      order: undefined,
    },
    hasMore:false
  };

  getColumns = () => {
    let me = this;

    return [{
      title: '名称',
      dataIndex: 'title',
      key: 'title',
    }, {
      title: '图标',
      dataIndex: 'iconId',
      key: 'iconId',
      render(iconId) {
        return (
          <Icon name={iconId || 'icon-micro-service'} type={IconTypes.ICON_FONT} />
        );
      }
    }, {
      title: '标签',
      dataIndex: 'tag',
      key: 'tag',
    }, {
      title: '短描述',
      dataIndex: 'shortDescription',
      key: 'shortDescription',
    }, {
      title: '更新时间',
      align: 'center',
      dataIndex: 'updateTimestamp',
      key: 'updateTimestamp',
      sorter: (a, b) => a.updateTimestamp > b.updateTimestamp ? 1 : (a.updateTimestamp === b.updateTimestamp ? 0 : -1),
      render(updateTimestamp) {
        return (
          <TimeDisplayField timestamp={(new Date(updateTimestamp)).getTime()} />
        );
      }
    }, {
      title: '操作',
      dataIndex: 'id',
      key: 'actions',
      render(id, record) {
        return (
          <span>
            <a onClick={() => me.onEditConfig(id, record)}>修改</a>
            &nbsp;|&nbsp;
            <a onClick={() => me.onRemoveConfig(id)}>删除</a>
          </span>
        );
      }
    }];
  };

  onAddConfig = () => {
    let me = this;

    me.props.bus.emit('view', 'micro_service.config.show_detail_modal', {info: undefined});
  };

  onEditConfig = (id, record) => {
    let me = this;

    me.props.bus.emit('view', 'micro_service.config.show_detail_modal', {info: record});
  };

  fetch = (p, forceReload) => {
    let me = this;

    if (!p) {
      p = {
        start: (me.state.pagination.current - 1) * me.state.pagination.pageSize,
        limit: me.state.pagination.pageSize,
      };
    }
    me.setState({loading: true}, () => {
      me.props.bus.emit('view', 'micro_service.config.on_load', {
        projectId: me.state.projectId ? me.state.projectId : undefined,
        query:me.state.query,
        tag: me.state.tag ? me.state.tag : undefined,
        enabledOnly: false,
        start: p.start,
        limit: p.limit,
        forceReload: forceReload === true,
        onSuccess: (infoList,configList,total) => {
          let pagination = this.state.pagination ,exnum = infoList.length==pagination.pageSize?1:0;
          //pagination.total = total?total:(p.start + infoList.length + exnum);
          pagination.total = total;
          me.setState({infoList, loading: false, hasMore:infoList.length===me.state.pagination.pageSize,pagination});
        },
        onError: () => {
          let pagination = this.state.pagination;
          pagination.total = 0;
          me.setState({loading: false,pagination});
        },
      });
    });
  };

  onRefresh = () => {
    let me = this;
    me.fetch(undefined, true);
  };

  handleTableChange = (pagination, filters, sorter) => {
    let me = this;

    let pager = { ...me.state.pagination };
    pager.current = pagination.current;
    me.setState({
      pagination: pager,
      sorter: {...me.state.sorter, ...sorter},
    });
    me.fetch({
      start: (pagination.current - 1) * pagination.pageSize,
      limit: pagination.pageSize
    });
  };

  onRemoveConfig = (id, confirmed = false) => {
    let me = this, successCb = () => {me.onRefresh()};

    if (confirmed) {
      me.props.bus.emit('view', 'micro_service.config.on_remove', {id, successCb});
    } else {
      Modal.confirm({
        title: '确认？',
        onOk: () => me.onRemoveConfig(id, true),
        okText: '确认删除',
        okType: 'danger',
        cancelText: '取消',
      });
    }
  };

  onChangeProjectId = (value) => {
    this.setState({projectId: value})
  };

  onChangeTag = (value) => {
    this.setState({tag: value})
  };

  componentDidMount() {
    let me = this;

    me.props.bus.with(me).subscribe('view', 'micro_service.config.refresh_list', ({projectId}) => {
      if (!me.state.projectId && projectId) return;
      if (me.state.projectId && !projectId) return;
      if (me.state.projectId && projectId && me.state.projectId !== projectId) return;
      me.onRefresh();
    });

    requestAnimationFrame(() => me.onRefresh());
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let me = this;

    if (!prevProps.visible && me.props.visible) {
      me.setState({
        pagination: {
          current: 1,
          pageSize: 20,
          total: 0
        }
      }, me.onRefresh);
    }
  }

  componentWillUnmount() {
    this.props.bus.remove(this);
  }

  render() {
    let me = this;

    return (
      <Modal
        title={'微服务配置列表'}
        visible={me.props.visible}
        centered={true}
        onCancel={me.props.onClose}
        footer={false}
        width={'70rem'}
      >
        <Form.Item>
          <Row>
            <Col span={2} style={{textAlign:'right'}}>
              项目ID：
            </Col>
            <Col span={2}>
              <Select
                style={{ width: 80 }}
                showSearch
                onChange={me.onChangeProjectId}
              >
                <Option value="">全部</Option>
                <Option value="dev">dev</Option>
              </Select>
            </Col>
            <Col span={2} style={{textAlign:'right'}}>
              标签：
            </Col>
            <Col span={3}>
              <Select
                style={{ width: 100 }}
                showSearch
                onChange={me.onChangeTag}
              >
                <Option value="">全部</Option>
                <Option value="图谱样式">图谱样式</Option>
                <Option value="图谱数据">图谱数据</Option>
                <Option value="图谱事件">图谱事件</Option>
                <Option value="图谱任务">图谱任务</Option>
                <Option value="图谱洞察">图谱洞察</Option>
                <Option value="图谱分享">图谱分享</Option>
              </Select>
            </Col>
            <Col span={2} style={{textAlign:'right'}}>
              标题：
            </Col>
            <Col span={5}>
              <Input onChange={e => me.setState({query: e.target.value})} value={me.state.query} />
            </Col>
            <Col offset={1} span={2}>
              <Button disabled={me.state.loading} block={true} onClick={me.onRefresh}>查询</Button>
            </Col>
            <Col offset={2} span={2}>
              <Button disabled={me.state.loading} block={true} onClick={me.onAddConfig}>新建</Button>
            </Col>
          </Row>
        </Form.Item>
        <Table
          className={`${style['config-list-table']} scrollbar-none`}
          columns={me.getColumns()}
          pagination={this.state.pagination}
          onChange={me.handleTableChange}
          rowKey={'id'}
          dataSource={me.state.infoList}
          scroll={{y: '40vh'}}
        />
      </Modal>
    );
  }
}

MicroServiceModalConfigList.defaultProps = {
  bus: PB,
};

MicroServiceModalConfigList.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  bus: PropTypes.instanceOf(SimplePB),
};

export default MicroServiceModalConfigList;