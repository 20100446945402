import React from 'react';
import PropTypes from "prop-types";

import style from '@/style/containers/exploreRelationView.less';
import PB, {SimplePB} from "@/libs/simplePB";

class WordCloudList extends React.Component {
  render() {
    let me = this;

    let words = me.props.words;
    /*let maxScore = words.length > 0 ? words[0].value : 1;*/

    return (
      <div className={style['word-cloud-list-frame']}>
        <div className={`${style['word-cloud-list-frame-inner']} scrollbar`}>
          <table>
            <tbody>
            {
              words.map((word, idx) => (
                <tr key={`w-${idx}`}>
                  <td style={{/*paddingRight: '0.5rem'*/}}>
                    {
                      word.text.split('/').map((key, kIdx) => (
                        <a
                          key={`w-${idx}-${kIdx}`}
                          onClick={
                            () => me.props.bus.emit('relation', 'find_node_by_text.show_next',
                              {text: key})
                          }
                        >
                          {key}
                        </a>
                      ))
                    }
                  </td>
                  {/*<td>{(word.value / maxScore).toFixed(2)}</td>*/}
                </tr>
              ))
            }
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

WordCloudList.defaultProps = {
  bus: PB,
  words: [],
};

WordCloudList.propTypes = {
  bus: PropTypes.instanceOf(SimplePB),
  words: PropTypes.array,
};

export default WordCloudList;