import React from 'react';
import PropTypes from 'prop-types';

import PB, {SimplePB} from '@/libs/simplePB';

import ViewDataProvider from '@/components/common/dataProvider/common.dataProvider.view';
import ExploreCommonRecommendDetailModal
  from "@/components/common/view/explore/common.view.explore.common.recommendDetail.modal";

class ExploreTalentRecommendDetailModal extends React.Component {
  render() {
    let me = this;

    return (
      <ExploreCommonRecommendDetailModal
        exploreName={'人物'}
        exploreKey={'talent'}
        exploreEventCategory={'explore_talent'}
        {...me.props}
      />
    );
  }
}

ExploreTalentRecommendDetailModal.defaultProps = {
  bus: PB,
};

ExploreTalentRecommendDetailModal.propTypes = {
  viewDataProvider: PropTypes.instanceOf(ViewDataProvider).isRequired,
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired,
  bus: PropTypes.instanceOf(SimplePB),
};

export default ExploreTalentRecommendDetailModal;