import React from 'react';
import PropTypes from 'prop-types';
import {Button, Modal, Input, List, Popover, message, Row, Col} from "antd";
import PB, {SimplePB} from "@/libs/simplePB";

import style from '@/style/common/view/common.view.teamwork.memberFindForm.less';
import UserAvatar from "react-user-avatar";
import {AvatarColors} from "@/constants/common";
import {getToken, REQUEST_BASE} from "@/utils/HttpUtil";
import Icon from "@/components/common/common.icon";

class TeamworkMemberFindForm extends React.PureComponent {
  state = {
    doSearching: false,
    searchText: '',
  };

  searchSuccessCb = userList => {
    let me = this;

    if (me.state.doSearching) {
      me.setState({
        doSearching: false,
      }, () => {
        if (userList && userList.length > 0) {
          const userInfo = me.state.searchText.includes('@') ? {...userList[0], email: me.state.searchText} :
            {...userList[0], accessToken: me.state.searchText};
          let existedUserIdList = me.props.userList.map(user => user.userId);
          if (existedUserIdList.includes(userInfo.userId)) {
            message.info('该用户已在协作成员列表中。');
          } else {
            Modal.confirm({
              title: '信息确认',
              icon: null,
              content: (
                <List
                  dataSource={[userInfo]}
                  className={style['modal-content']}
                  renderItem={user => (
                    <List.Item>
                      <List.Item.Meta
                        avatar={(
                          <div
                            className={`${style['avatar']} ${user.picId ? '' : style['avatar-default-icon']}`}
                          >
                            <UserAvatar
                              size={'var(--avatar-size)'}
                              name={user.nick}
                              src={user.picId ? `${REQUEST_BASE}/user/user/file/${user.picId}?Authorization=${getToken()}` : undefined}
                              colors={AvatarColors}
                            />
                          </div>
                        )}
                        title={user.nick}
                        description={(
                          <span>注册时间：{user['gmtCreate'] ? user['gmtCreate'] : '--'}</span>
                        )}
                      />
                    </List.Item>
                  )}
                />
              ),
              okText: '加入协作',
              cancelText: '取消',
              onOk: () => {
                me.props.bus.once(me, 'teamwork', 'member.add.success', me.props.onClose);
                me.onAddMember(userInfo);
              },
            });
          }
        } else {
          message.error('没有找到相关用户，请确认信息无误。');
        }
      });
    }
  };

  searchFailedCb = error => {
    this.setState({
      doSearching: false,
    }, () => {
      if (error['code'] === 28001) {
        message.error('没有找到相关用户，请确认信息无误。');
      }
      console.log('查找用户失败了，错误：', error);
    });
  };

  onSearch = () => {
    let me = this;

    if (me.state.searchText && me.state.searchText !== '') {
      me.setState({doSearching: true}, () => {
        const query = me.state.searchText.includes('@') ? {email: me.state.searchText} :
          {accessToken: me.state.searchText};
        me.props.bus.emit('teamwork', 'member.find.do',
          {query, success: me.searchSuccessCb, failed: me.searchFailedCb});
      });
    } else {
      me.setState({doSearching: false});
    }
  };

  onAddMember = userInfo => {
    this.props.bus.emit('teamwork', 'member.add.do',
      {viewId: this.props.viewId, userInfo});
  };

  afterModalClose = () => {
    this.setState({
      doSearching: false,
      searchText: '',
    })
  };

  render() {
    let me = this;

    return (
      <div className={style['container']}>
        <div className={style['member-find-form']}>
          <div className={style['input-wrap']}>
            <Input
              placeholder={'请输入对方协作ID或注册邮箱'}
              disabled={me.state.doSearching}
              value={me.state.searchText}
              onChange={v => me.setState({searchText: v.target.value})}
            />
          </div>
          <div className={style['controls-wrap']}>
            <Button
              type={'primary'}
              key={'ok'}
              onClick={me.onSearch}
              style={{marginRight: 10}}
            >
              加入协作
            </Button>
            <Button key={'close'} onClick={me.props.onClose}>取消</Button>
          </div>
        </div>
        <div>
          <Popover
              placement={'bottomLeft'}
              key={'popover'}
              content={(
                <div>
                  <span>第一步：</span><br />
                  <img alt={'点击主界面左上角的头像，打开基本信息对话框。'} src={'/assets/access_token_1.png'}/><br />
                  <br />
                  <span>第二步：</span><br />
                  <img alt={'第一行显示的即为协作ID。'} src={'/assets/access_token_2.png'}/><br />
                </div>
              )}
            >
              <span className={style['tips-text']}>
                <Icon name={'question-circle'} style={{marginRight: '0.5em'}} />
                如何查看协作ID
              </span>
            </Popover>
        </div>
      </div>
    );
  }
}

TeamworkMemberFindForm.defaultProps = {
  bus: PB,
  userList: [],
};

TeamworkMemberFindForm.propTypes = {
  bus: PropTypes.instanceOf(SimplePB),
  userList: PropTypes.array,
  viewId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default TeamworkMemberFindForm;