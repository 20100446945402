export function getBase64Url(img, callback) {
  const reader = new FileReader();
  // 先添加响应事件
  reader.addEventListener('load', () => callback(reader.result));
  // 再加载图片触发事件
  reader.readAsDataURL(img);
}

export function uniqueArrayObj(arr,key){
  return arr.reduce((arr,next)=>arr.some((item)=>item[key]==next[key])?arr:[...arr,next],[]);
}