import React from 'react';
import PB from "@/libs/simplePB";
import {message, Modal} from "antd";
import errorCodeList from "@/constants/errorCode";
import {getToken, removeToken} from "@/utils/HttpUtil";
import jwtDecode from "jwt-decode";
import copy from "copy-to-clipboard";
import intl from 'react-intl-universal';

const messages = {};
const displayedMessages = {};
const delayedMessages = {};
const autoHideMessages = {};

const copyMemberId = (accessToken) => {
  let result = copy(accessToken, {
    message: '请按下 #{key} 复制协作号。',
  });

  if (result) message.success('协作号已经复制到剪贴板');
};

export const getDelayHideFn = msg => {
  const hide = message.loading(msg);
  const delay = {delayed: false, callback: undefined};
  setTimeout(() => {
    delay.delayed = true;
    if (delay.callback) delay.callback();
  }, 700);
  return (callback) => {
    if (delay.delayed) {
      hide();
    } else {
      delay.callback = () => {
        callback();
        hide();
      };
    }
  };
};

// 显示顶部(异步)错误 【 提示 】
export const showErrorMsg = ({code, msg}, duration = 3) => message.error(errorCodeList[code] || msg, duration);

export const showErrorMessage = (
  { code, msg, extra },
  duration = 3,
  defaultFn = () => message.error(errorCodeList[code] || msg || '操作失败', duration)
) => {
  if (10003 === parseInt(code)) {
    let token = getToken();
    const currentTime = (new Date()).getTime() / 1000;
    if (token) {
      const decoded = jwtDecode(token);
      if (decoded['exp'] < currentTime) {
        token = undefined;
      }
    }
    if (token) {
      Modal.error({
        title: extra && extra['isModification'] ? '没有操作权限' : (
          extra && extra.accessToken ? '向版主申请协作' : '没有访问权限'
        ),
        content: extra && extra['isModification'] ? (
          <span>抱歉，您没有执行此项操作的权限。</span>
        ) : (
          <span>
            抱歉，您还不是图谱成员，没有访问权限。
            {extra && extra.accessToken ? (
              <span>
                <br />
                下方为您的协作号（类似QQ号），请发送给版主将您加为成员。
                <br />
                <br />
                {extra.accessToken}
                <br />
                <a onClick={() => copyMemberId(extra.accessToken)}>
                  点击复制
                </a>
              </span>
            ) : undefined}
          </span>
        ),
        okText: '知道了',
        okButtonProps: { type: 'normal' },
        mask: true,
        maskClosable: false,
      });
    } else {
      Modal.error({
        title: '登录超时',
        content: (
          <span>抱歉，登录超时，请重新登录本系统。</span>
        ),
        okText: '重新登录',
        okButtonProps: { type: 'normal' },
        onOk: () => {
          removeToken();
          window.location.reload();
        },
        mask: true,
        maskClosable: false,
      });
    }
  } else if (28002 === parseInt(code) && extra && extra.viewId) {
    Modal.error({
      title: '没有权限',
      content: (
        <span>抱歉，您还不是本图谱的协作团队成员，请先加入团队后再试。</span>
      ),
      okText: '立即加入',
      cancelText: '取消',
      onOk: () => {
        PB.emit('teamwork', 'member.join.do', { viewId: extra.viewId });
      },
      mask: true,
      maskClosable: false,
      okCancel: true,
    });
  } else if (25005 === parseInt(code)) {
    Modal.error({
      title: '图谱已锁定',
      content: '图谱已被作者锁定，请解锁后再试。',
      okText: '知道了',
      mask: true,
      maskClosable: false,
    });
  } else if ([].indexOf(code) >= 0) {
    message.error(msg);
  } else {
    defaultFn();
  }
};

// 显示顶部(异步)成功 【 提示 】
export const showSuccessMsg = ({code, msg}, duration = 3) => message.success(msg, duration);

/**
 * 显示loading状态，并出现遮罩层,中部(假异步)
 * 必须要主动关闭，否则一直存在
 * @param text 提示文字
 * @param ajax 是否是异步提示
 * @param delay 是否延时显示
 */
export const showPageLoading = (text = intl.get('Custom.message.loadExactMatch'), ajax = false, delay = 200) => {
  let id = `msg-id-${Math.random()}`;
  while (messages[id]) id = `msg-id-${Math.random()}`;
  messages[id] = {content: text, ajax};
  console.log(`申请展示消息，消息ID：${id}，消息内容：${text}`);
  console.time(`[time] 消息ID：${id}，消息内容：${text}`);
  if (delay > 0) {
    delayedMessages[id] = setTimeout(() => {
      if (!messages[id]) return;
      console.log(`延时展示消息，消息ID：${id}，消息内容：${text}`);
      delete delayedMessages[id];
      displayedMessages[id] = {content: text, ajax};
      PB.emit('MainView', 'spinLoading', {status: true, content: text, ajax: ajax});
      autoHideMessages[id] = setTimeout(() => {
        delete autoHideMessages[id];
        if (!messages[id]) {
          /// 消息应该被自动隐藏
          console.log(`自动隐藏消息，消息ID：${id}，消息内容：${text}`);
          innerHidePageLoading(id);
        }
      }, 700);
    }, delay);
  } else {
    PB.emit('MainView', 'spinLoading', {status: true, content: text, ajax: ajax});
  }
  return ((id) => () => hidePageLoading(id))(id);
};

/**
 * 隐藏loading遮罩,中部(假异步)
 */
export const hidePageLoading = (id) => {
  if (!messages[id]) return;
  console.timeEnd(`[time] 消息ID：${id}，消息内容：${messages[id]['content']}`);
  delete messages[id];
  if (delayedMessages[id]) {
    // 消息尚未显示
    clearTimeout(delayedMessages[id]);
    delete delayedMessages[id];
    return;
  }
  if (autoHideMessages[id]) {
    // 消息将会自动隐藏
    return;
  }
  innerHidePageLoading(id);
};

const innerHidePageLoading = id => {
  delete displayedMessages[id];
  let messageTexts = Object.values(displayedMessages);
  if (messageTexts && messageTexts.length > 0) {
    PB.emit('MainView', 'spinLoading', {status: true, ...messageTexts[messageTexts.length - 1]});
  } else {
    PB.emit('MainView', 'spinLoading', false)
  }
};