/*
 * @Author: Carr.s
 * @Date: 2022-04-26 11:07:06
 * @LastEditTime: 2022-05-13 18:44:16
 * @LastEditors: Carr.s
 * @Description: 产业链洞察2
 * @Copyright by joinmap
 */
import React from "react";
import ViewInsightChartTpl from "@/components/insightView/template/insight.chart.tpl";
import { connect } from "react-redux";
import styles from "@/style/components/insightView/insight.less";
import echarts from "echarts/lib/echarts";

class ViewIndustrialChainBInsight extends ViewInsightChartTpl {
  //===============================================================
  // begin of overwrite
  // 使用默认的方式就不用重写

  // ------------------------------------------------------------
  // 全局配置
  // ------------------------------------------------------------
  // 提示信息中提示添加哪种类型的节点
  nodeTitleInTip = "企业";
  // 显示底部按钮
  showToolbar = false;
  // 洞察名称
  viewerTitle = "产业链洞察 链点分布图";
  _viewerTitleRender = () => {
    return "";
  };

  // ------------------------------------------------------------
  // 层级分析对象的配置
  // ------------------------------------------------------------
  // 第一层 level1 tag
  column1_showType = "list"; // 第一列的展现形式，list=显示列，tree=显示树
  // column1_DataField = "data"; // 取值字段
  // column1_TotalField = "dataTotal"; // 计算总数(与返回数量不一定一致)字段
  column1_treeFields = []; // 用树状目录显示数据时各层级的 children 字段名数据
  column1_Title = "产业链点"; // 显示名称
  column1_Unit = "个"; // 显示的文字单位
  xcolumn1_ValueRender = (level1_data, idx, nodePath = undefined) => {
    // 右侧数值渲染，不输出则空白
    // 必须加上 className={styles["line-value"]}
  };
  /*
  column1_IconRender = (level1_data, idx,nodePath=undefined) => {
    // 左侧图表渲染，不输出则空白
    // 必须加上 className={styles["line-icon"]}
  };
  */
  xlevel1_HoverTitle = (level1_data, idx, nodePath = undefined) => {
    // 列表鼠标悬浮提示文字
    // 负导向数值
    let negTotal = level1_data["negTotal"];
    return `${this.column1_Title}：${level1_data["name"]}\n${
      this.column2_Title
    }：${level1_data[this.column2_TotalField]}${
      this.column2_Unit
    }\n负导向：${negTotal}条\n点击打开${this.column2_Title}列表`;
  };
  xcolumn1_subTitle = () => {
    // 列表顶部副标题文字
    const { _column1Len } = this.state;
    return (
      <>
        已显示一级核心词汇{_column1Len}
        {this.column1_Unit}
      </>
    );
  };

  // 第二层 item
  column2_DataField = "cop"; // 取值字段
  column2_TotalField = "value"; // 计算总数(与返回数量不一定一致)字段
  column2_Title = "企业"; // 显示名称
  column2_Unit = "家"; // 显示的文字单位
  column2_ValueRender = (level2_data, idx) => {
    // 右侧数值渲染，不输出则空白
    // 必须加上 className={styles["line-value"]}
    // 不显示
  };
  /*
  column2_IconRender = (level2_data, idx) => {
    // 左侧图表渲染，不输出则空白
    // 必须加上 className={styles["line-icon"]}
  };
  column2_HoverTitle = (level2_data, idx) => {
    // 列表鼠标悬浮提示文字
    return `${this.column2_Title}：${level2_data["name"]}\n点击打开${this.column1_Title}链接`;
  };
  */

  // ------------------------------------------------------------
  // 接口的配置
  // ------------------------------------------------------------
  // 接口 url path
  // api_path = "";
  api_path = "/wth_server_api/tiao_xing_pic_by_view_id";

  // 返回数据默认值
  defaultLimit = 60;

  /**
   * 【继承组件要重写】
   * api 请求完成后，setState结束后的 callback
   */
  apiLoadedCallback = () => {
    // message.info("数据加载成功");
  };

  // ------------------------------------------------------------
  // 图表的配置
  // ------------------------------------------------------------
  // 图表显示库
  chartType = "echarts";
  chartCompomentStyle = {
    width: "100%",
    height: "100%",
  };
  /**
   * 生成公用的、默认的 option
   * @returns
   */
  initChartOption = () => {
    let _chartOption = {
      title: {
        show: false,
      },
      grid: {
        top: "25%",
        left: "25%",
        right: "25%",
        bottom: "25%",
      },
      xAxis: {
        data: [],
        axisLabel: {
          inside: true,
          color: "#fff",
          interval: 0,
          rotate: 40,
        },
        axisTick: {
          show: false,
        },
        axisLine: {
          show: false,
        },
        z: 10,
      },
      yAxis: {
        name: "企业数量",
        nameTextStyle: {
          color: "#eeeeee",
        },
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          color: "#999",
        },
      },
      dataZoom: [
        {
          type: "inside",
        },
      ],
      series: [],
    };
    return _chartOption;
  };

  /**
   * 【继承组件要重写】
   * 生成 chart 的 options
   * @param {int} idx 选中的数据下标
   */
  refreshChartOptions = (nodePath = null) => {
    console.log("refreshChartOptions 0 nodePath:", nodePath);
    // icon的前缀
    const {
      query: { isDev },
    } = this.props;
    let me = this;
    let data = [];
    this._chartData.forEach((e, idx) => {
      data.push([e.name, e.value, idx]);
    });

    // 生成options
    me._chartOption = this.initChartOption();

    let barData = [];
    let xAxisData = [];

    me._chartData.forEach((i1, idx1) => {
      xAxisData.push(i1.name);

      barData.push({
        value: i1.value,
        _path: idx1,
      });

      // me._chartData[idx1]["_path"] = idx1;
    });

    // xAxis
    me._chartOption.xAxis = {
      data: xAxisData,
      name: "产业链点",
      nameTextStyle: {
        color: "#eeeeee",
      },
      axisLine: { onZero: true },
      splitLine: { show: false },
      splitArea: { show: false },
      axisLabel: {
        color: "#eeeeee",
        interval: 0,
        rotate: 40,
      },
    };

    //series
    me._chartOption.series.push({
      type: "bar",
      showBackground: true,
      barWidth: 30,
      barMaxWidth: 40,
      barMinWidth: 10,
      barGap: 10,
      barCategoryGap: 10,
      itemStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          { offset: 0, color: "#83bff6" },
          { offset: 0.5, color: "#188df0" },
          { offset: 1, color: "#188df0" },
        ]),
        borderWidth: 0,
      },
      emphasis: {
        itemStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: "#2378f7" },
            { offset: 0.7, color: "#2378f7" },
            { offset: 1, color: "#83bff6" },
          ]),
        },
      },
      label: {
        show: true,
        position: "inside",
        color: "#eeeeee",
        formatter: function (params) {
          // console.log("params:", params);
          return `${params.data.value}家`;
        },
      },
      data: barData,
    });

    // 鼠标悬浮提示
    me._chartOption["tooltip"] = {
      formatter: function (info) {
        return [
          `<div class="tooltip-title">链点名称：${echarts.format.encodeHTML(
            info.name
          )}</div>`,
          "相关企业：" + info.value + "家<br>",
        ].join("");
      },
    };

    return;
  };

  /**
   * 【继承组件要重写】
   * echart 有时需要绑定点击事件什么
   * @param {object} e ReactECharts 返回的 echarts 实例
   */
  eChartRefCallback = (e) => {
    let me = this;
    // me.chartIns = e.getEchartsInstance();
    if (!me._chartIns) {
      me._chartIns = e.getEchartsInstance();
      // 绑定点击事件
      me._chartIns.on("click", function (params) {
        console.log(
          "ViewIndustrialChainBInsight ReactECharts click params: ",
          params
        );
        if (
          params.hasOwnProperty("data") &&
          params.data.hasOwnProperty("_path")
        ) {
          let name = params.name; //点击的节点的name
          //调用点击事件
          me._column1_onClick(params.data._path, name, false);
          // me._column1_onClick(params.dataIndex - 1, name);
        } else {
          me._column1_onClick(null, null, true);
        }
      });
    }
  };

  // end of overwrite
  //===============================================================

  getColorOfLevel = (level, maxLevel = 1000000) => {
    if (level > 0 && level < this._chartColors.length) {
      if (level > maxLevel) {
        return "#dddddd";
      }
      return this._chartColors[level];
    } else {
      return "#dddddd";
    }
  };
}

export default connect(
  (state) => ({
    userInfo: state.account.userInfo,
  }),
  (dispatch) => ({})
)(ViewIndustrialChainBInsight);
