import React from 'react';
import PropTypes from 'prop-types';
import {Button, Tooltip, Badge} from 'antd';

import PB, {SimplePB} from '@/libs/simplePB';

import {IconTypes} from '@/constants/common';

import Icon from '@/components/common/common.icon';
import ViewDataProvider from '@/components/common/dataProvider/common.dataProvider.view';

class ExploreButtonSimple extends React.PureComponent {
  state = {
    title: '微服务',
    shortDescription: '',
    iconId: 'icon-micro-service',
    status: 'idle',
    /*modalVisible: false,*/
    /*hasNewResult: false,*/
  }

  onExploreOverall = () => {
    let me = this;

    me.props.bus.emit('view', 'micro_service.service.show_modal',
      {viewId: me.props.viewDataProvider.viewId, serviceId: me.props.serviceId});
  };

  componentDidMount() {
    let me = this;

    me.props.bus.with(
      me
    ).subscribe('view', 'micro_service.config.builtin_config_loaded', ({id, title, shortDescription, iconId}) => {
      if (id === me.props.serviceId) {
        me.setState({title, shortDescription, iconId});
      }
    }).subscribe('view', 'micro_service.service.do_load_more', ({viewId, serviceId}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId && serviceId === me.props.serviceId) {
        me.setState({status: 'loading'});
      }
    }).subscribe('view', 'micro_service.service.raw_data_loaded', ({hasMoreResult, viewId, serviceId}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId && serviceId === me.props.serviceId) {
        me.setState({
          status: hasMoreResult ? 'stopped' : 'finished',
        });
      }
    }).subscribe('view', 'micro_service.service.failed_to_load', ({viewId, serviceId}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId && serviceId === me.props.serviceId) {
        me.setState({
          status: 'failed',
        });
      }
    }).subscribe('view', 'micro_service.service.result_cleared', ({viewId, serviceId}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId && serviceId === me.props.serviceId) {
        me.setState({
          status: 'idle',
        });
      }
    }).subscribe('view', 'micro_service.service.modal_visible_changed', ({viewId, visible, serviceId}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId && serviceId === me.props.serviceId) {
        if (visible && (me.state.status === 'stopped' || me.state.status === 'finished' || me.state.status === 'failed')) {
          me.setState({
            status: 'idle',
            /*modalVisible: visible,*/
          });
        }/* else {
          me.setState({modalVisible: visible});
        }*/
      }
    });
  }

  componentWillUnmount() {
    this.props.bus.remove(this);
  }

  render() {
    let me = this, statusIdx = 0;

    // statusIdx: 0-idle, 1-success, 2-processing, 3-warning
    switch(me.state.status) {
      case 'failed':
        statusIdx = 3;
        break;
      case 'loading':
        statusIdx = Math.max(statusIdx, 2);
        break;
      case 'stopped':
      case 'finished':
        statusIdx = Math.max(statusIdx, 1);
        break;
    }

    return (
      <Tooltip
        title={(
          <div>
            <div>{me.state.title}</div>
            {me.state.shortDescription ? (
              <div>{me.state.shortDescription}</div>
            ) : null}
          </div>
        )}
        trigger={'hover'}
        placement={'left'}
      >
        <Button
          shape={'circle'}
          onClick={me.onExploreOverall}
        >
          <Badge status={[undefined, 'success', 'processing', 'warning'][statusIdx]}>
            <Icon name={me.state.iconId || 'icon-micro-service'} type={IconTypes.ICON_FONT} />
          </Badge>
        </Button>
      </Tooltip>
    );
  }
}

ExploreButtonSimple.defaultProps = {
  bus: PB,
};

ExploreButtonSimple.propTypes = {
  bus: PropTypes.instanceOf(SimplePB),
  serviceId: PropTypes.string.isRequired,
  viewDataProvider: PropTypes.instanceOf(ViewDataProvider).isRequired,
};

export default ExploreButtonSimple;