import React from 'react';
import PropTypes from 'prop-types';
import {Tooltip} from "antd";
import UserAvatar from 'react-user-avatar';

import PB, {SimplePB} from "@/libs/simplePB";

import {AvatarColors} from '@/constants/common';
import Icon from "@/components/common/common.icon";

import style from '@/style/common/view/common.view.teamwork.memberBar.less';
import {getToken, REQUEST_BASE} from "@/utils/HttpUtil";

import {SysUIConfig} from "@/constants/sys.config";

import {getNodeIcon, NODE_TYPE_TEXT} from '@/constants/vis.defaultDefine.1';


class TeamworkMemberMention extends React.Component {

  render() {
    let me = this;
    return (
      <div style={{display: 'flex',visibility: SysUIConfig.TeamworkMemberBar.visibility}}>
          {  me.props.recommendation && me.props.recommendation.length>0 &&  me.props.recommendation.map((user, idx) => user ? (
                  <Tooltip
                    key={`n-${idx}`}
                    placement={idx < 3 ? 'bottomLeft' : 'bottom'}
                    title={(user.nodeFname ? (
                      <span>
                        <span style={{fontWeight: 'bold'}}>{user.nick} </span>
                        在图谱
                        <span style={{fontWeight: 'bold'}}> {user.viewName} </span>
                        中提到：<br />
                        <span style={{fontWeight: 'bold'}}>
                          <Icon {...getNodeIcon({
                            type: NODE_TYPE_TEXT,
                            lev: user['nodeVrVisibleType'],
                          })} style={{marginRight: '0.5em'}} />
                          {user.nodeFname}
                        </span>
                      </span>
                    ) : (
                      <span>
                        <span style={{fontWeight: 'bold'}}>{user.nick} </span>
                        在图谱
                        <span style={{fontWeight: 'bold'}}> {user.viewName} </span>
                        中提到类似节点
                      </span>
                    ))}
                  >
                      <div
                        className={`${style['avatar']} ${user.picId ? '' : style['avatar-default-icon']}`}
                        onClick={e => {me.props.onOpen(e)}}
                      >
                        <UserAvatar
                          size={'var(--avatar-size)'}
                          name={user.nick}
                          src={user.picId ? `${REQUEST_BASE}/user/user/file/${user.picId}?Authorization=${getToken()}` : undefined}
                          colors={AvatarColors}
                        />
                      </div>
                      
                  </Tooltip>
                ) : null)
            }
      </div>
    );
  }
}

TeamworkMemberMention.defaultProps = {
  bus: PB,
};

TeamworkMemberMention.propTypes = {
  bus: PropTypes.instanceOf(SimplePB),
  recommendation: PropTypes.array,
  onOpen: PropTypes.func.isRequired,
};

export default TeamworkMemberMention;
