import _ from 'lodash';
import {distinctFn} from "@/components/common/view/statistics/node/common.view.statistics.node.logic.common";

export const statisticsByFavorite = ({nodes, distinct, cb}) => {
  let statisticsFn = (nodes, cb) => {
    // statistics
    let result = ['重要节点', '我的收藏'].map(
      (label, idx) => ({label, rate: 0, nodes: [], pos: idx + 1})
    ), pos = 0, batch, workFn = () => {
      batch = 0;
      while (batch < 1000 && pos < nodes.length) {
        let n = nodes[pos];
        if (n._userFavorite > -1) {
          result[1].rate++;
          result[1].nodes.push(n);
        }
        if (n._viewFavorite > -1) {
          result[0].rate++;
          result[0].nodes.push(n);
        }
        batch++;
        pos++;
      }
      if (pos < nodes.length) {
        setTimeout(workFn, 5);
      } else {
        cb(result);
      }
    };
    workFn();
  };

  distinctFn({nodes, distinct, cb: (nodes) => {
    statisticsFn(nodes, (result) => {
      cb(result);
    });
  }});
};