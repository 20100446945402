import React from 'react';

import { List } from 'antd';
import Icon from '@/components/common/common.icon';
import frameStyle from '@/style/containers/mainView.accountCenter.less';
import style from '@/style/components/accountCenter/account.basicInfoPanel.less';

class AccountBindingInfoPanel extends React.Component {
  state = {};
  toBindWX = () => {

  };
  render() {
    return (
      <div className={`${frameStyle['content-inner']} ${style['frame']} dark-theme`}>
        <h1 className={style['header']}>
          账号绑定
        </h1>
        <List className={`${style['content']} dark-theme`}>
          <List.Item actions={[<a onClick={() => this.toBindWX()}>立即绑定</a>]}>
            <List.Item.Meta
              title={'绑定微信'}
              description={
                <span>
                  <Icon name={'warning'} className={'warning-text'} style={{ marginRight: '0.5em' }} />
                  当前未绑定微信，绑定后可通过微信扫码登录。
                </span>
              }
            />
          </List.Item>
        </List>
      </div>
    );
  }
}

AccountBindingInfoPanel.defaultProps = {};

AccountBindingInfoPanel.propTypes = {};

export default AccountBindingInfoPanel;
