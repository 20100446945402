/*
 * @Author: Zheng
 * @Date: 2022-11-29 14:16:56
 * @LastEditTime: 2022-11-29 15:54:36
 * @LastEditors: Zheng
 * @Description: 节点扩展路由
 * @Copyright by joinmap
 */
import React from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import Qs from "qs";

import { Copyright } from "@/components/common/common.copyright";
import Loading from "@/components/common/common.loading";

import layoutStyles from "@/style/default/defaultViewLayout.less";

// 组件
import ViewNodeExtendedEchart from "@/components/nodeExtendedView/extended.echart";
import ViewGovEntInsight from "@/components/insightView/insight.gov.ent";

// const { Content } = Layout;

class NodeExtendedContainer extends React.Component {
  state = {
    // 加载页面遮罩
    viewLoading: {
      status: false,
      content: undefined, // 显示文字
    },
  };
  // url传入的参数
  // limit 返回 tag 数量。0 返回全部数据，默认返回150个
  // sortBy 后台排序 各个组件接口自己定义
  _query = {
    viewId: undefined,
    nodeId: undefined,
    limit: undefined,
    sortBy: undefined,
    params: undefined,
    isDev: false,
    isDebug: false,
  };

  componentDidMount() {}

  render() {
    if (this.props.history.location && this.props.history.location.search) {
      let qs = Qs.parse(this.props.history.location.search, {
        ignoreQueryPrefix: true,
        strictNullHandling: true,
      });

      // 其他自定义参数
      Object.keys(qs).forEach((k) => {
        if (k === "key") {
          // 读取 localstorage 内 key 的值
          try {
            this._query.params = JSON.parse(
              localStorage.getItem(qs.k)
            );
          } catch (e) {
            this._query.params = undefined;
          }
        } else if (k === "is_dev") {
          this._query.isDev = qs[k] == 1;
        } else if (k === "is_debug") {
          this._query.isDebug = qs[k] == 1;
        } else if (k === "sort_by") {
          this._query.sortBy = qs[k];
        } else if (k === "view_id") {
          this._query.viewId = qs[k];
        } else if (k === "node_id") {
          this._query.nodeId = qs[k];
        } else {
          this._query[k] = qs[k];
        }
      });

      if (this._query.isDebug) console.log("NodeExtendedContainer qs: ", qs);
    }
    if (this._query.isDebug)
      console.log("NodeExtendedContainer _query: ", this._query);

    return (
      <div className={layoutStyles["layout-wrap"]}>
        <Loading />
        <div
          className={`${layoutStyles["content-outer-fullscreen"]} dark-theme scrollbar-18`}
        >
          <Switch>
            <Route
              exact
              path={"/node_extended/echart"}
              component={({ match, history }) => (
                <ViewNodeExtendedEchart
                  history={history}
                  userInfo={this.props.userInfo}
                  query={this._query}
                />
              )}
            />
            <Route
              exact
              path={"/node_extended/gov_ent"}
              component={({ match, history }) => (
                <ViewGovEntInsight
                  history={history}
                  userInfo={this.props.userInfo}
                  query={this._query}
                />
              )}
            />
            <Route render={() => <Redirect to={"/"} />} />
          </Switch>
          {/*<Copyright className={style["version"]}/>*/}
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    userInfo: state.account.userInfo,
  }),
  (dispatch) => ({})
)(NodeExtendedContainer);
