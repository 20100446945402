import {bindUtil, withPromiseMessageDecorator} from "@/libs/core-decorators";
import messageProxy from '@/components/common/common.messageProxy';
import {getErrorInfo as commonGetErrorInfo} from "@/components/common/common.functions";
import {presentationOperationMessages} from "@/constants/messages";
import {
  API_AddPresentationConfig,
  API_LoadPresentationConfigList,
  API_RemovePresentationConfig,
  API_UpdatePresentationConfig,
  API_LoadPresentationList,
  API_getPresentations,
  API_SetPresentationsPublic
} from "@/api/presentation";

@bindUtil.asSourceClass
class PresentationDataProvider {
  static loadConfigList = (viewId, userId) => {
    return new Promise((resolve, reject) => {
      API_LoadPresentationConfigList(viewId, userId).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve(response.data.data);
        } else {
          reject({...PresentationDataProvider.getErrorInfo(response)});
        }
      }).catch(error => {
        reject({...PresentationDataProvider.getErrorInfo(error)});
      });
    });
  };

  static loadPresentationList = (params) => {
    return new Promise((resolve, reject) => {
      API_LoadPresentationList(params).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve(response.data.data);
        } else {
          reject({...PresentationDataProvider.getErrorInfo(response)});
        }
      }).catch(error => {
        reject({...PresentationDataProvider.getErrorInfo(error)});
      });
    });
  };

  static getPresentations = (presentationIds) => {
    return new Promise((resolve, reject) => {
      API_getPresentations(presentationIds).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve(response.data.data);
        } else {
          reject({...PresentationDataProvider.getErrorInfo(response)});
        }
      }).catch(error => {
        reject({...PresentationDataProvider.getErrorInfo(error)});
      });
    });
  };

  static addConfig = (config) => {
    return new Promise((resolve, reject) => {
      API_AddPresentationConfig(config).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve(response.data.data);
        } else {
          reject({...PresentationDataProvider.getErrorInfo(response)});
        }
      }).catch(error => {
        reject({...PresentationDataProvider.getErrorInfo(error)});
      });
    });
  };

  static updateConfig = (presentationId, config) => {
    return new Promise((resolve, reject) => {
      API_UpdatePresentationConfig(presentationId, config).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve(response.data.data);
        } else {
          reject({...PresentationDataProvider.getErrorInfo(response)});
        }
      }).catch(error => {
        reject({...PresentationDataProvider.getErrorInfo(error)});
      });
    });
  };

  static removeConfig = (presentationId) => {
    return new Promise((resolve, reject) => {
      API_RemovePresentationConfig(presentationId).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve();
        } else {
          reject({...PresentationDataProvider.getErrorInfo(response)});
        }
      }).catch(error => {
        reject({...PresentationDataProvider.getErrorInfo(error)});
      });
    });
  };

  static setPresentationsPublic = (presentationId, isPublic) => {
    return new Promise((resolve, reject) => {
      API_SetPresentationsPublic(presentationId, isPublic).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve(response.data.data);
        } else {
          reject({...PresentationDataProvider.getErrorInfo(response)});
        }
      }).catch(error => {
        reject({...PresentationDataProvider.getErrorInfo(error)});
      });
    });
  };

  /**
   * @private
   * 通過Axios请求返回的错误信息获取错误详情
   *
   * @param {*} error
   *
   * @return {{msg: string, code: number}}
   */
  static getErrorInfo = commonGetErrorInfo;
}

const overrideMessages = {};

/**
 * @type {typeof PresentationDataProvider}
 */
const PresentationDataProviderWrapper = withPromiseMessageDecorator(
  messageProxy(presentationOperationMessages, overrideMessages)
)(PresentationDataProvider);

// noinspection JSUnusedGlobalSymbols
export const overrideNextMessage = (method, msg) => {
  overrideMessages[method] = msg;
};

export default PresentationDataProviderWrapper;