import React from 'react';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import qs from 'qs';
import {/*Button, */Tooltip} from "antd";
import _ from "lodash";
import loadJs from "loadjs";

import {IconTypes} from '@/constants/common';

import {uuidV4} from "@/utils/UUID";

import Icon from '@/components/common/common.icon';
import AuthForm from '@/components/common/common.authForm';

import style from '@/style/components/common.authForm.less';
import weChatCss from '@/constants/weChatCss';
import {WeChatLoginStatusEnum} from "@/constants/account";
import SysConfig from "@/constants/sys.config";
import intl from 'react-intl-universal';

@withRouter
class LoginViaWechatPanel extends React.Component {
  wxLogin = undefined;

  state = {
    weChatLoginPanelId: _.uniqueId('app-random-id-'),
    wxLoginAble:true
  };

  wxLoginViaFwhStatusCheckInterval = undefined;
  wxLoginHopeTime = 0;

  initWxLoginViaFwhQrCode = () => {
    let me = this;
    me.wxLoginHopeTime = 0;
    me.props.doGetWeChatFwhTicket();
    me.setState({wxLoginAble:true}); 
  };

  initWxLogin = () => {
    let me = this,
      fn = () => {
        setTimeout(() => {
          if (me.wxLogin) delete me.wxLogin;
          me.wxLogin = undefined;
          let state = uuidV4();
          me.wxLogin = new window['WxLogin']({
            'id': this.state.weChatLoginPanelId, // div的id
            'appid': SysConfig.weChatLogin[SysConfig.weChatLogin.use].appid,
            'redirect_uri': SysConfig.weChatLogin[SysConfig.weChatLogin.use].redirect_uri,
            'scope': 'snsapi_login', // 写死
            'state': encodeURI(btoa(JSON.stringify({action: 'login', state}))), // 自定义
            'style': 'black', // 二维码黑白风格
            'href': weChatCss,
          });
          me.props.onWeChatResourceLoaded(state);
        }, 1000);
      };
    (typeof window['WxLogin'] !== "undefined") ? fn() : loadJs(window.location.protocol + '//res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js', fn);
  };

  doWxLoginViaFwhStatusCheckInterval = () => {
    let me = this;
    if(me.wxLoginHopeTime<60){
      me.props.doCheckWeChatFwhTicket(me.props.weChatFwhTicket);
      me.wxLoginHopeTime++;
    }else{
      me.stopWxLoginViaFwhStatusCheckInterval();
      me.setState({wxLoginAble:false})
    }
  }

  stopWxLoginViaFwhStatusCheckInterval = () => {
    let me = this;

    if (me.wxLoginViaFwhStatusCheckInterval) {
      clearInterval(me.wxLoginViaFwhStatusCheckInterval);
      me.wxLoginViaFwhStatusCheckInterval = undefined;
    }
  }

  doReLoadWxLogin = () =>{
    let me = this;

    requestAnimationFrame(() => me.initWxLoginViaFwhQrCode());
    me.wxLoginViaFwhStatusCheckInterval =
            setInterval(() => me.doWxLoginViaFwhStatusCheckInterval(), 1000);
  }

  componentDidMount() {
    let me = this;

    if (me.props.loginStatus === WeChatLoginStatusEnum.LOADING_FWH_QR_CODE) {
      requestAnimationFrame(() => me.initWxLoginViaFwhQrCode());
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let me = this;
    if(intl.get('locale')!=='zh-cn'){
      me.props.goBack();
    }

    if (prevProps.loginStatus !== me.props.loginStatus
      && me.props.loginStatus === WeChatLoginStatusEnum.LOADING_FWH_QR_CODE) {

      requestAnimationFrame(() => me.initWxLoginViaFwhQrCode());
    }
  }

  componentWillUnmount() {
    let me = this;

    me.stopWxLoginViaFwhStatusCheckInterval();
    me.setState({wxLoginAble:true})
  }

  render() {
    let panelContent, me = this;

    if (me.wxLoginViaFwhStatusCheckInterval) {
      clearInterval(me.wxLoginViaFwhStatusCheckInterval);
      me.wxLoginViaFwhStatusCheckInterval = undefined;
    }

    switch (me.props.loginStatus) {
      case WeChatLoginStatusEnum.WAIT_FOR_SCANNING:
        panelContent = <span style={{display: 'none'}}>&nbsp;</span>;
        let params = qs.parse(me.props.location.search, {
          ignoreQueryPrefix: true,
          strictNullHandling: true,
        });
        try {
          params['state'] = JSON.parse(atob(params['state'])).state;
        } catch (e) {
          // ignore
        }
        if (params['state'] === me.props.weChatState) {
          me.props.doLoginViaWeChat(params['code']);
        } else {
          if (params['state']) {
            requestAnimationFrame(() => me.props.history.push('/login'));
          }
          if (!me.wxLogin) {
            me.props.reset();
          }
        }
        break;
      case WeChatLoginStatusEnum.PROCESSING:
        panelContent = <span><Icon name="loading"/><br/><br/>{intl.get('Custom.general.logining')}</span>;
        break;
      case WeChatLoginStatusEnum.SUCCESS:
        panelContent = <span><Icon name="check"/><br/><br/>{intl.get('Custom.general.loginSuccess')}</span>;
        break;
      case WeChatLoginStatusEnum.LOADING:
        // LOADING
        panelContent = <span><Icon name="loading"/><br/><br/>{intl.get('Custom.general.resourceLoading')}</span>;
        requestAnimationFrame(() => me.initWxLogin());
        break;
      case WeChatLoginStatusEnum.WAIT_FOR_SCANNING_FWH_QR_CODE:
        if (me.props.weChatFwhTicket) {
          panelContent = (
            <div style={{width: '100%', textAlign: 'center'}}>
              <img
                src={`https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=${encodeURIComponent(me.props.weChatFwhTicket)}`}
                style={{width: '230px', marginTop: '15px', opacity:me.state.wxLoginAble?1:0.1 }}
              /><br /><br />
              {me.state.wxLoginAble ? (intl.get('Custom.general.pWechatLogin')):(<span>{intl.get('Custom.general.qrcodeExpired')} 
                <a style={{color:'rgb(24, 144, 255)'}} onClick={me.doReLoadWxLogin} >{intl.get('Custom.general.refresh')}</a></span>)}
            </div>
          );
          me.wxLoginViaFwhStatusCheckInterval =
            setInterval(() => me.doWxLoginViaFwhStatusCheckInterval(), 1000);
        } else {
          requestAnimationFrame(() => me.initWxLogin());
        }
        break;
      default:
        // LOADING_FWH_QR_CODE
        panelContent = <span><Icon name="loading"/><br/><br/>{intl.get('Custom.general.resourceLoading')}</span>;
    }
    // noinspection RequiredAttributes
    return (
      <AuthForm>
        {/*<Button shape="circle" size="small" icon="arrow-left" className={style['form-button']} onClick={me.props.goBack}/>
        &nbsp;&nbsp;&nbsp;<span style={{color: 'gray'}}>返回</span>
          <br/>
          <br/>*/}
        <div
          style={{
            height: '6rem',
            width: 'calc(350px + 5rem)',
            position: 'absolute',
            margin: '-2.5rem',
            textAlign: 'right',
            lineHeight: '5.5rem',
            fontSize: '5.5rem',
          }}
        >
          <Tooltip title={intl.get('Custom.general.accountLogin')}>
            <a onClick={me.props.goBack}>
              <Icon name={'icon-login-via-password'} type={IconTypes.ICON_FONT} />
            </a>
          </Tooltip>
        </div>
        <h1 className={style['form-title']} style={{marginBottom:'0'}}>{intl.get('Custom.general.wechatLogin')}</h1>
        <div id={me.state.weChatLoginPanelId} className={style['third-party-login-wechat-frame']}>
          {panelContent}
        </div>
      </AuthForm>
    );
  }
}

// noinspection JSUnusedGlobalSymbols
LoginViaWechatPanel.defaultProps = {
  loginStatus: WeChatLoginStatusEnum.LOADING,
  weChatState: '',
  goBack: () => {
    console.log('goBack property is not set.');
  },
  onWeChatResourceLoaded: (state) => {
    console.log('onWeChatResourceLoaded property is not set, state: %s.', state);
  },
  doLoginViaWeChat: (code) => {
    console.log('doLoginViaWeChat property is not set, code: %s.', code);
  },
  reset: () => {
    console.log('reset property is not set.');
  },
};

LoginViaWechatPanel.propTypes = {
  loginStatus: PropTypes.number,
  weChatState: PropTypes.string,
  weChatFwhTicket: PropTypes.string,
  goBack: PropTypes.func,
  onWeChatResourceLoaded: PropTypes.func,
  doLoginViaWeChat: PropTypes.func,
  doGetWeChatFwhTicket: PropTypes.func,
  doCheckWeChatFwhTicket: PropTypes.func,
  reset: PropTypes.func,
};

export default LoginViaWechatPanel;
