import React from 'react';
import PropTypes from 'prop-types';
import {Button, message, Tooltip} from "antd";
import UserAvatar from 'react-user-avatar';

import PB, {SimplePB} from "@/libs/simplePB";

import {AvatarColors, IconTypes} from '@/constants/common';
import Icon from "@/components/common/common.icon";

import style from '@/style/common/view/common.view.teamwork.memberBar.less';
import {getToken, REQUEST_BASE} from "@/utils/HttpUtil";

import TeamworkMemberFindModal from '@/components/common/view/teamwork/common.view.teamwork.memberFindModal';
import {SysUIConfig} from "@/constants/sys.config";
import TeamworkMemberMention from '@/components/common/view/teamwork/common.view.teamwork.memberMention';
import addMemberImg from '@/assets/icon/add-member.png';
import intl from 'react-intl-universal';

const displayAvatarAmount = 5;

class TeamworkMemberBar extends React.Component {
  state = {
    showAddMemberModal: false, // 是否显示添加成员弹框
    showMemberBar: true, // 是否显示协作列表
  };

  onShowTitleAndDescription = e => {
    e.stopPropagation();
    this.props.bus.emit('view', 'info.on_edit');
  };

  onShowTeamworkSettings = e => {
    e.stopPropagation();
    this.props.bus.emit('view', 'config.join_type_modal.on_show', this.props.viewId);
  };

  onShowTeamworkMembers = e => {
    e.stopPropagation();
    this.props.bus.emit('teamwork', 'member.list.show_modal');
  };

  closeAddMemberModal = () => {
    this.setState({
      showAddMemberModal: false,
    })
  };

  openAddMemberModal = (e) => {
    e.stopPropagation();
    let me = this;

    let owner = me.props.userList.find(user => user.userId === me.props.viewOwnerId);
    if (owner.userId === me.props.currentUserId) {
      this.setState({
        showAddMemberModal: true,
      })
    } else {
      message.info(intl.get('Custom.message.masterRight'))
    }
  };

  render() {
    const {showMemberBar} = this.state;
    let me = this;

    let amountLeft = Math.max(
      me.props.userList.length - displayAvatarAmount, 0
    );

    let owner = me.props.userList.find(user => user.userId === me.props.viewOwnerId);

    return (
        <div className={style['container']} style={{visibility: SysUIConfig.TeamworkMemberBar.visibility}}>
          <Tooltip
              arrowPointAtCenter={true}
              placement={'bottomLeft'}
              title={showMemberBar ? <div>{intl.get('Custom.view.putMemberlit')}</div> : <div>{intl.get('Custom.view.expandMemberlit')}</div>}
          >
            <div
                className={style['bar_show_btn']}
                onClick={() => {
                  me.setState({showMemberBar: !showMemberBar})
                }}
            >
              {
                showMemberBar ? <Icon name="double-left" className={style['bar_show_btn_icon']}/> :
                    <Icon name="double-right" className={style['bar_show_btn_icon']}/>
              }

            </div>
          </Tooltip>
          <div className={style['bar_content']} style={me.state.showMemberBar ? {display: 'inline-block'} : {display: 'none'}}>
          {
          owner ? (
            <Tooltip
              arrowPointAtCenter={true}
              placement={'bottomLeft'}
              title={(
                <div
                  className={`${style['member-avatar-tooltip']} dark-theme`}
                >
                  <h4 onClick={() => me.props.bus.emit('teamwork', 'member.node_panel.show', {memberId: owner.userId})}>{owner.nick}<span style={{display: 'inline-block', float: 'right', marginLeft: '0.5rem'}}>组长</span></h4>
                  <table onClick={() => me.props.bus.emit('teamwork', 'member.node_panel.show', {memberId: owner.userId})}>
                    <tbody>
                    <tr>
                      <td style={{paddingRight: '0.25em'}}>{intl.get('Custom.general.weekIntegral')}：</td>
                      <td style={{textAlign: 'right'}}>
                        {(!owner.nodeStatistics || owner.nodeStatistics.currentWeekday < 0) ? intl.get('Custom.general.loading') : owner.nodeStatistics.currentWeekday}
                      </td>
                    </tr>
                    <tr>
                      <td style={{paddingRight: '0.25em'}}>{intl.get('Custom.general.thisIntegral')}：</td>
                      <td style={{textAlign: 'right'}}>
                        {(!owner.nodeStatistics || owner.nodeStatistics.current < 0) ? intl.get('Custom.general.loading') : owner.nodeStatistics.current}
                      </td>
                    </tr>
                    <tr>
                      <td style={{paddingRight: '0.25em'}}>{intl.get('Custom.general.allIntegral')}：</td>
                      <td style={{textAlign: 'right'}}>
                        {(!owner.nodeStatistics || owner.nodeStatistics.total < 0) ? intl.get('Custom.general.loading') : owner.nodeStatistics.total}
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <div className={style['member-avatar-tooltip-btn-frame']}>
                    <Tooltip
                      arrowPointAtCenter={true}
                      placement={'bottomLeft'}
                      title={intl.get('Custom.view.editbase')}
                    >
                      <Button disabled={me.props.readonly} onClick={me.onShowTitleAndDescription}><Icon name="edit"/></Button>
                    </Tooltip>
                    <Tooltip placement={'bottom'} title={intl.get('Custom.view.openSettings')}>
                      <Button disabled={me.props.readonly} onClick={me.onShowTeamworkSettings}><Icon name="setting" /></Button>
                    </Tooltip>
                    <Tooltip placement={'bottom'} title={intl.get('Custom.view.memberlist')}>
                      <Button onClick={me.onShowTeamworkMembers}><Icon name="team"/></Button>
                    </Tooltip>
                  </div>
                </div>
              )}
              key={`user-${owner.userId}`}
              overlayStyle={{zIndex: 1000}}
            >
              <div
                className={`${style['avatar']} ${owner.picId ? '' : style['avatar-default-icon']}`}
                onClick={() => me.props.bus.emit('teamwork', 'member.node_panel.show', {memberId: owner.userId})}
              >
                <UserAvatar
                  size={'var(--avatar-size)'}
                  name={owner.nick}
                  src={owner.picId ? `${REQUEST_BASE}/user/user/file/${owner.picId}?Authorization=${getToken()}` : undefined}
                  colors={AvatarColors}
                />
                <div className={style['avatar-tag']}>
                  <Icon name={'icon-triangle-mark'} type={IconTypes.ICON_FONT} />
                </div>
              </div>
            </Tooltip>
          ) : null
        }
        {
          me.props.userList.length > 0 ? (
            <div className={`${style['avatar-btn-frame']} ${style['avatar-more']}`}>
              <div className={style['avatar-btn']}>
                <Tooltip
                  title={intl.get('Custom.view.seeMemberlit')}
                  placement={'bottom'}
                >
                  <a onClick={() => me.props.bus.emit('teamwork', 'member.list.show_modal', undefined)}>
                    【 {me.props.userList.length} 】
                  </a>
                </Tooltip>
              </div>
            </div>
          ) : null
        }
        {
          me.props.userListSorted ? me.props.userList.slice(0, me.props.userList.length - amountLeft)
            .map((user, idx) => {
              let mark = null, title = null;
              switch (idx) {
                case 0:
                  mark = (
                    <div className={style['avatar-tag']}>
                      <Icon name={'icon-pentagon-gold'} type={IconTypes.ICON_FONT} />
                    </div>
                  );
                  title = (<span style={{display: 'inline-block', float: 'right', marginLeft: '0.5rem'}}>本周目前第1</span>);
                  break;
                case 1:
                  mark = (
                    <div className={style['avatar-tag']}>
                      <Icon name={'icon-pentagon-silver'} type={IconTypes.ICON_FONT} />
                    </div>
                  );
                  title = (<span style={{display: 'inline-block', float: 'right', marginLeft: '0.5rem'}}>本周目前第2</span>);
                  break;
                case 2:
                  mark = (
                    <div className={style['avatar-tag']}>
                      <Icon name={'icon-pentagon-copper'} type={IconTypes.ICON_FONT} />
                    </div>
                  );
                  title = (<span style={{display: 'inline-block', float: 'right', marginLeft: '0.5rem'}}>本周目前第3</span>);
                  break;
              }
              return (
                <Tooltip
                  arrowPointAtCenter={true}
                  placement={idx < 1 ? 'bottomLeft' : 'bottom'}
                  title={(
                    <div
                      className={`${style['member-avatar-tooltip']} dark-theme`}
                      onClick={() => me.props.bus.emit('teamwork', 'member.node_panel.show', {memberId: user.userId})}
                    >
                      <h4 style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                        <span>{user.nick}</span>
                        <span style={{fontSize: '0.8rem', marginLeft: '0.5rem'}}>本周目前第{idx + 1}</span>
                      </h4>
                      <table>
                        <tbody>
                        <tr>
                          <td style={{paddingRight: '0.25em'}}>{intl.get('Custom.general.weekIntegral')}：</td>
                          <td style={{textAlign: 'right'}}>
                            {(!user.nodeStatistics || user.nodeStatistics.currentWeekday < 0) ? intl.get('Custom.general.loading') : user.nodeStatistics.currentWeekday}
                          </td>
                        </tr>
                        <tr>
                          <td style={{paddingRight: '0.25em'}}>{intl.get('Custom.general.thisIntegral')}：</td>
                          <td style={{textAlign: 'right'}}>
                            {(!user.nodeStatistics || user.nodeStatistics.current < 0) ? intl.get('Custom.general.loading') : user.nodeStatistics.current}
                          </td>
                        </tr>
                        <tr>
                          <td style={{paddingRight: '0.25em'}}>{intl.get('Custom.general.allIntegral')}：</td>
                          <td style={{textAlign: 'right'}}>
                            {(!user.nodeStatistics || user.nodeStatistics.total < 0) ? intl.get('Custom.general.loading') : user.nodeStatistics.total}
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  )}
                  key={`user-${user.userId}`}
                  overlayStyle={{zIndex: 1000}}
                >
                  <div
                    className={`${style['avatar']} ${user.picId ? '' : style['avatar-default-icon']}`}
                    onClick={() => me.props.bus.emit('teamwork', 'member.node_panel.show', {memberId: user.userId})}
                  >
                    <UserAvatar
                      size={'var(--avatar-size)'}
                      name={user.nick}
                      src={user.picId ? `${REQUEST_BASE}/user/user/file/${user.picId}?Authorization=${getToken()}` : undefined}
                      colors={AvatarColors}
                    />
                   {/* {mark}*/}
                  </div>
                </Tooltip>
              )
            }) : null
        }
        {
          owner ? (
            <div id="add_member_btn" className={`${style['avatar-btn-frame']} ${style['add-member']}`}
                 onClick={e => {
                   e.stopPropagation();
                   if (owner.userId === me.props.currentUserId) {
                     this.setState({
                       showAddMemberModal: true,
                     })
                   } else {
                     message.info(intl.get('Custom.message.masterRight'))
                   }
                 }}
            >
              <Tooltip
                title={intl.get('Custom.general.add')+intl.get('Custom.view.member')}
                placement={'bottom'}
              >
                <img src={addMemberImg} style={{width:'100%'}} />
              </Tooltip>
            </div>
          ) : null
        }
        <TeamworkMemberFindModal
          bus={me.props.bus}
          visible={me.state.showAddMemberModal}
          viewId={me.props.viewId}
          userList={me.props.userList}
          onClose={me.closeAddMemberModal}
          recommendation={me.props.recommendation||[]}
          userInfo={me.props.userInfo}
        />
        {me.props.recommendation && me.props.recommendation.length>0 && <TeamworkMemberMention
					bus={me.bus}
					recommendation={me.props.recommendation.slice(0, 5)}
          onOpen={me.openAddMemberModal}
				/>}
        </div>

  </div>
    );
  }
}

TeamworkMemberBar.defaultProps = {
  bus: PB,
  userList: [],
  readonly: true,
};

TeamworkMemberBar.propTypes = {
  bus: PropTypes.instanceOf(SimplePB),
  userList: PropTypes.array,
  userListSorted: PropTypes.bool,
  readonly: PropTypes.bool,
  viewId: PropTypes.string.isRequired,
  viewOwnerId: PropTypes.number.isRequired,
  currentUserId: PropTypes.number.isRequired,
  recommendation: PropTypes.array,
  userInfo: PropTypes.object.isRequired,
};

export default TeamworkMemberBar;
