import React from 'react';
import PropTypes from 'prop-types';

import PB, {SimplePB} from '@/libs/simplePB';

import ViewDataProvider from "@/components/common/dataProvider/common.dataProvider.view";

import ExploreCompanyOverallModal from '@/components/common/view/explore/common.view.explore.company.overall.modal';
import ExploreCompanyOverallLogic from '@/components/common/view/explore/common.view.explore.company.overall.logic';
import ExploreCompanyRecommendDetailLogic
  from '@/components/common/view/explore/common.view.explore.company.recommendDetail.logic';
import ExploreCompanyRecommendDetailModal
  from '@/components/common/view/explore/common.view.explore.company.recommendDetail.modal';

class ExploreCompany extends React.PureComponent {
  state = {
    showOverallResultModal: false,
    showRecommendDetailResultModal: false,
  };

  onExploreOverall = () => {
    let me = this;

    me.setState({showOverallResultModal: true});
  };

  onRecommendDetail = () => {
    let me = this;

    me.setState({showRecommendDetailResultModal: true});
  }

  componentDidMount() {
    let me = this;

    me.props.bus.subscribe(me, 'view', 'explore.explore_company.overall.show_modal', ({viewId}) => {
      if (viewId === me.props.viewDataProvider.viewId) {
        me.onExploreOverall();
      }
    }).subscribe(me, 'view', 'explore.explore_company.recommend_detail.show_modal', ({viewId}) => {
      if (viewId === me.props.viewDataProvider.viewId) {
        me.onRecommendDetail();
      }
    });
  }

  componentWillUnmount() {
    this.props.bus.remove(this);
  }

  render() {
    let me = this;
    return (
      <React.Fragment>
        <ExploreCompanyOverallLogic
          bus={me.props.bus}
          viewDataProvider={me.props.viewDataProvider}
        />
        <ExploreCompanyOverallModal
          viewDataProvider={me.props.viewDataProvider}
          visible={me.state.showOverallResultModal}
          onClose={() => me.setState({showOverallResultModal: false})}
          userId={me.props.userId}
        />
        <ExploreCompanyRecommendDetailLogic
          viewDataProvider={me.props.viewDataProvider}
          userId={me.props.userId}
          bus={me.props.bus}
        />
        <ExploreCompanyRecommendDetailModal
          userId={me.props.userId}
          viewDataProvider={me.props.viewDataProvider}
          visible={me.state.showRecommendDetailResultModal}
          onClose={() => me.setState({showRecommendDetailResultModal: false})}
          bus={me.props.bus}
        />
      </React.Fragment>
    );
  }
}

ExploreCompany.defaultProps = {
  bus: PB,
};

ExploreCompany.propTypes = {
  viewDataProvider: PropTypes.instanceOf(ViewDataProvider).isRequired,
  userId: PropTypes.number.isRequired,
  bus: PropTypes.instanceOf(SimplePB),
};

export default ExploreCompany;