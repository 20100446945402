export const VALIDATE_STORE = 'VALIDATE_STORE';

export const INVALIDATE_VIEW_DATA_CACHE = 'INVALIDATE_VIEW_DATA_CACHE';
export const INVALIDATE_ALL_VIEW_DATA_CACHE = 'INVALIDATE_ALL_VIEW_DATA_CACHE';

export const DO_LOAD_VIEW_INFO = 'DO_LOAD_VIEW_INFO';
export const LOAD_VIEW_INFO_SUCCESS = 'LOAD_VIEW_INFO_SUCCESS';
export const LOAD_VIEW_INFO_FAILED = 'LOAD_VIEW_INFO_FAILED';

export const DO_LOAD_VIEW_STRUCTURE = 'DO_LOAD_VIEW_STRUCTURE';
export const LOAD_VIEW_STRUCTURE_SUCCESS = 'LOAD_VIEW_STRUCTURE_SUCCESS';
export const LOAD_VIEW_STRUCTURE_FAILED = 'LOAD_VIEW_STRUCTURE_FAILED';

export const DO_LOAD_VIEW_NODES = 'DO_LOAD_VIEW_NODES';
export const LOAD_VIEW_NODES_SUCCESS = 'LOAD_VIEW_NODES_SUCCESS';
export const LOAD_VIEW_NODES_FAILED = 'LOAD_VIEW_NODES_FAILED';

export const DO_LOAD_VIEW_RELATION = 'DO_LOAD_VIEW_RELATION';
export const LOAD_VIEW_RELATION_SUCCESS = 'LOAD_VIEW_RELATION_SUCCESS';
export const LOAD_VIEW_RELATION_FAILED = 'LOAD_VIEW_RELATION_FAILED';