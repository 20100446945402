/*
 * @Author: Carr.s
 * @Date: 2022-04-20 14:16:56
 * @LastEditTime: 2022-07-11 15:54:36
 * @LastEditors: Carr.s
 * @Description: 洞察视图路由
 * @Copyright by joinmap
 */
import React from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import Qs from "qs";

import { Copyright } from "@/components/common/common.copyright";
import Loading from "@/components/common/common.loading";

import layoutStyles from "@/style/default/defaultViewLayout.less";

// 组件
import ViewCompanyTagsInsight from "@/components/insightView/insight.company.tags";
import ViewCooperationModeInsight from "@/components/insightView/insight.cooperation.mode";
import ViewGovEntInsight from "@/components/insightView/insight.gov.ent";
import ViewPeopleRelationInsight from "@/components/insightView/insight.people.relation";
import ViewPolicyOrientedInsight from "@/components/insightView/insight.policy.oriented";
import ViewKeyWordsInsight from "@/components/insightView/insight.key.words";
import ViewInnovationNetworkInsight from "@/components/insightView/insight.innovation.network";
import ViewIndustrialChainInsight from "@/components/insightView/insight.industrial.chain";
import ViewCorpusAnalysisInsight from "@/components/insightView/insight.corpus.analysis";
import ViewTaskProcessInsight from "@/components/insightView/insight.task.process";
import ViewSocialGovInsight from "@/components/insightView/insight.social.gov";
import ViewIndexChangeInsight from "@/components/insightView/insight.index.change.1";
import ViewEcoInvestigationInsight from "@/components/insightView/insight.eco.investigation";
import ViewRuralGovInsight from "@/components/insightView/insight.rural.gov";
import ViewFarmTechInsight from "@/components/insightView/insight.farm.tech";
import ViewAgglomerationInsight from "@/components/insightView/insight.agglomeration";
import intl from 'react-intl-universal';

// const { Content } = Layout;

class InsightContainer extends React.Component {
  state = {
    // 加载页面遮罩
    viewLoading: {
      status: false,
      content: undefined, // 显示文字
    },
  };
  // url传入的参数
  // limit 返回 tag 数量。0 返回全部数据，默认返回150个
  // sortBy 后台排序 各个组件接口自己定义
  _query = {
    viewId: undefined,
    limit: undefined,
    sortBy: undefined,
    microServiceKey: undefined,
    microServiceParams: undefined,
    isDev: false,
    isDebug: false,
  };

  componentDidMount() {}

  render() {
    if (this.props.history.location && this.props.history.location.search) {
      let qs = Qs.parse(this.props.history.location.search, {
        ignoreQueryPrefix: true,
        strictNullHandling: true,
      });

      // 其他自定义参数
      Object.keys(qs).forEach((k) => {
        if (k === "key") {
          // 读取 localstorage 内 key 的值
          try {
            this._query.microServiceParams = JSON.parse(
              localStorage.getItem(qs.k)
            );
          } catch (e) {
            this._query.microServiceParams = undefined;
          }
        } else if (k === "is_dev") {
          this._query.isDev = qs[k] == 1;
        } else if (k === "is_debug") {
          this._query.isDebug = qs[k] == 1;
        } else if (k === "sort_by") {
          this._query.sortBy = qs[k];
        } else if (k === "view_id") {
          this._query.viewId = qs[k];
        } else {
          this._query[k] = qs[k];
        }
      });

      if (this._query.isDebug) console.log("InsightContainer qs: ", qs);
    }
    if (this._query.isDebug)
      console.log("InsightContainer _query: ", this._query);

    return (
      <div className={layoutStyles["layout-wrap"]}>
        <Loading />
        <div className={layoutStyles["header"]}>
          <div className={layoutStyles["logo"]}>
            <img
              onClick={() => {
                this.props.history.push({
                  pathname: "/index",
                  state: { type: "intro" },
                });
              }}
              src={intl.get('locale')==='zh-cn'?'/assets/logo-hans.png':'/assets/logo-hans_en.png'}
              alt={"炬图"}
            />
          </div>
        </div>
        <div
          className={`${layoutStyles["content-outer-fullscreen"]} dark-theme scrollbar-18`}
        >
          <Switch>
            <Route
              exact
              path={"/insight/company_tags"}
              component={({ match, history }) => (
                <ViewCompanyTagsInsight
                  history={history}
                  userInfo={this.props.userInfo}
                  query={this._query}
                />
              )}
            />
            <Route
              exact
              path={"/insight/cooperation_mode"}
              component={({ match, history }) => (
                <ViewCooperationModeInsight
                  history={history}
                  userInfo={this.props.userInfo}
                  query={this._query}
                />
              )}
            />
            <Route
              exact
              path={"/insight/gov_ent"}
              component={({ match, history }) => (
                <ViewGovEntInsight
                  history={history}
                  userInfo={this.props.userInfo}
                  query={this._query}
                />
              )}
            />
            <Route
              exact
              path={"/insight/people_relation"}
              component={({ match, history }) => (
                <ViewPeopleRelationInsight
                  history={history}
                  userInfo={this.props.userInfo}
                  query={this._query}
                />
              )}
            />
            <Route
              exact
              path={"/insight/policy_oriented"}
              component={({ match, history }) => (
                <ViewPolicyOrientedInsight
                  history={history}
                  userInfo={this.props.userInfo}
                  query={this._query}
                />
              )}
            />
            <Route
              exact
              path={"/insight/key_words"}
              component={({ match, history }) => (
                <ViewKeyWordsInsight
                  history={history}
                  userInfo={this.props.userInfo}
                  query={this._query}
                />
              )}
            />
            <Route
              exact
              path={"/insight/innovation_network"}
              component={({ match, history }) => (
                <ViewInnovationNetworkInsight
                  history={history}
                  userInfo={this.props.userInfo}
                  query={this._query}
                />
              )}
            />
            <Route
              exact
              path={"/insight/industrial_chain"}
              component={({ match, history }) => (
                <ViewIndustrialChainInsight
                  history={history}
                  userInfo={this.props.userInfo}
                  query={this._query}
                />
              )}
            />
            <Route
              exact
              path={"/insight/corpus_analysis"}
              component={({ match, history }) => (
                <ViewCorpusAnalysisInsight
                  history={history}
                  userInfo={this.props.userInfo}
                  query={this._query}
                />
              )}
            />
            <Route
              exact
              path={"/insight/task_process"}
              component={({ match, history }) => (
                <ViewTaskProcessInsight
                  history={history}
                  userInfo={this.props.userInfo}
                  query={this._query}
                />
              )}
            />
            <Route
              exact
              path={"/insight/social_gov"}
              component={({ match, history }) => (
                <ViewSocialGovInsight
                  history={history}
                  userInfo={this.props.userInfo}
                  query={this._query}
                />
              )}
            />
            <Route
              exact
              path={"/insight/index_change"}
              component={({ match, history }) => (
                <ViewIndexChangeInsight
                  history={history}
                  userInfo={this.props.userInfo}
                  query={this._query}
                />
              )}
            />
            <Route
              exact
              path={"/insight/eco_investigation"}
              component={({ match, history }) => (
                <ViewEcoInvestigationInsight
                  history={history}
                  userInfo={this.props.userInfo}
                  query={this._query}
                />
              )}
            />
            <Route
              exact
              path={"/insight/rural_gov"}
              component={({ match, history }) => (
                <ViewRuralGovInsight
                  history={history}
                  userInfo={this.props.userInfo}
                  query={this._query}
                />
              )}
            />
            <Route
              exact
              path={"/insight/farm_tech"}
              component={({ match, history }) => (
                <ViewFarmTechInsight
                  history={history}
                  userInfo={this.props.userInfo}
                  query={this._query}
                />
              )}
            />
            <Route
              exact
              path={"/insight/agglomeration"}
              component={({ match, history }) => (
                <ViewAgglomerationInsight
                  history={history}
                  userInfo={this.props.userInfo}
                  query={this._query}
                />
              )}
            />
            <Route render={() => <Redirect to={"/"} />} />
          </Switch>
          {/*<Copyright className={style["version"]}/>*/}
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    userInfo: state.account.userInfo,
  }),
  (dispatch) => ({})
)(InsightContainer);
