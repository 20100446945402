import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {getToken, REQUEST_BASE} from "@/utils/HttpUtil";

import ViewDataProvider from '@/components/common/dataProvider/common.dataProvider.view';

import style from '@/style/common/relation/common.relation.presentation.less';
import {NetworkFileListLoadingStatus} from "@/libs/view/network/status";
import PB, {SimplePB} from "@/libs/simplePB";
import {defaultIconData} from '@/components/common/view/presentation/common.view.presentation.logic';
import HoverContent from "@/components/common/common.hoverContent";
import TimeDisplayField from "@/components/common/common.timeDisplayField";
import EventListener from "react-event-listener";
import {AvatarColors, IconTypes} from '@/constants/common';
import Icon from '@/components/common/common.icon';
import {getNodeIcon, NODE_TYPE_TEXT} from '@/constants/vis.defaultDefine.1';
import UserAvatar from "react-user-avatar";

const supportedSuffixList = ['png', 'jpg', 'gif', 'jpeg'];

/**
 * 获取节点Tooltip内容
 *
 * @param {ViewDataProvider} viewDataProvider
 * @param {string} nodeId
 * @param {Function} [callback]
 *
 * @return {{
 *   success: boolean,
 *   [processing]: boolean,
 *   [content]: string,
 *   [images]: array,
 * }}
 */
export const getNodeTooltipContent = (viewDataProvider, nodeId, callback) => {
  if (!viewDataProvider || !(viewDataProvider instanceof ViewDataProvider) || !nodeId) {
    return {success: false};
  }
  const fileListStatus = viewDataProvider.fileListLoadingStatus;
  if (
    fileListStatus.status === NetworkFileListLoadingStatus.IDLE ||
    fileListStatus.status === NetworkFileListLoadingStatus.FAILED
  ) {
    viewDataProvider.loadFileList().then(() => {
      if (callback && _.isFunction(callback)) {
        callback(getNodeTooltipContent(viewDataProvider, nodeId));
      }
    }).catch(error => {
      if (callback && _.isFunction(callback)) {
        callback({...error, success: false});
      }
    });
  } else if (fileListStatus.status === NetworkFileListLoadingStatus.SUCCESS) {
    // 获取节点信息
    const nodeInfo = viewDataProvider.getNode(nodeId);
    if (!nodeInfo) {
      return {success: false};
    }
    let content = nodeInfo.description;
    let fname = nodeInfo.fname;
    let tags = nodeInfo.tags;
    let url = nodeInfo.url;
    let remarks = nodeInfo.remarks;
    // 获取图像信息
    const fileMap = viewDataProvider.fileByNode;
    if (!fileMap) return {success: false};
    const attachmentList = (fileMap[nodeId] || []).slice().reverse();
    let images = [];
    for (let i = 0; i < attachmentList.length; i++) {
      let attachment = attachmentList[i];
      if (attachment['from'] !== nodeId) continue; // 节点不匹配
      if (!attachment['fileList'] || !_.isArray(attachment['fileList'])) continue; // 无附件
      for (let j = 0; j < attachment['fileList'].length; j++) {
        let fileInfo = attachment['fileList'][j];
        const segments = fileInfo['fileName'].split(/\./g);
        const type = segments.pop().toLowerCase();
        if (supportedSuffixList.includes(type)) {
          // 找到图片
          images.push(fileInfo);
        }
      }
    }
    return {success: true, content, images,fname,tags,url,remarks};
  } // else processing
  return {success: false, processing: true};
};

class NodeInfoPresentationTooltip extends React.Component {
  state = {
    fullScreenContent: false,
  };

  onKeyDown = e => {
    let me = this;
      me.props.bus.emit('image_light_box', 'hide.do');
      if (e.keyCode === 37 /* left */ ||e.keyCode === 39 /* right */ ) {
        if(e.keyCode === 39){
          me.props.bus.emit('relation', 'presentation.node.show_full_screen');
        }else{
          me.props.bus.emit('relation', 'presentation.node.show_img');
        }
      }
  };

  componentDidMount() {
    let me = this;
    me.props.bus.sub(me, 'relation', 'presentation.node.show_full_screen', () => {
      me.setState({fullScreenContent: true});
    })

    me.props.bus.sub(me, 'relation', 'presentation.node.show_img', () => {
      if(me.props.images){
        me.setState({fullScreenContent: false});
        me.props.bus.emit('image_light_box', 'show.do', {images: me.props.images, idx: 0});
        //me.props.bus.emit('relation', 'presentation.do_pause');
      }
    })
  }

  render() {
    let me = this;
    const {fname, tags, content,url,remarks} = me.props;
    let lines = (me.props.content || '').split('\n');

    let contentList = [], description = '-';
    if (content) {
      contentList.push(content);
    }else{
      if(me.props.type == 'notNode' || me.props.type == 'title'){
        contentList.push(fname);
        lines = fname.split('\n');
      }
    }
    if (contentList.length > 0) {
      description = contentList.join("\n");
    }

    let fullScreenContent = null;

    if (me.state.fullScreenContent && contentList.length > 0) {
      fullScreenContent = (
        <HoverContent onClose={() => me.setState({fullScreenContent: false})}>
          <div
            className={`${style['fullscreen-frame']} scrollbar scrollbar-none`}
            onClick={e => e.stopPropagation()}
          >
            <pre style={{minHeight: '3rem','marginBottom': '1.5rem',fontSize: '3.9rem',color: '#fff',backgroundColor:'#385C80',padding:'0 1rem'}}>
              {fname}
              <span style={{float:'right',width:'3rem',height:'3rem',marginRight: '1rem'}}>
                    <Icon name="zoom-out" theme="outlined" style={{width:'2rem',height:'2rem',cursor:'pointer'}} onClick={e => {me.setState({fullScreenContent: false})}}/>
              </span>
            </pre>
            {me.props.type == 'title' && remarks &&
              <div style={{minHeight: '3rem','marginBottom': '1rem',display:'flex',justifyContent:'space-between'}}>
                <div><span style={{width: '4rem',display:'inline-block'}}><UserAvatar size={50} colors={AvatarColors} style={{marginRight: '0.3em',paddingTop:'0.2rem'}} name={remarks.username}
                        src={remarks.userPicId ? `${REQUEST_BASE}/user/user/file/${remarks.userPicId}?Authorization=${getToken()}` : undefined}
                      /></span>{remarks.username}</div>
                <div><Icon type={IconTypes.ICON_FONT} name={'icon-latest'} color="#1890ff99" style={{marginRight: '0.3em'}}/><TimeDisplayField timestamp={(new Date(remarks.updateTimestamp)).getTime()} /></div>
                <div><Icon {...getNodeIcon({ type: NODE_TYPE_TEXT})} style={{marginRight: '0.3em'}} />{remarks.nodeNum}</div>
                <div><span style={{borderLeft: '0.8rem solid #F37B1D',paddingLeft:'0.6em',fontSize:'2rem',paddingTop:'1.05rem'}}> </span>{remarks.contentList-remarks.nodeNum>0?remarks.contentList-remarks.nodeNum : 0}</div>
              </div>
            }
            {tags && tags.length>0 && 
                <div className="ant-form-vertical" style={{'marginBottom': '1.5rem'}}>    
                  <div className="ant-form-item-control" style={{lineHeight: '0.5'}}>
                      <span className="ant-form-item-children">
                      {tags.map((tag, idx) => (
                          <span className="ant-tag" key={`tag-${idx}`} style={{'backgroundColor': 'rgba(88, 118, 163)',border:'0',textShadow:'none',fontSize: '1.6rem','lineHeight': '1.5',marginBottom:'8px',display:'inline-block'}}>{tag}</span>
                      ))}
                      </span>
                  </div>
              </div>
            }
            <div style={{borderTop: '1px solid rgba(146, 207, 243, 0.7)',height: '2rem'}}></div>
            {description.split('\n').map((line, idx) => (
              <pre key={`l-${idx}`} style={{minHeight: '3rem','marginBottom': '1.8rem'}}>{line}</pre>
            ))}
            {url &&<div onClick={() => {window.open(url,'_blank')}}>
              <pre>{url}</pre>
            </div>}
            <div style={{borderBottom: '1px solid rgba(146, 207, 243, 0.7)',height: '2rem'}}></div> 
          </div>
        </HoverContent>
      );
    }

    let style_tooltip_inner={},style_tooltip_text_box={width: '100%',maxHeight:'70vh'},style_tooltip_text={fontSize: '1.5rem'},style_tooltip_text_desc={fontSize: '1.5rem'},style_tooltip_remarks={display:'flex'};
    let style_tooltip_text_title = {fontSize: '2.6rem',color: '#fff',backgroundColor:'#385C80','lineHeight': '4rem','marginBottom': '1rem',padding:'0 0.5rem',pointerEvents: 'auto'};
    if(me.props.type == 'notNode'){
      style_tooltip_inner = {paddingLeft:0};
      style_tooltip_text = {fontSize: '1.5rem',width: '50%',margin: '0 auto',borderTop:'5px double rgba(146, 207, 243, 0.7)',borderBottom:'5px double rgba(146, 207, 243, 0.7)'};
      style_tooltip_text_desc = {color: '#fff',backgroundColor:'#385C80'};
    }
    if(me.props.type == 'title'){
      style_tooltip_inner = {paddingLeft:0};
        style_tooltip_text_title = {fontSize: '3rem',color: '#fff',backgroundColor:'#385C80','lineHeight': '4.9rem','marginBottom': '1rem',padding:'0 0.5rem',pointerEvents: 'auto'};
        style_tooltip_text_box = {width: '50%','margin': '-10rem auto',maxHeight:'70vh'};
        style_tooltip_text = {width: '100%',margin: '0 auto',borderTop:'5px double rgba(146, 207, 243, 0.7)',borderBottom:'5px double rgba(146, 207, 243, 0.7)'};
        style_tooltip_text_desc = {color: '#fff',fontSize: '1.5rem'};
        style_tooltip_remarks = {'lineHeight': '2.5rem',height: '2.5rem','marginBottom': '1rem',fontSize: '1.4rem',color: '#fff',display:'flex',justifyContent:'space-between',pointerEvents: 'auto'};
    }

    return (fname || me.props.content || (me.props.images && me.props.images.length > 0)) ? (
      <div className={`${style['presentation-tooltip-container']} dark-theme`} style={me.props.style}>
        <div className={`${style['presentation-tooltip-inner']}`} style={style_tooltip_inner} >
          {
            lines && !(lines.length === 1 && lines[0] === '') || me.props.type == 'notNode' || fname ? (
            <div className={`${style['presentation-tooltip-inner-text']}`} style={style_tooltip_text_box}>
                {fname && <div style={style_tooltip_text_title} >
                  {me.props.type == 'title' && remarks && <img src={remarks.iconData ? remarks.iconData : defaultIconData} style={{margin: '0.3em',width:'3.3rem',verticalAlign:'top'}}/>}
                  <span>{fname}</span>
                  {contentList.length > 0 && <div style={{float:'right',width:'3rem',height:'3rem'}}>
                    <Icon name="zoom-in" theme="outlined" style={{width:'2rem',height:'2rem',cursor:'pointer'}} onClick={e => {e.preventDefault();me.setState({fullScreenContent: true});e.stopPropagation();}}/>
                  </div>}
                </div>}
                {me.props.type == 'title' && remarks && 
                  <div style={style_tooltip_remarks}>
                    <div><span style={{width: '2rem',display:'inline-block'}}><UserAvatar size={22} colors={AvatarColors} style={{marginRight: '0.3em',paddingTop:'0.2rem'}} name={remarks.username}
                            src={remarks.userPicId ? `${REQUEST_BASE}/user/user/file/${remarks.userPicId}?Authorization=${getToken()}` : undefined}
                          /></span>{remarks.username}</div>
                    <div><Icon type={IconTypes.ICON_FONT} name={'icon-latest'} color="#1890ff99" style={{marginRight: '0.3em'}}/><TimeDisplayField timestamp={(new Date(remarks.updateTimestamp)).getTime()} /></div>
                    <div><Icon {...getNodeIcon({ type: NODE_TYPE_TEXT})} style={{marginRight: '0.3em'}} />{remarks.nodeNum}</div>
                    <div><span style={{borderLeft: '0.4rem solid #F37B1D',paddingLeft:'0.6em',fontSize:'0.8rem',paddingTop:'0.3rem'}}> </span>{remarks.contentList-remarks.nodeNum>0?remarks.contentList-remarks.nodeNum : 0}</div>
                  </div>}
                {tags && tags.length>0 && 
                  <div class="ant-form-vertical" style={{'marginBottom': '1rem',pointerEvents: 'auto'}}>    
                    <div class="ant-form-item-control" >
                        <span class="ant-form-item-children">
                        {tags.map((tag, idx) => (
                            <span class="ant-tag" key={`tag-${idx}`} style={{'backgroundColor': 'rgba(88, 118, 163)',border:'0',fontSize: '1.3rem',lineHeight:'1.5',marginBottom:'8px'}}>{tag}</span>
                        ))}
                        </span>
                    </div>
                  </div>
                }
                <div className={`scrollbar ${style['panel-desc']}`} style={style_tooltip_text}>
                  <div className={`${style['desc-text']}`}  style={style_tooltip_text_desc}>
                    {
                      lines.map((line, idx) => (
                        <span key={`ln-${idx}`} className={`${style['desc-text-span']}`}>{line}{idx === lines.length - 1 ? null : (<br />)}</span>
                      ))
                    }
                  </div>
                  {url &&<div className={`${style['desc-text']}`} onClick={() => {window.open(url,'_blank')}}>
                    <span>{url}</span>
                  </div>}
              </div>
              {fullScreenContent}
            </div>
            ) : null
          }
          {
            (me.props.images && me.props.images.length > 0) ? (
              <div className={`${style['panel-image']}`} style={{bottom: 'auto',zIndex: 0}} 
                   onClick={() => {
                     me.props.bus.emit('image_light_box', 'show.do', {images: me.props.images, idx: 0});
                     me.props.bus.emit('relation', 'presentation.do_pause');
                   }}
              >
                <img
                  src={`${REQUEST_BASE}/view/project/file/thumb/${Math.ceil(window.innerWidth / 3)}/0/${me.props.images[0]['fileId']}?Authorization=${getToken()}`}
                  alt={me.props.images[0]['fileName']}
                  hspace="10"
                />
              </div>
            ) : null
          }
        </div>
        <EventListener
          target={window}
          onKeyDown={me.onKeyDown}
          onKeyPress={me.onKeyEvent}
          onKeyUp={me.onKeyEvent}
        />
      </div>
    ) : null;
  }
}

NodeInfoPresentationTooltip.defaultProps = {
  bus: PB,
  style: {},
};

NodeInfoPresentationTooltip.propTypes = {
  bus: PropTypes.instanceOf(SimplePB),
  style: PropTypes.object,
  content: PropTypes.string,
  images: PropTypes.array,
};

export default NodeInfoPresentationTooltip;