import {httpUtilWithNoMsg as httpUtil,getToken} from '@/utils/HttpUtil';

export const API_LoadMicroServiceConfigList = ({projectId, enabled, tag, query, start, limit, orderBy, orderType, id}) => {
  return httpUtil.get(`/view/micro_service`, {project_id: projectId, enabled, tag, query, start, limit, order_by: orderBy, order_type: orderType, id});
}

export const API_MyMicroServiceConfigList = ({tag, start, limit}) => {
  return httpUtil.get(`/view/micro_service/actions/my`, {tag, start, limit, orderType: 'desc'});
}

export const API_RecommendMicroServiceConfigList = ({tag, start, limit}) => {
  return httpUtil.get(`/view/micro_service/actions/recommend`, {tag, start, limit});
}

export const API_AddMicroServiceConfig = (config) => {
  return httpUtil.post(`/view/micro_service`, config);
}

export const API_UpdateMicroServiceConfig = (microServiceId, config) => {
  return httpUtil.put(`/view/micro_service/${microServiceId}`, config);
}

export const API_RemoveMicroServiceConfig = (microServiceId) => {
  return httpUtil.delete(`/view/micro_service/${microServiceId}`);
}

export const API_CallMicroService = (microServiceId, viewId, request) => {
  request.authorization = getToken();
  return httpUtil.post(`/view/micro_service/${microServiceId}/actions/call_by_view/${viewId}`, request);
}

export const API_GetMicroServiceListByIds = (ids) => {
  return httpUtil.get(`/view/micro_service/actions/get_by_ids`, {ids: ids});
}