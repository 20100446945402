import { put, call, takeLatest } from 'redux-saga/effects';
import {
  API_SubmitFeedBack,
  API_SubmitPrinting,
} from "@/api/system";
import {
  submitFeedbackSuccessAction,
  submitFeedbackFailedAction,
  submitPrintingSuccessAction,
  submitPrintingFailedAction,
} from "./actions";
import {
  SUBMIT_FEEDBACK,
  SUBMIT_PRINTING,
} from "./actionTypes";

function* submitFeedbackAsync(action) {
  try {
    yield call(API_SubmitFeedBack, action.params);
    yield put(submitFeedbackSuccessAction());
  } catch (error) {
    yield put(submitFeedbackFailedAction());
  }
}

export function* watchSubmitFeedback() {
  console.log('watcher saga is listening to watchSubmitFeedback action');
  yield takeLatest([SUBMIT_FEEDBACK], submitFeedbackAsync);
}

function* submitPrintingAsync(action) {
  try {
    yield call(API_SubmitPrinting, action.params);
    yield put(submitPrintingSuccessAction());
  } catch (error) {
    yield put(submitPrintingFailedAction());
  }
}

export function* watchSubmitPrinting() {
  console.log('watcher saga is listening to watchSubmitFeedback action');
  yield takeLatest([SUBMIT_PRINTING], submitPrintingAsync);
}

