import React from 'react';
import {Icon, Modal, Button, message} from 'antd';
import PropTypes from 'prop-types';
import _ from "lodash";
import loadJs from "loadjs";
import qs from "qs";
import addEventListener from 'add-dom-event-listener';

import {uuidV4} from "@/utils/UUID";
import SysConfig from "@/constants/sys.config";
import weChatCss from "@/constants/weChatCss";
import {bindUtil} from "@/libs/core-decorators";
import {WeChatBindingStatus} from "@/components/common/common.account";
import Account from '@/components/common/common.account';

import style from '@/style/components/accountCenter/account.bindWeChatAccountModal.less';
import {autoSetState, withReactStateHelper} from "@/libs/react-state-helper";
import MergeAccountModal from "@/components/accountCenter/account.mergeAccountModal";

@withReactStateHelper
@bindUtil.asTargetClass
class BindWeChatAccountModal extends React.Component {
  wxLoginHandler = undefined;

  state = {
    weChatBindingPanelId: _.uniqueId('app-random-id-'),
    weChatResourceLoaded: false,
    code: uuidV4(),
    autoMerge: 0,
    showMergeModal: false,
    mergeAccessToken: undefined,
  };

  @autoSetState
  @bindUtil.bindToProperty(Account, 'weChatBindingStatus')
  weChatBindingStatus = {
    status: WeChatBindingStatus.IDLE,
  };

  initWxLogin = () => {
    let me = this,
      fn = () => {
        requestAnimationFrame(() => {
          me.weChatBindingStatus = {
            status: WeChatBindingStatus.IDLE,
          };
          me.setState({weChatResourceLoaded: true}, () => {
            if (me.wxLoginHandler) {
              me.wxLoginHandler.remove();
              delete me.wxLoginHandler;
            }
            me.wxLoginHandler = undefined;
            if (me.props.visible) {
              window['WxLogin']({
                'id': me.state.weChatBindingPanelId, // div的id
                'appid': SysConfig.weChatLogin[SysConfig.weChatLogin.use].appid,
                'redirect_uri': SysConfig.weChatLogin[SysConfig.weChatLogin.use].redirect_uri,
                'scope': 'snsapi_login', // 写死
                'state': encodeURI(btoa(JSON.stringify({action: 'bind', state: me.state.code}))), // 自定义
                'style': 'white', // 二维码黑白风格
                'href': weChatCss,
                'self_redirect': true,
              });
              const iframe = document.getElementById(me.state.weChatBindingPanelId).childNodes[0];
              me.wxLoginHandler = addEventListener(
                iframe, 'load', () => {
                  try {
                    let {code, state} = qs.parse(iframe.contentWindow.location.search, {
                      ignoreQueryPrefix: true,
                      strictNullHandling: true,
                    });
                    try {
                      state = JSON.parse(atob(state)).state;
                    } catch (e) {
                      // ignore
                    }
                    if (state === me.state.code) {
                      Account.bindWeChatAccount(code, me.state.autoMerge).catch(({code, msg, data}) => {
                        if (code === 30011) {
                          // 该账号已被使用
                          if (me.state.autoMerge === 1 && data) {
                            // 开始自动合并
                            me.setState({
                              showMergeModal: true,
                              mergeAccessToken: data['accessToken'],
                            });
                          } else {
                            // 提示是否自动合并
                            Modal.confirm({
                              title: "绑定出错",
                              content: '该微信账号已登录过本网站，是否确认将该账号下的数据合并至本账号？',
                              okText: '重新扫码并合并账号',
                              cancelText: '取消',
                              onOk: () => {
                                me.setState({autoMerge: 1}, () => {
                                  me.initWxLogin();
                                })
                              },
                              onCancel: () => {
                                me.initWxLogin();
                              },
                            });
                          }
                        } else {
                          message.error(msg);
                          me.initWxLogin();
                        }
                      });
                    } else {
                      me.initWxLogin();
                    }
                  } catch (e) {
                    // ignore
                  }
                });
            }
          });
        });
      };
    (typeof window['WxLogin'] !== "undefined") ? fn() :
      loadJs(window.location.protocol + '//res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js', fn);
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.visible === false && this.props.visible) {
      this.initWxLogin();
    }
  }

  render() {
    let panelContent, me = this;
    switch (me.weChatBindingStatus.status) {
      case WeChatBindingStatus.FAILED:
      case WeChatBindingStatus.PROCESSING:
        panelContent = <span><Icon type="loading"/><br/><br/>处理中...</span>;
        break;
      case WeChatBindingStatus.SUCCESS:
        panelContent = <span><Icon type="check"/><br/><br/>操作成功</span>;
        break;
      default:
        // LOADING
        panelContent = <span><Icon type="loading"/><br/><br/>资源加载中...</span>;
    }

    // noinspection RequiredAttributes
    return (
      <Modal
        // width={348}
        centered={true}
        closable={me.weChatBindingStatus.status !== WeChatBindingStatus.PROCESSING}
        visible={me.props.visible}
        title={'绑定微信'}
        footer={[
          <Button key="close" onClick={me.props.onCancel}>关闭</Button>,
        ]}
        destroyOnClose={true}
        onCancel={me.props.onCancel}
      >
        <div id={me.state.weChatBindingPanelId}
             className={style['third-party-login-wechat-frame']}
             style={{
               height: (me.weChatBindingStatus.status === WeChatBindingStatus.IDLE && me.state.weChatResourceLoaded) ?
                 'unset' : 0,
             }}>
        </div>
        <div className={style['third-party-login-wechat-frame']}
             style={{
               height: (me.weChatBindingStatus.status !== WeChatBindingStatus.IDLE || !me.state.weChatResourceLoaded) ?
                 'unset' : 0,
             }}>
          {panelContent}
        </div>
        <MergeAccountModal
          visible={me.state.showMergeModal}
          onClose={() => me.setState({showMergeModal: false, mergeAccessToken: undefined})}
          accessToken={me.state.mergeAccessToken}
        />
      </Modal>
    );
  }
}

BindWeChatAccountModal.propType = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
};

export default BindWeChatAccountModal;