import tinycolor from 'tinycolor2' // 颜色处理库
import {DataSet} from '@/libs/vis/index'
import {IconTypes} from "@/constants/common";
import {
    TYPE_FIELD_NAME,
    NODE_TYPE_NODE_TIP,
    NODE_TYPE_TEXT, // 关注点，(原来的tag,用户输入，新建节点）
    NODE_TYPE_COMPANY, // 企业
    NODE_TYPE_TALENT, // 人物
    NODE_TYPE_PATENT, // #废弃# 专利
    NODE_TYPE_PAPER, // #废弃# 论文
    NODE_TYPE_POLICY, // 政策
    NODE_TYPE_ORG, // 协会 原：社团协会
    NODE_TYPE_INSTITUTE, // 院所 原：科研院所
    NODE_TYPE_NEWS_ACTIVITIES, // #废弃# 新闻与活动
    NODE_TYPE_TAG, // 线索 即：后台计算出的标签
    NODE_TYPE_DOCS,  // #废弃# 用户文档 = 用户文档
    NODE_TYPE_DATASET,  // 数据 原：数据集
    NODE_TYPE_GOV, // 政府
    NODE_TYPE_NATURE, // 自然
    NODE_TYPE_INDEX, // #废弃# 指标
    NODE_TYPE_GRAPH, // #废弃# 图表
    NODE_TYPE_COLLEGE_AND_UNIVERSITY, // 高校
    NODE_TYPE_PARK, // 园区
    NODE_TYPE_TECHNOLOGY, // 技术
    NODE_TYPE_TEXT_GOAL, // 目标
    NODE_TYPE_PROJECT, // 项目
} from "@/constants/nodeConfig";
import Node from '@/libs/view/Node';
import Edge from '@/libs/view/Edge';

// 节点、边状态
export const STATUS_GREW = 0; // 未保存节点、边

// 节点类型
export * from "@/constants/nodeConfig";

// 边的类型
export const EDGE_TYPE_NONE = 0; // 没有关系
export const EDGE_TYPE_OUTLINE = 1; // 主干，用户输入节点之间的连线
export const EDGE_TYPE_PEOPLE = 2; // 人脉 关系

/*
{ label: '企业', value: '1' },-
    { label: '人物', value: '2' },-
    { label: '技术', value: '3' },
    { label: '文章', value: '4' },-
    { label: '政策', value: '5' },
    { label: '协会', value: '6' },-
    { label: '科研', value: '7' },-
    { label: '活动', value: '8' }, 合并到4
    { label: '文档', value: '10' },
    { label: '数据集', value: '11' },

 */

const brighten = (color, amount = 1.1) => {
    let rgb = tinycolor(color).toRgb();
    rgb.r = Math.max(0, Math.min(255, Math.round(rgb.r * amount)));
    rgb.g = Math.max(0, Math.min(255, Math.round(rgb.g * amount)));
    rgb.b = Math.max(0, Math.min(255, Math.round(rgb.b * amount)));
    return tinycolor(rgb);
};

/**
 * 默认颜色设置
 * @type {{colors: {default: string, defaultColor: string, highlightNodeBorder: string, lastSelectedNodeBorder: string, highlightEdge: string, shadow: string, level3: string, level3Color: string, level2: string, level2Color: string, level1: string, level1Color: string, level0: string, level0Color: string}, echo: defaultDefine.echo}}
 */
export const defaultDefine = {
    // 全部使用rgb格式,便于计算rgba的值
    'colors': {
        // 基本颜色
        'default': '#9b9b9b', // 基本颜色 默认画布颜色
        'defaultColor': 'rgb(206,206,206)', // 基本字体颜色 ，默认背景色为画布颜色 #cecece 补色 #313131
        'defaultPrimary': 'rgb(24,144,255)', // 主色 蓝色
        'defaultSelected': 'rgb(233,37,69)', // 默认选中主色 浅红 #e92545
        'defaultBlack': 'rgb(0,0,0)', // 黑色
        'defaultWhite': 'rgb(255,255,255)', // 白色

        'defaultRed': 'rgb(255,0,0)', // 红色
        'defaultYellow': 'rgb(255,255,0)', // 黄色
        'defaultBlue': 'rgb(0,0,255)', // 蓝色

        // ----- node -------
        // 默认 字体颜色
        'nodeBorder': 'rgb(194,194,194)', // 节点默认的边框颜色 灰白 #C2C2C2
        // 选中状态 颜色,用于：高亮、多点连线
        'selectedNode': '#e92545', // 节点高亮的边框颜色 浅红  rgb(233,37,69)
        // 节点高亮的边框颜色
        // 'highlightNodeBorder': '#e92545', // 节点高亮的边框颜色 浅红  rgb(233,37,69)
        'lastSelectedNodeBorder': 'rgb(255,0,0)', // 前一个选中节点高亮的边框颜色 红色

        // ----- edge -------
        // 默认 字体颜色
        // 'edgeColor': '#ffffff', // rgb(225,225,225)
        // 'edgeColor': 'rgb(112,128,144)', // rgb(112,128,144)
        'edgeColor': '#cecece', // rgb(206,206,206)
        // 默认边的色
        'defaultEdge': '#9eaab6', // rgb(158,170,182)
        // 悬浮状态 颜色,用于：高亮、多点连线,
        'hoverEdge': '#0094ff', // rgb(0, 148, 255)
        // 选中状态 颜色,用于：高亮、多点连线
        'selectedEdge': '#0094ff', // 亮蓝 rgb(0, 148, 255)
        // 'selectedEdge': '#9eaab6', // 亮蓝 rgb(0, 148, 255)
        // 自定义边高亮色,含义：其他的边变暗，指定的节点高亮；用于：高亮、多点连线
        'highlightEdge': '#9eaab6', // 亮蓝 rgb(0, 148, 255)
        // 'highlightEdge': 'rgb(91,127,227)', // 亮蓝
        // 'highlightEdge': 'rgb(83,102,236)', // 原方案
        // 'highlightEdge': 'rgb(195, 176, 145)', // 卡其色

        // 画面的基本颜色设定
        'shadow': 'white', // 阴影的颜色

        'componentBackground': '#29323d',

        // @1101 设置画面出现的4中主要颜色
        /*
                'level3': 'rgb(255,255,107)', // D 黄色 #ffff6b
                'level3Color': 'rgb(0,0,0)', // D 字体 黑色

                'level2': 'rgb(32,178,170)', // C 绿色 #20b2aa
                'level2Color': 'rgb(255,255,255)', // C 字体 白色

                'level1': 'rgb(178,178,178)', // B 灰色 #b2b2b2
                'level1Color': 'rgb(77,77,77)', // B 字体 灰黑色

                'level0': 'rgb(233,37,69)', // A  浅红 #e92545
                'level0Color': 'rgb(245,245,245)', // A 字体 灰白色
            */

        // @190316 设置画面出现的4中主要颜色
        'level3': 'rgb(255,255,107)', // D 黄色 #ffff6b
        'level3Color': 'rgb(0,0,0)', // D 字体 黑色

        'level2': 'rgb(32,178,170)', // C 绿色 #20b2aa
        'level2Color': 'rgb(255,255,255)', // C 字体 白色

        'level1RGBA': {
            r: 224,
            g: 224,
            b: 224,
            a: 1,
        },
        'level1': 'rgb(224,224,224)', // B 灰色 #e0e0e0
        'level1Color': 'rgb(77,77,77)', // B 字体 灰黑色

        'level0': 'rgb(233,37,69)', // A  浅红 #e92545
        'level0Color': 'rgb(245,245,245)', // A 字体 灰白色
        'multipleFont': [
            '#9c9c9c',
            '#1F252E',
            '#FFFFFF',
            '#FFFFFF',
            '#FFFFFF',
            '#1F252E',
            '#FFFFFF',
            '#FFFFFF',
        ],
        'multipleFontHover': [
            tinycolor('#9c9c9c').lighten(15).toString(),
            tinycolor('#1F252E').lighten(5).toString(),
            tinycolor('#FFFFFF').lighten().toString(),
            tinycolor('#FFFFFF').lighten().toString(),
            tinycolor('#FFFFFF').lighten().toString(),
            tinycolor('#1F252E').lighten(5).toString(),
            tinycolor('#FFFFFF').lighten().toString(),
            tinycolor('#FFFFFF').lighten().toString(),
        ],
        'multipleBackground': [
            '#1F252E',
            '#E8E8E8',
            '#5189BD',
            '#00B0F0',
            '#70AD47',
            '#FFCC40',
            '#ED7D31',
            '#C21F1F',
        ],
        'multipleBackgroundHover': [
            tinycolor('#1F252E').lighten().toString(),
            tinycolor('#E8E8E8').lighten().toString(),
            tinycolor('#5189BD').lighten().toString(),
            tinycolor('#00B0F0').lighten().toString(),
            tinycolor('#70AD47').lighten().toString(),
            tinycolor('#FFCC40').lighten().toString(),
            tinycolor('#ED7D31').lighten().toString(),
            tinycolor('#C21F1F').lighten().toString(),
        ],
        'multipleBorder': [
            tinycolor('#1F252E').lighten(5).toString(),
            tinycolor('#E8E8E8').darken().toString(),
            tinycolor('#5189BD').darken().toString(),
            tinycolor('#00B0F0').darken().toString(),
            tinycolor('#70AD47').darken().toString(),
            tinycolor('#FFCC40').darken().toString(),
            tinycolor('#ED7D31').darken().toString(),
            tinycolor('#C21F1F').darken().toString(),
        ],
        'multipleBorderHover': [
            tinycolor('#1F252E').lighten(5).lighten().toString(),
            tinycolor('#E8E8E8').darken().lighten().toString(),
            tinycolor('#5189BD').darken().lighten().toString(),
            tinycolor('#00B0F0').darken().lighten().toString(),
            tinycolor('#70AD47').darken().lighten().toString(),
            tinycolor('#FFCC40').darken().lighten().toString(),
            tinycolor('#ED7D31').darken().lighten().toString(),
            tinycolor('#C21F1F').darken().lighten().toString(),
        ],
    },
    // vis 的设置
    'vis': {
        'defaultDashes': false, // 联想节点的虚线间隔
        'grewDashes': [2, 3], // 联想节点的虚线间隔
    },
    // 计算隐藏时的颜色值
    'darken': (color, int) => {
        return parseInt(int) > 0 ? tinycolor(color).darken(int).toString() : tinycolor(color).darken().toString()
    },
    // 计算隐藏时的颜色值
    'lighten': (color, int) => {
        return parseInt(int) > 0 ? tinycolor(color).lighten(int).toString() : tinycolor(color).lighten().toString()
    },
    // 计算隐藏时的颜色值
    'brighten': (color, percent) => {
        return isNaN(percent) ? brighten(color).toString() : brighten(color, percent).toString()
    },
};

const getTipNodeConfigFn = {
    normal: idx => ({
        'shape': 'box',
        'widthConstraint': {maximum: 300},
        'heightConstraint': {maxLines: 8},
        'borderWidth': 1,
        'borderWidthSelected': 1,
        'labelHighlightBold': false,
        'margin': {
            'top': 3,
            'left': 5,
            'right': 5,
            'bottom': 5,
        },
        'font': {
            'size': 13, // 默认字体大小
            'color': defaultDefine.colors.multipleFont[idx],
            'background': 'transparent', // 字体背景透明
            'strokeWidth': 0,
            'align': 'left',
            'multi': true,
            'lineHeight': 19,
            'vadjust': 3,
        },
        'color': {
            'border': defaultDefine.colors.multipleBorder[idx],
            'background': defaultDefine.colors.multipleBackground[idx],
            'highlight': {
                'border': defaultDefine.colors.multipleBorderHover[idx],
                'background': defaultDefine.colors.multipleBackgroundHover[idx],
            },
            'hover': {
                'border': defaultDefine.colors.multipleBorderHover[idx],
                'background': defaultDefine.colors.multipleBackgroundHover[idx],
            },
        },
    }),
    // * 当前选中节点的节点样式,没有使用
    current: idx => ({
        'font': {
            'size': 13,
            'color': defaultDefine.colors.multipleFontHover[idx],
            'background': 'transparent', // 字体背景透明
            'lineHeight': 19,
            'vadjust': 3,
        },
        'color': {
            'border': defaultDefine.colors.multipleBorderHover[idx],
            'background': defaultDefine.colors.multipleBackgroundHover[idx],
        },
    }),
    // * 悬浮状态
    hover: idx => ({
        'shadow': {
            'enabled': true,
            'color': defaultDefine.colors.multipleBorderHover[idx],
            'size': 25,
            'x': 0,
            'y': 0,
        },
    }),
    // * 聚焦状态
    focus: idx => ({
        'shadow': {
            'enabled': true,
            'color': defaultDefine.colors.multipleBorderHover[idx],
            'size': 25,
            'x': 0,
            'y': 0,
        },
        'font': {
            'color': defaultDefine.lighten(defaultDefine.colors.defaultBlack, 20),
            'background': 'transparent', // 字体背景透明
            'lineHeight': 19,
            'vadjust': 3,
        },
        'color': {
            'background': defaultDefine.colors.defaultWhite,
        },
    }),
};

const getTipNodeConfig = (tipLev, idx) => ({
    [tipLev]: getTipNodeConfigFn.normal(idx),
    [`currentSelect-${tipLev}`]: getTipNodeConfigFn.current(idx),
    [`hover-${tipLev}`]: getTipNodeConfigFn.hover(idx),
    [`focus-${tipLev}`]: getTipNodeConfigFn.focus(idx),
});

const getTipNodeConfigObject = () => ({
    ...getTipNodeConfig('tipA', 0),
    ...getTipNodeConfig('tipB', 1),
    ...getTipNodeConfig('tipC', 2),
    ...getTipNodeConfig('tipD', 3),
    ...getTipNodeConfig('tipE', 4),
    ...getTipNodeConfig('tipF', 5),
    ...getTipNodeConfig('tipG', 6),
    ...getTipNodeConfig('tipH', 7),
});

const getTagNodeConfigObject = () => ({
    ...getTipNodeConfig('tagA', 0),
    ...getTipNodeConfig('tagB', 1),
    ...getTipNodeConfig('tagC', 2),
    ...getTipNodeConfig('tagD', 3),
    ...getTipNodeConfig('tagE', 4),
    ...getTipNodeConfig('tagF', 5),
    ...getTipNodeConfig('tagG', 6),
    ...getTipNodeConfig('tagH', 7),
});

/**
 * 各类节点的标题、统计文字、icon 、基本色配置：
 * title: 节点名称
 * shape:形状，用于vis的元素形状
 * icon：针对antd icon ，可用于列表时的头像之类 ,判断顺序，icon>icontFont>fa
 * iconFont：针对iconFont图表，优先级高于fa ，用在antd的avatar组件和tab组件
 * fa：针对font-awesome  vis用
 * faCode：与fa对应的font-awesome图标的图标编码 vis 用
 * label:节点的前置文字标签（不和值连在一起），用于技术统计
 * prefix：前缀文字
 * postfix：后缀文字，类似于单位
 * items: 节点的各种类型，用于legend，default为默认值
 * @type {{"0": {title: string, icon: string, label: string, postfix: string, items: {default: {title: string, color: string, shape: string}, level0: {title: string, color: string, shape: string}, level1: {title: string, color: string, shape: string}}}, "2": {title: string, icon: string, label: string, postfix: string, items: {default: {title: string, color: string, shape: string, icon: string}}}, "1": {title: string, icon: string, label: string, postfix: string, items: {listed20: {title: string, color: string, shape: string}, listed10: {title: string, color: string, shape: string}, listed0: {title: string, color: string, shape: string}, default: {title: string, color: string, shape: string}}}, word: {title: string, icon: string, label: string, postfix: string, items: {default: {title: string, color: string, shape: string}}}, edge: {title: string, icon: string, label: string, postfix: string, items: {default: {title: string, color: string, shape: string}, grew: {title: string, color: string, shape: string}}}}}
 */
const nodesConfig = {
    [NODE_TYPE_NODE_TIP]: {
        title: '统计信息',
        label: '统计信息',
        postfix: '个',
        timelineFieldName: 'startDate', // 时间排序字段名
        // icon: "hdd", // antd icon
        'iconFont': 'icon--rss',
        'fa': 'rss-square', // fontawason的图标
        'faCode': '\uf143', // fontawason的图标编码
        items: {
            default: {
                'title': '统计信息',
                'color': defaultDefine.colors.listed0,
                // 'shape': 'round',
                // icon: "hdd", // antd icon
                'iconFont': 'icon--rss',
                'fa': 'rss-square', // fontawason的图标
                'faCode': '\uf143', // fontawason的图标编码
            },
        },
    },
    [NODE_TYPE_TEXT]: {
        title: '关注',
        fullTitle: '关注点',
        label: '关注点',
        postfix: '个',
        timelineFieldName: 'startDate', // 时间排序字段名
        // icon: "tag", // antd 的图标
        'iconFont': 'icon-yuandianda', // iconfont的图标
        'fa': 'comment', // fontawason的图标
        'faCode': '\uf075', // fontawason的图标
        items: {
            'default': {
                'title': '关注点',
                'color': defaultDefine.colors.default,
                // 'shape': 'rectangle',
                'iconFont': 'icon-yuandianda', // iconfont的图标
                'fa': 'comment', // fontawason的图标
                'faCode': '\uf075', // fontawason的图标
            },
            /*'root': {
                'title': '中心节点',
                'color': defaultDefine.colors.level1,
                // 'shape': 'rectangle',
                // 'iconFont': 'icon-yuandianda', // iconfont的图标
                // 'fa': 'comment', // fontawason的图标
                // 'faCode': '\uf075', // fontawason的图标
            },*/
            'gradeA': {
                'title': '关注点',
                'color': defaultDefine.colors.level3,
                'iconFont': 'icon-flag1', // iconfont的图标
                'fa': 'flag', // fontawason的图标
                'faCode': '\uf024', // fontawason的图标
            },
            'gradeB': {
                'title': '关注点',
                'color': defaultDefine.colors.level2,
                // 'shape': 'rectangle',
                'iconFont': 'icon-flag1', // iconfont的图标
                'fa': 'flag', // fontawason的图标
                'faCode': '\uf024', // fontawason的图标
            },
            'gradeC': {
                'title': '关注点',
                'color': defaultDefine.colors.level0,
                // 'shape': 'rectangle',
                'iconFont': 'icon-flag1', // iconfont的图标
                'fa': 'flag', // fontawason的图标
                'faCode': '\uf024', // fontawason的图标
            },
            'gradeDA': {
                'color': defaultDefine.colors.level3,
            },
            'gradeDB': {
                'color': defaultDefine.colors.level2,
            },
            'gradeDC': {
                'color': defaultDefine.colors.level0,
            },
            'gradeT': {
                'title': '树干节点',
                'color': defaultDefine.colors.level1,
                // 'shape': 'rectangle',
                'iconFont': 'icon-flag1', // iconfont的图标
                'fa': 'flag', // fontawason的图标
                'faCode': '\uf024', // fontawason的图标
            },
            'gradeU': {
                'title': '树干节点',
                'color': defaultDefine.colors.level1,
                // 'shape': 'rectangle',
                'iconFont': 'icon-flag1', // iconfont的图标
                'fa': 'flag', // fontawason的图标
                'faCode': '\uf024', // fontawason的图标
            },
            // 190715 新增状态值
            'gradeE': {
                'title': '标记4',
                'color': defaultDefine.colors.level3,
                'image': '/assets/flag/gradeD.svg',
                'iconFont': 'icon-flag2', // iconfont的图标
            },
            'gradeF': {
                'title': '标记5',
                'color': defaultDefine.colors.level2,
                'image': '/assets/flag/gradeE.svg',
                'iconFont': 'icon-flag2', // iconfont的图标
            },
            'gradeG': {
                'title': '标记6',
                'color': defaultDefine.colors.level0,
                'image': '/assets/flag/gradeF.svg',
                'iconFont': 'icon-flag2', // iconfont的图标
            },
            // 190704 新增状态值
            'prepare': {
                'title': '准备节点',
                'color': defaultDefine.colors.level1,
                'image': '/assets/flag/prepare.svg',
            },
            'doing': {
                'title': '进行节点',
                'color': defaultDefine.colors.level1,
                'image': '/assets/flag/doing.svg',
            },
            'finish': {
                'title': '完成节点',
                'color': defaultDefine.colors.level1,
                'image': '/assets/flag/finish.svg',
            },
            // 191119 新增状态值
            'good': {
                'title': '准备节点',
                'color': defaultDefine.colors.level1,
                'image': '/assets/flag/good.svg',
            },
            'bad': {
                'title': '进行节点',
                'color': defaultDefine.colors.level1,
                'image': '/assets/flag/bad.svg',
            },
            'watch': {
                'title': '完成节点',
                'color': defaultDefine.colors.level1,
                'image': '/assets/flag/watch.svg',
            },
            'noIcon': {
                'title': '无图标节点',
                'color': defaultDefine.colors.level1,
                'image': '/assets/flag/noIcon.svg',
            },
            // 20200824 标签式节点
            'tipA': {
                'title': '便签A',
                'color': defaultDefine.colors.multipleBackground[0],
                'iconFont': 'icon-tip-solid',
            },
            'tipB': {
                'title': '便签A',
                'color': defaultDefine.colors.multipleBackground[1],
                'iconFont': 'icon-tip-solid',
            },
            'tipC': {
                'title': '便签A',
                'color': defaultDefine.colors.multipleBackground[2],
                'iconFont': 'icon-tip-solid',
            },
            'tipD': {
                'title': '便签A',
                'color': defaultDefine.colors.multipleBackground[3],
                'iconFont': 'icon-tip-solid',
            },
            'tipE': {
                'title': '便签A',
                'color': defaultDefine.colors.multipleBackground[4],
                'iconFont': 'icon-tip-solid',
            },
            'tipF': {
                'title': '便签A',
                'color': defaultDefine.colors.multipleBackground[5],
                'iconFont': 'icon-tip-solid',
            },
            'tipG': {
                'title': '便签A',
                'color': defaultDefine.colors.multipleBackground[6],
                'iconFont': 'icon-tip-solid',
            },
            'tipH': {
                'title': '便签A',
                'color': defaultDefine.colors.multipleBackground[7],
                'iconFont': 'icon-tip-solid',
            },
            //
            'system-recommend': {
                'title': '系统推荐',
                'color': defaultDefine.colors.level1,
                'image': '/assets/flag/dashed-circle.svg',
            },
        },
    },
    [NODE_TYPE_COMPANY]: {
        title: '企业',
        title4: '企业组织',
        label: '企业数',
        postfix: '家',
        timelineFieldName: 'startDate', // 时间排序字段名
        // icon: "shop", // antd icon
        'iconFont': 'icon-company',
        'fa': 'circle', // fontawason的图标
        'faCode': '\uf111', // fontawason的图标编码
        items: {
            'default': {
                'title': '未知',
                'color': defaultDefine.colors.level1,
                // 'shape': 'round',
                'iconFont': 'icon-company',
                'fa': 'circle', // fontawason的图标
                'faCode': '\uf111', // fontawason的图标编码
                'image': '/assets/icon/company-default.svg',
            },
            listed0: {
                'title': '未上市',
                'color': defaultDefine.colors.level1,
                // 'shape': 'round',
                'iconFont': 'icon-company',
                'fa': 'circle', // fontawason的图标
                'faCode': '\uf111', // fontawason的图标编码
                'image': '/assets/icon/company-listed0.svg',
            },
            listed20: {
                'title': '主板上市',
                'color': defaultDefine.colors.level3,
                // 'shape': 'round',
                'iconFont': 'icon-company',
                'fa': 'circle', // fontawason的图标
                'faCode': '\uf111', // fontawason的图标编码
                'image': '/assets/icon/company-listed20.svg',
            },
            listed10: {
                'title': '新三板',
                'color': defaultDefine.colors.level2,
                // 'shape': 'round',
                'iconFont': 'icon-company',
                'fa': 'circle', // fontawason的图标
                'faCode': '\uf111', // fontawason的图标编码
                'image': '/assets/icon/company-listed10.svg',
            },
        },
    },
    [NODE_TYPE_TALENT]: {
        title: '人物',
        title4: '其他人物',
        label: '人物数',
        // postfix: '人',
        postfix: '位',
        timelineFieldName: 'startDate', // 时间排序字段名
        // icon: "user", // antd icon
        'iconFont': 'icon-talent',
        'fa': 'user', // fontawason的图标
        'faCode': '\uf007', // fontawason的图标编码
        items: {
            'default': {
                'title': '人物',
                // 'color': defaultDefine.colors.listed30,
                'color': defaultDefine.colors.level1,
                // 'shape': 'triangle',
                // 'shape': 'icon',
                // "icon": "user", // antd 图表
                'iconFont': 'icon-talent', // iconfont的图标
                'fa': 'user', // fontawason的图标
                'faCode': '\uf007', // fontawason的图标编码
                'image': '/assets/icon/talent-default.svg',
            },
        },
    },
    [NODE_TYPE_PATENT]: {
        title: '专利',
        title4: '科技专利',
        label: '项',
        postfix: '份',
        timelineFieldName: 'startDate', // 时间排序字段名
        // icon: "hdd", // antd icon
        'iconFont': 'icon-zhuanli',
        // 'fa': 'paperclip', // fontawason的图标
        // 'faCode': '\uf0c6', // fontawason的图标编码
        items: {
            default: {
                'title': '专利',
                'color': defaultDefine.colors.listed0,
                // 'shape': 'round',
                // icon: "hdd", // antd icon
                'iconFont': 'icon-zhuanli',
                // 'fa': 'paperclip', // fontawason的图标
                // 'faCode': '\uf0c6', // fontawason的图标编码
                'image': '/assets/icon/patent3.svg',
            },
        },
    },
    [NODE_TYPE_PAPER]: {
        title: '论文',
        title4: '论文专著',
        label: '论文数',
        postfix: '篇',
        timelineFieldName: 'startDate', // 时间排序字段名
        // icon: "file-text", // antd icon
        'iconFont': 'icon-book',
        'fa': 'book', // fontawason的图标
        'faCode': '\uf02d', // fontawason的图标编码
        items: {
            'default': {
                'title': '论文',
                'color': defaultDefine.colors.level1,
                // 'shape': 'round',
                'iconFont': 'icon-book',
                'fa': 'book', // fontawason的图标
                'faCode': '\uf02d', // fontawason的图标编码
            },

        },
    },
    [NODE_TYPE_POLICY]: {
        title: '政策',
        title4: '政情政策',
        label: '政策数',
        postfix: '份',
        timelineFieldName: 'startDate', // 时间排序字段名
        // icon: "hdd", // antd icon
        'iconFont': 'icon-policy1',
        'fa': 'file-text-o', // fontawason的图标
        'faCode': '\uf0f6', // fontawason的图标编码
        items: {
            default: {
                'title': '政策',
                'color': defaultDefine.colors.listed0,
                'shape': 'round',
                // 'iconFont': 'icon-file1',
                'iconFont': 'icon-policy1',
                'fa': 'file-text-o', // fontawason的图标
                'faCode': '\uf0f6', // fontawason的图标编码
                'image': '/assets/icon/policy-default.svg',
            },
        },
    },
    [NODE_TYPE_ORG]: {
        title: '协会',
        title4: '协会社团',
        label: '协会',
        postfix: '个',
        timelineFieldName: 'startDate', // 时间排序字段名
        // icon: "hdd", // antd icon
        'iconFont': 'icon-org',
        'fa': 'group', // fontawason的图标
        'faCode': '\uf0c0', // fontawason的图标编码
        items: {
            default: {
                'title': '协会',
                'color': defaultDefine.colors.listed0,
                // 'shape': 'round',
                // icon: "hdd", // antd icon
                'iconFont': 'icon-org',
                'fa': 'group', // fontawason的图标
                'faCode': '\uf0c0', // fontawason的图标编码
                'image': '/assets/icon/org-default.svg',
            },
        },
    },
    [NODE_TYPE_INSTITUTE]: {
        title: '院所',
        title4: '科研院所',
        label: '院所',
        postfix: '个',
        timelineFieldName: 'startDate', // 时间排序字段名
        // icon: "hdd", // antd icon
        'iconFont': 'icon-institute',
        'fa': 'fa-graduation-cap', // fontawason的图标
        'faCode': '\uf19d', // fontawason的图标编码
        items: {
            default: {
                'title': '院所',
                'color': defaultDefine.colors.listed0,
                // 'shape': 'round',
                'iconFont': 'icon-institute',
                'fa': 'graduation-cap', // fontawason的图标
                'faCode': '\uf19d', // fontawason的图标编码
                'image': '/assets/icon/institute-default.svg',
            },
        },
    },
    [NODE_TYPE_NEWS_ACTIVITIES]: {
        title: '新闻',
        label: '新闻',
        postfix: '条',
        timelineFieldName: 'startDate', // 时间排序字段名
        // icon: "hdd", // antd icon
        'iconFont': 'icon--rss',
        'fa': 'rss-square', // fontawason的图标
        'faCode': '\uf143', // fontawason的图标编码
        items: {
            default: {
                'title': '新闻',
                'color': defaultDefine.colors.listed0,
                // 'shape': 'round',
                // icon: "hdd", // antd icon
                'iconFont': 'icon--rss',
                'fa': 'rss-square', // fontawason的图标
                'faCode': '\uf143', // fontawason的图标编码
            },
        },
    },
    [NODE_TYPE_TAG]: {
        title: '线索',
        label: '线索数',
        postfix: '个',
        timelineFieldName: 'startDate', // 时间排序字段名
        // icon: "tag", // antd icon
        'iconFont': 'icon-bubble',
        'fa': 'comment', // fontawason的图标
        'faCode': '\uf075', // fontawason的图标
        items: {
            default: {
                'title': '线索',
                'color': defaultDefine.colors.listed0,
                // 'shape': 'round',
                // icon: "tag", // antd icon
                'iconFont': 'icon-bubble',
                'fa': 'comment', // fontawason的图标
                'faCode': '\uf075', // fontawason的图标
            },
        },
    },
    [NODE_TYPE_DOCS]: {
        title: '文档',
        label: '文档数',
        postfix: '份',
        timelineFieldName: 'startDate', // 时间排序字段名
        // icon: "file", // antd icon
        'iconFont': 'icon-files-o',
        'fa': 'files-o', // fontawason的图标
        'faCode': '\uf0c5', // fontawason的图标编码
        items: {
            default: {
                'title': '用户文档',
                'color': defaultDefine.colors.listed0,
                // 'shape': 'round',
                'iconFont': 'icon-files-o',
                'fa': 'files-o', // fontawason的图标
                'faCode': '\uf0c5', // fontawason的图标编码
            },

        },
    },
    [NODE_TYPE_DATASET]: {
        title: '数据',
        label: '数据',
        postfix: '份',
        timelineFieldName: 'startDate', // 时间排序字段名
        // icon: "hdd", // antd icon
        'iconFont': 'icon-database',
        'fa': 'database', // fontawason的图标
        'faCode': '\uf1c0', // fontawason的图标编码
        items: {
            default: {
                'title': '数据',
                'color': defaultDefine.colors.listed0,
                // 'shape': 'round',
                'iconFont': 'icon-database',
                'fa': 'database', // fontawason的图标
                'faCode': '\uf1c0', // fontawason的图标编码
            },
        },
    },
    [NODE_TYPE_GOV]: {
        title: '政府',
        title4: '政情政策',
        label: '政府',
        postfix: '条',
        timelineFieldName: null, // 时间排序字段名
        // icon: "bubble", // antd icon
        /*
                'iconFont': 'icon-policy',
                'fa': 'user', // fontawason的图标
                'faCode': '\uf007', // fontawason的图标编码
        */

        'iconFont': 'icon-gov',
        'fa': 'university', // fontawason的图标
        'faCode': '\uf19c', // fontawason的图标编码

        items: {
            'default': {
                'title': '政府',
                'color': defaultDefine.colors.level1,
                // 'shape': 'triangle',
                // 'shape': 'icon',
                // "icon": "bubble", // antd 图表
                'iconFont': 'icon-gov', // iconfont的图标
                'fa': 'university', // fontawason的图标
                'faCode': '\uf19c', // fontawason的图标编码
                'image': '/assets/icon/gov-default.svg',
            },
        },
    },
    [NODE_TYPE_NATURE]: {
        title: '自然',
        fullTitle: '自然资源',
        label: '自然资源',
        postfix: '个',
        timelineFieldName: null, // 时间排序字段名
        // icon: "bubble", // antd icon
        // 'iconFont': 'icon-nature',
        'fa': 'tree', // fontawason的图标
        'faCode': '\uf1bb', // fontawason的图标编码
        items: {
            'default': {
                'title': '自然',
                // 'color': defaultDefine.colors.listed30,
                'color': defaultDefine.colors.default,
                // 'shape': 'triangle',
                // 'shape': 'icon',
                // "icon": "bubble", // antd 图表
                // 'iconFont': 'icon-nature', // iconfont的图标
                'iconFont': 'icon-natural-resources', // iconfont的图标
                'fa': 'tree', // fontawason的图标
                'faCode': '\uf1bb', // fontawason的图标编码
                'image': '/assets/icon/natural-resources-default.svg',
            },
        },
    },
    [NODE_TYPE_INDEX]: {
        title: '指标',
        label: '指标数量',
        postfix: '条',
        timelineFieldName: null, // 时间排序字段名
        // icon: "bubble", // antd icon
        'iconFont': 'icon-index',
        'fa': 'user', // fontawason的图标
        'faCode': '\uf007', // fontawason的图标编码
        items: {
            'default': {
                'title': '指数',
                // 'color': defaultDefine.colors.listed30,
                'color': defaultDefine.colors.default,
                // 'shape': 'triangle',
                // 'shape': 'icon',
                // "icon": "bubble", // antd 图表
                'iconFont': 'icon-index', // iconfont的图标
                'fa': 'user', // fontawason的图标
                'faCode': '\uf007', // fontawason的图标编码
            },
        },
    },
    [NODE_TYPE_GRAPH]: {
        title: '图表',
        label: '图表数',
        postfix: '个',
        timelineFieldName: null, // 时间排序字段名
        // icon: "bubble", // antd icon
        'iconFont': 'icon-graph',
        'fa': 'user', // fontawason的图标
        'faCode': '\uf007', // fontawason的图标编码
        items: {
            'default': {
                'title': '图表',
                // 'color': defaultDefine.colors.listed30,
                'color': defaultDefine.colors.default,
                // 'shape': 'triangle',
                // 'shape': 'icon',
                // "icon": "bubble", // antd 图表
                'iconFont': 'icon-graph', // iconfont的图标
                'fa': 'user', // fontawason的图标
                'faCode': '\uf007', // fontawason的图标编码
            },
        },
    },
    [NODE_TYPE_COLLEGE_AND_UNIVERSITY]: {
        title: '高校',
        title4: '高等院校',
        label: '高校数量',
        postfix: '所',
        timelineFieldName: 'startDate', // 时间排序字段名
        // icon: "hdd", // antd icon
        'iconFont': 'icon-university',
        'fa': 'fa-graduation-cap', // fontawason的图标
        'faCode': '\uf19d', // fontawason的图标编码
        items: {
            default: {
                'title': '高校',
                'color': defaultDefine.colors.listed0,
                // 'shape': 'round',
                'iconFont': 'icon-university',
                'fa': 'graduation-cap', // fontawason的图标
                'faCode': '\uf19d', // fontawason的图标编码
                'image': '/assets/icon/university-default.svg',
            },
        },
    },
    [NODE_TYPE_PARK]: {
        title: '园区',
        label: '园区数',
        postfix: '座',
        timelineFieldName: 'startDate', // 时间排序字段名
        // icon: "hdd", // antd icon
        'iconFont': 'icon-park',
        'fa': 'fa-graduation-cap', // fontawason的图标
        'faCode': '\uf19d', // fontawason的图标编码
        items: {
            default: {
                'title': '园区',
                'color': defaultDefine.colors.listed0,
                // 'shape': 'round',
                'iconFont': 'icon-park',
                'fa': 'graduation-cap', // fontawason的图标
                'faCode': '\uf19d', // fontawason的图标编码
                'image': '/assets/icon/park-default.svg',
            },
        },
    },
    [NODE_TYPE_TECHNOLOGY]: {
        title: '技术',
        label: '技术数量',
        postfix: '项',
        timelineFieldName: 'startDate', // 时间排序字段名
        // icon: "file-text", // antd icon
        'iconFont': 'icon-book',
        'fa': 'book', // fontawason的图标
        'faCode': '\uf02d', // fontawason的图标编码
        items: {
            'default': {
                'title': '技术',
                'color': defaultDefine.colors.level1,
                // 'shape': 'round',
                'iconFont': 'icon-book',
                'fa': 'book', // fontawason的图标
                'faCode': '\uf02d', // fontawason的图标编码
            },

        },
    },
    [NODE_TYPE_TEXT_GOAL]: {
        title: '目标',
        label: '目标',
        postfix: '个',
        iconFont: 'icon-flag1',
    },
    [NODE_TYPE_PROJECT]: {
        title: '项目',
        label: '项目',
        postfix: '个',
        timelineFieldName: 'startDate', // 时间排序字段名
        // icon: "hdd", // antd icon
        iconFont: 'icon-project',
        'fa': 'tasks', // fontawason的图标
        'faCode': '\uf0ae', // fontawason的图标编码
        items: {
            default: {
                'title': '项目',
                'color': defaultDefine.colors.listed0,
                // 'shape': 'round',
                'iconFont': 'icon-project',
                'fa': 'tasks', // fontawason的图标
                'faCode': '\uf0ae', // fontawason的图标编码
                'image': '/assets/icon/project-default.svg',
            },
        },
    },
    word: {
        title: '单词',
        label: '单词数',
        postfix: '个',
        // icon: "hdd", // antd icon
        // 'iconFont':'icon-yuandianda',
        'fa': 'graduation-cap', // fontawason的图标
        'faCode': '\uf09e', // fontawason的图标编码
        items: {
            default: {
                'title': '单词',
                'color': defaultDefine.colors.listed0,
                'shape': 'rectangle',
                icon: "hdd", // antd icon
                // 'iconFont':'icon-yuandianda',
                'fa': 'graduation-cap', // fontawason的图标
                'faCode': '\uf09e', // fontawason的图标编码
            },
        },
    },
    edge: {
        title: '关系',
        label: '关系数',
        postfix: '条',
        // icon: "", // antd icon
        // 'iconFont':'icon-yuandianda',
        'fa': '', // fontawason的图标
        'faCode': '', // fontawason的图标编码
        items: {
            default: {
                'title': '关系',
                'color': defaultDefine.colors.listed0,
                'shape': 'line',
                // icon: "hdd", // antd icon
                // 'iconFont':'icon-yuandianda',
                'fa': 'fa-graduation-cap', // fontawason的图标
                'faCode': '\uf09e', // fontawason的图标编码
            },
            grew: {
                'title': '自动联想的节点',
                'color': defaultDefine.colors.listed0,
                'shape': 'dashline',
                // icon: "hdd", // antd icon
                // 'iconFont':'icon-yuandianda',
                'fa': 'fa-graduation-cap', // fontawason的图标
                'faCode': '\uf09e', // fontawason的图标编码
            },
        },
    },
};

// 分类判定
// noinspection SpellCheckingInspection
nodesConfig[NODE_TYPE_TEXT].items[`levgradeType${NODE_TYPE_COMPANY}`] = nodesConfig[NODE_TYPE_COMPANY].items.default;
// noinspection SpellCheckingInspection
nodesConfig[NODE_TYPE_TEXT].items[`levgradeType${NODE_TYPE_TALENT}`] = nodesConfig[NODE_TYPE_TALENT].items.default;
// noinspection SpellCheckingInspection
nodesConfig[NODE_TYPE_TEXT].items[`levgradeType${NODE_TYPE_PATENT}`] = nodesConfig[NODE_TYPE_PATENT].items.default;
// noinspection SpellCheckingInspection
nodesConfig[NODE_TYPE_TEXT].items[`levgradeType${NODE_TYPE_PAPER}`] = nodesConfig[NODE_TYPE_PAPER].items.default;
// noinspection SpellCheckingInspection
nodesConfig[NODE_TYPE_TEXT].items[`levgradeType${NODE_TYPE_ORG}`] = nodesConfig[NODE_TYPE_ORG].items.default;
// noinspection SpellCheckingInspection
nodesConfig[NODE_TYPE_TEXT].items[`levgradeType${NODE_TYPE_INSTITUTE}`] = nodesConfig[NODE_TYPE_INSTITUTE].items.default;
// noinspection SpellCheckingInspection
nodesConfig[NODE_TYPE_TEXT].items[`levgradeType${NODE_TYPE_NEWS_ACTIVITIES}`] = nodesConfig[NODE_TYPE_NEWS_ACTIVITIES].items.default;
// noinspection SpellCheckingInspection
nodesConfig[NODE_TYPE_TEXT].items[`levgradeType${NODE_TYPE_TAG}`] = nodesConfig[NODE_TYPE_TAG].items.default;
// noinspection SpellCheckingInspection
nodesConfig[NODE_TYPE_TEXT].items[`levgradeType${NODE_TYPE_DOCS}`] = nodesConfig[NODE_TYPE_DOCS].items.default;
// noinspection SpellCheckingInspection
nodesConfig[NODE_TYPE_TEXT].items[`levgradeType${NODE_TYPE_DATASET}`] = nodesConfig[NODE_TYPE_DATASET].items.default;
// noinspection SpellCheckingInspection
nodesConfig[NODE_TYPE_TEXT].items[`levgradeType${NODE_TYPE_GOV}`] = nodesConfig[NODE_TYPE_GOV].items.default;
// noinspection SpellCheckingInspection
nodesConfig[NODE_TYPE_TEXT].items[`levgradeType${NODE_TYPE_COLLEGE_AND_UNIVERSITY}`] = nodesConfig[NODE_TYPE_COLLEGE_AND_UNIVERSITY].items.default;
// noinspection SpellCheckingInspection
nodesConfig[NODE_TYPE_TEXT].items[`levgradeType${NODE_TYPE_PARK}`] = nodesConfig[NODE_TYPE_PARK].items.default;
// noinspection SpellCheckingInspection
nodesConfig[NODE_TYPE_TEXT].items[`levgradeType${NODE_TYPE_TECHNOLOGY}`] = nodesConfig[NODE_TYPE_TECHNOLOGY].items.default;
// noinspection SpellCheckingInspection
nodesConfig[NODE_TYPE_TEXT].items[`levgradeType${NODE_TYPE_POLICY}`] = nodesConfig[NODE_TYPE_POLICY].items.default;
// noinspection SpellCheckingInspection
nodesConfig[NODE_TYPE_TEXT].items[`levgradeType${NODE_TYPE_NATURE}`] = nodesConfig[NODE_TYPE_NATURE].items.default;
// noinspection SpellCheckingInspection
nodesConfig[NODE_TYPE_TEXT].items[`levgradeType${NODE_TYPE_PROJECT}`] = nodesConfig[NODE_TYPE_PROJECT].items.default;

export const edgesConfig = {
    [EDGE_TYPE_NONE]: {
        title: '关系',
        label: '关系',
        postfix: '个',
        timelineFieldName: 'startDate', // 时间排序字段名
        // icon: "hdd", // antd icon
        'iconFont': 'icon--rss',
        'fa': 'rss-square', // fontawason的图标
        'faCode': '\uf143', // fontawason的图标编码
        items: {
            default: {
                'title': '统计信息',
                'color': defaultDefine.colors.listed0,
                // 'shape': 'round',
                // icon: "hdd", // antd icon
                'iconFont': 'icon--rss',
                'fa': 'rss-square', // fontawason的图标
                'faCode': '\uf143', // fontawason的图标编码
            },
        },
    },
    [EDGE_TYPE_PEOPLE]: {
        title: '人脉',
        label: '',
        postfix: '',
        items: {
            /*'default': {
                'title': '关注点',
                'color': defaultDefine.colors.default,
            },*/
            'lev1': {
                'title': '同乡',
                'color': defaultDefine.colors.level1,
            },
            'lev2': {
                'title': '同学',
                'color': defaultDefine.colors.level3,
            },
            'lev3': {
                'title': '同事',
                'color': defaultDefine.colors.level2,
            },
            'lev4': {
                'title': '测试',
                'color': defaultDefine.colors.level0,
            },
        },
    },
    [EDGE_TYPE_OUTLINE]: {
        title: '主干',
        label: '',
        postfix: '',
        items: {
            'levtrunk': {
                'title': '树干',
                'color': defaultDefine.colors.level1,
            },
            'levstraight': {
                'title': '直线',
                'color': defaultDefine.colors.level1,
            },
        },
    },
};

/**
 * 统计的文字描述
 * @type {{current: {label: string}, computed: {label: string}, connected: {label: string}}}
 */
export const statisticConfig = {
    current: {
        label: '当前',
    },
    computed: {
        label: '参与计算',
    },
    connected: {
        label: '连接',
    },
};

/**
 * 节点的样式
 * 没有配置的样式会用options中node默认值代替
 * default、defaultGrew、multiSelected、lastSelect、currentSelect 需要设置相同的值，不然会有问题
 * @type {*}
 */
export const nodeGroupNamesStyle = {
    // * 样式默认值，tip:位置不能变,必须放在第一个，'default'名字也不要变
    'default': {
        'shape': 'dot',
        'shadow': false, // 与hoverFocusNode状态对应

    },
    // * 联想状态的样式，只有独立设置到节点上，用group的方式很繁琐 tip: 必须有
    'defaultGrew': {
        'shapeProperties': {
            'borderDashes': [1, 4], // 虚线的样式 格式：[实，间隔]
        },
    },
    // * 多点连线用
    'multiSelected': {
        'color': {
            'background': defaultDefine.colors.highlightEdge,
        },
        // 针对节点类型是 icon   等类型的节点
        'icon': {
            'color': defaultDefine.colors.highlightEdge,
        },
    },
    // * 上一个选中的节点样式，这里面的属性其他状态里面必须都有
    /*'lastSelect': {
        'font': {
            //'color': defaultDefine.lighten(defaultDefine.colors.defaultBlack, 20),
            //'background': defaultDefine.colors.componentBackground,
        },
    },*/
    // * 当前选中节点的节点样式,没有使用
    'currentSelect': {
        'font': {
            'size': 15,
            'color': '#f6f6f6',
            'background': defaultDefine.colors.componentBackground,
        },
    },
    // * 定位时，节点的状态（和鼠标悬浮时样式相同）
    'hoverFocusNode': {
        'shadow': {
            'enabled': true,
            'color': defaultDefine.colors.shadow,
            'size': 25,
            'x': 0,
            'y': 0,
        },
    },
    // * 悬浮状态
    'hover': {
        'shadow': {
            'enabled': true,
            'color': defaultDefine.colors.shadow,
            'size': 25,
            'x': 0,
            'y': 0,
        },
    },
    // * 聚焦状态
    'focus': {
        'shadow': {
            'enabled': true,
            'color': defaultDefine.colors.shadow,
            'size': 25,
            'x': 0,
            'y': 0,
        },
        'font': {
            'color': defaultDefine.lighten(defaultDefine.colors.defaultBlack, 20),
            'background': defaultDefine.colors.defaultWhite,
        },
    },
    // ----公司类节点----------------------------
    [NODE_TYPE_COMPANY]: {
        // * 默认[正常]状态
        'default': {
            'shadow': false, // 与hoverFocusNode状态对应
            // 图形方式
            'shape': 'image',
            'image': nodesConfig[NODE_TYPE_COMPANY].items.default.image,
            // 'size':10,
            'color': {
                'border': defaultDefine.colors.nodeBorder,
                'background': defaultDefine.colors.level1,
                'highlight': {
                    'border': defaultDefine.colors.highlightNodeBorder,
                    'background': defaultDefine.colors.level1,
                },
                'hover': {
                    'border': defaultDefine.colors.highlightNodeBorder,
                    'background': defaultDefine.colors.level1,
                },
            },
            'shapeProperties': {
                'borderDashes': defaultDefine.vis.defaultDashes,
            },
            //
            'font': {
                'size': 13, // 默认字体大小
                'color': defaultDefine.colors.defaultColor,
                'background': 'transparent', // 字体背景透明
                'strokeWidth': 0,
            },
        },
        // * 联想状态样式
        'grew': {
            // 图形方式
            // 'borderWidth': '2',
            // 'borderWidthSelected': '2',
            'shapeProperties': {
                'borderDashes': defaultDefine.vis.grewDashes,
            },
        },
        // 各种属性时对应的样式，配置要写全
        'listed0': {
            // 图形方式
            'shape': 'image',
            'image': nodesConfig[NODE_TYPE_COMPANY].items.listed0.image,
            'color': {
                'border': defaultDefine.colors.nodeBorder,
                'background': defaultDefine.colors.level1,
                'highlight': {
                    'border': defaultDefine.colors.highlightNodeBorder,
                    'background': defaultDefine.colors.level1,
                },
                'hover': {
                    'border': defaultDefine.colors.highlightNodeBorder,
                    'background': defaultDefine.colors.level1,
                },
            },
        },
        'listed10': {
            'shape': 'image',
            'image': nodesConfig[NODE_TYPE_COMPANY].items.listed10.image,
            'color': {
                'border': defaultDefine.colors.nodeBorder,
                'background': defaultDefine.colors.level2,
                'highlight': {
                    'border': defaultDefine.colors.highlightNodeBorder,
                    'background': defaultDefine.colors.level2,
                },
                'hover': {
                    'border': defaultDefine.colors.highlightNodeBorder,
                    'background': defaultDefine.colors.level2,
                },
            },
        },
        'listed20': {
            'shape': 'image',
            'image': nodesConfig[NODE_TYPE_COMPANY].items.listed20.image,
            'color': {
                'border': defaultDefine.colors.nodeBorder,
                'background': defaultDefine.colors.level3,
                'highlight': {
                    'border': defaultDefine.colors.highlightNodeBorder,
                    'background': defaultDefine.colors.level3,
                },
                'hover': {
                    'border': defaultDefine.colors.highlightNodeBorder,
                    'background': defaultDefine.colors.level3,
                },
            },
        },
    },
    // ----人物节点样式----------------------------
    [NODE_TYPE_TALENT]: {
        // * 默认[正常]状态
        'default': {
            'shadow': false, // 与hoverFocusNode状态对应
            'shape': 'image',
            'image': nodesConfig[NODE_TYPE_TALENT].items.default.image,
            'icon': {
                'face': 'FontAwesome',
                'code': nodesConfig[NODE_TYPE_TALENT].items.default.faCode,
                'size': 22,
                'color': defaultDefine.colors.level1,
            },
            'margin': {
                'top': 12, // 画面中label加了两行后，文字和图标距离太近
                'bottom': 18,
            },
            // 'shape':  'triangle',
            'shapeProperties': {
                'borderDashes': false,
            },
            'color': {
                'border': defaultDefine.colors.nodeBorder,
                'background': defaultDefine.colors.level1,
                'highlight': {
                    'border': defaultDefine.colors.highlightNodeBorder,
                    'background': defaultDefine.colors.level1,
                },
                'hover': {
                    'border': defaultDefine.colors.highlightNodeBorder,
                    'background': defaultDefine.colors.level1,
                },
            },
            'font': {
                'size': 13, // 默认字体大小
                'color': defaultDefine.colors.defaultColor,
                'background': 'transparent', // 字体背景透明
                'strokeWidth': 0,
            },
        },
        // * 联想状态样式
        'grew': {
            'shapeProperties': {
                'borderDashes': defaultDefine.vis.grewDashes,
            },
        },
    },
    // ----用户输入节点（类似语工业软件的那种标签）----------------------------
    [NODE_TYPE_TEXT]: {
        // * 默认[正常]状态
        'default': {
            // ce
            'shadow': {
                'enabled': false, // 与hoverFocusNode状态对应
            },
            'shape': 'dot',
            'size': 10,

            'icon': {
                'face': 'FontAwesome',
                'code': nodesConfig[NODE_TYPE_TEXT].items.default.faCode,
                'color': nodesConfig[NODE_TYPE_TEXT].items.default.color,
                'size': 18,
            },
            // 测试图标周围边距
            'margin': {
                'top': 0,
                'left': 0,
                'right': 0,
                'bottom': 18,
            },
            'color': {
                'border': defaultDefine.colors.nodeBorder,
                'background': defaultDefine.colors.level1,
                'highlight': {
                    'border': defaultDefine.colors.highlightNodeBorder,
                    'background': defaultDefine.colors.level1,
                },
                'hover': {
                    'border': defaultDefine.colors.highlightNodeBorder,
                    'background': defaultDefine.colors.level1,
                },
            },

            'font': {
                'size': 13, // 默认字体大小
                'color': defaultDefine.colors.defaultColor,
                'background': 'transparent', // 字体背景透明
                'strokeWidth': 0,
                'align': 'center',
                'multi': false,
                'lineHeight': 19,//0,
                'vadjust': 0,
            },
            imageFilter: undefined,
            'widthConstraint': undefined,
            'heightConstraint': undefined,
            'borderWidth': undefined,
            'borderWidthSelected': undefined,
            'labelHighlightBold': undefined,
        },
        // * 联想状态样式
        'grew': {
            'shapeProperties': {
                'borderDashes': defaultDefine.vis.grewDashes,
            },
        },
        // 各种属性时对应的样式，配置要写全
        /*'root': {
            'shape': 'ellipse',
            'font': {
                'size': 18, // 默认字体大小
                'color': '#212121',
                'background': 'transparent', // 字体背景透明
                'strokeWidth': 0,
                'bold': '20 arial #313131',
            },
            // 'borderWidth': 5,
            // 'borderWidthSelected': 5,
            'color': {
                'border': defaultDefine.colors.level1,
                'background': defaultDefine.colors.level1,
                'highlight': {
                    'border': defaultDefine.colors.level1,
                    'background': defaultDefine.colors.level1,
                },
            },
        },*/
        [`gradeType${NODE_TYPE_COMPANY}`]: {
            // 识别为企业
            'shape': 'image',
            'image': nodesConfig[NODE_TYPE_COMPANY].items.default.image,
            'size': 10,
        },
        [`gradeType${NODE_TYPE_TALENT}`]: {
            // 识别为人物
            'shape': 'image',
            'image': nodesConfig[NODE_TYPE_TALENT].items.default.image,
            'size': 10,
        },
        [`gradeType${NODE_TYPE_PATENT}`]: {
            // 识别为专利
            'shape': 'image',
            'image': nodesConfig[NODE_TYPE_PATENT].items.default.image,
            'size': 10,
        },
        [`gradeType${NODE_TYPE_PAPER}`]: {
            // 识别为论文
            'shape': 'icon',
            'icon': {
                'face': 'FontAwesome',
                'code': nodesConfig[NODE_TYPE_PAPER].items.default.faCode,
                'size': 18,
                'color': defaultDefine.colors.level1,
            },
        },
        [`gradeType${NODE_TYPE_POLICY}`]: {
            // 识别为政策
            'shape': 'image',
            'image': nodesConfig[NODE_TYPE_POLICY].items.default.image,
            'size': 10,
        },
        [`gradeType${NODE_TYPE_ORG}`]: {
            // 识别为社团协会
            'shape': 'image',
            'image': nodesConfig[NODE_TYPE_ORG].items.default.image,
            'size': 10,
        },
        [`gradeType${NODE_TYPE_INSTITUTE}`]: {
            // 识别为科研院所
            'shape': 'image',
            'image': nodesConfig[NODE_TYPE_INSTITUTE].items.default.image,
            'size': 10,
        },
        [`gradeType${NODE_TYPE_NEWS_ACTIVITIES}`]: {
            // 识别为新闻活动
            'shape': 'icon',
            'icon': {
                'face': 'FontAwesome',
                'code': nodesConfig[NODE_TYPE_NEWS_ACTIVITIES].items.default.faCode,
                'size': 18,
                'color': defaultDefine.colors.level1,
            },
        },
        [`gradeType${NODE_TYPE_TAG}`]: {
            // 识别为线索
            'shape': 'icon',
            'icon': {
                'face': 'FontAwesome',
                'code': nodesConfig[NODE_TYPE_TAG].items.default.faCode,
                'size': 18,
                'color': defaultDefine.colors.level1,
            },
        },
        [`gradeType${NODE_TYPE_DOCS}`]: {
            // 识别为用户文档
            'shape': 'icon',
            'icon': {
                'face': 'FontAwesome',
                'code': nodesConfig[NODE_TYPE_DOCS].items.default.faCode,
                'size': 18,
                'color': defaultDefine.colors.level1,
            },
        },
        [`gradeType${NODE_TYPE_DATASET}`]: {
            // 识别为数据集
            'shape': 'icon',
            'icon': {
                'face': 'FontAwesome',
                'code': nodesConfig[NODE_TYPE_DATASET].items.default.faCode,
                'size': 18,
                'color': defaultDefine.colors.level1,
            },
        },
        [`gradeType${NODE_TYPE_GOV}`]: {
            // 识别为政府
            'shape': 'image',
            'image': nodesConfig[NODE_TYPE_GOV].items.default.image,
            'size': 10,
        },
        [`gradeType${NODE_TYPE_NATURE}`]: {
            // 识别为自然资源
            'shape': 'image',
            'image': nodesConfig[NODE_TYPE_NATURE].items.default.image,
            'size': 12,
        },
        //  NODE_TYPE_INDEX, NODE_TYPE_GRAPH
        [`gradeType${NODE_TYPE_COLLEGE_AND_UNIVERSITY}`]: {
            // 识别为高校
            'shape': 'image',
            'image': nodesConfig[NODE_TYPE_COLLEGE_AND_UNIVERSITY].items.default.image,
            'size': 10,
        },
        [`gradeType${NODE_TYPE_PARK}`]: {
            // 识别为园区
            'shape': 'image',
            'image': nodesConfig[NODE_TYPE_PARK].items.default.image,
            'size': 10,
        },
        [`gradeType${NODE_TYPE_TECHNOLOGY}`]: {
            // 识别为技术
            'shape': 'icon',
            'icon': {
                'face': 'FontAwesome',
                'code': nodesConfig[NODE_TYPE_TECHNOLOGY].items.default.faCode,
                'size': 18,
                'color': defaultDefine.colors.level1,
            },
        },
        // NODE_TYPE_TEXT_GOAL
        [`gradeType${NODE_TYPE_PROJECT}`]: {
            // 识别为项目
            'shape': 'image',
            'image': nodesConfig[NODE_TYPE_PROJECT].items.default.image,
            'size': 10,
        },
        'gradeG': {
            // 图形方式
            'shape': 'image',
            'image': nodesConfig[NODE_TYPE_TEXT].items.gradeG.image,
            'size': 16,
        },
        'gradeF': {
            // 图形方式
            'shape': 'image',
            'image': nodesConfig[NODE_TYPE_TEXT].items.gradeF.image,
            'size': 16,
        },
        'gradeE': {
            // 图形方式
            'shape': 'image',
            'image': nodesConfig[NODE_TYPE_TEXT].items.gradeE.image,
            'size': 16,
        },
        'gradeD': {},
        'gradeDA': {
            'color': {
                'background': nodesConfig[NODE_TYPE_TEXT].items.gradeDA.color,
            },
        },
        'gradeDB': {
            'color': {
                'background': nodesConfig[NODE_TYPE_TEXT].items.gradeDB.color,
            },
        },
        'gradeDC': {
            'color': {
                'background': nodesConfig[NODE_TYPE_TEXT].items.gradeDC.color,
            },
        },
        'gradeC': {
            'shape': 'icon',
            'icon': {
                'face': 'FontAwesome',
                'code': nodesConfig[NODE_TYPE_TEXT].items.gradeC.faCode,
                'size': 20,
                'color': nodesConfig[NODE_TYPE_TEXT].items.gradeC.color,
            },
        },
        'gradeB': {
            'shape': 'icon',
            'icon': {
                'face': 'FontAwesome',
                'code': nodesConfig[NODE_TYPE_TEXT].items.gradeB.faCode,
                'size': 20,
                'color': nodesConfig[NODE_TYPE_TEXT].items.gradeB.color,
            },
        },
        'gradeA': {
            'shape': 'icon',
            'icon': {
                'face': 'FontAwesome',
                'code': nodesConfig[NODE_TYPE_TEXT].items.gradeA.faCode,
                'size': 20,
                'color': defaultDefine.colors.level3,
            },
        },
        // 用户中心节点
        'gradeRoot': {
            'shape': 'icon',
            'icon': {
                'face': 'FontAwesome',
                'code': nodesConfig[NODE_TYPE_TEXT].items.gradeA.faCode,
                'size': 28,
                'color': defaultDefine.colors.level3,
            },
        },
        // 树干节点
        'gradeT': {
            size: 30,
            'shape': 'circularImage',
            'image': '/assets/joinmap.png',
            'color': {
                'border': defaultDefine.colors.nodeBorder,
                'background': 'rgba(0, 0, 0, 0)',
                'highlight': {
                    'border': defaultDefine.colors.highlightNodeBorder,
                    'background': 'rgba(0, 0, 0, 0.4)',
                },
                'hover': {
                    'border': defaultDefine.colors.highlightNodeBorder,
                    'background': 'rgba(0, 0, 0, 0.4)',
                },
            },
        },
        // 树干节点
        'gradeU': {
            size: 30,
            'shape': 'image',
            'image': '/assets/joinmap.png',
            'color': {
                'border': defaultDefine.colors.nodeBorder,
                'background': 'rgba(0, 0, 0, 0)',
                'highlight': {
                    'border': defaultDefine.colors.highlightNodeBorder,
                    'background': 'rgba(0, 0, 0, 0.4)',
                },
                'hover': {
                    'border': defaultDefine.colors.highlightNodeBorder,
                    'background': 'rgba(0, 0, 0, 0.4)',
                },
            },
        },
        // 准备
        'prepare': {
            // 图形方式
            'shape': 'image',
            'image': nodesConfig[NODE_TYPE_TEXT].items.prepare.image,
        },
        // 进行
        'doing': {
            // 图形方式
            'shape': 'image',
            'image': nodesConfig[NODE_TYPE_TEXT].items.doing.image,
        },
        // 结束
        'finish': {
            // 图形方式
            'shape': 'image',
            'image': nodesConfig[NODE_TYPE_TEXT].items.finish.image,
        },
        // 点赞
        'good': {
            // 图形方式
            'shape': 'image',
            'image': nodesConfig[NODE_TYPE_TEXT].items.good.image,
        },
        // 点踩
        'bad': {
            // 图形方式
            'shape': 'image',
            'image': nodesConfig[NODE_TYPE_TEXT].items.bad.image,
        },
        // 围观
        'watch': {
            // 图形方式
            'shape': 'image',
            'image': nodesConfig[NODE_TYPE_TEXT].items.watch.image,
        },
        // 无图标
        'noIcon': {
            // 图形方式
            'shape': 'image',
            'image': nodesConfig[NODE_TYPE_TEXT].items.noIcon.image,
        },
        // 便签式
        ...getTipNodeConfigObject(),
        // 标签式
        ...getTagNodeConfigObject(),
        // 系统推荐
        'system-recommend': {
            // 图形方式
            'shape': 'image',
            'image': nodesConfig[NODE_TYPE_TEXT].items['system-recommend'].image,
        },
    },
    // ----专利节点----------------------------
    [NODE_TYPE_PATENT]: {
        // * 默认[正常]状态，样式写全
        'default': {
            'shadow': false, // 与hoverFocusNode状态对应
            // 图片模式时
            'shape': 'image',
            // 'size':10,
            'image': nodesConfig[NODE_TYPE_PATENT].items.default.image,
            'shapeProperties': {
                'useBorderWithImage': false,
            },
            'font': {
                'size': 13, // 默认字体大小
                'color': defaultDefine.colors.defaultColor,
                'background': 'transparent', // 字体背景透明
                'strokeWidth': 0,
            },
            // 'hidden':true
            // 'label': '<span style="color:red;margin:10px;">这都是标签</span>'
        },
        // * 联想状态样式
        'grew': {
            'borderWidth': '2',
            'borderWidthSelected': '2',
            'shapeProperties': {
                'borderDashes': defaultDefine.vis.grewDashes,
            },
        },
    },
    // ----文档节点----------------------------
    [NODE_TYPE_DOCS]: {
        // * 默认[正常]状态
        'default': {
            'shadow': false, // 与hoverFocusNode状态对应
            'icon': {
                'face': 'FontAwesome',
                'code': nodesConfig[NODE_TYPE_DOCS].items.default.faCode,
                'size': 18,
                'color': defaultDefine.colors.level1,
            },
            // 测试图标周围边距
            'margin': {
                'bottom': 18,
            },
            'font': {
                'size': 13, // 默认字体大小
                'color': defaultDefine.colors.defaultColor,
                'background': 'transparent', // 字体背景透明
                'strokeWidth': 0,
            },
            // 'hidden':true
            // 'label': '<span style="color:red;margin:10px;">这都是标签</span>'
        },
        // * 联想状态样式
        'grew': {
            'borderWidth': '2',
            'borderWidthSelected': '2',
            'shapeProperties': {
                'borderDashes': defaultDefine.vis.grewDashes,
            },
        },
    },
    // ----数据集节点----------------------------
    [NODE_TYPE_DATASET]: {
        // * 默认[正常]状态
        'default': {
            'shadow': false, // 与hoverFocusNode状态对应
            'shape': 'icon',
            'icon': {
                'face': 'FontAwesome',
                'code': nodesConfig[NODE_TYPE_DATASET].items.default.faCode,
                'size': 18,
                'color': defaultDefine.colors.level1,
            },
            // 测试图标周围边距
            'margin': {
                'bottom': 18,
            },
            'font': {
                'size': 13, // 默认字体大小
                'color': defaultDefine.colors.defaultColor,
                'background': 'transparent', // 字体背景透明
                'strokeWidth': 0,
                // 'multi': 'html'
            },
            // 'hidden':true
            // 'label': '<span style="color:red;margin:10px;">这都是标签</span>'
        },
        // * 联想状态样式
        'grew': {
            'borderWidth': '2',
            'borderWidthSelected': '2',
            'shapeProperties': {
                'borderDashes': defaultDefine.vis.grewDashes,
            },
        },
    },
    // ----科研院所节点----------------------------
    [NODE_TYPE_INSTITUTE]: {
        // * 默认[正常]状态
        'default': {
            'shadow': false, // 与hoverFocusNode状态对应
            'shape': 'image',
            'image': nodesConfig[NODE_TYPE_INSTITUTE].items.default.image,
            'icon': {
                'face': 'FontAwesome',
                'code': nodesConfig[NODE_TYPE_INSTITUTE].items.default.faCode,
                'size': 18,
                'color': defaultDefine.colors.level1,
            },
            'font': {
                'size': 13, // 默认字体大小
                'color': defaultDefine.colors.defaultColor,
                'background': 'transparent', // 字体背景透明
                'strokeWidth': 0,
                // 'multi': 'html'
            },
            // 'hidden':true
            // 'label': '<span style="color:red;margin:10px;">这都是标签</span>'
        },
        // * 联想状态样式
        'grew': {
            'borderWidth': '2',
            'borderWidthSelected': '2',
            'shapeProperties': {
                'borderDashes': defaultDefine.vis.grewDashes,
            },
        },
    },
    // ----社团 协会节点----------------------------
    [NODE_TYPE_ORG]: {
        // * 默认[正常]状态
        'default': {
            'shadow': false, // 与hoverFocusNode状态对应
            'shape': 'image',
            'image': nodesConfig[NODE_TYPE_ORG].items.default.image,
            'icon': {
                'face': 'FontAwesome',
                'code': nodesConfig[NODE_TYPE_ORG].items.default.faCode,
                'size': 18,
                'color': defaultDefine.colors.level1,
            },
            // 测试图标周围边距
            'margin': {
                'bottom': 18,
            },
            'font': {
                'size': 13, // 默认字体大小
                'color': defaultDefine.colors.defaultColor,
                'background': 'transparent', // 字体背景透明
                'strokeWidth': 0,
                // 'multi': 'html'
            },
            // 'hidden':true
            // 'label': '<span style="color:red;margin:10px;">这都是标签</span>'
        },
        // * 联想状态样式
        'grew': {
            'borderWidth': '2',
            'borderWidthSelected': '2',
            'shapeProperties': {
                'borderDashes': defaultDefine.vis.grewDashes,
            },
        },
    },
    // ----知识技术节点----------------------------
    [NODE_TYPE_PAPER]: {
        // * 默认[正常]状态
        'default': {
            'shadow': false, // 与hoverFocusNode状态对应
            'shape': 'icon',
            'icon': {
                'face': 'FontAwesome',
                'code': nodesConfig[NODE_TYPE_PAPER].items.default.faCode,
                'size': 18,
                'color': defaultDefine.colors.level1,
            },
            // 测试图标周围边距
            'margin': {
                'bottom': 18,
            },
            'font': {
                'size': 13, // 默认字体大小
                'color': defaultDefine.colors.defaultColor,
                'background': 'transparent', // 字体背景透明
                'strokeWidth': 0,
                // 'multi': 'html'
            },
            // 'hidden':true
            // 'label': '<span style="color:red;margin:10px;">这都是标签</span>'
        },
        // * 联想状态样式
        'grew': {
            'borderWidth': '2',
            'borderWidthSelected': '2',
            'shapeProperties': {
                'borderDashes': defaultDefine.vis.grewDashes,
            },
        },
    },
    // ----计算标签节点 后台算法 预留----------------------------
    [NODE_TYPE_TAG]: {
        // * 默认[正常]状态
        'default': {
            'shadow': false, // 与hoverFocusNode状态对应
            'shape': 'icon',
            'icon': {
                'face': 'FontAwesome',
                'code': nodesConfig[NODE_TYPE_TAG].items.default.faCode,
                'size': 18,
                'color': defaultDefine.colors.level1,
            },
            'font': {
                'size': 13, // 默认字体大小
                'color': defaultDefine.colors.defaultColor,
                'background': 'transparent', // 字体背景透明
                'strokeWidth': 0,
                // 'multi': 'html'
            },
            // 'hidden':true
            // 'label': '<span style="color:red;margin:10px;">这都是标签</span>'
        },
        // * 联想状态样式
        'grew': {
            'borderWidth': '2',
            'borderWidthSelected': '2',
            'shapeProperties': {
                'borderDashes': defaultDefine.vis.grewDashes,
            },
        },
    },
    // ----新闻活动节点----------------------------
    [NODE_TYPE_NEWS_ACTIVITIES]: {
        // * 默认[正常]状态
        'default': {
            'shadow': false, // 与hoverFocusNode状态对应
            'shape': 'icon',
            'icon': {
                'face': 'FontAwesome',
                'code': nodesConfig[NODE_TYPE_NEWS_ACTIVITIES].items.default.faCode,
                'size': 18,
                'color': defaultDefine.colors.level1,
            },
            // 测试图标周围边距
            'margin': {
                'bottom': 18,
            },
            'font': {
                'size': 13, // 默认字体大小
                'color': defaultDefine.colors.defaultColor,
                'background': 'transparent', // 字体背景透明
                'strokeWidth': 0,
                // 'multi': 'html'
            },
            // 'hidden':true
            // 'label': '<span style="color:red;margin:10px;">这都是标签</span>'
        },
        // * 联想状态样式
        'grew': {
            'borderWidth': '2',
            'borderWidthSelected': '2',
            'shapeProperties': {
                'borderDashes': defaultDefine.vis.grewDashes,
            },
        },
    },
    // ----政府节点样式----------------------------
    [NODE_TYPE_GOV]: {
        // * 默认[正常]状态
        'default': {
            'shadow': false, // 与hoverFocusNode状态对应
            'shape': 'image',
            'image': nodesConfig[NODE_TYPE_GOV].items.default.image,
            'margin': {
                'top': 12, // 画面中label加了两行后，文字和图标距离太近
                'bottom': 18,
            },
            // 'shape':  'triangle',
            'shapeProperties': {
                'borderDashes': false,
            },
            'color': {
                'border': defaultDefine.colors.nodeBorder,
                'background': defaultDefine.colors.level1,
                'highlight': {
                    'border': defaultDefine.colors.highlightNodeBorder,
                    'background': defaultDefine.colors.level1,
                },
                'hover': {
                    'border': defaultDefine.colors.highlightNodeBorder,
                    'background': defaultDefine.colors.level1,
                },
            },
            'font': {
                'size': 13, // 默认字体大小
                'color': defaultDefine.colors.defaultColor,
                'background': 'transparent', // 字体背景透明
                'strokeWidth': 0,
            },
        },
        // * 联想状态样式
        'grew': {
            'shapeProperties': {
                'borderDashes': defaultDefine.vis.grewDashes,
            },
        },
    },
    // ----高校节点样式----------------------------
    [NODE_TYPE_COLLEGE_AND_UNIVERSITY]: {
        // * 默认[正常]状态
        'default': {
            'shadow': false, // 与hoverFocusNode状态对应
            'shape': 'image',
            'image': nodesConfig[NODE_TYPE_COLLEGE_AND_UNIVERSITY].items.default.image,
            'margin': {
                'top': 12, // 画面中label加了两行后，文字和图标距离太近
                'bottom': 18,
            },
            // 'shape':  'triangle',
            'shapeProperties': {
                'borderDashes': false,
            },
            'color': {
                'border': defaultDefine.colors.nodeBorder,
                'background': defaultDefine.colors.level1,
                'highlight': {
                    'border': defaultDefine.colors.highlightNodeBorder,
                    'background': defaultDefine.colors.level1,
                },
                'hover': {
                    'border': defaultDefine.colors.highlightNodeBorder,
                    'background': defaultDefine.colors.level1,
                },
            },
            'font': {
                'size': 13, // 默认字体大小
                'color': defaultDefine.colors.defaultColor,
                'background': 'transparent', // 字体背景透明
                'strokeWidth': 0,
            },
        },
        // * 隐藏状态，todo:
        'hidden': {},
        // * 联想状态样式
        'grew': {
            'shapeProperties': {
                'borderDashes': defaultDefine.vis.grewDashes,
            },
        },
    },
    // ----园区节点样式----------------------------
    [NODE_TYPE_PARK]: {
        // * 默认[正常]状态
        'default': {
            'shadow': false, // 与hoverFocusNode状态对应
            'shape': 'image',
            'image': nodesConfig[NODE_TYPE_PARK].items.default.image,
            'margin': {
                'top': 12, // 画面中label加了两行后，文字和图标距离太近
                'bottom': 18,
            },
            // 'shape':  'triangle',
            'shapeProperties': {
                'borderDashes': false,
            },
            'color': {
                'border': defaultDefine.colors.nodeBorder,
                'background': defaultDefine.colors.level1,
                'highlight': {
                    'border': defaultDefine.colors.highlightNodeBorder,
                    'background': defaultDefine.colors.level1,
                },
                'hover': {
                    'border': defaultDefine.colors.highlightNodeBorder,
                    'background': defaultDefine.colors.level1,
                },
            },
            'font': {
                'size': 13, // 默认字体大小
                'color': defaultDefine.colors.defaultColor,
                'background': 'transparent', // 字体背景透明
                'strokeWidth': 0,
            },
        },
        // * 隐藏状态，todo:
        'hidden': {},
        // * 联想状态样式
        'grew': {
            'shapeProperties': {
                'borderDashes': defaultDefine.vis.grewDashes,
            },
        },
    },
    // ----技术技术节点----------------------------
    [NODE_TYPE_TECHNOLOGY]: {
        // * 默认[正常]状态
        'default': {
            'shadow': false, // 与hoverFocusNode状态对应
            'shape': 'icon',
            'icon': {
                'face': 'FontAwesome',
                'code': nodesConfig[NODE_TYPE_TECHNOLOGY].items.default.faCode,
                'size': 18,
                'color': defaultDefine.colors.level1,
            },
            // 测试图标周围边距
            'margin': {
                'bottom': 18,
            },
            'font': {
                'size': 13, // 默认字体大小
                'color': defaultDefine.colors.defaultColor,
                'background': 'transparent', // 字体背景透明
                'strokeWidth': 0,
                // 'multi': 'html'
            },
            // 'hidden':true
            // 'label': '<span style="color:red;margin:10px;">这都是标签</span>'
        },
        // * 联想状态样式
        'grew': {
            'borderWidth': '2',
            'borderWidthSelected': '2',
            'shapeProperties': {
                'borderDashes': defaultDefine.vis.grewDashes,
            },
        },
    },
    // ----自然资源节点样式----------------------------
    [NODE_TYPE_NATURE]: {
        // * 默认[正常]状态
        'default': {
            'shadow': false, // 与hoverFocusNode状态对应
            'shape': 'image',
            'image': nodesConfig[NODE_TYPE_NATURE].items.default.image,
            'margin': {
                'top': 12, // 画面中label加了两行后，文字和图标距离太近
                'bottom': 18,
            },
            // 'shape':  'triangle',
            'shapeProperties': {
                'borderDashes': false,
            },
            'color': {
                'border': defaultDefine.colors.nodeBorder,
                'background': defaultDefine.colors.level1,
                'highlight': {
                    'border': defaultDefine.colors.highlightNodeBorder,
                    'background': defaultDefine.colors.level1,
                },
                'hover': {
                    'border': defaultDefine.colors.highlightNodeBorder,
                    'background': defaultDefine.colors.level1,
                },
            },
            'font': {
                'size': 13, // 默认字体大小
                'color': defaultDefine.colors.defaultColor,
                'background': 'transparent', // 字体背景透明
                'strokeWidth': 0,
            },
        },
        // * 联想状态样式
        'grew': {
            'shapeProperties': {
                'borderDashes': defaultDefine.vis.grewDashes,
            },
        },
    },
    // ----政策节点样式----------------------------
    [NODE_TYPE_POLICY]: {
        // * 默认[正常]状态
        'default': {
            'shadow': false, // 与hoverFocusNode状态对应
            'shape': 'image',
            'image': nodesConfig[NODE_TYPE_POLICY].items.default.image,
            'margin': {
                'top': 12, // 画面中label加了两行后，文字和图标距离太近
                'bottom': 18,
            },
            // 'shape':  'triangle',
            'shapeProperties': {
                'borderDashes': false,
            },
            'color': {
                'border': defaultDefine.colors.nodeBorder,
                'background': defaultDefine.colors.level1,
                'highlight': {
                    'border': defaultDefine.colors.highlightNodeBorder,
                    'background': defaultDefine.colors.level1,
                },
                'hover': {
                    'border': defaultDefine.colors.highlightNodeBorder,
                    'background': defaultDefine.colors.level1,
                },
            },
            'font': {
                'size': 13, // 默认字体大小
                'color': defaultDefine.colors.defaultColor,
                'background': 'transparent', // 字体背景透明
                'strokeWidth': 0,
            },
        },
        // * 联想状态样式
        'grew': {
            'shapeProperties': {
                'borderDashes': defaultDefine.vis.grewDashes,
            },
        },
    },
    // ----项目节点样式----------------------------
    [NODE_TYPE_PROJECT]: {
        // * 默认[正常]状态
        'default': {
            'shadow': false, // 与hoverFocusNode状态对应
            'shape': 'image',
            'image': nodesConfig[NODE_TYPE_PROJECT].items.default.image,
            'margin': {
                'top': 12, // 画面中label加了两行后，文字和图标距离太近
                'bottom': 18,
            },
            // 'shape':  'triangle',
            'shapeProperties': {
                'borderDashes': false,
            },
            'color': {
                'border': defaultDefine.colors.nodeBorder,
                'background': defaultDefine.colors.level1,
                'highlight': {
                    'border': defaultDefine.colors.highlightNodeBorder,
                    'background': defaultDefine.colors.level1,
                },
                'hover': {
                    'border': defaultDefine.colors.highlightNodeBorder,
                    'background': defaultDefine.colors.level1,
                },
            },
            'font': {
                'size': 13, // 默认字体大小
                'color': defaultDefine.colors.defaultColor,
                'background': 'transparent', // 字体背景透明
                'strokeWidth': 0,
            },
        },
        // * 联想状态样式
        'grew': {
            'shapeProperties': {
                'borderDashes': defaultDefine.vis.grewDashes,
            },
        },
    },
    // ---- [废] 提示类节点----------------------------
    [NODE_TYPE_NODE_TIP]: {
        // * 默认[正常]状态
        'default': {
            'shadow': false, // 与hoverFocusNode状态对应
            'shape': 'text',
            'font': {
                'size': 13, // 默认字体大小
                'color': defaultDefine.colors.defaultColor,
                'background': 'transparent', // 字体背景透明
                'strokeWidth': 0,
                // 'multi': 'html'
            },
        },
        // * 联想状态样式
        'grew': {
            'borderWidth': '2',
            'borderWidthSelected': '2',
            'shapeProperties': {
                'borderDashes': defaultDefine.vis.grewDashes,
            },
        },
    },
};
export const edgeGroupNamesStyle = {
    // * 正常状态边样式
    'default': {
        'dashes': false, // 虚线
        'physics': true,
        'width': 0.5,
        'selectionWidth': 1,
        // 边的弯曲设置
        'smooth': {
            'enabled': true,
            'type': 'curvedCW',
            'forceDirection': 'vertical',
            'roundness': 0.15,
        },
        // 边的有向设置
        /*'arrows': {
            'to': {
                'enabled': false,
                'scaleFactor': 0.9,
                'type': 'arrow',
            },
        },*/
        // 边的颜色设置
        'color': {
            // 'inherit': 'from',  // 颜色来自from
            'inherit': false, // 颜色不取两端边的颜色
            'color': defaultDefine.colors.edgeColor,
            // 'border': defaultDefine.colors.edgeBorderColor,
            // 'highlight': defaultDefine.colors.highlightEdge,
            // 'hover': defaultDefine.colors.highlightEdge,
        },
        // 边上面的字体设置，可以支持部分html
        'font': {
            'color': defaultDefine.colors.defaultColor,
            'size': 13,
            // 'background': 'rgba(255,255,255,0)',
            'strokeWidth': 0,
        },
    },
    // * 当前选中节点的节点样式,没有使用
    'currentSelect': {
        'font': {
            'size': 15,
            // 'color': '#ffff9e',
            'color': '#f6f6f6',
            'background': defaultDefine.colors.componentBackground,
        },
    },
    // * 联想出的边样式
    'grew': {
        'dashes': true, // 虚线
        'width': 1,
        'color': {
            'color': defaultDefine.colors.edgeColor,
            // 'border': defaultDefine.colors.edgeBorderColor,
            // 'highlight': defaultDefine.colors.highlightEdge,
            // 'hover': defaultDefine.colors.highlightEdge,
            // 'opacity': 0.8, // 尽量不要用，耗资源，用颜色tinycolor库处理成颜色值
        },
        'font': {
            'color': defaultDefine.colors.defaultColor,
            'size': 13,
            // 'background': 'rgba(255,255,255,0)',
            'strokeWidth': 0,
        },
    },
    // * todo:悬浮状态
    'hover': {},
    // * todo:聚焦状态
    'focus': {},
    'straight': {
        'smooth': false,
    },
    'no-arrows': {
        'arrows': '',
    },
    'nonePhysics': {
        'physics': false,
    },
    'invisible': {
        'dashes': true, // 虚线
        'width': 0,
        'color': {
            'color': 'rgba(0, 0, 0, 0)',
        },
    },
    'ai-generated': {
        'dashes': true, // 虚线
        'color': {
            'color': 'rgba(0, 0, 0, 0)',
        },
    },
    'user-marked-invisible': {
        'dashes': true, // 虚线
        'color': {
            'color': 'rgba(0, 0, 0, 0)',
        },
    },
    'width-1': {
        'width': 1,
    },
    'width-2': {
        'width': 2,
    },
    'width-5': {
        'width': 5,
    },
    'width-10': {
        'width': 10,
    },
    // 人脉分类
    [EDGE_TYPE_PEOPLE]: {
        // * 默认[正常]状态
        'default': {
            'shadow': false, // 与hoverFocusNode状态对应
            'shape': 'text',
            'font': {
                'size': 12, // 默认字体大小
                'color': defaultDefine.colors.defaultColor,
                'background': 'transparent', // 字体背景透明
                'strokeWidth': 0,
                // 'multi': 'html'
            },
            // ---
        },
        // * 高亮状态
        'highLight': {},
        // * 联想状态样式
        'grew': {
            'borderWidth': '2',
            'borderWidthSelected': '2',
            'shapeProperties': {
                'borderDashes': defaultDefine.vis.grewDashes,
            },
        },
        // 同乡
        'lev1': {
            'color': {
                'inherit': false, // 颜色不取两端边的颜色,值：from to both false
                'color': defaultDefine.colors.level1,
            },
        },
        // 校友
        'lev2': {
            'color': {
                'inherit': false, // 颜色不取两端边的颜色,值：from to both false
                'color': defaultDefine.colors.level2,
            },
        },
        // 同事
        'lev3': {
            'color': {
                'inherit': false, // 颜色不取两端边的颜色,值：from to both false
                'color': defaultDefine.colors.level3,
            },
        },
        // 测试
        'lev4': {
            'color': {
                'inherit': false, // 颜色不取两端边的颜色,值：from to both false
                'color': defaultDefine.colors.level0,
            },
        },
        // 无宽度
        'levNoWidth': {
            hidden: true,
        },
    },
    // 大纲分类
    [EDGE_TYPE_OUTLINE]: {
        // * 默认[正常]状态
        'default': {
            // 取消虚线
            'dashes': false,
            // 边的弯曲设置
            'smooth': false,
            'width': 2,

        },
        // * 高亮状态
        'highLight': {},
        // * 生长状态样式
        'grew': {},
        // 树干：用户输入节点之间的连线
        'levtrunk': {
            'width': 2,
        },
        // 直线：线条变为直线
        'levstraight': {
            'width': 0.5,
        },

        // 资源节点发散出的分析属性间的连线
        'lev2': {
            'width': 2,
        },
    },
};

/**
 * 图数据的一些配置信息
 * @type  {*}
 */
export const graphConfig = {
    /** 区分 节点、边 状态的字段名   */
    'StatusFieldName': 'status',
    /** 区分节点分类的字段名   */
    'NodeClassFieldName': 'type',
    /** 区分边各种状态的字段名   */
    // 'EdgeClassFieldName': 'status',
    /** 区分 边 各种状态的字段名   */
    'EdgeClassFieldName': 'type',
    /** 各种类节点的分类字段 对应不同的状态 */
    'NodeDivideFieldName': {
        [NODE_TYPE_TEXT]: 'lev', // 徐国不想区分各种层级，就设置成null
        [NODE_TYPE_COMPANY]: 'listed', //
        [NODE_TYPE_TALENT]: null, // prfLevel
        [NODE_TYPE_PAPER]: null, //
        [NODE_TYPE_PATENT]: null, //
        [NODE_TYPE_POLICY]: null, //
        [NODE_TYPE_ORG]: null, //
        [NODE_TYPE_INSTITUTE]: null, //
        [NODE_TYPE_NEWS_ACTIVITIES]: null, //
        [NODE_TYPE_TAG]: null, //
        [NODE_TYPE_DOCS]: null, //
        [NODE_TYPE_DATASET]: null,//
        [NODE_TYPE_NATURE]: null,
        [NODE_TYPE_PROJECT]: null,
    },
    /** 各种类边的分类字段 对应不同的状态 */
    'EdgeDivideFieldName': {
        [EDGE_TYPE_NONE]: null, //
        [EDGE_TYPE_PEOPLE]: 'lev', // 如果不想区分各种层级，就设置成null
        [EDGE_TYPE_OUTLINE]: 'lev', // 如果不想区分各种层级，就设置成null
    },
    /** 不能通过改变颜色的shape种类   */
    'NeedChangeShapes': ';img;icon;',

    /** 点击节点是否居中   */
    'IsClickedAndCenter': false,
    /**
     * 画面高亮方式
     * selected = 需要高亮的节点设置成选中状态
     * group = 需要高亮的节点通过设置group来切换显示样式
     * undefined = vis 默认的效果
     */
    'HighlightType': undefined,
};

/**
 * vis 包装了操作集合
 * @type  {*}
 */
export const myVis = {
    ...graphConfig,
    /**
     * @param node
     * @return {string|number}
     */
    getNodeTypeFn(node) {
        let typeValue = node[myVis.NodeClassFieldName];
        if (typeValue === NODE_TYPE_TEXT || typeValue === NODE_TYPE_TAG) {
            if (`${node[myVis.NodeDivideFieldName[NODE_TYPE_TEXT]]}`.indexOf('gradeType') === 0) {
                typeValue = parseInt(node['lev'].substring(9));
            } else if (['gradeA', 'gradeB', 'gradeC', 'gradeE', 'gradeF', 'gradeG']
              .includes(node[myVis.NodeDivideFieldName[NODE_TYPE_TEXT]])) {

                typeValue = NODE_TYPE_TEXT_GOAL;
            }
        }
        return typeValue;
    },
    /*==========================================================
     操作
    ===========================================================*/
    /**
     * 统计节点类型数据
     * {computed: {},connected: {nodeType: 4, 2: 2},current: {0: 14, 1: 33, 2: 18}}
     * @param node 统计的节点
     * @param statistic 统计结果
     * @param {boolean|function} [callback] 执行额外操作
     * @param {function|undefined} [getNodeTypeFn] 指定获取节点类型的方法
     * @param typeName 按该值的字段分类统计
     */
    statisticFun(node, statistic = {}, callback = false, getNodeTypeFn = myVis.getNodeTypeFn, typeName = this.NodeClassFieldName) {
        let typeValue = getNodeTypeFn ? getNodeTypeFn(node) : node[typeName];
        if (statistic[typeValue]) {
            statistic[typeValue] = statistic[typeValue] + 1
        } else {
            statistic[typeValue] = 1
        }
        // 触发统计
        callback && callback()
    },

    /**
     * 对单个节点计算图例，并添加到图例数组中
     * 返回格式：{0: ["default"]1:["default", "listed0"]2: ["default"]}
     * 1112 增加记录每种类型的数量
     * 返回格式：{"nodeType1": {"default":1000},"nodeType2":{"default":1000, "listed0":1000},"nodeType3": {"default":1000}}
     * @param node array of node 统计的节点
     * @param legend object 被追加的结果json{cop:['default','xxx','xxxx'],prf:['default','xxx','xxxx'],......}
     * @param callback function 结束后执行额外操作
     * @param add 增加还是减少wtf干什么用的？
     * @param {function|undefined} [getNodeTypeFn] 指定获取节点类型的方法
     * @param nodeClassFieldName string 按该字段值区分节点种类，例如：type
     * @param nodeDivideFieldName array 按该字段值区分节点层级，各类节点区分层级的字段值
     */
    legendFun(node, legend = {}, callback = false, add = true, getNodeTypeFn = myVis.getNodeTypeFn, nodeClassFieldName = this.NodeClassFieldName, nodeDivideFieldName = this.NodeDivideFieldName) {
        let typeValue = getNodeTypeFn ? getNodeTypeFn(node) : node[nodeClassFieldName];
        if (typeValue !== null && typeValue !== undefined) {
            if (!legend[typeValue]) {
                legend[typeValue] = {}
            }
            if (node[nodeDivideFieldName[typeValue]] !== null && node[nodeDivideFieldName[typeValue]] !== undefined) { // 图例统计的 字段 是否存在
                let divideFieldName = nodeDivideFieldName[typeValue] + node[nodeDivideFieldName[typeValue]];
                if (legend[typeValue][divideFieldName] !== null && legend[typeValue][divideFieldName] !== undefined) { // 图例统计的 字段 是否存在
                    if (add === false) {
                        legend[typeValue][divideFieldName] = legend[typeValue][divideFieldName] - 1
                    } else {
                        legend[typeValue][divideFieldName] = legend[typeValue][divideFieldName] + 1
                    }
                } else {
                    if (add !== false) {
                        legend[typeValue][divideFieldName] = 1
                    }
                }
            } else {
                // 没法确认是该节点的什么级别，只能用默认
                if (legend[typeValue].default !== null && legend[typeValue].default !== undefined) { // 图例统计的 字段 是否存在
                    if (add === false) {
                        legend[typeValue].default = legend[typeValue].default - 1
                    } else {
                        legend[typeValue].default = legend[typeValue].default + 1
                    }
                } else {
                    if (add !== false) {
                        legend[typeValue].default = 1
                    }
                }
            }
        } else {
            // todo:不知道是什么节点,这里扔掉不处理统计
        }
        // 统计后的额外操作
        callback && callback()
    },


    /**
     * 只做格式化
     * 给数据集节点增加对应的样式后，再添加到目标图数据中
     * 根据节点第二层性质[ divideFieldName 区分]，分配不同的group样式,并把初始的group值存储在firstGroup字段中
     * @param data array 需要处理的数据集,
     * @param graph 与画面对应的vis dataset 数据集
     * @param nodeBeforeGroup function 每个节点的分配group前的额外处理
     * @param nodeAfterGroup function 每个节点的分配group后的额外处理
     * @param groupStyle object 分组的样式
     * @param divideFieldArr array 指定区分分组的字段
     * @param nodeClassFieldName string 指定区分节点类型的字段
     * @param edgeClassFieldName string 指定区边类型的字段
     * @param options object 参数
     * @return {{nodes: DataSet, edges: DataSet}}
     */
    /**
     *
     * @param data
     * @param graph
     * @param options
     * @returns {*}
     */
    format(
        data = {'nodes': [], 'edges': []},
        graph = {'nodes': new DataSet(), 'edges': new DataSet()},
        options = {}
    ) {
        // 默认options配置
        let defaultOptions = {
            groupStyle: this.GroupStyle,
            divideFieldArr: this.NodeDivideFieldName,
            nodeClassFieldName: this.NodeClassFieldName,
            edgeClassFieldName: this.EdgeClassFieldName,
            statistic: undefined, // tip:传空值时不统计
            legend: undefined, // tip:传空值时不统计
        };
        //todo:合并option
        let newOptions = {...defaultOptions, ...options}
        const {
            groupStyle,
            divideFieldArr,
            nodeClassFieldName,
            edgeClassFieldName,
            statistic,
            legend,
        } = newOptions;
        // 格式化 nodes 分配不同的样式
        const tmpNode = []
        if (data.nodes && data.nodes.length > 0) {
            data.nodes.forEach((node, index) => {

                // * todo:如果节点没有id的处理
                if (!node.id) {
                }
                // * 每个节点分配group之前的处理，根据需要增加、删除、修改节点的字段
                this.formatNodeBefore(node, nodeClassFieldName)

                // * 陆金龙，格式化节点样式
                // this.dealNodeGroupNames(node)
                node = new Node(node)

                // * 每个节点分配样式以后的处理
                this.formatNodeAfter(node, index)

                // * 判断graph是否存在，查询是否有重复id,有=update，没有=push
                if (!graph || graph.nodes.get(node.id) === null) {
                    tmpNode.push(node)
                } else {
                    // tip:处理重复id的节点，不处理就空着，这里会更新掉原来的节点
                    graph.nodes.update(node)
                }

                // * 统计
                if (statistic) {
                    this.statisticFun(node, statistic)
                }
                if (legend) {
                    this.legendFun(node, legend)
                }
            })
        }
        const tmpEdge = []
        // * 格式化 edges，todo: 有虚线的需求
        if (data.edges && data.edges.length > 0) {
            data.edges.forEach((edge) => {

                edge = new Edge(edge)
                // 格式化边的样式
                // this.dealEdgeGroupNames(edge);
                // edge.dealGroupNamesAndAddStyle(this.EdgeClassFieldName)

                if (!graph || graph.edges.get({
                    filter: function (item) {
                        return (item.from === edge.from && item.to === edge.to)
                    },
                }).length === 0) {
                    // 没有重复的edge id
                    tmpEdge.push(edge)
                } else {
                    // 有重复的edge id,todo:有重复边没做处理，直接丢掉

                }
            })
        }

        if (graph) {
            graph.nodes.update(tmpNode);
            graph.edges.update(tmpEdge);

            return graph;
        } else {
            return {nodes: tmpNode, edges: tmpEdge};
        }

    },

    /*==========================================================
    vis js network 动作
    ===========================================================*/
    /**
     * 根据节点的种类、级别格式化vis画面的样式
     * via 陆金龙
     *
     * @param node 需要改变的节点
     * @param classname 节点分类的字段名
     * @param level 节点的级别的字段名
     */
    dealNodeGroupNames(node, classname = this.NodeClassFieldName, level = this.NodeDivideFieldName) {
        delete node.group
        delete node.firstGroup
        delete node.hiddenGroup
        delete node.color
        delete node.shape
        delete node.font
        delete node.icon
        delete node.borderWidth
        delete node.borderWidthSelected
        delete node.shapeProperties

        node.dealGroupNamesAndAddStyle()

    },
    /**
     * @param edge
     * @param classname
     */
    dealEdgeGroupNames(edge, classname = this.EdgeClassFieldName) {
        edge.dealGroupNamesAndAddStyle(classname)
    },

    /**
     * 高亮指定节点以及连接的边、点，并focus该节点，移动节点到屏幕中央
     * 点击？选中？聚焦？
     * @param network 需要操作的vis network对象
     * @param graph 与画面对应的vis dataset 数据集
     * @param id 需要操作的节点id
     * @param afterCallBack 高亮动作完成后，并且focus动画结束 以后的处理 fixme:这个需要吗？
     * @return {{node: *,connectedNodesIdArr:[],connectedEdgesIdArr:[]}} 返回数据：node、connectedNodesIdArr连接的节点id数组、connectedEdgesIdArr连接的边id数组
     */
    highlightNode(network, graph, id, afterCallBack, isMoveTOCenter = this.IsClickedAndCenter) {
        let node = graph.nodes.get(id)
        let result = {node: node}

        // * 获取连接的节点id
        let connectedNodes = network.getConnectedNodes(id)
        result.connectedNodesIdArr = network.getConnectedNodes(id)
        connectedNodes.push(id) // 把自己添加到连接id数组里

        // * 获取连接的边id
        let connectedEdges = network.getConnectedEdges(id)
        result.connectedEdgesIdArr = connectedEdges

        // tip:高亮的节点增加一个样式配置
        let groupNames = node.groupNames.split(' ');


        // 根据需要添加
        if (this.HighlightType == 'selected') {
            this.selectedNodeByIdArr(network, connectedNodes, connectedEdges)
        }

        // 是否移动到画布中心
        if (isMoveTOCenter === true) {
            // 聚焦被点击的节点，移动到屏幕中央
            network.focus(id, {
                // 'scale': 0.8,
                'animation': {
                    'duration': 300,
                },
            })
            // 高亮以后的动作
            if (afterCallBack) {
                // 绑定一次性的动画结束事件
                network.once('animationFinished', () => {
                    afterCallBack(node)
                })
            }
        } else {
            // 高亮以后的动作
            if (afterCallBack) {
                afterCallBack(node)
            }
        }

        // 返回：id对应的节点+连接节点+连接的边 的id数组
        return result
    },

    /**
     * 高亮指定的边和连接的节点
     * @param network vis network对象
     * @param graph 与画面对应的vis dataset 数据集
     * @param id 边的id
     * @param afterCallBack 高亮以后的操作（一次性调用vis动画结束事件） fixme:这个需要吗？
     * @return {{edge: *}}
     */
    highlightEdge(network, graph, id, afterCallBack) {
        let edge = graph.edges.get(id)
        let result = {edge: edge}
        // * 获取连接的节点id，并转换成字符串
        let connectedNodes = [edge.from, edge.to]
        result.connectedNodesIdArr = connectedNodes

        // 根据需要添加
        if (this.HighlightType == 'selected') {
            this.selectedNodeByIdArr(network, connectedNodes, [id])
        }

        // fixme: 这里是否需要暂停？
        network.stopSimulation()

        if (afterCallBack) {
            afterCallBack(edge)
        }

        // 返回：id对应的节点+连接节点+连接的边 的id数组
        return result
    },

    /**
     * 高亮指定的节点
     * 需要高亮的节点设置成选中状态，其他的不隐藏
     * @param network vis network 对象
     * @param nodesIdArr 节点id Array
     * @param edgesIdArr 边 id Array
     */
    selectedNodeByIdArr(network, nodesIdArr, edgesIdArr) {
        network.setSelection(
            {nodes: nodesIdArr, edges: edgesIdArr},
            {
                unselectAll: true, //
                highlightEdges: false, //
            })
    },

    /**
     * 恢复节点和边的样式到开始状态
     * 通过group切换节点的不用样式
     * 节点必须要有：firstGroup初始样式、hiddenGroup隐藏样式
     * @param graph 与画面对应的vis dataset 数据集
     * @param edgeStyle
     */
    resetStyle(graph, edgeStyle = this.GroupStyle.edge) {
        // 存放临时修改过的数据
        let tempModify = []
        graph.nodes.map((node) => {
            if (node.group && node.firstGroup && node.group != node.firstGroup) {
                node.group = node.firstGroup
                tempModify.push(node)
            } else if (!node.firstGroup) {
                node.group = 'default'
                tempModify.push(node)
            } else if (!node.group) {
                node.group = node.firstGroup
                tempModify.push(node)
            }
        })
        // update节点
        graph.nodes.update(tempModify)

        // 检查边并更新hidden的边
        tempModify = []
        graph.edges.map((edge) => {
            edge = Object.assign(edge, edgeStyle[edge.firstGroup])
            tempModify.push(edge)
        })
        // update 边
        graph.edges.update(tempModify)
    },

    /**
     * 缩放图
     * @param network
     * @param h
     */
    zoom(network, h) {
        let xy = network.getViewPosition()
        let scale = network.getScale()
        // if (scale < 0.5) return
        let c
        if (h === 'up') {
            c = 3 / 2;
        } else if (h === 'down') {
            c = 2 / 3;
        } else {
            console.log('无效的缩放指令,忽略。')
            c = 1;
        }
        network.moveTo({
            'position': xy,
            'scale': scale * c,
            // offset: {x:Number, y:Number}
            'animation': { // -------------------> can be a boolean too!
                'duration': 100,
            },
        })
    },

    /**
     * 计算某节点随机半径、随机角度的坐标位置
     * @param network
     * @param s
     */
    randomRoundOfNode(network, s) {
        let txy = {}
        // 有连接节点，获取目标节点的xy位置
        let xy = network.getPositions([s.id])
        if (Object.keys(xy).length > 0) {
            // 在中心点周围圆周上生成随机点，100是半径
            let radius = Math.random() * 100 + 50
            let angle = Math.random() * Math.PI * 2;
            let offsetX = Math.cos(angle) * radius;
            let offsetY = Math.sin(angle) * radius;

            txy.x = xy[s.id].x + offsetX; // 不重叠
            txy.y = xy[s.id].y + offsetY;
        }
        return txy;
    },

    /**
     * 默认vis options
     * @return {*}
     */
    'options': () => {
        return {
            // useDefaultGroups: false, // 当node设置了group但是没有设置groups样式，是否使用vis js默认的groups样式
            // groups: this.GroupStyle.node, // 用户自定义的groups样式
            // layout 布局相关
            'layout': {
                // 配置每次生成的节点位置是否一样，参数为数字1、2等。当不使用分层布局时，节点最初是随机定位的。这意味着每次固定的位置是不同的。如果手动提供一个随机种子，每次布局都是相同的。理想情况下，您尝试使用未定义的种子，重新加载，直到您对布局感到满意，并使用getSeed()方法来确定种子。
                'randomSeed': 1234,
                // 当启用时，网络将使用Kamada Kawai算法进行初始布局。对于大于100个节点的网络，将自动执行聚类以减少节点的数量。这可以大大提高稳定时间。如果网络是非常互联的（没有或很少的叶节点），这可能不起作用，它将恢复到旧的方法。性能将在未来得到改善。
                'improvedLayout': true,
                // 层级结构显示。当为true时，布局引擎使用默认设置以分层方式对节点进行定位。对于定制，您可以提供对象。
                'hierarchical': {
                    'enabled': false,
                    'direction': 'UD',
                    //用于根据数据确定节点级别的算法。可能的选项是：hubsize, directed。Hubsize采取最边缘的节点，并把它们放在最上面。从层次结构的其余部分进行评估。定向粘附到和来自边缘的数据。A–> B SO B是一个低于A的级别。
                    'sortMethod': 'hubsize',
                },


            },
            // 0.9 版节点配置，默认（普通节点）
            'nodes': {
                'size': 10, // 节点shape=image(图形时)定义默认大小
                'borderWidth': 0, // 正常时的边
                'borderWidthSelected': 1, // 选中节点时的边宽度 该数值会累加到borderWidth上
                'color': {
                    // 临时测试
                    // 'border': 'rgba(233,230,228,1)', // 暗灰
                    // 'background': 'rgba(248,249,252,1)', // 浅灰
                    // 节点基本颜色
                    'border': defaultDefine.colors.nodeBorder, // 边框样式
                    // 'background': defaultDefine.colors.level1, // 背景色
                    // 高亮状态（和选中）
                    'highlight': {
                        'border': defaultDefine.colors.highlightNodeBorder,
                        'background': defaultDefine.colors.defaultPrimary,
                    },
                    // 节点悬浮状态
                    'hover': {
                        'border': defaultDefine.colors.highlightNodeBorder,
                        // 'background': defaultDefine.colors.listed0,
                    },
                },
                // 节点默认字体
                'font': {
                    'color': defaultDefine.colors.defaultColor,
                    'background': 'transparent', // 字体背景透明
                    'size': 13, // 默认字体大小
                    // 'background': 'rgba(255,255,255,1)', // 默认背景色，不设置为透明
                    'strokeWidth': 0,
                },
                // 通用 节点的阴影，测试用于高亮时的效果
                'shadow': {
                    'enabled': false,
                    'color': defaultDefine.colors.shadow,
                    'size': 25,
                    'x': 0,
                    'y': 0,
                },
                'chosen': {
                    'node': function (values, id, selected, hovering) {
                        // console.log('values, id, selected, hovering:',values, id, selected, hovering)
                        // rgb(255,255,107) 'bold', 'italic'
                        values.shadow = true; // 选中节点出现阴影
                        // values.borderWidth = 1;
                        // values.borderColor = defaultDefine.colors.highlightNodeBorder;
                    },
                    'label': function (values, id, selected, hovering) {
                        if (selected) {
                            // values.size = 16
                            // values.mod = 'bold'
                            // values.strokeWidth = 3
                            // values.strokeColor = '#87ceeb'

                            // values.strokeColor = defaultDefine.colors.shadow
                            // values.color = '#8e8e8e'

                            // values.color = '#ffff00' // 金黄色
                            // this.background=defaultDefine.colors.defaultPrimary
                        }

                    },
                },
                scaling: {
                    min: 10,
                    max: 100,
                    label: false,
                    customScalingFunction: (min, max, total, value) => {
                        return Math.min(9, Math.log10(value)) / 9;
                    },
                },
            },
            'edges': {
                // 边的弯曲设置
                'smooth': true,
                // 边的有向设置
                'arrows': {
                    'to': {
                        'enabled': false,
                        'scaleFactor': 0.9,
                        'type': 'arrow',
                    },
                },
                // hidden: false, // 是否隐藏，仍然参与布局计算
                /*
                    'scaling': { // 根据edge的value值进行 宽度 缩放
                      'min': 1, // 最小值
                      'max': 20, // 最大值
                      // 自定义缩放规则，必须返回0-1之间的值
                      'customScalingFunction': function (min, max, total, value) {
                      // return 0 // 不做缩放
                      if (max === min) {
                        return 0.5;
                      } else {
                        let scale = 1 / (max - min)
                        return Math.max(0, (value - min) * scale);
                      }
                      },
                      // 'length':1, // 物理弹性之后的长度，尼玛这说法自己都看不懂
                    },
                */
                'color': {
                    // 'inherit': 'from',  // 颜色来自from
                    'inherit': false, // 颜色不取两端边的颜色
                    'color': defaultDefine.colors.edgeColor,
                    // 'highlight': defaultDefine.colors.highlightEdge,
                    // 'hover': defaultDefine.colors.highlightEdge,
                    'opacity': 1,
                },
                'chosen': {
                    'edge': function (values, id, selected, hovering) {
                        if (hovering) {
                            values.opacity = 0.9;
                            values.color = defaultDefine.colors.highlightEdge;
                        }
                        if (selected) {
                            values.opacity = 1;
                            values.color = defaultDefine.colors.highlightEdge;
                        }
                    },
                },

            },
            // 物理(仿真)效应
            'physics': {
                'solver': 'barnesHut',
                'barnesHut': {
                    'gravitationalConstant': -8000,
                    'centralGravity': 0.01, // 中心的引力大小
                    'springLength': 100, // 弹簧的剩余长度
                    'springConstant': 0.03,
                    'damping': 0.1, // 节点移动速度，碰撞、抖动加剧
                    'avoidOverlap': 0, // 不知道什么意思，效果是调节了最小间距节点间的距离
                },
                'forceAtlas2Based': {
                    'gravitationalConstant': -80,
                    'centralGravity': 0.01, // 中心的引力大小
                    'springLength': 100, // 弹簧的剩余长度
                    'springConstant': 0.03,
                    'damping': 0.1, // 节点移动速度，碰撞、抖动加剧
                    'avoidOverlap': 0, // 不知道什么意思，效果是调节了最小间距节点间的距离
                },
                'minVelocity': 0.5, // 一旦达到所有节点的最小速度，我们假设网络已经稳定，仿真停止。
                // 'maxVelocity':50, // 节点最大速度
                'timestep': 0.6, // 节点变化速度
                'stabilization': true,  // 不知道啥玩意儿
            },
            'interaction': {
                'dragNodes': true, // 是否能拖动节点
                'dragView': true, // 是否能拖动画布
                'hideEdgesOnDrag': true, // 拖动画布时是否隐藏连接线
                'hideNodesOnDrag': false, // 拖动画布时是否隐藏节点
                'hover': true, // 鼠标移过后加粗该节点和连接线
                'keyboard': false, // 激活键盘操作画面
                'multiselect': false, // 按 ctrl 多选
                'navigationButtons': false, // 是否显示控制按钮
                'selectable': true, // 是否可以点击选择
                'selectConnectedEdges': true, // 选择节点后是否显示连接线
                'hoverConnectedEdges': false, // 鼠标滑动节点后是否显示连接线
                'tooltipDelay': 200,
                'zoomView': true, // 是否能缩放画布
            },

        };
    },

    /**
     * 节点预先处理
     * 比如做一些测试
     * @param node
     * @param nodeClassFieldName string 节点分类的区别字段
     * @returns {*}
     */
    formatNodeBefore(node, nodeClassFieldName = this.NodeClassFieldName) {
        if (node[nodeClassFieldName] === NODE_TYPE_TEXT) {
            if (node.label) {
                node.fname = node.label;
            }
        }
        node.label = node.fname

        // 人物 在label上加上单位名称
        if (node[nodeClassFieldName] === NODE_TYPE_TALENT) {
            if (node.fname) {
                node.name = node.fname;
            } else {
                node.name = node.label;
            }
            node.label += ' ' + node.org;
        }

        // 企业用短名称作为vis画面节点的标签
        if (node[nodeClassFieldName] === NODE_TYPE_COMPANY) {
            if (node.sname) {
                node.label = node.sname;
            }
        }

        // * 画面节点下面加上tag
        if (node.tag) {
            let tagArr = node.tag.split(' ', 3)
            let tagStr = tagArr.join(' ')
            if (tagStr.length > 0) {
                node.label = node.label + '\n[ ' + tagStr + ' ]';
            }
        }

        return node;
    },

    /**
     * 节点后处理处理
     * 比如做一些测试
     * @param node
     */
    formatNodeAfter(node) {
    },

}

/**
 * 查询节点icon
 *
 * @param node
 * @param config
 * @param lib
 * @param classname
 * @param level
 * @returns {*}
 */
export const getNodeIcon = (node, config = nodesConfig, lib = 'iconfont',
                            classname = myVis.NodeClassFieldName,
                            level = myVis.NodeDivideFieldName) => {
    if (!config[node[classname]]) {
        console.log('未知节点类型');
        return {};
    } else {
        let result = config[node[classname]].items.default;
        let configId = level[node[classname]] + node[level[node[classname]]];
        let nodeType = myVis.getNodeTypeFn(node);
        if (config[node[classname]].items[configId]) {
            result = config[node[classname]].items[configId];
        } else if (nodeType !== node[classname]) {
            return getNodeIconByType(nodeType);
        }
        return getNodeIconFromConfig(result);
    }
};

export const getNodeIconByType = (nodeType, config = nodesConfig) => {
    let result = config[nodeType];
    if (!result) {
        // console.warn('Icon config not found for type: ', nodeType);
        return {};
    }
    return getNodeIconFromConfig(result);
};

/**
 * 用配置生成图标配置对象
 * @param config
 * @return {*}
 */
export const getNodeIconFromConfig = (config) => {
    if (config['icon']) {
        return {
            name: config.icon,
            type: IconTypes.ANT_DESIGN,
            color: config.color,
        };
    } else if (config['iconFont']) {
        return {
            name: config.iconFont,
            type: IconTypes.ICON_FONT,
            color: config.color,
        };
    } else if (config['fa']) {
        return {
            name: config.fa,
            type: IconTypes.FONT_AWESOME,
            color: config.color,
        };
    } else {
        // console.warn('Icon info not found, Config: ', config);
        return {};
    }
};

export const getNodeDisplayTitle = (node, maxLength = -1) => {
    const nodeType = node[TYPE_FIELD_NAME];
    let result = node.fname;
    switch (nodeType) {
        case NODE_TYPE_TALENT:
            result = `${node.fname} ${node.org}`;
            break;
        default:
        // Nothing
    }
    if (result && maxLength > 0) {
        result = result.length > maxLength ? `${result}`.substr(0, maxLength - 1) + '...' : result;
    }
    return result ? result.replace('\u00A0', ' ') : result;
};

export const getExternalLinkUrl = (node) => {
    return `/detail_relation/${node[TYPE_FIELD_NAME]}/${node.id}`;
};
