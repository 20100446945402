import React from 'react';
import PropTypes from 'prop-types';
import {Input} from 'antd';

class MicroServicePanelParameterTextInput extends React.PureComponent {
  componentDidMount() {
    let me = this;

    if (!me.props.locked) {
      me.props.onChange(me.props.value || me.props.defaultValue);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let me = this;

    if (!me.props.locked) {
      if (prevProps.value !== me.props.value) {
        me.props.onShortTextChange(me.props.value || me.props.defaultValue);
      }

      if (prevProps.locked !== me.props.locked) {
        me.props.onChange(me.props.value || me.props.defaultValue);
      } else if (me.props.value === undefined && me.props.defaultValue) {
        me.props.onChange(me.props.defaultValue);
      }
    }
  }

  render() {
    let me = this;

    return (
      <Input.TextArea
        cols={4}
        onChange={e => me.props.onChange(e.target.value)}
        value={me.props.value || me.props.defaultValue}
        disabled={me.props.locked}
      />
    )
  }
}

MicroServicePanelParameterTextInput.defaultProps = {};

MicroServicePanelParameterTextInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  onShortTextChange: PropTypes.func.isRequired,
  locked: PropTypes.bool.isRequired,
  defaultValue: PropTypes.string.isRequired,
  value: PropTypes.string,
};

export default MicroServicePanelParameterTextInput;