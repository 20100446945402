import React from 'react';
import PropTypes from 'prop-types';
import { Badge, Button, Form, Input, message, Modal, Popover, Slider, Tag, Tooltip, Upload } from 'antd';
import AvatarEditor from 'react-avatar-editor';

import PB, { SimplePB } from '@/libs/simplePB';
import { getBase64Url } from '@/utils/Common';
import Icon from '@/components/common/common.icon';
import ViewManager from '@/components/common/common.viewManager';

import style from '@/style/common/view/common.view.info.setInfoModal.less';
import { checkUploadImage } from '@/components/common/common.functions';
import { IconTypes } from '@/constants/common';

const coverWidth = 576;
const coverHeight = coverWidth / 16 * 9;

class setInfoModal extends React.Component {
	constructor(props) {
		super(props);
		let tags = [];
		if (typeof props.viewInfo.tags === 'string') {
			let tagsStr = props.viewInfo.tags ? props.viewInfo.tags.replace(/[\n]/g, ' ') : '';
			tagsStr.split(' ').forEach(item => {
				if (item && item !== ' ') {
					tags.push(item);
				}
			});
		} else if (Array.isArray(props.viewInfo.tags)) {
			tags = props.viewInfo.tags;
		}

		this.state = {
			name: props.viewInfo.name,
			desc: props.viewInfo.desc,
			tags: tags,
			currentTag: '', // 当前输入标签名称
			historyTags: [], // 历史记录标签
			showTagInputTooltip: false,

			status: 'idle',
			showCoverEditor: false,
			uploading: false,
			uploadedCoverImgBase64Url: undefined,
			coverScale: 1,
			coverRotate: 0,
			coverPosition: {x: 0.5, y: 0.5},

			needSave: false, // 是否需要保存操作
		};
	}

	coverEditorRef = undefined;

	addTagInputRef = undefined; // 标签输入框

	componentDidMount() {
		let me = this;
		if (me.props.viewInfo.hasCover === 1) {
			requestAnimationFrame( () => {
				ViewManager.loadViewCover(me.props.viewId).then(coverRes => {
					if (coverRes) {
						me.setState({
							uploadedCoverImgBase64Url: coverRes,
						})
					}
				});
			});
		}
	}

	componentWillUnmount() {
		this.props.bus.remove(this);
	}

	init = callback => {
		let me = this;

		me.setState({
			status: 'idle',
			uploadedCoverImgBase64Url: undefined,
			uploading: false,
			coverScale: 1,
			coverRotate: 0,
		}, callback);
	};

	beforeUpload = file => {
		let me = this;

		if (checkUploadImage(file, message)) {
			getBase64Url(file, imgBase64Url => {
				me.setState({
					status: 'idle',
					uploadedCoverImgBase64Url: imgBase64Url,
					uploading: false,
					coverScale: 1,
					coverRotate: 0,
					needSave: true,
					showCoverEditor: true,
				});
			});
		} else {
			me.setState({uploading: false, needSave: true});
		}
		return false;
	};

	onUploadChange = info => {
		let me = this;

		console.log('InfoSetCoverComponent - onUploadChange: ', info);

		if (info.file.status === 'uploading') {
			me.setState({uploading: true, needSave: true});
		}
	};

	onSave = () => {
		let me = this,
			coverData = me.state.uploadedCoverImgBase64Url;
		const {form} = me.props;
		form.validateFields(async (err, values) => {
			if (!err) {
				console.log('Received values of form: ', values);
				const {name, desc, tags} = values;
				if (me.state.needSave) {
					const canvas = (me.coverEditorRef && me.state.uploadedCoverImgBase64Url) ?
						this.coverEditorRef.getImageScaledToCanvas() : undefined;
					/**
					 * @type {undefined|HTMLCanvasElement}
					 */
					let dstCanvas = undefined;
					if (canvas) {
						// noinspection JSValidateTypes
						dstCanvas = document.createElement('canvas');
						dstCanvas.width = canvas.width;
						dstCanvas.height = canvas.height;
						const ctx = dstCanvas.getContext('2d');
						ctx.fillStyle = '#caccce';
						ctx.fillRect(0, 0, dstCanvas.width, dstCanvas.height);
						ctx.drawImage(canvas, 0, 0);
						coverData = dstCanvas.toDataURL('image/jpeg');
					}
				}

				const view = {
					...me.props.viewInfo,
					name,
					desc,
					tags,
					hasCover: coverData ? 1 : 0,
				};
				let viewRes = undefined;
				if (me.state.needSave) {
					const res = await Promise.all([
						ViewManager.editView(view),
						ViewManager.setViewCover({viewId: me.props.viewId, coverData}),
					]);
					viewRes = res[0];
				} else {
					const res = await ViewManager.editView(view);
					viewRes = res;
				}

				if (viewRes) {
					message.success('保存成功！');
					me.props.bus.emit('view', 'view.info.update', {viewId: me.props.viewId, viewInfo: viewRes});
					me.props.callback && me.props.callback({action: 'edit', viewId: view.viewId, success: true, viewInfo: viewRes, coverData});
					me.props.onClose();
				} else {
					console.log(' common.viewManager->editView->修改图谱信息出错 e= :', );
					message.error('您没有权限或网络不稳定，请尝试重新保存。', 3);
				}
			}
		});
	};

	onTagAdd = (tag) => {
		const {form} = this.props;
		let tags = Array.from(form.getFieldValue('tags'));
		if (!tag) {
			message.error('请输入标签内容');
		} else if (tag.includes(' ')) {
			message.error('标签内容不可含有空格');
		} else if (tags.includes(tag)) {
			message.error('该标签已存在');
			this.setState({currentTag: ''});
		} else {
			tags = tags.concat(tag);

			// 将标签缓存到本地
			let historyTags = localStorage.getItem('historyViewTags') || '';
			if (historyTags) {
				historyTags = historyTags.includes(tag) ? historyTags : `${tag}|${historyTags}`;
			} else{
				historyTags = `${tag}`;
			}

			localStorage.setItem('historyViewTags', historyTags);
			// can use data-binding to set
			form.setFieldsValue({
				tags: tags,
			});
			this.setState({
				tags: tags,
				currentTag: '',
				historyTags: historyTags.split('|'),
			});
		}
	};

	onTagRemove = (i) => {
		const {form} = this.props;
		// can use data-binding to get
		let tags = Array.from(form.getFieldValue('tags'));
		tags.splice(i, 1);

		// can use data-binding to set
		form.setFieldsValue({
			tags: tags,
		});
		this.setState({tags: tags});
	};

	render() {
		let me = this;
		const {form, viewId, visible = {}, viewInfo} = me.props;
		const {getFieldDecorator} = form;
		const formItemLayout = {
			// labelCol: {span: 24},
			// wrapperCol: {span: 24},
		};
		getFieldDecorator('tags', {initialValue: this.state.tags || []});
		return (
			<Modal
				title={<div><Icon name="edit" style={{color: '#a5a5a5', marginRight: 10}}/>基本属性</div>}
				visible={visible}
				okText={'保存'}
				cancelText={'取消'}
				centered={true}
				destroyOnClose={true}
				maskClosable={false}
				width={520}
				onOk={me.onSave}
				onCancel={me.props.onClose}
				wrapClassName={'modal-footer-custom'}
			>
				<Form className={style['custom-form-wrap']}>
					<Form.Item {...formItemLayout}
					           label={(<span>
						           标题
							           <span style={{fontSize: '14px'}}>（会被所有用户搜索到，请避免隐私词汇
									   <Popover content={(
							           <div style={{width: '350px', fontSize: '14px'}}>
								           <p>图谱标题为公开信息，会被所有用户搜索到，请避免隐私词汇的使用。</p>
								           <p style={{margin: 0}}>图谱内容是否公开，由组长在图谱访问设置中进行设置。<br/>
									           （访问设置位置：“菜单 -> 图谱设置 -> 访问设置”）</p>
							           </div>
						           )}
						                    placement="top"
						           ><Icon type={IconTypes.ICON_FONT} name="icon-yibantishi" style={{padding: '0.1rem'}}/></Popover>）</span>
					           </span>)}
					>
						{getFieldDecorator('name', {
							initialValue: me.props.viewInfo.name,
							rules: [{
								required: true, message: '请输入图谱标题',
							}],
						})(
							<Input autoFocus={true}/>
						)}
					</Form.Item>
					<Form.Item {...formItemLayout}
					           label="描述："
					>
						{getFieldDecorator('desc', {
							initialValue: viewInfo.desc,
							rules: [{
								message: '请输入图谱描述',
							}],
						})(
							<Input.TextArea rows={4}
							/>
						)}
					</Form.Item>
					<Form.Item {...formItemLayout}
					           label="标签："
					>
						{
							this.state.tags.map(
								(tag, i) => (
									<Tag key={`tag-${i}`}>
										&nbsp;{tag}&nbsp;
										<a
											className={style['tag-remove']}
											onClick={(e) => {
												e.stopPropagation();
												e.preventDefault();
												this.onTagRemove(i);
											}}
										>
											<Icon name={'delete'}/>
										</a>
									</Tag>
								)
							)
						}
						<Tooltip
							title={
								<div>
									<Input.Search
										placeholder="请输入标签"
										enterButton="添加"
										value={this.state.currentTag}
										ref={inputRef => {me.addTagInputRef = inputRef;}}
										onChange={e => {
											let value = e.target.value;
											let tags = localStorage.getItem('historyViewTags') || '';
											let historyTags = tags && tags.split('|') || [];
											let filterTags = historyTags.filter(item => item.includes(value));
											if (value) {
												this.setState({
													currentTag: e.target.value,
													historyTags: filterTags,
												});
											} else {
												this.setState({
													currentTag: e.target.value,
													historyTags: historyTags,
												});
											}
										}}
										onSearch={this.onTagAdd}
										onKeyDown={event => {
											if (event.key === 'Escape') {
												event.stopPropagation();
												me.setState({showTagInputTooltip: false, currentTag: ''});
											}
										}}
									/>
									{
										me.state.currentTag ? (
											<div className={style['tag-shortcut-wrap']}>
												{
													me.state.historyTags.length > 0 ? (
														<React.Fragment>
															<div className={style['tag-group-title']}>输入匹配：</div>
															<div className={style['tag-group']}>
																{
																	me.state.historyTags.map((item, index) => {
																		if (index < 3) {
																			return (
																				<div key={item} style={{display: 'inline-block', marginBottom: '10px'}}>
																					<Tag
																						style={{cursor: 'pointer'}}
																						onClick={() => {
																							this.onTagAdd(item);
																						}}
																					>
																						{item}
																					</Tag>
																				</div>
																			)
																		}
																	})
																}
															</div>
														</React.Fragment>
													) : null
												}
											</div>
										) : (
											<div className={style['tag-shortcut-wrap']}>
												{
													me.state.historyTags.length > 0 ? (
														<React.Fragment>
															<div className={style['tag-group-title']}>历史记录：</div>
															<div className={style['tag-group']}>
																{
																	me.state.historyTags.map((item, index) => {
																		if (index < 8) {
																			return (
																				<div key={item} style={{display: 'inline-block', marginBottom: '10px', cursor: 'pointer'}}>
																					<Tag onClick={() => {
																						this.onTagAdd(item);
																					}}
																					>
																						{item}
																					</Tag>
																				</div>
																			)
																		}
																	})
																}
															</div>
														</React.Fragment>
													) : null
												}
											</div>
										)
									}
								</div>
							}
							placement="bottom"
							overlayClassName={style['add-tag-tooltip']}
							trigger={'click'}
							visible={me.state.showTagInputTooltip}
							onVisibleChange={ visible => {
								me.setState({ showTagInputTooltip: visible });
								if (visible) {
									requestAnimationFrame(() => {
										if (me.addTagInputRef) {
											me.addTagInputRef.focus();
										}
										let tags = localStorage.getItem('historyViewTags') || '';
										if (tags) {
											me.setState({
												historyTags: tags.split('|'),
											})
										}
									});
								}
							}}
						>
							<Button shape="circle" icon="plus" size={'small'} />
						</Tooltip>
					</Form.Item>
					<Form.Item {...formItemLayout} label="封面：">
						<div style={{display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between'}}>
							<div style={{display: 'flex', flexDirection: 'column'}}>
								<Upload
									name={'file_from_container'}
									multiple={false}
									showUploadList={false}
									// disabled={me.props.disabled}
									beforeUpload={me.beforeUpload}
									onChange={me.onUploadChange}
								>
									<Button>
										<Icon name="picture"/> 选择封面
									</Button>
								</Upload>
								<Button style={{marginTop: 10, marginBottom: me.state.showCoverEditor ? 65 : 10}}
									onClick={() => {
								    // 确认对话框
								    me.setState({
								      showCoverEditor: false,
								      uploadedCoverImgBase64Url: undefined,
								      needSave: true,
								    });
								  }}
								>
									<Icon name="icon-no_picture" type={IconTypes.ICON_FONT}/> 删除封面
								</Button>
							</div>
							{
								me.state.showCoverEditor ? (
									<div style={{textAlign: 'center'}}>
										<AvatarEditor
											image={this.state.uploadedCoverImgBase64Url}
											width={!!(this.state.coverRotate % 180) ? coverHeight / 2 : coverWidth / 2}
											height={!!(this.state.coverRotate % 180) ? coverWidth / 2 : coverHeight / 2}
											position={me.state.coverPosition}
											border={1}
											borderRadius={0}
											color={[0, 0, 0, 0.2]}
											scale={this.state.coverScale}
											rotate={this.state.coverRotate}
											style={{background: 'rgba(0,0,0,0.1)'}}
											onPositionChange={coverPosition => {
												me.setState({coverPosition});
											}}
										/>
										<div style={{
											position: 'absolute',
											userSelect: 'none',
											pointerEvents: 'none',
											top: 0,
											opacity: 0,
											height: 0,
											width: 0,
											overflow: 'hidden',
										}}>
											<AvatarEditor
												ref={ref => this.coverEditorRef = ref}
												image={this.state.uploadedCoverImgBase64Url}
												width={!!(this.state.coverRotate % 180) ? coverHeight : coverWidth}
												height={!!(this.state.coverRotate % 180) ? coverWidth : coverHeight}
												border={2}
												borderRadius={0}
												color={[0, 0, 0, 0.2]}
												scale={this.state.coverScale}
												rotate={this.state.coverRotate}
												style={{background: 'rgba(0,0,0,0.1)'}}
												position={me.state.coverPosition}
											/>
										</div>
										<Slider
											onChange={v => me.setState({coverScale: v})}
											min={0.5}
											max={2}
											step={0.01}
											value={this.state.coverScale}
											style={{width: coverWidth / 2 + 2, margin: '0 auto 0.5rem'}}
										/>
										<div style={{margin: '0 auto'}}>
											<Tooltip placement={'top'} title={'缩小'}>
												<Button
													title={'缩小'}
													icon={'zoom-out'}
													disabled={me.state.coverScale <= 0.5}
													onClick={() => me.setState({
														coverScale: Math.max(me.state.coverScale - 0.1, 0.5),
														needSave: true,
													})}
												/>
											</Tooltip>
											<Tooltip placement={'top'} title={'放大'}>
												<Button
													title={'放大'}
													icon={'zoom-in'}
													disabled={me.state.coverScale >= 2}
													onClick={() => me.setState({
														coverScale: Math.min(me.state.coverScale + 0.1, 2),
														needSave: true,
													})}
												/>
											</Tooltip>
											<Tooltip placement={'top'} title={'左转90°'}>
												<Button
													title={'左转90°'}
													icon={'undo'}
													onClick={() => me.setState({
														coverRotate: (me.state.coverRotate - 90) % 360,
														needSave: true,
													})}
												/>
											</Tooltip>
											<Tooltip placement={'top'} title={'右转90°'}>
												<Button
													title={'右转90°'}
													icon={'redo'}
													onClick={() => me.setState({
														coverRotate: (me.state.coverRotate + 90) % 360,
														needSave: true,
													})}
												/>
											</Tooltip>
										</div>
									</div>
								) : (
									<div style={{textAlign: 'center'}}>
										<AvatarEditor
											ref={ref => this.coverEditorRef = ref}
											image={this.state.uploadedCoverImgBase64Url}
											width={!!(this.state.coverRotate % 180) ? coverHeight / 2 : coverWidth / 2}
											height={!!(this.state.coverRotate % 180) ? coverWidth / 2 : coverHeight / 2}
											border={1}
											borderRadius={0}
											color={[0, 0, 0, 0.2]}
											scale={this.state.coverScale}
											rotate={this.state.coverRotate}
											style={{
												background: 'rgba(0, 0, 0, 0.1)',
											}}
										/>
									</div>
								)
							}

						</div>
					</Form.Item>
				</Form>
			</Modal>
		);
	}
}

const WrappedSetInfoModal = Form.create()(setInfoModal);

WrappedSetInfoModal.defaultProps = {
	visible: false,
	viewInfo: {name: '', desc: '', hasCover: false},
	callback: () => {},
};

WrappedSetInfoModal.propTypes = {
	bus: PropTypes.instanceOf(SimplePB),
	visible: PropTypes.bool.isRequired,
	viewId: PropTypes.string.isRequired,
	onClose: PropTypes.func.isRequired,
	viewInfo: PropTypes.object.isRequired,
	callback: PropTypes.func,
};

export default WrappedSetInfoModal;