import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import EventListener from "react-event-listener";
import {Button, Col, Form, Input, message, Modal, Row, Select, Tooltip, Upload} from 'antd';

import {SimplePB} from "@/libs/simplePB";

import {getBase64Url} from "@/utils/Common";

import {IconTypes} from "@/constants/common";

import CommonHoverContent from "@/components/common/common.hoverContent";
import Icon from "@/components/common/common.icon";
import {checkUploadImage, getDisplayNumber} from "@/components/common/common.functions";

import style from '@/style/common/view/statistics/common.view.statistics.index.less';

class StatisticsIndexLargeImage extends React.Component {
  state = {
    editMode: 'none', // none-未进入编辑状态，background-编辑背景图中，index-编辑指标中

    indexes: [],

    uploadedBackgroundImgBase64Url: undefined, // 用户当前上传的背景图Base64值

    showIndexSettingModal: -1,
    editingIndex: {},
  };

  containerElement = undefined;

  containerBoundingRect = undefined;

  dragging = -1;

  lastX = undefined;

  lastY = undefined;

  indexTitleInput = undefined;

  beforeBackgroundUpload = file => {
    let me = this;

    if (checkUploadImage(file, message)) {
      getBase64Url(file, imgBase64Url => {
        me.setState({
          uploadedBackgroundImgBase64Url: imgBase64Url,
          editMode: 'background',
        });
      });
    }
    return false;
  };

  onClose = () => {
    let me = this;

    if (me.state.editMode === 'none') {
      me.props.bus.emit('statistics.index', 'large_image.hide')
    } else if (me.state.editMode === 'background') {
      Modal.confirm({
        title: '指标图背景已修改，是否立即保存？',
        okText: '是',
        cancelText: '否',
        onOk: () => me.onSaveBackground({
          success: () => me.props.bus.emit('statistics.index', 'large_image.hide'),
        }),
        onCancel: () => {
          me.setState({
            uploadedBackgroundImgBase64Url: undefined,
            editMode: 'none',
          }, () => {
            me.props.bus.emit('statistics.index', 'large_image.hide');
          });
        },
      })
    } else if (me.state.editMode === 'index') {
      Modal.confirm({
        title: '是否保存当前指标设置？',
        okText: '是',
        cancelText: '否',
        onOk: () => me.onSaveIndex({
          success: () => me.props.bus.emit('statistics.index', 'large_image.hide'),
        }),
        onCancel: () => {
          me.setState({
            indexes: [],
            editMode: 'none',
          }, () => {
            me.props.bus.emit('statistics.index', 'large_image.hide');
          });
        },
      })
    }
  };

  onSaveBackground = ({success, failed}) => {
    let me = this;

    me.props.bus.emit('statistics.index', 'config.update.do', {
      viewId: me.props.viewId,
      config: {backgroundImgBase64Url: me.state.uploadedBackgroundImgBase64Url},
      success: () => {
        me.props.bus.emit('statistics.index', 'config.load.do', {
          viewId: me.props.viewId,
          success: () => {
            message.success('指标图背景保存成功');
            me.setState({
              uploadedBackgroundImgBase64Url: undefined,
              editMode: 'none',
            }, () => {
              success && success();
            });
          },
        });
      },
      failed: () => {
        message.error('指标图背景保存失败，请稍后再试');
        failed && failed();
      },
    });
  };

  onSaveIndex = ({success, failed}) => {
    let me = this,
      indexes = me.state.indexes.map(indexConfig => {
        if (indexConfig.posX !== undefined && indexConfig.posY !== undefined) {
          indexConfig.left = indexConfig.posX / me.containerBoundingRect.containerWidth;
          indexConfig.top = indexConfig.posY / me.containerBoundingRect.containerHeight;
        }
        return {
          left: indexConfig.left,
          top: indexConfig.top,
          title: indexConfig.title,
          filter: indexConfig.filter,
          alg: indexConfig.alg,
        };
      });

    me.props.bus.emit('statistics.index', 'config.update.do', {
      viewId: me.props.viewId,
      config: {indexes},
      success: () => {
        me.props.bus.emit('statistics.index', 'config.load.do', {
          viewId: me.props.viewId,
          success: () => {
            message.success('指标设置保存成功');
            me.setState({
              indexes: [],
              editMode: 'none',
            }, () => {
              success && success();
            });
          },
        });
      },
      failed: () => {
        message.error('指标设置保存失败，请稍后再试');
        failed && failed();
      },
    });
  };

  initContainerElementXY = () => {
    let me = this;

    if (me.containerBoundingRect) return;
    if (!me.containerElement) return;

    let containerBoundingClientRect = me.containerElement.getBoundingClientRect();

    me.containerBoundingRect = {
      // 浮层位置范围
      minTop: containerBoundingClientRect.top,
      maxTop: containerBoundingClientRect.top + containerBoundingClientRect.height,
      minLeft: containerBoundingClientRect.left,
      maxLeft: containerBoundingClientRect.left + containerBoundingClientRect.width,
      // 鼠标范围
      minX: containerBoundingClientRect.left,
      maxX: containerBoundingClientRect.left + containerBoundingClientRect.width,
      minY: containerBoundingClientRect.top,
      maxY: containerBoundingClientRect.top + containerBoundingClientRect.height,
      // 容器位置
      containerTop: containerBoundingClientRect.top,
      containerLeft: containerBoundingClientRect.left,
      // 容器大小
      containerWidth: containerBoundingClientRect.width,
      containerHeight: containerBoundingClientRect.height,
    };
  };

  onIndexDragStart = (e, pos) => {
    e.stopPropagation();
    this.dragging = pos;
  };

  onIndexDragging = e => {
    let me = this;
    if (me.dragging < 0) return;
    let currentX = Math.max(me.containerBoundingRect.minX, Math.min(me.containerBoundingRect.maxX, e.clientX));
    let currentY = Math.max(me.containerBoundingRect.minY, Math.min(me.containerBoundingRect.maxY, e.clientY));
    if (me.lastX && me.lastY) {
      let dx = currentX - me.lastX,
        dy = currentY - me.lastY,
        currentLeft = me.state.indexes[me.dragging].posX + me.containerBoundingRect.containerLeft,
        currentTop = me.state.indexes[me.dragging].posY + me.containerBoundingRect.containerTop,
        targetLeft = currentLeft + dx,
        targetTop = currentTop + dy;
      me.state.indexes[me.dragging].posX = Math.max(me.containerBoundingRect.minLeft,
        Math.min(me.containerBoundingRect.maxLeft, targetLeft)) - me.containerBoundingRect.containerLeft;
      me.state.indexes[me.dragging].posY = Math.max(me.containerBoundingRect.minTop,
        Math.min(me.containerBoundingRect.maxTop, targetTop)) - me.containerBoundingRect.containerTop;
      me.forceUpdate();
    }
    me.lastX = currentX;
    me.lastY = currentY;
  };

  onIndexDragEnd = pos => {
    let me = this;

    if (pos !== undefined && pos !== me.dragging) return;
    if (me.dragging < 0) return;

    me.dragging = -1;
    me.lastX = undefined;
    me.lastY = undefined;
    me.forceUpdate();
  };

  componentWillUnmount() {
    this.props.bus.remove(this);
  }

  render() {
    let me = this;
    const formItemLayout = {
      labelCol: { span: 5 },
      wrapperCol: { span: 18 },
    };

    let ctrlPanel = null;
    if (!me.props.readonly) {
      if (me.state.editMode === 'background') {
        ctrlPanel = (
          <div>
            <Button
              size={'large'}
              onClick={() => me.setState({
                uploadedBackgroundImgBase64Url: undefined,
                editMode: 'none',
              })}
            >
              <Icon name={'stop'}/>取消
            </Button>
            <Button size={'large'} onClick={() => me.onSaveBackground({})}>
              <Icon name={'save'}/>保存
            </Button>
          </div>
        );
      } else if (me.state.editMode === 'index') {
        ctrlPanel = (
          <div>
            <Button
              size={'large'}
              onClick={() => me.setState({
                indexes: [],
                editMode: 'none',
              })}
            >
              <Icon name={'stop'}/>取消
            </Button>
            <Button size={'large'} onClick={() => me.onSaveIndex({})}>
              <Icon name={'save'}/>保存
            </Button>
          </div>
        );
      } else {
        ctrlPanel = (
          <div>
            <Button
              size={'large'}
              onClick={() => me.setState({
                indexes: _.map(me.props.config.indexes, _.clone),
                editMode: 'index',
              })}
            >
              <Icon name={'stock'}/>编辑指标
            </Button>
            <Upload
              multiple={false}
              showUploadList={false}
              beforeUpload={me.beforeBackgroundUpload}
            >
              <Tooltip
                title={<span>建议使用宽高比为16:9、透明背景的图片</span>}
                placement={'top'}
              >
                <Button size={'large'}>
                  <Icon name={'picture'}/>修改背景
                </Button>
              </Tooltip>
            </Upload>
          </div>
        )
      }
    }

    return (
      <CommonHoverContent
        onClose={me.onClose}
        showInnerCover={false}
        frameZIndex={2}
        innerCoverZIndex={3}
      >
        <div className={style['large-image-frame-ct']}>
          <div
            className={style['large-image-frame']}
            onClick={e => e.stopPropagation()}
          >
            <div
              className={`${style['large-image-content']} ${me.state.editMode === 'none' ? '' : 'editing'}`}
              ref={ele => {
                if (!ele || ele === me.containerElement) return;
                me.containerElement = ele;
                me.initContainerElementXY();
              }}
            >
              <EventListener
                target={window}
                onMouseMove={e => me.onIndexDragging(e)}
                onMouseUp={() => me.onIndexDragEnd()}
              />
              <div className={style['large-image-background']}>
                {
                  me.state.editMode === 'background' ? (
                    <img alt={'指标图背景'} src={me.state.uploadedBackgroundImgBase64Url} />
                  ) : (
                    <img alt={'指标图背景'} src={me.props.config.backgroundImgBase64Url} />
                  )
                }
              </div>
              {
                (me.state.editMode === 'index' ? me.state.indexes : me.props.config.indexes).map((indexConfig, idx) => (
                  <div
                    key={`box-${idx}`}
                    className={`${style['large-image-index-frame']} ${me.state.editMode === 'index' ? 'editing' : ''}`}
                    onMouseDown={e => me.state.editMode === 'index' && me.onIndexDragStart(e, idx)}
                    onMouseUp={() => me.onIndexDragEnd(idx)}
                    ref={ele => {
                      if (!ele) return;
                      indexConfig.posX = ele.offsetLeft;
                      indexConfig.posY = ele.offsetTop;
                    }}
                    style={{
                      left: indexConfig.posX !== undefined ? `${indexConfig.posX}px` : `${indexConfig.left * 100}%`,
                      top: indexConfig.posY !== undefined ? `${indexConfig.posY}px` : `${indexConfig.top * 100}%`,
                    }}
                  >
                    <div className={style['large-image-index-frame-inner']}>
                      <div>
                        <div
                          className={`${style['large-image-index-move-btn']} ant-btn-sm`}
                        >
                          <Icon name={'icon-move'} theme="outlined" type={IconTypes.ICON_FONT} style={{marginRight: '8px'}}/>
                          按住拖动
                        </div>
                        <div className={style['large-image-index-content']}>
                          <h4>{indexConfig.title}</h4>
                          <span>{me.props.values[idx] === false ? '--'/*<Icon name={'loading'} />*/ : getDisplayNumber(me.props.values[idx], 2)}</span>
                        </div>
                        <div className={style['large-image-index-edit-btn']}>
                          <Button
                            block={true}
                            size={'small'}
                            onClick={() => me.setState({
                              showIndexSettingModal: idx,
                              editingIndex: _.clone(indexConfig),
                            })}
                          >
                            <Icon name={'setting'}/>参数设置
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              }
              <div
                className={`${style['large-image-ctrl-panel']} ${me.state.editMode === 'none' ? '' : 'editing'}`}
              >
                {ctrlPanel}
              </div>
              <Modal
                title={'指标参数设置'}
                centered={true}
                visible={me.state.showIndexSettingModal >= 0}
                width={'37rem'}
                onOk={() => {
                  if (!me.state.editingIndex.title) {
                    Modal.error({
                      title: '指标名称不能为空',
                      onOk: () => setTimeout(() => me.indexTitleInput && me.indexTitleInput.focus(), 200),
                    });
                  } else {
                    me.state.indexes[me.state.showIndexSettingModal] = _.clone(me.state.editingIndex);
                    me.setState({showIndexSettingModal: -1, editingIndex: {}});
                  }
                }}
                onCancel={() => {me.setState({showIndexSettingModal: -1, editingIndex: {}})}}
                destroyOnClose={true}
                okText={'确定'}
                cancelText={'取消'}
              >
                <Form.Item label={'指标名称'} {...formItemLayout}>
                  <Input
                    ref={ele => me.indexTitleInput = ele}
                    autoFocus={true}
                    onChange={e => {
                      me.state.editingIndex.title = e.target.value;
                      me.forceUpdate();
                    }}
                    value={me.state.editingIndex.title}
                  />
                </Form.Item>
                <Form.Item label={'筛选范围'} {...formItemLayout}>
                  <Input.TextArea
                    rows={10}
                    value={me.state.editingIndex.filter}
                    placeholder={'将自动筛选出名称中包含这些词的节点，空格隔开的词表示必须同时存在。例如：\n\n将要 %\n预计 实现\n即将 吨\n打算\n计划\n准备\n初步考虑\n估计'}
                    onChange={e => {
                      me.state.editingIndex.filter = e.target.value;
                      me.forceUpdate();
                    }}
                  />
                </Form.Item>
                <Form.Item label={'计算方法'} {...formItemLayout}>
                  <Row>
                    <Col span={24}>
                      <Select
                        value={me.state.editingIndex.alg}
                        style={{ width: '100%' }}
                        onChange={v => {
                          me.state.editingIndex.alg = v;
                          me.forceUpdate();
                        }}
                      >
                        <Select.Option value={0}>数值求平均</Select.Option>
                        <Select.Option value={1}>数值求和</Select.Option>
                        <Select.Option value={3}>命中节点数</Select.Option>
                      </Select>
                    </Col>
                    <Col span={24}>
                      <Icon name={'info-circle'} style={{marginRight: '0.5em'}} />需要定制算法请联系客服 hi@joinmap.ai
                    </Col>
                  </Row>
                </Form.Item>
              </Modal>
            </div>
          </div>
        </div>
      </CommonHoverContent>
    );
  }
}

StatisticsIndexLargeImage.defaultProps = {
  config: {},
  values: [],
  readonly: true,
};

StatisticsIndexLargeImage.propTypes = {
  bus: PropTypes.instanceOf(SimplePB).isRequired,
  viewId: PropTypes.string.isRequired,
  loadingStatus: PropTypes.string.isRequired,
  config: PropTypes.object,
  values: PropTypes.array,
  readonly: PropTypes.bool,
};

export default StatisticsIndexLargeImage;