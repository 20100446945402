import React from 'react';
import PropTypes from 'prop-types';
import { message } from 'antd';
import PB from "@/libs/simplePB";

import style from '@/style/containers/dashboard.less';
import viewStyle from '@/style/components/dashboard/dashboard.view.less';
import ToolbarWithButtons from '@/components/dashboard/dashboard.toolbarwithbuttons';
import { Col } from 'antd';
import QueueAnim from 'rc-queue-anim';
import ViewInfoCard from '@/components/dashboard/dashboard.viewInfoCard';
import { bindUtil } from '@/libs/core-decorators';
import ViewManager from '@/components/common/common.viewManager';
import { autoSetState, withReactStateHelper } from '@/libs/react-state-helper';
import Icon from '@/components/common/common.icon';
import {
	AccessibleViewListLoadingStatus,
	PublicViewListLoadingStatus,
	UserIsMasterViewListLoadingStatus,
	UserIsMemberViewListLoadingStatus,
} from "@/libs/view/ViewManager";
import CreateViewModal from '@/components/common/view/common.view.info.createModal';
import LatestViews from '@/components/dashboard/dashboard.latestView';
import {API_GetVideoList} from "@/api/system";
import VideoInfoCard from '@/components/dashboard/dashboard.videoInfoCard';
import intl from 'react-intl-universal';

// 每页加载的数据
const loadItemsPerStep = 30;

@withReactStateHelper
@bindUtil.asTargetClass
class ViewsList extends React.Component {
	// 页面数据模块
	module = 'all';
	// 页面数据 专题频道
	channel = 0;

	// 我的图谱数据
	@autoSetState
	@bindUtil.bindToProperty(ViewManager.dataProvider, 'userViewList')
	ViewsForMy = [];

	// 我是组长图谱数据
	@autoSetState
	@bindUtil.bindToProperty(ViewManager.dataProvider, 'userIsMasterViewList')
	ViewsForMyIsMaster = [];

	// 我是成员图谱数据
	@autoSetState
	@bindUtil.bindToProperty(ViewManager.dataProvider, 'userIsMemberViewList')
	ViewsForMyIsMember = [];

	// 协作图谱数据
	@autoSetState
	@bindUtil.bindToProperty(ViewManager.dataProvider, 'teamworkViewList')
	ViewsForTeam = [];

	// 在售图谱数据(免费+收费)
	@autoSetState
	@bindUtil.bindToProperty(ViewManager.dataProvider, 'publishedViewList')
	ViewsForPublish = [];

	// 公开图谱数据
	@autoSetState
	@bindUtil.bindToProperty(ViewManager.dataProvider, 'publicViewList')
	ViewsForPublic = [];

	// 可访问的图谱数据
	@autoSetState
	@bindUtil.bindToProperty(ViewManager.dataProvider, 'accessibleViewList')
	ViewsForAccessible = [];

	@autoSetState
	@bindUtil.bindToProperty(ViewManager.dataProvider, 'publishedFreeViewList')
	ViewsForPublicOfFree = [];

	// 事件关联图谱数据
	@autoSetState
	@bindUtil.bindToProperty(ViewManager.dataProvider, 'eventcorrelationViewList')
	ViewsForEventcorrelation = [];

	@autoSetState
	@bindUtil.bindToProperty(ViewManager.dataProvider, 'leaderspeechViewList')
	ViewsForLeaderspeech = [];

	@autoSetState
	@bindUtil.bindToProperty(ViewManager.dataProvider, 'majorprojectViewList')
	ViewsForMajorproject = [];
	
	@autoSetState
	@bindUtil.bindToProperty(ViewManager.dataProvider, 'advancedmodelViewList')
	ViewsForAdvancedmodel = [];
	
	@autoSetState
	@bindUtil.bindToProperty(ViewManager.dataProvider, 'achievementViewList')
	ViewsForAchievement = [];
	
	@autoSetState
	@bindUtil.bindToProperty(ViewManager.dataProvider, 'partypaperViewList')
	ViewsForPartypaper = [];

	@autoSetState
	@bindUtil.bindToProperty(ViewManager.dataProvider, 'defaultViewList')
	ViewsForDefault = [];

	@autoSetState
	@bindUtil.bindToProperty(ViewManager.dataProvider, 'accessibleViewListLoadingStatus')
	accessibleViewListLoadingStatus = {status: AccessibleViewListLoadingStatus.IDLE};

	@autoSetState
	@bindUtil.bindToProperty(ViewManager.dataProvider, 'userIsMasterViewListLoadingStatus')
	userIsMasterViewListLoadingStatus = {status: UserIsMasterViewListLoadingStatus.IDLE};

	@autoSetState
	@bindUtil.bindToProperty(ViewManager.dataProvider, 'userIsMemberViewListLoadingStatus')
	userIsMemberViewListLoadingStatus = {status: UserIsMemberViewListLoadingStatus.IDLE};

	@autoSetState
	@bindUtil.bindToProperty(ViewManager.dataProvider, 'publicViewListLoadingStatus')
	publicViewListLoadingStatus = {status: PublicViewListLoadingStatus.IDLE};

	// 展示的数据 我的图谱
	filteredViewList = undefined;
	refreshQueueAnim = (new Date()).getTime();

	// 展示的数据 我的图谱
	// filteredViewsForMy = [];
	// 展示的数据 协作图谱
	// filteredViewsForTeam = [];
	// 展示的数据 上架图谱
	// filteredViewsForPublish = [];

	pageStart = 0;
	pageFreeStep = loadItemsPerStep * 3; // 开放复制是从公开中筛选出来的数据，因此请求数量需要增加

	constructor(props) {
		super(props);

		this.state = {
			// 标记异步获取数据的结果状态
			isAjaxResultStatus: false,
			// 展示的数据量
			displayAmount: loadItemsPerStep - 2,
			// 图谱卡片的打开模式
			isReadOnly: true,
			hasMore: true, // 是否还有更多数据
			showCreateViewModal: false, // 新建图谱弹框是否显示
		};
	}

	componentDidMount() {
		let me = this;
		// 调起新建看板
		PB.sub(this, "dashboard", "dashboard.createViewModal.show", ({ status }) => {
			me.setState({
				showCreateViewModal: !!status,
			})
		  });

		this.refresh();
	}

	componentWillReceiveProps(nextProps, nextContext) {
		// this.refresh();
	}

	componentWillUnmount = () => {
        this.setState = (state,callback)=>{
          return;
        };
    } 

	/**
	 * 加载页面数据（数据流）
	 */
	refresh = async () => {
		console.log('views->refresh->this.props:', this.props);
		// 设置模块
		this.module = this.props.module || 'all';
		// 设置专题频道
		this.channel = this.props.channel || 0;
		// 加载数据
		this.showMore(true);
	};

	/**
	 * ??
	 * @param action
	 * @param viewId
	 * @param viewInfo
	 */
	onViewItemUpdated = ({action}) => {
		switch (action) {
			case 'edit':
				this.refresh();
				break;
			case 'sale':
				break;
			case 'cancel_sale':
				this.refresh();
				break;
			default:
				this.refresh();
		}
	};

	showMore = (isFirstLoad = false) => {
		let me = this, limit = loadItemsPerStep;
		// 登录用户id
		const currentUserId = localStorage.getItem('userId');
		if (isFirstLoad) {
			this.pageStart = 0;
			this.ViewsForPublic = [];
			limit--;
			if (this.module === 'my' || this.module === 'all') {
				limit--;
			}
		}
		switch (this.module) {
			case 'public':
				// 加载公开图谱
				ViewManager.loadPublicViewList({q: '', start: this.pageStart, limit, channel: this.channel}).then(res => {
					this.ViewsForPublic = this.ViewsForPublic.concat(res);
					this.filteredViewList = me.ViewsForPublic;
					this.pageStart = this.pageStart + limit;
					this.setState({displayAmount: this.state.displayAmount + loadItemsPerStep, hasMore: res.length === limit});
				}).catch(err => {
					console.log(intl.get('Custom.message.loadFail'),err);
					message.info(intl.get('Custom.message.loadFail'));
				});
				break;
			case 'cooperation':
				// 加载公开图谱
				ViewManager.loadPublicViewList({q: '', start: this.pageStart, limit, teamwork_type: 1, channel: this.channel}).then(res => {
					this.ViewsForPublic = this.ViewsForPublic.concat(res);
					this.filteredViewList = me.ViewsForPublic;
					this.pageStart = this.pageStart + limit;
					this.setState({displayAmount: this.state.displayAmount + loadItemsPerStep, hasMore: res.length === limit});
				}).catch(err => {
					console.log(intl.get('Custom.message.loadFail'),err);
					message.info(intl.get('Custom.message.loadFail'));
				});
				break;
			case 'free':
				ViewManager.loadPublishedViewOfFreeList({q: '', start: this.pageStart, limit: this.pageFreeStep, channel: this.channel}).then(res => {
					this.ViewsForPublicOfFree = this.ViewsForPublicOfFree.concat(res);
					this.filteredViewList = me.ViewsForPublicOfFree;
					this.pageStart = this.pageStart + this.pageFreeStep;
					this.setState({displayAmount: this.state.displayAmount + loadItemsPerStep, hasMore: res.length === this.pageFreeStep});
				}).catch(err => {
					console.log(intl.get('Custom.message.loadFail'),err);
					message.info(intl.get('Custom.message.loadFail'));
				});
				break;
			case 'teamwork':
				// 加载我是成员
				ViewManager.loadUserIsMemberViewList(currentUserId, {q: '', start: this.pageStart, limit, channel: this.channel}).then(res => {
					this.ViewsForMyIsMember = this.ViewsForMyIsMember.concat(res);
					this.filteredViewList = me.ViewsForMyIsMember;
					this.pageStart = this.pageStart + limit;
					this.setState({displayAmount: this.state.displayAmount + loadItemsPerStep, hasMore: res.length === limit});
				}).catch(err => {
					console.log(intl.get('Custom.message.loadFail'),err);
					message.info(intl.get('Custom.message.loadFail'));
				});
				break;
			case 'my':
				// 加载我是组长
				ViewManager.loadUserIsMasterViewList(currentUserId, {q: '', e: intl.get('Custom.view.eventMap'), start: this.pageStart, limit, channel: this.channel}).then(res => {
					let res_my = res.filter(item => {
						return item.name.indexOf(intl.get('Custom.view.eventMap')) === -1;
					})
					this.ViewsForMyIsMaster = this.ViewsForMyIsMaster.concat(res_my);
					this.filteredViewList = me.ViewsForMyIsMaster;
					this.pageStart = this.pageStart + limit;
					//if(this.filteredViewList.length==0){
					//	this.showMore();
					//}
					console.error(me.ViewsForMyIsMaster)
					this.setState({displayAmount: this.state.displayAmount + loadItemsPerStep, hasMore: res.length === limit});
				}).catch(err => {
					console.log(intl.get('Custom.message.loadFail'),err);
					message.info(intl.get('Custom.message.loadFail'));
				});
				break;
			case 'eventcorrelation':
				// 加载事件关联图谱
				ViewManager.loadUserIsMasterViewList(currentUserId, {q: intl.get('Custom.view.eventMap'), start: this.pageStart, limit, channel: this.channel}).then(res => {
					this.ViewsForMyIsMaster = this.ViewsForMyIsMaster.concat(res);
					this.filteredViewList = me.ViewsForMyIsMaster;
					this.pageStart = this.pageStart + limit;
					this.setState({displayAmount: this.state.displayAmount + loadItemsPerStep, hasMore: res.length === limit});
				}).catch(err => {
					console.log(intl.get('Custom.message.loadFail'),err);
					message.info(intl.get('Custom.message.loadFail'));
				});
				break;
			/*case 'leaderspeech':
				// 加载领导讲话图谱
				ViewManager.loadPublicViewListAll({q: '', e: intl.get('Custom.view.eventMap'), start: this.pageStart, limit, channel: this.channel}).then(res => {
					this.ViewsForLeaderspeech = this.ViewsForLeaderspeech.concat(res);
					this.filteredViewList = me.ViewsForLeaderspeech;
					this.pageStart = this.pageStart + limit;
					this.setState({displayAmount: this.state.displayAmount + loadItemsPerStep, hasMore: res.length === limit});
				}).catch(err => {
					console.log(' 加载更多数据失败 ->  :',err);
					message.info('加载更多数据失败，请稍后再试');
				});
				break;
			case 'majorproject':
				ViewManager.loadPublicViewListAll({q: '', e: intl.get('Custom.view.eventMap'), start: this.pageStart, limit, channel: this.channel}).then(res => {
						this.ViewsForMajorproject = this.ViewsForMajorproject.concat(res);
						this.filteredViewList = me.ViewsForMajorproject;
						this.pageStart = this.pageStart + limit;
						this.setState({displayAmount: this.state.displayAmount + loadItemsPerStep, hasMore: res.length === limit});
				}).catch(err => {
						console.log(' 加载更多数据失败 ->  :',err);
						message.info('加载更多数据失败，请稍后再试');
				});
				break;
			case 'advancedmodel':
				ViewManager.loadPublicViewListAll({q: '', e: intl.get('Custom.view.eventMap'), start: this.pageStart, limit, channel: this.channel}).then(res => {
					this.ViewsForAdvancedmodel = this.ViewsForAdvancedmodel.concat(res);
					this.filteredViewList = me.ViewsForAdvancedmodel;
					this.pageStart = this.pageStart + limit;
					this.setState({displayAmount: this.state.displayAmount + loadItemsPerStep, hasMore: res.length === limit});
				}).catch(err => {
					console.log(' 加载更多数据失败 ->  :',err);
					message.info('加载更多数据失败，请稍后再试');
				});
				break;
			case 'achievement':
				ViewManager.loadPublicViewListAll({q: '', e: '的事件关联图谱', start: this.pageStart, limit, channel: this.channel}).then(res => {
					this.ViewsForAchievement = this.ViewsForAchievement.concat(res);
					this.filteredViewList = me.ViewsForAchievement;
					this.pageStart = this.pageStart + limit;
					this.setState({displayAmount: this.state.displayAmount + loadItemsPerStep, hasMore: res.length === limit});
				}).catch(err => {
					console.log(' 加载更多数据失败 ->  :',err);
					message.info('加载更多数据失败，请稍后再试');
				});
				break;
			case 'partypaper':
				ViewManager.loadPublicViewListAll({q: '', e: '的事件关联图谱', start: this.pageStart, limit, channel: this.channel}).then(res => {
					this.ViewsForPartypaper = this.ViewsForPartypaper.concat(res);
					this.filteredViewList = me.ViewsForPartypaper;
					this.pageStart = this.pageStart + limit;
					this.setState({displayAmount: this.state.displayAmount + loadItemsPerStep, hasMore: res.length === limit});
				}).catch(err => {
					console.log(' 加载更多数据失败 ->  :',err);
					message.info('加载更多数据失败，请稍后再试');
				});
				break;
			*/
			case 'latest':
				/*
				// 加载事件关联图谱
				ViewManager.loadAccessibleViewList({q: '', start: 0, limit:5}).then(res => {
					this.ViewsForAccessible = this.ViewsForAccessible.concat(res);
					this.filteredViewList = me.ViewsForAccessible;
					this.pageStart = this.pageStart + limit;
					this.setState({displayAmount: this.state.displayAmount + loadItemsPerStep, hasMore: res.length === limit});
				}).catch(err => {
					console.log(' 加载更多数据失败 ->  :', );
					message.info('加载更多数据失败，请稍后再试');
				});
				*/
				break;
			case 'all':
				// 默认 all = 可访问的图谱
				ViewManager.loadAccessibleViewList({q: '', start: this.pageStart, limit, channel: this.channel}).then(res => {
					this.ViewsForAccessible = this.ViewsForAccessible.concat(res);
					this.filteredViewList = me.ViewsForAccessible;
					this.pageStart = this.pageStart + limit;
					this.setState({displayAmount: this.state.displayAmount + loadItemsPerStep, hasMore: res.length === limit});
				}).catch(err => {
					console.log(intl.get('Custom.message.loadFail'),err);
					message.info(intl.get('Custom.message.loadFail'));
				});
				break;
			case 'video':
				this.filteredViewList = [];
				this.pageStart = 0;
				API_GetVideoList().then(response => {
					this.videoList = JSON.parse(JSON.stringify(response.data));
					this.setState({displayAmount: 2});
				  }).catch(error => {
					console.warn(error);
				  });
				
				break;
			default:
				ViewManager.loadPublicViewListAll({q: '', e: intl.get('Custom.view.eventMap'), start: this.pageStart, limit, channel: this.channel}).then(res => {
					me.ViewsForPublic = me.ViewsForPublic.concat(res);
					this.filteredViewList = me.ViewsForPublic;
					this.pageStart = this.pageStart + limit;
					this.setState({displayAmount: this.state.displayAmount + loadItemsPerStep, hasMore: res.length === limit});
				}).catch(err => {
					console.log(intl.get('Custom.message.loadFail'),err);
					message.info(intl.get('Custom.message.loadFail'));
				});
		}
	};

	render() {
		let me = this;
		const { contentWrapSpacing } = me.props;

		return (
			<div className={style['container']}>
				<div className={style['content'] + ' scrollbar scrollbar-none'}>
					<div className={`${style['published-view']} ${viewStyle['container']}`}>
						<ToolbarWithButtons
							// className={style["business-toolbar"]}
							buttons={
								this.props.navOptionList.map(({name, path}) => ({
									name,
									active: path === me.props.history.location.pathname,
									onClick: () => {
										document.title = `${name} - ${intl.get('Custom.general.title')}`;
										if (path === this.props.currentPath) {
											this.refresh();
										} else {
											this.props.history.push(path);
										}
									},
								}))
							}
							// divClassName={mainViewStyle["search-toolbar"]}
							history={this.props.history}
							module={this.module}
							refresh={this.refresh}
							contentWrapSpacing={contentWrapSpacing}
							showCreateViewModal={ () => {
								me.setState({
									showCreateViewModal: true,
								})
							}}
						/>
						<QueueAnim duration={[250, 0]}
						           interval={[30, 0]}
						           className={viewStyle['cards-container']}
						           style={{margin: `0 ${contentWrapSpacing}px`}}
						           key={me.refreshQueueAnim}
						>
							{
								this.module === 'my' || this.module === 'all'? (
									<div key={'new-view'}>
										<div  className={viewStyle['view-card-wrap']}>
											<div
												className={viewStyle['add-btn'] + ' add-btn'}
												onClick={() => {
													me.setState({
														showCreateViewModal: true,
													})
												}}
											>
												<Icon name='plus'/>
												<div>{intl.get('Custom.view.add')}</div>
											</div>
										</div>
									</div>
								) : ''
							}
							{this.module === 'latest' && (
								<LatestViews
									showCornerTip={true}
									module={this.module}
									networkRef={this.props.networkRef}
								/>
							)}
							{this.module === 'video' && this.videoList && this.videoList.map((view,index) => (
								<VideoInfoCard
									showCornerTip={true}
									callback={this.onViewItemUpdated}
									view={view}
									key={`video-${index}`}
									module={this.module}
								/>
							))}
							{this.module !== 'latest' && this.module !== 'video' && this.filteredViewList && this.filteredViewList.slice(0, this.state.displayAmount).map(view => (
								<ViewInfoCard
									readOnly={this.state.isReadOnly && parseInt(view['teamworkType']) !== 1}
									showCornerTip={true}
									callback={this.onViewItemUpdated}
									view={view}
									key={`view-${view.viewId}`}
									module={this.module}
								/>
							))}
							{this.module !== 'latest' && this.module !== 'video' && this.filteredViewList && this.state.hasMore ? (
									<Col key={`view-more-${Math.random()}`} md={24} lg={24} xl={24}
									     className={viewStyle['view-card-wrap']}>
										<div
											className={viewStyle['more-btn']}
											onClick={() => this.showMore()}
										>
											<Icon name='appstore'/>
											<div>{intl.get('Custom.general.load')}{intl.get('Custom.general.more')}</div>
										</div>
									</Col>
								) : null
							}
						</QueueAnim>
						{
							this.module !== 'video' && (this.filteredViewList && this.filteredViewList.slice(0, this.state.displayAmount).length === 0 ? (
								(
									(
										this.props.module === 'all' && (
											this.accessibleViewListLoadingStatus.status === AccessibleViewListLoadingStatus.IDLE
											|| this.accessibleViewListLoadingStatus.status === AccessibleViewListLoadingStatus.PROCESSING
										)
									) || (
										this.props.module === 'my' && (
											this.userIsMasterViewListLoadingStatus.status === UserIsMasterViewListLoadingStatus.IDLE
											|| this.userIsMasterViewListLoadingStatus.status === UserIsMasterViewListLoadingStatus.PROCESSING
										)
									) || (
										this.props.module === 'teamwork' && (
											this.userIsMemberViewListLoadingStatus.status === UserIsMasterViewListLoadingStatus.IDLE
											|| this.userIsMemberViewListLoadingStatus.status === UserIsMasterViewListLoadingStatus.PROCESSING
										)
									) || (
										(this.props.module === 'free' || this.props.module === 'public' || this.props.module === 'cooperation' || this.props.module === 'leaderspeech' || this.props.module === 'majorproject'
											|| this.props.module === 'advancedmodel' || this.props.module === 'achievement' || this.props.module === 'partypaper' || this.props.module === 'demonstration' 
											|| this.props.module === 'scientific' || this.props.module === 'leaderreceiveguests' || this.props.module === 'guestspeech') && (
											this.publicViewListLoadingStatus.status === PublicViewListLoadingStatus.IDLE
											|| this.publicViewListLoadingStatus.status === PublicViewListLoadingStatus.PROCESSING
										)
									)
								) ? null : (
									!this.state.isAjaxResultStatus ? (
										<Col key={'none-view'} md={24} lg={24} xl={24}>
											<div className={viewStyle['view-none']}  style={{height: this.props.module === 'my' ? '10rem' : '30rem', alignItems: 'center', justifyContent: 'center'}}>
												<div>{intl.get('Custom.view.addInfo')}</div>
											</div>
										</Col>
									) : null
								)
							) : null)
						}
					</div>
				</div>

				<div
					className={style['add-view-fixed-btn']}
					style={me.channel > 0?{display:"none"}:{}}
					onClick={() => {
						me.setState({
							showCreateViewModal: true,
						})
					}}
				>
					{/*<Icon name='plus'/>*/}
					<div className={style['text']}>{intl.get('Custom.general.creat')}<br/>{intl.get('Custom.view.name')}</div>
				</div>

				<CreateViewModal
					visible={me.state.showCreateViewModal}
					onClose={() => me.setState({showCreateViewModal: false})}
					callback={res => {
						const {action, success, viewInfo} = res;
						if (action === 'create' && success) {
							if (this.module === 'my') {
								me.refresh();
							} else {
								if (me.channel === 0) {
									me.props.history.push('/mainview/dashboard/userview');
								}
							}
							window.open(`/mainview/relation/${viewInfo.viewId}`, '_blank');
						}
					}}
					params={{channel: this.channel}}
				/>
			</div>
		);
	}
}

ViewsList.propTypes = {
	history: PropTypes.object, // router的参数
	navOptionList: PropTypes.array, // 顶部菜单列表
	className: PropTypes.string, // css class
	title: PropTypes.string, // 页面标题
	module: PropTypes.string, // 页面数据模块名 my=我的图谱，teamwork=协作图谱，published=上架图谱，public=公开图谱 all=可访问的图谱
	channel: PropTypes.number, // 频道参数
	contentWrapSpacing: PropTypes.number, // 计算左右间距
	networkRef: PropTypes.object,
};

export default (props) => <ViewsList {...props} key={props.history.location.pathname}/>;
