import React from 'react';
import PropTypes from 'prop-types';

import PB, {SimplePB} from "@/libs/simplePB";

import style from '@/style/containers/exploreRelationView.less';

class ExploreRelationPlugin extends React.Component {
  state = {
    showWizard: false,
    viewId: undefined,
    srcNodeId: undefined,
  };

  nodeInfo = undefined;

  onWizardFinished = (/*{withTags, selectedResourceType, selectedNode}*/) => {
    let me = this;

    // me.setState({showWizard: false}, () => {
    const param = btoa(unescape(encodeURIComponent(JSON.stringify({
      // t: withTags,
      p: me.nodeInfo && me.nodeInfo.meta ? me.nodeInfo.meta.preferredType : undefined,
      n: me.nodeInfo, // selectedNode,
      s: me.state.srcNodeId,
    })))).replace(/\//g, '$');

    window.open(
      `/explore_relation/${me.state.viewId}/${me.nodeInfo.id}/${me.nodeInfo.type}/${param}`,
      '_blank'
    );
    // });
  };

  componentDidMount() {
    let me = this;

    me.props.bus.sub(me, 'explore_relation', 'show_wizard', ({viewId, nodeInfo}) => {
      /*if (!me.state.showWizard) {
        me.nodeInfo = nodeInfo;
        me.setState({showWizard: true, viewId, srcNodeId: me.props.srcNodeId || nodeInfo.id});
      }*/
      me.nodeInfo = nodeInfo;
      me.setState({viewId, srcNodeId: me.props.srcNodeId || nodeInfo.id},
        () => me.onWizardFinished());
    });
  }

  componentWillUnmount() {
    this.props.bus.remove(this);
  }

  render() {
    // let me = this;

    return (
      <div className={style['plugin-container']} />
    );
  }
}

ExploreRelationPlugin.defaultProps = {
  bus: PB,
};

ExploreRelationPlugin.propTypes = {
  bus: PropTypes.instanceOf(SimplePB),
  srcNodeId: PropTypes.string,
};

export default ExploreRelationPlugin;