import React from 'react';
import PropTypes from 'prop-types';
import {Drawer} from 'antd';

import PB, {SimplePB} from '@/libs/simplePB';

import PresentationLogic from '@/components/common/view/presentation/common.view.presentation.logic';
import PresentationConfigList from '@/components/common/view/presentation/common.view.presentation.configList';
import PresentationConfigEditPanel from '@/components/common/view/presentation/common.view.presentation.configEditPanel';
import PresentationConfigDetailPanel
  from '@/components/common/view/presentation/common.view.presentation.configDetailPanel';

import style from '@/style/common/view/common.view.presentation.less';
import ViewDataProvider from "@/components/common/dataProvider/common.dataProvider.view";

class PresentationDrawer extends React.PureComponent {
  state = {
    showDrawer: false,
    config: undefined,
  };

  nodeFilterOutShow = outShow => {
    this.props.bus.emit('relation', 'node_filter.out.show', {outShow});
  };

  componentDidMount() {
    let me = this;

    me.props.bus.with(me).subscribe('presentation', 'config.list.show_drawer', ({viewId}) => {
      if (me.props.viewDataProvider.viewId !== viewId) return;
      if (me.state.showDrawer !== 'list') {
        me.setState({showDrawer: 'list', config: undefined});
        me.nodeFilterOutShow(false);
      }
    }).subscribe('presentation', 'config.list.hide_drawer', ({viewId}) => {
      if (me.props.viewDataProvider.viewId !== viewId) return;
      if (me.state.showDrawer) {
        me.setState({showDrawer: false, config: undefined});
        me.nodeFilterOutShow(true);
      }
    }).subscribe('presentation', 'config.add.show_modal', ({viewId}) => {
      if (me.props.viewDataProvider.viewId !== viewId) return;
      if (me.state.showDrawer && me.state.showDrawer !== 'add') {
        me.setState({showDrawer: 'add', config: undefined});
        me.nodeFilterOutShow(false);
      }
    }).subscribe('presentation', 'config.update.show_modal', ({viewId, config}) => {
      if (me.props.viewDataProvider.viewId !== viewId) return;
      if (me.state.showDrawer && me.state.showDrawer !== 'update') {
        me.setState({showDrawer: 'update', config});
        me.nodeFilterOutShow(false);
      }
    }).subscribe('presentation', 'config.info.show_modal', ({viewId, config}) => {
      if (me.props.viewDataProvider.viewId !== viewId) return;
      if (me.state.showDrawer && me.state.showDrawer !== 'info') {
        me.setState({showDrawer: 'info', config});
        me.nodeFilterOutShow(false);
      }
    });
  }

  componentWillUnmount() {
    this.props.bus.remove(this);
  }

  render() {
    let me = this, drawerContent;
    switch (me.state.showDrawer) {
      case 'add':
        drawerContent = (
          <PresentationConfigEditPanel
            bus={me.props.bus}
            viewDataProvider={me.props.viewDataProvider}
            activatedNodeList={me.props.activatedNodeList}
          />
        );
        break;
      case 'update':
        drawerContent = (
          <PresentationConfigEditPanel
            bus={me.props.bus}
            viewDataProvider={me.props.viewDataProvider}
            config={me.state.config}
          />
        );
        break;
      case 'info':
        drawerContent = (
          <PresentationConfigDetailPanel
            bus={me.props.bus}
            viewDataProvider={me.props.viewDataProvider}
            config={me.state.config}
          />
        );
        break;
      default:
        drawerContent = (
          <PresentationConfigList
            bus={me.props.bus}
            readOnly={me.props.readOnly}
            viewId={me.props.viewDataProvider.viewId}
            viewOwnerId={me.props.viewOwnerId}
            currentUserId={me.props.currentUserId}
          />
        );
    }

    return (
      <div className={style['container']}>
        <PresentationLogic
          bus={me.props.bus}
          viewId={me.props.viewDataProvider.viewId}
          viewDataProvider={me.props.viewDataProvider}
          viewOwnerId={me.props.viewOwnerId}
          currentUserId={me.props.currentUserId}
        />
        <Drawer
          onClose={() => {me.setState({showDrawer: false});me.nodeFilterOutShow(true);}}
          visible={!!me.state.showDrawer}
          width={'28rem'}
          placement='left'
          destroyOnClose={true}
          mask={false}
          className={`${style['config-drawer']} dark-theme`}
          bodyStyle={{height: '100%'}}
        >
          {drawerContent}
        </Drawer>
      </div>
    );
  }
}

PresentationDrawer.defaultProps = {
  bus: PB,
  readOnly: true,
};

PresentationDrawer.propTypes = {
  viewDataProvider: PropTypes.instanceOf(ViewDataProvider).isRequired,
  viewOwnerId: PropTypes.number.isRequired,
  currentUserId: PropTypes.number.isRequired,
  bus: PropTypes.instanceOf(SimplePB),
  readOnly: PropTypes.bool,
  activatedNodeList: PropTypes.array
};

export default PresentationDrawer;