import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {Modal, Alert, Tooltip, Steps, Collapse, Form, Input, Radio, Checkbox, Select, Button, InputNumber, Row, Col} from 'antd';

import PB, {SimplePB} from '@/libs/simplePB';

import CHINA_ADC from '@/constants/china_adc.2018.json';
import {IconTypes} from '@/constants/common';

import {strToFunc} from '@/components/common/common.functions';
import Icon from '@/components/common/common.icon';
import {
  optionNames
} from '@/components/common/view/microService/panel/parameter/common.view.microService.panel.parameter.duration';

const TARGET_LABEL_NODE_AND_FILTERED_NODES = '根据节点';
const TARGET_LABEL_TEXT_OR_URL = '请输入一段文本或一个网页URL';
const TARGET_LABEL_VIEW = '根据当前图谱';
const TARGET_LABEL_FILES = '根据上传的文件';

const DEFAULT_NODE_LABEL = '根据当前选中的节点';
const DEFAULT_NODE_SHORT_TEXT = '选中节点';
const DEFAULT_NODE_DYNAMIC_LABEL = "function dynamicLabel(param) {\n    return param['node'] ? '根据当前选中的节点（' + param['node']['displayTitle6'] + '）' : '根据当前选中的节点';\n}";
const DEFAULT_NODE_DYNAMIC_SHORT_TEXT = "function dynamicShortText(param) {\n    return param['node'] ? '节点：' + param['node']['displayTitle6'] : '--';\n}";
const DEFAULT_FILTERED_NODES_LABEL = '根据当前图谱中的全部节点';
const DEFAULT_FILTERED_NODES_SHORT_TEXT = '全部节点';
const DEFAULT_FILTERED_NODES_DYNAMIC_LABEL = "function dynamicLabel(param) {\n  return param['filteredNodes'] ? '根据当前图谱中的全部节点（共' + param['filteredNodes']['nodesLength'] + '个）' : '根据当前图谱中的全部节点';\n}";
const DEFAULT_FILTERED_NODES_DYNAMIC_SHORT_TEXT = "function dynamicShortText(param) {\n  return param['filteredNodes'] ? '全部节点（' + param['filteredNodes']['nodesLength'] + '个）' : '全部节点';\n}";
const DEFAULT_FILTERED_NODES_FILTER = "function filter() {\n  return true;\n}";
const DEFAULT_TEXT_OR_URL_LABEL = '文本或URL';
const DEFAULT_TEXT_OR_URL_SHORT_TEXT = '文本或URL';
const DEFAULT_TEXT_OR_URL_DYNAMIC_LABEL = "function dynamicLabel(param) {\n  return param['textOrUrl']['text'] ? ('文本：' + param['textOrUrl']['text']['textLength'] + '字') : (param['textOrUrl']['url'] ? 'URL' : '--');\n}";
const DEFAULT_TEXT_OR_URL_DYNAMIC_SHORT_TEXT = "function dynamicShortText(param) {\n  return param['textOrUrl']['text'] ? ('文本：' + param['textOrUrl']['text']['textLength'] + '字') : (param['textOrUrl']['url'] ? 'URL' : '--');\n}";
const DEFAULT_VIEW_LABEL = '根据当前图谱';
const DEFAULT_VIEW_SHORT_TEXT = '图谱';
const DEFAULT_VIEW_DYNAMIC_LABEL = "function dynamicLabel(param) {\n  return param['view'] ? '根据当前图谱（' + param['view']['displayTitle6'] + '）' : '根据当前图谱';\n}";
const DEFAULT_VIEW_DYNAMIC_SHORT_TEXT = "function dynamicShortText(param) {\n  return param['view'] ? '图谱：' + param['view']['displayTitle6'] : '--';\n}";
const DEFAULT_FILES_LABEL = '根据上传的文件';
const DEFAULT_FILES_SHORT_TEXT = '文件';
const DEFAULT_FILES_DYNAMIC_LABEL = "function dynamicLabel(param) {\n  return param['files'] ? '根据上传的文件（' + param['files']['filesLength'] + '个）' : '根据上传的文件';\n}";
const DEFAULT_FILES_DYNAMIC_SHORT_TEXT = "function dynamicShortText(param) {\n  return param['files'] ? '文件：' + param['files']['filesLength'] + '个' : '--';\n}";

const TYPE_NAMES = {
  '0': {type: 0, name: '一般'},
  '1': {type: 1, name: '企业'},
  '2': {type: 2, name: '人物'},
  '3': {type: 3, name: '专利'},
  '4': {type: 4, name: '论文'},
  '5': {type: 5, name: '政策'},
  '6': {type: 6, name: '协会'},
  '7': {type: 7, name: '院所'},
  '8': {type: 8, name: '新闻'},
  '9': {type: 9, name: '标签'},
  '10': {type: 10, name: '文档'},
  '11': {type: 11, name: '数据'},
  '12': {type: 12, name: '政府'},
  '13': {type: 13, name: '自然'},
  '14': {type: 14, name: '指标'},
  '15': {type: 15, name: '图表'},
  '16': {type: 16, name: '高校'},
  '17': {type: 17, name: '园区'},
  '18': {type: 18, name: '技术'},
  '19': {type: 19, name: '项目'},
  'type_0_goal': {type: 'type_0_goal', name: '旗帜'},
  'prepare': {type: 'prepare', name: '准备'},
  'doing': {type: 'doing', name: '进行'},
  'finish': {type: 'finish', name: '完成'},
  'good': {type: 'good', name: '点赞'},
  'bad': {type: 'bad', name: '点踩'},
  'watch': {type: 'watch', name: '围观'},
  'no_icon': {type: 'no_icon', name: '无标记'},
  'individual': {type: 'individual', name: '孤立'},
  'connectedToMine': {type: 'connectedToMine', name: '连我'},
  'selected': {type: 'selected', name: '选中'},
  'today': {type: 'today', name: '今天'},
  'yesterday': {type: 'yesterday', name: '昨天'},
  'dby': {type: 'dby', name: '前天'},
};

const INITIAL_STATE = {
  version: '2.0.0',

  currentStep: 0,

  showAdvancedSettings: false,

  // request - simple
  requestTargetLimit: 10,
  requestResultLimit: 100,

  // target - complex
  targetFormLayout: '{}',
  targetLabel: TARGET_LABEL_NODE_AND_FILTERED_NODES,
  targetDefault: 'auto',

  // target - builtinGroups
  targetBuiltinGroup: 'nodeAndFilteredNodes',

  // target - node
  targetNodeEnabled: true,
  targetNodeLabelType: 'autoDynamic', // autoStatic、autoDynamic、customStatic、customDynamic 下同
  targetNodeShortTextType: 'autoDynamic',
  targetNodeLabel: DEFAULT_NODE_LABEL,
  targetNodeShortText: DEFAULT_NODE_SHORT_TEXT,
  targetNodeDynamicLabel: DEFAULT_NODE_DYNAMIC_LABEL,
  targetNodeDynamicShortText: DEFAULT_NODE_DYNAMIC_SHORT_TEXT,

  // target - filteredNodes
  targetFilteredNodesEnabled: true,
  targetFilteredNodesLabelType: 'autoDynamic',
  targetFilteredNodesShortTextType: 'autoDynamic',
  targetFilteredNodesLabel: DEFAULT_FILTERED_NODES_LABEL,
  targetFilteredNodesShortText: DEFAULT_FILTERED_NODES_SHORT_TEXT,
  targetFilteredNodesDynamicLabel: DEFAULT_FILTERED_NODES_DYNAMIC_LABEL,
  targetFilteredNodesDynamicShortText: DEFAULT_FILTERED_NODES_DYNAMIC_SHORT_TEXT,
  targetFilteredNodesFilterType: 'builtIn', // builtIn、custom
  targetFilteredNodesFilter: DEFAULT_FILTERED_NODES_FILTER,
  targetFilteredNodesFilterByType: false,
  targetFilteredNodesFilterByTypeAllowedTypes: [],
  targetFilteredNodesFilterByAuth: false,

  // target - textOrUrl
  targetTextOrUrlEnabled: false,
  targetTextOrUrlLabelType: 'autoDynamic',
  targetTextOrUrlShortTextType: 'autoDynamic',
  targetTextOrUrlLabel: DEFAULT_TEXT_OR_URL_LABEL,
  targetTextOrUrlShortText: DEFAULT_TEXT_OR_URL_SHORT_TEXT,
  targetTextOrUrlDynamicLabel: DEFAULT_TEXT_OR_URL_DYNAMIC_LABEL,
  targetTextOrUrlDynamicShortText: DEFAULT_TEXT_OR_URL_DYNAMIC_SHORT_TEXT,

  // target - view
  targetViewEnabled: false,
  targetViewLabelType: 'autoDynamic',
  targetViewShortTextType: 'autoDynamic',
  targetViewLabel: DEFAULT_VIEW_LABEL,
  targetViewShortText: DEFAULT_VIEW_SHORT_TEXT,
  targetViewDynamicLabel: DEFAULT_VIEW_DYNAMIC_LABEL,
  targetViewDynamicShortText: DEFAULT_VIEW_DYNAMIC_SHORT_TEXT,

  // target - files
  targetFilesEnabled: false,
  targetFilesLabelType: 'autoDynamic',
  targetFilesShortTextType: 'autoDynamic',
  targetFilesLabel: DEFAULT_FILES_LABEL,
  targetFilesShortText: DEFAULT_FILES_SHORT_TEXT,
  targetFilesDynamicLabel: DEFAULT_FILES_DYNAMIC_LABEL,
  targetFilesDynamicShortText: DEFAULT_FILES_DYNAMIC_SHORT_TEXT,

  // parameter - complex
  parameterFormLayout: '{}',
  parameterItems: [],
  parameterActiveKeys: [],

  // operations - saveNodes
  operationSaveNodesEnabled: false,
  operationSaveNodesLineType: 'auto',
  operationSaveNodesLineStyle: 'auto',
  operationSaveNodesArrangeBy: 'star',
  operationSaveNodesClassifyBy: 'none',
  operationSaveNodesCategories: [],
  operationSaveNodesOrders: [],
  operationSaveNodesCopyTextKey: 'fname',

  // operations - saveEdges
  operationSaveEdgesEnabled: false,
  operationSaveEdgesLineType: 'auto',
  operationSaveEdgesLineStyle: 'auto',
  operationSaveEdgesCopyTextKey: 'fname',

  // operations - saveSubGraphs
  operationSaveSubGraphsEnabled: false,
  operationSaveSubGraphsLineType: 'auto',
  operationSaveSubGraphsLineStyle: 'auto',
  operationSaveSubGraphsInnerLineType: 'auto',
  operationSaveSubGraphsInnerLineStyle: 'auto',
  operationSaveSubGraphsArrangeBy: 'star',
  operationSaveSubGraphsClassifyBy: 'none',
  operationSaveSubGraphsCategories: [],
  operationSaveSubGraphsOrders: [],
  operationSaveSubGraphsCopyTextKey: 'fname',

  // operations - saveContent
  operationSaveContentEnabled: false,
  operationSaveContentContentParts: ['all'],
  operationSaveContentCopyTextKey: 'fname',

  // operations - showWordCloud
  operationShowWordCloudEnabled: false,
  operationShowWordCloudKvLabel: '',
  operationShowWordCloudCopyTextKey: 'fname',

  // operations - saveFiles
  operationSaveFilesEnabled: false,
  operationSaveFilesCopyTextKey: 'fname',

  // operations - showCharts
  operationShowChartsEnabled: false,
  operationShowChartsUrl: '',
  operationShowChartsCopyTextKey: 'fname',

  // operations - showOutLink
  operationShowOutLinkEnabled: false,
  operationShowOutLinkUrl: '',
  operationShowOutLinkCopyTextKey: 'fname',

  // operations - showImplement
  operationShowImplementEnabled: false,
  operationShowImplementCopyTextKey: 'fname',
};

const ERRORS = {
  target: {
    invalidFormLayout: '目标表单布局设置 - 无效，请参考AntD中表单部分的formItemLayout！',
    emptyLabel: '目标选择提示文本 - 不可为空！',
    invalidDefault: '默认目标选项 - 无效！',
  },
  parameter: {
    invalidFormLayout: '参数表单布局设置 - 无效，请参考AntD中表单部分的formItemLayout！',
  },
  operation: {
    saveContent: {
      emptyContentParts: '修改节点 - 可保存的节点内容类型 - 不可为空！',
    },
    showWordCloud: {
      emptyKVLabel: '展示词云 - K-V类型数据在树节点中展示的标题 - 不可为空！',
    },
    showCharts: {
      emptychartUrl: '展示图表 - 图表展示页URL - 不可为空！',
    },
    showOutLink: {
      emptyOutLinkUrl: '展示结果 - 结果展示页URL - 不可为空！',
    },
    emptyOperation: '微服务操作 - 不可为空！',
  },
};

const TXT_MAP = {
  node: '当前选中节点',
  filteredNodes: '当前选中节点',
  textOrUrl: '当前选中节点',
  view: '当前选中节点',
  files: '当前选中节点',
  saveNodes: '添加节点',
  saveEdges: '添加关系',
  saveSubGraphs: '添加子图',
  saveContent: '修改节点',
  showWordCloud: '展示词云',
  saveFiles: '下载文件',
  emptyLabel: '选项展示文本内容 - 不可为空！',
  emptyShortText: '选项概述文本内容 - 不可为空！',
  invalidDynamicLabel: '选项展示文本函数 - 无效！',
  invalidDynamicShortText: '选项概述文本函数 - 无效！',
  emptyCopyTextKey: '复制文本路径 - 不可为空！',
  showCharts: '展示图表',
  showOutLink: '展示结果',
  showImplement: '直接执行'
};

const TARGET_CHECK_MAP = {
  node: {
    enabled: 'targetNodeEnabled',
    labelType: 'targetNodeLabelType',
    shortTextType: 'targetNodeShortTextType',
    label: 'targetNodeLabel',
    shortText: 'targetNodeShortText',
    dynamicLabel: 'targetNodeDynamicLabel',
    dynamicShortText: 'targetNodeDynamicShortText',
  },
  filteredNodes: {
    enabled: 'targetFilteredNodesEnabled',
    labelType: 'targetFilteredNodesLabelType',
    shortTextType: 'targetFilteredNodesShortTextType',
    label: 'targetFilteredNodesLabel',
    shortText: 'targetFilteredNodesShortText',
    dynamicLabel: 'targetFilteredNodesDynamicLabel',
    dynamicShortText: 'targetFilteredNodesDynamicShortText',
  },
  textOrUrl: {
    enabled: 'targetTextOrUrlEnabled',
    labelType: 'targetTextOrUrlLabelType',
    shortTextType: 'targetTextOrUrlShortTextType',
    label: 'targetTextOrUrlLabel',
    shortText: 'targetTextOrUrlShortText',
    dynamicLabel: 'targetTextOrUrlDynamicLabel',
    dynamicShortText: 'targetTextOrUrlDynamicShortText',
  },
  view: {
    enabled: 'targetViewEnabled',
    labelType: 'targetViewLabelType',
    shortTextType: 'targetViewShortTextType',
    label: 'targetViewLabel',
    shortText: 'targetViewShortText',
    dynamicLabel: 'targetViewDynamicLabel',
    dynamicShortText: 'targetViewDynamicShortText',
  },
  files: {
    enabled: 'targetFilesEnabled',
    labelType: 'targetFilesLabelType',
    shortTextType: 'targetFilesShortTextType',
    label: 'targetFilesLabel',
    shortText: 'targetFilesShortText',
    dynamicLabel: 'targetFilesDynamicLabel',
    dynamicShortText: 'targetFilesDynamicShortText',
  },
};

const OPERATION_CHECK_MAP = {
  saveNodes: {
    enabled: 'operationSaveNodesEnabled',
    copyTextKey: 'operationSaveNodesCopyTextKey',
  },
  saveEdges: {
    enabled: 'operationSaveEdgesEnabled',
    copyTextKey: 'operationSaveEdgesCopyTextKey',
  },
  saveSubGraphs: {
    enabled: 'operationSaveSubGraphsEnabled',
    copyTextKey: 'operationSaveSubGraphsCopyTextKey',
  },
  saveContent: {
    enabled: 'operationSaveContentEnabled',
    copyTextKey: 'operationSaveContentCopyTextKey',
  },
  showWordCloud: {
    enabled: 'operationShowWordCloudEnabled',
    copyTextKey: 'operationShowWordCloudCopyTextKey',
  },
  saveFiles: {
    enabled: 'operationSaveFilesEnabled',
    copyTextKey: 'operationSaveFilesCopyTextKey',
  },
  showCharts: {
    enabled: 'operationShowChartsEnabled',
    copyTextKey: 'operationShowChartsCopyTextKey',
  },
  showOutLink: {
    enabled: 'operationShowOutLinkEnabled',
    copyTextKey: 'operationShowOutLinkCopyTextKey',
  },
  showImplement: {
    enabled: 'operationShowImplementEnabled',
    copyTextKey: 'operationShowImplementCopyTextKey',
  },
};

['node', 'filteredNodes', 'textOrUrl', 'view', 'files'].forEach(type => {
  ERRORS.target[type] = ERRORS.target[type] || {};
  ['emptyLabel', 'emptyShortText', 'invalidDynamicLabel', 'invalidDynamicShortText'].forEach(error => {
    ERRORS.target[type][error] = `${TXT_MAP[type]} - ${TXT_MAP[error]}`;
  });
});

['saveNodes', 'saveEdges', 'saveSubGraphs', 'saveContent', 'showWordCloud', 'saveFiles', 'showCharts', 'showOutLink', 'showImplement'].forEach(type => {
  ERRORS.operation[type] = ERRORS.operation[type] || {};
  ERRORS.operation[type]['emptyCopyTextKey'] = `${TXT_MAP[type]} - ${TXT_MAP['emptyCopyTextKey']}`;
});

class MicroServiceModalUiConfigWizard extends React.Component {
  state = {...INITIAL_STATE};

  addParameterAt = idx => {
    let me = this, parameterItems = [...me.state.parameterItems],
      parameterActiveKeys = [...me.state.parameterActiveKeys],
      key = `p-${Math.random()}`;

    parameterItems.splice(idx, 0, {
      key,
      name: '',
      type: 'textInput',
      label: '',
      'default': '',
    });
    parameterActiveKeys.push(key);

    me.setState({
      parameterItems,
      parameterActiveKeys,
    });
  };

  checkStepTarget = () => {
    let me = this, content = '';

    try {
      JSON.parse(me.state.targetFormLayout);
    } catch (ignored) {
      Modal.error({content: ERRORS.target.invalidFormLayout});
      return false;
    }

    if (!(`${me.state.targetLabel}`.trim())) {
      Modal.error({content: ERRORS.target.emptyLabel});
      return false;
    }

    switch (me.state.targetDefault) {
      case 'node':
        if (!me.state.targetNodeEnabled) {
          content = ERRORS.target.invalidDefault;
        }
        break;
      case 'filteredNodes':
        if (!me.state.targetFilteredNodesEnabled) {
          content = ERRORS.target.invalidDefault;
        }
        break;
      case 'textOrUrl':
        if (!me.state.targetTextOrUrlEnabled) {
          content = ERRORS.target.invalidDefault;
        }
        break;
      case 'view':
        if (!me.state.targetViewEnabled) {
          content = ERRORS.target.invalidDefault;
        }
        break;
      case 'files':
        if (!me.state.targetFilesEnabled) {
          content = ERRORS.target.invalidDefault;
        }
        break;
    }
    if (content) {
      Modal.error({content});
      return false;
    }

    Object.keys(TARGET_CHECK_MAP).find(k => {
      if (me.state[TARGET_CHECK_MAP[k].enabled]) {
        if (['autoStatic', 'customStatic'].includes(me.state[TARGET_CHECK_MAP[k].labelType])) {
          // 检查label
          if (!(`${me.state[TARGET_CHECK_MAP[k].label]}`).trim()) {
            content = ERRORS.target[k].emptyLabel;
            return true;
          }
        } else {
          // 检查dynamicLabel函数
          if (!(`${me.state[TARGET_CHECK_MAP[k].dynamicLabel]}`).trim()) {
            content = ERRORS.target[k].invalidDynamicLabel;
            return true;
          }
          try {
            strToFunc((`${me.state[TARGET_CHECK_MAP[k].dynamicLabel]}`).trim());
          } catch (ignored) {
            content = ERRORS.target[k].invalidDynamicLabel;
            return true;
          }
        }
        if (['autoStatic', 'customStatic'].includes(me.state[TARGET_CHECK_MAP[k].shortTextType])) {
          // 检查shortText
          if (!(`${me.state[TARGET_CHECK_MAP[k].shortText]}`).trim()) {
            content = ERRORS.target[k].emptyShortText;
            return true;
          }
        } else {
          // 检查dynamicShortText函数
          if (!(`${me.state[TARGET_CHECK_MAP[k].dynamicShortText]}`).trim()) {
            content = ERRORS.target[k].invalidDynamicShortText;
            return true;
          }
          try {
            strToFunc((`${me.state[TARGET_CHECK_MAP[k].dynamicShortText]}`).trim());
          } catch (ignored) {
            content = ERRORS.target[k].invalidDynamicShortText;
            return true;
          }
        }
      }
      return false;
    });
    if (content) {
      Modal.error({content});
      return false;
    }

    return true;
  }

  checkStepParameter = () => {
    let me = this;

    try {
      JSON.parse(me.state.targetFormLayout);
    } catch (ignored) {
      Modal.error({content: ERRORS.parameter.invalidFormLayout});
      return false;
    }

    return me.state.parameterItems.length === 0 || undefined === me.state.parameterItems.find((p, idx) => {
      if (!`${p.label}`.trim()) {
        Modal.error({content: `参数${idx + 1} - 参数标题文本（中文）- 不能为空！`});
        return true;
      }
      if (!`${p.name}`.trim()) {
        Modal.error({content: `参数${idx + 1} - 参数字段名称（字母+数字+下划线）- 不能为空！`});
        return true;
      }
      if (!/^[a-zA-Z0-9_]+$/.test(`${p.name}`.trim())) {
        Modal.error({content: `参数${idx + 1} - 参数字段名称（字母+数字+下划线）- 无效！`});
        return true;
      }
      switch (p.type) {
        case 'textInput':
          return false;
        case 'radioGroup':
        case 'checkboxGroup':
          if (p.optionSpan > 24 || p.optionSpan < 1) {
            Modal.error({content: `参数${idx + 1} - 参数选项span值 - 无效！`});
            return true;
          }
          if ((p.options || []).length <= 0) {
            Modal.error({content: `参数${idx + 1} - 参数选项 - 不能为空！`});
            return true;
          }

          // 检查所有参数项，汇总有效参数value
          let values = [];
          if (undefined !== p.options.find((option, optIdx) => {
            if (!`${option.label}`.trim()) {
              Modal.error({content: `参数${idx + 1} - 参数选项${optIdx + 1} - 选项文本 - 不能为空！`});
              return true;
            }
            if (option.value !== 0 && !option.value) {
              Modal.error({content: `参数${idx + 1} - 参数选项${optIdx + 1} - 选项值 - 不能为空！`});
              return true;
            }
            if (values.includes(option.value)) {
              Modal.error({content: `参数${idx + 1} - 参数选项${optIdx + 1} - 选项值 - 重复！`});
              return true;
            }
            values.push(option.value);
            return false;
          })) {
            return true;
          }

          // 检查默认值
          let defaultValues = _.isArray(p['default']) ? [...p['default']]
            : (p['default'] === undefined || p['default'] === '' ? [] : [p['default']]);
          return undefined !== defaultValues.find(v => {
            if (!values.includes(v)) {
              Modal.error({content: `参数${idx + 1} - 参数默认值${v} - 无效！`});
              return true;
            }
            return false;
          });
        case 'duration':
          if (p.optionSpan > 24 || p.optionSpan < 1) {
            Modal.error({content: `参数${idx + 1} - 参数选项span值 - 无效！`});
            return true;
          }
          if ((p.enabledOptions || []).length <= 0) {
            Modal.error({content: `参数${idx + 1} - 参数可选选项 - 不能为空！`});
            return true;
          }
          if (p['default'] !== undefined && !p.enabledOptions.includes(p['default'])) {
            Modal.error({content: `参数${idx + 1} - 参数默认值 ${optionNames[p['default']]} - 无效！`});
            return true;
          }
          return false;
        case 'location':
          if (p.optionSpan > 24 || p.optionSpan < 1) {
            Modal.error({content: `参数${idx + 1} - 参数选项span值 - 无效！`});
            return true;
          }
          return undefined !== p['default'].find((adc, idx) => {
            if (idx > 0) {
              if (p['default'][idx - 1] === adc) {
                Modal.error({content: `参数${idx + 1} - 参数默认值${adc} - 无效！`});
                return true;
              }
              let parent = _.trimEnd(p['default'][idx - 1], '0');
              if (!_.startsWith(adc, parent)) {
                Modal.error({content: `参数${idx + 1} - 参数默认值${adc} - 无效！`});
                return true;
              }
            }
            if (!CHINA_ADC[adc]) {
              Modal.error({content: `参数${idx + 1} - 参数默认值${adc} - 无效！`});
              return true;
            }
          });
        default:
          Modal.error({content: `参数${idx + 1} - 参数类型 - 无效！`});
          return true;
      }
    });
  }

  checkStepOperation = () => {
    let me = this, hasOperationEnabled = false;

    if (undefined !== Object.keys(OPERATION_CHECK_MAP).find(k => {
      if (me.state[OPERATION_CHECK_MAP[k].enabled]) {
        hasOperationEnabled = true;
        if (me.state[OPERATION_CHECK_MAP[k].copyTextKey] !== false) {
          if (!`${me.state[OPERATION_CHECK_MAP[k].copyTextKey]}`.trim()) {
            Modal.error({content: ERRORS.operation[k].emptyCopyTextKey});
            return true;
          }
        }
      }
      return false;
    })) {
      return false;
    }

    if (me.state.operationSaveContentEnabled && me.state.operationSaveContentContentParts.length === 0) {
      Modal.error({content: ERRORS.operation.saveContent.emptyContentParts});
      return false;
    }

    if (me.state.operationShowWordCloudEnabled && `${me.state.operationShowWordCloudKvLabel}`.trim() === '') {
      Modal.error({content: ERRORS.operation.showWordCloud.emptyKVLabel});
      return false;
    }
    if (me.state.operationShowChartsEnabled && `${me.state.operationShowChartsUrl}`.trim() === '') {
      Modal.error({content: ERRORS.operation.showCharts.emptychartUrl});
      return false;
    }
    if (me.state.operationShowOutLinkEnabled && `${me.state.operationShowOutLinkUrl}`.trim() === '') {
      Modal.error({content: ERRORS.operation.showOutLink.emptyOutLinkUrl});
      return false;
    }
    if (!hasOperationEnabled) {
      Modal.error({content: ERRORS.operation.emptyOperation});
      return false;
    }

    return true;
  }

  onClose = () => {
    let me = this;

    Modal.confirm({
      content: '是否确认放弃本次修改？',
      okText: '是',
      cancelText: '否',
      onOk: () => {
        me.props.onClose();
      }
    })
  }

  onSave = () => {
    let me = this, config = {
      version: me.state.version,
      request: {
        targetLimit: me.state.requestTargetLimit,
        resultLimit: me.state.requestResultLimit,
      },
      target: {
        formLayout: JSON.parse(me.state.targetFormLayout),
        label: `${me.state.targetLabel}`.trim(),
        'default': me.state.targetDefault === 'auto' ? undefined : me.state.targetDefault,
        items: [],
      },
      parameter: {
        formLayout: JSON.parse(me.state.parameterFormLayout),
        items: [],
      },
      operations: [],
    };

    // target.items

    let fn = ({type, enabledKey, labelType, label, dynamicLabel, shortTextType, shortText, dynamicShortText}) => {
      if (me.state[enabledKey]) {
        let itemConfig = {type};
        [
          [labelType, label, dynamicLabel, 'label', 'dynamicLabel'],
          [shortTextType, shortText, dynamicShortText, 'shortText', 'dynamicShortText']
        ].forEach(([type, text, dynamicText, textKey, dynamicTextKey]) => {
          if (['autoStatic', 'customStatic'].includes(me.state[type])) {
            // static
            itemConfig[textKey] = `${me.state[text]}`.trim();
          } else {
            // dynamic
            itemConfig[dynamicTextKey] = `${me.state[dynamicText]}`.trim();
          }
        });
        config.target.items.push(itemConfig);
      }
    };

    fn({
      type: 'node',
      enabledKey: 'targetNodeEnabled',
      labelType: 'targetNodeLabelType',
      label: 'targetNodeLabel',
      dynamicLabel: 'targetNodeDynamicLabel',
      shortTextType: 'targetNodeShortTextType',
      shortText: 'targetNodeShortText',
      dynamicShortText: 'targetNodeDynamicShortText',
    });

    if (me.state.targetFilteredNodesEnabled) {
      let itemConfig = {type: 'filteredNodes'};
      [[
        'targetFilteredNodesLabelType',
        'targetFilteredNodesLabel',
        'targetFilteredNodesDynamicLabel',
        'label',
        'dynamicLabel',
      ], [
        'targetFilteredNodesShortTextType',
        'targetFilteredNodesShortText',
        'targetFilteredNodesDynamicShortText',
        'shortText',
        'dynamicShortText',
      ]].forEach(([type, text, dynamicText, textKey, dynamicTextKey]) => {
        if (['autoStatic', 'customStatic'].includes(me.state[type])) {
          // static
          itemConfig[textKey] = `${me.state[text]}`.trim();
        } else {
          // dynamic
          itemConfig[dynamicTextKey] = `${me.state[dynamicText]}`.trim();
        }
      });
      itemConfig.filter = `${me.state.targetFilteredNodesFilter}`.trim();
      config.target.items.push(itemConfig);
    }

    fn({
      type: 'textOrUrl',
      enabledKey: 'targetTextOrUrlEnabled',
      labelType: 'targetTextOrUrlLabelType',
      label: 'targetTextOrUrlLabel',
      dynamicLabel: 'targetTextOrUrlDynamicLabel',
      shortTextType: 'targetTextOrUrlShortTextType',
      shortText: 'targetTextOrUrlShortText',
      dynamicShortText: 'targetTextOrUrlDynamicShortText',
    });

    fn({
      type: 'view',
      enabledKey: 'targetViewEnabled',
      labelType: 'targetViewLabelType',
      label: 'targetViewLabel',
      dynamicLabel: 'targetViewDynamicLabel',
      shortTextType: 'targetViewShortTextType',
      shortText: 'targetViewShortText',
      dynamicShortText: 'targetViewDynamicShortText',
    });

    fn({
      type: 'files',
      enabledKey: 'targetFilesEnabled',
      labelType: 'targetFilesLabelType',
      label: 'targetFilesLabel',
      dynamicLabel: 'targetFilesDynamicLabel',
      shortTextType: 'targetFilesShortTextType',
      shortText: 'targetFilesShortText',
      dynamicShortText: 'targetFilesDynamicShortText',
    });

    // parameter.items

    me.state.parameterItems.forEach(parameter => {
      let paramConfig = {
        name: `${parameter.name}`.trim(),
        type: parameter.type,
        label: `${parameter.label}`.trim(),
      };
      switch (parameter.type) {
        case 'textInput':
          if (parameter['default']) {
            paramConfig['default'] = parameter['default'];
          }
          break;
        case 'checkboxGroup':
        case 'radioGroup':
          if (parameter.type === 'checkboxGroup') {
            if (parameter.required === true) {
              paramConfig.required = true;
            }
            paramConfig['default'] = paramConfig['default'];
          } else {
            paramConfig['default'] = parameter['default'] === undefined ? undefined
              : (_.isString(parameter['default']) ? parameter['default'].trim() : parameter['default']);
          }
          paramConfig.optionSpan = parameter.optionSpan;
          paramConfig.options = parameter.options.map(option => {
            let r = {
              label: `${option.label}`.trim(),
              value: option.value,
            };
            if (option.shortText !== undefined && `${option.shortText}`.trim() !== '') {
              r.shortText = `${option.shortText}`.trim();
            }
            return r;
          });
          break;
        case 'duration':
          paramConfig['default'] = parameter['default'] === undefined ? undefined
            : (_.isString(parameter['default']) ? parameter['default'].trim() : parameter['default']);
          paramConfig.optionSpan = parameter.optionSpan;
          paramConfig.enabledOptions = parameter.enabledOptions;
          break;
        case 'location':
          paramConfig['default'] = paramConfig['default'];
          paramConfig.optionSpan = parameter.optionSpan;
          break;
        default:
          return;
      }
      config.parameter.items.push(paramConfig);
    });

    // operations

    if (me.state.operationSaveNodesEnabled) {
      config.operations.push({
        type: 'saveNodes',
        lineType: me.state.operationSaveNodesLineType,
        lineStyle: me.state.operationSaveNodesLineStyle,
        arrangeBy: me.state.operationSaveNodesArrangeBy,
        classifyBy: me.state.operationSaveNodesClassifyBy,
        categories: me.state.operationSaveNodesCategories,
        orders: me.state.operationSaveNodesOrders,
        copyTextKey: me.state.operationSaveNodesCopyTextKey === false
          ? false : `${me.state.operationSaveNodesCopyTextKey}`.trim(),
      });
    }

    if (me.state.operationSaveEdgesEnabled) {
      config.operations.push({
        type: 'saveEdges',
        lineType: me.state.operationSaveEdgesLineType,
        lineStyle: me.state.operationSaveEdgesLineStyle,
        copyTextKey: me.state.operationSaveNodesCopyTextKey === false
          ? false : `${me.state.operationSaveEdgesCopyTextKey}`.trim(),
      })
    }

    if (me.state.operationSaveSubGraphsEnabled) {
      config.operations.push({
        type: 'saveSubGraphs',
        lineType: me.state.operationSaveSubGraphsLineType,
        lineStyle: me.state.operationSaveSubGraphsLineStyle,
        innerLineType: me.state.operationSaveSubGraphsInnerLineType,
        innerLineStyle: me.state.operationSaveSubGraphsInnerLineStyle,
        arrangeBy: me.state.operationSaveSubGraphsArrangeBy,
        classifyBy: me.state.operationSaveSubGraphsClassifyBy,
        categories: me.state.operationSaveSubGraphsCategories,
        orders: me.state.operationSaveSubGraphsOrders,
        copyTextKey: me.state.operationSaveSubGraphsCopyTextKey === false
          ? false : `${me.state.operationSaveSubGraphsCopyTextKey}`.trim(),
      });
    }

    if (me.state.operationSaveContentEnabled) {
      config.operations.push({
        type: 'saveContent',
        contentParts: me.state.operationSaveContentContentParts,
        copyTextKey: me.state.operationSaveContentCopyTextKey === false
          ? false : `${me.state.operationSaveContentCopyTextKey}`.trim(),
      });
    }

    if (me.state.operationShowWordCloudEnabled) {
      config.operations.push({
        type: 'showWordCloud',
        kvLabel: me.state.operationShowWordCloudKvLabel,
        copyTextKey: me.state.operationShowWordCloudCopyTextKey === false
          ? false : `${me.state.operationShowWordCloudCopyTextKey}`.trim(),
      });
    }

    if (me.state.operationSaveFilesEnabled) {
      config.operations.push({
        type: 'saveFiles',
        copyTextKey: me.state.operationSaveFilesCopyTextKey === false
          ? false : `${me.state.operationSaveFilesCopyTextKey}`.trim(),
      });
    }
    
    if (me.state.operationShowChartsEnabled) {
      config.operations.push({
        type: 'showCharts',
        chartUrl: me.state.operationShowChartsUrl,
        copyTextKey: me.state.operationShowChartsCopyTextKey === false
          ? false : `${me.state.operationShowChartsCopyTextKey}`.trim(),
      });
    }
    
    if (me.state.operationShowOutLinkEnabled) {
      config.operations.push({
        type: 'showOutLink',
        outUrl: me.state.operationShowOutLinkUrl,
        copyTextKey: me.state.operationShowOutLinkCopyTextKey === false
          ? false : `${me.state.operationShowOutLinkCopyTextKey}`.trim(),
      });
    }
    
    if (me.state.operationShowImplementEnabled) {
      config.operations.push({
        type: 'showImplement',
        copyTextKey: me.state.operationShowImplementCopyTextKey === false
          ? false : `${me.state.operationShowImplementCopyTextKey}`.trim(),
      });
    }

    me.props.onSave(JSON.stringify(config));
  }

  getFilteredNodesLabel = () => {
    let me = this, text, typeConditions = [];

    if (!me.state.targetFilteredNodesFilterByType && !me.state.targetFilteredNodesFilterByAuth) {
      return '根据当前图谱中的全部节点';
    }

    text = '根据当前图谱中';

    if (me.state.targetFilteredNodesFilterByAuth) {
      text += '有权修改';
    }

    text += '的';

    if (me.state.targetFilteredNodesFilterByType) {
      me.state.targetFilteredNodesFilterByTypeAllowedTypes.forEach(type => {
        typeConditions.push(TYPE_NAMES[`${type}`].name);
      });
      if (typeConditions.length > 0) {
        text += typeConditions.join('、').split('').reverse().join('')
          .replace('、', '或').split('').reverse().join('');
      } else if (!me.state.targetFilteredNodesFilterByAuth)  {
        text += '全部';
      }
    }

    text += '节点';

    return text;
  };

  getFilteredNodesDynamicLabelFnSource = () => {
    let me = this, text = me.getFilteredNodesLabel();
    return `function dynamicLabel(param) {\n  return param['filteredNodes'] ? '${
      text}（共' + param['filteredNodes']['nodesLength'] + '个）' : '${text}';\n}`;
  };

  getFilteredNodesShortText = () => {
    let me = this, text, typeConditions = [];

    if (!me.state.targetFilteredNodesFilterByType && !me.state.targetFilteredNodesFilterByAuth) {
      return '全部节点';
    }

    text = '';

    if (me.state.targetFilteredNodesFilterByAuth) {
      text += '可修改的';
    }

    if (me.state.targetFilteredNodesFilterByType) {
      me.state.targetFilteredNodesFilterByTypeAllowedTypes.forEach(type => {
        typeConditions.push(TYPE_NAMES[`${type}`].name);
      });
      if (text) {
        text += '部分';
      } else if (typeConditions.length > 2) {
        text += typeConditions[0];
        text += '等类型的';
      } else {
        text += typeConditions.join('或');
      }
    }

    text = text || '全部';

    text += '节点';

    return text;
  };

  getFilteredNodesDynamicShortTextFnSource = () => {
    let me = this, text = me.getFilteredNodesShortText();
    return `function dynamicShortText(param) {\n  return param['filteredNodes'] ? '${
      text}（共' + param['filteredNodes']['nodesLength'] + '）' : '${text}';\n}`;
  };

  getFilteredNodesFilterFnSource = () => {
    let me = this, overallConditions = [], typeConditions = [];

    if (!me.state.targetFilteredNodesFilterByType && !me.state.targetFilteredNodesFilterByAuth) {
      return 'function filter() {\n  return true;\n}';
    }

    if (me.state.targetFilteredNodesFilterByType) {
      me.state.targetFilteredNodesFilterByTypeAllowedTypes.forEach(type => {
        if (_.isString(type)) {
          typeConditions.push(`param['getNodeType'](node) === "${type}"`);
        } else {
          typeConditions.push(`param['getNodeType'](node) === ${type}`);
        }
      });
      if (typeConditions.length > 0) {
        overallConditions.push('(' + typeConditions.join(' || ') + ')');
      }
    }

    if (me.state.targetFilteredNodesFilterByAuth) {
      overallConditions.push("param['canModify'](node)");
    }

    return `function filter(node, param) {\n  return ${overallConditions.length > 0 ? overallConditions.join(' && ') : 'true'};\n}`;
  };

  updateFilteredNodesOptions = () => {
    let me = this, state = {};
    if (me.state.targetFilteredNodesFilterType === 'builtIn') {
      state.targetFilteredNodesFilter = me.getFilteredNodesFilterFnSource();
      if (me.state.targetFilteredNodesLabelType === 'autoStatic') {
        state.targetFilteredNodesLabel = me.getFilteredNodesLabel();
      } else if (me.state.targetFilteredNodesLabelType === 'autoDynamic') {
        state.targetFilteredNodesDynamicLabel = me.getFilteredNodesDynamicLabelFnSource();
      }
      if (me.state.targetFilteredNodesShortTextType === 'autoStatic') {
        state.targetFilteredNodesShortText = me.getFilteredNodesShortText();
      } else if (me.state.targetFilteredNodesShortTextType === 'autoDynamic') {
        state.targetFilteredNodesDynamicShortText = me.getFilteredNodesDynamicShortTextFnSource();
      }
      me.setState(state);
    }
  };

  renderTargetCollapsePanel = (
    {
      panelKey,
      panelHeader,
      stateEnabledKey,
      stateLabelTypeKey,
      stateLabelKey,
      stateDynamicLabelKey,
      stateShortTextTypeKey,
      stateShortTextKey,
      stateDynamicShortTextKey,
      defaultLabel,
      defaultDynamicLabel,
      defaultShortText,
      defaultDynamicShortText,
    }
  ) => {
    let me = this;

    return (
      <Collapse.Panel
        key={panelKey}
        showArrow={false}
        header={(
          <Checkbox
            disabled={me.state.targetBuiltinGroup !== 'custom'}
            checked={me.state[stateEnabledKey]}
            onChange={e => me.setState({[stateEnabledKey]: e.target.checked})}
          >
            {panelHeader}
          </Checkbox>
        )}
      >
        {
          me.state.showAdvancedSettings ? (
            <>
              <Form.Item label={'选项展示文本类型'}>
                <Radio.Group
                  value={me.state[stateLabelTypeKey]}
                  onChange={e => {
                    let state = {
                      [stateLabelTypeKey]: e.target.value,
                    };
                    switch (e.target.value) {
                      case 'autoStatic':
                        state[stateLabelKey] = defaultLabel;
                        break;
                      case 'autoDynamic':
                        state[stateDynamicLabelKey] = defaultDynamicLabel;
                        break;
                    }
                    me.setState(state);
                  }}
                >
                  <Radio.Button value={'autoStatic'}>默认静态文本</Radio.Button>
                  <Radio.Button value={'autoDynamic'}>默认动态文本</Radio.Button>
                  <Radio.Button value={'customStatic'}>自定义静态文本</Radio.Button>
                  <Radio.Button value={'customDynamic'}>自定义动态文本</Radio.Button>
                </Radio.Group>
              </Form.Item>
              <Form.Item label={'选项展示文本内容/函数'}>
                {
                  ['autoStatic', 'customStatic'].includes(me.state[stateLabelTypeKey]) ? (
                    <Input
                      value={me.state[stateLabelKey]}
                      onChange={e => me.setState({[stateLabelKey]: e.target.value})}
                      disabled={'autoStatic' === me.state[stateLabelTypeKey]}
                    />
                  ) : null
                }
                {
                  ['autoDynamic', 'customDynamic'].includes(me.state[stateLabelTypeKey]) ? (
                    <Input.TextArea
                      rows={4}
                      value={me.state[stateDynamicLabelKey]}
                      onChange={e => me.setState({[stateDynamicLabelKey]: e.target.value})}
                      disabled={'autoDynamic' === me.state[stateLabelTypeKey]}
                    />
                  ) : null
                }
              </Form.Item>
              <Form.Item label={'选项概述文本类型'}>
                <Radio.Group
                  value={me.state[stateShortTextTypeKey]}
                  onChange={e => {
                    let state = {
                      [stateShortTextTypeKey]: e.target.value,
                    };
                    switch (e.target.value) {
                      case 'autoStatic':
                        state[stateShortTextKey] = defaultShortText;
                        break;
                      case 'autoDynamic':
                        state[stateDynamicShortTextKey] = defaultDynamicShortText;
                        break;
                    }
                    me.setState(state);
                  }}
                >
                  <Radio.Button value={'autoStatic'}>默认静态概述</Radio.Button>
                  <Radio.Button value={'autoDynamic'}>默认动态概述</Radio.Button>
                  <Radio.Button value={'customStatic'}>自定义静态概述</Radio.Button>
                  <Radio.Button value={'customDynamic'}>自定义动态概述</Radio.Button>
                </Radio.Group>
              </Form.Item>
              <Form.Item label={'选项概述文本内容/函数'}>
                {
                  ['autoStatic', 'customStatic'].includes(me.state[stateShortTextTypeKey]) ? (
                    <Input
                      value={me.state[stateShortTextKey]}
                      onChange={e => me.setState({[stateShortTextKey]: e.target.value})}
                      disabled={'autoStatic' === me.state[stateShortTextTypeKey]}
                    />
                  ) : null
                }
                {
                  ['autoDynamic', 'customDynamic'].includes(me.state[stateShortTextTypeKey]) ? (
                    <Input.TextArea
                      rows={4}
                      value={me.state[stateDynamicShortTextKey]}
                      onChange={e => me.setState({[stateDynamicShortTextKey]: e.target.value})}
                      disabled={'autoDynamic' === me.state[stateShortTextTypeKey]}
                    />
                  ) : null
                }
              </Form.Item>
            </>
          ) : (
            <Alert message={'部分高级配置参数已隐藏'} type="info" showIcon />
          )
        }
      </Collapse.Panel>
    );
  };

  renderParameterItem = idx => {
    let me = this, paramConfig = me.state.parameterItems[idx], content = null,
      checkBoxGroupContent = null;

    // noinspection FallThroughInSwitchStatementJS
    switch (paramConfig.type) {
      case 'textInput':
        content = (
          <>
            <Form.Item label={'参数默认值'}>
              <Input
                value={paramConfig['default']}
                onChange={e => {
                  let parameterItems = [...me.state.parameterItems];
                  parameterItems[idx]['default'] = e.target.value;
                  me.setState({parameterItems});
                }}
              />
            </Form.Item>
          </>
        );
        break;
      case 'checkboxGroup':
        checkBoxGroupContent = (
          <>
            <Form.Item label={'是否必须选择至少一个'}>
              <Checkbox
                checked={!!paramConfig['required']}
                onChange={e => {
                  let parameterItems = [...me.state.parameterItems];
                  parameterItems[idx]['required'] = e.target.checked;
                  me.setState({parameterItems});
                }}
              >
                必须选择至少一个
              </Checkbox>
            </Form.Item>
            <Form.Item label={'参数默认值（空格分隔）'}>
              <Input
                value={(paramConfig['default'] || []).join(' ')}
                onChange={e => {
                  let parameterItems = [...me.state.parameterItems];
                  parameterItems[idx]['default'] = e.target.value ? e.target.value.split(' ') : [];
                  me.setState({parameterItems});
                }}
                onBlur={() => {
                  let parameterItems = [...me.state.parameterItems],
                    v = (parameterItems[idx]['default'] || []).join(' '),
                    vTrim = v.trim();
                  if (v !== vTrim) {
                    parameterItems[idx]['default'] = vTrim ? vTrim.split(' ') : [];
                    me.setState({parameterItems});
                  }
                }}
              />
            </Form.Item>
          </>
        );
        // fall through
      case 'radioGroup':
        content = (
          <>
            {
              checkBoxGroupContent ? checkBoxGroupContent : (
                <Form.Item label={'参数默认值'}>
                  <Input
                    value={paramConfig['default']}
                    onChange={e => {
                      let parameterItems = [...me.state.parameterItems];
                      parameterItems[idx]['default'] = e.target.value;
                      me.setState({parameterItems});
                    }}
                  />
                </Form.Item>
              )
            }
            {
              me.state.showAdvancedSettings ? (
                <Form.Item label={'参数选项span值'}>
                  <InputNumber
                    min={1}
                    max={24}
                    value={paramConfig['optionSpan']}
                    onChange={v => {
                      let parameterItems = [...me.state.parameterItems];
                      parameterItems[idx]['optionSpan'] = v;
                      me.setState({parameterItems});
                    }}
                  />
                </Form.Item>
              ) : (
                <Alert message={'部分高级配置参数已隐藏'} type="info" showIcon style={{marginBottom: '1rem'}} />
              )
            }
            <Row gutter={2} style={{marginBottom: '2px'}}>
              <Col span={me.state.showAdvancedSettings ? 2 : 4} style={{textAlign:'center'}}>值</Col>
              <Col span={me.state.showAdvancedSettings ? 12 : 16} style={{textAlign:'center'}}>选项文本</Col>
              {
                me.state.showAdvancedSettings ? (
                  <Col span={8} style={{textAlign:'center'}}>选项概述</Col>
                ) : null
              }
              <Col span={me.state.showAdvancedSettings ? 2 : 4} style={{textAlign:'center'}}>操作</Col>
            </Row>
            {
              paramConfig.options.map((option, opIdx) => (
                <Row gutter={2} key={`r-${opIdx}`} style={{marginBottom: '2px'}}>
                  <Col span={me.state.showAdvancedSettings ? 2 : 4}>
                    <Input
                      value={option.value}
                      onChange={e => {
                        let parameterItems = [...me.state.parameterItems];
                        parameterItems[idx]['options'][opIdx]['value'] = e.target.value;
                        me.setState({parameterItems});
                      }}
                    />
                  </Col>
                  <Col span={me.state.showAdvancedSettings ? 12 : 16} style={{textAlign:'center'}}>
                    <Input
                      value={option.label}
                      onChange={e => {
                        let parameterItems = [...me.state.parameterItems];
                        parameterItems[idx]['options'][opIdx]['label'] = e.target.value;
                        me.setState({parameterItems});
                      }}
                    />
                  </Col>
                  {
                    me.state.showAdvancedSettings ? (
                      <Col span={8} style={{textAlign:'center'}}>
                        <Input
                          value={option.shortText}
                          onChange={e => {
                            let parameterItems = [...me.state.parameterItems];
                            parameterItems[idx]['options'][opIdx]['shortText'] = e.target.value;
                            me.setState({parameterItems});
                          }}
                        />
                      </Col>
                    ) : null
                  }
                  <Col span={me.state.showAdvancedSettings ? 2 : 4} style={{textAlign:'center'}}>
                    <Button
                      icon={'delete'}
                      onClick={() => {
                        let parameterItems = [...me.state.parameterItems];
                        parameterItems[idx]['options'].splice(opIdx, 1);
                        me.setState({parameterItems});
                      }}
                      shape={'circle-outline'}
                      size={'small'}
                    />
                  </Col>
                </Row>
              ))
            }
            <Button
              block={true}
              ghost={true}
              type={'primary'}
              onClick={() => {
                let parameterItems = [...me.state.parameterItems];
                parameterItems[idx]['options'].push({
                  label: '',
                  value: '',
                });
                me.setState({parameterItems});
              }}
            >
              <Icon name={'plus-circle'} type={IconTypes.ANT_DESIGN} style={{marginRight: '0.5rem'}} />
              添加选项
            </Button>
          </>
        );
        break;
      case 'location':
        content = (
          <>
            {
              me.state.showAdvancedSettings ? (
                <>
                  <Form.Item label={'参数默认值（行政区划编码数组，空格分隔）'}>
                    <Input
                      value={(paramConfig['default'] || []).join(' ')}
                      onChange={e => {
                        let parameterItems = [...me.state.parameterItems];
                        parameterItems[idx]['default'] = e.target.value.split(' ');
                        me.setState({parameterItems});
                      }}
                      onBlur={() => {
                        let parameterItems = [...me.state.parameterItems],
                          v = (parameterItems[idx]['default'] || []).join(' '),
                          vTrim = v.trim();
                        if (v !== vTrim) {
                          parameterItems[idx]['default'] = vTrim ? vTrim.split(' ') : [];
                          me.setState({parameterItems});
                        }
                      }}
                    />
                  </Form.Item>
                  <Form.Item label={'参数选项span值'}>
                    <InputNumber
                      min={1}
                      max={24}
                      value={paramConfig['optionSpan']}
                      onChange={v => {
                        let parameterItems = [...me.state.parameterItems];
                        parameterItems[idx]['optionSpan'] = v;
                        me.setState({parameterItems});
                      }}
                    />
                  </Form.Item>
                </>
              ) : (
                <Alert message={'部分高级配置参数已隐藏'} type="info" showIcon style={{marginBottom: '1rem'}} />
              )
            }
          </>
        );
        break;
      case 'duration':
        content = (
          <>
            <Form.Item label={'参数默认值'}>
              <Select
                value={paramConfig['default'] || 'auto'}
                onChange={v => {
                  let parameterItems = [...me.state.parameterItems];
                  parameterItems[idx]['default'] = (v === 'auto' ? undefined : v);
                  me.setState({parameterItems});
                }}
              >
                <Select.Option value={'auto'}>自动</Select.Option>
                {
                  Object.keys(optionNames).map(k => (
                    <Select.Option key={`opt-${k}`} value={k}>{optionNames[k]}</Select.Option>
                  ))
                }
              </Select>
            </Form.Item>
            {
              me.state.showAdvancedSettings ? (
                <Form.Item label={'参数选项span值'}>
                  <InputNumber
                    min={1}
                    max={24}
                    value={paramConfig['optionSpan']}
                    onChange={v => {
                      let parameterItems = [...me.state.parameterItems];
                      parameterItems[idx]['optionSpan'] = v;
                      me.setState({parameterItems});
                    }}
                  />
                </Form.Item>
              ) : (
                <Alert message={'部分高级配置参数已隐藏'} type="info" showIcon style={{marginBottom: '1rem'}} />
              )
            }
            <Form.Item label={'参数可选选项'}>
              <Checkbox.Group
                value={paramConfig['enabledOptions'] || []}
                onChange={v => {
                  let parameterItems = [...me.state.parameterItems];
                  parameterItems[idx]['enabledOptions'] = v;
                  me.setState({parameterItems});
                }}
              >
                <Row>
                  {
                    Object.keys(optionNames).map(k => (
                      <Col key={`col-${k}`} span={6}><Checkbox value={k}>{optionNames[k]}</Checkbox></Col>
                    ))
                  }
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </>
        );
        break;
    }

    return (
      <>
        <Form.Item label={'参数标题文本（中文）'}>
          <Input
            value={paramConfig.label}
            onChange={e => {
              let parameterItems = [...me.state.parameterItems];
              parameterItems[idx].label = e.target.value;
              me.setState({parameterItems});
            }}
          />
        </Form.Item>
        <Form.Item label={'参数字段名称（字母+数字+下划线）'}>
          <Input
            value={paramConfig.name}
            onChange={e => {
              let parameterItems = [...me.state.parameterItems];
              parameterItems[idx].name = e.target.value;
              me.setState({parameterItems});
            }}
          />
        </Form.Item>
        <Form.Item label={'参数类型'}>
          <Radio.Group
            value={paramConfig.type}
            onChange={e => {
              let parameterItems = [...me.state.parameterItems];
              parameterItems[idx].type = e.target.value;
              switch (parameterItems[idx].type) {
                case 'textInput':
                  parameterItems[idx].default = '';
                  break;
                case 'checkboxGroup':
                  parameterItems[idx].required = false;
                  parameterItems[idx].default = [];
                  parameterItems[idx].optionSpan = 24;
                  parameterItems[idx].options = [];
                  break;
                case 'radioGroup':
                  parameterItems[idx].default = undefined;
                  parameterItems[idx].optionSpan = 24;
                  parameterItems[idx].options = [];
                  break;
                case 'location':
                  parameterItems[idx].default = ['110000'];
                  parameterItems[idx].optionSpan = 18;
                  break;
                case 'duration':
                  parameterItems[idx].default = undefined;
                  parameterItems[idx].optionSpan = 6;
                  parameterItems[idx].enabledOptions = [
                    'this_month',
                    'this_quarter',
                    'last_month',
                    'last_quarter',
                    'this_half_of_year',
                    'first_half_of_year',
                    'second_half_of_year',
                    'this_year',
                  ];
                  break;
              }
              me.setState({parameterItems});
            }}
          >
            <Radio value={'textInput'}>文本框</Radio>
            <Radio value={'radioGroup'}>单选组</Radio>
            <Radio value={'checkboxGroup'}>复选组</Radio>
            <Radio value={'duration'}>时间段</Radio>
            <Radio value={'location'}>地理位置</Radio>
          </Radio.Group>
        </Form.Item>
        {content}
      </>
    )
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    let me = this;

    if (me.props.visible && !prevProps.visible) {
      me.setState({...INITIAL_STATE});
    }
  }

  render() {
    let me = this, content, footer;

    switch (me.state.currentStep) {
      case 0:
        footer = [
          (
            <Button
              type={'primary'}
              key={'parameter'}
              onClick={() => {
                if (me.checkStepTarget()) {
                  me.setState({currentStep: 1})
                }
              }}
            >
              下一步：参数设置
            </Button>
          ),
          (<Button key={'close'} onClick={me.onClose}>取消</Button>),
        ];
        content = (
          <>
            <Form.Item label={'每次请求的目标数量限制（1 - 20）'}>
              <InputNumber min={1} max={20} onChange={v => me.setState({requestTargetLimit: Math.min(Math.max(v, 1), 20)})} value={me.state.requestTargetLimit} />
            </Form.Item>
            {
              me.state.showAdvancedSettings ? (
                <>
                  <Form.Item label={'每个目标对应的返回结果数量限制（1 - 300）'}>
                    <InputNumber min={1} max={300} onChange={v => me.setState({requestResultLimit: Math.min(Math.max(v, 1), 300)})} value={me.state.requestResultLimit} />
                  </Form.Item>
                  <Form.Item label={'目标表单布局设置（formItemLayout）'}>
                    <Input.TextArea
                      rows={4}
                      value={me.state.targetFormLayout}
                      onChange={e => me.setState({targetFormLayout: e.target.value})}
                    />
                  </Form.Item>
                  <Form.Item label={'默认目标选项'}>
                    <Radio.Group
                      value={me.state.targetDefault}
                      onChange={e => me.setState({targetDefault: e.target.value})}
                      size={'small'}
                    >
                      <Radio style={{display: 'block'}} value={'auto'} key={'auto'}>自动</Radio>
                      <Radio style={{display: 'block'}} value={'node'} key={'node'}>选中节点</Radio>
                      <Radio style={{display: 'block'}} value={'filteredNodes'} key={'filtered-nodes'}>图谱中的部分节点</Radio>
                      <Radio style={{display: 'block'}} value={'textOrUrl'} key={'text-or-url'}>文本或URL</Radio>
                      <Radio style={{display: 'block'}} value={'view'} key={'view'}>整个图谱</Radio>
                      <Radio style={{display: 'block'}} value={'files'} key={'files'}>用户上传文件</Radio>
                    </Radio.Group>
                  </Form.Item>
                </>
              ) : (
                <Alert message={'部分高级配置参数已隐藏'} type="info" showIcon />
              )
            }
            <Form.Item label={'目标组合设置'}>
              <Radio.Group
                value={me.state.targetBuiltinGroup}
                onChange={e => {
                  switch (e.target.value) {
                    case 'nodeAndFilteredNodes':
                      me.setState({
                        targetLabel: TARGET_LABEL_NODE_AND_FILTERED_NODES,
                        targetBuiltinGroup: 'nodeAndFilteredNodes',
                        targetNodeEnabled: true,
                        targetFilteredNodesEnabled: true,
                        targetTextOrUrlEnabled: false,
                        targetViewEnabled: false,
                        targetFilesEnabled: false,
                      });
                      break;
                    case 'textOrUrl':
                      me.setState({
                        targetLabel: TARGET_LABEL_TEXT_OR_URL,
                        targetBuiltinGroup: 'textOrUrl',
                        targetNodeEnabled: false,
                        targetFilteredNodesEnabled: false,
                        targetTextOrUrlEnabled: true,
                        targetViewEnabled: false,
                        targetFilesEnabled: false,
                      });
                      break;
                    case 'view':
                      me.setState({
                        targetLabel: TARGET_LABEL_VIEW,
                        targetBuiltinGroup: 'view',
                        targetNodeEnabled: false,
                        targetFilteredNodesEnabled: false,
                        targetTextOrUrlEnabled: false,
                        targetViewEnabled: true,
                        targetFilesEnabled: false,
                      });
                      break;
                    case 'files':
                      me.setState({
                        targetLabel: TARGET_LABEL_FILES,
                        targetBuiltinGroup: 'files',
                        targetNodeEnabled: false,
                        targetFilteredNodesEnabled: false,
                        targetTextOrUrlEnabled: false,
                        targetViewEnabled: false,
                        targetFilesEnabled: true,
                      });
                      break;
                    default:
                      me.setState({
                        targetBuiltinGroup: 'custom',
                      });
                  }
                }}
                size={'small'}
              >
                <Radio style={{display: 'block'}} value={'nodeAndFilteredNodes'} key={'node-and-filtered-nodes'}>选中节点 + 图谱中的部分节点</Radio>
                <Radio style={{display: 'block'}} value={'textOrUrl'} key={'text-or-url'}>文本或URL列表</Radio>
                <Radio style={{display: 'block'}} value={'view'} key={'view'}>整个图谱</Radio>
                <Radio style={{display: 'block'}} value={'files'} key={'files'}>用户上传文件</Radio>
                <Radio style={{display: 'block'}} value={'custom'} key={'custom'} disabled={!me.state.showAdvancedSettings}>自定义（仅高级模式下支持）</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item label={'目标选择提示文本'}>
              <Input
                value={me.state.targetLabel}
                onChange={e => me.setState({targetLabel: e.target.value})}
              />
            </Form.Item>
            <Collapse
              activeKey={[
                me.state.targetNodeEnabled ? 'node' : undefined,
                me.state.targetFilteredNodesEnabled ? 'filteredNode' : undefined,
                me.state.targetTextOrUrlEnabled ? 'textOrUrl' : undefined,
                me.state.targetViewEnabled ? 'view' : undefined,
                me.state.targetFilesEnabled ? 'files' : undefined,
              ]}
            >
              {
                me.renderTargetCollapsePanel({
                  panelKey: 'node',
                  panelHeader: '当前选中节点',
                  stateEnabledKey: 'targetNodeEnabled',
                  stateLabelTypeKey: 'targetNodeLabelType',
                  stateLabelKey: 'targetNodeLabel',
                  stateDynamicLabelKey: 'targetNodeDynamicLabel',
                  stateShortTextTypeKey: 'targetNodeShortTextType',
                  stateShortTextKey: 'targetNodeShortText',
                  stateDynamicShortTextKey: 'targetNodeDynamicShortText',
                  defaultLabel: DEFAULT_NODE_LABEL,
                  defaultDynamicLabel: DEFAULT_NODE_DYNAMIC_LABEL,
                  defaultShortText: DEFAULT_NODE_SHORT_TEXT,
                  defaultDynamicShortText: DEFAULT_NODE_DYNAMIC_SHORT_TEXT,
                })
              }
              <Collapse.Panel
                key={'filteredNode'}
                showArrow={false}
                header={(
                  <Checkbox
                    disabled={me.state.targetBuiltinGroup !== 'custom'}
                    checked={me.state.targetFilteredNodesEnabled}
                    onChange={e => me.setState({targetFilteredNodesEnabled: e.target.checked})}
                  >
                    图谱中的部分节点
                  </Checkbox>
                )}
              >
                <Form.Item label={'选项过滤函数类型'}>
                  <Radio.Group
                    value={me.state.targetFilteredNodesFilterType}
                    onChange={e => {
                      let state = {
                        targetFilteredNodesFilterType: e.target.value,
                      };
                      if (e.target.value === 'custom') {
                        if (me.state.targetFilteredNodesLabelType === 'autoStatic') {
                          state.targetFilteredNodesLabelType = 'customStatic';
                        } else if (me.state.targetFilteredNodesLabelType === 'autoDynamic') {
                          state.targetFilteredNodesLabelType = 'customDynamic';
                        }
                        if (me.state.targetFilteredNodesShortTextType === 'autoStatic') {
                          state.targetFilteredNodesShortTextType = 'customStatic';
                        } else if (me.state.targetFilteredNodesShortTextType === 'autoDynamic') {
                          state.targetFilteredNodesShortTextType = 'customDynamic';
                        }
                      } else {
                        state.targetFilteredNodesFilter = me.getFilteredNodesFilterFnSource();
                      }
                      me.setState(state);
                    }}
                  >
                    <Radio.Button value={'builtIn'}>内置过滤函数</Radio.Button>
                    <Radio.Button value={'custom'} disabled={!me.state.showAdvancedSettings}>自定义过滤函数（仅高级模式下支持）</Radio.Button>
                  </Radio.Group>
                </Form.Item>
                {
                  me.state.targetFilteredNodesFilterType === 'custom' ? null : (
                    <Form.Item label={'选项过滤条件'}>
                      <Checkbox
                        onChange={e => me.setState({targetFilteredNodesFilterByAuth: e.target.checked}, me.updateFilteredNodesOptions)}
                        checked={me.state.targetFilteredNodesFilterByAuth}
                      >
                        仅限有权修改的节点
                      </Checkbox>
                      <br />
                      <Checkbox
                        onChange={e => me.setState({targetFilteredNodesFilterByType: e.target.checked}, me.updateFilteredNodesOptions)}
                        checked={me.state.targetFilteredNodesFilterByType}
                      >
                        仅限特定类型的节点
                      </Checkbox>
                      <br />
                      <Checkbox.Group
                        onChange={checkedValue => me.setState({targetFilteredNodesFilterByTypeAllowedTypes: checkedValue}, me.updateFilteredNodesOptions)}
                        value={me.state.targetFilteredNodesFilterByTypeAllowedTypes}
                        disabled={!me.state.targetFilteredNodesFilterByType}
                      >
                        <Row>
                          {Object.values(TYPE_NAMES).map(option => (
                            <Col key={`c-check-${option.type}`} span={6}>
                              <Checkbox value={option.type}>
                                {option.name}
                              </Checkbox>
                            </Col>
                          ))}
                        </Row>
                      </Checkbox.Group>
                    </Form.Item>
                  )
                }
                {
                  me.state.showAdvancedSettings ? (
                    <>
                      <Form.Item label={'选项过滤函数代码'}>
                        <Input.TextArea
                          rows={4}
                          value={me.state.targetFilteredNodesFilter}
                          onChange={e => me.setState({targetFilteredNodesFilter: e.target.value})}
                          disabled={'builtIn' === me.state.targetFilteredNodesFilterType}
                        />
                      </Form.Item>
                      <Form.Item label={'选项展示文本类型'}>
                        <Radio.Group
                          value={me.state.targetFilteredNodesLabelType}
                          onChange={e => {
                            let state = {
                              targetFilteredNodesLabelType: e.target.value,
                            };
                            switch (e.target.value) {
                              case 'autoStatic':
                                state.targetFilteredNodesLabel = me.getFilteredNodesLabel();
                                break;
                              case 'autoDynamic':
                                state.targetFilteredNodesDynamicLabel = me.getFilteredNodesDynamicLabelFnSource();
                                break;
                            }
                            me.setState(state);
                          }}
                        >
                          <Radio.Button disabled={me.state.targetFilteredNodesFilterType === 'custom'} value={'autoStatic'}>默认静态文本</Radio.Button>
                          <Radio.Button disabled={me.state.targetFilteredNodesFilterType === 'custom'} value={'autoDynamic'}>默认动态文本</Radio.Button>
                          <Radio.Button value={'customStatic'}>自定义静态文本</Radio.Button>
                          <Radio.Button value={'customDynamic'}>自定义动态文本</Radio.Button>
                        </Radio.Group>
                      </Form.Item>
                      <Form.Item label={'选项展示文本内容/函数'}>
                        {
                          ['autoStatic', 'customStatic'].includes(me.state.targetFilteredNodesLabelType) ? (
                            <Input
                              value={me.state.targetFilteredNodesLabel}
                              onChange={e => me.setState({targetFilteredNodesLabel: e.target.value})}
                              disabled={'autoStatic' === me.state.targetFilteredNodesLabelType}
                            />
                          ) : null
                        }
                        {
                          ['autoDynamic', 'customDynamic'].includes(me.state.targetFilteredNodesLabelType) ? (
                            <Input.TextArea
                              rows={4}
                              value={me.state.targetFilteredNodesDynamicLabel}
                              onChange={e => me.setState({targetFilteredNodesDynamicLabel: e.target.value})}
                              disabled={'autoDynamic' === me.state.targetFilteredNodesLabelType}
                            />
                          ) : null
                        }
                      </Form.Item>
                      <Form.Item label={'选项概述文本类型'}>
                        <Radio.Group
                          value={me.state.targetFilteredNodesShortTextType}
                          onChange={e => {
                            let state = {
                              targetFilteredNodesShortTextType: e.target.value,
                            };
                            switch (e.target.value) {
                              case 'autoStatic':
                                state.targetFilteredNodesShortText = me.getFilteredNodesShortText();
                                break;
                              case 'autoDynamic':
                                state.targetFilteredNodesDynamicShortText = me.getFilteredNodesDynamicShortTextFnSource();
                                break;
                            }
                            me.setState(state);
                          }}
                        >
                          <Radio.Button disabled={me.state.targetFilteredNodesFilterType === 'custom'} value={'autoStatic'}>默认静态概述</Radio.Button>
                          <Radio.Button disabled={me.state.targetFilteredNodesFilterType === 'custom'} value={'autoDynamic'}>默认动态概述</Radio.Button>
                          <Radio.Button value={'customStatic'}>自定义静态概述</Radio.Button>
                          <Radio.Button value={'customDynamic'}>自定义动态概述</Radio.Button>
                        </Radio.Group>
                      </Form.Item>
                      <Form.Item label={'选项概述文本内容/函数'}>
                        {
                          ['autoStatic', 'customStatic'].includes(me.state.targetFilteredNodesShortTextType) ? (
                            <Input
                              value={me.state.targetFilteredNodesShortText}
                              onChange={e => me.setState({targetFilteredNodesShortText: e.target.value})}
                              disabled={'autoStatic' === me.state.targetFilteredNodesShortTextType}
                            />
                          ) : null
                        }
                        {
                          ['autoDynamic', 'customDynamic'].includes(me.state.targetFilteredNodesShortTextType) ? (
                            <Input.TextArea
                              rows={4}
                              value={me.state.targetFilteredNodesDynamicShortText}
                              onChange={e => me.setState({targetFilteredNodesDynamicShortText: e.target.value})}
                              disabled={'autoDynamic' === me.state.targetFilteredNodesShortTextType}
                            />
                          ) : null
                        }
                      </Form.Item>
                    </>
                  ) : (
                    <Alert message={'部分高级配置参数已隐藏'} type="info" showIcon />
                  )
                }
              </Collapse.Panel>
              {
                me.renderTargetCollapsePanel({
                  panelKey: 'textOrUrl',
                  panelHeader: '文本或URL列表',
                  stateEnabledKey: 'targetTextOrUrlEnabled',
                  stateLabelTypeKey: 'targetTextOrUrlLabelType',
                  stateLabelKey: 'targetTextOrUrlLabel',
                  stateDynamicLabelKey: 'targetTextOrUrlDynamicLabel',
                  stateShortTextTypeKey: 'targetTextOrUrlShortTextType',
                  stateShortTextKey: 'targetTextOrUrlShortText',
                  stateDynamicShortTextKey: 'targetTextOrUrlDynamicShortText',
                  defaultLabel: DEFAULT_TEXT_OR_URL_LABEL,
                  defaultDynamicLabel: DEFAULT_TEXT_OR_URL_DYNAMIC_LABEL,
                  defaultShortText: DEFAULT_TEXT_OR_URL_SHORT_TEXT,
                  defaultDynamicShortText: DEFAULT_TEXT_OR_URL_DYNAMIC_SHORT_TEXT,
                })
              }
              {
                me.renderTargetCollapsePanel({
                  panelKey: 'view',
                  panelHeader: '整个图谱',
                  stateEnabledKey: 'targetViewEnabled',
                  stateLabelTypeKey: 'targetViewLabelType',
                  stateLabelKey: 'targetViewLabel',
                  stateDynamicLabelKey: 'targetViewDynamicLabel',
                  stateShortTextTypeKey: 'targetViewShortTextType',
                  stateShortTextKey: 'targetViewShortText',
                  stateDynamicShortTextKey: 'targetViewDynamicShortText',
                  defaultLabel: DEFAULT_VIEW_LABEL,
                  defaultDynamicLabel: DEFAULT_VIEW_DYNAMIC_LABEL,
                  defaultShortText: DEFAULT_VIEW_SHORT_TEXT,
                  defaultDynamicShortText: DEFAULT_VIEW_DYNAMIC_SHORT_TEXT,
                })
              }
              {
                me.renderTargetCollapsePanel({
                  panelKey: 'files',
                  panelHeader: '用户上传文件',
                  stateEnabledKey: 'targetFilesEnabled',
                  stateLabelTypeKey: 'targetFilesLabelType',
                  stateLabelKey: 'targetFilesLabel',
                  stateDynamicLabelKey: 'targetFilesDynamicLabel',
                  stateShortTextTypeKey: 'targetFilesShortTextType',
                  stateShortTextKey: 'targetFilesShortText',
                  stateDynamicShortTextKey: 'targetFilesDynamicShortText',
                  defaultLabel: DEFAULT_FILES_LABEL,
                  defaultDynamicLabel: DEFAULT_FILES_DYNAMIC_LABEL,
                  defaultShortText: DEFAULT_FILES_SHORT_TEXT,
                  defaultDynamicShortText: DEFAULT_FILES_DYNAMIC_SHORT_TEXT,
                })
              }
            </Collapse>
          </>
        );
        break;
      case 1:
        footer = [
          (<Button key={'target'} style={{float: 'left'}} onClick={() => me.setState({currentStep: 0})}>上一步：目标设置</Button>),
          (
            <Button
              type={'primary'}
              key={'operation'}
              onClick={() => {
                if (me.checkStepParameter()) {
                  me.setState({currentStep: 2});
                }
              }}
            >
              下一步：操作设置
            </Button>
          ),
          (<Button key={'close'} onClick={me.onClose}>取消</Button>),
        ];
        content = (
          <>
            {
              me.state.showAdvancedSettings ? (
                <Form.Item label={'参数表单布局设置（formItemLayout）'}>
                  <Input.TextArea
                    rows={4}
                    value={me.state.parameterFormLayout}
                    onChange={e => me.setState({parameterFormLayout: e.target.value})}
                  />
                </Form.Item>
              ) : (
                <Alert message={'部分高级配置参数已隐藏'} type="info" showIcon style={{marginBottom: '1rem'}} />
              )
            }
            {
              me.state.parameterItems.length > 0 ? (
                <>
                  <Collapse
                    activeKey={me.state.parameterActiveKeys}
                    onChange={parameterActiveKeys => me.setState({parameterActiveKeys})}
                    style={{marginBottom: '1rem'}}
                  >
                    {
                      me.state.parameterItems.map((p, idx) => (
                        <Collapse.Panel
                          key={p.key}
                          header={(
                            <span style={{display: 'inline-block', width: '28rem'}}>
                              {`参数${idx + 1}${p.label ? `：${p.name ? `${p.label}（${p.name}）` : p.label}` : ''}`}
                            </span>
                          )}
                          extra={(
                            <>
                              <Tooltip title={'上移'}>
                                <Button
                                  icon={'up'}
                                  onClick={e => {
                                    e.stopPropagation();
                                    let parameterItems = [...me.state.parameterItems];
                                    parameterItems[idx] = parameterItems[idx - 1];
                                    parameterItems[idx - 1] = p;
                                    me.setState({parameterItems});
                                  }}
                                  shape={'circle-outline'}
                                  size={'small'}
                                  style={{marginRight: '0.5rem'}}
                                  disabled={idx === 0}
                                />
                              </Tooltip>
                              <Tooltip title={'下移'}>
                                <Button
                                  icon={'down'}
                                  onClick={e => {
                                    e.stopPropagation();
                                    let parameterItems = [...me.state.parameterItems];
                                    parameterItems[idx] = parameterItems[idx + 1];
                                    parameterItems[idx + 1] = p;
                                    me.setState({parameterItems});
                                  }}
                                  shape={'circle-outline'}
                                  size={'small'}
                                  style={{marginRight: '0.5rem'}}
                                  disabled={idx === me.state.parameterItems.length - 1}
                                />
                              </Tooltip>
                              <Tooltip title={'在上方插入'}>
                                <Button
                                  icon={'plus-circle'}
                                  onClick={e => {
                                    e.stopPropagation();
                                    me.addParameterAt(idx);
                                  }}
                                  shape={'circle-outline'}
                                  size={'small'}
                                  style={{marginRight: '0.5rem'}}
                                />
                              </Tooltip>
                              <Tooltip title={'删除'}>
                                <Button
                                  icon={'delete'}
                                  onClick={e => {
                                    e.stopPropagation();
                                    let parameterItems = [...me.state.parameterItems],
                                      parameterActiveKeys = [...me.state.parameterActiveKeys];
                                    parameterItems.splice(idx, 1);
                                    if (parameterActiveKeys.includes(p.key)) {
                                      parameterActiveKeys.splice(parameterActiveKeys.indexOf(p.key), 1);
                                    }
                                    me.setState({parameterItems, parameterActiveKeys});
                                  }}
                                  shape={'circle-outline'}
                                  size={'small'}
                                />
                              </Tooltip>
                            </>
                          )}
                        >
                          {me.renderParameterItem(idx)}
                        </Collapse.Panel>
                      ))
                    }
                  </Collapse>
                </>
              ) : null
            }
            <Button block={true} ghost={true} type={'primary'} onClick={() => me.addParameterAt(me.state.parameterItems.length)}>
              <Icon name={'plus-circle'} type={IconTypes.ANT_DESIGN} style={{marginRight: '0.5rem'}} />
              添加参数
            </Button>
          </>
        );
        break;
      case 2:
        footer = [
          (<Button key={'parameter'} style={{float: 'left'}} onClick={() => me.setState({currentStep: 1})}>上一步：参数设置</Button>),
          (
            <Button
              type={'primary'}
              key={'save'}
              onClick={() => {
                if (me.checkStepOperation()) {
                  me.onSave();
                }
              }}
            >
              保存
            </Button>
          ),
          (<Button key={'close'} onClick={me.onClose}>取消</Button>),
        ];
        content = (
          <>
            <Collapse
              activeKey={[
                me.state.operationSaveNodesEnabled ? 'saveNodes' : undefined,
                me.state.operationSaveEdgesEnabled ? 'saveEdges' : undefined,
                me.state.operationSaveSubGraphsEnabled ? 'saveSubGraphs' : undefined,
                me.state.operationSaveContentEnabled ? 'saveContent' : undefined,
                me.state.operationShowWordCloudEnabled ? 'showWordCloud' : undefined,
                me.state.operationSaveFilesEnabled ? 'saveFiles' : undefined,
                me.state.operationShowChartsEnabled ? 'showCharts' : undefined,
                me.state.operationShowOutLinkEnabled ? 'showOutLink' : undefined,
                me.state.operationShowImplementEnabled ? 'showImplement' : undefined,
              ]}
            >
              <Collapse.Panel
                key={'saveNodes'}
                showArrow={false}
                header={(
                  <Checkbox
                    checked={me.state.operationSaveNodesEnabled}
                    onChange={e => me.setState({operationSaveNodesEnabled: e.target.checked})}
                  >
                    添加节点
                  </Checkbox>
                )}
              >
                {
                  me.state.showAdvancedSettings ? (
                    <>
                      <Form.Item label={'连线类型'}>
                        <Radio.Group
                          value={me.state.operationSaveNodesLineType}
                          onChange={e => me.setState({operationSaveNodesLineType: e.target.value})}
                        >
                          <Radio.Button value={'auto'}>自动（由返回的数据确定）</Radio.Button>
                          <Radio.Button value={'dash'}>虚线（默认隐藏）</Radio.Button>
                          <Radio.Button value={'solid'}>实线</Radio.Button>
                        </Radio.Group>
                      </Form.Item>
                      <Form.Item label={'连线样式'}>
                        <Radio.Group
                          value={me.state.operationSaveNodesLineStyle}
                          onChange={e => me.setState({operationSaveNodesLineStyle: e.target.value})}
                        >
                          <Radio.Button value={'auto'}>自动（由返回的数据确定）</Radio.Button>
                          <Radio.Button value={'straight'}>直线</Radio.Button>
                          <Radio.Button value={'curve'}>曲线</Radio.Button>
                        </Radio.Group>
                      </Form.Item>
                      <Form.Item label={'布局方式'}>
                        <Radio.Group
                          value={me.state.operationSaveNodesArrangeBy}
                          onChange={e => me.setState({operationSaveNodesArrangeBy: e.target.value})}
                        >
                          <Radio.Button value={'star'}>星型</Radio.Button>
                          <Radio.Button value={'link'}>链式</Radio.Button>
                        </Radio.Group>
                      </Form.Item>
                      <Form.Item label={'复制文本路径'}>
                        <Checkbox
                          checked={me.state.operationSaveNodesCopyTextKey !== false}
                          onChange={e => {
                            if (e.target.checked) {
                              me.setState({operationSaveNodesCopyTextKey: 'fname'});
                            } else {
                              me.setState({operationSaveNodesCopyTextKey: false});
                            }
                          }}
                        >
                          支持复制文本，复制路径：
                        </Checkbox>
                        <Input
                          value={me.state.operationSaveNodesCopyTextKey === false ? '' : me.state.operationSaveNodesCopyTextKey}
                          onChange={e => {
                            if (me.state.operationSaveNodesCopyTextKey !== false) {
                              me.setState({operationSaveNodesCopyTextKey: e.target.value});
                            }
                          }}
                          disabled={me.state.operationSaveNodesCopyTextKey === false}
                        />
                      </Form.Item>
                    </>
                  ) : (
                    <Alert message={'部分高级配置参数已隐藏'} type="info" showIcon />
                  )
                }
              </Collapse.Panel>
              <Collapse.Panel
                key={'saveEdges'}
                showArrow={false}
                header={(
                  <Checkbox
                    checked={me.state.operationSaveEdgesEnabled}
                    onChange={e => me.setState({operationSaveEdgesEnabled: e.target.checked})}
                  >
                    添加关系
                  </Checkbox>
                )}
              >
                {
                  me.state.showAdvancedSettings ? (
                    <>
                      <Form.Item label={'连线类型'}>
                        <Radio.Group
                          value={me.state.operationSaveEdgesLineType}
                          onChange={e => me.setState({operationSaveEdgesLineType: e.target.value})}
                        >
                          <Radio.Button value={'auto'}>自动（由返回的数据确定）</Radio.Button>
                          <Radio.Button value={'dash'}>虚线（默认隐藏）</Radio.Button>
                          <Radio.Button value={'solid'}>实线</Radio.Button>
                        </Radio.Group>
                      </Form.Item>
                      <Form.Item label={'连线样式'}>
                        <Radio.Group
                          value={me.state.operationSaveEdgesLineStyle}
                          onChange={e => me.setState({operationSaveEdgesLineStyle: e.target.value})}
                        >
                          <Radio.Button value={'auto'}>自动（由返回的数据确定）</Radio.Button>
                          <Radio.Button value={'straight'}>直线</Radio.Button>
                          <Radio.Button value={'curve'}>曲线</Radio.Button>
                        </Radio.Group>
                      </Form.Item>
                      <Form.Item label={'复制文本路径'}>
                        <Checkbox
                          checked={me.state.operationSaveEdgesCopyTextKey !== false}
                          onChange={e => {
                            if (e.target.checked) {
                              me.setState({operationSaveEdgesCopyTextKey: 'fname'});
                            } else {
                              me.setState({operationSaveEdgesCopyTextKey: false});
                            }
                          }}
                        >
                          支持复制文本，复制路径：
                        </Checkbox>
                        <Input
                          value={me.state.operationSaveEdgesCopyTextKey === false ? '' : me.state.operationSaveEdgesCopyTextKey}
                          onChange={e => {
                            if (me.state.operationSaveEdgesCopyTextKey !== false) {
                              me.setState({operationSaveEdgesCopyTextKey: e.target.value});
                            }
                          }}
                          disabled={me.state.operationSaveEdgesCopyTextKey === false}
                        />
                      </Form.Item>
                    </>
                  ) : (
                    <Alert message={'部分高级配置参数已隐藏'} type="info" showIcon />
                  )
                }
              </Collapse.Panel>
              <Collapse.Panel
                key={'saveSubGraphs'}
                showArrow={false}
                header={(
                  <Checkbox
                    checked={me.state.operationSaveSubGraphsEnabled}
                    onChange={e => me.setState({operationSaveSubGraphsEnabled: e.target.checked})}
                  >
                    添加子图
                  </Checkbox>
                )}
              >
                {
                  me.state.showAdvancedSettings ? (
                    <>
                      <Form.Item label={'连线类型（中心节点与子图根节点）'}>
                        <Radio.Group
                          value={me.state.operationSaveSubGraphsLineType}
                          onChange={e => me.setState({operationSaveSubGraphsLineType: e.target.value})}
                        >
                          <Radio.Button value={'auto'}>自动（由返回的数据确定）</Radio.Button>
                          <Radio.Button value={'dash'}>虚线（默认隐藏）</Radio.Button>
                          <Radio.Button value={'solid'}>实线</Radio.Button>
                        </Radio.Group>
                      </Form.Item>
                      <Form.Item label={'连线样式（中心节点与子图根节点）'}>
                        <Radio.Group
                          value={me.state.operationSaveSubGraphsLineStyle}
                          onChange={e => me.setState({operationSaveSubGraphsLineStyle: e.target.value})}
                        >
                          <Radio.Button value={'auto'}>自动（由返回的数据确定）</Radio.Button>
                          <Radio.Button value={'straight'}>直线</Radio.Button>
                          <Radio.Button value={'curve'}>曲线</Radio.Button>
                        </Radio.Group>
                      </Form.Item>
                      <Form.Item label={'连线类型（子图根节点与子图叶子节点）'}>
                        <Radio.Group
                          value={me.state.operationSaveSubGraphsInnerLineType}
                          onChange={e => me.setState({operationSaveSubGraphsInnerLineType: e.target.value})}
                        >
                          <Radio.Button value={'auto'}>自动（由返回的数据确定）</Radio.Button>
                          <Radio.Button value={'dash'}>虚线（默认隐藏）</Radio.Button>
                          <Radio.Button value={'solid'}>实线</Radio.Button>
                        </Radio.Group>
                      </Form.Item>
                      <Form.Item label={'连线样式（子图根节点与子图叶子节点）'}>
                        <Radio.Group
                          value={me.state.operationSaveSubGraphsInnerLineStyle}
                          onChange={e => me.setState({operationSaveSubGraphsInnerLineStyle: e.target.value})}
                        >
                          <Radio.Button value={'auto'}>自动（由返回的数据确定）</Radio.Button>
                          <Radio.Button value={'straight'}>直线</Radio.Button>
                          <Radio.Button value={'curve'}>曲线</Radio.Button>
                        </Radio.Group>
                      </Form.Item>
                      <Form.Item label={'布局方式（子图根节点与中心节点的连接）'}>
                        <Radio.Group
                          value={me.state.operationSaveSubGraphsArrangeBy}
                          onChange={e => me.setState({operationSaveSubGraphsArrangeBy: e.target.value})}
                        >
                          <Radio.Button value={'star'}>星型</Radio.Button>
                          <Radio.Button value={'link'}>链式</Radio.Button>
                        </Radio.Group>
                      </Form.Item>
                      <Form.Item label={'复制文本路径'}>
                        <Checkbox
                          checked={me.state.operationSaveSubGraphsCopyTextKey !== false}
                          onChange={e => {
                            if (e.target.checked) {
                              me.setState({operationSaveSubGraphsCopyTextKey: 'fname'});
                            } else {
                              me.setState({operationSaveSubGraphsCopyTextKey: false});
                            }
                          }}
                        >
                          支持复制文本，复制路径：
                        </Checkbox>
                        <Input
                          value={me.state.operationSaveSubGraphsCopyTextKey === false ? '' : me.state.operationSaveSubGraphsCopyTextKey}
                          onChange={e => {
                            if (me.state.operationSaveSubGraphsCopyTextKey !== false) {
                              me.setState({operationSaveSubGraphsCopyTextKey: e.target.value});
                            }
                          }}
                          disabled={me.state.operationSaveSubGraphsCopyTextKey === false}
                        />
                      </Form.Item>
                    </>
                  ) : (
                    <Alert message={'部分高级配置参数已隐藏'} type="info" showIcon />
                  )
                }
              </Collapse.Panel>
              <Collapse.Panel
                key={'saveContent'}
                showArrow={false}
                header={(
                  <Checkbox
                    checked={me.state.operationSaveContentEnabled}
                    onChange={e => me.setState({operationSaveContentEnabled: e.target.checked})}
                  >
                    修改节点
                  </Checkbox>
                )}
              >
                {
                  me.state.showAdvancedSettings ? (
                    <>
                      <Form.Item label={'可保存的节点内容类型'}>
                        <Radio.Group
                          value={me.state.operationSaveContentContentParts.length === 1 && me.state.operationSaveContentContentParts[0] === 'all' ? 'all' : 'custom'}
                          onChange={e => {
                            if (e.target.value === 'all') {
                              me.setState({operationSaveContentContentParts: ['all']});
                            } else {
                              me.setState({operationSaveContentContentParts: []});
                            }
                          }}
                        >
                          <Radio value={'all'}>不限</Radio>
                          <br />
                          <Radio value={'custom'}>自定义</Radio>
                          <br />
                        </Radio.Group>
                        <br />
                        <Checkbox.Group
                          onChange={checkedValue => me.setState({operationSaveContentContentParts: checkedValue})}
                          value={me.state.operationSaveContentContentParts.length === 1 && me.state.operationSaveContentContentParts[0] === 'all' ? [] : me.state.operationSaveContentContentParts}
                          disabled={me.state.operationSaveContentContentParts.length === 1 && me.state.operationSaveContentContentParts[0] === 'all'}
                        >
                          <Row>
                            <Col span={6}><Checkbox value={'icon'}>图标</Checkbox></Col>
                            <Col span={6}><Checkbox value={'description'}>描述</Checkbox></Col>
                            <Col span={6}><Checkbox value={'url'}>链接</Checkbox></Col>
                            <Col span={6}><Checkbox value={'tag'}>标签</Checkbox></Col>
                            <Col span={6}><Checkbox value={'position'}>位置</Checkbox></Col>
                          </Row>
                        </Checkbox.Group>
                      </Form.Item>
                      <Form.Item label={'复制文本路径'}>
                        <Checkbox
                          checked={me.state.operationSaveContentCopyTextKey !== false}
                          onChange={e => {
                            if (e.target.checked) {
                              me.setState({operationSaveContentCopyTextKey: 'fname'});
                            } else {
                              me.setState({operationSaveContentCopyTextKey: false});
                            }
                          }}
                        >
                          支持复制文本，复制路径：
                        </Checkbox>
                        <Input
                          value={me.state.operationSaveContentCopyTextKey === false ? '' : me.state.operationSaveContentCopyTextKey}
                          onChange={e => {
                            if (me.state.operationSaveContentCopyTextKey !== false) {
                              me.setState({operationSaveContentCopyTextKey: e.target.value});
                            }
                          }}
                          disabled={me.state.operationSaveContentCopyTextKey === false}
                        />
                      </Form.Item>
                    </>
                  ) : (
                    <Alert message={'部分高级配置参数已隐藏'} type="info" showIcon />
                  )
                }
              </Collapse.Panel>
              <Collapse.Panel
                key={'showWordCloud'}
                showArrow={false}
                header={(
                  <Checkbox
                    checked={me.state.operationShowWordCloudEnabled}
                    onChange={e => me.setState({operationShowWordCloudEnabled: e.target.checked})}
                  >
                    展示词云
                  </Checkbox>
                )}
              >
                <Form.Item label={'K-V类型数据在树节点中展示的标题（如：词频）'}>
                  <Input
                    value={me.state.operationShowWordCloudKvLabel}
                    onChange={e => me.setState({operationShowWordCloudKvLabel: e.target.value})}
                  />
                </Form.Item>
                {
                  me.state.showAdvancedSettings ? (
                    <Form.Item label={'复制文本路径'}>
                      <Checkbox
                        checked={me.state.operationShowWordCloudCopyTextKey !== false}
                        onChange={e => {
                          if (e.target.checked) {
                            me.setState({operationShowWordCloudCopyTextKey: 'fname'});
                          } else {
                            me.setState({operationShowWordCloudCopyTextKey: false});
                          }
                        }}
                      >
                        支持复制文本，复制路径：
                      </Checkbox>
                      <Input
                        value={me.state.operationShowWordCloudCopyTextKey === false ? '' : me.state.operationShowWordCloudCopyTextKey}
                        onChange={e => {
                          if (me.state.operationShowWordCloudCopyTextKey !== false) {
                            me.setState({operationShowWordCloudCopyTextKey: e.target.value});
                          }
                        }}
                        disabled={me.state.operationShowWordCloudCopyTextKey === false}
                      />
                    </Form.Item>
                  ) : (
                    <Alert message={'部分高级配置参数已隐藏'} type="info" showIcon />
                  )
                }
              </Collapse.Panel>
              <Collapse.Panel
                key={'saveFiles'}
                showArrow={false}
                header={(
                  <Checkbox
                    checked={me.state.operationSaveFilesEnabled}
                    onChange={e => me.setState({operationSaveFilesEnabled: e.target.checked})}
                  >
                    下载文件
                  </Checkbox>
                )}
              >
                {
                  me.state.showAdvancedSettings ? (
                    <Form.Item label={'复制文本路径'}>
                      <Checkbox
                        checked={me.state.operationSaveFilesCopyTextKey !== false}
                        onChange={e => {
                          if (e.target.checked) {
                            me.setState({operationSaveFilesCopyTextKey: 'fname'});
                          } else {
                            me.setState({operationSaveFilesCopyTextKey: false});
                          }
                        }}
                      >
                        支持复制文本，复制路径：
                      </Checkbox>
                      <Input
                        value={me.state.operationSaveFilesCopyTextKey === false ? '' : me.state.operationSaveFilesCopyTextKey}
                        onChange={e => {
                          if (me.state.operationSaveFilesCopyTextKey !== false) {
                            me.setState({operationSaveFilesCopyTextKey: e.target.value});
                          }
                        }}
                        disabled={me.state.operationSaveFilesCopyTextKey === false}
                      />
                    </Form.Item>
                  ) : (
                    <Alert message={'部分高级配置参数已隐藏'} type="info" showIcon />
                  )
                }
              </Collapse.Panel>
              <Collapse.Panel
                key={'showCharts'}
                showArrow={false}
                header={(
                  <Checkbox
                    checked={me.state.operationShowChartsEnabled}
                    onChange={e => me.setState({operationShowChartsEnabled: e.target.checked})}
                  >
                    展示图表
                  </Checkbox>
                )}
              >
                <Form.Item label={'图表展示页URL'}>
                  <Input
                    value={me.state.operationShowChartsUrl}
                    onChange={e => me.setState({operationShowChartsUrl: e.target.value})}
                  />
                </Form.Item>
                {
                  me.state.showAdvancedSettings ? (
                    <Form.Item label={'复制文本路径'}>
                      <Checkbox
                        checked={me.state.operationShowChartsCopyTextKey !== false}
                        onChange={e => {
                          if (e.target.checked) {
                            me.setState({operationShowChartsCopyTextKey: 'fname'});
                          } else {
                            me.setState({operationShowChartsCopyTextKey: false});
                          }
                        }}
                      >
                        支持复制文本，复制路径：
                      </Checkbox>
                      <Input
                        value={me.state.operationShowChartsCopyTextKey === false ? '' : me.state.operationShowChartsCopyTextKey}
                        onChange={e => {
                          if (me.state.operationShowChartsCopyTextKey !== false) {
                            me.setState({operationShowChartsCopyTextKey: e.target.value});
                          }
                        }}
                        disabled={me.state.operationShowChartsCopyTextKey === false}
                      />
                    </Form.Item>
                  ) : (
                    <Alert message={'部分高级配置参数已隐藏'} type="info" showIcon />
                  )
                }
              </Collapse.Panel>
              <Collapse.Panel
                key={'showOutLink'}
                showArrow={false}
                header={(
                  <Checkbox
                    checked={me.state.operationShowOutLinkEnabled}
                    onChange={e => me.setState({operationShowOutLinkEnabled: e.target.checked})}
                  >
                    展示结果
                  </Checkbox>
                )}
              >
                <Form.Item label={'结果展示页URL'}>
                  <Input
                    value={me.state.operationShowOutLinkUrl}
                    onChange={e => me.setState({operationShowOutLinkUrl: e.target.value})}
                  />
                </Form.Item>
                {
                  me.state.showAdvancedSettings ? (
                    <Form.Item label={'复制文本路径'}>
                      <Checkbox
                        checked={me.state.operationShowOutLinkCopyTextKey !== false}
                        onChange={e => {
                          if (e.target.checked) {
                            me.setState({operationShowOutLinkCopyTextKey: 'fname'});
                          } else {
                            me.setState({operationShowOutLinkCopyTextKey: false});
                          }
                        }}
                      >
                        支持复制文本，复制路径：
                      </Checkbox>
                      <Input
                        value={me.state.operationShowOutLinkCopyTextKey === false ? '' : me.state.operationShowOutLinkCopyTextKey}
                        onChange={e => {
                          if (me.state.operationShowOutLinkCopyTextKey !== false) {
                            me.setState({operationShowOutLinkCopyTextKey: e.target.value});
                          }
                        }}
                        disabled={me.state.operationShowOutLinkCopyTextKey === false}
                      />
                    </Form.Item>
                  ) : (
                    <Alert message={'部分高级配置参数已隐藏'} type="info" showIcon />
                  )
                }
              </Collapse.Panel>
              <Collapse.Panel
                key={'showImplement'}
                showArrow={false}
                header={(
                  <Checkbox
                    checked={me.state.operationShowImplementEnabled}
                    onChange={e => me.setState({operationShowImplementEnabled: e.target.checked})}
                  >
                    直接执行
                  </Checkbox>
                )}
              >
                {
                  me.state.showAdvancedSettings ? (
                    <Form.Item label={'复制文本路径'}>
                      <Checkbox
                        checked={me.state.operationShowImplementCopyTextKey !== false}
                        onChange={e => {
                          if (e.target.checked) {
                            me.setState({operationShowImplementCopyTextKey: 'fname'});
                          } else {
                            me.setState({operationShowImplementCopyTextKey: false});
                          }
                        }}
                      >
                        支持复制文本，复制路径：
                      </Checkbox>
                      <Input
                        value={me.state.operationShowImplementCopyTextKey === false ? '' : me.state.operationShowImplementCopyTextKey}
                        onChange={e => {
                          if (me.state.operationShowImplementCopyTextKey !== false) {
                            me.setState({operationShowImplementCopyTextKey: e.target.value});
                          }
                        }}
                        disabled={me.state.operationShowImplementCopyTextKey === false}
                      />
                    </Form.Item>
                  ) : (
                    <Alert message={'部分高级配置参数已隐藏'} type="info" showIcon />
                  )
                }
              </Collapse.Panel>
            </Collapse>
          </>
        );
        break;
      default:
        footer = [];
        content = null;
    }

    return (
      <Modal
        title={(
          <span>
            微服务配置编辑
            <Checkbox
              checked={me.state.showAdvancedSettings}
              onChange={() => {
                if (me.state.showAdvancedSettings) {
                  Modal.confirm({
                    content: '关闭高级模式将重置所有修改并返回第一步，是否继续？',
                    okText: '继续',
                    cancelText: '取消',
                    onOk: () => {
                      me.setState({...INITIAL_STATE});
                    },
                  });
                } else {
                  me.setState({showAdvancedSettings: true});
                }
              }}
              style={{marginLeft: '1.5rem'}}
            >
              高级模式
            </Checkbox>
          </span>
        )}
        visible={me.props.visible}
        centered={true}
        onCancel={me.onClose}
        footer={footer}
        bodyStyle={{height: '70vh'}}
        width={'45rem'}
        destroyOnClose={true}
      >
        <Form>
          <Steps current={me.state.currentStep} style={{marginBottom: '1rem'}}>
            <Steps.Step title={'目标设置'} />
            <Steps.Step title={'参数设置'} />
            <Steps.Step title={'操作设置'} />
          </Steps>
          {content}
        </Form>
      </Modal>
    );
  }
}

MicroServiceModalUiConfigWizard.defaultProps = {
  bus: PB,
};

MicroServiceModalUiConfigWizard.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  bus: PropTypes.instanceOf(SimplePB),
};

export default MicroServiceModalUiConfigWizard;