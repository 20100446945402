/*
 * @Author: Carr.s
 * @Date: 2022-07-22 10:08:27
 * @LastEditTime: 2022-07-22 15:54:30
 * @LastEditors: Carr.s
 * @Description: 待办任务 修改任务状态 modal
 * @Copyright by joinmap
 */
import React from "react";
import PropTypes from "prop-types";
import { Modal, Button, Popover, Radio, message, Form, Select } from "antd";
import PB, { SimplePB } from "@/libs/simplePB";
import styles from "@/style/components/todoView/todo.timeline.less";

// 任务常量值
const priorityText = ["一般", "紧急", "特急"];
const statusText = ["--", "待办", "已办", "办结", "撤办"];
const statusTextTips = [
  "--",
  <span>任务将重置，确定要重新发起此任务吗？</span>,
  <span>任务确定已经完成了吗？</span>,
  <span>
    任务确实已经完成了吗？ <br />
    （如果需要的话，办结后还可以再次设置任务）
  </span>,
  <span>任务确实不需要了吗？撤办后任务记录将消失！</span>,
];

const statusFilter = [
  ["not-status-4", "全部"],
  ["status-1", "待办"],
  ["status-2", "已办"],
  ["status-3", "办结"],
];

const relationFilter = {
  all: "全部",
  related_to: "与我相关",
  user_id: "由我创建",
  assign_to: "指派给我",
};

class NodeTodoStatusModal extends React.Component {
  state = {
    clickedTaskStatus: 0, // 当前选择的任务状态
  };

  // ???
  onReturn = (nodeTaskId) => {
    let me = this;

    me.props.bus.emit("node_todo", "todo.do_patch", {
      task: { nodeTaskId, status: 1 },
    });
  };

  // 完成
  onComplete = (nodeTaskId) => {
    let me = this;

    me.props.bus.emit("node_todo", "todo.do_complete", nodeTaskId);
  };

  // 办结
  onFinish = (nodeTaskId) => {
    let me = this;

    me.props.bus.emit("node_todo", "todo.do_patch", {
      task: { nodeTaskId, status: 3 },
    });
  };

  // 撤回
  onCancel = (nodeTaskId) => {
    let me = this;

    me.props.bus.emit("node_todo", "todo.do_patch", {
      task: { nodeTaskId, status: 4 },
    });
  };

  onClose = () => {
    this.props.onClose();
  };

  onCancelConfirm = () => {
    this.setState({
      clickedTaskStatus: 0,
    });
  };

  onOkConfirm = () => {
    this.setState({
      clickedTaskStatus: 0,
    });

    this.props.onClose();
  };

  render() {
    const { task, curUserId } = this.props;
    console.log(
      "🚀 ~ file: common.node.todo.statusModal.js ~ line 89 ~ NodeTodoStatusModal ~ render ~ this.props",
      this.props
    );
    if (!task) {
      return "";
    }
    const { id, type, group, content, start, end, data } = task;
    const { status } = data;
    let me = this;
    // 权限
    let isNotOwner = true; // 不是创建人
    let isNotAssignee = true; // 不是发起人

    if (data) {
      isNotOwner = curUserId !== parseInt(data.userId);
      isNotAssignee = !data.assignTo || curUserId !== parseInt(data.assignTo);
    }

    return (
      <Modal
        title={"更改待办状态"}
        maskClosable={false}
        destroyOnClose={true}
        visible={me.props.visible}
        centered={true}
        onCancel={me.onClose}
        width={"25rem"}
        footer={[
          <Button
            key={"confirm"}
            type={"primary"}
            style={{ marginRight: "8px" }}
            onClick={me.onClose}
          >
            取消
          </Button>,
        ]}
      >
        <Popover
          title={`${statusText[me.state.clickedTaskStatus]}提示`}
          getPopupContainer={(triggerNode) => triggerNode.parentNode}
          overlayStyle={{
            zIndex: 999,
          }}
          content={
            <div style={{ padding: ".5rem" }}>
              <div
                style={{
                  minWidth: "15rem",
                  fontSize: "1rem",
                  textAlign: "center",
                  paddingBottom: "1.5rem",
                }}
              >
                {statusTextTips[me.state.clickedTaskStatus]}
              </div>
              <div
                style={{
                  textAlign: "right",
                }}
              >
                <Button
                  onClick={me.onCancelConfirm}
                  style={{ marginRight: ".8rem" }}
                >
                  取消
                </Button>
                <Button
                  type={"primary"}
                  onClick={() => {
                    switch (me.state.clickedTaskStatus) {
                      case 1:
                        me.onReturn(data.nodeTaskId);
                        break;
                      case 2:
                        me.onComplete(data.nodeTaskId);
                        break;
                      case 3:
                        me.onFinish(data.nodeTaskId);
                        break;
                      case 4:
                        me.onCancel(data.nodeTaskId);
                        break;
                    }
                    me.onOkConfirm();
                  }}
                >
                  确定
                </Button>
              </div>
            </div>
          }
          visible={!!me.state.clickedTaskStatus}
        >
          <div style={{ float: "left", marginRight: 5 }}>选择状态: </div>
          <div style={{ float: "left" }}>
            <Radio.Group
              size={"small"}
              value={status}
              onChange={(e) => {
                console.log(
                  "🚀 ~ file: common.node.todo.statusModal.js ~ line 178 ~ NodeTodoStatusModal ~ render ~ e",
                  e
                );
                me.setState({ clickedTaskStatus: e.target.value });
              }}
              buttonStyle={"solid"}
              className={`${styles["status-radio"]} todo-status-${data.status}`}
            >
              <Radio.Button
                value={4}
                key={`status-4`}
                disabled={isNotOwner || data.status === 3}
              >
                {statusText[4]}
              </Radio.Button>
              <Radio.Button
                value={1}
                key={`status-1`}
                disabled={isNotOwner || data.status === 4 || data.status === 3}
              >
                {statusText[1]}
              </Radio.Button>
              <Radio.Button
                value={2}
                key={`status-2`}
                disabled={
                  (isNotOwner && isNotAssignee) ||
                  data.status === 4 ||
                  data.status === 3
                }
              >
                {statusText[2]}
              </Radio.Button>
              <Radio.Button
                value={3}
                key={`status-3`}
                disabled={isNotOwner || data.status === 4}
              >
                {statusText[3]}
              </Radio.Button>
            </Radio.Group>
          </div>

          <div style={{ clear: "both" }} />
        </Popover>
      </Modal>
    );
  }
}

NodeTodoStatusModal.defaultProps = {
  bus: PB,
  task: undefined,
};

NodeTodoStatusModal.propTypes = {
  bus: PropTypes.instanceOf(SimplePB),
  curUserId: PropTypes.number,
  task: PropTypes.object,
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default NodeTodoStatusModal;
