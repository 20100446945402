import {
  DO_CHECK_USERNAME,
  USER_FOUND,
  INIT_LOGIN_VIA_USERNAME,
  INIT_LOGIN_VIA_WE_CHAT,
  BACK_TO_CHECK_USERNAME,
  DO_LOGIN,
  DO_LOGOUT,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  INIT_REGISTER,
  DO_REGISTER,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  RESET_PWD_VIA_EMAIL,
  RESET_PWD_EMAIL_SENT,
  RESET_PWD_EMAIL_SEND_FAIL,
  DO_LOGIN_VIA_WE_CHAT,
  LOGIN_VIA_WE_CHAT_SUCCESS,
  LOGIN_VIA_WE_CHAT_FAIL,
  WE_CHAT_RESOURCE_LOADED,
  RESET_PWD_SEND_NEW_PWD,
  RESET_PWD_DONE,
  RESET_PWD_FAIL,
  RESET_PWD_CONFIRM,
  RESET_PWD_INIT,
  DO_CHANGE_PWD,
  CHANGE_PWD_INIT,
  CHANGE_PWD_SUCCESS,
  CHANGE_PWD_FAIL,
  CHANGE_USER_BASIC_INFO,
  CHANGE_USER_BASIC_INFO_SUCCESS,
  CHANGE_USER_BASIC_INFO_FAIL,
  CHANGE_EMAIL_SUCCESS,
  DO_GET_WE_CHAT_FWH_TICKET,
  GET_WE_CHAT_FWH_TICKET_SUCCESS,
  GET_WE_CHAT_FWH_TICKET_FAIL, DO_CHECK_WE_CHAT_FWH_TICKET,
} from "./actionTypes";

import {
  LoginStatusEnum,
  NormalLoginStatusEnum,
  RegisterStatusEnum,
  AuthStepEnum,
  WeChatLoginStatusEnum,
  ResetPwdStatusEnum,
  ChangePwdStatus,
  ChangeUserBasicInfoStatus,
} from "@/constants/account";

const initialStore = {
  loginStatus: LoginStatusEnum.INTERACTING,
  normalLoginStatus: NormalLoginStatusEnum.INIT,
  weChatLoginStatus: WeChatLoginStatusEnum.LOADING_FWH_QR_CODE,
  weChatLoginState: '',
  weChatFwhTicket: '',
  registerStatus: RegisterStatusEnum.INIT,
  resetPwdStatus: ResetPwdStatusEnum.INTERACTING,
  authStep: AuthStepEnum.WAIT_FOR_WECHAT_SCAN_QR_CODE,
  goBackFromLoginViaWechatFn: false,
  existedUsername: undefined,
  userInfo: undefined,
  changePwdStatus: ChangePwdStatus.INTERACTING,
  changeUserBasicInfoStatus: ChangeUserBasicInfoStatus.SUCCESS,
};

export default function reducer(state = initialStore, action) {
  switch (action.type) {
    case DO_CHECK_USERNAME:
      return {
        ...state,
        loginStatus: LoginStatusEnum.PROCESSING,
        normalLoginStatus: NormalLoginStatusEnum.CHECKING_USERNAME,
        existedUsername: undefined,
      };
    case USER_FOUND:
      return {
        ...state,
        loginStatus: LoginStatusEnum.INTERACTING,
        existedUsername: action.username,
        normalLoginStatus: NormalLoginStatusEnum.INIT,
        authStep: AuthStepEnum.WAIT_FOR_CHECK_PASSWORD,
      };
    case INIT_LOGIN_VIA_USERNAME:
      return {
        ...state,
        loginStatus: LoginStatusEnum.INTERACTING,
        normalLoginStatus: NormalLoginStatusEnum.INIT,
        authStep: AuthStepEnum.WAIT_FOR_CHECK_USERNAME,
        existedUsername: undefined,
        userInfo: undefined,
      };
    case INIT_LOGIN_VIA_WE_CHAT:
      return {
        ...state,
        loginStatus: LoginStatusEnum.LOADING,
        weChatLoginStatus: WeChatLoginStatusEnum.LOADING_FWH_QR_CODE,
        authStep: AuthStepEnum.WAIT_FOR_WECHAT_SCAN_QR_CODE,
        userInfo: undefined,
        goBackFromLoginViaWechatFn: action.backFn || false,
      };
    case BACK_TO_CHECK_USERNAME:
      return {
        ...state,
        loginStatus: LoginStatusEnum.INTERACTING,
        normalLoginStatus: NormalLoginStatusEnum.INIT,
        authStep: AuthStepEnum.WAIT_FOR_CHECK_USERNAME,
        userInfo: undefined,
      };
    case RESET_PWD_CONFIRM:
      return {
        ...state,
        authStep: AuthStepEnum.RESET_PWD_EMAIL_CONFIRM,
      };
    case RESET_PWD_VIA_EMAIL:
      return {
        ...state,
        loginStatus: LoginStatusEnum.PROCESSING,
        normalLoginStatus: NormalLoginStatusEnum.SENDING_RESET_PWD_EMAIL,
        userInfo: undefined,
      };
    case RESET_PWD_EMAIL_SENT:
      return {
        ...state,
        loginStatus: LoginStatusEnum.INTERACTING,
        normalLoginStatus: NormalLoginStatusEnum.INIT,
        authStep: AuthStepEnum.RESET_PWD_EMAIL_SENT,
        userInfo: undefined,
      };
    case RESET_PWD_EMAIL_SEND_FAIL:
      return {
        ...state,
        loginStatus: LoginStatusEnum.INTERACTING,
        normalLoginStatus: NormalLoginStatusEnum.INIT,
        userInfo: undefined,
      };
    case RESET_PWD_SEND_NEW_PWD:
      return {
        ...state,
        resetPwdStatus: ResetPwdStatusEnum.PROCESSING,
      };
    case RESET_PWD_DONE:
      return {
        ...state,
        resetPwdStatus: ResetPwdStatusEnum.SUCCESS,
      };
    case RESET_PWD_FAIL:
      return {
        ...state,
        resetPwdStatus: ResetPwdStatusEnum.INTERACTING,
      };
    case RESET_PWD_INIT:
      return {
        ...state,
        resetPwdStatus: ResetPwdStatusEnum.INTERACTING,
      };
    case DO_LOGIN:
      return {
        ...state,
        loginStatus: LoginStatusEnum.PROCESSING,
        normalLoginStatus: NormalLoginStatusEnum.CHECKING_PASSWORD,
        userInfo: undefined,
      };
    case LOGIN_SUCCESS:
      localStorage.setItem('userId',action.userInfo.userId);
      return {
        ...state,
        loginStatus: LoginStatusEnum.SUCCESS,
        normalLoginStatus: NormalLoginStatusEnum.SUCCESS,
        userInfo: action.userInfo,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        loginStatus: action.code,
        normalLoginStatus: action.code,
        userInfo: undefined,
      };
    case DO_LOGOUT:
      return {
        ...state,
        loginStatus: LoginStatusEnum.INTERACTING,
        normalLoginStatus: NormalLoginStatusEnum.INIT,
        weChatLoginStatus: WeChatLoginStatusEnum.LOADING_FWH_QR_CODE,
        authStep: AuthStepEnum.WAIT_FOR_WECHAT_SCAN_QR_CODE,
        existedUsername: undefined,
        userInfo: undefined,
      };
    case INIT_REGISTER:
      return {
        ...state,
        registerStatus: RegisterStatusEnum.INIT,
      };
    case DO_REGISTER:
      return {
        ...state,
        registerStatus: RegisterStatusEnum.REGISTERING,
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        registerStatus: RegisterStatusEnum.SUCCESS,
        existedUsername: action.email,
      };
    case REGISTER_FAIL:
      return {
        ...state,
        registerStatus: RegisterStatusEnum.INIT,
      };
    case WE_CHAT_RESOURCE_LOADED:
      return {
        ...state,
        loginStatus: LoginStatusEnum.INTERACTING,
        weChatLoginStatus: WeChatLoginStatusEnum.WAIT_FOR_SCANNING,
        weChatLoginState: action.state,
      };
    case DO_LOGIN_VIA_WE_CHAT:
      return {
        ...state,
        loginStatus: LoginStatusEnum.PROCESSING,
        weChatLoginStatus: WeChatLoginStatusEnum.PROCESSING,
      };
    case LOGIN_VIA_WE_CHAT_SUCCESS:
      localStorage.setItem('userId',action.userInfo.userId);
      return {
        ...state,
        loginStatus: LoginStatusEnum.SUCCESS,
        weChatLoginStatus: WeChatLoginStatusEnum.SUCCESS,
        weChatFwhTicket: '',
        userInfo: action.userInfo,
      };
    case LOGIN_VIA_WE_CHAT_FAIL:
      return {
        ...state,
        loginStatus: action.code,
        weChatLoginStatus: action.code,
        weChatFwhTicket: '',
        userInfo: undefined,
      };
    case DO_GET_WE_CHAT_FWH_TICKET:
      return {
        ...state,
        weChatLoginStatus: WeChatLoginStatusEnum.LOADING_FWH_QR_CODE,
        weChatFwhTicket: '',
        userInfo: undefined,
      };
    case GET_WE_CHAT_FWH_TICKET_SUCCESS:
      return {
        ...state,
        weChatLoginStatus: WeChatLoginStatusEnum.WAIT_FOR_SCANNING_FWH_QR_CODE,
        weChatFwhTicket: action.ticket,
        userInfo: undefined,
      };
    case GET_WE_CHAT_FWH_TICKET_FAIL:
      return {
        ...state,
        weChatLoginStatus: WeChatLoginStatusEnum.LOADING,
        weChatFwhTicket: '',
        userInfo: undefined,
      };
    case DO_CHANGE_PWD:
      return {
        ...state,
        changePwdStatus: ChangePwdStatus.PROCESSING,
      };
    case CHANGE_PWD_SUCCESS:
      return {
        ...state,
        changePwdStatus: ChangePwdStatus.SUCCESS,
        userInfo: {
          ...state.userInfo,
          strength: action.strength,
          noPassword: false,
        },
      };
    case CHANGE_PWD_FAIL:
      return {
        ...state,
        changePwdStatus: action.errorCode,
      };
    case CHANGE_PWD_INIT:
      return {
        ...state,
        changePwdStatus: ChangePwdStatus.INTERACTING,
      };
    case CHANGE_USER_BASIC_INFO:
      return {
        ...state,
        changeUserBasicInfoStatus: ChangeUserBasicInfoStatus.PROCESSING,
      };
    case CHANGE_USER_BASIC_INFO_SUCCESS:
      const {nick, name, org, province, city, picId} = action.userInfo;
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          nick, name, org, province, city, picId,
        },
        changeUserBasicInfoStatus: ChangeUserBasicInfoStatus.SUCCESS,
      };
    case CHANGE_USER_BASIC_INFO_FAIL:
      return {
        ...state,
        changeUserBasicInfoStatus: action.errorCode,
      };
    case CHANGE_EMAIL_SUCCESS:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          email: action.email,
        },
      };
    default:
      return state;
  }
}