import React from 'react';
import PropTypes from 'prop-types';
import {Button, Checkbox, message, Modal, Upload} from 'antd';

import {getBase64Url} from "@/utils/Common";
import Icon from "@/components/common/common.icon";

import '@/style/common/view/common.view.info.setCoverComponent.less';
import {checkUploadImage} from "@/components/common/common.functions";
import PB, {SimplePB} from "@/libs/simplePB";

class UploadBackgroundComponent extends React.Component {
  state = {
    showUploadNotice: false,
    autoShowUploadNotice: false,
    uploading: false,
  };

  beforeUpload = file => {
    let me = this;

    if (checkUploadImage(file, message, 10)) {
      getBase64Url(file, imgBase64Url => {
        me.setState({
          uploading: true,
        }, () => {
          me.props.bus.emit('view', 'background.do_upload',
            ({viewId: me.props.viewId, dataUrl: imgBase64Url}));
        });
      });
    }
    return false;
  };

  handleCancel = e => {
    let me = this;

    e.stopPropagation();
    me.setState({showUploadNotice: false});
  };

  componentDidMount() {
    let me = this;

    me.props.bus.subscribe(me, 'view', 'background.on_upload', () => {
      if (me.state.showUploadNotice) return;
      if (localStorage.getItem('show_upload_notice') !== '0') me.setState({showUploadNotice: true});
    });

    me.props.bus.subscribe(me, 'view', 'background.uploaded', ({viewId}) => {
      if (viewId === me.props.viewId && me.state.uploading) {
        if (me.state.showUploadNotice) {
          localStorage.setItem('show_upload_notice', me.state.autoShowUploadNotice ? '1' : '0');
        }
        me.setState({showUploadNotice: false, uploading: false});
      }
    });

    me.props.bus.subscribe(me, 'view', 'background.upload_failed', ({viewId}) => {
      if (viewId === me.props.viewId && me.state.uploading) {
        me.setState({uploading: false});
      }
    });
  }

  componentWillUnmount() {
    this.props.bus.remove(this);
  }

  render() {
    let me = this, showUploadNotice = localStorage.getItem('show_upload_notice') !== '0', upload = (
      <Upload
        name={'file_from_container'}
        multiple={false}
        showUploadList={false}
        disabled={me.props.disabled}
        beforeUpload={me.beforeUpload}
        key={'upload'}
      >
        {showUploadNotice ? (
          <Button type="primary" style={{marginLeft: '8px'}} loading={me.state.uploading}>
            选择图片
          </Button>
        ) : me.props.children}
      </Upload>
    );

    return (
      <React.Fragment>
        <span>
          {showUploadNotice ? me.props.children : upload}
        </span>
        <Modal
          title={(
            <span>
              <Icon className={'info-text'} name={'info-circle'} style={{marginRight: '0.5em'}}/>
              提示
            </span>
          )}
          visible={me.state.showUploadNotice}
          footer={[
            (
              <Checkbox
                key={'tip'}
                style={{float: 'left', marginLeft: '8px', height: '32px', lineHeight: '32px'}}
                checked={!me.state.autoShowUploadNotice}
                onChange={e => me.setState({autoShowUploadNotice: !e.target.checked})}
              >
                以后不再显示
              </Checkbox>
            ),
            <Button key="cancel" onClick={me.handleCancel}>
              取消
            </Button>,
            upload,
          ]}
          onCancel={me.handleCancel}
          onClick={e => e.stopPropagation()}
        >
          底图将按原尺寸绘制在画布中央，不支持移动、缩放。<br />
          图片大小可能影响图谱加载速度，最大不可超过10M。<br />
          建议使用深色或透明背景的图片。
        </Modal>
      </React.Fragment>
    );
  }
}

UploadBackgroundComponent.defaultProps = {
  disabled: true,
  bus: PB,
};

UploadBackgroundComponent.propTypes = {
  disabled: PropTypes.bool,
  onCoverChanged: PropTypes.func,
  viewId: PropTypes.string.isRequired,
  bus: PropTypes.instanceOf(SimplePB),
};

export default UploadBackgroundComponent;