import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {message,Tooltip,Button,Checkbox} from 'antd';
import copy from 'copy-to-clipboard';
import {IconTypes} from '@/constants/common';
import PB, {SimplePB} from '@/libs/simplePB';

import Icon from '@/components/common/common.icon';
import ViewDataProvider from "@/components/common/dataProvider/common.dataProvider.view";
import {API_esMicroServiceQuery,API_esMicroServicePresentationQuery} from "@/api/relation";
import {API_getPresentations} from "@/api/presentation";

import style from '@/style/common/microService/common.microService.special.nodeExpand.less';
import {defaultIconData} from '@/components/common/view/presentation/common.view.presentation.logic';
import {API_LoadMicroServiceConfigList} from '@/api/microService';
import PresentationDataProvider from '@/components/common/dataProvider/common.dataProvider.presentation';
import {uniqueArrayObj} from "@/utils/Common";
const presentationSvg = () => (
  <svg t="1661161487435" class="icon" viewBox="0 0 1047 1024" version="1.1" p-id="1098" width="1em" height="1em">
    <path d="M900.179878 99.090921v805.641091h-801.089976V99.089943h801.089976z m0-77.37872h-801.089976c-40.964917 0-77.37872 36.413803-77.378721 77.377742v805.642069c0 40.963939 36.413803 77.377742 77.377743 77.377742h805.642068c40.963939 0 77.377742-36.412825 77.377743-77.377742V99.089943c-4.551114-40.963939-36.412825-77.377742-81.929835-77.377742z" p-id="1099" fill="#dddddd"></path>
    <path d="M217.454075 287.032184a45.782018 44.444261 90 1 0 88.888521 0 45.782018 44.444261 90 1 0-88.888521 0z" p-id="1100" fill="#dddddd"></path>
    <path d="M217.454075 500.682255a45.782018 44.444261 90 1 0 88.888521 0 45.782018 44.444261 90 1 0-88.888521 0z" p-id="1101" fill="#dddddd"></path>
    <path d="M217.454075 714.332326a45.782018 44.444261 90 1 0 88.888521 0 45.782018 44.444261 90 1 0-88.888521 0z" p-id="1102" fill="#dddddd"></path>
    <path d="M765.597349 691.43985h-370.367209c-11.852064 0-22.221641 10.683481-22.221642 22.892476s10.370556 22.89052 22.221642 22.89052h370.367209c11.852064 0 22.222619-10.682504 22.222619-22.891498s-10.370556-22.89052-22.222619-22.89052z m-370.367209-381.51519h370.367209c11.852064 0 22.222619-10.683481 22.222619-22.892476s-10.370556-22.89052-22.222619-22.89052h-370.367209c-11.852064 0-22.221641 10.682504-22.221642 22.891498s10.370556 22.89052 22.221642 22.89052z m370.367209 167.867075h-370.367209c-11.852064 0-22.221641 10.682504-22.221642 22.89052s10.370556 22.891498 22.221642 22.891498h370.367209c11.852064 0 22.222619-10.682504 22.222619-22.891498s-10.370556-22.89052-22.222619-22.89052z" p-id="1103" fill="#dddddd"></path>
  </svg>
);

class MicroServiceSpecialNodeExpand extends React.PureComponent {

  state = {
    status: 'idle',
    nodeList:[],
    showBox: false
  };

  timer = undefined; // 自动隐藏定时器
  isMouseOver = false; // 鼠标是否移入此区域
  doExpand = (keyword) => {
    let me = this,reg=/[ ,　]/g;
    if(keyword.replace(reg,'') ==='' || keyword.replace(reg,'')===null){
      return;
    }
    API_esMicroServicePresentationQuery(keyword,1,30).then(response => {
      if (response && response.data && response.data.code === 0) {
        let data = response.data.data;
        if (me.props.nodeInfo && me.props.nodeInfo.fname === keyword) {
          let pids = [];
          data.forEach((item, idx) => {
            pids.push(item.id);
          });
          API_getPresentations(pids.join(',')).then(response => {
            if (response && response.data && response.data.code === 0) {
              // 结果有效
              let _data = [];
              response.data.data.forEach((item, idx) => {
                item.id = item.presentationId;
                item.type = 1;
                _data.push(item);
              });
              me.setState({
                status: 'success',
                nodeList: uniqueArrayObj(_data,'title').slice(0,10)
              }, () => {
                me.timer = setTimeout(() => {
                  if (me.props.visible && !me.isMouseOver) {
                    me.setState({status: 'idle'})
                  }
                }, 2000);
              });
            } else {
              if (me.props.nodeInfo && me.props.nodeInfo.fname === keyword) {
                me.setState({
                  status: 'failed',
                });
              }
            }
          }).catch(error => {
            console.warn(`获取信息失败，keyword: ${keyword}`,error);
          });
          
        }
      } else {
        if (me.props.nodeInfo && me.props.nodeInfo.fname === keyword) {
          me.setState({
            status: 'failed',
          });
        }
      }
    }).catch(error => {
      console.warn(`获取信息失败，keyword: ${keyword}`,error);
    });
  };

  gotoMicroServiceList  = () => {
    let me = this, nodeInfo = me.props.nodeInfo;
    let keyword = nodeInfo.fname;
    me.props.bus.emit('view', 'micro_service.config.show_market_drawer',
                    {projectId: me.props.projectId,keyword:keyword});

  };

  gotoMicroService  = (info) => {
    let me = this;
    PresentationDataProvider.getPresentations(info.id).then(data => {
      if(data.length>0){
        me.callPresentation(data[0]);
      }
    }).catch(error => {
      console.warn(`获取信息失败: `,error);
    })
  };

  callPresentation = info => {
    let me = this;

    if (!me.props.viewId) return;
    info.id = info.presentationId;
    info.version = "v1.0";
    info.dataType = 1;
    info.tag = "专题报告";

    me.props.bus.emit('view', 'micro_service.service.show_modal',
      {viewId: me.props.viewId, serviceId: info.id, dataType: 1, serviceInfo: info});
  };

  doMicroService = info => {
    let me = this;
    if (!me.props.viewId) return;

    switch (info.version) {
      case 'v0.6.1':
        me.props.bus.emit(info.calcServerId, info.calcServerPathId,{viewId: me.props.viewId, serviceId: info.id});
        break;
      case 'v0.6.2':
        me.callFunctionMicroService(info);
        break;
      case 'v0.6.3':
        me.callDefaultMicroService(info.id);
        break;
      default:
        me.callMicroService(info.id);
        break;
    }
  };

  callFunctionMicroService = info => {
    let me = this;
    if (!me.props.viewId) return;

  };

  callMicroService = id => {
    let me = this;

    if (!me.props.viewId) return;

    me.props.bus.emit('view', 'micro_service.service.show_modal',{viewId: me.props.viewId, serviceId: id});
  };

  callDefaultMicroService = id => {
    let me = this;
    if (!me.props.viewId) return;

    switch (id) {
      case 'ys-1':
        me.props.bus.emit('view', 'view.info.setViewInfo');
        break;
      case 'ys-3':
        me.props.bus.emit('view', 'desktop_background.show_config_modal',{viewId: me.props.viewId});
        break;
      case 'ys-4':
          me.props.bus.emit('view', 'banner.show_upload_modal.show',{viewId: me.props.viewId});
          break;
      case 'fx-1':
        me.props.bus.emit('view', 'view.share.exportPicture');
        break;
      case 'fx-2':
        me.props.bus.emit('relation', 'graph.export',{viewId: me.props.viewId});
          break;
      case 'fx-3':
        me.props.bus.emit('view', 'view.share.exportLink');
        break;
      case 'fx-4':
        me.props.bus.emit('printing', 'new');
          break;
      default:
        me.props.bus.emit('view', 'micro_service.service.show_modal',{viewId: me.props.viewId, serviceId: id});
        break;
    }
  };

  renderNodeItem = (node, index) => {
    let me = this;
    return (
      <div
        key={`node-item-${index}`}
        className={`${style['item']}`}
        onClick={e => {
          e.stopPropagation();
          me.gotoMicroService(node);
        }}
      >
        <div style={{display: 'flex', alignItems: 'center', width: '100%'}}>
          <view className={style['data-line']}>
            {node.type===1 && <Icon component={presentationSvg} />}
            {node.type!==1 && <Icon name={'icon-micro-service'} type={IconTypes.ICON_FONT} />}
					</view>
          <span className={`${style['name']}`}>
            <Tooltip placement="top" title={node.title} arrowPointAtCenter>{node.title}</Tooltip>
          </span>
        </div>
      </div>
    );
  };

  renderNodeList = () => {
    let me = this, nodeList = me.state.nodeList;

    return nodeList.length > 0 ? (
      <>
        <div className={`${style['list']}`}>
          {
            nodeList.map(me.renderNodeItem)
          }
        </div>
        <div style={{width: '98%', margin: '.3rem auto', borderBottom: '1px dashed rgba(201, 201, 201, 0.3)'}}/>
        <div className={style['action-box']}>
          <Tooltip
            placement={"bottom"}
            title={'搜索更多'}
            overlayClassName={'dark-theme'}
          >
            <a
              onClick={e => this.gotoMicroServiceList(e)}
              style={{float: 'right',marginLeft:'.5rem'}}
            >
              <Icon
              name={'icon-search'}
              type={IconTypes.ICON_FONT}
              style={{marginRight: '.5rem', textAlign: 'right'}}
            /> 更多
            </a>
          </Tooltip>
        </div>
      </>
    ) : null;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let me = this, nodeInfo = me.props.nodeInfo, previousNodeInfo = prevProps.nodeInfo;

    if (
      (nodeInfo && !prevProps.visible && me.props.visible)
      || (me.props.visible && nodeInfo && (!previousNodeInfo || nodeInfo.id !== previousNodeInfo.id))
    ) {
      // 节点信息存在，面板从不可见变为可见，自动加载推荐填充信息
      // 面板可见，节点信息发生变化，自动加载推荐填充信息
      me.setState({
        status: 'processing',
        showBox:true
      });
      // 自动隐藏数据初始化
      me.isMouseOver = false;
      if (me.timer) {
        clearTimeout(me.timer);
        me.timer = undefined;
      }
      // 触发
      me.doExpand(nodeInfo.fname);
    }else if(previousNodeInfo && !nodeInfo && prevProps.visible && !me.props.visible){
      if (me.timer) {
        clearTimeout(me.timer);
        me.timer = undefined;
      }
      me.timer = setTimeout(() => {
        me.setState({showBox: false})
      }, 2000); 
    }
  }

  componentWillUnmount() {
    let me = this;

    if (me.timer) {
      clearTimeout(me.timer);
      me.timer = undefined;
    }
  }

  render() {
    let me = this, visible = me.props.visible && me.state.status !== 'idle' && me.state.status !== 'failed';

    return  me.state.showBox ? (
      <div
        className={`${style['frame']} dark-theme ${visible ? style['fadeIn'] : style['fadeOut']}`}
        onMouseEnter={e => {
          e.stopPropagation();
          me.isMouseOver = true;
        }}
      >
        {
          me.state.nodeList && me.state.nodeList.length>0 ? me.renderNodeList() : (
            <div className={style['tips']}>
              <span style={{position: 'relative', top: '-4px'}}>... ...</span>
            </div>
          )
        }
      </div>
    ):null;
  }
}

MicroServiceSpecialNodeExpand.defaultProps = {
  bus: PB,
};

MicroServiceSpecialNodeExpand.propTypes = {
  bus: PropTypes.instanceOf(SimplePB),
  viewDataProvider: PropTypes.instanceOf(ViewDataProvider).isRequired,
  visible: PropTypes.bool.isRequired,
  viewId: PropTypes.string,
  nodeInfo: PropTypes.object,
};

export default MicroServiceSpecialNodeExpand;