import {
    SUBMIT_FEEDBACK,
    SUBMIT_FEEDBACK_SUCCESS,
    SUBMIT_FEEDBACK_FAILED,
    SUBMIT_PRINTING,
    SUBMIT_PRINTING_SUCCESS,
    SUBMIT_PRINTING_FAILED,
} from "./actionTypes";

export const submitFeedbackAction = (params) => ({ type: SUBMIT_FEEDBACK, params });
export const submitFeedbackSuccessAction = () => ({ type: SUBMIT_FEEDBACK_SUCCESS });
export const submitFeedbackFailedAction = () => ({ type: SUBMIT_FEEDBACK_FAILED });

export const submitPrintingAction = (params) => ({ type: SUBMIT_PRINTING, params });
export const submitPrintingSuccessAction = () => ({ type: SUBMIT_PRINTING_SUCCESS });
export const submitPrintingFailedAction = () => ({ type: SUBMIT_PRINTING_FAILED });
