import React from 'react';
import { Modal, Tooltip, Timeline, Button, message } from 'antd';
import PropTypes from 'prop-types';

import { REQUEST_BASE, getToken } from '@/utils/HttpUtil';

import style from '@/style/components/main.nodeInfo.less';
import Icon from '@/components/common/common.icon';
import ViewDataProvider from '@/components/common/dataProvider/common.dataProvider.view';
import { NodeEvents } from '@/libs/view/network/events';
import FileUploadModal from '@/components/mainView/main.fileUploadModal';

class NodeInfoAttachmentListModal extends React.Component {
	state = {
		nodeId: undefined,
		confirmingItemList: [],
		removingItemList: [],
		attachmentList: [],
		showUploadModal: false,
		confirmRemoveItemId: undefined, // 确定删除的文件id
	};

	onAttachmentRemove = (attachment) => {
		this.setState({
      confirmingItemList: this.state.confirmingItemList.concat(attachment['id']),
      confirmRemoveItemId: undefined,
		});
	};

	onAttachmentRemoveConfirmed = (attachment) => {
		let me = this;
		me.setState({
			removingItemList: [...me.state.removingItemList, attachment['id']],
		}, () => {
			me.props.networkRef.removeFiles(attachment['id'], me.state.nodeId).then(res => {
				console.log('NodeInfo -> attachmentListModal -> onAttachmentRemoveConfirmed -> res :', res);
				let temp = me.state.attachmentList.filter(v => v.id !== attachment.id);
				me.setState({
					attachmentList: temp,
					confirmingItemList: me.state.confirmingItemList.filter(v => v !== attachment['id']),
					removingItemList: me.state.removingItemList.filter(v => v !== attachment['id']),
				});
			}).catch(() => {
				me.setState({
					removingItemList: me.state.removingItemList.filter(v => v !== attachment['id']),
				});
			});
		});
	};

	onAttachmentRemoveCancelled = (attachment) => {
		this.setState({confirmingItemList: this.state.confirmingItemList.filter(v => v !== attachment['id'])});
	};

	// 删除一组附加信息中的某一个文件
	onAttachmentItemRemoveConfirmed = (e, attachment, fileInfo) => {
		e.preventDefault();
		let me = this;
		console.log(' NodeInfo -> attachmentListModal -> onAttachmentItemRemoveConfirmed -> attachment :', attachment);
		console.log(' NodeInfo -> attachmentListModal -> onAttachmentItemRemoveConfirmed -> fileInfo :', fileInfo);
		if (attachment.fileList.length === 1) {
		  this.onAttachmentRemoveConfirmed(attachment);
    } else {
			me.props.networkRef.removeFileItem(fileInfo['id'], me.state.nodeId).then((res) => {
				console.log('NodeInfo -> attachmentListModal -> onAttachmentItemRemoveConfirmed -> res :', res);
				let temp = me.state.attachmentList;
				temp.forEach(item => {
					if (item.id === attachment.id) {
						item.fileList = item.fileList.filter(v => v.id !== fileInfo.id);
					}
				});
				me.setState({attachmentList: temp});
			}).catch(() => {
				message.error('删除附件失败！');
				this.onAttachmentItemRemoveCancelled();
			});
    }
	};


	onAttachmentItemRemoveCancelled = () => {
		this.setState({
			confirmRemoveItemId: undefined,
		})
	};

	renderAttachmentItem = (attachment) => {
		// noinspection RequiredAttributes
		const editButtons = (
			<span style={{float: 'right'}} className={style['record-action']}>
        <span
	        style={{
		        display: this.state.confirmingItemList.indexOf(attachment['id']) >= 0 ? 'inline-block' : 'none',
		        marginRight: '0.5em',
	        }}
        >
          确认删除？
          <Button
	          shape="circle" icon="check" size={'small'} type="danger"
	          onClick={(e) => {
		          e.preventDefault();
		          this.onAttachmentRemoveConfirmed(attachment);
	          }}
	          style={{marginRight: '0.5em'}}
          />
          <Button
	          shape="circle" icon="close" size={'small'}
	          onClick={(e) => {
		          e.preventDefault();
		          this.onAttachmentRemoveCancelled(attachment);
	          }}
          />
        </span>
        <Button
	        shape="circle" icon="delete" size={'small'}
	        type="danger" style={{
	        float: 'right',
	        display: this.state.confirmingItemList.indexOf(attachment['id']) < 0 ? 'inline-block' : 'none',
        }}
	        loading={this.state.removingItemList.indexOf(attachment['id']) >= 0}
	        onClick={(e) => {
		        e.preventDefault();
		        this.onAttachmentRemove(attachment);
	        }}
        />
      </span>
		);
		if (attachment['remark']) {
			try {
				attachment['remark'] = JSON.parse(attachment['remark']);
				attachment['remark'] = _.isPlainObject(attachment['remark']) ? attachment['remark'].comment : attachment['remark'];
			} catch (e) {
				// ignore
			}
		}
		return (
			<div
				className={style['record-frame']}
				onMouseLeave={() => {
					if (this.state.confirmingItemList.indexOf(attachment['id']) >= 0) {
						this.onAttachmentRemoveCancelled(attachment);
					}
				}}
			>
				{attachment['remark'] ?
					<div style={{hyphens: 'auto', wordBreak: 'break-word'}}>{attachment['remark']}</div> : ''}
				{
					attachment['fileList'] && attachment['fileList'].length > 0
						?
						attachment['fileList'].map(
							fileInfo => (
								<div key={`file-${fileInfo['fileId']}`}
                     className={style['record-frame-file']}
                     onMouseLeave={e => {
                       e.stopPropagation();
                       this.onAttachmentItemRemoveCancelled();
                     }}
                >
									{
										['png', 'jpg', 'gif', 'jpeg'].includes(fileInfo['fileName'].split(/\./g).pop().toLowerCase()) ? (
											<Tooltip
												title={(
													<div style={{maxHeight: '10rem', overflow: 'hidden'}}>
														<img
															src={`${REQUEST_BASE}/view/project/file/thumb/200/200/${fileInfo['fileId']}?Authorization=${getToken()}`}
															alt={fileInfo['fileName']}
														/>
													</div>
												)}
												placement={'left'}
											>
												<a
													href={`${REQUEST_BASE}/view/project/file/${fileInfo['fileId']}?Authorization=${getToken()}&view=true`}
													target={'_blank'}
													style={{hyphens: 'auto'}}
												>
													<div style={{height: 0, width: 0, opacity: 0, overflow: 'hidden'}}>
														<img
															src={`${REQUEST_BASE}/view/project/file/thumb/200/200/${fileInfo['fileId']}?Authorization=${getToken()}`}
															alt={fileInfo['fileName']}
														/>
													</div>
													<Icon name="search" theme="outlined" style={{marginRight: '0.5em'}}/>{fileInfo['fileName']}
												</a>
											</Tooltip>
										) : (
											<Tooltip title={'查看附件'}>
												<a
													href={`${REQUEST_BASE}/view/project/file/${fileInfo['fileId']}?Authorization=${getToken()}&view=true`}
													target={'_blank'}
													style={{hyphens: 'auto'}}
												>
													<Icon name="search" theme="outlined" style={{marginRight: '0.5em'}}/>{fileInfo['fileName']}
												</a>
											</Tooltip>
										)
									}
									<Tooltip title={'删除附件'}>
                    <span
                      className={style['file-delete-box']}
                      style={{
                        display: this.state.confirmRemoveItemId && this.state.confirmRemoveItemId === fileInfo.id ? null : 'none',
                        marginLeft: '1rem',
                      }}
                    >
                      确认删除？
                      <Button
	                      shape="circle" icon="check" size={'small'} type="danger"
	                      onClick={(e) => {
		                      this.onAttachmentItemRemoveConfirmed(e, attachment, fileInfo);
	                      }}
	                      style={{marginRight: '0.5em', height: '21px', width: '21px', minWidth: '21px'}}
                      />
                      <Button
	                      shape="circle" icon="close" size={'small'}
	                      onClick={(e) => {
		                      e.stopPropagation();
                          this.onAttachmentRemoveCancelled(attachment);
		                      this.onAttachmentItemRemoveCancelled();
	                      }}
												style={{height: '21px', width: '21px', minWidth: '21px'}}
                      />
                    </span>
										<span className={style['file-delete-box']}
                            style={{ display: this.state.confirmRemoveItemId && this.state.confirmRemoveItemId === fileInfo.id ? 'none' : null}}
										        onClick={e => {
											        e.stopPropagation();
                              this.onAttachmentRemoveCancelled(attachment);
											        this.setState({
												        confirmRemoveItemId: fileInfo.id,
											        });
										        }}
										>
											<Icon name="delete" theme="outlined" size={'small'}/>
										</span>

									</Tooltip>

									{/*&nbsp;
                  <a href={`${REQUEST_BASE}/view/project/file/${fileInfo['fileId']}?Authorization=${getToken()}`}
                     target={'_download'} style={{hyphens: 'auto', marginLeft: '0.5em'}}>
                    [ 下载 ]
                  </a>*/}
								</div>
							)
						)
						:
						''
				}
				<div className={style['record-title']}>
					<Icon name={'calendar'} theme="outlined" style={{marginRight: '0.5em'}}/>{attachment['recordTime']}
					{
						this.props.editable ? editButtons : ''
					}
				</div>
			</div>
		);
	};

	loadData = () => {
		let me = this;

		requestAnimationFrame(() => {
			let nodeIdChanged = me.state.nodeId !== me.props.nodeId;
			let nodeInfo;
			if (me.props.networkRef) {
				nodeInfo = me.props.networkRef.getNode(me.props.nodeId);
			} else if (me.props.node) {
				nodeInfo = me.props.node;
			}

			me.setState({
				nodeId: me.props.nodeId,
				attachmentList: nodeInfo ? nodeInfo['prop'] || [] : [],
			}, () => {
				if (nodeIdChanged && me.props.networkRef) {
					// noinspection JSIgnoredPromiseFromCall
					me.props.networkRef.loadNodeDetailInfo(me.state.nodeId);
				}
			});
		});
	};

	componentDidMount() {
		let me = this;
		if (this.props.networkRef) {
			this.props.networkRef.subscribe(this, NodeEvents.DETAIL_INFO_LOADED, (nodeId, nodeInfo) => {
				if (nodeId !== me.state.nodeId) return;
				me.setState({
					attachmentList: nodeInfo['prop'] || [],
				});
			}).subscribe(this, NodeEvents.ATTACHMENT_UPLOADED, nodeId => {
				if (nodeId !== me.state.nodeId) return;
				// noinspection JSIgnoredPromiseFromCall
				me.props.networkRef.loadNodeDetailInfo(me.state.nodeId);
			});
		} else if (me.props.node) {
			me.setState({
				attachmentList: me.props.node['prop'] || [],
			});
		}

		me.loadData();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		let me = this;

		if (!prevProps.visible && me.props.visible) {
			me.loadData();
		}
	}

	componentWillUnmount() {
		if (this.props.networkRef) {
			this.props.networkRef.unSubscribe(this);
		}
	}

	render() {
		let me = this;
		const {editable, networkRef, nodeId} = me.props;
		const {attachmentList} = me.state;
		// noinspection RequiredAttributes
		return (
			<Modal
				title={
					<span className={style['related-info-panel-listed-result-header']}>
            {`附加信息${attachmentList.length}条`}
						{
							editable ? (
								<Tooltip title={`添加信息`} placement={'right'}>
									<Button
										shape="circle"
										icon="plus"
										type={'primary'}
										size={'small'}
										ghost={true} // 大色块好像太扎眼了，改为主色线条
										style={{marginLeft: '0.5em'}}
										onClick={() => me.setState({showUploadModal: true})}
									/>
								</Tooltip>
							) : null
						}
          </span>
				}
				wrapClassName={style['attachment-list-wrapper']}
				footer={[
					(<Button key={'close'} onClick={me.props.onClose}>关闭</Button>),
				]}
				visible={me.props.visible}
				onCancel={me.props.onClose}
				centered={true}
			>
				{
					attachmentList.length > 0 ? (
						<Timeline className={style['attachment-list']}>
							{attachmentList.map((attachment, idx) => (
								<Timeline.Item key={`prop_${idx}`}>
									{me.renderAttachmentItem(attachment)}
								</Timeline.Item>
							))}
						</Timeline>
					) : (
						<div
							style={{
								padding: '16px 7px',
								marginTop: '-11px',
								fontSize: '14px',
								lineHeight: 1.5,
								textAlign: 'center',
							}}
						>
							没有相关附加信息
						</div>
					)
				}
				{
					me.props.editable ? (
						<FileUploadModal
							networkRef={networkRef}
							visible={me.state.showUploadModal}
							nodeId={nodeId}
							onClose={() => me.setState({showUploadModal: false})}
						/>
					) : null
				}
			</Modal>
		);
	}
}

NodeInfoAttachmentListModal.defaultProps = {
	editable: false,
};

NodeInfoAttachmentListModal.propTypes = {
	nodeId: PropTypes.string.isRequired,
	editable: PropTypes.bool.isRequired,
	networkRef: PropTypes.instanceOf(ViewDataProvider),
	visible: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	node: PropTypes.object,
};

export default NodeInfoAttachmentListModal;