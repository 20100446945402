import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import IconAvatar from '@/components/common/common.iconAvatar';
import PB from "@/libs/simplePB";
import Icon from "@/components/common/common.icon";
import {IconTypes} from "@/constants/common";
import {
  getNodeIcon,
  /*TYPE_FIELD_NAME,
  NODE_TYPE_TAG,
  getExternalLinkUrl,*/
} from "@/constants/vis.defaultDefine.1";

class CommonNodeSummaryInfo extends React.Component {
  onTitleClicked = (e) => {
    e.preventDefault();

    const {node, /*permanent, */onTitleClicked} = this.props;

    if (_.isFunction(onTitleClicked)) {
      onTitleClicked(node);
    } else {
      PB.emit('network', 'focus', node);
    }
  };

  render() {
    const {node} = this.props;
    const {name, type, color} = getNodeIcon(node);
    const actions = [];

    // 其他操作
    if (this.props.actions && this.props.actions.length > 0) {
      Array.prototype.push.apply(actions, this.props.actions);
    }
    return (
      <div className="ant-list-item-meta">
        {
          this.props.showIcon ? (
            <div className="ant-list-item-meta-avatar">
              <a onClick={(e) => {e.preventDefault(); PB.emit('network', 'focus', node)}}>
                <IconAvatar name={name} type={type} color={color} />
              </a>
            </div>
          ) : ''
        }
        <div className="ant-list-item-meta-content">
          <h4 className="ant-list-item-meta-title">
            <a
              onClick={this.onTitleClicked}
            >
              <span>{node.fname}</span>
            </a>
          </h4>
          {
            (node.url) ? (
              <div className='ant-list-item-meta-description'>
                <a target={'_blank'} href={node.url} className={'single-line'}>
                  <Icon name={'icon-link-o'} type={IconTypes.ICON_FONT} style={{marginRight: '0.5em'}}/>
                  {node.url}
                </a>
              </div>
            ) : null
          }
          {
            actions.length > 0 ? (
              <ul className="ant-list-item-action" style={{marginLeft: 'auto'}}>
                {actions}
              </ul>
            ) : <br/>
          }
        </div>
      </div>
    );
  }
}

CommonNodeSummaryInfo.defaultProps = {
  permanent: false,
  showIcon: true,
  actions: [],
};

CommonNodeSummaryInfo.propTypes = {
  node: PropTypes.object.isRequired,
  permanent: PropTypes.bool,
  showIcon: PropTypes.bool,
  actions: PropTypes.array,
  onTitleClicked: PropTypes.func,
};

export default CommonNodeSummaryInfo;
