export const DO_CHECK_USERNAME = "DO_CHECK_USERNAME";
export const USER_FOUND = "USER_FOUND";
export const INIT_LOGIN_VIA_USERNAME = "INIT_LOGIN_VIA_USERNAME";
export const INIT_LOGIN_VIA_WE_CHAT = "INIT_LOGIN_VIA_WE_CHAT";
export const BACK_TO_CHECK_USERNAME = "BACK_TO_CHECK_USERNAME";
export const DO_LOGIN = "DO_LOGIN";
export const DO_MAGIC_LOGIN = "DO_MAGIC_LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const DO_LOGOUT = "DO_LOGOUT";
export const INIT_REGISTER = "INIT_REGISTER";
export const DO_REGISTER = "DO_REGISTER";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const RESET_PWD_CONFIRM = 'RESET_PWD_CONFIRM';
export const RESET_PWD_VIA_EMAIL = 'RESET_PWD_VIA_EMAIL';
export const RESET_PWD_EMAIL_SENT = 'RESET_PWD_EMAIL_SENT';
export const RESET_PWD_EMAIL_SEND_FAIL = 'RESET_PWD_EMAIL_SEND_FAIL';
export const RESET_PWD_SEND_NEW_PWD = 'RESET_PWD_SEND_NEW_PWD';
export const RESET_PWD_DONE = 'RESET_PWD_DONE';
export const RESET_PWD_FAIL = 'RESET_PWD_FAIL';
export const RESET_PWD_INIT = 'RESET_PWD_INIT';
export const WE_CHAT_RESOURCE_LOADED = 'WE_CHAT_RESOURCE_LOADED';
export const DO_LOGIN_VIA_WE_CHAT = 'DO_LOGIN_VIA_WE_CHAT';
export const LOGIN_VIA_WE_CHAT_SUCCESS = 'LOGIN_VIA_WE_CHAT_SUCCESS';
export const LOGIN_VIA_WE_CHAT_FAIL = 'LOGIN_VIA_WE_CHAT_FAIL';
export const DO_GET_WE_CHAT_FWH_TICKET = 'DO_GET_WE_CHAT_FWH_TICKET';
export const GET_WE_CHAT_FWH_TICKET_SUCCESS = 'GET_WE_CHAT_FWH_TICKET_SUCCESS';
export const GET_WE_CHAT_FWH_TICKET_FAIL = 'GET_WE_CHAT_FWH_TICKET_FAIL';
export const DO_CHECK_WE_CHAT_FWH_TICKET = 'DO_CHECK_WE_CHAT_FWH_TICKET';
export const DO_CHANGE_PWD = 'DO_CHANGE_PWD';
export const CHANGE_PWD_INIT = 'CHANGE_PWD_INIT';
export const CHANGE_PWD_SUCCESS = 'CHANGE_PWD_SUCCESS';
export const CHANGE_PWD_FAIL = 'CHANGE_PWD_FAIL';
export const CHANGE_USER_BASIC_INFO = 'CHANGE_USER_BASIC_INFO';
export const CHANGE_USER_BASIC_INFO_SUCCESS = 'CHANGE_USER_BASIC_INFO_SUCCESS';
export const CHANGE_USER_BASIC_INFO_FAIL = 'CHANGE_USER_BASIC_INFO_FAIL';
export const CHANGE_EMAIL_SUCCESS = 'CHANGE_EMAIL_SUCCESS';