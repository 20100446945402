/**
 * （节点）待办中心
 */
import React from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import Qs from "qs";

import {
  changePwdInitAction,
  changeUserBasicInfoAction,
  doChangePwdAction,
  doLogoutAction,
} from "@/redux-saga/account/actions";
import PB from "@/libs/simplePB";
import layoutStyles from "@/style/default/defaultViewLayout.less";
import ViewDataProvider from "@/components/common/dataProvider/common.dataProvider.view";
import { Copyright } from "@/components/framework/frame.components";
// import StickyNodes from "@/components/common/common.stickyNodes";
// import Feedback from "@/components/feedback/feedback.1.0";
import { submitFeedbackAction } from "@/redux-saga/system/actions";
import { aidDomain, SysUIConfig } from "@/constants/sys.config";
// import BadgeInfo from "@/components/mainView/main.badgeInfo";
import Loading from "@/components/common/common.loading";
import TodoAIConsole from "@/components/common/relation/common.relation.aiConsole";

// 路由组件
import TodoTimelineView from "@/components/todoView/todo.timeline";
import intl from 'react-intl-universal';

class TodoView extends React.PureComponent {
  state = {
    // 加载页面遮罩
    viewLoading: {
      status: false,
      content: undefined, // 显示文字
    },
  };

  // url传入的参数
  // limit 返回 tag 数量。0 返回全部数据，默认返回150个
  // sortBy 后台排序 各个组件接口自己定义
  _query = {
    viewId: undefined,
    limit: undefined,
    sortBy: undefined,
    isDev: false,
    isDebug: false,
  };

  // ai对话框
  aiConfigRef = undefined;

  aiConfigRect = {
    height: window.innerHeight * 0.8,
    width: 28 * this.rem,
  };

  componentDidMount() {
    document.title = "待办中心 -  "+intl.get('Custom.general.title');

    this.aiConfigRect = {
      height: this.aiConfigRef.containerBoundingRect.consoleHeight,
      width: this.aiConfigRef.containerBoundingRect.consoleWidth,
    };
  }

  componentWillUnmount() {
    PB.remove(this);
  }

  render() {
    let me = this;
    if (this.props.history.location && this.props.history.location.search) {
      let qs = Qs.parse(this.props.history.location.search, {
        ignoreQueryPrefix: true,
        strictNullHandling: true,
      });

      if (qs.hasOwnProperty("is_dev")) {
        this._query.isDev = qs.is_dev == 1;
      }
      if (qs.hasOwnProperty("is_debug")) {
        this._query.isDebug = qs.is_debug == 1;
      }
      if (qs.hasOwnProperty("limit")) {
        this._query.limit = qs.limit;
      }
      if (qs.hasOwnProperty("sort_by")) {
        this._query.sortBy = qs.sort_by;
      }
      if (qs.hasOwnProperty("view_id")) {
        this._query.viewId = qs.view_id;
      }
    }

    const currentUserId = parseInt(localStorage.getItem("userId"));

    return (
      <div className={layoutStyles["layout-wrap"]}>
        <Loading />
        <div className={layoutStyles["header"]}>
          <div className={layoutStyles["logo"]}>
            <img
              onClick={() => {
                this.props.history.push({
                  pathname: "/index",
                  state: { type: "intro" },
                });
              }}
              src={intl.get('locale')==='zh-cn'?'/assets/logo-hans.png':'/assets/logo-hans_en.png'}
              alt={"炬图"}
            />
          </div>
          {/*           <BadgeInfo
            currentUserInfo={this.props.userInfo}
            doChangePwd={this.props.doChangePwd}
            onLogout={this.props.doLogout}
            history={this.props.history}
            changeUserBasicInfoStatus={this.props.changeUserBasicInfoStatus}
            onSaveUserBasicInfo={this.props.onChangeUserBasicInfo}
            style={{
              visibility: SysUIConfig.BadgeInfo.visibility,
            }}
          />
          <Feedback
            feedbackLoading={this.props.feedbackLoading}
            feedbackResult={this.props.feedbackResult}
            submitFeedbackAction={this.props.submitFeedbackAction}
          />
          <StickyNodes />
 */}{" "}
        </div>
        <div
          className={`${layoutStyles["content-outer-fullscreen"]} dark-theme scrollbar-18`}
        >
          <Switch>
            <Route
              exact
              path={"/todo"}
              component={({ match, history }) => (
                <TodoTimelineView
                  history={history}
                  userInfo={this.props.userInfo}
                  query={this._query}
                  currentUserId={currentUserId}
                />
              )}
            />
          </Switch>
          <Copyright className={layoutStyles["version"]} />
        </div>

        {/* <NodeTodo currentUserId={currentUserId}/> */}

        <TodoAIConsole
          ref={(ele) => {
            if (!ele || ele === me.aiConfigRef) return;
            me.aiConfigRef = ele;
          }}
          currentUserInfo={me.props.userInfo}
          initialLeft={window.innerWidth - me.aiConfigRect.width - 3}
          initialTop={(window.innerHeight - me.aiConfigRect.height) / 2}
          viewDataProvider={ViewDataProvider}
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    userInfo: state.account.userInfo,
    changePwdStatus: state.account.changePwdStatus,
    feedbackLoading: state.system.feedbackLoading,
    feedbackResult: state.system.feedbackResult,
    changeUserBasicInfoStatus: state.account.changeUserBasicInfoStatus,
  }),
  (dispatch) => ({
    doLogout: () => dispatch(doLogoutAction()),
    submitFeedbackAction: (params) => dispatch(submitFeedbackAction(params)),
    doChangePwd: (oldPwd, newPwd, strength) =>
      dispatch(doChangePwdAction(oldPwd, newPwd, strength)),
    resetChangePwdStatus: () => dispatch(changePwdInitAction()),
    onChangeUserBasicInfo: (userInfo) =>
      dispatch(changeUserBasicInfoAction(userInfo)),
  })
)(TodoView);
