import React from 'react';
import PropTypes from 'prop-types';

import PB, {SimplePB} from '@/libs/simplePB';

import {getNodeIconType, iconConfig} from "@/constants/iconConfig";

import {ChinaADCStatisticsType, stringToMoment} from '@/components/common/common.functions';

import {statisticsByTime} from '@/components/common/view/statistics/node/common.view.statistics.node.logic.date';
import {statisticsByArea} from '@/components/common/view/statistics/node/common.view.statistics.node.logic.area';
import {statisticsByIcon} from "@/components/common/view/statistics/node/common.view.statistics.node.logic.icon";
import {statisticsByProgress} from '@/components/common/view/statistics/node/common.view.statistics.node.logic.progress';
import {statisticsByFavorite} from "@/components/common/view/statistics/node/common.view.statistics.node.logic.favorite";
import {statisticsByAuthor} from "@/components/common/view/statistics/node/common.view.statistics.node.logic.author";

const cache = {
  key: `node-statistics-${Math.random()}`,
  resultMap: {
    ['conditionKey']: [],
  },
};

export const STATISTIC_CONDITIONS = {
  CREATE_DATE: {
    key: 'CREATE_DATE',
    category: 'CREATE_TIME',
    defaultLimit: 10,
    defaultSort: 'DESC',
    cacheKeyFn: ({distinct, sort, limit}) => {
      return `CREATE_DATE_D_${distinct ? 1 : 0}_S_${`${sort}`.toLowerCase() === 'asc' ? 'ASC' : 'DESC'}_L_${limit}`;
    },
    statisticsType: 'localFn',
    statisticsFn: ({nodes, distinct, sort, limit, cb}) => {
      return statisticsByTime(
        {nodes, distinct, getConditionFn: (n, _) => _.get(n, 'linkTime'), timeFormat: 'YYYY-MM-DD', sort, limit, cb});
    },
  },
  CREATE_MONTH: {
    key: 'CREATE_MONTH',
    category: 'CREATE_TIME',
    defaultLimit: 10,
    defaultSort: 'DESC',
    cacheKeyFn: ({distinct, sort, limit}) => {
      return `CREATE_MONTH_D_${distinct ? 1 : 0}_S_${`${sort}`.toLowerCase() === 'asc' ? 'ASC' : 'DESC'}_L_${limit}`;
    },
    statisticsType: 'localFn',
    statisticsFn: ({nodes, distinct, sort, limit, cb}) => {
      return statisticsByTime(
        {nodes, distinct, getConditionFn: (n, _) => _.get(n, 'linkTime'), timeFormat: 'YYYY-MM', sort, limit, cb});
    },
  },
  CREATE_YEAR: {
    key: 'CREATE_YEAR',
    category: 'CREATE_TIME',
    defaultLimit: 10,
    defaultSort: 'DESC',
    cacheKeyFn: ({distinct, sort, limit}) => {
      return `CREATE_YEAR_D_${distinct ? 1 : 0}_S_${`${sort}`.toLowerCase() === 'asc' ? 'ASC' : 'DESC'}_L_${limit}`;
    },
    statisticsType: 'localFn',
    statisticsFn: ({nodes, distinct, sort, limit, cb}) => {
      return statisticsByTime(
        {nodes, distinct, getConditionFn: (n, _) => _.get(n, 'linkTime'), timeFormat: 'YYYY年', sort, limit, cb});
    },
  },
  UPDATE_DATE: {
    key: 'UPDATE_DATE',
    category: 'UPDATE_TIME',
    defaultLimit: 10,
    defaultSort: 'DESC',
    cacheKeyFn: ({distinct, sort, limit}) => {
      return `UPDATE_DATE_D_${distinct ? 1 : 0}_S_${`${sort}`.toLowerCase() === 'asc' ? 'ASC' : 'DESC'}_L_${limit}`;
    },
    statisticsType: 'localFn',
    statisticsFn: ({nodes, distinct, sort, limit, cb}) => {
      return statisticsByTime(
        {nodes, distinct, getConditionFn: (n, _) => _.get(n, 'updateTime') || _.get(n, 'linkTime'),
          timeFormat: 'YYYY-MM-DD', sort, limit, cb});
    },
  },
  UPDATE_MONTH: {
    key: 'UPDATE_MONTH',
    category: 'UPDATE_TIME',
    defaultLimit: 10,
    defaultSort: 'DESC',
    cacheKeyFn: ({distinct, sort, limit}) => {
      return `UPDATE_MONTH_D_${distinct ? 1 : 0}_S_${`${sort}`.toLowerCase() === 'asc' ? 'ASC' : 'DESC'}_L_${limit}`;
    },
    statisticsType: 'localFn',
    statisticsFn: ({nodes, distinct, sort, limit, cb}) => {
      return statisticsByTime(
        {nodes, distinct, getConditionFn: (n, _) => _.get(n, 'updateTime') || _.get(n, 'linkTime'),
          timeFormat: 'YYYY-MM', sort, limit, cb});
    },
  },
  UPDATE_YEAR: {
    key: 'UPDATE_YEAR',
    category: 'UPDATE_TIME',
    defaultLimit: 10,
    defaultSort: 'DESC',
    cacheKeyFn: ({distinct, sort, limit}) => {
      return `UPDATE_YEAR_D_${distinct ? 1 : 0}_S_${`${sort}`.toLowerCase() === 'asc' ? 'ASC' : 'DESC'}_L_${limit}`;
    },
    statisticsType: 'localFn',
    statisticsFn: ({nodes, distinct, sort, limit, cb}) => {
      return statisticsByTime(
        {nodes, distinct, getConditionFn: (n, _) => _.get(n, 'updateTime') || _.get(n, 'linkTime'),
          timeFormat: 'YYYY年', sort, limit, cb});
    },
  },
  TAG_DATE: {
    key: 'TAG_DATE',
    category: 'TAG_TIME',
    defaultLimit: 10,
    defaultSort: 'DESC',
    cacheKeyFn: ({distinct, sort, limit}) => {
      return `TAG_DATE_D_${distinct ? 1 : 0}_S_${`${sort}`.toLowerCase() === 'asc' ? 'ASC' : 'DESC'}_L_${limit}`;
    },
    statisticsType: 'localFn',
    statisticsFn: ({nodes, distinct, sort, limit, cb}) => {
      return statisticsByTime({
        nodes,
        distinct,
        getConditionFn: (n, _) => {
          let tags = _.get(n, 'tags');
          if (tags) {
            return tags.map(t => stringToMoment(t)).filter(t => !!t);
          }
          return [];
        },
        timeFormat: 'YYYY-MM-DD',
        sort,
        limit,
        cb,
      });
    },
  },
  TAG_MONTH: {
    key: 'TAG_MONTH',
    category: 'TAG_TIME',
    defaultLimit: 10,
    defaultSort: 'DESC',
    cacheKeyFn: ({distinct, sort, limit}) => {
      return `TAG_MONTH_D_${distinct ? 1 : 0}_S_${`${sort}`.toLowerCase() === 'asc' ? 'ASC' : 'DESC'}_L_${limit}`;
    },
    statisticsType: 'localFn',
    statisticsFn: ({nodes, distinct, sort, limit, cb}) => {
      return statisticsByTime({
        nodes,
        distinct,
        getConditionFn: (n, _) => {
          let tags = _.get(n, 'tags');
          if (tags) {
            return tags.map(t => stringToMoment(t)).filter(t => !!t);
          }
          return [];
        },
        timeFormat: 'YYYY-MM',
        sort,
        limit,
        cb,
      });
    },
  },
  TAG_YEAR: {
    key: 'TAG_YEAR',
    category: 'TAG_TIME',
    defaultLimit: 10,
    defaultSort: 'DESC',
    cacheKeyFn: ({distinct, sort, limit}) => {
      return `TAG_YEAR_D_${distinct ? 1 : 0}_S_${`${sort}`.toLowerCase() === 'asc' ? 'ASC' : 'DESC'}_L_${limit}`;
    },
    statisticsType: 'localFn',
    statisticsFn: ({nodes, distinct, sort, limit, cb}) => {
      return statisticsByTime({
        nodes,
        distinct,
        getConditionFn: (n, _) => {
          let tags = _.get(n, 'tags');
          if (tags) {
            return tags.map(t => stringToMoment(t)).filter(t => !!t);
          }
          return [];
        },
        timeFormat: 'YYYY年',
        sort,
        limit,
        cb,
      });
    },
  },
  NAME_AREA_PROVINCE: {
    key: 'NAME_AREA_PROVINCE',
    category: 'NAME_AREA',
    defaultLimit: 10,
    defaultSort: 'DESC',
    cacheKeyFn: ({distinct, sort, limit}) => {
      return `NAME_AREA_PROVINCE_D_${distinct ? 1 : 0}_S_${`${sort}`.toLowerCase() === 'asc' ? 'ASC' : 'DESC'}_L_${limit}`;
    },
    statisticsType: 'localFn',
    statisticsFn: ({nodes, distinct, sort, limit, cb}) => {
      return statisticsByArea(
        {nodes, distinct, getConditionFn: (n, _) => _.get(n, 'fname'), level: ChinaADCStatisticsType.PROVINCE, sort, limit, cb});
    },
  },
  NAME_AREA_CITY: {
    key: 'NAME_AREA_CITY',
    category: 'NAME_AREA',
    defaultLimit: 10,
    defaultSort: 'DESC',
    cacheKeyFn: ({distinct, sort, limit}) => {
      return `NAME_AREA_CITY_D_${distinct ? 1 : 0}_S_${`${sort}`.toLowerCase() === 'asc' ? 'ASC' : 'DESC'}_L_${limit}`;
    },
    statisticsType: 'localFn',
    statisticsFn: ({nodes, distinct, sort, limit, cb}) => {
      return statisticsByArea(
        {nodes, distinct, getConditionFn: (n, _) => _.get(n, 'fname'), level: ChinaADCStatisticsType.CITY, sort, limit, cb});
    },
  },
  NAME_AREA_DISTRICT: {
    key: 'NAME_AREA_DISTRICT',
    category: 'NAME_AREA',
    defaultLimit: 10,
    defaultSort: 'DESC',
    cacheKeyFn: ({distinct, sort, limit}) => {
      return `NAME_AREA_DISTRICT_D_${distinct ? 1 : 0}_S_${`${sort}`.toLowerCase() === 'asc' ? 'ASC' : 'DESC'}_L_${limit}`;
    },
    statisticsType: 'localFn',
    statisticsFn: ({nodes, distinct, sort, limit, cb}) => {
      return statisticsByArea(
        {nodes, distinct, getConditionFn: (n, _) => _.get(n, 'fname'), level: ChinaADCStatisticsType.DISTRICT, sort, limit, cb});
    },
  },
  TAG_AREA_PROVINCE: {
    key: 'TAG_AREA_PROVINCE',
    category: 'TAG_AREA',
    defaultLimit: 10,
    defaultSort: 'DESC',
    cacheKeyFn: ({distinct, sort, limit}) => {
      return `TAG_AREA_PROVINCE_D_${distinct ? 1 : 0}_S_${`${sort}`.toLowerCase() === 'asc' ? 'ASC' : 'DESC'}_L_${limit}`;
    },
    statisticsType: 'localFn',
    statisticsFn: ({nodes, distinct, sort, limit, cb}) => {
      return statisticsByArea(
        {nodes, distinct, getConditionFn: (n, _) => _.get(n, 'tags').join(' '), level: ChinaADCStatisticsType.PROVINCE, sort, limit, cb});
    },
  },
  TAG_AREA_CITY: {
    key: 'TAG_AREA_CITY',
    category: 'TAG_AREA',
    defaultLimit: 10,
    defaultSort: 'DESC',
    cacheKeyFn: ({distinct, sort, limit}) => {
      return `TAG_AREA_CITY_D_${distinct ? 1 : 0}_S_${`${sort}`.toLowerCase() === 'asc' ? 'ASC' : 'DESC'}_L_${limit}`;
    },
    statisticsType: 'localFn',
    statisticsFn: ({nodes, distinct, sort, limit, cb}) => {
      return statisticsByArea(
        {nodes, distinct, getConditionFn: (n, _) => _.get(n, 'tags').join(' '), level: ChinaADCStatisticsType.CITY, sort, limit, cb});
    },
  },
  TAG_AREA_DISTRICT: {
    key: 'TAG_AREA_DISTRICT',
    category: 'TAG_AREA',
    defaultLimit: 10,
    defaultSort: 'DESC',
    cacheKeyFn: ({distinct, sort, limit}) => {
      return `TAG_AREA_DISTRICT_D_${distinct ? 1 : 0}_S_${`${sort}`.toLowerCase() === 'asc' ? 'ASC' : 'DESC'}_L_${limit}`;
    },
    statisticsType: 'localFn',
    statisticsFn: ({nodes, distinct, sort, limit, cb}) => {
      return statisticsByArea(
        {nodes, distinct, getConditionFn: (n, _) => _.get(n, 'tags').join(' '), level: ChinaADCStatisticsType.DISTRICT, sort, limit, cb});
    },
  },
  ICON: {
    key: 'ICON',
    category: 'ICON',
    defaultLimit: -1,
    defaultSort: 'DESC',
    cacheKeyFn: ({distinct, sort, limit}) => {
      return `ICON_D_${distinct ? 1 : 0}_S_${`${sort}`.toLowerCase() === 'asc' ? 'ASC' : 'DESC'}_L_${limit}`;
    },
    statisticsType: 'localFn',
    statisticsFn: ({nodes, distinct, sort, limit, cb}) => {
      return statisticsByIcon({
        nodes,
        distinct,
        getIconFn: (n) => {
          let type = getNodeIconType(n);
          return {
            type,
            label: iconConfig[type].title._default,
          };
        },
        sort,
        limit,
        cb,
      });
    },
  },
  PROGRESS: {
    key: 'PROGRESS',
    category: 'PROGRESS',
    defaultLimit: -1,
    defaultSort: false,
    cacheKeyFn: ({distinct}) => {
      return `PROGRESS_D_${distinct ? 1 : 0}`;
    },
    statisticsType: 'localFn',
    statisticsFn: ({nodes, distinct, cb}) => {
      return statisticsByProgress({nodes, distinct, getConditionFn: (n, _) => _.get(n, 'aiGraphRank'), cb});
    },
  },
  FAVORITE: {
    key: 'FAVORITE',
    category: 'FAVORITE',
    defaultLimit: -1,
    defaultSort: false,
    cacheKeyFn: ({distinct}) => {
      return `FAVORITE_D_${distinct ? 1 : 0}`;
    },
    statisticsType: 'localFn',
    statisticsFn: ({nodes, distinct, cb}) => {
      return statisticsByFavorite({nodes, distinct, cb});
    },
  },
  AUTHOR: {
    key: 'AUTHOR',
    category: 'AUTHOR',
    defaultLimit: 10,
    defaultSort: 'DESC',
    cacheKeyFn: ({distinct, sort, limit}) => {
      return `ICON_D_${distinct ? 1 : 0}_S_${`${sort}`.toLowerCase() === 'asc' ? 'ASC' : 'DESC'}_L_${limit}`;
    },
    statisticsType: 'localFn',
    statisticsFn: ({nodes, distinct, sort, limit, extra, cb}) => {
      return statisticsByAuthor({
        nodes,
        distinct,
        getAuthorFn: (n) => {
          return {
            userId: n.userId,
            label: extra.userMap[n.userId] ? (extra.userMap[n.userId].nick || '外部用户') : '外部用户',
          };
        },
        sort,
        limit,
        cb,
      });
    },
  },
};

class ViewStatisticsNodeLogic extends React.PureComponent {
  componentDidMount() {
    let me = this;

    me.props.bus.with(
      me
    ).subscribe('node.statistics', 'node.do_load', (
      {nodes, conditionKey, cacheKey, distinct, sort, limit, extra}
    ) => {
      let conditionCfg = STATISTIC_CONDITIONS[conditionKey];

      if (!conditionCfg) {
        me.props.bus.emit('node.statistics', 'node.failed_to_load', {code: 500, msg: '统计方式有误'});
        return;
      }

      sort = (sort === undefined) ? conditionCfg.defaultSort : sort;
      limit = (limit === undefined) ? conditionCfg.defaultLimit : limit;

      if (cache.key === cacheKey) {
        let cachedResult = cache.resultMap[conditionCfg.cacheKeyFn({distinct, sort, limit})];
        if (cachedResult) {
          me.props.bus.emit('node.statistics', 'node.loaded',
            {distinct, sort, limit, result: cachedResult});
          return;
        }
      } else {
        cache.key = cacheKey;
        cache.resultMap = {};
      }

      if (conditionCfg.statisticsType === 'localFn') {
        conditionCfg.statisticsFn({
          nodes,
          distinct,
          sort,
          limit,
          extra,
          cb: (cachedResult) => {
            let max = Math.max.apply(Math, cachedResult.map(item => item.rate));
            cachedResult = cachedResult.map(item => ({...item, percent: Math.round(item.rate / max * 100)}));
            cache.resultMap[conditionCfg.cacheKeyFn({distinct, sort, limit})] = cachedResult;
            me.props.bus.emit('node.statistics', 'node.loaded',
              {distinct, sort, limit, result: cachedResult});
          },
        });
      } else {
        me.props.bus.emit('node.statistics', 'node.failed_to_load', {code: 500, msg: '统计方式暂不支持'});
      }
    })
  }

  componentWillUnmount() {
    let me = this;

    me.props.bus.remove(me);
  }

  render() {
    return (
      <div style={{height: 0, width: 0}} />
    );
  }
}

ViewStatisticsNodeLogic.defaultProps = {
  bus: PB,
};

ViewStatisticsNodeLogic.propTypes = {
  bus: PropTypes.instanceOf(SimplePB),
};

export default ViewStatisticsNodeLogic;