import { put, /*takeEvery, fork, */call, /*select, */takeLatest } from 'redux-saga/effects';

import {showErrorMsg} from "@/components/common/common.message";
import {
  API_ChangePwd,
  API_ChangeUserBasicInfo,
  API_CheckUsername,
  API_Login,
  API_MagicLogin,
  API_LoginViaWeChat,
  API_GetWeChatFwhTicket,
  API_CheckWeChatFwhTicket,
  API_Register,
  API_ResetPassword,
  API_ResetPasswordSaveNewPassword,
} from '@/api/account';
import {
  DO_CHECK_USERNAME,
  DO_LOGIN,
  DO_MAGIC_LOGIN,
  DO_LOGOUT,
  DO_REGISTER,
  RESET_PWD_VIA_EMAIL,
  DO_LOGIN_VIA_WE_CHAT,
  DO_GET_WE_CHAT_FWH_TICKET,
  DO_CHECK_WE_CHAT_FWH_TICKET,
  RESET_PWD_SEND_NEW_PWD,
  DO_CHANGE_PWD,
  CHANGE_USER_BASIC_INFO,
} from './actionTypes';
import {
  userFoundAction,
  loginSuccessAction,
  loginFailAction,
  registerSuccessAction,
  registerFailAction,
  loginViaWeChatSuccessAction,
  loginViaWeChatFailAction,
  getWeChatFwhTicketSuccessAction,
  getWeChatFwhTicketFailAction,
  resetPwdEmailSentAction,
  resetPwdEmailSendFailAction,
  resetPwdFailAction,
  resetPwdDoneAction,
  changePwdSuccessAction,
  changePwdFailAction,
  changeUserBasicInfoSuccessAction,
  changeUserBasicInfoFailAction,
} from "./actions";

function* checkUsernameAsync(action) {
  try {
    const response = yield call(API_CheckUsername, action.username);
    if (response && response.data && response.data.code === 30002) {
      // 账号已被注册，通过验证
      yield put(userFoundAction(action.username));
    } else {
      // 账号未注册
      showErrorMsg(response.data);
      yield put(loginFailAction(response.data.code));
    }
  } catch (error) {
    let code = error;
    if (typeof code !== 'number') {
      code = 500;
    }
    yield put(loginFailAction(code));
  }
}

function* loginAsync(action) {
    try {
        const response = yield call(API_Login, action.username, action.password, action.remember);
        yield put(loginSuccessAction(response.data.user));
        // localStorage.setItem("userId",response.data.user.userId)
    } catch (error) {
        let code = error;
        if (typeof code !== 'number') {
            code = 500;
        }
        yield put(loginFailAction(code));
    }
}

function* magicLoginAsync() {
    try {
        const response = yield call(API_MagicLogin);
        if (response && response.data && response.data.user) {
          yield put(loginSuccessAction(response.data.user));
        } else {
          localStorage.setItem('try_magic_login', '0');
        }
    } catch (error) {
        localStorage.setItem('try_magic_login', '0');
    }
}

function* doLogout() {
    localStorage.removeItem('token');
    // localStorage.clear()
    yield ;
}

function* registerAsync(action) {
    try {
        yield call(API_Register, action.username);
        yield put(registerSuccessAction(action.username));
    } catch (error) {
        let code = error;
        if (typeof code !== 'number') {
            code = 500;
        }
        yield put(registerFailAction(code));
    }
}

function* resetPwdViaEmailAsync(action) {
  try {
    yield call(API_ResetPassword, action.email);
    yield put(resetPwdEmailSentAction(action.email));
  } catch (error) {
    let code = error;
    if (typeof code !== 'number') {
      code = 500;
      showErrorMsg({code: 500, msg: '操作失败，内部错误。'})
    }
    yield put(resetPwdEmailSendFailAction(code));
  }
}

function* resetPwdSendNewPwdAsync(action) {
  try {
    yield call(API_ResetPasswordSaveNewPassword, action.password, action.token, action.strength);
    yield put(resetPwdDoneAction());
  } catch (error) {
    let code = error;
    if (typeof code !== 'number') {
      code = 500;
      showErrorMsg({code: 500, msg: '操作失败，内部错误。'})
    }
    yield put(resetPwdFailAction(code));
  }
}

function* loginViaWeChatAsync(action) {
  try {
    const response = yield call(API_LoginViaWeChat, action.code);
    yield put(loginViaWeChatSuccessAction(response.data.user));
  } catch (error) {
    let code = error;
    if (typeof code !== 'number') {
      code = 500;
    }
    yield put(loginViaWeChatFailAction(code));
  }
}

function* getWeChatFwhTicketAsync() {
  try {
    const response = yield call(API_GetWeChatFwhTicket);
    yield put(getWeChatFwhTicketSuccessAction(response.data.data));
  } catch (error) {
    yield put(getWeChatFwhTicketFailAction());
  }
}

function* checkWeChatFwhTicketAsync(action) {
  try {
    const response = yield call(API_CheckWeChatFwhTicket, action.ticket);
    yield put(loginViaWeChatSuccessAction(response.data.user));
  } catch (error) {
    // ignore
  }
}

function* changePwdAsync(action) {
  try {
    yield call(API_ChangePwd, action.oldPwd, action.newPwd, action.strength);
    yield put(changePwdSuccessAction(action.strength));
  } catch (error) {
    let code = error;
    if (typeof code !== 'number') {
      code = 500;
      showErrorMsg({code: 500, msg: '操作失败，内部错误。'})
    }
    yield put(changePwdFailAction(code));
  }
}

function* changeUserBasicInfoAsync(action) {
  try {
    const response = yield call(API_ChangeUserBasicInfo, action.userInfo);
    yield put(changeUserBasicInfoSuccessAction(response.data.data));
  } catch (error) {
    let code = error;
    if (typeof code !== 'number') {
      code = 500;
      showErrorMsg({code: 500, msg: '操作失败，内部错误。'})
    }
    yield put(changeUserBasicInfoFailAction(code));
  }
}

export function* watchCheckUsername() {
    console.log('watcher saga is listening to checkUsername action');
    yield takeLatest([DO_CHECK_USERNAME], checkUsernameAsync)
}

export function* watchLogin() {
    console.log('watcher saga is listening to login action');
    yield takeLatest([DO_LOGIN], loginAsync)
}

export function* watchMagicLogin() {
    console.log('watcher saga is listening to magic login action');
    yield takeLatest([DO_MAGIC_LOGIN], magicLoginAsync)
}

export function* watchLogout() {
    console.log('watcher saga is listening to logout action');
    yield takeLatest([DO_LOGOUT], doLogout)
}

export function* watchRegister() {
    console.log('watcher saga is listening to register action');
    yield takeLatest([DO_REGISTER], registerAsync)
}

export function* watchResetPwdViaEmail() {
  console.log('watcher saga is listening to resetPwdViaEmail action');
  yield takeLatest([RESET_PWD_VIA_EMAIL], resetPwdViaEmailAsync)
}

export function* watchResetPwdSendNewPwd() {
  console.log('watcher saga is listening to resetPwdSendNewPwdAsync action');
  yield takeLatest([RESET_PWD_SEND_NEW_PWD], resetPwdSendNewPwdAsync)
}

export function* watchWeChatLogin() {
  console.log('watcher saga is listening to wechat login action');
  yield takeLatest([DO_LOGIN_VIA_WE_CHAT], loginViaWeChatAsync)
}

export function* watchGetWeChatFwhTicket() {
  console.log('watcher saga is listening to get wechat fwh ticket action');
  yield takeLatest([DO_GET_WE_CHAT_FWH_TICKET], getWeChatFwhTicketAsync)
}

export function* watchCheckWeChatFwhTicket() {
  console.log('watcher saga is listening to check wechat fwh ticket action');
  yield takeLatest([DO_CHECK_WE_CHAT_FWH_TICKET], checkWeChatFwhTicketAsync)
}

export function* watchChangePwd() {
  console.log('watcher saga is listening to change password action');
  yield takeLatest([DO_CHANGE_PWD], changePwdAsync)
}

export function* watchChangeUserBasicInfo() {
  console.log('watcher saga is listening to change user basic info action');
  yield takeLatest([CHANGE_USER_BASIC_INFO], changeUserBasicInfoAsync)
}