import React from 'react';
import {Tooltip, Icon, Button} from "antd";
import style from '@/style/components/main.toolbar.context.less'

// import PropTypes from "prop-types";
import PB from '@/libs/simplePB'

class ToolbarContext extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            // 控制组件显隐
            status: false,
            // 参数
            pos: {x: 0, y: 0}, // dom 的x y 值
            node: undefined,
            edge: undefined,
        };

    }

    componentWillUnmount() {
        PB.remove(this)
    }

    componentWillReceiveProps(next) {

    }

    componentDidMount() {
        PB.sub(this, 'network', 'ActionContext', (params) => {
            console.log('上下文 params', params);
            this.setState({
                ...params,
            })
        })

    }

    render() {
        const {status,pos/*,node,edge*/}=this.state;

        /*let buttons=[];
        if(node){
            buttons=[
                {
                    title:'按钮',
                    icon:'',
                    onClick:''
                }
            ]
        }*/
        // noinspection RequiredAttributes
        return (
            <Tooltip
                title={
                    <Button.Group>
                        <Button type="primary">
                            <Icon type="left"/>Backward
                        </Button>
                        <Button type="primary">
                            Forward<Icon type="right"/>
                        </Button>
                        <Button type="primary" onClick={() => {
                            PB.emit('network', 'ActionContext', {status: false});
                        }
                        }>
                            <Icon type="close"/>
                        </Button>
                    </Button.Group>
                }
                visible={status}
                overlayClassName={style['point-tooltip']}
            >
                <div
                    className={style.point}
                    style={{
                        visible: status,
                        left: pos && pos.x ? pos.x : '0',
                        top: pos && pos.y ? pos.y : '0',
                    }}
                />
            </Tooltip>
        );
    }
}

// noinspection JSUnusedGlobalSymbols
ToolbarContext.defaultProps = {};

ToolbarContext.propTypes = {
    // onOptionChanged: PropTypes.func,
    // growViewAction: PropTypes.func,
    // viewOption: PropTypes.object
};


export default ToolbarContext;
