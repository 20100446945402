import React, { Component } from 'react';
import 'animate.css';
import './App.css';
import RouterView from '@/routes';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';
import {store, persistor} from '@/store';
import {message, notification} from 'antd';
import WindowSize from 'react-window-size';
import 'antd/dist/antd.css';
import 'react-image-gallery/styles/css/image-gallery.css';
import '@/constants/common.config';
import locales from './i18n/locales'
import IntlTranslation from './i18n/IntlTranslation'

//import { emit } from "./components/utils/emit";
import intl from 'react-intl-universal';

// antd 的 message 允许的最大提醒数量
message.config({
  maxCount: 1,
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentLocale: 'zh-CN',  //组件的国际化 'en-US'  'zh-CN'
    }
  }

  loadLocales(currentLocale = 'zh-CN') {
    intl.init({
      currentLocale: currentLocale,  // 设置初始语音
      commonLocaleDataUrls: {
        'zh': false,
        'en': false
      },
      locales
    }).then(() => {
      this.setState({
        currentLocale: currentLocale
      });
    });
  }

  componentDidMount() {
    let me = this;

    //emit.on('change_language', lang => this.loadLocales(lang)); // 监听语言改变事件
    this.loadLocales('zh-CN'); // 初始化语言  'en-US'  'zh-CN'

    if (me.props.windowWidth < 1500) {
      setTimeout(() => {
        notification.warn({
          key: 'screen-width-alert',
          message: <IntlTranslation intlKey = "Custom.notification.screenWidthAlert"/>,
          duration: 0,
        });
      }, 1000);
    }
    
    notification.info({
      message: <span>为了给您更好的体验，<br/>系统于2024年2月23日17:00至2024年2月26日9:00维护升级，<br/>给您带来不便，敬请谅解。</span>,
      duration: 0,
    })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let me = this;

    if (prevProps.windowWidth !== me.props.windowWidth) {
      if (me.props.windowWidth >= 1500 && prevProps.windowWidth < 1500) {
        notification.close('screen-width-alert');
      } else if (me.props.windowWidth < 1500 && prevProps.windowWidth >= 1500) {
        setTimeout(() => {
          notification.warn({
            key: 'screen-width-alert',
            message: <IntlTranslation intlKey = "Custom.notification.screenWidthAlert"/>,
            duration: 0,
          });
        }, 200);
      }
    }
  }

  render() {
    window.assistant.hidePageLoading(120);
    return (
      <Provider store={store} locale={this.state.currentLocale}>
        <PersistGate loading={null} persistor={persistor}>
          <RouterView />
        </PersistGate>
      </Provider>
    );
  }
}

export default WindowSize(App);
