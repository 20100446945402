import React from 'react';
import _ from "lodash";
import moment from "moment";
import PropTypes from 'prop-types';
import {Popover} from "antd";

import {
  ChinaADCColorMap,
  ChinaADCDisplayTitleMap,
  getChinaProvinceADC,
  unknownChinaADC,
} from "@/components/common/common.functions";
import {showPageLoading} from "@/components/common/common.message";

import style from "@/style/common/common.timestampAndLocationStatistics.less";
import {bindUtil} from "@/libs/core-decorators";
import {autoSetState, withReactStateHelper} from "@/libs/react-state-helper";
import Icon from "@/components/common/common.icon";
import {IconTypes} from "@/constants/common";

export const provincePathOfNode = 'meta.prov';
export const chinaADCPathOfNode = 'meta.china_adc';
export const datePathOfNode = 'meta.create_date';
export const timestampPathOfNode = 'meta.timestamp';

export const TimeStatisticsType = Object.freeze({
  ANNUAL: 12,
  SEMIANNUAL: 6,
  QUARTER: 3,
  MONTHLY: 1,
});

export const ChinaADCStatisticsType = Object.freeze({
  AREA: 1,
  PROVINCE: 2,
  CITY: 4,
  DISTRICT: 6,
});

export const quarterI18N = ['一季度', '二季度', '三季度', '四季度'];
export const semiannualI18N = ['上半年', '下半年'];
export const localizationFormat = Object.freeze({
  [TimeStatisticsType.ANNUAL]: {
    fullName: (startTimestamp, endTimestamp) => {
      return startTimestamp <= 0 ? (
        endTimestamp <= 0 ? '不限时段' : `${moment(endTimestamp + 1).year()}年前`
      ) : (
        endTimestamp <= 0 ? `${moment(startTimestamp).year()}年后` : moment(startTimestamp).format('YYYY年')
      )
    },
    primaryName: (startTimestamp, endTimestamp) => {
      return (startTimestamp <= 0 && endTimestamp <= 0) ? '不限时段' : moment(startTimestamp).format('YYYY年')
    },
    secondaryName: (startTimestamp, endTimestamp) => {
      return startTimestamp <= 0 ? (endTimestamp <= 0 ? '' : '前') : (endTimestamp <= 0 ? '后' : '')
    },
  },
  [TimeStatisticsType.SEMIANNUAL]: {
    fullName: (startTimestamp, endTimestamp) => {
      return startTimestamp <= 0 ? (
        endTimestamp <= 0 ? '不限时段' : `${moment(endTimestamp + 1).year()}年前`
      ) : (
        endTimestamp <= 0 ?
          `${moment(startTimestamp).year()}年后` :
          `${moment(startTimestamp).year()}年${semiannualI18N[moment(startTimestamp).month() / 6]}`
      )
    },
    primaryName: (startTimestamp, endTimestamp) => {
      return (startTimestamp <= 0 && endTimestamp <= 0) ? '不限时段' : moment(startTimestamp).format('YYYY年')
    },
    secondaryName: (startTimestamp, endTimestamp) => {
      return startTimestamp <= 0 ? (
        endTimestamp <= 0 ? '不限时段' : '前'
      ) : (
        endTimestamp <= 0 ? '后' : `${semiannualI18N[moment(startTimestamp).month() / 6]}`
      )
    },
  },
  [TimeStatisticsType.QUARTER]: {
    fullName: (startTimestamp, endTimestamp) => {
      return startTimestamp <= 0 ? (
        endTimestamp <= 0 ? '不限时段' : `${moment(endTimestamp + 1).year()}年前`
      ) : (
        endTimestamp <= 0 ?
          `${moment(startTimestamp).year()}年后` :
          `${moment(startTimestamp).year()}年${quarterI18N[moment(startTimestamp).month() / 3]}`
      )
    },
    primaryName: (startTimestamp, endTimestamp) => {
      return (startTimestamp <= 0 && endTimestamp <= 0) ? '不限时段' : moment(startTimestamp).format('YYYY年')
    },
    secondaryName: (startTimestamp, endTimestamp) => {
      return startTimestamp <= 0 ? (
        endTimestamp <= 0 ? '不限时段' : '前'
      ) : (
        endTimestamp <= 0 ? '后' : `${quarterI18N[moment(startTimestamp).month() / 3]}`
      )
    },
  },
  [TimeStatisticsType.MONTHLY]: {
    fullName: (startTimestamp, endTimestamp) => {
      return startTimestamp <= 0 ? (
        endTimestamp <= 0 ? '不限时段' : `${moment(endTimestamp + 1).year()}年前`
      ) : (
        endTimestamp <= 0 ? moment(startTimestamp).format('YYYY年后') :
          moment(startTimestamp).format('YYYY.MM')
      )
    },
    primaryName: (startTimestamp, endTimestamp) => {
      return (startTimestamp <= 0 && endTimestamp <= 0) ? '不限时段' : moment(startTimestamp).format('YYYY.')
    },
    secondaryName: (startTimestamp, endTimestamp) => {
      return startTimestamp <= 0 ? (
        endTimestamp <= 0 ? '不限时段' : '前'
      ) : (
        endTimestamp <= 0 ? '后' : moment(startTimestamp).format('MM')
      )
    },
  },
});

export const getTimeStatisticsStruct = (() => {
  let today = moment().hour(0).minute(0).second(0).millisecond(0);
  let result;
  let timeSectionMap;
  return () => {
    let currentDay = moment().hour(0).minute(0).second(0).millisecond(0);
    if (!result || (today.format('YYYY-MM-DD') !== currentDay.format('YYYY-MM-DD'))) {
      result = {};
      let currentYear = currentDay.year(); // 获得年份
      let currentMonth = currentDay.month() + 1; // 获得月份，此方法获得的月份是从0至11，故+1

      // 统计上限为当前年份前推两年的1月1日
      let earliestDay = moment(0).year(currentYear - 2).dayOfYear(1).hour(0);
      let firstDayOfNextMonth = moment().month(currentMonth).date(1).hour(0).minute(0).second(0).millisecond(0);
      timeSectionMap = {
        [-1]: {
          start: -1,
          end: earliestDay.valueOf() - 1,
          amount: 0,
        },
      };

      let loopDay = earliestDay.clone();
      let nextLoopDay = loopDay.clone();
      for (; loopDay < firstDayOfNextMonth;) {
        nextLoopDay.month(nextLoopDay.month() + 1);
        timeSectionMap[loopDay.valueOf()] = {
          start: loopDay.valueOf(),
          end: nextLoopDay.valueOf() - 1,
          amount: 0,
        };
        loopDay.month(loopDay.month() + 1);
      }

      Object.values(TimeStatisticsType).forEach(monthsInStep => {
        let currentTimestamp = -1;
        result[monthsInStep] = [{
          start: currentTimestamp,
          end: earliestDay.valueOf() - 1,
          fullName: localizationFormat[monthsInStep].fullName(currentTimestamp, earliestDay.valueOf() - 1),
          primaryName: localizationFormat[monthsInStep].primaryName(currentTimestamp, earliestDay.valueOf() - 1),
          secondaryName: localizationFormat[monthsInStep].secondaryName(currentTimestamp, earliestDay.valueOf() - 1),
          contains: [timeSectionMap[currentTimestamp]],
        }];

        let currentIndexInStep = 0;
        loopDay = earliestDay.clone();
        nextLoopDay = loopDay.clone();
        for (; loopDay < firstDayOfNextMonth;) {
          nextLoopDay.month(nextLoopDay.month() + 1);
          currentTimestamp = loopDay.valueOf();
          if (currentIndexInStep === 0) {
            // 当前阶段开始，初始化字段
            result[monthsInStep].push({
              start: currentTimestamp,
              end: -1,
              contains: [],
            });
          }

          result[monthsInStep][result[monthsInStep].length - 1].contains.push(timeSectionMap[currentTimestamp]);

          currentIndexInStep = (++currentIndexInStep % monthsInStep);
          if (currentIndexInStep === 0) {
            // 当前阶段结束，更新end字段
            result[monthsInStep][result[monthsInStep].length - 1].end = nextLoopDay.valueOf() - 1;
            result[monthsInStep][result[monthsInStep].length - 1].fullName =
              localizationFormat[monthsInStep].fullName(result[monthsInStep][result[monthsInStep].length - 1].start,
                nextLoopDay.valueOf() - 1);
            result[monthsInStep][result[monthsInStep].length - 1].primaryName =
              localizationFormat[monthsInStep].primaryName(result[monthsInStep][result[monthsInStep].length - 1].start,
                nextLoopDay.valueOf() - 1);
            result[monthsInStep][result[monthsInStep].length - 1].secondaryName =
              localizationFormat[monthsInStep].secondaryName(result[monthsInStep][result[monthsInStep].length - 1].start,
                nextLoopDay.valueOf() - 1);
          }

          loopDay.month(loopDay.month() + 1);
        }
      });
    }
    return {result, sectionMap: timeSectionMap};
  };
})();

/**
 * 获取节点所标注的位置信息，当前仅支持到省级
 *
 * @param node
 * @param {string} provincePath
 * @param {string} chinaADCPath
 * @return {string}
 */
export const getAndSetChinaADC = (node, provincePath = provincePathOfNode, chinaADCPath = chinaADCPathOfNode) => {
  let provinceStr = _.get(node, provincePath);
  const code = getChinaProvinceADC(provinceStr);
  _.set(node, chinaADCPath, code);
  return code;
};

/**
 * 获取节点所标注的时间信息
 *
 * @param node
 * @param {string} datePath
 * @param {string} timestampPath
 * @return {moment.Moment|boolean}
 */
export const getAndSetTimestamp = (node, datePath = datePathOfNode, timestampPath = timestampPathOfNode) => {
  let timestamp = _.get(node, datePath, false);
  if (timestamp) {
    timestamp = moment(timestamp);
  }
  if (timestamp && timestamp.isValid()) {
    _.set(node, timestampPath, timestamp);
  } else {
    console.warn('Got invalid date from node: ', node);
    _.set(node, timestampPath, false);
  }
  return timestamp || false;
};

@bindUtil.asSourceClass
class FilterAndStatisticsBus {
  otherFilters = []; // 额外过滤器

  chinaADCStatisticsType = ChinaADCStatisticsType.PROVINCE;
  timeStatisticsType = TimeStatisticsType.MONTHLY;

  chinaADCFilter = '000000'; // 过滤地区，值为匹配中国行政区划代码的正则表达式字符串，全0为不过滤
  timestampStartFilter = -1; // 过滤起始时间戳（millisecond），为-1时不限，为false时为其他
  timestampEndFilter = -1; // 过滤终止时间戳（millisecond），为-1时不限，为false时为其他
  mustHaveChinaADC = false; // 是否仅保留包含地理位置信息的数据
  mustHaveTimestamp = false; // 是否仅保留包含时间信息的数据

  originalNodes = []; // 原始数据
  preprocessed = false; // 原始数据是否经过了预处理
  lastUpdate = Math.random(); // 上次原始数据更新标记
  provincePathOfNode = provincePathOfNode; // 省份信息在节点中的路径
  chinaADCPathOfNode = chinaADCPathOfNode; // 行政区划代码在节点中的路径
  datePathOfNode = datePathOfNode; // 日期信息在节点中的路径
  timestampPathOfNode = timestampPathOfNode; // 时间戳信息在节点中的路径

  statistics = {chinaADC: {}, timestamp: {}}; // 统计结果
  nodes = []; // 过滤结果
  statisticsUpdated = Math.random(); // 上次过滤结果更新标记

  constructor() {
    this.statistics = this.initStatistics();
  }

  initStatistics = () => {
    let me = this;
    const {result, sectionMap} = getTimeStatisticsStruct();
    Object.keys(sectionMap).forEach(key => sectionMap[key].amount = 0);

    let statistics = {
      chinaADC: {},
      timestamp: {},
    };

    if (me.chinaADCStatisticsType === ChinaADCStatisticsType.AREA) {
      // TODO 大区需要特殊处理
    } else {
      Object.keys(ChinaADCDisplayTitleMap[me.chinaADCStatisticsType].fullName)
        .forEach(code => statistics.chinaADC[code] = {
          shortName: ChinaADCDisplayTitleMap[me.chinaADCStatisticsType].shortName[code],
          fullName: ChinaADCDisplayTitleMap[me.chinaADCStatisticsType].fullName[code],
          formattedName: ChinaADCDisplayTitleMap[me.chinaADCStatisticsType].formattedName[code],
          color: ChinaADCColorMap[code],
          amount: 0,
        });
    }

    if (!me.mustHaveChinaADC) {
      statistics.chinaADC[unknownChinaADC] = {
        shortName: '未知',
        fullName: '未知',
        formattedName: '未知',
        color: ChinaADCColorMap[unknownChinaADC],
        amount: 0,
      };
    }

    Object.keys(result).forEach(key => {
      statistics.timestamp[key] = result[key].map(section => {
        return {
          start: section.start,
          end: section.end,
          total: 0,
        }
      });
      if (!me.mustHaveTimestamp) {
        statistics.timestamp[key].push({
          start: false,
          end: false,
          total: 0,
        });
      }
    });

    return statistics;
  };

  doFilterAndStatistics = () => {
    let me = this;

    let nodes = me.originalNodes;
    if (me.otherFilters) {
      // 其他过滤器
      me.otherFilters.forEach(filter => nodes = filter(nodes));
    }
    if (!me.preprocessed) {
      // 为时间和位置信息过滤做准备
      nodes = nodes.map(node => {
        getAndSetChinaADC(node, me.provincePathOfNode, me.chinaADCPathOfNode);
        getAndSetTimestamp(node, me.datePathOfNode, me.timestampPathOfNode);
        return node;
      });
    }

    // 过滤是否包含地理位置信息：me.mustHaveChinaADC
    if (me.mustHaveChinaADC === true) {
      nodes = nodes.filter(node => _.get(node, me.chinaADCPathOfNode) !== unknownChinaADC);
    }

    // 过滤是否包含时间信息：me.mustHaveTimestamp
    if (me.mustHaveTimestamp === true) {
      nodes = nodes.filter(node => _.get(node, me.timestampPathOfNode) !== false);
    }

    // 为按照时间及行政区划统计准备的临时变量
    let tmpData = {locationFilteredOnly: [...nodes], timestampFilteredOnly: [...nodes]};

    // 过滤地理位置信息：me.chinaADCFilter
    let chinaADCFilter = me.chinaADCFilter;
    if (chinaADCFilter !== '000000') {
      if (!(chinaADCFilter instanceof RegExp)) {
        chinaADCFilter = new RegExp(
          `^${chinaADCFilter.substring(0, me.chinaADCStatisticsType)}.*$`, 'g');
      }
      nodes = nodes.filter(node => `${_.get(node, me.chinaADCPathOfNode)}`.match(chinaADCFilter));
      tmpData.locationFilteredOnly =
        tmpData.locationFilteredOnly.filter(node => `${_.get(node, me.chinaADCPathOfNode)}`.match(chinaADCFilter));
    }

    // 过滤时间信息：me.timestampStartFilter
    if (me.timestampStartFilter === false || me.timestampEndFilter === false) {
      // 其他时段
      nodes = nodes.filter(node => _.get(node, me.timestampPathOfNode) === false);
      tmpData.timestampFilteredOnly =
        tmpData.timestampFilteredOnly.filter(node => _.get(node, me.timestampPathOfNode) === false);
    } else {
      // 时间范围
      if (me.timestampStartFilter !== -1) {
        nodes = nodes.filter(node =>
          _.get(node, me.timestampPathOfNode) &&
          _.get(node, me.timestampPathOfNode).valueOf() >= me.timestampStartFilter);
        tmpData.timestampFilteredOnly =
          tmpData.timestampFilteredOnly.filter(node =>
            _.get(node, me.timestampPathOfNode) &&
            _.get(node, me.timestampPathOfNode).valueOf() >= me.timestampStartFilter);
      }
      if (me.timestampEndFilter !== -1) {
        nodes = nodes.filter(node =>
          _.get(node, me.timestampPathOfNode) &&
          _.get(node, me.timestampPathOfNode).valueOf() <= me.timestampEndFilter);
        tmpData.timestampFilteredOnly =
          tmpData.timestampFilteredOnly.filter(node =>
            _.get(node, me.timestampPathOfNode) &&
            _.get(node, me.timestampPathOfNode).valueOf() <= me.timestampEndFilter);
      }
    }

    // 统计
    const {result, sectionMap} = getTimeStatisticsStruct();
    let {locationFilteredOnly, timestampFilteredOnly} = tmpData;
    let earliestDay = moment(0).year(moment().year() - 2).dayOfYear(1).hour(0).valueOf();
    let statistics = me.initStatistics();

    timestampFilteredOnly.forEach(node => {
      let chinaADC = _.get(node, me.chinaADCPathOfNode, unknownChinaADC);
      chinaADC = chinaADC === unknownChinaADC ? unknownChinaADC :
        chinaADC.substring(0, me.chinaADCStatisticsType).padEnd(6, '0');
      statistics.chinaADC[chinaADC] ? statistics.chinaADC[chinaADC].amount++ : null;
    });

    statistics.chinaADC['000000'].amount = Object.values(statistics.chinaADC)
      .reduce((accumulator, currentValue) => accumulator + currentValue.amount, 0);

    let others = 0;

    locationFilteredOnly.forEach(node => {
      let timestamp = _.get(node, me.timestampPathOfNode, false);
      if (timestamp) {
        let key = timestamp.clone().date(1).hour(0).minute(0).second(0).millisecond(0).valueOf();
        if (key < earliestDay) key = -1;
        sectionMap[key] && sectionMap[key].amount++;
      } else {
        others++;
      }
    });

    Object.keys(result).forEach(key => {
      statistics.timestamp[key] = result[key].map(section => {
        return {
          start: section.start,
          end: section.end,
          total: section.contains.reduce((accumulator, currentValue) => accumulator + currentValue.amount, 0),
        }
      });
      if (!me.mustHaveTimestamp) {
        statistics.timestamp[key].push({
          start: false,
          end: false,
          total: others,
        });
      }
    });

    me.nodes = nodes;
    me.statistics = statistics;
  };
}

export {FilterAndStatisticsBus};

@bindUtil.asTargetClass
@withReactStateHelper
class TimestampAndLocationStatistics extends React.Component {

  @bindUtil.bindToProperty('props.bus', 'lastUpdate')
  @autoSetState
  lastUpdate = false; // 上次原始数据更新标记

  processedUpdate = false; // 已处理过的原始数据更新标记

  // noinspection JSUnusedLocalSymbols
  onNodeFiltered = (nodes, statistics) => {
    let me = this;

    me.props.bus.statisticsUpdated = Math.random();
  };

  doFilterAndStatistics = () => {
    let me = this;
    me.processedUpdate = me.lastUpdate;
    const hideLoading = me.props.bus.originalNodes.length > 0 ? showPageLoading('数据统计中，请稍后……') : () => {};
    setTimeout(() => {
      me.props.bus.doFilterAndStatistics();
      me.onNodeFiltered(me.props.bus.nodes, me.props.bus.statistics);
      me.forceUpdate(() => setTimeout(hideLoading, 100));
    }, 100);
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.lastUpdate !== this.processedUpdate) {
      this.doFilterAndStatistics();
    }
    this.onNodeFiltered = _.isFunction(this.props.onNodesFiltered) ? this.props.onNodesFiltered : this.onNodeFiltered;
  }

  render() {
    let me = this;

    let currentYear = 0;
    let hasTimestampFilter = !(me.props.bus.timestampStartFilter === -1 && me.props.bus.timestampEndFilter === -1);
    let chinaADC = me.props.bus.chinaADCFilter;
    let chinaAOCRegex = chinaADC;
    let hasLocationFilter = chinaAOCRegex !== '000000';
    if (chinaAOCRegex && !(chinaAOCRegex instanceof RegExp)) {
      // 中国行政区划代码字符串
      chinaADC = chinaADC === unknownChinaADC ? unknownChinaADC : `${chinaADC}`.substring(0, me.props.bus.chinaADCStatisticsType);
      chinaAOCRegex = new RegExp(`^${chinaADC}.*$`, 'g');
      chinaADC = chinaADC.padEnd(6, '0');
    }

    return (
      <div className={style['statistics-frame']} style={me.props.style}>
        <div className={style['timestamp-statistics-frame']}>
          <div className={style['timestamp-statistics-frame-inner']}>
            <div className={style['timestamp-statistics']}>
              <table>
                <thead>
                <tr
                  className={hasTimestampFilter ? null : style['legend-selected']}
                  onClick={() => {
                    me.props.bus.timestampStartFilter = -1;
                    me.props.bus.timestampEndFilter = -1;
                    me.props.bus.lastUpdate = Math.random();
                  }}
                >
                  <th colSpan={2} className={style['legend-align-left']}>
                    <div>
                      全部时段
                      {
                        hasLocationFilter ? (<br />) : null
                      }
                      {
                        hasLocationFilter ? (
                          `(${chinaADC === unknownChinaADC ? '未知地区' :
                            ChinaADCDisplayTitleMap[me.props.bus.chinaADCStatisticsType].formattedName[chinaADC] || '指定地区'})`
                        ) : null
                      }
                    </div>
                  </th>
                  <th className={style['legend-align-right']}>
                    {
                      me.props.bus.statistics.timestamp[me.props.bus.timeStatisticsType] ?
                        me.props.bus.statistics.timestamp[me.props.bus.timeStatisticsType]
                          .reduce((accumulator, currentValue) => accumulator + currentValue.total, 0) : 0
                    }
                  </th>
                </tr>
                </thead>
                <tbody>
                {
                  me.props.bus.statistics.timestamp[me.props.bus.timeStatisticsType] ? (
                    [...me.props.bus.statistics.timestamp[me.props.bus.timeStatisticsType]]
                      .sort((a, b) => {
                        return a['start'] === false ? 1 : (b['start'] === false ? 1 : (b['start'] - a['start']));
                      })
                      .map(({start, end, total}) => {
                        /**
                         * @type {boolean|number}
                         */
                        let tmpCurrentYear = false;
                        let tmpCurrentMonth = '';
                        if (start !== false && start >= 0) {
                          tmpCurrentYear = moment(start).year();
                          tmpCurrentMonth = moment(start).format('MM');
                        } else if (end >= 0) {
                          tmpCurrentYear = moment(end).year();
                        }
                        if (tmpCurrentYear === false) {
                          // 不限时间或未知时间
                        } else if (currentYear === tmpCurrentYear) {
                          // 不显示年份
                          tmpCurrentYear = -1;
                        } else {
                          // 更新当前年份
                          currentYear = tmpCurrentYear;
                        }
                        return (
                          <tr
                            key={`timestamp-statistics-${start >= 0 ? start : 'unlimited'}-${end >= 0 ? end : 'unlimited'}`}
                            className={hasTimestampFilter ? (
                              (me.props.bus.timestampStartFilter === false || me.props.bus.timestampEndFilter === false) ? (
                                start === false ? style['legend-selected'] : (total > 0 ? null : style['legend-disabled'])
                              ) : (
                                (
                                  start !== false &&
                                  start >= me.props.bus.timestampStartFilter &&
                                  end <= me.props.bus.timestampEndFilter
                                ) ? style['legend-selected'] : (total > 0 ? null : style['legend-disabled'])
                              )
                            ) : (total > 0 ? null : style['legend-disabled'])}
                            onClick={
                              () => {
                                me.props.bus.timestampStartFilter = start;
                                me.props.bus.timestampEndFilter = end;
                                me.props.bus.lastUpdate = Math.random();
                              }
                            }
                          >
                            <td className={style['legend-align-left']} colSpan={start === false ? 2 : undefined}>
                              {
                                tmpCurrentYear > 0 ? (start === -1 ? (tmpCurrentYear + 1) : `${tmpCurrentYear}.`) : null
                              }
                              {
                                start === false ? '未知' : null
                              }
                            </td>
                            {
                              tmpCurrentMonth ? (
                                <td className={style['legend-align-right']} style={{paddingLeft: 0}}>{tmpCurrentMonth}</td>
                              ) : (
                                start === -1 ? (
                                  <td className={style['legend-align-right']} style={{paddingLeft: 0}}>前</td>
                                ) : null
                              )
                            }
                            <td className={style['legend-align-right']}>{total}</td>
                          </tr>
                        )
                      })
                  ) : null
                }
                </tbody>
              </table>
            </div>
            {/*<div className={style['china_adc-statistics']}>
              <table>
                <thead>
                <tr
                  key={`china_adc-statistics-all`}
                  className={hasLocationFilter ? null : style['legend-selected']}
                  onClick={() => {
                    me.props.bus.chinaADCFilter = '000000;
                    me.props.bus.lastUpdate = Math.random();
                  }}
                >
                  <th className={style['legend-align-left']}>
                    全部地区
                    {
                      hasTimestampFilter ? (<br />) : null
                    }
                    {
                      hasTimestampFilter ? (
                        `(${
                          (me.props.bus.timestampStartFilter === false || me.props.bus.timestampEndFilter === false) ?
                            '未知时段' :
                            (
                              localizationFormat[me.props.bus.timeStatisticsType]
                                .fullName(me.props.bus.timestampStartFilter, me.props.bus.timestampEndFilter)
                            )
                          })`
                      ) : null
                    }
                  </th>
                  <th className={style['legend-align-right']}>
                    {
                      Object.values(me.props.bus.statistics.chinaADC)
                        .reduce((accumulator, currentValue) => accumulator + currentValue.amount, 0)
                    }
                  </th>
                </tr>
                </thead>
                <tbody>
                {
                  Object.keys(me.props.bus.statistics.chinaADC).map(key => (
                    <tr
                      key={`china_adc-statistics-${key}`}
                      className={chinaAOCRegex !== '000000' ? (
                        `${key}`.match(chinaAOCRegex) ? style['legend-selected'] :
                          (me.props.bus.statistics.chinaADC[key].amount > 0 ? null : style['legend-disabled'])
                      ) : (me.props.bus.statistics.chinaADC[key].amount > 0 ? null : style['legend-disabled'])}
                      onClick={() => {
                        me.props.bus.chinaADCFilter = key;
                        me.props.bus.lastUpdate = Math.random();
                      }}
                    >
                      <td className={style['legend-align-left']}>{me.props.bus.statistics.chinaADC[key].formattedName}</td>
                      <td className={style['legend-align-right']}>{me.props.bus.statistics.chinaADC[key].amount}</td>
                    </tr>
                  ))
                }
                </tbody>
              </table>
            </div>*/}
          </div>
        </div>
        <div className={style['china_adc-statistics-frame']}>
          <div className={style['china_adc-statistics-frame-inner']}>
            {
              Object.keys(me.props.bus.statistics.chinaADC).sort().map(key => {
                return (
                  <Popover
                    key={`china_adc-${key}`}
                    placement="top"
                    overlayClassName={`dark-theme ${style['china_adc-item-content']}`}
                    content={
                      <div>
                        <div className={style['china_adc-item-content-title']}>
                          <div
                            style={{
                              backgroundColor: me.props.bus.statistics.chinaADC[key].color,
                              marginRight: '0.5rem',
                            }}
                          >
                            <Icon
                              name={`icon-china_adc-${key}`}
                              type={IconTypes.ICON_FONT}
                            />
                          </div>
                          <h4>{me.props.bus.statistics.chinaADC[key].formattedName}</h4>
                        </div>
                        <div className={style['china_adc-item-content-table']}>
                          <table>
                            <tbody>
                            <tr>
                              <td>资源数</td>
                              <td style={{textAlign: 'right'}}>{me.props.bus.statistics.chinaADC[key].amount}</td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    }
                    arrowPointAtCenter
                    transitionName={'fade'}
                  >
                    <div
                      className={
                        `${style['china_adc-item']} ${
                          (chinaAOCRegex === '000000' || !`${key}`.match(chinaAOCRegex)) ? (
                            me.props.bus.statistics.chinaADC[key].amount <= 0 ? style['china_adc-item-disabled'] : ''
                          ) : style['china_adc-item-selected']
                        }`
                      }
                      onClick={() => {
                        me.props.bus.chinaADCFilter = key;
                        me.props.bus.lastUpdate = Math.random();
                      }}
                    >
                      <span
                        className={style['china_adc-item-icon-background']}
                        style={{backgroundColor: me.props.bus.statistics.chinaADC[key].color}}
                      >
                        <Icon
                          name={`icon-china_adc-${key}`}
                          type={IconTypes.ICON_FONT}
                        />
                      </span>
                      <span className={style['china_adc-item-icon']}>
                        <Icon
                          name={`icon-china_adc-${key}`}
                          type={IconTypes.ICON_FONT}
                        />
                      </span>
                      <span className={style['china_adc-item-amount']}>{me.props.bus.statistics.chinaADC[key].amount}</span>
                    </div>
                  </Popover>
                )
              })
            }
          </div>
        </div>
      </div>
    )
  }
}

TimestampAndLocationStatistics.defaultProps = {
  style: {},
  onNodesFiltered: false,
};

TimestampAndLocationStatistics.propTypes = {
  style: PropTypes.object,
  bus: PropTypes.instanceOf(FilterAndStatisticsBus).isRequired,
  onNodesFiltered: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
};

export default TimestampAndLocationStatistics;