import React from "react";
import PropTypes from 'prop-types';
import moment from "moment";

import PB, {SimplePB} from "@/libs/simplePB";

import {getNodeDisplayTitle, getNodeIcon} from "@/constants/vis.defaultDefine.1";

import TimeDisplayField from "@/components/common/common.timeDisplayField";
import Icon from "@/components/common/common.icon";
import {IconTypes} from "@/constants/common";
import TeamworkMemberStatisticsChart from "@/components/common/view/teamwork/common.view.teamwork.memberStatisticsChart";

import style from '@/style/common/view/common.view.teamwork.memberNodePanel.less';
import {getDisplayNumber} from "@/components/common/common.functions";

class TeamworkMemberNodePanel extends React.Component {
  onTryAgain = () => {
    let me = this;
    me.props.bus.emit('teamwork', 'statistics.member_node_amount.load',
      {memberId: me.props.memberInfo.userId});
  };

  doFocusNode = node => {
    this.props.bus.emit('relation', 'node.presentation.focus', {node: node});
  };

  render() {
    let me = this;
    let todayDate = new Date();

    if (me.props.loadingStatus === 'success') {
      return (
        <div key={'success'} className={style['result-frame']}>
          <div className={style['result-frame-left-container']}>
            <div className={style['result-title']}>活跃度统计</div>
            <div className={style['result-frame-statistics-container']}>
              {
                me.props.memberNodeAmountStatistics ? (
                  <table>
                    <thead>
                      <tr>
                        <th>
                          积分
                        </th>
                        <th style={{textAlign: 'right'}}>
                          <Icon name={'icon-yuandianda'} 
                            type={IconTypes.ICON_FONT} 
                            style={{color: '#7f7f7f', marginRight: 4}}
                          />
                          当前图谱
                        </th>
                        <th style={{textAlign: 'right'}}>
                          <Icon name={'icon-yuandianda'} 
                            type={IconTypes.ICON_FONT} 
                            style={{color: '#ed7d31', marginRight: 4}}
                          />
                          TA在当前图谱
                        </th>
                        <th style={{textAlign: 'right'}}>
                          TA在全系统
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td>总积分</td>
                      <td style={{textAlign: 'right'}}>
                        {getDisplayNumber(me.props.viewStatistics.totalViewNodeAmount)}
                      </td>
                      <td style={{textAlign: 'right'}}>
                        {getDisplayNumber(me.props.memberNodeAmountStatistics.totalNodeAmount)}
                      </td>
                      <td style={{textAlign: 'right'}}>
                        {/*getDisplayNumber(me.props.memberNodeAmountStatistics.totalUserNodeAmount)*/}...
                      </td>
                    </tr>
                    <tr>
                      <td>最大单日</td>
                      <td style={{textAlign: 'right'}}>
                        {getDisplayNumber(me.props.viewStatistics.maxViewNodeAmountByDay)}
                      </td>
                      <td style={{textAlign: 'right'}}>
                        {getDisplayNumber(me.props.memberNodeAmountStatistics.maxNodeAmountByDay)}
                      </td>
                      <td style={{textAlign: 'right'}}>
                        {/*getDisplayNumber(me.props.memberNodeAmountStatistics.maxUserNodeAmountByDay)*/}...
                      </td>
                    </tr>
                    <tr>
                      <td>最近一周</td>
                      <td style={{textAlign: 'right'}}>
                        {getDisplayNumber(me.props.viewStatistics.viewNodeAmountRecentDays)}
                      </td>
                      <td style={{textAlign: 'right'}}>
                        {getDisplayNumber(me.props.memberNodeAmountStatistics.nodeAmountRecentDays)}
                      </td>
                      <td style={{textAlign: 'right'}}>
                        {/*getDisplayNumber(me.props.memberNodeAmountStatistics.userNodeAmountRecentDays)*/}...
                      </td>
                    </tr>
                    <tr>
                      <td>{moment(todayDate).month() > 9 ? `${moment(todayDate).month() + 1}月` : `${moment(todayDate).month() + 1}月份` }</td>
                      <td style={{textAlign: 'right'}}>
                        {getDisplayNumber(me.props.viewStatistics.viewNodeAmountThisMonth)}
                      </td>
                      <td style={{textAlign: 'right'}}>
                        {getDisplayNumber(me.props.memberNodeAmountStatistics.nodeAmountThisMonth)}
                      </td>
                      <td style={{textAlign: 'right'}}>
                        {/*getDisplayNumber(me.props.memberNodeAmountStatistics.userNodeAmountThisMonth)*/}...
                      </td>
                    </tr>
                    <tr>
                      <td>{ `${['一', '二', '三', '四'][moment(todayDate).quarter() - 1]}季度`}</td>
                      <td style={{textAlign: 'right'}}>
                        {getDisplayNumber(me.props.viewStatistics.viewNodeAmountThisQuarter)}
                      </td>
                      <td style={{textAlign: 'right'}}>
                        {getDisplayNumber(me.props.memberNodeAmountStatistics.nodeAmountThisQuarter)}
                      </td>
                      <td style={{textAlign: 'right'}}>
                        {/*getDisplayNumber(me.props.memberNodeAmountStatistics.userNodeAmountThisQuarter)*/}...
                      </td>
                    </tr>
                    <tr>
                      <td>本年度</td>
                      <td style={{textAlign: 'right'}}>
                        {getDisplayNumber(me.props.viewStatistics.viewNodeAmountThisYear)}
                      </td>
                      <td style={{textAlign: 'right'}}>
                        {getDisplayNumber(me.props.memberNodeAmountStatistics.nodeAmountThisYear)}
                      </td>
                      <td style={{textAlign: 'right'}}>
                        {/*getDisplayNumber(me.props.memberNodeAmountStatistics.userNodeAmountThisYear)*/}...
                      </td>
                    </tr>
                    </tbody>
                  </table>
                ) : null
              }
            </div>
            <div className={style['result-frame-chart-container']}>
              <TeamworkMemberStatisticsChart
                title={'近7天活跃度'}
                overallStatistics={me.props.overallStatistics}
                memberStatistics={me.props.memberStatistics}
                memberInfo={me.props.memberInfo}
              />
            </div>
          </div>
          <div className={style['result-frame-list-container']}>
            <div className={style['result-title']}>最新节点</div>
            {
              me.props.memberRecentNodeList.length > 0 ? (
                <table className={style['result-frame-list-table']}>
                  <tbody>
                  {
                    me.props.memberRecentNodeList.map(node => (
                      <tr key={`node-${node.id}`}  onClick={() => me.doFocusNode(node)}>
                        <td>
                          <Icon name={getNodeIcon(node).name} type={getNodeIcon(node).type} color={'#a5a5a5'} style={{marginRight: '0.5em'}} />
                          {getNodeDisplayTitle(node)}
                        </td>
                        <td>
                          {node.updateTime ? (
                            <TimeDisplayField timestamp={moment(node.updateTime, 'YYYY-MM-DD HH:mm:ss').valueOf()} />
                          ) : (node.linkTime ? (
                            <TimeDisplayField timestamp={moment(node.linkTime, 'YYYY-MM-DD HH:mm:ss').valueOf()} />
                          ) : '-')}
                        </td>
                      </tr>
                    ))
                  }
                  </tbody>
                </table>
              ) : (
                <div style={{textAlign: 'center', padding: '1rem 2rem', color: '#999'}}>暂无最新节点</div>
              )
            }
          </div>
        </div>
      );
    } else if (me.props.loadingStatus === 'failed') {
      return (
        <div key={'failed'} className={style['result-failed']}>
          <Icon name={'exclamation-circle'} theme={'outlined'} /><br />
          <span>数据加载失败，<a onClick={() => me.onTryAgain()}>点击重试</a></span>
        </div>
      )
    } else {
      return (
        <div key={'loading'} className={style['result-loading']}>
          <Icon name={'loading'} theme={'outlined'} /><br />
          数据加载中，请稍后...
        </div>
      )
    }
  }
}

TeamworkMemberNodePanel.defaultProps = {
  bus: PB,
  memberRecentNodeList: [],
  overallStatistics: {},
  memberStatistics: {},
  viewStatistics: {},
  loadingStatus: 'idle',
};

TeamworkMemberNodePanel.propTypes = {
  bus: PropTypes.instanceOf(SimplePB),
  memberRecentNodeList: PropTypes.array,
  overallStatistics: PropTypes.object,
  memberStatistics: PropTypes.object,
  viewStatistics: PropTypes.object,
  memberNodeAmountStatistics: PropTypes.object,
  loadingStatus: PropTypes.oneOf(['idle', 'processing', 'success', 'failed']),
  memberInfo: PropTypes.object.isRequired,
};

export default TeamworkMemberNodePanel;
