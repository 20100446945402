import React, { useState, useEffect } from 'react';
import { Drawer } from 'antd';
import EventListener from 'react-event-listener';

let isResizing = false;

const ResizableDrawer = ({ children, ...props }) => {
  const [drawerWidth, setDrawerWidth] = useState(undefined);
  const [resizerWidth, setResizerWidth] = useState(undefined);

  useEffect(() => {
    setDrawerWidth(props.width);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.visible]);

  function handleMouseUp() {
    if (!isResizing) {
      return;
    }
    isResizing = false;
    setResizerWidth(undefined);
  }

  function handleMouseDown(e) {
    e.stopPropagation();
    e.preventDefault();
    isResizing = true;
    setResizerWidth('100%');
  }

  function handleMouseMove(e) {
    if (!isResizing) return;
    let offsetRight =
      document.body.offsetWidth - (e.clientX - document.body.offsetLeft);
    if (offsetRight > 0 && offsetRight < document.body.offsetWidth) {
      setDrawerWidth(offsetRight);
    }
  }

  return (
    <Drawer {...props} width={drawerWidth}>
      <EventListener
        target={window}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
      />
      <div
        className={'drawer-resizer'}
        style={{width: resizerWidth}}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
      />
      {children}
    </Drawer>
  );
};

export default ResizableDrawer;
