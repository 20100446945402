import React, {lazy, Suspense} from 'react';
// noinspection ES6CheckImport
import {renderToString} from 'react-dom/server';
import PropTypes from 'prop-types';
import vis from 'vis';
import moment from 'moment';
import momentLocale from 'moment/locale/zh-cn';
import 'vis/dist/vis-timeline-graph2d.min.css';
import {Button, Layout, message, Tooltip} from 'antd';

import {getNodeDisplayTitle} from "@/constants/vis.defaultDefine.1";

import {showErrorMessage, showPageLoading} from '@/components/common/common.message';
import NodeDataProvider from "@/components/common/dataProvider/common.dataProvider.node.js";
import ViewDataProvider from '@/components/common/dataProvider/common.dataProvider.view';
import PB from '@/libs/simplePB';
import style from "@/style/containers/relationView.less";

import NodeInfoCard from "@/components/relationView/relation.nodeInfoCard";
import RelationPagination from "@/components/relationView/relation.pagination_2";
import RelationEmptyResult from "@/components/relationView/relation.emptyResult";
import NodeInfoAttachmentListModal from "@/components/mainView/nodeInfo/main.nodeInfo.attachmentListModal";
import NodeFilter from "@/components/relationView/relation.timeline.nodeFilter";
import MainAiConsoleMessageCommonNodeInfo
  from '@/components/mainView/aiConsoleMessage/main.aiConsoleMessage.commonNodeInfo';
import Icon from '@/components/common/common.icon';
import {IconTypes} from '@/constants/common';
import SysConfig from '@/constants/sys.config';
// moment 使用中文
moment.updateLocale('zh-cn', momentLocale);

// noinspection JSUnusedGlobalSymbols
vis.DataSet.prototype.replaceWith = function (data, senderId) {
  let addedIds = [];
  let updatedIds = [];
  let removedIds;
  let oldData = [];
  let updatedData = [];
  let removedItems = [];
  let me = this;
  let fieldId = me._fieldId;
  let originalIds = me.getIds();
  
  let addOrUpdate = (item) => {
    let id = item[fieldId];
    if (me._data[id]) {
      let oldItem = vis.util.extend({}, me._data[id]);
      // update item
      id = me._updateItem(item);
      updatedIds.push(id);
      updatedData.push(item);
      oldData.push(oldItem);
    } else {
      // add new item
      id = me._addItem(item);
      addedIds.push(id);
    }
  };
  
  if (Array.isArray(data)) {
    // Array
    for (let i = 0, len = data.length; i < len; i++) {
      if (data[i] && typeof data[i] === 'object') {
        addOrUpdate(data[i]);
      } else {
        console.warn('Ignoring input item, which is not an object at index ' + i);
      }
    }
  } else if (data && typeof data === 'object') {
    // Single item
    addOrUpdate(data);
  } else {
    throw new Error('Unknown dataType');
  }
  
  removedIds = originalIds.filter(id => !updatedIds.includes(id));
  for (let i = 0, len = removedIds.length; i < len; i++) {
    let item = me._remove(removedIds[i]);
    if (item) {
      removedItems.push(item);
    }
  }
  
  if (addedIds.length) {
    me._trigger('add', {items: addedIds}, senderId);
  }
  if (updatedIds.length) {
    let props = {items: updatedIds, oldData: oldData, data: updatedData};
    me._trigger('update', props, senderId);
  }
  if (removedIds.length) {
    me._trigger('remove', {items: removedIds, oldData: removedItems}, senderId);
  }
  
  return addedIds.concat(updatedIds);
};

class Timeline extends React.Component {
  // 容器id
  containerId = `briefing-timeline-${Math.random()}`;
  
  container = undefined;
  
  // vis timeline实例
  myTimeline = undefined;
  
  // 用于 vis timeline 画图绑定的dataset
  /**
   * @type {vis.DataSet|DataSet}
   */
  data = new vis.DataSet();
  
  // timeline默认配置，正常状态(多个节点的)
  defaultOption = {
    width: '100%',
    height: '100%',
    type: 'box',
    selectable: false,
    zoomMin: 30 * 60 * 1000,
    min: new Date(1800, 1, 1),
    max: new Date(2037, 12, 31),
    template: (item/*, element*/) => {
      return renderToString(
        <NodeInfoCard
          key={`node.${item.node.id}`}
          info={item.node}
          userInfo={item.userInfo}
          viewType={this.props.viewType}
        />
      );
    },
    format: {
      minorLabels: {
        millisecond: 'SSS',
        second: 'MM:ss',
        minute: 'HH:mm',
        hour: 'HH:mm',
        weekday: 'D日',
        day: 'D日',
        week: '第w周',
        month: 'M月',
        year: 'YYYY年',
      },
      majorLabels: {
        millisecond: 'HH:mm:ss',
        second: 'D日 HH时',
        minute: 'M月DD日',
        hour: 'M月DD日',
        weekday: 'YYYY年MM月',
        day: 'YYYY年MM月',
        week: 'YYYY年MM月',
        month: 'YYYY年',
        year: '',
      },
    },
  };
  
  rangeChangedByUser = false;
  
  rangeChangedByUserTimeout = undefined;
  
  drawAllTimeout = 0;
  
  hideDrawAllPageLoading = undefined;
  
  originalViewNodesRangeList = undefined; // 原图谱中节点分页后的日期范围
  
  /* 解析过程mesage key */
  messageKey = undefined;
  
  detailMessageKeys = {};
  
  state = {
    // 强制刷新
    refresh: false,
    
    // 鼠标悬浮的节点
    // nodeOnMouseHover: undefined,
    
    // 当前展示的原图谱中的节点
    currentShownOriginalViewNodes: [],
    currentShownOriginalViewNodesTimeRange: [],
    
    selectedNode: undefined, // 选中的节点
    selectedNodeBoardShow: false,
    extraInfoListModalVisible: false,
    extraInfoListModalNodeId: undefined,
    
  };
  // 新的导航方式 年月日 选择后展示节点
  yearRangeOfNodesList = [];
  // 新的导航方式 年月日 级联菜单数据
  datetimeTree = [];
  // 新的导航方式 年月日 底部导航按钮
  dateTimePagination = {
    years: {},
    months: {
      year: null,
      month: null,
      date: null,
      
      startTime: '',
      endTime: '',
      
      nodesTotal: 0,
      pagination: {}
    },
    dates: {
      year: null,
      month: null,
      date: null,
      
      startTime: '',
      endTime: '',
      
      nodesTotal: 0,
      pagination: {}
    },
    hours: {
      year: null,
      month: null,
      date: null,
      
      startTime: '',
      endTime: '',
      
      nodesTotal: 0,
      pagination: {}
    },
  };
  
  // 新的导航方式 年月日 时间范围内的前300个
  nodesOfTimeRangeTop300 = [];
  // 新的导航方式 年月日 超过300个的节点总数
  nodesTotalOutOf300 = 0;
  
  
  processOriginalViewNodes = () => {
    console.log('relation.timeline processOriginalViewNodes');
    let me = this;
    me.originalViewNodesRangeList = me.props.paginationViewNodes(me.props.viewNodeList, me.props.viewType);
    me.forceUpdate();
  };
  
  XonPageChange = (currentOriginalViewNodesPage, currentSubPageIndex) => {
    let me = this, list, range = [];
    list = me.originalViewNodesRangeList[currentOriginalViewNodesPage - 1];
    if (list.idx > 0) {
      // 分段
      range[0] = list.monthStart.clone().add(10 * (list.idx - 1), 'day');
      range[1] = list.idx >= 3 ? range[0].clone().startOf('month').add(1, 'month') :
        range[0].clone().add(10, 'day');
    } else {
      // 整月
      range[0] = list.monthStart.clone();
      range[1] = range[0].clone().startOf('month').add(1, 'month');
    }
    
    me.setState({
      currentShownOriginalViewNodes: list.hasSubPage[currentSubPageIndex] || [],
      currentShownOriginalViewNodesTimeRange: range,
    }, () => {
      if (!me.messageKey) {
        PB.emit('aiConsole', 'message.push', {
          type: 'ai',
          content: `图谱 "${me.props.viewInfo.name}" 加载${me.props.filterType[me.props.filterCurKey].text}数据完成`,
          callback: ({key}) => {
            me.messageKey = key;
          },
        });
      }
      me.drawAll();
    });
  };
  
  onRangeChangedByUser = () => {
    let me = this;
    me.rangeChangedByUser = true;
    me.myTimeline.off('rangeChanged'.toLowerCase(), me.onRangeChangedByUser);
  };
  
  // ------------------------------------------------------------------------------
  /**
   * 根据指定字段，读取节点用于时间线显示的时间值
   * @param node
   * @returns {undefined}
   */
  getNodeTimeForTimeLine = (node) => {
    const {viewType} = this.props;
    let nodeTime = undefined;
    if (viewType === 'tagTime') {
      if (node.hasOwnProperty('tags') && node.tags.length > 0) {
        try {
          nodeTime = new Date(node.tagTime); // 张絮石的代码
          // // nodeTime = new Date(node.tags[0]);
          // // nodeTime = moment(node.tagTime);
          // nodeTime = moment(node.tags[0]);
          // nodeTime = nodeTime.isValid() ? nodeTime.toDate() : undefined;
        } catch (e) {
          console.log('tag time 格式化出错')
        }
      }
    } else if (viewType === 'linkTime') {
      nodeTime = new Date(node.linkTime);
    } else {
      nodeTime = new Date(node.updateTime || node.linkTime);
    }
    return nodeTime
  }
  
  /**
   * 统计时间段内的节点数量
   * @param startTime
   * @param endTime
   * @returns {number}
   */
  countNodes = (startTime, endTime) => {
    let me = this
    let total = 0;
    this.props.viewNodeList.forEach(n => {
      let nodeTime = undefined;
      if (me.props.viewType === 'tagTime') {
        nodeTime = new Date(n.tagTime);
      } else if (me.props.viewType === 'linkTime') {
        nodeTime = new Date(n.linkTime);
      } else {
        nodeTime = new Date(n.updateTime || n.linkTime);
      }
      if (nodeTime) {
        if (nodeTime >= startTime && nodeTime < endTime) {
          total++;
        }
      }
    });
    return total;
  }
  
  /**
   * 月份导航按钮默认数据
   */
  resetMonthData = () => {
    this.dateTimePagination.months.pagination = {};
    for (let i = 1; i < 13; i++) {
      this.dateTimePagination.months.pagination['m_' + i] = {
        label: i + '月',
        value: i,
        total: 0
      };
    }
  }
  /**
   * 日期导航按钮默认数据
   */
  resetDateData = () => {
    this.dateTimePagination.dates.pagination = {};
    for (let i = 1; i < 32; i++) {
      this.dateTimePagination.dates.pagination['d_' + i] = {
        label: i + '日',
        value: i,
        total: 0
      };
    }
  }
  /**
   * 时间导航按钮默认数据
   */
  resetHourData = () => {
    this.dateTimePagination.hours.pagination = {};
    for (let i = 0; i < 25; i++) {
      this.dateTimePagination.hours.pagination['d_' + i] = {
        label: i + '时',
        value: i,
        total: 0
      };
    }
  }
  
  /**
   * 生成底部导航按钮用的数据
   * @param t_year
   * @param t_month
   * @param t_date
   * @returns *
   */
  genPaginationData = (t_year = null, t_month = null, t_date = null) => {
    // 选中年，列出12个月 和每个月的数量
    // 选中年月，列出当月每天，及其数量
    // 选中年月日，列出24小时及其数量、
    //  数量为零的 导航按钮为灰色
    let me = this;
    // 导航key的前缀 y=年, m=月, d=日, h=小时
    let key_pre = 'y';
    // 导航数据
    let result = {
      year: t_year,
      month: t_month,
      date: t_date,
      
      startTime: '',
      endTime: '',
      
      nodesTotal: 0,
      pagination: {}
    };
    
    if (!t_year) {
      // 没有设置时间 => 列出跨度内的全部年份
      key_pre = 'y';
      result['startTime'] = new Date(this.yearRangeOfNodesList[0] + '-01-01 00:00');
      // result['endTime'] = this.yearRangeOfNodesList[this.yearRangeOfNodesList.length - 1] + '-12-31 23:59';
      result['endTime'] = new Date(this.yearRangeOfNodesList[this.yearRangeOfNodesList.length - 1] + '-12-31 00:00');
      result['endTime'] = result['endTime'].setDate(result['endTime'].getDate() + 1);
      result['endTime'] = new Date(result['endTime']);
      
      result['year'] = null;
      result['month'] = null;
      result['date'] = null;
      
      // 必须列出节点列表里指定字段时间中年份跨度的全部年份
      this.yearRangeOfNodesList.forEach(y => {
        let tempStartTime = new Date(y + '-01-01 00:00');
        let tempEndTime = new Date(y + '-12-31 00:00');
        tempEndTime = tempEndTime.setDate(tempEndTime.getDate() + 1);
        tempEndTime = new Date(tempEndTime);
        
        result.pagination[key_pre + '_' + y] = {
          label: y + '年',
          value: y,
          total: me.countNodes(tempStartTime, tempEndTime)
        };
      });
      
    } else {
      let month_arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
      if (month_arr.indexOf(t_month) === -1) {
        // 选择没有月份,或者月份不正确，就认为选择了年，(这种情况不再判断是否到具体日期)
        // 选择了年 => 列出全部12个月份
        key_pre = 'm';
        result['startTime'] = new Date(`${t_year}-01-01 00:00`);
        result['endTime'] = new Date(`${t_year}-12-31 00:00`);
        result['endTime'] = result['endTime'].setDate(result['endTime'].getDate() + 1);
        result['endTime'] = new Date(result['endTime']);
        
        // result['startTime'] = t_year + '-01-01 00:00';
        // result['endTime'] = t_year + '-12-31 23:59';
        
        result['year'] = t_year;
        result['month'] = null;
        result['date'] = null;
        
        // 列出全部12个月份,没有节点的 total 设置为0
        for (let i = 1; i < 13; i++) {
          let tempStartTime = new Date(`${t_year}-${i}-01 00:00`);
          let tempEndTime = new Date(`${t_year}-${i}-31 00:00`);
          tempEndTime = tempEndTime.setDate(tempEndTime.getDate() + 1);
          tempEndTime = new Date(tempEndTime);
          result.pagination[key_pre + '_' + i] = {
            label: i + '月',
            value: i,
            total: me.countNodes(tempStartTime, tempEndTime)
          };
        }
        
      } else {
        // 计算当月多少天
        let day_total = new Date(t_year, t_month, 0).getDate();   //最后一个参数为0,意为获取2018年10月一共多少天
        
        if (!t_date) {
          // 选择到月份 => 列出全部天数
          key_pre = 'd';
          
          result['startTime'] = new Date(`${t_year}-${t_month}-01 00:00`);
          result['endTime'] = new Date(`${t_year}-${t_month}-${day_total} 00:00`);
          result['endTime'] = result['endTime'].setDate(result['endTime'].getDate() + 1);
          result['endTime'] = new Date(result['endTime']);
          
          // result['startTime'] = `${t_year}-${t_month}-01`;
          // result['endTime'] = `${t_year}-${t_month}-${day_total} 23:59`;
          
          result['year'] = t_year;
          result['month'] = t_month;
          result['date'] = null;
          // 列出这个月份全部的天数，
          for (let i = 1; i <= day_total; i++) {
            let tempStartTime = new Date(`${t_year}-${t_month}-${i} 00:00`);
            let tempEndTime = new Date(`${t_year}-${t_month}-${i} 00:00`);
            tempEndTime = tempEndTime.setDate(tempEndTime.getDate() + 1);
            tempEndTime = new Date(tempEndTime);
            
            result.pagination[key_pre + '_' + i] = {
              label: i + '日',
              value: i,
              total: me.countNodes(tempStartTime, tempEndTime)
            };
          }
          
        } else {
          // 选择到天 => 列出24小时
          key_pre = 'h';
          let t_date_fact = t_date;
          if (t_date > day_total) {
            // 日期不在月份以内，报错=> 取最后一天
            console.log('日期错误');
            t_date_fact = day_total;
          }
          
          result['startTime'] = new Date(`${t_year}-${t_month}-${t_date_fact} 00:00`);
          result['endTime'] = new Date(`${t_year}-${t_month}-${t_date_fact} 00:00`);
          result['endTime'] = result['endTime'].setDate(result['endTime'].getDate() + 1);
          result['endTime'] = new Date(result['endTime']);
          
          // result['startTime'] = `${t_year}-${t_month}-${t_date_fact} 00:00`;
          // result['endTime'] = `${t_year}-${t_month}-${t_date_fact} 23:59`;
          result['year'] = t_year;
          result['month'] = t_month;
          result['date'] = t_date_fact;
          
          // 列出24小时
          for (let i = 0; i < 25; i++) {
            let tempStartTime = new Date(`${t_year}-${t_month}-${t_date_fact} ${i}:00`);
            let tempEndTime = new Date(`${t_year}-${t_month}-${t_date_fact} ${i}:00`);
            tempEndTime = tempEndTime.setHours(tempEndTime.getHours() + 1); // 加1小时
            tempEndTime = new Date(tempEndTime);
            result.pagination[key_pre + '_' + i] = {
              label: i === 0 ? '0时' : i + '时',
              value: i,
              total: me.countNodes(tempStartTime, tempEndTime)
            };
          }
        }
      }
    }
    
    return result;
  }
  
  /**
   * 时间段范围变化的事件
   * @param values: 格式：[year, [month], [date], [hour]]
   * @param limit: 时间段变化时，画节点的数量
   */
  onRangeChange = (values, limit = 300) => {
    console.log('relation.timeline onRangeChange -> values:', values);
    
    let me = this;
    
    // vis timeline 时间轴起始端时间
    let start_time = undefined, end_time = undefined;
    
    // 判断时间范围
    let timeRangeLevel = 'year'; // 默认值
    if (values.length === 1) {
      // 指定了年 => 更新月；重置日期、小时
      // 更新月
      this.dateTimePagination.months = {};
      this.dateTimePagination.months = this.genPaginationData(values[0]);
      start_time = new Date(this.dateTimePagination.months.startTime);
      end_time = new Date(this.dateTimePagination.months.endTime);
      // 重置日期、小时
      this.resetDateData();
      this.resetHourData();
      
    } else if (values.length === 2) {
      // 指定了年、月 => 更新日期；重置小时
      timeRangeLevel = 'month';
      // 更新日期
      this.dateTimePagination.dates = {};
      this.dateTimePagination.dates = this.genPaginationData(values[0], values[1]);
      start_time = new Date(this.dateTimePagination.dates.startTime);
      end_time = new Date(this.dateTimePagination.dates.endTime);
      // 重置小时
      this.resetHourData();
      
    } else if (values.length === 3) {
      // 指定了年、月、日 => 更新小时
      timeRangeLevel = 'date';
      // 更新小时
      this.dateTimePagination.hours = {};
      this.dateTimePagination.hours = this.genPaginationData(values[0], values[1], values[2]);
      start_time = new Date(this.dateTimePagination.hours.startTime);
      end_time = new Date(this.dateTimePagination.hours.endTime);
      
    } else if (values.length === 4) {
      // 指定了年、月、日、小时 => 只刷新数据，不刷新导航
      timeRangeLevel = 'hour';
      start_time = new Date(`${values[0]}-${values[1]}-${values[2]} ${values[3]}:00`);
      end_time = new Date(`${values[0]}-${values[1]}-${values[2]} ${values[3]}:00`);
      end_time = end_time.setHours(end_time.getHours() + 1);
      end_time = new Date(end_time);
      
    } else {
      // 什么都没指定 => 显示全部节点数据，重置月份、日期、小时
      
      start_time = this.dateTimePagination.years.startTime;
      end_time = this.dateTimePagination.years.endTime;
      
      // 年不动,其他重置
      this.resetMonthData();
      this.resetDateData();
      this.resetHourData();
      
    }
    me.drawNodesInTimeRange(start_time, end_time, timeRangeLevel, limit);
  }
  
  /**
   * 筛选时间段内节点，保留前 xxx 个，并画时间线
   * @param startTime
   * @param endTime
   * @param level: 数据的时间范围是年、月、日、小时中的哪个层级，影响时间轴放血大小
   * @param limit: 显示数量
   */
  drawNodesInTimeRange = (startTime, endTime, level = 'year', limit = 300) => {
    console.log('relation.timeline drawNodesInTimeRange -> startTime:', startTime);
    console.log('relation.timeline drawNodesInTimeRange -> endTime:', endTime);
    
    let me = this;
    // let drawLoading = showPageLoading('正在绘图，请稍后……');
    message.loading('正在绘图，请稍后……', 0);
    // 清空 vis timeline
    this.data.replaceWith([]);
    
    // 筛选时间范围内前300（默认）个，并统计超出300还有多少个没显示
    let t = 0;
    me.nodesTotalOutOf300 = 0;
    me.nodesOfTimeRangeTop300 = [];
    this.props.viewNodeList.forEach(n => {
      let nodeTime = this.getNodeTimeForTimeLine(n);
      if (nodeTime >= startTime && nodeTime < endTime) {
        if (t < limit || limit === -1) {
          // 节点所属用户信息，用来显示头像
          let userInfo = undefined;
          if (n.userId && me.props.userList.length > 0) {
            userInfo = me.props.userList.find(user => parseInt(user.userId) === parseInt(n.userId));
          }
          me.nodesOfTimeRangeTop300.push({
            id: n.id,
            // start: nodeTime.format('YYYY-MM-DD HH:mm:ss'), //YYYY-MM-DD HH:mm:ss
            start: nodeTime, //YYYY-MM-DD HH:mm:ss
            content: getNodeDisplayTitle(n),
            category: 'view_node',
            userInfo,
            node: n,
          })
          t++;
        } else {
          me.nodesTotalOutOf300++;
        }
      }
    });
    // 更新时间轴,时间轴的可移动范围要比实际时间范围要大，否则节点信息框会有一半在屏幕外
    let timelineTimeMin = new Date(startTime);
    let timelineTimeMax = new Date(endTime);
    if (level === 'month') {
      // timelineTimeMin = timelineTimeMin.setMonth(timelineTimeMin.getMonth() - 1);
      // timelineTimeMax = timelineTimeMax.setMonth(timelineTimeMax.getMonth() + 1);
      timelineTimeMin = timelineTimeMin.setDate(timelineTimeMin.getDate() - 3);
      timelineTimeMax = timelineTimeMax.setDate(timelineTimeMax.getDate() + 3);
    } else if (level === 'date') {
      // timelineTimeMin = timelineTimeMin.setDate(timelineTimeMin.getDate() - 1);
      // timelineTimeMax = timelineTimeMax.setDate(timelineTimeMax.getDate() + 1);
      timelineTimeMin = timelineTimeMin.setHours(timelineTimeMin.getHours() - 3);
      timelineTimeMax = timelineTimeMax.setHours(timelineTimeMax.getHours() + 3);
    } else if (level === 'hour') {
      // timelineTimeMin = timelineTimeMin.setHours(timelineTimeMin.getHours() - 1);
      // timelineTimeMax = timelineTimeMax.setHours(timelineTimeMax.getHours() + 1);
      timelineTimeMin = timelineTimeMin.setMinutes(timelineTimeMin.getMinutes() - 5);
      timelineTimeMax = timelineTimeMax.setMinutes(timelineTimeMax.getMinutes() + 5);
    } else {
      // 默认为年 => 两端放血3个月时间
      // timelineTimeMin = timelineTimeMin.setFullYear(timelineTimeMin.getFullYear() - 1);
      // timelineTimeMax = timelineTimeMax.setFullYear(timelineTimeMax.getFullYear() + 1);
      timelineTimeMin = timelineTimeMin.setMonth(timelineTimeMin.getMonth() - 3);
      timelineTimeMax = timelineTimeMax.setMonth(timelineTimeMax.getMonth() + 3);
    }
    // 更新 vis timeline 时间轴
    timelineTimeMin = new Date(timelineTimeMin);
    timelineTimeMax = new Date(timelineTimeMax);
    this.myTimeline.setOptions({
      // start: startTime,
      // end: endTime,
      start: timelineTimeMin,
      end: timelineTimeMax,
      min: timelineTimeMin,
      max: timelineTimeMax,
    });
    
    // 渲染页面
    me.setState({
      currentShownOriginalViewNodes: me.nodesOfTimeRangeTop300,
      currentShownOriginalViewNodesTimeRange: [startTime, endTime]
    }, () => {
      // ai 提示信息
      if (!me.messageKey) {
        PB.emit('aiConsole', 'message.push', {
          type: 'ai',
          content: `图谱 "${me.props.viewInfo.name}" 加载【${me.props.filterType[me.props.filterCurKey].text}】数据完成`,
          callback: ({key}) => {
            me.messageKey = key;
          },
        });
      }
      
      // ?
      if (me.drawAllTimeout) {
        clearTimeout(me.drawAllTimeout);
      }
      
      // vis 画图
      me.drawAllTimeout = setTimeout(() => {
        clearTimeout(me.drawAllTimeout);
        me.drawAllTimeout = undefined;
        // drawLoading && drawLoading();
        message.destroy();
        me.setState({refresh: !me.state.refresh});
        
        requestAnimationFrame(() => {
          if (Array.isArray(me.nodesOfTimeRangeTop300)) {
            me.data.replaceWith(me.nodesOfTimeRangeTop300);
            // me.myTimeline.fit({animation: true});
          }
        });
      }, 100)
      
    });
    
  }
  
  componentDidMount() {
    console.log('relation.timeline componentDidMount -> this.props:', this.props);
    // 节点内指定字段时间年份列表
    let year_arr = [];
    // 节点内指定字段时间年份层级数据，用与级联菜单
    let year_tree = {};
    this.props.viewNodeList.forEach((n, idx) => {
      let nodeTime = this.getNodeTimeForTimeLine(n);
      // console.log('relation.timeline componentDidMount -> nodeTime:', nodeTime);
      if (!nodeTime) {
        this.props.viewNodeList[idx]['year'] = null;
        this.props.viewNodeList[idx]['month'] = null;
        this.props.viewNodeList[idx]['date'] = null;
        this.props.viewNodeList[idx]['hour'] = null;
        this.props.viewNodeList[idx]['minute'] = null;
        this.props.viewNodeList[idx]['second'] = null;
        
      } else {
        this.props.viewNodeList[idx]['year'] = nodeTime.getFullYear();
        this.props.viewNodeList[idx]['month'] = nodeTime.getMonth() + 1;
        this.props.viewNodeList[idx]['date'] = nodeTime.getDate();
        this.props.viewNodeList[idx]['hour'] = nodeTime.getHours();
        this.props.viewNodeList[idx]['minute'] = nodeTime.getMinutes();
        this.props.viewNodeList[idx]['second'] = nodeTime.getSeconds();
        
        let year_ = this.props.viewNodeList[idx]['year'];
        let month_ = this.props.viewNodeList[idx]['month'];
        let date_ = this.props.viewNodeList[idx]['date'];
        // let hour_ = this.props.viewNodeList[idx]['hour'];
        // let minute_ = this.props.viewNodeList[idx]['minute'];
        // let second_ = this.props.viewNodeList[idx]['second'];
        
        if (year_arr.indexOf(year_) === -1) {
          year_arr.push(year_);
        }
        
        // 年的统计
        if (year_tree.hasOwnProperty(year_)) {
          year_tree[year_]['total'] += 1;
        } else {
          year_tree[year_] = {
            label: year_ * 1,
            value: year_ * 1,
            total: 1,
            children: [],
            childrenDict: {}
          };
        }
        
        // 节点的时间字段中月份的统计
        if (year_tree.hasOwnProperty(year_)
          && year_tree[year_]['childrenDict'].hasOwnProperty(month_)
        ) {
          year_tree[year_]['childrenDict'][month_]['total'] += 1;
        } else {
          year_tree[year_]['childrenDict'][month_] = {
            label: month_ * 1,
            value: month_ * 1,
            total: 1,
            children: [],
            childrenDict: {}
          };
        }
        
        // 节点的时间字段中日期的统计
        if (year_tree.hasOwnProperty(year_)
          && year_tree[year_]['childrenDict'].hasOwnProperty(month_)
          && year_tree[year_]['childrenDict'][month_]['childrenDict'].hasOwnProperty(date_)
        ) {
          year_tree[year_]['childrenDict'][month_]['childrenDict'][date_]['total'] += 1
        } else {
          year_tree[year_]['childrenDict'][month_]['childrenDict'][date_] = {
            label: date_ * 1,
            value: date_ * 1,
            total: 1,
            // children: [],
            // childrenDict: {}
          };
        }
      }
    });
    
    // 节点的时间 tree 的结果
    Object.keys(year_tree).forEach(y => {
      if (year_tree[y].hasOwnProperty('childrenDict')) {
        // 有月份
        Object.keys(year_tree[y]['childrenDict']).forEach(m => {
          
          if (year_tree[y]['childrenDict'][m].hasOwnProperty('childrenDict')) {
            // 有日期
            year_tree[y]['childrenDict'][m]['children'] = Object.values(year_tree[y]['childrenDict'][m]['childrenDict']);
          } else {
            year_tree[y]['childrenDict'][m]['children'] = [];
          }
          // year_tree[y]['childrenDict'][m]['childrenDict'] = null;
          
        });
        
        year_tree[y]['children'] = Object.values(year_tree[y]['childrenDict']);
        // year_tree[y]['childrenDict'] = null;
      }
    });
    // 去重 排序 生成跨度的完整列表
    year_arr.sort();
    let year_max = Math.max(...year_arr);
    let year_min = Math.min(...year_arr);
    let year_arr_2 = [];
    for (let i = year_min; i <= year_max; i++) {
      year_arr_2.push(i);
    }
    this.yearRangeOfNodesList = year_arr_2;
    this.datetimeTree = Object.values(year_tree);
    // 初始化年份导航
    this.dateTimePagination.years = this.genPaginationData();
    // 初始化导航按钮
    this.resetMonthData();
    this.resetDateData();
    this.resetHourData();
    console.log('relation.timeline componentDidMount -> year_tree:', year_tree);
    console.log('relation.timeline componentDidMount -> this.yearRangeOfNodesList:', this.yearRangeOfNodesList);
    
    // ---------------------------------------------------------
    let me = this;
    this.container = document.getElementById(me.containerId);
    let options = me.defaultOption;
    me.myTimeline = new vis.Timeline(me.container, me.data, options);
    
    me.myTimeline.on('rangechanged', () => {
      me.myTimeline.redraw();
    });
    
    me.myTimeline.on('mouseDown', () => {
      if (me.container && me.container.firstChild) {
        me.container.firstChild.style.cursor = 'move';
      }
    });
    me.myTimeline.on('mouseUp', () => {
      if (me.container) {
        me.container.firstChild.style.cursor = 'auto';
      }
    });
    me.myTimeline.on('click', params => {
      if (me.container) {
        let clickNode = undefined;
        me.state.currentShownOriginalViewNodes.forEach(node => {
          if (node.id === params.item) {
            clickNode = node;
          }
        });
        // console.log(' Relation.timeline -> click -> me.props.viewId :', me.props.viewId);
        console.log(' Relation.timeline -> click -> clickNode :', clickNode);
        
        if (clickNode) {
          let node = clickNode;
          NodeDataProvider.loadNodeDetail(me.props.viewId, parseInt(clickNode.node.type), clickNode.id).then(res => {
            console.log(' Relation.timeline -> click -> loadNodeDetail res:', res);
            clickNode = Object.assign(clickNode, res);
            this.setState({
              selectedNode: clickNode,
              // selectedNodeBoardShow: true,
            });
            // 添加用户操作提示
            PB.emit('aiConsole', 'message.push',
              {type: 'user', content: `查看节点 "${getNodeDisplayTitle(node, 5)}" 的详细信息`});
            // 添加加载节点详情提示
            PB.emit('aiConsole', 'message.push', {
              type: 'ai',
              content: (<span><Icon name={'loading'} style={{marginRight: '0.5em'}}/>请稍后...</span>),
              callback: ({key}) => {
                if (me.detailMessageKeys && me.detailMessageKeys[node.id]) {
                  // 清理上一次显示的信息
                  PB.emit('aiConsole', 'message.update', {
                    key: me.detailMessageKeys[node.id],
                    content: (
                      <span>
                    <a
                      onClick={() => PB.emit('aiConsole', 'message.notice',
                        {key: me.detailMessageKeys[node.id] || key})}
                    >
                      点击查看节点最新信息
                    </a>
                  </span>
                    ),
                  });
                }
                me.detailMessageKeys[node.id] = key;
              },
              delay: 200,
            });
            let loadingStatus = {
              detailLoaded: false,
              detailNode: undefined,
            };
            NodeDataProvider.loadNodeDetail(this.props.viewId, parseInt(node.type), node.id).then(res => {
              // console.log(' Relation.map -> showNodeInfo -> loadNodeDetail res:', res);
              /*<NodeInfoBoard selectedNode={me.state.selectedNode}
														 originalViewId={me.props.viewId}
														 show={!!me.state.selectedNode}
							/>*/
              node = Object.assign(node, res);
              me.setState({
                selectedNode: node,
              });
              loadingStatus.detailLoaded = true;
              loadingStatus.detailNode = node;
              
              PB.emit('aiConsole', 'message.update', {
                key: me.detailMessageKeys[node.id],
                content: (
                  <MainAiConsoleMessageCommonNodeInfo
                    bus={PB}
                    node={node}
                    originalViewId={me.props.viewId}
                    viewDataProvider={me.props.viewDataProvider}
                    viewInfo={me.props.viewInfo}
                    recommendation={[false]}
                  />
                ),
                actions: [
                  //   (
                  //   <Tooltip title={`定位到节点 "${getNodeDisplayTitle(node, 12)}"`} key={'locate'}>
                  //     <Button
                  //       shape={'circle'}
                  //       className={'first ant-btn-icon ant-btn-icon-only'}
                  //       onClick={e => {
                  //         e.preventDefault();
                  //         PB.emit('network', 'focus', node);
                  //       }}
                  //     >
                  //       <Icon name={'icon-location'} type={IconTypes.ICON_FONT}/>
                  //     </Button>
                  //   </Tooltip>
                  // ),
                  (
                    <Tooltip title={'在网上搜索'} key={'search_online'}>
                      <Button
                        shape={'circle'}
                        style={{float: 'right'}}
                        className={'last ant-btn-icon ant-btn-icon-only'}
                        onClick={e => {
                          e.preventDefault();
                          window.open(`${SysConfig.sysSearchEngine}${getNodeDisplayTitle(node)}`, '_blank');
                        }}
                      >
                        <Icon name={'icon-hollow-arrow-right'} type={IconTypes.ICON_FONT}/>
                      </Button>
                    </Tooltip>
                  )],
              });
            }).catch(({code, msg}) => {
              showErrorMessage({code, msg, extra: {viewId: me.state.viewId, isModification: true}});
              PB.emit('console', 'info', '获取节点详细信息失败');
              PB.emit('aiConsole', 'message.update',
                {key: me.detailMessageKeys[node.id], content: `操作失败`});
            });
            
            // 加载自动推荐（目前暂时选用线索联想功能提供数据）
            // overrideNextMessageForNodeDataProvider('static::loadRelatedClue', false);
            me.props.viewDataProvider.smartSearchUserInAllView(node.fname, node.id).then(users => {
              loadingStatus.recommendation = (users || []).slice(0, 3);
              console.log(loadingStatus.recommendation);
              if (loadingStatus.detailLoaded) {
                PB.emit('aiConsole', 'message.patch', {
                  key: me.detailMessageKeys[node.id],
                  content: (
                    <MainAiConsoleMessageCommonNodeInfo
                      bus={PB}
                      node={loadingStatus.detailNode}
                      originalViewId={me.props.viewId}
                      recommendation={loadingStatus.recommendation}
                      viewDataProvider={me.props.viewDataProvider}
                      viewInfo={me.props.viewInfo}
                    />
                  ),
                });
              }
            }).catch(() => {
              loadingStatus.recommendation = [];
              console.log(loadingStatus.recommendation);
              if (loadingStatus.detailLoaded) {
                PB.emit('aiConsole', 'message.patch', {
                  key: me.detailMessageKeys[node.id],
                  content: (
                    <MainAiConsoleMessageCommonNodeInfo
                      bus={PB}
                      node={loadingStatus.detailNode}
                      originalViewId={me.props.viewId}
                      recommendation={loadingStatus.recommendation}
                      viewDataProvider={me.props.viewDataProvider}
                      viewInfo={me.props.viewInfo}
                    />
                  ),
                });
              }
            });
          });
          
        } else {
          this.setState({
            selectedNode: undefined,
            selectedNodeBoardShow: false,
          })
        }
      }
    });
    
    if (me.props.status === 'success') {
      me.processOriginalViewNodes();
      // 初始画图, 显示单位为年
      this.drawNodesInTimeRange(this.dateTimePagination.years.startTime, this.dateTimePagination.years.endTime);
    }
    
    PB.sub(me, 'network', 'node.on_show_extra_info', ({node}) => {
      me.setState({
        extraInfoListModalVisible: true,
        extraInfoListModalNodeId: node.id,
      });
    });
    
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    console.log('relation.timeline componentDidUpdate -> this.props:', this.props);
    let me = this;
    if (me.props.status === 'success' && prevProps.status !== 'success') {
      me.processOriginalViewNodes();
    }
  }
  
  componentWillUnmount() {
    this.myTimeline.off('mouseDown');
    this.myTimeline.off('mouseUp');
    this.myTimeline.off('click');
    PB.remove(this);
  }
  
  render() {
    console.log('relation.timeline render -> this.props:', this.props);
    let me = this;
    
    return (
      <React.Fragment>
        <Layout.Content className={style['content-result-container']}>
          <div className={`${style['content-result-cover']}`}>
            <RelationEmptyResult
              status={me.props.status}
              hasNodes={me.originalViewNodesRangeList !== undefined && me.originalViewNodesRangeList.length > 0}
            />
            <div
              style={{
                display: me.props.status === 'processing' ? 'none' : undefined,
                height: '100%',
                width: '100%',
                position: 'relative',
              }}
            >
              <div className={this.props.className}>
                <div id={this.containerId} style={{width: '100%', height: 'calc(100% - 80px)'}}/>
              </div>
            </div>
          </div>
        </Layout.Content>
        {
          me.originalViewNodesRangeList === undefined ? null : (
            <RelationPagination nodesRangeList={me.originalViewNodesRangeList}
                                onRangeChange={me.onRangeChange}
              // onPaginationClick={me.onPaginationClick}
                                type={'timeline'}
                                viewType={me.props.viewType}
                                filterType={this.props.filterType[this.props.filterCurKey]}
              // datetimeTree={this.datetimeTree}
                                dateTimePagination={this.dateTimePagination}
                                nodesTotalOutOf300={this.nodesTotalOutOf300}
                                nodesTotal={this.props.viewNodeList ? this.props.viewNodeList.length : 0}
                                refresh={me.state.refresh}
            />
          )
        }
        {/*<div className={style["right-drawer"]}>
          <Suspense fallback={null}>
            <NodeInfoBoard selectedNode={me.state.selectedNode}
                           originalViewId={me.props.viewId}
                           show={me.state.selectedNodeBoardShow}
            />
          </Suspense>
        </div>*/}
        {
          me.state.extraInfoListModalNodeId ? (
            <NodeInfoAttachmentListModal nodeId={me.state.extraInfoListModalNodeId}
                                         node={me.state.selectedNode}
                                         editable={false}
                                         networkRef={null}
                                         visible={me.state.extraInfoListModalVisible}
                                         onClose={() => me.setState({
                                           extraInfoListModalVisible: false,
                                           extraInfoListModalNodeId: undefined,
                                         })}
            />
          ) : null
        }
        <NodeFilter viewType={me.props.viewType}
                    switchView={me.props.switchView}
                    filterType={me.props.filterType}
                    filterCurKey={me.props.filterCurKey}
                    resetNodes={me.props.resetNodes}
        />
      </React.Fragment>
    )
  }
}

Timeline.defaultProps = {
  className: undefined,
  viewNodeList: [],
  userList: [],
};

Timeline.propTypes = {
  className: PropTypes.string, // 组件的外部容器的 className
  viewId: PropTypes.string,
  viewType: PropTypes.string,
  viewInfo: PropTypes.object,
  viewNodeList: PropTypes.array,
  userList: PropTypes.array,
  paginationViewNodes: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  switchView: PropTypes.func,
  filterType: PropTypes.object, // 如果用户筛选项列表
  filterCurKey: PropTypes.string, // 当用显示的筛选值
  resetNodes: PropTypes.func, // 切换筛选节点，筛选点还是全部
  viewDataProvider: PropTypes.instanceOf(ViewDataProvider),
};

export default Timeline;
