import React from 'react';
import PropTypes from 'prop-types';
import {Form, Input, Modal, Progress} from "antd";

import checkPwdStrength from '@/utils/PwdStrengthChecker';

const StrengthColors = ['#f5222d', '#faad14', '#52c41a'];

class ChangePwdModal extends React.Component {
  state = {
    confirmDirty: false,
    strengthColor: StrengthColors[0],
  };

  handleConfirmBlur = (e) => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('password')) {
      callback('两次密码输入不一致！');
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  };

  shouldComponentUpdate(nextProps) {
    if (this.props.visible === false && nextProps.visible === true) {
      const me = this;
      this.setState(() => {
        requestAnimationFrame(() => {
          const {form} = me.props;
          form.resetFields();
        });
        return {confirmDirty: false};
      });
    }
    return true;
  }

  render() {
    let me = this;
    const {visible, onCancel, onSave, form} = this.props;
    const {getFieldDecorator} = form;
    const formItemLayout = {
      labelCol: {
        xs: {span: 8},
        sm: {span: 4},
      },
      wrapperCol: {
        xs: {span: 36},
        sm: {span: 18},
      },
    };
    // noinspection RequiredAttributes
    return (
      <Modal
        closable={!this.props.processing}
        visible={visible}
        title={me.props.hasPassword ? '修改密码' : '设置密码'}
        okText='保存密码'
        cancelText='取消'
        onCancel={onCancel}
        onOk={onSave}
        okButtonProps={{loading: this.props.processing}}
        cancelButtonProps={{disabled: this.props.processing}}
        destroyOnClose={true}
        centered={true}
      >
        <Form>
          {
            me.props.hasPassword ? (
              <Form.Item
                {...formItemLayout}
                label="原密码"
              >
                {getFieldDecorator('oldPassword', {
                  rules: [{
                    required: true, message: '请输入原密码',
                  }],
                })(
                  <Input type="password" autoFocus={true}/>
                )}
              </Form.Item>
            ) : null
          }
          <Form.Item
            {...formItemLayout}
            label="新密码"
          >
            {getFieldDecorator('password', {
              rules: [{
                required: true, message: '请输入新密码',
              }, {
                validator: this.validateToNextPassword,
              }],
            })(
              <div>
                <Input type="password" />
                <Progress percent={checkPwdStrength(this.props.form.getFieldValue('password'))}
                          strokeColor={me.state.strengthColor}
                          format={percent => {
                            if (percent < 35) {
                              if (me.state.strengthColor !== StrengthColors[0]) {
                                requestAnimationFrame(() => me.setState({strengthColor: StrengthColors[0]}));
                              }
                              return <span className={'error-text'}>较弱</span>
                            } else if (percent < 70) {
                              if (me.state.strengthColor !== StrengthColors[1]) {
                                requestAnimationFrame(() => me.setState({strengthColor: StrengthColors[1]}));
                              }
                              return <span className={'warning-text'}>中等</span>
                            } else {
                              if (me.state.strengthColor !== StrengthColors[2]) {
                                requestAnimationFrame(() => me.setState({strengthColor: StrengthColors[2]}));
                              }
                              return <span className={'success-text'}>较强</span>
                            }
                          }}/>
              </div>
            )}
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            label="密码确认"
          >
            {getFieldDecorator('confirm', {
              rules: [{
                required: true, message: '请再输入一次新密码',
              }, {
                validator: this.compareToFirstPassword,
              }],
            })(
              <Input type="password" onBlur={this.handleConfirmBlur} />
            )}
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

const WrappedChangePwdModal = Form.create()(ChangePwdModal);

WrappedChangePwdModal.defaultProps = {
  visible: false,
  processing: false,
  hasPassword: true,
};

WrappedChangePwdModal.propTypes = {
  visible: PropTypes.bool,
  processing: PropTypes.bool,
  hasPassword: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default WrappedChangePwdModal;
