import React from 'react';
import PropTypes from 'prop-types';
import LightBox from "react-image-lightbox";

import PB, {SimplePB} from "@/libs/simplePB";

import {getToken, REQUEST_BASE} from "@/utils/HttpUtil";

class ImageLightBox extends React.PureComponent {
  state = {
    images: [],
    currentImageIdx: -1,
  };

  componentDidMount() {
    let me = this;

    me.props.bus.sub(me, 'image_light_box', 'show.do', ({images, idx}) => {
      images = images || me.state.images;
      if (idx < 0 || idx >= images.length) idx = -1;
      me.setState({images, currentImageIdx: idx});
    });

    me.props.bus.sub(me, 'image_light_box', 'hide.do', () => {
      me.setState({currentImageIdx: -1})
    });
  }

  componentWillUnmount() {
    this.props.bus.remove(this);
  }

  render() {
    let me = this, lightBox = <span style={{display: 'none'}} />;

    if (me.state.images && me.state.images.length > 0 && me.state.currentImageIdx >= 0 &&
      me.state.currentImageIdx < me.state.images.length) {

      const mainSrc =
        `${REQUEST_BASE}/view/project/file/${me.state.images[me.state.currentImageIdx]['fileId']}?Authorization=${getToken()}`;
      if (mainSrc) {
        const nextSrcIdx = (me.state.currentImageIdx + 1) % me.state.images.length;
        const nextSrc = `${REQUEST_BASE}/view/project/file/${me.state.images[nextSrcIdx]['fileId']}?Authorization=${getToken()}`;
        const prevSrcIdx = (me.state.images.length + me.state.currentImageIdx - 1) % me.state.images.length;
        const prevSrc = `${REQUEST_BASE}/view/project/file/${me.state.images[prevSrcIdx]['fileId']}?Authorization=${getToken()}`;
        lightBox = (
          <LightBox
            mainSrc={mainSrc}
            prevSrc={me.state.images.length === 1 ? null : prevSrc}
            nextSrc={me.state.images.length === 1 ? null : nextSrc}
            onCloseRequest={() => me.setState({currentImageIdx: -1})}
            onMovePrevRequest={() => me.setState({currentImageIdx: prevSrcIdx})}
            onMoveNextRequest={() => me.setState({currentImageIdx: nextSrcIdx})}
          />
        );
      }
    }

    return lightBox;
  }
}

ImageLightBox.defaultProps = {
  bus: PB,
};

ImageLightBox.propTypes = {
  bus: PropTypes.instanceOf(SimplePB),
};

export default ImageLightBox;