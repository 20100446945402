import React from 'react';
import PropTypes from 'prop-types';
import {Modal, Tree, Tooltip, Button, Checkbox, message} from 'antd';
import {Element as ScrollElement} from 'react-scroll';

import {NodeEvents} from '@/libs/view/network/events';
import PB, {SimplePB} from '@/libs/simplePB';

import {getNodeDisplayTitle, getNodeIcon, NODE_TYPE_TEXT} from "@/constants/vis.defaultDefine.1";

import Icon from "@/components/common/common.icon";
import ViewDataProvider from "@/components/common/dataProvider/common.dataProvider.view";
import {showErrorMessage} from "@/components/common/common.message";
import {scrollToEnd} from '@/components/common/common.functions';

import style from "@/style/common/view/common.view.explore.less";

class ExploreCompanyOverallModal extends React.Component {
  state = {
    containerId: `explore-company-overall-modal-${Math.random()}`,

    loadingResult: false,
    hasMoreResult: true,
    lastLoadingFailed: false,
    currentPos: -1,
    autoLoadMore: true,
    resultNodeMap: {},

    dataTree: [],
    dataMap: {},
    availableNodeAmount: 0,
    checkedKeys: [],
    expandedKeys: [],

    savingStatus: 'idle',
    graphToSave: {
      nodeMap: {},
      nodeAmount: 0,
      edgeMap: {},
    },
    savedNodeMap: {},
    removedNodeMap: {},
  };

  resultData = {nodes: [], edges: []};

  idReplacementMap = {};

  idReplacementRevertMap = {};

  autoStart = true;

  autoScroll = true; // 自动滚动至最下方

  onClose = () => {
    let me = this;

    if (me.state.autoLoadMore && me.state.hasMoreResult) {
      message.info('系统持续发现中，您可随时通过右侧按钮查看发现进度');
    }

    me.props.onClose();
  };

  onResultDataLoaded = ({nodes, edges, total, viewId}) => {
    let me = this;

    if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId) {
      const {resultNodeMap, dataTree, dataMap, newKeys, availableNodeAmount} = me.parseResult(nodes, edges);
      me.resultData.nodes.push.apply(me.resultData.nodes, nodes);
      me.resultData.edges.push.apply(me.resultData.edges, edges);
      me.setState({
        loadingResult: false,
        hasMoreResult: (me.state.currentPos + 10) < total,
        lastLoadingFailed: false,
        availableNodeAmount,
        expandedKeys: [...me.state.expandedKeys, ...newKeys],
        resultNodeMap,
        dataTree,
        dataMap,
      }, () => {
        setTimeout(() => scrollToEnd(me), 300);
      });
    }
  };

  onResultDataFailedToLoad = ({code, msg, viewId}) => {
    let me = this;

    if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId) {
      me.setState({
        loadingResult: false,
        lastLoadingFailed: true,
      }, () => {
        showErrorMessage({code, msg, extra: {viewId: me.props.viewDataProvider.viewId, isModification: false}});
      });
    }
  };

  onResultDataStartToLoad = ({start, viewId, autoLoadMore}) => {
    let me = this;

    if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId) {
      me.setState({
        loadingResult: true,
        lastLoadingFailed: false,
        currentPos: start,
        autoLoadMore,
      });
    }
  };

  onResultDataRefreshed = ({resultData: {nodes, edges}, idReplacementMap, idReplacementRevertMap, viewId}) => {
    let me = this;

    if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId) {
      me.setState({
        resultNodeMap: {},
        dataTree: [],
        dataMap: {},
      }, () => {
        const {resultNodeMap, dataTree, dataMap, newKeys, availableNodeAmount} = me.parseResult(nodes, edges);
        me.resultData = {nodes: [], edges: []};
        me.resultData.nodes.push.apply(me.resultData.nodes, nodes);
        me.resultData.edges.push.apply(me.resultData.edges, edges);
        me.idReplacementMap = idReplacementMap;
        me.idReplacementRevertMap = idReplacementRevertMap;
        me.setState({
          availableNodeAmount,
          expandedKeys: [...newKeys],
          resultNodeMap,
          dataTree,
          dataMap,
        }, () => {
          setTimeout(() => scrollToEnd(me), 300);
        });
      });
    }
  };

  onItemCheck = checkedKeys => {
    let me = this, graphToSave = {
      nodeMap: {},
      nodeAmount: 0,
      edgeMap: {},
    };

    checkedKeys.forEach(checkedKey => {
      let nodeIdList = checkedKey.split('\/') || [];
      nodeIdList.forEach((nodeId, idx) => {
        if (me.state.resultNodeMap[nodeId]) {
          graphToSave.nodeMap[nodeId] = me.state.resultNodeMap[nodeId];
          if (idx === 0) {
            // 根
            let treeItem = me.state.dataMap[nodeId];
            if (treeItem.relatedNodeIds && treeItem.relatedNodeIds.length > 0) {
              graphToSave.edgeMap[nodeId] = [...treeItem.relatedNodeIds];
            }
          }
          if (idx > 0) {
            // 分支及叶子
            graphToSave.edgeMap[nodeId] = graphToSave.edgeMap[nodeId] || [];
            graphToSave.edgeMap[nodeId].push(nodeIdList[idx - 1]);
          }
        }
      })
    });
    graphToSave.nodeAmount = Object.keys(graphToSave.nodeMap)
      .filter(nodeId => !me.state.savedNodeMap[nodeId]).length;

    me.setState({checkedKeys, graphToSave});
  };

  onRetry = () => {
    let me = this;

    if (me.props.viewDataProvider) {
      me.props.bus.emit('view', 'explore.explore_company.overall.on_load_more',
        {viewId: me.props.viewDataProvider.viewId, autoLoadMore: me.state.autoLoadMore});
    }
  };

  onRestart = () => {
    let me = this, callback = () => {
      me.autoStart = true;

      if (me.props.viewDataProvider) {
        me.props.bus.emit('view', 'explore.explore_company.overall.clear_result',
          {viewId: me.props.viewDataProvider.viewId, autoLoadMore: me.state.autoLoadMore});
      }
    }
    if (!me.state.autoLoadMore) {
      me.setState({autoLoadMore: true}, callback);
    } else {
      callback();
    }
  };

  onResultCleared = ({viewId}) => {
    let me = this;

    if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId && me.props.visible) {
      me.setState({
        currentPos: -1,

        availableNodeAmount: 0,
        checkedKeys: [],

        savingStatus: 'idle',
        graphToSave: {
          nodeMap: {},
          nodeAmount: 0,
          edgeMap: {},
        },
        savedNodeMap: {},
        removedNodeMap: {},
      }, () => {
        me.autoScroll = true;

        me.props.bus.emit('view', 'explore.explore_company.overall.broadcast_status',
          {viewId: me.props.viewDataProvider.viewId});
      })
    }
  };

  onSaveGraphToView = () => {
    let me = this, nodes = [], edges = [];

    Object.keys(me.state.graphToSave.nodeMap).forEach(nodeId => {
      if (me.state.savedNodeMap[nodeId]) {
        return;
      }
      let node = me.state.graphToSave.nodeMap[nodeId];
      nodes.push({
        ...node,
        type: NODE_TYPE_TEXT,
        'delete': 0,
        userConfirmed: true,
        status: 1,
        userId: me.props.userId,
        userPreferredType: node.aiPreferredType,
        forceAdd: true,
        replaceNodeId: true,
        meta: {
          ...(node.meta || {}),
          status: 1,
          scale: 1,
        },
      });
    });

    let nodeIdxMap = {};
    nodes.forEach((node, idx) => {
      nodeIdxMap[node.id] = idx;
    });

    nodes.forEach((node, idx) => {
      if (me.state.graphToSave.edgeMap[node.id]) {
        me.state.graphToSave.edgeMap[node.id].forEach(from => {
          let fromNodeId = from, fromIndex = -1;
          if (nodeIdxMap[from] >= 0) {
            fromIndex = nodeIdxMap[from];
          } else if (me.idReplacementMap[from]) {
            fromNodeId = me.idReplacementMap[from];
          }
          if (fromIndex === -1 && !me.props.viewDataProvider.getNode(fromNodeId)) {
            return;
          }
          edges.push({
            from: fromIndex >= 0 ? undefined : fromNodeId,
            fromIndex: fromIndex >= 0 ? fromIndex : undefined,
            toIndex: idx,
            userConfirmed: false,
            meta: {recommend: 1, ai: 1},
          });
        });
      }
    });

    me.setState({savingStatus: 'processing'}, () => {
      me.props.viewDataProvider.saveRelationGraph(nodes, edges, false, false,
        'recommend_company_by_view', false)
        .then(() => {
          me.setState({
            availableNodeAmount: Object.keys(me.state.dataMap).filter(key =>
              me.state.dataMap[key] && (
                (
                  (!me.state.savedNodeMap[me.state.dataMap[key].nodeId])
                  && (!me.state.dataMap[key].children || me.state.dataMap[key].children.length <= 0)
                ) || (me.state.dataMap[key].children && me.state.dataMap[key].children.length > 0)
              )
            ).length,
            checkedKeys: [],
            savingStatus: 'idle',
            graphToSave: {
              nodeMap: {},
              nodeAmount: 0,
              edgeMap: {},
            },
          }, () => {
            message.success(`成功加入 ${nodes.length} 个节点`)
          });
        })
        .catch(({code, msg}) => {
          showErrorMessage({code, msg});
          me.setState({savingStatus: 'idle'});
        });
    });
  };

  onCheckAll = () => {
    let me = this, dataMap = me.state.dataMap;

    // noinspection DuplicatedCode
    me.onItemCheck(
      me.state.checkedKeys.length < me.state.availableNodeAmount ? (
        Object.keys(dataMap).filter(key =>
          dataMap[key] && (
            (
              (!me.state.savedNodeMap[dataMap[key].nodeId])
              && (!dataMap[key].children || dataMap[key].children.length <= 0)
            ) || (dataMap[key].children && dataMap[key].children.length > 0)
          )
        )
      ) : []
    );
  };

  onStatusRefreshed = ({currentPos, hasMoreResult, loadingResult, lastLoadingFailed, autoLoadMore, viewId}) => {
    let me = this;

    if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId && me.props.visible) {
      let requestResultData = (me.state.currentPos !== currentPos) || (me.state.loadingResult !== loadingResult);
      me.setState({
        loadingResult,
        hasMoreResult,
        lastLoadingFailed,
        currentPos,
        autoLoadMore,
      }, () => {
        if (requestResultData) {
          me.props.bus.emit('view', 'explore.explore_company.overall.broadcast_data', {viewId});
        }
        if (!autoLoadMore && me.props.visible && me.autoStart) {
          me.autoStart = false;
          me.props.bus.emit('view', 'explore.explore_company.overall.on_load_more', {viewId});
        }
      });
    }
  };

  onStop = () => {
    let me = this, callback = () => {
      if (me.props.viewDataProvider) {
        me.props.bus.emit('view', 'explore.explore_company.overall.stop_auto_load',
          {viewId: me.props.viewDataProvider.viewId});
      }
    }
    if (me.state.autoLoadMore) {
      me.setState({autoLoadMore: false}, callback);
    } else {
      callback();
    }
  };

  parseResult = (nodes, edges) => {
    let me = this, resultNodeMap = me.state.resultNodeMap, dataTree = me.state.dataTree, dataMap = me.state.dataMap,
      edgeProcessingMap = {}, rootItemMap = {}, nextNodeIdsToHandle = {}, treeItemMap = {}, nodeIdsForCurrentRound,
      newTreeKeys = [];

    nodes.forEach(node => {
      if (!resultNodeMap[node.id]) {
        resultNodeMap[node.id] = node;
      }
    });

    edges.forEach(edge => {
      edgeProcessingMap[edge['from']] = (edgeProcessingMap[edge['from']] || {});
      edgeProcessingMap[edge['from']][edge['to']] = false;
      edgeProcessingMap[edge['to']] = (edgeProcessingMap[edge['to']] || {});
      edgeProcessingMap[edge['to']][edge['from']] = false;
    });

    let relatedNodeIds = Object.keys(edgeProcessingMap);

    relatedNodeIds.forEach(nodeId => {
      if (!resultNodeMap[nodeId]) {
        if (me.props.viewDataProvider.getNode(nodeId)) {
          // 找对应根节点
          let connectedNodeIdMap = edgeProcessingMap[nodeId], connectedNodeIds = Object.keys(connectedNodeIdMap);
          connectedNodeIds.forEach(connectedNodeId => {
            if (connectedNodeIdMap[connectedNodeId] === false) {
              edgeProcessingMap[nodeId][connectedNodeId] = true;
              if (edgeProcessingMap[connectedNodeId] && edgeProcessingMap[connectedNodeId][nodeId] === false) {
                edgeProcessingMap[connectedNodeId][nodeId] = true;
              }
              if (resultNodeMap[connectedNodeId]) {
                rootItemMap[connectedNodeId] = rootItemMap[connectedNodeId] || {};
                rootItemMap[connectedNodeId][nodeId] = true;
                nextNodeIdsToHandle[connectedNodeId] = true;
              }
            }
          });
        } else {
          // 无效数据
        }
      }
    });

    // 处理根节点
    nodeIdsForCurrentRound = Object.keys(nextNodeIdsToHandle);
    nextNodeIdsToHandle = {};
    nodeIdsForCurrentRound.forEach(nodeId => {
      newTreeKeys.push(nodeId);
      treeItemMap[nodeId] = {
        id: nodeId,
        nodeId,
        relatedNodeIds: Object.keys(rootItemMap[nodeId]),
      };
      dataMap[treeItemMap[nodeId].id] = treeItemMap[nodeId];

      let connectedNodeIdMap = edgeProcessingMap[nodeId], connectedNodeIds = Object.keys(connectedNodeIdMap);
      connectedNodeIds.forEach(connectedNodeId => {
        if (connectedNodeIdMap[connectedNodeId] === false) {
          edgeProcessingMap[nodeId][connectedNodeId] = true;
          if (edgeProcessingMap[connectedNodeId] && edgeProcessingMap[connectedNodeId][nodeId] === false) {
            edgeProcessingMap[connectedNodeId][nodeId] = true;
          }
          if (resultNodeMap[connectedNodeId] && !treeItemMap[connectedNodeId]) {
            nextNodeIdsToHandle[connectedNodeId] = true;
            newTreeKeys.push(connectedNodeId);
            treeItemMap[connectedNodeId] = {
              id: `${nodeId}/${connectedNodeId}`,
              nodeId: connectedNodeId,
            };
            dataMap[treeItemMap[connectedNodeId].id] = treeItemMap[connectedNodeId];
            treeItemMap[nodeId].children = treeItemMap[nodeId].children || [];
            treeItemMap[nodeId].children.push(treeItemMap[connectedNodeId]);
          }
        }
      });
    });

    // 处理剩余节点
    nodeIdsForCurrentRound = Object.keys(nextNodeIdsToHandle);
    nextNodeIdsToHandle = {};
    while (nodeIdsForCurrentRound.length > 0) {
      nodeIdsForCurrentRound.forEach(nodeId => {
        if (!treeItemMap[nodeId]) return;
        let connectedNodeIdMap = edgeProcessingMap[nodeId], connectedNodeIds = Object.keys(connectedNodeIdMap);
        connectedNodeIds.forEach(connectedNodeId => {
          if (connectedNodeIdMap[connectedNodeId] === false) {
            edgeProcessingMap[nodeId][connectedNodeId] = true;
            if (edgeProcessingMap[connectedNodeId] && edgeProcessingMap[connectedNodeId][nodeId] === false) {
              edgeProcessingMap[connectedNodeId][nodeId] = true;
            }
            if (resultNodeMap[connectedNodeId] && !treeItemMap[connectedNodeId]) {
              nextNodeIdsToHandle[connectedNodeId] = true;
              newTreeKeys.push(connectedNodeId);
              treeItemMap[connectedNodeId] = {
                id: `${treeItemMap[nodeId].id}/${connectedNodeId}`,
                nodeId: connectedNodeId,
              };
              dataMap[treeItemMap[connectedNodeId].id] = treeItemMap[connectedNodeId];
              treeItemMap[nodeId].children = treeItemMap[nodeId].children || [];
              treeItemMap[nodeId].children.push(treeItemMap[connectedNodeId]);
            }
          }
        });
      });

      nodeIdsForCurrentRound = Object.keys(nextNodeIdsToHandle);
      nextNodeIdsToHandle = {};
    }

    Object.keys(rootItemMap).forEach(rootItemId => {
      dataTree.push(treeItemMap[rootItemId]);
    });

    // noinspection DuplicatedCode
    let availableNodeAmount = Object.keys(dataMap).filter(key => dataMap[key] && ((
      (!me.state.savedNodeMap[dataMap[key].nodeId]) && (!dataMap[key].children || dataMap[key].children.length <= 0)
    ) || (dataMap[key].children && dataMap[key].children.length > 0))).length;

    return {resultNodeMap, dataTree, dataMap,
      newKeys: newTreeKeys.filter(key =>
        treeItemMap[key] && treeItemMap[key].children && treeItemMap[key].children.length > 0),
      availableNodeAmount};
  };

  renderTreeNodes = items => {
    let me = this;

    return items.map(item => {
      let node = me.state.resultNodeMap[item.nodeId] || me.props.viewDataProvider.getNode(item.nodeId);

      return node ? (
        <Tree.TreeNode
          title={me.getTreeNodeTitle(item)}
          key={item.id}
          dataRef={item}
          disableCheckbox={!!(me.state.savedNodeMap[item.nodeId]) && (!item.children || item.children <= 0)}
          switcherIcon={item.children && item.children.length > 0 ? null :
            (me.state.removedNodeMap[item.nodeId] ? <Icon name={'stop'}/> :
              (me.state.savedNodeMap[item.nodeId] ? <Icon name={'check-circle'}/> :
                <Icon {...getNodeIcon(node)} color={undefined} style={{opacity: 0.7}}/>))}
        >
          {item.children && item.children.length > 0 ? me.renderTreeNodes(item.children) : undefined}
        </Tree.TreeNode>
      ) : undefined;
    });
  }

  getTreeNodeTitle = item => {
    let me = this, node = me.state.resultNodeMap[item.nodeId] || me.props.viewDataProvider.getNode(item.nodeId);

    if (!node) return '';

    let displayTitle = getNodeDisplayTitle(node);

    if (item.relatedNodeIds) {
      let relatedToNodes = (me.props.viewDataProvider.getNode(item.relatedNodeIds) || []).filter(n => !!n);
      if (relatedToNodes && relatedToNodes.length > 0) {
        displayTitle = `${displayTitle}（相关节点：${getNodeDisplayTitle(relatedToNodes[0], 8)}`;
        if (relatedToNodes.length > 1) {
          displayTitle = `${displayTitle}、${getNodeDisplayTitle(relatedToNodes[1], 8)}`;
          if (relatedToNodes.length > 2) {
            displayTitle = `${displayTitle} 等 ${relatedToNodes.length} 个`
          }
        }
        displayTitle = `${displayTitle}）`;
      }
    }

    return node.description ? (
      <Tooltip
        key={`tooltip/${item.id}/`}
        placement={'left'}
        align={{offset: [-24, 0]}}
        title={(
          <span>
            {
              node.description.split('\n').map((line, idx) => (
                <span key={`ln-${idx}`}>{line}<br /></span>
              ))
            }
          </span>
        )}
        overlayClassName={'light-theme'}
        mouseLeaveDelay={0.05}
      >
        <span style={{width: '100%'}}>{displayTitle}</span>
      </Tooltip>
    ) : (
      <span>{displayTitle}</span>
    );
  };

  componentDidMount() {
    let me = this;

    me.props.bus.with(me).subscribe(
      'view',
      'explore.explore_company.overall.current_status',
      me.onStatusRefreshed
    ).subscribe(
      'view',
      'explore.explore_company.overall.current_data',
      me.onResultDataRefreshed
    ).subscribe('view', 'explore.explore_company.overall.stop_auto_load', ({viewId}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId && me.state.autoLoadMore) {
        me.setState({autoLoadMore: false});
      }
    }).subscribe(
      'view',
      'explore.explore_company.overall.do_load_more',
      me.onResultDataStartToLoad
    ).subscribe(
      'view',
      'explore.explore_company.overall.data_loaded',
      me.onResultDataLoaded
    ).subscribe(
      'view',
      'explore.explore_company.overall.failed_to_load',
      me.onResultDataFailedToLoad
    ).subscribe(
      'view',
      'explore.explore_company.overall.result_cleared',
      me.onResultCleared
    );

    me.props.viewDataProvider.with(me).subscribe(NodeEvents.ADDED, (type, addedNodeIds, addedNodes) => {
      let savedNodeMap = me.state.savedNodeMap, removedNodeMap = me.state.removedNodeMap, dataChanged = false;
      addedNodeIds.forEach((nodeId, idx) => {
        let originalNodeId = nodeId;
        if (me.idReplacementRevertMap[originalNodeId]) {
          originalNodeId = me.idReplacementRevertMap[originalNodeId];
        }
        if (me.state.resultNodeMap[originalNodeId]) {
          if (!savedNodeMap[originalNodeId]) {
            dataChanged = true;
          }
          if (removedNodeMap[originalNodeId]) {
            dataChanged = true;
            delete removedNodeMap[originalNodeId];
          }
          savedNodeMap[originalNodeId] = addedNodes[idx];
        }
      });
      if (dataChanged) {
        let graphToSave = me.state.graphToSave;
        graphToSave.nodeAmount = Object.keys(graphToSave.nodeMap)
          .filter(nodeId => !savedNodeMap[nodeId]).length;
        me.setState({savedNodeMap, removedNodeMap, graphToSave});
      }
    }).subscribe(NodeEvents.REMOVED, (type, nodeInfoList) => {
      let savedNodeMap = me.state.savedNodeMap, removedNodeMap = me.state.removedNodeMap, dataChanged = false;
      nodeInfoList.forEach(node => {
        let originalNodeId = node.id;
        if (me.idReplacementRevertMap[originalNodeId]) {
          originalNodeId = me.idReplacementRevertMap[originalNodeId];
        }
        if (me.state.resultNodeMap[originalNodeId] && savedNodeMap[originalNodeId]) {
          if (!removedNodeMap[originalNodeId]) {
            dataChanged = true;
            removedNodeMap[originalNodeId] = true;
          }
        }
      });
      if (dataChanged) {
        me.setState({removedNodeMap});
      }
    }).subscribe(NodeEvents.ID_REPLACED, (nodeIdReplacement) => {
      let originalNodeIds = Object.keys(nodeIdReplacement);
      originalNodeIds.forEach(originalNodeId => {
        if (me.state.resultNodeMap[originalNodeId]) {
          me.idReplacementMap[originalNodeId] = nodeIdReplacement[originalNodeId];
          me.idReplacementRevertMap[nodeIdReplacement[originalNodeId]] = originalNodeId;
        }
      });
    });

    if (me.props.visible) {
      me.props.bus.emit('view', 'explore.explore_company.overall.modal_visible_changed',
        {viewId: me.props.viewDataProvider.viewId, visible: me.props.visible});
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let me = this;

    if ((!prevProps.visible || !prevProps.viewDataProvider) && me.props.visible && me.props.viewDataProvider) {
      me.props.bus.emit('view', 'explore.explore_company.overall.broadcast_status',
        {viewId: me.props.viewDataProvider.viewId});
    }

    if (prevProps.visible !== me.props.visible) {
      me.props.bus.emit('view', 'explore.explore_company.overall.modal_visible_changed',
        {viewId: me.props.viewDataProvider.viewId, visible: me.props.visible});
    }
  }

  componentWillUnmount() {
    this.props.bus.remove(this);
    this.props.viewDataProvider.unSubscribe(this);
  }

  render() {
    let me = this;

    return (
      <Modal
        title={`全局企业发现`}
        visible={me.props.visible}
        closable={me.state.savingStatus !== 'processing'}
        width={`calc(40rem + 48px)`}
        bodyStyle={{height: '50vh'}}
        centered={true}
        onCancel={me.onClose}
        footer={[
          (!me.state.loadingResult && me.state.lastLoadingFailed && me.state.autoLoadMore) ? (
            <Button key={'retry'} style={{float: 'left'}} onClick={me.onRetry}>重试</Button>
          ) : undefined,
          (!me.state.loadingResult && !me.state.autoLoadMore) ? (
            <Button key={'restart'} style={{float: 'left'}} onClick={me.onRestart}>重新启动</Button>
          ) : undefined,
          me.state.loadingResult ? (
            <Button
              key={'stop'}
              style={{float: 'left'}}
              disabled={!me.state.autoLoadMore}
              onClick={me.onStop}
            >
              {me.state.autoLoadMore ? '停止' : '停止中...'}
            </Button>
          ) : undefined,
          (
            <Checkbox
              key={'select-all'}
              onClick={me.onCheckAll}
              disabled={me.state.availableNodeAmount <= 0}
              checked={me.state.checkedKeys.length > 0 && me.state.checkedKeys.length === me.state.availableNodeAmount}
            >
              全选
            </Checkbox>
          ),
          (<Button key={'close'} onClick={me.onClose}>关闭</Button>),
          (
            <Button
              key={'save'}
              onClick={me.onSaveGraphToView}
              style={{minWidth: 'calc(7rem + 32px)'}}
              disabled={me.state.graphToSave.nodeAmount === 0}
              loading={me.state.savingStatus === 'processing'}
              type={'primary'}
            >
              {`加入图谱${me.state.graphToSave.nodeAmount > 0 ? (me.state.graphToSave.nodeAmount > 99 ? ' (99+)' : ` (${me.state.graphToSave.nodeAmount})`) : ''}`}
            </Button>
          ),
        ]}
      >
        <ScrollElement
          id={me.state.containerId}
          className={`scrollbar-none`}
          style={{overflow: 'hidden auto', height: '100%'}}
          onScroll={e => {
            let element = e.target;
            me.autoScroll = element.scrollHeight - element.scrollTop - element.clientHeight < 10;
          }}
        >
          <Tree
            className={`scrollbar-none`}
            selectable={false}
            checkable={true}
            expandedKeys={me.state.expandedKeys}
            onExpand={expandedKeys => me.setState({expandedKeys})}
            checkedKeys={me.state.checkedKeys}
            onCheck={me.onItemCheck}
            switcherIcon={<Icon name="down" />}
          >
            {me.renderTreeNodes(me.state.dataTree)}
          </Tree>
          {
            (
              (me.state.currentPos === -1 && me.state.autoLoadMore) || me.state.loadingResult // 加载尚未启动或正在加载中
            ) ? (
              <div
                className={style[me.state.dataTree.length <= 0 ? 'infinite-scroll-loading-empty' : 'infinite-scroll-loading']}
                key={0}
              >
                <Icon name="loading" style={{marginRight: '0.5em'}} /> 计算中，请稍后...
              </div>
            ) : (
              (!me.state.loadingResult && me.state.lastLoadingFailed) ? (
                <div
                  className={style[me.state.dataTree.length <= 0 ? 'infinite-scroll-loading-empty' : 'infinite-scroll-loading']}
                  key={0}
                >
                  <Icon name={'exclamation-circle'} theme={'outlined'} style={{marginRight: '0.5em'}} />
                  数据计算失败，<a onClick={() => me.onRetry()}>点击重试</a>
                </div>
              ) : (
                me.state.dataTree.length <= 0 ? (
                  <div
                    className={style['infinite-scroll-loading-empty']}
                    key={0}
                  >
                    <Icon name={'exclamation-circle'} theme={'outlined'} style={{marginRight: '0.5em'}} />
                    没有找到相关数据
                  </div>
                ) : undefined
              )
            )
          }
        </ScrollElement>
      </Modal>
    );
  }
}

ExploreCompanyOverallModal.defaultProps = {
  bus: PB,
};

ExploreCompanyOverallModal.propTypes = {
  viewDataProvider: PropTypes.instanceOf(ViewDataProvider).isRequired,
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired,
  bus: PropTypes.instanceOf(SimplePB),
};

export default ExploreCompanyOverallModal;