import React from 'react';
import ClampLines from 'react-clamp-lines';

const defaultRenderFn = (text, extraElement) => {
  const lines = text.split('\n');
  return (
    <span>{
      lines.map((line, idx) => (
        <span key={`ln-${idx}`}><pre>{line}{idx === lines.length - 1 ? null : (<br />)}</pre></span>
      ))
    }{extraElement ? extraElement : null}</span>
  );
};

export default class MyClampLines extends ClampLines {
  renderFn = defaultRenderFn;
  processing = true;
  contentWidth = 0;
  lastContentWidth = -1;
  lastWidth = -1;
  lastMiddle = 0;
  firstTime = true;
  forceUpdateText = false;

  constructor(props) {
    super(props);

    this.renderFn = props.renderFn || defaultRenderFn;
    this.start = 0;
    this.middle = 0;
    this.end = this.original.length;
    this.processing = true;

    if (!this.ssr) {
      // noinspection JSUnusedGlobalSymbols
      this.debounced = this.debounce(this.action, props.delay);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    super.componentDidUpdate(prevProps, prevState, snapshot);

    let me = this;
    let maxHeight = me.lineHeight * me.props.lines + 1;
    // console.log('maxHeight: ', maxHeight);

    if (prevProps.text !== me.props.text || me.forceUpdateText) {
      // console.log(`componentDidUpdate - prevProps.text !== me.props.text: ${prevProps.text !== me.props.text}, forceUpdateText: ${me.forceUpdateText}`);
      me.processing = true;
      me.contentWidth = 0;
      me.lastContentWidth = -1;
      me.lastWidth = -1;
      me.lastMiddle = 0;
      me.original = me.props.text;
      me.start = 0;
      me.middle = Math.floor(me.original.length / 2);
      me.end = me.original.length;
      me.firstTime = prevProps.text !== me.props.text;
      me.forceUpdateText = false;
    }

    if (me.processing && (me.start <= me.end)) {
      // setTimeout(() => {
      requestAnimationFrame(() => {
        if (!me.processing) return;
        try {
          me.moveMarkers(maxHeight);
          me.clampLines();
        } catch (e) {
          console.info(e)
        }
      });
      // }, 5000);
    }

    me.renderFn = me.props.renderFn || defaultRenderFn;
  }

  moveMarkers(maxHeight) {
    let me = this;

    if (me.element.clientHeight <= maxHeight) {
      me.start = (me.start === me.middle) ? me.middle + 1 : me.middle;
    } else {
      me.end = me.middle - 1;
    }
  }

  action = () => {
    let me = this;

    if (me.watch) {
      me.setState({
        noClamp: false,
      });
      if (!me.element) return;
      if (me.contentWidth === me.element.clientWidth) return;
      me.clampLines();
    }
  };

  clampLines = () => {
    if (!this.element) return;

    let me = this;

    if (me.contentWidth > 0 && me.lastContentWidth < me.contentWidth) {
      me.lastContentWidth = me.contentWidth;
    }
    me.contentWidth = me.element.clientWidth;

    if (me.lastWidth !== -1 && me.lastWidth === me.contentWidth) {
      // console.log(`me.lastWidth === me.contentWidth - contentWidth: ${me.contentWidth}, lastWidth: ${me.lastWidth}, start: ${me.start}, middle: ${me.middle}, end: ${me.end}`);
      me.processing = false;
      if (me.lastMiddle === me.original.length) {
        me.setState({
          text: me.original,
          noClamp: true,
        });
      } else {
        me.setState({
          text: me.original.slice(0, me.lastMiddle)/* + me.getEllipsis()*/,
          noClamp: false,
        });
      }
    } else if (me.start <= me.end) {
      // console.log(`me.start <= me.end - contentWidth: ${me.contentWidth}, lastWidth: ${me.lastWidth}, start: ${me.start}, middle: ${me.middle}, end: ${me.end}`);
      me.processing = true;
      me.middle = Math.floor((me.start + me.end) / 2);
      if (me.lastMiddle === me.middle) {
        // middle计算出来一样，导致文本一样，导致react不做更新
        me.forceUpdate();
      } else {
        me.lastMiddle = me.middle;
        // console.log(`me.start <= me.end - text: ${me.original.slice(0, me.middle) + me.getEllipsis() + (me.props.moreText ? ('汗' + me.props.moreText) : '汗')}`);
        me.setState({
          text: me.original.slice(0, me.middle)/* + me.getEllipsis()*/ +
            (me.props.moreText ? me.props.moreText : ''),
            // (me.props.moreText ? ('汗' + me.props.moreText) : '汗'),
        });
      }
    } else {
      // console.log(`else - contentWidth: ${me.contentWidth}, lastWidth: ${me.lastWidth}, start: ${me.start}, middle: ${me.middle}, end: ${me.end}`);
      me.processing = false;
      me.lastWidth = me.contentWidth;
      if (me.middle === me.original.length) {
        if (me.state.text !== me.original || !me.state.noClamp) {
          me.setState({
            text: me.original,
            noClamp: true,
          }, () => {
            me.start = 0;
            me.middle = 0;
            me.end = me.original.length;
          });
        } else {
          me.forceUpdate(() => {
            me.start = 0;
            me.middle = 0;
            me.end = me.original.length;
          });
        }
      } else {
        me.lastMiddle = Math.min(me.end, me.middle);
        if (me.lastMiddle < 0) {
          requestAnimationFrame(() => {
            me.forceUpdateText = true;
            me.forceUpdate();
          });
        } else {
          me.setState({text: me.original.slice(0, me.lastMiddle)/* + me.getEllipsis()*/},
            () => {
              me.start = 0;
              me.middle = 0;
              me.end = me.original.length;
            });
        }
      }
    }
  };

  getLinkButton = () => {
    if (this.state.noClamp) {
      return this.props.extraLinkButtons ? (
        <span style={{userSelect: 'none'}}>
          {this.props.extraLinkButtons}
        </span>
      ) : null;
    }

    let buttonText = this.watch ? this.props.moreText : this.props.lessText;

    return (
      <span style={{userSelect: 'none'}}>
        {this.props.extraLinkButtons ? this.props.extraLinkButtons : null}
        {this.props.extraLinkButtons ? (
          <span style={{marginLeft: '0.5em', marginRight: '0.5em', opacity: 0.6}} />
        ) : null}
        {buttonText ? (
          <a
            className="clamp-lines__link_button"
            onClick={this.clickHandler}
            aria-controls={`clamped-content-${this.randomID}`}
            aria-expanded={!this.state.expanded}
          >
            {buttonText}
          </a>
        ) : null}
      </span>
    );
  };

  render () {
    let text = this.state.text, me = this;

    if (!this.props.text) {
      return null;
    }

    if (this.firstTime) {
      text = '1';
      this.firstTime = false;
      requestAnimationFrame(() => me.forceUpdate());
    }

    return (
      <div className={this.getClassName()}>
        <div
          ref={e => {
            this.element = e;
          }}
          style={this.processing ? (
            // 尚未获取容器宽度或容器宽度随内容扩大时时不可将容器从上下文中移走，已获得宽度后为保证画面不抖动，将容器移开
            (this.contentWidth > 0 && this.lastContentWidth >= 0 && this.contentWidth <= this.lastContentWidth) ? {
              opacity: 0,
              width: this.contentWidth,
              top: '1vh',
              right: `-${this.contentWidth}`,
              position: 'absolute',
              transitionDuration: 0,
            } : {
              opacity: 0,
              transitionDuration: 0,
            }
          ) : {
            transitionDuration: 0,
          }}
        >
          {this.renderFn(text, (
            this.contentWidth <= 0
          ) ? null : (
            (
              this.processing || !this.props.useLinkButtons
            ) ? (
              <span>
                {this.getEllipsis()}
              </span>
            ) : (
              <React.Fragment>
                <span>
                  {this.getEllipsis()}
                </span>
                <span>
                  {this.getLinkButton()}
                </span>
              </React.Fragment>
            )
          ))}
          {this.watch ? null : (
            <span style={{opacity: 0, userSelect: 'none'}}>
              {this.props.lessText}
            </span>
          )}
        </div>
        {(this.processing || this.contentWidth <= 0 || this.props.useLinkButtons) ? null : this.getButton()}
      </div>
    );
  }
}
