import React from 'react';
import PropTypes from 'prop-types';
import {Cascader, Row, Col} from 'antd';
import _ from 'lodash';

import {chinaADCTitleMap, getChinaADCCascaderOptions} from '@/components/common/common.functions';

import style from '@/style/common/microService/common.microService.less';

class MicroServicePanelParameterLocation extends React.PureComponent {
  options = undefined;

  constructor(props) {
    super(props);

    let me = this;

    me.options = getChinaADCCascaderOptions('000000', 'distinct', props.allowAnyProvince, props.allowAnyCity,
      props.allowAnyDistinct);
  }

  resetLocation = () => {
    let me = this, adc = '000000';

    if (me.props.locked) {
      return;
    }

    for (let i = (me.props.value || me.props.defaultValue).length - 1; i >= 0; i--) {
      if ((me.props.value || me.props.defaultValue)[i] !== '--') {
        adc = (me.props.value || me.props.defaultValue)[i];
        break;
      }
    }

    me.props.onLocationChange(adc);
    me.props.onShortTextChange((me.props.value || me.props.defaultValue)
      .map(value => chinaADCTitleMap[value] || '未知').join('/'));
  };

  componentDidMount() {
    let me = this;

    if (!me.props.locked) {
      me.props.onChange(me.props.value || me.props.defaultValue);
    }
    me.resetLocation();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let me = this;

    if (!_.isEqual(prevProps.value, me.props.value)) {
      me.resetLocation();
    }

    if (!me.props.locked && prevProps.locked !== me.props.locked) {
      me.props.onChange(me.props.value || me.props.defaultValue);
    } else if (!me.props.locked && me.props.value === undefined && me.props.defaultValue) {
      me.props.onChange(me.props.defaultValue);
    }
  }

  render() {
    let me = this;

    return (
      <Row>
        <Col span={me.props.optionSpan}>
          <Cascader
            options={me.options}
            className={style['parameter-location']}
            expandTrigger={'hover'}
            onChange={me.props.onChange}
            value={me.props.value || me.props.defaultValue}
            allowClear={true}
            disabled={me.props.locked}
            changeOnSelect={true}
            showSearch={(inputValue, path) => {
              return path.some(option => option.label.indexOf(inputValue) > -1);
            }}
          />
        </Col>
      </Row>
    );
  }
}

MicroServicePanelParameterLocation.defaultProps = {
  optionSpan: 18,
  allowAnyProvince: false,
  allowAnyCity: false,
  allowAnyDistinct: false,
};

MicroServicePanelParameterLocation.propTypes = {
  onChange: PropTypes.func.isRequired,
  onLocationChange: PropTypes.func.isRequired,
  onShortTextChange: PropTypes.func.isRequired,
  locked: PropTypes.bool.isRequired,
  defaultValue: PropTypes.array.isRequired,
  value: PropTypes.array,
  optionSpan: PropTypes.number,
  allowAnyProvince: PropTypes.bool,
  allowAnyCity: PropTypes.bool,
  allowAnyDistinct: PropTypes.bool,
};

export default MicroServicePanelParameterLocation;