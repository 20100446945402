import React from 'react';
import PropTypes from 'prop-types';
import { Menu } from 'antd';

import PB from '@/libs/simplePB';
import View from '@/components/common/common.viewManager';
import JoinTypeModal from '@/components/common/view/common.view.config.joinTypeModal';
import SetViewInfoModal from '@/components/common/view/common.view.info.setInfoModal';

export default class ViewOperateMenu extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			viewInfo: props.view,
			showJoinTypeModal: false,
			showSetViewInfoModal: false, // 设置图谱基本信息弹框显示隐藏
		};
	}

	// componentDidMount() {}

	// componentWillMount() {}

	// componentWillReceiveProps(next) {}

	render() {
		let me = this;
		const {callback, disabledDelMenu, extraMenuItems} = this.props;
		const view = me.state.viewInfo;
		// console.log('dashboard.view.operate->render-> this.props:', this.props);
		// console.log('view operate 传入的 view', view)
		// 登录用户id
		const currentUserId = parseInt(localStorage.getItem('userId'));
		// console.log('view operate  currentUserId', currentUserId)
		// console.log('view operate  view userId', view.userId)
		// 判断是否是创建人
		const isOwner = (currentUserId === view.userId);

		// 判断是否是免费、收费
		const onSales = !!(view.up && view.up === 1); // 是否上架
		const isFree = !!(view.up && view.up === 1 && !(view.price > 0)); // 是否免费

		// console.log('dashboard.view.operate->render->view 的状态 isOwner=', isOwner)
		// console.log('dashboard.view.operate->render->view 的状态 onSales=', onSales)
		// console.log('dashboard.view.operate->render->view 的状态 isFree=', isFree)

		// 传递Dropdown注入的props
		const uiProps = {...{}, ...this.props};
		delete uiProps.view;
		delete uiProps.disabledDelMenu;
		delete uiProps.extraMenuItems;
		delete uiProps.callback;
		// console.log('uiProps',uiProps)
		return (
			<React.Fragment>
				<Menu {...uiProps} style={{textAlign: 'center', width: '7rem'}}>
					<Menu.Item disabled={!isOwner || view.lock === 1}
										 style={{color: !isOwner ? '#a5a5a5': ''}}
										 onClick={() => me.setState({showSetViewInfoModal: true})}
					>
						基本属性
					</Menu.Item>
					<Menu.Item
						disabled={!isFree && !isOwner}
						style={{color: (isFree || isOwner) ? '': '#a5a5a5'}}
						onClick={() => View.payFreeView(view, callback)}
					>
						复制图谱
					</Menu.Item>
					<Menu.Divider/>
					<Menu.Item disabled={!isOwner}
										 style={{color: !isOwner ? '#a5a5a5': ''}}
										 onClick={() => me.setState({showJoinTypeModal: true})}>
						访问设置
					</Menu.Item>
					<Menu.Item
						key="view-share-link"
						onClick={() => View.copyUrlToBoard(view, callback)}>
						{/*<Icon name="copy"/> */}
						复制链接
					</Menu.Item>
					<Menu.Divider/>
					{
						disabledDelMenu ? null : (
							<Menu.Item
								disabled={disabledDelMenu ? disabledDelMenu : !isOwner}
								style={{color: !isOwner ? '#a5a5a5': ''}}
								onClick={() => View.delView(view, callback)}
							>
								删除图谱
							</Menu.Item>
						)
					}
					{
						extraMenuItems && extraMenuItems.length > 0 ? <Menu.Divider/> : null
					}
					{
						extraMenuItems && extraMenuItems.length > 0 ? extraMenuItems.map(i => i) : null
					}
				</Menu>
				<JoinTypeModal
					visible={me.state.showJoinTypeModal}
					viewId={view.viewId}
					viewInfo={view}
					readonly={!isOwner}
					onClose={() => me.setState({showJoinTypeModal: false})}
					callback={me.props.callback}
				/>
				<SetViewInfoModal
					bus={PB}
					visible={me.state.showSetViewInfoModal}
					viewId={view.viewId}
					viewInfo={view}
					onClose={() => me.setState({showSetViewInfoModal: false})}
					callback={me.props.callback}
				/>
			</React.Fragment>
		);
	}
}

ViewOperateMenu.defaultProps = {
	view: undefined, // 默认图谱
	disabledDelMenu: false, // 默认是否禁用删除图谱菜单项
	extraMenuItems: [],
	callback: () => {
	},
};
ViewOperateMenu.propTypes = {
	view: PropTypes.object.isRequired, // 需要操作的view对象
	disabledDelMenu: PropTypes.bool, // 删除按钮 的状态
	extraMenuItems: PropTypes.array, // 额外的按钮 Menu.Item 组成的数组
	callback: PropTypes.func, // 操作的回调
};
