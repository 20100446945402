import React from 'react';
import PropTypes from 'prop-types';
import {Input, Form, Button, Popover, Row, Col, Upload, Tooltip, Slider, message, Switch} from 'antd';
import {SketchPicker} from 'react-color';
import AvatarEditor from 'react-avatar-editor';
import _ from 'lodash';

import PB, {SimplePB} from '@/libs/simplePB';

import {defaultDefine, getNodeDisplayTitle} from '@/constants/vis.defaultDefine.1';

import {getBase64Url} from '@/utils/Common';

import Icon from '@/components/common/common.icon';
import {checkUploadImage} from '@/components/common/common.functions';
import ViewDataProvider from '@/components/common/dataProvider/common.dataProvider.view';
import PresentationDataProvider from '@/components/common/dataProvider/common.dataProvider.presentation';

import style from '@/style/common/view/common.view.presentation.less';
import moment from "moment";

const iconSize = 180;

const presetColors = ['rgba(0, 0, 0, 0)', defaultDefine.colors.level1, '#D0021B', '#F5A623', '#F8E71C', '#8B572A',
  '#7ED321', '#417505', '#BD10E0', '#9013FE', '#4A90E2', '#50E3C2', '#B8E986', '#000000', '#FFFFFF']
  .map(color => ({color, title: ''}));

class PresentationConfigEditPanel extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = props.config ? {
      title: props.config.title,
      description: props.config.description,
      nodeListText: props.config.content && props.config.content.nodeIds && props.viewDataProvider.getNode(props.config.content.nodeIds).filter(n => !!n)
        .map(n => getNodeDisplayTitle(n)).join("\r\n") || '',
      presentationId: props.config.presentationId,

      nodeIdList: props.config.content.nodeIds,
      isPublic: props.config.isPublic,
      showIconEditor: !!props.config.meta['iconData'],

      uploading: false,
      saving: false,

      iconScale: 1,
      iconRotate: 0,
      iconBackground: props.config.meta['iconData'] && /image\/png/.test(props.config.meta['iconData'])
        ? {r: 0, g: 0, b: 0, a: 0} : defaultDefine.colors.level1RGBA,
      iconSize,
      iconOriginalSize: props.config.meta['iconMeta'] && props.config.meta['iconMeta']['rectHeight'],
      iconPosition: {x: 0.5, y: 0.5},
      iconImgBase64Url: props.config.meta['iconData'],
    } : {
      title: '',
      description: '',
      nodeListText: props.viewDataProvider.getNode(props.activatedNodeList).filter(n => !!n)
      .map(n => getNodeDisplayTitle(n)).join("\r\n"),
      presentationId: undefined,

      nodeIdList: props.activatedNodeList,
      isPublic: 0,
      showIconEditor: false,

      uploading: false,
      saving: false,

      iconScale: 1,
      iconRotate: 0,
      iconBackground: defaultDefine.colors.level1RGBA,
      iconSize,
      iconOriginalSize: 0,
      iconPosition: {x: 0.5, y: 0.5},
      iconImgBase64Url: undefined,
      valueList: [], 
      noNodeIdList: []
    };
  }

  iconEditorRef = undefined;

  onSaveConfig = (e) => {
    let me = this;
    const {form} = me.props;

    e && e.preventDefault();

    form.validateFields((err, values) => {
      if (!err) {
        let {title, description} = values;

        const canvas = (me.state['showIconEditor'] && me.iconEditorRef && me.state['iconImgBase64Url']) ?
          this.iconEditorRef.getImageScaledToCanvas() : undefined;
        /**
         * @type {undefined|HTMLCanvasElement}
         */
        let dstCanvas = undefined;
        if (canvas) {
          dstCanvas = document.createElement('canvas');
          dstCanvas.width = canvas.width;
          dstCanvas.height = canvas.height;
          const ctx = dstCanvas.getContext('2d');
          ctx.fillStyle = `rgba(${Object.values(me.state['iconBackground']).join(', ')})`;
          ctx.fillRect(0, 0, dstCanvas.width, dstCanvas.height);
          ctx.drawImage(canvas, 0, 0);
        }
        const config = {
          presentationId: me.state['presentationId'],
          title,
          description,
          isPublic: me.state['isPublic'],
          viewId: me.props.viewDataProvider.viewId,
          meta: {
            iconMeta: {
              shape: 'circle',
              rectHeight: me.state['iconSize'],
              rectWidth: me.state['iconSize'],
            },
            iconData: (dstCanvas ? dstCanvas.toDataURL(
              me.state['iconBackground'].a === 1 ? 'image/jpeg' : 'image/png') : me.state['iconImgBase64Url']),
          },
          content: {
            nodeIds: me.state['nodeIdList'],
            contentList: me.state['valueList']
          }
        };
        me.setState({saving: true}, () => {
          if (me.state['presentationId']) {
            me.props.bus.emit('presentation', 'config.update.do',
              {viewId: me.props.viewDataProvider.viewId, presentationId: me.state['presentationId']});
            PresentationDataProvider.updateConfig(me.state['presentationId'], config).then(config => {
              message.success('专题报告修改成功');
              me.setState({saving: false}, () => {
                me.props.bus.emit('presentation', 'config.updated',
                  {presentationId: me.state['presentationId'], config});
                me.props.bus.emit('presentation', 'config.list.show_drawer',
                  {viewId: me.props.viewDataProvider.viewId});
              });
            }).catch(error => {
              message.error('专题报告修改存失败');
              me.setState({saving: false}, () => {
                me.props.bus.emit('presentation', 'config.update.failed',
                  {...error, viewId: me.props.viewDataProvider.viewId});
              });
            });
          } else {
            me.props.bus.emit('presentation', 'config.add.do',
              {viewId: me.props.viewDataProvider.viewId});
            PresentationDataProvider.addConfig(config).then(config => {
              message.success('专题报告保存成功');
              me.setState({saving: false}, () => {
                me.props.bus.emit('presentation', 'config.added', config);
                me.props.bus.emit('presentation', 'config.list.show_drawer',
                  {viewId: me.props.viewDataProvider.viewId});
              });
            }).catch(error => {
              message.error('专题报告保存失败');
              me.setState({saving: false}, () => {
                me.props.bus.emit('presentation', 'config.add.failed',
                  {...error, viewId: me.props.viewDataProvider.viewId});
              });
            });
          }
        });
      }
    });
  };

  validateNodeList = (rule, value, callback) => {
    try {
      let me = this, nodeIdList = [], nodeIdMap = {}, trimmedNodeIdMap = {};
      let valueList =[], noNodeIdList = [];

      if (!value) {
        // noinspection ExceptionCaughtLocallyJS
        throw new Error('请输入专题报告清单，节点之间用换行分隔');
      }

      let textList = value.replace(/\r\n/g, '\n').replace(/\r/g, '\n').split('\n');
      me.props.viewDataProvider.getData().data.nodes.get().forEach(n => {
        const title = getNodeDisplayTitle(n);
        if (!nodeIdMap[title]) {
          nodeIdMap[title] = n.id;
        }
        const trimmedTitle = _.trim(title);
        if (trimmedTitle !== title) {
          trimmedNodeIdMap[trimmedTitle] = n.id;
        }
      });

      textList.forEach(text => {
        if (text) {
          if (nodeIdMap[text]) {
            nodeIdList.push(nodeIdMap[text]);
            valueList.push(nodeIdMap[text]);
          } else if (trimmedNodeIdMap[_.trim(text)]) {
            nodeIdList.push(trimmedNodeIdMap[_.trim(text)]);
            valueList.push(trimmedNodeIdMap[_.trim(text)]);
          } else {
            // throw new Error(`没有找到名称为 ${text} 的节点`);
            noNodeIdList.push(text);
            valueList.push(text);
          }
        }
      });

      if (nodeIdList.length === 0) {
        // noinspection ExceptionCaughtLocallyJS
        //throw new Error('请输入专题报告清单，节点之间用换行分隔');
      }

      me.setState({nodeIdList,valueList,noNodeIdList}, callback);
    } catch (e) {
      callback(e);
    }
  };

  beforeIconUpload = (file) => {
    let me = this;

    if (checkUploadImage(file, message)) {
      getBase64Url(file, imgBase64Url => {
        const URL = window.URL || window.webkitURL;
        // noinspection JSCheckFunctionSignatures
        let img = new Image();
        img.src = URL.createObjectURL(file);
        img.onload = () => {
          const ratio = img.naturalWidth / img.naturalHeight;
          let iconPosition = {x: 0.5, y: 0.5};
          if (ratio >= 0.5 && ratio <= 0.85) {
            iconPosition.y *= (0.85 - (0.85 - ratio) * 4 / 7);
          }
          me.setState({
            uploading: false,

            showIconEditor: true,

            iconImgBase64Url: imgBase64Url,
            iconScale: 1,
            iconRotate: 0,
            iconPosition,
            iconOriginalSize: Math.min(img.naturalHeight, img.naturalWidth),
            iconSize,
          });
        };
      });
    } else {
      me.setState({uploading: false});
    }
    return false;
  };

  onPastingImage = e => {
    let me = this;
    // noinspection DuplicatedCode
    if (e.clipboardData && e.clipboardData.items) {
      // 从剪贴板中获取items
      let items = e.clipboardData.items;
      // 循环items获取剪贴板中的图片
      if (items.length === 1 && items[0].type.indexOf('image') !== -1) {
        // 获取图片的文件信息
        let blob = items[0].getAsFile();
        // 配合输入框文字，"可粘贴截屏"好像不太顺，改为截图
        let fileName = '截图' + moment().format("YYYY-MM-DD HH:mm:ss");
        let file = new window.File([blob], fileName + '.png', {type: blob.type});
        me.beforeIconUpload(file);
        e.preventDefault();
      }
    }
  };

  componentDidMount() {
    let me = this;

    if (me.props.config) {
      let nodeList = [];
      me.props.config.content.contentList && me.props.config.content.contentList.forEach((node)=>{
        if(me.props.config.content.nodeIds.includes(node)){
          nodeList = [...nodeList,...me.props.viewDataProvider.getNode([node]).filter(n => !!n)
        .map(n => getNodeDisplayTitle(n))];
        }else{
          nodeList.push(node);
        }
      })
      let nodeListText = nodeList.join("\r\n");
      me.props['form'].setFieldsValue({
        title: me.state['title'],
        description: me.state['description'],
        nodeListText: nodeListText||me.state['nodeListText'],
      });
    }else{
      if(me.props.activatedNodeList){
        let nodeListText = me.props.viewDataProvider.getNode(me.props.activatedNodeList).filter(n => !!n)
      .map(n => getNodeDisplayTitle(n)).join("\r\n");
        me.props['form'].setFieldsValue({
          nodeListText: me.state['nodeListText']||nodeListText,
        });
      }
    }

    // 聚焦添加描述文本框时，使用粘贴功能，提取剪贴板中图片作为附件
    window.addEventListener('paste', me.onPastingImage);
  }

  componentWillUnmount() {
    window.removeEventListener('paste', this.onPastingImage);
  }

  render() {
    let me = this, ratio;
    const {getFieldDecorator} = me.props['form'];

    if (me.state['showIconEditor']) ratio = Math.min(2, me.state['iconOriginalSize'] / me.state['iconSize']);

    return (
      <div className={style['config-edit-panel']}>
        <div className={style['config-edit-header']}>
          <div className={style['config-title']}>
            {me.state['presentationId'] ? '修改专题报告' : '添加专题报告'}
          </div>
          <div
            className={style['config-back-to-list']}
            onClick={() => me.props.bus.emit('presentation', 'config.list.show_drawer',
              {viewId: me.props.viewDataProvider.viewId})}
          >
            <Icon name={'left'}/> 专题报告列表
          </div>
        </div>
        <div className={`${style['config-edit-content']} scrollbar scrollbar-none`}>
        <div className={`${style['config-table']} scrollbar-none`}>
          <Form layout={'vertical'} onSubmit={me.onSaveConfig} className={style['config-edit-form']}>
            <Form.Item label={'名称'}>
              {
                getFieldDecorator('title', {rules: [{
                  validator: (rule, value, callback) =>
                    callback(!!value && value !== '' ? undefined : new Error(rule.message)),
                  message: '请输入专题报告名称，不能为空',
                }]})(
                  <Input
                    placeholder={'请输入专题报告名称'}
                    autoFocus={true}
                    onChange={e => me.setState({title: e.target.value})}
                  />
                )
              }
            </Form.Item>
            <Form.Item label={'描述'}>
              {
                getFieldDecorator('description')(
                  <Input.TextArea
                    placeholder={'请输入专题报告描述'}
                    onChange={e => me.setState({description: e.target.value})}
                    rows={3}
                  />
                )
              }
            </Form.Item>
            <Form.Item label={'图标'}>
              {
                me.state['showIconEditor'] ? (
                  <Row type={'flex'} justify="space-around">
                    <Col style={{width: me.state['iconSize'] + 20, height: me.state['iconSize'] + 20}}>
                      <div className={style['avatar-editor']}>
                        <AvatarEditor
                          image={me.state['iconImgBase64Url']}
                          width={me.state['iconSize']}
                          height={me.state['iconSize']}
                          position={me.state['iconPosition']}
                          border={10}
                          borderRadius={me.state['iconSize'] / 2}
                          color={[0, 0, 0, 0.4]}
                          scale={me.state['iconScale']}
                          rotate={me.state['iconRotate']}
                          style={{backgroundColor: `rgba(${Object.values(me.state['iconBackground']).join(', ')})`}}
                          onPositionChange={iconPosition => me.setState({iconPosition})}
                        />
                        <div style={{
                          position: 'absolute',
                          userSelect: 'none',
                          pointerEvents: 'none',
                          top: 0,
                          opacity: 0,
                          height: 0,
                          width: 0,
                          overflow: 'hidden',
                        }}>
                          <AvatarEditor
                            ref={ref => me.iconEditorRef = ref}
                            image={me.state['iconImgBase64Url']}
                            width={me.state['iconSize'] * ratio}
                            height={me.state['iconSize'] * ratio}
                            position={me.state['iconPosition']}
                            border={10 * ratio}
                            borderRadius={me.state['iconSize'] / 2 * ratio}
                            color={[0, 0, 0, 0.4]}
                            scale={me.state['iconScale']}
                            rotate={me.state['iconRotate']}
                            style={{backgroundColor: `rgba(${Object.values(me.state['iconBackground']).join(', ')})`}}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col className={style['config-edit-form-icon-ctrl-frame']}>
                      <div>
                        <Upload
                          className={style['config-edit-form-icon-ctrl-upload']}
                          name={'file'}
                          multiple={false}
                          showUploadList={false}
                          disabled={this.state['uploading']}
                          beforeUpload={file => me.beforeIconUpload(file)}
                          onChange={info => {
                            if (info.file.status === 'uploading') {
                              me.setState({uploading: true});
                            }
                          }}
                        >
                          <Button
                            className={style['config-edit-form-btn-normal']}
                            icon={'upload'}
                            block={true}
                          >
                            上传图标文件
                          </Button>
                        </Upload>
                      </div>
                      <div>
                        <Popover
                          content={(
                            <SketchPicker
                              color={me.state['iconBackground']}
                              onChange={color => me.setState({iconBackground: color.rgb})}
                              presetColors={presetColors}
                            />
                          )}
                          overlayClassName={`dark-theme ${style['sketch-picker-overlay']}`}
                          placement={'right'}
                        >
                          <Button
                            className={style['config-edit-form-btn-normal']}
                            style={{lineHeight: '32px'}}
                            block={true}
                          >
                            设置背景
                            <span className={style['config-edit-icon-bg-sample']}>
                          <span
                            style={{
                              backgroundColor: `rgba(${Object.values(me.state['iconBackground']).join(', ')})`,
                            }}
                          >
                            &nbsp;
                          </span>
                        </span>
                          </Button>
                        </Popover>
                      </div>
                      <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <Tooltip placement={'top'} title={'缩小'}>
                          <Button
                            icon={'zoom-out'}
                            className={style['config-edit-form-btn-normal']}
                            disabled={me.state['iconScale'] <= 0.5}
                            onClick={() => me.setState({iconScale: Math.max(me.state['iconScale'] - 0.1, 0.5)})}
                          />
                        </Tooltip>
                        <Tooltip placement={'top'} title={'放大'}>
                          <Button
                            icon={'zoom-in'}
                            className={style['config-edit-form-btn-normal']}
                            disabled={me.state['iconScale'] >= 2}
                            onClick={() => me.setState({iconScale: Math.min(me.state['iconScale'] + 0.1, 2)})}
                          />
                        </Tooltip>
                        <Tooltip placement={'top'} title={'左转90°'}>
                          <Button
                            icon={'undo'}
                            className={style['config-edit-form-btn-normal']}
                            onClick={() => me.setState({iconRotate: (me.state['iconRotate'] - 90) % 360})}
                          />
                        </Tooltip>
                        <Tooltip placement={'top'} title={'右转90°'}>
                          <Button
                            icon={'redo'}
                            className={style['config-edit-form-btn-normal']}
                            onClick={() => me.setState({iconRotate: (me.state['iconRotate'] + 90) % 360})}
                          />
                        </Tooltip>
                      </div>
                      <div>
                        <Slider
                          style={{margin: '0 6px'}}
                          onChange={v => me.setState({iconScale: v})}
                          min={0.5}
                          max={2}
                          step={0.01}
                          value={this.state['iconScale']}
                        />
                      </div>
                      <div>
                        <Button
                          className={style['config-edit-form-btn-normal']}
                          icon={'delete'}
                          block={true}
                          onClick={() => {
                            me.setState({
                              showIconEditor: false,
                              iconImgBase64Url: undefined,
                              iconScale: 1,
                              iconRotate: 0,
                              iconOriginalSize: 0,
                              iconPosition: {x: 0.5, y: 0.5},
                              iconSize,
                            });
                          }}
                        >
                          移除自定义图标
                        </Button>
                      </div>
                    </Col>
                  </Row>
                ) : (
                  <Upload.Dragger
                    name={'file'}
                    multiple={false}
                    showUploadList={false}
                    disabled={me.state['uploading']}
                    beforeUpload={file => me.beforeIconUpload(file)}
                    className={style['config-edit-form-icon-upload-frame']}
                    onChange={info => {
                      if (info.file.status === 'uploading') {
                        me.setState({uploading: true});
                      }
                    }}
                  >
                    <p className="ant-upload-drag-icon">
                      <Icon name="picture"/>
                    </p>
                    <p className="ant-upload-text">
                      点击选取上传图标文件 或 拖拽图标文件到这里<br />
                      也可直接 Ctrl+V 粘贴
                    </p>
                  </Upload.Dragger>
                )
              }
            </Form.Item>
            <Form.Item label={'节点清单（节点之间用换行分隔）'}>
              {
                getFieldDecorator('nodeListText', {
                  rules: [{validator: me.validateNodeList}],
                })(
                  <Input.TextArea
                    placeholder={'请输入专题报告清单，节点之间用换行分隔'}
                    onChange={e => me.setState({nodeListText: e.target.value})}
                    rows={10}
                  />
                )
              }
            </Form.Item>
            <Form.Item label={'是否公开'} className={style['config-edit-leftright-frame']}>
              <Switch checkedChildren="是" unCheckedChildren="否" checked={me.state['isPublic']===1} onChange={checked => me.setState({isPublic: checked===true?1:0})}/>
            </Form.Item>
            <Form.Item className={style['config-edit-form-btn-frame']}>
              <Button
                className={style['config-edit-form-btn-normal']}
                onClick={() => {
                  me.props.bus.emit('presentation', 'config.list.show_drawer',
                    {viewId: me.props.viewDataProvider.viewId});
                }}
              >
                取消
              </Button>
              <Button type={'primary'} htmlType={'submit'} loading={me.state['saving']}>保存</Button>
            </Form.Item>
          </Form>
          </div>
        </div>
      </div>
    );
  }
}

PresentationConfigEditPanel.defaultProps = {
  bus: PB,
};

PresentationConfigEditPanel.propTypes = {
  viewDataProvider: PropTypes.instanceOf(ViewDataProvider).isRequired,
  config: PropTypes.object,
  bus: PropTypes.instanceOf(SimplePB),
  activatedNodeList: PropTypes.array
};

const WrappedPresentationConfigEditPanel = Form.create()(PresentationConfigEditPanel);

// noinspection JSValidateTypes
WrappedPresentationConfigEditPanel.defaultProps = {
  bus: PB,
};

// noinspection JSValidateTypes
WrappedPresentationConfigEditPanel.propTypes = {
  viewDataProvider: PropTypes.instanceOf(ViewDataProvider).isRequired,
  config: PropTypes.object,
  bus: PropTypes.instanceOf(SimplePB),
  activatedNodeList: PropTypes.array
};

export default WrappedPresentationConfigEditPanel;