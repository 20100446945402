import React from 'react';
import PropTypes from 'prop-types';
import {Tabs} from 'antd';

import PB, {SimplePB} from '@/libs/simplePB';

import ViewDataProvider from '@/components/common/dataProvider/common.dataProvider.view';

import {MicroServiceUIConfigOperationItemTypes}
  from '@/components/common/view/microService/shape/common.view.microService.shape.uiConfig';
import MicroServicePanelResultSaveNodes
  from '@/components/common/view/microService/panel/result/common.view.microService.panel.result.saveNodes';
import MicroServicePanelResultSaveEdges
  from '@/components/common/view/microService/panel/result/common.view.microService.panel.result.saveEdges';
import MicroServicePanelResultSaveSubGraphs
  from '@/components/common/view/microService/panel/result/common.view.microService.panel.result.saveSubGraphs';
import MicroServicePanelResultSaveContent
  from '@/components/common/view/microService/panel/result/common.view.microService.panel.result.saveContent';
import MicroServicePanelResultShowWordCloud
  from '@/components/common/view/microService/panel/result/common.view.microService.panel.result.showWordCloud';
import MicroServicePanelResultSaveFiles
  from '@/components/common/view/microService/panel/result/common.view.microService.panel.result.saveFiles';
  import MicroServicePanelResultShowCharts
  from '@/components/common/view/microService/panel/result/common.view.microService.panel.result.showCharts';

import style from '@/style/common/microService/common.microService.less';

const getResultPanel = (me, type) => {
  switch (type) {
    case 'saveNodes':
      return (
        <MicroServicePanelResultSaveNodes
          locked={me.props.locked}
          autoLoadMore={me.props.autoLoadMore}
          hasMoreResult={me.props.hasMoreResult}
          lastLoadingFailed={me.props.lastLoadingFailed}
          loadingResult={me.props.loadingResult}
          onCheckStatusChanged={(hasCheckedKey) => me.onCheckStatusChanged(type, hasCheckedKey)}
          viewDataProvider={me.props.viewDataProvider}
          bus={me.props.bus}
          microServiceId={me.props.microServiceId}
          viewId={me.props.viewId}
          visible={me.props.visible && me.state.activatedType === type}
        />
      );
    case 'saveEdges':
      return (
        <MicroServicePanelResultSaveEdges
          locked={me.props.locked}
          autoLoadMore={me.props.autoLoadMore}
          hasMoreResult={me.props.hasMoreResult}
          lastLoadingFailed={me.props.lastLoadingFailed}
          loadingResult={me.props.loadingResult}
          onCheckStatusChanged={(hasCheckedKey) => me.onCheckStatusChanged(type, hasCheckedKey)}
          viewDataProvider={me.props.viewDataProvider}
          bus={me.props.bus}
          microServiceId={me.props.microServiceId}
          viewId={me.props.viewId}
          visible={me.props.visible && me.state.activatedType === type}
        />
      );
    case 'saveSubGraphs':
      return (
        <MicroServicePanelResultSaveSubGraphs
          locked={me.props.locked}
          autoLoadMore={me.props.autoLoadMore}
          hasMoreResult={me.props.hasMoreResult}
          lastLoadingFailed={me.props.lastLoadingFailed}
          loadingResult={me.props.loadingResult}
          onCheckStatusChanged={(hasCheckedKey) => me.onCheckStatusChanged(type, hasCheckedKey)}
          viewDataProvider={me.props.viewDataProvider}
          bus={me.props.bus}
          microServiceId={me.props.microServiceId}
          viewId={me.props.viewId}
          visible={me.props.visible && me.state.activatedType === type}
        />
      );
    case 'saveContent':
      return (
        <MicroServicePanelResultSaveContent
          locked={me.props.locked}
          autoLoadMore={me.props.autoLoadMore}
          hasMoreResult={me.props.hasMoreResult}
          lastLoadingFailed={me.props.lastLoadingFailed}
          loadingResult={me.props.loadingResult}
          onCheckStatusChanged={(hasCheckedKey) => me.onCheckStatusChanged(type, hasCheckedKey)}
          viewDataProvider={me.props.viewDataProvider}
          bus={me.props.bus}
          microServiceId={me.props.microServiceId}
          viewId={me.props.viewId}
          visible={me.props.visible && me.state.activatedType === type}
        />
      );
    case 'showWordCloud':
      return (
        <MicroServicePanelResultShowWordCloud
          locked={me.props.locked}
          autoLoadMore={me.props.autoLoadMore}
          hasMoreResult={me.props.hasMoreResult}
          lastLoadingFailed={me.props.lastLoadingFailed}
          loadingResult={me.props.loadingResult}
          onCheckStatusChanged={(hasCheckedKey) => me.onCheckStatusChanged(type, hasCheckedKey)}
          viewDataProvider={me.props.viewDataProvider}
          bus={me.props.bus}
          microServiceId={me.props.microServiceId}
          viewId={me.props.viewId}
          visible={me.props.visible && me.state.activatedType === type}
        />
      );
    case 'saveFiles':
      return (
        <MicroServicePanelResultSaveFiles
          locked={me.props.locked}
          autoLoadMore={me.props.autoLoadMore}
          hasMoreResult={me.props.hasMoreResult}
          lastLoadingFailed={me.props.lastLoadingFailed}
          loadingResult={me.props.loadingResult}
          onCheckStatusChanged={(hasCheckedKey) => me.onCheckStatusChanged(type, hasCheckedKey)}
          viewDataProvider={me.props.viewDataProvider}
          bus={me.props.bus}
          microServiceId={me.props.microServiceId}
          viewId={me.props.viewId}
          visible={me.props.visible && me.state.activatedType === type}
        />
      );
    case 'showCharts':
      return (
        <MicroServicePanelResultShowCharts
          locked={me.props.locked}
          autoLoadMore={me.props.autoLoadMore}
          hasMoreResult={me.props.hasMoreResult}
          lastLoadingFailed={me.props.lastLoadingFailed}
          loadingResult={me.props.loadingResult}
          onCheckStatusChanged={(hasCheckedKey) => me.onCheckStatusChanged(type, hasCheckedKey)}
          viewDataProvider={me.props.viewDataProvider}
          bus={me.props.bus}
          microServiceId={me.props.microServiceId}
          viewId={me.props.viewId}
          visible={me.props.visible && me.state.activatedType === type}
        />
      );
    default:
      return null;
  }
};

const TAB_TITLES = {
  saveNodes: '节点',
  saveEdges: '连接',
  saveSubGraphs: '子图',
  saveContent: '内容',
  showWordCloud: '词云',
  saveFiles: '文件',
  showCharts: '图表',
};

class MicroServicePanelResult extends React.PureComponent {
  state = {
    activatedType: undefined,
  };

  hasCheckedKeyMap = {};

  onCheckStatusChanged = (type, hasCheckedKey) => {
    let me = this;
    me.hasCheckedKeyMap[type] = hasCheckedKey;

    if (type === me.state.activatedType) {
      return me.props.onCheckStatusChanged(hasCheckedKey);
    }
  };

  componentDidMount() {
    let me = this;

    requestAnimationFrame(() => me.setState({activatedType: me.props.operations[0].type}));
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let me = this;

    if (prevProps.operations !== me.props.operations) {
      requestAnimationFrame(() => me.setState({activatedType: me.props.operations[0].type}));
    }
  }

  render() {
    let me = this;

    if (!me.props.operations) {
      return null;
    } else if (me.props.operations.length === 1) {
      return (
        <div className={style['result-frame']}>
          {getResultPanel(me, me.props.operations[0].type)}
        </div>
      );
    } else {
      return (
        <Tabs
          className={style['result-frame']}
          activeKey={me.state.activatedType}
          type={'card'}
          tabBarStyle={{marginBottom: '-1px', borderBottomColor: 'transparent'}}
          onChange={activatedType => {
            me.setState({activatedType});
            me.props.onCheckStatusChanged(me.hasCheckedKeyMap[activatedType] === true);
            me.props.onOperationChanged(activatedType);
          }}
        >
          {me.props.operations.map(({type}) => (
            <Tabs.TabPane key={type} tab={TAB_TITLES[type]} forceRender={true} >
              {getResultPanel(me, type)}
            </Tabs.TabPane>
          ))}
        </Tabs>
      )
    }
  }
}

MicroServicePanelResult.defaultTypes = {
  bus: PB,
};

MicroServicePanelResult.propTypes = {
  operations: PropTypes.arrayOf(PropTypes.oneOfType(MicroServiceUIConfigOperationItemTypes)).isRequired,
  locked: PropTypes.bool.isRequired,
  autoLoadMore: PropTypes.bool.isRequired,
  hasMoreResult: PropTypes.bool.isRequired,
  lastLoadingFailed: PropTypes.bool.isRequired,
  loadingResult: PropTypes.bool.isRequired,
  onCheckStatusChanged: PropTypes.func.isRequired,
  onOperationChanged: PropTypes.func.isRequired,
  viewDataProvider: PropTypes.instanceOf(ViewDataProvider).isRequired,
  bus: PropTypes.instanceOf(SimplePB),
  microServiceId: PropTypes.string,
  viewId: PropTypes.string,
  visible: PropTypes.bool,
};

export default MicroServicePanelResult;