import PropTypes from 'prop-types';

/**
 * @module MicroServiceUI
 * @description 微服务UI配置
 * @author zhangxs@joinmap.ai
 */

/**
 * @description 请求参数配置
 * @typedef {Object} TMicroServiceUIConfigRequest
 * @property {number} [targetLimit] - 每次请求最多发送的目标数量，默认为10
 * @property {number} [resultLimit] - 每次请求最多获取的结果数量，默认为100
 */
export const MicroServiceUIConfigRequest = {
  targetLimit: PropTypes.number,
  resultLimit: PropTypes.number,
};

/**
 * @description 抽象作用对象选项配置
 * @typedef {Object} TAbstractMicroServiceUIConfigTargetItem
 * @property {string} [label] - 目标选项静态标题，如：全部节点
 * @property {string} [shortText] - 目标选项静态简要文本，如：全部
 * @property {function} [dynamicLabel] - 一段JS方法的源码，执行后需返回目标选项动态标题，如执行后返回字符串：全部节点（8个）
 * @property {function} [dynamicShortText] - 一段JS方法的源码，执行后需返回目标选项动态简要文本，如执行后返回字符串：全部（8）
 */
const AbstractMicroServiceUIConfigTargetItem = {
  label: PropTypes.string,
  shortText: PropTypes.string,
  dynamicLabel: PropTypes.func,
  dynamicShortText: PropTypes.func,
};

/**
 * @description 作用对象选项配置 - 当前节点
 * @typedef {TAbstractMicroServiceUIConfigTargetItem} TMicroServiceUIConfigTargetItemNode
 * @property {'node'} type - 目标类型
 */
export const MicroServiceUIConfigTargetItemNode = {
  ...AbstractMicroServiceUIConfigTargetItem,
  type: PropTypes.oneOf(['node']),
}

/**
 * @description 作用对象选项配置 - 节点列表
 * @typedef {TAbstractMicroServiceUIConfigTargetItem} TMicroServiceUIConfigTargetItemFilteredNodes
 * @property {'filteredNodes'} type - 目标类型
 * @property {function} filter - 一段JS方法源码，用来过滤节点，执行后需返回true或false
 */
export const MicroServiceUIConfigTargetItemFilteredNodes = {
  ...AbstractMicroServiceUIConfigTargetItem,
  type: PropTypes.oneOf(['filteredNodes']),
  filter: PropTypes.func.isRequired,
};

/**
 * @description 作用对象选项配置 - 文本或链接
 * @typedef {TAbstractMicroServiceUIConfigTargetItem} TMicroServiceUIConfigTargetItemTextOrUrl
 * @property {'textOrUrl'} type - 目标类型
 */
export const MicroServiceUIConfigTargetItemTextOrUrl = {
  ...AbstractMicroServiceUIConfigTargetItem,
  type: PropTypes.oneOf(['textOrUrl']),
};

/**
 * @description 作用对象选项配置 - 当前看板
 * @typedef {TAbstractMicroServiceUIConfigTargetItem} TMicroServiceUIConfigTargetItemView
 * @property {'view'} type - 目标类型
 */
export const MicroServiceUIConfigTargetItemView = {
  ...AbstractMicroServiceUIConfigTargetItem,
  type: PropTypes.oneOf(['view']),
}

/**
 * @description 作用对象选项配置 - 文件
 * @typedef {TAbstractMicroServiceUIConfigTargetItem} TMicroServiceUIConfigTargetItemFiles
 * @property {'files'} type - 目标类型
 */
export const MicroServiceUIConfigTargetItemFiles = {
  ...AbstractMicroServiceUIConfigTargetItem,
  type: PropTypes.oneOf(['files']),
}

/**
 * @description 作用对象配置选项
 * @typedef {
 *   TMicroServiceUIConfigTargetItemNode
 *   |TMicroServiceUIConfigTargetItemFilteredNodes
 *   |TMicroServiceUIConfigTargetItemTextOrUrl
 *   |TMicroServiceUIConfigTargetItemView
 *   |TMicroServiceUIConfigTargetItemFiles
 * } TMicroServiceUIConfigTargetItem
 */
const MicroServiceUIConfigTargetItemTypes = [
  PropTypes.shape(MicroServiceUIConfigTargetItemNode),
  PropTypes.shape(MicroServiceUIConfigTargetItemFilteredNodes),
  PropTypes.shape(MicroServiceUIConfigTargetItemTextOrUrl),
  PropTypes.shape(MicroServiceUIConfigTargetItemView),
  PropTypes.shape(MicroServiceUIConfigTargetItemFiles),
];

/**
 * @description 作用对象配置
 * @typedef {Object} TMicroServiceUIConfigTarget
 * @property {Object} [formLayout] - 表单布局配置对象，同JSX
 * @property {string} label - 目标配置标题，如：中心节点
 * @property {('node'|'filteredNodes'|'textOrUrl'|'view')} [default] - 默认目标选项
 * @property {TMicroServiceUIConfigTargetItem[]} items - 目标选项列表
 */
export const MicroServiceUIConfigTarget = {
  formLayout: PropTypes.object,
  label: PropTypes.string.isRequired,
  default: PropTypes.oneOf(['node', 'filteredNodes', 'textOrUrl', 'view']),
  items: PropTypes.arrayOf(PropTypes.oneOfType(MicroServiceUIConfigTargetItemTypes)),
};

/**
 * @description 参数类型
 * @typedef {('radioGroup'|'duration'|'location'|'checkboxGroup'|'textInput')} TMicroServiceUIConfigParameterType
 */
const MicroServiceUIConfigParameterTypeValues = ['radioGroup', 'duration', 'location', 'checkboxGroup', 'textInput'];

/**
 * @description 参数抽象配置
 * @typedef {Object} TAbstractMicroServiceUIConfigParameterItem
 * @property {string} name - 参数名
 * @property {TMicroServiceUIConfigParameterType} type - 参数类型
 * @property {string} label - 参数标题，如：请输入一个关键词，名称包含该词的节点都会被计算
 */
const AbstractMicroServiceUIConfigParameterItem = {
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(MicroServiceUIConfigParameterTypeValues).isRequired,
  label: PropTypes.string.isRequired,
};

/**
 * @description 参数选项配置
 * @typedef {Object} TMicroServiceUIConfigParameterItemOptionItem
 * @property {string} label - 选项标题，如：名称与目标节点的名称相似
 * @property {string} [shortText] - 选项概要文本，如：名称相似
 * @property {string|number} value - 选项值
 */
const MicroServiceUIConfigParameterItemOptionItem = {
  label: PropTypes.string.isRequired,
  shortText: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

/**
 * @description 单选组参数配置
 * @typedef {TAbstractMicroServiceUIConfigParameterItem} TMicroServiceUIConfigParameterRadioGroup
 * @property {'radioGroup'} type - 参数类型
 * @property {string|number} [default] - 默认参数值，默认选择第一个选项
 * @property {number} [optionSpan] - 每个选项占据的宽度，总宽度为24，默认为24
 * @property {TMicroServiceUIConfigParameterItemOptionItem[]} options - 选项列表
 */
export const MicroServiceUIConfigParameterRadioGroup = {
  ...AbstractMicroServiceUIConfigParameterItem,
  type: PropTypes.oneOf(['radioGroup']),
  default: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  optionSpan: PropTypes.number,
  options: PropTypes.arrayOf(PropTypes.shape(MicroServiceUIConfigParameterItemOptionItem)),
};

/**
 * @description 多选组参数配置
 * @typedef {TAbstractMicroServiceUIConfigParameterItem} TMicroServiceUIConfigParameterCheckboxGroup
 * @property {'checkboxGroup'} type - 参数类型
 * @property {boolean} [required] - 是否必须选择至少一个
 * @property {string[]|number[]} [default] - 默认参数值，默认为空
 * @property {number} [optionSpan] - 每个选项占据的宽度，总宽度为24，默认为24
 * @property {TMicroServiceUIConfigParameterItemOptionItem[]} options - 选项列表
 */
export const MicroServiceUIConfigParameterCheckboxGroup = {
  ...AbstractMicroServiceUIConfigParameterItem,
  type: PropTypes.oneOf(['checkboxGroup']),
  required: PropTypes.bool,
  default: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  optionSpan: PropTypes.number,
  options: PropTypes.arrayOf(PropTypes.shape(MicroServiceUIConfigParameterItemOptionItem)),
};

/**
 * @description 时间选项类型
 * @typedef {(
 *   'this_month'
 *   |'this_quarter'
 *   |'last_month'
 *   |'last_quarter'
 *   |'this_half_of_year'
 *   |'first_half_of_year'
 *   |'second_half_of_year'
 *   |'this_year'
 *   |'custom'
 * )} TMicroServiceUIConfigParameterDurationType
 */
const MicroServiceUIConfigParameterDurationTypeValues = [
  'this_month',
  'this_quarter',
  'last_month',
  'last_quarter',
  'this_half_of_year',
  'first_half_of_year',
  'second_half_of_year',
  'this_year',
  'custom',
];

/**
 * @description 时间范围参数配置
 * @typedef {TAbstractMicroServiceUIConfigParameterItem} TMicroServiceUIConfigParameterDuration
 * @property {'duration'} type - 参数类型
 * @property {TMicroServiceUIConfigParameterDurationType} [default] - 默认参数值，默认选择第一个选项
 * @property {number} [optionSpan] - 每个选项占据的宽度，总宽度为24，默认为6
 * @property {TMicroServiceUIConfigParameterDurationType[]} enabledOptions - 可选选项
 */
export const MicroServiceUIConfigParameterDuration = {
  ...AbstractMicroServiceUIConfigParameterItem,
  type: PropTypes.oneOf(['duration']),
  default: PropTypes.oneOf(MicroServiceUIConfigParameterDurationTypeValues),
  optionSpan: PropTypes.number,
  enabledOptions: PropTypes.arrayOf(PropTypes.oneOf(MicroServiceUIConfigParameterDurationTypeValues)).isRequired,
};

/**
 * @description 地理位置参数配置
 * @typedef {TAbstractMicroServiceUIConfigParameterItem} TMicroServiceUIConfigParameterLocation
 * @property {'location'} type - 参数类型
 * @property {string[]} [default] - 默认参数值，默认选择北京
 * @property {number} [optionSpan] - 选择框占据的宽度，总宽度为24，默认为18
 */
export const MicroServiceUIConfigParameterLocation = {
  ...AbstractMicroServiceUIConfigParameterItem,
  type: PropTypes.oneOf(['location']),
  default: PropTypes.arrayOf(PropTypes.string),
  optionSpan: PropTypes.number,
};

/**
 * @description 纯文本参数配置
 * @typedef {TAbstractMicroServiceUIConfigParameterItem} TMicroServiceUIConfigParameterTextInput
 * @property {'textInput'} type - 参数类型
 * @property {string} [default] - 默认参数值，默认为空
 */
export const MicroServiceUIConfigParameterTextInput = {
  ...AbstractMicroServiceUIConfigParameterItem,
  type: PropTypes.oneOf(['textInput']),
  default: PropTypes.string,
};

/**
 * @description 参数项配置
 * @typedef {
 *   TMicroServiceUIConfigParameterRadioGroup
 *   |TMicroServiceUIConfigParameterCheckboxGroup
 *   |TMicroServiceUIConfigParameterDuration
 *   |TMicroServiceUIConfigParameterLocation
 *   |TMicroServiceUIConfigParameterTextInput
 * } TMicroServiceUIConfigParameterItem
 */
const MicroServiceUIConfigParameterItemTypes = [
  PropTypes.shape(MicroServiceUIConfigParameterRadioGroup),
  PropTypes.shape(MicroServiceUIConfigParameterCheckboxGroup),
  PropTypes.shape(MicroServiceUIConfigParameterDuration),
  PropTypes.shape(MicroServiceUIConfigParameterLocation),
  PropTypes.shape(MicroServiceUIConfigParameterTextInput),
];

/**
 * @description 参数配置
 * @typedef {Object} TMicroServiceUIConfigParameter
 * @property {Object} [formLayout] - 表单布局配置对象，同JSX
 * @property {TMicroServiceUIConfigParameterItem[]} items - 参数项配置列表
 */
export const MicroServiceUIConfigParameter = {
  formLayout: PropTypes.object,
  items: PropTypes.arrayOf(PropTypes.oneOfType(MicroServiceUIConfigParameterItemTypes)).isRequired,
};

/**
 * @description 返回节点分类配置
 * @typedef {Object} TMicroServiceUIConfigOperationResultNodeCategoryItem
 * @property {string} text - 展示名称
 * @property {string|number} value - 该分类对应值，用过分类配置的path取出后比对判断
 */
const MicroServiceUIConfigOperationResultNodeCategoryItem = {
  text: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
};

/**
 * @description 返回节点分类配置
 * @typedef {Object} TMicroServiceUIConfigOperationResultNodeCategory
 * @property {string} label - 展示名称
 * @property {string} path - 分类值所在路径，按lodash库的规范填写，如：$.meta.semantic_type
 * @property {TMicroServiceUIConfigOperationResultNodeCategoryItem[]} items - 分类项列表
 */
const MicroServiceUIConfigOperationResultNodeCategory = {
  label: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape(MicroServiceUIConfigOperationResultNodeCategoryItem)),
};

/**
 * @description 返回节点排序配置
 * @typedef {Object} TMicroServiceUIConfigOperationResultNodeOrder
 * @property {string} label - 展示名称
 * @property {string} path - 分类值值所在路径，按lodash库的规范填写，如：$.meta.semantic_type
 * @property {('time'|'number'|'string'|'custom')} compareFnType - 比较函数类型
 * @property {string} [compareFn] - 比较函数，一段JS方法源码，执行后需返回1、-1或0
 * @property {('ASC'|'DESC')} [orderType] - 排序方式
 */
const MicroServiceUIConfigOperationResultNodeOrder = {
  label: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  compareFnType: PropTypes.oneOf(['time', 'number', 'string', 'custom']).isRequired,
  compareFn: PropTypes.func,
  orderType: PropTypes.oneOf(['ASC', 'DESC']),
};

/**
 * @description 保存节点操作配置
 * @typedef {Object} TMicroServiceUIConfigOperationSaveNodes
 * @property {'saveNodes'} type - 操作类型
 * @property {('auto'|'dash'|'solid')} [lineType] - 连线类型，实线、虚线或自动（由返回的数据确定），默认：auto
 * @property {('auto'|'straight'|'curve')} [lineStyle] - 连线样式，曲线、直线或自动（由返回的数据确定），默认：auto
 * @property {('star'|'link')} [arrangeBy] - 布局方式，星型或链式，默认：star
 * @property {('none'|string)} [classifyBy] - 归类方式，无或配置直接指定，指定后将归类成一棵树再挂接到原节点，默认：none
 * @property {TMicroServiceUIConfigOperationResultNodeCategory[]} [categories] - 节点分类配置
 * @property {TMicroServiceUIConfigOperationResultNodeOrder[]} [orders] - 节点排序配置
 * @property {string|false} [copyTextKey] - 要复制的文本所在路径，按lodash库的规范填写，默认：fname
 */
export const MicroServiceUIConfigOperationSaveNodes = {
  type: PropTypes.oneOf(['saveNodes']).isRequired,
  lineType: PropTypes.oneOf(['auto', 'dash', 'solid']),
  lineStyle: PropTypes.oneOf(['auto', 'straight', 'curve']),
  arrangeBy: PropTypes.oneOf(['star', 'link']),
  classifyBy: PropTypes.oneOfType([
    PropTypes.oneOf(['none']),
    PropTypes.string,
  ]),
  categories: PropTypes.arrayOf(PropTypes.shape(MicroServiceUIConfigOperationResultNodeCategory)),
  orders: PropTypes.arrayOf(PropTypes.shape(MicroServiceUIConfigOperationResultNodeOrder)),
  copyTextKey: PropTypes.oneOfType([PropTypes.oneOf([false]), PropTypes.string]),
};

/**
 * @description 保存关联操作配置
 * @typedef {Object} TMicroServiceUIConfigOperationSaveEdges
 * @property {'saveEdges'} type - 操作类型
 * @property {('auto'|'dash'|'solid')} [lineType] - 连线类型，实线、虚线或由用户选择，默认：auto
 * @property {('auto'|'straight'|'curve')} [lineStyle] - 连线样式，曲线、直线或自动（由返回的数据确定），默认：auto
 * @property {string|false} [copyTextKey] - 要复制的文本所在路径，按lodash库的规范填写，默认：fname
 */
export const MicroServiceUIConfigOperationSaveEdges = {
  type: PropTypes.oneOf(['saveEdges']).isRequired,
  lineType: PropTypes.oneOf(['auto', 'dash', 'solid']),
  lineStyle: PropTypes.oneOf(['auto', 'straight', 'curve']),
  copyTextKey: PropTypes.oneOfType([PropTypes.oneOf([false]), PropTypes.string]),
};

/**
 * @description 保存子图操作配置
 * @typedef {Object} TMicroServiceUIConfigOperationSaveSubGraphs
 * @property {'saveSubGraphs'} type - 操作类型
 * @property {('auto'|'dash'|'solid')} [lineType] - 子图根节点与原节点连线类型，实线、虚线或自动（由返回的数据确定），默认：auto
 * @property {('auto'|'straight'|'curve')} [lineStyle] - 子图根节点与原节点连线样式，曲线、直线或自动（由返回的数据确定），默认：auto
 * @property {('auto'|'dash'|'solid')} [innerLineType] - 子图内部节点连线类型，实线、虚线或自动（由返回的数据确定），默认：auto
 * @property {('auto'|'straight'|'curve')} [innerLineStyle] - 子图内部节点连线样式，曲线、直线或自动（由返回的数据确定），默认：auto
 * @property {('star'|'link')} [arrangeBy] - 子图根节点与原节点连接布局方式，星型或链式，默认：star
 * @property {('none'|string)} [classifyBy] - 归类方式，无或配置直接指定，指定后将子图根节点归类成一棵树再挂接到原节点，默认：none
 * @property {TMicroServiceUIConfigOperationResultNodeCategory[]} [categories] - 节点分类配置
 * @property {TMicroServiceUIConfigOperationResultNodeOrder[]} [orders] - 节点排序配置
 * @property {string|false} [copyTextKey] - 要复制的文本所在路径，按lodash库的规范填写，默认：fname
 */
export const MicroServiceUIConfigOperationSaveSubGraphs = {
  type: PropTypes.oneOf(['saveSubGraphs']).isRequired,
  lineType: PropTypes.oneOf(['auto', 'dash', 'solid']),
  lineStyle: PropTypes.oneOf(['auto', 'straight', 'curve']),
  innerLineType: PropTypes.oneOf(['auto', 'dash', 'solid']),
  innerLineStyle: PropTypes.oneOf(['auto', 'straight', 'curve']),
  arrangeBy: PropTypes.oneOf(['star', 'link']),
  classifyBy: PropTypes.oneOfType([
    PropTypes.oneOf(['none']),
    PropTypes.string,
  ]),
  categories: PropTypes.arrayOf(PropTypes.shape(MicroServiceUIConfigOperationResultNodeCategory)),
  orders: PropTypes.arrayOf(PropTypes.shape(MicroServiceUIConfigOperationResultNodeOrder)),
  copyTextKey: PropTypes.oneOfType([PropTypes.oneOf([false]), PropTypes.string]),
};

/**
 * @description 可保存的节点内容类型
 * @typedef {('icon'|'description'|'url'|'tag'|'position')} TMicroServiceUIConfigOperationResultContentPart
 */
const MicroServiceUIConfigOperationResultContentPartValues = ['icon', 'description', 'url', 'tag', 'position'];

/**
 * @description 保存节点内容操作配置
 * @typedef {Object} TMicroServiceUIConfigOperationSaveContent
 * @property {'saveContent'} type - 操作类型
 * @property {(TMicroServiceUIConfigOperationResultContentPart[]|'all')} [contentParts] - 可保存的节点内容类型
 * @property {string|false} [copyTextKey] - 要复制的文本所在路径，按lodash库的规范填写，默认：fname
 */
export const MicroServiceUIConfigOperationSaveContent = {
  type: PropTypes.oneOf(['saveContent']).isRequired,
  contentParts: PropTypes.oneOf([...MicroServiceUIConfigOperationResultContentPartValues, 'all']),
  copyTextKey: PropTypes.oneOfType([PropTypes.oneOf([false]), PropTypes.string]),
};

/**
 * @description 展示词云操作配置
 * @typedef {Object} TMicroServiceUIConfigOperationShowWordCloud
 * @property {'showWordCloud'} type - 操作类型
 * @property {string} [kvLabel] - K-V类型数据在树节点中展示的标题
 * @property {string|false} [copyTextKey] - 要复制的文本所在路径，按lodash库的规范填写，默认：fname
 */
export const MicroServiceUIConfigOperationShowWordCloud = {
  type: PropTypes.oneOf(['showWordCloud']).isRequired,
  kvLabel: PropTypes.string,
  copyTextKey: PropTypes.oneOfType([PropTypes.oneOf([false]), PropTypes.string]),
};

/**
 * @description 保存文件操作配置
 * @typedef {Object} TMicroServiceUIConfigOperationSaveFiles
 * @property {'saveFiles'} type - 操作类型
 * @property {string|false} [copyTextKey] - 要复制的文本所在路径，按lodash库的规范填写，默认：fname
 */
export const MicroServiceUIConfigOperationSaveFiles = {
  type: PropTypes.oneOf(['saveFiles']).isRequired,
  copyTextKey: PropTypes.oneOfType([PropTypes.oneOf([false]), PropTypes.string]),
};

/**
 * @description 展示图表操作配置
 * @typedef {Object} TMicroServiceUIConfigOperationShowCharts
 * @property {'showCharts'} type - 操作类型
 * @property {string} [kvLabel] - K-V类型数据在树节点中展示的标题
 * @property {string|false} [copyTextKey] - 要复制的文本所在路径，按lodash库的规范填写，默认：fname
 */
export const MicroServiceUIConfigOperationShowCharts = {
  type: PropTypes.oneOf(['showCharts']).isRequired,
  chartUrl: PropTypes.string,
  copyTextKey: PropTypes.oneOfType([PropTypes.oneOf([false]), PropTypes.string]),
};

/**
 * @description 操作配置
 * @typedef {
 *   TMicroServiceUIConfigOperationSaveNodes
 *   |TMicroServiceUIConfigOperationSaveEdges
 *   |TMicroServiceUIConfigOperationSaveSubGraphs
 *   |TMicroServiceUIConfigOperationSaveContent
 *   |TMicroServiceUIConfigOperationShowWordCloud
 *   |TMicroServiceUIConfigOperationSaveFiles
 *   |TMicroServiceUIConfigOperationShowCharts
 * } TMicroServiceUIConfigOperationItem
 */
export const MicroServiceUIConfigOperationItemTypes = [
  PropTypes.shape(MicroServiceUIConfigOperationSaveNodes),
  PropTypes.shape(MicroServiceUIConfigOperationSaveEdges),
  PropTypes.shape(MicroServiceUIConfigOperationSaveSubGraphs),
  PropTypes.shape(MicroServiceUIConfigOperationSaveContent),
  PropTypes.shape(MicroServiceUIConfigOperationShowWordCloud),
  PropTypes.shape(MicroServiceUIConfigOperationSaveFiles),
  PropTypes.shape(MicroServiceUIConfigOperationShowCharts),
];

/**
 * @description 微服务UI主要配置
 * @typedef {Object} TMicroServiceUIConfig
 * @property {string} [version] - 版本号
 * @property {TMicroServiceUIConfigRequest} request - 请求参数设置
 * @property {TMicroServiceUIConfigTarget} target - 目标配置
 * @property {TMicroServiceUIConfigParameter} parameter - 参数配置
 * @property {TMicroServiceUIConfigOperationItem[]} operations - 操作配置
 * @version 2.0.0
 */
export const MicroServiceUIConfig = {
  version: PropTypes.string,
  request: PropTypes.shape(MicroServiceUIConfigRequest).isRequired,
  target: PropTypes.shape(MicroServiceUIConfigTarget).isRequired,
  parameter: PropTypes.shape(MicroServiceUIConfigParameter).isRequired,
  operations: PropTypes.arrayOf(PropTypes.oneOfType(MicroServiceUIConfigOperationItemTypes)).isRequired,
};

export const DEFAULT_COPY_TEXT_KEY = 'fname';

export const DEFAULT_KV_LABEL = '数值';

/**
 * @description 带ID数据的微服务前端配置
 * @typedef {TMicroServiceUIConfig} TMicroServiceUIConfigWithId
 * @property {string} id - 微服务ID
 */