import React from "react";
import PropTypes from "prop-types";
import { Tooltip } from "antd";

import PB, { SimplePB } from "@/libs/simplePB";

import NodeDataProvider from "@/components/common/dataProvider/common.dataProvider.node";
import DigitConvertToIcons from "@/components/common/common.digitConvertToIcons";
import badgeInfoStyle from "@/style/components/mainView/main.badgeInfo.less";
import { IconTypes } from "@/constants/common";
import Icon from "@/components/common/common.icon";

class NodeTodoAccountActions extends React.PureComponent {
  state = {
    todoAmount: 0,
    totalAmount: 0,
  };

  componentDidMount() {
    let me = this;

    me.props.bus.sub(
      me,
      "node_todo",
      ["todo.created", "todo.updated", "todo.patched", "todo.completed"],
      me.refresh
    );

    me.refresh();
  }

  componentWillUnmount() {
    let me = this;

    me.props.bus.remove(me);
  }

  refresh = () => {
    let me = this,
      conditions = {};

    conditions["related_to"] = me.props.userId;
    conditions["status"] = 1;
    conditions["limit"] = 0;
    conditions["start"] = 0;
    NodeDataProvider.loadNodeTaskList(conditions).then(({ total }) =>
      me.setState({ todoAmount: total })
    );
  };

  render() {
    let me = this;
    const { viewId } = this.props;

    return (
      <Tooltip title={"我的待办"}>
        <div
          className={`${badgeInfoStyle["badge-more-view"]} ${badgeInfoStyle["account-action"]}`}
          onClick={() => {
            // me.props.bus.emit('node_task', 'listModal.do_show', {type: 'my'});
            let v = "";
            if (viewId) {
              v = "?view_id=" + viewId;
            }
            window.open(`/todo${v}`);
          }}
        >
          <Icon
            name={"icon-todo-list"}
            type={IconTypes.ICON_FONT}
            className={`${badgeInfoStyle["icon"]}`}
          />
          <DigitConvertToIcons digit={me.state.todoAmount} />
        </div>
      </Tooltip>
    );
  }
}

NodeTodoAccountActions.defaultProps = {
  bus: PB,
};

NodeTodoAccountActions.propTypes = {
  bus: PropTypes.instanceOf(SimplePB),
  userId: PropTypes.number.isRequired,
  viewId: PropTypes.string.isRequired,
};

export default NodeTodoAccountActions;
