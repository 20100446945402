import React from 'react';
import PropTypes from 'prop-types';

import PB, {SimplePB} from '@/libs/simplePB';

import ViewDataProvider from '@/components/common/dataProvider/common.dataProvider.view';

import { NODE_TYPE_TEXT } from '@/constants/nodeConfig';
import { getNodeDisplayTitle, getNodeIcon } from '@/constants/vis.defaultDefine.1';
import Icon from '@/components/common/common.icon';
import { message, Tooltip } from 'antd';
import style from '@/style/common/node/common.node.explore.overrideAndRelatedNode.less';
import { IconTypes } from '@/constants/common';
import UserAvatar from 'react-user-avatar';

class ExploreRecommendPanel extends React.PureComponent {
  relatedSenderId = `node-explore-related-node-panel-${Math.random()}`;

  state = {
    relatedNodes: undefined, // 推荐填充可选择的节点列表
    relatedStatus: 'idle',
  };

  addNodeMessageKeys = {};
  undoMessageKeys = {};

  pageSize = 5;

  /**
   * 用户触发添加关联节点操作
   *
   * @param {string} nodeId 节点ID
   * @param {object} relatedNodeInfo 要添加的关联节点信息
   * @param {string} senderId 发送者标识
   */
  onAddRelatedNodeInfo = (nodeId, relatedNodeInfo, senderId) => {
    let me = this;

    senderId = senderId || me.relatedSenderId;

    let linkNode = {
      description: relatedNodeInfo.description,
      fname: relatedNodeInfo.fname,
      label: relatedNodeInfo.label || relatedNodeInfo.fname,
      lev: relatedNodeInfo.lev || 'gradeD',
      meta: relatedNodeInfo.meta,
      tag: relatedNodeInfo.tag,
      tags: relatedNodeInfo.tags,
      type: NODE_TYPE_TEXT,
      url: relatedNodeInfo.url,
      userPreferredType: relatedNodeInfo.userPreferredType || NODE_TYPE_TEXT,
      owner: 0,
    };
    me.onAddRelatedNode(nodeId, linkNode, senderId);
  };

  onAddRelatedNode = (nodeId, relatedNodeInfo = {}, senderId = '') => {
    let me = this, dataToSave;

    if (!me.props.viewDataProvider) {
      me.props.bus.emit('node', 'explore.related_node.failed_to_add_related_node', ({code: 500, msg: '组件缺失', nodeId, senderId}));
      return;
    }

    dataToSave = {
      ...relatedNodeInfo,
      userConfirmed: true,
      forceAdd: true,
    };
    me.props.bus.emit('node', 'explore.related_node.on_add_related_node', {nodeId, dataToSave, senderId});
    me.props.viewDataProvider.addNodeLinkedTo(
      [dataToSave],
      nodeId,
      nodeId,
      false,
      senderId,
      false
    ).then(relation => {
      me.props.bus.emit('node', 'explore.related_node.add_related_node_succeeded', {nodeId, relation, senderId});
    }).catch(({msg, code}) => {
      me.props.bus.emit('node', 'explore.related_node.failed_to_add_related_node', {code, msg, nodeId, senderId});
    });
  };

  /* 推荐节点列表渲染 */
  renderNodesList = (nodesList = [], senderId) => {
    let me = this;

    return (
      <React.Fragment>
        {
          nodesList.length > 0 ? (
            <React.Fragment>
              <div className={`${style['extra-properties-list']}`}>
                {
                  nodesList.map((node, index) => {
                    const {name, type, color} = getNodeIcon(node);
                    return (
                      <div key={`${type}-item-${index}`}
                           className={`${style['extra-properties-item']} ${style['related-node']} `}
                           onClick={e => {
                             e.stopPropagation();
                             me.onAddRelatedNodeInfo(me.props.nodeId, node, senderId);
                           }}
                      >
                        <Tooltip
                          title={<div className={style['extra-properties-tooltip']}
                                      onClick={e => {
                                        e.stopPropagation();
                                        me.onAddRelatedNodeInfo(me.props.nodeId, node, senderId);
                                      }}
                          >
                            <div className={`${style['text-title']} ${node.meta && node.meta.iconData ? style['user-avatar'] : ''}`}>
                              <span className={style['text-icon']}>{
                                node.meta && node.meta.iconData ? (
                                  <UserAvatar
                                    name={node.fname}
                                    size={40}
                                    src={node.meta.iconData}
                                    style={{margin: '0 .5em .3rem 0'}}
                                  />
                                ) : (
                                  type && name ? (
                                    <Icon name={name} type={type} color={color} style={{marginRight: '0.5em'}}/>
                                  ) : (
                                    <Icon name={'icon-yuandianda'} type={IconTypes.ICON_FONT} color={color} style={{marginRight: '0.5em'}}/>
                                  )
                                )
                              }</span>
                              <span className={style['text-name']}>{getNodeDisplayTitle(node)}</span>
                            </div>
                            <div className={`${style['text-desc']} `}>
                              描述：{node.meta && node.meta.description ? node.meta.description : '--'}
                            </div>
                            <div className={style['text-url']}>
                              外链：{node.meta && node.meta.url ? node.meta.url : '--'}
                            </div>
                          </div>}
                          placement={'right'}
                        >
                          <div
                            style={{display: 'flex', alignItems: 'center'}}
                          >
                            <Icon name={`icon-related-node`} type={IconTypes.ICON_FONT} className={style['extra-add']}/>
                            {
                              type && name ? (
                                <Icon name={name} type={type} color={color} style={{marginRight: '0.5em'}}/>
                              ) : (
                                <Icon name={'icon-yuandianda'} type={IconTypes.ICON_FONT} color={color} style={{marginRight: '0.5em'}}/>
                              )
                            }
                            <span className={`${style['name']}`} style={
                              node.description || (node.meta && node.meta.description) || node.url || (node.meta && node.meta.url) ? null : {maxWidth: '13rem'}
                            }>{node.fname}</span>
                            {
                              node.description || (node.meta && node.meta.description) ? (
                                <span className={style['extra-info-box']}>
                                            （{<span className={style['extra-info']}>{node.description || (node.meta && node.meta.description) || ''}</span>}）
                                          </span>
                              ) : node.url || (node.meta && node.meta.url) ? (
                                <span className={style['extra-info-box']}>
                                            （{<span className={style['extra-info']}>{node.url || (node.meta && node.meta.url) || ''}</span>}）
                                          </span>
                              ) : null
                            }
                          </div>
                        </Tooltip>
                      </div>
                    )
                  })
                }
              </div>
            </React.Fragment>
          ) : (
            me.renderNodesEmpty()
          )
        }
      </React.Fragment>
    )
  };

  // 内容为空时，显示更多功能提示
  renderNodesEmpty = () => {
    return (
      <div className={`${style['extra-properties-list']}`}>
        <div className={`${style['extra-properties-item']} ${style['tips-more']}`}>
          <span>（暂无内容，请稍后再试）</span>
        </div>
      </div>
    )
  };

  // 撤销新增节点
  undoAddRelatedNode = (nodeId, contentText, relation) => {
    let me = this, removeFn = () => {
      // 停用按钮
      me.props.bus.emit('aiConsole', 'message.update', {
        key: me.addNodeMessageKeys[nodeId],
        content: (
          <span>
            {contentText}
            <span style={{marginLeft: '0.5em', opacity: 0.6}}>撤销</span>
          </span>
        ),
      });
      me.props.bus.emit('aiConsole', 'message.push', {
        type: 'user',
        content: `撤销新增节点`,
      });
      me.props.bus.emit('aiConsole', 'message.push', {
        type: 'ai',
        content: '正常处理...',
        callback: ({key}) => me.undoMessageKeys[nodeId] = key,
        delay: 200,
      });
      me.props.viewDataProvider.removeRelationGraph(
        relation['nodes'].map(node => node.id),
        relation['edges'].map(edge => ({from: edge.from, to: edge.to}))
      ).then(() => {
        me.props.bus.emit('aiConsole', 'message.update', {
          key: me.undoMessageKeys[nodeId],
          content: '撤销成功',
        });
      }).catch(() => {
        me.props.bus.emit('aiConsole', 'message.update', {
          key: me.undoMessageKeys[nodeId],
          content: '撤销失败',
        });
        me.props.bus.emit('aiConsole', 'message.update', {
          key: me.addNodeMessageKeys[nodeId],
          content: (
            <span>
              {contentText}
              <a
                style={{marginLeft: '0.5em'}}
                className={'plain-action'}
                onClick={e => {
                  e.stopPropagation();
                  removeFn();
                }}
              >撤销</a>
            </span>
          ),
        });
      });
    };
    removeFn();
  };

  componentDidMount() {
    let me = this;

    me.props.bus.with(me).subscribe('node', 'explore.related_node.on_add_related_node', ({nodeId, dataToSave, senderId}) => {
      if (me.relatedSenderId === senderId) {
        me.props.bus.emit('aiConsole', 'message.push', {
          type: 'user',
          content: `添加节点 "${getNodeDisplayTitle(dataToSave, 12)}" 并关联至 "${getNodeDisplayTitle(me.props.nodeInfo, 12)}"`,
        });
        me.props.bus.emit('aiConsole', 'message.push', {
          type: 'ai',
          content: (<span><Icon name={'loading'} style={{marginRight: '0.5em'}}/>请稍后...</span>),
          callback: ({key}) => {
            if (me.addNodeMessageKeys[nodeId]) {
              // 清理上一次显示的信息
              PB.emit('aiConsole', 'message.update', {
                key: me.addNodeMessageKeys[nodeId],
                content: (
                  <span>
                    操作成功
                    <span style={{marginLeft: '0.5em', opacity: 0.6}}>撤销</span>
                  </span>
                ),
              });
            }
            me.addNodeMessageKeys[nodeId] = key;
          },
          delay: 200,
        });
      }
    }).subscribe('node', 'explore.related_node.add_related_node_succeeded', ({nodeId, relation, senderId}) => {
      if (me.relatedSenderId === senderId) {
        let addNodeInfo = relation['nodes'][0]; // 推荐连接时，只能添加一个节点，因此[0]
        me.props.bus.emit('aiConsole', 'message.update', {
          key: me.addNodeMessageKeys[nodeId],
          content: (
            <span>
                操作成功
                <a
                  style={{marginLeft: '0.5em'}}
                  className={'plain-action'}
                  onClick={ e => {
                    e.stopPropagation();
                    me.undoAddRelatedNode(nodeId, '操作成功', relation);
                  }}
                >撤销</a>
              </span>
          ),
        });
        setTimeout(() => {
          message.success(
            <span>
                <span>新节点</span>
                <span className={style["node-in-msg"]}>{getNodeDisplayTitle(addNodeInfo)}</span>
                <span>添加成功</span>
              </span>
          );
        }, 200);
      }
    }).subscribe('node', 'explore.related_node.failed_to_add_related_node', ({/*code, msg, */nodeId, senderId}) => {
      if (me.relatedSenderId === senderId) {
        setTimeout(() => {
          message.error('添加节点失败！');
        }, 200);
        me.props.bus.emit('aiConsole', 'message.update', {key: me.addNodeMessageKeys[nodeId], content: `操作失败`});
      }
    });
  }

  componentWillUnmount() {
    this.props.bus.remove(this);
  }

  render() {
    let me = this;
    return (
      <React.Fragment>
        <div className={`${style['frame']} visible dark-theme`}>
          {/* 内容展示 */}
          {/* 推荐连接 */}
          <div className={style['extra-properties-title']}>
            <Icon name={'icon-related-node'} type={IconTypes.ICON_FONT} style={{marginRight: '0.5em'}}/>
            推荐连接：
          </div>
          {me.renderNodesList(me.props.relatedNodeList, me.relatedSenderId)}
        </div>
      </React.Fragment>
    );
  }
}

ExploreRecommendPanel.defaultProps = {
  bus: PB,
};

ExploreRecommendPanel.propTypes = {
  nodeId: PropTypes.string,
  nodeInfo: PropTypes.object,
  relatedNodeList: PropTypes.array,
  viewDataProvider: PropTypes.instanceOf(ViewDataProvider),
  bus: PropTypes.instanceOf(SimplePB),
};

export default ExploreRecommendPanel;