import React from 'react';
import PropTypes from 'prop-types';
import {Button, Modal, Table, Col, Row} from 'antd';
import _ from 'lodash';

import PB, {SimplePB} from '@/libs/simplePB';

import ViewDataProvider from '@/components/common/dataProvider/common.dataProvider.view';
import ViewManager from '@/components/common/common.viewManager';

import style from '@/style/common/view/common.view.teamwork.ticket.less';
import TimeDisplayField from "@/components/common/common.timeDisplayField";
import {getNodeDisplayTitle} from "@/constants/vis.defaultDefine.1";
import TeamworkTicketDetailModal from "@/components/common/view/teamwork/common.view.teamwork.ticketDetailModal";
import TeamworkTicketBatchAddModal from "@/components/common/view/teamwork/common.view.teamwork.ticketBatchAddModal";

class TeamworkTicketListModal extends React.Component {
  state = {
    processing: false,
    ticketList: [],
    pagination: {
      current: 1,
      pageSize: 30,
    },
    sorter: {
      field: undefined,
      order: undefined,
    },

    showTicketDetailModal: false,
    ticketDetail: undefined,
    showBatchAddTicketsModal: false,
  }

  fetch = (pagination, forceReload) => {
    let me = this;

    if (!pagination) {
      pagination = {
        start: (me.state.pagination.current - 1) * me.state.pagination.pageSize,
        limit: me.state.pagination.pageSize,
        orderBy: _.snakeCase(me.state.sorter.field),
        orderType: me.state.sorter.order,
      };
    }

    me.setState({processing: true}, () => {
      me.props.bus.emit('teamwork', 'ticket.list.do', {
        viewId: me.props.viewDataProvider.viewId,
        parameters: {
          ...pagination,
        },
        forceReload: forceReload === true,
      });
    });
  };

  getColumns = () => {
    let me = this;

    return [{
      title: '唯一码',
      dataIndex: 'code',
    }, {
      title: '更新时间',
      dataIndex: 'updateTimestamp',
      render(updateTimestamp) {
        return (
          <TimeDisplayField timestamp={(new Date(updateTimestamp)).getTime()} />
        );
      }
    }, {
      title: '使用时间',
      dataIndex: 'useAtTimestamp',
      render(useAtTimestamp) {
        return useAtTimestamp ? (
          <TimeDisplayField timestamp={(new Date(useAtTimestamp)).getTime()} />
        ) : undefined;
      }
    }, {
      title: '相连节点',
      dataIndex: 'extraInformation',
      render(extraInformation) {
        if (extraInformation && extraInformation['afterUse']) {
          let nodeIds = [];
          extraInformation['afterUse'].forEach(item => {
            if (item['action'] === 'transfer_owner') {
              nodeIds.push(item['nodeId']);
            }
          });
          if (nodeIds && nodeIds.length > 0) {
            let node = me.props.viewDataProvider.getNode(nodeIds[0]);
            if (!node) {
              return '!!!节点已被删除!!!';
            } else {
              return (
                <a onClick={() => me.props.bus.emit('network', 'node.on_edit', {node, id: node.id})}>
                  {getNodeDisplayTitle(node)}
                </a>
              );
            }
          } else {
            return '无';
          }
        }
      }
    }, {
      title: '操作',
      dataIndex: 'code',
      key: 'actions',
      render(code, record) {
        return (
          <span>
            <a onClick={() => me.onShowTicket(code, record)}>查看</a>
            &nbsp;|&nbsp;
            <a onClick={() => me.onRemoveTicket(code)}>删除</a>
          </span>
        );
      }
    }];
  };

  handleTableChange = (pagination, filters, sorter) => {
    let me = this;

    const pager = { ...me.state.pagination };
    pager.current = pagination.current;
    me.setState({
      pagination: pager,
      sorter: {...me.state.sorter, ...sorter},
    });
    me.fetch({
      start: (pagination.current - 1) * pagination.pageSize,
      limit: pagination.pageSize,
      orderBy: _.snakeCase(sorter.field || me.state.sorter.field),
      orderType: sorter.order || me.state.sorter.order,
    });
  };

  onAddTicket = () => {
    let me = this;

    me.setState({showTicketDetailModal: true, ticketDetail: undefined});
  };

  onBatchAddTickets = () => {
    let me = this;

    me.setState({showBatchAddTicketsModal: true});
  };

  onShowTicket = (code, ticket) => {
    let me = this;

    me.setState({showTicketDetailModal: true, ticketDetail: ticket});
  };

  onRemoveTicket = (code, confirmed = false) => {
    let me = this;

    if (confirmed) {
      ViewManager.removeViewTeamworkTicket(me.props.viewDataProvider.viewId, code).then(() => {
        me.props.bus.emit('teamwork', 'ticket.list.changed', {viewId: me.props.viewDataProvider.viewId});
      });
    } else {
      Modal.confirm({
        title: '确认？',
        onOk: () => me.onRemoveTicket(code, true),
        okText: '确认删除',
        okType: 'danger',
        cancelText: '取消',
      });
    }
  };

  refresh = () => {
    let me = this;

    me.fetch(undefined, true);
  };

  componentDidMount() {
    let me = this;

    me.props.bus.with(me).subscribe('teamwork', 'ticket.list.success', ({viewId, ticketList, total}) => {
      if (me.props.viewDataProvider.viewId !== viewId) return;
      const pagination = { ...me.state.pagination };
      pagination.total = total;
      me.setState({
        processing: false,
        ticketList,
        pagination,
      });
    }).subscribe('teamwork', 'ticket.list.failed', ({viewId}) => {
      if (me.props.viewDataProvider.viewId !== viewId) return;
      const pagination = { ...me.state.pagination };
      pagination.total = 0;
      me.setState({
        processing: false,
        ticketList: [],
        pagination,
      });
    }).subscribe('teamwork', 'ticket.list.changed', ({viewId}) => {
      if (me.props.viewDataProvider.viewId !== viewId) return;
      me.refresh();
    });

    if (me.props.visible) {
      me.refresh();
    }
  }

  componentWillUnmount() {
    this.props.bus.remove(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let me = this;

    if (!prevProps.visible && me.props.visible) {
      me.setState({
        pagination: {
          current: 1,
          pageSize: 30,
        },
        sorter: {
          field: 'updateTimestamp',
          order: 'desc',
        },
      }, me.refresh);
    }
  }

  render() {
    let me = this;

    return (
      <React.Fragment>
        <Modal
          title={'门票列表'}
          visible={me.props.visible}
          centered={true}
          onCancel={me.props.onClose}
          footer={false}
          width={'70rem'}
        >
          <Row>
            <Col span={4}>
              <Button block={true} onClick={me.onAddTicket}>添加</Button>
            </Col>
            <Col span={4} offset={1}>
              <Button block={true} onClick={me.onBatchAddTickets}>批量添加</Button>
            </Col>
            <Col span={4} offset={1}>
              <Button block={true} onClick={me.refresh}>刷新</Button>
            </Col>
          </Row>
          <Table
            className={`${style['ticket-list-table']} scrollbar-none`}
            columns={me.getColumns()}
            pagination={me.state.pagination}
            rowKey={'code'}
            onChange={me.handleTableChange}
            dataSource={me.state.ticketList}
            scroll={{y: '40vh'}}
          />
        </Modal>
        <TeamworkTicketDetailModal
          viewDataProvider={me.props.viewDataProvider}
          visible={me.state.showTicketDetailModal}
          config={me.state.ticketDetail}
          bus={me.props.bus}
          onClose={() => me.setState({showTicketDetailModal: false, ticketDetail: undefined})}
        />
        <TeamworkTicketBatchAddModal
          visible={me.state.showBatchAddTicketsModal}
          onClose={() => me.setState({showBatchAddTicketsModal: false})}
          viewDataProvider={me.props.viewDataProvider}
          bus={me.props.bus}
        />
      </React.Fragment>
    )
  }
}

TeamworkTicketListModal.defaultProps = {
  bus: PB,
};

TeamworkTicketListModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  viewDataProvider: PropTypes.instanceOf(ViewDataProvider).isRequired,
  bus: PropTypes.instanceOf(SimplePB),
};

export default TeamworkTicketListModal;