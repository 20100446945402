import * as React from 'react';
import * as PropTypes from 'prop-types';
import ReactDiffViewer, {DiffMethod} from 'react-diff-viewer';

import {
  computeLineInformation,
  DiffType,
} from './compute-lines';

class MyDiffViewer extends ReactDiffViewer {
  /**
   * Generates the entire diff view.
   */
  renderDiff = () => {
    const { oldValue, newValue, splitView, disableWordDiff, compareMethod } = this.props;
    const { lineInformation, diffLines } = computeLineInformation(
      oldValue,
      newValue,
      disableWordDiff,
      compareMethod,
    );
    const extraLines = this.props.extraLinesSurroundingDiff < 0
      ? 0
      : this.props.extraLinesSurroundingDiff;
    let skippedLines = [];
    return lineInformation.map(
      (line, i) => {
        const diffBlockStart = diffLines[0];
        const currentPosition = diffBlockStart - i;
        if (this.props.showDiffOnly) {
          if (currentPosition === -extraLines) {
            skippedLines = [];
            diffLines.shift();
          }
          if (
            line.left.type === DiffType.DEFAULT
            && (currentPosition > extraLines
            || typeof diffBlockStart === 'undefined')
            && !this.state.expandedBlocks.includes(diffBlockStart)
          ) {
            skippedLines.push(i + 1);
            if (i === lineInformation.length - 1 && skippedLines.length > 1) {
              // noinspection JSUnresolvedFunction
              return this.renderSkippedLineIndicator(
                skippedLines.length,
                diffBlockStart,
                line.left.lineNumber,
                line.right.lineNumber,
              );
            }
            return null;
          }
        }

        // noinspection JSUnresolvedFunction
        const diffNodes = splitView
          ? this.renderSplitView(line, i)
          : this.renderInlineView(line, i);

        if (currentPosition === extraLines && skippedLines.length > 0) {
          const { length } = skippedLines;
          skippedLines = [];
          // noinspection JSUnresolvedFunction
          return (
            <React.Fragment key={i}>
              {this.renderSkippedLineIndicator(
                length,
                diffBlockStart,
                line.left.lineNumber,
                line.right.lineNumber,
              )}
              {diffNodes}
            </React.Fragment>
          );
        }
        return diffNodes;
      },
    );
  }
}

MyDiffViewer.defaultProps = {
  oldValue: '',
  newValue: '',
  splitView: true,
  highlightLines: [],
  disableWordDiff: false,
  compareMethod: DiffMethod.CHARS,
  styles: {},
  hideLineNumbers: true,
  extraLinesSurroundingDiff: 1,
  showDiffOnly: true,
  useDarkTheme: false,
};

// noinspection JSValidateTypes
MyDiffViewer.propTypes = {
  oldValue: PropTypes.string.isRequired,
  newValue: PropTypes.string.isRequired,
  splitView: PropTypes.bool,
  disableWordDiff: PropTypes.bool,
  compareMethod: PropTypes.oneOf(Object.values(DiffMethod)),
  renderContent: PropTypes.func,
  onLineNumberClick: PropTypes.func,
  extraLinesSurroundingDiff: PropTypes.number,
  styles: PropTypes.object,
  hideLineNumbers: PropTypes.bool,
  showDiffOnly: PropTypes.bool,
  highlightLines: PropTypes.arrayOf(PropTypes.string),
  leftTitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  rightTitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
};

export default MyDiffViewer;