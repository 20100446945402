import React from 'react';
import PropTypes from 'prop-types';
import {Checkbox, Row, Col} from 'antd';
import _ from 'lodash';

import style from '@/style/common/microService/common.microService.less';

class MicroServicePanelParameterCheckboxGroup extends React.PureComponent {
  shortTextMap = {};

  resetCheckboxGroup = () => {
    let me = this;

    if (me.props.locked) {
      return;
    }

    me.props.onShortTextChange((me.props.value || me.props.defaultValue).map(v => me.shortTextMap[`v-${v}`]).join('，') || '');
  };

  componentDidMount() {
    let me = this;

    me.props.options.forEach(option => {
      me.shortTextMap[`v-${option.value}`] = option.shortText;
    });
    me.resetCheckboxGroup();
    if (!me.props.locked) {
      me.props.onChange(me.props.value || me.props.defaultValue);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let me = this;

    if (prevProps.options !== me.props.options) {
      me.props.options.forEach(option => {
        me.shortTextMap[`v-${option.value}`] = option.shortText;
      });
      me.resetCheckboxGroup();
    } else if (!_.isEqual(prevProps.value, me.props.value)) {
      me.resetCheckboxGroup();
    }

    if (!me.props.locked && prevProps.locked !== me.props.locked) {
      me.props.onChange(me.props.value || me.props.defaultValue);
    } else if (!me.props.locked && me.props.value === undefined && me.props.defaultValue) {
      me.props.onChange(me.props.defaultValue);
    }
  }

  render() {
    let me = this;

    return (
      <Checkbox.Group
        className={style['parameter-checkbox-group']}
        onChange={me.props.onChange}
        value={me.props.value || me.props.defaultValue}
        disabled={me.props.locked}
      >
        <Row>
          {me.props.options.map(option => (
            <Col key={`c-check-${option.value}`} span={me.props.optionSpan}>
              <Checkbox className={style['parameter-checkbox-item']} value={option.value}>
                {option.label}
              </Checkbox>
            </Col>
          ))}
        </Row>
      </Checkbox.Group>
    );
  }
}

MicroServicePanelParameterCheckboxGroup.defaultProps = {
  optionSpan: 24,
};

MicroServicePanelParameterCheckboxGroup.propTypes = {
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onShortTextChange: PropTypes.func.isRequired,
  locked: PropTypes.bool.isRequired,
  defaultValue: PropTypes.array.isRequired,
  value: PropTypes.array,
  optionSpan: PropTypes.number,
};

export default MicroServicePanelParameterCheckboxGroup;