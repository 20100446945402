import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Table, message, Button, Avatar, Row, Col, Empty } from 'antd';

import PB, {SimplePB} from "@/libs/simplePB";

import ViewDataProvider from "@/components/common/dataProvider/common.dataProvider.view";
import UserDataProviderWrapper, {overrideNextMessage} from '@/components/common/dataProvider/common.dataProvider.user';
import Icon from "@/components/common/common.icon";
import { AvatarColors, IconTypes } from '@/constants/common';
import TimeDisplayField from '@/components/common/common.timeDisplayField';
import moment from 'moment';
import style from '@/style/common/view/common.view.find.smartSearchUserModal.less';
import UserAvatar from "react-user-avatar";
import { getToken, REQUEST_BASE } from '@/utils/HttpUtil';
import heightBarStyle from '@/style/components/main.headerbar.less';
import { getNodeDisplayTitle, getNodeIcon } from '@/constants/vis.defaultDefine.1';

const COLUMNS = (me) => [
  {
    title: '用户',
    dataIndex: 'nick',
    key: 'nick',
    render: (text, record, index) => {
      return (
        <div style={{display: 'flex', alignItems: 'center', cursor: 'pointer'}} onClick={() => {me.getUserInfo(record)}}>
          <div className={`${style['avatar']} ${record.picId ? '' : style['avatar-default-icon']}`}>
            <Avatar
              style={{background: record.picId ? "#eee" : AvatarColors[0], marginRight: '0'}}
              src={record.picId ? `${REQUEST_BASE}/user/user/file/${record.picId}?Authorization=${getToken()}` : undefined}
            >
              <i className={style['default-user-icon']}></i>
            </Avatar>
          </div>
          <div style={{lineHeight: 1.5, marginLeft: '0.6rem', color: '#1890ff'}}>
            <div>{record.nick}</div>
          </div>
        </div>
      );
    },
  },
  {
    title: '关联',
    dataIndex: 'viewName',
    key: 'viewName',
    render: (text) => {
      return (
        <div>
          在图谱<b style={{padding: '0 0.3rem'}}>{text}</b>中提到类似节点
        </div>
      );
    },
  },
];

class SmartSearchUserModal extends React.PureComponent {
  state = {
    smartSearchUserList: undefined,
    loadingStatus: 'idle',// 数据加载中
    type: 'list', // 当前展示内容： list: 用户列表 user: 某个用户信息
    userInfo: undefined, // 用户信息
    userEditableViewList: undefined, // 用户参与的图谱
    userViewPublicNode: undefined, // 用户公开节点信息
  };

  getSmartSearchUser = () => {
    const me = this;
    const { node, viewDataProvider } = me.props;
    me.setState({loadingStatus: 'processing'});
    viewDataProvider.smartSearchUserInAllView(node.fname, node.id).then(users => {
      console.log(' FindSmartSearchUserModal -> getSmartSearchUser  -> smartSearchUserInAllView -> users :', users);
      me.setState({
        loadingStatus: 'success',
        type: 'list',
        smartSearchUserList: users,
      })
    }).catch(() => {
      me.setState({loadingStatus: 'failed'});
      // message.error('获取发现用户数据失败');
    });
  };

  getUserInfo = (user) => {
    const me = this;
    let data = me.state.smartSearchUserList;
    console.log(' FindSmartSearchUserModal -> getUserInfo -> user :', user);
    // 通过Id找人物信息
    UserDataProviderWrapper.getUserInfoById(user.userId).then(res => {
      console.log(' FindSmartSearchUserModal -> getUserInfoById -> res :', res);
      me.setState({
        userInfo: Object.assign({}, res, user),
        type: 'user',
      })
    }).catch(() => {
      message.error('用户信息获取失败');
    });

    // 获取参与的图谱列表
    overrideNextMessage('static::getEditableViewByUserId', false);
    UserDataProviderWrapper.getEditableViewByUserId(user.userId).then(res => {
      console.log(' FindSmartSearchUserModal -> getUserInfoById -> res :', res);
      me.setState({
        userEditableViewList: (res.length > 20 ? res.slice(0, 19) : res) || [] ,
      })
    }).catch(() => {
      console.log('  ->  getEditableViewByUserId -> 获取失败');
      me.setState({
        userEditableViewList: [] ,
      })
    });

    // 获取节点公开信息列表
    overrideNextMessage('static::getViewPublicNode', false);
    UserDataProviderWrapper.getViewPublicNode({userId: user.userId}).then(res => {
      console.log(' FindSmartSearchUserModal -> getViewPublicNode -> res :', res);
      let nodes = res.filter(node => node.fname);
      me.setState({userViewPublicNode: nodes});
    }).catch(() => {
      console.log('  ->  getViewPublicNode -> 获取失败');
      me.setState({userViewPublicNode: []})
    });
  };

  switchToList = () => {
    const me = this;
    me.setState({
      type: 'list',
      userInfo: undefined, // 用户信息
      userEditableViewList: undefined, // 用户参与的图谱
      userViewPublicNode: undefined, // 用户公开节点信息
    });
  };

  componentDidMount() {
    let me = this;

    if (me.props.visible && me.props.user) {
      me.getUserInfo(me.props.user);
    } else {
      // 加载发现用户数据
      me.getSmartSearchUser();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let me = this;

    if (!prevProps.visible && me.props.visible) {
      // 加载排名
      me.getSmartSearchUser();
    }
  }

  render() {
    let me = this, content = null;

    switch (me.state.loadingStatus) {
      case 'idle':
      case 'processing':
        content = (
          <div className={style['smart-search-user-loading']}>
            <div>
              <Icon name="loading" theme="outlined"/><br/>
              数据加载中，请稍候...
            </div>
          </div>
        );
        break;
      case 'success':
        content = me.state.type === 'list' ? (
          <div className={style['content-wrap']}>
            <Table
              className={`scrollbar-none`}
              columns={COLUMNS(me)}
              dataSource={me.state.smartSearchUserList}
              rowKey={'userId'}
              locale={{emptyText: '尚未找到关注类似节点的用户'}}
            />
          </div>
        ) : (
          <div className={style['content-wrap']}>
            <div className={style['user-info-header']}>
              <div className={style['header-title']}>
                <div className={`${style['avatar']} ${me.state.userInfo.picId ? '' : style['avatar-default-icon']}`}>
                  <Avatar
                    size={'large'}
                    style={{background: me.state.userInfo.picId ? "#eee" : AvatarColors[0], marginRight: '0'}}
                    src={me.state.userInfo.picId ? `${REQUEST_BASE}/user/user/file/${me.state.userInfo.picId}?Authorization=${getToken()}` : undefined}
                  >
                    <i className={style['default-user-icon']}></i>
                  </Avatar>
                </div>
                <div style={{lineHeight: 1.5, marginLeft: '0.6rem'}}>
                  <div>{me.state.userInfo.nick}</div>
                </div>
              </div>
            </div>
            <div className={style['attribute-item']}>
              <Icon name={'icon-map--o1'} type={IconTypes.ICON_FONT} style={{fontSize: '1.3rem', marginRight: '0.6rem'}}/>
              地区：{`${me.state.userInfo.province || '--'}${me.state.userInfo.city ? '/' + me.state.userInfo.city : ''}${me.state.userInfo.district ? '/' + me.state.userInfo.district : ''}` }
            </div>
            <div className={style['attribute-item']}>
              <Icon name={'icon-building-o'} type={IconTypes.ICON_FONT} style={{fontSize: '1.3rem', marginRight: '0.6rem'}} />
              公司/机构：{me.state.userInfo.org || '--'}
            </div>
            <div className={style['attribute-item']}>
              <Icon name={'icon-history-o'} type={IconTypes.ICON_FONT} style={{fontSize: '1.3rem', marginRight: '0.6rem'}} />
              加入时间：<TimeDisplayField timestamp={moment(me.state.userInfo.gmtCreate, 'YYYY-MM-DD HH:mm:ss').valueOf()}/>
            </div>
            <div className={style['attribute-item']}>
              <Icon name={'icon-storage-o'} type={IconTypes.ICON_FONT} style={{fontSize: '1.3rem', marginRight: '0.6rem'}} />
              参与的图谱
              <Row className={style['attribute-item-content']}>
                { !me.state.userEditableViewList ? (
                  <div className={style['attribute-item-content']}><span style={{color: '#999'}}>（正在搜索关联信息...）</span></div>
                ) : (
                  me.state.userEditableViewList.length === 0 ? (
                    <div className={style['attribute-item-content']}><span style={{color: '#999'}}>（没有找到关联信息，可以稍后再试）</span></div>
                  ) : (
                    me.state.userEditableViewList.map((item, index) => (
                      <Col span={12} key={index}>
                        <div className={style['view-name']}>{item.name}</div>
                      </Col>
                    ))
                  )
                )}
              </Row>
            </div>
            <div className={style['attribute-item']}>
              <Icon name={'icon-process-o'} type={IconTypes.ICON_FONT} style={{fontSize: '1.3rem', marginRight: '0.6rem'}} />
              与 {me.props.node.fname} 有关的节点
              {
                me.state.userInfo.nodeFname ? (
                  <div className={style['attribute-item-content']}>
                    <span>{me.state.userInfo.nodeFname}</span>
                    <span style={{padding: '0.2rem'}}>@</span>
                    <span>{me.state.userInfo.viewName}</span>
                  </div>
                ) : (
                  <div className={style['attribute-item-content']}><span style={{color: '#999'}}>您暂无权限阅读其隐私节点！</span></div>
                )
              }
            </div>
            <div className={style['attribute-item']}>
              <Icon name={'icon-compass-o'} type={IconTypes.ICON_FONT} style={{fontSize: '1.3rem', marginRight: '0.6rem'}} />
              最新动态
              { !me.state.userViewPublicNode ? (
                <div className={style['attribute-item-content']}><span style={{color: '#999'}}>（正在搜索动态信息...）</span></div>
              ) : (
                me.state.userViewPublicNode.length === 0 ? (
                  <div className={style['attribute-item-content']}><span style={{color: '#999'}}>（动态信息为隐私内容，暂无权限查看）</span></div>
                ) : (
                  <div className={style['attribute-item-content']}>
                    {
                      me.state.userViewPublicNode.map((node, index) => (
                        <div key={index} className={style['node-item']}>
                          <div>
                            <Icon name={getNodeIcon(node).name} type={getNodeIcon(node).type} color={'#a5a5a5'} style={{marginRight: '0.5em'}} />
                            {getNodeDisplayTitle(node)}
                          </div>
                          <div>
                            {node.updateTime ? (
                              <TimeDisplayField timestamp={moment(node.updateTime, 'YYYY-MM-DD HH:mm:ss').valueOf()} />
                            ) : (node.linkTime ? (
                              <TimeDisplayField timestamp={moment(node.linkTime, 'YYYY-MM-DD HH:mm:ss').valueOf()} />
                            ) : '-')}
                          </div>
                        </div>
                      ))
                    }
                  </div>
                )
              )}
            </div>
          </div>
        );
        break;
      case 'failed':
        content = (
          <div className={style['smart-search-user-loading']}>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={"尚未找到关注类似节点的用户"}/>
          </div>
        );
        break;
      default:
        content = (
          <div className={style['smart-search-user-loading']}>
            <div>
              <Icon name="info-circle" theme="outlined"/><br/>
              尚未计算完成
              <div style={{marginTop: '1.2rem'}}>
                <Button onClick={() => me.getSmartSearchUser()}>刷新</Button>
              </div>
            </div>
          </div>
        );
    }

    return (
      <Modal
        wrapClassName={style['smart-search-user-modal']}
        width={'48rem'}
        title={<div className={style['modal-title']}>
          <div>发现用户</div>
          {me.state.type === 'user' && (<div onClick={() => me.switchToList()} className={style['return-list']}><Icon name={'left'}/> 用户列表 </div>)}
        </div>}
        footer={null}
        centered={true}
        visible={me.props.visible}
        onCancel={() => {
          me.props.onClose();
          me.setState({type: 'list'})
        }}
        bodyStyle={{padding: '1rem 1.5rem'}}
      >
        {content}
      </Modal>
    );
  }
}

SmartSearchUserModal.defaultProps = {
  bus: PB,
  user: undefined,
};

SmartSearchUserModal.propTypes = {
  bus: PropTypes.instanceOf(SimplePB),
  node: PropTypes.object.isRequired,
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  viewDataProvider: PropTypes.instanceOf(ViewDataProvider).isRequired,
  user: PropTypes.object,
};

export default SmartSearchUserModal;