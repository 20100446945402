import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {message} from 'antd';
import copy from 'copy-to-clipboard';

import PB, {SimplePB} from '@/libs/simplePB';
import Node from '@/libs/view/Node';
import {NodeEvents} from '@/libs/view/network/events';

import {NODE_TYPE_TEXT} from '@/constants/nodeConfig';

import {showErrorMessage} from '@/components/common/common.message';

import ViewDataProvider from '@/components/common/dataProvider/common.dataProvider.view';
import {
  DEFAULT_COPY_TEXT_KEY,
  MicroServiceUIConfig
} from '@/components/common/view/microService/shape/common.view.microService.shape.uiConfig';

/**
 * @description 保存内容微服务数据项
 * @typedef {Object} TMicroServiceDataOpSaveNodes
 * @property {Object} resultTargetMap - 目标对象ID到数据对应关系
 * @property {Object} resultTargetToTreeNode - 目标对象ID到树节点对应关系
 * @property {Array} dataTree - 树形列表节点数据
 * @property {Object} dataTreeMap - 树形列表节点ID到数据对应关系
 * @property {Object} idReplacementMap - 替换前节点ID到替换后节点ID对应关系
 * @property {Object} idReplacementRevertMap - 替换后节点ID到替换前节点ID对应关系
 * @property {Object} originalInfoMap - 原始节点、边信息
 * @property {Object.<string, string[]>} treeNodeUidToIdMap - 实际数据节点ID到树节点ID的对应关系
 * @property {boolean} currentHasData - 当前处理的返回值是否有新结果
 * @property {number} availableNodeAmount - 当前可勾选的对象数量
 * @property {'idle'|'processing'} savingStatus - 当前保存流程状态
 * @property {string[]} checkedKeys - 当前勾选的对象ID
 * @property {string[]} expandedKeys - 当前展开的对象ID
 * @property {string[]} objectIds - 对象ID列表
 * @property {Object.<string, TMicroServiceResponseObjectStatus>} objectStatus - 对象状态
 * @property {TMicroServiceUIConfigOperationSaveNodes} operationConfig - 操作详细配置
 * @property {TMicroServiceUIConfigOperationSaveNodes} userOperationConfig - 用户修改后的操作详细配置
 */

/**
 * @description 初始化服务数据
 * @param {TMicroServiceUIConfigOperationSaveNodes} operationConfig
 * @return {TMicroServiceDataOpSaveNodes}
 */
const getInitialServiceData = operationConfig => ({
  resultTargetMap: {},
  resultTargetToTreeNode: {},
  dataTree: [],
  dataTreeMap: {},
  idReplacementMap: {},
  idReplacementRevertMap: {},
  originalInfoMap: {},
  treeNodeUidToIdMap: {},
  currentHasData: false,

  availableNodeAmount: 0,
  checkedKeys: [],
  expandedKeys: [],

  savingStatus: 'idle',

  objectIds: [],
  objectStatus: {},
  operationConfig,
  userOperationConfig: _.cloneDeep(operationConfig),
});

const OPERATION = 'saveNodes';

class MicroServiceLogicOperationSaveNodes extends React.PureComponent {
  /**
   * @type {Object.<string, TMicroServiceDataOpSaveNodes>}
   */
  serviceDataMap = {};

  /**
   * 判断树节点是否可被选择
   *
   * @param {Object} treeNode - 树节点
   * @param {string} serviceId - 微服务ID
   * @return {boolean}
   */
  isCheckableTreeNode = (treeNode, serviceId) => {
    let me = this, serviceData = me.serviceDataMap[serviceId];

    return serviceData && serviceData.objectStatus[treeNode.uid]
      && ['idle', 'selected'].includes(serviceData.objectStatus[treeNode.uid].status);
  };

  /**
   * 配置文件加载完毕后，检查是否需要初始化数据
   *
   * @param {string} projectId - 项目ID
   * @param {TMicroServiceUIConfig[]} configList - 微服务配置列表
   */
  onConfigListLoaded = (projectId, configList) => {
    let me = this;

    (configList || []).forEach(config => {
      config.operations.find(op => {
        if (op.type === OPERATION) {
          me.serviceDataMap[config.id] = getInitialServiceData(op);
          return true;
        }
        return false;
      });
    });
  };

  /**
   * 复制选中节点的文本内容至剪切板
   *
   * @param {string} serviceId - 微服务ID
   */
  onCopySelectedNodesToClipboard = (serviceId) => {
    let me = this, textList = [], serviceData = me.serviceDataMap[serviceId];

    if (serviceData) {
      // noinspection JSValidateTypes
      _.intersection(Object.keys(serviceData.dataTreeMap), serviceData.checkedKeys).forEach(k => {
        if (serviceData.dataTreeMap[k] && serviceData.dataTreeMap[k].copyText) {
          textList.push(serviceData.dataTreeMap[k].copyText);
        }
      });

      let result = copy(textList.join("\r\n"), {
        message: '请按下 #{key} 复制选中节点文本。',
      });

      if (result) message.success('选中节点文本已复制到剪切板。');
    }
  };

  /**
   * 原始结果加载完毕后，解析并存储数据
   *
   * @param {TMicroServiceResponseDataItem[]} data - 原始数据列表
   * @param {string} serviceId - 微服务ID
   */
  onRawDataLoaded = (data, serviceId) => {
    let me = this, newKeys = me.parseResult(serviceId, data), serviceData;
    serviceData = me.serviceDataMap[serviceId];
    serviceData.treeNodeUidToIdMap = {};
    Object.values(serviceData.dataTreeMap).forEach(node => {
      if (!serviceData.treeNodeUidToIdMap[node.uid]) {
        serviceData.treeNodeUidToIdMap[node.uid] = [];
      }
      serviceData.treeNodeUidToIdMap[node.uid].push(node.id);
    });

    me.props.bus.emit('view', 'micro_service.service.broadcast_operation_result',
      {viewId: me.props.viewDataProvider.viewId, serviceId, operation: OPERATION});
    if (newKeys && newKeys.length > 0) {
      me.serviceDataMap[serviceId].availableNodeAmount = Object.values(me.serviceDataMap[serviceId].dataTreeMap)
        .filter(treeNode => me.isCheckableTreeNode(treeNode, serviceId)).length;

      serviceData.expandedKeys = [...serviceData.expandedKeys, ...newKeys];

      me.props.bus.emit('view', 'micro_service.service.broadcast_operation_statistics',
        {viewId: me.props.viewDataProvider.viewId, serviceId, operation: OPERATION});
      me.props.bus.emit('view', 'micro_service.service.broadcast_operation_ui',
        {viewId: me.props.viewDataProvider.viewId, serviceId, operation: OPERATION});
    }
  };

  /**
   * 微服务主控数据清理后，清理业务数据
   *
   * @param {string} serviceId - 微服务ID
   */
  onResultCleared = serviceId => {
    let me = this, serviceData = me.serviceDataMap[serviceId];

    if (me.props.viewDataProvider) {
      serviceData.resultTargetMap = {};
      serviceData.resultTargetToTreeNode = {};
      serviceData.dataTree = [];
      serviceData.dataTreeMap = {};
      serviceData.idReplacementMap = {};
      serviceData.idReplacementRevertMap = {};
      serviceData.originalInfoMap = {};
      serviceData.treeNodeUidToIdMap = {};
      serviceData.currentHasData = false;

      serviceData.availableNodeAmount = 0;
      serviceData.checkedKeys =  [];
      serviceData.expandedKeys = [];

      serviceData.objectIds = [];
      serviceData.objectStatus = {};
      serviceData.userOperationConfig = _.cloneDeep(serviceData.operationConfig);
    }
  };

  onOperationConfigChange = (serviceId, path, config) => {
    let me = this, serviceData = me.serviceDataMap[serviceId];

    if (serviceData) {
      _.set(serviceData.userOperationConfig, path, config);
    }
  };

  /**
   * 保存数据回调
   *
   * @param {string} serviceId - 微服务ID
   */
  onSaveNodesToView = serviceId => {
    let me = this, serviceData = me.serviceDataMap[serviceId], nodeIdxMap = {}, nodes = [], edges = [],
      treeNodeEdgeMap = {}, config = serviceData.userOperationConfig, pushNode = (nodeInfo, nodeId, nodes, nodeIdxMap) => {
        nodeInfo = {
          ...nodeInfo,
          id: nodeId,
          type: NODE_TYPE_TEXT,
          'delete': 0,
          userConfirmed: true,
          status: 1,
          userId: me.props.userId,
          userPreferredType: nodeInfo.userPreferredType === undefined ? nodeInfo.aiPreferredType : nodeInfo.userPreferredType,
          forceAdd: true,
          replaceNodeId: true,
          meta: {
            ...(nodeInfo.meta || {}),
            status: 1,
          },
          initialX: undefined,
          initialY: undefined,
          //fx: undefined,
          //fy: undefined,
        }
        nodeIdxMap[nodeInfo.id] = nodes.length;
        nodes.push(nodeInfo);
      };

    if (serviceData) {
      serviceData.checkedKeys.forEach(key => {
        let treeNode = serviceData.dataTreeMap[key];
        if (!treeNode || treeNode.originallyInView
          || ['saved', 'invalid'].includes(serviceData.objectStatus[treeNode.uid].status)) {

          return;
        }

        let nodeInfo = serviceData.resultTargetMap[treeNode.nodeId];
        if (!nodeInfo) return;

        if (nodeIdxMap[treeNode.nodeId] === undefined) {
          pushNode(nodeInfo, treeNode.nodeId, nodes, nodeIdxMap);
        }

        for (let i = treeNode.path.length - 1; i > 0; i--) {
          // 连接 i 至 i - 1
          let parentTreeNode = serviceData.dataTreeMap[treeNode.path[i - 1]],
            childTreeNode = serviceData.dataTreeMap[treeNode.path[i]];

          if (!parentTreeNode || !childTreeNode || treeNodeEdgeMap[childTreeNode.uid]) continue;

          let meta = (nodeInfo.meta && nodeInfo.meta['edgeMeta']) ? {...nodeInfo.meta['edgeMeta']}
            : ((config.lineType || config.lineStyle) ? {} : undefined);

          if (config.lineType === 'dash') {
            meta.userMarkedInvisible = 1;
          } else if (config.lineType === 'solid') {
            meta.userMarkedInvisible = 0;
          }

          if (config.lineStyle === 'straight') {
            meta.smooth = 0;
          } else if (config.lineType === 'curve') {
            meta.smooth = 1;
          }

          let edgeInfo = {
            userConfirmed: true,
            status: 1,
            meta,
          };

          if (childTreeNode.originallyInView) {
            edgeInfo.to = childTreeNode.nodeId;
          } else if (
            serviceData.resultTargetMap[childTreeNode.nodeId]
            && serviceData.objectStatus[childTreeNode.nodeId]
            && serviceData.objectStatus[childTreeNode.nodeId].status === 'saved'
          ) {
            edgeInfo.to = serviceData.resultTargetMap[childTreeNode.nodeId].nodeId;
          } else if (nodeIdxMap[childTreeNode.nodeId] !== undefined) {
            edgeInfo.toIndex = nodeIdxMap[childTreeNode.nodeId];
          } else {
            continue;
          }

          if (parentTreeNode.originallyInView) {
            edgeInfo.from = parentTreeNode.nodeId;
          } else if (
            serviceData.resultTargetMap[parentTreeNode.nodeId]
            && serviceData.objectStatus[parentTreeNode.nodeId]
            && serviceData.objectStatus[parentTreeNode.nodeId].status === 'saved'
          ) {
            edgeInfo.from = serviceData.resultTargetMap[parentTreeNode.nodeId].id;
          } else if (nodeIdxMap[parentTreeNode.nodeId] !== undefined) {
            edgeInfo.fromIndex = nodeIdxMap[parentTreeNode.nodeId];
          } else {
            let parentNodeInfo = serviceData.resultTargetMap[parentTreeNode.nodeId];
            pushNode(parentNodeInfo, parentTreeNode.nodeId, nodes, nodeIdxMap);
            edgeInfo.fromIndex = nodeIdxMap[parentTreeNode.nodeId];
          }

          edges.push(edgeInfo);
          treeNodeEdgeMap[childTreeNode.uid] = edgeInfo;
        }
      });
      if (nodes.length === 0 && edges.length === 0) {
        message.success(`所选节点均已加入看板`);
        return;
      }

      serviceData.savingStatus = 'processing';
      me.props.bus.emit('view', 'micro_service.service.broadcast_operation_ui',
        {viewId: me.props.viewDataProvider.viewId, serviceId, operation: OPERATION});

      me.props.viewDataProvider.saveRelationGraph(
        nodes, edges, nodes[0].withNodeId, false, 'micro_service_by_view', false
      ).then(() => {
        serviceData.checkedKeys = [];
        serviceData.savingStatus = 'idle';
        // 这里不更新objectStatus，交给边的添加事件监听函数处理
        me.props.bus.emit('view', 'micro_service.service.broadcast_operation_ui',
          {viewId: me.props.viewDataProvider.viewId, serviceId, operation: OPERATION});
        message.success(`成功加入 ${nodes.length} 个节点`);
      }).catch(({code, msg}) => {
        showErrorMessage({code, msg, extra: {isModification: true}});
        serviceData.savingStatus = 'idle';
        me.props.bus.emit('view', 'micro_service.service.broadcast_operation_ui',
          {viewId: me.props.viewDataProvider.viewId, serviceId, operation: OPERATION});
      });
    }
  };

  /**
   * 树节点勾选回调
   *
   * @param {string} serviceId - 微服务ID
   * @param {string[]} checkedKeys - 勾选的节点ID列表
   */
  onTreeNodeItemCheck = (serviceId, checkedKeys) => {
    let me = this, serviceData = me.serviceDataMap[serviceId], tmpObjectStatus = {}, updatedObjectStatus = {},
      checkedKeyMap = {}, objectStatus = serviceData.objectStatus, hasUpdatedObjectStatus = false;

    if (serviceData) {
      checkedKeys.forEach(k => {
        let treeNode = serviceData.dataTreeMap[k];

        if (!treeNode) return;

        checkedKeyMap[k] = true;
        if (serviceData.treeNodeUidToIdMap[treeNode.uid].length > 1) {
          serviceData.treeNodeUidToIdMap[treeNode.uid].forEach(id => checkedKeyMap[id] = true);
        }

        [...treeNode.path].reverse().find(k => {
          let pN = serviceData.dataTreeMap[k];
          if (!updatedObjectStatus[pN.uid]) {
            if (objectStatus[pN.uid]) {
              if (objectStatus[pN.uid].status === 'idle') {
                objectStatus[pN.uid].status = "selected";
                updatedObjectStatus[pN.uid] = {
                  type: objectStatus[pN.uid].type,
                  status: 'selected',
                };
                tmpObjectStatus[pN.uid] = 'selected';
                hasUpdatedObjectStatus = true;
              } else if (objectStatus[pN.uid].status === 'selected') {
                tmpObjectStatus[pN.uid] = 'selected';
              }
            }
          }
          return pN && pN.action;
        });
      });

      serviceData.objectIds.forEach(id => {
        if (!tmpObjectStatus[id] && objectStatus[id].status === 'selected') {
          objectStatus[id].status = 'idle';
          updatedObjectStatus[id] = {
            type: objectStatus[id].type,
            status: 'idle',
          };
          hasUpdatedObjectStatus = true;
        }
      });

      me.serviceDataMap[serviceId].objectStatus = objectStatus;
      me.serviceDataMap[serviceId].checkedKeys = Object.keys(checkedKeyMap);

      if (hasUpdatedObjectStatus) {
        me.props.bus.emit('view', 'micro_service.service.operation_statistics_updated',
          {viewId: me.props.viewDataProvider.viewId, serviceId, operation: OPERATION, updatedObjectStatus});
      }

      me.props.bus.emit('view', 'micro_service.service.broadcast_operation_ui',
        {viewId: me.props.viewDataProvider.viewId, serviceId, operation: OPERATION});
    }
  };

  /**
   * 树节点展开回调
   *
   * @param {string} serviceId - 微服务ID
   * @param {string[]} expandedKeys - 展开的节点ID列表
   */
  onTreeNodeItemExpand = (serviceId, expandedKeys = []) => {
    let me = this, serviceData = me.serviceDataMap[serviceId];

    if (serviceData) {
      if (serviceData.expandedKeys.length !== expandedKeys.length
        || _.difference(serviceData.expandedKeys, expandedKeys).length > 0) {

        me.serviceDataMap[serviceId].expandedKeys = expandedKeys;
        me.props.bus.emit('view', 'micro_service.service.broadcast_operation_ui',
          {viewId: me.props.viewDataProvider.viewId, serviceId, operation: OPERATION});
      }
    }
  };

  /**
   * 解析微服务返回的数据
   *
   * @param {string} serviceId - 微服务ID
   * @param {TMicroServiceResponseDataItem[]} dataList - 服务器返回的数据列表
   *
   * @return {string[]} 新增树节点的ID列表
   */
  parseResult = (serviceId, dataList) => {
    let me = this,
      serviceData = me.serviceDataMap[serviceId],
      currentHasData = false,
      config = serviceData.operationConfig,
      resultTargetMap = serviceData.resultTargetMap,
      resultTargetToTreeNode = serviceData.resultTargetToTreeNode,
      dataTree = serviceData.dataTree,
      dataTreeMap = serviceData.dataTreeMap,
      originalInfoMap = serviceData.originalInfoMap,
      objectIds = serviceData.objectIds,
      objectStatus = serviceData.objectStatus,
      oldKeys = Object.keys(serviceData.dataTreeMap),
      dataMsg;
    let copyPath = config.copyTextKey === false ? false : DEFAULT_COPY_TEXT_KEY;
    dataList.forEach(dataItem => {
      if ((dataItem.calcStatus && dataItem.calcStatus == 1)) {
        dataMsg = {
          'msgStatus' : dataItem.calcStatus,
          'msg' : dataItem.calcMsg
        }
      }
      if ((dataItem.msgStatus && dataItem.msgStatus == 1)) {
        dataMsg = {
          'msgStatus' : dataItem.msgStatus,
          'msg' : dataItem.msg
        }
      }
      if ((dataItem.nodes && dataItem.nodes.length > 0) || (dataItem.info && dataItem.info.fname)) {
        let itemRootNode = resultTargetMap[dataItem.id], treeNode,
          treeNodeId = `${serviceId}-root-${dataItem.id}`;
        if (!itemRootNode) {
          if (![undefined, null, 'node'].includes(dataItem.type)) {
            // 暂不支持
            return;
          }

          treeNode = {
            id: treeNodeId,
            uid: dataItem.id,
            nodeId: dataItem.id,
            originallyInView: true,
            renderAs: 'node',
            path: [treeNodeId],
            action: 'none',
          };

          itemRootNode = me.props.viewDataProvider.getNode(dataItem.id);
          if (!itemRootNode) {
            // 看板中不存在对应节点 则创建新节点
            let nodeId = `${serviceId}-n-${dataItem.id}`;
            dataItem.info.id = nodeId;
            itemRootNode = new Node(dataItem.info);
            treeNode.uid = nodeId;
            treeNode.nodeId = nodeId;
            treeNode.originallyInView = false;
            treeNode.action = 'add';
            originalInfoMap[treeNode.uid] = itemRootNode;
          }

          resultTargetMap[treeNode.uid] = itemRootNode;
          resultTargetToTreeNode[treeNode.uid] = treeNode;
          treeNode.copyText = (copyPath ? _.get(itemRootNode, copyPath) : '');
          dataTreeMap[treeNodeId] = treeNode;
          dataTree.push(treeNode);
          if (!objectStatus[treeNode.uid]) {
            objectIds.push(treeNode.uid);
            objectStatus[treeNode.uid] = {
              type: treeNode.action || 'none',
              status: 'idle',
            };
          }
        }

        let aiRelatedTo = treeNode.originallyInView ? treeNode.nodeId : undefined;

        treeNode = dataTreeMap[treeNodeId];

        // 处理数据
        // TODO 目前忽略顺序、分类，后续再加
        treeNode.children = treeNode.children || [];
        dataItem.nodes && dataItem.nodes.forEach(node => {
          node.id = (node.id || `${Math.random()}`);
          let subTreeNode,
            subTreeNodeId = `${serviceId}-t-${dataItem.id}-${node.id}`,
            nodeId = `${serviceId}-n-${dataItem.id}-${node.id}`;

          if (!resultTargetMap[nodeId]) {
            subTreeNode = {
              id: subTreeNodeId,
              uid: nodeId,
              nodeId,
              renderAs: 'node',
              path: [treeNodeId, subTreeNodeId],
              action: 'add',
            };
            // 生成子节点并加入children中
            let nodeInfo = {...node, id: nodeId, aiRelatedTo, withNodeId: aiRelatedTo};
            resultTargetMap[subTreeNode.uid] = new Node(nodeInfo);
            originalInfoMap[subTreeNode.uid] = resultTargetMap[subTreeNode.uid];
            resultTargetToTreeNode[subTreeNode.uid] = subTreeNode;
            subTreeNode.copyText = _.get(resultTargetMap[subTreeNode.uid], copyPath);
            treeNode.children.push(subTreeNode);
            dataTreeMap[subTreeNodeId] = subTreeNode;
            if (!objectStatus[subTreeNode.uid]) {
              objectIds.push(subTreeNode.uid);
              objectStatus[subTreeNode.uid] = {
                type: subTreeNode.action || 'none',
                status: 'idle',
              };
            }

            currentHasData = true;
          }
        });
      }
    });
    me.serviceDataMap[serviceId].resultTargetMap = resultTargetMap;
    me.serviceDataMap[serviceId].resultTargetToTreeNode = resultTargetToTreeNode;
    me.serviceDataMap[serviceId].dataTree = dataTree;
    me.serviceDataMap[serviceId].dataTreeMap = dataTreeMap;
    me.serviceDataMap[serviceId].originalInfoMap = originalInfoMap;
    me.serviceDataMap[serviceId].currentHasData = currentHasData;
    me.serviceDataMap[serviceId].objectIds = objectIds;
    me.serviceDataMap[serviceId].objectStatus = objectStatus;
    me.serviceDataMap[serviceId].dataMsg = dataMsg;
    return _.difference(Object.keys(dataTreeMap), oldKeys);
  };

  componentDidMount() {
    let me = this;

    me.props.bus.with(
      me
    ).subscribe('view', 'micro_service.config.data_loaded', ({projectId, configList}) => {
      me.onConfigListLoaded(projectId, configList);
    }).subscribe('view', 'micro_service.service.raw_data_loaded', ({data, viewId, serviceId}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId && me.serviceDataMap[serviceId]) {
        me.onRawDataLoaded(data, serviceId);
      }
    }).subscribe('view', 'micro_service.service.result_cleared', ({viewId, serviceId}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId && me.serviceDataMap[serviceId]) {
        me.onResultCleared(serviceId);
      }
    }).subscribe('view', 'micro_service.service.operation_save_nodes.copy_selected_node_to_clipboard', ({viewId, serviceId}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId && me.serviceDataMap[serviceId]) {
        me.onCopySelectedNodesToClipboard(serviceId);
      }
    }).subscribe('view', 'micro_service.service.operation_save_nodes.tree_node_item_check', ({viewId, serviceId, checkedKeys}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId && me.serviceDataMap[serviceId]) {
        me.onTreeNodeItemCheck(serviceId, checkedKeys);
      }
    }).subscribe('view', 'micro_service.service.operation_save_nodes.tree_node_item_expand', ({viewId, serviceId, expandedKeys}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId && me.serviceDataMap[serviceId]) {
        me.onTreeNodeItemExpand(serviceId, expandedKeys);
      }
    }).subscribe('view', 'micro_service.service.operation_save_nodes.set_operation_config', ({viewId, serviceId, path, config}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId && me.serviceDataMap[serviceId]) {
        me.onOperationConfigChange(serviceId, path, config);
      }
    }).subscribe('view', 'micro_service.service.broadcast_operation_result', ({viewId, serviceId, operation}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId && me.serviceDataMap[serviceId]
        && operation === OPERATION) {

        me.props.bus.emit('view', 'micro_service.service.current_operation_result', {
          viewId,
          serviceId,
          operation: OPERATION,
          resultTargetMap: {...me.serviceDataMap[serviceId].resultTargetMap},
          dataTree: [...me.serviceDataMap[serviceId].dataTree],
          dataTreeMap: {...me.serviceDataMap[serviceId].dataTreeMap},
          treeNodeUidToIdMap: {...me.serviceDataMap[serviceId].treeNodeUidToIdMap},
          currentHasData: me.serviceDataMap[serviceId].currentHasData,
          dataMsg:me.serviceDataMap[serviceId].dataMsg
        });
      }
    }).subscribe('view', 'micro_service.service.broadcast_operation_ui', ({viewId, serviceId, operation}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId && me.serviceDataMap[serviceId]
        && operation === OPERATION) {

        me.props.bus.emit('view', 'micro_service.service.current_operation_ui', {
          viewId,
          serviceId,
          operation: OPERATION,

          availableNodeAmount: me.serviceDataMap[serviceId].availableNodeAmount,
          checkedKeys: [...me.serviceDataMap[serviceId].checkedKeys],
          expandedKeys: [...me.serviceDataMap[serviceId].expandedKeys],

          savingStatus: me.serviceDataMap[serviceId].savingStatus,

          operationConfig: _.cloneDeep(me.serviceDataMap[serviceId].operationConfig),
          userOperationConfig: me.serviceDataMap[serviceId].userOperationConfig,
        });
      }
    }).subscribe('view', 'micro_service.service.broadcast_operation_statistics', ({viewId, serviceId, operation}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId && me.serviceDataMap[serviceId]
        && operation === OPERATION) {

        me.props.bus.emit('view', 'micro_service.service.current_operation_statistics', {
          viewId,
          serviceId,
          operation: OPERATION,
          objectIds: [...me.serviceDataMap[serviceId].objectIds],
          objectStatus: {...me.serviceDataMap[serviceId].objectStatus},
        });
      }
    }).subscribe('view', 'micro_service.service.action.main_operation', ({viewId, serviceId, operation}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId && me.serviceDataMap[serviceId]
        && operation === OPERATION) {

        me.onSaveNodesToView(serviceId);
      }
    });

    me.props.viewDataProvider.with(me).subscribe(NodeEvents.ID_REPLACED, (nodeIdReplacement) => {
      let originalNodeIds = Object.keys(nodeIdReplacement);
      Object.keys(me.serviceDataMap).forEach(serviceId => {
        originalNodeIds.forEach(originalNodeId => {
          if (me.serviceDataMap[serviceId].resultTargetMap && me.serviceDataMap[serviceId].resultTargetMap[originalNodeId]) {
            me.serviceDataMap[serviceId].idReplacementMap[originalNodeId] = nodeIdReplacement[originalNodeId];
            me.serviceDataMap[serviceId].idReplacementRevertMap[nodeIdReplacement[originalNodeId]] = originalNodeId;
          }
        });
      });
    }).subscribe(NodeEvents.ADDED, (type, addedNodeIds, addedNodes) => {
      Object.keys(me.serviceDataMap).forEach(serviceId => {
        let changed = false, serviceData = me.serviceDataMap[serviceId];
        addedNodeIds.forEach((nodeId, idx) => {
          let originalNodeId = nodeId;
          if (me.serviceDataMap[serviceId].idReplacementRevertMap[nodeId]) {
            originalNodeId = me.serviceDataMap[serviceId].idReplacementRevertMap[nodeId];
          }
          if (me.serviceDataMap[serviceId].objectStatus[originalNodeId]) {
            changed = true;
            serviceData.objectStatus[originalNodeId].status = 'saved';
            serviceData.resultTargetMap[originalNodeId] = addedNodes[idx];
            let treeNodeIds = serviceData.treeNodeUidToIdMap[originalNodeId];
            treeNodeIds.forEach(id => {
              let n = serviceData.dataTreeMap[id], nPath = n.path, pN;

              nPath.find(treeNodeId => {
                pN = serviceData.dataTreeMap[treeNodeId];

                if (pN && serviceData.objectStatus[pN.uid]
                  && ['idle', 'selected'].includes(serviceData.objectStatus[pN.uid].status)
                  && pN.children && !pN.children.find(cN => serviceData.objectStatus[cN.uid]
                    && ['idle', 'selected'].includes(serviceData.objectStatus[cN.uid].status))
                ) {
                  serviceData.objectStatus[pN.uid].status = 'saved';
                  return false;
                } else {
                  return true;
                }
              });
            });
          }
        });
        if (changed) {
          serviceData.availableNodeAmount = Object.values(serviceData.dataTreeMap)
            .filter(treeNode => me.isCheckableTreeNode(treeNode, serviceId)).length;
          me.props.bus.emit('view', 'micro_service.service.broadcast_operation_statistics',
            {viewId: me.props.viewDataProvider.viewId, serviceId, operation: OPERATION});
          me.props.bus.emit('view', 'micro_service.service.broadcast_operation_ui',
            {viewId: me.props.viewDataProvider.viewId, serviceId, operation: OPERATION});
        }
      });
    }).subscribe(NodeEvents.REMOVED, (type, nodeInfoList) => {
      Object.keys(me.serviceDataMap).forEach(serviceId => {
        let changed = false;
        nodeInfoList.forEach(node => {
          let originalNodeId = node.id;
          if (me.serviceDataMap[serviceId].idReplacementRevertMap[node.id]) {
            originalNodeId = me.serviceDataMap[serviceId].idReplacementRevertMap[node.id];
          }
          if (me.serviceDataMap[serviceId].objectStatus[originalNodeId]) {
            changed = true;
            me.serviceDataMap[serviceId].objectStatus[originalNodeId].status = 'invalid';
            if (me.serviceDataMap[serviceId].resultTargetMap[originalNodeId]) {
              me.serviceDataMap[serviceId].resultTargetMap[originalNodeId] =
                me.serviceDataMap[serviceId].originalInfoMap[originalNodeId];
            }
            if (me.serviceDataMap[serviceId].resultTargetToTreeNode[originalNodeId]) {
              let children = me.serviceDataMap[serviceId].resultTargetToTreeNode[originalNodeId].children;
              let childrenList = [children];
              while (childrenList.length > 0) {
                let currentChildren = childrenList.shift();
                if (currentChildren && currentChildren.length > 0) {
                  currentChildren.forEach(child => {
                    if (me.serviceDataMap[serviceId].objectStatus[child.uid]) {
                      me.serviceDataMap[serviceId].objectStatus[child.uid].status = 'invalid';
                      if (me.serviceDataMap[serviceId].resultTargetMap[child.uid]) {
                        me.serviceDataMap[serviceId].resultTargetMap[child.uid] =
                          me.serviceDataMap[serviceId].originalInfoMap[child.uid];
                      }
                    }
                    if (child.children && child.children.length > 0) {
                      childrenList.push(child.children);
                    }
                  });
                }
              }
            }
          }
        });
        if (changed) {
          me.serviceDataMap[serviceId].availableNodeAmount = Object.values(me.serviceDataMap[serviceId].dataTreeMap)
            .filter(treeNode => me.isCheckableTreeNode(treeNode, serviceId)).length;
          me.props.bus.emit('view', 'micro_service.service.broadcast_operation_statistics',
            {viewId: me.props.viewDataProvider.viewId, serviceId, operation: OPERATION});
          me.props.bus.emit('view', 'micro_service.service.broadcast_operation_ui',
            {viewId: me.props.viewDataProvider.viewId, serviceId, operation: OPERATION});
        }
      });
    });
  }

  componentWillUnmount() {
    this.props.bus.remove(this);
    this.props.viewDataProvider.unSubscribe(this);
  }

  render() {
    return null;
  }
}

MicroServiceLogicOperationSaveNodes.defaultProps = {
  bus: PB,
};

MicroServiceLogicOperationSaveNodes.propTypes = {
  viewDataProvider: PropTypes.instanceOf(ViewDataProvider).isRequired,
  microServiceConfigMap: PropTypes.objectOf(PropTypes.shape(MicroServiceUIConfig)).isRequired,
  userId: PropTypes.number.isRequired,
  bus: PropTypes.instanceOf(SimplePB),
};

export default MicroServiceLogicOperationSaveNodes;