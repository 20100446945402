import React from "react";
/*import {getShortestPath} from "@/libs/graph-utils";*/
import {getNodeDisplayTitle} from "@/constants/vis.defaultDefine.1";
import Icon from "@/components/common/common.icon";
import {NodeEvents} from "@/libs/view/network/events";
import style from "@/style/common/view/common.view.explore.less";
import {Button, Checkbox, Divider, Modal} from 'antd';
import PB, {SimplePB} from "@/libs/simplePB";
import PropTypes from "prop-types";
import ViewDataProvider from "@/components/common/dataProvider/common.dataProvider.view";
import CandidateNodeTree from "@/components/common/view/explore/common.view.explore.candidateNodeTree";

/**
 * 候选节点列表
 */
class CandidateNodeList extends React.Component {
  savedNodeMap = {};

  removedNodeMap = {};

  allNodeMap = {};

  idReplacementMap = {};

  candidateNodeTreeUpdateTicket = Math.random();

  /*shortestPathResult = undefined;*/

  state = {
    showNodeTreeModal: false,
  };

  constructor(props) {
    super(props);

    this.allNodeMap = {};

    props.viewDataProvider.getNode(props.nodeIds).forEach(node => node && (this.allNodeMap[node.id] = node));

    /*this.shortestPathResult = getShortestPath(Object.values(this.allNodeMap).concat(props.relatedTo),
      props.viewDataProvider.getEdge(), false);*/
  }

  onSaveNode = (node, e) => {
    let me = this;

    if (e.target.checked) {
      // 保留整个链路
      /*let targetNodeId = node.aiRelatedTo;
      let graphToSave = {
        nodes: [],
        edges: [],
      };
      if (targetNodeId && me.shortestPathResult && me.shortestPathResult[node.id] &&
        me.shortestPathResult[node.id][targetNodeId]) {

        // 获取整个路径
        let {pathList} = me.shortestPathResult[node.id][targetNodeId], relatedNodeMap = {}, relatedEdgeMap = {};
        pathList.forEach(path => path.forEach(edgeId => {
          if (relatedEdgeMap[edgeId]) return;
          let edge = me.props.viewDataProvider.getEdge(edgeId);
          if (edge.status === 1) return;
          relatedEdgeMap[edgeId] = edge;
          if (!relatedNodeMap[edge.from]) {
            relatedNodeMap[edge.from] = me.props.viewDataProvider.getNode(edge.from);
          }
          if (!relatedNodeMap[edge.to]) {
            relatedNodeMap[edge.to] = me.props.viewDataProvider.getNode(edge.to);
          }
        }));
        Object.values(relatedEdgeMap).forEach(edge =>
          graphToSave.edges.push({from: edge.from, to: edge.to, meta: {...edge.meta, status: 1, userConfirmed: true}}));
        Object.values(relatedNodeMap).forEach(node => node.status === 0 &&
          graphToSave.nodes.push({...node, userConfirmed: true}));
      } else {
        graphToSave.nodes.push({...node, userConfirmed: true});
      }*/
      let messageKey;
      me.props.bus.emit('aiConsole', 'message.push', {
        type: 'user',
        content: `保留节点 "${getNodeDisplayTitle(node, 12)}"`,
      });
      me.props.bus.emit('aiConsole', 'message.push', {
        type: 'ai',
        content: (<span><Icon name={'loading'} style={{marginRight: '0.5em'}} />请稍后...</span>),
        callback: ({key}) => messageKey = key,
        delay: 200,
      });
      /*graphToSave.nodes.forEach(node => {
        me.savedNodeMap[node.id] = false;
        node.meta && (node.meta.status = 1);
      });*/
      me.savedNodeMap[node.id] = false;
      node.meta && (node.meta.status = 1);
      node.userConfirmed = true;
      me.props.viewDataProvider.saveNodeWithRelations(
        node.id
      /*me.props.viewDataProvider.saveRelationGraph(
        graphToSave.nodes, graphToSave.edges, false, false, undefined, false*/
      ).then(() => {
        me.props.bus.emit('aiConsole', 'message.update', {key: messageKey, content: `操作成功`});
        /*graphToSave.nodes.forEach(node => me.savedNodeMap[node.id] = messageKey);*/
        if (me.idReplacementMap[node.id]) {
          node.id = me.idReplacementMap[node.id];
        }
        me.savedNodeMap[node.id] = messageKey;
        me.candidateNodeTreeUpdateTicket = Math.random();
        me.forceUpdate();
      }).catch(() => {
        me.props.bus.emit('aiConsole', 'message.update', {key: messageKey, content: `操作失败`});
        /*graphToSave.nodes.forEach(node => delete me.savedNodeMap[node.id]);*/
        if (me.idReplacementMap[node.id]) {
          node.id = me.idReplacementMap[node.id];
        }
        delete me.savedNodeMap[node.id];
        node.meta && (node.meta.status = 0);
        node.userConfirmed = false;
      });
    }
  };

  componentDidMount() {
    let me = this;

    me.props.viewDataProvider.with(me).subscribe(NodeEvents.UPDATED, (updatedNodeIds, updatedNodes) => {
      let dataChanged = false;
      updatedNodeIds.forEach((nodeId, idx) => {
        if (me.allNodeMap[nodeId]) {
          if (me.removedNodeMap[nodeId]) return; // 忽略已删除的节点
          if (me.savedNodeMap[nodeId]) return; // 忽略已保留过的节点
          if (updatedNodes[idx].status === 1 || updatedNodes[idx].userConfirmed) {
            me.savedNodeMap[nodeId] = true;
            dataChanged = true;
            me.candidateNodeTreeUpdateTicket = Math.random();
          }
        }
      });
      dataChanged && me.forceUpdate();
    }).subscribe(NodeEvents.REMOVED, (type, nodeInfoList) => {
      let dataChanged = false;
      nodeInfoList.forEach(node => {
        if (me.allNodeMap[node.id]) {
          if (!me.removedNodeMap[node.id]) {
            me.removedNodeMap[node.id] = true;
            dataChanged = true;
            me.candidateNodeTreeUpdateTicket = Math.random();
          }
        }
      });
      dataChanged && me.forceUpdate();
    }).subscribe(NodeEvents.ID_REPLACED, (nodeIdReplacement) => {
      let dataChanged = false, originalNodeIds = Object.keys(nodeIdReplacement);
      originalNodeIds.forEach(originalNodeId => {
        if (me.allNodeMap[originalNodeId]) {
          dataChanged = true;
          me.allNodeMap[originalNodeId].id = nodeIdReplacement[originalNodeId];
          me.allNodeMap[nodeIdReplacement[originalNodeId]] = me.allNodeMap[originalNodeId];
          delete me.allNodeMap[originalNodeId];
          me.idReplacementMap[originalNodeId] = nodeIdReplacement[originalNodeId];
        }
        if (me.savedNodeMap[originalNodeId] !== undefined) {
          me.savedNodeMap[nodeIdReplacement[originalNodeId]] = me.savedNodeMap[originalNodeId];
          delete me.savedNodeMap[originalNodeId];
        }
        if (me.removedNodeMap[originalNodeId] !== undefined) {
          me.removedNodeMap[nodeIdReplacement[originalNodeId]] = me.removedNodeMap[originalNodeId];
          delete me.removedNodeMap[originalNodeId];
        }
      });
      dataChanged && me.forceUpdate();
    });
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    let me = this;

    console.log('CandidateNodeList - update');

    if (nextProps.updateTicket !== me.props.updateTicket) {
      me.allNodeMap = {};

      me.props.viewDataProvider.getNode(nextProps.nodeIds).forEach(node => node && (me.allNodeMap[node.id] = node));

      /*me.shortestPathResult = getShortestPath(Object.values(me.allNodeMap).concat(me.props.relatedTo),
        me.props.viewDataProvider.getEdge(), false);*/
    }

    return true;
  }

  componentWillUnmount() {
    let me = this;

    me.props.bus.remove(me);
    me.props.viewDataProvider.unSubscribe(me);
  }

  render() {
    let me = this, nodes = Object.values(me.allNodeMap);

    console.log('CandidateNodeList - render');

    return nodes.length > 0 ? (
      <div className={`${style['candidate-list-frame']} scrollbar`}>
        <ul>
          {
            nodes.filter(node => node.fname && node.fname !== ' ').slice(0, 9).map(node => (
              <li
                key={`n-${node.id}`}
                onClick={() => me.removedNodeMap[node.id] || me.props.bus.emit('network', 'node_tooltip.show', node)}
                className={me.removedNodeMap[node.id] ? 'removed' : ''}
              >
                {
                  me.removedNodeMap[node.id] ? (
                    <Icon name={'stop'} style={{marginRight: '8px', verticalAlign: '-0.2rem'}}/>
                  ) : (
                    me.savedNodeMap[node.id] === undefined ? (
                      <Checkbox
                        style={{marginRight: '8px'}}
                        onClick={e => e.stopPropagation()}
                        onChange={e => me.onSaveNode(node, e)}
                        checked={false}
                      />
                    ) : (
                      <Icon
                        name={me.savedNodeMap[node.id] !== false ? 'check-circle' : 'loading'}
                        style={{marginRight: '8px', verticalAlign: '-0.2rem'}}
                      />
                    )
                  )
                }
                {getNodeDisplayTitle(node)}
              </li>
            ))
          }
        </ul>
        {
          nodes.length > 0 ? (
            <Divider style={{
              margin: '0.2rem 0 0',
              fontSize: '1rem',
              overflow: 'hidden',
            }}>
              <a onClick={() => me.setState({showNodeTreeModal: true})}>点击查看更多</a>
            </Divider>
          ) : null
        }
        {
          me.state.showNodeTreeModal ? (
            <Modal
              title={'节点树形列表'}
              visible={me.state.showNodeTreeModal}
              onCancel={() => me.setState({showNodeTreeModal: false})}
              footer={[
                (
                  <span
                    key={'info'}
                    style={{
                      float: 'left',
                      lineHeight: '32px',
                    }}
                  >
                    <Icon name={'info-circle'} style={{marginRight: '0.5em'}} />
                    点击即可保留您感兴趣的节点
                  </span>
                ),
                (<Button key={'close'} onClick={() => me.setState({showNodeTreeModal: false})}>关闭</Button>),
              ]}
            >
              <CandidateNodeTree
                savedNodeMap={me.savedNodeMap}
                nodeIds={me.props.nodeIds}
                viewDataProvider={me.props.viewDataProvider}
                removedNodeMap={me.removedNodeMap}
                allNodeMap={me.allNodeMap}
                relatedTo={me.props.relatedTo}
                updateTicket={me.candidateNodeTreeUpdateTicket}
                onSaveNode={me.onSaveNode}
              />
            </Modal>
          ) : null
        }
      </div>
    ) : null;
  }
}

CandidateNodeList.defaultProps = {
  bus: PB,
};

CandidateNodeList.propTypes = {
  bus: PropTypes.instanceOf(SimplePB),
  nodeIds: PropTypes.array.isRequired,
  relatedTo: PropTypes.object.isRequired,
  updateTicket: PropTypes.number.isRequired,
  viewDataProvider: PropTypes.instanceOf(ViewDataProvider).isRequired,
};

export default CandidateNodeList;