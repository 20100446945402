import React from 'react';
import style from '@/style/components/mainView/main.aiConsoleMessage.less';
import UserAvatar from "react-user-avatar";
import {getHttpUtil, getToken, REQUEST_BASE} from "@/utils/HttpUtil";
import {AvatarColors, IconTypes} from "@/constants/common";
import {Col, Row, Tooltip, Divider, Button} from "antd";
import Icon from "@/components/common/common.icon";
import moment from "moment";
import TimeDisplayField from "@/components/common/common.timeDisplayField";
// import {getNodeDisplayTitle, getNodeIcon, NODE_TYPE_TEXT} from '@/constants/vis.defaultDefine.1';
import {getViewBadgeList} from "@/components/common/common.functions";

const httpUtil2 = getHttpUtil(false, 'https://snap.joinmap.ai');
// const httpUtil2 = getHttpUtil(false, 'http://localhost:5000');
const API_CheckUserNewNotices = ({user_id}) => {
  return httpUtil2.get(`/notice_api/check`, {user_id});
};
const API_GetUserNotices = ({
                              user_id,
                              cate,
                              start_time,
                              end_time,
                              limit,
                              offset = 0,
                              save_read_time = 0,
                              latest = 0
                            }) => {
  return httpUtil2.get(`/notice_api/list`, {
    user_id,
    cate,
    start_time,
    end_time,
    limit,
    offset,
    axios: false,
    save_read_time,
    latest
  });
};

Date.prototype.Format = function (fmt) {
  let o = {
    "M+": this.getMonth() + 1, //月份
    "d+": this.getDate(), //日
    "H+": this.getHours(), //小时
    "m+": this.getMinutes(), //分
    "s+": this.getSeconds(), //秒
    "q+": Math.floor((this.getMonth() + 3) / 3), //季度
    "S": this.getMilliseconds() //毫秒
  };
  if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
  for (let k in o)
    if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
  return fmt;
}

// 鼠标是否点击
window.mouseDown = false;
document.body.onmousedown = function () {
  if (!window.mouseDown) {
    console.log('loopNoticeTip mouse clicked');
    window.mouseDown = true;
  }
}

// 组件
class UserStatAiNotices extends React.Component {
  state = {
    playSound: false
  }

  updateMessage = (nid) => {
    console.log('loopNoticeTip update nid=', nid);
    let me = this;
    const {key, item} = this.aiMsgKeyDict.hasOwnProperty(nid) ? this.aiMsgKeyDict[nid] : {};
    console.log('loopNoticeTip update key=', key);
    if (key && item) {
      const {notice_key, content, actions} = this.formatNodeTaskReminder(item, 1);
      me.props.bus.emit('aiConsole', 'message.update', {
        key: key,
        content: content,
        callback: (key) => {
          console.log('loopNoticeTip callback =', key);
          me.aiMsgKeyDict[notice_key] = {
            key: key['key'],
            item: item
          };
        },
        actions
      });
    }
  }
  // 带文字按钮样式
  btnStyle = {
    float: 'right',
    fontSize: '1rem',
    height: '2.5rem',
    width: '7rem',
    minWidth: '1.8rem',
    lineHeight: '1.3rem',
  }

  // 生成 节点任务 的消息主体
  formatNodeTaskReminder = (item, status = 0) => {
    let me = this;
    const notice_data = item['notice_data'] ? JSON.parse(item['notice_data']) : null;
    const notice_key = `ain-${item['id']}`

    // 判断等级
    let msg_classname = '';

    // 判断操作按钮
    let actions = [];


    let ok = <Tooltip title={`点击确认`} key={'ok-btn'}>
      <Button
        style={me.btnStyle}
        className={'ant-btn-icon'}
        onClick={e => {
          e.preventDefault();
          me.updateMessage(notice_key)
        }}
      >
        <Icon name={'icon-complete'} type={IconTypes.ICON_FONT}/> <span className={'plain-action'}
                                                                        style={{fontSize: '0.8rem'}}> 我知道了</span>
      </Button>
    </Tooltip>;

    let go = <Tooltip title={`前往查看`} key={'go-btn'}>
      <Button
        style={me.btnStyle}
        className={'ant-btn-icon'}
        onClick={e => {
          e.preventDefault();
          me.props.bus.emit('node_task', 'listModal.do_show', {type: 'my'});
        }}
      >
        <Icon name={'icon-hollow-arrow-right'} type={IconTypes.ICON_FONT}/> <span className={'plain-action'}
                                                                                  style={{fontSize: '0.8rem'}}> 前往查看</span>
      </Button>
    </Tooltip>;

    // 播放声音
    let playSoundFun = false;
    if (status === 0) { // 没有被点击消除了
      // 没有被点击消除 => 判断闪烁
      if (item['notice_level'] === 'a') {
        msg_classname = 'ani-twinkling animated';
        actions.push(ok);
        playSoundFun = true;
      } else if (item['notice_level'] === 'b') {
        msg_classname = ''
        actions.push(go);
      } else {
        msg_classname = '';
        actions.push(go);
      }
    } else {
      actions.push(go);
    }

    // 根据状态生成标题文字
    let titleText = '未知状态'
    if (notice_data['actType'] === 'new') { // 新待办
      titleText =
        <span><span>{notice_data['fromUser']['user_name'] || notice_data['fromUser']['user_nick']}</span> 给您创建了新待办：</span>;
    } else if (notice_data['actType'] === 'update') { // 更新 ，变更时间 人员什么的
      titleText =
        <span><span>{notice_data['fromUser']['user_name'] || notice_data['fromUser']['user_nick']}</span> 更新了给您的待办：</span>;
    } else if (notice_data['actType'] === 'done') { // 已办
      titleText =
        <span><span>{notice_data['fromUser']['user_name'] || notice_data['fromUser']['user_nick']}</span> 完成了您创建的待办：</span>;
    } else if (notice_data['actType'] === 'over') {// 办结
      titleText =
        <span><span>{notice_data['fromUser']['user_name'] || notice_data['fromUser']['user_nick']}</span> 办结了给您的待办：</span>;
    } else if (notice_data['actType'] === 'undo') {// 撤办
      titleText =
        <span><span>{notice_data['fromUser']['user_name'] || notice_data['fromUser']['user_nick']}</span> 撤销了给您的待办：</span>;
    }

    // 看板名称 和 节点名称 的长度处理
    let viewNameJsx = '';
    if (notice_data.hasOwnProperty('viewName') && notice_data['viewName'].length > 15) {
      notice_data['viewNameSub'] = notice_data['viewName'].substr(0, 15) + '...';
      viewNameJsx = <Tooltip title={'图谱: ' + notice_data['viewName']} placement={'left'}>
        <Row gutter={0} className={style['attribute-item']}>
          <Col span={4}>
            图谱:
          </Col>
          <Col span={20}>
            {notice_data['viewNameSub']}
          </Col>
        </Row>
      </Tooltip>;
    } else {
      viewNameJsx = <Row gutter={0} className={style['attribute-item']}>
        <Col span={4}>
          图谱:
        </Col>
        <Col span={20}>
          {notice_data['viewName']}
        </Col>
      </Row>;
    }

    let nodeNameJsx = '';
    if (notice_data.hasOwnProperty('nodeName') && notice_data['nodeName'].length > 15) {
      notice_data['nodeNameSub'] = notice_data['nodeName'].substr(0, 15) + '...'
      nodeNameJsx = <Tooltip title={'节点: ' + notice_data['nodeName']} placement={'left'}>
        <Row gutter={0} className={style['attribute-item']}>
          <Col span={4}>
            任务:
          </Col>
          <Col span={20}>
            {notice_data['nodeNameSub']}
          </Col>
        </Row>
      </Tooltip>;
    } else {
      nodeNameJsx = <Row gutter={0} className={style['attribute-item']}>
        <Col span={4}>
          任务:
        </Col>
        <Col span={20}>
          {notice_data['nodeName']}
        </Col>
      </Row>;
    }

    // 生成内容
    let content = <div className={style['body-frame'] + ' ' + msg_classname}>
      <div className={style['title-frame']}>
        <UserAvatar
          style={{'--avatar-size': '1rem', fontSize: '0.5rem', height: '1rem', width: '1rem', display: 'inline-block'}}
          size={'var(--avatar-size)'}
          name={notice_data ? notice_data['fromUser']['user_nick'] : 'a'}
          src={notice_data && notice_data['fromUser']['user_pic_id'] ? `${REQUEST_BASE}/user/user/file/${notice_data['fromUser']['user_pic_id']}?Authorization=${getToken()}` : undefined}
          colors={AvatarColors}
        />
        <span className={style['title']}
              style={{marginLeft: '0.55rem', marginTop: '-0.25rem'}}>{titleText}</span>
      </div>
      <hr/>
      <div className={style['content-frame']}>

        {viewNameJsx}

        <Row gutter={0} className={style['attribute-item']}>
          <Col span={4}>
            发布:
          </Col>
          <Col span={20}>
            {notice_data['userName'] || notice_data['userNick'] || '-'}
          </Col>
        </Row>

        <Row gutter={0} className={style['attribute-item']}>
          <Col span={4}>
            时间:
          </Col>
          <Col span={20}>
            <TimeDisplayField
              timestamp={moment(item['act_time_str'], 'YYYY-MM-DD HH:mm:ss').valueOf()}
              tooltipPlacement={'bottom'}
              description={'发布时间：'}
            />
            <span>&nbsp;&nbsp;▷▷&nbsp;&nbsp;</span>
            <TimeDisplayField
              timestamp={moment(notice_data['deadline_str'], 'YYYY-MM-DD HH:mm:ss').valueOf()}
              tooltipPlacement={'bottom'}
              description={'截止时间：'}
            />
          </Col>
        </Row>

        {nodeNameJsx}

        <Row gutter={0} className={style['attribute-item']}>
          <Col span={4}>
            等级:
          </Col>
          <Col span={20}>
            {notice_data['priority_str']}
          </Col>
        </Row>

        <Row gutter={0} className={style['attribute-item']}>
          <Col span={4}>
            状态:
          </Col>
          <Col span={20}>
            {notice_data['status_str']}
          </Col>
        </Row>

        {/*<Row gutter={0} className={style['attribute-item']}>*/}
        {/*  <Col span={4}>*/}
        {/*    {notice_data['status_str'] === '待办' ? '创建' : notice_data['status_str']}:*/}
        {/*  </Col>*/}
        {/*  <Col span={20}>*/}
        {/*    <TimeDisplayField*/}
        {/*      timestamp={moment(notice_data['update_timestamp_str'], 'YYYY-MM-DD HH:mm:ss').valueOf()}*/}
        {/*      tooltipPlacement={'bottom'}*/}
        {/*      description={notice_data['status_str'] === '待办' ? '创建' : notice_data['status_str'] + '时间：'}*/}
        {/*    />*/}
        {/*  </Col>*/}
        {/*</Row>*/}

      </div>
    </div>;
    return {
      notice_key,
      content,
      actions,
      playSoundFun
    }
  }

  // 循环体
  autoLoopNoticesTimestamp = new Date().Format("yyyy-MM-dd HH:mm:ss"); // 消息起始时间
  aiMsgKeyDict = {}; // 每个消息与key值对应
  loopDo = () => {
    let me = this;
    console.log('loopNoticeTip  3 loop');
    // 判断是否在图谱打开页面 url 判断
    let curUrl = me.props.history.location.pathname;
    console.log('loopNoticeTip curUrl=', curUrl);
    if (curUrl.indexOf('/mainview/relation/') === -1 || curUrl.indexOf('/mainview/dashboard/latest') === -1) {
      console.log('loopNoticeTip  非看板页面,不发送请求');
      return false;
    }

    // 判断是否是激活tab
    if (!window.TabIsActive) {
      console.log('loopNoticeTip  非当前页面,不发送请求');
      return false;
    }

    // 判断是否鼠标点击了
    if (!window.mouseDown) {
      console.log('loopNoticeTip  鼠标没有点击动作,不发送请求');
      return false;
    }

    // 发送提示信息 只有第一次会发送
    let beginMsgKey = undefined;
    if (me.loopTimes === 0) {
      me.props.bus.emit('aiConsole', 'message.push', {
        type: 'ai',
        content: <div>正在读取用户消息...</div>,
        callback: (key) => {
          beginMsgKey = key['key'];
        }
      });
    }

    // 拉取数据
    const userId = localStorage.getItem("userId");
    const end = new Date().Format("yyyy-MM-dd HH:mm:ss"); // 消息截止时间
    API_GetUserNotices({
      latest: 1,
      user_id: userId,
      // // start_time: me.loopTimes === 0 ? '' : me.autoLoopNoticesTimestamp,
      // // end_time: end,
      limit: 0,
      // // offset: 0,
      save_read_time: me.loopTimes === 0 ? 0 : 1
    }).then(res => {
      if (res && res.data && res.data.code === 0) {
        let notices = res.data.data.slice(0, 5); // 取前5个
        notices.reverse(); // 最新节点放到最下面
        if (notices.length > 0) {
          notices.forEach((item, idx) => {
            if (item['notice_cate'] === 'link_reminder') {

              const notice_data = item.hasOwnProperty('notice_data') && item['notice_data'] ? JSON.parse(item['notice_data']) : null;
              const notice_key = `ain-${item['id']}`
              console.log('loopNoticeTip  notice_key=', notice_key);

              // 看板名称 和 节点名称 的长度处理
              let viewNameJsx = '';
              if (notice_data.hasOwnProperty('viewName') && notice_data['viewName'].length > 15) {
                notice_data['viewNameSub'] = notice_data['viewName'].substr(0, 15) + '...';
                viewNameJsx = <Tooltip title={'图谱: ' + notice_data['viewName']} placement={'left'}>
                  <Row gutter={0} className={style['attribute-item']}>
                    <Col span={6}>
                      图谱名称:
                    </Col>
                    <Col span={18}>
                      {notice_data['viewNameSub']}
                    </Col>
                  </Row>
                </Tooltip>;
              } else {
                viewNameJsx = <Row gutter={0} className={style['attribute-item']}>
                  <Col span={6}>
                    图谱名称:
                  </Col>
                  <Col span={18}>
                    {notice_data['viewName']}
                  </Col>
                </Row>;
              }

              let nodeNameJsx = '';
              if (notice_data['nodeName'].length > 15) {
                notice_data['nodeNameSub'] = notice_data['nodeName'].substr(0, 15) + '...'
                nodeNameJsx = <Tooltip title={'新建节点: ' + notice_data['nodeName']} placement={'left'}>
                  <Row gutter={0} className={style['attribute-item']}>
                    <Col span={6}>
                      新建节点:
                    </Col>
                    <Col span={18}>
                      {notice_data['nodeNameSub']}
                    </Col>
                  </Row>
                </Tooltip>;
              } else {
                nodeNameJsx = <Row gutter={0} className={style['attribute-item']}>
                  <Col span={6}>
                    新建节点:
                  </Col>
                  <Col span={18}>
                    {notice_data['nodeName']}
                  </Col>
                </Row>;
              }


              let linkNodeNameJsx = '';
              if (notice_data['linkNodeName'].length > 15) {
                notice_data['linkNodeNameSub'] = notice_data['linkNodeName'].substr(0, 15) + '...'
                linkNodeNameJsx = <Tooltip title={'被相连节点: ' + notice_data['linkNodeName']} placement={'left'}>
                  <Row gutter={0} className={style['attribute-item']}>
                    <Col span={6}>
                    相连节点:
                    </Col>
                    <Col span={18}>
                      {notice_data['linkNodeNameSub']}
                    </Col>
                  </Row>
                </Tooltip>;
              } else {
                linkNodeNameJsx = <Row gutter={0} className={style['attribute-item']}>
                  <Col span={6}>
                  相连节点:
                  </Col>
                  <Col span={18}>
                    {notice_data['linkNodeName']}
                  </Col>
                </Row>;
              }

              // 操作按钮
              let actions = [<Tooltip title={`前往查看`} key={'reward'}>
                <Button
                  style={me.btnStyle}
                  className={'ant-btn-icon'}
                  onClick={e => {
                    e.preventDefault();
                    window.open(`${item['notice_link']}`, '_blank');
                  }}
                >
                  <Icon name={'icon-hollow-arrow-right'} type={IconTypes.ICON_FONT}/> <span
                  className={'plain-action'}
                  style={{fontSize: '0.8rem'}}> 前往查看</span>
                </Button>
              </Tooltip>];

              // 消息标题
              let titleText = `${notice_data['fromUser']['user_name'] || notice_data['fromUser']['user_nick']} 创建了与您关联的新节点`;

              me.props.bus.emit('aiConsole', 'message.push', {
                type: 'ai',
                content: <div className={style['body-frame']}>
                  <div className={style['title-frame']}>
                    <UserAvatar
                      style={{
                        '--avatar-size': '1rem',
                        fontSize: '0.5rem',
                        height: '1rem',
                        width: '1rem',
                        display: 'inline-block'
                      }}
                      size={'var(--avatar-size)'}
                      name={notice_data ? notice_data['fromUser']['user_nick'] : 'a'}
                      src={notice_data && notice_data['fromUser']['user_pic_id'] ? `${REQUEST_BASE}/user/user/file/${notice_data['fromUser']['user_pic_id']}?Authorization=${getToken()}` : undefined}
                      colors={AvatarColors}
                    />
                    <span className={style['title']}
                          style={{marginLeft: '0.55rem', marginTop: '-0.25rem'}}>{titleText}</span>
                  </div>
                  <hr/>
                  <div className={style['content-frame']}>
                    {viewNameJsx}
                    {nodeNameJsx}
                    {linkNodeNameJsx}
                    <Row gutter={0} className={style['attribute-item']}>
                      <Col span={6}>
                        关联时间:
                      </Col>
                      <Col span={18}>
                        <TimeDisplayField
                          timestamp={moment(item['act_time_str'], 'YYYY-MM-DD HH:mm:ss').valueOf()}
                          tooltipPlacement={'bottom'}
                          description={'创建时间：'}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>,
                callback: (key) => {
                  console.log('loopNoticeTip callback =', key);
                  me.aiMsgKeyDict[notice_key] = {
                    key: key['key'],
                    item: item
                  };
                },
                actions
              });
            } else if (item['notice_cate'] === 'node_task_reminder') {
              const {notice_key, content, actions, playSoundFun} = me.formatNodeTaskReminder(item, 0);
              me.props.bus.emit('aiConsole', 'message.push', {
                type: 'ai',
                content: content,
                callback: (key) => {
                  console.log('loopNoticeTip callback =', key);
                  if (playSoundFun) {
                    if (!(res.data.hasOwnProperty('mute') && !!res.data['mute'])) {
                      me.playSound();
                    }
                  }
                  me.aiMsgKeyDict[notice_key] = {
                    key: key['key'],
                    item: item
                  };
                },
                actions
              });
            } else if (item['notice_cate'] === 'badge_reminder') {

              const notice_data = item['notice_data'] ? JSON.parse(item['notice_data']) : null;
              const notice_key = `ain-${item['id']}`
              console.log('loopNoticeTip  notice_key=', notice_key);

              // 看板名称 和 节点名称 的长度处理
              let viewNameJsx = '';
              if (notice_data.hasOwnProperty('view_name') && notice_data['view_name'].length > 15) {
                notice_data['viewNameSub'] = notice_data['view_name'].substr(0, 15) + '...';
                viewNameJsx = <Tooltip title={'图谱: ' + notice_data['view_name']} placement={'left'}>
                  <Row gutter={0} className={style['attribute-item']}>
                    <Col span={4}>
                      图谱:
                    </Col>
                    <Col span={20}>
                      {notice_data['viewNameSub']}
                    </Col>
                  </Row>
                </Tooltip>;
              } else {
                viewNameJsx = <Row gutter={0} className={style['attribute-item']}>
                  <Col span={4}>
                    图谱:
                  </Col>
                  <Col span={20}>
                    {notice_data['view_name']}
                  </Col>
                </Row>;
              }

              let nodeNameJsx = '';
              if (notice_data['node_fname'].length > 15) {
                notice_data['nodeNameSub'] = notice_data['node_fname'].substr(0, 15) + '...'
                nodeNameJsx = <Tooltip title={'新建节点: ' + notice_data['node_fname']} placement={'left'}>
                  <Row gutter={0} className={style['attribute-item']}>
                    <Col span={4}>
                      节点:
                    </Col>
                    <Col span={20}>
                      {notice_data['nodeNameSub']}
                    </Col>
                  </Row>
                </Tooltip>;
              } else {
                nodeNameJsx = <Row gutter={0} className={style['attribute-item']}>
                  <Col span={4}>
                    节点:
                  </Col>
                  <Col span={20}>
                    {notice_data['node_fname']}
                  </Col>
                </Row>;
              }

              // 奖名
              let textJsx = '';
              if (notice_data['text'].length > 15) {
                notice_data['textSub'] = notice_data['text'].substr(0, 15) + '...'
                textJsx = <Tooltip title={'新建节点: ' + notice_data['text']} placement={'left'}>
                  <Row gutter={0} className={style['attribute-item']}>
                    <Col span={4}>
                      奖名:
                    </Col>
                    <Col span={20}>
                      {notice_data['textSub']}
                    </Col>
                  </Row>
                </Tooltip>;
              } else {
                textJsx = <Row gutter={0} className={style['attribute-item']}>
                  <Col span={4}>
                    奖名:
                  </Col>
                  <Col span={20}>
                    {notice_data['text']}
                  </Col>
                </Row>;
              }

              // 礼物
              let iconTypeJsx = <Icon
                name={getViewBadgeList()[notice_data['type']].icon}
                type={IconTypes.ICON_FONT}
                style={{fontSize: 1.2 + 0.2 * (notice_data['type'] + 1) + 'rem'}}
              />


              // 操作按钮
              let actions = [<Tooltip title={`前往查看`} key={'reward'}>
                <Button
                  style={me.btnStyle}
                  className={'ant-btn-icon'}
                  onClick={e => {
                    e.preventDefault();
                    // window.open(`${item['notice_link']}`, '_blank');
                    me.props.bus.emit('badge', 'badgeList.do_show', true);
                  }}
                >
                  <Icon name={'icon-hollow-arrow-right'} type={IconTypes.ICON_FONT}/> <span
                  className={'plain-action'}
                  style={{fontSize: '0.8rem'}}> 前往查看</span>
                </Button>
              </Tooltip>];

              // 消息标题
              let titleText = `${notice_data['by_user_name'] || notice_data['by_user_nick']} 给您点赞啦`;

              me.props.bus.emit('aiConsole', 'message.push', {
                type: 'ai',
                content: <div className={style['body-frame']}>
                  <div className={style['title-frame']}>
                    <UserAvatar
                      style={{'--avatar-size': '1rem', height: '1rem', width: '1rem', display: 'inline-block'}}
                      size={'var(--avatar-size)'}
                      name={notice_data ? notice_data['by_user_nick'] : 'a'}
                      src={notice_data && notice_data['by_user_pic_id'] ? `${REQUEST_BASE}/user/user/file/${notice_data['by_user_pic_id']}?Authorization=${getToken()}` : undefined}
                      colors={AvatarColors}
                    />
                    <span className={style['title']}
                          style={{marginLeft: '0.55rem', marginTop: '-0.25rem'}}>{titleText}</span>
                  </div>
                  <hr/>
                  <div className={style['content-frame']}>
                    {viewNameJsx}
                    {nodeNameJsx}
                    {textJsx}
                    <Row gutter={0} className={style['attribute-item']}>
                      <Col span={4}>
                        礼物:
                      </Col>
                      <Col span={20}>
                        {iconTypeJsx}
                      </Col>
                    </Row>
                    {/*<Row gutter={0} className={style['attribute-item']}>*/}
                    {/*  <Col span={4}>*/}
                    {/*    赠送人:*/}
                    {/*  </Col>*/}
                    {/*  <Col span={20}>*/}
                    {/*    {notice_data['by_user_name'] || notice_data['by_user_nick']}*/}
                    {/*  </Col>*/}
                    {/*</Row>*/}
                    <Row gutter={0} className={style['attribute-item']}>
                      <Col span={4}>
                        时间:
                      </Col>
                      <Col span={20}>
                        <TimeDisplayField
                          timestamp={moment(item['act_time_str'], 'YYYY-MM-DD HH:mm:ss').valueOf()}
                          tooltipPlacement={'bottom'}
                          description={'创建时间：'}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>,
                callback: (key) => {
                  console.log('loopNoticeTip callback =', key);
                  me.aiMsgKeyDict[notice_key] = {
                    key: key['key'],
                    item: item
                  };
                },
                actions
              });
            } else {
              // other.push(item);
            }
          });
          // console.log('loopNoticeTip other =', other);
          if (beginMsgKey) {
            me.props.bus.emit('aiConsole', 'message.update', {
              key: beginMsgKey,
              type: 'ai',
              content: <div>已显示您的新消息</div>
            });
          }
        } else {
          console.log('loopNoticeTip me.loopTimes =', me.loopTimes);
          if (beginMsgKey) {
            me.props.bus.emit('aiConsole', 'message.update', {
              key: beginMsgKey,
              type: 'ai',
              content: <div>您最近没有新消息</div>
            });
          }
        }

        // 是否只取最后5条，其他的折叠并提示前往消息中心浏览全部 ?最后显示
        if (res.data.data.length > 5) {
          me.props.bus.emit('aiConsole', 'message.push', {
            type: 'ai',
            content: <div>您还有更多通知请<a className={'plain-action'}
                                     style={{marginLeft: '0.5em'}} onClick={e => {
              e.preventDefault();
              window.open(`/notice`, '_blank');
            }}>前往消息中心</a> 浏览</div>
          });
        }

        me.autoLoopNoticesTimestamp = end;
        beginMsgKey = undefined; // 取消第一次
      } else {
        // 获取数据错误
      }
    }).catch(e => {
    });
  }

  // 定时获取通知消息数据
  loopTimes = 0;
  autoLoopNoticesInterval = undefined;
  loopNoticeTip = (me) => {
    if (!me.autoLoopNoticesInterval) {
      me.autoLoopNoticesInterval = setInterval(() => {
        me.loopDo();
        me.loopTimes = 1;
      }, 30000);
    }
  }

  componentDidMount() {
    let me = this;
    // 播放声音
    this.audioDom.load();
    setTimeout(() => {
      // 第一次先跑一遍
      me.loopDo();
      me.loopTimes = 1;
      // 循环数据
      me.loopNoticeTip(me);
    }, 5000)

    // 播放声音
    me.props.bus.sub(this, 'aiConsole', 'audio.play', status => {
      if (!!status) {
        me.playSound();
      } else {
        me.stopSound();
      }
    });
  }

  playBtn = undefined;
  audioDom = undefined;
  playSound = () => {
    // document.getElementById('play_sound_btn').click();
    // this.playBtn.click()
    this.audioDom.play();
  }

  stopSound = () => {
    // document.getElementById('play_sound_btn').click();
    // this.playBtn.click()
    this.audioDom.stop()
  }


  render() {
    return <React.Fragment>
      <audio
        ref={(audio) => {
          this.audioDom = audio;
        }}
        controls={false}
        preload="auto"
        controlsList="nodownload"
        src={"/assets/a_notice.wav"}
      >
        <track kind="captions"/>
        您的浏览器不支持 audio 元素。
      </audio>

      {/*<Button*/}
      {/*  ref={(btn) => {*/}
      {/*    this.playBtn = btn;*/}
      {/*  }}*/}

      {/*  onClick={() => {*/}
      {/*    this.playSound();*/}
      {/*  }}>测试播放</Button>*/}
    </React.Fragment>
  }
}

export default UserStatAiNotices;