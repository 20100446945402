import {bindUtil, withPromiseMessageDecorator} from "@/libs/core-decorators";
import messageProxy from '@/components/common/common.messageProxy';
import {API_BindWeChat, API_GetUserInfo, API_UnbindWeChat} from "@/api/account";
import {accountOperationMessages} from "@/constants/messages";
import {getErrorInfo as commonGetErrorInfo} from "@/components/common/common.functions";

const overrideMessages = {};

export const UserInfoLoadingStatus = Object.freeze({
  IDLE: 'user_info.loading.idle',
  PROCESSING: 'user_info.loading.waiting',
  SUCCESS: 'user_info.loading.success',
  FAILED: 'user_info.loading.failed',
});

export const WeChatBindingStatus = Object.freeze({
  IDLE: 'we_chat.binding.idle',
  PROCESSING: 'we_chat.binding.waiting',
  SUCCESS: 'we_chat.binding.success',
  FAILED: 'we_chat.binding.failed',
});

@withPromiseMessageDecorator(messageProxy(accountOperationMessages, overrideMessages))
@bindUtil.asSourceClass
class Account {
  static userInfo = undefined;

  static userInfoLoadingStatus = {
    status: UserInfoLoadingStatus.IDLE,
    errorCode: 0,
    errorMsg: '',
  };

  static weChatBindingStatus = {
    status: WeChatBindingStatus.IDLE,
    errorCode: 0,
    errorMsg: '',
  };

  static bindWeChatAccount = (token, autoMerge = 0) => {
    return new Promise((resolve, reject) => {
      Account.weChatBindingStatus = {
        status: WeChatBindingStatus.PROCESSING,
        errorCode: 0,
        errorMsg: '',
      };
      API_BindWeChat(token, autoMerge).then(response => {
        if (response && response.data && response.data.code === 0) {
          Account.weChatBindingStatus = {
            status: WeChatBindingStatus.SUCCESS,
            errorCode: 0,
            errorMsg: '',
          };
          resolve();
        } else {
          const {code, msg} = Account.getErrorInfo(response);
          const data = response && response.data ? response.data.data : undefined;
          Account.weChatBindingStatus = {
            status: WeChatBindingStatus.FAILED,
            errorCode: code,
            errorMsg: msg,
          };
          reject({code, msg, data});
        }
      }).catch(error => {
        const {code, msg} = Account.getErrorInfo(error);
        Account.weChatBindingStatus = {
          status: WeChatBindingStatus.FAILED,
          errorCode: code,
          errorMsg: msg,
        };
        reject({code, msg, data: undefined});
      });
    });
  };

  static unbindWeChatAccount = () => {
    return new Promise((resolve, reject) => {
      Account.weChatBindingStatus = {
        status: WeChatBindingStatus.PROCESSING,
        errorCode: 0,
        errorMsg: '',
      };
      API_UnbindWeChat().then(response => {
        if (response && response.data && response.data.code === 0) {
          Account.weChatBindingStatus = {
            status: WeChatBindingStatus.SUCCESS,
            errorCode: 0,
            errorMsg: '',
          };
          resolve();
        } else {
          const {code, msg} = Account.getErrorInfo(response);
          Account.weChatBindingStatus = {
            status: WeChatBindingStatus.FAILED,
            errorCode: code,
            errorMsg: msg,
          };
          reject({code, msg});
        }
      }).catch(error => {
        const {code, msg} = Account.getErrorInfo(error);
        Account.weChatBindingStatus = {
          status: WeChatBindingStatus.FAILED,
          errorCode: code,
          errorMsg: msg,
        };
        reject({code, msg});
      });
    });
  };

  static loadUserInfo = () => {
    return new Promise((resolve, reject) => {
      Account.userInfo = undefined;
      Account.userInfoLoadingStatus = {
        status: UserInfoLoadingStatus.PROCESSING,
        errorCode: 0,
        errorMsg: '',
      };
      API_GetUserInfo().then(response => {
        if (response && response.data && response.data.code === 0) {
          Account.userInfo = response.data.data;
          Account.userInfoLoadingStatus = {
            status: UserInfoLoadingStatus.SUCCESS,
            errorCode: 0,
            errorMsg: '',
          };
          resolve(Account.userInfo);
        } else {
          const {code, msg} = Account.getErrorInfo(response);
          Account.userInfoLoadingStatus = {
            status: UserInfoLoadingStatus.SUCCESS,
            errorCode: code,
            errorMsg: msg,
          };
          reject({code, msg});
        }
      }).catch(error => {
        const {code, msg} = Account.getErrorInfo(error);
        Account.userInfoLoadingStatus = {
          status: UserInfoLoadingStatus.SUCCESS,
          errorCode: code,
          errorMsg: msg,
        };
        reject({code, msg});
      });
    });
  };

  /**
   * @private
   * 通過Axios请求返回的错误信息获取错误详情
   *
   * @param {*} error
   *
   * @return {{msg: string, code: number}}
   */
  static getErrorInfo = commonGetErrorInfo;
}

export default Account;

export const overrideNextMessage = (method, msg) => {
  overrideMessages[method] = msg;
};