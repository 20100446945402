import React from 'react';
import { Layout } from 'antd';
import { Redirect, Route, Switch } from 'react-router-dom';
import {connect} from 'react-redux';
import moment from 'moment';

import PB from '@/libs/simplePB';

import Loading from '@/components/common/common.loading';
import ViewDataProvider from '@/components/common/dataProvider/common.dataProvider.view';
import { Copyright } from '@/components/framework/frame.components';
import {stringToMoment} from '@/components/common/common.functions';

import Timeline from '@/components/relationView/relation.timeline';
import Map from '@/components/relationView/relation.map';
//import Map from '@/components/relationView/relation.map.v2';

import style from '@/style/containers/relationView.less';
import TeamworkLogic from '@/components/common/view/teamwork/common.view.teamwork.logic';
import RelationAIConsole from '@/components/common/relation/common.relation.aiConsole';

import _ from 'lodash';
import BusinessTool from '@/components/mainView/main.toolbar.work';
import BadgeInfo from "@/components/mainView/main.badgeInfo";
// import toolbarStyle from '@/style/components/main.toolbar.less';
import { SysUIConfig } from '@/constants/sys.config';
import {
	changePwdInitAction,
	changeUserBasicInfoAction,
	doChangePwdAction,
	doLogoutAction,
} from '@/redux-saga/account/actions';
import { submitFeedbackAction, submitPrintingAction } from '@/redux-saga/system/actions';
import StickyNodes from '@/components/common/common.stickyNodes';
import Feedback from '@/components/feedback/feedback.1.0';
import NodeTodo from '@/components/common/node/todo/common.node.todo';
import ViewManager from '@/components/common/common.viewManager';
import { IconTypes } from '@/constants/common';
import Icon from '@/components/common/common.icon';

const ROOT_PATH = '/relation/:viewId';

const originalViewNodesPerPage = 100;
const maxNodesNumber = 300; // 最大节点数据

const paginationViewNodesByDateTime = (viewNodeList, viewType) => {
	if (!viewNodeList || viewNodeList.length <= 0) return [];
	// console.log(' paginationViewNodesByDateTime ->  viewNodeList:', viewNodeList);
	let currentPos = 0, maxDateTime, originalViewNodesRangeList = [];
	if (viewType === 'tagTime') {
		maxDateTime = moment(
			viewNodeList[0].tagTime
		).startOf('month').add(1, 'month');
	} else if (viewType === 'linkTime') {
		maxDateTime = moment(
			viewNodeList[0].linkTime
		).startOf('month').add(1, 'month');
	} else {
		maxDateTime = moment(
			viewNodeList[0].updateTime || viewNodeList[0].linkTime
		).startOf('month').add(1, 'month');
	}

	// console.log(' paginationViewNodesByDateTime -> maxDateTime: ', maxDateTime.format('YYYY-MM-DD HH:mm:ss'));

	let currentGroupStart = maxDateTime.clone().subtract(1, 'month'),
		currentGroupEnd = maxDateTime.clone(),
		currentStart = maxDateTime.clone().subtract(1, 'month').add(20, 'day'),
		currentEnd = maxDateTime.clone(),
		inGroupData = [
			{monthStart: currentGroupStart, nodeList: [], pageTotal: 0},
			{monthStart: currentGroupStart, nodeList: [], pageTotal: 0},
			{monthStart: currentGroupStart, nodeList: [], pageTotal: 0},
		],
		posInGroup = 0,
		sumFn = (inGroupData, currentGroupStart) => {
			// 计算总数
			if ((inGroupData[0].nodeList.length + inGroupData[1].nodeList.length + inGroupData[2].nodeList.length) <=
				originalViewNodesPerPage) {

				// 合并
				originalViewNodesRangeList.push({
					monthStart: currentGroupStart,
					idx: 0,
					pageTotal: _.concat(inGroupData[0].nodeList, inGroupData[1].nodeList, inGroupData[2].nodeList).length,
					hasSubPage: [_.concat(inGroupData[0].nodeList, inGroupData[1].nodeList, inGroupData[2].nodeList)],
					nodeList: _.concat(inGroupData[0].nodeList, inGroupData[1].nodeList, inGroupData[2].nodeList),
				});
			} else {
				// 分开添加
				inGroupData.forEach((data, idx) => {
					if (data.nodeList.length > maxNodesNumber) {
						let subPage = Math.ceil(data.nodeList.length / maxNodesNumber);
						let subNodeList = [];
						for(let i = 0; i < subPage; i++) {
							subNodeList[i] = data.nodeList.slice(i * maxNodesNumber, i * maxNodesNumber + maxNodesNumber);
						}
						originalViewNodesRangeList.push({
							monthStart: data.monthStart,
							idx: 3 - idx,
							pageTotal: data.nodeList.length,
							hasSubPage: subNodeList,
							nodeList: data.nodeList,
						});
					} else {
						originalViewNodesRangeList.push({
							monthStart: data.monthStart,
							idx: 3 - idx,
							pageTotal: data.nodeList.length,
							hasSubPage: [data.nodeList],
							nodeList: data.nodeList,
						});
					}

				});
			}

		};

	while (currentPos < viewNodeList.length) {
		let node = viewNodeList[currentPos], nodeDateTime;
		if (viewType === 'tagTime') {
			nodeDateTime = moment(node.tagTime);
		} else if (viewType === 'linkTime') {
			nodeDateTime = moment(node.linkTime);
		} else {
			nodeDateTime = moment(node.updateTime || node.linkTime)
		}
		if (currentGroupStart.isAfter(nodeDateTime)) { // 是否已到上个月
			sumFn(inGroupData, currentGroupStart);
			// 下一个月
			currentGroupStart = currentGroupStart.clone().subtract(1, 'month');
			currentGroupEnd.subtract(1, 'month');
			currentStart = currentGroupStart.clone().add(20, 'day');
			currentEnd = currentGroupEnd.clone();
			inGroupData = [
				{monthStart: currentGroupStart, nodeList: [], pageTotal: 0},
				{monthStart: currentGroupStart, nodeList: [], pageTotal: 0},
				{monthStart: currentGroupStart, nodeList: [], pageTotal: 0},
			];
			posInGroup = 0;
		} else if (currentStart.isAfter(nodeDateTime)) {
			// 已到上个10天
			currentEnd = currentStart.clone();
			currentStart.subtract(10, 'day');
			posInGroup++;
		} else {
			inGroupData[posInGroup].nodeList.push(node);
			currentPos++;
		}
	}
	if (currentPos > 0) {
		sumFn(inGroupData, currentGroupStart);
	}
	originalViewNodesRangeList.reverse();
	return originalViewNodesRangeList;
};

const initRem = () => {
	let documentWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
	let result = 0;

	if (documentWidth > 3999) {
		result = 24;
	} else if (documentWidth > 1920) {
		result = 18;
	} else {
		result = 14;
	}

	return result;
};

class RelationView extends React.Component {

	state = {
		readonly: false,
		status: 'processing',
		viewTitle: undefined,
		viewInfo: undefined,
		userList: [],
		// 当前展示的原图谱中的节点
		currentShownOriginalViewNodes: [],
		// 当前展示的原图谱中节点分页数据页码
		currentOriginalViewNodesPage: 0,
		viewType: 'updateTime', // 视图展示类型  0 按更新时间展示  1 按标签时间展示
		currentUserInfo: undefined, // 当前用户信息
		filterType: {
			all: {
				key: 'all',
				text: '全部',
			},
		}, // 筛选的类型
		filterCurKey: 'all',
		viewNodeList: [], // 节点列表
		timelineNodesList: [], // 标签带时间的节点列表
	};

	viewId = this.props.match.params.viewId;

	viewDataProvider = new ViewDataProvider(this.viewId);

	// bus = new SimplePB();

	excludedNodeIds = [];

	aiConfigRef = undefined;

	allViewNodeList = {}; // 图谱所有节点

	rem = initRem();

	aiConfigRect = {
		height: window.innerHeight * 0.8,
		width: 28 * this.rem,
	};

	componentDidMount() {
		let me = this;
		let viewId = me.props.match.params.viewId;
		let resultType = me.props.match.params.resultType;
		let getStorageNodes = JSON.parse(localStorage.getItem(`MT_nodes_${viewId}`));
		let getStorageNodesIds = JSON.parse(localStorage.getItem(`MT_nodeIds_filter_${viewId}`));
		let getStorageViewInfo = JSON.parse(localStorage.getItem(`MT_view_${viewId}`));
		let getStorageMembers = JSON.parse(localStorage.getItem(`MT_members_${viewId}`));

		if (getStorageViewInfo && getStorageNodes) {
			let nodeList = [];
			me.allViewNodeList['all'] = Object.values(getStorageNodes);
			me.state.filterCurKey = 'all';

			if (getStorageNodesIds) {
				// 用户选择了筛选节点
				nodeList = getStorageNodesIds.nodeIds.map(id => {
					return getStorageNodes[id];
				});

				me.allViewNodeList[getStorageNodesIds.key] = nodeList || [];
				me.state.filterType[getStorageNodesIds.key] = {
					key: getStorageNodesIds.key,
					text: getStorageNodesIds.text,
				};
				me.state.filterCurKey = getStorageNodesIds.key;
			} else {
				nodeList = me.allViewNodeList.all;
			}

			if (getStorageMembers) {
				me.setState({
					viewInfo: getStorageViewInfo,
					viewTitle: getStorageViewInfo.name,
					filterType: me.state.filterType,
					filterCurKey: me.state.filterCurKey,
					userList: getStorageMembers,
					status: 'success',
				});
				me.filterNodes(nodeList);
			} else {
				PB.sub(me, 'teamwork', 'member.list.success', ({viewId, userList}) => {
					if (viewId === viewId) {
						me.setState({
							viewInfo: getStorageViewInfo,
							viewTitle: getStorageViewInfo.name,
							filterType: me.state.filterType,
							filterCurKey: me.state.filterCurKey,
							userList,
							status: 'success',
						});

						me.filterNodes(nodeList);
					}
				});
				// 触发获取协作成员信息
				PB.emit('teamwork', 'member.list.do', {viewId: viewId, forceReload: false});
			}
		} else {
			// me.viewDataProvider = new ViewDataProvider(me.props.match.params.viewId);
			me.viewDataProvider.loadData().then(viewData => {
				// console.log('relationView -> loadData -> viewData ->', viewData);

				// 获取协作成员信息列表成功
				PB.sub(me, 'teamwork', 'member.list.success', ({viewId, userList}) => {
					if (viewId === me.viewId) {
						me.setState({
							userList,
							viewTitle: viewData.viewInfo.name,
							viewInfo: viewData.viewInfo,
							filterType: me.state.filterType,
							filterCurKey: me.state.filterCurKey,
							status: 'success',
						});

						let allNodeList = {};
						let nodeList = viewData.data.nodes.get().map(node => {
							let simpleNode = {
								id: node.id,
								fname: node.fname,
								description: node.description,
								tags: node.tags,
								userId: node.userId,
								type: node.type,
								lev: node.lev,
								updateTime: node.updateTime,
								linkTime: node.linkTime,
							};
							allNodeList[node.id] = simpleNode;

							return simpleNode;
						});
						me.allViewNodeList['all'] = nodeList;
						me.filterNodes(nodeList);
						localStorage.setItem(`MT_nodes_${viewId}`, JSON.stringify(allNodeList));
					}
				});

				// 触发获取协作成员信息
				PB.emit('teamwork', 'member.list.do', {viewId: me.props.match.params.viewId, forceReload: false});
			}).catch(error => {
				// 加载出错
				console.log('加载图谱信息失败: ', error);
				me.setState({status: 'failed'});
			});
		}

		me.aiConfigRect = {
			height: me.aiConfigRef.containerBoundingRect.consoleHeight,
			width: me.aiConfigRef.containerBoundingRect.consoleWidth,
		};

		PB.sub(me, 'teamwork', 'member.list.do', ({viewId}) => {
			if (me.viewId !== viewId) return;

			if (me.state.viewInfo && me.state.userList.length > 0) {
				// 已经有协作成员数据时，直接使用
				PB.emit('teamwork', 'member.list.success', {viewId, userList: me.state.userList});
			} else {
				// 没有协作成员数据时，从接口获取
				ViewManager.loadViewTeamworkMembers(viewId).then(data => {
					PB.emit('teamwork', 'member.list.success', {viewId, userList: data});
				}).catch(error => {
					console.log('获取图谱协作列表失败', error);
					PB.emit('teamwork', 'member.list.failed', {viewId});
				});
			}
		});
	};

	componentWillUnmount() {
		PB.remove(this);
		this.viewDataProvider.unSubscribe(this);
	}

	/**
	 * 切换视图（按更新时间/按标签时间）
	 * e.target.value： 0 按更新时间展示  1 按标签时间展示
	 */
	switchView = value => {
		let me = this;
		// e.stopPropagation();
		console.log(' relationView -> switchView -> value :', value);
		me.setState({
			viewType: value,
		}, () => {
			me.filterNodes(me.allViewNodeList[me.state.filterCurKey]);
		});
	};

	// 重新加载节点
	resetNodes = (type) => {
		let me = this;
		// console.log(' relationView -> resetNodes -> type :', type);
		// console.log(' relationView -> resetNodes -> me.allViewNodeList[type] :', me.allViewNodeList[type]);
		if (type === 'all') {
			localStorage.removeItem(`MT_nodeIds_filter_${me.state.viewInfo.viewId}`);
			this.setState({
				filterCurKey: type,
				filterType: {
					all: {
						key: 'all',
						text: '全部',
					},
				},
			});
		} else {
			this.setState({
				filterCurKey: type,
			});
		}

		me.filterNodes(me.allViewNodeList[type]);
	};

	// 根据要求筛选数据
	filterNodes = (nodes) => {
		let me = this;
		// console.log(' relationView -> filterNodes -> me.state.viewType :', me.state.viewType);
		// console.log(' relationView -> filterNodes -> nodes :', nodes);
		if (me.state.viewType === 'tagTime') {
			let tagTimeNodes = [];
			nodes.forEach(node => {
				node.tags.find(tag => {
					let dateTag = stringToMoment(tag);
					if (dateTag) {
						node['tagTime'] = dateTag.format('YYYY-MM-DD HH:mm:ss');
						return true;
					}
					return false;
				});
				if (node['tagTime']) {
					tagTimeNodes.push(node);
				}
			});
			tagTimeNodes.sort((a, b) => moment(b.tagTime).valueOf() - moment(a.tagTime).valueOf());
			// console.log(' relationView -> filterNodes -> tagTimeNodes :', tagTimeNodes);
			me.setState({
				timelineNodesList: tagTimeNodes,
			})
		} else if (me.state.viewType === 'linkTime') {
			let viewNodeList = nodes;

			viewNodeList = viewNodeList.filter(n => !!n['linkTime'])
				.sort((a, b) => -`${a['linkTime']}`
					.localeCompare(`${b['linkTime']}`));
			// console.log('relationView -> filterNodes -> viewNodeList ->', viewNodeList);
			me.setState({
				viewNodeList,
				timelineNodesList: viewNodeList,
			})
		} else {
			let viewNodeList = nodes;

			viewNodeList = viewNodeList.filter(n => !!(n['updateTime'] || n['linkTime']))
				.sort((a, b) => -`${a['updateTime'] || a['linkTime']}`
					.localeCompare(`${b['updateTime'] || b['linkTime']}`));
			// console.log('relationView -> filterNodes -> viewNodeList ->', viewNodeList);
			me.setState({
				viewNodeList,
				timelineNodesList: viewNodeList,
			})
		}

	};

	render() {
		let me = this;
		const currentUserId = parseInt(localStorage.getItem('userId'));

		let pathName = me.props.history.location.pathname.indexOf('map') > -1 ? ' 地理视图' : (
			me.props.history.location.pathname.indexOf('timeline') > -1 ? ' 时间视图' : null
		);

		return (
			<Layout className={`${style['frame']} dark-theme`} style={{minHeight: window.innerHeight, minWidth: window.innerWidth}}>
				<Loading/>
				{
					me.state.viewInfo ? (
						<TeamworkLogic
							viewOwnerId={me.state.viewInfo.userId}
							currentUserId={currentUserId}
							viewId={me.props.match.params.viewId}
							bus={PB}
						/>
					) : null
				}
				{
					me.state.viewTitle ? (
						<Layout.Header className={style['header']}>
							<div className={style["menu-bar"]} style={{visibility: SysUIConfig.MainMenu.visibility}}>
								<BusinessTool
									isViewer={true} // {match.params.category !== 'relation'} // 只有relation才可编辑
									className={style["bar"]}
									userInfo={me.props.userInfo}
									viewId={me.props.match.params.viewId}
								/>
							</div>
							<div className={style['view-title']}>
								<span
									style={{
										borderBottom: "3px double",
									}}
								>
		              {`${me.state.viewTitle} ${pathName}`}
									{me.state.viewInfo.lock === 1 ? (<Icon type={IconTypes.ICON_FONT} name={'icon-lock-position'} style={{marginLeft: '0.5rem'}} />) : null}
		            </span>
							</div>
							<BadgeInfo
								viewType={'map'}
								viewDataProvider={me.viewDataProvider}
								currentUserInfo={me.props.userInfo}
								doChangePwd={me.props.doChangePwd}
								onLogout={me.props.doLogout}
								history={me.props.history}
								changeUserBasicInfoStatus={me.props.changeUserBasicInfoStatus}
								onSaveUserBasicInfo={me.props.onChangeUserBasicInfo}
								style={{visibility: SysUIConfig.BadgeInfo.visibility}}
							/>
						</Layout.Header>
					) : null
				}

				<Switch>
					<Route path={`${ROOT_PATH}/timeline/:nodeId?`} key='/timeline' component={({match, history}) => (
						<React.Fragment>
							{
								me.state.viewInfo ? (
									<Timeline
										className={style['timeline']}
										viewId={me.props.match.params.viewId}
										viewType={me.state.viewType}
										viewInfo={me.state.viewInfo}
										viewNodeList={me.state.timelineNodesList}
										userList={me.state.userList}
										paginationViewNodes={paginationViewNodesByDateTime}
										switchView={me.switchView}
										status={me.state.status}
										filterType={me.state.filterType}
										filterCurKey={me.state.filterCurKey}
										resetNodes={me.resetNodes}
										viewDataProvider={me.viewDataProvider}
									/>
								) : null
							}
						</React.Fragment>
					)}/>
					<Route path={`${ROOT_PATH}/map/:nodeId?`} key='/map' component={(/*{match}*/) => (
						<React.Fragment>
							{ me.state.viewInfo ? (
								<Map
									viewId={me.props.match.params.viewId}
									viewInfo={me.state.viewInfo}
									className={style['map']}
									viewNodeList={me.state.viewNodeList}
									userList={me.state.userList}
									paginationViewNodes={paginationViewNodesByDateTime}
									status={me.state.status}
									filterType={me.state.filterType}
									filterCurKey={me.state.filterCurKey}
									resetNodes={me.resetNodes}
									viewDataProvider={me.viewDataProvider}
								/>
							) : null}

						</React.Fragment>
					)}/>
					<Route render={() => <Redirect to={`/`}/>}/>
				</Switch>
				<RelationAIConsole
					ref={ele => {
						if (!ele || ele === me.aiConfigRef) return;
						me.aiConfigRef = ele;
					}}
					currentUserInfo={me.props.userInfo}
					initialLeft={window.innerWidth - me.aiConfigRect.width - 3}
					initialTop={ (window.innerHeight - me.aiConfigRect.height) / 2}
					viewId={me.props.match.params.viewId} 
					networkRef={me.viewDataProvider}
				/>
				<Copyright/>

				{/* 任务交待办 */}
				<NodeTodo currentUserId={currentUserId} />
				{/* 客服反馈 */}
				<Feedback
					feedbackLoading={this.props.feedbackLoading}
					feedbackResult={this.props.feedbackResult}
					submitFeedbackAction={this.props.submitFeedbackAction}
				/>
				{/* 便签 */}
				<StickyNodes />
			</Layout>
		);
	}
}

export default connect(
	state => ({
		loginStatus: state.account.loginStatus,
		userInfo: state.account.userInfo,
		changePwdStatus: state.account.changePwdStatus,
		feedbackLoading: state.system.feedbackLoading,
		feedbackResult: state.system.feedbackResult,
		changeUserBasicInfoStatus: state.account.changeUserBasicInfoStatus,
	}),
	dispatch => ({
		doLogout: () => dispatch(doLogoutAction()),
		submitFeedbackAction: (params) => dispatch(submitFeedbackAction(params)),
		doChangePwd: (oldPwd, newPwd, strength) => dispatch(doChangePwdAction(oldPwd, newPwd, strength)),
		resetChangePwdStatus: () => dispatch(changePwdInitAction()),
		onChangeUserBasicInfo: (userInfo) => dispatch(changeUserBasicInfoAction(userInfo)),
	})
)(RelationView);
