import React from 'react';
import PropTypes from 'prop-types';
import {Button} from 'antd';

import style from '@/style/common/microService/common.microService.less';

const getDisplayNum = n => n > 9999 ? '9999+' : n;

const BTN_JSX = {
  saveNodes: (<React.Fragment>生成节点</React.Fragment>),
  saveEdges: (<React.Fragment>建立连接</React.Fragment>),
  saveSubGraphs: (<React.Fragment>生成子图</React.Fragment>),
  saveContent: (<React.Fragment>更新节点</React.Fragment>),
  showWordCloud: (<React.Fragment>展示词云</React.Fragment>),
  saveFiles: (<React.Fragment>下载文件</React.Fragment>),
  showCharts: (<React.Fragment>展示图表</React.Fragment>),
};

class MicroServicePanelOperation extends React.PureComponent {
  render() {
    let me = this, mainButton,
      /** @type {Object.<string, TMicroServiceResponseStatisticsSummary>} */statisticsByType = me.props.statisticsByType;

    if (BTN_JSX[me.props.type]) {
      mainButton = (
        <Button
          onClick={me.props.onMainActionClicked}
          disabled={me.props.mainActionDisabled && me.props.type!=='showCharts' }
          type={'primary'}
          block={true}
        >
          {BTN_JSX[me.props.type]}
        </Button>
      );
    }
    
    return (
      <div className={style['result-action-frame']}>
        {
          me.props.locked ? null : (
            <Button
              type={'primary'}
              onClick={me.props.onStartClicked}
              disabled={!me.props.hasTarget}
              block={true}
            >
              开始计算
            </Button>
          )
        }
        {
          me.props.locked && ((me.props.autoLoadMore && me.props.hasMoreResult) || me.props.loadingResult) ? (
            <Button
              onClick={me.props.onStopClicked}
              disabled={!me.props.autoLoadMore && me.props.loadingResult}
              block={true}
            >
              停止计算
            </Button>
          ) : null
        }
        {
          me.props.locked && (!me.props.autoLoadMore || !me.props.hasMoreResult) && !me.props.loadingResult ? (
            <Button
              onClick={me.props.onStartOverClicked}
              block={true}
            >
              重新开始
            </Button>
          ) : null
        }
        <table>
          <tbody>
          <tr>
            <td>增加：</td>
            <td style={{textAlign: 'right'}}>
              {statisticsByType['add'] ? getDisplayNum(statisticsByType['add'].saved + statisticsByType['add'].selected) : 0}/
            </td>
            <td>{statisticsByType['add'] ? getDisplayNum(statisticsByType['add'].total) : 0}</td>
          </tr>
          <tr>
            <td>修改：</td>
            <td style={{textAlign: 'right'}}>
              {statisticsByType['modify'] ? getDisplayNum(statisticsByType['modify'].saved + statisticsByType['modify'].selected) : 0}/
            </td>
            <td>{statisticsByType['modify'] ? getDisplayNum(statisticsByType['modify'].total) : 0}</td>
          </tr>
          <tr>
            <td>删除：</td>
            <td style={{textAlign: 'right'}}>
              {statisticsByType['remove'] ? getDisplayNum(statisticsByType['remove'].saved + statisticsByType['remove'].selected) : 0}/
            </td>
            <td>{statisticsByType['remove'] ? getDisplayNum(statisticsByType['remove'].total) : 0}</td>
          </tr>
          <tr>
            <td>连接：</td>
            <td style={{textAlign: 'right'}}>
              {statisticsByType['connect'] ? getDisplayNum(statisticsByType['connect'].saved + statisticsByType['connect'].selected) : 0}/
            </td>
            <td>{statisticsByType['connect'] ? getDisplayNum(statisticsByType['connect'].total) : 0}</td>
          </tr>
          </tbody>
        </table>
        {mainButton}
      </div>
    );
  }
}

MicroServicePanelOperation.defaultProps = {};

MicroServicePanelOperation.propTypes = {
  type: PropTypes.string.isRequired,
  locked: PropTypes.bool.isRequired,
  hasTarget: PropTypes.bool.isRequired,
  autoLoadMore: PropTypes.bool.isRequired,
  hasMoreResult: PropTypes.bool.isRequired,
  loadingResult: PropTypes.bool.isRequired,
  mainActionDisabled: PropTypes.bool.isRequired,
  statisticsByType: PropTypes.objectOf(PropTypes.shape({
    total: PropTypes.number.isRequired,
    idle: PropTypes.number,
    selected: PropTypes.number,
    saved: PropTypes.number,
    invalid: PropTypes.number,
  })),
  onStartClicked: PropTypes.func.isRequired,
  onStopClicked: PropTypes.func.isRequired,
  onStartOverClicked: PropTypes.func.isRequired,
  onMainActionClicked: PropTypes.func.isRequired,
}

export default MicroServicePanelOperation;