import React from "react";
import PropTypes from 'prop-types';

import PB, {SimplePB} from "@/libs/simplePB";
import style from '@/style/common/common.accountActions.logSummary.less';
import badgeInfoStyle from '@/style/components/mainView/main.badgeInfo.less';
import {Divider, Tooltip} from 'antd';
import moment from 'moment';
import UserDataProvider from '@/components/common/dataProvider/common.dataProvider.user';
import DigitConvertToIcons from '@/components/common/common.digitConvertToIcons';

class AccountActionsLogSummary extends React.PureComponent {
  state = {
    viewActionLogSummary: {
      maxDuration: 0,
      currentDuration: 0,
      currentEnd: '',
    }, // 打卡信息
    ratio: 0, // 当前 / 最大  的比率
  };

  // 计算当前与最大打卡天数的比率 result = 0~100
  calculateRatio = (maxDuration, currentDuration) => {
    let result = 0;
    if (isNaN(currentDuration) || isNaN(maxDuration)) {
      result = 0;
    } else {
      result = Math.trunc((currentDuration / maxDuration) * 100);
    }
    return result;
  };

  // 获取图谱操作记录统计
  getViewActionLogSummary = (callback) => {
    let me = this;
    let currentDate = moment().format('YYYYMMDD');
    if (me.state.viewActionLogSummary.currentEnd !== currentDate) {
      UserDataProvider.getViewActionLogSummary({userId: me.props.userInfo.userId}).then(res => {
        console.log('MainBadgeInfo -> getViewActionLogSummary ->  res:', res);
        if (res['DAILY_EDIT_ACTION']) {
          let result = res['DAILY_EDIT_ACTION'];
          me.setState({
            viewActionLogSummary: result,
            ratio: me.calculateRatio(result['maxDuration'], result['currentDuration']),
          },()=>{
            callback && callback();
          })
        }
      }).catch(() => {
        console.log(' 获取打卡信息失败 ');
      });
    }

  };

  updateSummaryTip = (level, status = 1) => {
    let me = this;
    const {levelId, content} = me.formatSummaryTip(level, status)
    // 发出更新
    me.props.bus.emit('aiConsole', 'message.update', {
      key:me.aiNoticeDict.hasOwnProperty(levelId)?me.aiNoticeDict[levelId]:"",
      type: 'ai',
      content: content,
      callback: key => {
        me.aiNoticeDict[levelId] = key['key'];
      }
    })
  }

  // 生成提示
  aiNoticeDict = {};
  formatSummaryTip = (level, status = 0) => {
    let me = this;
    let levelId = '';
    let content = '';
    if (level === 1) {
      // 差1天 A级
      levelId = 'day1'
      if (status === 0) {
        content = <div className='ani-twinkling animated' style={{lineHeight:'1rem'}}>还差1天就超过最高打卡记录了！<a className={'plain-action'} style={{marginLeft: '0.5em',fontSize:'0.8rem'}} onClick={e => {
          e.preventDefault();
          me.updateSummaryTip(1,2);
        }}>知道了</a></div>;
      } else {
        content = <div>还差1天就超过最高打卡记录了！<span className={'plain-action'} style={{marginLeft: '0.5em'}}>加油！</span></div>;
      }

    } else if (level === 2) {
      // 差1天 B级
      levelId = 'day1'
      if (status === 0) {
        content = <div className='ani-twinkling animated' style={{lineHeight:'1rem'}}>还差2天就超过最高打卡记录了!<a className={'plain-action'} style={{marginLeft: '0.5em',fontSize:'0.8rem'}} onClick={e => {
          e.preventDefault();
          me.updateSummaryTip(2,2);
        }}>知道了</a></div>;
      } else {
        content = <div>还差2天就超过最高打卡记录了!<span className={'plain-action'} style={{marginLeft: '0.5em'}}>加油！</span></div>;
      }
    } else {
      levelId = 'dayx';
      content = ''
    }
    return {levelId, content}
  }

  componentDidMount() {
    let me = this;
    // 获取图谱操作记录统计
    me.getViewActionLogSummary(() => {
      // console.log('loopNoticeTip viewActionLogSummary=', me.state.viewActionLogSummary);
      setTimeout(() => {
        if ((me.state.viewActionLogSummary['maxDuration'] - me.state.viewActionLogSummary['currentDuration']) === 2) {
          // B 级提示信息
          const {levelId, content} = me.formatSummaryTip(2, 0)
          me.props.bus.emit('aiConsole', 'message.push', {
            type: 'ai',
            content: content,
            callback: key => {
              me.aiNoticeDict[levelId] = key['key'];
            }
          })
        } else if ((me.state.viewActionLogSummary['maxDuration'] - me.state.viewActionLogSummary['currentDuration']) === 1) {
          // A 级提示信息
          const {levelId, content} = me.formatSummaryTip(1, 0)
          me.props.bus.emit('aiConsole', 'message.push', {
            type: 'ai',
            content: content,
            callback: key => {
              me.aiNoticeDict[levelId] = key['key'];
              // 播放声音
              me.props.bus.emit('aiConsole', 'audio.play', true);
              }
          })
        }
      }, 1000)

    });
  }

  componentWillUnmount() {
    this.props.bus.remove(this);
  }

  render() {
    let me = this;

    return (
      <React.Fragment>
        <Tooltip
          title={(<div className={style['tooltip-wrap']}>
            <div>您已连续打卡 <span className={style['number']}>{me.state.viewActionLogSummary['currentDuration']}</span> 天</div>
            <div>史上最长连续 <span className={style['number']}>{me.state.viewActionLogSummary['maxDuration']}</span> 天</div>
            <div className={style['tips']}>每天编辑图谱即打卡成功</div>
          </div>)}
          placement={'topRight'}
        >
          <div className={`${badgeInfoStyle['badge-more-view']} ${badgeInfoStyle['account-action']} ${me.props.className ? me.props.className : ''}`}>
            <div className={`${style['icon-box']} ${badgeInfoStyle['icon']}`} style={{}}>
              <div className={style['icon-outer']}>
                <div className={style['icon-inner']} style={{bottom: '13px', visibility: me.state.ratio > 80 ? 'visible' : 'hidden'}} />
                <div className={style['icon-inner']} style={{bottom: '10px', visibility: me.state.ratio > 60 ? 'visible' : 'hidden'}} />
                <div className={style['icon-inner']} style={{bottom: '7px', visibility: me.state.ratio > 40 ? 'visible' : 'hidden'}} />
                <div className={style['icon-inner']} style={{bottom: '4px', visibility: me.state.ratio > 20 ? 'visible' : 'hidden'}} />
                <div className={style['icon-inner']} style={{bottom: '1px', visibility: me.state.ratio > 0 ? 'visible' : 'hidden'}} />
              </div>
            </div>
            <DigitConvertToIcons digit={me.state.viewActionLogSummary['currentDuration']} />
          </div>
        </Tooltip>
      </React.Fragment>
    );
  }
}

AccountActionsLogSummary.defaultProps = {
  style: {},
  bus: PB,
};

AccountActionsLogSummary.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  bus: PropTypes.instanceOf(SimplePB),
  userInfo: PropTypes.object,
};

export default AccountActionsLogSummary;