/*
 * @Author: Carr.s
 * @Date: 2022-04-08 11:28:40
 * @LastEditTime: 2022-05-17 08:43:36
 * @LastEditors: Carr.s
 * @Description: 洞察 政策导向
 * @Copyright by joinmap
 */
import React from "react";
import ViewInsightChartTpl from "@/components/insightView/template/insight.chart.tpl";
import { connect } from "react-redux";
import styles from "@/style/components/insightView/insight.less";

class ViewPolicyOrientedInsight extends ViewInsightChartTpl {
  //===============================================================
  // begin of overwrite
  // 使用默认的方式就不用重写

  // ------------------------------------------------------------
  // 全局配置
  // ------------------------------------------------------------
  // 提示信息中提示添加哪种类型的节点
  nodeTitleInTip = "政策";
  // 显示底部按钮
  showToolbar = false;
  // 洞察名称
  viewerTitle = "政策导向";

  // ------------------------------------------------------------
  // 层级分析对象的配置
  // ------------------------------------------------------------
  // 第一层 level1 tag
  // column1_DataField = "data"; // 取值字段
  // column1_TotalField = "dataTotal"; // 计算总数(与返回数量不一定一致)字段
  column1_Title = "政策"; // 显示名称
  column1_Unit = "篇"; // 显示的文字单位
  /*
  column1_ValueRender = (level1_data, idx,nodePath=undefined) => {
    // 右侧数值渲染，不输出则空白
    // 必须加上 className={styles["line-value"]}
    return (
      <div className={styles["line-value"]}>
        {level1_data["value"]}{level1_data["valueUnit"]}
      </div>
    );
  };
  column1_IconRender = (level1_data, idx,nodePath=undefined) => {
    // 左侧图表渲染，不输出则空白
    // 必须加上 className={styles["line-icon"]}
  };
  */
  column1_HoverTitle = (level1_data, idx, nodePath = undefined) => {
    // 列表鼠标悬浮提示文字
    // 负导向数值
    let negTotal = level1_data["negTotal"];
    return `${this.column1_Title}：${level1_data["name"]}\n${
      this.column2_Title
    }：${level1_data[this.column2_TotalField]}${
      this.column2_Unit
    }\n负导向：${negTotal}条\n点击打开${this.column2_Title}列表`;
  };

  // 第二层 item
  column2_DataField = "posNews"; // 取值字段
  column2_TotalField = "posTotal"; // 计算总数(与返回数量不一定一致)字段
  column2_Title = "正导向"; // 显示名称
  column2_Unit = "条"; // 显示的文字单位
  column2_ValueRender = (level2_data, idx) => {
    // 右侧数值渲染，不输出则空白
    // 必须加上 className={styles["line-value"]}
    // 不显示
  };
  /*
  column2_IconRender = (level2_data, idx) => {
    // 左侧图表渲染，不输出则空白
    // 必须加上 className={styles["line-icon"]}
  };
  column2_HoverTitle = (level2_data, idx) => {
    // 列表鼠标悬浮提示文字
    return `${this.column2_Title}：${level2_data["name"]}\n点击打开${this.column1_Title}链接`;
  };
  */

  // ------------------------------------------------------------
  // 接口的配置
  // ------------------------------------------------------------
  // 接口 url path
  api_path = "/wth_server_api/zhengce_news_by_view_id";

  // 返回数据默认值
  defaultLimit = 60;

  /**
   * 【继承组件要重写】
   * api 请求完成后，setState结束后的 callback
   */
  apiLoadedCallback = () => {
    // message.info("数据加载成功");
  };

  // ------------------------------------------------------------
  // 图表的配置
  // ------------------------------------------------------------
  // 图表显示库
  chartType = "echarts";

  chartDivLeft = 300;
  chartCompomentStyle = {
    width: "100%",
    height: "100%",
  };

  /**
   * 生成公用的、默认的 option
   * @returns
   */
  initChartOption = () => {
    let _chartOption = {
      colors: this._chartColors,
      animation: true,
      animationDuration: 800,
      animationEasing: "cubicInOut",
      animationDurationUpdate: 500,
      animationEasingUpdate: "quinticInOut", // cubicInOut
      grid: {
        show: false,
        // left: "300px",
      },
      xAxis: {},
      yAxis: {
        splitLine: {
          show: true,
          lineStyle: {
            color: "#2e546e",
          },
        },
        name: "导向句子数量",
        nameTextStyle: {
          color: "#eeeeee",
          fontSize: 14,
        },
        axisLabel: {
          color: "#999",
        },
      },
      series: [],
    };
    return _chartOption;
  };

  /**
   * 【继承组件要重写】
   * 生成 chart 的 options
   * @param {int} idx 选中的数据下标
   */
  refreshChartOptions = (idx) => {
    const {
      query: { isDebug },
    } = this.props;
    if (isDebug) console.log("refreshChartOptions idx:", idx);
    let me = this;
    // const { column2_DataField } = this.state;
    me._chartOption = this.initChartOption();

    let data1 = [];
    let data2 = [];
    let xAxisData = [];

    me._chartData.forEach((i1, idx1) => {
      // console.log("i1:", i1);
      // xAxisData
      xAxisData.push(i1.name);
      // data1
      data1.push({
        value: i1.posTotal,
        textStyle: {
          color: "#eeeeee",
        },
      });
      // data2
      data2.push({
        value: i1.negTotal * -1,
        textStyle: {
          color: "#eeeeee",
        },
      });
    });

    // console.log("me.chartOptions:", me.chartOptions);
    // console.log("xAxisData:", xAxisData);
    // console.log("data1:", data1);
    // console.log("data2:", data2);

    // data1 series
    me._chartOption.series.push({
      roundCap: true,
      name: "正导向",
      type: "bar",
      stack: "one",
      itemStyle: {
        color: "#73C0DE",
      },
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowColor: "rgba(0,0,0,0.3)",
        },
      },
      label: {
        show: true,
        color: "#eeeeee",
        formatter: function (params) {
          // console.log("ddddddddddd----->", params);
          if (params.value == 0) {
            return "";
          } else {
            return params.value;
          }
        },
      },
      data: data1,
    });

    // data2 series
    me._chartOption.series.push({
      roundCap: true,
      name: "负导向",
      type: "bar",
      stack: "one",
      itemStyle: {
        color: "#FC8452",
      },
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowColor: "rgba(0,0,0,0.3)",
        },
      },
      label: {
        show: true,
        color: "#eeeeee",
        formatter: function (params) {
          // console.log("ddddddddddd----->", params);
          if (params.value == 0) {
            return "";
          } else {
            return params.value;
          }
        },
      },
      data: data2,
    });

    // xAxis
    me._chartOption.xAxis = {
      data: xAxisData,
      name: "政策",
      nameTextStyle: {
        color: "#eeeeee",
        fontSize: 14,
      },
      axisLine: { onZero: true },
      splitLine: { show: false },
      splitArea: { show: false },
      axisLabel: {
        color: "#eeeeee",
      },
    };

    // 鼠标悬浮提示
    me._chartOption.tooltip = {
      show: true,
      formatter: function (info) {
        // console.log("tooltip:", info);
        return [
          "<div>政策：" + info.name + "</div>",
          "<div>" + info.seriesName + ":" + Math.abs(info.value) + "句</div>",
        ].join("");
      },
    };

    // 图表的左边距
    setTimeout(() => {
      let column1El = document.getElementById("column1_list");
      let column1Width = column1El ? column1El.clientWidth : 300;
      me.chartDivLeft = column1Width + 10;
    }, 1000);

    if (isDebug) console.log("me._chartOption:", me._chartOption);
    return;
  };

  /**
   * 【继承组件要重写】
   * echart 有时需要绑定点击事件什么
   * @param {object} e ReactECharts 返回的 echarts 实例
   */
  eChartRefCallback = (e) => {
    let me = this;
    if (!me._chartIns) {
      me._chartIns = e.getEchartsInstance();
      // 绑定点击事件
      me._chartIns.on("click", function (params) {
        console.log(
          "ViewPolicyOrientedInsight ReactECharts click params: ",
          params
        );
        // 判断是否要切换item对应的字段
        let column2_TotalField = "posTotal";
        let column2_DataField = "posNews";
        if (params.seriesName == "负导向") {
          column2_TotalField = "negTotal";
          column2_DataField = "negNews";
        }
        me.column2_TotalField = column2_TotalField;
        me.column2_DataField = column2_DataField;
        me.column2_Title = params.seriesName;
        me.setState(
          {
            _refresh: !me.state._refresh,
          },
          () => {
            if (params.hasOwnProperty("data")) {
              //点击的节点的name
              let name = me._chartData[params.dataIndex].name;
              //调用点击事件
              me._column1_onClick(params.dataIndex, name, false);
            }
          }
        );
      });
    }
  };

  // end of overwrite
  //===============================================================
}

export default connect(
  (state) => ({
    userInfo: state.account.userInfo,
  }),
  (dispatch) => ({})
)(ViewPolicyOrientedInsight);
