import React from 'react';
import {Icon} from 'antd'
import PB from '@/libs/simplePB'
import style from '@/style/components/main.toolbar.statistic.less'

export default class ToolbarStatistic extends React.Component {
  state = {
    right: '0px',
  };

  componentDidMount() {
    PB.sub(this, 'infoboard', 'showStatus', bol => {
      if (bol) {
        this.setState({right: '31.5rem'})
      } else {
        this.setState({right: '1.5rem'})
      }
    })
  }

  render() {
    return (
      <div className={style['container']}
           /*style={{right: this.state.right}}*/
           style={!this.props.visual ? {display: 'none', right: this.state.right} : {right: this.state.right}}>
        <div className={style['close']} onClick={() => this.props.onClose && this.props.onClose(false)}>
          <Icon type='close'/>
        </div>
        <div>
          暂无统计信息
          <br/>
          <br/>
          <br/>
          <br/>
        </div>
      </div>
    );
  }
}
