
import intl from 'react-intl-universal';
export const viewOperationMessages = {
  accessNode: false,
  addFiles: intl.get('Custom.message.viewOperation.addFiles'),
  addNodeLinkedTo: intl.get('Custom.message.viewOperation.addTextNodes'),
  addRelation: intl.get('Custom.message.viewOperation.addRelation'),
  addTextNodes: intl.get('Custom.message.viewOperation.addTextNodes'),
  doMicroServiceByView: false,
  exploreAsync: false,
  exploreAsyncByProps: false,
  exploreByNode: intl.get('Custom.message.loadExactMatch'),//'正在进行计算……',
  exploreBySubview: intl.get('Custom.message.loadExactMatch'),//'正在进行计算……',
  exploreByView: intl.get('Custom.message.loadExactMatch'),//'正在进行计算……',
  exploreSyncByConnection: false,
  exploreSyncByDomainPerson: false,
  exploreSyncBySimilarity: false,
  fixNodeTo: false, // '正在改变节点固定位置……',
  loadBriefingInfoList: intl.get('Custom.message.viewOperation.loadBriefingInfoList'),
  loadConfig: false,
  loadData: intl.get('Custom.message.viewOperation.loadData'),
  loadDataGradually: intl.get('Custom.message.viewOperation.loadData'),
  loadDynamicData: intl.get('Custom.message.viewOperation.loadDynamicData'),
  loadDataSet: intl.get('Custom.message.viewOperation.loadDataSet'),
  loadExactMatch: intl.get('Custom.message.loadExactMatch'),//'正在进行计算……',
  loadFileList: intl.get('Custom.message.viewOperation.loadFileList'),
  loadGravityNodeList: false,
  loadIndexValue: false,
  loadRankData: false,
  loadMatchForSubView: '……',
  loadMatchedResources: false,
  loadMatchedTags: false,
  loadNodeDetailInfo: false, // '正在加载节点详情，请稍后……',
  loadRelatedClue: intl.get('Custom.message.viewOperation.loadRelatedClue'),
  loadRelatedResource: intl.get('Custom.message.viewOperation.loadRelatedResource'),
  loadSubViewData: intl.get('Custom.message.viewOperation.loadSubViewData'),
  recommendCompanyByView: false,
  recoverRelationGraph: intl.get('Custom.message.viewOperation.recoverRelationGraph'),
  reloadData: false,
  reloadTeamworkMember: false,
  removeFiles: intl.get('Custom.message.viewOperation.removeFiles'),
  removeNode: intl.get('Custom.message.viewOperation.removeNode'),
  removeRelation: intl.get('Custom.message.viewOperation.removeRelation'),
  removeRelationGraph: intl.get('Custom.message.viewOperation.removeRelationGraph'),
  saveNodeWithRelations: intl.get('Custom.message.viewOperation.saveNodeWithRelations'),
  saveRelationGraph: intl.get('Custom.message.viewOperation.saveRelationGraph'),
  smartSearch: false,
  smartSearchGraphInAllView: intl.get('Custom.message.viewOperation.smartSearchGraphInAllView'),
  smartSearchNodeInAllView: false,
  smartSearchUserInAllView: false,
  unFixNode: false, // '正在取消节点固定位置……',
  updateConfig: intl.get('Custom.message.viewOperation.updateConfig'),
  updateEdgeInfo: false, // '正在更新关联关系信息……',
  updateFilesRemark: intl.get('Custom.message.viewOperation.updateFilesRemark'),
  updateNodeInfo: intl.get('Custom.message.viewOperation.updateNodeInfo'),
  updateNodePosition: false,
  voteExplorationResultByNode: intl.get('Custom.message.viewOperation.voteExplorationResultByNode'),
  voteExplorationResultBySubview: intl.get('Custom.message.viewOperation.voteExplorationResultBySubview'),
};

export const viewManagerOperationMessages = {
  'static::publishToStore': '正在处理，请稍后……',
  'static::removeFromStore': '正在处理，请稍后……',
  'static::loadPublishedViewList': '正在获取信息……',
  'static::orderView': '正在下单，请稍后……',
  'static::orderFreeView': '正在复制，请稍后……',
  'static::loadViewTeamworkMembers': '正在获取协作成员信息……',
  'static::addViewTeamworkMember': '正在添加协作成员，请稍后……',
  'static::removeViewTeamworkMember': '正在移除协作成员，请稍后……',
  'static::moveViewInfoByAccessToken': false,
  'static::moveTransInfoByAccessToken': false,
  'static::loadViewCover': false,
  'static::getViewTags': false,
  'static::setViewCover': '正在设置图谱封面，请稍后……',
};

export const accountOperationMessages = {
  'static::bindWeChatAccount': '正在绑定……',
  'static::loadUserInfo': '正在加载用户信息……',
};

export const nodeOperationMessages = {
  'static::exploreRelatedNode': false,
  'static::exploreOverride': false,
  'static::recommendNodes': false,
  'static::loadInsightDetailInfo': '正在加载概要信息，请稍后……',
  'static::loadInsightDetailProperties': '正在加载详细信息，请稍后……',
  'static::loadInsightDetailTimeline': '正在加载事件信息，请稍后……',
  'static::loadInsightDetailRelations': '正在加载相关信息，请稍后……',
  'static::loadBriefingListByNodes': '正在加载相关简报，请稍后……',
  'static::loadNodeDetail': '正在加载节点信息，请稍后……',
  'static::loadMatchedTags': '正在理解节点信息，请稍后……',
  'static::loadRelatedClue': '正在获取节点相关线索，请稍后……',
  'static::loadNodeListByUser': '正在获取统计信息，请稍后……',
  'static::batchLoadNodeAmountStatistics': '正在获取统计信息，请稍后……',
  'static::loadNodeTaskList': '正在获取任务列表，请稍后……',
  'static::expandWord': '正在进行左右组词联想，请耐心等待……',
  'static::loadWordStatisticsResult': false,
  'static::recommendDetailByNodeList': false,
};

export const userOperationMessages = {
  'static::queryUser': '正在查询，请稍后……',
  'static::verifyEmailByToken': '正在验证邮箱，请稍后……',
  'static::changeEmail': '正在绑定邮箱，请稍后……',
  'static::sendFeedback': '正在提交，稍后会有客服人员与你联系……',
};

export const relationOperationMessages = {
  'static::parseRelation': '正在解析，请稍后……',
  'static::loadBriefing': '正在加载相关简报，请稍后……',
};

export const presentationOperationMessages = {
  'static::loadConfigList': false,
  'static::addConfig': '正在保存，请稍后……',
  'static::updateConfig': '正在保存，请稍后……',
  'static::removeConfig': '正在删除，请稍后……',
};

export const microServiceOperationMessages = {
  'static::loadConfigList': false,
  'static::recommendConfigList': false,
  'static::addConfig': '正在保存，请稍后……',
  'static::updateConfig': '正在保存，请稍后……',
  'static::removeConfig': '正在删除，请稍后……',
  'static::callByView': false,
};

export const systemOperationMessages = {
  'static::uploadTempFile': false,
  'static::uploadPublicFile': false,
};