import React from 'react';
import PropTypes from 'prop-types';
import {Tooltip, List, Progress, Dropdown, Menu, message} from 'antd';

import PB, {SimplePB} from '@/libs/simplePB';

import {getNodeDisplayTitle, getNodeIcon} from "@/constants/vis.defaultDefine.1";

import Icon from '@/components/common/common.icon';
import {showErrorMessage} from "@/components/common/common.message";

import ViewStatisticsEdgeLogic from "@/components/common/view/statistics/edge/common.view.statistics.edge.logic";

import style from '@/style/common/view/statistics/common.view.statistics.edge.less';

const CONDITION_TITLE = {
  TYPE: '连线计数',
  AMOUNT_RANGE: '连线区间',
};

const CONDITION_FULL_TITLE = {
  TYPE: '连线计数',
  AMOUNT_RANGE: '连线区间',
};

const RANK_COLOR = {
  'pos_1': '#F28B54',
  'pos_2': '#F7B500',
  'pos_3': '#F6D95E',
  '_default': '#c8c8c8',
};

class ViewStatisticsEdgePanel extends React.Component {
  state = {
    cacheKey: `edge-statistics-${Math.random()}`,
    conditionKey: 'TYPE',
    limit: 10,
    sort: 'desc',
    rankLoadingStatus: 'idle',
    selectedRankItemIndex: -1,
    listLoadingStatus: 'idle',
  };

  rankContentData = [];

  listContentData = [];

  lastHoverKey = {
    list: undefined,
    menu: undefined,
  };

  availableNodeAmount = 0;

  onClose = () => {
    let me = this;

    me.rankContentData = [];
    me.listContentData = [];
    me.lastHoverKey.list = undefined;
    me.lastHoverKey.menu = undefined;
    me.setState({
      cacheKey: `edge-statistics-${Math.random()}`,
      conditionKey: 'TYPE',
      rankLoadingStatus: 'idle',
      selectedRankItemIndex: -1,
      listLoadingStatus: 'idle',
    });

    me.props.onClose();
  };

  onBackToRank = () => {
    let me = this;

    me.listContentData = [];
    me.setState({
      selectedRankItemIndex: -1,
      listLoadingStatus: 'idle',
    });
  };

  onRankItemClick = idx => {
    let me = this;

    me.listContentData = me.rankContentData[idx].nodes;
    if (me.listContentData !== undefined) {
      me.setState({
        selectedRankItemIndex: idx,
        listLoadingStatus: 'success',
      });
    } else {
      message.info('功能开发中，敬请期待...');
    }
  };

  onComponentHover = (type, key) => {
    let me = this;

    if (!me.lastHoverKey[type]) {
      me.lastHoverKey[type] = key;
      me.forceUpdate();
    } else {
      me.lastHoverKey[type] = key;
    }
  };

  delayComponentLostHover = (type, key) => {
    let me = this;

    setTimeout(() => {
      if (me.lastHoverKey[type] === key) {
        me.lastHoverKey[type] = undefined;
        me.forceUpdate();
      }
    }, 10);
  };

  componentDidMount() {
    let me = this;

    me.props.bus.with(me).subscribe('node.statistics', 'edge.loaded', ({sort, limit, result}) => {
      me.rankContentData = result;
      me.setState({
        limit,
        sort,
        rankLoadingStatus: 'success',
        selectedRankItemIndex: -1,
        listLoadingStatus: 'idle',
      }, () => {
        let unSavedNodes = me.props.nodes.filter(node => !node.status);
        me.availableNodeAmount = me.props.nodes.length - unSavedNodes.length;
        if (unSavedNodes.length > 0) {
          message.info(`请注意，有${unSavedNodes.length}个节点尚未确认保存，因此未列入统计范围`);
        }
      });
    }).subscribe('node.statistics', 'edge.failed_to_load', ({code, msg}) => {
      showErrorMessage({code, msg});
      me.setState({
        rankLoadingStatus: 'failed',
        selectedRankItemIndex: -1,
        listLoadingStatus: 'idle',
      });
    });

    // 临时加载数据
    setTimeout(() => {
      me.setState({rankLoadingStatus: 'processing', conditionKey: me.props.initialConditionKey}, () => {
        me.props.bus.emit('node.statistics', 'edge.do_load',
          {nodes: me.props.nodes.filter(node => !!node.status), edges: me.props.edges,
            conditionKey: me.state.conditionKey, cacheKey: me.state.cacheKey});
      });
    }, 0);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let me = this;

    if (me.props.initialConditionKey !== prevProps.initialConditionKey
      && me.state.conditionKey !== me.props.initialConditionKey) {

      setTimeout(() => {
        me.setState({rankLoadingStatus: 'processing', conditionKey: me.props.initialConditionKey}, () => {
          me.props.bus.emit('node.statistics', 'edge.do_load',
            {nodes: me.props.nodes.filter(node => !!node.status), edges: me.props.edges,
              conditionKey: me.state.conditionKey, cacheKey: me.state.cacheKey});
        });
      }, 0);
    }
  }

  componentWillUnmount() {
    this.props.bus.remove(this);
  }

  render() {
    let me = this, rankContent, listContent = undefined, listBottom = undefined;

    switch (me.state.rankLoadingStatus) {
      case 'idle':
      case 'processing':
        rankContent = (
          <div key={'rank-loading'} className={style['rank-loading']}>
            <div>
              <Icon name={'loading'} theme={'outlined'}/><br/>
              数据加载中，请稍候...
            </div>
          </div>
        );
        break;
      case 'success':
        if (me.rankContentData.length > 0) {
          rankContent = (
            <List
              key={'rank-content'}
              className={style['rank-content']}
              dataSource={me.rankContentData}
              renderItem={(item, idx) => (
                <List.Item onClick={e => {e.stopPropagation();me.onRankItemClick(idx);}}>
                  <div>
                    <div>
                      {me.state.sort ? (<span className={style['rank-item-pos']}>TOP.{item.pos}</span>) : undefined}
                      <span className={style['rank-item-edge']}>{item.label}</span>
                    </div>
                    <Progress
                      size="small"
                      strokeColor={RANK_COLOR[`pos_${item.pos}`] || RANK_COLOR['_default']}
                      strokeWidth={6}
                      percent={item.percent}
                      showInfo={false}
                    />
                  </div>
                </List.Item>
              )}
            />
          );
          break;
        }
      // noinspection FallThroughInSwitchStatementJS
      case 'failed':
      default:
        rankContent = (
          <div key={'rank-failed-to-load'} className={style['rank-failed-to-load']}>
            <div>
              <Icon name={'info-circle'} theme={'outlined'}/><br/>
              没有可以统计的节点
            </div>
          </div>
        );
    }
    if (me.state.selectedRankItemIndex >= 0) {
      switch (me.state.listLoadingStatus) {
        case 'idle':
        case 'processing':
          listContent = (
            <div key={'list-loading'} className={style['rank-loading']}>
              <div>
                <Icon name={'loading'} theme={'outlined'}/><br/>
                数据加载中，请稍候...
              </div>
            </div>
          );
          break;
        case 'success':
          if (me.listContentData.length > 0) {
            listContent = (
              <ul
                key={'list-content'}
                className={`${style['list-content']} scrollbar ${me.lastHoverKey['list'] ? 'hover' : ''}`}
              >
                {me.listContentData.map((node) => (
                  node.description ? (
                    <Tooltip
                      key={`n-${node.id}`}
                      placement={'right'}
                      title={
                        node.description.split('\n').map((line, idx) => (
                          <span key={`ln-${idx}`}>{line}<br /></span>
                        ))
                      }
                      mouseLeaveDelay={0.05}
                      onVisibleChange={visible =>
                        visible ? me.onComponentHover('list', `n-${node.id}`) :
                          me.delayComponentLostHover('list', `n-${node.id}`)}
                    >
                      <li onClick={() => me.props.bus.emit('network', 'focus', node)}>
                        <Icon {...getNodeIcon(node)} style={{marginRight: '0.5em'}}/>
                        {getNodeDisplayTitle(node)}
                      </li>
                    </Tooltip>
                  ) : (
                    <li
                      key={`n-${node.id}`}
                      onClick={() => me.props.bus.emit('network', 'focus', node)}
                    >
                      <Icon {...getNodeIcon(node)} style={{marginRight: '0.5em'}}/>
                      {getNodeDisplayTitle(node)}
                    </li>
                  )
                ))}
              </ul>
            );
            listBottom = (
              <div key={'statistics'} className={style['statistics-frame']}>
                <table className={style['statistics-content']}>
                  <colgroup>
                    <col style={{width: '4.7em'}} />
                    <col style={{width: '1.2em'}} />
                    <col />
                    <col style={{width: '1.2em'}} />
                    <col style={{minWidth: '2em'}} />
                    <col style={{width: '1em'}} />
                  </colgroup>
                  <tbody>
                  <tr>
                    <td rowSpan={2} style={{verticalAlign: 'top'}}>
                      {CONDITION_TITLE[me.state.conditionKey]}
                    </td>
                    <td rowSpan={2} style={{verticalAlign: 'top'}}>
                      =
                    </td>
                    <td rowSpan={2} style={{verticalAlign: 'top'}}>
                      {me.rankContentData[me.state.selectedRankItemIndex].label}<br />
                    </td>
                    <td />
                    <td style={{textAlign: 'right', paddingRight: '0.5em'}}>{me.listContentData.length}</td>
                    <td>个</td>
                  </tr>
                  <tr>
                    <td style={{textAlign: 'right'}}>共</td>
                    <td style={{textAlign: 'right', paddingRight: '0.5em'}}>{me.availableNodeAmount}</td>
                    <td>个</td>
                  </tr>
                  <tr>
                    <td>
                      {me.state.sort ? '排名' : ''}
                    </td>
                    <td>
                      {me.state.sort ? '=' : ''}
                    </td>
                    <td>
                      {me.state.sort ? 'No.' + (me.state.selectedRankItemIndex + 1) : ''}
                    </td>
                    <td style={{textAlign: 'right'}}>占</td>
                    <td style={{textAlign: 'right', paddingRight: '0.5em'}}>{Math.round(me.listContentData.length / me.availableNodeAmount * 10000) / 100}</td>
                    <td>%</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            );
            break;
          }
        // noinspection FallThroughInSwitchStatementJS
        case 'failed':
        default:
          listContent = (
            <div key={'list-failed-to-load'} className={style['list-failed-to-load']}>
              <div>
                <Icon name={'info-circle'} theme={'outlined'}/><br/>
                未发现相关节点
              </div>
            </div>
          );
      }
    }

    return (
      <React.Fragment>
        <div key={'rank-panel'} className={`${style['frame']} ${me.lastHoverKey['menu'] ? 'hover' : ''}`}>
          <div className={style['header-frame']}>
            节点连线 -
            <Dropdown
              overlay={(
                <Menu className={'dark-theme'}>
                  <Menu.Item
                    key={'m-type'}
                    onClick={() => {
                      me.delayComponentLostHover('menu', `e-statistics_menu`);
                      me.setState({
                        rankLoadingStatus: 'processing',
                        conditionKey: 'TYPE',
                        sort: true,
                      }, () => {
                        me.props.bus.emit('node.statistics', 'edge.do_load',
                          {nodes: me.props.nodes.filter(node => !!node.status), edges: me.props.edges,
                            conditionKey: me.state.conditionKey, sort: me.state.sort, cacheKey: me.state.cacheKey});
                      });
                    }}
                  >连接计数</Menu.Item>
                  <Menu.Item
                    key={'m-progress'}
                    onClick={() => {
                      me.delayComponentLostHover('menu', `e-statistics_menu`);
                      me.setState({
                        rankLoadingStatus: 'processing',
                        conditionKey: 'AMOUNT_RANGE',
                        limit: 10,
                        sort: true,
                      }, () => {
                        me.props.bus.emit('node.statistics', 'edge.do_load',
                          {nodes: me.props.nodes.filter(node => !!node.status), edges: me.props.edges,
                            conditionKey: me.state.conditionKey, limit: me.state.limit, sort: me.state.sort,
                            cacheKey: me.state.cacheKey});
                      });
                    }}
                  >连接区间</Menu.Item>
                </Menu>
              )}
              onVisibleChange={visible =>
                visible ? me.onComponentHover('menu', `e-statistics_menu`) :
                  me.delayComponentLostHover('menu', `e-statistics_menu`)}
              overlayClassName={'dark-theme min-width-7em'}
            >
              <span style={{cursor: 'pointer'}}> {CONDITION_FULL_TITLE[me.state.conditionKey]} {(me.state.sort && me.state.limit > 0) ? 'Top' : undefined}{(me.state.sort && me.state.limit > 0) ? me.state.limit : undefined} <Icon name={'down'} /></span>
            </Dropdown>
            <Tooltip
              placement={"top"}
              title={"关闭统计面板"}
              overlayClassName={'dark-theme'}
            >
              <a
                onClick={me.onClose}
                style={{float: 'right', marginLeft: '2.5em'}}
              >
                <Icon name={'close'}/>
              </a>
            </Tooltip>
          </div>
          <div key={'rank'} className={style['rank-frame']}>
            {rankContent}
          </div>
        </div>
        {me.state.selectedRankItemIndex >= 0 ? (
          <div key={'list-panel'} className={`${style['frame']} ${me.lastHoverKey['list'] ? 'hover' : ''}`}>
            <div className={style['header-frame']}>
              节点连线 - 相关节点
              <Tooltip
                placement={"top"}
                title={"关闭节点列表"}
                overlayClassName={'dark-theme'}
              >
                <a
                  onClick={me.onBackToRank}
                  style={{float: 'right', marginLeft: '0.5em'}}
                >
                  <Icon name={'close'}/>
                </a>
              </Tooltip>
            </div>
            <div key={'list'} className={style['list-frame']}>
              {listContent}
            </div>
            {listBottom}
          </div>
        ) : undefined}
        {me.props.logic ? me.props.logic : (
          <ViewStatisticsEdgeLogic bus={me.props.bus} />
        )}
      </React.Fragment>
    )
  }
}

ViewStatisticsEdgePanel.defaultProps = {
  bus: PB,
  nodes: [],
  edges: [],
  initialConditionKey: 'TYPE',
};

ViewStatisticsEdgePanel.propTypes = {
  onClose: PropTypes.func.isRequired,
  bus: PropTypes.instanceOf(SimplePB),
  nodes: PropTypes.array,
  edges: PropTypes.array,
  logic: PropTypes.instanceOf(React.ReactNode),
  initialConditionKey: PropTypes.string,
};

export default ViewStatisticsEdgePanel;