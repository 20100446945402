import React from 'react';
import PropTypes from 'prop-types';
import {Tooltip} from "antd";

import {SimplePB} from "@/libs/simplePB";

import Icon from "@/components/common/common.icon";
import {getDisplayNumber} from "@/components/common/common.functions";

import style from '@/style/common/view/statistics/common.view.statistics.index.less';

class StatisticsIndexTinyImage extends React.Component {
  valueUpdatedFnTimeout = [];

  onTinyImageClicked = () => {
    let me = this;

    if (me.props.loadingStatus === 'failed') {
      // 重试
      me.props.bus.emit('statistics.index', 'config.load.do', {viewId: me.props.viewId});
    } else {
      // 其他状态下打开大图
      me.props.bus.emit('statistics.index', 'large_image.show');
    }
  };

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    let me = this, valueChangedObj = {changed: false};

    me.props.values.forEach((v, idx) => {
      if (v !== false && nextProps.values[idx] !== false && v !== nextProps.values[idx]) {
        // 数值更新
        me.valueUpdatedFnTimeout[idx] = true;
        (idx => setTimeout(() => me.valueUpdatedFnTimeout[idx] = false, 1000))(idx);
        valueChangedObj.changed = true;
      }
    });

    if (valueChangedObj.changed) {
      setTimeout(() => {
        me.forceUpdate();
      }, 2000);
    }

    return true;
  }

  render() {
    let me = this;

    let content;

    if (me.props.loadingStatus === 'success') {
      content = (
        <div className={style['tiny-image-content']}>
          <div className={style['tiny-image-background']}>
            <img
              alt={'指标图背景'}
              src={me.props.config.backgroundImgBase64Url}
            />
          </div>
          {/*
            me.props.config.indexes.map((indexConfig, idx) => (
              <div
                key={`box-${idx}`}
                className={style['tiny-image-index-frame']}
                style={{
                  left: `${indexConfig.left * 100}%`,
                  top: `${indexConfig.top * 100}%`,
                  display: me.props.values[idx] === false ? 'none' : 'flex', // 小图不展示加载状态，避免干扰用户注意力
                }}
              >
                <div className={style['tiny-image-index-frame-inner']}>
                  <Tooltip
                    title={`${indexConfig.title}：${me.props.values[idx]}`}
                  >
                    <div className={style['tiny-image-index-content']}>
                      <span className={me.valueUpdatedFnTimeout[idx] !== true ? '' : style['blinking']}>
                        {me.props.values[idx] === false ? '' : getDisplayNumber(me.props.values[idx], 2)}
                      </span>
                    </div>
                  </Tooltip>
                </div>
              </div>
            ))
          */}
        </div>
      );
    } else if (me.props.loadingStatus === 'failed') {
      content = (
        <div className={style['tiny-image-content']}>
          <div key={'failed'} className={style['tiny-image-retry']}>
            <Icon name={'exclamation-circle'} theme={'outlined'} /><br />
            <span>数据加载失败，点击重试</span>
          </div>
        </div>
      );
    } else {
      content = null;
    }

    return (
      <div
        className={style['tiny-image-frame']}
        onClick={me.onTinyImageClicked}
      >
        {content}
      </div>
    );
  }
}

StatisticsIndexTinyImage.defaultProps = {
  config: {},
  values: [],
};

StatisticsIndexTinyImage.propTypes = {
  bus: PropTypes.instanceOf(SimplePB).isRequired,
  viewId: PropTypes.string.isRequired,
  loadingStatus: PropTypes.string.isRequired,
  config: PropTypes.object,
  values: PropTypes.array,
};

export default StatisticsIndexTinyImage;