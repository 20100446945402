/**
 * 频道中心
 */
import React from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import Qs from "qs";

import {
  changePwdInitAction,
  changeUserBasicInfoAction,
  doChangePwdAction,
  doLogoutAction,
} from "@/redux-saga/account/actions";
import PB from "@/libs/simplePB";
import layoutStyles from "@/style/default/defaultViewLayout.less";
import { Copyright } from "@/components/framework/frame.components";
// import StickyNodes from "@/components/common/common.stickyNodes";
// import Feedback from "@/components/feedback/feedback.1.0";
import { submitFeedbackAction } from "@/redux-saga/system/actions";
import { SysUIConfig } from "@/constants/sys.config";
import BadgeInfo from "@/components/mainView/main.badgeInfo";
import Loading from "@/components/common/common.loading";
import Feedback from "@/components/feedback/feedback.1.0";
import StickyNodes from "@/components/common/common.stickyNodes";
// 路由组件
import ChannelEducationView from "@/components/channelView/channel.education";
import ChannelHfyzView from "@/components/channelView/channel.hfyz";
import intl from 'react-intl-universal';

class ChannelBoardView extends React.PureComponent {
  state = {
    // 加载页面遮罩
    viewLoading: {
      status: false,
      content: undefined, // 显示文字
    },
  };

  // url传入的参数
  _query = {
    isDev: false,
    isDebug: false,
  };

  componentDidMount() {
    document.title = "专题频道 -  "+intl.get('Custom.general.title');
  }

  componentWillUnmount() {
    PB.remove(this);
  }

  render() {
    // 传参
    if (this.props.history.location && this.props.history.location.search) {
      let qs = Qs.parse(this.props.history.location.search, {
        ignoreQueryPrefix: true,
        strictNullHandling: true,
      });

      if (qs.hasOwnProperty("is_dev")) {
        this._query.isDev = qs.is_dev == 1;
      }
      if (qs.hasOwnProperty("is_debug")) {
        this._query.isDebug = qs.is_debug == 1;
      }
    }

    const currentUserId = parseInt(localStorage.getItem("userId"));

    return (
      <div className={layoutStyles["layout-wrap"]}>
        <Loading />
        <div className={layoutStyles["header"]}>
          <div className={layoutStyles["logo"]}>
            <img
              onClick={() => {
                this.props.history.push({
                  pathname: "/index",
                  state: { type: "intro" },
                });
              }}
              src={intl.get('locale')==='zh-cn'?'/assets/logo-hans.png':'/assets/logo-hans_en.png'}
              alt={"炬图"}
            />
          </div>
          <BadgeInfo
            currentUserInfo={this.props.userInfo}
            doChangePwd={this.props.doChangePwd}
            onLogout={this.props.doLogout}
            history={this.props.history}
            changeUserBasicInfoStatus={this.props.changeUserBasicInfoStatus}
            onSaveUserBasicInfo={this.props.onChangeUserBasicInfo}
            style={{
              visibility: SysUIConfig.BadgeInfo.visibility,
            }}
            params={
              {
                // channel: 0,
              }
            }
          />
          <Feedback
            feedbackLoading={this.props.feedbackLoading}
            feedbackResult={this.props.feedbackResult}
            submitFeedbackAction={this.props.submitFeedbackAction}
          />
          <StickyNodes />
        </div>

        <Switch>
          <Route
            exact
            path={"/channel/education"}
            component={({ match, history }) => (
              <ChannelEducationView
                history={history}
                userInfo={this.props.userInfo}
                query={this._query}
                currentUserId={currentUserId}
                channel={1}
                // loginStatus={this.props.loginStatus}
                // onLogout={this.props.doLogout}
                // doChangePwd={this.props.doChangePwd}
                // changeUserBasicInfoStatus={
                //   this.props.changeUserBasicInfoStatus
                // }
                // onSaveUserBasicInfo={this.props.onChangeUserBasicInfo}
              />
            )}
          />
          <Route
            exact
            path={"/channel/education/*"}
            component={({ match, history }) => (
              <ChannelEducationView
                history={history}
                userInfo={this.props.userInfo}
                query={this._query}
                currentUserId={currentUserId}
                channel={1}
                // loginStatus={this.props.loginStatus}
                // onLogout={this.props.doLogout}
                // doChangePwd={this.props.doChangePwd}
                // changeUserBasicInfoStatus={
                //   this.props.changeUserBasicInfoStatus
                // }
                // onSaveUserBasicInfo={this.props.onChangeUserBasicInfo}
              />
            )}
          />
          <Route
            exact
            path={"/channel/hfyz"}
            component={({ match, history }) => (
              <ChannelHfyzView
                history={history}
                userInfo={this.props.userInfo}
                query={this._query}
                currentUserId={currentUserId}
                channel={2}
              />
            )}
          />
          <Route
            exact
            path={"/channel/hfyz/*"}
            component={({ match, history }) => (
              <ChannelHfyzView
                history={history}
                userInfo={this.props.userInfo}
                query={this._query}
                currentUserId={currentUserId}
                channel={2}
              />
            )}
          />
          <Route render={() => <Redirect to={"/"} />} />
        </Switch>
        <Copyright className={layoutStyles["version"]} />
      </div>
    );
  }
}

// 以下部分方法为了与左上角的功能对应
export default connect(
  (state) => ({
    loginStatus: state.account.loginStatus,
    userInfo: state.account.userInfo,
    changePwdStatus: state.account.changePwdStatus,
    feedbackLoading: state.system.feedbackLoading,
    feedbackResult: state.system.feedbackResult,
    changeUserBasicInfoStatus: state.account.changeUserBasicInfoStatus,
  }),
  (dispatch) => ({
    doLogout: () => dispatch(doLogoutAction()),
    submitFeedbackAction: (params) => dispatch(submitFeedbackAction(params)),
    submitPrintingAction: (params) => dispatch(submitPrintingAction(params)),
    doChangePwd: (oldPwd, newPwd, strength) =>
      dispatch(doChangePwdAction(oldPwd, newPwd, strength)),
    resetChangePwdStatus: () => dispatch(changePwdInitAction()),
    onChangeUserBasicInfo: (userInfo) =>
      dispatch(changeUserBasicInfoAction(userInfo)),
  })
)(ChannelBoardView);
