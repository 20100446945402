import * as idb from "idb";
import _ from 'lodash';

const CACHE_DB_NAME = 'joinmap-cache';
const CACHE_DB_VERSION = 4;

/**
 * @type {function(): Promise<idb.IDBPDatabase<unknown>>}
 */
const openCacheDb = ((dbName, dbVersion) => {
  /**
   * @type {idb.IDBPDatabase<unknown>}
   */
  let database;
  return () => {
    return new Promise((resolve, reject) => {
      if (database) {
        resolve(database);
      } else {
        idb.openDB(dbName, dbVersion, {
          blocked() {
            reject({code: 500, msg: 'blocked'});
          },
          upgrade(database, oldVersion/*, newVersion, transaction*/) {
            if (oldVersion < 3) {
              let existedObjectStores = database.objectStoreNames;
              if (!existedObjectStores.contains('local-cache-data')) {
                database.createObjectStore('local-cache-data');
              }
              if (!existedObjectStores.contains('view-cache-data')) {
                database.createObjectStore('view-cache-data');
              }
            }
          },
          blocking() {
            database && database.close();
            database = undefined;
          },
          terminated() {
            database = undefined;
          }
        }).then(db => {
          database = db;
          resolve(database);
        }).catch(error => reject(error));
      }
    });
  };
})(CACHE_DB_NAME, CACHE_DB_VERSION)

/**
 * @param {string} storeName
 * @return {Promise<idb.IDBPObjectStore<*, [string], string, string>>}
 */
export const getCacheStore = (storeName) => {
  return new Promise((resolve, reject) => {
    openCacheDb().then(db => {
      /**
       * @param {IDBTransactionMode} mode
       *
       * @return {idb.IDBPObjectStore<*, [string], string, string>}
       */
      const getStore = (mode) => db.transaction(storeName, mode).objectStore(storeName),
        wrapper = new Proxy({getStore}, {
          get: function ({getStore}, key, receiver) {
            let mode = 'readonly';
            if (['transaction', 'add', 'clear', 'delete', 'openCursor', 'openKeyCursor', 'put'].includes(key)) {
              mode = 'readwrite';
            }
            let store = getStore(mode), value = Reflect.get(store, key, receiver);
            if (_.isFunction(value)) {
              return value.bind(store);
            } else {
              return value;
            }
          },
        });

      resolve(wrapper);
    }).catch(error => reject(error));
  });
}