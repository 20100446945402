/*
 * @Author: Carr.s
 * @Date: 2022-07-15 09:05:22
 * @LastEditTime: 2022-07-22 15:02:10
 * @LastEditors: Carr.s
 * @Description: 个人待办中心 timeline 节点 card
 * @Copyright by joinmap
 */

import React from "react";
import PropTypes from "prop-types";
import { Tag, Tooltip, Radio, Popover, Button } from "antd";
import styles from "@/style/components/todoView/todo.timeline.less";
import moment from "moment";
import PB, { SimplePB } from "@/libs/simplePB";
import { IconFont } from "@/components/common/common.fonticon";
import TimeDisplayField from "@/components/common/common.timeDisplayField";
import UserNickAvatar from "@/components/common/objects/common.userAvatar";

import {
  calculateStandardDate,
  formatAfterDate,
} from "@/components/common/common.functions";
let today = moment().startOf("day");
let standardDate = calculateStandardDate();
// 任务常量值
const priorityText = ["一般", "紧急", "特急"];
const statusText = ["--", "待办", "已办", "办结", "撤办"];
const statusTextTips = [
  "--",
  <span>任务将重置，确定要重新发起此任务吗？</span>,
  <span>任务确定已经完成了吗？</span>,
  <span>
    任务确实已经完成了吗？ <br />
    （如果需要的话，办结后还可以再次设置任务）
  </span>,
  <span>任务确实不需要了吗？撤办后任务记录将消失！</span>,
];

const statusFilter = [
  ["not-status-4", "全部"],
  ["status-1", "待办"],
  ["status-2", "已办"],
  ["status-3", "办结"],
];

const relationFilter = {
  all: "全部",
  related_to: "与我相关",
  user_id: "由我创建",
  assign_to: "指派给我",
};

// const TimelineTodoItemCard = ({ item }) => {
class TimelineTodoItemCard extends React.PureComponent {
  render() {
    const { item, action, curUserId, memberInfoList } = this.props;
    let me = this;
    if (!item) {
      return '';
    }
    const { id, type, group, content, start, end, data } = item;
    let classnames = styles.todo_item;
    // classnames += " " + styles["priority_" + data.priority];
    // classnames += " " + styles["status_" + data.status];

    // 前缀文字
    // 判断优先级
    let priority_tag = "";
    if (data.priority >= 0) {
      priority_tag = (
        <Tag className={styles["priority_" + data.priority]}>
          {priorityText[data.priority]}
        </Tag>
      );
    }
    // 状态
    let status_tag = "";
    if (data.status >= 0) {
      status_tag = (
        <Tag className={styles["status_" + data.status]}>
          {statusText[data.status]}
        </Tag>
      );
    }

    return (
      <div className={classnames}>
        <div>
          {data.status > 1 ? status_tag : ""}
          {data.priority > 0 ? priority_tag : ""}
          <IconFont
            type="icon-todo-list"
            style={{ marginLeft: 6, marginRight: 6 }}
          />
          <b>{content}</b>
        </div>
      </div>
    );
  }
}

TimelineTodoItemCard.defaultProps = {
  bus: PB,
  curUserId: undefined,
  item: undefined,
  action: (item, action) => {},
  memberInfoList: {},
};
TimelineTodoItemCard.propTypes = {
  bus: PropTypes.instanceOf(SimplePB),
  curUserId: PropTypes.number,
  item: PropTypes.object,
  action: PropTypes.func,
  memberInfoList: PropTypes.object,
};

export default TimelineTodoItemCard;
