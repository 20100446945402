import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch as SwitchR, Link } from 'react-router-dom';
import { Avatar, Button, Col, Icon, Input, Layout, message } from 'antd';

import style from '@/style/containers/publicViews.less';
import IconCom from '@/components/common/common.icon';
import { IconTypes } from '@/constants/common';
import { getToken, REQUEST_BASE } from '@/utils/HttpUtil';
import ViewManager from '@/components/common/common.viewManager';
import dashboardStyle from '@/style/containers/dashboard.less';
import ToolbarSearchStyle from '@/style/components/main.toolbar.search.less';
import toolbarStyle from '@/style/components/main.toolbar.less';
import heightBarStyle from '@/style/components/main.headerbar.less';
import ViewInfoCard from '@/components/dashboard/dashboard.viewInfoCard';
import viewStyle from '@/style/components/dashboard/dashboard.view.less';
import QueueAnim from 'rc-queue-anim';
import { autoSetState, withReactStateHelper } from '@/libs/react-state-helper';
import { bindUtil } from '@/libs/core-decorators';
import PropTypes from 'prop-types';
import badgeInfoStyle from '@/style/components/mainView/main.badgeInfo.less';
import intl from 'react-intl-universal';

const {Header, Content} = Layout;

// 每页加载的数据
const loadItemsPerStep = 60;

@withReactStateHelper
@bindUtil.asTargetClass
class PublicView extends React.Component {
	// 展示的数据 我的图谱
	filteredViewList = undefined;

	// 全部图谱（全部有权的图谱就是所有开放阅读的图谱），也是开放阅读图谱
	@autoSetState
	@bindUtil.bindToProperty(ViewManager.dataProvider, 'guestPublicViewList')
	ViewsForPublic = [];

	// 开放复制图谱（开放阅读并可复制图谱）
	@autoSetState
	@bindUtil.bindToProperty(ViewManager.dataProvider, 'guestPublicViewListOfFree')
	ViewsForPublicOfFree = [];

	pageStart = 0;
	pageFreeStart = 0;

	state = {
		topMenus: [
			{name: '全部', key: 'all', module: 'all'},
			{name: '开放阅读', key: 'public', module: 'public'},
			{name: '开放复制', key: 'free', module: 'free'},
		],
		curKey: 'all', // 当前筛选项
		searchWord: '', // 搜索关键字
		// 标记异步获取数据的结果状态
		isAjaxResultStatus: false,
		// 展示的数据量
		displayAmount: loadItemsPerStep - 2,
		contentWrapSpacing: 100, // 计算图谱列表容器左右间距
	};

	componentDidMount() {
		this.refresh('', this.state.curKey);
		this.handleResize();
		window.addEventListener('resize', this.handleResize); //监听窗口大小改变
	}

	componentWillMount() {
		const {loginStatus} = this.props;
		if (loginStatus === 0) {
			this.props.history.push('/mainview/dashboard/all');
		}
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleResize); // 取消监听窗口大小变化
	}

	// 计算图谱列表容器左右间距
	handleResize = () => {
		// console.log('浏览器窗口大小改变事件', window.innerWidth);
		let htmlFontSize = 14, // html的响应变化基准
			windowWidth = window.innerWidth,
			viewInfoCardWidth = 26; // 图谱卡片占的宽度，单位rem，需要跟据html的响应变化基准
		// 参照css定义的html的响应设置
		if (windowWidth <= 1920) {
			htmlFontSize = 14;
		} else if (windowWidth > 1920 && windowWidth <= 3999) {
			htmlFontSize = 18;
		} else if (windowWidth > 4000) {
			htmlFontSize = 24;
		}

		const cardWidth = viewInfoCardWidth * htmlFontSize;
		this.setState({
			contentWrapSpacing: (windowWidth - Math.floor(windowWidth / cardWidth) * cardWidth) / 2,
		});
	};

	/**
	 * 加载页面数据（数据流）
	 */
	refresh = async (searchWord, key) => {
		// 显示总数
		let displayAmount = loadItemsPerStep - 1;
		// 加载数据
		switch (key) {
			case 'all':
				// 默认 all = 全部图谱
				await ViewManager.loadGuestPublicViewList({q: '', start: 0, limit: loadItemsPerStep});
				break;
			case 'public':
				// 默认 public = 开放阅读
				await ViewManager.loadGuestPublicViewList({q: '', start: 0, limit: loadItemsPerStep, teamwork_type: 0});
				break;
			case 'free':
				// 加载开放复制
				await ViewManager.loadGuestPublicViewListOfFree({q: '', start: 0, limit: loadItemsPerStep});
				break;
			case 'search':
				// 默认 search = 全部图谱
				await ViewManager.loadGuestPublicViewList({q: searchWord, start: 0, limit: 120});
				break;
			default:
				// 默认 all = 全部图谱

				// 加载全部图谱
				await ViewManager.loadGuestPublicViewList({q: searchWord, start: 0, limit: loadItemsPerStep});
		}
		// 设置状态值
		// this.setState({isAjaxResultStatus: true, displayAmount})
		this.state.isAjaxResultStatus = true;
		this.state.displayAmount = displayAmount;
		this.onSearch(searchWord, this.state.curKey);
	};

	onSearch = (value, key) => {
		console.log(' 点击筛选顶 -> value :', value, '-> key :', key);
		let me = this;
		const searchWord = value;

		requestAnimationFrame(() => {
			// 显示总数
			let displayAmount = loadItemsPerStep - 1;

			// 清空数据
			this.filteredViewList = undefined;

			// 加载数据
			switch (key) {
				case 'all':
					me.filteredViewList = me.ViewsForPublic;
					break;
				case 'public':
					me.filteredViewList = me.ViewsForPublic;
					break;
				case 'free':
					me.filteredViewList = me.ViewsForPublicOfFree;
					break;
				case 'search':
					me.filteredViewList = me.ViewsForPublic;
					break;
				default:
					// 默认 all= 我的图谱
					// 过滤数据
					me.filteredViewList = me.ViewsForPublic;
			}

			me.setState({
				isAjaxResultStatus: true,
				displayAmount,
			});
		});
	};

	onClickTabs = (searchWord, key) => {
		this.filteredViewList = undefined;
		this.setState({
			curKey: key,
			searchWord: searchWord,
		}, () => {
			this.refresh(searchWord, key);
		});

	};

	showMore = () => {
		let me = this;
		switch (this.state.curKey) {
			case 'all':
				this.pageStart = this.pageStart + loadItemsPerStep;
				ViewManager.loadGuestPublicViewList({q: '', start: this.pageStart, limit: loadItemsPerStep}).then(res => {
					this.ViewsForPublic = this.ViewsForPublic.concat(res);
					// 过滤数据
					this.filteredViewList = me.ViewsForPublic;
					this.setState({displayAmount: this.state.displayAmount + loadItemsPerStep});
				}).catch(err => {
					console.log(' 加载更多数据失败 ->  :', err);
					message.info('加载更多数据失败，请稍后再试');
				});
				break;
			case 'public':
				this.pageStart = this.pageStart + loadItemsPerStep;
				ViewManager.loadGuestPublicViewList({q: '', start: this.pageStart, limit: loadItemsPerStep, teamwork_type: 0}).then(res => {
					this.ViewsForPublic = this.ViewsForPublic.concat(res);
					// 过滤数据
					this.filteredViewList = me.ViewsForPublic;
					this.setState({displayAmount: this.state.displayAmount + loadItemsPerStep});
				}).catch(err => {
					console.log(' 加载更多数据失败 ->  :', err);
					message.info('加载更多数据失败，请稍后再试');
				});
				break;
			case 'free':
				this.pageFreeStart = this.pageFreeStart + loadItemsPerStep;
				ViewManager.loadGuestPublicViewListOfFree({q: '', start: this.pageFreeStart, limit: loadItemsPerStep}).then(res => {
					this.ViewsForPublicOfFree = this.ViewsForPublicOfFree.concat(res);
					// 过滤数据
					this.filteredViewList = me.ViewsForPublicOfFree;
					this.setState({displayAmount: this.state.displayAmount + loadItemsPerStep});
				}).catch(err => {
					console.log(' 加载更多数据失败 ->  :', err);
					message.info('加载更多数据失败，请稍后再试');
				});
				break;
			case 'search':
				this.pageStart = this.pageStart + loadItemsPerStep;
				ViewManager.loadGuestPublicViewList({q: this.state.searchWord, start: this.pageStart, limit: loadItemsPerStep}).then(res => {
					this.ViewsForPublic = this.ViewsForPublic.concat(res);
					// 过滤数据
					this.filteredViewList = me.ViewsForPublic;
					this.setState({displayAmount: this.state.displayAmount + loadItemsPerStep});
				}).catch(err => {
					console.log(' 加载更多数据失败 ->  :', err);
					message.info('加载更多数据失败，请稍后再试');
				});
				break;
			default:
				this.pageStart = this.pageStart + loadItemsPerStep;
				ViewManager.loadGuestPublicViewList({q: '', start: this.pageStart, limit: loadItemsPerStep}).then(res => {
					this.ViewsForPublic = this.ViewsForPublic.concat(res);
					// 过滤数据
					this.filteredViewList = me.ViewsForPublic;
					this.setState({displayAmount: this.state.displayAmount + loadItemsPerStep});
				}).catch(err => {
					console.log(' 加载更多数据失败 ->  :', err);
					message.info('加载更多数据失败，请稍后再试');
				});
		}


	};

	render() {
		const {topMenus, searchWord, curKey, contentWrapSpacing} = this.state;
		const filteredViewList = this.filteredViewList;

		return (
			<div className={style['public-views-layout']}>
				<Layout className={dashboardStyle['dashboard-layout-wrap']}>
					<Header style={{backgroundColor: 'hsl(213, 20%, 15%)'}}>
						<div className={`${heightBarStyle['header-bar-inside']} dark-theme`} style={{height: '70px'}}>
							<div className={heightBarStyle['header-left']} style={{paddingLeft: '1rem'}}>
								<Link className={style['frame-logo']} to='/'>
									<img src={intl.get('locale')==='zh-cn'?'/assets/logo-hans.png':'/assets/logo-hans_en.png'}
									     alt={'炬图'} style={{marginTop: '2px', height: '30px'}}/>
								</Link>
							</div>
							<div className={heightBarStyle['header-right']}>
								<div className={`${badgeInfoStyle['badge-more-view']} ${badgeInfoStyle['search-input-bar']}`} style={{paddingRight: '2rem'}}>
									<Input className={badgeInfoStyle['auto-complete-input']}
									       placeholder={'搜索图谱...'}
									       suffix={
										       <Icon
											       type="close-circle"
											       onClick={() => this.onClickTabs('', 'search')}
											       hidden={!this.state.searchWord}
											       style={{color: 'hsl(0, 0%, 100%)'}}
										       />
									       }
									       value={this.state.searchWord}
									       onPressEnter={() => this.onClickTabs(this.state.searchWord, 'search')}
									       onChange={(e) => this.setState({searchWord: e.target.value})}
									/>
									<div className={badgeInfoStyle['search-input-btn']}
									     onClick={() => this.onClickTabs(this.state.searchWord, 'search')}
									>
										<Icon
											type="search"
											className={`${badgeInfoStyle['icon']}`}
										/>
									</div>
								</div>
								<div className={heightBarStyle['badge-info-user']} onClick={() => this.props.history.push('/login')}>
									<div className={`${heightBarStyle['avatar']} ${heightBarStyle['avatar-default-icon']}`}>
										<Avatar
											style={{background: 'hsl(213, 20%, 15%)', marginRight: '0', width: '1.8rem', height: '1.8rem'}}
											size={'var(--avatar-size)'}
											src={'/home/img/user2.png'}
										/>
									</div>
									<div className={heightBarStyle['login-in']} style={{fontSize: '16px', cursor: 'pointer'}}>登录/注册</div>
								</div>

							</div>
						</div>
					</Header>
					<Content className={dashboardStyle['container']}>
						<div className={`${dashboardStyle['content']} ${style['public-views-content']} scrollbar scrollbar-none`}>
							<div className={`${toolbarStyle['tabs-frame']} dark-theme`} style={{paddingTop: '40px'}}>
								<div className={ToolbarSearchStyle['search-tool-inside'] + ' dark-theme'}>
									<div>

									</div>
								</div>
								<div className={toolbarStyle['tabs-wrap']} style={{paddingRight: contentWrapSpacing - 12}}>
									{
										topMenus.length > 0 && topMenus.map((btn, index) => (
											<Button
												size={'default'}
												className={curKey === btn.key ? 'active' : undefined}
												style={{borderBottom: curKey === btn.key ? '3px solid #1890ff' : '3px solid transparent'}}
												key={`index_${btn.name}_${index}`}
												onClick={() => this.onClickTabs('', btn.key)}
											>
												{btn.name}
											</Button>
										))
									}
								</div>
							</div>
							<div className={viewStyle['container']}>
								<QueueAnim duration={[250, 0]}
								           interval={[30, 0]}
								           className={viewStyle['cards-container']}
								           style={{margin: `0 ${contentWrapSpacing}px`}}
								>
									{filteredViewList && filteredViewList.length > 0 && filteredViewList.slice(0, this.state.displayAmount).map(view => (
										<ViewInfoCard
											readOnly={true}
											showCornerTip={true}
											view={view}
											key={`view-${view.viewId}`}
											module={'public'}
											hasLogin={false}
										/>
									))}
									{
										this.filteredViewList && this.state.displayAmount < this.filteredViewList.length ? (
											<Col key={`view-more-${Math.random()}`} md={24} lg={24} xl={24}
											     className={viewStyle['view-card-wrap']}>
												<div
													className={viewStyle['more-btn']}
													onClick={() => this.showMore()}
												>
													<Icon name='appstore'/>
													<div>加载更多</div>
												</div>
											</Col>
										) : null
									}
									{
										filteredViewList && filteredViewList.length === 0 ? (
											this.state.searchWord ? (
												<Col key={'none-view'} md={24} lg={24} xl={24}>
													<div className={viewStyle['view-none']} style={{height: '30rem', alignItems: 'center', justifyContent: 'center'}}>
														<div>没有找到与 "{this.state.searchWord}" 相关的图谱</div>
													</div>
												</Col>
											) : (
												<Col key={'none-view'} md={24} lg={24} xl={24} >
													<div className={viewStyle['view-none']} style={{height: '30rem', alignItems: 'center', justifyContent: 'center'}}>
														<div>您还没有图谱，赶紧新建……</div>
													</div>
												</Col>
											)
										) : null
									}
								</QueueAnim>
							</div>
						</div>
					</Content>
				</Layout>
			</div>
		);
	}
}

export default connect(
	state => ({
		loginStatus: state.account.loginStatus,
	}),
	dispatch => ({}),
)(PublicView);

