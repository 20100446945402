import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import copy from 'copy-to-clipboard';
import {Tooltip, Menu, Table, Modal, message} from 'antd';
import nzh from 'nzh';
// noinspection SpellCheckingInspection
import anime from 'animejs';
import EventListener from 'react-event-listener';

import PB, {SimplePB} from '@/libs/simplePB';

import {AvatarColors, IconTypes} from '@/constants/common';

import Icon from '@/components/common/common.icon';
import {defaultDefine, getNodeDisplayTitle, getNodeIcon, NODE_TYPE_TEXT} from '@/constants/vis.defaultDefine.1';

import ViewStatisticsWordPanel from '@/components/common/view/statistics/word/common.view.statistics.word.panel';
import ViewStatisticsNodeDatetimePanel from '@/components/common/view/statistics/node/common.view.statistics.node.datetimePanel';
import ViewStatisticsNodeGeneralPanel from '@/components/common/view/statistics/node/common.view.statistics.node.generalPanel';

import style from '@/style/common/view/common.view.presentation.storyFilter.less';
import ViewStatisticsEdgePanel from "@/components/common/view/statistics/edge/common.view.statistics.edge.panel";

import PresentationDataProvider from '@/components/common/dataProvider/common.dataProvider.presentation';
import TimeDisplayField from "@/components/common/common.timeDisplayField";
import {defaultIconData} from '@/components/common/view/presentation/common.view.presentation.logic';
import {getToken, REQUEST_BASE} from '@/utils/HttpUtil';
import UserAvatar from "react-user-avatar";
import intl from 'react-intl-universal';

class StoryListFilter extends React.PureComponent {
  state = {
    forceUpdate: undefined,
    nodeListLimit: 100,
    filteredConfigList: [],
    currentPresentationIndex: -1
  };

  memberMap = undefined;

  lastHoverKey = {
    list: undefined,
    statistics: undefined,
  };

  getColumns = () => {
    let me = this;
    let r = [{
      title: '专题报告名称',
      dataIndex: 'title',
      key: 'title',
      className: style['config-table-column-title'],
      render: (title, record, index) => {
        return {
          children: (
            <div
            className={style['config-box']}
            onClick={() => {
              me.onPresentationNode(record['presentationId'], record, index);
            }}
            >
              <div style={{clear: 'both'}}>
                <div className={`ant-avatar common-avatar ${style['config-avatar']}`}>
                  <img
                    src={record.meta && record.meta['iconData'] ? record.meta['iconData'] : '/assets/presentation/story_default.png'}
                    alt={'icon'}
                  />
                  {record.isPublic === 1 && 
                    <img src={`/assets/free_flag.png`} alt={intl.get('Custom.view.public')} style={{marginTop:'-4.6rem',width:'66%',height:'66%',float:'right',opacity:'inherit'}}/>}
                </div>
                <div className={style['config-meta']}>
                  <div className={style['config-title-box']}>
                    <div className={style['config-title']}><Tooltip title={record.isPublic === 1?('【'+intl.get('Custom.view.public')+'】'+title):title}>{title}</Tooltip></div>
                    <div className={`${style['config-avatar-play']}`}>
                      <div
                        className={style['config-avatar-start']}
                        onClick={e => {
                          e.stopPropagation();
                          me.onStartPresentation(record['presentationId'], record,me.memberMap && me.memberMap[`user-${record.userId}`] ? me.memberMap[`user-${record.userId}`].nick : (record.userId === -1 ? intl.get('Custom.view.system') : '--' ),
                          me.memberMap && me.memberMap[`user-${record.userId}`] ? me.memberMap[`user-${record.userId}`].picId : undefined,
                          record.meta && record.meta['iconData'] ? record.meta['iconData'] : '/assets/presentation/story_default.png');
                        }}
                      >
                        <Tooltip
                          overlayClassName={style['presentation-content']}
                          title={(
                            <span style={{display: 'inline-block', padding: '6px 8px'}}>
                                {intl.get('Custom.view.autoPlay')}
                            </span>
                          )}
                          trigger='hover'
                          placement="top"
                        >
                          <Icon name={'caret-right'} className={style['play-icon']} type={IconTypes.ANT_DESIGN}/>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                  <div className={style['config-description']}> {/*record.description*/}</div>
                  <div className={style['config-misc']}>
                    <div className={style['config-owner']}>
                      {
                        me.memberMap && me.memberMap[`user-${record.userId}`] && 
                        <UserAvatar
                          size={13}
                          name={me.memberMap[`user-${record.userId}`].nick}
                          src={me.memberMap[`user-${record.userId}`].picId ? `${REQUEST_BASE}/user/user/file/${me.memberMap[`user-${record.userId}`].picId}?Authorization=${getToken()}` : undefined}
                          colors={AvatarColors}
                          style={{marginRight: '0.5em',paddingTop:'0.3rem'}}
                        />
                      }
                      {
                        me.memberMap && me.memberMap[`user-${record.userId}`]
                          ? (me.memberMap[`user-${record.userId}`].nick) : (
                            record.userId === -1 ? intl.get('Custom.view.system') : '--'
                          )
                      
                      }
                    </div>
                    <div className={style['config-node-amount']}>
                      <Icon {...getNodeIcon({
                              type: NODE_TYPE_TEXT,
                            })} style={{marginRight: '0.3em'}} />
                        {record.content && record.content.nodeIds ? record.content.nodeIds.length : 0}
                    </div>
                    <div className={style['config-update-timestamp']}>
                      <Icon type={IconTypes.ICON_FONT} name={'icon-latest'} style={{marginRight: '0.3em'}}/>
                      <TimeDisplayField timestamp={(new Date(record.updateTimestamp)).getTime()} />
                    </div>
                  </div>
                </div>
              </div>
              {false &&
              <div className={style['config-actions-box']} >
                <div className={style['config-table-column-actions']} style={{width:'3rem',textAlign:'center',float:'left',lineHeight:2}}></div>
                <div className={style['config-description']}>
                  <div className={style['config-misc']}>{record.description}</div>
                </div>
              </div>}
              
            </div>
          ),
          props: {
            colSpan: 3,
          },
        };
      },
    }, {
      title: '节点',
      align: 'center',
      dataIndex: 'content.nodeIds.length',
      key: 'amount',
      sorter: (a, b) => a.content.nodeIds.length - b.content.nodeIds.length,
      width: '4.5rem',
      render: () => ({
        children: null,
        props: {
          colSpan: 0,
        },
      }),
    }, {
      title: '更新时间',
      align: 'center',
      dataIndex: 'updateTimestamp',
      key: 'updateTimestamp',
      sorter: (a, b) => a.updateTimestamp > b.updateTimestamp ? 1 : (a.updateTimestamp === b.updateTimestamp ? 0 : -1),
      width: '7rem',
      render: () => ({
        children: null,
        props: {
          colSpan: 0,
        },
      }),
    }];
    return r;
  };

  onStartPresentation = (presentationId, config, username, userPicId, iconData) => {
    let me = this;
    config.username = username;
    config.userPicId = userPicId;
    config.iconData = iconData;
    me.props.bus.emit('relation', 'node.presentation.stop');
    me.props.bus.emit('presentation', 'config.play.do', {viewId: me.props.viewId, presentationId, config});
  };

  onPresentationNode = (presentationId, config, index) => {
    let me = this,viewId = this.props.viewId;
    me.props.bus.emit('relation', 'presentation.custom_nodes.set',
        {viewId, title: config['title'], nodeIds: config['content']['nodeIds']});
    me.props.bus.emit('relation', 'node_filter.story_nodes.set',
        {viewId, title: config['title'], description: config['description'], storyInfo: config, nodeIds: config['content']['contentList']?config['content']['contentList']:config['content']['nodeIds']});
    me.props.bus.emit('relation', 'node_filter.filter.set',
        {viewId: config['viewId'], filterKey: 'story'});
    me.props.bus.emit('presentation', 'show.presentation.node.list.index', {index});
  };

  onComponentHover = (type, key) => {
    let me = this;
    if (!me.lastHoverKey[type]) {
      me.lastHoverKey[type] = key;
      me.forceUpdate();
    } else {
      me.lastHoverKey[type] = key;
    }
  };

  delayComponentLostHover = (type, key) => {
    let me = this;

    setTimeout(() => {
      if (me.lastHoverKey[type] === key) {
        me.lastHoverKey[type] = undefined;
        me.forceUpdate();
      }
    }, 10);
  };

  onMouseMove = () => {
    // fake empty function, initialized after mount 3 seconds
  };


  constructor(props) {
    super(props);

  }

  componentDidMount() {
    let me = this;
    me.props.bus.with(me).subscribe('presentation', 'config.list.success', ({viewId, configList}) => {
      if (me.props.viewId !== viewId) return;
      me.setState({
        configLoaded: true,
        configList,
        filteredConfigList: [...configList]
      });
    }).subscribe('teamwork', 'member.list.success', ({viewId, userList}) => {
      if (me.props.viewId !== viewId) return;
      me.memberMap = {};
      userList.forEach(userInfo => {
        me.memberMap[`user-${userInfo.userId}`] = userInfo;
      });
    }).subscribe('presentation', 'show.presentation.node.list.index', ({index}) => {
      me.setState({
        currentPresentationIndex: index
      });
    });
    me.props.bus.emit('teamwork', 'member.list.do', {viewId: me.props.viewId});
    me.props.bus.emit('presentation', 'config.list.do', {viewId: me.props.viewId});
  }

  componentDidUpdate() {
  }

  componentWillUnmount() {
    this.props.bus.remove(this);
  }

  doFocusNode = node => {
    this.props.bus.emit('relation', 'node.presentation.focus', {node: node});
  };

  render() {
    let me = this;
    //me.doStatistics(me.props.allNodeMap,me.props.idMap,me.props.storyNodeIds);

    return me.props.loading ? null : (
              <Table
                className={`${style['config-table']} scrollbar-none`}
                columns={me.getColumns()}
                pagination={false}
                rowKey={'presentationId'}
                dataSource={me.state.filteredConfigList}
                showHeader={false}
                rowClassName={(record, index) =>{
                  return index === me.state.currentPresentationIndex ? style['active'] : '';
                }}
                locale={{
                  emptyText: me.state.configLoaded ? (
                    me.state.configList.length > 0 ? '查询结果为空' : '您还没有添加专题报告'
                  ) : '加载中，请稍后',
                }}
                scroll={{y: 'calc(100% - 0rem)'}}
              />
    );
  }
}

StoryListFilter.defaultProps = {
  bus: PB,
};

StoryListFilter.propTypes = {
  bus: PropTypes.instanceOf(SimplePB),
  viewId: PropTypes.string.isRequired,
  loading:PropTypes.bool,
  resetFilter:PropTypes.func
};

export default StoryListFilter;


const stopAnimation = animations => {
  const stop = anim => {
    const { duration, remaining } = anim;
    if (remaining === 1) anim.seek(duration);
    else anim.pause();
  };
  if (Array.isArray(animations)) animations.forEach(anim => stop(anim));
  else stop(animations);
};
