/*
 * @Author: Carr.s
 * @Date: 2022-04-22 17:40:54
 * @LastEditTime: 2022-07-11 16:11:03
 * @LastEditors: Carr.s
 * @Description: 洞察 乡村治理
 * @Copyright by joinmap
 */
import React from "react";
import { notification } from "antd";
import ViewInsightChartTpl from "@/components/insightView/template/insight.chart.tpl";
import styles from "@/style/components/insightView/insight.less";
import echarts from "echarts/lib/echarts";

class ViewRuralGovInsight extends ViewInsightChartTpl {
  //===============================================================
  // begin of overwrite
  // 使用默认的方式就不用重写

  // ------------------------------------------------------------
  // 全局配置
  // ------------------------------------------------------------
  // 提示信息中提示添加哪种类型的节点
  nodeTitleInTip = "企业";
  // 显示底部按钮
  showToolbar = false;
  // 洞察名称
  viewerTitle = "乡村治理";

  // ------------------------------------------------------------
  // 层级分析对象的配置
  // ------------------------------------------------------------
  // 第一层 level1 tag
  column1_Title = "治理词汇"; // 显示名称
  column1_Unit = "条"; // 显示的文字单位

  // 第二层 item
  column2_DataField = "news"; // 取值字段
  column2_TotalField = "newsTotal"; // 计算总数(与返回数量不一定一致)字段
  column2_Title = "新闻"; // 显示名称
  column2_Unit = "篇"; // 显示的文字单位
  column2_IconRender = (level2_data, idx) => {
    // 左侧图表渲染，不输出则空白
    // 必须加上 className={styles["line-icon"]}
  };
  column2_HoverTitle = (level2_data, idx) => {
    // 列表鼠标悬浮提示文字
    return `${this.column2_Title}：${level2_data["name"]}\n点击打开${this.column2_Title}链接`;
  };

  // ------------------------------------------------------------
  // 接口的配置
  // ------------------------------------------------------------
  // 接口 url path
  api_path = "/wth_server_api/xiang_cun_manage_juzheng_by_view_id";

  // 返回数据默认值
  defaultLimit = 150;

  /**
   * 【继承组件要重写】
   * api 请求完成后，setState结束后的 callback
   */
  apiLoadedCallback = () => {
    if (this.state._column1Len > 0 && this.chartType === "echarts") {
      notification.open({
        duration: 8,
        message: "操作提示",
        description: (
          <div>
            <div>洞察数据加载成功</div>
            <div>
              图表支持鼠标<b>缩放</b>、<b>平移</b>操作。
            </div>
          </div>
        ),
      });
    }
  };

  // ------------------------------------------------------------
  // 图表的配置
  // ------------------------------------------------------------
  // 图表显示库
  chartType = "echarts";
  chartDivLeft = 300;
  chartCompomentStyle = {
    width: "100%",
    height: "100%",
  };

  /**
   * 生成公用的、默认的 option
   * @returns
   */
  initChartOption = () => {
    return {
      title: {
        show: false,
        left: "center",
        text: "企业特征分析",
        subtext: "",
      },
      tooltip: {},
      series: [
        {
          name: "ALL",
          top: "middle",
          type: "treemap",
          label: {
            show: true,
            fontSize: 16,
            formatter: function (params) {
              // console.log("params:", params);
              return `${params.data.name}\n${params.data.value}${params.data.valueUnit}`;
            },
          },
          itemStyle: {
            // 'borderColor': 'black'
          },
          visualMin: 0,
          visualMax: 100,
          // 'visualDimension': 0,
          levels: [
            {
              color: ["#363636", "#2a843a"],
              colorMappingBy: "value",
              itemStyle: {
                gapWidth: 1,
              },
            },
          ],
          breadcrumb: {
            show: false,
            bottom: 25,
          },
          data: [],
        },
      ],
    };
  };

  /**
   * 【继承组件要重写】
   * 生成 chart 的 options
   * @param {int} idx 选中的数据下标
   */
  refreshChartOptions = (idx) => {
    const {
      query: { isDev },
    } = this.props;
    let me = this;
    me._chartOption = this.initChartOption();
    me._chartOption.series[0].data = me._chartData;
    // 视觉映射的最大值
    if (me._chartData && me._chartData.length > 0) {
      me._chartOption.series[0].visualMax = me._chartData[0].value;
    }
    // 鼠标悬浮提示
    me._chartOption["tooltip"] = {
      formatter: function (info) {
        let copsTotal = info.data.hasOwnProperty("copsTotal")
          ? info.data["copsTotal"]
          : 0;
        let tagsTotal = info.data.hasOwnProperty("tagsTotal")
          ? info.data["tagsTotal"]
          : "";
        return [
          `<div class="tooltip-title">${echarts.format.encodeHTML(
            info.name
          )}</div>`,
          "共现次数：" + tagsTotal + "次<br>",
          "相关企业：" + copsTotal + "家<br>",
        ].join("");
      },
    };
  };

  /**
   * 【继承组件要重写】
   * echart 有时需要绑定点击事件什么
   * @param {object} e ReactECharts 返回的 echarts 实例
   */
  eChartRefCallback = (e) => {
    let me = this;
    // me._chartIns = e.getEchartsInstance();
    console.log("ViewCompanyTagsInsight ReactECharts e: ", e);
    if (!me.chartIns) {
      me.chartIns = e.getEchartsInstance();
      //4.树绑定事件
      me.chartIns.on("click", function (params) {
        // console.log('ViewCompanyTagsInsight ReactECharts click params: ', params);
        if (
          params.hasOwnProperty("data") &&
          params.data.hasOwnProperty("name")
        ) {
          let name = params.data.name; //点击的节点的name
          // let value = params.data.value;//点击的节点的value
          //调用点击事件
          me._column1_onClick(params.dataIndex - 1, name);
        }
      });

      // 缩放后的事件
      me.chartIns.on("datazoom", function (params) {
        console.log(
          "ViewCompanyTagsInsight ReactECharts datazoom params: ",
          params
        );
        // me.chartZoomEnd = params.end;
      });
    }
  };

  // end of overwrite
  //===============================================================
}

export default ViewRuralGovInsight;
