/*
 * @Author: Carr.s
 * @Date: 2022-07-15 09:05:22
 * @LastEditTime: 2022-07-22 15:36:47
 * @LastEditors: Carr.s
 * @Description: 个人待办中心 todo 完整信息 card
 * @Copyright by joinmap
 */

import React from "react";
import PropTypes from "prop-types";
import { Tag, Tooltip, Radio, Popover, Button } from "antd";
import styles from "@/style/components/todoView/todo.timeline.less";
import moment from "moment";
import PB, { SimplePB } from "@/libs/simplePB";
import { IconFont } from "@/components/common/common.fonticon";
import TimeDisplayField from "@/components/common/common.timeDisplayField";
import UserNickAvatar from "@/components/common/objects/common.userAvatar";
import intl from 'react-intl-universal';

import {
  calculateStandardDate,
  formatAfterDate,
  formatAfterDate_en,
} from "@/components/common/common.functions";
let today = moment().startOf("day");
let standardDate = calculateStandardDate();
// 任务常量值
const priorityText = ["一般", "紧急", "特急"];
const statusText = ["--", "待办", "已办", "办结", "撤办"];
const statusTextTips = [
  "--",
  <span>任务将重置，确定要重新发起此任务吗？</span>,
  <span>任务确定已经完成了吗？</span>,
  <span>
    任务确实已经完成了吗？ <br />
    （如果需要的话，办结后还可以再次设置任务）
  </span>,
  <span>任务确实不需要了吗？撤办后任务记录将消失！</span>,
];

const statusFilter = [
  ["not-status-4", "全部"],
  ["status-1", "待办"],
  ["status-2", "已办"],
  ["status-3", "办结"],
];

const relationFilter = {
  all: "全部",
  related_to: "与我相关",
  user_id: "由我创建",
  assign_to: "指派给我",
};

// const TodoInfoCard = ({ item }) => {
class TodoInfoCard extends React.PureComponent {
  state = {
    // confirmVisible: false, // 确定改变状态的弹层显示隐藏
    currentTaskStatus: 0, // 当前选择的任务状态
  };

  onReturn = (nodeTaskId) => {
    let me = this;

    me.props.bus.emit("node_todo", "todo.do_patch", {
      task: { nodeTaskId, status: 1 },
    });
  };

  onComplete = (nodeTaskId) => {
    let me = this;

    me.props.bus.emit("node_todo", "todo.do_complete", nodeTaskId);
  };

  onFinish = (nodeTaskId) => {
    let me = this;

    me.props.bus.emit("node_todo", "todo.do_patch", {
      task: { nodeTaskId, status: 3 },
    });
  };

  onCancel = (nodeTaskId) => {
    let me = this;

    me.props.bus.emit("node_todo", "todo.do_patch", {
      task: { nodeTaskId, status: 4 },
    });
  };

  onCancelConfirm = () => {
    let me = this;
    me.setState({
      //   confirmVisible: false,
      currentTaskStatus: 0,
    });
    console.log(
      "🚀 ~ file: timeline.nodeInfo.card.js ~ line 97 ~ TodoInfoCard ~ //TodoInfoCard ~ currentTaskStatus",
      this.state.currentTaskStatus
    );
  };

  render() {
    const { item, action, curUserId, memberInfoList } = this.props;
    let me = this;
    if (!item) {
      return <div>没有节点</div>;
    }
    const { id, type, group, content, start, end, data } = item;
    let classnames = styles.todo_info_card;
    // classnames += " " + styles["priority_" + data.priority];
    // classnames += " " + styles["status_" + data.status];

    // 前缀文字
    // 判断优先级
    let priority_tag = "";
    if (data.priority >= 0) {
      priority_tag = (
        <Tag className={styles["priority_" + data.priority]}>
          {priorityText[data.priority]}
        </Tag>
      );

      priority_tag = priorityText[data.priority];
    }

    // 状态
    let status_tag = "";
    if (data.status >= 0) {
      status_tag = (
        <Tag className={styles["status_" + data.status]}>
          {statusText[data.status]}
        </Tag>
      );
    }

    // 截止时间
    let endFormattedList = intl.get('locale')==='zh-cn'?formatAfterDate(end):formatAfterDate_en(end),
      endClassName = "";
    if (data.status === 1) {
      let realToday = moment().startOf("day");
      if (realToday.diff(today) !== 0) {
        standardDate = calculateStandardDate();
        today = realToday;
      }
      let deadlineMoment = moment(end).startOf("day"),
        { threeDaysAfter } = standardDate;
      if (deadlineMoment < today) {
        endClassName = styles["overdue-0"];
      } else if (deadlineMoment <= threeDaysAfter) {
        endClassName = styles["overdue-1"];
      }
    }

    // 权限
    let isNotOwner = curUserId !== parseInt(data.userId);
    let isNotAssignee = !data.assignTo || curUserId !== parseInt(data.assignTo);

    return (
      <div className={classnames} style={{ width: "100%" }}>
        <div className={styles.div_line}>
          <Tooltip placement={"left"} title={"待办"}>
            <IconFont
              type="icon-todo-list"
              style={{ marginRight: 6, fontSize: "14px" }}
            />
          </Tooltip>
          <b>{content}</b>
          <div className={styles.act_btns}>
            {/* {curUserId == data.userId ? (
              <Tooltip placement={"top"} title={"编辑待办"}>
                <IconFont
                  type="icon-edit1"
                  style={{ marginRight: 6, color: "#2db7f5" }}
                  onClick={() => {
                    action && action(item, "update");
                  }}
                />
              </Tooltip>
            ) : (
              ""
            )} */}
          </div>
        </div>
        <hr
          style={{
            borderColor: "#636363",
            marginBottom: 3,
          }}
        />
        <div className={styles.div_line}>
          <Tooltip placement={"left"} title={"待办优先级"}>
            <IconFont
              type={"icon-exclamatory-mark-t"}
              style={{ marginRight: 8, fontSize: "16px" }}
            />
          </Tooltip>
          <span className={styles.todo_tags}>
            <span
              className={
                data.priority == 0
                  ? styles.todo_tag + " " + styles._0
                  : styles.todo_tag
              }
            >
              {priorityText[0]}
            </span>
            <span
              className={
                data.priority == 1
                  ? styles.todo_tag + " " + styles._1
                  : styles.todo_tag
              }
            >
              {priorityText[1]}
            </span>
            <span
              className={
                data.priority == 2
                  ? styles.todo_tag + " " + styles._2
                  : styles.todo_tag
              }
            >
              {priorityText[2]}
            </span>
          </span>

          <div style={{ clear: "both" }}></div>
        </div>
        <div className={styles.div_line}>
          <Tooltip placement={"left"} title={"待办状态"}>
            <IconFont
              type={"icon-icon_Readytopick"}
              style={{ marginRight: 8 }}
            />
          </Tooltip>

          <span className={styles.todo_tags}>
            {/* <span
              className={
                data.status == 4
                  ? styles.todo_tag + " " + styles.s_4
                  : styles.todo_tag
              }
            >
              {statusText[4]}
            </span> */}

            <span
              className={
                data.status == 1
                  ? styles.todo_tag + " " + styles.s_1
                  : styles.todo_tag
              }
            >
              {statusText[1]}
            </span>
            <span
              className={
                data.status == 2
                  ? styles.todo_tag + " " + styles.s_2
                  : styles.todo_tag
              }
            >
              {statusText[2]}
            </span>
            <span
              className={
                data.status == 3
                  ? styles.todo_tag + " " + styles.s_3
                  : styles.todo_tag
              }
            >
              {statusText[3]}
            </span>
          </span>

          {/* 
          <Popover
            title={`${statusText[me.state.currentTaskStatus]}提示`}
            overlayStyle={{
              zIndex: 999,
            }}
            content={
              <div style={{ padding: ".5rem" }}>
                <div
                  style={{
                    minWidth: "15rem",
                    fontSize: "1rem",
                    textAlign: "center",
                    paddingBottom: "1.5rem",
                  }}
                >
                  {statusTextTips[me.state.currentTaskStatus]}
                </div>
                <div
                  style={{
                    textAlign: "right",
                  }}
                >
                  <Button
                    onClick={me.onCancelConfirm}
                    style={{ marginRight: ".8rem" }}
                  >
                    取消
                  </Button>
                  <Button
                    type={"primary"}
                    onClick={() => {
                      switch (me.state.currentTaskStatus) {
                        case 1:
                          me.onReturn(data.nodeTaskId);
                          break;
                        case 2:
                          me.onComplete(data.nodeTaskId);
                          break;
                        case 3:
                          me.onFinish(data.nodeTaskId);
                          break;
                        case 4:
                          me.onCancel(data.nodeTaskId);
                          break;
                      }
                      me.onCancelConfirm();
                    }}
                  >
                    确定
                  </Button>
                </div>
              </div>
            }
            visible={!!me.state.currentTaskStatus}
          >
            <Radio.Group
              size={"small"}
              value={data.status}
              onChange={(e) =>
                me.setState({ currentTaskStatus: e.target.value })
              }
              buttonStyle={"solid"}
              className={`${styles["status-radio"]} todo-status-${data.status}`}
            >
              <Radio.Button
                value={4}
                key={`status-4`}
                disabled={isNotOwner || data.status === 3}
              >
                {statusText[4]}
              </Radio.Button>
              <Radio.Button
                value={1}
                key={`status-1`}
                disabled={isNotOwner || data.status === 4 || data.status === 3}
              >
                {statusText[1]}
              </Radio.Button>
              <Radio.Button
                value={2}
                key={`status-2`}
                disabled={
                  (isNotOwner && isNotAssignee) ||
                  data.status === 4 ||
                  data.status === 3
                }
              >
                {statusText[2]}
              </Radio.Button>
              <Radio.Button
                value={3}
                key={`status-3`}
                disabled={isNotOwner || data.status === 4}
              >
                {statusText[3]}
              </Radio.Button>
            </Radio.Group>
          </Popover>
           */}
        </div>
        <div className={styles.div_line}>
          <Tooltip placement={"left"} title={"待办发起与接收人员"}>
            <IconFont
              type={"icon-user"}
              style={{ float: "left", marginRight: 8 }}
            />
          </Tooltip>
          <UserNickAvatar
            className={styles["user_in_line"]}
            userId={data.userId + ""}
            user={
              memberInfoList.hasOwnProperty(data.userId)
                ? memberInfoList[data.userId]
                : undefined
            }
            avatarSize="1rem"
          />
          <span style={{ float: "left", marginLeft: 5, marginRight: 5 }}>
            <IconFont
              type="icon-move-right"
              style={{ marginLeft: 6, marginRight: 6 }}
            />
          </span>
          <span style={{ float: "left", marginRight: 5 }}>指派给:</span>
          <UserNickAvatar
            className={styles["user_in_line"]}
            userId={data.assignTo + ""}
            user={
              memberInfoList.hasOwnProperty(data.assignTo)
                ? memberInfoList[data.assignTo]
                : undefined
            }
            avatarSize="1rem"
          />{" "}
          <div style={{ clear: "both" }}></div>
        </div>
        <div className={styles.div_line}>
          <Tooltip placement={"left"} title={"待办起止时间"}>
            <IconFont type={"icon-calendar"} style={{ marginRight: 8 }} />
          </Tooltip>
          <TimeDisplayField
            tooltipPlacement="bottom"
            timestamp={moment(start, "YYYY-MM-DD HH:mm:ss").valueOf()}
            description={"更新时间："}
          />
          <span>&nbsp;&nbsp;▷▷&nbsp;&nbsp;</span>
          <TimeDisplayField
            tooltipPlacement="bottom"
            timestamp={moment(end, "YYYY-MM-DD HH:mm:ss").valueOf()}
            description={"截止时间："}
          >
            <span className={endClassName}>
              {endFormattedList[0]}
              {endFormattedList[1] ? ` (${endFormattedList[1]})` : null}
            </span>
          </TimeDisplayField>
        </div>
      </div>
    );
  }
}

TodoInfoCard.defaultProps = {
  bus: PB,
  curUserId: undefined,
  item: undefined,
  action: (item, action) => {},
  memberInfoList: {},
};
TodoInfoCard.propTypes = {
  bus: PropTypes.instanceOf(SimplePB),
  curUserId: PropTypes.number,
  item: PropTypes.object,
  action: PropTypes.func,
  memberInfoList: PropTypes.object,
};

export default TodoInfoCard;
