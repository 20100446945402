/*
 * @Author: Carr.s
 * @Date: 2022-04-29 12:26:34
 * @LastEditTime: 2022-05-16 09:53:01
 * @LastEditors: Carr.s
 * @Description:
 * @Copyright by joinmap
 */
/*
 * @Author: Carr.s
 * @Date: 2022-04-29 11:41:35
 * @LastEditTime: 2022-04-29 12:24:26
 * @LastEditors: Carr.s
 * @Description: 预料分析洞察(领导讲话)
 * @Copyright by joinmap
 */
import React from "react";
import ViewInsightChartTpl from "@/components/insightView/template/insight.chart.tpl";
import { connect } from "react-redux";
import styles from "@/style/components/insightView/insight.less";
import echarts from "echarts/lib/echarts";

class ViewIndustrialChainAInsight extends ViewInsightChartTpl {
  //===============================================================
  // begin of overwrite
  // 使用默认的方式就不用重写

  // ------------------------------------------------------------
  // 全局配置
  // ------------------------------------------------------------
  nodeTitleInTip = "事件";
  // 显示底部按钮
  showToolbar = false;
  // 洞察名称
  viewerTitle = "语料风格";
  /**
   * 标题渲染
   * 兼容已经做好的洞察页面
   * @returns
   */
  _viewerTitleRender = () => {
    return "";
  };

  // ------------------------------------------------------------
  // 层级分析对象的配置
  // ------------------------------------------------------------
  // 第一层 level1 tag
  column1_showType = "none"; // 第一列的展现形式，list=显示列，tree=显示树
  // column1_DataField = "data"; // 取值字段
  // column1_TotalField = "dataTotal"; // 计算总数(与返回数量不一定一致)字段
  column1_treeFields = []; // 用树状目录显示数据时各层级的 children 字段名数据
  column1_Title = "段落长度"; // 显示名称
  column1_Unit = "个"; // 显示的文字单位
  column1_subTitle = () => {
    // 列表顶部副标题文字
    const { _column1Len, _column1Total } = this.state;
    return <>已显示出现次数前{_column1Len}名的段落长度</>;
  };
  /*
  column1_IconRender = (level1_data, idx,nodePath=undefined) => {
    // 左侧图表渲染，不输出则空白
    // 必须加上 className={styles["line-icon"]}
  };
  */
  column1_HoverTitle = (column1_data, idx, nodePath = undefined) => {
    // 列表鼠标悬浮提示文字
    let name = column1_data.hasOwnProperty("name") ? column1_data.name : "";
    if (this.column2_DataField) {
      let column2Length = this.column2_DataField
        ? column1_data[this.column2_DataField].length
        : "";
      return `${this.column1_Title}：${name}\n${this.column2_Title}：${column2Length}${this.column2_Unit}\n点击打开${this.column2_Title}列表`;
    } else {
      return `${this.column1_Title}：${name}`;
    }
  };

  // 第二层 item
  column2_DataField = null; // 取值字段
  column2_TotalField = "value"; // 计算总数(与返回数量不一定一致)字段
  column2_Title = "企业"; // 显示名称
  column2_Unit = "家"; // 显示的文字单位
  column2_ValueRender = (level2_data, idx) => {
    // 右侧数值渲染，不输出则空白
    // 必须加上 className={styles["line-value"]}
    // 不显示
  };

  // ------------------------------------------------------------
  // 接口的配置
  // ------------------------------------------------------------
  // 接口 url path
  // api_path = "";
  api_path = "/wth_server_api/chang_du_by_view_id";

  // 返回数据默认值
  defaultLimit = 60;

  /**
   * 【继承组件要重写】
   * api 请求完成后，setState结束后的 callback
   */
  apiLoadedCallback = () => {
    // message.info("数据加载成功");
  };

  // ------------------------------------------------------------
  // 图表的配置
  // ------------------------------------------------------------
  // 图表显示库
  chartType = "echarts";

  chartCompomentStyle = {
    width: "100%",
    height: "100%",
  };

  /**
   * 生成公用的、默认的 option
   * @returns
   */
  initChartOption = () => {
    return {
      title: {
        show: false,
      },
      grid: {
        top: "20%",
        left: "20%",
        right: "20%",
        bottom: "20%",
      },
      xAxis: {
        data: [],
        axisLabel: {
          inside: true,
          color: "#fff",
          interval: 0,
          rotate: 40,
        },
        axisTick: {
          show: false,
        },
        axisLine: {
          show: false,
        },
        z: 10,
      },
      yAxis: {
        name: "出现频率(次数)",
        nameTextStyle: {
          color: "#eeeeee",
        },
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          color: "#999",
        },
      },
      dataZoom: [
        {
          type: "inside",
        },
      ],
      series: [],
    };
  };

  /**
   * 【继承组件要重写】
   * 生成 chart 的 options
   * @param {int} idx 选中的数据下标
   */
  refreshChartOptions = (nodePath = null) => {
    console.log("refreshChartOptions 0 nodePath:", nodePath);
    // icon的前缀
    const {
      query: { isDev },
    } = this.props;
    let me = this;
    let data = [];
    this._chartData.forEach((e, idx) => {
      data.push([e.name, e.value, idx]);
    });

    // 生成options
    me._chartOption = this.initChartOption();

    let barData = [];
    let xAxisData = [];

    me._chartData.forEach((i1, idx1) => {
      xAxisData.push(i1.name);

      barData.push({
        value: i1.value,
        _path: idx1,
      });

      // me._chartData[idx1]["_path"] = idx1;
    });

    // xAxis
    me._chartOption.xAxis = {
      name: "句子长度(字数)",
      nameTextStyle: {
        color: "#eeeeee",
      },
      data: xAxisData,
      axisLine: { onZero: true },
      splitLine: { show: false },
      splitArea: { show: false },

      axisLabel: {
        color: "#eeeeee",
        interval: 0,
        rotate: 40,
      },
    };

    //series
    me._chartOption.series.push({
      type: "bar",
      showBackground: true,
      barWidth: 30,
      barMaxWidth: 40,
      barMinWidth: 10,
      barGap: 10,
      barCategoryGap: 10,
      itemStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          { offset: 0, color: "#83bff6" },
          { offset: 0.5, color: "#188df0" },
          { offset: 1, color: "#188df0" },
        ]),
        borderWidth: 0,
      },
      emphasis: {
        itemStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: "#2378f7" },
            { offset: 0.7, color: "#2378f7" },
            { offset: 1, color: "#83bff6" },
          ]),
        },
      },
      label: {
        show: true,
        position: "inside",
        color: "#eeeeee",
        formatter: function (params) {
          // console.log("params:", params);
          return `${params.data.value}次`;
        },
      },
      data: barData,
    });

    // 鼠标悬浮提示
    me._chartOption["tooltip"] = {
      formatter: function (info) {
        return [
          `<div class="tooltip-title">句子长度为 ${echarts.format.encodeHTML(
            info.name
          )}</div>`,
          "出现：" + info.value + "次<br>",
        ].join("");
      },
    };

    return;
    // ---------------------------------------------
    // 计算图表的左侧留空宽度 column1_list
    let w = 10; // 两个间隙

    let column1Div = document.getElementById("column1_list");
    if (nodePath === undefined || nodePath === null || nodePath === false) {
      w = 0;
    } else {
      if (column1Div) {
        console.log("column1_list.clientWidth:", column1Div.clientWidth);
        w = w + column1Div.clientWidth;
      }
    }

    // 更新 图表div的左偏移量
    let chartDiv = document.getElementById("chart_div");
    console.log("chartDiv:", chartDiv);
    if (chartDiv) {
      chartDiv.style.marginLeft = w + "px";
    }
  };

  /**
   * 【继承组件要重写】
   * echart 有时需要绑定点击事件什么
   * @param {object} e ReactECharts 返回的 echarts 实例
   */
  eChartRefCallback = (e) => {
    let me = this;
    // me.chartIns = e.getEchartsInstance();
    if (!me._chartIns) {
      me._chartIns = e.getEchartsInstance();
      // 绑定点击事件
      me._chartIns.on("click", function (params) {
        console.log(
          "ViewIndustrialChainBInsight ReactECharts click params: ",
          params
        );
        if (
          params.hasOwnProperty("data") &&
          params.data.hasOwnProperty("_path")
        ) {
          let name = params.name; //点击的节点的name
          //调用点击事件
          me._column1_onClick(params.data._path, name, false);
          // me._column1_onClick(params.dataIndex - 1, name);
        } else {
          me._column1_onClick(null, null, true);
        }
      });
    }
  };
  /**
   * api 请求成功后的初始化过程
   * @param {*} resData api 返回的data
   * @returns
   */
  _init = (resData) => {
    this._chartData = this._chartData.slice(0, 20);
    // 生成  chart的option
    this.refreshChartOptions();
  };

  // end of overwrite
  //===============================================================

  getColorOfLevel = (level, maxLevel = 1000000) => {
    if (level > 0 && level < this._chartColors.length) {
      if (level > maxLevel) {
        return "#dddddd";
      }
      return this._chartColors[level];
    } else {
      return "#dddddd";
    }
  };
}

export default connect(
  (state) => ({
    userInfo: state.account.userInfo,
  }),
  (dispatch) => ({})
)(ViewIndustrialChainAInsight);
