/*
 * @Author: Carr.s
 * @Date: 2022-07-22 10:08:27
 * @LastEditTime: 2022-07-22 15:54:30
 * @LastEditors: Carr.s
 * @Description: 节点的子弹评估, 评估子项的列表
 * @Copyright by joinmap
 */
import React from "react";
import PropTypes from "prop-types";
import {
  Modal,
  Button,
  Popover,
  Radio,
  message,
  Form,
  Select,
  Row,
  Col,
  Input,
  List,
  Avatar,
  Spin,
  Empty,
} from "antd";
import moment from "moment";
import PB, { SimplePB } from "@/libs/simplePB";
import Icon from "@/components/common/common.icon";
import { IconTypes } from "@/constants/common";
import nodeStyle from "@/style/components/mainView/node.less";
import UserNickAvatar from "@/components/common/objects/common.userAvatar";
import TimeDisplayField from "@/components/common/common.timeDisplayField";
import NodeAssessDetail from "@/components/common/node/assess/common.node.assess.detail";
import {
  calculateStandardDate,
  formatAfterDate,
} from "@/components/common/common.functions";
// 数据流
import NodeDataProvider from "@/components/common/dataProvider/common.dataProvider.node";

class NodeAssessFieldList extends React.Component {
  state = {};

  componentDidMount() {
    console.log(
      "🚀 ~ file: common.node.assess.list.js ~ line 33 ~ NodeAssessFieldList ~ componentDidMount",
      this.props
    );
  }

  componentWillUnmount() {
    this.props.bus.remove(this);
  }

  render() {
    let me = this;

    const { field, height, assessList, loadAssessList: loading } = this.props;
    if (loading) {
      return (
        <div
          style={{
            flex: 1,
            minHeight: height || 300,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin
            tip="正在加载评估"
            indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />}
            size="large"
          />
        </div>
      );
    }
    if (!assessList || assessList.length === 0) {
      return (
        <Empty
          image="/assets/icons/empty.svg"
          imageStyle={{
            height: 120,
          }}
          description={
            <span>
              <span style={{ fontSize: "1rem", margin: "15px 0" }}>
                该节点还没有评估
              </span>
            </span>
          }
          style={{ margin: "20px auto", height: 200, width: 260 }}
        />
      );
    }

    return (
      <div
        style={{
          minWidth: 450,
          maxWidth: "43rem",
          // height: height > 150 ? height : 150,
          height:
            assessList.length == 1
              ? 200
              : assessList.length == 2
              ? 350
              : assessList.length == 3
              ? 450
              : assessList.length == 4
              ? 550
              : height,
          // backgroundColor: "rgba(0,0,0,.1)",
          display: "flex",
          // alignItems: "stretch",
        }}
      >
        <div
          className={"scrollbar-18"}
          style={{
            flex: 1,
            height: "100%",
            // backgroundColor: "rgba(0,0,0,0)",
            overflowX: "hidden",
            overflowY: "auto",
          }}
        >
          <div style={{ backgroundColor: "rgba(0,0,0,0)" }}>
            <List
              split={false}
              itemLayout="horizontal"
              dataSource={assessList}
              renderItem={(item) => {
                let formattedList = formatAfterDate(item.updateTimestamp);
                let isFieldBlank = true;
                let fieldData = JSON.parse(item[field]);
                Object.values(fieldData).forEach((v) => {
                  if (v) {
                    isFieldBlank = false;
                  }
                });
                if (isFieldBlank) {
                  return <></>;
                }
                return (
                  <List.Item
                    style={{
                      flexDirection: "column",
                      alignItems: "flex-start",
                      paddingLeft: 10,
                      paddingRight: 10,
                      paddingBottom: 0,
                      // cursor: "pointer",
                    }}
                  >
                    <div
                      style={{
                        // backgroundColor: "rgba(0,0,0,.1)",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottom: "solid 1px rgba(0,0,0,.1)",
                      }}
                    >
                      <UserNickAvatar
                        userId={item.userId + ""}
                        user={undefined}
                        avatarSize="2rem"
                        nickStyle={{
                          fontSize: "1rem",
                          color: "rgba(0,0,0,.85)",
                        }}
                      />
                      <div
                        style={{
                          fontSize: "1rem",
                          color: "rgba(0,0,0,.5)",
                        }}
                      >
                        <TimeDisplayField
                          timestamp={moment(
                            item.updateTimestamp,
                            "YYYY-MM-DD"
                          ).valueOf()}
                          tooltipPlacement={"bottom"}
                          description={"评估时间"}
                        >
                          <span>
                            {formattedList[0]}
                            {formattedList[1] ? ` (${formattedList[1]})` : null}
                          </span>
                        </TimeDisplayField>
                      </div>
                    </div>

                    <NodeAssessDetail
                      assessInfo={item}
                      fields={field ? [field] : undefined}
                      showTitle={false}
                    />
                  </List.Item>
                );
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

NodeAssessFieldList.defaultProps = {
  bus: PB,
  height: 450,
  assessList: [],
  loadAssessList: false,
};

NodeAssessFieldList.propTypes = {
  bus: PropTypes.instanceOf(SimplePB),
  field: PropTypes.string, // 显示的字段名
  height: PropTypes.number, // modal body的高度
  assessList: PropTypes.array, // 要显示的评估列表
  loadAssessList: PropTypes.bool, // 评估列表的请求状态
};

export default NodeAssessFieldList;
