/*
 * @Author: Carr.s
 * @Date: 2022-04-22 17:40:54
 * @LastEditTime: 2022-07-11 15:56:37
 * @LastEditors: Carr.s
 * @Description: 洞察 农技创新
 * @Copyright by joinmap
 */
import React from "react";
import ViewInsightChartTpl from "@/components/insightView/template/insight.chart.tpl";
import styles from "@/style/components/insightView/insight.less";

class ViewFarmTechInsight extends ViewInsightChartTpl {
  // ---begin custom-----------------------------------------
  colorMaxIdx = 10;
  // ---end of custom-----------------------------------------

  //===============================================================
  // begin of overwrite
  // 使用默认的方式就不用重写

  // ------------------------------------------------------------
  // 全局配置
  // ------------------------------------------------------------
  // 提示信息中提示添加哪种类型的节点
  nodeTitleInTip = "";
  // 显示底部按钮
  showToolbar = false;
  // 洞察名称
  viewerTitle = "农技创新";
  // 每次点击 column1 时是否刷新 chart
  refreshForClickColumn1 = false;

  // ------------------------------------------------------------
  // 层级分析对象的配置
  // ------------------------------------------------------------
  // 第一层 level1 tag
  // column1_DataField = "data"; // 取值字段
  // column1_TotalField = "dataTotal"; // 计算总数(与返回数量不一定一致)字段
  column1_Title = "农技项目"; // 显示名称
  column1_Unit = "个"; // 显示的文字单位
  column1_treeFields = [];
  column1_ValueRender = (level1_data, idx, nodePath = undefined) => {
    // 右侧数值渲染，不输出则空白
    // 必须加上 className={styles["line-value"]}
    return (
      <div className={styles["line-value"]}>
        {level1_data[this.column2_TotalField]}
        {this.column2_Unit}
      </div>
    );
  };

  /**/
  column1_IconRender = (level1_data, idx, nodePath = undefined) => {
    // 左侧图表渲染，不输出则空白
    // 必须加上 className={styles["line-icon"]}
    let color = "#b6b6b6";
    if (idx < this.colorMaxIdx - 1 && idx < this._chartColors.length) {
      color = this._chartColors[idx];
    }
    return (
      <div className={styles["line-icon"]}>
        <div
          className={styles["dot-icon"]}
          style={{ backgroundColor: color }}
        ></div>
      </div>
    );
  };
  column1_HoverTitle = (level1_data, idx, nodePath = undefined) => {
    // 列表鼠标悬浮提示文字
    return `${this.column1_Title}：${level1_data["name"]}\n${
      this.column2_Title
    }：${level1_data[this.column2_TotalField]}${this.column2_Unit}\n点击打开${
      this.column2_Title
    }列表`;
  };

  // 第二层 item
  column2_DataField = "children"; // 取值字段
  column2_TotalField = "childrenTotal"; // 计算总数(与返回数量不一定一致)字段
  column2_Title = "相关词汇"; // 显示名称
  column2_Unit = "个"; // 显示的文字单位
  column2_ValueRender = (column2_data, idx) => {
    // 右侧数值渲染，不输出则空白
    // 必须加上 className={styles["line-value"]}
    return (
      <div className={styles["line-value"]}>
        {column2_data["_value"]}
        {column2_data["valueUnit"]
          ? column2_data["valueUnit"]
          : this.column2_Unit}
      </div>
    );
  };

  /* 
  column2_IconRender = (level2_data, idx) => {
    // 左侧图表渲染，不输出则空白
    // 必须加上 className={styles["line-icon"]}
  };
 */
  column2_HoverTitle = (level2_data, idx) => {
    // 列表鼠标悬浮提示文字
    return `${this.column2_Title}：${level2_data["name"]}\n点击打开图谱链接`;
  };

  // ------------------------------------------------------------
  // 接口的配置
  // ------------------------------------------------------------
  // 接口 url path
  api_path = "/wth_server_api/nong_ji_by_view_id";

  // 返回数据默认值
  defaultLimit = 150;

  /**
   * 【继承组件要重写】
   * api 请求完成后，setState结束后的 callback
   */
  apiLoadedCallback = () => {
    // message.info("数据加载成功");
  };

  // ------------------------------------------------------------
  // 图表的配置
  // ------------------------------------------------------------
  // 图表显示库
  chartType = "highcharts";

  // chartDivLeft = 300;
  chartStyle = {
    top: 0,
    bottom: 0,
  };
  chartCompomentStyle = {
    width: "100%",
    height: "100%",
  };

  /**
   * 生成公用的、默认的 option
   * @returns
   */
  initChartOption = () => {
    const {
      query: { isDebug },
    } = this.props;
    let chartWidth = document.getElementById("chart_div").clientWidth;
    let chartHeight = document.getElementById("chart_div").clientHeight;

    let me = this;
    return {
      colors: this._chartColors,
      credits: {
        enabled: false, // 禁用版权信息
      },
      chart: {
        backgroundColor: null,
        type: "packedbubble",
        // 指定内边距，下面的四个配置可以用 spacing: [10, 10, 15, 10] 来代替
        spacingBottom: 0,
        spacingTop: 0,
        spacingLeft: 10,
        spacingRight: 10,
        // 指定图表大小
        width: chartWidth,
        height: chartHeight,
      },
      title: {
        text: "",
      },
      legend: {
        enabled: false,
        itemStyle: {
          color: "#f8f8f8",
        },
      },
      tooltip: {
        useHTML: true,
        headerFormat: "",
        backgroundColor: "#fff",
        formatter: function () {
          // console.log("this", this);
          if (this.key) {
            return `<span style="z-index:9999;"><b>${this.series.name}</b> 与 <b>${this.point.name}</b> 合作${this.point._value}次</sub></span>`;
          }
          return `<span style="z-index:9999;"><b>${this.series.name}</b> 的合作单位</span>`;
        },
      },
      plotOptions: {
        packedbubble: {
          useSimulation: true,
          minSize: "5%",
          maxSize: "150%",
          zMin: 20,
          zMax: 500,
          dataLabels: {
            enabled: true,
            align: "center",
            useHTML: true,
            formatter: function () {
              // console.log("data label:", this);
              let txt = this.key.slice(0, 6);
              if (this.key.length > 8) {
                txt += "...";
              }
              return `<div style="z-index:-1;text-align: center;width: 120px;"><b>${txt}</b></div>`;
              // return '<div style="z-index:-1;text-align: center;width: 120px;"><b>{point.name}</b></div>';
            },
            filter: {
              property: "y",
              operator: ">",
              value: 5, // 250
            },
            style: {
              // color: "#f9f9f9",
              color: "#858b91",
              // color: "#1f6de1", // 麻1
              // color: "#8250d1", // 麻2
              textOutline: "none",
              fontWeight: "normal",
              fontSize: "1rem",
            },
            overflow: "crop",
            allowOverlap: true,
          },
          layoutAlgorithm: {
            gravitationalConstant: 0.05,
            splitSeries: true,
            seriesInteraction: false,
            dragBetweenSeries: true,
            parentNodeLimit: true,
          },
          // 允许选中点
          allowPointSelect: true,
          point: {
            events: {
              click: (e) => {
                if (isDebug) console.log("point event e:", e);
                let sIndex = undefined;
                let sName = undefined;
                if (
                  e.point.hasOwnProperty("isParentNode") &&
                  e.point.isParentNode
                ) {
                  if (isDebug)
                    console.log("point isParentNode:", e.point.isParentNode);
                  // 点击了大饼
                  sIndex = e.point.series.index;
                  sName = e.point.series.name;
                  if (isDebug) console.log("point event sIndex:", sIndex);
                  if (isDebug) console.log("point event sName:", sName);
                  // setTimeout(() => {
                  //   me._column1_onClick(sIndex, sName, false);
                  // }, 2000);
                } else {
                  sIndex = e.point.series.index;
                  sName = e.point.series.name;
                  if (isDebug) console.log("point event sIndex:", sIndex);
                  if (isDebug) console.log("point event sName:", sName);
                  me._column1_onClick(sIndex, sName, false);
                }
              },
            },
          },
        },
      },
      series: [],
    };
  };

  /**
   * 【继承组件要重写】
   * 生成 chart 的 options
   * @param {int} nodePath 选中的数据下标
   */
  refreshChartOptions = (nodePath = null) => {
    let me = this;
    // 处理数据
    let nodes = [];
    this._chartData.forEach((n1, idx1) => {
      let n1Data = [];

      // 统计项数值
      let dataValueCount = 0;
      n1["children"].forEach((n2, idx2) => {
        // 计算总次数
        dataValueCount = dataValueCount + n2.value;
        // 计算 value 值
        if (n2.hasOwnProperty("value")) {
          n2["_value"] = n2["value"] + 1;

          // 计算圆圈大小
          if (n2["value"] >= 10) {
            n2["value"] = 100; //10
          } else if (n2["value"] >= 5) {
            n2["value"] = 90; //5
          } else {
            n2["value"] = 80; //3
          }
        } else {
          n2["_value"] = 1;
          n2["value"] = 80;
        }

        n1Data.push(n2);
      });

      // 额外值
      n1["childrenValueSum"] = dataValueCount;
      n1["valueUnit"] = "次";
      n1["value"] = idx1;
      // 颜色
      if (idx1 < me.colorMaxIdx) {
        n1["color"] = me._chartColors[idx1];
      } else {
        n1["color"] = "#b6b6b6";
      }
      n1.data = n1Data;
      nodes.push(n1);
    });

    // 在按新的value值排序
    // this._level1Sort("value");

    console.log("refreshChartOptions this._chartData=", this._chartData);
    console.log("refreshChartOptions nodes=", nodes);
    this._chartOption = this.initChartOption();
    this._chartOption.series = nodes;
  };

  /**
   * 【继承组件要重写】
   * echart 有时需要绑定点击事件什么
   * @param {object} e ReactECharts 返回的 echarts 实例
   */
  eChartRefCallback = (e) => {
    // me._chartIns = e.getEchartsInstance();
    // let me = this;
  };

  // end of overwrite
  //===============================================================
}

export default ViewFarmTechInsight;
