import React from 'react';
import PropTypes from 'prop-types';

import Icon from '@/components/common/common.icon';
import {IconTypes} from "@/constants/common";
import {defaultDefine} from "@/constants/vis.defaultDefine.1";

export default class IconAvatar extends React.Component {
  render () {
    let props = {...this.props};
    delete props.color;
    return (
      <span
        className={`ant-avatar ant-avatar-circle ant-avatar-icon common-avatar`}
        style={{color: this.props.color}}
      >
        <Icon {...props} />
      </span>
    );
  }
}

IconAvatar.defaultProps = {
  type: IconTypes.ANT_DESIGN,
  name: 'question-circle',
  color: defaultDefine.colors.level1,
};

IconAvatar.propTypes = {
  type: PropTypes.number, // icon 库名
  name: PropTypes.string, // icon 名
  color: PropTypes.string, // icon 颜色
};
