import React from 'react';
import PropTypes from 'prop-types';
/*import {message} from 'antd';*/

import PB, {SimplePB} from '@/libs/simplePB';

import ViewDataProvider from '@/components/common/dataProvider/common.dataProvider.view';
import MicroServiceModalConfigDetail
  from '@/components/common/view/microService/modal/common.view.microService.modal.configDetail';
import MicroServiceModalConfigList
  from '@/components/common/view/microService/modal/common.view.microService.modal.configList';
import MicroServiceModalService from '@/components/common/view/microService/modal/common.view.microService.modal.service';
import MicroServiceLogicCall from '@/components/common/view/microService/logic/common.view.microService.logic.call';
import MicroServiceLogicConfig from '@/components/common/view/microService/logic/common.view.microService.logic.config';
import MicroServiceLogicOperationSaveNodes
  from '@/components/common/view/microService/logic/operation/common.view.microService.logic.operation.saveNodes';
import MicroServiceLogicOperationSaveEdges
  from '@/components/common/view/microService/logic/operation/common.view.microService.logic.operation.saveEdges';
import MicroServiceLogicOperationSaveSubGraphs
  from '@/components/common/view/microService/logic/operation/common.view.microService.logic.operation.saveSubGraphs';
import MicroServiceLogicOperationSaveContent
  from "@/components/common/view/microService/logic/operation/common.view.microService.logic.operation.saveContent";
import MicroServiceLogicOperationShowWordCloud
  from '@/components/common/view/microService/logic/operation/common.view.microService.logic.operation.showWordCloud';
import MicroServiceLogicOperationSaveFiles
  from '@/components/common/view/microService/logic/operation/common.view.microService.logic.operation.saveFiles';
import MicroServiceLogicOperationShowCharts
  from '@/components/common/view/microService/logic/operation/common.view.microService.logic.operation.showCharts';
import MicroServiceLogicOperationShowImplement
  from '@/components/common/view/microService/logic/operation/common.view.microService.logic.operation.showImplement';
import MicroServiceModalMarketDrawer
  from "@/components/common/view/microService/modal/common.view.microService.modal.marketDrawer";
//import MicroServiceModalServiceWizard
//  from "@/components/common/view/microService/modal/common.view.microService.modal.serviceWizard";
import MicroServiceModalServiceWizard
  from "@/components/common/view/microService/modal/common.view.microService.modal.serviceWizard.v3";

import style from '@/style/common/microService/common.microService.less';
import {toLatestConfig} from '@/components/common/view/microService/common.view.microService.dataAdapter';

class MicroService extends React.PureComponent {
  state = {
    showModal: false,
    microServiceId: undefined,

    microServiceInfoMap: {},
    microServiceConfigMap: {},

    showListModal: false,

    showDetailModal: false,
    detailInfo: undefined,

    showMarketDrawer: false,
    marketProjectId: undefined,
    marketActiveTag: undefined,
    marketKeyword: undefined,
    dataType: 0,
    serviceInfo: undefined,
    serviceConfig: undefined
  };

  componentDidMount() {
    let me = this, /** @type {SimplePB} me.props.bus */ bus = me.props.bus;

    bus.with(me).subscribe('view', 'micro_service.service.show_modal', ({viewId, serviceId, dataType, serviceInfo}) => {
      if (viewId === me.props.viewDataProvider.viewId) {
        let serviceConfig = dataType===1?{
          "id": serviceInfo.id,
          "version": serviceInfo.version,
          "request": {
            "targetLimit": 10,
            "resultLimit": 100
          },
          "parameter":{
            formLayout: undefined,
            items:[]
          },
          "target":{
            default: "node",
            formLayout: undefined,
            items: [],
            label: ""
          },
          "operations": [
           {
              "type": me.props.currentNodeId ? "saveSubGraphs": "saveNodes",
              "lineType": "solid",
              "arrangeBy": "star",
              "classifyBy": "none",
              "copyTextKey": "$.fname"
           }
         ]}:undefined; (serviceInfo && serviceInfo.uiConfig ? serviceInfo.uiConfig : undefined);
        if(dataType===0 && serviceInfo && serviceInfo.uiConfig){
          serviceConfig = {
            id: serviceInfo.id,
            ...toLatestConfig(serviceInfo.uiConfig),
          }
        }
        me.setState({showModal: true, microServiceId: serviceId , dataType: dataType?dataType:0 ,serviceInfo, serviceConfig}, () => {
          me.props.bus.emit('view', 'micro_service.service.modal_visible_changed',
            {viewId, visible: me.state.showModal, serviceId});
        });
        if(serviceInfo && serviceInfo.id){
          me.props.bus.emit('view', 'micro_service.config.data_loaded', {projectId:serviceInfo.id, infoList:[serviceInfo], configList:[serviceConfig]});
        }
      }
    }).subscribe('view', 'micro_service.config.hide_market_drawer', () => {
      me.setState({showMarketDrawer: false});
    }).subscribe('view', 'micro_service.config.show_market_drawer', ({projectId,activeTag,keyword}) => {
      me.setState({showMarketDrawer: true, marketProjectId: projectId,marketActiveTag: activeTag,marketKeyword: keyword});
    }).subscribe('view', 'micro_service.config.show_list_modal', () => {
      me.setState({showListModal: true});
    }).subscribe('view', 'micro_service.config.show_detail_modal', ({info}) => {
      me.setState({showDetailModal: true, detailInfo: info});
    }).subscribe('view', 'micro_service.config.data_loaded', ({projectId, infoList, configList}) => {
      let microServiceInfoMap = me.state.microServiceInfoMap,
        microServiceConfigMap = me.state.microServiceConfigMap;

      if (configList.length > 0) {
        configList.forEach((config, idx) => {
          let info = infoList[idx];
          microServiceInfoMap[info.id] = info;
          microServiceConfigMap[info.id] = config;
        });
        if (projectId === 'builtin') {
          infoList.forEach(info => {
            me.props.bus.emit('view', 'micro_service.config.builtin_config_loaded', info);
          });
        }
        me.setState({microServiceInfoMap, microServiceConfigMap});
      }
    })/*.subscribe('view', 'micro_service.service.raw_data_loaded', ({viewId, serviceId, hasMoreResult, autoLoadMore}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId
        && me.state.microServiceInfoMap[serviceId]) {

        if (!autoLoadMore || !hasMoreResult) {
          message.success(`${me.state.microServiceInfoMap[serviceId].title}已完成`);
        }
      }
    })*/;

    // bus.emit('view', 'micro_service.config.on_load', {projectId: 'builtin'});

    setTimeout(() => bus.emit('view', 'micro_service.component.loaded',
      {viewId: me.props.viewDataProvider.viewId}), 200);
  }

  componentWillUnmount() {
    this.props.bus.remove(this);
  }

  render() {
    let me = this;

    return (
      <React.Fragment>
        <MicroServiceLogicConfig viewDataProvider={me.props.viewDataProvider} bus={me.props.bus} />
        <MicroServiceLogicCall
          viewDataProvider={me.props.viewDataProvider}
          microServiceConfigMap={me.state.microServiceConfigMap}
          currentUserId={me.props.userId}
          currentNodeId={me.props.currentNodeId}
          bus={me.props.bus}
        />
        <MicroServiceLogicOperationSaveNodes
          viewDataProvider={me.props.viewDataProvider}
          microServiceConfigMap={me.state.microServiceConfigMap}
          userId={me.props.userId}
          bus={me.props.bus}
        />
        <MicroServiceLogicOperationSaveEdges
          viewDataProvider={me.props.viewDataProvider}
          microServiceConfigMap={me.state.microServiceConfigMap}
          userId={me.props.userId}
          bus={me.props.bus}
        />
        <MicroServiceLogicOperationSaveSubGraphs
          viewDataProvider={me.props.viewDataProvider}
          microServiceConfigMap={me.state.microServiceConfigMap}
          userId={me.props.userId}
          bus={me.props.bus}
        />
        <MicroServiceLogicOperationSaveContent
          viewDataProvider={me.props.viewDataProvider}
          microServiceConfigMap={me.state.microServiceConfigMap}
          bus={me.props.bus}
        />
        <MicroServiceLogicOperationShowWordCloud
          viewDataProvider={me.props.viewDataProvider}
          microServiceConfigMap={me.state.microServiceConfigMap}
          bus={me.props.bus}
        />
        <MicroServiceLogicOperationSaveFiles
          viewDataProvider={me.props.viewDataProvider}
          microServiceConfigMap={me.state.microServiceConfigMap}
          bus={me.props.bus}
        />
        <MicroServiceLogicOperationShowCharts
          viewDataProvider={me.props.viewDataProvider}
          microServiceConfigMap={me.state.microServiceConfigMap}
          bus={me.props.bus}
        />
        <MicroServiceLogicOperationShowImplement
          viewDataProvider={me.props.viewDataProvider}
          microServiceConfigMap={me.state.microServiceConfigMap}
          bus={me.props.bus}
        />
        {
          me.state.serviceInfo || (me.state.microServiceId && me.state.microServiceConfigMap[me.state.microServiceId]) ? (
            <MicroServiceModalServiceWizard
              viewDataProvider={me.props.viewDataProvider}
              microServiceInfo={me.state.serviceInfo||me.state.microServiceInfoMap[me.state.microServiceId]}
              microServiceConfig={me.state.serviceConfig||me.state.microServiceConfigMap[me.state.microServiceId]}
              microServiceId={me.state.microServiceId}
              visible={me.state.showModal}
              onClose={() => me.setState({showModal: false,serviceInfo:undefined}, () => {
                me.props.bus.emit('view', 'micro_service.service.modal_visible_changed',
                  {viewId: me.props.viewDataProvider.viewId, visible: me.state.showModal,
                    serviceId: me.state.microServiceId});
              })}
              userId={me.props.userId}
              currentNodeId={me.props.currentNodeId}
              bus={me.props.bus}
              serviceInfo={me.state.serviceInfo}
              dataType={me.state.dataType}
            />
          ) : null
        }
        <MicroServiceModalConfigList
          onClose={() => me.setState({showListModal: false})}
          visible={me.state.showListModal}
          bus={me.props.bus}
        />
        <MicroServiceModalConfigDetail
          onClose={() => me.setState({showDetailModal: false})}
          visible={me.state.showDetailModal}
          info={me.state.detailInfo}
          bus={me.props.bus}
        />
        <div className={style['market-drawer-container']}>
          {me.state.showMarketDrawer && <MicroServiceModalMarketDrawer
            onClose={() => me.setState({showMarketDrawer: false})}
            visible={me.state.showMarketDrawer}
            viewId={me.props.viewDataProvider ? me.props.viewDataProvider.viewId : undefined}
            projectId={me.state.marketProjectId}
            activeTag={me.state.marketActiveTag}
            keyword={me.state.marketKeyword}
          />}
        </div>
      </React.Fragment>
    );
  }
}

MicroService.defaultProps = {
  bus: PB,
};

MicroService.propTypes = {
  viewDataProvider: PropTypes.instanceOf(ViewDataProvider).isRequired,
  userId: PropTypes.number.isRequired,
  currentNodeId: PropTypes.string,
  bus: PropTypes.instanceOf(SimplePB),
};

export default MicroService;