// 库
import React from 'react';
import PropTypes from 'prop-types';
import vis from 'vis';
import moment from 'moment';
import momentLocale from 'moment/locale/zh-cn';
import 'vis/dist/vis-timeline-graph2d.min.css';
// css
import style from '@/style/components/toolbar/timeline.less';
// 组件
import NodeInfoCard from "@/components/explorationView/exploration.nodeInfoCard";
import {showAsTip} from "@/components/common/common.functions";
import _ from "lodash";
import {FilterAndStatisticsBus, timestampPathOfNode} from "@/components/common/common.timestampAndLocationStatistics";
import {bindUtil} from "@/libs/core-decorators";
import {autoSetState, withReactStateHelper} from "@/libs/react-state-helper";
import {getNodeDisplayTitle} from "@/constants/vis.defaultDefine.1";

// 时序图内节点样式
/*function NodeInfoInTimeline(props, /!* context *!/) {
    const {mode, item} = props;
    const {name, type, color} = getNodeIconByType(item.node.type);
    // 按节点类型显示不同的文字
    return <div style={{backgroundColor: 'hsla(0, 0%, 13%, 1)'}}>
        <Icon name={name} type={type} color={color}/> {mode === 'nav' ? item.start : item.content}
    </div>
}*/

// momnet 使用中文
moment.updateLocale('zh-cn', momentLocale);

/**
 * 用vis js 的timeline组件画图标出节点的时间信息
 * 方式一：nav 配置
 * 方式二：default 配置
 * 判断是否有时间字段 --> 并转换start字段
 */
@bindUtil.asTargetClass
@withReactStateHelper
class TimelineNav extends React.Component {
    // 容器id
    containerId = 'visTimelineContainerId';
    container = undefined;
    // 浮动提示id
    elNodeInfoCardId = 'NodeInfoCard';
    // vis timeline实例
    myTimeline = undefined;
    // 有开始时间的node数据
    nodesHasTimeArray = []; // vis timeline 格式的node 数组
    nodesHasTimeDataSet = new vis.DataSet(); // 用于 vis timeline 画图绑定的dataset

    lastHoveredItem = false;

    @bindUtil.bindToProperty('props.bus', 'statisticsUpdated')
    @autoSetState
    statisticsUpdated = false;

    processedUpdate = false; // 已处理过的原始数据更新标记

    // timeline默认配置，正常状态(多个节点的)
    defaultOption = {
        width: '100%',
        height: '100%',
        type: 'box',
        selectable: false,
        zoomMin: 7 * 24 * 60 * 60 * 1000,
        min: new Date(1800, 1, 1),
        max: new Date(2037, 12, 31),
        template: (item) => {
            return item.content;
        },
        format: {
            minorLabels: {
                millisecond: 'SSS',
                second: 'MM:ss',
                minute: 'HH:mm',
                hour: 'HH:mm',
                weekday: 'D日',
                day: 'D日',
                week: '第w周',
                month: 'M月',
                year: 'YYYY年',
            },
            majorLabels: {
                millisecond: 'HH:mm:ss',
                second: 'D日 HH时',
                minute: 'M月DD日',
                hour: 'M月DD日',
                weekday: 'YYYY年MM月',
                day: 'YYYY年MM月',
                week: 'YYYY年MM月',
                month: 'YYYY年',
                year: '',
            },
        },
    };

    state = {
        // 强制刷新
        refresh: false,
        // 组件自己的提示
        tip: null,
        // 鼠标悬浮的节点
        nodeOnMouseHover: undefined,
    };

    componentDidMount() {
        let that = this;
        this.container = document.getElementById(this.containerId);
        let options = this.defaultOption;
        this.myTimeline = new vis.Timeline(this.container, this.nodesHasTimeDataSet, options);
        // 绑定事件
        this.myTimeline.on('mouseOver', (params) => {
            let classList;
            if (that.lastHoveredItem && that.lastHoveredItem !== params.event.target) {
                that.lastHoveredItem.parentElement.className =
                  that.lastHoveredItem.parentElement.className.split(' ')
                    .filter(name => name !== 'hover').join(' ');
                that.lastHoveredItem = false;
            }
            if (params.item !== null) {
                // 数据列鼠标悬浮
                if (!that.lastHoveredItem) {
                    that.lastHoveredItem = params.event.target;
                    classList = that.lastHoveredItem.parentElement.className.split(' ');
                    classList.push('hover');
                    that.lastHoveredItem.parentElement.className = classList.join(' ');
                }
                that.setState({
                    nodeOnMouseHover: params.item,
                }, () => {
                    let c = that.container.getClientRects();
                    showAsTip(that.elNodeInfoCardId, {x: 0, y: -c[0].top}); // 设置跟随鼠标移动
                })
            } else {
                that.setState({
                    nodeOnMouseHover: undefined,
                })
            }
        });
        this.myTimeline.on('click', (params) => {
            console.log('TimelineNav->myTimeline->click->params=', params);
        });

        // 效果不明
        // this.myTimeline.zoomOut(1, {animation: true})

        //清理数据，只留下有指定字段的数据
        // 对于没有时间的信息，默认挂载到当天
        this.nodesHasTimeArray = this.props.bus.nodes/*.filter(
          node => moment(_.get(node, timestampPathOfNode, 0)).isValid()
        )*/.map(node => {
          let m = moment(_.get(node, timestampPathOfNode, 0));
          m = m.isValid() ? m : moment();
          return {
            id: node.id,
            start: m.format('YYYY-MM-DD'), //YYYY-MM-DD HH:mm:ss
            content: getNodeDisplayTitle(node),
          };
        });

        // 画图
        this.drawAll(this.nodesHasTimeArray);

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        console.log('TimelineNav->componentDidUpdate->prevProps：', prevProps);
        if (this.processedUpdate !== this.props.bus.statisticsUpdated) {
            this.processedUpdate = this.props.bus.statisticsUpdated;
            // 对于没有时间的信息，默认挂载到当天
            this.nodesHasTimeArray = this.props.bus.nodes/*.filter(
              node => moment(_.get(node, timestampPathOfNode, 0)).isValid()
            )*/.map(node => {
                let m = moment(_.get(node, timestampPathOfNode, 0));
                m = m.isValid() ? m : moment();
                return {
                    id: node.id,
                    start: m.format('YYYY-MM-DD'), //YYYY-MM-DD HH:mm:ss
                    content: getNodeDisplayTitle(node),
                };
            });
            // 画图
            this.drawAll(this.nodesHasTimeArray)
        }
    }

    /**
     * 画全部节点
     * @param {array} nodes 含有有效分类字段值的节点数组
     */
    drawAll = (nodes) => {
        let me = this;

        this.nodesHasTimeDataSet.clear();
        me.myTimeline.setOptions({
            min: new Date(1800, 1, 1),
            max: new Date(2037, 12, 31),
        });
        if (Array.isArray(nodes)) {
            this.nodesHasTimeDataSet.add(nodes);
            // 显示的节点居中
            if (nodes.length === 1) {
                console.log('TimelineNav->drawAll->nodes.length == 1-> nodes=', nodes);
                this.myTimeline.focus(nodes[0].id, {
                    animation: true,
                })
            } else if (nodes.length > 1) {
                this.myTimeline.fit({
                    animation: true,
                })
            } else if (nodes.length === 0) {
                console.log('TimelineNav->drawAll->没有数据画图')
            }

            /*
            this.myTimeline.zoomOut(0,{animation:true})
            */

            // 强制刷新
            this.setState({
                refresh: !this.state.refresh,
            }, () => {
                if (nodes.length <= 0) {
                    // no items
                } else if (nodes.length === 1) {
                    // a single item -> don't fit, just show a range around the item from -4 to +3 days
                    me.myTimeline.setOptions({
                        min: moment(nodes[0].start).add(-4, 'days'),
                        max: moment(nodes[0].start).add(3, 'days'),
                    });
                } else {
                    const range = me.myTimeline.getItemRange();
                    me.myTimeline.setOptions(range);
                }
            })
        } else {
            console.log('TimelineNav->drawAll->传入的nodes不是数组:', nodes)
        }
    };

    /**
     * 按照字段值获取节点
     * @param {function} predicate 过滤条件函数
     * @param {Array} items 被过滤的数组
     * @returns {Array}
     */
    /*getItemsIdByFieldFromArray = (predicate, items = this.nodesHasTimeArray) => {
        let result = [];
        items.forEach((item, index) => {
            if (predicate(item, index)) {
                result.push(item.id)
            }
        });
        return result
    };*/

    /**
     * 切换时间
     * @param {object} date {begin:Date,end:Date}
     * @param {function} predicate
     */
    /*switchToDate = ({date, predicate = undefined},) => {
        if (date instanceof Object) {
            const {begin, end} = date;
            let beginDate = begin ? moment(begin) : moment().subtract(100, 'years')
            let endDate = end ? moment(end) : moment();
            let ids = this.getItemsIdByFieldFromArray((item) => {
                // let nodeDate = moment(getObjectValue(item.info, fieldName))
                // 直接用item.start判断就行
                let nodeDate = moment(item.start);
                return nodeDate.isBetween(beginDate, endDate)
            });

            if (ids.length === 0) {
                // message.warning('暂时没有找到 ' + begin + ' - ' + end + ' 内资源，请尝试其他时间段。')
                return null
            }

            // 高亮并居中
            this.myTimeline.setSelection(ids, {
                focus: true,
            })
        } else if (date === '合计') {
            // 全部节点
            // this.drawAll(this.nodesHasTimeArray)
        } else {
            // 其他情况
        }
    };*/

    render() {
        // console.log('TimelineNav->render->this.props:', this.props)
        const {mode, link} = this.props;
        const {tip, nodeOnMouseHover} = this.state;

        // 显示卡片的节点
        let nodeSelected;
        this.props.bus.nodes.forEach(node => {
            if (nodeOnMouseHover && node.id === nodeOnMouseHover) {
                nodeSelected = node
            }
        });

        return (
            <div
                className={mode === 'nav' ? (this.props.className + ' ' + style['nav'] + ' ' + style['inside']) : (this.props.className + ' ' + style['inside'])}
            >
                {
                    link ? (
                      <div
                        style={{
                            position: 'absolute',
                            top: '0',
                            left: '0',
                            width: '100%',
                            height: '100%',
                            cursor: 'pointer',
                            zIndex: '1000',
                        }}
                        onClick={link}
                      />
                    ) : null
                }

                <span
                    style={{
                        position: 'absolute',
                        top: '-25px',
                        left: '200px',
                        textAlign: 'center',
                    }}
                >
                    {tip}
                </span>
                <div
                    id={this.containerId}
                    style={{width: '100%', height: '100%'}}
                >
                </div>
                {
                    nodeSelected ? (
                      <div
                        id={this.elNodeInfoCardId}
                        className={style['info-card-float']}
                      >
                        <NodeInfoCard
                          style={{padding: 0}}
                          readonly={this.props.readonly}
                          node={nodeSelected}
                          onAddToGraph={this.props.onAddToGraph}
                          onRemoveFromGraph={this.props.onRemoveFromGraph}
                          onExplore={this.props.onExplore}
                          onVote={this.props.onVote}
                        />
                      </div>
                    ) : null
                }
            </div>

        )
    }
}

TimelineNav.propTypes = {
    readonly: PropTypes.bool,
    mode: PropTypes.oneOf(['default', 'nav']), // 组件的模式：nav=导航状态，normal=正常状态
    className: PropTypes.string,// 组件的外部容器的 classname
    // 传入的配置项
    options: PropTypes.shape({
        filterField: PropTypes.string, // 过滤数据字段
        classifyField: PropTypes.string, // 分类字段
    }),
    link: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.bool,
    ]),// nav 模式时点击响应函数
    // linkTip: PropTypes.string,// nav 模式时跳转链接的文字提示
    noticeAsEmpty: PropTypes.func, // 最终绘图时，如结果为空，则通知上级组件
    bus: PropTypes.instanceOf(FilterAndStatisticsBus).isRequired,
    onExplore: PropTypes.func.isRequired,
    onVote: PropTypes.func.isRequired,
};

TimelineNav.defaultProps = {
    readonly: true,
    mode: 'default',
    className: null,
    options: {
        filterField: 'meta.create_date',
        classifyField: 'start',
    },
    link: false,
    // linkTip: null,
    noticeAsEmpty: () => {
        console.log('MapNav->defaultProps->noticeAsEmpty emited')
    },
};

export default TimelineNav;
