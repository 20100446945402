import React from 'react';
import PropTypes from "prop-types";

import PB from '@/libs/simplePB';
import style from "@/style/components/main.nodeInfo.less";

import {
  TYPE_FIELD_NAME,
  NODE_TYPE_TEXT,//
  NODE_TYPE_COMPANY,//
  NODE_TYPE_TALENT,//
  NODE_TYPE_PAPER,//
  NODE_TYPE_PATENT,//
  NODE_TYPE_TAG,//
} from "@/constants/vis.defaultDefine.1";

import NodeInfoNodeGeneralPanel from "@/components/mainView/nodeInfo/main.nodeInfo.common.general";
import NodeInfoCompanyGeneralPanel from "@/components/mainView/nodeInfo/main.nodeInfo.company.general";
import NodeInfoTalentGeneralPanel from "@/components/mainView/nodeInfo/main.nodeInfo.talent.general";
import NodeInfoPaperGeneralPanel from '@/components/mainView/nodeInfo/main.nodeInfo.paper.general';
import NodeInfoPatentGeneralPanel from '@/components/mainView/nodeInfo/main.nodeInfo.patent.general';
import NodeInfoTextGeneralPanel from '@/components/mainView/nodeInfo/main.nodeInfo.text.general';
import NodeInfoTagGeneralPanel from "@/components/mainView/nodeInfo/main.nodeInfo.tag.general";
import ViewDataProvider from '@/components/common/dataProvider/common.dataProvider.view';
import {NodeDetailInfoLoadingStatus, NodeInfoUpdatingStatus} from "@/libs/view/network/status";
import {EdgeEvents, NodeEvents} from "@/libs/view/network/events";
import Icon from '@/components/common/common.icon';

const RelatedInfoPanelEnum = Object.freeze({
  LINKED_NODES: 'linked-nodes',
  EXPANDED_RESULT: 'expanded-result',
  GROWN_RESULT: 'grown-result',
  PAPER_LIST: 'paper-list',
  PATENT_LIST: 'patent-list',
  ATTACHMENT_LIST: 'attachment-list',
});

class NodeInfoPanel extends React.Component {
  state = {
    nodeId: undefined, // 当前展示的节点ID
    loadingStatus: NodeDetailInfoLoadingStatus.IDLE,
    errorCode: 0,
    errorMsg: '',
    updatingStatus: NodeInfoUpdatingStatus.IDLE,
    currentShownRelatedInfoPanel: RelatedInfoPanelEnum.LINKED_NODES,
    activeKey: 'none',
  };

  nodeDetailInfo = {}; // 节点详细信息

  delayMarkAsInterestedTimeout = undefined; // 延时标记为感兴趣超时函数指针

  componentDidMount() {
    let me = this;
    PB.sub(this, 'nodeInfo', 'showLinkedNodeList', nodeId => {
      if (me.nodeDetailInfo && me.nodeDetailInfo.id === nodeId) {
        me.setState({
          currentShownRelatedInfoPanel: RelatedInfoPanelEnum.LINKED_NODES,
        });
      }
    }).sub(this, 'nodeInfo', 'showPropertyList', nodeId => {
      if (me.nodeDetailInfo && me.nodeDetailInfo.id === nodeId) {
        me.setState({
          currentShownRelatedInfoPanel: RelatedInfoPanelEnum.ATTACHMENT_LIST,
        });
      }
    }).sub(this, 'nodeInfo', 'showExpandResult', nodeId => {
      if (me.nodeDetailInfo && me.nodeDetailInfo.id === nodeId) {
        me.setState({
          currentShownRelatedInfoPanel: RelatedInfoPanelEnum.EXPANDED_RESULT,
        });
      }
    }).sub(this, 'nodeInfo', 'showRelatedResourceResult', nodeId => {
      if (me.nodeDetailInfo && me.nodeDetailInfo.id === nodeId) {
        me.setState({
          currentShownRelatedInfoPanel: RelatedInfoPanelEnum.GROWN_RESULT,
        });
      }
    });

    if (this.props.networkRef) {
      this.props.networkRef.subscribe(this, NodeEvents.LOADING_DETAIL_INFO, nodeId => {
        if (nodeId !== me.state.nodeId) return;
        me.setState({
          loadingStatus: NodeDetailInfoLoadingStatus.PROCESSING,
          errorCode: 0,
          errorMsg: '',
        });
      }).subscribe(this, NodeEvents.DETAIL_INFO_LOADED, (nodeId, nodeInfo) => {
        if (nodeId !== me.state.nodeId) return;
        me.nodeDetailInfo = nodeInfo;
        me.setState({
          loadingStatus: NodeDetailInfoLoadingStatus.SUCCESS,
          errorCode: 0,
          errorMsg: '',
        });
      }).subscribe(this, NodeEvents.LOAD_DETAIL_INFO_FAILED, (nodeId, code, msg) => {
        if (nodeId !== me.state.nodeId) return;
        if (me.props.ignoreLoadError) {
          // 获取失败且允许忽略失败时，尝试从传入参数获取数据
          me.nodeDetailInfo = me.props.getNodeInfo(me.props.nodeId);
          me.setState({
            loadingStatus: NodeDetailInfoLoadingStatus.SUCCESS,
            errorCode: 0,
            errorMsg: '',
          });
        } else {
          me.nodeDetailInfo = undefined;
          me.setState({
            loadingStatus: NodeDetailInfoLoadingStatus.SUCCESS,
            errorCode: code,
            errorMsg: msg,
          });
        }
      }).subscribe(this, NodeEvents.UPDATING, nodeInfoList => {
        nodeInfoList.forEach(nodeInfo => {
          if (nodeInfo && nodeInfo.id !== me.state.nodeId) return;
          me.setState({updatingStatus: NodeInfoUpdatingStatus.PROCESSING});
        });
      }).subscribe(this, NodeEvents.UPDATED, nodeIds => {
        nodeIds.forEach(nodeId => {
          if (nodeId !== me.state.nodeId) return;
          me.setState({updatingStatus: NodeInfoUpdatingStatus.SUCCESS},
            () => me.setState({updatingStatus: NodeInfoUpdatingStatus.IDLE}));
          me.onTryAgain();
        });
      }).subscribe(this, NodeEvents.UPDATE_FAILED, nodeInfoList => {
        nodeInfoList.forEach(nodeInfo => {
          if (nodeInfo && nodeInfo.id !== me.state.nodeId) return;
          me.setState({updatingStatus: NodeInfoUpdatingStatus.FAILED});
        });
      }).subscribe(this, EdgeEvents.ADDED, (type, addedEdgeIds, addedEdges) => {
        let refresh = false;
        addedEdges.forEach(
          edgeInfo => refresh = refresh || (edgeInfo.from === me.state.nodeId) || (edgeInfo.to === me.state.nodeId));
        if (refresh) me.onTryAgain();
      });
    }

    requestAnimationFrame(() => {
      if (me.props.networkRef) {
        me.setState({
          nodeId: me.props.nodeId,
          activeKey: 'none',
        }, () => me.onTryAgain());
      } else if (me.props.getNodeInfo) {
        me.nodeDetailInfo = me.props.getNodeInfo(me.props.nodeId);
        me.nodeDetailInfo.$getInfoRef = () => me;
        me.setState({
          nodeId: me.props.nodeId,
          loadingStatus: NodeDetailInfoLoadingStatus.SUCCESS,
          errorCode: 0,
          errorMsg: '',
          activeKey: 'none',
        });
      }
      me.delayMarkAsInterested(me.props.nodeId);
    });
  }

  componentWillUnmount() {
    PB.remove(this);
    this.props.networkRef && this.props.networkRef.unSubscribe(this);
    if (this.delayMarkAsInterestedTimeout) {
      clearTimeout(this.delayMarkAsInterestedTimeout);
      this.delayMarkAsInterestedTimeout = undefined;
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let me = this;
    if ((me.props.nodeId || this.state.nodeId) && me.props.nodeId !== this.state.nodeId) {
      if (me.props.networkRef) {
        me.setState({
          nodeId: me.props.nodeId,
          activeKey: 'none',
          loadingStatus: NodeDetailInfoLoadingStatus.IDLE,
          currentShownRelatedInfoPanel: RelatedInfoPanelEnum.LINKED_NODES,
        }, () => me.onTryAgain());
      } else if (me.props.getNodeInfo) {
        me.nodeDetailInfo = me.props.getNodeInfo(me.props.nodeId);
        me.nodeDetailInfo.$getInfoRef = () => me;
        me.setState({
          nodeId: me.props.nodeId,
          loadingStatus: NodeDetailInfoLoadingStatus.SUCCESS,
          errorCode: 0,
          errorMsg: '',
          activeKey: 'none',
        });
      }
      me.delayMarkAsInterested(me.props.nodeId);
    }
  }

  delayMarkAsInterested = nodeId => {
    let me = this;

    // 仅支持对图谱中的节点做更新访问时间操作
    if (!me.props.networkRef) return;

    if (me.delayMarkAsInterestedTimeout) {
      clearTimeout(me.delayMarkAsInterestedTimeout);
      me.delayMarkAsInterestedTimeout = undefined;
    }

    if (!nodeId) return;

    me.delayMarkAsInterestedTimeout = setTimeout(() => {
      me.delayMarkAsInterestedTimeout = undefined;
      if (nodeId === me.state.nodeId) {
        console.log(`Node (id=${nodeId}) is marked as interested.`);
        if (me.props.networkRef) {
          me.props.networkRef.accessNode(nodeId);
        }
      }
    }, 1000);
  };

  onTryAgain = (e) => {
    e && e.preventDefault();
    if (this.state.nodeId && this.state.loadingStatus !== NodeDetailInfoLoadingStatus.PROCESSING) {
      // noinspection JSIgnoredPromiseFromCall
      this.props.networkRef && this.props.networkRef.loadNodeDetailInfo(this.state.nodeId);
    }
  };

  getGeneralInfoPanel = () => {
    const node = this.nodeDetailInfo;
    if (!node) return null;

    switch (node[TYPE_FIELD_NAME]) {
      case NODE_TYPE_COMPANY:
        return (
          <NodeInfoCompanyGeneralPanel
            node={node}
            editable={!this.props.readonly}
            permanent={node.status === 1}
            updatingStatus={this.state.updatingStatus}
            userInfo={this.props.userInfo}
            networkRef={this.props.networkRef}
            showIcon={false}
            showAssistant={this.props.showAssistant}
            originalViewId={this.props.originalViewId}
          />
        );
      case NODE_TYPE_PAPER:
        return (
          <NodeInfoPaperGeneralPanel
            node={node}
            editable={!this.props.readonly}
            permanent={node.status === 1}
            networkRef={this.props.networkRef}
            showIcon={false}
            showAssistant={this.props.showAssistant}
            originalViewId={this.props.originalViewId}
          />
        );
      case NODE_TYPE_PATENT:
        return (
          <NodeInfoPatentGeneralPanel
            node={node}
            editable={!this.props.readonly}
            permanent={node.status === 1}
            networkRef={this.props.networkRef}
            showIcon={false}
            showAssistant={this.props.showAssistant}
            originalViewId={this.props.originalViewId}
          />
        );
      case NODE_TYPE_TALENT:
        return (
          <NodeInfoTalentGeneralPanel
            node={node}
            editable={!this.props.readonly}
            permanent={node.status === 1}
            updatingStatus={this.state.updatingStatus}
            userInfo={this.props.userInfo}
            networkRef={this.props.networkRef}
            showIcon={false}
            showAssistant={this.props.showAssistant}
            originalViewId={this.props.originalViewId}
          />
        );
      case NODE_TYPE_TEXT:
        return (
          <NodeInfoTextGeneralPanel
            node={node}
            editable={!this.props.readonly}
            permanent={node.status === 1}
            updatingStatus={this.state.updatingStatus}
            userInfo={this.props.userInfo}
            networkRef={this.props.networkRef}
            showIcon={false}
            showAssistant={this.props.showAssistant}
            originalViewId={this.props.originalViewId}
          />
        );
      case NODE_TYPE_TAG:
        return (
          <NodeInfoTagGeneralPanel
            node={node}
            editable={!this.props.readonly}
            permanent={node.status === 1}
            updatingStatus={this.state.updatingStatus}
            userInfo={this.props.userInfo}
            networkRef={this.props.networkRef}
            showIcon={false}
            showAssistant={this.props.showAssistant}
            originalViewId={this.props.originalViewId}
          />
        );
      default:
        return (
          <NodeInfoNodeGeneralPanel
            node={node}
            editable={!this.props.readonly}
            permanent={node.status === 1}
            updatingStatus={this.state.updatingStatus}
            userInfo={this.props.userInfo}
            networkRef={this.props.networkRef}
            showIcon={false}
            showAssistant={this.props.showAssistant}
            originalViewId={this.props.originalViewId}
          />
        );
    }
  };

  render() {
    let me = this;
    // noinspection FallThroughInSwitchStatementJS
    switch (me.state.loadingStatus) {
      case NodeDetailInfoLoadingStatus.IDLE:
        return (
          <div className={style['node-info-none-selected']}>
            <div>没有选中产业对象</div>
          </div>
        );
      case NodeDetailInfoLoadingStatus.PROCESSING:
        return (
          <div className={style['node-info-loading']}>
            <div>
              <Icon name="loading" theme="outlined"/><br/>
              数据加载中，请稍候...
            </div>
          </div>
        );
      case NodeDetailInfoLoadingStatus.SUCCESS:
        if (me.nodeDetailInfo) {

          let tmpState = {};

          if (Object.keys(tmpState) && Object.keys(tmpState).length > 0) {
            requestAnimationFrame(() => me.setState(tmpState));
          }

          return (
            <div className={`${style['board-panels']} dark-theme`} style={{borderRadius: 0}}>
              <div className={style['general-info-panel']}>
                {me.getGeneralInfoPanel()}
              </div>
            </div>
          );
        } else {
          // ATTENTION: 状态为成功但没有节点详情，直接向下走到获取数据失败流程
        }
      // eslint-disable-next-line
      default:
        return (
          <div className={style['node-info-failed-to-load']}>
            <div>
              <Icon name="exclamation-circle" theme="outlined"/><br/>
              数据加载失败，错误码：{me.state.errorCode}，<a onClick={me.onTryAgain}>&lt;点击重试&gt;</a>
            </div>
          </div>
        );
    }
  }
}

NodeInfoPanel.defaultProps = {
  getNodeInfo: () => undefined,
  getNodeConnectedInfo: () => undefined,
  readonly: false,
  ignoreLoadError: false,
  showAssistant: false,
};

NodeInfoPanel.propTypes = {
  nodeId: PropTypes.string.isRequired,
  getNodeInfo: PropTypes.func,
  getNodeConnectedInfo: PropTypes.func,
  userInfo: PropTypes.object,
  networkRef: PropTypes.instanceOf(ViewDataProvider),
  readonly: PropTypes.bool,
  ignoreLoadError: PropTypes.bool,
  showAssistant: PropTypes.bool,
  originalViewId: PropTypes.string,
};

export default NodeInfoPanel;
