import React from 'react';
import PropTypes from 'prop-types';

import PB, {SimplePB} from '@/libs/simplePB';

import ViewDataProvider from '@/components/common/dataProvider/common.dataProvider.view';
import {NodeEvents} from '@/libs/view/network/events';

class ExploreCompanyOverallLogic extends React.PureComponent {
  currentPos = 0;

  hasMoreResult = true;

  loadingResult = false;

  lastLoadingFailed = false;

  autoLoadMore = false;

  resultData = {nodes: [], edges: []};

  resultNodeMap = {};

  idReplacementMap = {};

  idReplacementRevertMap = {};

  onLoadMoreData = () => {
    let me = this;

    if (me.loadingResult) {
      return;
    }

    if (me.props.viewDataProvider) {
      let viewId = me.props.viewDataProvider.viewId, start = me.currentPos, limit = 10;
      me.props.bus.emit('view', 'explore.explore_company.overall.do_load_more',
        {start, limit, viewId: me.props.viewDataProvider.viewId, autoLoadMore: me.autoLoadMore});
      me.loadingResult = true;
      me.props.viewDataProvider.recommendCompanyByView(me.currentPos, limit).then(({nodes, edges, total}) => {
        me.resultData.nodes.push.apply(me.resultData.nodes, nodes);
        me.resultData.edges.push.apply(me.resultData.edges, edges);
        me.currentPos += limit;
        me.hasMoreResult = me.currentPos < total;
        me.loadingResult = false;
        me.lastLoadingFailed = false;
        me.props.bus.emit('view', 'explore.explore_company.overall.data_loaded', {nodes, edges, start, limit, total, viewId});
      }).catch(({code, msg}) => {
        me.loadingResult = false;
        me.lastLoadingFailed = true;
        me.props.bus.emit('view', 'explore.explore_company.overall.failed_to_load', {code, msg, viewId});
      });
    }
  };

  clearResult = () => {
    let me = this;

    if (me.loadingResult) {
      return;
    }

    if (me.props.viewDataProvider) {
      let viewId = me.props.viewDataProvider.viewId;

      me.currentPos = 0;
      me.hasMoreResult = true;
      me.loadingResult = false;
      me.lastLoadingFailed = false;
      me.resultData = {nodes: [], edges: []};
      me.idReplacementMap = {};
      me.idReplacementRevertMap = {};

      me.props.bus.emit('view', 'explore.explore_company.overall.result_cleared', {viewId});
    }
  };

  componentDidMount() {
    let me = this;

    me.props.bus.with(me).subscribe('view', 'explore.explore_company.overall.on_load_more', ({viewId, autoLoadMore = true}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId) {
        me.autoLoadMore = autoLoadMore;
        me.onLoadMoreData();
      }
    }).subscribe('view', 'explore.explore_company.overall.clear_result', ({viewId}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId) {
        me.clearResult();
      }
    }).subscribe('view', 'explore.explore_company.overall.stop_auto_load', ({viewId}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId && me.autoLoadMore) {
        me.autoLoadMore = false;
        me.props.bus.emit('view', 'explore.explore_company.overall.broadcast_status', {viewId});
      }
    }).subscribe('view', 'explore.explore_company.overall.data_loaded', ({viewId}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId && me.autoLoadMore && me.hasMoreResult) {
        requestAnimationFrame(() => me.onLoadMoreData());
      }
    }).subscribe('view', 'explore.explore_company.overall.broadcast_status', ({viewId}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId) {
        me.props.bus.emit('view', 'explore.explore_company.overall.current_status', {
          viewId,
          currentPos: me.currentPos,
          hasMoreResult: me.hasMoreResult,
          loadingResult:me.loadingResult,
          lastLoadingFailed: me.lastLoadingFailed,
          autoLoadMore: me.autoLoadMore,
        });
      }
    }).subscribe('view', 'explore.explore_company.overall.broadcast_data', ({viewId}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId) {
        me.props.bus.emit('view', 'explore.explore_company.overall.current_data', {
          viewId,
          resultData: me.resultData,
          resultNodeMap: {...me.resultNodeMap},
          idReplacementMap: {...me.idReplacementMap},
          idReplacementRevertMap: {...me.idReplacementRevertMap},
        });
      }
    });

    me.props.viewDataProvider.with(me).subscribe(NodeEvents.ID_REPLACED, (nodeIdReplacement) => {
      let originalNodeIds = Object.keys(nodeIdReplacement);
      originalNodeIds.forEach(originalNodeId => {
        if (me.resultNodeMap[originalNodeId]) {
          me.idReplacementMap[originalNodeId] = nodeIdReplacement[originalNodeId];
          me.idReplacementRevertMap[nodeIdReplacement[originalNodeId]] = originalNodeId;
        }
      });
    });
  }

  componentWillUnmount() {
    this.props.bus.remove(this);
    this.props.viewDataProvider.unSubscribe(this);
  }

  render() {
    return (<React.Fragment />);
  }
}

ExploreCompanyOverallLogic.defaultProps = {
  bus: PB,
};

ExploreCompanyOverallLogic.propTypes = {
  viewDataProvider: PropTypes.instanceOf(ViewDataProvider).isRequired,
  bus: PropTypes.instanceOf(SimplePB),
};

export default ExploreCompanyOverallLogic;