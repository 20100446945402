/*
 * @Author: Zheng
 * @Date: 2022-11-29 14:16:56
 * @LastEditTime: 2022-11-29 15:54:36
 * @LastEditors: Zheng
 * @Description: 节点扩展路由
 * @Copyright by joinmap
 */
import React from "react";
import ViewNodeExtendedChartTpl from "@/components/nodeExtendedView/template/nodeExtended.chart.tpl";
import styles from "@/style/components/nodeExtendedView/nodeExtended.less";
import echarts from "echarts/lib/echarts";
import { notification } from "antd";

// https://snap.joinmap.ai/wth_server_api/social_manage_juzheng_by_view_id?view_id=4b609468-4f50-4ed0-bbb8-3e15b06b54d0

class ViewNodeExtendedEchart extends ViewNodeExtendedChartTpl {
  // 全局配置
  viewerTitle = "社会治理问题导向图";
  _viewerTitleRender = () => {
    return "";
  };
  // column1
  column1_Title = "分类词"; // 显示名称
  column1_Unit = "个"; // 显示的文字单位
  column1_subTitle = () => {
    // 列表顶部副标题文字
    const { _column1Len, _column1Total } = this.state;
    return <>已计算出 {_column1Len} 个</>;
  };
  column1_ValueRender = (column1_data, idx, nodePath = undefined) => {
    // 右侧数值渲染，不输出则空白
    // 必须加上 className={styles["line-value"]}
    const { column2_DataField, column2_Unit } = this;
    let value = column1_data.hasOwnProperty(column2_DataField)
      ? column1_data[column2_DataField].length
      : "";
    return (
      <div className={styles["line-value"]}>
        {value}
        {column2_Unit}
      </div>
    );
  };

  // column2
  column2_showType = "list"; // 第二列的展现形式，list=显示列，tree=显示树, none=不显示
  column2_DataField = "news"; // 取值字段
  column2_TotalField = "value"; // 计算总数(与返回数量不一定一致)字段
  column2_ValueRender = (column1_data, idx, nodePath = undefined) => {
    return "";
  };

  // api
  api_path = "/wth_server_api/social_manage_juzheng_by_view_id";
  defaultLimit = 60;
  apiLoadedCallback = () => {
    if (this.state._column1Len > 0 && this.chartType === "echarts") {
      notification.open({
        duration: 8,
        message: "操作提示",
        description: (
          <div>
            <div>数据加载成功</div>
            <div>
              图表支持鼠标<b>缩放</b>、<b>平移</b>操作。
            </div>
          </div>
        ),
      });
    }
  };

  // chart
  chartDivLeft = "auto";
  chartStyle = {
    top: 0,
    bottom: 0,
  };

  chartCompomentStyle = {
    width: "100%",
    height: "100%",
  };

  chartType = "echarts";

  initChartOption = () => {
    const {
      query: { isDebug },
    } = this.props;
    let option = {
      colors: this._chartColors,
      animation: true,
      animationDuration: 1000,
      animationEasing: "cubicInOut",
      animationDurationUpdate: 800,
      animationEasingUpdate: "cubicInOut",
      grid: {
        top: 30,
      },
      tooltip: {
        formatter: function (info) {
          if (isDebug)
            console.log("initChartOption tooltip formatter info:", info);
          let value = info.data.hasOwnProperty("value")
            ? info.data["value"]
            : "";
          return [
            `<div class="tooltip-title">${echarts.format.encodeHTML(
              info.name
            )}</div>`,
            "相关新闻：" + value + "篇<br>",
          ].join("");
        },
      },
      series: [
        {
          name: "分类词",
          top: "middle",
          type: "treemap",
          data: [],
        },
      ],
    };
    return option;
  };
  refreshChartOptions = (nodePath = null) => {
    const {
      query: { isDebug },
    } = this.props;
    if (isDebug) console.log("refreshChartOptions 0 nodePath:", nodePath);
    // let me = this;
    this._chartData.forEach((element) => {});

    this._chartOption = this.initChartOption();
    this._chartOption.series[0].data = this._chartData;
  };

  eChartRefCallback = (e) => {
    const {
      query: { isDebug },
    } = this.props;
    let me = this;
    // me._chartIns = e.getEchartsInstance();
    if (isDebug) console.log("ViewNodeExtendedEchart ReactECharts e: ", e);
    if (!me.chartIns) {
      me.chartIns = e.getEchartsInstance();
      //4.树绑定事件
      me.chartIns.on("click", function (params) {
        // console.log('ViewCompanyTagsInsight ReactECharts click params: ', params);
        if (
          params.hasOwnProperty("data") &&
          params.data.hasOwnProperty("name")
        ) {
          let name = params.data.name; //点击的节点的name
          // let value = params.data.value;//点击的节点的value
          //调用点击事件
          me._column1_onClick(params.dataIndex - 1, name);
        }
      });
    }
  };
}
export default ViewNodeExtendedEchart;
