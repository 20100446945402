export const IconTypes = Object.freeze({
  ANT_DESIGN: 0,
  ICON_FONT: 1,
  FONT_AWESOME: 2,
});

export const AvatarColors = [
  '#2ecc71', // emerald
  '#3498db', // peter river
  //'#8e44ad', // wisteria
  '#e67e22', // carrot
  '#e74c3c', // alizarin
  '#1abc9c', // turquoise
];

export const MULTIMEDIA_EXT = [
  '.aac',
  '.snd',
  '.au',
  '.flac',
  '.m4a',
  '.mp2',
  '.mp3',
  '.oga',
  '.ogg',
  '.opus',
  '.spx',
  '.aifc',
  '.aif',
  '.aiff',
  '.wav',
  '.m4v',
  '.mp4',
  '.mpg',
  '.mpe',
  '.mpeg',
  '.ogv',
  '.mov',
  '.qt',
  '.webm',
  '.avi',
  '.movie',
  '.mv',
];


