import React from 'react';
import PropTypes from "prop-types";
import {Card, Modal, Empty, Tooltip, Upload, message} from 'antd';

import Icon from '@/components/common/common.icon';

import style from '@/style/components/toolbar/main.toolbar.nav.img.less';
import {getToken, REQUEST_BASE} from "@/utils/HttpUtil";
import ViewDataProvider from '@/components/common/dataProvider/common.dataProvider.view';
import {IconTypes} from "@/constants/common";

class MainToolbarNavImg extends React.Component {
  state = {
    uploading: false,
  };

  onClearClicked = (e) => {
    e.preventDefault();

    let me = this;
    const {images, imageId} = me.props;
    const originImg = imageId ? images[imageId] : undefined;

    if (originImg) {
      Modal.confirm({
        title: '清除设置',
        content: '是否确认清除该位置的图表设置？',
        okText: '确认清除设置',
        cancelText: '取消',
        onOk: () => {
          const originAttachmentInfo = originImg.attachmentRef;
          const originAttachmentMeta = originAttachmentInfo.meta;
          originAttachmentMeta[originImg.idxInList] = {
            ...originAttachmentMeta[originImg.idxInList],
            i: -1,
          };
          // noinspection JSIgnoredPromiseFromCall
          me.props.networkRef.updateFilesRemark(originAttachmentInfo.id, originAttachmentInfo.remark,
            originAttachmentMeta, originAttachmentInfo['from']);
        },
      });
    }
  };

  beforeUpload = file => {
    let me = this;

    if (file.size > 50 * 1024 * 1024) {
      message.error("上传图片不要超过50MB");
      return false;
    }
    me.setState({uploading: true}, () => {
      me.props.networkRef.addFiles(undefined, undefined, [file], [{
        i: me.props.idx,
      }]).then(() => {
        me.setState({uploading: false});
        message.success('图片上传成功');
      }).catch(error => {
        me.setState({uploading: false});
        message.success('图片上传失败，请稍后重试');
        console.error(error);
      });
    });
    return false;
  };

  render() {
    let me = this;
    const className = (me.props.className ? `${me.props.className} ${style['frame']}` : style['frame']);
    const {imageId, images} = me.props;
    /* TODO: 最大宽度写死200，后期需根据屏幕适配 */
    const imgSrc = `${REQUEST_BASE}/view/project/file/thumb/200/0/${imageId}?Authorization=${getToken()}`;

    // noinspection RequiredAttributes
    return (
      <div className={className} style={me.props.style || {}}>
        {
          me.state.uploading ? (
            <div className={style['set-img-btn']}>
              <Icon name={'loading'} />
              <div>请稍后</div>
            </div>
          ) : (
            imageId ? (
              <div className={style['img-container']} style={{backgroundImage: `url(${imgSrc})`}}>
                <div className={style['title']}>{images[imageId].comment || images[imageId].dftComment}</div>
                <div className={style['actions']}>
                  <div className={style['action-show']} onClick={() => me.props.onFullScreen(me.props.idx)}>
                    <Icon name={'fullscreen'} />
                  </div>
                  <div className={style['action-edit']}>
                    <Upload
                      multiple={false}
                      showUploadList={false}
                      beforeUpload={me.beforeUpload}
                      className={style['action-edit-replace-upload']}
                    >
                      <div className={style['action-edit-replace']}>
                        <Tooltip title={'替换'} placement={'right'}>
                          <Icon name={'icon-replace'} type={IconTypes.ICON_FONT} />
                        </Tooltip>
                      </div>
                    </Upload>
                    <div className={style['action-edit-clear']} onClick={me.onClearClicked}>
                      <Tooltip title={'清除'} placement={'right'}>
                        <Icon name={'close-circle'} />
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <Upload
                multiple={false}
                showUploadList={false}
                beforeUpload={me.beforeUpload}
                className={style['set-img-upload']}
              >
                <div className={style['set-img-btn']}>
                  <Icon name={'plus'} />
                  <div>上传插图</div>
                </div>
              </Upload>
            )
          )
        }
      </div>
    );
  }
}

MainToolbarNavImg.defaultProps = {
  readonly: true,
};

MainToolbarNavImg.propTypes = {
  readonly: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
  images: PropTypes.object.isRequired,
  networkRef: PropTypes.instanceOf(ViewDataProvider).isRequired,
  onFullScreen: PropTypes.func,
  idx: PropTypes.number,
  imageId: PropTypes.string,
};

export default MainToolbarNavImg;