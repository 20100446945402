import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {Modal, Button, Checkbox, message} from 'antd';
import {Element as ScrollElement} from 'react-scroll';

import PB, {SimplePB} from '@/libs/simplePB';

import ViewDataProvider from '@/components/common/dataProvider/common.dataProvider.view';
import {showErrorMessage} from "@/components/common/common.message";
import {scrollToEnd} from '@/components/common/common.functions';
import {NODE_TYPE_TEXT} from "@/constants/nodeConfig";
import {NodeEvents} from "@/libs/view/network/events";
import ExploreCommonRecommendDetailResultList
  from "@/components/common/view/explore/common.view.explore.common.recommendDetailResultList";
import style from "@/style/common/view/common.view.explore.less";
import Icon from "@/components/common/common.icon";

class ExploreCommonRecommendDetailModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      containerId: `explore-${props.exploreKey}-recommend-detail-modal-${Math.random()}`,

      loadingResult: false,
      hasMoreResult: true,
      lastLoadingFailed: false,
      currentPos: -1,
      autoLoadMore: true,

      originalNodeMap: undefined,
      mergedNodeList: [],
      resultMap: {},
      availableNodeAmount: 0,

      savingStatus: 'idle',
      checkedNodeMap: {},
    };
  }


  autoStart = true;

  autoScroll = true; // 自动滚动至最下方

  onClose = () => {
    let me = this;

    if (me.state.autoLoadMore && me.state.hasMoreResult && !me.state.lastLoadingFailed) {
      message.info(`${me.props.exploreName}信息填充推荐持续进行中，您可随时通过右侧按钮查看发现进度`);
    }

    me.props.onClose();
  };

  onResultDataLoaded = ({resultData, currentPos, hasMoreResult}) => {
    let me = this, {mergedNodeList, resultMap, availableNodeAmount} = me.parseResult(resultData);

    me.setState({
      mergedNodeList: _.concat(me.state.mergedNodeList, mergedNodeList),
      resultMap: {...me.state.resultMap, ...resultMap},
      availableNodeAmount: me.state.availableNodeAmount + availableNodeAmount,
      currentPos,
      hasMoreResult,
      loadingResult: false,
    }, () => {
      setTimeout(() => scrollToEnd(me), 300);
    });
  };

  onResultDataFailedToLoad = ({code, msg}) => {
    let me = this;

    if (!me.props.viewDataProvider) return;

    me.setState({
      loadingResult: false,
      lastLoadingFailed: true,
    }, () => {
      showErrorMessage({code, msg, extra: {viewId: me.props.viewDataProvider.viewId, isModification: false}});
    });
  };

  onResultDataStartToLoad = ({currentPos, autoLoadMore}) => {
    let me = this;

    me.setState({
      loadingResult: true,
      lastLoadingFailed: false,
      currentPos,
      autoLoadMore,
    });
  };

  onResultDataRefreshed = ({originalNodeList, resultData}) => {
    let me = this, originalNodeMap = {};

    me.setState({
      originalNodeMap: undefined,
      mergedNodeList: [],
      resultMap: {},
      availableNodeAmount: 0,
    }, () => {
      originalNodeList.forEach(node => originalNodeMap[node.id] = node);
      me.setState({originalNodeMap}, () => {
        let {mergedNodeList, resultMap, availableNodeAmount} = me.parseResult(resultData);
        me.setState({
          originalNodeMap,
          mergedNodeList,
          resultMap,
          availableNodeAmount,
        }, () => {
          setTimeout(() => scrollToEnd(me), 300);
        });
      });
    });
  };

  onItemCheckChange = (id, checked = undefined) => {
    let me = this, checkedNodeMap = me.state.checkedNodeMap, originalChecked = !!checkedNodeMap[id];

    if (checked === undefined) {
      checked = !originalChecked;
    }

    if (checked !== originalChecked) {
      checked ? (checkedNodeMap[id] = checked) : (delete checkedNodeMap[id]);
      me.setState({checkedNodeMap});
    }
  };

  onRetry = () => {
    let me = this;

    if (me.props.viewDataProvider) {
      me.props.bus.emit('view', `explore.${me.props.exploreEventCategory}.recommend_detail.on_load_more`,
        {viewId: me.props.viewDataProvider.viewId, autoLoadMore: me.state.autoLoadMore});
    }
  };

  onRestart = () => {
    let me = this, callback = () => {
      me.autoStart = true;

      if (me.props.viewDataProvider) {
        me.props.bus.emit('view', `explore.${me.props.exploreEventCategory}.recommend_detail.clear_result`,
          {viewId: me.props.viewDataProvider.viewId, autoLoadMore: me.state.autoLoadMore});
      }
    }
    if (!me.state.autoLoadMore) {
      me.setState({autoLoadMore: true}, callback);
    } else {
      callback();
    }
  };

  onResultCleared = () => {
    let me = this;

    if (!me.props.viewDataProvider) return;

    me.setState({
      currentPos: -1,

      originalNodeMap: undefined,
      mergedNodeList: [],
      resultMap: {},
      availableNodeAmount: 0,

      savingStatus: 'idle',
      checkedNodeMap: {},
    }, () => {
      me.autoScroll = true;

      me.props.bus.emit('view', `explore.${me.props.exploreEventCategory}.recommend_detail.initialize_node_list`,
        {viewId: me.props.viewDataProvider.viewId, autoLoadMore: me.autoStart});
    });
  };

  onSaveNodeList = (override = 'all', checkedNodeMap = undefined) => {
    let me = this, nodeListToSave = [];

    if (!me.props.viewDataProvider) return;

    if (!checkedNodeMap) {
      checkedNodeMap = me.state.checkedNodeMap;
    }

    switch (override) {
      case 'auto-description':
        Object.keys(checkedNodeMap).forEach(nodeId => {
          let originalNode = me.state.originalNodeMap[nodeId];
          if (!originalNode) return;

          let resultData = me.state.resultMap[nodeId],
            hasDescription = (!originalNode.description) && resultData && resultData.description;

          if (resultData) {
            nodeListToSave.push({
              ...originalNode,
              description: hasDescription ? resultData.description : originalNode.description,
              meta: originalNode.meta && originalNode.meta.description ?
                {...originalNode.meta, description: resultData.description} : originalNode.meta,
            });
          }
        });
        break;
      case 'description':
        Object.keys(checkedNodeMap).forEach(nodeId => {
          let originalNode = me.state.originalNodeMap[nodeId];
          if (!originalNode) return;

          let resultData = me.state.resultMap[nodeId], hasDescription = resultData && resultData.description;

          if (resultData) {
            nodeListToSave.push({
              ...originalNode,
              description: hasDescription ? resultData.description : originalNode.description,
              meta: originalNode.meta && originalNode.meta.description ?
                {...originalNode.meta, description: resultData.description} : originalNode.meta,
            });
          }
        });
        break;
      case 'icon':
        Object.keys(checkedNodeMap).forEach(nodeId => {
          let originalNode = me.state.originalNodeMap[nodeId];
          if (!originalNode) return;

          let resultData = me.state.resultMap[nodeId],
            hasIcon = resultData && resultData.meta && resultData.meta.iconMeta && resultData.meta.iconData;

          if (resultData) {
            nodeListToSave.push({
              ...originalNode,
              meta: hasIcon ? {...(originalNode.meta || {}), ...resultData.meta, userPreferredType: NODE_TYPE_TEXT} : originalNode.meta,
              userPreferredType: hasIcon ? NODE_TYPE_TEXT : originalNode.userPreferredType,
              lev: hasIcon ? undefined : originalNode.lev,
            });
          }
        });
        break;
      case 'all':
        Object.keys(checkedNodeMap).forEach(nodeId => {
          let originalNode = me.state.originalNodeMap[nodeId];
          if (!originalNode) return;

          let resultData = me.state.resultMap[nodeId],
            hasIcon = resultData && resultData.meta && resultData.meta.iconMeta && resultData.meta.iconData,
            hasDescription = resultData && resultData.description;

          if (resultData) {
            let meta = hasIcon ? {...(originalNode.meta || {}), ...resultData.meta, userPreferredType: NODE_TYPE_TEXT} : originalNode.meta;
            nodeListToSave.push({
              ...originalNode,
              description: hasDescription ? resultData.description : originalNode.description,
              meta: hasDescription && meta && meta.description !== undefined ? {...meta, description: resultData.description} : meta,
              userPreferredType: hasIcon ? NODE_TYPE_TEXT : originalNode.userPreferredType,
              lev: hasIcon ? undefined : originalNode.lev,
            });
          }
        });
        break;
      default:
        Object.keys(checkedNodeMap).forEach(nodeId => {
          let originalNode = me.state.originalNodeMap[nodeId];
          if (!originalNode) return;

          let resultData = me.state.resultMap[nodeId],
            hasIcon = resultData && resultData.meta && resultData.meta.iconMeta && resultData.meta.iconData,
            hasDescription = (!originalNode.description) && resultData && resultData.description;

          if (resultData) {
            let meta = hasIcon ? {...(originalNode.meta || {}), ...resultData.meta, userPreferredType: NODE_TYPE_TEXT} : originalNode.meta;
            nodeListToSave.push({
              ...originalNode,
              userPreferredType: hasIcon ? NODE_TYPE_TEXT : originalNode.userPreferredType,
              description: hasDescription ? resultData.description : originalNode.description,
              meta: hasDescription && meta && meta.description !== undefined ? {...meta, description: resultData.description} : meta,
              lev: hasIcon ? undefined : originalNode.lev,
            });
          }
        });
    }

    me.setState({savingStatus: 'processing'}, () => {
      me.props.viewDataProvider.saveRelationGraph(nodeListToSave, [], false, false, undefined, false).then(() => {
        me.setState({savingStatus: 'idle'}, () => {
          message.success(`成功保存 ${nodeListToSave.length} 个节点`)
        })
      }).catch(({code, msg}) => {
        showErrorMessage({code, msg});
        me.setState({savingStatus: 'idle'});
      });
    });
  };

  onCheckAll = () => {
    let me = this, checkedNodeMap = {};

    if (Object.keys(me.state.checkedNodeMap).length < me.state.availableNodeAmount) {
      // 全选
      me.state.mergedNodeList.forEach(node => {
        if (node.hasIconToOverride || node.hasDescriptionToOverride) {
          checkedNodeMap[node.id] = true;
        }
      });
    }
    me.setState({checkedNodeMap});
  };

  onStatusRefreshed = ({nodeListInitialized, currentPos, loadingResult, hasMoreResult, lastLoadingFailed, autoLoadMore}) => {
    let me = this;

    if (me.props.viewDataProvider && me.props.visible) {
      if (!nodeListInitialized) {
        me.props.bus.emit('view', `explore.${me.props.exploreEventCategory}.recommend_detail.initialize_node_list`,
          {viewId: me.props.viewDataProvider.viewId, autoLoadMore});
        return;
      }
      let requestResultData = (me.state.currentPos !== currentPos) || (me.state.loadingResult !== loadingResult);
      me.setState({
        loadingResult,
        hasMoreResult,
        lastLoadingFailed,
        currentPos,
        autoLoadMore,
      }, () => {
        if (requestResultData) {
          me.props.bus.emit('view', `explore.${me.props.exploreEventCategory}.recommend_detail.broadcast_data`,
            {viewId: me.props.viewDataProvider.viewId});
        }
        if (!autoLoadMore && me.props.visible && me.autoStart) {
          me.autoStart = false;
          me.props.bus.emit('view', `explore.${me.props.exploreEventCategory}.recommend_detail.on_load_more`,
            {viewId: me.props.viewDataProvider.viewId});
        }
      });
    }
  };

  onNodeListInitialized = () => {
    let me = this;

    me.props.bus.emit('view', `explore.${me.props.exploreEventCategory}.recommend_detail.broadcast_status`,
      {viewId: me.props.viewDataProvider.viewId});
  };

  onStop = () => {
    let me = this, callback = () => {
      if (me.props.viewDataProvider) {
        me.props.bus.emit('view', `explore.${me.props.exploreEventCategory}.recommend_detail.stop_auto_load`,
          {viewId: me.props.viewDataProvider.viewId});
      }
    }
    if (me.state.autoLoadMore) {
      me.setState({autoLoadMore: false}, callback);
    } else {
      callback();
    }
  };

  parseResult = (resultData) => {
    let me = this, mergedNodeList = [], resultMap = {}, availableNodeAmount = 0;

    resultData.forEach(node => {
      let originalNode = me.state.originalNodeMap[node.id];
      if (!originalNode) return;

      resultMap[node.id] = node;

      let resultHasIcon = node.meta && node.meta.iconMeta && node.meta.iconData,
        originalHasIcon = originalNode.meta && originalNode.meta.iconMeta && originalNode.meta.iconData,
        hasIconToOverride = (!originalHasIcon && resultHasIcon),
        hasDescriptionToOverride = (node.description && originalNode.description !== node.description);

      if (hasIconToOverride || hasDescriptionToOverride) {
        availableNodeAmount++;
      }

      // 合并节点数据
      mergedNodeList.push({
        ...originalNode,
        originalDescription: originalNode.description,
        ...node,
        userPreferredType: (hasIconToOverride ? NODE_TYPE_TEXT : originalNode.userPreferredType),
        meta: hasIconToOverride ? {
          ...(originalNode.meta || {}),
          ...(node.meta || {}),
        } : originalNode.meta,
        hasIconToOverride,
        hasDescriptionToOverride,
      });
    });

    return {mergedNodeList, resultMap, availableNodeAmount};
  };

  componentDidMount() {
    let me = this;

    me.props.bus.with(
      me
    ).subscribe('view', `explore.${me.props.exploreEventCategory}.recommend_detail.node_list_initialized`, payload => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === payload.viewId) {
        me.onNodeListInitialized();
      }
    }).subscribe('view', `explore.${me.props.exploreEventCategory}.recommend_detail.current_status`, payload => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === payload.viewId) {
        me.onStatusRefreshed(payload);
      }
    }).subscribe('view', `explore.${me.props.exploreEventCategory}.recommend_detail.current_data`, payload => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === payload.viewId) {
        me.onResultDataRefreshed(payload);
      }
    }).subscribe('view', `explore.${me.props.exploreEventCategory}.recommend_detail.stop_auto_load`, payload => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === payload.viewId && me.state.autoLoadMore) {
        me.setState({autoLoadMore: false});
      }
    }).subscribe('view', `explore.${me.props.exploreEventCategory}.recommend_detail.do_load_more`, payload => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === payload.viewId) {
        me.onResultDataStartToLoad(payload);
      }
    }).subscribe('view', `explore.${me.props.exploreEventCategory}.recommend_detail.data_loaded`, payload => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === payload.viewId) {
        me.onResultDataLoaded(payload);
      }
    }).subscribe('view', `explore.${me.props.exploreEventCategory}.recommend_detail.failed_to_load`, payload => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === payload.viewId) {
        me.onResultDataFailedToLoad(payload);
      }
    }).subscribe('view', `explore.${me.props.exploreEventCategory}.recommend_detail.result_cleared`, payload => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === payload.viewId) {
        me.onResultCleared();
      }
    }).subscribe('view', `explore.${me.props.exploreEventCategory}.recommend_detail.node_list_initialized`, payload => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === payload.viewId) {
        let originalNodeMap = {};
        payload.originalNodeList.forEach(node => originalNodeMap[node.id] = node);
        me.setState({originalNodeMap});
      }
    });

    me.props.viewDataProvider.with(me).subscribe(NodeEvents.UPDATED, (updatedNodeIds, updatedNodes) => {
      // originalNodeMap, mergedNodeList, resultMap, availableNodeAmount, checkedNodeMap
      let dataChanged = false,
        {originalNodeMap, mergedNodeList, resultMap, availableNodeAmount, checkedNodeMap} = me.state;

      if (!originalNodeMap) return;

      updatedNodes.forEach(node => {
        let originalNodeId = node.id;

        if (me.state.originalNodeMap[originalNodeId]) {
          originalNodeMap[originalNodeId] = node;

          if (resultMap[originalNodeId]) {
            let resultNode = resultMap[originalNodeId],
              oldMergedNodeIndex = mergedNodeList.findIndex(node => node.id === originalNodeId),
              oldMergedNode = mergedNodeList[oldMergedNodeIndex],
              oldHasDataToOverride = oldMergedNode.hasIconToOverride || oldMergedNode.hasDescriptionToOverride,
              resultHasIcon = resultNode.meta && resultNode.meta.iconMeta && resultNode.meta.iconData,
              originalHasIcon = node.meta && node.meta.iconMeta && node.meta.iconData,
              hasIconToOverride = (!originalHasIcon && resultHasIcon),
              hasDescriptionToOverride = (resultNode.description && node.description !== resultNode.description),
              hasDataToOverride = hasIconToOverride || hasDescriptionToOverride;

            if (oldHasDataToOverride !== hasDataToOverride) {
              if (oldHasDataToOverride) {
                availableNodeAmount--;
              } else {
                availableNodeAmount++;
              }
            }

            mergedNodeList[oldMergedNodeIndex] = {
              ...node,
              originalDescription: node.description,
              ...resultNode,
              userPreferredType: (hasIconToOverride ? NODE_TYPE_TEXT : node.userPreferredType),
              meta: hasIconToOverride ? {
                ...(node.meta || {}),
                ...(resultNode.meta || {}),
              } : node.meta,
              hasIconToOverride,
              hasDescriptionToOverride,
            };

            if (checkedNodeMap[originalNodeId] && !hasDataToOverride) {
              delete checkedNodeMap[originalNodeId];
            }
          }

          dataChanged = true;
        }
      });

      if (dataChanged) {
        me.setState({originalNodeMap, mergedNodeList, availableNodeAmount, checkedNodeMap});
      }
    }).subscribe(NodeEvents.REMOVED, (type, nodeInfoList) => {
      // originalNodeMap, mergedNodeList, resultMap, availableNodeAmount, checkedNodeMap
      let dataChanged = false,
        {originalNodeMap, mergedNodeList, resultMap, availableNodeAmount, checkedNodeMap} = me.state;

      if (!originalNodeMap) return;

      nodeInfoList.forEach(node => {
        let originalNodeId = node.id;

        if (me.state.originalNodeMap[originalNodeId]) {
          delete originalNodeMap[originalNodeId];

          if (resultMap[originalNodeId]) {
            let mergedNode = mergedNodeList.find(node => node.id === originalNodeId);

            if (mergedNode.hasIconToOverride || mergedNode.hasDescriptionToOverride) {
              availableNodeAmount--;
            }

            delete resultMap[originalNodeId];
            mergedNodeList = mergedNodeList.filter(node => node.id !== originalNodeId);
          }

          if (checkedNodeMap[originalNodeId]) {
            delete checkedNodeMap[originalNodeId];
          }

          dataChanged = true;
        }
      });

      if (dataChanged) {
        me.setState({originalNodeMap, mergedNodeList, resultMap, availableNodeAmount, checkedNodeMap});
      }
    }).subscribe(NodeEvents.ID_REPLACED, (nodeIdReplacement) => {
      // originalNodeMap, mergedNodeList, resultMap, availableNodeAmount, checkedNodeMap
      let dataChanged = false,
        {originalNodeMap, mergedNodeList, resultMap, checkedNodeMap} = me.state,
        originalNodeIds = Object.keys(nodeIdReplacement);

      if (!originalNodeMap) return;

      originalNodeIds.forEach(originalNodeId => {
        if (me.state.originalNodeMap[originalNodeId]) {
          originalNodeMap[nodeIdReplacement[originalNodeId]] = originalNodeMap[originalNodeId];
          originalNodeMap[nodeIdReplacement[originalNodeId]].id = nodeIdReplacement[originalNodeId];
          delete originalNodeMap[originalNodeId];

          if (resultMap[originalNodeId]) {
            mergedNodeList.find(node => {
              if (node.id === originalNodeId) {
                node.id = nodeIdReplacement[originalNodeId];
                return true;
              }
            });

            resultMap[nodeIdReplacement[originalNodeId]] = resultMap[originalNodeId];
            resultMap[nodeIdReplacement[originalNodeId]].id = nodeIdReplacement[originalNodeId];
            delete resultMap[originalNodeId];
          }

          if (checkedNodeMap[originalNodeId]) {
            checkedNodeMap[nodeIdReplacement[originalNodeId]] = true;
            delete checkedNodeMap[originalNodeId];
          }

          dataChanged = true;
        }
      });

      if (dataChanged) {
        me.setState({originalNodeMap, mergedNodeList, resultMap, checkedNodeMap});
      }
    });

    if (me.props.visible) {
      me.props.bus.emit('view', `explore.${me.props.exploreEventCategory}.recommend_detail.modal_visible_changed`,
        {viewId: me.props.viewDataProvider.viewId, visible: me.props.visible});
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let me = this;

    if ((!prevProps.visible || !prevProps.viewDataProvider) && me.props.visible && me.props.viewDataProvider) {
      me.props.bus.emit('view', `explore.${me.props.exploreEventCategory}.recommend_detail.broadcast_status`,
        {viewId: me.props.viewDataProvider.viewId});
    }

    if (prevProps.visible !== me.props.visible) {
      me.props.bus.emit('view', `explore.${me.props.exploreEventCategory}.recommend_detail.modal_visible_changed`,
        {viewId: me.props.viewDataProvider.viewId, visible: me.props.visible});
    }
  }

  componentWillUnmount() {
    this.props.bus.remove(this);
    this.props.viewDataProvider.unSubscribe(this);
  }

  render() {
    let me = this, checkedNodeLength = Object.keys(me.state.checkedNodeMap).length;

    return (
      <Modal
        title={`${me.props.exploreName}信息填充`}
        visible={me.props.visible}
        closable={me.state.savingStatus !== 'processing'}
        width={`calc(40rem + 48px)`}
        bodyStyle={{height: '50vh'}}
        centered={true}
        onCancel={me.onClose}
        footer={[
          (!me.state.loadingResult && me.state.lastLoadingFailed && me.state.autoLoadMore) ? (
            <Button key={'retry'} style={{float: 'left'}} onClick={me.onRetry}>重试</Button>
          ) : undefined,
          (!me.state.loadingResult && (!me.state.autoLoadMore || !me.state.hasMoreResult)) ? (
            <Button key={'restart'} style={{float: 'left'}} onClick={me.onRestart}>重新启动</Button>
          ) : undefined,
          me.state.loadingResult ? (
            <Button
              key={'stop'}
              style={{float: 'left'}}
              disabled={!me.state.autoLoadMore}
              onClick={me.onStop}
            >
              {me.state.autoLoadMore ? '停止' : '停止中...'}
            </Button>
          ) : undefined,
          (
            <Checkbox
              key={'select-all'}
              onClick={me.onCheckAll}
              disabled={me.state.availableNodeAmount <= 0}
              checked={checkedNodeLength > 0 && checkedNodeLength === me.state.availableNodeAmount}
            >
              全选
            </Checkbox>
          ),
          (<Button key={'close'} onClick={me.onClose}>关闭</Button>),
          (
            <Button
              key={'save'}
              onClick={() => me.onSaveNodeList()}
              style={{minWidth: 'calc(9rem + 32px)'}}
              disabled={checkedNodeLength === 0}
              loading={me.state.savingStatus === 'processing'}
              type={'primary'}
            >
              {`保存节点信息${checkedNodeLength > 0 ? (checkedNodeLength > 99 ? ' (99+)' : ` (${checkedNodeLength})`) : ''}`}
            </Button>
          ),
        ]}
      >
        <ScrollElement
          id={me.state.containerId}
          className={`scrollbar-none`}
          style={{overflow: 'hidden auto', height: '100%'}}
          onScroll={e => {
            let element = e.target;
            me.autoScroll = element.scrollHeight - element.scrollTop - element.clientHeight < 10;
          }}
        >
          {
            me.state.mergedNodeList.length > 0 ? (
              <ExploreCommonRecommendDetailResultList
                viewDataProvider={me.props.viewDataProvider}
                checkedNodeMap={me.state.checkedNodeMap}
                loadingResult={me.state.loadingResult}
                onItemCheckChange={me.onItemCheckChange}
                onSaveNode={(nodeId, override) => {
                  let checkedNodeMap = {};
                  checkedNodeMap[nodeId] = true;
                  me.onSaveNodeList(override, checkedNodeMap);
                }}
                nodeList={me.state.mergedNodeList}
                bus={me.props.bus}
              />
            ) : undefined
          }
          {
            (
              me.state.originalNodeMap && Object.keys(me.state.originalNodeMap).length <= 0 ? (
                // 没有找到可操作的指定类型的节点
                <div
                  className={style['recommend-detail-result-list-error']}
                  key={'-1'}
                >
                  <p>
                    <span>
                      <Icon name={'exclamation-circle'} theme={'outlined'} />
                    </span>
                    <br />
                    没有可参与计算的节点，请知悉：
                    <ul style={{paddingLeft: '1.25rem'}}>
                      <li>仅标记为"{me.props.exploreName}"的节点参与计算</li>
                      <li>自动发现的节点中仅已保留的参与计算</li>
                      <li>仅您有权限的节点参与计算（如您是版主或相关节点创建人）</li>
                    </ul>
                  </p>
                </div>
              ) : (
                (
                  (me.state.currentPos === -1 && me.state.autoLoadMore) || me.state.loadingResult // 加载尚未启动或正在加载中
                ) ? (
                  <div
                    className={style[me.state.mergedNodeList.length <= 0 ? 'infinite-scroll-loading-empty' : 'infinite-scroll-loading']}
                    key={0}
                  >
                    <Icon name="loading" style={{marginRight: '0.5em'}} /> 计算中，请稍后...
                  </div>
                ) : (
                  (!me.state.loadingResult && me.state.lastLoadingFailed) ? (
                    <div
                      className={style[me.state.mergedNodeList.length <= 0 ? 'infinite-scroll-loading-empty' : 'infinite-scroll-loading']}
                      key={0}
                    >
                      <Icon name={'exclamation-circle'} theme={'outlined'} style={{marginRight: '0.5em'}} />
                      数据计算失败，<a onClick={() => me.onRetry()}>点击重试</a>
                    </div>
                  ) : (
                    me.state.mergedNodeList.length <= 0 ? (
                      <div
                        className={style['infinite-scroll-loading-empty']}
                        key={0}
                      >
                        <Icon name={'exclamation-circle'} theme={'outlined'} style={{marginRight: '0.5em'}} />
                        没有找到相关数据
                      </div>
                    ) : undefined
                  )
                )
              )
            )
          }
        </ScrollElement>
      </Modal>
    );
  }
}

ExploreCommonRecommendDetailModal.defaultProps = {
  bus: PB,
};

ExploreCommonRecommendDetailModal.propTypes = {
  exploreName: PropTypes.string.isRequired,
  exploreKey: PropTypes.string.isRequired,
  exploreEventCategory: PropTypes.string.isRequired,
  viewDataProvider: PropTypes.instanceOf(ViewDataProvider).isRequired,
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired,
  bus: PropTypes.instanceOf(SimplePB),
};

export default ExploreCommonRecommendDetailModal;