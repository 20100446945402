import React from 'react';
import PropTypes from 'prop-types';
import {message, Modal, Button, Steps, Row, Col, Drawer, Tag } from 'antd';
import ImageGallery from 'react-image-gallery';
import _ from 'lodash';

import PB, {SimplePB} from '@/libs/simplePB';

import {REQUEST_BASE} from '@/utils/HttpUtil';

import ViewDataProvider from '@/components/common/dataProvider/common.dataProvider.view';

import MicroServicePanelOperation from '@/components/common/view/microService/panel/common.view.microService.panel.operation';
import MicroServicePanelParameter from '@/components/common/view/microService/panel/common.view.microService.panel.parameter';
import MicroServicePanelTarget from '@/components/common/view/microService/panel/common.view.microService.panel.target';
import MicroServicePanelResult from '@/components/common/view/microService/panel/common.view.microService.panel.result.v2';
import {MicroServiceUIConfig} from '@/components/common/view/microService/shape/common.view.microService.shape.uiConfig';
import {showErrorMessage} from "@/components/common/common.message";
import {NetworkDataLoadingStatus} from "@/libs/view/network/status";
import {NetworkEvents} from "@/libs/view/network/events";
import {getToken} from '@/utils/HttpUtil';

import style from '@/style/common/microService/common.microService.v2.less';
//import reactWindowSize from 'react-window-size';
const defaultDescription = "根据您的图谱内容进行智能计算，计算结果可以对图谱中的节点进行增加、修改、删除或连接操作。";


class MicroServiceModalServiceWizard extends React.Component {
  state = {
    currentStep: 0,

    hasTarget: false,

    locked: true,

    targets: {},
    parameters: {},

    // 结果加载状态参数
    loadingResult: false,
    hasMoreResult: false,
    lastLoadingFailed: false,
    autoLoadMore: false,

    hasCheckedKey: false,

    savingStatus: 'idle',
    operationType: undefined,
    statisticsByType: {},
  };

  closing = false;

  localStorageKey = undefined;

  getBackgroundTargetUpdater = () => {
    let me = this;
    return new Promise(((resolve, reject) => {
      if (me.props.viewDataProvider) {
        me.props.bus.emit('view', 'micro_service.service.target.background_updater.get', {
          viewId: me.props.viewDataProvider.viewId,
          serviceId: me.props.microServiceId,
          callback: updater => resolve(updater),
        });
      } else {
        reject();
      }
    }))
  };

  onClose = () => {
    // TODO
    let me = this, waitAndClose = () => {
      let closeFn = () => {
        me.closing = true;
        me.onStartOver();
        me.props.onClose();
        me.closing = false;
      };
      if (me.state.loadingResult) {
        let hideMsg = message.loading('正在停止');
        let closeInterval = setInterval(() => {
          if (!me.state.loadingResult) {
            clearInterval(closeInterval);
            hideMsg();
            closeFn();
          }
        }, 1000);
      } else {
        closeFn();
      }
    };

    if (me.state.autoLoadMore && me.state.hasMoreResult && !me.state.lastLoadingFailed) {
      Modal.confirm({
        title: '关闭对话框将停止计算并清空已有计算结果，是否确认？',
        onOk: () => {
          me.onStop();
          waitAndClose();
        },
        okText: '确认关闭',
        cancelText: '取消',
      });
    } else {
      me.onStop();
      waitAndClose();
    }
  };

  onDataRefreshed = (
    {
      locked,
      ui,
      hasMoreResult,
      loadingResult,
      lastLoadingFailed,
      autoLoadMore,
      targets,
      parameters,

      /*actionOptions,*/
    }
  ) => {
    let me = this, newState = {};

    if (!me.closing) {
      newState.currentStep = ui.currentStep || 0;
    }

    newState.locked = locked;

    newState.targets = _.cloneDeep(targets);
    newState.parameters = _.cloneDeep(parameters);

    newState.hasMoreResult = hasMoreResult;
    newState.loadingResult = loadingResult;
    newState.lastLoadingFailed = lastLoadingFailed;
    newState.autoLoadMore = autoLoadMore;

    // TODO actionOptions
    me.setState(newState);
  };

  onMainAction = () => {
    let me = this;

    if (me.props.viewDataProvider) {
      me.props.bus.emit('view', 'micro_service.service.action.main_operation',
        {viewId: me.props.viewDataProvider.viewId, serviceId: me.props.microServiceId,
          operation: me.state.operationType});
    }
  };

  onParameterSet = (name, value, key = 'value') => {
    let me = this, parameters = {...me.state.parameters};

    if (me.props.viewDataProvider) {
      me.props.bus.emit('view', 'micro_service.service.parameter.set',
        {viewId: me.props.viewDataProvider.viewId, serviceId: me.props.microServiceId, name, key,
          value});

      parameters[name] = parameters[name] || {};
      parameters[name][key] = value;
      me.setState({parameters});
    }
  };

  onResultCleared = () => {
    let me = this;

    if (me.closing) return;

    me.setState({
      currentStep: 0,

      locked: false,

      loadingResult: false,
      hasMoreResult: false,
      lastLoadingFailed: false,
      autoLoadMore: false,

      hasCheckedKey: false,
    });
  };

  onResultDataFailedToLoad = ({code, msg}) => {
    let me = this;

    me.setState({
      loadingResult: false,
      lastLoadingFailed: true,
    }, () => {
      showErrorMessage({code, msg, extra: {viewId: me.props.viewDataProvider.viewId, isModification: false}});
    });
  };

  onResultDataLoaded = ({hasMoreResult}) => {
    let me = this;

    me.setState({
      loadingResult: false,
      lastLoadingFailed: false,
      hasMoreResult,
    });
  };

  onResultDataStartToLoad = ({autoLoadMore}) => {
    let me = this;

    me.setState({
      loadingResult: true,
      lastLoadingFailed: false,
      autoLoadMore,
    });
  };

  onRetry = () => {
    let me = this;

    if (me.props.viewDataProvider) {
      me.props.bus.emit('view', 'micro_service.service.on_load_more',
        {viewId: me.props.viewDataProvider.viewId, serviceId: me.props.microServiceId,
          autoLoadMore: me.state.autoLoadMore, dataType: me.props.dataType});
    }
  };

  onStart = () => {
    let me = this, callback = () => {
      if (me.props.viewDataProvider) {
        me.props.bus.emit('view', 'micro_service.service.on_load_more',
          {viewId: me.props.viewDataProvider.viewId, serviceId: me.props.microServiceId,
            autoLoadMore: me.state.autoLoadMore, dataType: me.props.dataType,serviceInfo: me.props.microServiceInfo});
      }
    };
    me.setState({locked: true, autoLoadMore: true}, callback);
  };

  onStartOver = () => {
    let me = this, callback = () => {
      if (me.props.viewDataProvider) {
        me.props.bus.emit('view', 'micro_service.service.clear_result',
          {viewId: me.props.viewDataProvider.viewId, serviceId: me.props.microServiceId});
        me.props.bus.emit('view', 'micro_service.service.broadcast_data',
          {viewId: me.props.viewDataProvider.viewId, serviceId: me.props.microServiceId, preferResetTarget: true});
      }
    };

    if (me.state.autoLoadMore) {
      me.setState({autoLoadMore: false}, callback);
    } else {
      callback();
    }
  };

  onStatisticsRefreshed = ({statisticsByType}) => {
    let me = this;

    me.setState({statisticsByType});
  };

  onStep = currentStep => {
    let me = this;

    if (me.props.viewDataProvider) {
      me.props.bus.emit('view', 'micro_service.service.ui.set',
        {viewId: me.props.viewDataProvider.viewId, serviceId: me.props.microServiceId, key: 'currentStep', value: currentStep});

      me.setState({currentStep}, () => {
        if (currentStep === 2) {
          me.onStart();
        }
      });
    }
  };

  onStop = () => {
    let me = this, callback = () => {
      if (me.props.viewDataProvider) {
        me.props.bus.emit('view', 'micro_service.service.stop_auto_load',
          {viewId: me.props.viewDataProvider.viewId, serviceId: me.props.microServiceId});
      }
    };

    if (me.state.autoLoadMore) {
      me.setState({autoLoadMore: false}, callback);
    } else {
      callback();
    }
  };

  onTargetSet = (name, value, key = 'value') => {
    let me = this;
    if (me.props.viewDataProvider) {
      me.props.bus.emit('view', 'micro_service.service.target.set',
        {viewId: me.props.viewDataProvider.viewId, serviceId: me.props.microServiceId, name, key, value});
    }
  };

  refreshData = () => {
    let me = this;

    if (me.props.viewDataProvider.getData().status === NetworkDataLoadingStatus.SUCCESS) {
      me.props.bus.emit('view', 'micro_service.service.broadcast_data',
        {viewId: me.props.viewDataProvider.viewId, serviceId: me.props.microServiceId, preferResetTarget: true});
    } else {
      me.props.viewDataProvider.once(me, NetworkEvents.LOADING_DATA_SUCCESS, () => {
        me.props.bus.emit('view', 'micro_service.service.broadcast_data',
          {viewId: me.props.viewDataProvider.viewId, serviceId: me.props.microServiceId, preferResetTarget: true});
      });
    }

    requestAnimationFrame(() => {
      if (me.props.microServiceConfig && me.props.microServiceConfig.operations.length > 0) {
        me.setState({operationType: me.props.microServiceConfig.operations[0].type});
      }
    })
  };

  /**
   * 用户点击展示图表的回调
   */
   onShowLink = (operationType) => {
    let me = this;
    let params ={
      token:localStorage.getItem("token"),
      viewId:me.props.viewDataProvider.viewId,
      serviceId:me.props.microServiceId,
      apiUrl:`/base/view/micro_service/${me.props.microServiceId}/actions/call_by_view/${me.props.viewDataProvider.viewId}`,
      microServiceInfo:me.props.microServiceInfo,
      targets:me.state.targets,
      parameters:me.state.parameters
    };
    let linkUrl = '';
    me.props.microServiceConfig.operations.map((item) => {
      if(item.type==="showCharts"){
        linkUrl = item.chartUrl;
      }else if(item.type==="showOutLink"){
        linkUrl = item.outUrl;
      }else if(item.type==="showImplement"){
        window.location.reload();
      }
    })
    if(linkUrl){
      me.localStorageKey = 'microService_'+Math.random();
      localStorage.setItem(me.localStorageKey,JSON.stringify(params));
      if(operationType==="showOutLink"){
        let nodes = params.targets.$target.value && params.targets.$target.value.nodes;
        let userToken = getToken();
        if(nodes && nodes.length>0){
          linkUrl = `${linkUrl}?user_token=${encodeURIComponent(userToken)}&view_id=${me.props.viewDataProvider.viewId}&node_id=${nodes[0]}&key=${me.localStorageKey}`;
        }else{
          linkUrl = `${linkUrl}?user_token=${encodeURIComponent(userToken)}&view_id=${me.props.viewDataProvider.viewId}&key=${me.localStorageKey}`;
        }
      }else{
        linkUrl = `${linkUrl}?view_id=${me.props.viewDataProvider.viewId}&key=${me.localStorageKey}`;
      }
      window.open(linkUrl, '_blank');
    }
    
  };

  componentDidMount() {
    let me = this;

    me.props.bus.with(me).subscribe('view', 'micro_service.service.current_data', data => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === data.viewId && me.props.visible
        && me.props.microServiceId === data.serviceId) {

        me.onDataRefreshed(data);
      }
    }).subscribe('view', 'micro_service.service.current_statistics', data => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === data.viewId && me.props.visible
        && me.props.microServiceId === data.serviceId) {

        me.onStatisticsRefreshed(data);
      }
    }).subscribe('view', 'micro_service.service.current_operation_ui', ({viewId, serviceId, savingStatus}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId && me.props.visible
        && me.props.microServiceId === serviceId) {

        // 暂不检查operation
        if (savingStatus !== undefined && me.state.savingStatus !== savingStatus) {
          me.setState({savingStatus});
        }
      }
    }).subscribe('view', 'micro_service.service.do_load_more', data => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === data.viewId && me.props.visible
        && me.props.microServiceId === data.serviceId) {

        me.onResultDataStartToLoad(data);
      }
    }).subscribe('view', 'micro_service.service.raw_data_loaded', data => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === data.viewId && me.props.visible
        && me.props.microServiceId === data.serviceId) {

        me.onResultDataLoaded(data);
      }
    }).subscribe('view', 'micro_service.service.failed_to_load', data => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === data.viewId && me.props.visible
        && me.props.microServiceId === data.serviceId) {

        me.onResultDataFailedToLoad(data);
      }
    }).subscribe('view', 'micro_service.service.result_cleared', data => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === data.viewId && me.props.visible
        && me.props.microServiceId === data.serviceId) {

        me.onResultCleared();
      }
    }).subscribe('view', 'micro_service.service.action.start', data => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === data.viewId && me.props.visible
        && me.props.microServiceId === data.serviceId) {

        me.onStart();
      }
    }).subscribe('view', 'micro_service.service.action.stop', data => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === data.viewId && me.props.visible
        && me.props.microServiceId === data.serviceId) {

        me.onStop();
      }
    }).subscribe('view', 'micro_service.service.action.start_over', data => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === data.viewId && me.props.visible
        && me.props.microServiceId === data.serviceId) {

        me.onStartOver();
      }
    }).subscribe('view', 'micro_service.service.action.retry', data => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === data.viewId && me.props.visible
        && me.props.microServiceId === data.serviceId) {

        me.onRetry();
      }
    }).subscribe('view', 'micro_service.service.action.main_operation', ({viewId, serviceId, operation}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId && me.props.visible
        && me.props.microServiceId === serviceId && (me.state.operationType === 'showCharts' || me.state.operationType === 'showOutLink' || me.state.operationType === 'showImplement')) {
        me.onShowLink(me.state.operationType);
      }
    });

    if (me.props.visible) {
      me.refreshData();
    }
    if(me.props.dataType===1){
      me.onStart();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let me = this;

    if (!prevProps.visible && me.props.visible) {
      me.refreshData();
      me.closing = false;
      if(me.props.dataType===1){
        me.onStart();
      }
    }
  }

  componentWillUnmount() {
    this.props.bus.remove(this);
    localStorage.setItem(this.localStorageKey,'');
  }

  render() {
    let me = this,
      /** @type {TMicroServiceUIConfig} */ uiConfig = me.props.microServiceConfig,
      images = [];

    if (me.props.microServiceInfo.images && me.props.microServiceInfo.images.length > 0) {
      images = me.props.microServiceInfo.images.map(id => ({
        original: `${REQUEST_BASE}/view/public_files/${id}`,
      }));
    } else if (me.props.microServiceInfo.coverId) {
      images = [{
        original: `${REQUEST_BASE}/view/public_files/${me.props.microServiceInfo.coverId}`,
      }];
    }
    if(me.props.dataType===1 && me.props.microServiceInfo.meta && me.props.microServiceInfo.meta.iconData){
      images = [{
        original: me.props.microServiceInfo.meta.iconData,
      }];
    }

    return (
      <React.Fragment>
        {
          me.props.dataType===1 || uiConfig ? (
            <Drawer
              visible={me.props.visible}
              placement={'right'}
              destroyOnClose={true}
              mask={true}
              maskClosable={true}
              maskStyle={{backgroundColor: 'transparent'}}
              width={'46rem'}
              className={`${style['market-drawer-wrap']}`}
              onClose={me.props.onClose}
              style={{zIndex:1001,opacity:1}}
            >
              <div style={{ height: '100%',backgroundColor:'#fff',opacity:1}}>
                <div className={style['market-drawer-header']} style={{borderBottom:0}}>
                  <div className={style['market-drawer-title']} style={{flex:'auto'}}>{me.props.microServiceInfo.title}</div>
                  <div className={style['market-drawer-right']}>
                    
                  </div>
                </div>
                <div className={style['market-drawer-body']}>
                  <div className={`${style['wizard-content']} scrollbar scrollbar-none `} style={{height:'auto',maxHeight:'98%'}}>
                    <div style={{border:0}}>
                      <Row>
                        <Col span={16} style={{paddingRight: '0.5rem'}}>
                          <p style={{whiteSpace: 'pre-wrap', marginBottom: 0,lineHeight:1.8}}>
                            <span>服务调用：&lt;500次 <br /></span>
                            <span>开发者：炬图AI<br /></span>
                            <span>当前版本：{me.props.microServiceInfo.version}<br /></span>
                            {me.props.microServiceInfo.createTimestamp && <span>创建时间：{me.props.microServiceInfo.createTimestamp.substr(0, 10)}<br /></span>}
                            {me.props.microServiceInfo.updateTimestamp && <span>更新时间：{me.props.microServiceInfo.updateTimestamp.substr(0, 10)}<br /></span>}
                          </p>
                          
                        </Col>
                        <Col span={8} style={{paddingLeft: '0.5rem'}}>
                          {
                            images.length > 0 ? (
                              <ImageGallery items={images} infinite={false} showThumbnails={false} showPlayButton={false} useBrowserFullscreen={false} />
                            ) : null
                          }
                        </Col>
                      </Row>
                    </div>
                    <div>
                      <p className={style['market-drawer-tag']}>
                            {me.props.microServiceInfo.tag && me.props.microServiceInfo.tag.split(' ').map(
                              (tag, i) => (
                                tag && (<Tag key={`tag-${i}`} color="#ffe699">
                                  &nbsp;{tag}&nbsp;
                                </Tag>)
                              )
                            )}
                      </p>
                      <p className={`${style['market-drawer-description']} scrollbar scrollbar-none`}>
                            {
                              (me.props.microServiceInfo.description || defaultDescription).split('\n').map((line, idx) => (
                                <span key={`ln-${idx}`}>{line}<br /></span>
                              ))
                            }
                      </p>
                    </div>
                    <React.Fragment>
                    {me.props.dataType!==1 && <div className={`${style['market-drawer-header']} ${style['market-drawer-primary']}`}>
                        <div className={style['market-drawer-title']}>输入参数</div>
                        {me.props.microServiceInfo.calcServerId && me.props.microServiceInfo.calcServerPathId &&
                        <div className={style['market-drawer-right']} style={{flex:'none',paddingRight:0}}>
                          {
                            me.state.locked ? null : (
                              <Button
                                type={'primary'}
                                onClick={me.onStart}
                                disabled={!me.state.hasTarget}
                                block={true}
                              >
                                开始计算
                              </Button>
                            )
                          }
                          {
                            me.state.locked && ((me.state.autoLoadMore && me.state.hasMoreResult) || me.state.loadingResult) ? (
                              <Button
                                onClick={me.onStop}
                                disabled={!me.state.autoLoadMore && me.state.loadingResult}
                                block={true}
                              >
                                停止计算
                              </Button>
                            ) : null
                          }
                          {
                            me.state.locked && (!me.state.autoLoadMore || !me.state.hasMoreResult) && !me.state.loadingResult ? (
                              <Button
                                onClick={me.onStartOver}
                                block={true}
                              >
                                重新计算
                              </Button>
                            ) : null
                          }
                        </div>
                        }
                      </div>}
                      {me.props.dataType!==1 &&<MicroServicePanelTarget
                        config={uiConfig.target}
                        currentNodeId={me.props.currentNodeId}
                        locked={me.state.locked}
                        visible={me.props.visible}
                        targets={me.state.targets}
                        onTargetSet={me.onTargetSet}
                        onHasTargetChanged={hasTarget => me.setState({hasTarget})}
                        getBackgroundTargetUpdater={me.getBackgroundTargetUpdater}
                      />}
                      {me.props.dataType!==1 && (uiConfig.parameter && uiConfig.parameter.items && uiConfig.parameter.items.length > 0 ? ( 
                      <MicroServicePanelParameter
                        config={uiConfig.parameter}
                        locked={me.state.locked}
                        onParameterSet={me.onParameterSet}
                        parameters={me.state.parameters}
                      />):(
                        <div className={style['market-drawer-line']}></div>))
                      }
                    </React.Fragment>

                    { <MicroServicePanelResult
                          operations={uiConfig.operations}
                          locked={me.state.locked || me.props.dataType===1}
                          autoLoadMore={me.state.autoLoadMore}
                          hasMoreResult={me.state.hasMoreResult && me.props.dataType!==1}
                          lastLoadingFailed={me.state.lastLoadingFailed}
                          loadingResult={me.state.loadingResult}
                          onCheckStatusChanged={hasCheckedKey => {
                            if (me.state.hasCheckedKey !== hasCheckedKey) {
                              me.setState({hasCheckedKey});
                            }
                          }}
                          onOperationChanged={operationType => me.setState({operationType})}
                          viewDataProvider={me.props.viewDataProvider}
                          bus={me.props.bus}
                          microServiceId={me.props.microServiceId}
                          viewId={me.props.viewDataProvider ? me.props.viewDataProvider.viewId : undefined}
                          visible={me.props.visible}
                          operationType={me.state.operationType}
                          mainActionDisabled={!me.state.locked && me.props.dataType!==1 || !me.state.hasCheckedKey}
                          onMainActionClicked={me.onMainAction}
                          microServiceInfo={me.props.microServiceInfo}
                          statisticsByType={me.state.statisticsByType}
                    />}

                    
                      {/*<div className={style['wizard-calc-action']}>
                        {
                          me.state.operationType ? (
                            <MicroServicePanelOperation
                              type={me.state.operationType}
                              locked={me.state.locked}
                              hasTarget={me.state.hasTarget}
                              autoLoadMore={me.state.autoLoadMore}
                              hasMoreResult={me.state.hasMoreResult}
                              loadingResult={me.state.loadingResult}
                              mainActionDisabled={!me.state.locked || !me.state.hasCheckedKey}
                              statisticsByType={me.state.statisticsByType}
                              onStartClicked={me.onStart}
                              onStopClicked={me.onStop}
                              onStartOverClicked={me.onStartOver}
                              onMainActionClicked={me.onMainAction}
                            />
                          ) : null
                        }
                      </div>*/}

                  </div>
{/*
                  <Steps
                    type="navigation"
                    current={me.state.currentStep}
                  >
                    <Steps.Step status={me.state.currentStep === 0 ? 'process' : 'finish'} title="功能简介" />
                    <Steps.Step status={me.state.currentStep === 0 ? 'wait' : (me.state.currentStep === 1 ? 'process' : 'finish')} title="设置参数" />
                    <Steps.Step status={me.state.currentStep === 2 ? 'process' : 'wait'} title="开始计算" />
                  </Steps>
                  <div className={`${style['wizard-content']} scrollbar scrollbar-none `}>
                    {content}
                  </div>
*/}  
                </div>
              </div>
            </Drawer>
          ) : null
        }
      </React.Fragment>
    );
  }
}

MicroServiceModalServiceWizard.defaultProps = {
  bus: PB,
};

MicroServiceModalServiceWizard.propTypes = {
  viewDataProvider: PropTypes.instanceOf(ViewDataProvider).isRequired,
  microServiceId: PropTypes.string.isRequired,
  microServiceInfo: PropTypes.object.isRequired,
  microServiceConfig: PropTypes.shape(MicroServiceUIConfig).isRequired,
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired,
  currentNodeId: PropTypes.string,
  bus: PropTypes.instanceOf(SimplePB),
  dataType: PropTypes.number.isRequired,
};

export default MicroServiceModalServiceWizard;