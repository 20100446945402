import {
  DO_CHECK_USERNAME,
  USER_FOUND,
  INIT_LOGIN_VIA_USERNAME,
  INIT_LOGIN_VIA_WE_CHAT,
  BACK_TO_CHECK_USERNAME,
  DO_LOGIN,
  DO_MAGIC_LOGIN,
  DO_LOGOUT,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  DO_REGISTER,
  INIT_REGISTER,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  RESET_PWD_VIA_EMAIL,
  WE_CHAT_RESOURCE_LOADED,
  DO_LOGIN_VIA_WE_CHAT,
  LOGIN_VIA_WE_CHAT_SUCCESS,
  LOGIN_VIA_WE_CHAT_FAIL,
  DO_GET_WE_CHAT_FWH_TICKET,
  GET_WE_CHAT_FWH_TICKET_SUCCESS,
  GET_WE_CHAT_FWH_TICKET_FAIL,
  DO_CHECK_WE_CHAT_FWH_TICKET,
  RESET_PWD_EMAIL_SENT,
  RESET_PWD_EMAIL_SEND_FAIL,
  RESET_PWD_SEND_NEW_PWD,
  RESET_PWD_DONE,
  RESET_PWD_FAIL,
  RESET_PWD_CONFIRM,
  RESET_PWD_INIT,
  DO_CHANGE_PWD,
  CHANGE_PWD_INIT,
  CHANGE_PWD_SUCCESS,
  CHANGE_PWD_FAIL,
  CHANGE_USER_BASIC_INFO,
  CHANGE_USER_BASIC_INFO_SUCCESS,
  CHANGE_USER_BASIC_INFO_FAIL,
  CHANGE_EMAIL_SUCCESS,
} from "./actionTypes";

export const doCheckUsernameAction = (username) => ({type: DO_CHECK_USERNAME, username});

export const userFoundAction = (username) => ({type: USER_FOUND, username});

export const initLoginViaUsernameAction = () => ({type: INIT_LOGIN_VIA_USERNAME});

export const initLoginViaWechatAction = (backFn) => ({type: INIT_LOGIN_VIA_WE_CHAT, backFn});

export const backToCheckUsernameAction = () => ({type: BACK_TO_CHECK_USERNAME});

export const doLoginAction = (username, password, remember) => ({type: DO_LOGIN, username, password, remember});

export const doMagicLoginAction = () => ({type: DO_MAGIC_LOGIN});

export const doLogoutAction = () => ({type: DO_LOGOUT});

export const loginSuccessAction = (userInfo) => ({type: LOGIN_SUCCESS, userInfo});

export const loginFailAction = (code) => ({type: LOGIN_FAIL, code});

export const initRegisterAction = () => ({type: INIT_REGISTER});

export const doRegisterAction = (username) => ({type: DO_REGISTER, username});

export const registerSuccessAction = (email) => ({type: REGISTER_SUCCESS, email});

export const registerFailAction = (code) => ({type: REGISTER_FAIL, code});

export const resetPwdConfirmAction = () => ({type: RESET_PWD_CONFIRM});

export const resetPwdViaEmailAction = (email) => ({type: RESET_PWD_VIA_EMAIL, email});

export const resetPwdEmailSentAction = (email) => ({type: RESET_PWD_EMAIL_SENT, email});

export const resetPwdEmailSendFailAction = (code) => ({type: RESET_PWD_EMAIL_SEND_FAIL, code});

export const resetPwdSendNewPwdAction = (password, token, strength) =>
  ({type: RESET_PWD_SEND_NEW_PWD, password, token, strength});

export const resetPwdDoneAction = () => ({type: RESET_PWD_DONE});

export const resetPwdFailAction = (code) => ({type: RESET_PWD_FAIL, code});

export const resetPwdInitAction = () => ({type: RESET_PWD_INIT});

export const onWeChatResourceLoadedAction = (state) => ({type: WE_CHAT_RESOURCE_LOADED, state});

export const doLoginViaWeChatAction = (code) => ({type: DO_LOGIN_VIA_WE_CHAT, code});

export const loginViaWeChatSuccessAction = (userInfo) => ({type: LOGIN_VIA_WE_CHAT_SUCCESS, userInfo});

export const loginViaWeChatFailAction = (code) => ({type: LOGIN_VIA_WE_CHAT_FAIL, code});

export const doGetWeChatFwhTicketAction = () => ({type: DO_GET_WE_CHAT_FWH_TICKET});

export const getWeChatFwhTicketSuccessAction = (ticket) => ({type: GET_WE_CHAT_FWH_TICKET_SUCCESS, ticket});

export const getWeChatFwhTicketFailAction = () => ({type: GET_WE_CHAT_FWH_TICKET_FAIL});

export const doCheckWeChatFwhTicketAction = (ticket) => ({type: DO_CHECK_WE_CHAT_FWH_TICKET, ticket});

export const doChangePwdAction = (oldPwd, newPwd, strength) => ({type: DO_CHANGE_PWD, oldPwd, newPwd, strength});

export const changePwdSuccessAction = (strength) => ({type: CHANGE_PWD_SUCCESS, strength});

export const changePwdFailAction = (errorCode) => ({type: CHANGE_PWD_FAIL, errorCode});

export const changePwdInitAction = () => ({type: CHANGE_PWD_INIT});

export const changeUserBasicInfoAction = ({nick, name, org, province, city, picId}) =>
  ({type: CHANGE_USER_BASIC_INFO, userInfo: {nick, name, org, province, city, picId}});

export const changeUserBasicInfoSuccessAction = (userInfo) => ({type: CHANGE_USER_BASIC_INFO_SUCCESS, userInfo});

export const changeUserBasicInfoFailAction = (errorCode) => ({type: CHANGE_USER_BASIC_INFO_FAIL, errorCode});

export const changeEmailSuccessAction = (email) => ({type: CHANGE_EMAIL_SUCCESS, email});