import React from 'react';
import PropTypes from 'prop-types';
import {Button, message, Modal, Upload, Row, Col} from 'antd';

import {getBase64Url} from "@/utils/Common";
import Icon from "@/components/common/common.icon";

import style from '@/style/common/view/common.view.info.setCoverComponent.less';
import {checkUploadImage} from "@/components/common/common.functions";
import PB, {SimplePB} from "@/libs/simplePB";
import ViewDataProvider from '@/components/common/dataProvider/common.dataProvider.view';
import {showErrorMessage} from "@/components/common/common.message";

class UploadBannerComponent extends React.Component {
    state = {
        showModal: false,
        // autoShowUploadNotice: false, // 显示提示弹窗
        imageDataUrl: false, // 上传的图片数据
        newImageDataUrl: undefined, // 新上传的图片数据
        // showViewTitleBanner: localStorage.getItem('show_view_title_banner') === '1',
        showViewTitleBanner: false,

        uploading: false, // 正在上传
        changing: false, // 正在替换
        clearing: false // 正在删除
    };

    componentDidMount() {
        console.log('bannerImageConfig props:', this.props)
        let me = this;
        // 弹出、关闭上传弹框
        PB.subscribe(me, 'view', 'banner.show_upload_modal', ({show, viewId}) => {
            me.setState({showModal: show, viewId: viewId});
        });

        PB.subscribe(me, 'view', 'banner.show_upload_modal.show', ({viewId}) => {
            me.setState({showModal: true, viewId: viewId});
        });

        // 上传失败
        PB.subscribe(me, 'view', 'banner.upload_failed', ({viewId}) => {
            if (viewId === me.props.viewId && me.state.uploading) {
                me.setState({
                    newImageDataUrl: undefined,
                    uploading: false,
                    changing: false,
                    clearing: false
                });
            }
        });

        // 删除失败
        PB.subscribe(me, 'view', 'banner.clear_failed', ({viewId}) => {
            if (viewId === me.props.viewId && me.state.clearing) {
                me.setState({
                    newImageDataUrl: undefined,
                    uploading: false,
                    changing: false,
                    clearing: false
                });
            }
        });

        // -------------------------------------------------------------------------------
        // 加载图片数据
        PB.subscribe(me, 'view', 'banner.loaded', ({viewId, dataUrl}) => {
            console.log('bannerImageConfig banner.loaded:', viewId, dataUrl);
            if (viewId === me.props.viewId) {
                console.log('bannerImageConfig banner.loaded setState');
                me.setState({imageDataUrl: dataUrl, showViewTitleBanner: !!dataUrl});
            }
        });
    }

    /*
    // 上传图片
    uploadBanner_old = file => {
        let me = this;
        if (checkUploadImage(file, message, 20)) {
            getBase64Url(file, imgBase64Url => {
                me.setState({
                    uploading: !me.state.imageDataUrl,
                    changing: !!me.state.imageDataUrl,
                }, () => {
                    let config = {
                        hasData: true,
                        dataUrl: imgBase64Url,
                        random: `${Math.random()}-${(imgBase64Url).slice(-10)}`,
                    };
                    me.props.networkRef.updateConfig(`$.viewData.banner`, config).then(() => {
                        console.log('bannerImageConfig updateConfig upload');
                        // 刷新图谱数据
                        // PB.emit('view', 'banner.do_load', ({viewId: me.props.viewId}));
                        me.setState({imageDataUrl: imgBase64Url});
                        setTimeout(() => {
                            me.setState({
                                uploading: false,
                                changing: false,
                            });
                        }, 500);

                    }).catch(({code, msg}) => {
                        console.log(`bannerImageConfig updateConfig error code: ${code}, msg: ${msg}`);
                        PB.emit('view', 'banner.upload_failed', {viewId: me.props.viewId, code, msg});
                        showErrorMessage({code, msg, extra: {viewId: me.props.viewId, isModification: true}});
                        setTimeout(() => {
                            me.setState({
                                uploading: false,
                                changing: false,
                            });
                        }, 500);
                    });

                });
            });
        }
        return false;
    };

    // 删除图片
    clearBanner_old = (e) => {
        e.stopPropagation();
        let me = this;
        if (this.state.imageDataUrl) {
            Modal.confirm({
                title: "操作确认",
                content: '确认清除标题图片吗？',
                okText: '确认清除',
                cancelText: '取消',
                okButtonProps: {
                    type: 'danger',
                },
                onOk: () => {
                    me.setState({
                        clearing: true,
                    }, () => {
                        let config = {
                            hasData: false,
                            random: `${Math.random()}-$empty`,
                        };
                        me.props.networkRef.updateConfig(`$.viewData.banner`, config).then(() => {
                            console.log('bannerImageConfig updateConfig clear');
                            // 刷新图谱数据
                            // PB.emit('view', 'banner.do_load', ({viewId: me.props.viewId}));
                            me.setState({imageDataUrl: undefined});
                            setTimeout(() => {
                                me.setState({
                                    clearing: false,
                                });
                            }, 500);

                        }).catch(({code, msg}) => {
                            console.log(`bannerImageConfig updateConfig clear error code: ${code}, msg: ${msg}`);
                            // PB.emit('aiConsole', 'message.update', {key: messageKey, content: `操作失败`});
                            PB.emit('view', 'banner.clear_failed', {viewId: me.props.viewId, code, msg});
                            showErrorMessage({code, msg, extra: {viewId: me.props.viewId, isModification: true}});
                            setTimeout(() => {
                                me.setState({
                                    clearing: false,
                                });
                            }, 500);

                        });
                    });
                },
            });
        } else {
            Modal.info({
                content: '当前图谱没有设置标题图',
                okText: '知道了',
            });
        }
    }

     */

    // 临时保存图片
    cacheBanner = file => {
        let me = this;
        if (checkUploadImage(file, message, 20)) {
            getBase64Url(file, imgBase64Url => {
                me.setState({
                    uploading: !me.state.imageDataUrl,
                    changing: !!me.state.imageDataUrl,
                }, () => {
                    me.setState({
                        newImageDataUrl: imgBase64Url,
                        showViewTitleBanner: true,
                        uploading: false,
                        changing: false,
                    });
                });
            });
        }
        return false;
    };

    // 文字图片相互切换，不保存
    switchShowViewTitleBanner = (e) => {
        e.stopPropagation();
        this.setState({
            // showViewTitleBanner: !this.state.showViewTitleBanner
            showViewTitleBanner: false,
            newImageDataUrl: undefined
        })
    }

    // 关闭弹窗，不保存设置
    handleCancel = e => {
        console.log('bannerImageConfig handleCancel:');
        e.stopPropagation();
        // 刷新看板
        // PB.emit('view', 'banner.switch_image');
        // 刷新图谱数据
        PB.emit('view', 'banner.do_load', ({viewId: this.props.viewId}));
        this.setState({
            newImageDataUrl: undefined,
            uploading: false,
            changing: false,
            clearing: false,
            showModal: false
        });
    };

    // 关闭弹窗，保存设置
    handleOk = e => {
        console.log('bannerImageConfig handleOk:');
        e.stopPropagation();
        const {imageDataUrl, newImageDataUrl, showViewTitleBanner} = this.state;
        let me = this;
        // 判断是否有文件要上传
        if (newImageDataUrl) {
            this.setState({
                uploading: !this.state.imageDataUrl,
                changing: !!this.state.imageDataUrl,
            }, () => {
                let imgBase64Url = newImageDataUrl;
                let config = {
                    hasData: true,
                    dataUrl: imgBase64Url,
                    random: `${Math.random()}-${(imgBase64Url).slice(-10)}`,
                };
                me.props.networkRef.updateConfig(`$.viewData.banner`, config).then(() => {
                    console.log('bannerImageConfig updateConfig upload');
                    // 保存显示状态
                    localStorage.setItem('show_view_title_banner', '1');
                    // 刷新图谱数据
                    PB.emit('view', 'banner.do_load', ({viewId: me.props.viewId}));
                    setTimeout(() => {
                        me.setState({
                            newImageDataUrl: undefined,
                            uploading: false,
                            changing: false,
                            showModal: false
                        });
                    }, 500);
                }).catch(({code, msg}) => {
                    console.log(`bannerImageConfig updateConfig error code: ${code}, msg: ${msg}`);
                    localStorage.setItem('show_view_title_banner', '0');
                    PB.emit('view', 'banner.upload_failed', {viewId: me.props.viewId, code, msg});
                    showErrorMessage({code, msg, extra: {viewId: me.props.viewId, isModification: true}});
                    setTimeout(() => {
                        me.setState({
                            newImageDataUrl: undefined,
                            uploading: false,
                            changing: false,
                            // showModal: false
                        });
                    }, 500);
                });
            });
        } else {
            if (!showViewTitleBanner) {
                // 删除图片
                if (imageDataUrl) {
                    let config = {
                        hasData: false,
                        random: `${Math.random()}-$empty`,
                    };
                    me.props.networkRef.updateConfig(`$.viewData.banner`, config).then(() => {
                        console.log('bannerImageConfig updateConfig clear');
                        // 刷新图谱数据
                        PB.emit('view', 'banner.do_load', ({viewId: me.props.viewId}));
                        setTimeout(() => {
                            me.setState({
                                newImageDataUrl: undefined,
                                uploading: false,
                                changing: false,
                                showModal: false
                            });
                        }, 500);

                    }).catch(({code, msg}) => {
                        console.log(`bannerImageConfig updateConfig clear error code: ${code}, msg: ${msg}`);
                        // PB.emit('aiConsole', 'message.update', {key: messageKey, content: `操作失败`});
                        PB.emit('view', 'banner.clear_failed', {viewId: me.props.viewId, code, msg});
                        showErrorMessage({code, msg, extra: {viewId: me.props.viewId, isModification: true}});
                        setTimeout(() => {
                            me.setState({
                                newImageDataUrl: undefined,
                                uploading: false,
                                changing: false,
                                // showModal: false
                            });
                        }, 500);
                    });
                } else {
                    // Modal.info({
                    //     content: '当前图谱没有设置标题图',
                    //     okText: '知道了',
                    // });
                    // 刷新图谱数据
                    PB.emit('view', 'banner.do_load', ({viewId: me.props.viewId}));
                    setTimeout(() => {
                        me.setState({
                            newImageDataUrl: undefined,
                            uploading: false,
                            changing: false,
                            showModal: false
                        });
                    }, 500);
                }
            }
        }


        // 保存显示状态
        // localStorage.setItem('show_view_title_banner', this.state.showViewTitleBanner ? '1' : '0');
        // 刷新看板
        // PB.emit('view', 'banner.switch_image');
        // 刷新图谱数据
        // PB.emit('view', 'banner.do_load', ({viewId: this.props.viewId}));
        // this.setState({showModal: false});
    };

    componentWillUnmount() {
        this.props.bus.remove(this);
    }

    render() {
        const {viewInfo} = this.props;
        const {showModal, imageDataUrl, newImageDataUrl, showViewTitleBanner} = this.state;
        let me = this;

        return (
            <Modal
                title={(
                    <span>
                        <Icon className={'info-text'} name={'info-circle'} style={{marginRight: '0.5em'}}/> 设置图谱的标题图片
                    </span>
                )}
                closable={false}
                closeIcon={''}
                destroyOnClose={true}
                visible={showModal}
                xfooter={[
                    <Button
                        key={'ok'}
                        type="primary"
                        block
                        onClick={this.handleOk}
                    >
                        确定
                    </Button>
                ]}
                onCancel={this.handleCancel}
                onOk={this.handleOk}
                okText={'确认'}
                cancelText={'取消'}
                onClick={e => e.stopPropagation()}
                bodyStyle={{padding: 0}}
            >
                <Row>
                    <Col style={{
                        height: 200,
                        backgroundColor: 'hsl(213, 20%, 15%)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        paddingLeft: 10,
                        paddingRight: 10
                    }}>
                        {showViewTitleBanner ?
                            newImageDataUrl ? (
                                <img src={newImageDataUrl} alt={viewInfo ? viewInfo.name : ''}
                                     style={{maxWidth: '100%', maxHeight: '100%'}}
                                />
                            ) : imageDataUrl ? (
                                <img src={imageDataUrl} alt={viewInfo ? viewInfo.name : ''}
                                     style={{maxWidth: '100%', maxHeight: '100%'}}
                                />
                            ) : (
                                <span style={{fontSize: '1.5rem', color: '#ccc'}}>
                                    {viewInfo ? viewInfo.name : ''}
                                </span>
                            ) : (
                                <span style={{fontSize: '1.5rem', color: '#ccc'}}>
                                    {viewInfo ? viewInfo.name : ''}
                                </span>
                            )

                        }

                    </Col>
                    <Col style={{padding: 0}}>
                        <Row>
                            <Col span={14} style={{padding: 15}}>
                                标题图片将覆盖文字标题，
                                <br/>
                                图片大小最大不可超过5M，
                                <br/>
                                建议使用深色或透明背景的图片。
                            </Col>
                            <Col span={10} style={{padding: '5px 0'}}>
                                <div style={{width: 150, margin: '0 auto'}}>
                                    <Button
                                        key={'switch'}
                                        // type="primary"
                                        block
                                        // size='small'
                                        style={{marginTop: 2, marginBottom: 2}}
                                        onClick={this.switchShowViewTitleBanner}
                                        // disabled={showViewTitleBanner}
                                    >
                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            width: '100%',
                                            height: '100%',
                                        }}>
                                            <img
                                                src="/assets/text.png"
                                                style={{
                                                    padding: 0,
                                                    margin: 0,
                                                    marginRight: 5,
                                                    maxWidth: 30,
                                                    height: 30
                                                }}
                                                alt="恢复文字标题"
                                            />
                                            恢复文字标题
                                        </div>
                                    </Button>
                                    <Upload
                                        key={'upload'}
                                        className={style['btn']}
                                        name={'file_from_container'}
                                        multiple={false}
                                        showUploadList={false}
                                        disabled={me.props.disabled}
                                        beforeUpload={me.cacheBanner}
                                    >
                                        <Button
                                            // type="primary"
                                            block
                                            // size='small'
                                            style={{marginTop: 2, marginBottom: 2}}
                                        >
                                            <div style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                width: '100%',
                                                height: '100%',
                                            }}>
                                                <img
                                                    src="/assets/select_image_file.png"
                                                    style={{
                                                        padding: 0,
                                                        margin: 0,
                                                        marginRight: 5,
                                                        width: 30,
                                                        height: 30
                                                    }}
                                                    alt="选取图片文件"
                                                />
                                                选取图片文件
                                            </div>

                                        </Button>
                                    </Upload>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Modal>
        );
    }
}

UploadBannerComponent.defaultProps = {
    disabled: true,
    bus: PB,
};

UploadBannerComponent.propTypes = {
    disabled: PropTypes.bool,
    networkRef: PropTypes.instanceOf(ViewDataProvider),
    viewId: PropTypes.string.isRequired,
    bus: PropTypes.instanceOf(SimplePB),
};

export default UploadBannerComponent;
