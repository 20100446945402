import React from 'react';
import PropTypes from 'prop-types';
import {Tooltip, message} from 'antd';

import PB, {SimplePB} from '@/libs/simplePB';

import {getNodeDisplayTitle, getNodeIcon} from '@/constants/vis.defaultDefine.1';

import Icon from '@/components/common/common.icon';

import style from '@/style/components/mainView/main.aiConsoleMessage.less';
import copy from 'copy-to-clipboard';

class MainAiConsoleMessageEdgeInfo extends React.PureComponent {

  copyToClipboard = text => {
    let result = copy(text, {
      message: '请按下 #{key} 复制文本。',
    });

    if (result) message.success('文本已复制到剪切板。');
  };

  render() {
    let me = this;

    const fromNodeTitle = getNodeDisplayTitle(me.props.fromNode);
    const toNodeTitle = getNodeDisplayTitle(me.props.toNode);

    return (
      <div className={style['body-frame']}>
        <div className={style['title-frame']}>
          <Tooltip
            title={'复制节点标题'}
            placement={'left'}
          >
            <a style={{marginRight: '0.55rem'}} onClick={() => me.copyToClipboard(fromNodeTitle)}>
              <Icon {...getNodeIcon(me.props.fromNode)} />
            </a>
          </Tooltip>
          <span className={style['title']}><span>{fromNodeTitle}</span></span>
        </div>
        <hr />
        <div className={style['second-title-frame']}>
          <Tooltip
            title={'复制节点标题'}
            placement={'left'}
          >
            <a style={{marginRight: '0.55rem'}} onClick={() => me.copyToClipboard(toNodeTitle)}>
              <Icon {...getNodeIcon(me.props.toNode)} />
            </a>
          </Tooltip>
          <span className={style['title']}><span>{toNodeTitle}</span></span>
        </div>
      </div>
    )
  }
}

MainAiConsoleMessageEdgeInfo.defaultProps = {
  bus: PB,
};

MainAiConsoleMessageEdgeInfo.propTypes = {
  bus: PropTypes.instanceOf(SimplePB),
  fromNode: PropTypes.object.isRequired,
  toNode: PropTypes.object.isRequired,
};

export default MainAiConsoleMessageEdgeInfo;