import React from 'react';
import PropTypes from 'prop-types';
import {Form, Input, Modal, Radio, Checkbox, Divider, message} from 'antd';
import copy from 'copy-to-clipboard';
import _ from 'lodash';

import PB, {SimplePB} from '@/libs/simplePB';

import {IconTypes} from '@/constants/common';

import Icon from '@/components/common/common.icon';

const Icons = [{
  name: 'icon-extended-feature',
  type: IconTypes.ICON_FONT
}, {
  name: 'edit',
  type: IconTypes.ANT_DESIGN
}, {
  name: 'unordered-list',
  type: IconTypes.ANT_DESIGN
}, {
  name: 'search',
  type: IconTypes.ANT_DESIGN
}, {
  name: 'star',
  type: IconTypes.ANT_DESIGN
}, {
  name: 'user',
  type: IconTypes.ANT_DESIGN
}, {
  name: 'team',
  type: IconTypes.ANT_DESIGN
}, {
  name: 'setting',
  type: IconTypes.ANT_DESIGN
}];

const IconMap = ((list) => {
  let result = {};
  list.forEach(i => result[`${i.type}-${i.name}`] = i);
  return result;
})(Icons);

class ExtendedActionSettingsEditModal extends React.Component {
  state = {
    idx: 0,
    name: '',
    nameValidateStatus: 'success',
    nameValidateHelp: '',
    description: '',
    descriptionValidateStatus: 'success',
    descriptionValidateHelp: '',
    iconName: 'icon-extended-feature',
    iconType: IconTypes.ICON_FONT,
    icon: '1-icon-extended-feature',
    iconValidateStatus: 'success',
    iconValidateHelp: '',
    url: '',
    urlValidateStatus: 'success',
    urlValidateHelp: '',
    userToken: false,
  };

  copyToClipboard = (text, title) => {
    let result = copy(text, {
      message: `请按下 #{key} 复制${title}。`,
    });

    if (result) message.success(`${title}已复制。`);
  };

  onNameChange = (name) => {
    let me = this, nameValidateStatus = 'success', nameValidateHelp = '';

    if (name === '') {
      nameValidateStatus = 'error';
      nameValidateHelp = '功能名称不可为空';
    } else if (name.length > 15) {
      nameValidateStatus = 'warning';
      nameValidateHelp = '功能名称建议不超过15个字符';
    }

    me.setState({name, nameValidateStatus, nameValidateHelp});
  };

  onIconChange = (icon) => {
    let me = this;

    me.setState({icon, iconName: IconMap[icon].name, iconType: IconMap[icon].type});
  };

  onDescriptionChange = (description) => {
    let me = this, descriptionValidateStatus = 'success', descriptionValidateHelp = '';

    if (description.length > 50) {
      descriptionValidateStatus = 'warning';
      descriptionValidateHelp = '功能描述建议不超过50个字符';
    }

    me.setState({description, descriptionValidateStatus, descriptionValidateHelp});
  };

  onUrlChange = (url) => {
    let me = this, urlValidateStatus = 'success', urlValidateHelp = '';

    if (url === '') {
      urlValidateStatus = 'error';
      urlValidateHelp = '功能链接不可为空';
    } else if (!/^((https?:)?\/)?\/.+/.test(url)) {
      urlValidateStatus = 'error';
      urlValidateHelp = '功能链接无效，请以http或https开头';
    } else if (!_.startsWith(url, 'https://')) {
      urlValidateStatus = 'warning';
      urlValidateHelp = '由于浏览器的安全设置，http开头的链接可能无法正常打开，建议使用https开头的链接';
    }

    me.setState({url, urlValidateStatus, urlValidateHelp});
  };

  onSave = () => {
    let me = this, settings = {
      name: me.state.name,
      iconName: me.state.iconName,
      iconType: me.state.iconType,
      url: me.state.url,
      description: me.state.description,
      userToken: me.state.userToken,
    };

    me.props.bus.emit('network', 'node.do_edit_extended_action_settings',
      {nodeId: me.props.nodeId, idx: me.props.idx, settings});
  };

  componentDidMount() {
    let me = this;

    if (me.props.visible) {
      me.setState({
        idx: me.props.idx,
        name: me.props.name,
        nameValidateStatus: 'success',
        nameValidateHelp: '',
        description: me.props.description,
        descriptionValidateStatus: 'success',
        descriptionValidateHelp: '',
        iconName: me.props.iconName,
        iconType: me.props.iconType,
        icon: `${me.props.iconType}-${me.props.iconName}`,
        iconValidateStatus: 'success',
        url: me.props.url,
        urlValidateStatus: 'success',
        urlValidateHelp: '',
        userToken: me.props.userToken,
      });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let me =this;

    if (!prevProps.visible && me.props.visible) {
      me.setState({
        idx: me.props.idx,
        name: me.props.name,
        nameValidateStatus: 'success',
        nameValidateHelp: '',
        description: me.props.description,
        descriptionValidateStatus: 'success',
        descriptionValidateHelp: '',
        iconName: me.props.iconName,
        iconType: me.props.iconType,
        icon: `${me.props.iconType}-${me.props.iconName}`,
        iconValidateStatus: 'success',
        url: me.props.url,
        urlValidateStatus: 'success',
        urlValidateHelp: '',
        userToken: me.props.userToken,
      });
    }
  }

  render() {
    let me = this;
    const {visible, doClose} = me.props;
    const formItemLayoutFull = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 2 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 22 },
      },
    };
    const saveEnabled = me.state.name !== '' && me.state.url !== '' && me.state.nameValidateStatus !== 'error'
      && me.state.descriptionValidateStatus !== 'error' && me.state.urlValidateStatus !== 'error'
      && me.state.iconValidateStatus !== 'error';

    return (
      <Modal
        closable={!me.props.processing}
        visible={visible}
        title={'扩展功能配置'}
        okText={'确定'}
        cancelText={'取消'}
        onCancel={doClose}
        onOk={me.onSave}
        okButtonProps={{loading: me.props.processing, disabled: !saveEnabled}}
        cancelButtonProps={{disabled: me.props.processing}}
        width={'40rem'}
      >
        <Form>
          <Form.Item
            label={'名称'}
            validateStatus={me.state.nameValidateStatus}
            help={me.state.nameValidateHelp}
            {...formItemLayoutFull}
          >
            <Input
              placeholder={'请输入扩展功能名称'}
              autoFocus={true}
              value={me.state.name}
              onChange={e => me.onNameChange(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            label={'图标'}
            validateStatus={me.state.iconValidateStatus}
            help={me.state.iconValidateHelp}
            {...formItemLayoutFull}
          >
            <Radio.Group
              value={me.state.icon}
              onChange={e => me.onIconChange(e.target.value)}
            >
              {Icons.map((icon, idx) => (
                <Radio.Button key={`r-btn-${idx}`} value={`${icon.type}-${icon.name}`}>
                  <Icon name={icon.name} type={icon.type} />
                </Radio.Button>
              ))}
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label={'链接'}
            validateStatus={me.state.urlValidateStatus}
            help={me.state.urlValidateHelp}
            {...formItemLayoutFull}
          >
            <Input.TextArea
              placeholder={'请输入扩展功能链接'}
              value={me.state.url}
              onChange={e => me.onUrlChange(e.target.value)}
              autoSize={{minRows: 1, maxRows: 5}}
              style={{marginTop: '5px'}}
            />
          </Form.Item>
          <Form.Item
            label={'描述'}
            validateStatus={me.state.descriptionValidateStatus}
            help={me.state.descriptionValidateHelp}
            {...formItemLayoutFull}
          >
            <Input.TextArea
              placeholder={'请输入扩展功能描述'}
              value={me.state.description}
              onChange={e => me.onDescriptionChange(e.target.value)}
              autoSize={{minRows: 2, maxRows: 5}}
              style={{marginTop: '5px'}}
            />
          </Form.Item>
          <Form.Item label={'高级'}{...formItemLayoutFull} help={'高级设置选项，如您没有特殊需求不建议修改'}>
            <Checkbox onChange={e => {me.setState({userToken: e.target.checked})}} checked={me.state.userToken}>
              共享用户登录信息
            </Checkbox>
          </Form.Item>
        </Form>
        <Divider />
        <div style={{opacity: 0.7}}>
          参考数据：<br />
          当前看板ID：{me.props.viewId}&nbsp;
          <a onClick={() => me.copyToClipboard(me.props.viewId, '看板ID')}>点击复制</a>
          <br />
          当前节点ID：{me.props.nodeId}&nbsp;
          <a onClick={() => me.copyToClipboard(me.props.nodeId, '节点ID')}>点击复制</a>
          <br />
        </div>
      </Modal>
    );
  }
}

const WrappedExtendedActionSettingsEditModal = Form.create()(ExtendedActionSettingsEditModal);

WrappedExtendedActionSettingsEditModal.defaultProps = {
  bus: PB,
};

WrappedExtendedActionSettingsEditModal.propTypes = {
  idx: PropTypes.number.isRequired,
  name: PropTypes.string,
  description: PropTypes.string,
  iconName: PropTypes.string,
  iconType: PropTypes.number,
  url: PropTypes.string,
  userToken: PropTypes.bool,
  bus: PropTypes.instanceOf(SimplePB),
  visible: PropTypes.bool,
  processing: PropTypes.bool,
  viewId: PropTypes.string,
  nodeId: PropTypes.string,
  doClose: PropTypes.func.isRequired,
};

export default WrappedExtendedActionSettingsEditModal;