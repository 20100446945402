import React from 'react';
import PropTypes from 'prop-types';
import {DatePicker, Radio, Col, Row} from 'antd';
import moment from 'moment';

import style from '@/style/common/microService/common.microService.less';

export const optionNames = {
  this_month: '本月',
  last_month: '上月',
  this_quarter: '本季度',
  last_quarter: '上季度',
  this_half_of_year: '本半年度',
  first_half_of_year: '上半年度',
  second_half_of_year: '下半年度',
  this_year: '本年度',
  custom: '自定义',
};

const getDurationText = type => {
  let today = moment().startOf('day'), start, end;

  switch (type) {
    case 'this_month':
      start = moment().startOf('month');
      return `${optionNames[type]}(${start.format('YYYY-MM')})`;
    case 'last_month':
      start = moment().startOf('month').subtract(1, 'month');
      return `${optionNames[type]}(${start.format('YYYY-MM')})`;
    case 'this_quarter':
      start = moment().startOf('quarter');
      return `${optionNames[type]}(${today.startOf('month').isSame(start) ? start.format('YYYY-MM') : `${start.format('YYYY-MM')} 至今`})`;
    case 'last_quarter':
      start = moment().startOf('quarter').subtract(1, 'quarter');
      end = moment().startOf('quarter').subtract(1, 'day');
      return `${optionNames[type]}(${start.format('YYYY-MM')} 至 ${end.format('YYYY-MM')})`;
    case 'this_half_of_year':
      if (today.month() < 6) {
        start = moment().startOf('year');
      } else {
        start = moment().startOf('year').add(6, 'month');
      }
      return `${optionNames[type]}(${today.startOf('month').isSame(start) ? start.format('YYYY-MM') : `${start.format('YYYY-MM')} 至今`})`;
    case 'first_half_of_year':
      start = moment().startOf('year');
      end = moment().startOf('year').add(5, 'month');
      return `${optionNames[type]}(${start.format('YYYY-MM')} 至 ${end.format('YYYY-MM')})`;
    case 'second_half_of_year':
      start = moment().startOf('year').add(6, 'month');
      end = moment().startOf('year').add(11, 'month');
      return `${optionNames[type]}(${start.format('YYYY-MM')} 至 ${end.format('YYYY-MM')})`;
    case 'this_year':
      start = moment().startOf('year');
      return `${optionNames[type]}(${start.format('YYYY年')})`;
    default:
      return '--';
  }
};

const getDuration = type => {
  let today = moment().endOf('day'), start, end;

  switch (type) {
    case 'this_month':
      start = moment().startOf('month');
      return {start: start.valueOf(), end: today.valueOf()};
    case 'last_month':
      start = moment().startOf('month').subtract(1, 'month');
      return {start: start.valueOf(), end: today.startOf('month').valueOf() - 1};
    case 'this_quarter':
      start = moment().startOf('quarter');
      return {start: start.valueOf(), end: today.valueOf()};
    case 'last_quarter':
      start = moment().startOf('quarter').subtract(1, 'quarter');
      end = moment().startOf('quarter');
      return {start: start.valueOf(), end: end.valueOf() - 1};
    case 'this_half_of_year':
      if (today.month() < 6) {
        start = moment().startOf('year');
      } else {
        start = moment().startOf('year').add(6, 'month');
      }
      return {start: start.valueOf(), end: today.valueOf()};
    case 'first_half_of_year':
      start = moment().startOf('year');
      end = moment().startOf('year').add(6, 'month');
      return {start: start.valueOf(), end: end.valueOf() - 1};
    case 'second_half_of_year':
      start = moment().startOf('year').add(6, 'month');
      end = moment().endOf('year');
      return {start: start.valueOf(), end: end.valueOf()};
    case 'this_year':
      start = moment().startOf('year');
      return {start: start.valueOf(), end: today.valueOf()};
    default:
      return {start: 0, end: 0};
  }
};

const getOptions = (me, type) => {
  switch (type) {
    case 'custom':
      return (
        <React.Fragment>
          <Radio className={style['parameter-duration-item']} value={type}>
            {optionNames[type]}：
          </Radio>
          <DatePicker.RangePicker
            onChange={me.props.onCustomDurationSelected}
            value={me.props.customDuration || me.props.defaultCustomDuration}
            allowClear={false}
            disabled={me.props.locked}
          />
        </React.Fragment>
      );
    default:
      return (
        <Radio
          className={style['parameter-duration-item']}
          value={type}
        >
          {optionNames[type]}
        </Radio>
      );
  }
}

class MicroServicePanelParameterDuration extends React.PureComponent {
  resetDuration = () => {
    let me = this;

    if (me.props.locked) {
      return;
    }

    if ((me.props.value || me.props.defaultValue) === 'custom') {
      me.props.onDurationChange({
        start: (me.props.customDuration || me.props.defaultCustomDuration)[0].startOf('day').valueOf(),
        end: (me.props.customDuration || me.props.defaultCustomDuration)[1].endOf('day').valueOf(),
      });
    } else {
      me.props.onDurationChange(getDuration(me.props.value || me.props.defaultValue));
    }

    me.props.onShortTextChange((me.props.value || me.props.defaultValue) === 'custom'
      ? `${
        moment((me.props.customDuration || me.props.defaultCustomDuration)[0]).format('YYYY-MM-DD')
      } 至 ${
        moment((me.props.customDuration || me.props.defaultCustomDuration)[1]).format('YYYY-MM-DD')
      }` : getDurationText(me.props.value || me.props.defaultValue));
  };

  componentDidMount() {
    let me = this;

    if (!me.props.locked) {
      me.props.onChange({target: {value: (me.props.value || me.props.defaultValue)}});
      me.props.onCustomDurationSelected(me.props.customDuration || me.props.defaultCustomDuration);
    }
    me.resetDuration();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let me = this;

    if (prevProps.value !== me.props.value || !_.isEqual(prevProps.customDuration, me.props.customDuration)) {
      me.resetDuration();
    }

    if (!me.props.locked && prevProps.locked !== me.props.locked) {
      me.props.onChange({target: {value: (me.props.value || me.props.defaultValue)}});
    } else if (!me.props.locked) {
      if (me.props.value === undefined && me.props.defaultValue) {
        me.props.onChange({target: {value: me.props.defaultValue}});
      }
      if (me.props.customDuration === undefined && me.props.defaultCustomDuration) {
        me.props.onCustomDurationSelected(me.props.defaultCustomDuration);
      }
    }
  }

  render() {
    let me = this;

    return (
      <Radio.Group
        className={style['parameter-duration-group']}
        onChange={me.props.onChange}
        value={me.props.value || me.props.defaultValue}
        disabled={me.props.locked}
      >
        <Row>
          {
            me.props.enabledOptions.map(type => (
              <Col key={`c-duration-${type}`} span={type === 'custom' ? 18 : me.props.optionSpan}>
                {getOptions(me, type)}
              </Col>
            ))
          }
        </Row>
      </Radio.Group>
    );
  }
}

MicroServicePanelParameterDuration.defaultProps = {
  optionSpan: 6,
};

MicroServicePanelParameterDuration.propTypes = {
  enabledOptions: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onCustomDurationSelected: PropTypes.func.isRequired,
  onDurationChange: PropTypes.func.isRequired,
  onShortTextChange: PropTypes.func.isRequired,
  locked: PropTypes.bool.isRequired,
  defaultValue: PropTypes.string.isRequired,
  defaultCustomDuration: PropTypes.array.isRequired,
  value: PropTypes.string,
  customDuration: PropTypes.array,
  optionSpan: PropTypes.number,
};

export default MicroServicePanelParameterDuration;