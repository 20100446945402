import React from 'react';
import PropTypes from "prop-types";
import {Button, Collapse, Form, List, Modal, Radio, Tooltip} from 'antd';

import style from '@/style/components/main.edgeInfo.less';
import {
  getNodeDisplayTitle,
  NODE_TYPE_COMPANY,
  NODE_TYPE_PAPER,
  NODE_TYPE_PATENT,
  NODE_TYPE_TALENT,
  NODE_TYPE_TEXT,
  TYPE_FIELD_NAME,
} from "@/constants/vis.defaultDefine.1";
import CompanyNodeSummaryInfo from "@/components/mainView/nodeInfo/main.nodeInfo.company.summary";
import TalentNodeSummaryInfo from "@/components/mainView/nodeInfo/main.nodeInfo.talent.summary";
import PaperNodeSummaryInfo from "@/components/mainView/nodeInfo/main.nodeInfo.paper.summary";
import PatentNodeSummaryInfo from "@/components/mainView/nodeInfo/main.nodeInfo.patent.summary";
import CommonNodeSummaryInfo from "@/components/mainView/nodeInfo/main.nodeInfo.common.summary";
import TextNodeSummaryInfo from "@/components/mainView/nodeInfo/main.nodeInfo.text.summary";
import ViewDataProvider from '@/components/common/dataProvider/common.dataProvider.view';
import {EdgeEvents, REMOVE_FROM_GRAPH} from "@/libs/view/network/events";
import Icon from "@/components/common/common.icon";
import PB from "@/libs/simplePB";
import {IconTypes} from "@/constants/common";

class EdgeInfoPanel extends React.Component {
  state = {
    edgeId: undefined,
    processing: false,
    edgeStyleSmooth: 1,
  };

  edgeInfo = {};

  connectedNodes = [];

  componentDidMount () {
    let me = this;

    this.edgeInfo = {};
    this.connectedNodes = [];
    if (this.props.edgeId) {
      me.refreshLocalEdgeInfo();
      let meta = this.edgeInfo ? this.edgeInfo.meta : undefined;
      this.setState({edgeId: this.props.edgeId, processing: false,
        edgeStyleSmooth: (meta && meta.smooth !== undefined) ? meta.smooth : 1});
    }
    if (this.props.networkRef) {
      this.props.networkRef.subscribe(this, EdgeEvents.REMOVING, edgeSimpleInfoList => {
        if (this.edgeInfo) {
          edgeSimpleInfoList.forEach(({fromNodeId, toNodeId}) => {
            if ((this.edgeInfo.from === fromNodeId && this.edgeInfo.to === toNodeId) ||
              (this.edgeInfo.from === toNodeId && this.edgeInfo.to === fromNodeId)) {

              me.setState({processing: true});
            }
          });
        }
      }).subscribe(this, EdgeEvents.UPDATED, (edgeIdList, edgeInfoList) => {
        // noinspection JSBitwiseOperatorUsage
        if (this.edgeInfo) {
          edgeInfoList.forEach(({from, to, meta}) => {
            if ((this.edgeInfo.from === from && this.edgeInfo.to === to) ||
              (this.edgeInfo.from === to && this.edgeInfo.to === from)) {

              me.setState({edgeStyleSmooth: (meta && meta.smooth !== undefined) ? meta.smooth : 1});
            }
          });
        }
      }).subscribe(this, EdgeEvents.REMOVED, (type, edgeInfoList) => {
        // noinspection JSBitwiseOperatorUsage
        if (this.edgeInfo && (type & REMOVE_FROM_GRAPH)) {
          edgeInfoList.forEach(({from, to}) => {
            if ((this.edgeInfo.from === from && this.edgeInfo.to === to) ||
              (this.edgeInfo.from === to && this.edgeInfo.to === from)) {

              me.setState({processing: false});
            }
          });
        }
      }).subscribe(this, EdgeEvents.REMOVE_FAILED, edgeSimpleInfoList => {
        if (this.edgeInfo) {
          edgeSimpleInfoList.forEach(({fromNodeId, toNodeId}) => {
            if ((this.edgeInfo.from === fromNodeId && this.edgeInfo.to === toNodeId) ||
              (this.edgeInfo.from === toNodeId && this.edgeInfo.to === fromNodeId)) {

              me.setState({processing: false});
            }
          });
        }
      });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.edgeId !== this.state.edgeId) {
      this.refreshLocalEdgeInfo();
      let meta = this.edgeInfo ? this.edgeInfo.meta : undefined;
      this.setState({edgeId: this.props.edgeId, processing: false,
        edgeStyleSmooth: (meta && meta.smooth !== undefined) ? meta.smooth : 1});
    }
  }

  componentWillUnmount() {
    this.props.networkRef && this.props.networkRef.unSubscribe(this);
  }

  refreshLocalEdgeInfo = () => {
    this.edgeInfo = {};
    this.connectedNodes = [];

    if (this.props.networkRef) {
      this.edgeInfo = this.props.networkRef.getEdge(this.props.edgeId);
      this.connectedNodes = this.props.networkRef.getConnectedNodes(this.props.edgeId);
    } else {
      this.edgeInfo = this.props.getEdgeInfo(this.props.edgeId);
      this.connectedNodes = this.props.getEdgeConnectedNodes(this.props.edgeId);
    }
    // 获取失败且允许忽略失败时，尝试从传入参数获取数据
    if (this.props.networkRef && !this.edgeInfo && this.props.ignoreLoadError) {
      this.edgeInfo = this.props.getEdgeInfo(this.props.edgeId);
      this.connectedNodes = this.props.getEdgeConnectedNodes(this.props.edgeId);
    }
  };

  getNodeContent = (node) => {
    const nodeTypeFieldName = this.props.typeFieldName;
    switch (node[nodeTypeFieldName]) {
      case NODE_TYPE_COMPANY:
        return <CompanyNodeSummaryInfo node={node} permanent={node.status === 1}/>;
      case NODE_TYPE_TALENT:
        return <TalentNodeSummaryInfo node={node} permanent={node.status === 1}/>;
      case NODE_TYPE_PAPER:
        return <PaperNodeSummaryInfo node={node} permanent={node.status === 1}/>;
      case NODE_TYPE_PATENT:
        return <PatentNodeSummaryInfo node={node} permanent={node.status === 1}/>;
      case NODE_TYPE_TEXT:
        return (
          <TextNodeSummaryInfo
            /*networkRef={this.props.networkRef}*/
            node={node}
            permanent={node.status === 1}
          />
        );
      default:
        return (
          <CommonNodeSummaryInfo
            /*networkRef={this.props.networkRef}*/
            node={node}
            permanent={node.status === 1}
          />
        );
    }
  };

  onRemoveLinkClicked = () => {
    let me = this;

    PB.emit('network', 'edge.on_remove', {edge: me.edgeInfo});
  };

  onSaveLinkClicked = () => {
    // noinspection JSIgnoredPromiseFromCall
    this.props.networkRef && this.props.networkRef.addRelation(this.edgeInfo.from, this.edgeInfo.to, 'user_save_edge');
  };

  onEdgeStyleSmoothChange = e => {
    let me = this, meta = {...(me.edgeInfo.meta || {})};
    meta.smooth = e.target.value;

    PB.emit('network', 'edge.do_edit', {id: me.edgeInfo.id, meta});
  };

  render() {
    let connected = this.edgeInfo && this.edgeInfo.status === 1;
    let me = this;
    const {readonly} = me.props;
    const formItemLayout = {
      labelCol: {
        xs: {span: 6},
        sm: {span: 3},
      },
      wrapperCol: {
        xs: {span: 38},
        sm: {span: 19},
      },
    };
    // noinspection RequiredAttributes
    return (
      <Collapse
        bordered={false} activeKey={'edge-info'}
        className={`${style['edge-info-frame']} dark-theme scrollbar scrollbar-none`}
        style={{borderRadius: 0}}
      >
        <Collapse.Panel
          header={
            <span>
              相连节点
              {
                (!readonly && me.props.networkRef) ? (
                  connected ? (
                    <Tooltip placement="bottomRight" title='删除相连关系' arrowPointAtCenter={true}>
                      <Button
                        shape="circle"
                        size={'small'}
                        type={'danger'}
                        onClick={(e) => {
                          e.stopPropagation();
                          me.onRemoveLinkClicked();
                        }}
                        loading={this.state.processing}
                        style={{float: 'right'}}
                      >
                        <Icon name={'icon-scissors-tool'} type={IconTypes.ICON_FONT} />
                      </Button>
                    </Tooltip>
                  ) : (
                    <Tooltip placement="bottomRight" title='保留相连关系' arrowPointAtCenter={true}>
                      <Button
                        shape="circle"
                        size={'small'}
                        type={'success'}
                        onClick={(e) => {
                          e.stopPropagation();
                          me.onSaveLinkClicked();
                        }}
                        loading={this.state.processing}
                        style={{float: 'right'}}
                      >
                        <Icon name={'check'} />
                      </Button>
                    </Tooltip>
                  )
                ) : null
              }
            </span>
          }
          key={'edge-info'}
          style={{flexBasis: 'auto', borderRadius: 0}}>

          <List
            itemLayout="horizontal"
            split={false}
            size="small"
            locale={{emptyText: '没有相连接的产业对象'}}
            dataSource={this.connectedNodes}
            renderItem={node => <List.Item>{this.getNodeContent(node)}</List.Item>}
          />
        </Collapse.Panel>
        <div className={`${style['edge-edit-panel']} dark-theme`}>
          <Form.Item label={'线型'} {...formItemLayout}>
            <Radio.Group onChange={me.onEdgeStyleSmoothChange} value={me.state.edgeStyleSmooth} size={'small'}>
              <Radio.Button value={1}>曲线</Radio.Button>
              <Radio.Button value={0}>直线</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </div>
      </Collapse>
    );
  }
}

EdgeInfoPanel.defaultProps = {
  getEdgeInfo: () => undefined,
  getEdgeConnectedNodes: () => [],
  typeFieldName: TYPE_FIELD_NAME,
  ignoreLoadError: false,
};

EdgeInfoPanel.propTypes = {
  edgeId: PropTypes.string.isRequired,
  getEdgeInfo: PropTypes.func,
  getEdgeConnectedNodes: PropTypes.func,
  typeFieldName: PropTypes.string,
  networkRef: PropTypes.instanceOf(ViewDataProvider),
  ignoreLoadError: PropTypes.bool,
};

export default EdgeInfoPanel;