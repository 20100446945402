import _ from 'lodash';

import {ChinaADCDisplayTitleMap, ChinaADCStatisticsType, findChinaADC} from '@/components/common/common.functions';
import {distinctFn} from "@/components/common/view/statistics/node/common.view.statistics.node.logic.common";

export const statisticsByArea = ({nodes, distinct, getConditionFn, level = ChinaADCStatisticsType.CITY, sort, limit, cb}) => {
  let statisticsFn = (nodes, cb) => {
    // statistics
    let result = {}, pos = 0, batch, workFn = () => {
      batch = 0;
      while (batch < 500 && pos < nodes.length) {
        let n = nodes[pos];
        let condition = getConditionFn(n, _);
        if (!_.isArray(condition)) {
          condition = [condition];
        }
        let conditionKeys = {};
        condition.forEach(c => {
          let areaList = undefined;
          if (n && n.meta && n.meta['china_adc'] && _.isArray(n.meta['china_adc'])) {
            let info = n.meta['china_adc'].find(item => item && c === item.c);
            if (info && info.r && info.v === 'F03') {
              areaList = info.r;
            }
          }
          if (!areaList) {
            areaList = findChinaADC(c, true);
            n.meta = n.meta || {};
            n.meta['china_adc'] = n.meta['china_adc'] || [];
            n.meta['china_adc'].push({
              c,
              v: 'F03',
              r: areaList,
            });
          }

          areaList.forEach(areaInfo => {
            let key = areaInfo.adc, formattedKey = key.substr(0, level) + _.repeat('0', 6 - level);

            if (level > ChinaADCStatisticsType.PROVINCE) {
              if (key.substr(level - 2, 2) === '00') return;
            }
            conditionKeys[formattedKey] = true;
          });
        });

        Object.keys(conditionKeys).forEach(key => {
          if (!result[key]) {
            let tmpLevel = level - 2,
              labels = [ChinaADCDisplayTitleMap[level][level === ChinaADCStatisticsType.DISTRICT ? 'fullName' : 'shortName'][key]];
            while (tmpLevel > 0) {
              let label = ChinaADCDisplayTitleMap[tmpLevel].shortName[key.substr(0, tmpLevel) + _.repeat('0', 6 - tmpLevel)];
              if (labels[labels.length - 1] !== label) {
                labels.push(label);
              }
              tmpLevel -= 2;
            }
            labels.reverse();
            result[key] = {
              label: labels.join(' / '),
              rate: 0,
              nodes: [],
            };
          }

          result[key].nodes.push(n);
          result[key].rate++;
        });

        batch++;
        pos++;
      }
      if (pos < nodes.length) {
        setTimeout(workFn, 5);
      } else {
        cb(result);
      }
    };
    workFn();
  };

  distinctFn({nodes, distinct, cb: (nodes) => {
    statisticsFn(nodes, (result) => {
      let resultArray;

      // sort
      if (sort) {
        let asc = false;
        if (`${sort}`.toLowerCase() === 'asc') {
          asc = true;
        }
        resultArray = Object.values(result).sort((a, b) => {
          return asc ? a.nodes.length - b.nodes.length : b.nodes.length - a.nodes.length;
        }).map(((value, index) => ({...value, pos: index + 1})));
      } else {
        resultArray = Object.values(result).map(((value, index) => ({...value, pos: index + 1})));
      }

      // limit
      if (limit && limit > 0) {
        resultArray = resultArray.slice(0, parseInt(limit));
      }

      cb(resultArray);
    });
  }});
};