import React, { Component } from 'react';
import intl from 'react-intl-universal';
class IntlTranslation extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() {
        let val = intl.get(this.props.intlKey);
        return ( 
            Array.isArray(val)?<span>{val.map((line, idx) => (
                <span key={`ln-${idx}`}>{line}<br /></span>
              ))}</span>
            :<span>{val}</span>
         );
    }
}
 
export default IntlTranslation;