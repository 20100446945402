import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Radio, Form, Select, DatePicker, Badge, Tag } from 'antd';
import moment from 'moment';

import PB, {SimplePB} from "@/libs/simplePB";

import style from '@/style/common/node/common.node.todo.less';
import Icon from '@/components/common/common.icon';

const titleText = {
  'create': '创建待办事项',
  'update': '修改待办事项',
  'view': '查看待办事项',
};

class NodeTodoDetailModal extends React.Component {
  threeAfterToday = moment().startOf('day').add(3, 'days');

  state = {
    priority: 0,
    assignTo: undefined,
    deadline: this.threeAfterToday.format('YYYY-MM-DD 23:59:59'),
    deadlineMoment: this.threeAfterToday,

    processing: false,

    deadlineOpen: false,

    standardMoment: undefined,
  };



  onCreate = () => {
    let me = this;

    me.setState({processing: true}, () => {
      me.props.bus.emit('node_todo', 'todo.do_create',
        {task: {...me.props.task, ...me.state}, cb: success => {me.setState({processing: false},
            () => {success && me.props.onClose()})}});
    });
  };

  onUpdate = () => {
    let me = this;

    me.setState({processing: true}, () => {
      me.props.bus.emit('node_todo', 'todo.do_update',
        {task: {...me.props.task, ...me.state}, cb: success => {me.setState({processing: false},
            () => {success && me.props.onClose()})}});
    });
  };

  onClose = () => {
    let me = this;

    me.props.onClose();
  };

  getShortcutDate = () => {
    const me = this, weekName = ['周日', '周一', '周二', '周三', '周四', '周五', '周六'];
    let temp = [];
    // 今天
    const today = moment();
    temp.push({
      id: 0,
      text: `今天`,
      date: today.format('YYYY-MM-DD'),
    });
    // 明天
    const tomorrow = moment(today).startOf('day').add(1, 'days');
    temp.push({
      id: temp.length,
      text: `明天`,
      date: tomorrow.format('YYYY-MM-DD'),
    });
    // 三天后
    const threeAfterToday = moment(today).startOf('day').add(3, 'days');
    temp.push({
      id: temp.length,
      text: `三天后`,
      date: threeAfterToday.format('YYYY-MM-DD'),
    });
    const beforeThisSunday = moment(today).startOf('day').subtract(moment(today).day(), 'days');
    const thisSunday = moment(today).startOf('day').add(7 - moment(today).day(), 'days');
    // const afterThisSunday = moment(today).startOf('day').add(7 - moment(today).day() + 7, 'days');
    const thisWednesday = moment(beforeThisSunday).startOf('day').add(3, 'days');
    const thisFriday = moment(beforeThisSunday).startOf('day').add(5, 'days');
    const nextMonday = moment(thisSunday).startOf('day').add(1, 'days');
    const nextWednesday = moment(thisSunday).startOf('day').add(3, 'days');
    const nextFriday = moment(thisSunday).startOf('day').add(5, 'days');

    if (today.day() === 1 ) {
      temp.push({
        id: temp.length,
        text: `本${weekName[thisWednesday.day()]}`,
        date: thisWednesday.format('YYYY-MM-DD'),
      });
      temp.push({
        id: temp.length,
        text: `本${weekName[thisFriday.day()]}`,
        date: thisFriday.format('YYYY-MM-DD'),
      });
    } else if (today.day() === 2 || today.day() === 3) {
      temp.push({
        id: temp.length,
        text: `本${weekName[thisFriday.day()]}`,
        date: thisFriday.format('YYYY-MM-DD'),
      });
    }

    temp.push({
      id: temp.length,
      text: `下${weekName[nextMonday.day()]}`,
      date: nextMonday.format('YYYY-MM-DD'),
    });
    temp.push({
      id: temp.length,
      text: `下${weekName[nextWednesday.day()]}`,
      date: nextWednesday.format('YYYY-MM-DD'),
    });

    if (temp.length < 6) {
      temp.push({
        id: temp.length,
        text: `下${weekName[nextFriday.day()]}`,
        date: nextFriday.format('YYYY-MM-DD'),
      });
    }

    me.setState({
      standardMoment: temp,
    })
  };

  componentDidMount() {
    let me = this;
    if (me.props.visible && me.props.action !== 'create') {
      me.setState({
        priority: me.props.task['priority'],
        assignTo: me.props.task['assignTo'],
        deadline: me.props.task['deadline'],
        deadlineMoment: moment(me.props.task['deadline']),
        processing: false,
      });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let me = this;

    if (me.props.visible && !prevProps.visible) {
      this.getShortcutDate();
      if (me.props.action === 'create') {
        me.setState({
          priority: 0,
          assignTo: undefined,
          deadline: this.threeAfterToday.format('YYYY-MM-DD 23:59:59'),
          deadlineMoment: this.threeAfterToday,
          processing: false,
        });
      } else {
        me.setState({
          priority: me.props.task['priority'],
          assignTo: me.props.task['assignTo'],
          deadline: me.props.task['deadline'],
          deadlineMoment: moment(me.props.task['deadline']),
          processing: false,
        });
      }
    }
  }

  render() {
    let me = this;
    const formItemLayout = {
      labelCol: { span: 5 },
      wrapperCol: { span: 18 },
    };

    return (
      <Modal
        title={titleText[me.props.action]}
        visible={me.props.visible}
        centered={true}
        zIndex={1001}
        onCancel={me.onClose}
        width={'33rem'}
        footer={[
          me.props.action !== 'view' ? (
            <Button key={'cancel'} style={{marginRight: '8px'}} onClick={me.onClose}>取消</Button>
          ) : null,
          me.props.action === 'create' ? (
            <Button type={'primary'} key={'create'} style={{marginRight: '8px'}} onClick={me.onCreate}>创建</Button>
          ) : null,
          me.props.action === 'update' ? (
            <Button type={'primary'} key={'update'} style={{marginRight: '8px'}} onClick={me.onUpdate}>修改</Button>
          ) : null,
          me.props.action === 'view' ? (
            <Button key={'close'} style={{marginRight: '8px'}} onClick={me.onClose}>关闭</Button>
          ) : null,
        ]}
      >
        <Form.Item label={'节点名称'} {...formItemLayout}>{me.props.task ? me.props.task['vrDisplayText'] : ''}</Form.Item>
        <Form.Item label={'紧急程度'} {...formItemLayout}>
          <Radio.Group
            value={me.state.priority}
            onChange={e => {
              if (me.props.action !== 'view') {
                me.setState({priority: e.target.value});
              }
            }}
            disabled={me.state.processing}
            size={'small'}
            className={style[`priority-radio-group-${me.state.priority}`]}
          >
            <Radio.Button value={0} key={'normal'}>一般</Radio.Button>
            <Radio.Button value={1} key={'important'}>紧急</Radio.Button>
            <Radio.Button value={2} key={'urgent'}>特急</Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item label={'待办成员'} {...formItemLayout}>
          <Select
            value={me.state.assignTo}
            style={{width: 'calc(46px + 6.5rem)'}}
            onChange={assignTo => {
              if (me.props.action !== 'view') {
                me.setState({assignTo});
              }
            }}
          >
            {me.props.memberList.sort((a, b) => a.nick.localeCompare(b.nick)).map(member => (
              <Select.Option value={member.userId} key={`m-${member.userId}`}>{member.nick}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label={'截止日期'} {...formItemLayout} style={{marginBottom: 0}}>
          <DatePicker
            style={{width: 'calc(46px + 6.5rem)'}}
            value={me.state.deadlineMoment}
            onChange={(m, s) => {
              if (me.props.action !== 'view') {
                me.setState({deadlineMoment: m, deadline: s === '' ? undefined : s + ' 23:59:59'});
              }
            }}
            showToday={false}
            open={me.state.deadlineOpen}
            onOpenChange={deadlineOpen => {
              if (me.props.action !== 'view') {
                me.setState({deadlineOpen});
              }
            }}
          />
          <div style={{paddingTop: '6px', lineHeight: '32px'}} hidden={me.props.action === 'view'}>
            {
              me.state.standardMoment && me.state.standardMoment.map((item,idx) => (
                <div key={`d-${idx}`} style={{ display: 'inline-block', width: '155px'}}>
                  <Tag style={{cursor: 'pointer', marginRight: 0}}
                       color={ me.state.deadlineMoment && (moment(me.state.deadlineMoment).format('YYYY-MM-DD') === item.date) ? 'blue' : ''}
                       onClick={() => {
                         me.setState({
                           deadlineOpen: false,
                           deadlineMoment: moment(item.date),
                           deadline: moment(item.date).format('YYYY-MM-DD 23:59:59'),
                         });
                       }}
                  >
                    <Icon name={'calendar'} style={{marginRight: '6px'}}/>
                    {item.text} {item.date}
                  </Tag>
                </div>
              ))
            }
          </div>
        </Form.Item>
      </Modal>
    );
  }
}

NodeTodoDetailModal.defaultProps = {
  bus: PB,
};

NodeTodoDetailModal.propTypes = {
  bus: PropTypes.instanceOf(SimplePB),
  task: PropTypes.object,
  action: PropTypes.string.isRequired,
  memberList: PropTypes.array.isRequired,
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default NodeTodoDetailModal;