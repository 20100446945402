import React from 'react';
import PropTypes from 'prop-types';
import {Button, Modal, Input, List, Popover, message, Tooltip, Checkbox} from "antd";
import PB, {SimplePB} from "@/libs/simplePB";

import style from '@/style/common/view/common.view.teamwork.memberFindModal.less';
import UserAvatar from "react-user-avatar";
import {AvatarColors} from "@/constants/common";
import {getToken, REQUEST_BASE} from "@/utils/HttpUtil";
import Icon from "@/components/common/common.icon";
import {getNodeIcon, NODE_TYPE_TEXT} from '@/constants/vis.defaultDefine.1';

class TeamworkMemberFindModal extends React.PureComponent {
  state = {
    doSearching: false,
    searchText: '',
    checkValueLists: [],
    chechValueChanger: false
  };

  searchInput = undefined;

  searchSuccessCb = userList => {
    let me = this;

    if (me.state.doSearching) {
      me.setState({
        doSearching: false,
      }, () => {
        if (userList && userList.length > 0) {
          const userInfo = me.state.searchText.includes('@') ? {...userList[0], email: me.state.searchText} :
            {...userList[0], accessToken: me.state.searchText};
          let existedUserIdList = me.props.userList.map(user => user.userId);
          if (existedUserIdList.includes(userInfo.userId)) {
            message.info('您输入的用户已在协作成员列表中。');
          } else {
            let addUserIds = [userInfo.userId];
            let checkUserLists = [{...userInfo, addFlag: true}];
            if(me.state.checkValueLists && me.state.checkValueLists.length>0){
              me.state.checkValueLists.map(userId => {
                if (!existedUserIdList.includes(userId) && userId!=userInfo.userId) {
                  addUserIds.push(userId);
                }
              })
              me.props.recommendation.map(user => {
                if(me.state.checkValueLists.includes(user.userId) && user.userId!=userInfo.userId){
                  if(existedUserIdList.includes(user.userId)){
                    checkUserLists.push({...user, addFlag: false});
                  }else{
                    checkUserLists.push({...user, addFlag: true});
                  }
                }
              })
            }

            Modal.confirm({
              title: '信息确认',
              icon: null,
              content: (
                <List
                  dataSource={checkUserLists}
                  className={style['modal-content']}
                  renderItem={user => (
                    <List.Item style={{opacity:user.addFlag?1:0.5}}>
                      <List.Item.Meta
                        avatar={(
                          <div
                            className={`${style['avatar']} ${user.picId ? '' : style['avatar-default-icon']}`}
                          >
                            <UserAvatar
                              size={'var(--avatar-size)'}
                              name={user.nick}
                              src={user.picId ? `${REQUEST_BASE}/user/user/file/${user.picId}?Authorization=${getToken()}` : undefined}
                              colors={AvatarColors}
                            />
                          </div>
                        )}
                        title={user.nick}
                        description={user.userId==userInfo.userId?(
                          <span>注册时间：{user['gmtCreate'] ? user['gmtCreate'] : '--'}</span>
                        ):(
                          <span>{user.addFlag?'待添加':'已添加'}</span>
                        )}
                      />
                    </List.Item>
                  )}
                />
              ),
              okText: '加入协作',
              cancelText: '取消',
              onOk: () => {
                me.props.bus.once(me, 'teamwork', 'member.add.success', me.props.onClose);
                me.onAddMemberLists(addUserIds);
              },
            });
          }
        } else {
          message.error('没有找到相关用户，请确认信息无误。');
          if (this.searchInput) {
            this.searchInput.focus();
          }
        }
      });
    }
  };

  searchFailedCb = error => {
    this.setState({
      doSearching: false,
    }, () => {
      if (error['code'] === 28001) {
        message.error('没有找到相关用户，请确认信息无误。');
      }
      console.log('查找用户失败了，错误：', error);
      if (this.searchInput) {
        this.searchInput.focus();
      }
    });
  };

  checkSuccessCb = () => {
    let me = this;
    let addUserIds = [];
    let allIncludeFlag = true;
    let existedUserIdList = me.props.userList.map(user => user.userId);
    me.state.checkValueLists.map(userId => {
      if (!existedUserIdList.includes(userId)) {
        allIncludeFlag = false;
        addUserIds.push(userId);
      }
    })

    if (allIncludeFlag==true) {
      message.info('所选用户已在协作成员列表中。');
    } else {
      let checkUserLists = [];
      me.props.recommendation.map(user => {
        if(me.state.checkValueLists.includes(user.userId)){
          if(existedUserIdList.includes(user.userId)){
            checkUserLists.push({...user, addFlag: false});
          }else{
            checkUserLists.push({...user, addFlag: true});
          }
        }
      })
      if(checkUserLists.length>0){
          Modal.confirm({
              title: '信息确认',
              icon: null,
              content: (
                <List
                  dataSource={checkUserLists}
                  className={style['modal-content']}
                  renderItem={user => (
                    <List.Item style={{opacity:user.addFlag?1:0.5}}>
                      <List.Item.Meta
                        avatar={(
                          <div
                            className={`${style['avatar']} ${user.picId ? '' : style['avatar-default-icon']}`}
                          >
                            <UserAvatar
                              size={'var(--avatar-size)'}
                              name={user.nick}
                              src={user.picId ? `${REQUEST_BASE}/user/user/file/${user.picId}?Authorization=${getToken()}` : undefined}
                              colors={AvatarColors}
                            />
                          </div>
                        )}
                        title={user.nick}
                        description={(
                          <span>{user.addFlag?'待添加':'已添加'}</span>
                        )}
                      />
                    </List.Item>
                  )}
                />
              ),
              okText: '加入协作',
              cancelText: '取消',
              onOk: () => {
                me.props.bus.once(me, 'teamwork', 'member.add.success', me.props.onClose);
                me.onAddMemberLists(addUserIds);
              },
          });
      }
    }
  }

  onSearch = () => {
    let me = this;

    if (me.state.searchText && me.state.searchText !== '') {
      me.setState({doSearching: true}, () => {
        const query = me.state.searchText.includes('@') ? {email: me.state.searchText} :
          {accessToken: me.state.searchText};
        me.props.bus.emit('teamwork', 'member.find.do',
          {query, success: me.searchSuccessCb, failed: me.searchFailedCb});
      });
    }else if(me.state.checkValueLists && me.state.checkValueLists.length>0){
      me.checkSuccessCb();
    } else {
      me.setState({doSearching: false});
    }
  };

  onAddMember = userInfo => {
    this.props.bus.emit('teamwork', 'member.add.do',
      {viewId: this.props.viewId, userInfo});
  };

  onAddMemberLists = userLists => {
    let me = this;
    me.onAddMember({userId:userLists[0],accessToken:me.props.userInfo.accessToken});
    userLists.splice(0,1);
    userLists.length>0 && setTimeout(() => {
      me.onAddMemberLists(userLists);
    },10);
  };
  
  checkValue = user => {
    let checkValueLists = this.state.checkValueLists;
    let userLists = this.state.userLists;
    let checkIndex = checkValueLists.indexOf(user.userId);
    if(checkIndex==-1){
      checkValueLists.push(user.userId);
    }else{
      checkValueLists.splice(checkIndex, 1);
    }
    this.setState({checkValueLists,chechValueChanger:!this.state.chechValueChanger});
  };

  afterModalClose = () => {
    this.setState({
      doSearching: false,
      searchText: '',
    })
  };

  render() {
    let me = this;

    return (
      <Modal
        title={<div><Icon name="edit" style={{color: '#a5a5a5', marginRight: 6}}/>添加协作成员</div>}
        className={style['container']}
        style={{ top: '32vh' }}
        footer={[
          (
            <Popover
              key={'tip'}
              placement={'bottomLeft'}
              content={(
                <div>
                  <span>第一步：</span><br />
                  <img alt={'点击主界面左上角的头像，打开基本信息对话框。'} src={'/assets/access_token_1.png'}/><br />
                  <br />
                  <span>第二步：</span><br />
                  <img alt={'第一行显示的即为协作ID。'} src={'/assets/access_token_2.png'}/><br />
                </div>
              )}
            >
              <span
                style={{
                  float: 'left',
                  lineHeight: '32px',
                  cursor: 'pointer',
                  paddingLeft: '1em',
                  marginLeft: '-1em',
                }}
              >
                <Icon name={'question-circle'} style={{marginRight: '0.5em'}} />
                如何查看协作ID
              </span>
            </Popover>
          ),
          (<Button key={'close'} onClick={me.props.onClose}>取消</Button>),
          (
            <Button
              type={'primary'}
              key={'ok'}
              onClick={me.onSearch}
            >
              加入协作
            </Button>
          ),
        ]}
        visible={me.props.visible}
        onCancel={me.props.onClose}
        closable={false}
        afterClose={me.afterModalClose}
        bodyStyle={{padding: '24px 30px'}}
      >
        <Input
          placeholder={'请输入对方协作ID或注册邮箱'}
          autoFocus={true}
          disabled={me.state.doSearching}
          value={me.state.searchText}
          onChange={v => me.setState({searchText: v.target.value})}
          onKeyPress={e => e.key === 'Enter' && me.onSearch()}
          ref={input => {
            me.searchInput = input;
          }}
        />
        {me.props.recommendation && me.props.recommendation.length>0 && <List
          dataSource={me.props.recommendation}
          className={`${style['modal-content']} ${style['user-recommendation-list']}` }
          renderItem={user => (
            <List.Item onClick={e => {
              e.stopPropagation();
              me.checkValue(user);
            }}>
              <List.Item.Meta
                        avatar={(
                          <div
                            className={`${style['avatar']} ${user.picId ? '' : style['avatar-default-icon']}`}
                          >
                            <UserAvatar
                              size={'var(--avatar-size)'}
                              name={user.nick}
                              src={user.picId ? `${REQUEST_BASE}/user/user/file/${user.picId}?Authorization=${getToken()}` : undefined}
                              colors={AvatarColors}
                            />
                          </div>
                        )}
              />
              <Popover
                key={'tip'}
                placement={'bottomLeft'}
                content={(user.nodeFname ? (
                  <span>
                    <span style={{fontWeight: 'bold'}}>{user.nick} </span>
                    在图谱
                    <span style={{fontWeight: 'bold'}}> {user.viewName} </span>
                    中提到：<br />
                    <span style={{fontWeight: 'bold'}}>
                      <Icon {...getNodeIcon({
                        type: NODE_TYPE_TEXT,
                        lev: user['nodeVrVisibleType'],
                      })} style={{marginRight: '0.5em'}} />
                      {user.nodeFname}
                    </span>
                  </span>
                ) : (
                  <span>
                    <span style={{fontWeight: 'bold'}}>{user.nick} </span>
                    在图谱
                    <span style={{fontWeight: 'bold'}}> {user.viewName} </span>
                    中提到类似节点
                  </span>
                ))}
              >
                <div className={style['recommendation-text']}>
                  <span>
                      <span style={{fontWeight: 'bold'}}>{user.nick} </span>
                      在图谱
                      <span style={{fontWeight: 'bold'}}> {user.viewName} </span>
                      中提到类似节点
                  </span>
                </div>
              </Popover>
              <div className={style['right-checkbox']}><Checkbox checked={me.state.checkValueLists.includes(user.userId)} ></Checkbox></div>
            </List.Item>
            )
          }
        />}
      </Modal>
    );
  }
}

TeamworkMemberFindModal.defaultProps = {
  bus: PB,
  userList: [],
};

TeamworkMemberFindModal.propTypes = {
  bus: PropTypes.instanceOf(SimplePB),
  userList: PropTypes.array,
  viewId: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  recommendation: PropTypes.array,
  userInfo: PropTypes.object.isRequired,
};

export default TeamworkMemberFindModal;