import React from 'react';
import PropTypes from 'prop-types';

import PB, {SimplePB} from '@/libs/simplePB';
import {NodeEvents} from '@/libs/view/network/events';

import {myVis} from '@/constants/vis.defaultDefine.1';

import NodeDataProvider from '@/components/common/dataProvider/common.dataProvider.node';
import ViewDataProvider from '@/components/common/dataProvider/common.dataProvider.view';

class ExploreCommonRecommendDetailLogic extends React.PureComponent {
  // 初始化是否完成
  nodeListInitialized = false;

  // 本次初始化/重置后的原始节点列表
  originalNodeList = [];

  // 当前加载到的原节点下标
  currentPos = 0;

  // 是否还有更多结果需要加载
  hasMoreResult = true;

  // 本次初始化/重置后的加载KEY，避免重置前发出的请求对重置后的数据造成影响
  loadingKey = undefined;

  // 是否正处于加载中状态
  loadingResult = false;

  // 上次加载是否失败
  lastLoadingFailed = false;

  // 前一次加载完毕后是否自动启动下一次加载
  autoLoadMore = false;

  // 推荐详情列表，针对每个原节点，只取推荐详情列表的第一个值
  resultData = [];

  // 以节点ID为键、推荐详情列表为值的键值对
  resultMap = {};

  initializeNodeList = () => {
    let me = this;

    me.nodeListInitialized = false;
    me.originalNodeList = [];
    me.currentPos = 0;
    me.loadingKey = `explore-${me.props.exploreKey}-loading-${Math.random()}`;
    me.loadingResult = false;
    me.lastLoadingFailed = false;
    me.autoLoadMore = false;
    me.resultData = [];
    me.resultMap = {};

    if (me.props.viewDataProvider) {
      let viewInfo = me.props.viewDataProvider.viewInfo;

      if (viewInfo) {
        me.props.bus.emit('view', `explore.${me.props.exploreEventCategory}.recommend_detail.do_initialize_node_list`,
          {viewId: me.props.viewDataProvider.viewId});

        setTimeout(() => {
          let isOwner = (me.props.userId === viewInfo.userId);
          me.originalNodeList = (
            isOwner ? me.props.viewDataProvider.getData().data.nodes
                .get({filter: node => node.status === 1 && myVis.getNodeTypeFn(node) === me.props.exploreType})
              : me.props.viewDataProvider.getData().data.nodes
                .get({filter: node => node.status === 1 && myVis.getNodeTypeFn(node) === me.props.exploreType && node.userId === me.props.userId})
          );
          me.nodeListInitialized = true;
          me.hasMoreResult = me.hasMoreResult && me.originalNodeList.length > 0;
          me.props.bus.emit('view', `explore.${me.props.exploreEventCategory}.recommend_detail.node_list_initialized`,
            {viewId: me.props.viewDataProvider.viewId, originalNodeList: me.originalNodeList});
        }, 100);
      }
    }
  };

  onLoadMoreData = () => {
    let me = this;

    if (!me.nodeListInitialized) {
      return;
    }

    if (me.loadingResult) {
      return;
    }

    if (me.hasMoreResult) {
      // 取5个节点开始处理
      let currentPos = me.currentPos, limit = 5, processingNodeList = me.originalNodeList.slice(currentPos, currentPos + limit),
        nodeIds = processingNodeList.map(node => node.id), loadingKey = me.loadingKey;

      if (nodeIds.length <= 0) {
        return;
      }

      me.props.bus.emit('view', `explore.${me.props.exploreEventCategory}.recommend_detail.do_load_more`,
        {viewId: me.props.viewDataProvider.viewId, nodeIds, currentPos, limit, autoLoadMore: me.autoLoadMore});

      me.loadingResult = true;

      NodeDataProvider.recommendDetailByNodeList(processingNodeList, me.props.exploreType).then(resultMap => {
        if (me.loadingKey !== loadingKey) return;

        let resultData = [];
        resultMap = resultMap || {};
        me.currentPos += limit;
        me.hasMoreResult = (me.originalNodeList.length > me.currentPos);
        me.loadingResult = false;
        me.lastLoadingFailed = false;
        Object.values(resultMap).forEach(nodeList => resultData.push(nodeList[0]));
        me.resultData.push.apply(me.resultData, resultData);
        me.resultMap = {...me.resultMap, ...resultMap};

        me.props.bus.emit('view', `explore.${me.props.exploreEventCategory}.recommend_detail.data_loaded`,
          {viewId: me.props.viewDataProvider.viewId, resultMap, resultData, nodeIds, currentPos: me.currentPos, limit,
            hasMoreResult: me.hasMoreResult, overallResultMap: me.resultMap, overallResultData: me.resultData});
      }).catch(({code, msg}) => {
        if (me.loadingKey !== loadingKey) return;

        me.loadingResult = false;
        me.lastLoadingFailed = true;
        me.props.bus.emit('view', `explore.${me.props.exploreEventCategory}.recommend_detail.failed_to_load`,
          {code, msg, viewId: me.props.viewDataProvider.viewId});
      });
    }
  }

  clearResult = () => {
    let me = this;

    if (me.loadingResult) {
      return;
    }

    if (me.nodeListInitialized) {
      me.nodeListInitialized = false;
      me.originalNodeList = [];
      me.currentPos = 0;
      me.hasMoreResult = true;
      me.loadingKey = undefined;
      me.loadingResult = false;
      me.lastLoadingFailed = false;
      me.autoLoadMore = false;
      me.resultData = [];
      me.resultMap = {};

      me.props.bus.emit('view', `explore.${me.props.exploreEventCategory}.recommend_detail.result_cleared`,
        {viewId: me.props.viewDataProvider.viewId});
    }
  };

  componentDidMount() {
    let me = this;

    me.props.bus.with(
      me
    ).subscribe('view', `explore.${me.props.exploreEventCategory}.recommend_detail.show_modal`, ({viewId}) => {
      if (me.props.viewDataProvider && viewId === me.props.viewDataProvider.viewId) {
        if (!me.nodeListInitialized) {
          me.props.bus.emit('view', `explore.${me.props.exploreEventCategory}.recommend_detail.initialize_node_list`, {viewId});
        }
      }
    }).subscribe('view', `explore.${me.props.exploreEventCategory}.recommend_detail.initialize_node_list`, ({viewId, autoLoadMore = true}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId) {
        me.autoLoadMore = autoLoadMore;
        me.initializeNodeList();
      }
    }).subscribe('view', `explore.${me.props.exploreEventCategory}.recommend_detail.node_list_initialized`, ({viewId}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId) {
        if (me.autoLoadMore) {
          me.props.bus.emit('view', `explore.${me.props.exploreEventCategory}.recommend_detail.on_load_more`,
            {viewId, autoLoadMore: me.autoLoadMore});
        }
      }
    }).subscribe('view', `explore.${me.props.exploreEventCategory}.recommend_detail.on_load_more`, ({viewId, autoLoadMore = true}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId) {
        me.autoLoadMore = autoLoadMore;
        me.onLoadMoreData();
      }
    }).subscribe('view', `explore.${me.props.exploreEventCategory}.recommend_detail.clear_result`, ({viewId}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId) {
        me.clearResult();
      }
    }).subscribe('view', `explore.${me.props.exploreEventCategory}.recommend_detail.stop_auto_load`, ({viewId}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId && me.autoLoadMore) {
        me.autoLoadMore = false;
        me.props.bus.emit('view', `explore.${me.props.exploreEventCategory}.recommend_detail.broadcast_status`, {viewId});
      }
    }).subscribe('view', `explore.${me.props.exploreEventCategory}.recommend_detail.data_loaded`, ({viewId}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId && me.autoLoadMore && me.hasMoreResult) {
        requestAnimationFrame(() => me.onLoadMoreData());
      }
    }).subscribe('view', `explore.${me.props.exploreEventCategory}.recommend_detail.broadcast_status`, ({viewId}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId) {
        me.props.bus.emit('view', `explore.${me.props.exploreEventCategory}.recommend_detail.current_status`, {
          viewId,
          nodeListInitialized: me.nodeListInitialized,
          currentPos: me.currentPos,
          hasMoreResult: me.hasMoreResult,
          loadingResult: me.loadingResult,
          lastLoadingFailed: me.lastLoadingFailed,
          autoLoadMore: me.autoLoadMore,
        });
      }
    }).subscribe('view', `explore.${me.props.exploreEventCategory}.recommend_detail.broadcast_data`, ({viewId}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId) {
        me.props.bus.emit('view', `explore.${me.props.exploreEventCategory}.recommend_detail.current_data`, {
          viewId,
          originalNodeList: [...me.originalNodeList],
          resultData: [...me.resultData],
          resultMap: {...me.resultMap},
        });
      }
    });

    me.props.viewDataProvider.with(me).subscribe(NodeEvents.UPDATED, (updatedNodeIds, updatedNodes) => {
      let updatedNodeMap = {};

      updatedNodes.forEach(node => updatedNodeMap[node.id] = node);
      me.originalNodeList.forEach((node, idx) => {
        if (updatedNodeMap[node.id]) {
          me.originalNodeList[idx] = node;
        }
      });
    }).subscribe(NodeEvents.REMOVED, (type, nodeInfoList) => {
      let removedNodeMap = {}, resultChanged = false;

      nodeInfoList.forEach(node => {
        removedNodeMap[node.id] = true;
        if (me.resultMap[node.id]) {
          delete me.resultMap[node.id];
          resultChanged = true;
        }
      });
      me.originalNodeList = me.originalNodeList.filter((node, idx) => {
        if (removedNodeMap[node.id]) {
          if (me.currentPos > idx) {
            me.currentPos--;
          }
          return false;
        } else {
          return true;
        }
      });
      if (resultChanged) {
        me.resultData = me.resultData.filter(node => !removedNodeMap[node.id]);
      }
    }).subscribe(NodeEvents.ID_REPLACED, (nodeIdReplacement) => {
      let originalNodeIds = Object.keys(nodeIdReplacement);
      originalNodeIds.forEach(originalNodeId => {
        if (me.resultMap[originalNodeId]) {
          me.resultMap[nodeIdReplacement[originalNodeId]] = me.resultMap[originalNodeId];
          me.resultMap[nodeIdReplacement[originalNodeId]].forEach(node => {node.id = nodeIdReplacement[originalNodeId]});
          /*me.resultData.find(node => {
            if (node.id === originalNodeId) {
              node.id = nodeIdReplacement[originalNodeId];
              return true;
            }
          });*/
          me.originalNodeList.find(node => {
            if (node.id === originalNodeId) {
              node.id = nodeIdReplacement[originalNodeId];
              return true;
            }
          });
        }
      });
    });
  }

  componentWillUnmount() {
    this.props.bus.remove(this);
    this.props.viewDataProvider.unSubscribe(this);
  }

  render() {
    return null;
  }
}

ExploreCommonRecommendDetailLogic.defaultProps = {
  bus: PB,
};

ExploreCommonRecommendDetailLogic.propTypes = {
  exploreKey: PropTypes.string.isRequired,
  exploreType: PropTypes.number.isRequired,
  exploreEventCategory: PropTypes.string.isRequired,
  viewDataProvider: PropTypes.instanceOf(ViewDataProvider).isRequired,
  userId: PropTypes.number.isRequired,
  bus: PropTypes.instanceOf(SimplePB),
};

export default ExploreCommonRecommendDetailLogic;