import React from "react";
import PropTypes from 'prop-types';

import Icon from "@/components/common/common.icon";

import style from "@/style/containers/relationView.less";
import {
  getNodeDisplayTitle,
  getNodeIcon,
  myVis,
  NODE_TYPE_TEXT,
} from "@/constants/vis.defaultDefine.1";
import UserAvatar from "react-user-avatar";
import {getToken, REQUEST_BASE} from "@/utils/HttpUtil";
import { AvatarColors } from '@/constants/common';
import moment from 'moment';
import TimeDisplayField from '@/components/common/common.timeDisplayField';

class NodeInfoCard extends React.Component {
  myRef = React.createRef();

  onWheel = e => {
    let me = this;
    if (e.deltaMode !== undefined && e.deltaMode === 0) {
      if (me.myRef.current.scrollTop === 0 && e.deltaY < 0) {
        // ignore
      } else if (me.myRef.current.scrollTop + me.myRef.current.clientHeight + 1 >= me.myRef.current.scrollHeight &&
        e.deltaY > 0) {

        // ignore
      } else {
        e.stopPropagation();
        e.preventDefault();
        me.myRef.current.scroll(e.deltaX, e.deltaY);
      }
    }
  };

  componentDidMount() {
    if (this.myRef.current) {
      this.myRef.current.addEventListener("wheel", this.onWheel);
    }
  }

  componentWillUnmount() {
    if (this.myRef.current) {
      this.myRef.current.removeEventListener("wheel", this.onWheel);
    }
  }

  render() {
    let me = this;

    return (
      <div className={style['node-info-panel']}>
        <div className={'ant-card-body'}>
          <div
            className={
              `${style['node-info-icon']} ${
                me.props.userInfo ? (me.props.userInfo.picId ? '' : 'default-icon') : 'computer-icon'
              }`
            }
          >
            {myVis.getNodeTypeFn(me.props.info) === NODE_TYPE_TEXT ? null : <Icon {...getNodeIcon(me.props.info)}/>}
          </div>
          <div className={style['node-info-meta']}>
            <div className={style['node-info-meta-header']}>
              <div
                className={
                  `${style['node-info-meta-author']} ${
                    me.props.userInfo ? (me.props.userInfo.picId ? '' : 'default-icon') : 'computer-icon'
                  }`
                }
              >
                <UserAvatar
                  size={'var(--avatar-size)'}
                  name={me.props.userInfo ? me.props.userInfo.nick : '炬图智能大脑'}
                  src={me.props.userInfo && me.props.userInfo.picId ?
                    `${REQUEST_BASE}/user/user/file/${me.props.userInfo.picId}?Authorization=${getToken()}` : undefined}
                  colors={AvatarColors}
                />
                {me.props.userInfo ? me.props.userInfo.nick : '炬图智能大脑'}
              </div>
              {
                me.props.viewType === 'tagTime' ? (
                  <div>
                    <Icon name={'tags'} style={{marginRight: '0.3rem'}}/>
                    <span>{moment(me.props.info.tagTime).format('YYYY-MM-DD')}</span>
                  </div>
                ) : (
                  me.props.viewType === 'linkTime' ? (
                    <div>
                      <Icon name={'clock-circle'} style={{marginRight: '0.3rem'}}/>
                      <TimeDisplayField
                        description={'创建时间：'}
                        timestamp={moment(me.props.info.linkTime, 'YYYY-MM-DD HH:mm:ss').valueOf()}/>
                    </div>
                  ) : (
                    <div>
                      <Icon name={'history'} style={{marginRight: '0.3rem'}}/>
                      <TimeDisplayField
                        description={'更新时间：'}
                        timestamp={moment(me.props.info.updateTime || me.props.info.linkTime, 'YYYY-MM-DD HH:mm:ss').valueOf()}/>
                    </div>
                  )
                )
              }

            </div>
            <div className={style['node-info-meta-description']}>
              <div ref={me.myRef} className={style['node-info-meta-description-content']}>
                {getNodeDisplayTitle(me.props.info)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

NodeInfoCard.defaultProps = {};

NodeInfoCard.propTypes = {
  info: PropTypes.object.isRequired,
  userInfo: PropTypes.object,
  viewType: PropTypes.string,
};

export default NodeInfoCard;