import React from "react";
import PropTypes from 'prop-types';

import PB, {SimplePB} from "@/libs/simplePB";

import StatisticsIndexTinyImage from "@/components/common/view/statistics/index/common.view.statistics.index.tinyImage";

import style from '@/style/common/view/statistics/common.view.statistics.index.less';
import StatisticsIndexLargeImage
  from "@/components/common/view/statistics/index/common.view.statistics.index.largeImage";
import StatisticsIndexLogic from "@/components/common/view/statistics/index/common.view.statistics.index.logic";
import ViewDataProvider from "@/libs/view/network/ViewDataProvider";

class StatisticsIndexPlugin extends React.PureComponent {
  state = {
    configLoadingStatus: 'idle',
    showLargeImage: false,
    values: [],
  };

  config = {};

  componentDidMount() {
    let me = this;

    let busWrapper = me.props.bus.with(me);

    busWrapper.subscribe('statistics.index', 'large_image.show', () => {
      me.setState({showLargeImage: true});
    });

    busWrapper.subscribe('statistics.index', 'large_image.hide', () => {
      me.setState({showLargeImage: false});
    });

    busWrapper.subscribe('statistics.index', 'config.load.success', ({viewId, config}) => {
      if (me.props.viewId !== viewId) return;
      me.config = config;
      me.setState({configLoadingStatus: 'success', values: this.config.indexes.map(() => false)}, () => {
        /*me.config.indexes.forEach((config, idx) => {
          me.props.bus.emit('statistics.index', 'value.get', {idx, hash: config.hash, viewId});
        });*/
      });
    });

    busWrapper.subscribe('statistics.index', 'config.load.failed', ({viewId, error}) => {
      if (me.props.viewId !== viewId) return;
      me.setState({configLoadingStatus: 'failed'});
      console.log('Failed to load statistics index config: ', error);
    });

    busWrapper.subscribe('statistics.index', 'value.load.success', ({idx, value, hash, viewId}) => {
      if (me.props.viewId !== viewId) return;
      if (!me.config.indexes[idx]) return;
      let values = [...me.state.values];
      values[idx] = value;
      me.config.indexes[idx].hash = hash;
      me.setState({values});
    });

    /*busWrapper.subscribe('relation', ['node.added', 'node.updated', 'node.removed'],
      ({viewId, nodeIds}) => {
        if (me.props.viewId !== viewId) return;
        me.config.indexes.forEach((config, idx) => {
          me.props.bus.emit('statistics.index', 'value.load.do',
            {idx, ...config, modifiedNodeIds: nodeIds, viewId});
        });
      });*/

    requestAnimationFrame(() => {
      me.setState({configLoadingStatus: 'loading'}, () => {
        me.props.bus.emit('statistics.index', 'config.get', ({viewId: me.props.viewId}));
      });
    });
  }

  render() {
    let me = this;

    return (
      <div className={`${style['plugin-frame']} dark-theme`}>
        {
          me.state.showLargeImage ? (
            <StatisticsIndexLargeImage
              bus={me.props.bus}
              viewId={me.props.viewId}
              readonly={me.props.readonly}
              loadingStatus={me.state.configLoadingStatus}
              config={me.config}
              values={me.state.values}
            />
          ) : null
        }
        <StatisticsIndexTinyImage
          bus={me.props.bus}
          viewId={me.props.viewId}
          loadingStatus={me.state.configLoadingStatus}
          config={me.config}
          values={me.state.values}
        />
        <StatisticsIndexLogic bus={me.props.bus} viewId={me.props.viewId} viewDataProvider={me.props.viewDataProvider} />
      </div>
    );
  }
}

StatisticsIndexPlugin.defaultProps = {
  bus: PB,
  readonly: true,
};

StatisticsIndexPlugin.propTypes = {
  bus: PropTypes.instanceOf(SimplePB),
  readonly: PropTypes.bool,
  viewId: PropTypes.string.isRequired,
  viewDataProvider: PropTypes.instanceOf(ViewDataProvider).isRequired,
};

export default StatisticsIndexPlugin;