import React from 'react';
import PropTypes from 'prop-types';

import PB, {SimplePB} from '@/libs/simplePB';

import {httpUtilWithNoMsg as httpUtil} from "@/utils/HttpUtil";

import NodeDataProvider from '@/components/common/dataProvider/common.dataProvider.node';

class ViewStatisticsWordLogic extends React.PureComponent {
  componentDidMount() {
    let me = this;

    me.props.bus.with(me).subscribe('node.statistics', 'word.do_tmp_load', ({viewId}) => {
      httpUtil.get(`/view/view/${viewId}/rank_data`, {type: 'word_freq'}).then((response) => {
        if (response && response['data'] && response['data']['code'] === 0 && response['data'].data) {
          let result = response['data'].data['rank_data'] || [];
          let max = Math.max.apply(Math, result.map(item => item.rate));
          let dataList = result.map(item => ({...item, percent: Math.round(item.rate / max * 100)}))
          me.props.bus.emit('node.statistics', 'word.loaded', {result: dataList});
        } else {
          me.props.bus.emit('node.statistics', 'word.failed_to_load', response['data']);
        }
      }).catch(({msg, code}) => {
        me.props.bus.emit('node.statistics', 'word.failed_to_load', {code, msg});
      });
    }).subscribe('node.statistics', 'word.do_load', ({nodes, limit}) => {
      NodeDataProvider.loadWordStatisticsResult(nodes, limit).then((result) => {
        result = result['rank_data'] || [];
        let max = Math.max.apply(Math, result.map(item => item.rate));
        let dataList = result.map(item => ({...item, percent: Math.round(item.rate / max * 100)}));
        me.props.bus.emit('node.statistics', 'word.loaded', {result: dataList});
      }).catch(({msg, code}) => {
        me.props.bus.emit('node.statistics', 'word.failed_to_load', {code, msg});
      });
    });
  }

  componentWillUnmount() {
    let me = this;

    me.props.bus.remove(me);
  }

  render() {
    return (
      <div style={{height: 0, width: 0}} />
    );
  }
}

ViewStatisticsWordLogic.defaultProps = {
  bus: PB,
};

ViewStatisticsWordLogic.propTypes = {
  bus: PropTypes.instanceOf(SimplePB),
};

export default ViewStatisticsWordLogic;