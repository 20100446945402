import React from 'react';
import PropTypes from 'prop-types';
import {Col, Radio, Row} from 'antd';

import style from '@/style/common/microService/common.microService.less';

class MicroServicePanelParameterRadioGroup extends React.PureComponent {
  shortTextMap = {};

  resetRadioGroup = () => {
    let me = this;

    if (me.props.locked) {
      return;
    }

    me.props.onShortTextChange(me.shortTextMap[`v-${me.props.value || me.props.defaultValue}`] || '');
  };

  componentDidMount() {
    let me = this;

    me.props.options.forEach(option => {
      me.shortTextMap[`v-${option.value}`] = option.shortText;
    });

    if (!me.props.locked) {
      me.props.onChange({target: {value: (me.props.value || me.props.defaultValue)}});
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let me = this;

    if (prevProps.options !== me.props.options) {
      me.props.options.forEach(option => {
        me.shortTextMap[`v-${option.value}`] = option.shortText;
      });
      me.resetRadioGroup();
    } else if (prevProps.value !== me.props.value) {
      me.resetRadioGroup();
    }

    if (!me.props.locked && prevProps.locked !== me.props.locked) {
      me.props.onChange({target: {value: (me.props.value || me.props.defaultValue)}});
    } else if (!me.props.locked && me.props.value === undefined && me.props.defaultValue) {
      me.props.onChange({target: {value: me.props.defaultValue}});
    }
  }

  render() {
    let me = this;

    return (
      <Radio.Group
        className={style['parameter-radio-group']}
        onChange={me.props.onChange}
        value={me.props.value || me.props.defaultValue}
        disabled={me.props.locked}
      >
        <Row>
          {me.props.options.map(option => (
            <Col span={me.props.optionSpan}>
              <Radio className={style['parameter-radio-item']} value={option.value}>
                {option.label}
              </Radio>
            </Col>
          ))}
        </Row>
      </Radio.Group>
    );
  }
}

MicroServicePanelParameterRadioGroup.defaultProps = {
  optionSpan: 24,
};

MicroServicePanelParameterRadioGroup.propTypes = {
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onShortTextChange: PropTypes.func.isRequired,
  locked: PropTypes.bool.isRequired,
  defaultValue: PropTypes.any.isRequired,
  value: PropTypes.any,
  optionSpan: PropTypes.number,
};

export default MicroServicePanelParameterRadioGroup;