import _ from 'lodash';
import {distinctFn} from "@/components/common/view/statistics/node/common.view.statistics.node.logic.common";

export const statisticsByProgress = ({nodes, distinct, getConditionFn, cb}) => {
  let statisticsFn = (nodes, cb) => {
    // statistics
    let result = [9, 8, 7, 6, 5, 4, 3, 2, 1, 0].map(
      v => ({label: `${v * 10}% ~ ${v * 10 + 10}%`, rate: 0, nodes: [], pos: 10 - v})
    ), pos = 0, batch, workFn = () => {
      batch = 0;
      while (batch < 1000 && pos < nodes.length) {
        let n = nodes[pos], condition = parseInt(getConditionFn(n, _)), idx = Math.floor(condition / 10);
        if (!isNaN(condition)) {
          idx = 9 - Math.max(0, Math.min(9, idx));
          result[idx].rate++;
          result[idx].nodes.push(n);
        }
        batch++;
        pos++;
      }
      if (pos < nodes.length) {
        setTimeout(workFn, 5);
      } else {
        cb(result);
      }
    };
    workFn();
  };

  distinctFn({nodes, distinct, cb: (nodes) => {
    statisticsFn(nodes, (result) => {
      cb(result);
    });
  }});
};