import React from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";

import { Copyright } from "@/components/common/common.copyright";
import Loading from "@/components/common/common.loading";

// import style from "@/style/containers/viewer.less";
import layoutStyles from "@/style/default/defaultViewLayout.less";
import intl from 'react-intl-universal';

// const { Content } = Layout;

class ViewContainer extends React.Component {
  state = {
    // 加载页面遮罩
    viewLoading: {
      status: false,
      content: undefined, // 显示文字
    },
  };

  componentDidMount() {}

  render() {
    return (
      <div className={layoutStyles["layout-wrap"]}>
        <Loading />
        <div className={layoutStyles["header"]}>
          <div className={layoutStyles["logo"]}>
            <img
              onClick={() => {
                this.props.history.push({
                  pathname: "/index",
                  state: { type: "intro" },
                });
              }}
              src={intl.get('locale')==='zh-cn'?'/assets/logo-hans.png':'/assets/logo-hans_en.png'}
              alt={"炬图"}
            />
          </div>
        </div>
        <div
          className={`${layoutStyles["content-outer-fullscreen"]} dark-theme scrollbar-18`}
        >
          <Switch>
            <Route render={() => <Redirect to={"/"} />} />
          </Switch>
          {/*<Copyright className={style["version"]}/>*/}
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    userInfo: state.account.userInfo,
  }),
  (dispatch) => ({})
)(ViewContainer);
