/*
 * @Author: Carr.s
 * @Date: 2022-07-22 10:08:27
 * @LastEditTime: 2022-07-22 15:54:30
 * @LastEditors: Carr.s
 * @Description: 节点的子弹评估, 评估列表
 * @Copyright by joinmap
 */
import React from "react";
import PropTypes from "prop-types";
import {
  Modal,
  Button,
  Popover,
  Radio,
  message,
  Form,
  Select,
  Row,
  Col,
  Input,
  List,
  Avatar,
  Spin,
  Empty,
} from "antd";
import moment from "moment";
import PB, { SimplePB } from "@/libs/simplePB";
import Icon from "@/components/common/common.icon";
import { IconTypes } from "@/constants/common";
import nodeStyle from "@/style/components/mainView/node.less";
import UserNickAvatar from "@/components/common/objects/common.userAvatar";
import TimeDisplayField from "@/components/common/common.timeDisplayField";
import NodeAssessDetail from "@/components/common/node/assess/common.node.assess.detail";
import {
  calculateStandardDate,
  formatAfterDate,
} from "@/components/common/common.functions";
// 数据流
import NodeDataProvider from "@/components/common/dataProvider/common.dataProvider.node";

class NodeAssessList extends React.Component {
  state = {
    selectedAssessInfo: undefined, // 点击选中的评估
    assessList: [],
    loading: false,
    loadingTip: "正在加载评估",
    loadingIcon: <Icon type="loading" style={{ fontSize: 24 }} spin />,
  };

  loadList = () => {
    const { viewId, node } = this.props;
    this.setState(
      {
        loading: true,
        loadingTip: "正在加载评估",
        loadingIcon: <Icon name={"loading"} style={{ fontSize: 24 }} />,
      },
      () => {
        NodeDataProvider.loadNodeAssessByNodeId(viewId, node.id, {})
          .then((res) => {
            console.log(
              "🚀 ~ file: common.node.assess.list.js ~ line 74 ~ NodeAssessList ~ .then ~ res",
              res
            );
            // let res = demoRes;
            // 判断是否查询成功 => 显示列表?
            if (res.code == 0) {
              // message.success("加载子弹评估成功");
              me.setState({
                curAssessInfo: res.data[0],
                assessList: res.data,
                loading: false,
              });
            } else {
              // 加载出错
              me.setState({
                loading: true,
                loadingTip: "加载子弹评估出错，请稍后重试",
                loadingIcon: (
                  <Icon
                    name={"icon-error-r"}
                    type={IconTypes.ICON_FONT}
                    style={{ color: "red", fontSize: 24 }}
                  />
                ),
              });
            }
          })
          .catch((e) => {
            console.log(
              "🚀 ~ file: common.node.assess.modal.js ~ line 81 ~ NodeAssessModal ~ NodeDataProvider.createNodeAssess ~ e",
              e
            );
            // 查询失败给提示
            // message.warning("加载子弹评估失败，请稍后重试");
            me.setState({
              loading: true,
              loadingTip: "加载子弹评估失败，请稍后重试",
              loadingIcon: (
                <Icon
                  name={"icon-error-o"}
                  type={IconTypes.ICON_FONT}
                  style={{ color: "red", fontSize: 32 }}
                />
              ),
            });
          })
          .finally(() => {});
      }
    );
  };
  componentDidMount() {
    console.log(
      "🚀 ~ file: common.node.assess.list.js ~ line 33 ~ NodeAssessList ~ componentDidMount",
      this.props
    );
    // this.props.bus.emit("node_assess", "modal.load.list");
  }

  componentWillUnmount() {
    this.props.bus.remove(this);
  }

  render() {
    let me = this;
    const {
      selectedAssessInfo,
      assessList: x_accessList,
      loading: x_loading,
      loadingTip,
      loadingIcon,
    } = this.state;
    const {
      height,
      assessList,
      loadAssessList: loading,
      curAssessIdx,
    } = this.props;

    if (loading) {
      return (
        <div
          style={{
            flex: 1,
            minHeight: height,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin tip={loadingTip} indicator={loadingIcon} size="large" />
        </div>
      );
    }
    if (!assessList || assessList.length === 0) {
      return (
        <Empty
          image="/assets/icons/empty.svg"
          imageStyle={{
            height: 120,
          }}
          description={
            <span>
              <span style={{ fontSize: "1.2rem", margin: "15px 0" }}>
                该节点还没有评估
              </span>
            </span>
          }
          style={{
            margin: "20px auto",
            height: height - 40,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        />
      );
    }

    // 选中项
    let assessInfo = undefined;
    if (curAssessIdx >= 0) {
      assessInfo = assessList[curAssessIdx];
    } else {
      if (selectedAssessInfo) {
        assessInfo = selectedAssessInfo;
      } else {
        assessInfo = assessList[0];
      }
    }

    const selectedStyle = {
      paddingLeft: 10,
      paddingRight: 10,
      // borderRadius: "5px",
      backgroundColor: "rgba(0,0,0,0.1)",
      cursor: "pointer",
    };

    return (
      <div
        style={{
          flex: 1,
          // height: "100%",
          minHeight: height,
          // backgroundColor: "rgba(0,0,0,.1)",
          display: "flex",
          // alignItems: "stretch",
        }}
      >
        <div
          className={"scrollbar-18"}
          style={{
            flex: "0 1 28%",
            height: "100%",
            // backgroundColor: "rgba(0,0,0,0)",
            overflowX: "hidden",
            overflowY: "auto",
          }}
        >
          <div style={{ backgroundColor: "rgba(0,0,0,0)" }}>
            <List
              itemLayout="horizontal"
              dataSource={assessList}
              renderItem={(item) => {
                let formattedList = formatAfterDate(item.updateTimestamp);
                return (
                  <List.Item
                    onClick={() => {
                      me.setState({
                        selectedAssessInfo: item,
                      });
                    }}
                    style={
                      assessInfo && item["id"] == assessInfo["id"]
                        ? selectedStyle
                        : {
                            paddingLeft: 10,
                            paddingRight: 10,
                            cursor: "pointer",
                          }
                    }
                  >
                    <UserNickAvatar
                      userId={item.userId + ""}
                      user={undefined}
                      avatarSize="2rem"
                      nickStyle={{
                        fontSize: "1rem",
                        color: "rgba(0,0,0,.85)",
                      }}
                    />
                    <div
                      style={{
                        fontSize: "1rem",
                        color: "rgba(0,0,0,.5)",
                      }}
                    >
                      <TimeDisplayField
                        timestamp={moment(
                          item.updateTimestamp,
                          "YYYY-MM-DD"
                        ).valueOf()}
                        tooltipPlacement={"bottom"}
                        description={"评估时间"}
                      >
                        <span>
                          {formattedList[0]}
                          {formattedList[1] ? ` (${formattedList[1]})` : null}
                        </span>
                      </TimeDisplayField>
                    </div>
                  </List.Item>
                );
              }}
            />
          </div>
        </div>
        <div
          className={"scrollbar-18"}
          style={{
            flex: "0 1 72%",
            height: "100%",
            backgroundColor: "rgba(0,0,0,.1)",
            padding: "10px 10px",
            overflowX: "hidden",
            overflowY: "auto",
          }}
        >
          <div style={{ backgroundColor: "rgba(0,0,0,0)" }}>
            <NodeAssessDetail assessInfo={assessInfo} />
          </div>
        </div>
      </div>
    );
  }
}

NodeAssessList.defaultProps = {
  bus: PB,
  curAssessIdx: undefined,
  viewId: undefined,
  node: undefined,
  height: 600,
  assessList: [],
  loadAssessList: false,
};

NodeAssessList.propTypes = {
  bus: PropTypes.instanceOf(SimplePB),
  curAssessIdx: PropTypes.number, // 默认选中的评估项,数组下标
  viewId: PropTypes.string, // 评估节点所在的看板
  node: PropTypes.object, // 评估对应的节点
  height: PropTypes.number, // 评估列表的外部div高度
  assessList: PropTypes.array, // 要显示的评估列表
  loadAssessList: PropTypes.bool, // 评估列表的请求状态
};

export default NodeAssessList;
