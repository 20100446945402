import React from 'react';
import PropTypes from 'prop-types';
import {List, Checkbox, message, Modal, Row, Col, Form, Input} from 'antd';

import PB, {SimplePB} from '@/libs/simplePB';
import ClampLines from '@/libs/my-react-clamp-lines';

import {getNodeDisplayTitle, getNodeIcon} from '@/constants/vis.defaultDefine.1';

import IconAvatar from '@/components/common/common.iconAvatar';
import Icon from '@/components/common/common.icon';

import style from '@/style/common/view/common.view.explore.less';
import {IconTypes} from "@/constants/common";
import ViewDataProvider from "@/components/common/dataProvider/common.dataProvider.view";
import {showErrorMessage} from "@/components/common/common.message";

class ExploreCommonRecommendDetailResultList extends React.Component {
  state = {
    showDescriptionDiffModal: false,
    savingDescription: false,
    node: undefined,
    resultDescription: '',
    nodeDescription: '',
  };

  getNodeContent = node => {
    let me = this, onClick = e => {
      e.preventDefault();
      me.props.onItemCheckChange(node.id);
    }, avatarElement = (
      node.meta && node.meta['iconMeta'] && node.meta['iconData'] ? (
        node.meta['iconMeta'].shape === 'rect' ? (
          <span className={`ant-avatar ant-avatar-icon common-avatar`}>
                        <img src={node.meta['iconData']} alt={'icon'} />
                      </span>
        ) : (
          <span className={`ant-avatar ant-avatar-circle ant-avatar-icon common-avatar`}>
                        <img src={node.meta['iconData']} alt={'icon'} />
                      </span>
        )
      ) : (
        <IconAvatar {...getNodeIcon(node)} />
      )
    );

    return (
      <div className="ant-list-item-meta">
        {
          node.hasIconToOverride || node.hasDescriptionToOverride ? (
            <Checkbox
              checked={!!me.props.checkedNodeMap[node.id]}
              onChange={e => me.props.onItemCheckChange(node.id, e.target.checked)}
            />
          ) : (
            <label className={'ant-checkbox-wrapper'} aria-readonly={true}>
              <Icon name={'check-circle'}/>
            </label>
          )
        }
        <div className="ant-list-item-meta-avatar">
          {
            node.hasIconToOverride || node.hasDescriptionToOverride ? (
              <a onClick={onClick}>
                {avatarElement}
              </a>
            ) : avatarElement
          }
        </div>
        <div className="ant-list-item-meta-content">
          <h4 className="ant-list-item-meta-title">
            <span>{node.fname}</span>
          </h4>
          <ClampLines
            id={`node-${node.id}-description`}
            className="ant-list-item-meta-description"
            lines={2}
            moreText={'展开 ∨'}
            lessText={'收起 ∧'}
            text={node.description || ''}
          />
          <ul className="ant-list-item-action">
            <li aria-disabled={!node.hasIconToOverride}>
              <span>
                {
                  node.hasIconToOverride ? (
                    <a
                      onClick={e => {
                        e.preventDefault();
                        me.props.onSaveNode(node.id, 'icon');
                      }}
                    >
                      <Icon name={'icon-custom-icon'} type={IconTypes.ICON_FONT} /> 保存图标
                    </a>
                  ) : (
                    <React.Fragment>
                      <Icon name={'icon-custom-icon'} type={IconTypes.ICON_FONT} /> 保存图标
                    </React.Fragment>
                  )
                }
              </span>
              <em className="ant-list-item-action-split" />
            </li>
            <li aria-disabled={!node.hasDescriptionToOverride}>
              <span>
                {
                  node.hasDescriptionToOverride ? (
                    <a
                      onClick={e => {
                        e.preventDefault();
                        me.props.onSaveNode(node.id, 'description');
                      }}
                    >
                      <Icon name={'align-left'} /> 保存描述
                    </a>
                  ) : (
                    <React.Fragment>
                      <Icon name={'align-left'} /> 保存描述
                    </React.Fragment>
                  )
                }
              </span>
              <em className="ant-list-item-action-split" />
            </li>
            <li aria-disabled={!node.hasDescriptionToOverride || !node.originalDescription}>
              <span>
                {
                  node.hasDescriptionToOverride && node.originalDescription ? (
                    <a
                      onClick={e => {
                        e.preventDefault();
                        me.setState({
                          showDescriptionDiffModal: true,
                          savingDescription: false,
                          node,
                          resultDescription: node.description,
                          nodeDescription: node.originalDescription,
                        });
                      }}
                    >
                      <Icon name={'fork'} /> 描述对比
                    </a>
                  ) : (
                    <React.Fragment>
                      <Icon name={'fork'} /> 描述对比
                    </React.Fragment>
                  )
                }
              </span>
            </li>
          </ul>
        </div>
      </div>
    );
  };

  saveNodeDescription = () => {
    let me = this, nodeInfo = me.props.viewDataProvider.getNode(me.state.node.id);

    if (nodeInfo) {
      me.setState({savingDescription: true}, () => {
        let nodeToSave = {...nodeInfo, description: me.state.nodeDescription,
          meta: (nodeInfo.meta && nodeInfo.meta.description !== undefined
            ? {...nodeInfo.meta, description: me.state.nodeDescription} : nodeInfo.meta)}
        me.props.viewDataProvider.saveRelationGraph([nodeToSave], [], false, false, undefined, false).then(() => {
          me.setState({
            showDescriptionDiffModal: false,
            savingDescription: false,
            node: undefined,
            resultDescription: '',
            nodeDescription: '',
          }, () => {
            message.success('节点描述保存成功');
          });
        }).catch(({code, msg}) => {
          showErrorMessage({code, msg});
          me.setState({savingDescription: false,});
        });
      });
    }
  };

  render() {
    let me = this;

    return (
      <React.Fragment>
        <List
          itemLayout={'horizontal'}
          size={'small'}
          locale={{emptyText: me.props.loadingResult ? '数据加载中' : '没有找到可以填充的数据'}}
          dataSource={me.props.nodeList}
          className={style['recommend-detail-result-list']}
          renderItem={(node) => (
            <List.Item className={style['recommend-detail-result-list-item']}>
              {me.getNodeContent(node)}
            </List.Item>
          )}
        />
        <Modal
          title={`描述对比${me.state.node ? `（${getNodeDisplayTitle(me.state.node, 18)}）` : ''}`}
          visible={me.state.showDescriptionDiffModal}
          width={`calc(45rem + 48px)`}
          centered={true}
          onCancel={() => me.setState({
            showDescriptionDiffModal: false,
            resultDescription: '',
            nodeDescription: '',
          })}
          cancelText={'取消'}
          onOk={me.saveNodeDescription}
          okText={'保存节点描述'}
          okButtonProps={{loading: me.state.savingDescription}}
        >
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label={'参考描述'}>
                <Input.TextArea
                  className={`scrollbar`}
                  style={{overflowY: 'scroll !important', resize: 'none'}}
                  placeholder=""
                  rows={16}
                  autoSize={false}
                  readOnly={true}
                  value={me.state.resultDescription}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={'节点描述'}>
                <Input.TextArea
                  className={`scrollbar`}
                  style={{overflowY: 'scroll !important', resize: 'none'}}
                  placeholder="输入与当前关注点有关的任何描述信息"
                  rows={16}
                  autoSize={false}
                  value={me.state.nodeDescription}
                  onChange={({target: {value}}) => me.setState({nodeDescription: value})}
                />
              </Form.Item>
            </Col>
          </Row>
        </Modal>
      </React.Fragment>
    );
  }
}

ExploreCommonRecommendDetailResultList.defaultProps = {
  bus: PB,
};

ExploreCommonRecommendDetailResultList.propTypes = {
  viewDataProvider: PropTypes.instanceOf(ViewDataProvider).isRequired,
  onItemCheckChange: PropTypes.func.isRequired,
  onSaveNode: PropTypes.func.isRequired,
  loadingResult: PropTypes.bool.isRequired,
  nodeList: PropTypes.array.isRequired,
  checkedNodeMap: PropTypes.object.isRequired,
  bus: PropTypes.instanceOf(SimplePB),
};

export default ExploreCommonRecommendDetailResultList;