import React from 'react';
import PropTypes from 'prop-types';
import {Button, Descriptions, Divider, Modal, Radio, message, Tag} from "antd";
import PB, {SimplePB} from "@/libs/simplePB";
import TeamworkMemberStatisticsTable from "@/components/common/view/teamwork/common.view.teamwork.memberStatisticsTable";
import Icon from "@/components/common/common.icon";

const teamworkType = ['创建者主动添加', '所有用户均可加入'];

class SettingsModal extends React.Component {
  state = {
    basicSettingEditMode: false,
    settingsType: 0,
    settingsMeta: {},
    loadingStatus: 'idle',
    updatingStatus: 'idle',
    joiningStatus: 'idle',
  };

  settings = {
    viewId: undefined,
    type: 0,
    meta: {},
  };

  members = [];

  componentDidMount() {
    let me = this;

    me.props.bus.sub(me, 'teamwork', 'settings.load.processing', ({viewId}) => {
      if (me.props.viewId !== viewId) return;
      me.setState({loadingStatus: 'processing'});
    });

    me.props.bus.sub(me, 'teamwork', 'settings.load.success', ({viewId, type, meta}) => {
      if (me.props.viewId !== viewId) return;
      me.settings = {viewId, type, meta};
      me.setState({loadingStatus: 'success'});
    });

    me.props.bus.sub(me, 'teamwork', 'settings.load.failed', ({viewId}) => {
      if (me.props.viewId !== viewId) return;
      me.setState({loadingStatus: 'failed'});
    });

    me.props.bus.sub(me, 'teamwork', 'settings.update.processing', ({viewId}) => {
      if (me.props.viewId !== viewId) return;
      me.setState({updatingStatus: 'processing'});
    });

    me.props.bus.sub(me, 'teamwork', 'settings.update.success', ({viewId}) => {
      if (me.props.viewId !== viewId) return;
      me.settings = {viewId, type: me.state.settingsType, meta: me.state.settingsMeta};
      me.setState({updatingStatus: 'success', basicSettingEditMode: false});
      message.success('操作成功');
    });

    me.props.bus.sub(me, 'teamwork', 'settings.update.failed', ({viewId}) => {
      if (me.props.viewId !== viewId) return;
      me.setState({updatingStatus: 'failed'});
    });

    me.props.bus.sub(me, 'teamwork', 'member.join.processing', ({viewId}) => {
      if (me.props.viewId !== viewId) return;
      me.setState({joiningStatus: 'processing'});
    });

    me.props.bus.sub(me, 'teamwork', 'member.join.success', ({viewId, type, meta}) => {
      if (me.props.viewId !== viewId) return;
      me.settings = {viewId, type, meta};
      me.setState({joiningStatus: 'success'});
    });

    me.props.bus.sub(me, 'teamwork', 'member.join.failed', ({viewId}) => {
      if (me.props.viewId !== viewId) return;
      me.setState({joiningStatus: 'failed'});
    });

    me.props.bus.sub(me, 'teamwork', 'statistics.members.ready', ({members}) => {
      me.members = members;
      me.forceUpdate();
    });

    requestAnimationFrame(() => {
      me.props.bus.emit('teamwork', 'statistics.members.get');
      me.props.bus.emit('teamwork', 'settings.load.do', {viewId: me.props.viewId});
    });
  }

  componentWillUnmount() {
    this.props.bus.remove(this);
  }

  onSetTeamworkSettings = () => {
    let me = this;

    me.props.bus.emit('teamwork', 'settings.update.do',
      {viewId: me.settings.viewId, type: me.state.settingsType});
  };

  render() {
    let me = this;

    return (
      <Modal
        title={'协作管理'}
        centered
        visible={me.props.visible}
        width={'70rem'}
        footer={null}
        onCancel={me.props.onClose}
        destroyOnClose={true}
      >
        <Descriptions title={(
          <span>
            <span style={{marginRight: '0.5em'}}>基本配置</span>
            {
              me.props.readonly || me.state.basicSettingEditMode ? null :
                (
                  <Button
                    type={'circle'}
                    size={'small'}
                    onClick={() => {
                      me.setState({
                        basicSettingEditMode: true,
                        settingsType: me.settings.type,
                        settingsMeta: me.settings.meta,
                      });
                    }}
                  >
                    <Icon name={'edit'} />
                  </Button>
                )
            }
            {
              !me.props.readonly && me.state.basicSettingEditMode ? (
                <Button
                  size={'small'}
                  type={'primary'}
                  style={{marginRight: '0.5rem'}}
                  loading={me.state.updatingStatus === 'processing'}
                  onClick={me.onSetTeamworkSettings}
                >
                  保存
                </Button>
              ) : null
            }
            {
              !me.props.readonly && me.state.basicSettingEditMode ? (
                <Button
                  size={'small'}
                  onClick={() => me.setState({basicSettingEditMode: false})}
                  disabled={me.state.updatingStatus === 'processing'}
                >
                  取消
                </Button>
              ) : null
            }
          </span>
        )}>
          <Descriptions.Item label={'加入方式'}>
            {me.state.basicSettingEditMode ? (
              <div>
                <Radio.Group
                  value={me.state.settingsType}
                  onChange={e => {
                    me.setState({settingsType: e.target.value});
                  }}
                >
                  <Radio value={0}>{teamworkType[0]}</Radio>
                  <Radio value={1}>{teamworkType[1]}</Radio>
                </Radio.Group>
              </div>
            ) : teamworkType[me.settings.type]}
          </Descriptions.Item>
        </Descriptions>
        <Divider style={{marginTop: '16px'}} />
        <Descriptions
          title={(
            <span>
              <span style={{marginRight: '0.5em'}}>团队成员({me.members.length})</span>
              {
                me.props.readonly ? null : (
                  <Button
                    type={'circle'}
                    size={'small'}
                    onClick={() => me.props.bus.emit('teamwork', 'member.add.show_modal')}
                    style={{marginRight: '0.5rem'}}
                  >
                    <Icon name={'plus'} />
                  </Button>
                )
              }
              {
                me.props.readonly && me.members && me.members.length > 0 ? (
                  me.members.find(member => parseInt(member.userId) === me.props.currentUserId) ? (
                    <Tag>您已加入</Tag>
                  ) : (
                    me.settings.type === 1 ? (
                      <Button
                        size={'small'}
                        type={'primary'}
                        onClick={
                          () =>
                            me.props.bus.emit('teamwork', 'member.join.do', {viewId: me.props.viewId})
                        }
                        loading={me.state.joiningStatus === 'processing'}
                      >
                        立即加入
                      </Button>
                    ) : null
                  )
                ) : null
              }
            </span>
          )}
        >
          <Descriptions.Item>
            <TeamworkMemberStatisticsTable
              bus={me.props.bus}
              scroll={{y: '50vh'}}
              readonly={me.props.readonly}
              currentUserId={me.props.currentUserId}
              viewId={me.props.viewId}
            />
          </Descriptions.Item>
        </Descriptions>
      </Modal>
    );
  }
}

SettingsModal.defaultProps = {
  bus: PB,
  readonly: true,
};

SettingsModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  bus: PropTypes.instanceOf(SimplePB),
  readonly: PropTypes.bool,
  viewId: PropTypes.string.isRequired,
  currentUserId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SettingsModal;