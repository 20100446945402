import React from 'react';
import PropTypes from 'prop-types';
import {Table} from "antd";
import {getNodeDisplayTitle} from "@/constants/vis.defaultDefine.1";

import style from '@/style/common/view/statistics/common.view.statistics.nodeTable.less';

const COLUMNS = () => [
  {
    title: '节点名称',
    dataIndex: 'title',
    key: 'title',
    render: (text, record) => {
      return getNodeDisplayTitle(record);
    },
  },
  {
    title: '最后更新时间',
    dataIndex: 'updateTime',
    key: 'updateTime',
    width: '20rem',
    defaultSortOrder: 'descend',
    sorter: (a, b) => {
      return a['updateTime'] && b['updateTime'] ? (
        a['updateTime'] > b['updateTime'] ? 1 : (
          a['updateTime'] === b['updateTime'] ? 0 : -1
        )
      ) : (
        a['updateTime'] || b['updateTime'] ? (
          a['updateTime'] ? 1 : -1
        ) : 0
      );
    },
  },
];

class StatisticsNodeTable extends React.Component {
  render() {
    let me = this;

    return (
      <Table
        className={`${style['node-table']} scrollbar-none`}
        columns={COLUMNS()}
        dataSource={me.props.nodes}
        scroll={me.props.scroll}
        pagination={false}
      />
    );
  }
}

StatisticsNodeTable.defaultProps = {
  nodes: [],
  scroll: undefined,
};

StatisticsNodeTable.propTypes = {
  nodes: PropTypes.array,
  scroll: PropTypes.object,
};

export default StatisticsNodeTable;