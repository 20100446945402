import React from 'react';
import PropTypes from 'prop-types';

import PB, {SimplePB} from '@/libs/simplePB';

import {statisticsByType} from '@/components/common/view/statistics/edge/common.view.statistics.edge.logic.type';
import {statisticsByAmountRange} from '@/components/common/view/statistics/edge/common.view.statistics.edge.logic.amountRange';

const cache = {
  key: `node-statistics-${Math.random()}`,
  resultMap: {
    ['conditionKey']: [],
  },
};

export const STATISTIC_CONDITIONS = {
  TYPE: {
    key: 'TYPE',
    category: 'TYPE',
    defaultLimit: -1,
    defaultSort: 'DESC',
    cacheKeyFn: ({sort, limit}) => {
      return `CREATE_DATE_S_${`${sort}`.toLowerCase() === 'asc' ? 'ASC' : 'DESC'}_L_${limit}`;
    },
    statisticsType: 'localFn',
    statisticsFn: ({nodes, edges, sort, cb}) => {
      return statisticsByType({nodes, edges, sort, cb});
    },
  },
  AMOUNT_RANGE: {
    key: 'AMOUNT_RANGE',
    category: 'AMOUNT_RANGE',
    defaultLimit: 10,
    defaultSort: 'DESC',
    cacheKeyFn: ({sort, limit}) => {
      return `CREATE_MONTH_S_${`${sort}`.toLowerCase() === 'asc' ? 'ASC' : 'DESC'}_L_${limit}`;
    },
    statisticsType: 'localFn',
    statisticsFn: ({nodes, edges, sort, limit, cb}) => {
      return statisticsByAmountRange({nodes, edges, sort, limit, cb});
    },
  },
};

class ViewStatisticsEdgeLogic extends React.PureComponent {
  componentDidMount() {
    let me = this;

    me.props.bus.with(
      me
    ).subscribe('node.statistics', 'edge.do_load', ({nodes, edges, conditionKey, cacheKey, sort, limit}) => {
      let conditionCfg = STATISTIC_CONDITIONS[conditionKey];

      if (!conditionCfg) {
        me.props.bus.emit('node.statistics', 'edge.failed_to_load', {code: 500, msg: '统计方式有误'});
        return;
      }

      sort = (sort === undefined) ? conditionCfg.defaultSort : sort;
      limit = (limit === undefined) ? conditionCfg.defaultLimit : limit;

      if (cache.key === cacheKey) {
        let cachedResult = cache.resultMap[conditionCfg.cacheKeyFn({sort, limit})];
        if (cachedResult) {
          me.props.bus.emit('node.statistics', 'edge.loaded',
            {sort, limit, result: cachedResult});
          return;
        }
      } else {
        cache.key = cacheKey;
        cache.resultMap = {};
      }

      if (conditionCfg.statisticsType === 'localFn') {
        conditionCfg.statisticsFn({
          nodes,
          edges,
          sort,
          limit,
          cb: (cachedResult) => {
            let max = Math.max.apply(Math, cachedResult.map(item => item.rate));
            cachedResult = cachedResult.map(item => ({...item, percent: Math.round(item.rate / max * 100)}));
            cache.resultMap[conditionCfg.cacheKeyFn({sort, limit})] = cachedResult;
            me.props.bus.emit('node.statistics', 'edge.loaded',
              {sort, limit, result: cachedResult});
          },
        });
      } else {
        me.props.bus.emit('node.statistics', 'edge.failed_to_load', {code: 500, msg: '统计方式暂不支持'});
      }
    })
  }

  componentWillUnmount() {
    let me = this;

    me.props.bus.remove(me);
  }

  render() {
    return (
      <div style={{height: 0, width: 0}} />
    );
  }
}

ViewStatisticsEdgeLogic.defaultProps = {
  bus: PB,
};

ViewStatisticsEdgeLogic.propTypes = {
  bus: PropTypes.instanceOf(SimplePB),
};

export default ViewStatisticsEdgeLogic;