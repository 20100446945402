import React from 'react';
import {Input, message, Modal, Upload} from "antd";
import PropTypes from "prop-types";
import moment from 'moment';

import Icon from "@/components/common/common.icon";
import ViewDataProvider from '@/components/common/dataProvider/common.dataProvider.view';

import style from "@/style/components/main.view.fileList.less";

class FileUploadModal extends React.Component {
  state = {
    uploading: false,
    comment: '',
    fileList: [],
  };

  mounted = false;

  onUploadSuccess = () => {
    let me = this;

    if (me.mounted) {
      me.setState({
        uploading: false,
        fileList: [],
        comment: '',
      }, () => {
        me.props.onClose(true);
      });
    }
    // 显示进度条showPageLoading消失后显示message
    setTimeout(() => {
      message.success('上传信息成功');
    }, 700);
  };

  onUploadFailed = () => {
    let me = this;

    me.setState({uploading: false});
    message.error('上传信息失败，请重试');
  };

  doUpload = () => {
    let me = this;
    me.setState({
      uploading: true,
    }, () => {
      (me.props.doUpload || me.props.networkRef.addFiles)(me.props.nodeId, me.state.comment, me.state.fileList)
        .then(() => me.onUploadSuccess())
        .catch(() => me.onUploadFailed())
    });
  };

  onPastingImage = e => {
    let me = this;
    if (!me.props.visible) return;
    if (e.clipboardData && e.clipboardData.items) {
      // 从剪贴板中获取items
      let items = e.clipboardData.items;
      // 循环items获取剪贴板中的图片
      for (let i = 0; i < items.length; i++) {
        if (items[i].type.indexOf('image') !== -1) {
          // 获取图片的文件信息
          let blob = items[i].getAsFile();
          let nowTime = new Date();
          // 配合输入框文字，"可粘贴截屏"好像不太顺，改为截图
          let fileName = '截图' + moment().format("YYYY-MM-DD HH:mm:ss");
          let file = new window.File([blob], fileName + '.png', {type: blob.type});
          file['uid'] = 'rc-upload-' + nowTime.getTime();
          me.setState({
            fileList: [...me.state.fileList, file],
          });
        }
      }
      e.preventDefault();
    }
  };

  componentDidMount() {
    this.mounted = true;
    let me = this;
    // 聚焦添加描述文本框时，使用粘贴功能，提取剪贴板中图片作为附件
    window.addEventListener('paste', me.onPastingImage);
  }

  componentWillUnmount() {
    this.mounted = false;
    window.removeEventListener('paste', this.onPastingImage);
  }

  render() {
    let me = this;

    // noinspection RequiredAttributes
    return (
      <Modal
        title={<div>
          <Icon name="edit" style={{color: '#a5a5a5', marginRight: 10}}/>
          {me.props.title || (me.props.nodeId ? '添加信息至节点' : '上传文件至图谱')}
        </div>}
        centered
        visible={me.props.visible}
        confirmLoading={me.state.uploading}
        className={me.props.className}
        onOk={me.doUpload}
        onCancel={() => me.setState({fileList: [], comment: ''}, () => me.props.onClose(false))}
        okText="确认"
        cancelText="取消"
        okButtonProps={
          {disabled: (!me.props.showComment || !me.state.comment || !me.props.nodeId) && me.state.fileList.length <= 0}
        }
      >
        {
          me.props.showComment ? (
            <Input.TextArea
              autoFocus={true}
              autoSize={{minRows: 2, maxRows: 10}} placeholder="请输入描述"
              value={me.state.comment}
              onChange={(v) => me.setState({comment: v.target.value})}
              style={{marginBottom: '10px'}}
            />
          ) : null
        }
        <Upload.Dragger
          name='file'
          className={style['file-upload-list']}
          fileList={me.state.fileList}
          multiple={false}
          onRemove={(file) => {
            me.setState(({fileList}) => {
              const index = fileList.indexOf(file);
              const newFileList = fileList.slice();
              newFileList.splice(index, 1);
              return {
                fileList: newFileList,
              }
            })
          }}
          beforeUpload={(file) => {
            if (file.size > 50 * 1024 * 1024) {
              message.error("上传文件不要超过50MB");
              return false
            }
            if (!me.props.allowMultipleFiles) {
              if (this.state.fileList.length > 0) {
                message.info(`不支持批量上传，文件 ${this.state.fileList[0].name} 已被替换`);
              }
              me.setState(() => ({
                fileList: [file],
              }));
            } else {
              me.setState(() => ({
                fileList: [...me.state.fileList, file],
              }));
            }
            return false;
          }}
        >
          <p className="ant-upload-drag-icon">
            <Icon name="inbox"/>
          </p>
          <p className="ant-upload-text">
            点击选取上传文件 或 拖拽文件到这里<br />
            也可直接 Ctrl+V 粘贴
          </p>
        </Upload.Dragger>
      </Modal>
    );
  }
}

FileUploadModal.defaultProps = {
  className: '',
  showComment: true,
  title: undefined,
  doUpload: false,
  allowMultipleFiles: true,
};

FileUploadModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  networkRef: PropTypes.instanceOf(ViewDataProvider).isRequired,
  className: PropTypes.string,
  showComment: PropTypes.bool,
  title: PropTypes.string,
  nodeId: PropTypes.string,
  doUpload: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  allowMultipleFiles: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

export default FileUploadModal;