import React from 'react';
import PropTypes from 'prop-types';
import {Button, Tooltip} from 'antd';
import UserAvatar from 'react-user-avatar';
import _ from 'lodash';

import PB, {SimplePB} from '@/libs/simplePB';
import {NetworkEvents} from '@/libs/view/network/events';

import {AvatarColors, IconTypes} from "@/constants/common";

import {getToken, REQUEST_BASE} from '@/utils/HttpUtil';

import ViewDataProvider from '@/components/common/dataProvider/common.dataProvider.view';
import ViewManager, {overrideNextMessage} from '@/components/common/common.viewManager';
import Icon from '@/components/common/common.icon';

import style from '@/style/components/main.relation.less';
import intl from 'react-intl-universal';

class RelationAutoRefresh extends React.Component {
  state = {
    autoRefreshing: false,
  };

  autoRefreshNodeTimeout = undefined; // 图谱数据自动刷新标记
  autoRefreshMemberTimeout = undefined; // 图谱协作成员自动刷新标记
  autoFocusNodeInterval = undefined; // 自动定位节点标记
  pendingNodesToFocus = []; // 待定位的节点列表
  refreshedNodeMap = {}; // 定位过的节点信息
  ignoreNextFocusMap = {}; // 忽略下一次定位
  previousTeamworkMembers = undefined; // 已存在的看板成员列表
  ticketToNodeIdMap = {}; // 票据列表

  startRefreshing = () => {
    let me = this, pendingCodes = [];

    me.setState({autoRefreshing: true}, () => {
      me.pendingNodesToFocus = [];
      me.refreshedNodeMap = {};
      me.ignoreNextFocusMap = {};

      let refreshTicketFn = () => {
        overrideNextMessage('static::loadViewTeamworkTickets', false);
        ViewManager.loadViewTeamworkTickets(me.props.viewDataProvider.viewId).then(({data}) => {
          data.forEach(t => {
            _.get(t, 'extraInformation.afterUse', []).find(i => {
              if (i['action'] === 'transfer_owner') {
                me.ticketToNodeIdMap[t.code] = i['nodeId'];
                return true;
              }
            });
          });
          if (pendingCodes && pendingCodes.length > 0) {
            pendingCodes.forEach(code => {
              if (me.ticketToNodeIdMap[code]) {
                // noinspection DuplicatedCode
                if (me.refreshedNodeMap[me.ticketToNodeIdMap[code]] && me.refreshedNodeMap[me.ticketToNodeIdMap[code]] === (node.updateTime || node.linkTime)) {
                  // 节点已经被定位过，或已在定位列表中
                } else {
                  me.pendingNodesToFocus.push(me.ticketToNodeIdMap[code]);
                }
              }
            });
            pendingCodes.length = 0;
          }
        });
      }
      refreshTicketFn();

      if (!me.autoFocusNodeInterval) {
        let autoFocusFn = () => {
          if (me.pendingNodesToFocus.length > 0) {
            let nodeId = me.pendingNodesToFocus.shift(), node = me.props.viewDataProvider.getNode(nodeId);
            while (
              (!node || me.refreshedNodeMap[node.id] === (node.updateTime || node.linkTime))
              && me.pendingNodesToFocus.length > 0
            ) {
              nodeId = me.pendingNodesToFocus.shift();
              node = me.props.viewDataProvider.getNode(nodeId);
              if (me.ignoreNextFocusMap[nodeId]) {
                node = undefined;
                delete me.ignoreNextFocusMap[nodeId];
              }
            }
            if (node) {
              me.props.bus.emit('relation', 'node.presentation.focus', {node: node,type: 'autoRefresh'});
              me.refreshedNodeMap[node.id] = (node.updateTime || node.linkTime);
            }
          }
        };
        me.autoFocusNodeInterval = setInterval(autoFocusFn, 3000);
        autoFocusFn();
      }

      if (!me.autoRefreshNodeTimeout) {
        let autoRefreshNodeFn = () => {
          me.props.viewDataProvider.reloadData().then(() => {
            me.props.viewDataProvider.applyPendingChanges((nodeId, node, action, viewDataProvider, pendingChanges) => {
              if (node && node.userConfirmed) {
                if (me.refreshedNodeMap[nodeId] && me.refreshedNodeMap[nodeId] === (node.updateTime || node.linkTime)) {
                  // 节点已经被定位过，或已在定位列表中
                } else {
                  me.pendingNodesToFocus.push(nodeId);
                }
                if (action === 'add') {
                  // 找一下是否与现有节点关联
                  pendingChanges.edgesToAdd.find(edge => {
                    if (edge.userConfirmed) {
                      let anotherNodeId = edge.from === nodeId ? edge.to : edge.from;
                      if (me.props.viewDataProvider.getNode(anotherNodeId)) {
                        node.withNodeId = anotherNodeId;
                        return true;
                      }
                    }
                    return false;
                  });
                  if (!node.withNodeId) {
                    pendingChanges.edgesToAdd.find(edge => {
                      if (edge.userConfirmed) {
                        let anotherNodeId = edge.from === nodeId ? edge.to : edge.from;
                        return !!pendingChanges.nodesToAdd.find(n => {
                          if (n.id === anotherNodeId && n.withNodeId) {
                            node.withNodeId = n.withNodeId;
                            return true;
                          } else {
                            return false;
                          }
                        });
                      } else {
                        return false;
                      }
                    });
                  }
                }
                return true;
              } else {
                return false;
              }
            }, (edgeId, edge) => edge.userConfirmed);
          }).catch(error => {
            // 忽略错误
            console.error('reloadData failed, ', error);
          }).finally(() => {
            me.autoRefreshNodeTimeout = setTimeout(autoRefreshNodeFn, 30000);
          });
        };
        autoRefreshNodeFn();
      }

      if (!me.autoRefreshMemberTimeout) {
        let autoRefreshMemberFn = () => {
          const nodes = me.props.viewDataProvider.getNode({order: 'updateTime'});

          overrideNextMessage('static::loadViewTeamworkMembers', false);
          ViewManager.loadViewTeamworkMembers(me.props.viewDataProvider.viewId, 10, undefined, 'desc').then(data => {
            // noinspection JSCheckFunctionSignatures
            let newMembers = _.differenceBy(data, me.previousTeamworkMembers, 'userId'), uItemJsx = [];
            me.previousTeamworkMembers = data;
            if (newMembers.length > 0) {
              me.pendingNodesToFocus.length = 0; // 清空待定位节点列表
            }
            newMembers.forEach((u, idx) => {
              uItemJsx.push(
                <div className={style['welcome-member-item']} key={'autoRefreshMsg-' + idx}>
                  <div
                    className={`${style['avatar']} ${u.picId ? '' : style['avatar-default-icon']}`}
                  >
                    <UserAvatar
                      size={'var(--avatar-size)'}
                      name={u.nick}
                      src={u.picId ? `${REQUEST_BASE}/user/user/file/${u.picId}?Authorization=${getToken()}` : undefined}
                      colors={AvatarColors}
                    />
                  </div>
                  {u.nick}
                </div>
              );

              switch (parseInt(u['joinBy'])) {
                case 3: // 邀请码
                  let code = u['joinById'];
                  if (me.ticketToNodeIdMap[code]) {
                    // noinspection DuplicatedCode
                    if (me.refreshedNodeMap[me.ticketToNodeIdMap[code]] && me.refreshedNodeMap[me.ticketToNodeIdMap[code]] === (node.updateTime || node.linkTime)) {
                      // 节点已经被定位过，或已在定位列表中
                    } else {
                      me.pendingNodesToFocus.push(me.ticketToNodeIdMap[code]);
                      me.ignoreNextFocusMap[me.ticketToNodeIdMap[code]] = true;
                    }
                  } else {
                    pendingCodes.push(code);
                  }
                  break;
                default: // 非邀请码的用户昵称
                  let totalPreNode = 0;// 取前三个的计数器
                  nodes.find(n => {
                    if ((n.fname + ' ' + n.description + ' ' + n.tag).indexOf(u.nick) > -1) {
                      if (me.refreshedNodeMap[n.id] && me.refreshedNodeMap[n.id] === (n.updateTime || n.linkTime)) {
                        // 节点已经被定位过，或已在定位列表中
                      } else {
                        me.pendingNodesToFocus.push(n.id);
                        me.ignoreNextFocusMap[n.id] = true;
                        totalPreNode += 1;
                        return totalPreNode >= 3; // 数量足够时返回true，终止循环
                      }
                    }
                    return false;
                  });
              }
            });

            if (pendingCodes.length > 0) {
              refreshTicketFn();
            }

            if (uItemJsx.length > 0) {
              me.props.bus.emit('aiConsole', 'message.push', {
                type: 'ai',
                content: (
                  <div className={style['welcome-member']}>
                    <div>欢迎新成员加入： 👏👏👏</div>
                    {uItemJsx}
                  </div>
                ),
              });
            }
          }).finally(() => {
            me.autoRefreshMemberTimeout = setTimeout(autoRefreshMemberFn, 10000);
          });
        };
        autoRefreshMemberFn();
      }
    });
  };

  stopRefreshing = () => {
    let me = this;
    me.setState({autoRefreshing: false}, () => {
      if (me.autoFocusNodeInterval) {
        clearInterval(me.autoFocusNodeInterval);
        me.autoFocusNodeInterval = undefined;
      }

      if (me.autoRefreshNodeTimeout) {
        clearTimeout(me.autoRefreshNodeTimeout);
        me.autoRefreshNodeTimeout = undefined;
      }

      if (me.autoRefreshMemberTimeout) {
        clearTimeout(me.autoRefreshMemberTimeout);
        me.autoRefreshMemberTimeout = undefined;
      }
    });
  };

  componentDidMount() {
    let me = this;

    me.props.viewDataProvider.once(me, NetworkEvents.LOADING_STRUCTURE_SUCCESS, () => {
      ViewManager.loadViewTeamworkMembers(me.props.viewDataProvider.viewId, 10, undefined, 'desc').then(data => {
        me.previousTeamworkMembers = data;
      });
    });

    me.props.bus.with(me).subscribe('view', 'relation.noce.refreshing', () => {
      me.startRefreshing();
      setTimeout(me.stopRefreshing, 5000);
    })

  }

  componentWillUnmount() {
    this.props.bus.remove(this);
  }

  render() {
    let me = this;

    return (
      <Tooltip title={me.state.autoRefreshing ? intl.get('Custom.menu.autoRefreshingStop') : intl.get('Custom.menu.autoRefreshing')}>
        <Button
          className={'ant-btn-icon-only'}
          onClick={e => {
            e.stopPropagation();
            me.state.autoRefreshing ? me.stopRefreshing() : me.startRefreshing();
          }}
        >
          <Icon name={'sync'} type={IconTypes.ANT_DESIGN} spin={me.state.autoRefreshing} />
        </Button>
      </Tooltip>
    )
  }
}

RelationAutoRefresh.defaultProps = {
  bus: PB,
};

RelationAutoRefresh.propTypes = {
  bus: PropTypes.instanceOf(SimplePB),
  viewDataProvider: PropTypes.instanceOf(ViewDataProvider).isRequired,
};

export default RelationAutoRefresh;