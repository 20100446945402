/*
 * @Author: Carr.s
 * @Date: 2022-04-22 17:40:54
 * @LastEditTime: 2022-07-11 16:11:03
 * @LastEditors: Carr.s
 * @Description: 洞察 产业集聚效应
 * @Copyright by joinmap
 */
import React from "react";
import { message, notification } from "antd";
import ViewInsightVisNetworkTpl from "@/components/insightView/template/insight.vis.tpl";
// import styles from "@/style/components/insightView/insight.less";
import { snapDomain } from "@/constants/sys.config";

class ViewAgglomerationInsight extends ViewInsightVisNetworkTpl {
  // 洞察名称
  _viewerTitle = "产业集聚效应";

  /**
   * 【继承组件要重写】
   * 刷新 vis network
   */
  _refreshNetwork = () => {
    message.destroy();
    message.loading("正在加载产业集聚数据...", 0);

    let me = this;
    const {
      query: { viewId, limit, score, isDebug, isDev, load_fixed },
    } = this.props;
    if (isDebug)
      console.log(
        "🚀 ~ file: insight.agglomeration.js ~ line 26 ~ ViewAgglomerationInsight ~ this.props.history",
        this.props
      );

    // edge 过滤参数
    let _score = 0;
    if (score) {
      _score = parseFloat(score);
    }

    if (isDebug)
      console.log(
        "🚀 ~ file: insight.agglomeration.js ~ line 25 ~ ViewAgglomerationInsight ~ _score",
        _score
      );

    const url = `${snapDomain}/wth_server_api/qiye_jvji_view_id`;
    this._API_MicroServer(
      url,
      { limit, view_id: viewId, score: _score },
      {},
      "get",
      isDebug,
      isDev
    )
      .then((_res) => {
        const { code, data } = _res.data;

        if (code === 0) {
          // const data = require("./data/jv_rong.json");
          const { nodes, edges, calcStatus, calcMsg, CalcData } = data;
          if (calcStatus === 1) {
            message.warning(
              calcMsg ||
                "本洞察用于定制项目的计算，如需申请试用请联系: wangf@joinmap.ai",
              0
            );
            return false;
          }
          if (!nodes) {
            message.warning(
              "本洞察用于定制项目的计算，如需申请试用请联系wangf@joinmap.ai",
              0
            );
            // message.warning("没有计算出产业集聚数据", 0);
            return false;
          }
          let graphFixed = false; // node已固定
          let _edges = [];
          nodes.forEach((n, idx) => {
            nodes[idx].id = idx + 1;
            // 控制固定节点
            if (parseInt(load_fixed) === 0) {
              nodes[idx].fixed = false;
            }
            if (nodes[idx].fixed) {
              graphFixed = true;
            }
            // 处理 tag
            if (!n["tag"] && Array.isArray(n["tags"])) {
              nodes[idx]["tag"] = n["tags"].join(" ");
            }
          });
          edges.forEach((e, idx) => {
            if (e.score > _score) {
              _edges.push({
                from: e.fromIndex,
                to: e.toIndex,
              });
            }
          });
          if (isDebug) {
            console.log(
              "🚀 ~ file: insight.agglomeration.js ~ line 35 ~ ViewAgglomerationInsight ~ data.edges.forEach ~ nodes",
              nodes
            );
            // console.log(
            //   "🚀 ~ file: insight.agglomeration.js ~ line 35 ~ ViewAgglomerationInsight ~ data.edges.forEach ~ _edges",
            //   _edges
            // );
          }
          message.loading("正在计算产业集聚效应...", 0);
          me._myNetwork.addGraph({ nodes, edges: _edges });
          // fit 动画结束后 message 消失
          me._myNetwork.network.once("animationFinished", () => {
            message.loading("正在计算产业集聚效应...", 2);
          });

          if (graphFixed) {
            me._myNetwork.network.stopSimulation();
            me._myNetwork.network.fit({ animation: true });
            // 上传节点坐标
            me.uploadNodePosition();
            // message.destroy();
            // message.loading("正在计算产业集聚效应...", 6);
          } else {
            setTimeout(() => {
              me._myNetwork.network.stopSimulation();
              me._myNetwork.network.fit({ animation: true });
              // 上传节点坐标
              me.uploadNodePosition();
              // message.destroy();
              // message.loading("正在计算产业集聚效应...", 6);
            }, 6000 * 10 * 2);
          }
        } else {
          message.warning("产业集聚数据计算出错,请重试", 0);
        }
      })
      .catch((e) => {
        console.error(
          "🚀 ~ file: insight.agglomeration.js ~ line 90 ~ ViewAgglomerationInsight ~ e",
          e
        );
        message.warning("产业集聚数据请求出错,请重试", 0);
      });
  };

  /**
   * 自定义事件
   * @returns object
   */
  xmyEvents = () => {
    let me = this;
    return {
      click: (params) => {
        me._myNetwork.network.stopSimulation();
      },
      stabilized: (params) => {
        // message.destroy();
      },
      nearlyStabilized: (params) => {
        console.log(
          "🚀 ~ file: insight.vis.tpl.js ~ line 113 ~ ViewInsightVisNetworkTpl ~ stabilized params",
          params
        );
        me._myNetwork.network.stopSimulation();
      },
    };
  };

  /**
   * 上传节点数据
   * @returns
   */
  uploadNodePosition = () => {
    const {
      query: { viewId, isDebug, isDev, save_pos },
    } = this.props;
    console.log(
      "🚀 ~ file: insight.agglomeration.js ~ line 122 ~ ViewAgglomerationInsight ~ this.props",
      this.props
    );
    if (!parseInt(save_pos)) {
      console.log("不保存坐标数据", parseInt(save_pos));
      return false;
    }
    this._myNetwork.network.stopSimulation();
    let posData = this._myNetwork.network.getPositions();
    console.log(
      "🚀 ~ file: insight.agglomeration.js ~ line 120 ~ ViewAgglomerationInsight ~ posData",
      posData
    );
    // 测试
    // Object.keys(posData).forEach((k) => {
    //   posData[k] = { x: 0, y: 0 };
    // });
    // return false;
    let url = "https://snap.joinmap.ai/wth_server_api/get_ji_jv_data";
    // url = "https://aid.joinmap.ai/micro_api/ji_jv_data/save_pos";
    this._API_MicroServer(
      url,
      {},
      { viewId: viewId, data: posData },
      "post",
      isDebug,
      isDev
    )
      .then((_res) => {
        console.log(
          "🚀 ~ file: insight.agglomeration.js ~ line 136 ~ ViewAgglomerationInsight ~ .then ~ _res",
          _res
        );
        // 忽略是否上传成功
      })
      .catch((e) => {
        // 忽略出错
        console.log(
          "🚀 ~ file: insight.agglomeration.js ~ line 139 ~ ViewAgglomerationInsight ~ e",
          e
        );
      });
  };
}

export default ViewAgglomerationInsight;
