import React from 'react';
import PropTypes from "prop-types";
import {withReactStateHelper, autoSetState} from "@/libs/react-state-helper";
import {bindUtil} from "@/libs/core-decorators";

import {Table, Modal, Icon, Input} from 'antd';
import style from "@/style/components/main.view.fileList.less";
import {getToken, REQUEST_BASE} from "@/utils/HttpUtil";
import ViewDataProvider from '@/components/common/dataProvider/common.dataProvider.view';
import {NodeEvents} from "@/libs/view/network/events";
import {NetworkFileListLoadingStatus} from "@/libs/view/network/status";
import {getNodeDisplayTitle} from "@/constants/vis.defaultDefine.1";

const columns = (me) => {
  const currentUserId = parseInt(localStorage.getItem("userId"));
  return [{
    title: '文件名称',
    key: 'fileName',
    dataIndex: 'fileName',
    render: (text, row) => {
      return (
        <div>
          {
            row['fileList'].map(record => (
              <span key={`file-${record['fileId']}`}>
              {record['fileName']}
              <a
                href={`${REQUEST_BASE}/view/project/file/${record['fileId']}?Authorization=${getToken()}`}
                target={'_download'}
                style={{hyphens: 'auto', marginLeft: '0.5em', whiteSpace: 'nowrap'}}
              >
                [ 下载 ]
              </a>
              <br/>
            </span>
            ))
          }
          <span className={style['file-desc']}>说明：{row['remark'] ? row['remark'] : '无'}</span>
        </div>
      )
    },
  }, {
    title: '相关节点',
    key: 'relatedNode',
    width: '20rem',
    render: (text, record) => {
      return me.nodeDisplayTitleMap[record['from']] ? me.nodeDisplayTitleMap[record['from']] : '--';
    },
  }, {
    title: '上传时间',
    key: 'recordTime',
    dataIndex: 'recordTime',
    width: '13rem',
  }, {
    title: '操作',
    width: '5rem',
    key: 'action',
    render: (text, record) => {
      return (
        <div>
          <a onClick={(e) => {
            if (parseInt(record.userId) !== currentUserId) return;
            e.preventDefault();
            me.onRemoveFile(record.id, record.from)
          }}
             className={
               parseInt(record.userId) === currentUserId || me.props.viewCreateUserId === currentUserId
                 ? '' : style['file-delete-disabled']}>
            删除
          </a>
        </div>
      )
    },
  }];
}

@withReactStateHelper
@bindUtil.asTargetClass
class FileList extends React.Component {

  state = {
    searchText: '',
    appliedSearchText: '',
  };

  @autoSetState
  @bindUtil.bindToProperty('props.networkRef', 'fileListLoadingStatus')
  loadingStatus = {status: NetworkFileListLoadingStatus.IDLE};

  @autoSetState
  @bindUtil.bindToProperty('props.networkRef', 'files')
  fileList = [];

  previousFileList = undefined;

  calculatedFileList = []; // 经过筛选的文件列表

  nodeDisplayTitleMap = {}; // 相关节点展示文本

  componentDidMount () {
    let me = this;

    me.props.networkRef.with(me).subscribe(NodeEvents.ATTACHMENT_UPLOADED, () => {
      me.loadingStatus.status = NetworkFileListLoadingStatus.IDLE;
      me.fileList = [];
    }).subscribe(NodeEvents.PROPERTY_REMOVED, () => {
      me.loadingStatus.status = NetworkFileListLoadingStatus.IDLE;
      me.fileList = [];
    });

    me.previousFileList = me.fileList;
  }

  componentWillUnmount () {
    this.props.networkRef.unSubscribe(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let me = this, recalculateNodes = false, reFilter = false;

    if (me.fileList !== me.previousFileList) {
      me.previousFileList = me.fileList;
      recalculateNodes = true;
      reFilter = true;
    }
    if (me.props.visible && !prevProps.visible) {
      recalculateNodes = true;
    }
    if (me.state.appliedSearchText !== prevState.appliedSearchText) {
      reFilter = true;
    }

    if (recalculateNodes) {
      me.nodeDisplayTitleMap = {};
      me.fileList.forEach(info => {
        if (info['from']) {
          if (me.nodeDisplayTitleMap[info['from']] === undefined) {
            let node = me.props.networkRef.getNode(info['from']);
            if (node) {
              me.nodeDisplayTitleMap[info['from']] = getNodeDisplayTitle(node);
            } else {
              me.nodeDisplayTitleMap[info['from']] = false;
            }
          }
        }
      });
    }

    if (reFilter) {
      if (me.state.appliedSearchText === '') {
        me.calculatedFileList = me.fileList;
      } else {
        me.calculatedFileList = me.fileList.filter(info => (
          info['fileList'] && info['fileList'].length > 0
          && !!info['fileList'].find(file => file['fileName'].indexOf(me.state.appliedSearchText) >= 0)
        ) || (
          info['from'] && me.nodeDisplayTitleMap[info['from']]
          && me.nodeDisplayTitleMap[info['from']].indexOf(me.state.appliedSearchText) >= 0
        ));
      }
      setTimeout(() => me.forceUpdate(), 10);
    }
  }

  onTryAgain = (e) => {
    e && e.preventDefault();
    // noinspection JSIgnoredPromiseFromCall
    this.props.networkRef.loadFileList();
  };

  onRemoveFile = (id, nodeId) => {
    let me = this;
    Modal.confirm({
      title: '确认删除该文件吗？',
      okText: '确认',
      okType: 'danger',
      cancelText: '取消',
      onOk() {
        // noinspection JSIgnoredPromiseFromCall
        me.props.networkRef.removeFiles(id, nodeId);
      },
      onCancel() {
        // ignore
      },
    });
  };

  render () {
    let me = this;
    if (me.loadingStatus.status === NetworkFileListLoadingStatus.IDLE) {
      requestAnimationFrame(() => {
        me.onTryAgain();
      });
    }
    switch (me.loadingStatus.status) {
      case NetworkFileListLoadingStatus.FAILED:
        return (
          <div className={style['file-list-failed-to-load']}>
            <div>
              <Icon type="exclamation-circle" theme="outlined"/><br/>
              数据加载失败，错误码：{this.loadingStatus.code}，<a onClick={this.onTryAgain}>&lt;点击重试&gt;</a>
            </div>
          </div>
        );
      default:
        return (
          <React.Fragment>
            <div style={{marginBottom: '0.5em'}}>
              <span style={{lineHeight: 1.5, padding: '5px 0', display: 'inline-block'}}>表格内列出了图谱内全部文件、数据集：</span>
              <Input.Search
                value={me.state.searchText}
                onChange={e => me.setState({searchText: e.target.value})}
                placeholder="支持搜索文件名称、节点名称"
                onSearch={value => me.setState({appliedSearchText: value})}
                className={style['search']}
              />
            </div>
            <Table
              dataSource={me.calculatedFileList}
              rowKey={'id'}
              loading={me.loadingStatus.status === NetworkFileListLoadingStatus.PROCESSING}
              locale={{emptyText: '文件列表为空'}}
              scroll={{y: document.body.offsetHeight - 340}}
              columns={columns(me)}
              pagination={false}
              className={`${style['file-list-table-body']} scrollbar-none`}
              size="middle"
            />
          </React.Fragment>
        );
    }
  }
}

FileList.defaultProps = {
  dataSource: [],
  onRemoveFile: function (id) {
    console.log('onRemoveFile property not set, file id: %s.', id);
  },
  readonly: true,
};

FileList.propTypes = {
  visible: PropTypes.bool.isRequired,
  viewId: PropTypes.string.isRequired,
  networkRef: PropTypes.instanceOf(ViewDataProvider),
  readonly: PropTypes.bool,
	viewCreateUserId: PropTypes.number,
};

export default FileList;
