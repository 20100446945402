import React from 'react';
import PropTypes from 'prop-types';
import _ from "lodash";
import {message} from "antd";

import PB, {SimplePB} from "@/libs/simplePB";
import MyNetwork from '@/libs/myVis/MyNetwork3';

import EventListener from "react-event-listener";
import {getHttpUtil} from "@/utils/HttpUtil";

import ViewDataProvider from '@/components/common/dataProvider/common.dataProvider.view';
import NodeInfoPresentationTooltip, {getNodeTooltipContent} from "@/components/mainView/nodeInfo/main.nodeInfo.presentation.tooltip";

import style from '@/style/common/relation/common.relation.presentation.less';
// import {getNodeDisplayTitle} from "@/constants/vis.defaultDefine.1";

const publicResourceHttpUtil = getHttpUtil(false, '');

class NodePresentation extends React.Component {
  state = {
    showNodeTooltip: false,
    nodeTooltipInfo: undefined,
    nodeInfo: undefined
  };

  currentNodePos = 0; // 当前漫游到的节点位置

  autoStart = false; // 演示初始化及数据加载完毕后自动开始

  initialized = false; // 演示是否初始化完毕

  defaultPresentationConfig = undefined; // 图谱默认演示配置

  customPresentationConfig = {}; // 自定义演示配置

  presentationConfig = undefined; // 合并后演示配置

  presentationWaiting = false; // 演示是否正在等待画面静止

  presentationStarted = false; // 演示是否正在进行中

  presentationStayFocus = false; // 演示进行中，当前画面静止在聚焦状态，手动播放步骤

  presentationInterval = 0; // 演示周期函数标记

  presentationNodeIds = []; // 参与演示的节点ID

  getNodeTooltipContent = nodeId => {
    if (_.isFunction(this.props.getNodeTooltipContent)) {
      return this.props.getNodeTooltipContent(nodeId);
    } else {
      return getNodeTooltipContent(this.props.viewDataProvider, nodeId);
    }
  };

  internalShowNextNode = () => {
    let me = this;

    me.setState({showNodeTooltip: false}, () => {
      me.props.network.presenting(undefined);
      if (!me.presentationStarted) {
        clearInterval(me.presentationInterval);
        me.presentationInterval = 0;
        return;
      }
      let nodeId = me.props.nodeId;
      me.currentNodePos = (me.currentNodePos + 1) % me.presentationNodeIds.length;
      let nodes = [];
      if(me.state.nodeInfo.type == 'notNode'){
        nodes[0] = me.state.nodeInfo;
      }else{
        nodes = me.props.network.getNodes(nodeId);
      }
      if (nodes && nodes.length > 0) {
        let node = nodes[0];
        me.props.bus.emit('node', 'presentation.playing.node', {viewId: me.props.viewId, nodeId: node.id});
        me.props.network.presenting(nodeId);
        me.props.network.network.stopSimulation();
        me.props.network.focus(nodeId);
        let nodeTooltipInfo = undefined ;
        if(me.state.nodeInfo.type !== 'notNode'){
          nodeTooltipInfo = me.getNodeTooltipContent(nodeId);
          if (nodeTooltipInfo.success) {
            me.props.network.network.once('animationFinished', () => {
              setTimeout(() => {
                if (!me.presentationStarted) {
                  clearInterval(me.presentationInterval);
                  me.presentationInterval = 0;
                  me.props.network.presenting(undefined);
                  return;
                }
                // 显示节点Tooltip
                nodeTooltipInfo = {...nodeTooltipInfo};
                this.setState({showNodeTooltip: true, nodeTooltipInfo});
              }, 1500);
            });
          }
        }else{
          nodeTooltipInfo = {success: true,type:'notNode', content:me.state.nodeInfo.description};
          setTimeout(() => {
            // 显示节点Tooltip
            nodeTooltipInfo = {...nodeTooltipInfo};
            this.setState({showNodeTooltip: true, nodeTooltipInfo});
          }, 500);
        }
      }
    });
  };

  internalTryStart = (wait = true) => {
    let me = this;

    const doStart = () => {
      me.presentationWaiting = false;
      if (!me.autoStart) return;
      me.presentationStarted = true;
      me.props.bus.emit('node', 'presentation.started', {viewId: me.props.viewId});
      //me.presentationInterval = setInterval(me.internalShowNextNode, me.presentationConfig['interval']);
      me.internalShowNextNode();
    };
    if (wait) {
      let startTimeout = setTimeout(doStart, 25000); // 25秒后强制开始
      const tryStartFn = () => {
        console.log('in tryStartFn');
        let localStartTimeout = setTimeout(() => {
          console.log('in localStartTimeout');
          if (!me.presentationStarted) {
            console.log('in localStartTimeout --- !me.presentationStarted');
            clearTimeout(startTimeout);
            me.props.network.network.stopSimulation();
            doStart();
          }
        }, 500);
        me.props.network.network.once('startStabilizing', () => {
          clearTimeout(localStartTimeout);
          if (!me.autoStart) {
            me.presentationWaiting = false;
            return;
          }
          if (!me.presentationStarted) {
            me.props.network.network.once('nearlyStabilized', tryStartFn);
          }
        });
      };
      me.props.network.network.once('nearlyStabilized', tryStartFn);
      me.props.network.network.startSimulation();
    } else {
      me.props.network.network.stopSimulation();
      doStart();
    }
  };

  preparePresentationNodeIds = () => {
    let me = this;

    me.presentationNodeIds = me.props.network.graph.nodes.get()/*.filter(node => {
      let content = me.getNodeTooltipContent(node.id);
      return content.success && (content.content || content.image);
    })*/.sort(
      (a, b) => (
        -`${a['updateTime'] || a['linkTime'] || a['accessTime'] }`
          .localeCompare(`${b['updateTime'] || b['linkTime'] || b['accessTime']}`)
      )
    ).map(node => node.id);

    if (
      me.presentationConfig['maxNodeAmount'] &&
      me.presentationNodeIds.length > me.presentationConfig['maxNodeAmount']
    ) {
      me.presentationNodeIds = me.presentationNodeIds.slice(0, me.presentationConfig['maxNodeAmount']);
    }
  };


  componentDidMount() {
    let me = this;

    // 初始化
    me.props.bus.sub(me, 'node', 'presentation.do_init', ({viewId, autoStart, noWait}) => {
      if (me.props.viewId && viewId !== me.props.viewId) return;
      const showWaitingTimeout = {timeout: 0, msgShown: false, timestamp: 0};
      if (noWait === true) {
        showWaitingTimeout.timestamp = (new Date()).getTime();
        showWaitingTimeout.timeout = setTimeout(() => {
          showWaitingTimeout.msgShown = true;
        }, 500);
      }
      const fn = data => {
        me.defaultPresentationConfig = data;
        me.initialized = true;
        me.autoStart = (autoStart === true);
        me.props.bus.emit('node', 'presentation.initialized', {viewId});
        if (me.autoStart) {
          if (showWaitingTimeout.msgShown) {
            let currentTimestamp = (new Date()).getTime();
            setTimeout(() => {
              me.props.bus.emit('node', 'presentation.do_start', {viewId, noWait});
            }, Math.max(1, showWaitingTimeout.timestamp + 2000 - currentTimestamp));
          } else {
            if (noWait === true) clearTimeout(showWaitingTimeout.timeout);
            me.props.bus.emit('node', 'presentation.do_start', {viewId, noWait});
          }
        }
      };
      if (me.props.viewId) {
        publicResourceHttpUtil.get(`/assets/presentation/${me.props.viewId}.json`).then(response => {
          if (response && response.data && _.isObject(response.data)) {
            fn(response.data);
          } else {
            // 尝试获取默认配置文件
            publicResourceHttpUtil.get(`/assets/presentation/_default.json`).then(response => {
              if (response && response.data) {
                fn(response.data);
              }
            });
          }
        }).catch(() => {
          // 尝试获取默认配置文件
          publicResourceHttpUtil.get(`/assets/presentation/_default.json`).then(response => {
            if (response && response.data) {
              fn(response.data);
            }
          });
        });
      } else {
        // 尝试获取默认配置文件
        publicResourceHttpUtil.get(`/assets/presentation/_default.json`).then(response => {
          if (response && response.data) {
            fn(response.data);
          }
        });
      }
    });

    // 尝试开始演示
    me.props.bus.sub(me, 'node', 'presentation.do_start', ({noWait, config}) => {
      if (me.presentationWaiting || me.presentationStarted) {
        if (config) {
          me.props.bus.emit('node', 'presentation.do_pause', {noMsg: true});
          me.props.bus.emit('node', 'presentation.do_start', {noWait, config});
          return;
        }
      }
      me.customPresentationConfig = config || me.customPresentationConfig;
      me.currentNodePos = 0;
      if (me.initialized !== true) {
        me.props.bus.emit('node', 'presentation.do_init',
          {viewId: me.props.viewId, autoStart: true, noWait: noWait === true});
      } else  {
        me.autoStart = true;
        me.presentationConfig = _.merge({'interval':3000}, me.defaultPresentationConfig, me.customPresentationConfig);
        me.internalTryStart(noWait !== true);
      }
    });

    // 暂停演示
    me.props.bus.sub(me, 'node', 'presentation.do_pause', ({noMsg} = {noMsg: false}) => {
      me.autoStart = false;
      me.presentationStarted = false;
      if (me.presentationInterval > 0 || me.presentationStayFocus) {
        if (me.presentationInterval > 0) {
          clearTimeout(me.presentationInterval);
          me.presentationInterval = 0;
        }
        if (me.presentationStayFocus) {
          me.presentationStayFocus = false;
        }
        me.props.bus.emit('relation', 'presentation.stopped', {viewId: me.props.viewId});
      }
      me.setState({showNodeTooltip: false});
      me.props.network.presenting(undefined);
    });

    // 更新默认漫游配置
    me.props.bus.sub(me, 'node', 'presentation.default_config.update', (config, resetCurrentNodePos = false) => {
      let updateDefaultConfigFn = () => {
        // noinspection JSValidateTypes
        me.defaultPresentationConfig = _.merge({}, me.defaultPresentationConfig, config);
        // noinspection JSValidateTypes
        me.presentationConfig = _.merge({}, me.defaultPresentationConfig, me.customPresentationConfig);
        if (resetCurrentNodePos) {
          me.currentNodePos = 0;
          me.presentationNodeIds = [];
        }
      };
      if (me.initialized !== true) {
        me.props.bus.once(me, 'node', 'presentation.initialized', () => updateDefaultConfigFn());
        me.props.bus.emit('node', 'presentation.do_init', {viewId: me.props.viewId});
      } else {
        updateDefaultConfigFn();
      }
    });

    // 设置自定义漫游配置
    me.props.bus.sub(me, 'node', 'presentation.custom_config.set', (config, resetCurrentNodePos = false) => {
      me.customPresentationConfig = config || {};
      // noinspection JSValidateTypes
      me.presentationConfig = _.merge({}, me.defaultPresentationConfig, me.customPresentationConfig);
      if (resetCurrentNodePos) {
        me.currentNodePos = 0;
      }
    });

    //点击节点显示详情
    PB.sub(this, 'node', 'presentation.do', (value) => {
      me.setState({nodeInfo: value.node}, () => {
        me.props.bus.emit('node', 'presentation.do_start', { noWait: true});
        me.props.bus.emit('relation', 'presentation.do_hold');
      })
    });

    PB.sub(this, 'node', 'presentation.stop', () => {
      me.props.bus.emit('node', 'presentation.do_pause', {noMsg: true});
    });

    const stopPresentationFn = () => {
      me.props.bus.emit('relation', 'node.presentation.stop');
    };
    me.props.network.subscribe(me, 'NETWORK.click', stopPresentationFn);
    me.props.network.subscribe(me, 'NETWORK.dragStart', stopPresentationFn);
    me.props.network.subscribe(me, 'NETWORK.zoom', stopPresentationFn);
    me.props.bus.sub(me, 'network', 'focus', stopPresentationFn);
  }

  componentWillUnmount() {
    this.props.bus.remove(this);
    this.props.network.unSubscribe(this);
  }

  render() {
    let me = this;
    return (
      <div className={style['frame']}>
        {
          this.state.showNodeTooltip && this.state.nodeTooltipInfo &&
          this.state.nodeTooltipInfo.success && (
            <NodeInfoPresentationTooltip {...this.state.nodeTooltipInfo} />
          )
        }
        <EventListener
          target={window}
        />
      </div>
    );
  }
}

NodePresentation.defaultProps = {
  bus: PB,
  getNodeTooltipContent: false,
};

NodePresentation.propTypes = {
  bus: PropTypes.instanceOf(SimplePB),
  viewDataProvider: PropTypes.instanceOf(ViewDataProvider),
  network: PropTypes.instanceOf(MyNetwork).isRequired,
  viewId: PropTypes.string,
  getNodeTooltipContent: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
};

export default NodePresentation;