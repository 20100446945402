const stateKey = 'view';

const getCachedDataStatus = (state, {viewId, dataType}) => {
  return state[stateKey] && state[stateKey].viewDataCache && state[stateKey].viewDataCache[viewId] ?
    (state[stateKey].viewDataCache[viewId][`${dataType}Status`] || 'idle') : 'idle';
};

const getCachedData = (state, {viewId, dataType}) => {
  return state[stateKey] && state[stateKey].viewDataCache && state[stateKey].viewDataCache[viewId] ?
    state[stateKey].viewDataCache[viewId][dataType] : undefined;
};

export const getCachedViewInfoStatus = (state, {viewId}) => {
  return getCachedDataStatus(state, {viewId, dataType: 'viewInfo'});
};

export const getCachedViewInfo = (state, {viewId}) => {
  return getCachedData(state, {viewId, dataType: 'viewInfo'});
};

export const getCachedViewStructureStatus = (state, {viewId}) => {
  return getCachedDataStatus(state, {viewId, dataType: 'viewStructure'});
};

export const getCachedViewNodesStatus = (state, {viewId}) => {
  return getCachedDataStatus(state, {viewId, dataType: 'viewNodes'});
};

export const getCachedViewRelationStatus = (state, {viewId}) => {
  return getCachedDataStatus(state, {viewId, dataType: 'viewRelation'});
};