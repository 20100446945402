import React from "react";
import PropTypes from 'prop-types';
import {Button, Checkbox, Modal} from "antd";

import guideImage from '@/assets/relation_guide.png';

class GuideModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      autoShow: localStorage.getItem('show_relation_guide') !== '0',
    };
  }

  onClose = () => {
    localStorage.setItem('show_relation_guide', this.state.autoShow ? '1' : '0');
    this.props.onClose();
  };

  render() {
    let me = this;

    return (
      <Modal
        title={'图谱操作指南'}
        visible={me.props.visible}
        width={1048}
        centered
        onCancel={me.onClose}
        footer={[
          (
            <Checkbox
              key={'tip'}
              style={{float: 'left', marginLeft: '8px', height: '32px', lineHeight: '32px'}}
              checked={!me.state.autoShow}
              onChange={e => me.setState({autoShow: !e.target.checked})}
            >
              以后不再显示
            </Checkbox>
          ),
          (<Button key={'close'} style={{marginRight: '8px'}} onClick={me.onClose}>关闭</Button>),
        ]}
      >
        <img src={guideImage} alt={'图谱操作指南'} style={{height: '570px', width: '1000px'}} />
      </Modal>
    );
  }
}

GuideModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default GuideModal;