import React from 'react';

import style from '@/style/components/common.authForm.less';
import logo from '@/assets/site_logo_28.png';

class CommonAuthForm extends React.Component {
  render() {
    return (
      <div className={style['panel']}>
        <div className={style['logo-container']}><img src={logo} alt={'Logo'} />JoinMap.Ai</div>
        {this.props.children}
      </div>
    );
  }
}

export default CommonAuthForm;