import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Icon, message, Checkbox } from 'antd';
import style from '@/style/common/relation/common.relation.nodeFilter.less';
import { IconTypes } from '@/constants/common';
import { getNodeDisplayTitle } from '@/constants/vis.defaultDefine.1';
import PB from '@/libs/simplePB';
import anime from 'animejs';
import EventListener from 'react-event-listener';

class NodeFilter extends React.PureComponent {
	state = {
		filterKey: undefined,
		nodeListSelected: [], // 当前选择的节点列表
		currentItemKey: undefined, // 当前选择的
		showStatisticsGrid: false, // 是否展示统计表
	};

	statisticsElement = undefined; // 节点统计组件DOM对象

	lastHoverKey = {
		list: undefined,
		statistics: undefined,
	};

	ignoreMouseMoveEvent = false; // 是否忽略鼠标移动事件，按下鼠标的时候需要忽略

	// 统计表自动展示触发区域
	showStatisticsPanelTriggerArea = {
		minX: 0,
		maxX: 0,
		minY: 0,
		maxY: 0,
	};

	// 统计表自动隐藏排除区域
	hideStatisticsPanelTriggerArea = {
		minX: 0,
		maxX: 0,
		minY: 0,
		maxY: 0,
	};

	statisticsGridAnim = undefined; // 统计表格动画对象

	onMouseMove = () => {
		// fake empty function, initialized after mount 3 seconds
	};

	recalculateTriggerArea = () => {
		let me = this;

		me.showStatisticsPanelTriggerArea = {
			minX: 0,
			maxX: 0,
			minY: 0,
			maxY: 0,
		};
		me.hideStatisticsPanelTriggerArea = {
			minX: 0,
			maxX: 0,
			minY: 0,
			maxY: 0,
		};
		if (me.statisticsElement) {
			{
				let {left: minX, right: maxX, top: minY, bottom: maxY} = me.statisticsElement.children[0].getBoundingClientRect();
				maxX = Math.max(maxX, 10);
				me.showStatisticsPanelTriggerArea = {minX, maxX, minY, maxY};
			}
			{
				let {left: minX, right: maxX, top: minY, bottom: maxY} = me.statisticsElement.getBoundingClientRect();
				maxX = Math.max(maxX, 10);
				me.hideStatisticsPanelTriggerArea = {minX, maxX, minY, maxY};
			}
		}
	};

	animateStatisticsGrid = () => {
		let me = this;
		const {statisticsGridAnim} = me;

		if (statisticsGridAnim) stopAnimation(statisticsGridAnim);

		requestAnimationFrame(() => {
			me.statisticsGridAnim = anime({
				targets: me.statisticsElement,
				translateX: me.state.showStatisticsGrid ? 0 : -me.statisticsElement.children[0].getBoundingClientRect().width,
				duration: 400,
				easing: 'easeInOutCirc',
				update: function() {
					me.recalculateTriggerArea();
				},
			});
		});
	};

	onComponentHover = (type, key) => {
		let me = this;

		/*if (me.lostHoverTimeout[type]) {
			clearTimeout(me.lostHoverTimeout[type]);
			me.lostHoverTimeout[type] = undefined;
		}*/

		if (!me.lastHoverKey[type]) {
			me.lastHoverKey[type] = key;
			me.forceUpdate();
		} else {
			me.lastHoverKey[type] = key;
		}
	};

	delayComponentLostHover = (type, key) => {
		let me = this;

		/*if (me.lostHoverTimeout[type]) {
			clearTimeout(me.lostHoverTimeout[type]);
		}*/

		/*me.lostHoverTimeout[type] = */setTimeout(() => {
			if (me.lastHoverKey[type] === key) {
				me.lastHoverKey[type] = undefined;
				me.forceUpdate();
			}
		}, 10);
	};

	componentDidMount() {
		let me = this;

		setTimeout(() => {
			me.onMouseMove = e => {
				if (me.statisticsElement && !me.ignoreMouseMoveEvent &&
					Object.values(me.lastHoverKey).filter(v => !!v).length === 0) {

					if ((e.clientX >= me.showStatisticsPanelTriggerArea.minX &&
						e.clientX <= me.showStatisticsPanelTriggerArea.maxX &&
						e.clientY >= me.showStatisticsPanelTriggerArea.minY &&
						e.clientY <= me.showStatisticsPanelTriggerArea.maxY) || me.state.nodeListSelected.length === 0) {

						// 需要显示
						if (!me.state.showStatisticsGrid) {
							requestAnimationFrame(() => me.setState({showStatisticsGrid: true},
								() => me.animateStatisticsGrid()));
						}
					} else if (me.state.nodeListSelected.length > 0) {
						// 需要隐藏
						if (me.state.showStatisticsGrid) {
							requestAnimationFrame(() => me.setState({showStatisticsGrid: false},
								() => me.animateStatisticsGrid()));
						}
					}
				}
			};
		}, 3000);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		let me = this;

		if (me.props.currentAllMapList !== prevProps.currentAllMapList) {
			this.setState({
				nodeListSelected: [],
			});
		}
	}

	/**
	 * 重置过滤器
	 */
	resetFilter = (type, filterItem) => {
		let me = this,selectArr = [];
		const {filterType, filterCurKey} = me.props;

		if (filterType[filterCurKey].key === type) {
			return;
		}

		if (type === 'statistics') {
			if (me.state.currentItemKey !== filterItem.code) {
				let filterKey = filterItem.code.substr(0, 2);
				Object.values(me.props.detailListSeriesData).forEach(item => {
					if (item.china_adc.substr(0, 2) === filterKey) {
						selectArr.push(item);
					}
				});

				this.setState({
					currentItemKey: filterItem.code,
					nodeListSelected: selectArr,
				});
			} else {
				this.setState({
					nodeListSelected: [], // 当前选择的节点列表
					currentItemKey: 'none', // 当前选择的
				})
			}

		} else {
			PB.emit('aiConsole', 'message.push', {
				type: 'ai',
				content: `图谱切换数据源`,
			});
			me.props.resetNodes(type);
			this.setState({
				currentItemKey: undefined,
			});
		}
	};

	render() {
		let me = this;
		const {currentAllMapList, detailListSeriesData, filterType, filterCurKey} = me.props;
		// console.log(' relation.map.nodeFilter -> me.state.nodeListSelected ->  :', me.state.nodeListSelected);
		// console.log(' relation.map.nodeFilter -> detailListSeriesData ->  :', detailListSeriesData);
		// console.log(' relation.map.nodeFilter -> currentAllMapList ->  :', currentAllMapList);
		// console.log(' relation.map.nodeFilter -> filterType ->  :', filterType);
		// console.log(' relation.map.nodeFilter -> filterCurKey ->  :', filterCurKey);
		// console.log(' relation.map.nodeFilter -> filterType[filterCurKey].key ->  :', filterType[filterCurKey].key);

		return (
			<div className={`${style['frame']} dark-theme`}
			     style={{zIndex: 100}}
			     ref={ele => {
				     if (!ele || ele === me.statisticsElement) return;
				     me.statisticsElement = ele;
				     me.recalculateTriggerArea();
			     }}
			>
				<EventListener
					target={window}
					onResize={() => me.recalculateTriggerArea()}
					onMouseDown={() => me.ignoreMouseMoveEvent = true}
					onMouseUp={() => me.ignoreMouseMoveEvent = false}
					onMouseMove={e => me.onMouseMove(e)}
				/>
				<div className={`${style['statistics-frame']} ${me.lastHoverKey['statistics'] ? 'hover' : ''}`}>
					<div className={`${style['statistics-content-frame']} ${me.lastHoverKey['statistics'] ? 'hover' : ''}`}>
						<table>
							<thead>
							<tr>
								<th colSpan={3}>类型：</th>
							</tr>
							</thead>
							<tbody>
							{
								Object.values(filterType).map(item => (
									<Tooltip
										title={`图谱${item.text}节点`}
										key={item.key}
										placement={'right'}
									>
										<tr
											className={`${item.key === filterType[filterCurKey].key ? 'highlighted' : null}`}
											onClick={() => {
												me.resetFilter(item.key, null);
											}}
										>
											<td colSpan={3} style={{textAlign: 'left'}}>{item.text}</td>
										</tr>
									</Tooltip>
								))
							}
							</tbody>
						</table>

						{
							currentAllMapList && Object.keys(currentAllMapList).length > 0 ? (
								<React.Fragment>
									<hr/>
									<table>
										<thead>
										<tr>
											<th colSpan={3} style={{textAlign: 'left'}}>{filterType[filterCurKey].text}统计：</th>
										</tr>
										</thead>
										<tbody>
										{currentAllMapList && currentAllMapList.map(item => (
											<Tooltip
												title={`“${item.name}”数量统计`}
												key={item.name}
												placement={'right'}
												mouseLeaveDelay={0.05}
												onVisibleChange={visible =>
													visible ? me.onComponentHover('statistics', item.code) :
														me.delayComponentLostHover('statistics', item.code)}
											>
												<tr
													className={`${me.state.currentItemKey === item.code ? 'highlighted' : null}`}
													onClick={e => {
														e.stopPropagation();
														me.resetFilter('statistics', item);
													}}
												>
													<td><Icon type="environment" style={{fontSize: '0.8rem'}}/></td>
													<td>{item.name}</td>
													<td>{item.value}</td>
												</tr>
											</Tooltip>
										))}
										</tbody>
									</table>
								</React.Fragment>
							) : null
						}
					</div>
				</div>
				{
					me.state.nodeListSelected.length > 0 ? (
						<div className={`${style['list-frame']} ${me.lastHoverKey['list'] ? 'hover' : ''}`} style={{paddingTop: (Object.values(filterType).length + 3) + 'rem'}}>
							<div className={`${style['list-content-frame']} scrollbar ${me.lastHoverKey['list'] ? 'hover' : ''}`}>
								<div>
									{
										me.state.nodeListSelected.map(item => (
											<div key={`c-${item.china_adc}`}>
												<div style={{opacity: 0.5, padding: '.5rem 0.5rem .2rem .5rem'}}>{item.name}</div>
												<ul>
													{
														item.list.map(node => node.description ? (
															<Tooltip
																key={`n-${node.id}`}
																placement={'right'}
																title={
																	<div className={`${style['list-content-frame']} scrollbar`}
																	     style={{maxHeight: '15rem', overflow: 'auto'}}>
																		{
																			node.description.split('\n').map((line, idx) => (
																				<span key={`ln-${idx}`}>{line}<br/></span>
																			))
																		}
																	</div>
																}
																mouseEnterDelay={0.3}
																mouseLeaveDelay={0.05}
																// getPopupContainer={() => document.getElementById('listContent')}
															>
																<li onClick={() => me.props.showNodeInfo(node)} style={{padding: "0.2rem 0.5rem"}}>
																	{node.fname}
																</li>
															</Tooltip>
														) : (
															<li key={`n-${node.id}`}
															    onClick={() => me.props.showNodeInfo(node)} style={{padding: "0.2rem 0.5rem"}}>
																{node.fname}
															</li>
														))
													}
												</ul>
											</div>
										))
									}
								</div>
							</div>
						</div>
					) : null
				}
			</div>
		);
	}
}

NodeFilter.defaultProps = {
	viewId: '',
	currentAllMapList: [],
	detailListSeriesData: {},
};

NodeFilter.propTypes = {
	viewId: PropTypes.string,
	currentAllMapList: PropTypes.array.isRequired,
	detailListSeriesData: PropTypes.object,
	showNodeInfo: PropTypes.func,
	filterType: PropTypes.object, // 如果用户筛选项列表
	filterCurKey: PropTypes.string, // 当用显示的筛选值
	resetNodes: PropTypes.func, // 切换筛选节点，筛选点还是全部
};

export default NodeFilter;


const stopAnimation = animations => {
	/*
	 This used to just pause any remaining animation
	 but anime gets stuck sometimes when an animation
	 is trying to tween values approaching 0.

	 Basically to avoid that we're just forcing near-finished
	 animations to jump to the end.

	 This is definitely a hack but it gets the job done—
	 if the root cause can be determined it would be good
	 to revisit.
	 */
	const stop = anim => {
		const { duration, remaining } = anim;
		if (remaining === 1) anim.seek(duration);
		else anim.pause();
	};
	if (Array.isArray(animations)) animations.forEach(anim => stop(anim));
	else stop(animations);
};