export const distinctFn = ({nodes, distinct, cb}, threshold = 2000) => {
  // 去重
  if (distinct) {
    let nodeMap = {}, pos = 0, batch, r = [], workFn = () => {
      batch = 0;
      while (batch < threshold && pos < nodes.length) {
        let n = nodes[pos];
        if (!nodeMap[n['fname']]) {
          nodeMap[n['fname']] = true;
          r.push(n);
        }
        pos++;
      }
      if (pos < nodes.length) {
        setTimeout(workFn, 5);
      } else {
        cb(r);
      }
    };
    workFn();
  } else {
    cb(nodes);
  }
};