/*
 * @Author: Carr.s
 * @Date: 2022-07-22 10:08:27
 * @LastEditTime: 2022-07-22 15:54:30
 * @LastEditors: Carr.s
 * @Description: 节点的子弹评估, 评估详情
 * @Copyright by joinmap
 */
import React from "react";
import PropTypes from "prop-types";
import {
  Modal,
  Button,
  Popover,
  Radio,
  message,
  Form,
  Select,
  Row,
  Col,
  Input,
  List,
} from "antd";
import PB, { SimplePB } from "@/libs/simplePB";
import nodeStyle from "@/style/components/mainView/node.less";
import Icon from "@/components/common/common.icon";
import { IconTypes } from "@/constants/common";

class NodeAssessDetail extends React.Component {
  state = {};

  componentDidMount() {
    console.log(
      "🚀 ~ file: common.node.assess.detail.js ~ line 33 ~ NodeAssessDetail ~ componentDidMount",
      this.props
    );
  }

  componentWillUnmount() {
    this.props.bus.remove(this);
  }

  render() {
    let me = this;
    const { assessInfo, fields, showTitle } = this.props;
    let lineArr = ["line1", "line2", "line3", "line4", "line5"];

    const defaultFields = {
      mainPoints: "目标要点",
      advantage: "有利因素",
      disadvantage: "不利因素",
      shortTerm: "近期预判",
      midTerm: "中期预判",
    };
    let curFields = {};
    if (Array.isArray(fields) && fields) {
      fields.forEach((f) => {
        if (defaultFields.hasOwnProperty(f)) {
          curFields[f] = defaultFields[f];
        }
      });
    } else {
      curFields = defaultFields;
    }
    const data = [];
    if (assessInfo) {
      // new
      Object.keys(curFields).forEach((f) => {
        console.log(
          "🚀 ~ file: common.node.assess.detail.js ~ line 103 ~ NodeAssessDetail ~ Object.keys ~ f",
          f
        );
        let d = JSON.parse(assessInfo[f]);
        let iconName = "access.png";
        if (f === "mainPoints") {
          iconName = "assess_1.png";
        } else if (f === "advantage") {
          iconName = "assess_2.png";
        } else if (f === "disadvantage") {
          iconName = "assess_3.png";
        } else if (f === "shortTerm") {
          iconName = "assess_4.png";
        } else if (f === "midTerm") {
          iconName = "assess_5.png";
        }

        let fieldData = [];
        let idx = 0; // 每一行前面的序号
        lineArr.forEach((l) => {
          if (d.hasOwnProperty(l)) {
            if (f === "shortTerm") {
              if (l === "line1") {
                fieldData.push(idx + 1 + "、一天内达成可能性: " + d[l] + "%");
              } else if (l === "line2") {
                fieldData.push(idx + 1 + "、三天内达成可能性: " + d[l] + "%");
              } else if (l === "line3") {
                fieldData.push(idx + 1 + "、七天内达成可能性: " + d[l] + "%");
              }
            } else if (f === "midTerm") {
              if (l === "line1") {
                fieldData.push(idx + 1 + "、一个月内达成可能性: " + d[l] + "%");
              } else if (l === "line2") {
                fieldData.push(idx + 1 + "、三个月内达成可能性: " + d[l] + "%");
              } else if (l === "line3") {
                fieldData.push(idx + 1 + "、六个月内达成可能性: " + d[l] + "%");
              }
            } else {
              fieldData.push(idx + 1 + "、" + d[l]);
            }
            idx++;
          } else {
            // fieldData.push(idx + 1 + "、");
          }
        });

        if (fieldData) {
          data.push({
            type: f,
            icon: iconName,
            title: curFields[f],
            data: fieldData.join("\n"),
          });
        } else {
          data.push({
            type: f,
            icon: iconName,
            title: curFields[f],
            data: undefined,
          });
        }
      });

      // old
      // data.push({
      //   title: "目标要点",
      //   data: assessInfo.mainPoints,
      // });
      // data.push({
      //   title: "有利因素",
      //   data: assessInfo.advantage,
      // });
      // data.push({
      //   title: "不利因素",
      //   data: assessInfo.disadvantage,
      // });
      // data.push({
      //   title: "近期预判",
      //   data: assessInfo.shortTerm,
      // });
      // data.push({
      //   title: "中期预判",
      //   data: assessInfo.midTerm,
      // });
    }

    const iconStyle = {
      width: 22,
      height: 22,
    };

    return (
      <>
        <List
          itemLayout="horizontal"
          dataSource={data}
          renderItem={(item) => {
            return (
              <List.Item
                style={{ /*minHeight: 100,*/ alignItems: "flex-start" }}
              >
                <List.Item.Meta
                  title={
                    showTitle ? (
                      <div>
                        {/* <Icon name={"icon-start"} type={IconTypes.ICON_FONT} /> */}
                        <img
                          src={`/assets/icons/${item.icon}`}
                          style={iconStyle}
                        />

                        <span
                          style={{
                            borderBottom: "3px solid #00BFFF",
                            marginLeft: 5,
                          }}
                        >
                          <strong>{item.title}</strong>
                        </span>
                        <span>：</span>
                      </div>
                    ) : null
                  }
                  description={
                    <div
                      style={
                        showTitle
                          ? {
                              backgroundColor: "#fff",
                              whiteSpace: "pre-wrap",
                              borderRadius: 3,
                              padding: 10,
                              // minHeight: 100,
                              color: "#000",
                              marginLeft: 28,
                              marginTop: 5,
                            }
                          : {
                              // backgroundColor: "#fff",
                              whiteSpace: "pre-wrap",
                              borderRadius: 3,
                              padding: "0 10px",
                              // minHeight: 60,
                              color: "#000",
                              marginLeft: 0,
                              marginTop: 0,
                            }
                      }
                    >
                      {item.data ? (
                        item.data
                      ) : (
                        <span style={{ color: "#CDC9C9" }}>无内容</span>
                      )}
                    </div>
                  }
                />
              </List.Item>
            );
          }}
        />
      </>
    );
  }
}

NodeAssessDetail.defaultProps = {
  bus: PB,
  assessInfo: undefined,
  fields: undefined,
  showTitle: true,
};

NodeAssessDetail.propTypes = {
  bus: PropTypes.instanceOf(SimplePB),
  assessInfo: PropTypes.object,
  fields: PropTypes.array, // 显示子项列表
  showTitle: PropTypes.bool, // 显示子项标题
};

export default NodeAssessDetail;
