import React from 'react';
import PropTypes from 'prop-types';
import {Icon as AntDIcon} from "antd";

import {IconTypes} from "@/constants/common";
// import {IconFont} from "@/components/common/common.fonticon";

import Config from '@/constants/sys.config'

const IconFont = AntDIcon.createFromIconfontCN({
  scriptUrl: Config.iconFont, // generated by iconfont.cn
});

export default class Icon extends React.Component {
  render () {
    let passedProps = {...this.props};
    let result, {type, name, color} = passedProps;
    delete passedProps.type;
    delete passedProps.name;
    switch (type) {
      case IconTypes.FONT_AWESOME:
        result = <i className={`fa fa-${name}`} {...passedProps} />;
        break;
      case IconTypes.ICON_FONT:
        let style = {color, ...passedProps.style || {}};
        delete passedProps.style;
        result = <IconFont type={name} style={style} {...passedProps} />;
        break;
      default:
        result = <AntDIcon type={name} {...passedProps} />;
    }
    return result;
  }
}

Icon.defaultProps = {
  type: IconTypes.ANT_DESIGN,
  name: 'question-circle',
};

Icon.propTypes = {
  type: PropTypes.number,
  name: PropTypes.string,
};