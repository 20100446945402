/*
 * @Author: Carr.s
 * @Date: 2022-04-25 18:39:27
 * @LastEditTime: 2022-05-16 09:49:26
 * @LastEditors: Carr.s
 * @Description: 产业链洞察1
 * @Copyright by joinmap
 */
import React from "react";
import ViewInsightChartTpl from "@/components/insightView/template/insight.chart.tpl";
import { connect } from "react-redux";
import styles from "@/style/components/insightView/insight.less";
import Highcharts from "highcharts";
import highcharts3d from "highcharts/highcharts-3d";
highcharts3d(Highcharts);

class ViewTaskProcessInsight extends ViewInsightChartTpl {
  //===============================================================
  // begin of overwrite
  // 使用默认的方式就不用重写

  // ------------------------------------------------------------
  // 全局配置
  // ------------------------------------------------------------
  // 显示底部按钮
  showToolbar = false;
  // 洞察名称
  viewerTitle = "产业链洞察 企业分类统计图";
  _viewerTitleRender = () => {
    return "";
  };

  // ------------------------------------------------------------
  // 层级分析对象的配置
  // ------------------------------------------------------------
  // 第一层 level1 tag
  column1_showType = "list"; // 第一列的展现形式，list=显示列，tree=显示树
  // column1_DataField = "data"; // 取值字段
  // column1_TotalField = "dataTotal"; // 计算总数(与返回数量不一定一致)字段
  column1_treeFields = []; // 用树状目录显示数据时各层级的 children 字段名数据
  column1_Title = "企业分类"; // 显示名称
  column1_Unit = "个"; // 显示的文字单位

  // 第二层 item
  column2_DataField = "cop"; // 取值字段
  column2_TotalField = "value"; // 计算总数(与返回数量不一定一致)字段
  column2_Title = "企业"; // 显示名称
  column2_Unit = "家"; // 显示的文字单位
  column2_ValueRender = (level2_data, idx) => {
    // 右侧数值渲染，不输出则空白
    // 必须加上 className={styles["line-value"]}
    // 不显示
  };

  // ------------------------------------------------------------
  // 接口的配置
  // ------------------------------------------------------------
  // 接口 url path
  // api_path = "";
  api_path = "/wth_server_api/mian_ji_pic_by_view_id";

  // 返回数据默认值
  defaultLimit = 60;

  /**
   * 【继承组件要重写】
   * api 请求完成后，setState结束后的 callback
   */
  apiLoadedCallback = () => {
    // message.info("数据加载成功");
  };

  // ------------------------------------------------------------
  // 图表的配置
  // ------------------------------------------------------------
  // 图表显示库
  chartType = "highcharts";
  chartDivLeft = 0;

  /**
   * 生成公用的、默认的 option
   * @returns
   */
  initChartOption = () => {
    let me = this;
    let _chartOption = {
      colors: this._chartColors,
      credits: {
        enabled: false, // 禁用版权信息
      },
      chart: {
        backgroundColor: null,
        // 指定内边距，下面的四个配置可以用 spacing: [10, 10, 15, 10] 来代替
        spacingBottom: 0,
        spacingTop: 10,
        spacingLeft: 10,
        spacingRight: 10,
        // 指定图表大小
        // width: "50%", // width 不支持百分数
        // width: Math.round(document.documentElement.clientWidth / 2),
        // height: document.documentElement.clientHeight - 70 + "px",
        type: "pie",
        options3d: {
          enabled: true,
          alpha: 60, //倾斜角度
          beta: 0,
        },
      },
      title: {
        text: "",
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        backgroundColor: "#fff",
        // pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
        pointFormat:
          "企业: <b>{point.y}家</b> {series.name}: <b>{point.percentage:.1f}%</b>",
        style: {
          fontSize: 14,
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          slicedOffset: 50,
          depth: 30,
          dataLabels: {
            enabled: true,
            // format: "{point.name}: <b>{point.percentage:.1f}%</b>",
            format: "{point.name}: <b>{point.y}家</b>",
            style: {
              color: "#858b91",
              textOutline: "none",
              fontWeight: "normal",
              fontSize: "1rem",
            },
          },
          point: {
            events: {
              click: (e) => {
                const {
                  query: { isDebug },
                } = me.props;
                if (isDebug) console.log("point event e:", e);
                me._column1_onClick(e.point.index, e.point.name, false);
              },
            },
          },
        },
      },
      series: [
        {
          type: "pie",
          name: "占比",
          data: [],
        },
      ],
    };
    return _chartOption;
  };

  /**
   * 【继承组件要重写】
   * 生成 chart 的 options
   * @param {int} idx 选中的数据下标
   */
  refreshChartOptions = (nodePath = null) => {
    const {
      query: { isDebug },
    } = this.props;
    if (isDebug) console.log("refreshChartOptions 0 nodePath:", nodePath);
    let me = this;
    let data = [];
    this._chartData.forEach((e, idx) => {
      data.push([e.name, e.value, idx]);
    });

    // 生成options
    me._chartOption = this.initChartOption();
    me._chartOption.series[0].data = data;

    // ---------------------------------------------
    // 图表大小的处理
    let chartMaxWidth = 1500;
    let chartMaxHeight = 1500;
    // 计算图表的宽度
    let chartWidth = document.getElementById("chart_div").clientWidth;
    chartWidth = chartWidth > chartMaxWidth ? chartMaxWidth : chartWidth;
    me._chartOption.chart.width = chartWidth;
    // 计算图表的高度
    let chartHeight = document.getElementById("chart_div").clientHeight;
    chartHeight = chartHeight > chartMaxHeight ? chartMaxHeight : chartHeight;
    me._chartOption.chart.height = chartHeight;

    if (isDebug) console.log("me._chartOption:", me._chartOption);
    return;
  };

  /**
   * 【继承组件要重写】
   * echart 有时需要绑定点击事件什么
   * @param {object} e ReactECharts 返回的 echarts 实例
   */
  eChartRefCallback = (e) => {};

  // end of overwrite
  //===============================================================

  getColorOfLevel = (level, maxLevel = 1000000) => {
    if (level > 0 && level < this._chartColors.length) {
      if (level > maxLevel) {
        return "#dddddd";
      }
      return this._chartColors[level];
    } else {
      return "#dddddd";
    }
  };
}

export default connect(
  (state) => ({
    userInfo: state.account.userInfo,
  }),
  (dispatch) => ({})
)(ViewTaskProcessInsight);
