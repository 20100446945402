import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import copy from 'copy-to-clipboard';
import {Tooltip, message, Checkbox, Divider} from 'antd';
import nzh from 'nzh';
// noinspection SpellCheckingInspection
import anime from 'animejs';
import EventListener from 'react-event-listener';

import PB, {SimplePB} from '@/libs/simplePB';

import {
  ICON_CATEGORY_CUSTOM,
  ICON_CATEGORY_TEXT,
  ICON_CATEGORY_COMPANY,
  ICON_CATEGORY_TALENT,
  ICON_CATEGORY_PATENT,
  ICON_CATEGORY_PAPER,
  ICON_CATEGORY_POLICY,
  ICON_CATEGORY_ORG,
  ICON_CATEGORY_INSTITUTE,
  ICON_CATEGORY_NEWS_ACTIVITIES,
  ICON_CATEGORY_TAG,
  ICON_CATEGORY_DOCS,
  ICON_CATEGORY_DATASET,
  ICON_CATEGORY_GOV,
  ICON_CATEGORY_NATURE,
  ICON_CATEGORY_INDEX,
  ICON_CATEGORY_GRAPH,
  ICON_CATEGORY_COLLEGE_AND_UNIVERSITY,
  ICON_CATEGORY_PARK,
  ICON_CATEGORY_TECHNOLOGY,
  ICON_CATEGORY_PROJECT,
  ICON_CATEGORY_PREPARE,
  ICON_CATEGORY_DOING,
  ICON_CATEGORY_FINISH,
  ICON_CATEGORY_FLAG_A,
  ICON_CATEGORY_FLAG_B,
  ICON_CATEGORY_FLAG_C,
  ICON_CATEGORY_FLAG_E,
  ICON_CATEGORY_FLAG_F,
  ICON_CATEGORY_FLAG_G,
  ICON_CATEGORY_TEXT_A,
  ICON_CATEGORY_TEXT_B,
  ICON_CATEGORY_TEXT_C,
  ICON_CATEGORY_TIP_A,
  ICON_CATEGORY_TIP_B,
  ICON_CATEGORY_TIP_C,
  ICON_CATEGORY_TIP_D,
  ICON_CATEGORY_TIP_E,
  ICON_CATEGORY_TIP_F,
  ICON_CATEGORY_TIP_G,
  ICON_CATEGORY_TIP_H,
  ICON_CATEGORY_NO_ICON,
  ICON_CATEGORY_GOOD,
  ICON_CATEGORY_BAD,
  ICON_CATEGORY_WATCH,
  iconConfig, getNodeIconType,
} from "@/constants/iconConfig";
import {IconTypes} from '@/constants/common';

import Icon from '@/components/common/common.icon';
import {defaultDefine, getNodeDisplayTitle, getNodeIcon} from '@/constants/vis.defaultDefine.1';

import ViewStatisticsWordPanel from '@/components/common/view/statistics/word/common.view.statistics.word.panel';
import ViewStatisticsNodeDatetimePanel from '@/components/common/view/statistics/node/common.view.statistics.node.datetimePanel';
import ViewStatisticsNodeGeneralPanel from '@/components/common/view/statistics/node/common.view.statistics.node.generalPanel';

import style from '@/style/common/relation/common.relation.nodeFilter.less';
import ViewStatisticsEdgePanel from "@/components/common/view/statistics/edge/common.view.statistics.edge.panel";

const iconTypes = [
  ICON_CATEGORY_GOV,
  ICON_CATEGORY_POLICY,
  ICON_CATEGORY_ORG,
  ICON_CATEGORY_PARK,
  ICON_CATEGORY_COMPANY,
  ICON_CATEGORY_COLLEGE_AND_UNIVERSITY,
  ICON_CATEGORY_INSTITUTE,
  ICON_CATEGORY_TALENT,
  ICON_CATEGORY_PATENT,
  ICON_CATEGORY_PAPER,
  ICON_CATEGORY_DOCS,
  ICON_CATEGORY_DATASET,
  ICON_CATEGORY_INDEX,
  ICON_CATEGORY_GRAPH,
  ICON_CATEGORY_TECHNOLOGY,
  ICON_CATEGORY_NEWS_ACTIVITIES,
  ICON_CATEGORY_NATURE,
  ICON_CATEGORY_TAG,
  ICON_CATEGORY_PROJECT,
  ICON_CATEGORY_PREPARE,
  ICON_CATEGORY_DOING,
  ICON_CATEGORY_FINISH,
  ICON_CATEGORY_FLAG_A,
  ICON_CATEGORY_FLAG_B,
  ICON_CATEGORY_FLAG_C,
  ICON_CATEGORY_FLAG_E,
  ICON_CATEGORY_FLAG_F,
  ICON_CATEGORY_FLAG_G,
  ICON_CATEGORY_TEXT_A,
  ICON_CATEGORY_TEXT_B,
  ICON_CATEGORY_TEXT_C,
  ICON_CATEGORY_TEXT,
  ICON_CATEGORY_TIP_A,
  ICON_CATEGORY_TIP_B,
  ICON_CATEGORY_TIP_C,
  ICON_CATEGORY_TIP_D,
  ICON_CATEGORY_TIP_E,
  ICON_CATEGORY_TIP_F,
  ICON_CATEGORY_TIP_G,
  ICON_CATEGORY_TIP_H,
  ICON_CATEGORY_GOOD,
  ICON_CATEGORY_BAD,
  ICON_CATEGORY_WATCH,
  ICON_CATEGORY_NO_ICON,
  ICON_CATEGORY_CUSTOM,
];



class StoryNodeFilter extends React.PureComponent {
  state = {
    forceUpdate: undefined,
    nodeListLimit: 100,
  };

  storyNodeMaps = [];


  lastHoverKey = {
    list: undefined,
    statistics: undefined,
  };

  doStatistics = (allNodeMap, idMap, storyNodeIds) => {
    let me = this, storyNodeMaps = [];
    (storyNodeIds.length>0?storyNodeIds:Object.keys(idMap['story'])).forEach((n,index) => {
      if(Object.keys(idMap['story']).includes(n)){
        storyNodeMaps.push(allNodeMap[n]);
      }else{
        storyNodeMaps.push({id:'id_'+index,fname:n,type:'notNode'});
      }
    })
    me.storyNodeMaps = storyNodeMaps;
  };

  onComponentHover = (type, key) => {
    let me = this;

    if (!me.lastHoverKey[type]) {
      me.lastHoverKey[type] = key;
      me.forceUpdate();
    } else {
      me.lastHoverKey[type] = key;
    }
  };

  delayComponentLostHover = (type, key) => {
    let me = this;

    setTimeout(() => {
      if (me.lastHoverKey[type] === key) {
        me.lastHoverKey[type] = undefined;
        me.forceUpdate();
      }
    }, 10);
  };

  onMouseMove = () => {
    // fake empty function, initialized after mount 3 seconds
  };


  constructor(props) {
    super(props);

  }

  componentDidMount() {
  }

  componentDidUpdate() {
  }

  componentWillUnmount() {
    let me = this;
    me.props.bus.remove(me);
  }

  doFocusNode = node => {
    this.props.bus.emit('relation', 'node.presentation.focus', {node: node});
  };

  render() {
    let me = this;
    me.doStatistics(me.props.allNodeMap,me.props.idMap,me.props.storyNodeIds);

    return me.props.loading ? null : (
                <ul>
                  {
                    me.state.nodeListLimit > 0 && me.storyNodeMaps.length>0 &&
                      me.storyNodeMaps.map((node, index) => (
                        node.type!=='notNode'?(
                        <Tooltip
                          key={`n-${node.id}`}
                          placement={'right'}
                          onVisibleChange={visible =>
                            visible ? me.onComponentHover('list', `n-${node.id}`) :
                              me.delayComponentLostHover('list', `n-${node.id}`)}
                        >
                          <li
                            className={
                              me.props.presentationPlayingNodeId === node.id ? style['active'] : ''
                            }
                            onClick={() => me.doFocusNode(node)}
                          >
                            {node.fname}
                          </li>
                        </Tooltip>
                      ) : (
                      <li
                          key={`n-${index}`}
                          className={
                            me.props.presentationPlayingNodeId === node.fname ? style['active'] : ''
                          }
                          onClick={() => me.doFocusNode({id:'id_'+index,description:node.fname,type:'notNode'})}
                        >
                          {node.fname}
                        </li>
                      ))
                    )
                  }
                </ul>
    );
  }
}

StoryNodeFilter.defaultProps = {
  bus: PB,
};

StoryNodeFilter.propTypes = {
  bus: PropTypes.instanceOf(SimplePB),
  presentationPlayingNodeId: PropTypes.string,
  idMap: PropTypes.object,
  allNodeMap: PropTypes.object,
  loading:PropTypes.bool,
  storyNodeIds:PropTypes.array
};

export default StoryNodeFilter;


const stopAnimation = animations => {
  /*
   This used to just pause any remaining animation
   but anime gets stuck sometimes when an animation
   is trying to tween values approaching 0.

   Basically to avoid that we're just forcing near-finished
   animations to jump to the end.

   This is definitely a hack but it gets the job done—
   if the root cause can be determined it would be good
   to revisit.
   */
  const stop = anim => {
    const { duration, remaining } = anim;
    if (remaining === 1) anim.seek(duration);
    else anim.pause();
  };
  if (Array.isArray(animations)) animations.forEach(anim => stop(anim));
  else stop(animations);
};
