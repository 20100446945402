import {NetworkEvents, NodeEvents} from "./events";

// 节点扩展相关状态，与事件命名相同，部分代码直接复用
export const NodeExpandingStatus = Object.freeze({
  IDLE: 'node.expanding.idle', // 节点扩展初始状态
  PROCESSING: NodeEvents.EXPANDING,
  SUCCESS: NodeEvents.EXPANDING_SUCCESS,
  FAILED: NodeEvents.EXPANDING_FAILED,
});

// 节点联想相关状态，与事件命名相同，部分代码直接复用
export const NodeGrowingStatus = Object.freeze({
  IDLE: 'node.growing.idle', // 节点联想初始状态
  PROCESSING: NodeEvents.GROWING,
  SUCCESS: NodeEvents.GROWING_SUCCESS,
  FAILED: NodeEvents.GROWING_FAILED,
});

// 节点搜索相关状态，与事件命名相同，部分代码直接复用
export const NodeExactMatchingStatus = Object.freeze({
  IDLE: 'node.exact_matching.idle', // 节点联想初始状态
  PROCESSING: NodeEvents.EXACT_MATCHING,
  SUCCESS: NodeEvents.EXACT_MATCHING_SUCCESS,
  FAILED: NodeEvents.EXACT_MATCHING_FAILED,
});

// 节点探索相关状态，与事件命名相同，部分代码直接复用
export const NodeExploringStatus = Object.freeze({
  IDLE: 'node.exploring.idle', // 节点联想初始状态
  PROCESSING: NodeEvents.EXPLORING,
  SUCCESS: NodeEvents.EXPLORING_SUCCESS,
  FAILED: NodeEvents.EXPLORING_FAILED,
});

// 子图搜索相关状态，与事件命名相同，部分代码直接复用
export const NetworkSubViewMatchingStatus = Object.freeze({
  IDLE: 'network.sub_view_matching.idle', // 节点联想初始状态
  PROCESSING: NetworkEvents.SUB_VIEW_MATCHING,
  SUCCESS: NetworkEvents.SUB_VIEW_MATCHING_SUCCESS,
  FAILED: NetworkEvents.SUB_VIEW_MATCHING_FAILED,
});

// 子图探索相关状态，与事件命名相同，部分代码直接复用
export const NetworkSubViewExploringStatus = Object.freeze({
  IDLE: 'network.sub_view_exploring.idle', // 节点联想初始状态
  PROCESSING: NetworkEvents.SUB_VIEW_EXPLORING,
  SUCCESS: NetworkEvents.SUB_VIEW_EXPLORING_SUCCESS,
  FAILED: NetworkEvents.SUB_VIEW_EXPLORING_FAILED,
});

export const NetworkDataLoadingStatus = Object.freeze({
  IDLE: 'network.loading.idle', // 关系图数据初始状态
  PROCESSING: NetworkEvents.LOADING_DATA,
  SUCCESS: NetworkEvents.LOADING_DATA_SUCCESS,
  FAILED: NetworkEvents.LOADING_DATA_FAILED,
});

export const NetworkDataReloadingStatus = Object.freeze({
  IDLE: 'network.reloading.idle', // 关系图数据初始状态
  PROCESSING: NetworkEvents.RELOADING_DATA,
  SUCCESS: NetworkEvents.RELOADING_DATA_SUCCESS,
  FAILED: NetworkEvents.RELOADING_DATA_FAILED,
});

export const NetworkSubViewDataLoadingStatus = Object.freeze({
  IDLE: 'network.loading_sub_view.idle', // 关系图子图数据初始状态
  PROCESSING: NetworkEvents.LOADING_SUB_VIEW,
  SUCCESS: NetworkEvents.LOADING_SUB_VIEW_SUCCESS,
  FAILED: NetworkEvents.LOADING_SUB_VIEW_FAILED,
});

export const NetworkFileListLoadingStatus = Object.freeze({
  IDLE: 'network.loading_file_list.idle', // 关系图文件数据初始状态
  PROCESSING: NetworkEvents.LOADING_FILE_LIST,
  SUCCESS: NetworkEvents.LOADING_FILE_LIST_SUCCESS,
  FAILED: NetworkEvents.LOADING_FILE_LIST_FAILED,
});

export const NetworkDataSetLoadingStatus = Object.freeze({
  IDLE: 'network.loading_data_set.idle', // 关系图数据集数据初始状态
  PROCESSING: NetworkEvents.LOADING_DATA_SET,
  SUCCESS: NetworkEvents.LOADING_DATA_SET_SUCCESS,
  FAILED: NetworkEvents.LOADING_DATA_SET_FAILED,
});

export const NodeDetailInfoLoadingStatus = Object.freeze({
  IDLE: 'node.detail_info.idle',
  PROCESSING: NodeEvents.LOADING_DETAIL_INFO,
  SUCCESS: NodeEvents.DETAIL_INFO_LOADED,
  FAILED: NodeEvents.LOAD_DETAIL_INFO_FAILED,
});

export const NodeInfoUpdatingStatus = Object.freeze({
  IDLE: 'node.update.idle',
  PROCESSING: NodeEvents.UPDATING,
  SUCCESS: NodeEvents.UPDATED,
  FAILED: NodeEvents.UPDATE_FAILED,
});
