import React from 'react';
import {connect} from 'react-redux';
import {doRegisterAction, initLoginViaUsernameAction, initLoginViaWechatAction} from "@/redux-saga/account/actions";

import {LoginStatusEnum, RegisterStatusEnum} from "@/constants/account";

import NormalRegisterForm from '@/components/registerView/register.normalForm';
import EmailSentNoticePanel from '@/components/common/common.emailSentNoticePanel';
import LoginRelatedView from '@/components/common/common.loginRelatedView';

import style from '@/style/components/common.loginRelatedView.less';

class RegisterView extends React.Component {
  state = {
    redirectToLoginAt: 0,
  };

  gotoLogin = (e) => {
    e && e.preventDefault();
    this.props.initLoginViaUsername();
    this.props.history.push('/login');
  };

  gotoLoginViaWechatQRCode = () => {
    this.props.initWechatQRCode(false);
    this.props.history.push('/login');
  };

  onInputFocus = (e) => {
    e.target.previousSibling.firstElementChild.style.color = '#404040';
  };

  onInputBlur = (e) => {
    e.target.previousSibling.firstElementChild.style.color = '';
  };

  componentWillMount() {
    const {loginStatus} = this.props;
    if (loginStatus === LoginStatusEnum.SUCCESS) {
      this.props.history.push("/relation");
    }
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    console.log("nextProps", nextProps);
    if (nextProps.loginStatus === LoginStatusEnum.SUCCESS) {
      nextProps.history.push("/relation");
    } else if (nextProps.registerStatus === RegisterStatusEnum.SUCCESS &&
      this.props.registerStatus !== nextProps.registerStatus) {
      // 注册成功，提示并跳转
      this.setState({redirectToLoginAt: (new Date()).getTime() + 5000});
    }
    return true
  }

  render() {
    let form, me = this;
    switch (this.props.registerStatus) {
      case RegisterStatusEnum.SUCCESS:
        form = (
          <EmailSentNoticePanel
            onBack={() => me.props.history.push('/login')}
            email={me.props.existedUsername}
            redirectAt={me.state.redirectToLoginAt}
          />
        );
        break;
      default:
        form = (
          <NormalRegisterForm
            onRegister={this.props.register}
            gotoLogin={this.gotoLogin}
            loginViaWechat={this.gotoLoginViaWechatQRCode}
            onInputFocus={this.onInputFocus}
            onInputBlur={this.onInputBlur}
            registerLoading={this.props.registerStatus === -2}
          />
        );
    }
    return (
      <div className={`${style["container"]} dark-theme`}>
        <LoginRelatedView.Header history={me.props.history} />
        <LoginRelatedView.Background />
        <div className={style["content"]}>
          {form}
        </div>
        <LoginRelatedView.Intro />
        <LoginRelatedView.Bottom />
      </div>
    )
  }
}

export default connect(
  state => ({
    loginStatus: state.account.loginStatus,
    existedUsername: state.account.existedUsername,
    registerStatus: state.account.registerStatus,
  }),
  dispatch => ({
    initLoginViaUsername: () => dispatch(initLoginViaUsernameAction()),
    initWechatQRCode: () => dispatch(initLoginViaWechatAction(false)),
    register: (username) => dispatch(doRegisterAction(username)),
  })
)(RegisterView)
