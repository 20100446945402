import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {Form} from 'antd';
import moment from 'moment';

import MicroServicePanelParameterRadioGroup
  from '@/components/common/view/microService/panel/parameter/common.view.microService.panel.parameter.radioGroup';
import MicroServicePanelParameterDuration
  from '@/components/common/view/microService/panel/parameter/common.view.microService.panel.parameter.duration';
import MicroServicePanelParameterLocation
  from '@/components/common/view/microService/panel/parameter/common.view.microService.panel.parameter.location';
import MicroServicePanelParameterCheckboxGroup
  from '@/components/common/view/microService/panel/parameter/common.view.microService.panel.parameter.checkboxGroup';
import MicroServicePanelParameterTextInput
  from '@/components/common/view/microService/panel/parameter/common.view.microService.panel.parameter.textInput';
import {MicroServiceUIConfigParameter} from '@/components/common/view/microService/shape/common.view.microService.shape.uiConfig';

import style from '@/style/common/microService/common.microService.less';

const defaultCustomDuration = [moment().startOf('month'), moment().endOf('day')];

const defaultFormLayout = {
  labelAlign: 'left',
  labelCol: {
    xs: {span: 0},
    sm: {span: 0},
  },
  wrapperCol: {
    xs: {span: 48},
    sm: {span: 24},
  },
};

class MicroServicePanelParameter extends React.PureComponent {
  state = {
    parameterShortTextMap: {},
  };

  getParameterContents = () => {
    let me = this,
      /** @type {TMicroServiceUIConfigParameter} */ config = me.props.config;

    return config.items.map((config, idx) => {
      switch (config.type) {
        case 'radioGroup':
          return (
            <Form.Item
              className={style['parameter-panel']}
              key={`p-${_.snakeCase(config.name)}`}
              label={`${idx + 1}.${config.label}`}
            >
              <MicroServicePanelParameterRadioGroup
                onChange={e => me.props.onParameterSet(config.name, e.target.value)}
                value={(me.props.parameters[config.name] || {}).value}
                defaultValue={config['default'] || config.options[0].value}
                options={config.options}
                optionSpan={config.optionSpan}
                onShortTextChange={value => me.setParameterShortText(config.name, value)}
                locked={me.props.locked}
              />
            </Form.Item>
          );
        case 'duration':
          return (
            <Form.Item
              className={style['parameter-panel']}
              key={`p-${_.snakeCase(config.name)}`}
              label={`${idx + 1}.${config.label}`}
            >
              <MicroServicePanelParameterDuration
                onChange={e => me.props.onParameterSet(config.name, e.target.value)}
                value={(me.props.parameters[config.name] || {}).value}
                defaultValue={config['default'] || config.enabledOptions[0]}
                customDuration={(me.props.parameters[config.name] || {}).customDuration}
                defaultCustomDuration={defaultCustomDuration}
                onCustomDurationSelected={moments => me.props.onParameterSet(config.name, moments, 'customDuration')}
                onDurationChange={({start, end}) => {
                  me.props.onParameterSet(config.name, start, 'start');
                  me.props.onParameterSet(config.name, end, 'end');
                }}
                onShortTextChange={value => me.setParameterShortText(config.name, value)}
                enabledOptions={config.enabledOptions}
                optionSpan={config.optionSpan}
                locked={me.props.locked}
              />
            </Form.Item>
          );
        case 'location':
          return (
            <Form.Item
              className={style['parameter-panel']}
              key={`p-${_.snakeCase(config.name)}`}
              label={`${idx + 1}.${config.label}`}
            >
              <MicroServicePanelParameterLocation
                onChange={value => me.props.onParameterSet(config.name, value)}
                onLocationChange={adc => me.props.onParameterSet(config.name, adc, 'adc')}
                onShortTextChange={value => me.setParameterShortText(config.name, value)}
                value={(me.props.parameters[config.name] || {}).value}
                defaultValue={config['default'] || ['110000']}
                locked={me.props.locked}
                optionSpan={config.optionSpan}
              />
            </Form.Item>
          );
        case 'checkboxGroup':
          return (
            <Form.Item
              className={style['parameter-panel']}
              key={`p-${_.snakeCase(config.name)}`}
              label={`${idx + 1}.${config.label}`}
            >
              <MicroServicePanelParameterCheckboxGroup
                onChange={value => me.props.onParameterSet(config.name, value)}
                value={(me.props.parameters[config.name] || {}).value}
                defaultValue={config['default'] || []}
                onShortTextChange={value => me.setParameterShortText(config.name, value)}
                options={config.options}
                optionSpan={config.optionSpan}
                locked={me.props.locked}
              />
            </Form.Item>
          );
        case 'textInput':
          return (
            <Form.Item
              className={style['parameter-panel']}
              key={`p-${_.snakeCase(config.name)}`}
              label={`${idx + 1}.${config.label}`}
            >
              <MicroServicePanelParameterTextInput
                onChange={value => me.props.onParameterSet(config.name, value)}
                value={(me.props.parameters[config.name] || {}).value}
                defaultValue={config['default'] || ''}
                onShortTextChange={value => me.setParameterShortText(config.name, value)}
                locked={me.props.locked}
              />
            </Form.Item>
          );
      }
    });
  };

  setParameterShortText = (name, value) => {
    let me = this, parameterShortTextMap = me.state.parameterShortTextMap;

    parameterShortTextMap[name] = value;

    me.setState({parameterShortTextMap});
  };

  render() {
    let me = this;
    return me.props.config.items.length > 0 ? (
      <div className={`${style['parameter-frame']} scrollbar`}>
        <Form {...(me.props.config.formLayout || defaultFormLayout)} className={style['parameter-form']}>
          {me.getParameterContents()}
        </Form>
      </div>
    ) : (
      <div className={`${style['parameter-frame']} scrollbar`}>
        <div className={style['parameter-empty']}>
          此微服务没有其他参数
        </div>
      </div>
    );
  }
}

MicroServicePanelParameter.defaultProps = {};

MicroServicePanelParameter.propTypes = {
  config: PropTypes.shape(MicroServiceUIConfigParameter).isRequired,
  locked: PropTypes.bool.isRequired,
  onParameterSet: PropTypes.func.isRequired,
  parameters: PropTypes.object.isRequired,
};

export default MicroServicePanelParameter;