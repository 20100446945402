import React from 'react';
import PropTypes from 'prop-types';
import { message } from 'antd';

import style from '@/style/containers/dashboard.less';
import viewStyle from '@/style/components/dashboard/dashboard.view.less';
import ToolbarWithButtons from '@/components/dashboard/dashboard.toolbarwithbuttons';
import { Col } from 'antd';
import QueueAnim from 'rc-queue-anim';
import ViewInfoCard from '@/components/dashboard/dashboard.viewInfoCard';
import { bindUtil } from '@/libs/core-decorators';
import ViewManager from '@/components/common/common.viewManager';
import { autoSetState, withReactStateHelper, helperKey as autoSetStateHelperKey } from '@/libs/react-state-helper';
import Icon from '@/components/common/common.icon';
import { SearchViewListLoadingStatus, SearchViewListByNodeLoadingStatus } from "@/libs/view/ViewManager";
import CreateViewModal from '@/components/common/view/common.view.info.createModal';
import PB, {SimplePB} from "@/libs/simplePB";
import intl from 'react-intl-universal';
// 搜索结果后台不支持分页 一性加载一定数量的数据
const loadItemsPerStep = 60 * 2;

@withReactStateHelper
@bindUtil.asTargetClass
class ResultView extends React.Component {
    // 页面数据模块
    module = 'search';

    // 通过看板内节点搜索看板
    @autoSetState
    @bindUtil.bindToProperty(ViewManager.dataProvider, 'searchViewListByNode')
    ViewsForSearchByNode = [];

    @autoSetState
    @bindUtil.bindToProperty(ViewManager.dataProvider, 'searchViewListByNodeLoadingStatus')
    searchViewListByNodeLoadingStatus = {status: SearchViewListByNodeLoadingStatus.IDLE};

    // 通过节点找看板的搜索结果图谱数据
    @autoSetState
    @bindUtil.bindToProperty(ViewManager.dataProvider, 'searchViewList')
    ViewsForSearch = [];

    @autoSetState
    @bindUtil.bindToProperty(ViewManager.dataProvider, 'searchViewListLoadingStatus')
    searchViewListLoadingStatus = {status: SearchViewListLoadingStatus.IDLE};

    // 展示的数据 我的图谱
    filteredViewList = undefined;
    refreshQueueAnim = (new Date()).getTime();

    pageStart = 0;

    state = {
        // 标记异步获取数据的结果状态
        isAjaxResultStatus: false,
        // 搜索关键字
        searchWord: '',

        // 图谱卡片的打开模式
        isReadOnly: true,

        viewTagsList: [], // 图谱标签列表

        showCreateViewModal: false, // 新建图谱弹框是否显示
    };

    componentDidMount() {
        let me = this;
        let historySearchWord = this.props.history.location && this.props.history.location.state && this.props.history.location.state.searchWord;
        this.setState({
            searchWord: historySearchWord,
        }, () => {
            // 加载数据
            this.onSearch(this.state.searchWord);
        });

        PB.sub(this, 'search', 'search.view.searchWord', ({searchWord}) => {
            this.setState({
                searchWord: searchWord,
            }, () => {
                this.onSearch(this.state.searchWord);
            })
        });

        // 7月15日，辛总提出，刷新后不允许包括上次的搜索   刷新 立刻 改为 原始状态
        window.onbeforeunload = function(event){
            me.props.history.push({pathname: '/mainview/dashboard/search', state: {searchWord: ''}});
        };

		// 调起新建看板
		PB.sub(this, "dashboard", "dashboard.createViewModal.show", ({ status }) => {
			me.setState({
				showCreateViewModal: !!status,
			})
		  });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        // this.refresh();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.searchWord !== this.props.searchWord) {
            this.setState({
                searchWord: this.props.searchWord,
            }, () => {
                this.onSearch(this.state.searchWord);
                // 清空数据
                this.filteredViewList = undefined;
            });
        }
    }

    componentWillUnmount() {
        PB.remove(this);
    }

    /**
     * 加载页面数据（数据流）
     */
    refresh = async () => {
        console.log('views->refresh->this.props:', this.props);
        // 设置模块
        this.module = this.props.module || 'search';
        // console.log(' resultView -> refresh -> this.state.searchWord :', this.state.searchWord);
        this.onSearch(this.state.searchWord);
    };

    /**
     * ??
     * @param action
     * @param viewId
     * @param viewInfo
     */
    onViewItemUpdated = ({action}) => {
        switch (action) {
            case 'edit':
                this.refresh();
                break;
            case 'sale':
                break;
            case 'cancel_sale':
                this.refresh();
                break;
            default:
                this.refresh();
        }
    };

    /**
     * 搜索图谱
     * 搜索图谱：总是搜索全部图谱，不区分我的、协作、收费、免费。所以搜索的方法和结果页面都是统一的。
     * @param searchWord
     */
    onSearch = searchWord => {
        let me = this;
        const {searchParams: {channel = 0, tag = undefined}} = this.props;
        console.log(' dashboard.view -> channel, searchWord :', channel, searchWord);
        me.props.search(searchWord);
        // 清空数据
        me.filteredViewList = [];
        // 加载数据
        me.state.isReadOnly = true;

        // 搜索图谱时，先搜索图谱名称等相关信息，如果找不到，再通过搜索节点找图谱
        ViewManager.loadSearchViewList({q: searchWord, limit: loadItemsPerStep, channel, tag}).then(res => {
            // 设置模块
            me.module = me.props.module || 'search';
            me.ViewsForSearch = this.ViewsForSearch.concat(res);

            if (me.ViewsForSearch.length > 0) {
                me.filteredViewList = me.sortViewListForSearch(me.ViewsForSearch);
                me.setState({
                    searchWord,
                    isAjaxResultStatus: true,
                });
            } else if(!tag) {// 如果指定了匹配tag，则不再继续通过节点搜索看板
                ViewManager.loadSearchViewListByNode({q: searchWord, limit: loadItemsPerStep, channel}).then(res => {
                    // 设置模块
                    me.ViewsForSearchByNode = me.ViewsForSearchByNode.concat(res);
                    me.filteredViewList = me.sortViewListForSearch(me.ViewsForSearchByNode);
                    me.setState({
                        searchWord,
                        isAjaxResultStatus: true,
                    });
                }).catch(err => {
                    console.log(' 加载更多数据失败 ->  :', err);
                    // 102 数据检索中
                    if (err.code === 102) return;
                    me.filteredViewList = [];
                    if (err.code === 10001) return;
                    message.error(err.msg);
                });
            }else{
                me.filteredViewList = [];
                // message.warn('没有查找到图谱');
                me.setState({
                    searchWord,
                    isAjaxResultStatus: true,
                });
            }

        }).catch(err => {
            console.log(' 加载更多数据失败 ->  :', err);
            // 102 数据检索中
            if (err.code === 102) return;
            // this.filteredViewList = [];
            // message.error(err.msg);

            if(tag) return false; // 如果指定了匹配tag，则不再继续通过节点搜索看板

            ViewManager.loadSearchViewListByNode({q: searchWord, limit: loadItemsPerStep, channel}).then(res => {
                // 设置模块
                me.module = me.props.module || 'search';
                me.ViewsForSearchByNode = me.ViewsForSearchByNode.concat(res);
                me.filteredViewList = me.sortViewListForSearch(me.ViewsForSearchByNode);
                me.setState({
                    searchWord,
                    isAjaxResultStatus: true,
                });
            }).catch(err => {
                console.log(' 加载更多数据失败 ->  :', err);
                // 102 数据检索中
                if (err.code === 102) return;
                me.filteredViewList = [];
                if (err.code === 10001) return;
                message.error(err.msg);
            });
        });
    };

    // 对搜索结果排序 ：名称还有搜索关键词的看板排前面
    sortViewList = (viewList = []) => {
        let me = this;
        let result = [], nameHasSearchWord = [], nameHasNotSearchWord = [];
        viewList.map(view => {
            if (view.name.indexOf(me.state.searchWord) > -1) {
                nameHasSearchWord.push(view);
            } else {
                nameHasNotSearchWord.push(view);
            }
        });
        result = nameHasSearchWord.concat(nameHasNotSearchWord);
        return result;
    };

    sortViewListForSearch = (viewList = []) => {
        let me = this;
        let result = [], nameHasSearchWord = [], nameHasNotSearchWord = [],nameFist = [],nameSecond = [];
        viewList.map(view => {
            if(view.channel===3 && view.name.indexOf(me.state.searchWord) > -1){
                nameFist.push(view);
            }else if (view.channel===3) {
                nameSecond.push(view);
            }else if (view.name.indexOf(me.state.searchWord) > -1) {
                nameHasSearchWord.push(view);
            } else {
                nameHasNotSearchWord.push(view);
            }
        });
        result = nameFist.concat(nameSecond).concat(nameHasSearchWord).concat(nameHasNotSearchWord);
        return result;
    };

    render() {
        let me = this;
        const { contentWrapSpacing, searchParams: {channel = 0, tag = undefined} } = me.props;
        console.log("🚀 | file: dashboard.resultView.js | line 246 | ResultView | render | tag", tag)

        return (
          <div className={style['container']}>
              <div className={style['content'] + ' scrollbar scrollbar-none'}>
                  <div className={`${style['published-view']} ${viewStyle['container']}`}>
                      <ToolbarWithButtons
                        // className={style["business-toolbar"]}
                        buttons={
                            this.props.navOptionList.map(({name, path}) => ({
                                name,
                                active: path === me.props.history.location.pathname,
                                onClick: () => {
                                    document.title = `${name} - ${intl.get('Custom.general.title')}`;
                                    if (path === this.props.currentPath) {
                                        this.refresh();
                                    } else {
                                        this.props.history.push(path);
                                    }
                                },
                            }))
                        }
                        // divClassName={mainViewStyle["search-toolbar"]}
                        searchBar={{
                            style: {marginRight: '20px', width: '24rem'},
                            placeholder: '搜索图谱...',
                            searchWord: this.state.searchWord,
                            onSearch: this.onSearch,
                            visible: false,
                        }}
                        history={this.props.history}
                        module={this.module}
                        refresh={this.refresh}
                        contentWrapSpacing={contentWrapSpacing}
                        viewTagsList={me.state.viewTagsList}
                        showCreateViewModal={ () => {
                            me.setState({
                                showCreateViewModal: true,
                            })
                        }}
                      />
                      <QueueAnim duration={[250, 0]}
                                 interval={[30, 0]}
                                 className={viewStyle['cards-container']}
                                 style={tag?{margin: `0 ${contentWrapSpacing}px`,paddingBottom:0}:{margin: `0 ${contentWrapSpacing}px`}}
                                 key={me.refreshQueueAnim}
                      >
                          {this.filteredViewList && this.filteredViewList.map(view => (
                            <ViewInfoCard
                              readOnly={this.state.isReadOnly && parseInt(view['teamworkType']) !== 1}
                              showCornerTip={true}
                              callback={this.onViewItemUpdated}
                              view={view}
                              key={`view-${view.viewId}`}
                              module={this.module}
                              searchWord={this.state.searchWord}
                            />
                          ))}
                      </QueueAnim>

                      {/* 请求结果状态判断 */}
                      {
                          this.filteredViewList && this.filteredViewList.length===0 ? tag ? this.state.isAjaxResultStatus ? (
                            <Col key={'view-none'} md={24} lg={24} xl={24}>
                                <div className={viewStyle['view-none']} style={{alignItems: 'center', justifyContent: 'center'}}>
                                    <div>没有找到与 "{tag}" 相关的图谱</div>
                                </div>
                            </Col>
                          ) : null : (
                              this.searchViewListLoadingStatus.status === SearchViewListLoadingStatus.IDLE
                              || this.searchViewListLoadingStatus.status === SearchViewListLoadingStatus.PROCESSING
                              || this.searchViewListByNodeLoadingStatus.status === SearchViewListByNodeLoadingStatus.IDLE
                              || this.searchViewListByNodeLoadingStatus.status === SearchViewListByNodeLoadingStatus.PROCESSING
                            ) ?  null : (
                              this.state.searchWord ? (
                                <Col key={'view-none'} md={24} lg={24} xl={24}>
                                    <div className={viewStyle['view-none']} style={{height: '40rem', alignItems: 'center', justifyContent: 'center'}}>
                                        <div>没有找到与 "{this.state.searchWord}" 相关的图谱</div>
                                    </div>
                                </Col>
                              ) : (
                                !this.state.isAjaxResultStatus ? (
                                  <Col key={'none-view'} md={24} lg={24} xl={24}>
                                      <div className={viewStyle['view-none']}>
                                          <div>您还没有图谱，赶紧新建……</div>
                                      </div>
                                  </Col>
                                ) : null
                              )
                            )
                            : null
                      }
                  </div>
              </div>

              <div
                className={style['add-view-fixed-btn']}
				style={me.props.channel > 0?{display:"none"}:{}}
                onClick={() => {
                    me.setState({
                        showCreateViewModal: true,
                    })
                }}
              >
                  {/*<Icon name='plus'/>*/}
                  <div className={style['text']}>新建<br/>图谱</div>
              </div>

              <CreateViewModal
                visible={me.state.showCreateViewModal}
                onClose={() => me.setState({showCreateViewModal: false})}
                callback={res => {
                    const {action, success, viewInfo} = res;
                    if (action === 'create' && success) {
                        if (this.module === 'my') {
                            me.refresh();
                        } else {
                            if (me.channel === 0) {
                                me.props.history.push('/mainview/dashboard/userview');
                            }
                        }
                        window.open(`/mainview/relation/${viewInfo.viewId}`, '_blank');
                    }
                }}
				params={{channel}}
              />
          </div>
        );
    }
}

ResultView.defaultProps = {
	searchParams: {
		channel: 0,
        tag: undefined
	}
};

ResultView.propTypes = {
    history: PropTypes.object, // router的参数
    navOptionList: PropTypes.array, // 顶部菜单列表
    className: PropTypes.string, // css class
    title: PropTypes.string, // 页面标题
    module: PropTypes.string, // 页面数据模块名 my=我的图谱，teamwork=协作图谱，published=上架图谱，public=公开图谱 all=可访问的图谱
    searchWord: PropTypes.string, // 搜索的关键字
    searchParams: PropTypes.object, // 搜索的参数。eg: word=搜索的关键字; channel=搜索的频道; tag=指定匹配的看板标签
    contentWrapSpacing: PropTypes.number, // 计算左右间距
	channel: PropTypes.number, // 频道参数
};

export default (props) => <ResultView {...props} key={props.history.location.pathname}/>;
