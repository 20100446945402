import React from 'react';
import PropTypes from "prop-types";
import {Input, Modal, Form} from "antd";
import EventListener from "react-event-listener";

import {getNodeDisplayTitle} from "@/constants/vis.defaultDefine.1";
import PB, {SimplePB} from "@/libs/simplePB";
import Icon from "@/components/common/common.icon";

import style from '@/style/components/mainView/node.less';
import ViewDataProvider from '@/components/common/dataProvider/common.dataProvider.view';

class NodeDeleteModal extends React.Component {
  state = {
    validateValue: undefined, // 需要验证时，用户输入的删除节点的数量
  };

  inputRef = undefined;
  validateNumber = 30;
  removeNodeIds = [];
  removeNode = undefined;

  deleteNodeTipsInfo = () => {
    const me = this, userId = parseInt(localStorage.getItem('userId'));
    let confirmText;
    if (me.props.withNode.node) {
      me.removeNode = me.props.withNode.node;
      me.removeNodeIds = [];
      confirmText = <p>是否确认删除节点: <b className={style['highlight']}> {me.removeNode ? (getNodeDisplayTitle(me.removeNode)) : ''}</b> ? </p>;
    } else if (me.props.withNode.nodeIds && me.props.withNode.nodeIds.length > 0) {
      let nodeIds = me.props.withNode.nodeIds;
      let allNodes = me.props.networkRef.getNode(nodeIds), nodesCanRemove = [],
        viewOwnerId = parseInt(me.props.viewInfo.userId);
      me.removeNode = undefined;
      if (allNodes.length <= 0) return;
      allNodes.forEach(node => {
        if (userId === node.userId || userId === viewOwnerId) nodesCanRemove.push(node);
      });
      if (allNodes.length === nodesCanRemove.length) {
        if (nodesCanRemove.length === 1) {
          confirmText = (
            <p>是否确认删除节点: <b className={style['highlight']}> {nodesCanRemove[0] ? (getNodeDisplayTitle(nodesCanRemove[0])) : ''}</b> ? </p>
          )
        } else {
          confirmText = <p>是否确认删除所选的 <b className={style['highlight']}>{allNodes.length}</b> 个节点？</p>;
        }
      } else if (nodesCanRemove.length > 0) {
        // 部分节点可以删除
        confirmText = <p>共选中节点 {allNodes.length} 个，您有权删除其中的 <b className={style['highlight']}>{nodesCanRemove.length}</b> 个，是否确认？</p>;
      } else {
        // 没有节点可以删除
        // message.warn('您没有删除所选节点的权限。');
        confirmText = <p>您没有删除所选节点的权限。</p>
      }
      nodeIds = nodesCanRemove.map(node => node.id);
      me.removeNodeIds = nodeIds;
    }
    return confirmText;
  };

  onDeleteNodes = () => {
    const me = this;
    if (me.removeNodeIds.length > me.validateNumber && parseInt(me.state.validateValue) !== me.removeNodeIds.length) {

    } else {
      me.props.bus.emit('network', 'node.do_remove', {node: me.removeNode, nodeIds: me.removeNodeIds});
      me.props.doClose();
    }
  };

  onKeyEvent = e => {
    let me = this;

    if (!me.props.visible) {
      // 对话框隐藏时不响应按键操作
      return;
    }

    switch (e.keyCode) {
      case 13: // 回车
        me.onDeleteNodes();
        break;
      case 32: // 空格
        if (me.props.withNode.node || (me.props.withNode.nodeIds && me.props.withNode.nodeIds.length === 1)) {
          me.onDeleteNodes();
          break;
        }
        // noinspection FallThroughInSwitchStatementJS
      default:
        return;
    }
    e.preventDefault();
    e.stopPropagation();
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    let me = this;

    if (me.props.visible && !prevProps.visible) {
      me.removeNodeIds = [];
      me.removeNode = undefined;
      requestAnimationFrame(() =>
        me.setState({
          validateValue: undefined,
        }));
    }

    if (me.props.visible && me.inputRef) me.inputRef.focus();
  }

  componentWillUnmount() {
    this.props.bus.remove(this);
  }

  render() {
    let me = this;
    const {visible, doClose} = me.props;
    const confirmText = me.deleteNodeTipsInfo();
    const validateFail = me.removeNodeIds.length > me.validateNumber && parseInt(me.state.validateValue) !== me.removeNodeIds.length;
    // noinspection RequiredAttributes
    return (
      <Modal
        wrapClassName={style['node-delete-modal']}
        title={<div>
          <Icon name="delete" className={style['header-icon']}/>
          删除节点
        </div>}
        style={{ top: '32vh' }}
        visible={visible}
        onOk={() => me.onDeleteNodes()}
        okButtonProps={{
          disabled: validateFail,
          type: 'danger',
          hidden: !me.removeNode && me.removeNodeIds.length === 0,
        }}
        onCancel={doClose}
        okText="确认"
        cancelText={!me.removeNode && me.removeNodeIds.length === 0 ? "关闭" : "取消"}
      >
        <div className={style['node-delete-body']}>
          <EventListener
            target={window}
            onKeyPress={me.onKeyEvent}
          />
          {confirmText}
          {
            me.removeNodeIds.length > me.validateNumber ? (
              <Form.Item label={'验证信息'} style={{marginBottom: 0}}>
                <Input
                  autoFocus
                  ref={e => me.inputRef = e}
                  type={'number'}
                  value={me.state.validateValue}
                  onChange={e => me.setState({validateValue: e.target.value})}
                  onPressEnter={() => { }}
                />
                <div className={me.state.validateValue && validateFail ? style['error'] : style['tips']} >{me.state.validateValue && validateFail ? '输入数量与删除节点数量不符' : 'Tips:验证信息为删除节点的数量'}</div>
              </Form.Item>
            ) : null
          }
        </div>
      </Modal>
    );
  }
}

NodeDeleteModal.defaultProps = {
  bus: PB,
  visible: false,
  processing: true,
  withNode: {
    node: undefined,
    nodeIds: undefined,
  },
};

NodeDeleteModal.propTypes = {
  bus: PropTypes.instanceOf(SimplePB),
  viewInfo: PropTypes.object,
  visible: PropTypes.bool,
  withNode: PropTypes.object.isRequired,
  doClose: PropTypes.func.isRequired,
  networkRef: PropTypes.instanceOf(ViewDataProvider).isRequired,
};

export default NodeDeleteModal;