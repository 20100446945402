import React from 'react';
import PropTypes from 'prop-types';
import {Row, Col, Form, Input, Cascader, Button, Upload, message, Slider, Tooltip, Modal} from 'antd';
import AvatarEditor from 'react-avatar-editor';
import UserAvatar from 'react-user-avatar';

import {REQUEST_BASE, getToken} from "@/utils/HttpUtil";
import {getBase64Url} from "@/utils/Common";
import frameStyle from '@/style/containers/mainView.accountCenter.less';
import style from '@/style/components/accountCenter/account.basicInfoModal.less';
import {ChangeUserBasicInfoStatus} from "@/constants/account";
import {checkUploadImage} from "@/components/common/common.functions";
import copy from 'copy-to-clipboard';

// noinspection NpmUsedModulesInstalled
const chinaDivision = require('@/constants/area.nocode.json');

const avatarSize = 180;

class AccountBasicInfoPanel extends React.Component {
    state = {
        dataChanged: false,
        uploading: false,
        uploadedAvatarImgBase64Url: null,
        showAvatarEditor: false,
        avatarWidth: avatarSize,
        avatarHeight: avatarSize,
        avatarScale: 1,
        avatarRotate: 0,
        avatarPicId: '',
        nick: '',
        name: '',
        org: '',
        province: '',
        city: '',
    };

    avatarEditorRef = undefined;

    updateUserBasicInfo = (e) => {
        let me = this;
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const canvas = (me.avatarEditorRef && me.state.uploadedAvatarImgBase64Url) ?
                  this.avatarEditorRef.getImageScaledToCanvas() : undefined;
                /**
                 * @type {undefined|HTMLCanvasElement}
                 */
                let dstCanvas = undefined;
                if (canvas) {
                    // noinspection JSValidateTypes
                    dstCanvas = document.createElement('canvas');
                    dstCanvas.width = canvas.width;
                    dstCanvas.height = canvas.height;
                    const ctx = dstCanvas.getContext('2d');
                    ctx.fillStyle = 'white';
                    ctx.fillRect(0, 0, dstCanvas.width, dstCanvas.height);
                    ctx.drawImage(canvas, 0, 0);
                }
                this.props.onSaveUserBasicInfo({
                    nick: values.nick,
                    name: values.name,
                    picId: dstCanvas ? dstCanvas.toDataURL('image/jpeg') : (me.state.avatarPicId || ''),
                    org: values.org,
                    province: values.area[0],
                    city: values.area[1],
                });

                // 更新个人信息后关闭弹窗
                this.props.onCancel();
            }
        })
    };

    onNickNameChanged = e => {
        if (e.target.value) {
            this.setState({dataChanged: true, nick: e.target.value});
        } else {
            this.setState({dataChanged: true});
        }
    };

    onFieldChanged = () => {
        this.setState({dataChanged: true});
    };

    resetFieldValues = () => {
        const {nick, name, avatarPicId, org, province, city} = this.props;
        let me = this;
        this.setState({
            nick, name, org, province, city, avatarPicId,
            uploadedAvatarImgBase64Url: null,
            dataChanged: false,
            showAvatarEditor: false,
        }, () => me.props.form.resetFields());
    };

    copyMemberId = (memberId) => {
        let result = copy(memberId, {
            message: '请按下 #{key} 复制选中节点文本。',
        });

        if (result) message.success('协作ID已经复制到剪贴板');
    };

    constructor(props) {
        super(props);
        const {nick, name, avatarPicId, org, province, city} = props;
        if (!this.state.dataChanged) {
            this.state = {
                ...this.state,
                nick,
                name,
                org,
                province,
                city,
                avatarPicId,
                uploadedAvatarImgBase64Url: null,
                showAvatarEditor: false,
            };
        }
    }

    componentWillReceiveProps(nextProps, nextState) {
        if (nextProps) {
            const {nick, name, avatarPicId, org, province, city, changeUserBasicInfoStatus} = nextProps;
            if (this.props.changeUserBasicInfoStatus === ChangeUserBasicInfoStatus.PROCESSING &&
                changeUserBasicInfoStatus === ChangeUserBasicInfoStatus.SUCCESS) {
                // 保存用户信息成功，刷新数据
                requestAnimationFrame(this.resetFieldValues);
            } else if ((!nextState && this.state.dataChanged === false) || (nextState && nextState.dataChanged === false)) {
                // 用户未修改，数据有刷新
                this.setState({
                    nick, name, org, province, city, avatarPicId,
                    uploadedAvatarImgBase64Url: null,
                    showAvatarEditor: false,
                });
            }
        }
    }

    render() {
        let me = this;
        const {visible, onCancel} = this.props;
        const {getFieldDecorator} = this.props.form;
        // noinspection RequiredAttributes
        return (
            <Modal
                visible={visible}
                title={'基本信息'}
                maskClosable={true}
                centered={true}
                width={760}
                onCancel={onCancel}
                destroyOnClose={true}
                footer={null}
            >
                <div className={`${frameStyle['content-inner']} ${style['frame']}`}>
                    <Form layout={'vertical'} onSubmit={me.updateUserBasicInfo}
                          className={`${style['content']}`}>
                        <Form.Item>
                            <div style={{marginRight: '-15px'}}>
                                <span style={{userSelect: 'none'}}>协作ID：</span>
                                <span>{this.props.accessToken}</span>
                                <span className={style['copy-tips']}
                                      onClick={() => me.copyMemberId(this.props.accessToken)}>
                                    （<u>复制协作ID</u>&nbsp;&nbsp;类似QQ号&nbsp;&nbsp;可发送给朋友&nbsp;&nbsp;用于协作）
                                </span>
                            </div>
                        </Form.Item>
                        <Row type={'flex'} justify={'space-between'} align={'top'}>
                            <Col span={11} >
                                <Form.Item label={'昵称'}>
                                    {getFieldDecorator('nick', {
                                        initialValue: me.state.nick,
                                        rules: [{
                                            required: true,
                                            message: '昵称不可为空！',
                                        }],
                                    })(
                                        <Input onChange={me.onNickNameChanged} autoFocus={true}/>
                                    )}
                                </Form.Item>
                                <Form.Item label={'姓名'}>
                                    {getFieldDecorator('name', {
                                        initialValue: me.state.name,
                                    })(
                                        <Input onChange={me.onFieldChanged}/>
                                    )}
                                </Form.Item>
                                <Form.Item label={'公司/机构'}>
                                    {getFieldDecorator('org', {
                                        initialValue: me.state.org,
                                    })(
                                        <Input onChange={me.onFieldChanged}/>
                                    )}
                                </Form.Item>
                                <Form.Item label='地区'>
                                    {getFieldDecorator('area', {
                                        initialValue: [me.state.province, me.state.city],
                                    })(
                                        <Cascader
                                            options={chinaDivision}
                                            expandTrigger="hover"
                                            onChange={me.onFieldChanged}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={12} className={style['avatar-column']}>
                                <Form.Item style={{width: 'fit-content'}}>
                                    {
                                        this.state.showAvatarEditor ? (
                                            <div style={{marginTop: '5px'}}>
                                                <AvatarEditor
                                                    ref={ref => me.avatarEditorRef = ref}
                                                    image={this.state.uploadedAvatarImgBase64Url}
                                                    width={avatarSize}
                                                    height={avatarSize}
                                                    border={15}
                                                    borderRadius={avatarSize / 2}
                                                    color={[0, 0, 0, 0.4]}
                                                    scale={this.state.avatarScale}
                                                    rotate={this.state.avatarRotate}/>
                                                <Slider
                                                    onChange={v => me.setState({avatarScale: v})}
                                                    min={0.5}
                                                    max={2}
                                                    step={0.01}
                                                    value={this.state.avatarScale}
                                                    style={{width: avatarSize + 30}}
                                                />
                                                <Tooltip placement={'top'} title={'缩小'}>
                                                    <Button title={'缩小'} icon="zoom-out"
                                                            disabled={me.state.avatarScale <= 0.5}
                                                            onClick={() =>
                                                                me.setState({avatarScale: Math.max(me.state.avatarScale - 0.1, 0.5)})}/>
                                                </Tooltip>
                                                <Tooltip placement={'top'} title={'放大'}>
                                                    <Button title={'放大'} icon="zoom-in"
                                                            disabled={me.state.avatarScale >= 2}
                                                            onClick={() =>
                                                                me.setState({avatarScale: Math.min(me.state.avatarScale + 0.1, 2)})}/>
                                                </Tooltip>
                                                <Tooltip placement={'top'} title={'还原'}>
                                                    <Button title={'还原'} icon="sync"
                                                            disabled={me.state.avatarScale === 1 && me.state.avatarRotate === 0}
                                                            onClick={() => me.setState({
                                                                avatarRotate: 0,
                                                                avatarScale: 1,
                                                            })}/>
                                                </Tooltip>
                                                <Tooltip placement={'top'} title={'左转90°'}>
                                                    <Button title={'左转90°'} icon="undo"
                                                            onClick={() =>
                                                                me.setState({avatarRotate: (me.state.avatarRotate - 90) % 360})}/>
                                                </Tooltip>
                                                <Tooltip placement={'top'} title={'右转90°'}>
                                                    <Button title={'右转90°'} icon="redo"
                                                            onClick={() =>
                                                                me.setState({avatarRotate: (me.state.avatarRotate + 90) % 360})}/>
                                                </Tooltip>
                                            </div>
                                        ) : (
                                            <UserAvatar size={avatarSize} name={me.state.nick}
                                                        src={me.state.avatarPicId ?
                                                            `${REQUEST_BASE}/user/user/file/${me.state.avatarPicId}?Authorization=${getToken()}` :
                                                            undefined}
                                                        className={me.state.avatarPicId ? null : style['card-avatar-default-icon']}
                                                        style={{
                                                            width: 'fit-content',
                                                            fontSize: avatarSize / 2.5,
                                                            marginTop: '55px',
                                                        }}/>
                                        )
                                    }
                                </Form.Item>
                                <div style={{marginTop: '5px'}}>
                                    <Upload name={'file'}
                                            multiple={false}
                                            showUploadList={false}
                                            disabled={this.state.uploading}
                                            beforeUpload={file => {
                                                if (checkUploadImage(file, message)) {
                                                    getBase64Url(file, imgBase64Url => {
                                                        me.setState({
                                                            dataChanged: true,
                                                            uploadedAvatarImgBase64Url: imgBase64Url,
                                                            uploading: false,
                                                            showAvatarEditor: true,
                                                        });
                                                    });
                                                } else {
                                                    me.setState({uploading: false});
                                                }
                                                return false;
                                            }}
                                            onChange={info => {
                                                if (info.file.status === 'uploading') {
                                                    this.setState({uploading: true});
                                                }
                                            }}>
                                        <Button icon={'upload'} style={{marginRight: '1rem'}}>选择头像</Button>
                                    </Upload>
                                    <Button icon={'smile'}
                                            disabled={!me.state.showAvatarEditor && !me.state.avatarPicId}
                                            onClick={() =>
                                                this.setState({
                                                    showAvatarEditor: false,
                                                    uploadedAvatarImgBase64Url: null,
                                                    avatarPicId: '',
                                                    dataChanged: true,
                                                })}>使用默认</Button>
                                </div>
                            </Col>
                        </Row>
                        <Row className={style['btn-row']}>
                            {/*
                            <Button icon={'rollback'}
                                    disabled={!me.state.dataChanged || me.props.changeUserBasicInfoStatus ===
                                    ChangeUserBasicInfoStatus.PROCESSING}
                                    onClick={me.resetFieldValues}>还原</Button>
                            */}
                            <Button onClick={onCancel}>取消</Button>
                            <Button
                                type={'primary'}
                                // icon={'save'}
                                disabled={!me.state.dataChanged || me.props.changeUserBasicInfoStatus === ChangeUserBasicInfoStatus.PROCESSING}
                                htmlType="submit"
                            >
                                保存修改
                            </Button>
                        </Row>
                    </Form>
                </div>
            </Modal>
        );
    }
}

const WrappedAccountBasicInfoPanel = Form.create()(AccountBasicInfoPanel);

WrappedAccountBasicInfoPanel.defaultProps = {
    onSaveUserBasicInfo: (userInfo) => {
        console.log('property onSaveUserBasicInfo is not set, userInfo: ', userInfo);
    },
};

WrappedAccountBasicInfoPanel.propTypes = {
    nick: PropTypes.string.isRequired,
    name: PropTypes.string,
    avatarPicId: PropTypes.string,
    org: PropTypes.string,
    province: PropTypes.string,
    city: PropTypes.string,
    accessToken: PropTypes.string,
    changeUserBasicInfoStatus: PropTypes.number,
    onSaveUserBasicInfo: PropTypes.func,
};

export default WrappedAccountBasicInfoPanel;
