import React from "react";
import PropTypes from "prop-types";

import PB, {SimplePB} from "@/libs/simplePB";

import NodeDataProvider from "@/components/common/dataProvider/common.dataProvider.node";
import ViewManager from "@/components/common/common.viewManager";

class NodeTodoLogic extends React.PureComponent {
  loadParameters = '{}';

  nodeTaskResult = {data: [], total: 0, userInfo: {}};

  componentDidMount() {
    let me = this;

    me.props.bus.sub(me, 'node_todo', 'list.do_load', ({parameters, forceReload = false, senderId}) => {
      if (!forceReload) {
        let loadParameters = JSON.stringify(parameters);
        if (me.loadParameters === loadParameters) {
          me.props.bus.emit('node_todo', 'list.loaded', {...me.nodeTaskResult, senderId});
          return;
        }
      }
      me.nodeTaskResult = {data: [], total: 0, userInfo: {}};
      NodeDataProvider.loadNodeTaskList(parameters).then(({data, total, userInfo}) => {
        me.nodeTaskResult = {data, total, userInfo};
        me.props.bus.emit('node_todo', 'list.loaded', {...me.nodeTaskResult, senderId});
      }).catch(() => {
        me.props.bus.emit('node_todo', 'list.failed_to_load', {senderId});
      });
    });

    me.props.bus.sub(me, 'node_todo', 'todo.do_create', ({task, cb}) => {
      me.props.bus.emit('node_todo', 'todo.creating', task);
      NodeDataProvider.createNodeTask(task).then(() => {
        cb && cb(true);
        me.props.bus.emit('node_todo', 'todo.created', task);
      }).catch(() => {
        cb && cb(false);
        me.props.bus.emit('node_todo', 'todo.failed_to_create', task);
      });
    });

    me.props.bus.sub(me, 'node_todo', 'todo.do_load', nodeTaskId => {
      me.props.bus.emit('node_todo', 'todo.loading', {nodeTaskId});
      NodeDataProvider.loadNodeTask(nodeTaskId).then(todoInfo => {
        me.props.bus.emit('node_todo', 'todo.loaded', todoInfo);
      }).catch(() => {
        me.props.bus.emit('node_todo', 'todo.failed_to_load', {nodeTaskId});
      });
    });

    me.props.bus.sub(me, 'node_todo', 'todo.do_update', ({task, cb}) => {
      me.props.bus.emit('node_todo', 'todo.updating', {nodeTaskId: task['nodeTaskId']});
      NodeDataProvider.updateNodeTask(task).then(() => {
        cb && cb(true);
        me.props.bus.emit('node_todo', 'todo.updated', {nodeTaskId: task['nodeTaskId']});
      }).catch(() => {
        cb && cb(false);
        me.props.bus.emit('node_todo', 'todo.failed_to_update', {nodeTaskId: task['nodeTaskId']});
      });
    });

    me.props.bus.sub(me, 'node_todo', 'todo.do_patch', ({task, cb}) => {
      me.props.bus.emit('node_todo', 'todo.patching', {nodeTaskId: task['nodeTaskId']});
      NodeDataProvider.patchNodeTask(task).then(() => {
        cb && cb(true);
        me.props.bus.emit('node_todo', 'todo.patched', {nodeTaskId: task['nodeTaskId']});
      }).catch(() => {
        cb && cb(false);
        me.props.bus.emit('node_todo', 'todo.failed_to_patch', {nodeTaskId: task['nodeTaskId']});
      });
    });

    me.props.bus.sub(me, 'node_todo', 'todo.do_complete', nodeTaskId => {
      me.props.bus.emit('node_todo', 'todo.completing', {nodeTaskId});
      NodeDataProvider.completeNodeTask(nodeTaskId).then(() => {
        me.props.bus.emit('node_todo', 'todo.completed', {nodeTaskId});
      }).catch(() => {
        me.props.bus.emit('node_todo', 'todo.failed_to_complete', {nodeTaskId});
      });
    });

    me.props.bus.sub(me, 'node_todo', 'memberList.do_load', ({viewId, cb}) => {
      ViewManager.loadViewTeamworkMembers(viewId).then(memberList => {
        cb && cb(memberList);
        me.props.bus.emit('node_todo', 'memberList.loaded', {viewId, memberList});
      }).catch(() => {
        cb && cb(false);
        me.props.bus.emit('node_todo', 'memberList.failed_to_load', {viewId});
      });
    });
  }

  componentWillUnmount() {
    let me = this;

    me.props.bus.remove(me);
  }

  render() {
    return (
      <div style={{height: 0, width: 0}} />
    );
  }
}

NodeTodoLogic.defaultProps = {
  bus: PB,
};

NodeTodoLogic.propTypes = {
  bus: PropTypes.instanceOf(SimplePB),
  currentUserId: PropTypes.number.isRequired,
};

export default NodeTodoLogic;