/*
 * @Author: Carr.s
 * @Date: 2022-07-22 10:08:27
 * @LastEditTime: 2022-07-22 15:54:30
 * @LastEditors: Carr.s
 * @Description: 节点的子弹评估 Modal
 * @Copyright by joinmap
 */
import React from "react";
import PropTypes from "prop-types";
import {
  Modal,
  Button,
  Popover,
  Radio,
  message,
  Form,
  Select,
  Row,
  Col,
  Input,
} from "antd";
import PB, { SimplePB } from "@/libs/simplePB";
import Icon from "@/components/common/common.icon";
import { IconTypes } from "@/constants/common";
import nodeStyle from "@/style/components/mainView/node.less";
import NodeAssessFormAdd from "@/components/common/node/assess/common.node.assess.form.add";
import NodeAssessList from "@/components/common/node/assess/common.node.assess.list";

// 数据流
import NodeDataProvider from "@/components/common/dataProvider/common.dataProvider.node";
import ViewManager from "@/components/common/common.viewManager";

class NodeAssessModal extends React.Component {
  state = {
    visible: false,
    modalWidth: 60,
    userInfo: undefined,
    viewId: undefined,
    node: undefined,
    action: "create", // modal 的状态: create=写评估、list=评估列表、detail=评估详情
    assessList: [], // 全部评估数组
    loadAssessList: false, // 查询全部评估请求状态
    assessFieldsTotal: {
      mainPoints: 0,
      advantage: 0,
      disadvantage: 0,
      shortTerm: 0,
      midTerm: 0,
    }, // 节点评估每个评估子项的有效个数
  };
  // modal的高度
  modalBodyHeight = 0;

  onClose = () => {
    this.setState({
      visible: !this.state.visible,
    });
  };
  onSave = () => {
    const { action, viewId, userInfo, node } = this.state;

    this.props.bus.emit("node_assess", "save", { viewId, node });
  };

  loadAssessList = () => {
    let me = this;
    const { action, viewId, userInfo, node } = me.state;
    me.setState(
      {
        loadAssessList: true,
      },
      () => {
        NodeDataProvider.loadNodeAssessByNodeId(viewId, node.id, {})
          .then((res) => {
            // let res = demoRes;
            // 判断是否查询成功 => 显示列表?
            if (res.code == 0) {
              // 统计各个子项的数据个数
              const fieldsTotal = {
                mainPoints: 0,
                advantage: 0,
                disadvantage: 0,
                shortTerm: 0,
                midTerm: 0,
              };
              Object.keys(fieldsTotal).forEach((k) => {
                let total = 0;

                res.data.forEach((i) => {
                  let isFieldBlank = true;
                  let fieldLineData = JSON.parse(i[k]);
                  Object.values(fieldLineData).forEach((v) => {
                    if (v && v != "") {
                      isFieldBlank = false;
                    }
                  });
                  if (!isFieldBlank) {
                    total++;
                  }
                });

                fieldsTotal[k] = total;
              });

              me.setState({
                assessList: res.data,
                assessFieldsTotal: fieldsTotal,
                loadAssessList: false,
              });
            } else {
              // 加载出错
              me.setState({
                assessList: [],
                loadAssessList: false,
              });
            }
          })
          .catch((e) => {
            // 查询失败
            me.setState({
              assessList: [],
              loadAssessList: false,
            });
          })
          .finally(() => {});
      }
    );
  };

  componentDidMount() {
    let me = this;
    // pb create
    this.props.bus.sub(
      this,
      "node_assess",
      "modal.show",
      ({ action, viewId, node, loadList }) => {
        if (action == "create") {
          me.setState(
            { action, viewId, node, visible: true, modalWidth: 60 },
            () => {
              setTimeout(() => {
                let els = document.getElementsByClassName("ant-modal-body");
                console.log(
                  "🚀 ~ file: common.node.assess.modal.js ~ line 110 ~ NodeAssessModal ~ componentDidMount ~ els",
                  els
                );
                if (els.length > 0) {
                  me.modalBodyHeight = els[0].clientHeight;
                } else {
                  me.modalBodyHeight = 615;
                }

                // 加载已有评估列表
                if (loadList) {
                  me.loadAssessList();
                }
              }, 500);
            }
          );
        } else {
          me.setState({ action, viewId, node, visible: true }, () => {
            setTimeout(() => {
              let els = document.getElementsByClassName("ant-modal-body");
              console.log(
                "🚀 ~ file: common.node.assess.modal.js ~ line 124 ~ NodeAssessModal ~ me.setState ~ els",
                els
              );
              if (els.length > 0) {
                me.modalBodyHeight = els[0].clientHeight;
              } else {
                me.modalBodyHeight = 615;
              }

              // 加载已有评估列表
              if (loadList) {
                me.loadAssessList();
              }
            }, 500);
          });
        }
      }
    );

    // pb load list
    this.props.bus.sub(this, "node_assess", "modal.load.list", () => {
      me.loadAssessList();
    });

    // pb show list
    this.props.bus.sub(this, "node_assess", "modal.show.list", () => {
      me.setState({ action: "list", modalWidth: 60 });
    });

    // pb change modal width
    // this.props.bus.sub(this, "node_assess", "modal.change.width", (width) => {
    //   me.setState({ modalWidth: width });
    // });

    // save success
    this.props.bus.sub(this, "node_assess", "save.success", (assessId) => {
      me.loadAssessList();
      me.props.bus.emit("node_assess", "modal.show.list", assessId);
    });
  }

  componentWillUnmount() {
    this.props.bus.remove(this);
  }

  render() {
    let me = this;
    const { currentUserId } = this.props;
    const {
      modalWidth,
      action,
      userInfo,
      viewId,
      node,
      visible,
      assessList,
      assessFieldsTotal,
      loadAssessList,
    } = this.state;

    // modal 定制
    let bodyStyle = {};
    let footerBtnArr = [];
    if (action === "create") {
      footerBtnArr = [
        <Button
          key={"cancel"}
          style={{ marginRight: "8px" }}
          onClick={me.onClose}
        >
          取消
        </Button>,
        <Button
          key={"confirm"}
          type={"primary"}
          style={{ marginRight: "8px" }}
          onClick={me.onSave}
        >
          保存
        </Button>,
      ];
    } else if (action === "list") {
      bodyStyle = { padding: "0 0", display: "flex" };
      footerBtnArr = [
        <Button
          key={"cancel"}
          style={{ marginRight: "8px" }}
          onClick={me.onClose}
        >
          关闭
        </Button>,
      ];
    }

    return (
      <Modal
        bodyStyle={bodyStyle}
        title={
          <div>
            <span style={{ fontSize: "1.2rem", fontWeight: 600 }}>
              <a
                href="javascript:void(0);"
                onClick={() => {
                  me.modalBodyHeight =
                    document.getElementsByClassName(
                      "ant-modal-body"
                    )[0].clientHeight;

                  me.setState({ action: "create", modalWidth: 60 });
                }}
                style={
                  action === "create"
                    ? {}
                    : { color: "#202020", fontSize: "1.1rem" }
                }
              >
                添加子弹评估
              </a>
            </span>
            <span
              style={{ fontSize: "1.2rem", fontWeight: 600, marginLeft: 20 }}
            >
              <a
                href="javascript:void(0);"
                onClick={() => {
                  me.modalBodyHeight =
                    document.getElementsByClassName(
                      "ant-modal-body"
                    )[0].clientHeight;

                  me.setState({ action: "list", modalWidth: 60 });
                }}
                style={
                  action === "list"
                    ? {}
                    : { color: "#202020", fontSize: "1.1rem" }
                }
              >
                查看子弹评估
              </a>
            </span>
          </div>
        }
        maskClosable={false}
        destroyOnClose={true}
        visible={visible}
        centered={true}
        onCancel={me.onClose}
        width={modalWidth + "rem"}
        footer={footerBtnArr}
      >
        {!node ? (
          <div style={{ height: 500 }}>没有选择节点</div>
        ) : action === "create" ? (
          <NodeAssessFormAdd
            viewId={viewId}
            node={node}
            userInfo={userInfo}
            assessList={assessList}
            loadAssessList={loadAssessList}
            height={me.modalBodyHeight}
            assessFieldsTotal={assessFieldsTotal}
          />
        ) : action === "list" ? (
          <NodeAssessList
            viewId={viewId}
            node={node}
            userInfo={userInfo}
            height={me.modalBodyHeight}
            assessList={assessList}
            loadAssessList={loadAssessList}
          />
        ) : (
          <div>Nothing</div>
        )}
      </Modal>
    );
  }
}

NodeAssessModal.defaultProps = {
  bus: PB,
  userInfo: undefined,
};

NodeAssessModal.propTypes = {
  bus: PropTypes.instanceOf(SimplePB),
  userInfo: PropTypes.object,
  // viewId: PropTypes.string,
};
export default NodeAssessModal;
