import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import {Menu} from 'antd';

class CommonContextMenu extends React.PureComponent {
  state = {
    x: 0,
    y: 0,
    opacity: 0,
  };

  element = undefined;

  updatePosition = () => {
    let me = this;

    let {x, y} = me.props, modified = false;

    if (window.innerHeight < (y + me.element.clientHeight + 40)) {
      y = window.innerHeight - me.element.clientHeight - 40;
      modified = true;
    }
    if (window.innerWidth < (x + me.element.clientWidth)) {
      x -= me.element.clientWidth;
      modified = true;
    }
    if (modified) {
      me.setState({x, y, opacity: 1})
    } else {
      me.setState({opacity: 1})
    }
  }

  componentDidMount() {
    let me = this;
    me.element = ReactDOM.findDOMNode(me);

    requestAnimationFrame(() => {
      me.setState({x: me.props.x, y: me.props.y, opacity: 0}, () => {
        if (me.props.visible) {
          requestAnimationFrame(() => me.updatePosition());
        }
      });
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let me = this;

    if (me.props.x !== prevProps.x || me.props.y !== prevProps.y) {
      requestAnimationFrame(() => {
        me.setState({x: me.props.x, y: me.props.y, opacity: 0});
      });
    }
    if (me.props.visible && !prevProps.visible && me.element) {
      requestAnimationFrame(() => me.updatePosition());
    }
  }

  render() {
    let me = this;

    return (
      <Menu
        id="common_context_menu"
        className={'dark-theme'}
        style={{
          display: me.props.visible ? 'block' : 'none',
          position: 'absolute',
          left: me.state.x,
          top: me.state.y,
          opacity: me.state.opacity,
        }}
        selectable={false}
        mode={'vertical'}
        theme={'dark'}
      >
        {me.props.children}
      </Menu>
    );
  }
}

CommonContextMenu.defaultProps = {
  visible: false,
  x: 0,
  y: 0,
};

CommonContextMenu.propTypes = {
  visible: PropTypes.bool,
  x: PropTypes.number,
  y: PropTypes.number,
};

export default CommonContextMenu;