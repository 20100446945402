import React from 'react';
import PropTypes from 'prop-types';
import {Button,Tooltip} from 'antd';

import {IconTypes} from '@/constants/common';

import Icon from '@/components/common/common.icon';
import {Copyright} from '@/components/framework/frame.components';

import style from '@/style/components/common.loginRelatedView.less';
import intl from 'react-intl-universal';

class Header extends React.PureComponent {
  render() {
    let me = this;

    return (
      <React.Fragment>
        <div className={style['logo']}>
          <img
            onClick={() => me.props.history.push('/')}
            src={intl.get('locale')==='zh-cn'?'/assets/logo-hans.png':'/assets/logo-hans_en.png'}
            alt={intl.get('Custom.general.title')}
          />
        </div>
        {/*
        <div className={style['toolbar']}>
          <Button
            size={'large'}
            style={{marginRight: '0.5em', letterSpacing: 'inherit'}}
          >
            <Icon name="icon-search" theme="outlined" type={IconTypes.ICON_FONT}/>
            更多搜索
          </Button>
          <Button
            size={'large'}
            style={{marginRight: '0.5em', letterSpacing: 'inherit'}}
          >
            <img className={style['user-avatar']} src={'/assets/home/user.png'} alt="头像"/>
            登录/注册
          </Button>
        </div>
        */}
      </React.Fragment>
    );
  }
}

Header.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
};

class Background extends React.PureComponent {
  state = {
    index: -1,
    active: false,
    smallScreen: 1,
    widthImgBase: 20
  };

  timer = undefined;

  swiperMainImg = '/assets/home/index-bg.jpg';

  swiperData = [{
      img:'/assets/home/index-bg0.jpg',
      icon:'/assets/home/index-icon0.png',
      title:intl.get('Custom.leader.swiperTitle_0'),
      content:intl.get('Custom.leader.swiperContent_0'),
    },{
      img:'/assets/home/index-bg1.jpg',
      icon:'/assets/home/index-icon1.png',
      title:intl.get('Custom.leader.swiperTitle_1'),
      content:intl.get('Custom.leader.swiperContent_1'),
    },{
      img:'/assets/home/index-bg2.jpg',
      icon:'/assets/home/index-icon2.png',
      title:intl.get('Custom.leader.swiperTitle_2'),
      content:intl.get('Custom.leader.swiperContent_2'),
    },{
      img:'/assets/home/index-bg3.jpg',
      icon:'/assets/home/index-icon3.png',
      title:intl.get('Custom.leader.swiperTitle_3'),
      content:intl.get('Custom.leader.swiperContent_3'),
    },{
      img:'/assets/home/index-bg4.jpg',
      icon:'/assets/home/index-icon4.png',
      title:intl.get('Custom.leader.swiperTitle_4'),
      content:intl.get('Custom.leader.swiperContent_4'),
    },{
      img:'/assets/home/index-bg5.jpg',
      icon:'/assets/home/index-icon5.png',
      title:intl.get('Custom.leader.swiperTitle_5'),
      content:intl.get('Custom.leader.swiperContent_5'),
    },{
      img:'/assets/home/index-bg6.jpg',
      icon:'/assets/home/index-icon6.png',
      title:intl.get('Custom.leader.swiperTitle_6'),
      content:intl.get('Custom.leader.swiperContent_6'),
    }
  ];

  turn = step => {
    let index = this.state.index + step;
    if (index >= this.swiperData.length) {
      index = 0;
    }
    if (index < 0) {
      index = this.swiperData.length - 1;
    }
    this.setState({ index: index })
  };

  swiperGo = () => {
    this.timer = setInterval(
      () => this.turn(1),
      6000,
    )
  };

  swiperClear = (index) => {
      clearInterval(this.timer);
      this.setState({ active: true,index:index });
  };

  go = (index) => {
    if(index === this.state.index && this.state.active){
      this.setState({ active: false });
      this.swiperGo();
    }
  };

  overSwiper = (index) => {
    this.setState({ active: true,index:index });
  };

  outSwiper = (index) => {
    this.setState({ active: false,index:-1 });
  };


  componentDidMount() {
    let ScreenObject_1 = window.matchMedia('(max-height:600px)').matches?1:0;
    let ScreenObject_2 = window.matchMedia('(max-height:800px)').matches?1:0;
    let ScreenObject_3 = window.matchMedia('(max-height:1000px)').matches?1:0;
    let ScreenObject_4 = window.matchMedia('(max-height:1200px)').matches?1:0;
    let ScreenObject_5 = window.matchMedia('(max-height:1500px)').matches?1:0;
    let ScreenObject_6 = window.matchMedia('(max-height:1800px)').matches?1:0;
    let ScreenObject_7 = window.matchMedia('(max-height:2200px)').matches?1:0;
    let smallScreen = 7 - ScreenObject_1 - ScreenObject_2 - ScreenObject_3 - ScreenObject_4 - ScreenObject_5 - ScreenObject_6 - ScreenObject_7;
    this.setState({smallScreen,widthImgBase:smallScreen*3+5});
  }

  componentDidUpdate() {
    let me = this;
    let ScreenObject_1 = window.matchMedia('(max-height:600px)').matches?1:0;
    let ScreenObject_2 = window.matchMedia('(max-height:800px)').matches?1:0;
    let ScreenObject_3 = window.matchMedia('(max-height:1000px)').matches?1:0;
    let ScreenObject_4 = window.matchMedia('(max-height:1200px)').matches?1:0;
    let ScreenObject_5 = window.matchMedia('(max-height:1500px)').matches?1:0;
    let ScreenObject_6 = window.matchMedia('(max-height:1800px)').matches?1:0;
    let ScreenObject_7 = window.matchMedia('(max-height:2200px)').matches?1:0;
    let smallScreen = 7 - ScreenObject_1 - ScreenObject_2 - ScreenObject_3 - ScreenObject_4 - ScreenObject_5 - ScreenObject_6 - ScreenObject_7;
    this.setState({smallScreen,widthImgBase:smallScreen*3+5});
  }

  render() {
    return (
      <div className={style['intro-frame']}>

        <div className={style.root}>
          <div style={{opacity:this.state.active?'1':'0.3'}} className={style.center} >
              <img src={ this.state.index===-1?this.swiperMainImg:this.swiperData[this.state.index].img } />
          </div>
        </div>
        <div className={style['intro-box']} style={{display:this.state.active?'none':'',top:this.state.smallScreen*this.state.smallScreen/6+'rem'}}>
                <div className={`${style['intro-step']} ${style['intro-step-1']}`} style={{marginBottom: 0}}>
                  <img src={'/assets/joinmap.png'} alt={intl.get('Custom.general.titleMeta')} style={{width:this.state.widthImgBase*0.6+'rem'}}/>
                </div>
                <div className={`${style['intro-step']} ${style['intro-step-1']}`} style={{marginTop: 0}}>
                  <img src={intl.get('locale')==='zh-cn'?'/assets/home/index-1-1.png':'/assets/home/index-1-1_en.png'} alt={intl.get('Custom.general.titleMeta')} style={{width:this.state.widthImgBase*1.5+'rem'}}/>
                </div>
              </div>
              <div className={style['intro-box']} style={{display:this.state.active?'none':'',top:'auto'}}>
                <div className={`${style['intro-step']} ${style['intro-step-2']}`}>
                  <img
                    style={{width:this.state.widthImgBase+'rem'}}
                    src={intl.get('locale')==='zh-cn'?'/assets/home/index-3-1.png':'/assets/home/index-3-1_en.png'}
                    alt={intl.get('Custom.general.join')}
                    onClick={() => me.props.history.push('/mainview/dashboard/public')}
                  />
                </div>
        </div>
        <div className={style['intro-box']} style={{marginTop:this.state.smallScreen*3+'rem',display:this.state.active?'':'none'}}>
                {this.state.index >-1 && this.swiperData[this.state.index].content.map((item, index) => (
                  <div key={index} className={`${style['intro-step']} ${style['intro-step-1']} ${style['swiper-text']}`}>
                    {item}
                  </div>
                ))}
              </div>
              <div className={`${style['intro-step']} ${style['swiper-box']}`} style={{bottom:this.state.smallScreen*1+'rem',display:'inline'}}>
                <div className={style['swiper-row']}>
                { this.swiperData.map((item, index) => (
                  <div key={index} onMouseOver={() =>this.overSwiper(index)} onMouseOut={() =>this.outSwiper(index)} className={style.active}>
                    <p style={{marginBottom:this.state.smallScreen/4+'rem'}}><img src={ item.icon }></img></p>
                    <p style={{fontSize:this.state.smallScreen/4+'rem'}}>{ item.title }</p>
                  </div>
                ))
                }
                </div>
        </div>
        <div className={style.rightlogo} > {/*style={{display:'none'}}*/}
          <Tooltip trigger='click' placement='top'>
            <div className={style.rightlogointem} onClick={() => {window.open('https://space.bilibili.com/1839363891', '_blank');}}>
            <i className={style.wx_channels}><img src={'/assets/home/bilibili.png'}/></i>
              <span style={{fontSize:this.state.smallScreen/10+'rem'}}>{intl.get('Custom.outLink.bilibili')}</span>
            </div>
          </Tooltip>
          <Tooltip trigger='click' placement='top' title={<div className={style['control-list']}><img src={'/assets/home/wx_channels_code.jpg'}/></div>}>
            <div className={style.rightlogointem}>
              <i className={style.wx_channels}><img src={'/assets/home/wx_channels.png'}/></i>
              <span style={{fontSize:this.state.smallScreen/10+'rem'}}>{intl.get('Custom.outLink.wx_video')}</span>
            </div>
          </Tooltip>
          <Tooltip trigger='click' placement='top' title={<div className={style['control-list']}><img src={'/assets/home/wx_mini_p.jpg'}/></div>}>
            <div className={style.rightlogointem}>
              <i className={style.wx_channels}><img src={'/assets/home/wx_mini.png'}/></i>
              <span style={{fontSize:this.state.smallScreen/10+'rem'}}>{intl.get('Custom.outLink.wx_mini')}</span>
            </div>
          </Tooltip>
        </div>
      </div>
    );
  }
}

class Intro extends React.PureComponent {
  render() {
    // noinspection HttpUrlsUsage
    return (
      <React.Fragment>
        <div className={style['lesson-frame']}>
          <div className={style['lesson-content']}>
            <div className={style['lesson-text-container']}>
              <span>参加微创业</span>
            </div>
            <div className={style['lesson-image-container']}>
              <img src={'/assets/home/video.png'} alt={'参加微创业'}/>
            </div>
          </div>
          <div className={style['lesson-popup']}>
            <h4>观看微创业介绍视频</h4>
            <ol>
              <li><a href={'http://video.joinmap.ai/guide-E1129.mp4'} target={'_blank'}>产品基本操作</a></li>
              <li><a href={'http://video.joinmap.ai/kc2-bzkhcg.mp4'} target={'_blank'}>容易上手的场景</a></li>
              <li><a href={'http://video.joinmap.ai/kc3-cyhms.mp4'} target={'_blank'}>谋生和创业</a></li>
            </ol>
          </div>
        </div>
        <div className={style['mini-program-frame']}>
          <div className={style['mini-program-content']}>
            <div className={style['mini-program-text-container']}>
              <span>手机小程序</span>
            </div>
            <div className={`${style['mini-program-image-container']} ${style['mini-program-image-small']}`}>
              <img src={'/assets/home/mini-program.jpg'} className={style['mini-program-image-small']} alt={'小程序码'} />
            </div>
          </div>
          <div className={`${style['mini-program-popup']} ${style['mini-program-image-container']}`}>
            <img src={'/assets/home/mini-program.jpg'} alt={'小程序码'} />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

class Bottom extends React.PureComponent {
  render() {
    return (
      <Copyright className={style["version"]} locale={intl.get('locale')}/>
    );
  }
}

export default class LoginRelatedView extends React.PureComponent {
  static Header = Header;
  static Background = Background;
  static Intro = Intro;
  static Bottom = Bottom;

  render() {
    return null;
  }
}