import React from 'react';
import PropTypes from 'prop-types';
import {Route, Redirect, Switch} from "react-router-dom";

import {Layout, Input, Modal, message, notification, Tree} from "antd";

import style from "@/style/containers/explorationView.less";
import {Copyright} from "@/components/framework/frame.components";

// 组件
import Node from '@/libs/view/Node';
import ViewDataProvider, {overrideNextMessage} from '@/components/common/dataProvider/common.dataProvider.view';
import NodeDataProvider from "@/components/common/dataProvider/common.dataProvider.node";

import Loading from "@/components/common/common.loading";
import ResultList from "@/components/explorationView/exploration.list";
import Map from '@/components/explorationView/exploration.map'; // 地图组件
import Timeline from '@/components/explorationView/exploration.timeline'; // 时间线组件
import Network from '@/components/mainView/toolbar/network'; // 关系图组件
import {
  getNodeDisplayTitle,
  NODE_TYPE_COMPANY, // 1-企业
  NODE_TYPE_TALENT, // 2-人物
  NODE_TYPE_PATENT, // 3-专利
  NODE_TYPE_PAPER, // 4-论文
  NODE_TYPE_ORG, // 6-协会
  NODE_TYPE_INSTITUTE, // 7-院所
  NODE_TYPE_DATASET, // 11-数据
  NODE_TYPE_GOV, // 12-政府
  NODE_TYPE_COLLEGE_AND_UNIVERSITY, // 16-高校
  NODE_TYPE_PARK, // 17-园区
  // NODE_TYPE_TECHNOLOGY, // 18-技术
  TYPE_FIELD_NAME,
} from "@/constants/vis.defaultDefine.1";
import {NetworkEvents, NodeEvents} from "@/libs/view/network/events";
import Icon from "@/components/common/common.icon";
import {IconTypes} from "@/constants/common";
import ExplorationSearchOptionForm from "@/components/explorationView/exploration.searchOptionForm";
import {
  NetworkDataLoadingStatus,
  NetworkSubViewDataLoadingStatus,
  NetworkSubViewExploringStatus,
  NodeExploringStatus,
} from "@/libs/view/network/status";
import {DataSet} from '@/libs/vis';
import TimestampAndLocationStatistics, {
  getAndSetChinaADC,
  getAndSetTimestamp,
  FilterAndStatisticsBus,
} from "@/components/common/common.timestampAndLocationStatistics";
import {bindUtil} from "@/libs/core-decorators";
import {autoSetState, withReactStateHelper} from "@/libs/react-state-helper";
import AlgSwitch from "@/components/explorationView/exploration.algSwitch";
import StepInfo from "@/components/common/relation/common.relation.stepInfo";
import WizardModal from "@/components/explorationView/exploration.wizardModal";
import qs from "qs";

const ROOT_PATH = '/explore/:viewId';

const getDefaultExactMatchOptions = () => ({
  time: '',
  types: [
    NODE_TYPE_GOV,
  ],
  areas: [],
  keywords: [],
});

const numberOfNodesPerType = 500;

const resourceTypes = [
  {label: '政府', value: NODE_TYPE_GOV},
  {label: '协会', value: NODE_TYPE_ORG},
  {label: '园区', value: NODE_TYPE_PARK},
  {label: '企业', value: NODE_TYPE_COMPANY},
  {label: '高校', value: NODE_TYPE_COLLEGE_AND_UNIVERSITY},
  {label: '院所', value: NODE_TYPE_INSTITUTE},
  {label: '人物', value: NODE_TYPE_TALENT},
  {label: '专利', value: NODE_TYPE_PATENT},
  {label: '论文', value: NODE_TYPE_PAPER},
  /*{label: '技术', value: NODE_TYPE_TECHNOLOGY},*/
  {label: '数据', value: NODE_TYPE_DATASET},
];

class ExplorationViewHeader extends React.Component {
  state = {
    // 过滤的字符串
    filterText: '',
    appliedFilterText: '',
    applyFilterTimeout: -1,

    //viewType: 'list',
  };

  clearFilter = () => {
    this.setState({filterText: ''}, () => {
      this.props.filterBus.otherFilters = [];
      this.props.filterBus.lastUpdate = Math.random();
    });
  };

  onFilter = (filterText) => {
    let me = this;
    me.setState({
      filterText,
    });
  };

  componentWillReceiveProps(nextProps, nextContext) {
    // props更新后清空过滤
    this.clearFilter();
  }

  render() {
    let me = this;

    // noinspection RequiredAttributes
    return (
      <Layout.Header className={style['header']}>
        <Input
          placeholder={'过滤查询结果'}
          disabled={me.props.filterDisabled}
          style={me.props.searchInputStyle}
          className={style['result-filter']}
          prefix={<Icon name={'icon-filter'} type={IconTypes.ICON_FONT}/>}
          suffix={(
            <Icon
              name={'close-circle'}
              style={{display: me.state.filterText ? undefined : 'none'}}
              onClick={me.clearFilter}
            />
          )}
          value={me.state.filterText}
          onChange={e => me.onFilter(e.target.value)}
          onPressEnter={() => {
            let filterText = `${me.state.filterText}`;
            if (filterText) {
              me.props.filterBus.otherFilters[0] = nodes => {
                // 过滤文本：me.state.appliedFilterText
                if (filterText) {
                  nodes = nodes.filter(node => (
                    node.fname.includes(filterText) ||
                    (node[TYPE_FIELD_NAME] === NODE_TYPE_TALENT && node.org.includes(filterText)) ||
                    node.tag.includes(filterText) ||
                    node.keyStc.includes(filterText)
                  ));
                }
                return nodes;
              };
            } else {
              me.props.filterBus.otherFilters = [];
            }
            me.props.filterBus.lastUpdate = Math.random();
          }}
        />
      </Layout.Header>
    );
  }
}

ExplorationViewHeader.defaultProps = {
  searchInputStyle: {},
};

ExplorationViewHeader.propTypes = {
  viewId: PropTypes.string,
  viewName: PropTypes.string,
  subViewName: PropTypes.string,
  subViewTags: PropTypes.array,
  networkRef: PropTypes.instanceOf(ViewDataProvider).isRequired,
  dataLoadingStatus: PropTypes.string.isRequired,
  subViewDataLoadingStatus: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  filterBus: PropTypes.instanceOf(FilterAndStatisticsBus).isRequired,
  filterDisabled: PropTypes.bool.isRequired,
  searchInputStyle: PropTypes.object,
};

@bindUtil.asTargetClass
@withReactStateHelper
class ExplorationEmptyResult extends React.Component {

  @bindUtil.bindToProperty('props.bus', 'statisticsUpdated')
  @autoSetState
  statisticsUpdated = false;

  processedUpdate = false; // 已处理过的原始数据更新标记

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (this.statisticsUpdated && this.processedUpdate !== this.statisticsUpdated) {
      this.processedUpdate = this.statisticsUpdated;
      return true;
    } else {
      return this.props.status !== nextProps.status;
    }
  }

  render() {
    let me = this;

    return (
      <div
        className={style['content-result-cover-content']}
        style={{
          display: (me.props.bus.nodes.length === 0 && me.props.status === 'success') ? 'flex' : 'none',
        }}
      >
        <div>
          <i>
            <svg width="64" height="41" xmlns="http://www.w3.org/2000/svg">
              <g transform="translate(0 1)" fill="none">
                <ellipse fill="hsla(0, 0%, 45%, 1)" cx="32" cy="33" rx="32" ry="7"/>
                <g stroke="hsla(0, 0%, 35%, 0.8)">
                  <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"/>
                  <path d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z" fill="hsla(0, 0%, 45%, 1)"/>
                </g>
              </g>
            </svg>
          </i><br />
          {
            me.props.resourceId === 'resources' ?
              `没有找到已关联的资源，${me.props.bus.otherFilters.length > 0 ? '您可以去除过滤条件或' : '请先'}将资源加入子图。` :
              `没有找到相关资源，请尝试${me.props.bus.otherFilters.length > 0 ? '去除过滤条件或使用' : ''}其他计算模式。`
          }
        </div>
      </div>
    );
  }
}

ExplorationEmptyResult.propTypes = {
  resourceId: PropTypes.string,
  status: PropTypes.string.isRequired,
  bus: PropTypes.instanceOf(FilterAndStatisticsBus).isRequired,
};

class ExplorationView extends React.Component {

  state = {
    viewId: '',
    viewName: undefined,

    subViewId: '',
    subViewName: undefined,
    subViewTags: [],
    subViewVersion: 0,

    selectedNodeId: '', // 在图谱中选中的节点ID
    withRelation: false,

    specifiedResource: undefined,

    dataLoadingStatus: NetworkDataLoadingStatus.IDLE,
    subViewDataLoadingStatus: NetworkSubViewDataLoadingStatus.IDLE,
    nodeExploringStatus: NodeExploringStatus.IDLE,
    subViewExploringStatus: NetworkSubViewExploringStatus.IDLE,
    nodeTags: [],

    readonly: true, // 是否只读
    emptyResult: true, // 过滤后的数据是否为空

    showWizard: false,
    sourceNodeInfo: undefined,
    optionsUpdated: Math.random(), // 向导触发的探索配置被修改的标记

    showAlgSwitch: false, // 是否显示算法切换面板
  };

  filterAndStatisticsBus = new FilterAndStatisticsBus();

  reloadSubView = false;

  explorationResult = {
    nodes: new DataSet(),
    edges: new DataSet(),
  };

  exactMatchOptions = getDefaultExactMatchOptions();

  viewDataProvider = new ViewDataProvider();

  explorationAlg = 0; // 训练模式

  senderId = `exploration-sender-${Math.round(100 * Math.random())}`;

  explorationResultByType = {}; // 通过向导获取的资源探索结果

  /**
   * @return {ViewOptions}
   */
  getExactMatchOptions = () => {
    let me = this;

    if (me.state.specifiedResource && me.state.specifiedResource.id === 'resources') {
      return {
        ...me.exactMatchOptions,
        types: [],
      }
    } else {
      return me.exactMatchOptions;
    }
  };

  setExactMatchOptions = options => this.exactMatchOptions = {
    ...this.exactMatchOptions,
    ...options,
  };

  onAddToGraph = node => {
    let me = this;

    if (this.viewDataProvider.getNode(node.id)) {
      this.viewDataProvider.addRelation(this.state.selectedNodeId, node.id).then(() => {
        node.status = 1;
        node.$getInfoCardRef && node.$getInfoCardRef().forceUpdate();
        message.success('资源添加成功。');
        me.reloadSubView = true;
      });
    } else {
      this.viewDataProvider.addNodeLinkedTo(
        [{...node, userConfirmed: true, forceAdd: true}],
        this.state.selectedNodeId,
        false,
        false,
        undefined,
        false
      ).then(() => {
        node.status = 1;
        node.$getInfoCardRef && node.$getInfoCardRef().forceUpdate();
        message.success('资源添加成功。');
        me.reloadSubView = true;
      });
    }
  };

  onRemoveFromGraph = node => {
    let me = this;
    const connectedEdges = me.viewDataProvider.getConnectedEdgeIds(node.id)
      .map(edgeId => me.viewDataProvider.getEdge(edgeId));
    const subViewNodeIds = [me.state.subViewId, ...me.viewDataProvider.getSubViewInfo(me.state.subViewId).nodeIds];
    const edgesToRemove = connectedEdges.filter(
      edgeInfo => edgeInfo.from === node.id ? subViewNodeIds.includes(edgeInfo.to) :
        subViewNodeIds.includes(edgeInfo.from));
    const removedEdges = [];
    let content = null;
    if (edgesToRemove.length === connectedEdges.length) {
      // 直接删除节点的不做复杂提示
      content = (
        <p>
          是否移除资源: "{getNodeDisplayTitle(node)}" ？
        </p>
      );
    } else {
      // 删除关联关系
      content = (
        <p>
          是否移除资源: "{getNodeDisplayTitle(node)}" ？<br />
          <span style={{fontSize: '0.8em'}}>
            请注意：本操作将移除该资源与 {me.state.subViewName} 中所有节点的关联关系，与其他子图的关联关系不变。
          </span>
        </p>
      );
    }

    Modal.confirm({
      title: '移除资源',
      content,
      okText: '确认移除',
      cancelText: '取消',
      okButtonProps: {type: "danger"},
      onOk: () => {
        if (edgesToRemove.length === connectedEdges.length) {
          // 直接删除节点
          me.viewDataProvider.removeNode(node.id).then(() => {
            node.status = 0;
            node.$getInfoCardRef && node.$getInfoCardRef().forceUpdate();
            message.success('资源移除成功。');
            me.reloadSubView = true;
          });
        } else {
          // 删除关联关系
          const afterEdgeRemoved = edgeId => {
            removedEdges.push(edgeId);
            if (removedEdges.length === edgesToRemove.length) {
              node.status = 0;
              node.$getInfoCardRef && node.$getInfoCardRef().forceUpdate();
              message.success('资源移除成功。');
              me.reloadSubView = true;
            }
          };
          edgesToRemove.forEach(edgeInfo => edgeInfo.from === node.id ?
            me.viewDataProvider.removeRelation(edgeInfo.to, node.id).then(() => afterEdgeRemoved(edgeInfo.id)) :
            me.viewDataProvider.removeRelation(edgeInfo.from, node.id).then(() => afterEdgeRemoved(edgeInfo.id)));
        }
      },
    });
  };

  onExplore = node => {
    let me = this;
    const {viewId, resultType, selectedNodeId, resourceId} = me.props.match.params;
    if (resourceId === node.id) return;

    window.open(
      `${ROOT_PATH.replace(':viewId', viewId)}/${resultType || 'list'}/${selectedNodeId}/${node[TYPE_FIELD_NAME]}/${node.id}`,
      '_blank'
    );
  };

  onTryAgain = () => {
    let me = this;

    if (me.state.dataLoadingStatus === NetworkDataLoadingStatus.FAILED) {
      // noinspection JSIgnoredPromiseFromCall
      me.viewDataProvider.loadData(me.props.match.params.viewId);
    } else if (me.state.subViewDataLoadingStatus === NetworkSubViewDataLoadingStatus.FAILED) {
      // noinspection JSIgnoredPromiseFromCall
      me.viewDataProvider.loadSubViewData();
    } else {
      me.setState({
        subViewExploringStatus: NetworkSubViewExploringStatus.IDLE,
        nodeExploringStatus: NodeExploringStatus.IDLE,
        nodeTags: [],
      });
    }
  };

  onVote = (resultId, vote) => {
    let me = this;

    if (me.state.specifiedResource) {
      return me.viewDataProvider.voteExplorationResultByNode(me.state.subViewId, me.props.match.params['resourceId'], me.explorationAlg,
        resultId, vote);
    } else {
      return me.viewDataProvider.voteExplorationResultBySubview(me.state.subViewId, me.explorationAlg, resultId, vote);
    }
  };

  doExploreByIndividualNode = () => {
    let me = this;

    return me.viewDataProvider.exploreByNode(me.state.subViewId, me.props.match.params['selectedNodeId'],
      me.getExactMatchOptions(), me.state.specifiedResource, numberOfNodesPerType,
      me.props.match.params.resultType === 'network', me.explorationAlg, me.senderId);
  };

  doExploreBySubViewNode = () => {
    let me = this;

    return me.viewDataProvider.exploreBySubview(me.state.subViewId, me.props.match.params['selectedNodeId'],
      me.getExactMatchOptions(), undefined, [], numberOfNodesPerType,
      me.props.match.params.resultType === 'network', me.explorationAlg, me.senderId);
  };

  doExploreByView = () => {
    let me = this;

    return me.viewDataProvider.exploreByView(me.senderId);
  };

  doExploreFromWizard = (type, userSelectedNode, userSelectedTags, sender) => {
    let me = this;

    const matchOptions = me.getExactMatchOptions();
    matchOptions.types = [type];

    if (me.state.specifiedResource) {
      overrideNextMessage('exploreByNode', false);
      return me.viewDataProvider.exploreByNode(me.state.subViewId, me.props.match.params['selectedNodeId'],
        matchOptions, me.state.specifiedResource, numberOfNodesPerType,
        me.props.match.params.resultType === 'network', me.explorationAlg, sender, true);
    } else {
      overrideNextMessage('exploreBySubview', false);
      return me.viewDataProvider.exploreBySubview(me.state.subViewId, me.props.match.params['selectedNodeId'],
        matchOptions, (userSelectedNode ? [userSelectedNode] : undefined), userSelectedTags, numberOfNodesPerType,
        me.props.match.params.resultType === 'network', me.explorationAlg, sender, true);
    }
  };

  /**
   * 显示向导
   */
  showWizard = () => {
    let me = this;

    me.setState({showWizard: true});
  };

  /**
   * 向导完成后加载并展示数据
   */
  onWizardFinished = ({explorationResultByType, selectedType}) => {
    let me = this;

    me.explorationResultByType = explorationResultByType;
    me.setExactMatchOptions({types: [selectedType]});
    me.setState({showWizard: false, optionsUpdated: Math.random()}, () => {
      me.loadDataFromWizard(selectedType);
    });
  };

  /**
   * 从向导加载指定类型的资源
   *
   * @param {number} resourceType 资源类型
   */
  loadDataFromWizard = resourceType => {
    console.log(`尝试加载结果，资源类型：${resourceType}`);
    let me = this;

    const {nodes, edges, sources} = me.explorationResultByType[`r-${resourceType}`];
    me.state.specifiedResource ?
      me.onNodeExplorationResultLoaded({node: me.state.sourceNodeInfo, nodes, edges, sources}) :
      me.onSubViewNodeExplorationResultLoaded({nodes, edges, sources});
  };

  /**
   * 单节点探索结果返回后回调
   *
   * @param {object} node
   * @param {array} nodes
   * @param {array} edges
   * @param {array} sources
   */
  onNodeExplorationResultLoaded = ({node, nodes, edges, sources}) => {
    let me = this;

    nodes = nodes.map(node => {
      getAndSetChinaADC(node);
      getAndSetTimestamp(node);
      return node;
    });
    me.explorationResult = {
      nodes: new DataSet(nodes),
      edges: new DataSet(edges),
    };
    me.setState({
      nodeExploringStatus: NodeExploringStatus.SUCCESS,
      specifiedResource: node,
      edges,
      emptyResult: false,
      statistics: {chinaADC: {}, timestamp: {}},
      nodeTags: sources,
    }, () => {
      me.filterAndStatisticsBus.preprocessed = true;
      me.filterAndStatisticsBus.originalNodes = me.explorationResult.nodes.get();
      me.filterAndStatisticsBus.nodes = me.explorationResult.nodes.get();
      me.filterAndStatisticsBus.chinaADCFilter = '000000';
      me.filterAndStatisticsBus.timestampStartFilter = -1;
      me.filterAndStatisticsBus.timestampEndFilter = -1;
      me.filterAndStatisticsBus.lastUpdate = Math.random();
    });
  };

  /**
   * 图谱节点探索结果返回后回调
   *
   * @param {object} node
   * @param {array} nodes
   * @param {array} edges
   * @param {array} sources
   */
  onSubViewNodeExplorationResultLoaded = ({nodes, edges, sources}) => {
    let me = this;

    nodes = nodes.map(node => {
      getAndSetChinaADC(node);
      getAndSetTimestamp(node);
      return node;
    });
    me.explorationResult = {
      nodes: new DataSet(nodes),
      edges: new DataSet(edges),
    };
    me.setState({
      subViewExploringStatus: NetworkSubViewExploringStatus.SUCCESS,
      emptyResult: false,
      nodeTags: sources,
    }, () => {
      me.filterAndStatisticsBus.preprocessed = true;
      me.filterAndStatisticsBus.originalNodes = me.explorationResult.nodes.get();
      me.filterAndStatisticsBus.nodes = me.explorationResult.nodes.get();
      me.filterAndStatisticsBus.chinaADCFilter = '000000';
      me.filterAndStatisticsBus.timestampStartFilter = -1;
      me.filterAndStatisticsBus.timestampEndFilter = -1;
      me.filterAndStatisticsBus.lastUpdate = Math.random();
    });
  };

  /**
   * 渲染树
   * @param data
   * @returns {*}
   */
  renderTreeNodes = data => {
    return data.map((item, index) => {
      if (item.children) {
        return (
          <Tree.TreeNode title={item.title} key={index} dataRef={item}>
            {this.renderTreeNodes(item.children)}
          </Tree.TreeNode>
        );
      }
      return <Tree.TreeNode {...item} dataRef={item}/>;
    });
  };

  arrToTree = (arr) => {
    return arr.map(a => {
      return {title: a}
    })
  };

  componentWillMount() {
    let params = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
      strictNullHandling: true,
    });

    if (params['from'] === 'menu') {
      this.setState({showAlgSwitch: true});
    }
  }

  componentDidMount() {
    let me = this;

    me.viewDataProvider.with(me).subscribe(NetworkEvents.LOADING_DATA, viewId => {
      me.setState({
        viewId,
        viewName: undefined,
        specifiedResource: undefined,
        dataLoadingStatus: NetworkDataLoadingStatus.PROCESSING,
        readonly: true,
      });
    }).subscribe(NetworkEvents.LOADING_DATA_SUCCESS, viewId => {
      const viewData = me.viewDataProvider.getData();
      if (me.state.viewId === viewId && me.state.viewName !== viewData.viewInfo.name) {
        me.setState({
          viewName: viewData.viewInfo.name,
          dataLoadingStatus: NetworkDataLoadingStatus.SUCCESS,
          readonly: viewData.viewInfo['userId'] !== parseInt(localStorage.getItem('userId'), 10),
        }, () => {
          // 加载子图信息
          // noinspection JSIgnoredPromiseFromCall
          me.viewDataProvider.loadSubViewData();
        });
      }
    }).subscribe(NetworkEvents.LOADING_DATA_FAILED, viewId => {
      if (me.state.viewId === viewId) {
        me.setState({
          dataLoadingStatus: NetworkDataLoadingStatus.FAILED,
        });
      }
    }).subscribe(NetworkEvents.LOADING_SUB_VIEW, viewId => {
      if (me.state.viewId === viewId) {
        me.setState({
          subViewDataLoadingStatus: NetworkSubViewDataLoadingStatus.PROCESSING,
        });
      }
    }).subscribe(NetworkEvents.LOADING_SUB_VIEW_SUCCESS, viewId => {
      if (me.state.viewId === viewId) {
        me.reloadSubView = false;
        me.setState({
          subViewDataLoadingStatus: NetworkSubViewDataLoadingStatus.SUCCESS,
        });
      }
    }).subscribe(NetworkEvents.LOADING_SUB_VIEW_FAILED, viewId => {
      if (me.state.viewId === viewId) {
        me.setState({
          subViewDataLoadingStatus: NetworkSubViewDataLoadingStatus.FAILED,
        });
      }
    }).subscribe(NodeEvents.EXPLORING, ({nodeId, node, sender}) => {
      // 增加判断，是向导发出的请求还是本页面自己发出的请求
      if (sender !== me.senderId) return;
      if (node && nodeId === me.props.match.params['selectedNodeId']) {
        const subViewInfo = me.viewDataProvider.getSubViewInfo(me.state.subViewId);
        me.setState({
          subViewName: subViewInfo.name,
          subViewTags: subViewInfo.tags,
          subViewVersion: subViewInfo.version,
          nodeExploringStatus: NodeExploringStatus.PROCESSING,
          specifiedResource: node,
        });
      }
    }).subscribe(NodeEvents.EXPLORING_SUCCESS, ({nodeId, node, nodes, edges, sources, sender}) => {
      // 增加判断，是向导发出的请求还是本页面自己发出的请求
      if (sender !== me.senderId) return;
      if (me.state.specifiedResource && node && node.id === me.state.specifiedResource.id &&
        nodeId === me.props.match.params['selectedNodeId']) {
        me.onNodeExplorationResultLoaded({node, nodes, edges, sources});
      }
    }).subscribe(NodeEvents.EXPLORING_FAILED, ({nodeId, node, sender}) => {
      // 增加判断，是向导发出的请求还是本页面自己发出的请求
      if (sender !== me.senderId) return;
      if (me.state.specifiedResource && node && node.id === me.state.specifiedResource.id &&
        nodeId === me.props.match.params['selectedNodeId']) {

        me.explorationResult = {
          nodes: new DataSet(),
          edges: new DataSet(),
        };
        me.setState({
          nodeExploringStatus: NodeExploringStatus.FAILED,
          specifiedResource: node,
          emptyResult: true,
        });
      }
    }).subscribe(NetworkEvents.SUB_VIEW_EXPLORING, ({subViewId, sender}) => {
      // 增加判断，是向导发出的请求还是本页面自己发出的请求
      if (sender !== me.senderId) return;
      const subViewInfo = me.viewDataProvider.getSubViewInfo(subViewId);
      if (subViewInfo && me.state.subViewId === subViewId) {
        me.setState({
          subViewName: subViewInfo.name,
          subViewTags: subViewInfo.tags,
          subViewVersion: subViewInfo.version,
          subViewExploringStatus: NetworkSubViewExploringStatus.PROCESSING,
          nodeExploringStatus: NodeExploringStatus.IDLE,
          specifiedResource: undefined,
          nodeTags: [],
        });
      }
    }).subscribe(NetworkEvents.SUB_VIEW_EXPLORING_SUCCESS, (
      {subViewId, nodeId, nodes, edges, sources, version, sender}) => {
      // 增加判断，是向导发出的请求还是本页面自己发出的请求
      if (sender !== me.senderId) return;
      const subViewInfo = me.viewDataProvider.getSubViewInfo(subViewId);
      if (version > me.state.subViewVersion) {
        // 子图有更新
        me.setState({
          subViewExploringStatus: NetworkSubViewExploringStatus.IDLE,
          subViewDataLoadingStatus: NetworkSubViewDataLoadingStatus.IDLE,
          nodeTags: [],
        }, () => {
          // 加载子图信息
          // noinspection JSIgnoredPromiseFromCall
          me.viewDataProvider.loadSubViewData();
        })
      } else if (
        subViewInfo && subViewId === me.state.subViewId && (
          nodeId === me.props.match.params['selectedNodeId'] ||
          'overview' === me.props.match.params['selectedNodeId']
        )
      ) {
        me.onSubViewNodeExplorationResultLoaded({nodes, edges, sources});
      }
    }).subscribe(NetworkEvents.SUB_VIEW_EXPLORING_FAILED, ({subViewId, sender}) => {
      // 增加判断，是向导发出的请求还是本页面自己发出的请求
      if (sender !== me.senderId) return;
      const subViewInfo = me.viewDataProvider.getSubViewInfo(subViewId);
      if (subViewInfo && subViewId === me.state.subViewId) {
        me.explorationResult = {
          nodes: new DataSet(),
          edges: new DataSet(),
        };
        me.setState({
          subViewExploringStatus: NetworkSubViewExploringStatus.FAILED,
          emptyResult: true,
          nodeTags: [],
        });
      }
    });

    // noinspection JSIgnoredPromiseFromCall
    me.viewDataProvider.loadData(me.props.match.params.viewId);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let me = this;

    if (me.state.subViewDataLoadingStatus === NetworkSubViewDataLoadingStatus.SUCCESS &&
      me.state.nodeExploringStatus === NodeExploringStatus.IDLE &&
      me.state.subViewExploringStatus === NetworkSubViewExploringStatus.IDLE) {

      const subViewInfoList = me.viewDataProvider.getSubViewInfo();
      // 判断是否通过URL传入节点ID
      if (me.props.match.params.selectedNodeId) {
        if (me.props.match.params.selectedNodeId === 'overview') {
          // 总览图，将viewId作为subViewId
          let subViewInfo = me.viewDataProvider.getSubViewInfo(me.viewDataProvider.viewId);
          if (!subViewInfo || (subViewInfo.nodeIds.length <= 0 && subViewInfo.resourceIds.length <= 0)) {
            // 图谱中没有节点
            notification.error({
              message: '请先在图谱中加入节点。',
              duration: 0,
            });
          } else {
            me.explorationAlg = 0;
            me.setState({
              selectedNodeId: me.props.match.params.selectedNodeId,
              withRelation: me.props.match.params.resultType === 'network',
              subViewId: subViewInfo.id,
              subViewExploringStatus: NetworkSubViewExploringStatus.PROCESSING,
              nodeTags: [],
              sourceNodeInfo: undefined,
            }, me.doExploreByView);
          }
        } else {
          // 通过节点ID自动查找对应子图
          /**
           * @type {SubViewInfo[]}
           */
          const subViewInfoList = me.viewDataProvider.getSubViewInfo();
          /**
           * @type {null|SubViewInfo}
           */
          let subViewInfo = null;
          subViewInfoList.forEach(info => {
            if (
              info.nodeIds.includes(me.props.match.params.selectedNodeId) ||
              info.resourceIds.includes(me.props.match.params.selectedNodeId)
            ) {
              subViewInfo = info;
            }
          });
          if (!subViewInfo && subViewInfoList.length > 0) {
            // 节点不存在
            notification.error({
              message: '没有找到指定的节点信息。',
              duration: 0,
            });
          } else if (!subViewInfo) {
            // 子图ID无效且无有效子图可以选择，跳转至整体图谱页面
            notification.error({
              message: '没有找到指定的节点信息。',
              duration: 0,
            });
          } else {
            // 子图ID有效
            if (me.props.match.params['resourceId'] === 'resources') {
              // me.props.match.params['resourceId'] === 'resources' 查看子图内所有资源
              // 获取已加入图谱的资源时，判断是否需要刷新子图信息，
              // 当子图所属资源有修改（新增或删除）时，应当重新加载，再展示资源列表
              // 否则直接绘图
              if (me.reloadSubView) {
                // noinspection JSIgnoredPromiseFromCall
                me.viewDataProvider.loadSubViewData();
                // return;
              } else {
                let nodes = me.viewDataProvider.getSubViewInfo(subViewInfo.id).resources.map(node => {
                  getAndSetChinaADC(node);
                  getAndSetTimestamp(node);
                  return node;
                });
                me.explorationResult = {
                  nodes: new DataSet(nodes),
                  edges: new DataSet(me.viewDataProvider.getData().data.edges.get()),
                };
                me.setState({
                  selectedNodeId: me.props.match.params.selectedNodeId,
                  withRelation: me.props.match.params.resultType === 'network',
                  subViewId: subViewInfo.id,
                  subViewName: subViewInfo.name,
                  subViewTags: subViewInfo.tags,
                  subViewVersion: subViewInfo.version,
                  subViewExploringStatus: NetworkSubViewExploringStatus.IDLE,
                  nodeExploringStatus: NodeExploringStatus.SUCCESS,
                  nodeTags: [],
                  specifiedResource: {
                    id: 'resources',
                    type: 0,
                    fname: '已关联的资源',
                    tags: subViewInfo.tags,
                  },
                  emptyResult: false,
                });
              }
            } else if (me.props.match.params['resourceId'] &&
              parseInt(me.props.match.params['resourceType'], 10) > 0) {

              // 单一节点探索
              me.explorationAlg = 0;
              me.setState({
                selectedNodeId: me.props.match.params.selectedNodeId,
                withRelation: me.props.match.params.resultType === 'network',
                subViewId: subViewInfo.id,
                nodeExploringStatus: NodeExploringStatus.PROCESSING,
              }, () => {
                // 尝试获取资源详情
                NodeDataProvider.loadInsightDetailInfo(me.props.match.params['resourceId'],
                  parseInt(me.props.match.params['resourceType'], 10)).then(data => {

                  me.setState({sourceNodeInfo: new Node(data), specifiedResource: new Node(data)},
                    me.showWizard);
                }).catch(() => {
                  me.explorationResult = {
                    nodes: new DataSet(),
                    edges: new DataSet(),
                  };
                  me.setState({
                    nodeExploringStatus: NodeExploringStatus.FAILED,
                    specifiedResource: me.state.specifiedResource || new Node({
                      id: me.props.match.params['resourceId'],
                      type: me.props.match.params['resourceType'],
                      fname: '加载中...',
                      tag: '加载中...',
                    }),
                    emptyResult: true,
                  });
                });
              });
            } else {
              // 子图中节点探索
              me.explorationAlg = 0;
              me.setState({
                selectedNodeId: me.props.match.params.selectedNodeId,
                withRelation: me.props.match.params.resultType === 'network',
                subViewId: subViewInfo.id,
                subViewExploringStatus: NetworkSubViewExploringStatus.PROCESSING,
                nodeTags: [],
                sourceNodeInfo: me.viewDataProvider.getNode(me.props.match.params.selectedNodeId),
              }, me.showWizard);
            }
          }
        }
      } else if (subViewInfoList.length > 0) {
        // 提示用户选择子图，此操作由Header部分完成
        notification.error({
          message: '请点击图谱菜单进入本界面。',
          duration: 0,
        });
      }
    }
  }

  componentWillUnmount() {
    this.viewDataProvider.unSubscribe(this);
  }

  render() {
    let me = this;

    let status = 'idle';
    if (
      me.state.dataLoadingStatus === NetworkDataLoadingStatus.PROCESSING ||
      me.state.subViewDataLoadingStatus === NetworkSubViewDataLoadingStatus.PROCESSING ||
      me.state.subViewExploringStatus === NetworkSubViewExploringStatus.PROCESSING ||
      me.state.nodeExploringStatus === NodeExploringStatus.PROCESSING
    ) {
      status = 'processing';
    } else if (
      me.state.dataLoadingStatus === NetworkDataLoadingStatus.FAILED ||
      me.state.subViewDataLoadingStatus === NetworkSubViewDataLoadingStatus.FAILED ||
      me.state.subViewExploringStatus === NetworkSubViewExploringStatus.FAILED ||
      me.state.nodeExploringStatus === NodeExploringStatus.FAILED
    ) {
      status = 'failed';
    } else if (
      me.state.dataLoadingStatus === NetworkDataLoadingStatus.SUCCESS &&
      me.state.subViewDataLoadingStatus === NetworkSubViewDataLoadingStatus.SUCCESS &&
      (
        me.state.subViewExploringStatus === NetworkSubViewExploringStatus.SUCCESS ||
        me.state.nodeExploringStatus === NodeExploringStatus.SUCCESS
      )
    ) {
      status = 'success';
    }

    const logTree = me.arrToTree(me.state.nodeTags);

    return (
      <Layout className={`${style['frame']} dark-theme`}>
        <Loading/>
        <ExplorationViewHeader
          viewId={me.state.viewId}
          viewName={me.state.viewName}
          subViewName={me.state.subViewName}
          subViewTags={me.state.subViewTags}
          filterBus={me.filterAndStatisticsBus}
          networkRef={me.viewDataProvider}
          dataLoadingStatus={me.state.dataLoadingStatus}
          subViewDataLoadingStatus={me.state.subViewDataLoadingStatus}
          history={me.props.history}
          filterDisabled={
            me.state.dataLoadingStatus !== NetworkDataLoadingStatus.SUCCESS ||
            me.state.subViewDataLoadingStatus !== NetworkSubViewDataLoadingStatus.SUCCESS ||
            (status !== 'failed' && status !== 'success')
          }
          searchInputStyle={{display: status === 'success' ? undefined : 'none'}}
        />
        <div className={style['result-filter-frame']}>
          {
            me.props.match.params.selectedNodeId === 'overview' ? null : (
              <ExplorationSearchOptionForm
                networkRef={me.viewDataProvider}
                onSearch={() => {
                  me.explorationAlg = 0;
                  // me.state.specifiedResource ? me.doExploreByIndividualNode() : me.doExploreBySubViewNode();
                  // 选项卡切换后还原至计算模式1，此时直接从已加载结果中取数据
                  me.loadDataFromWizard(me.getExactMatchOptions().types[0]);
                }}
                sender={me.senderId}
                resourceTypes={resourceTypes}
                getExactMatchOptions={me.getExactMatchOptions}
                setExactMatchOptions={me.setExactMatchOptions}
                optionsUpdated={me.state.optionsUpdated}
                processing={
                  me.state.specifiedResource ?
                    me.state.nodeExploringStatus === NodeExploringStatus.PROCESSING :
                    me.state.subViewExploringStatus === NetworkSubViewExploringStatus.PROCESSING
                }
                disabled={
                  (me.state.specifiedResource && me.state.specifiedResource.id === 'resources')  ||
                  (
                    me.state.dataLoadingStatus !== NetworkDataLoadingStatus.SUCCESS ||
                    me.state.subViewDataLoadingStatus !== NetworkSubViewDataLoadingStatus.SUCCESS ||
                    (status !== 'failed' && status !== 'success')
                  )
                }
              />
            )
          }
          <StepInfo step={2} style={{top: '0.7rem'}}/>
        </div>
        <TimestampAndLocationStatistics
          style={{display: status === 'success' ? undefined : 'none'}}
          bus={me.filterAndStatisticsBus}
        />
        {
          me.state.showAlgSwitch ? (
            <div
              className={style['switch-frame']}
              style={{display: status === 'success' ? undefined : 'none'}}
            >
              <AlgSwitch
                alg={me.explorationAlg}
                onChange={alg => {
                  // 判断是节点还是子图的计算模式切换
                  if (me.explorationAlg !== alg) {
                    me.explorationAlg = alg;
                    // 如果此时为计算模式1，直接从已加载结果中取数据
                    if (me.explorationAlg === 0) {
                      me.loadDataFromWizard(me.getExactMatchOptions().types[0]);
                    } else {
                      me.state.specifiedResource ? me.doExploreByIndividualNode() : me.doExploreBySubViewNode()
                    }
                  }
                }}
              />
            </div>
          ) : null
        }
        <Layout.Content className={style['content-result-container']}>
          <div className={`${style['content-result-cover']}`}>
            <ExplorationEmptyResult
              status={status}
              bus={me.filterAndStatisticsBus}
              resourceId={me.props.match.params['resourceId']}
            />
            <div style={{display: status === 'success' ? undefined : 'none', height: '100%', width: '100%'}}>
              <div className={style['sub-view-info-frame']} style={{display: status === 'success' ? undefined : 'none'}}>
                <h3>{me.state.viewName}</h3>
                {/*<h3>【<span>{me.state.viewName}</span>】</h3>*/}
                <hr />
                <div className={style['log-tree']}>
                  {
                    <Tree
                      defaultExpandAll={true}
                      showIcon={false}
                    >
                      {me.renderTreeNodes(logTree)}
                    </Tree>
                  }
                </div>
              </div>
              <Switch>
                <Route path={`${ROOT_PATH}/list/:nodeId?/:resourceType?/:resourceId?`} component={(/*{match}*/) => {
                  if (me.filterAndStatisticsBus.mustHaveChinaADC || me.filterAndStatisticsBus.mustHaveTimestamp) {
                    requestAnimationFrame(() => {
                      me.filterAndStatisticsBus.mustHaveChinaADC = false;
                      me.filterAndStatisticsBus.mustHaveTimestamp = false;
                      if (status === 'success') {
                        me.filterAndStatisticsBus.lastUpdate = Math.random();
                      }
                      me.forceUpdate();
                    });
                    return null;
                  } else {
                    return (
                      <ResultList
                        readonly={me.state.readonly}
                        onExplore={me.onExplore}
                        onVote={me.onVote}
                        onAddToGraph={me.onAddToGraph}
                        onRemoveFromGraph={me.onRemoveFromGraph}
                        bus={me.filterAndStatisticsBus}
                      />
                    );
                  }
                }}/>
                <Route key={'map-route'} path={`${ROOT_PATH}/map/:nodeId?/:resourceType?/:resourceId?`} component={(/*{match}*/) => {
                  // 对于没有地理位置的信息，展示到位置区域中，因此此处不再过滤
                  // if (!me.filterAndStatisticsBus.mustHaveChinaADC || me.filterAndStatisticsBus.mustHaveTimestamp) {
                  if (me.filterAndStatisticsBus.mustHaveChinaADC || me.filterAndStatisticsBus.mustHaveTimestamp) {
                    requestAnimationFrame(() => {
                      me.filterAndStatisticsBus.mustHaveChinaADC = false;
                      me.filterAndStatisticsBus.mustHaveTimestamp = false;
                      if (status === 'success') {
                        me.filterAndStatisticsBus.lastUpdate = Math.random();
                      }
                      me.forceUpdate();
                    });
                    return null;
                  } else {
                    return (
                      <Map
                        key={'mat-view'}
                        readonly={me.state.readonly}
                        className={style['map']}
                        onExplore={me.onExplore}
                        onVote={me.onVote}
                        onAddToGraph={me.onAddToGraph}
                        onRemoveFromGraph={me.onRemoveFromGraph}
                        bus={me.filterAndStatisticsBus}
                      />
                    );
                  }
                }}/>
                <Route path={`${ROOT_PATH}/timeline/:nodeId?/:resourceType?/:resourceId?`} component={(/*{match}*/) => {
                  // 对于没有时间的信息，默认挂载到当天，因此此处不再过滤
                  // if (!me.filterAndStatisticsBus.mustHaveTimestamp || me.filterAndStatisticsBus.mustHaveChinaADC) {
                  if (me.filterAndStatisticsBus.mustHaveTimestamp || me.filterAndStatisticsBus.mustHaveChinaADC) {
                    requestAnimationFrame(() => {
                      me.filterAndStatisticsBus.mustHaveChinaADC = false;
                      // me.filterAndStatisticsBus.mustHaveTimestamp = true;
                      me.filterAndStatisticsBus.mustHaveTimestamp = false;
                      if (status === 'success') {
                        me.filterAndStatisticsBus.lastUpdate = Math.random();
                      }
                      me.forceUpdate();
                    });
                    return null;
                  } else {
                    return (
                      <Timeline
                        readonly={me.state.readonly}
                        className={style['timeline']}
                        onExplore={me.onExplore}
                        onVote={me.onVote}
                        onAddToGraph={me.onAddToGraph}
                        onRemoveFromGraph={me.onRemoveFromGraph}
                        bus={me.filterAndStatisticsBus}
                      />
                    );
                  }
                }}/>
                <Route path={`${ROOT_PATH}/network/:nodeId?/:resourceType?/:resourceId?`} component={(/*{match}*/) => {
                  if (me.filterAndStatisticsBus.mustHaveChinaADC || me.filterAndStatisticsBus.mustHaveTimestamp) {
                    requestAnimationFrame(() => {
                      me.filterAndStatisticsBus.mustHaveChinaADC = false;
                      me.filterAndStatisticsBus.mustHaveTimestamp = false;
                      if (status === 'success') {
                        me.filterAndStatisticsBus.lastUpdate = Math.random();
                      }
                      me.forceUpdate();
                    });
                    return null;
                  } else {
                    return (
                      <Network
                        edges={me.explorationResult.edges.get()}
                        readonly={me.state.readonly}
                        className={style['network']}
                        onExplore={me.onExplore}
                        onVote={me.onVote}
                        onAddToGraph={me.onAddToGraph}
                        onRemoveFromGraph={me.onRemoveFromGraph}
                        bus={me.filterAndStatisticsBus}
                      />
                    );
                  }
                }}/>
                <Route render={() => (
                  <Redirect to={`${ROOT_PATH.replace(':viewId', me.props.match.params.viewId)}/list`}/>
                )}/>
              </Switch>
            </div>
            <div className={style['failed']} style={{display: status === 'failed' ? undefined : 'none'}}>
              <Icon name="icon-server_busy" type={IconTypes.ICON_FONT}/><br/>
              <span>系统计算繁忙，请<a onClick={me.onTryAgain}>&lt;稍后再试&gt;</a>。</span>
            </div>
          </div>
        </Layout.Content>
        <WizardModal
          visible={me.state.showWizard}
          onClose={me.onWizardFinished}
          doExplore={me.doExploreFromWizard}
          doLoadMatchedTags={nodeId => me.viewDataProvider.loadMatchedTags(nodeId)}
          doLoadMatchedResources={nodeId => me.viewDataProvider.loadMatchedResources(nodeId)}
          resourceTypes={resourceTypes}
          currentNodeInfo={me.state.sourceNodeInfo}
        />
        <Copyright/>
      </Layout>
    );
  }
}

export default ExplorationView;