import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Table, message } from 'antd';

import PB, { SimplePB } from '@/libs/simplePB';

import Icon from '@/components/common/common.icon';

import style from '@/style/common/common.account.badgeList.less';
import { IconTypes } from '@/constants/common';
import UserDataProvider from '@/components/common/dataProvider/common.dataProvider.user';
import { getViewBadgeList } from '@/components/common/common.functions';
import moment from 'moment';
import TimeDisplayField from '@/components/common/common.timeDisplayField';

const COLUMNS = (me) => [
	{
		title: '奖名',
		dataIndex: 'text',
		key: 'text',
		width: '10rem',
	},
	{
		title: '礼物',
		dataIndex: 'type',
		key: 'type',
		className: style['member-table-column-amount'],
		align: 'center',
		render: (value) => (
			<div>
				<Icon
					name={getViewBadgeList()[value].icon}
					type={IconTypes.ICON_FONT}
					style={{fontSize: 1.2 + 0.2 * (value + 1) + 'rem'}}
				/>
			</div>
		),
	},
	{
		title: '赠送人',
		dataIndex: 'byUserNick',
		key: 'byUserNick',
		align: 'center',
		render: (value) => (
			<span>{value || '--'}</span>
		),
	},
	{
		title: '节点',
		dataIndex: 'nodeFname',
		key: 'nodeFname',
		width: '20rem',
	},
	{
		title: '赠送时间',
		dataIndex: 'timestamp',
		key: 'timestamp',
		render: (value) => (
			<TimeDisplayField
				timestamp={moment(value, 'YYYY-MM-DD HH:mm:ss').valueOf()}/>
		),
	},
];

class MemberBadgeListModal extends React.PureComponent {
	state = {
		viewBadgeTypeList: getViewBadgeList(),
		viewBadgeList: undefined,
		typeMaxInfo: undefined, // 获取的最高点赞信息
	};

	// 获取图谱相关的用户奖章
	getViewBadgeList = () => {
		let me = this;
		UserDataProvider.getViewBadge({
			userId: me.props.currentUserInfo.userId,
			limit: null,
		}).then(res => {
			// console.log('AccountBadgeList -> getViewBadge ->  res:', res);
			let typeMax = undefined;
			if (res.length > 0) {
				typeMax = res[0];
				res.forEach(item => {
					if (typeMax.type < item.type) {
						typeMax = item;
					}
				});
			}
			me.setState({
				viewBadgeList: res,
				typeMaxInfo: typeMax,
			});
		}).catch(() => {
			message.error('获取奖章信息失败');
		});
	};

	componentDidMount() {
		let me = this;
		PB.sub(me, 'account', 'badgeList.get', () => {
			// 获取图谱相关的用户奖章
			me.getViewBadgeList();
		});
	}

	componentWillUnmount() {
		PB.remove(this);
	}

	render() {
		let me = this;

		return (
			<Modal
				wrapClassName={style['account-badge-list-modal']}
				width={'58rem'}
				title={'点赞记录'}
				visible={me.props.visible}
				onCancel={() => {
					me.props.onClose();
				}}
				footer={null}
				centered={true}
				bodyStyle={{height: '50rem', padding: '1rem 1.5rem', maxHeight: '50rem'}}
			>
				{
					me.state.typeMaxInfo ? (
						<div className={style['max-good-info']}>
							<div className={style['label']}><b>最高记录：</b></div>
							<Table
								className={`${style['account-badge-max-table']} scrollbar-none`}
								columns={COLUMNS(me)}
								dataSource={[me.state.typeMaxInfo]}
								rowKey={'badgeId'}
								showHeader={false}
								pagination={false}
								bordered={false}
							/>
						</div>
					) : null
				}
				<Table
					className={`${style['account-badge-list-table']} scrollbar-none`}
					columns={COLUMNS(me)}
					dataSource={me.state.viewBadgeList}
					rowKey={'badgeId'}
				/>
			</Modal>
		);
	}
}

MemberBadgeListModal.defaultProps = {
	bus: PB,
};

MemberBadgeListModal.propTypes = {
	bus: PropTypes.instanceOf(SimplePB),
	visible: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	currentUserInfo: PropTypes.object,
};

export default MemberBadgeListModal;