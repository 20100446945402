import _ from 'lodash';
import moment from 'moment';
import {distinctFn} from "@/components/common/view/statistics/node/common.view.statistics.node.logic.common";

export const statisticsByTime = ({nodes, distinct, getConditionFn, timeFormat, sort, limit, cb}) => {
  let statisticsFn = (nodes, cb) => {
    // statistics
    let result = {}, pos = 0, batch, workFn = () => {
      batch = 0;
      while (batch < 500 && pos < nodes.length) {
        let n = nodes[pos];
        let condition = getConditionFn(n, _);
        if (!_.isArray(condition)) {
          condition = [condition];
        }
        let conditionKeys = {};
        condition.forEach(c => {
          let m = moment(c), key = m.format(timeFormat);

          conditionKeys[key] = true;
        });

        Object.keys(conditionKeys).forEach(key => {
          if (!result[key]) {
            result[key] = {
              label: key,
              rate: 0,
              nodes: [],
            };
          }

          result[key].nodes.push(n);
          result[key].rate++;
        });

        batch++;
        pos++;
      }
      if (pos < nodes.length) {
        setTimeout(workFn, 5);
      } else {
        cb(result);
      }
    };
    workFn();
  };

  distinctFn({nodes, distinct, cb: (nodes) => {
    statisticsFn(nodes, (result) => {
      let resultArray;

      // sort
      if (sort) {
        let asc = false;
        if (`${sort}`.toLowerCase() === 'asc') {
          asc = true;
        }
        resultArray = Object.values(result).sort((a, b) => {
          return asc ? a.nodes.length - b.nodes.length : b.nodes.length - a.nodes.length;
        }).map(((value, index) => ({...value, pos: index + 1})));
      } else {
        resultArray = Object.values(result).map(((value, index) => ({...value, pos: index + 1})));
      }

      // limit
      if (limit && limit > 0) {
        resultArray = resultArray.slice(0, parseInt(limit));
      }

      cb(resultArray);
    });
  }});
};