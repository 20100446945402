import React from 'react';
import PropTypes from 'prop-types';
import {Form, Modal} from 'antd';
import TinyColor from 'tinycolor2';

import PB, {SimplePB} from '@/libs/simplePB';
import ClampLines from '@/libs/my-react-clamp-lines';

import {defaultDefine, getNodeDisplayTitle} from '@/constants/vis.defaultDefine.1';
import {IconTypes} from '@/constants/common';

import Icon from '@/components/common/common.icon';

import style from '@/style/components/mainView/edge.less';

const defaultEdgeColorRGBA = TinyColor(defaultDefine.colors.defaultEdge).toRgb();

const EdgeShape = PropTypes.shape({
  from: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    userMarkedInvisible: PropTypes.oneOf([0, 1]), // default: 0
    direction: PropTypes.string,
    smooth: PropTypes.oneOf([0, 1]), // default: 1
    width: PropTypes.oneOf([1, 2, 5, 10]), // default: 1
    color: PropTypes.object,
  }),
});

const lineColors = [{
  text: 'A',
  color: defaultDefine.colors.multipleBackground[2],
}, {
  text: 'B',
  color: defaultDefine.colors.multipleBackground[3],
}, {
  text: 'C',
  color: defaultDefine.colors.multipleBackground[4],
}, {
  text: 'D',
  color: defaultDefine.colors.multipleBackground[5],
}, {
  text: 'E',
  color: defaultDefine.colors.multipleBackground[6],
}, {
  text: 'F',
  color: defaultDefine.colors.multipleBackground[7],
}, {
  text: 'G',
  color: defaultDefine.colors.multipleBackground[0],
}, {
  text: 'H',
  color: defaultDefine.colors.multipleBackground[1],
  rgba: defaultEdgeColorRGBA,
  key: TinyColor(defaultEdgeColorRGBA).toRgbString(),
}].map(({text, color, rgba, key}) => {
  let tc = TinyColor(color);

  return {
    text,
    color,
    rgba: rgba || tc.toRgb(),
    key: key || tc.toRgbString(),
  }
});

class EdgeCommonEditModal extends React.Component {
  state = {
    userMarkedInvisible: 0,
    direction: undefined,
    smooth: 1,
    width: 1,
    color: defaultEdgeColorRGBA,
    colorKey: TinyColor(defaultEdgeColorRGBA).toRgbString(),
  };

  onColorChange = ({rgba, key}) => {
    let me = this;

    me.setState({color: rgba, colorKey: key});
  }

  onSave = () => {
    let me = this;

    const meta = {
      ...(me.props.edge.meta || {}),
      ...me.state,
    };
    me.props.bus.emit('network', 'edge.do_edit', {id: me.props.edge.id, meta});
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    let me = this;

    if (me.props.visible && !prevProps.visible) {
      requestAnimationFrame(() => {
        let newState = {
          userMarkedInvisible: 0,
          direction: undefined,
          smooth: 1,
          width: 1,
          color: defaultEdgeColorRGBA,
          colorKey: TinyColor(defaultEdgeColorRGBA).toRgbString(),
        };

        if (me.props.edge && me.props.edge.meta) {
          newState.userMarkedInvisible = (me.props.edge.meta.userMarkedInvisible === 1) ? 1 : 0;
          newState.direction = me.props.edge.meta.direction || 'none';
          newState.smooth = (me.props.edge.meta.smooth === 0) ? 0 : 1;
          newState.width = me.props.edge.meta.width || 1;
          newState.color = me.props.edge.meta.color || defaultEdgeColorRGBA;
          newState.colorKey = TinyColor(newState.color).toRgbString();
        }

        me.setState(newState);
      });
    }
  }

  render() {
    let me = this;

    return (
      <Modal
        width={'35rem'}
        visible={me.props.visible}
        closable={!me.props.processing}
        title={'编辑节点的连接'}
        okText={'确定'}
        cancelText={'取消'}
        centered={true}
        maskClosable={false}
        onOk={me.onSave}
        onCancel={me.props.doClose}
        okButtonProps={{loading: me.props.processing}}
        cancelButtonProps={{disabled: me.props.processing}}
        destroyOnClose={true}
      >
        {
          me.props.visible ? (
            <React.Fragment>
              <div style={{margin: '-20px 0 0', paddingBottom: '4px'}} className={style['section']}>
                <div>
                  <div className="ant-row ant-form-item" style={{marginBottom: 0}}>
                    <div
                      className="ant-col ant-form-item-control"
                      style={{width: '1rem', float: 'left', top: '19px', borderTop: '1px solid', borderLeft: '1px solid'}}
                    >
                      &nbsp;
                    </div>
                    <div className="ant-col ant-form-item-label ant-form-item-label-left" style={{width: '3rem', float: 'left', marginLeft: '0.5rem'}}>
                      <label>起点</label>
                    </div>
                    <div className="ant-col ant-form-item-control-wrapper" style={{width: 'calc(100% - 4.5rem)', float: 'left'}}>
                      <div className="ant-form-item-control">
                        <span className="ant-form-item-children">
                          <span className="ant-form-text" style={{lineHeight: '1.4rem', paddingTop: 'calc(20px - 0.7rem)'}}>
                            <ClampLines
                              id={`txt-${me.props.fromNode.id}`}
                              text={getNodeDisplayTitle(me.props.fromNode)}
                              lines={1}
                              useLinkButtons={true}
                              moreText={''}
                              lessText={''}
                              renderFn={(text, extraElement) => (<span>{text}{extraElement ? extraElement : null}</span>)}
                            />
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="ant-row ant-form-item" style={{marginBottom: 0}}>
                    <div
                      className="ant-col ant-form-item-control"
                      style={{width: '1rem', float: 'left', top: '-19px', borderBottom: '1px solid', borderLeft: '1px solid'}}
                    >
                      &nbsp;
                    </div>
                    <div className="ant-col ant-form-item-label ant-form-item-label-left" style={{width: '3rem', float: 'left', marginLeft: '0.5rem'}}>
                      <label>终点</label>
                    </div>
                    <div className="ant-col ant-form-item-control-wrapper" style={{width: 'calc(100% - 4.5rem)', float: 'left'}}>
                      <div className="ant-form-item-control">
                        <span className="ant-form-item-children">
                          <span className="ant-form-text" style={{lineHeight: '1.4rem', paddingTop: 'calc(20px - 0.7rem)'}}>
                            <ClampLines
                              id={`txt-${me.props.toNode.id}`}
                              text={getNodeDisplayTitle(me.props.toNode)}
                              lines={1}
                              useLinkButtons={true}
                              moreText={''}
                              lessText={''}
                              renderFn={(text, extraElement) => (<span>{text}{extraElement ? extraElement : null}</span>)}
                            />
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{display: 'flex', padding: '5px 0 0', justifyContent: 'space-between'}}
                className={style['section']}
              >
                <div>
                  <div className={`ant-col ant-form-item-label ${style['no-colon-label']}`} style={{padding: '0 1.5rem'}}>
                    <label>箭头方向</label>
                  </div>
                  <div className={style['svg-list']}>
                    <ul>
                      <li
                        className={me.state.direction === 'to' ? style['current'] : ''}
                        onClick={() => me.setState({direction: 'to'})}
                      >
                        <svg className="icon" viewBox="0 0 1280 256" version="1.1" xmlns="http://www.w3.org/2000/svg" width="5em" height="1em" fill={'currentColor'}>
                          <path d="M1024 230l230-102-230-102 0 80-998 0 0 44 998 0z" />
                        </svg>
                      </li>
                      <li
                        className={me.state.direction === 'from' ? style['current'] : ''}
                        onClick={() => me.setState({direction: 'from'})}
                      >
                        <svg className="icon" viewBox="0 0 1280 256" version="1.1" xmlns="http://www.w3.org/2000/svg" width="5em" height="1em" fill={'currentColor'}>
                          <path d="M256 26l-230 102 230 102 0-80 998 0 0 -44-998 0z" />
                        </svg>
                      </li>
                      <li
                        className={me.state.direction === 'none' ? style['current'] : ''}
                        onClick={() => me.setState({direction: 'none'})}
                      >
                        <svg className="icon" viewBox="0 0 1280 256" version="1.1" xmlns="http://www.w3.org/2000/svg" width="5em" height="1em" fill={'currentColor'}>
                          <path d="M26 106l0 44 1228 0 0 -44-1228 0z" />
                        </svg>
                      </li>
                      <li
                        className={me.state.direction === 'both' ? style['current'] : ''}
                        onClick={() => me.setState({direction: 'both'})}
                      >
                        <svg className="icon" viewBox="0 0 1280 256" version="1.1" xmlns="http://www.w3.org/2000/svg" width="5em" height="1em" fill={'currentColor'}>
                          <path d="M256 26l-230 102 230 102 0-80 768 0 0 80 230-102-230-102 0 80-768 0z" />
                        </svg>
                      </li>
                    </ul>
                  </div>
                </div>
                <div>
                  <div
                    className={`ant-col ant-form-item-label ${style['no-colon-label']}`}
                    style={{padding: '0 1.5rem', width:' 100%', textAlign: 'center'}}
                  >
                    <label>线条颜色</label>
                  </div>
                  <div className={style['color-list']}>
                    <ul>
                      {lineColors.map((cfg, idx) => (
                        <li key={`color-${idx}`} onClick={() => me.onColorChange(cfg)}>
                          <div
                            className={`${me.state.colorKey === cfg.key ? style['current'] : ''}`}
                            style={{backgroundColor: cfg.color}}
                          >
                            {me.state.colorKey === cfg.key ? (<Icon name={'check-circle'} theme={'filled'} />) : (<span>&nbsp;</span>)}
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div>
                  <div className={`ant-col ant-form-item-label ${style['no-colon-label']}`} style={{padding: '0 1.5rem'}}>
                    <label>线条宽度</label>
                  </div>
                  <div className={style['svg-list']}>
                    <ul>
                      <li
                        className={me.state.width === 1 ? style['current'] : ''}
                        onClick={() => me.setState({width: 1})}
                      >
                        <svg className="icon" viewBox="0 0 1280 256" version="1.1" xmlns="http://www.w3.org/2000/svg" width="5em" height="1em" fill={'currentColor'}>
                          <path d="M26 122l0 12 1228 0 0 -12-1228 0z" />
                        </svg>
                      </li>
                      <li
                        className={me.state.width === 2 ? style['current'] : ''}
                        onClick={() => me.setState({width: 2})}
                      >
                        <svg className="icon" viewBox="0 0 1280 256" version="1.1" xmlns="http://www.w3.org/2000/svg" width="5em" height="1em" fill={'currentColor'}>
                          <path d="M26 116l0 24 1228 0 0 -24-1228 0z" />
                        </svg>
                      </li>
                      <li
                        className={me.state.width === 5 ? style['current'] : ''}
                        onClick={() => me.setState({width: 5})}
                      >
                        <svg className="icon" viewBox="0 0 1280 256" version="1.1" xmlns="http://www.w3.org/2000/svg" width="5em" height="1em" fill={'currentColor'}>
                          <path d="M26 98l0 60 1228 0 0 -60-1228 0z" />
                        </svg>
                      </li>
                      <li
                        className={me.state.width === 10 ? style['current'] : ''}
                        onClick={() => me.setState({width: 10})}
                      >
                        <svg className="icon" viewBox="0 0 1280 256" version="1.1" xmlns="http://www.w3.org/2000/svg" width="5em" height="1em" fill={'currentColor'}>
                          <path d="M26 68l0 120 1228 0 0 -120-1228 0z" />
                        </svg>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div style={{display: 'flex', padding: '5px 1.5rem 0', marginBottom: '-19px'}}>
                <div style={{flex: 1, display: 'flex'}} className={style['option-switch']}>
                  <div className={`ant-col ant-form-item-label`}>
                    <label>线条可见</label>
                  </div>
                  <div className="ant-col ant-form-item-control-wrapper">
                    <div className="ant-form-item-control" style={{height: '40px', fontSize: '1.5rem'}}>
                        <span className="ant-form-item-children">
                          <span
                            style={{margin: '0 1rem 0 0.6rem', cursor: 'pointer'}}
                            className={me.state.userMarkedInvisible === 0 ? style['current'] : ''}
                            onClick={() => me.setState({userMarkedInvisible: 0})}
                          >
                            <Icon name={'eye'} />
                          </span>
                          <span
                            style={{cursor: 'pointer'}}
                            className={me.state.userMarkedInvisible === 1 ? style['current'] : ''}
                            onClick={() => me.setState({userMarkedInvisible: 1})}
                          >
                            <Icon name={'eye-invisible'} />
                          </span>
                        </span>
                    </div>
                  </div>
                </div>
                <div style={{flex: 1, display: 'flex'}} className={style['option-switch']}>
                  <div className={`ant-col ant-form-item-label`}>
                    <label>是否弯曲</label>
                  </div>
                  <div className="ant-col ant-form-item-control-wrapper">
                    <div className="ant-form-item-control" style={{height: '40px', fontSize: '1.5rem'}}>
                        <span className="ant-form-item-children">
                          <span
                            style={{margin: '0 1rem 0 0.6rem', cursor: 'pointer'}}
                            className={me.state.smooth === 1 ? style['current'] : ''}
                            onClick={() => me.setState({smooth: 1})}
                          >
                            <Icon name={'icon-smooth-line'} type={IconTypes.ICON_FONT}/>
                          </span>
                          <span
                            style={{cursor: 'pointer'}}
                            className={me.state.smooth === 0 ? style['current'] : ''}
                            onClick={() => me.setState({smooth: 0})}
                          >
                            <Icon name={'icon-straight-line'} type={IconTypes.ICON_FONT}/>
                          </span>
                        </span>
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>
          ) : null
        }
      </Modal>
    );
  }
}

EdgeCommonEditModal.defaultProps = {
  bus: PB,
  visible: false,
  processing: false,
};

EdgeCommonEditModal.propTypes = {
  edge: EdgeShape,
  fromNode: PropTypes.object,
  toNode: PropTypes.object,
  bus: PropTypes.instanceOf(SimplePB),
  visible: PropTypes.bool,
  processing: PropTypes.bool,
  doClose: PropTypes.func.isRequired,
};

const WrappedEdgeCommonEditModal = Form.create()(EdgeCommonEditModal);

WrappedEdgeCommonEditModal.defaultProps = {
  bus: PB,
  visible: false,
  processing: false,
};

WrappedEdgeCommonEditModal.propTypes = {
  edge: EdgeShape,
  fromNode: PropTypes.object,
  toNode: PropTypes.object,
  bus: PropTypes.instanceOf(SimplePB),
  visible: PropTypes.bool,
  processing: PropTypes.bool,
  doClose: PropTypes.func.isRequired,
};

export default WrappedEdgeCommonEditModal;