import {
  VALIDATE_STORE,

  INVALIDATE_VIEW_DATA_CACHE,
  INVALIDATE_ALL_VIEW_DATA_CACHE,

  DO_LOAD_VIEW_INFO,
  LOAD_VIEW_INFO_SUCCESS,
  LOAD_VIEW_INFO_FAILED,

  DO_LOAD_VIEW_STRUCTURE,
  LOAD_VIEW_STRUCTURE_SUCCESS,
  LOAD_VIEW_STRUCTURE_FAILED,

  DO_LOAD_VIEW_NODES,
  LOAD_VIEW_NODES_SUCCESS,
  LOAD_VIEW_NODES_FAILED,

  DO_LOAD_VIEW_RELATION,
  LOAD_VIEW_RELATION_SUCCESS,
  LOAD_VIEW_RELATION_FAILED,
} from './actionTypes';

export const validateStoreAction = () => ({type: VALIDATE_STORE});

export const invalidateViewDataCacheAction = viewId => ({type: INVALIDATE_VIEW_DATA_CACHE, payload: {viewId}});

export const invalidateAllViewDataCacheAction = () => ({type: INVALIDATE_ALL_VIEW_DATA_CACHE});

export const doLoadViewInfoAction = viewId => ({type: DO_LOAD_VIEW_INFO, payload: {viewId}});

export const loadViewInfoSuccessAction = (viewId, viewInfo) => ({type: LOAD_VIEW_INFO_SUCCESS, payload: {viewId, viewInfo}});

export const loadViewInfoFailedAction = viewId => ({type: LOAD_VIEW_INFO_FAILED, payload: {viewId}});

export const doLoadViewStructureAction = viewId => ({type: DO_LOAD_VIEW_STRUCTURE, payload: {viewId}});

export const loadViewStructureSuccessAction = (viewId, viewStructure) => ({type: LOAD_VIEW_STRUCTURE_SUCCESS, payload: {viewId, viewStructure}});

export const loadViewStructureFailedAction = viewId => ({type: LOAD_VIEW_STRUCTURE_FAILED, payload: {viewId}});

export const doLoadViewNodesAction = viewId => ({type: DO_LOAD_VIEW_NODES, payload: {viewId}});

export const loadViewNodesSuccessAction = (viewId, viewNodes) => ({type: LOAD_VIEW_NODES_SUCCESS, payload: {viewId, viewNodes}});

export const loadViewNodesFailedAction = viewId => ({type: LOAD_VIEW_NODES_FAILED, payload: {viewId}});

export const doLoadViewRelationAction = viewId => ({type: DO_LOAD_VIEW_RELATION, payload: {viewId}});

export const loadViewRelationSuccessAction = (viewId, viewRelation) => ({type: LOAD_VIEW_RELATION_SUCCESS, payload: {viewId, viewRelation}});

export const loadViewRelationFailedAction = viewId => ({type: LOAD_VIEW_RELATION_FAILED, payload: {viewId}});