/*
 * @Author: Carr.s
 * @Date: 2022-04-26 18:24:10
 * @LastEditTime: 2022-04-28 17:28:41
 * @LastEditors: Carr.s
 * @Description:产业链洞察 c
 * @Copyright by joinmap
 */
import React from "react";
import ViewInsightChartTpl from "@/components/insightView/template/insight.chart.tpl";
import { connect } from "react-redux";
import styles from "@/style/components/insightView/insight.less";
import echarts from "echarts/lib/echarts";

class ViewIndustrialChainAInsight extends ViewInsightChartTpl {
  //===============================================================
  // begin of overwrite
  // 使用默认的方式就不用重写

  // ------------------------------------------------------------
  // 全局配置
  // ------------------------------------------------------------
  // 提示信息中提示添加哪种类型的节点
  nodeTitleInTip = "企业";
  // 显示底部按钮
  showToolbar = false;
  // 洞察名称
  viewerTitle = "产业链洞察 关键环节雷达图";
  _viewerTitleRender = () => {
    return "";
  };

  // ------------------------------------------------------------
  // 层级分析对象的配置
  // ------------------------------------------------------------
  // 第一层 level1 tag
  column1_showType = "tree"; // 第一列的展现形式，list=显示列，tree=显示树
  // column1_DataField = "data"; // 取值字段
  // column1_TotalField = "dataTotal"; // 计算总数(与返回数量不一定一致)字段
  column1_treeFields = []; // 用树状目录显示数据时各层级的 children 字段名数据
  column1_Title = "产业链点"; // 显示名称
  column1_Unit = "个"; // 显示的文字单位
  column1_ValueRender = (column1_data, idx, nodePath = undefined) => {
    // 右侧数值渲染，不输出则空白
    // 必须加上 className={styles["line-value"]}
    let nodePathArr = [];
    if (
      !(
        nodePath === null ||
        nodePath === undefined ||
        nodePath === false ||
        nodePath === ""
      )
    ) {
      nodePathArr = ("" + nodePath).split("-");
    }
    if (nodePathArr.length > 1) {
      return (
        <div className={styles["line-value"]}>
          {column1_data["value"]}
          {column1_data["valueUnit"]}
        </div>
      );
    } else {
      return "";
    }
  };
  /*
  column1_IconRender = (level1_data, idx,nodePath=undefined) => {
    // 左侧图表渲染，不输出则空白
    // 必须加上 className={styles["line-icon"]}
  };
  */
  column1_HoverTitle = (level1_data, idx, nodePath = undefined) => {
    // 列表鼠标悬浮提示文字
  };
  xcolumn1_subTitle = () => {
    // 列表顶部副标题文字
    const { _column1Len } = this.state;
    return (
      <>
        已显示一级核心词汇{_column1Len}
        {this.column1_Unit}
      </>
    );
  };

  /**
   * column1 的checkbox 改变后的响应
   */
  column1CheckboxChangeCallback = (nodePath) => {
    this.refreshChartOptions();
    this.setState({
      _refresh: !this.state._refresh,
    });
  };

  /**
   * column1 的check all checkbox 改变后的响应
   */
  column1CheckAllBoxChangeCallback = (checked) => {
    this.refreshChartOptions();
    this.setState({
      _refresh: !this.state._refresh,
    });
  };

  // 第二层 item
  column2_DataField = "cops"; // 取值字段
  column2_TotalField = "value"; // 计算总数(与返回数量不一定一致)字段
  column2_Title = "企业"; // 显示名称
  column2_Unit = "家"; // 显示的文字单位
  column2_ValueRender = (level2_data, idx) => {
    // 右侧数值渲染，不输出则空白
    // 必须加上 className={styles["line-value"]}
    // 不显示
  };
  /*
  column2_IconRender = (level2_data, idx) => {
    // 左侧图表渲染，不输出则空白
    // 必须加上 className={styles["line-icon"]}
  };
  column2_HoverTitle = (level2_data, idx) => {
    // 列表鼠标悬浮提示文字
    return `${this.column2_Title}：${level2_data["name"]}\n点击打开${this.column1_Title}链接`;
  };
  */

  // ------------------------------------------------------------
  // 接口的配置
  // ------------------------------------------------------------
  // 接口 url path
  // api_path = "";
  api_path = "/wth_server_api/lei_da_pic_by_view_id";

  // 返回数据默认值
  defaultLimit = 60;

  /**
   * 【继承组件要重写】
   * api 请求完成后，setState结束后的 callback
   */
  apiLoadedCallback = () => {
    // message.info("数据加载成功");
  };

  // ------------------------------------------------------------
  // 图表的配置
  // ------------------------------------------------------------
  // 图表显示库
  chartType = "echarts";
  chartCompomentStyle = {
    width: "100%",
    height: "100%",
  };
  /**
   * 生成公用的、默认的 option
   * @returns
   */
  initChartOption = () => {
    let _chartOption = {
      colors: this._chartColors,
      title: {
        show: false,
      },
      tooltip: {
        formatter: function (info) {
          // console.log("info:", info);
          let value = 0;
          if (info.data.value.length > info.componentIndex) {
            value = info.data.value[info.componentIndex];
          }
          return [
            `<div class="tooltip-title">链点名称：${echarts.format.encodeHTML(
              info.name
            )}</div>`,
            // "相关企业：" + value + "家<br>",
          ].join("");
        },
      },
      radar: {
        shape: "circle",
        indicator: [
          { name: "标签1", max: 6500 },
          { name: "标签2", max: 16000 },
          { name: "标签3", max: 30000 },
          { name: "标签4", max: 38000 },
          { name: "标签5", max: 52000 },
          { name: "标签6", max: 25000 },
        ],
      },
      series: [
        {
          name: "企业分类",
          type: "radar",
          lineStyle: {
            width: 3,
          },
          itemStyle: {
            normal: {
              // color: "#00C9A3",
              // opacity: 0.9,
            },
          },
          areaStyle: {
            normal: {
              opacity: 0,
            },
          },
          data: [
            {
              value: [4200, 3000, 20000, 35000, 50000, 18000],
              name: "企业标签2",
            },
            {
              value: [5000, 14000, 28000, 26000, 42000, 21000],
              name: "企业标签1",
              lineStyle: {
                width: 10,
                color: "#3BA272",
              },
            },
            {
              value: [2000, 11000, 26000, 28000, 45000, 2000],
              name: "企业标签1",
            },
          ],
        },
      ],
    };
    return _chartOption;
  };

  /**
   * 【继承组件要重写】
   * 生成 chart 的 options
   * @param {string} nodePath 选中的数据下标
   */
  refreshChartOptions = (nodePath = null) => {
    console.log("refreshChartOptions 0 nodePath:", nodePath);
    // checkbox 显示雷达图
    // 点击的雷达图高亮显示
    // 被点击的雷达图不在选中列表里则加上

    // icon的前缀
    const {
      query: { isDev },
    } = this.props;
    let me = this;

    // 生成options
    me._chartOption = this.initChartOption();
    // return;

    console.log(
      "refreshChartOptions _column1CheckedPathList:",
      me.state._column1CheckedPathList
    );

    // 指定 node path 的column1Idx
    let column1Idx = undefined;
    let column1SelectedIdx = [];
    // 判断被点击的 nodePath在不在选中的列表里
    if (
      !(
        nodePath === null ||
        nodePath === undefined ||
        nodePath === false ||
        nodePath === ""
      )
    ) {
      if (me.state._column1CheckedPathList.indexOf("" + nodePath) == -1) {
        // me._column1_onCheckboxChange({ target: { path: "" + nodePath } });
        // return;
      }

      // 判断第一层idx
      let nodePathArr = ("" + nodePath).split("-");
      column1Idx =
        nodePathArr.length > 0 ? parseInt(nodePathArr[0]) : undefined;
    }
    // checkbox 被选中的 node path 的column1Idx
    me.state._column1CheckedPathList.forEach((i) => {
      let nodePathArr = ("" + i).split("-");
      // 取第一层，显示雷达
      let iidx = nodePathArr.length > 0 ? parseInt(nodePathArr[0]) : 0;
      column1SelectedIdx.push(iidx);
    });

    console.log("refreshChartOptions column1SelectedIdx:", column1SelectedIdx);
    console.log("refreshChartOptions column1Idx:", column1Idx);
    column1SelectedIdx = [...new Set(column1SelectedIdx)];
    let seriesData = []; // 雷达维度的数据
    let indicatorObj = {}; // 雷达维度及最大值
    let maxIndicator = 0; // 雷达维度最大值

    // 计算雷达维度
    me._chartData.forEach((i1, idx1) => {
      // 用第一个获取雷达图维度
      i1.children.forEach((i2, idx2) => {
        if (Object.keys(indicatorObj).indexOf(i2.name) > -1) {
          indicatorObj[i2.name] =
            indicatorObj[i2.name] > i2.value ? indicatorObj[i2.name] : i2.value;
        } else {
          indicatorObj[i2.name] = i2.value;
        }
      });
    });

    // 计算雷达数据，及高亮
    me._chartData.forEach((i1, idx1) => {
      if (column1SelectedIdx.indexOf(idx1) > -1) {
        let values = {};
        i1.children.forEach((i2, idx2) => {
          values[i2.name] = i2.value;
        });
        let data = {
          value: values,
          name: i1.name,
        };
        if (idx1 == column1Idx) {
          data.lineStyle = {
            width: 10,
            color: "#FAC858",
          };
          // data.zlevel = 999;
          // data.z = 999;
        } else {
          // data.z = idx1;
        }
        data._path = idx1;
        seriesData.push(data);
      }
    });

    //radar indicator
    let indicator = [];
    Object.keys(indicatorObj).forEach((name, idx) => {
      indicator.push({
        name: name,
        max: indicatorObj[name] * 1.2,
      });
    });
    me._chartOption.radar.indicator = indicator;
    //series
    me._chartOption.series[0].data = [];
    // 把选中的链点放到最后
    let tartgetSeriesData = undefined;
    //series 处理 value值
    seriesData.forEach((i) => {
      let value = [];
      Object.keys(indicatorObj).forEach((name) => {
        if (i["value"].hasOwnProperty(name)) {
          value.push(i["value"][name]);
        } else {
          value.push(0);
        }
      });
      i.value = value;
      if (i._path != column1Idx) {
        me._chartOption.series[0].data.push(i);
      } else {
        tartgetSeriesData = { ...{}, ...i };
      }
    });
    console.log("refreshChartOptions tartgetSeriesData:", tartgetSeriesData);
    if (tartgetSeriesData) {
      me._chartOption.series[0].data.push(tartgetSeriesData);
    }
    console.log("refreshChartOptions me._chartOptionh:", me._chartOption);

    return;
    // ---------------------------------------------
    // 计算图表的左侧留空宽度 column1_list
    let w = 10; // 两个间隙

    let column1Div = document.getElementById("column1_list");
    if (nodePath === undefined || nodePath === null || nodePath === false) {
      w = 0;
    } else {
      if (column1Div) {
        console.log("column1_list.clientWidth:", column1Div.clientWidth);
        w = w + column1Div.clientWidth;
      }
    }

    // 更新 图表div的左偏移量
    let chartDiv = document.getElementById("chart_div");
    console.log("chartDiv:", chartDiv);
    if (chartDiv) {
      chartDiv.style.marginLeft = w + "px";
    }
  };

  /**
   * 【继承组件要重写】
   * echart 有时需要绑定点击事件什么
   * @param {object} e ReactECharts 返回的 echarts 实例
   */
  eChartRefCallback = (e) => {
    let me = this;
    // me.chartIns = e.getEchartsInstance();
    if (!me._chartIns) {
      me._chartIns = e.getEchartsInstance();
      // 绑定点击事件
      me._chartIns.on("click", function (params) {
        console.log("ViewKeyWordsInsight ReactECharts click params: ", params);
        if (
          params.hasOwnProperty("data") &&
          params.data.hasOwnProperty("_path")
        ) {
          let name = params.data.name; //点击的节点的name
          //调用点击事件
          me._column1_onClick(params.data._path, name, false);
        } else {
          me._column1_onClick(null, null, true);
        }
      });
    }
  };

  /**
   * api 请求成功后的初始化过程
   * @param {*} resData api 返回的data
   * @returns
   */
  _init = (resData) => {
    // 生成  chart的option
    // this.refreshChartOptions("all");
  };

  /**
   * 【继承组件要重写】
   * api 请求完成后，setState结束后的 callback
   */
  apiLoadedCallback = () => {
    let me = this;
    //选中全部 column1 子项
    // this._column1_onCheckAllBoxChange({ target: { checked: true } });
    // 默认选中第一层的元素
    let defaultSelectedPath = [];
    this._chartData.forEach((i, idx) => {
      defaultSelectedPath.push("" + idx);
      // me._column1_onCheckboxChange({ target: { path: "" + idx } });
    });

    this.setState(
      {
        _column1CheckedPathList: defaultSelectedPath,
      },
      () => {
        me.refreshChartOptions();
        me.setState({
          _refresh: !this.state._refresh,
        });
      }
    );
  };

  // end of overwrite
  //===============================================================
}

export default connect(
  (state) => ({
    userInfo: state.account.userInfo,
  }),
  (dispatch) => ({})
)(ViewIndustrialChainAInsight);
