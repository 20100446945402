import React from 'react';
import PropTypes from 'prop-types';
import {Form, Input, Modal, Row, Col, Checkbox, Upload, message, InputNumber, Button, Tooltip, Tag} from 'antd';

import PB, {SimplePB} from '@/libs/simplePB';

import {IconTypes} from '@/constants/common';

import {getBase64Url} from '@/utils/Common';
import {REQUEST_BASE} from '@/utils/HttpUtil';

import Icon from '@/components/common/common.icon';
import {
  getChinaADCByTitle,
  unknownChinaADC
} from '@/components/common/common.functions';
import SystemDataProvider from "@/components/common/dataProvider/common.dataProvider.system";
import MicroServiceModalUiConfigWizard
  from '@/components/common/view/microService/modal/common.view.microService.modal.uiConfigWizard';

import style from '@/style/components/mainView/node.less';

class TextEditModal extends React.Component {
  state = {
    title: '',
    iconId: '',
    coverId: '',
    shortDescription: '',
    description: '',
    images: [],
    authorId: undefined,
    version: '',
    recommend: 0,
    projectId: undefined,
    enabled: true,
    uiConfig: '{}',
    calcServerId: '',
    calcServerPathId: '',

    savingStatus: 'idle',
    loadingCover: false,
    loadingImage: false,
    imageList: [],
    previewVisible: false,
    previewImageBase64Url: undefined,
    showUIConfigWizard: false,
    tags:[],
    currentTag:undefined,
    shortcutTags:['图谱样式', '图谱数据', '图谱事件', '图谱任务', '图谱洞察', '图谱分享'], // 快捷标签
    historyTags: [], // 历史记录标签
    showTagInputTooltip: false,
    tag:undefined
  }

  beforeCoverUpload = file => {
    const validSize = file.size / 1024 / 1024 < 5;
    if (!validSize) {
      message.error(`请选择小于 5MB 的图片!`);
      file.validated = false;
      return false;
    } else {
      const fileName = file.name;
      return new Promise((resolve) => {
        let img = new Image();
        img.onload = () => {
          URL.revokeObjectURL(img.src);
          const wRatio = img.naturalWidth > 500 ? (500 / img.naturalWidth) : 1;
          const hRatio = img.naturalHeight > 500 ? (500 / img.naturalHeight) : 1;
          const ratio = Math.min(hRatio, wRatio);
          if (file.type === 'image/jpeg' && ratio === 1) {
            resolve(file);
          } else {
            let c = document.createElement("canvas"), ctx = c.getContext("2d");
            c.width = ratio * img.naturalWidth;
            c.height = ratio * img.naturalHeight;
            ctx.drawImage(img, 0, 0, c.width, c.height);

            // convert to File object, NOTE: we're using binary mime-type for the final Blob/File
            c.toBlob(function(blob) {
              let file = new File([blob], fileName.replaceAll(/\.[^.]+$/g, ".jpg"), {type: "application/octet-stream"});
              resolve(file);
            }, "image/jpeg", 0.8);
          }
        };
        img.src = URL.createObjectURL(file);
      });
    }
  };

  beforeImageUpload = file => {
    const validSize = file.size / 1024 / 1024 < 10;
    if (!validSize) {
      message.error(`请选择小于 10MB 的图片!`);
      file.validated = false;
      return false;
    } else {
      return true;
    }
  };

  customCoverUploadRequest = ({onSuccess, onError, file, onProgress}) => {
    let me = this;

    me.setState({loadingCover: true}, () => {
      SystemDataProvider.uploadPublicFile(file, event => {
        const percent = Math.floor((event.loaded / event.total) * 100);
        onProgress({percent});
      }).then(data => {
        me.setState({loadingCover: false, coverId: data[0].id}, () => {
          onSuccess('OK');
        });
      }).catch(() => {
        me.setState({loadingCover: false, coverId: ''}, () => {
          onError('OK');
        });
      });
    });
  };

  customImageUploadRequest = ({onSuccess, onError, file, onProgress}) => {
    let me = this;

    SystemDataProvider.uploadPublicFile(file, event => {
      const percent = Math.floor((event.loaded / event.total) * 100);
      onProgress({percent});
    }).then(data => {
      let imageList = me.state.imageList;
      imageList.find(img => {
        if (img.uid === file.uid) {
          img.id = data[0].id;
          return true;
        } else {
          return false;
        }
      });

      let images = imageList.filter(img => !!img.id).map(img => img.id);

      me.setState({imageList, images}, () => {
        onSuccess('OK');
      });
    }).catch(() => {
      onError('OK');
    });
  };

  onClose = () => {
    let me = this;

    me.props.onClose();
  };

  onImageChange = ({fileList}) => this.setState({
    imageList: fileList,
  });

  onImagePreview = file => {
    let me = this;

    if (!file.url && !file.preview) {
      getBase64Url(file.originFileObj, imgBase64Url => {
        file.preview = imgBase64Url;
        me.setState({
          previewVisible: true,
          previewImageBase64Url: imgBase64Url,
        });
      });
    } else {
      me.setState({
        previewVisible: true,
        previewImageBase64Url: file.url || file.preview,
      });
    }
  };

  onSave = () => {
    let me = this,
      successCb = () => {me.setState({savingStatus: 'success'}, () => {
        me.props.bus.emit('view', 'micro_service.config.refresh_list', {projectId: me.state.projectId});
        me.props.onClose();
      })},
      failedCb = () => {me.setState({savingStatus: 'failed'})};
    let tag = me.state.tags.length > 0 ? me.state.tags.join(' ') : undefined;
    me.setState({tag,savingStatus: 'processing'}, () => {
      if (me.props.info) {
        me.props.bus.emit('view', 'micro_service.config.on_edit', {
          config: {
            ...me.state,
            id: me.props.info.id,
            uiConfig: JSON.parse(me.state.uiConfig),
          },
          successCb,
          failedCb,
        });
      } else {
        me.props.bus.emit('view', 'micro_service.config.on_add', {
          config: {
            ...me.state,
            uiConfig: JSON.parse(me.state.uiConfig),
          },
          successCb,
          failedCb,
        });
      }
    });
  };

  initState = () => {
    let me = this;

    if (me.props.info) {
      let tag = me.props.info.tag||'';
      let tags = tag.includes(' ') ? tag.split(' ') : [tag];
      me.props.form.setFieldsValue({tags:tags});
      me.setState({
        title: me.props.info.title,
        iconId: me.props.info.iconId,
        coverId: me.props.info.coverId,
        shortDescription: me.props.info.shortDescription,
        description: me.props.info.description,
        images: me.props.info.images,
        authorId: me.props.info.authorId,
        version: me.props.info.version,
        recommend: me.props.info.recommend,
        projectId: me.props.info.projectId,
        enabled: me.props.info.enabled,
        uiConfig: JSON.stringify(me.props.info.uiConfig),
        calcServerId: me.props.info.calcServerId,
        calcServerPathId: me.props.info.calcServerPathId,
        savingStatus: 'idle',
        loadingCover: false,
        loadingImage: false,
        imageList: me.props.info.images.map((id, idx) => ({
          uid: -1 - idx,
          name: `cover_${idx}`,
          id,
          status: 'done',
          url: `${REQUEST_BASE}/view/public_files/${id}`,
        })),
        previewVisible: false,
        previewImageBase64Url: undefined,
        tags: tags,
        tag: me.props.info.tags && me.props.info.tags.length > 0 ? me.props.info.tags.join(' ') : undefined
      });
    } else {
      me.setState({
        title: '',
        iconId: '',
        coverId: '',
        shortDescription: '',
        description: '',
        images: [],
        authorId: undefined,
        version: '',
        recommend: 0,
        projectId: undefined,
        enabled: true,
        uiConfig: '{}',
        calcServerId: '',
        calcServerPathId: '',
        savingStatus: 'idle',
        loadingCover: false,
        loadingImage: false,
        imageList: [],
        previewVisible: false,
        previewImageBase64Url: undefined,
        tags: [],
        tag:undefined
      });
      me.props.form.setFieldsValue({tags:[]});
    }
  };

  onTagRemove = (i) => {
    const {form} = this.props;
    // can use data-binding to get
    let tags = Array.from(form.getFieldValue('tags'));
    tags.splice(i, 1);

    // can use data-binding to set
    form.setFieldsValue({
      tags: tags,
    });
    this.setState({tags: tags});
  };

  onTagAdd = (tag) => {
    const {form} = this.props;
    let tags = Array.from(form.getFieldValue('tags'));
    if (!tag) {
      message.error('请输入标签内容');
      return;
    }
    let newTags = tag.includes(' ') ? tag.split(' ') : [tag];
    let historyTags = localStorage.getItem('historyServiceTags') || '';
    if (newTags.filter(tag => !!tag).find(tag => {
      if (tags.includes(tag)) {
        message.error('标签 ' + tag + ' 已存在');
        return true;
      }
      tags = tags.concat(tag);

      // 将标签缓存到本地
      if (historyTags) {
        historyTags = historyTags.includes(tag) ? historyTags : `${tag}|${historyTags}`;
      } else{
        historyTags = `${tag}`;
      }

      localStorage.setItem('historyServiceTags', historyTags);
      return false;
    })) {
      return;
    }
    // can use data-binding to set
    form.setFieldsValue({
      tags: tags,
    });
    this.setState({
      tags: tags,
      currentTag: '',
      historyTags: historyTags.split('|'),
    });
  };

  tagIconType = (tag) => {
    let result = <Icon name='tag'/>;
    if (getChinaADCByTitle(tag) !== unknownChinaADC && getChinaADCByTitle(tag) !== '000000') {
    //  result = <Icon name={'environment'}/>
    }

    return result;
  };

  componentDidMount() {
    let me = this;

    if (me.props.visible) {
      me.initState();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let me = this;

    if (!prevProps.visible && me.props.visible) {
      me.initState();
    }
  }

  render() {
    let me = this;
    const {form} = me.props;
    const {getFieldDecorator} = form;

    getFieldDecorator('tags', {initialValue: this.props.tags || []});

    return (
      <>
        <Modal
          title={'微服务配置编辑'}
          visible={me.props.visible}
          clasable={me.state.savingStatus !== 'processing'}
          centered={true}
          onCancel={me.onClose}
          cancelText={'取消'}
          onOk={me.onSave}
          okText={'保存'}
        >
          <Form id={"nodeEditForm"}>
            <Form.Item label={'标题（9字内）'}>
              <Input maxLength={9} onChange={e => me.setState({title: e.target.value})} value={me.state.title} autoFocus={true} />
            </Form.Item>
            <Form.Item label={'图标ID（25字符内）'}>
              <Row>
                <Col span={12}>
                  <Input placeholder={'icon-micro-service'} maxLength={25} onChange={e => me.setState({iconId: e.target.value})} value={me.state.iconId} />
                </Col>
                <Col span={12} style={{fontSize: '2rem', paddingLeft: '1rem'}}>
                  <Icon name={me.state.iconId || 'icon-micro-service'} type={IconTypes.ICON_FONT} />
                </Col>
              </Row>
            </Form.Item>
            <Form.Item label={'封面图（14:9）'}>
              <Upload
                name="coverId"
                listType={"picture-card"}
                showUploadList={false}
                beforeUpload={me.beforeCoverUpload}
                customRequest={me.customCoverUploadRequest}
              >
                {
                  me.state.coverId ? (
                    <img src={`${REQUEST_BASE}/view/public_files/${me.state.coverId}`} alt="cover" style={{ width: '100%' }} />
                  ) : (
                    <div>
                      <Icon name={me.state.loadingCover ? 'loading' : 'plus'} />
                      <div className="ant-upload-text">上传</div>
                    </div>
                  )}
              </Upload>
            </Form.Item>
            <Form.Item label={'短描述（19字内）'}>
              <Input maxLength={19} onChange={e => me.setState({shortDescription: e.target.value})} value={me.state.shortDescription} />
            </Form.Item>
            <Form.Item label={'描述（1000字内）'}>
              <Input.TextArea rows={10} maxLength={1000} onChange={e => me.setState({description: e.target.value})} value={me.state.description} />
            </Form.Item>
            <Form.Item label={'图片（10张内）'}>
              <Upload
                listType="picture-card"
                fileList={me.state.imageList}
                beforeUpload={me.beforeImageUpload}
                customRequest={me.customImageUploadRequest}
                onPreview={me.onImagePreview}
                onChange={me.onImageChange}
              >
                {me.state.imageList >= 10 ? null : (
                  <div>
                    <Icon name="plus" />
                    <div className="ant-upload-text">上传</div>
                  </div>
                )}
              </Upload>
              <Modal visible={me.state.previewVisible} footer={null} onCancel={() => me.setState({previewVisible: false})}>
                <img alt="image" style={{ width: '100%' }} src={me.state.previewImageBase64Url} />
              </Modal>
            </Form.Item>
            <Form.Item label={'作者ID（数字）'}>
              <Input
                onChange={e => me.setState({authorId: e.target.value === `${parseInt(e.target.value)}` ? parseInt(e.target.value) : undefined})}
                value={me.state.authorId}
              />
            </Form.Item>
            <Form.Item label={'版本（45字符内）'}>
              <Input maxLength={45} onChange={e => me.setState({version: e.target.value})} value={me.state.version} />
            </Form.Item>
            <Form.Item label={'推荐值（数值）'}>
              <InputNumber min={0} onChange={v => me.setState({recommend: v})} value={me.state.recommend} />
            </Form.Item>
            <Form.Item label={'项目ID（45字符内）'}>
              <Input maxLength={45} onChange={e => me.setState({projectId: e.target.value === '' ? undefined : e.target.value})} value={me.state.projectId} />
            </Form.Item>
            <Form.Item label={'是否启用'}>
              <Checkbox onChange={e => me.setState({enabled: e.target.checked})} checked={me.state.enabled} />
            </Form.Item>
            <Form.Item label={(
              <>
                <span>配置文本</span>
                <Button style={{float: 'right'}} onClick={() => me.setState({showUIConfigWizard: true})}>配置向导</Button>
              </>
            )}>
              <Input.TextArea rows={10} onChange={e => me.setState({uiConfig: e.target.value})} value={me.state.uiConfig} />
            </Form.Item>
            <Form.Item label={'计算服务器ID（9字符内）'}>
              <Input maxLength={9} onChange={e => me.setState({calcServerId: e.target.value})} value={me.state.calcServerId} />
            </Form.Item>
            <Form.Item label={'计算服务路径ID（45字符内）'}>
              <Input maxLength={45} onChange={e => me.setState({calcServerPathId: e.target.value})} value={me.state.calcServerPathId} />
            </Form.Item>
            <Form.Item
                label="标签"
                style={{marginBottom: '0'}}
              >
                {
                  this.state.tags.map(
                    (tag, i) => (
                      tag && (<Tag key={`tag-${i}`}>
                        { me.tagIconType(tag)}
                        &nbsp;{tag}&nbsp;
                        <a
                          className={style['tag-remove']}
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            this.onTagRemove(i);
                          }}
                        >
                          <Icon name={'delete'}/>
                        </a>
                      </Tag>)
                    )
                  )
                }
                <Tooltip
                  title={
                    <div style={{paddingTop: '10px'}}>
                      <Input.Search
                        placeholder="请输入标签"
                        enterButton="添加"
                        value={this.state.currentTag}
                        ref={inputRef => {me.addTagInputRef = inputRef;}}
                        onChange={e => {
                          let value = e.target.value;
                          let tags = localStorage.getItem('historyServiceTags') || '';
                          let historyTags = tags.split('|') || [];
                          let filterTags = historyTags.filter(item => item.includes(value));
                          if (value) {
                            this.setState({
                              currentTag: e.target.value,
                              historyTags: filterTags,
                            });
                          } else {
                            this.setState({
                              currentTag: e.target.value,
                              historyTags: historyTags,
                            });
                          }
                        }}
                        onSearch={this.onTagAdd}
                        onKeyDown={event => {
                          if (event.key === 'Escape') {
                            event.stopPropagation();
                            me.setState({showTagInputTooltip: false, currentTag: ''});
                          }
                        }}
                      />
                      {
                        me.state.currentTag ? (
                          <div className={style['tag-shortcut-wrap']}>
                            {
                              me.state.historyTags.length > 0 ? (
                                <React.Fragment>
                                  <div className={style['tag-group-title']}>输入匹配：</div>
                                  <div className={style['tag-group']}>
                                    {
                                      me.state.historyTags.map((item, index) => {
                                        if (index < 3) {
                                          return (
                                            <div key={item} style={{display: 'inline-block', marginBottom: '10px'}}>
                                              <Tag
                                                style={{cursor: 'pointer'}}
                                                onClick={() => {
                                                  this.onTagAdd(item);
                                                }}
                                              >
                                                {item}
                                              </Tag>
                                            </div>
                                          )
                                        }
                                      })
                                     }
                                  </div>
                                </React.Fragment>
                              ) : null
                            }
                          </div>
                        ) : (
                          <div className={style['tag-shortcut-wrap']}>
                            <div className={style['tag-group-title']}>快捷标签：</div>
                            <div className={style['tag-group']}>
                              {
                                me.state.shortcutTags.map(item => (
                                  <div key={item} style={{display: 'inline-block', marginBottom: '10px'}}>
                                    <Tag style={{cursor: 'pointer'}}
                                         onClick={() => {
                                           this.onTagAdd(item);
                                         }}
                                    >
                                      <Icon name={'tag'} style={{marginRight: '6px'}}/>
                                      {item}
                                    </Tag>
                                  </div>
                                ))
                              }
                            </div>
                            {
                              me.state.historyTags.length > 0 ? (
                                <React.Fragment>
                                  <div className={style['tag-group-title']}>历史记录：</div>
                                  <div className={style['tag-group']}>
                                    {
                                      me.state.historyTags.map((item, index) => {
                                        if (index < 8) {
                                          return (
                                            <div key={item} style={{display: 'inline-block', marginBottom: '10px', cursor: 'pointer'}}>
                                              <Tag onClick={() => {
                                                this.onTagAdd(item);
                                              }}
                                              >
                                                {item}
                                              </Tag>
                                            </div>
                                          )
                                        }
                                      })
                                    }
                                  </div>
                                </React.Fragment>
                              ) : null
                            }
                          </div>
                        )
                      }
                    </div>
                  }
                  placement="top"
                  overlayClassName={style['add-tag-tooltip']}
                  trigger={'click'}
                  visible={me.state.showTagInputTooltip}
                  getPopupContainer={() => document.getElementById('nodeEditForm')}
                  onVisibleChange={showTagInputTooltip => {
                    me.setState({showTagInputTooltip});
                    if (showTagInputTooltip) {
                      requestAnimationFrame(() => {
                        if (me.addTagInputRef) {
                          me.addTagInputRef.focus();
                        }
                        let tags = localStorage.getItem('historyServiceTags') || '';
                        if (tags) {
                          me.setState({
                            historyTags: tags.split('|'),
                          })
                        }
                      });
                    }
                  }}
                >
                  <Button shape="circle" icon="plus" size={'small'} />
                </Tooltip>
              </Form.Item>
          </Form>
        </Modal>
        <MicroServiceModalUiConfigWizard
          visible={me.state.showUIConfigWizard}
          onClose={() => me.setState({showUIConfigWizard: false})}
          onSave={uiConfig => me.setState({uiConfig, showUIConfigWizard: false})}
          bus={me.props.bus}
        />
      </>
    );
  }
}

const MicroServiceModalConfigDetail = Form.create()(TextEditModal);

MicroServiceModalConfigDetail.defaultProps = {
  bus: PB,
};

MicroServiceModalConfigDetail.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  info: PropTypes.object,
  bus: PropTypes.instanceOf(SimplePB),
};

export default MicroServiceModalConfigDetail;