import React from 'react';
import PropTypes from 'prop-types';

import PB, {SimplePB} from '@/libs/simplePB';

import ViewDataProvider from '@/components/common/dataProvider/common.dataProvider.view';
import ExploreTalentRecommendDetailLogic
  from '@/components/common/view/explore/common.view.explore.talent.recommendDetail.logic';
import ExploreTalentRecommendDetailModal
  from '@/components/common/view/explore/common.view.explore.talent.recommendDetail.modal';

class ExploreTalent extends React.PureComponent {
  state = {
    showRecommendDetailResultModal: false,
  };

  onRecommendDetail = () => {
    let me = this;

    me.setState({showRecommendDetailResultModal: true});
  }

  componentDidMount() {
    let me = this;

    me.props.bus.subscribe(me, 'view', 'explore.explore_talent.recommend_detail.show_modal', ({viewId}) => {
      if (viewId === me.props.viewDataProvider.viewId) {
        me.onRecommendDetail();
      }
    });
  }

  componentWillUnmount() {
    this.props.bus.remove(this);
  }

  render() {
    let me = this;

    return (
      <React.Fragment>
        <ExploreTalentRecommendDetailLogic
          viewDataProvider={me.props.viewDataProvider}
          userId={me.props.userId}
          bus={me.props.bus}
        />
        <ExploreTalentRecommendDetailModal
          userId={me.props.userId}
          viewDataProvider={me.props.viewDataProvider}
          visible={me.state.showRecommendDetailResultModal}
          onClose={() => me.setState({showRecommendDetailResultModal: false})}
          bus={me.props.bus}
        />
      </React.Fragment>
    );
  }
}

ExploreTalent.defaultProps = {
  bus: PB,
};

ExploreTalent.propTypes = {
  viewDataProvider: PropTypes.instanceOf(ViewDataProvider).isRequired,
  userId: PropTypes.number.isRequired,
  bus: PropTypes.instanceOf(SimplePB),
};

export default ExploreTalent;