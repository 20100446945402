import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Dropdown, Tooltip } from 'antd';
import moment from 'moment';
import UserAvatar from 'react-user-avatar';

import { getToken, REQUEST_BASE } from '@/utils/HttpUtil';
import Icon from '@/components/common/common.icon';
import { AvatarColors, IconTypes } from '@/constants/common';
import ViewOperateMenu from '@/components/dashboard/dashboard.view.operate';
import View from '@/components/common/common.viewManager';

import style from '@/style/components/dashboard/dashboard.view.less';
import TimeDisplayField from '@/components/common/common.timeDisplayField';

import {API_GetViewById,API_GetTeamworkMembers} from '@/libs/view/network/api.js';

export default class ViewInfoCard extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			coverData: undefined,
			currentViewInfo: props.view,
			membersList:[]
		};
	}

	/*splitViewName = (view) => {
		let me = this;
		let result = [], viewName = view.name;
		if (me.props.searchWord && viewName.indexOf(me.props.searchWord) > -1) {
			let tempName = viewName.replace(me.props.searchWord, `≈${me.props.searchWord}≈`);
			let tempNameArr = tempName.split('≈');
			tempNameArr.forEach((name, index) => {
				if (name === me.props.searchWord) {
					result.push((<span key={`name-${index}`} style={{color: '#FF2600'}}>{name}</span>));
				} else if (name) {
					result.push((<span key={`name-${index}`}>{name}</span>));
				}
			})
		} else {
			result.push((<span key={`name-0`}>{viewName}</span>));
		}
		view['nameArr'] = result;
		return view;
	};*/

	openView = viewId => {
		if (this.props.hasLogin) {
			window.open(`/mainview/relation/${viewId}`, '_blank');
			return;
			if (this.props.readOnly === true) {
				window.open(`/viewer/${viewId}`, '_blank');
			} else {
				window.open(`/mainview/relation/${viewId}`, '_blank');
			}
		} else {
			Modal.info({
				title: '登录提醒',
				icon: <Icon name={'user'} style={{color: '#a5a5a5'}}/>,
				content: '您还没有登录，请先注册或登录后再尝试打开图谱！',
				okText: '知道了',
				onOk() {
					console.log('OK');
				},
			});
		}
	};

	/*
	* 对大于1000的数字进行显示优化
	* 大于1千小于100百万，例如2000：2K+
	* 大于100百万小于1亿，例如2百万：2M+
	* 大于1亿，例如2亿：2B+
	*/
	optimizeData = data => {
		let viewData = data.toString();
		let length = viewData.length;

		if (length >= 4 && length < 7) {
			viewData = `${viewData.slice(0, length - 3)}K+`;
		} else if (length >= 7 && length < 9) {
			viewData = `${viewData.slice(0, length - 6)}M+`;
		} else if (length >= 9) {
			viewData = `${viewData.slice(0, length - 8)}B+`;
		}
		return viewData;
	};
	
	/**
	 * 加载看板封面
	 * @param view
	 */
	loadViewCover = (view) => {
		let me = this;
		if (view['hasCover'] === 1) {
			requestAnimationFrame(() => {
				if (me.props.hasLogin) {
					View.loadViewCover(view.viewId).then(coverData => {
						if (coverData) {
							me.setState({coverData});
						}
						//me.loadMembers();
					}).catch(() => {
						// ignore
					});
				} else {
					View.loadViewPublicCover(view.viewId).then(coverData => {
						if (coverData) {
							me.setState({coverData});
						}
						//me.loadMembers();
					}).catch(() => {
						// ignore
					});
				}
			});
		}else{
			//me.loadMembers();
		}
	}
	loadMembers = () => {
		let me = this;
		let viewId = me.props.viewId?me.props.viewId:me.props.view.viewId;
		if (viewId) {
			let limit = 10, orderBy = undefined, orderType = undefined, start = 0
			API_GetTeamworkMembers(viewId, limit, orderBy, orderType, start).then(response => {
				let res = response.data;
				if (res.code === 0) {
					me.setState({
						membersList: res.data
					})
				} else {
					me.setState({
						membersList: []
					});
				}
			}).catch(e => {
				console.log('API_GetTeamworkMembers e: ', e);
				// ignore
			});
		}
	}
	
	componentDidMount() {
		let me = this;
		// 判断是否要读取看板信息
		const {view, viewId} = this.props;
		if (!view) {
			if (viewId) {
				API_GetViewById(viewId).then(response => {
					console.log('ViewInfoCard API_GetViewById response: ', response);
					let res = response.data;
					if (res.hasOwnProperty('code') && res.code === 0) {
						me.setState({
							currentViewInfo: res.data
						}, () => {
							me.loadViewCover(me.state.currentViewInfo);
						})
					} else {
						// 获取看板信息出错
						me.setState({
							currentViewInfo: false
						});
					}
				}).catch(e => {
					console.log('ViewInfoCard API_GetViewById e: ', e);
					// ignore
				});
			} else {
				// 没有看板信息
				me.setState({
					currentViewInfo: false
				});
			}
		} else {
			me.loadViewCover(view);
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.view !== this.props.view) {
			// console.log(`viewInfo updated: ${this.props.view.viewId}: `, this.props.view);
			this.setState({currentViewInfo: this.props.view});
		}
	}

	render() {
		const {/*view, */ callback /*, showCornerTip*/} = this.props;
		let {currentViewInfo: view} = this.state;
		if(view===false){
			return <div className={style['view-card-wrap']}>加载出错</div>
		}
		if(!view){
			return <div className={style['view-card-wrap']}>加载中</div>
		}
		if (view['viewItem']) {
			view = {...view, ...view['viewItem']};
		}
		// 对大于1000的数字进行显示优化
		let viewCount = view['viewCount'] > 1000 ? this.optimizeData(view['viewCount']) : view['viewCount'];
		let nodeCount = view['nodeCount'] > 1000 ? this.optimizeData(view['nodeCount']) : view['nodeCount'];

		// noinspection HtmlUnknownTarget
		return (
			<div className={style['view-card-wrap']}>
				<div className={style['card-cover']}>
					{
						this.state.coverData ? (
							<img src={this.state.coverData} alt={'图谱封面'}/>
						) : <img src={'/assets/view-default-cover.jpg'} alt={'图谱封面'}/>
					}
					<div className={style['card-bg']} />
				</div>
				<div className={style['card-info']}>
					<div className={style['header']} onClick={() => this.openView(view.viewId)}>
						<div className={style['view-name']}>{view.name}</div>
						{/*<div className={style['view-name']}>{view.nameArr}</div>*/}
						<div className={style['item']}>
							{
								this.props.module === 'public' ? (
									<Tooltip title={'开放阅读'}>
										<div className={style['icon-box']}>
											<Icon name="icon-detail-line" type={IconTypes.ICON_FONT} className={style['icon-down']}/>
										</div>
									</Tooltip>
								) : null
							}
							{/* {
								view.teamworkType === 0 ? (
									<Tooltip title={'开放协作'}>
										<div className={style['icon-box']}>
											<Icon name="icon-type-audit" type={IconTypes.ICON_FONT} className={style['icon-down']}/>
										</div>
									</Tooltip>
								) : null
							}*/}
							{
								view.teamworkType === 1 ? (
									<Tooltip title={'开放协作'}>
										<div className={style['icon-box']}>
											<Icon name="icon-type-cooperation" type={IconTypes.ICON_FONT} className={style['icon-down']}/>
										</div>
									</Tooltip>
								) : null
							}
							{
								view.teamworkType === 3 ? (
									<Tooltip title={'邀请码模式'}>
										<div className={style['icon-box']}>
											<Icon name="icon-type-invitation" type={IconTypes.ICON_FONT} className={style['icon-down']}/>
										</div>
									</Tooltip>
								) : null
							}
							{
								view.up === 1 && view.price === 0 ? (
									<Tooltip title={'开放复制'}>
										<div className={style['icon-box']}>
											<Icon name="icon-fuzhi" type={IconTypes.ICON_FONT} className={style['icon-down']}/>
										</div>
									</Tooltip>
								) : null
							}
							{
								view.lock === 1 ? (
									<Tooltip title={'已锁定'}>
										<div className={style['icon-box']} style={{marginLeft: 0}}>
											<Icon name="icon-lock" type={IconTypes.ICON_FONT} className={style['icon-down']}/>
										</div>
									</Tooltip>
								) : null
							}
							{
								this.props.module === 'eventcorrelation' ? (
									<Tooltip title={'删除图谱'}>
										<div className={style['icon-box']} onClick={(e) => {e.stopPropagation();View.delView(view, callback)}} style={{cursor: 'pointer'}}>
											<Icon name="delete" className={style['icon-down']}/>
										</div>
									</Tooltip>
								) : null
							}
						</div>
					</div>
					<div className={style['body']} onClick={() => this.openView(view.viewId)}>
						<div className={style['owner-info']}>
							<div className={style['avatar-wrap']} title={view.nick}>
								<UserAvatar
									size={'var(--avatar-size)'}
									name={view.nick ? view.nick : '炬图'}
									src={view.picId && getToken() ?
										`${REQUEST_BASE}/user/user/file/${view.picId}?Authorization=${getToken()}`
											:
										`${REQUEST_BASE}/view/public/view/${view.viewId}/owner_avatar`
										}
									colors={AvatarColors}
									className={view.picId ? style['card-avatar'] : `${style['card-avatar']} ${style['card-avatar-default-icon']}`
									}
								/>
							</div>
							{view.members && view.members.length>0 && view.members.map((member) => {
								return member.userId!==view.userId ? (<div className={style['avatar-wrap']} title={member.nick}>
									<UserAvatar
										size={'var(--avatar-size)'}
										name={member.nick ? member.nick : '炬图'}
										src={member.picId && getToken() ?
											`${REQUEST_BASE}/user/user/file/${member.picId}?Authorization=${getToken()}`
												:
											`${REQUEST_BASE}/view/public/view/${member.viewId}/owner_avatar`
											}
										colors={AvatarColors}
										className={member.picId ? style['card-avatar'] : `${style['card-avatar']} ${style['card-avatar-default-icon']}`
										}
									/>
								</div>):null}
							)}
						</div>
					</div>
					<div className={style['footer']}>
						<div className={style['item']} style={{flex: 1}} onClick={() => this.openView(view.viewId)}>
							<Tooltip title={'关注点数量'}>
								<div className={style['item']}>
									<div className={style['icon-box']} style={{marginLeft: 0}}>
										<Icon name="icon-correlation" type={IconTypes.ICON_FONT} /*className={style['icon-down']}*//>
									</div>
									<span>{nodeCount}</span>
								</div>
							</Tooltip>
							<Tooltip title={'浏览数'}>
								<div className={style['item']}>
									<div className={style['icon-box']}>
										<Icon name="eye" /*className={style['icon-down']}*//>
									</div>
									<span>{viewCount}</span>
								</div>
							</Tooltip>
							<Tooltip title={'最后更新时间'}>
								{view.updateTime ? (
									<div className={style['item']}>
										<div className={style['icon-box']} style={{paddingRight: '6px'}}>
											<Icon name="icon-Raidobox-o" type={IconTypes.ICON_FONT} /*className={style['icon-down']}*//>
										</div>
										<TimeDisplayField timestamp={moment(view.updateTime, 'YYYY-MM-DD HH:mm:ss').valueOf()}/>
									</div>
								) : null}
							</Tooltip>
						</div>
						{this.props.hasLogin ? (
							<div /*className={style['controls']}*/>
								<Dropdown
									overlayClassName={'dark-theme '}
									trigger={['click']}
									overlay={(
										<ViewOperateMenu
											callback={(result) => {
												if (result.success) {
													// noinspection FallThroughInSwitchStatementJS
													switch (result.action) {
														case 'edit':
															this.setState({
																currentViewInfo: Object.assign(view, result.viewInfo),
																coverData: result.coverData,
															});
															break;
														case 'sale':
															this.setState({
																currentViewInfo: Object.assign(view, result.viewInfo),
															});
															callback(result);
															break;
														case 'cancel_sale':
															this.setState({
																currentViewInfo: Object.assign(view, result.viewInfo),
															});
															callback(result);
															break;
														// fall_through
														default:
															callback(result);
															break;
													}
												}
											}}
											view={view}
										/>
									)}
									placement="topCenter">
									<div className={[style['icon-box'], style['menu']]} style={{cursor: 'pointer'}}>
										<Icon name="ellipsis" /*className={style['icon-down']}*/ style={{fontSize: '1.6rem'}}/>
									</div>
								</Dropdown>
							</div>
						) : null
						}
					</div>
				</div>
			</div>
		);
	}
}
ViewInfoCard.defaultProps = {
	readOnly: true,
	// showCornerTip: false,
	callback: () => {
	},
	hasLogin: true,
	searchWord: '',
};
ViewInfoCard.propTypes = {
	readOnly: PropTypes.bool, // 是否是创建人
	// showCornerTip: PropTypes.bool, // 是否判断显示角标提示
	view: PropTypes.object,
	viewId: PropTypes.string, // 优先使用 view 内的数据，当view不存在时，用viewId读取view信息
	callback: PropTypes.func, // 图谱操作的回调
	module: PropTypes.string, // 当前显示哪类图谱
	hasLogin: PropTypes.bool, // 是否已经登录
	searchWord: PropTypes.string,
};
