import { Domain, BaseUri } from "@/constants/Version";

/**
 * 系统辅助接口域名
 * @type {string}
 */
export const aidDomain = "https://aid.joinmap.ai";
// export const aidDomain = "http://aid.joinmap.ai";
// export const aidDomain = "http://localhost:5000";
export const snapDomain = "https://snap.joinmap.ai";

/**
 * 系统UI的部分隐藏配置
 * @type {{mainMenu: string, aiDialog: string}}
 */
export const SysUIConfig = {
  // 左上角主菜单
  MainMenu: {
    visibility: "show", // hidden or show
  },
  // 右上角个人统计
  BadgeInfo: {
    visibility: "show", // hidden or show
  },
  // 右上角 分享印刷 按钮
  ShortcutMenu: {
    visibility: "show", // hidden or show
  },
  // 右侧发现按钮
  ExploreToolBar: {
    visibility: "show", // hidden or show
  },
  // ai对话框
  RelationAIConsole: {
    visibility: "show", // hidden or show
  },
  // 协作成员
  TeamworkMemberBar: {
    visibility: "show", // hidden or show
  },
  // 浮动输入条
  RelationSearchBar: {
    visibility: "show", // hidden or show
  },
  // loading
  LoadingBar: {
    display: "flex", // none or flex
  },
};

export default {
  // package.json
  baseUri: BaseUri, // 重置密码的跳转路径
  sysTitle: "JoinMap.Ai",
  sysLogo: "/assets/joinmap128.png",
  // sysCopyRight: "Copyright © 2018-2020 炬图",
  sysCopyRight: "我们图解价值",
  sysIcp: "苏ICP备17060486号-1",
  sysVats: "增值电信经营许可证苏B2-20201139",
  iconFont: "/libs/font_568945_j2jhd7obb6_list.js", //"//at.alicdn.com/t/font_568945_j2jhd7obb6.js",
  advanceExportUser: [1696, 674, 1206, 1250, 1256, 1274, 1364], // 允许使用高级导出

  // 微信登录
  weChatLogin: {
    use: Domain, // 值要和下面的配置项对应p1,v1
    // 微信第三方登录配置 p1.joinmap.ai
    p1: {
      appid: "wx227cb411c0fb8138",
      scope: "snsapi_login", // 写死
      redirect_uri: window.location.protocol + "//p1.joinmap.ai/login",
      style: "black", // 二维码黑白风格
    },
    // 微信第三方登录配置 sh.joinmap.ai
    sh: {
      appid: "wx31feffe51544bea0",
      scope: "snsapi_login", // 写死
      redirect_uri: window.location.protocol + "//sh.joinmap.ai/login",
      style: "black", // 二维码黑白风格
    },
    // 微信第三方登录配置 vip.joinmap.ai
    vip: {
      appid: "wx31feffe51544bea0",
      scope: "snsapi_login", // 写死
      redirect_uri: window.location.protocol + "//vip.joinmap.ai/login",
      style: "black", // 二维码黑白风格
    },
  },

  // 系统搜索外部引擎
  sysSearchEngine: "https://www.baidu.com/s?ie=UTF-8&wd=", // 默认百度搜索
  sysSearchEngineByBing: "https://cn.bing.com/search?q=", // 必应搜索
  sysSearchEngineBySogou: "https://www.sogou.com/web?query=", // 搜狗搜索
  sysSearchEngineBySo: "https://www.so.com/s?ie=UTF-8&q=", // 360搜索
  // 天眼查查询url
  tianyanchaCom: "https://www.tianyancha.com/search?key=",
  // 企查查查询url
  qichachaCom: "https://www.qichacha.com/search?key=",
  // 万方人才智能查询url 格式：作者:某某某
  // 'wanfangdataComCn':'http://www.wanfangdata.com.cn/search/searchList.do?searchType=all&showType=&pageSize=&searchWord='
  // 'wanfangdataComCn':'http://social.wanfangdata.com.cn/search/getSearcAcademic.do?scholarPN=1&searchParam=$1&search=scholar&sort=bf&orgAndClass=$2',
  wanfangdataComCn:
    "http://social.wanfangdata.com.cn/search/getSearcAcademic.do?scholarPN=1&searchParam=",
  wan_fang_talent_fmt:
    "http://social.old.wanfangdata.com.cn/Scholar.aspx?q=%%E5%%A7%%93%%E5%%90%%8D%%3A%%22%s%%22%%20%%E5%%8D%%95%%E4%%BD%%8D%%3A%%22%s%%22&f=scholar.top",
  // 'wanfangdataComCn': 'http://social.old.wanfangdata.com.cn/Scholar.aspx?q=%E5%A7%93%E5%90%8D%3A%22%E9%BB%84%E6%80%9D%E6%BA%90%22%20%E5%8D%95%E4%BD%8D%3A%22%E5%9B%BD%E7%BD%91%E5%9B%9B%E5%B7%9D%E7%9C%81%E7%94%B5%E5%8A%9B%E5%85%AC%E5%8F%B8%E5%AE%A3%E6%B1%89%E5%8E%BF%E4%BE%9B%E7%94%B5%E5%88%86%E5%85%AC%E5%8F%B8%22&f=scholar.top',
  //万方 专利,格式+id
  wangfangdataPatent:
    "http://www.wanfangdata.com.cn/details/detail.do?_type=patent&id=",
  // 万方 论文
  wangfangdataPap: "http://d.old.wanfangdata.com.cn/Periodical/",
};
