import React from 'react';
import PropTypes from 'prop-types';
import {Button, Tooltip, Badge, Menu} from 'antd';

import PB, {SimplePB} from '@/libs/simplePB';

import {IconTypes} from '@/constants/common';

import Icon from '@/components/common/common.icon';
import ViewDataProvider from '@/components/common/dataProvider/common.dataProvider.view';

import style from '@/style/common/view/common.view.explore.less';

class ExploreButtonMenu extends React.Component {
  state = {
    menuVisible: false,
    idList: [],
    statusMap: {},
    infoMap: {},
  }

  callMicroService = id => {
    let me = this;

    if (me.state.menuVisible) {
      me.setState({menuVisible: false});
    }
    me.props.bus.emit('view', 'micro_service.service.show_modal',
      {viewId: me.props.viewDataProvider.viewId, serviceId: id});
  };

  componentDidMount() {
    let me = this;

    me.props.bus.with(
      me
    ).subscribe('view', 'micro_service.config.data_loaded', ({projectId, infoList}) => {
      if ((projectId || me.props.projectId) && projectId !== me.props.projectId) return;
      let idList = [], statusMap = {}, infoMap = {};
      if (infoList.length > 0) {
        infoList.forEach(info => {
          idList.push(info.id);
          statusMap[info.id] = {
            status: 'idle',
            modalVisible: false,
            hasNewResult: false,
          };
          infoMap[info.id] = info;
        });
        me.setState({idList, statusMap, infoMap});
      }
    }).subscribe('view', 'micro_service.service.do_load_more', ({viewId, serviceId}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId && me.state.infoMap[serviceId]) {
        let statusMap = me.state.statusMap;
        statusMap[serviceId].status = 'loading';
        me.setState({statusMap});
      }
    }).subscribe('view', 'micro_service.service.raw_data_loaded', ({hasMoreResult, viewId, serviceId}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId && me.state.infoMap[serviceId]) {
        let statusMap = me.state.statusMap;
        statusMap[serviceId].status = hasMoreResult ? 'stopped' : 'finished';
        me.setState({statusMap});
      }
    }).subscribe('view', 'micro_service.service.current_operation_result', ({currentHasData, viewId, serviceId}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId && me.state.infoMap[serviceId]) {
        let statusMap = me.state.statusMap;
        statusMap[serviceId].hasNewResult = !statusMap[serviceId].modalVisible
          && (statusMap[serviceId].hasNewResult || currentHasData);
        me.setState({statusMap});
      }
    }).subscribe('view', 'micro_service.service.failed_to_load', ({viewId, serviceId}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId && me.state.infoMap[serviceId]) {
        let statusMap = me.state.statusMap;
        statusMap[serviceId].status = 'failed';
        me.setState({statusMap});
      }
    }).subscribe('view', 'micro_service.service.result_cleared', ({viewId, serviceId}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId && me.state.infoMap[serviceId]) {
        let statusMap = me.state.statusMap;
        statusMap[serviceId].status = 'idle';
        statusMap[serviceId].hasNewResult = false;
        me.setState({statusMap});
      }
    }).subscribe('view', 'micro_service.service.modal_visible_changed', ({viewId, visible, serviceId}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId && me.state.infoMap[serviceId]) {
        let statusMap = me.state.statusMap, status = statusMap[serviceId].status;
        statusMap[serviceId].modalVisible = visible;
        if (visible && (status === 'stopped' || status === 'finished' || status === 'failed')) {
          statusMap[serviceId].status = 'idle';
        }
        statusMap[serviceId].hasNewResult = statusMap[serviceId].hasNewResult && !visible;
        me.setState({statusMap});
      }
    }).subscribe('view', 'micro_service.component.loaded', ({viewId}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId) {
        // me.props.bus.emit('view', 'micro_service.config.on_load', {projectId: me.props.projectId});
      }
    });
  }

  componentWillUnmount() {
    this.props.bus.remove(this);
  }

  render() {
    let me = this, statusIdx = 0;

    Object.values(me.state.statusMap).forEach(k => {
      switch (k) {
        case 'failed':
          statusIdx = 3;
          return;
        case 'loading':
          statusIdx = Math.max(statusIdx, 2);
          return;
        case 'stopped':
        case 'finished':
          statusIdx = Math.max(statusIdx, 1);
          return;
      }
    });

    return (
      <Tooltip
        overlayClassName={me.state.idList.length > 0 ? style['layout-overlay'] : ''}
        trigger={'hover'}
        placement={'left'}
        title={me.state.idList.length > 0 ? (
          <div className={'dark-theme'} style={{maxHeight: '70vh', overflowY: 'auto'}}>
            <Menu selectable={false}>
              {
                me.state.idList.map(id => (
                  <Menu.Item key={`m.${id}`} onClick={() => me.callMicroService(id)}>
                    <div className={style['explore-menu-item']}>
                      <Badge dot={me.state.statusMap[id].hasNewResult}>
                        <div className={style['explore-menu-item-icon']}>
                          <Icon name={me.state.infoMap[id].iconId || 'icon-micro-service'} type={IconTypes.ICON_FONT} />
                        </div>
                      </Badge>
                      <div className={style['explore-menu-item-meta']}>
                        <div className={style['explore-menu-item-title']}>{me.state.infoMap[id].title}</div>
                        <div className={style['explore-menu-item-description']}>{me.state.infoMap[id].shortDescription}</div>
                        <div className={style['explore-menu-item-notice']}>
                          {(() => {
                            switch (me.state.statusMap[id].status) {
                              case 'idle':
                                return '';
                              case 'loading':
                                if (me.state.statusMap[id].hasNewResult) {
                                  return '有新结果';
                                } else {
                                  return '计算中';
                                }
                              case 'stopped':
                                return '已停止';
                              case 'finished':
                                return '已完成';
                              case 'failed':
                                return '出错了';
                              default:
                                return '';
                            }
                          })()}
                        </div>
                      </div>
                    </div>
                  </Menu.Item>
                ))
              }
            </Menu>
          </div>
        ) : '暂无相关微服务'}
        onVisibleChange={menuVisible => me.setState({menuVisible})}
        visible={me.state.menuVisible}
      >
        <Button
          shape={'circle'}
          onClick={me.onExploreOverall}
        >
          <Badge status={[undefined, 'success', 'processing', 'warning'][statusIdx]}>
            <Icon name={me.props.iconId} type={IconTypes.ICON_FONT} />
          </Badge>
        </Button>
      </Tooltip>
    );
  }
}

ExploreButtonMenu.defaultProps = {
  bus: PB,
  iconId: 'icon-micro-services',
};

ExploreButtonMenu.propTypes = {
  bus: PropTypes.instanceOf(SimplePB),
  iconId: PropTypes.string,
  projectId: PropTypes.string,
  viewDataProvider: PropTypes.instanceOf(ViewDataProvider).isRequired,
};

export default ExploreButtonMenu;