import Pagination from "rc-pagination/es/locale/zh_CN";
import DatePicker from '../date-picker/locale/zh_CN';
import TimePicker from '../time-picker/locale/zh_CN';
import Calendar from '../calendar/locale/zh_CN';
export default {
  locale: 'zh-cn',
  Pagination: Pagination,
  DatePicker: DatePicker,
  TimePicker: TimePicker,
  Calendar: Calendar,
  // locales for all comoponents
  global: {
    placeholder: '请选择'
  },
  Table: {
    filterTitle: '筛选',
    filterConfirm: '确定',
    filterReset: '重置',
    selectAll: '全选当页',
    selectInvert: '反选当页',
    sortTitle: '排序',
    expand: '展开行',
    collapse: '关闭行'
  },
  Modal: {
    okText: '确定',
    cancelText: '取消',
    justOkText: '知道了'
  },
  Popconfirm: {
    cancelText: '取消',
    okText: '确定'
  },
  Transfer: {
    searchPlaceholder: '请输入搜索内容',
    itemUnit: '项',
    itemsUnit: '项'
  },
  Upload: {
    uploading: '文件上传中',
    removeFile: '删除文件',
    uploadError: '上传错误',
    previewFile: '预览文件',
    downloadFile: '下载文件'
  },
  Empty: {
    description: '暂无数据'
  },
  Icon: {
    icon: '图标'
  },
  Text: {
    edit: '编辑',
    copy: '复制',
    copied: '复制成功',
    expand: '展开'
  },
  PageHeader: {
    back: '返回'
  },
  DateTime_c:{
    weekName:['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
    dayName:['今天','昨天','前天'],
    dayText:['本','上','天前','天后'],
    timeText:['前','后']
  },
  Custom: {
    general:{
      title: '炬图',
      titleMeta: '我们图解价值',
      join: '立即加入炬图',
      load: '加载',
      loading:'加载中，请稍后...',
      more: '更多',
      add:'添加',
      creat: '新建',
      feedback: '联系客服',
      help: '常见问题和操作指南',
      operationGuide: '查看操作指南',
      projectVideo:'案例视频',
      note:{
        changer:'便签内容有变化',
        hidewindow:'隐藏便签窗口',
        showwindow:'显示便签窗口'

      },
      message:"消息中心",
      weekIntegral:'本周积分',
      thisIntegral:'本图积分',
      allIntegral:'全图积分',
      userCenter:'个人中心',
      baseInfo:'基本信息',
      setPassword:'设置密码',
      changePassword:'修改密码',
      modifyMailbox:'修改邮箱',
      bindmailbox:'绑定邮箱',
      bindWeChat:'绑定微信',
      signOut:'退出登录',
      login:'登录',
      logining:'登录中',
      loginSuccess:'登录成功',
      register:'注册',
      resourceLoading:'资源加载中',
      scanLogin:'扫码登录',
      pWechatLogin:'请使用微信扫码登录',
      qrcodeExpired:'二维码已过期，请',
      accountLogin:'通过账密登录',
      wechatLogin:'通过微信登录',
      wechat:'微信',
      noNeedPassword:'无须设置密码',
      enterEmail:'请输入电子邮箱',
      haveAccount:'已经拥有账号？',
      noAccount:'还没有账号？',
      forgotPassword:'忘记密码？',
      loginNow:'立即登录',
      registerNow:'立即注册',
      inputPassword:'输入密码',
      pInputPassword:'请输入密码',
      refresh:'刷新',
      next:'下一步',
      requestWait:'请求中，请稍后...',
      new:'最新',
      operationSucceeded:'操作成功',
      searchMore:'更多搜索',
      addToMap:'添加到图谱',
      connectNode:'连接到当前节点',
      copySelectedNode:'复制选中节点文本',
      switchNext:'切换下一批',
      operationTerminated:'操作已终止',
      pleasePress:'请按下',
      latestNews:'最新动态',
      attentionReport:'关注主题最新动态，洞悉发展趋势',
      of:'的',
      news:'动态',
      dynamicInformationBase:'动态信息库'
    },
    form:{
      checkAll:'全选',
      enterKeywordSearch:'输入关键词搜索...',
      searchInMap:'在图谱中搜索',
      signSpace:'可尝试大于号或空格隔开的词',
      send:'发送',
      sendPlaceholder:'与机器人对话',
      switch:'切换'

    },
    outLink:{
      bilibili:'哔哩哔哩',
      wx_video:'微信视频号',
      wx_mini:'微信小程序',
    },
    notification:{
      screenWidthAlert: '如果在4K显示器上全屏幕运行炬图系统，将会获得极佳的视觉体验。',
    },
    message:{
      loadFail:'加载更多数据失败，请稍后再试',
      masterRight:'只有版主才能增加协作成员，请联系版主。',
      welcome:'欢迎来到炬图！',
      youhave:'您有',
      newMessages:'条新消息',
      nodowith:['当前点与其他点关系不大'],
      noRecommendedNodes:['由于节点不够多，','没有推荐连接的节点'],
      calculation:['计算中，可继续其他操作，','数秒后结果会显示到右侧对话框中。'],
      noCalculationResultRedo:['暂无计算结果，','正在重试……'],
      noCalculationResult:['暂无计算结果，','可稍后再试'],
      noCalculationResultForMap:'该问题似乎与图谱节点无关，可以尝试包含节点中的某个关键词。',
      noCalculationResultSame:'您输入内容与上次相同，可以直接查看上次的结果。',
      calculationCompleted:'计算完成，请到AI对话框查看结果',
      pleaseSelectConnected:'请先选择待连接的节点。',
      pleaseSelectAdded:'请先选择待添加的节点。',
      recommendedClipboard:'推荐的候选信息已经复制到剪贴板。',
      selectNodeCopy:'请先选择要复制文本内容的节点。',
      copySelectedText:'复制选中节点文本。',
      switchDialogStatus:'点击切换对话框状态，当前：',
      loadExactMatch:'正在进行计算……',
      loadingUnderlay:'正在加载底图，请稍后……',
      loadingFavorite:'正在加载我的收藏，请稍后...',
      calculatingOptimization:'正在计算加载优化路径...',
      cooperation:['本图谱允许开放协作，','您将成为协作成员，并可创建您的节点！'],
      noChopResult:['当前图谱似乎与提问没有关联，您可以','1. 在当前图谱中增加一些与问题有关的素材节点，\n2. 或者可以切换语境到动态信息库再次尝试提问'],
      noChopDataResult:['没有搜索到与您的提问有关的素材，您可以',
        '1. 联系我们 hi@joinmap.ai 定制您的专属动态信息库；\n2. 或者您在图谱中自由积累信息，逐步形成自己的信息库。',
        '信息库可以汇聚与您有关的碎片化信息，例如网上信息、工作信息、读书笔记、日常琐事等等，机器人可以对这些信息进行整理和搜索，为您提供辅助决策支持。'],
      viewOperation:{
        addFiles: '正在上传，请稍后……',
        addRelation: '正在添加关联关系，请稍后……',
        addTextNodes: '正在添加节点，请稍后……',
        loadBriefingInfoList: '正在载入简报……',
        loadData: '正在载入图谱数据……',
        loadDynamicData: '正在载入动态分析数据……',
        loadDataSet: '正在加载数据集信息，请稍后……',
        loadFileList: '正在加载文件列表，请稍后……',
        loadRelatedClue: '正在进行语义发现……',
        loadRelatedResource: '正在进行资源联想……',
        loadSubViewData: '正在计算节点关联信息，请稍后……',
        recoverRelationGraph: '正在处理，请稍后……',
        removeFiles: '正在删除文件，请稍后……',
        removeNode: '正在删除节点，请稍后……',
        removeRelation: '正在删除关联关系，请稍后……',
        removeRelationGraph: '正在处理，请稍后……',
        saveNodeWithRelations: '正在保留节点，请稍后……',
        saveRelationGraph: '正在保存关系图，请稍后……',
        smartSearchGraphInAllView: '正在进行关系结构联想，请耐心等待……',
        updateConfig: '正在保存……',
        updateFilesRemark: '正在设置，请稍后……',
        updateNodeInfo: '正在更新节点信息……',
        voteExplorationResultByNode: '评价提交中，请稍后……',
        voteExplorationResultBySubview: '评价提交中，请稍后……',
      }
    },
    aiConsole:{
      aiBox:'AI对话框',
      drag:'按住拖动，双击自动停靠',
      increase:'增加对话框高度',
      lower:'降低对话框高度',
      mapLoaded:'图谱数据加载完毕',
      popFilterBar:'鼠标移至界面左侧可弹出筛选栏',
      clickReport:'点击筛选栏最上方“报告”按钮可查看本图谱专题报告',
      clickLatestNews:'点击专题报告中的“最新动态”可查看本图谱最新节点',
      aiSendType:['当前图谱','频道图谱','新闻事件','科技成果','操作指南','不限语境'],
      aiSendTypeText:['该语境主要包含当前图谱信息','该语境主要包含矩图的频道图谱的信息','该语境主要包含新闻事件信息','该语境主要包含论文专利软著信息','该语境主要包含矩图操作指南相关信息','该语境主要回答其他语境无法回答的信息'],
      currentContext:'当前对话语境：',
      switchContext:'切换对话语境',
      pleaseSwitchContext:'为您提供以下对话语境，请选择：',
      successfullySwitchContext:'已成功为您切换对话语境为：',
    },
    leader:{
      swiperTitle_0: '创建动态关联笔记',
      swiperContent_0: ['新颖的关联输入','适应大脑的联想式查阅','轻松建立枝繁叶茂的知识体系'],
      swiperTitle_1: '链接数据传感网络',
      swiperContent_1: ['开放的数据','隐含的线索','平行世界的数字互动'],
      swiperTitle_2: '生成宏大精致图谱',
      swiperContent_2: ['浩瀚星辰','创新意蕴','令人惊叹的科技美学'],
      swiperTitle_3: '分享认知洞察见解',
      swiperContent_3: ['发现有趣的故事','体验探索的快乐','与世界分享珍贵的见解'],
      swiperTitle_4: '纵览产业行业资源',
      swiperContent_4: ['一目了然的产业全貌','自由编辑的主题报告','群星闪烁的企业和人才'],
      swiperTitle_5: '跟推团队协作任务',
      swiperContent_5: ['鼓舞人心的战术资源','俯视全局的挂图作战','任务场景化的解决方案'],
      swiperTitle_6: '贯通教育培训思维',
      swiperContent_6: ['神经元模式的知识结构','富有意趣的蔓延式交流','深刻的认知网络分析'],
    },
    channel:{
      sysName:['我是组长','我是成员','开放阅读','开放协作','开放复制','最新动态'],
      simpleChannel:['全部有权','只看有我','只看公共']
    },
    menu:{
      properties:'图谱属性',
      basicProperties:'基本属性',
      setUnderlay:'设置底图',
      chinaMap:'设置中国地图',
      uploadImage:'上传底图图片',
      clearImage:'清除底图图片',
      setBackground:'设置背景',
      titlePicture:'标题图片',
      optimizeLoading:'优化加载',
      cancelOptimization:'取消优化',
      hideAIDialogBox:'隐藏AI对话框',
      showAIDialogBox:'显示AI对话框',
      hideInputBox:'隐藏输入框',
      showInputBox:'显示输入框',
      autoDim:'自动暗淡',
      allNodesOn:'节点全亮',
      currentNodesOn:'节点全亮（当前）',
      autoDimTime:'节点随时间自动暗淡',
      currentAutoDimTime:'节点随时间自动暗淡（当前）',
      autoDimLevel:'节点随层级自动暗淡',
      currentAutoDimLevel:'节点随层级自动暗淡（当前）',
      latestOverview:'最新概况',
      mapAuthority:'图谱权限',
      accessSettings:'访问设置',
      ticketManagement:'门票管理',
      mapTransfer:'图谱转让',
      unlockEdit:'解锁编辑',
      lockEdit:'锁定编辑',
      copyMap:'复制图谱',
      mutualErasure:'相互删改',
      prohibitEditOthers:'禁止成员删改他人的节点',
      allowEditOthers:'允许成员删改他人的节点',
      mapNode:'图谱节点',
      nodesStatistics:'节点统计',
      keynoteReport:'主题报告',
      relationshipClues:'关系线索',
      enclosure:'图谱附件',
      allNodesFixed:'全部固定',
      allNodesMoved:'全部可动',
      allNodesLines:'全部直线',
      allNodesCurves:'全部曲线',
      exportSave:'导出保存',
      advancedExport:'高级导出',
      exportPicture:'导出图片',
      exportAll:'导出全部',
      generateLink:'生成链接',
      customPrinting:'定制印刷',
      training:'智能训练',
      byTime:'按时间',
      byGeography:'按地理',
      byRelationship:'按关系',
      byCard:'按卡片',
      opex:'系统运维',
      panoramaView:'全景视图',
      microservices:'微服务',
      export:'导出',
      printing:'印刷',
      switchDimMode:'切换暗淡方式',
      affectsVisual:'（仅影响视觉效果）',
      keepAllBright:'保持全亮',
      adjustSpacing:'调整节点间距',
      adjustFontSize:'调整节点字体大小',
      switchNodeLayout:'切换节点布局',
      gravitationalContractionLayout:'引力收缩布局',
      expansionExtensionLayout:'膨胀伸展布局',
      layoutRightLeft:'从右到左布局',
      layoutLeftRight:'从左到右布局',
      layoutTopDown:'自上而下布局',
      layoutBottomUp:'自下而上布局',
      arrowMark:'箭头标记',
      showAllNodes:'显示所有节点',
      displayWordCloud:'显示本图谱所有节点的词云',
      autoRefreshing:'启动图谱数据自动刷新',
      autoRefreshingStop:'图谱数据自动刷新中，点击停止',
      ShowHideSearch:'显示／隐藏搜索条',
      ShowHideInput:'显示／隐藏输入条',
      ShowHideAI:'显示／隐藏AI对话框',
      ShowHideMicroservices:'显示／隐藏微服务区',
      ShowHideView:'显示／隐藏视图区',
      selectPlaySpeed:'选择播放速度',
      slowlyPlay:'慢速播放',
      constantPlay:'常速播放',
      quickPlay:'快速播放',
      resumeAutomatic:'空格/点击按钮：恢复自动播放',
      clickStopPause:'点击按钮停止，按空格键暂停',
      showNodeEditing:'显示节点编辑的时间顺序',
      hideNodeEditing:'隐藏节点编辑的时间顺序'
    },
    filter:{
      all:'全部',
      highlightAllNodes:'高亮全部节点',
      report:'报告',
      highlightReport:'高亮专题报告',
      reportTip:'高亮最近播放的专题报告中的节点',
      favorite:'收藏',
      favoriteTip:'设置后仅本人可见',
      important:'重要',
      importantTop:'设置后本图谱成员均可见',
      find:'发现',
      findTip:'高亮发现活动／发现人物等功能创建的节点',
      selected:'选中',
      selectedTip:'高亮与选中节点关联的其他节点',
      type:'类型',
      connect:'连接',
      today:'今天',
      todayTip:'高亮今天更新的节点',
      yesterday:'昨天',
      yesterdayTip:'高亮昨天更新的节点',
      before:'前天',
      beforeTip:'高亮前天更新的节点',
      isolated:'孤立',
      isolatedTip:'高亮全部孤立节点',
      connectMe:'连我',
      connectMeTip:'高亮与我的节点相关联的其他用户节点',
      highlightAll:'高亮全部',
      typeNodes:'类型的节点'
    },
    view:{
      add:'新建图谱',
      name:'图谱',
      addInfo:'您还没有图谱，赶紧新建……',
      eventMap:'的事件关联图谱',
      list: '图谱市场',
      openSettings:'开放设置',
      memberlist:'成员列表',
      editbase:'修改图谱基本信息',
      putMemberlit:'收起协作成员列表',
      expandMemberlit:'展开协作成员列表',
      seeMemberlit:'查看成员列表',
      member:'协作成员',
      newNodes:'最新节点',
      node:'节点',
      backgroundStatus:'安静模式',
      hiddenStatus: '隐藏模式',
      activeStatus: '交互模式',
      searchMaps:'搜图谱',
      searchNodes:'搜节点',
      previousNode:'上一个节点',
      nextNode:'下一个节点',
      rambling:'正在漫游',
      left:'左侧',
      screen:'筛选',
      leftFilter:'左侧筛选',
      customList:'自定列表',
      system:'系统',
      public:'公开',
      autoPlay:'点击按钮自动播放'
    }
  }
};