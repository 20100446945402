import {
	SUBMIT_FEEDBACK,
	SUBMIT_FEEDBACK_SUCCESS,
	SUBMIT_FEEDBACK_FAILED,
	SUBMIT_PRINTING,
	SUBMIT_PRINTING_SUCCESS,
	SUBMIT_PRINTING_FAILED,
} from './actionTypes';

const initialStore = {
	feedbackLoading: false,
	feedbackResult: 'common',
	printingLoading: false,
	printingResult: 'common',
};

export default function reducer(state = initialStore, action) {
	switch (action.type) {
		case SUBMIT_FEEDBACK:
			return {
				...state,
				feedbackLoading: true,
				feedbackResult: 'common',
			};
		case SUBMIT_FEEDBACK_SUCCESS:
			return {
				...state,
				feedbackLoading: false,
				feedbackResult: 'success',
			};
		case SUBMIT_FEEDBACK_FAILED:
			return {
				...state,
				feedbackLoading: false,
				feedbackResult: 'failed',
			};
		case SUBMIT_PRINTING:
			return {
				...state,
				printingLoading: true,
				printingResult: 'common',
			};
		case SUBMIT_PRINTING_SUCCESS:
			return {
				...state,
				printingLoading: false,
				printingResult: 'success',
			};
		case SUBMIT_PRINTING_FAILED:
			return {
				...state,
				printingLoading: false,
				printingResult: 'failed',
			};
		default:
			return state;
	}
}