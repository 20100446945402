import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Icon } from 'antd';
import style from '@/style/common/relation/common.relation.nodeFilter.less';
import PB from '@/libs/simplePB';

class NodeFilter extends React.PureComponent {

	constructor(props) {
		super(props);

		this.state = {
			currentItemKey: props.viewType, // 当前选择的
		};
	}

	viewStyle = [
		{
			key: 'updateTime',
      icon: 'history',
			text: '更新时间',
			tip: '按更新时间展示时间视图',
		},
		{
			key: 'linkTime',
			icon: 'clock-circle',
			text: '创建时间',
			tip: '按更新时间展示时间视图',
		},
		{
			key: 'tagTime',
      icon: 'tags',
			text: '标签时间',
			tip: '按标签时间展示时间视图',
		},
	];

	componentDidMount() {

	}

	componentWillUnmount() {

	}

	/**
	 * 重置过滤器
	 */
	resetFilter = (type, filterItem) => {
		let me = this, selectArr = [];
		const { filterType, filterCurKey } = me.props;
		if (filterType[filterCurKey].key === type) {
			return;
		}

		if (type === 'viewType') {
			PB.emit('aiConsole', 'message.push', {
				type: 'ai',
				content: `图谱切换 ${filterItem.tip}`,
			});
			me.props.switchView(filterItem.key);

			this.setState({
				currentItemKey: filterItem.key,
			});
		} else {
			PB.emit('aiConsole', 'message.push', {
				type: 'ai',
				content: `图谱切换数据源`,
			});
			me.props.resetNodes(type);
			this.setState({
				currentItemKey: undefined,
			});
		}

	};

	render() {
		let me = this, currentUserId = parseInt(localStorage.getItem('userId'));
		const {filterType, filterCurKey} = me.props;

		return (
      <div className={`${style['frame']} dark-theme`} style={{zIndex: 100}}>
        <div className={`${style['statistics-frame']}`}>
          <div className={`${style['statistics-content-frame']}`}>
	          <table>
		          <thead>
		          <tr>
			          <th colSpan={2}>类型：</th>
		          </tr>
		          </thead>
		          <tbody>
		          {
			          Object.values(filterType).map(item => (
				          <Tooltip
					          title={item.text}
					          key={item.key}
					          placement={'right'}
				          >
					          <tr
						          className={`${item.key === filterType[filterCurKey].key ? 'highlighted' : null}`}
						          onClick={ e => {
							          e.stopPropagation();
							          me.resetFilter(item.key, null);
						          }}
					          >
						          <td colSpan={2} style={{textAlign: 'left'}}>{item.text}</td>
					          </tr>
				          </Tooltip>
			          ))
		          }
		          </tbody>
	          </table>
	          <hr/>
            <table>
              <thead>
              <tr>
                <th colSpan={2}>{filterType[filterCurKey].text}查看：</th>
              </tr>
              </thead>
              <tbody>
              {me.viewStyle.map(type => (
                <Tooltip
                  title={type.tip}
                  key={type.key}
                  placement={'right'}
                  mouseLeaveDelay={0.05}
                >
                  <tr
                    className={`${me.state.currentItemKey === type.key ? 'highlighted' : null}`}
                    onClick={e => {
                      e.stopPropagation();
	                    me.resetFilter('viewType', type);
                    }}
                  >
                    <td><Icon type={type.icon} /></td>
                    <td>{type.text}</td>
                  </tr>
                </Tooltip>
              ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
	}
}

NodeFilter.defaultProps = {
	viewType: 'updateTime',
};

NodeFilter.propTypes = {
  viewType: PropTypes.string,
  switchView: PropTypes.func,
	filterType: PropTypes.object, // 如果用户筛选项列表
	filterCurKey: PropTypes.string, // 当用显示的筛选值
	resetNodes: PropTypes.func, // 切换筛选节点，筛选点还是全部
};

export default NodeFilter;