import React from 'react';
import PropTypes from 'prop-types';
import {Modal, Button, Radio, message, Tooltip} from 'antd';
import PB, { SimplePB } from '@/libs/simplePB';
import ReactWordCloud from 'react-wordcloud';
import copy from 'copy-to-clipboard';
import ViewDataProvider from '@/components/common/dataProvider/common.dataProvider.view';
import style from '@/style/common/view/common.view.wordCloudModal.less';
import Icon from '@/components/common/common.icon';
import { IconTypes } from '@/constants/common';

class viewWordCloudModal extends React.Component {
	state = {
		loadingStatus: 'idle', // 词云数据加载状态
		rankList: [], // 排名数据
		cloudWords: undefined, // 图谱词云词

		type: 'phrase', // phrase, words
		amount: 20,
	};

	onLoadWordsCloudList = (type) => {
		let me = this, loadFn = () => {
			me.props.viewDataProvider.loadRankData(type=='w'?null:type).then(data => {
				let words = data['rank_data'].map(item => {
					item.text = item.label;
					item.value = item.rate;
					return item;
				}).sort((a, b) => b.value - a.value);

				me.setState({
					type,
					rankList: data['rank_data'],
					cloudWords: words,
					loadingWordsCloudStatus: 'success',
				});
			}).catch((/*{code, msg}*/) => {
				me.setState({
					type,
					loadingWordsCloudStatus: 'failed',
					rankList: [],
					cloudWords: [],
				});
			});
		};
		type = type || me.state.type;
		if (me.state.loadingWordsCloudStatus !== 'processing') {
			me.setState({type, loadingWordsCloudStatus: 'processing', rankList: [], cloudWords: []}, loadFn);
		} else {
			loadFn();
		}
	};

	copyToClipboard = text => {
		let result = copy(text, {
			message: '请按下 #{key} 复制文本。',
		});

		if (result) message.success('文本已复制到剪切板。');
	};

	componentDidMount() {
		let me = this;

		if (me.props.visible) {
			// 加载排名
			me.onLoadWordsCloudList();
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		let me = this;

		if (!prevProps.visible && me.props.visible && prevState.rankList.length === 0) {
			// 加载排名
			me.onLoadWordsCloudList();
		}
	}

	componentWillUnmount() {
		this.props.bus.remove(this);
	}

	render() {
		let me = this, top3Words = (me.state.cloudWords || []).slice(0, 3).map(w => `${w.text} (${w.value})`);

		return (
			<Modal
				title={<div>图谱词云</div>}
				width={'50rem'}
				visible={me.props.visible}
				centered={true}
				wrapClassName={style['word-cloud-modal']}
				bodyStyle={{height: '30rem'}}
				onCancel={me.props.onClose}
				footer={[(
					<Radio.Group
						value={me.state.type}
						onChange={e => me.onLoadWordsCloudList(e.target.value)}
						style={{float: 'left'}}
						key={'option-type'}
					>
						<Radio.Button value="phrase">词组</Radio.Button>
						<Radio.Button value='w' >词汇</Radio.Button>
					</Radio.Group>
				), (
					<Radio.Group
						value={`${me.state.amount}`}
						onChange={e => me.setState({amount: parseInt(e.target.value)})}
						style={{float: 'left', marginLeft: '0.5em'}}
						key={'option-amount'}
					>
						<Radio.Button value="10">10</Radio.Button>
						<Radio.Button value="20">20</Radio.Button>
						<Radio.Button value="50">50</Radio.Button>
						<Radio.Button value="100">100</Radio.Button>
					</Radio.Group>
				), (me.state.cloudWords && me.state.cloudWords.length > 0 ? (
					<div
						key={'action-copy'}
						style={{float: 'left', marginLeft: '0.5em', display: 'inline-block', lightHeight: '1.5'}}
					>
						<Tooltip
							title={(
								<pre>
									{top3Words.join('\r\n') + ((me.state.cloudWords && me.state.cloudWords.length > 3) ? '\r\n...' : '')}
								</pre>
							)}
						>
							<a
								onClick={() => {
									me.copyToClipboard(me.state.cloudWords.slice(0, me.state.amount).map(w => `${w.text} (${w.value})`).join('\r\n'));
								}}
								style={{
									lineHeight: '32px',
									display: 'inline-block',
									height: '32px',
								}}
							>
								<Icon name={'copy'} style={{verticalAlign: '-0.2em', marginRight: '0.15em'}} />
								复制词云文本
							</a>
						</Tooltip>
					</div>
				) : (
					<div
						key={'action-copy'}
						style={{float: 'left', marginLeft: '0.5em', display: 'inline-block', lightHeight: '1.5'}}
					>
						<a
							className={'disabled-ctrl'}
							style={{
								lineHeight: '32px',
								display: 'inline-block',
								height: '32px',
								cursor: 'not-allowed',
							}}
						>
							<Icon name={'copy'} style={{verticalAlign: '-0.2em', marginRight: '0.15em'}} />
							复制词云文本
						</a>
					</div>
				)), (
					<Button key="btn-close" type="primary" onClick={me.props.onClose}>
						关闭
					</Button>
				)]}
			>
				{
					me.state.cloudWords && me.state.cloudWords.length > 0 ? (
						<ReactWordCloud
							minSize={[200, 100]}
							maxWords={me.state.amount}
							options={{
								rotations: 1,
								rotationAngles: [0, 0],
								fontSizes: [200 / Math.pow(me.state.amount, 0.5), 350 / Math.pow(me.state.amount, 0.5), 500 / Math.pow(me.state.amount, 0.5)],
								transitionDuration: 300,
							}}
							words={me.state.cloudWords}
							callbacks={{
								onWordClick: word => {
									me.copyToClipboard(word.text);
								},
							}}
						/>
					) : (
						( me.state.loadingWordsCloudStatus === 'idle' || me.state.loadingWordsCloudStatus === 'processing') ? (
							<div className={style['loading']}>正在生成词云…</div>
						) : (
								me.state.cloudWords && me.state.cloudWords.length === 0 ? (
								<div className={style['no-more-panel']}>
									<Icon name={'icon-robot'} type={IconTypes.ICON_FONT}/>
									<span>数据通信失败或生成词云异常，<br/>请稍后再试！</span>
								</div>
							) : null
						)
					)
				}

			</Modal>
		);
	}
}

viewWordCloudModal.defaultProps = {
	bus: PB,
	visible: false,
	callback: () => {},
};

viewWordCloudModal.propTypes = {
	bus: PropTypes.instanceOf(SimplePB),
	visible: PropTypes.bool.isRequired,
	viewId: PropTypes.string.isRequired,
	onClose: PropTypes.func.isRequired,
	viewDataProvider: PropTypes.instanceOf(ViewDataProvider),
};

export default viewWordCloudModal;