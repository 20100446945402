import React from 'react';
import PropTypes from 'prop-types';
import {Button, Form} from 'antd';

import AuthForm from '@/components/common/common.authForm';
import style from '@/style/components/common.authForm.less';

class DurationNoticePanel extends React.Component {
  state = {
    redirectAt: 0,
    intervalId: 0,
    timeout: 0,
  };

  componentWillMount() {
    let me = this;
    if (me.props.redirectAt > this.state.redirectAt) {
      me.resetTimeout(me.props.redirectAt)
    }
  }

  shouldComponentUpdate(nextProps) {
    let me = this;
    if (nextProps.redirectAt > this.state.redirectAt) {
      me.resetTimeout(nextProps.redirectAt)
    }
    return true;
  }

  resetTimeout = (redirectAt) => {
    let me = this;
    let duration = redirectAt - (new Date()).getTime();
    let timeout = Math.ceil(duration / 1000), millisLeft = duration % 1000;
    let fn = (timeout) => {
      console.log('current timeout ', timeout);
      if (timeout <= 0) {
        me.onBack();
      }
      me && me.setState({
        timeout: timeout,
        intervalId: timeout > 0 ? setTimeout(fn, 1000, timeout - 1) : 0,
      })
    };
    requestAnimationFrame(() => {
      if (me.state.intervalId) {
        clearInterval(me.state.intervalId);
      }
      me.setState({
        timeout: timeout,
        intervalId: setTimeout(fn, millisLeft, timeout - 1),
        redirectAt: redirectAt,
      })
    });
  };

  onBack = (e) => {
    e && e.preventDefault();
    if (this.state.intervalId) {
      clearTimeout(this.state.intervalId);
    }
    requestAnimationFrame(() => this.props.onBack());
  };

  render () {
    // noinspection RequiredAttributes
    return (
      <AuthForm>
        <Form onSubmit={this.onBack}>
          {this.props.children}
          <Form.Item className={style['form-item']}>
            <Button type="primary"
                    htmlType="submit"
                    className={style['form-button']}>
              {this.props.btnText}({this.state.timeout})
            </Button>
          </Form.Item>
        </Form>
      </AuthForm>
    )
  }
}

DurationNoticePanel.defaultProps = {
  btnText: '',
  redirectAt: 0,
  onBack: () => {console.log('onBack property is not set.')},
};

DurationNoticePanel.propTypes = {
  btnText: PropTypes.string,
  redirectAt: PropTypes.number,
  onBack: PropTypes.func,
};

export default DurationNoticePanel;