/*
 * @Author: Carr.s
 * @Date: 2022-04-20 14:16:56
 * @LastEditTime: 2022-06-27 10:22:22
 * @LastEditors: Carr.s
 * @Description: 搜索视图路由
 * @Copyright by joinmap
 */
import React from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import Qs from "qs";

import { Copyright } from "@/components/common/common.copyright";
import Loading from "@/components/common/common.loading";

import layoutStyles from "@/style/default/defaultViewLayout.less";

// 组件
import SearchNodesResult from "@/components/searchView/search_nodesResult";
import SearchNewsEvent from "@/components/searchView/search_newsEvent";
import intl from 'react-intl-universal';

// const { Content } = Layout;

class IndightContainer extends React.Component {
  state = {
    // 加载页面遮罩
    viewLoading: {
      status: false,
      content: undefined, // 显示文字
    },
  };
  // url传入的参数
  // limit 返回 tag 数量。0 返回全部数据，默认返回150个
  // sortBy 后台排序 各个组件接口自己定义
  _query = {
    wd: undefined,
    viewId: undefined,
    nodeId: undefined,
    target: undefined,
    limit: undefined,
    isDev: false,
    isDebug: false,
  };

  componentDidMount() {}

  render() {
    if (this.props.history.location && this.props.history.location.search) {
      let qs = Qs.parse(this.props.history.location.search, {
        ignoreQueryPrefix: true,
        strictNullHandling: true,
      });

      if (qs.hasOwnProperty("is_dev")) {
        this._query.isDev = qs.is_dev == 1;
      }
      if (qs.hasOwnProperty("is_debug")) {
        this._query.isDebug = qs.is_debug == 1;
      }
      if (qs.hasOwnProperty("limit")) {
        this._query.limit = qs.limit;
      }
      if (qs.hasOwnProperty("view_id")) {
        this._query.viewId = qs.view_id;
      }
      if (qs.hasOwnProperty("node_id")) {
        this._query.nodeId = qs.node_id;
      }
      if (qs.hasOwnProperty("wd")) {
        this._query.wd = qs.wd;
      }
      if (qs.hasOwnProperty("target")) {
        this._query.target = qs.target;
      }
      console.log("IndightContainer qs: ", qs);
    }
    console.log("IndightContainer _query: ", this._query);

    return (
      <div className={layoutStyles["layout-wrap"]}>
        <Loading />
        <div className={layoutStyles["header"]}>
          <div className={layoutStyles["logo"]}>
            <img
              onClick={() => {
                this.props.history.push({
                  pathname: "/index",
                  state: { type: "intro" },
                });
              }}
              src={intl.get('locale')==='zh-cn'?'/assets/logo-hans.png':'/assets/logo-hans_en.png'}
              alt={"炬图"}
            />
          </div>
        </div>
        <div
          className={`${layoutStyles["content-outer"]} dark-theme scrollbar-18`}
        >
          <Switch>
            <Route
              exact
              path={"/search/nodes"}
              component={({ match, history }) => (
                <SearchNodesResult
                  history={history}
                  viewId={match.params.id}
                  userInfo={this.props.userInfo}
                  query={this._query}
                />
              )}
            />
            <Route
              exact
              path={"/search/news-event"}
              component={({ match, history }) => (
                <SearchNewsEvent
                  history={history}
                  viewId={match.params.id}
                  userInfo={this.props.userInfo}
                  query={this._query}
                />
              )}
            />
            <Route render={() => <Redirect to={"/"} />} />
          </Switch>
          {/*<Copyright className={style["version"]}/>*/}
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    userInfo: state.account.userInfo,
  }),
  (dispatch) => ({})
)(IndightContainer);
