import React from "react";
import PropTypes from 'prop-types';
import {Button, Tooltip, message, Menu, Modal} from 'antd';

import PB, {SimplePB} from "@/libs/simplePB";

import {IconTypes} from '@/constants/common';
import {SysUIConfig} from '@/constants/sys.config';
import {getNodeDisplayTitle, getNodeIconByType, NODE_TYPE_TALENT} from '@/constants/vis.defaultDefine.1';
import {NODE_TYPE_TEXT, TYPE_FIELD_NAME} from '@/constants/nodeConfig';

import Icon from "@/components/common/common.icon";
import ViewDataProvider from '@/components/common/dataProvider/common.dataProvider.view';
import NodeDataProvider from "@/components/common/dataProvider/common.dataProvider.node.js";
import {showErrorMessage} from '@/components/common/common.message';
import CandidateNodeList from '@/components/common/view/explore/common.view.explore.candidateNodeList';
// import RankListModal from '@/components/common/view/common.view.rank.listModal';
import ExploreSmartSearchGraphModal from '@/components/common/view/explore/common.view.explore.smartSearchGraphModal';
import ExploreExpandWordByNodeModal from '@/components/common/view/explore/common.view.explore.expandWordByNodeModal';
import ExploreCompany from '@/components/common/view/explore/common.view.explore.company';
import ExploreButtonCompany from '@/components/common/view/explore/common.view.explore.button.company';
import ExploreButtonTalent from '@/components/common/view/explore/common.view.explore.button.talent';

import style from '@/style/common/view/common.view.explore.less';
import relationStyle from '@/style/components/main.relation.less';
import ExploreTalent from "@/components/common/view/explore/common.view.explore.talent";
import MicroService from "@/components/common/view/microService/common.view.microService";
import ExploreButtonSimple from "@/components/common/view/explore/common.view.explore.button.simple";
import ExploreButtonMenu from "@/components/common/view/explore/common.view.explore.button.menu";

import ExploreSmartRelationshipCluesModal from '@/components/common/view/explore/common.view.explore.smartRelationshipCluesModal';
import relationCluePngIcon from '@/assets/clue/relationCluePngIcon.png';

const presentationSvg = () => (
  <svg t="1661161487435" class="icon" viewBox="0 0 1047 1024" version="1.1" p-id="1098" width="1em" height="1em">
    <path d="M900.179878 99.090921v805.641091h-801.089976V99.089943h801.089976z m0-77.37872h-801.089976c-40.964917 0-77.37872 36.413803-77.378721 77.377742v805.642069c0 40.963939 36.413803 77.377742 77.377743 77.377742h805.642068c40.963939 0 77.377742-36.412825 77.377743-77.377742V99.089943c-4.551114-40.963939-36.412825-77.377742-81.929835-77.377742z" p-id="1099" fill="#dddddd"></path>
    <path d="M217.454075 287.032184a45.782018 44.444261 90 1 0 88.888521 0 45.782018 44.444261 90 1 0-88.888521 0z" p-id="1100" fill="#dddddd"></path>
    <path d="M217.454075 500.682255a45.782018 44.444261 90 1 0 88.888521 0 45.782018 44.444261 90 1 0-88.888521 0z" p-id="1101" fill="#dddddd"></path>
    <path d="M217.454075 714.332326a45.782018 44.444261 90 1 0 88.888521 0 45.782018 44.444261 90 1 0-88.888521 0z" p-id="1102" fill="#dddddd"></path>
    <path d="M765.597349 691.43985h-370.367209c-11.852064 0-22.221641 10.683481-22.221642 22.892476s10.370556 22.89052 22.221642 22.89052h370.367209c11.852064 0 22.222619-10.682504 22.222619-22.891498s-10.370556-22.89052-22.222619-22.89052z m-370.367209-381.51519h370.367209c11.852064 0 22.222619-10.683481 22.222619-22.892476s-10.370556-22.89052-22.222619-22.89052h-370.367209c-11.852064 0-22.221641 10.682504-22.221642 22.891498s10.370556 22.89052 22.221642 22.89052z m370.367209 167.867075h-370.367209c-11.852064 0-22.221641 10.682504-22.221642 22.89052s10.370556 22.891498 22.221642 22.891498h370.367209c11.852064 0 22.222619-10.682504 22.222619-22.891498s-10.370556-22.89052-22.222619-22.89052z" p-id="1103" fill="#dddddd"></path>
  </svg>
);

const expandingStatus = {
  normal: {},
  props: {},
  num: {},
  domain: {},
  similarity: {},
};

const expandNames = {
  normal: '活动发现',
  props: '个人社会关系发现',
  num: '数值发现',
  domain: '同领域/同行发现',
  similarity: '相似发现',
};

const expandPropNames = {
  clue: 'relByClue',
  props: 'relByProps',
  num: 'relByNum',
  domain: 'relByDomain',
};

const expandFnNames = {
  normal: 'exploreAsync',
  props: 'exploreAsyncByProps',
  num: 'exploreAsyncByNum',
  domain: 'exploreSyncByDomainPerson',
  similarity: 'exploreSyncBySimilarity',
};

const globalRefreshing = {
  refreshingInterval: undefined,
  refreshing: false,
};

const flagProps = Object.values(expandPropNames);

const aiConsoleStatusText = {
  'background': '安静模式',
  'hidden': '隐藏模式',
  'active': '交互模式',
};

const exploreAsyncMap = {
  normal: true,
  props: true,
  num: true,
  domain: false,
  similarity: false,
}

class ExploreToolBar extends React.PureComponent {
  state = {
    currentShownNodeId: undefined,
    currentAlg: 'normal', // normal, props, num
    containerId: `relation-explore-${Math.random()}`,

    aiConsoleStatus: 'active',

    rankType: 'list', // 图谱排名当前类型
    showRankResult: false, // 图谱排名是否需要显示排名结果

    lastModifiedNodeId: undefined,
    lastSelectedNodeId: undefined,
    currentSelectedNodeId: undefined,

    showSmartSearchGraphModal: false, // 是否展示关系结构联想结果对话框
    smartSearchGraphList: [], // 关系结构联想结果
    smartSearchGraphTargetNode: undefined, // 关系结构联想关联节点

    showExpandWordByNodeModal: false, // 是否展示左右组词联想结果对话框
    expandWordByNodeWordGroups: [], // 左右组词联想结果
    expandWordByNodeTargetNode: undefined, // 左右组词联想关联节点

    showDiscoverValuesByNodeModal: false, // 是否展示数值发现结果对话框
    discoverValuesByNodeResults: [], // 数值发现结果
    discoverValuesByNodeTargetNode: undefined, // 数值发现关联节点
    
    showSmartRelationshipCluesModal: false, // 是否展示关系线索对话框
    smartRelationshipCluesList: [], // 关系线索
    smartRelationshipCluesTargetNode: undefined, // 关系线索关联节点

    myMicroServiceInfoList: [],
  };

  memberMap = undefined;

  processing = {};

  onExplore = (alg, currentNodeId) => {
    let me = this;
    currentNodeId = currentNodeId || me.state.currentSelectedNodeId;

    if (!currentNodeId) {
      message.info('请先选择一个节点');
      return;
    }

    let targetNode = me.props.viewDataProvider.getNode(currentNodeId);
    if (!targetNode) {
      message.info('请先选择一个节点');
      return;
    }

    if (targetNode.status === 0) {
      Modal.confirm({
        title: (
          <span>
            <span>您选择的节点</span>
            <span className={relationStyle["node-in-msg"]}>{getNodeDisplayTitle(targetNode)}</span>
            <span>尚未保留，无法进行操作，是否立即保留并继续？</span>
          </span>
        ),
        okText: '保留并继续',
        cancelText: '取消',
        onOk: () => {
          me.props.bus.emit('network', 'node.do_save',
            {node: targetNode, success: () => me.onExplore(alg)});
        },
      });
      return;
    }
    
    me.props.bus.emit('view', 'micro_service.config.hide_market_drawer');

    me.setState({currentAlg: alg}, () => {
      // 如果操作正在进行，直接展示即可
      // 如果自动刷新已停止且为异步操作，重新开始即可
      // 其他情况，启动自动发现流程
      if (expandingStatus[alg][currentNodeId] && !expandingStatus[alg][currentNodeId].stopped) {
        // console.log('如果操作正在进行，直接展示即可');
        me.props.bus.emit('aiConsole', 'message.notice',
          {key: expandingStatus[alg][currentNodeId].logMessageKey});
      } else if (expandingStatus[alg][currentNodeId] && expandingStatus[alg][currentNodeId].async) {
        me.props.bus.emit('aiConsole', 'message.push', {
          type: 'user',
          content: `对节点 "${getNodeDisplayTitle(targetNode, 12)}" 启动${expandNames[alg]}`,
        });

        // console.log('如果自动刷新已停止，重新开始即可');
        expandingStatus[alg][currentNodeId].secondsLeft = 3;
        expandingStatus[alg][currentNodeId].loopTimeout = undefined;
        expandingStatus[alg][currentNodeId].stopped = false;
        expandingStatus[alg][currentNodeId].refreshing = false;
        expandingStatus[alg][currentNodeId].lastMsg =
          `${expandNames[alg]}已启动，正在奋力查找中，请稍后...`;
        me.props.bus.emit('aiConsole', 'message.push', {
          type: 'ai',
          content: (
            <React.Fragment>
              <span>
                {expandingStatus[alg][currentNodeId].lastMsg}
              </span>
              <CandidateNodeList
                key={`exp-candidate-${expandingStatus[alg][currentNodeId].logMessageKey}`}
                bus={me.props.bus}
                nodeIds={expandingStatus[alg][currentNodeId].nodeIds}
                relatedTo={targetNode}
                updateTicket={expandingStatus[alg][currentNodeId].nodeIds.length}
                viewDataProvider={me.props.viewDataProvider}
              />
            </React.Fragment>
          ),
          callback: ({key}) => expandingStatus[alg][currentNodeId].logMessageKey = key,
          delay: 200,
        });
        me.afterExploreAsync(alg, currentNodeId, targetNode);
      } else {
        me.props.bus.emit('aiConsole', 'message.push', {
          type: 'user',
          content: `对节点 "${getNodeDisplayTitle(targetNode, 12)}" 启动${expandNames[alg]}`,
        });

        // console.log('其他情况，启动自动发现流程');
        let messageKey;
        me.props.bus.emit('aiConsole', 'message.push', {
          type: 'ai',
          content: (
            <React.Fragment>
              <span>
                {expandNames[alg]}正在启动，请稍后...
              </span>
            </React.Fragment>
          ),
          callback: ({key}) => messageKey = key,
          delay: 200,
        });
        me.props.viewDataProvider[expandFnNames[alg]](currentNodeId, {
          userId: me.props.userInfo.userId,
        }).then(data => {
          if (expandingStatus[alg][currentNodeId] && expandingStatus[alg][currentNodeId].async) return;
          expandingStatus[alg][currentNodeId] = {
            secondsLeft: 3,
            newNodesAmount: 0,
            hiddenNodeAmount: 0, // 尚未展示的节点数量
            loopTimeout: undefined,
            stopped: false,
            refreshing: false,
            logMessageKey: messageKey,
            nodeIds: [],
            lastMsg: `${expandNames[alg]}已启动，正在奋力查找中，请稍后...`,
            async: exploreAsyncMap[alg],
          };
          if (me.state.currentSelectedNodeId === currentNodeId && me.state.currentAlg === alg) {
            me.setState({currentShownNodeId: currentNodeId});
          }
          me.props.bus.emit('aiConsole', 'message.update', {
            key: messageKey,
            content: (
              <React.Fragment>
                <span>
                  {expandingStatus[alg][currentNodeId].lastMsg}
                </span>
                <CandidateNodeList
                  key={`exp-candidate-${expandingStatus[alg][currentNodeId].logMessageKey}`}
                  bus={me.props.bus}
                  nodeIds={expandingStatus[alg][currentNodeId].nodeIds}
                  relatedTo={targetNode}
                  updateTicket={expandingStatus[alg][currentNodeId].nodeIds.length}
                  viewDataProvider={me.props.viewDataProvider}
                />
              </React.Fragment>
            ),
          });
          me[exploreAsyncMap[alg] ? 'afterExploreAsync' : 'afterExploreSync'](alg, currentNodeId, targetNode, data,
            messageKey);
        }).catch(({ code, msg }) => {
          me.props.bus.emit('aiConsole', 'message.update', {key: messageKey, content: `操作失败`});
          showErrorMessage({ code, msg });
        });
      }
    });
  };

  afterExploreSync = (alg, currentNodeId, targetNode, {nodes, edges, done = true}, messageKey) => {
    let me = this;

    let locateAction = (
      <Tooltip title={`定位到节点 "${getNodeDisplayTitle(targetNode, 12)}"`} key={'locate'}>
        <Button
          shape={'circle'}
          className={'first ant-btn-icon ant-btn-icon-only'}
          onClick={e => {e.preventDefault(); me.props.bus.emit('network', 'focus', targetNode)}}
        >
          <Icon name={'icon-location'} type={IconTypes.ICON_FONT} />
        </Button>
      </Tooltip>
    );

    // 更新数据
    let newNodeAmount = me.applyPendingChanges(currentNodeId, alg, targetNode, {
      nodesToAdd: nodes,
      edgesToAdd: edges,
    }, done ? undefined : () => {
      me.props.viewDataProvider[expandFnNames[alg]](currentNodeId, {
        userId: me.props.userInfo.userId,
      }).then(data => {
        if (me.state.currentSelectedNodeId === currentNodeId && me.state.currentAlg === alg) {
          me.setState({currentShownNodeId: currentNodeId});
        }
        me.props.bus.emit('aiConsole', 'message.update', {
          key: messageKey,
          content: (
            <React.Fragment>
              <span>
                {expandingStatus[alg][currentNodeId].lastMsg}
              </span>
              <CandidateNodeList
                key={`exp-candidate-${expandingStatus[alg][currentNodeId].logMessageKey}`}
                bus={me.props.bus}
                nodeIds={expandingStatus[alg][currentNodeId].nodeIds}
                relatedTo={targetNode}
                updateTicket={expandingStatus[alg][currentNodeId].nodeIds.length}
                viewDataProvider={me.props.viewDataProvider}
              />
            </React.Fragment>
          ),
        });
        me.afterExploreSync(alg, currentNodeId, targetNode, data, messageKey);
      })
    });
    if (newNodeAmount > 0) {
      expandingStatus[alg][currentNodeId].newNodesAmount += newNodeAmount;
      expandingStatus[alg][currentNodeId].lastNewNodesAmount = newNodeAmount;
    }

    expandingStatus[alg][currentNodeId].lastMsg =
      `操作完成，${expandNames[alg]}共找到新节点 ${expandingStatus[alg][currentNodeId].newNodesAmount} 个`;
    me.props.bus.emit('aiConsole', 'message.update', {
      key: expandingStatus[alg][currentNodeId].logMessageKey,
      content: me.exploreResultContentFn(alg, currentNodeId, targetNode),
      actions: [
        locateAction,
        (
          <span key={'loading'} style={{float: 'right'}}>
            {expandingStatus[alg][currentNodeId].lastNewNodesAmount > 0 ?
              `新节点 ${expandingStatus[alg][currentNodeId].lastNewNodesAmount} 个` : ''}
          </span>
        ),
      ],
    });
    expandingStatus[alg][currentNodeId].stopped = (done === true);
  };

  afterExploreAsync = (alg, currentNodeId, targetNode) => {
    let me = this;

    let locateAction = (
      <Tooltip title={`定位到节点 "${getNodeDisplayTitle(targetNode, 12)}"`} key={'locate'}>
        <Button
          shape={'circle'}
          className={'first ant-btn-icon ant-btn-icon-only'}
          onClick={e => {e.preventDefault(); me.props.bus.emit('network', 'focus', targetNode)}}
        >
          <Icon name={'icon-location'} type={IconTypes.ICON_FONT} />
        </Button>
      </Tooltip>
    );

    let waitActionsFn = lastNewNodesAmount => [
      locateAction,
      (
        <Tooltip title={'停止刷新'} key={'stop'}>
          <Button shape={'circle'} icon={'stop'} style={{float: 'right'}} className={'last'} onClick={stopFn} />
        </Tooltip>
      ),
      (
        <span key={'loading'} style={{float: 'right', marginLeft: '3rem'}}>
          {lastNewNodesAmount > 0 ? `新节点 ${lastNewNodesAmount} 个` : '奋力查找中'}
          ，{expandingStatus[alg][currentNodeId].secondsLeft} 秒后刷新...
        </span>
      ),
    ];
    let loadingActionsFn = () => [
      locateAction,
      (
        <Tooltip title={'停止刷新'} key={'stop'}>
          <Button shape={'circle'} icon={'stop'} style={{float: 'right'}} className={'last'} onClick={stopFn} />
        </Tooltip>
      ),
      (
        <span key={'loading'} style={{float: 'right', marginLeft: '3rem'}}>
          正在刷新，请稍后...
        </span>
      ),
    ];
    let stopFn = () => {
      let stopped = expandingStatus[alg][currentNodeId].stopped;
      clearTimeout(expandingStatus[alg][currentNodeId].loopTimeout);
      if (expandingStatus[alg][currentNodeId].loopLoadingInterval !== undefined) {
        clearInterval(expandingStatus[alg][currentNodeId].loopLoadingInterval);
        expandingStatus[alg][currentNodeId].loopLoadingInterval = undefined;
      }
      expandingStatus[alg][currentNodeId].loopTimeout = undefined;
      expandingStatus[alg][currentNodeId].lastMsg =
        `操作完成，${expandNames[alg]}共找到新节点 ${expandingStatus[alg][currentNodeId].newNodesAmount} 个`;
      delete me.processing[expandingStatus[alg][currentNodeId].logMessageKey];
      me.props.bus.emit('aiConsole', 'message.update', {
        key: expandingStatus[alg][currentNodeId].logMessageKey,
        content: me.exploreResultContentFn(alg, currentNodeId, targetNode),
        actions: [
          locateAction,
          (
            <Tooltip title={'手动刷新'} key={'reload'}>
              <Button
                shape={'circle'}
                icon={'reload'}
                style={{float: 'right'}}
                className={'last'}
                onClick={() => {
                  expandingStatus[alg][currentNodeId].secondsLeft = -1;
                  loopFn();
                }}
              />
            </Tooltip>
          ),
          (
            <span key={'loading'} style={{float: 'right'}}>
              {expandingStatus[alg][currentNodeId].lastNewNodesAmount > 0 ?
                `新节点 ${expandingStatus[alg][currentNodeId].lastNewNodesAmount} 个，` : ''}
              {stopped ? '手动刷新已完成' : '自动刷新已停止'}
            </span>
          ),
        ],
      });
      expandingStatus[alg][currentNodeId].stopped = true;
    };
    let loopFn = () => {
      me.processing[expandingStatus[alg][currentNodeId].logMessageKey] = true;
      let lastNewNodesAmount = expandingStatus[alg][currentNodeId].lastNewNodesAmount;
      if (expandingStatus[alg][currentNodeId].secondsLeft > 0) {
        if (expandingStatus[alg][currentNodeId].refreshing) {
          if (!globalRefreshing.refreshing) {
            expandingStatus[alg][currentNodeId].refreshing = false;
            // 更新数据
            let newNodeAmount = me.applyPendingChanges(currentNodeId, alg, targetNode);
            if (newNodeAmount > 0) {
              expandingStatus[alg][currentNodeId].newNodesAmount += newNodeAmount;
              expandingStatus[alg][currentNodeId].lastNewNodesAmount = newNodeAmount;
              expandingStatus[alg][currentNodeId].lastMsg =
                `${expandNames[alg]}正在进行中，目前共找到新节点 ${expandingStatus[alg][currentNodeId].newNodesAmount} 个`;
              me.props.bus.emit('aiConsole', 'message.update', {
                key: expandingStatus[alg][currentNodeId].logMessageKey,
                content: me.exploreResultContentFn(alg, currentNodeId, targetNode),
                actions: waitActionsFn(newNodeAmount),
              });
            }
            if (expandingStatus[alg][currentNodeId].stopped) {
              stopFn();
              return;
            }
          } else {
            // 提示正在获取数据
            me.props.bus.emit('aiConsole', 'message.patch', {
              key: expandingStatus[alg][currentNodeId].logMessageKey,
              actions: loadingActionsFn(),
            });
          }
        } else {
          // 提示几秒后自动刷新
          me.props.bus.emit('aiConsole', 'message.patch', {
            key: expandingStatus[alg][currentNodeId].logMessageKey,
            actions: waitActionsFn(lastNewNodesAmount),
          });
          expandingStatus[alg][currentNodeId].secondsLeft--;
        }
      } else {
        expandingStatus[alg][currentNodeId].secondsLeft = 3;
        expandingStatus[alg][currentNodeId].refreshing = true;
        if (!globalRefreshing.refreshingInterval) {
          let reloadDataFn = () => {
            globalRefreshing.refreshing = true;
            setTimeout(() => {
              if (Object.values(me.processing).length <= 0) {
                clearInterval(globalRefreshing.refreshingInterval);
                globalRefreshing.refreshing = false;
                globalRefreshing.refreshingInterval = undefined;
                return;
              }
              me.props.viewDataProvider.reloadData().catch(error => {
                // 忽略错误
                console.error('reloadData failed, ', error);
              }).finally(() => {
                globalRefreshing.refreshing = false;
              });
            }, 200);
          };
          globalRefreshing.refreshingInterval = setInterval(reloadDataFn, 3000);
          reloadDataFn();
        }
        me.props.bus.emit('aiConsole', 'message.patch', {
          key: expandingStatus[alg][currentNodeId].logMessageKey,
          actions: loadingActionsFn(),
        });
      }
      expandingStatus[alg][currentNodeId].loopTimeout = setTimeout(loopFn, 1000);
    };

    loopFn();
  };

  exploreResultContentFn = (alg, currentNodeId, targetNode) => {
    let me = this;
    return (
      <React.Fragment>
        <span>
          {expandingStatus[alg][currentNodeId].lastMsg}
          {expandingStatus[alg][currentNodeId].nodeIds.length > 0 ? <br /> : null}
          {expandingStatus[alg][currentNodeId].nodeIds.length > 0 ? (
            <span style={{padding: '0.7rem 0 0.3rem', display: 'block'}}>
              节点列表：
              <Tooltip title={'点击您感兴趣的条目即可定位并查看相关信息，在前方方框中打钩即可保留对应节点'}>
                <Icon name={'question-circle'} style={{marginLeft: '0.5em', cursor: 'pointer'}} />
              </Tooltip>
            </span>
          ) : null}
        </span>
        <CandidateNodeList
          key={`exp-candidate-${expandingStatus[alg][currentNodeId].logMessageKey}`}
          bus={me.props.bus}
          nodeIds={expandingStatus[alg][currentNodeId].nodeIds}
          relatedTo={targetNode}
          updateTicket={expandingStatus[alg][currentNodeId].nodeIds.length}
          viewDataProvider={me.props.viewDataProvider}
        />
      </React.Fragment>
    );
  };

  applyPendingChanges = (currentNodeId, alg, targetNode, {nodesToAdd, edgesToAdd} = {nodesToAdd: [], edgesToAdd: []}, afterApplied = undefined) => {
    let me = this, newNodeAmount = 0, newHiddenNodeAmount = 0;
    if (nodesToAdd && nodesToAdd.length > 0) {
      newNodeAmount += nodesToAdd.length;
    }
    me.props.viewDataProvider.applyPendingChanges((nodeId, node, action) => {
      // 判断是否是通过当前节点自动发现操作生成
      if (node && node.aiRelatedTo === currentNodeId) {
        // 判断算法是否匹配（人物发现、关系发现等等）
        let found = expandPropNames[alg] && node.meta[expandPropNames[alg]];
        if (!expandPropNames[alg]) {
          found = true;
          flagProps.forEach(prop => (node.meta && node.meta[prop]) ? found = false : null);
        }
        // 如果算法也匹配，则进行下一步处理
        if (found) {
          if (action === 'add' || action === 'update') {
            newNodeAmount++;
            if (action === 'update') {
              expandingStatus[alg][currentNodeId].nodeIds.push(nodeId);
              return true;
            } else/* if (action === 'add')*/ {
              newHiddenNodeAmount++;
              return false;
            }
          }
        }
      }
      return false;
    }, (edgeId, edge) => { // 临时处理
      edge && (edge.meta && ((edge.meta.relatedTo || edge.meta.source) === currentNodeId)) ? (edge._aiGenerated = true) : null;
      return !!edge && (!edge.meta || edge.meta.status === undefined || (edge.meta.relatedTo || edge.meta.source) === currentNodeId);
    }, {edgesToAdd: edgesToAdd.map(e => {e._aiGenerated = true; return e;})});
    newNodeAmount -= expandingStatus[alg][currentNodeId].hiddenNodeAmount;
    expandingStatus[alg][currentNodeId].hiddenNodeAmount = newHiddenNodeAmount;
    let loopLoadingFn = () => {
      let currentNodesToAdd = [], visibleNodesToAdd = [];
      if (nodesToAdd && nodesToAdd.length > 0) {
        let edges = me.props.viewDataProvider.getEdge();
        let currentNodesToAddMap = {};
        nodesToAdd.forEach(n => {
          if (!me.props.viewDataProvider.getNode(n.id)) {
            for (let i = 0; i < edges.length; i++) {
              let linkedNodeId = undefined;
              if (edges[i].from === n.id) {
                linkedNodeId = edges[i].to;
              } else if (edges[i].to === n.id) {
                linkedNodeId = edges[i].from;
              }
              if (linkedNodeId && me.props.viewDataProvider.getNode(linkedNodeId) && visibleNodesToAdd.length < 15) {
                n.withNodeId = linkedNodeId;
                currentNodesToAdd.push(n);
                currentNodesToAddMap[n.id] = n;
                expandingStatus[alg][currentNodeId].nodeIds.push(n.id);
                if (n && n.fname && n.fname !== ' ') {
                  visibleNodesToAdd.push(n);
                }
              }
            }
          }
        });
        if (visibleNodesToAdd.length < 15) {
          nodesToAdd.forEach(n => {
            if (!me.props.viewDataProvider.getNode(n.id)) {
              for (let i = 0; i < edges.length; i++) {
                let linkedNodeId = undefined;
                if (edges[i].from === n.id) {
                  linkedNodeId = edges[i].to;
                } else if (edges[i].to === n.id) {
                  linkedNodeId = edges[i].from;
                }
                if (linkedNodeId && currentNodesToAddMap[linkedNodeId] && visibleNodesToAdd.length < 15) {
                  n.withNodeId = linkedNodeId;
                  currentNodesToAdd.push(n);
                  expandingStatus[alg][currentNodeId].nodeIds.push(n.id);
                  if (n && n.fname && n.fname !== ' ') {
                    visibleNodesToAdd.push(n);
                  }
                }
              }
            }
          });
        }
      }
      let amount = currentNodesToAdd.length, visibleAmount = visibleNodesToAdd.length;
      me.props.viewDataProvider.applyPendingChanges((nodeId, node, action, viewDataProvider) => {
        // 判断是否是通过当前节点自动发现操作生成，判断是否还有被隐藏的新节点
        if (node && node.aiRelatedTo === currentNodeId && expandingStatus[alg][currentNodeId].hiddenNodeAmount > 0) {
          // 判断算法是否匹配（人物发现、关系发现等等）
          let found = expandPropNames[alg] && node.meta[expandPropNames[alg]];
          if (!expandPropNames[alg]) {
            found = true;
            flagProps.forEach(prop => (node.meta && node.meta[prop]) ? found = false : null);
          }
          // 如果算法也匹配，则进行下一步处理
          if (found) {
            if (action === 'add') {
              let connectedNodeIds = [];
              viewDataProvider.getData().data.edges.forEach(edge => {
                if (edge.from === nodeId) {
                  connectedNodeIds.push(edge.to);
                } else if (edge.to === nodeId) {
                  connectedNodeIds.push(edge.from);
                }
              });
              let connectedNodes = viewDataProvider.getNode(connectedNodeIds).filter(n => !!n);
              if (connectedNodes.length > 0 && visibleAmount < 15) {
                amount++;
                if (node && node.fname && node.fname !== ' ') {
                  visibleAmount++;
                }
                expandingStatus[alg][currentNodeId].hiddenNodeAmount--;
                node.withNodeId = connectedNodes.length > 0 ? connectedNodes[0].id : currentNodeId;
                expandingStatus[alg][currentNodeId].nodeIds.push(nodeId);
                return true;
              }
            }
          }
        }
        return false;
      }, (edgeId, edge) => { // 临时处理
        edge && (edge.meta && ((edge.meta.relatedTo || edge.meta.source) === currentNodeId)) ? (edge._aiGenerated = true) : null;
        return !!edge && (!edge.meta || edge.meta.status === undefined || (edge.meta.relatedTo || edge.meta.source) === currentNodeId);
      }, {nodesToAdd: currentNodesToAdd});
      if (amount <= 0 && expandingStatus[alg][currentNodeId].loopLoadingInterval !== undefined) {
        // 操作结束
        setTimeout(() => afterApplied && afterApplied(), 3000);
        clearInterval(expandingStatus[alg][currentNodeId].loopLoadingInterval);
        expandingStatus[alg][currentNodeId].loopLoadingInterval = undefined;
      } else {
        me.props.bus.emit('aiConsole', 'message.patch', {
          key: expandingStatus[alg][currentNodeId].logMessageKey,
          content: me.exploreResultContentFn(alg, currentNodeId, targetNode),
        });
      }
      console.log('amount - ', amount);
    };
    if (expandingStatus[alg][currentNodeId].loopLoadingInterval === undefined) {
      expandingStatus[alg][currentNodeId].loopLoadingInterval = setInterval(loopLoadingFn, 3000);
      loopLoadingFn();
    }
    expandingStatus[alg][currentNodeId].lastNewNodesAmount = 0;
    return newNodeAmount;
  };

  onEvaluationAndForecast = () => {
    let me = this, currentNodeId = me.state.currentSelectedNodeId;

    if (!currentNodeId) {
      message.info('请先选择一个节点');
      return;
    }

    let targetNode = me.props.viewDataProvider.getNode(currentNodeId);
    if (!targetNode) {
      message.info('请先选择一个节点');
      return;
    }

    let messageKey;
    me.props.bus.emit('aiConsole', 'message.push', {
      type: 'user',
      content: `以节点 "${getNodeDisplayTitle(targetNode, 12)}" 为中心应用评估预测模型`,
    });

    me.props.bus.emit('aiConsole', 'message.push', {
      type: 'ai',
      content: (<span><Icon name={'loading'} style={{marginRight: '0.5em'}} />请稍后...</span>),
      callback: ({key}) => messageKey = key,
      delay: 200,
    });

    let addRelatedNodesFn = () => {
      me.props.viewDataProvider.addTextNodes([{
        fname: '启动，预测目标完成概率50%',
        label: '启动，预测目标完成概率50%',
        type: NODE_TYPE_TEXT,
        owner: 0,
        lev: 'prepare',
        forceAdd: true,
      }, {
        fname: '进行1，预测目标完成概率60%',
        label: '进行1，预测目标完成概率60%',
        type: NODE_TYPE_TEXT,
        owner: 0,
        lev: 'doing',
        forceAdd: true,
      }, {
        fname: '遇到困难2，预测目标完成概率30%',
        label: '遇到困难2，预测目标完成概率30%',
        type: NODE_TYPE_TEXT,
        owner: 0,
        lev: 'doing',
        forceAdd: true,
      }], targetNode.id).then(relation => {
        me.props.bus.emit('network', 'focus', [].concat(relation['nodes']).concat([targetNode]));
        me.props.bus.emit('aiConsole', 'message.update', {
          key: messageKey,
          content: `操作成功`,
          actions: [
            (
              <Tooltip title={`定位到节点 "${getNodeDisplayTitle(targetNode, 12)}"`}>
                <Button
                  shape={'circle'}
                  className={'first ant-btn-icon ant-btn-icon-only'}
                  key={'locate'}
                  onClick={e => {e.preventDefault(); me.props.bus.emit('network', 'focus', targetNode)}}
                >
                  <Icon name={'icon-location'} type={IconTypes.ICON_FONT} />
                </Button>
              </Tooltip>
            ),
          ],
        });
      }).catch(({ code, msg }) => {
        showErrorMessage({ code, msg, extra: { viewId: me.props.viewDataProvider.viewId, isModification: true } });
        console.log(`添加失败，code: ${code}, msg: ${msg}`);
        me.props.bus.emit('aiConsole', 'message.update', {key: messageKey, content: `操作失败`});
      });
    };
    if (
      targetNode[TYPE_FIELD_NAME] === NODE_TYPE_TEXT && (
        targetNode.lev === 'gradeD' || (
          targetNode.lev.startsWith('gradeType') && targetNode.meta.userPreferredType === undefined
        )
      ) && parseInt(targetNode.userId) === me.props.userInfo.userId
    ) {
      // 改变图标
      me.props.viewDataProvider.updateNodeInfo({...targetNode, lev: 'gradeG', id: targetNode.id}).then(() => {
        addRelatedNodesFn();
      }).catch(({ code, msg }) => {
        showErrorMessage({ code, msg, extra: { viewId: me.props.viewDataProvider.viewId, isModification: true } });
        console.log(`添加失败，code: ${code}, msg: ${msg}`);
        me.props.bus.emit('aiConsole', 'message.update', {key: messageKey, content: `操作失败`});
      });
    } else {
      // 直接添加节点
      addRelatedNodesFn();
    }
  };

  onShowViewTodoList = () => {
    let me = this;

    me.props.bus.emit('node_task', 'listModal.do_show', {type: 'view'});
  };

  smartRelationshipClues = (nodeId) => {
    let me = this, targetNode = me.props.viewDataProvider.getNode(nodeId);
    if (!targetNode) {
      message.info('请先选择一个节点');
    } else {
      me.setState({showSmartRelationshipCluesModal: true,smartRelationshipCluesTargetNode: targetNode});
    }
  }

  /**
   * 发现用户 - 根据此节点内容搜索炬图用户
   */
  smartSearchUserInAllView = () => {
    let me = this;
    if (!me.state.currentSelectedNodeId && !me.state.lastSelectedNodeId && !me.state.lastModifiedNodeId) {
      message.info('请先选择一个节点');
      return;
    }
    let nodeId = me.state.currentSelectedNodeId || me.state.lastSelectedNodeId || me.state.lastModifiedNodeId;
    if (!nodeId) {
      message.info('请先选择一个节点');
    } else {
      let targetNode = me.props.viewDataProvider.getNode(nodeId);
      me.props.bus.emit('view', 'find.smartSearchUser.show_modal',
        {viewId: me.props.viewDataProvider.viewId, node: targetNode});
    }
  };

  smartSearchGraphInAllView = (nodeId) => {
    let me = this, targetNode = me.props.viewDataProvider.getNode(nodeId);

    if (!targetNode) {
      message.info('请先选择一个节点');
    } else {
      me.props.viewDataProvider.smartSearchGraphInAllView(getNodeDisplayTitle(targetNode)).then(data => {
        let {rootNodes, nodes, edges} = data, nodeMap = {};

        if (rootNodes.length <= 0) {
          message.info('暂时没有找到相关节点');
          return;
        }

        nodes = nodes.filter(n => !!n);
        nodes.forEach(node => {
          nodeMap[node.id] = node;
          node.fixed = false;
          node.fy = 0;
          node.fx = 0;
        });

        let graphList = [], amount = Math.min(3, rootNodes.length);
        for (let i = 0; i < amount; i++) {
          let graph = {tree: {node: rootNodes[i], children: []}, nodes: [rootNodes[i]], edges: []},
            parentNodeIds = [];

          edges = edges.filter(edge => {
            if (!edge) return false;
            if (edge.from === rootNodes[i].id && nodeMap[edge.to]) {
              graph.edges.push(edge);
              parentNodeIds.push(edge.to);
              return false;
            } else if (edge.to === rootNodes[i].id && nodeMap[edge.from]) {
              graph.edges.push(edge);
              parentNodeIds.push(edge.from);
              return false;
            }
            return true;
          });
          parentNodeIds.forEach(nodeId => {
            let node = nodeMap[nodeId];
            graph.nodes.push(node);
            let subGraph = {node, children: [], parentId: rootNodes[i].id};
            edges = edges.filter(edge => {
              if (edge.from === node.id && nodeMap[edge.to]) {
                graph.edges.push(edge);
                graph.nodes.push(nodeMap[edge.to]);
                subGraph.children.push({node: nodeMap[edge.to], parentId: `${rootNodes[i].id}/${nodeId}`});
                return false;
              } else if (edge.to === node.id && nodeMap[edge.from]) {
                graph.edges.push(edge);
                graph.nodes.push(nodeMap[edge.from]);
                subGraph.children.push({node: nodeMap[edge.from], parentId: `${rootNodes[i].id}/${nodeId}`});
                return false;
              } else {
                return true;
              }
            });
            graph.tree.children.push(subGraph);
          });

          graphList.push(graph);
        }

        me.setState({showSmartSearchGraphModal: true, smartSearchGraphList: graphList,
          smartSearchGraphTargetNode: targetNode});
      }).catch(({code, msg}) => {
        showErrorMessage({code, msg, extra: {viewId: me.props.viewDataProvider.viewId, isModification: false}});
      });
    }
  };

  expandWordByNode = (nodeId) => {
    let me = this, targetNode = me.props.viewDataProvider.getNode(nodeId);

    if (!targetNode) {
      message.info('请先选择一个节点');
    } else {
      NodeDataProvider.expandWord(getNodeDisplayTitle(targetNode)).then(data => {
        me.setState({showExpandWordByNodeModal: true, expandWordByNodeWordGroups: data.data,
          expandWordByNodeTargetNode: targetNode});
      }).catch(({code, msg}) => {
        showErrorMessage({code, msg, extra: {viewId: me.props.viewDataProvider.viewId, isModification: false}});
      });
    }
  };

  /*onRecommend = () => {
    let me = this, userId = parseInt(localStorage.getItem('userId'));

    me.props.viewDataProvider.recommendByView(2, {userId}).catch(({code, msg}) => {
      showErrorMessage({code, msg, extra: {viewId: me.props.viewDataProvider.viewId, isModification: false}});
    });
  };*/

  componentDidMount() {
    let me = this;

    me.props.bus.sub(me, 'relation', 'node.single_selection_change', nodeId => {
      if (me.state.currentShownNodeId !== nodeId) {
        if (expandingStatus[me.state.currentAlg][nodeId]) {
          me.setState({currentShownNodeId: nodeId});
        } else if (expandingStatus['normal'][nodeId]) {
          me.setState({currentShownNodeId: nodeId, currentAlg: 'normal'});
        } else {
          me.setState({currentShownNodeId: undefined});
        }
      }
    });

    me.props.bus.sub(me, 'relation', 'node.explore.do', ({alg, nodeId}) => {
      me.onExplore(alg, nodeId);
    });

    me.props.bus.sub(me, 'relation', 'node.explore.do.normal', () => {
      if (!me.state.currentSelectedNodeId && !me.state.lastSelectedNodeId && !me.state.lastModifiedNodeId) {
        message.info('请先选择一个节点');
        return;
      }
      let nodeId = me.state.currentSelectedNodeId || me.state.lastSelectedNodeId || me.state.lastModifiedNodeId;
      me.onExplore('normal', nodeId);
    });

    me.props.bus.sub(me, 'relation', 'node.smart_search_graph_in_all_view.do', ({viewId}) => {
      if (!me.state.currentSelectedNodeId && !me.state.lastSelectedNodeId && !me.state.lastModifiedNodeId) {
        message.info('请先选择一个节点');
        return;
      }
      let nodeId = me.state.currentSelectedNodeId || me.state.lastSelectedNodeId || me.state.lastModifiedNodeId;
      me.smartSearchGraphInAllView(nodeId);
    });

    me.props.bus.sub(me, 'relation', 'node.expand_word_by_current.do', ({viewId}) => {
      if (!me.state.currentSelectedNodeId && !me.state.lastSelectedNodeId && !me.state.lastModifiedNodeId) {
        message.info('请先选择一个节点');
        return;
      }
      let nodeId = me.state.currentSelectedNodeId || me.state.lastSelectedNodeId || me.state.lastModifiedNodeId;
      me.expandWordByNode(nodeId);
    });

    me.props.bus.sub(me, 'relation', 'node.single_selection_change', (nodeId) => {
      if (me.state.currentSelectedNodeId !== nodeId) {
        me.setState({
          currentSelectedNodeId: nodeId,
          lastSelectedNodeId: me.state.currentSelectedNodeId || me.state.lastSelectedNodeId,
        });
      }
    });

    me.props.bus.sub(me, 'relation', 'data.loaded', () => {
      let lastModifiedNode = me.props.viewDataProvider.getNode().reduce((lastNode, currentNode) => {
        let lastTime = lastNode ? (lastNode.updateTime || lastNode.linkTime) : undefined;
        let currentTime = currentNode.updateTime || currentNode.linkTime;
        if (lastTime && lastTime >= currentTime) {
          return lastNode;
        } else {
          return currentNode;
        }
      }, undefined);
      if (lastModifiedNode) {
        me.setState({lastModifiedNodeId: lastModifiedNode.id});
      }
    });

    me.props.bus.sub(me, 'relation', 'node.removed', ({nodeIds}) => {
      let {currentSelectedNodeId, lastSelectedNodeId, lastModifiedNodeId} = me.state;
      if ((nodeIds || []).indexOf(currentSelectedNodeId) >= 0) {
        currentSelectedNodeId = undefined;
      }
      if ((nodeIds || []).indexOf(lastSelectedNodeId) >= 0) {
        lastSelectedNodeId = undefined;
      }
      if ((nodeIds || []).indexOf(lastModifiedNodeId) >= 0) {
        lastModifiedNodeId = undefined;
        let lastModifiedNode = me.props.viewDataProvider.getNode().reduce((lastNode, currentNode) => {
          let lastTime = lastNode ? (lastNode.updateTime || lastNode.linkTime) : undefined;
          let currentTime = currentNode.updateTime || currentNode.linkTime;
          if (lastTime && lastTime >= currentTime) {
            return lastNode;
          } else {
            return currentNode;
          }
        }, undefined);
        if (lastModifiedNode) {
          lastModifiedNodeId = lastModifiedNode.id;
        }
      }
      me.setState({currentSelectedNodeId, lastSelectedNodeId, lastModifiedNodeId});
    });

    me.props.bus.sub(me, 'teamwork', 'member.list.success', ({viewId, userList}) => {
      if (me.props.viewId !== viewId) return;
      me.memberMap = {};
      userList.forEach(userInfo => {
        me.memberMap[`user-${userInfo.userId}`] = userInfo;
      });
    });

    me.props.bus.subscribe(me, 'view', 'micro_service.component.loaded', ({viewId}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId) {
        me.props.bus.emit('view', 'micro_service.config.on_load', {
          type: 'my',
          start: 0,
          limit: 5,
          onSuccess: (myMicroServiceInfoList) => {
            me.setState({myMicroServiceInfoList});
          },
        });
      }
    });

    me.props.bus.sub(me, 'toolbar', 'view.explore.toolbar.relationship_clues', ({viewId}) => {
      //if (me.props.viewId !== viewId) return;
      if (!me.state.currentSelectedNodeId && !me.state.lastSelectedNodeId && !me.state.lastModifiedNodeId) {
        message.info('请先选择一个节点');
        return;
      }
      let nodeId = me.state.currentSelectedNodeId || me.state.lastSelectedNodeId || me.state.lastModifiedNodeId;
      me.smartRelationshipClues(nodeId);
    });

    me.props.bus.sub(me, 'toolbar', 'view.explore.toolbar.search.user.allView', () => {
      me.smartSearchUserInAllView();
    });
    
  }

  componentWillUnmount() {
    let me = this;

    me.props.bus.remove(me);
  }

  render() {
    let me = this;

    /*let expandVisible = true, expandUsable = false, evaluationAndForecastVisible = true,
      evaluationAndForecastUsable = false;

    // 权限放开
    let permission = atob(me.props.userInfo.simplePermission || '');
    if (permission.length > 5) {
      // permissionArray[5]: b'10100000' - 基本发现/人脉/指标[可见,可用],分析预测[可见,可用]
      let permissionCode = permission.charCodeAt(5);
      expandVisible = (permissionCode & 0x80) > 0;
      expandUsable = (permissionCode & 0x40) > 0;
      evaluationAndForecastVisible = (permissionCode & 0x20) > 0;
      evaluationAndForecastUsable = (permissionCode & 0x10) > 0;
    }*/

    // noinspection SpellCheckingInspection
    return me.props.hideMicroservicesBoxFlag?(''):(
      <React.Fragment>
        <div className={`${style['frame']} dark-theme`} style={{visibility: SysUIConfig.ExploreToolBar.visibility}}>
          <div className={style['inner']}>
            <Tooltip
              title={(
                <div>
                  <div>打开样式类微服务</div>
                </div>
              )}
              trigger={'hover'}
              placement={'left'}
            >
              <Button className={style['cool-button']}
                onClick={() => {
                  me.props.bus.emit('view', 'micro_service.config.show_market_drawer',
                    {projectId: me.props.projectId,activeTag:'图谱样式'});
                }}>
                <Icon type={IconTypes.ICON_FONT} name="icon-dashboard1" className={style['icon-operate']}/>  样式
              </Button>
            </Tooltip>
            <Tooltip
              title={(
                <div>
                  <div>打开数据类微服务</div>
                </div>
              )}
              trigger={'hover'}
              placement={'left'}
            >
              <Button
                shape={'circle'}
                onClick={() => {
                  me.props.bus.emit('view', 'micro_service.config.show_market_drawer',
                    {projectId: me.props.projectId,activeTag:'图谱数据'});
                }}
              >
                <Icon name={'table'} className={style['icon-operate']}/> 数据
              </Button>
            </Tooltip>
            <Tooltip
              title={(
                <div>
                  <div>打开专题报告类微服务</div>
                </div>
              )}
              trigger={'hover'}
              placement={'left'}
            >
              <Button
                shape={'circle'}
                onClick={() => {
                  me.props.bus.emit('view', 'micro_service.config.show_market_drawer',
                    {projectId: me.props.projectId,activeTag:'专题报告'});
                }}
              >
                <Icon component={presentationSvg} alt={'icon'} className={style['icon-operate']}/>
                 报告
              </Button>
            </Tooltip>
            <Tooltip
              title={(
                <div>
                  <div>打开事件类微服务</div>
                </div>
              )}
              trigger={'hover'}
              placement={'left'}
            >
              <Button
                shape={'circle'}
                onClick={() => {
                  me.props.bus.emit('view', 'micro_service.config.show_market_drawer',
                    {projectId: me.props.projectId,activeTag:'图谱事件'});
                }}
              >
                <Icon name={'thunderbolt'} className={style['icon-operate']}/> 事件
              </Button>
            </Tooltip>
            <Tooltip
              title={(
                <div>
                  <div>打开任务类微服务</div>
                </div>
              )}
              trigger={'hover'}
              placement={'left'}
            >
              <Button
                shape={'circle'}
                onClick={() => {
                  me.props.bus.emit('view', 'micro_service.config.show_market_drawer',
                    {projectId: me.props.projectId,activeTag:'图谱任务'});
                }}
              >
                <Icon name={'icon-todo-list'} type={IconTypes.ICON_FONT} className={style['icon-operate']}/> 任务
              </Button>
            </Tooltip>
            <Tooltip
              title={(
                <div>
                  <div>打开洞察类微服务</div>
                </div>
              )}
              trigger={'hover'}
              placement={'left'}
            >
              <Button
                shape={'circle'}
                onClick={() => {
                  me.props.bus.emit('view', 'micro_service.config.show_market_drawer',
                    {projectId: me.props.projectId,activeTag:'图谱洞察'});
                }}
              >
                <Icon name={'line-chart'} className={style['icon-operate']}/> 洞察
              </Button>
            </Tooltip>
            <Tooltip
              title={(
                <div>
                  <div>打开分享类微服务</div>
                </div>
              )}
              trigger={'hover'}
              placement={'left'}
            >
              <Button className={style['cool-button']}
                onClick={() => {
                  me.props.bus.emit('view', 'micro_service.config.show_market_drawer',
                    {projectId: me.props.projectId,activeTag:'图谱分享'});
                }}>
                <Icon type={IconTypes.ICON_FONT} name="icon-fenxiang" className={style['icon-operate']}/> 分享
              </Button>
            </Tooltip>
            {/* 基本区 */}
            {/*
            <Tooltip
              title={(
                <div>
                  <div>打开微服务</div>
                </div>
              )}
              trigger={'hover'}
              placement={'left'}
            >
              <Button
                shape={'circle'}
                onClick={() => {
                  me.props.bus.emit('view', 'micro_service.config.show_market_drawer',
                    {projectId: me.props.projectId});
                }}
              >
                <Icon name={'icon-project-service'} type={IconTypes.ICON_FONT} />
              </Button>
            </Tooltip>
            <hr />
            {
              [...me.state.myMicroServiceInfoList, undefined, undefined, undefined, undefined, undefined].slice(0, 5).map((info, idx) => info ? (
                <Tooltip
                  title={(
                    <div>
                      <div style={{fontSize: '1.2rem'}}>{info.title}</div>
                      <div style={{fontSize: '0.75rem'}}>{info.shortDescription}</div>
                    </div>
                  )}
                  trigger={'hover'}
                  placement={'left'}
                  key={`micro_service-btn-${idx}`}
                >
                  <Button
                    shape={'circle'}
                    onClick={() => me.props.bus.emit('view', 'micro_service.service.show_modal',
                      {viewId: me.props.viewDataProvider.viewId, serviceId: info.id})}
                  >
                    <Icon name={info.iconId || 'icon-micro-service'} type={IconTypes.ICON_FONT} />
                  </Button>
                </Tooltip>
              ) : (
                <Tooltip
                  title={(
                    <div>
                      <div>常用微服务{idx + 1}</div>
                    </div>
                  )}
                  trigger={'hover'}
                  placement={'left'}
                  key={`micro_service-btn-${idx}`}
                >
                  <Button
                    shape={'circle'}
                    style={{cursor: 'not-allowed'}}
                  >
                    <Icon name={'icon-micro-service'} type={IconTypes.ICON_FONT} />
                  </Button>
                </Tooltip>
              ))
            }
            {
              me.props.projectId === 'dev' ? (
                <>
                  <hr />
                  <Tooltip
                    title={(
                      <div>
                        <div>发现用户</div>
                        <div>哪些用户也关注类似节点</div>
                      </div>
                    )}
                    trigger='hover'
                    placement="left"
                  >
                    <Button
                      shape={'circle'}
                      onClick={() => me.smartSearchUserInAllView()}
                      //disabled={!expandUsable}
                    >
                      <Icon name={'icon-user-find'} type={IconTypes.ICON_FONT} />
                    </Button>
                  </Tooltip>
                  <Tooltip
                    title={(
                      <div>
                        <div>发现活动</div>
                        <div>搜索该节点的更多交互活动</div>
                      </div>
                    )}
                    trigger='hover'
                    placement="left"
                  >
                    <Button
                      shape={'circle'}
                      onClick={() => me.onExplore('normal')}
                      //disabled={!expandUsable}
                    >
                      <Icon name={'icon-node-activity'} type={IconTypes.ICON_FONT} />
                    </Button>
                  </Tooltip>
                  <Tooltip
                    title={(
                      <div>
                        <div>关系结构联想</div>
                        <div>查找该节点的更多关联结构</div>
                      </div>
                    )}
                    trigger='hover'
                    placement="left"
                  >
                    <Button
                      shape={'circle'}
                      onClick={() => {
                        if (!me.state.currentSelectedNodeId) {
                          message.info('请先选择一个节点');
                          return;
                        }
                        me.props.bus.emit('relation', 'node.smart_search_graph_in_all_view.do',
                          {nodeId: me.state.currentSelectedNodeId});
                      }}
                      //disabled={!expandUsable}
                    >
                      <Icon name={'icon-node-action'} type={IconTypes.ICON_FONT} />
                    </Button>
                  </Tooltip>
                  <Tooltip
                    title={(
                      <div>
                        <div>左右组词联想</div>
                        <div>查看该节点的更多扩展词语</div>
                      </div>
                    )}
                    trigger='hover'
                    placement="left"
                  >
                    <Button
                      shape={'circle'}
                      onClick={() => {
                        if (!me.state.currentSelectedNodeId) {
                          message.info('请先选择一个节点');
                          return;
                        }
                        me.props.bus.emit('relation', 'node.expand_word_by_current.do',
                          {nodeId: me.state.currentSelectedNodeId});
                      }}
                      //disabled={!expandUsable}
                    >
                      <Icon name={'icon-expand'} type={IconTypes.ICON_FONT} />
                    </Button>
                  </Tooltip>
                </>
              ) : undefined
            }
            <hr />
            */}
            {/* 专用区 
            <ExploreButtonCompany viewDataProvider={me.props.viewDataProvider} bus={me.props.bus} />
            <Tooltip
              title={'人物关联检索'}
              trigger='hover'
              placement="left"
            >
              <Button
                shape={'circle'}
                onClick={() => {
                  if (!me.state.currentSelectedNodeId && !me.state.lastSelectedNodeId && !me.state.lastModifiedNodeId) {
                    message.info('请先选择一个节点');
                    return;
                  }
                  let nodeId = me.state.currentSelectedNodeId || me.state.lastSelectedNodeId || me.state.lastModifiedNodeId;
                  me.props.bus.emit('network', 'node.show_extended_action_viewer', {
                    name: '人物关联检索',
                    url: `https://subapps.joinmap.ai/person/#/?view_id=${me.props.viewDataProvider.viewId}&node_id=${nodeId}`,
                    userToken: true,
                  });
                }}
              >
                <Icon {...getNodeIconByType(NODE_TYPE_TALENT)} />
              </Button>
            </Tooltip>
            {
              me.props.projectId === 'dev' ? (
                <ExploreButtonTalent
                  viewDataProvider={me.props.viewDataProvider}
                  userInfo={me.props.userInfo}
                  bus={me.props.bus}
                />
              ) : undefined
            }
            <hr />
            */}
            {/* 任务区 
            {{
              //evaluationAndForecastVisible ? (
                }
                <Tooltip title={'关系线索'} trigger='hover' placement="left">
                  <Button
                    shape={'circle'}
                    //onClick={me.onEvaluationAndForecast}
                    //disabled={!evaluationAndForecastUsable}
                    onClick={() => {
                      if (!me.state.currentSelectedNodeId && !me.state.lastSelectedNodeId && !me.state.lastModifiedNodeId) {
                        message.info('请先选择一个节点');
                        return;
                      }
                      let nodeId = me.state.currentSelectedNodeId || me.state.lastSelectedNodeId || me.state.lastModifiedNodeId;
                      me.smartRelationshipClues(nodeId);
                    }}
                  >
                    <img src={relationCluePngIcon} style={{width:'2em'}}/>
                  </Button>
                </Tooltip>
              {) : null
            }}
            <Tooltip title={'待办事项'} trigger='hover' placement="left">
              <Button
                shape={'circle'}
                onClick={me.onShowViewTodoList}
              >
                <Icon name={'icon-todo-list'} type={IconTypes.ICON_FONT} />
              </Button>
            </Tooltip>
            */}
          </div>
        </div>
        <ExploreSmartSearchGraphModal
          targetNode={me.state.smartSearchGraphTargetNode}
          graphList={me.state.smartSearchGraphList}
          viewDataProvider={me.props.viewDataProvider}
          visible={me.state.showSmartSearchGraphModal}
          onClose={() => me.setState({showSmartSearchGraphModal: false})}
        />
        <ExploreExpandWordByNodeModal
          targetNode={me.state.expandWordByNodeTargetNode}
          wordGroups={me.state.expandWordByNodeWordGroups}
          viewDataProvider={me.props.viewDataProvider}
          visible={me.state.showExpandWordByNodeModal}
          onClose={() => me.setState({showExpandWordByNodeModal: false})}
        />
        <ExploreCompany userId={me.props.userInfo.userId} viewDataProvider={me.props.viewDataProvider} bus={me.props.bus} />
        <ExploreTalent userId={me.props.userInfo.userId} viewDataProvider={me.props.viewDataProvider} bus={me.props.bus} />
        <MicroService
          userId={me.props.userInfo.userId}
          viewDataProvider={me.props.viewDataProvider}
          currentNodeId={me.state.currentSelectedNodeId}
          bus={me.props.bus}
        />
        {me.state.showSmartRelationshipCluesModal && <ExploreSmartRelationshipCluesModal
          targetNode={me.state.smartRelationshipCluesTargetNode}
          graphList={me.state.smartRelationshipCluesList}
          viewDataProvider={me.props.viewDataProvider}
          visible={me.state.showSmartRelationshipCluesModal}
          userId={me.props.userInfo.userId}
          onClose={() => me.setState({showSmartRelationshipCluesModal: false})}
          memberMap={me.memberMap}
        />}
      </React.Fragment>
    );
  }
}

ExploreToolBar.defaultProps = {
  bus: PB,
};

ExploreToolBar.propTypes = {
  bus: PropTypes.instanceOf(SimplePB),
  userInfo: PropTypes.object.isRequired,
  viewDataProvider: PropTypes.instanceOf(ViewDataProvider).isRequired,
  projectId: PropTypes.string,
  hideMicroservicesBoxFlag: PropTypes.bool
};

export default ExploreToolBar;
