/*
 * @Author: Carr.s
 * @Date: 2022-07-22 10:08:27
 * @LastEditTime: 2022-07-22 15:54:30
 * @LastEditors: Carr.s
 * @Description: 节点的子弹评估, 新增表单
 * @Copyright by joinmap
 */
import React from "react";
import PropTypes from "prop-types";
import {
  Modal,
  Button,
  Popover,
  Radio,
  message,
  Form,
  Select,
  Row,
  Col,
  Input,
  Slider,
  InputNumber,
} from "antd";
import PB, { SimplePB } from "@/libs/simplePB";
import Icon from "@/components/common/common.icon";
import { IconTypes } from "@/constants/common";
import nodeStyle from "@/style/components/mainView/node.less";
import style from "@/style/common/node/common.node.assess.less";
// 数据流
import NodeDataProvider from "@/components/common/dataProvider/common.dataProvider.node";
import NodeAssessFieldList from "@/components/common/node/assess/common.node.assess.field.list";

class NodeAssessFormAdd extends React.Component {
  state = {
    shortTerm_line1: 0,
    shortTerm_line2: 0,
    shortTerm_line3: 0,
    midTerm_line1: 0,
    midTerm_line2: 0,
    midTerm_line3: 0,
  };

  componentDidMount() {
    console.log(
      "🚀 ~ file: common.node.assess.form.add.js ~ line 33 ~ NodeAssessFormAdd ~ componentDidMount",
      this.props
    );
    let me = this;

    // node assess 的数据处理（相当于 node todo 的logic）
    this.props.bus.sub(
      this,
      "node_assess",
      "save",
      ({ viewId, node, params = {} }) => {
        me.props.form.validateFields((err) => {
          console.log(
            "🚀 ~ file: common.node.assess.form.add.js ~ line 44 ~ NodeAssessFormAdd ~ me.props.form.validateFields ~ err",
            err
          );
          if (err) {
            message.warning("请按要求填写评估项");
            return false;
          } else {
            const {
              shortTerm_line1,
              shortTerm_line2,
              shortTerm_line3,
              midTerm_line1,
              midTerm_line2,
              midTerm_line3,
            } = me.state;
            let formData = me.props.form.getFieldsValue();
            console.log(
              "🚀 ~ file: common.node.assess.form.add.js ~ line 55 ~ NodeAssessFormAdd ~ me.props.form.validateFields ~ formData",
              formData
            );
            let data = {};
            Object.keys(formData).forEach((key) => {
              // data[key] = Object.values(formData[key]).join("\n");
              data[key] = JSON.stringify(formData[key]);
            });

            // 短期 中期 预测数据
            data["shortTerm"] = JSON.stringify({
              line1: shortTerm_line1,
              line2: shortTerm_line2,
              line3: shortTerm_line3,
            });

            data["midTerm"] = JSON.stringify({
              line1: midTerm_line1,
              line2: midTerm_line2,
              line3: midTerm_line3,
            });
            console.log(
              "🚀 ~ file: common.node.assess.form.add.js ~ line 60 ~ NodeAssessFormAdd ~ me.props.form.validateFields ~ data",
              data
            );

            // return false;

            NodeDataProvider.createNodeAssess(viewId, node.id, data, params)
              .then((res_) => {
                // 判断是否创建成功 => 显示列表?
                // let res_ = {
                //   code: 0,
                //   msg: "",
                //   data: "",
                // };
                if (res_.code === 0) {
                  message.success("保存子弹评估成功");

                  // 返回中包含 id，跳转并高亮刚建的评估
                  let assessId = 1;
                  me.props.bus.emit("node_assess", "save.success", assessId);
                } else {
                  message.warning("保存子弹评估失败，请稍后重试");
                }
              })
              .catch((e) => {
                console.log(
                  "🚀 ~ file: common.node.assess.modal.js ~ line 81 ~ NodeAssessModal ~ NodeDataProvider.creatNodeAssess ~ e",
                  e
                );
                // 创建失败给提示
                message.warning("保存子弹评估出错，请稍后重试");
              });
          }
        });
        return false;
      }
    );
  }

  componentWillUnmount() {
    this.props.bus.remove(this);
  }

  onShortTermLine1Change = (value) => {
    this.setState({
      shortTerm_line1: value,
    });
  };
  onShortTermLine2Change = (value) => {
    this.setState({
      shortTerm_line2: value,
    });
  };
  onShortTermLine3Change = (value) => {
    this.setState({
      shortTerm_line3: value,
    });
  };

  onMidTermLine1Change = (value) => {
    this.setState({
      midTerm_line1: value,
    });
  };
  onMidTermLine2Change = (value) => {
    this.setState({
      midTerm_line2: value,
    });
  };
  onMidTermLine3Change = (value) => {
    this.setState({
      midTerm_line3: value,
    });
  };

  render() {
    let me = this;
    const {
      shortTerm_line1,
      shortTerm_line2,
      shortTerm_line3,
      midTerm_line1,
      midTerm_line2,
      midTerm_line3,
    } = this.state;
    const {
      userInfo,
      node,
      onSubmit,
      assessList,
      loadAssessList,
      height,
      assessFieldsTotal,
    } = this.props;
    const { getFieldDecorator } = this.props.form;
    const itemStyle = {
      marginBottom: 5,
      paddingBottom: 0,
      marginLeft: 28,
    };
    const iconOuterStyle = {
      display: "flex",
      alignItems: "center",
      marginBottom: 5,
    };
    const iconStyle = {
      width: 22,
      height: 22,
    };
    const iconLabel = {
      marginLeft: 5,
      fontSize: "1.15rem",
      fontWeight: 650,
      borderBottom: "3px solid #00BFFF",
      // borderBottom: "3px solid rgb(237,124,49)",
    };
    const sliderTextStyle = {
      fontWeight: 500,
      marginTop: 15,
    };

    const moreStyle = {
      flex: 1,
      display: "flex",
      justifyContent: "flex-end",
      // position: "relative",
    };

    const moreTipTitleStyle = {
      fontSize: "1.1rem",
      fontWeight: 600,
      marginTop: "5px",
      marginBottom: "5px",
      marginLeft: 5,
    };
    return (
      <div
        className={"scrollbar-18"}
        style={{
          height: "100%",
          // backgroundColor: "rgba(0,0,0,0)",
          overflowX: "hidden",
          overflowY: "auto",
        }}
      >
        <Form id={"nodeEditForm"} layout={"vertical"}>
          <div style={{ marginBottom: 20 }}>
            <div style={iconOuterStyle}>
              <div style={{ flex: 1 }}>
                <img src="/assets/icons/assess_1.png" style={iconStyle} />
                <span style={iconLabel}>目标要点</span>
                <span>：</span>
              </div>
              <div style={moreStyle}>
                {assessFieldsTotal["mainPoints"] > 0 ? (
                  <Popover
                    arrowPointAtCenter={false}
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    placement="left"
                    title={
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src="/assets/icons/assess_1.png"
                          style={iconStyle}
                        />
                        <span style={moreTipTitleStyle}>目标要点</span>
                        <span>：</span>
                      </div>
                    }
                    content={
                      <NodeAssessFieldList
                        field="mainPoints"
                        assessList={assessList}
                        loadAssessList={loadAssessList}
                        height={height}
                      />
                    }
                    trigger="click"
                    overlayClassName={style["assess-field-tip"]}
                  >
                    <a href="javascript:void(0);">
                      已有{assessFieldsTotal["mainPoints"]}条
                      <span style={{ marginLeft: 5 }}>点击查看</span>
                    </a>
                  </Popover>
                ) : (
                  <span
                    href="javascript:void(0);"
                    style={{ color: "gray", cursor: "not-allowed" }}
                  >
                    已有0条 <span style={{ marginLeft: 5 }}>点击查看</span>
                  </span>
                )}
              </div>
            </div>

            <Form.Item style={itemStyle}>
              {getFieldDecorator("mainPoints.line1", {
                rules: [
                  {
                    required: true,
                    message: "请输入目标要点的信息",
                  },
                ],
              })(
                <Input
                  placeholder="输入目标要点"
                  addonBefore="1"
                  tabindex="1"
                />
              )}
            </Form.Item>
            <Form.Item style={itemStyle}>
              {getFieldDecorator("mainPoints.line2")(
                <Input
                  placeholder="输入目标要点"
                  addonBefore="2"
                  tabindex="2"
                />
              )}
            </Form.Item>
            <Form.Item style={itemStyle}>
              {getFieldDecorator("mainPoints.line3")(
                <Input
                  placeholder="输入目标要点"
                  addonBefore="3"
                  tabindex="3"
                />
              )}
            </Form.Item>
            <Form.Item style={itemStyle}>
              {getFieldDecorator("mainPoints.line4")(
                <Input
                  placeholder="输入目标要点"
                  addonBefore="4"
                  tabindex="4"
                />
              )}
            </Form.Item>
            <Form.Item style={itemStyle}>
              {getFieldDecorator("mainPoints.line5")(
                <Input
                  placeholder="输入目标要点"
                  addonBefore="5"
                  tabindex="5"
                />
              )}
            </Form.Item>
          </div>

          <div style={{ marginBottom: 20 }}>
            <div style={iconOuterStyle}>
              <div style={{ flex: 1 }}>
                <img src="/assets/icons/assess_2.png" style={iconStyle} />
                <span style={iconLabel}>有利因素</span>
                <span>：</span>
              </div>
              <div style={moreStyle}>
                {assessFieldsTotal["advantage"] > 0 ? (
                  <Popover
                    arrowPointAtCenter={false}
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    placement="left"
                    title={
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src="/assets/icons/assess_2.png"
                          style={iconStyle}
                        />
                        <span style={moreTipTitleStyle}>有利因素</span>
                        <span>：</span>
                      </div>
                    }
                    content={
                      <NodeAssessFieldList
                        field="advantage"
                        assessList={assessList}
                        loadAssessList={loadAssessList}
                        height={height}
                      />
                    }
                    trigger="click"
                    overlayClassName={style["assess-field-tip"]}
                  >
                    <a href="javascript:void(0);">
                      已有{assessFieldsTotal["advantage"]}条
                      <span style={{ marginLeft: 5 }}>点击查看</span>
                    </a>
                  </Popover>
                ) : (
                  <span
                    href="javascript:void(0);"
                    style={{ color: "gray", cursor: "not-allowed" }}
                  >
                    已有0条
                    <span style={{ marginLeft: 5 }}>点击查看</span>
                  </span>
                )}
              </div>
            </div>
            <Form.Item style={itemStyle}>
              {getFieldDecorator("advantage.line1")(
                <Input
                  placeholder="输入有利因素"
                  addonBefore="1"
                  tabindex="6"
                />
              )}
            </Form.Item>
            <Form.Item style={itemStyle}>
              {getFieldDecorator("advantage.line2")(
                <Input
                  placeholder="输入有利因素"
                  addonBefore="2"
                  tabindex="7"
                />
              )}
            </Form.Item>
            <Form.Item style={itemStyle}>
              {getFieldDecorator("advantage.line3")(
                <Input
                  placeholder="输入有利因素"
                  addonBefore="3"
                  tabindex="8"
                />
              )}
            </Form.Item>
            <Form.Item style={itemStyle}>
              {getFieldDecorator("advantage.line4")(
                <Input
                  placeholder="输入有利因素"
                  addonBefore="4"
                  tabindex="9"
                />
              )}
            </Form.Item>
            <Form.Item style={itemStyle}>
              {getFieldDecorator("advantage.line5")(
                <Input
                  placeholder="输入有利因素"
                  addonBefore="5"
                  tabindex="10"
                />
              )}
            </Form.Item>
          </div>

          <div style={{ marginBottom: 20 }}>
            <div style={iconOuterStyle}>
              <div style={{ flex: 1 }}>
                <img src="/assets/icons/assess_3.png" style={iconStyle} />
                <span style={iconLabel}>不利因素</span>
                <span>：</span>
              </div>
              <div style={moreStyle}>
                {assessFieldsTotal["disadvantage"] > 0 ? (
                  <Popover
                    arrowPointAtCenter={false}
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    placement="left"
                    title={
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src="/assets/icons/assess_3.png"
                          style={iconStyle}
                        />
                        <span style={moreTipTitleStyle}>不利因素</span>
                        <span>：</span>
                      </div>
                    }
                    content={
                      <NodeAssessFieldList
                        field="disadvantage"
                        assessList={assessList}
                        loadAssessList={loadAssessList}
                        height={height}
                      />
                    }
                    trigger="click"
                    overlayClassName={style["assess-field-tip"]}
                  >
                    <a href="javascript:void(0);">
                      已有{assessFieldsTotal["disadvantage"]}条
                      <span style={{ marginLeft: 5 }}>点击查看</span>{" "}
                    </a>
                  </Popover>
                ) : (
                  <span
                    href="javascript:void(0);"
                    style={{ color: "gray", cursor: "not-allowed" }}
                  >
                    已有0条
                    <span style={{ marginLeft: 5 }}>点击查看</span>{" "}
                  </span>
                )}
              </div>
            </div>
            <Form.Item style={itemStyle}>
              {getFieldDecorator("disadvantage.line1")(
                <Input
                  placeholder="输入不利因素"
                  addonBefore="1"
                  tabindex="11"
                />
              )}
            </Form.Item>
            <Form.Item style={itemStyle}>
              {getFieldDecorator("disadvantage.line2")(
                <Input
                  placeholder="输入不利因素"
                  addonBefore="2"
                  tabindex="12"
                />
              )}
            </Form.Item>
            <Form.Item style={itemStyle}>
              {getFieldDecorator("disadvantage.line3")(
                <Input
                  placeholder="输入不利因素"
                  addonBefore="3"
                  tabindex="13"
                />
              )}
            </Form.Item>
            <Form.Item style={itemStyle}>
              {getFieldDecorator("disadvantage.line4")(
                <Input
                  placeholder="输入不利因素"
                  addonBefore="4"
                  tabindex="14"
                />
              )}
            </Form.Item>
            <Form.Item style={itemStyle}>
              {getFieldDecorator("disadvantage.line5")(
                <Input
                  placeholder="输入不利因素"
                  addonBefore="5"
                  tabindex="15"
                />
              )}
            </Form.Item>
          </div>
          {/* 
          <div style={{ marginBottom: "12px" }}>
            <div style={iconOuterStyle}>
              <img src="/assets/icons/assess_4.png" style={iconStyle} />
              <span style={iconLabel}>近期预判</span>
              <span>：</span>
            </div>
            <Form.Item style={itemStyle}>
              {getFieldDecorator("shortTerm.line1")(
                <Input
                  placeholder="1天内达成可能性"
                  addonBefore="1"
                  tabindex="16"
                />
              )}
            </Form.Item>
            <Form.Item style={itemStyle}>
              {getFieldDecorator("shortTerm.line2")(
                <Input
                  placeholder="3天内达成可能性"
                  addonBefore="2"
                  tabindex="17"
                />
              )}
            </Form.Item>
            <Form.Item style={itemStyle}>
              {getFieldDecorator("shortTerm.line3")(
                <Input
                  placeholder="7天内达成可能性"
                  addonBefore="3"
                  tabindex="18"
                />
              )}
            </Form.Item>
          </div> */}

          <div style={{ marginBottom: 20 }}>
            <div style={iconOuterStyle}>
              <div style={{ flex: 1 }}>
                <img src="/assets/icons/assess_4.png" style={iconStyle} />
                <span style={iconLabel}>近期预判</span>
                <span>：</span>
              </div>
              <div style={moreStyle}>
                {assessFieldsTotal["shortTerm"] > 0 ? (
                  <Popover
                    arrowPointAtCenter={false}
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    placement="left"
                    title={
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src="/assets/icons/assess_4.png"
                          style={iconStyle}
                        />
                        <span style={moreTipTitleStyle}>近期预判</span>
                        <span>：</span>
                      </div>
                    }
                    content={
                      <NodeAssessFieldList
                        field="shortTerm"
                        assessList={assessList}
                        loadAssessList={loadAssessList}
                        height={height}
                      />
                    }
                    trigger="click"
                    overlayClassName={style["assess-field-tip"]}
                  >
                    <a href="javascript:void(0);">
                      已有{assessFieldsTotal["shortTerm"]}条
                      <span style={{ marginLeft: 5 }}>点击查看</span>{" "}
                    </a>
                  </Popover>
                ) : (
                  <span
                    href="javascript:void(0);"
                    style={{ color: "gray", cursor: "not-allowed" }}
                  >
                    已有0条
                    <span style={{ marginLeft: 5 }}>点击查看</span>{" "}
                  </span>
                )}
              </div>
            </div>

            <div style={{ ...itemStyle, ...sliderTextStyle }}>
              1、一天内达成可能性：
            </div>
            <Row style={itemStyle}>
              <Col span={20}>
                <Slider
                  value={shortTerm_line1}
                  step={5}
                  marks={{
                    5: "很不可能",
                    30: "不太可能",
                    50: "不确定",
                    70: "很可能",
                    95: "非常可能",
                  }}
                  tooltipVisible={false}
                  onChange={this.onShortTermLine1Change}
                />
              </Col>
              <Col span={4}>
                <InputNumber
                  // defaultValue={30}
                  value={shortTerm_line1}
                  min={0}
                  max={100}
                  step={5}
                  formatter={(value) => `${value}%`}
                  parser={(value) => value.replace("%", "")}
                  onChange={this.onShortTermLine1Change}
                  style={{ marginTop: 5, width: 70, float: "right" }}
                />
                <div style={{ clear: "both" }} />
              </Col>
            </Row>

            <div style={{ ...itemStyle, ...sliderTextStyle }}>
              2、三天内达成可能性：
            </div>
            <Row style={itemStyle}>
              <Col span={20}>
                <Slider
                  value={shortTerm_line2}
                  step={5}
                  marks={{
                    5: "很不可能",
                    30: "不太可能",
                    50: "不确定",
                    70: "很可能",
                    95: "非常可能",
                  }}
                  tooltipVisible={false}
                  onChange={this.onShortTermLine2Change}
                />
              </Col>
              <Col span={4}>
                <InputNumber
                  // defaultValue={30}
                  value={shortTerm_line2}
                  min={0}
                  max={100}
                  step={5}
                  formatter={(value) => `${value}%`}
                  parser={(value) => value.replace("%", "")}
                  onChange={this.onShortTermLine2Change}
                  style={{ marginTop: 5, width: 70, float: "right" }}
                />
                <div style={{ clear: "both" }} />
              </Col>
            </Row>

            <div style={{ ...itemStyle, ...sliderTextStyle }}>
              3、七天内达成可能性：
            </div>
            <Row style={itemStyle}>
              <Col span={20}>
                <Slider
                  value={shortTerm_line3}
                  step={5}
                  marks={{
                    5: "很不可能",
                    30: "不太可能",
                    50: "不确定",
                    70: "很可能",
                    95: "非常可能",
                  }}
                  tooltipVisible={false}
                  onChange={this.onShortTermLine3Change}
                />
              </Col>
              <Col span={4}>
                <InputNumber
                  // defaultValue={30}
                  value={shortTerm_line3}
                  min={0}
                  max={100}
                  step={5}
                  formatter={(value) => `${value}%`}
                  parser={(value) => value.replace("%", "")}
                  onChange={this.onShortTermLine3Change}
                  style={{ marginTop: 5, width: 70, float: "right" }}
                />
                <div style={{ clear: "both" }} />
              </Col>
            </Row>
          </div>

          <div style={{ marginBottom: "15px" }}>
            <div style={iconOuterStyle}>
              <div style={{ flex: 1 }}>
                <img src="/assets/icons/assess_5.png" style={iconStyle} />
                <span style={iconLabel}>中期预判</span>
                <span>：</span>
              </div>
              <div style={moreStyle}>
                {assessFieldsTotal["midTerm"] > 0 ? (
                  <Popover
                    arrowPointAtCenter={false}
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    placement="left"
                    title={
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src="/assets/icons/assess_5.png"
                          style={iconStyle}
                        />
                        <span style={moreTipTitleStyle}>中期预判</span>
                        <span>：</span>
                      </div>
                    }
                    content={
                      <NodeAssessFieldList
                        field="midTerm"
                        assessList={assessList}
                        loadAssessList={loadAssessList}
                        height={height}
                      />
                    }
                    trigger="click"
                    overlayClassName={style["assess-field-tip"]}
                  >
                    <a href="javascript:void(0);">
                      已有{assessFieldsTotal["midTerm"]}条
                      <span style={{ marginLeft: 5 }}>点击查看</span>{" "}
                    </a>
                  </Popover>
                ) : (
                  <span
                    href="javascript:void(0);"
                    style={{ color: "gray", cursor: "not-allowed" }}
                  >
                    已有0条
                    <span style={{ marginLeft: 5 }}>点击查看</span>{" "}
                  </span>
                )}
              </div>
            </div>

            <div style={{ ...itemStyle, ...sliderTextStyle }}>
              1、一个月内达成可能性：
            </div>
            <Row style={itemStyle}>
              <Col span={20}>
                <Slider
                  value={midTerm_line1}
                  step={5}
                  marks={{
                    5: "很不可能",
                    30: "不太可能",
                    50: "不确定",
                    70: "很可能",
                    95: "非常可能",
                  }}
                  tooltipVisible={false}
                  onChange={this.onMidTermLine1Change}
                />
              </Col>
              <Col span={4}>
                <InputNumber
                  // defaultValue={30}
                  value={midTerm_line1}
                  min={0}
                  max={100}
                  step={5}
                  formatter={(value) => `${value}%`}
                  parser={(value) => value.replace("%", "")}
                  onChange={this.onMidTermLine1Change}
                  style={{ marginTop: 5, width: 70, float: "right" }}
                />
                <div style={{ clear: "both" }} />
              </Col>
            </Row>

            <div style={{ ...itemStyle, ...sliderTextStyle }}>
              2、三个月内达成可能性：
            </div>
            <Row style={itemStyle}>
              <Col span={20}>
                <Slider
                  value={midTerm_line2}
                  step={5}
                  marks={{
                    5: "很不可能",
                    30: "不太可能",
                    50: "不确定",
                    70: "很可能",
                    95: "非常可能",
                  }}
                  tooltipVisible={false}
                  onChange={this.onMidTermLine2Change}
                />
              </Col>
              <Col span={4}>
                <InputNumber
                  // defaultValue={30}
                  value={midTerm_line2}
                  min={0}
                  max={100}
                  step={5}
                  formatter={(value) => `${value}%`}
                  parser={(value) => value.replace("%", "")}
                  onChange={this.onMidTermLine2Change}
                  style={{ marginTop: 5, width: 70, float: "right" }}
                />
                <div style={{ clear: "both" }} />
              </Col>
            </Row>

            <div style={{ ...itemStyle, ...sliderTextStyle }}>
              3、六个月内达成可能性：
            </div>
            <Row style={itemStyle}>
              <Col span={20}>
                <Slider
                  value={midTerm_line3}
                  step={5}
                  marks={{
                    5: "很不可能",
                    30: "不太可能",
                    50: "不确定",
                    70: "很可能",
                    95: "非常可能",
                  }}
                  tooltipVisible={false}
                  onChange={this.onMidTermLine3Change}
                />
              </Col>
              <Col span={4}>
                <InputNumber
                  // defaultValue={30}
                  value={midTerm_line3}
                  min={0}
                  max={100}
                  step={5}
                  formatter={(value) => `${value}%`}
                  parser={(value) => value.replace("%", "")}
                  onChange={this.onMidTermLine3Change}
                  style={{ marginTop: 5, width: 70, float: "right" }}
                />
                <div style={{ clear: "both" }} />
              </Col>
            </Row>
          </div>
        </Form>
      </div>
    );
  }
}

NodeAssessFormAdd.defaultProps = {
  bus: PB,
  userInfo: undefined,
  node: undefined,
  assessList: [],
  loadAssessList: false,
  height: 450,
  assessFieldsTotal: {
    mainPoints: 0,
    advantage: 0,
    disadvantage: 0,
    shortTerm: 0,
    midTerm: 0,
  },
};

NodeAssessFormAdd.propTypes = {
  bus: PropTypes.instanceOf(SimplePB),
  userInfo: PropTypes.object, // 当前用户信息
  node: PropTypes.object, // 评估对应的节点
  assessList: PropTypes.array, // 要显示的评估列表
  loadAssessList: PropTypes.bool, // 评估列表的请求状态
  height: PropTypes.number, // modal body的高度
  assessFieldsTotal: PropTypes.object, // 评估子项的有效个数
};
NodeAssessFormAdd = Form.create({})(NodeAssessFormAdd);
export default NodeAssessFormAdd;
