import _ from 'lodash';

export const statisticsByAmountRange = ({nodes, edges, sort, limit, cb}) => {
  let statisticsFn = (edges, cb) => {
    // statistics
    let nodeConnectionsMap = {}, pos = 0, batch, allNodeMap = {}, workFn = () => {
      batch = 0;
      while (batch < 1000 && pos < edges.length) {
        let e = edges[pos];
        if (nodeConnectionsMap[e.from] !== undefined && nodeConnectionsMap[e.to] !== undefined) {
          nodeConnectionsMap[e.from].push(e.to);
          nodeConnectionsMap[e.to].push(e.from);
        }
        batch++;
        pos++;
      }
      if (pos < edges.length) {
        setTimeout(workFn, 5);
      } else {
        let max = 0;
        Object.keys(nodeConnectionsMap).forEach(nodeId => {
          nodeConnectionsMap[nodeId] = _.uniq(nodeConnectionsMap[nodeId]);
          max = Math.max(max, nodeConnectionsMap[nodeId].length);
        });
        let result = _.fill(Array(Math.ceil(max / 5) + 1), undefined).map((v, idx) => ({
          label: idx === 0 ? '0' : `${5 * idx - 4} ~ ${5 * idx}`,
          rate: 0,
          nodes: [],
        }));
        Object.keys(nodeConnectionsMap).forEach(nodeId => {
          result[Math.ceil(nodeConnectionsMap[nodeId].length / 5)].rate++;
          result[Math.ceil(nodeConnectionsMap[nodeId].length / 5)].nodes.push(allNodeMap[nodeId]);
        });
        cb(result);
      }
    };
    nodes.forEach(n => {
      nodeConnectionsMap[n.id] = [];
      allNodeMap[n.id] = n;
    });
    workFn();
  };

  statisticsFn(edges, (result) => {
    let resultArray;

    // sort
    if (sort) {
      let asc = false;
      if (`${sort}`.toLowerCase() === 'asc') {
        asc = true;
      }
      resultArray = Object.values(result).sort((a, b) => {
        return asc ? a.nodes.length - b.nodes.length : b.nodes.length - a.nodes.length;
      }).map(((value, index) => ({...value, pos: index + 1})));
    } else {
      resultArray = Object.values(result).map(((value, index) => ({...value, pos: index + 1})));
    }

    // limit
    if (limit && limit > 0) {
      resultArray = resultArray.slice(0, parseInt(limit));
    }

    cb(resultArray);
  });
};