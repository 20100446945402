import React from 'react';
import PropTypes from 'prop-types';
import {message} from 'antd';

import PB, {SimplePB} from "@/libs/simplePB";

import NodeTodoLogic from "@/components/common/node/todo/common.node.todo.logic";
import NodeTodoListModal from "@/components/common/node/todo/common.node.todo.listModal";
import NodeTodoDetailModal from "@/components/common/node/todo/common.node.todo.detailModal";
import NodeTodoAssignToModal from "@/components/common/node/todo/common.node.todo.assignToModal";

class NodeTodo extends React.Component {
  state = {
    showListModal: false,
    showViewTask: false,
    viewInfo: undefined,

    showDetailModal: false,
    memberList: [],
    taskInfo: undefined,
    detailModalAction: 'view',
    showAssignToModal: false,
  };

  onModalClose = () => {
    let me = this;

    me.setState({showListModal: false});
  }

  componentDidMount() {
    let me = this;
    me.props.bus.sub(me, 'node_task', 'listModal.do_show', ({type}) => {
      if (type === 'view') {
        if (!me.state.viewInfo) {
          message.info('图谱信息尚未加载完毕，请稍后再试');
          return;
        }
      }
      me.setState({showListModal: true, showViewTask: (type === 'view')});
    });

    me.props.bus.sub(me, 'node_todo', 'detailModal.do_show', ({action, task, userInfo}) => {
      let cb = () => {
        if (action !== 'view') {
          // 加载图谱成员
          me.props.bus.emit('node_todo', 'memberList.do_load',
            {viewId: task['viewId'], cb: memberList => {if (memberList) me.setState({memberList})}})
        }
      };
      me.setState({showDetailModal: true, taskInfo: task, memberList: userInfo ? [userInfo] : [], detailModalAction: action}, cb);
    });

    me.props.bus.sub(me, 'node_todo', 'assignToModal.do_show', ({task, userInfo}) => {
      let cb = () => {
        // 加载图谱成员
        me.props.bus.emit('node_todo', 'memberList.do_load',
          {viewId: task['viewId'], cb: memberList => {if (memberList) me.setState({memberList})}})
      };
      me.setState({showAssignToModal: true, taskInfo: task, memberList: [userInfo]}, cb);
    });

    me.props.bus.sub(me, 'view', 'info.loaded', ({viewInfo}) => {
      me.setState({viewInfo});
    });

    me.props.bus.sub(me, 'node_todo',
      ['todo.created', 'todo.updated', 'todo.patched', 'todo.completed'],
      () => message.success('操作成功'));
  }

  componentWillUnmount() {
    this.props.bus.remove(this);
  }

  render() {
    let me = this;

    return (
      <React.Fragment>
        <NodeTodoLogic
          bus={me.props.bus}
          currentUserId={me.props.currentUserId}
        />
        <NodeTodoListModal
          viewInfo={me.state.viewInfo}
          userId={me.props.currentUserId}
          visible={me.state.showListModal}
          showViewTask={me.state.showViewTask}
          onClose={me.onModalClose}
        />
        <NodeTodoDetailModal
          task={me.state.taskInfo}
          action={me.state.detailModalAction}
          memberList={me.state.memberList}
          visible={me.state.showDetailModal}
          onClose={() => me.setState({showDetailModal: false})}
        />
        <NodeTodoAssignToModal
          taskId={me.state.taskInfo ? me.state.taskInfo.nodeTaskId : undefined}
          memberList={me.state.memberList}
          visible={me.state.showAssignToModal}
          onClose={() => me.setState({showAssignToModal: false})}
        />
      </React.Fragment>
    )
  }
}

NodeTodo.defaultProps = {
  bus: PB,
};

NodeTodo.propTypes = {
  bus: PropTypes.instanceOf(SimplePB),
  currentUserId: PropTypes.number.isRequired,
};

export default NodeTodo;