import React from 'react';
// import PropTypes from 'prop-types';
import {Layout} from "antd";
import {Route, Switch} from "react-router-dom";

import {Copyright} from '@/components/common/common.copyright';
import Loading from "@/components/common/common.loading";

import ExploreRelationMainView from '@/components/exploreRelationView/exploreRelation.main';

import style from '@/style/containers/exploreRelationView.less';
import {
  getNodeDisplayTitle,
  NODE_TYPE_TEXT,
  TYPE_FIELD_NAME,
} from "@/constants/vis.defaultDefine.1";
import ViewDataProvider from "@/components/common/dataProvider/common.dataProvider.view";
import NodeDataProvider from "@/components/common/dataProvider/common.dataProvider.node";
import Node from "@/libs/view/Node";

// 通过文本造假节点数据，目前都是人物节点
const getFakeNodeData = ({id, type}) => {
  return {fname: '', id, type, lev: 'gradeType2', meta: {preferredType: 2}};
};

class ExploreRelationView extends React.Component {
  /**
   * 中心节点
   *
   * @type {Node|undefined}
   */
  nodeInfo = undefined;

  viewDataProvider = new ViewDataProvider();

  getNodeFromUrl = () => {
    let me = this;
    if (me.props.match.params.urlData) {
      const params = JSON.parse(decodeURIComponent(escape(atob(me.props.match.params.urlData.replace(/\$/g, '/')))));
      return params['n'];
    } else {
      // 假数据
      return getFakeNodeData({id: me.props.match.params.nodeId, type: parseInt(me.props.match.params.nodeType)});
    }
  };

  componentDidMount() {
    let me = this;

    me.viewDataProvider.loadData(me.props.match.params.viewId, undefined, false).then(() => {
      // 加载图谱数据成功
      // 获取节点信息，成功后展示向导
      (
        parseInt(me.props.match.params.nodeType) === NODE_TYPE_TEXT ? (
          NodeDataProvider.loadNodeDetail(me.props.match.params.viewId, parseInt(me.props.match.params.nodeType),
            me.props.match.params.nodeId)
        ) : (
          NodeDataProvider.loadInsightDetailInfo(me.props.match.params.nodeId, parseInt(me.props.match.params.nodeType))
        )
      ).then(data => {
        if (!data) {
          data = me.getNodeFromUrl();
        }
        setTimeout(() => {
          // 无图标关注点节点使用默认样式
          if (
            data[TYPE_FIELD_NAME] === NODE_TYPE_TEXT && data['lev'] &&
            !['gradeD', 'gradeT', 'gradeU'].includes(data['lev']) &&
            !`${data['lev']}`.startsWith('gradeType')
          ) {
            data['lev'] = 'gradeD';
          }
          me.nodeInfo = new Node({...data, x: 0, y: 0, fixed: true});
          me.forceUpdate();
        }, 500);
      }).catch(error => {
        if (error.code === 10002) {
          // 无该节点信息，尝试通过洞察详情接口获取数据
          NodeDataProvider.loadInsightDetailInfo(me.props.match.params.nodeId, parseInt(me.props.match.params.nodeType))
            .then(
              /**
               * @param {Node|Object} data
               */
              data => {
                if (!data) {
                  data = me.getNodeFromUrl();
                }
                setTimeout(() => {
                  // 无图标关注点节点使用默认样式
                  if (
                    data[TYPE_FIELD_NAME] === NODE_TYPE_TEXT && data['lev'] &&
                    !['gradeD', 'gradeT', 'gradeU'].includes(data['lev']) &&
                    !`${data['lev']}`.startsWith('gradeType')
                  ) {
                    data['lev'] = 'gradeD';
                  }
                  me.nodeInfo = new Node({...data, x: 0, y: 0, fixed: true});
                  me.forceUpdate();
                }, 500);
              }
            )
            .catch(error => {
              // TODO: 获取详情失败了，需要提示
              console.log('TODO: 获取详情失败了，需要提示', error);
            });
        } else {
          // TODO: 获取详情失败了，需要提示
          console.log('TODO: 获取详情失败了，需要提示', error);
        }
      });
    }).catch(error => {
      // TODO: 获取图谱信息失败了，需要提示
      console.log('TODO: 获取图谱信息失败了，需要提示', error);
    });
  }

  render() {
    let me = this;

    return (
      <Layout className={`${style['container']} dark-theme`}>
        <Loading />
        <Layout.Header className={style['header']}>
          {me.nodeInfo ? <h1>{getNodeDisplayTitle(me.nodeInfo)} 全向资源探索</h1> : ''}
        </Layout.Header>
        <Layout.Content>
          {
            me.nodeInfo ? (
              <Switch>
                <Route
                  exact
                  path={'/explore_relation/:viewId/:nodeId/:nodeType/:urlData'}
                  component={({match}) => (
                    <ExploreRelationMainView
                      viewId={match.params.viewId}
                      nodeId={match.params.nodeId}
                      nodeType={parseInt(match.params.nodeType)}
                      nodeInfo={me.nodeInfo}
                      viewDataProvider={me.viewDataProvider}
                      urlData={match.params.urlData}
                    />
                  )}
                />
              </Switch>
            ) : null
          }
        </Layout.Content>
        <Copyright className={style['version']}/>
      </Layout>
    );
  }
}

ExploreRelationView.defaultProps = {};

ExploreRelationView.propTypes = {};

export default ExploreRelationView;