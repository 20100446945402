import React from "react";
import axios from "axios";
// import PropTypes from "prop-types";
import { Icon, Button, Badge } from "antd";
import PB from "@/libs/simplePB";

//leftTop=左上 left=左中；leftBottom=左下;
//rightTop=右上 right=右中；rightBottom=右下;
//topLeft=上左 top=上中；topRight=上右;
//bottomLeft=下左 bottom=下中；bottomRight=下右;
const TutorialConfigExample = [
  {
    domId: "common_relation_search",
    tip: {
      tipTitle: "搜索条使用提示",
      tipContent:
        "在输入框中输入内容，按回车键形成节点，一次性输入多个节点可用”空格“隔开。",
      tipContentImg: "图片的url",
      tipContentStyle: {
        fontSize: "1.4rem",
        width: 500,
        height: 100,
        color: "#f8f8f8",
        backgroundColor: "#6b7785",
        border: "3px solid #c9cdd4",
      },
    },
    badge: {
      badgeStyle: {
        backgroundColor: "#3491FA",
        color: "#f8f8f8",
      },
    },

    outer: {
      outerPadding: 30,
      outerStyle: {
        border: "3px solid #3491FA",
      },
    },

    offset: {
      outerAnchor: "top",
      tipAnchor: "bottom",
      offsetX: 0,
      offsetY: -100,
    },

    line: {
      outerAnchor: "top",
      tipAnchor: "bottom",
      lineColor: "#3491FA",
    },
  },
  {
    domId: false,
    tip: {
      tipPosition: "right",
      tipTitle: "节点邮件菜单提示",
      tipContent: "右键节点使用节点编辑功能",
      tipContentImg: {
        url: "",
      },
      tipContentStyle: {
        width: 300,
        height: 100,
        color: "#f8f8f8",
        // backgroundColor: "rgba(0,0,0,.5)",
        border: "3px solid #c9cdd4",
        fontFamily: "楷书",
      },
    },
    badge: {
      badgeStyle: {
        backgroundColor: "#c9cdd4",
        color: "#272e3b",
      },
    },

    outer: {
      outerPadding: 20,
      outerStyle: {
        position: "absolute",
        left: 20,
        top: "center",
        width: 200,
        height: 380,
        border: "3px solid #c9cdd4",
        backgroundPosition: "center",
        // backgroundSize: "cover",
        backgroundImage: "url('/assets/tutorial/2_right_context.png')",
      },
    },
    offset: {
      outerAnchor: "right",
      tipAnchor: "left",
      offsetX: 100,
      offsetY: 0,
    },

    line: {
      outerAnchor: "right",
      tipAnchor: "left",
      lineColor: "#c9cdd4",
    },
  },
  {
    domId: "ai_console",
    tip: {
      tipPosition: "right",
      tipTitle: "对话框提示",
      tipContent: "在对话框中查看节点详情",
      tipContentImg: {
        url: "",
      },
      tipContentStyle: {
        width: 400,
        height: 70,
        color: "#f8f8f8",
        border: "3px solid #c9cdd4",
        fontFamily: "楷书",
      },
    },
    badge: {
      badgeStyle: {
        backgroundColor: "#c9cdd4",
        color: "#272e3b",
      },
    },

    outer: {
      outerPadding: 10,
      outerStyle: {
        border: "3px solid #c9cdd4",
        width: 350,
        height: 500,
      },
    },

    offset: {
      outerAnchor: "bottom",
      tipAnchor: "top",
      offsetX: 0,
      offsetY: 100,
    },

    line: {
      show: true,
      outerAnchor: "bottom",
      tipAnchor: "top",
      lineColor: "#c9cdd4",
    },
  },
  {
    domId: "add_member_btn",
    tip: {
      tipPosition: "right",
      tipTitle: "添加协作成员",
      tipContent: "输入协作成员的ID号，类似QQ号，用于与他人在图谱中协作",
      tipContentStyle: {
        width: 400,
        height: 100,
        color: "#f8f8f8",
        border: "3px solid #c9cdd4",
        fontFamily: "楷书",
      },
    },
    badge: {
      badgeStyle: {
        backgroundColor: "#c9cdd4",
        color: "#272e3b",
      },
    },

    outer: {
      outerPadding: 10,
      outerStyle: {
        border: "3px solid #c9cdd4",
      },
    },
    offset: {
      outerAnchor: "rightBottom",
      tipAnchor: "leftTop",
      offsetX: 100,
      offsetY: 100,
    },

    line: {
      outerAnchor: "rightBottom",
      tipAnchor: "leftTop",
      lineColor: "#c9cdd4",
    },
  },
  {
    domId: "badge_user_avatar",
    tip: {
      tipPosition: "right",
      tipTitle: "协作ID",
      tipContent: "查看和复制“协作ID”",
      tipContentStyle: {
        width: 350,
        height: 70,
        color: "#f8f8f8",
        border: "3px solid #c9cdd4",
        fontFamily: "楷书",
      },
    },
    badge: {
      badgeStyle: {
        backgroundColor: "#c9cdd4",
        color: "#272e3b",
      },
    },

    outer: {
      outerPadding: 5,
      outerStyle: {
        border: "3px solid #c9cdd4",
      },
    },

    offset: {
      outerAnchor: "leftBottom",
      tipAnchor: "topRight",
      offsetX: -500,
      offsetY: 250,
    },

    line: {
      outerAnchor: "leftBottom",
      tipAnchor: "topRight",
      lineColor: "#c9cdd4",
    },
  },
  {
    domId: "badge_operate_help",
    tip: {
      tipPosition: "right",
      tipTitle: "操作教程",
      tipContent: "查看更多操作教程”",
      tipContentStyle: {
        width: 300,
        height: 70,
        color: "#f8f8f8",
        border: "3px solid #c9cdd4",
        fontFamily: "楷书",
      },
    },
    badge: {
      badgeStyle: {
        backgroundColor: "#c9cdd4",
        color: "#272e3b",
      },
    },

    outer: {
      outerPadding: 5,
      outerStyle: {
        border: "3px solid #c9cdd4",
      },
    },

    offset: {
      outerAnchor: "leftBottom",
      tipAnchor: "topRight",
      offsetX: -400,
      offsetY: 100,
    },

    line: {
      outerAnchor: "leftBottom",
      tipAnchor: "topRight",
      lineColor: "#c9cdd4",
    },
  },
];
console.log(
  "TutorialCourser TutorialConfigExample=",
  JSON.stringify(TutorialConfigExample)
);

const TutorialConfigExampleXPath = [
  {
    xpath: `//*[@id="mainBadgeInfo"]/div[4]`,
    domId: "common_relation_search",
    badge: {
      badgeStyle: {},
    },
    line: {
      lineColor: "rgb(255,227,187)",
      outerAnchor: "bottom",
      tipAnchor: "top",
    },
    offset: {
      offsetX: -10,
      offsetY: 0,
      outerAnchor: "right",
      tipAnchor: "top",
    },
    outer: {
      outerPadding: 10,
      outerStyle: {
        border: "4px solid #57A9FB",
      },
    },
    tip: {
      tipContent: "用xpath来定位页面元素。",
      tipContentStyle: {
        border: "1px solid rgb(255,227,187)",
        fontSize: "1.4rem",
        height: 100,
        width: 500,
      },
      tipTitle: "XPath定位元素",
    },
  },
];

//封装xpath
function getElementByXpath(xpath) {
  var element = document.evaluate(xpath, document).iterateNext();
  return element;
}

class TutorialCourser extends React.Component {
  state = {
    refresh: false,
    showStatus: false,
    showIdx: -1,
  };
  // tips 的配置
  tipsConfig = [];
  // 提示信息
  tipJsx = [];

  // css实现两点之间的连线
  drawLine = (startObj, endObj, key, lineColor = "#3491FA") => {
    // 起点元素中心坐标
    const startY = startObj.y;
    const startX = startObj.x;

    // 终点元素中心坐标
    const endY = endObj.y;
    const endX = endObj.x;

    // 用勾股定律计算出斜边长度及其夹角（即连线的旋转角度）
    const lx = endX - startX;
    const ly = endY - startY;
    // 计算连线长度
    const length = Math.sqrt(lx * lx + ly * ly);
    // 弧度值转换为角度值
    const c = (360 * Math.atan2(ly, lx)) / (2 * Math.PI);

    // 连线中心坐标
    const midX = (endX + startX) / 2;
    const midY = (endY + startY) / 2;
    const deg = c <= -90 ? 360 + c : c; // 负角转换为正角
    const style = {
      position: "absolute",
      top: midY,
      left: midX - length / 2,
      width: length,
      height: 0,
      transform: `rotate(${deg}deg)`,
      border: `2px solid ${lineColor}`,
    };
    console.log(
      "🚀 | file: tutorial.course.js | line 100 | TutorialCourser | style",
      style
    );
    return <div key={key} style={style} />;
  };

  // 计算 dom el 的位置
  getElQuadrant = (x, y, w, h) => {
    let quadrantX = 0;
    let quadrantY = 0;
    const xLine1 = w * 0.3;
    const xLine2 = w * 0.7;
    const xLine3 = w * 1;
    if (x < xLine1) {
      quadrantX = 1;
    } else if (x < xLine2) {
      quadrantX = 2;
    } else {
      quadrantX = 3;
    }
    const yLine1 = h * 0.3;
    const yLine2 = h * 0.7;
    const yLine3 = h * 1;
    if (y < yLine1) {
      quadrantY = 1;
    } else if (y < yLine2) {
      quadrantY = 2;
    } else {
      quadrantY = 3;
    }
    const quadrantXY = quadrantX * 10 + quadrantY;

    return quadrantXY;
    if (quadrantXY === 11) {
      return "rightBottom";
    } else if (quadrantXY === 12) {
      return "right";
    } else if (quadrantXY === 13) {
      return "rightTop";
    } else if (quadrantXY === 21) {
      return "bottom";
    } else if (quadrantXY === 22) {
      return "top";
    } else if (quadrantXY === 23) {
      return "top";
    } else if (quadrantXY === 31) {
      return "leftBottom";
    } else if (quadrantXY === 32) {
      return "left";
    } else if (quadrantXY === 33) {
      return "leftTop";
    }
    return "top";
  };

  // 计算 已知对象的锚点坐标
  getAnchorPos(x, y, w, h, anchor) {
    if (anchor === "topLeft" || anchor === "leftTop") {
      return { x, y };
    } else if (anchor === "top") {
      return { x: x + w / 2, y };
    } else if (anchor === "topRight" || anchor === "rightTop") {
      return { x: x + w, y };
    } else if (anchor === "left") {
      return { x, y: y + h / 2 };
    } else if (anchor === "right") {
      return { x: x + w, y: y + h / 2 };
    } else if (anchor === "leftBottom" || anchor === "bottomLeft") {
      return { x, y: y + h };
    } else if (anchor === "bottom") {
      return { x: x + w / 2, y: y + h };
    } else if (anchor === "bottomRight" || anchor === "rightBottom") {
      return { x: x + w, y: y + h };
    } else {
      return { x, y };
    }
  }

  // 用锚点坐标计算对象的起始坐标
  getElPosFromAnchorPos(x, y, w, h, anchor) {
    if (anchor === "topLeft" || anchor === "leftTop") {
      return { x, y };
    } else if (anchor === "top") {
      return { x: x - w / 2, y };
    } else if (anchor === "topRight" || anchor === "rightTop") {
      return { x: x - w, y };
    } else if (anchor === "left") {
      return { x, y: y - h / 2 };
    } else if (anchor === "right") {
      return { x: x - w, y: y - h / 2 };
    } else if (anchor === "leftBottom" || anchor === "bottomLeft") {
      return { x, y: y - h };
    } else if (anchor === "bottom") {
      return { x: x - w / 2, y: y - h };
    } else if (anchor === "bottomRight" || anchor === "rightBottom") {
      return { x: x - w, y: y - h };
    } else {
      return { x, y };
    }
  }

  // tip 配置生成 jsx 存入 this.tipJsx
  genTipsJsx = (tutorialConfig) => {
    let me = this;
    const jsxArr = [];
    // 网页可视宽高
    const clientW = document.documentElement.clientWidth;
    const clientH = document.documentElement.clientHeight;
    tutorialConfig.forEach((tipConf, idx) => {
      // 自定义的配置
      const {
        xpath = undefined,
        domId = undefined,
        tip = {},
        badge = {},
        outer = {},
        offset = {},
        line = {},
      } = tipConf;
      // 当前显示的tip编号
      const id = "tutorial=" + idx;

      // 是否指向 dom 对象
      let hasTarget = true;

      // 获取 dom 元素
      let tgtDomEl = undefined; // 被指向的dom对象
      if (domId) {
        tgtDomEl = document.querySelector(`#${domId}`);
      } else if (xpath) {
        tgtDomEl = getElementByXpath(xpath);
      } else {
        // 无指向的 tip
        hasTarget = false;
      }

      // ---------------------------------
      // outer 外框

      // 判断有没有设置元素，作为 outer 的初始位置
      const { outerPadding = 0, outerStyle = {} } = outer;
      // outer 的初始值
      let outerPosStyle = {
        left: 0,
        top: 0,
        width: 0,
        height: 0,
      };
      if (hasTarget) {
        // const tgtDomEl = document.querySelector(`#${domId}`);
        if (!tgtDomEl) {
          return false;
        }
        // 被提示元素的坐标和宽高
        const { x, y, width, height } = tgtDomEl.getBoundingClientRect();

        // 外框 outer
        outerPosStyle = {
          left: x - outerPadding > 0 ? x - outerPadding : 0,
          top: y - outerPadding > 0 ? y - outerPadding : 0,
          width: width + outerPadding * 2,
          height: height + outerPadding * 2,
        };

        // 判断是否出界
        if (outerPosStyle.left + outerPosStyle.width > clientW) {
          outerPosStyle.width = clientW - outerPosStyle.left;
        }

        if (outerPosStyle.top + outerPosStyle.height > clientH) {
          outerPosStyle.height = clientH - outerPosStyle.top;
        }
      }

      // 如果用户自定了宽高，则使用自定义的宽高
      // 后面计算 提示框和连线时需要
      const {
        left: outerCusLeft = undefined,
        top: outerCusTop = undefined,
        width: outerCusW = undefined,
        height: outerCusH = undefined,
      } = outerStyle;
      if (outerCusW) outerPosStyle.width = outerCusW;
      if (outerCusH) outerPosStyle.height = outerCusH;

      if (outerCusLeft) {
        if (outerCusLeft === "center") {
          outerPosStyle.left = (clientW - outerPosStyle.width) / 2;
          outerStyle.left = outerPosStyle.left;
        } else {
          outerPosStyle.left = outerCusLeft;
        }
      }

      if (outerCusTop) {
        if (outerCusTop === "center") {
          outerPosStyle.top = (clientH - outerPosStyle.height) / 2;
          outerStyle.top = outerPosStyle.top;
        } else {
          outerPosStyle.top = outerCusTop;
        }
      }

      jsxArr.push(
        <div
          key={`${id}-outer`}
          style={{
            ...{
              height: 2,
              width: 2,
              position: "absolute",
              border: `3px solid #FF7D00`,
              zIndex: 101,
            },
            ...outerPosStyle,
            ...outerStyle,
          }}
        ></div>
      );

      // ---------------------------------
      // 提示 tip
      const { tipContent = "", tipContentStyle = {} } = tip;
      const { badgeStyle = {} } = badge;
      const {
        outerAnchor = "top",
        tipAnchor = "bottom",
        offsetX: tipOffsetX = 100,
        offsetY: tipOffsetY = 100,
      } = offset;
      // outer 连接点的坐标
      const outerAnchorPos = me.getAnchorPos(
        outerPosStyle.left,
        outerPosStyle.top,
        outerPosStyle.width,
        outerPosStyle.height,
        outerAnchor
      );

      const { width: tipW = 300, height: tipH = 150 } = tipContentStyle;
      const tipPos = me.getElPosFromAnchorPos(
        outerAnchorPos.x + tipOffsetX,
        outerAnchorPos.y + tipOffsetY,
        tipW,
        tipH,
        tipAnchor
      );

      jsxArr.push(
        <div
          key={`${id}-tip`}
          style={{
            position: "absolute",
            left: tipPos.x,
            top: tipPos.y,
            width: tipW,
            height: tipH,
            margin: 0,
            padding: 0,
          }}
        >
          <div
            style={{
              position: "relative",
              width: "100%",
              height: "100%",
            }}
          >
            <div
              style={{
                ...{
                  position: "absolute",
                  left: "1rem",
                  top: "-2rem",
                  fontSize: "1.5rem",
                  padding: "1rem",
                  width: "4rem",
                  height: "4rem",
                  backgroundColor: "#57A9FB",
                  color: "#f8f8f8",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "50%",
                  fontStyle: "italic",
                  fontWeight: 600,
                },
                ...badgeStyle,
              }}
            >
              <Icon type="bulb" />
            </div>

            <div
              style={{
                ...{
                  fontSize: "1.4rem",
                  fontWeight: 500,
                  color: "#020202",
                  backgroundColor: "#f7f8fa",
                  padding: 20,
                  textIndent: "4rem",
                  borderRadius: "3px",
                  boxShadow: "rgba(255, 255, 255, 0.05) 0px 2px 5px",
                },
                ...tipContentStyle,
              }}
            >
              {tipContent}
            </div>

            {/* 下一步按钮 */}
            <Button
              type="primary"
              style={{
                position: "absolute",
                right: "1rem",
                top: tipH - 15,
                paddingLeft: 20,
                paddingRight: 20,
              }}
              onClick={me.showNextTip}
            >
              {me.state.showIdx === me.tutorialConfig.length - 1
                ? "结束教程"
                : "下一条"}
            </Button>
          </div>
        </div>
      );

      // ---------------------------------
      // 连线 line
      const {
        show: lineShow = true,
        outerAnchor: lineOuterAnchor = "top",
        tipAnchor: lineTipAnchor = "bottom",
        lineColor = "#3491FA",
      } = line;
      if (lineShow) {
        // outer 连接点的坐标
        const lineOuterAnchorPos = me.getAnchorPos(
          outerPosStyle.left,
          outerPosStyle.top,
          outerPosStyle.width,
          outerPosStyle.height,
          lineOuterAnchor
        );
        const lineTipAnchorPos = me.getAnchorPos(
          tipPos.x,
          tipPos.y,
          tipW,
          tipH,
          lineTipAnchor
        );
        const lineJsx = me.drawLine(
          lineOuterAnchorPos,
          lineTipAnchorPos,
          `${id}-line`,
          lineColor
        );
        jsxArr.push(lineJsx);
      }
    });

    return jsxArr;
  };

  showNextTip = () => {
    if (this.state.showIdx === this.tutorialConfig.length - 1) {
      // 最后一条提示
      // 浏览完全部教程，以后不再提示教程
      this.setNotShowAgain();

      // 隐藏提示界面
      this.setState({
        showStatus: false,
        showIdx: -1,
        refresh: !this.state.refresh,
      });
      return false;
    }
    this.setState({
      showStatus: true,
      showIdx: this.state.showIdx + 1,
      refresh: !this.state.refresh,
    });
  };

  setNotShowAgain = () => {
    const { userInfo } = this.props;
    //  记录当前账号已经出现过提示，下次不再出现提示
    // 读取本地缓存，判断账号是否已经显示过教程提示
    let localShowedAc = localStorage.getItem("channeltutorial");
    try {
      if (localShowedAc) {
        localShowedAc = JSON.parse(localShowedAc);
      } else {
        localShowedAc = {};
      }
    } catch (error) {
      localShowedAc = {};
    }
    const { acs = undefined } = localShowedAc;
    if (!acs) {
      localShowedAc.acs = [];
    }
    localShowedAc.acs.push(userInfo.userId);
    localShowedAc.acs = Array.from(new Set(localShowedAc.acs));
    localStorage.setItem("channeltutorial", JSON.stringify(localShowedAc));
  };

  componentDidMount() {
    let me = this;
    //-------------------------------------------------------------------
    // 请求 tip 配置
    axios
      .create({
        timeout: 1500000, // 超时时间 150秒
        // timeout: 40000, // 超时时间 40秒
        headers: {
          "x-requested-with": "XMLHttpRequest",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Max-Age": 0,
        },
      })({
        method: "get",
        url: "https://aid.joinmap.ai/view_api/tutorial/config",
        // url: "/assets/tutorial/config.json",
      })
      .then((res) => {
        const { data: resData = {} } = res;
        const { code = -1, data = [] } = resData;
        if (code === 0) {
          me.tutorialConfig = data;
        } else {
          me.tutorialConfig = [];
        }

        // 测试 xpath
        // me.tutorialConfig = TutorialConfigExampleXPath;

        me.setState({
          showStatus: false,
          showIdx: -1,
          refresh: !me.state.refresh,
        });
        return;
      })
      .catch((e) => {
        console.log(
          "🚀 | file: tutorial.course.js | line 739 | TutorialCourser | componentDidMount | e",
          e
        );
        // 出错的情况，则不显示教程
        me.setState({
          showStatus: false,
          showIdx: -1,
          refresh: !me.state.refresh,
        });
        return;
      });

    //-------------------------------------------------------------------
    PB.sub(
      this,
      "tutorial",
      "status.show",
      ({ isShow = undefined, showIdx = undefined, viewInfo = {} }) => {
        const { userInfo } = me.props;
        let _show = isShow;
        // 没有设置 isShow 参数 -> 通过 viewInfo 的 channel 字段判断
        if (isShow === undefined) {
          const { channel = 0 } = viewInfo;
          if (channel > 0) {
            _show = true;
          } else {
            _show = false;
          }
        }

        // 读取本地缓存，判断账号是否已经显示过教程提示
        let localShowedAc = localStorage.getItem("channeltutorial");
        try {
          if (localShowedAc) {
            localShowedAc = JSON.parse(localShowedAc);
          } else {
            localShowedAc = {};
          }
        } catch (error) {
          localShowedAc = {};
        }

        const { acs } = localShowedAc;
        if (acs) {
          if (acs.indexOf(userInfo.userId) > -1) {
            _show = false;
          }
        } else {
          localShowedAc.acs = [];
        }

        if (_show === false) {
          // 强制显示隐藏
          me.setState({
            showStatus: false,
            showIdx: -1,
            refresh: !me.state.refresh,
          });
          return false;
        }

        // 判断有没有tip配置文件如果为空数组，则视为没有看过教程
        if (me.tutorialConfig.length === 0) {
          me.setState({
            showStatus: false,
            showIdx: -1,
            refresh: !me.state.refresh,
          });
          return false;
        }

        //  记录当前账号已经出现过提示
        // 修改为 全部提示浏览完成后才记录为不再提示
        // localShowedAc.acs.push(userInfo.userId);
        // localShowedAc.acs = Array.from(new Set(localShowedAc.acs));
        // localStorage.setItem("channeltutorial", JSON.stringify(localShowedAc));

        // 如果已经是显示状态则跳过
        if (me.state.showStatus === _show) {
          return false;
        }

        // 显示tip
        me.setState({
          showStatus: true,
          showIdx: 0,
          refresh: !me.state.refresh,
        });
      }
    );
  }

  render() {
    let me = this;
    const { showStatus, showIdx = -1 } = this.state;
    let tmpTipsJsx = [];
    if (showIdx > -1) {
      tmpTipsJsx = this.genTipsJsx([this.tutorialConfig[showIdx]]);
    }
    return (
      <div
        id="tutorial_outer"
        style={
          showStatus
            ? {
                position: "absolute",
                left: 0,
                top: 0,
                right: 0,
                bottom: 0,
                pointerEvents: "all",
                backgroundColor: "rgba(0,0,0,.4)",
                zIndex: 100,
              }
            : { display: "none" }
        }
      >
        {tmpTipsJsx.map((i) => i)}

        <div
          style={{
            width: "100%",
            position: "absolute",
            left: 0,
            right: 0,
            bottom: "3rem",
            textAlign: "center",
          }}
        >
          <Button
            type="primary"
            shape="round"
            icon="close"
            size="large"
            onClick={() => {
              me.setNotShowAgain();
              // 隐藏提示界面
              me.setState({
                showStatus: false,
                showIdx: -1,
                refresh: !me.state.refresh,
              });
            }}
          >
            关闭教程
            <span
              style={{ fontSize: ".8rem", color: "#c9cdd4", marginLeft: 10 }}
            >
              不再提示
            </span>
          </Button>
        </div>
      </div>
    );
  }
}

export default TutorialCourser;
