import React from "react";
import { connect } from "react-redux";
import { Empty, message, Spin, Input, Row, Col, BackTop } from "antd";
import ClampLines from "@/libs/my-react-clamp-lines";
import relationStyle from "@/style/components/main.relation.less";
import axios from "axios";
import { getToken } from "@/utils/HttpUtil";
import Qs from "qs";
import schViewStyles from "@/style/components/searchView/searchView.less";
import styles from "@/style/components/searchView/search_nodesResult.less";
import userStyles from "@/style/default/user.less";
import dashboardStyles from "@/style/containers/dashboard.less";
// import UserAvatar from 'react-user-avatar';
// import {AvatarColors, IconTypes} from '@/constants/common';
// import Icon from "@/components/common/common.icon";
// import copy from 'copy-to-clipboard';
// import ViewInfoCard from "@/components/dashboard/dashboard.viewInfoCard";
import ViewInfoCard from "@/components/common/objects/common.viewInfoCard.style.1";
import UserNickAvatar from "@/components/common/objects/common.userAvatar";
import { API_CallMicroService } from "@/api/microService.js";
import { API_GetViewById } from "@/libs/view/network/api.js";

import { httpUtilWithNoMsg } from "@/utils/HttpUtil";
import Highlighter from "react-highlight-words";
import NodeAvatar from "../common/objects/common.nodeAvatar";
import moment from "moment";
import Axios from "axios";
import intl from 'react-intl-universal';

// 接口
/**
 * 全局搜索节点
 * 异步版本
 * @param text
 * @param limit
 * @returns {*}
 * @constructor
 */
const API_GetSimilarNodes = ({ text, limit, start }) => {
  let url = `/view/relation/node/match`;
  url += `?keyword=${text}`;
  if (limit > 0) {
    url += `&limit=${limit}`;
  }
  return httpUtilWithNoMsg.get(url);
};

/**
 * 全局搜索节点
 * 同步版本
 * @param text
 * @param limit
 * @returns {*}
 * @constructor
 */
const API_GetSimilarNodesSync = async ({ text, limit, start }) => {
  let url = `/view/relation/node/match`;
  url += `?keyword=${text}`;
  if (limit > 0) {
    url += `&limit=${limit}`;
  }
  return await httpUtilWithNoMsg.get(url);
};

/**
 * 请求看板信息
 * 同步版本
 * @param viewId
 * @returns {*}
 * @constructor
 */
const API_GetViewByIdSync = async (viewId) => {
  return await API_GetViewById(viewId);
};

/**
 * 搜索节点页面
 * SearchView 页面框架下的子路由
 */
class SearchNodesComponent extends React.Component {
  state = {
    // 刷新页面
    refresh: false,
    // 用户输入的词汇
    inputText: "",
    // 用于搜索节点的文本
    textForNodesSearch: "",
    // 搜索节点状态
    nodesIsSearching: false,
    // 搜索节点后台报错
    nodesSchIsError: false,
    // 搜索节点总数
    nodesTotal: 0,
    // 用于搜索推荐词汇的文本
    textForTagsSearch: "",
    // 搜索tag状态
    tagsIsSearching: false,
    // 选中的推荐词汇
    selectedTags: [],
    // 查询节点数量
    schLimit: 120,
  };

  /**
   * 搜索结果中 view cover 的显示宽度
   * @type {string}
   */
  coverWidth = "8rem";

  /**
   * 瀑布列 1
   * @type {[]}
   */
  idxInColumn1 = [];

  /**
   * 瀑布列 2
   * @type {[]}
   */
  idxInColumn2 = [];

  /**
   * 拓展的推荐词汇
   * @type {string[]}
   */
  tagsFromServer = [];

  /**
   * 推荐词汇tag的点击
   */
  handleTagClick = (tag) => {
    // 多选tag
    // const {selectedTags} = this.state;
    // const nextSelectedTags = selectedTags.indexOf(tag) === -1 ? [...selectedTags, tag] : selectedTags.filter(t => t !== tag);
    // 单选tag
    const nextSelectedTags = [tag];
    this.setState({ selectedTags: nextSelectedTags, searchText: tag });
    // 点击标签直接搜索节点
    this.schNodes(tag);
  };

  /**
   * 拓展推荐词汇 tag
   * @param txt 待拓展的文本
   * @param limit
   * @param start 暂时没有分页
   */
  schTags = (txt, limit = 20, start = 0) => {
    let me = this;
    this.tagsFromServer = [];
    this.setState(
      {
        tagsIsSearching: true,
        textForTagsSearch: txt,
      },
      () => {
        // 调用微服务接口，微服务ID:a63f93ef-fb6c-40ce-a007-80eb8cf8113a
        // 微服务需要指定一个看板ID,
        // 这里用一个空看板的ID(f60cf0b6-d2cf-494d-9fa5-6da4d97c4984)代替,
        // 该看板不能删除
        API_CallMicroService(
          "a63f93ef-fb6c-40ce-a007-80eb8cf8113a",
          "f60cf0b6-d2cf-494d-9fa5-6da4d97c4984",
          {
            limit: limit,
            start: 0,
            target: {
              textOrUrls: [txt],
            },
            parameters: {},
          }
        )
          .then((response) => {
            let res = response.data;
            // console.log('SearchNodesComponent API_CallMicroService res: ', res);
            if (res.hasOwnProperty("code") && res.code === 0) {
              me.tagsFromServer = [];
              res.data[0].nodes.forEach((i, idx) => {
                if (idx < limit) {
                  me.tagsFromServer.push(i.fname);
                }
              });
            } else {
              // 接口报错，打印日志，清空 tags 列表
              console.log(
                "SearchNodesComponent API_CallMicroService failed res: ",
                res
              );
              me.tagsFromServer = [];
            }

            me.setState({
              tagsIsSearching: false,
            });
          })
          .catch((e) => {
            console.log(
              "SearchNodesComponent API_CallMicroService error e: ",
              e
            );
            me.tagsFromServer = [];
            me.setState({
              tagsIsSearching: false,
            });
          });
      }
    );
  };

  /**
   * 搜索输入框开始搜索事件
   * @param txt
   */
  handleInputOnSearch = (txt) => {
    // console.log('SearchNodesComponent handleInputOnChange value: ', value);
    this.setState({ inputText: txt });
    // 输入框输入的文本：拓展词汇 + 节点搜索
    this.doSearch(txt, this.state.schLimit);
  };

  /**
   * 输入框输入change事件
   * @param e 触发对象
   */
  handleInputOnChange = (e) => {
    // console.log('SearchNodesComponent handleInputOnChange e: ', e);
    this.setState({ inputText: e.target.value });
  };

  /**
   * 搜索的节点结果结构举例
   * @type {[]}
   */
  schResult_example = [
    {
      view: {
        viewId: "asdfasd",
        name: "v1",
      },
      nodes: [
        {
          id: "sdd",
          fname: "节点名1",
          desc: "节点1的描述描述描述描述描述描述描述描述描述",
          tag: "标签1 标签2 标签3",
        },
      ],
    },
  ];

  /**
   * 搜索的结果
   * @type {[]}
   */
  schResult = [];

  /**
   * 搜节点
   * @param txt 待搜索的文本
   * @param limit
   * @param start 暂时没有分页
   */
  schNodes = (txt, limit = this.schLimit, start = 0) => {
    let me = this;
    this.schResult = [];
    this.setState(
      {
        nodesTotal: 0,
        nodesIsSearching: true,
        textForNodesSearch: txt,
      },
      async () => {
        try {
          let res = await API_GetSimilarNodesSync({ text: txt, limit, start });
          // console.log('API_GetSimilarNodesSync response=', res);
          res = res.data;
          if (res.hasOwnProperty("code") && res.code === 0) {
            let resultObj = {};
            res.data.reverse(); // 按道理应该后台排序，这里临时倒置处理，把时间最近的放到前面
            res.data.forEach((n) => {
              if (resultObj.hasOwnProperty(n.view_id)) {
                resultObj[n.view_id]["nodes"].push(n);
              } else {
                resultObj[n.view_id] = {
                  view: { viewId: n.view_id },
                  nodes: [n],
                };
              }
            });

            // 请求 view info 并且做权限检查
            let result = [];
            let nodesTotal = 0; // 有权限的节点总数

            // 把 view id 按指定数量分组,批量发送请求节省时间
            let viewIdArr = Object.keys(resultObj);
            let viewIdGroupArr = [];
            let num = 10; // 每次10个请求
            for (let i = 0; i < viewIdArr.length; i += num) {
              viewIdGroupArr.push(viewIdArr.slice(i, i + num));
            }
            // console.log('SearchNodesComponent API_GetViewById viewIdGroupArr: ', viewIdGroupArr);
            for (let i = 0; i < viewIdGroupArr.length; i += 1) {
              // 同时发送多个请求
              let resList = await Axios.all(
                viewIdGroupArr[i].map((viewId) => {
                  return API_GetViewByIdSync(viewId);
                })
              );
              // console.log('全部加载完成 resList= ', resList);
              resList.forEach((res2) => {
                res2 = res2.data;
                if (res2.hasOwnProperty("code") && res2.code === 0) {
                  // 有权限
                  let viewId = res2.data["viewId"];
                  resultObj[viewId]["view"] = res2.data;
                  result.push(resultObj[viewId]);
                  nodesTotal = nodesTotal + resultObj[viewId]["nodes"].length;
                } else {
                  // 获取看板信息出错
                  // ignore
                }
              });
            }

            // TIP:临时排序,由于节点没有时间值，用 view 的 last_node_update 字段排序
            result.sort(function (a, b) {
              // console.log('SearchNodesComponent API_GetViewById a: ', a);
              // b value
              if (!b["view"]["lastNodeUpdate"]) {
                return -1;
              }
              let valueOfb = 0;
              try {
                valueOfb = moment(
                  b["view"]["lastNodeUpdate"],
                  "YYYY-MM-DD HH:mm:ss"
                ).valueOf();
              } catch (e) {
                return -1;
              }

              // a value
              if (!a["view"]["lastNodeUpdate"]) {
                return 1;
              }
              let valueOfa = 0;
              try {
                valueOfa = moment(
                  a["view"]["lastNodeUpdate"],
                  "YYYY-MM-DD HH:mm:ss"
                ).valueOf();
              } catch (e) {
                return 1;
              }
              return valueOfb - valueOfa;
            });

            // 设置返回结果
            me.schResult = result;
            // 两列瀑布流分配 idx
            this.idxInColumn1 = [];
            this.idxInColumn2 = [];
            for (let i = 0; i < me.schResult.length; i++) {
              if (i % 2 === 0) {
                this.idxInColumn1.push(i);
              } else {
                this.idxInColumn2.push(i);
              }
            }

            // 强制刷新页面
            me.setState({
              nodesTotal: nodesTotal,
              nodesIsSearching: false,
              refresh: !me.state.refresh,
            });
          } else {
            // 内部出错
            message.warning("没有搜索到节点", 5);
            me.schResult = [];
            me.setState({
              nodesTotal: 0,
              nodesIsSearching: false,
              nodesSchIsError: true,
            });
          }
        } catch (e) {
          console.log("SearchNodesComponent API_GetSimilarNodes error: ", e);
          message.warning("没有搜索到节点", 5);
          me.schResult = [];
          me.setState({
            nodesTotal: 0,
            nodesIsSearching: false,
            nodesSchIsError: true,
          });
        }
      }
    );
  };
  /**
   * 搜索
   */
  doSearch = (txt, limit) => {
    if (!txt) {
      message.warning("请输入词汇后再搜索");
      return false;
    }
    // 搜节点
    this.schNodes(txt, limit);
    // 拓展词
    this.schTags(txt);
  };

  /**
   * 点击 节点
   * @param viewId
   */
  openView = (viewId) => {
    window.open("/mainview/relation/" + viewId, "_blank");
  };

  /**
   * 渲染一个看板的结果
   * @param idx
   * @returns {JSX.Element}
   */
  renderResult = (idx) => {
    const { textForNodesSearch } = this.state;
    let i = this.schResult[idx];
    // console.log('renderResult  idx=', idx);
    // console.log('renderResult  this.schResult.length=', this.schResult.length);
    // console.log('renderResult  i=', i);
    return (
      <div className={styles["result"]} key={"i-" + idx}>
        <div className={styles["view"]}>
          <ViewInfoCard
            readOnly={false}
            view={i["view"]}
            key={`view-${idx}`}
            coverWidth={this.coverWidth}
            showUpdateTime={false}
            showLastNodeUpdate={true}
          />
        </div>
        <div className={styles["nodes-list"]}>
          <div
            className={styles["nodes-list-blank"]}
            style={{ flex: "0 1 " + this.coverWidth }}
          >
            &nbsp;
          </div>
          <div className={styles["nodes-list-content"]}>
            {i["nodes"].map((n, idx2) => {
              return (
                <div key={"n-" + idx2} className={styles["node"]}>
                  {/*<div className={styles['fname']} style={{display: 'none'}} onClick={() => {*/}
                  {/*  this.openView(i['view']['viewId']);*/}
                  {/*}}>*/}
                  {/*  <NodeAvatar nodeId={n['id']} viewId={i['view']['viewId']} showFname={false}>*/}
                  {/*    <Highlighter*/}
                  {/*      highlightClassName={styles['key-wd']}*/}
                  {/*      searchWords={[textForNodesSearch]}*/}
                  {/*      autoEscape={true}*/}
                  {/*      textToHighlight={n['fname']}*/}
                  {/*    />*/}
                  {/*  </NodeAvatar>*/}
                  {/*</div>*/}
                  <div
                    className={styles["meta"]}
                    onClick={() => {
                      this.openView(i["view"]["viewId"]);
                    }}
                    title={
                      n["description"]
                        ? "节点名称: " +
                          n["fname"] +
                          "\n节点描述:\n" +
                          n["description"]
                        : "节点名称: " + n["fname"]
                    }
                  >
                    <UserNickAvatar
                      className={styles["user"]}
                      userId={n.user_id}
                      avatarSize="1rem"
                    />
                    <NodeAvatar
                      className={styles["body"]}
                      xnodeId={n["id"]}
                      node={{
                        id: n["id"],
                        fname: n["fname"],
                      }}
                      viewId={i["view"]["viewId"]}
                      showFname={true}
                      showUpdateTime={true}
                      highlightWords={[textForNodesSearch]}
                      highlightClass={styles["key-wd"]}
                    ></NodeAvatar>
                  </div>
                  {false && n["description"] ? (
                    <div className={styles["desc"]} style={{ display: "none" }}>
                      <ClampLines
                        className={"ant-list-item-meta-description"}
                        lines={2}
                        moreText={"展开 ∨"}
                        lessText={"收起 ∧"}
                        text={n["description"]}
                        renderFn={(text, extraElement) => {
                          const lines = text.split("\n");
                          return (
                            <span>
                              {lines.map((line, idx) => (
                                <span key={`ln-${idx}`}>
                                  <Highlighter
                                    highlightClassName={styles["key-wd"]}
                                    searchWords={[textForNodesSearch]}
                                    autoEscape={true}
                                    textToHighlight={line}
                                  />
                                  {idx === lines.length - 1 ? null : <br />}
                                </span>
                              ))}
                              {extraElement ? extraElement : null}
                            </span>
                          );
                        }}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  componentDidMount() {
    // 设置返回特征标签数量
    // limit=0 返回全部数据，默认返回150个
    // sortBy=cop 按企业数量排序， tag 按tag出现次数排序
    let query = { limit: 120, text: null, tags: [] };
    if (this.props.history.location && this.props.history.location.search) {
      let qs = Qs.parse(this.props.history.location.search, {
        ignoreQueryPrefix: true,
        strictNullHandling: true,
      });

      if (qs.hasOwnProperty("limit")) {
        query.limit = qs.limit;
      }
      if (qs.hasOwnProperty("wd")) {
        query.text = qs.wd;
      }
      if (qs.hasOwnProperty("tags")) {
        query.tags = qs.tags.split(";");
      }
    }
    let me = this;
    document.title = "搜索节点 - "+intl.get('Custom.general.title');

    if (query.text) {
      me.setState({
        inputText: query.text,
        schLimit: query.limit,
      });
      // 搜节点
      this.schNodes(query.text, query.limit);
      // 拓展词
      if (query.tags.length === 0) {
        this.schTags(query.text);
      } else {
        this.tagsFromServer = query.tags;
      }
    }
  }

  render() {
    const {
      inputText,
      selectedTags,
      textForTagsSearch,
      textForNodesSearch,
      nodesIsSearching,
      nodesSchIsError,
      tagsIsSearching,
      nodesTotal,
    } = this.state;

    // <span className={styles['key-wd']}>"{textForTagsSearch}"</span> 的
    return (
      <>
        {!textForNodesSearch ? (
          <div className={schViewStyles["default-sch"]}>
            <div style={{ marginBottom: "2rem" }}>
              <img
                src={intl.get('locale')==='zh-cn'?'https://vip.joinmap.ai/assets/logo-hans.png':'https://vip.joinmap.ai/assets/logo-hans_en.png'}
                alt="炬图"
              />
            </div>
            <Input.Search
              className={schViewStyles["sch-input"]}
              placeholder="输入关键词搜索"
              enterButton="搜节点"
              size="large"
              onSearch={this.handleInputOnSearch}
            />
            <div style={{ flex: "0 1 300px" }}>&nbsp;</div>
          </div>
        ) : (
          <div className={schViewStyles["result-outer"]}>

            <Row gutter={[16, 16]}>
              <Col span={12} offset={6}>
                <Input.Search
                  className={schViewStyles["sch-input"]}
                  placeholder="请输入关键词"
                  enterButton="搜节点"
                  size="large"
                  defaultValue={inputText ? inputText : ""}
                  onSearch={this.handleInputOnSearch}
                  onChange={this.handleInputOnChange}
                />
              </Col>
            </Row>

            {/*标签 */}
            <div className={schViewStyles["result-title"]}>
              {textForTagsSearch ? (
                <span style={{ marginLeft: ".5em" }}>推荐词汇：</span>
              ) : (
                <span style={{ marginLeft: ".5em" }}>推荐词汇：</span>
              )}
            </div>

            {this.tagsFromServer.length > 0 ? (
              this.tagsFromServer.map((tag) => (
                <span
                  key={tag}
                  className={
                    selectedTags.indexOf(tag) > -1
                      ? schViewStyles["tag"] + " " + schViewStyles["selected"]
                      : schViewStyles["tag"]
                  }
                  onClick={() => {
                    this.handleTagClick(tag);
                  }}
                >
                  {tag}
                </span>
              ))
            ) : tagsIsSearching ? (
              <Spin
                className={styles["spin"]}
                spinning={true}
                tip={"正在加载"}
                size={"large"}
              />
            ) : (
              <Empty
                className={styles["spin"]}
                image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                imageStyle={{
                  height: 120,
                }}
                description={tagsIsSearching ? "" : "暂无推荐词汇"}
              />
            )}

            <div style={{ flex: "0 1 40px" }}>&nbsp;</div>

            {/*节点*/}
            <div className={schViewStyles["result-title"]}>
              {textForNodesSearch ? (
                <span style={{ marginLeft: ".5em" }}>
                  <span className={styles["key-wd"]}>
                    "{textForNodesSearch}"
                  </span>{" "}
                  的搜索结果：
                </span>
              ) : (
                <span style={{ marginLeft: ".5em" }}>搜索结果：</span>
              )}
              {nodesTotal > 0 ? (
                <span className={schViewStyles["result-sub-title"]}>
                  为您找到相关节点前{nodesTotal}个
                </span>
              ) : (
                ""
              )}
            </div>
            <div className={styles["result-list"]}>
              {this.schResult.length > 0 ? (
                <>
                  <div className={styles["column-1"]}>
                    {this.idxInColumn1.map((idx) => {
                      return this.renderResult(idx);
                    })}
                  </div>
                  <div className={styles["column-2"]}>
                    {this.idxInColumn2.map((idx) => {
                      return this.renderResult(idx);
                    })}
                  </div>
                </>
              ) : nodesIsSearching ? (
                <Spin
                  className={styles["spin"] + " " + styles["spin-high"]}
                  spinning={true}
                  tip={"正在加载"}
                  size={"large"}
                />
              ) : (
                <Empty
                  className={styles["spin"] + " " + styles["spin-high"]}
                  image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                  imageStyle={{
                    height: 120,
                  }}
                  description={
                    nodesSchIsError ? "没有搜索到节点..." : "没有搜索到节点"
                  }
                />
              )}
            </div>
          </div>
        )}
        <BackTop />
        <br />
        <br />
        <br />
      </>
    );
  }
}

export default connect(
  (state) => ({
    userInfo: state.account.userInfo,
  }),
  (dispatch) => ({})
)(SearchNodesComponent);
