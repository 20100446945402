import {
    API_GetMyViewList,
    API_GetTeamworkViewList,
    API_GetPublishedViewList,
    API_GetAccessibleViewList,
    API_OrderView,
    API_OrderFreeView,
    API_QueryOrder,
    API_AddNewView,
    API_GetRecommendView,
    API_RecommendView,
    API_GetViewById,
    API_UpdateView,
    API_DeleteView,
    API_PublishViewToStore,
    API_RemoveViewFromStore,
    API_GetTeamworkMembers,
    API_AddTeamworkMember,
    API_RemoveTeamworkMember,
    API_MoveViewInfoByAccessToken,
    API_MoveTransInfoByAccessToken,
    API_GetViewCover,
    API_SetViewCover,
    API_LoadTeamworkSettings,
    API_SetTeamworkSettings,
    API_GetPublicViewList,
    API_GetPublicViewListAll,
    API_ViewTransferToUser,
    API_GetMyIsMasterViewList,
    API_GetMyIsMemberViewList,
    API_GetGuestPublicViewList,
    API_GetGuestPublicViewListOfFree,
    API_GetViewPublicCover,
    API_GetViewTags,
    API_LoadViewUserConfig,
    API_UpdateViewUserConfig,
    API_HasSubGraphSnapshot,
    API_LoadTeamworkTickets,
    API_AddTeamworkTicket,
    API_RemoveTeamworkTicket,
    API_GetSearchViewList,
    API_GetSearchViewListByNode,
    API_SetTeamworkSettingsPartially,
    API_GetRelationNodeList,
    API_GetSubGraph
} from "@/libs/view/network/api";
import {bindUtil} from "@/libs/core-decorators";
import {getErrorInfo as commonGetErrorInfo} from "@/components/common/common.functions";

// 拉取已上架图谱请求状态
export const PublishedViewListLoadingStatus = Object.freeze({
    IDLE: 'published_view_list.loading.idle',
    PROCESSING: 'published_view_list.loading.waiting',
    SUCCESS: 'published_view_list.loading.success',
    FAILED: 'published_view_list.loading.failed',
});

// 拉取公开图谱请求状态
export const PublicViewListLoadingStatus = Object.freeze({
    IDLE: 'public_view_list.loading.idle',
    PROCESSING: 'public_view_list.loading.waiting',
    SUCCESS: 'public_view_list.loading.success',
    FAILED: 'public_view_list.loading.failed',
});

// 拉取可访问的图谱请求状态
export const AccessibleViewListLoadingStatus = Object.freeze({
    IDLE: 'accessible_view_list.loading.idle',
    PROCESSING: 'accessible_view_list.loading.waiting',
    SUCCESS: 'accessible_view_list.loading.success',
    FAILED: 'accessible_view_list.loading.failed',
});

// 拉取我的图谱请求状态
export const UserViewListLoadingStatus = Object.freeze({
    IDLE: 'user_view_list.loading.idle',
    PROCESSING: 'user_view_list.loading.waiting',
    SUCCESS: 'user_view_list.loading.success',
    FAILED: 'user_view_list.loading.failed',
});

// 拉取我是组长图谱请求状态
export const UserIsMasterViewListLoadingStatus = Object.freeze({
    IDLE: 'user_is_master_view_list.loading.idle',
    PROCESSING: 'user_is_master_view_list.loading.waiting',
    SUCCESS: 'user_is_master_view_list.loading.success',
    FAILED: 'user_is_master_view_list.loading.failed',
});

// 拉取我是成员图谱请求状态
export const UserIsMemberViewListLoadingStatus = Object.freeze({
    IDLE: 'user_is_member_view_list.loading.idle',
    PROCESSING: 'user_is_member_view_list.loading.waiting',
    SUCCESS: 'user_is_member_view_list.loading.success',
    FAILED: 'user_is_member_view_list.loading.failed',
});

// 拉取协作图谱请求状态
export const TeamworkViewListLoadingStatus = Object.freeze({
    IDLE: 'teamwork_view_list.loading.idle',
    PROCESSING: 'teamwork_view_list.loading.waiting',
    SUCCESS: 'teamwork_view_list.loading.success',
    FAILED: 'teamwork_view_list.loading.failed',
});

// 拉取推荐图谱请求状态
export const RecommendViewListLoadingStatus = Object.freeze({
    IDLE: 'recommend_view_list.loading.idle',
    PROCESSING: 'recommend_view_list.loading.waiting',
    SUCCESS: 'recommend_view_list.loading.success',
    FAILED: 'recommend_view_list.loading.failed',
});

// 获取首页图谱列表（未登录下所有可访问图谱）
export const GuestPublicViewListLoadingStatus = Object.freeze({
    IDLE: 'guest_public_view_list.loading.idle',
    PROCESSING: 'guest_public_view_list.loading.waiting',
    SUCCESS: 'guest_public_view_list.loading.success',
    FAILED: 'guest_public_view_list.loading.failed',
});

// 获取首页免费图谱列表（未登录下所有免费图谱）
export const GuestPublicViewListOfFreeLoadingStatus = Object.freeze({
    IDLE: 'guest_public_view_list_of_free.loading.idle',
    PROCESSING: 'guest_public_view_list_of_free.loading.waiting',
    SUCCESS: 'guest_public_view_list_of_free.loading.success',
    FAILED: 'guest_public_view_list_of_free.loading.failed',
});

export const SummaryViewDataLoadingStatus = Object.freeze({
    IDLE: 'summary_view_data.loading.idle',
    PROCESSING: 'summary_view_data.loading.waiting',
    SUCCESS: 'summary_view_data.loading.success',
    FAILED: 'summary_view_data.loading.failed',
});

export const SummaryHotWordsLoadingStatus = Object.freeze({
    IDLE: 'summary_hot_words.loading.idle',
    PROCESSING: 'summary_hot_words.loading.waiting',
    SUCCESS: 'summary_hot_words.loading.success',
    FAILED: 'summary_hot_words.loading.failed',
});

// 通过名称搜索图谱请求状态
export const SearchViewListLoadingStatus = Object.freeze({
    IDLE: 'search_view_list.loading.idle',
    PROCESSING: 'search_view_list.loading.waiting',
    SUCCESS: 'search_view_list.loading.success',
    FAILED: 'search_view_list.loading.failed',
});

// 通过看板内节点搜索图谱请求状态
export const SearchViewListByNodeLoadingStatus = Object.freeze({
    IDLE: 'search_view_list_by_node.loading.idle',
    PROCESSING: 'search_view_list_by_node.loading.waiting',
    SUCCESS: 'search_view_list_by_node.loading.success',
    FAILED: 'search_view_list_by_node.loading.failed',
});


export const nodebyViewListLoadingStatus = Object.freeze({
    IDLE: 'node_by_view_list.loading.idle',
    PROCESSING: 'node_by_view_list.loading.waiting',
    SUCCESS: 'node_by_view_list.loading.success',
    FAILED: 'node_by_view_list.loading.failed',
});

export const subGraphNodesListLoadingStatus = Object.freeze({
    IDLE: 'sub_graph_nodes_list.loading.idle',
    PROCESSING: 'sub_graph_nodes_list.loading.waiting',
    SUCCESS: 'sub_graph_nodes_list.loading.success',
    FAILED: 'sub_graph_nodes_list.loading.failed',
});

@bindUtil.asSourceClass
class ViewManager {
    static userViewList = []; // 我的图谱列表
    static userIsMasterViewList = []; // 我的图谱列表
    static userIsMemberViewList = []; // 我的图谱列表
    static teamworkViewList = []; // 协作（个人）图谱列表
    static publicViewList = []; // 公开图谱列表
    static accessibleViewList = []; // 可访问的图谱列表
    static publishedViewList = []; // 上架图谱列表
    static publishedFreeViewList = []; // 免费图谱列表
    static publishedNoneFreeViewList = []; // 收费图谱列表
    static recommendViewList = []; // 推荐图谱列表
    static guestPublicViewList = []; // 获取首页图谱列表（未登录下所有可访问图谱）
    static guestPublicViewListOfFree = []; // 获取首页免费图谱列表（未登录下所有免费图谱）
    static summaryViewData = {nodes: [], edges: []}; // 汇总图数据
    static summaryHotWords = []; // 最热关注点
    static searchViewList = []; // 搜索图谱的结果列表
    static searchViewListByNode = []; // 搜索图谱的结果列表

    // 上架图谱
    static publishedViewListLoadingStatus = {
        status: PublishedViewListLoadingStatus.IDLE,
        errorCode: 0,
        errorMsg: '',
        // params: undefined,
    };

    // 公开图谱
    static publicViewListLoadingStatus = {
        status: PublicViewListLoadingStatus.IDLE,
        errorCode: 0,
        errorMsg: '',
    };

    // 可访问的图谱
    static accessibleViewListLoadingStatus = {
        status: AccessibleViewListLoadingStatus.IDLE,
        errorCode: 0,
        errorMsg: '',
    };

    // （个人）我的图谱
    static userViewListLoadingStatus = {
        status: UserViewListLoadingStatus.IDLE,
        errorCode: 0,
        errorMsg: '',
    };

    // 我是组长图谱
    static userIsMasterViewListLoadingStatus = {
        status: UserIsMasterViewListLoadingStatus.IDLE,
        errorCode: 0,
        errorMsg: '',
    };

    // 我是成员图谱
    static userIsMemberViewListLoadingStatus = {
        status: UserIsMemberViewListLoadingStatus.IDLE,
        errorCode: 0,
        errorMsg: '',
    };

    // （个人）协作图谱
    static teamworkViewListLoadingStatus = {
        status: TeamworkViewListLoadingStatus.IDLE,
        errorCode: 0,
        errorMsg: '',
    };

    // 推荐图谱
    static recommendViewListLoadingStatus = {
        status: RecommendViewListLoadingStatus.IDLE,
        errorCode: 0,
        errorMsg: '',
    };

    // 获取首页图谱列表（未登录下所有可访问图谱）
    static guestPublicViewListLoadingStatus = {
        status: GuestPublicViewListLoadingStatus.IDLE,
        errorCode: 0,
        errorMsg: '',
    };

    // 获取首页免费图谱列表（未登录下所有免费图谱）
    static guestPublicViewListOfFreeLoadingStatus = {
        status: GuestPublicViewListOfFreeLoadingStatus.IDLE,
        errorCode: 0,
        errorMsg: '',
    };

    // ??
    static summaryViewDataLoadingStatus = {
        status: SummaryViewDataLoadingStatus.IDLE,
        errorCode: 0,
        errorMsg: '',
    };

    // ??
    static summaryHotWordsLoadingStatus = {
        status: SummaryHotWordsLoadingStatus.IDLE,
        errorCode: 0,
        errorMsg: '',
    };

    // 可访问的图谱
    static searchViewListLoadingStatus = {
        status: SearchViewListLoadingStatus.IDLE,
        errorCode: 0,
        errorMsg: '',
    };
    // 可访问的图谱
    static searchViewListByNodeLoadingStatus = {
        status: SearchViewListByNodeLoadingStatus.IDLE,
        errorCode: 0,
        errorMsg: '',
    };

    // 节点关联点
    static nodebyViewListLoadingStatus = {
        status: nodebyViewListLoadingStatus.IDLE,
        errorCode: 0,
        errorMsg: '',
    };

    /**
     * 图谱上架
     *
     * @param {string} viewId 要上架的视图ID
     * @param {number} price 视图价格
     *
     * @return {Promise}
     */
    static publishToStore = (viewId, price) => {
        price = Math.max(0, price);
        return new Promise((resolve, reject) => {
            API_PublishViewToStore(viewId, price).then(response => {
                if (response && response.data && response.data.code === 0) {
                    /*if (ViewManager.publishedViewListLoadingStatus.status === PublishedViewListLoadingStatus.SUCCESS) {
                      // noinspection JSIgnoredPromiseFromCall
                      ViewManager.loadPublishedViewList(ViewManager.publishedViewListLoadingStatus.params);
                    }*/
                    if (ViewManager.userViewListLoadingStatus.status === UserViewListLoadingStatus.SUCCESS) {
                        for (let i = 0; i < ViewManager.userViewList.length; i++) {
                            if (ViewManager.userViewList[i].viewId === response.data.data.viewId) {
                                ViewManager.userViewList[i] = {...ViewManager.userViewList[i], ...response.data.data};
                                break;
                            }
                        }
                    }
                    resolve(response.data.data);
                } else {
                    reject(ViewManager.getErrorInfo(response));
                }
            }).catch(error => {
                reject(ViewManager.getErrorInfo(error));
            });
        });
    };

    /**
     * 图谱下架
     *
     * @param {string} viewId 要下架的视图ID
     *
     * @return {Promise}
     */
    static removeFromStore = viewId => {
        return new Promise((resolve, reject) => {
            API_RemoveViewFromStore(viewId).then(response => {
                if (response && response.data && response.data.code === 0) {
                    /*if (ViewManager.publishedViewListLoadingStatus.status === PublishedViewListLoadingStatus.SUCCESS) {
                      // noinspection JSIgnoredPromiseFromCall
                      ViewManager.loadPublishedViewList(ViewManager.publishedViewListLoadingStatus.params);
                    }*/
                    if (ViewManager.userViewListLoadingStatus.status === UserViewListLoadingStatus.SUCCESS) {
                        for (let i = 0; i < ViewManager.userViewList.length; i++) {
                            if (ViewManager.userViewList[i].viewId === response.data.data.viewId) {
                                ViewManager.userViewList[i] = {...ViewManager.userViewList[i], ...response.data.data};
                                break;
                            }
                        }
                    }
                    resolve(response.data.data);
                } else {
                    reject(ViewManager.getErrorInfo(response));
                }
            }).catch(error => {
                reject(ViewManager.getErrorInfo(error));
            });
        });
    };

    /**
     * 加载我的图谱（包含创建的、购买的）
     * 20190813改为自己创建的图谱，不包括协作图谱
     * @return {Promise}
     */
    static loadUserViewList = (userId, params = {}) => {
        return new Promise((resolve, reject) => {
            if (ViewManager.userViewListLoadingStatus.status === UserViewListLoadingStatus.PROCESSING) {
                // 正在加载，忽略该请求
                reject({code: 102, msg: 'Request is already processing.'});
                return;
            }
            ViewManager.userViewListLoadingStatus = {
                status: UserViewListLoadingStatus.PROCESSING,
                errorCode: 0,
                errorMsg: '',
            };
            ViewManager.userViewList = [];
            // API_GetUserViewList(userId, params).then(response => {
            API_GetMyViewList(userId, params).then(response => {
                if (response && response.data && response.data.code === 0) {
                    ViewManager.userViewList = response.data.data;
                    ViewManager.userViewListLoadingStatus = {
                        status: UserViewListLoadingStatus.SUCCESS,
                        errorCode: 0,
                        errorMsg: '',
                    };
                    resolve(ViewManager.userViewList);
                } else {
                    const {msg, code} = ViewManager.getErrorInfo(response);
                    ViewManager.userViewListLoadingStatus = {
                        status: UserViewListLoadingStatus.SUCCESS,
                        errorCode: code,
                        errorMsg: msg,
                    };
                    reject({msg, code});
                }
            }).catch(error => {
                const {msg, code} = ViewManager.getErrorInfo(error);
                ViewManager.userViewListLoadingStatus = {
                    status: UserViewListLoadingStatus.FAILED,
                    errorCode: code,
                    errorMsg: msg,
                };
                reject({msg, code});
            });
        });
    };

    /**
     * 加载我是组长图谱（包含创建的、购买的）
     * @return {Promise}
     */
    static loadUserIsMasterViewList = (userId, {q, start, limit, e, channel}) => {
        if (start === 0) {
            return new Promise((resolve, reject) => {
                if (ViewManager.userIsMasterViewListLoadingStatus.status === UserIsMasterViewListLoadingStatus.PROCESSING) {
                    // 正在加载，忽略该请求
                    reject({code: 102, msg: 'Request is already processing.'});
                    return;
                }
                ViewManager.userIsMasterViewListLoadingStatus = {
                    status: UserIsMasterViewListLoadingStatus.PROCESSING,
                    errorCode: 0,
                    errorMsg: '',
                };
                ViewManager.userIsMasterViewList = [];
                // API_GetUserViewList(userId, params).then(response => {
                API_GetMyIsMasterViewList(userId, {q, start, limit, e, channel}).then(response => {
                    if (response && response.data && response.data.code === 0) {
                        ViewManager.userIsMasterViewList = response.data.data;
                        ViewManager.userIsMasterViewListLoadingStatus = {
                            status: UserIsMasterViewListLoadingStatus.SUCCESS,
                            errorCode: 0,
                            errorMsg: '',
                        };
                        resolve(ViewManager.userIsMasterViewList);
                    } else {
                        const {msg, code} = ViewManager.getErrorInfo(response);
                        ViewManager.userIsMasterViewListLoadingStatus = {
                            status: UserIsMasterViewListLoadingStatus.SUCCESS,
                            errorCode: code,
                            errorMsg: msg,
                        };
                        reject({msg, code});
                    }
                }).catch(error => {
                    const {msg, code} = ViewManager.getErrorInfo(error);
                    ViewManager.userIsMasterViewListLoadingStatus = {
                        status: UserIsMasterViewListLoadingStatus.FAILED,
                        errorCode: code,
                        errorMsg: msg,
                    };
                    reject({msg, code});
                });
            });
        } else {
            return new Promise((resolve, reject) => {
                API_GetMyIsMasterViewList(userId, {q, start, limit, e, channel}).then(response => {
                    if (response && response.data && response.data.code === 0) {
                        let result = response.data.data;
                        resolve(result);
                    } else {
                        reject(ViewManager.getErrorInfo(response));
                    }
                }).catch(error => {
                    reject(ViewManager.getErrorInfo(error));
                });
            });
        }
    };

    /**
     * 加载我是成员图谱（不包含创建的）
     * @return {Promise}
     */
    static loadUserIsMemberViewList = (userId, {q, start, limit, channel}) => {
        if (start === 0) {
            return new Promise((resolve, reject) => {
                if (ViewManager.userIsMemberViewListLoadingStatus.status === UserIsMemberViewListLoadingStatus.PROCESSING) {
                    // 正在加载，忽略该请求
                    reject({code: 102, msg: 'Request is already processing.'});
                    return;
                }
                ViewManager.userIsMemberViewListLoadingStatus = {
                    status: UserIsMemberViewListLoadingStatus.PROCESSING,
                    errorCode: 0,
                    errorMsg: '',
                };
                ViewManager.userIsMemberViewList = [];

                API_GetMyIsMemberViewList(userId, {q, start, limit, channel}).then(response => {
                    if (response && response.data && response.data.code === 0) {
                        ViewManager.userIsMemberViewList = response.data.data;
                        ViewManager.userIsMemberViewListLoadingStatus = {
                            status: UserIsMemberViewListLoadingStatus.SUCCESS,
                            errorCode: 0,
                            errorMsg: '',
                        };
                        resolve(ViewManager.userIsMemberViewList);
                    } else {
                        const {msg, code} = ViewManager.getErrorInfo(response);
                        ViewManager.userIsMemberViewListLoadingStatus = {
                            status: UserIsMemberViewListLoadingStatus.SUCCESS,
                            errorCode: code,
                            errorMsg: msg,
                        };
                        reject({msg, code});
                    }
                }).catch(error => {
                    const {msg, code} = ViewManager.getErrorInfo(error);
                    ViewManager.userIsMemberViewListLoadingStatus = {
                        status: UserIsMemberViewListLoadingStatus.FAILED,
                        errorCode: code,
                        errorMsg: msg,
                    };
                    reject({msg, code});
                });
            });
        } else {
            return new Promise((resolve, reject) => {
                API_GetMyIsMemberViewList(userId, {q, start, limit, channel}).then(response => {
                    if (response && response.data && response.data.code === 0) {
                        let result = response.data.data;
                        resolve(result);
                    } else {
                        reject(ViewManager.getErrorInfo(response));
                    }
                }).catch(error => {
                    reject(ViewManager.getErrorInfo(error));
                });
            });
        }
    };

    /**
     * 加载（个人）协作图谱
     *
     * @return {Promise}
     */
    static loadTeamworkViewList = (userId, params = {}) => {
        return new Promise((resolve, reject) => {
            if (ViewManager.teamworkViewListLoadingStatus.status === TeamworkViewListLoadingStatus.PROCESSING) {
                // 正在加载，忽略该请求
                reject({code: 102, msg: 'Request is already processing.'});
                return;
            }
            ViewManager.teamworkViewListLoadingStatus = {
                status: TeamworkViewListLoadingStatus.PROCESSING,
                errorCode: 0,
                errorMsg: '',
            };
            ViewManager.teamworkViewList = [];
            API_GetTeamworkViewList(userId, params).then(response => {
                if (response && response.data && response.data.code === 0) {
                    ViewManager.teamworkViewList = response.data.data;
                    ViewManager.teamworkViewListLoadingStatus = {
                        status: TeamworkViewListLoadingStatus.SUCCESS,
                        errorCode: 0,
                        errorMsg: '',
                    };
                    resolve(ViewManager.teamworkViewList);
                } else {
                    const {msg, code} = ViewManager.getErrorInfo(response);
                    ViewManager.teamworkViewListLoadingStatus = {
                        status: TeamworkViewListLoadingStatus.SUCCESS,
                        errorCode: code,
                        errorMsg: msg,
                    };
                    reject({msg, code});
                }
            }).catch(error => {
                const {msg, code} = ViewManager.getErrorInfo(error);
                ViewManager.teamworkViewListLoadingStatus = {
                    status: TeamworkViewListLoadingStatus.FAILED,
                    errorCode: code,
                    errorMsg: msg,
                };
                reject({msg, code});
            });
        });
    };

    /**
     * 加载已上架的所有图谱
     *
     * @return {Promise}
     */
    static loadPublishedViewList = (type, {q, start, limit, channel}) => {
        if (start === 0) {
            return new Promise((resolve, reject) => {
                if (ViewManager.publishedViewListLoadingStatus.status === PublishedViewListLoadingStatus.PROCESSING) {
                    // 正在加载，忽略该请求
                    reject({code: 102, msg: 'Request is already processing.'});
                    return;
                }
                ViewManager.publishedViewList = [];
                ViewManager.publishedFreeViewList = [];
                ViewManager.publishedNoneFreeViewList = [];
                ViewManager.publishedViewListLoadingStatus = {
                    status: PublishedViewListLoadingStatus.PROCESSING,
                    errorCode: 0,
                    errorMsg: '',
                    // params,
                };
                API_GetPublishedViewList({q, start, limit, channel}).then(response => {
                    if (response && response.data && response.data.code === 0) {
                        ViewManager.publishedViewList = response.data.data.map(data => {
                            let result = {...data, ...data['viewItem']};
                            delete result['viewItem'];
                            return result;
                        });
                        ViewManager.publishedFreeViewList = ViewManager.publishedViewList
                          .filter(viewInfo => viewInfo.up === 1 && viewInfo.price === 0);
                        ViewManager.publishedNoneFreeViewList = ViewManager.publishedViewList
                          .filter(viewInfo => viewInfo.up === 1 && viewInfo.price > 0);
                        ViewManager.publishedViewListLoadingStatus = {
                            status: PublishedViewListLoadingStatus.SUCCESS,
                            errorCode: 0,
                            errorMsg: '',
                            // params,
                        };
                        resolve(ViewManager.publishedViewList);
                    } else {
                        const {msg, code} = ViewManager.getErrorInfo(response);
                        ViewManager.publishedViewListLoadingStatus = {
                            status: PublishedViewListLoadingStatus.SUCCESS,
                            errorCode: code,
                            errorMsg: msg,
                            // params,
                        };
                        reject({msg, code});
                    }
                }).catch(error => {
                    const {msg, code} = ViewManager.getErrorInfo(error);
                    ViewManager.publishedViewListLoadingStatus = {
                        status: PublishedViewListLoadingStatus.FAILED,
                        errorCode: code,
                        errorMsg: msg,
                        // params,
                    };
                    reject({msg, code});
                });
            });
        } else {
            return new Promise((resolve, reject) => {
                API_GetPublishedViewList({q, start, limit, channel}).then(response => {
                    if (response && response.data && response.data.code === 0) {
                        let result = response.data.data.map(data => {
                            let result = {...data, ...data['viewItem']};
                            delete result['viewItem'];
                            return result;
                        });
                        if (type === 'free') {
                            result = result.filter(viewInfo => viewInfo.up === 1 && viewInfo.price === 0)
                        }
                        resolve(result);
                    } else {
                        reject(ViewManager.getErrorInfo(response));
                    }
                }).catch(error => {
                    reject(ViewManager.getErrorInfo(error));
                });
            });
        }
    };

    /**
     * 加载公开的所有图谱
     *
     * @return {Promise}
     */
    static loadPublicViewList = ({q, start, limit, teamwork_type, channel}) => {
        if (start === 0) {
            return new Promise((resolve, reject) => {
                if (ViewManager.publicViewListLoadingStatus.status === PublicViewListLoadingStatus.PROCESSING) {
                    // 正在加载，忽略该请求
                    reject({code: 102, msg: 'Request is already processing.'});
                    return;
                }
                ViewManager.publicViewList = [];
                ViewManager.publicViewListLoadingStatus = {
                    status: PublicViewListLoadingStatus.PROCESSING,
                    errorCode: 0,
                    errorMsg: '',
                };
                API_GetPublicViewList({q, start, limit, teamwork_type, channel}).then(response => {
                    if (response && response.data && response.data.code === 0) {
                        ViewManager.publicViewList = response.data.data.map(data => {
                            let result = {...data, ...data['viewItem']};
                            delete result['viewItem'];
                            return result;
                        });
                        ViewManager.publicViewListLoadingStatus = {
                            status: PublicViewListLoadingStatus.SUCCESS,
                            errorCode: 0,
                            errorMsg: '',
                            // params,
                        };
                        resolve(ViewManager.publicViewList);
                    } else {
                        const {msg, code} = ViewManager.getErrorInfo(response);
                        ViewManager.publicViewListLoadingStatus = {
                            status: PublicViewListLoadingStatus.SUCCESS,
                            errorCode: code,
                            errorMsg: msg,
                            // params,
                        };
                        reject({msg, code});
                    }
                }).catch(error => {
                    const {msg, code} = ViewManager.getErrorInfo(error);
                    ViewManager.publicViewListLoadingStatus = {
                        status: PublicViewListLoadingStatus.FAILED,
                        errorCode: code,
                        errorMsg: msg,
                        // params,
                    };
                    reject({msg, code});
                });
            });
        } else {
            return new Promise((resolve, reject) => {
                API_GetPublicViewList({q, start, limit, teamwork_type, channel}).then(response => {
                    if (response && response.data && response.data.code === 0) {
                        let result = response.data.data.map(data => {
                            let result = {...data, ...data['viewItem']};
                            delete result['viewItem'];
                            return result;
                        });
                        resolve(result);
                    } else {
                        reject(ViewManager.getErrorInfo(response));
                    }
                }).catch(error => {
                    reject(ViewManager.getErrorInfo(error));
                });
            });
        }
    };

    /**
     * 加载公开的所有图谱
     *
     * @return {Promise}
     */
     static loadPublicViewListAll = ({q, start, limit, teamwork_type, channel,ignore_owned_by_ai}) => {
        if (start === 0) {
            return new Promise((resolve, reject) => {
                if (ViewManager.publicViewListLoadingStatus.status === PublicViewListLoadingStatus.PROCESSING) {
                    // 正在加载，忽略该请求
                    reject({code: 102, msg: 'Request is already processing.'});
                    return;
                }
                ViewManager.publicViewList = [];
                ViewManager.publicViewListLoadingStatus = {
                    status: PublicViewListLoadingStatus.PROCESSING,
                    errorCode: 0,
                    errorMsg: '',
                };
                API_GetPublicViewListAll({q, start, limit, teamwork_type, channel,ignore_owned_by_ai}).then(response => {
                    if (response && response.data && response.data.code === 0) {
                        ViewManager.publicViewList = response.data.data.map(data => {
                            let result = {...data, ...data['viewItem']};
                            delete result['viewItem'];
                            return result;
                        });
                        ViewManager.publicViewListLoadingStatus = {
                            status: PublicViewListLoadingStatus.SUCCESS,
                            errorCode: 0,
                            errorMsg: '',
                            // params,
                        };
                        resolve(ViewManager.publicViewList);
                    } else {
                        const {msg, code} = ViewManager.getErrorInfo(response);
                        ViewManager.publicViewListLoadingStatus = {
                            status: PublicViewListLoadingStatus.SUCCESS,
                            errorCode: code,
                            errorMsg: msg,
                            // params,
                        };
                        reject({msg, code});
                    }
                }).catch(error => {
                    const {msg, code} = ViewManager.getErrorInfo(error);
                    ViewManager.publicViewListLoadingStatus = {
                        status: PublicViewListLoadingStatus.FAILED,
                        errorCode: code,
                        errorMsg: msg,
                        // params,
                    };
                    reject({msg, code});
                });
            });
        } else {
            return new Promise((resolve, reject) => {
                API_GetPublicViewListAll({q, start, limit, teamwork_type, channel,ignore_owned_by_ai}).then(response => {
                    if (response && response.data && response.data.code === 0) {
                        let result = response.data.data.map(data => {
                            let result = {...data, ...data['viewItem']};
                            delete result['viewItem'];
                            return result;
                        });
                        resolve(result);
                    } else {
                        reject(ViewManager.getErrorInfo(response));
                    }
                }).catch(error => {
                    reject(ViewManager.getErrorInfo(error));
                });
            });
        }
    };

    /**
     * 加载已上架的所有图谱
     *
     * @return {Promise}
     */
    static loadRecommendViewList = () => {
        return new Promise((resolve, reject) => {
            if (ViewManager.recommendViewListLoadingStatus.status === RecommendViewListLoadingStatus.PROCESSING) {
                // 正在加载，忽略该请求
                reject({code: 102, msg: 'Request is already processing.'});
                return;
            }
            ViewManager.recommendViewList = [];
            ViewManager.recommendViewListLoadingStatus = {
                status: RecommendViewListLoadingStatus.PROCESSING,
                errorCode: 0,
                errorMsg: '',
            };
            API_GetRecommendView().then(response => {
                if (response && response.data && response.data.code === 0) {
                    ViewManager.recommendViewList = response.data.data;
                    // console.log('loadRecommendViewList->response=',response)
                    // console.log('loadRecommendViewList->ViewManager.recommendViewList=',ViewManager.recommendViewList)
                    ViewManager.recommendViewListLoadingStatus = {
                        status: RecommendViewListLoadingStatus.SUCCESS,
                        errorCode: 0,
                        errorMsg: '',
                    };
                    resolve(ViewManager.recommendViewList);
                } else {
                    const {msg, code} = ViewManager.getErrorInfo(response);
                    ViewManager.recommendViewListLoadingStatus = {
                        status: RecommendViewListLoadingStatus.SUCCESS,
                        errorCode: code,
                        errorMsg: msg,
                    };
                    reject({msg, code});
                }
            }).catch(error => {
                const {msg, code} = ViewManager.getErrorInfo(error);
                ViewManager.recommendViewListLoadingStatus = {
                    status: RecommendViewListLoadingStatus.FAILED,
                    errorCode: code,
                    errorMsg: msg,
                };
                reject({msg, code});
            });
        });
    };

    /**
     * 加载可访问的图谱列表
     * @return {Promise}
     */
    static loadAccessibleViewList = ({q, start, limit, teamwork_type, channel}) => {
        if (start === 0) {
            return new Promise((resolve, reject) => {
                if (ViewManager.accessibleViewListLoadingStatus.status === AccessibleViewListLoadingStatus.PROCESSING) {
                    // 正在加载，忽略该请求
                    reject({code: 102, msg: 'Request is already processing.'});
                    return;
                }
                ViewManager.accessibleViewList = [];
                ViewManager.accessibleViewListLoadingStatus = {
                    status: AccessibleViewListLoadingStatus.PROCESSING,
                    errorCode: 0,
                    errorMsg: '',
                };
                API_GetAccessibleViewList({q, start, limit, teamwork_type, channel}).then(response => {
                    if (response && response.data && response.data.code === 0) {
                        ViewManager.accessibleViewList = response.data.data.map(data => {
                            let result = {...data, ...data['viewItem']};
                            delete result['viewItem'];
                            return result;
                        });
                        ViewManager.accessibleViewListLoadingStatus = {
                            status: AccessibleViewListLoadingStatus.SUCCESS,
                            errorCode: 0,
                            errorMsg: '',
                            // params,
                        };
                        resolve(ViewManager.accessibleViewList);
                    } else {
                        const {msg, code} = ViewManager.getErrorInfo(response);
                        ViewManager.accessibleViewListLoadingStatus = {
                            status: AccessibleViewListLoadingStatus.SUCCESS,
                            errorCode: code,
                            errorMsg: msg,
                            // params,
                        };
                        reject({msg, code});
                    }
                }).catch(error => {
                    const {msg, code} = ViewManager.getErrorInfo(error);
                    ViewManager.accessibleViewListLoadingStatus = {
                        status: AccessibleViewListLoadingStatus.FAILED,
                        errorCode: code,
                        errorMsg: msg,
                        // params,
                    };
                    reject({msg, code});
                });
            });
        } else {
            return new Promise((resolve, reject) => {
                API_GetAccessibleViewList({q, start, limit, teamwork_type, channel}).then(response => {
                    if (response && response.data && response.data.code === 0) {
                        let result = response.data.data.map(data => {
                            let result = {...data, ...data['viewItem']};
                            delete result['viewItem'];
                            return result;
                        });
                        resolve(result);
                    } else {
                        reject(ViewManager.getErrorInfo(response));
                    }
                }).catch(error => {
                    reject(ViewManager.getErrorInfo(error));
                });
            });
        }

    };

    /**
     * 取首页图谱列表（未登录下所有可访问图谱）
     * @param q
     * @param start
     * @param limit
     * @param teamwork_type
     * @returns {*}
     */
    static loadGuestPublicViewList = ({q, start, limit, teamwork_type, channel}) => {
        if (start === 0) {
            return new Promise((resolve, reject) => {
                if (ViewManager.guestPublicViewListLoadingStatus.status === GuestPublicViewListLoadingStatus.PROCESSING) {
                    // 正在加载，忽略该请求
                    reject({code: 102, msg: 'Request is already processing.'});
                    return;
                }
                ViewManager.guestPublicViewList = [];
                ViewManager.guestPublicViewListLoadingStatus = {
                    status: GuestPublicViewListLoadingStatus.PROCESSING,
                    errorCode: 0,
                    errorMsg: '',
                };
                API_GetGuestPublicViewList({q, start, limit, teamwork_type, channel}).then(response => {
                    if (response && response.data && response.data.code === 0) {
                        ViewManager.guestPublicViewList = response.data.data.map(data => {
                            let result = {...data, ...data['viewItem']};
                            delete result['viewItem'];
                            return result;
                        });
                        ViewManager.guestPublicViewListLoadingStatus = {
                            status: GuestPublicViewListLoadingStatus.SUCCESS,
                            errorCode: 0,
                            errorMsg: '',
                            // params,
                        };
                        resolve(ViewManager.guestPublicViewList);
                    } else {
                        const {msg, code} = ViewManager.getErrorInfo(response);
                        ViewManager.guestPublicViewListLoadingStatus = {
                            status: GuestPublicViewListLoadingStatus.SUCCESS,
                            errorCode: code,
                            errorMsg: msg,
                            // params,
                        };
                        reject({msg, code});
                    }
                }).catch(error => {
                    const {msg, code} = ViewManager.getErrorInfo(error);
                    ViewManager.guestPublicViewListLoadingStatus = {
                        status: GuestPublicViewListLoadingStatus.FAILED,
                        errorCode: code,
                        errorMsg: msg,
                        // params,
                    };
                    reject({msg, code});
                });
            });
        } else {
            return new Promise((resolve, reject) => {
                API_GetGuestPublicViewList({q, start, limit, teamwork_type, channel}).then(response => {
                    if (response && response.data && response.data.code === 0) {
                        let result = response.data.data.map(data => {
                            let result = {...data, ...data['viewItem']};
                            delete result['viewItem'];
                            return result;
                        });
                        resolve(result);
                    } else {
                        reject(ViewManager.getErrorInfo(response));
                    }
                }).catch(error => {
                    reject(ViewManager.getErrorInfo(error));
                });
            });
        }
    };

    /**
     * 获取首页免费图谱列表（未登录下所有免费图谱）
     * @param q
     * @param start
     * @param limit
     * @returns {*}
     */
    static loadGuestPublicViewListOfFree = ({q, start, limit, channel}) => {
        if (start === 0) {
            return new Promise((resolve, reject) => {
                if (ViewManager.guestPublicViewListOfFreeLoadingStatus.status === GuestPublicViewListOfFreeLoadingStatus.PROCESSING) {
                    // 正在加载，忽略该请求
                    reject({code: 102, msg: 'Request is already processing.'});
                    return;
                }
                ViewManager.guestPublicViewListOfFree = [];
                ViewManager.guestPublicViewListOfFreeLoadingStatus = {
                    status: GuestPublicViewListOfFreeLoadingStatus.PROCESSING,
                    errorCode: 0,
                    errorMsg: '',
                };
                API_GetGuestPublicViewListOfFree({q, start, limit, channel}).then(response => {
                    if (response && response.data && response.data.code === 0) {
                        ViewManager.guestPublicViewListOfFree = response.data.data.map(data => {
                            let result = {...data, ...data['viewItem']};
                            delete result['viewItem'];
                            return result;
                        });
                        ViewManager.guestPublicViewListOfFreeLoadingStatus = {
                            status: GuestPublicViewListOfFreeLoadingStatus.SUCCESS,
                            errorCode: 0,
                            errorMsg: '',
                            // params,
                        };
                        resolve(ViewManager.guestPublicViewListOfFree);
                    } else {
                        const {msg, code} = ViewManager.getErrorInfo(response);
                        ViewManager.guestPublicViewListOfFreeLoadingStatus = {
                            status: GuestPublicViewListOfFreeLoadingStatus.SUCCESS,
                            errorCode: code,
                            errorMsg: msg,
                            // params,
                        };
                        reject({msg, code});
                    }
                }).catch(error => {
                    const {msg, code} = ViewManager.getErrorInfo(error);
                    ViewManager.guestPublicViewListOfFreeLoadingStatus = {
                        status: GuestPublicViewListOfFreeLoadingStatus.FAILED,
                        errorCode: code,
                        errorMsg: msg,
                        // params,
                    };
                    reject({msg, code});
                });
            });
        } else {
            return new Promise((resolve, reject) => {
                API_GetGuestPublicViewListOfFree({q, start, limit, channel}).then(response => {
                    if (response && response.data && response.data.code === 0) {
                        let result = response.data.data.map(data => {
                            let result = {...data, ...data['viewItem']};
                            delete result['viewItem'];
                            return result;
                        });
                        resolve(result);
                    } else {
                        reject(ViewManager.getErrorInfo(response));
                    }
                }).catch(error => {
                    reject(ViewManager.getErrorInfo(error));
                });
            });
        }
    };

    /**
     * 通过名称搜索图谱列表
     * @return {Promise}
     */
    static loadSearchViewList = ({q, limit, teamwork_type, channel, tag}) => {
        return new Promise((resolve, reject) => {
            if (ViewManager.searchViewListLoadingStatus.status === SearchViewListLoadingStatus.PROCESSING) {
                // 正在加载，忽略该请求
                reject({code: 102, msg: 'Request is already processing.'});
                return;
            }
            ViewManager.searchViewList = [];
            ViewManager.searchViewListLoadingStatus = {
                status: SearchViewListLoadingStatus.PROCESSING,
                errorCode: 0,
                errorMsg: '',
            };
            API_GetSearchViewList({q, limit, teamwork_type, channel, tag}).then(response => {
                if (response && response.data && response.data.code === 0) {
                    ViewManager.searchViewList = response.data.data.map(data => {
                        let result = {...data, ...data['viewItem']};
                        delete result['viewItem'];
                        return result;
                    });
                    ViewManager.searchViewListLoadingStatus = {
                        status: SearchViewListLoadingStatus.SUCCESS,
                        errorCode: 0,
                        errorMsg: '',
                        // params,
                    };
                    resolve(ViewManager.searchViewList);
                } else {
                    const {msg, code} = ViewManager.getErrorInfo(response);
                    ViewManager.searchViewListLoadingStatus = {
                        status: SearchViewListLoadingStatus.SUCCESS,
                        errorCode: code,
                        errorMsg: msg,
                        // params,
                    };
                    reject({msg, code});
                }
            }).catch(error => {
                const {msg, code} = ViewManager.getErrorInfo(error);
                ViewManager.searchViewListLoadingStatus = {
                    status: SearchViewListLoadingStatus.FAILED,
                    errorCode: code,
                    errorMsg: msg,
                    // params,
                };
                reject({msg, code});
            });
        });

    };

    /**
     * 通过图谱内节点搜索图谱列表
     * @return {Promise}
     */
    static loadSearchViewListByNode = ({q, limit, channel}) => {
        return new Promise((resolve, reject) => {
            if (ViewManager.searchViewListByNodeLoadingStatus.status === SearchViewListByNodeLoadingStatus.PROCESSING) {
                // 正在加载，忽略该请求
                reject({code: 102, msg: 'Request is already processing.'});
                return;
            }
            ViewManager.searchViewListByNode = [];
            ViewManager.searchViewListByNodeLoadingStatus = {
                status: SearchViewListByNodeLoadingStatus.PROCESSING,
                errorCode: 0,
                errorMsg: '',
            };
            API_GetSearchViewListByNode({q, limit, channel}).then(response => {
                if (response && response.data && response.data.code === 0) {
                    ViewManager.searchViewListByNode = response.data.data.map(data => {
                        let result = {...data, ...data['viewItem']};
                        delete result['viewItem'];
                        return result;
                    });
                    ViewManager.searchViewListByNodeLoadingStatus = {
                        status: SearchViewListByNodeLoadingStatus.SUCCESS,
                        errorCode: 0,
                        errorMsg: '',
                        // params,
                    };
                    resolve(ViewManager.searchViewListByNode);
                } else {
                    const {msg, code} = ViewManager.getErrorInfo(response);
                    ViewManager.searchViewListByNodeLoadingStatus = {
                        status: SearchViewListByNodeLoadingStatus.SUCCESS,
                        errorCode: code,
                        errorMsg: msg,
                        // params,
                    };
                    reject({msg, code});
                }
            }).catch(error => {
                const {msg, code} = ViewManager.getErrorInfo(error);
                ViewManager.searchViewListByNodeLoadingStatus = {
                    status: SearchViewListByNodeLoadingStatus.FAILED,
                    errorCode: code,
                    errorMsg: msg,
                    // params,
                };
                reject({msg, code});
            });
        });

    };

    static orderView = viewId => {
        return new Promise((resolve, reject) => {
            API_OrderView(viewId).then(response => {
                if (response && response.data && response.data.code === 0) {
                    resolve({url: response.data.data, orderId: response.data['orderId']});
                } else {
                    reject(ViewManager.getErrorInfo(response));
                }
            }).catch(error => {
                reject(ViewManager.getErrorInfo(error));
            });
        });
    };

    static orderFreeView = viewId => {
        return new Promise((resolve, reject) => {
            API_OrderFreeView(viewId).then(response => {
                console.log('orderFreeView前的 viewId=', viewId);
                console.log('orderFreeView后的返回 response=', response);
                if (response && response.data && response.data.code === 0) {
                    resolve(response.data.data);
                } else {
                    reject(ViewManager.getErrorInfo(response));
                }
            }).catch(error => {
                reject(ViewManager.getErrorInfo(error));
            });
        });
    };

    /**
     * 订单查询
     * 查询订单的完成情况
     *
     * @param orderId
     * @returns {Promise<any>}
     */
    static queryOrder = orderId => {
        return new Promise((resolve, reject) => {
            API_QueryOrder(orderId).then(response => {
                // console.log('queryOrder后的返回 response=',response);
                if (response && response.data && response.data.code === 0) {
                    resolve(response.data.data);
                } else {
                    reject(ViewManager.getErrorInfo(response));
                }
            }).catch(error => {
                reject(ViewManager.getErrorInfo(error));
            });
        });
    };

    /**
     * 加载图谱协作成员列表
     *
     * @param viewId {string} 图谱ID
     * @param limit {number} 返回数量限制
     * @param orderBy {string} 排序依据
     * @param orderType {string} 排序方式
     * @param start {number} 起始位置
     * @return {Promise}
     */
    static loadViewTeamworkMembers = (viewId, limit = -1, orderBy = undefined, orderType = undefined, start = 0) => {
        return new Promise((resolve, reject) => {
            API_GetTeamworkMembers(viewId, limit, orderBy, orderType, start).then(response => {
                if (response && response.data && response.data.code === 0) {
                    resolve(response.data.data);
                } else {
                    reject(ViewManager.getErrorInfo(response));
                }
            }).catch(error => {
                reject(ViewManager.getErrorInfo(error));
            });
        });
    };

    /**
     * 添加图谱协作成员
     *
     * @param viewId 图谱ID
     * @param userId 用户UUID
     * @param token 协作ID
     * @return {Promise}
     */
    static addViewTeamworkMember = (viewId, userId, token) => {
        return new Promise((resolve, reject) => {
            API_AddTeamworkMember(viewId, userId, token).then(response => {
                if (response && response.data && response.data.code === 0) {
                    resolve();
                } else {
                    reject(ViewManager.getErrorInfo(response));
                }
            }).catch(error => {
                reject(ViewManager.getErrorInfo(error));
            });
        });
    };

    /**
     * 移除图谱协作成员
     *
     * @param viewId 图谱ID
     * @param userId 用户UUID
     * @return {Promise}
     */
    static removeViewTeamworkMember = (viewId, userId) => {
        return new Promise((resolve, reject) => {
            API_RemoveTeamworkMember(viewId, userId).then(response => {
                if (response && response.data && response.data.code === 0) {
                    resolve();
                } else {
                    reject(ViewManager.getErrorInfo(response));
                }
            }).catch(error => {
                reject(ViewManager.getErrorInfo(error));
            });
        });
    };

    /**
     * 获取图谱协作配置
     *
     * @param viewId 图谱ID
     * @param key 访问密码
     * @returns {Promise}
     */
    static loadViewTeamworkSettings = (viewId, key = undefined) => {
        return new Promise((resolve, reject) => {
            API_LoadTeamworkSettings(viewId, key).then(response => {
                if (response && response.data && response.data.code === 0) {
                    resolve(response.data.data);
                } else {
                    reject(ViewManager.getErrorInfo(response));
                }
            }).catch(error => {
                reject(ViewManager.getErrorInfo(error));
            });
        });
    };

    /**
     * 设置图谱协作配置
     *
     * @param viewId 图谱ID
     * @param type 协作类型
     * @param autoSetSharingType 自动加入公开图谱
     * @returns {Promise}
     */
    static setViewTeamworkSettings = (viewId, type, autoSetSharingType = 1) => {
        return new Promise((resolve, reject) => {
            API_SetTeamworkSettings(viewId, type, autoSetSharingType).then(response => {
                if (response && response.data && response.data.code === 0) {
                    resolve();
                } else {
                    reject(ViewManager.getErrorInfo(response));
                }
            }).catch(error => {
                reject(ViewManager.getErrorInfo(error));
            });
        });
    };

    /**
     * 部分设置图谱协作配置
     *
     * @param viewId 图谱ID
     * @param type 协作类型
     * @param autoSetSharingType 自动加入公开图谱
     * @param meta 额外元信息
     * @returns {Promise}
     */
    static setViewTeamworkSettingsPartially = (viewId, type = undefined, autoSetSharingType = 1, meta) => {
        return new Promise((resolve, reject) => {
            API_SetTeamworkSettingsPartially(viewId, type, autoSetSharingType, meta).then(response => {
                if (response && response.data && response.data.code === 0) {
                    resolve();
                } else {
                    reject(ViewManager.getErrorInfo(response));
                }
            }).catch(error => {
                reject(ViewManager.getErrorInfo(error));
            });
        });
    };

    /**
     * 加载图谱凭据列表
     *
     * @param viewId 图谱ID
     * @param parameters 请求参数
     * @return {Promise}
     */
    static loadViewTeamworkTickets = (viewId, parameters) => {
        return new Promise((resolve, reject) => {
            API_LoadTeamworkTickets(viewId, parameters).then(response => {
                if (response && response.data && response.data.code === 0) {
                    resolve(response.data);
                } else {
                    reject(ViewManager.getErrorInfo(response));
                }
            }).catch(error => {
                reject(ViewManager.getErrorInfo(error));
            });
        });
    };

    /**
     * 添加门票
     *
     * @param viewId 图谱ID
     * @param config 门票信息
     * @return {Promise}
     */
    static addViewTeamworkTicket = (viewId, config) => {
        return new Promise((resolve, reject) => {
            API_AddTeamworkTicket(viewId, config).then(response => {
                if (response && response.data && response.data.code === 0) {
                    resolve(response.data.data);
                } else {
                    reject(ViewManager.getErrorInfo(response));
                }
            }).catch(error => {
                reject(ViewManager.getErrorInfo(error));
            });
        });
    };

    /**
     * 删除门票
     *
     * @param viewId 图谱ID
     * @param code 门票ID
     * @return {Promise}
     */
    static removeViewTeamworkTicket = (viewId, code) => {
        return new Promise((resolve, reject) => {
            API_RemoveTeamworkTicket(viewId, code).then(response => {
                if (response && response.data && response.data.code === 0) {
                    resolve(response.data.data);
                } else {
                    reject(ViewManager.getErrorInfo(response));
                }
            }).catch(error => {
                reject(ViewManager.getErrorInfo(error));
            });
        });
    };

    /**
     * 将一个用户的所有图谱相关信息转移到另一个用户中
     *
     * @param accessToken
     * @return {Promise}
     */
    static moveViewInfoByAccessToken = accessToken => {
        return new Promise((resolve, reject) => {
            API_MoveViewInfoByAccessToken(accessToken).then(response => {
                const data = response && response.data ? response.data.data : undefined;
                if (response && response.data && response.data.code === 0) {
                    resolve(data);
                } else {
                    reject({...ViewManager.getErrorInfo(response), data});
                }
            }).catch(error => {
                reject({...ViewManager.getErrorInfo(error), data: undefined});
            });
        });
    };

    /**
     * 将一个用户的所有交易相关信息转移到另一个用户中
     *
     * @param accessToken
     * @return {Promise}
     */
    static moveTransInfoByAccessToken = accessToken => {
        return new Promise((resolve, reject) => {
            API_MoveTransInfoByAccessToken(accessToken).then(response => {
                const data = response && response.data ? response.data.data : undefined;
                if (response && response.data && response.data.code === 0) {
                    resolve(data);
                } else {
                    reject({...ViewManager.getErrorInfo(response), data});
                }
            }).catch(error => {
                reject({...ViewManager.getErrorInfo(error), data: undefined});
            });
        });
    };

    /**
     * 获取图谱封面信息
     *
     * @param {string} viewId 图谱ID
     * @return {Promise}
     */
    static loadViewCover = viewId => {
        return new Promise((resolve, reject) => {
            API_GetViewCover(viewId).then(response => {
                const data = response && response.data ? response.data.data : undefined;
                if (response && response.data && response.data.code === 0) {
                    resolve(data === null ? undefined : data);
                } else {
                    reject({...ViewManager.getErrorInfo(response)});
                }
            }).catch(error => {
                reject({...ViewManager.getErrorInfo(error)});
            });
        });
    };

    /**
     * 获取公开图谱封面信息
     *
     * @param {string} viewId 图谱ID
     * @return {Promise}
     */
    static loadViewPublicCover = viewId => {
        return new Promise((resolve, reject) => {
            API_GetViewPublicCover(viewId).then(response => {
                const data = response && response.data ? response.data.data : undefined;
                if (response && response.data && response.data.code === 0) {
                    resolve(data === null ? undefined : data);
                } else {
                    reject({...ViewManager.getErrorInfo(response)});
                }
            }).catch(error => {
                reject({...ViewManager.getErrorInfo(error)});
            });
        });
    };

    /**
     * 设置图谱封面信息
     *
     * @param {string} viewId 图谱ID
     * @param {string} coverData 封面图像数据
     * @return {Promise}
     */
    static setViewCover = (viewId, coverData = undefined) => {
        return new Promise((resolve, reject) => {
            API_SetViewCover(viewId, coverData).then(response => {
                const data = response && response.data ? response.data.data : undefined;
                if (response && response.data && response.data.code === 0) {
                    resolve(data === null ? undefined : data);
                } else {
                    reject({...ViewManager.getErrorInfo(response)});
                }
            }).catch(error => {
                reject({...ViewManager.getErrorInfo(error)});
            });
        });
    };

    /**
     * @private
     * 通過Axios请求返回的错误信息获取错误详情
     *
     * @param {*} error
     *
     * @return {{msg: string, code: number}}
     */
    static getErrorInfo = commonGetErrorInfo;

    //-------shilei 190309 add------------

    /**
     * 编辑图谱信息
     *
     * @param newInfo 新图谱的信息
     * @param userId 创建人
     * @returns {Promise<any>}
     */
    static createView = (newInfo, userId) => {
        return new Promise((resolve, reject) => {
            API_AddNewView(newInfo, userId).then(response => {
                if (response && response.data && response.data.code === 0) {
                    resolve(response.data.data); // 返回新添加的视图信息
                } else {
                    reject(ViewManager.getErrorInfo(response));
                }
            }).catch(error => {
                reject(ViewManager.getErrorInfo(error));
            });
        });
    };

    /**
     * 获取指定图谱信息
     *
     * @param viewId 目标图谱的id
     * @returns {Promise<any>}
     */
    static getViewById = (viewId) => {
        return new Promise((resolve, reject) => {
            API_GetViewById(viewId).then(response => {
                console.log('查询后的返回 response=',response);
                if (response && response.data && response.data.code === 0) {
                    resolve(response.data.data);
                } else {
                    reject(ViewManager.getErrorInfo(response));
                }
            }).catch(error => {
                reject(ViewManager.getErrorInfo(error));
            });
        });
    };

    /**
     * 编辑图谱信息
     *
     * @param viewId 目标图谱的id
     * @param newInfo 新信息包含：name desc
     * @returns {Promise<any>}
     */
    static updateView = (viewId, newInfo) => {
        return new Promise((resolve, reject) => {
            API_UpdateView(viewId, newInfo).then(response => {
                if (response && response.data && response.data.code === 0) {
                    resolve(response.data.data); // 返回更新后视图的信息
                } else {
                    reject(ViewManager.getErrorInfo(response));
                }
            }).catch(error => {
                reject(ViewManager.getErrorInfo(error));
            });
        });
    };

    /**
     * 删除图谱
     *
     * @param viewId 目标图谱的id
     * @returns {Promise<any>}
     */
    static deleteView = (viewId) => {
        return new Promise((resolve, reject) => {
            API_DeleteView(viewId).then(response => {
                // console.log('删除后的返回 response=',response)
                if (response && response.data && response.data.code === 0) {
                    resolve(response.data.data); // fixme:返回了删除以后用户的图谱列表？？
                } else {
                    reject(ViewManager.getErrorInfo(response));
                }
            }).catch(error => {
                reject(ViewManager.getErrorInfo(error));
            });
        });
    };

    /**
     * 设置图谱的推荐状态
     *
     * @param viewId 目标图谱的id
     * @param recommend 推荐状态
     * @returns {Promise<any>}
     */
    static recommendView = (viewId, recommend) => {
        // 转换 0 1
        if (recommend === true) {
            recommend = 1
        } else {
            recommend = 0
        }
        return new Promise((resolve, reject) => {
            API_RecommendView(viewId, recommend).then(response => {
                // console.log('recommendView response=', response)
                if (response && response.data && response.data.code === 0) {
                    resolve(response.data.data);
                } else {
                    reject(ViewManager.getErrorInfo(response));
                }
            }).catch(error => {
                reject(ViewManager.getErrorInfo(error));
            });
        });
    };

    /**
     * 将图谱转到指定用户下
     * @param viewId
     * @param userId
     * @returns {*}
     */
    static viewTransferToUser = (viewId, userId) => {
        return new Promise((resolve, reject) => {
            API_ViewTransferToUser(viewId, userId).then(response => {
                // console.log('recommendView response=', response)
                if (response && response.data && response.data.code === 0) {
                    resolve(response.data);
                } else {
                    reject(ViewManager.getErrorInfo(response));
                }
            }).catch(error => {
                reject(ViewManager.getErrorInfo(error));
            });
        });
    };

    /**
     * 获取图谱标签列表
     * @param q
     * @param start
     * @param limit
     * @returns {*}
     */
    static getViewTags = ({q, start, limit}) => {
        return new Promise((resolve, reject) => {
            API_GetViewTags({q, start, limit}).then(response => {
                // console.log('recommendView response=', response)
                if (response && response.data && response.data.code === 0) {
                    resolve(response.data);
                } else {
                    reject(ViewManager.getErrorInfo(response));
                }
            }).catch(error => {
                reject(ViewManager.getErrorInfo(error));
            });
        });
    };

    /**
     * 获取跨图谱用户配置信息
     * @param key
     * @param forDisplayOnly
     * @returns {*}
     */
    static getCrossViewConfig = (key, forDisplayOnly) => {
        return new Promise((resolve, reject) => {
            API_LoadViewUserConfig("$mgc_cross_view", key, forDisplayOnly).then(response => {
                // console.log('recommendView response=', response)
                if (response && response.data && response.data.code === 0) {
                    resolve(response.data);
                } else {
                    reject(ViewManager.getErrorInfo(response));
                }
            }).catch(error => {
                reject(ViewManager.getErrorInfo(error));
            });
        });
    };

    /**
     * 更新跨图谱获取用户配置信息
     * @param key
     * @param config
     * @param replaceAll
     * @returns {*}
     */
    static updateCrossViewConfig = (key, config, replaceAll = true) => {
        return new Promise((resolve, reject) => {
            API_UpdateViewUserConfig("$mgc_cross_view", key, config, replaceAll).then(response => {
                // console.log('recommendView response=', response)
                if (response && response.data && response.data.code === 0) {
                    resolve(response.data);
                } else {
                    reject(ViewManager.getErrorInfo(response));
                }
            }).catch(error => {
                reject(ViewManager.getErrorInfo(error));
            });
        });
    };

    /**
     * 获取关系图子图截图 -> 查询是否有快照
     * @param viewId
     * @param params
     * @returns {*}
     */
    static hasSubGraphSnapshot = (viewId, params) => {
        return new Promise((resolve, reject) => {
            API_HasSubGraphSnapshot(viewId, params).then(response => {
                // console.log('recommendView response=', response)
                if (response && response.data && response.data.code === 0) {
                    resolve(response.data);
                } else {
                    reject(ViewManager.getErrorInfo(response));
                }
            }).catch(error => {
                reject(ViewManager.getErrorInfo(error));
            });
        });
    };

    /**
     * 节点及关联点
     * @return {Promise}
     */
     static loadTopNodesList = (viewId, {start, limit}) => {
        return new Promise((resolve, reject) => {
            ViewManager.topNodesList = [];
            API_GetRelationNodeList(viewId, {userConfirmed: 1}, start, limit, 'sequence_node_ex', 'DESC').then(response => {
                if (response && response.data && response.data.code === 0) {
                    ViewManager.topNodesList = response.data.data;
                    ViewManager.nodebyViewListLoadingStatus = {
                        status: nodebyViewListLoadingStatus.SUCCESS,
                        errorCode: 0,
                        errorMsg: '',
                    };
                    resolve(ViewManager.topNodesList);
                } else {
                    const {msg, code} = ViewManager.getErrorInfo(response);
                    ViewManager.nodebyViewListLoadingStatus = {
                        status: nodebyViewListLoadingStatus.SUCCESS,
                        errorCode: code,
                        errorMsg: msg,
                    };
                    reject({msg, code});
                }
            }).catch(error => {
                const {msg, code} = ViewManager.getErrorInfo(error);
                ViewManager.nodebyViewListLoadingStatus = {
                    status: nodebyViewListLoadingStatus.FAILED,
                    errorCode: code,
                    errorMsg: msg,
                };
                reject({msg, code});
            });
        });
    };

    /**
     * 节点及关联点
     * @return {Promise}
     */
     static loadSubGraphNodesList = (viewId, params) => {
        return new Promise((resolve, reject) => {
            ViewManager.subGraphNodesList = [];
            API_GetSubGraph(viewId,params).then(response => {
                if (response && response.data && response.data.code === 0) {
                    ViewManager.subGraphNodesList = response.data.data;
                    ViewManager.subGraphNodesListLoadingStatus = {
                        status: subGraphNodesListLoadingStatus.SUCCESS,
                        errorCode: 0,
                        errorMsg: '',
                    };
                    resolve(ViewManager.subGraphNodesList);
                } else {
                    const {msg, code} = ViewManager.getErrorInfo(response);
                    ViewManager.subGraphNodesListLoadingStatus = {
                        status: subGraphNodesListLoadingStatus.SUCCESS,
                        errorCode: code,
                        errorMsg: msg,
                    };
                    reject({msg, code});
                }
            }).catch(error => {
                const {msg, code} = ViewManager.getErrorInfo(error);
                ViewManager.subGraphNodesListLoadingStatus = {
                    status: subGraphNodesListLoadingStatus.FAILED,
                    errorCode: code,
                    errorMsg: msg,
                };
                reject({msg, code});
            });
        });
    };
}

export default ViewManager;