import {bindUtil, withPromiseMessageDecorator} from '@/libs/core-decorators';
import messageProxy from '@/components/common/common.messageProxy';
import {getErrorInfo as commonGetErrorInfo} from '@/components/common/common.functions';
import {microServiceOperationMessages} from '@/constants/messages';
import {
  API_LoadMicroServiceConfigList,
  API_AddMicroServiceConfig,
  API_UpdateMicroServiceConfig,
  API_RemoveMicroServiceConfig,
  API_CallMicroService,
  API_MyMicroServiceConfigList,
  API_RecommendMicroServiceConfigList,
  API_GetMicroServiceListByIds
} from '@/api/microService';
import {API_GetRelationNodeList,API_GetRelationNodeListByIds} from "@/libs/view/network/api";
import {API_esMicroServiceQuery,API_esMicroServicePresentationQuery} from "@/api/relation";

@bindUtil.asSourceClass
class MicroServiceDataProvider {
  static loadConfigList = (projectId, enabledOnly = true, tag, query = '', start = 0, limit = 15, orderBy = 'update_timestamp', orderType = 'desc') => {
    return new Promise((resolve, reject) => {
      API_LoadMicroServiceConfigList({projectId, enabled: enabledOnly ? true : undefined, tag, query, start, limit, orderBy, orderType}).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve(response.data);
        } else {
          reject({...MicroServiceDataProvider.getErrorInfo(response)});
        }
      }).catch(error => {
        reject({...MicroServiceDataProvider.getErrorInfo(error)});
      });
    });
  };

  static myConfigList = (tag, start = 0, limit = 15) => {
    return new Promise((resolve, reject) => {
      API_MyMicroServiceConfigList({tag, start, limit}).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve(response.data);
        } else {
          reject({...MicroServiceDataProvider.getErrorInfo(response)});
        }
      }).catch(error => {
        reject({...MicroServiceDataProvider.getErrorInfo(error)});
      });
    });
  };

  static recommendConfigList = (tag, start = 0, limit = 15) => {
    return new Promise((resolve, reject) => {
      API_RecommendMicroServiceConfigList({tag, start, limit}).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve(response.data);
        } else {
          reject({...MicroServiceDataProvider.getErrorInfo(response)});
        }
      }).catch(error => {
        reject({...MicroServiceDataProvider.getErrorInfo(error)});
      });
    });
  };

  static addConfig = (config) => {
    return new Promise((resolve, reject) => {
      API_AddMicroServiceConfig(config).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve(response.data.data);
        } else {
          reject({...MicroServiceDataProvider.getErrorInfo(response)});
        }
      }).catch(error => {
        reject({...MicroServiceDataProvider.getErrorInfo(error)});
      });
    });
  };

  static updateConfig = (microServiceId, config) => {
    return new Promise((resolve, reject) => {
      API_UpdateMicroServiceConfig(microServiceId, config).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve(response.data.data);
        } else {
          reject({...MicroServiceDataProvider.getErrorInfo(response)});
        }
      }).catch(error => {
        reject({...MicroServiceDataProvider.getErrorInfo(error)});
      });
    });
  };

  static removeConfig = (microServiceId) => {
    return new Promise((resolve, reject) => {
      API_RemoveMicroServiceConfig(microServiceId).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve(response.data.data);
        } else {
          reject({...MicroServiceDataProvider.getErrorInfo(response)});
        }
      }).catch(error => {
        reject({...MicroServiceDataProvider.getErrorInfo(error)});
      });
    });
  };

  static callByView = (microServiceId, viewId, request) => {
    return new Promise((resolve, reject) => {
      API_CallMicroService(microServiceId, viewId, request).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve(response.data);
        } else {
          reject({...MicroServiceDataProvider.getErrorInfo(response)});
        }
      }).catch(error => {
        reject({...MicroServiceDataProvider.getErrorInfo(error)});
      });
    });
  };

  static getRelationNodeList = (viewId, idList, start=0, limit=100) => {
    return new Promise((resolve, reject) => {
      API_GetRelationNodeList(viewId, {userConfirmed: 1,ispresentation: 1,idList:idList.join(',')}, start, limit, 'sequence_node_ex', 'DESC').then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve(response.data);
        } else {
          reject({...MicroServiceDataProvider.getErrorInfo(response)});
        }
      }).catch(error => {
        reject({...MicroServiceDataProvider.getErrorInfo(error)});
      });
    });
  };

  static getRelationNodeListByIds = (viewId, idList) => {
    return new Promise((resolve, reject) => {
      API_GetRelationNodeListByIds(viewId, idList.join(','), {ispresentation: 1}).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve(response.data);
        } else {
          reject({...MicroServiceDataProvider.getErrorInfo(response)});
        }
      }).catch(error => {
        reject({...MicroServiceDataProvider.getErrorInfo(error)});
      });
    });
  };

  static getEsMicroServiceAndPresentationList = (keyword, type=undefined, limit) => {
    return new Promise((resolve, reject) => {
      API_esMicroServicePresentationQuery(keyword, type, limit).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve(response.data.data);
        } else {
          reject({...MicroServiceDataProvider.getErrorInfo(response)});
        }
      }).catch(error => {
        reject({...MicroServiceDataProvider.getErrorInfo(error)});
      });
    });
  };

  static getMicroServiceById = (id) => {
    return new Promise((resolve, reject) => {
      API_LoadMicroServiceConfigList({id}).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve(response.data);
        } else {
          reject({...MicroServiceDataProvider.getErrorInfo(response)});
        }
      }).catch(error => {
        reject({...MicroServiceDataProvider.getErrorInfo(error)});
      });
    });
  };

  static getMicroServiceListByIds = (ids) => {
    return new Promise((resolve, reject) => {
      API_GetMicroServiceListByIds(ids).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve(response.data.data);
        } else {
          reject({...MicroServiceDataProvider.getErrorInfo(response)});
        }
      }).catch(error => {
        reject({...MicroServiceDataProvider.getErrorInfo(error)});
      });
    });
  };
  /**
   * @private
   * 通過Axios请求返回的错误信息获取错误详情
   *
   * @param {*} error
   *
   * @return {{msg: string, code: number}}
   */
  static getErrorInfo = commonGetErrorInfo;
}

const overrideMessages = {};

/**
 * @type {typeof MicroServiceDataProvider}
 */
const MicroServiceDataProviderWrapper = withPromiseMessageDecorator(
  messageProxy(microServiceOperationMessages, overrideMessages)
)(MicroServiceDataProvider);

// noinspection JSUnusedGlobalSymbols
export const overrideNextMessage = (method, msg) => {
  overrideMessages[method] = msg;
};

export default MicroServiceDataProviderWrapper;