import React from 'react';
import PropTypes from 'prop-types';
import {Form, Input, Modal} from 'antd';
import {getNodeDisplayTitle} from "@/constants/vis.defaultDefine.1";

class NodeInfoTextGeneralEditModal extends React.Component {
  state = {
    name: '',
    description: '',
    url: '',
    tags: [],
    currentTag: '',
  };

  shouldComponentUpdate(nextProps) {
    if (this.props.visible === false && nextProps.visible === true) {
      const me = this;
      this.setState((state, props) => {
        requestAnimationFrame(() => {
          const {form} = me.props;
          form.resetFields();
        });
        return {
          name: props.text,
          url: props.url,
          description: props.description,
          tags: props.tags,
        };
      });
    }
    return true;
  }

  /*onTagRemove = (i) => {
    const {form} = this.props;
    // can use data-binding to get
    let tags = Array.from(form.getFieldValue('tags'));
    tags.splice(i, 1);

    // can use data-binding to set
    form.setFieldsValue({
      tags: tags,
    });
    this.setState({tags: tags});
  };

  onTagAdd = (tag) => {
    const {form} = this.props;
    let tags = Array.from(form.getFieldValue('tags'));
    if (tag.includes(' ')) {
      message.error('标签内容不可含有空格');
    } else if (tags.includes(tag)) {
      message.error('该标签已存在');
      this.setState({currentTag: ''});
    } else {
      tags = tags.concat(tag);
      // can use data-binding to set
      form.setFieldsValue({
        tags: tags,
      });
      this.setState({tags: tags, currentTag: ''});
    }
  };*/

  render() {
    const {visible, onCancel, onSave, form} = this.props;
    const {getFieldDecorator} = form;
    const formItemLayout = {
      labelCol: {
        xs: {span: 8},
        sm: {span: 4},
      },
      wrapperCol: {
        xs: {span: 36},
        sm: {span: 18},
      },
    };
    /*getFieldDecorator('tags', {initialValue: this.props.tags});*/

    // noinspection RequiredAttributes
    return (
      <Modal
        closable={!this.props.processing}
        visible={visible}
        title={`编辑节点详情（${getNodeDisplayTitle({fname: this.props.text}, 18)}）`}
        okText={'确定'}
        cancelText={'取消'}
        onCancel={onCancel}
        onOk={onSave}
        okButtonProps={{loading: this.props.processing}}
        cancelButtonProps={{disabled: this.props.processing}}
      >
        <Form>
          <Form.Item
            {...formItemLayout}
            label="关注点"
          >
            {getFieldDecorator('name', {
              initialValue: this.props.text,
              rules: [{
                required: true, message: '请输入关注点，不能为空',
              }],
            })(
              <Input/>
            )}
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            label="外链"
          >
            {getFieldDecorator('url', {
              initialValue: this.props.url,
              rules: [{
                pattern: /^(https?:)?\/\/.+/,
                message: '请输入有效的链接地址，以http或https开头',
              }],
            })(
              <Input
                  placeholder="请输入以 http:// 开头的网络链接"
              />
            )}
          </Form.Item>
          <Form.Item
              {...formItemLayout}
              label="描述"
          >
            {getFieldDecorator('description', {
              initialValue: this.props.description,
              rules: [{
                message: '请输入描述信息',
              }],
            })(
                <Input.TextArea
                    placeholder="输入与当前关注点有关的任何描述信息"
                    autoSize={{ minRows: 2, maxRows: 6 }}
                />
            )}
          </Form.Item>
          {/*<Form.Item
            {...formItemLayout}
            label="标签"
          >
            {
              this.state.tags.map(
                (tag, i) => (
                  <Tag key={`tag-${i}`}>
                    <Icon type='tag'/>
                    &nbsp;{tag}&nbsp;
                    <a className={style['tag-remove']}
                       onClick={(e) => {
                         e.stopPropagation();
                         e.preventDefault();
                         this.onTagRemove(i);
                       }}>
                      <Icon type={'delete'}/>
                    </a>
                  </Tag>
                )
              )
            }
            <Tooltip
              title={
                <Input.Search placeholder="请输入标签"
                              enterButton="确定"
                              value={this.state.currentTag}
                              onChange={e => this.setState({currentTag: e.target.value})}
                              onSearch={this.onTagAdd} />
              }
              placement="bottom">

              <Button shape="circle" icon="plus" size={'small'} />
            </Tooltip>
          </Form.Item>*/}
        </Form>
      </Modal>
    );
  }
}

const WrappedNodeInfoTextGeneralEditModal = Form.create()(NodeInfoTextGeneralEditModal);

WrappedNodeInfoTextGeneralEditModal.defaultProps = {
  text: '',
  description: '',
  url: '',
  // tags: [],
  visible: false,
  processing: false,
};

WrappedNodeInfoTextGeneralEditModal.propTypes = {
  text: PropTypes.string,
  description: PropTypes.string,
  url: PropTypes.string,
  // tags: PropTypes.array,
  visible: PropTypes.bool,
  processing: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default WrappedNodeInfoTextGeneralEditModal;
