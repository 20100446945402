/*
 * @Author: Carr.s
 * @Date: 2022-04-29 11:41:35
 * @LastEditTime: 2022-04-29 12:24:26
 * @LastEditors: Carr.s
 * @Description: 预料分析洞察(领导讲话)
 * @Copyright by joinmap
 */
import React from "react";
import { Tabs } from "antd";

import { Copyright } from "@/components/common/common.copyright";

import layoutStyles from "@/style/default/defaultViewLayout.less";
import styles from "@/style/components/insightView/insight.less";

import Icon from "@/components/common/common.icon";
import { IconTypes } from "@/constants/common";
// 组件
import ViewCorpusAnalysisAInsight from "@/components/insightView/insight.corpus.analysis.a";
import ViewCorpusAnalysisBInsight from "@/components/insightView/insight.corpus.analysis.b";

const { TabPane } = Tabs;

class ViewCorpusAnalysisInsight extends React.Component {
  componentDidMount() {
    document.title = "语料风格洞察 - 炬图";
  }

  render() {
    const { query, userInfo, history } = this.props;
    return (
      <div
        style={{
          paddingTop: 60,
          height: "100%",
        }}
      >
        <Tabs
          centered={true}
          tabBarStyle={{
            textAlign: "center",
          }}
          style={{
            height: "100%",
          }}
        >
          <TabPane
            tab={
              <span style={{ fontSize: 16 }}>
                <Icon name="pie-chart" /> 语料词性分析图
              </span>
            }
            key="1"
          >
            <ViewCorpusAnalysisAInsight
              history={history}
              userInfo={userInfo}
              query={query}
            />
          </TabPane>
          <TabPane
            tab={
              <span style={{ fontSize: 16 }}>
                <Icon name="bar-chart" />
                语料句长分析图
              </span>
            }
            key="2"
          >
            <ViewCorpusAnalysisBInsight
              history={history}
              userInfo={userInfo}
              query={query}
            />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

export default ViewCorpusAnalysisInsight;
