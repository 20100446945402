import React from 'react';
import PropTypes from 'prop-types';
import {Form, Input, Modal} from 'antd';
import {getNodeDisplayTitle} from "@/constants/vis.defaultDefine.1";

class NodeInfoNodeGeneralEditModal extends React.Component {
  state = {
    name: '',
  };

  shouldComponentUpdate(nextProps) {
    if (this.props.visible === false && nextProps.visible === true) {
      const me = this;
      this.setState((state, props) => {
        requestAnimationFrame(() => {
          const {form} = me.props;
          form.resetFields();
        });
        return {name: props.nodeName};
      });
    }
    return true;
  }

  render() {
    const {visible, onCancel, onSave, form} = this.props;
    const {getFieldDecorator} = form;
    const formItemLayout = {
      labelCol: {
        xs: {span: 8},
        sm: {span: 4},
      },
      wrapperCol: {
        xs: {span: 36},
        sm: {span: 18},
      },
    };

    // noinspection RequiredAttributes
    return (
      <Modal
        closable={!this.props.processing}
        visible={visible}
        title={`编辑节点详情（${getNodeDisplayTitle({fname: this.props.nodeName}, 18)}）`}
        okText={'确定'}
        cancelText={'取消'}
        onCancel={onCancel}
        onOk={onSave}
        okButtonProps={{loading: this.props.processing}}
        cancelButtonProps={{disabled: this.props.processing}}
      >
        <Form>
          <Form.Item
            {...formItemLayout}
            label="名称"
          >
            {getFieldDecorator('name', {
              initialValue: this.props.nodeName,
              rules: [{
                required: true, message: '请输入名称',
              }],
            })(
              <Input/>
            )}
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

const WrappedNodeInfoNodeGeneralEditModal = Form.create()(NodeInfoNodeGeneralEditModal);

WrappedNodeInfoNodeGeneralEditModal.defaultProps = {
  nodeName: '',
  visible: false,
  processing: false,
};

WrappedNodeInfoNodeGeneralEditModal.propTypes = {
  nodeName: PropTypes.string,
  visible: PropTypes.bool,
  processing: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default WrappedNodeInfoNodeGeneralEditModal;
