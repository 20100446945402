import React from 'react';
import {Layout} from "antd";
import {Route, Redirect, Switch} from "react-router-dom";

import {Copyright} from '@/components/common/common.copyright';
import Loading from "@/components/common/common.loading";

import DetailRelationMainView from '@/components/detailRelationView/detailRelation.main';

import style from '@/style/containers/detailRelationView.less';

class DetailRelationView extends React.Component {
  render() {
    return (
      <Layout.Content className={`${style['container']} dark-theme`}>
        <Loading />
        <Switch>
          <Route exact path={'/detail_relation/:type/:id'} component={({match, history}) => (
            <DetailRelationMainView
              nodeId={match.params.id}
              nodeType={parseInt(match.params.type)}
            />
          )}/>
        </Switch>
        <Copyright className={style['version']}/>
      </Layout.Content>
    );
  }
}

export default DetailRelationView;