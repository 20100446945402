import React from 'react';
import PropTypes from 'prop-types';
import {Card, Tooltip} from "antd";

import Node from '@/libs/view/Node'

import style from '@/style/containers/explorationView.less';
import Icon from "@/components/common/common.icon";
import {getExternalLinkUrl, getNodeIcon} from "@/constants/vis.defaultDefine.1";
import _ from "lodash";
import {IconTypes} from "@/constants/common";

const getSummaryContent = (node) => {
  let contentList = [];
  if (node.htmlTags && node.htmlTags.length > 0) {
    // this.props.companyTags.map((e, i) => <Tag key={`tag-${i}`}><Icon type='tag' />&nbsp;{e}</Tag>)
    contentList.push(`[ ${node.htmlTags.join(' ')} ]`);
  }
  if (node['keyStcHtml']) {
    contentList.push(node['keyStcHtml']);
  }
  return (
    <div className={'ant-list-item-meta-description'}>
      <span dangerouslySetInnerHTML={{__html: contentList[0]}} />
      {contentList.length > 1 ? <br /> : null}
      {contentList.length > 1 ? <span dangerouslySetInnerHTML={{__html: contentList[1]}} /> : null}
    </div>
  )
};

export default class NodeInfoCard extends React.Component {
  onTitleClicked = (e) => {
    e.preventDefault();

    const {node, onTitleClicked} = this.props;

    if (_.isFunction(onTitleClicked)) {
      onTitleClicked(node);
    } else {
      window.open(getExternalLinkUrl(node), '_blank');
    }
  };

  onVoteClicked = (vote) => {
    let me = this;

    if (vote === 1 && me.props.node.meta.userVote === 1) {
      // 取消赞
      vote = 0;
    } else if (vote === -1 && me.props.node.meta.userVote === -1) {
      // 取消踩
      vote = 0;
    }
    me.props.onVote(me.props.node.id, vote).then(() => {
      if (vote === 0 && me.props.node.meta.userVote === 1) {
        // 取消赞
        me.props.node.meta.userVote = 0;
        me.props.node.meta.vote--;
      } else if (vote === 0 && me.props.node.meta.userVote === -1) {
        // 取消踩
        me.props.node.meta.userVote = 0;
        me.props.node.meta.vote++;
      } else if (vote === 1 && me.props.node.meta.userVote === -1) {
        // 取消踩且赞
        me.props.node.meta.userVote = 1;
        me.props.node.meta.vote += 2;
      } else if (vote === -1 && me.props.node.meta.userVote === 1) {
        // 取消赞且踩
        me.props.node.meta.userVote = -1;
        me.props.node.meta.vote -= 2;
      } else {
        me.props.node.meta.userVote = vote;
        me.props.node.meta.vote += vote;
      }
      me.forceUpdate();
    });
  };

  componentDidMount() {
    const {node} = this.props;
    if (node) {
      node.$getInfoCardRef = () => this;
    }
  }

  render() {
    const {node} = this.props;
    const {name, type, color} = getNodeIcon(node);

    let cls = `${style['result-item']}`, me = this;
    if (this.props.className) {
      cls = `${this.props.className} ${cls}`;
    }
    return (
      <div className={cls} style={this.props.style || {}}>
        <Card
          title={
            <a onClick={this.onTitleClicked} title={{/*node.fname*/}}>{/*清明节样式*/}
              <Icon name={name} type={type} color={color} style={{marginRight: '0.5em'}} />
              <span dangerouslySetInnerHTML={{__html: node.fnameHtml}} />
            </a>
          }
          actions={[
            node.status === 1 ? (
              <span key={'action-remove'}
                    className={`${style['info-card-action']} ${style['info-card-action-remove']} ${this.props.readonly ? 'disabled' : ''}`}>
                <span className={style['info-card-action-remove-added']}>
                  <Icon name={'star'} theme={'filled'} style={{marginRight: '0.5em'}}/>
                  已添加
                </span>
                {/*<span className={style['info-card-action-remove-remove']}>
                  <Icon name={'delete'} style={{marginRight: '0.5em'}} />
                  从子图移除
                </span>*/}
              </span>
            ) : (
              <span
                key={'action-save'}
                className={`${style['info-card-action']} ${style['info-card-action-save']} ${this.props.readonly ? 'disabled' : ''}`}
                onClick={this.props.readonly ? () => {} : () => this.props.onAddToGraph(node)}
              >
                <Icon name={'plus-circle'} style={{marginRight: '0.5em'}} />
                加入图谱
              </span>
            ),
            <span key={'action-explore'} className={style['info-card-action']} onClick={() => this.props.onExplore(node)}>
              <Icon name={'icon-similar'} type={IconTypes.ICON_FONT} style={{marginRight: '0.5em'}} />
              查询相似
            </span>,
            <span key={'action-explore'} className={style['info-card-action']}
                  onClick={() => window.open(getExternalLinkUrl(node), '_blank')}>
              <Icon name={'search'} style={{marginRight: '0.5em'}} />
              更多信息
            </span>,
          ]}
        >
          <div className={'ant-list-item-meta'}>
            <div className={style['info-card-vote-frame']}>
              <Tooltip title={'这资源有用'} placement={'left'}>
                <a
                  onClick={() => {me.onVoteClicked(1)}}
                  className={node.meta.userVote === 1 ? 'checked' : ''}
                >
                  <Icon name={'icon-good'} type={IconTypes.ICON_FONT} />
                </a>
              </Tooltip>
              <span>{node.meta ? (node.meta.vote > 99 ? '99+' : (node.meta.vote < -99 ? -99 : node.meta.vote)) || 0 : 0}</span>
              <Tooltip title={'这资源没用'} placement={'left'}>
                <a
                  onClick={() => {me.onVoteClicked(-1)}}
                  className={node.meta.userVote === -1 ? 'checked' : ''}
                >
                  <Icon name={'icon-bad'} type={IconTypes.ICON_FONT} />
                </a>
              </Tooltip>
            </div>
            <div className="ant-list-item-meta-content">
              {getSummaryContent(node)}
            </div>
          </div>
        </Card>
      </div>
    );
  }
}

NodeInfoCard.defaultProps = {
  readonly: true,
};

NodeInfoCard.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  readonly: PropTypes.bool,
  node: PropTypes.instanceOf(Node).isRequired,
  onTitleClicked: PropTypes.func,
  onAddToGraph: PropTypes.func.isRequired,
  onRemoveFromGraph: PropTypes.func.isRequired,
  onExplore: PropTypes.func.isRequired,
  onVote: PropTypes.func.isRequired,
};