import React from 'react';
import {List, Card} from 'antd';
import PropTypes from 'prop-types';

import Icon from '@/components/common/common.icon';
import NodeInfoNodeGeneralEditModal from "@/components/mainView/nodeInfo/main.nodeInfo.common.general.edit";
import {NodeInfoUpdatingStatus} from "@/libs/view/network/status";
import ViewDataProvider from '@/components/common/dataProvider/common.dataProvider.view';
import NodeInfoCommonDetailPanel from "@/components/mainView/nodeInfo/main.nodeInfo.common.detail";
import {
  getNodeDisplayTitle,
  getNodeIcon,
} from "@/constants/vis.defaultDefine.1";
import PB, {SimplePB} from "@/libs/simplePB";

class NodeInfoNodeGeneralPanel extends React.Component {
  state = {
    editModalVisible: false,
  };

  formRef = undefined;

  onEdit = () => {
    this.setState({editModalVisible: true});
  };

  onSave = () => {
    let me = this;
    const form = me.formRef.props.form;
    form.validateFields((err, values) => {
      if (!err && me.props.userInfo) {
        console.log('Received values of form: ', values);
        // 节点信息中的名称可编辑
        const {name} = values;
        const nodeInfo = {
          ...me.props.node,
          fname: name,
          label: name,
          userId: me.props.userInfo.userId,
        };
        // noinspection JSIgnoredPromiseFromCall, JSCheckFunctionSignatures
        me.props.networkRef && me.props.networkRef.updateNodeInfo(nodeInfo);
      }
    });
  };

  render() {
    const {node, /*permanent, */showIcon, showAssistant, editable, userInfo, updatingStatus, networkRef} = this.props;
    const records = (node ? [node] : []);
    let me = this;
    if (updatingStatus === NodeInfoUpdatingStatus.SUCCESS && me.state.editModalVisible) {
      requestAnimationFrame(() => {
        me.setState({editModalVisible: false});
      });
    }
    const {name, type, color} = getNodeIcon(node);

    return (
      <Card
        bordered={false}
        title={
          <span>
            <a
              style={{
                '--extra-action-width': node._$extraTitleActions ? node._$extraTitleActions._$width : '0em',
              }}
            >
              <Icon name={name} type={type} color={color} style={{marginRight: '0.5em'}} />
              <span><span>{getNodeDisplayTitle(node)}</span></span>
            </a>
            {
              node._$extraTitleActions ? node._$extraTitleActions() : null
            }
          </span>
        }
      >
        <List itemLayout="horizontal"
              split={false}
              size="small"
              locale={{emptyText: this.props.emptyText}}
              dataSource={records}
              renderItem={
                (node) => (
                  <List.Item>
                    <NodeInfoCommonDetailPanel
                      bus={me.props.PB}
                      node={node} 
                      showIcon={showIcon} 
                      showAssistant={showAssistant}
                      networkRef={networkRef}
                      originalViewId={me.props.networkRef ? me.props.networkRef.getData().viewId : me.props.originalViewId}
                    />
                  </List.Item>
                )
              }/>
        {
          (node.status === 1 && editable && userInfo) ? (
            <NodeInfoNodeGeneralEditModal
              wrappedComponentRef={(formRef) => {
                me.formRef = formRef;
              }}
              nodeName={node.fname}
              visible={this.state.editModalVisible}
              processing={updatingStatus === NodeInfoUpdatingStatus.PROCESSING}
              onSave={this.onSave}
              onCancel={() => {
                this.setState({editModalVisible: false});
              }}
            />
          ) : null
        }
      </Card>
    );
  }
}

NodeInfoNodeGeneralPanel.defaultProps = {
  bus: PB,
  permanent: true,
  showIcon: true,
  showAssistant: false,
  emptyText: '没有选中产业对象',
  editable: false,
  updatingStatus: NodeInfoUpdatingStatus.IDLE,
};

NodeInfoNodeGeneralPanel.propTypes = {
  bus: PropTypes.instanceOf(SimplePB),
  node: PropTypes.object.isRequired,
  userInfo: PropTypes.object,
  permanent: PropTypes.bool,
  showIcon: PropTypes.bool,
  showAssistant: PropTypes.bool,
  emptyText: PropTypes.string,
  editable: PropTypes.bool,
  updatingStatus: PropTypes.string,
  networkRef: PropTypes.instanceOf(ViewDataProvider),
  originalViewId: PropTypes.string,
};

export default NodeInfoNodeGeneralPanel;
