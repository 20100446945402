import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {Drawer, Input, Tabs, Tooltip} from 'antd';

import PB, {SimplePB} from '@/libs/simplePB';

import {IconTypes} from '@/constants/common';

import Icon from '@/components/common/common.icon';

import style from '@/style/common/microService/common.microService.less';
import {REQUEST_BASE} from "@/utils/HttpUtil";
import PresentationDataProvider from '@/components/common/dataProvider/common.dataProvider.presentation';
import MicroServiceDataProvider from '@/components/common/dataProvider/common.dataProvider.microService';
import {uniqueArrayObj} from "@/utils/Common";

const pageNum = 30;

class MicroServiceModalMarketDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeKey: 'recommend',
      status: 'idle',
      infoList: [],
      searchText: '',
      query: '',
      queryInfoList: [],
      queryStatus: 'idle',
      start: 0,
      firstLoad: true,
      total: 0,
      dataType: 0
    };
  }

  bottomRef = undefined;

  initObserver = () => {
    let me = this;
    const Observer = new IntersectionObserver(entries => {
      entries.forEach(elem => {
        if (elem.isIntersecting) {
          // 在创建元素的时候，定位了元素的第一个和最后一个子元素的位置
          // 通过id来实现对于第一和最后一个子元素的监听过程
          if (elem.target.id === "bottom") {
            let start = me.state.start;
            start = start + pageNum;
            me.setState({start}, () => {
              me.onLoadMicroService();
              //me.Observer.unobserve(item.target);
            })
          }
        }
      });
    });
    Observer.observe(me.bottomRef);
  };
/*
  useEffect(() => {
    let me = this;
    me.initObserver();
    return () => {
      me.resetObserver();
    };
  }, [start]);
*/

  doMicroServiceSearch = info => {
    let me = this;
    if (!me.props.viewId) return;
    if(info.type===1){
      PresentationDataProvider.getPresentations(info.id).then(data => {
        if(data.length>0){
          me.callMicroServiceInfo({dataType:1,...info,...data[0]});
        }
      }).catch(error => {
        console.warn(`获取信息失败: `,error);
      })
    }else{
      me.props.bus.emit('view', 'micro_service.config.on_load', {
        type: 'id',
        id: info.id,
        onSuccess: (infoList) => {
          me.doMicroService(infoList[0]);
        },
        onError: () => {
          
        }
      });
    }
  }

  doMicroService = info => {
    let me = this;
    if (!me.props.viewId) return;
    if(info.dataType===1){
      me.callMicroServiceInfo(info);
    }else{
      switch (info.version) {
        case 'v0.6.1':
          me.props.bus.emit(info.calcServerId, info.calcServerPathId,{viewId: me.props.viewId, serviceId: info.id});
          break;
        case 'v0.6.2':
          me.callFunctionMicroService(info);
          break;
        case 'v0.6.3':
          me.callDefaultMicroService(info.id);
          break;
        default:
          me.callMicroService(info);
          break;
      }
    }
  };

  callFunctionMicroService = info => {
    let me = this;
    if (!me.props.viewId) return;

  };

  callMicroService = info => {
    let me = this;
    if (!me.props.viewId) return;

    me.props.bus.emit('view', 'micro_service.service.show_modal',
      {viewId: me.props.viewId, serviceId: info.id});
  };

  callMicroServiceInfo = info => {
    let me = this;
    if (!me.props.viewId) return;
    me.props.bus.emit('view', 'micro_service.service.show_modal',
      {viewId: me.props.viewId, serviceId: info.id, dataType: info.dataType, serviceInfo: info});
  };

  callDefaultMicroService = id => {
    let me = this;
    if (!me.props.viewId) return;

    switch (id) {
      case 'ys-1':
        me.props.bus.emit('view', 'view.info.setViewInfo');
        break;
      case 'ys-3':
        me.props.bus.emit('view', 'desktop_background.show_config_modal',{viewId: me.props.viewId});
        break;
      case 'ys-4':
          me.props.bus.emit('view', 'banner.show_upload_modal.show',{viewId: me.props.viewId});
          break;
      case 'fx-1':
        me.props.bus.emit('view', 'view.share.exportPicture');
        break;
      case 'fx-2':
        me.props.bus.emit('relation', 'graph.export',{viewId: me.props.viewId});
          break;
      case 'fx-3':
        me.props.bus.emit('view', 'view.share.exportLink');
        break;
      case 'fx-4':
        me.props.bus.emit('printing', 'new');
          break;
      default:
        me.props.bus.emit('view', 'micro_service.service.show_modal',{viewId: me.props.viewId, serviceId: id});
        break;
    }
  };

  onLoadPrepageData =() =>{
    let me = this,start = me.state.start - pageNum;
    
    me.setState({start:start<0?0:start}, () => {
      me.onLoadMicroService();
    })
  }

  onLoadNextpageData =() =>{
    let me = this,start = me.state.start + pageNum;
    me.setState({start}, () => {
      me.onLoadMicroService();
    })
  }

  onLoadMicroService = (projectId) => {
    let me = this, activeKey = me.state.activeKey;
    if (me.props.activeTag === '专题报告') {
      requestAnimationFrame(() => {
        me.setState({dataType: 1}, () => {
          me.onLoadPublicPresentation();
          return;
        });
      });
    }else{
      me.setState({status: 'loading', infoList: []}, () => {
        me.props.bus.emit('view', 'micro_service.config.on_load', {
          projectId,
          tag: me.props.activeTag,
          start: me.state.start,
          limit: pageNum,
          onSuccess: (infoList, configList, total) => {
            if (me.state.activeKey === activeKey) {
              me.setState({status: 'success', infoList, total},()=>{
                //setTimeout(() => {
                //  me.initObserver();
                //}, 100);
              });
            }
            
          },
          onError: () => {
            if (me.state.activeKey === activeKey) {
              me.setState({status: 'failed'});
            }
          }
        });
      });
    }
  };

  onMyMicroService = () => {
    let me = this;
    /*if (me.props.activeTag==='图谱样式' || me.props.activeTag==='图谱分享') {
      me.onLoadDefaultMicroService();
      return;
    }*/
    me.setState({status: 'loading', infoList: []}, () => {
      me.props.bus.emit('view', 'micro_service.config.on_load', {
        type: 'my',
        tag: me.props.activeTag,
        start: 0,
        limit: pageNum,
        onSuccess: (infoList) => {
          if (me.state.activeKey === 'used') {
            me.setState({status: 'success', infoList});
          }
        },
        onError: () => {
          if (me.state.activeKey === 'used') {
            me.setState({status: 'failed'});
          }
        }
      });
    });
  }

  onRecommendMicroService = () => {
    let me = this;
    /* if (me.props.activeTag==='图谱样式' || me.props.activeTag==='图谱分享') {
      me.onLoadDefaultMicroService();
      return;
    } */
    me.setState({status: 'loading',dataType:0, infoList: []}, () => {
      me.props.bus.emit('view', 'micro_service.config.on_load', {
        type: 'recommend',
        tag: me.props.activeTag,
        start: 0,
        limit: pageNum,
        onSuccess: (infoList) => {
          if (me.state.activeKey === 'recommend') {
            me.setState({status: 'success', infoList});
          }
        },
        onError: () => {
          if (me.state.activeKey === 'recommend') {
            me.setState({status: 'failed'});
          }
        }
      });
    });
  }

  onLoadMicroServiceByTag = (tag) => {
    let me = this, activeKey = me.state.activeKey;
    me.setState({status: 'loading', infoList: []}, () => {
      me.props.bus.emit('view', 'micro_service.config.on_load', {
        tag,
        start: 0,
        limit: pageNum,
        onSuccess: (infoList) => {
          if (me.state.activeKey === activeKey) {
            me.setState({status: 'success', infoList});
          }
        },
        onError: () => {
          if (me.state.activeKey === activeKey) {
            me.setState({status: 'failed'});
          }
        }
      });
    });
  };

  onLoadDefaultMicroService = () => {
  /*   let me = this;
    let infoList = me.infoListDefault[me.props.activeTag=='图谱样式'?0:1];
    me.setState({status: 'success', infoList}); */
  };

  onLoadPublicPresentation = () => {
    let me = this, activeKey = me.state.activeKey;
    me.setState({status: 'loading', infoList: []}, () => {
      PresentationDataProvider.loadPresentationList({isPublic:1,start: me.state.start,limit: pageNum}).then(data => {
        if (me.state.activeKey === activeKey) {
          let infoList = [];
          data.forEach((item, idx) => {
            item.id = item.presentationId;
            item.version = "v1.0";
            item.dataType = 1;
            item.tag = "专题报告";
            infoList.push(item);
          });
          me.setState({status: 'success', infoList, total: (me.state.start+data.length+(data.length===pageNum?1:0))},()=>{
            //setTimeout(() => {
            //  me.initObserver();
            //}, 100);
          });
        }
      }).catch(error => {
        if (me.state.activeKey === activeKey) {
          me.setState({status: 'failed'});
        }
      })
    });
  };

  onSearchChange = e => {
    let me = this;

    me.setState({searchText: e.target.value});
  }
/*
  onSearchMicroService = () => {
    let me = this;
    me.setState({query: me.state.searchText, activeKey: 'search'}, () => {
      let query = me.state.query;
      if (query) {
        me.setState({queryStatus: 'loading', queryInfoList: []}, () => {
          MicroServiceDataProvider.getEsMicroServiceAndPresentationList(query,undefined,100).then(data => {
            if (me.state.activeKey === 'search') {
              let queryInfoList = [], msids = [], pids = [];
              uniqueArrayObj(data,'title').forEach((item, idx) => {
                item.updateTimestamp = item.update_timestamp;
                item.version = "v1.0";
                item.dataType = item.type;
                item.tag = item.type === 1?"专题报告":item.tag;
                queryInfoList.push(item);
              });
              me.setState({queryStatus: 'success', queryInfoList});
            }
          }).catch(error => {
            if (me.state.activeKey === 'search') {
              me.setState({queryStatus: 'failed'});
            }
          })
        });
      } else if (me.state.activeKey === 'search') {
        me.setState({activeKey: 'recommend'});
      }
    })
  };
*/
  onSearchMicroService = () => {
    let me = this;
    me.setState({query: me.state.searchText, activeKey: 'search'}, () => {
      let query = me.state.query;
      if (query) {
        me.setState({queryStatus: 'loading', queryInfoList: []}, () => {
          me.props.bus.emit('view', 'micro_service.config.on_load', {
            query,
            start: 0,
            limit: 30,
            onSuccess: (queryInfoList) => {
              if (me.state.activeKey === 'search') {
                me.setState({queryStatus: 'success', queryInfoList});
              }
            },
            onError: () => {
              if (me.state.activeKey === 'search') {
                me.setState({queryStatus: 'failed'});
              }
            }
          });
        });
      } else if (me.state.activeKey === 'search') {
        me.setState({activeKey: 'recommend'});
      }
    })
  };

  onTabChange = activeKey => {
    let me = this;
    if (activeKey !== me.state.activeKey) {
      me.setState({activeKey,start:0}, () => {
        switch (activeKey) {
          case 'project':
            me.onLoadMicroService(me.props.projectId);
            break;
          case 'recommend':
            me.onRecommendMicroService();
            break;
          case 'used':
            me.onMyMicroService();
            break;
          case 'tag_data':
              me.onLoadMicroServiceByTag('图谱数据');
              break;
          case 'tag_event':
              me.onLoadMicroServiceByTag('图谱事件');
              break;
          case 'tag_task':
              me.onLoadMicroServiceByTag('图谱任务');
              break;
          case 'tag_insight':
              me.onLoadMicroServiceByTag('图谱洞察');
              break;
          case 'search':
            // Nothing
            break;
          default:
            me.onLoadMicroService();
            break;
        }
      });
    }
  };

  componentDidMount() {
    let me = this;
    /* if (me.props.activeTag==='图谱样式' || me.props.activeTag==='图谱分享') {
      requestAnimationFrame(() => me.onLoadDefaultMicroService());
    }else  */
    if (me.props.activeTag === '专题报告') {
      requestAnimationFrame(() => {
        me.setState({dataType: 1}, () => {
          me.onLoadPublicPresentation();
        });
      });
    } else if (me.props.projectId) {
      requestAnimationFrame(() => {
        me.setState({activeKey: 'project',dataType:0}, () => {
          me.onLoadMicroService(me.props.projectId);
        });
      });
    } else if (me.props.keyword) {
      requestAnimationFrame(() => {
        me.setState({searchText: me.props.keyword,dataType:0}, () => {
          me.onSearchMicroService();
        });
      });
    } else {
      requestAnimationFrame(() => me.onRecommendMicroService());
    }
  }

  render() {
    let me = this, tabContent, moreContent;
    if (me.state.activeKey === 'search') {
      if (me.state.queryStatus === 'success') {
        tabContent = me.state.queryInfoList.length > 0 ? me.state.queryInfoList.map((item, idx) => (
          <div
            key={`micro-service-${idx}`}
            className={style['market-drawer-body-tab-item']}
            onClick={() => me.doMicroServiceSearch(item)}
          >
            <div className={style['market-drawer-body-tab-item-cover']}>
              {
                item.coverId ? (
                  <div
                    className={style['market-drawer-body-tab-item-cover-img']}
                  >
                    <img
                      src={`${REQUEST_BASE}/view/public_files/${item.coverId}`}
                      alt={item.title}
                      onError={e => {e.target['parentNode'].style.display = "none";}}
                    />
                  </div>
                ) : null
              }
              <Icon name={item.iconId || 'icon-micro-service'} type={IconTypes.ICON_FONT} />
            </div>
            <div className={style['market-drawer-body-tab-item-title']}>{item.title}</div>
            <div className={style['market-drawer-body-tab-item-meta']}>
              <span>{item.version}</span>
              {item.updateTimestamp && <span style={{float: 'right'}}>{item.updateTimestamp.substr(0, 10)}</span>}
            </div>
          </div>
        )) : (
          <div className={style['market-drawer-body-tab-content-empty']}>
            没有找到相关微服务
          </div>
        );
      } else if (me.state.queryStatus === 'failed') {
        tabContent = (
          <div className={style['market-drawer-body-tab-content-loading']}>
            加载失败，点击<a>重试</a>
          </div>
        );
      } else {
        tabContent = (<div className={style['market-drawer-body-tab-content-loading']}>加载中...</div>)
      }
    } else {
      if (me.state.status === 'success') {
        tabContent = me.state.infoList.length > 0 ? me.state.infoList.map((item, idx) => (
          <div
            key={`micro-service-${idx}`}
            className={style['market-drawer-body-tab-item']}
            onClick={() => me.doMicroService(item)}
          >
            <div className={style['market-drawer-body-tab-item-cover']}>
              {
                item.coverId ? (
                  <div className={style['market-drawer-body-tab-item-cover-img']}>
                    <img
                      src={`${REQUEST_BASE}/view/public_files/${item.coverId}/thumb/400/0`}
                      alt={item.title}
                      onError={e => {e.target['parentNode'].style.display = "none";}}
                    />
                  </div>
                ) : (item.dataType === 1 && item.meta && item.meta.iconData ? (
                  <div className={style['market-drawer-body-tab-item-cover-img']}>
                    <img
                      src={item.meta.iconData}
                      alt={item.title}
                      onError={e => {e.target['parentNode'].style.display = "none";}}
                    />
                  </div>
                ) : null)
              }
              <Icon name={item.iconId || 'icon-micro-service'} type={IconTypes.ICON_FONT} />
            </div>
            <div className={style['market-drawer-body-tab-item-title']}>{item.title}</div>
            <div className={style['market-drawer-body-tab-item-meta']}>
              {/*<span style={{marginRight: '0.5em'}}>炬图系统</span>*/}
              <span>{item.version}</span>
              {item.updateTimestamp && <span style={{float: 'right'}}>{item.updateTimestamp.substr(0, 10)}</span>}
            </div>
          </div>
        )) : (
          <div className={style['market-drawer-body-tab-content-empty']}>
            没有找到相关微服务
          </div>
        );
        moreContent = me.state.total > pageNum ?(
          <div className={style['market-drawer-body-tab-content-page']} id='bottom' ref={bottomRef => {me.bottomRef = bottomRef;}} >
            {me.state.start >= pageNum && (<span><a onClick={()=>{me.onLoadPrepageData()}}>上一页</a></span>)}
            {me.state.total-me.state.start > pageNum && me.state.infoList.length == pageNum && (<span><a onClick={()=>{me.onLoadNextpageData()}}>下一页</a></span>)}
          </div>):null
      } else if (me.state.status === 'failed') {
        tabContent = (
          <div className={style['market-drawer-body-tab-content-loading']}>
            加载失败，点击<a>重试</a>
          </div>
        );
      } else {
        tabContent = (<div className={style['market-drawer-body-tab-content-loading']}>加载中...</div>)
      }
    }

    return (
      <Drawer
        //title={'微服务'}
        visible={me.props.visible}
        placement={'right'}
        destroyOnClose={true}
        mask={true}
        maskClosable={true}
        maskStyle={{backgroundColor: 'transparent'}}
        width={'46rem'}
        className={`${style['market-drawer-wrap']} dark-theme`}
        onClose={me.props.onClose}
      >
        <div style={{ height: '100%'}}>
          <div className={style['market-drawer-header']}>
            <div className={style['market-drawer-title']}>微服务</div>
            <div className={style['market-drawer-right']}>
              <Input.Search
                  size={'small'}
                  placeholder={'搜索微服务...'}
                  value={me.state.searchText}
                  onChange={me.onSearchChange}
                  onSearch={me.onSearchMicroService}
                />
            </div>
          </div>
          <div className={style['market-drawer-body']}>
            <Tabs
              size={'small'}
              activeKey={me.state.activeKey}
              animated={false}
              tabBarGutter={0}
              className={style['market-drawer-body-tabs']}
              onChange={me.onTabChange}
            >
              { me.props.activeTag === '专题报告' && <Tabs.TabPane
                tab={'最新'}
                key={'recommend'}
                className={`${style['market-drawer-body-tab-content']} scrollbar scrollbar-none`}
              >
                {me.state.activeKey === 'recommend' ? tabContent : null}
                {moreContent}
              </Tabs.TabPane>}
              {
                me.props.projectId ? (
                  <Tabs.TabPane
                    tab={'专属'}
                    key={'project'}
                    className={`${style['market-drawer-body-tab-content']} scrollbar scrollbar-none`}
                  >
                    {me.state.activeKey === 'project' ? tabContent : null}
                  </Tabs.TabPane>
                ) : null
              }
              { me.props.activeTag !== '专题报告' && <Tabs.TabPane
                tab={'推荐'}
                key={'recommend'}
                className={`${style['market-drawer-body-tab-content']} scrollbar scrollbar-none`}
              >
                {me.state.activeKey === 'recommend' ? tabContent : null}
              </Tabs.TabPane>}
              { me.props.activeTag !== '专题报告' && <Tabs.TabPane
                tab={'常用'}
                key={'used'}
                className={`${style['market-drawer-body-tab-content']} scrollbar scrollbar-none`}
              >
                {me.state.activeKey === 'used' ? tabContent : null}
              </Tabs.TabPane>}
              { me.props.activeTag !== '专题报告' && <Tabs.TabPane
                tab={'最新'}
                key={'latest'}
                className={`${style['market-drawer-body-tab-content']} scrollbar scrollbar-none`}
              >
                {me.state.activeKey === 'latest' ? tabContent : null}
                {moreContent}
              </Tabs.TabPane>}
              {
                me.state.query ? (
                  <Tabs.TabPane
                    tab={(
                      <Tooltip title={me.state.query}>
                        搜索：{(me.state.query.length > 3) ? `${me.state.query}`.substr(0, 3) + '...' : me.state.query}
                      </Tooltip>
                    )}
                    key={'search'}
                    className={`${style['market-drawer-body-tab-content']} scrollbar scrollbar-none`}
                  >
                    {me.state.activeKey === 'search' ? tabContent : null}
                  </Tabs.TabPane>
                ) : null
              }
            </Tabs>
          </div>
        </div>
      </Drawer>
    );
  }
}

MicroServiceModalMarketDrawer.defaultProps = {
  bus: PB,
};

MicroServiceModalMarketDrawer.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  viewId: PropTypes.string,
  projectId: PropTypes.string,
  activeTag: PropTypes.string,
  keyword: PropTypes.string,
  bus: PropTypes.instanceOf(SimplePB),
};

export default MicroServiceModalMarketDrawer;