import { all } from 'redux-saga/effects'

import {
  watchCheckUsername,
  watchLogin,
  watchMagicLogin,
  watchLogout,
  watchRegister,
  watchResetPwdViaEmail,
  watchResetPwdSendNewPwd,
  watchWeChatLogin,
  watchGetWeChatFwhTicket,
  watchCheckWeChatFwhTicket,
  watchChangePwd,
  watchChangeUserBasicInfo,
} from './account/saga'
import {
  watchSubmitFeedback,
  watchSubmitPrinting,
} from "./system/saga"
export default function* sagas() {
  yield all([
    watchCheckUsername(),
    watchLogin(),
    watchMagicLogin(),
    watchLogout(),
    watchRegister(),
    watchResetPwdViaEmail(),
    watchResetPwdSendNewPwd(),
    watchWeChatLogin(),
    watchGetWeChatFwhTicket(),
    watchCheckWeChatFwhTicket(),
    watchChangePwd(),
    watchChangeUserBasicInfo(),
    watchSubmitFeedback(),
    watchSubmitPrinting(),
  ]);
}