import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input, message, Modal, Radio, Tag } from 'antd';

import PB, { SimplePB } from '@/libs/simplePB';

import Icon from '@/components/common/common.icon';

import style from '@/style/common/view/common.view.member.setBadge.less';
import { IconTypes } from '@/constants/common';
import { showErrorMessage } from '@/components/common/common.message';
import ViewDataProvider from '@/components/common/dataProvider/common.dataProvider.view';
import { getViewBadgeList } from "@/components/common/common.functions";

class MemberSetBadge extends React.PureComponent {
	state = {
		viewBadgeTypeList: getViewBadgeList(),
		selectedValue: 0,
		processing: false,
	};

	// 评语选择列表
	commentList = [
		{ key: 0, value: 0, text: '超级厉害' },
		{ key: 1, value: 1, text: '脑洞大开' },
		{ key: 2, value: 2, text: '思路清晰' },
		{ key: 3, value: 3, text: '行动迅速' },
		{ key: 4, value: 4, text: '非常赞同' },
		{ key: 5, value: 5, text: '深受启发' },
		{ key: 6, value: 6, text: '创新精神' },
		{ key: 7, value: 7, text: '优秀案例' },
	];

	onSave = () => {
		let me = this;
		const {form} = me.props;
		form.validateFields((err, values) => {
			if (!err) {
				const { name, gift } = values;
				console.log('ViewMemberReward -> onSave -> values :', values);
				me.setState({processing: true}, () => {
					let params = {
						viewId: me.props.viewDataProvider.viewId,
						userId: me.props.node.userId,
						nodeId: me.props.node.id,
						nodeFname: me.props.node.fname,
						text: name,
						type: gift,
					};

					me.props.viewDataProvider.setViewBadge(params).then(res => {
						console.log('ViewMemberReward -> onSave -> values :', values);
						me.props.onClose();
						message.success('提交成功！');
						me.setState({
							processing: false,
						});
					}).catch(() => {
						me.setState({
							processing: false,
						});
						message.success('提交失败，请稍后再试！')
					});
				});
			}
		});
	};

	render() {
		const me = this, { form } = me.props;
		const {getFieldDecorator} = form;
		const formItemLayout = {
			labelCol: {span: 5},
			wrapperCol: {span: 18},
		};
		const formValues = form.getFieldsValue();

		return (
			<Modal
				wrapClassName={style['set-badge-modal']}
				width={530}
				title={'给TA点赞'}
				visible={me.props.visible}
				onOk={me.onSave}
				onCancel={() => {
					me.props.onClose();
				}}
				okText={'提交'}
				cancelText={'取消'}
				closable={!this.state.processing}
				okButtonProps={{loading: this.state.processing, disabled: this.state.processing}}
				cancelButtonProps={{disabled: this.state.processing}}
				centered={true}
				destroyOnClose={true}
			>
				<Form>
					<Form.Item
						{...formItemLayout}
						label="针对您的节点"
					>
						<div className={style['node-name']}>{me.props.node.fname || me.props.node.label}</div>
					</Form.Item>
					<Form.Item
						{...formItemLayout}
						label="表示隆重点赞"
						className={style['reward-name-item']}
					>
						{getFieldDecorator('name', {
							initialValue: '超级厉害',
							rules: [{
								required: true,
								message: '请输入点赞评语',
							}],
						})(
							<Input className={style['reward-name-input']} autoFocus={true}/>
						)}
					</Form.Item>
					<Form.Item
						wrapperCol={{span: 18, offset: 5}}
						className={style['reward-name-select']}
					>
						<div className={style['reward-name-tags']}>
							{me.commentList.map(item => (
								<Tag key={item.key}
								     color={formValues.name === item.text ? 'blue' : ''}
								     className={style['reward-name-tag']}
								     onClick={() => me.props.form.setFieldsValue({name: item.text})}
								>{item.text}</Tag>
							))}
						</div>
					</Form.Item>
					<Form.Item
						labelCol={{span: 0}}
						wrapperCol={{span: 24}}
					>
						{getFieldDecorator('gift', {
							initialValue: me.state.selectedValue,
						})(
							<Radio.Group
								className={style['radio-group']}
							>
								{
									me.state.viewBadgeTypeList.map((item, index) => (
										<Radio key={item.value} className={style['radio-item']} value={item.id}>
											<Icon name={`${item.icon}`} type={IconTypes.ICON_FONT} className={style['radio-icon']} style={{fontSize: 1.5 + 0.3 * index + 'rem'}}/>
											<div className={style['radio-text']}>{item.label}</div>
										</Radio>
									))
								}
							</Radio.Group>
						)}
					</Form.Item>
				</Form>
			</Modal>
		);
	}
}

const MemberSetBadgeModal = Form.create()(MemberSetBadge);

MemberSetBadgeModal.defaultProps = {
	node: {fname: '', description: '', label: ''},
	bus: PB,
};

MemberSetBadgeModal.propTypes = {
	node: PropTypes.object,
	bus: PropTypes.instanceOf(SimplePB),
	visible: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	viewDataProvider: PropTypes.instanceOf(ViewDataProvider).isRequired,
};

export default MemberSetBadgeModal;