import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import PB, {SimplePB} from '@/libs/simplePB';

import ViewManager from '@/components/common/common.viewManager';
import UserDataProvider from '@/components/common/dataProvider/common.dataProvider.user';
import NodeDataProvider, {overrideNextMessage} from "@/components/common/dataProvider/common.dataProvider.node";

class TeamworkLogic extends React.PureComponent {
  userList = undefined;
  settings = {};

  memberLoadingStatus = 'idle';
  memberSortingStatus = 'idle';
  settingsLoadingStatus = 'idle';
  ticketLoadingStatus = 'idle';
  ticketLoadingParameters = '';
  ticketList = [];
  ticketTotal = 0;

  componentDidMount() {
    let me = this;

    // 查询用户列表处理函数
    const queryUserFn = ({query: {nick, email, mobile, accessToken}, success, failed}) => {
      UserDataProvider.matchUser({nick, email, mobile, accessToken}).then(userList => {
        me.props.bus.emit('teamwork', 'member.find.success', {userList});
        success && success(userList);
      }).catch(error => {
        me.props.bus.emit('teamwork', 'member.find.failed', {error});
        failed && failed(error);
      })
    };

    // 获取协作成员列表
    me.props.bus.sub(me, 'teamwork', 'member.list.do', ({viewId, forceReload}) => {
      if (me.props.viewId !== viewId) return;
      if (!me.props.viewId) {
        me.memberLoadingStatus = 'idle';
        return;
      }
      if (forceReload === true) me.memberLoadingStatus = 'idle';
      if (me.memberLoadingStatus === 'success') {
        me.props.bus.emit('teamwork', 'member.list.success', {viewId, userList: me.userList});
      } else if (me.memberLoadingStatus === 'processing') {
        // Do nothing
      } else {
        me.memberLoadingStatus = 'processing';
        me.props.bus.emit('teamwork', 'member.list.processing', {viewId});
        ViewManager.loadViewTeamworkMembers(me.props.viewId).then(data => {
          me.userList = data;
          me.memberSortingStatus = 'idle';
          me.memberLoadingStatus = 'success';
          me.props.bus.emit('teamwork', 'member.list.success', {viewId, userList: me.userList, reloaded: true});
        }).catch(error => {
          me.memberLoadingStatus = 'failed';
          console.log('获取图谱协作列表失败', error);
          me.props.bus.emit('teamwork', 'member.list.failed', {viewId});
        });
      }
    });

    // 获取协作成员列表
    me.props.bus.sub(me, 'teamwork', 'member.list.sort', ({viewId, forceReload}) => {
      if (me.props.viewId !== viewId) return;
      if (me.memberLoadingStatus !== 'success') return;
      if (me.memberSortingStatus === 'success' && !forceReload) {
        me.props.bus.emit('teamwork', 'member.list.sorted',
          {viewId, userList: me.userList, sorted: true});
      } else if (me.memberSortingStatus === 'processing') {
        // Do nothing
      } else {
        me.memberSortingStatus = 'processing';
        me.loadUserNodeStatisticsInView(me.userList.map(userInfo => userInfo.userId)).then(resultMap => {
          me.userList.forEach(userInfo =>
            userInfo.nodeStatistics = {total: -1, current: -1, currentWeekday: -1, ...resultMap[`u_${userInfo.userId}`]});
          // 按照用户身份及当前积分排序
          me.userList.sort((userA, userB) => {
            return (userA.nodeStatistics.current !== userB.nodeStatistics.current) ? (
              userB.nodeStatistics.current - userA.nodeStatistics.current
            ) : (
              userA['createTime'] === userB['createTime'] ? 0 : (
                userA['createTime'] < userB['createTime'] ? 1 : -1
              )
            )
          });
          me.memberSortingStatus = 'success';
          me.props.bus.emit('teamwork', 'member.list.sorted',
            {viewId, userList: me.userList, sorted: true});
          me.loadUserNodeStatistics(me.userList.map(userInfo => userInfo.userId)).then(resultMap => {
            me.userList.forEach(userInfo =>
              userInfo.nodeStatistics = {...userInfo.nodeStatistics, ...resultMap[`u_${userInfo.userId}`]});
            me.props.bus.emit('teamwork', 'member.list.sorted',
              {viewId, userList: me.userList, sorted: true});
          });
        }).catch(error => {
          console.log('图谱协作列表排序失败', error);
          me.memberSortingStatus = 'failed';
          me.props.bus.emit('teamwork', 'member.list.sort_failed', {viewId});
        });
      }
    });

    // 显示添加协作成员对话框
    me.props.bus.sub(me, 'teamwork', 'member.add.on', () => {
      me.props.bus.emit('teamwork', 'member.add.show_modal', undefined);
    });

    // 显示移除协作成员对话框
    me.props.bus.sub(me, 'teamwork', 'member.remove.on', ({viewId, userInfo}) => {
      me.props.bus.emit('teamwork', 'member.remove.show_confirm', {viewId, userInfo})
    });

    // 发送请求添加协作成员
    me.props.bus.sub(me, 'teamwork', 'member.add.do', ({viewId, userInfo}) => {
      if (me.props.viewId !== viewId) return;
      ViewManager.addViewTeamworkMember(
        me.props.viewId, userInfo.userId, userInfo['accessToken'] || userInfo['email']
      ).then(() => {
        me.props.bus.emit('teamwork', 'member.add.success', {viewId, userInfo});
      }).catch(error => {
        console.log('添加协作成员失败', error);
        me.props.bus.emit('teamwork', 'member.add.failed', {viewId, userInfo});
      });
    });

    // 发送请求删除协作成员
    me.props.bus.sub(me, 'teamwork', 'member.remove.do', ({viewId, userInfo}) => {
      if (me.props.viewId !== viewId) return;
      ViewManager.removeViewTeamworkMember(me.props.viewId, userInfo ? userInfo.userId : 0).then(() => {
        me.props.bus.emit('teamwork', 'member.remove.success', {viewId, userInfo});
      }).catch(error => {
        console.log('移除协作成员失败', error);
        me.props.bus.emit('teamwork', 'member.remove.failed', {viewId, userInfo});
      });
    });

    // 协作成员添加成功后更新协作成员列表
    me.props.bus.sub(me, 'teamwork', ['member.add.success', 'member.join.success'], ({viewId}) => {
      me.props.bus.emit('teamwork', 'member.list.do', {viewId, forceReload: true});
    });

    // 协作成员移除成功后更新协作成员列表
    me.props.bus.sub(me, 'teamwork', 'member.remove.success', ({viewId, userInfo}) => {
      if (userInfo) {
        me.props.bus.emit('teamwork', 'member.list.do', {viewId, forceReload: true});
      }
    });

    // 加入协作
    me.props.bus.sub(me, 'teamwork', 'member.join.do', ({viewId}) => {
      if (me.props.viewId !== viewId) return;
      me.props.bus.emit('teamwork', 'member.join.processing', {viewId});
      ViewManager.addViewTeamworkMember(me.props.viewId, 0).then(() => {
        me.props.bus.emit('teamwork', 'member.join.success', {viewId});
      }).catch(error => {
        console.log('加入协作成员失败', error);
        me.props.bus.emit('teamwork', 'member.join.failed', {viewId});
      });
    });

    // 加载协作配置
    me.props.bus.sub(me, 'teamwork', 'settings.load.do', ({viewId, forceReload}) => {
      if (me.props.viewId !== viewId) return;
      if (!me.props.viewId) {
        me.settingsLoadingStatus = 'idle';
        return;
      }
      if (forceReload === true) me.settingsLoadingStatus = 'idle';
      if (me.settingsLoadingStatus === 'success') {
        me.props.bus.emit('teamwork', 'settings.load.success', {viewId, ...me.settings});
      } else if (me.settingsLoadingStatus === 'processing') {
        // Do nothing
      } else {
        me.settingsLoadingStatus = 'processing';
        me.props.bus.emit('teamwork', 'settings.load.processing', {viewId});
        ViewManager.loadViewTeamworkSettings(viewId).then(data => {
          me.settings = data;
          me.settingsLoadingStatus = 'success';
          me.props.bus.emit('teamwork', 'settings.load.success', {viewId, ...me.settings});
        }).catch(error => {
          me.settingsLoadingStatus = 'failed';
          console.log('获取图谱协作配置失败', error);
          me.props.bus.emit('teamwork', 'settings.load.failed', {viewId});
        });
      }
    });

    // 修改协作配置
    me.props.bus.sub(me, 'teamwork', 'settings.update.do', ({viewId, type}) => {
      if (me.props.viewId !== viewId) return;
      me.props.bus.emit('teamwork', 'settings.update.processing', {viewId});
      ViewManager.setViewTeamworkSettingsPartially(viewId, type).then(() => {
        me.settings = {...me.settings, type};
        me.props.bus.emit('teamwork', 'settings.update.success', {viewId});
      }).catch(error => {
        console.log('修改图谱协作配置失败', error);
        me.props.bus.emit('teamwork', 'settings.update.failed', {viewId});
      });
    });

    // 查询用户列表
    me.props.bus.sub(me, 'teamwork', 'member.find.do', queryUserFn);

    // 通过节点数据及成员数据生成统计结果
    me.props.bus.sub(me, 'teamwork', 'statistics.generate_result',
      ({nodes, members, maxRecentItemAmount}) => {
        if (!members || !nodes) return;

        let memberIdxMap = {};
        let memberStatisticsList = [];
        let viewStatistics = {};
        let viewNodeAmountRecentDays = 0;

        let startDay = moment().startOf('day').subtract(7, 'd');
        let startTimestamp = startDay.valueOf();
        let endTimestamp = startDay.add(8, 'd').valueOf();
        let step = 1000 * 60 * 60;
        let currentDay = moment().startOf('day');

        let overallStatistics = {startTimestamp, bar: {data: [], step}, points: {data: []}};
        for (let currentTimestamp = startTimestamp; currentTimestamp < endTimestamp; currentTimestamp += step) {
          overallStatistics.bar.data.push(0);
        }

        members.forEach(member => {
          let overallStatistics = {startTimestamp, bar: {data: [], step}, points: {data: []}};
          memberIdxMap[`user-${member.userId}`] = memberStatisticsList.length;
          for (let currentTimestamp = startTimestamp; currentTimestamp < endTimestamp; currentTimestamp += step) {
            overallStatistics.bar.data.push(0);
          }
          memberStatisticsList.push({
            id: member.userId,
            name: member.nick,
            picId: member.picId,
            amount: 0,
            percent: 0,
            lastAccessDateTime: [],
            lastInputNodes: [],
            joinDateTime: member['createTime'],
            overallStatistics,
            member,
            nodes: [],
          });
        });

        nodes.forEach(node => {
          let memberIdx = memberIdxMap[`user-${node.userId}`];
          let updateTimeObj = moment(node.updateTime, 'YYYY-MM-DD HH:mm:ss');
          if (updateTimeObj.isValid() && memberIdx !== undefined) {
            // points
            let realUpdateTimestamp = updateTimeObj.valueOf();
            let pointY = updateTimeObj.hour() * 60 + updateTimeObj.minute();
            let pointX = (updateTimeObj.second() * 1000 + updateTimeObj.millisecond()) * 24 * 60;
            let updateTimestamp = updateTimeObj.minute(0).second(0).millisecond(0).valueOf();
            pointX += updateTimeObj.hour(0).valueOf();
            if (updateTimestamp >= startTimestamp && updateTimestamp <= endTimestamp) {
              overallStatistics.bar.data[Math.ceil((updateTimestamp - startTimestamp) / step)]++;
              memberStatisticsList[memberIdx].overallStatistics.bar
                .data[Math.ceil((updateTimestamp - startTimestamp) / step)]++;
              overallStatistics.points.data.push([pointX, pointY, node, overallStatistics.points.data.length,
                realUpdateTimestamp]);
              memberStatisticsList[memberIdx].overallStatistics.points.data.push(
                [pointX, pointY, node, overallStatistics.points.data.length, realUpdateTimestamp]);
              viewNodeAmountRecentDays++;
            }
            memberStatisticsList[memberIdx].amount++;
            memberStatisticsList[memberIdx].nodes.push(node);
            let inserted = false;
            for (let i = 0; i < memberStatisticsList[memberIdx].lastAccessDateTime.length; i++) {
              if (memberStatisticsList[memberIdx].lastAccessDateTime[i] < node.updateTime) {
                memberStatisticsList[memberIdx].lastAccessDateTime.splice(i, 0, node.updateTime);
                memberStatisticsList[memberIdx].lastAccessDateTime.splice(maxRecentItemAmount);
                memberStatisticsList[memberIdx].lastInputNodes.splice(i, 0, node);
                memberStatisticsList[memberIdx].lastInputNodes.splice(maxRecentItemAmount);
                inserted = true;
                break;
              }
            }
            if (!inserted && memberStatisticsList[memberIdx].lastAccessDateTime.length < maxRecentItemAmount) {
              memberStatisticsList[memberIdx].lastAccessDateTime.push(node.updateTime);
              memberStatisticsList[memberIdx].lastInputNodes.push(node);
            }
          }
        });

        memberStatisticsList.forEach(data => {
          data.percent = (data.amount / nodes.length);
        });

        NodeDataProvider.batchLoadNodeAmountStatistics([
          {conditions: {viewId: me.props.viewId, userConfirmed: 1}},
          {
            conditions: {viewId: me.props.viewId, userConfirmed: 1},
            statisticsBy: ['vr_date_of_update_time'],
            pagination: {limit: 1},
          },
          {
            conditions: {
              viewId: me.props.viewId,
              userConfirmed: 1,
              // Note: Months are zero indexed, so January is month 0.
              vrYearMonthOfUpdateTime: currentDay.year() * 12 + currentDay.month(),
            },
          },
          {
            conditions: {
              viewId: me.props.viewId,
              userConfirmed: 1,
              // Note: Months are zero indexed, so January is month 0.
              vrYearQuarterOfUpdateTime: currentDay.year() * 4 + Math.floor(currentDay.month() / 3),
            },
          },
          {
            conditions: {
              viewId: me.props.viewId,
              userConfirmed: 1,
              vrYearOfUpdateTime: currentDay.year(),
            },
          },
        ]).then(statisticsViewResultList => {
          viewStatistics = {
            totalViewNodeAmount: statisticsViewResultList[0]['amount'] || 0,
            maxViewNodeAmountByDay: statisticsViewResultList[1]['amountList'].length > 0 ?
              statisticsViewResultList[1]['amountList'][0]['amount'] : 0,
            maxViewNodeAmountByDayDate: statisticsViewResultList[1]['amountList'].length > 0 ?
              statisticsViewResultList[1]['amountList'][0].group['vr_date_of_update_time'] : undefined,
            viewNodeAmountThisMonth: statisticsViewResultList[2]['amount'] || 0,
            viewNodeAmountThisQuarter: statisticsViewResultList[3]['amount'] || 0,
            viewNodeAmountThisYear: statisticsViewResultList[4]['amount'] || 0,
            viewNodeAmountRecentDays,
          };

          me.props.bus.emit('teamwork', 'statistics.result.ready',
            {memberStatisticsList, overallStatistics, memberIdxMap, viewStatistics});
        }).catch(error => {
          console.log('获取节点数量统计信息失败', error);
        });
      });

    me.props.bus.sub(me, 'teamwork', 'statistics.member_node_amount.load',
      ({memberId}) => {
        let currentDay = moment().startOf('day');
        overrideNextMessage('static::batchLoadNodeAmountStatistics', false);
        NodeDataProvider.batchLoadNodeAmountStatistics([
          {conditions: {userId: memberId, viewId: me.props.viewId, userConfirmed: 1}},
          {
            conditions: {userId: memberId, viewId: me.props.viewId, userConfirmed: 1},
            statisticsBy: ['vr_date_of_update_time'],
            pagination: {limit: 1},
          },
          {
            conditions: {
              userId: memberId,
              viewId: me.props.viewId,
              userConfirmed: 1,
              // Note: Months are zero indexed, so January is month 0.
              vrYearMonthOfUpdateTime: currentDay.year() * 12 + currentDay.month(),
            },
          },
          {
            conditions: {
              userId: memberId,
              viewId: me.props.viewId,
              userConfirmed: 1,
              // Note: Months are zero indexed, so January is month 0.
              vrYearQuarterOfUpdateTime: currentDay.year() * 4 + Math.floor(currentDay.month() / 3),
            },
          },
          {
            conditions: {
              userId: memberId,
              viewId: me.props.viewId,
              userConfirmed: 1,
              vrYearOfUpdateTime: currentDay.year(),
            },
          },
        ]).then(allRes => {
          const statisticsResultList = allRes;

          if (statisticsResultList.length === 5) {
            const statistics = {
              totalNodeAmount: statisticsResultList[0]['amount'] || 0,
              maxNodeAmountByDay: statisticsResultList[1]['amountList'].length > 0 ?
                statisticsResultList[1]['amountList'][0]['amount'] : 0,
              maxNodeAmountByDayDate: statisticsResultList[1]['amountList'].length > 0 ?
                statisticsResultList[1]['amountList'][0].group['vr_date_of_update_time'] : undefined,
              nodeAmountThisMonth: statisticsResultList[2]['amount'] || 0,
              nodeAmountThisQuarter: statisticsResultList[3]['amount'] || 0,
              nodeAmountThisYear: statisticsResultList[4]['amount'] || 0,
            };

            me.props.bus.emit('teamwork', 'statistics.member_node_amount.success', {
              memberId,
              statistics,
            });
          }
        }).catch(error => {
          console.log('获取节点数量统计信息失败', error);
          me.props.bus.emit('teamwork', 'statistics.member_node_amount.failed',
            {memberId, error});
        })
    });

    // 获取凭据列表
    me.props.bus.sub(me, 'teamwork', 'ticket.list.do', ({viewId, parameters, forceReload}) => {
      if (me.props.viewId !== viewId) return;
      if (!me.props.viewId) {
        me.ticketLoadingStatus = 'idle';
        return;
      }
      let loadingParameters = JSON.stringify(parameters);
      if (forceReload === true) me.ticketLoadingStatus = 'idle';
      if (me.ticketLoadingStatus === 'success' && loadingParameters === me.ticketLoadingParameters) {
        me.props.bus.emit('teamwork', 'ticket.list.success',
          {viewId, ticketList: me.ticketList, total: me.ticketTotal});
      } else if (me.ticketLoadingStatus === 'processing' && loadingParameters === me.ticketLoadingParameters) {
        // Do nothing
      } else {
        me.ticketLoadingStatus = 'processing';
        me.ticketLoadingParameters = loadingParameters;
        me.props.bus.emit('teamwork', 'ticket.list.processing', {viewId, parameters});
        ViewManager.loadViewTeamworkTickets(me.props.viewId, parameters).then(({data, total}) => {
          if (me.ticketLoadingParameters !== loadingParameters) return;
          me.ticketList = data;
          me.ticketTotal = total;
          me.ticketLoadingStatus = 'success';
          me.props.bus.emit('teamwork', 'ticket.list.success', {viewId, ticketList: me.ticketList, total, reloaded: true});
        }).catch(error => {
          if (me.ticketLoadingParameters !== loadingParameters) return;
          me.ticketLoadingStatus = 'failed';
          console.log('获取图谱凭据列表失败', error);
          me.props.bus.emit('teamwork', 'ticket.list.failed', {viewId});
        });
      }
    });

    if (me.props.viewId) {
      requestAnimationFrame(() => me.props.bus.emit('teamwork', 'member.list.do',
        {viewId: me.props.viewId}));
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.viewId !== this.props.viewId) {
      this.props.bus.emit('teamwork', 'member.list.do', {viewId: this.props.viewId});
    }
  }

  componentWillUnmount() {
    this.props.bus.remove(this);
  }

  /**
   * 获取用户在当前图谱的近期节点统计数据
   *
   * @param {number[]} userIds
   * @return {Promise}
   */
  loadUserNodeStatisticsInView = userIds => {
    let me = this;
    let weekday = moment().weekday() + 1;
    let startDay = moment().hour(0).minute(0).second(0).millisecond(0).subtract(weekday, 'd').format("YYYY-MM-DD");
    return new Promise((resolve, reject) => {
      overrideNextMessage('static::batchLoadNodeAmountStatistics', false);
      NodeDataProvider
        .batchLoadNodeAmountStatistics([{
          conditions: {vrDateOfUpdateTimeAfterEqual: startDay, viewId: me.props.viewId, userConfirmed: 1},
          statisticsBy: ['user_id'],
        }, {
          conditions: {viewId: me.props.viewId, userConfirmed: 1},
          statisticsBy: ['user_id'],
        }])
        .then(statisticsResultList => {
          let result = {};
          userIds.forEach(userId => result[`u_${userId}`] = {currentWeekday: -1, current: -1});
          statisticsResultList[0]['amountList'].forEach(resultItem => {
            let userId = resultItem.group['user_id'], key = `u_${userId}`;
            result[key] && (result[key].currentWeekday = resultItem.amount);
          });
          statisticsResultList[1]['amountList'].forEach(resultItem => {
            let userId = resultItem.group['user_id'], key = `u_${userId}`;
            result[key] && (result[key].current = resultItem.amount);
          });
          resolve(result);
        })
        .catch(error => reject(error));
    });
  };

  /**
   * 获取用户节点统计数据
   *
   * @param {number[]} userIds
   * @returns {*}
   */
  loadUserNodeStatistics = userIds => {
    let me = this;
    return new Promise((resolve, reject) => {
      let statisticsRequestList = userIds.reduce(
        (accumulator, currentValue) =>
          accumulator.concat({conditions: {userId: currentValue, userConfirmed: 1}}), []
      );
      overrideNextMessage('static::batchLoadNodeAmountStatistics', false);
      NodeDataProvider
        .batchLoadNodeAmountStatistics(statisticsRequestList
          .concat({conditions: {viewId: me.props.viewId}, statisticsBy: ['user_id']}))
        .then(statisticsResultList => {
          let result = {};
          statisticsRequestList.forEach((r, idx) => {
            let userId = statisticsRequestList[idx].conditions['userId'], key = `u_${userId}`;
            result[key] = {total: 0, current: 0};
            result[key].total = statisticsResultList[idx]['amount'];
          });
          resolve(result);
        })
        .catch(error => reject(error));
    });
  };

  render() {
    return (
      <div style={{height: 0, width: 0}} />
    );
  }
}

TeamworkLogic.defaultProps = {
  bus: PB,
};

TeamworkLogic.propTypes = {
  bus: PropTypes.instanceOf(SimplePB),
  viewId: PropTypes.string.isRequired,
  viewOwnerId: PropTypes.number.isRequired,
  currentUserId: PropTypes.number.isRequired,
};

export default TeamworkLogic;