import React from 'react';
import PropTypes from 'prop-types';
import {Menu} from 'antd';

import PB, {SimplePB} from '@/libs/simplePB';
import Node from '@/libs/view/Node';
import Icon from "@/components/common/common.icon";
import CommonContextMenu from "@/components/common/common.contextMenu";
import {IconTypes} from "@/constants/common";
import {
  getNodeDisplayTitle,
  NODE_TYPE_TEXT,
  NODE_TYPE_TAG,
  TYPE_FIELD_NAME,
} from "@/constants/vis.defaultDefine.1";
import IconMart from "@/components/common/node/common.node.iconMart";
import {reloadCustomIconFromCache} from '@/components/common/node/common.node.customIcon';
import iconMartStyle from '@/style/common/node/common.node.iconMart.less';

/**
 * 节点右键菜单（一般状态下，已保存的节点）：
 *  # 添加关联节点 - 添加新节点并关联到此节点
 *  # 编辑节点详情 - 修改节点信息
 *  # 关联上一节点：<节点名，限制5字符>
 *  # 进入多点连线状态
 *  # 设置标记 - 子菜单展示
 *  # 固定位置/取消固定 - 根据节点位置是否固定展示
 *  # 联想线索 - 对此节点进行线索联想，展示十条，仅第一次点击时从后台获取数据，其余为切换操作
 *  # 新增附加信息 - 打开上传附加信息对话框
 *  # 删除节点
 *
 * 节点右键菜单（一般状态下，未保存的节点）：
 *  # 保留节点 - 将节点保留在图谱上
 *  # 关联节点 - 展示与此节点相关联的节点列表
 *  # 删除节点
 */

const scaleText = ['微', '小', '中', '大'];

class NodeNormalContextMenu extends React.PureComponent {
  cachedIconInfo = [];

  /**
   * 保留节点 - 将节点保留在图谱上
   */
  onSave = () => {
    this.props.bus.emit('network', 'node.on_save', {node: this.props.node});
  };

  /**
   * 添加关联节点 - 添加新节点并关联到此节点
   */
  onAddNodeWith = () => {
    this.props.bus.emit('network', 'node.on_add', {withNode: this.props.node});
  };

  /**
   * 编辑节点详情 - 修改节点信息
   */
  onEdit = () => {
    this.props.bus.emit('network', 'node.on_edit',
      {node: this.props.node, id: this.props.node.id});
  };

  /**
   * 关联上一节点：<节点名，限制5字符>
   */
  onLinkToLast = () => {
    this.props.bus.emit('network', 'edge.on_add',
      {from: this.props.lastSelectedNode, to: this.props.node});
  };

  /**
   * 进入多点连线状态
   */
  onBatchLink = () => {
    this.props.bus.emit('network', 'edge.on_batch_add_from_node', {fromNode: this.props.node});
  };

  /**
   * 设置标记 - 子菜单展示
   */
  onSetFlag = (flag, extra, title) => {
    this.props.bus.emit('network', 'node.on_set_flag', {nodes: [this.props.node], flag, extra, title});
  };

  /**
   * 设置缩放比例
   * @param scale
   */
  onSetScale = scale => {
    this.props.bus.emit('network', 'node.on_set_scale', {nodes: [this.props.node], scale});
  };

  /**
   * 固定位置/取消固定 - 根据节点位置是否固定展示
   */
  onFixChange = () => {
    this.props.bus.emit('network',
      (this.props.node.fixed && !this.props.node._locked) ? 'node.on_unfix' : 'node.on_fix',
      {nodes: [this.props.node]});
  };

  /**
   * 设置/取消首屏展示 - 根据节点设置展示
   */
  onPriorityChange = () => {
    this.props.bus.emit('relation',
      `view.favorite.node.${this.props.node._viewFavorite > -1 ? 'remove' : 'add'}`, {nodeId: this.props.node.id});
  };

  /**
   * 设置/取消我的收藏
   */
  onFavoriteChange = () => {
    this.props.bus.emit('relation',
      `user.favorite.node.${this.props.node._userFavorite > -1 ? 'remove' : 'add'}`, {nodeId: this.props.node.id});
  };

  /**
   * 联想线索 - 对此节点进行线索联想，展示十条，仅第一次点击时从后台获取数据，其余为切换操作
   */
  onGetClue = () => {
    this.props.bus.emit('network', 'node.on_get_related_clue', {node: this.props.node});
  };

  /**
   * 新增附加信息 - 打开上传附加信息对话框
   */
  onShowExtraInfo = () => {
    this.props.bus.emit('network', 'node.on_add_extra_info', {node: this.props.node});
  };

  /**
   * 删除节点
   */
  onRemove = () => {
    this.props.bus.emit('network', 'node.on_remove', {node: this.props.node});
  };

  componentDidMount() {
    let me = this;

    reloadCustomIconFromCache().then(list => {
      me.cachedIconInfo = [...list].filter(i => !!i).slice(0, 3);
      me.forceUpdate();
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let me = this;

    if (me.props.visible && !prevProps.visible) {
      reloadCustomIconFromCache().then(list => {
        me.cachedIconInfo = [...list].filter(i => !!i).slice(0, 6);
        me.forceUpdate();
      });
    }
  }

  render() {
    let me = this;
    const editableNodeTypes = [NODE_TYPE_TEXT, NODE_TYPE_TAG];
    
    if (!me.props.node) {
      return null;
    }

    return (
      <CommonContextMenu {...me.props}>
        {
          !me.props.node.status && (
            <Menu.Item key={'save'} onClick={me.onSave}>
              <Icon name={'check'} style={{marginRight: 0}}/>
              <span style={{marginLeft: '0.5em'}}>{`保留节点`}</span>
            </Menu.Item>
          )
        }
        <Menu.Item key={'add_node_with'} onClick={me.onAddNodeWith} disabled={!me.props.node.status}>
          <Icon name={'plus-circle'} style={{marginRight: 0}}/>
          <span style={{marginLeft: '0.5em'}}>{`添加相连节点`}</span>
        </Menu.Item>
        <Menu.Item
          key={'edit'}
          onClick={me.onEdit}
          disabled={!editableNodeTypes.includes(me.props.node[TYPE_FIELD_NAME]) || !me.props.node.status || me.props.readOnly}
        >
          <Icon name={'edit'} style={{marginRight: 0}}/>
          <span style={{marginLeft: '0.5em'}}>{`编辑节点详情`}</span>
        </Menu.Item>
        <Menu.Item
          key={'link_to_last'}
          onClick={me.onLinkToLast}
          disabled={
            !me.props.node.status || !me.props.lastSelectedNode || (me.props.node.id === me.props.lastSelectedNode.id)
          }
        >
          <Icon name={'icon-relation4'} type={IconTypes.ICON_FONT} style={{marginRight: 0}}/>
          <span style={{marginLeft: '0.5em'}}>
            {`关联上一节点${me.props.lastSelectedNode && (me.props.node.id !== me.props.lastSelectedNode.id) ?
              '：' + getNodeDisplayTitle(me.props.lastSelectedNode, 6) : ''}`}
          </span>
        </Menu.Item>
        <Menu.Item key={'batch_link'} onClick={me.onBatchLink} disabled={!me.props.node.status || me.props.readOnly}>
          <Icon name={'icon-star'} type={IconTypes.ICON_FONT} style={{marginRight: 0}}/>
          <span style={{marginLeft: '0.5em'}}>{`多点连线`}</span>
        </Menu.Item>
        {
          me.props.node[TYPE_FIELD_NAME] === NODE_TYPE_TEXT && (
            <Menu.SubMenu
              popupClassName={'dark-theme'}
              key={'set_flag'}
              disabled={!me.props.node.status || me.props.readOnly}
              title={(
                <span>
                  <Icon name={'flag'} type={IconTypes.FONT_AWESOME} style={{marginRight: 0}}/>
                  <span style={{marginLeft: '0.5em'}}>{`设置标记`}</span>
                </span>
              )}
            >
              <Menu.Item className={iconMartStyle['icon-mart-menu-item']}>
                <IconMart
                  onIconClicked={({value, extra, title}) => me.onSetFlag(value, extra, title)}
                  customIcons={me.cachedIconInfo}
                />
              </Menu.Item>
            </Menu.SubMenu>
          )
        }
        <Menu.SubMenu
          popupClassName={'dark-theme'}
          key={'set_scale'}
          disabled={!me.props.node.status || me.props.readOnly}
          title={(
            <span>
              <Icon name={'icon-scale'} type={IconTypes.ICON_FONT} style={{marginRight: 0}}/>
              <span style={{marginLeft: '0.5em'}}>{`缩放比例`}</span>
            </span>
          )}
        >
          {
            [50, 100, 200, 400].map((i, idx) => (
              <Menu.Item key={`scale-${idx}`} onClick={() => me.onSetScale(i / 100)} disabled={!me.props.node.status}>
                <Icon
                  name={'icon-yuandianda'}
                  type={IconTypes.ICON_FONT}
                  style={{
                    marginRight: 0,
                    fontSize: `${0.2 * (idx + 2)}rem`,
                    // lineHeight: '1rem',
                    verticalAlign: `${0.12 - 0.12 * idx}rem`,
                  }}
                />
                <span style={{marginLeft: '0.5em'}}>{scaleText[idx]}（{`${i}%`}）</span>
              </Menu.Item>
            ))
          }
        </Menu.SubMenu>
        <Menu.Item key={'fix_change'} onClick={me.onFixChange} disabled={!me.props.node.status || me.props.readOnly}>
          <Icon
            name={'pushpin'}
            style={{marginRight: 0, transform: (me.props.node.fixed && !me.props.node._locked) ? 'rotate(45deg)' : 'rotate(-35deg)'}}
          />
          <span style={{marginLeft: '0.5em'}}>{(me.props.node.fixed && !me.props.node._locked) ? '取消固定位置' : '固定节点位置'}</span>
        </Menu.Item>
        <Menu.Item
          key={'set_favorite'}
          onClick={me.onFavoriteChange}
          disabled={!me.props.node.status || me.props.readOnly}
        >
          <Icon
            name={'heart'}
            theme={me.props.node._userFavorite > -1 ? undefined : 'filled'}
            style={{marginRight: 0}}
          />
          <span style={{marginLeft: '0.5em'}}>{me.props.node._userFavorite > -1 ? '取消我的收藏' : '设为我的收藏'}</span>
        </Menu.Item>
        <Menu.Item
          key={'set_priority_10'}
          onClick={me.onPriorityChange}
          disabled={!me.props.node.status || me.props.readOnly}
        >
          <Icon
            name={'star'}
            theme={me.props.node._viewFavorite > -1 ? undefined : 'filled'}
            style={{marginRight: 0}}
          />
          <span style={{marginLeft: '0.5em'}}>{me.props.node._viewFavorite > -1 ? '设为普通节点' : '设为重要节点'}</span>
        </Menu.Item>
        <Menu.Item key={'show_extra_info'} onClick={me.onShowExtraInfo} disabled={!me.props.node.status || me.props.readOnly}>
          <Icon name={'paper-clip'} style={{marginRight: 0}}/>
          <span style={{marginLeft: '0.5em'}}>{`附加信息`}</span>
        </Menu.Item>
        <Menu.Item key={'remove'} onClick={me.onRemove} disabled={me.props.readOnly}>
          <Icon name={'delete'} style={{marginRight: 0}}/>
          <span style={{marginLeft: '0.5em'}}>{`删除节点`}</span>
        </Menu.Item>
      </CommonContextMenu>
    );
  }
}

NodeNormalContextMenu.defaultProps = {
  visible: false,
  x: 0,
  y: 0,
  bus: PB,
  readOnly: false,
};

NodeNormalContextMenu.propTypes = {
  visible: PropTypes.bool,
  x: PropTypes.number,
  y: PropTypes.number,
  bus: PropTypes.instanceOf(SimplePB),
  node: PropTypes.instanceOf(Node),
  lastSelectedNode: PropTypes.instanceOf(Node),
  readOnly: PropTypes.bool,
};

export default NodeNormalContextMenu;