import React from 'react';
import PropTypes from 'prop-types';
import {Table} from "antd";
import UserAvatar from "react-user-avatar";
import moment from "moment";
// import ClampLines from '@/libs/my-react-clamp-lines';

import PB, {SimplePB} from "@/libs/simplePB";

import {AvatarColors} from "@/constants/common";

import {getToken, REQUEST_BASE} from "@/utils/HttpUtil";

import style from '@/style/common/view/common.view.teamwork.memberStatisticsModal.less';

const MAX_RECENT_ITEM_AMOUNT = 3;

const COLUMNS = (me) => [
  {
    title: '成员/加入天数',
    dataIndex: 'name',
    key: 'name',
    className: style['member-table-column-name'],
    width: '10rem',
    sorter: (a, b) => (a['joinDateTime'] < b['joinDateTime']) ? -1 : (a['joinDateTime'] > b['joinDateTime'] ? 1 : 0),
    render: (text, record) => {
      const days = moment().diff(moment(record.joinDateTime, 'YYYY-MM-DD HH:mm:ss'), 'days');
      let joinDateTime;
      if (days < 1) {
        joinDateTime = <div style={{fontSize: '0.8rem', color: '#999'}}>加入:不到 1 天</div>;
      } else {
        joinDateTime = <div style={{fontSize: '0.8rem', color: '#999'}}>加入:{days} 天</div>;
      }
      return (
        <div style={{display: 'flex', alignItems: 'center', cursor: 'pointer'}}
             onClick={() => me.doShowMemberStatisticsChart(record.id)}
        >
          <div
            className={`${style['avatar']} ${record.picId ? '' : style['avatar-default-icon']}`}
          >
            <UserAvatar
              size={'var(--avatar-size)'}
              name={record.name}
              src={record.picId ? `${REQUEST_BASE}/user/user/file/${record.picId}?Authorization=${getToken()}` : undefined}
              colors={AvatarColors}
            />
          </div>
          <div style={{lineHeight: 1.5}}>
            <div>{record.name}</div>
            {joinDateTime}
          </div>
        </div>
      );
    },
  },
  {
    title: '贡献节点',
    dataIndex: 'amount',
    key: 'amount',
    className: style['member-table-column-amount'],
    sorter: (a, b) => a['amount'] - b['amount'],
    width: '7rem',
    defaultSortOrder: 'descend',
    render: (amount, record) => (
      <div onClick={() => me.doShowMemberStatisticsChart(record.id)} style={{cursor: 'pointer'}}>
        <div>{amount}</div>
        <div style={{fontSize: '0.8rem', color: '#999'}}>({(Number.parseFloat(record['percent']) * 100).toFixed(2)}%)</div>
      </div>
    ),
  },
  // {
  //   title: '加入天数',
  //   dataIndex: 'joinDateTime',
  //   key: 'joinDateTime',
  //   className: style['member-table-column-join'],
  //   width: '6.5rem',
  //   sorter: (a, b) => (a['joinDateTime'] < b['joinDateTime']) ? -1 : (a['joinDateTime'] > b['joinDateTime'] ? 1 : 0),
  //   render: value => {
  //     const days = moment().diff(moment(value, 'YYYY-MM-DD HH:mm:ss'), 'days');
  //
  //   },
  // },
  {
    title: '最新活跃',
    dataIndex: 'lastAccessDateTime',
    key: 'lastAccessDateTime',
    className: style['member-table-column-last_access_center'],
    width: '7rem',
    sorter: (a, b) => {
      if (a['lastAccessDateTime'].length <= 0 && b['lastAccessDateTime'].length <= 0) {
        return 0;
      } else if (a['lastAccessDateTime'].length <= 0) {
        return -1;
      } else if (b['lastAccessDateTime'].length <= 0) {
        return 1;
      } else {
        return (a['lastAccessDateTime'][0] < b['lastAccessDateTime'][0]) ? -1 :
          (a['lastAccessDateTime'][0] > b['lastAccessDateTime'][0] ? 1 : 0)
      }
    },
    render: (value, record) => (
      <span onClick={() => me.doShowMemberStatisticsChart(record.id)} style={{cursor: 'pointer'}}>
        {value.length > 0 ? moment(value, 'YYYY-MM-DD HH:mm:ss').fromNow() : '暂无记录'}
      </span>
    ),
  },
  // {
  //   title: '最新录入',
  //   dataIndex: 'lastInputNodes',
  //   key: 'lastInputNodes',
  //   className: style['member-table-column-last_input'],
  //   width: '27.5rem',
  //   render: (value/*, record*/) => (
  //     <span>
  //       {value.slice(0, MAX_RECENT_ITEM_AMOUNT).map((node, idx) => (
  //         <Tooltip key={`tip-${idx}`} title={getNodeDisplayTitle(node)} overlayClassName={'use-original'}>
  //           <Tag
  //             key={`tag-${idx}`}
  //             onClick={() => me.doFocusNode(node)}
  //             style={{cursor: 'pointer'}}
  //           >
  //             <span
  //               style={{
  //                 display: 'inline-block',
  //                 maxWidth: '7.25em',
  //                 height: '1.33em',
  //                 whiteSpace: 'nowrap',
  //                 overflow: 'hidden',
  //                 textOverflow: 'ellipsis',
  //               }}
  //             >
  //               {getNodeDisplayTitle(node)}
  //             </span>
  //           </Tag>
  //         </Tooltip>
  //       ))}
  //     </span>
  //   ),
  // },

];

const COLUMNS_SHOW = (me) => [
  {
    title: '操作',
    dataIndex: 'id',
    key: 'actions',
    className: style['member-table-column-actions'],
    width: '4rem',
    render: (id, record) => {
      return (
        <span>
        {
          me.props.readonly ? (
            id === me.props.currentUserId ? (
              <a onClick={() => me.onRemoveMember()}>退群</a>
            ) : null
          ) : (
            id === me.props.currentUserId ? null : (
              <a onClick={() => me.onRemoveMember(record.member)}>移除</a>
            )
          )
        }
      </span>
      );
    },
  },
];

class TeamworkMemberStatisticsTable extends React.Component {
  state = {
    memberStatisticsList: [],
    overallStatistics: {},
  };

  members = undefined;

  doShowMemberStatisticsChart = (memberId) => {
    let me = this;
    if (me.props.type === 'transfer') return;
    me.props.bus.emit('teamwork', 'member.node_panel.show',
      {memberId});
  };

  doShowMemberNodeTable = (nodes, memberInfo) => {
    let me = this;

    me.props.bus.emit('teamwork', 'member.statistics.show_node_table', {nodes, memberInfo});
  };

  onRemoveMember = userInfo => {
    this.props.bus.emit('teamwork', 'member.remove.on', {viewId: this.props.viewId, userInfo});
  };

  doFocusNode = node => {
    this.props.bus.emit('teamwork', 'member.list.hide_modal');
    this.props.bus.emit('network', 'focus', node);
  };

  componentDidMount() {
    let me = this;

    me.props.bus.sub(me, 'teamwork', 'statistics.nodes.ready', ({nodes}) => {
      me.nodes = nodes;
      if (me.nodes && me.members) {
        me.props.bus.emit('teamwork', 'statistics.result.get');
      }
    });

    me.props.bus.sub(me, 'teamwork', 'statistics.members.ready', ({members}) => {
      me.members = members;
      if (me.nodes && me.members) {
        me.props.bus.emit('teamwork', 'statistics.result.get');
      }
    });

    me.props.bus.sub(me, 'teamwork', 'statistics.result.ready',
      ({memberStatisticsList, overallStatistics}) =>
      {me.setState({memberStatisticsList, overallStatistics})});

    if (me.props.nodes) {
      me.nodes = me.props.nodes;
    } else {
      requestAnimationFrame(() => {
        me.props.bus.emit('teamwork', 'statistics.nodes.get');
      });
    }

    if (me.props.members) {
      me.members = me.props.members;
    } else {
      requestAnimationFrame(() => {
        me.props.bus.emit('teamwork', 'statistics.members.get');
      });
    }

    if (me.nodes && me.members) {
      me.props.bus.emit('teamwork', 'statistics.result.get');
    }
  }

  componentWillUnmount() {
    this.props.bus.remove(this);
  }

  render() {
    let me = this;
    if (me.props.type === 'show') {
      return (
        <Table
          className={`${style['member-table']} scrollbar-none`}
          columns={COLUMNS(me).concat(COLUMNS_SHOW(me))}
          dataSource={me.state.memberStatisticsList}
          pagination={false}
          rowKey={'id'}
        />
      );
    } else if (me.props.type === 'transfer') {
      return (
        <Table
          className={`${style['member-table']} scrollbar-none ${me.props.type === 'transfer' ? style['view-transfer'] : undefined}`}
          rowSelection={{
            type: 'radio',
            onChange: (selectedRowKeys, selectedRows) => {
              console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
              // me.props.bus.emit('view', 'transfer.toUserId', {userId: selectedRowKeys[0]});
              me.props.viewTransferUserId(selectedRowKeys[0]);
            },
          }}
          columns={COLUMNS(me)}
          dataSource={me.state.memberStatisticsList.filter(user => user.member.userId !== me.props.currentUserId)}
          pagination={false}
          rowKey={'id'}
        />
      );
    }

  }
}

TeamworkMemberStatisticsTable.defaultProps = {
  bus: PB,
  nodes: undefined,
  members: undefined,
  scroll: undefined,
  readonly: true,
  type: 'show',
};

TeamworkMemberStatisticsTable.propTypes = {
  bus: PropTypes.instanceOf(SimplePB),
  nodes: PropTypes.array,
  members: PropTypes.array,
  scroll: PropTypes.object,
  readonly: PropTypes.bool,
  viewId: PropTypes.string.isRequired,
  currentUserId: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  viewTransferUserId: PropTypes.func,
};

export default TeamworkMemberStatisticsTable;