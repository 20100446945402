// 获取鼠标位置
export const getMousePos = (event, offset = {x: 0, y: 0}) => {
    if (!offset || !offset.x) {
        offset.x = 0
    }
    if (!offset || !offset.y) {
        offset.y = 0
    }
    let e = event || window.event;
    let scrollX = document.documentElement.scrollLeft || document.body.scrollLeft;
    let scrollY = document.documentElement.scrollTop || document.body.scrollTop;
    let x = e.pageX || e.clientX + scrollX;
    let y = e.pageY || e.clientY + scrollY;
    x = x + offset.x
    y = y + offset.y
    // console.log('鼠标 x y ', x, y)
    return {x, y};
}

//todo:生成从minNum到maxNum的随机整数
export const randomNum = (minNum, maxNum) => {
    // if (minNum <= 0) minNum = 0
    // if (maxNum <= 0) maxNum = 0

    return parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10);
    /*
        if (maxNum !== 0) {
            if (minNum !== 0) {
                return parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10);
            } else {
                return Math.round(maxNum);
            }
        } else if (minNum !== 0) {
            return parseInt(Math.random() * minNum + 1, 10);
        } else {
            return 0;
        }
    */
}

// 读取节点数据字段值，支持.分隔层级
export const getObjectValue = (object, fieldPath = []) => {
    if (!Array.isArray(fieldPath)) {
        fieldPath = fieldPath.split('.')
    }
    let value = {...object}
    // console.log('getObjectValue value=',value)
// 层级取值
    try {
        fieldPath.forEach(field => {
            if (value[field]) {
                value = value[field]
            } else {
                throw new Error('字段值不存在')
            }
        })
    } catch (e) {
        // console.log('获取节点字段值报错：', e)
        value = undefined
    }
    return value
}

//prov 简化
export const simplifyProv = (prov, getFullName = false) => {
    if (!prov) {
        return null
    }
    if (getFullName == false) {
        return prov.replace("省", "").replace("市", "").replace("回族自治区", "").replace("维吾尔自治区", "").replace("壮族自治区", "").replace("自治区", "").replace("特别行政区", "").replace("地区", "");
    } else {
        const simplified = {
            '北京': '北京市',
            '天津': '天津市',
            '河北': '河北省',
            '山西': '山西省',
            '内蒙古': '内蒙古自治区',
            '辽宁': '辽宁省',
            '吉林': '吉林省',
            '黑龙江': '黑龙江省',
            '上海': '上海市',
            '江苏': '江苏省',
            '浙江': '浙江省',
            '安徽': '安徽省',
            '福建': '福建省',
            '江西': '江西省',
            '山东': '山东省',
            '河南': '河南省',
            '湖北': '湖北省',
            '湖南': '湖南省',
            '广东': '广东省',
            '广西': '广西壮族自治区',
            '海南': '海南省',
            '四川': '四川省',
            '贵州': '贵州省',
            '云南': '云南省',
            '西藏': '西藏自治区',
            '重庆': '重庆市',
            '陕西': '陕西省',
            '甘肃': '甘肃省',
            '青海': '青海省',
            '宁夏': '宁夏回族自治区',
            '新疆': '新疆维吾尔自治区',
            '台湾': '台湾省',
            '香港': '香港特别行政区',
            '澳门': '澳门特别行政区',
        }
        if (simplified[prov]) {
            return simplified[prov]
        } else {
            return null
        }
    }
}

/**
 * 数组去重
 * ES6 有更简单的写法，但是这个方法好用一些
 * @param array
 * @param isSorted 表示函数传入的数组是否已排过序，如果为 true，将会采用更快的方法进行去重
 * @param iteratee 传入一个函数，可以对每个元素进行重新的计算，然后根据处理的结果进行去重
 * @returns {Array}
 */
export const unique = (array, isSorted, iteratee) => {
    var res = [];
    var seen = [];

    for (var i = 0, len = array.length; i < len; i++) {
        var value = array[i];
        var computed = iteratee ? iteratee(value, i, array) : value;
        if (isSorted) {
            if (!i || seen !== computed) {
                res.push(value)
            }
            seen = computed;
        } else if (iteratee) {
            if (seen.indexOf(computed) === -1) {
                seen.push(computed);
                res.push(value);
            }
        } else if (res.indexOf(value) === -1) {
            res.push(value);
        }
    }
    return res;
}

//深复制，要想达到深复制就需要用递归
export const deepCopy = (o, c) => {
    var c = c || {}
    for (var i in o) {
        if (typeof o[i] === 'object') {
            //要考虑深复制问题了
            if (o[i].constructor === Array) {
                //这是数组
                c[i] = []
            } else {
                //这是对象
                c[i] = {}
            }
            deepCopy(o[i], c[i])
        } else {
            c[i] = o[i]
        }
    }
    return c
}