import {strToFunc} from "@/components/common/common.functions";

/**
 * @description 将版本1配置格式转换为版本2配置格式，不处理函数
 * @param cfg
 * @return {Object}
 */
const v1ToV2 = cfg => {
  let result = {
    version: '2.0.0',
    request: {},
    target: {},
    operations: [],
  };

  result.request.targetLimit = cfg['targetLimit'] || 10;
  result.request.resultLimit = cfg['resultLimit'] || 100;
  result.target.formLayout = cfg['targetFormLayout'];
  result.target.label = cfg['targetLabel'];
  result.target.default = cfg['targetDefault'];
  result.target.items = cfg['targets'].map(key => ({
    type: key,
    label: cfg['targetConfig'][key].label,
    shortText: cfg['targetConfig'][key].shortText,
    dynamicLabel: cfg['targetConfig'][key].dynamicLabel,
    dynamicShortText: cfg['targetConfig'][key].dynamicShortText,
    filter: cfg['targetConfig'][key].filter,
  }));
  result.parameter = {
    formLayout: cfg['parameterFormLayout'],
    items: (cfg['parameters'] || []).map(key => ({
      name: key,
      type: cfg['parameterConfig'][key].type,
      label: cfg['parameterConfig'][key].label,
      default: cfg['parameterConfig'][key].default,
      optionSpan: cfg['parameterConfig'][key].optionSpan,
      options: cfg['parameterConfig'][key].options,
      required: cfg['parameterConfig'][key].required,
      enabledOptions: cfg['parameterConfig'][key].enabledOptions,
    })),
  };
  switch (cfg['mainOperation']) {
    case 'saveEdges':
      cfg['operationConfig']['saveEdges'] = cfg['operationConfig']['saveEdges'] || {};
      result.operations = [{
        type: 'saveEdges',
        lineType: cfg['operationConfig']['saveEdges'].lineType,
        lineStyle: cfg['operationConfig']['saveEdges'].lineStyle,
        copyTextKey: cfg['enableCopyText'] ? cfg['operationConfig']['copyText']['textKey'] : false,
      }];
      break;
    case 'saveNodes':
      if (cfg['resultConfig'] && cfg['resultConfig']['type'] && 'graphs' === cfg['resultConfig']['type']) {
        // 下穿至saveSubGraphs
      } else {
        cfg['operationConfig']['saveNodes'] = cfg['operationConfig']['saveNodes'] || {};
        result.operations = [{
          type: 'saveNodes',
          lineType: cfg['operationConfig']['saveNodes'].lineType,
          lineStyle: cfg['operationConfig']['saveNodes'].lineStyle,
          arrangeBy: cfg['operationConfig']['saveNodes'].arrangeBy,
          classifyBy: cfg['operationConfig']['saveNodes'].classifyBy,
          categories: cfg['operationConfig']['saveNodes'].categories,
          orders: cfg['operationConfig']['saveNodes'].orders,
          copyTextKey: cfg['enableCopyText'] ? cfg['operationConfig']['copyText']['textKey'] : false,
        }];
        break;
      }
    // noinspection FallThroughInSwitchStatementJS
    case 'saveSubGraphs':
      cfg['operationConfig']['saveSubGraphs'] = cfg['operationConfig']['saveSubGraphs'] || {};
      result.operations = [{
        type: 'saveSubGraphs',
        lineType: cfg['operationConfig']['saveSubGraphs'].lineType,
        lineStyle: cfg['operationConfig']['saveSubGraphs'].lineStyle,
        innerLineType: cfg['operationConfig']['saveSubGraphs'].innerLineType,
        innerLineStyle: cfg['operationConfig']['saveSubGraphs'].innerLineStyle,
        arrangeBy: cfg['operationConfig']['saveSubGraphs'].arrangeBy,
        classifyBy: cfg['operationConfig']['saveSubGraphs'].classifyBy,
        categories: cfg['operationConfig']['saveSubGraphs'].categories,
        orders: cfg['operationConfig']['saveSubGraphs'].orders,
        copyTextKey: cfg['enableCopyText'] ? cfg['operationConfig']['copyText']['textKey'] : false,
      }];
      break;
    case 'saveContent':
      result.operations = [{
        type: 'saveContent',
        contentParts: 'all',
        copyTextKey: cfg['enableCopyText'] ? cfg['operationConfig']['copyText']['textKey'] : false,
      }];
      break;
    case 'showWordCloud':
      result.operations = [{
        type: 'showWordCloud',
        kvLabel: cfg['resultConfig']['kvLabel'],
        copyTextKey: cfg['enableCopyText'] ? cfg['operationConfig']['copyText']['textKey'] : false,
      }];
      break;
    case 'showCharts':
      result.operations = [{
        type: 'showCharts',
        chartUrl: cfg['resultConfig']['chartUrl'],
        copyTextKey: cfg['enableCopyText'] ? cfg['operationConfig']['copyText']['textKey'] : false,
      }];
      break;
  }

  return result;
};

/**
 * 将配置中的方法源码转换为方法
 *
 * @param {TMicroServiceUIConfig} cfg
 */
const parseInnerFunc = cfg => {
  cfg.target.items.forEach(item => {
    if (item.dynamicLabel) {
      item.dynamicLabel = strToFunc(item.dynamicLabel);
    }
    if (item.dynamicShortText) {
      item.dynamicShortText = strToFunc(item.dynamicShortText);
    }
    if (item.filter) {
      item.filter = strToFunc(item.filter);
    }
  });
  cfg.operations.forEach(operation => {
    if (operation.orders && operation.orders.length > 0) {
      operation.orders.forEach(order => {
        if (order.compareFn) {
          order.compareFn = strToFunc(order.compareFn);
        }
      });
    }
  });
  return cfg;
};

/**
 * @description 将老版本配置格式转换为最新版本配置格式
 * @param cfg
 * @return {undefined|TMicroServiceUIConfig}
 */
export const toLatestConfig = cfg => {
  if (!cfg) return undefined;

  const cfgVersion = cfg['version'] || '1.0.0';

  switch (cfgVersion) {
    case '1.0.0':
      cfg = v1ToV2(cfg);
  }

  return parseInnerFunc(cfg);
};