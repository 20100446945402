import {bindUtil, withPromiseMessageDecorator} from "@/libs/core-decorators";
import messageProxy from '@/components/common/common.messageProxy';
import {getErrorInfo as commonGetErrorInfo} from "@/components/common/common.functions";
import {presentationOperationMessages} from "@/constants/messages";
import {
  API_AddRelationshipClue,
  API_LoadRelationshipClueList,
  API_RemoveRelationshipClue,
  API_UpdateRelationshipClue,
} from "@/api/relationshipClue";

@bindUtil.asSourceClass
class RelationshipClueDataProvider {
  static loadRelationshipClueList = (config) => {
    return new Promise((resolve, reject) => {
      API_LoadRelationshipClueList(config).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve(response.data);
        } else {
          reject({...RelationshipClueDataProvider.getErrorInfo(response)});
        }
      }).catch(error => {
        reject({...RelationshipClueDataProvider.getErrorInfo(error)});
      });
    });
  };

  static addRelationshipClue = (config) => {
    return new Promise((resolve, reject) => {
      API_AddRelationshipClue(config).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve(response.data.data);
        } else {
          reject({...RelationshipClueDataProvider.getErrorInfo(response)});
        }
      }).catch(error => {
        reject({...RelationshipClueDataProvider.getErrorInfo(error)});
      });
    });
  };

  static updateRelationshipClue = (Id, config) => {
    return new Promise((resolve, reject) => {
      API_UpdateRelationshipClue(Id, config).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve(response.data.data);
        } else {
          reject({...RelationshipClueDataProvider.getErrorInfo(response)});
        }
      }).catch(error => {
        reject({...RelationshipClueDataProvider.getErrorInfo(error)});
      });
    });
  };

  static removeRelationshipClue = (Id) => {
    return new Promise((resolve, reject) => {
      API_RemoveRelationshipClue(Id).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve();
        } else {
          reject({...RelationshipClueDataProvider.getErrorInfo(response)});
        }
      }).catch(error => {
        reject({...RelationshipClueDataProvider.getErrorInfo(error)});
      });
    });
  };

  /**
   * @private
   * 通過Axios请求返回的错误信息获取错误详情
   *
   * @param {*} error
   *
   * @return {{msg: string, code: number}}
   */
  static getErrorInfo = commonGetErrorInfo;
}

const overrideMessages = {};

/**
 * @type {typeof RelationshipClueDataProvider}
 */
const RelationshipClueDataProviderWrapper = withPromiseMessageDecorator(
  messageProxy(presentationOperationMessages, overrideMessages)
)(RelationshipClueDataProvider);

// noinspection JSUnusedGlobalSymbols
export const overrideNextMessage = (method, msg) => {
  overrideMessages[method] = msg;
};

export default RelationshipClueDataProviderWrapper;