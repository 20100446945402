import _ from "lodash";
import {SimplePB} from "@/libs/simplePB";

const BIND_SOURCE_FLAG = `bind-src-${Math.random()}`;
const BIND_TARGET_FLAG = `bind-dst-${Math.random()}`;

export const bindUtil = Object.freeze({
  asSourceClass: constructor => {
    const originalValues = {};
    if (!constructor['_$origin_constructor']) {
      Object.defineProperty(constructor, '_$origin_constructor',
        {value: constructor, writable: false, configurable: false});
    }
    Object.defineProperty(constructor, '_$BIND_SOURCE_FLAG',
      {value: BIND_SOURCE_FLAG, writable: false, configurable: false});
    Object.defineProperty(constructor, '_$BIND_SOURCE_UUID',
      {value: _.uniqueId('bind-src-'), writable: false, configurable: false});
    Object.defineProperty(constructor, '_$BIND_PB',
      {value: new SimplePB(), writable: false, configurable: false});
    Object.defineProperty(constructor, '_$original_values',
      {value: originalValues, writable: false, configurable: false});
    Object.getOwnPropertyNames(constructor).forEach(prop => {
      let descriptor = Object.getOwnPropertyDescriptor(constructor, prop);
      if (descriptor.writable && descriptor.enumerable && descriptor.configurable) {
        if (descriptor.get && descriptor.set) {
          const originSet = descriptor.set;
          descriptor.set = (value) => {
            originSet(value);
            requestAnimationFrame(() => constructor._$BIND_PB.emit('set',
              prop.charAt(0) === '_' ? prop.substring(1) : prop, value));
          };
        } else if (!(descriptor.get || descriptor.set) && !_.isFunction(descriptor.value)) {
          (prop => {
            constructor._$original_values[prop] = descriptor.value;
            descriptor.get = () => constructor._$original_values[prop];
            descriptor.set = (value) => {
              constructor._$original_values[prop] = value;
              requestAnimationFrame(() => constructor._$BIND_PB.emit('set',
                prop.charAt(0) === '_' ? prop.substring(1) : prop, value));
            };
            delete descriptor.value;
            delete descriptor.writable;
          })(prop);
        } else {
          return;
        }
        Object.defineProperty(constructor, prop, descriptor);
      }
    });

    return class extends constructor {
      constructor(...args) {
        super(...args);
        let me = this._self || this;
        me._$BIND_SOURCE_FLAG = BIND_SOURCE_FLAG;
        me._$BIND_SOURCE_UUID = _.uniqueId('bind-src-');
        me._$BIND_PB = new SimplePB();
        me._self = new Proxy(me, {
          get: function (target, key, receiver) {
            return Reflect.get(target, key, receiver);
          },
          set: function (target, key, value, receiver) {
            requestAnimationFrame(() => {
              me._$BIND_PB.emit('set', (
                key.charAt(0) === '_' ? key.substring(1) : key
              ), value);
            });
            return Reflect.set(target, key, value, receiver);
          },
        });
        return me._self;
      }
    }
  },
  asTargetClass: constructor => {
    if (!constructor['_$origin_constructor']) {
      Object.defineProperty(constructor, '_$origin_constructor',
        {value: constructor, writable: false, configurable: false});
    }
    return class extends constructor {
      componentDidMount(...args) {
        super.componentDidMount && super.componentDidMount(...args);

        let me = this;
        if (constructor.prototype[`${BIND_TARGET_FLAG}-params`]) {
          for (let k in constructor.prototype[`${BIND_TARGET_FLAG}-params`]) {
            if (Object.prototype.hasOwnProperty.call(constructor.prototype[`${BIND_TARGET_FLAG}-params`], k)) {
              const [instance, property] = constructor.prototype[`${BIND_TARGET_FLAG}-params`][k];
              let instanceObj = me;
              if (instance && instance._$BIND_SOURCE_FLAG) {
                instanceObj = instance;
              } else if (_.isString(instance)) {
                instance.split('.').forEach(key => {
                  if (instanceObj && instanceObj[key]) {
                    instanceObj = instanceObj[key];
                  } else {
                    instanceObj = undefined;
                  }
                });
              }
              if (instanceObj && instanceObj._$BIND_SOURCE_FLAG) {
                me[k] = instanceObj[property];
                instanceObj._$BIND_PB.sub(me, 'set', property, value => {
                  me[k] = value;
                });
              }
            }
          }
        }
      }

      componentWillUnmount(...args) {
        super.componentWillUnmount && super.componentWillUnmount(...args);

        let me = this;
        let instanceList = {};
        if (constructor.prototype[`${BIND_TARGET_FLAG}-params`]) {
          for (let k in constructor.prototype[`${BIND_TARGET_FLAG}-params`]) {
            if (Object.prototype.hasOwnProperty.call(constructor.prototype[`${BIND_TARGET_FLAG}-params`], k)) {
              const [instance] = constructor.prototype[`${BIND_TARGET_FLAG}-params`][k];
              instanceList[instance] = true;
            }
          }
        }
        Object.keys(instanceList).forEach(instance => {
          let instanceObj = me;
          instance.split('.').forEach(key => {
            if (instanceObj && instanceObj[key]) {
              instanceObj = instanceObj[key];
            } else {
              instanceObj = undefined;
            }
          });
          if (instanceObj && instanceObj._$BIND_SOURCE_FLAG) {
            instanceObj._$BIND_PB.remove(me);
          }
        });
      }
    }
  },
  /**
   * 将指定来源实例的指定成员绑定到此成员变量上
   *
   * @param {*} instanceSelector 来源实例选择器
   * @param {string} property 来源实例的成员变量名
   * @return {Function}
   */
  bindToProperty: (instanceSelector, property) => (target, name) => {
    target[`${BIND_TARGET_FLAG}-params`] = target[`${BIND_TARGET_FLAG}-params`] || {};
    target[`${BIND_TARGET_FLAG}-params`][name] = [instanceSelector, property];
  },
});

export const privateProtector = constructor => {
  if (!constructor['_$origin_constructor']) {
    Object.defineProperty(constructor, '_$origin_constructor',
      {value: constructor, writable: false, configurable: false});
  }
  return class extends constructor {
    constructor(...args) {
      super(...args);
      let me = this._self || this;
      return new Proxy(me, {
        get: function (target, key, receiver) {
          return Reflect.get(target, key, receiver);
        },
        set: function (target, key, value, receiver) {
          if (key.charAt(0) === '_') {
            throw new Error(`Cannot modify private variable ${key}.`);
          }
          return Reflect.set(target, key, value, receiver);
        },
      });
    }
  }
};

/**
 * @callback classWrapper
 * @param {class|Function} constructor 要装饰的类
 *
 * @return {*}
 */

/**
 * @callback messageProxyOnProcessing
 * @param {string} methodName 方法名称
 * @param {array} args 参数
 * @return {*}
 */

/**
 * @callback messageProxyOnSuccess
 * @param {string} methodName 方法名称
 * @param {*} onProcessingResult onProcessing函数返回值
 * @param {Array} args 参数
 */

/**
 * @callback messageProxyOnFailure
 * @param {string} methodName 方法名称
 * @param {*} onProcessingResult onProcessing函数返回值
 * @param {Array} args 参数
 */

/**
 * 消息装饰模块
 *
 * @param {{
 *   processing: messageProxyOnProcessing,
 *   success: messageProxyOnSuccess,
 *   failure: messageProxyOnFailure
 * }} messageProxy 消息代理结构
 * @param {string} key 方法关键字
 *
 * @return {classWrapper}
 */
export const withPromiseMessageDecorator = (messageProxy, key = '_default') => {
  return (constructor) => {
    if (!constructor['_$origin_constructor']) {
      Object.defineProperty(constructor, '_$origin_constructor',
        {value: constructor, writable: false, configurable: false});
    }
    let descriptor = Object.getOwnPropertyDescriptor(constructor['prototype'], 'constructor');
    if (descriptor) {
      // constructor is class
      let proxy = new Proxy(constructor, {
        get: function (target, key, receiver) {
          const value = Reflect.get(target, key, receiver);
          if (_.isFunction(value) && _.isString(key)) {
            if (target && target['_$origin_constructor'] &&
              Object.getOwnPropertyDescriptor(target['_$origin_constructor'], key)) {

              return (...args) => {
                let result = value(...args);
                if (result instanceof Promise) {
                  const proxyResult = messageProxy.processing(`static::${key}`, args);
                  return new Promise((resolve, reject) => {
                    result.then((...args) => {
                      messageProxy.success(`static::${key}`, proxyResult, args);
                      resolve(...args);
                    }).catch((...args) => {
                      messageProxy.failure(`static::${key}`, proxyResult, args);
                      reject(...args);
                    });
                  });
                } else {
                  return result;
                }
              };
            }
          }
          return value;
        },
      });
      descriptor.value = proxy;
      Object.defineProperty(constructor['prototype'], 'constructor', descriptor);

      return class extends proxy {
        constructor(...args) {
          super(...args);
          let me = this._self || this;
          return new Proxy(me, {
            get: function (target, key, receiver) {
              const origMethod = target[key];
              if (_.isFunction(origMethod)) {
                return function (...args) {
                  let result = origMethod.apply(me, args);
                  if (result instanceof Promise) {
                    const proxyResult = messageProxy.processing(`${key}`, args);
                    return new Promise((resolve, reject) => {
                      result.then((...args) => {
                        messageProxy.success(`${key}`, proxyResult, args);
                        resolve(...args);
                      }).catch((...args) => {
                        messageProxy.failure(`${key}`, proxyResult, args);
                        reject(...args);
                      });
                    });
                  }
                  return result;
                };
              } else {
                return Reflect.get(target, key, receiver);
              }
            },
          });
        }
      }
    } else if (_.isFunction(constructor)) {
      // constructor is only a function
      return (...args) => {
        let result = constructor(...args);
        if (result instanceof Promise) {
          const proxyResult = messageProxy.processing(key, args);
          return new Promise((resolve, reject) => {
            result.then((...args) => {
              messageProxy.success(key, proxyResult, args);
              resolve(...args);
            }).catch((...args) => {
              messageProxy.failure(key, proxyResult, args);
              reject(...args);
            });
          });
        } else {
          return result;
        }
      };
    }
  };
};