import React from 'react';
import PropTypes from 'prop-types';
import {Tooltip} from 'antd';
import LightBox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

import style from '@/style/components/toolbar/toolbar.nav.less'
import PB from "@/libs/simplePB";
import Icon from '@/components/common/common.icon';
import {randomNum, getObjectValue} from '@/components/common/common.functions'

// 动效地图组件
import Map from '@/components/mainView/toolbar/map.json'

// 地图数据，演示数据
import {worldCountryNodes} from '@/constants/worldcountry/node'
import {NetworkDataSetLoadingStatus, NetworkFileListLoadingStatus} from "@/libs/view/network/status";
import {autoSetState, withReactStateHelper} from "@/libs/react-state-helper";
import {bindUtil} from "@/libs/core-decorators";
import MainToolbarNavImg from "@/components/mainView/toolbar/main.toolbar.nav.img";
import {getToken, REQUEST_BASE} from "@/utils/HttpUtil";
// VisNetWork ref
import ViewDataProvider from '@/components/common/dataProvider/common.dataProvider.view';
import {
    NodeEvents,
} from "@/libs/view/network/events";
import intl from 'react-intl-universal';

const supportedSuffixList = ['png', 'jpg', 'gif', 'jpeg'];

/**
 * 通过数据分析接口，获取数据集（对象）显示在导航列表中
 */
@withReactStateHelper
@bindUtil.asTargetClass
class MainToolbarNav extends React.Component {
    // 闪烁时间间隔
    timer = 2000;
    // 设置定时器
    FlashInterval = undefined;
    // 地理过滤字段路径
    // mapFilterField = 'meta.object.meta.position'
    // mapClassifyField: 'meta.prov'

    mapFilterField = 'position';
    mapClassifyField= 'prov';
    // 时间线过滤字段路径
    // timeFilterField = 'meta.object.meta.create_date'
    timeFilterField = 'startDate';

    state = {
        // 强制刷新
        refresh: false,
        // 数据集列表的显示状态
        showChartNav: false,

        // 正在闪烁的点，数据集格式
        flashingNode: {nodes: [], edges: []},
        // nav 的tip
        // tip: null,

        // 显示大图索引
        showLightBox: -1,
    };

    @autoSetState
    @bindUtil.bindToProperty('props.networkRef', 'fileListLoadingStatus')
    fileListLoadingStatus = {status: NetworkFileListLoadingStatus.IDLE};

    @autoSetState
    @bindUtil.bindToProperty('props.networkRef', 'files')
    fileList = [];

    @autoSetState
    @bindUtil.bindToProperty('props.networkRef', 'dataSetsLoadingStatus')
    dataSetLoadingStatus = {status: NetworkDataSetLoadingStatus.IDLE};

    @autoSetState
    @bindUtil.bindToProperty('props.networkRef', 'dataSets')
    dataSets = [];

    componentDidMount() {
        let me = this;

        // 订阅新增节点后开始精确匹配
        this.props.networkRef.with(this).once(NodeEvents.ADDED, () => {
            // 改用演示数据
            me.flashing(worldCountryNodes)
        });
    }

    componentWillUnmount() {
        //去掉所有PB的订阅
        this.props.networkRef.unSubscribe(this);
    }

    onGetBriefingInfoList = type => {
        let me = this;

        // 更新当前图谱中LocalStorage节点数据
        let data = me.props.networkRef.getData();
        let viewInfo = data.viewInfo;
        PB.emit('teamwork', 'update.localstorage.members');
        PB.emit('nodeFilter', 'update.localstorage.nodeFilter', {viewId: viewInfo.viewId, type});

        if (viewInfo) {
            localStorage.setItem(`MT_view_${me.props.viewId}`, JSON.stringify(viewInfo));
        }
    };

    /**
     * 显示/关闭 图表导航区域
     */
    tgChartNav(type) {
        this.setState({showChartNav: this.state.showChartNav === type ? false : type}, () => {
            if (this.state.showChartNav === 'user' &&
                this.fileListLoadingStatus.status !== NetworkFileListLoadingStatus.SUCCESS) {

                // noinspection JSIgnoredPromiseFromCall
                this.props.networkRef.loadFileList();
            }
        })
    }

    //-------闪烁设置--------------

    // 过滤出有时间和有经纬度的节点
    getNodesHasPositionAndTime = (nodes, mapFilterField = this.mapFilterField, timeFilterField = this.timeFilterField) => {
        console.log('MainToolbarNav->getNodesHasPositionAndTime->开始过滤 nodes=', nodes);
        let result = [];
        if (nodes) {
            nodes.forEach(node => {
                // console.log('MainToolbarNav->getNodesHasPositionAndTime->开始过滤 getObjectValue(node, mapFilterField)=', getObjectValue(node, mapFilterField))
                // console.log('MainToolbarNav->getNodesHasPositionAndTime->开始过滤 getObjectValue(node, timeFilterField)=', getObjectValue(node, timeFilterField))
                if (getObjectValue(node, mapFilterField)) {
                    if (getObjectValue(node, timeFilterField)) {
                        result.push(node);
                    }
                }
            })
        }
        console.log('MainToolbarNav->getNodesHasPositionAndTime->过滤结束 result=', result);
        return result;

    };

    // 闪烁,从nodes里随机找个节点，发动给组件
    /**
     * nodes
     * status 开始闪烁还是取消
     */
    flashing = (nodes, status = true) => {
        // console.log('MainToolbarNav->flashing->开始flashing nodes=', nodes)
        let that = this;
        if (status === true) {
            let nodesLength = nodes.length;
            if (nodesLength < 1) {
                // 取消闪烁计数器
                this.FlashInterval = undefined;
                that.setState({
                    // tip: <span><Icon name="exclamation-circle"/> 没有找到新资源</span>,
                    flashingNode: {nodes: [], edges: []},
                }, () => {
                    setTimeout(() => {
                        that.setState({
                            // tip: null,
                        })
                    }, 3000)
                });
                return null;
            }
            that.setState({
                // tip: <span><Icon name="icon-server_busy" type={IconTypes.ICON_FONT}/> 正在计算……</span>,
                flashingNode: {nodes: [], edges: []},
            });

            this.FlashInterval = setTimeout(() => {
                // console.log('MainToolbarNav->flashing-> randomNum(0, nodesLength - 1)=', randomNum(0, nodesLength - 1))
                // 清空数据
                that.setState({
                    // tip: <span><Icon name="icon-server_busy" type={IconTypes.ICON_FONT}/> 正在计算……</span>,
                    flashingNode: {nodes: [], edges: []},
                }, () => {
                    let node = nodes[randomNum(0, nodesLength - 1)];
                    console.log('MainToolbarNav->flashing->随机选中的node=', node);
                    // const {name, type, color} = getNodeIcon(node);
                    that.setState({
                        flashingNode: {nodes: [{...node, [this.mapFilterField]: node['pos'] || node['position']}], edges: []},
                        // tip: <span><Icon name="info-circle" /> 找到新资源</span>,
                        // tip: <span>找到新资源：<Icon name={name} type={type} color={color}/> {node.fname}</span>
                        // tip: null
                    })
                    // console.log('MainToolbarNav->flashing->被随机选中 node=', node)
                    // 延迟1s显示数据，和tip显示同步
                    // 生成随机闪烁节点
                    /*setTimeout(() => {
                    }, 2000)*/
                })
            }, this.timer);
        } else {
            // 取消闪烁计数器
            this.FlashInterval = undefined;
        }
    };

    render() {
        let me = this;
        // const {networkRef} = this.props;
        const {flashingNode} = this.state;
        // console.log('MainToolbarNav->render->开始 this.state.flashingNode=', flashingNode)
        // 计算显示数据
        let data = [];
        // 记录最后更新时间戳
        let infoModified = [];
        let imagePathList = [];

        if (this.state.showChartNav === 'user') {
            switch (this.fileListLoadingStatus.status) {
                case NetworkFileListLoadingStatus.SUCCESS:
                    for (let i = 0; i < 10; i++) {
                        data.push(null);
                        infoModified.push(0);
                        imagePathList.push(null);
                    }
                    const images = {};
                    this.fileList.forEach(attachment => {
                        attachment.fileList.forEach((file, idx) => {
                            let meta = attachment.meta ? attachment.meta[idx] : {i: -1, c: null, m: 0};
                            const segments = file['fileName'].split(/\./g);
                            const type = segments.pop().toLowerCase();
                            const comment = segments.join('.');
                            if (supportedSuffixList.includes(type)) {
                                images[file['fileId']] = {
                                    attachmentRef: attachment,
                                    imageId: file['fileId'],
                                    comment: meta.c,
                                    dftComment: attachment['remark'] || comment,
                                    recordTime: attachment['recordTime'],
                                    idxInList: idx,
                                };
                                if (meta.i >= 0 && infoModified[meta.i] < meta.m) {
                                    data[meta.i] = (
                                        <span className={style['nav-item']} key={`file-${meta.i}`}>
                                            <MainToolbarNavImg
                                                className={style['nav-item-img']}
                                                readonly={this.props.readonly}
                                                images={images}
                                                networkRef={this.props.networkRef}
                                                onFullScreen={idx => this.setState({showLightBox: idx})}
                                                idx={meta.i}
                                                imageId={file['fileId']}
                                            />
                                        </span>
                                    );
                                    infoModified[meta.i] = meta.m;
                                    imagePathList[meta.i] = file['fileId'];
                                }
                            }
                        })
                    });
                    if (this.props.readonly) {
                        data = data.filter(ele => ele !== null);
                    } else {
                        data = data.map((ele, idx) => ele || (
                            <span className={style['nav-item']} key={`file-${idx}`}>
                                <MainToolbarNavImg
                                    readonly={this.props.readonly}
                                    images={images}
                                    idx={idx}
                                    onFullScreen={idx => this.setState({showLightBox: idx})}
                                    networkRef={this.props.networkRef}
                                />
                            </span>
                        ));
                    }
                    imagePathList = imagePathList.map(fileId =>
                        fileId ? `${REQUEST_BASE}/view/project/file/${fileId}?Authorization=${getToken()}` : null);
                    break;
                case NetworkFileListLoadingStatus.PROCESSING:
                    data = [
                        <span className={style['nav-item']} key='loading'
                              style={{'--item-height': 'var(--nav-height)'}}>
                            <div className={style['no-nav-item-title']}>
                                <span>正在计算……</span>
                            </div>
                        </span>,
                    ];
                    break;
                default:
                    data = [
                        <span className={style['nav-item']} key='nothing'
                              style={{'--item-height': 'var(--nav-height)'}}>
                            <div className={style['no-nav-item-title']}>
                                <span>没有图表</span>
                            </div>
                        </span>,
                    ];
            }
        }

        if (data.length === 0) {
            data = [
                <span className={style['nav-item']} key='nothing'>
                    <div className={style['no-nav-item-title']}>
                        <span>没有图表</span>
                    </div>
                </span>,
            ];
        }

        let lightBox = null;
        if (this.state.showLightBox >= 0) {
            const mainSrc = imagePathList[this.state.showLightBox] || null;
            if (mainSrc) {
                let nextSrcIdx = (this.state.showLightBox + 1) % imagePathList.length;
                let nextSrc = imagePathList[nextSrcIdx];
                while (!nextSrc) {
                    nextSrcIdx = (nextSrcIdx + 1) % imagePathList.length;
                    nextSrc = imagePathList[nextSrcIdx];
                    if (nextSrc === mainSrc) {
                        nextSrc = undefined;
                        break;
                    }
                }
                let prevSrcIdx = (imagePathList.length + this.state.showLightBox - 1) % imagePathList.length;
                let prevSrc = imagePathList[prevSrcIdx];
                while (!prevSrc) {
                    prevSrcIdx = (imagePathList.length + prevSrcIdx - 1) % imagePathList.length;
                    prevSrc = imagePathList[prevSrcIdx];
                    if (prevSrc === mainSrc) {
                        prevSrc = undefined;
                        break;
                    }
                }

                lightBox = (
                    <LightBox
                        mainSrc={mainSrc}
                        prevSrc={prevSrc}
                        nextSrc={nextSrc}
                        onCloseRequest={() => this.setState({showLightBox: -1})}
                        onMovePrevRequest={() => this.setState({showLightBox: prevSrcIdx})}
                        onMoveNextRequest={() => this.setState({showLightBox: nextSrcIdx})}
                    />
                );
            }
        }

        const navClassName = this.state.showChartNav ?
            (this.props.className || '') + ' ' + style["img-nav-inside"] + ' ' + style["img-nav-inside-pullout"] :
            (this.props.className || '') + ' ' + style["img-nav-inside"];
        return intl.get('locale')==='zh-cn'?(
            <div className={navClassName}>
                <Map
                    mode='nav'
                    dataset={flashingNode}
                    options={{regionFilterField: this.mapFilterField,regionClassifyField:this.mapClassifyField}}
                    className={style["map-nav"]}
                    link={() => me.onGetBriefingInfoList('map')}
                    // link={() => window.open('/explore/' + networkRef.getData().viewId + '/map', '_blank')}
                    // linkTip='地理探索'
                />
                <div
                  className={`${style["timeline-nav"]} dark-theme`}
                  onClick={() => me.onGetBriefingInfoList('timeline')}
                >
                    <div style={{width: '90%', margin: '0 auto', paddingTop: '0.8rem', paddingBottom: '0.15rem'}}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 163 65.66">
                            <g fill="currentColor" stroke="currentColor">
                                <line strokeWidth="1.5" y1="64.91" x2="163" y2="64.91"/>
                                <line strokeWidth="0.5" x1="134.5" y1="22.41" x2="134.5" y2="64.84"/>
                                <line strokeWidth="0.5" x1="83.5" y1="38.41" x2="83.5" y2="65.28"/>
                                <line strokeWidth="0.5" x1="71.5" y1="15.41" x2="71.5" y2="64.72"/>
                                <line strokeWidth="0.5" x1="27.5" y1="32.41" x2="27.5" y2="65.04"/>
                                <circle cx="83.5" cy="30.41" r="8"/>
                                <circle cx="71.5" cy="8.5" r="8"/>
                                <circle cx="27.5" cy="24.54" r="8"/>
                                <circle cx="134.5" cy="14.34" r="8"/>
                            </g>
                        </svg>
                    </div>
                    <div style={{textAlign: 'center', userSelect: 'none'}}>
                        <span style={{margin: '0 0.6rem'}}>前月</span>
                        <span style={{margin: '0 0.6rem'}}>上月</span>
                        <span style={{margin: '0 0.6rem'}}>本月</span>
                    </div>
                </div>
                <span className={style["chart-nav"]}>
                    <div className={style["toggle-nav-btn"]}>
                        <Tooltip placement="top" title="浏览上传插图">
                            <Icon
                              name="paper-clip" style={{fontSize:'1.8rem'}}
                              onClick={() => this.tgChartNav('user')}
                              className={this.state.showChartNav === 'user' ? style['toggle-nav-btn-active'] : null}
                            />
                        </Tooltip>
                    </div>

                    <div className={`${style["nav-item-frame"]} scrollbar scrollbar-18`}>
                        <div className={style["nav-item-container"]}>
                            {data}
                        </div>
                    </div>
                </span>
                {lightBox}
            </div>
        ):null
    }
}

MainToolbarNav.defaultProps = {
    readonly: true,
};

MainToolbarNav.propTypes = {
    networkRef: PropTypes.instanceOf(ViewDataProvider).isRequired,
    readonly: PropTypes.bool,
};

export default MainToolbarNav;
