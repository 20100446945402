import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {getToken, REQUEST_BASE} from "@/utils/HttpUtil";

import ViewDataProvider from '@/components/common/dataProvider/common.dataProvider.view';

import style from '@/style/components/main.nodeInfo.less';
import {NetworkFileListLoadingStatus} from "@/libs/view/network/status";
import PB, {SimplePB} from "@/libs/simplePB";

const supportedSuffixList = ['png', 'jpg', 'gif', 'jpeg'];

/**
 * 获取节点Tooltip内容
 *
 * @param {ViewDataProvider} viewDataProvider
 * @param {string} nodeId
 * @param {Function} [callback]
 *
 * @return {{
 *   success: boolean,
 *   [processing]: boolean,
 *   [content]: string,
 *   [images]: array,
 * }}
 */
export const getNodeTooltipContent = (viewDataProvider, nodeId, callback) => {
  if (!viewDataProvider || !(viewDataProvider instanceof ViewDataProvider) || !nodeId) {
    return {success: false};
  }
  const fileListStatus = viewDataProvider.fileListLoadingStatus;
  if (
    fileListStatus.status === NetworkFileListLoadingStatus.IDLE ||
    fileListStatus.status === NetworkFileListLoadingStatus.FAILED
  ) {
    viewDataProvider.loadFileList().then(() => {
      if (callback && _.isFunction(callback)) {
        callback(getNodeTooltipContent(viewDataProvider, nodeId));
      }
    }).catch(error => {
      if (callback && _.isFunction(callback)) {
        callback({...error, success: false});
      }
    });
  } else if (fileListStatus.status === NetworkFileListLoadingStatus.SUCCESS) {
    // 获取节点信息
    const nodeInfo = viewDataProvider.getNode(nodeId);
    if (!nodeInfo) {
      return {success: false};
    }
    let content = nodeInfo.description;
    // 获取图像信息
    const fileMap = viewDataProvider.fileByNode;
    if (!fileMap) return {success: false};
    const attachmentList = (fileMap[nodeId] || []).slice().reverse();
    let images = [];
    for (let i = 0; i < attachmentList.length; i++) {
      let attachment = attachmentList[i];
      if (attachment['from'] !== nodeId) continue; // 节点不匹配
      if (!attachment['fileList'] || !_.isArray(attachment['fileList'])) continue; // 无附件
      for (let j = 0; j < attachment['fileList'].length; j++) {
        let fileInfo = attachment['fileList'][j];
        const segments = fileInfo['fileName'].split(/\./g);
        const type = segments.pop().toLowerCase();
        if (supportedSuffixList.includes(type)) {
          // 找到图片
          images.push(fileInfo);
        }
      }
    }
    return {success: true, content, images};
  } // else processing
  return {success: false, processing: true};
};

class NodeInfoCommonTooltip extends React.Component {
  render() {
    let me = this;
    const lines = (me.props.content || '<暂无描述信息>').split('\n');

    return (me.props.content || (me.props.images && me.props.images.length > 0)) ? (
      <div className={`${style['node-tooltip-container']} dark-theme`} style={me.props.style}>
        <div className={`scrollbar ${style['node-tooltip-container-inner']}`}>
          {
            (me.props.images && me.props.images.length > 0) ? (
              <div
                className={style['node-tooltip-image-container']}
                onClick={() => {
                  me.props.bus.emit('image_light_box', 'show.do', {images: me.props.images, idx: 0});
                  me.props.bus.emit('relation', 'presentation.do_pause');
                }}
              >
                <img
                  src={`${REQUEST_BASE}/view/project/file/thumb/${Math.ceil(window.innerWidth / 3)}/0/${me.props.images[0]['fileId']}?Authorization=${getToken()}`}
                  alt={me.props.images[0]['fileName']}
                  hspace="10"
                />
              </div>
            ) : (
              <span>
                {
                  lines.map((line, idx) => (
                    <span key={`ln-${idx}`}>{line}{idx === lines.length - 1 ? null : (<br />)}</span>
                  ))
                }
              </span>
            )
          }
        </div>
      </div>
    ) : null;
  }
}

NodeInfoCommonTooltip.defaultProps = {
  bus: PB,
  style: {},
};

NodeInfoCommonTooltip.propTypes = {
  bus: PropTypes.instanceOf(SimplePB),
  style: PropTypes.object,
  content: PropTypes.string,
  images: PropTypes.array,
};

export default NodeInfoCommonTooltip;