import React from 'react';
// noinspection ES6CheckImport
import {renderToString} from 'react-dom/server';
import PropTypes from 'prop-types';
import ReactECharts from 'echarts-for-react';
import eCharts from 'echarts/lib/echarts';
import moment from "moment";

import 'echarts/lib/chart/scatter';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/legend';

import {getNodeDisplayTitle} from "@/constants/vis.defaultDefine.1";

class TeamworkMemberStatisticsChart extends React.Component {
  eChartsRef = undefined;

  getOption = me => ({
    tooltip: {
      formatter: params => {
        return renderToString(
          <span>
            {
              moment(params.value[4]).format('YYYY-MM-DD HH:mm:ss')
            }<br />
            <span
              style={{
                display: 'inline-block',
                marginRight: '0.5em',
                borderRadius: '1em',
                width: '1em',
                height: '1em',
                verticalAlign: '-0.11em',
                backgroundColor: params.color,
              }}
            />
            {getNodeDisplayTitle(params.value[5])}
          </span>
        );
      },
    },
    grid: {
      top: 20,
      right: 10,
      bottom: 30,
    },
    xAxis: {
      type: 'time',
      min: moment().hour(0).minute(0).second(0).millisecond(0)
        .subtract(7, 'd').valueOf(),
      max: moment().hour(0).minute(0).second(0).millisecond(0)
        .add(1, 'd').valueOf(),
      interval: 86400000,
      axisLabel: {
        formatter: value => {
          if (value === moment().hour(0).minute(0).second(0).millisecond(0)
            .subtract(7, 'd').valueOf()) {
            return '';
          } else {
            let contentMoment = moment(value).subtract(1, 'd');
            return contentMoment.format('MM-DD') + '\n' + contentMoment.format('ddd');
          }
        },
        padding: [0, 0, 0, this.eChartsRef ? -this.eChartsRef['echartsElement'].clientWidth * 0.1 : 0],
        color: '#a5a5a5',
				fontSize: 9,
      },
      axisLine:{
        lineStyle:{
          color:'#595959',
        },
      },
      splitLine: {//分割线配置
        lineStyle: {
          color: "#444",
        },
      },
    },
    yAxis: {
      type: 'value',
      min: 0,
      max: 60 * 24,
      interval: 4 * 60,
      axisLabel: {
        formatter: value => `${
          value === 60 * 24 ? '24:00' : moment().hour(Math.floor(value / 60)).minute(value % 60)
            .format('H:mm')
        }`,
        color: '#a5a5a5',
				fontSize: 9,
      },
      axisLine:{
        lineStyle:{
          color:'#595959',
        },
      },
      splitLine: {//分割线配置
        lineStyle: {
          color: "#595959",
        },
      },
    },
    series: [{
      name: '当前图谱成员',
      smooth: true,
      showSymbol: false,
      type: 'scatter',
      symbolSize: 8,
      data: (this.props.overallStatistics.points && this.props.overallStatistics.points.data) ?
        this.props.overallStatistics.points.data
          .map((value, idx) => ([value[0], value[1], 1, idx, value[4], value[2]])) : [],
      itemStyle: {
        color: '#7f7f7f',
      },
      areaStyle: {},
    }, {
      name: 'TA在当前图谱',
      smooth: true,
      showSymbol: false,
      type: 'scatter',
      symbolSize: 8,
      data: (this.props.memberStatistics.points && this.props.memberStatistics.points.data) ?
        this.props.memberStatistics.points.data
          .map((value, idx) => ([value[0], value[1], 1, idx, value[4], value[2]])) : [],
      itemStyle: {
        color: '#ed7d31',
      },
      areaStyle: {},
    } ],
  });

  render() {
    let me = this;

    return (
      <ReactECharts
        echarts={eCharts}
        option={me.getOption(me)}
        style={{height: '200px'}}
        ref={e => {
          me.eChartsRef = e;
          if (e) {
            requestAnimationFrame(() => {
              me.eChartsRef && me.eChartsRef['getEchartsInstance']().setOption(me.getOption(me));
            });
          }
        }}
      />
    );
  }
}

TeamworkMemberStatisticsChart.defaultProps = {
  title: '近7天节点统计',
  overallStatistics: {},
  memberStatistics: {},
};

TeamworkMemberStatisticsChart.propTypes = {
  title: PropTypes.string,
  overallStatistics: PropTypes.object,
  memberStatistics: PropTypes.object,
  memberInfo: PropTypes.object.isRequired,
};

export default TeamworkMemberStatisticsChart;