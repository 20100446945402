import * as diff from 'diff';
import _ from 'lodash';

export const DiffType = {
  DEFAULT : 0,
  ADDED :1,
  REMOVED : 2,
};

// See https://github.com/kpdecker/jsdiff/tree/v4.0.1#api for more info on the below JsDiff methods
export const DiffMethod = {
  CHARS : 'diffChars',
  WORDS : 'diffWords',
  WORDS_WITH_SPACE : 'diffWordsWithSpace',
  LINES : 'diffLines',
  TRIMMED_LINES : 'diffTrimmedLines',
  SENTENCES : 'diffSentences',
  CSS : 'diffCss',
}

/**
 * Splits diff text by new line and computes final list of diff lines based on
 * conditions.
 *
 * @param value Diff text from the js diff module.
 */
const constructLines = (value) => {
  const lines = value.split('\n');
  const isAllEmpty = lines.every((val) => !val);
  if (isAllEmpty) {
    // This is to avoid added an extra new line in the UI.
    if (lines.length === 2) {
      return [];
    }
    lines.pop();
    return lines;
  }

  const lastLine = lines[lines.length - 1];
  const firstLine = lines[0];
  // Remove the first and last element if they are new line character. This is
  // to avoid addition of extra new line in the UI.
  if (!lastLine) {
    lines.pop();
  }
  if (!firstLine) {
    lines.shift();
  }
  return lines;
};

/**
 * Computes word diff information in the line.
 * [TODO]: Consider adding options argument for JsDiff text block comparison
 *
 * @param oldValue Old word in the line.
 * @param newValue New word in the line.
 * @param compareMethod JsDiff text diff method from https://github.com/kpdecker/jsdiff/tree/v4.0.1#api
 */
const computeDiff = (
  oldValue,
  newValue,
  compareMethod = DiffMethod.CHARS,
) => {
  const diffArray = diff[compareMethod](oldValue, newValue);
  const computedDiff = {
    left: [],
    right: [],
  };
  diffArray
    .forEach(({ added, removed, value }) => {
      const diffInformation = {};
      if (added) {
        diffInformation.type = DiffType.ADDED;
        diffInformation.value = value;
        computedDiff.right.push(diffInformation);
      }
      if (removed) {
        diffInformation.type = DiffType.REMOVED;
        diffInformation.value = value;
        computedDiff.left.push(diffInformation);
      }
      if (!removed && !added) {
        diffInformation.type = DiffType.DEFAULT;
        diffInformation.value = value;
        computedDiff.right.push(diffInformation);
        computedDiff.left.push(diffInformation);
      }
      return diffInformation;
    });
  return computedDiff;
};

const myDiffLines = (oldString, newString) => {
  let textDiff = diff.diffChars(oldString, newString), mergedDiffArr = [],
    tmpDiffArr = [], previousLineEnd = true, pos, restStr, sameLines,
    leftLines, rightLines, lineAmountDiff, lastLine, lastDiff, result = [];

  for (let i = 0; i < textDiff.length;) {
    if (!textDiff[i].added && !textDiff[i].removed) {
      mergedDiffArr.push(textDiff[i]);
      i++;
    } else if (textDiff[i].removed) {
      if (i < textDiff.length - 1 && textDiff[i + 1].added) {
        mergedDiffArr.push([textDiff[i], textDiff[i + 1]]);
        i += 2;
      } else {
        mergedDiffArr.push([textDiff[i], undefined]);
        i++;
      }
    } else {
      mergedDiffArr.push([undefined, textDiff[i]]);
      i++;
    }
  }

  mergedDiffArr.forEach(d => {
    if (_.isArray(d)) {
      if(!previousLineEnd) {
        lastDiff = tmpDiffArr.pop();
        d[0] = d[0] || {count: 0, added: undefined, removed: true, value: ''};
        d[1] = d[1] || {count: 0, added: true, removed: undefined, value: ''};
        if (_.isArray(lastDiff)) {
          d[0].count += (lastDiff[0] ? lastDiff[0].count : 0);
          d[0].value = (lastDiff[0] ? lastDiff[0].value : '') + d[0].value;
          d[1].count += (lastDiff[1] ? lastDiff[1].count : 0);
          d[1].value = (lastDiff[1] ? lastDiff[1].value : '') + d[1].value;
        } else {
          d[0].count += lastDiff.count;
          d[0].value = lastDiff.value + d[0].value;
          d[1].count += lastDiff.count;
          d[1].value = lastDiff.value + d[1].value;
        }
      }
      leftLines = d[0] ? d[0].value.split('\n') : [undefined];
      rightLines = d[1] ? d[1].value.split('\n') : [undefined];
      if (leftLines[leftLines.length - 1] === '') {
        leftLines[leftLines.length - 1] = undefined;
      }
      if (rightLines[rightLines.length - 1] === '') {
        rightLines[rightLines.length - 1] = undefined;
      }
      [leftLines, rightLines].forEach(lines => {
        lines.forEach((l, idx) => {
          if (l !== undefined && (idx < lines.length - 1)) {
            lines[idx] += '\n';
          }
        });
      });
      lineAmountDiff = leftLines.length - rightLines.length;
      if (lineAmountDiff > 0) {
        lastLine = rightLines.pop();
        rightLines = rightLines.concat(Array(lineAmountDiff));
        rightLines.push(lastLine);
      } else if (lineAmountDiff < 0) {
        lastLine = leftLines.pop();
        leftLines = leftLines.concat(Array(-lineAmountDiff));
        leftLines.push(lastLine);
      }
      for (let i = 0; i < leftLines.length; i++) {
        tmpDiffArr.push([
          leftLines[i] === undefined ? undefined : {...d[0], count: leftLines[i].length, value: leftLines[i]},
          rightLines[i] === undefined ? undefined : {...d[1], count: rightLines[i].length, value: rightLines[i]},
        ]);
      }
      lastDiff = tmpDiffArr[tmpDiffArr.length - 1];
      if (lastDiff[0] === undefined && lastDiff[1] === undefined) {
        previousLineEnd = true;
        tmpDiffArr.pop();
      } else {
        previousLineEnd = false;
      }
    } else {
      if (previousLineEnd) {
        if (!d.value.includes('\n')) {
          tmpDiffArr.push(d);
          previousLineEnd = false;
        } else if (d.value.endsWith('\n')) {
          tmpDiffArr.push(d);
          previousLineEnd = true;
        } else {
          pos = d.value.lastIndexOf('\n');
          tmpDiffArr.push({
            count: pos + 1,
            value: d.value.substring(0, pos + 1),
          });
          tmpDiffArr.push({
            count: d.value.length - pos - 1,
            value: d.value.substr(pos + 1),
          });
          previousLineEnd = false;
        }
      } else {
        lastDiff = tmpDiffArr.pop();
        if (!d.value.includes('\n')) {
          lastDiff[0] = lastDiff[0] || {count: 0, added: undefined, removed: true, value: ''};
          lastDiff[1] = lastDiff[1] || {count: 0, added: true, removed: undefined, value: ''};
          lastDiff[0].count += d.count;
          lastDiff[0].value = lastDiff[0].value + d.value;
          lastDiff[1].count += d.count;
          lastDiff[1].value = lastDiff[1].value + d.value;
          tmpDiffArr.push(lastDiff);
        } else {
          sameLines = [];
          pos = d.value.indexOf('\n');
          sameLines.push(d.value.substring(0, pos + 1));
          if (pos + 1 < d.value.length) {
            restStr = d.value.substring(pos + 1);
            pos = restStr.lastIndexOf('\n');
            if (pos >= 0 && pos < restStr.length - 1) {
              sameLines.push(restStr.substring(0, pos + 1));
              sameLines.push(restStr.substring(pos + 1));
              previousLineEnd = false;
            } else {
              sameLines.push(restStr);
              previousLineEnd = (pos === restStr.length - 1);
            }
          } else {
            previousLineEnd = true;
          }
          sameLines.forEach((l, idx) => {
            if (idx === 0) {
              lastDiff[0] = lastDiff[0] || {count: 0, added: undefined, removed: true, value: ''};
              lastDiff[1] = lastDiff[1] || {count: 0, added: true, removed: undefined, value: ''};
              lastDiff[0].count += sameLines[idx].length;
              lastDiff[0].value = lastDiff[0].value + sameLines[idx];
              lastDiff[1].count += sameLines[idx].length;
              lastDiff[1].value = lastDiff[1].value + sameLines[idx];
              tmpDiffArr.push(lastDiff);
            } else {
              tmpDiffArr.push({
                count: l.length,
                value: l,
              });
            }
          });
        }
      }
    }
  });

  tmpDiffArr.forEach(d => {
    if (_.isArray(d)) {
      if (d[0]) result.push(d[0]);
      if (d[1]) result.push(d[1]);
    } else {
      result.push(d);
    }
  })

  return result;
};

/**
 * [TODO]: Think about moving common left and right value assignment to a
 * common place. Better readability?
 *
 * Computes line wise information based in the js diff information passed. Each
 * line contains information about left and right section. Left side denotes
 * deletion and right side denotes addition.
 *
 * @param oldString Old string to compare.
 * @param newString New string to compare with old string.
 * @param disableWordDiff Flag to enable/disable word diff.
 * @param compareMethod JsDiff text diff method from https://github.com/kpdecker/jsdiff/tree/v4.0.1#api
 */
const computeLineInformation = (
  oldString,
  newString,
  disableWordDiff = false,
  compareMethod = DiffMethod.CHARS,
) => {
  const diffArray = myDiffLines(
    oldString,
    newString,
  );
  let rightLineNumber = 0;
  let leftLineNumber = 0;
  let lineInformation = [];
  let counter = 0;
  const diffLines = [];
  const ignoreDiffIndexes = [];
  const getLineInformation = (
    value,
    diffIndex,
    added,
    removed,
    evaluateOnlyFirstLine,
  ) => {
    const lines = constructLines(value);

    return lines.map((line, lineIndex) => {
      const left = {};
      const right = {};
      if (ignoreDiffIndexes.includes(`${diffIndex}-${lineIndex}`)
        || (evaluateOnlyFirstLine && lineIndex !== 0)) {
        return undefined;
      }
      if (added || removed) {
        if (!diffLines.includes(counter)) {
          diffLines.push(counter);
        }
        if (removed) {
          leftLineNumber += 1;
          left.lineNumber = leftLineNumber;
          left.type = DiffType.REMOVED;
          left.value = line || ' ';
          // When the current line is of type REMOVED, check the next item in
          // the diff array whether it is of type ADDED. If true, the current
          // diff will be marked as both REMOVED and ADDED. Meaning, the
          // current line is a modification.
          const nextDiff = diffArray[diffIndex + 1];
          if (nextDiff && nextDiff.added) {
            const nextDiffLines = constructLines(nextDiff.value)[lineIndex];
            if (nextDiffLines) {
              const {
                value: rightValue,
                lineNumber,
                type,
              } = getLineInformation(nextDiff.value, diffIndex, true, false, true)[0].right;
              // When identified as modification, push the next diff to ignore
              // list as the next value will be added in this line computation as
              // right and left values.
              ignoreDiffIndexes.push(`${diffIndex + 1}-${lineIndex}`);
              right.lineNumber = lineNumber;
              right.type = type;
              // Do word level diff and assign the corresponding values to the
              // left and right diff information object.
              if (disableWordDiff) {
                right.value = rightValue;
              } else {
                const computedDiff = computeDiff(line, rightValue, compareMethod);
                right.value = computedDiff.right;
                left.value = computedDiff.left;
              }
            }
          }
        } else {
          rightLineNumber += 1;
          right.lineNumber = rightLineNumber;
          right.type = DiffType.ADDED;
          right.value = line;
        }
      } else {
        leftLineNumber += 1;
        rightLineNumber += 1;

        left.lineNumber = leftLineNumber;
        left.type = DiffType.DEFAULT;
        left.value = line;
        right.lineNumber = rightLineNumber;
        right.type = DiffType.DEFAULT;
        right.value = line;
      }

      counter += 1;
      return { right, left };
    }).filter(Boolean);
  };

  diffArray
    .forEach(({ added, removed, value }, index) => {
      lineInformation = [
        ...lineInformation,
        ...getLineInformation(value, index, added, removed),
      ];
    });

  return {
    lineInformation, diffLines,
  };
};

export { computeLineInformation };