export const statisticsByType = ({nodes, edges, sort, cb}) => {
  let statisticsFn = (edges, cb) => {
    // statistics
    let result = ['直线连接', '曲线连接', '隐藏连接'].map(
      label => ({label, rate: 0, nodes: [], nodeMap: {}})
    ), pos = 0, batch, allNodeMap = {}, workFn = () => {
      batch = 0;
      while (batch < 1000 && pos < edges.length) {
        let e = edges[pos];
        if (allNodeMap[e.from] && allNodeMap[e.to]) {
          let idx = 1;
          if (e._aiGenerated || e._userMarkedInvisible || !e.visible) {
            idx = 2;
          } else if (e.meta && e.meta['smooth'] === 0) {
            idx = 0;
          }
          result[idx].rate++;
          if (!result[idx].nodeMap[e.from]) {
            result[idx].nodes.push(allNodeMap[e.from]);
            result[idx].nodeMap[e.from] = true;
          }
          if (!result[idx].nodeMap[e.to]) {
            result[idx].nodes.push(allNodeMap[e.to]);
            result[idx].nodeMap[e.to] = true;
          }
        }
        batch++;
        pos++;
      }
      if (pos < edges.length) {
        setTimeout(workFn, 5);
      } else {
        cb(result);
      }
    };
    nodes.forEach(n => allNodeMap[n.id] = n);
    workFn();
  };

  statisticsFn(edges, (result) => {
    let resultArray;

    // sort
    if (sort) {
      let asc = false;
      if (`${sort}`.toLowerCase() === 'asc') {
        asc = true;
      }
      resultArray = Object.values(result).sort((a, b) => {
        return asc ? a.nodes.length - b.nodes.length : b.nodes.length - a.nodes.length;
      }).map(((value, index) => ({...value, pos: index + 1})));
    } else {
      resultArray = Object.values(result).map(((value, index) => ({...value, pos: index + 1})));
    }

    cb(resultArray);
  });
};