/**
 * 看板信息卡片 样式 1
 * 用于搜索结果中的看板信息卡片
 */
import React from 'react';
import PropTypes from 'prop-types';
import {Modal, Tooltip} from 'antd';
import moment from 'moment';
import Icon from '@/components/common/common.icon';
import {IconTypes} from '@/constants/common';
import View from '@/components/common/common.viewManager';
import styles from '@/style/components/common/objects/common.viewInfoCard.style.1.less';
import TimeDisplayField from '@/components/common/common.timeDisplayField';
import {API_GetViewById} from '@/libs/view/network/api.js';
import UserNickAvatar from "@/components/common/objects/common.userAvatar";


export default class ViewInfoCard extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      // 封面
      coverData: undefined,
      // 用于展示的 view info
      currentViewInfo: props.view,
    };
  }
  
  /**
   * 新建tab页打开看板
   * @param viewId
   */
  openView = viewId => {
    if (this.props.hasLogin) {
      if (this.props.readOnly === false) {
        window.open(`/mainview/relation/${viewId}`, '_blank');
      } else {
        // 原本设计用于没有登录用户或者允许浏览的用户，用浏览模式打开看板，此处忽略
        // ignore
      }
    } else {
      Modal.info({
        title: '登录提醒',
        icon: <Icon name={'user'} style={{color: '#a5a5a5'}}/>,
        content: '您还没有登录，注册或登录后再尝试打开图谱！',
        okText: '知道了',
        onOk() {
          // ignore
        },
      });
    }
  };
  
  /**
   * 对大于1000的数字,进行显示优化
   * 大于 1   千小于100百万，例如2000：2K+
   * 大于 100 百万小于1亿，例如2百万：2M+
   * 大于 1亿，例如2亿：2B+
   *
   * @param data
   * @returns {string}
   */
  optimizeDigit = data => {
    let viewData = data.toString();
    let length = viewData.length;
    
    if (length >= 4 && length < 7) {
      viewData = `${viewData.slice(0, length - 3)}K+`;
    } else if (length >= 7 && length < 9) {
      viewData = `${viewData.slice(0, length - 6)}M+`;
    } else if (length >= 9) {
      viewData = `${viewData.slice(0, length - 8)}B+`;
    }
    return viewData;
  };
  
  /**
   * 加载看板封面
   * @param view
   */
  loadViewCover = (view) => {
    let me = this;
    if (view['hasCover'] === 1) {
      requestAnimationFrame(() => {
        if (me.props.hasLogin) {
          View.loadViewCover(view.viewId).then(coverData => {
            if (coverData) {
              me.setState({coverData});
            }
          }).catch(() => {
            // ignore
          });
        } else {
          View.loadViewPublicCover(view.viewId).then(coverData => {
            if (coverData) {
              me.setState({coverData});
            }
          }).catch(() => {
            // ignore
          });
        }
      });
    }
  }
  
  componentDidMount() {
    let me = this;
    // 判断是否要读取看板信息
    const {view, viewId} = this.props;
    if (!view) {
      if (viewId) {
        API_GetViewById(viewId).then(response => {
          let res = response.data;
          if (res.hasOwnProperty('code') && res.code === 0) {
            me.setState({
              currentViewInfo: res.data
            }, () => {
              me.loadViewCover(me.state.currentViewInfo);
            })
          } else {
            // 获取看板信息出错
            me.setState({
              currentViewInfo: false
            });
          }
        }).catch(e => {
          // ignore
        });
      } else {
        // 没有看板信息
        me.setState({
          currentViewInfo: false
        });
      }
    } else {
      // 直接请求封面数据
      me.loadViewCover(view);
    }
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.view !== this.props.view) {
      // console.log(`viewInfo updated: ${this.props.view.viewId}: `, this.props.view);
      this.setState({currentViewInfo: this.props.view});
    }
  }
  
  render() {
    const {className, style, callback, coverWidth, showUpdateTime, showLastNodeUpdate} = this.props;
    let {currentViewInfo: view, coverData} = this.state;
    if (view === false) {
      return <div className={style['view-card-wrap']}>加载出错</div>
    }
    if (!view) {
      return <div className={style['view-card-wrap']}>加载中</div>
    }
    if (view['viewItem']) {
      view = {...view, ...view['viewItem']};
    }
    // 对大于1000的数字进行显示优化
    // let viewCount = view['viewCount'] > 1000 ? this.optimizeDigit(view['viewCount']) : view['viewCount'];
    // let nodeCount = view['nodeCount'] > 1000 ? this.optimizeDigit(view['nodeCount']) : view['nodeCount'];
    
    // 样式和封面宽度
    let _style = {};
    if (style) {
      _style = {..._style, style};
    }
    if (coverWidth) {
      _style['--cover-width'] = coverWidth;
    }
    // noinspection HtmlUnknownTarget
    return (
      <div className={`${className} ${styles['outer']}`} style={_style}>
        <div className={styles['cover']}>
          {
            coverData ? (
              <img src={coverData} alt={'图谱封面'}/>
            ) : <img src={'/assets/view-default-cover.jpg'} alt={'图谱封面'}/>
          }
        </div>
        <div className={styles['content']}>
          <div className={styles['name']} onClick={() => this.openView(view.viewId)}>{view.name}</div>
          <div className={styles['meta']}>
            <UserNickAvatar className={styles['user']}
                            user={{
                              nick: view.nick,
                              picId: view.picId || null
                            }}
                            avatarSize='1rem'
            />
            {
              showUpdateTime && view.updateTime ? (
                <div className={styles['item']}>
                  <div className={styles['icon-box']} style={{paddingRight: '5px'}}>
                    <Icon name="icon-Raidobox-o" type={IconTypes.ICON_FONT}/>
                  </div>
                  <TimeDisplayField description={'更新时间'}
                                    timestamp={moment(view.updateTime, 'YYYY-MM-DD HH:mm:ss').valueOf()}
                  />
                </div>
              ) : null
            }
            {
              showLastNodeUpdate && view['lastNodeUpdate'] ? (
                <div className={styles['item']}>
                  <div className={styles['icon-box']} style={{paddingRight: '5px'}}>
                    <Icon name="icon-Raidobox-o" type={IconTypes.ICON_FONT}/>
                  </div>
                  <TimeDisplayField description={'更新时间'}
                                    timestamp={moment(view['lastNodeUpdate'], 'YYYY-MM-DD HH:mm:ss').valueOf()}
                  />
                </div>
              ) : null
            }
          </div>
        </div>
      </div>
    );
  }
}

ViewInfoCard.defaultProps = {
  readOnly: true,
  // showCornerTip: false,
  callback: () => {
  },
  hasLogin: true,
  coverWidth: undefined,
  showUpdateTime: true,
  showLastNodeUpdate: false,
};

ViewInfoCard.propTypes = {
  readOnly: PropTypes.bool, // 是否是只读，创建人才能修改等操作
  // showCornerTip: PropTypes.bool, // 是否判断显示角标提示
  view: PropTypes.object,
  viewId: PropTypes.string, // 优先使用 view 内的数据，当view不存在时，用viewId读取view信息
  callback: PropTypes.func, // 图谱操作的回调，TODO: 待实现，点击的自定义操作等
  hasLogin: PropTypes.bool, // 是否已经登录
  coverWidth: PropTypes.string, // 封面图的自定义宽度
  showUpdateTime: PropTypes.bool, // 是否显示更新时间
  showLastNodeUpdate: PropTypes.bool, // 是否显示最后节点更新时间
};
