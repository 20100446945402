import React from 'react';
import PropTypes from 'prop-types';

import DurationNoticePanel from '@/components/common/common.durationNoticePanel';
import style from '@/style/components/common.authForm.less';

class EmailSentNoticePanel extends React.Component {
  render () {
    return (
      <DurationNoticePanel onBack={this.props.onBack}
                           btnText={'返回登录'}
                           redirectAt={this.props.redirectAt}>
        <h1 className={style['form-title']}>邮件已发送</h1>
        <div className={style['form-item']} style={{marginBottom: '3em'}}>
          邮件已发送至：{this.props.email}，请前往查收邮件，若收件箱未有新邮件提示，请注意查看垃圾邮件。<br />
          系统将在5秒后自动跳转至登录页面。
        </div>
      </DurationNoticePanel>
    )
  }
}

EmailSentNoticePanel.defaultProps = {
  email: '',
  redirectAt: 0,
  onBack: () => {console.log('onBack property is not set.')},
};

EmailSentNoticePanel.propTypes = {
  email: PropTypes.string,
  redirectAt: PropTypes.number,
  onBack: PropTypes.func,
};

export default EmailSentNoticePanel;
