import React from "react";
import PropTypes from 'prop-types';
import {Modal, Popover, Form, Input, Button, Radio} from "antd";

import {getNodeDisplayTitle, getNodeIcon} from "@/constants/vis.defaultDefine.1";

import ViewDataProvider from "@/components/common/dataProvider/common.dataProvider.view";
import Icon from "@/components/common/common.icon";

import style from "@/style/common/view/common.view.explore.less";
import {showErrorMessage} from "@/components/common/common.message";

import {Element as ScrollElement} from 'react-scroll';

import relationCluePng101 from '@/assets/clue/101.png';
import relationCluePng102 from '@/assets/clue/102.png';
import relationCluePng103 from '@/assets/clue/103.png';
import relationCluePng104 from '@/assets/clue/104.png';
import relationCluePng201 from '@/assets/clue/201.png';
import relationCluePng202 from '@/assets/clue/202.png';
import relationCluePng203 from '@/assets/clue/203.png';
import relationCluePng301 from '@/assets/clue/301.png';
import relationCluePng302 from '@/assets/clue/302.png';
import relationCluePng303 from '@/assets/clue/303.png';
import relationCluePng304 from '@/assets/clue/304.png';
import relationCluePng401 from '@/assets/clue/401.png';
import relationCluePng402 from '@/assets/clue/402.png';
import relationCluePng403 from '@/assets/clue/403.png';
import relationCluePng501 from '@/assets/clue/501.png';
import relationCluePng502 from '@/assets/clue/502.png';
import relationCluePng503 from '@/assets/clue/503.png';
import relationCluePng504 from '@/assets/clue/504.png';

const { TextArea } = Input;

class ExploreSmartRelationshipListsModal extends React.Component {

  state = {
    containerId: `explore-company-overall-modal-${Math.random()}`,
    relationsVal_0: '',
    relationsVal_1: '',
    relationsVal_2: '',
    relationsVal_3: '',
    relationsVal_4: ''
  }

  relationsVal = [,,,,];
  relationsSort = [];

  afterModalClose = () => {
    this.relationsVal = [,,,,];
    relationsSort = [];
  };


  onChoose = () => {
    this.props.onChoose(this.relationsVal.filter((item) => item!=''));
    this.props.onClose();
  };

  valToRelations = (key,val) =>{
    this.relationsSort.push(key);
    if(this.relationsSort.length>3){
      let _index = this.relationsSort[0];
      this.relationsVal[_index] = '';
      this.relationsSort.splice(0,1);
    }
    if(val==this.relationsVal[key]){
      this.relationsVal[key]='';
    }else{
      this.relationsVal[key]=val;
    }
    this.setState({
      relationsVal_0: this.relationsVal[0],
      relationsVal_1: this.relationsVal[1],
      relationsVal_2: this.relationsVal[2],
      relationsVal_3: this.relationsVal[3],
      relationsVal_4: this.relationsVal[4]
    })
  }

  onChange = e => {
    let val = e.target.value;
    let key = Math.floor(val/100)-1;
    this.valToRelations(key,val);
  };

  getDerivedStateFromProps(props, state) {
    
  }

  componentWillMount() {
    let me = this;
    me.props.relations.forEach(val => {
      let key = Math.floor(val/100)-1;
      me.relationsVal[key]=val;
    })
  }

  render() {
    let me = this;
    let modalTitle = (<span>选择关系<span style={{color:'rgba(0,0,0,.45)'}}>(请从下列5组关系中选择一项或者多项)</span></span>);
    return (
      <Modal
        title={modalTitle}
        visible={me.props.visible}
        onCancel={me.props.onClose}
        afterClose={me.afterModalClose}
        width={`calc(40rem + 48px)`}
        height={`calc(55rem)`}
        centered={true}
        cancelText={'取消'}
        okText={'确定选择'}
        onOk={me.onChoose}
        className={`${style['relationshiplist-box']}`}
      >
        <ScrollElement
          id={me.state.containerId}
          className={`scrollbar-none scrollbar`}
          style={{overflow: 'hidden auto', height: '37rem'}}
          onScroll={e => {
            let element = e.target;
            me.autoScroll = element.scrollHeight - element.scrollTop - element.clientHeight < 10;
          }}
        >
        <Radio.Group onChange={me.onChange} defaultValue={me.relationsVal[0]} value={me.state.relationsVal_0}>
          <span className={style['relation-svg-title']}>概念范畴</span>
          <Radio.Button value="101" className={style['relation-svg-box']}>
            <div><img src={relationCluePng101} /></div>
            <div>完全相同</div>
          </Radio.Button>
          <Radio.Button value="102" className={style['relation-svg-box']}>
            <div><img src={relationCluePng102} /></div>
            <div>大致相似</div>
          </Radio.Button>
          <Radio.Button value="103" className={style['relation-svg-box']}>
            <div><img src={relationCluePng103} /></div>
            <div>总分包含</div>
          </Radio.Button>
          <Radio.Button value="104" className={style['relation-svg-box']}>
            <div><img src={relationCluePng104} /></div>
            <div>非此即彼</div>
          </Radio.Button>
        </Radio.Group>
        <div style={{borderBottom:'1px dashed #013780'}}></div>
        <Radio.Group onChange={me.onChange} defaultValue={me.relationsVal[1]} value={me.state.relationsVal_1}>
          <span className={style['relation-svg-title']}>时间事件</span>
          <Radio.Button value="201" className={`${style['relation-svg-box']} ${style['relation-svg-big-box']}`}>
            <div><img src={relationCluePng201}/></div>
            <div>历史未来</div>
          </Radio.Button>
          <Radio.Button value="202" className={`${style['relation-svg-box']} ${style['relation-svg-big-box']}`}>
            <div><img src={relationCluePng202}/></div>
            <div>紧接连续</div>
          </Radio.Button>
          <Radio.Button value="203" className={style['relation-svg-box']}>
          <div><img src={relationCluePng203} /></div>
          <div>同时发生</div>
          </Radio.Button>
        </Radio.Group>
        <div style={{borderBottom:'1px dashed #013780'}}></div>
        <Radio.Group onChange={me.onChange} defaultValue={me.relationsVal[2]} value={me.state.relationsVal_2}>
        <span className={style['relation-svg-title']}>空间事件</span>
          <Radio.Button value="301" className={`${style['relation-svg-box']} ${style['relation-svg-big-box']}`}>
          <div><img src={relationCluePng301}/></div>
          <div>东西左右</div>
          </Radio.Button>
          <Radio.Button value="302" className={style['relation-svg-box']}>
          <div><img src={relationCluePng302} /></div>
          <div>南北上下</div>
          </Radio.Button>
          <Radio.Button value="303" className={style['relation-svg-box']}>
          <div><img src={relationCluePng303} /></div>
          <div>内外包含</div>
          </Radio.Button>
          <Radio.Button value="304" className={style['relation-svg-box']}>
          <div><img src={relationCluePng304} /></div>
          <div>相同地点</div>
          </Radio.Button>
        </Radio.Group>
        <div style={{borderBottom:'1px dashed #013780'}}></div>
        <Radio.Group onChange={me.onChange} defaultValue={me.relationsVal[3]} value={me.state.relationsVal_3}>
          <span className={style['relation-svg-title']}>因果关系</span>
          <Radio.Button value="401" className={`${style['relation-svg-box']} ${style['relation-svg-big-box']}`}>
          <Popover content="因为雨下得太大了,好多同学迟到了">
            <div><img src={relationCluePng401} /></div>
            <div>因导致果</div>
            <Icon name={"info-circle"} className={style['relation-svg-info']}/>
            </Popover>
          </Radio.Button>
          <Radio.Button value="402" className={`${style['relation-svg-box']} ${style['relation-svg-big-box']}`}>
          <Popover content={(<div><div>虽然我去过那里,但是我还是对那里不了解</div><div>他是这样为大家着想,有的人却在背后说人家的风凉话</div></div>)}>
            <div><img src={relationCluePng402} /></div>
            <div>转折对比</div>
            <Icon name={"info-circle"} className={style['relation-svg-info']}/>
            </Popover>
          </Radio.Button>
          <Radio.Button value="403" className={`${style['relation-svg-box']} ${style['relation-svg-big-box']}`}>
          <Popover content={(<div><div>只要我去过那里,我一定能够了解那里的情况</div><div>如果没有群众帮助，就不可能完成任务</div></div>)}>
            <div><img src={relationCluePng403} /></div>
            <div>条件假设</div>
            <Icon name={"info-circle"} className={style['relation-svg-info']}/>
            </Popover>
          </Radio.Button>
        </Radio.Group>
        <div style={{borderBottom:'1px dashed #013780'}}></div>
        <Radio.Group onChange={me.onChange} defaultValue={me.relationsVal[4]} value={me.state.relationsVal_4}>
        <span className={style['relation-svg-title']}>非因果关系</span>
          <Radio.Button value="501" className={`${style['relation-svg-box']} ${style['relation-svg-big-box']}`}>
          <Popover content="他是三好学生,也是优秀少先队员">
            <div><img src={relationCluePng501} /></div>
            <div>并列称述</div>
            <Icon name={"info-circle"} className={style['relation-svg-info']}/>
            </Popover>
          </Radio.Button>
          <Radio.Button value="502" className={style['relation-svg-box']} style={{verticalAlign:'top'}}>
          <Popover content="与其去长城，还不如去故宫">
            <div><img src={relationCluePng502} /></div>
            <div>多项选一</div>
            <Icon name={"info-circle"} className={style['relation-svg-info']}/>
            </Popover>
          </Radio.Button>
          <Radio.Button value="503" className={`${style['relation-svg-box']} ${style['relation-svg-big-box']}`}>
          <Popover content="他不仅爱好书法,而且爱好文学">
            <div><img src={relationCluePng503} /></div>
            <div>程度递进</div>
            <Icon name={"info-circle"} className={style['relation-svg-info']}/>
            </Popover>
          </Radio.Button>
          <Radio.Button value="504" className={`${style['relation-svg-box']} ${style['relation-svg-big-box']}`}>
          <Popover content="他来到了教室,接着写起来了作业">
            <div><img src={relationCluePng504} /></div>
            <div>连续发生</div>
            <Icon name={"info-circle"} className={style['relation-svg-info']}/>
            </Popover>
          </Radio.Button>
        </Radio.Group>
        </ScrollElement>
      </Modal>
    );
  }
}

ExploreSmartRelationshipListsModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onChoose: PropTypes.func.isRequired,
  relations: PropTypes.array.isRequired
};

export default ExploreSmartRelationshipListsModal;