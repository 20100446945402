import React from 'react';
import PropTypes from 'prop-types';

import PB, {SimplePB} from '@/libs/simplePB';

class RelationComponentConnector extends React.PureComponent {
  currentPresentationId = undefined;

  presentationStarted = false;

  componentDidMount() {
    let me = this;

    me.props.bus.with(
      me
    ).subscribe('relation', 'node_filter.updated', ({viewId, filterKey, filterName, filteredNodeIds}) => {
      if (me.props.viewId && me.props.viewId !== viewId) return;
      me.props.bus.emit('relation', 'presentation.filtered_nodes.set',
        {viewId, filterKey, filterName, nodeIds: filteredNodeIds});
    }).subscribe('presentation', 'config.play.do', ({viewId, config}) => {
      if (me.props.viewId && me.props.viewId !== viewId) return;
      me.currentPresentationId = config['presentationId'];
      me.props.bus.emit('relation', 'presentation.custom_nodes.set',
        {viewId, title: config['title'], nodeIds: config['content']['nodeIds']});
      me.props.bus.emit('relation', 'node_filter.story_nodes.set',
        {viewId, title: config['title'], storyInfo: config, description: config['description'], nodeIds: config['content']['contentList']?config['content']['contentList']:config['content']['nodeIds']});
      me.props.bus.emit('relation', 'node_filter.filter.set',
        {viewId: config['viewId'], filterKey: 'story'});
      let remarks = {presentationId:config['presentationId'],username:config['username'],updateTimestamp:config['updateTimestamp'],
        nodeNum:config['content']&&config['content']['nodeIds']?config['content']['nodeIds'].length:0,contentList:config['content']&&config['content']['contentList']?config['content']['contentList'].length:0,
        userPicId:config['userPicId'],iconData:config['iconData']};
      me.props.bus.emit('relation', 'presentation.do_start', {config: config['content'], title: config['title'], description: config['description'], remarks:remarks, noWait: true});
    }).subscribe('presentation', 'config.updated', ({presentationId, config}) => {
      if (me.currentPresentationId !== presentationId) return;
      me.props.bus.emit('relation', 'presentation.custom_nodes.set',
        {viewId: config['viewId'], title: config['title'], nodeIds: config['content']['nodeIds']});
      me.props.bus.emit('relation', 'node_filter.story_nodes.set',
        {viewId: config['viewId'], title: config['title'], storyInfo: config, nodeIds: config['content']['contentList']?config['content']['contentList']:config['content']['nodeIds']});
    }).subscribe('presentation', 'config.removed', ({presentationId, config}) => {
      if (me.currentPresentationId !== presentationId) return;
      me.props.bus.emit('relation', 'presentation.custom_nodes.set', {viewId: config['viewId']});
      me.props.bus.emit('relation', 'node_filter.story_nodes.set', {viewId: config['viewId']});
    }).subscribe('relation', 'presentation.started', ({viewId}) => {
      if (me.props.viewId && me.props.viewId !== viewId) return;
      me.presentationStarted = true;
    }).subscribe('relation', 'presentation.stopped', ({viewId}) => {
      if (me.props.viewId && me.props.viewId !== viewId) return;
      me.presentationStarted = false;
    });
  }

  componentWillUnmount() {
    this.props.bus.remove(this);
  }

  render() {
    return null;
  }
}

RelationComponentConnector.defaultProps = {
  bus: PB,
};

RelationComponentConnector.propTypes = {
  bus: PropTypes.instanceOf(SimplePB),
  viewId: PropTypes.string,
};

export default RelationComponentConnector;