import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {message} from 'antd';
import copy from 'copy-to-clipboard';

import PB, {SimplePB} from '@/libs/simplePB';
import Node from '@/libs/view/Node';

import ViewDataProvider from '@/components/common/dataProvider/common.dataProvider.view';
import {
  DEFAULT_COPY_TEXT_KEY,
  MicroServiceUIConfig,
} from '@/components/common/view/microService/shape/common.view.microService.shape.uiConfig';

/**
 * @description 展示词云微服务数据项
 * @typedef {Object} TMicroServiceDataOpSaveFiles
 * @property {Object} resultTargetMap - 目标对象ID到数据对应关系
 * @property {Object} resultTargetToTreeNode - 目标对象ID到树节点对应关系
 * @property {Array} dataTree - 树形列表节点数据
 * @property {Object} dataTreeMap - 树形列表节点ID到数据对应关系
 * @property {Object.<string, string[]>} treeNodeUidToIdMap - 实际数据节点ID到树节点ID的对应关系
 * @property {boolean} currentHasData - 当前处理的返回值是否有新结果
 * @property {number} availableNodeAmount - 当前可勾选的对象数量
 * @property {string[]} checkedKeys - 当前勾选的对象ID
 * @property {string[]} expandedKeys - 当前展开的对象ID
 * @property {string[]} objectIds - 对象ID列表
 * @property {Object.<string, TMicroServiceResponseObjectStatus>} objectStatus - 对象状态
 * @property {TMicroServiceUIConfigOperationSaveFiles} operationConfig - 操作详细配置
 */

/**
 * @description 初始化服务数据
 * @param {TMicroServiceUIConfigOperationSaveFiles} operationConfig
 * @return {TMicroServiceDataOpSaveFiles}
 */
const getInitialServiceData = operationConfig => ({
  resultTargetMap: {},
  resultTargetToTreeNode: {},
  dataTree: [],
  dataTreeMap: {},
  treeNodeUidToIdMap: {},
  currentHasData: false,

  availableNodeAmount: 0,
  checkedKeys: [],
  expandedKeys: [],

  objectIds: [],
  objectStatus: {},
  operationConfig,
});

const OPERATION = 'saveFiles';

class MicroServiceLogicOperationSaveFiles extends React.PureComponent {
  /**
   * @type {Object.<string, TMicroServiceDataOpSaveFiles>}
   */
  serviceDataMap = {};

  isCheckableTreeNode = () => {
    return true;
  };

  /**
   * 配置文件加载完毕后，检查是否需要初始化数据
   *
   * @param {string} projectId - 项目ID
   * @param {TMicroServiceUIConfig[]} configList - 微服务配置列表
   */
  onConfigListLoaded = (projectId, configList) => {
    let me = this;

    (configList || []).forEach(config => {
      config.operations.find(op => {
        if (op.type === OPERATION) {
          me.serviceDataMap[config.id] = getInitialServiceData(op);
          return true;
        }
        return false;
      });
    });
  };

  /**
   * 复制选中节点的文本内容至剪切板
   *
   * @param {string} serviceId - 微服务ID
   */
  onCopySelectedNodesToClipboard = (serviceId) => {
    let me = this, textList = [], serviceData = me.serviceDataMap[serviceId];

    if (serviceData) {
      // noinspection JSValidateTypes
      _.intersection(Object.keys(serviceData.dataTreeMap), serviceData.checkedKeys).forEach(k => {
        if (serviceData.dataTreeMap[k] && serviceData.dataTreeMap[k].copyText) {
          textList.push(serviceData.dataTreeMap[k].copyText);
        }
      });

      let result = copy(textList.join("\r\n"), {
        message: '请按下 #{key} 复制选中节点文本。',
      });

      if (result) message.success('选中节点文本已复制到剪切板。');
    }
  };

  /**
   * 原始结果加载完毕后，解析并存储数据
   *
   * @param {TMicroServiceResponseDataItem[]} data - 原始数据列表
   * @param {string} serviceId - 微服务ID
   */
  onRawDataLoaded = (data, serviceId) => {
    let me = this, newKeys = me.parseResult(serviceId, data), serviceData;

    serviceData = me.serviceDataMap[serviceId];
    serviceData.treeNodeUidToIdMap = {};
    Object.values(serviceData.dataTreeMap).forEach(node => {
      if (!serviceData.treeNodeUidToIdMap[node.uid]) {
        serviceData.treeNodeUidToIdMap[node.uid] = [];
      }
      serviceData.treeNodeUidToIdMap[node.uid].push(node.id);
    });

    me.props.bus.emit('view', 'micro_service.service.broadcast_operation_result',
      {viewId: me.props.viewDataProvider.viewId, serviceId, operation: OPERATION});
    if (newKeys && newKeys.length > 0) {
      me.serviceDataMap[serviceId].availableNodeAmount = Object.values(me.serviceDataMap[serviceId].dataTreeMap)
        .filter(treeNode => me.isCheckableTreeNode(treeNode)).length;

      serviceData.expandedKeys = [...serviceData.expandedKeys, ...newKeys];

      me.props.bus.emit('view', 'micro_service.service.broadcast_operation_statistics',
        {viewId: me.props.viewDataProvider.viewId, serviceId, operation: OPERATION});
      me.props.bus.emit('view', 'micro_service.service.broadcast_operation_ui',
        {viewId: me.props.viewDataProvider.viewId, serviceId, operation: OPERATION});
    }
  };

  /**
   * 微服务主控数据清理后，清理业务数据
   *
   * @param {string} serviceId - 微服务ID
   */
  onResultCleared = serviceId => {
    let me = this, serviceData = me.serviceDataMap[serviceId];

    if (me.props.viewDataProvider) {
      serviceData.resultTargetMap = {};
      serviceData.resultTargetToTreeNode = {};
      serviceData.dataTree = [];
      serviceData.dataTreeMap = {};
      serviceData.treeNodeUidToIdMap = {};
      serviceData.currentHasData = false;

      serviceData.availableNodeAmount = 0;
      serviceData.checkedKeys =  [];
      serviceData.expandedKeys = [];

      serviceData.objectIds = [];
      serviceData.objectStatus = {};
    }
  };

  /**
   * 树节点勾选回调
   *
   * @param {string} serviceId - 微服务ID
   * @param {string[]} checkedKeys - 勾选的节点ID列表
   */
  onTreeNodeItemCheck = (serviceId, checkedKeys) => {
    let me = this, serviceData = me.serviceDataMap[serviceId], tmpObjectStatus = {}, updatedObjectStatus = {},
      checkedKeyMap = {}, objectStatus = serviceData.objectStatus, hasUpdatedObjectStatus = false;

    if (serviceData) {
      checkedKeys.forEach(k => {
        let treeNode = serviceData.dataTreeMap[k];

        if (!treeNode) return;

        checkedKeyMap[k] = true;
        if (serviceData.treeNodeUidToIdMap[treeNode.uid].length > 1) {
          serviceData.treeNodeUidToIdMap[treeNode.uid].forEach(id => checkedKeyMap[id] = true);
        }

        [...treeNode.path].reverse().find(k => {
          let pN = serviceData.dataTreeMap[k];
          if (!updatedObjectStatus[pN.uid]) {
            if (objectStatus[pN.uid]) {
              if (objectStatus[pN.uid].status === 'idle') {
                objectStatus[pN.uid].status = "selected";
                updatedObjectStatus[pN.uid] = {
                  type: objectStatus[pN.uid].type,
                  status: 'selected',
                };
                tmpObjectStatus[pN.uid] = 'selected';
                hasUpdatedObjectStatus = true;
              } else if (objectStatus[pN.uid].status === 'selected') {
                tmpObjectStatus[pN.uid] = 'selected';
              }
            }
          }
          return pN && pN.action;
        });
      });

      serviceData.objectIds.forEach(id => {
        if (!tmpObjectStatus[id] && objectStatus[id].status === 'selected') {
          objectStatus[id].status = 'idle';
          updatedObjectStatus[id] = {
            type: objectStatus[id].type,
            status: 'idle',
          };
          hasUpdatedObjectStatus = true;
        }
      });

      me.serviceDataMap[serviceId].objectStatus = objectStatus;
      me.serviceDataMap[serviceId].checkedKeys = Object.keys(checkedKeyMap);

      if (hasUpdatedObjectStatus) {
        me.props.bus.emit('view', 'micro_service.service.operation_statistics_updated',
          {viewId: me.props.viewDataProvider.viewId, serviceId, operation: OPERATION, updatedObjectStatus});
      }

      me.props.bus.emit('view', 'micro_service.service.broadcast_operation_ui',
        {viewId: me.props.viewDataProvider.viewId, serviceId, operation: OPERATION});
    }
  };

  /**
   * 树节点展开回调
   *
   * @param {string} serviceId - 微服务ID
   * @param {string[]} expandedKeys - 展开的节点ID列表
   */
  onTreeNodeItemExpand = (serviceId, expandedKeys = []) => {
    let me = this, serviceData = me.serviceDataMap[serviceId];

    if (serviceData) {
      if (serviceData.expandedKeys.length !== expandedKeys.length
        || _.difference(serviceData.expandedKeys, expandedKeys).length > 0) {

        me.serviceDataMap[serviceId].expandedKeys = expandedKeys;
        me.props.bus.emit('view', 'micro_service.service.broadcast_operation_ui',
          {viewId: me.props.viewDataProvider.viewId, serviceId, operation: OPERATION});
      }
    }
  };

  /**
   * 解析微服务返回的数据
   *
   * @param {string} serviceId - 微服务ID
   * @param {TMicroServiceResponseDataItem[]} dataList - 服务器返回的数据列表
   *
   * @return {string[]} 新增树节点的ID列表
   */
  parseResult = (serviceId, dataList) => {
    let me = this,
      serviceData = me.serviceDataMap[serviceId],
      currentHasData = false,
      config = serviceData.operationConfig,
      resultTargetMap = serviceData.resultTargetMap,
      resultTargetToTreeNode = serviceData.resultTargetToTreeNode,
      dataTree = serviceData.dataTree,
      dataTreeMap = serviceData.dataTreeMap,
      objectIds = serviceData.objectIds,
      objectStatus = serviceData.objectStatus,
      oldKeys = Object.keys(serviceData.dataTreeMap),
      dataMsg;

    let copyPath = config.copyTextKey === false ? false : DEFAULT_COPY_TEXT_KEY;

    dataList.forEach(dataItem => {
      if ((dataItem.calcStatus && dataItem.calcStatus == 1)) {
        dataMsg = {
          'msgStatus' : dataItem.calcStatus,
          'msg' : dataItem.calcMsg
        }
      }
      if ((dataItem.msgStatus && dataItem.msgStatus == 1)) {
        dataMsg = {
          'msgStatus' : dataItem.msgStatus,
          'msg' : dataItem.msg
        }
      }
      if (dataItem.files && dataItem.files.length > 0) {
        let itemRootNode = resultTargetMap[dataItem.id], treeNode,
          treeNodeId = `${serviceId}-root-${dataItem.id}`;

        if (!itemRootNode) {
          if (![undefined, null, 'node', 'view'].includes(dataItem.type)) {
            // 暂不支持
            return;
          }
          treeNode = {
            id: treeNodeId,
            uid: dataItem.id,
            renderAs: dataItem.type,
            path: [treeNodeId],
            action: 'show',
          };

          switch (dataItem.type) {
            case 'view':
              // 针对看板生成，仅限当前看板
              if (dataItem.id !== me.props.viewDataProvider.viewId) {
                return;
              }
              itemRootNode = {...me.props.viewDataProvider.viewInfo};
              treeNode.viewId = dataItem.id;
              break;
            default:
              itemRootNode = me.props.viewDataProvider.getNode(dataItem.id);
              treeNode.nodeId = dataItem.id;
              treeNode.renderAs = 'node';
              if (!itemRootNode) {
                // 看板中不存在对应节点
                let nodeId = `${serviceId}-n-${dataItem.id}`;
                dataItem.info.id = nodeId;
                itemRootNode = new Node(dataItem.info);
                treeNode.uid = nodeId;
                treeNode.nodeId = nodeId;
              }
          }
          resultTargetMap[treeNode.uid] = itemRootNode;
          resultTargetToTreeNode[treeNode.uid] = treeNode;
          treeNode.copyText = (copyPath ? _.get(itemRootNode, copyPath) : '');
          dataTreeMap[treeNodeId] = treeNode;
          dataTree.push(treeNode);
          if (!objectStatus[treeNode.uid]) {
            objectIds.push(treeNode.uid);
            objectStatus[treeNode.uid] = {
              type: treeNode.action || 'none',
              status: 'idle',
            };
          }
        }

        treeNode = dataTreeMap[treeNodeId];

        // 处理数据
        treeNode.children = treeNode.children || [];
        dataItem.files.forEach(file => {
          let subTreeNode,
            rndId = Math.random(),
            subTreeNodeId = `${serviceId}-t-${dataItem.id}-${rndId}`,
            fileId = file.id;

          while (resultTargetMap[fileId]) {
            rndId = Math.random();
            subTreeNodeId = `${serviceId}-t-${dataItem.id}-${rndId}`;
          }

          subTreeNode = {
            id: subTreeNodeId,
            uid: fileId,
            fileId,
            renderAs: 'file_info',
            path: [treeNodeId, subTreeNodeId],
            action: 'show',
            copyText: file.name,
          };

          resultTargetMap[fileId] = {...file};

          treeNode.children.push(subTreeNode);
          dataTreeMap[subTreeNodeId] = subTreeNode;
          if (!objectStatus[subTreeNode.uid]) {
            objectIds.push(subTreeNode.uid);
            objectStatus[subTreeNode.uid] = {
              type: subTreeNode.action || 'none',
              status: 'idle',
            };
          }

          currentHasData = true;
        });
      }
    });

    me.serviceDataMap[serviceId].resultTargetMap = resultTargetMap;
    me.serviceDataMap[serviceId].resultTargetToTreeNode = resultTargetToTreeNode;
    me.serviceDataMap[serviceId].dataTree = dataTree;
    me.serviceDataMap[serviceId].dataTreeMap = dataTreeMap;
    me.serviceDataMap[serviceId].currentHasData = currentHasData;
    me.serviceDataMap[serviceId].objectIds = objectIds;
    me.serviceDataMap[serviceId].objectStatus = objectStatus;
    me.serviceDataMap[serviceId].dataMsg = dataMsg;

    return _.difference(Object.keys(dataTreeMap), oldKeys);
  };

  componentDidMount() {
    let me = this;

    me.props.bus.with(
      me
    ).subscribe('view', 'micro_service.config.data_loaded', ({projectId, configList}) => {
      me.onConfigListLoaded(projectId, configList);
    }).subscribe('view', 'micro_service.service.raw_data_loaded', ({data, viewId, serviceId}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId && me.serviceDataMap[serviceId]) {
        me.onRawDataLoaded(data, serviceId);
      }
    }).subscribe('view', 'micro_service.service.result_cleared', ({viewId, serviceId}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId && me.serviceDataMap[serviceId]) {
        me.onResultCleared(serviceId);
      }
    }).subscribe('view', 'micro_service.service.operation_save_files.copy_selected_node_to_clipboard', ({viewId, serviceId}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId && me.serviceDataMap[serviceId]) {
        me.onCopySelectedNodesToClipboard(serviceId);
      }
    }).subscribe('view', 'micro_service.service.operation_save_files.tree_node_item_check', ({viewId, serviceId, checkedKeys}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId && me.serviceDataMap[serviceId]) {
        me.onTreeNodeItemCheck(serviceId, checkedKeys);
      }
    }).subscribe('view', 'micro_service.service.operation_save_files.tree_node_item_expand', ({viewId, serviceId, expandedKeys}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId && me.serviceDataMap[serviceId]) {
        me.onTreeNodeItemExpand(serviceId, expandedKeys);
      }
    }).subscribe('view', 'micro_service.service.broadcast_operation_result', ({viewId, serviceId, operation}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId && me.serviceDataMap[serviceId]
        && operation === OPERATION) {

        me.props.bus.emit('view', 'micro_service.service.current_operation_result', {
          viewId,
          serviceId,
          operation: OPERATION,
          resultTargetMap: {...me.serviceDataMap[serviceId].resultTargetMap},
          dataTree: [...me.serviceDataMap[serviceId].dataTree],
          dataTreeMap: {...me.serviceDataMap[serviceId].dataTreeMap},
          treeNodeUidToIdMap: {...me.serviceDataMap[serviceId].treeNodeUidToIdMap},
          currentHasData: me.serviceDataMap[serviceId].currentHasData,
          dataMsg:me.serviceDataMap[serviceId].dataMsg
        });
      }
    }).subscribe('view', 'micro_service.service.broadcast_operation_ui', ({viewId, serviceId, operation}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId && me.serviceDataMap[serviceId]
        && operation === OPERATION) {

        me.props.bus.emit('view', 'micro_service.service.current_operation_ui', {
          viewId,
          serviceId,
          operation: OPERATION,

          availableNodeAmount: me.serviceDataMap[serviceId].availableNodeAmount,
          checkedKeys: [...me.serviceDataMap[serviceId].checkedKeys],
          expandedKeys: [...me.serviceDataMap[serviceId].expandedKeys],
        });
      }
    }).subscribe('view', 'micro_service.service.broadcast_operation_statistics', ({viewId, serviceId, operation}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId && me.serviceDataMap[serviceId]
        && operation === OPERATION) {

        me.props.bus.emit('view', 'micro_service.service.current_operation_statistics', {
          viewId,
          serviceId,
          operation: OPERATION,
          objectIds: [...me.serviceDataMap[serviceId].objectIds],
          objectStatus: {...me.serviceDataMap[serviceId].objectStatus},
        });
      }
    });
  }

  componentWillUnmount() {
    this.props.bus.remove(this);
  }

  render() {
    return null;
  }
}

MicroServiceLogicOperationSaveFiles.defaultProps = {
  bus: PB,
};

MicroServiceLogicOperationSaveFiles.propTypes = {
  viewDataProvider: PropTypes.instanceOf(ViewDataProvider).isRequired,
  microServiceConfigMap: PropTypes.objectOf(PropTypes.shape(MicroServiceUIConfig)).isRequired,
  bus: PropTypes.instanceOf(SimplePB),
};

export default MicroServiceLogicOperationSaveFiles;