import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {message,Tooltip, Drawer, Button, Checkbox} from 'antd';
import PB, {SimplePB} from '@/libs/simplePB';
import {IconTypes} from '@/constants/common';
import Icon from '@/components/common/common.icon';
import ViewDataProvider from "@/components/common/dataProvider/common.dataProvider.view";
import RelationDataProvider from '@/components/common/dataProvider/common.dataProvider.relation';
import {API_analysisIncidentDetails} from "@/api/relation";

import style from '@/style/common/node/common.node.explore.analysis.incident.less';
import moment from "moment";

class AnalysisIncidentList extends React.PureComponent {

  state = {
    status: 'idle',
    msg:'',
    bus_data: {keyword:''},
    dataList:[],
    showDrawer: false,
    showDrawerDetail: false,
    nodeListSelectionMap: {},
    nodeListSelectedAmount: 0,
    nodeListAllSelectedIndeterminate: false,
    nodeListAllSelected: false,
    nodeListYYYYMM: {},
    incidentDetail: {},
    APIPage: 0
  };

  pageNo = 0;

  onNodeListSelectAllChanged = e => {
    let me = this, nodeListAllSelected = e.target.checked, nodeListSelectionMap = {}, nodeListSelectedAmount = 0;
    if (nodeListAllSelected) {
      Object.keys(me.state.nodeListSelectionMap).forEach(k => nodeListSelectionMap[k] = true);
      nodeListSelectedAmount = Object.keys(me.state.nodeListSelectionMap).length;
    }else{
      Object.keys(me.state.nodeListSelectionMap).forEach(k => nodeListSelectionMap[k] = false);
    }
    me.setState({
      nodeListAllSelected,
      nodeListAllSelectedIndeterminate: false,
      nodeListSelectionMap,
      nodeListSelectedAmount,
    });
  };

  onNodeListSelectionChanged = (nodeId, e) => {
    let me = this, selected = e.target.checked,
      nodeListSelectedAmount = me.state.nodeListSelectedAmount,
      nodeListSelectionMap = {...me.state.nodeListSelectionMap};

    if (selected) {
      nodeListSelectedAmount++;
      nodeListSelectionMap[nodeId] = true;
    } else {
      nodeListSelectedAmount--;
      nodeListSelectionMap[nodeId] = false;
    }

    me.setState({
      nodeListAllSelected:  Object.keys(me.state.nodeListSelectionMap).length === nodeListSelectedAmount,
      nodeListAllSelectedIndeterminate: nodeListSelectedAmount > 0 &&  Object.keys(me.state.nodeListSelectionMap).length > nodeListSelectedAmount,
      nodeListSelectionMap: nodeListSelectionMap,
      nodeListSelectedAmount: nodeListSelectedAmount,
    });
  };

  onDataRefreshed = () => {
    let me = this,nodeListSelectionMap={},nodeListYYYYMM={};
    /*message.loading({
      content: '正在计算中，本次计算预计用时3分钟，请耐心等待。计算成功后，生成的事件图谱24小时后会自动删除，如想保留可改动图谱名称。',
      key,
      duration: 0,
    });*/
    if(me.state.bus_data && me.state.bus_data.keyword===''){
      return ;
    }
    let params ={
      begin_ym:(moment().format('YYYYMM')-100-100*me.pageNo), 
      end_ym:moment().format('YYYYMM')-100*me.pageNo, 
      ety_type:'any', 
      ety_name:me.state.bus_data.keyword, 
      limit:30
    }
    RelationDataProvider.analysisIncidentList(params).then((data) => {
      if(data && data.length>0){
        let _data = [];
        data.forEach((item, idx) => {
          _data.push(item.id + '|' + moment(new Date(item.publish)).format("YYYYMM")) ;
          nodeListSelectionMap[item.id] = false;
          nodeListYYYYMM[item.id] = moment(new Date(item.publish)).format("YYYYMM");
        });
        let APIPage = 0;
        if(params.begin_ym<=201912){
          APIPage = 1;
        }
        me.setState({
          status: 'success',
          dataList: data,
          APIPage,
          nodeListAllSelected: false,nodeListAllSelectedIndeterminate: false,nodeListSelectionMap,nodeListSelectedAmount:0,nodeListYYYYMM
        });
        me.getDetail(_data.join(','));
      }else{
        if(params.begin_ym<=201912){
          me.setState({
            status: 'success',
            dataList: [],
            msg:'未获取到数据',
            APIPage:1,
            nodeListAllSelected: false,nodeListAllSelectedIndeterminate: false,nodeListSelectionMap,nodeListSelectedAmount:0,nodeListYYYYMM
          });
        }else{
          me.pageNo++;
          me.onDataRefreshed();
        }
      }
    }).catch(({code, msg}) => {
      me.setState({
        status: 'failed',
        dataList: [],
        msg:msg,
        nodeListAllSelected: false,nodeListAllSelectegetDetaildIndeterminate: false,nodeListSelectionMap,nodeListSelectedAmount:0,nodeListYYYYMM
      });
    });
  }

  onDataFromSearcher = () => {
    let me = this,nodeListSelectionMap={};
    if(me.state.bus_data && me.state.bus_data.keyword===''){
      return ;
    }
    let params ={
      ety_name:me.state.bus_data.keyword, 
      limit:30
    }
    RelationDataProvider.analysisIncidentFromSearcher(params).then((data) => {
      if(data && data.length>0){
        let _data = [];
        data.forEach((item, idx) => {
          nodeListSelectionMap[item.id] = false;
        });
        me.setState({
          status: 'success',
          dataList: data,
          APIPage: 2,
          nodeListAllSelected: false,nodeListAllSelectedIndeterminate: false,nodeListSelectionMap,nodeListSelectedAmount:0
        });
      }else{
        me.setState({
          status: 'success',
          dataList: [],
          APIPage: 2,
          msg:'未获取到数据',
          nodeListAllSelected: false,nodeListAllSelectedIndeterminate: false,nodeListSelectionMap,nodeListSelectedAmount:0
        });
      }
    }).catch(({code, msg}) => {
      me.setState({
        status: 'failed',
        dataList: [],
        msg:msg,
        nodeListAllSelected: false,nodeListAllSelectegetDetaildIndeterminate: false,nodeListSelectionMap,nodeListSelectedAmount:0
      });
    });
  }

  getDetail = (ids) => {
    let me = this;
    let params ={
      ids:ids
    }
    API_analysisIncidentDetails(params).then(response => {
      if (response && response.data && response.data.code === 0) {
        let _data = [];
        response.data.data.forEach((item, idx) => {
          //item.ath_arr = item.ath ? item.ath.split("|||").split("###"):[];
          item.kws_arr = item.kws && [...new Set(item.kws.split("|||"))] || [];
          item.abs_arr = item.abs && [...new Set(item.abs.split("|||"))] || [];
          item.com_arr = item.com && [...new Set(item.com.split("|||"))] || [];
          item.rnd_arr = item.rnd && [...new Set(item.rnd.split("|||"))] || [];
          item.who_arr = item.who && [...new Set(item.who.split("|||"))] || [];
          item.amt_arr = item.amt && [...new Set(item.amt.split("|||"))] || [];
          _data.push(item);
        });
        me.setState({
          dataList: _data,
        });
      }
    }).catch(error => {
      console.warn(`获取信息失败`,error);
    });
  };

  doShowDetail = (item) => {
    this.setState({
      showDrawerDetail: true,
      incidentDetail: item
    });
  }

  onCloseDetail = () => {
    this.setState({
      showDrawerDetail: false,
      incidentDetail: {}
    });
  }

  onClose = () => {
    this.setState({
      showDrawer: false,
      status: 'idle',
      dataList: []
    });
  }

  onSave = (e) => {
    e.stopPropagation();
    let me = this;
    /*
    let nodeIds = Object.keys(me.state.nodeListSelectionMap).filter(id => me.state.nodeListSelectionMap[id]);
    if(nodeIds.length>0){
      let _data = [];
      nodeIds.forEach((id, idx) => {
        _data.push(id + '|' + me.state.nodeListYYYYMM[id]);
      });
    }*/
    let SelectionDataList = me.state.dataList.filter(item => me.state.nodeListSelectionMap[item.id]);
    me.doAdd(SelectionDataList);
  }

  doAdd = (_data) => {
    let me = this,addNodeList = [];
    _data.forEach((item, idx) => {
      let node = {
        index: idx,
        fname: item.title,
        description: item.abs_arr && item.abs_arr.join('\n'),
        tag: item.kws_arr && item.kws_arr.join(' '),
        tags: item.kws_arr && item.kws_arr,
        fx: 0,
        fy: 0,
        fixed: false,
        type: 0,
        userConfirmed: true
      }
      addNodeList.push(node);
    });

    this.props.bus.emit('network', 'addNode', {
      autoParse: 'expand-star',
      addNodeList: addNodeList,
      successCB: () => {
        try {
          message.success('操作已完成')
          this.props.bus.emit('relation', 'find_node_by_text.start', {text: ''})
          me.onClose();
        } catch (e) {
          // ignore
        }
      },
      failedCB: () => {
        
      },
      interruptedCB: () => {
        message.warn('操作已终止')
      }
    });
  };

  gotoIncidentList  = (keyword) => {
    let me = this;
    me.onCloseDetail();
    me.props.bus.emit('analysis', 'incident.list.show',{viewId: me.props.viewId,keyword:keyword});
  };

  loadMore  = () => {
    let me = this;
    if(me.state.APIPage===1){
      me.pageNo=0;
      //me.onDataFromSearcher();
    }else{
      me.pageNo++;
      me.onDataRefreshed();
    }
  };
  
  componentDidMount() {
    let me = this;

    me.props.bus.with(me).subscribe('analysis', 'incident.list.show', data => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === data.viewId && me.props.visible) {
        me.pageNo = 0;
        me.setState({
          showDrawer: true,
          status: 'idle',
          bus_data: data
        }, () => {
          me.onDataRefreshed();
        });
      }
    })
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
  }

  componentWillUnmount() {
    let me = this;
    me.pageNo = 0;
  }

  render() {
    let me = this;
    return (
      <React.Fragment>
        {
          me.state.showDrawer ? (
            <Drawer
              visible={me.props.visible}
              placement={'right'}
              destroyOnClose={true}
              mask={true}
              maskClosable={true}
              maskStyle={{backgroundColor: 'transparent'}}
              width={'46rem'}
              className={`${style['market-drawer-wrap']}`}
              onClose={me.onClose}
              style={{zIndex:1001,opacity:1}}
            >
              <div style={{ height: '100%',backgroundColor:'#fff',opacity:1}}>
                <div className={style['market-drawer-header']} style={{borderBottom:0}}>
                  <div className={style['market-drawer-title']} style={{flex:'auto'}}>{me.state.bus_data.keyword + '的相关事件'}</div>
                  <div className={style['market-drawer-right']}>
                    
                  </div>
                </div>
                <div className={style['market-drawer-body']}>
                  <div className={`${style['wizard-content']} scrollbar scrollbar-none `} style={{height:'auto',maxHeight:'98%'}}>
                    {me.state.status === 'success' &&
                    <ul className={style['list-content-li-box']}>
                     {me.state.dataList && me.state.dataList.length>0 ?(
                      me.state.dataList.map((item, index) => (
                        <li onClick={() => me.doShowDetail(item)}>
                          <div className={style['title']}>
                            <Checkbox style={{marginRight: '0.7rem',float:'left',fontSize:'1.1rem'}}
                              onClick={e => e.stopPropagation()}
                              onChange={e => me.onNodeListSelectionChanged(item.id, e)}
                              checked={me.state.nodeListSelectionMap[item.id] === true}/>
                            <span className={style['node-fname']}>{item.title}</span>
                          </div>
                          <div className={style['kws']}>
                            {item.kws_arr && item.kws_arr.slice(0,7).map((kws) => { return kws ?(<span>{kws}</span>):null})}</div>
                          <div className={style['from-font']}>
                            <span>{moment(new Date(item.publish)).format("YYYY-MM-DD")}</span>
                            <span className={style['url-font']} onClick={e => {e.stopPropagation();window.open(`${item.url}`, '_blank');}}>{item.url}</span>
                          </div>
                        </li>)
                      )) : (
                        <div className={style['market-drawer-body-tab-content-empty']}>
                          <Icon name={'exclamation-circle'} theme={'outlined'} /><br />
                          <span>没有找到相关信息</span>
                        </div>
                      )}
                      {false && me.state.APIPage<2 && <li className={style['load-more']}>
                        <div onClick={() => me.loadMore()}>
                          <span>搜索更多数据</span>
                        </div>
                      </li>}
                    </ul>}
                    {me.state.status === 'failed'  && 
                      <div className={style['market-drawer-body-tab-content-loading']}>
                        <Icon name={'exclamation-circle'} theme={'outlined'} /><br />
                        <span>数据准备中，请稍后<a onClick={() => me.onDataRefreshed()}>&lt;重试&gt;</a></span>
                      </div>
                    }
                    {me.state.status !== 'success' && me.state.status !== 'failed'  && 
                      <div className={style['market-drawer-body-tab-content-loading']}>
                        <Icon name={'loading'} theme={'outlined'} /><br />
                        数据加载中，请稍后…
                      </div>
                    }  
                  </div>
                </div>
                <div className={style['action-box']}>
                  <div className={style['action-right']}>
                    <Button type={'primary'} onClick={me.onSave} disabled={!me.state.nodeListSelectedAmount} block={true}>添加到图谱</Button>
                  </div>
                </div>
              </div>
            </Drawer>
          ) : null
        }
        {me.state.showDrawer && me.state.showDrawerDetail ? (<Drawer
              visible={true}
              placement={'right'}
              destroyOnClose={true}
              mask={true}
              maskClosable={true}
              maskStyle={{backgroundColor: 'transparent'}}
              width={'46rem'}
              className={`${style['market-drawer-wrap']}`}
              onClose={() => me.onCloseDetail()}
              style={{zIndex:1003,opacity:1}}
            >
              <div style={{ height: '100%',backgroundColor:'#fff',opacity:1}}>
                <div className={style['market-drawer-header']} style={{borderBottom:0}}>
                  <div className={style['market-drawer-title']} style={{flex:'auto'}}>{'详情'}</div>
                  <div className={style['market-drawer-right']}>
                    
                  </div>
                </div>
                <div className={`${style['market-drawer-body']}`}>
                  <div className={`${style['wizard-content']} scrollbar scrollbar-none `} style={{height:'auto',maxHeight:'98%'}}>
                    <ul className={style['list-content-li-box-tetail']}>
                        <li>
                          <div className={style['title']}>
                            <span className={style['node-dot']}> ○ </span>
                            <span className={style['node-fname']}>{me.state.incidentDetail.title}</span>
                          </div>
                          <div className={style['kws']}>{me.state.incidentDetail.kws_arr && me.state.incidentDetail.kws_arr.map((kws) => { return kws ?(<span onClick={e => {e.stopPropagation();me.gotoIncidentList(kws);}} className={kws===me.state.bus_data.keyword?style['active']:''}>{kws}</span>):null})}</div>
                          {me.state.incidentDetail.amt_arr && me.state.incidentDetail.amt_arr.length>0 && <div className={style['mark-box']}>
                            <div className={style['mark-name']}><Icon type={IconTypes.ICON_FONT} name={'icon-database'} className={style['mark-img']}/>数值：</div>
                            {me.state.incidentDetail.amt_arr.map((kws) => { return kws && kws.split("###") && kws.split("###")[3]  ?(<div className={style['data-txt']}>
                            <span className={style['data-span']}>{kws.split("###")[0]}{kws.split("###")[2]}</span>----<span>{kws.split("###")[3]}</span></div>):null})}
                          </div>}
                          {me.state.incidentDetail.org_arr && me.state.incidentDetail.org_arr.length>0 && <div className={style['mark-box']}>
                            <div className={style['mark-name']}><img src={`/assets/icon/gov.svg`} className={style['mark-img']}/>机构：</div>
                            <div className={style['txt']}>{me.state.incidentDetail.org_arr.map((kws) => { return kws ?(<span onClick={e => {e.stopPropagation();me.gotoIncidentList(kws);}} className={kws===me.state.bus_data.keyword?style['active']:''}>{kws}</span>):null})}</div>
                          </div>}
                          {me.state.incidentDetail.rnd_arr && me.state.incidentDetail.rnd_arr.length>0 && <div className={style['mark-box']}>
                            <div className={style['mark-name']}><img src={`/assets/icon/institute.svg`} className={style['mark-img']}/>院所：</div>
                            <div className={style['txt']} style={{marginBottom:'.6rem'}}>{me.state.incidentDetail.rnd_arr.map((kws) => { return kws ?(<span onClick={e => {e.stopPropagation();me.gotoIncidentList(kws);}} className={kws===me.state.bus_data.keyword?style['active']:''}>{kws}</span>):null})}</div>
                          </div>}
                          {me.state.incidentDetail.com_arr && me.state.incidentDetail.com_arr.length>0 && <div className={style['mark-box']}>
                            <div className={style['mark-name']}><img src={`/assets/icon/company.svg`} className={style['mark-img']}/>企业：</div>
                            <div className={style['txt']}>{me.state.incidentDetail.com_arr.map((kws) => { return kws ?(<span onClick={e => {e.stopPropagation();me.gotoIncidentList(kws);}} className={kws===me.state.bus_data.keyword?style['active']:''}>{kws}</span>):null})}</div>
                          </div>}
                          {me.state.incidentDetail.who_arr && me.state.incidentDetail.who_arr.length>0 && <div className={style['mark-box']}>
                            <div className={style['mark-name']}><img src={`/assets/icon/talent.svg`} className={style['mark-img']}/>人物：</div>
                            <div className={style['txt']}>{me.state.incidentDetail.who_arr.map((kws) => { return kws ?(<span onClick={e => {e.stopPropagation();me.gotoIncidentList(kws.split("###") && kws.split("###")[0] || me.state.incidentDetail.title);}} className={kws.replace('###',' ')===me.state.bus_data.keyword?style['active']:''}>{kws.replace('###',' ')}</span>):null})}</div>
                          </div>}
                          {me.state.incidentDetail.abs_arr && me.state.incidentDetail.abs_arr.length>0 && <div className={style['mark-box']} style={{marginBottom:'0'}}>
                            <div className={style['mark-name']}><Icon name={'file-text'} theme="outlined" className={style['mark-img']}/>摘要：</div>
                          </div>}
                          {me.state.incidentDetail.abs_arr && me.state.incidentDetail.abs_arr.map((kws) => { return kws ?(<div className={style['abs']}>{kws}</div>):null})}
                          <div className={style['from-font']}>
                            <span>{moment(new Date(me.state.incidentDetail.publish)).format("YYYY-MM-DD")}</span>
                            <span className={style['url-font']} onClick={e => {e.stopPropagation();window.open(`${me.state.incidentDetail.url}`, '_blank');}}>{me.state.incidentDetail.url}</span>
                          </div>
                        </li>
                    </ul>
                  </div>
                </div>
                <div className={style['action-box']}>
                  <div className={style['action-right']}>
                    <Button type={'primary'} onClick={() => me.onCloseDetail()} block={true}>返回</Button>
                  </div>
                </div>
              </div>
            </Drawer>) : null}
      </React.Fragment>
    );
  }
}

AnalysisIncidentList.defaultProps = {
  bus: PB,
};

AnalysisIncidentList.propTypes = {
  bus: PropTypes.instanceOf(SimplePB),
  viewDataProvider: PropTypes.instanceOf(ViewDataProvider).isRequired,
  visible: PropTypes.bool.isRequired,
  viewId: PropTypes.string,
  nodeInfo: PropTypes.object,
};

export default AnalysisIncidentList;