import React from 'react';
import PropTypes from 'prop-types';
import moment from "moment";
import momentLocale from 'moment/locale/zh-cn';
import _ from "lodash";
import {Timeline, DataSet} from 'vis';

import NodeDataProvider from '@/components/common/dataProvider/common.dataProvider.node';

import style from '@/style/containers/exploreRelationView.less';
import {showPageLoading} from "@/components/common/common.message";

// moment 使用中文
moment.updateLocale('zh-cn', momentLocale);

class TimelinePanel extends React.Component {
  containerId = `resource-timeline-${Math.random()}`;

  myTimeline = undefined;

  /**
   * @var {DataSet}
   */
  data = new DataSet();

  componentDidMount() {
    let me = this;

    const container = document.getElementById(me.containerId);
    this.myTimeline = new Timeline(container, me.data, {
      width: '100%',
      height: '100%',
      type: 'box',
      selectable: false,
      zoomMin: 7 * 24 * 60 * 60 * 1000,
      min: new Date(1800, 1, 1),
      max: new Date(2037, 12, 31),
      template: (item) => {
        return item.content;
      },
      format: {
        minorLabels: {
          millisecond: 'SSS',
          second: 'MM:ss',
          minute: 'HH:mm',
          hour: 'HH:mm',
          weekday: 'D日',
          day: 'D日',
          week: '第w周',
          month: 'M月',
          year: 'YYYY年',
        },
        majorLabels: {
          millisecond: 'HH:mm:ss',
          second: 'D日 HH时',
          minute: 'M月DD日',
          hour: 'M月DD日',
          weekday: 'YYYY年MM月',
          day: 'YYYY年MM月',
          week: 'YYYY年MM月',
          month: 'YYYY年',
          year: '',
        },
      },
    });

    NodeDataProvider.loadInsightDetailTimeline(me.props.nodeId, me.props.nodeType).then(data => {
      me.data.clear();
      let hideLoadingFn = showPageLoading('正在处理，请稍后...');
      container.style.opacity = '0';
      me.myTimeline.setOptions({
        min: new Date(1800, 1, 1),
        max: new Date(2037, 12, 31),
      });
      me.data.update(
        data.filter(item => moment(_.get(item, 'time', 'invalid')).isValid())
          .map((item, idx) => ({
            id: `item-${idx}`,
            start: moment(_.get(item, 'time', 0)).format('YYYY-MM-DD'),
            content: item['event'],
          }))
      );
      me.myTimeline.fit({animation: true});
      setTimeout(() => {
        if (me.data.length <= 0) {
          // no items
        } else if (me.data.length === 1) {
          // a single item -> don't fit, just show a range around the item from -4 to +3 days
          me.myTimeline.setOptions({
            min: moment(nodes[0].start).add(-4, 'days'),
            max: moment(nodes[0].start).add(3, 'days'),
          });
        } else {
          const range = me.myTimeline.getItemRange();
          me.myTimeline.setOptions(range);
        }
        container.style.opacity = '1';
        hideLoadingFn();
      }, 1000);
    }).catch(error => {
      console.log('时间线获取失败：' + error);
    });
  }

  render() {
    let me = this;

    return (
      <div
        className={style['hover-content-frame']}
        onClick={me.props.onClose}
      >
        <div className={style['hover-content-frame-lt']} />
        <div className={style['hover-content-frame-ct']} />
        <div className={style['hover-content-frame-rt']} />
        <div className={style['hover-content-frame-lc']} />
        <div className={style['hover-content-frame-rc']} />
        <div className={style['hover-content-frame-lb']} />
        <div className={style['hover-content-frame-cb']} />
        <div className={style['hover-content-frame-rb']} />
        <div
          className={style['hover-content-frame-inner']}
          onClick={e => e.stopPropagation()}
        >
          <div className={style['timeline-container']} id={me.containerId} />
          <div className={style['hover-content-frame-inner-cover']} />
        </div>
      </div>
    );
  }
}

TimelinePanel.defaultProps = {
};

TimelinePanel.propTypes = {
  onClose: PropTypes.func.isRequired,
  nodeId: PropTypes.string.isRequired,
  nodeType: PropTypes.number.isRequired,
};

export default TimelinePanel;