import React from 'react';
import {Card/*, Alert*/} from "antd";
import _ from 'lodash';

import NodeDataProvider, {overrideNextMessage} from "@/components/common/dataProvider/common.dataProvider.node";
// import RelationDataProvider, {overrideNextMessage} from "@/components/common/common.dataProvider.relation";

import Icon from "@/components/common/common.icon";

import BriefingInfoCard from "@/components/common/relation/common.relation.briefingInfoCard";

import style from '@/style/common/relation/common.relation.briefingInfo.less';
import PropTypes from "prop-types";
import {getNodeDisplayTitle, getNodeIcon} from "@/constants/vis.defaultDefine.1";

class BriefingInfoPanel extends React.Component {
  state = {
    status: 'idle',
    infoList: [],
  };

  onTryAgain = () => {
    let me = this;
    me.setState({
      status: 'processing',
      infoList: [],
    }, () => {
      overrideNextMessage('static::loadBriefingListByNodes', false);
      NodeDataProvider.loadBriefingListByNodes(me.props.nodes).then(
        // overrideNextMessage('static::loadBriefing', false);
        // RelationDataProvider.loadBriefing(evidenceId).then(
        /**
         * @param {Array} infoList
         */
        infoList => {
          infoList = _.isArray(infoList) ? infoList : [infoList];
          me.setState({
            status: 'success',
            infoList,
          });
        }
      ).catch(error => {
        console.log('加载简报列表失败：', error);
        me.setState({
          status: 'failed',
          infoList: [],
        })
      });
    });
  };

  componentDidMount() {
    let me = this;

    requestAnimationFrame(() => me.onTryAgain());
  }

  render() {
    let me = this;

    let content;
    switch (me.state.status) {
      case 'failed':
        content = (
          <div className={style['empty-data']}>
            <Icon name={'exclamation-circle'} theme={'outlined'} /><br />
            <span>数据准备中，请稍后<a onClick={() => me.onTryAgain()}>&lt;重试&gt;</a></span>
          </div>
        );
        break;
      case 'success':
        content = (
          me.state.infoList.length > 0 ? (
            <div className={`${style['result-list']} scrollbar-none`}>
              {
                me.state.infoList.map(info => (
                  <BriefingInfoCard key={`info-${info.id}`} info={info} />
                ))
              }
            </div>
          ) : (
            <div className={style['empty-data']}>
              <Icon name={'exclamation-circle'} theme={'outlined'} /><br />
              <span>没有更多详细信息</span>
            </div>
          )
        );
        break;
      default:
        content = (
          <div className={style['loading']}>
            <Icon name={'loading'} theme={'outlined'} /><br />
            数据加载中，请稍后…
          </div>
        );
    }

    return (
      <div className={`${style['briefing-info-panel']} dark-theme`}>
        <Card
          bordered={false}
          title={(
            <span>
              <span className={style['related-node-title']}>
                <span>
                  <Icon {...getNodeIcon(me.props.nodes[0])} style={{marginRight: '0.5em'}}/>
                  <span>{getNodeDisplayTitle(me.props.nodes[0])}</span>
                </span>
              </span>
              <span className={style['related-node-title-link']}>&lt;-&gt;</span>
              <span className={style['related-node-title']}>
                <span>
                  <Icon {...getNodeIcon(me.props.nodes[1])} style={{marginRight: '0.5em'}}/>
                  <span>{getNodeDisplayTitle(me.props.nodes[1])}</span>
                </span>
              </span>
            </span>
          )}
        >
          {content}
        </Card>
      </div>
    );
  }
}

BriefingInfoPanel.defaultProps = {
};

BriefingInfoPanel.propTypes = {
  nodes: PropTypes.array.isRequired,
};

export default BriefingInfoPanel;