import React from 'react';
import PropTypes from 'prop-types';
import {Form, Input, Modal} from 'antd';
import PB, {SimplePB} from "@/libs/simplePB";
import {getNodeDisplayTitle} from "@/constants/vis.defaultDefine.1";

class CommonEditModal extends React.Component {
  state = {
    name: '',
  };

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (this.props.visible === false && nextProps.visible === true) {
      const me = this;
      this.setState((state, props) => {
        requestAnimationFrame(() => {
          const {form} = me.props;
          form.resetFields();
        });
        return {name: props.node.fname};
      });
    }
    return true;
  }

  onSave = () => {
    let me = this;
    const {form} = me.props;
    form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
        // 节点信息中的名称可编辑
        const {name} = values;
        const node = {
          ...me.props.node,
          fname: name,
          label: name,
        };
        me.props.bus.emit('network', 'node.do_edit', {node});
      }
    });
  };

  render() {
    let me = this;
    const {visible, doClose, form} = me.props;
    const {getFieldDecorator} = form;
    const formItemLayout = {
      labelCol: {
        xs: {span: 8},
        sm: {span: 4},
      },
      wrapperCol: {
        xs: {span: 36},
        sm: {span: 18},
      },
    };

    // noinspection RequiredAttributes
    return (
      <Modal
        closable={!me.props.processing}
        visible={visible}
        title={`编辑节点详情（${getNodeDisplayTitle(me.props.node, 18)}）`}
        okText={'确定'}
        cancelText={'取消'}
        onCancel={doClose}
        onOk={me.onSave}
        okButtonProps={{loading: me.props.processing}}
        cancelButtonProps={{disabled: me.props.processing}}
      >
        <Form>
          <Form.Item
            {...formItemLayout}
            label="名称"
          >
            {getFieldDecorator('name', {
              initialValue: me.props.node.fname,
              rules: [{
                required: true, message: '请输入名称',
              }],
            })(
              <Input/>
            )}
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

const WrappedCommonEditModal = Form.create()(CommonEditModal);

// noinspection JSValidateTypes
WrappedCommonEditModal.defaultProps = {
  node: {fname: ''},
  bus: PB,
  visible: false,
  processing: false,
};

// noinspection JSValidateTypes
WrappedCommonEditModal.propTypes = {
  node: PropTypes.object,
  bus: PropTypes.instanceOf(SimplePB),
  visible: PropTypes.bool,
  processing: PropTypes.bool,
  doClose: PropTypes.func.isRequired,
};

export default WrappedCommonEditModal;
