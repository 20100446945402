import React from 'react';
import PropTypes from 'prop-types';
import _ from "lodash";
import {message} from "antd";

import PB, {SimplePB} from "@/libs/simplePB";
import MyNetwork from '@/libs/myVis/MyNetwork3';

import EventListener from "react-event-listener";
import {getHttpUtil} from "@/utils/HttpUtil";

import ViewDataProvider from '@/components/common/dataProvider/common.dataProvider.view';
import NodeInfoPresentationTooltip, {getNodeTooltipContent} from "@/components/mainView/nodeInfo/main.nodeInfo.presentation.tooltip";

import style from '@/style/common/relation/common.relation.presentation.less';
// import {getNodeDisplayTitle} from "@/constants/vis.defaultDefine.1";
import PresentationStoryShow from '@/components/common/view/presentation/common.view.presentation.storyShow';

const publicResourceHttpUtil = getHttpUtil(false, '');

class RelationPresentation extends React.Component {
  state = {
    showNodeTooltip: false,
    nodeTooltipInfo: undefined,
    presentationConfig: undefined
  };

  currentNodePos = 0; // 当前漫游到的节点位置

  autoStart = false; // 演示初始化及数据加载完毕后自动开始

  initialized = false; // 演示是否初始化完毕

  defaultPresentationConfig = undefined; // 图谱默认演示配置

  customPresentationConfig = {}; // 自定义演示配置

  presentationConfig = undefined; // 合并后演示配置

  dataReady = false; // 关系图及其附件数据是否加载完毕

  presentationWaiting = false; // 演示是否正在等待画面静止

  presentationStarted = false; // 演示是否正在进行中

  presentationStayFocus = false; // 演示进行中，当前画面静止在聚焦状态，手动播放步骤

  presentationInterval = 0; // 演示周期函数标记

  presentationNodeIds = []; // 参与演示的节点ID

  storyInfo = {
    title: undefined,
    description: undefined,
    remarks:undefined
  };

  getNodeTooltipContent = nodeId => {
    if (_.isFunction(this.props.getNodeTooltipContent)) {
      return this.props.getNodeTooltipContent(nodeId);
    } else {
      return getNodeTooltipContent(this.props.viewDataProvider, nodeId);
    }
  };

  internalShowNextNode = () => {
    let me = this;
    me.setState({showNodeTooltip: false}, () => {
      me.props.network.presenting(undefined);
      if (!me.presentationStarted) {
        clearInterval(me.presentationInterval);
        me.presentationInterval = 0;
        return;
      }
      let nodeId = me.presentationNodeIds[me.currentNodePos];
      me.currentNodePos = (me.currentNodePos + 1) % me.presentationNodeIds.length;
      let currentPos = me.currentNodePos;
      if(currentPos==1 && nodeId==='#&#title#&#' && me.storyInfo && me.storyInfo.title){
        let nodeTooltipInfo = {success: true, type:'title', fname:me.storyInfo.title, content:me.storyInfo.description, remarks:me.storyInfo.remarks};
        me.props.bus.emit('relation', 'presentation.playing.node', {viewId: me.props.viewId, nodeId: nodeId});
        setTimeout(() => {
              // 显示节点Tooltip
          nodeTooltipInfo = {...nodeTooltipInfo};
          this.setState({showNodeTooltip: true, nodeTooltipInfo});
          me.delayTime();
        }, 500);
      }else{
        let nodes = me.props.network.getNodes(nodeId);
        if (nodes && nodes.length > 0) {
          let node = nodes[0];
          me.props.bus.emit('relation', 'presentation.playing.node', {viewId: me.props.viewId, nodeId: node.id});
          me.props.network.presenting(nodeId);
          me.props.network.network.stopSimulation();
          me.props.network.focus(nodeId);
          let nodeTooltipInfo = me.getNodeTooltipContent(nodeId);
          if (nodeTooltipInfo.success) {
            me.props.network.network.once('animationFinished', () => {
              setTimeout(() => {
                if (me.currentNodePos !== currentPos) return;
                if (!me.presentationStarted) {
                  clearInterval(me.presentationInterval);
                  me.presentationInterval = 0;
                  me.props.network.presenting(undefined);
                  return;
                }
                // 显示节点Tooltip
                nodeTooltipInfo = {...nodeTooltipInfo};
                this.setState({showNodeTooltip: true, nodeTooltipInfo});
                me.delayTime();
              }, 1500);
            });
          }
        }else{
          let nodeTooltipInfo = {success: true,type:'notNode', content:nodeId};
          me.props.bus.emit('relation', 'presentation.playing.node', {viewId: me.props.viewId, nodeId: nodeId});
            setTimeout(() => {
              // 显示节点Tooltip
              nodeTooltipInfo = {...nodeTooltipInfo};
              this.setState({showNodeTooltip: true, nodeTooltipInfo});
              me.delayTime();
            }, 500);
        }
      }
    });
  };

  delayTime = () => {
    let me = this;
    //me.presentationInterval = setInterval(me.internalShowNextNode, me.presentationConfig['interval'])
    if (me.presentationInterval > 0) {
      clearTimeout(me.presentationInterval);
      me.presentationInterval = 0;
    }
    me.presentationInterval = setTimeout(me.internalShowNextNode, me.presentationConfig['interval']-2000);
  }

  internalTryStart = (wait = true) => {
    let me = this;

    const doStart = () => {
      me.presentationWaiting = false;
      if (!me.autoStart) return;
      me.presentationStarted = true;
      me.props.bus.emit('relation', 'presentation.started', {viewId: me.props.viewId});
      //me.delayTime();
      me.internalShowNextNode();
    };
    if (wait) {
      let startTimeout = setTimeout(doStart, 25000); // 25秒后强制开始
      const tryStartFn = () => {
        console.log('in tryStartFn');
        let localStartTimeout = setTimeout(() => {
          console.log('in localStartTimeout');
          if (!me.presentationStarted) {
            console.log('in localStartTimeout --- !me.presentationStarted');
            clearTimeout(startTimeout);
            me.props.network.network.stopSimulation();
            doStart();
          }
        }, 500);
        me.props.network.network.once('startStabilizing', () => {
          clearTimeout(localStartTimeout);
          if (!me.autoStart) {
            me.presentationWaiting = false;
            return;
          }
          if (!me.presentationStarted) {
            me.props.network.network.once('nearlyStabilized', tryStartFn);
          }
        });
      };
      me.props.network.network.once('nearlyStabilized', tryStartFn);
      me.props.network.network.startSimulation();
    } else {
      me.props.network.network.stopSimulation();
      doStart();
    }
  };

  preparePresentationNodeIds = () => {
    let me = this;

    me.presentationNodeIds = me.props.network.graph.nodes.get()/*.filter(node => {
      let content = me.getNodeTooltipContent(node.id);
      return content.success && (content.content || content.image);
    })*/.sort(
      (a, b) => (
        -`${a['updateTime'] || a['linkTime'] || a['accessTime'] }`
          .localeCompare(`${b['updateTime'] || b['linkTime'] || b['accessTime']}`)
      )
      /*(a, b) => (a._userFavorite > -1 && b._userFavorite > -1) ? (
        getNodeDisplayTitle(a).localeCompare(getNodeDisplayTitle(b), 'zh-CN')
      ) : (
        (a._userFavorite > -1 && b._userFavorite > -1) || (a._userFavorite === b._userFavorite) ? ( //原来(a._userFavorite === b._userFavorite)
          (a.priority === 10 && b.priority === 10) ? (
            getNodeDisplayTitle(a).localeCompare(getNodeDisplayTitle(b), 'zh-CN')
          ) : (a.priority === b.priority ? (
            -`${a['accessTime'] || a['updateTime'] || a['linkTime']}`
              .localeCompare(`${b['accessTime'] || b['updateTime'] || b['linkTime']}`)
          ) : ((b.priority || 0) - (a.priority || 0)))
        ) : (a._userFavorite > -1 ? -1 : 1)
      )*/
    ).map(node => node.id);

    if (
      me.presentationConfig['maxNodeAmount'] &&
      me.presentationNodeIds.length > me.presentationConfig['maxNodeAmount']
    ) {
      me.presentationNodeIds = me.presentationNodeIds.slice(0, me.presentationConfig['maxNodeAmount']);
    }
  };



  onKeyDown = e => {
    let me = this;
    if (me.presentationStarted||me.presentationNodeIds.length>0) {
      me.props.bus.emit('image_light_box', 'hide.do');
      // space
      if (e.keyCode === 32)  {
        // 演示进行中，用户按下空格键
        if (me.presentationStayFocus !== true) {
          // 画面静止，播放步骤交由用户控制
          clearTimeout(me.presentationInterval);
          me.presentationInterval = 0;
          me.presentationStayFocus = true;
          me.props.bus.emit('relation', 'presentation.playing.focus');
        } else {
          // 恢复自动播放下一个节点
          //me.delayTime();
          me.internalShowNextNode();
          me.presentationStayFocus = false;
          me.props.bus.emit('relation', 'presentation.playing.continue');
        }
      } else if ( e.keyCode === 38 /* up */ || e.keyCode === 33) {
        // 画面静止，播放步骤交由用户控制，跳转到上一个节点
        if (me.presentationInterval > 0) {
          clearTimeout(me.presentationInterval);
          me.presentationInterval = 0;
          me.presentationStayFocus = true;
          me.props.bus.emit('relation', 'presentation.playing.focus');
        }
        me.currentNodePos = (me.currentNodePos + me.presentationNodeIds.length - 2) % me.presentationNodeIds.length;
        me.internalShowNextNode();
      } else if (e.keyCode === 40 /* down */ || e.keyCode === 34) {
        // 画面静止，播放步骤交由用户控制，跳转到上一个节点
        if (me.presentationInterval > 0) {
          clearTimeout(me.presentationInterval);
          me.presentationInterval = 0;
          me.presentationStayFocus = true;
          me.props.bus.emit('relation', 'presentation.playing.focus');
        }
        me.internalShowNextNode();
      } else if (e.keyCode === 37 /* left */ ||e.keyCode === 39 /* right */ ) {
        // 画面静止，播放步骤交由用户控制，跳转到上一个节点
        if (me.presentationInterval > 0) {
          clearTimeout(me.presentationInterval);
          me.presentationInterval = 0;
        }
        if(e.keyCode === 39){
          me.props.bus.emit('relation', 'presentation.node.show_full_screen');
        }else{
          me.props.bus.emit('relation', 'presentation.node.show_img');
        }
        //me.props.bus.emit('relation', 'presentation.do_pause');
      } else {
        me.props.bus.emit('relation', 'presentation.do_pause');
      }
    }
  };

  onKeyEvent = e => {
    let me = this;
    if (me.presentationStarted) {
      if (e.keyCode === 32 || (e.keyCode >= 37 && e.keyCode <= 40) || e.keyCode === 33 || e.keyCode === 34)  {
        e.stopPropagation();
        e.preventDefault();
      }
    }
  };

  componentDidMount() {
    let me = this;

    // 初始化
    me.props.bus.sub(me, 'relation', 'presentation.do_init', ({viewId, autoStart, noWait, title, description, remarks}) => {
      if (me.props.viewId && viewId !== me.props.viewId) return;
      const showWaitingTimeout = {timeout: 0, msgShown: false, timestamp: 0};
      if (noWait === true) {
        showWaitingTimeout.timestamp = (new Date()).getTime();
        showWaitingTimeout.timeout = setTimeout(() => {
          showWaitingTimeout.msgShown = true;
          message.info('数据准备中，请稍后…');
        }, 500);
      }
      const fn = data => {
        me.defaultPresentationConfig = data;
        me.initialized = true;
        me.autoStart = (autoStart === true);
        me.props.bus.emit('relation', 'presentation.initialized', {viewId});
        if (me.dataReady && me.autoStart) {
          if (showWaitingTimeout.msgShown) {
            let currentTimestamp = (new Date()).getTime();
            setTimeout(() => {
              me.props.bus.emit('relation', 'presentation.do_start', {viewId, noWait, title, description, remarks});
            }, Math.max(1, showWaitingTimeout.timestamp + 2000 - currentTimestamp));
          } else {
            if (noWait === true) clearTimeout(showWaitingTimeout.timeout);
            me.props.bus.emit('relation', 'presentation.do_start', {viewId, noWait, title, description, remarks});
          }
        }
      };
      if (me.props.viewId) {
        publicResourceHttpUtil.get(`/assets/presentation/${me.props.viewId}.json`).then(response => {
          if (response && response.data && _.isObject(response.data)) {
            fn(response.data);
          } else {
            // 尝试获取默认配置文件
            publicResourceHttpUtil.get(`/assets/presentation/_default.json`).then(response => {
              if (response && response.data) {
                fn(response.data);
              }
            });
          }
        }).catch(() => {
          // 尝试获取默认配置文件
          publicResourceHttpUtil.get(`/assets/presentation/_default.json`).then(response => {
            if (response && response.data) {
              fn(response.data);
            }
          });
        });
      } else {
        // 尝试获取默认配置文件
        publicResourceHttpUtil.get(`/assets/presentation/_default.json`).then(response => {
          if (response && response.data) {
            fn(response.data);
          }
        });
      }
    });

    // 关系图及其附件数据加载完毕
    me.props.bus.sub(me, 'relation', 'presentation.on_relation_data_ready', ({viewId}) => {
      if (me.props.viewId && viewId !== me.props.viewId) return;
      me.dataReady = true;
      if (me.autoStart === true) {
        me.props.bus.emit('relation', 'presentation.do_start', {viewId});
      }
    });

    // 尝试开始演示
    me.props.bus.sub(me, 'relation', 'presentation.do_start', ({noWait, config, title, description, remarks}) => {
      if(title){
        me.storyInfo = {title, description, remarks};
      }else{
        me.storyInfo = {
          title: undefined,
          description: undefined,
          remarks:undefined
        };
      }
      if (me.presentationWaiting || me.presentationStarted) {
        if (config) {
          me.props.bus.emit('relation', 'presentation.do_pause', {noMsg: true});
          me.props.bus.emit('relation', 'presentation.do_start', {noWait, config, title, description, remarks});
          return;
        }
      }
      me.customPresentationConfig = config || me.customPresentationConfig;
      me.currentNodePos = 0;
      if (me.initialized !== true) {
        me.props.bus.emit('relation', 'presentation.do_init',
          {viewId: me.props.viewId, autoStart: true, noWait: noWait === true, title, description, remarks});
      } else if (me.dataReady !== true) {
        me.autoStart = true;
      } else if ((!me.presentationWaiting) && (!me.presentationStarted)) {
        me.presentationWaiting = true;
        me.autoStart = true;
        // noinspection JSValidateTypes
        me.presentationConfig = _.merge({}, me.defaultPresentationConfig, me.customPresentationConfig);
        me.setState({presentationConfig: me.presentationConfig});
        if (_.isArray(me.presentationConfig['contentList']) && me.presentationConfig['contentList'].length > 0) {
          me.presentationNodeIds = me.presentationConfig['contentList'];
        }else if (_.isArray(me.presentationConfig['nodeIds']) && me.presentationConfig['nodeIds'].length > 0) {
          me.presentationNodeIds = me.presentationConfig['nodeIds'];
        } else {
          me.preparePresentationNodeIds();
        }
        if (me.presentationNodeIds.length <= 0) {
          message.info('暂无可以漫游的节点，请尝试修改节点描述或上传图片附件。');
          me.presentationWaiting = false;
          me.props.bus.emit('relation', 'presentation.do_pause');
          return;
        }else{
          if(title){
            me.presentationNodeIds.unshift('#&#title#&#');
          }
        }
        me.internalTryStart(noWait !== true);
      }
    });

    // 暂停演示
    me.props.bus.sub(me, 'relation', 'presentation.do_pause', ({noMsg} = {noMsg: false}) => {
      me.autoStart = false;
      me.presentationStarted = false;
      if (me.presentationInterval > 0 || me.presentationStayFocus) {
        noMsg;
        if (me.presentationInterval > 0) {
          clearTimeout(me.presentationInterval);
          me.presentationInterval = 0;
        }
        if (me.presentationStayFocus) {
          me.presentationStayFocus = false;
        }
        me.props.bus.emit('relation', 'presentation.stopped', {viewId: me.props.viewId});
      }
      me.setState({showNodeTooltip: false});
      me.props.network.presenting(undefined);
    });

    // 继续演示
    me.props.bus.sub(me, 'relation', 'presentation.do_resume', (options = {noWait: true}) => {
      if (me.initialized !== true) {
        me.props.bus.emit('relation', 'presentation.do_init',
          {viewId: me.props.viewId, autoStart: true, noWait: options.noWait !== false});
      } else if (me.dataReady !== true) {
        me.autoStart = true;
      } else if ((!me.presentationWaiting) && (!me.presentationStarted)) {
        me.presentationWaiting = true;
        me.autoStart = true;
        // 如果参与演示的节点数量为零，尝试重新计算
        if (me.presentationNodeIds.length <= 0) {
          if (_.isArray(me.presentationConfig['contentList']) && me.presentationConfig['contentList'].length > 0) {
            me.presentationNodeIds = me.presentationConfig['contentList'];
          }else if (_.isArray(me.presentationConfig['nodeIds']) && me.presentationConfig['nodeIds'].length > 0) {
            me.presentationNodeIds = me.presentationConfig['nodeIds'];
          } else {
            me.preparePresentationNodeIds();
          }
          me.currentNodePos = 0;
        } else {
          // 指向上一个节点
          me.currentNodePos = (me.currentNodePos + me.presentationNodeIds.length - 1) % me.presentationNodeIds.length;
        }
        if (me.presentationNodeIds.length <= 0) {
          message.info('暂无可以漫游的节点，请尝试修改节点描述或上传图片附件。');
          me.presentationWaiting = false;
          me.props.bus.emit('relation', 'presentation.do_pause');
          return;
        }
        me.internalTryStart(false);
      }
    });

    // 挂起演示
    me.props.bus.sub(me, 'relation', 'presentation.do_hold', ({noMsg} = {noMsg: false}) => {
      if (me.presentationStarted) {
        noMsg;
        if (me.presentationInterval > 0) {
          clearTimeout(me.presentationInterval);
          me.presentationInterval = 0;
        }
        if (me.presentationStayFocus) {
          me.presentationStayFocus = false;
        }
        me.presentationStarted = false;
        me.currentNodePos = (me.currentNodePos + 1) % me.presentationNodeIds.length;
        me.props.bus.emit('relation', 'presentation.stopped', {viewId: me.props.viewId});
        me.setState({showNodeTooltip: false});
        me.props.network.presenting(undefined);
      } else if (me.autoStart) {
        noMsg;
      }
      me.autoStart = false;
      me.presentationStarted = false;
    });

    // 恢复自动播放
    me.props.bus.sub(me, 'relation', 'presentation.playing.do_continue', () => {
      if (me.presentationStarted && me.presentationStayFocus) {
        // 恢复自动播放下一个节点
        //me.delayTime();
        me.internalShowNextNode();
        me.presentationStayFocus = false;
        me.props.bus.emit('relation', 'presentation.playing.continue');
      }
    });

    // 更新默认漫游配置
    me.props.bus.sub(me, 'relation', 'presentation.default_config.update', (config, resetCurrentNodePos = false) => {
      let updateDefaultConfigFn = () => {
        // noinspection JSValidateTypes
        me.defaultPresentationConfig = _.merge({}, me.defaultPresentationConfig, config);
        // noinspection JSValidateTypes
        me.presentationConfig = _.merge({}, me.defaultPresentationConfig, me.customPresentationConfig);
        me.setState({presentationConfig: me.presentationConfig});
        if (resetCurrentNodePos) {
          me.currentNodePos = 0;
          me.presentationNodeIds = [];
        }
        if (me.presentationInterval > 0) {
          clearTimeout(me.presentationInterval);
          me.presentationInterval = 0;
        }
        me.delayTime();
      };
      if (me.initialized !== true) {
        me.props.bus.once(me, 'relation', 'presentation.initialized', () => updateDefaultConfigFn());
        me.props.bus.emit('relation', 'presentation.do_init', {viewId: me.props.viewId});
      } else {
        updateDefaultConfigFn();
      }
    });

    // 设置自定义漫游配置
    me.props.bus.sub(me, 'relation', 'presentation.custom_config.set', (config, resetCurrentNodePos = false) => {
      me.customPresentationConfig = config || {};
      // noinspection JSValidateTypes
      me.presentationConfig = _.merge({}, me.defaultPresentationConfig, me.customPresentationConfig);
      me.setState({presentationConfig: me.presentationConfig});
      if (resetCurrentNodePos) {
        me.currentNodePos = 0;
      }
    });

    const stopPresentationFn = () => {
      me.props.bus.emit('relation', 'presentation.do_pause');
    };
    me.props.network.subscribe(me, 'NETWORK.click', stopPresentationFn);
    me.props.network.subscribe(me, 'NETWORK.dragStart', stopPresentationFn);
    me.props.network.subscribe(me, 'NETWORK.zoom', stopPresentationFn);
    me.props.bus.sub(me, 'network', 'focus', stopPresentationFn);
  }

  componentWillUnmount() {
    this.props.bus.remove(this);
    this.props.network.unSubscribe(this);
  }

  render() {
    let me = this;

    return (
      <div className={style['frame']}>
        {
          this.state.showNodeTooltip && this.state.nodeTooltipInfo &&
          this.state.nodeTooltipInfo.success && (
            <NodeInfoPresentationTooltip {...this.state.nodeTooltipInfo} />
          )
        }
        <EventListener
          target={window}
          onKeyDown={me.onKeyDown}
          onKeyPress={me.onKeyEvent}
          onKeyUp={me.onKeyEvent}
        />
        { (me.presentationStarted || me.presentationNodeIds.length>0) && false && <PresentationStoryShow
            bus={me.props.bus}
            viewId={me.props.viewId}
            config={{storyInfo:me.storyInfo,content:me.state.presentationConfig}}
            viewDataProvider={me.props.viewDataProvider}
          />}
      </div>
    );
  }
}

RelationPresentation.defaultProps = {
  bus: PB,
  getNodeTooltipContent: false,
};

RelationPresentation.propTypes = {
  bus: PropTypes.instanceOf(SimplePB),
  viewDataProvider: PropTypes.instanceOf(ViewDataProvider),
  network: PropTypes.instanceOf(MyNetwork).isRequired,
  viewId: PropTypes.string,
  getNodeTooltipContent: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
};

export default RelationPresentation;