import React from 'react';
import {Icon} from 'antd';

import PB from '@/libs/simplePB'
import style from '@/style/containers/mainView.1.0.1.less'
import {SysUIConfig} from "@/constants/sys.config";

export default class Loading extends React.Component {
    state = {
        // 加载页面遮罩
        viewLoading: {
            status: false,
            content: undefined, // 显示文字
        },
    };

    timeoutId = -1;

    componentDidMount() {
        /**
         * 订阅加载数据的loading
         * params:true | false | {status:true | false,content:ReactJSX}
         */
        PB.sub(this, 'MainView', 'spinLoading', (params) => {
            if (this.timeoutId >= 0) {
                clearTimeout(this.timeoutId);
                this.timeoutId = -1;
            }
            if (params === false) {
                // 延迟0.2秒消失
                // console.log('loading延迟1s消失')
                let that = this;
                this.timeoutId = setTimeout(
                  () => that.setState({viewLoading: {status: false}, content: undefined}), 200)
            } else if (params === true) {
                this.setState({viewLoading: {status: true, content: undefined}})
            } else {
                this.setState({viewLoading: {status: params.status, content: params.content, ajax: params.ajax}})
            }
        });
    }


    render() {
        const {viewLoading}=this.state
        return (
            <div
                style={{ display: viewLoading.status ? SysUIConfig.LoadingBar.display : 'none' }}
                className={viewLoading.ajax===false?style.spinning:style.spinning+' '+style['spinning-as-ajax']}
            >
            {viewLoading.content ? <span className={style['spinning-content']}><Icon type="loading" className={style['spinning-content-loading']}/>  {viewLoading.content}</span> : ''}
        </div>
        )
    }
}
