// 库
import React from 'react';
import PropTypes from 'prop-types';

import {DataSet} from '@/libs/vis'
// ECharts 主模块ap.json
import echarts from 'echarts/lib/echarts';
// ECharts 柱状图、饼图、折线图
import 'echarts/lib/chart/bar';
import 'echarts/lib/chart/pie';
import 'echarts/lib/chart/line';
// ECharts 提示框和标题组件
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/title';
import 'echarts/map/js/china.js'; // 百度自带的完整中国地图
import 'echarts/map/js/world.js'; // 百度自带的完整世界地图
// css
import style from '@/style/components/toolbar/map.json.less'
import lineStyle from '@/style/components/toolbar/line.list.less'
// 组件
import {Popover, Tooltip, message} from 'antd';
import {getObjectValue, simplifyProv} from '@/components/mainView/toolbar/functions'
import NodeInfoCard from "@/components/explorationView/exploration.nodeInfoCard";
import Node from '@/libs/view/Node'
import {
    showAsTip,
    chinaProvinceADCMap,
    simplifiedChinaProvinceADCMap,
    unknownChinaADC,
} from "@/components/common/common.functions";
import {FilterAndStatisticsBus} from "@/components/common/common.timestampAndLocationStatistics";
import {bindUtil} from "@/libs/core-decorators";
import {autoSetState, withReactStateHelper} from "@/libs/react-state-helper";
import {getNodeDisplayTitle} from "@/constants/vis.defaultDefine.1";

// 缩放比例 1-3，放大默认3 todo：各个省的中心点，这里是省会城市的坐标
const geoCoordMapOfProv = {
    '110000': ['116.46', '39.92', 9], // 北京
    '120000': ['117.2', '39.13', 9], // 天津
    '130000': ['114.48', '38.03', 3], // 河北
    '140000': ['112.53', '37.87', 3], // 山西
    '150000': ['111.65', '40.82', 3], // 内蒙古
    '210000': ['123.38', '41.8', 3], // 辽宁
    '220000': ['125.35', '43.88', 3], // 吉林
    '230000': ['126.63', '45.75', 3], // 黑龙江
    '310000': ['121.48', '31.22', 10], // 上海
    '320000': ['118.78', '32.04', 5], // 江苏
    '330000': ['120.19', '30.26', 3], // 浙江
    '340000': ['117.27', '31.86', 3], // 安徽
    '350000': ['119.3', '26.08', 3], // 福建
    '360000': ['115.89', '28.68', 3], // 江西
    '370000': ['117.024967066', '36.6827847272', 3], // 山东
    '410000': ['113.65', '34.76', 3], // 河南
    '420000': ['114.31', '30.52', 3], // 湖北
    '430000': ['113', '28.21', 3], // 湖南
    '440000': ['113.23', '23.16', 3], // 广东
    '450000': ['106.54', '29.59', 3], // 广西
    '460000': ['110.35', '20.02', 5], // 海南
    '500000': ['106.54', '29.59', 5], // 重庆
    '510000': ['104.06', '30.67', 3], // 四川
    '520000': ['106.71', '26.57', 3], // 贵州
    '530000': ['102.73', '25.04', 3], // 云南
    '540000': ['91.11', '29.97', 3], // 西藏
    '610000': ['108.95', '34.27', 3], // 陕西
    '620000': ['103.73', '36.03', 3], // 甘肃
    '630000': ['101.74', '36.56', 3], // 青海
    '640000': ['106.27', '38.47', 3], // 宁夏
    // '650000': ['87.68', '43.77', 3], // 新疆
    '650000': ['87.627704', '43.793026', 3], // 新疆
    '710000': ['121.5', '25.14', 3], // 台湾
    '810000': ['114.1', '22.2', 3], // 香港
    '820000': ['113.33', '22.13', 3], // 澳门
};

// 各个省统计数据提示的坐标，todo：各个省的中心点，这里是省会城市的坐标
const statisticTipArr = () => {
    const result = [];
    Object.keys(simplifiedChinaProvinceADCMap).forEach(chinaADC => {
        result.push({
            name: simplifiedChinaProvinceADCMap[chinaADC],
            china_adc: chinaADC,
            value: 0,
        });
    });
    return result;
};

// 中国地图中心
const geoCoordMapOfChina = ['106.553514', '35.358265'];

/**
 * 用echarts画json地图，并且做个动态的打点效果
 * 动态数据来源：父级传入，
 */
@bindUtil.asTargetClass
@withReactStateHelper
class MapNav extends React.Component {
    // 容器id
    containerId = 'MapNavEchartsMapId';
    container = undefined;
    // 浮动提示id
    elNodeInfoCardId = 'NodeInfoCard';

    // 含有有效分类字段值的node数据
    nodesHasPositionArray = [];
    // 各地区数量统计数据
    regionsStatistic = statisticTipArr();
    // 按区域统计的结果中：各区域节点的数量最大值和最小值
    statisticTotal = {min: 0, max: 50};
    statisticMin = 0;
    statisticMax = 50;

    // 有开始时间的node数据
    nodesHasTimeArray = []; // vis timeline 格式的node 数组
    // 各时间段数量统计数据
    datetimeStatistic = [];

    @bindUtil.bindToProperty('props.bus', 'statisticsUpdated')
    @autoSetState
    statisticsUpdated = false;

    // echarts实例
    myChart = undefined;
    // timeline默认配置，正常状态(多个节点的)
    defaultOptionFun = () => {
        let that = this;
        this.regionsStatistic = statisticTipArr();
        return {
            // 可视化颜色
            visualMap: {
                seriesIndex: 0, // 视觉组件只对哪个数据列有效
                left: 400, // 根据css文件设置值变动
                bottom: 100,
                orient: 'horizontal', // 水平还是垂直放置视觉组件 vertical
                min: 0,
                max: 50,
                itemWidth: 15, // 图形宽度 默认：20
                itemHeight: 250, // 图形长度
                inRange: {
                    // color: ['#696969', '#AFD5E8', '#419391', '#3B447A', '#EACE3F', '#B35C1E', '#B22200']
                    color: ['#3B447A', '#419391', '#AFD5E8', '#EACE3F', '#B35C1E', '#B22200'],
                    // color: [, '#3B447A', '#419391', '#AFD5E8', '#EACE3F', '#B35C1E', '#B22200']
                    // color: ['#e0f3f8', '#abd9e9', '#74add1', '#4575b4', '#313695',]
                    // color: ['#ffffbf', '#fee090', '#fdae61', '#f46d43', '#d73027', '#a50026']
                },
                text: ['多', '少'], // 文本，默认为数值文本
                textStyle: {
                    color: '#f8f8f8',
                },
                calculable: true,
            },
            geo: {
                // map: 'China',
                map: 'china',
                // silent: true, // 响应鼠标事件
                roam: false, // 开启鼠标缩放、平移
                center: geoCoordMapOfChina,
                label: {
                    position: 'bottom',
                    distance: 5,
                    normal: {
                        show: false,
                    },
                    emphasis: {
                        show: false,
                        fontSize: 24,
                        color: '#f8f8f8',
                    },
                },
                // 地图区域的多边形 图形样式。
                itemStyle: {
                    normal: {
                        // areaColor: '#545454',
                        areaColor: '#696969',
                        borderColor: '#111',
                    },
                    emphasis: {
                        // areaColor: '#ffffbf'
                        areaColor: '#1890ff',
                    },
                },
            },
            series: [
                {
                    name: '区域',// 不能改
                    type: 'map',
                    // mapType: 'china',
                    geoIndex: 0, // 公用geo的地图
                    roam: false,
                    data: statisticTipArr(),
                },
                {
                    name: '产业资源',// 不能改
                    // type: 'effectScatter',
                    type: 'scatter',
                    coordinateSystem: 'geo',
                    geoIndex: 0, // 公用geo的地图
                    data: [],
                    symbolSize: 8,
                    rippleEffect: {
                        scale: 2,
                    },
                    label: {
                        show: false,
                        position: [0, 0],
                        formatter: function (params) {
                            return params.name;
                            // return params.name + ' : ' + params.value[2];
                        },
                    },
                    itemStyle: {
                        normal: {
                            opacity: 0.8,
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowOffsetY: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)',
                            color: new echarts.graphic.RadialGradient(0.4, 0.3, 1, [{
                                offset: 0,
                                // color: 'rgb(251, 118, 123)'
                                color: '#f6f6f6',
                            }, {
                                offset: 1,
                                // color: 'rgb(204, 46, 72)'
                                color: '#9b9596',
                            }]),
                        },
                        emphasis: {
                            borderColor: '#f8f8f8',
                            borderWidth: 3,
                        },
                    },
                },
                {
                    z: 10, // 数据列的显示属性，越大越往前
                    name: "统计数据", // name不能改
                    type: 'custom',//配置显示方式为用户自定义
                    coordinateSystem: 'geo',
                    itemStyle: {
                        normal: {
                            color: '#46bee9',
                        },
                    },
                    renderItem: function (params, api) {//具体实现自定义图标的方法
                        if (that.regionsStatistic[params.dataIndex] &&
                            that.regionsStatistic[params.dataIndex].value &&
                            geoCoordMapOfProv[that.regionsStatistic[params.dataIndex].china_adc])
                        {
                            return {
                                type: 'text',
                                style: {
                                    text: that.regionsStatistic[params.dataIndex].name + ':' + that.regionsStatistic[params.dataIndex].value,
                                    fill: '#f8f8f8',
                                    font: 'bolder 1em "Microsoft YaHei",sans-serif',
                                    stroke: '#46bee9',
                                    x: api.coord(geoCoordMapOfProv[that.regionsStatistic[params.dataIndex].china_adc])[0],
                                    y: api.coord(geoCoordMapOfProv[that.regionsStatistic[params.dataIndex].china_adc])[1],
                                },
                                styleEmphasis: {
                                    text: that.regionsStatistic[params.dataIndex].name + ':' + that.regionsStatistic[params.dataIndex].value,
                                    fill: '#f8f8f8',
                                    font: 'bolder 2em "Microsoft YaHei",sans-serif',
                                    stroke: '#46bee9',
                                    x: api.coord(geoCoordMapOfProv[that.regionsStatistic[params.dataIndex].china_adc])[0],
                                    y: api.coord(geoCoordMapOfProv[that.regionsStatistic[params.dataIndex].china_adc])[1],
                                },
                            }
                        }
                        return null

                    },
                    data: that.regionsStatistic,
                },
            ],
        }
    };
    currentOption = this.defaultOptionFun();

    processedUpdate = false; // 已处理过的原始数据更新标记

    state = {
        // 强制刷新
        refresh: false,
        // 组件的提示文字
        tip: '',
        // 鼠标悬浮的节点
        nodeOnMouseHover: undefined,
        // 列表展示的nodes
        nodesForList: [],
        // 列表展示的省名
        titleForList: '',
    };

    // componentWillUnmount() {}

    componentDidMount() {
        console.log('MapNav->componentDidMount-> this.currentOption=', this.currentOption);
        // map
        this.container = document.getElementById(this.containerId);
        this.container.addEventListener("click", () => {
            // 重置地图
            if (this.props.bus.chinaADCFilter !== '000000') {
                this.props.bus.chinaADCFilter = '000000';
                this.props.bus.lastUpdate = Math.random();
            }
        });

        let that = this;
        // 加载地图json文件
        // echarts.registerMap('China', ChinaMapJson);
        // 基于准备好的dom，初始化echarts实例
        this.myChart = echarts.init(this.container);
        // 初始背景图
        this.myChart.setOption(this.currentOption);
        // 地图鼠标响应
        this.myChart.on('click', function (params) {
            // console.log('MapNav->myChart->click-> 鼠标点击了echarts params=', params)
            // 阻止冒泡
            window.event.cancelBubble ? window.event.cancelBubble = true : window.event.stopPropagation();
        });
        this.myChart.on('click', 'series.map', function (params) {
            console.log('MapNav->myChart->click series.map-> 鼠标点击地图 params=', params);
            // 取消选择的节点
            that.setState({
                nodeOnMouseHover: undefined,
            });
            console.log('MapNav->myChart->click series.map-> 鼠标点击的省份是 simplifyProv(params.name, true)=', simplifyProv(params.name, true))

            // 传行政区划代码
            that.props.bus.chinaADCFilter = params.data['china_adc'];
            that.props.bus.lastUpdate = Math.random();
        });
        this.myChart.on('mouseover', 'series.map', function (params) {
            // console.log('MapNav->myChart->mouseover series.map-> 鼠标悬浮在地图上，省份是 params=', params)
        });

        // 节点鼠标响应
        this.myChart.on('mouseout', 'series.scatter', function (params) {
            /*
                        that.setState({
                            nodeOnMouseHover: undefined
                        })
            */
        });
        this.myChart.on('mouseover', 'series.scatter', function (params) {
            // this.myChart.on('mouseover', {seriesIndex: 1, name: 'xx'}, function (params) {
            // this.myChart.on('mouseover', function (params) {
            // this.myChart.on('click', function (params) {
            // todo:跟随鼠标显示提示框

            // 数据列鼠标悬浮
            that.setState({
                nodeOnMouseHover: params.data && params.data.node ? params.data.node : undefined,
            }, () => {
                params.data && params.data.node ? that.focusNode(params.data.node) : null;
                let c = that.container.getClientRects();
                showAsTip(that.elNodeInfoCardId, {x: 0, y: -c[0].top}); // 设置跟随鼠标移动
            })

        });

        //清理数据，只留下有经纬度的数据
        this.nodesHasPositionArray = this.props.bus.nodes;
        this.switchToRegion({chinaADC: this.props.bus.chinaADCFilter})
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        console.log('MapNav->componentDidUpdate->prevProps：', prevProps);
        if (this.processedUpdate !== this.props.bus.statisticsUpdated) {
            this.processedUpdate = this.props.bus.statisticsUpdated;
            this.nodesHasPositionArray = this.props.bus.nodes;
            // 画图
            this.switchToRegion({chinaADC: this.props.bus.chinaADCFilter})
        }
    }

    /**
     * 画全部节点
     * @param {array} nodes 含有有效分类字段值的节点数组
     * @param {string} positionPath 含有有效分类字段值的节点数组
     */
    drawAll = (nodes, positionPath = this.props.options.regionFilterField) => {
        this.statisticMax = 50;
        this.regionsStatistic = Object.keys(this.props.bus.statistics.chinaADC).map(chinaADC => {
            this.statisticMax = Math.max(this.statisticMax, this.props.bus.statistics.chinaADC[chinaADC].amount);
            return {
                name: this.props.bus.statistics.chinaADC[chinaADC].shortName,
                china_adc: chinaADC,
                value: this.props.bus.statistics.chinaADC[chinaADC].amount,
            };
        });
        let that = this;
        // 转换成echarts的数据格式
        const convertData = function (nodes) {
            let res = [];
            if (Array.isArray(nodes)) {
                nodes.forEach(node => {
                    //position值 转为数组
                    let value = [];
                    let position = getObjectValue(node, positionPath);
                    if (position) {
                        if (!Array.isArray(position)) {
                            value = position.split(',')
                        } else {
                            value = position
                        }
                    } else {
                        value = [0, 0]
                    }
                    if (value.length > 1) {
                        res.push({
                            name: node.fname,
                            value: [value[0], value[1]],
                            node: node,
                        })
                    } else {
                        console.log('MapNav->drawAll->convertData->节点转换的坐标不正确：', node)
                    }
                })
            }
            return res;
        };

        this.statisticMax = Math.ceil(this.statisticMax / 50) * 50;
        this.statisticMin ? this.currentOption.visualMap.min = this.statisticMin : this.currentOption.visualMap.min = 0; // 视觉数据对应的最小值,默认值0
        this.statisticMax && this.statisticMax > 0 ? this.currentOption.visualMap.max = this.statisticMax : this.currentOption.visualMap.max = 50; // 视觉数据对应的最大值,默认值50

        this.currentOption.series.forEach((serie, index) => {
            if (serie.name === '区域') {
                this.currentOption.series[index].data = this.regionsStatistic; // 地图各省颜色
            } else if (serie.name === '产业资源') {
                // this.currentOption.series[1].data = convertData(this.nodesHasPositionArray) // 打点数据
                // this.currentOption.series[1].data = convertData(this.state.nodesForList) // 打点数据
                this.currentOption.series[index].data = convertData(nodes) // 打点数据
            } else if (serie.name === '统计数据') {
                this.currentOption.series[index].data = this.regionsStatistic; // 地图各省显示统计数据
            }
        });
        // console.log('MapNav->drawAll->this.currentOption：', this.currentOption)

        that.myChart.setOption(this.currentOption);

        // 强制刷新
        this.setState({
            refresh: !this.state.refresh,
        })
    };

    // --- 交互-----------

    /**
     * 重置地图到全国状态
     */
    reset = () => {
        this.myChart.dispatchAction({
            type: 'downplay',
            // 可选，系列 index，可以是一个数组指定多个系列
            // seriesIndex?: number|Array,
            // 可选，系列名称，可以是一个数组指定多个系列
            seriesName: ['区域', '产业资源', '统计数据'],
            // 可选，数据的 index
            // dataIndex?: number,
            // 可选，数据的 名称
            // name?: string
        });

        // 取消选择的节点
        this.setState({
            nodeOnMouseHover: undefined,
        }, () => {
            // 清空列表内的数据
            /*this.state.titleForList = null;
            this.state.nodesForList = [];*/

            // console.log('MapNav->reset->this.currentOption：', this.currentOption)

            // 地图恢复到全中国
            if (this.currentOption && this.currentOption.geo) {
                this.currentOption.geo.center = geoCoordMapOfChina; // 中心坐标
                this.currentOption.geo.zoom = 1 // 缩放等级
            }

            // 区域内的节点 画图
            this.drawAll(this.nodesHasPositionArray);
        })
    };

    /**
     * 对全部数据，切换到某个区域
     * 关于省份名字：数据过滤用的是全称，echarts触发用的是短名称
     */
    switchToRegion = ({chinaADC}) => {
        if (chinaADC === undefined && chinaADC === null) { //
            message.warning('请选择一个区域！')
        }

        // 设置区域名称
        this.setState({
            titleForList: chinaADC === unknownChinaADC ? chinaProvinceADCMap[unknownChinaADC] : simplifiedChinaProvinceADCMap[chinaADC],
            nodesForList: this.nodesHasPositionArray,
        });

        if (chinaADC === '000000' || chinaADC === unknownChinaADC) {// 例外
            this.reset();
            return null;
        }

        /*if (this.state.nodesForList.length === 0) {
            message.warning('暂时没有找到' + this.state.titleForList + '内的资源，请尝试其他地区。')
        }*/

        // 设置放大地图区域
        if (geoCoordMapOfProv[chinaADC]) {
            this.currentOption.geo.center = geoCoordMapOfProv[chinaADC];
            this.currentOption.geo.zoom = geoCoordMapOfProv[chinaADC][2];
        } else {
            this.currentOption.geo.center ? delete this.currentOption.geo.center : null;
            this.currentOption.geo.zoom = 1;
        }

        // 区域内的节点 画图
        this.drawAll(this.state.nodesForList);
        // 地图对应的动作，目前只能对应省份
        this.myChart.dispatchAction({
            type: 'downplay',
            // 可选，系列 index，可以是一个数组指定多个系列
            // seriesIndex?: number|Array,
            // 可选，系列名称，可以是一个数组指定多个系列
            seriesName: ['区域', '统计数据'],
            // 可选，数据的 index
            // dataIndex?: number,
            // 可选，数据的 名称
            // name?: string
        });
        this.myChart.dispatchAction({
            type: 'highlight',
            // 可选，系列 index，可以是一个数组指定多个系列
            // seriesIndex?: number|Array,
            // 可选，系列名称，可以是一个数组指定多个系列
            seriesName: ['区域', '统计数据'],
            // seriesName: ['统计数据'],
            // 可选，数据的 index
            // dataIndex?: number,
            // 可选，数据的 名称
            name: simplifiedChinaProvinceADCMap[chinaADC], // 目前只支持省
        });
    };

    // 地图上高亮节点
    focusNode = (node, isFocus = true) => {
        this.myChart.dispatchAction({
            type: 'downplay',
            // 可选，系列 index，可以是一个数组指定多个系列
            // seriesIndex?: number|Array,
            // 可选，系列名称，可以是一个数组指定多个系列
            seriesName: ['产业资源'],
            // 可选，数据的 index
            // dataIndex?: number,
            // 可选，数据的 名称
            // name?: string
        });
        if (isFocus === true) this.myChart.dispatchAction({
            type: 'highlight',
            // 可选，系列 index，可以是一个数组指定多个系列
            // seriesIndex?: number|Array,
            // 可选，系列名称，可以是一个数组指定多个系列
            seriesName: '产业资源',
            // 可选，数据的 index
            // dataIndex?: number,
            // 可选，数据的 名称
            name: node.fname,
        })
    };

    render() {
        console.log('MapNav->render->this.props=', this.props);
        const {mode, link, linkTip} = this.props;
        const {tip, nodeOnMouseHover, nodesForList, titleForList} = this.state;

        // map 的 classname
        let mapClassName = mode ? style['map'] + ' ' + style[`map-${mode}`] : style['map'] + ' ' + style['map-default'];

        let listHtml = [];
        nodesForList.forEach((node, i) => {
            listHtml.push(
                <Popover
                    key={"statistic" + i}
                    placement="leftTop"
                    overlayClassName={style['node-info'] + ' dark-theme'}
                    content={
                        <div>
                            <NodeInfoCard
                                className={style['info-card-popover']}
                                readonly={this.props.readonly}
                                node={new Node(node)}
                                onAddToGraph={this.props.onAddToGraph}
                                onRemoveFromGraph={this.props.onRemoveFromGraph}
                                onExplore={this.props.onExplore}
                                onVote={this.props.onVote}
                            />
                        </div>
                    }>
                    <div
                        className={style['line-list'] + ' ' + lineStyle['mouse-over']}
                        onMouseOver={() => {
                            this.focusNode(node) // 地图上高亮节点
                        }}
                        onMouseLeave={() => {
                            this.focusNode(node, false) // 取消地图上高亮节点
                        }}
                        title={{/*getNodeDisplayTitle(node)*/}}
                    >
                        {/*清明节样式*/}
                        {/*<span className={style['line-hd']}></span>*/}
                        <span className={lineStyle['line-bd']}>{getNodeDisplayTitle(node, 20)}</span>
                        {/*<span className={style['line-ft']}></span>*/}
                    </div>
                </Popover>
            )
        });

        return (
            <div
                className={this.props.className + ' ' + style['inside']}
                style={this.props.style}
            >
                {
                    link ? (
                      <Tooltip placement="top" title={linkTip}>
                          <div
                            style={{
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                width: '100%',
                                height: '100%',
                                cursor: 'pointer',
                                zIndex: '1000',
                            }}
                            onClick={link}
                          />
                      </Tooltip>
                    ) : null
                }

                <span
                    style={{
                        position: 'absolute',
                        top: '-25px',
                        left: '10px',
                        textAlign: 'center',
                    }}
                >{tip}</span>
                <div className={mapClassName}>
                    <div
                        id='MapNavEchartsMapId'
                        style={{width: '100%', height: '100%'}}
                    >
                    </div>
                </div>

                <div className={style['visual-list']} style={{zIndex: 'unset'}}>
                    <div className={lineStyle['vertical-container']}>
                        {
                            titleForList && listHtml.length > 0 ? (
                              <div className={lineStyle['list-title']}>
                                  {titleForList}找到资源 {listHtml.length} 条：
                              </div>
                            ) : null
                        }
                        {
                            listHtml.length > 0 ? (
                                <div className={lineStyle['list-content'] + '  scrollbar scrollbar-none'}>
                                    {listHtml.map(i => i)}
                                </div>
                            ) : null
                        }
                    </div>
                </div>

                {
                    !nodeOnMouseHover ? null : (
                      <div
                        id={this.elNodeInfoCardId}
                        className={style['info-card-float']}
                        onMouseLeave={() => {
                            // 取消选择的节点
                            this.setState({
                                nodeOnMouseHover: undefined,
                            })
                        }}
                      >
                          <NodeInfoCard
                            style={{padding: 0}}
                            readonly={this.props.readonly}
                            node={nodeOnMouseHover}
                            onAddToGraph={this.props.onAddToGraph}
                            onRemoveFromGraph={this.props.onRemoveFromGraph}
                            onExplore={this.props.onExplore}
                            onVote={this.props.onVote}
                          />
                      </div>
                    )
                }

            </div>

        )
    }
}

MapNav.propTypes = {
    style: PropTypes.object, // 样式
    readonly: PropTypes.bool, // 传递节点的只读状态
    mode: PropTypes.oneOf(['default', 'nav']), // 组件的模式：nav=导航状态，normal=正常状态
    className: PropTypes.string,// 组件的外部容器的 classname
    // 传入的配置项
    options: PropTypes.shape({
        filterField: PropTypes.string, // 过滤数据字段
        classifyField: PropTypes.string, // 分类字段
    }),
    link: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.bool,
    ]),// nav 模式时点击响应函数
    linkTip: PropTypes.string,// nav 模式时跳转链接的文字提示
    noticeAsEmpty: PropTypes.func, // 最终绘图时，如结果为空，则通知上级组件
    bus: PropTypes.instanceOf(FilterAndStatisticsBus).isRequired,
    onExplore: PropTypes.func.isRequired,
    onVote: PropTypes.func.isRequired,
};

MapNav.defaultProps = {
    style: {}, // 样式
    readonly: true,
    mode: 'default',
    className: null,
    options: {
        // 区域维度
        regionFilterField: 'meta.position', // 判断数据值
        regionClassifyField: 'meta.prov', // 区域名称

        // 时间维度
        datetimeClassifyField: 'meta.create_date',
    },
    link: false,
    linkTip: null,
    noticeAsEmpty: () => {
        console.log('MapNav->defaultProps->noticeAsEmpty emited')
    },
};


export default MapNav;
