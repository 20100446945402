import React from 'react';
import { Button, Input, List, Modal, Progress, Steps, Tooltip } from 'antd';

import PropTypes from 'prop-types';
import ReactECharts from 'echarts-for-react';
import eCharts from 'echarts/lib/echarts';

import PB, { SimplePB } from '@/libs/simplePB';
import ViewDataProvider from '@/components/common/dataProvider/common.dataProvider.view';

// 不同类型关系名称
const RELATION_NAME = {
	'work': '同事', // 工作对应同事关系
	'school': '校友', // 学校对应校友关系
	'ht': '籍贯', // hometown，家乡对应籍贯
};

// 不同类型关系线条的颜色
const RELATION_LINE_COLORS = {
	'work': '#85d6f7', // 工作对应同事关系
	'school': '#D48265', // 学校对应校友关系
	'ht': '#74A083', // hometown，家乡对应籍贯
};

// 关系类型
const RELATION_TYPES = Object.keys(RELATION_NAME);

class multiRelationShip extends React.Component {
	eChartsRef = undefined;

	initColor = ["#C23530", "#2F4554", "#61A0A8", "#D48265", "#90C8AD", "#74A083", "#BEA299", "#6E7074", "#C4CCD3", "#85d6f7"];

	state = {
		graph: {
			nodes: [],
			edges: [],
			categories: [],
		},
	};

	getOption = (graph) => ({
		/*		title: {
					text: 'Les Miserables',
					subtext: 'Circular layout',
					top: 'bottom',
					left: 'right',
				},*/
		tooltip: {},
		/*legend: [{
			// selectedMode: 'single',
			data: graph.categories.map(function (a) {
				return a.name;
			}),
		}],*/
		animationDurationUpdate: 1500,
		animationEasingUpdate: 'quinticInOut',
		series: [
			{
				name: '节点信息',
				type: 'graph',
				layout: 'circular',
				data: graph.nodes,
				links: graph.edges,
				categories: graph.categories,
				roam: true,
				circular: {
					rotateLabel: true,
				},
				focusNodeAdjacency: true,
				label: {
					normal: {
						position: 'center',
						formatter: '{b}',
					},
				},
				lineStyle: {
					normal: {
						width: 0.5,
						curveness: 0.3,
					},
				},
				emphasis: {
					lineStyle: {
						width: "3",
					},
				},
			},
		],
	});

	doLoadGraphData = () => {
		// me.props.viewDataProvider.loadRankData(type).then(data => {
		// });

	};

	componentDidMount() {
		let me = this;
		let getData = {
			persons: [{
				"id": "ac9858d1-4010-4819-ae74-f9249d2e3898",
				"fname": "宝色股份 曹先博",
				"org": "宝色股份",
				"relation": {"work": ["宝色股份"], "school": ["南京林业大学"]},
			}, {
				"id": "68acc0cc-6c12-4dfc-9b77-fd912b1de08d",
				"fname": "宝色股份 丁忠杰",
				"org": "宝色股份",
				"relation": {"work": ["宝色股份"], "school": ["陕西工商管理硕士学院"]},
			}, {
				"id": "e2c615ea-49f2-4f60-8a3d-e96910076672",
				"fname": "宝色股份 杜永勤",
				"org": "宝色股份",
				"relation": {"work": ["宝色股份"], "school": ["西安建筑科技大学"]},
			}, {
				"id": "ffea71cb-f0b8-457a-ba12-5247cbcd966c",
				"fname": "宝色股份 高颀",
				"org": "宝色股份",
				"relation": {"work": ["宝色股份"], "school": ["中南工业大学"]},
			}, {
				"id": "e9aad522-9281-4a91-a7e7-900739a39daa",
				"fname": "宝色股份 季为民",
				"org": "宝色股份",
				"relation": {"work": ["宝色股份"], "school": ["西北工业大学"]},
			}, {
				"id": "58340e31-339a-493c-8a0b-2bf135591089",
				"fname": "宝色股份 蒋建华",
				"org": "宝色股份",
				"relation": {"work": ["宝色股份"], "school": ["上海财经大学", "南京农业大学", "南京审计大学"]},
			}, {
				"id": "c36cef1d-78c1-49fa-9266-9e2918f814d6",
				"fname": "宝色股份 李金让",
				"org": "宝色股份",
				"relation": {"work": ["宝色股份"], "school": ["西安交通大学"]},
			}, {
				"id": "356d29d4-f593-4319-bdef-c9af1ecbe76b",
				"fname": "宝色股份 李向军",
				"org": "宝色股份",
				"relation": {"work": ["宝色股份"], "school": ["山西师范大学"]},
			}, {
				"id": "2a469f6b-f621-428e-926e-392e06604a94",
				"fname": "宝色股份 刘鸿彦",
				"org": "宝色股份",
				"relation": {"work": ["宝色股份"], "school": ["西北工业大学"]},
			}, {
				"id": "7e583403-6fe4-4270-90c0-4c9438392383",
				"fname": "宝色股份 刘义忠",
				"org": "宝色股份",
				"relation": {"work": ["宝色股份"], "school": ["中国科学院"]},
			}, {
				"id": "e711b905-ac18-4f35-b408-1dc11cf63f01",
				"fname": "宝色股份 任建新",
				"org": "宝色股份",
				"relation": {"work": ["宝色股份"], "school": ["第二炮兵工程学院"]},
			}, {
				"id": "988812f9-7a94-4ab6-ac52-eb7aa0951b03",
				"fname": "宝色股份 宋玉杰",
				"org": "宝色股份",
				"relation": {"work": ["宝色股份"], "school": ["西北农林科技大学"]},
			}, {
				"id": "88008081-86d3-47c9-a0b3-be6556c85f67",
				"fname": "宝色股份 唐支翔",
				"org": "宝色股份",
				"relation": {"work": ["宝色股份"], "school": ["中南工业大学"]},
			}, {
				"id": "4bc2aeb7-7a02-43a9-b0bf-3a9a679a2c3a",
				"fname": "宝色股份 王俭",
				"org": "宝色股份",
				"relation": {"work": ["宝色股份"], "school": ["东北工学院"]},
			}, {
				"id": "2d81d7eb-360e-49d1-b4b7-6a2f6e6ff096",
				"fname": "宝色股份 王耀锋",
				"org": "宝色股份",
				"relation": {"work": ["宝色股份"], "school": ["东北大学"]},
			}, {
				"id": "bc3a3a36-032e-4a12-b55b-a56c98e6df10",
				"fname": "宝色股份 吴丕杰",
				"org": "宝色股份",
				"relation": {"work": ["宝色股份"], "school": ["兰州理工大学"]},
			}, {
				"id": "571d6d1b-6121-4366-8833-5821c8145216",
				"fname": "宝色股份 曾庆军",
				"org": "宝色股份",
				"relation": {"work": ["宝色股份"], "school": ["东南大学", "江苏科技大学", "赴加拿大西安大略大学"]},
			}, {
				"id": "6ec4d464-89ad-4566-bcd4-17efad8e0ccb",
				"fname": "宝色股份 赵彬",
				"org": "宝色股份",
				"relation": {"work": ["宝色股份"], "school": ["西北大学"]},
			}, {
				"id": "dbf40a5e-0163-4ea7-8cf0-51e1e4a42cf6",
				"fname": "炬图 辛柯俊",
				"org": "炬图",
				"relation": {"work": ["炬图"], "school": ["东南大学"], "ht": ["扬州", "江苏省"]},
			}, {
				"id": "5b0fad51-7c6e-428f-acd9-45b0e40c7a28",
				"fname": "国电南自 陈晓彬",
				"org": "国电南自",
				"relation": {"work": ["国电南自"], "school": ["贵州工学院"]},
			}, {
				"id": "9fe782a5-6c2e-46be-b520-b7368d007249",
				"fname": "国电南自 狄小华",
				"org": "国电南自",
				"relation": {"work": ["国电南自"], "school": ["中国政法大学", "南京大学"]},
			}, {
				"id": "1e444fd9-7065-4554-a8bc-cbb574d1987d",
				"fname": "国电南自 郭效军",
				"org": "国电南自",
				"relation": {"work": ["国电南自"], "school": ["华中理工大学"]},
			}, {
				"id": "3b732695-3018-4c7d-be75-55027ce9571c",
				"fname": "国电南自 解宏松",
				"org": "国电南自",
				"relation": {"work": ["国电南自"], "school": ["华北电力大学"]},
			}, {
				"id": "383e2394-b1ab-4f11-989c-ab155cea8af4",
				"fname": "国电南自 刘伟",
				"org": "国电南自",
				"relation": {"work": ["国电南自"], "school": ["西安交通大学"]},
			}, {
				"id": "e41ddb65-0afb-401b-a3e7-a0e64ad6e03a",
				"fname": "国电南自 刘颖",
				"org": "国电南自",
				"relation": {"work": ["国电南自"], "school": ["扬州大学"]},
			}, {
				"id": "7b8cb911-7a5f-4e42-be2d-4c90978fbad2",
				"fname": "国电南自 王凤蛟",
				"org": "国电南自",
				"relation": {"work": ["国电南自"], "school": ["哈尔滨船舶工程学院"]},
			}, {
				"id": "a00f9555-e458-4256-96d0-6eccf9fc841c",
				"fname": "国电南自 杨淑娥",
				"org": "国电南自",
				"relation": {"work": ["国电南自"], "school": ["东北财经大学", "西安交通大学", "上海对外经贸大学"]},
			}, {
				"id": "3dfc3666-e2d4-45fe-b66e-073d007e5486",
				"fname": "国电南自 张建华",
				"org": "国电南自",
				"relation": {"work": ["国电南自"], "school": ["华北电力大学"]},
			}, {
				"id": "eb68c4bc-a738-456a-a09e-1ac4de9bc1f5",
				"fname": "国防科技大学 胡友彬",
				"org": "国防科技大学",
				"relation": {"work": ["国防科技大学"], "school": ["东南大学", "国防科技大学"], "ht": ["江苏建湖"]},
			}, {
				"id": "8e13ee2d-cfb2-41eb-ba99-30214c81c893",
				"fname": "航天晨光 弓兴隆",
				"org": "航天晨光",
				"relation": {"work": ["航天晨光"], "ht": ["山西兴县"]},
			}, {
				"id": "18944fcc-7da6-4948-ba42-c3597990264a",
				"fname": "航天晨光 王镭",
				"org": "航天晨光",
				"relation": {"work": ["航天晨光"], "school": ["北京理工大学"], "ht": ["山东曹县"]},
			}, {
				"id": "4fcb86fb-3556-4454-bbd2-959de59ddde7",
				"fname": "航天晨光 王清胤",
				"org": "航天晨光",
				"relation": {"work": ["航天晨光"], "ht": ["内蒙古赤峰"]},
			}, {
				"id": "40cc1bc4-588f-4a85-8f1d-e6fb65bd1686",
				"fname": "航天晨光 魏志刚",
				"org": "航天晨光",
				"relation": {"work": ["航天晨光"], "ht": ["江苏南京"]},
			}, {
				"id": "41123d03-eecb-4886-b118-d2cad7c000d6",
				"fname": "红太阳 涂勇",
				"org": "红太阳",
				"relation": {"work": ["红太阳"], "school": ["南京大学", "上海交通大学"]},
			}, {
				"id": "943c79de-761c-4de0-983a-ec959d09786d",
				"fname": "红太阳 杨寿海",
				"org": "红太阳",
				"relation": {"work": ["红太阳"], "school": ["东南大学"], "ht": ["江苏南京"]},
			}, {
				"id": "c2de0cb4-50f4-4d4b-a72e-501d9e499311",
				"fname": "金智科技 郭伟",
				"org": "金智科技",
				"relation": {"work": ["金智科技"], "school": ["东南大学"]},
			}, {
				"id": "d7f4de43-13d8-4026-9ac4-8fa04d1c4bf5",
				"fname": "金智科技 贺安鹰",
				"org": "金智科技",
				"relation": {"work": ["金智科技"], "school": ["东南大学"]},
			}, {
				"id": "a52b6a11-4b4d-4863-a0ab-47d31efeca5f",
				"fname": "金智科技 吕云松",
				"org": "金智科技",
				"relation": {"work": ["金智科技"], "school": ["东南大学"]},
			}, {
				"id": "01e54108-eaa1-45a4-b07a-de81090d3fcc",
				"fname": "金智科技 汪进元",
				"org": "金智科技",
				"relation": {"work": ["金智科技"], "school": ["湖北大学", "东南大学"], "ht": ["湖北洪湖"]},
			}, {
				"id": "0a9ac7f4-5a60-4974-beea-7cf0e998d8f7",
				"fname": "金智科技 王姣姣",
				"org": "金智科技",
				"relation": {"work": ["金智科技"], "school": ["南京工程学院"]},
			}, {
				"id": "b37d0356-3550-4e6b-a9b5-359a6bbef4d1",
				"fname": "金智科技 臧胜",
				"org": "金智科技",
				"relation": {"work": ["金智科技"], "school": ["东南大学"]},
			}, {
				"id": "5a4f60d2-35d2-456e-b287-0791293ccde8",
				"fname": "栖霞建设 江劲松",
				"org": "栖霞建设",
				"relation": {"work": ["栖霞建设"], "school": ["南京大学"]},
			}, {
				"id": "6cb28b58-8fa4-4e9a-81f9-98dc6c1ed9da",
				"fname": "栖霞建设 陆阳俊",
				"org": "栖霞建设",
				"relation": {"work": ["栖霞建设"], "ht": ["江苏兴化"]},
			}, {
				"id": "b417a09d-af65-4808-9fe6-1366026bbb6b",
				"fname": "三宝科技 沙敏",
				"org": "三宝科技",
				"relation": {"work": ["三宝科技"], "school": ["东南大学"], "ht": ["扬州", "江苏省"]},
			}, {
				"id": "2882e2a3-b4db-4869-b256-fa827fab0aab",
				"fname": "苏宁易购 孙为民",
				"org": "苏宁易购",
				"relation": {"work": ["苏宁易购"], "school": ["北京师范大学", "南京理工大学"]},
			}, {
				"id": "3978b92c-9766-44d8-ab14-db59f50bf8d6",
				"fname": "苏宁易购 张近东",
				"org": "苏宁易购",
				"relation": {"work": ["苏宁易购"], "school": ["美国宾州州立大学"]},
			}, {
				"id": "32831e28-aec0-448e-a0bb-24f95bc18dff",
				"fname": "我乐家居 刘家雍",
				"org": "我乐家居",
				"relation": {"work": ["我乐家居"], "school": ["美国密苏里大学", "刘先生曾任台湾东吴大学"]},
			}, {
				"id": "4b484f89-4156-47df-aca2-1019102d7ee8",
				"fname": "我乐家居 汪春俊",
				"org": "我乐家居",
				"relation": {"work": ["我乐家居"], "school": ["中国人民大学"]},
			}, {
				"id": "d7e3fff1-a3e1-47fc-9e07-7eec1b808110",
				"fname": "我乐家居 张磊",
				"org": "我乐家居",
				"relation": {"work": ["我乐家居"], "ht": ["江苏南京"]},
			}, {
				"id": "589c66b3-615f-4846-aa14-47c20ac23cb9",
				"fname": "我乐家居 张琪",
				"org": "我乐家居",
				"relation": {"work": ["我乐家居"], "ht": ["江苏南京"]},
			}],
		};
		let personNodes = [], personEdges = [];
		let categories = getData.persons.map((node, index) => ({name: `${node.fname.substring(2, 0)}${index}`}));

		// 根据relation将数据分组
		let personSet = {};
		RELATION_TYPES.forEach(relationType => personSet[relationType] = {});
		getData.persons.forEach(item => {
			let relationMap = item.relation;
			RELATION_TYPES.forEach(relationType => {
				relationMap[relationType] && relationMap[relationType].forEach(v => {
					personSet[relationType][v] = personSet[relationType][v] || [];
					personSet[relationType][v].push(item);
				})
			});
		});

		getData.persons.forEach(node => {
			let category = Math.floor((Math.random() * categories.length));
			let color = me.initColor[Math.floor((Math.random() * me.initColor.length))];
			let tempNode = {
				id: node.id,
				name: node.fname,
				symbolSize: 10,
				value: 10,
				label: {
					normal: {
						show: true,
					},
				},
				// x: 20 + i * 28,
				// y: 30 + i * 28,
				attributes: {modularity_class: category},
				category: category,
				itemStyle: {
					normal: {
						// color: color,
					},
				},
				tooltip: {
					trigger: 'item',
					formatter: function (params) {
						let res = params.seriesName + '：<br />';
						res = res + params.marker + ' ' + params.name  + '<br />';
						res = '<div style="padding: 2px 6px;">' + res + '</div>';
						return res;
					},
				},
			};

			// 根据relation将数据分组，分组后的数据进行关系关联
			RELATION_TYPES.forEach(relationType => {
				// 如果当前节点里有当前关系类型情况下
				if (node.relation && node.relation[relationType]) {
					let relationName = RELATION_NAME[relationType];
					node.relation[relationType].forEach(relationValue => {
						// 如果人物集合【当前关系】【当前节点关系值】长度 > 1，即有多个节点，节点之间应有关联关系
						if (personSet[relationType][relationValue].length > 1) {
							Object.values(personSet[relationType][relationValue]).forEach(item => {
								if (item.id !== node.id) {
									let hasExist = false;
									// 是否已经存在关系
									personEdges.forEach(edge => {
										if (edge.source === node.id && edge.target === item.id) {
											edge.name = `${edge.name}，${relationName}：${relationValue}`;
											hasExist = true;
										}
									});
									// 不存在关联关系时
									if (!hasExist) {
										tempNode.symbolSize++;
										tempNode.value++;
										personEdges.push({
											id: `${node.id}|${relationType}|${relationValue}|${item.id}`,
											name: `${relationName}：${relationValue}`,
											source: node.id,
											target: item.id,
											lineStyle: {
												'normal': {
													color: RELATION_LINE_COLORS[relationType], // 同一种类型的关系用同一种颜色
												},
											},
											tooltip: {
												trigger: 'item',
												formatter: function (params) {
													let res = params.data.name; // 似乎应该用data里面的name
													res = '<div style="padding: 2px 6px;">' + res + '</div>';
													return res;
												},
											},
										});
									}
								}
							})
						}
					});
				}
			});
			// tempNode.label.normal.show = tempNode.symbolSize > 15;
			personNodes.push(tempNode);
		});

		me.setState({
			graph: {
				nodes: personNodes,
				edges: personEdges,
				categories,
			},
		});
		// 获取数据
		me.props.bus.sub(me, 'network', 'graph.after_loaded', ({nodes, edges}) => {

		});
	}

	render() {
		let me = this;

		return (
			<Modal
				width={'58rem'}
				title={'多人社会关系'}
				visible={me.props.visible}
				onCancel={() => {
					me.props.onClose();
				}}
				centered={true}
				footer={null}
				bodyStyle={{maxHeight: '52rem', height: '52rem'}}
			>
				<ReactECharts
					echarts={eCharts}
					option={me.getOption(me.state.graph)}
					style={{height: '100%', width: '100%'}}
					ref={e => {
						me.eChartsRef = e;
						if (e) {
							requestAnimationFrame(() => {
								e.getEchartsInstance().setOption(me.getOption(me.state.graph));
							});
						}
					}}
				/>
			</Modal>
		);
	}
}

multiRelationShip.defaultProps = {
	bus: PB,
	visible: true,
	onClose: () => {
	},
};

multiRelationShip.propTypes = {
	bus: PropTypes.instanceOf(SimplePB),
	visible: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	viewDataProvider: PropTypes.instanceOf(ViewDataProvider).isRequired,
};

export default multiRelationShip;