import React from 'react';
import PropTypes from 'prop-types';
import {Button} from "antd";
import _ from 'lodash';

import {defaultDefine, NODE_TYPE_TEXT} from '@/constants/vis.defaultDefine.1';

import Icon from "@/components/common/common.icon";

import style from '@/style/common/node/common.node.iconMart.less';
import {
  ICON_CATEGORY_TEXT,
  ICON_CATEGORY_COMPANY,
  ICON_CATEGORY_TALENT,
  ICON_CATEGORY_PATENT,
  ICON_CATEGORY_PAPER,
  ICON_CATEGORY_POLICY,
  ICON_CATEGORY_ORG,
  ICON_CATEGORY_INSTITUTE,
  ICON_CATEGORY_NEWS_ACTIVITIES,
  ICON_CATEGORY_DATASET,
  ICON_CATEGORY_GOV,
  ICON_CATEGORY_NATURE,
  ICON_CATEGORY_COLLEGE_AND_UNIVERSITY,
  ICON_CATEGORY_PARK,
  ICON_CATEGORY_PROJECT,
  ICON_CATEGORY_PREPARE,
  ICON_CATEGORY_DOING,
  ICON_CATEGORY_FINISH,
  ICON_CATEGORY_FLAG_A,
  ICON_CATEGORY_FLAG_B,
  ICON_CATEGORY_FLAG_C,
  ICON_CATEGORY_FLAG_E,
  ICON_CATEGORY_FLAG_F,
  ICON_CATEGORY_FLAG_G,
  ICON_CATEGORY_TEXT_A,
  ICON_CATEGORY_TEXT_B,
  ICON_CATEGORY_TEXT_C,
  ICON_CATEGORY_TIP_A,
  ICON_CATEGORY_TIP_B,
  ICON_CATEGORY_TIP_C,
  ICON_CATEGORY_TIP_D,
  ICON_CATEGORY_TIP_E,
  ICON_CATEGORY_TIP_F,
  ICON_CATEGORY_TIP_G,
  ICON_CATEGORY_TIP_H,
  ICON_CATEGORY_TAG_A,
  ICON_CATEGORY_TAG_B,
  ICON_CATEGORY_TAG_C,
  ICON_CATEGORY_TAG_D,
  ICON_CATEGORY_TAG_E,
  ICON_CATEGORY_TAG_F,
  ICON_CATEGORY_TAG_G,
  ICON_CATEGORY_TAG_H,
  ICON_CATEGORY_NO_ICON,
  ICON_CATEGORY_GOOD,
  ICON_CATEGORY_BAD,
  ICON_CATEGORY_WATCH,
  iconConfig,
} from "@/constants/iconConfig";

export const iconCfgToIconMartCfg = (cfg) => {
  cfg = _.isObject(cfg) ? cfg : {key: cfg};
  let iconCfg = iconConfig[cfg.key];
  return {
    title: iconCfg.title._default,
    description: `标记为 ${iconCfg.title._default}`,
    value: iconCfg.nodeProperty.lev,
    name: iconCfg.iconProperty.name,
    type: iconCfg.iconProperty.type,
    color: iconCfg.iconProperty.color,
    extra: {
      userPreferredType: iconCfg.nodeProperty.userPreferredType,
      meta: iconCfg.nodeProperty.meta,
    },
    ...cfg,
  };
}

export const nodeIcons = [
  ICON_CATEGORY_TEXT,
  ICON_CATEGORY_GOV,
  ICON_CATEGORY_ORG,
  ICON_CATEGORY_PARK,
  ICON_CATEGORY_COMPANY,
  ICON_CATEGORY_COLLEGE_AND_UNIVERSITY,
  undefined,
  ICON_CATEGORY_INSTITUTE,
  ICON_CATEGORY_TALENT,
  ICON_CATEGORY_PATENT,
  ICON_CATEGORY_PAPER,
  ICON_CATEGORY_DATASET,
  ICON_CATEGORY_NEWS_ACTIVITIES,
  undefined,
  ICON_CATEGORY_NATURE,
  ICON_CATEGORY_POLICY,
  ICON_CATEGORY_PROJECT,
  {
    key: ICON_CATEGORY_PREPARE,
    description: '任务准备开始',
  },
  {
    key: ICON_CATEGORY_DOING,
    description: '任务正在进行',
  },
  {
    key: ICON_CATEGORY_FINISH,
    description: '任务已经完成',
  },
  undefined,
  ICON_CATEGORY_GOOD,
  ICON_CATEGORY_BAD,
  ICON_CATEGORY_WATCH,
  ICON_CATEGORY_TEXT_A,
  ICON_CATEGORY_TEXT_B,
  ICON_CATEGORY_TEXT_C,
  undefined,
  ICON_CATEGORY_FLAG_A,
  ICON_CATEGORY_FLAG_B,
  ICON_CATEGORY_FLAG_C,
  ICON_CATEGORY_FLAG_E,
  ICON_CATEGORY_FLAG_F,
  ICON_CATEGORY_FLAG_G,
  undefined,
  ICON_CATEGORY_TIP_F,
  ICON_CATEGORY_TIP_G,
  {
    key: ICON_CATEGORY_TIP_H,
    color: defaultDefine.colors.level0, // 颜色调整
  },
  ICON_CATEGORY_TAG_F,
  ICON_CATEGORY_TAG_G,
  {
    key: ICON_CATEGORY_TAG_H,
    color: defaultDefine.colors.level0, // 颜色调整
  },
  undefined,
  ICON_CATEGORY_TIP_C,
  ICON_CATEGORY_TIP_D,
  ICON_CATEGORY_TIP_E,
  ICON_CATEGORY_TAG_C,
  ICON_CATEGORY_TAG_D,
  ICON_CATEGORY_TAG_E,
  undefined,
  {
    key: ICON_CATEGORY_TIP_A,
    color: '#777777', // 颜色调整
  },
  {
    key: ICON_CATEGORY_TIP_B,
    color: undefined, // 颜色调整
  },
  ICON_CATEGORY_NO_ICON,
  {
    key: ICON_CATEGORY_TAG_A,
    color: '#777777', // 颜色调整
  },
  {
    key: ICON_CATEGORY_TAG_B,
    color: undefined, // 颜色调整
  },
  undefined
].map(cfg => cfg ? iconCfgToIconMartCfg(cfg) : cfg);

export default class IconMart extends React.Component {
  state = {
    current: undefined,
  };

  mouseLeaveTimeout = undefined;

  render() {
    let me = this;
    let description = '请选择一个标记';
    if (me.state.current) {
      description = me.state.current.description || me.state.current.title || description;
    }

    return (
      <div className={style['icon-mart']}>
        <ul>
          {
            nodeIcons.map((cfg, idx) => cfg ? (
              <li
                key={`icon-${cfg.value}-${idx}`}
              >
                <Button
                  shape={'circle'}
                  style={{'--original-color': cfg['color'] || undefined}}
                  onClick={() => me.props.onIconClicked(cfg)}
                  onMouseEnter={() => {
                    if (me.mouseLeaveTimeout) clearTimeout(me.mouseLeaveTimeout);
                    me.mouseLeaveTimeout = undefined;
                    me.setState({current: cfg});
                  }}
                  onMouseLeave={() => {
                    if (me.mouseLeaveTimeout) clearTimeout(me.mouseLeaveTimeout);
                    me.mouseLeaveTimeout = setTimeout(() => {
                      if (me.state.current === cfg) {
                        me.setState({current: undefined});
                      }
                    }, 200);
                  }}
                >
                  <Icon {...cfg} />
                </Button>
              </li>
            ) : <br key={`br-${idx}`} />)
          }
          {
            me.props.customIcons.map(({iconData, iconShape, iconRectHeight, iconRectWidth}, idx) => {
              let cfg = {
                title: '自定义图标',
                description: `标记为 自定义图标`,
                value: 'gradeD',
                extra: {
                  meta: {
                    userPreferredType: NODE_TYPE_TEXT,
                    iconData,
                    iconMeta: {
                      shape: iconShape,
                      rectHeight: iconRectHeight,
                      rectWidth: iconRectWidth,
                    }
                  }
                },
              };
              return (
                <li key={`icon-custom-${idx}`}>
                  <Button
                    shape={'circle'}
                    onClick={() => me.props.onIconClicked(cfg)}
                    onMouseEnter={() => {
                      if (me.mouseLeaveTimeout) clearTimeout(me.mouseLeaveTimeout);
                      me.mouseLeaveTimeout = undefined;
                      me.setState({current: cfg});
                    }}
                    onMouseLeave={() => {
                      if (me.mouseLeaveTimeout) clearTimeout(me.mouseLeaveTimeout);
                      me.mouseLeaveTimeout = setTimeout(() => {
                        if (me.state.current === cfg) {
                          me.setState({current: undefined});
                        }
                      }, 200);
                    }}
                  >
                    <img src={iconData} alt={'自定义图标'} className={iconShape} />
                  </Button>
                </li>
              );
            })
          }
        </ul>
        <div className={style['icon-mart-description']}>
          {description}
        </div>
      </div>
    );
  }
};

IconMart.defaultProps = {
  customIcons: [],
};

IconMart.propTypes = {
  onIconClicked: PropTypes.func.isRequired,
  customIcons: PropTypes.array,
};