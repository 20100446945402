import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Axios from 'axios';
import {Radio, Row, Col, Input, Form, Upload, Button, message} from 'antd';

import Icon from '@/components/common/common.icon';
import {IconTypes} from '@/constants/common';

import SystemDataProvider from '@/components/common/dataProvider/common.dataProvider.system';
import {MicroServiceUIConfigTarget} from '@/components/common/view/microService/shape/common.view.microService.shape.uiConfig';

import style from '@/style/common/microService/common.microService.v2.less';

const defaultFormLayout = {
  labelAlign: 'left',
  labelCol: {
    xs: {span: 0},
    sm: {span: 0},
  },
  wrapperCol: {
    xs: {span: 48},
    sm: {span: 24},
  },
};

const FILE_STATUS_STYLE = {
  'done': style['upload-list-item-success'],
  'uploading': style['upload-list-item-processing'],
  'error': style['upload-list-item-error'],
};

class MicroServicePanelTarget extends React.PureComponent {
  state = {
    type: undefined,
    param: {},
    textOrUrl: '',
    fileList: [],

    hasTarget: false,
  };

  mounted = false;

  fileUploadSource = {};

  fileInfoMap = {};

  beforeUpload = file => {
    let me = this;

    const validSize = file.size / 1024 / 1024 < 50;
    if (!validSize) {
      message.error(`请选择小于 50MB 的文件!`);
      file.validated = false;
      return false;
    }

    // 生成cancelToken
    me.fileUploadSource[file.uid] = Axios.CancelToken.source();

    // 更新数据至target
    me.props.onTargetSet('files', me.fileUploadSource, 'fileUploadSource');
    me.props.onTargetSet('files', [...me.state.fileList, file], 'fileList');

    return true;
  };

  customUploadRequest = ({onSuccess, onError, file, onProgress}) => {
    let me = this, cancelToken = me.fileUploadSource[file.uid] ? me.fileUploadSource[file.uid].token : undefined;
    me.props.getBackgroundTargetUpdater().then(({getTarget, setTarget}) => {
      let progressCallback = event => {
        const percent = Math.floor((event.loaded / event.total) * 100);
        onProgress({percent});

        let fileList = getTarget('files', 'fileList') || [], idx = fileList.findIndex(f => f.uid === file.uid);
        if (idx >= 0) {
          fileList[idx].status = 'uploading';
          fileList[idx].percent = percent;
          setTarget('files', 'fileList', fileList);
        }
      };

      SystemDataProvider.uploadTempFile(file, progressCallback, cancelToken).then(data => {
        if (me && me.mounted && me.props.visible) {
          me.fileInfoMap[file.uid] = data[0];
          if (me.fileUploadSource[file.uid]) {
            delete me.fileUploadSource[file.uid];
          }
        }

        // 更新数据，fileInfoMap、fileUploadSource、fileList
        let fileList = getTarget('files', 'fileList') || [],
          fileListIdx = fileList.findIndex(f => f.uid === file.uid),
          fileInfoMap = getTarget('files', 'fileInfoMap'),
          fileUploadSource = getTarget('files', 'fileUploadSource');

        if (fileListIdx >= 0) {
          fileList[fileListIdx].status = 'done';
          fileList[fileListIdx].percent = 100;
          setTarget('files', 'fileList', fileList);
        }

        fileInfoMap[file.uid] = data[0];
        setTarget('files', 'fileInfoMap', fileInfoMap);

        if (fileUploadSource[file.uid]) {
          delete fileUploadSource[file.uid];
          setTarget('files', 'fileUploadSource', fileUploadSource);
        }

        onSuccess("Ok");
      }).catch(() => {
        if (me && me.mounted && me.props.visible) {
          if (me.fileUploadSource[file.uid]) {
            delete me.fileUploadSource[file.uid];
          }
        }

        // 更新数据 fileUploadSource、fileList
        let fileList = getTarget('files', 'fileList') || [],
          fileListIdx = fileList.findIndex(f => f.uid === file.uid),
          fileUploadSource = getTarget('files', 'fileUploadSource');

        if (fileListIdx >= 0) {
          fileList[fileListIdx].status = 'error';
          setTarget('files', 'fileList', fileList);
        }

        if (fileUploadSource[file.uid]) {
          delete fileUploadSource[file.uid];
          setTarget('files', 'fileUploadSource', fileUploadSource);
        }

        onError("Error");
      });
    });
  };

  getTargetContent = () => {
    let me = this, /** @type {TMicroServiceUIConfigTarget} */ config = me.props.config;

    if (config.items.length > 1) {
      return (
        <Form.Item
          label={config.label}
          key={'t-multiple'}
          className={style['parameter-panel']}
        >
          <Radio.Group
            value={me.state.type}
            onChange={e => me.onTargetTypeChange(e.target.value)}
            className={style['target-radio-group']}
            disabled={me.props.locked}
          >
            <Row>
              {config.items.map(target => {
                if (target.type === 'textOrUrl') {
                  return (
                    <React.Fragment>
                      <Col span={24} key={'t-text-or-url-0'}>
                        <Radio className={style['target-radio-item']} value={target.type}>
                          {
                            target.dynamicLabel ? target.dynamicLabel(me.state.param) : target.label
                          }
                        </Radio>
                      </Col>
                      <Col span={24} key={'t-text-or-url-1'}>
                        <Input.TextArea
                          disabled={me.props.locked || me.state.type !== 'textOrUrl'}
                          rows={4}
                          value={me.state.textOrUrl}
                          onChange={e => me.onTargetTextOrUrlChange(e.target.value)}
                          style={{width: '100%', resize: 'none'}}
                        />
                      </Col>
                    </React.Fragment>
                  )
                } else if (target.type === 'files') {
                  return (
                    <Col span={24} key={`t-${_.snakeCase(target.type)}`}>
                      <Radio
                        className={style['target-radio-item']}
                        value={target.type}
                      >
                        {
                          target.dynamicLabel ? target.dynamicLabel(me.state.param) : target.label
                        }
                        <Upload
                          showUploadList={false}
                          beforeUpload={me.beforeUpload}
                          customRequest={me.customUploadRequest}
                          onChange={me.onUploadChange}
                          fileList={me.state.fileList}
                          disabled={me.props.locked || me.state.type !== target.type}
                        >
                          <Button disabled={me.props.locked || me.state.type !== target.type} style={{marginLeft: '0.5rem'}}>
                            <Icon name={'upload'} />
                            请选择文件
                          </Button>
                        </Upload>
                      </Radio>
                    </Col>
                  );
                } else if (target.type !== 'files') {
                  return (
                    <Col span={24} key={`t-${_.snakeCase(target.type)}`}>
                      <Radio
                        className={style['target-radio-item']}
                        value={target.type}
                        disabled={target.type === 'node' && !me.props.currentNodeId}
                      >
                        {
                          target.dynamicLabel ? target.dynamicLabel(me.state.param) : target.label
                        }
                      </Radio>
                    </Col>
                  )
                }
              })}
            </Row>
          </Radio.Group>
        </Form.Item>
      )
    } else if (config.items[0] && config.items[0].type === 'textOrUrl') {
      return (
        <Form.Item
          label={config.label}
          key={'t-text-or-url'}
          className={style['parameter-panel']}
        >
          <Input.TextArea
            disabled={me.props.locked || me.state.type !== 'textOrUrl'}
            rows={4}
            value={me.state.textOrUrl}
            onChange={e => me.onTargetTextOrUrlChange(e.target.value)}
            style={{width: '100%', resize: 'none'}}
            placeholder={
              config.items[0].dynamicLabel ? config.items[0].dynamicLabel(me.state.param)
                : config.items[0].label
            }
          />
        </Form.Item>
      );
    } else if (config.items[0] && config.items[0].type === 'files') {
      return (
        <div key={'t-files'} className={style['parameter-panel']}>
          <div style={{display: 'flex', justifyContent: 'start', alignItems: 'baseline', paddingTop: '7px'}}>
            <label>{config.label}</label>
            <Upload
                showUploadList={false}
                beforeUpload={me.beforeUpload}
                customRequest={me.customUploadRequest}
                onChange={me.onUploadChange}
                fileList={me.state.fileList}
                disabled={me.props.locked}
            >
              <Button disabled={me.props.locked} style={{marginLeft:'2rem'}}>
                <Icon name={'upload'} />
                请选择文件
              </Button>
            </Upload>
          </div>
          <div className={`${style['upload-list']} scrollbar-none`}>
            <div>
              {me.state.fileList.map((file, idx) => (
                <div
                  key={`f-${idx}`}
                  className={`${style['upload-list-item']} ${FILE_STATUS_STYLE[file.status]}`}
                >
                  <div className={style['upload-list-item-info']}>
                    <span>
                      <Icon className={style['upload-list-item-info-icon']} name={'file'} />
                      <span className={style['upload-list-item-info-name']}>
                        {file.name}
                      </span>
                      {
                        me.props.locked ? null : (
                          <span className={style['upload-list-item-info-action']}>
                            <a onClick={() => me.onRemoveFile(file)}>
                              {
                                file.status === 'uploading' ? (
                                  <Icon name={'icon-stop-large'} type={IconTypes.ICON_FONT} />
                                ) : (
                                  <Icon name={'delete'} />
                                )
                              }
                            </a>
                          </span>
                        )
                      }
                    </span>
                  </div>
                  {
                    file.status === 'uploading' ? (
                        <div className={style['upload-list-item-processing-bar']} style={{width: `${file.percent}%`}} />
                    ) : null
                  }
                </div>
              ))}
            </div>
          </div>
        </div>
      );
    } else if (config.items[0]) {
      return (
        <Form.Item
          label={config.label}
          key={`t-${_.snakeCase(config.items[0].type)}`}
          className={style['parameter-panel']}
        >
          <Radio
            className={style['target-radio-item']}
            value={config.items[0].type}
            checked={true}
            disabled={me.props.locked || (config.items[0].type === 'node' && !me.props.currentNodeId)}
            style={{paddingLeft: '1px'}}
          >
            {config.items[0].dynamicLabel ? config.items[0].dynamicLabel(me.state.param) : config.items[0].label}
          </Radio>
        </Form.Item>
      );
    }
  };

  onRemoveFile = file => {
    let me = this, fileList = me.state.fileList;

    if (me.props.locked) {
      return;
    }

    if (file.status === 'uploading') {
      if (me.fileUploadSource[file.uid]) {
        me.fileUploadSource[file.uid].cancel();
      }
    }

    fileList = fileList.filter(f => f.uid !== file.uid);

    if (me.fileUploadSource[file.uid]) {
      delete me.fileUploadSource[file.uid];
      me.props.onTargetSet('files', me.fileUploadSource, 'fileUploadSource');
    }

    if (me.fileInfoMap[file.uid]) {
      delete me.fileInfoMap[file.uid];
      me.props.onTargetSet('files', me.fileInfoMap, 'fileInfoMap');
    }

    me.props.onTargetSet('files', fileList, 'fileList');

    if (me.state.type === 'files') {
      let files = fileList.filter(f => me.fileInfoMap[f.uid]).map(f => me.fileInfoMap[f.uid].id);
      me.props.onTargetSet('$target', {files});
      let hasTarget = files && files.length > 0 && fileList.filter(f => f.status === 'uploading').length === 0;
      if (hasTarget !== me.state.hasTarget) {
        me.props.onHasTargetChanged(hasTarget);
        me.setState({hasTarget});
      }
    }

    me.setState({fileList});
  };

  onTargetDataRefreshed = () => {
    let me = this, targets = me.props.targets, newState = {};
    newState.param = {...targets.$param.value};
    newState.type = targets.$type.value;
    switch (newState.type) {
      case 'node':
      case 'filteredNodes':
        newState.hasTarget = targets.$target && targets.$target.value && targets.$target.value.nodes
          && (targets.$target.value.nodes.length > 0);
        break;
      case 'textOrUrl':
        // 暂时只支持一条文本
        newState.hasTarget = targets.$target && targets.$target.value && targets.$target.value.textOrUrls
          && (targets.$target.value.textOrUrls.length > 0) && (targets.$target.value.textOrUrls[0].length > 0);
        break;
      case 'view':
        newState.hasTarget = targets.$target && targets.$target.value && targets.$target.value.viewId;
        break;
      case 'files':
        newState.hasTarget = false;
        if (targets['files'] && targets['files']['fileList'] && targets['files']['fileList'].length > 0) {
          newState.hasTarget = targets.$target && targets.$target.value && targets.$target.value.files
            && (targets.$target.value.files.length > 0)
            && targets['files']['fileList'].filter(f => f.status === 'uploading').length === 0;
        }
        break;
    }
    newState.hasTarget = !!newState.hasTarget;

    newState.textOrUrl = targets['textOrUrl'] ? targets['textOrUrl'].value : undefined;

    newState.fileList = targets['files'] ? targets['files']['fileList'] : [];
    me.fileUploadSource = targets['files'] ? targets['files']['fileUploadSource'] : {};
    me.fileInfoMap = targets['files'] ? targets['files']['fileInfoMap'] : {};

    if (newState.hasTarget !== me.state.hasTarget) {
      me.props.onHasTargetChanged(newState.hasTarget);
    }
    me.setState(newState);
  };

  onTargetTextOrUrlChange = textOrUrl => {
    let me = this, param = me.state.param, textOrUrls;

    param.textOrUrl = {};

    if (/^[\n\r]*(((https?):\/\/[^\n\r]+)[\n\r]+)*((https?):\/\/[^\n\r]+)[\r\n]*$/.test(textOrUrl)) {
      textOrUrls = _.split(textOrUrl, /[\r\n]/).map(t => t.trim()).filter(t => !!t);
      param.textOrUrl.url = {url: textOrUrl, urlList: textOrUrls};
    } else {
      textOrUrls = [textOrUrl];
      param.textOrUrl.text = {text: textOrUrl, textLength: textOrUrl.length};
    }

    me.setState({textOrUrl, param});
    me.props.onTargetSet('textOrUrl', textOrUrl);
    me.props.onTargetSet('$param', {...param});

    if (me.state.type === 'textOrUrl') {
      me.props.onTargetSet('$target', {textOrUrls});
      let hasTarget = textOrUrl && textOrUrl.length > 0
      if (hasTarget !== me.state.hasTarget) {
        me.props.onHasTargetChanged(hasTarget);
        me.setState({hasTarget});
      }
    }
  };

  onTargetTypeChange = type => {
    let me = this, target, hasTarget = false;

    switch (type) {
      case 'node':
        target = {nodes: [me.props.currentNodeId]};
        hasTarget = !!me.props.currentNodeId;
        break;
      case 'filteredNodes':
        target = {nodes: me.state.param['filteredNodes']['ids']};
        hasTarget = target.nodes.length > 0;
        break;
      case 'textOrUrl':
        target = {textOrUrls: [me.state.textOrUrl]};
        hasTarget = me.state.textOrUrl && me.state.textOrUrl.length > 0;
        break;
      case 'files':
        const files = me.state.fileList.filter(f => me.fileInfoMap[f.uid]).map(f => me.fileInfoMap[f.uid].id);
        target = {files};
        hasTarget = files && files.length > 0 && me.state.fileList.filter(f => f.status === 'uploading').length === 0;
        break;
    }

    me.props.onTargetSet('$target', target);
    me.props.onTargetSet('$type', type);
    if (hasTarget !== me.state.hasTarget) {
      me.props.onHasTargetChanged(hasTarget);
    }
    me.setState({type, hasTarget});
  };

  onUploadChange = ({file, fileList}) => {
    let me = this;

    if (me.mounted && me.props.visible && file.validated !== false) {
      me.setState({fileList});
      me.forceUpdate();

      if (me.state.type === 'files') {
        let files = fileList.filter(f => me.fileInfoMap[f.uid]).map(f => me.fileInfoMap[f.uid].id);
        me.props.onTargetSet('$target', {files});
        let hasTarget = files && files.length > 0 && fileList.filter(f => f.status === 'uploading').length === 0;
        if (hasTarget !== me.state.hasTarget) {
          me.props.onHasTargetChanged(hasTarget);
          me.setState({hasTarget});
        }
      }
    }
  };

  componentDidMount() {
    let me = this;

    if (me.props.targets) {
      me.onTargetDataRefreshed();
    }

    me.mounted = true;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let me = this;

    if (me.props.targets !== prevProps.targets) {
      me.onTargetDataRefreshed();
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  render() {
    let me = this;

    return (
      <Form {...(me.props.config.formLayout || defaultFormLayout)} className={style['target-form']}>
        {me.getTargetContent()}
      </Form>
    );
  }
}

MicroServicePanelTarget.defaultProps = {};

MicroServicePanelTarget.propTypes = {
  config: PropTypes.shape(MicroServiceUIConfigTarget).isRequired,
  currentNodeId: PropTypes.string,
  locked: PropTypes.bool.isRequired,
  visible: PropTypes.bool.isRequired,
  targets: PropTypes.object.isRequired,
  onTargetSet: PropTypes.func.isRequired,
  getBackgroundTargetUpdater: PropTypes.func.isRequired,
  onHasTargetChanged: PropTypes.func.isRequired,
}

export default MicroServicePanelTarget;