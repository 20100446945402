import React from "react";
import {Col, Row, Form, Radio, Card} from "antd";
import style from "@/style/containers/explorationView.less";
import PropTypes from "prop-types";
import ViewDataProvider from '@/components/common/dataProvider/common.dataProvider.view';
import {NetworkEvents, NodeEvents} from "@/libs/view/network/events";
import {
  TYPE_FIELD_NAME,
  getNodeIcon,
} from "@/constants/vis.defaultDefine.1";
import Icon from "@/components/common/common.icon";

@Form.create()
class ExplorationSearchOptionForm extends React.Component {
  state = {
    optResourceType: '',
    optTime: '',
    optArea: [],
  };

  componentDidMount() {
    let me = this;

    me.props.networkRef.with(me)
      .subscribe([NodeEvents.EXPLORING, NetworkEvents.SUB_VIEW_EXPLORING], ({sender}) => {
        if (sender !== me.props.sender) return;
        const options = me.props.getExactMatchOptions();
        me.setState({
          optResourceType: options.types[0],
          optArea: options.areas,
          optTime: options.time,
        });
      });
  }

  componentWillUnmount() {
    this.props.networkRef.unSubscribe(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let me = this;

    if (me.props.optionsUpdated !== prevProps.optionsUpdated) {
      const options = me.props.getExactMatchOptions();
      me.setState({
        optResourceType: options.types[0],
        optArea: options.areas,
        optTime: options.time,
      });
    }
  }

  onSearch = () => {
    this.props.setExactMatchOptions({
      types: [this.state.optResourceType],
      areas: this.state.optArea,
      time: this.state.optTime,
    });
    this.props.onSearch();
  };

  render() {
    let me = this;

    // noinspection RequiredAttributes
    return (
      <Card bordered={false} className={style['result-filters']}>
        <Form>
          <Row type={'flex'} justify={'space-between'} align={'middle'}>
            <Col>
              <Form.Item>
                <Radio.Group name={'time'} value={me.state.optResourceType} buttonStyle={'solid'}
                             onChange={e => me.setState({optResourceType: e.target.value}, () => me.onSearch())}
                             disabled={me.props.processing || me.props.disabled}>
                  {
                    me.props.resourceTypes.map(({label, value}) => {
                      let iconConfig = getNodeIcon({[TYPE_FIELD_NAME]: value});
                      delete iconConfig.color;
                      return (
                        <Radio.Button value={value} key={`opt-${value}`}>
                          <Icon {...iconConfig} style={{marginRight: '0.5rem'}}/>
                          {label}
                        </Radio.Button>
                      );
                    })
                  }
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>
    );
  }
}

ExplorationSearchOptionForm.defaultProps = {
  processing: true,
  disabled: true,
  sender: undefined,
  resourceTypes: [],
};

ExplorationSearchOptionForm.propTypes = {
  networkRef: PropTypes.instanceOf(ViewDataProvider).isRequired,
  onSearch: PropTypes.func.isRequired,
  getExactMatchOptions: PropTypes.func.isRequired,
  setExactMatchOptions: PropTypes.func.isRequired,
  optionsUpdated: PropTypes.number.isRequired,
  processing: PropTypes.bool,
  disabled: PropTypes.bool,
  sender: PropTypes.string,
  resourceTypes: PropTypes.array,
};

export default ExplorationSearchOptionForm;