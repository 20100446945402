import {
    NODE_TYPE_TALENT,
    NODE_TYPE_TEXT,
    getNodeDisplayTitle,
} from '@/constants/vis.defaultDefine.1'
import {myVisNetworkStyle} from '@/libs/myVis/MyNetwork3';

import _ from "lodash";
import moment from "moment";
import {MULTIMEDIA_EXT} from "@/constants/common";

let today = moment().startOf('day');
let monday = moment().startOf('week');
let friday = monday.clone().add(4, 'days');
let lastFriday = friday.clone().subtract(7, 'days');
let daysAfterFriday = today.diff(friday, 'days');

export default class Node {
    constructor({
                    label, id, fname, fnameHtml, sname, name, org, tag, keyStc, type, fixed, lev, status, meta,
                    linkTime, updateTime, position, x, y, fx, fy, userId, prop, listed, hidden, withNodeId,
                    withNodeExtra, keyStcHtml, htmlTags, amount, image, brokenImage, level, hasProps, hasMultimedia,
                    detailLoaded, priority, accessTimestamp, description, url, version, aiGraphRank, aiPreferredType,
                    aiRelatedTo, aiLinkedTo, aiUpdateTime, userPreferredType, userConfirmed, _currentSelect, _lastSelect,
                    _focused, _enableScaling, _priority, _brightness, _brightnessFromAccessTimestamp,
                    _brightnessFromLevel, _previousAccessTimestamp, _presenting, _userFavorite, _viewFavorite,
                    _initialized, _locked, _systemRecommend, _structOnly, _nodeLevel,
                }) {

        this._initialized = true;
        this.listed = listed;
        this.id = id;
        this.fnameHtml = fnameHtml || (fname ? _.escape(getNodeDisplayTitle(arguments[0])) : '');
        this.fname = fname ||
            (fnameHtml ? new DOMParser().parseFromString(fnameHtml, 'text/html').body.textContent || '' : '');
        this.sname = sname;
        this.name = name;
        this.org = org;
        this.tag = tag || '';
        this.keyStcHtml = keyStcHtml || (keyStc ? _.escape(keyStc) : '');
        this.keyStc = keyStc ||
            (keyStcHtml ? new DOMParser().parseFromString(keyStcHtml, 'text/html').body.textContent || '' : '');
        this.type = type;
        this.fixed = fixed === 1 || fixed === true;
        this.lev = lev ? lev : null;
        this.status = status === 1 ? 1 : 0;
        this.meta = meta || {};
        this.linkTime = linkTime || '2010-07-01';
        this.updateTime = updateTime || null;
        this.position = position || null;
        this.fx = fx;
        this.fy = fy;
        this.x = (x === undefined && fx !== undefined && fx !== null && this.fixed ? parseInt(fx) : x);
        this.y = (y === undefined && fy !== undefined && fy !== null && this.fixed ? parseInt(fy) : y);
        if (_initialized || isNaN(this.x)) this.x = undefined;
        if (_initialized || isNaN(this.y)) this.y = undefined;
        this.userId = userId;
        this.prop = prop || [];
        this.hasProps = detailLoaded ? this.prop.length > 0 : hasProps === true;
        this.hasMultimedia = detailLoaded ? this.prop.find(attachment => {
            return attachment['fileList'] && (!!attachment['fileList'].find(fileInfo => {
                const segments = fileInfo['fileName'].split(/\./g);
                const type = segments.pop().toLowerCase();
                return segments.length > 0 && MULTIMEDIA_EXT.includes(`.${type}`);
            }));
        }) : hasMultimedia === true;
        this.priority = priority > 0 ? priority : 0; // 节点优先级、重要程度，数值越高，越重要，展现时越靠上层，加载时越优先加载
        this.accessTimestamp = accessTimestamp || this.updateTime || this.linkTime;
        this.description = /*description || */(meta && meta.description ? `${meta.description}` : undefined) || description;
        this.url = /*url || */(meta ? meta.url : undefined) || url;
        this.version = version; // TODO 这里考虑下是不是要给默认值
        this.aiGraphRank = /*aiGraphRank ||*/ (meta ? meta['graph_rank'] : undefined) || aiGraphRank;
        this.aiRelatedTo = /*aiRelatedTo ||*/ (meta ? meta['relatedTo'] : undefined) || aiRelatedTo;
        this.aiPreferredType = /*aiPreferredType ||*/ (meta ? meta['preferredType'] : undefined) || aiPreferredType;
        this.aiLinkedTo = aiLinkedTo || '';
        this.aiLinkedToIdList = this.aiLinkedTo.split(',').slice(0, 3);
        this.aiUpdateTime = aiUpdateTime;
        this.userPreferredType = /*userPreferredType ||*/ (meta ? meta['userPreferredType'] : undefined) || userPreferredType;
        this.userConfirmed = userConfirmed === undefined ? (meta ? parseInt(meta.status) !== 0 : true) : !!userConfirmed;
        this.detailLoaded = detailLoaded === true;
        this.hidden = (hidden === true);
        // 节点首次出现在画面中时的位置，如指定关联节点ID，则出现在关联节点所在位置，否则出现在图谱默认位置
        this.withNodeId = withNodeId;
        this.withNodeExtra = withNodeExtra;
        this.amount = amount || 0;
        this._currentSelect = _currentSelect === true;
        this._presenting = _presenting === true;
        this._lastSelect = _lastSelect === true;
        this._focused = _focused === true;
        this._brightness = _brightness || undefined;
        this._brightnessFromLevel = _brightnessFromLevel || undefined;
        this._enableScaling = _enableScaling === true;
        this.groupNames = ''; // * 必须
        this.image = image; // 节点自定义显示图片
        this.brokenImage = brokenImage; // 节点自定义显示图片
        this.level = level; // 节点层级数据
        this._priority = _priority ? _priority : (this.priority > 0 ? this.priority : undefined); // 节点优先级，前端临时数据
        this._userFavorite = _userFavorite > -1 ? _userFavorite : -1;
        this._viewFavorite = _viewFavorite > -1 ? _viewFavorite : -1;
        this._locked = !!_locked;
        this._systemRecommend = !!_systemRecommend && !this.userConfirmed;
        this._structOnly = _structOnly === true;
        this._nodeLevel = _nodeLevel || 1;

        if (_brightnessFromAccessTimestamp && _previousAccessTimestamp &&
            _previousAccessTimestamp === this.accessTimestamp) {

            this._brightnessFromAccessTimestamp = _brightnessFromAccessTimestamp;
        } else {
            this._previousAccessTimestamp = this.accessTimestamp;
            this.calculateBrightnessFromAccessTimestamp();
        }

        if (_.isString(this.tag)) {
            this.tag = _.trim(this.tag);
            this.tags = this.tag === '' ? [] : this.tag.split(' ')
        } else if (!_.isArray(this.tag)) {
            this.tags = []
        } else {
            this.tags = this.tag;
        }

        this.htmlTags = htmlTags || this.tags;

        if (this.meta && this.meta['status'] !== undefined) {
            this.status = this.meta.status === 0 ? 0 : 1;
        }

        if (this.userConfirmed) {
            this.status = 1;
        }

        // 处理fix
        if (this.fixed === 0) {
            this.fixed = false;
        } else if (this.fixed === 1) {
            this.fixed = true;
        }

        // 处理位置
        x = parseFloat(this.fx);
        y = parseFloat(this.fy);
        if (!isNaN(x) && !isNaN(y)) {
            if (isFinite(x) && isFinite(y)) {
                if (this.fixed) {
                    this.x = x;
                    this.y = y;
                } else {
                    this.initialX = x;
                    this.initialY = y;
                }
            }
        }

        // 图片展示
        if (this.meta && this.meta['iconData']) {
            this._image = this.meta['iconData'];
            if (this.meta['iconMeta'] && this.meta['iconMeta'].shape === 'rect') {
                this._imageType = 'gradeU';
            } else {
                this._imageType = 'gradeT';
            }
        }

        // 处理lev
        if (this.lev === 'gradeD' || this.lev === null || this.lev === undefined || this.lev.startsWith('gradeType')) {
            if (this.userPreferredType !== undefined && !isNaN(parseInt(this.userPreferredType))) {
                // 处理userPreferredType
                this.lev = `gradeType${parseInt(this.userPreferredType)}`;
            } else if (this.aiPreferredType !== undefined && !isNaN(parseInt(this.aiPreferredType))) {
                // 处理aiPreferredType
                this.lev = `gradeType${parseInt(this.aiPreferredType)}`;
            } else {
                this.lev = "gradeD";
            }
        }

        // 进度条控制
        this._noProgressBar = !!(this.lev && this.lev.startsWith('tip'));

        // 处理label
        if (this.type === NODE_TYPE_TEXT) {
            this.label = fname || label;
            this.fname = fname || this.label;
            if (this.lev && this.lev.startsWith('tip')) {
                this.label = this.label
                  .replace(/&/g, '&amp;')
                  .replace(/</g, '&lg;');
            } else if (this.lev && this.lev.startsWith('tag')) {
                if (this.label && this.label.length > 15) {
                    this.label = this.label.substr(0, 14) + '…';
                }
                this.label = this.label
                  .replace(/&/g, '&amp;')
                  .replace(/</g, '&lg;');
            } else if (this.label && this.label.length > 30) {
                this.label = this.label.substr(0, 29) + '…';
            }
        } else if (this.type === NODE_TYPE_TALENT) {
            this.label = fname + ' ' + org;
        } else {
            this.label = fname;
        }

        if (this._userFavorite > -1) {
            this.label = '❤ ' + this.label;
        } else if (this._viewFavorite > -1) {
            this.label = '★ ' + this.label;
        }

        if (this.hasMultimedia) {
            this.label += ' ♬';
        } else if (this.hasProps) {
            this.label += ' 📎';
        }

        if (this.lev && (this.lev.startsWith('tip') || this.lev.startsWith('tag'))) {
            this.label = `<b>${this.label}</b>`;
        }

        // 用于拼接label
        if (this.tags.length > 0 && (!this.lev || !this.lev.startsWith('tag'))) {
            this.label += (this.lev && this.lev.startsWith('tip') ? '\n' : '');
            this.label += '\n[ ' + this.tags.filter((v, i) => i < 3).join(' ') + ' ]'
        }

        // 进度条
        if (this.aiGraphRank && (!this.lev || (!this.lev.startsWith('tip') && !this.lev.startsWith('tag')))) {
            let per_level = 20;
            // this.label += '\n\n';
            this.label += '\n';
            let blockAmount = Math.max(0, Math.min(100 / per_level, Math.ceil(parseFloat(this.aiGraphRank) / per_level)));
            let emptyAmount = 100 / per_level - blockAmount;
            // 方案一 █
            // this.label += _.repeat('█', blockAmount);
            // this.label += _.repeat(' ', emptyAmount * 2);

            // 方案二 ▮▯
            // this.label += _.repeat('▮', blockAmount);
            // this.label += _.repeat('▯', emptyAmount);

            // 方案三 □■  200508 增加空格方块
            this.label += _.repeat('■', blockAmount);
            this.label += _.repeat('□', emptyAmount);

            // 方案四 ➨➨➩
            // this.label += _.repeat('➨', blockAmount);
            // this.label += _.repeat('➩', emptyAmount);

            // 百分数
            // this.label += ` ${parseFloat(this.aiGraphRank).toFixed(1)}%`;
        }

        if (this.description && this.lev && this.lev.startsWith('tip')) {
            this.label += (this.tags.length > 0 ? ' ' : '\n\n');
            this.label += this.description;
        }

        this._locateOnly = this.type === 0 && this.lev === 'noIcon' && (this.label || '').trim() === '';

        myVisNetworkStyle.dealNodeStyle(this);
    }

    calculateBrightnessFromAccessTimestamp() {
        let accessDate = moment(this.accessTimestamp, 'YYYY-MM-DD HH:mm:ss').startOf('day');
        let level = today.diff(accessDate, 'days'), step = 0.175;

        if (level >= 5) {
            // 超过或等于5天，直接最低
            level = 4;
        } else if (daysAfterFriday > 0) {
            // 今天为周末
            level = Math.max(0, friday.diff(accessDate, 'days'));
        } else {
            // 今天为周一至周五
            if (lastFriday.diff(accessDate, 'days') >= 0) {
                // 访问时间为上周五及之前
                level -= 2;
            } else if (monday.diff(accessDate, 'days') >= 0) {
                // 访问时间为本周一以前
                level = today.diff(monday, 'days');
            }
        }

        this._brightnessFromAccessTimestamp = 1 - step * level;
    }

    /**
     * 已废弃
     * 保留为了兼容 main.relation.1.js 的旧方案
     */
    dealGroupNamesAndAddStyle() {
        myVisNetworkStyle.dealNodeStyle(this)
    }

    /**
     * 变成当前节点
     */
    becomeCurrentNode() {
        // console.log("Node change currentNode:", this);
        this._lastSelect = false;
        this._currentSelect = true;
        myVisNetworkStyle.dealNodeStyle(this);
        return true
    }

    /**
     * 变成上一个节点
     */
    becomeLastNode() {
        // console.log("Node change lastNode:", this);
        this._currentSelect = false;
        this._lastSelect = true;
        myVisNetworkStyle.dealNodeStyle(this);
        return true
    }

    /**
     * 变成普通节点
     */
    becomeCommonNode() {
        // console.log("Node change commonNode:", this);
        this._currentSelect = false;
        this._lastSelect = false;
        myVisNetworkStyle.dealNodeStyle(this);
        return true
    }


    /**
     * 变成隐藏
     */
    becomeHidden() {
        // console.log("Node change becomeHidden:", this.hidden);
        if (this.hidden !== true) {
            this.hidden = true;
            return true
        } else {
            return false
        }
    }

    /**
     * 取消隐藏
     */
    cancelHidden() {
        // console.log("Node change cancelHidden:", this.hidden);
        if (this.hidden !== false) {
            this.hidden = false;
            return true
        } else {
            return false
        }
    }

    /**
     * 变成暗节点
     */
    becomeDarken() {
        // console.log("Node change becomeDarken:", this._brightness);
        if (this._brightness !== 0.3) {
            this._brightness = 0.3;
            myVisNetworkStyle.dealNodeStyle(this);
            return true
        } else {
            return false
        }
    }

    /**
     * 取消暗节点
     */
    cancelDarken(brightness = undefined) {
        switch (brightness) {
            case 'time':
                brightness = this._brightnessFromAccessTimestamp;
                break;
            case 'level':
                brightness = this._brightnessFromLevel;
                break;
        }
        // console.log("Node change cancelDarken:", this._brightness);
        if (this._brightness !== brightness) {
            this._brightness = brightness;
            myVisNetworkStyle.dealNodeStyle(this);
            return true
        } else {
            return false
        }
    }

    /**
     * 变成焦点
     */
    becomeFocusNode() {
        // console.log("Node change becomeFocus:", this);
        if (this._focused !== true) {
            this._focused = true;
            myVisNetworkStyle.dealNodeStyle(this);
            return true
        } else {
            return false
        }
    }

    /**
     * 取消焦点
     */
    cancelFocus() {
        // console.log("Node change cancelFocus:", this);
        if (this._focused !== false) {
            this._focused = false;
            myVisNetworkStyle.dealNodeStyle(this);
            return true
        } else {
            return false
        }
    }

    /**
     * 固化节点 改变status
     */
    becomeSolid() {
        if (this.status !== 1) {
            this.status = 1;
            myVisNetworkStyle.dealNodeStyle(this);
            return true
        } else {
            return false
        }
    }

    /**
     * 取消固化节点
     */
    cancelSolid() {
        if (this.status !== 0) {
            this.status = 0;
            myVisNetworkStyle.dealNodeStyle(this);
            return true
        } else {
            return false
        }
    }

    /**
     * 更新节点内容,Todo: 有用？
     * @param {*} newNode
     */
    update(newNode) {
        let tmp = Object.assign({}, this, newNode);
        return new Promise((resolve/*, reject*/) => {
            delete tmp.groupNames;
            tmp = new Node(tmp);
            // console.log("Node change update:", this, newNode, tmp);
            resolve(tmp);
        })
    }

    /**
     * 修改节点label
     * @param text
     */
    setLabel(text = '') {
        // 用于拼接label
        let tempTagStr = '';
        if (this.tags.length > 0) {
            tempTagStr = '\n[ ' + this.tags.filter((v, i) => i < 3).join(' ') + ' ]'
        }

        if (this.type === NODE_TYPE_TEXT) {
            // 用户输入节点优先读取label值
            this.label = this.label || this.fname;
            if (!this.fname) {
                this.fname = this.label
            }
        } else if (this.type === NODE_TYPE_TALENT) {
            // 人物节点 加上单位和标签
            this.label = this.fname + ' ' + this.org + tempTagStr;
        } else {
            // 其他节点fname+标签
            this.label = this.fname + tempTagStr;
        }

        // 追加需要设置的text
        // 要放到其他位置，动手改代码
        this.label = this.label + text
    }
}
