import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {Input, Modal, Form} from "antd";

import UserDataProvider from '@/components/common/dataProvider/common.dataProvider.user';
import { IconFont } from '@/components/common/common.fonticon';
import Icon from '@/components/common/common.icon';
import style from '@/style/common/view/common.view.info.setInfoModal.less';

class ChangeEmailModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      processing: false,
      errMsg: '', // 提交后的错误提示
    };
  }

  onSave = () => {
    let me = this;
    const {form} = me.props;
    form.validateFields((err, values) => {
      if (!err) {
        const { email, password } = values;
        if (email) {
          me.setState({processing: true}, () => {
            UserDataProvider.changeEmail(email, password).then(() => {
              me.props.onClose();
              me.setState({processing: false}, () => {
                Modal.success({
                  title: '操作成功',
                  centered: true,
                  content: <div>
                    <div>我们已向</div>
                    <div style={{fontSize: '2rem', padding: '10px 0'}}>{email}</div>
                    <div>发送了一份验证邮件，请查看你的收件箱，然后按照说明完成邮箱验证。</div>
                  </div>,
                  okText: '知道了',
                  onOk: me.props.onClose,
                });
              });
            }).catch(({msg}) => {
              me.setState({
                processing: false,
                errMsg: msg,
              });
            });
          });
        }
      }
    });
  };

  componentWillUnmount() {
    this.setState({email: ''});
  }

  render() {
    let me = this;
    const {visible, onClose, form} = this.props;
    const {getFieldDecorator} = form;

    // noinspection RequiredAttributes
    return (
      <Modal
        visible={visible}
        closable={!this.state.processing}
        title={<div>
          <Icon name="edit"
                style={{
                  color: '#a5a5a5',
                  margin: '4px 6px 0 6px',
                  fontSize: '16px'}}
          /> {me.props.userEmail ? '修改邮箱' : '绑定邮箱'}</div>}
        onCancel={onClose}
        onOk={me.onSave}
        okText={'下一步'}
        cancelText={'取消'}
        okButtonProps={{loading: this.state.processing, disabled: this.state.processing}}
        cancelButtonProps={{disabled: this.state.processing}}
        centered={true}
        destroyOnClose={true}
        wrapClassName={style['change-email-modal']}
        bodyStyle={{paddingLeft: '32px', paddingRight: '32px', minHeight: '140px'}}
      >
        {
          me.props.userEmail ? (
            <Fragment>
              <div className={style['email-show']}>
                邮箱地址： {me.props.userEmail}
              </div>
              {
                me.state.errMsg && (
                  <div className={style['error-tips']}>
                    {me.state.errMsg}
                  </div>
                )
              }
              <Form className={style['custom-form-wrap']}>
                <Form.Item label="换绑邮箱："
                >
                  {getFieldDecorator('email', {
                    rules: [{
                      required: true,
                      pattern: /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/,
                      message: '请输入合法的邮箱地址',
                    }],
                  })(
                    <Input type={'email'}
                           placeholder={'请输入换绑邮箱地址'}
                           disabled={me.state.processing}
                           autoFocus={true}
                           onChange={() => {
                             this.setState({
                               errMsg: '',
                             })
                           }}
                    />
                  )}
                </Form.Item>
                <Form.Item label="确认密码：">
                  {getFieldDecorator('password', {
                    rules: [{
                      required: true, message: '请确认登录密码',
                    }],
                  })(
                    <Input
                      type={'password'}
                      placeholder={'请确认登录密码'}
                      disabled={me.state.processing}
                      onChange={() => {
                        this.setState({errMsg: ''})
                      }}
                    />
                  )}
                </Form.Item>
              </Form>
            </Fragment>
          ) : (
            <Fragment>
              <div className={style['email-show']}>
                邮箱地址： 未绑定
              </div>
              {
                me.state.errMsg && (
                  <div className={style['error-tips']}>
                    {me.state.errMsg}
                  </div>
                )
              }
              <Form className={style['custom-form-wrap']}>
                <Form.Item label="绑定邮箱："
                >
                  {getFieldDecorator('email', {
                    initialValue: me.props.userEmail,
                    rules: [{
                      required: true,
                      pattern: /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/,
                      message: '请输入合法的邮箱地址',
                    }],
                  })(
                    <Input type={'email'}
                           placeholder={'请输入绑定邮箱地址'}
                           disabled={me.state.processing}
                           onChange={() => {
                             this.setState({
                               errMsg: '',
                             })
                           }}
                    />
                  )}
                </Form.Item>
              </Form>
            </Fragment>
          )
        }
      </Modal>
    );
  }
}

const WrappedChangeEmailModal = Form.create()(ChangeEmailModal);

WrappedChangeEmailModal.defaultProps = {
  visible: false,
  userEmail: null,
};

WrappedChangeEmailModal.propTypes = {
  visible: PropTypes.bool,
  userEmail: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

export default WrappedChangeEmailModal;
