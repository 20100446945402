import React from 'react';
import PropTypes from 'prop-types';
import {Form, Button, Input, Checkbox, message, Tooltip} from 'antd';

import {IconTypes} from "@/constants/common";

import Icon from '@/components/common/common.icon';

import AuthForm from '@/components/common/common.authForm';

import style from '@/style/components/common.authForm.less';
import intl from 'react-intl-universal';

class CheckPasswordForm extends React.Component {
  onResetPwd = (e) => {
    e && e.preventDefault();
    if (this.props.checkingPassword) {
      message.info(intl.get('Custom.general.requestWait'))
    } else {
      this.props.onResetPwd();
    }
  };

  handleCheckPassword = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        //this.props.onCheckPassword(values.password, values['remember'] ? 30 : 0);
        this.props.onCheckPassword(values.password, 7);
      }
    })
  };

  render() {
    let me = this;
    const {getFieldDecorator} = this.props.form;
    // noinspection RequiredAttributes
    return (
      <AuthForm>
        {intl.get('locale')==='zh-cn' &&
        <div
          style={{
            height: '6rem',
            width: 'calc(350px + 5rem)',
            position: 'absolute',
            margin: '-2.5rem',
            textAlign: 'right',
            lineHeight: '5.5rem',
            fontSize: '5.5rem',
          }}
        >
          <Tooltip title={intl.get('Custom.general.scanLogin')}>
            <a onClick={me.props.loginViaWechat}>
              <Icon name={'icon-qr-code'} type={IconTypes.ICON_FONT} />
            </a>
          </Tooltip>
        </div>}
        <Form onSubmit={this.handleCheckPassword} style={{height: 'calc(265px + 3rem)', display: 'flex', flexDirection: 'column'}}>
          <Form.Item className={`${style['form-item']} ${style['back-to-username']}`} style={{marginBottom: '1rem'}}>
            <Button
              shape="circle"
              size="small"
              icon="arrow-left"
              className={style['form-button']}
              onClick={this.props.gotoCheckUsername}
              style={{marginRight: '1em'}}
            />
            <span>{this.props.currentUsername}</span>
          </Form.Item>
          <h1 className={style['form-title']}>{intl.get('Custom.general.inputPassword')}</h1>
          <Form.Item className={style['form-item']}>
            {getFieldDecorator('password', {
              rules: [{
                required: true,
                message: intl.get('Custom.general.pInputPassword'),
              }],
            })(
              <Input
                prefix={<Icon name="lock"/>}
                type="password"
                placeholder={intl.get('Custom.general.pInputPassword')}
                autoFocus
                onFocus={this.props.onInputFocus}
                onBlur={this.props.onInputBlur}
              />
            )}
          </Form.Item>
          <Form.Item className={style['form-item']} style={{flex: 1}}>
            <div style={{lineHeight: '1.25em', paddingBottom: '3em'}}>
              {/*getFieldDecorator('remember', {
                valuePropName: 'checked',
                initialValue: false,
              })(
                <Checkbox>30天内免登录</Checkbox>
              )*/}
              <a style={{float: 'right'}} onClick={this.onResetPwd}>{intl.get('Custom.general.forgotPassword')}</a>
            </div>
          </Form.Item>
          <Form.Item className={style['form-item']}>
            <Button
              type="primary"
              htmlType="submit"
              loading={this.props.checkingPassword}
              className={style['form-button']}
            >
              {intl.get('Custom.general.login')}
            </Button>
          </Form.Item>
        </Form>
      </AuthForm>
    );
  }
}

CheckPasswordForm.defaultProps = {
  onCheckPassword(password, remember) {
    console.warn('onCheckPassword property is not set, password %s, remember %d.', password, remember);
  },
  onResetPwd() {
    console.warn('onResetPwd property is not set.');
  },
  gotoCheckUsername() {
    console.warn('gotoCheckUsername property is not set.');
  },
  onInputFocus() {
  },
  onInputBlur() {
  },
  currentUsername: '',
  checkingPassword: false,
};

CheckPasswordForm.propTypes = {
  onCheckPassword: PropTypes.func,
  onResetPwd: PropTypes.func,
  gotoCheckUsername: PropTypes.func,
  loginViaWechat: PropTypes.func,
  onInputFocus: PropTypes.func,
  onInputBlur: PropTypes.func,
  currentUsername: PropTypes.string,
  checkingPassword: PropTypes.bool,
};

const WrappedCheckPasswordForm = Form.create()(CheckPasswordForm);

export default WrappedCheckPasswordForm;