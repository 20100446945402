import {myVisNetworkStyle} from '@/libs/myVis/MyNetwork3';

let cachedStyles = {};

export const defaultEdgeId = (from, to) => (from > to ? `|${to}|->|${from}|` : `|${from}|->|${to}|`);

export default class Edge {
    constructor({
        id, from, to, label, type, lev, status, groupNames, viewId, size, userConfirmed,
        meta, length, hidden, unique, arrows, visible, _selectable, _userMarkedInvisible, _aiGenerated, _localOnly,
        _brightness, _brightnessFromAccessTimestamp, _brightnessFromLevel,
    }) {
        this.id = id ? id : (unique === false ? undefined : defaultEdgeId(from, to));
        this.from = from;
        this.to = to;
        this.label = label ? label : null;
        this.type = type ? type : null; // 节点类型
        this.lev = lev ? lev : null;
        this.length = length === undefined ? (meta && meta.length !== undefined ? parseInt(meta.length) : undefined) : length;
        this.status = status;
        this.userConfirmed = userConfirmed === undefined ? (meta ? parseInt(meta.status) !== 0 : true) : !!userConfirmed;
        this.groupNames = groupNames;
        this.viewId = viewId;
        this.size = size;
        this.meta = meta ? meta : {};
        // this.meta._darken = 0; // 用于变暗
        this._selectable = _selectable !== false;
        this._userMarkedInvisible = !!(this.meta && this.meta.userMarkedInvisible);
        this._aiGenerated = _aiGenerated === true || (this.meta && (this.meta.gravity || this.meta.ai));
        this._localOnly = _localOnly !== false;
        this._brightness = _brightness || undefined;
        this._brightnessFromAccessTimestamp = _brightnessFromAccessTimestamp || this._brightness;
        this._brightnessFromLevel = _brightnessFromLevel || this._brightness;
        this.visible = visible !== false; // 用户隐藏状态的引力边
        this.hidden = hidden === true; // 用于边的显示与隐藏
        this.arrows = arrows || '';

        if (this.meta && this.meta['status'] !== undefined) {
            this.status = this.meta.status === 0 ? 0 : 1;
        }

        if (this.userConfirmed) {
            this.status = 1;
        }

        // 强制刷新vis库中对关系信息的缓存
        this._resetInitialOptions = true;

        myVisNetworkStyle.dealEdgeStyle(this);
    }

    updateBrightnessFromAccessTimestamp(fromNode, toNode) {
        this._brightnessFromAccessTimestamp = Math.max(fromNode ? fromNode._brightnessFromAccessTimestamp : 0.3,
          toNode ? toNode._brightnessFromAccessTimestamp : 0.3);
    }

    updateBrightnessFromLevel(fromNode, toNode) {
        this._brightnessFromLevel = Math.max(fromNode ? fromNode._brightnessFromLevel : 0.3,
          toNode ? toNode._brightnessFromLevel : 0.3);
    }

    /**
     * 固化
     */
    becomeSolid() {
        if (this.status === 0) {
            this.status = 1;
            myVisNetworkStyle.dealEdgeStyle(this);
            return true
        } else {
            return false
        }
    }

    /**
     * 取消固化
     */
    cancelSolid() {
        if (this.status === 1) {
            this.status = 0;
            myVisNetworkStyle.dealEdgeStyle(this);
            return true
        } else {
            return false
        }
    }

    /**
     * 变成隐藏
     */
    becomeHidden() {
        // console.log("Edge.js -> change -> becomeHidden:", this.hidden);
        if (this.hidden !== true) {
            this.hidden = true;
            return true
        } else {
            return false
        }
    }

    /**
     * 取消隐藏
     */
    cancelHidden() {
        // console.log("Edge.js -> change -> cancelHidden:", this.hidden);
        if (this.hidden !== false) {
            this.hidden = false;
            return true
        } else {
            return false
        }
    }

    /**
     * 变暗
     */
    becomeDarken() {
        // console.log("Edge.js -> change -> becomeDarken:", this);
        if (this._brightness !== 0.3) {
            this._brightness = 0.3;
            myVisNetworkStyle.dealEdgeStyle(this);
            return true
        } else {
            return false
        }
    }

    /**
     * 取消变暗
     */
    cancelDarken(brightness = undefined) {
        switch (brightness) {
            case 'time':
                brightness = this._brightnessFromAccessTimestamp;
                break;
            case 'level':
                brightness = this._brightnessFromLevel;
                break;
        }
        // console.log("Edge.js -> change -> cancelDarken:", this);
        if (this._brightness !== brightness) {
            this._brightness = brightness;
            myVisNetworkStyle.dealEdgeStyle(this);
            return true
        } else {
            return false
        }
    }
}
