import {Icon} from 'antd'
import Config from '@/constants/sys.config'

export const xFontIcon = Icon.createFromIconfontCN({
  scriptUrl: Config.iconFont, // generated by iconfont.cn
});
export const IconFont = Icon.createFromIconfontCN({
  scriptUrl: Config.iconFont, // generated by iconfont.cn
});




