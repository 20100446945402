import React from 'react';
import PropTypes from 'prop-types';

import dashboardStyle from "@/style/containers/dashboard.less";
import styles from "./dashboard.default.less";
import viewStyle from '@/style/components/dashboard/dashboard.view.less';
import {Modal, Tooltip, Button, Empty} from "antd";
import QueueAnim from "rc-queue-anim";
import ViewInfoCard from "@/components/dashboard/dashboard.viewInfoCard";
import {bindUtil} from "@/libs/core-decorators";
import ViewManager from "@/components/common/common.viewManager";
import {autoSetState, withReactStateHelper} from "@/libs/react-state-helper";
import Icon from '@/components/common/common.icon'
import {
    PublicViewListLoadingStatus,
    UserIsMasterViewListLoadingStatus,
    UserIsMemberViewListLoadingStatus,
} from "@/libs/view/ViewManager";
import CreateViewModal from '@/components/common/view/common.view.info.createModal';
import intl from 'react-intl-universal';

// 每页加载的数据
const loadItemsPerStep = 3;

@withReactStateHelper
@bindUtil.asTargetClass
class Dashboard extends React.Component {
    // 当前页 myNetwork对象
    myNetwork = undefined;
    // network 容器
    container = undefined;

    // 我是组长数据
    @autoSetState
    @bindUtil.bindToProperty(ViewManager.dataProvider, 'userIsMasterViewList')
    ViewsForMy = [];

    @autoSetState
    @bindUtil.bindToProperty(ViewManager.dataProvider, 'userIsMasterViewListLoadingStatus')
    userIsMasterViewListLoadingStatus = {};

    // 我是成员数据
    @autoSetState
    @bindUtil.bindToProperty(ViewManager.dataProvider, 'userIsMemberViewList')
    ViewsForTeam = [];

    @autoSetState
    @bindUtil.bindToProperty(ViewManager.dataProvider, 'userIsMemberViewListLoadingStatus')
    userIsMemberViewListLoadingStatus = {};

    // 公共图谱数据
    @autoSetState
    @bindUtil.bindToProperty(ViewManager.dataProvider, 'publicViewList')
    ViewsForPublic = [];

    @autoSetState
    @bindUtil.bindToProperty(ViewManager.dataProvider, 'publicViewListLoadingStatus')
    publicViewListLoadingStatus = {};

    state = {
        // 标记异步获取数据的结果状态
        isAjaxResultStatus: false,
        // 展示的数据量
        displayAmount: loadItemsPerStep - 2,
        // 九宫弹框
        modalVisible: true,

        //
        tempJSX: '',
        tempState: false,

        showCreateViewModal: false, // 新建图谱弹框是否显示
    };


    componentDidMount() {
        this.refresh();
    }

    /**
     * 加载页面数据（数据流）
     */
    refresh = () => {
        console.log('this.props:', this.props);
        // 登录用户id
        const currentUserId = localStorage.getItem("userId");

        // 显示总数
        // let displayAmount = loadItemsPerStep - 1;
        // 加载数据
        // 默认 dashboard= 我是组长,加载三个
        let displayAmount = loadItemsPerStep - 2;

        // 加载我是组长
        // noinspection JSIgnoredPromiseFromCall
        ViewManager.loadUserIsMasterViewList(currentUserId,{start:0,limit:loadItemsPerStep});

        // 加载我是成员
        // noinspection JSIgnoredPromiseFromCall
        ViewManager.loadUserIsMemberViewList(currentUserId,{start:0,limit:loadItemsPerStep});

        // 加载公开图谱
        // noinspection JSIgnoredPromiseFromCall
        ViewManager.loadPublicViewList({start:0,limit:loadItemsPerStep});

        // 设置状态值
        this.setState({isAjaxResultStatus: true, displayAmount})
    };

    /**
     * ??
     * @param action
     */
    onViewItemUpdated = ({action}) => {
        switch (action) {
            case 'edit':
            case 'sale':
            case 'cancel_sale':
                this.refresh();
                break;
            default:
                this.refresh();
        }
    };

    checkLoadingState = () => {
        let that = this;
        that.state.tempState=false;

        if (this.userIsMasterViewListLoadingStatus.status === UserIsMasterViewListLoadingStatus.SUCCESS) {
            if (this.userIsMemberViewListLoadingStatus.status === UserIsMemberViewListLoadingStatus.SUCCESS) {
                if (this.publicViewListLoadingStatus.status === PublicViewListLoadingStatus.SUCCESS) {
                    that.state.tempState=true;
                }
            }
        }
    };

    render() {
        let that = this;
        // 登录用户id
        const currentUserId = localStorage.getItem("userId");

        // 检查数据拉取状态
        this.checkLoadingState();

        const tempState = this.state.tempState;

        let myEmptySpace = Math.min(2, Math.max(0, 2 - that.ViewsForMy.length)),
          teamworkEmptySpace = Math.min(2, Math.max(0, 3 - that.ViewsForTeam.length)),
          publicEmptySpace = Math.min(2, Math.max(0, 3 - that.ViewsForPublic.length));

        return (
          <div className={dashboardStyle["container"]}>
              <div id="content-network" className={dashboardStyle["content"] + " scrollbar scrollbar-none"}>
                  <div id="network" className={{/*dashboardStyle["relation"]*/}}/>
              </div>

              {/*九宫格弹框*/}
              <Modal
                visible={this.state.modalVisible}
                getContainer={'#content-network'}
                width={'auto'}
                title={null}
                footer={null}
                closable={false}
                centered={true}
                bodyStyle={{padding: '0 0', backgroundColor: 'rgba(0,0,0,0)'}}
                className={styles['modal']}
                mask={false}
                wrapClassName={styles['modal-wrap']}
              >
                  <div className={[styles["slide-div"],tempState?styles["slide-out"]:''].join(' ')}>
                      <div style={{width:'auto'}}>
                          <div
                            className={`${viewStyle["container"]} ${styles["line-container"]} ${styles["line-bg"]}`}>
                              <div className={styles['title']}
                                   style={{backgroundColor: 'hsla(198, 65%, 60%, 1)', cursor: 'pointer'}}
                                   onClick={() => {
                                       that.props.history.push('/mainview/dashboard/userview');
                                   }}
                              >
                                  <h2>{intl.get('Custom.channel.sysName')[0]}</h2>
                              </div>
                              <QueueAnim
                                duration={[250, 0]}
                                interval={[30, 0]}
                                className={styles['cards-container']}
                              >
                                  {
                                      that.ViewsForMy.length > 0 ?
                                        that.ViewsForMy.map(view => (
                                          <ViewInfoCard readOnly={false} callback={that.onViewItemUpdated}
                                                        view={view}
                                                        key={`view-${view.viewId}`}/>
                                        )) : null
                                  }
                                  {
                                      that.ViewsForMy.length < 3 ? (
                                        <div className={viewStyle['view-card-wrap']} key={`add-btn`}>
                                            <div
                                              className={viewStyle["add-btn"] + ' add-btn'}
                                              // style={{backgroundColor: 'transparent'}}
                                              onClick={() => {
                                                  that.setState({
                                                      showCreateViewModal: true,
                                                  });
                                              }}
                                            >
                                                <Icon name='plus'/>
                                                <div>{intl.get('Custom.view.add')}</div>
                                            </div>
                                        </div>
                                      ) : null
                                  }
                                  {
                                      myEmptySpace > 0 ? _.fill(Array(myEmptySpace), true).map((e, idx) => (
                                        <div key={`empty-space-${idx}`} className={viewStyle['col-item']}>
                                            <div className={viewStyle['empty-space']} />
                                        </div>
                                      )) : null
                                  }
                              </QueueAnim>
                              {
                                  tempState && that.ViewsForMy.length > 0 ? (
                                    <div className={`${styles['meta']} dark-theme`}>
                                        <Tooltip title="{intl.get('Custom.view.add')}">
                                            <Icon style={{fontSize: '28px'}} name="plus"
                                                  onClick={() => {
                                                      that.setState({
                                                          showCreateViewModal: true,
                                                      })
                                                  }}
                                            />
                                        </Tooltip>
                                        <Button type="link"
                                                onClick={() => {
                                                    that.props.history.push('/mainview/dashboard/userview');
                                                }}
                                        >{intl.get('Custom.general.more')}</Button>
                                    </div>
                                  ) : null
                              }

                          </div>

                          <div className={`${viewStyle["container"]} ${styles["line-container"]}`}>
                              <div className={styles['title']}
                                   style={{backgroundColor: '#dc7e23', cursor: 'pointer'}}
                                   onClick={() => {
                                       that.props.history.push('/mainview/dashboard/teamwork');
                                   }}
                              >
                                  <h2>{intl.get('Custom.channel.sysName')[1]}</h2>
                              </div>
                              <QueueAnim
                                duration={[250, 0]}
                                interval={[30, 0]}
                                className={styles['cards-container']}
                              >
                                  {
                                      that.ViewsForTeam.length > 0 ?
                                        that.ViewsForTeam.map(view => (
                                          <ViewInfoCard readOnly={false} callback={that.onViewItemUpdated}
                                                        view={view}
                                                        key={`view-${view.viewId}`}/>
                                        ))
                                        : (
                                          <div key={'notice'} className={viewStyle['col-item']}>
                                              <div className={viewStyle['empty-space']}>
                                                  <p>
                                                      如果他人的图谱添加了您参与协作，则会作为“我是成员”显示在这里。
                                                  </p>
                                              </div>
                                          </div>
                                        )


                                  }
                                  {
                                      teamworkEmptySpace > 0 ? _.fill(Array(teamworkEmptySpace), true).map((e, idx) => (
                                        <div key={`empty-space-${idx}`} className={viewStyle['col-item']}>
                                            <div className={viewStyle['empty-space']} />
                                        </div>
                                      )) : null
                                  }
                              </QueueAnim>
                              {
                                  tempState && that.ViewsForTeam.length > 0 ? (
                                    <div className={`${styles['meta']} dark-theme`}>
                                        <div />
                                        <Button type="link"
                                                onClick={() => {
                                                    that.props.history.push('/mainview/dashboard/teamwork');
                                                }}
                                        >{intl.get('Custom.general.more')}</Button>
                                    </div>
                                  ) : null
                              }

                          </div>

                          <div
                            className={`${viewStyle["container"]} ${styles["line-container"]} ${styles["line-bg"]}`}>
                              <div className={styles['title']}
                                   style={{backgroundColor: '#d3d3d3', cursor: 'pointer'}}
                                   onClick={() => {
                                       that.props.history.push('/mainview/dashboard/public');
                                   }}
                              >
                                  <h2>{intl.get('Custom.channel.sysName')[2]}</h2>
                              </div>
                              <QueueAnim
                                duration={[250, 0]}
                                interval={[30, 0]}
                                className={styles['cards-container']}
                              >
                                  {

                                      that.ViewsForPublic.length > 0 ?
                                        that.ViewsForPublic.map(view => (
                                          <ViewInfoCard readOnly={parseInt(view['teamworkType']) !== 1}
                                                        callback={that.onViewItemUpdated}
                                                        view={view}
                                                        key={`view-${view.viewId}`}/>
                                        )) : (
                                          <div className={viewStyle['col-item']}>
                                              <div className={viewStyle["blank-btn"]}>
                                                  <Empty description={<span
                                                    style={{fontSize: '1.3rem'}}>没有开放阅读的图谱</span>}
                                                         image={Empty.PRESENTED_IMAGE_SIMPLE}/>
                                              </div>
                                          </div>
                                        )

                                  }
                                  {
                                      publicEmptySpace > 0 ? _.fill(Array(publicEmptySpace), true).map((e, idx) => (
                                        <div key={`empty-space-${idx}`} className={viewStyle['col-item']}>
                                            <div className={viewStyle['empty-space']} />
                                        </div>
                                      )) : null
                                  }
                              </QueueAnim>
                              {
                                  tempState && that.ViewsForPublic.length > 0 ? (
                                    <div className={`${styles['meta']} dark-theme`}>
                                        <div />
                                        <Button type="link"
                                                onClick={() => {
                                                    that.props.history.push('/mainview/dashboard/public');
                                                }}
                                        >{intl.get('Custom.general.more')}</Button>
                                    </div>
                                  ) : null
                              }

                          </div>
                      </div>
                  </div>

              </Modal>

              <CreateViewModal
                visible={that.state.showCreateViewModal}
                onClose={() => that.setState({showCreateViewModal: false})}
                callback={res => {
                    const {action, success, viewInfo} = res;
                    if (action === 'create' && success) {
                        that.refresh();
                        window.open(`/mainview/relation/${viewInfo.viewId}`, '_blank');
                    }
                }}
              />
          </div>

        );
    }
}

Dashboard.propTypes = {
    history: PropTypes.object, // router的参数
    className: PropTypes.string, // css class
    title: PropTypes.string, // 页面标题
};

export default Dashboard;
