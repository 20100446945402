import React from 'react';
import PropTypes from 'prop-types';
import {Drawer} from 'antd';

import PB, {SimplePB} from '@/libs/simplePB';
import ResizableDrawer from '@/libs/resizable-drawer';

import {getToken} from '@/utils/HttpUtil';

import style from '@/style/common/view/common.view.extendedActions.less';

class ExtendedActionsViewer extends React.PureComponent {
  state = {
    showDrawer: false,
    name: '',
    url: '',
    userToken: false,
  }

  componentDidMount() {
    let me = this;

    me.props.bus.with(me).subscribe('network', 'node.show_extended_action_viewer', ({name, url, userToken}) => {
      if (!me.state.showDrawer) {
        me.setState({showDrawer: true, url, name, userToken});
      }
    });
  }

  componentWillUnmount() {
    this.props.bus.remove(this);
  }

  render() {
    let me = this, url = me.state.url;

    if (me.state.userToken) {
      let userToken = getToken();
      if (/.+\?.*/.test(url)) {
        url += /.+&$/.test(url) ? `user_token=${encodeURIComponent(userToken)}` : `&user_token=${encodeURIComponent(userToken)}`;
      } else {
        url += `?user_token=${encodeURIComponent(userToken)}`;
      }
    }

    return (
      <div className={style['container']}>
        <ResizableDrawer
          title={me.state.name}
          onClose={() => me.setState({showDrawer: false})}
          visible={!!me.state.showDrawer}
          width={'33vw'}
          placement='right'
          destroyOnClose={true}
          mask={true}
          className={`${style['viewer-drawer']} dark-theme`}
          bodyStyle={{height: 'calc(100% - 55px)', padding: 0}}
        >
          <iframe src={url} style={{borderStyle: 'none', borderWidth: 0, height: '100%', width: '100%'}} />
        </ResizableDrawer>
      </div>
    );
  }
}

ExtendedActionsViewer.defaultProps = {
  bus: PB,
};

ExtendedActionsViewer.propTypes = {
  bus: PropTypes.instanceOf(SimplePB),
};

export default ExtendedActionsViewer;