import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {message,Tooltip,Button,Checkbox,Modal} from 'antd';
import copy from 'copy-to-clipboard';
import {IconTypes} from '@/constants/common';
import PB, {SimplePB} from '@/libs/simplePB';

import Icon from '@/components/common/common.icon';
import ViewDataProvider from "@/components/common/dataProvider/common.dataProvider.view";
import {API_analysisNodeForEdge,API_analysisChopdown,API_analysisChopdown_v2,API_analysisChopdown_english} from "@/api/relation";
import {API_getPresentations} from "@/api/presentation";
import {IconFont} from '@/components/common/common.fonticon';

import style from '@/style/common/microService/common.microService.special.nodeExpand.less';
import {defaultIconData} from '@/components/common/view/presentation/common.view.presentation.logic';
import {API_LoadMicroServiceConfigList} from '@/api/microService';
import PresentationDataProvider from '@/components/common/dataProvider/common.dataProvider.presentation';
import {uniqueArrayObj} from "@/utils/Common";
import { getNodeDisplayTitle, getNodeIcon } from '@/constants/vis.defaultDefine.1';
import intl from 'react-intl-universal';

const presentationSvg = () => (
  <svg t="1661161487435" class="icon" viewBox="0 0 1047 1024" version="1.1" p-id="1098" width="1em" height="1em">
    <path d="M900.179878 99.090921v805.641091h-801.089976V99.089943h801.089976z m0-77.37872h-801.089976c-40.964917 0-77.37872 36.413803-77.378721 77.377742v805.642069c0 40.963939 36.413803 77.377742 77.377743 77.377742h805.642068c40.963939 0 77.377742-36.412825 77.377743-77.377742V99.089943c-4.551114-40.963939-36.412825-77.377742-81.929835-77.377742z" p-id="1099" fill="#dddddd"></path>
    <path d="M217.454075 287.032184a45.782018 44.444261 90 1 0 88.888521 0 45.782018 44.444261 90 1 0-88.888521 0z" p-id="1100" fill="#dddddd"></path>
    <path d="M217.454075 500.682255a45.782018 44.444261 90 1 0 88.888521 0 45.782018 44.444261 90 1 0-88.888521 0z" p-id="1101" fill="#dddddd"></path>
    <path d="M217.454075 714.332326a45.782018 44.444261 90 1 0 88.888521 0 45.782018 44.444261 90 1 0-88.888521 0z" p-id="1102" fill="#dddddd"></path>
    <path d="M765.597349 691.43985h-370.367209c-11.852064 0-22.221641 10.683481-22.221642 22.892476s10.370556 22.89052 22.221642 22.89052h370.367209c11.852064 0 22.222619-10.682504 22.222619-22.891498s-10.370556-22.89052-22.222619-22.89052z m-370.367209-381.51519h370.367209c11.852064 0 22.222619-10.683481 22.222619-22.892476s-10.370556-22.89052-22.222619-22.89052h-370.367209c-11.852064 0-22.221641 10.682504-22.221642 22.891498s10.370556 22.89052 22.221642 22.89052z m370.367209 167.867075h-370.367209c-11.852064 0-22.221641 10.682504-22.221642 22.89052s10.370556 22.891498 22.221642 22.891498h370.367209c11.852064 0 22.222619-10.682504 22.222619-22.891498s-10.370556-22.89052-22.222619-22.89052z" p-id="1103" fill="#dddddd"></path>
  </svg>
);

class NodeExpandAnalysis extends React.PureComponent {

  state = {
    status: 'idle',
    nodeList:[],
    nodeListSelectionMap:{},
    nodeListSelectedAmount: 0,
    nodeListAllSelectedIndeterminate: false,
    nodeListAllSelected: false,
    showBox: false,
    showMsg: false,
    keepShow: true,
    chop_idx: 0,
    showMsgText : [],
  };
  doDelectedNode = false;
  nodeTypeName = ['机器人推荐','核心技术','应用领域'];
  nodeTypeName_en = ['Phrases','Words','Sentences'];
  addNodeList = [];
  allChopList = [];
  nodeType = 0;
  connectedNodes = [];
  timer = undefined; // 自动隐藏定时器
  isMouseOver = false; // 鼠标是否移入此区域
  allNodeMount = 0;
  bak = 0;

  doExpand = (p_nodeInfo) => {
    let me = this;
    API_analysisNodeForEdge(me.props.viewId,p_nodeInfo.id,p_nodeInfo.fname,p_nodeInfo.tag).then(response => {
      if (response && response.data && response.data.code === 0 && response.data.data.length>0) {
        let _data = [],nodeListSelectionMap={};
        response.data.data.forEach((item, idx) => {
          //if(p_nodeInfo.id !== item.id){
            let nodeInfo = me.props.viewDataProvider.getNode(item.id);
            if(nodeInfo){
              _data.push(nodeInfo);
              nodeListSelectionMap[item.id] = false;
            }
          //}
        });
        if(_data.length>0){
          me.setState({
            status: 'success',
            showMsg: false,
            showMsgText: [],
            nodeList: _data.slice(0,5),
            nodeListAllSelected: false,nodeListAllSelectedIndeterminate: false,nodeListSelectionMap,nodeListSelectedAmount:0
          }, () => {
            me.timer = setTimeout(() => {
              if (me.props.visible && !me.isMouseOver) {
                me.setState({status: 'idle'})
              }
            }, 2000);
          });
        }else{
          me.setState({
            status: 'failed',
            showMsg: this.state.keepShow?true:false,
            showMsgText: me.allNodeMount>100?intl.get('Custom.message.nodowith'):intl.get('Custom.message.noRecommendedNodes')
          }, () => {
            me.timer = setTimeout(() => {
              if (me.props.visible && !me.isMouseOver) {
                me.setState({status: 'idle'})
              }
            }, 2000);
          });
        }
      } else {
        me.setState({
          status: 'failed',
          showMsg: this.state.keepShow?true:false,
          showMsgText: me.allNodeMount>100?intl.get('Custom.message.nodowith'):intl.get('Custom.message.noRecommendedNodes')
        }, () => {
          me.timer = setTimeout(() => {
            if (me.props.visible && !me.isMouseOver) {
              me.setState({status: 'idle'})
            }
          }, 2000);
        });
      }
    }).catch(error => {
      console.warn(`failed:`,error);
    });
  };

  getAnalysisChopdown = (params) => {
    return new Promise((resolve, reject) => {
      if(intl.get('locale')==='zh-cn'){
        if(params.chop_way==10){
          API_analysisChopdown_v2(params).then(response => {
            if (response && response.data && response.data.code === 0) {
              resolve(response);
            } else {
              reject(response);
            }
          }).catch(error => {
            reject(error);
          });
        }else{
          API_analysisChopdown(params).then(response => {
            if (response && response.data && response.data.code === 0) {
              resolve(response);
            } else {
              reject(response);
            }
          }).catch(error => {
            reject(error);
          });
        }
        
      }else{
        API_analysisChopdown_english(params).then(response => {
          if (response && response.data && response.data.code === 0) {
            resolve(response);
          } else {
            reject(response);
          }
        }).catch(error => {
          reject(error);
        });
      }
    });
  };

  doChopdown = (reget = false) => {
    let me = this, child_arr = [];
    if(me.nodeType === -1){
      me.doExpand(me.props.nodeInfo);
    }
    if(me.doDelectedNode && me.connectedNodes){
      me.connectedNodes.length>0 && me.connectedNodes.forEach((item, idx) => {
        if(idx<3){
          child_arr.push(item['fname'].replace(/、/g, " ").substring(0,30));
        }
      })
      let params ={
        view_id:me.props.viewId, 
        node_id:me.props.nodeInfo.id, 
        node_nm:me.props.nodeInfo.fname, 
        node_tag:me.props.nodeInfo.tag,
        chop_way:me.nodeType,
        child:child_arr.join("、"),
        reget:reget?1:0,
        bak:me.bak,
        by_keyword:1
      }
      if(me.nodeType==10){
        params.node_description=me.props.nodeInfo.description;
      }
      let nodeType = me.nodeType;
      let startTime = parseInt((new Date().getTime()).toString());
      me.timer = setTimeout(() => {
        me.setState({showMsg: true,showMsgText:intl.get('Custom.message.calculation')})
      }, 800);
      me.allChopList = [];
      me.bak=0;
      me.getAnalysisChopdown(params).then(response => {
        me.clearTimer();
        if (response && response.data && response.data.code === 0 && response.data.data.length>0) {
          response.data.data.forEach((d, index) => {
            if(d.phr_down.length>0){
              let data_arr = d.phr_down.split('^^');
              if(data_arr.length>0){
                let _data = [];
                data_arr.forEach((item, idx) => {
                  _data.push({id:idx,label:item});
                });
                me.allChopList.push(_data);
              }
            }
          });
          if(me.allChopList.length>0){
            let _d = [],nodeListSelectionMap={};
            me.allChopList[0].forEach((i, idx) => {
              _d.push(i);
              nodeListSelectionMap[idx] = false;
            });
            if(nodeType === me.nodeType){
              me.setState({
                status: 'success',
                nodeList: _d.slice(0,5),
                nodeListAllSelected: false,nodeListAllSelectedIndeterminate: false,nodeListSelectionMap,nodeListSelectedAmount:0,
                showMsg: false,
                showMsgText: []
              }, () => {
                me.timer = setTimeout(() => {
                  if (me.props.visible && !me.isMouseOver) {
                    me.setState({status: 'idle'})
                  }
                }, 2000);
              });
            }
            let getDataTime  = parseInt((new Date().getTime()).toString());
            if(getDataTime-startTime>2000){
              PB.emit('aiConsole', 'message.push', {
                type: 'user',
                content: `${params.node_nm}`
              });
              let messageKey;
              PB.emit('aiConsole', 'message.push', {
                type: 'ai',
                content: (<span><Icon name={'loading'} style={{marginRight: '0.5em'}}/>{intl.get('Custom.message.loadExactMatch')}</span>),
                callback: ({key}) => messageKey = key,
                delay: 0,
              });
              me.showAiConsole(params.node_nm,nodeType,_d,messageKey);
            }
          }else{
            me.setState({
              status: 'failed',
              showMsg: this.state.keepShow?true:false,
              showMsgText: params.bak===0?intl.get('Custom.message.noCalculationResultRedo'):intl.get('Custom.message.noCalculationResult')
            }, () => {
              if(params.bak===1){
                me.timer = setTimeout(() => {
                  if (me.props.visible && !me.isMouseOver) {
                    me.setState({status: 'idle'})
                  }
                }, 2000);
              }
            });
            if(params.bak===0){
              me.bak=1;
              me.doChopdown(reget);
            }
          }    
        } else {
          me.setState({
            status: 'failed',
            showMsg: this.state.keepShow?true:false,
            showMsgText: params.bak===0?intl.get('Custom.message.noCalculationResultRedo'):intl.get('Custom.message.noCalculationResult')
          }, () => {
            if(params.bak===1){
              me.timer = setTimeout(() => {
                if (me.props.visible && !me.isMouseOver) {
                  me.setState({status: 'idle'})
                }
              }, 2000);
            }
          });
          if(params.bak===0){
            me.bak=1;
            me.doChopdown(reget);
          }
        }
      }).catch(error => {
        console.warn(`get api failed`,error);
        me.setState({
          status: 'failed',
          showMsg: false,
          showMsgText : []
        });
      });
    }else{
      setTimeout(() => {
        me.doChopdown(reget);
      }, 100);
    }
  };

  doExchange = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let me = this;
    let chop_idx = me.state.chop_idx+1;
    if(chop_idx<me.allChopList.length){
      let _d = [],nodeListSelectionMap={};
      me.allChopList[chop_idx].forEach((i, idx) => {
        _d.push(i);
        nodeListSelectionMap[idx] = false;
      });
      me.setState({
        chop_idx,
        status: 'success',
        nodeList: _d.slice(0,5),
        nodeListAllSelected: false,nodeListAllSelectedIndeterminate: false,nodeListSelectionMap,nodeListSelectedAmount:0,
        showMsg: false,
        showMsgText: []
      });    
    }else{
      me.setState({nodeList: [],showMsg: false,keepShow: true,chop_idx:0}, () => {
        me.doChopdown(true);
      })
    }
  }

  addNodes = (e) => {
    e.stopPropagation();
    let me = this;
    me.addNodeList = [];
    let nodeIds = Object.keys(me.state.nodeListSelectionMap).filter(id => me.state.nodeListSelectionMap[id]);
    if(nodeIds.length>0){
      if(me.nodeType === -1){
        me.doLink(me.props.nodeInfo.id,nodeIds);
      }else{
        me.doAdd(me.props.nodeInfo.id,nodeIds);
      }
    }else{
      message.warn(`${me.nodeType === -1?intl.get('Custom.message.pleaseSelectConnected'):intl.get('Custom.message.pleaseSelectAdded')}`);
      return;
    }
  }
  doLink = (nodeId, nodeIds) => {
    let me = this;
    me.props.viewDataProvider.addRelation(nodeId, nodeIds).then(() => {
      try {
        message.success(intl.get('Custom.general.operationSucceeded'))
        this.props.bus.emit('relation', 'find_node_by_text.start', {text: ''})
      } catch (e) {
        // ignore
      }
    }); 
  }

  doAdd = (nodeId, nodeIds) => {
    let me = this;
    let addNodeList =[];
      me.state.nodeList.forEach((item, idx) => {
        if(me.state.nodeListSelectionMap[item.id]){
          let node = {
            index: idx,
            fname: item.label,
            fx: null,
            fy: null,
            fixed: false,
            type: 0,
            userConfirmed: true
          }
          addNodeList.push(node);
        }
      })
      this.props.bus.emit('network', 'addNode', {
        autoParse: 'expand-star',
        addNodeList: addNodeList,
        successCB: () => {
          try {
            message.success(intl.get('Custom.general.operationSucceeded'))
            this.props.bus.emit('relation', 'find_node_by_text.start', {text: ''})
          } catch (e) {
            // ignore
          }
        },
        failedCB: () => {
          
        },
        interruptedCB: () => {
          message.warn(intl.get('Custom.general.operationTerminated'))
        }
      });
  };

  moreAnalysis = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let me = this
    //me.props.bus.emit('right.box.analysis', 'analysis.nodeforedge.list.show', 
    //  {viewId:me.props.viewId,nodeId:me.props.nodeInfo.id,nodeInfo:{id:me.props.nodeInfo.id,fname:me.props.nodeInfo.fname,tag:me.props.nodeInfo.tag,description:me.props.nodeInfo.description}});
    me.props.bus.emit('searchinput', 'ety.list.show', {keyword: me.props.nodeInfo.fname});
    
  }

  gotoIncidentList  = (keyword) => {
    let me = this;
    me.props.bus.emit('analysis', 'incident.list.show',{viewId: me.props.viewId,keyword:keyword});
  };

  onNodeListSelectAllChanged = e => {
    let me = this, nodeListAllSelected = e.target.checked, nodeListSelectionMap = {}, nodeListSelectedAmount = 0;
    if (nodeListAllSelected) {
      Object.keys(me.state.nodeListSelectionMap).forEach(k => nodeListSelectionMap[k] = true);
      nodeListSelectedAmount = Object.keys(me.state.nodeListSelectionMap).length;
    }else{
      Object.keys(me.state.nodeListSelectionMap).forEach(k => nodeListSelectionMap[k] = false);
    }
    me.setState({
      nodeListAllSelected,
      nodeListAllSelectedIndeterminate: false,
      nodeListSelectionMap,
      nodeListSelectedAmount,
    });
  };

  onNodeListSelectionChanged = (nodeId, e) => {
    let me = this, selected = e.target.checked,
      nodeListSelectedAmount = me.state.nodeListSelectedAmount,
      nodeListSelectionMap = {...me.state.nodeListSelectionMap};

    if (selected) {
      nodeListSelectedAmount++;
      nodeListSelectionMap[nodeId] = true;
    } else {
      nodeListSelectedAmount--;
      nodeListSelectionMap[nodeId] = false;
    }

    me.setState({
      nodeListAllSelected:  Object.keys(me.state.nodeListSelectionMap).length === nodeListSelectedAmount,
      nodeListAllSelectedIndeterminate: nodeListSelectedAmount > 0 &&  Object.keys(me.state.nodeListSelectionMap).length > nodeListSelectedAmount,
      nodeListSelectionMap: nodeListSelectionMap,
      nodeListSelectedAmount: nodeListSelectedAmount,
    });
  };

  onCopyNodeInfo = node => {
    let result = copy(node.label, {
      message:  intl.get('Custom.general.pleasePress') + ' #{key} ' + intl.get('Custom.general.copySelectedText')
    });

    if (result) message.success(intl.get('Custom.message.recommendedClipboard'));
  };

  copySelectedNodesToClipboard = () => {
    let me = this, textList = [];
    me.state.nodeListSelectedAmount>0 && this.state.nodeList.forEach(node => {
      if (node && me.state.nodeListSelectionMap[node.id]) {
        textList.push(node.label);
      }
    })
    if (textList.length <= 0) {
      message.warn(intl.get('Custom.message.selectNodeCopy'));
      return;
    }
    let result = copy(textList.join("\r\n"), {
      message: intl.get('Custom.general.pleasePress') + ' #{key} ' + intl.get('Custom.general.copySelectedText')
    });

    if (result) message.success(intl.get('Custom.message.recommendedClipboard'));
  };

  switchMidTab = (e, val) => {
    e.stopPropagation();
    let me = this;
    me.nodeType = val ;
    me.resetData(() => {
      switch (me.nodeType) {
        case -1:
          me.doExpand(me.props.nodeInfo);
          break;
        default:
          me.doChopdown();
      }
    })
  }
  
  onGetAnswerModal = node => {
    let me = this;
    const getAnswerModal = Modal.confirm({
      centered: true,
      destroyOnClose: true,
      title: "获取答案",
      icon: <Icon type={IconTypes.ICON_FONT} name="icon-china_adc-999999"/>,
      okText: "确认",
      cancelText: "取消",
      width: 520,
      content: <span>将在右侧AI对话框中进行问答，是否继续？<br/>{node.label}</span>,
      onOk: () => {
        this.props.bus.emit('aiConsole', 'input.send', {inputText: node.label});
      },
      onCancel: () => {
        this.props.bus.emit('aiConsole', 'input.changed', {inputText: node.label});
        getAnswerModal.destroy();
      },
  })
  };

  onNodeToDo = node => {
    let me = this;
    if(me.nodeType===-1){
      PB.emit('network', 'focus', node);
    }else if(me.nodeType===10){
      me.onGetAnswerModal(node);
    }else{
      me.onCopyNodeInfo(node);
    }
  };

  renderNodeItem = (node, index) => {
    let me = this;
    return node?(
      <div
        key={`node-item-${index}`}
        className={`${style['item']}`}
        onClick={e => {
          e.stopPropagation();
          me.onNodeToDo(node);
        }}
      >
        <div style={{display: 'flex', alignItems: 'center', width: '100%'}}>
          <Checkbox style={{marginRight: '8px',float:'left'}}
            onClick={e => e.stopPropagation()}
            onChange={e => me.onNodeListSelectionChanged(node.id, e)}
            checked={me.state.nodeListSelectionMap[node.id] === true}/>
          {index>-1 && <view className={style['tree-line']}>
						<view className={style['tree-line-inner']}></view>
					</view>}
          <span className={`${style['name']}`}>
            <Tooltip placement="top" title={node.label.replace(/<[^>]+>/gi, '')} arrowPointAtCenter>{index+1}. {node.label.replace(/<[^>]+>/gi, '')}</Tooltip>
          </span>
        </div>
      </div>
    ):null;
  };

  renderMsg = (txt) => {
    return (
      <div>{txt} </div>
    );
  };

  renderNodeList = () => {
    let me = this;

    return me.state.showMsg || me.state.nodeList.length > 0 ? (
      <>
        {intl.get('locale')==='zh-cn' &&
        <div className={style['mid-tab']}>
          <Tooltip placement="top" title={'图谱中已有的相关节点'} arrowPointAtCenter><span className={me.nodeType === -1 ? style['mid-tab-current'] : null} style={{marginLeft:'0rem'}} onClick={e => me.switchMidTab(e,-1)}><Icon name={'icon-smooth-line'} type={IconTypes.ICON_FONT}/>已有节点</span></Tooltip>
          <Tooltip placement="top" title={'根据选中节点和相连节点，机器人智能推荐生成新节点'} arrowPointAtCenter><span className={me.nodeType === 0 ? style['mid-tab-current'] : null} onClick={e => me.switchMidTab(e,0)}><Icon name={'icon-baxin'} type={IconTypes.ICON_FONT}/>机器人推荐</span></Tooltip>
          <Tooltip placement="top" title={'根据选中节点和相连节点，向机器人提问'} arrowPointAtCenter><span className={me.nodeType === 10 ? style['mid-tab-current'] : null} onClick={e => me.switchMidTab(e,10)}><Icon name={'icon-help'} type={IconTypes.ICON_FONT}/>向机器人提问</span></Tooltip>
        </div>}
        {intl.get('locale')!=='zh-cn' &&
        <div className={style['mid-tab']}>
          <Tooltip placement="top" title={'Connect the existing nodes in the current map'} arrowPointAtCenter><span className={me.nodeType === -1 ? style['mid-tab-current'] : null} style={{marginLeft:'0rem'}} onClick={e => me.switchMidTab(e,-1)}><Icon name={'icon-smooth-line'} type={IconTypes.ICON_FONT}/>Links</span></Tooltip>
          <Tooltip placement="top" title={'Phrases'} arrowPointAtCenter><span className={me.nodeType === 1 ? style['mid-tab-current'] : null} onClick={e => me.switchMidTab(e,1)}><Icon name={'icon-baxin'} type={IconTypes.ICON_FONT}/>Phrases</span></Tooltip>
          <Tooltip placement="top" title={'Words'} arrowPointAtCenter><span className={me.nodeType === 2 ? style['mid-tab-current'] : null} onClick={e => me.switchMidTab(e,2)}><Icon name={'icon-institute'} type={IconTypes.ICON_FONT}/>Words</span></Tooltip>
          <Tooltip placement="top" title={'Sentences'} arrowPointAtCenter><span className={me.nodeType === 3 ? style['mid-tab-current'] : null} onClick={e => me.switchMidTab(e,3)}><Icon name={'icon-changfangbiaozhunhetong'} type={IconTypes.ICON_FONT}/>Sentences</span></Tooltip>
        </div>}
        <div style={{width: '98%', margin: '.3rem auto', borderBottom: '1px dashed rgba(201, 201, 201, 0.3)'}}/>
        <div className={`${style['list']}`}>
          {me.state.nodeList && me.state.nodeList.length>0 && me.state.nodeList.map(me.renderNodeItem)}
          {me.state.showMsg && <div className={style['msg-box']}>{me.state.showMsgText.map(me.renderMsg)}</div>}
        </div>
        <div style={{width: '98%', margin: '.3rem auto', borderBottom: '1px dashed rgba(201, 201, 201, 0.3)'}}/>
        <div className={style['action-box']}>
          <Checkbox
            indeterminate={me.state.nodeListAllSelectedIndeterminate}
            checked={me.state.nodeListAllSelected}
            onChange={e => me.onNodeListSelectAllChanged(e)}
          >
            {intl.get('Custom.form.checkAll')}
          </Checkbox>
          {intl.get('locale')==='zh-cn' &&
          <Tooltip
            placement={"bottom"}
            title={intl.get('Custom.general.dynamicInformationBase')}
            overlayClassName={'dark-theme'}
          >
            <a
              onClick={e => this.moreAnalysis(e)}
              style={{float: 'right',marginLeft:'.5rem'}}
            >
              
              <Icon name="icon-search" theme="outlined" type={IconTypes.ICON_FONT}
              style={{marginRight: '.5rem', textAlign: 'right'}}
            />
            </a>
          </Tooltip>
          }
          {me.nodeType !== -1 && 
          <Tooltip
            placement={"bottom"}
            title={intl.get('Custom.general.addToMap')}
            overlayClassName={'dark-theme'}
          >
            <a
              className={me.state.nodeListSelectedAmount === 0 ? 'disabled' : null}
              onClick={e => this.addNodes(e)}
              style={{float: 'right',marginLeft:'.5rem'}}
            >
              <Icon name={'plus'}/>
            </a>
          </Tooltip>}
          {me.nodeType === -1 && 
          <Tooltip
            placement={"bottom"}
            title={intl.get('Custom.general.connectNode')}
            overlayClassName={'dark-theme'}
          >
            <a
              className={me.state.nodeListSelectedAmount === 0 ? 'disabled' : null}
              onClick={e => this.addNodes(e)}
              style={{float: 'right',marginLeft:'.5rem'}}
            >
              <Icon name={'icon-smooth-line'} type={IconTypes.ICON_FONT}/>
            </a>
          </Tooltip>}
          <Tooltip
            placement={"bottom"}
            title={intl.get('Custom.general.copySelectedNode')}
            overlayClassName={'dark-theme'}
          >
            <a
              className={me.state.nodeListSelectedAmount === 0 ? 'disabled' : null}
              onClick={me.copySelectedNodesToClipboard}
              style={{float: 'right',marginLeft:'.5rem'}}
            >
              <Icon name={'snippets'}/>
            </a>
          </Tooltip>
          {me.nodeType !== -1 && 
          <Tooltip
            placement={"bottom"}
            title={intl.get('Custom.general.switchNext')}
            overlayClassName={'dark-theme'}
          >
            <a
              onClick={e => this.doExchange(e)}
              style={{float: 'right',marginLeft:'.5rem'}}
            >
              <Icon name={'icon-refresh'} type={IconTypes.ICON_FONT}/>
            </a>
          </Tooltip>}
        </div>
      </>
    ) : null;
  }

  showAiConsole = (fname,nodeType,nodeList,messageKey) => {
    let me = this;
    message.info(`${intl.get('Custom.view.node')} “${fname}” ${intl.get('Custom.view.calculationCompleted')}`,5);
    if(nodeList && nodeList.length>0){
      PB.emit('aiConsole', 'message.update', {
        key: messageKey,
        content: (
          <div>
            {fname}：
            <ul className={style['ai-node-ul']}>{nodeList.map((node,idx) => (<li className={style['ai-node-li']}>{node.label}</li>))}</ul>
          </div>
        ),
        delay: 0,
      });
    }        
  }

  clearTimer = () => {
    let me = this;
    if (me.timer) {
      clearTimeout(me.timer);
      me.timer = undefined;
    }
  }

  resetData = (callback) => {
    let me = this;
    me.addNodeList = [];
    me.allChopList = [];
    me.clearTimer();
    me.setState({
      chop_idx: 0,
      nodeList: [],
      nodeListAllSelected: false,nodeListAllSelectedIndeterminate: false,nodeListSelectionMap:{},nodeListSelectedAmount:0,
      showMsg: false,showMsg: false,showMsgText:[],keepShow: true,
    },() => {
      callback && callback();
    });
    
  }

  componentDidMount() {
    let me = this;
    me.props.bus.with(me).subscribe("network", "selectedNode", (node) => {
      me.connectedNodes = node.connectedNodes;
      me.doDelectedNode = true;
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let me = this, nodeInfo = me.props.nodeInfo, previousNodeInfo = prevProps.nodeInfo;

    if (
      (nodeInfo && !prevProps.visible && me.props.visible)
      || (me.props.visible && nodeInfo && (!previousNodeInfo || nodeInfo.id !== previousNodeInfo.id))
    ) {
      // 节点信息存在，面板从不可见变为可见，自动加载推荐填充信息
      // 面板可见，节点信息发生变化，自动加载推荐填充信息
      me.setState({
        status: 'processing',
        showBox:true,
        nodeList: []
      });
      // 自动隐藏数据初始化
      me.isMouseOver = false;
      me.clearTimer();
      let networkData = me.props.viewDataProvider.getData();
      let allMount = networkData.data.nodes.get().length;
      me.nodeType=-1;
      me.allNodeMount = allMount;
      // 触发
      if(me.nodeType === -1){
        me.doExpand(nodeInfo);
      }else{
        me.doChopdown();
      }
    }else if(previousNodeInfo && !nodeInfo && prevProps.visible && !me.props.visible){
      me.clearTimer();
      me.timer = setTimeout(() => {
        me.setState({showBox: false})
      }, 2000); 
    }
  }

  componentWillUnmount() {
    let me = this;
    me.resetData();
    me.doDelectedNode = false;
  }

  render() {
    let me = this;
    //let visible = me.props.visible && (me.state.showMsg || (me.state.status !== 'idle' && me.state.status !== 'failed'));
    let visible = me.props.visible && me.state.status !== 'idle';
    return  me.state.showBox ? (
      <div
        className={`${style['frame']} dark-theme ${visible ? style['fadeIn'] : style['fadeOut']}`}
        onMouseEnter={e => {
          e.stopPropagation();
          me.isMouseOver = true;
        }}
      >
        {me.state.showMsg || (me.state.nodeList && me.state.nodeList.length>0) ? me.renderNodeList() : (
            <div className={style['tips']}>
              <span style={{position: 'relative', top: '-4px'}}>... ...</span>
            </div>
          )
        }
      </div>
    ):null;
  }
}

NodeExpandAnalysis.defaultProps = {
  bus: PB,
};

NodeExpandAnalysis.propTypes = {
  bus: PropTypes.instanceOf(SimplePB),
  viewDataProvider: PropTypes.instanceOf(ViewDataProvider).isRequired,
  visible: PropTypes.bool.isRequired,
  viewId: PropTypes.string,
  nodeInfo: PropTypes.object,
};

export default NodeExpandAnalysis;