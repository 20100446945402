import React from 'react';
import PropTypes from 'prop-types';
import {Button, Input, List, Modal, Progress, Steps, Tooltip} from 'antd';

import PB, {SimplePB} from "@/libs/simplePB";

import ViewDataProvider from "@/components/common/dataProvider/common.dataProvider.view";
import Icon from "@/components/common/common.icon";

import style from "@/style/common/view/common.view.rank.less";
import {IconTypes} from "@/constants/common";
import UserDataProvider from "@/components/common/dataProvider/common.dataProvider.user";
import {showErrorMessage} from "@/components/common/common.message";

const nameMap = {
  word_freq: '词汇排名',
  company_con: '企业排名',
  custom: '定制排名',
};

const trendMark = {
  'flat': '--',
  'up': '↑',
  'new': '↑↑',
  'down': '↓',
};

const trendTooltip = {
  'flat': '排名不变',
  'up': '排名上升',
  'new': '新上榜',
  'down': '排名下降',
};

const rankColor = {
  'pos_1': '#F28B54',
  'pos_2': '#F7B500',
  'pos_3': '#F6D95E',
  '_default': '#c8c8c8',
};

class RankListModal extends React.PureComponent {
  state = {
    loadingStatus: 'idle',
    customRequest: '',
  };

  rankList = [];

  customMessageKey = undefined;

  doLoadRankList = () => {
    let me = this, messageKey, type = me.props.type, loadFn = () => {
      me.props.bus.emit('aiConsole', 'message.push', {
        type: 'ai',
        content: (<span><Icon name={'loading'} style={{marginRight: '0.5em'}} />{nameMap[type]}加载中，请稍后...</span>),
        callback: ({key}) => messageKey = key,
        delay: 200,
      });
      me.props.viewDataProvider.loadRankData(type).then(data => {
        if (data['rank_data']) data = data['rank_data'];
        let max = Math.max.apply(Math, data.map(e => e.rate));
        me.rankList = data.map(e => ({...e, percent: Math.round(e.rate / max * 100)}));
        me.props.bus.emit('aiConsole', 'message.update', {
          key: messageKey,
          content: (
            <div style={{lineHeight: '1.5rem'}}>
              获取图谱{nameMap[type]}成功：<br />
              {data[0] && (<div><span style={{display: 'inline-block', paddingRight: '0.5rem'}}>1.</span>{data[0].label}</div>)}
              {data[1] && (<div><span style={{display: 'inline-block', paddingRight: '0.5rem'}}>2.</span>{data[1].label}</div>)}
              {data[2] && (<div><span style={{display: 'inline-block', paddingRight: '0.5rem'}}>3.</span>{data[2].label}</div>)}
            </div>
          ),
          actions: [(
            <Tooltip title={'查看排名'} key={'reload'}>
              <Button
                shape={'circle'}
                icon={'ordered-list'}
                style={{float: 'right'}}
                className={'last'}
                onClick={() => me.props.bus.emit('view', 'rank.show_modal',
                  {viewId: me.props.viewDataProvider.viewId, type})}
              />
            </Tooltip>
          )],
        });
        me.setState({loadingStatus: 'success'});
      }).catch((/*{code, msg}*/) => {
        me.setState({loadingStatus: 'failed'});
        /*showErrorMessage({ code, msg, extra: { viewId: me.state.viewId, isModification: false } });*/
        me.props.bus.emit('aiConsole', 'message.update',
          {key: messageKey, content: `${nameMap[type]}尚未计算完成`});
      });
    };

    if (me.customMessageKey !== undefined) {
      me.props.bus.emit('aiConsole', 'message.update',
        {key: me.customMessageKey, content: `用户取消操作`});
      me.customMessageKey = undefined;
    }

    if (me.props.type === 'custom') {
      me.props.bus.emit('aiConsole', 'message.push', {
        type: 'ai',
        content: (<span><Icon name={'loading'} style={{marginRight: '0.5em'}} />等待用户提供更多信息</span>),
        callback: ({key}) => me.customMessageKey = key,
        delay: 200,
      });
    } else if (me.state.loadingStatus !== 'processing') {
      me.setState({loadingStatus: 'processing'}, loadFn);
    } else {
      loadFn();
    }
  };

  onRequestCustomRankList = () => {
    let me = this, feedback = `定制排名，图谱：${me.props.visible ? me.props.viewDataProvider.viewInfo.name : ''}(${me.props.visible ? me.props.viewDataProvider.viewId : ''})，定制需求：${me.state.customRequest}`;

    me.props.bus.emit('aiConsole', 'message.update', {
      key: me.customMessageKey,
      content: (
        <span><Icon name={'loading'} style={{marginRight: '0.5em'}} />定制排名需求正在提交，请稍后...</span>
      ),
    });
    UserDataProvider.sendFeedback(feedback).then(() => {
      me.props.bus.emit('aiConsole', 'message.update',
        {key: me.customMessageKey, content: '定制排名需求已提交，稍后会有客服人员与你联系。'});
      me.customMessageKey = undefined;
      me.props.onClose();
    }).catch(({code, msg}) => {
      showErrorMessage({ code, msg, extra: { viewId: me.state.viewId, isModification: false } });
      me.props.bus.emit('aiConsole', 'message.update', {
        type: 'ai',
        content: (<span><Icon name={'loading'} style={{marginRight: '0.5em'}} />等待用户提供更多信息</span>),
        key: me.customMessageKey,
      });
    });
  };

  searchRankItem = async item => {
    let me = this;
    me.props.bus.emit('aiConsole', 'message.push', {
      type: 'ai',
      content: `搜索${nameMap[me.props.type]}中的“${item.label}”，归入“自选集合”筛选项`,
    });
    me.props.bus.emit('view', 'rank.search.item', {rankTypeName: nameMap[me.props.type], rankItem: item});
  };

  componentDidMount() {
    let me = this;

    if (me.props.visible) {
      // 加载排名
      me.doLoadRankList();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let me = this;

    if ((!prevProps.visible && me.props.visible) ||
      (me.props.visible && me.props.type !== prevProps.type && me.props.type)) {
      // 加载排名
      me.doLoadRankList();
    }
  }

  render() {
    let me = this, content = null;

    if (me.props.type && me.props.type !== 'custom') {
      switch (me.state.loadingStatus) {
        case 'idle':
        case 'processing':
          content = (
            <div className={style['rank-list-loading']}>
              <div>
                <Icon name="loading" theme="outlined"/><br/>
                数据加载中，请稍候...
              </div>
            </div>
          );
          break;
        case 'success':
          if (me.rankList.length > 0) {
            content = (
              <List
                className={style['rank-list-content']}
                itemLayout={'horizontal'}
                dataSource={me.rankList}
                renderItem={item => (
                  <List.Item onClick={e => {
                    e.stopPropagation();
                    me.searchRankItem(item);
                  }} style={{cursor: 'pointer'}}>
                    <div>
                      <div>
                        <span style={{display: 'inline-block', width: '4rem', verticalAlign: 'middle'}}>TOP.{item.pos}</span>
                        <span style={{display: 'inline-block', width: '14rem', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', verticalAlign: 'middle'}}>{item.label}</span>
                        <span style={{display: 'inline-block', float: 'right', verticalAlign: 'middle'}}>
                          {/*<span>{item.rate}</span>*/}
                          <span
                            className={item['trend']}
                            style={{display: 'inline-block', width: '1.8rem', textAlign: 'right'}}
                          >
                            <Tooltip title={trendTooltip[item['trend']]}>
                              {trendMark[item['trend']]}
                            </Tooltip>
                          </span>
                        </span>
                      </div>
                      <Progress
                        size="small"
                        strokeColor={rankColor[`pos_${item.pos}`] || rankColor['_default']}
                        strokeWidth={6}
                        percent={item.percent}
                        showInfo={false}
                      />
                    </div>
                  </List.Item>
                )}
              />
            );
            break;
          }
        // noinspection FallThroughInSwitchStatementJS
        case 'failed':
        default:
          content = (
            <div className={style['rank-list-loading']}>
              <div>
                <Icon name="info-circle" theme="outlined"/><br/>
                排名尚未计算完成
              </div>
            </div>
          );
      }
    } else if (me.props.type === 'custom') {
      content = (
        <div>
          <div style={{marginBottom: '1rem', opacity: 0.6}}>通过对图谱从不同维度的排名分析，可以全局快速了解图谱的主要信息。</div>
          <Steps direction="vertical" current={0} className={style['rank-list-custom-steps']}>
            <Steps.Step
              title="请告诉我们您的关注点"
              description={(
                <Input.TextArea
                  rows={4}
                  value={me.state.customRequest}
                  onChange={({target: {value}}) => me.setState({customRequest: value})}
                />
              )}
            />
            <Steps.Step title="我们将为您提供专属定制方案" status={'process'} />
            <Steps.Step title="我们会持续优化定制分析" status={'process'} />
          </Steps>
          <div style={{opacity: 0.6}}>
            <Icon type={IconTypes.ICON_FONT} name={'icon-email-open'} style={{marginRight: '0.5em'}} />
            提交后我们会尽快与您邮件联系。
          </div>
        </div>
      );
    }

    if (me.props.type === 'custom') {
      return (
        <Modal
          width={'38rem'}
          title={(
            <span>
              {nameMap[me.props.type]} - {me.props.visible ? me.props.viewDataProvider.viewInfo.name : ''}
            </span>
          )}
          footer={me.props.type === 'custom' ? undefined : false}
          visible={me.props.visible}
          onOk={me.onRequestCustomRankList}
          onCancel={() => {
            if (me.props.type === 'custom') {
              me.props.bus.emit('aiConsole', 'message.update',
                {key: me.customMessageKey, content: `用户取消操作`});
            }
            me.props.onClose();
          }}
          okText={'提交'}
          cancelText={'取消'}
          afterClose={() => me.setState({customRequest: ''})}
        >
          {content}
        </Modal>
      );
    } else {
      return content;
    }
  }
}

RankListModal.defaultProps = {
  bus: PB,
};

RankListModal.propTypes = {
  bus: PropTypes.instanceOf(SimplePB),
  type: PropTypes.string,
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  viewDataProvider: PropTypes.instanceOf(ViewDataProvider).isRequired,
};

export default RankListModal;