import React from 'react';
import PropTypes from 'prop-types';
import {Menu, message, Modal} from 'antd';
import copy from 'copy-to-clipboard';

import PB, {SimplePB} from '@/libs/simplePB';
import Node from '@/libs/view/Node';
import Edge from '@/libs/view/Edge';
import Network from '@/libs/vis/lib/network/Network';

import {IconTypes} from '@/constants/common';
import {getNodeDisplayTitle, NODE_TYPE_TEXT, TYPE_FIELD_NAME} from '@/constants/vis.defaultDefine.1';

import Icon from '@/components/common/common.icon';
import CommonContextMenu from '@/components/common/common.contextMenu';
import IconMart from '@/components/common/node/common.node.iconMart';
import {reloadCustomIconFromCache} from '@/components/common/node/common.node.customIcon';

import iconMartStyle from '@/style/common/node/common.node.iconMart.less';

const iconAlignToNodeHorizontal = () => (
  <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg">
    <line x1="5" y1="0" x2="5" y2="16" stroke="#fff" stroke-width="1"/>
    <circle cx="5" cy="5" r="2" stroke="#fff" stroke-width="1" fill="#fff"/>
    <circle cx="11" cy="12" r="2" stroke="#fff" stroke-width="1" fill-opacity="0"/>
  </svg>
);
const iconAlignToCenterHorizontal = () => (
  <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg">
    <line x1="8" y1="0" x2="8" y2="16" stroke="#fff" stroke-width="1"/>
    <circle cx="4" cy="4" r="2" stroke="#fff" stroke-width="1" fill-opacity="0"/>
    <circle cx="12" cy="12" r="2" stroke="#fff" stroke-width="1" fill-opacity="0"/>
  </svg>
);

const iconDivideHorizontal = () => (
  <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg">
    <circle cx="3" cy="8" r="2" stroke="#fff" stroke-width="1" fill-opacity="0"/>
	  <circle cx="8" cy="8" r="2" stroke="#fff" stroke-width="1" fill-opacity="0"/>
    <circle cx="13" cy="8" r="2" stroke="#fff" stroke-width="1" fill-opacity="0"/>
  </svg>
);

const iconAlignToNodeVertical = () => (
  <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg">
    <line x1="0" y1="11" x2="16" y2="11" stroke="#fff" stroke-width="1"/>
    <circle cx="5" cy="11" r="2" stroke="#fff" stroke-width="1" fill="#fff"/>
    <circle cx="11" cy="5" r="2" stroke="#fff" stroke-width="1" fill-opacity="0"/>
  </svg>
);
const iconAlignToCenterVertical = () => (
  <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg">
    <line x1="0" y1="8" x2="16" y2="8" stroke="#fff" stroke-width="1"/>
    <circle cx="4" cy="12" r="2" stroke="#fff" stroke-width="1" fill-opacity="0"/>
    <circle cx="11" cy="4" r="2" stroke="#fff" stroke-width="1" fill-opacity="0"/>
  </svg>
);
const iconDivideVertical = () => (
  <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg">
    <circle cx="8" cy="3" r="2" stroke="#fff" stroke-width="1" fill-opacity="0"/>
	  <circle cx="8" cy="8" r="2" stroke="#fff" stroke-width="1" fill-opacity="0"/>
    <circle cx="8" cy="13" r="2" stroke="#fff" stroke-width="1" fill-opacity="0"/>
  </svg>
);

const scaleText = ['微', '小', '中', '大'];

const alignText = {
  'align-to-center-horizontal': '垂直居中对齐',
  'align-to-node-horizontal': '垂直头部对齐',
  'align-to-center-vertical': '水平居中对齐',
  'align-to-node-vertical': '水平头部对齐',
  'divide-horizontal': '水平均匀分布',
  'divide-vertical': '垂直均匀分布',
};

class GraphNormalContextMenu extends React.Component {
  cachedIconInfo = [];

  onAlign = type => {
    let me = this, contextNode = me.props.node;

    me.onEditActions().then(nodes => {
      let nodePositions = me.props.network.getPositions(nodes.map(n => n.id)), xList, yList, centerX, centerY,
        r = undefined, currentX, currentY, step;
      switch (type) {
        case 'align-to-center-horizontal':
          xList = nodes.map(n => nodePositions[n.id].x);
          centerX = (Math.max.apply(Math, xList) + Math.min.apply(Math, xList)) / 2;
          r = nodes.map(n => ({id: n.id, x: centerX, y: nodePositions[n.id].y}));
          break;
        case 'align-to-node-horizontal':
          centerX = nodePositions[contextNode.id] ? nodePositions[contextNode.id].x : undefined;
          if (!isNaN(centerX)) {
            r = nodes.map(n => ({id: n.id, x: centerX, y: nodePositions[n.id].y}));
          }
          break;
        case 'align-to-center-vertical':
          yList = nodes.map(n => nodePositions[n.id].y);
          centerY = (Math.max.apply(Math, yList) + Math.min.apply(Math, yList)) / 2;
          r = nodes.map(n => ({id: n.id, x: nodePositions[n.id].x, y: centerY}));
          break;
        case 'align-to-node-vertical':
          centerY = nodePositions[contextNode.id] ? nodePositions[contextNode.id].y : undefined;
          if (!isNaN(centerY)) {
            r = nodes.map(n => ({id: n.id, x: nodePositions[n.id].x, y: centerY}));
          }
          break;
        case 'divide-horizontal':
          xList = nodes.map(n => nodePositions[n.id].x);
          currentX = Math.min.apply(Math, xList);
          step = Math.round((Math.max.apply(Math, xList) - currentX) / (nodes.length - 1) * 100) / 100;
          currentX -= step;
          r = nodes.sort((a, b) => nodePositions[a.id].x - nodePositions[b.id].x).map(n => {
            currentX += step;
            return {id: n.id, x: currentX, y: nodePositions[n.id].y};
          });
          break;
        case 'divide-vertical':
          yList = nodes.map(n => nodePositions[n.id].y);
          currentY = Math.min.apply(Math, yList);
          step = Math.round((Math.max.apply(Math, yList) - currentY) / (nodes.length - 1) * 100) / 100;
          currentY -= step;
          r = nodes.sort((a, b) => nodePositions[a.id].y - nodePositions[b.id].y).map(n => {
            currentY += step;
            return {id: n.id, x: nodePositions[n.id].x, y: currentY};
          });
          break;
      }
      if (r) {
        let positions = {};
        r.forEach(i => positions[i.id] = {x: i.x, y: i.y});
        me.props.bus.emit('network', 'node.on_fix', {nodes, positions});
      }
    });
  };

  onEditActions = (nodes) => {
    let me = this;
    nodes = nodes || me.props.nodes;

    return new Promise((resolve, reject) => {
      let confirmText, canDoNodes = (me.props.viewOwnerId === me.props.currentUserId || me.props.enableCrossEdit)
        ? nodes.filter(n => !!n.status && n[TYPE_FIELD_NAME] === NODE_TYPE_TEXT)
        : nodes.filter(n => !!n.status && n[TYPE_FIELD_NAME] === NODE_TYPE_TEXT && n.userId === me.props.currentUserId);

      if (canDoNodes.length === nodes.length) {
        resolve(canDoNodes);
        return;
      } else if (me.props.viewOwnerId === me.props.currentUserId) {
        confirmText = (
          <span>共选中节点 {nodes.length} 个，其中的 <b>{canDoNodes.length}</b> 个可以修改，是否继续？</span>
        );
      } else {
        confirmText = (
          <span>共选中节点 {nodes.length} 个，您有权操作其中的 <b>{canDoNodes.length}</b> 个，是否继续？</span>
        );
      }
      Modal.confirm({
        title: confirmText,
        okText: '确认',
        onOk: () => resolve(canDoNodes),
        cancelText: '取消',
        onCancel: () => {
          reject();
        },
      });
    });
  };

  onCopyNodeTitles = () => {
    let me = this, textList = [];

    me.props.nodes.forEach(node => {
      textList.push(getNodeDisplayTitle(node));
    });

    let result = copy(textList.join("\r\n"), {
      message: '请按下 #{key} 复制节点标题。',
    });

    if (result) message.success('节点标题已复制到剪切板。');
  };

  onFixNodes = fix => {
    let me = this;

    me.onEditActions().then(nodes => {
      me.props.bus.emit('network', fix ? 'node.on_fix' : 'node.on_unfix', {nodes});
    });
  }

  onRemove = () => {
    this.props.bus.emit('relation', 'node.on_remove', this.props.nodes.map(n => n.id));
  };

  /**
   * 设置标记 - 子菜单展示
   */
  onSetFlag = (flag, extra, title) => {
    let me = this;

    me.onEditActions().then(nodes => {
      me.props.bus.emit('network', 'node.on_set_flag', {nodes, flag, extra, title});
    });
  };

  /**
   * 设置缩放比例
   * @param scale
   */
  onSetScale = scale => {
    let me = this;

    me.onEditActions().then(nodes => {
      me.props.bus.emit('network', 'node.on_set_scale', {nodes, scale});
    });
  };

  componentDidMount() {
    let me = this;

    reloadCustomIconFromCache().then(list => {
      me.cachedIconInfo = [...list].filter(i => !!i).slice(0, 3);
      me.forceUpdate();
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let me = this;

    if (me.props.visible && !prevProps.visible) {
      reloadCustomIconFromCache().then(list => {
        me.cachedIconInfo = [...list].filter(i => !!i).slice(0, 6);
        me.forceUpdate();
      });
    }
  }

  render() {
    let me = this, readOnly = me.props.readOnly
      || !me.props.nodes.find(n => !!n.status && n[TYPE_FIELD_NAME] === NODE_TYPE_TEXT) ||
      (me.props.viewOwnerId !== me.props.currentUserId && (!me.props.enableCrossEdit)
        && !me.props.nodes.find(n => n.userId === me.props.currentUserId));

    // noinspection SpellCheckingInspection
    return (
      <CommonContextMenu {...me.props}>
        <Menu.Item key={'copy-title'} onClick={me.onCopyNodeTitles}>
          <Icon name={'copy'} style={{marginRight: 0}}/>
          <span style={{marginLeft: '0.5em'}}>{`复制节点标题`}</span>
        </Menu.Item>
        <Menu.SubMenu
          popupClassName={'dark-theme'}
          key={'set_align'}
          disabled={readOnly}
          title={(
            <span>
              <Icon name={'icon-align-to-center-horizontal'} type={IconTypes.ICON_FONT} style={{marginRight: 0}}/>
              <span style={{marginLeft: '0.5em'}}>{`节点对齐`}</span>
            </span>
          )}
        >
          <Menu.Item
            key={'align-to-node-horizontal'}
            onClick={() => me.onAlign('align-to-node-horizontal')}
            disabled={!me.props.node}
          >
            <Icon component={iconAlignToNodeHorizontal}/>
            <span style={{marginLeft: '0.1em'}}>{alignText['align-to-node-horizontal']}</span>
          </Menu.Item>
          <Menu.Item key={'align-to-center-horizontal'} onClick={() => me.onAlign('align-to-center-horizontal')}>
          <Icon component={iconAlignToCenterHorizontal}/>
            <span style={{marginLeft: '0.1em'}}>{alignText['align-to-center-horizontal']}</span>
          </Menu.Item>
          <Menu.Item key={'divide-vertical'} onClick={() => me.onAlign('divide-vertical')}>
          <Icon component={iconDivideVertical}/>
            <span style={{marginLeft: '0.1em'}}>{alignText['divide-vertical']}</span>
          </Menu.Item>
          <Menu.Item
            key={'align-to-node-vertical'}
            onClick={() => me.onAlign('align-to-node-vertical')}
            disabled={!me.props.node}
          >
            <Icon component={iconAlignToNodeVertical}/>
            <span style={{marginLeft: '0.1em'}}>{alignText['align-to-node-vertical']}</span>
          </Menu.Item>
          <Menu.Item key={'align-to-center-vertical'} onClick={() => me.onAlign('align-to-center-vertical')}>
          <Icon component={iconAlignToCenterVertical}/>
            <span style={{marginLeft: '0.1em'}}>{alignText['align-to-center-vertical']}</span>
          </Menu.Item>
          <Menu.Item key={'divide-horizontal'} onClick={() => me.onAlign('divide-horizontal')}>
            <Icon component={iconDivideHorizontal}/>
            <span style={{marginLeft: '0.1em'}}>{alignText['divide-horizontal']}</span>
          </Menu.Item>
        </Menu.SubMenu>
        <Menu.SubMenu
          popupClassName={'dark-theme'}
          key={'set_flag'}
          disabled={readOnly}
          title={(
            <span>
              <Icon name={'flag'} type={IconTypes.FONT_AWESOME} style={{marginRight: 0}}/>
              <span style={{marginLeft: '0.5em'}}>{`设置标记`}</span>
            </span>
          )}
        >
          <Menu.Item className={iconMartStyle['icon-mart-menu-item']}>
            <IconMart
              onIconClicked={({value, extra, title}) => me.onSetFlag(value, extra, title)}
              customIcons={me.cachedIconInfo}
            />
          </Menu.Item>
        </Menu.SubMenu>
        <Menu.SubMenu
          popupClassName={'dark-theme'}
          key={'set_scale'}
          disabled={readOnly}
          title={(
            <span>
              <Icon name={'icon-scale'} type={IconTypes.ICON_FONT} style={{marginRight: 0}}/>
              <span style={{marginLeft: '0.5em'}}>{`缩放比例`}</span>
            </span>
          )}
        >
          {
            [50, 100, 200, 400].map((i, idx) => (
              <Menu.Item key={`scale-${idx}`} onClick={() => me.onSetScale(i / 100)}>
                <Icon
                  name={'icon-yuandianda'}
                  type={IconTypes.ICON_FONT}
                  style={{
                    marginRight: 0,
                    fontSize: `${0.2 * (idx + 2)}rem`,
                    // lineHeight: '1rem',
                    verticalAlign: `${0.12 - 0.12 * idx}rem`,
                  }}
                />
                <span style={{marginLeft: '0.5em'}}>{scaleText[idx]}（{`${i}%`}）</span>
              </Menu.Item>
            ))
          }
        </Menu.SubMenu>
        <Menu.Item key={'fix_nodes'} onClick={e => me.onFixNodes(true)} disabled={readOnly}>
          <Icon name={'pushpin'} style={{marginRight: 0, transform: 'rotate(-35deg)'}}/>
          <span style={{marginLeft: '0.5em'}}>{`固定节点位置`}</span>
        </Menu.Item>
        <Menu.Item key={'unfix_nodes'} onClick={e => me.onFixNodes(false)} disabled={readOnly}>
          <Icon name={'pushpin'} style={{marginRight: 0, transform: 'rotate(45deg)'}}/>
          <span style={{marginLeft: '0.5em'}}>{`取消固定位置`}</span>
        </Menu.Item>
        <Menu.Item key={'remove'} onClick={me.onRemove} disabled={readOnly}>
          <Icon name={'delete'} style={{marginRight: 0}}/>
          <span style={{marginLeft: '0.5em'}}>{`删除节点`}</span>
        </Menu.Item>
      </CommonContextMenu>
    );
  }
}

GraphNormalContextMenu.defaultProps = {
  visible: false,
  x: 0,
  y: 0,
  bus: PB,
  readOnly: false,
  enableCrossEdit: false,
};

GraphNormalContextMenu.propTypes = {
  visible: PropTypes.bool,
  x: PropTypes.number,
  y: PropTypes.number,
  bus: PropTypes.instanceOf(SimplePB),
  node: PropTypes.instanceOf(Node),
  nodes: PropTypes.arrayOf(PropTypes.instanceOf(Node)).isRequired,
  edges: PropTypes.arrayOf(PropTypes.instanceOf(Edge)).isRequired,
  network: PropTypes.instanceOf(Network).isRequired,
  readOnly: PropTypes.bool,
  currentUserId: PropTypes.number.isRequired,
  enableCrossEdit: PropTypes.bool,
  viewOwnerId: PropTypes.number.isRequired,
};

export default GraphNormalContextMenu;