import React from 'react';
import PropTypes from 'prop-types';
import {Menu, message} from 'antd';
import ViewStatisticsNodePanel from "@/components/common/view/statistics/node/common.view.statistics.node.panel";

const CONDITION_TITLE = {
  NAME_AREA_PROVINCE: '名称地区',
  NAME_AREA_CITY: '名称地区',
  NAME_AREA_DISTRICT: '名称地区',
  TAG_AREA_PROVINCE: '标签地区',
  TAG_AREA_CITY: '标签地区',
  TAG_AREA_DISTRICT: '标签地区',
  ICON: '图标类别',
  PROGRESS: '进度评估',
  FAVORITE: '收藏重要',
  AUTHOR: '节点用户',
};

const CONDITION_FULL_TITLE = {
  NAME_AREA_PROVINCE: '名称地区(省份)',
  NAME_AREA_CITY: '名称地区(城市)',
  NAME_AREA_DISTRICT: '名称地区(区县)',
  TAG_AREA_PROVINCE: '标签地区(省份)',
  TAG_AREA_CITY: '标签地区(城市)',
  TAG_AREA_DISTRICT: '标签地区(区县)',
  ICON: '图标类别',
  PROGRESS: '进度评估',
  FAVORITE: '收藏重要',
  AUTHOR: '节点用户',
};

class ViewStatisticsNodeGeneralPanel extends ViewStatisticsNodePanel {
  userMap = false;

  getMenuContent = () => {
    let me = this;

    return (
      <Menu className={'dark-theme'}>
        <Menu.SubMenu key={'m-name_area'} popupClassName={'dark-theme'} title="名称地区">
          <Menu.Item
            key={'m-name_area-province'}
            onClick={() => {
              me.delayComponentLostHover('menu', `n-statistics_menu`);
              me.setState({
                rankLoadingStatus: 'processing',
                conditionKey: 'NAME_AREA_PROVINCE',
                limit: 10,
                sort: 'desc',
              }, () => {
                me.props.bus.emit('node.statistics', 'node.do_load',
                  {nodes: me.props.nodes.filter(node => !!node.status), conditionKey: me.state.conditionKey,
                    limit: me.state.limit, sort: me.state.sort, cacheKey: me.state.cacheKey,
                    distinct: me.state.distinct});
              });
            }}
          >按省份</Menu.Item>
          <Menu.Item
            key={'m-name_area-city'}
            onClick={() => {
              me.delayComponentLostHover('menu', `n-statistics_menu`);
              me.setState({
                rankLoadingStatus: 'processing',
                conditionKey: 'NAME_AREA_CITY',
                limit: 10,
                sort: 'desc',
              }, () => {
                me.props.bus.emit('node.statistics', 'node.do_load',
                  {nodes: me.props.nodes.filter(node => !!node.status), conditionKey: me.state.conditionKey,
                    limit: me.state.limit, sort: me.state.sort, cacheKey: me.state.cacheKey,
                    distinct: me.state.distinct});
              });
            }}
          >按城市</Menu.Item>
          <Menu.Item
            key={'m-name_area-district'}
            onClick={() => {
              me.delayComponentLostHover('menu', `n-statistics_menu`);
              me.setState({
                rankLoadingStatus: 'processing',
                conditionKey: 'NAME_AREA_DISTRICT',
                limit: 10,
                sort: 'desc',
              }, () => {
                me.props.bus.emit('node.statistics', 'node.do_load',
                  {nodes: me.props.nodes.filter(node => !!node.status), conditionKey: me.state.conditionKey,
                    limit: me.state.limit, sort: me.state.sort, cacheKey: me.state.cacheKey,
                    distinct: me.state.distinct});
              });
            }}
          >按区县</Menu.Item>
        </Menu.SubMenu>
        <Menu.SubMenu key={'m-tag_area'} popupClassName={'dark-theme'} title="标签地区">
          <Menu.Item
            key={'m-tag_area-province'}
            onClick={() => {
              me.delayComponentLostHover('menu', `n-statistics_menu`);
              me.setState({
                rankLoadingStatus: 'processing',
                conditionKey: 'TAG_AREA_PROVINCE',
                limit: 10,
                sort: 'desc',
              }, () => {
                me.props.bus.emit('node.statistics', 'node.do_load',
                  {nodes: me.props.nodes.filter(node => !!node.status), conditionKey: me.state.conditionKey,
                    limit: me.state.limit, sort: me.state.sort, cacheKey: me.state.cacheKey,
                    distinct: me.state.distinct});
              });
            }}
          >按省份</Menu.Item>
          <Menu.Item
            key={'m-tag_area-city'}
            onClick={() => {
              me.delayComponentLostHover('menu', `n-statistics_menu`);
              me.setState({
                rankLoadingStatus: 'processing',
                conditionKey: 'TAG_AREA_CITY',
                limit: 10,
                sort: 'desc',
              }, () => {
                me.props.bus.emit('node.statistics', 'node.do_load',
                  {nodes: me.props.nodes.filter(node => !!node.status), conditionKey: me.state.conditionKey,
                    limit: me.state.limit, sort: me.state.sort, cacheKey: me.state.cacheKey,
                    distinct: me.state.distinct});
              });
            }}
          >按城市</Menu.Item>
          <Menu.Item
            key={'m-tag_area-district'}
            onClick={() => {
              me.delayComponentLostHover('menu', `n-statistics_menu`);
              me.setState({
                rankLoadingStatus: 'processing',
                conditionKey: 'TAG_AREA_DISTRICT',
                limit: 10,
                sort: 'desc',
              }, () => {
                me.props.bus.emit('node.statistics', 'node.do_load',
                  {nodes: me.props.nodes.filter(node => !!node.status), conditionKey: me.state.conditionKey,
                    limit: me.state.limit, sort: me.state.sort, cacheKey: me.state.cacheKey,
                    distinct: me.state.distinct});
              });
            }}
          >按区县</Menu.Item>
        </Menu.SubMenu>
        <Menu.Item
          key={'m-icon'}
          onClick={() => {
            me.delayComponentLostHover('menu', `n-statistics_menu`);
            me.setState({
              rankLoadingStatus: 'processing',
              conditionKey: 'ICON',
              limit: -1,
              sort: 'desc',
            }, () => {
              me.props.bus.emit('node.statistics', 'node.do_load',
                {nodes: me.props.nodes.filter(node => !!node.status), conditionKey: me.state.conditionKey,
                  limit: me.state.limit, sort: me.state.sort, cacheKey: me.state.cacheKey,
                  distinct: me.state.distinct});
            });
          }}
        >图标类别</Menu.Item>
        <Menu.Item
          key={'m-progress'}
          onClick={() => {
            me.delayComponentLostHover('menu', `n-statistics_menu`);
            me.setState({
              rankLoadingStatus: 'processing',
              conditionKey: 'PROGRESS',
              limit: 10,
              sort: false,
            }, () => {
              me.props.bus.emit('node.statistics', 'node.do_load',
                {nodes: me.props.nodes.filter(node => !!node.status), conditionKey: me.state.conditionKey,
                  limit: me.state.limit, sort: me.state.sort, cacheKey: me.state.cacheKey,
                  distinct: me.state.distinct});
            });
          }}
        >进度预估</Menu.Item>
        <Menu.Item
          key={'m-favorite'}
          onClick={() => {
            me.delayComponentLostHover('menu', `n-statistics_menu`);
            me.setState({rankLoadingStatus: 'processing', conditionKey: 'FAVORITE'}, () => {
              me.props.bus.emit('node.statistics', 'node.do_load',
                {nodes: me.props.nodes.filter(node => !!node.status), conditionKey: 'FAVORITE',
                  cacheKey: me.state.cacheKey, distinct: me.state.distinct, limit: 10});
            });
          }}
        >收藏重要</Menu.Item>
        <Menu.Item
          key={'m-author'}
          onClick={() => {
            me.delayComponentLostHover('menu', `n-statistics_menu`);
            if (!me.userMap) {
              message.info('暂时不支持按照用户统计');
              return;
            }
            me.setState({
              rankLoadingStatus: 'processing',
              conditionKey: 'AUTHOR',
              limit: 10,
              sort: 'desc',
            }, () => {
              me.props.bus.emit('node.statistics', 'node.do_load',
                {nodes: me.props.nodes.filter(node => !!node.status), conditionKey: me.state.conditionKey,
                  limit: me.state.limit, sort: me.state.sort, cacheKey: me.state.cacheKey,
                  distinct: me.state.distinct, extra: {userMap: me.userMap}});
            });
          }}
        >节点用户</Menu.Item>
      </Menu>
    );
  };

  getConditionCellTitle = key => CONDITION_TITLE[key];

  getConditionMenuTitle = (key, sort, limit) => `${CONDITION_FULL_TITLE[key]} ${(sort && limit > 0) ? 'Top' : ''}${(sort && limit > 0) ? limit : ''}`;

  componentDidMount() {
    super.componentDidMount();
    let me = this;

    me.props.bus.sub(me, 'teamwork', 'member.list.success', ({viewId, userList}) => {
      if (me.props.viewId !== viewId) return;
      me.userMap = {};
      userList.forEach(user => me.userMap[user.userId] = user);
      if (me.state.conditionKey === 'AUTHOR') {
        me.forceUpdate();
      }
    });

    if (me.props.viewId) {
      me.props.bus.emit('teamwork', 'member.list.do', {viewId: me.props.viewId});
    }
  }
}

ViewStatisticsNodeGeneralPanel.defaultProps = {
  ...ViewStatisticsNodePanel.defaultProps,
};

ViewStatisticsNodeGeneralPanel.propTypes = {
  ...ViewStatisticsNodePanel.propTypes,
  viewId: PropTypes.string,
};

export default ViewStatisticsNodeGeneralPanel;