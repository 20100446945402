import React from 'react';
import PropTypes from 'prop-types';
import {Menu, Badge, message, Modal, Tooltip, Button} from 'antd';

import PB, {SimplePB} from '@/libs/simplePB';

import {IconTypes} from "@/constants/common";
import {NODE_TYPE_TALENT} from '@/constants/nodeConfig';
import {getNodeDisplayTitle} from '@/constants/vis.defaultDefine.1';

import Icon from "@/components/common/common.icon";
import ViewDataProvider from '@/components/common/dataProvider/common.dataProvider.view';
import {showErrorMessage} from '@/components/common/common.message';
import CandidateNodeList from '@/components/common/view/explore/common.view.explore.candidateNodeList';

import style from '@/style/common/view/common.view.explore.less';
import relationStyle from '@/style/components/main.relation.less';

const personLinksAvailableViewIds = ['10fed8a4-29ea-4ff9-bd04-cbc9893170da', '27266c94-c406-4c07-a2a9-c59c7faabe6c'];

const overallAvailableViewIds = ['03936864-c728-4e8e-84f3-ed074dcda840'];

const exploreDomainStatus = {};

const explorePropsStatus = {};

const globalRefreshing = {
  refreshingInterval: undefined,
  refreshing: false,
};

class ExploreMenuTalent extends React.PureComponent {
  state = {
    recommendDetailStatus: 'idle',
    recommendDetailModalVisible: false,
    recommendDetailHasNewResult: 0,
  };

  processing = {};

  currentSelectedNodeId = undefined;

  onExploreOverall = () => {
    let me = this, userId = me.props.userInfo.userId;

    me.props.viewDataProvider.recommendByView(NODE_TYPE_TALENT, {userId}).catch(({code, msg}) => {
      showErrorMessage({code, msg, extra: {viewId: me.props.viewDataProvider.viewId, isModification: false}});
    });
  }

  onExploreRecommendDetail = () => {
    let me = this;

    me.props.bus.emit('view', 'explore.explore_talent.recommend_detail.show_modal',
      {viewId: me.props.viewDataProvider.viewId});
    if (me.state.recommendDetailHasNewResult > 0) {
      me.setState({recommendDetailHasNewResult: 0});
    }
  };

  onExploreDomain = (currentNodeId) => {
    let me = this;

    me.beforeCommonExplore(currentNodeId).then((nodeId, node) => {
      me.doCommonSyncExplore({
        statusMap: exploreDomainStatus,
        nodeId,
        node,
        exploreName: '同领域/同行发现',
        validNodeFn: (n) => !!(n && n.meta && n.meta['relByDomain']),
        doRequest: () => {
          return me.props.viewDataProvider.exploreSyncByDomainPerson(nodeId, {userId: me.props.userInfo.userId});
        },
      });
    }).catch(({showMsgFn}) => showMsgFn());
  }

  onExploreProps = (currentNodeId) => {
    let me = this;

    me.beforeCommonExplore(currentNodeId).then((nodeId, node) => {
      me.doCommonAsyncExplore({
        statusMap: explorePropsStatus,
        nodeId,
        node,
        exploreName: '个人社会关系发现',
        validNodeFn: (n) => !!(n && n.meta && n.meta['relByProps']),
        doRequest: () => {
          return me.props.viewDataProvider.exploreAsyncByProps(nodeId);
        },
      });
    }).catch(({showMsgFn}) => showMsgFn());
  };

  beforeCommonExplore = (currentNodeId) => {
    let me = this;

    currentNodeId = currentNodeId || me.currentSelectedNodeId;

    return new Promise((resolve, reject) => {
      if (!currentNodeId) {
        reject({msg: '请先选择一个节点', showMsgFn: () => message.info('请先选择一个节点')});
        return;
      }

      let targetNode = me.props.viewDataProvider.getNode(currentNodeId);
      if (!targetNode) {
        reject({msg: '请先选择一个节点', showMsgFn: () => message.info('请先选择一个节点')});
        return;
      }

      if (targetNode.status === 0) {
        Modal.confirm({
          title: (
            <span>
            <span>您选择的节点</span>
            <span className={relationStyle["node-in-msg"]}>{getNodeDisplayTitle(targetNode)}</span>
            <span>尚未保留，无法进行操作，是否立即保留并继续？</span>
          </span>
          ),
          okText: '保留并继续',
          cancelText: '取消',
          onOk: () => {
            me.props.bus.emit('network', 'node.do_save',
              {node: targetNode, success: () => resolve({nodeId: currentNodeId, node: targetNode})});
          },
        });
      } else {
        resolve({nodeId: currentNodeId, node: targetNode});
      }
    });
  };

  doCommonAsyncExplore = ({statusMap, nodeId, node, exploreName, validNodeFn, beforeRequest, doRequest, onResponse, afterResponse}) => {
    let me = this;

    // 如果操作正在进行，直接展示即可
    // 如果自动刷新已停止且为异步操作，重新开始即可
    // 其他情况，启动自动发现流程
    if (statusMap[nodeId] && !statusMap[nodeId].stopped) {
      // 如果操作正在进行，直接展示即可
      me.props.bus.emit('aiConsole', 'message.notice', {key: statusMap[nodeId].logMessageKey});
    } else if (statusMap[nodeId]) {
      // 如果自动刷新已停止且为异步操作，重新开始即可
      me.props.bus.emit('aiConsole', 'message.push', {
        type: 'user',
        content: `对节点 "${getNodeDisplayTitle(node, 12)}" 启动${exploreName}`,
      });

      statusMap[nodeId].secondsLeft = 3;
      statusMap[nodeId].loopTimeout = undefined;
      statusMap[nodeId].stopped = false;
      statusMap[nodeId].refreshing = false;
      statusMap[nodeId].lastMsg = `${exploreName}已启动，正在奋力查找中，请稍后...`;

      me.props.bus.emit('aiConsole', 'message.push', {
        type: 'ai',
        content: (
          <React.Fragment>
              <span>
                {statusMap[nodeId].lastMsg}
              </span>
            <CandidateNodeList
              key={`exp-candidate-${statusMap[nodeId].logMessageKey}`}
              bus={me.props.bus}
              nodeIds={statusMap[nodeId].nodeIds}
              relatedTo={node}
              updateTicket={statusMap[nodeId].nodeIds.length}
              viewDataProvider={me.props.viewDataProvider}
            />
          </React.Fragment>
        ),
        callback: ({key}) => statusMap[nodeId].logMessageKey = key,
        delay: 200,
      });

      (afterResponse || me.afterCommonAsyncExploreResponse)({statusMap, nodeId, node, exploreName, validNodeFn});
    } else {
      // 其他情况，启动自动发现流程
      (beforeRequest || me.beforeCommonExploreRequest)({node, exploreName}).then(({messageKey}) => {
        doRequest({messageKey}).then(() => {
          if (statusMap[nodeId]) {
            // TODO 补充一下说明
            return;
          }
          (onResponse || me.onCommonExploreResponse)({statusMap, nodeId, node, exploreName, messageKey, async: true});
          (afterResponse || me.afterCommonAsyncExploreResponse)({statusMap, nodeId, node, exploreName, validNodeFn});
        })
      });
    }
  };

  doCommonSyncExplore = ({statusMap, nodeId, node, exploreName, validNodeFn, beforeRequest, doRequest, onResponse, afterResponse}) => {
    let me = this;

    // 如果操作正在进行，直接展示即可
    // 其他情况，启动自动发现流程
    if (statusMap[nodeId] && !statusMap[nodeId].stopped) {
      // 如果操作正在进行，直接展示即可
      me.props.bus.emit('aiConsole', 'message.notice', {key: statusMap[nodeId].logMessageKey});
    } else {
      // 其他情况，启动自动发现流程
      (beforeRequest || me.beforeCommonExploreRequest)({node, exploreName}).then(({messageKey}) => {
        doRequest({messageKey}).then(({nodes, edges, done}) => {
          done = !!(done || done === undefined);
          (onResponse || me.onCommonExploreResponse)({statusMap, nodeId, node, exploreName, messageKey, async: false});
          (afterResponse || me.afterCommonSyncExploreResponse)(
            {statusMap, nodeId, node, exploreName, messageKey, validNodeFn, doRequest, afterResponse},
            {nodes, edges, done}
          );
        })
      });
    }
  };

  beforeCommonExploreRequest = ({node, exploreName}) => {
    let me = this;
    me.props.bus.emit('aiConsole', 'message.push', {
      type: 'user',
      content: `对节点 "${getNodeDisplayTitle(node, 12)}" 启动${exploreName}`,
    });

    return new Promise(resolve => {
      me.props.bus.emit('aiConsole', 'message.push', {
        type: 'ai',
        content: (
          <React.Fragment>
            <span>
              {exploreName}正在启动，请稍后...
            </span>
          </React.Fragment>
        ),
        callback: ({key}) => resolve({messageKey: key}),
        delay: 10,
      });
    })
  };

  onCommonExploreResponse = ({statusMap, nodeId, node, exploreName, messageKey, async}) => {
    let me = this;

    statusMap[nodeId] = {
      secondsLeft: 3,
      newNodesAmount: 0,
      hiddenNodeAmount: 0, // 尚未展示的节点数量
      loopTimeout: undefined,
      stopped: false,
      refreshing: false,
      logMessageKey: messageKey,
      nodeIds: [],
      lastMsg: `${exploreName}已启动，正在奋力查找中，请稍后...`,
      async,
    };

    me.props.bus.emit('aiConsole', 'message.update', {
      key: messageKey,
      content: (
        <React.Fragment>
          <span>
            {statusMap[nodeId].lastMsg}
          </span>
          <CandidateNodeList
            key={`exp-candidate-${statusMap[nodeId].logMessageKey}`}
            bus={me.props.bus}
            nodeIds={statusMap[nodeId].nodeIds}
            relatedTo={node}
            updateTicket={statusMap[nodeId].nodeIds.length}
            viewDataProvider={me.props.viewDataProvider}
          />
        </React.Fragment>
      ),
    });
  };

  afterCommonAsyncExploreResponse = (
    {statusMap, nodeId, node, exploreName, validNodeFn},
  ) => {
    let me = this;

    let locateAction = (
      <Tooltip title={`定位到节点 "${getNodeDisplayTitle(node, 12)}"`} key={'locate'}>
        <Button
          shape={'circle'}
          className={'first ant-btn-icon ant-btn-icon-only'}
          onClick={e => {e.preventDefault(); me.props.bus.emit('network', 'focus', node)}}
        >
          <Icon name={'icon-location'} type={IconTypes.ICON_FONT} />
        </Button>
      </Tooltip>
    );

    let waitActionsFn = lastNewNodesAmount => [
      locateAction,
      (
        <Tooltip title={'停止刷新'} key={'stop'}>
          <Button shape={'circle'} icon={'stop'} style={{float: 'right'}} className={'last'} onClick={stopFn} />
        </Tooltip>
      ),
      (
        <span key={'loading'} style={{float: 'right', marginLeft: '3rem'}}>
          {lastNewNodesAmount > 0 ? `新节点 ${lastNewNodesAmount} 个` : '奋力查找中'}
          ，{statusMap[nodeId].secondsLeft} 秒后刷新...
        </span>
      ),
    ];

    let loadingActionsFn = () => [
      locateAction,
      (
        <Tooltip title={'停止刷新'} key={'stop'}>
          <Button shape={'circle'} icon={'stop'} style={{float: 'right'}} className={'last'} onClick={stopFn} />
        </Tooltip>
      ),
      (
        <span key={'loading'} style={{float: 'right', marginLeft: '3rem'}}>
          正在刷新，请稍后...
        </span>
      ),
    ];

    let stopFn = () => {
      let stopped = statusMap[nodeId].stopped;
      clearTimeout(statusMap[nodeId].loopTimeout);
      if (statusMap[nodeId].loopLoadingInterval !== undefined) {
        clearInterval(statusMap[nodeId].loopLoadingInterval);
        statusMap[nodeId].loopLoadingInterval = undefined;
      }
      statusMap[nodeId].loopTimeout = undefined;
      statusMap[nodeId].lastMsg =
        `操作完成，${exploreName}共找到新节点 ${statusMap[nodeId].newNodesAmount} 个`;
      delete me.processing[statusMap[nodeId].logMessageKey];
      me.props.bus.emit('aiConsole', 'message.update', {
        key: statusMap[nodeId].logMessageKey,
        content: me.exploreResultContentFn({statusMap, nodeId, node}),
        actions: [
          locateAction,
          (
            <Tooltip title={'手动刷新'} key={'reload'}>
              <Button
                shape={'circle'}
                icon={'reload'}
                style={{float: 'right'}}
                className={'last'}
                onClick={() => {
                  statusMap[nodeId].secondsLeft = -1;
                  loopFn();
                }}
              />
            </Tooltip>
          ),
          (
            <span key={'loading'} style={{float: 'right'}}>
              {statusMap[nodeId].lastNewNodesAmount > 0 ?
                `新节点 ${statusMap[nodeId].lastNewNodesAmount} 个，` : ''}
              {stopped ? '手动刷新已完成' : '自动刷新已停止'}
            </span>
          ),
        ],
      });
      statusMap[nodeId].stopped = true;
    };

    let loopFn = () => {
      me.processing[statusMap[nodeId].logMessageKey] = true;
      let lastNewNodesAmount = statusMap[nodeId].lastNewNodesAmount;
      if (statusMap[nodeId].secondsLeft > 0) {

        if (statusMap[nodeId].refreshing) {
          if (!globalRefreshing.refreshing) {
            statusMap[nodeId].refreshing = false;
            // 更新数据
            let newNodeAmount = me.applyPendingChanges({statusMap, nodeId, node, validNodeFn});
            if (newNodeAmount > 0) {
              statusMap[nodeId].newNodesAmount += newNodeAmount;
              statusMap[nodeId].lastNewNodesAmount = newNodeAmount;
              statusMap[nodeId].lastMsg =
                `${exploreName}正在进行中，目前共找到新节点 ${statusMap[nodeId].newNodesAmount} 个`;
              me.props.bus.emit('aiConsole', 'message.update', {
                key: statusMap[nodeId].logMessageKey,
                content: me.exploreResultContentFn({statusMap, nodeId, node}),
                actions: waitActionsFn(newNodeAmount),
              });
            }
            if (statusMap[nodeId].stopped) {
              stopFn();
              return;
            }
          } else {
            // 提示正在获取数据
            me.props.bus.emit('aiConsole', 'message.patch', {
              key: statusMap[nodeId].logMessageKey,
              actions: loadingActionsFn(),
            });
          }
        } else {
          // 提示几秒后自动刷新
          me.props.bus.emit('aiConsole', 'message.patch', {
            key: statusMap[nodeId].logMessageKey,
            actions: waitActionsFn(lastNewNodesAmount),
          });
          statusMap[nodeId].secondsLeft--;
        }
      } else {
        statusMap[nodeId].secondsLeft = 3;
        statusMap[nodeId].refreshing = true;
        if (!globalRefreshing.refreshingInterval) {
          let reloadDataFn = () => {
            globalRefreshing.refreshing = true;
            setTimeout(() => {
              if (Object.values(me.processing).length <= 0) {
                clearInterval(globalRefreshing.refreshingInterval);
                globalRefreshing.refreshing = false;
                globalRefreshing.refreshingInterval = undefined;
                return;
              }
              me.props.viewDataProvider.reloadData().catch(error => {
                // 忽略错误
                console.error('reloadData failed, ', error);
              }).finally(() => {
                globalRefreshing.refreshing = false;
              });
            }, 200);
          };
          globalRefreshing.refreshingInterval = setInterval(reloadDataFn, 3000);
          reloadDataFn();
        }
        me.props.bus.emit('aiConsole', 'message.patch', {
          key: statusMap[nodeId].logMessageKey,
          actions: loadingActionsFn(),
        });
      }
      statusMap[nodeId].loopTimeout = setTimeout(loopFn, 1000);
    };

    loopFn();
  };

  afterCommonSyncExploreResponse = (
    {statusMap, nodeId, node, exploreName, messageKey, validNodeFn, doRequest, afterResponse},
    {nodes, edges, done}
  ) => {

    let me = this;

    let locateAction = (
      <Tooltip title={`定位到节点 "${getNodeDisplayTitle(node, 12)}"`} key={'locate'}>
        <Button
          shape={'circle'}
          className={'first ant-btn-icon ant-btn-icon-only'}
          onClick={e => {e.preventDefault(); me.props.bus.emit('network', 'focus', node)}}
        >
          <Icon name={'icon-location'} type={IconTypes.ICON_FONT} />
        </Button>
      </Tooltip>
    );

    // 更新数据
    let newNodeAmount = me.applyPendingChanges({
      statusMap,
      nodeId,
      node,
      afterApplied: done ? undefined : () => {
        doRequest({messageKey}).then(({nodes, edges, done}) => {
          done = !!(done || done === undefined);
          (afterResponse || me.afterCommonSyncExploreResponse)(
            {statusMap, nodeId, node, exploreName, messageKey, validNodeFn, doRequest, afterResponse},
            {nodes, edges, done}
          );
        });
      },
      validNodeFn,
    }, {
      nodesToAdd: nodes,
      edgesToAdd: edges,
    });
    if (newNodeAmount > 0) {
      statusMap[nodeId].newNodesAmount += newNodeAmount;
      statusMap[nodeId].lastNewNodesAmount = newNodeAmount;
    }

    statusMap[nodeId].lastMsg =
      `操作完成，${exploreName}共找到新节点 ${statusMap[nodeId].newNodesAmount} 个`;
    me.props.bus.emit('aiConsole', 'message.update', {
      key: statusMap[nodeId].logMessageKey,
      content: me.exploreResultContentFn({statusMap, nodeId, node}),
      actions: [
        locateAction,
        (
          <span key={'loading'} style={{float: 'right'}}>
            {statusMap[nodeId].lastNewNodesAmount > 0 ? `新节点 ${statusMap[nodeId].lastNewNodesAmount} 个` : ''}
          </span>
        ),
      ],
    });
    statusMap[nodeId].stopped = (done === true);
  };

  exploreResultContentFn = ({statusMap, nodeId, node}) => {
    let me = this;

    return (
      <React.Fragment>
        <span>
          {statusMap[nodeId].lastMsg}
          {statusMap[nodeId].nodeIds.length > 0 ? <br /> : null}
          {statusMap[nodeId].nodeIds.length > 0 ? (
            <span style={{padding: '0.7rem 0 0.3rem', display: 'block'}}>
              节点列表：
              <Tooltip title={'点击您感兴趣的条目即可定位并查看相关信息，在前方方框中打钩即可保留对应节点'}>
                <Icon name={'question-circle'} style={{marginLeft: '0.5em', cursor: 'pointer'}} />
              </Tooltip>
            </span>
          ) : null}
        </span>
        <CandidateNodeList
          key={`exp-candidate-${statusMap[nodeId].logMessageKey}`}
          bus={me.props.bus}
          nodeIds={statusMap[nodeId].nodeIds}
          relatedTo={node}
          updateTicket={statusMap[nodeId].nodeIds.length}
          viewDataProvider={me.props.viewDataProvider}
        />
      </React.Fragment>
    );
  };

  applyPendingChanges = ({statusMap, nodeId: targetNodeId, node: targetNode, afterApplied, validNodeFn},
                         {nodesToAdd, edgesToAdd} = {nodesToAdd: [], edgesToAdd: []}) => {
    let me = this, newNodeAmount = 0, newHiddenNodeAmount = 0;
    if (nodesToAdd && nodesToAdd.length > 0) {
      newNodeAmount += nodesToAdd.length;
    }
    let edgeFilterFn = (edgeId, edge) => {
      let match = !!edge && edge.meta && ((edge.meta.relatedTo || edge.meta.source) === targetNodeId);
      if (match && !edge._aiGenerated) edge._aiGenerated = true;
      return !!edge && (match || !edge.meta || edge.meta.status === undefined);
    };
    me.props.viewDataProvider.applyPendingChanges((nodeId, node, action) => {
      // 判断是否是通过当前节点自动发现操作生成
      if (node && node.aiRelatedTo === targetNodeId) {
        if (!validNodeFn || validNodeFn(node, targetNodeId)) {
          // 如果算法也匹配，则进行下一步处理
          if (action === 'add' || action === 'update') {
            newNodeAmount++;
            if (action === 'update') {
              statusMap[targetNodeId].nodeIds.push(nodeId);
              return true;
            } else/* if (action === 'add')*/ {
              newHiddenNodeAmount++;
              return false;
            }
          }
        }
      }
      return false;
    }, edgeFilterFn, {edgesToAdd: edgesToAdd.map(e => {e._aiGenerated = true; return e;})});
    newNodeAmount -= statusMap[targetNodeId].hiddenNodeAmount;
    statusMap[targetNodeId].hiddenNodeAmount = newHiddenNodeAmount;
    let loopLoadingFn = () => {
      let currentNodesToAdd = [], visibleNodesToAdd = [];
      if (nodesToAdd && nodesToAdd.length > 0) {
        let edges = me.props.viewDataProvider.getEdge();
        let currentNodesToAddMap = {};
        nodesToAdd.forEach(n => {
          if (!me.props.viewDataProvider.getNode(n.id)) {
            for (let i = 0; i < edges.length; i++) {
              let linkedNodeId = undefined;
              if (edges[i].from === n.id) {
                linkedNodeId = edges[i].to;
              } else if (edges[i].to === n.id) {
                linkedNodeId = edges[i].from;
              }
              if (linkedNodeId && me.props.viewDataProvider.getNode(linkedNodeId) && visibleNodesToAdd.length < 15) {
                n.withNodeId = linkedNodeId;
                currentNodesToAdd.push(n);
                currentNodesToAddMap[n.id] = n;
                statusMap[targetNodeId].nodeIds.push(n.id);
                if (n && n.fname && n.fname !== ' ') {
                  visibleNodesToAdd.push(n);
                }
              }
            }
          }
        });
        if (visibleNodesToAdd.length < 15) {
          nodesToAdd.forEach(n => {
            if (!me.props.viewDataProvider.getNode(n.id)) {
              for (let i = 0; i < edges.length; i++) {
                let linkedNodeId = undefined;
                if (edges[i].from === n.id) {
                  linkedNodeId = edges[i].to;
                } else if (edges[i].to === n.id) {
                  linkedNodeId = edges[i].from;
                }
                if (linkedNodeId && currentNodesToAddMap[linkedNodeId] && visibleNodesToAdd.length < 15) {
                  n.withNodeId = linkedNodeId;
                  currentNodesToAdd.push(n);
                  statusMap[targetNodeId].nodeIds.push(n.id);
                  if (n && n.fname && n.fname !== ' ') {
                    visibleNodesToAdd.push(n);
                  }
                }
              }
            }
          });
        }
      }
      let amount = currentNodesToAdd.length, visibleAmount = visibleNodesToAdd.length;
      me.props.viewDataProvider.applyPendingChanges((nodeId, node, action, viewDataProvider) => {
        // 判断是否是通过当前节点自动发现操作生成，判断是否还有被隐藏的新节点
        if (node && node.aiRelatedTo === targetNodeId && statusMap[targetNodeId].hiddenNodeAmount > 0) {
          // 判断算法是否匹配（人物发现、关系发现等等）
          if (!validNodeFn || validNodeFn(node, targetNodeId)) {
            // 如果算法也匹配，则进行下一步处理
            if (action === 'add') {
              let connectedNodeIds = [];
              viewDataProvider.getData().data.edges.forEach(edge => {
                if (edge.from === nodeId) {
                  connectedNodeIds.push(edge.to);
                } else if (edge.to === nodeId) {
                  connectedNodeIds.push(edge.from);
                }
              });
              let connectedNodes = viewDataProvider.getNode(connectedNodeIds).filter(n => !!n);
              if (connectedNodes.length > 0 && visibleAmount < 15) {
                amount++;
                if (node && node.fname && node.fname !== ' ') {
                  visibleAmount++;
                }
                statusMap[targetNodeId].hiddenNodeAmount--;
                node.withNodeId = connectedNodes.length > 0 ? connectedNodes[0].id : targetNodeId;
                statusMap[targetNodeId].nodeIds.push(nodeId);
                return true;
              }
            }
          }
        }
        return false;
      }, edgeFilterFn, {nodesToAdd: currentNodesToAdd});
      if (amount <= 0 && statusMap[targetNodeId].loopLoadingInterval !== undefined) {
        // 操作结束
        setTimeout(() => afterApplied && afterApplied(), 3000);
        clearInterval(statusMap[targetNodeId].loopLoadingInterval);
        statusMap[targetNodeId].loopLoadingInterval = undefined;
      } else {
        me.props.bus.emit('aiConsole', 'message.patch', {
          key: statusMap[targetNodeId].logMessageKey,
          content: me.exploreResultContentFn({statusMap, nodeId: targetNodeId, node: targetNode}),
        });
      }
    };
    if (statusMap[targetNodeId].loopLoadingInterval === undefined) {
      statusMap[targetNodeId].loopLoadingInterval = setInterval(loopLoadingFn, 3000);
      loopLoadingFn();
    }
    statusMap[targetNodeId].lastNewNodesAmount = 0;
    return newNodeAmount;
  };

  componentDidMount() {
    let me = this;

    me.props.bus.with(me).subscribe('relation', 'node.single_selection_change', (nodeId) => {
      if (nodeId) {
        me.currentSelectedNodeId = nodeId;
      } else {
        me.currentSelectedNodeId = undefined;
      }
    }).subscribe('view', 'explore.explore_talent.recommend_detail.do_load_more', ({viewId}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId) {
        me.setState({recommendDetailStatus: 'loading'});
      }
    }).subscribe('view', 'explore.explore_talent.recommend_detail.data_loaded', ({resultData, hasMoreResult, viewId}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId) {
        me.setState({
          recommendDetailStatus: hasMoreResult ? 'stopped' : 'finished',
          recommendDetailHasNewResult: me.state.recommendDetailModalVisible ? 0 : (me.state.recommendDetailHasNewResult + resultData.length),
        });
      }
    }).subscribe('view', 'explore.explore_talent.recommend_detail.failed_to_load', ({viewId}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId) {
        me.setState({
          recommendDetailStatus: 'failed',
        });
      }
    }).subscribe('view', 'explore.explore_talent.recommend_detail.modal_visible_changed', ({viewId, visible}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId) {
        me.setState({
          recommendDetailModalVisible: visible,
        });
      }
    });
  }

  componentWillUnmount() {
    this.props.bus.remove(this);
  }

  render() {
    let me = this;

    return (
      <div className={'dark-theme'}>
        <Menu selectable={false}>
          <Menu.Item key={'m.person.field'} onClick={() => me.onExploreDomain()}>
            <div className={style['explore-menu-item']}>
              <div className={style['explore-menu-item-icon']}>
                <Icon name={'icon-person-field'} type={IconTypes.ICON_FONT} />
              </div>
              <div className={style['explore-menu-item-meta']}>
                <div className={style['explore-menu-item-title']}>同领域同行</div>
                <div className={style['explore-menu-item-description']}>根据给定人物返回同领域人物</div>
              </div>
            </div>
          </Menu.Item>
          <Menu.Item key={'m.person.single'} onClick={() => me.onExploreProps()}>
            <div className={style['explore-menu-item']}>
              <div className={style['explore-menu-item-icon']}>
                <Icon name={'icon-person-single'} type={IconTypes.ICON_FONT} />
              </div>
              <div className={style['explore-menu-item-meta']}>
                <div className={style['explore-menu-item-title']}>个人社会关系</div>
                <div className={style['explore-menu-item-description']}>根据给定人物挖掘其同乡校友同事</div>
              </div>
            </div>
          </Menu.Item>
          <Menu.Item
            key={'m.person.links'}
            disabled={personLinksAvailableViewIds.indexOf(me.props.viewDataProvider.viewId) < 0}
            onClick={() => {
              me.props.bus.emit('view', 'person.multiRelationship.show_modal',
                {viewId: me.props.viewDataProvider.viewId});
            }}
          >
            <div className={style['explore-menu-item']}>
              <div className={style['explore-menu-item-icon']}>
                <Icon name={'icon-person-multi'} type={IconTypes.ICON_FONT} />
              </div>
              <div className={style['explore-menu-item-meta']}>
                <div className={style['explore-menu-item-title']}>多人社会关系</div>
                <div className={style['explore-menu-item-description']}>当前图谱内的人物之间同乡校友同事关系</div>
              </div>
            </div>
          </Menu.Item>
          <Menu.Item
            key={'m.person.overall'}
            disabled={overallAvailableViewIds.indexOf(me.props.viewDataProvider.viewId) < 0}
            onClick={() => me.onExploreOverall()}
          >
            <div className={style['explore-menu-item']}>
              <div className={style['explore-menu-item-icon']}>
                <Icon name={'icon-person-multi'} type={IconTypes.ICON_FONT} />
              </div>
              <div className={style['explore-menu-item-meta']}>
                <div className={style['explore-menu-item-title']}>相关人物挖掘</div>
                <div className={style['explore-menu-item-description']}>挖掘与当前图谱相关的更多人物信息</div>
              </div>
            </div>
          </Menu.Item>
          <Menu.Item key={'m.person.recommend-detail'} onClick={me.onExploreRecommendDetail}>
            <div className={style['explore-menu-item']}>
              <Badge dot={me.state.recommendDetailHasNewResult > 0}>
                <div className={style['explore-menu-item-icon']}>
                  <Icon name={'icon-person-override'} type={IconTypes.ICON_FONT} />
                </div>
              </Badge>
              <div className={style['explore-menu-item-meta']}>
                <div className={style['explore-menu-item-title']}>人物信息填充</div>
                <div className={style['explore-menu-item-description']}>遍历图谱中的人物节点并推荐填充信息</div>
                <div className={style['explore-menu-item-notice']}>
                  {(() => {
                    switch (me.state.recommendDetailStatus) {
                      case 'idle':
                        return '';
                      case 'loading':
                        if (me.state.recommendDetailHasNewResult > 0) {
                          return '有新结果';
                        } else {
                          return '计算中';
                        }
                      case 'stopped':
                        return '已停止';
                      case 'finished':
                        return '已完成';
                      case 'failed':
                        return '出错了';
                      default:
                        return '';
                    }
                  })()}
                </div>
              </div>
            </div>
          </Menu.Item>
          <Menu.Item
            key={'m.person.custom'}
            onClick={() => {
              me.props.bus.emit('view', 'explore.show_custom_request_modal',
                {viewId: me.props.viewDataProvider.viewId});
            }}
          >
            <div className={style['explore-menu-item']}>
              <div className={style['explore-menu-item-icon']}>
                <Icon name={'icon-person-find'} type={IconTypes.ICON_FONT} />
              </div>
              <div className={style['explore-menu-item-meta']}>
                <div className={style['explore-menu-item-title']}>更多定制发现</div>
                <div className={style['explore-menu-item-description']}>为您定制发现模型，请联系 hi@joinmap.ai</div>
              </div>
            </div>
          </Menu.Item>
        </Menu>
      </div>
    );
  }
}

ExploreMenuTalent.defaultProps = {
  bus: PB,
};

ExploreMenuTalent.propTypes = {
  bus: PropTypes.instanceOf(SimplePB),
  userInfo: PropTypes.object.isRequired,
  viewDataProvider: PropTypes.instanceOf(ViewDataProvider).isRequired,
};

export default ExploreMenuTalent;