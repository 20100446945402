import CHINA_ADC from '@/constants/china_adc.2018.json';
import CHINA_ADC_COORDINATE from '@/constants/map/china_city_center_coordinate.json';

import _ from 'lodash';
import moment from 'moment';
import {animateScroll} from 'react-scroll';

// 获取鼠标位置
export const getMousePos = (event, offset = {x: 0, y: 0}) => {
    if (!offset || !offset.x) {
        offset.x = 0
    }
    if (!offset || !offset.y) {
        offset.y = 0
    }
    let e = event || window.event;
    let scrollX = document.documentElement.scrollLeft || document.body.scrollLeft;
    let scrollY = document.documentElement.scrollTop || document.body.scrollTop;
    let x = e.pageX || e.clientX + scrollX;
    let y = e.pageY || e.clientY + scrollY;
    x = x + offset.x;
    y = y + offset.y;
    // console.log('鼠标 x y ', x, y)
    return {x, y};
};

// todo:生成浮动提示框的坐标
/**
 * @param id 目标元素的id
 * @param offset 和鼠标或指定位置位置的偏移
 * @param position 指定位置，不设置则去鼠标位置
 * @param event 无用
 * @returns {{x: number, y: number}}
 */
export const showAsTip = (id, offset = {x: 0, y: 0}, position, event = window.event) => {
    //el表示需要悬浮的框
    const el = document.getElementById(id);
    if (el) {
        if (!offset || !offset.x) {
            offset.x = 0
        }
        if (!offset || !offset.y) {
            offset.y = 0
        }
        let e = event || window.event;
        // 设置目标框位置
        if (position && position.x && position.y) {
            // 定位悬浮框到指定位置
            el.style.left = position.x + offset.x + 'px';
            el.style.top = position.y + offset.y + 'px';
        } else {
            // 根据鼠标位置定位悬浮框
            el.style.left = e.clientX + offset.x + 'px';
            el.style.top = e.clientY + offset.y + 'px';
        }

        // 宽度碰撞检测
        let width = el.offsetWidth;
        if (e.clientX + width + 0 > document.documentElement.clientWidth) {
            el.style.left = e.clientX - width + 'px';
        }
        //高度碰撞检测
        let height = el.offsetHeight;
        if (e.clientY + height + 0 > document.documentElement.clientHeight) {
            el.style.top = e.clientY - height + 'px';
        }
    } else {
        console.log('no 没获取到document对象')
    }
};

//todo:生成从minNum到maxNum的随机整数
export const randomNum = (minNum, maxNum) => {
    return parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10);
};

// 读取节点数据字段值，支持.分隔层级
export const getObjectValue = (object, fieldPath = []) => {
    // console.log('getObjectValue->fieldPath：',fieldPath)
    if (!Array.isArray(fieldPath)) {
        console.log('getObjectValue->传入的不是数组：', fieldPath);
        fieldPath = fieldPath.split('.')
    }
    let value = {...object};
    // console.log('getObjectValue value=',value)
// 层级取值
    try {
        fieldPath.forEach(field => {
            if (value[field]) {
                value = value[field]
            } else {
                throw new Error('字段值不存在')
            }
        })
    } catch (e) {
        // console.log('获取节点字段值报错：', e)
        value = undefined
    }
    return value
};

// 延边朝鲜族自治州 -> 延边
// 恩施土家族苗族自治州 -> 恩施
// 神农架林区 -> 神农架林区
// 湘西土家族苗族自治州 -> 湘西州
// 白沙黎族自治县 -> 白沙
// 昌江黎族自治县 -> 昌江
// 乐东黎族自治县 -> 乐东
// 陵水黎族自治县 -> 陵水
// 保亭黎族苗族自治县 -> 保亭
// 琼中黎族苗族自治县 -> 琼中
// 阿坝藏族羌族自治州 -> 阿坝州
// 甘孜藏族自治州 -> 甘孜州
// 凉山彝族自治州 -> 凉山州
// 毕节市 -> 毕节地区
// 铜仁市 -> 铜仁地区
// 黔西南布依族苗族自治州 -> 黔西南州
// 黔东南苗族侗族自治州 -> 黔东南州
// 黔南布依族苗族自治州 -> 黔南州
// 楚雄彝族自治州 -> 楚雄州
// 红河哈尼族彝族自治州 -> 红河州
// 文山壮族苗族自治州 -> 文山
// 西双版纳傣族自治州 -> 西双版纳
// 大理白族自治州 -> 大理州
// 德宏傣族景颇族自治州 -> 德宏州
// 怒江傈僳族自治州 -> 怒江州
// 迪庆藏族自治州 -> 迪庆州
// 日喀则市 -> 日喀则地区
// 昌都市 -> 昌都地区
// 林芝市 -> 林芝地区
// 山南市 -> 山南地区
// 那曲市 -> 那曲地区
// 阿里地区 -> 阿里地区
// 临夏回族自治州 -> 临夏州
// 甘南藏族自治州 -> 甘南州
// 海东市 -> 海东地区
// 海北藏族自治州 -> 海北州
// 黄南藏族自治州 -> 黄南州
// 海南藏族自治州 -> 海南州
// 果洛藏族自治州 -> 果洛州
// 玉树藏族自治州 -> 玉树州
// 海西蒙古族藏族自治州 -> 海西州
// 吐鲁番市 -> 吐鲁番地区
// 哈密市 -> 哈密地区
// 昌吉回族自治州 -> 昌吉州
// 博尔塔拉蒙古自治州 -> 博尔塔拉州
// 巴音郭楞蒙古自治州 -> 巴音郭楞
// 阿克苏地区 -> 阿克苏地区
// 克孜勒苏柯尔克孜自治州 -> 克孜勒苏州
// 喀什地区 -> 喀什地区
// 和田地区 -> 和田地区
// 伊犁哈萨克自治州 -> 伊犁州
// 塔城地区 -> 塔城地区
// 阿勒泰地区 -> 阿勒泰地区

// noinspection NonAsciiCharacters
export const knownChinaADCTitle = {
    '莫力达瓦达斡尔族自治旗': ['莫旗'],
    '东乡族自治县': ['东乡'],
    '白云鄂博矿区': ['白云矿区'],
};

const chinaADCTitleSimplifyException = ['下花园区', '万柏林区', '达坂城区'];

export const unknownChinaADC = '999999';

export const chinaADCTitleMap = ((adcData) => {
    const result = {};
    Object.keys(adcData).forEach(code => {
        let tmpLocation;

        result[code] = [];
        result[code].push(adcData[code]);
        result[code].push(adcData[code].replace('全中国', '全国'));

        let locationOnly = adcData[code]
          .replace('回族', '')
          .replace('壮族', '')
          .replace('满族', '')
          .replace('畲族', '')
          .replace('苗族', '')
          .replace('瑶族', '')
          .replace('侗族', '')
          .replace('黎族', '')
          .replace('傣族', '')
          .replace('羌族', '')
          .replace('彝族', '')
          .replace('藏族', '')
          .replace('水族', '')
          .replace('佤族', '')
          .replace('白族', '')
          .replace('怒族', '')
          .replace('土族', '')
          .replace('朝鲜族', '')
          .replace('仫佬族', '')
          .replace('仡佬族', '')
          .replace('毛南族', '')
          .replace('蒙古族', '')
          .replace('土家族', '')
          .replace('哈尼族', '')
          .replace('布依族', '')
          .replace('纳西族', '')
          .replace('拉祜族', '')
          .replace('布朗族', '')
          .replace('景颇族', '')
          .replace('傈僳族', '')
          .replace('独龙族', '')
          .replace('普米族', '')
          .replace('裕固族', '')
          .replace('保安族', '')
          .replace('东乡族', '')
          .replace('撒拉族', '')
          .replace('达斡尔族', '')
          .replace('鄂温克族', '')
          .replace('哈萨克族', '')
          .replace('各族', '');

        if (/自治([县区州旗])$/.test(locationOnly)) {
            tmpLocation = locationOnly
              .replace('蒙古', '')
              .replace('锡伯', '')
              .replace('维吾尔', '')
              .replace('哈萨克', '')
              .replace('塔吉克', '')
              .replace('柯尔克孜', '');
            if (tmpLocation.length > 4) locationOnly = tmpLocation; // 去掉 自治X 之后至少包含两个字
        }

        result[code].push(locationOnly);
        result[code].push(locationOnly.replace(/(.{2,})省$/, '$1'));
        result[code].push(locationOnly.replace(/(.{2,})市$/, '$1'));
        result[code].push(locationOnly.replace('特别行政区', ''));
        result[code].push(locationOnly.replace(/(.{2,})地区$/, '$1'));

        if (/自治[县区州旗]$/.test(locationOnly)) {
          result[code].push(locationOnly.replace(/自治([县区州旗])$/, '$1'));
          result[code].push(locationOnly.replace(/自治([县区州旗])$/, ''));
        } else {
            result[code].push(locationOnly.replace(/(.{2,})县$/, '$1'));
        }
        if (!/(自治|地|林|特别行政|新|矿|园|郊|城|市)区$/.test(locationOnly) && /区$/.test(locationOnly)) {
            tmpLocation = locationOnly.replace(/区$/, '');
            if (tmpLocation.length > 1) result[code].push(tmpLocation);
        }
        if (/(.{2,})[林新矿园郊城市]区$/.test(locationOnly)) {
            if (chinaADCTitleSimplifyException.includes(locationOnly)) {
                result[code].push(locationOnly.replace('区', ''));
            } else {
                result[code].push(locationOnly.replace(/(.{2,})[林新矿园郊城市]区$/, '$1'));
            }
        } else if (/(.)[林新矿园郊城市]区$/.test(locationOnly)) {
            result[code].push(locationOnly.replace('区', ''));
        }

        if (knownChinaADCTitle[adcData[code]]) {
            result[code] = result[code].concat(knownChinaADCTitle[adcData[code]]);
        }

        result[code] = result[code].filter((v, i, a) => v.length > 1 && a.indexOf(v) === i);
    });
    return result;
})(CHINA_ADC);

export const simplifyChinaADCTitle = (title) => {
  let tmpTitle;

  title = title
    .replace('回族', '')
    .replace('壮族', '')
    .replace('满族', '')
    .replace('畲族', '')
    .replace('苗族', '')
    .replace('瑶族', '')
    .replace('侗族', '')
    .replace('黎族', '')
    .replace('傣族', '')
    .replace('羌族', '')
    .replace('彝族', '')
    .replace('藏族', '')
    .replace('水族', '')
    .replace('佤族', '')
    .replace('白族', '')
    .replace('怒族', '')
    .replace('土族', '')
    .replace('朝鲜族', '')
    .replace('仫佬族', '')
    .replace('仡佬族', '')
    .replace('毛南族', '')
    .replace('蒙古族', '')
    .replace('土家族', '')
    .replace('哈尼族', '')
    .replace('布依族', '')
    .replace('纳西族', '')
    .replace('拉祜族', '')
    .replace('布朗族', '')
    .replace('景颇族', '')
    .replace('傈僳族', '')
    .replace('独龙族', '')
    .replace('普米族', '')
    .replace('裕固族', '')
    .replace('保安族', '')
    .replace('东乡族', '')
    .replace('撒拉族', '')
    .replace('达斡尔族', '')
    .replace('鄂温克族', '')
    .replace('哈萨克族', '')
    .replace('各族', '');

  if (/自治(县|区|州|旗)$/.test(title)) {
    tmpTitle = title
      .replace('蒙古', '')
      .replace('锡伯', '')
      .replace('维吾尔', '')
      .replace('哈萨克', '')
      .replace('塔吉克', '')
      .replace('柯尔克孜', '');
    if (tmpTitle.length > 4) title = tmpTitle; // 去掉 自治X 之后至少包含两个字
  }

  title = title
    .replace(/(.{2,})省$/, '$1')
    .replace(/(.{2,})市$/, '$1')
    .replace('特别行政区', '')
    .replace(/(.{2,})地区$/, '$1');

  if (/自治([县区州旗])$/.test(title)) {
    title = title.replace(/自治([县区州旗])$/, '');
  } else {
    title = title.replace(/(.{2,})县$/, '$1');
  }

  if (!/(自治|地|林|特别行政|新|矿|园|郊|城|市)区$/.test(title) && /区$/.test(title)) {
    tmpTitle = title.replace(/区$/, '');
    if (tmpTitle.length > 1) title = tmpTitle;
  }

  if (/(.{2,})[林新矿园郊城市]区$/.test(title)) {
    if (chinaADCTitleSimplifyException.includes(title)) {
      title = title.replace('区', '');
    } else {
      title = title.replace(/(.{2,})[林新矿园郊城市]区$/, '$1');
    }
  } else if (/(.)[林新矿园郊城市]区$/.test(title)) {
    title = title.replace('区', '');
  }

  return title;
};

export const chinaTitleADCMap = ((adcTitleMap) => {
  let result = {};
  Object.keys(adcTitleMap).forEach(adc => {
    adcTitleMap[adc].forEach(title => {
      result[title] = result[title] || [];
      result[title].push(adc);
    });
  });
  return result;
})(chinaADCTitleMap);

export const getChinaADCCascaderOptions = ((adcData) => {
  let result = {};

  Object.keys(adcData).forEach(adc => {
    if (adc === '000000' || adc === unknownChinaADC) {
      return;
    }
    let parentAdc = adc.substring(2) === '0000' ? '000000' :
      (adc.substring(4) === '00' ? `${adc.substr(0, 2)}0000` : `${adc.substr(0, 4)}00`);
    if (!adcData[parentAdc]) {
      parentAdc = parentAdc.substring(2) === '0000' ? '000000' : `${adc.substr(0, 2)}0000`;
    }
    let option = {
      label: adcData[adc],
      value: adc,
    };
    if (result[parentAdc] === undefined) {
      result[parentAdc] = [];
    }
    result[parentAdc].push(option);
  });

  return (adc = '000000', level = 'city', allowAnyProvince = true,
          allowAnyCity = true, allowAnyDistinct = true) => {

    let realResult = {};
    // noinspection FallThroughInSwitchStatementJS
    switch (level) {
      case 'distinct':
        Object.keys(result).forEach(adc => {
          if (adc.substring(2) !== '0000' && adc.substring(4) === '00') {
            realResult[adc] = [...result[adc]];
            if (allowAnyDistinct) {
              realResult[adc].unshift({label: `全${adcData[adc].slice(-1)}`, value: '--'});
            }
          }
        });
      case 'city':
        Object.keys(result).forEach(adc => {
          if (adc !== '000000' && adc.substring(2) === '0000') {
            realResult[adc] = [...result[adc]];
            if (allowAnyCity) {
              realResult[adc].unshift({label: `全${adcData[adc].slice(-1)}`, value: '--'});
            }
          }
        });
      case 'province':
        realResult['000000'] = [...result['000000']];
        if (allowAnyProvince) {
          realResult['000000'].unshift({label: `全${adcData[adc].slice(-1)}`, value: '--'});
        }
    }
    Object.keys(realResult).forEach(adc => {
      realResult[adc].forEach((option, idx) => {
        if (realResult[option.value]) {
          realResult[adc][idx].children = realResult[option.value];
        }
      });
    });
    return realResult[adc];
  };
})(CHINA_ADC);

export const maxChinaTitleLength = Math.max(...Object.keys(chinaTitleADCMap).map(title => title.length));

const internalGetChinaADCByTitle = (title) => {
  let result = false, tmpTitle;
  if (chinaTitleADCMap[title]) {
    result = chinaTitleADCMap[title];
  } else if (/(.{2,})地区$/.test(title)) {
    title = title.replace(/(.{2,})地区$/, '$1');
    if (chinaTitleADCMap[title]) {
      result = chinaTitleADCMap[title];
    }
  }
  if (result) {
    return {
      titleLength: Math.min(maxChinaTitleLength + 2, title.length) + 1,
      result,
    }
  } else {
    return {
      titleLength: -1,
      result: undefined,
    };
  }
};

export const getChinaADCByTitle = (title, preferAllResult = false) => {
  let {result} = internalGetChinaADCByTitle(title);
  if (result) {
    if (preferAllResult) {
      return result;
    } else if (result.length > 1) {
      // 返回最高级别区域代码
      let currentPos = 0, tailingZero = 0, adc, resultAdc = result[0];
      while (tailingZero < 3 && currentPos < result.length) {
        adc = result[currentPos];
        let adcTailingZero = adc === '000000' ? 3 : (adc.substring(2) === '0000' ? 2 :
          (adc.substring(4) === '00' ? 1 : 0));
        if (adcTailingZero > tailingZero) {
          tailingZero = adcTailingZero;
          resultAdc = adc;
        }
        currentPos++;
      }
      return resultAdc;
    } else {
      return result[0];
    }
  } else {
    return unknownChinaADC;
  }
};

export const getChinaADCCoordinate = (adc) => {
  if (adc === unknownChinaADC) return undefined;
  let coordinate = CHINA_ADC_COORDINATE[adc];
  if (!coordinate || !_.isArray(coordinate)) {
    adc = adc.substring(0, 4) + '00';
    coordinate = CHINA_ADC_COORDINATE[adc];
  }
  if (!coordinate || !_.isArray(coordinate)) {
    adc = adc.substring(0, 2) + '0000';
    coordinate = CHINA_ADC_COORDINATE[adc];
  }
  if (coordinate && _.isArray(coordinate)) {
    return {
      x: coordinate[0],
      y: coordinate[1],
      scale: coordinate[2],
      adc,
    }
  } else {
    return undefined;
  }
};

export const chinaProvinceADCMap = ((adcData) => {
    const result = {};
    Object.keys(adcData).filter(code => code.substring(2) === '0000' || code === unknownChinaADC)
        .forEach(code => result[code] = adcData[code]);
    return Object.freeze(result);
})(CHINA_ADC);

export const simplifiedChinaProvinceADCMap = ((provinceAdcData) => {
    const result = {};
    Object.keys(provinceAdcData).forEach(code =>
        result[code] = provinceAdcData[code]
            .replace('全中国', '全国')
            .replace('省', '')
            .replace('市', '')
            .replace('回族自治区', '')
            .replace('维吾尔自治区', '')
            .replace('壮族自治区', '')
            .replace('自治区', '')
            .replace('特别行政区', '')
            .replace('地区', '')
            .replace('区', ''));
    return Object.freeze(result);
})(chinaProvinceADCMap);

export const formattedChinaProvinceADCMap = ((provinceAdcData, simplifiedProvinceAdcData) => {
    const result = {};
    Object.keys(provinceAdcData).forEach(code =>
        result[code] = (
            provinceAdcData[code].length <= 3 ? (
                provinceAdcData[code]
            ) : (
                simplifiedProvinceAdcData[code] && simplifiedProvinceAdcData[code].length === 2 ? (
                    simplifiedProvinceAdcData[code] + provinceAdcData[code][provinceAdcData[code].length - 1]
                ) : (
                    provinceAdcData[code].substring(0, 3)
                )
            )
        )
    );
    return Object.freeze(result);
})(chinaProvinceADCMap, simplifiedChinaProvinceADCMap);

export const chinaCityADCMap = ((adcData) => {
  const result = {};
  Object.keys(adcData).filter(code => code.substring(4) === '00' || code === unknownChinaADC)
    .forEach(code => result[code] = adcData[code]);
  return Object.freeze(result);
})(CHINA_ADC);

export const simplifiedChinaCityADCMap = ((cityADCMap) => {
  const result = {};
  Object.keys(cityADCMap).forEach(code =>
    result[code] = simplifyChinaADCTitle(cityADCMap[code]));
  return Object.freeze(result);
})(chinaCityADCMap);

export const simplifiedChinaDistrictADCMap = ((ADCMap) => {
  const result = {};
  Object.keys(ADCMap).forEach(code =>
    result[code] = simplifyChinaADCTitle(ADCMap[code]));
  return Object.freeze(result);
})(CHINA_ADC);

export const getChinaProvinceADC = provinceStr => {
    provinceStr = `${provinceStr}`;
    let result = unknownChinaADC;
    let pos = provinceStr.length;
    Object.keys(simplifiedChinaProvinceADCMap).forEach(code => {
        let currentPos = provinceStr.indexOf(simplifiedChinaProvinceADCMap[code]);
        if (currentPos >= 0 && currentPos < pos) {
            result = code;
            pos = currentPos;
        }
    });
    return result;
};

// 国内地理位置统计类型
export const ChinaADCStatisticsType = Object.freeze({
    AREA: 1,
    PROVINCE: 2,
    CITY: 4,
    DISTRICT: 6,
});

// 国内地理位置展示名称
export const ChinaADCDisplayTitleMap = Object.freeze({
    [ChinaADCStatisticsType.AREA]: {
        shortName: {
            '000000': '全国',
            '100000': '华北',
            '200000': '东北',
            '300000': '华东',
            '400000': '中南',
            '500000': '西南',
            '600000': '西北',
            '700000': '港澳台',
            '800000': '港澳台',
        },
        fullName: {
            '000000': '全部地区',
            '100000': '华北地区',
            '200000': '东北地区',
            '300000': '华东地区',
            '400000': '中南地区',
            '500000': '西南地区',
            '600000': '西北地区',
            '700000': '港澳台地区',
            '800000': '港澳台地区',
        },
        formattedName: {
            '000000': '全中国',
            '100000': '华北区',
            '200000': '东北区',
            '300000': '华东区',
            '400000': '中南区',
            '500000': '西南区',
            '600000': '西北区',
            '700000': '港澳台',
            '800000': '港澳台',
        },
    },
    [ChinaADCStatisticsType.PROVINCE]: {
        shortName: simplifiedChinaProvinceADCMap,
        fullName: chinaProvinceADCMap,
        formattedName: formattedChinaProvinceADCMap,
    },
    [ChinaADCStatisticsType.CITY]: {
        shortName: simplifiedChinaCityADCMap,
        fullName: chinaCityADCMap,
        formattedName: {}, // TODO
    },
    [ChinaADCStatisticsType.DISTRICT]: {
        shortName: simplifiedChinaDistrictADCMap,
        fullName: Object.freeze(CHINA_ADC),
        formattedName: {}, // TODO
    },
});

export const getPossibleChinaProvinceTitles = (adc) => {
  const titleMap = {}, fullTitle = chinaProvinceADCMap[adc];
  let shortTitle, tmpTitle;
  titleMap[fullTitle] = 0x100000;

  // .replace('省', '').replace('市', '').replace('特别行政区', '')
  shortTitle = fullTitle.replace('省', '').replace('市', '').replace('特别行政区', '');
  if (!titleMap[shortTitle]) {
    titleMap[shortTitle] = 0x100000;
    tmpTitle = `${shortTitle}地区`;
    titleMap[tmpTitle] = 0x100000;
  }

  // .replace('回族自治区', '').replace('维吾尔自治区', '').replace('壮族自治区', '').replace('自治区', '')
  shortTitle = fullTitle.replace('回族自治区', '').replace('维吾尔自治区', '').replace('壮族自治区', '').replace('自治区', '');
  if (!titleMap[shortTitle]) {
    titleMap[shortTitle] = 0x100000;
    tmpTitle = `${shortTitle}自治区`;
    titleMap[tmpTitle] = 0x100000;
    tmpTitle = `${shortTitle}地区`;
    titleMap[tmpTitle] = 0x100000;
    tmpTitle = `${shortTitle}省`;
    titleMap[tmpTitle] = 0x100000;
  }

  return titleMap;
}

export const chinaProvinceTitleMap = ((provinceAdcData) => {
  const result = {}, setAdcFn = (result, title, adc, possibility) => {
    if (!result[title]) {
      result[title] = [];
    }
    result[title].push({
      adc,
      possibility,
    });
  };
  Object.keys(provinceAdcData).forEach(code => {
    if (code === unknownChinaADC || code === '000000') return;
    let titles = getPossibleChinaProvinceTitles(code);
    Object.keys(titles).forEach(title => setAdcFn(result, title, code, titles[title]));
  });
  return Object.freeze(result);
})(chinaProvinceADCMap);

export const getPossibleChinaCityTitles = (adc) => {
  const titleMap = {}, fullTitle = chinaCityADCMap[adc];
  let shortTitle = fullTitle, tmpTitle;
  titleMap[fullTitle] = 0x8000;

  // 藏族自治县
  // 市、盟、自治州、地区

  // 民族
  tmpTitle = fullTitle
    .replace('回族', '')
    .replace('壮族', '')
    .replace('满族', '')
    .replace('畲族', '')
    .replace('苗族', '')
    .replace('瑶族', '')
    .replace('侗族', '')
    .replace('黎族', '')
    .replace('傣族', '')
    .replace('羌族', '')
    .replace('彝族', '')
    .replace('藏族', '')
    .replace('水族', '')
    .replace('佤族', '')
    .replace('白族', '')
    .replace('怒族', '')
    .replace('土族', '')
    .replace('朝鲜族', '')
    .replace('仫佬族', '')
    .replace('仡佬族', '')
    .replace('毛南族', '')
    .replace('蒙古族', '')
    .replace('土家族', '')
    .replace('哈尼族', '')
    .replace('布依族', '')
    .replace('纳西族', '')
    .replace('拉祜族', '')
    .replace('布朗族', '')
    .replace('景颇族', '')
    .replace('傈僳族', '')
    .replace('独龙族', '')
    .replace('普米族', '')
    .replace('裕固族', '')
    .replace('保安族', '')
    .replace('东乡族', '')
    .replace('撒拉族', '')
    .replace('达斡尔族', '')
    .replace('鄂温克族', '')
    .replace('哈萨克族', '')
    .replace('各族', '');
  if (/自治州$/.test(tmpTitle)) {
    tmpTitle = tmpTitle
      .replace('蒙古', '')
      .replace('锡伯', '')
      .replace('维吾尔', '')
      .replace('哈萨克', '')
      .replace('塔吉克', '')
      .replace('柯尔克孜', '');
    if (tmpTitle.length > 4) shortTitle = tmpTitle; // 去掉 自治X 之后至少包含两个字
  }
  if (!titleMap[shortTitle]) {
    titleMap[shortTitle] = 0x6000;
  }

  // 自治[州]
  tmpTitle = shortTitle.replace(/自治(州)$/, '$1');
  if (!titleMap[tmpTitle]) {
    titleMap[tmpTitle] = 0x6000;
  }

  // 市、地区、自治[州]
  tmpTitle = shortTitle
    .replace(/(.{2,})市$/, '$1')
    .replace(/(.{2,})地区$/, '$1');
  if (/自治州$/.test(tmpTitle)) {
    tmpTitle = tmpTitle.replace(/自治州$/, '');
  }
  if (!titleMap[tmpTitle]) {
    titleMap[tmpTitle] = 0x1000;
  }
  tmpTitle = `${tmpTitle}地区`;
  if (!titleMap[tmpTitle]) {
    titleMap[tmpTitle] = 0x2000;
  }

  // 城市例外
  ['长治', '中山', '灯塔', '朝阳', '四平', '白山', '五常', '天长', '日照', '神木', '白银', '阿里'].forEach(title => {
    if (titleMap[title]) {
      titleMap[title] = 0x1;
    }
  });

  return titleMap;
};

export const chinaCityTitleMap = ((cityAdcData) => {
  const result = {}, setAdcFn = (result, title, adc, possibility) => {
    if (!result[title]) {
      result[title] = [];
    }
    result[title].push({
      adc,
      possibility,
    });
  };
  Object.keys(cityAdcData).forEach(code => {
    if (code === unknownChinaADC || _.endsWith('0000', code)) return;
    let titles = getPossibleChinaCityTitles(code);
    Object.keys(titles).forEach(title => setAdcFn(result, title, code, titles[title]));
  });
  return Object.freeze(result);
})(chinaCityADCMap);

export const getPossibleChinaDistrictTitles = (adc) => {
  const titleMap = {}, fullTitle = CHINA_ADC[adc];
  let shortTitle = fullTitle, tmpTitle;
  titleMap[fullTitle] = 0x80;

  // 藏族自治县
  // 市、盟、自治州、地区

  // 民族
  tmpTitle = fullTitle
    .replace('回族', '')
    .replace('壮族', '')
    .replace('满族', '')
    .replace('畲族', '')
    .replace('苗族', '')
    .replace('瑶族', '')
    .replace('侗族', '')
    .replace('黎族', '')
    .replace('傣族', '')
    .replace('羌族', '')
    .replace('彝族', '')
    .replace('藏族', '')
    .replace('水族', '')
    .replace('佤族', '')
    .replace('白族', '')
    .replace('怒族', '')
    .replace('土族', '')
    .replace('朝鲜族', '')
    .replace('仫佬族', '')
    .replace('仡佬族', '')
    .replace('毛南族', '')
    .replace('蒙古族', '')
    .replace('土家族', '')
    .replace('哈尼族', '')
    .replace('布依族', '')
    .replace('纳西族', '')
    .replace('拉祜族', '')
    .replace('布朗族', '')
    .replace('景颇族', '')
    .replace('傈僳族', '')
    .replace('独龙族', '')
    .replace('普米族', '')
    .replace('裕固族', '')
    .replace('保安族', '')
    .replace('东乡族', '')
    .replace('撒拉族', '')
    .replace('达斡尔族', '')
    .replace('鄂温克族', '')
    .replace('哈萨克族', '')
    .replace('各族', '');
  if (/自治([县旗])$/.test(tmpTitle)) {
    tmpTitle = tmpTitle
      .replace('蒙古', '')
      .replace('锡伯', '')
      .replace('维吾尔', '')
      .replace('哈萨克', '')
      .replace('塔吉克', '')
      .replace('柯尔克孜', '');
    if (tmpTitle.length > 4) shortTitle = tmpTitle; // 去掉 自治X 之后至少包含两个字
  }
  if (!titleMap[shortTitle]) {
    titleMap[shortTitle] = 0x60;
  }

  // 自治[县旗]
  tmpTitle = shortTitle.replace(/自治([县旗])$/, '$1');
  if (!titleMap[tmpTitle]) {
    titleMap[tmpTitle] = 0x60;
  }

  // 自治[县旗]
  if (/自治([县旗])$/.test(shortTitle)) {
    tmpTitle = shortTitle.replace(/自治([县旗])$/, '');
    if (tmpTitle.length > 2) {
      if (!titleMap[tmpTitle]) {
        titleMap[tmpTitle] = 0x10;
      }
    }
    tmpTitle = shortTitle.replace(/自治([县旗])$/, '区');
    if (!titleMap[tmpTitle]) {
      titleMap[tmpTitle] = 0x10;
    }
  } else {
    tmpTitle = shortTitle.replace(/(.{2,})县$/, '$1');
    if (tmpTitle.length > 2) {
      if (!titleMap[tmpTitle]) {
        titleMap[tmpTitle] = 0x10;
      }
    }
  }

  if (!/([林新矿园郊城市])区$/.test(shortTitle) && /区$/.test(shortTitle)) {
    tmpTitle = shortTitle.replace(/区$/, '');
    if (tmpTitle.length > 2) {
      if (!titleMap[tmpTitle]) {
        titleMap[tmpTitle] = 0x10;
      }
    }
  }

  tmpTitle = shortTitle;
  if (/(.{2,})[林新矿园郊城市]区$/.test(shortTitle)) {
    if (chinaADCTitleSimplifyException.includes(shortTitle)) {
      tmpTitle = shortTitle.replace('区', '');
    } else {
      tmpTitle = shortTitle.replace(/(.{2,})[林新矿园郊城市]区$/, '$1');
    }
  } else if (/(.)[林新矿园郊城市]区$/.test(shortTitle)) {
    tmpTitle = shortTitle.replace('区', '');
  }
  if (tmpTitle.length > 2) {
    if (!titleMap[tmpTitle]) {
      titleMap[tmpTitle] = 0x10;
    }
  }

  return titleMap;
};

export const chinaDistrictTitleMapObject = ((chinaDistrictADCMap) => {
  const result = {done: false, map: {}, distinctNameMap: {}, cityMap: {}}, adcList = Object.keys(chinaDistrictADCMap);
  const setAdcFn = (map, title, adc, possibility) => {
    if (!map[title]) {
      map[title] = [];
    }
    map[title].push({
      adc,
      possibility,
    });
  };
  let pos = 0, i = setInterval(() => {
    let loop = 0;
    while (loop < 200) {
      let code = adcList[pos];
      if (code !== unknownChinaADC && !_.endsWith('00', code)) {
        let cityAdc = code.substr(0, 4) + '00';
        result.cityMap[cityAdc] = result.cityMap[cityAdc] || {};
        let titles = getPossibleChinaDistrictTitles(code);
        Object.keys(titles).forEach(title => {
          setAdcFn(result.map, title, code, titles[title]);
          setAdcFn(result.cityMap[cityAdc], title, code, titles[title]);
        });
      }
      loop++;
      pos++;
      if (pos >= adcList.length) {
        break;
      }
    }
    if (pos >= adcList.length) {
      clearInterval(i);
      result.done = true;
      Object.keys(result.map).forEach(title => {
        if (result.map[title].length === 1) {
          result.distinctNameMap[title] = result.map[title];
        }
      })
      result.map = Object.freeze(result.map);
    }
  }, 100);
  return result;
})(CHINA_ADC);

export const findChinaADC = (() => {
  const provinceNames = Object.keys(chinaProvinceTitleMap), cityNames = Object.keys(chinaCityTitleMap);
  const ignoredCharRegex = /^[.,\/#!$%^&*;{}=\-_`~()?，？（）【】「」|…\s]*$/;
  let distinctDistrictNames = undefined, cityDistrictNames = {};
  return (text, preferAllResult = false) => {
    if (!chinaDistrictTitleMapObject.done) {
      return undefined;
    }
    if (distinctDistrictNames === undefined) {
      distinctDistrictNames = Object.keys(chinaDistrictTitleMapObject.distinctNameMap);
      Object.keys(chinaDistrictTitleMapObject.cityMap).forEach(cityAdc => {
        cityDistrictNames[cityAdc] = Object.keys(chinaDistrictTitleMapObject.cityMap[cityAdc]);
      });
    }
    let result = {province: {}, city: {}, district: {}, overall: {}}, cityAdcSet = new Set(), adcPosMap = {};
    text = `${text}`;

    // 找所有省份
    provinceNames.forEach(provinceName => {
      let pos = text.indexOf(provinceName);
      while (pos >= 0) {
        if (!result.province[`p_${pos}`] || result.province[`p_${pos}`].length < provinceName.length) {
          result.province[`p_${pos}`] = {
            pos,
            length: provinceName.length,
            text: provinceName,
          };
        }
        pos = text.indexOf(provinceName, pos + 1);
      }
    });
    Object.keys(result.province).forEach(provincePosIndex => {
      chinaProvinceTitleMap[result.province[provincePosIndex].text].forEach(item => {
        adcPosMap[item.adc] = adcPosMap[item.adc] || {};
        adcPosMap[item.adc][provincePosIndex] = {
          ...result.province[provincePosIndex],
          possibility: item.possibility,
        };

        if (!result.overall[provincePosIndex] || result.overall[provincePosIndex].possibility < item.possibility) {
          result.overall[provincePosIndex] = {
            adc: item.adc,
            ...result.province[provincePosIndex],
            possibility: item.possibility,
          };
        }
      });
    })

    // 找所有城市
    cityNames.forEach(cityName => {
      let pos = text.indexOf(cityName);
      while (pos >= 0) {
        chinaCityTitleMap[cityName].forEach(item => cityAdcSet.add(item.adc));
        if (!result.city[`p_${pos}`] || result.city[`p_${pos}`].length < cityName.length) {
          result.city[`p_${pos}`] = {
            pos,
            length: cityName.length,
            text: cityName,
          };
        }
        pos = text.indexOf(cityName, pos + 1);
      }
    });

    // 合并省市
    Object.keys(result.city).forEach(cityPosIndex => {
      let cityStartPos = result.city[cityPosIndex].pos;
      chinaCityTitleMap[result.city[cityPosIndex].text].forEach(item => {
        let cityAdc = item.adc, provinceAdc = cityAdc.substr(0, 2) + '0000';
        // 找对应省份
        if (adcPosMap[provinceAdc]) {
          if (Object.keys(adcPosMap[provinceAdc]).findIndex(provincePosIndex => {
            let provinceData = adcPosMap[provinceAdc][provincePosIndex],
              provinceEnd = provinceData.pos + provinceData.text.length;
            if (cityStartPos >= provinceEnd) {
              let extraText = text.substring(provinceEnd, cityStartPos);
              if (extraText.length === 0 || ignoredCharRegex.test(extraText)) {
                adcPosMap[cityAdc] = adcPosMap[cityAdc] || {};
                adcPosMap[cityAdc][provincePosIndex] = {
                  pos: provinceData.pos,
                  length: provinceData.length + extraText.length + result.city[cityPosIndex].length,
                  text: provinceData.text + extraText + result.city[cityPosIndex].text,
                  possibility: provinceData.possibility + item.possibility - extraText.length,
                };

                if (!result.overall[provincePosIndex] || result.overall[provincePosIndex].possibility < adcPosMap[cityAdc][provincePosIndex].possibility) {
                  result.overall[provincePosIndex] = {
                    adc: cityAdc,
                    pos: provinceData.pos,
                    length: provinceData.length + extraText.length + result.city[cityPosIndex].length,
                    text: provinceData.text + extraText + result.city[cityPosIndex].text,
                    possibility: adcPosMap[cityAdc][provincePosIndex].possibility,
                  };
                }
                return true;
              }
            } else if (provinceData.text === result.city[cityPosIndex].text) {
              // 直辖市
              adcPosMap[cityAdc] = adcPosMap[cityAdc] || {};
              adcPosMap[cityAdc][provincePosIndex] = {
                pos: provinceData.pos,
                length: provinceData.length,
                text: provinceData.text,
                possibility: provinceData.possibility + item.possibility,
              };

              if (!result.overall[provincePosIndex] || result.overall[provincePosIndex].possibility < adcPosMap[cityAdc][provincePosIndex].possibility) {
                result.overall[provincePosIndex] = {
                  adc: cityAdc,
                  pos: provinceData.pos,
                  length: provinceData.length,
                  text: provinceData.text,
                  possibility: adcPosMap[cityAdc][provincePosIndex].possibility,
                };
              }
              return true;
            }
            return false;
          }) >= 0) {
            // 已找到，直接返回
            return;
          }
        }
        // 未找到，添加
        if (item.possibility >= 0x1000) {
          adcPosMap[cityAdc] = adcPosMap[cityAdc] || {};
          adcPosMap[cityAdc][cityPosIndex] = {
            ...result.city[cityPosIndex],
            possibility: item.possibility,
          };

          if (!result.overall[cityPosIndex] || result.overall[cityPosIndex].possibility < item.possibility) {
            result.overall[cityPosIndex] = {
              adc: item.adc,
              ...result.city[cityPosIndex],
              possibility: item.possibility,
            };
          }
        }
      });
    });

    // 找所有区县
    let targetDistrictNames = distinctDistrictNames;
    let processFn = (subResult, subText = text, start = 0) => {
      targetDistrictNames.forEach(districtName => {
        let pos = subText.indexOf(districtName);
        while (pos >= 0) {
          if (!subResult[`p_${pos}`] || subResult[`p_${pos}`].length < districtName.length) {
            subResult[`p_${pos}`] = {
              pos: pos + start,
              length: districtName.length,
              text: districtName,
            };
          }
          pos = subText.indexOf(districtName, pos + 1);
        }
      });
    };
    let findAndMergeFn = (cityAdc, cityData, districtAdc, districtData) => {
      let cityEndPos = cityData.pos + cityData.length, cityPosIndex = `p_${cityData.pos}`, districtPosIndex = `p_${districtData.pos}`;
      if (districtData.pos >= cityEndPos) {
        let extraText = text.substring(cityEndPos, districtData.pos);
        if (extraText.length === 0 || ignoredCharRegex.test(extraText)) {
          adcPosMap[districtAdc] = adcPosMap[districtAdc] || {};
          adcPosMap[districtAdc][cityPosIndex] = {
            pos: cityData.pos,
            length: cityData.length + extraText.length + districtData.length,
            text: cityData.text + extraText + districtData.text,
            possibility: cityData.possibility + districtData.possibility - extraText.length,
          };

          if (!result.overall[cityPosIndex] || result.overall[cityPosIndex].possibility < adcPosMap[districtAdc][cityPosIndex].possibility) {
            result.overall[cityPosIndex] = {
              adc: districtAdc,
              pos: cityData.pos,
              length: cityData.length + extraText.length + districtData.length,
              text: cityData.text + extraText + districtData.text,
              possibility: adcPosMap[districtAdc][cityPosIndex].possibility,
            };
          }
          return true;
        }
      }
      return false;
    };
    if (cityAdcSet.size === 0) {
      processFn(result.district);

      // 合并市-区/县
      Object.keys(result.district).forEach(districtPosIndex => {
        let districtStartPos = result.district[districtPosIndex].pos;
        chinaDistrictTitleMapObject.distinctNameMap[result.district[districtPosIndex].text].forEach(item => {
          let districtAdc = item.adc, cityAdc = districtAdc.substr(0, 4) + '00';
          // 找对应城市
          if (adcPosMap[cityAdc]) {
            if (Object.keys(adcPosMap[cityAdc]).findIndex(
              cityPosIndex => findAndMergeFn(cityAdc, adcPosMap[cityAdc][cityPosIndex], districtAdc,
                {...result.district[districtPosIndex], ...item})
            ) >= 0) {
              // 已找到，直接返回
              return;
            }
          }
          // 未找到，添加
          if (item.possibility >= 0x10) {
            adcPosMap[districtAdc] = adcPosMap[districtAdc] || {};
            adcPosMap[districtAdc][districtPosIndex] = {
              ...result.district[districtPosIndex],
              possibility: item.possibility,
            };

            if (!result.overall[districtPosIndex] || result.overall[districtPosIndex].possibility < item.possibility) {
              result.overall[districtPosIndex] = {
                adc: item.adc,
                ...result.district[districtPosIndex],
                possibility: item.possibility,
              };
            }
          }
        });
      });
    } else {
      let overallResultList = Object.values(result.overall).sort((a, b) => a.pos - b.pos),
        maxI = overallResultList.length - 1;
      for (let i = 0; i <= maxI; i++) {
        let {adc, pos, length} = overallResultList[i], start = pos + length, tmpResult = {},
          end = (i === maxI) ? text.length : overallResultList[i + 1].pos;
        if (!cityAdcSet.has(adc)) continue;
        targetDistrictNames = cityDistrictNames[adc];
        processFn(tmpResult, text.substring(start, end), start);

        // 合并市-区/县
        Object.keys(tmpResult).forEach(districtPosIndex => {
          let districtData = {
            ...tmpResult[districtPosIndex],
            possibility: chinaDistrictTitleMapObject.cityMap[adc][tmpResult[districtPosIndex].text][0].possibility,
          };
          let districtAdc = chinaDistrictTitleMapObject.cityMap[adc][districtData.text][0].adc;

          if (!findAndMergeFn(adc, overallResultList[i], districtAdc, districtData)) {
            // 未找到，添加
            if (districtData.possibility >= 0x10) {
              adcPosMap[districtAdc] = adcPosMap[districtAdc] || {};
              adcPosMap[districtAdc][districtPosIndex] = {
                ...districtData,
              };

              if (!result.overall[districtPosIndex] || result.overall[districtPosIndex].possibility < districtData.possibility) {
                result.overall[districtPosIndex] = {
                  adc: districtAdc,
                  ...districtData,
                };
              }
            }
          }
        });
      }
    }

    let finalResult = Object.values(result.overall).sort((a, b) => a.pos - b.pos);
    if (!preferAllResult) {
      let max = finalResult[0];
      finalResult.forEach(item => {
        if (item.possibility > max.possibility) {
          max = item;
        }
      });
      return max;
    }
    return finalResult;
  }
})();

// 国内地理位置展示默认颜色
export const ChinaADCColorMap = Object.freeze({
    '000000': '#40b3b3', // 全国
    '110000': '#b39929', // 北京
    '120000': '#4789b3', // 天津
    '130000': '#b38e29', // 河北
    '140000': '#46b390', // 山西
    '150000': '#51b324', // 内蒙古
    '210000': '#b36b2d', // 辽宁
    '220000': '#46b38e', // 吉林
    '230000': '#39b339', // 黑龙江
    '310000': '#3f3fcc', // 上海
    '320000': '#87b346', // 江苏
    '330000': '#b36c02', // 浙江
    '340000': '#b39247', // 安徽
    '350000': '#2e9ab3', // 福建
    '360000': '#87b329', // 江西
    '370000': '#466ab3', // 山东
    '410000': '#b35646', // 河南
    '420000': '#3553cc', // 湖北
    '430000': '#b3b346', // 湖南
    '440000': '#3f5ecc', // 广东
    '450000': '#a1b32d', // 广西
    '460000': '#3998b3', // 海南
    '500000': '#b35d17', // 重庆
    '510000': '#3685b3', // 四川
    '520000': '#6eb329', // 贵州
    '530000': '#9cb32d', // 云南
    '540000': '#36b3a8', // 西藏
    '610000': '#b38825', // 陕西
    '620000': '#acb329', // 甘肃
    '630000': '#356ccc', // 青海
    '640000': '#2eb389', // 宁夏
    '650000': '#b39d25', // 新疆
    '710000': '#46b36f', // 台湾
    '810000': '#b36440', // 香港
    '820000': '#46b346', // 澳门
    [unknownChinaADC]: '#b3a140', // 未知
    // TODO 是否需要定义更细致的地区颜色？
});

/**
 * 数组去重
 * ES6 有更简单的写法，但是这个方法好用一些
 * @param array
 * @param isSorted 表示函数传入的数组是否已排过序，如果为 true，将会采用更快的方法进行去重
 * @param iteratee 传入一个函数，可以对每个元素进行重新的计算，然后根据处理的结果进行去重
 * @returns {Array}
 */
export const unique = (array, isSorted, iteratee) => {
  const res = [];
  let seen = [];

  let i = 0;
  const len = array.length;
  for (; i < len; i++) {
      const value = array[i];
      const computed = iteratee ? iteratee(value, i, array) : value;
      if (isSorted) {
            if (!i || seen !== computed) {
                res.push(value)
            }
            seen = computed;
        } else if (iteratee) {
            if (seen.indexOf(computed) === -1) {
                seen.push(computed);
                res.push(value);
            }
        } else if (res.indexOf(value) === -1) {
            res.push(value);
        }
    }
    return res;
};

//深复制，要想达到深复制就需要用递归
export const deepCopy = (o, c) => {
    c = c || {}
    for (let i in o) {
        if (typeof o[i] === 'object') {
            //要考虑深复制问题了
            if (o[i].constructor === Array) {
                //这是数组
                c[i] = []
            } else {
                //这是对象
                c[i] = {}
            }
            deepCopy(o[i], c[i])
        } else {
            c[i] = o[i]
        }
    }
    return c
};

// 价格转换,转换成元，转换成分
export const convertPrice = (value, to = '元') => {
    value = parseFloat(value);
    if (to === '元') {
        return value / 100
    } else if (to === '分') {
        return value * 100
    } else {
        return value
    }
};

// 对象排序，升序
export const ObjectSortAsc = (obj, numb) => {
    if (obj && obj.length === 0) return obj;
    let arr = [];
    for (let i in obj) {
        arr.push([obj[i], i]);
    }
    arr.sort(function (a, b) {
        return a[0] - b[0];
    });
    let len = arr.length,
        obj2 = {};
    let max = numb && numb > 0 && numb < len ? numb : len;
    for (let i = 0; i < max; i++) {
        obj2[arr[i][1]] = arr[i][0];
    }
    return obj2;
};

// 对象排序，倒序
export const ObjectSortDesc = (obj, numb) => {
    if (obj && obj.length === 0) return obj;
    let arr = [];
    for (let i in obj) {
        arr.push([obj[i], i]);
    }
    arr.sort(function (a, b) {
        return b[0] - a[0];
    });
    let len = arr.length,
        obj2 = {};
    let max = numb && numb > 0 && numb < len ? numb : len;
    for (let i = 0; i < max; i++) {
        obj2[arr[i][1]] = arr[i][0];
    }
    return obj2;
};

/**
 * 高亮关键字，会先去除html标签，在
 * @param html 被高亮的字符串
 * @param word 关键词 空格分割
 * @param color 高亮的背景色 和 字体颜色
 * @returns {string}
 */
export const highlightHtmlWord = (html, word, color) => {
    if (!color) {
        color.background = 'rgb(33,86,85)';
        color.color = '#f6f6f6';
    }
    // 按空格分解成数组
    let wordArr = word.split(' ');
    let newstr = html.replace(/<[^>]+>/g, "");//去掉所有的html标记
    let reg = new RegExp("(" + wordArr.join('|') + ")", "ig");
    newstr = newstr.replace(reg, "<span style='background-color: " + color.background + ";color:" + color.color + ";'>$1</span>"); // background-color: lightyellow;
    return newstr
};

/**
 * 取最长公共子字符串长度
 *
 * @param strA
 * @param strB
 * @return {number}
 */
export const longestCommonSubstring = (strA, strB) => {
    // init max value
    let longestCommonSubstring = 0;
    // init 2D array with 0
    let table = [], lenA = strA.length, lenB = strB.length, row, col;
    for(row = 0; row <= lenA; row++){
        table[row] = [];
        for(col = 0; col <= lenB; col++){
            table[row][col] = 0;
        }
    }
    // fill table
    let i, j;
    for(i = 0; i < lenA; i++){
        for(j = 0; j < lenB; j++){
            if(strA[i] === strB[j]){
                if(table[i][j] === 0){
                    table[i+1][j+1] = 1;
                } else {
                    table[i+1][j+1] = table[i][j] + 1;
                }
                if(table[i+1][j+1] > longestCommonSubstring){
                    longestCommonSubstring = table[i+1][j+1];
                }
            } else {
                table[i+1][j+1] = 0;
            }
        }
    }
    return longestCommonSubstring;
};

export const getErrorInfo = error => {
  let msg = '处理您的信息流时遇到异常，可能是网络原因，您可稍后再试一下。';
  let code = 500;
  if (error && error.response) {
    error = error.response;
  }
  if (error) {
    if (error.data) {
      code = error.data.code || error.data.status;
      msg = error.data.msg || msg;
    } else {
      code = error.status;
    }
  }
  return {code, msg};
};

export const getDisplayNumber = (value = 0, fix = 0) => {
  const unit = ['', '万', '亿'];
  let pos = 0;
  while (true) {
    if (pos === 0) {
      if (value > 99999) {
        pos++;
        value /= 10000;
      } else {
        break;
      }
    } else {
      if (value > 9999) {
        pos++;
        value /= 10000;
      } else {
        break;
      }
    }
  }
  if (pos > 0) {
    if (value < 100) {
      fix = 2;
    } else if (value < 1000) {
      fix = 1;
    } else {
      fix = 0;
    }
  }
  return pos < unit.length ? value.toFixed(fix) + unit[pos] : '10万亿+';
};

export const checkUploadImage = (file, message, sizeInM = 2) => {
  const isImage = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png';
  if (!isImage) {
    message.error('请选择JPEG、PNG格式的图片文件!');
  }
  const validSize = file.size / 1024 / 1024 < sizeInM;
  if (!validSize) {
    let imgTypeName = file.type === 'image/png'?'JPEG':'PNG';
    message.error(`请选择小于 ${sizeInM}MB 的图片文件，可以尝试转换图片格式为${imgTypeName}!`);
  }

  return isImage && validSize;
};

export const copyUrlToBoard = (path, success, failure) => {
  const input = document.createElement('input');
  document.body.appendChild(input);
  input.setAttribute('readonly', 'readonly');
  input.setAttribute('value', path);
  input.select();
  if (document.execCommand('copy')) {
    document.execCommand('copy');
    success && success();
  } else {
    failure && failure();
  }
  document.body.removeChild(input);
};

// 识别标签包含日期
export const validMomentDateFormats = ['MM-DD-YYYY', 'MM-YYYY', 'YYYY MM DD', 'YYYY MM', 'YYYYMMDD', 'YYYYMM',
  'YYYY-MM-DD', 'YYYY-MM', 'YYYY-M-D', 'YYYY-M', 'YYYY/MM/DD', 'YYYY/MM', 'YYYY年MM月DD日', 'YYYY年MM月', 'YYYY年M月D日', 'YYYY年M月'];
/*export const validMomentDateFormats = ['MM-DD-YYYY', 'YYYY MM DD', 'YYYYMMDD', 'YYYY-MM-DD', 'YYYY-M-D', 'YYYY/MM/DD',
  'YYYY年MM月DD日', 'YYYY年M月D日'];*/

export const validMomentTimeFormats = [
  'MM-DD-YYYY HH:mm', 'MM-DD-YYYY[T]HH:mm', 'MM-DD-YYYY HH:mm:ss', 'MM-DD-YYYY[T]HH:mm:ss',
  'MM-DD-YYYY h:mm a', 'MM-DD-YYYY h:mm:ss a', 'MM-DD-YYYY h:mm A', 'MM-DD-YYYY h:mm:ss A',

  'YYYY-MM-DD HH:mm', 'YYYY-MM-DD[T]HH:mm', 'YYYY-MM-DD HH:mm:ss', 'YYYY-MM-DD[T]HH:mm:ss',
  'YYYY-MM-DD h:mm a', 'YYYY-MM-DD h:mm:ss a', 'YYYY-MM-DD h:mm A', 'YYYY-MM-DD h:mm:ss A',

  'YYYY-M-D HH:mm', 'YYYY-M-D[T]HH:mm', 'YYYY-M-D HH:mm:ss', 'YYYY-M-D[T]HH:mm:ss',
  'YYYY-M-D h:mm a', 'YYYY-M-D h:mm:ss a', 'YYYY-M-D h:mm A', 'YYYY-M-D h:mm:ss A',

  'YYYY/MM/DD HH:mm', 'YYYY/MM/DD[T]HH:mm', 'YYYY/MM/DD HH:mm:ss', 'YYYY/MM/DD[T]HH:mm:ss',
  'YYYY/MM/DD h:mm a', 'YYYY/MM/DD h:mm:ss a', 'YYYY/MM/DD h:mm A', 'YYYY/MM/DD h:mm:ss A',

  'YYYY年MM月DD日 HH:mm', 'YYYY年MM月DD日 HH:mm:ss',
  'YYYY年MM月DD日HH时', 'YYYY年MM月DD日HH时mm分', 'YYYY年MM月DD日HH时mm分ss秒',
  'YYYY年MM月DD日Ah时', 'YYYY年MM月DD日Ah时mm分', 'YYYY年MM月DD日Ah时mm分ss秒',
  'YYYY年MM月DD日Ah点', 'YYYY年MM月DD日Ah点mm分', 'YYYY年MM月DD日Ah点mm分ss秒',

  'YYYY年M月D日 HH:mm', 'YYYY年M月D日 HH:mm:ss',
  'YYYY年M月D日HH时', 'YYYY年M月D日HH时mm分', 'YYYY年M月D日HH时mm分ss秒',
  'YYYY年M月D日Ah时', 'YYYY年M月D日Ah时mm分', 'YYYY年M月D日Ah时mm分ss秒',
  'YYYY年M月D日Ah点', 'YYYY年M月D日Ah点mm分', 'YYYY年M月D日Ah点mm分ss秒',
];

export const stringToMoment = text => {
  let result, i;

  i = 0;
  do {
    while (text.length < validMomentTimeFormats[i] && i < validMomentTimeFormats.length) i++;
    if (i >= validMomentTimeFormats.length) break;
    result = moment(text, validMomentTimeFormats[i]);
    i++;
  } while ((!result.isValid() || text.indexOf(result.format(validMomentTimeFormats[i - 1])) < 0) && i < validMomentTimeFormats.length);

  if (result && result.isValid() && text.indexOf(result.format(validMomentTimeFormats[i - 1])) >= 0) return result;

  i = 0;
  do {
    result = moment(text, validMomentDateFormats[i]);
    i++;
  } while ((!result.isValid() || text.indexOf(result.format(validMomentDateFormats[i - 1])) < 0) && i < validMomentDateFormats.length);

  return (result.isValid() && text.indexOf(result.format(validMomentDateFormats[i - 1])) >= 0) ? result.add(12, 'h') : undefined;
};

export const isDateString = (text) => {
  let result = false,
    parseText = text;
  if (
    /\d+/.test(parseText)
    && (parseText.indexOf('/')
      || parseText.indexOf('-')
      || parseText.indexOf('年')
      || parseText.indexOf('月')
      || parseText.indexOf('日')
      || parseText.indexOf('T')
      || /^\d+$/.test(parseText))
  ) {
    if (parseText.indexOf('年') || parseText.indexOf('月') || parseText.indexOf('日')) {
      parseText = parseText.replace('年', '-');
      parseText = parseText.replace('月', '-');
      parseText = parseText.replace('日', '');
    }
    if (!(/.*[\u4e00-\u9fa5]+.*$/.test(parseText))) {
      if (moment(parseText, moment.RFC_2822).isValid()
        || moment(parseText, moment.ISO_8601).isValid()
        || moment(parseText, "MM-DD-YYYY").isValid()
        || moment(parseText, "YYYY MM DD").isValid()
        || moment(parseText, "YYYYMMDD").isValid()
        || moment(parseText, "YYYY/MM/DD").isValid()
      ) {
        // console.log(' common functions -> isDateString -> parseText time :', moment(parseText), '-->',moment(parseText).valueOf());
        result = true;
      }
    }
  }
  return result;
};

/* 超级点赞礼物列表 */
export const getViewBadgeList = () => {
  return [
    {
      id: 0,
      value: 'safflower',
      label: '红花',
      icon: 'icon-safflower',
    },
    {
      id: 1,
      value: 'certificate-of-award',
      label: '奖状',
      icon: 'icon-certificate-of-award',
    },
    {
      id: 2,
      value: 'medal',
      label: '奖牌',
      icon: 'icon-medal',
    },
    {
      id: 3,
      value: 'trophy',
      label: '奖杯',
      icon: 'icon-trophy',
    },
    {
      id: 4,
      value: 'silk-banner',
      label: '锦旗',
      icon: 'icon-silk-banner',
    },
  ];
};

export const weekName = ['周一', '周二', '周三', '周四', '周五', '周六', '周日'];

export const calculateStandardDate = () => {
  let today = moment().startOf('day');
  let thisMonth = today.clone().startOf('month');
  let thisYear = today.clone().startOf('year');

  return {
    today,
    yesterday: today.clone().subtract(1, 'days'),
    beforeYesterday: today.clone().subtract(2, 'days'),
    tomorrow: today.clone().add(1, 'days'),
    afterTomorrow: today.clone().add(2, 'days'),
    threeDaysAfter: today.clone().add(3, 'days'),
    thisMonday: today.clone().subtract(moment().weekday() - 1, 'days'),
    previousMonday: moment().weekday(-6).hour(0).minute(0).second(0),
    thisSunday: today.clone().add(7 - moment().weekday(), 'days').hour(23).minute(59).second(59),
    nextSunday: today.clone().add(14 - moment().weekday(), 'days').hour(23).minute(59).second(59),
    thisMonth,
    previousMonth: moment(thisMonth).subtract(1, 'month'),
    nextMonth: moment(thisMonth).add(1, 'month'),
    thisYear,
    nextYear: thisYear.clone().add(1, 'year'),
  };
};

let today = moment().startOf('day');
let standardDate = calculateStandardDate();

/**
 * 处理到期时间展示文本，最小显示 天 （不显示小时、分、秒）
 *
 * @param {string} date
 *
 * @return {string[]|null}
 */
export const formatAfterDate = date => {
  // date = '2019-11-09 23:59:59'
  if (!date) return null;
  // console.log('formatAfterDate ------------------------>');
  let result = [];
  let targetMoment = moment(date);
  let realToday = moment().startOf('day');
  if (realToday.diff(today) !== 0) {
    standardDate = calculateStandardDate();
    today = realToday;
  }
  let dayDiff = targetMoment.diff(today, 'days');
  const {
    yesterday,
    beforeYesterday,
    tomorrow,
    afterTomorrow,
    threeDaysAfter,
    thisMonday,
    previousMonday,
    thisSunday,
    nextSunday,
    thisMonth,
    nextMonth,
    thisYear,
  } = standardDate;

  if (targetMoment.valueOf() >= today.valueOf()) {
    if (targetMoment <= tomorrow) {
      result = ['今天'];
    } else if (targetMoment <= afterTomorrow) {
      result = ['明天'];
    } else if (targetMoment <= threeDaysAfter) {
      result = ['后天'];
    } else if (targetMoment <= thisSunday) {
      result = [`本${weekName[targetMoment.weekday()]}`, `${dayDiff}天后`];
    } else if (targetMoment <= nextSunday) {
      result = [`下${weekName[targetMoment.weekday()]}`, `${dayDiff}天后`];
    } else if (targetMoment <= nextMonth) {
      result = [`${targetMoment.format('MM-DD')}`, `${dayDiff}天后`];
    } else {
      result = [`${targetMoment.format('YYYY-MM-DD')}`, `${dayDiff}天后`];
    }
  } else {
    if (yesterday <= targetMoment) {
      result = ['昨天'];
    } else if (beforeYesterday <= targetMoment) {
      result = ['前天'];
    } else if (thisMonday <= targetMoment) {
      if (targetMoment.day() > 0) {
        result = [`本${weekName[targetMoment.weekday()]}`, `${-dayDiff + 1}天前`];
      } else if (targetMoment.day() === 0) {
        result = [`上${weekName[targetMoment.weekday()]}`, `${-dayDiff + 1}天前`];
      }
    } else if (previousMonday <= targetMoment) {
      if (targetMoment.day() > 0) {
        result = [`上${weekName[targetMoment.weekday()]}`, `${-dayDiff + 1}天前`];
      } else if (targetMoment.day() === 0) {
        result = [`上${weekName[targetMoment.weekday()]}`, `${-dayDiff + 1}天前`];
      }
    } else if (thisMonth <= targetMoment) {
      result = [`${targetMoment.format('本月DD日')}`, `${-dayDiff + 1}天前`];
    } else if (thisYear <= targetMoment) {
      result = [`${targetMoment.format('MM-DD')}`, `${-dayDiff + 1}天前`];
    } else {
      if (-dayDiff > 60) {
        result = [`${targetMoment.format('YYYY-MM-DD')}`, null];
      } else {
        result = [`${targetMoment.format('YYYY-MM-DD')}`, `${-dayDiff + 1}天前`];
      }
    }
  }
  // console.log('formatAfterDate -> result ----->', result);
  return result;
};

export const formatAfterDate_en = date => {
  // date = '2019-11-09 23:59:59'
  if (!date) return null;
  // console.log('formatAfterDate ------------------------>');
  let result = [];
  let targetMoment = moment(date);
  let realToday = moment().startOf('day');
  if (realToday.diff(today) !== 0) {
    standardDate = calculateStandardDate();
    today = realToday;
  }
  let dayDiff = targetMoment.diff(today, 'days');
  const {
    yesterday,
    beforeYesterday,
    tomorrow,
    afterTomorrow,
    threeDaysAfter,
    thisMonday,
    previousMonday,
    thisSunday,
    nextSunday,
    thisMonth,
    nextMonth,
    thisYear,
  } = standardDate;

  if (targetMoment.valueOf() >= today.valueOf()) {
    if (targetMoment <= tomorrow) {
      result = ['today'];
    } else if (targetMoment <= afterTomorrow) {
      result = ['tomorrow'];
    } else if (targetMoment <= threeDaysAfter) {
      result = ['the day after tomorrow'];
    } else if (targetMoment <= thisSunday) {
      result = [`this ${weekName[targetMoment.weekday()]}`, `${dayDiff} day after`];
    } else if (targetMoment <= nextSunday) {
      result = [`next ${weekName[targetMoment.weekday()]}`, `${dayDiff} day after`];
    } else if (targetMoment <= nextMonth) {
      result = [`${targetMoment.format('MM-DD')}`, `${dayDiff} days after`];
    } else {
      result = [`${targetMoment.format('YYYY-MM-DD')}`, `${dayDiff} days after`];
    }
  } else {
    if (yesterday <= targetMoment) {
      result = ['yesterday'];
    } else if (beforeYesterday <= targetMoment) {
      result = ['the day before yesterday'];
    } else if (thisMonday <= targetMoment) {
      if (targetMoment.day() > 0) {
        result = [`this ${weekName[targetMoment.weekday()]}`, `${-dayDiff + 1} days before`];
      } else if (targetMoment.day() === 0) {
        result = [`last ${weekName[targetMoment.weekday()]}`, `${-dayDiff + 1} days before`];
      }
    } else if (previousMonday <= targetMoment) {
      if (targetMoment.day() > 0) {
        result = [`last ${weekName[targetMoment.weekday()]}`, `${-dayDiff + 1} days before`];
      } else if (targetMoment.day() === 0) {
        result = [`last ${weekName[targetMoment.weekday()]}`, `${-dayDiff + 1} days before`];
      }
    } else if (thisMonth <= targetMoment) {
      result = [`${targetMoment.format('DD of this month')}`, `${-dayDiff + 1} days before`];
    } else if (thisYear <= targetMoment) {
      result = [`${targetMoment.format('MM-DD')}`, `${-dayDiff + 1} days before`];
    } else {
      if (-dayDiff > 60) {
        result = [`${targetMoment.format('YYYY-MM-DD')}`, null];
      } else {
        result = [`${targetMoment.format('YYYY-MM-DD')}`, `${-dayDiff + 1} days before`];
      }
    }
  }
  // console.log('formatAfterDate -> result ----->', result);
  return result;
};

export const saveJsonFile = (content, name) => {
  let data = new TextEncoder().encode(JSON.stringify(content, null, 4));

  let blob = new Blob([data], {
    type: 'application/octet-stream',
  });

  let url = URL.createObjectURL(blob);
  let link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', `${name}.json`);

  let event = document.createEvent('MouseEvents');
  event.initMouseEvent('click', true, true, window, 1, 0,
    0, 0, 0, false, false, false, false,
    0, null);
  link.dispatchEvent(event);
};

export const scrollToEnd = (me, containerId = undefined, autoScroll = undefined) => {
  containerId = containerId || (me.state ? me.state.containerId : undefined);
  autoScroll = autoScroll === undefined ? me.autoScroll : autoScroll;

  if (!containerId) return;

  let objDiv = document.getElementById(containerId);
  if (objDiv) {
    if (!!autoScroll) {
      // 计算位置
      let dx = objDiv.scrollHeight;
      if (dx > objDiv.clientHeight) {
        // 计算滚动位置
        dx = 0;
        objDiv.childNodes.forEach((node, idx) => {
          if (idx <= objDiv.childNodes.length) dx += node.offsetHeight;
        });
      }
      animateScroll.scrollTo(
        dx,
        {
          containerId: containerId,
          duration: 350,
        }
      );
    }
  }
};

// noinspection CommaExpressionJS
export const strToFunc = str => (0, eval)("(" + str + ")");