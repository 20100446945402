/*
 * @Author: Carr.s
 * @Date: 2022-05-24 11:26:28
 * @LastEditTime: 2022-06-02 14:00:39
 * @LastEditors: Carr.s
 * @Description: 指标变化洞察
 * @Copyright by joinmap
 */
import React from "react";
import ViewInsightChartTpl from "@/components/insightView/template/insight.chart.tpl";
import styles from "@/style/components/insightView/insight.less";
import Highcharts from "highcharts";
import heatmap from "highcharts/modules/heatmap";
heatmap(Highcharts);

class ViewIndexChangeInsight extends ViewInsightChartTpl {
  viewerTitle = "指标变化";
  // column1
  column1_Title = "指标"; // 显示名称
  column1_Unit = "个"; // 显示的文字单位
  column1_subTitle = () => {
    // 列表顶部副标题文字
    const { _column1Len, _column1Total } = this.state;
    return <>已计算出 {_column1Len} 个</>;
  };
  column1_ValueRender = (column1_data, idx, nodePath = undefined) => {
    return "";
  };
  // column2
  column2_showType = "none"; // 第二列的展现形式，list=显示列，tree=显示树, none=不显示
  column2_DataField = "news"; // 取值字段
  column2_Title = "指标"; // 显示名称
  // api
  api_path = "/wth_server_api/data_index_by_view_id";
  // chart
  chartType = "highcharts";
  initChartOption = () => {
    let me = this;
    return {
      colors: this._chartColors,
      credits: {
        enabled: false, // 禁用版权信息
      },
      chart: {
        backgroundColor: null,
        // 指定内边距，下面的四个配置可以用 spacing: [10, 10, 15, 10] 来代替
        spacingBottom: 0,
        spacingTop: 10,
        spacingLeft: 10,
        spacingRight: 10,
        // 指定图表大小
        // width: "50%", // width 不支持百分数
        // width: Math.round(document.documentElement.clientWidth / 2),
        // height: document.documentElement.clientHeight - 70 + "px",
        type: "heatmap",
      },
      title: {
        text: "",
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        backgroundColor: "#fff",
        formatter: function () {
          //   console.log("this--》", this);
          let idx = this.point.x;
          let name = me._chartData[idx].name;
          let value = me._chartData[idx].value;
          let set = me._chartData[idx]["news"][0].name;
          return `<span style="width:60px">指标</span>：<span style="z-index:9999;"><b>${name}</b><br/><span style="width:60px">变化</span>：${value}%</sub></span><br/><span style="width:60px">原文</span>：${set}</sub></span>`;
        },
        style: {
          fontSize: 14,
        },
      },
      xAxis: {
        title: {
          text: "指标",
          align: "high",
          style: {
            color: "#eeeeee",
          },
        },
        labels: {
          style: {
            color: "#eeeeee",
          },
          rotation: -45,
          formatter: function () {
            let txt =
              this.value.length > 8
                ? this.value.slice(0, 8) + "..."
                : this.value;
            return txt;
          },
        },
        categories: [],
      },
      yAxis: {
        title: {
          text: "数值变化百分比",
          align: "high",
          style: {
            color: "#eeeeee",
          },
        },
        categories: [""],
        labels: {
          style: {
            color: "#eeeeee",
          },
        },
      },
      legend: {
        align: "right",
        layout: "vertical",
        margin: 30,
        verticalAlign: "middle",
        y: 25,
        symbolHeight: 280,
      },
      colorAxis: {
        xstops: [
          [0, "#4cb0eb"],
          [0.1, "#dab6b2"],
          [1, "#c4463a"],
        ],
        stops: [
          [0, "#4cb0eb"],
          // [0.49, "#92cff3"],
          // [0.49, "#dab6b2"],
          [0.5, "#dab6b2"],
          [1, "#c4463a"],
        ],
        // minColor: "#dab6b2",
        // minColor: "#4cb0eb",
        // maxColor: "#c4463a",
        min: 0,
        max: 100,
        startOnTick: false,
        endOnTick: false,
        labels: {
          format: "{value}%",
        },
      },
      plotOptions: {
        heatmap: {
          // shared options for all heatmap series
          point: {
            events: {
              click: (e) => {
                console.log("point event e:", e);
                let sIndex = undefined;
                let sName = undefined;

                sIndex = e.point.x;
                sName = e.point.yCategory;
                me._column1_onClick(sIndex, sName, false);
              },
            },
          },
        },
      },
      series: [
        {
          name: "指标变化百分比",
          nullColor: "#EFEFEF",
          borderWidth: 0,
          data: [
            [0, 0, 10],
            [1, 0, 19],
            [2, 0, 8],
            [3, 0, 24],
            [4, 0, 67],
            [5, 0, 67],
            [6, 0, 67],
            [7, 0, 67],
            [8, 0, 67],
            [9, 0, 67],
            [10, 0, 67],
            [11, 0, 67],
          ],
          dataLabels: {
            enabled: true,
            color: "#000000",
          },
        },
      ],
    };
  };
  refreshChartOptions = (nodePath = null) => {
    const {
      query: { isDebug },
    } = this.props;
    if (isDebug) console.log("refreshChartOptions 0 nodePath:", nodePath);
    let me = this;
    let data = [];
    let xAxisData = [];
    this._chartData.forEach((el, idx) => {
      data.push([idx, 0, el.value, el.name]);
      xAxisData.push(el.name);
    });

    this._chartOption = this.initChartOption();
    this._chartOption.xAxis.categories = xAxisData;
    this._chartOption.series[0].data = data;

    // ---------------------------------------------
    // 图表大小的处理
    let chartMaxWidth = 1000;
    let chartMaxHeight = 1000;
    // 计算图表的宽度
    let chartWidth = document.getElementById("chart_div").clientWidth;
    chartWidth = chartWidth > chartMaxWidth ? chartMaxWidth : chartWidth;
    me._chartOption.chart.width = chartWidth;
    // 计算图表的高度
    let chartHeight = document.getElementById("chart_div").clientHeight;
    chartHeight = chartHeight > chartMaxHeight ? chartMaxHeight : chartHeight;
    me._chartOption.chart.height = chartHeight;

    if (isDebug) console.log("me._chartOption:", me._chartOption);
    return;
  };
}
export default ViewIndexChangeInsight;
