import React from 'react';
import PropTypes from 'prop-types';
import {Form, Input, Modal} from 'antd';
import PB, {SimplePB} from "@/libs/simplePB";
import {getNodeDisplayTitle} from "@/constants/vis.defaultDefine.1";

class CopyNodeToViewModal extends React.Component {
  state = {
    viewId: '',
  };

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (this.props.visible === false && nextProps.visible === true) {
      /*const me = this;
      this.setState((state, props) => {
        requestAnimationFrame(() => {
          const {form} = me.props;
          form.resetFields();
        });
        return {viewId: props.node.fname};
      });*/
    }
    return true;
  }

  onSave = () => {
    let me = this;
    const {form} = me.props;
    form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
        // 节点信息中的名称可编辑
        const {viewId} = values;
        me.props.bus.emit('network', 'node.do_copy_to_view', {viewId:viewId});
      }
    });
  };

  render() {
    let me = this;
    const {visible, onClose, form} = me.props;
    const {getFieldDecorator} = form;
    const formItemLayout = {
      labelCol: {
        xs: {span: 8},
        sm: {span: 4},
      },
      wrapperCol: {
        xs: {span: 36},
        sm: {span: 18},
      },
    };

    // noinspection RequiredAttributes
    return (
      <Modal
        closable={!me.props.processing}
        visible={visible}
        title={`复制${me.props.copyNodeToViewType===1?'节点':'子图'}到图谱`}
        okText={'确定'}
        cancelText={'取消'}
        onCancel={onClose}
        onOk={me.onSave}
        okButtonProps={{loading: me.props.processing}}
        cancelButtonProps={{disabled: me.props.processing}}
        centered
      >
        <Form>
          <Form.Item
            {...formItemLayout}
            label="图谱ID"
          >
            {getFieldDecorator('viewId', {
              initialValue: me.state.viewId,
              rules: [{
                required: true, message: '图谱ID',
              }],
            })(
              <Input/>
            )}
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

const WrappedCopyNodeToViewModal = Form.create()(CopyNodeToViewModal);

// noinspection JSValidateTypes
WrappedCopyNodeToViewModal.defaultProps = {
  bus: PB,
  visible: false,
  processing: false,
};

// noinspection JSValidateTypes
WrappedCopyNodeToViewModal.propTypes = {
  bus: PropTypes.instanceOf(SimplePB),
  visible: PropTypes.bool,
  processing: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  copyNodeToViewType: PropTypes.number
};

export default WrappedCopyNodeToViewModal;
