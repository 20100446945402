import React from 'react';
import { Icon } from 'antd';
import {connect} from 'react-redux'
import {changeEmailSuccessAction} from '@/redux-saga/account/actions';

import UserDataProvider from '@/components/common/dataProvider/common.dataProvider.user';
import DurationNoticePanel from '@/components/common/common.durationNoticePanel';
import LoginRelatedView from '@/components/common/common.loginRelatedView';

import frameStyle from '@/style/components/common.loginRelatedView.less';
import style from '@/style/containers/verifyEmailView.less';

class VerifyEmailView extends React.Component {
  state = {
    redirectToMainViewAt: 0,
    status: 'processing',
    msg: '',
    errMsg: '', // 错误提示信息
  };

  componentWillMount() {
    const {token} = this.props.match.params;
    if (!token) {
      this.props.history.push("/");
    }
  }

  componentDidMount() {
    let me = this;

    UserDataProvider.verifyEmailByToken(me.props.match.params.token).then(({email}) => {
      me.setState({
          status: 'success',
          redirectToMainViewAt: (new Date()).getTime() + 6000},
        () => me.props.onEmailVerified(email)
      );
    }).catch(({msg}) => {
      me.setState({
        status: 'fail',
        errMsg: msg,
        redirectToMainViewAt: (new Date()).getTime() + 6000,
      });
    });
  }

  render() {
    let me = this;
    return (
      <div className={`${frameStyle["container"]} dark-theme`}>
        <LoginRelatedView.Header history={me.props.history} />
        <LoginRelatedView.Background />
        <div className={frameStyle['content']}>
          {
            me.state.status === 'success' ? (
              <DurationNoticePanel
                redirectAt={me.state.redirectToMainViewAt}
                btnText={'进入首页'}
                onBack={() => me.props.history.push('/')}
              >
                <div className={style['verify-email-success-item']}>
                  <Icon type="check-circle" style={{fontSize: '3rem', color: '#52c41a'}}/>
                  <div className={style['info-text']} >
                    <div className={style['text']}>验证邮箱成功</div>
                    <div className={style['tips']}>您的新邮箱将获得邮箱服务功能</div>
                  </div>
                </div>
                <div className={style['verify-email-success-item']} style={{marginBottom: '3rem'}}>
                  <Icon type="login" style={{fontSize: '3rem', color: '#1890ff'}}/>
                  <div className={style['info-text']} >
                    <div className={style['text']}>邮箱登录炬图</div>
                    <div className={style['tips']}>{
                      me.props.userInfo['noPassword'] ? (
                        <span>随机密码已发送到该邮箱，请注意查收</span>
                      ) : (
                        <span>使用浏览器密阴管理可快捷登录系统</span>
                      )
                    }</div>
                  </div>
                </div>
              </DurationNoticePanel>
            ) : null
          }
          {
            me.state.status === 'fail' ? (
              <DurationNoticePanel
                redirectAt={me.state.redirectToMainViewAt}
                btnText={'更多搜索'}
                onBack={() => me.props.history.push('/')}
              >
                <div style={{paddingLeft: '1.5rem'}}>
                  <h1 className={style['form-title']}>验证邮箱失败</h1>
                  <div className={style['form-item']} style={{marginBottom: '3em'}}>
                    <div>您的邮箱由于</div>
                    <div style={{fontSize: '2rem', padding: '10px 0'}}>{me.state.errMsg}</div>
                    <div>验证失败，请进入个人中心重新操作</div>
                  </div>
                </div>
              </DurationNoticePanel>
            ) : null
          }
        </div>
        <LoginRelatedView.Intro />
        <LoginRelatedView.Bottom />
      </div>
    );
  }
}

export default connect(
  state => ({
    userInfo: state.account.userInfo,
  }),
  dispatch => ({
    onEmailVerified: email => dispatch(changeEmailSuccessAction(email)),
  })
)(VerifyEmailView);