import React from "react";
// import { connect } from "react-redux";
import {
  Empty,
  message,
  Spin,
  Input,
  Row,
  Col,
  Button,
  Modal,
  Icon,
  Checkbox,
} from "antd";
// import ClampLines from "@/libs/my-react-clamp-lines";
// import relationStyle from "@/style/components/main.relation.less";
// import axios from "axios";
// import { getToken } from "@/utils/HttpUtil";
// import Qs from "qs";
import schViewStyles from "@/style/components/searchView/searchView.less";
import styles from "@/style/components/searchView/search_newsEvent.less";
// import userStyles from "@/style/default/user.less";
// import dashboardStyles from "@/style/containers/dashboard.less";
// import UserAvatar from 'react-user-avatar';
// import {AvatarColors, IconTypes} from '@/constants/common';
// import Icon from "@/components/common/common.icon";
// import copy from 'copy-to-clipboard';
// import ViewInfoCard from "@/components/dashboard/dashboard.viewInfoCard";
// import ViewInfoCard from "@/components/common/objects/common.viewInfoCard.style.1";
// import UserNickAvatar from "@/components/common/objects/common.userAvatar";
import { API_CallMicroService } from "@/api/microService.js";
// import { API_GetViewById } from "@/libs/view/network/api.js";

import { getHttpUtil } from "@/utils/HttpUtil";
// API_AddRelationGraph = (viewId, nodes, edges, testOnly = false, doAnalyze = true)
import { API_AddRelationGraph } from "@/libs/view/network/api";
// import Highlighter from "react-highlight-words";
// import NodeAvatar from "../common/objects/common.nodeAvatar";
// import moment from "moment";
// import Axios from "axios";

import MyNetwork, { myVisNetworkStyle } from "@/libs/myVis/MyNetwork3";
// import Node from "@/libs/view/Node";
import intl from 'react-intl-universal';

// 接口
const http = getHttpUtil(false, "");
/**
 * 搜索新闻事件图
 * 同步版本
 * @param text
 * @param limit
 * @returns {*}
 * @constructor
 */
const API_GetNewsEventSync = async ({ text, nodeId, viewId, limit }) => {
  // https://snap.joinmap.ai/wth_server_api/tu_pu_pic_by_node_id?node_id=35045e88-8854-477a-a04d-4ec40d0d564c&wd=人工智能&view_id=acefefe8-f8a6-4d05-9fb3-34db432c9b28
  let url = `https://snap.joinmap.ai/wth_server_api/tu_pu_pic_by_node_id?view_id=${viewId}`;
  if (nodeId) {
    url += `&node_id=${nodeId}`;
  }
  if (text) {
    url += `&wd=${text}`;
  }
  if (limit > 0) {
    url += `&limit=${limit}`;
  }
  return await http.get(url);
};

/**
 * 搜索新闻事件
 * SearchView 页面框架下的子路由
 */
class SearchNewsEventComponent extends React.Component {
  state = {
    // 刷新页面
    refresh: false,
    // 用户输入的词汇
    inputText: "",
    // 用于搜索事件的文本
    textForSearch: "",
    // 搜索事件状态
    isSearching: false,
    // 搜索事件后台报错
    nodesSchIsError: false,
    // 查询事件数量
    schLimit: 120,
    // 搜索到的事件数
    eventTotal: 0,

    // 用于搜索推荐词汇的文本
    textForTagsSearch: "",
    // 搜索tag状态
    isTagSearching: false,
    // 选中的推荐词汇
    selectedTags: [],

    // 选择事件Modal显示控制
    selectModalVisible: false,
  };

  /**
   * 拓展的推荐词汇
   * @type {string[]}
   */
  tagsFromServer = [];

  /**
   * vis 关系图 MyNetwork3 实例
   */
  myNetwork = undefined;

  /**
   * 点击选中的节点
   */
  clickedNode = undefined;

  /**
   * 弹框中选择的事件节点
   */
  selectedEventNodes = [];

  /**
   * 推荐词汇tag的点击
   */
  handleTagClick = (tag) => {
    // 多选tag
    // const {selectedTags} = this.state;
    // const nextSelectedTags = selectedTags.indexOf(tag) === -1 ? [...selectedTags, tag] : selectedTags.filter(t => t !== tag);
    // 单选tag
    const nextSelectedTags = [tag];
    this.setState({ selectedTags: nextSelectedTags, searchText: tag });
    // 点击标签直接搜索事件
    this.schEvents(tag);
  };

  /**
   * 拓展推荐词汇 tag
   * @param txt 待拓展的文本
   * @param limit
   * @param start 暂时没有分页
   */
  schTags = (txt, limit = 20, start = 0) => {
    const { isDebug } = this.props.query;
    let me = this;
    this.tagsFromServer = [];
    this.setState(
      {
        isTagSearching: true,
        textForTagsSearch: txt,
      },
      () => {
        // 调用微服务接口，微服务ID:a63f93ef-fb6c-40ce-a007-80eb8cf8113a
        // 微服务需要指定一个看板ID,
        // 这里用一个空看板的ID(f60cf0b6-d2cf-494d-9fa5-6da4d97c4984)代替,
        // 该看板不能删除
        API_CallMicroService(
          "a63f93ef-fb6c-40ce-a007-80eb8cf8113a",
          "f60cf0b6-d2cf-494d-9fa5-6da4d97c4984",
          {
            limit: limit,
            start: 0,
            target: {
              textOrUrls: [txt],
            },
            parameters: {},
          }
        )
          .then((response) => {
            let res = response.data;
            // console.log('schTags API_CallMicroService res: ', res);
            if (res.hasOwnProperty("code") && res.code === 0) {
              me.tagsFromServer = [];
              res.data[0].nodes.forEach((i, idx) => {
                if (idx < limit) {
                  me.tagsFromServer.push(i.fname);
                }
              });
            } else {
              // 接口报错，打印日志，清空 tags 列表
              if (isDebug)
                console.log("schTags API_CallMicroService failed res: ", res);
              me.tagsFromServer = [];
            }
            me.setState({
              isTagSearching: false,
            });
          })
          .catch((e) => {
            if (isDebug)
              console.log("schTags API_CallMicroService error e: ", e);
            me.tagsFromServer = [];
            me.setState({
              isTagSearching: false,
            });
          });
      }
    );
  };

  /**
   * 搜索输入框开始搜索事件
   * @param txt
   */
  handleInputOnSearch = (txt) => {
    // console.log('SearchNodesComponent handleInputOnChange value: ', value);
    this.setState({ inputText: txt });
    // 输入框输入的文本：拓展词汇 + 事件搜索
    this.doSearch(txt, this.state.schLimit);
  };

  /**
   * 输入框输入change事件
   * @param e 触发对象
   */
  handleInputOnChange = (e) => {
    // console.log('SearchNodesComponent handleInputOnChange e: ', e);
    this.setState({ inputText: e.target.value });
  };

  /**
   * 有效的搜索结果
   */
  schResult = [];

  /**
   * 搜事件
   * @param txt 待搜索的文本
   * @param limit
   * @param start 暂时没有分页
   */
  schEvents = (txt, limit = this.state.schLimit) => {
    const { viewId, nodeId: _nodeId, target, isDebug } = this.props.query;
    let me = this;
    this.schResult = [];
    this.selectedEventNodes = [];
    // 清空vis
    if (this.myNetwork) {
      this.myNetwork.graph.nodes.clear();
      this.myNetwork.graph.edges.clear();
    }
    this.setState(
      {
        eventTotal: 0,
        isSearching: true,
        textForSearch: txt,
      },
      async () => {
        // return false;
        // 判断是否搜索 nodeId 还是 wd
        const nodeId =
          [1, "1", "true"].indexOf(target) > -1 ? undefined : _nodeId;
        if (isDebug)
          console.log(
            "🚀 ~ file: search_newsEvent.js ~ line 252 ~ SearchNewsEventComponent ~ nodeId",
            nodeId
          );
        try {
          let res = await API_GetNewsEventSync({
            text: txt,
            nodeId,
            viewId,
            limit,
          });
          if (isDebug) console.log("API_GetNewsEventSync response=", res);
          res = res.data;
          if (res.hasOwnProperty("code") && res.code === 0) {
            let ns = [];
            res.data.nodes.forEach((n, idx) => {
              n.id = idx + 1; // id 不能为0，visjs可能的bug
              n.fixed = true;
              // n.forceAdd = true;
              // n.userPreferredType = 2;
              // if (Array.isArray(n.tags)) {
              //   n.tag = n.tags.join(" ");
              // }
              ns.push(n);

              if (n.userPreferredType === 8) {
                me.schResult.push(n);
              }
            });
            let es = [];
            res.data.edges.forEach((e, idx) => {
              e.from = e.fromIndex + 1;
              e.to = e.toIndex + 1;
              es.push(e);
            });
            me.myNetwork.addGraph({ nodes: ns, edges: res.data.edges });
            me.myNetwork.network.fit({ animation: true });
            if (isDebug)
              console.log(
                "schEvents vis nodes: ",
                me.myNetwork.graph.nodes.get()
              );

            // 强制刷新页面
            me.setState({
              eventTotal: me.schResult.length,
              isSearching: false,
              refresh: !me.state.refresh,
            });
          } else {
            // 内部出错
            message.warning("没有搜索到事件", 5);
            me.schResult = [];
            me.setState({
              eventTotal: 0,
              isSearching: false,
              nodesSchIsError: true,
            });
          }
        } catch (e) {
          if (isDebug) console.log("schEvents API_GetNewsEventSync error: ", e);
          message.warning("没有搜索到事件", 5);
          me.schResult = [];
          me.setState({
            eventTotal: 0,
            isSearching: false,
            nodesSchIsError: true,
          });
        } finally {
        }
      }
    );
  };

  /**
   * 搜索
   */
  doSearch = (txt, limit) => {
    if (!txt) {
      message.warning("请输入词汇后再搜索");
      return false;
    }
    // 搜事件
    this.schEvents(txt, limit);
    // 拓展词
    this.schTags(txt);
  };

  /**
   * 添加事件节点到原看板
   * @returns
   */
  doAddToView = () => {
    const { viewId, nodeId, wd, isDev, isDebug } = this.props.query;
    let me = this;
    if (this.selectedEventNodes.length == 0) {
      return false;
    }
    const eventsJsx = [];
    this.selectedEventNodes.forEach((n) => {
      // 生成列表项
      eventsJsx.push(<li key={"x-" + n.id}>{n.fname}</li>);
    });
    Modal.confirm({
      title: "复制事件",
      content: (
        <div>
          确定复制以下事件到原图谱?
          <br />
          <span
            className={schViewStyles["key-wd"]}
            style={{ fontSize: "1.1rem" }}
          >
            <ul>{eventsJsx.map((i) => i)}</ul>
          </span>
        </div>
      ),
      width: "528px",
      okText: "确认",
      cancelText: "取消",
      onOk() {
        return new Promise((resolve, reject) => {
          // 关闭 modal
          me.setState({
            selectModalVisible: false,
          });
          // 查找连接节点
          let _newNodes = [];
          let _newEdges = [];
          me.selectedEventNodes.forEach((n) => {
            // n.fixed = false;
            // n.id = null;
            _newNodes.push({
              description: n["description"],
              fname: n["fname"],
              forceAdd: n["forceAdd"],
              id: null,
              lev: n["lev"],
              meta: n["meta"],
              tag: n["tag"],
              tags: n["tags"],
              type: n["type"],
              url: n["url"],
              userConfirmed: true,
              userPreferredType: n["userPreferredType"],
              version: "E07",
              vrDisplayText: "gh",
            });
            // 查找连接节点
            const linkedNodes = me.myNetwork.graph.nodes.get(
              me.myNetwork.network.getConnectedNodes(n.id)
            );
            linkedNodes.forEach((n2) => {
              _newNodes.push({
                description: n2["description"],
                fname: n2["fname"],
                forceAdd: n2["forceAdd"],
                id: null,
                lev: n2["lev"],
                meta: n2["meta"],
                tag: n2["tag"],
                type: n2["type"],
                url: n2["url"],
                userConfirmed: true,
                userPreferredType: n2["userPreferredType"],
                version: "E07",
                vrDisplayText: "gh",
              });
            });
            // _newNodes = _newNodes.concat(linkedNodes);
          });
          let tempEventIdx = undefined;
          _newNodes.forEach((n, idx) => {
            if (n.userPreferredType === 8) {
              tempEventIdx = idx;
              _newEdges.push({
                fromIndex: null,
                toIndex: idx,
                from: nodeId,
                to: null,
                meta: null,
                userConfirmed: true,
                version: "E07",
              });
            } else {
              _newEdges.push({
                fromIndex: tempEventIdx,
                toIndex: idx,
                from: null,
                to: null,
                meta: null,
                userConfirmed: true,
                version: "E07",
              });
            }
          });

          if (isDebug) {
            console.log("doAddToView  _newNodes: ", _newNodes);
            console.log("doAddToView  _newEdges: ", _newEdges);
          }
          // -------------------------------------------------------------
          // 拼接 nodes edges
          // const nodes = me.selectedEventNodes.concat(me.linkedNodes);
          // const edges = [
          //   {
          //     fromIndex: 0,
          //     toIndex: null,
          //     from: null,
          //     to: nodeId,
          //     meta: null,
          //     userConfirmed: true,
          //     version: "E07",
          //   },
          // ];
          // for (let i = 1; i < nodes.length; i++) {
          //   edges.push({
          //     fromIndex: 0,
          //     toIndex: i,
          //     from: null,
          //     to: null,
          //     meta: null,
          //     userConfirmed: true,
          //     version: "E07",
          //   });
          // }
          // const newNodes = [];
          // nodes.forEach((n) => {
          //   n.fixed = false;
          //   newNodes.push(n);
          // });

          // if (isDebug) {
          //   console.log("doAddToView  newNodes: ", newNodes);
          //   console.log("doAddToView  edges: ", edges);
          // }

          // 添加到看板
          const hideLoading = message.loading("复制到原图谱中...", 0);
          API_AddRelationGraph(viewId, _newNodes, _newEdges)
            .then((resp) => {
              const {
                data: { code, msg, data },
              } = resp;

              hideLoading();
              if (code == 0) {
                Modal.confirm({
                  icon: (
                    <Icon
                      type="check-circle"
                      theme="twoTone"
                      twoToneColor="#52c41a"
                    />
                  ),
                  title: "复制成功",
                  content: (
                    <div>请回到原图谱刷新查看结果！或重新打开图谱。</div>
                  ),
                  width: "500px",
                  okText: "打开图谱",
                  cancelText: "取消",
                  onOk() {
                    return new Promise((resolve, reject) => {
                      window.open(`/mainview/relation/${viewId}`, "_blank");
                      resolve();
                    }).catch(() => console.log("打开图谱出错"));
                  },
                });
              } else {
                message.error("复制失败,请重试！");
              }
              me.selectedEventNodes = [];
            })
            .catch((e) => {
              if (isDebug) console.log("添加到看板出错,e:", e);
              hideLoading();
              message.error("复制到看板出错,请重新选择后再试！");
              me.selectedEventNodes = [];
            });
          // setTimeout(Math.random() > 0.5 ? resolve : reject, 800);

          resolve();
        }).catch((e) => console.log("Oops errors!", e));
      },
      onCancel() {},
    });
    return false;
  };

  /**
   * 弹框选择需要复制的事件
   */
  selectEventsModal = (value = false) => {
    this.setState({
      selectModalVisible: true,
    });
  };

  /**
   * 弹框选择需要复制的事件
   */
  schInNewTab = () => {
    const { viewId, nodeId, limit, isDev, isDebug } = this.props.query;
    if (!this.clickedNode) {
      return false;
    }
    const wd = this.clickedNode.fname;
    let url = `/search/news-event?view_id=${viewId}&node_id=${nodeId}&wd=${wd}`;
    // 指定搜素 wd
    url += `&target=1`;
    if (limit) {
      url += `&limit=${limit}`;
    }
    if (isDev) {
      url += `&is_dev=${isDev}`;
    }
    if (isDebug) {
      url += `&is_debug=${isDebug}`;
    }
    window.open(url, "_blank");
  };

  componentDidMount() {
    document.title = "搜索新闻事件 - 炬图";
    // query 参数
    const { wd, limit } = this.props.query;
    // 画图
    let me = this,
      networkOptions = myVisNetworkStyle.options();
    networkOptions.physics.enabled = false; // 强制设置一次，防止出错
    me.container = document.getElementById("network");
    networkOptions.physics.enabled = false;
    me.myNetwork = new MyNetwork(
      me.container,
      { nodes: [], edges: [] },
      networkOptions
    );
    // 点击事件
    me.myNetwork.network.on("click", (params) => {
      const { nodes } = params;
      me.clickedNode = undefined;
      if (nodes.length === 1) {
        // 点击了1个点
        // 找到全部连接节点
        me.clickedNode = me.myNetwork.graph.nodes.get(nodes[0]);
        // 判断是不是新闻节点
        // if (me.clickedNode.userPreferredType === 8) {
        // igrone
        // }
      }

      // 刷新页面
      me.setState({
        refresh: !me.state.refresh,
      });
    });

    // 刷新初始数据
    if (wd) {
      me.setState({
        inputText: wd,
        textForSearch: wd,
        schLimit: limit,
      });
      // 搜事件
      this.schEvents(wd, limit);
      // 拓展词
      this.schTags(wd);
    }
  }

  render() {
    const {
      inputText,
      selectedTags,
      textForTagsSearch,
      textForSearch,
      isSearching,
      nodesSchIsError,
      isTagSearching,
      eventTotal,
      selectModalVisible,
    } = this.state;
    const { doAddToView, selectEventsModal, renderEventCheckbox, schInNewTab } =
      this;
    let me = this;
    // <span className={schViewStyles['key-wd']}>"{textForTagsSearch}"</span> 的
    return (
      <>
        <div id="network" className={styles["result-network"]} />

        {!textForSearch ? (
          <div className={schViewStyles["default-sch"]}>
            <div style={{ marginBottom: "2rem" }}>
              <img
                src={intl.get('locale')==='zh-cn'?'https://vip.joinmap.ai/assets/logo-hans.png':'https://vip.joinmap.ai/assets/logo-hans_en.png'}
                alt="炬图"
              />
            </div>
            <Input.Search
              className={schViewStyles["sch-input"]}
              placeholder="输入关键词"
              enterButton="搜事件"
              size="large"
              onSearch={this.handleInputOnSearch}
            />
            <div style={{ flex: "0 1 300px" }}>&nbsp;</div>
          </div>
        ) : (
          <div className={schViewStyles["result-outer"]}>
            {/* 搜索框 */}
            <Row gutter={[16, 16]}>
              <Col span={12} offset={6}>
                <Input.Search
                  className={schViewStyles["sch-input"]}
                  placeholder="请输入关键词"
                  enterButton="搜事件"
                  size="large"
                  defaultValue={inputText ? inputText : ""}
                  onSearch={this.handleInputOnSearch}
                  onChange={this.handleInputOnChange}
                />
              </Col>
            </Row>

            {/*标签 */}
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <div className={schViewStyles["result-title"]}>
                  {textForTagsSearch ? (
                    <span style={{ marginLeft: ".5em" }}>推荐词汇：</span>
                  ) : (
                    <span style={{ marginLeft: ".5em" }}>推荐词汇：</span>
                  )}
                </div>

                {this.tagsFromServer.length > 0 ? (
                  this.tagsFromServer.map((tag) => (
                    <span
                      key={tag}
                      className={
                        selectedTags.indexOf(tag) > -1
                          ? schViewStyles["tag"] +
                            " " +
                            schViewStyles["selected"]
                          : schViewStyles["tag"]
                      }
                      onClick={() => {
                        this.handleTagClick(tag);
                      }}
                    >
                      {tag}
                    </span>
                  ))
                ) : isTagSearching ? (
                  <Spin
                    className={schViewStyles["spin"]}
                    spinning={true}
                    tip={"正在加载"}
                    size={"large"}
                  />
                ) : (
                  <Empty
                    className={schViewStyles["spin"]}
                    image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                    imageStyle={{
                      height: 120,
                    }}
                    description={isTagSearching ? "" : "暂无推荐词汇"}
                  />
                )}
              </Col>
            </Row>

            <div style={{ flex: "0 1 40px" }}>&nbsp;</div>

            {/*搜索结果*/}
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <div className={schViewStyles["result-title"]}>
                  {textForSearch ? (
                    <span style={{ marginLeft: ".5em" }}>
                      <span className={schViewStyles["key-wd"]}>
                        "{textForSearch}"
                      </span>
                      {isSearching
                        ? "正在搜索事件中..."
                        : `搜索到 ${eventTotal} 条相关事件：`}
                    </span>
                  ) : (
                    <span style={{ marginLeft: ".5em" }}>搜索结果：</span>
                  )}
                  <div style={{ flex: 1 }}></div>

                  {/* 选中节点搜索按钮 */}
                  <Button
                    type="primary"
                    onClick={schInNewTab}
                    disabled={this.clickedNode ? "" : "disabled"}
                  >
                    继续搜索
                  </Button>
                  <span style={{ width: "8px" }}></span>
                  {/* 右侧复制按钮 */}
                  <Button
                    type="primary"
                    onClick={selectEventsModal}
                    disabled={eventTotal > 0 ? "" : "disabled"}
                  >
                    复制事件
                  </Button>
                </div>
              </Col>
            </Row>

            <div className={styles["result-list"]}>
              {eventTotal > 0 ? (
                ""
              ) : isSearching ? (
                <Spin
                  className={
                    schViewStyles["spin"] + " " + schViewStyles["spin-high"]
                  }
                  spinning={true}
                  tip={"正在搜索"}
                  size={"large"}
                />
              ) : (
                <Empty
                  className={schViewStyles["spin"]}
                  image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                  imageStyle={{
                    height: 120,
                  }}
                  description={isSearching ? "" : "暂无相关事件"}
                />
              )}
            </div>
          </div>
        )}

        <Modal
          title="选择事件"
          width="560px"
          visible={selectModalVisible}
          destroyOnClose={true}
          onOk={doAddToView}
          onCancel={() => {
            me.selectedEventNodes = [];
            me.setState({
              selectModalVisible: false,
            });
          }}
          okText="复制到图谱"
          cancelText="取消"
        >
          <Checkbox.Group
            style={{ width: "100%" }}
            onChange={(checkedValues) => {
              me.selectedEventNodes =
                me.myNetwork.graph.nodes.get(checkedValues);
              me.setState({
                refresh: !me.state.refresh,
              });
            }}
          >
            <Row>{renderEventCheckbox().map((d) => d)}</Row>
          </Checkbox.Group>
        </Modal>
      </>
    );
  }

  // 渲染 事件 checkbox
  renderEventCheckbox = () => {
    const jsx = [];
    this.schResult.forEach((n) => {
      jsx.push(
        <Col key={"ckbox-" + n.id} span={24} style={{ padding: "3px 0" }}>
          <Checkbox value={n.id}>{n.fname}</Checkbox>
        </Col>
      );
    });
    return jsx;
  };
}

export default SearchNewsEventComponent;
