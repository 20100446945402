import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {message} from 'antd';
import copy from 'copy-to-clipboard';

import PB, {SimplePB} from '@/libs/simplePB';
import Node from '@/libs/view/Node';
import {NodeEvents} from '@/libs/view/network/events';

import {NODE_TYPE_TEXT} from '@/constants/vis.defaultDefine.1';

import {showErrorMessage} from '@/components/common/common.message';

import ViewDataProvider from '@/components/common/dataProvider/common.dataProvider.view';
import {
  DEFAULT_COPY_TEXT_KEY,
  MicroServiceUIConfig
} from '@/components/common/view/microService/shape/common.view.microService.shape.uiConfig';

/**
 * @description 保存内容微服务数据项
 * @typedef {Object} TMicroServiceDataOpSaveContent
 * @property {Object} resultTargetMap - 目标对象ID到数据对应关系
 * @property {Object} resultTargetToTreeNode - 目标对象ID到树节点对应关系
 * @property {Array} dataTree - 树形列表节点数据
 * @property {Object} dataTreeMap - 树形列表节点ID到数据对应关系
 * @property {Object.<string, string[]>} treeNodeUidToIdMap - 实际数据节点ID到树节点ID的对应关系
 * @property {boolean} currentHasData - 当前处理的返回值是否有新结果
 * @property {number} availableNodeAmount - 当前可勾选的对象数量
 * @property {'idle'|'processing'} savingStatus - 当前保存流程状态
 * @property {string[]} checkedKeys - 当前勾选的对象ID
 * @property {string[]} expandedKeys - 当前展开的对象ID
 * @property {string[]} objectIds - 对象ID列表
 * @property {Object.<string, TMicroServiceResponseObjectStatus>} objectStatus - 对象状态
 * @property {TMicroServiceUIConfigOperationSaveContent} operationConfig - 操作详细配置
 */

/**
 * @description 初始化服务数据
 * @param {TMicroServiceUIConfigOperationSaveContent} operationConfig
 * @return {TMicroServiceDataOpSaveContent}
 */
const getInitialServiceData = operationConfig => ({
  resultTargetMap: {},
  resultTargetToTreeNode: {},
  dataTree: [],
  dataTreeMap: {},
  treeNodeUidToIdMap: {},
  currentHasData: false,

  availableNodeAmount: 0,
  checkedKeys: [],
  expandedKeys: [],

  savingStatus: 'idle',

  objectIds: [],
  objectStatus: {},
  operationConfig,
});

const OPERATION = 'saveContent';

const availableReplacementTypes = ['fname', 'description', 'url', 'tag', 'icon', 'position'];

class MicroServiceLogicOperationSaveContent extends React.PureComponent {
  /**
   * @type {Object.<string, TMicroServiceDataOpSaveContent>}
   */
  serviceDataMap = {};

  /**
   * 判断树节点是否可被选择
   *
   * @param {Object} treeNode - 树节点
   * @param {string} serviceId - 微服务ID
   * @return {boolean}
   */
  isCheckableTreeNode = (treeNode, serviceId) => {
    let me = this, serviceData = me.serviceDataMap[serviceId];

    return serviceData && serviceData.objectStatus[treeNode.uid]
      && ['idle', 'selected'].includes(serviceData.objectStatus[treeNode.uid].status);
  };

  /**
   * 配置文件加载完毕后，检查是否需要初始化数据
   *
   * @param {string} projectId - 项目ID
   * @param {TMicroServiceUIConfig[]} configList - 微服务配置列表
   */
  onConfigListLoaded = (projectId, configList) => {
    let me = this;

    (configList || []).forEach(config => {
      config.operations.find(op => {
        if (op.type === OPERATION) {
          me.serviceDataMap[config.id] = getInitialServiceData(op);
          return true;
        }
        return false;
      });
    });
  };

  /**
   * 复制选中节点的文本内容至剪切板
   *
   * @param {string} serviceId - 微服务ID
   */
  onCopySelectedNodesToClipboard = (serviceId) => {
    let me = this, textList = [], serviceData = me.serviceDataMap[serviceId];

    if (serviceData) {
      // noinspection JSValidateTypes
      _.intersection(Object.keys(serviceData.dataTreeMap), serviceData.checkedKeys).forEach(k => {
        if (serviceData.dataTreeMap[k] && serviceData.dataTreeMap[k].copyText) {
          textList.push(serviceData.dataTreeMap[k].copyText);
        }
      });

      let result = copy(textList.join("\r\n"), {
        message: '请按下 #{key} 复制选中节点文本。',
      });

      if (result) message.success('选中节点文本已复制到剪切板。');
    }
  };

  /**
   * 原始结果加载完毕后，解析并存储数据
   *
   * @param {TMicroServiceResponseDataItem[]} data - 原始数据列表
   * @param {string} serviceId - 微服务ID
   */
  onRawDataLoaded = (data, serviceId) => {
    let me = this, newKeys = me.parseResult(serviceId, data), serviceData;

    serviceData = me.serviceDataMap[serviceId];
    serviceData.treeNodeUidToIdMap = {};
    Object.values(serviceData.dataTreeMap).forEach(node => {
      if (!serviceData.treeNodeUidToIdMap[node.uid]) {
        serviceData.treeNodeUidToIdMap[node.uid] = [];
      }
      serviceData.treeNodeUidToIdMap[node.uid].push(node.id);
    });

    me.props.bus.emit('view', 'micro_service.service.broadcast_operation_result',
      {viewId: me.props.viewDataProvider.viewId, serviceId, operation: OPERATION});
    if (newKeys && newKeys.length > 0) {
      me.serviceDataMap[serviceId].availableNodeAmount = Object.values(me.serviceDataMap[serviceId].dataTreeMap)
        .filter(treeNode => me.isCheckableTreeNode(treeNode, serviceId)).length;

      serviceData.expandedKeys = [...serviceData.expandedKeys, ...newKeys];

      me.props.bus.emit('view', 'micro_service.service.broadcast_operation_statistics',
        {viewId: me.props.viewDataProvider.viewId, serviceId, operation: OPERATION});
      me.props.bus.emit('view', 'micro_service.service.broadcast_operation_ui',
        {viewId: me.props.viewDataProvider.viewId, serviceId, operation: OPERATION});
    }
  };

  /**
   * 微服务主控数据清理后，清理业务数据
   *
   * @param {string} serviceId - 微服务ID
   */
  onResultCleared = serviceId => {
    let me = this, serviceData = me.serviceDataMap[serviceId];

    if (me.props.viewDataProvider) {
      serviceData.resultTargetMap = {};
      serviceData.resultTargetToTreeNode = {};
      serviceData.dataTree = [];
      serviceData.dataTreeMap = {};
      serviceData.treeNodeUidToIdMap = {};
      serviceData.currentHasData = false;

      serviceData.availableNodeAmount = 0;
      serviceData.checkedKeys =  [];
      serviceData.expandedKeys = [];

      serviceData.objectIds = [];
      serviceData.objectStatus = {};
    }
  };

  /**
   * 保存数据回调
   *
   * @param {string} serviceId - 微服务ID
   */
  onSaveContentToView = serviceId => {
    let me = this, serviceData = me.serviceDataMap[serviceId], tmpObjectStatus = {}, nodes = [], edges = [], nodeIdxMap = {};

    if (serviceData) {
      serviceData.checkedKeys.forEach(key => {
        let treeNode = serviceData.dataTreeMap[key];
        if (!treeNode || treeNode.renderAs !== 'content'
          || ['saved', 'invalid'].includes(serviceData.objectStatus[treeNode.uid].status)) {

          return;
        }

        let replacementInfo = serviceData.resultTargetMap[treeNode.contentId];
        if (!replacementInfo) return;

        let originalNodeId = replacementInfo.targetNodeId, nodeInfo;
        if (nodeIdxMap[originalNodeId] !== undefined) {
          nodeInfo = nodes[nodeIdxMap[originalNodeId]];
        } else {
          let originalNode = me.props.viewDataProvider.getNode(originalNodeId);
          if (!originalNode) return;
          nodeInfo = {...originalNode};
        }

        let rData = replacementInfo.replacementDataRef;
        switch (replacementInfo.type) {
          case 'icon':
            // type && userPreferredType && aiPreferredType
            if (rData.type !== undefined && rData.type !== NODE_TYPE_TEXT) {
              nodeInfo.userPreferredType = rData.type;
              if (nodeInfo.meta && nodeInfo.meta.userPreferredType !== undefined) {
                nodeInfo.meta = {...nodeInfo.meta, userPreferredType: rData.type};
              }
            } else if (rData.userPreferredType !== undefined) {
              nodeInfo.userPreferredType = rData.userPreferredType;
              if (nodeInfo.meta && nodeInfo.meta.userPreferredType !== undefined) {
                nodeInfo.meta = {...nodeInfo.meta, userPreferredType: rData.userPreferredType};
              }
            } else if (rData.aiPreferredType !== undefined) {
              nodeInfo.userPreferredType = rData.aiPreferredType;
              if (nodeInfo.meta && nodeInfo.meta.userPreferredType !== undefined) {
                nodeInfo.meta = {...nodeInfo.meta, userPreferredType: rData.aiPreferredType};
              }
            }
            // meta.iconMeta && meta.iconData
            if (rData.meta) {
              nodeInfo.meta = {...(nodeInfo.meta || {}), iconMeta: rData.meta.iconMeta, iconData: rData.meta.iconData};
            }
            break;
          case 'fname':
          case 'url':
            nodeInfo[replacementInfo.type] = rData[replacementInfo.type];
            break;
          case 'tag':
            nodeInfo.tag = rData.tag;
            nodeInfo.tags = nodeInfo.tag === '' ? [] : nodeInfo.tag.split(' ');
            break;
          case 'description':
            nodeInfo.description = rData.description;
            if (nodeInfo.meta && nodeInfo.meta.description !== undefined) {
              nodeInfo.meta = {...nodeInfo.meta, description: rData.description};
            }
            break;
          case 'position':
            // fixed, fx, fy
            ['fixed', 'fx', 'fy'].forEach(k => {
              if (rData[k] !== undefined) {
                nodeInfo[k] = rData[k];
              }
            });
            break;
          default:
            return;
        }

        tmpObjectStatus[treeNode.uid] = 'saved';

        if (nodeIdxMap[originalNodeId] === undefined) {
          nodeIdxMap[originalNodeId] = nodes.length;
          nodes.push(nodeInfo);
        }
      });
      if (nodes.length === 0) {
        message.success(`所选节点修改均已保存`);
        return;
      }

      serviceData.savingStatus = 'processing';
      me.props.bus.emit('view', 'micro_service.service.broadcast_operation_ui',
        {viewId: me.props.viewDataProvider.viewId, serviceId, operation: OPERATION});

      me.props.viewDataProvider.saveRelationGraph(
        nodes, edges, false, false, 'micro_service_by_view', false
      ).then(() => {
        serviceData.checkedKeys = [];
        serviceData.savingStatus = 'idle';
        Object.keys(tmpObjectStatus).forEach(uid => {
          if (serviceData.objectStatus[uid]) {
            serviceData.objectStatus[uid].status = tmpObjectStatus[uid];
            serviceData.treeNodeUidToIdMap[uid].forEach(id => {
              let n = serviceData.dataTreeMap[id], nPath = n.path, pN;
              if (nPath.length === 2) {
                pN = serviceData.dataTreeMap[nPath[0]];

                if (pN && serviceData.objectStatus[pN.uid]
                  && ['idle', 'selected'].includes(serviceData.objectStatus[pN.uid].status)
                  && pN.children && !pN.children.find(cN => serviceData.objectStatus[cN.uid]
                      && ['idle', 'selected'].includes(serviceData.objectStatus[cN.uid].status))
                ) {
                  serviceData.objectStatus[pN.uid].status = 'saved';
                }
              }
            });
          }
        });
        serviceData.availableNodeAmount = Object.values(serviceData.dataTreeMap)
          .filter(treeNode => me.isCheckableTreeNode(treeNode, serviceId)).length;
        me.props.bus.emit('view', 'micro_service.service.broadcast_operation_ui',
          {viewId: me.props.viewDataProvider.viewId, serviceId, operation: OPERATION});
        me.props.bus.emit('view', 'micro_service.service.broadcast_operation_statistics',
          {viewId: me.props.viewDataProvider.viewId, serviceId, operation: OPERATION});
        message.success(`成功保存 ${nodes.length} 个节点`);
      }).catch(({code, msg}) => {
        showErrorMessage({code, msg, extra: {isModification: true}});

        serviceData.savingStatus = 'idle';
        me.props.bus.emit('view', 'micro_service.service.broadcast_operation_ui',
          {viewId: me.props.viewDataProvider.viewId, serviceId, operation: OPERATION});
      });
    }
  };

  /**
   * 树节点勾选回调
   *
   * @param {string} serviceId - 微服务ID
   * @param {string[]} checkedKeys - 勾选的节点ID列表
   */
  onTreeNodeItemCheck = (serviceId, checkedKeys) => {
    let me = this, serviceData = me.serviceDataMap[serviceId], tmpObjectStatus = {}, updatedObjectStatus = {},
      checkedKeyMap = {}, objectStatus = serviceData.objectStatus, hasUpdatedObjectStatus = false;

    if (serviceData) {
      checkedKeys.forEach(k => {
        let treeNode = serviceData.dataTreeMap[k];

        if (!treeNode) return;

        checkedKeyMap[k] = true;
        if (serviceData.treeNodeUidToIdMap[treeNode.uid].length > 1) {
          serviceData.treeNodeUidToIdMap[treeNode.uid].forEach(id => checkedKeyMap[id] = true);
        }

        [...treeNode.path].reverse().find(k => {
          let pN = serviceData.dataTreeMap[k];
          if (!updatedObjectStatus[pN.uid]) {
            if (objectStatus[pN.uid]) {
              if (objectStatus[pN.uid].status === 'idle') {
                objectStatus[pN.uid].status = "selected";
                updatedObjectStatus[pN.uid] = {
                  type: objectStatus[pN.uid].type,
                  status: 'selected',
                };
                tmpObjectStatus[pN.uid] = 'selected';
                hasUpdatedObjectStatus = true;
              } else if (objectStatus[pN.uid].status === 'selected') {
                tmpObjectStatus[pN.uid] = 'selected';
              }
            }
          }
          return pN && pN.action;
        });
      });

      serviceData.objectIds.forEach(id => {
        if (!tmpObjectStatus[id] && objectStatus[id].status === 'selected') {
          objectStatus[id].status = 'idle';
          updatedObjectStatus[id] = {
            type: objectStatus[id].type,
            status: 'idle',
          };
          hasUpdatedObjectStatus = true;
        }
      });

      me.serviceDataMap[serviceId].objectStatus = objectStatus;
      me.serviceDataMap[serviceId].checkedKeys = Object.keys(checkedKeyMap);

      if (hasUpdatedObjectStatus) {
        me.props.bus.emit('view', 'micro_service.service.operation_statistics_updated',
          {viewId: me.props.viewDataProvider.viewId, serviceId, operation: OPERATION, updatedObjectStatus});
      }

      me.props.bus.emit('view', 'micro_service.service.broadcast_operation_ui',
        {viewId: me.props.viewDataProvider.viewId, serviceId, operation: OPERATION});
    }
  };

  /**
   * 树节点展开回调
   *
   * @param {string} serviceId - 微服务ID
   * @param {string[]} expandedKeys - 展开的节点ID列表
   */
  onTreeNodeItemExpand = (serviceId, expandedKeys = []) => {
    let me = this, serviceData = me.serviceDataMap[serviceId];

    if (serviceData) {
      if (serviceData.expandedKeys.length !== expandedKeys.length
        || _.difference(serviceData.expandedKeys, expandedKeys).length > 0) {

        me.serviceDataMap[serviceId].expandedKeys = expandedKeys;
        me.props.bus.emit('view', 'micro_service.service.broadcast_operation_ui',
          {viewId: me.props.viewDataProvider.viewId, serviceId, operation: OPERATION});
      }
    }
  };

  /**
   * 解析微服务返回的数据
   *
   * @param {string} serviceId - 微服务ID
   * @param {TMicroServiceResponseDataItem[]} dataList - 服务器返回的数据列表
   *
   * @return {string[]} 新增树节点的ID列表
   */
  parseResult = (serviceId, dataList) => {
    let me = this,
      serviceData = me.serviceDataMap[serviceId],
      currentHasData = false,
      config = serviceData.operationConfig,
      resultTargetMap = serviceData.resultTargetMap,
      resultTargetToTreeNode = serviceData.resultTargetToTreeNode,
      dataTree = serviceData.dataTree,
      dataTreeMap = serviceData.dataTreeMap,
      objectIds = serviceData.objectIds,
      objectStatus = serviceData.objectStatus,
      oldKeys = Object.keys(serviceData.dataTreeMap),
      dataMsg;

    let copyPath = config.copyTextKey === false ? false : DEFAULT_COPY_TEXT_KEY;

    dataList.forEach(dataItem => {
      if ((dataItem.calcStatus && dataItem.calcStatus == 1)) {
        dataMsg = {
          'msgStatus' : dataItem.calcStatus,
          'msg' : dataItem.calcMsg
        }
      }
      if ((dataItem.msgStatus && dataItem.msgStatus == 1)) {
        dataMsg = {
          'msgStatus' : dataItem.msgStatus,
          'msg' : dataItem.msg
        }
      }
      if (dataItem.replacements && dataItem.replacements.length > 0) {
        let itemRootNode = resultTargetMap[dataItem.id], treeNode,
          treeNodeId = `${serviceId}-root-${dataItem.id}`;

        if (!itemRootNode) {
          if (![undefined, null, 'node'].includes(dataItem.type)) {
            // 暂不支持
            return;
          }

          itemRootNode = me.props.viewDataProvider.getNode(dataItem.id);
          if (!itemRootNode) {
            // 看板中不存在对应节点
            return;
          }

          treeNode = {
            id: treeNodeId,
            uid: dataItem.id,
            nodeId: dataItem.id,
            renderAs: 'node',
            path: [treeNodeId],
            action: 'modify',
          };

          resultTargetMap[treeNode.uid] = itemRootNode;
          resultTargetToTreeNode[treeNode.uid] = treeNode;
          treeNode.copyText = (copyPath ? _.get(itemRootNode, copyPath) : '');
          dataTreeMap[treeNodeId] = treeNode;
          dataTree.push(treeNode);
          if (!objectStatus[treeNode.uid]) {
            objectIds.push(treeNode.uid);
            objectStatus[treeNode.uid] = {
              type: treeNode.action || 'none',
              status: 'idle',
            };
          }
        }

        treeNode = dataTreeMap[treeNodeId];

        // 处理数据
        treeNode.children = treeNode.children || [];
        dataItem.replacements.forEach(replacement => {
          let replacementNode = new Node({type: NODE_TYPE_TEXT, ...replacement.data});
          (replacement.replace || []).forEach(type => {
            if (!availableReplacementTypes.includes(type)) return;

            let subTreeNode,
              rndId = Math.random(),
              subTreeNodeId = `${serviceId}-t-${dataItem.id}-${rndId}`,
              contentId = `${serviceId}-c-${dataItem.id}-${rndId}`;

            while (resultTargetMap[contentId]) {
              rndId = Math.random();
              subTreeNodeId = `${serviceId}-t-${dataItem.id}-${rndId}`;
              contentId = `${serviceId}-c-${dataItem.id}-${rndId}`;
            }

            subTreeNode = {
              id: subTreeNodeId,
              uid: contentId,
              contentId,
              renderAs: 'content',
              path: [treeNodeId, subTreeNodeId],
              action: 'none',
              copyText: false,
            };

            resultTargetMap[contentId] = {
              type,
              replacementDataRef: replacement.data,
              replacementNodeRef: replacementNode,
              targetNodeId: dataItem.id,
            };
            resultTargetToTreeNode[subTreeNode.uid] = subTreeNode;

            treeNode.children.push(subTreeNode);
            dataTreeMap[subTreeNodeId] = subTreeNode;
            if (!objectStatus[subTreeNode.uid]) {
              objectIds.push(subTreeNode.uid);
              objectStatus[subTreeNode.uid] = {
                type: subTreeNode.action || 'none',
                status: 'idle',
              };
            }

            currentHasData = true;
          });
        });
      }
    });

    me.serviceDataMap[serviceId].resultTargetMap = resultTargetMap;
    me.serviceDataMap[serviceId].resultTargetToTreeNode = resultTargetToTreeNode;
    me.serviceDataMap[serviceId].dataTree = dataTree;
    me.serviceDataMap[serviceId].dataTreeMap = dataTreeMap;
    me.serviceDataMap[serviceId].currentHasData = currentHasData;
    me.serviceDataMap[serviceId].objectIds = objectIds;
    me.serviceDataMap[serviceId].objectStatus = objectStatus;
    me.serviceDataMap[serviceId].dataMsg = dataMsg;

    return _.difference(Object.keys(dataTreeMap), oldKeys);
  };

  componentDidMount() {
    let me = this;

    me.props.bus.with(
      me
    ).subscribe('view', 'micro_service.config.data_loaded', ({projectId, configList}) => {
      me.onConfigListLoaded(projectId, configList);
    }).subscribe('view', 'micro_service.service.raw_data_loaded', ({data, viewId, serviceId}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId && me.serviceDataMap[serviceId]) {
        me.onRawDataLoaded(data, serviceId);
      }
    }).subscribe('view', 'micro_service.service.result_cleared', ({viewId, serviceId}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId && me.serviceDataMap[serviceId]) {
        me.onResultCleared(serviceId);
      }
    }).subscribe('view', 'micro_service.service.operation_save_content.copy_selected_node_to_clipboard', ({viewId, serviceId}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId && me.serviceDataMap[serviceId]) {
        me.onCopySelectedNodesToClipboard(serviceId);
      }
    }).subscribe('view', 'micro_service.service.operation_save_content.tree_node_item_check', ({viewId, serviceId, checkedKeys}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId && me.serviceDataMap[serviceId]) {
        me.onTreeNodeItemCheck(serviceId, checkedKeys);
      }
    }).subscribe('view', 'micro_service.service.operation_save_content.tree_node_item_expand', ({viewId, serviceId, expandedKeys}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId && me.serviceDataMap[serviceId]) {
        me.onTreeNodeItemExpand(serviceId, expandedKeys);
      }
    }).subscribe('view', 'micro_service.service.broadcast_operation_result', ({viewId, serviceId, operation}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId && me.serviceDataMap[serviceId]
        && operation === OPERATION) {

        me.props.bus.emit('view', 'micro_service.service.current_operation_result', {
          viewId,
          serviceId,
          operation: OPERATION,
          resultTargetMap: {...me.serviceDataMap[serviceId].resultTargetMap},
          dataTree: [...me.serviceDataMap[serviceId].dataTree],
          dataTreeMap: {...me.serviceDataMap[serviceId].dataTreeMap},
          treeNodeUidToIdMap: {...me.serviceDataMap[serviceId].treeNodeUidToIdMap},
          currentHasData: me.serviceDataMap[serviceId].currentHasData,
          dataMsg:me.serviceDataMap[serviceId].dataMsg
        });
      }
    }).subscribe('view', 'micro_service.service.broadcast_operation_ui', ({viewId, serviceId, operation}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId && me.serviceDataMap[serviceId]
        && operation === OPERATION) {

        me.props.bus.emit('view', 'micro_service.service.current_operation_ui', {
          viewId,
          serviceId,
          operation: OPERATION,

          availableNodeAmount: me.serviceDataMap[serviceId].availableNodeAmount,
          checkedKeys: [...me.serviceDataMap[serviceId].checkedKeys],
          expandedKeys: [...me.serviceDataMap[serviceId].expandedKeys],

          savingStatus: me.serviceDataMap[serviceId].savingStatus,
        });
      }
    }).subscribe('view', 'micro_service.service.broadcast_operation_statistics', ({viewId, serviceId, operation}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId && me.serviceDataMap[serviceId]
        && operation === OPERATION) {

        me.props.bus.emit('view', 'micro_service.service.current_operation_statistics', {
          viewId,
          serviceId,
          operation: OPERATION,
          objectIds: [...me.serviceDataMap[serviceId].objectIds],
          objectStatus: {...me.serviceDataMap[serviceId].objectStatus},
        });
      }
    }).subscribe('view', 'micro_service.service.action.main_operation', ({viewId, serviceId, operation}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId && me.serviceDataMap[serviceId]
        && operation === OPERATION) {

        me.onSaveContentToView(serviceId);
      }
    });

    me.props.viewDataProvider.with(me).subscribe(NodeEvents.REMOVED, (type, nodeInfoList) => {
      Object.keys(me.serviceDataMap).forEach(serviceId => {
        let changed = false;
        nodeInfoList.forEach(node => {
          let nodeId = node.id;
          if (me.serviceDataMap[serviceId].objectStatus[nodeId]) {
            changed = true;
            me.serviceDataMap[serviceId].objectStatus[nodeId].status = 'invalid';
            if (me.serviceDataMap[serviceId].resultTargetToTreeNode[nodeId]) {
              let children = me.serviceDataMap[serviceId].resultTargetToTreeNode[nodeId].children;
              if (children && children.length > 0) {
                children.forEach(child => {
                  if (me.serviceDataMap[serviceId].objectStatus[child.uid]) {
                    me.serviceDataMap[serviceId].objectStatus[child.uid].status = 'invalid';
                  }
                });
              }
            }
          }
        });
        if (changed) {
          me.props.bus.emit('view', 'micro_service.service.broadcast_operation_statistics',
            {viewId: me.props.viewDataProvider.viewId, serviceId, operation: OPERATION});
        }
      });
    });
  }

  componentWillUnmount() {
    this.props.bus.remove(this);
    this.props.viewDataProvider.unSubscribe(this);
  }

  render() {
    return null;
  }
}

MicroServiceLogicOperationSaveContent.defaultProps = {
  bus: PB,
};

MicroServiceLogicOperationSaveContent.propTypes = {
  viewDataProvider: PropTypes.instanceOf(ViewDataProvider).isRequired,
  microServiceConfigMap: PropTypes.objectOf(PropTypes.shape(MicroServiceUIConfig)).isRequired,
  bus: PropTypes.instanceOf(SimplePB),
};

export default MicroServiceLogicOperationSaveContent;