/*
 * @Author: Carr.s
 * @Date: 2022-05-19 10:11:21
 * @LastEditTime: 2022-05-21 14:56:53
 * @LastEditors: Carr.s
 * @Description: 社会治理洞察
 * @Copyright by joinmap
 */

import React from "react";
import { Checkbox, Empty, message, Spin, Tooltip, Button, Tabs } from "antd";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import Qs from "qs";

import { Copyright } from "@/components/common/common.copyright";
import Loading from "@/components/common/common.loading";

import layoutStyles from "@/style/default/defaultViewLayout.less";
import styles from "@/style/components/insightView/insight.less";

import Icon from "@/components/common/common.icon";
import { IconTypes } from "@/constants/common";
// 组件
import ViewSocialGovAInsight from "@/components/insightView/insight.social.gov.a";
import ViewSocialGovBInsight from "@/components/insightView/insight.social.gov.b";

// const { Content } = Layout;
const { TabPane } = Tabs;

class InsightSocialGovContainer extends React.Component {
  componentDidMount() {
    document.title = "社会治理洞察 - 炬图";
  }

  render() {
    const { query, userInfo, history } = this.props;
    return (
      <div
        style={{
          paddingTop: 60,
          height: "100%",
        }}
      >
        <Tabs
          centered={true}
          tabBarStyle={{
            textAlign: "center",
          }}
          style={{
            height: "100%",
            position: "relative",
          }}
        >
          <TabPane
            tab={
              <span style={{ fontSize: 16 }}>
                <Icon name="fund" /> 社会治理问题导向图
              </span>
            }
            key="1"
            style={{
              position: "relative",
            }}
          >
            <ViewSocialGovAInsight
              history={history}
              userInfo={userInfo}
              query={query}
            />
          </TabPane>
          <TabPane
            tab={
              <span style={{ fontSize: 16 }}>
                <Icon name="dot-chart" />
                社会治理时空推进图
              </span>
            }
            key="2"
            style={{
              position: "relative",
            }}
          >
            <ViewSocialGovBInsight
              history={history}
              userInfo={userInfo}
              query={query}
            />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

export default InsightSocialGovContainer;
