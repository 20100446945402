import React from 'react';
import {List, Avatar, message, Tooltip} from 'antd';
import PropTypes from 'prop-types';

import ChangePwdModal from '@/components/accountCenter/account.changePwdModal';

import Icon from '@/components/common/common.icon';
import frameStyle from '@/style/containers/mainView.accountCenter.less';
import style from '@/style/components/accountCenter/account.basicInfoPanel.less';
import {ChangePwdStatus} from "@/constants/account";
import checkPwdStrength from "@/utils/PwdStrengthChecker";
import {showSuccessMsg} from "@/components/common/common.message";

const getPasswordStrengthText = (score) => {
  if (score < 35) {
    return <b className={'error-text'}>较弱</b>
  } else if (score < 70) {
    return <b className={'warning-text'}>中等</b>
  } else {
    return <b className={'success-text'}>较强</b>
  }
};

class AccountSecurityInfoPanel extends React.Component {
  state = {
    securityMobile: '',
    securityEmail: '',
    showChangePwdModal: false, // 是否显示修改密码弹框
  };

  changePwdFormRef = undefined;

  doChangePwd = () => {
    if (!this.changePwdFormRef) return;
    const form = this.changePwdFormRef.props.form;
    form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
        const {oldPassword, password} = values;
        this.props.doChangePwd(oldPassword, password, checkPwdStrength(password));
      }
    });
  };

  onChangePwdSuccess = () => {
    let me = this;
    this.setState({showChangePwdModal: false}, () => {
      me.props.resetChangePwdStatus();
      showSuccessMsg({msg: '密码修改成功'});
    });
  };

  render() {
    let me = this;
    if (this.props.changePwdStatus === ChangePwdStatus.SUCCESS && this.state.showChangePwdModal) {
      requestAnimationFrame(() => me.onChangePwdSuccess());
    }
    return (
      <div className={`${frameStyle['content-inner']} ${style['frame']} dark-theme`}>
        <h1 className={style['header']}>
          安全设置
        </h1>
        <List className={`${style['content']} dark-theme`}>
          <List.Item actions={[<a onClick={() => me.setState({showChangePwdModal: true})}>修改</a>]}>
            <List.Item.Meta
              avatar={<Avatar size={56} icon="lock" />}
              title={
                <span>
                  账户密码
                </span>
              }
              description={
                <span>当前密码强度：{getPasswordStrengthText(this.props.passwordStrength)}</span>
              }
            />
            <ChangePwdModal
              visible={this.state.showChangePwdModal}
              hasPassword={this.props.hasPassword}
              onCancel={() => {
                me.setState({showChangePwdModal: false})
              }}
              onSave={this.doChangePwd}
              wrappedComponentRef={(formRef) => {
                me.changePwdFormRef = formRef;
              }}
              processing={this.props.changePwdStatus === ChangePwdStatus.PROCESSING}
            />
          </List.Item>
          <List.Item actions={[<a onClick={() => message.warn('该功能正在完善中，敬请期待……')}>立即绑定</a>]}>
            <List.Item.Meta
              avatar={<Avatar size={56} icon="mobile" />}
              title={
                <span>
                  密保手机
                  <Tooltip placement={'right'} title={'该功能正在完善中，敬请期待……'}>
                    <Icon name={'info-circle'} style={{marginLeft: '0.5em'}} className={'info-text'} />
                  </Tooltip>
                </span>
              }
              description={
                <span>
                  <Icon name={'warning'} className={'warning-text'} style={{marginRight: '0.5em'}}/>
                  {
                    this.state.securityMobile ?
                      `已绑定手机：${this.state.securityMobile}` :
                      '当前未绑定手机，绑定后可通过短信找回密码。'
                  }
                </span>
              }
            />
          </List.Item>
          <List.Item actions={[<a onClick={() => message.warn('该功能正在完善中，敬请期待……')}>立即绑定</a>]}>
            <List.Item.Meta
              avatar={<Avatar size={56} icon="mail" />}
              title={
                <span>
                  安全邮箱
                  <Tooltip placement={'right'} title={'该功能正在完善中，敬请期待……'}>
                    <Icon name={'info-circle'} style={{marginLeft: '0.5em'}} className={'info-text'} />
                  </Tooltip>
                </span>
              }
              description={
                <span>
                  <Icon name={'warning'} className={'warning-text'} style={{marginRight: '0.5em'}}/>
                  {
                    this.state.securityEmail ?
                      `已绑定邮箱：${this.state.securityEmail}` :
                      '当前未绑定邮箱，绑定后可通过邮箱找回密码。'
                  }
                </span>
              }
            />
          </List.Item>
        </List>
      </div>
    );
  }
}

AccountSecurityInfoPanel.defaultProps = {
  passwordStrength: 50,
  changePwdStatus: ChangePwdStatus.INTERACTING,
  hasPassword: true,
  doChangePwd: (oldPwd, newPwd) => {
    console.log('doChangePwd property is not set, old password: %s, new password: %s.', oldPwd, newPwd);
  },
  resetChangePwdStatus: () => {
    console.log('resetChangePwdStatus property is not set.');
  },
};

AccountSecurityInfoPanel.propTypes = {
  passwordStrength: PropTypes.number,
  changePwdStatus: PropTypes.number,
  hasPassword: PropTypes.bool,
  doChangePwd: PropTypes.func,
  resetChangePwdStatus: PropTypes.func,
};

export default AccountSecurityInfoPanel;