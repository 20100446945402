/*
 * @Author: Carr.s
 * @Date: 2022-04-29 12:26:59
 * @LastEditTime: 2022-05-17 08:27:10
 * @LastEditors: Carr.s
 * @Description:
 * @Copyright by jushutech
 */
/*
 * @Author: Carr.s
 * @Date: 2022-04-29 11:41:35
 * @LastEditTime: 2022-04-29 12:24:26
 * @LastEditors: Carr.s
 * @Description: 预料分析洞察(领导讲话)
 * @Copyright by joinmap
 */
import React from "react";
import ViewInsightChartTpl from "@/components/insightView/template/insight.chart.tpl";
import { connect } from "react-redux";
import styles from "@/style/components/insightView/insight.less";
import Highcharts from "highcharts";
import highcharts3d from "highcharts/highcharts-3d";
highcharts3d(Highcharts);

class ViewIndustrialChainAInsight extends ViewInsightChartTpl {
  //===============================================================
  // begin of overwrite
  // 使用默认的方式就不用重写

  // ------------------------------------------------------------
  // 全局配置
  // ------------------------------------------------------------
  // 显示底部按钮
  showToolbar = false;
  // 洞察名称
  viewerTitle = "语料风格";
  /**
   * 标题渲染
   * 兼容已经做好的洞察页面
   * @returns
   */
  _viewerTitleRender = () => {
    return "";
  };

  // ------------------------------------------------------------
  // 层级分析对象的配置
  // ------------------------------------------------------------
  // 第一层 level1 tag
  column1_showType = "none"; // 第一列的展现形式，list=显示列，tree=显示树
  // column1_DataField = "data"; // 取值字段
  // column1_TotalField = "dataTotal"; // 计算总数(与返回数量不一定一致)字段
  column1_treeFields = []; // 用树状目录显示数据时各层级的 children 字段名数据
  column1_Title = "词性表"; // 显示名称
  column1_Unit = "个"; // 显示的文字单位
  column1_ValueRender = (column1_data, idx, nodePath = undefined) => {
    // 右侧数值渲染，不输出则空白
    // 必须加上 className={styles["line-value"]}
    return "";
    let value = column1_data.hasOwnProperty("value")
      ? Math.round(column1_data.value * 1000) / 10
      : "";
    return <div className={styles["line-value"]}>{value}%</div>;
  };
  /*
  column1_IconRender = (level1_data, idx,nodePath=undefined) => {
    // 左侧图表渲染，不输出则空白
    // 必须加上 className={styles["line-icon"]}
  };
  */
  column1_HoverTitle = (column1_data, idx, nodePath = undefined) => {
    // 列表鼠标悬浮提示文字
    let name = column1_data.hasOwnProperty("name") ? column1_data.name : "";
    if (this.column2_DataField) {
      let column2Length = this.column2_DataField
        ? column1_data[this.column2_DataField].length
        : "";
      return `${this.column1_Title}：${name}\n${this.column2_Title}：${column2Length}${this.column2_Unit}\n点击打开${this.column2_Title}列表`;
    } else {
      return `${this.column1_Title}：${name}`;
    }
  };

  // 第二层 item
  column2_DataField = null; // 取值字段
  column2_TotalField = "value"; // 计算总数(与返回数量不一定一致)字段
  column2_Title = "企业"; // 显示名称
  column2_Unit = "家"; // 显示的文字单位
  column2_ValueRender = (level2_data, idx) => {
    // 右侧数值渲染，不输出则空白
    // 必须加上 className={styles["line-value"]}
    // 不显示
  };

  // ------------------------------------------------------------
  // 接口的配置
  // ------------------------------------------------------------
  // 接口 url path
  // api_path = "";
  api_path = "/wth_server_api/ci_xing_by_view_id";

  // 返回数据默认值
  defaultLimit = 60;

  /**
   * 【继承组件要重写】
   * api 请求完成后，setState结束后的 callback
   */
  apiLoadedCallback = () => {
    // message.info("数据加载成功");
  };

  // ------------------------------------------------------------
  // 图表的配置
  // ------------------------------------------------------------
  // 图表显示库
  chartType = "highcharts";

  /**
   * 生成公用的、默认的 option
   * @returns
   */
  initChartOption = () => {
    let me = this;
    let _chartOption = {
      colors: this._chartColors,
      credits: {
        enabled: false, // 禁用版权信息
      },
      chart: {
        backgroundColor: null,
        // 指定内边距，下面的四个配置可以用 spacing: [10, 10, 15, 10] 来代替
        spacingBottom: 0,
        spacingTop: 10,
        spacingLeft: 10,
        spacingRight: 10,
        // 指定外边距
        // margin: null,
        // marginTop: 0,
        // 指定图表大小
        // width: "50%", // width 不支持百分数
        // width: Math.round(document.documentElement.clientWidth / 2),
        // height: document.documentElement.clientHeight + "px",
        zoomType: "xy", // x、y轴方向可放大
        zoomKey: "shift", // 启用放大按键
        panning: true, // 平移
        panKey: "ctrl", // 启用平移按键

        type: "pie",
        options3d: {
          enabled: true,
          alpha: 60, //倾斜角度
          beta: 0,
        },
        style: {
          margin: "0 auto",
        },
      },
      title: {
        text: "",
      },
      legend: {
        enabled: false,
        itemStyle: {
          color: "#f8f8f8",
        },
      },
      tooltip: {
        backgroundColor: "#fff",
        pointFormat: "<b>{series.name}</b>: <b>{point.percentage:.1f}%</b>",
        style: {
          fontSize: 14,
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          slicedOffset: 50,
          depth: 30,
          dataLabels: {
            enabled: true,
            format: "{point.name}: <b>{point.percentage:.1f}%</b>",
            style: {
              color: "#858b91",
              textOutline: "none",
              fontWeight: "normal",
              fontSize: "1rem",
            },
          },
          point: {
            events: {
              click: (e) => {
                console.log("point event e:", e);
                me._column1_onClick(e.point.index, e.point.name, false);
              },
            },
          },
        },
      },
      series: [
        {
          type: "pie",
          name: "占比",
          data: [],
        },
      ],
    };
    return _chartOption;
  };

  /**
   * 【继承组件要重写】
   * 生成 chart 的 options
   * @param {int} idx 选中的数据下标
   */
  refreshChartOptions = (nodePath = null) => {
    const {
      query: { isDebug },
    } = this.props;
    if (isDebug) console.log("refreshChartOptions 0 nodePath:", nodePath);
    let me = this;
    let data = [];
    this._chartData.forEach((e, idx) => {
      data.push([e.name, Math.round(e.value * 1000) / 1000, idx]);
    });

    // 生成options
    me._chartOption = this.initChartOption();
    me._chartOption.series[0].data = data;

    // ---------------------------------------------
    // 图表大小的处理
    let chartMaxWidth = 1500;
    let chartMaxHeight = 1500;
    // 计算图表的宽度
    let chartWidth = document.getElementById("chart_div").clientWidth;
    chartWidth = chartWidth > chartMaxWidth ? chartMaxWidth : chartWidth;
    me._chartOption.chart.width = chartWidth;
    // 计算图表的高度
    let chartHeight = document.getElementById("chart_div").clientHeight;
    chartHeight = chartHeight > chartMaxHeight ? chartMaxHeight : chartHeight;
    me._chartOption.chart.height = chartHeight;

    if (isDebug) console.log("me._chartOption:", me._chartOption);
    return;
  };

  /**
   * 【继承组件要重写】
   * echart 有时需要绑定点击事件什么
   * @param {object} e ReactECharts 返回的 echarts 实例
   */
  eChartRefCallback = (e) => {};

  // end of overwrite
  //===============================================================

  getColorOfLevel = (level, maxLevel = 1000000) => {
    if (level > 0 && level < this._chartColors.length) {
      if (level > maxLevel) {
        return "#dddddd";
      }
      return this._chartColors[level];
    } else {
      return "#dddddd";
    }
  };
}

export default connect(
  (state) => ({
    userInfo: state.account.userInfo,
  }),
  (dispatch) => ({})
)(ViewIndustrialChainAInsight);
