import React from 'react';
import PropTypes from 'prop-types';
import {message, Modal, Tooltip, Cascader, Button, Statistic} from "antd";
// import moment from 'moment';

// import {getNodeIconByType, NODE_TYPE_TEXT} from "@/constants/vis.defaultDefine.1";
// import Icon from "@/components/common/common.icon";

import styles from "@/style/containers/relationView.less";

const {confirm} = Modal;

class RelationPagination extends React.PureComponent {
  state = {
    // 当前展示的原图谱中节点分页数据页码
    currentOriginalViewNodesPage: 0,
    currentSubPageIndex: 0,
    
    // 被点击的按钮对应的时间数据
    selectYearVal: -1,
    selectMonthVal: undefined,
    selectDateVal: undefined,
    selectHourVal: undefined,
    
    // 出现提示的状态值
    selectedTipVal: undefined
  };
  
  /**
   * 显示看板的原生节点 ？？？
   * @param currentOriginalViewNodesPage
   * @param pageSize
   * @param currentSubPageIndex
   */
  showOriginalViewNodesAtPage = (currentOriginalViewNodesPage, pageSize, currentSubPageIndex = 0) => {
    let me = this,
      totalPage = me.props.nodesRangeList.length,
      list;
    
    if (me.props.nodesRangeList.length <= 0) return;
    if (currentOriginalViewNodesPage === undefined) {
      currentOriginalViewNodesPage = totalPage;
      while (currentOriginalViewNodesPage > 1 &&
      me.props.nodesRangeList[currentOriginalViewNodesPage - 1].nodeList.length <= 0) {
        
        currentOriginalViewNodesPage--;
      }
    }
    list = me.props.nodesRangeList[currentOriginalViewNodesPage - 1];
    if (list.nodeList.length <= 0) {
      message.info('您选择的时段没有任何数据');
    }
    
    me.setState({
      currentOriginalViewNodesPage,
      currentSubPageIndex: currentSubPageIndex,
    }, () => {
      if (me.props.type === 'timeline') {
        // me.props.onPageChange(currentOriginalViewNodesPage, currentSubPageIndex);
      } else {
        // me.props.onPageChange(currentOriginalViewNodesPage);
      }
      
    });
  };
  
  componentDidMount() {
    let me = this;
    
    me.showOriginalViewNodesAtPage();
  }
  
  /**
   * 读取四个筛选维度的状态值
   * @returns {*[]}
   */
  getSelectedValues = () => {
    let values = [];
    if (this.state.selectYearVal && this.state.selectYearVal > -1) {
      values.push(this.state.selectYearVal);
      if (this.state.selectMonthVal && this.state.selectMonthVal > -1) {
        values.push(this.state.selectMonthVal);
        if (this.state.selectDateVal && this.state.selectDateVal > -1) {
          values.push(this.state.selectDateVal);
          if (this.state.selectHourVal && this.state.selectHourVal > -1) {
            values.push(this.state.selectHourVal);
          }
        }
      }
    }
    return values;
  }
  
  showAllNodes = (nodesTotal = 0) => {
    const {onRangeChange} = this.props;
    let me = this;
    if (nodesTotal > 300) {
      confirm({
        title: '提示',
        content: '即将显示的节点数量较大，会严重影响绘图性能，是否继续?',
        okText: '继续显示',
        onOk() {
          onRangeChange && onRangeChange(me.getSelectedValues(), -1);
        },
        cancelText: '取消',
        onCancel() {
        },
      });
    } else {
      onRangeChange && onRangeChange(me.getSelectedValues(), -1);
    }
    
  }
  
  render() {
    console.log('88888888888888888888888888 pagination_2 props= ', this.props);
    const {
      datetimeTree = [],
      dateTimePagination = {},
      onRangeChange,
      onPaginationClick,
      nodesTotalOutOf300 = 0,
      nodesRangeList,
      nodesTotal = 0,
      filterType
    } = this.props;
    const {selectYearVal, selectMonthVal, selectDateVal, selectHourVal, selectedTipVal} = this.state;
    let me = this;
    
    console.log('88888888888888888888888888 pagination_2 dateTimePagination= ', dateTimePagination);
    
    // 年份按钮
    let yearsBtn = [];
    if (dateTimePagination
      && dateTimePagination.hasOwnProperty('years')
      && dateTimePagination.years.hasOwnProperty('pagination')
    ) {
      Object.keys(dateTimePagination.years.pagination).forEach(k => {
        let b = dateTimePagination.years.pagination[k];
        yearsBtn.push(
          <Tooltip
            key={k}
            defaultVisible={selectedTipVal === k}
            title={<div style={{width: 120}}>
              <div>{b.value}年度:</div>
              <div>节点数: <span style={{float: 'right'}}>{b.total} 个</span></div>
              {
                selectYearVal === b.value && selectMonthVal === -1 ? (
                  <>
                    <div>已显示: <span style={{float: 'right'}}>{(b.total - nodesTotalOutOf300)} 个</span></div>
                    <div>未显示: <span style={{float: 'right'}}>{nodesTotalOutOf300} 个</span></div>
                    {
                      nodesTotalOutOf300 > 0 ?
                        <div style={{width: '100%', textAlign: 'center'}}><a onClick={() => {
                          me.showAllNodes(b.total);
                        }
                        }>全部显示</a></div> : ''
                    }
                  </>
                ) : null
              }
              <div style={{clear: 'both'}}></div>
            </div>}
          >
            <Button
              size='small'
              type={selectYearVal === b.value ? "primary" : "default"}
              disabled={b.total < 1}
              onClick={() => {
                this.setState({
                  selectYearVal: b.value,
                  selectMonthVal: -1,
                  selectDateVal: undefined,
                  selectHourVal: undefined,
                  selectedTipVal: k
                }, () => {
                  setTimeout(() => {
                    onRangeChange && onRangeChange(me.getSelectedValues());
                  }, 50);
                });
              }}
            >
              {b.total > 0 ? <strong>{b.label}</strong> : b.label}
            </Button>
          </Tooltip>
        );
      });
    }
    
    // 月份按钮
    let monthsBtn = [];
    if (dateTimePagination
      && dateTimePagination.hasOwnProperty('months')
      && dateTimePagination.months.hasOwnProperty('pagination')
    ) {
      Object.keys(dateTimePagination.months.pagination).forEach(k => {
        let b = dateTimePagination.months.pagination[k];
        monthsBtn.push(
          <Tooltip
            key={k}
            defaultVisible={selectedTipVal === k}
            title={!selectYearVal || selectYearVal < 0 ? null : <div style={{width: 120}}>
              <div>{selectYearVal}年{b.value}月内:</div>
              <div>节点数: <span style={{float: 'right'}}>{b.total} 个</span></div>
              {
                selectMonthVal === b.value && selectDateVal === -1 ? (
                  <>
                    <div>已显示: <span style={{float: 'right'}}>{(b.total - nodesTotalOutOf300)} 个</span></div>
                    <div>未显示: <span style={{float: 'right'}}>{nodesTotalOutOf300} 个</span></div>
                    {
                      nodesTotalOutOf300 > 0 ?
                        <div style={{width: '100%', textAlign: 'center'}}><a onClick={() => {
                          me.showAllNodes(b.total);
                        }
                        }>全部显示</a></div> : ''
                    }
                  </>
                ) : null
              }
              <div style={{clear: 'both'}}></div>
            </div>
            }
          >
            <Button
              size='small'
              type={selectMonthVal === b.value ? "primary" : "default"}
              disabled={b.total < 1}
              onClick={() => {
                this.setState({
                  selectMonthVal: b.value,
                  selectDateVal: -1,
                  selectHourVal: undefined,
                  selectedTipVal: k
                }, () => {
                  setTimeout(() => {
                    onRangeChange && onRangeChange(me.getSelectedValues());
                  }, 50);
                });
              }}
            >
              {b.total > 0 ? <strong>{b.label}</strong> : b.label}
            </Button>
          </Tooltip>
        );
      });
    }
    
    // 日期按钮
    let datesBtn = [];
    if (dateTimePagination
      && dateTimePagination.hasOwnProperty('dates')
      && dateTimePagination.dates.hasOwnProperty('pagination')
    ) {
      Object.keys(dateTimePagination.dates.pagination).forEach(k => {
        let b = dateTimePagination.dates.pagination[k];
        datesBtn.push(
          <Tooltip
            key={k}
            defaultVisible={selectedTipVal === k}
            title={!selectMonthVal || selectMonthVal < 0 ? null : <div style={{width: 130}}>
              <div>{selectYearVal}年{selectMonthVal}月{b.value}日内:</div>
              <div>节点数: <span style={{float: 'right'}}>{b.total} 个</span></div>
              {
                selectDateVal === b.value && selectHourVal === -1 ? (
                  <>
                    <div>已显示: <span style={{float: 'right'}}>{(b.total - nodesTotalOutOf300)} 个</span></div>
                    <div>未显示: <span style={{float: 'right'}}>{nodesTotalOutOf300} 个</span></div>
                    {
                      nodesTotalOutOf300 > 0 ?
                        <div style={{width: '100%', textAlign: 'center'}}><a onClick={() => {
                          me.showAllNodes(b.total);
                        }
                        }>全部显示</a></div> : ''
                    }
                  </>
                ) : null
              }
              <div style={{clear: 'both'}}></div>
            </div>
            }
          >
            <Button
              size='small'
              type={selectDateVal === b.value ? "primary" : "default"}
              disabled={b.total < 1}
              onClick={() => {
                this.setState({
                  selectDateVal: b.value,
                  selectHourVal: -1,
                  selectedTipVal: k
                }, () => {
                  setTimeout(() => {
                    onRangeChange && onRangeChange(me.getSelectedValues());
                  }, 50);
                });
              }}
            >
              {b.total > 0 ? <strong>{b.label}</strong> : b.label}
            </Button>
          </Tooltip>
        );
      });
    }
    
    // 小时按钮
    let hoursBtn = [];
    if (dateTimePagination
      && dateTimePagination.hasOwnProperty('hours')
      && dateTimePagination.hours.hasOwnProperty('pagination')
    ) {
      Object.keys(dateTimePagination.hours.pagination).forEach(k => {
        let b = dateTimePagination.hours.pagination[k];
        hoursBtn.push(
          <Tooltip
            key={k}
            defaultVisible={selectedTipVal === k}
            title={!selectDateVal || selectDateVal < 0 ? null : <div style={{width: 160}}>
              <div>{selectYearVal}年{selectMonthVal}月{selectDateVal}日{b.value}时内:</div>
              <div>节点数: <span style={{float: 'right'}}>{b.total} 个</span></div>
              {
                selectHourVal === b.value ? (
                  <>
                    <div>已显示: <span style={{float: 'right'}}>{(b.total - nodesTotalOutOf300)} 个</span></div>
                    <div>未显示: <span style={{float: 'right'}}>{nodesTotalOutOf300} 个</span></div>
                    {
                      nodesTotalOutOf300 > 0 ?
                        <div style={{width: '100%', textAlign: 'center'}}><a onClick={() => {
                          me.showAllNodes(b.total);
                        }
                        }>全部显示</a></div> : ''
                    }
                  </>
                ) : null
              }
              <div style={{clear: 'both'}}></div>
            </div>
            }
          >
            <Button
              size='small'
              type={selectHourVal === b.value ? "primary" : "default"}
              disabled={b.total < 1}
              onClick={() => {
                this.setState({
                  selectHourVal: b.value,
                  selectedTipVal: k
                }, () => {
                  setTimeout(() => {
                    onRangeChange && onRangeChange(me.getSelectedValues());
                  }, 50);
                });
              }}
            >
              {b.total > 0 ? <strong>{b.label}</strong> : b.label}
            </Button>
          </Tooltip>
        );
      });
    }
    
    return (
      <div className={styles['pagination-frame']}>
        <div className={styles['statistic']}
             style={{flex: '0 1 200px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <Statistic title={<div>【{filterType ? filterType.text : '总'}】节点总数</div>}
                     value={nodesTotal ? nodesTotal : 0}
                     suffix="个"
                     style={{flex: 1, textAlign: 'center', color: '#fff'}}
          />
        </div>
        <div className={styles['scrollbar-x']}
             style={{flex: '1', margin: '0 auto', padding: '0 5px', position: 'relative'}}>
          {/*
          <Cascader
            size='small'
            changeOnSelect
            expandTrigger='hover'
            options={datetimeTree}
            onChange={(values) => {
              this.setState({
                selectIdx: -1
              }, () => {
                setTimeout(() => {
                  onRangeChange && onRangeChange(values);
                }, 300);
              });
            }}
            placeholder="选择日期"
            style={{display: 'none'}}
          />
*/}
          
          {/*年*/}
          <div style={{width: '100%', margin: '0 auto', padding: 2.5}}>
            <span>年份：</span>
            <Tooltip
              defaultVisible={selectYearVal === -1}
              title={!selectYearVal || selectYearVal > -1 ? <div>全部年份内节点</div> : <div style={{width: 150}}>
                <div>图谱内{filterType ? '【' + filterType.text + '】节点' : '节点'}:</div>
                <div>节点数: <span style={{float: 'right'}}>{nodesTotal} 个</span></div>
                {
                  selectYearVal === -1 ? (
                    <>
                      <div>已显示: <span style={{float: 'right'}}>{(nodesTotal - nodesTotalOutOf300)} 个</span></div>
                      <div>未显示: <span style={{float: 'right'}}>{nodesTotalOutOf300} 个</span></div>
                      {
                        nodesTotalOutOf300 > 0 ?
                          <div style={{width: '100%', textAlign: 'center'}}><a onClick={() => {
                            me.showAllNodes(nodesTotal);
                          }
                          }>全部显示</a></div> : ''
                      }
                    </>
                  ) : null
                }
                <div style={{clear: 'both'}}></div>
              </div>
              }
            >
              <Button
                size='small'
                type={selectYearVal === -1 ? "primary" : "default"}
                onClick={() => {
                  this.setState({
                    selectYearVal: -1,
                    selectMonthVal: undefined,
                    selectDateVal: undefined,
                    selectHourVal: undefined
                  }, () => {
                    setTimeout(() => {
                      onRangeChange && onRangeChange(me.getSelectedValues());
                    }, 500);
                  });
                }}
              >
                全部
              </Button>
            </Tooltip>
            {yearsBtn.map(i => i)}
          </div>
          
          {/*月*/}
          <div className={styles['scrollbar-xx']} style={{width: '100%', margin: '0 auto', padding: 2.5}}>
            <span>月份：</span>
            <Tooltip
              title={<div>显示全年节点</div>}
            >
              <Button
                size='small'
                type={selectMonthVal === -1 ? "primary" : "default"}
                disabled={!selectYearVal || selectYearVal === -1}
                onClick={() => {
                  this.setState({
                    selectMonthVal: -1,
                    selectDateVal: undefined,
                    selectHourVal: undefined
                  }, () => {
                    setTimeout(() => {
                      onRangeChange && onRangeChange(me.getSelectedValues());
                    }, 500);
                  });
                }}
              >
                全部
              </Button>
            </Tooltip>
            
            {monthsBtn.map(i => i)}
          </div>
          
          {/*日*/}
          <div className={styles['scrollbar-xx']} style={{width: '100%', margin: '0 auto', padding: 2.5}}>
            <span>日期：</span>
            <Tooltip
              title={<div>显示整月内节点</div>}
            >
              <Button
                size='small'
                type={selectDateVal === -1 ? "primary" : "default"}
                disabled={!selectMonthVal || selectMonthVal === -1}
                onClick={() => {
                  this.setState({
                    selectDateVal: -1,
                    selectHourVal: undefined
                  }, () => {
                    setTimeout(() => {
                      onRangeChange && onRangeChange(me.getSelectedValues());
                    }, 500);
                  });
                }}
              >
                全部
              </Button>
            </Tooltip>
            
            {datesBtn.map(i => i)}
          </div>
          
          {/*小时*/}
          <div className={styles['scrollbar-xx']} style={{width: '100%', margin: '0 auto', padding: 2.5}}>
            <span>时间：</span>
            <Tooltip
              title={<div>显示全天内节点</div>}
            >
              <Button
                size='small'
                type={selectHourVal === -1 ? "primary" : "default"}
                disabled={!selectDateVal || selectDateVal === -1}
                onClick={() => {
                  this.setState({
                    selectHourVal: -1
                  }, () => {
                    setTimeout(() => {
                      onRangeChange && onRangeChange(me.getSelectedValues());
                    }, 500);
                  });
                }}
              >
                全部
              </Button>
            </Tooltip>
            {hoursBtn.map(i => i)}
          </div>
        </div>
      </div>
    );
  }
}

RelationPagination.defaultProps = {};

RelationPagination.propTypes = {
  nodesRangeList: PropTypes.array.isRequired,
  onRangeChange: PropTypes.func.isRequired,
  // onPaginationClick: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  viewType: PropTypes.string,
  datetimeTree: PropTypes.array,
  dateTimePagination: PropTypes.object,
};

export default RelationPagination;
