import React from 'react';
import PropTypes from 'prop-types';

import {SimplePB} from "@/libs/simplePB";
import ViewDataProvider from "@/libs/view/network/ViewDataProvider";

import bg from '@/assets/statistics_index_bg.png';

// noinspection SpellCheckingInspection
const initialConfig = {
  backgroundImgBase64Url: bg,
  indexes: [{
    left: 0.3,
    top: 0.25,
    title: '数值总和',
    filter: '',
    alg: 1, // 0-数值求平均，1-数值求和，2-百分比求平均，3-命中节点数量
  }, {
    left: 0.3,
    top: 0.75,
    title: '数值平均',
    filter: '',
    alg: 0,
  }],
};

const initialValues = [false, false];

class StatisticsIndexLogic extends React.PureComponent {
  config = initialConfig;
  values = initialValues;
  loadingStatus = 'idle';

  componentDidMount() {
    let me = this;

    let busWrapper = me.props.bus.with(me);

    // 加载图谱指标配置
    busWrapper.subscribe('statistics.index', 'config.load.do', ({viewId, success, failed}) => {
      if (viewId === me.props.viewId) {
        me.loadingStatus = 'processing';
        me.props.viewDataProvider.loadConfig('$.viewData.statistics.index').then(config => {
          me.config = config ? {...initialConfig, ...config} : initialConfig;
          me.values = me.config.indexes.map(() => false);
          me.loadingStatus = 'success';
          me.props.bus.emit('statistics.index', 'config.load.success',
            {viewId, config: me.config});
          success && success();
        }).catch(error => {
          me.loadingStatus = 'failed';
          me.props.bus.emit('statistics.index', 'config.load.failed', {viewId, error});
          failed && failed();
        });
      }
    });

    // 获取图谱指标配置
    busWrapper.subscribe('statistics.index', 'config.get', ({viewId, success, failed}) => {
      if (viewId === me.props.viewId) {
        if (me.loadingStatus === 'processing') return;
        if (me.loadingStatus === 'success') {
          me.props.bus.emit('statistics.index', 'config.load.success',
            {viewId, config: me.config});
          success && success();
        } else {
          me.props.bus.emit('statistics.index', 'config.load.do', {viewId, success, failed});
        }
      }
    });

    // 更新部分配置
    busWrapper.subscribe('statistics.index', 'config.update.do',
      ({viewId, key, config, success, failed}) => {
        if (viewId === me.props.viewId) {
          me.props.viewDataProvider.updateConfig(
            `$.viewData.statistics.index${key ? '.' + key : ''}`, config, false
          ).then(() => {
            me.props.bus.emit('statistics.index', 'config.update.success', {viewId});
            success && success();
          }).catch(error => {
            me.props.bus.emit('statistics.index', 'config.update.failed', {viewId, error});
            failed && failed();
          });
        }
      });

    // 加载指标值
    busWrapper.subscribe('statistics.index', 'value.load.do',
      ({idx, filter, alg, modifiedNodeIds, hash, viewId, success, failed}) => {
        if (viewId === me.props.viewId) {
          me.props.viewDataProvider.loadIndexValue(filter, alg, modifiedNodeIds, hash).then(({value, hash}) => {
            me.config.indexes[idx].hash = hash;
            me.values[idx] = value;
            me.props.bus.emit('statistics.index', 'value.load.success', {idx, value, hash, viewId});
            success && success({idx, value, hash});
          }).catch(error => {
            me.props.bus.emit('statistics.index', 'value.load.failed', {idx, viewId, error});
            failed && failed();
          });
        }
      });

    // 获取指标值
    /*busWrapper.subscribe('statistics.index', 'value.get',
      ({idx, hash, viewId, success, failed}) => {
        if (viewId === me.props.viewId) {
          if (me.config.indexes[idx] && me.config.indexes[idx].hash && me.config.indexes[idx].hash === hash) {
            me.props.bus.emit('statistics.index', 'value.load.success',
              {idx, value: me.values[idx], hash, viewId});
            success && success({idx, value: me.values[idx], hash});
          } else if (me.config.indexes[idx]) {
            me.props.bus.emit('statistics.index', 'value.load.do',
              {idx, ...me.config.indexes[idx], viewId, success, failed});
          }
        }
      });*/
  }

  componentWillUnmount() {
    this.props.bus.remove(this);
  }

  render() {
    return (<div style={{height: 0, width: 0}} />);
  }
}

StatisticsIndexLogic.propTypes = {
  bus: PropTypes.instanceOf(SimplePB).isRequired,
  viewId: PropTypes.string.isRequired,
  viewDataProvider: PropTypes.instanceOf(ViewDataProvider).isRequired,
};

export default StatisticsIndexLogic;