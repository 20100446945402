import React from 'react';
import PropTypes from 'prop-types';

import PB, {SimplePB} from '@/libs/simplePB';

import ViewDataProvider from '@/components/common/dataProvider/common.dataProvider.view';
import ExploreCommonRecommendDetailModal
  from "@/components/common/view/explore/common.view.explore.common.recommendDetail.modal";

class ExploreCompanyRecommendDetailModal extends React.Component {
  render() {
    let me = this;

    return (
      <ExploreCommonRecommendDetailModal
        exploreName={'企业'}
        exploreKey={'company'}
        exploreEventCategory={'explore_company'}
        {...me.props}
      />
    );
  }
}

ExploreCompanyRecommendDetailModal.defaultProps = {
  bus: PB,
};

ExploreCompanyRecommendDetailModal.propTypes = {
  viewDataProvider: PropTypes.instanceOf(ViewDataProvider).isRequired,
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired,
  bus: PropTypes.instanceOf(SimplePB),
};

export default ExploreCompanyRecommendDetailModal;