import React, { Fragment } from 'react';
import { Icon, Input, Modal, Form, Upload, message, Menu } from 'antd';

import PB from '@/libs/simplePB';
import { IconFont } from '@/components/common/common.fonticon';
import style from '@/style/components/main.siderbar.less';

@Form.create()
class Feedback extends React.Component {

	canFeedback = true;
	state = {
		visible: false, // 显示反馈窗口
		fileList: [],
		previewVisible: false,
		previewImage: '',
	};

	componentWillReceiveProps(nextProps) {
		if (nextProps.feedbackResult === 'success' && this.canFeedback) {
			message.info('反馈成功！谢谢', 1);
			this.setState({visible: false, fileList: []});
			this.canFeedback = false;
			this.props.form.resetFields();

		}
		if (nextProps.feedbackResult === 'failed' && this.canFeedback) {
			message.info('反馈失败！请重试', 1);
			this.setState({fileList: []});
			this.canFeedback = false;
			this.props.form.resetFields();
		}
	}

	componentDidMount() {
		PB.sub(this, 'feedback', 'new', () => {
			this.setState({visible: true});
		});
		PB.sub(this, 'feedback', 'close', () => {
			this.setState({visible: false});
		});
	}

	componentWillUnmount() {
		PB.remove(this);
	}

	handleOk = () => {
		this.props.form.validateFieldsAndScroll((err, values) => {
			if (!err) {
				console.log('Received values of form: ', values);
				const params = {
					userId: parseInt(localStorage.getItem('userId'), 10),
					feedback: values.feedback,
				};

				const files = [];
				this.state.fileList.forEach(file => {
					console.log(file);
					const {name, thumbUrl} = file;
					files.push({name, base64: thumbUrl});
				});

				// formData.append('files', files);
				params.files = files;
				// console.log('deal Received values of form: ', formData);
				// const formData = new FormData();
				// for (let k in params) {
				//     formData.append(k, params[k]);
				// }
				this.canFeedback = true;
				this.props.submitFeedbackAction(params);
			}
		});

	};
	handleCancel = () => this.setState({previewVisible: false});

	// 图片上传
	handleBeforeUpload = (file) => {
		console.log(file);
		if (file.size > 5 * 1024 * 1024) {
			message.error('上传文件不要超过5MB');
			return false;
		}
		this.setState(({fileList}) => ({
			fileList: [...fileList, file],
		}));
		return false;
	};
	handleUploadPreview = (file) => {
		this.setState({
			previewImage: file.url || file.thumbUrl,
			previewVisible: true,
		});
	};
	handleUploadChange = ({fileList}) => this.setState({fileList});

	render() {
		// console.log('feedback', this.props)
		const {previewVisible, previewImage/*, fileList*/} = this.state;
		const {getFieldDecorator} = this.props.form;
		// 上传附件的按钮
		const uploadButton = (
			<div>
				<Icon type="plus"/>
				{/* <div className="ant-upload-text">上传截图</div> */}
			</div>
		);
		const formItemLayout = null;
		/*const xformItemLayout = {
				labelCol: {
						xs: {span: 16},
						sm: {span: 5},
				},
				wrapperCol: {
						xs: {span: 30},
						sm: {span: 18},
				},
		};*/
		// noinspection RequiredAttributes
		return (
			<Fragment>
				<Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
					<img alt="example" style={{width: '100%'}} src={previewImage}/>
				</Modal>
				<Modal title={<div><IconFont type="icon-kefu" style={{marginRight: 6}}/> 反馈意见</div>}
				       centered={true}
				       visible={this.state.visible}
				       onOk={this.handleOk}
				       confirmLoading={this.props.feedbackLoading}
				       onCancel={() => {
					       this.setState({visible: false, fileList: []});
					       this.props.form.resetFields();
				       }}
				       cancelText='取消'
				       okText='提交'
				       //wrapClassName={style['feedback-form-modal']} 提交
				       width={555}
					   footer={null}
				>
					 <p>感谢您的使用和支持，有任何疑问和建议请发送邮件到：hi@joinmap.ai</p> 
					{/*
					<Form layout='vertical'
					      className={style['feedback-form']}
					      onSubmit={() => {}}
					>
						<Form.Item
							{...formItemLayout}
							style={{marginBottom: 6}}
						>
							{getFieldDecorator('feedback', {
								rules: [{required: true, message: '请写上您的问题或者想法', whitespace: true}],
							})(
								<Input.TextArea
									placeholder="请说明您的问题或分享您的想法"
									autoSize={{minRows: 9}}
									style={{borderRadius: '0'}}
									autoFocus={true}
								/>
							)}
						</Form.Item>
						<Form.Item
							// label="上传截图"
							{...formItemLayout}
							style={{marginRight: '-9px', paddingBottom: 0}}
						>
							{getFieldDecorator('files', {
								// rules: [{ required: true, message: '请输入您的反馈意见', whitespace: true }],
							})(
								<Upload
									// action="//jsonplaceholder.typicode.com/posts/"
									accept=".jpg,.png,.jpeg"
									listType="picture-card"
									fileList={this.state.fileList}
									beforeUpload={this.handleBeforeUpload}
									onPreview={this.handleUploadPreview}
									onChange={this.handleUploadChange}
									onRemove={(file) => {
										this.setState(({fileList}) => {
											const index = fileList.indexOf(file);
											fileList.splice(index, 1);
											return {
												fileList,
											};
										});
									}}
								>
									{this.state.fileList.length < 3 ? uploadButton : null} 上传截图
								</Upload>
							)}
						</Form.Item>
						<div className="clearfix"></div>
					</Form>*/}
				</Modal>
			</Fragment>
		);
	}
}

export default Feedback;