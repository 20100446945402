import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import PB, {SimplePB} from '@/libs/simplePB';

import MicroServiceDataProvider from '@/components/common/dataProvider/common.dataProvider.microService';
import ViewDataProvider from '@/components/common/dataProvider/common.dataProvider.view';
import {toLatestConfig} from '@/components/common/view/microService/common.view.microService.dataAdapter';

class MicroServiceLogicConfig extends React.PureComponent {

  onAddConfig = (config, successCb, failedCb) => {
    let me = this;

    me.props.bus.emit('view', 'micro_service.config.do_add', {config});
    MicroServiceDataProvider.addConfig(config).then(() => {
      me.props.bus.emit('view', 'micro_service.config.added', {config});
      successCb && successCb();
    }).catch(({code, msg}) => {
      me.props.bus.emit('view', 'micro_service.config.failed_to_add', {code, msg, config});
      failedCb && failedCb({code, msg});
    });
  }

  onEditConfig = (config, successCb, failedCb) => {
    let me = this;

    me.props.bus.emit('view', 'micro_service.config.do_edit', {id: config.id, config});
    MicroServiceDataProvider.updateConfig(config.id, config).then(() => {
      me.props.bus.emit('view', 'micro_service.config.edited', {id: config.id, config});
      successCb && successCb();
    }).catch(({code, msg}) => {
      me.props.bus.emit('view', 'micro_service.config.failed_to_edit', {code, msg, id: config.id, config});
      failedCb && failedCb({code, msg});
    });
  }

  onLoadConfig = (projectId, enabledOnly, type = '', tag, query = '', start = 0, limit = 15, onSuccess = () => {}, onError = () => {}, id) => {
    let me = this;
    me.props.bus.emit('view', 'micro_service.config.do_load', {projectId, tag, query, start, limit});
    let loadFn;
    switch (type) {
      case 'my':
        loadFn = () => MicroServiceDataProvider.myConfigList(tag, start, limit);
        break;
      case 'recommend':
        loadFn = () => MicroServiceDataProvider.recommendConfigList(tag, start, limit);
        break;
      case 'id':
        loadFn = () => MicroServiceDataProvider.getMicroServiceById(id);
        break;
      default:
        loadFn = () => MicroServiceDataProvider.loadConfigList(projectId, enabledOnly, tag, query, start, limit);
    }

    loadFn().then(reData => {
      let dataList = reData.data, total = reData.total;
      let infoList = (dataList || []).map(data => _.cloneDeep(data));
      let configList = (dataList || []).map(data => ({
        id: data.id,
        ...toLatestConfig(data.uiConfig),
      }));
      me.props.bus.emit('view', 'micro_service.config.data_loaded', {projectId, type, query, start, limit, infoList, configList});
      if (onSuccess) {
        onSuccess(infoList, configList, total);
      }
    }).catch(({code, msg}) => {
      me.props.bus.emit('view', 'micro_service.config.failed_to_load', {code, msg, projectId, type, query, start, limit});
      if (onError) {
        onError(code, msg);
      }
    });
  };

  onRemoveConfig = (configId, successCb, failedCb) => {
    let me = this;

    me.props.bus.emit('view', 'micro_service.config.do_remove', {id: configId});
    MicroServiceDataProvider.removeConfig(configId).then(() => {
      me.props.bus.emit('view', 'micro_service.config.removed', {id: configId});
      successCb && successCb();
    }).catch(({code, msg}) => {
      me.props.bus.emit('view', 'micro_service.config.failed_to_edit', {code, msg, id: configId});
      failedCb && failedCb({code, msg});
    });
  }

  componentDidMount() {
    let me = this;

    me.props.bus.with(
      me
    ).subscribe('view', 'micro_service.config.on_load', (
      {projectId, enabledOnly, type, tag, query, start, limit, onSuccess, onError,id}
    ) => {
      me.onLoadConfig(projectId, enabledOnly !== false, type, tag, query, start, limit, onSuccess, onError,id);
    }).subscribe('view', 'micro_service.config.on_add', ({config, successCb, failedCb}) => {
      me.onAddConfig(config, successCb, failedCb);
    }).subscribe('view', 'micro_service.config.on_edit', ({config, successCb, failedCb}) => {
      me.onEditConfig(config, successCb, failedCb);
    }).subscribe('view', 'micro_service.config.on_remove', ({id, successCb, failedCb}) => {
      me.onRemoveConfig(id, successCb, failedCb);
    });
  }

  componentWillUnmount() {
    this.props.bus.remove(this);
  }

  render() {
    return null;
  }
}

MicroServiceLogicConfig.defaultProps = {
  bus: PB,
};

MicroServiceLogicConfig.propTypes = {
  viewDataProvider: PropTypes.instanceOf(ViewDataProvider).isRequired,
  bus: PropTypes.instanceOf(SimplePB),
};

export default MicroServiceLogicConfig;