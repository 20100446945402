import React from 'react';
import PropTypes from 'prop-types';
import {Input, Table, Button, Row, Col, Modal, message, Tooltip, Switch} from 'antd';
import Highlighter from 'react-highlight-words';

import PB, {SimplePB} from '@/libs/simplePB';

import Icon from '@/components/common/common.icon';
import PresentationDataProvider from '@/components/common/dataProvider/common.dataProvider.presentation';
import TimeDisplayField from "@/components/common/common.timeDisplayField";
import {defaultIconData} from '@/components/common/view/presentation/common.view.presentation.logic';
import {getToken, REQUEST_BASE} from '@/utils/HttpUtil';
import UserAvatar from "react-user-avatar";
import {AvatarColors, IconTypes} from '@/constants/common';
import {defaultDefine, getNodeDisplayTitle, getNodeIcon, NODE_TYPE_TEXT} from '@/constants/vis.defaultDefine.1';
import style from '@/style/common/view/common.view.presentation.less';

class PresentationConfigList extends React.Component {
  state = {
    searchText: '',
    delayedSearchText: '',
    filteredConfigList: [],
    configList: [],
    configLoaded: false,
  }

  memberMap = undefined;

  getColumns = () => {
    let me = this;
    let r = [{
      title: '专题报告名称',
      dataIndex: 'title',
      key: 'title',
      className: style['config-table-column-title'],
      render: (title, record, index) => {
        return {
          children: (
            <div className={style['config-box']} onClick={() => me.onShowDetail(record['presentationId'], record)}>
              <div style={{clear: 'both'}}>
                <div className={`ant-avatar common-avatar ${style['config-avatar']}`}>
                  <img
                    src={record.meta && record.meta['iconData'] ? record.meta['iconData'] : '/assets/presentation/story_default.png'}
                    alt={'icon'}
                  />
                  {record.isPublic === 1 && 
                    <img src={`/assets/free_flag.png`} alt={'公开'} style={{marginTop:'-4.6rem',width:'66%',height:'66%',float:'right',opacity:'inherit'}}/>}
                </div>
                <div className={style['config-meta']}>
                  <div className={style['config-title-box']}>
                    <div className={style['config-title']}><Tooltip title={record.isPublic === 1?('【已公开】'+title):title}>{title}</Tooltip></div>
                    <div className={`${style['config-avatar-play']}`}>
                      <div
                        className={style['config-avatar-start']}
                        onClick={e => {
                          e.stopPropagation();
                          me.onStartPresentation(record['presentationId'], record,me.memberMap && me.memberMap[`user-${record.userId}`] ? me.memberMap[`user-${record.userId}`].nick : (record.userId === -1 ? '系统' : '--' ),
                          me.memberMap && me.memberMap[`user-${record.userId}`] ? me.memberMap[`user-${record.userId}`].picId : undefined,
                          record.meta && record.meta['iconData'] ? record.meta['iconData'] : '/assets/presentation/story_default.png');
                        }}
                      >
                        <Tooltip
                          overlayClassName={style['presentation-content']}
                          title={(
                            <span style={{display: 'inline-block', padding: '6px 8px'}}>
                                点击按钮自动播放
                            </span>
                          )}
                          trigger='hover'
                          placement="top"
                        >
                          <Icon name={'caret-right'} className={style['play-icon']} type={IconTypes.ANT_DESIGN}/>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                  <div className={style['config-description']}> {/*record.description*/}</div>
                  <div className={style['config-misc']}>
                    <div className={style['config-owner']}>
                      {
                        me.memberMap && me.memberMap[`user-${record.userId}`] && 
                        <UserAvatar
                          size={13}
                          name={me.memberMap[`user-${record.userId}`].nick}
                          src={me.memberMap[`user-${record.userId}`].picId ? `${REQUEST_BASE}/user/user/file/${me.memberMap[`user-${record.userId}`].picId}?Authorization=${getToken()}` : undefined}
                          colors={AvatarColors}
                          style={{marginRight: '0.5em',paddingTop:'0.3rem'}}
                        />
                      }
                      <Tooltip title={me.memberMap && me.memberMap[`user-${record.userId}`] ? (me.memberMap[`user-${record.userId}`].nick) : ( record.userId === -1 ? '系统' : '--')}>
                      {me.memberMap && me.memberMap[`user-${record.userId}`]
                          ? (me.memberMap[`user-${record.userId}`].nick) : (
                            record.userId === -1 ? '系统' : '--'
                          )
                      }</Tooltip>
                    </div>
                    <div className={style['config-node-amount']}>
                      <Icon {...getNodeIcon({
                              type: NODE_TYPE_TEXT,
                            })} style={{marginRight: '0.3em'}} />
                        <Tooltip title={record.content && record.content.nodeIds ? record.content.nodeIds.length : 0}>{record.content && record.content.nodeIds ? record.content.nodeIds.length : 0}</Tooltip>
                    </div>
                    <div className={style['config-update-timestamp']}>
                      <Icon type={IconTypes.ICON_FONT} name={'icon-latest'} style={{marginRight: '0.3em'}}/>
                      <TimeDisplayField timestamp={(new Date(record.updateTimestamp)).getTime()} />
                    </div>
                    <div className={style['config-operate']}>
                      {record.userId !== -1 && (me.props.currentUserId === me.props.viewOwnerId || me.props.currentUserId === record.userId) ? (
                          <span onClick={(e) => {e.stopPropagation();}}>
                            {/*<a className={'link'} style={{margin: '5px'}} onClick={(e) => {e.stopPropagation();me.onSetPublic(record['presentationId'],record)}}>{record.isPublic===1?'公开':'不公开'}</a>*/}
                            <Switch checkedChildren="已公开" unCheckedChildren="未公开" checked={record.isPublic===1} onChange={checked => me.onSetPublic(record['presentationId'],checked===true?1:0)}/> | 
                            <a onClick={(e) => {e.stopPropagation();me.onEditConfig(record['presentationId'],record)}}><Icon name={'edit'}/></a>| 
                            <a style={{marginRight: '0'}} onClick={(e) => {e.stopPropagation();me.onRemoveConfig(record['presentationId'],record)}}><Icon name={'delete'}/></a>
                          </span>
                        ) : (
                          <span>
                            <a onClick={(e) => {e.stopPropagation();me.onShowDetail(record)}}>查看</a>
                          </span>
                        )
                      }
                    </div>
                  </div>
                </div>
              </div>
              {false &&
              <div className={style['config-actions-box']} >
                <div className={style['config-table-column-actions']} style={{width:'3rem',textAlign:'center',float:'left',lineHeight:2}}></div>
                <div className={style['config-description']}>
                  <div className={style['config-misc']}>{record.description}</div>
                </div>
              </div>}
              
            </div>
          ),
          props: {
            colSpan: 4,
          },
        };
      },
    }, {
      title: '节点',
      align: 'center',
      dataIndex: 'content.nodeIds.length',
      key: 'amount',
      sorter: (a, b) => a.content.nodeIds.length - b.content.nodeIds.length,
      width: '4rem',
      render: () => ({
        children: null,
        props: {
          colSpan: 0,
        },
      }),
    }, {
      title: '更新时间',
      align: 'center',
      dataIndex: 'updateTimestamp',
      key: 'updateTimestamp',
      sorter: (a, b) => a.updateTimestamp > b.updateTimestamp ? 1 : (a.updateTimestamp === b.updateTimestamp ? 0 : -1),
      width: '6.9rem',
      render: () => ({
        children: null,
        props: {
          colSpan: 0,
        },
      }),
    }, {
      title: '操作',
      align: 'right',
      dataIndex: 'presentationId',
      key: 'presentationId',
      width: '8rem',
      render: () => ({
        children: null,
        props: {
          colSpan: 0,
        },
      }),
    }];
    return r;
  };

  onSearchChange = (e) => {
    let me = this, searchText = e.target.value;

    me.setState({
      searchText,
      delayedSearchText: searchText === '' ? '' : me.state.delayedSearchText,
      filteredConfigList: searchText === '' ? me.state.configList : me.state.filteredConfigList,
    }, () => {
      setTimeout(() => {
        if (me.state.searchText === searchText && me.state.delayedSearchText !== searchText) {
          me.setState({
            delayedSearchText: searchText,
            filteredConfigList: searchText === '' ? [...me.state.configList] : me.state.configList.filter(c =>
              c.title.indexOf(searchText) >= 0),
          })
        }
      }, 250);
    });
  };

  onStartPresentation = (presentationId, config, username, userPicId, iconData) => {
    let me = this;

    //me.props.bus.emit('presentation', 'config.play.do', {viewId: me.props.viewId, presentationId, config});
    me.props.bus.emit('presentation', 'config.list.hide_drawer', {viewId: me.props.viewId});
    //me.props.bus.emit('presentation', 'story.storyshow.show_drawer', {viewId: me.props.viewId,presentationId,config});
    config.username = username;
    config.userPicId = userPicId;
    config.iconData = iconData;
    me.props.bus.emit('relation', 'node.presentation.stop');
    me.props.bus.emit('presentation', 'config.play.do', {viewId: me.props.viewId, presentationId, config});
  };

  onAddConfig = () => {
    let me = this;

    me.props.bus.emit('presentation', 'config.add.show_modal', {viewId: me.props.viewId});
  };

  onShowDetail = (presentationId,config) => {
    let me = this;

    me.props.bus.emit('presentation', 'config.info.show_modal',
      {viewId: me.props.viewId, presentationId, config});
  }

  onEditConfig = (presentationId,config) => {
    let me = this;
    console.error('2-1:',presentationId,config);
    me.props.bus.emit('presentation', 'config.update.show_modal',
      {viewId: me.props.viewId, presentationId, config});
  }
  
  onSetPublic = (presentationId,isPublic) => {
    let me = this;
    PresentationDataProvider.setPresentationsPublic(presentationId,isPublic).then((config) => {
      message.success(`专题报告已成功设置为${config.isPublic===1?'公开':'不公开'}`);
      me.setState({saving: false}, () => {
        me.props.bus.emit('presentation', 'config.updated',
          {presentationId: presentationId, config});
        me.props.bus.emit('presentation', 'config.list.show_drawer',
          {viewId: me.props.viewId});
      });
    }).catch(error => {
      message.error('专题报告设置是否公开失败');
      me.setState({saving: false}, () => {
        me.props.bus.emit('presentation', 'config.update.failed',
        {...error, viewId: me.props.viewId, presentationId});
      });
    });
  }

  onRemoveConfig = (presentationId,config) => {
    let me = this;

    Modal.confirm({
      centered: true,
      title: "删除专题报告",
      icon: <Icon name="delete"/>,
      okText: "确认删除",
      okType: 'danger',
      cancelText: "取消",
      autoFocusButton: 'cancel',
      content: (<p>是否确认删除专题报告：<b>{config.title}</b>？</p>),
      onOk: () => {
        me.props.bus.emit('presentation', 'config.remove.do',
          {viewId: me.props.viewId, presentationId});
        PresentationDataProvider.removeConfig(presentationId).then(() => {
          message.success('专题报告删除成功');
          me.setState({saving: false}, () => {
            me.props.bus.emit('presentation', 'config.removed',
              {presentationId, config});
          });
        }).catch(error => {
          message.error('专题报告删除失败');
          me.setState({saving: false}, () => {
            me.props.bus.emit('presentation', 'config.remove.failed',
              {...error, viewId: me.props.viewDataProvider.viewId, presentationId});
          });
        });
      },
    });
  }

  arraySort = (property) => {
    return function (a,b) {
      let value1 = a[property].substr(0,1);
      let value2 = b[property].substr(0,1);
      return value1 - value2;
    }
  }

  componentDidMount() {
    let me = this;

    me.props.bus.with(me).subscribe('presentation', 'config.list.success', ({viewId, configList}) => {
      if (me.props.viewId !== viewId) return;
      configList.sort(me.arraySort('title'));
      me.setState({
        configLoaded: true,
        configList,
        filteredConfigList: me.state.delayedSearchText === '' ? [...configList] : configList.filter(c =>
          c.title.indexOf(me.state.delayedSearchText) >= 0),
      });
    }).subscribe('teamwork', 'member.list.success', ({viewId, userList}) => {
      if (me.props.viewId !== viewId) return;
      me.memberMap = {};
      userList.forEach(userInfo => {
        me.memberMap[`user-${userInfo.userId}`] = userInfo;
      });
    });

    me.props.bus.emit('teamwork', 'member.list.do', {viewId: me.props.viewId});
    me.props.bus.emit('presentation', 'config.list.do', {viewId: me.props.viewId});
  }

  componentWillUnmount() {
    this.props.bus.remove(this);
  }

  render() {
    let me = this;
    let filteredConfigList = me.state.filteredConfigList.filter(c =>
      c.presentationId.indexOf('latest') ==-1 && c.title!=='最新动态');

    return (
      <div className={style['config-list-panel']}>
        <div className={style['config-list-header']}>
          专题报告列表
        </div>
        <div className={`${style['config-list-content']} scrollbar scrollbar-none`}>
          <div style={{'border-bottom': '1px solid hsl(213, 10%, 45%)'}}>
            {
              me.props.readOnly ? (
                <Row style={{height: '32px'}}>
                  <Input.Search
                    size={'small'}
                    placeholder={'查询专题报告'}
                    value={me.state.searchText}
                    onChange={me.onSearchChange}
                  />
                </Row>
              ) : (
                <Row style={{height: '32px'}}>
                  <Col span={12}>
                    <Button className={'ant-btn-icon'} size={'small'} type={'primary'} onClick={me.onAddConfig}>
                      <Icon name={'plus'} />添加
                    </Button>
                  </Col>
                  <Col span={12}>
                    <Input.Search
                      size={'small'}
                      placeholder={'查询专题报告'}
                      value={me.state.searchText}
                      onChange={me.onSearchChange}
                    />
                  </Col>
                </Row>
              )
            }
          </div>
          <Table
            className={`${style['config-table']} scrollbar-none`}
            columns={me.getColumns()}
            pagination={false}
            rowKey={'presentationId'}
            dataSource={filteredConfigList}
            showHeader={false}
            rowClassName={(record, index) =>{
              return index === me.state.currentPresentationIndex ? style['active'] : '';
            }}
            locale={{
              emptyText: me.state.configLoaded ? (
                me.state.configList.length > 0 ? '查询结果为空' : '您还没有添加专题报告'
              ) : '加载中，请稍后',
            }}
            scroll={{y: 'calc(100% - 0.5rem)'}}
          />
        </div>
      </div>
    );
  }
}

PresentationConfigList.defaultProps = {
  bus: PB,
  readOnly: true,
};

PresentationConfigList.propTypes = {
  viewId: PropTypes.string.isRequired,
  viewOwnerId: PropTypes.number.isRequired,
  currentUserId: PropTypes.number.isRequired,
  bus: PropTypes.instanceOf(SimplePB),
  readOnly: PropTypes.bool,
};

export default PresentationConfigList;