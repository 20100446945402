import {httpUtilWithNoMsg as httpUtil} from '@/utils/HttpUtil';

export const API_LoadPresentationConfigList = (viewId, userId) => {
  return httpUtil.get(`/view/presentation`, {view_id: viewId, user_id: userId});
};

export const API_LoadPresentationList = (params) => {
  return httpUtil.get(`/view/presentation`, params);
};

export const API_AddPresentationConfig = (config) => {
  return httpUtil.post(`/view/presentation`, config);
}

export const API_UpdatePresentationConfig = (presentationId, config) => {
  return httpUtil.put(`/view/presentation/${presentationId}`, config);
}

export const API_RemovePresentationConfig = (presentationId) => {
  return httpUtil.delete(`/view/presentation/${presentationId}`);
}

export const API_getPresentations = (presentationIds) => {
  return httpUtil.get(`/view/presentation/actions/get_by_ids`, {ids: presentationIds});
}

export const API_SetPresentationsPublic = (presentationId,isPublic) => {
  return httpUtil.put(`/view/presentation/${presentationId}/setpublic`, {isPublic:isPublic});
}