export const TYPE_FIELD_NAME = 'type'; // 节点信息结构体中表示节点类型字段的KEY值
export const NODE_TYPE_NODE_TIP = -1; // 节点 的提示节点  废弃
export const NODE_TYPE_TEXT = 0; // 关注点，(原来的tag,用户输入，新建节点）
export const NODE_TYPE_COMPANY = 1; // 企业
export const NODE_TYPE_TALENT = 2; // 人物
export const NODE_TYPE_PATENT = 3; // #废弃# 专利
export const NODE_TYPE_PAPER = 4; // #废弃# 论文
export const NODE_TYPE_POLICY = 5; // 政策
export const NODE_TYPE_ORG = 6; // 协会 原：社团协会
export const NODE_TYPE_INSTITUTE = 7; // 院所 原：科研院所
export const NODE_TYPE_NEWS_ACTIVITIES = 8; // #废弃# 新闻与活动
export const NODE_TYPE_TAG = 9; // 线索 即：后台计算出的标签
export const NODE_TYPE_DOCS = 10; // #废弃# 用户文档 = 用户文档
export const NODE_TYPE_DATASET = 11; // 数据 原：数据集
export const NODE_TYPE_GOV = 12; // 政府
export const NODE_TYPE_NATURE = 13; // #自然
export const NODE_TYPE_INDEX = 14; // #废弃# 指标
export const NODE_TYPE_GRAPH = 15; // #废弃# 图表
export const NODE_TYPE_COLLEGE_AND_UNIVERSITY = 16; // 高校
export const NODE_TYPE_PARK = 17; // 园区
export const NODE_TYPE_TECHNOLOGY = 18; // 技术
export const NODE_TYPE_TEXT_GOAL = 'type_0_goal'; // 目标
export const NODE_TYPE_PROJECT = 19; // 项目
