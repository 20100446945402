import React from 'react';
import {connect} from 'react-redux';
import {resetPwdSendNewPwdAction, resetPwdInitAction, initLoginViaUsernameAction} from '@/redux-saga/account/actions';

import {ResetPwdStatusEnum} from '@/constants/account';

import checkPwdStrength from '@/utils/PwdStrengthChecker';

import NormalResetPwdForm from '@/components/resetPwdView/resetPwd.normalForm';
import SuccessNoticePanel from '@/components/resetPwdView/resetPwd.successNoticePanel';
import LoginRelatedView from '@/components/common/common.loginRelatedView';

import style from '@/style/components/common.loginRelatedView.less';

class ResetPwdView extends React.Component {
  state = {
    redirectToLoginAt: 0,
  };

  componentWillMount() {
    const {token} = this.props.match.params;
    if (!token) {
      this.props.history.push("/login");
    }
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    let me = this;
    if (me.props.resetPwdStatus !== nextProps.resetPwdStatus &&
      nextProps.resetPwdStatus === ResetPwdStatusEnum.SUCCESS) {

      requestAnimationFrame(() => {
        me.setState({redirectToLoginAt: (new Date()).getTime() + 5000});
      });
    }
    return true;
  }

  onResetPwd = (password) => {
    const {token} = this.props.match.params;
    this.props.doResetPwd(password, token, checkPwdStrength(password));
  };

  render () {
    let form = '', me = this;
    switch (me.props.resetPwdStatus) {
      case ResetPwdStatusEnum.SUCCESS:
        form = (
          <SuccessNoticePanel
            redirectAt={me.state.redirectToLoginAt}
            onBack={() => {
              me.props.init();
              me.props.history.push('/login');
            }}>
          </SuccessNoticePanel>
        );
        break;
      default:
        form = (
          <NormalResetPwdForm
            onResetPwd={me.onResetPwd}
            processing={this.props.resetPwdStatus === ResetPwdStatusEnum.PROCESSING}
          />
        );
    }
    return (
      <div className={`${style["container"]} dark-theme`}>
        <LoginRelatedView.Header history={me.props.history} />
        <LoginRelatedView.Background />
        <div className={style["content"]}>
          {form}
        </div>
        <LoginRelatedView.Intro />
        <LoginRelatedView.Bottom />
      </div>
    );
  }
}

export default connect(
  state => ({
    resetPwdStatus: state.account.resetPwdStatus,
  }),
  dispatch => ({
    doResetPwd: (password, token, strength) => dispatch(resetPwdSendNewPwdAction(password, token, strength)),
    init: () => dispatch(resetPwdInitAction()),
  })
)(ResetPwdView);
