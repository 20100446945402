import React from 'react';
import PropTypes from 'prop-types';
import {Form, Icon, Input, Button, Tooltip} from 'antd';

import AuthForm from '@/components/common/common.authForm';

import style from '@/style/components/common.authForm.less';
import intl from 'react-intl-universal';

class NormalRegisterForm extends React.Component {
  handleRegister = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onRegister(values.username)
      }
    });
  };

  render() {
    const {getFieldDecorator} = this.props.form;
    // noinspection RequiredAttributes
    return (
      <AuthForm>
        <Form onSubmit={this.handleRegister}>
          <h1 className={style['form-title']}>{intl.get('Custom.general.register')}</h1>
          <Form.Item className={style['form-item']}>
            {getFieldDecorator('username', {
              rules: [{
                required: true,
                message: intl.get('Custom.general.enterEmail'),
              }],
            })(
              <Input prefix={<Icon type="mail"/>} placeholder={intl.get('Custom.general.enterEmail')} autoFocus
                     onFocus={this.props.onInputFocus} onBlur={this.props.onInputBlur}/>
            )}
          </Form.Item>
          <Form.Item className={style['form-item']}>
            <div style={{lineHeight: '1.25em', paddingBottom: '3em'}}>
              {intl.get('Custom.general.haveAccount')} <a onClick={this.props.gotoLogin}>&lt;{intl.get('Custom.general.loginNow')}&gt;</a>
            </div>
            <Tooltip placement="right" title={<div>{intl.get('Custom.general.wechatLogin')}<br/>{intl.get('Custom.general.noNeedPassword')}</div>}>
              <Button
                shape="circle"
                className={`${style['form-button']} ${style['wechat-button']}`}
                onClick={this.props.loginViaWechat}
                style={{float: 'left'}}
              >
                <Icon type={'wechat'} />
              </Button>
            </Tooltip>
            <Button
              type="primary"
              htmlType="submit"
              loading={this.props.registerLoading}
              className={style['form-button']}
            >
              {intl.get('Custom.general.register')}
            </Button>
          </Form.Item>
        </Form>
      </AuthForm>
    );
  }
}

NormalRegisterForm.defaultProps = {
  onRegister: () => {
    console.log('onRegister property is not set.')
  },
  gotoLogin: (e) => {
    e.preventDefault();
    console.log('gotoLogin property is not set.')
  },
  loginViaWechat: (e) => {
    e.preventDefault();
    console.log('loginViaWechat property is not set.')
  },
  onInputFocus() {},
  onInputBlur() {},
  registerLoading: false,
};

NormalRegisterForm.propTypes = {
  onRegister: PropTypes.func,
  gotoLogin: PropTypes.func,
  loginViaWechat: PropTypes.func,
  onInputFocus: PropTypes.func,
  onInputBlur: PropTypes.func,
  registerLoading: PropTypes.bool,
};

const WrappedNormalRegisterForm = Form.create()(NormalRegisterForm);

export default WrappedNormalRegisterForm;
