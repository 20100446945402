import React from 'react';
import PropTypes from "prop-types";
import MyNetwork from '@/libs/myVis/MyNetwork3';
import ViewDataProvider from "@/components/common/dataProvider/common.dataProvider.view";

import PB, {SimplePB} from "@/libs/simplePB";
import {message, Tooltip} from 'antd';
import {compare} from "@/utils/PinYinCompare";

import style from '@/style/common/relation/common.relation.visualToolMini.less';
import Icon from "@/components/common/common.icon";
import {IconTypes} from "@/constants/common";
import QRCode from 'qrcode.react'
// ws socket.io
import io from 'socket.io-client'
import _ from "lodash";


class RemoteAction extends React.PureComponent {
  state = {
    remoteMsg: '', // 信息文字
    wsPaired: undefined, // 是否已经被远程控制配对（二维码被扫，并有反馈）
    wsClientId: undefined, // socket.io 的 client id，undefined=未运行，false=出错，string=已注册
    viewInDoing: null, // view 是否在何种操作中
  };
  // socket.io 服务器
  // wsSSLProtocol = false;
  // wsDomain = '192.168.199.117:9001';
  wsSSLProtocol = true;
  wsDomain = 'ws.joinmap.ai';
  
  // socket.io 客户端
  socket = undefined;
  viewId = this.props.viewProvider.viewInfo.viewId;
  viewName = this.props.viewProvider.viewInfo.name;
  
  componentDidMount() {
    let me = this;
    // console.log('RemoteAction componentDidMount:', this.props);
    // console.log('RemoteAction componentDidMount:', this.props.viewProvider.viewInfo);
    // this.connectWS();
    
    me.props.bus.with(me).subscribe('presentation', 'config.list.success', ({viewId, configList}) => {
      // console.log('RemoteAction componentDidMount viewId:', viewId);
      // console.log('RemoteAction componentDidMount configList:', configList);
      // 查找到全部节点名称
      configList.forEach((config, idx) => {
        let items = me.props.viewProvider.getNode(config['content'].nodeIds).filter(n => !!n)
        configList[idx]['newContent'] = [];
        
        items.forEach(item => {
          configList[idx]['newContent'].push({
            id: item.id,
            name: item.label,
          });
        })
      })
      
      if (me.socket) {
        me.socket.emit('storyList', configList);
      }
    });
    
  }
  
  
  componentWillUnmount() {
    // console.log('RemoteAction componentWillUnmount');
    if (this.socket) {
      this.socket.disconnect();
    }
    this.props.bus.remove(this);
  }
  
  // 连接远程控制
  connectWS = () => {
    let me = this;
    // 连接服务器, 得到与服务器的连接对象
    let wsUrl = this.wsSSLProtocol ? 'wss://' + this.wsDomain : 'ws://' + this.wsDomain;
    console.log('RemoteAction ws url: ', wsUrl);
    this.socket = io(wsUrl)
    // ws连接成功
    this.socket.on('registered', function (data) {
      console.log('RemoteAction registered ctrl url: ', me.wsSSLProtocol ? `https://${me.wsDomain}/jmctrl?from=qr&id=${data.data.clientId}` : `http://${me.wsDomain}/jmctrl?from=qr&id=${data.data.clientId}`);
      me.setState({wsPaired: undefined, wsClientId: data.data.clientId});
    });
    
    // 配对成功
    this.socket.on('paired', function (data) {
      // console.log('RemoteAction paired:', data);
      me.setState({wsPaired: true}, () => {
        message.success('已与遥控器配对！');
      });
    });
    
    // 配对断开
    this.socket.on('dispaired', function (data) {
      // console.log('RemoteAction dispaired:', data);
      me.setState({wsPaired: undefined, wsClientId: undefined}, () => {
        message.success('已与遥控器断开！');
      });
      me.socket.disconnect();
    });
    
    // 接受系统消息
    this.socket.on('message', function (data) {
      // console.log('RemoteAction message:', data);
      message.info(data.msg);
    });
    
    // 接受系统消息
    this.socket.on('action', function (params) {
      // console.log('RemoteAction action:', params);
      const {action, data} = params;
      const {bus, myNetwork} = me.props;
      
      myNetwork.network.stopSimulation();
      
      // 当又新的action传来是，仍然在播放节点
      // 则清除播放节点时留在页面上得文字和图片
      if (me.state.viewInDoing === 'playNode' || me.state.viewInDoing === 'schAndPlayNode') {
        // console.log('RemoteAction socket.on action --------------------------> clean effect');
        bus.emit('relation', 'node.presentation.stop');
        // bus.emit('relation', 'presentation.do_pause');
        bus.emit('network', 'node_tooltip.hide');
        // bus.emit('workspace', 'input.do_focus');
        // bus.emit('relation', 'presentation.stopped', {viewId: me.viewId});
        // bus.emit('relation', 'node.single_selection_change', undefined);
      }
      // bus.emit('relation', 'node.presentation.stop');
      // bus.emit('network', 'node_tooltip.hide');
      
      if (action == 'fit') {
        me.setState({viewInDoing: 'fit'}, () => {
          me.doViewAction('fit', '');
        });
      } else if (action == 'zoomin') {
        me.setState({viewInDoing: 'zoomin'}, () => {
          me.doViewAction('zoom', 'up');
        });
      } else if (action == 'zoomout') {
        me.setState({viewInDoing: 'zoomout'}, () => {
          me.doViewAction('zoom', 'down');
        });
      } else if (action == 'playNode') {
        // 播放节点
        me.setState({viewInDoing: 'playNode'}, () => {
          me.doViewAction('playNode', data);
        });
      } else if (action == 'getStories') {
        me.setState({viewInDoing: 'getStories'}, () => {
          // 获取故事线
          me.props.bus.emit('presentation', 'config.list.do', {viewId: me.props.viewProvider.viewInfo.viewId});
        });
      } else if (action == 'cmd') {
        // 手动输入或者语音输入的命令
        console.log('RemoteAction socket.on action= cmd');
        console.log('RemoteAction socket.on action= cmd data=', data);
        // 判断是否是支持的几类命令文字
        if (['全貌', '全局', '全部'].indexOf(data) > -1) {
          me.setState({viewInDoing: 'fit'}, () => {
            me.doViewAction('fit', '');
          });
        } else if (['放大', '大一点', '大一些', '放大一点', '放大一些'].indexOf(data) > -1) {
          me.setState({viewInDoing: 'zoomin'}, () => {
            me.doViewAction('zoom', 'up');
          });
        } else if (['缩小', '小一点', '小一些', '缩小一点', '缩小一些'].indexOf(data) > -1) {
          me.setState({viewInDoing: 'zoomout'}, () => {
            me.doViewAction('zoom', 'down');
          });
        } else if (['左移', '向左', '左一'].indexOf(data) > -1) {
          me.setState({viewInDoing: 'move'}, () => {
            me.doViewAction('move', 'left');
          });
        } else if (['右移', '向右', '右一'].indexOf(data) > -1) {
          me.setState({viewInDoing: 'move'}, () => {
            me.doViewAction('move', 'right');
          });
        } else if (['上移', '向上', '上一'].indexOf(data) > -1) {
          me.setState({viewInDoing: 'move'}, () => {
            me.doViewAction('move', 'up');
          });
        } else if (['下移', '向下', '下一'].indexOf(data) > -1) {
          me.setState({viewInDoing: 'move'}, () => {
            me.doViewAction('move', 'down');
          });
        } else {
          // 不是预设的命令,搜索节点并播放
          me.setState({viewInDoing: 'schAndPlayNode'}, () => {
            me.doViewAction('schAndPlayNode', data);
          });
        }
      } else if (action == 'move') {
        // 移动
        me.setState({viewInDoing: 'move'}, () => {
          me.doViewAction('move', data);
        });
      } else {
        // 不支持的 action
        me.socket.emit('message', '无效的操作');
      }
    });
    
    
    // ---------------------------------------------------------------
    // 2)注册
    // ---------------------------------------------------------------
    setTimeout(function () {
      me.socket.emit('register', {clientName: me.viewName});
    }, 500)
  }
  
  // 执行画面动作
  doViewAction = (action, data) => {
    let me = this;
    // console.log('RemoteAction doViewAction:', action, data);
    console.log('RemoteAction doViewAction this.props:', this.props);
    const {myNetwork, bus, viewProvider} = this.props;
    if (action === 'move') {
      myNetwork ? myNetwork.move(data) : bus.emit('network', action, data);
    } else if (action === 'fit') {
      myNetwork ? myNetwork.network.fit({animation: true}) : bus.emit('network', 'fit');
    } else if (action === 'zoom') {
      let t = data.toLowerCase();
      t = t.charAt(0).toUpperCase() + t.slice(1);
      myNetwork ? myNetwork.zoom(data) : bus.emit('network', 'zoom' + t);
    } else if (action === 'playNode') {
      // Todo:播放节点: 先暂停上次播放，隐藏上次播放得节点信息框
      bus.emit('relation', 'node.presentation.focus', ({
        node: {id: data},
        config: {title: '播放', content: {nodeIds: [data]}}
      }));
    } else if (action === 'schAndPlayNode') {
      // --------------------------------------
      // 与输入条统一的方案
      // 搜索节点的 fname tag 命中节点
      // 使用引力场查询
      // 合并上面两项结果
      // 按拼音排序
      // --------------------------------------
      // 节点字段过滤
      const searchFields = ['fname', 'tag'];
      let result = [];
      myNetwork.graph.nodes.forEach((n) => {
        searchFields.forEach(field => {
          if (n[field] && n[field].toLowerCase().indexOf(data.toLowerCase()) !== -1) {
            // 找到节点
            result.push(n);
          }
        })
      });
      
      // 排序
      let result_sorted = result.sort((v1, v2) => {
        return compare(v1, v2)
      });
      
      // 调用后台引力场查询
      viewProvider.smartSearch(data.toLowerCase()).then(nodes => {
        // console.log('后台引力场查询正常返回');
        console.log('RemoteAction 后台引力场查询正常返回，结果有效，nodes: ', nodes);
        // 引力结果排序
        let nodes_sorted = nodes.sort((v1, v2) => {
          return compare(v1, v2)
        });
        // console.log('RemoteAction 后台引力场查询排序后: ');
        // nodes_sorted.forEach((n, idx) => {
        //   console.log(idx + 1, '、', n.fname)
        // })
        // 合并
        result_sorted = result_sorted.concat(nodes_sorted);
        
        console.log('RemoteAction 查询结果，result: ');
        result_sorted.forEach((n, idx) => {
          console.log(idx + 1, '、' + n.fname)
        })
        
        if (result_sorted.length > 0) {
          // 播放第一个节点
          PB.emit('relation', 'node.presentation.focus', ({
            node: result_sorted[0],
            config: {title: '测试', content: {nodeIds: [result_sorted[0].id]}}
          }));
          
          // 发送到遥控段
          if (me.socket) {
            let schNodeList = [];
            result_sorted.forEach(n => {
              schNodeList.push({
                id: n.id,
                name: n.fname,
              })
            })
            schNodeList = schNodeList.slice(0, 50);
            me.socket.emit('schNodeList', schNodeList);
          }
        } else {
          me.socket.emit('message', '没有搜索到相关节点');
        }
      }).catch(() => {
        // 引力场返回错误，返回节点字段匹配结果
        // console.log('RemoteAction 后台引力场查询返回错误信息');
        if (result_sorted.length > 0) {
          // 播放第一个节点
          PB.emit('relation', 'node.presentation.focus', ({
            node: result_sorted[0],
            config: {title: '测试', content: {nodeIds: [result_sorted[0].id]}}
          }));
          // 发送到遥控段
          if (me.socket) {
            let schNodeList = [];
            result_sorted.forEach(n => {
              schNodeList.push({
                id: n.id,
                name: n.fname,
              })
            });
            schNodeList = schNodeList.slice(0, 50);
            me.socket.emit('schNodeList', schNodeList);
          }
        } else {
          me.socket.emit('message', '没有搜索到相关节点');
        }
      });
    } else {
      // 不支持的action
    }
  }
  
  render() {
    let me = this;
    const {showMode} = this.props;
    const {wsPaired, wsClientId} = this.state;
    
    
    let contentJSX = null;
    let tipTxt = null; // 提示文字
    
    // remoteCtrl 远程控制的显示代码
    let remoteJSX = null;
    
    if (wsClientId === undefined) {
      remoteJSX = null;
      // tipTxt = <span><a onClick={() => {
      //   me.connectWS();
      //   me.setState({wsPaired: false});
      // }}>手机扫码远程控制</a></span>;
    } else if (wsClientId === false) {
      remoteJSX = '远程遥控配对失败'
    } else {
      if (!wsPaired) {
        remoteJSX = <QRCode
          style={{width: '98%', height: 'auto', margin: '0 auto'}}
          value={me.wsSSLProtocol ? "https://" + me.wsDomain + "/jmctrl?from=qr&id=" + wsClientId : "http://" + me.wsDomain + "/jmctrl?from=qr&id=" + wsClientId}
        />
        
        // <br/> <a href={"http://" + me.wsDomain + "/jmctrl?from=qr&id=" + wsClientId} target="_blank">网页版</a>
        tipTxt = (
          <span>请用微信扫码打开遥控器
            <br/><a onClick={() => {
              if (me.socket) {
                me.socket.disconnect();
              }
              me.setState({wsPaired: false, wsClientId: undefined});
            }}>取消</a>
        </span>
        );
      } else {
        remoteJSX = false;
        tipTxt = <span><a onClick={() => {
          if (me.socket) {
            me.socket.disconnect();
          }
          me.setState({wsPaired: false, wsClientId: undefined});
        }}>断开远程遥控</a></span>;
      }
    }
    
    // 本地遥控按钮jsx
    let localJSX = (<>
      <table style={{width: '100%', height: '182px', textAlign: 'center', border: "0px solid #008080"}}
             border={0}>
        <tbody>
        <tr style={{height: 0}}>
          <td style={{width: '16.666666%'}}></td>
          <td style={{width: '16.666666%'}}></td>
          <td style={{width: '16.666666%'}}></td>
          <td style={{width: '16.666666%'}}></td>
          <td style={{width: '16.666666%'}}></td>
          <td style={{width: '16.666666%'}}></td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td colSpan="2">
            <Tooltip title={'画面上移'} trigger='hover' placement="top">
              <div
                className={style['move-btn']}
                onClick={() => {
                  me.doViewAction('move', 'up');
                }}
              >
                <Icon type={IconTypes.ICON_FONT} name={'icon-m-up'}/>
              </div>
            </Tooltip>
          </td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td colSpan="2">
            <Tooltip title={'画面左移'} trigger='hover' placement="top">
              <div
                className={style['move-btn']}
                onClick={() => {
                  me.doViewAction('move', 'left');
                }}
              >
                <Icon type={IconTypes.ICON_FONT} name={'icon-m-left'}/>
              </div>
            </Tooltip>
          </td>
          <td colSpan="2">
            <div
              className={style['move-btn']}
              onClick={() => {
                me.doViewAction('fit', '');
              }}
            >
              <Tooltip title={'显示所有节点'} trigger='hover' placement="top">
                <Icon type={IconTypes.ICON_FONT} name={'icon-circle'}/>
              </Tooltip>
            </div>
          </td>
          <td colSpan="2">
            <Tooltip title={'画面右移'} trigger='hover' placement="top">
              <div
                className={style['move-btn']}
                onClick={() => {
                  me.doViewAction('move', 'right');
                }}
              >
                <Icon type={IconTypes.ICON_FONT} name={'icon-m-right'}/>
              </div>
            </Tooltip>
          </td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td colSpan="2">
            <Tooltip title={'画面下移'} trigger='hover' placement="top">
              <div
                className={style['move-btn']}
                onClick={() => {
                  me.doViewAction('move', 'down');
                }}
              >
                <Icon type={IconTypes.ICON_FONT} name={'icon-m-down'}/>
              </div>
            </Tooltip>
          </td>
          <td></td>
          <td></td>
        </tr>
        </tbody>
      </table>
      <br/>
      <table style={{width: '100%', height: '48px', textAlign: 'center', border: "0px solid #008080"}} border={0}>
        <tbody>
        <tr style={{height: 0}}>
          <td style={{width: '16.666666%'}}></td>
          <td style={{width: '16.666666%'}}></td>
          <td style={{width: '16.666666%'}}></td>
          <td style={{width: '16.666666%'}}></td>
          <td style={{width: '16.666666%'}}></td>
          <td style={{width: '16.666666%'}}></td>
        </tr>
        <tr>
          <td colSpan="2">
            <div
              className={style['move-btn']}
              onClick={() => {
                me.doViewAction('zoom', 'up');
              }}
            >
              <Tooltip title={'放大'} trigger='hover' placement="top">
                <Icon name={'zoom-in'}/>
              </Tooltip>
            </div>
          </td>
          <td colSpan="2">
            <div
              className={style['move-btn']}
              onClick={() => {
                me.connectWS();
                me.setState({wsPaired: false});
              }}
            >
              <Tooltip title={'手机扫码远程控制'} trigger='hover' placement="top">
                <Icon type={IconTypes.ICON_FONT} name={'icon-shoujiyuancheng'}/>
              </Tooltip>
            </div>
          </td>
          <td colSpan="2">
            <div
              className={style['move-btn']}
              onClick={() => {
                me.doViewAction('zoom', 'down');
              }}
            >
              <Tooltip title={'缩小'} trigger='hover' placement="top">
                <Icon name={'zoom-out'}/>
              </Tooltip>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </>);
    
    if (showMode === 'localCtrl') {
      tipTxt = "";
      contentJSX = localJSX;
      // } else if (showMode === 'remoteCtrl') {
      //   // do nothing
      //   contentJSX = remoteJSX ? remoteJSX : localJSX;
    } else {
      // 默认 本地+远程 都显示
      contentJSX = remoteJSX ? remoteJSX : localJSX;
    }
    
    return (
      <div style={{width: 200, height: 260, textAlign: 'center'}}>
        {contentJSX ? contentJSX : ("")}
        <br/>
        {tipTxt ? tipTxt : ''}
      </div>
    );
  }
}

RemoteAction.defaultProps = {
  bus: PB,
  myNetwork: undefined,
  viewProvider: undefined,
};

RemoteAction.propTypes = {
  bus: PropTypes.instanceOf(SimplePB),
  myNetwork: PropTypes.instanceOf(MyNetwork),
  viewProvider: PropTypes.instanceOf(ViewDataProvider),
};

export default RemoteAction;
