/**
 * TODO: 位置移动到 common 目录中
 * 与看板数据无关的组件
 */
import React from "react";
import PropTypes from "prop-types";
import { Tooltip, Menu, Badge, Button } from "antd";

import PB, { SimplePB } from "@/libs/simplePB";
import ViewDataProvider from "@/libs/view/network/ViewDataProvider";
import {
  ADD_TO_VIEW,
  NetworkEvents,
  NodeEvents,
  REMOVE_FROM_VIEW,
} from "@/libs/view/network/events";
import Icon from "@/components/common/common.icon";
import NodeDataProvider, {
  overrideNextMessage,
} from "@/components/common/dataProvider/common.dataProvider.node";
import { AvatarColors, IconTypes } from "@/constants/common";

import styles from "@/style/components/mainView/main.badgeInfo.less";
import UserAvatar from "react-user-avatar";
import { getHttpUtil, getToken, REQUEST_BASE } from "@/utils/HttpUtil";
import AccountActions from "@/components/common/common.accountActions";
import AccountActionsLogSummary from "@/components/common/common.accountActions.logSummary";
import AccountActionsBadgeLatest from "@/components/common/common.accountActions.badgeLatest";
import NodeTodoAccountActions from "@/components/common/node/todo/common.node.todo.accountActions";
import SearchInputBar from "@/components/common/common.searchInputBar";
import UserAiNotices from "@/components/common/common.account.userAiNotices";
import { aidDomain } from "@/constants/sys.config";
import intl from 'react-intl-universal';

const httpUtil2 = getHttpUtil(false, aidDomain);
const API_CheckUserNewNotices = ({ user_id }) => {
  return httpUtil2.get(`/notice_api/check`, { user_id });
};

class BadgeInfo extends React.Component {
  state = {
    lastViewScore: -1,
    currentViewScore: -1,
    lastTotalScore: -1,
    currentTotalScore: -1,
    showChangePwdModal: false, // 是否显示修改密码弹框
    showBasicInfoModal: false, // 是否显示个人信息弹框
    showWeChatBindingModal: false, // 是否显示绑定微信弹框
    showEmailBindingModal: false, // 是否显示绑定邮箱弹框
    showStickyNodesWindow: false, // 是否显示便签窗口
    hasChangeInStickyNodes: false, // 便签窗口内容是否有变化
    newNoticeCount: 0, // 消息中心是否有新消息
  };

  logSummaryRef = undefined;
  badgeLatestRef = undefined;

  checkNewNotices = () => {
    const me = this;
    // 从 localstorage 中取出 user id
    const userId = localStorage.getItem("userId");
    // 请求数据
    API_CheckUserNewNotices({ user_id: userId })
      .then((res) => {
        console.log("API_CheckUserNewNotices=", res.data);
        if (res && res.data && res.data.code === 0) {
          // 接口返回正常
          let value = res.data.data || 0;
          me.setState({
            newNoticeCount: value,
          });
        } else {
          // 获取数据错误
          me.setState({
            newNoticeCount: 0,
          });
        }
      })
      .catch();
  };

  componentDidMount() {
    let me = this;

    me.checkNewNotices();

    if (me.props.viewDataProvider) {
      me.props.viewDataProvider
        .with(me)
        .subscribe(NetworkEvents.LOADING_DATA_SUCCESS, () => {
          const {
            data: { nodes },
          } = me.props.viewDataProvider.getData();
          let currentViewScore = 0;
          nodes.forEach((node) =>
            node.userId === me.props.currentUserInfo.userId
              ? currentViewScore++
              : null
          );
          me.setState({
            lastViewScore: 0,
            currentViewScore,
          });
        })
        .subscribe(NodeEvents.ADDED, (type, addedNodeIds, addedNodes) => {
          // noinspection JSBitwiseOperatorUsage
          if (type & ADD_TO_VIEW) {
            // 节点被添加到图谱中
            let currentViewScore = me.state.currentViewScore,
              currentTotalScore = me.state.currentTotalScore;
            addedNodes.forEach((node) =>
              node.userId === me.props.currentUserInfo.userId
                ? currentViewScore++ && currentTotalScore++
                : null
            );
            me.setState({
              lastViewScore: me.state.currentViewScore,
              currentViewScore:
                me.state.currentViewScore < 0 ? -1 : currentViewScore,
              lastTotalScore: me.state.currentTotalScore,
              currentTotalScore:
                me.state.currentTotalScore < 0 ? -1 : currentTotalScore,
            });

            // 获取图谱操作记录统计
            me.logSummaryRef.getViewActionLogSummary();
          }
        })
        .subscribe(NodeEvents.REMOVED, (type, nodeInfoList) => {
          // noinspection JSBitwiseOperatorUsage
          if (type & REMOVE_FROM_VIEW) {
            // 节点被添加到图谱中
            let currentViewScore = me.state.currentViewScore,
              currentTotalScore = me.state.currentTotalScore;
            nodeInfoList.forEach((node) =>
              node.userId === me.props.currentUserInfo.userId
                ? currentViewScore-- && currentTotalScore--
                : null
            );
            me.setState({
              lastViewScore: me.state.currentViewScore,
              currentViewScore:
                me.state.currentViewScore < 0 ? -1 : currentViewScore,
              lastTotalScore: me.state.currentTotalScore,
              currentTotalScore:
                me.state.currentTotalScore < 0 ? -1 : currentTotalScore,
            });

            // 获取图谱操作记录统计
            me.logSummaryRef.getViewActionLogSummary();
          }
        })
        .subscribe(NodeEvents.UPDATED, () => {
          // 获取图谱操作记录统计
          me.logSummaryRef.getViewActionLogSummary();
        })
        .subscribe(NodeEvents.BADGE_SET_SUCCESS, () => {
          // 获取图谱相关的用户奖章
          me.badgeLatestRef.getViewBadgeInfo();
        });
    } else {
      me.setState({
        currentViewScore: "--",
      });
    }

    let loadNodeAmountStatisticsFn = () => {
      overrideNextMessage("static::batchLoadNodeAmountStatistics", false);
      NodeDataProvider.batchLoadNodeAmountStatistics([
        {
          conditions: {
            userId: me.props.currentUserInfo.userId,
            userConfirmed: 1,
          },
        },
      ])
        .then(([{ amount }]) => {
          me.setState({ lastTotalScore: 0, currentTotalScore: amount || 0 });
        })
        .catch(() => {
          setTimeout(loadNodeAmountStatisticsFn, 10000);
        });
    };
    // 获取全图积分注释掉2022-08-23
    //loadNodeAmountStatisticsFn();

    // 便签小窗口显示隐藏
    me.props.bus.sub(this, "user", "stickyNodes.show", ({ show }) => {
      me.setState({ showStickyNodesWindow: show });
    });
    // 便签小窗口内容有变化
    me.props.bus.sub(
      this,
      "user",
      "stickyNodes.hasChange",
      ({ hasSave = false, hasUpdate = false }) => {
        me.setState({ hasChangeInStickyNodes: hasSave || hasUpdate });
      }
    );

    // console.log(' main.badgeInfo -> componentsDidMount :', me.props.history);
    if (!me.props.history.location) {
      // 地理视图和时间视图history无内容，此时本图积分不显示
      me.setState({ currentViewScore: -2 });
    }
  }

  componentWillUnmount() {
    if (this.props.viewDataProvider) {
      this.props.viewDataProvider.unSubscribe(this);
    }

    this.props.bus.remove(this);
  }

  render() {
    const { className, style, viewDataProvider } = this.props;
    let me = this;
    let viewId =
      viewDataProvider && viewDataProvider.viewInfo
        ? viewDataProvider.viewInfo.viewId
        : undefined;

    return (
      <div
        id={"mainBadgeInfo"}
        className={`${className ? className : ""} dark-theme`}
        style={style ? style : {}}
        onContextMenu={() => {
          window.event.returnValue = false;
          return false;
        }}
      >
        <UserAiNotices
          bus={me.props.bus}
          history={me.props.history}
        ></UserAiNotices>
        <SearchInputBar
          history={me.props.history}
          userInfo={me.props.currentUserInfo}
          pathname={me.props.history.location.pathname}
          params={me.props.params}
        />
        <Tooltip title={intl.get('Custom.general.help')}>
          <div
            className={`${styles["badge-more-view"]} ${styles["has-border"]}`}
            onClick={() => {
              window.open('/mainview/relation/74a712e3-007c-4bea-bbd0-c9c0ef504f53', '_blank');
            }}
          >
            <Icon
              name={"icon-help"}
              type={IconTypes.ICON_FONT}
              className={`${styles["icon"]}`}
            />
          </div>
        </Tooltip>
        <Tooltip title={intl.get('Custom.general.feedback')}>
          <div
            className={`${styles["badge-more-view"]} ${styles["has-border"]}`}
            onClick={() => {
              me.props.bus.emit("feedback", "new", "");
            }}
          >
            <Icon
              name={"icon-kefu"}
              type={IconTypes.ICON_FONT}
              className={`${styles["icon"]}`}
            />
          </div>
        </Tooltip>{intl.get('locale')==='zh-ch' &&
        <Tooltip title={intl.get('Custom.general.operationGuide')}>
          <div
            id="badge_operate_help"
            className={`${styles["badge-more-view"]} ${styles["has-border"]}`}
            onClick={() => {
              window.open(
                "/mainview/relation/74a712e3-007c-4bea-bbd0-c9c0ef504f53",
                "_blank"
              );
            }}
          >
            <Icon name={"question-circle"} className={`${styles["icon"]}`} />
          </div>
        </Tooltip>}
        <Tooltip title={intl.get('Custom.general.projectVideo')}>
          <div
            id="project_video"
            className={`${styles["badge-more-view"]} ${styles["has-border"]}`}
            onClick={() => {
              window.open(
                "/mainview/dashboard/video",
                "_blank"
              );
            }}
          >
            <Icon name={"icon-record-video"} type={IconTypes.ICON_FONT} className={`${styles["icon"]}`} style={{fontSize:'1.5rem'}} />
          </div>
        </Tooltip>
        {false &&
        <Tooltip
          title={
            me.state.hasChangeInStickyNodes
              ? intl.get('Custom.general.note.changer')
              : `${
                  me.state.showStickyNodesWindow
                    ? intl.get('Custom.general.note.hidewindow')
                    : intl.get('Custom.general.note.showwindow')
                }`
          }
        >
          <div
            className={`${styles["badge-more-view"]} ${styles["has-border"]}`}
            onClick={() => {
              me.props.bus.emit("user", "stickyNodes.switch", {
                show: !me.state.showStickyNodesWindow,
              });
            }}
          >
            <Badge dot={me.state.hasChangeInStickyNodes}>
              <Icon
                name={"icon-biaoshilei_jihua"}
                type={IconTypes.ICON_FONT}
                className={`${styles["icon"]}`}
                style={{
                  color: me.state.showStickyNodesWindow ? "#1890ff" : "",
                }}
              />
            </Badge>
          </div>
        </Tooltip>}
        <Tooltip title={intl.get('Custom.general.message')}>
          <div
            className={`${styles["badge-more-view"]} ${styles["has-border"]}`}
            onClick={() => {
              window.open(`/notice`, "_blank");
            }}
          >
            <Badge dot={!!me.state.newNoticeCount}>
              <Icon
                name={"icon-xiaoxi"}
                type={IconTypes.ICON_FONT}
                className={`${styles["icon"]}`}
              />
            </Badge>
          </div>
        </Tooltip>
        <NodeTodoAccountActions
          viewId={viewId}
          userId={me.props.currentUserInfo.userId}
        />
        <AccountActionsLogSummary
          ref={(ele) => {
            if (!ele || ele === me.logSummaryRef) return;
            me.logSummaryRef = ele;
          }}
          userInfo={me.props.currentUserInfo}
        />
        <AccountActionsBadgeLatest
          ref={(ele) => {
            if (!ele || ele === me.badgeLatestRef) return;
            me.badgeLatestRef = ele;
          }}
          userInfo={me.props.currentUserInfo}
          style={{ margin: 0 }}
        />
        <AccountActions
          extraMenuItems={[
            <Menu.Item
              key={"user-basic-integral"}
              onClick={() => {
                me.props.bus.emit("teamwork", "member.node_panel.show", {
                  memberId: me.props.currentUserInfo.userId,
                });
              }}
            >
              <div className={`${styles["avatar-tooltip"]} dark-theme`}>
                <table style={{ fontSize: 12 }}>
                  <tbody>
                    <tr>
                      <td style={{ paddingRight: "0.25em" }}>{intl.get('Custom.general.thisIntegral')}：</td>
                      <td style={{ textAlign: "right" }}>
                        {me.state.currentViewScore >= 0 ? (
                          me.state.currentViewScore
                        ) : me.state.currentViewScore === -1 ? (
                          <span className={"ellipsis-anim"}>
                            <span>·</span>
                            <span>·</span>
                            <span>·</span>
                          </span>
                        ) : (
                          "--"
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ paddingRight: "0.25em" }}>{intl.get('Custom.general.allIntegral')}：</td>
                      <td style={{ textAlign: "right" }}>
                        {me.state.currentTotalScore >= 0 ? (
                          me.state.currentTotalScore
                        ) : (
                          <span className={"ellipsis-anim"}>
                            <span>·</span>
                            <span>·</span>
                            <span>·</span>
                          </span>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Menu.Item>,
          ]}
          userInfo={me.props.currentUserInfo}
          changePwdStatus={me.props.changePwdStatus}
          onLogout={me.props.onLogout}
          doChangePwd={me.props.doChangePwd}
          resetChangePwdStatus={me.props.resetChangePwdStatus}
          changeUserBasicInfoStatus={me.props.changeUserBasicInfoStatus}
          onSaveUserBasicInfo={me.props.onSaveUserBasicInfo}
        >
          <span id="badge_user_avatar" className={styles["badge-info-user"]}>
            <div
              className={`${styles["avatar"]} ${
                me.props.currentUserInfo.picId
                  ? ""
                  : styles["avatar-default-icon"]
              }`}
              onClick={() => {
                //  me.props.bus.emit('account', 'user_center.show_modal');
              }}
            >
              <UserAvatar
                size={"var(--avatar-size)"}
                name={me.props.currentUserInfo.nick}
                src={
                  me.props.currentUserInfo.picId
                    ? `${REQUEST_BASE}/user/user/file/${
                        me.props.currentUserInfo.picId
                      }?Authorization=${getToken()}`
                    : undefined
                }
                colors={AvatarColors}
              />
            </div>
            {/*
						{
							me.state.currentViewScore < 0 ? (
								<span className={'ellipsis-anim'}><span>·</span><span>·</span><span>·</span></span>
							) : (
								<CountUp
									start={me.state.lastViewScore}
									end={me.state.currentViewScore}
									delay={me.state.lastViewScore === 0 ? 0 : 1.5}
									duration={1.5}
								/>
							)
						}
						<span style={{margin: '0 0.4em'}}>/</span>
						{
							me.state.currentTotalScore < 0 ? (
								<span className={'ellipsis-anim'}><span>·</span><span>·</span><span>·</span></span>
							) : (
								<CountUp
									start={me.state.lastTotalScore}
									end={me.state.currentTotalScore}
									delay={me.state.lastTotalScore === 0 ? 0 : 1.5}
									duration={1.5}
								/>
							)
						}*/}
          </span>
        </AccountActions>
      </div>
    );
  }
}

BadgeInfo.defaultProps = {
  style: {},
  className: styles["container"],
  bus: PB,
  currentUserInfo: { userId: parseInt(localStorage.getItem("userId")) },
  doChangePwd: (oldPwd, newPwd) => {
    console.log(
      "doChangePwd property is not set, old password: %s, new password: %s.",
      oldPwd,
      newPwd
    );
  },
  params:{}
};

BadgeInfo.propTypes = {
  history: PropTypes.object, // router的参数
  style: PropTypes.object,
  className: PropTypes.string,
  bus: PropTypes.instanceOf(SimplePB),
  currentUserInfo: PropTypes.object,
  viewDataProvider: PropTypes.instanceOf(ViewDataProvider),
  doChangePwd: PropTypes.func,
  changeUserBasicInfoStatus: PropTypes.number,
  onSaveUserBasicInfo: PropTypes.func,
  params:PropTypes.object // 一些非必须参数
};

export default BadgeInfo;
