import React from 'react';
import PropTypes from 'prop-types';

import PB, {SimplePB} from '@/libs/simplePB';

import {NODE_TYPE_COMPANY} from '@/constants/nodeConfig';

import ViewDataProvider from '@/components/common/dataProvider/common.dataProvider.view';
import ExploreCommonRecommendDetailLogic
  from "@/components/common/view/explore/common.view.explore.common.recommendDetail.logic";

class ExploreCompanyRecommendDetailLogic extends React.PureComponent {
  render() {
    let me = this;

    return (
      <ExploreCommonRecommendDetailLogic
        exploreEventCategory={'explore_company'}
        exploreKey={'company'}
        exploreType={NODE_TYPE_COMPANY}
        {...me.props}
      />
    );
  }
}

ExploreCompanyRecommendDetailLogic.defaultProps = {
  bus: PB,
};

ExploreCompanyRecommendDetailLogic.propTypes = {
  viewDataProvider: PropTypes.instanceOf(ViewDataProvider).isRequired,
  userId: PropTypes.number.isRequired,
  bus: PropTypes.instanceOf(SimplePB),
};

export default ExploreCompanyRecommendDetailLogic;