// 库
import React from 'react';
import PropTypes from 'prop-types';

// ECharts 主模块ap.json
// noinspection SpellCheckingInspection
import echarts from 'echarts/lib/echarts';
// ECharts 柱状图、饼图、折线图
import 'echarts/lib/chart/bar';
import 'echarts/lib/chart/pie';
import 'echarts/lib/chart/line';
// ECharts 提示框和标题组件
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/title';
import 'echarts/map/js/china.js'; // 百度自带的完整中国地图
import 'echarts/map/js/world.js'; // 百度自带的完整世界地图
// css
import style from '@/style/containers/exploreRelationView.less';
// 组件
import {message} from 'antd';
import {
  simplifiedChinaProvinceADCMap,
  unknownChinaADC,
} from "@/components/common/common.functions";
import {getNodeDisplayTitle} from "@/constants/vis.defaultDefine.1";
import {FilterAndStatisticsBus} from "@/components/common/common.timestampAndLocationStatistics";

// 缩放比例 1-3，放大默认3
const geoCoordMapOfProv = {
  '000000': ['106.55', '35.36',  1], // 全国
  '110000': ['116.46', '39.92',  9], // 北京
  '120000': ['117.20', '39.13',  9], // 天津
  '130000': ['114.48', '38.03',  3], // 河北
  '140000': ['112.53', '37.87',  3], // 山西
  '150000': ['111.65', '40.82',  3], // 内蒙古
  '210000': ['123.38', '41.80',  3], // 辽宁
  '220000': ['125.35', '43.88',  3], // 吉林
  '230000': ['126.63', '45.75',  3], // 黑龙江
  '310000': ['121.48', '31.22', 10], // 上海
  '320000': ['118.78', '32.04',  5], // 江苏
  '330000': ['120.19', '30.26',  3], // 浙江
  '340000': ['117.27', '31.86',  3], // 安徽
  '350000': ['119.30', '26.08',  3], // 福建
  '360000': ['115.89', '28.68',  3], // 江西
  '370000': ['117.02', '36.68',  3], // 山东
  '410000': ['113.65', '34.76',  3], // 河南
  '420000': ['114.31', '30.52',  3], // 湖北
  '430000': ['113.00', '28.21',  3], // 湖南
  '440000': ['113.23', '23.16',  3], // 广东
  '450000': ['108.36', '22.79',  3], // 广西
  '460000': ['110.35', '20.02',  5], // 海南
  '500000': ['106.54', '29.59',  5], // 重庆
  '510000': ['104.06', '30.67',  3], // 四川
  '520000': ['106.71', '26.57',  3], // 贵州
  '530000': ['102.73', '25.04',  3], // 云南
  '540000': [ '91.11', '29.97',  3], // 西藏
  '610000': ['108.95', '34.27',  3], // 陕西
  '620000': ['103.73', '36.03',  3], // 甘肃
  '630000': ['101.74', '36.56',  3], // 青海
  '640000': ['106.27', '38.47',  3], // 宁夏
  '650000': [ '87.63', '43.79',  3], // 新疆
  '710000': ['121.50', '25.14',  3], // 台湾
  '810000': ['114.10', '22.20',  3], // 香港
  '820000': ['113.33', '22.13',  3], // 澳门
  [unknownChinaADC]: ['106.55', '35.36',  1], // 未知，直接定位到全国
};

// 各个省统计数据提示的坐标
const statisticTipArr = () => {
  const result = [];
  Object.keys(simplifiedChinaProvinceADCMap).forEach(chinaADC => {
    if (chinaADC === '000000' || chinaADC === unknownChinaADC) return;
    result.push({
      name: simplifiedChinaProvinceADCMap[chinaADC],
      china_adc: chinaADC,
      value: 0,
    });
  });
  return result;
};

/**
 * 用echarts画json地图，并且做个动态的打点效果
 * 动态数据来源：父级传入，
 */
class MapPanel extends React.Component {
  // 各地区数量统计数据
  regionsStatistic = statisticTipArr();

  state = {
    // 鼠标悬浮的节点
    nodeOnMouseHover: undefined,
    // 列表展示的nodes
    nodesForList: [],
    // 列表展示的省名
    titleForList: '',
  };

  // 容器id
  containerId = `resource-timeline-${Math.random()}`;

  // DOM容器
  container = undefined;

  // ECharts实例
  myChart = undefined;

  // 当前ECharts配置
  currentOption = undefined;

  // 数据过滤总线
  /**
   * @type {FilterAndStatisticsBus}
   */
  bus = new FilterAndStatisticsBus();

  // --- 数据 -----------

  defaultOptionFun = () => {
    let me = this;
    this.regionsStatistic = statisticTipArr();
    return {
      geo: {
        // map: 'China',
        map: 'china',
        // silent: true, // 响应鼠标事件
        roam: false, // 开启鼠标缩放、平移
        center: geoCoordMapOfProv['000000'],
        label: {
          position: 'bottom',
          distance: 5,
          normal: {
            show: false,
          },
          emphasis: {
            show: false,
            fontSize: 24,
            color: '#f8f8f8',
          },
        },
        // 地图区域的多边形 图形样式。
        itemStyle: {
          normal: {
            // areaColor: '#545454',
            areaColor: 'rgba(0,0,0,0)',
            borderColor: 'rgba(161,161,161,0.49)',
          },
          emphasis: {
            // areaColor: '#ffffbf'
            areaColor: '#1890ff',
          },
        },
      },
      series: [
        {
          name: '区域',// 不能改
          type: 'map',
          // mapType: 'china',
          geoIndex: 0, // 公用geo的地图
          roam: false,
          data: statisticTipArr(),
        },
        {
          name: '产业资源',// 不能改
          // type: 'effectScatter',
          type: 'scatter',
          coordinateSystem: 'geo',
          geoIndex: 0, // 公用geo的地图
          data: [],
          symbolSize: 8,
          rippleEffect: {
            scale: 2,
          },
          label: {
            show: false,
            position: [0, 0],
            formatter: function (params) {
              return params.name;
              // return params.name + ' : ' + params.value[2];
            },
          },
          itemStyle: {
            normal: {
              opacity: 0.8,
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowOffsetY: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
              color: new echarts['graphic'].RadialGradient(0.4, 0.3, 1, [{
                offset: 0,
                // color: 'rgb(251, 118, 123)'
                color: '#f6f6f6',
              }, {
                offset: 1,
                // color: 'rgb(204, 46, 72)'
                color: '#9b9596',
              }]),
            },
            emphasis: {
              borderColor: '#f8f8f8',
              borderWidth: 3,
            },
          },
        },
        {
          z: 10, // 数据列的显示属性，越大越往前
          name: "统计数据", // name不能改
          type: 'custom',//配置显示方式为用户自定义
          coordinateSystem: 'geo',
          itemStyle: {
            normal: {
              color: '#46bee9',
            },
          },
          renderItem: function (params, api) {//具体实现自定义图标的方法
            if (me.regionsStatistic[params.dataIndex] &&
              me.regionsStatistic[params.dataIndex].value &&
              geoCoordMapOfProv[me.regionsStatistic[params.dataIndex].china_adc])
            {
              return {
                type: 'text',
                style: {
                  text: me.regionsStatistic[params.dataIndex].name + ':' + me.regionsStatistic[params.dataIndex].value,
                  fill: '#f8f8f8',
                  font: 'bolder 1em "Microsoft YaHei",sans-serif',
                  stroke: '#46bee9',
                  x: api.coord(geoCoordMapOfProv[me.regionsStatistic[params.dataIndex].china_adc])[0],
                  y: api.coord(geoCoordMapOfProv[me.regionsStatistic[params.dataIndex].china_adc])[1],
                },
                styleEmphasis: {
                  text: me.regionsStatistic[params.dataIndex].name + ':' + me.regionsStatistic[params.dataIndex].value,
                  fill: '#f8f8f8',
                  font: 'bolder 2em "Microsoft YaHei",sans-serif',
                  stroke: '#46bee9',
                  x: api.coord(geoCoordMapOfProv[me.regionsStatistic[params.dataIndex].china_adc])[0],
                  y: api.coord(geoCoordMapOfProv[me.regionsStatistic[params.dataIndex].china_adc])[1],
                },
              }
            }
            return null

          },
          data: me.regionsStatistic,
        },
      ],
    }
  };

  /**
   * 画全部节点
   * @param {array} nodes 含有有效分类字段值的节点数组
   * @param {string} positionPath 含有有效分类字段值的节点数组
   */
  drawAll = (nodes = this.bus.nodes, positionPath = this.props.positionFieldKey) => {
    this.regionsStatistic = Object.keys(this.bus.statistics.chinaADC).filter(
      chinaADC => chinaADC !== '000000' && chinaADC !== unknownChinaADC
    ).map(chinaADC => {
      return {
        name: this.bus.statistics.chinaADC[chinaADC].shortName,
        china_adc: chinaADC,
        value: this.bus.statistics.chinaADC[chinaADC].amount,
      };
    });

    // 转换成ECharts的数据格式
    const convertData = function (nodes) {
      let res = [];
      if (Array.isArray(nodes)) {
        nodes.forEach(node => {
          // position值 转为数组
          let value = [];
          let position = _.get(node, positionPath);
          if (position) {
            if (!Array.isArray(position)) {
              value = position.split(',');
            } else {
              value = position;
            }
          } else {
            value = [0, 0];
          }
          if (value.length > 1) {
            res.push({
              name: node.fname,
              value: [value[0], value[1]],
              node: node,
            });
          } else {
            console.info('节点转换的坐标不正确：', node);
          }
        });
      }
      return res;
    };

    this.currentOption.series.forEach((series, index) => {
      if (series.name === '区域') {
        this.currentOption.series[index].data = this.regionsStatistic; // 地图各省颜色
      } else if (series.name === '产业资源') {
        this.currentOption.series[index].data = convertData(nodes); // 打点数据
      } else if (series.name === '统计数据') {
        this.currentOption.series[index].data = this.regionsStatistic; // 地图各省显示统计数据
      }
    });

    this.myChart.setOption(this.currentOption);
  };

  // --- 交互 -----------

  /**
   * 对全部数据，切换到某个区域
   * 关于省份名字：数据过滤用的是全称，echarts触发用的是短名称
   */
  switchToRegion = ({chinaADC}) => {
    if (chinaADC === undefined && chinaADC === null) {
      message.warning('请选择一个区域！');
      return;
    }

    // 设置放大地图区域
    if (geoCoordMapOfProv[chinaADC]) {
      this.currentOption.geo.center = geoCoordMapOfProv[chinaADC];
      this.currentOption.geo.zoom = geoCoordMapOfProv[chinaADC][2];
    } else {
      this.currentOption.geo.center = undefined;
      this.currentOption.geo.zoom = 1 // 缩放等级
    }

    // 区域内的节点 画图
    this.drawAll();

    // 地图对应的动作，目前只能对应省份
    this.myChart.dispatchAction({
      type: 'downplay',
      // 可选，系列 index，可以是一个数组指定多个系列
      // seriesIndex?: number|Array,
      // 可选，系列名称，可以是一个数组指定多个系列
      seriesName: ['区域', '产业资源', '统计数据'],
      // 可选，数据的 index
      // dataIndex?: number,
      // 可选，数据的 名称
      // name?: string
    });
    if (chinaADC !== '000000' && chinaADC !== unknownChinaADC) {
      this.myChart.dispatchAction({
        type: 'highlight',
        // 可选，系列 index，可以是一个数组指定多个系列
        // seriesIndex?: number|Array,
        // 可选，系列名称，可以是一个数组指定多个系列
        seriesName: ['区域', '统计数据'],
        // seriesName: ['统计数据'],
        // 可选，数据的 index
        // dataIndex?: number,
        // 可选，数据的 名称
        name: simplifiedChinaProvinceADCMap[chinaADC], // 目前只支持省
      });
    }

    this.forceUpdate();
  };

  // 地图上高亮节点
  focusNode = (node) => {
    this.myChart.dispatchAction({
      type: 'downplay',
      // 可选，系列 index，可以是一个数组指定多个系列
      // seriesIndex?: number|Array,
      // 可选，系列名称，可以是一个数组指定多个系列
      seriesName: ['产业资源'],
      // 可选，数据的 index
      // dataIndex?: number,
      // 可选，数据的 名称
      // name?: string
    });
    if (node) {
      this.myChart.dispatchAction({
        type: 'highlight',
        // 可选，系列 index，可以是一个数组指定多个系列
        // seriesIndex?: number|Array,
        // 可选，系列名称，可以是一个数组指定多个系列
        seriesName: '产业资源',
        // 可选，数据的 index
        // dataIndex?: number,
        // 可选，数据的 名称
        name: node.fname,
      })
    }
  };

  getInfoListTitle = () => {
    return `${simplifiedChinaProvinceADCMap[this.bus.chinaADCFilter]}找到资源 ${this.bus.nodes.length} 条：`;
  };

  componentDidMount() {
    let me = this;

    // 找到准备好的DOM
    me.container = document.getElementById(me.containerId);

    // 基于准备好的DOM，初始化ECharts实例
    // noinspection JSCheckFunctionSignatures
    me.myChart = echarts.init(me.container);

    me.currentOption = me.defaultOptionFun();

    me.myChart.setOption(me.currentOption);

    me.myChart.on('click', function ({event}) {
      event.event.stopPropagation();
    });

    me.myChart.on('click', 'series.map', function (params) {
      // 传行政区划代码
      me.bus.chinaADCFilter = params.data['china_adc'];
      me.bus.doFilterAndStatistics();
      // 画图
      me.switchToRegion({chinaADC: me.bus.chinaADCFilter});
    });

    me.bus.mustHaveChinaADC = true;
    me.bus.originalNodes = this.props.allNodes;
    me.bus.doFilterAndStatistics();
    // 画图
    me.switchToRegion({chinaADC: this.bus.chinaADCFilter});
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.allNodes !== prevProps.allNodes) {
      this.bus.originalNodes = this.props.allNodes;
      this.bus.doFilterAndStatistics();
      // 画图
      this.switchToRegion({chinaADC: this.bus.chinaADCFilter});
    }
  }

  render() {
    let me = this;

    if (me.bus.nodes && me.bus.nodes.length === 0) {
      // TODO 没有数据
      console.log('TODO: 没有数据');
    }

    return (
      <div
        className={style['hover-content-frame']}
        onClick={me.props.onClose}
      >
        <div className={style['hover-content-frame-lt']} />
        <div className={style['hover-content-frame-ct']} />
        <div className={style['hover-content-frame-rt']} />
        <div className={style['hover-content-frame-lc']} />
        <div className={style['hover-content-frame-rc']} />
        <div className={style['hover-content-frame-lb']} />
        <div className={style['hover-content-frame-cb']} />
        <div className={style['hover-content-frame-rb']} />
        <div
          className={style['hover-content-frame-inner']}
          onClick={e => e.stopPropagation()}
        >
          <div
            className={style['map-container']}
            id={me.containerId}
            onClick={() => {
              // 重置地图
              if (me.bus.chinaADCFilter !== '000000') {
                me.bus.chinaADCFilter = '000000';
                me.bus.doFilterAndStatistics();
                // 画图
                me.switchToRegion({chinaADC: me.bus.chinaADCFilter});
              }
            }}
          />
          {
            me.bus.nodes && me.bus.nodes.length > 0 && (
              <div className={`${style['map-info-list']}`}>
                <h3>{me.getInfoListTitle()}</h3>
                <ul className={'scrollbar'}>
                  {
                    me.bus.nodes.map((node, idx) => (
                      <li
                        key={`node-${idx}`}
                        onMouseOver={() => me.focusNode(node)}
                        onMouseLeave={() => me.focusNode(false)}
                        title={{/*getNodeDisplayTitle(node)*/}}
                      >{/*清明节样式*/}
                        {getNodeDisplayTitle(node)}
                      </li>
                    ))
                  }
                </ul>
              </div>
            )
          }
          <div className={style['hover-content-frame-inner-cover']} />
        </div>
      </div>
    );
  }
}

MapPanel.defaultProps = {
  allNodes: [],
  positionFieldKey: 'meta.position',
};

MapPanel.propTypes = {
  allNodes: PropTypes.array,
  positionFieldKey: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

export default MapPanel;