import httpUtil, {httpUtilWithNoMsg,getHttpUtil} from '@/utils/HttpUtil'

const httpUtil2 = getHttpUtil(false, 'http://localhost:3000/');

export const API_SubmitFeedBack = (params) => {
  params.axios = {loading_msg: '正在保存您的反馈……'}
  return httpUtil.post(`/view/project/feedback`, params)
};

export const API_SubmitPrinting = (params) => {
  params.axios = {loading_msg: '正在保存，稍后会有客服人员与你联系……'};
  return httpUtil.post(`/view/project/feedback`, params)
};

export const API_UploadTempFiles = (formData, progressCallback = undefined, cancelToken = undefined) => {
  return httpUtilWithNoMsg.post("/view/temp_files", formData, undefined, progressCallback, cancelToken);
};

export const API_UploadPublicFiles = (formData, progressCallback = undefined, cancelToken = undefined) => {
  return httpUtilWithNoMsg.post("/view/public_files", formData, undefined, progressCallback, cancelToken);
};

export const API_GetVideoList = () => {
  //return httpUtil2.get(`/video/video_data.json`);
  return httpUtilWithNoMsg.get(`/g08/video/getvideolist`);
};