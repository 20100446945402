import React, {Suspense} from 'react';
import PropTypes from 'prop-types';
import {/*Layout, */message, Modal/*, Table, Tooltip*/} from "antd";
// import _ from "lodash";
// import LightBox from "react-image-lightbox";

import {
  NODE_TYPE_COLLEGE_AND_UNIVERSITY,
  NODE_TYPE_COMPANY,
  NODE_TYPE_GOV,
  NODE_TYPE_INSTITUTE,
  NODE_TYPE_ORG,
  NODE_TYPE_PAPER,
  NODE_TYPE_PATENT,
  NODE_TYPE_TALENT,
  NODE_TYPE_TEXT,
  NODE_TYPE_TAG,
  TYPE_FIELD_NAME,
  EDGE_TYPE_OUTLINE,
} from "@/constants/vis.defaultDefine.1";
import {
  iconConfig,
  ICON_CATEGORY_COMPANY,
  ICON_CATEGORY_TALENT,
  ICON_CATEGORY_PATENT,
  ICON_CATEGORY_PAPER,
  ICON_CATEGORY_ORG,
  ICON_CATEGORY_INSTITUTE,
  ICON_CATEGORY_GOV,
  ICON_CATEGORY_COLLEGE_AND_UNIVERSITY,
} from '@/constants/iconConfig'

import MyNetwork from "@/libs/myVis/MyNetwork3";
import PB from "@/libs/simplePB";
import Node from "@/libs/view/Node";

import RelationSearch from "@/components/common/relation/common.relation.search";
import VisualToolMini from "@/components/common/relation/common.relation.visualToolMini";
import Icon from "@/components/common/common.icon";
import NodeDataProvider, {overrideNextMessage as overrideNextMessageForNode} from "@/components/common/dataProvider/common.dataProvider.node";
import ViewDataProvider from "@/components/common/dataProvider/common.dataProvider.view";
import InfoBoard from "@/components/mainView/main.infoBoard";
// import WizardModal from "@/components/exploreRelationView/exploreRelation.wizardModal";
import TimelinePanel from "@/components/exploreRelationView/exploreRelation.timeline";
import MapPanel from "@/components/exploreRelationView/exploreRelation.map";

import style from '@/style/containers/exploreRelationView.less';
import styleMainView from "@/style/containers/mainView.1.0.1.less";
import Edge from "@/libs/view/Edge";
import {DataSet} from "@/libs/vis"
// import TimestampAndLocationStatistics from "@/components/common/common.timestampAndLocationStatistics";
import WordCloudList from "@/components/exploreRelationView/exploreRelation.wordCloudList";
import _ from "lodash";

/*const resourceTypes = [
  {label: '政府', value: NODE_TYPE_GOV},
  {label: '协会', value: NODE_TYPE_ORG},
  {label: '园区', value: NODE_TYPE_PARK},
  {label: '企业', value: NODE_TYPE_COMPANY},
  {label: '高校', value: NODE_TYPE_COLLEGE_AND_UNIVERSITY},
  {label: '院所', value: NODE_TYPE_INSTITUTE},
  {label: '人物', value: NODE_TYPE_TALENT},
  {label: '专利', value: NODE_TYPE_PATENT},
  {label: '论文', value: NODE_TYPE_PAPER},
  /!*{label: '技术', value: NODE_TYPE_TECHNOLOGY},*!/
  {label: '数据', value: NODE_TYPE_DATASET},
];

const availableResourceTypes = [NODE_TYPE_TALENT];*/

// const ITEMS_PER_STEP = 30;

const typeIdPrefix = 'node_category_';

const categoryNodeRadius = 700;

const centerNodeSize = 20;
const centerNodeFontSize = 30;

const getPoint = (r, nodes, {x, y} = {x: 0, y: 0}) => {
  let count = nodes.length;
  let radians = (Math.PI / 180) * Math.round(360 / count); //弧度
  let i = 0;
  for (; i < count; i++) {
    nodes[i].x = x + r * Math.sin(radians * i + Math.PI);
    nodes[i].y = y + r * Math.cos(radians * i + Math.PI);
    nodes[i].fixed = true;
  }

  return nodes;
};

// TODO 属性名称对应数组，暂时放在这里
/*const propertyNames = {
  birth: '出生日期',
  degree: '文化程度',
  gender: '性别',
  name: '姓名',
  org_set: '相关单位',
  origin: '籍贯',
  post: '职位',
  tags: '专业领域',
  title: '头衔',
  school: '毕业院校',
  text: '文本',
  honor: '奖项荣誉',
};*/

// TODO 可从节点中获取到的属性信息，暂时放在这里
/*const knownPropertyMap = {
  [NODE_TYPE_TALENT]: {name: 'fname'},
  [NODE_TYPE_TEXT]: {text: 'fname'},
  [NODE_TYPE_TAG]: {text: 'fname'},
};*/

// TODO 资源属性排序方案，暂时放在这里
/*const propertyOrderMap = {
  [NODE_TYPE_TALENT]: ['name', 'gender', 'origin', 'birth', 'degree', 'school', 'tags', 'post', 'title', 'honor', 'org_set'],
  [NODE_TYPE_TEXT]: ['text'],
  [NODE_TYPE_TAG]: ['text'],
};*/

/*const propertyColumns = [{
  title: '属性名',
  dataIndex: 'key',
  key: 'key',
  className: style['property-key'],
  render: text => <span>{propertyNames[text] || text}</span>,
}, {
  title: '属性值',
  dataIndex: 'value',
  key: 'value',
  className: style['property-value'],
  render: (text, record) => {
    const value = record.value;
    if (value === undefined) {
      return <span><Icon name={'loading'} style={{marginRight: '0.5rem'}}/>后台计算中…</span>;
    } else if (!value || (_.isArray(value) && value.length <= 0)) {
      return (
        <Tooltip
          placement={'top'}
          title={'后台计算可能长达1小时'}
          overlayClassName={'dark-theme'}
        >
          <span><Icon name={'info-circle'} style={{opacity: 0.6, cursor: 'pointer'}} /></span>
        </Tooltip>
      );
    } else if (_.isArray(value)) {
      return (
        <ul>
          {
            record.value.map((v, idx) => (
              <li key={`li-${idx}`}><span>{`${v}`}</span></li>
            ))
          }
        </ul>
      );
    } else {
      return <span>{value}</span>;
    }
  },
}];*/

class MainView extends React.Component {
  state = {
    showMoreChart: false,
    showLightBox: -1,
    showTimeline: false,
    showChinaADC: false,
    // showWizard: false,
    withTags: [],
    /*selectedResourceType: undefined,*/
    // 是否展示关联关系简报列表
    showBriefingInfoList: false,
    // 简报相关节点列表
    briefingInfoRelatedNodes: undefined,
    // 指明来源节点ID
    srcNodeId: undefined,
    // 词云结果
    analysisResult: [],
  };

  containerId = 'explore-relation-network-' + Math.random();

  container = undefined;

  // 关系数据
  relationData = undefined;

  // 图片数据
  imageData = undefined;

  // 属性数据
  propertyData = undefined;

  // 资源数据
  resourceData = new DataSet();

  firstTimeLoadingDetail = true;
  loadingFinished = false;

  /**
   * @type {MyNetwork|undefined}
   */
  myNetwork = undefined;

  fromUrlParamWrapper = () => {
    let me = this;

    try {
      if (me.props.urlData) {
        const params = JSON.parse(decodeURIComponent(escape(atob(me.props.urlData.replace(/\$/g, '/')))));
        if (me.props.nodeInfo && (
          me.props.nodeInfo[TYPE_FIELD_NAME] === NODE_TYPE_TEXT ||
          me.props.nodeInfo[TYPE_FIELD_NAME] === NODE_TYPE_TAG
        )) {
          if (!me.props.nodeInfo.meta) {
            me.props.nodeInfo.meta = {};
          }
          if (!me.props.nodeInfo.meta['preferredType'] && params['p'] >= 0) {
            me.props.nodeInfo.meta.preferredType = params['p'];
          }
        }

        params['t'] = me.state.analysisResult.slice(0, 50).map(tag => tag.text);
        if (params['s']) {
          me.setState({srcNodeId: params['s']}, () => {
            me.onWizardFinished({withTags: params['t'], selectedNode: params['n']});
          });
        } else {
          me.onWizardFinished({withTags: params['t'], selectedNode: params['n']});
        }
        return;
      }
    } catch (e) {
      // ignore
    }
    Modal.info({
      title: (<span><span>数据无效。</span></span>),
      okText: '关闭本页面',
      onOk: () => {
        window.close();
      },
    });
  };

  onWizardFinished = ({withTags/*, selectedResourceType*/, selectedNode}) => {
    let me = this;

    me.setState({/*showWizard: false, */withTags/*, selectedResourceType*/});
    const nodeMap = {};

    /*let preferredType = me.props.nodeInfo[TYPE_FIELD_NAME];
    if (preferredType === NODE_TYPE_TEXT || preferredType === NODE_TYPE_TAG) {
      if (me.props.nodeInfo.meta && me.props.nodeInfo.meta.preferredType) {
        preferredType = me.props.nodeInfo.meta.preferredType;
      }
    }*/

    // 获取关系
    /*let viewOptions = me.props.viewDataProvider.getData().viewOptions;
    viewOptions.types = [selectedResourceType];*/

    let originalStatus = me.firstTimeLoadingDetail;
    let loadingFinished = me.loadingFinished;
    /**
     * @type {MessageType|undefined|function}
     */
    let hideLoadingFn = undefined;
    if (!me.firstTimeLoadingDetail) {
      hideLoadingFn = hideLoadingFn = message.loading('持续探索中…', 0);
      overrideNextMessageForNode('static::loadInsightDetailRelations', false);
    } else {
      me.firstTimeLoadingDetail = false;
    }

    let override = {};
    for (let k in selectedNode) {
      if (Object.hasOwnProperty.call(selectedNode, k) && !k.startsWith('_')) {
        override[k] = selectedNode[k];
      }
    }
    override.meta = undefined;
    override.image = undefined;
    NodeDataProvider.loadInsightDetailRelations(
      me.props.nodeId, me.props.nodeType, undefined, withTags, override
    ).then(({nodes, edges}) => {
      nodes = nodes.filter(node => node.id !== me.props.nodeInfo.id); // TODO 后台不再返回主节点后删除
      // const edges = [];
      // nodes = nodes.slice(0, ITEMS_PER_STEP);
      nodes.forEach(node => {
        node = new Node(node);
        /*let nodeType = myVis.getNodeTypeFn(node);
        edges.push({
          from: `${typeIdPrefix}${nodeType}`,
          to: node.id,
          label: node.meta && node.meta['relation'] || undefined,
        });*/
        nodeMap[node.id] = node;
      });
      edges = edges.map(e => new Edge(e));
      me.resourceData.clear();
      me.resourceData.add(Object.values(nodeMap));

      me.myNetwork.addGraph({nodes, edges});
      me.myNetwork.network.startSimulation();
      if (originalStatus) {
        // 画面自动居中
        setTimeout(() => {
          me.myNetwork.network.fitAt({x: 0, y: 0}, {'animation': true});
          /*me.myNetwork.network.once('animationFinished', () => {
            requestAnimationFrame(() => me.myNetwork.network.moveTo({
              scale: me.myNetwork.network.getScale() * 0.9,
              'animation': true,
            }))
          });*/
        }, 1500);
      }
      if (loadingFinished && hideLoadingFn) {
        hideLoadingFn();
      } else if (!loadingFinished && hideLoadingFn === undefined) {
        hideLoadingFn = hideLoadingFn = message.loading('持续探索中…', 0);
      }
      if (loadingFinished) {
        setTimeout(() => message.success('全向资源探索已完成'), 500);
      }
      /*requestAnimationFrame(() => {
        me.myNetwork.dark(Object.keys(nodeMap));

        let loopOpt = {i: 0};
        let loopFn = () => {
          if (loopOpt.i >= nodes.length) {
            // 画面自动居中
            setTimeout(() => {
              me.myNetwork.network.fit({'animation': true});
              me.myNetwork.network.once('animationFinished', () => {
                requestAnimationFrame(() => me.myNetwork.network.moveTo({
                  scale: me.myNetwork.network.getScale() * 0.9,
                  'animation': true,
                }))
              });
            }, 1500);
            message.success('相关资源探索完毕，系统将继续跟踪最新信息', undefined, () => {
              message.info('双击您感兴趣的节点可以继续探索更多信息', 5);
            });
            return;
          }

          let node = nodes[loopOpt.i];
          let flashingFlagObj = {enable: true};

          me.myNetwork.light(node.id);
          let flashLoopFn = () => {
            setTimeout(() => {
              me.myNetwork.dark(node.id);
              setTimeout(() => {
                me.myNetwork.light(node.id);
                if (flashingFlagObj.enable) {
                  flashLoopFn();
                }
              }, 300);
            }, 300);
          };
          flashLoopFn();

          setTimeout(() => {
            overrideNextMessageForNode('static::loadInsightDetailRelations', false);
            NodeDataProvider.loadInsightDetailRelations(node.id, node.type, preferredType, withTags).then(nodes => {
              nodes = nodes.filter(subNode => node.id !== subNode.id); // TODO 后台不再返回主节点后删除
              flashingFlagObj.enable = false;
              const edges = [];
              // 返回节点中第一个为原节点，直接去除
              nodes = nodes.slice(1, ITEMS_PER_STEP);
              nodes.forEach(info => {
                info = new Node(info);
                edges.push({
                  from: node.id,
                  to: info.id,
                  label: info.meta && info.meta['relation'] || undefined,
                });
                nodeMap[info.id] = info;
              });
              me.resourceData.clear();
              me.resourceData.add(Object.values(nodeMap));

              me.myNetwork.addGraph({nodes, edges});

              loopOpt.i++;

              setTimeout(loopFn, 200);
            }).catch(error => {
              // TODO: 获取详情失败了，需要提示
              console.log('TODO: 第二轮探索失败了，需要提示', error);
              flashingFlagObj.enable = false;

              loopOpt.i++;

              setTimeout(loopFn, 200);
            });
          }, 1000);
        };

        setTimeout(loopFn, 200);
      });*/
    }).catch(error => {
      // TODO: 获取详情失败了，需要提示
      console.log('TODO: 第一轮探索失败了，需要提示', error);
      message.info('暂时没有找到相关资源，系统将持续为您跟踪');
      if (loadingFinished && hideLoadingFn) {
        hideLoadingFn();
      } else if (!loadingFinished && hideLoadingFn === undefined) {
        hideLoadingFn = hideLoadingFn = message.loading('持续探索中…', 0);
      }
      if (originalStatus) {
        me.firstTimeLoadingDetail = originalStatus;
        // 画面自动居中
        setTimeout(() => {
          me.myNetwork.network.fitAt({x: 0, y: 0}, {'animation': true});
          /*me.myNetwork.network.once('animationFinished', () => {
            requestAnimationFrame(() => me.myNetwork.network.moveTo({
              scale: me.myNetwork.network.getScale() * 0.9,
              'animation': true,
            }))
          });*/
        }, 1500);
      }
    });

    // 获取图片
    me.imageData = undefined;
    /*NodeDataProvider.loadInsightDetailImages(me.props.nodeId, me.props.nodeType).then(data => {
      me.imageData = data;
      me.setState({showMoreChart: me.imageData && me.imageData.length > 0});
    }).catch(error => {
      // TODO: 获取图片失败了，需要提示
      console.log('TODO: 获取图片失败了，需要提示', error);
      // TODO: 这里暂时放上测试图片
      // me.imageData = testImages;
      me.setState({showMoreChart: me.imageData && me.imageData.length > 0});
    });*/

    // 获取属性
    me.propertyData = [];
    /*if (propertyOrderMap[preferredType]) {
      propertyOrderMap[preferredType].forEach(key => {
        if (knownPropertyMap[preferredType][key] && me.props.nodeInfo) {
          me.propertyData.push({key, value: me.props.nodeInfo[knownPropertyMap[preferredType][key]]});
        } else {
          me.propertyData.push({key, value: undefined});
        }
      });
    }
    NodeDataProvider.loadInsightDetailProperties(me.props.nodeId, me.props.nodeType, preferredType).then(data => {
      me.propertyData = [];
      if (data['type'] >= 0) {
        preferredType = parseInt(data['type']);
      }
      if (propertyOrderMap[preferredType]) {
        propertyOrderMap[preferredType].forEach(key => {
          if (data[key]) {
            me.propertyData.push({key, value: data[key]});
          } else {
            me.propertyData.push({key, value: false});
          }
        });
      }
      me.forceUpdate();
    }).catch(error => {
      // TODO: 获取属性失败了，需要提示
      console.log('TODO: 获取属性失败了，需要提示', error);
      me.propertyData = [];
      if (propertyOrderMap[preferredType]) {
        propertyOrderMap[preferredType].forEach(key => {
          if (knownPropertyMap[preferredType][key] && me.props.nodeInfo) {
            me.propertyData.push({key, value: me.props.nodeInfo[knownPropertyMap[preferredType][key]]});
          } else {
            me.propertyData.push({key, value: undefined});
          }
        });
      }
      me.forceUpdate();
    });*/
  };

  getNodeInfo = id => {
    let me = this;

    let node = me.relationData.nodes.get(id);
    // 判断该节点是否支持加回图谱操作
    if (id !== me.props.nodeInfo.id && !id.startsWith(typeIdPrefix)) {
      let originalNode = me.props.viewDataProvider.getNode(me.state.srcNodeId || me.props.nodeId);
      if (originalNode) {
        let connectedNodeIds = me.props.viewDataProvider.getConnectedNodeIdsByNodeId(me.state.srcNodeId || me.props.nodeId);
        if (connectedNodeIds.includes(id)) {
          // 该节点已被加入图谱且与中心点有关联
        } else if (me.props.viewDataProvider.getNode(id)) {
          // 该节点存在，但与中心点无关联
          node._$extraTitleActions = () => (
            <a
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                me.props.viewDataProvider.addRelation(me.state.srcNodeId || me.props.nodeId, node.id).then(() => {
                  node._$extraTitleActions = undefined;
                  if (node.$getInfoRef) {
                    node.$getInfoRef().nodeDetailInfo = node;
                    node.$getInfoRef().forceUpdate();
                  }
                  message.success('资源添加成功。');
                });
              }}
            >
              <Icon
                name={'plus'}
                style={{marginLeft: '0.5em'}}
              />
            </a>
          );
          node._$extraTitleActions._$width = '1.5em';
        } else {
          // 该节点尚未加入图谱
          node._$extraTitleActions = () => (
            <a
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                me.props.viewDataProvider.addNodeLinkedTo(
                  [{...node, userConfirmed: true, forceAdd: true}],
                  me.state.srcNodeId || me.props.nodeId,
                  false,
                  false,
                  undefined,
                  false
                ).then(() => {
                  node._$extraTitleActions = undefined;
                  if (node.$getInfoRef) {
                    node.$getInfoRef().nodeDetailInfo = node;
                    node.$getInfoRef().forceUpdate();
                  }
                  message.success('资源添加成功。');
                });
              }}
            >
              <Icon
                name={'plus'}
                style={{marginLeft: '0.5em'}}
              />
            </a>
          );
          node._$extraTitleActions._$width = '1.5em';
        }
      }
    }
    return node;
  };

  getNodeConnectedInfo = id => {
    let me = this;

    const edges = [];
    if (me.relationData.nodes.get(id)) {
      me.relationData.edges.forEach(edge => {
        if (edge.from === id || edge.to === id) {
          edges.push(edge);
        }
      })
    }
    const nodes = edges.map(edge =>
      edge.from === id ? me.relationData.nodes.get(edge.to) : me.relationData.nodes.get(edge.from)
    );
    return {nodes, edges};
  };

  getEdgeInfo = id => this.relationData.edges.get(id);

  getEdgeConnectedNodes = id => {
    const edge = this.relationData.edges.get(id);
    if (edge) {
      return this.relationData.nodes.get([edge.from, edge.to]);
    } else {
      return [];
    }
  };

  doAnalysis = (callback) => {
    let me = this;

    // 目前，node就是me.props.nodeInfo
    let node = me.props.nodeInfo;

    let methodFn = {
      'byViewNodeInner': flag => NodeDataProvider.loadMatchedTags(me.props.viewId, me.props.nodeId, node.fname, flag).then(({tags, type, flag}) => {
        if (me.props.nodeInfo && (
          me.props.nodeInfo[TYPE_FIELD_NAME] === NODE_TYPE_TEXT || me.props.nodeInfo[TYPE_FIELD_NAME] === NODE_TYPE_TAG
        )) {
          if (!me.props.nodeInfo.meta) {
            me.props.nodeInfo.meta = {};
          }
          if (!me.props.nodeInfo.meta['preferredType'] && type >= 0) {
            me.props.nodeInfo.meta.preferredType = type;
          }
        }
        if (flag !== undefined && flag !== null && `${flag}` !== 'Done') {
          me.loadingFinished = false;
          setTimeout(() => {
            overrideNextMessageForNode('static::loadMatchedTags', false);
            methodFn.byViewNode(flag);
          }, 200);
        } else {
          me.loadingFinished = true;
        }
        me.setState({analysisResult: tags}, callback);
      }),
      'byViewNode': flag => {
        methodFn.byViewNodeInner(flag).catch(error => {
          // TODO: 错误处理
          console.log('doGetResourceTags - failed: ', error);
          this.loadingFinished = true;
          callback && callback();
        });
      },
      'byText': flag => {
        NodeDataProvider.loadMatchedTags(me.props.viewId, undefined, node.fname, flag).then(({tags, type, flag}) => {
          if (me.props.nodeInfo && (
            me.props.nodeInfo[TYPE_FIELD_NAME] === NODE_TYPE_TEXT || me.props.nodeInfo[TYPE_FIELD_NAME] === NODE_TYPE_TAG
          )) {
            if (!me.props.nodeInfo.meta) {
              me.props.nodeInfo.meta = {};
            }
            if (!me.props.nodeInfo.meta['preferredType'] && type >= 0) {
              me.props.nodeInfo.meta.preferredType = type;
            }
          }
          if (flag !== undefined && flag !== null && `${flag}` !== 'Done') {
            me.loadingFinished = false;
            setTimeout(() => {
              overrideNextMessageForNode('static::loadMatchedTags', false);
              methodFn.byText(flag);
            }, 200);
          } else {
            me.loadingFinished = true;
          }
          me.setState({analysisResult: tags}, callback);
        }).catch(error => {
          // TODO: 错误处理
          console.log('doGetResourceTags - failed: ', error);
          this.loadingFinished = true;
          callback && callback();
        });
      },
    };

    return new Promise((/*resolve, reject*/) => {
      methodFn.byViewNodeInner().catch(error => {
        if (error.code === 10002) {
          // 节点不存在，直接使用文本方式
          methodFn.byText();
        } else {
          // TODO: 错误处理
          console.log('doGetResourceTags - failed: ', error);
          this.loadingFinished = true;
          callback && callback();
        }
      });
    });
  };

  componentDidMount() {
    let me = this;
    me.container = document.getElementById(me.containerId);
    me.myNetwork = new MyNetwork(me.container, {nodes: [], edges: []}, {});
    me.relationData = me.myNetwork.graph;

    const refreshCenterNodeSizeSenderId = `s-${Math.random()}`;
    me.relationData.nodes.on('*', (event, {items}, senderId) => {
      if (senderId === refreshCenterNodeSizeSenderId) return;
      if (event === 'update' || event === 'add') {
        items.forEach(id => {
          if (id === me.props.nodeInfo.id) {
            let node = me.relationData.nodes.get(id);
            let changed = false;
            if (node && node.size !== centerNodeSize) {
              node.size = centerNodeSize;
              changed = true;
            }
            if (node && node.font && node.font.size !== centerNodeFontSize) {
              node.font = {...node.font, size: centerNodeFontSize};
              changed = true;
            }
            if (changed) {
              me.relationData.nodes.update([node], refreshCenterNodeSizeSenderId);
            }
          }
        });
      }
    });

    const networkCanvas = me.container.getElementsByTagName("canvas")[0];

    // 自定义事件
    const myEvents = {
      'click': (params) => {
        me.myNetwork.network.stopSimulation();
        me.setState({
          showBriefingInfoList: false,
          briefingInfoRelatedNodes: undefined,
        }, () => {
          if (params.nodes.length === 1) { // 点击一个节点
            // 观察者：触发节点点击
            PB.emit("network", "selectedNode", {node: me.relationData.nodes.get(params.nodes[0]), connectedNodes: {}});
            // 用于训练菜单
            PB.emit('relation', 'node.single_selection_change', params.nodes[0]);
            // 返回，不走外面的逻辑
            return;
          } else if (params.edges.length === 1) { // 点击一条边
            try {
              // 获取边信息
              let edge = me.relationData.edges.get(params.edges[0]);
              if (edge.from === me.props.nodeInfo.id) {
                // 点击了主节点与分类标识节点之间的连线，不做操作
              } else {
                // 获取节点两端节点，并找到与关联关系简报相关的用户选择节点（资源节点）
                let nodes = me.resourceData.get([edge.from, edge.to]);
                nodes.push(me.props.nodeInfo);
                me.setState({
                  showBriefingInfoList: true,
                  briefingInfoRelatedNodes: nodes.filter(n => n !== null), // [me.props.nodeInfo, me.relationData.nodes.get(edge.to)],
                });
              }
            } catch (e) {
              // 记录错误并忽略
              console.log('Network click event error on edge: ', e);
            }
            // 继续走完外面的逻辑
          }
          // 观察者：触发节点点击
          PB.emit("network", "selectedNode", {node: undefined, connectedNodes: {}});
        });
      },
      /*'doubleClick': (params) => {
        me.myNetwork.network.stopSimulation();
        if (params.nodes.length === 1) { // 点击一个节点
          let node = me.relationData.nodes.get(params.nodes[0]);
          if (node[TYPE_FIELD_NAME] === NODE_TYPE_TEXT || node[TYPE_FIELD_NAME] === NODE_TYPE_TAG) {
            message.info('暂不支持对该节点重新进行探索，请选择其他节点');
            return;
          }
          me.setState({
            showBriefingInfoList: false,
            briefingInfoRelatedNodes: undefined,
          }, () => {
            const nodeMap = {};
            let flashingFlagObj = {enable: true};
            let flashLoopFn = () => {
              setTimeout(() => {
                me.myNetwork.dark(node.id, false);
                setTimeout(() => {
                  me.myNetwork.light(node.id);
                  if (flashingFlagObj.enable) {
                    flashLoopFn();
                  }
                }, 300);
              }, 300);
            };
            flashLoopFn();

            let preferredType = me.props.nodeInfo[TYPE_FIELD_NAME];
            if (preferredType === NODE_TYPE_TEXT || preferredType === NODE_TYPE_TAG) {
              if (me.props.nodeInfo.meta && me.props.nodeInfo.meta.preferredType) {
                preferredType = me.props.nodeInfo.meta.preferredType;
              }
            }

            setTimeout(() => {
              overrideNextMessageForNode('static::loadInsightDetailRelations', false);
              NodeDataProvider.loadInsightDetailRelations(node.id, node.type, preferredType, me.state.withTags).then(nodes => {
                nodes = nodes.filter(subNode => node.id !== subNode.id); // TODO 后台不再返回主节点后删除
                flashingFlagObj.enable = false;
                if (nodes.length > 0) {
                  const edges = [];
                  // 返回节点中第一个为原节点，直接去除
                  nodes = nodes.slice(1, ITEMS_PER_STEP);
                  nodes.forEach(info => {
                    info = new Node(info);
                    edges.push({
                      from: node.id,
                      to: info.id,
                      label: info.meta && info.meta['relation'] || undefined,
                    });
                    nodeMap[info.id] = info;
                  });
                  me.resourceData.clear();
                  me.resourceData.add(Object.values(nodeMap));

                  me.myNetwork.addGraph({nodes, edges});

                  message.success('相关资源探索完毕，系统将继续跟踪最新信息');
                } else {
                  message.info('暂时没有找到相关资源，系统将持续为您跟踪');
                }
              }).catch(error => {
                // TODO: 获取详情失败了，需要提示
                console.log('TODO: 第二轮探索失败了，需要提示', error);
                flashingFlagObj.enable = false;
                message.info('暂时没有找到相关资源，系统将持续为您跟踪');
              });
            }, 1000);
          });
        }
      },*/
      'hoverEdge': ({edge: edgeId}) => {
        let edge = me.relationData.edges.get(edgeId);
        /*edge.label = '…';
        me.myNetwork.updateEdges([edge]);*/
        if (edge.from === me.props.nodeInfo.id) return;
        networkCanvas.style.cursor = 'pointer';
      },
      'blurEdge': ({edge: edgeId}) => {
        let edge = me.relationData.edges.get(edgeId);
        /*edge.label = '';
        me.myNetwork.updateEdges([edge]);*/
        if (edge.from === me.props.nodeInfo.id) return;
        networkCanvas.style.cursor = 'default';
      },
    };

    // 我的视图
    this.myNetwork.bindEvent(myEvents);

    PB.sub(this, 'network', 'focus', nodes => {
      if (!_.isArray(nodes)) nodes = [nodes];
      me.setState({
        clickedNode: me.myNetwork.graph.nodes.get(nodes[0].id),
      }, () => {
        me.myNetwork.focus(nodes[0].id);
      })
    });

    let categoryNodes = [
      ICON_CATEGORY_GOV,
      ICON_CATEGORY_ORG,
      ICON_CATEGORY_COMPANY,
      ICON_CATEGORY_COLLEGE_AND_UNIVERSITY,
      ICON_CATEGORY_INSTITUTE,
      ICON_CATEGORY_TALENT,
      ICON_CATEGORY_PATENT,
      ICON_CATEGORY_PAPER,
    ].map(type => ({
      id: `${typeIdPrefix}${iconConfig[type].extra.nodeType}`,
      fname: iconConfig[type].title.x4 || iconConfig[type].title._default,
      label: iconConfig[type].title.x4 || iconConfig[type].title._default,
      type: NODE_TYPE_TEXT,
      lev: 'gradeF',
      _priority: 1,
    }));
    categoryNodes = getPoint(categoryNodeRadius, categoryNodes);
    categoryNodes = categoryNodes.map(node => new Node(node));

    let categoryEdges = [
      ICON_CATEGORY_GOV,
      ICON_CATEGORY_ORG,
      ICON_CATEGORY_COMPANY,
      ICON_CATEGORY_COLLEGE_AND_UNIVERSITY,
      ICON_CATEGORY_INSTITUTE,
      ICON_CATEGORY_TALENT,
      ICON_CATEGORY_PATENT,
      ICON_CATEGORY_PAPER,
    ].map(type => new Edge({
        from: me.props.nodeInfo.id,
        to: `${typeIdPrefix}${iconConfig[type].extra.nodeType}`,
        type: EDGE_TYPE_OUTLINE,
        meta: {
          text2text: true,
        },
      })
    );

    me.props.nodeInfo._priority = 2;

    me.myNetwork.addGraph({nodes: [me.props.nodeInfo].concat(categoryNodes), edges: [].concat(categoryEdges)});

    // 显示向导
    // me.setState({showWizard: true});

    // 加载线索
    NodeDataProvider.loadRelatedClue(me.props.viewId, me.props.nodeInfo.id).then(nodes => {
      let edges = [];
      nodes = (nodes || []).slice(0, 10).map(node => new Node(node));
      nodes.forEach(node => {
        edges.push(new Edge({
          from: me.props.nodeInfo.id,
          to: node.id,
          status: 0,
        }));
      });

      me.myNetwork.updateGraph({nodes, edges});
    });

    // 加载词云
    me.doAnalysis(() => me.fromUrlParamWrapper());
  }

  componentWillUnmount() {
    PB.remove(this);
  }

  render() {
    let me = this;

    return (
      <div className={style['container-body']}>
        <div id={me.containerId} className={style['relation']}>&nbsp;</div>
        {
          me.myNetwork ? (
            <RelationSearch
              dataLists={me.myNetwork.graph}
              typeFieldName={TYPE_FIELD_NAME}
            />
          ) : null
        }
        {/*<div className={`${style['property-table-frame']} scrollbar`}>
          <Table
            showHeader={false}
            pagination={false}
            scroll={{y: 'calc(61.8vh - 8rem)'}}
            columns={propertyColumns}
            dataSource={me.propertyData}
            locale={{
              emptyText: '暂无数据',
            }}
          />
          <div
            className={style['property-more_info_btn']}
            onClick={() => me.setState({showTimeline: true})}
          >
            <span>时间相关</span>
          </div>
          <div
            className={style['property-more_info_btn']}
            onClick={() => me.setState({showChinaADC: true})}
          >
            <span>地理相关</span>
          </div>
        </div>*/}
        {/*<div className={`${style['more-chart-frame']} scrollbar`}>
          <div className={style['more-chart-content']} style={{display: me.state.showMoreChart ? 'block' : 'none'}}>
            {
              me.imageData && me.imageData.map(({title, src, link}, idx) => (
                <div className={style['more-chart-item-frame']} key={`more-chart-${idx}`}>
                  <div
                    className={style['more-chart-item-img']}
                    onClick={() => {
                      me.setState({showLightBox: idx});
                    }}
                  >
                    <span className={'helper'} />
                    <img src={src} alt={title} />
                  </div>
                  <a href={link} target={'_blank'}>{title}</a>
                </div>
              ))
            }
          </div>
          <Tooltip
            placement={'topLeft'}
            title={me.state.showMoreChart ? '收起' : '展开'}
            overlayClassName={'dark-theme'}
          >
            <div className={`${style['more-chart-switch']} ${me.state.showMoreChart ? 'active' : ''}`}>
              <a
                onClick={() => {
                  if (me.imageData && me.imageData.length > 0) {
                    me.setState({showMoreChart: !me.state.showMoreChart});
                  } else {
                    message.info('没有找到相关图片');
                  }
                }}
              >
                <Icon name={me.state.showMoreChart ? 'icon-caret-left' : 'icon-caret-right'} type={IconTypes.ICON_FONT}/>
              </a>
            </div>
          </Tooltip>
        </div>*/}
        <WordCloudList words={me.state.analysisResult} />
        <VisualToolMini networkRef={me.myNetwork}/>
        <div className={style['east-container']}>
          <Suspense fallback={null}>
            <InfoBoard
              className={styleMainView["info-board"]}
              readonly={true}
              getNodeInfo={me.getNodeInfo}
              getNodeConnectedInfo={me.getNodeConnectedInfo}
              getEdgeInfo={me.getEdgeInfo}
              getEdgeConnectedNodes={me.getEdgeConnectedNodes}
              showBriefingInfo={me.state.showBriefingInfoList}
              briefingInfoRelatedNodes={me.state.briefingInfoRelatedNodes}
              originalViewId={me.props.viewId}
              srcNodeId={me.state.srcNodeId}
            />
          </Suspense>
        </div>
        {me.state.showTimeline && (
          <TimelinePanel
            nodeId={me.props.nodeId}
            nodeType={me.props.nodeType}
            onClose={() => me.setState({showTimeline: false})}
          />
        )}
        {me.state.showChinaADC && (
          <MapPanel
            allNodes={me.resourceData.get(undefined)}
            onClose={() => me.setState({showChinaADC: false})}
          />
        )}
        {/*lightBox*/}
        {/*<WizardModal
          visible={me.state.showWizard}
          onFinished={me.onWizardFinished}
          doAnalysis={node => {
            return new Promise((resolve, reject) => {
              overrideNextMessageForNode('static::loadMatchedTags', false);
              NodeDataProvider.loadMatchedTags(me.props.viewId, me.props.nodeId, node.fname).then(({tags, type}) => {
                if (me.props.nodeInfo && (
                  me.props.nodeInfo[TYPE_FIELD_NAME] === NODE_TYPE_TEXT || me.props.nodeInfo[TYPE_FIELD_NAME] === NODE_TYPE_TAG
                )) {
                  if (!me.props.nodeInfo.meta) {
                    me.props.nodeInfo.meta = {};
                  }
                  if (!me.props.nodeInfo.meta['preferredType'] && type >= 0) {
                    me.props.nodeInfo.meta.preferredType = type;
                  }
                }
                resolve({tags});
              }).catch(error => reject(error));
            });
          }}
          viewId={me.props.viewId}
          currentNodeInfo={me.props.nodeInfo}
          /!*resourceTypes={resourceTypes}
          availableResourceTypes={availableResourceTypes}
          defaultSelectedType={availableResourceTypes[0]}*!/
        />*/}
      </div>
    );
  }
}

MainView.defaultProps = {};

MainView.propTypes = {
  viewId: PropTypes.string.isRequired,
  nodeId: PropTypes.string.isRequired,
  nodeType: PropTypes.number.isRequired,
  nodeInfo: PropTypes.instanceOf(Node).isRequired,
  viewDataProvider: PropTypes.instanceOf(ViewDataProvider).isRequired,
  urlData: PropTypes.string,
};

export default MainView;