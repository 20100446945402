import GuideResources from './resources'
import React from 'react';
import { Button } from 'antd'
import {SimplePB} from '@/libs/simplePB'

// const getUserId = localStorage.getItem("userId")
//引导图类型 需要getuserID做前缀
const EXPAND_NODE_GUIDE = localStorage.getItem("userId") + "_EXPAND_NODE_GUIDE";

// const GuideResources = require('./resources')
const sPB = new SimplePB();

//记录当前的引导图类型
let currentType = "";
//查询是否需要展示引导图
const needShow = (a) => parseInt(localStorage.getItem(a), 10) === 0 || localStorage.getItem(a) === null;
//根据类型选择引导图生成img标签
const chooseResource = (type) => {
    currentType = type;
    switch (type) {
        case EXPAND_NODE_GUIDE:
            return <img src={GuideResources.expandNodeGuide} style={{maxHeight:'100%'}}  alt={'Guide'}/>;
        default:
            return null
    }
};


export default class Guide extends React.Component {
    state = {
        content: chooseResource(),
    };
    componentDidMount() {
        sPB.sub(this, 'guide', 'changePic', pic => {

            this.setState({content:pic})
        })
    }
    hadShownGuide = () => {
        localStorage.setItem(currentType, '1');
        // init = chooseResource()
        this.setState({content:chooseResource()})
    };
    render() {
        const {content} = this.state;
        if(!content) return null;
        // noinspection RequiredAttributes
        return (
          <div style={{
              position: "absolute",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: 'center',
              width: "100%",
              height: "100%",
              backgroundColor: 'rgba(0,0,0,0.8)',
              zIndex: 9999,
          }}>
              <div style={{height: "5rem"}} />
              <div>{this.state.content}</div>
              <div style={{height: "3rem"}}>
                  <Button onClick={()=>this.hadShownGuide()}> 知道了</Button>
              </div>
          </div>
        );
    }
}


export const createExpandNodeGuide = () => {
    if (!needShow(EXPAND_NODE_GUIDE)) return;
    // init = chooseResource(EXPAND_NODE_GUIDE)
    sPB.emit('guide','changePic', chooseResource(EXPAND_NODE_GUIDE));
};

