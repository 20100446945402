import React from "react";
import PropTypes from 'prop-types';
import {Modal, Button, Radio, Table, Tooltip, Popover} from 'antd';
import moment from 'moment';

import PB, {SimplePB} from "@/libs/simplePB";

import {calculateStandardDate, formatAfterDate} from "@/components/common/common.functions";
import Icon from "@/components/common/common.icon";
import TimeDisplayField from "@/components/common/common.timeDisplayField";

import style from '@/style/common/node/common.node.todo.less';
import { IconTypes } from '@/constants/common';

let today = moment().startOf('day');
let standardDate = calculateStandardDate();

const COLUMNS = me => [
  {
    title: '节点名称',
    dataIndex: 'vrDisplayText',
    render: (text, row/*, index*/) => {
      return (
        <React.Fragment>
          <div className={style['name-text']}>{text}</div>
          {me.props.showViewTask ? null : (
            <div className={style['view-text']}>图谱：{row['viewName']}</div>
          )}
        </React.Fragment>
      );
    },
  },
  {
    title: '优先级',
    dataIndex: 'priority',
    align: 'center',
    width: '65px',
    render: (text/*, row, index*/) => {
      return <span className={style[`priority-${text}`]}>{priorityText[text]}</span>;
    },
  },
  {
    title: '发布者',
    dataIndex: 'userId',
    align: 'center',
    width: '7rem',
    render: (text/*, row, index*/) => {
      return <span className={style['user-text']}>{me.state.userInfo[text] ? me.state.userInfo[text].nick : '--'}</span>;
    },
  },
  {
    title: '指派给',
    dataIndex: 'assignTo',
    align: 'center',
    width: '7rem',
    render: (text/*, row, index*/) => {
      return <span className={style['user-text']}>{me.state.userInfo[text] ? me.state.userInfo[text].nick : '--'}</span>;
    },
  },
  {
    title: '状态',
    dataIndex: 'status',
    align: 'center',
    width: '200px',
    render: (text, row/*, index*/) => {
      let isNotOwner = me.props.userId !== parseInt(row['userId']);
      let isNotAssignee = (!row['assignTo'] || me.props.userId !== parseInt(row['assignTo']));
      return (
        <Popover
          title={`${statusText[me.state.currentTaskStatus]}提示`}
          content={<div style={{padding: '.5rem'}}>
            <div style={{minWidth: '15rem', fontSize: '1rem', textAlign: 'center', paddingBottom: '1.5rem'}}>{statusTextTips[me.state.currentTaskStatus]}</div>
            <div style={{
              textAlign: 'right',
            }}>
              <Button onClick={me.onCancelConfirm} style={{marginRight: '.8rem'}}>取消</Button>
              <Button type={'primary'} onClick={() => {
                switch (me.state.currentTaskStatus) {
                  case 1:
                    me.onReturn(row['nodeTaskId']);
                    break;
                  case 2:
                    me.onComplete(row['nodeTaskId']);
                    break;
                  case 3:
                    me.onFinish(row['nodeTaskId']);
                    break;
                  case 4:
                    me.onCancel(row['nodeTaskId']);
                    break;
                }
                me.onCancelConfirm();
              }}>确定</Button>
            </div>
          </div>}
          visible={me.state.confirmVisible === row['nodeTaskId']}
        >
          <Radio.Group
            value={text}
            onChange={e => {
              if (text === e.target.value) return;
              me.setState({
                confirmVisible: row['nodeTaskId'],
                currentTaskStatus: e.target.value,
              });

            }}
            size={'small'}
            buttonStyle={'solid'}
            className={`${style['status-radio']} todo-status-${text}`}
          >
            <Radio.Button value={4} key={`status-4`} disabled={isNotOwner || text === 3}>{statusText[4]}</Radio.Button>
            <Radio.Button value={1} key={`status-1`} disabled={isNotOwner || text === 4 || text === 3}>{statusText[1]}</Radio.Button>
            <Radio.Button value={2} key={`status-2`} disabled={(isNotOwner && isNotAssignee) || text === 4 || text === 3}>{statusText[2]}</Radio.Button>
            <Radio.Button value={3} key={`status-3`} disabled={isNotOwner || text === 4}>{statusText[3]}</Radio.Button>
          </Radio.Group>
        </Popover>
      );
      // return <span className={style[`status-${text}`]}>{statusText[text]}</span>;
    },
  },
  {
    title: '截止时间',
    dataIndex: 'deadline',
    align: 'center',
    width: '12rem',
    render: (text, row/*, index*/) => {
      if (!text) return '--';
      let formattedList = formatAfterDate(text), className = '';
      if (row['status'] === 1) {
        let realToday = moment().startOf('day');
        if (realToday.diff(today) !== 0) {
          standardDate = calculateStandardDate();
          today = realToday;
        }
        let deadlineMoment = moment(text).startOf('day'), {threeDaysAfter} = standardDate;
        if (deadlineMoment < today) {
          className = style['overdue-0'];
        } else if (deadlineMoment <= threeDaysAfter) {
          className = style['overdue-1'];
        }
      }
      return (
        <TimeDisplayField timestamp={moment(text).valueOf()}>
          <span className={className}>
            {formattedList[0]}{formattedList[1] ? ` (${formattedList[1]})` : null}
          </span>
        </TimeDisplayField>
      )
    },
  },
  {
    title: '更新时间',
    dataIndex: 'updateTimestamp',
    align: 'center',
    width: '8rem',
    render: (text, row/*, index*/) => {
      if (!text) return '--';
      return (
        <TimeDisplayField timestamp={moment(text).valueOf()} />
      )
    },
  },
  {
    title: '操作',
    key: 'action',
    align: 'center',
    width: '6rem',
    render: (text, row/*, index*/) => {
      return (
        <React.Fragment>
          {(me.props.userId === row['userId'] && (row['status'] === 1 || row['status'] === 2)) ? (
            <Tooltip title={'编辑待办事项'}>
              <a className={style['action']} onClick={() => me.onEdit(row)}>
                <Icon name={'edit'} style={{marginRight: '0.25em'}} />编辑
              </a>
            </Tooltip>
          ) : null}
          {(me.props.userId !== row['userId'] || row['status'] === 3 || row['status'] === 4) ? (
            <Tooltip title={'查看待办事项'}>
              <a className={style['action']} onClick={() => me.onView(row)}>
                <Icon name={'eye'} style={{marginRight: '0.25em'}} />查看
              </a>
            </Tooltip>
          ) : null}
          {(!row['assignTo'] && me.props.userId === row['userId'] && row['status'] !== 3 && row['status'] !== 4) ? (
            <Tooltip title={'指派任务给团队成员'}>
              <a className={style['action']} onClick={() => me.onAssignTo(row)}>
                <Icon name={'user'} style={{marginRight: '0.25em'}} />指派
              </a>
            </Tooltip>
          ) : null}
        </React.Fragment>
      );
    },
  },
];

const priorityText = ['一般', '紧急', '特急'];

const statusText = ['--', '待办', '已办', '办结', '撤办'];
const statusTextTips = [
  '--',
  <span>任务将重置，确定要重新发起此任务吗？</span>,
  <span>任务确定已经完成了吗？</span>,
  <span>任务确实已经完成了吗？ <br/>（如果需要的话，办结后还可以再次设置任务）</span>,
  <span>任务确实不需要了吗？撤办后任务记录将消失！</span>,
];

class NodeTodoListModal extends React.Component {
  state = {
    statusFilter: 'not-status-4',
    relationFilter: 'related_to',
    processing: false,
    data: [],
    pagination: {
      current: 1,
      pageSize: 1,
    },
    sorter: {
      field: undefined,
      order: undefined,
    },
    userInfo: {},
    confirmVisible: false, // 确定改变状态的弹层显示隐藏
    currentTaskStatus: undefined, // 当前选择的任务状态
  };

  onClose = () => {
    let me = this;
    me.onCancelConfirm();
    me.props.onClose();
  };

  refresh = () => {
    let me = this;

    me.fetch(undefined, true);
  };

  fetch = (pagination, forceReload) => {
    let me = this;

    if (!pagination) {
      pagination = {
        start: (me.state.pagination.current - 1) * me.state.pagination.pageSize,
        limit: me.state.pagination.pageSize,
        orderBy: me.state.sorter.field,
        orderType: me.state.sorter.order,
      };
    }

    let conditions = {};
    if (me.state.relationFilter !== 'all') {
      conditions[me.state.relationFilter] = me.props.userId;
    }
    if (me.state.statusFilter !== 'not-status-4') {
      conditions['status'] = parseInt(me.state.statusFilter.substr(7));
    } else {
      conditions['status_not'] = 4;
    }
    if (me.props.showViewTask && me.props.viewInfo) {
      conditions['view_id'] = me.props.viewInfo.viewId;
    }

    me.setState({processing: true}, () => {
      me.props.bus.emit('node_todo', 'list.do_load', {
        parameters: {
          ...pagination,
          ...conditions,
        },
        forceReload: forceReload === true,
      });
    });
  };

  handleTableChange = (pagination, filters, sorter) => {
    let me = this;

    const pager = { ...me.state.pagination };
    pager.current = pagination.current;
    me.setState({
      pagination: pager,
      sorter: {...me.state.sorter, ...sorter},
    });
    me.fetch({
      start: (pagination.current - 1) * pagination.pageSize,
      limit: pagination.pageSize,
      orderBy: sorter.field || me.state.sorter.field,
      orderType: sorter.order || me.state.sorter.order,
    });
  };

  onEdit = task => {
    let me = this;

    // 显示编辑对话框
    me.props.bus.emit('node_todo', 'detailModal.do_show',
      {action: 'update', task, userInfo: me.state.userInfo[task['assignTo']]});
  };

  onView = task => {
    let me = this;

    // 显示查看对话框
    me.props.bus.emit('node_todo', 'detailModal.do_show',
      {action: 'view', task, userInfo: me.state.userInfo[task['assignTo']]});
  };

  onAssignTo = task => {
    let me = this;

    // 显示指派对话框
    me.props.bus.emit('node_todo', 'assignToModal.do_show',
      {task, userInfo: me.state.userInfo[task['userId']]});
  };

  onReturn = nodeTaskId => {
    let me = this;

    me.props.bus.emit('node_todo', 'todo.do_patch', {task: {nodeTaskId, status: 1}});
  };

  onComplete = nodeTaskId => {
    let me = this;

    me.props.bus.emit('node_todo', 'todo.do_complete', nodeTaskId);
  };

  onFinish = nodeTaskId => {
    let me = this;

    me.props.bus.emit('node_todo', 'todo.do_patch', {task: {nodeTaskId, status: 3}});
  };

  onCancel = nodeTaskId => {
    let me = this;

    me.props.bus.emit('node_todo', 'todo.do_patch', {task: {nodeTaskId, status: 4}});
  };

  onCancelConfirm = () => {
    let me = this;
    me.setState({
      confirmVisible: false,
      // currentTaskStatus: undefined,
    })
  };

  locationNode = (row) => {
    let me = this;
    me.onClose();
    me.props.bus.emit('network', 'node_tooltip.show',
      {id: row.nodeId});
  };

  componentDidMount() {
    let me = this;

    me.props.bus.sub(me, 'node_todo', 'list.loaded', ({data, total, userInfo}) => {
      const pagination = { ...this.state.pagination };
      pagination.total = total;
      this.setState({
        processing: false,
        data: data,
        pagination,
        userInfo,
      });
    });

    me.props.bus.sub(me, 'node_todo', 'list.failed_to_load', () => {
      const pagination = { ...this.state.pagination };
      pagination.total = 0;
      this.setState({
        processing: false,
        data: [],
        pagination,
      });
    });

    me.props.bus.sub(me, 'node_todo',
      ['todo.created', 'todo.updated', 'todo.patched', 'todo.completed'], () => me.refresh());
  }

  componentWillUnmount() {
    let me = this;

    me.props.bus.remove(me);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let me = this;

    if (me.props.showViewTask !== prevProps.showViewTask
      || (me.props.visible === true && me.props.visible !== prevProps.visible)) {

      me.setState({
        statusFilter: 'not-status-4',
        relationFilter: 'related_to',
        pagination: {
          current: 1,
          pageSize: 20,
        },
        sorter: {
          field: 'update_timestamp',
          order: 'desc',
        },
      }, me.refresh);
    }
  }

  render() {
    let me = this;

    return (
      <Modal
        title={(me.props.viewInfo && me.props.showViewTask) ? `图谱待办事项` : `我的待办事项`}
        visible={me.props.visible}
        centered={true}
        onCancel={me.onClose}
        width={'83rem'}
        footer={[
          me.props.viewInfo ? (
            <span key={'tip'} style={{float: 'left', marginLeft: '8px', lineHeight: '32px'}}>
              <Icon name={'info-circle'} style={{marginRight: '0.5em'}} />
              切换至
              <a
                onClick={
                  () => {
                    me.setState({
                      statusFilter: 'not-status-4',
                      relationFilter: 'related_to',
                      pagination: {
                        current: 1,
                        pageSize: 20,
                      },
                      sorter: {
                        field: undefined,
                        order: undefined,
                      },
                      processing: true,
                      data: [],
                    });
                    me.props.bus.emit('node_task', 'listModal.do_show',
                      {type: me.props.showViewTask ? 'my' : 'view'});
                  }
                }
              >
                {me.props.showViewTask ? `我的待办事项` : `当前图谱待办`}
              </a>
            </span>
          ) : null,
          (<Button key={'close'} style={{marginRight: '8px'}} onClick={me.onClose}>关闭</Button>),
        ]}
      >
        <div onClick={() => me.onCancelConfirm()}>
          <Radio.Group
            value={me.state.relationFilter}
            onChange={e => me.setState({relationFilter: e.target.value}, () => me.refresh())}
            disabled={me.state.processing}
            size={'small'}
          >
            <Radio.Button value={'all'} key={`opt-all`} disabled={!me.props.viewInfo || !me.props.showViewTask}>
              所有待办
            </Radio.Button>
            <Radio.Button value={'related_to'} key={`opt-related-to`}>与我相关</Radio.Button>
            <Radio.Button value={'user_id'} key={`opt-own`}>由我创建</Radio.Button>
            <Radio.Button value={'assign_to'} key={`opt-assigned-to`}>指派给我</Radio.Button>
          </Radio.Group>

          <Radio.Group
            value={me.state.statusFilter}
            onChange={e => {
              if (e.target.value === 'status-1') {
                me.setState({
                  statusFilter: e.target.value,
                  pagination: {
                    current: 1,
                    pageSize: 20,
                  },
                  sorter: {
                    field: 'deadline',
                    order: undefined,
                  },
                }, () => me.refresh())
              } else {
                me.setState({
                  statusFilter: e.target.value,
                  pagination: {
                    current: 1,
                    pageSize: 20,
                  },
                  sorter: {
                    field: 'update_timestamp',
                    order: 'desc',
                  },
                }, () => me.refresh())
              }
            }}
            disabled={me.state.processing}
            size={'small'}
            style={{float: 'right'}}
          >
            <Radio.Button value={'not-status-4'} key={`opt-not-status-4`}>全部</Radio.Button>
            <Radio.Button value={'status-1'} key={`opt-status-1`}>待办</Radio.Button>
            <Radio.Button value={'status-2'} key={`opt-status-2`}>已办</Radio.Button>
            <Radio.Button value={'status-3'} key={`opt-status-3`}>办结</Radio.Button>
          </Radio.Group>

          <Table
            style={{marginTop: '1rem'}}
            size="small"
            columns={COLUMNS(me)}
            rowKey={record => record['nodeTaskId']}
            dataSource={this.state.data}
            pagination={this.state.pagination}
            loading={this.state.processing}
            onChange={this.handleTableChange}
            locale={{emptyText: this.state.processing ? '  ' : '暂无待办事项'}}
            scroll={{y: '40vh'}}
            className={`${style['todo-list-table']} scrollbar-none`}
          />
        </div>
      </Modal>
    );
  }
}

NodeTodoListModal.defaultProps = {
  bus: PB,
};

NodeTodoListModal.propTypes = {
  bus: PropTypes.instanceOf(SimplePB),
  viewInfo: PropTypes.object,
  userId: PropTypes.number.isRequired,
  visible: PropTypes.bool.isRequired,
  showViewTask: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default NodeTodoListModal;