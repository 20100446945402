/*
 * @Author: Carr.s
 * @Date: 2022-05-19 10:34:02
 * @LastEditTime: 2022-05-21 15:03:46
 * @LastEditors: Carr.s
 * @Description: 社会治理洞察 B 社会治理气泡图
 * @Copyright by joinmap
 */
import React from "react";
import ViewInsightChartTpl from "@/components/insightView/template/insight.chart.tpl";
import styles from "@/style/components/insightView/insight.less";
import { notification } from "antd";
import echarts from "echarts/lib/echarts";

// https://snap.joinmap.ai/wth_server_api/social_manage_qipao_by_view_id?view_id=4b609468-4f50-4ed0-bbb8-3e15b06b54d0

class ViewSocialGovBInsight extends ViewInsightChartTpl {
  // 全局配置
  nodeTitleInTip = "事件";
  viewerTitle = "社会治理时空推进图";
  _viewerTitleRender = () => {
    return "";
  };
  // column1
  column1_showType = "list"; // 第一列的展现形式，list=显示列，tree=显示树
  column1_Title = "分类词"; // 显示名称
  column1_Unit = "个"; // 显示的文字单位
  column1_subTitle = () => {
    // 列表顶部副标题文字
    const { _column1Len, _column1Total } = this.state;
    return (
      <>
        已计算出{_column1Len} {this.column1_Unit}
      </>
    );
  };
  column1_ValueRender = (column1_data, idx, nodePath = undefined) => {
    // 右侧数值渲染，不输出则空白
    // 必须加上 className={styles["line-value"]}
    let value = column1_data.hasOwnProperty("news")
      ? column1_data.news.length
      : "";
    let unit = this.column2_Unit;
    return (
      <div className={styles["line-value"]}>
        {value} {unit}
      </div>
    );
  };
  // column2
  column2_showType = "list"; // 第二列的展现形式，list=显示列，tree=显示树, none=不显示
  column2_DataField = "news"; // 取值字段
  column2_TotalField = "value"; // 计算总数(与返回数量不一定一致)字段
  column2_ValueRender = (column1_data, idx, nodePath = undefined) => {
    return "";
  };

  // api
  api_path = "/wth_server_api/social_manage_qipao_by_view_id";
  defaultLimit = 60;

  // chart
  chartDivLeft = "auto";
  chartStyle = {
    top: 0,
    bottom: 0,
  };

  chartCompomentStyle = {
    width: "100%",
    height: "100%",
  };

  chartType = "echarts";

  initChartOption = () => {
    const {
      query: { isDebug },
    } = this.props;
    let me = this;
    let option = {
      colors: this._chartColors,
      title: {
        show: false,
      },
      tooltip: {
        formatter: function (info) {
          if (isDebug)
            console.log("initChartOption tooltip formatter info:", info);
          let yName = me._chartData[info.data[1]].name;
          let value = me._chartData[info.data[1]].value;
          return [
            `<div class="tooltip-title">${echarts.format.encodeHTML(
              yName
            )}</div>`,
            info.name + "：" + value + "次<br>",
          ].join("");
        },
      },
      grid: {},
      xAxis: {
        data: [],
        name: "日期",
        nameTextStyle: {
          color: "#eeeeee",
        },
        type: "category",
        boundaryGap: false,
        splitLine: {
          show: true,
          lineStyle: {
            color: "#4f4f4f",
          },
        },
        axisLine: {
          show: false,
        },
        axisLabel: {
          inside: true,
          color: "#fff",
          interval: 0,
          rotate: 50,
          margin: -15,
          align: "center",
        },
      },
      yAxis: {
        data: [],
        name: "分类词",
        nameTextStyle: {
          color: "#eeeeee",
        },
        type: "category",
        axisLine: {
          show: false,
        },
        axisLabel: {
          color: "#eee",
        },
      },
      series: [
        {
          name: "分类词",
          type: "scatter",
          symbolSize: function (val) {
            return val[2] * 2;
          },
          data: [],
          animationDelay: function (idx) {
            return idx * 5;
          },
        },
      ],
    };
    return option;
  };

  refreshChartOptions = (nodePath = null) => {
    const {
      query: { isDebug },
    } = this.props;
    if (isDebug) console.log("refreshChartOptions 0 nodePath:", nodePath);
    // let me = this;
    let dateArr = [];
    let wordArr = [];
    this._chartData.forEach((el, idx) => {
      dateArr = dateArr.concat(el.dateList);
    });
    // 日期数组去重排序
    dateArr = [...new Set(dateArr)];
    dateArr = dateArr.sort((v1, v2) => {
      return new Date(v1) - new Date(v2);
    });
    // 补全

    // 从现在往前36个月
    dateArr = this.c_getDateMonthArray();
    if (isDebug) console.log("refreshChartOptions dateArr:", dateArr);

    let seriesData = [];
    this._chartData.forEach((el, idx) => {
      wordArr.push(el.name);
      el.dateList.forEach((el2, idx2) => {
        let t = new Date(el2);
        var y = t.getFullYear();
        var m = t.getMonth() + 1;
        let elDate_ = `${y}-${m}`;
        let dateIdx = dateArr.indexOf(elDate_);
        let value = el.value < 8 ? 8 : el.value;
        if (dateIdx > -1) {
          seriesData.push([dateIdx, idx, value]);
        }
      });
    });

    this._chartOption = this.initChartOption();
    this._chartOption.xAxis.data = dateArr;
    this._chartOption.yAxis.data = wordArr;
    // this._chartOption.series[0].data = this._chartData;
    this._chartOption.series[0].data = seriesData;
    if (isDebug)
      console.log("refreshChartOptions _chartOption:", this._chartOption);
  };

  /**
   * 生成从现在往前一定数量的月份列表
   * @param {int} monthNumb 往前的月份数量
   * @returns
   */
  c_getDateMonthArray = (monthNumb = 12) => {
    let dateArr = [];
    for (let idx = 0; idx < monthNumb; idx++) {
      let t = new Date();
      t.setMonth(t.getMonth() - idx);
      var y = t.getFullYear();
      var m = t.getMonth() + 1;
      dateArr.push(`${y}-${m}`);
    }
    return dateArr.reverse();
  };
}
export default ViewSocialGovBInsight;
