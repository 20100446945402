import React, { lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
// import _ from 'lodash';
import { Layout, Tooltip, message, Button } from 'antd';
import Icon from '@/components/common/common.icon';
import echarts from 'echarts/lib/echarts';
import 'echarts/map/js/china.js'; // 百度自带的完整中国地图

import { bindUtil } from '@/libs/core-decorators';
import { withReactStateHelper } from '@/libs/react-state-helper';

import {
	ChinaADCDisplayTitleMap,
	ChinaADCStatisticsType,
	getChinaADCByTitle,
	getChinaADCCoordinate,
	simplifiedChinaProvinceADCMap,
	unknownChinaADC,
} from '@/components/common/common.functions';
import style from '@/style/containers/relationView.less';
import RelationEmptyResult from '@/components/relationView/relation.emptyResult';
import RelationPagination from '@/components/relationView/relation.pagination';
import { getNodeDisplayTitle } from '@/constants/vis.defaultDefine.1';
import NodeInfoCard from '@/components/relationView/relation.nodeInfoCard';
import UserAvatar from 'react-user-avatar';
import { getToken, REQUEST_BASE } from '@/utils/HttpUtil';
import { AvatarColors, IconTypes } from '@/constants/common';
import NodeDataProvider from '@/components/common/dataProvider/common.dataProvider.node.js';
import ViewDataProvider from '@/components/common/dataProvider/common.dataProvider.view';

import NodeInfoAttachmentListModal from '@/components/mainView/nodeInfo/main.nodeInfo.attachmentListModal';
import PB from '@/libs/simplePB';
import moment from 'moment';
import CHINA_ADC from '@/constants/china_adc.2018.json';

const NodeInfoBoard = lazy(() => import('@/components/relationView/relation.nodeInfoBoard'));
import NodeFilter from '@/components/relationView/relation.map.nodeFilter';
import MainAiConsoleMessageCommonNodeInfo
	from '@/components/mainView/aiConsoleMessage/main.aiConsoleMessage.commonNodeInfo';
import SysConfig from '@/constants/sys.config';
import { showErrorMessage } from '@/components/common/common.message';

// 颜色系列  用index调用
/*const colors = [
    ["#1DE9B6", "#F46E36", "#04B9FF", "#5DBD32", "#FFC809", "#FB95D5", "#BDA29A", "#6E7074", "#546570", "#C4CCD3"],
    ["#37A2DA", "#67E0E3", "#32C5E9", "#9FE6B8", "#FFDB5C", "#FF9F7F", "#FB7293", "#E062AE", "#E690D1", "#E7BCF3", "#9D96F5", "#8378EA", "#8378EA"],
    ["#DD6B66", "#759AA0", "#E69D87", "#8DC1A9", "#EA7E53", "#EEDD78", "#73A373", "#73B9BC", "#7289AB", "#91CA8C", "#F49F42"],
];*/

// 城市列表
const ADCNameArrL1 = []; // 省份
const ADCNameArrL2 = []; // 城市 含直辖市
const ADCNameArrL3 = []; // 区县

Object.values(ChinaADCDisplayTitleMap[ChinaADCStatisticsType.PROVINCE].shortName).forEach(ele => {
	ADCNameArrL1.push(ele);
});
Object.values(ChinaADCDisplayTitleMap[ChinaADCStatisticsType.CITY].shortName).forEach(ele => {
	ADCNameArrL2.push(ele);
});
Object.values(ChinaADCDisplayTitleMap[ChinaADCStatisticsType.DISTRICT].shortName).forEach(ele => {
	ADCNameArrL3.push(ele);
});

/*let temp = '';
Object.values(CHINA_ADC).forEach(n => {
    temp = n.replace('全中国', '全国')
        .replace('省', '')
        .replace('市', '')
        .replace('回族自治区', '')
        .replace('维吾尔自治区', '')
        .replace('壮族自治区', '')
        .replace('自治区', '')
        .replace('特别行政区', '')
        .replace('地区', '')
        .replace('县', '')
        .replace('区', '');
    if (n.indexOf('省') > -1 || n.indexOf('自治区') > -1 || n.indexOf('特别行政区') > -1) {
        ADCNameArrL1.push(temp);
    } else if (n.indexOf('区') > -1 || n.indexOf('县') > -1) {
        ADCNameArrL3.push(temp);
    } else {
        ADCNameArrL2.push(temp);
    }
});*/
// delete temp;
// console.log('map ADCNameArrL1=', ADCNameArrL1);
// console.log('map ADCNameArrL2=', ADCNameArrL2);

// ------------------------------------------------------------------------------------------------------------------------
@bindUtil.asTargetClass
@withReactStateHelper
class Map extends React.PureComponent {
	state = {
		selectedNode: undefined,
		showDetailList: false,
		focusedDetailListADCName: undefined, // 正在聚焦显示的adc的文字名称
		focusedDetailListADC: undefined, // 正在聚焦显示的adc
		currentShownOriginalViewNodes: [], // 当前画面的节点分页数据(按月份)
		// chartsSeries: ['pin_scatter', 'effect_scatter', 'heatmap'], // 注意顺序,没用的必须去掉
		// 注意顺序, 不能重复； barALL=显示所有地点, barL1=显示省级, barL2=显示地市级, barL3=显示区县级, 必须在前面
		// chartsSeries: ['barL1', 'barL2', 'pin_scatter', 'effect_scatter'],
		chartsSeries: ['barL1', 'pin_scatter', 'effect_scatter', 'tips_scatter'],
		// chartsSeries: ['pin_scatter', 'heatmap'], // 注意顺序
		nodesListDomPos: [0, 0], // 节点简报列表左上角位置dom坐标
		extraInfoListModalVisible: false, // 节点附件弹框显示

		currentAllMapList: undefined, // 当前视图中的地理节点列表
	};

	// 容器id
	containerId = `relation-map-${Math.random()}`;
	container = undefined;

	/**
	 * ECharts实例
	 */
	myChart = undefined;

	detailListSeriesData = {}; // 处理后的按地理位置归类的简报列表数据

	delayRerenderDetailListTimeout = 0; // 延时重绘详情列表

	processedUpdate = undefined; // 已处理过的原始数据更新标记

	focusedProvince = undefined; // 聚焦的省份

	mapResizing = false; // 地图是否正在缩放、移动

	mapResizingTimeout = 0; // 地图缩放、移动状态清理函数标识

	mouseDown = false; // 地图区域，鼠标是否按下

	originalViewNodesRangeList = undefined; // 原图谱中节点分页后的日期范围

	userInfoMap = {}; // ????????

	/**
	 * 统计数值
	 * 省、市、区县 三级统计
	 * @type {undefined|{adc, x, y, value}}
	 */
	CountOfADC = {
		all: {},
		l1: {},
		l2: {},
		l3: {},
	};

	/**
	 * 是否分页
	 * 有问题，别用 2020/6/30
	 * @type {boolean}
	 */
	isPagination = true;

	/* 解析过程mesage key */
	messageKey = undefined;

	detailMessageKeys = {};

	/**
	 * 切换热力图
	 * @param type
	 */
	switchChartSeries = (type = 'effect_scatter') => {
		// console.log('切换图形：', type);
		if (type == 'effect_scatter') {
			this.setState({
				chartsSeries: ['pin_scatter', 'effect_scatter'],
			}, () => {
				this.loadMapData(this.getInitialMapOption(this));
			});
		} else if (type == 'heatmap') {
			this.setState({
				chartsSeries: ['pin_scatter', 'heatmap'],
			}, () => {
				this.loadMapData(this.getInitialMapOption(this));
			});
		}
	};

	/**
	 * 生成默认的echarts option
	 * @param me
	 * @returns {{*}}
	 */
	getInitialMapOption = me => {
		let centerCoordinate = getChinaADCCoordinate('000000');
		let thisOptions = {
			// colors: ['#a53200'],
			// backgroundColor: 'rgb(41,50,61)',
			// backgroundColor: '#030E24',
			animation: true,
			animationDuration: 1000,
			animationEasing: 'cubicInOut',
			animationDurationUpdate: 800,
			animationEasingUpdate: 'cubicInOut',
			title: [
				{
					show: false,
					text: '壮壮图 - ',
					right: '10%',
					top: '12%',
					textStyle: {
						color: '#fff',
						fontSize: 20,
					},
				},
				{
					show: false,
					text: '地理视图 - ',
					left: '45%',
					top: '12%',
					textStyle: {
						color: '#fff',
						fontSize: 30,
					},
				},

			],
			// legend: [],
			grid: [
				{
					right: '4.5%',
					bottom: '5%',
					// height: '27.6%', // 动态计算
					width: 180,
				},
				{
					right: '4.5%',
					bottom: '5%',
					// height: 300, // 动态计算
					width: 180,
				},
			],
			xAxis: [],
			yAxis: [],
			visualMap: {
				show: true,
				min: 0,
				max: 18,
				right: '1%',
				bottom: '4%',
				text: ['高', '低'], // 文本，默认为数值文本
				textStyle: {
					color: '#ccc',
				},
				itemWidth: 7,
				itemHeight: 100,
				calculable: false,
				seriesIndex: [this.state.chartsSeries.indexOf('pin_scatter'), this.state.chartsSeries.indexOf('effect_scatter')], // 指定对第二个序列视觉映射
				inRange: {
					// color: ["#37A2DA", colors[colorIndex][n]] // 蓝绿
					// 中间有绿色
					// color: ['lightgrey', 'lightgreen', 'green', 'yellow', 'gold', 'orangered']
					// 中间无绿色
					// color: ['lightgrey', 'grey', 'yellow', 'gold', 'orangered'],
					// color: ['lightgrey', 'yellow', 'gold', 'orangered'],
					// --------
					// color: ['#d94e5d', '#eac736', '#50a3ba'].reverse(),
					// color: ['#1260A8', '#4394C2', '#66A9D7', '#96C6DD', '#18639E'].reverse(),
					// color:[ '#d48265', '#91c7ae', '#c4ccd3'].reverse(),
					// color: ['#c23531', '#61a0a8', '#2f4554'].reverse(),
					// color: ['#c23531', '#67d08b', '#eec41b'].reverse(),
					// color: ['#2f83e4', '#23cbff', '#00e5c1'].reverse(),
					// color: ['#3c72a6', '#2ca681', '#f6e628'],
					// color: ['#3c72a6', '#2ca681', '#edb73e'],
					// MIT
					// color: ['#ff3534', '#f18989', '#eea9a9', '#e6e6e6', '#a9a9ee', '#8989f1', '#3435ff'],
					// color: ['#eea9a9', '#e6e6e6', '#a9a9ee', '#8989f1', '#3435ff'],
					// xiaya
					// color: ['#fe8284', '#4ccbce', '#999900'],
					// color: ['#33cc66', '#4ccbce', '#999900'],
					// color: ['#e59517', '#0664ed', '#058eb5'], // x
					color: ['#d2c057', '#f28b54', '#f28b54'], // x
					// color: ['#04a3ac', '#846ac8', '#2b62d7'], //
					// color: ['#01fd77', '#1ceef9', '#2c96ff'], //
				},
			},
			tooltip:
				{
					enterable: true,
					trigger: 'item',
					padding: 0,
					hideDelay: 200,
					transitionDuration: 0.4,
					textStyle: {
						color: '#fff',
						decoration: 'none',
						height: 20,
						lineHeight: 16,
					},
					// position: function (point, params, dom, rect, size) {
					//   return [point[0], point[1]];
					// },
					formatter: function (params) {
						// console.log('params=', params);
						if (params.seriesName == 'pin_scatter') {
							// params['data']['china_adc']
							me.getPointPos(params['seriesIndex'], params['value']);
							me.state.focusedDetailListADC = params['data']['china_adc'];
							me.state.focusedDetailListADCName = params['name'];
							return null;
						} else if (['barAll', 'barL1', 'barL2', 'barL3'].indexOf(params['seriesName']) > -1) {
							return params['name'] + ':' + params['value'];
						}
						return null;
					},
				},
			geo: [{
				map: 'china',
				roam: false,
				center: [centerCoordinate.x, centerCoordinate.y],
				selected: ['江苏'],
				zoom: 1.2,
				label: {
					position: 'bottom',
					distance: 5,
					normal: {
						show: false,
					},
					emphasis: {
						show: false,
					},
				},
				// 地图区域的多边形 图形样式。
				itemStyle: {
					normal: {
						areaColor: 'rgba(0,0,0,0)',
						borderColor: 'rgba(161,161,161,0.5)',
					},
					emphasis: {
						// areaColor: '#1890ff',
						areaColor: 'rgba(0,0,0,0)',
						borderColor: 'rgb(178,178,178)',
					},
				},
			}],
			series: [],
		};

		// console.log('me.state.chartsSeries=', me.state.chartsSeries);
		let barXCount = 0; // 柱状图的数量
		this.state.chartsSeries.forEach((item, idx) => {
			if (item === 'pin_scatter') {//气泡数字
				thisOptions.series.push({
					name: item,
					type: 'scatter',
					// symbol: 'circle', //气泡
					coordinateSystem: 'geo',
					geoIndex: 0,
					data: [],
					symbolSize: function (val) {
						// return val;
						// 最小值=50 最大值=200
						// return (val[2] / 5) < 200 ? (val[2] / 5) < 50 ? 50 : (val[2] / 5) : 200;
						// return val[2] < 200 ? val[2] < 45 ? 45 : val[2] : 200;
						return 24;
					},
					showEffectOn: 'render',
					hoverAnimation: true,
					/*label: {
							normal: {
									show: true,
									formatter: function (params) {
											return params.value[2];
									},
									position: 'inside',
									// color: '#031525',
									color: '#fff',
									fontSize: 16,
									fontWeight: 'bold',
							},
					},*/
					itemStyle: {
						normal: {
							// borderColor: 'rgba(255,255,255,0.5)',
							// borderColor: 'rgba(255,255,255,1)',
							// borderColor: '#fff',
							// borderWidth: 0.5,
							// color: '#F62157',
							// color: '#808080',
							// color: '#2bc8d5',
							// shadowBlur: 2,
							// shadowColor: '#fff',
							// shadowColor: '#1890ff',
							opacity: 0.3,
						},
						emphasis: {
							// color: '#F62157',
							color: '#d2c057',
							// shadowBlur: 5,
							// shadowColor: '#f28b54',
							// shadowColor: '#1890ff',
						},
					},
					zlevel: 99,
				});
			} else if (item === 'effect_scatter') {//地图中闪烁的点
				thisOptions.series.push({
					name: item,
					type: 'effectScatter',
					coordinateSystem: 'geo',
					geoIndex: 0,
					tooltip: false,
					data: [],
					symbolSize: function (val) {
						// return val;
						// 最小值=50 最大值=200
						return val[2] < 200 ? val[2] < 28 ? 28 : val[2] : 200;
					},
					showEffectOn: 'render',
					rippleEffect: {
						period: 4,
						scale: 4,
						brushType: 'stroke',
					},
					hoverAnimation: true,
					label: {
						normal: {
							formatter: '{b}',
							position: 'right',
							show: false,
						},
					},
					itemStyle: {
						normal: {
							// shadowBlur: 10,
							// shadowColor: colors[colorIndex][n],
						},
					},
					zlevel: 1,

				});
			} else if (item === 'heatmap') { //热图
				thisOptions.series.push({
					name: item,
					type: 'heatmap',
					coordinateSystem: 'geo',
					geoIndex: 0,
					data: [],
					// pointSize: 30,
					blurSize: 20,
					zlevel: 1,
					// z: 1
				});
			} else if (['barAll', 'barL1', 'barL2', 'barL3'].indexOf(item) > -1) { //柱状图
				// 判断文字
				let label = '全部';
				if (item === 'barAll') {
					label = '全部';
				} else if (item === 'barL1') {
					label = '各省';
				} else if (item === 'barL2') {
					label = '城市';
				} else if (item === 'barL3') {
					label = '区县';
				}
				thisOptions.series.push({
					xAxisIndex: barXCount,
					// yAxisIndex: barXCount,
					name: item,
					zlevel: 1.5,
					type: 'bar',
					symbol: 'none',
					barWidth: 6,
					label: {
						show: true,
						position: 'right',
						distance: 5,
						color: '#fff',
					},
					itemStyle: {
						normal: {
							// color: colors[colorIndex][n],
							// color: '#a53200',
							// color: '#808080',
							// color: '#edb73e',
							// color: '#02a347',

							// color: '#3aadf2',
							color: new echarts.graphic.LinearGradient(
								1, 0, 0, 0,
								[
									{offset: 0, color: '#f28b54'},                   //柱图渐变色
									{offset: 1, color: '#d2c057'},                   //柱图渐变色
								]
							),
							//柱形图圆角，初始化效果
							barBorderRadius: [6, 6, 6, 6],
						},
					},
					data: [],
				});

				// TODO:增加grid

				// 增加Axis
				thisOptions.xAxis.push(
					{
						gridIndex: barXCount,
						name: label + '相关节点数量统计（个）',
						nameLocation: 'center',
						nameTextStyle: {
							fontSize: 16,
							padding: 10,
							color: '#cccccc',
						},
						type: 'value',
						scale: true,
						position: 'top',
						min: 0,
						splitNumber: 0,
						boundaryGap: false,
						splitLine: {
							show: false,
						},
						axisLine: {
							show: false,
						},
						axisTick: {
							show: false,
						},
						axisLabel: {
							margin: 2,
							textStyle: {
								color: '#aaa',
							},
						},
					}
				);
				thisOptions.yAxis.push(
					{
						gridIndex: barXCount,
						type: 'category',
						// name: 'TOP 10',
						nameGap: 10,
						axisLine: {
							show: false,
							lineStyle: {
								color: '#ddd',
							},
						},
						axisTick: {
							show: false,
							lineStyle: {
								color: '#ddd',
							},
						},
						axisLabel: {
							interval: 0,
							textStyle: {
								color: '#ddd',
							},
						},
						data: [],
					},
				);
				// xAxisIndex、yAxisIndex的顺序
				barXCount++;

			} else if (item === 'tips_scatter') {//气泡数字
				thisOptions.series.push({
					name: item,
					type: 'scatter',
					// symbol: 'circle', //气泡
					coordinateSystem: 'geo',
					geoIndex: 0,
					data: [],
					symbolSize: function (val) {
						// return val;
						// 最小值=50 最大值=200
						// return (val[2] / 5) < 200 ? (val[2] / 5) < 50 ? 50 : (val[2] / 5) : 200;
						// return val[2] < 200 ? val[2] < 45 ? 45 : val[2] : 200;
						return 10;
					},
					showEffectOn: 'render',
					hoverAnimation: true,
					label: {
						normal: {
							show: true,
							formatter: function (params) {
								return `${params.data.name}（${params.data.listLength}）`;
							},
							position: 'top',
							backgroundColor: '#01B0F0',
							padding: [0, 10],
							borderRadius: 3,
							lineHeight: 28,
							color: '#ffffff',
							rich: {
								fline: {
									padding: [0, 10, 10, 10],
									color: '#ffffff',
								},
								tline: {
									padding: [10, 10, 0, 10],
									color: '#ffffff',
								},
							},
						},
						emphasis: {
							show: true,
						},
					},
					itemStyle: {
						normal: {
							// borderColor: 'rgba(255,255,255,0.5)',
							// borderColor: 'rgba(255,255,255,1)',
							// borderColor: '#fff',
							// borderWidth: 0.5,
							// color: '#F62157',
							// color: '#808080',
							// color: '#2bc8d5',
							// shadowBlur: 2,
							// shadowColor: '#fff',
							// shadowColor: '#1890ff',
							opacity: 0.6,
						},
						emphasis: {
							// color: '#F62157',
							// color: '#f28b54',
							// shadowBlur: 5,
							// shadowColor: '#f28b54',
							// shadowColor: '#1890ff',
						},
					},
					zlevel: 99,
				});
			}
		});

		// 最后加入地图用于点击聚焦省份事件
		/**/
		thisOptions.series.push(
			{
				geoIndex: 0,
				name: '全国',
				type: 'map',
				map: 'china',
				// roam: true,
				data: [],
			},
		);

		// console.log('thisOptions=', thisOptions);
		return thisOptions;
	};

	// 加载数据时的定时器
	loadingInterval = undefined;

	// 对展示节点画图
	loadMapData = (inOptions = null) => {
		let me = this;
		let thisOptions = this.getInitialMapOption(this);
		if (inOptions) {
			thisOptions = inOptions;
		}
		me.myChart.clear();
		me.myChart.setOption(thisOptions, true, false);
		// 每次加载移动到中心位置
		let coordinate = getChinaADCCoordinate('000000'); // 中国地图的中心节点
		setTimeout(() => {
			// 隐藏nodes明细弹框
			this.setState({focusedDetailListADC: undefined, selectedNode: undefined}, () => {
				// 清除定时器
				if (this.loadingInterval) {
					clearInterval(this.loadingInterval);
				}
				let loop_i = 0;
				let loop_len = me.state.currentShownOriginalViewNodes.length;
				let loop_locationSeriesDataMap = undefined;
				let loop_locationSeriesDataMap_Values = undefined;
				// 每次增加数量
				let loop_step = 10;
				// 根据节点多少设置每次增加步长
				if (loop_len < 10) {
					loop_step = 1;
				} else if (loop_len < 20) {
					loop_step = 2;
				} else if (loop_len < 30) {
					loop_step = 3;
				} else if (loop_len < 40) {
					loop_step = 4;
				} else if (loop_len < 50) {
					loop_step = 5;
				} else if (loop_len < 60) {
					loop_step = 6;
				}
				// 提示
				message.destroy();
				// message.loading('正在计算中......', 0);
				if (!me.messageKey) {
					PB.emit('aiConsole', 'message.push', {
						type: 'ai',
						content: `图谱 "${me.props.viewInfo.name}" 进入节点内容地理位置解析状态`,
						callback: ({key}) => {
							me.messageKey = key;
						},
					});
				}
				if (me.messageKey) {
					PB.emit('aiConsole', 'message.update', {
						key: me.messageKey,
						content: `图谱 "${me.props.viewInfo.name}" 进入节点内容地理位置解析状态`,
						actions: [
							(
								<span key={'loading'} style={{float: 'right', marginLeft: '3rem'}}>
                              <Icon name={'loading'} style={{marginRight: '.3rem'}}/>解析中，请耐心等待...
                          </span>
							),
						],
					});
				}
				me.loadingInterval = setInterval(() => {
					// 循环生成数据  二次分页
					if (loop_i < loop_len) {
						// console.log('map 继续定时器 loop_i', loop_i);
						loop_locationSeriesDataMap = me.state.currentShownOriginalViewNodes.slice(0, loop_i + loop_step);
					} else {
						console.log('map 结束定时器 loop_i', loop_i);
						clearInterval(me.loadingInterval);
						setTimeout(() => {
							let seriesArr = me.myChart.getOption().series;
							me.myChart.setOption({
								geo: [{
									roam: true,
								}],
								series: seriesArr.map(item => {
									if (item.name === 'tips_scatter') {
										item.data = [];
									}
									return item;
								}),
							}, false);
							message.destroy();
							// message.success('地理视图加载成功');
							if (me.messageKey) {
								PB.emit('aiConsole', 'message.update', {
									key: me.messageKey,
									content: `图谱 "${me.props.viewInfo.name}" 地理视图解析完成！`,
								});
							}

							if (me.state.currentAllMapList && me.state.currentAllMapList.length > 0) {
								PB.emit('aiConsole', 'message.push', {
									type: 'ai',
									content: (
										<div>
											{`图谱 "${me.props.viewInfo.name}" 解析结果统计：`}
											<div>
												{me.state.currentAllMapList.map(item => (
													<div key={item.code} style={{paddingTop: '.3rem'}}>{item.name} ：{item.value}</div>
												))}
											</div>
										</div>
									),
								});
							} else {
								PB.emit('aiConsole', 'message.push', {
									type: 'ai',
									content: (
										<div>
											{`图谱 "${me.props.viewInfo.name}" 解析结果统计：无`}
										</div>
									),
								});
							}

						}, 1000);
						return;
					}

					// ----------循环主体-----------------
					// 生成location系列数据 及 生成detail-list系列数据
					let locationSeriesDataMap = {}; // adc 的统计数字 带地图坐标
					let locationSeriesDataBar = {}; // adc 的统计数字 不带地图坐标
					let detailListSeriesDataMap = {}; // adc 带有节点列表详情
					let currentSeriesDataItem = {};
					// 清空nodes明细列表
					me.detailListSeriesData = {};
					// 清空 省、市、区县统计节点数量
					me.CountOfADC = {
						all: {},
						l1: {},
						l2: {},
						l3: {},
					};
					// 二次分页数据循环
					loop_locationSeriesDataMap.forEach(node => {
						if (!node._$mapInfo) return;
						// 省、市、区县统计节点数量
						this.countNodeByADC(node);
						// 统计当前节点
						// console.log('node= ', node);
						let {china_adc, coordinate} = node._$mapInfo;
						// console.log('china_adc=', china_adc, '=', ChinaADCDisplayTitleMap[ChinaADCStatisticsType.CITY].shortName[china_adc]);
						if (coordinate) {
							china_adc = coordinate.adc; // 转成地市级的坐标

							// 地图统计数据
							if (!locationSeriesDataMap[china_adc]) {
								locationSeriesDataMap[china_adc] = {
									id: china_adc,
									name: ChinaADCDisplayTitleMap[ChinaADCStatisticsType.CITY].shortName[china_adc],
									china_adc,
									value: [coordinate.x, coordinate.y, 0],
								};
							}

							// 柱状图计数据
							if (!locationSeriesDataBar[china_adc]) {
								let name = ChinaADCDisplayTitleMap[ChinaADCStatisticsType.CITY].shortName[china_adc];
								locationSeriesDataBar[china_adc] = {
									id: china_adc,
									name,
									china_adc,
									value: 0,
								};
							}

							currentSeriesDataItem = {
								id: china_adc,
								name: ChinaADCDisplayTitleMap[ChinaADCStatisticsType.CITY].shortName[china_adc],
								china_adc,
								value: [coordinate.x, coordinate.y],
							};

							// 节点详情数据
							if (!detailListSeriesDataMap[china_adc]) {
								detailListSeriesDataMap[china_adc] = {
									id: china_adc,
									name: ChinaADCDisplayTitleMap[ChinaADCStatisticsType.CITY].shortName[china_adc],
									china_adc,
									value: [coordinate.x, coordinate.y],
									list: [],
								};

								currentSeriesDataItem = {
									id: china_adc,
									name: ChinaADCDisplayTitleMap[ChinaADCStatisticsType.CITY].shortName[china_adc],
									china_adc,
									value: [coordinate.x, coordinate.y],
								};
							}

							// 按adc计数
							locationSeriesDataMap[china_adc].value[2]++;
							locationSeriesDataBar[china_adc].value++;
							// locationSeriesDataMap[china_adc].value[2] = locationSeriesDataMap[china_adc].value[2] * 10; // 放大地图数值
							// 对应列表增加节点
							detailListSeriesDataMap[china_adc].list.push(node);
						}
					});

					// 按adc分类的nodes明细详情
					me.detailListSeriesData = detailListSeriesDataMap;

					// 排序 + ？
					Object.keys(detailListSeriesDataMap).forEach(thisADC => {
						let info = detailListSeriesDataMap[thisADC];
						me.detailListSeriesData[thisADC] = {
							china_adc: info.china_adc,
							list: info.list.sort((a, b) => {
								if ((!a.time) || (!a.time[0])) return 1;
								if ((!b.time) || (!b.time[0])) return -1;
								return -`${a.time[0]}`.localeCompare(`${b.time[0]}`);
							}),
							position: false,
							name: info.name,
						};

						if (currentSeriesDataItem.china_adc === thisADC) {
							currentSeriesDataItem.listLength = me.detailListSeriesData[thisADC].list.length;
						}

					});

					// 更新echarts options
					let loop_options = me.getInitialMapOption(me);
					// console.log('loop_options=', loop_options);
					// console.log('聚焦 locationSeriesDataMap=', locationSeriesDataMap);
					// console.log('聚焦 locationSeriesDataBar=', locationSeriesDataBar);
					// console.log('聚焦 detailListSeriesDataMap=', detailListSeriesDataMap);
					// console.log('me.state.chartsSeries=', me.state.chartsSeries);
					// 更新地图数据 节点的统计数据
					loop_locationSeriesDataMap_Values = Object.values(locationSeriesDataMap);
					// 柱状图的数量
					let barXCount = 0;
					let barXValue = 0; // x轴最大值

					// 生成option
					this.state.chartsSeries.forEach((item, idx) => {
						if (item === 'pin_scatter') {//气泡数字
							loop_options.series[idx].data = loop_locationSeriesDataMap_Values;
						} else if (item === 'effect_scatter') {//地图中闪烁的点
							// let effectScatterData = convertDataWithGeo2(cityGeoCoordMap, locationSeriesDataMap_Values, 10); // 放大倍数
							loop_options.series[idx].data = loop_locationSeriesDataMap_Values;  // 波纹点数据
							// 波纹节点归一化计算大小
							let min = undefined;
							let max = undefined;
							loop_locationSeriesDataMap_Values.forEach(item => {
								if (min) {
									if (item.value[2] < min) {
										min = item.value[2] + 0;
									}
								} else {
									min = item.value[2] + 0;
								}
								if (max) {
									if (item.value[2] > max) {
										max = item.value[2] + 0;
									}
								} else {
									max = item.value[2] + 0;
								}
							});

							if (max === min) {
								max = 1;
								min = 0;
							}
							// 气泡形状最大35
							let symbolSizeMax = max * 5 < 35 ? max * 5 : 35;
							let symbolSizeMin = 5;
							loop_options.series[idx].symbolSize = function (val) {
								// 标准化归一
								let guiOne = ((val[2] - min) / (max - min)) * symbolSizeMax;
								// console.log('guiONe', guiOne);
								return guiOne < symbolSizeMax ? guiOne < symbolSizeMin ? symbolSizeMin : guiOne : symbolSizeMax;
							};
						} else if (item === 'heatmap') { //热图
							loop_options.series.push({
								name: item,
								type: 'heatmap',
								coordinateSystem: 'geo',
								data: [],
								// pointSize: 30,
								blurSize: 20,
								zlevel: 1,
								// z: 1
							});
						} else if (['barAll', 'barL1', 'barL2', 'barL3'].indexOf(item) > -1) { //柱状图
							let barData_ = me.CountOfADC.all; // 默认显示全部地点，不分等级
							if (item === 'barAll') {
								barData_ = me.CountOfADC.all;
							} else if (item === 'barL1') {
								barData_ = me.CountOfADC.l1;
							} else if (item === 'barL2') {
								barData_ = me.CountOfADC.l2;
							} else if (item === 'barL3') {
								barData_ = me.CountOfADC.l3;
							}
							// console.log('barData_=', barData_);
							// 生成bar格式
							let barData = [];
							let currentAllMapList = [];
							// let barData_ = Object.values(locationSeriesDataBar); // 默认显示全部地点，不分等级
							Object.keys(barData_).forEach((adc1) => {
								// console.log('adc1=', adc1, '=', ChinaADCDisplayTitleMap[ChinaADCStatisticsType.CITY].shortName[adc1]);
								/*barData.push({
										name: ChinaADCDisplayTitleMap[ChinaADCStatisticsType.CITY].shortName[adc1],
										value: barData_[adc1],
								});*/
								currentAllMapList.push({
									name: ChinaADCDisplayTitleMap[ChinaADCStatisticsType.CITY].shortName[adc1],
									value: barData_[adc1],
									code: adc1,
								});
							});

							// NodeFilter组件使用
							currentAllMapList.sort((a, b) => {
								return b.value - a.value;
							});
							this.setState({
								currentAllMapList,
							});
							barData = currentAllMapList.slice(0, 10);
							// 排序
							barData.sort((a, b) => {
								return a.value >= b.value ? 1 : -1;
							});
							// 前10
							// barData = barData.slice(0, 10);
							// console.log('barData=', barData);
							// 柱状图数据
							loop_options.series[idx].data = barData;

							// 更新category数据
							barData.forEach(item => {
								loop_options.yAxis[idx].data.push(item.name);
							});

							// 根据数量改变grid的高度
							loop_options.grid[barXCount].height = barData.length * 30;
							// 更新x轴的最大值
							if (barData.length > 0 && barData[barData.length - 1].value > barXValue) {
								barXValue = barData[barData.length - 1].value;
							}
							// 柱状图x轴的分割数量
							loop_options.xAxis[barXCount].max = barXValue;
							if (barData.length <= 1) {
								loop_options.xAxis[barXCount].splitNumber = 1;
							}
							//
							barXCount++;
						} else if (item === 'tips_scatter') {//气泡数字
							loop_options.series[idx].data = [currentSeriesDataItem];
						}
					});
					me.myChart.setOption(loop_options, true);
					// 记数
					loop_i = loop_i + loop_step;
				}, 600); // setInterval 结束
			});// setState 结束
		}, 500); // setTimeout 结束
	};

	/**
	 * 显示节点信息板
	 * @param node
	 */
	showNodeInfo = (node) => {
		// console.log(' Relation.map -> showNodeInfo -> loadNodeDetail node:', node);
		let me = this;
		// 添加用户操作提示
		PB.emit('aiConsole', 'message.push',
			{type: 'user', content: `查看节点 "${getNodeDisplayTitle(node, 5)}" 的详细信息`});
		// 添加加载节点详情提示
		PB.emit('aiConsole', 'message.push', {
			type: 'ai',
			content: (<span><Icon name={'loading'} style={{marginRight: '0.5em'}}/>请稍后...</span>),
			callback: ({key}) => {
				if (me.detailMessageKeys && me.detailMessageKeys[node.id]) {
					// 清理上一次显示的信息
					PB.emit('aiConsole', 'message.update', {
						key: me.detailMessageKeys[node.id],
						content: (
							<span>
                    <a
	                    onClick={() => PB.emit('aiConsole', 'message.notice',
		                    {key: me.detailMessageKeys[node.id] || key})}
                    >
                      点击查看节点最新信息
                    </a>
                  </span>
						),
					});
				}
				me.detailMessageKeys[node.id] = key;
			},
			delay: 200,
		});
		let loadingStatus = {
			detailLoaded: false,
			detailNode: undefined,
		};
		NodeDataProvider.loadNodeDetail(me.props.viewId, parseInt(node.type), node.id).then(res => {
			// console.log(' Relation.map -> showNodeInfo -> loadNodeDetail res:', res);
			/*<NodeInfoBoard selectedNode={me.state.selectedNode}
										 originalViewId={me.props.viewId}
										 show={!!me.state.selectedNode}
			/>*/
			node = Object.assign(node, res);
			console.log('Relation.map -> 选中节点node -> ', node);
			me.setState({
				selectedNode: node,
			});
			loadingStatus.detailLoaded = true;
			loadingStatus.detailNode = node;

			PB.emit('aiConsole', 'message.update', {
				key: me.detailMessageKeys[node.id],
				content: (
					<MainAiConsoleMessageCommonNodeInfo
						bus={PB}
						node={node}
						originalViewId={me.props.viewId}
						viewDataProvider={me.props.viewDataProvider}
						recommendation={[false]}
					/>
				),
				actions: [(
					<Tooltip title={`定位到节点 "${getNodeDisplayTitle(node, 12)}"`} key={'locate'}>
							<Button
								shape={'circle'}
								className={'first ant-btn-icon ant-btn-icon-only'}
								onClick={e => {
										e.preventDefault();
										PB.emit('network', 'focus', node);
								}}
							>
									<Icon name={'icon-location'} type={IconTypes.ICON_FONT}/>
							</Button>
					</Tooltip>
				), (
					<Tooltip title={'在网上搜索'} key={'search_online'}>
							<Button
								shape={'circle'}
								style={{float: 'right'}}
								className={'last ant-btn-icon ant-btn-icon-only'}
								onClick={e => {
										e.preventDefault();
										window.open(`${SysConfig.sysSearchEngine}${getNodeDisplayTitle(node)}`, '_blank');
								}}
							>
									<Icon name={'icon-hollow-arrow-right'} type={IconTypes.ICON_FONT}/>
							</Button>
					</Tooltip>
				)],
			});
		}).catch(({code, msg}) => {
			showErrorMessage({code, msg, extra: {viewId: me.state.viewId, isModification: true}});
			PB.emit('console', 'info', '获取节点详细信息失败');
			PB.emit('aiConsole', 'message.update',
				{key: me.detailMessageKeys[node.id], content: `操作失败`});
		});

      // 加载自动推荐（目前暂时选用线索联想功能提供数据）
      // overrideNextMessageForNodeDataProvider('static::loadRelatedClue', false);
      me.props.viewDataProvider.smartSearchUserInAllView(node.fname, node.id).then(users => {
          loadingStatus.recommendation = (users || []).slice(0, 3);
          console.log(loadingStatus.recommendation);
          if (loadingStatus.detailLoaded) {
              PB.emit('aiConsole', 'message.patch', {
                  key: me.detailMessageKeys[node.id],
                  content: (
                    <MainAiConsoleMessageCommonNodeInfo
                      bus={PB}
                      node={loadingStatus.detailNode}
                      originalViewId={me.props.viewId}
                      recommendation={loadingStatus.recommendation}
                      viewDataProvider={me.props.viewDataProvider}
                    />
                  ),
              });
          }
      }).catch(() => {
          loadingStatus.recommendation = [];
          console.log(loadingStatus.recommendation);
          if (loadingStatus.detailLoaded) {
              PB.emit('aiConsole', 'message.patch', {
                  key: me.detailMessageKeys[node.id],
                  content: (
                    <MainAiConsoleMessageCommonNodeInfo
                      bus={PB}
                      node={loadingStatus.detailNode}
                      originalViewId={me.props.viewId}
                      recommendation={loadingStatus.recommendation}
                      viewDataProvider={me.props.viewDataProvider}
                    />
                  ),
              });
          }
      });
	};

	/**
	 * echarts 数据点的经纬度转换成网页dom的坐标值
	 * @param seriesIndex
	 * @param current
	 */
	getPointPos = (seriesIndex, current) => {
		// 获取系列
		let seriesModel = this.myChart.getModel().getSeriesByIndex(seriesIndex);
		// 获取地理坐标系实例
		let coordSys = seriesModel.coordinateSystem;
		// dataToPoint 相当于 getPosByGeo
		let point = coordSys.dataToPoint([current[0], current[1]]);
		// 直接显示layer
		// console.log('转换成的页面坐标：', point);
		this.setState({
			nodesListDomPos: point,
		});
	};

	/**
	 * 按省、市、区县 三层统计节点
	 * @param node
	 */
	countNodeByADC = (node) => {
		if (!node.hasOwnProperty('_$mapInfo')) return;
		const {china_adc: adc, china_adc_l1, china_adc_l2, china_adc_l3, coordinate} = node._$mapInfo;
		if (adc !== unknownChinaADC && adc !== '000000') {
			// -----------
			// 分解ADC成省市区三级
			let adcL1 = china_adc_l1 ? china_adc_l1 : adc.substr(0, 2) + '0000'; // 省
			let adcL2 = china_adc_l2 ? china_adc_l2 : adc.substr(0, 4) + '00'; // 市
			let adcL3 = china_adc_l3 ? china_adc_l3 : adc; // 市
			if (this.CountOfADC.l1.hasOwnProperty(adcL1)) {
				this.CountOfADC.l1[adcL1] += 1;
			} else {
				this.CountOfADC.l1[adcL1] = 1;
			}
			if (adcL1 !== adcL2) {
				if (this.CountOfADC.l2.hasOwnProperty(adcL2)) {
					this.CountOfADC.l2[adcL2] += 1;
				} else {
					this.CountOfADC.l2[adcL2] = 1;
				}
			}
			if (adcL3 !== adcL1 && adcL3 !== adcL2) {
				if (this.CountOfADC.l3.hasOwnProperty(adcL3)) {
					this.CountOfADC.l3[adcL3] += 1;
				} else {
					this.CountOfADC.l3[adcL3] = 1;
				}
			}
			if (this.CountOfADC.all.hasOwnProperty(adc)) {
				this.CountOfADC.all[adc] += 1;
			} else {
				this.CountOfADC.all[adc] = 1;
			}
			// ----------------------
		}
		// console.log(' countNodeByADC -> this.CountOfADC :', this.CountOfADC);
		return true;
	};

	// 过滤数据+生成adc+生成坐标, 返回值确定是否有地图点
	processOriginalViewNodes = () => {
		let me = this, nodes;
		// 清理数据，只留下有指定字段的数据，同时生成connection系列数据
		let strForSch = '';
		PB.emit('aiConsole', 'message.push', {
			type: 'ai',
			content: `图谱 "${me.props.viewInfo.name}" 加载 "${me.props.filterType[me.props.filterCurKey].text}" 数据完成`,
		});

		nodes = me.props.viewNodeList.filter(node => {
			/**
			 * @type {undefined|adc}
			 */
			let mainADC = undefined;
			/**
			 * @type {undefined|{adc, x, y, scale}}
			 */
			let mainCoordinate = undefined;
			// _.isArray(node.tags) && node.tags.length > 0 && node.tags.forEach(tag => {
			// TODO: 添加对fname meta.desc 中地址的提取
			// 先找城市 -> 城市找不到再找省份
			strForSch = node.fname + '>' + node.tags.join(',');
			// console.log('map strForSch 0=', strForSch + '<');
			let adc = getChinaADCByTitle(me.getGeoNameFormStr(strForSch, ADCNameArrL2)); // 先找区县
			// console.log('map 分解出的adc 1=', adc);
			/*
			if (adc === unknownChinaADC || adc === '000000' || adc === '999999') {
					adc = getChinaADCByTitle(me.getGeoNameFormStr(strForSch, ADCNameArrL2)); // 再找地市
					console.log('map 分解出的adc 2=', adc + '<');
			}
	*/
			if (adc === unknownChinaADC || adc === '000000' || adc === '999999') {
				adc = getChinaADCByTitle(me.getGeoNameFormStr(strForSch, ADCNameArrL1)); // 再找省份
				// console.log('map 分解出的adc 3=', adc + '<');
			}
			if (adc !== unknownChinaADC && adc !== '000000' && adc !== '999999') {
				// 分解ADC成省市区三级
				let adcL1 = adc.substr(0, 2) + '0000'; // 省
				let adcL2 = adc.substr(0, 4) + '00'; // 市
				let adcL3 = null; // 区县
				if (adcL2 == adcL1) {
					adcL2 = null;
				}
				if (adc != adcL2 && adc != adcL1) {
					adcL3 = adc;
				}
				// 保存回节点中
				let coordinate = getChinaADCCoordinate(adc); // 坐标
				node._$mapInfo = {
					coordinate,
					china_adc: adc,
					china_adc_l1: adcL1,
					china_adc_l2: adcL2,
					china_adc_l3: adcL3,
				};
				if (!mainADC) {
					mainADC = adc;
					mainCoordinate = coordinate;
				}
			}
			return mainADC !== undefined; // 返回真=有效节点
		});
		// 用户数据 不要了吧！！！
		me.props.userList.forEach(user => me.userInfoMap[parseInt(user.userId)] = user);
		if (!!me.isPagination) {
			me.originalViewNodesRangeList = me.props.paginationViewNodes(nodes, 'updateTime'); // 按更新时间分页
		} else {
			me.originalViewNodesRangeList = [{idx: 0, monthStart: moment(), nodeList: nodes}]; // 不分页，全部节点
		}
		me.forceUpdate(); // 强制更新
	};

	/**
	 * 从字符串中查找第一个城市或省份名称
	 * 区县重名太多, 不建议匹配
	 * @param st
	 * @param arr
	 * @returns {null|*|string}
	 */
	getGeoNameFormStr = (st, arr = ADCNameArrL3) => {
		// st = '数字经济垂直行业+传统咨询模式>2020-07-05,合作方式';
		// 匹配规则：先从字多的开始匹配 ，否则会出现“井冈山南泥湾产业计算中心”解析为“山南市”
		let temp = '';
		let temp2 = '';
		// 先从4个字的开始找
		for (let i = 0; i < st.length; i++) {
			temp = st.slice(i, i + 4);
			if (arr.indexOf(temp) > -1) {
				return temp;
			}
		}

		// 4个字的没找到，3个字的来一遍
		for (let i = 0; i < st.length; i++) {
			temp = st.slice(i, i + 3);
			if (arr.indexOf(temp) > -1) {
				return temp;
			}
		}

		// 3个字的没找到，2个字的来一遍
		for (let i = 0; i < st.length; i++) {
			temp = st.slice(i, i + 2);
			if (arr.indexOf(temp) > -1) {
				// console.log('map temp -1=', temp + '<');
				// 例外处理
				if (temp === '阿里') {
					temp2 = st.slice(i, i + 4);
					if (temp2 === '阿里巴巴') {
						continue;
					}
				}
				// 甘肃 有个合作市 ？？ == 强匹配 否则放弃
				if (temp === '合作') {
					temp2 = st.slice(i, i + 3);
					if (temp2 !== '合作市') {
						continue;
					}
				}
				return temp;
			}
		}

		// TODO:第二次例外处理

		// console.log('循环结束了', temp);
		return null;
	};

	/**
	 * 分页数据
	 * @param currentOriginalViewNodesPage
	 */
	onPageChange = (currentOriginalViewNodesPage) => {
		let me = this;
		me.setState({
			currentShownOriginalViewNodes: me.originalViewNodesRangeList[currentOriginalViewNodesPage - 1].nodeList,
			currentAllMapList: undefined,
		}, () => {
			PB.emit('aiConsole', 'message.update', {
				key: me.messageKey,
				content: `图谱 "${me.props.viewInfo.name}" 地理位置解析停止`,
			});
			clearInterval(me.loadingInterval);
			me.loadMapData();
		});
	};

	//==================================================================================================================

	// 省份聚焦
	focusProvince = adc => {
		return;
		let me = this;

		adc = adc || '000000';
		let coordinate = getChinaADCCoordinate(adc);
		if (!coordinate) {
			adc = '000000';
			coordinate = getChinaADCCoordinate('000000');
		}
		console.log('focusProvince series click coordinate=', coordinate);

		me.focusedProvince = adc;
		let chartOption = me.myChart.getOption();
		// chartOption.geo[0].center = [coordinate.x, coordinate.y]; // 能消除不更新重绘
		// chartOption.geo[0].zoom = ;
		// chartOption.geo[0].name = simplifiedChinaProvinceADCMap[adc];
		// if (adc === '000000') me.myChart.setOption({}, true);
		me.myChart.setOption({}, false);
		me.myChart.setOption({
			geo: [{
				center: [coordinate.x, coordinate.y],
				zoom: coordinate.scale,
				name: simplifiedChinaProvinceADCMap[adc],
			}],
		});
		// me.myChart.resize();
		return;
		if (adc !== '000000') {
			me.myChart.dispatchAction({
				type: 'geoSelect', //'mapSelect',
				// 可选，系列 index，可以是一个数组指定多个系列
				// seriesIndex?: number|Array,
				// 可选，系列名称，可以是一个数组指定多个系列
				// seriesName?: string|Array,
				// 数据的 index，如果不指定也可以通过 name 属性根据名称指定数据
				// dataIndex?: number,
				// 可选，数据名称，在有 dataIndex 的时候忽略
				name: simplifiedChinaProvinceADCMap[adc],
			});
		}
	};

	componentDidMount() {
		let me = this;
		me.container = document.getElementById(me.containerId);
		me.myChart = echarts.init(me.container, undefined, undefined);
		me.myChart.setOption(me.getInitialMapOption(me));
		me.myChart.on('click', function (params) {
			// console.log('myChart click params=', params);
			me.setState({focusedDetailListADC: undefined, selectedNode: undefined});

			// 判断点击省份
			// if (params.componentType == 'geo') {
			//     let adc = getChinaADCByTitle(params.name); // 行政代码, 多个地址怎么处理
			//     if (adc !== unknownChinaADC && adc !== '000000') me.focusProvince(adc);
			// } else {
			//     // 恢复全国地图
			// }
		});
		me.myChart.on('click', 'series.map', (params) => {
			// console.log('myChart series.map click params=', params);
			const {name, event} = params;
			// console.log('myChart series.map click name=', name);
			let adc = getChinaADCByTitle(name); // 行政代码
			// console.log('myChart series.map click adc=', adc);
			if (adc !== unknownChinaADC && adc !== '000000') me.focusProvince(adc);
			// event.event.stopPropagation();
		});

		/*
me.myChart.on('geoRoam', () => {
				if (me.mapResizingTimeout) {
						clearTimeout(me.mapResizingTimeout);
				}
				me.mapResizing = true;
				if (!me.mouseDown) {
						me.mapResizingTimeout = setTimeout(() => {
								me.mapResizing = false;
								me.mapResizingTimeout = 0;
						}, 100);
				}
		});
		*/
		if (me.props.status === 'success') {
			me.processOriginalViewNodes();
			// 判断是否需要分页
			if (!me.isPagination) {
				me.setState({
					currentShownOriginalViewNodes: me.originalViewNodesRangeList[0].nodeList,
				}, () => {
					me.loadMapData();
				});
			}

		}

		PB.sub(me, 'network', 'node.on_show_extra_info', ({node}) => {
			me.setState({
				extraInfoListModalVisible: true,
			});
		});
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		let me = this;

		if (me.props.status === 'success' && prevProps.status !== 'success') {
			me.processOriginalViewNodes();
			this.setState({
				currentAllMapList: undefined,
			});
		}
	}

	componentWillUnmount() {
		PB.remove(this);
	}

	render() {
		let me = this;
		const {focusedDetailListADC, focusedDetailListADCName, selectedNode, extraInfoListModalVisible} = this.state;
		// console.log('刷新：render viewId=', this.props.viewId);
		// console.log('this.detailListSeriesData=', this.detailListSeriesData);
		let nodesListJsx = [];
		if (this.detailListSeriesData.hasOwnProperty(focusedDetailListADC)) {
			let nodes = this.detailListSeriesData[focusedDetailListADC]['list'];
			if (nodes.length > 0) {
				nodes.forEach(node => {
					nodesListJsx.push(
						<div
							key={'dd_' + node.id}
							onClick={() => {
								this.showNodeInfo(node);
							}}
							className="echarts-map-tip-item jm-ellipsis"
						>
							<i style={{
								display: 'inline-block',
								width: '0.5rem',
								height: '0.5rem',
								background: '#eee',
								borderRadius: '50%',
								margin: '0 0.6rem',
							}}></i>
							<span style={{color: '#ccc'}}>{node.fname}</span>
						</div>
					);
				});
			} else {
				nodesListJsx = [<div class="echarts-map-tip-item"><span style="color:#fff;">无节点</span></div>];
			}
		}

		// 列表框style
		let nodesListStyle = {
			// backgroundColor: '#00adf6',
			position: 'absolute',
			// width: '300px',
			// height: '300px',
			top: this.state.nodesListDomPos[1] + 20,
			left: this.state.nodesListDomPos[0] + 20,
		};

		return (
			<React.Fragment>
				<Layout.Content className={style['content-result-container']}>
					<div className={`${style['content-result-cover']}`} onClick={() => {
						me.setState({focusedDetailListADC: undefined, selectedNode: undefined});
						me.focusProvince();
					}}>
						<RelationEmptyResult
							status={me.props.status}
							hasNodes={me.originalViewNodesRangeList !== undefined && me.originalViewNodesRangeList.length > 0}
						/>
						<div
							style={{
								display: me.props.status === 'processing' ? 'none' : undefined,
								height: '100%',
								width: '100%',
								position: 'relative',
							}}
						>
							<div className={me.props.className}>
								<div
									id={me.containerId}
									style={{width: '100%', height: '100%'}}
									onClick={() => {
										// if (!me.mapResizing) me.focusProvince();
									}}
									onMouseDown={() => me.mouseDown = true}
									onMouseUp={() => {
										me.mouseDown = false;
										if (me.mapResizing) {
											if (me.mapResizingTimeout) {
												clearTimeout(me.mapResizingTimeout);
											}
											me.mapResizingTimeout = setTimeout(() => {
												me.mapResizing = false;
												me.mapResizingTimeout = 0;
											}, 10);
										}
									}}
								/>
								{
									me.state.showDetailList ? (
										me.detailListSeriesData.map(data => data.position ? (
											<div
												key={`detail-info-${data.china_adc}`}
												className={
													[
														style['map-detail-list'],
														// 这里可能要改一下
														me.state.focusedDetailListADC === data.china_adc ? 'focused' : (
															me.state.focusedDetailListADC ? 'faded' : ''
														),
													].join(' ')
												}
												style={{left: data.position[0], top: data.position[1]}}
												onMouseEnter={() => me.focusDetailList(data.china_adc)}
												onMouseOver={() => me.focusDetailList(data.china_adc)}
												onMouseLeave={() => me.cancelFocusDetailList(data.china_adc)}
											>
												<div className={style['map-detail-list-content']}>
													<ul className={'scrollbar'}>
														{
															data.list.map((node) => (
																<Tooltip
																	key={`n-${node.id}`}
																	placement={window.innerWidth > data.position[0] * 2 ? 'right' : 'left'}
																	title={(
																		<NodeInfoCard
																			key={`node.${node.id}`}
																			info={node}
																			userInfo={me.userInfoMap[parseInt(node.userId)]}
																		/>
																	)}
																	mouseLeaveDelay={0.05}
																	overlayClassName={style['map-detail-list-content-tooltip']}
																>
																	<li>
                                    <span>
                                      <span
	                                      className={`${style['map-detail-list-content-author']} ${me.userInfoMap[parseInt(node.userId)] ? (me.userInfoMap[parseInt(node.userId)].picId ? '' : 'default-icon') : 'computer-icon'}`
	                                      }
                                      >
                                        <UserAvatar
	                                        size={'var(--avatar-size)'}
	                                        name={
		                                        me.userInfoMap[parseInt(node.userId)] ?
			                                        me.userInfoMap[parseInt(node.userId)].nick : '炬图智能大脑'
	                                        }
	                                        src={
		                                        me.userInfoMap[parseInt(node.userId)] && me.userInfoMap[parseInt(node.userId)].picId ?
			                                        `${REQUEST_BASE}/user/user/file/${me.userInfoMap[parseInt(node.userId)].picId}?Authorization=${getToken()}` : undefined}
	                                        colors={AvatarColors}
                                        />
                                      </span>
	                                    {getNodeDisplayTitle(node)}
                                    </span>
																	</li>
																</Tooltip>
															))
														}
													</ul>
												</div>
											</div>
										) : null)
									) : null
								}
							</div>
						</div>
					</div>


				</Layout.Content>

				{
					// !me.detailListSeriesData || Object.keys(me.detailListSeriesData).length == 0 ? null : (
					true ? null : (
						<div className={style['echarts-map-switcher']}>
							<div style={{marginBottom: '5px'}}>查看:</div>
							<div
								className={this.state.chartsSeries.indexOf('heatmap') > -1 ? style['echarts-map-switcher-btn'] + ' ' + style['active'] : style['echarts-map-switcher-btn']}

								onClick={() => {
									this.switchChartSeries('heatmap');
								}}>
								<Icon type="heat-map"/> 热力图
							</div>
							<div
								className={this.state.chartsSeries.indexOf('effect_scatter') > -1 ? style['echarts-map-switcher-btn'] + ' ' + style['active'] : style['echarts-map-switcher-btn']}
								onClick={() => {
									this.switchChartSeries();
								}}><Icon type="dot-chart"/> 散点图
							</div>
						</div>
					)
				}
				{
					!me.isPagination ? null : me.originalViewNodesRangeList === undefined ? null : (
						<RelationPagination nodesRangeList={me.originalViewNodesRangeList}
						                    onPageChange={me.onPageChange}
						                    type={'map'}
						/>
					)
				}
				{
					!focusedDetailListADC ? null : (
						<div className="echarts-map-tip-outer" style={nodesListStyle}>
							<div style={{
								width: '100%',
								height: '40px',
								lineHeight: '40px',
								borderBottom: '1px solid #67727e',
								padding: '0 13px',
							}}>
								<span style={{color: '#ccc', fontSize: '1.2rem'}}>{focusedDetailListADCName}</span>
								<span style={{
									float: 'right',
									color: 'rgba(255,255,255,0.4)',
									fontSize: '0.9rem',
								}}>共有{nodesListJsx.length}个相关节点</span>
								<span style={{clear: 'both'}}></span>
							</div>
							<div className="echarts-map-tip-body scrollbar scrollbar-18">
								{
									nodesListJsx.map(i => i)
								}
							</div>
						</div>
					)
				}
				{
					!!selectedNode && extraInfoListModalVisible ? (
						<NodeInfoAttachmentListModal
							nodeId={selectedNode.id}
							node={selectedNode}
							editable={false}
							networkRef={null}
							visible={extraInfoListModalVisible}
							onClose={() => me.setState({
								extraInfoListModalVisible: false,
							})}
						/>
					) : null
				}

				<NodeFilter
					viewId={this.props.viewId}
					currentAllMapList={me.state.currentAllMapList}
					detailListSeriesData={me.detailListSeriesData}
					showNodeInfo={me.showNodeInfo}
					filterType={me.props.filterType}
					filterCurKey={me.props.filterCurKey}
					resetNodes={me.props.resetNodes}
				/>

			</React.Fragment>
		);
	}
}

Map.defaultProps = {
	className: undefined,
	viewNodeList: [],
	userList: [],
};

Map.propTypes = {
	viewId: PropTypes.string, // 图谱id
	viewInfo: PropTypes.object, // 图谱信息
	className: PropTypes.string,  // 组件的外部容器的 className
	viewNodeList: PropTypes.array,
	userList: PropTypes.array,
	paginationViewNodes: PropTypes.func.isRequired,
	status: PropTypes.string.isRequired,
	filterType: PropTypes.object, // 如果用户筛选项列表
	filterCurKey: PropTypes.string, // 当用显示的筛选值
	resetNodes: PropTypes.func, // 切换筛选节点，筛选点还是全部
	viewDataProvider: PropTypes.instanceOf(ViewDataProvider),
};

export default Map;
