import React from 'react';
import PropTypes from 'prop-types';

import IconAvatar from '@/components/common/common.iconAvatar';
import PB from "@/libs/simplePB";
import {/*getExternalLinkUrl, */getNodeIcon} from "@/constants/vis.defaultDefine.1";
import Icon from "@/components/common/common.icon";
import {IconTypes} from "@/constants/common";
import ClampLines from "@/libs/my-react-clamp-lines";
import _ from "lodash";

const getSummaryContent = (node) => {
  let contentList = [];
  if (node.tag && node.tag.length > 0) {
    // this.props.companyTags.map((e, i) => <Tag key={`tag-${i}`}><Icon type='tag' />&nbsp;{e}</Tag>)
    contentList.push(`[ ${node.tag.split(' ', 3).join(' ')} ]`);
  }
  if (node.description) {
    contentList.push(node.description);
  }
  return contentList.join(' ');
};

class TextNodeSummaryInfo extends React.Component {
  onTitleClicked = (e) => {
    e.preventDefault();

    const {node/*, permanent*/, onTitleClicked} = this.props;

    if (_.isFunction(onTitleClicked)) {
      onTitleClicked(node);
    } else {
      PB.emit('network', 'focus', node);
    }
  };

  render() {
    const {node, showSummary} = this.props;
    const {name, type, color} = getNodeIcon(node);
    const actions = [];

    // 其他操作
    if (this.props.actions && this.props.actions.length > 0) {
      Array.prototype.push.apply(actions, this.props.actions);
    }

    return (
      <div className="ant-list-item-meta">
        {
          this.props.showIcon ? (
            <div className="ant-list-item-meta-avatar">
              <a onClick={(e) => {e.preventDefault(); PB.emit('network', 'focus', node)}}>
                <IconAvatar name={name} type={type} color={color} />
              </a>
            </div>
          ) : ''
        }
        <div className="ant-list-item-meta-content">
          <h4 className="ant-list-item-meta-title">
            <a
                onClick={this.onTitleClicked}
            >
              <span>{node.fname}</span>
            </a>
          </h4>
          {
            (node.url) ? (
              <div className='ant-list-item-meta-description'>
                <a target={'_blank'} href={node.url} className={'single-line'}>
                  <Icon name={'icon-link-o'} type={IconTypes.ICON_FONT} style={{marginRight: '0.5em'}}/>
                  {node.url}
                </a>
              </div>
            ) : null
          }
          {
            showSummary ? (
              <ClampLines
                id={`node-${node.id}-description`}
                className="ant-list-item-meta-description"
                lines={2}
                moreText={'展开 ∨'}
                lessText={'收起 ∧'}
                text={getSummaryContent(node)}
              />
            ) : null
          }
          {
            actions.length > 0 ? (
              <ul className="ant-list-item-action" style={{marginLeft: 'auto'}}>
                {actions}
              </ul>
            ) : null
          }
        </div>
      </div>
    );
  }
}

TextNodeSummaryInfo.defaultProps = {
  permanent: true,
  showIcon: true,
  showSummary: false,
  actions: [],
};

TextNodeSummaryInfo.propTypes = {
  node: PropTypes.object.isRequired,
  permanent: PropTypes.bool,
  showIcon: PropTypes.bool,
  showSummary: PropTypes.bool,
  actions: PropTypes.array,
  onTitleClicked: PropTypes.func,
};

export default TextNodeSummaryInfo;
