import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Dropdown, Tooltip } from 'antd';
import moment from 'moment';
import UserAvatar from 'react-user-avatar';

import { getToken, REQUEST_BASE } from '@/utils/HttpUtil';
import Icon from '@/components/common/common.icon';
import { AvatarColors, IconTypes } from '@/constants/common';
import ViewOperateMenu from '@/components/dashboard/dashboard.view.operate';
import View from '@/components/common/common.viewManager';

import style from '@/style/components/dashboard/dashboard.view.less';
import TimeDisplayField from '@/components/common/common.timeDisplayField';

import {API_GetViewById} from '@/libs/view/network/api.js';

export default class VideoInfoCard extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			coverData: undefined,
			currentViewInfo: props.view,
		};
	}

	/*splitViewName = (view) => {
		let me = this;
		let result = [], viewName = view.name;
		if (me.props.searchWord && viewName.indexOf(me.props.searchWord) > -1) {
			let tempName = viewName.replace(me.props.searchWord, `≈${me.props.searchWord}≈`);
			let tempNameArr = tempName.split('≈');
			tempNameArr.forEach((name, index) => {
				if (name === me.props.searchWord) {
					result.push((<span key={`name-${index}`} style={{color: '#FF2600'}}>{name}</span>));
				} else if (name) {
					result.push((<span key={`name-${index}`}>{name}</span>));
				}
			})
		} else {
			result.push((<span key={`name-0`}>{viewName}</span>));
		}
		view['nameArr'] = result;
		return view;
	};*/

	openView = url => {
		window.open(`${url}`, '_blank');
		return;
	};

	/*
	* 对大于1000的数字进行显示优化
	* 大于1千小于100百万，例如2000：2K+
	* 大于100百万小于1亿，例如2百万：2M+
	* 大于1亿，例如2亿：2B+
	*/
	optimizeData = data => {
		let viewData = data.toString();
		let length = viewData.length;

		if (length >= 4 && length < 7) {
			viewData = `${viewData.slice(0, length - 3)}K+`;
		} else if (length >= 7 && length < 9) {
			viewData = `${viewData.slice(0, length - 6)}M+`;
		} else if (length >= 9) {
			viewData = `${viewData.slice(0, length - 8)}B+`;
		}
		return viewData;
	};
	
	
	componentDidMount() {
		let me = this;
		// 判断是否要读取看板信息
		
		
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.view !== this.props.view) {
			this.setState({currentViewInfo: this.props.view});
		}
	}

	render() {
		let {currentViewInfo: view} = this.state;
		return (
			<div className={`${style['view-card-wrap']} ${this.props.boxSize===1?style['view-card-wrap-1']:''}`} onClick={() => this.openView(view.url)} style={{height:'12.6rem'}}>
				<div className={style['card-cover']}>
					{
						view.picurl ? (
							<img src={`/video/pic/${view.picurl}`}/>
						) : <img src={'/assets/view-default-cover.jpg'}/>
					}
					<div className={style['card-bg']} />
				</div>
				<div className={style['card-info']}>
					<div className={style['header']}>
						<div className={style['view-name']}>{view.name}</div>
						{/*<div className={style['view-name']}>{view.nameArr}</div>*/}
						<div className={style['item']}>
							
						</div>
					</div>
					
				</div>
			</div>
		);
	}
}
VideoInfoCard.defaultProps = {
	readOnly: true,
	// showCornerTip: false,
	callback: () => {
	},
	hasLogin: true,
	searchWord: '',
	boxSize: 0
};
VideoInfoCard.propTypes = {
	readOnly: PropTypes.bool, // 是否是创建人
	// showCornerTip: PropTypes.bool, // 是否判断显示角标提示
	view: PropTypes.object,
	callback: PropTypes.func, // 图谱操作的回调
	module: PropTypes.string, // 当前显示哪类图谱
	hasLogin: PropTypes.bool, // 是否已经登录
	searchWord: PropTypes.string,
	boxSize: PropTypes.number
};
