import React from "react";
import PropTypes from 'prop-types';
import {Modal, Row, Col, Tree, Tooltip, Checkbox} from "antd";

import {getNodeDisplayTitle, getNodeIcon} from "@/constants/vis.defaultDefine.1";

import ViewDataProvider from "@/components/common/dataProvider/common.dataProvider.view";
import Icon from "@/components/common/common.icon";

import style from "@/style/common/view/common.view.explore.less";
import {showErrorMessage} from "@/components/common/common.message";

class ExploreSmartSearchGraphModal extends React.Component {
  state = {
    savingStatus: 'idle',
    graphToSave: [
      false,
      false,
      false,
    ],
  };

  afterModalClose = () => {
    this.setState({
      savingStatus: 'idle',
      graphToSave: [
        false,
        false,
        false,
      ],
    });
  };

  getTreeNodeTitle = (parentId, node) => (
    node.description ? (
      <Tooltip
        key={'tip-' + parentId + '/' + node.id}
        placement={'left'}
        align={{offset: [-24, 0]}}
        title={(
          <span>
            {
              node.description.split('\n').map((line, idx) => (
                <span key={`ln-${idx}`}>{line}<br /></span>
              ))
            }
          </span>
        )}
        mouseLeaveDelay={0.05}
        overlayClassName={'light-theme'}
      >
        <span style={{width: '100%'}}>{getNodeDisplayTitle(node)}</span>
      </Tooltip>
    ) : (
      <span>{getNodeDisplayTitle(node)}</span>
    )
  );

  onSaveGraphToView = () => {
    let me = this, nodesToAdd = [], nodeIdList = [], edgesToAdd = [], targetNodeId = me.props.targetNode.id;

    me.state.graphToSave.forEach((save, idx) => {
      if (save) {
        let nodes = me.props.graphList[idx].nodes.slice(1),
          edges = me.props.graphList[idx].edges;

        nodes.forEach(node => {
          nodesToAdd.push({
            ...node,
            forceAdd: true,
            userConfirmed: true,
          });
          nodeIdList.push(node.id);
        });

        edges.forEach(edge => {
          let fromIndex = nodeIdList.indexOf(edge.from), toIndex = nodeIdList.indexOf(edge.to);
          edgesToAdd.push({
            ...edge,
            from: fromIndex === -1 ? targetNodeId : undefined,
            to: toIndex === -1 ? targetNodeId : undefined,
            fromIndex: fromIndex === -1 ? undefined : fromIndex,
            toIndex: toIndex === -1 ? undefined : toIndex,
          });
        });
      }
    })

    me.setState({savingStatus: 'processing'}, () => {
      me.props.viewDataProvider
        .saveRelationGraph(nodesToAdd, edgesToAdd, targetNodeId, false, 'explore', false)
        .then(() => {
          me.setState({savingStatus: 'succeeded'}, me.props.onClose);
        })
        .catch(({code, msg}) => {
          me.setState({savingStatus: 'failed'});
          showErrorMessage({code, msg});
        });
    });
  };

  render() {
    let me = this;

    return (
      <Modal
        title={`关系结构联想${me.props.targetNode ? `（${getNodeDisplayTitle(me.props.targetNode, 15)}）` : ''}`}
        visible={me.props.visible}
        onCancel={me.props.onClose}
        afterClose={me.afterModalClose}
        closable={me.state.savingStatus !== 'processing'}
        width={`calc(60rem + 48px)`}
        centered={true}
        cancelText={'取消'}
        okText={'加入图谱'}
        okButtonProps={{
          disabled: me.state.graphToSave.indexOf(true) < 0,
          loading: me.state.savingStatus === 'processing',
        }}
        onOk={me.onSaveGraphToView}
      >
        <Row style={{width: `${me.props.graphList.length * 20}rem`, margin: 'auto'}}>
          {me.props.graphList.map((graph, idx) => (
            <Col
              key={`col-${idx}`}
              span={24 / me.props.graphList.length}
              className={style['smart-search-graph-list']}
            >
              <Checkbox
                checked={me.state.graphToSave[idx]}
                onChange={e => {
                  let graphToSave = me.state.graphToSave;
                  graphToSave[idx] = e.target.checked;
                  me.setState({graphToSave});
                }}
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: '100%',
                  display: 'block',
                  fontWeight: 'bold',
                }}
              >
                {getNodeDisplayTitle(graph.tree.node)}
              </Checkbox>
              <Tree
                defaultExpandAll={true}
                className={`scrollbar-none ${me.state.graphToSave[idx] ? 'checked' : ''}`}
                selectable={false}
                switcherIcon={<Icon name="down" />}
              >
                {graph.tree.children.map(item => (
                  <Tree.TreeNode
                    title={me.getTreeNodeTitle(item.parentId, item.node)}
                    key={item.parentId + '/' + item.node.id}
                    dataRef={item}
                    switcherIcon={item.children.length > 0 ? null :
                      <Icon {...getNodeIcon(item.node)} color={undefined} style={{opacity: 0.7}}/>}
                  >
                    {
                      item.children.map(child => (
                        <Tree.TreeNode
                          title={me.getTreeNodeTitle(child.parentId, child.node)}
                          key={child.parentId + '/' + child.node.id}
                          dataRef={child}
                          switcherIcon={<Icon {...getNodeIcon(child.node)} color={undefined} style={{opacity: 0.7}}/>}
                        />
                      ))
                    }
                  </Tree.TreeNode>
                ))}
              </Tree>
            </Col>
          ))}
        </Row>
      </Modal>
    );
  }
}

ExploreSmartSearchGraphModal.propTypes = {
  targetNode: PropTypes.object,
  graphList: PropTypes.array.isRequired,
  viewDataProvider: PropTypes.instanceOf(ViewDataProvider).isRequired,
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ExploreSmartSearchGraphModal;