import React from 'react';
import PropTypes from 'prop-types';
import {message, Tooltip, Modal, Row, Col, Button} from 'antd';
import _ from 'lodash';

import ClampLines from '@/libs/my-react-clamp-lines';
import PB, {SimplePB} from '@/libs/simplePB';
import {NetworkDataLoadingStatus} from '@/libs/view/network/status';
import {NetworkEvents} from '@/libs/view/network/events';

import Icon from '@/components/common/common.icon';
import {showErrorMessage} from '@/components/common/common.message';
import ViewDataProvider from '@/components/common/dataProvider/common.dataProvider.view';

import MicroServicePanelOperation from '@/components/common/view/microService/panel/common.view.microService.panel.operation';
import MicroServicePanelParameter from '@/components/common/view/microService/panel/common.view.microService.panel.parameter';
import MicroServicePanelTarget from '@/components/common/view/microService/panel/common.view.microService.panel.target';
import MicroServicePanelResult from '@/components/common/view/microService/panel/common.view.microService.panel.result';
import {MicroServiceUIConfig} from '@/components/common/view/microService/shape/common.view.microService.shape.uiConfig';

import style from '@/style/common/microService/common.microService.less';

const defaultDescription = "根据您的图谱内容进行智能计算，计算结果可以对图谱中的节点进行增加、修改、删除或连接操作。";

class MicroServiceModalService extends React.Component {
  state = {
    hasTarget: false,

    locked: true,

    targets: {},
    parameters: {},

    // 结果加载状态参数
    loadingResult: false,
    hasMoreResult: false,
    lastLoadingFailed: false,
    autoLoadMore: false,

    hasCheckedKey: false,

    savingStatus: 'idle',
    operationType: undefined,
    statisticsByType: {},
  };

  getBackgroundTargetUpdater = () => {
    let me = this;
    return new Promise(((resolve, reject) => {
      if (me.props.viewDataProvider) {
        me.props.bus.emit('view', 'micro_service.service.target.background_updater.get', {
          viewId: me.props.viewDataProvider.viewId,
          serviceId: me.props.microServiceId,
          callback: updater => resolve(updater),
        });
      } else {
        reject();
      }
    }))
  };

  onClose = () => {
    let me = this;

    if (me.state.autoLoadMore && me.state.hasMoreResult && !me.state.lastLoadingFailed) {
      message.info(`${me.props.microServiceInfo.title}持续进行中，您可随时通过右侧按钮查看进度`);
    }

    me.props.onClose();
  };

  onDataRefreshed = (
    {
      locked,
      hasMoreResult,
      loadingResult,
      lastLoadingFailed,
      autoLoadMore,
      targets,
      parameters,

      /*actionOptions,*/
    }
  ) => {
    let me = this, newState = {};

    newState.locked = locked;

    newState.targets = _.cloneDeep(targets);
    newState.parameters = _.cloneDeep(parameters);

    newState.hasMoreResult = hasMoreResult;
    newState.loadingResult = loadingResult;
    newState.lastLoadingFailed = lastLoadingFailed;
    newState.autoLoadMore = autoLoadMore;

    // TODO actionOptions

    me.setState(newState);
  };

  onMainAction = () => {
    let me = this;

    if (me.props.viewDataProvider) {
      me.props.bus.emit('view', 'micro_service.service.action.main_operation',
        {viewId: me.props.viewDataProvider.viewId, serviceId: me.props.microServiceId,
          operation: me.state.operationType});
    }
  };

  onParameterSet = (name, value, key = 'value') => {
    let me = this, parameters = {...me.state.parameters};

    if (me.props.viewDataProvider) {
      me.props.bus.emit('view', 'micro_service.service.parameter.set',
        {viewId: me.props.viewDataProvider.viewId, serviceId: me.props.microServiceId, name, key,
          value});

      parameters[name] = parameters[name] || {};
      parameters[name][key] = value;
      me.setState({parameters});
    }
  };

  onResultCleared = () => {
    let me = this;

    me.setState({
      locked: false,

      loadingResult: false,
      hasMoreResult: false,
      lastLoadingFailed: false,
      autoLoadMore: false,

      hasCheckedKey: false,
    });
  };

  onResultDataFailedToLoad = ({code, msg}) => {
    let me = this;

    me.setState({
      loadingResult: false,
      lastLoadingFailed: true,
    }, () => {
      showErrorMessage({code, msg, extra: {viewId: me.props.viewDataProvider.viewId, isModification: false}});
    });
  };

  onResultDataLoaded = ({hasMoreResult}) => {
    let me = this;

    me.setState({
      loadingResult: false,
      lastLoadingFailed: false,
      hasMoreResult,
    });
  };

  onResultDataStartToLoad = ({autoLoadMore}) => {
    let me = this;

    me.setState({
      loadingResult: true,
      lastLoadingFailed: false,
      autoLoadMore,
    });
  };

  onRetry = () => {
    let me = this;

    if (me.props.viewDataProvider) {
      me.props.bus.emit('view', 'micro_service.service.on_load_more',
        {viewId: me.props.viewDataProvider.viewId, serviceId: me.props.microServiceId,
          autoLoadMore: me.state.autoLoadMore});
    }
  };

  onStart = () => {
    let me = this, callback = () => {
      if (me.props.viewDataProvider) {
        me.props.bus.emit('view', 'micro_service.service.on_load_more',
          {viewId: me.props.viewDataProvider.viewId, serviceId: me.props.microServiceId,
            autoLoadMore: me.state.autoLoadMore});
      }
    };

    me.setState({locked: true, autoLoadMore: true}, callback);
  };

  onStartOver = () => {
    let me = this, callback = () => {
      if (me.props.viewDataProvider) {
        me.props.bus.emit('view', 'micro_service.service.clear_result',
          {viewId: me.props.viewDataProvider.viewId, serviceId: me.props.microServiceId});
        me.props.bus.emit('view', 'micro_service.service.broadcast_data',
          {viewId: me.props.viewDataProvider.viewId, serviceId: me.props.microServiceId, preferResetTarget: true});
      }
    };

    if (me.state.autoLoadMore) {
      me.setState({autoLoadMore: false}, callback);
    } else {
      callback();
    }
  };

  onStatisticsRefreshed = ({statisticsByType}) => {
    let me = this;

    me.setState({statisticsByType});
  };

  onStop = () => {
    let me = this, callback = () => {
      if (me.props.viewDataProvider) {
        me.props.bus.emit('view', 'micro_service.service.stop_auto_load',
          {viewId: me.props.viewDataProvider.viewId, serviceId: me.props.microServiceId});
      }
    };

    if (me.state.autoLoadMore) {
      me.setState({autoLoadMore: false}, callback);
    } else {
      callback();
    }
  };

  onTargetSet = (name, value, key = 'value') => {
    let me = this;
    if (me.props.viewDataProvider) {
      me.props.bus.emit('view', 'micro_service.service.target.set',
        {viewId: me.props.viewDataProvider.viewId, serviceId: me.props.microServiceId, name, key, value});
    }
  };

  refreshData = () => {
    let me = this;

    if (me.props.viewDataProvider.getData().status === NetworkDataLoadingStatus.SUCCESS) {
      me.props.bus.emit('view', 'micro_service.service.broadcast_data',
        {viewId: me.props.viewDataProvider.viewId, serviceId: me.props.microServiceId, preferResetTarget: true});
    } else {
      me.props.viewDataProvider.once(me, NetworkEvents.LOADING_DATA_SUCCESS, () => {
        me.props.bus.emit('view', 'micro_service.service.broadcast_data',
          {viewId: me.props.viewDataProvider.viewId, serviceId: me.props.microServiceId, preferResetTarget: true});
      });
    }

    requestAnimationFrame(() => {
      if (me.props.microServiceConfig && me.props.microServiceConfig.operations.length > 0) {
        me.setState({operationType: me.props.microServiceConfig.operations[0].type});
      }
    })
  };

  componentDidMount() {
    let me = this;

    me.props.bus.with(me).subscribe('view', 'micro_service.service.current_data', data => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === data.viewId && me.props.visible
        && me.props.microServiceId === data.serviceId) {

        me.onDataRefreshed(data);
      }
    }).subscribe('view', 'micro_service.service.current_statistics', data => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === data.viewId && me.props.visible
        && me.props.microServiceId === data.serviceId) {

        me.onStatisticsRefreshed(data);
      }
    }).subscribe('view', 'micro_service.service.current_operation_ui', ({viewId, serviceId, savingStatus}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId && me.props.visible
        && me.props.microServiceId === serviceId) {

        // 暂不检查operation
        if (savingStatus !== undefined && me.state.savingStatus !== savingStatus) {
          me.setState({savingStatus});
        }
      }
    }).subscribe('view', 'micro_service.service.do_load_more', data => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === data.viewId && me.props.visible
        && me.props.microServiceId === data.serviceId) {

        me.onResultDataStartToLoad(data);
      }
    }).subscribe('view', 'micro_service.service.raw_data_loaded', data => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === data.viewId && me.props.visible
        && me.props.microServiceId === data.serviceId) {

        me.onResultDataLoaded(data);
      }
    }).subscribe('view', 'micro_service.service.failed_to_load', data => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === data.viewId && me.props.visible
        && me.props.microServiceId === data.serviceId) {

        me.onResultDataFailedToLoad(data);
      }
    }).subscribe('view', 'micro_service.service.result_cleared', data => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === data.viewId && me.props.visible
        && me.props.microServiceId === data.serviceId) {

        me.onResultCleared();
      }
    }).subscribe('view', 'micro_service.service.action.start', data => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === data.viewId && me.props.visible
        && me.props.microServiceId === data.serviceId) {

        me.onStart();
      }
    }).subscribe('view', 'micro_service.service.action.stop', data => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === data.viewId && me.props.visible
        && me.props.microServiceId === data.serviceId) {

        me.onStop();
      }
    }).subscribe('view', 'micro_service.service.action.start_over', data => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === data.viewId && me.props.visible
        && me.props.microServiceId === data.serviceId) {

        me.onStartOver();
      }
    }).subscribe('view', 'micro_service.service.action.retry', data => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === data.viewId && me.props.visible
        && me.props.microServiceId === data.serviceId) {

        me.onRetry();
      }
    });

    if (me.props.visible) {
      me.refreshData();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let me = this;

    if (!prevProps.visible && me.props.visible) {
      me.refreshData();
    }
  }

  componentWillUnmount() {
    this.props.bus.remove(this);
  }

  render() {
    let me = this,
      /** @type {TMicroServiceUIConfig} */ uiConfig = me.props.microServiceConfig;

    return (
      <React.Fragment>
        {
          uiConfig ? (
            <Modal
              title={me.props.microServiceInfo.title}
              visible={me.props.visible}
              closable={me.state.savingStatus !== 'processing'}
              width={`calc(70rem + 48px)`}
              bodyStyle={{display: 'flex', flexDirection: 'column'}}
              centered={true}
              onCancel={me.onClose}
              footer={[
                (<Button key={'close'} onClick={me.onClose}>关闭</Button>),
              ]}
            >
              <Row>
                <Col span={12} style={{paddingRight: '0.75em'}}>
                  <div className={style['description-frame']}>
                    <ClampLines
                      text={me.props.microServiceInfo.description || defaultDescription}
                      id={`ms-${me.props.microServiceInfo.id}-description`}
                      lines={2}
                      ellipsis={(
                        <React.Fragment>
                          ...
                          <Tooltip
                            placement={'top'}
                            title={(
                              <pre style={{whiteSpace: 'pre-wrap', marginBottom: 0}}>
                                {
                                  (me.props.microServiceInfo.description || defaultDescription).split('\n').map((line, idx) => (
                                    <span key={`ln-${idx}`}>{line}<br /></span>
                                  ))
                                }
                              </pre>
                            )}
                            overlayClassName={'light-theme'}
                            mouseLeaveDelay={0.05}
                          >
                            <Icon name={'info-circle'} style={{verticalAlign: '-0.2em', marginLeft: '0.5em'}} />
                          </Tooltip>
                        </React.Fragment>
                      )}
                      moreText={false}
                      lessText={false}
                    />
                  </div>
                  <MicroServicePanelTarget
                    config={uiConfig.target}
                    currentNodeId={me.props.currentNodeId}
                    locked={me.state.locked}
                    visible={me.props.visible}
                    targets={me.state.targets}
                    onTargetSet={me.onTargetSet}
                    onHasTargetChanged={hasTarget => me.setState({hasTarget})}
                    getBackgroundTargetUpdater={me.getBackgroundTargetUpdater}
                  />
                </Col>
                <Col span={12} style={{paddingLeft: '0.75em'}}>
                  {
                    me.state.operationType ? (
                      <MicroServicePanelOperation
                        type={me.state.operationType}
                        locked={me.state.locked}
                        hasTarget={me.state.hasTarget}
                        autoLoadMore={me.state.autoLoadMore}
                        hasMoreResult={me.state.hasMoreResult}
                        loadingResult={me.state.loadingResult}
                        mainActionDisabled={!me.state.locked || !me.state.hasCheckedKey}
                        statisticsByType={me.state.statisticsByType}
                        onStartClicked={me.onStart}
                        onStopClicked={me.onStop}
                        onStartOverClicked={me.onStartOver}
                        onMainActionClicked={me.onMainAction}
                      />
                    ) : null
                  }
                </Col>
              </Row>
              <Row>
                <Col span={12} style={{paddingRight: '0.75em'}}>
                  <MicroServicePanelParameter
                    config={uiConfig.parameter}
                    locked={me.state.locked}
                    onParameterSet={me.onParameterSet}
                    parameters={me.state.parameters}
                  />
                </Col>
                <Col span={12} style={{paddingLeft: '0.75em'}}>
                  <MicroServicePanelResult
                    operations={uiConfig.operations}
                    locked={me.state.locked}
                    autoLoadMore={me.state.autoLoadMore}
                    hasMoreResult={me.state.hasMoreResult}
                    lastLoadingFailed={me.state.lastLoadingFailed}
                    loadingResult={me.state.loadingResult}
                    onCheckStatusChanged={hasCheckedKey => {
                      if (me.state.hasCheckedKey !== hasCheckedKey) {
                        me.setState({hasCheckedKey});
                      }
                    }}
                    onOperationChanged={operationType => me.setState({operationType})}
                    viewDataProvider={me.props.viewDataProvider}
                    bus={me.props.bus}
                    microServiceId={me.props.microServiceId}
                    viewId={me.props.viewDataProvider ? me.props.viewDataProvider.viewId : undefined}
                    visible={me.props.visible}
                  />
                </Col>
              </Row>
            </Modal>
          ) : null
        }
      </React.Fragment>
    );
  }
}

MicroServiceModalService.defaultProps = {
  bus: PB,
};

MicroServiceModalService.propTypes = {
  viewDataProvider: PropTypes.instanceOf(ViewDataProvider).isRequired,
  microServiceId: PropTypes.string.isRequired,
  microServiceInfo: PropTypes.object.isRequired,
  microServiceConfig: PropTypes.shape(MicroServiceUIConfig).isRequired,
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired,
  currentNodeId: PropTypes.string,
  bus: PropTypes.instanceOf(SimplePB),
};

export default MicroServiceModalService;