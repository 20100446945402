import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {message,Popover, Drawer, Button, Checkbox} from 'antd';
import PB, {SimplePB} from '@/libs/simplePB';
import {IconTypes} from '@/constants/common';
import Icon from '@/components/common/common.icon';
import ViewDataProvider from "@/components/common/dataProvider/common.dataProvider.view";
import RelationDataProvider from '@/components/common/dataProvider/common.dataProvider.relation';
import {API_analysisNodeForEdgeMore,API_analysisNodeForEdgeDeep} from "@/api/relation";
import {uniqueArrayObj} from "@/utils/Common";
import style from '@/style/common/node/common.node.explore.analysis.incident.less';
import moment from "moment";
import {API_getEtyMoreInfo } from "@/api/relation";
import copy from 'copy-to-clipboard';
import {
  ICON_CATEGORY_CUSTOM,
  ICON_CATEGORY_TEXT,
  ICON_CATEGORY_COMPANY,
  ICON_CATEGORY_TALENT,
  ICON_CATEGORY_PATENT,
  ICON_CATEGORY_PAPER,
  ICON_CATEGORY_POLICY,
  ICON_CATEGORY_ORG,
  ICON_CATEGORY_INSTITUTE,
  ICON_CATEGORY_NEWS_ACTIVITIES,
  ICON_CATEGORY_TAG,
  ICON_CATEGORY_DOCS,
  ICON_CATEGORY_DATASET,
  ICON_CATEGORY_GOV,
  ICON_CATEGORY_NATURE,
  ICON_CATEGORY_INDEX,
  ICON_CATEGORY_GRAPH,
  ICON_CATEGORY_COLLEGE_AND_UNIVERSITY,
  ICON_CATEGORY_PARK,
  ICON_CATEGORY_TECHNOLOGY,
  ICON_CATEGORY_PROJECT,
  ICON_CATEGORY_PREPARE,
  ICON_CATEGORY_DOING,
  ICON_CATEGORY_FINISH,
  ICON_CATEGORY_FLAG_A,
  ICON_CATEGORY_FLAG_B,
  ICON_CATEGORY_FLAG_C,
  ICON_CATEGORY_FLAG_E,
  ICON_CATEGORY_FLAG_F,
  ICON_CATEGORY_FLAG_G,
  ICON_CATEGORY_TEXT_A,
  ICON_CATEGORY_TEXT_B,
  ICON_CATEGORY_TEXT_C,
  ICON_CATEGORY_TIP_A,
  ICON_CATEGORY_TIP_B,
  ICON_CATEGORY_TIP_C,
  ICON_CATEGORY_TIP_D,
  ICON_CATEGORY_TIP_E,
  ICON_CATEGORY_TIP_F,
  ICON_CATEGORY_TIP_G,
  ICON_CATEGORY_TIP_H,
  ICON_CATEGORY_NO_ICON,
  ICON_CATEGORY_GOOD,
  ICON_CATEGORY_BAD,
  ICON_CATEGORY_WATCH,
  iconConfig, getNodeIconType,
} from "@/constants/iconConfig";

const latestCompare = (aNode, bNode) => -`${aNode['updateTime'] || aNode['linkTime']}`
  .localeCompare(`${bNode['updateTime'] || bNode['linkTime']}`);

const earliestCompare = (aNode, bNode) => `${aNode['updateTime'] || aNode['linkTime']}`
  .localeCompare(`${bNode['updateTime'] || bNode['linkTime']}`);

  const iconTypes = [
    ICON_CATEGORY_FLAG_A,
    ICON_CATEGORY_FLAG_B,
    ICON_CATEGORY_FLAG_C,
    ICON_CATEGORY_FLAG_E,
    ICON_CATEGORY_FLAG_F,
    ICON_CATEGORY_FLAG_G,
    ICON_CATEGORY_TIP_A,
    ICON_CATEGORY_TIP_B,
    ICON_CATEGORY_TIP_C,
    ICON_CATEGORY_TIP_D,
    ICON_CATEGORY_TIP_E,
    ICON_CATEGORY_TIP_F,
    ICON_CATEGORY_TIP_G,
    ICON_CATEGORY_TIP_H,
  ];

class AnalysisNodeForEdge extends React.PureComponent {

  state = {
    status: 'idle',
    msg:'',
    bus_data: {keyword:''},
    dataList:[],
    dataNum:0,
    showDrawer: false,
    showDrawerList: false,
    showDrawerDetail: false,
    nodeListSelectionMap: {},
    nodeListSelectedAmount: 0,
    nodeListAllSelectedIndeterminate: false,
    nodeListAllSelected: false,
    incidentDetail: {},
    APIPage: 0,
    activeKey: 'event',
    isDeep: false,
    sortBy: 'latest', // 排序方案，默认 connection 还支持 latest, alpha, custom
  };

  pageNo = 0;
  contrastNodeList = [];
  dataCache = {};
  filterFn = undefined;

  onNodeListSelectAllChanged = e => {
    let me = this, nodeListAllSelected = e.target.checked, nodeListSelectionMap = {}, nodeListSelectedAmount = 0;
    if (nodeListAllSelected) {
      Object.keys(me.state.nodeListSelectionMap).forEach(k => nodeListSelectionMap[k] = true);
      nodeListSelectedAmount = Object.keys(me.state.nodeListSelectionMap).length;
    }else{
      Object.keys(me.state.nodeListSelectionMap).forEach(k => nodeListSelectionMap[k] = false);
    }
    me.setState({
      nodeListAllSelected,
      nodeListAllSelectedIndeterminate: false,
      nodeListSelectionMap,
      nodeListSelectedAmount,
    });
  };

  onNodeListSelectionChanged = (nodeId, e) => {
    let me = this, selected = e.target.checked,
      nodeListSelectedAmount = me.state.nodeListSelectedAmount,
      nodeListSelectionMap = {...me.state.nodeListSelectionMap};

    if (selected) {
      nodeListSelectedAmount++;
      nodeListSelectionMap[nodeId] = true;
    } else {
      nodeListSelectedAmount--;
      nodeListSelectionMap[nodeId] = false;
    }

    me.setState({
      nodeListAllSelected:  Object.keys(me.state.nodeListSelectionMap).length === nodeListSelectedAmount,
      nodeListAllSelectedIndeterminate: nodeListSelectedAmount > 0 &&  Object.keys(me.state.nodeListSelectionMap).length > nodeListSelectedAmount,
      nodeListSelectionMap: nodeListSelectionMap,
      nodeListSelectedAmount: nodeListSelectedAmount,
    });
  };

  doDataRefreshed = () => {
    let me = this,nodeListSelectionMap={};
    if(me.contrastNodeList.length==0){
      return ;
    }
    let params ={
      currentNodeInfo:me.state.bus_data.nodeInfo,
      contrastNodeList:me.contrastNodeList.slice(0,10)
    }
    API_analysisNodeForEdgeMore(me.state.bus_data.viewId,me.state.bus_data.nodeId,params).then((response) => {     
      if(response && response.data && response.data.code === 0){
        let _data = [];
        response.data.data.forEach((item, idx) => {
          nodeListSelectionMap[item.id] = false;
          _data.push(item);
        });
        _data.sort((a, b) => b.prob - a.prob);
        me.setState({
          status: 'success',
          dataList: _data,
          nodeListAllSelected: false,nodeListAllSelectedIndeterminate: false,nodeListSelectionMap,nodeListSelectedAmount:0
        });
      }else{
        me.setState({
          status: 'success',
          dataList: [],
          msg:'未获取到数据',
          nodeListAllSelected: false,nodeListAllSelectedIndeterminate: false,nodeListSelectionMap,nodeListSelectedAmount:0
        });
      }
    }).catch(({code, msg}) => {
      me.setState({
        status: 'failed',
        dataList: [],
        msg:msg,
        nodeListAllSelected: false,nodeListAllSelectegetDetaildIndeterminate: false,nodeListSelectionMap,nodeListSelectedAmount:0
      });
    });
  }

  doDataDeep = () => {
    let me = this,nodeListSelectionMap={};
    if(me.contrastNodeList.length==0){
      return ;
    }
    let params ={
      currentNodeInfo:me.state.bus_data.nodeInfo,
      contrastNodeList:me.contrastNodeList.slice(0,100)
    }
    API_analysisNodeForEdgeMore(me.state.bus_data.viewId,me.state.bus_data.nodeId,params).then((response) => {
      if(response && response.data && response.data.code === 0){
        let _data = [];
        response.data.data.forEach((item, idx) => {
          nodeListSelectionMap[item.id] = false;
          _data.push(item);
        });
        _data.sort((a, b) => b.prob - a.prob);
        me.setState({
          status: 'success',
          dataList: _data,
          nodeListAllSelected: false,nodeListAllSelectedIndeterminate: false,nodeListSelectionMap,nodeListSelectedAmount:0
        });
      }else{
        me.setState({
          status: 'success',
          dataList: [],
          msg:'未获取到数据',
          nodeListAllSelected: false,nodeListAllSelectedIndeterminate: false,nodeListSelectionMap,nodeListSelectedAmount:0
        });
      }
    }).catch(({code, msg}) => {
      me.setState({
        status: 'failed',
        dataList: [],
        msg:msg,
        nodeListAllSelected: false,nodeListAllSelectegetDetaildIndeterminate: false,nodeListSelectionMap,nodeListSelectedAmount:0
      });
    });
  }

  onDataRefreshed = () => {
    let me = this;
    me.setState({status: 'idle',start:0,dataNum:0,isDeep:false}, () => {
      me.doDataRefreshed();
    });
  };

  onDataDeep = () => {
    let me = this;
    me.setState({status: 'idle',start:0,dataNum:0,isDeep:true}, () => {
      me.doDataDeep();
    });
  };

  onClose = () => {
    this.setState({
      showDrawer: false,
      status: 'idle',
      dataList: []
    });
  }

  addNodes = (e) => {
    e.stopPropagation();
    let me = this;
    let nodeIds = Object.keys(me.state.nodeListSelectionMap).filter(id => me.state.nodeListSelectionMap[id]);
    if(nodeIds.length>0){
      me.doAdd(me.state.bus_data.nodeId,nodeIds);
    }
  }

  doAdd = (nodeId, nodeIds) => {
    let me = this;
    me.props.viewDataProvider.addRelation(nodeId, nodeIds).then(() => {
      try {
        message.success('操作成功');
        this.props.bus.emit('relation', 'find_node_by_text.start', {text: ''});
        me.setState({
          dataList: [],
          nodeListAllSelected: false,nodeListAllSelectegetDetaildIndeterminate: false,nodeListSelectionMap,nodeListSelectedAmount:0
        }, () => {
          me.onClose();
        });
      } catch (e) {
        // ignore
      }
    });
  };

  loadMore  = () => {
    let me = this;
    me.pageNo++;
    me.onDataRefreshed();
  };

  onCopyNodeInfo = item => {
    let me = this, textList = [];
    textList.push(item.fname);
    textList.push(item.tag);
    if (textList.length <= 0) {
      message.warn('无可复制的文本。');
      return;
    }

    let result = copy(textList.join("\r\n"), {
      message: '请按下 #{key} 复制选中文本。',
    });

    if (result) message.success('推荐的候选信息已经复制到剪贴板。');
  };

  copySelectedNodesToClipboard = () => {
    let me = this, textList = [];
    me.state.nodeListSelectedAmount>0 && this.state.dataList.forEach(item => {
      if (item && me.state.nodeListSelectionMap[item.id]) {
        textList.push(item.fname + ' ' + item.tag);
      }
    })
    if (textList.length <= 0) {
      message.warn('请先选择要复制的文本。');
      return;
    }
    let result = copy(textList.join("\r\n"), {
      message: '请按下 #{key} 复制选中文本。',
    });

    if (result) message.success('选中文本已复制到剪切板。');
  };

  getFilterFn = () => {
    let me = this;
    if (!me.filterFn) {
      me.filterFn = {
        latest: latestCompare,
      }
    }
    return me.filterFn[me.state.sortBy];
  };

  doStatistics = ({nodes, edges}) => {
    let me = this, myNodeIds = [], connectedIds = [];
    if (nodes) {
      let contrastNodeList = [];
      nodes.sort(me.getFilterFn()).forEach(node => {
        let iconType = getNodeIconType(node),
          nodeTime = moment(node.updateTime || node['createTime'], 'YYYY-MM-DD HH:mm:ss')
            .format('YYYY-MM-DD');
        if(iconTypes.includes(iconType)){
          contrastNodeList.push({id:node.id,fname:node.fname,tag:node.tag,description:node.description});
        }
      });
      me.contrastNodeList = contrastNodeList;
    }
  };
  
  componentDidMount() {
    let me = this;

    me.props.bus.with(me).subscribe('right.box.analysis', 'analysis.nodeforedge.list.show', data => {
      if (me.props.viewDataProvider && me.props.visible) {
        me.pageNo = 0;
        me.doStatistics(me.dataCache);
        me.setState({
          showDrawer: true,
          status: 'idle',
          bus_data: data,isDeep:false
        }, () => {
          me.doDataRefreshed();
        });
      }
    });

    me.props.bus.subscribe(me, 'relation', 'data.loaded', ({nodes, edges}) => {
      me.dataCache = {nodes, edges};
    });

    me.props.bus.subscribe(me, 'relation', 'data.after_change', ({nodes, edges}) => {
      me.dataCache = {nodes, edges};
    });

  }
  componentDidUpdate(prevProps, prevState, snapshot) {
  }

  componentWillUnmount() {
    let me = this;
    me.pageNo = 0;
  }

  render() {
    let me = this, tabContent,moreContent;
    if (me.contrastNodeList.length > 0){
      if (me.state.status === 'success') {
        tabContent = me.state.dataList && me.state.dataList.length>0 ? (
          <ul className={style['list-content-li-box']}>
            {me.state.dataList.map((item, index) => (
              <li style={{marginBottom:'1.6rem'}} onClick={e => {
                e.stopPropagation();
                me.onCopyNodeInfo(item);
              }}>
                          <div className={style['title']}>
                            <Checkbox style={{marginRight: '0.7rem',float:'left',fontSize:'1.1rem'}}
                              onClick={e => e.stopPropagation()}
                              onChange={e => me.onNodeListSelectionChanged(item.id, e)}
                              checked={me.state.nodeListSelectionMap[item.id] === true}/>
                            <span style={{color:'rgb(117, 203, 251)'}}>〖{item.prob.toFixed(2)}〗</span>
                            <span className={style['node-dot']} style={{float:'inherit',marginRight:0}}> ○ </span>
                            <span className={style['node-fname']}>{item.fname}</span>
                          </div>
                          <div className={style['kws']} style={{marginLeft:'8.5rem',overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap'}}>
                              {item.tag}</div>
                </li>)
              )}
          </ul>
        ) : ( <div className={style['market-drawer-body-tab-content-empty']}>
              <Icon name={'exclamation-circle'} theme={'outlined'} /><br />
              <span>没有找到相关信息</span><br />
            </div>
        );

      }else if(me.state.status === 'failed'){
        tabContent = (<div className={style['market-drawer-body-tab-content-loading']}>
          <Icon name={'exclamation-circle'} theme={'outlined'} /><br />
          <span>数据准备中，请稍后<a onClick={() => me.onDataRefreshed()}>&lt;重试&gt;</a></span>
        </div>)
      }else{
        tabContent = (<div className={style['market-drawer-body-tab-content-loading']}>
          <Icon name={'loading'} theme={'outlined'} /><br />
          数据加载中，请稍后…
        </div>)
      }
    }else{
      tabContent = (<div className={style['market-drawer-body-tab-content-empty']}>
        <Icon name={'exclamation-circle'} theme={'outlined'} /><br />
        <span>没有找到推荐连接</span><br />
        <span>您可以先选择图谱中的部分节点设置标记为旗帜或标签，再来搜索</span><br />
      </div>)
    }

    return (
      <React.Fragment>
        {
          me.state.showDrawer ? (
            <Drawer
              visible={me.props.visible}
              placement={'right'}
              destroyOnClose={true}
              mask={true}
              maskClosable={true}
              maskStyle={{backgroundColor: 'transparent'}}
              width={'46rem'}
              className={`${style['market-drawer-wrap']}`}
              onClose={me.onClose}
              style={{zIndex:1001,opacity:1}}
            >
              <div style={{ height: '100%',backgroundColor:'#fff',opacity:1}}>
                <div className={style['market-drawer-header']}>
                  <div className={style['market-drawer-title']} style={{flex:'auto'}}>推荐连接</div>
                  <div className={style['market-drawer-right']}>
                    
                  </div>
                </div>
                <div className={style['market-drawer-body']}>
                  <div className={`${style['market-drawer-body-tab-content']} scrollbar scrollbar-none`}>
                    {tabContent}
                  </div>
                </div>
                <div className={style['action-box']}>
                  <div className={style['action-right']}>
                    {!me.state.isDeep &&
                    <Button style={{marginRight:'1rem'}} type={'primary'} ghost onClick={() => me.onDataDeep()} disabled={me.state.status === 'idle'} block={true}>
                      <Popover
                        placement={"top"}
                        content={"您可以进行深度搜索获取更多内容，深度搜索时间比较长，请耐心等待"}
                      >深度搜索</Popover>
                    </Button>}
                    <Button type={'primary'} ghost onClick={me.copySelectedNodesToClipboard} disabled={!me.state.nodeListSelectedAmount} block={true} style={{marginRight:'1rem'}}>复制选中内容</Button>
                    <Button type={'primary'} onClick={e => this.addNodes(e)} disabled={!me.state.nodeListSelectedAmount} block={true}>连接到当前节点</Button>
                  </div>
                </div>
              </div>
            </Drawer>
          ) : null
        }
        
      </React.Fragment>
    );
  }
}

AnalysisNodeForEdge.defaultProps = {
  bus: PB,
};

AnalysisNodeForEdge.propTypes = {
  bus: PropTypes.instanceOf(SimplePB),
  viewDataProvider: PropTypes.instanceOf(ViewDataProvider).isRequired,
  visible: PropTypes.bool.isRequired,
  viewId: PropTypes.string,
  nodeInfo: PropTypes.object,
};

export default AnalysisNodeForEdge;