import React, { Flagments } from 'react';
// import _ from 'lodash';
import PropTypes from 'prop-types';
import { message } from 'antd';
// noinspection SpellCheckingInspection
// import anime from 'animejs';
import style from '@/style/common/relation/common.relation.bigArrow.less';
import PB from "@/libs/simplePB";
// import ViewDataProvider from "@/components/common/common.dataProvider.view";

// 四周的padding
const margin = 8;
// 大箭头大小
const ba_width = 100;
const ba_height = 100;
// 箭头默认位置
const ba_default_pos = {
    x: (document.body.offsetWidth) * 0.5,
    y: (document.body.offsetHeight) * 0.5 - ba_height / 7,
};

class BigArrow extends React.Component {
    element = undefined; // 箭头对象
    anim = undefined; // 动画对象

    constructor(props) {
        super(props);
        this.state = {
            Refresh: false,
            arrows: [
                'bigArrow0',
                'bigArrow1',
                'bigArrow2',
                'bigArrow3',
                'bigArrow4',
            ],
            // 正在显示的箭头数组 0 ~ 4
            visibleArr: [],
        };
        this.moving = false;
        this.lastXY = {};
        this.translateXY = {};
        document.onmouseup = e => this.onMouseUp(e);
        document.onmousemove = e => this.onMouseMove(e);
    }

    onMouseDown(e, refId) {
        // console.log('BigArrow -> onMouseDown -> e:', e);
        e.stopPropagation();
        this.moving = refId;
    }

    onMouseUp() {
        // console.log('BigArrow -> onMouseUp -> this.moving：', this.moving);
        if (this.state.arrows.indexOf(this.moving) > -1) {
            this.lastXY[this.moving] = { x: null, y: null }
        }
        this.moving = false;
    }

    onMouseMove(e) {
        // console.log('BigArrow -> onMouseMove -> this.moving=', this.moving);
        (this.state.arrows.indexOf(this.moving) > -1) && this.onMove(e, this.moving);
    }

    onMove(e, refId) {
        // console.log('BigArrow -> onMove -> refId=', refId);
        // console.log('BigArrow -> onMove -> this.moving=', this.moving);
        // console.log('BigArrow -> onMove -> this.translateXY=', this.translateXY);
        // console.log('BigArrow -> onMove -> this.lastXY=', this.lastXY);

        // 被移动元素的左上角坐标
        this.element = this.refs[refId];
        // console.log('BigArrow -> this.element:', this.element);
        // this.parentBoundingClientRect = this.element.parentElement.getBoundingClientRect();
        this.parentBoundingClientRect = this.element.getBoundingClientRect();
        // this.parentBoundingClientRect = this.element.offsetParent.getBoundingClientRect();
        const { top, left } = this.parentBoundingClientRect;

        // (最近)容器的宽高
        const offsetWidth = this.element.offsetParent.offsetWidth;
        const offsetHeight = this.element.offsetParent.offsetHeight;

        let lastXY = ba_default_pos;
        if (this.lastXY.hasOwnProperty(refId)) {
            lastXY = this.lastXY[refId];
        }
        let translateXY = ba_default_pos;
        if (this.translateXY.hasOwnProperty(refId)) {
            translateXY = this.translateXY[refId];
        }

        const { areaLeft, areaTop, areaBottom, areaRight } = this.props;

        if (lastXY && lastXY['x'] && lastXY['y']) {
            let dx = e.clientX - lastXY['x'];
            let dy = e.clientY - lastXY['y'];

            let newTranslateXY = {
                x: (translateXY['x'] + dx) > areaLeft ? ((translateXY['x'] + dx) < (offsetWidth - areaRight - 32) ? (translateXY['x'] + dx) : (offsetWidth - areaRight - 32)) : areaLeft,
                y: (translateXY['y'] + dy) > areaTop ? ((translateXY['y'] + dy) < (offsetHeight - areaBottom - 32) ? (translateXY['y'] + dy) : (offsetHeight - areaBottom - 32)) : areaTop,
            };
            this.translateXY[refId] = newTranslateXY;
            this.setState({
                Refresh: !this.state.Refresh,
            });
        }

        // 鼠标的最后位置
        if (refId) {
            this.lastXY[refId] = {
                x: e.clientX > (areaLeft + left) ? (e.clientX < (offsetWidth - areaRight - 32) ? e.clientX : (offsetWidth - areaRight - 32)) : (areaLeft + left),
                y: e.clientY > (areaTop + top) ? (e.clientY < (offsetHeight - areaBottom - 32) ? e.clientY : (offsetHeight - areaBottom - 32)) : (areaTop + top),
            };
        }
    }

    onDoubleClick = (refId) => {
        PB.emit('bigArrow', 'show', refId);
    };

    render() {
        const { arrows, visibleArr } = this.state;
        const { width, height } = this.props;
        let thisCursor = 'dfault';
        if (!!this.moving) {
            thisCursor = 'move'
        }
        const styleArr = [];
        arrows.forEach((refId) => {
            if (!this.translateXY.hasOwnProperty(refId)) {
                this.translateXY[refId] = ba_default_pos;
            }
            styleArr.push({
                refId,
                style: {
                    overflow: 'hidden',
                    display: visibleArr.indexOf(refId) > -1 ? null : 'none',
                    transform: `translateX(${this.translateXY[refId]['x']}px)translateY(${this.translateXY[refId]['y']}px)`,
                    width: width ? `${width}px` : `${ba_width}px`,
                    height: height ? `${height}px` : `${ba_height}px`,
                    cursor: thisCursor,
                },
            });
        });

        return (
            <div>
                {
                    styleArr.map((item) => {
                        return (
                            <div
                                key={item['refId']}
                                ref={item['refId']}
                                className={style['big-arrow']}
                                onMouseDown={e => {
                                    this.onMouseDown(e, item['refId'])
                                }}
                                onDoubleClick={(e) => {
                                    this.onDoubleClick(item['refId'])
                                }}
                                style={item['style']}
                            >
                                <img src={`/assets/arrows/${item['refId']}.svg`} alt="指示箭头" />
                            </div>
                        );
                    })
                }

            </div>
        )
    }

    /**
     * 数组删除元素返回新数组
     *
     * @memberof BigArrow
     */
    ArrRemoveItem = (arr, value) => {
        let arr2 = [];
        var len = arr.length;
        for (var i = 0; i < len; i++) {//把出了要删除的元素赋值给新数组
            if (arr[i] != value) {
                arr2.push(arr[i]);
            }
        }
        return arr2;
    };

    componentDidMount() {
        let me = this;
        // 初始化最后位置
        this.state.arrows.forEach((refId) => {
            this.lastXY[refId] = { x: null, y: null }
        });
        // console.log('BigArrow -> componentDidMount -> this.lastXY=', this.lastXY);

        // 订阅触发事件
        PB.sub(this, 'bigArrow', 'show', (refId) => {
            if (me.state.arrows.indexOf(refId) > -1) {
                let newVisibleArr = this.ArrRemoveItem(this.state.visibleArr, refId);
                let existIdx = this.state.visibleArr.indexOf(refId);
                if (existIdx > -1) {
                    newVisibleArr = this.ArrRemoveItem(this.state.visibleArr, refId);
                } else {
                    newVisibleArr = this.state.visibleArr.concat([refId]);
                }
                // 清除最后停留的位置
                me.lastXY[refId] = {
                    x: null,
                    y: null,
                }

                // 重置对应箭头的初始位置
                me.translateXY[refId] = ba_default_pos;
                // 显示大箭头
                me.setState({
                    visibleArr: newVisibleArr,
                })
            } else {
                message.warn('无效的箭头标记');
            }

        });
    }


    componentWillUnmount() {
        PB.remove(this);
    }

}

BigArrow.defaultProps = {
    dockAtLeft: margin,
    dockAtTop: -margin,
    areaTop: margin,
    areaBottom: margin,
    areaLeft: margin,
    areaRight: margin,
};

BigArrow.propTypes = {
    width: PropTypes.number, // 箭头宽度
    height: PropTypes.number, // 箭头高度
    areaTop: PropTypes.number, // 可用区上边距
    areaBottom: PropTypes.number, // 可用区下边距
    areaLeft: PropTypes.number, // 可用区左边距
    areaRight: PropTypes.number, // 可用区右边距
};

export default BigArrow;

