/*
 * @Author: Carr.s
 * @Date: 2022-04-08 11:28:40
 * @LastEditTime: 2022-05-17 08:35:16
 * @LastEditors: Carr.s
 * @Description: 洞察 人脉关系
 * @Copyright by joinmap
 */
import React from "react";
import { notification } from "antd";
import ViewInsightChartTpl from "@/components/insightView/template/insight.chart.tpl";
import { connect } from "react-redux";
// import styles from "@/style/components/insightView/insight.less";

class ViewPeopleRelationInsight extends ViewInsightChartTpl {
  //===============================================================
  // begin of overwrite
  // 使用默认的方式就不用重写

  // ------------------------------------------------------------
  // 全局配置
  // ------------------------------------------------------------
  // 显示底部按钮
  showToolbar = false;
  // 洞察名称
  viewerTitle = "人脉关系";

  // ------------------------------------------------------------
  // 层级分析对象的配置
  // ------------------------------------------------------------
  // 第一层 level1 tag
  // column1_DataField = "data"; // 取值字段
  // column1_TotalField = "dataTotal"; // 计算总数(与返回数量不一定一致)字段
  column1_Title = "人物"; // 显示名称
  column1_Unit = "人"; // 显示的文字单位
  /*   
  column1_ValueRender = (level1_data, idx,nodePath=undefined) => {
    // 右侧数值渲染，不输出则空白
    // 必须加上 className={styles["line-value"]}
    return (
      <div className={styles["line-value"]}>
        {level1_data["value"] + level1_data["valueUnit"]}
      </div>
    );
  };
  column1_IconRender = (level1_data, idx,nodePath=undefined) => {
    // 左侧图表渲染，不输出则空白
    // 必须加上 className={styles["line-icon"]}
  };
  column1_HoverTitle = (level1_data, idx,nodePath=undefined) => {
    // 列表鼠标悬浮提示文字
    return `${this.column1_Title}：${level1_data["name"]}\n${
      this.column2_Title
    }：${level1_data[this.column2_DataField]}${
      level1_data["valueUnit"]
    }\n点击打开${this.column2_Title}列表`;
  };
 */
  // 第二层 item
  column2_DataField = "people"; // 取值字段
  column2_TotalField = "peopleTotal"; // 计算总数(与返回数量不一定一致)字段
  column2_Title = "关联人物"; // 显示名称
  column2_Unit = "人"; // 显示的文字单位
  column2_ValueRender = (level2_data, idx) => {
    // 右侧数值渲染，不输出则空白
    // 必须加上 className={styles["line-value"]}
  };
  /*   
  column2_IconRender = (level2_data, idx) => {
    // 左侧图表渲染，不输出则空白
    // 必须加上 className={styles["line-icon"]}
  };
 */
  column2_HoverTitle = (level2_data, idx) => {
    // 列表鼠标悬浮提示文字
    return `${this.column2_Title}：${level2_data["name"]}\n点击打开相关新闻链接`;
  };

  // ------------------------------------------------------------
  // 接口的配置
  // ------------------------------------------------------------
  // 接口 url path
  api_path = "/wth_server_api/ren_mai_mode_by_view_id";

  // 返回数据默认值
  defaultLimit = 60;

  /**
   * 【继承组件要重写】
   * api 请求完成后，setState结束后的 callback
   */
  apiLoadedCallback = () => {
    if (this.state._column1Len > 0 && this.chartType === "echarts") {
      notification.open({
        duration: 8,
        message: "操作提示",
        description: (
          <div>
            <div>洞察数据加载成功</div>
            <div>
              图表支持鼠标<b>缩放</b>、<b>平移</b>操作。
            </div>
          </div>
        ),
      });
    }
  };

  // ------------------------------------------------------------
  // 图表的配置
  // ------------------------------------------------------------
  // 图表显示库
  chartType = "echarts";
  chartStyle = {
    top: 0,
    bottom: 0,
  };
  // chartDivLeft = 300;
  chartCompomentStyle = {
    width: "100%",
    height: "100%",
  };

  /**
   * 生成公用的、默认的 option
   * @returns
   */
  initChartOption = () => {
    let _chartOption = {
      // colors: [colors[1]],
      // backgroundColor: 'rgb(41,50,61)',
      // backgroundColor: '#030E24',
      animation: true,
      animationDuration: 800,
      animationEasing: "cubicInOut",
      animationDurationUpdate: 500,
      animationEasingUpdate: "quinticInOut", // cubicInOut
      grid: {
        // show: true,
        // top: "10%",
      },
      xAxis: [],
      yAxis: [],
      visualMap: {
        show: false,
        min: 0,
        max: 18,
        right: "1%",
        bottom: "4%",
        text: ["高", "低"], // 文本，默认为数值文本
        textStyle: {
          color: "#ccc",
        },
        itemWidth: 7,
        itemHeight: 100,
        calculable: false,
        seriesIndex: [
          // this.state.chartsSeries.indexOf("pin_scatter"),
          // this.state.chartsSeries.indexOf("effect_scatter"),
        ], // 指定对第二个序列视觉映射
        inRange: {
          // color: ["#37A2DA", colors[colorIndex][n]] // 蓝绿
          // 中间有绿色
          // color: ['lightgrey', 'lightgreen', 'green', 'yellow', 'gold', 'orangered']
          // 中间无绿色
          // color: ['lightgrey', 'grey', 'yellow', 'gold', 'orangered'],
          // color: ['lightgrey', 'yellow', 'gold', 'orangered'],
          // --------
          // color: ['#d94e5d', '#eac736', '#50a3ba'].reverse(),
          // color: ['#1260A8', '#4394C2', '#66A9D7', '#96C6DD', '#18639E'].reverse(),
          // color:[ '#d48265', '#91c7ae', '#c4ccd3'].reverse(),
          // color: ['#c23531', '#61a0a8', '#2f4554'].reverse(),
          // color: ['#c23531', '#67d08b', '#eec41b'].reverse(),
          // color: ['#2f83e4', '#23cbff', '#00e5c1'].reverse(),
          // color: ['#3c72a6', '#2ca681', '#f6e628'],
          // color: ['#3c72a6', '#2ca681', '#edb73e'],
          // MIT
          // color: ['#ff3534', '#f18989', '#eea9a9', '#e6e6e6', '#a9a9ee', '#8989f1', '#3435ff'],
          // color: ['#eea9a9', '#e6e6e6', '#a9a9ee', '#8989f1', '#3435ff'],
          // xiaya
          // color: ['#fe8284', '#4ccbce', '#999900'],
          // color: ['#33cc66', '#4ccbce', '#999900'],
          // color: ['#e59517', '#0664ed', '#058eb5'], // x
          color: ["#d2c057", "#f28b54", "#f28b54"], // x
          // color: ['#04a3ac', '#846ac8', '#2b62d7'], //
          // color: ['#01fd77', '#1ceef9', '#2c96ff'], //
        },
      },
      series: [],
    };
    return _chartOption;
  };

  /**
   * 【继承组件要重写】
   * 生成 chart 的 options
   * @param {int} idx 选中的数据下标
   */
  refreshChartOptions = (idx) => {
    // icon的前缀
    const {
      query: { isDev, isDebug },
    } = this.props;
    if (isDebug) console.log("refreshChartOptions idx:", idx);
    let flagPrefix = "https://vip.joinmap.ai";
    if (isDev) {
      flagPrefix = "http://localhost:3000";
    }
    let me = this;
    // const { column2_DataField } = this.state;
    me._chartOption = this.initChartOption();
    // 生成 graph 数据
    let nodes_dict = [];
    me._chartData.forEach((i0, idx0) => {
      nodes_dict[i0.name] = idx0;
    });
    // console.log("nodes_dict=", nodes_dict);

    let nodes = [];
    let links = [];
    let people_not_in = [];
    me._chartData.forEach((i1, idx1) => {
      // links
      i1.people.forEach((i2, idx2) => {
        if (nodes_dict.hasOwnProperty(i2.name)) {
          links.push({
            target: idx1,
            source: nodes_dict[i2.name],
            _type: "edge",
            lineStyle: {
              color: "rgba(255,255,255,0.3)",
              width: 1,
            },
          });
        } else {
          people_not_in.push(i2.name);
        }
      });

      // nodes
      nodes.push({
        id: idx1,
        name: i1.name,
        value: i1.peopleTotal,
        news: i1.news,
        _type: "node",
        symbol:
          idx == idx1
            ? `image://${flagPrefix}/assets/icons/user_2.svg`
            : `image://${flagPrefix}/assets/icons/user.svg`,
      });
    });

    // console.log("不在节点中的人物:", people_not_in);
    // console.log("nodes:", nodes);

    // 再次计算一些绘图数据
    links.forEach((l, lidx) => {
      // 重新循环links，把包含指定 idx 节点的边全部高亮
      if (l.target == idx || l.source == idx) {
        links[lidx].lineStyle = {
          color: "#5470C6",
          width: 5,
        };
      }

      // 重新循环数据, 把指定节点的连接节点更换图表
      // console.log("l:", l);
      if (l.target == idx) {
        nodes[
          l.source
        ].symbol = `image://${flagPrefix}/assets/icons/user_1.svg`;
      } else if (l.source == idx) {
        nodes[
          l.target
        ].symbol = `image://${flagPrefix}/assets/icons/user_1.svg`;
      }
    });

    // 环形 数据列
    me._chartOption.series.push({
      roam: true,
      name: "人脉关系",
      type: "graph",
      layout: "circular",
      circular: {
        rotateLabel: true,
      },
      data: nodes,
      links: links,
      symbolSize: function (val) {
        // return val;
        // 范围 10~40
        return val * 5 > 40 ? 40 : val * 5;
      },
      showEffectOn: "render",
      hoverAnimation: true,
      label: {
        normal: {
          show: true,
          formatter: function (info) {
            return info.name + " (关联" + info.value + "人)";
          },
          // position: ["-60%", "100%"],
          position: "right",
          // align: "center",
          // color: '#031525',
          color: "#d1d1d1",
          fontSize: 12,
          // fontWeight: "bold",
        },
      },
      lineStyle: {
        color: "source",
        curveness: 0.3,
      },
      itemStyle: {
        normal: {
          opacity: 1,
        },
        emphasis: {
          opacity: 1,
        },
      },
      zoom: 0.8,
      zlevel: 99,
    });

    // 鼠标悬浮提示
    me._chartOption["tooltip"] = {
      show: true,
      formatter: function (info) {
        // console.log("tooltip:", info);
        if (info.data._type == "node") {
          return [
            "<div style='width:120px'>人物：" + info.name + "</div>",
            "<div>关联：" + info.value + "人</div>",
          ].join("");
        } else {
          return null;
        }
      },
    };

    // 图表的左边距
    setTimeout(() => {
      let column1El = document.getElementById("column1_list");
      let column1Width = column1El ? column1El.clientWidth : 300;
      me.chartDivLeft = column1Width + 10;
    }, 1000);

    if (isDebug) console.log("me._chartOption:", me._chartOption);
    return;
  };

  /**
   * 【继承组件要重写】
   * echart 有时需要绑定点击事件什么
   * @param {object} e ReactECharts 返回的 echarts 实例
   */
  eChartRefCallback = (e) => {
    // me.chartIns = e.getEchartsInstance();
    let me = this;
    if (!me._chartIns) {
      me._chartIns = e.getEchartsInstance();
      // 绑定点击事件
      me._chartIns.on("click", function (params) {
        console.log(
          "ViewPeopleRelationInsight ReactECharts click params: ",
          params
        );
        if (
          params.hasOwnProperty("data") &&
          params.data.hasOwnProperty("_type") &&
          params.data._type == "node"
        ) {
          let name = params.data.name; //点击的节点的name
          //调用点击事件
          me._column1_onClick(params.dataIndex, name);
        }
      });
    }
  };

  // end of overwrite
  //===============================================================
}

export default connect(
  (state) => ({
    userInfo: state.account.userInfo,
  }),
  (dispatch) => ({})
)(ViewPeopleRelationInsight);
