import React from 'react';
import PropTypes from 'prop-types';
import {message, Pagination, Tooltip} from "antd";
import moment from 'moment';

import {getNodeIconByType, NODE_TYPE_TEXT} from "@/constants/vis.defaultDefine.1";

import Icon from "@/components/common/common.icon";

import style from "@/style/containers/relationView.less";

const monthIdxStr = ['', '上旬', '中旬', '下旬'];
const maxNodesNumber = 300; // 最大节点数据

class RelationPagination extends React.PureComponent {
  state = {
    // 当前展示的原图谱中节点分页数据页码
    currentOriginalViewNodesPage: 0,
    currentSubPageIndex: 0,
  };

  showOriginalViewNodesAtPage = (currentOriginalViewNodesPage, pageSize ,currentSubPageIndex = 0) => {
    let me = this,
      totalPage = me.props.nodesRangeList.length,
      list;

    if (me.props.nodesRangeList.length <= 0) return;
    if (currentOriginalViewNodesPage === undefined) {
      currentOriginalViewNodesPage = totalPage;
      while (currentOriginalViewNodesPage > 1 &&
      me.props.nodesRangeList[currentOriginalViewNodesPage - 1].nodeList.length <= 0) {

        currentOriginalViewNodesPage--;
      }
    }
    list = me.props.nodesRangeList[currentOriginalViewNodesPage - 1];
    if (list.nodeList.length <= 0) {
      message.info('您选择的时段没有任何数据');
    }

    me.setState({
      currentOriginalViewNodesPage,
      currentSubPageIndex: currentSubPageIndex,
    }, () => {
      if (me.props.type === 'timeline') {
        me.props.onPageChange(currentOriginalViewNodesPage, currentSubPageIndex);
      } else {
        me.props.onPageChange(currentOriginalViewNodesPage);
      }

    });
  };

  componentDidMount() {
    let me = this;

    me.showOriginalViewNodesAtPage();
  }

  render() {
    let me = this;

    return (
      <div className={style['pagination-frame']}>
        {me.props.nodesRangeList.length > 0 ? (
          <Pagination
            total={me.props.nodesRangeList.length}
            pageSize={1}
            current={me.state.currentOriginalViewNodesPage}
            onChange={me.showOriginalViewNodesAtPage}
            showTitle={false}
            itemRender={(page, type, element) => {
              switch (type) {
                case "page":
                  return (
                    <Tooltip
                      title={
                        (
                          <div>
                            {
                              me.props.type === 'timeline' && me.props.nodesRangeList[page - 1].hasSubPage.length > 1 ? (
                                <div className={style['pagination-sub']}>{
                                  me.props.nodesRangeList[page - 1].hasSubPage.map((item, index) => (
                                    <div key={index}
                                         className={style['pagination-sub-item']}
                                         style={{
                                           backgroundColor: me.state.currentSubPageIndex === index ? 'hsl(213, 20%, 40%)' : '',
                                         }}
                                         onClick={e => {
                                           e.stopPropagation();
                                           me.showOriginalViewNodesAtPage(page, null, index);
                                         }}
                                    >
                                      {`第${index + 1}页(${moment(me.props.nodesRangeList[page - 1].hasSubPage[index][0][me.props.viewType]).format('MM-DD HH:MM')})/${maxNodesNumber}`}
                                    </div>
                                  ))
                                }</div>
                              ) : null
                            }
                            <div>{me.props.nodesRangeList[page - 1].monthStart.format('YYYY年M月') +
                            monthIdxStr[me.props.nodesRangeList[page - 1].idx] +
                            ` ${me.props.nodesRangeList[page - 1].nodeList.length} 个节点`}</div>
                          </div>
                        )
                      }
                      children={(
                        <a style={{display: 'inline-block', width: '6rem'}}>
                          {
                            <div>
                              {me.props.nodesRangeList[page - 1].monthStart.format('M月') +
                              monthIdxStr[me.props.nodesRangeList[page - 1].idx]}
                            </div>
                          }
                          <div
                            style={{
                              position: 'absolute',
                              top: '-2rem',
                              width: '6rem',
                              textAlign: 'center',
                              fontSize: '0.8rem',
                              opacity: 0.6,
                            }}
                          >
                            <Icon {...getNodeIconByType(NODE_TYPE_TEXT)} style={{marginRight: '0.5em'}}/>
                            {
                              me.props.nodesRangeList[page - 1].nodeList.length > 999 ? '999+' :
                                me.props.nodesRangeList[page - 1].nodeList.length
                            }
                            { me.props.type === 'timeline' && me.props.nodesRangeList[page - 1].hasSubPage.length > 1 ? (
                              <span style={{fontSize: '0.8rem'}}> /{me.props.nodesRangeList[page - 1].hasSubPage.length}页</span>
                            ) : null}
                          </div>
                        </a>
                      )}
                    />
                  );
                case "prev":
                  return (
                    <Tooltip
                      title={
                        `前一个时段`
                      }
                      children={element}
                    />
                  );
                case "next":
                  return (
                    <Tooltip
                      title={
                        `后一个时段`
                      }
                      children={element}
                    />
                  );
                default:
                  return element;
              }
            }}
          />
        ) : null}
      </div>
    );
  }
}

RelationPagination.defaultProps = {};

RelationPagination.propTypes = {
  nodesRangeList: PropTypes.array.isRequired,
  onPageChange: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  viewType: PropTypes.string,
};

export default RelationPagination;