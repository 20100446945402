import {
  VALIDATE_STORE,

  INVALIDATE_VIEW_DATA_CACHE,
  INVALIDATE_ALL_VIEW_DATA_CACHE,

  DO_LOAD_VIEW_INFO,
  LOAD_VIEW_INFO_SUCCESS,
  LOAD_VIEW_INFO_FAILED,

  DO_LOAD_VIEW_STRUCTURE,
  LOAD_VIEW_STRUCTURE_SUCCESS,
  LOAD_VIEW_STRUCTURE_FAILED,

  DO_LOAD_VIEW_NODES,
  LOAD_VIEW_NODES_SUCCESS,
  LOAD_VIEW_NODES_FAILED,

  DO_LOAD_VIEW_RELATION,
  LOAD_VIEW_RELATION_SUCCESS,
  LOAD_VIEW_RELATION_FAILED,
} from './actionTypes';

const CURRENT_VERSION = 'F0425';

const initialStore = {
  viewDataCache: {},
  version: CURRENT_VERSION,
};

export default function reducer(state = initialStore, action) {
  let viewDataCache;
  switch (action.type) {
    case VALIDATE_STORE:
      return state.version === CURRENT_VERSION ? state : {...initialStore};
    case INVALIDATE_VIEW_DATA_CACHE:
      if (state.viewDataCache[action.payload.viewId]) {
        viewDataCache = {...state.viewDataCache};
        delete viewDataCache[action.payload.viewId];
        return {...state, viewDataCache};
      } else {
        return state;
      }
    case INVALIDATE_ALL_VIEW_DATA_CACHE:
      return {...state, viewDataCache: {}};
    case DO_LOAD_VIEW_INFO:
      viewDataCache = {...state.viewDataCache};
      viewDataCache[action.payload.viewId] = {
        ...(viewDataCache[action.payload.viewId] || {}),
        viewInfoStatus: 'loading',
      };
      return {...state, viewDataCache};
    case LOAD_VIEW_INFO_SUCCESS:
      viewDataCache = {...state.viewDataCache};
      viewDataCache[action.payload.viewId] = {
        ...(viewDataCache[action.payload.viewId] || {}),
        viewInfoStatus: 'success',
        viewInfo: action.payload.viewInfo,
      };
      return {...state, viewDataCache};
    case LOAD_VIEW_INFO_FAILED:
      viewDataCache = {...state.viewDataCache};
      viewDataCache[action.payload.viewId] = {
        ...(viewDataCache[action.payload.viewId] || {}),
        viewInfoStatus: 'failed',
      };
      return {...state, viewDataCache};
    case DO_LOAD_VIEW_STRUCTURE:
      viewDataCache = {...state.viewDataCache};
      viewDataCache[action.payload.viewId] = {
        ...(viewDataCache[action.payload.viewId] || {}),
        viewStructureStatus: 'loading',
      };
      return {...state, viewDataCache};
    case LOAD_VIEW_STRUCTURE_SUCCESS:
      viewDataCache = {...state.viewDataCache};
      viewDataCache[action.payload.viewId] = {
        ...(viewDataCache[action.payload.viewId] || {}),
        viewStructureStatus: 'success',
      };
      return {...state, viewDataCache};
    case LOAD_VIEW_STRUCTURE_FAILED:
      viewDataCache = {...state.viewDataCache};
      viewDataCache[action.payload.viewId] = {
        ...(viewDataCache[action.payload.viewId] || {}),
        viewStructureStatus: 'failed',
      };
      return {...state, viewDataCache};
    case DO_LOAD_VIEW_NODES:
      viewDataCache = {...state.viewDataCache};
      viewDataCache[action.payload.viewId] = {
        ...(viewDataCache[action.payload.viewId] || {}),
        viewNodesStatus: 'loading',
      };
      return {...state, viewDataCache};
    case LOAD_VIEW_NODES_SUCCESS:
      viewDataCache = {...state.viewDataCache};
      viewDataCache[action.payload.viewId] = {
        ...(viewDataCache[action.payload.viewId] || {}),
        viewNodesStatus: 'success',
      };
      return {...state, viewDataCache};
    case LOAD_VIEW_NODES_FAILED:
      viewDataCache = {...state.viewDataCache};
      viewDataCache[action.payload.viewId] = {
        ...(viewDataCache[action.payload.viewId] || {}),
        viewNodesStatus: 'failed',
      };
      return {...state, viewDataCache};
    case DO_LOAD_VIEW_RELATION:
      viewDataCache = {...state.viewDataCache};
      viewDataCache[action.payload.viewId] = {
        ...(viewDataCache[action.payload.viewId] || {}),
        viewRelationStatus: 'loading',
      };
      return {...state, viewDataCache};
    case LOAD_VIEW_RELATION_SUCCESS:
      viewDataCache = {...state.viewDataCache};
      viewDataCache[action.payload.viewId] = {
        ...(viewDataCache[action.payload.viewId] || {}),
        viewRelationStatus: 'success',
      };
      return {...state, viewDataCache};
    case LOAD_VIEW_RELATION_FAILED:
      viewDataCache = {...state.viewDataCache};
      viewDataCache[action.payload.viewId] = {
        ...(viewDataCache[action.payload.viewId] || {}),
        viewRelationStatus: 'failed',
      };
      return {...state, viewDataCache};
    default:
      return state;
  }
}