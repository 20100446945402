import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@/components/common/common.icon';

import style from '@/style/common/view/common.view.infoComponent.less';
import {Drawer, Avatar, Input, Row, Col, Statistic, Spin} from 'antd';
import View from '@/components/common/common.viewManager';
import PB, {SimplePB} from '@/libs/simplePB';
import {getToken, REQUEST_BASE} from '@/utils/HttpUtil';
import {AvatarColors} from '@/constants/common';
import UserAvatar from 'react-user-avatar';
import moment from 'moment';
import TimeDisplayField from '@/components/common/common.timeDisplayField';
import {getNodeDisplayTitle, getNodeIcon} from '@/constants/vis.defaultDefine.1';
import axios from "axios";

// 网络请求数据
// 统计接口
const API_BASE = 'https://snap.joinmap.ai'
// const API_BASE = 'http://localhost:5000'

const service = axios.create({
  'timeout': 1500000, // 超时时间
  'headers': {
    'x-requested-with': 'XMLHttpRequest',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Max-Age': 0,
    'Authorization': getToken(),
  },
});
// 当前看板的一些图分析数据
const API_GetViewGraphAnalyze = ({viewId}) => {
  return service({
    method: 'get',
    url: API_BASE + `/stat_api/view/${viewId}/graph_analyze`,
    params: {view_id: viewId},
  })
};

class ViewInfoComponent extends React.Component {
  state = {
    coverData: '', // 图谱封面图
    
    // 图谱结构
    viewStatBase_loading: true,
    viewStatBase: {
      structure_score: 0,
      degree_0: 0,
      degree_1: 0,
      degree_avg: 0,
      degree_max: 0,
      degree_centrality_max: 0,
      density: 0,
    },
    // mock 数据
    viewStatBase_mock: {
      "degree_0": 3,
      "degree_1": 125,
      "degree_avg": 1.9504132231404958,
      "degree_centrality_max": 0.06224066390041494,
      "degree_max": 15.0,
      "density": 0.008093000925894173
    }
  };
  
  componentDidMount() {
    let me = this;
    if (me.props.viewInfo && me.props.viewInfo['hasCover'] === 1) {
      requestAnimationFrame(() => {
        View.loadViewCover(me.props.viewInfo.viewId).then(coverData => {
          if (coverData) {
            this.setState({coverData});
          }
        }).catch(() => {
          // ignore
        });
      });
    }
    
    // 当前图谱的一些图分析数据
    const {viewInfo} = this.props;
    let viewId = viewInfo.viewId;
    API_GetViewGraphAnalyze({viewId}).then(response => {
      let data = response.data.data;
      me.setState({
        viewStatBase_loading: false,
        viewStatBase: data ? data : {
          structure_score: 0,
          degree_0: 0,
          degree_1: 0,
          degree_avg: 0,
          degree_max: 0,
          degree_centrality_max: 0,
          density: 0,
        }
      })
    }).catch(e => {
      me.setState({
        viewStatBase_loading: false,
        viewStatBase: {
          structure_score: 0,
          degree_0: 0,
          degree_1: 0,
          degree_avg: 0,
          degree_max: 0,
          degree_centrality_max: 0,
          density: 0,
        }
      })
    })
  }
  
  componentWillUnmount() {
    this.props.bus.remove(this);
  }
  
  showMemberList = e => {
    e.stopPropagation();
    this.props.bus.emit('teamwork', 'member.list.show_modal');
  };
  
  doFocusNode = node => {
    this.props.bus.emit('relation', 'node.presentation.focus', {node: node});
  };
  
  render() {
    let me = this;
    const {coverData, viewStatBase, viewStatBase_loading} = me.state;
    const {viewInfo, userList, latestNodes, nodeCount} = me.props;
    
    return (
      <div className={`${style['view-intro']} `}>
        <div className={style['view-name']}>
          {
            coverData ? (
              <img className={style['cover-img']}
                   src={coverData} alt={'图谱封面'}/>
            ) : null
          }
          <div style={{paddingBottom: '1rem'}}>{viewInfo.name}</div>
          <div className={style['statistic-item']}>节点数量：{nodeCount||viewInfo.nodeCount}</div>
          <div className={style['statistic-item']}>访问热度：{viewInfo.viewCount}</div>
          <div className={style['statistic-item']}>
            最新更新：
            <TimeDisplayField timestamp={moment(viewInfo.updateTime, 'YYYY-MM-DD HH:mm:ss').valueOf()}/>
          </div>
        </div>
        {/*<div className={style['time-and-statistic']}>
					<div className={style['time']}>
						最新更新：
						<TimeDisplayField timestamp={moment(viewInfo.updateTime, 'YYYY-MM-DD HH:mm:ss').valueOf()} />
					</div>
					<div className={style['statistic']}>
						<div className={style['statistic-item']}>节点数量：{viewInfo.nodeCount}</div>
						<div className={style['statistic-item']}>访问热度：{viewInfo.viewCount}</div>
					</div>
				</div>*/}
        <div className={style['view-desc']}>
          <Input.TextArea
            className={style['view-desc-textarea']}
            readOnly={true}
            autoSize={true}
            defaultValue={viewInfo.desc}
            style={{fontSize: '1rem'}}
          />
        </div>
        <div className={style['members-item']}
             onClick={me.showMemberList}
        >
          <div>成员({Object.keys(userList).length})</div>
          <div className={style['member-list']}>
            {userList.length > 0 && userList.map((member, index) => {
                if (index <= 10) {
                  return (
                    <UserAvatar
                      key={`userAvatar-${index}`}
                      size={index === 0 ? '35' : 'var(--avatar-size)'}
                      name={member.nick}
                      src={member.picId ? `${REQUEST_BASE}/user/user/file/${member.picId}?Authorization=${getToken()}` : undefined}
                      colors={AvatarColors}
                      className={style['avatar-box']}
                    />
                  )
                }
              }
            )}
          </div>
        </div>
        
        <div className={style['latest-node-title']}>图谱结构</div>
        <Spin size="large" spinning={viewStatBase_loading}>
          <table width={'100%'} style={{marginBottom: 10}}>
            <tr>
              <td rowSpan="2" width='33.33333%'>
                <Statistic
                  title={<span style={{color: '#a5a5a5'}}>图结构度</span>}
                  value={viewStatBase['structure_score'] * 100}
                  precision={0}
                  suffix="%"
                  style={{color: '#f8f8f8', textAlign: "center"}}
                  valueStyle={{color: '#b3cdf5', fontSize: '2rem'}}
                />
              </td>
              <td width='33.3333%'>
                <Statistic
                  title={<span style={{color: '#a5a5a5'}}>图密度</span>}
                  value={viewStatBase['density']}
                  precision={4}
                  suffix=""
                  style={{color: '#f8f8f8', textAlign: "center", marginBottom: 15}}
                  valueStyle={{color: '#b3cdf5'}}
                />
              </td>
              <td width='33.3333%'>
                <Statistic
                  title={<span style={{color: '#a5a5a5'}}>孤立点占比</span>}
                  value={viewStatBase['degree_0_percent'] * 100}
                  precision={0}
                  suffix="%"
                  style={{color: '#f8f8f8', textAlign: "center", marginBottom: 15}}
                  valueStyle={{color: '#b3cdf5'}}
                />
              </td>
            </tr>
            <tr>
              <td>
                <Statistic
                  title={<span style={{color: '#a5a5a5'}}>度数平均值</span>}
                  value={viewStatBase['degree_avg']}
                  precision={4}
                  suffix=""
                  style={{color: '#f8f8f8', textAlign: "center"}}
                  valueStyle={{color: '#b3cdf5'}}
                />
              </td>
              <td>
                <Statistic
                  title={<span style={{color: '#a5a5a5'}}>度数最大值</span>}
                  value={viewStatBase['degree_max']}
                  precision={0}
                  suffix=""
                  style={{color: '#f8f8f8', textAlign: "center"}}
                  valueStyle={{color: '#b3cdf5'}}
                />
              </td>
            </tr>
          </table>
          <Row gutter={16} style={{marginTop: 10, marginBottom: 10, color: '#f8f8f8', display: 'none'}}>
            <Col span={8} style={{marginTop: 10}}>
              <Statistic
                title={<span style={{color: '#a5a5a5'}}>图谱中心度</span>}
                value={viewStatBase['degree_centrality_max']}
                precision={4}
                prefix=""
                style={{color: '#f8f8f8', textAlign: "center"}}
                valueStyle={{color: '#b3cdf5'}}
              />
            </Col>
            <Col span={8} style={{marginTop: 10}}>
              <Statistic
                title={<span style={{color: '#a5a5a5'}}>图谱密度</span>}
                value={viewStatBase['density']}
                precision={4}
                suffix=""
                style={{color: '#f8f8f8', textAlign: "center"}}
                valueStyle={{color: '#b3cdf5'}}
              />
            </Col>
            <Col span={8} style={{marginTop: 10}}>
              <Statistic
                title={<span style={{color: '#a5a5a5'}}>叶子节点数</span>}
                value={viewStatBase['degree_1']}
                precision={0}
                suffix=""
                style={{color: '#f8f8f8', textAlign: "center"}}
                valueStyle={{color: '#b3cdf5'}}
              />
            </Col>
            <Col span={8} style={{marginTop: 20}}>
              <Statistic
                title={<span style={{color: '#a5a5a5'}}>孤立节点数</span>}
                value={viewStatBase['degree_0']}
                precision={0}
                suffix=""
                style={{color: '#f8f8f8', textAlign: "center"}}
                valueStyle={{color: '#b3cdf5'}}
              />
            </Col>
            <Col span={8} style={{marginTop: 20}}>
              <Statistic
                title={<span style={{color: '#a5a5a5'}}>图度数平均值</span>}
                value={viewStatBase['degree_avg']}
                precision={4}
                suffix=""
                style={{color: '#f8f8f8', textAlign: "center"}}
                valueStyle={{color: '#b3cdf5'}}
              />
            </Col>
            <Col span={8} style={{marginTop: 20}}>
              <Statistic
                title={<span style={{color: '#a5a5a5'}}>图度数最大值</span>}
                value={viewStatBase['degree_max']}
                precision={0}
                suffix=""
                style={{color: '#f8f8f8', textAlign: "center"}}
                valueStyle={{color: '#b3cdf5'}}
              />
            </Col>
          </Row>
        </Spin>
        
        <div className={style['latest-node-title']}>最新节点</div>
        <table className={style['latest-nodes-list']}>
          <tbody>
          {
            me.props.latestNodes && me.props.latestNodes.map(node => (
              <tr key={`node-${node.id}`} onClick={() => me.doFocusNode(node)}>
                <td>
                  <Icon name={getNodeIcon(node).name} type={getNodeIcon(node).type} color={'#a5a5a5'}
                        style={{marginRight: '0.5em'}}/>
                  {getNodeDisplayTitle(node)}
                </td>
                <td>
                  {node.updateTime ? (
                    <TimeDisplayField timestamp={moment(node.updateTime, 'YYYY-MM-DD HH:mm:ss').valueOf()}/>
                  ) : (node.linkTime ? (
                    <TimeDisplayField timestamp={moment(node.linkTime, 'YYYY-MM-DD HH:mm:ss').valueOf()}/>
                  ) : '-')}
                </td>
              </tr>
            ))
          }
          </tbody>
        </table>
      </div>
    )
  }
}

ViewInfoComponent.defaultProps = {
  bus: PB,
};

ViewInfoComponent.propTypes = {
  bus: PropTypes.instanceOf(SimplePB),
  viewInfo: PropTypes.object.isRequired,
  userList: PropTypes.array.isRequired,
  latestNodes: PropTypes.array,
  nodeCount: PropTypes.number
};

export default ViewInfoComponent;
