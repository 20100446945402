/*
 * @Author: Carr.s
 * @Date: 2022-04-08 11:28:40
 * @LastEditTime: 2022-05-23 10:07:14
 * @LastEditors: Carr.s
 * @Description: 洞察 合作模式分析
 * @Copyright by joinmap
 */
import React from "react";
import ViewInsightChartTpl from "@/components/insightView/template/insight.chart.tpl";
import { connect } from "react-redux";
// import styles from "@/style/components/insightView/insight.less";

class ViewCooperationModeInsight extends ViewInsightChartTpl {
  //===============================================================
  // begin of overwrite
  // 使用默认的方式就不用重写

  // ------------------------------------------------------------
  // 全局配置
  // ------------------------------------------------------------
  // 显示底部按钮
  showToolbar = false;
  // 洞察名称
  viewerTitle = "合作模式";

  // ------------------------------------------------------------
  // 层级分析对象的配置
  // ------------------------------------------------------------
  // 第一层 level1 tag
  // column1_DataField = "data"; // 取值字段
  // column1_TotalField = "dataTotal"; // 计算总数(与返回数量不一定一致)字段
  column1_Title = "合作模式"; // 显示名称
  column1_Unit = "种"; // 显示的文字单位

  // 第二层 item
  column2_DataField = "news"; // 取值字段
  column2_TotalField = "newsTotal"; // 计算总数(与返回数量不一定一致)字段
  column2_Title = "事件"; // 显示名称
  column2_Unit = "个"; // 显示的文字单位
  column2_ValueRender = (level2_data, idx) => {
    // 右侧数值渲染，不输出则空白
    // 必须加上 className={styles["line-value"]}
  };

  // ------------------------------------------------------------
  // 接口的配置
  // ------------------------------------------------------------
  // 接口 url path
  api_path = "/wth_server_api/cooperation_mode_by_view_id";

  // 返回数据默认值
  defaultLimit = 150;

  /**
   * 【继承组件要重写】
   * api 请求完成后，setState结束后的 callback
   */
  apiLoadedCallback = () => {
    // message.info("数据加载成功");
  };

  // ------------------------------------------------------------
  // 图表的配置
  // ------------------------------------------------------------
  // 图表显示库
  chartType = "highcharts";
  chartStyle = {
    top: 0,
    bottom: 0,
  };

  chartCompomentStyle = {
    width: "100%",
    height: "100%",
  };

  /**
   * 生成公用的、默认的 option
   * @returns
   */
  initChartOption = () => {
    let me = this;
    return {
      colors: [
        "#058DC7",
        "#50B432",
        "#ED561B",
        "#DDDF00",
        "#24CBE5",
        "#64E572",
        "#FF9655",
        "#FFF263",
        "#6AF9C4",
      ],
      credits: {
        enabled: false, // 禁用版权信息
      },
      chart: {
        backgroundColor: null,
        type: "packedbubble",
        // 指定内边距，下面的四个配置可以用 spacing: [10, 10, 15, 10] 来代替
        spacingBottom: 0,
        spacingTop: 0,
        spacingLeft: 0,
        spacingRight: 0,
      },
      title: {
        text: "",
      },
      tooltip: {
        useHTML: true,
        // pointFormat: '<b>{point.name}:</b> {point.y}m CO<sub>2</sub>',
        pointFormat:
          '<div style="text-align: center;width: 100%;"><b>{point.name}</b></div><div style="text-align: center;width: 100%;">{point.y}篇</div>',
        headerFormat: "",
      },
      legend: {
        enabled: false,
        itemStyle: {
          color: "#f8f8f8",
        },
      },
      plotOptions: {
        packedbubble: {
          useSimulation: true,
          minSize: "30%",
          maxSize: "120%",
          zMin: 0,
          zMax: 80,
          dataLabels: {
            enabled: true,
            align: "center",
            useHTML: true,
            format:
              '<div style="text-align: center;width: 120px;"><b>{point.name}</b></div><div style="text-align: center;width: 120px;">{point.y}篇</div>',
            filter: {
              property: "y",
              operator: ">",
              value: 0, // 250
            },
            style: {
              color: "#f9f9f9",
              textOutline: "none",
              fontWeight: "normal",
              fontSize: "1rem",
            },
            overflow: "crop",
            allowOverlap: true,
          },
          // 设置气泡的间隔、填充、速度等
          layoutAlgorithm: {
            // 气泡的间隔
            // bubblePadding: 80,
            // 是否一组数据放在一堆
            splitSeries: false,
            // 用于算法重心力的万有引力常量
            gravitationalConstant: 0.04,
            // 允许仿真
            // enableSimulation: false,
            // 摩擦系数
            // friction: -0.1,
            // 最大运动数量
            // maxIterations: 1500
          },
          animation: false,
          animationLimit: 200,
          // 允许选中点
          allowPointSelect: true,
          point: {
            events: {
              click: (e) => {
                console.log("point event e:", e);
                me._column1_onClick(e.point.x, e.point.name, false);
              },
            },
          },
        },
      },
      series: [
        {
          name: "合作模式",
          data: [],
        },
      ],
    };
  };

  /**
   * 【继承组件要重写】
   * 生成 chart 的 options
   * @param {int} idx 选中的数据下标
   */
  refreshChartOptions = (idx) => {
    const {
      query: { isDebug },
    } = this.props;
    let me = this;
    this._chartOption = this.initChartOption();
    this._chartOption.series[0].data = this._chartData;

    // ---------------------------------------------
    // 图表大小的处理
    let chartMaxWidth = 1500;
    let chartMaxHeight = 1500;
    // 计算图表的宽度
    let chartWidth = document.getElementById("chart_div").clientWidth;
    // chartWidth = chartWidth > chartMaxWidth ? chartMaxWidth : chartWidth;
    me._chartOption.chart.width = chartWidth;
    // 计算图表的高度
    let chartHeight = document.getElementById("chart_div").clientHeight;
    // chartHeight = chartHeight > chartMaxHeight ? chartMaxHeight : chartHeight;
    me._chartOption.chart.height = chartHeight;

    if (isDebug) console.log("me._chartOption:", me._chartOption);
    return;
  };

  /**
   * 【继承组件要重写】
   * echart 有时需要绑定点击事件什么
   * @param {object} e ReactECharts 返回的 echarts 实例
   */
  eChartRefCallback = (e) => {
    // me._chartIns = e.getEchartsInstance();
    let me = this;
  };

  // end of overwrite
  //===============================================================
}

export default connect(
  (state) => ({
    userInfo: state.account.userInfo,
  }),
  (dispatch) => ({})
)(ViewCooperationModeInsight);
