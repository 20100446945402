import React from 'react';
import PropTypes from 'prop-types';
import {Badge, Menu} from 'antd';

import PB, {SimplePB} from '@/libs/simplePB';

import {IconTypes} from "@/constants/common";

import Icon from "@/components/common/common.icon";

import style from '@/style/common/view/common.view.explore.less';
import ViewDataProvider from '@/components/common/dataProvider/common.dataProvider.view';

class ExploreMenuCompany extends React.PureComponent {
  state = {
    overallStatus: 'idle',
    overallModalVisible: false,
    overallHasNewResult: 0,
    
    recommendDetailStatus: 'idle',
    recommendDetailModalVisible: false,
    recommendDetailHasNewResult: 0,
  };
  
  onExploreOverall = () => {
    let me = this;
    
    me.props.bus.emit('view', 'explore.explore_company.overall.show_modal',
      {viewId: me.props.viewDataProvider.viewId});
    if (me.state.overallHasNewResult > 0) {
      me.setState({overallHasNewResult: 0});
    }
  };
  
  // 跳转图谱内企业节点的特征标签气泡图
  onCompanyTagsBubble = () => {
    window.open(`/viewer/${this.props.viewDataProvider.viewId}/company_tags`, '_blank');
  }
  
  onExploreRecommendDetail = () => {
    let me = this;
    
    me.props.bus.emit('view', 'explore.explore_company.recommend_detail.show_modal',
      {viewId: me.props.viewDataProvider.viewId});
    if (me.state.recommendDetailHasNewResult > 0) {
      me.setState({recommendDetailHasNewResult: 0});
    }
  };
  
  componentDidMount() {
    let me = this;
    
    me.props.bus.with(me).subscribe('view', 'explore.explore_company.overall.do_load_more', ({viewId}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId) {
        me.setState({overallStatus: 'loading'});
      }
    }).subscribe('view', 'explore.explore_company.overall.data_loaded', ({nodes, start, limit, total, viewId}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId) {
        me.setState({
          overallStatus: (start + limit < total) ? 'stopped' : 'finished',
          overallHasNewResult: me.state.overallModalVisible ? 0 : (me.state.overallHasNewResult + nodes.length),
        });
      }
    }).subscribe('view', 'explore.explore_company.overall.failed_to_load', ({viewId}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId) {
        me.setState({
          overallStatus: 'failed',
        });
      }
    }).subscribe('view', 'explore.explore_company.overall.modal_visible_changed', ({viewId, visible}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId) {
        me.setState({
          overallModalVisible: visible,
        });
      }
    }).subscribe('view', 'explore.explore_company.recommend_detail.do_load_more', ({viewId}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId) {
        me.setState({recommendDetailStatus: 'loading'});
      }
    }).subscribe('view', 'explore.explore_company.recommend_detail.data_loaded', ({
                                                                                    resultData,
                                                                                    hasMoreResult,
                                                                                    viewId
                                                                                  }) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId) {
        me.setState({
          recommendDetailStatus: hasMoreResult ? 'stopped' : 'finished',
          recommendDetailHasNewResult: me.state.recommendDetailModalVisible ? 0 : (me.state.recommendDetailHasNewResult + resultData.length),
        });
      }
    }).subscribe('view', 'explore.explore_company.recommend_detail.failed_to_load', ({viewId}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId) {
        me.setState({
          recommendDetailStatus: 'failed',
        });
      }
    }).subscribe('view', 'explore.explore_company.recommend_detail.modal_visible_changed', ({viewId, visible}) => {
      if (me.props.viewDataProvider && me.props.viewDataProvider.viewId === viewId) {
        me.setState({
          recommendDetailModalVisible: visible,
        });
      }
    });
  }
  
  componentWillUnmount() {
    this.props.bus.remove(this);
  }
  
  render() {
    let me = this;
    
    return (
      <div className={'dark-theme'}>
        <Menu selectable={false}>
          <Menu.Item key={'m.company.overall'} onClick={me.onExploreOverall}>
            <div className={style['explore-menu-item']}>
              <Badge dot={me.state.overallHasNewResult > 0}>
                <div className={style['explore-menu-item-icon']}>
                  <Icon name={'icon-company-find'} type={IconTypes.ICON_FONT}/>
                </div>
              </Badge>
              <div className={style['explore-menu-item-meta']}>
                <div className={style['explore-menu-item-title']}>全局发现</div>
                <div className={style['explore-menu-item-description']}>根据本图谱内容发现相关企业</div>
                <div className={style['explore-menu-item-notice']}>
                  {(() => {
                    switch (me.state.overallStatus) {
                      case 'idle':
                        return '';
                      case 'loading':
                        if (me.state.overallHasNewResult > 0) {
                          return '有新结果';
                        } else {
                          return '计算中';
                        }
                      case 'stopped':
                        return '已停止';
                      case 'finished':
                        return '已完成';
                      case 'failed':
                        return '出错了';
                      default:
                        return '';
                    }
                  })()}
                </div>
              </div>
            </div>
          </Menu.Item>
          <Menu.Item key={'m.company.tagsBubble'} onClick={me.onCompanyTagsBubble}>
            <div className={style['explore-menu-item']}>
              <Badge dot={false}>
                <div className={style['explore-menu-item-icon']}>
                  <Icon name={'icon-company-size'} type={IconTypes.ICON_FONT}/>
                </div>
              </Badge>
              <div className={style['explore-menu-item-meta']}>
                <div className={style['explore-menu-item-title']}>企业特征</div>
                <div className={style['explore-menu-item-description']}>分析本图谱内企业特征</div>
                <div className={style['explore-menu-item-notice']}></div>
              </div>
            </div>
          </Menu.Item>
          {/*
          <Menu.Item
            key={'m.company.region'}
            onClick={() => message.info('功能开发中，敬请期待')}
            disabled={true}
          >
            <div className={style['explore-menu-item']}>
              <div className={style['explore-menu-item-icon']}>
                <Icon name={'icon-company-region'} type={IconTypes.ICON_FONT}/>
              </div>
              <div className={style['explore-menu-item-meta']}>
                <div className={style['explore-menu-item-title']}>地区标记</div>
                <div className={style['explore-menu-item-description']}>标记本图谱中特定地区的企业</div>
              </div>
            </div>
          </Menu.Item>
          <Menu.Item
            key={'m.company.special'}
            onClick={() => message.info('功能开发中，敬请期待')}
            disabled={true}
          >
            <div className={style['explore-menu-item']}>
              <div className={style['explore-menu-item-icon']}>
                <Icon name={'icon-company-good'} type={IconTypes.ICON_FONT}/>
              </div>
              <div className={style['explore-menu-item-meta']}>
                <div className={style['explore-menu-item-title']}>专优特新</div>
                <div className={style['explore-menu-item-description']}>标记本图谱中的专优特新企业</div>
              </div>
            </div>
          </Menu.Item>
          <Menu.Item
            key={'m.company.size'}
            onClick={() => message.info('功能开发中，敬请期待')}
            disabled={true}
          >
            <div className={style['explore-menu-item']}>
              <div className={style['explore-menu-item-icon']}>
                <Icon name={'icon-company-size'} type={IconTypes.ICON_FONT}/>
              </div>
              <div className={style['explore-menu-item-meta']}>
                <div className={style['explore-menu-item-title']}>大中小微</div>
                <div className={style['explore-menu-item-description']}>标记本图谱中的大中小微企业</div>
              </div>
            </div>
          </Menu.Item>
          */}
          <Menu.Item key={'m.company.recommend-detail'} onClick={me.onExploreRecommendDetail}>
            <div className={style['explore-menu-item']}>
              <Badge dot={me.state.recommendDetailHasNewResult > 0}>
                <div className={style['explore-menu-item-icon']}>
                  <Icon name={'icon-company-override'} type={IconTypes.ICON_FONT}/>
                </div>
              </Badge>
              <div className={style['explore-menu-item-meta']}>
                <div className={style['explore-menu-item-title']}>信息填充</div>
                <div className={style['explore-menu-item-description']}>遍历图谱中的企业节点并推荐填充信息</div>
                <div className={style['explore-menu-item-notice']}>
                  {(() => {
                    switch (me.state.recommendDetailStatus) {
                      case 'idle':
                        return '';
                      case 'loading':
                        if (me.state.recommendDetailHasNewResult > 0) {
                          return '有新结果';
                        } else {
                          return '计算中';
                        }
                      case 'stopped':
                        return '已停止';
                      case 'finished':
                        return '已完成';
                      case 'failed':
                        return '出错了';
                      default:
                        return '';
                    }
                  })()}
                </div>
              </div>
            </div>
          </Menu.Item>
        </Menu>
      </div>
    );
  }
}

ExploreMenuCompany.defaultProps = {
  bus: PB,
};

ExploreMenuCompany.propTypes = {
  bus: PropTypes.instanceOf(SimplePB),
  viewDataProvider: PropTypes.instanceOf(ViewDataProvider).isRequired,
};

export default ExploreMenuCompany;
