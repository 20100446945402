import React from "react";
import PropTypes from 'prop-types';
// import moment from "moment";
import {Empty, message, Modal, Drawer, Row, Col, Card, Statistic} from 'antd';
import ReactECharts from 'echarts-for-react';
import eCharts from 'echarts/lib/echarts';


import 'echarts/lib/chart/scatter';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/legend';
import axios from 'axios'
import {getToken, REQUEST_BASE} from '@/utils/HttpUtil'
import {AvatarColors} from '@/constants/common';


import PB, {SimplePB} from "@/libs/simplePB";
import UserAvatar from 'react-user-avatar';

// import {getNodeDisplayTitle, getNodeIcon} from "@/constants/vis.defaultDefine.1";

// import Icon from "@/components/common/common.icon";
// import {IconTypes} from "@/constants/common";

import styles from '@/style/common/view/common.view.teamwork.memberStatPanel.less';
import ReactWordCloud from "react-wordcloud";


function formatDate(date) {
    if (!date) {
        return false;
    }
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
}

/**
 * 大数字转换，将大额数字转换为万、千万、亿等
 * @param value 数字值
 */
const bigNumberTransform = num => {
    num = Number(num);
    if (num == 0) {
        return num + '';
    } else if (num > 1 && num < 10000) {
        return num + '';
    } else {
        return (num / 10000).toFixed(0) + '万'; // 去整
    }
};
// api 请求
const service = axios.create({
    'timeout': 1500000, // 超时时间
    'headers': {
        'x-requested-with': 'XMLHttpRequest',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Max-Age': 0,
        'Authorization': getToken(),
    },
});
// 统计接口
const API_BASE = 'https://snap.joinmap.ai'
// const API_BASE = 'http://localhost:5000'
// 获取个人用户的统计信息
// 用户基本统计数据
const API_GetUserCountBase = ({user_id, fields}) => {
    return service({
        method: 'get',
        url: API_BASE + '/stat_api/user/stat',
        params: {user_id, fields},
    })
};
// 用户节点词汇量排名
const API_GetUserHotWords = ({user_id, limit}) => {
    return service({
        method: 'get',
        url: 'https://snap.joinmap.ai/wth_server_api/wd_freq_by_user_id',
        params: {user_id, limit},
    })
};
// 用户打开看板活跃度
const API_GetUserCountOpenByDay = ({user_id, limit, year, month}) => {
    return service({
        method: 'get',
        url: API_BASE + '/stat_api/user/count_open_times/by_day',
        params: {user_id, limit, year, month},
    })
};

// echarts
const colorSet = (idx) => {
    // 渐变色 设置
    var color0 = new eCharts.graphic.LinearGradient(0, 0, 1, 0, [{
        offset: 0,
        // color: "#7ba2c9" // 0% 处的颜色
        color: "#DAE3F3" // 0% 处的颜色
    }, {
        offset: 1,
        // color: "#3D6082" // 100% 处的颜色
        color: "#8FAADC" // 100% 处的颜色
    }], false)
    var color1 = new eCharts.graphic.LinearGradient(0, 0, 1, 0, [{
        offset: 0,
        color: "#e30430" // 0% 处的颜色
    }, {
        offset: 1,
        color: "#f6c6a2" // 100% 处的颜色
    }], false)
    var color2 = new eCharts.graphic.LinearGradient(0, 0, 1, 0, [{
        offset: 0,
        color: "#d16922" // 0% 处的颜色
    }, {
        offset: 1,
        color: "#fcc09c" // 100% 处的颜色
    }], false)
    var color3 = new eCharts.graphic.LinearGradient(0, 0, 1, 0, [{
        offset: 0,
        color: "#77bc00" // 0% 处的颜色
    }, {
        offset: 1,
        color: "#dbc782" // 100% 处的颜色
    }], false)
    var color4 = new eCharts.graphic.LinearGradient(0, 0, 1, 0, [{
        offset: 0,
        color: "#1dc4bc" // 0% 处的颜色
    }, {
        offset: 1,
        color: "#f5f7f2" // 100% 处的颜色
    }], false)
    var color5 = new eCharts.graphic.LinearGradient(0, 0, 1, 0, [{
        offset: 0,
        color: "#1210cd" // 0% 处的颜色
    }, {
        offset: 1,
        color: "#e1ebf5" // 100% 处的颜色
    }], false)
    var color6 = new eCharts.graphic.LinearGradient(0, 0, 1, 0, [{
        offset: 0,
        color: "#ffd835" // 0% 处的颜色
    }, {
        offset: 1,
        color: "#fcf6da" // 100% 处的颜色
    }], false)
    var color7 = new eCharts.graphic.LinearGradient(0, 0, 1, 0, [{
        offset: 0,
        color: "#772ace" // 0% 处的颜色
    }, {
        offset: 1,
        color: "#dec7fc" // 100% 处的颜色
    }], false)

    var colorList = [
        color0, color1, color2, color3, color4, color5, color6, color7
    ];
    return colorList[idx]
}
// 统一竖轴的配置
const default_Axis = (fixedName) => {
    return {
        type: 'category',
        boundaryGap: true,
        splitLine: {
            show: false,
            lineStyle: {
                color: '#092b5d',
            },
        },
        axisLine: {
            //坐标轴轴线相关设置。数学上的x轴
            show: true,
            lineStyle: {
                color: '#092b5d',
            },
        },
        axisLabel: {
            //坐标轴刻度标签的相关设置
            textStyle: {
                // color: '#24c4ff',
                margin: 15,
            },
            formatter: function (data) {
                return data + fixedName;
            },
        },
        axisTick: {
            show: false,
        },
        data: [],
    }
}
//7个echarts图表配置
const chartOption_1_1 = () => {
    return {
        title: {
            text: '节点数量统计'
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            },
            formatter: function (data) {
                // console.log('echarts chartOption_1_3 tooltip formatter data=', data);
                var d = data[0];
                return '统计日期: ' + d.name + '<br/>' + d.seriesName + ': ' + d.data + ' 个';
            },
        },
        // legend: {},
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        xAxis: default_Axis(''),
        yAxis: {
            type: 'value',
            // boundaryGap: [0, 5]
            // max: 50,
            min: 0,
            splitNumber: 5,
        },
        series: [
            {
                name: '节点数量',
                type: 'line',
                data: [],
                itemStyle: {
                    normal: {
                        show: true,
                        //颜色设置
                        color: '#47693B',
                        opacity: 1
                    }
                },
                areaStyle: {
                    //区域填充样式
                    normal: {
                        //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                        color: eCharts
                            ? new eCharts.graphic.LinearGradient(0, 0, 0, 1, [
                                    {
                                        offset: 0,
                                        color: 'rgba(71,105,59,1)',
                                    },
                                    {
                                        offset: 1,
                                        color: 'rgba(71,105,59,0.34)',
                                    },
                                ],
                                false,
                            )
                            : 'rgba(255,255,255,0.3)',
                        shadowColor: 'rgba(53,142,215, 0.9)', //阴影颜色
                        shadowBlur: 20, //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
                    },
                },
            }
        ]
    }
}
// 热门图谱配置
const chartOption_2_1 = () => {
    return {
        title: {
            text: '用户的热门图谱',
            // textAlign: 'center'
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            },
            formatter: function (data) {
                var d = data[0];
                if (d.data > 0) {
                    return '图谱名称: ' + d.name + '<br/>' + d.seriesName + ': ' + d.data + ' 次';
                } else {
                    return '';
                }
            },

        },
        // legend: {},
        grid: {
            top: '10%',
            left: '2%',
            right: '4%',
            bottom: '8%',
            containLabel: false
        },
        yAxis: {
            show: false,
        },
        xAxis: {},
        series: [
            {
                name: '浏览总数',
                type: 'bar',
                data: [],
                itemStyle: {
                    normal: {
                        show: true,
                        // 圆角
                        barBorderRadius: [0, 5, 5, 0],
                        label: {
                            show: true,
                            formatter: function (data) {
                                if (data.data > 0) {
                                    return data.name + ' 浏览：' + data.data + '次';
                                } else {
                                    return '';
                                }
                            },
                            // position: ['1%', '50%'],
                            // position: 'insideStart',
                            position: 'insideLeft',
                            textStyle: {
                                fontWeight: "bolder",
                                fontSize: "12",
                                color: "#202020"
                            },
                            // verticalAlign: 'middle'
                        },
                        //颜色设置
                        color: colorSet(0),
                        opacity: 1
                    }
                }
            }
        ]
    }
}
// 打开看板次数 options
const chartOption_3_1 = () => {
    return {
        title: {
            text: '打开图谱次数',
            // textAlign: 'center'
        },
        tooltip: {
            position: 'top',
            formatter: function (data) {
                // console.log('echarts chartOption_3_2 tooltip formatter data=', data);
                return '活跃日期: ' + data.data[0] + '<br/>' + data.seriesName + ': ' + data.data[1] + ' 次';
            },
        },
        visualMap: {
            show: false,
            min: 0,
            max: 50,
            calculable: true,
            seriesIndex: [0],
            orient: 'horizontal',
            left: 'center',
            bottom: 20,
            inRange: {
                color: ['#F8CBAD', '#865130'],
                opacity: 0.3
            },
            controller: {
                inRange: {
                    opacity: 0.5
                }
            }
        },
        calendar: [
            {
                left: '2%',
                right: '2%',
                orient: 'vertical',
                yearLabel: {
                    show: false,
                    margin: 40
                },
                monthLabel: {
                    show: false,
                    nameMap: 'cn',
                    margin: 5,
                    // width: 0
                },
                dayLabel: {
                    show: true,
                    firstDay: 1,
                    nameMap: 'cn'
                },
                // height: 280,
                cellSize: 30,
                range: '2022-02'
            }
        ],
        series: [
            {
                name: '浏览总数',
                type: 'heatmap',
                coordinateSystem: 'calendar',
                calendarIndex: 0,
                data: [],
                itemStyle: {
                    normal: {
                        show: true,
                        //颜色设置
                        color: '#865130',
                        opacity: 1,
                        label: {
                            show: true,
                            formatter: function (data) {
                                // console.log('itemStyle label formatter o_3_2 data:', data)
                                if (data.data[1] > 100) {
                                    return '99+ 次';
                                }
                                return data.data[1] + '次';
                                let v = data.data[0].split('-');
                                v = v[v.length - 1];
                                v = Number(v);
                                return v + '日';
                            },
                            // position: ['1%', '50%'],
                            // position: 'insideStart',
                            position: 'inside',
                            textStyle: {
                                fontWeight: "bolder",
                                fontSize: "12",
                                color: "#f5f5f5"
                            },
                            // verticalAlign: 'middle'
                        }
                    }
                }
            }
        ]
    };
}


class TeamworkMemberStatPanel extends React.Component {
    state = {
        userStatBase: {
            views_total: 0,
            nodes_total: 0,
            node_tasks_send_total: 0,
            node_tasks_get_total: 0,
            node_tasks_get_done_total: 0,
            badges_get_total: 0,
            badges_send_total: 0,
            nodes_total_by_day_of_this_month: [],
            views_total_by_day_of_this_month: []
        },
        userStatBase_loading: false,
        userStatModalVisible: false,
        option_1_1: {},
        option_1_1_loading: true,
        option_1_2: {},
        option_1_2_loading: true,

        option_2_1: {},
        option_2_1_loading: true,
        option_2_2: {},
        option_2_2_loading: true,
        option_2_3: {},
        option_2_3_loading: true,

        option_3_1: {},
        option_3_1_loading: true,
        option_3_2: {},
        option_3_2_loading: true
    }

    componentDidMount() {
        console.log('TeamworkMemberStatPanel this.props:', this.props)
        this.loading_data(this.props.userId);
    }

    loading_data = (userId) => {
        let me = this;
        let nowDate = new Date();
        let month_ = nowDate.getMonth() + 1;
        let month = '' + month_;
        let year = nowDate.getFullYear();
        let month_previous = '' + (month_ - 1);
        let year_previous = year;
        if (month_ === 1) {
            month_previous = 12;
            year_previous = nowDate.getFullYear() - 1;
        }
        // 用户基本统计数据
        API_GetUserCountBase({user_id: userId, fields: '*'}).then(response => {
            // ---------------------------------------------------------------------------------------------
            let xAxisData = [];
            let seriesData = [];
            let seriesData_minTotal = 0;
            let seriesData_maxTotal = 0;

            // ---------------------------------------------------------------------------------------------
            // 第一行 每日节点数
            // 1-1 上月节点数统计
            let o_1_1 = chartOption_1_1();
            o_1_1.title.text = month_previous + '月' + o_1_1.title.text;
            response.data.data['nodes_total_by_day_of_previous_month'].forEach(i => {
                // console.log('TeamworkMemberStatPanel 1_1 i:', i)
                let month_day = i['count_date'].split('-');
                month_day = month_day[1] + '-' + month_day[2];
                xAxisData.push(month_day)
                seriesData.push(i.total)
                if (i.total > seriesData_maxTotal) {
                    seriesData_maxTotal = i.total
                } else if (i.total < seriesData_minTotal) {
                    seriesData_minTotal = i.total
                }
            })
            o_1_1.xAxis.data = [].concat(xAxisData)
            o_1_1.series[0].data = [].concat(seriesData)
            // console.log('TeamworkMemberStatPanel o_1_1:', o_1_1)

            // 1-2 本月节点数统计
            xAxisData = []
            seriesData = []
            // seriesData_minTotal = 0
            // seriesData_maxTotal = 0
            let o_1_2 = chartOption_1_1();
            o_1_2.title.text = month + '月' + o_1_2.title.text;
            response.data.data['nodes_total_by_day_of_this_month'].forEach(i => {
                // console.log('TeamworkMemberStatPanel 1_1 i:', i)
                let month_day = i['count_date'].split('-');
                month_day = month_day[1] + '-' + month_day[2];
                xAxisData.push(month_day)
                seriesData.push(i.total)
                if (i.total > seriesData_maxTotal) {
                    seriesData_maxTotal = i.total
                } else if (i.total < seriesData_minTotal) {
                    seriesData_minTotal = i.total
                }
            })
            o_1_2.xAxis.data = [].concat(xAxisData)
            o_1_2.series[0].data = [].concat(seriesData)
            // console.log('TeamworkMemberStatPanel o_1_2:', o_1_2)

            // 合并x轴最大值
            o_1_1.yAxis.max = seriesData_maxTotal;
            o_1_2.yAxis.max = seriesData_maxTotal;

            // ---------------------------------------------------------------------------------------------
            // 第二行 热门图谱
            // 1、上月
            let yAxisData_2 = [];
            let seriesData_2 = [];
            let maxTotal_2 = 0;
            let o_2_1 = chartOption_2_1();
            o_2_1.title.text = month_previous + '月' + o_2_1.title.text;
            response.data.data['hot_views_of_previous_month'].forEach(i => {
                // console.log('TeamworkMemberStatPanel i:', i);
                yAxisData_2.push(i.name)
                seriesData_2.push(i['view_total'])
                if (i['view_total'] > maxTotal_2) {
                    maxTotal_2 = i['view_total'];
                }
            });
            seriesData_2.reverse()
            yAxisData_2.reverse()
            // yAxisData_2 = yAxisData_2.slice(0, 8);
            // seriesData_2 = seriesData_2.slice(0, 8);
            o_2_1.yAxis.data = [].concat(yAxisData_2);
            o_2_1.series[0].data = [].concat(seriesData_2);
            // console.log('TeamworkMemberStatPanel o_2_1:', o_2_1);

            // 2、本月
            yAxisData_2 = [];
            seriesData_2 = [];
            let o_2_2 = chartOption_2_1();
            if (response.data.data['hot_views_of_this_month'].length === 0) {
                o_2_2 = {};
            } else {
                o_2_2.title.text = month + '月' + o_2_2.title.text;
                response.data.data['hot_views_of_this_month'].forEach(i => {
                    // console.log('TeamworkMemberStatPanel i:', i);
                    yAxisData_2.push(i.name)
                    seriesData_2.push(i['view_total'])
                    if (i['view_total'] > maxTotal_2) {
                        maxTotal_2 = i['view_total'];
                    }
                });
                seriesData_2.reverse()
                yAxisData_2.reverse()
                // yAxisData_2 = yAxisData_2.slice(0, 8);
                // seriesData_2 = seriesData_2.slice(0, 8);
                o_2_2.yAxis.data = [].concat(yAxisData_2);
                o_2_2.series[0].data = [].concat(seriesData_2);
                // console.log('TeamworkMemberStatPanel o_2_2:', o_2_2);
                // 3、合并最大值
                o_2_2.xAxis.max = maxTotal_2;
            }

            // 3、合并最大值
            o_2_1.xAxis.max = maxTotal_2

            // 更新数据
            me.setState({
                userStatBase: response.data.data,
                option_1_1: o_1_1,
                option_1_2: o_1_2,
                option_2_1: o_2_1,
                option_2_2: o_2_2,
            }, () => {
                me.setState({
                    userStatBase_loading: false,
                    option_1_1_loading: false,
                    option_1_2_loading: false,
                    option_2_1_loading: false,
                    option_2_2_loading: false,
                })
            })
        }).catch(error => {
            console.log('TeamworkMemberStatPanel error:', error)
            me.setState({
                userStatBase: {
                    views_total: 0,
                    nodes_total: 0,
                    node_tasks_send_total: 0,
                    node_tasks_get_total: 0,
                    node_tasks_get_done_total: 0,
                    badges_get_total: 0,
                    badges_send_total: 0,
                    nodes_total_by_day_of_this_month: [],
                    views_total_by_day_of_this_month: []
                },
                userStatBase_loading: false,
                option_1_1: {},
                option_1_1_loading: false,
                option_1_2: {},
                option_1_2_loading: false,
                option_2_1: {},
                option_2_1_loading: false,
                option_2_2: {},
                option_2_2_loading: false,
            })
        });


        // -----------------------------------------------------------------------------
        // 上月打开看板的次数统计
        API_GetUserCountOpenByDay({user_id: userId, year: year_previous, month: month_previous}).then(response => {
            let yAxisData = [];
            let seriesData = [];
            let minTotal = 0;
            let maxTotal = 0;
            response.data.data.forEach(i => {
                // console.log('TeamworkMemberStatPanel API_GetUserCountOpenByDay i:', i)
                yAxisData.push(i.name)
                seriesData.push([
                    i['count_date'],
                    i['total']
                ]);

                if (i.total > maxTotal) {
                    maxTotal = i.total
                } else if (i.total < minTotal) {
                    minTotal = i.total
                }
            })
            yAxisData.reverse();
            let o_3_1 = chartOption_3_1();
            o_3_1.calendar[0].range = year_previous + '-' + month_previous;
            o_3_1.title.text = month_previous + '月' + o_3_1.title.text;
            o_3_1.series[0].data = seriesData;
            // console.log('TeamworkMemberStatPanel o_3_1:', o_3_1);
            me.setState({
                option_3_1: o_3_1
            }, () => {
                me.setState({
                    option_3_1_loading: false
                })
            })
        }).catch(error => {
            console.log('TeamworkMemberStatPanel error:', error)
            me.setState({
                option_3_1: {},
                option_3_1_loading: false
            })
        });

        // 本月打开看板的次数统计
        API_GetUserCountOpenByDay({user_id: userId, year: year, month: month}).then(response => {
            let yAxisData = [];
            let seriesData = [];
            let minTotal = 0;
            let maxTotal = 0;
            response.data.data.forEach(i => {
                yAxisData.push(i.name)
                seriesData.push([
                    i['count_date'],
                    i['total']
                ]);

                if (i.total > maxTotal) {
                    maxTotal = i.total
                } else if (i.total < minTotal) {
                    minTotal = i.total
                }
            })
            yAxisData.reverse();
            // console.log('TeamworkMemberStatPanel o_3_2 seriesData:', seriesData);
            let o_3_2 = chartOption_3_1();
            o_3_2.calendar[0].range = year + '-' + month;
            o_3_2.title.text = month + '月' + o_3_2.title.text;
            o_3_2.series[0].data = seriesData;
            // console.log('TeamworkMemberStatPanel o_3_2:', o_3_2);
            me.setState({
                option_3_2: o_3_2
            }, () => {
                me.setState({
                    option_3_2_loading: false
                })
            })
        }).catch(error => {
            console.log('TeamworkMemberStatPanel error:', error)
            me.setState({
                option_3_2: {},
                option_3_2_loading: false
            })
        });


        // 统计 热词
        // 最慢 会卡 falsk 最后加载
        setTimeout(() => {
            API_GetUserHotWords({user_id: userId}).then(response => {
                // 2-3
                let data_2_3 = [];
                response.data.data.kvs.map(i => {
                    //词云
                    data_2_3.push({
                        text: i.k,
                        value: i.v
                    })
                })
                me.setState({
                    option_2_3: data_2_3
                }, () => {
                    me.setState({
                        option_2_3_loading: false
                    })
                })
            }).catch(error => {
                console.log('TeamworkMemberStatPanel error:', error)
                me.setState({
                    option_2_3: {},
                    option_2_3_loading: false
                })
            });
        }, 800);

    };// end of loading


    render() {
        const {
            userStatBase,
            option_1_1,
            option_1_1_loading,
            option_1_2,
            option_1_2_loading,
            option_2_1,
            option_2_1_loading,
            option_2_2,
            option_2_2_loading,
            option_2_3,
            option_2_3_loading,
            option_3_1,
            option_3_1_loading,
            option_3_2,
            option_3_2_loading
        } = this.state;
        let me = this;
        return (
            <div style={{width: 'auto'}}>

                <div className={styles.charts_frame}>
                    <div className={`${styles.chart_title} ${styles.line_1}`}>
                        数 <br/> 量
                    </div>
                    <div className={styles.charts}>
                        <div className={styles.chart}>
                            {
                                Object.keys(option_1_1).length === 0 ?
                                    <Empty style={{margin: '23%'}} description={option_1_1_loading ? '正在加载' : '暂无数据'}
                                           image={Empty.PRESENTED_IMAGE_SIMPLE}/> :
                                    <ReactECharts
                                        echarts={eCharts}
                                        option={option_1_1}
                                        style={{height: '100%'}}
                                        ref={e => {
                                        }}
                                    />
                            }

                        </div>
                        <div className={styles.chart}>
                            {
                                Object.keys(option_1_2).length === 0 ?
                                    <Empty style={{margin: '23%'}} description={option_1_2_loading ? '正在加载' : '暂无数据'}
                                           image={Empty.PRESENTED_IMAGE_SIMPLE}/> :
                                    <ReactECharts
                                        echarts={eCharts}
                                        option={option_1_2}
                                        style={{height: '100%'}}
                                        ref={e => {
                                        }}
                                    />
                            }

                        </div>
                        <div className={styles.statistics}>
                            <div className={styles.statistic_line_1}>
                                {/*图谱总数*/}
                                <Card bordered={false} className={styles.statistic}>
                                    <Statistic
                                        title="图谱总数"
                                        value={userStatBase['views_total']}
                                        precision={0}
                                        valueStyle={{color: '#3f8600'}}
                                        prefix={''}
                                        suffix="个"
                                    />
                                </Card>
                                {/*节点总数*/}
                                <Card bordered={false} className={styles.statistic}>
                                    <Statistic
                                        title="节点总数"
                                        value={bigNumberTransform(userStatBase['nodes_total'])}
                                        precision={0}
                                        valueStyle={{color: '#3f8600'}}
                                        prefix={''}
                                        suffix="个"
                                    />
                                </Card>

                            </div>
                            <div className={styles.statistic_line_2}>

                                {/*待办总数*/}
                                <Card bordered={false} className={styles.statistic}>
                                    <Statistic
                                        title="待办总数"
                                        value={userStatBase['node_tasks_get_total']}
                                        precision={0}
                                        valueStyle={{color: '#3f8600'}}
                                        prefix={''}
                                        suffix="个"
                                    />
                                </Card>
                                {/*点赞总数*/}
                                <Card bordered={false} className={styles.statistic}>
                                    <Statistic
                                        title="点赞总数"
                                        value={userStatBase['badges_get_total']}
                                        precision={0}
                                        valueStyle={{color: '#3f8600'}}
                                        prefix={''}
                                        suffix="个"
                                    />
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.charts_frame}>
                    <div className={styles.chart_title + ' ' + styles.line_2}>
                        内 <br/> 容
                    </div>
                    <div className={styles.charts}>
                        <div className={styles.chart}>
                            {
                                Object.keys(option_2_1).length === 0 ?
                                    <Empty style={{margin: '23%'}} description={option_2_1_loading ? '正在加载' : '暂无数据'}
                                           image={Empty.PRESENTED_IMAGE_SIMPLE}/> :
                                    <ReactECharts
                                        echarts={eCharts}
                                        option={option_2_1}
                                        style={{height: '100%'}}
                                        ref={e => {
                                        }}
                                    />
                            }
                        </div>
                        <div className={styles.chart}>
                            {
                                Object.keys(option_2_2).length === 0 ?
                                    <Empty style={{margin: '23%'}} description={option_2_2_loading ? '正在加载' : '暂无数据'}
                                           image={Empty.PRESENTED_IMAGE_SIMPLE}/> :
                                    <ReactECharts
                                        echarts={eCharts}
                                        option={option_2_2}
                                        style={{height: '100%'}}
                                        ref={e => {
                                        }}
                                    />
                            }
                        </div>
                        <div className={styles.chart} style={{textAlign: 'left', padding: 0}}>
                            <div className={styles.item_title} style={{textAlign: 'center'}}>节点词云</div>
                            {
                                option_2_3.length === 0 || Object.keys(option_2_3).length === 0 ?
                                    <Empty style={{margin: '23%'}} description={option_2_3_loading ? '正在加载' : '暂无数据'}
                                           image={Empty.PRESENTED_IMAGE_SIMPLE}/> :
                                    <ReactWordCloud
                                        style={{width: 'calc(100% - 5px)'}}
                                        words={option_2_3}
                                        maxWords={30}
                                        options={{
                                            enableTooltip: false,
                                            enableOptimizations: option_2_3.length > 80,
                                            fontSizes: [25, 80],
                                            padding: 0,
                                            rotations: 1,
                                            rotationAngles: [0, 0],
                                            scale: "sqrt",
                                        }}
                                    />
                            }
                        </div>
                    </div>
                </div>
                <div className={styles.charts_frame}>
                    <div className={styles.chart_title + ' ' + styles.line_3}>
                        活 <br/> 跃
                    </div>
                    <div className={styles.charts}>

                        <div className={styles.chart}>
                            {
                                option_3_1.length === 0 ?
                                    <Empty style={{margin: '23%'}} description={option_3_1_loading ? '正在加载' : '暂无数据'}
                                           image={Empty.PRESENTED_IMAGE_SIMPLE}/> :
                                    <ReactECharts
                                        echarts={eCharts}
                                        option={option_3_1}
                                        style={{height: '100%'}}
                                    />
                            }
                        </div>
                        <div className={styles.chart}>
                            {
                                option_3_2.length === 0 ?
                                    <Empty style={{margin: '23%'}} description={option_3_2_loading ? '正在加载' : '暂无数据'}
                                           image={Empty.PRESENTED_IMAGE_SIMPLE}/> :
                                    <ReactECharts
                                        echarts={eCharts}
                                        option={option_3_2}
                                        style={{height: '100%'}}
                                    />
                            }
                        </div>
                        <div className={styles.statistics}>
                            <div className={styles.statistic_line_1}>

                                {/*协作用户数*/}
                                <Card bordered={false} className={styles.statistic}>
                                    <Statistic
                                        title="协作用户数"
                                        value={userStatBase['linked_users_total']}
                                        precision={0}
                                        valueStyle={{color: '#3f8600'}}
                                        prefix={''}
                                        suffix="人"
                                    />
                                </Card>
                                {/*发出点赞*/}
                                <Card bordered={false} className={styles.statistic}>
                                    <Statistic
                                        title="发出点赞"
                                        value={userStatBase['badges_send_total']}
                                        precision={0}
                                        valueStyle={{color: '#3f8600'}}
                                        prefix={''}
                                        suffix="个"
                                    />
                                </Card>
                            </div>
                            <div className={styles.statistic_line_2}>


                                {/*发出待办*/}
                                <Card bordered={false} className={styles.statistic}>
                                    <Statistic
                                        title="发出待办"
                                        value={userStatBase['node_tasks_send_total']}
                                        precision={0}
                                        valueStyle={{color: '#3f8600'}}
                                        prefix={''}
                                        suffix="个"
                                    />
                                </Card>
                                {/*待办完成率*/}
                                <Card bordered={false} className={styles.statistic}>
                                    <Statistic
                                        title="待办完成率"
                                        value={userStatBase['node_tasks_get_total'] > 0 ? Math.round(userStatBase['node_tasks_get_done_total'] * 100 / userStatBase['node_tasks_get_total']) : 0}
                                        precision={0}
                                        valueStyle={{color: '#3f8600'}}
                                        prefix={''}
                                        suffix="%"
                                    />
                                </Card>


                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

TeamworkMemberStatPanel.defaultProps = {
    bus: PB,
};

TeamworkMemberStatPanel.propTypes = {
    bus: PropTypes.instanceOf(SimplePB),
    userId: PropTypes.number,
    memberInfo: PropTypes.object,
};

export default TeamworkMemberStatPanel;
