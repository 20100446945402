import React from 'react';
import PropTypes from 'prop-types';
import {Button, Form} from 'antd';

import AuthForm from '@/components/common/common.authForm';

import style from '@/style/components/common.authForm.less';

class ResetPwdConfirmPanel extends React.Component {
  onSendEmail = (e) => {
    e && e.preventDefault();
    this.props.onSendResetPwdEmail();
  };

  render() {
    // noinspection RequiredAttributes
    return (
      <AuthForm>
        <Form onSubmit={this.onSendEmail}>
          <Form.Item className={style['form-item']}>
            <Button shape="circle" size="small" icon="arrow-left" className={style['form-button']}
                    onClick={this.props.backToCheckPassword} style={{marginRight: '1em'}}/>
            <span>返回</span>
          </Form.Item>
          <h1 className={style['form-title']}>重置密码</h1>
          <div className={style['form-item']} style={{marginBottom: '3em'}}>
            我们将发送一封邮件至您的邮箱：
            <em style={
              {
                display: 'block',
                width: '100%',
                textAlign: 'center',
                lineHeight: '3.8rem',
                fontSize: '1.8rem',
              }
            }>{this.props.email}</em>
            请确认邮箱，并在收到邮件后重新设置密码。
          </div>
          <Form.Item className={style['form-item']}>
            <Button type="primary"
                    htmlType="submit"
                    loading={this.props.sendingEmail}
                    className={style['form-button']}>
              立即发送
            </Button>
          </Form.Item>
        </Form>
      </AuthForm>
    );
  }
}

ResetPwdConfirmPanel.defaultProps = {
  email: '',
  sendingEmail: false,
  backToCheckPassword: () => {console.log('gotoCheckPassword property is not set.')},
  onSendResetPwdEmail: () => {console.log('onSendResetPwdEmail property is not set.')},
};

ResetPwdConfirmPanel.propTypes = {
  email: PropTypes.string,
  sendingEmail: PropTypes.bool,
  backToCheckPassword: PropTypes.func,
  onSendResetPwdEmail: PropTypes.func,
};

export default ResetPwdConfirmPanel;
