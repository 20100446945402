export const NodeEvents = Object.freeze({
  ADDED: 'node.add.done', // 节点被添加到视图后触发事件
  UPDATED: 'node.update.done', // 节点信息更新后触发事件
  REMOVED: 'node.remove.done', // 节点被删除后触发事件
  CONNECTED_NODES_UPDATED: 'node.updated.connected_nodes', // 相关连接节点信息发生变化后触发事件
  // 加载节点详细信息相关事件
  LOADING_DETAIL_INFO: 'node.detail_info.loading', // 加载中
  DETAIL_INFO_LOADED: 'node.detail_info.loaded', // 加载成功
  LOAD_DETAIL_INFO_FAILED: 'node.detail_info.load_failed', // 加载失败
  // 节点扩展相关事件
  EXPANDING: 'node.expanding.waiting', // 请求已发送，等待响应
  EXPANDING_SUCCESS: 'node.expanding.success', // 扩展操作成功
  EXPANDING_FAILED: 'node.expanding.failed', // 扩展操作失败
  // 节点联想相关事件
  GROWING: 'node.growing.waiting', // 请求已发送，等待响应
  GROWING_SUCCESS: 'node.growing.success', // 联想操作成功
  GROWING_FAILED: 'node.growing.failed', // 联想操作失败
  // 节点搜索相关事件
  EXACT_MATCHING: 'node.exact_matching.waiting', // 请求已发送，等待响应
  EXACT_MATCHING_SUCCESS: 'node.exact_matching.success', // 搜索操作成功
  EXACT_MATCHING_FAILED: 'node.exact_matching.failed', // 搜索操作失败
  // 新增节点相关事件
  ADDING: 'node.add.waiting', // 请求已发送，等待响应
  ADD_FAILED: 'node.add.failed', // 添加节点失败
  // 更新节点相关事件
  UPDATING: 'node.update.waiting', // 请求已发送，等待响应
  UPDATE_FAILED: 'node.update.failed', // 更新操作失败
  // 删除节点相关事件
  REMOVING: 'node.remove.waiting', // 请求已发送，等待响应
  REMOVE_FAILED: 'node.remove.failed', // 删除节点失败
  // 替换
  ID_REPLACED: 'node.id_replaced', // 替换完成
  // 添加附件相关事件
  PROPERTY_UPLOADING: 'node.property.upload.waiting', // 上传中
  ATTACHMENT_UPLOADED: 'node.property.upload.done', // 上传成功
  PROPERTY_UPLOAD_FAILED: 'node.property.upload.failed', // 上传失败
  // 删除附件相关事件
  PROPERTY_REMOVING: 'node.property.remove.waiting', // 删除中
  PROPERTY_REMOVED: 'node.property.remove.done', // 删除成功
  PROPERTY_REMOVE_FAILED: 'node.property.remove.failed', // 删除失败
  // 修改附件信息相关事件
  PROPERTY_UPDATING: 'node.property.update.waiting', // 修改中
  PROPERTY_UPDATED: 'node.property.update.done', // 修改成功
  PROPERTY_UPDATE_FAILED: 'node.property.update.failed', // 修改失败
  // 节点探索相关事件
  EXPLORING: 'node.exploring.waiting', // 请求已发送，等待响应
  EXPLORING_SUCCESS: 'node.exploring.success', // 搜索操作成功
  EXPLORING_FAILED: 'node.exploring.failed', // 搜索操作失败
  // 节点超级点赞
  BADGE_SETTING: 'node.badge.setting', // 对节点进行超级点赞
  BADGE_SET_SUCCESS: 'node.badge.set.success', // 对节点进行超级点赞完成
  BADGE_SET_FAILED: 'node.badge.set.failed', // 对节点进行超级点赞完成
});

export const EdgeEvents = Object.freeze({
  ADDED: 'edge.add.done', // 边被添加后触发事件
  UPDATED: 'edge.update.done', // 边更新后触发事件
  REMOVED: 'edge.remove.done', // 边被删除后触发事件
  SAVING_RELATED_CLUE_RELATION: 'edge.saving_expanded_relation', // 保存扩展关联关系
  RELATED_CLUE_RELATION_SAVED: 'edge.expanded_relation_saved', // 扩展关系已保留
  SAVE_RELATED_CLUE_RELATION_FAILED: 'edge.save_expanded_relation_failed', // 保存扩展关联关系失败
  REMOVING_RELATED_CLUE_RELATION: 'edge.removing_expanded_relation', // 取消扩展关联关系
  RELATED_CLUE_RELATION_REMOVED: 'edge.expanded_relation_removed', // 扩展关系已取消
  REMOVE_RELATED_CLUE_RELATION_FAILED: 'edge.remove_expanded_relation_failed', // 取消扩展关联关系失败
  SAVING_RELATED_RESOURCE_RELATION: 'edge.saving_related_resource_relation', // 保存联想关联关系
  RELATED_RESOURCE_RELATION_SAVED: 'edge.related_resource_relation_saved', // 联想关系已保留
  SAVE_RELATED_RESOURCE_RELATION_FAILED: 'edge.save_related_resource_relation_failed', // 保存联想关联关系失败
  REMOVING_RELATED_RESOURCE_RELATION: 'edge.removing_related_resource_relation', // 取消联想关联关系
  RELATED_RESOURCE_RELATION_REMOVED: 'edge.related_resource_relation_removed', // 联想关系已取消
  REMOVE_RELATED_RESOURCE_RELATION_FAILED: 'edge.remove_related_resource_relation_failed', // 取消联想关联关系失败
  // 新增关联关系相关事件
  ADDING: 'edge.add.waiting', // 请求已发送，等待响应
  ADD_FAILED: 'edge.add.failed', // 添加关联关系失败
  // 更新关联关系相关事件
  UPDATING: 'edge.update.waiting', // 请求已发送，等待响应
  UPDATE_FAILED: 'edge.update.failed', // 更新操作失败
  // 删除关联关系相关事件
  REMOVING: 'edge.remove.waiting', // 请求已发送，等待响应
  REMOVE_FAILED: 'edge.remove.failed', // 删除关联关系失败
});

export const NetworkEvents = Object.freeze({
  RELATION_CHANGED: 'network.relation_changed', // 关系图结构发生变化后触发，如节点及边的增删
  VISIBLE_RELATION_CHANGED: 'network.visible_relation_changed', // 可见关系图结构发生变化后触发，如节点及边的增删、隐藏及显示等
  // 图谱数据加载相关事件
  LOADING_DATA: 'network.loading.waiting', // 请求已发送，等待响应
  LOADING_STRUCTURE_SUCCESS: 'network.loading_structure.success', // 结构数据加载成功
  LOADING_DATA_SUCCESS: 'network.loading.success', // 数据加载成功
  LOADING_DATA_FAILED: 'network.loading.failed', // 数据加载失败
  // 图谱数据重新加载相关事件
  RELOADING_DATA: 'network.reloading.waiting', // 请求已发送，等待响应
  RELOADING_DATA_SUCCESS: 'network.reloading.success', // 数据重新加载成功
  RELOADING_DATA_FAILED: 'network.reloading.failed', // 数据重新加载失败
  // 子图数据加载相关事件
  LOADING_SUB_VIEW: 'network.loading_sub_view.waiting', // 请求已发送，等待响应
  LOADING_SUB_VIEW_SUCCESS: 'network.loading_sub_view.success', // 数据加载成功
  LOADING_SUB_VIEW_FAILED: 'network.loading_sub_view.failed', // 数据加载失败
  // 子图搜索相关事件
  SUB_VIEW_MATCHING: 'network.sub_view_matching.waiting', // 请求已发送，等待响应
  SUB_VIEW_MATCHING_SUCCESS: 'network.sub_view_matching.success', // 搜索操作成功
  SUB_VIEW_MATCHING_FAILED: 'network.sub_view_matching.failed', // 搜索操作失败
  // 数据集数据加载相关事件
  LOADING_DATA_SET: 'network.loading_data_set.waiting', // 请求已发送，等待响应
  LOADING_DATA_SET_SUCCESS: 'network.loading_data_set.success', // 数据加载成功
  LOADING_DATA_SET_FAILED: 'network.loading_data_set.failed', // 数据加载失败
  // 文件数据加载相关事件
  LOADING_FILE_LIST: 'network.loading_file_list.waiting', // 请求已发送，等待响应
  LOADING_FILE_LIST_SUCCESS: 'network.loading_file_list.success', // 数据加载成功
  LOADING_FILE_LIST_FAILED: 'network.loading_file_list.failed', // 数据加载失败
  // 子图探索相关事件
  SUB_VIEW_EXPLORING: 'network.sub_view_exploring.waiting', // 请求已发送，等待响应
  SUB_VIEW_EXPLORING_SUCCESS: 'network.sub_view_exploring.success', // 搜索操作成功
  SUB_VIEW_EXPLORING_FAILED: 'network.sub_view_exploring.failed', // 搜索操作失败
  // 添加附件相关事件
  FILE_UPLOADING: 'network.file.upload.waiting', // 上传中
  FILE_UPLOADED: 'network.file.upload.done', // 上传成功
  FILE_UPLOAD_FAILED: 'network.file.upload.failed', // 上传失败
  // 修改附件信息相关事件
  FILE_UPDATING: 'network.file.update.waiting', // 修改中
  FILE_UPDATED: 'network.file.update.done', // 修改成功
  FILE_UPDATE_FAILED: 'network.file.update.failed', // 修改失败
  // 删除附件相关事件
  FILE_REMOVING: 'network.file.remove.waiting', // 删除中
  FILE_REMOVED: 'network.file.remove.done', // 删除成功
  FILE_REMOVE_FAILED: 'network.file.remove.failed', // 删除失败
});

export const ADD_TO_GRAPH = 0x1;
export const ADD_TO_VIEW = 0x2;

export const REMOVE_FROM_GRAPH = 0x1;
export const REMOVE_FROM_VIEW = 0x2;