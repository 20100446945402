/*
 * @Author: Carr.s
 * @Date: 2022-06-02 10:34:02
 * @LastEditTime: 2022-06-06 17:34:08
 * @LastEditors: Carr.s
 * @Description: 经侦线索洞察
 * @Copyright by joinmap
 */
import React from "react";
import ViewInsightChartTpl from "@/components/insightView/template/insight.chart.tpl";
import styles from "@/style/components/insightView/insight.less";
import { notification } from "antd";
import echarts from "echarts/lib/echarts";

// https://snap.joinmap.ai/wth_server_api/ecomic_fan_zui_by_view_id?view_id=df95529d-ae1b-4d41-8f8a-ee9fb2323027

class ViewEcoInvestigationInsight extends ViewInsightChartTpl {
  // 全局配置
  nodeTitleInTip = "事件";
  viewerTitle = "经侦线索";
  // column1
  column1_showType = "tree"; // 第一列的展现形式，list=显示列，tree=显示树
  column1_treeFields = []; // 用树状目录显示数据时各层级的 children 字段名数据
  column1_showTreeLevelNumb = 1; // 用树状目录显示层数,1=二层，2=三层，3=四层
  column1_Title = "线索项"; // 显示名称
  column1_Unit = "条"; // 显示的文字单位
  column1_subTitle = () => {
    // 列表顶部副标题文字
    const { _column1Len, _column1Total } = this.state;
    return (
      <>
        已计算出{_column1Len} {this.column1_Unit}
      </>
    );
  };
  column1_ValueRender = (column1_data, idx, nodePath = undefined) => {
    // 右侧数值渲染，不输出则空白
    // 必须加上 className={styles["line-value"]}
    return "";
    let value = column1_data.hasOwnProperty("children")
      ? column1_data.children.length
      : "";
    let unit = this.column2_Unit;
    return (
      <div className={styles["line-value"]}>
        {value} {unit}
      </div>
    );
  };
  column1_HoverTitle = () => {
    return "";
  };

  // column2
  column2_Title = "语料"; // 显示名称
  column2_showType = "list"; // 第二列的展现形式，list=显示列，tree=显示树, none=不显示
  column2_DataField = "children"; // 取值字段
  column2_TotalField = "childrenTotal"; // 计算总数(与返回数量不一定一致)字段
  column2_ValueRender = (column1_data, idx, nodePath = undefined) => {
    return "";
  };

  // api
  api_path = "/wth_server_api/ecomic_fan_zui_by_view_id";
  defaultLimit = 60;

  // chart
  chartDivLeft = "auto";
  chartStyle = {
    top: 0,
    bottom: 0,
    overFlow: "hidden",
  };

  chartCompomentStyle = {
    width: "100%",
    height: "100%",
  };

  chartType = "echarts";

  initChartOption = () => {
    const {
      query: { isDebug },
    } = this.props;
    // let me = this;
    let option = {
      colors: this._chartColors,
      title: {
        show: false,
      },
      tooltip: {
        formatter: function (info) {
          // if (isDebug)
          //   console.log("initChartOption tooltip formatter info:", info);
          return [
            `<div class="tooltip-title">${echarts.format.encodeHTML(
              info.data.name
            )}</div>`,
            info.data.value + info.data.valueUnit + "<br>",
          ].join("");
        },
      },
      grid: {},

      series: [
        {
          type: "sunburst",
          radius: ["15%", "80%"],
          sort: undefined,
          nodeClick: false,
          data: [],
          label: {
            formatter: function (node) {
              const level = (node.data._path + "").split("-").length;
              // if (isDebug)
              //   console.log(
              //     "initChartOption tooltip formatter node:",
              //     level,
              //     node
              //   );
              if (level == 3) {
                return node.data.name.length > 20
                  ? node.data.name.slice(0, 20) + "..."
                  : node.data.name;
              } else {
                return node.data.name.length > 5
                  ? node.data.name.slice(0, 5) + "..."
                  : node.data.name;
              }
            },
          },
          itemStyle: {
            borderWidth: 0,
          },
        },
      ],
    };
    return option;
  };

  refreshChartOptions = (nodePath = null) => {
    const {
      query: { isDebug },
    } = this.props;
    if (isDebug) console.log("refreshChartOptions 0 nodePath:", nodePath);
    let me = this;
    // let seriesData = [].concat(this._chartData);
    // this._chartData.forEach((el, idx) => {
    //   el._name = el.name;
    //   el.name = el.name > 10 ? el.name.slice(0, 10) + "..." : el.name;
    //   seriesData.push(el);
    // });
    let pathArr = [];
    if (nodePath) {
      pathArr = (nodePath + "").split("-");
    }

    // 深度循环
    let seriesData = this._setDataDeepValue(
      [].concat(this._chartData),
      undefined,
      (node, nodePath1, parent) => {
        // if (isDebug) console.log("refreshChartOptions 1 node:", node);
        let newNode = { ...{}, ...node };
        const pathArr1 = (nodePath1 + "").split("-");
        // 序号
        let nodeIdx = 0;
        if (pathArr1.length > 0) {
          nodeIdx = pathArr1[pathArr1.length - 1];
        }
        if (nodeIdx > 3) {
          // return false;
        }
        // 层数 断言
        const level = pathArr1.length;
        // const levelNo = (nodePath1 + "").split("-")[level-1];
        if (nodePath === undefined || nodePath === null || nodePath === false) {
          // 没有选中的扇区
          newNode["itemStyle"] = {
            color: me.getColorOfLevel(level, 3),
          };
          newNode["label"] = {
            color: "rgba(255,255,255,1)",
          };
        } else if (
          pathArr1.slice(0, pathArr.length).join("-") == pathArr.join("-")
        ) {
          // 命中选中的扇区
          newNode["itemStyle"] = {
            color: me.getColorOfLevel(level, 3),
          };
          newNode["label"] = {
            color: "rgba(255,255,255,1)",
          };
        } else {
          // 非选中的 灰色
          newNode["itemStyle"] = {
            color: "#22313d",
            borderWidth: 0,
          };
          newNode["label"] = {
            color: "rgba(255,255,255,0.3)",
          };
        }
        if (level == 3) {
          newNode.itemStyle.color = "rgba(255,255,255,0)";
          // newNode.itemStyle.opacity = 0;
          newNode.label.align = "left";
          if (parent.hasOwnProperty("value")) {
            newNode.value = parent.value * 0.3;
          }
        } else {
          // 加border
          newNode.itemStyle.borderWidth = 1;
        }

        // 按 value 值计算成三档

        return newNode;
      },
      this.column1_treeFields,
      (node, nodePath, childField, parent) => {
        let nodePathArr = []; // 正在渲染 node 的路径
        if (nodePath === undefined || nodePath === null || nodePath === false) {
          nodePathArr = [];
        } else {
          nodePathArr = (nodePath + "").split("-");
        }
        let nodeLevel = nodePathArr.length; // 正在渲染 node 的层数

        // if (isDebug) console.log("seriesData nodePath 1 =", nodePath);
        // if (isDebug) console.log("seriesData nodeLevel 1 =", nodeLevel);
        // if (nodeLevel > 2) {
        //   if (isDebug) console.log("seriesData nodePath 2 =", nodePath);
        //   if (isDebug) console.log("seriesData nodeLevel 2 =", nodeLevel);

        //   let childField = "children"; // 当前层级的子元素字段名
        //   if (nodeLevel < treeFieldArr.length) {
        //     childField = treeFieldArr[nodeLevel];
        //   }
        //   if (
        //     node.hasOwnProperty(childField) &&
        //     Array.isArray(node[childField])
        //   ) {
        //     node[childField] = node[childField].slice(0, 1);
        //   }
        // }
        if (nodeLevel >= 2) {
          if (
            node.hasOwnProperty(childField) &&
            Array.isArray(node[childField])
          ) {
            node[childField] = node[childField].slice(0, 3);
          }
        }
        return node;
      }
    );

    if (isDebug) console.log("this._chartData =", this._chartData);
    this._chartOption = this.initChartOption();
    // this._chartOption.series[0].data = this._chartData;
    if (isDebug) console.log("seriesData =", seriesData);
    this._chartOption.series[0].data = seriesData;
    if (isDebug)
      console.log("refreshChartOptions _chartOption:", this._chartOption);
  };

  /**
   * 【继承组件要重写】
   * echart 有时需要绑定点击事件什么
   * @param {object} e ReactECharts 返回的 echarts 实例
   */
  eChartRefCallback = (e) => {
    let me = this;
    // me.chartIns = e.getEchartsInstance();
    if (!me._chartIns) {
      me._chartIns = e.getEchartsInstance();
      // 绑定点击事件
      me._chartIns.on("click", function (params) {
        console.log(
          "ViewEcoInvestigationInsight ReactECharts click params: ",
          params
        );
        if (
          params.hasOwnProperty("data") &&
          params.data.hasOwnProperty("_path")
        ) {
          let name = params.data.name; //点击的节点的name
          //调用点击事件
          me._column1_onClick(params.data._path, name, false);
        } else {
          me._column1_onClick(null, null, true);
        }
      });
    }
  };

  // end of overwrite
  //===============================================================

  getColorOfLevel = (level, maxLevel = 1000000) => {
    if (level > 0 && level < this._chartColors.length) {
      if (level > maxLevel) {
        return "#dddddd";
      }
      return this._chartColors[level];
    } else {
      return "#dddddd";
    }
  };
}
export default ViewEcoInvestigationInsight;
