import { combineReducers } from 'redux'
import account from './account/reducer';
import system from './system/reducer';
import view from './view/reducer';

export default combineReducers({
	account,
	system,
	view,
});
