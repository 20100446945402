import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist-indexeddb-storage';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';

import reducer from '@/redux-saga/reducers';
import sagas from '@/redux-saga/sagas';

const persistedReducer = persistReducer({
    key: 'root',
    storage: storage('joinmapDB'),
    whitelist: ['account', 'view'],
}, reducer);

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

export const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(...middlewares)))
sagaMiddleware.run(sagas);

export const persistor = persistStore(store);