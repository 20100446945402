import React from 'react';
import PropTypes from 'prop-types';

import PB, {SimplePB} from '@/libs/simplePB';
import copy from 'copy-to-clipboard';
import ViewDataProvider from '@/components/common/dataProvider/common.dataProvider.view';

// import { NODE_TYPE_TEXT } from '@/constants/nodeConfig';
import { getNodeDisplayTitle, getNodeIcon } from '@/constants/vis.defaultDefine.1';
import Icon from '@/components/common/common.icon';
import { message, Tooltip } from 'antd';
import style from '@/style/common/node/common.node.explore.overrideAndRelatedNode.less';
import { IconTypes } from '@/constants/common';
import UserAvatar from 'react-user-avatar';

class ExploreOverridePanel extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      originNodeInfo: props.nodeInfo,
      overrideNodes: props.overrideList, // 推荐填充可选择的节点列表
      overrideStatus: props.nodeInfo && props.overrideList ? 'success' : 'idle',
    }
  }

  pageSize = 5;
  placeholderArr = ['--', '--', '--', '--', '--'];

  fieldPaths = [
    'lev',
    'description',
    'meta.description',
    'url',
    'meta.url',
    'userPreferredType',
    'meta.userPreferredType',
    'aiPreferredType',
    'tag',
    'tags',
    'meta.iconData',
    'meta.scale',
    'meta.iconMeta.rectWidth',
    'meta.iconMeta.rectHeight',
    'meta.iconMeta.shape',
  ];

  onCopyNodeInfo = (override) => {
    let textList = [];
    // 节点名称
    if (override.fname) {
      textList.push(`标题：${override.fname}`)
    }
    // 节点描述
    if (override.description || (override.meta && override.meta.description)) {
      textList.push(`描述：${override.description || override.meta.description}`);
    }
    // 节点链接
    if (override.url || (override.meta && override.meta.url)) {
      textList.push(`链接：${override.url || override.meta.url}`);
    }
    let result = copy(textList.join("\r\n"), {
      message: '请按下 #{key} 复制节点标题。',
    });

    if (result) message.success('推荐的候选信息已经复制到剪贴板。');
  };

  /*
  * 处理冗余数据
  * 去重
  * */
  handleRedundancyNodes = (nodes) => {
    let me = this, result = [], sameIndex = new Set();

    // 记录重复元素的索引index
    for(let i = 0; i < nodes.length; i++) {
      for(let j = i + 1; j < nodes.length; j++) {
        let flag = false;
        me.fieldPaths.forEach(path => {
          if (_.get(nodes[i], path) !== _.get(nodes[j], path)) {
            flag = true;
          }
        });
        if (!flag) {
          sameIndex.add(j);
        }
      }
    }

    // 保留不重复元素
    nodes.forEach((node, index) => {
      if (!sameIndex.has(index)) {
        result.push(node);
      }
    });
    return result;
  };

  renderNodeItem = (node, index) => {
    let me = this;

    const {name, type, color} = getNodeIcon(node);
    return (
      <div
        key={`${type}-item-${index}`}
        className={`${style['extra-properties-item']} ${style['override-node']} `}
        onClick={e => {
          e.stopPropagation();
          me.onCopyNodeInfo(node);
        }}
      >
        <Tooltip
          title={
            <div
              className={style['extra-properties-tooltip']}
              onClick={e => {
                e.stopPropagation();
                me.onCopyNodeInfo(node);
              }}
            >
              <div className={`${style['text-title']} ${node.meta && node.meta.iconData ? style['user-avatar'] : ''}`}>
                <span className={style['text-icon']}>{
                  node.meta && node.meta.iconData ? (
                    <UserAvatar
                      name={node.fname}
                      size={40}
                      src={node.meta.iconData}
                      style={{margin: '0 .5em .3rem 0'}}
                    />
                  ) : (
                    type && name ? (
                      <Icon name={name} type={type} color={color} style={{marginRight: '0.5em'}}/>
                    ) : (
                      <Icon name={'icon-yuandianda'} type={IconTypes.ICON_FONT} color={color} style={{marginRight: '0.5em'}}/>
                    )
                  )
                }</span>
                <span className={style['text-name']}>{getNodeDisplayTitle(node)}</span>
              </div>
              <div className={`${style['text-desc']} `}>
                <Icon name={'icon-image-text'} type={IconTypes.ICON_FONT} style={{marginRight: '0.5em'}} />
                {node.description || (node.meta && node.meta.description) ? node.description || (node.meta && node.meta.description) : '--'}
              </div>
              <div className={style['text-url']}>
                <Icon name={'icon-link'} type={IconTypes.ICON_FONT} style={{marginRight: '0.5em'}} />
                {node.url || (node.meta && node.meta.url) ? node.url || (node.meta && node.meta.url) : '--'}
              </div>
            </div>}
          placement={'right'}
        >
          <div
            style={{display: 'flex', alignItems: 'center', width: '100%'}}
          >
            <Icon
              name={`icon-override-node`}
              type={IconTypes.ICON_FONT}
              className={style['extra-add']}
              style={{opacity: me.props.viewDataProvider ? 0 : 1}}
            />
            {
              type && name ? (
                <Icon name={name} type={type} color={color} style={{marginRight: '0.5em'}}/>
              ) : (
                <Icon name={'icon-yuandianda'} type={IconTypes.ICON_FONT} color={color} style={{marginRight: '0.5em'}}/>
              )
            }
            <span className={`${style['name']}`}>{node.fname}</span>
          </div>
        </Tooltip>
      </div>
    )
  };

  /* 推荐节点列表渲染 */
  renderNodesList = (nodesList = []) => {
    let me = this;
    let placeholder = (me.pageSize - nodesList.length) > 0 ? (
      me.placeholderArr.slice(0, me.pageSize - nodesList.length)
    ) : ([]);
    return (
      <React.Fragment>
        {
          nodesList.length > 0 ? (
            <React.Fragment>
              <div className={`${style['extra-properties-list']}`}>
                {
                  nodesList.map(me.renderNodeItem)
                }
                {
                  placeholder.map((item, index) => (
                    <div key={`placeholder-override-node-${index}`} className={`${style['extra-properties-item']} ${style['placeholder']} `}>{item}</div>
                  ))
                }
              </div>
            </React.Fragment>
          ) : null
        }
      </React.Fragment>
    )
  };

  componentWillUnmount() {
    this.props.bus.remove(this);
  }

  render() {
    let me = this;

    return (
      <React.Fragment>
        <div className={`${style['frame']} ${me.props.visible ? 'visible' : 'invisible'} dark-theme`}>
          {/* 内容展示 */}
          {
            me.props.visible ? (
              <React.Fragment>
                {/* 推荐填充 */}
                {
                  (me.state.overrideStatus === 'success' && me.state.overrideNodes.length > 1) ? (
                    <div className={style['extra-properties-title']}>
                      推荐填充：
                    </div>
                  ) : null
                }
                {
                  me.state.overrideStatus === 'success' ? (
                    <React.Fragment>
                      {
                        me.renderNodesList(me.state.overrideNodes)
                      }
                    </React.Fragment>
                  ) : null
                }
                {
                  me.state.overrideStatus === 'processing' ? (
                    <div className={style['extra-properties-tips']}><span style={{position: 'relative', top: '-4px'}}>... ...</span></div>
                  ) : null
                }

              </React.Fragment>
            ) : null
          }
        </div>
      </React.Fragment>
    );
  }
}

ExploreOverridePanel.defaultProps = {
  bus: PB,
};

ExploreOverridePanel.propTypes = {
  visible: PropTypes.bool.isRequired,
  nodeInfo: PropTypes.object,
  nodeId: PropTypes.string,
  overrideList: PropTypes.array,
  viewDataProvider: PropTypes.instanceOf(ViewDataProvider),
  bus: PropTypes.instanceOf(SimplePB),
};

export default ExploreOverridePanel;