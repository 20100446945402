import {httpUtilWithNoMsg as httpUtil} from '@/utils/HttpUtil';

export const API_LoadRelationshipClueList = (param) => {
  return httpUtil.get(`/view/node_clue`, param);
};

export const API_AddRelationshipClue= (param) => {
  return httpUtil.post(`/view/node_clue`, param);
}

export const API_UpdateRelationshipClue = (nodeClueId, param) => {
  return httpUtil.put(`/view/node_clue/${nodeClueId}`, param);
}

export const API_RemoveRelationshipClue = (nodeClueId) => {
  return httpUtil.delete(`/view/node_clue/${nodeClueId}`);
}