import React from 'react';
import PropTypes from 'prop-types';
import {Row, Col, Tooltip, message, Divider, Checkbox} from 'antd';
import LightBox from 'react-image-lightbox';
import moment from 'moment';
import copy from 'copy-to-clipboard';

import PB, {SimplePB} from '@/libs/simplePB';
import ClampLines from '@/libs/my-react-clamp-lines';

import {getNodeDisplayTitle, getNodeIcon, NODE_TYPE_TEXT} from '@/constants/vis.defaultDefine.1';
import {AvatarColors, IconTypes} from '@/constants/common';

import {getToken, REQUEST_BASE} from '@/utils/HttpUtil';

import ViewDataProvider from '@/components/common/dataProvider/common.dataProvider.view';
import Icon from '@/components/common/common.icon';
import HoverContent from '@/components/common/common.hoverContent';
import TimeDisplayField from '@/components/common/common.timeDisplayField';

import style from '@/style/components/mainView/main.aiConsoleMessage.less';

const supportedSuffixList = ['png', 'jpg', 'gif', 'jpeg'];

class MainAiConsoleMessageCommonNodeInfo extends React.PureComponent {
  state = {
    currentImageIdx: -1,
    creatorInfo: null,
    fullScreenContent: false,
    recommendationStartIdx: 0,
    showExtendedActions: false,
  };

  clampLinesId = `ai-console-message-common-node-info-${Math.random()}`;

  copyToClipboard = text => {
    let result = copy(text, {
      message: '请按下 #{key} 复制文本。',
    });

    if (result) message.success('文本已复制到剪切板。');
  };

  doExtendedAction = (action) => {
    this.props.bus.emit('network', 'node.show_extended_action_viewer', action);
  };

  /**
   * 编辑节点详情 - 修改节点信息
   */
  onEdit = () => {
    this.props.bus.emit('network', 'node.on_edit',
      {node: this.props.node, id: this.props.node.id});
  };

  onExtendedActionSettings = (idx) => {
    this.props.bus.emit('network', 'node.on_edit_extended_action_settings',
      {node: this.props.node, id: this.props.node.id, idx});
  };

  onShowExtendedActions = () => {
    this.setState({showExtendedActions:!this.state.showExtendedActions});
  };
  componentDidMount() {
    let me = this;
    // 获取协作成员信息列表
    me.props.bus.sub(me, 'teamwork', 'member.list.success', ({viewId, userList}) => {
      if (viewId === me.props.originalViewId) {
        let creatorInfo = userList.length > 0 && userList.filter(item => parseInt(item.userId) === parseInt(me.props.node.userId))[0];
        me.setState({creatorInfo});
      }
    });

    // 触发获取协作成员信息
    me.props.bus.emit('teamwork', 'member.list.do', {viewId: me.props.originalViewId, forceReload: false});

    //setTimeout(me.forceUpdate, 3000);
  }

  componentWillUnmount() {
    this.props.bus.remove(this);
  }

  render() {
    let me = this;
    const {node} = me.props;
    const {name, type, color} = getNodeIcon(node);
    const extendedActions = (node.meta ? node.meta['extendedActions'] : undefined) || [undefined, undefined, undefined];

    let contentList = [], description = '-', tags = [];
    if (node.tag && node.tag.length > 0) {
      tags = node.tag.split(' ');
    }
    if (node.description) {
      contentList.push(node.description);
    }
    if (contentList.length > 0) {
      description = contentList.join("\n");
    }

    let fullScreenContent = null;

    if (me.state.fullScreenContent && contentList.length > 0) {
      fullScreenContent = (
        <HoverContent onClose={() => me.setState({fullScreenContent: false})}>
          <div
            className={`${style['fullscreen-frame']} scrollbar scrollbar-none`}
            onClick={e => e.stopPropagation()}
          >
            <pre style={{minHeight: '3rem','marginBottom': '1.5rem',fontSize: '3.9rem',color: '#fff',backgroundColor:'#385C80',padding:'0 1rem'}}>{node.fname}</pre>
            {tags && tags.length>0 && 
                <div className="ant-form-vertical" style={{'marginBottom': '1rem'}}>    
                  <div className="ant-form-item-control" style={{lineHeight: '0.5'}}>
                      <span className="ant-form-item-children">
                      {tags.map((tag, idx) => (
                          <span className="ant-tag" key={`tag-${idx}`} style={{'backgroundColor': 'rgba(88, 118, 163)',border:'0',textShadow:'none',fontSize: '1.6rem','lineHeight': '1.5',marginBottom:'8px',display:'inline-block'}}>{tag}</span>
                      ))}
                      </span>
                  </div>
              </div>
            }
            <div style={{borderTop: '1px solid rgba(146, 207, 243, 0.7)',height: '2rem'}}></div>
            {description.split('\n').map((line, idx) => (
              <pre key={`l-${idx}`} style={{minHeight: '3rem'}}>{line}</pre>
            ))}
            <div style={{borderBottom: '1px solid rgba(146, 207, 243, 0.7)',height: '2rem'}}></div>
          </div>
        </HoverContent>
      );
    }

    let lightBox = null, imageContentRow = null;
    const images = [];

    // 尝试获取附件中的图片信息
    if (node && node['prop']) {
      for (let i = node['prop'].length - 1; i >= 0; i--) {
        let property = node['prop'][i], fileList = property['fileList'];
        if (fileList) {
          for (let j = 0; j < fileList.length; j++) {
            let fileInfo = fileList[j];
            const segments = fileInfo['fileName'].split(/\./g);
            const type = segments.pop().toLowerCase();
            if (supportedSuffixList.includes(type)) {
              // 找到图片
              images.push(fileInfo);
            }
          }
        }
      }
    }
    

    if (me.state.currentImageIdx >= 0) {
      if (images.length > 0 && me.state.currentImageIdx < images.length) {
        const mainSrc =
          `${REQUEST_BASE}/view/project/file/${images[me.state.currentImageIdx]['fileId']}?Authorization=${getToken()}`;
        if (mainSrc) {
          const nextSrcIdx = (me.state.currentImageIdx + 1) % images.length;
          const prevSrcIdx = (images.length + this.state.currentImageIdx - 1) % images.length;
          let downButton = (<div  onClick={() => alert(mainSrc)}>下载</div>);
          lightBox = (
            <LightBox
              mainSrc={mainSrc}
              prevSrc={
                images.length === 1 ? null
                  : `${REQUEST_BASE}/view/project/file/${images[prevSrcIdx]['fileId']}?Authorization=${getToken()}`
              }
              nextSrc={
                images.length === 1 ? null
                  : `${REQUEST_BASE}/view/project/file/${images[nextSrcIdx]['fileId']}?Authorization=${getToken()}`
              }
              onCloseRequest={() => me.setState({currentImageIdx: -1})}
              onMovePrevRequest={() => me.setState({currentImageIdx: prevSrcIdx})}
              onMoveNextRequest={() => me.setState({currentImageIdx: nextSrcIdx})}
              zoomInLabel='放大'
              zoomOutLabel='缩小'
              closeLabel='关闭'
              toolbarButtons={[
                (<div className={style['image-down']} title='下载'><a href={mainSrc} download><Icon name="vertical-align-bottom" style={{ fontSize: '1.3em' }}/></a></div>)
             ]}
            />
          );
        }
      }
    }

    if (images.length > 0) {
      let imageSrc = `${REQUEST_BASE}/view/project/file/thumb/200/0/${images[0]['fileId']}?Authorization=${getToken()}`;
      imageContentRow = (
        <Row gutter={0}>
          <Col span={2}>
            <Tooltip
              title={'复制图片地址'}
              placement={'left'}
            >
              <Col span={2}>
                <a onClick={() => me.copyToClipboard(window.location.origin + imageSrc)}>
                  <Icon name={'icon-image'} type={IconTypes.ICON_FONT} />
                </a>
              </Col>
            </Tooltip>
          </Col>
          <Col span={22} className={style['image-container']}>
            <a onClick={() => me.setState({currentImageIdx: 0})}>
              {/* TODO: 最大宽度写死200，后期需根据屏幕适配 */}
              <img
                src={imageSrc}
                alt={images[0]['fileName']}
              />
            </a>
          </Col>
        </Row>
      );
    }

    const nodeTitle = getNodeDisplayTitle(node);
    const updateTime = node.updateTime ? node.updateTime : (node.linkTime ? node.linkTime : false);
    const linkTime = node.linkTime ? node.linkTime : (node.updateTime ? node.updateTime : false);
    const creatorNick = (node['userId'] && me.state.creatorInfo) ? me.state.creatorInfo.nick : false;
    const currentUserId = parseInt(localStorage.getItem('userId'));
    const nodeUserId = parseInt(node.userId);
    const viewInfo = me.props.viewInfo || me.props.viewDataProvider.viewInfo;
    const viewCreateUserId = viewInfo ? parseInt(viewInfo['userId']) : 0;
    const enableCrossEdit = viewInfo && viewInfo['teamworkMetaJSON']
      && viewInfo['teamworkMetaJSON']['enable_cross_edit'] === 1;
    const editable = (nodeUserId === currentUserId || viewCreateUserId === currentUserId || enableCrossEdit);   //viewInfo['lock'] !== 1  &&

    // noinspection JSValidateTypes
    return (
      <React.Fragment>
        <div className={style['body-frame']}>
          <div className={style['title-frame']}>
            <Tooltip
              title={'复制节点标题'}
              placement={'left'}
            >
              <a style={{marginRight: '0.55rem'}} onClick={() => me.copyToClipboard(nodeTitle)}>
                <Icon name={name} type={type} color={color} />
              </a>
            </Tooltip>
            <span className={style['title']} onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              PB.emit('searchinput', 'ety.list.show', {keyword: nodeTitle});
            }}><span>{nodeTitle}</span></span>
            {
              editable ? (
                <div className={style['edit-btn']} onClick={() => me.onEdit()}>
                  <Icon name="edit"/>
                </div>
              ) : null
            }
            <div className={style['edit-btn']} onClick={() => me.copyToClipboard(node.id)} style={{right:'1.6rem',color: 'hsla(213, 20%, 15%, 0.5)'}}>
              <Icon name="copy"/>
            </div>
          </div>
          <hr />
          <div className={style['content-frame']}>
            {tags && tags.length>0 && <Row gutter={0} className={style['attribute-item']} >
              <Tooltip
                title={'复制标签'}
                placement={'left'}
              >
                <Col span={2}>
                  <a onClick={() => me.copyToClipboard(node.tag)}>
                    <Icon name={'icon-tag'} type={IconTypes.ICON_FONT} />
                  </a>
                </Col>
              </Tooltip>
              <Col span={22}>
                {
                  tags.length > 0 ? (
                    <div className="ant-form-vertical">    
                      <div className="ant-form-item-control">
                        <span className="ant-form-item-children">
                            {tags.map((tag, idx) => (
                              <Tooltip
                                title={tag}
                                placement={'bottom'}
                              >
                                <span className={`ant-tag ${style['tag-txt']}`} key={`tag-${idx}`}
                                 onClick={e => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  PB.emit('searchinput', 'ety.list.show', {keyword: tag});
                                }}>{tag}</span>
                              </Tooltip>
                            ))}
                        </span>
                      </div>
                    </div>
                  ) : null
                }
              </Col>
            </Row>}
            <Row gutter={0} className={style['attribute-item']}>
              <Tooltip
                title={description !== '-' ? '复制描述信息' : '描述信息'}
                placement={'left'}
              >
                <Col span={2}>
                  {
                    description !== '-' ? (
                      <a onClick={() => me.copyToClipboard(description)}>
                        <Icon name={'icon-image-text'} type={IconTypes.ICON_FONT} />
                      </a>
                    ) : (
                      <Icon name={'icon-image-text'} type={IconTypes.ICON_FONT} />
                    )
                  }
                </Col>
              </Tooltip>
              <Col
                className={'scrollbar'}
                span={22}
                style={{maxHeight: '20rem', overflowY: 'auto', textAlign: 'justify', textAlignLast: 'auto'}}
              >
                <ClampLines
                  id={me.clampLinesId}
                  lines={5}
                  moreText={'展开 ∨'}
                  lessText={'收起 ∧'}
                  useLinkButtons={true}
                  text={description}
                  extraLinkButtons={contentList.length > 0 ? (
                    <React.Fragment>
                      <br />
                      <a
                        className="clamp-lines__link_button"
                        onClick={() => {contentList.length > 0 && me.setState({fullScreenContent: true})}}
                      >
                        放大 ＜
                      </a>
                    </React.Fragment>
                  ) : null}
                />
              </Col>
            </Row>
            <Row gutter={0} className={style['attribute-item']}>
              <Tooltip
                title={node.url ? '复制链接地址' : '链接地址'}
                placement={'left'}
              >
                <Col span={2}>
                  {
                    node.url ? (
                      <a onClick={() => me.copyToClipboard(node.url)}>
                        <Icon name={'icon-link'} type={IconTypes.ICON_FONT} />
                      </a>
                    ) : (
                      <Icon name={'icon-link'} type={IconTypes.ICON_FONT} />
                    )
                  }
                </Col>
              </Tooltip>
              <Col span={22}>
                {node.url ? (
                  <ClampLines
                    className={'ant-list-item-meta-description'}
                    lines={2}
                    moreText={'展开 ∨'}
                    lessText={'收起 ∧'}
                    text={node.url}
                    renderFn={(text) => (
                      <a href={node.url} target={'_blank'}>
                        <span>{text}</span>
                      </a>
                    )}
                  />
                ) : '-'}
              </Col>
            </Row>
            <Row gutter={0} className={style['attribute-item']}>
              <Tooltip
                title={updateTime ? '复制更新时间' : '更新时间'}
                placement={'left'}
              >
                <Col span={2}>
                  {
                    updateTime ? (
                      <a onClick={() => me.copyToClipboard(updateTime)}>
                        <Icon name={'icon-calendar'} type={IconTypes.ICON_FONT} />
                      </a>
                    ) : (
                      <Icon name={'icon-calendar'} type={IconTypes.ICON_FONT} />
                    )
                  }
                </Col>
              </Tooltip>
              <Col span={22}>
                {
                  linkTime ? (
                    <TimeDisplayField
                      timestamp={moment(linkTime, 'YYYY-MM-DD HH:mm:ss').valueOf()}
                      tooltipPlacement={'bottom'}
                      description={'创建时间：'}
                    />
                  ) : null
                }
                {
                  linkTime && updateTime ? (
                    <span>&nbsp;&nbsp;▷▷&nbsp;&nbsp;</span>
                  ) : null
                }
                {
                  updateTime ? (
                    <TimeDisplayField
                      timestamp={moment(updateTime, 'YYYY-MM-DD HH:mm:ss').valueOf()}
                      tooltipPlacement={'bottom'}
                      description={'更新时间：'}
                    />
                  ) : '-'
                }
              </Col>
            </Row>
            {imageContentRow}
            <Row gutter={0} className={style['attribute-item']}>
              <Col span={2}><Icon name={'paper-clip'} /></Col>
              {
                node['prop'] && node['prop'].length > 0 ? (
                  <Col span={22}>
                    <a
                      className={'as-link'}
                      onClick={() => {
                        me.props.bus.emit('network', 'node.on_show_extra_info', {node});
                      }}
                    >
                      附件数量({node['prop'].length > 999 ? '999+' : node['prop'].length})
                    </a>
                  </Col>
                ) : (
                  <Col span={22}>附件数量(0)</Col>
                )
              }
            </Row>
            <Row gutter={0} className={style['attribute-item']}>
              <Tooltip
                title={creatorNick ? '复制创建人信息' : '创建人信息'}
                placement={'left'}
              >
                <Col span={2}>
                  {
                    creatorNick ? (
                      <a onClick={() => me.copyToClipboard(creatorNick)}>
                        <Icon name={'icon-user'} type={IconTypes.ICON_FONT} />
                      </a>
                    ) : (
                      <Icon name={'icon-user'} type={IconTypes.ICON_FONT} />
                    )
                  }
                </Col>
              </Tooltip>
              <Col span={22}>{creatorNick || '-'}</Col>
            </Row>
          </div>
          <div className={`${style['extended-actions-frame']} animate__animated animate__fadeIn`}>
            <div className={style['divider-box']}>
              <Divider>
                扩展功能列表
              </Divider>
              <div className={style['cfg-btn']} onClick={() => me.onShowExtendedActions()}>
                  {!me.state.showExtendedActions && <Tooltip placement={'top'} title={'展开'}><Icon name={'down'} style={{color:'#1890ff'}}/></Tooltip> }
                  {me.state.showExtendedActions && <Tooltip placement={'top'} title={'收起'}><Icon name={'right'} style={{color:'#1890ff'}}/></Tooltip> }
              </div>
            </div>
            <ul>
              {
                extendedActions.map((action, idx) => action ? (
                  <Tooltip
                    key={`n-${idx}`}
                    placement={'left'}
                    title={action.description}
                  >
                    <li key={`n-${idx}`} style={{cursor: 'pointer'}}>
                      <Col span={2}>
                        <Icon
                          name={action['iconName'] || 'icon-extended-feature'}
                          type={action['iconType']}
                        />
                      </Col>
                      <Col span={22}>
                        <a onClick={() => me.doExtendedAction(action)}>{action.name}</a>
                        {
                          editable ? [(
                            <div key={'edit'} className={style['cfg-btn']} onClick={() => me.onExtendedActionSettings(idx)}>
                              <Icon name="setting"/>
                            </div>
                          ), (
                            <div
                              key={'remove'}
                              className={style['cfg-btn']}
                              onClick={() => {
                                me.props.bus.emit('network', 'node.do_edit_extended_action_settings',
                                  {nodeId: me.props.node.id, idx, settings: undefined});
                              }}
                            >
                              <Icon name="delete"/>
                            </div>
                          )] : null
                        }
                      </Col>
                    </li>
                  </Tooltip>
                ) : (
                  editable && me.state.showExtendedActions ? (
                    <li key={`n-${idx}`}>
                      <Col span={2}>
                        <Icon name={'icon-extended-feature'} type={IconTypes.ICON_FONT} />
                      </Col>
                      <Col span={22}>
                        扩展功能{idx + 1}
                        {
                          editable ? (
                            <div className={style['cfg-btn']} onClick={() => me.onExtendedActionSettings(idx)}>
                              <Icon name="setting"/>
                            </div>
                          ) : null
                        }
                      </Col>
                    </li>
                  ) : null
                ))
              }
              {
                (editable || extendedActions.find(a => !!a)) ? null : (
                  <div key={`not-found`} className={style['extended-actions-not-found']}>尚未配置扩展功能</div>
                )
              }
            </ul>
          </div>
        </div>
        {lightBox}
        {fullScreenContent}
      </React.Fragment>
    );
  }
}

MainAiConsoleMessageCommonNodeInfo.defaultProps = {
  bus: PB,
};

MainAiConsoleMessageCommonNodeInfo.propTypes = {
  bus: PropTypes.instanceOf(SimplePB),
  node: PropTypes.object.isRequired,
  originalViewId: PropTypes.string,
  recommendation: PropTypes.array,
  viewInfo: PropTypes.object,
  viewDataProvider: PropTypes.instanceOf(ViewDataProvider).isRequired,
};

export default MainAiConsoleMessageCommonNodeInfo;