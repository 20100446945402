/*
 * @Author: Carr.s
 * @Date: 2022-04-20 17:24:21
 * @LastEditTime: 2022-06-06 17:33:13
 * @LastEditors: Carr.s
 * @Description: 洞察 核心词汇洞察
 * @Copyright by joinmap
 */
import React from "react";
import ViewInsightChartTpl from "@/components/insightView/template/insight.chart.tpl";
import { connect } from "react-redux";
import styles from "@/style/components/insightView/insight.less";

class ViewKeyWordsInsight extends ViewInsightChartTpl {
  //===============================================================
  // begin of overwrite
  // 使用默认的方式就不用重写

  // ------------------------------------------------------------
  // 全局配置
  // ------------------------------------------------------------
  // 显示底部按钮
  showToolbar = false;
  // 洞察名称
  viewerTitle = "核心词汇";

  // ------------------------------------------------------------
  // 层级分析对象的配置
  // ------------------------------------------------------------
  // 第一层 level1 tag
  column1_showType = "tree"; // 第一列的展现形式，list=显示列，tree=显示树
  // column1_DataField = "data"; // 取值字段
  // column1_TotalField = "dataTotal"; // 计算总数(与返回数量不一定一致)字段
  column1_treeFields = []; // 用树状目录显示数据时各层级的 children 字段名数据
  column1_Title = "核心词汇"; // 显示名称
  column1_Unit = "个"; // 显示的文字单位
  column1_ValueRender = (level1_data, idx, nodePath = undefined) => {
    // 右侧数值渲染，不输出则空白
    // 必须加上 className={styles["line-value"]}
  };
  /*
  column1_IconRender = (level1_data, idx,nodePath=undefined) => {
    // 左侧图表渲染，不输出则空白
    // 必须加上 className={styles["line-icon"]}
  };
  */
  xlevel1_HoverTitle = (level1_data, idx, nodePath = undefined) => {
    // 列表鼠标悬浮提示文字
    // 负导向数值
    let negTotal = level1_data["negTotal"];
    return `${this.column1_Title}：${level1_data["name"]}\n${
      this.column2_Title
    }：${level1_data[this.column2_TotalField]}${
      this.column2_Unit
    }\n负导向：${negTotal}条\n点击打开${this.column2_Title}列表`;
  };
  column1_subTitle = () => {
    // 列表顶部副标题文字
    const { _column1Len } = this.state;
    return (
      <>
        已显示一级核心词汇{_column1Len}
        {this.column1_Unit}
      </>
    );
  };

  // 第二层 item
  column2_DataField = "news"; // 取值字段
  column2_TotalField = "newsTotal"; // 计算总数(与返回数量不一定一致)字段
  column2_ValueRender = (level2_data, idx) => {
    // 右侧数值渲染，不输出则空白
    // 必须加上 className={styles["line-value"]}
    // 不显示
  };
  /*
  column2_IconRender = (level2_data, idx) => {
    // 左侧图表渲染，不输出则空白
    // 必须加上 className={styles["line-icon"]}
  };
  column2_HoverTitle = (level2_data, idx) => {
    // 列表鼠标悬浮提示文字
    return `${this.column2_Title}：${level2_data["name"]}\n点击打开${this.column1_Title}链接`;
  };
  */

  // ------------------------------------------------------------
  // 接口的配置
  // ------------------------------------------------------------
  // 接口 url path
  // api_path = "";
  api_path = "/wth_server_api/extract_key_words_by_view_id";

  // 返回数据默认值
  defaultLimit = 60;

  /**
   * 【继承组件要重写】
   * api 请求完成后，setState结束后的 callback
   */
  apiLoadedCallback = () => {
    // message.info("数据加载成功");
  };

  // ------------------------------------------------------------
  // 图表的配置
  // ------------------------------------------------------------
  // 图表显示库
  chartType = "echarts";

  // chartDivLeft = 300;
  chartCompomentStyle = {
    width: "100%",
    height: "100%",
  };

  /**
   * 生成公用的、默认的 option
   * @returns
   */
  initChartOption = () => {
    let _chartOption = {
      grid: {
        show: false,
      },
      series: {
        type: "sunburst",
        radius: ["15%", "80%"],
        sort: undefined,
        emphasis: {
          focus: "descendant", // descendant ancestor
          label: {
            fontSize: "14",
          },
          labelLine: {
            show: true,
          },
        },
        data: [],
        label: {
          rotate: "radial",
          // rotate: "tangential",
        },
        levels: [
          {
            label: {
              formatter: function (val) {
                return "返回上层";
              },
              offset: [0, 0],
            },
            itemStyle: {
              color: "#22313d",
            },
          },
          {
            itemStyle: {
              color: this._chartColors[0],
            },
          },
          {
            itemStyle: {
              color: this._chartColors[1],
            },
          },
          {
            itemStyle: {
              color: this._chartColors[2],
            },
          },
        ],
        itemStyle: {
          // color: "#ddd",
          borderWidth: 1,
        },
      },
    };
    return _chartOption;
  };

  /**
   * 【继承组件要重写】
   * 生成 chart 的 options
   * @param {int} idx 选中的数据下标
   */
  refreshChartOptions = (nodePath = null) => {
    console.log("refreshChartOptions 0 nodePath:", nodePath);
    // icon的前缀
    const {
      query: { isDev },
    } = this.props;
    let me = this;
    let pathArr = [];
    if (nodePath) {
      pathArr = (nodePath + "").split("-");
    }

    // 按层级给数据上色
    let chartData = this._setDataDeepValue(
      me._chartData,
      undefined,
      (node, nodePath1) => {
        let newNode = { ...{}, ...node };
        const pathArr1 = (nodePath1 + "").split("-");
        // 断言
        const level = pathArr1.length;
        if (nodePath === undefined || nodePath === null || nodePath === false) {
          // 没有选中的扇区
          console.log("refreshChartOptions 1 nodePath1:", nodePath1);
          newNode["itemStyle"] = {
            color: me.getColorOfLevel(level, 3),
          };
          newNode["label"] = {
            color: "rgba(255,255,255,1)",
          };
        } else if (
          pathArr1.slice(0, pathArr.length).join("-") == pathArr.join("-")
        ) {
          // 命中选中的扇区
          console.log("refreshChartOptions 2 nodePath1:", nodePath1);
          newNode["itemStyle"] = {
            color: me.getColorOfLevel(level, 3),
          };
          newNode["label"] = {
            color: "rgba(255,255,255,1)",
          };
        } else {
          // 非选中的 灰色
          newNode["itemStyle"] = {
            color: "#22313d",
            borderWidth: 0,
          };
          newNode["label"] = {
            color: "rgba(255,255,255,0.3)",
          };
        }
        newNode["_path"] = nodePath1;
        return newNode;
      },
      me.column1_treeFields
    );
    console.log("refreshChartOptions chartData=", chartData);
    // 生成options
    me._chartOption = this.initChartOption();
    me._chartOption.series.data = chartData;
    // me._chartOption.series.data = me._chartData;
    // if (this._chartIns) {
    //   this._chartIns.setOption(me._chartOption);
    // }

    // return;
    // ---------------------------------------------
    // 计算图表的位置
    // me._chartOption.grid.top = "80px";
    // let w = 10; // 两个间隙

    // let column1Div = document.getElementById("column1_list");
    // if (nodePath === undefined || nodePath === null || nodePath === false) {
    //   w = 0;
    // } else {
    //   if (column1Div) {
    //     console.log("column1_list.clientWidth:", column1Div.clientWidth);
    //     w = w + column1Div.clientWidth;
    //   }
    // }

    // // 更新 图表div的左偏移量
    // let chartDiv = document.getElementById("chart_div");
    // console.log("chartDiv:", chartDiv);
    // if (chartDiv) {
    //   chartDiv.style.marginLeft = w + "px";
    // }
  };

  /**
   * 【继承组件要重写】
   * echart 有时需要绑定点击事件什么
   * @param {object} e ReactECharts 返回的 echarts 实例
   */
  eChartRefCallback = (e) => {
    let me = this;
    // me.chartIns = e.getEchartsInstance();
    if (!me._chartIns) {
      me._chartIns = e.getEchartsInstance();
      // 绑定点击事件
      me._chartIns.on("click", function (params) {
        console.log("ViewKeyWordsInsight ReactECharts click params: ", params);
        if (
          params.hasOwnProperty("data") &&
          params.data.hasOwnProperty("_path")
        ) {
          let name = params.data.name; //点击的节点的name
          //调用点击事件
          me._column1_onClick(params.data._path, name, false);
        } else {
          me._column1_onClick(null, null, true);
        }
      });
    }
  };

  // end of overwrite
  //===============================================================

  getColorOfLevel = (level, maxLevel = 1000000) => {
    if (level > 0 && level < this._chartColors.length) {
      if (level > maxLevel) {
        return "#dddddd";
      }
      return this._chartColors[level];
    } else {
      return "#dddddd";
    }
  };
}

export default ViewKeyWordsInsight;
