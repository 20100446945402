import {IDBPObjectStore} from 'idb';

import {getCacheStore} from "@/storage/idbStorage";

/**
 * @type {IDBPObjectStore<*, ['local-cache-data'], 'local-cache-data', 'readwrite'>}
 */
let localDataCacheStore = undefined;

getCacheStore('local-cache-data').then(store => {
  localDataCacheStore = store;
});

export const reloadCustomIconFromCache = () => {
  let nextCustomIconToReplace = 0, cachedIconInfo = [], responded = 0;

  return new Promise((resolve, reject) => {
    if (!localDataCacheStore) {
      reject();
    }

    localDataCacheStore.get(`cache:local:node:new_custom_icon_pos`).then(dataStr => {
      nextCustomIconToReplace = parseInt(dataStr || '0');
      let finalPos = nextCustomIconToReplace, currentPos = nextCustomIconToReplace;
      do {
        (pos => {
          localDataCacheStore.get(`cache:local:node:new_custom_icon_${pos}`).then(dataStr => {
            if (dataStr) {
              cachedIconInfo[(pos - nextCustomIconToReplace + 6) % 6] = JSON.parse(dataStr);
            }
          }).finally(() => {
            responded++;
            if (responded >= 6) {
              resolve(cachedIconInfo);
            }
          });
        })(currentPos);
        currentPos = (currentPos + 1) % 6;
      } while (currentPos !== finalPos)
    }).catch(() => {
      // ignore
    });
  });
}