import React from 'react';
import {List, Card} from 'antd';
import PropTypes from 'prop-types';

import ViewDataProvider from '@/components/common/dataProvider/common.dataProvider.view';
import NodeInfoCommonDetailPanel from "@/components/mainView/nodeInfo/main.nodeInfo.common.detail";
import {
  getNodeDisplayTitle,
  getNodeIcon,
} from "@/constants/vis.defaultDefine.1";
import Icon from "@/components/common/common.icon";
import PB, {SimplePB} from "@/libs/simplePB";

class NodeInfoPatentGeneralPanel extends React.Component {
  render() {
    let me = this;
    const {node, /*permanent, */showIcon, showAssistant, /*editable, */networkRef} = this.props;
    const records = (node ? [node] : []);
    const {name, type, color} = getNodeIcon(node);

    return (
      <Card
        bordered={false}
        title={
          <span>
            <a
              style={{
                '--extra-action-width': node._$extraTitleActions ? node._$extraTitleActions._$width : '0em',
              }}
            >
              <Icon name={name} type={type} color={color} style={{marginRight: '0.5em'}} />
              <span><span>{getNodeDisplayTitle(node)}</span></span>
            </a>
            {
              node._$extraTitleActions ? node._$extraTitleActions() : null
            }
          </span>
        }
        /*extra={(editable && networkRef) ? <NodeActions node={node} networkRef={networkRef}/> : null}*/
      >
        {/*{permanent ? null : <Alert message="该节点为系统自动联想节点" type="info" showIcon />}*/}
        <List key={'node-info'}
              itemLayout="horizontal"
              split={false}
              size="small"
              locale={{emptyText: this.props.emptyText}}
              dataSource={records}
              renderItem={
                (node) => (
                  <List.Item>
                    <NodeInfoCommonDetailPanel
                      bus={me.props.PB}
                      node={node} 
                      showIcon={showIcon}
                      showAssistant={showAssistant}
                      networkRef={networkRef}
                      originalViewId={me.props.networkRef ? me.props.networkRef.getData().viewId : me.props.originalViewId}
                    />
                  </List.Item>
                )
              }/>
        {/*{(editable && networkRef) ? <NodeActions node={node}/> : null}*/}
      </Card>
    );
  }
}

NodeInfoPatentGeneralPanel.defaultProps = {
  bus: PB,
  permanent: true,
  showIcon: true,
  showAssistant: false,
  emptyText: '没有选中专利对象',
  editable: false,
};

NodeInfoPatentGeneralPanel.propTypes = {
  bus: PropTypes.instanceOf(SimplePB),
  node: PropTypes.object.isRequired,
  permanent: PropTypes.bool,
  showIcon: PropTypes.bool,
  showAssistant: PropTypes.bool,
  emptyText: PropTypes.string,
  editable: PropTypes.bool,
  networkRef: PropTypes.instanceOf(ViewDataProvider),
  originalViewId: PropTypes.string,
};

export default NodeInfoPatentGeneralPanel;
