import React from 'react';
import {Tooltip} from "antd";
import PropTypes from "prop-types";

import style from '@/style/containers/explorationView.less';

class AlgSwitch extends React.Component {
  state = {
    hideTooltip: localStorage.getItem('hide_alg_switch_tooltip') || '0',
  };

  render() {
    let me = this;

    return (
      <Tooltip
        placement={'left'}
        overlayClassName={'dark-theme'}
        overlayStyle={{zIndex: 100}}
        visible={me.state.hideTooltip === '0'}
        title={(
          <div>
            <p style={{marginBottom: 0}}>
              系统正在使用<span style={{fontWeight: 'bold', padding: '0 0.5em'}}>模式{me.props.alg + 1}</span>进行计算。<br />
              如果您对当前结果不满意，可尝试其他计算模式。
            </p>
            <p style={{textAlign: 'right', marginBottom: 0}}>
              <a
                onClick={() => {
                  localStorage.setItem('hide_alg_switch_tooltip', '1');
                  me.setState({hideTooltip: '1'});
                }}
              >
                知道了
              </a>
            </p>
          </div>
        )}
      >
        <div className={style['switch-inner']} style={me.props.style}>
          <div className={style['alg-switch-title']}>
            计算模式
          </div>
          <ul className={style['alg-switch-action-frame']}>
            {
              [0, 1, 2, 3, 4].map(idx => (
                <li
                  key={`alg-${idx}`}
                  onClick={() => me.props.onChange(idx)}
                  className={`${me.props.alg === idx ? 'active' : ''}`}
                >
                  {idx + 1}
                </li>
              ))
            }
          </ul>
        </div>
      </Tooltip>
    );
  }
}

AlgSwitch.defaultProps = {
  style: {},
  alg: 0,
};

AlgSwitch.propTypes = {
  style: PropTypes.object,
  alg: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default AlgSwitch;