import {bindUtil, withPromiseMessageDecorator} from "@/libs/core-decorators";
import messageProxy from '@/components/common/common.messageProxy';
import {getErrorInfo as commonGetErrorInfo} from "@/components/common/common.functions";
import {relationOperationMessages} from "@/constants/messages";
import {API_ParseRelation,API_makeMapByNodeId,API_starNode,API_makeMap,API_analysisIncidentList,API_analysisIncidentFromSearcher,API_searchInputEvtList,API_matchInputEvtList,
  API_getPresentationByKeyword,API_getSubgraphByKeyword,API_getDatasetByKeyword,API_getDatasetDetail,API_matchEvtList,API_matchEvtListParent,API_matchEvtListChild,API_matchEtyList} from "@/api/relation";
import {API_LoadBriefingInfo} from "@/libs/view/network/api";
import {API_CallMicroService} from "@/api/microService.js";

@bindUtil.asSourceClass
class RelationDataProvider {
  static parseRelation = (text, parentNodeId = undefined) => {
    return new Promise((resolve, reject) => {
      API_ParseRelation(text, parentNodeId).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve(response.data.data);
        } else {
          reject({...RelationDataProvider.getErrorInfo(response)});
        }
      }).catch(error => {
        reject({...RelationDataProvider.getErrorInfo(error)});
      });
    });
  };

  static loadBriefing = (briefingId) => {
    return new Promise((resolve, reject) => {
      API_LoadBriefingInfo(briefingId).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve(response.data.data);
        } else {
          reject({...RelationDataProvider.getErrorInfo(response)});
        }
      }).catch(error => {
        reject({...RelationDataProvider.getErrorInfo(error)});
      });
    });
  };

  static makeMapByNodeId = (nodeId) => {
    return new Promise((resolve, reject) => {
      API_makeMapByNodeId(nodeId).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve(response.data.data);
        } else {
          reject({...RelationDataProvider.getErrorInfo(response)});
        }
      }).catch(error => {
        reject({...RelationDataProvider.getErrorInfo(error)});
      });
    });
  };

  static makeMap = (params) => {
    return new Promise((resolve, reject) => {
      API_makeMap(params).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve(response.data.data);
        } else {
          reject({...RelationDataProvider.getErrorInfo(response)});
        }
      }).catch(error => {
        reject({...RelationDataProvider.getErrorInfo(error)});
      });
    });
  };

  static starNode = (label) => {
    return new Promise((resolve, reject) => {
      API_starNode(label).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve(response.data.data);
        } else {
          reject({...RelationDataProvider.getErrorInfo(response)});
        }
      }).catch(error => {
        reject({...RelationDataProvider.getErrorInfo(error)});
      });
    });
  };

  static callMicroService = (microServiceId, viewId, request) => {
    return new Promise((resolve, reject) => {
      API_CallMicroService(microServiceId, viewId, request).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve(response.data.data);
        } else {
          reject({...RelationDataProvider.getErrorInfo(response)});
        }
      }).catch(error => {
        reject({...RelationDataProvider.getErrorInfo(error)});
      });
    });
  };

  static analysisIncidentList = (params) => {
    return new Promise((resolve, reject) => {
      API_analysisIncidentList(params).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve(response.data.data);
        } else {
          reject({...RelationDataProvider.getErrorInfo(response)});
        }
      }).catch(error => {
        reject({...RelationDataProvider.getErrorInfo(error)});
      });
    });
  };

  static analysisIncidentFromSearcher = (params) => {
    return new Promise((resolve, reject) => {
      API_analysisIncidentFromSearcher(params).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve(response.data.data);
        } else {
          reject({...RelationDataProvider.getErrorInfo(response)});
        }
      }).catch(error => {
        reject({...RelationDataProvider.getErrorInfo(error)});
      });
    });
  };

  static searchInputEvtList = (params) => {
    return new Promise((resolve, reject) => {
      API_searchInputEvtList(params).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve(response.data.data);
        } else {
          reject({...RelationDataProvider.getErrorInfo(response)});
        }
      }).catch(error => {
        reject({...RelationDataProvider.getErrorInfo(error)});
      });
    });
  };

  static matchInputEvtList = (params) => {
    return new Promise((resolve, reject) => {
      API_matchInputEvtList(params).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve(response.data.data);
        } else {
          reject({...RelationDataProvider.getErrorInfo(response)});
        }
      }).catch(error => {
        reject({...RelationDataProvider.getErrorInfo(error)});
      });
    });
  };

  static getPresentationByKeyword = (params) => {
    return new Promise((resolve, reject) => {
      API_getPresentationByKeyword(params).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve(response.data.data);
        } else {
          reject({...RelationDataProvider.getErrorInfo(response)});
        }
      }).catch(error => {
        reject({...RelationDataProvider.getErrorInfo(error)});
      });
    });
  };

  static getSubgraphByKeyword = (params) => {
    return new Promise((resolve, reject) => {
      API_getSubgraphByKeyword(params).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve(response.data.data);
        } else {
          reject({...RelationDataProvider.getErrorInfo(response)});
        }
      }).catch(error => {
        reject({...RelationDataProvider.getErrorInfo(error)});
      });
    });
  };

  static getDatasetByKeyword = (params) => {
    return new Promise((resolve, reject) => {
      API_getDatasetByKeyword(params).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve(response.data.data);
        } else {
          reject({...RelationDataProvider.getErrorInfo(response)});
        }
      }).catch(error => {
        reject({...RelationDataProvider.getErrorInfo(error)});
      });
    });
  };

  static getDatasetDetail = (params) => {
    return new Promise((resolve, reject) => {
      API_getDatasetDetail(params).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve(response.data.data);
        } else {
          reject({...RelationDataProvider.getErrorInfo(response)});
        }
      }).catch(error => {
        reject({...RelationDataProvider.getErrorInfo(error)});
      });
    });
  };

  static matchEvtList = (params) => {
    return new Promise((resolve, reject) => {
      API_matchEvtList(params).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve(response.data.data);
        } else {
          reject({...RelationDataProvider.getErrorInfo(response)});
        }
      }).catch(error => {
        reject({...RelationDataProvider.getErrorInfo(error)});
      });
    });
  };

  static matchEvtListParent = (params) => {
    return new Promise((resolve, reject) => {
      API_matchEvtListParent(params).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve(response.data.data);
        } else {
          reject({...RelationDataProvider.getErrorInfo(response)});
        }
      }).catch(error => {
        reject({...RelationDataProvider.getErrorInfo(error)});
      });
    });
  };

  static matchEvtListChild = (params) => {
    return new Promise((resolve, reject) => {
      API_matchEvtListChild(params).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve(response.data.data);
        } else {
          reject({...RelationDataProvider.getErrorInfo(response)});
        }
      }).catch(error => {
        reject({...RelationDataProvider.getErrorInfo(error)});
      });
    });
  };

  static matchEtyList = (params) => {
    return new Promise((resolve, reject) => {
      API_matchEtyList(params).then(response => {
        if (response && response.data && response.data.code === 0) {
          resolve(response.data.data);
        } else {
          reject({...RelationDataProvider.getErrorInfo(response)});
        }
      }).catch(error => {
        reject({...RelationDataProvider.getErrorInfo(error)});
      });
    });
  };

  /**
   * @private
   * 通過Axios请求返回的错误信息获取错误详情
   *
   * @param {*} error
   *
   * @return {{msg: string, code: number}}
   */
  static getErrorInfo = commonGetErrorInfo;
}

const overrideMessages = {};

/**
 * @type {typeof RelationDataProvider}
 */
const RelationDataProviderWrapper = withPromiseMessageDecorator(
  messageProxy(relationOperationMessages, overrideMessages)
)(RelationDataProvider);

// noinspection JSUnusedGlobalSymbols
export const overrideNextMessage = (method, msg) => {
  overrideMessages[method] = msg;
};

export default RelationDataProviderWrapper;